import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringGetVehicleTrackersRoutine } from '@modules/monitoring/actions';

// Получает значения привязанных к автомобилю трекеров (data.data)
function* monitoringGetVehicleTrackersWorker() {
  const { request, success, failure, fulfill } = monitoringGetVehicleTrackersRoutine;

  try {
    yield put(request());

    const { data } = yield call(MonitoringAPI.getVehicleTrackers);

    yield put(success(data.data));
  } catch (error) {
    //TODO зачем console.error
    console.error(error);

    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringGetVehicleTrackersWatcher() {
  yield takeLatest(monitoringGetVehicleTrackersRoutine.trigger, monitoringGetVehicleTrackersWorker);
}
