import { FC } from 'react';
import { MONTHS, WEEKDAYS } from '@common/constants/options';
import { Moment, MomentInput } from 'moment';
import { ILSCalendarPicker, ILSFormItem, ILSTypographyText } from '@common/components';
import { HandleMapDays } from '@components/data-entry/calendar/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/es/form/Form';
import { mapCalendarDayOfShipment } from '@modules/tender-platform/utils/decorators/map-calendar-day-of-shipment';

interface IProps {
  minDate: string;
  maxDate: string;
  disabled: boolean;

  disabledDate(current: Moment): boolean;
}

export const TenderPlatformFormCalendar: FC<IProps> = ({ maxDate, disabledDate, disabled, minDate }) => {
  const form = useFormInstance();
  const numberOfShipments: Array<MomentInput> | undefined = useWatch(TenderFormField.Dates, form);
  const handleMapDays: HandleMapDays = (date) =>
    mapCalendarDayOfShipment(date, {
      disabled,
      disabledDate,
      numberOfShipments
    });

  return (
    <>
      <ILSTypographyText className={'lot-shipping-counter'} children={`Количество перевозок: ${numberOfShipments?.length ?? 0}`} />
      <ILSFormItem rules={[{ required: !disabled, message: 'Пожалуйста, выберите даты' }]} label="Даты" name={TenderFormField.Dates}>
        <ILSCalendarPicker
          shadow={false}
          weekStartDayIndex={1}
          weekDays={WEEKDAYS}
          months={MONTHS}
          numberOfMonths={1}
          mapDays={handleMapDays}
          multiple
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      </ILSFormItem>
    </>
  );
};
