import { ReactNode } from 'react';
import { IndexedArray } from '@common/types';
import { BoardWidgetParams } from '@common/types/dictionaries/analytics';

export type TableContainerProps = {
  tableProps: {
    dataSource: any;
    config: {
      title?: string;
      className?: string;
    };
    showSummary?: ReactNode;
  };
  params: IndexedArray<BoardWidgetParams> | undefined;
  hideWidget: boolean;
  storeParams: IndexedArray<{ name: string; value: string | number | undefined }>;
  loading?: boolean;
  children?: JSX.Element;
  hideBorder?: boolean;
};

export type TableChildProps = {
  tableConfig: {
    dataSource: any;
    config?: any;
    showSummary?: ReactNode;
  };
};

export enum AnalyticsWidgetFieldType {
  ProgressCell = 'ProgressCell',
  TrafficLightCell = 'TrafficLightCell',
  FavoritesCell = 'FavoritesCell'
}

export enum AnalyticsWidgetDataType {
  Number = 'number',
  String = 'string'
}
