import { TPersistTransform } from '@core/persist/transforms';
import initialState from '../store';
import { IStore } from '../types/store';

export const persistTransform: TPersistTransform<IStore> = {
  initialState,
  // transform state being persisted
  onPersist: (inboundState: IStore) => {
    return {
      localConf: inboundState?.localConf ? inboundState.localConf : initialState.localConf
    };
  }
};
