import { FC } from 'react';
import { getNotificationTips } from '@modules/planning/children/by-date/decorators/notice/get-notification-tips';
import { PlanNotice } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { head } from 'lodash';

interface NotificationTipProps {
  ownNotifications: PlanNotice[];
  childNotifications: PlanNotice[];
}

export const NotificationTip: FC<NotificationTipProps> = ({ ownNotifications = [], childNotifications = [] }) => {
  const { notification, childWarnings, existChildNotifications, childErrors } = getNotificationTips({
    ownNotifications,
    childNotifications
  });

  return (
    <>
      {Object.values(notification).map(head)}
      {existChildNotifications && (
        <>
          Во вложенных элементах: {childErrors ? `ошибок: ${childErrors}; ` : EMPTY_STRING}
          {childWarnings ? `предупреждений: ${childWarnings}` : EMPTY_STRING}
        </>
      )}
    </>
  );
};
