import { disabledDate } from '@common/utils/helpers/date-time/date-time';
import { Moment } from 'moment';
import {
  AVAILABLE_DATE_BACKGROUND,
  AVAILABLE_DATE_COLOR,
  UNAVAILABLE_DATE_BACKGROUND,
  UNAVAILABLE_DATE_COLOR
} from '@common/constants/colors/calendar';

export const renderAvailableDates = (current: Moment, availableDates: Array<string>) => {
  const isDisabled = disabledDate(current, availableDates);
  const style: { [k: string]: string } = {};
  if (isDisabled) {
    style.color = UNAVAILABLE_DATE_COLOR;
    style.backgroundColor = UNAVAILABLE_DATE_BACKGROUND;
  } else {
    style.backgroundColor = AVAILABLE_DATE_BACKGROUND;
    style.color = AVAILABLE_DATE_COLOR;
  }

  return (
    <div className="ant-picker-cell-inner" data-testid={`ant-picker-cell-inner-${current.dayOfYear()}`} style={style}>
      {current.date()}
    </div>
  );
};
