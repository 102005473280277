import { TripRecord } from '@modules/planning/children/by-date/types/record';
import { useDispatch, useSelector } from 'react-redux';
import { projectDriverSelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary } from '@common/types';
import { getDriversByTransportCompany } from '@modules/planning/children/by-date/helpers/get-drivers-by-transport-company';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { useEffect } from 'react';
import { changeDriverInTripRoutine } from '@modules/planning/children/by-date/actions';

export const useDriverActions = (record: TripRecord) => {
  const dispatch = useDispatch();
  const drivers = useSelector(projectDriverSelector);
  const { catalogLoad } = useCatalog();

  useEffect(() => {
    catalogLoad(Dictionary.Driver);
  }, []);

  const [transportCompanyID] = Object.keys(record.TransportCompanyEnum ?? {}).map(Number);

  const driversSubMenu = getDriversByTransportCompany({
    drivers,
    transportCompanyID
  });

  const onChangeDriver = ({ driverID }: { driverID: number }) => {
    dispatch(
      changeDriverInTripRoutine({
        tripID: record.ID,
        planID: record?.PlanID,
        vehicleID: record.VehicleID,
        driverID
      })
    );
  };

  return { onChangeDriver, driversSubMenu };
};
