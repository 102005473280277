import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { addEndDepotToTripRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';

export const addEndDepotToTripReducer = createReducer(initialState, {
  [addEndDepotToTripRoutine.SUCCESS]: (
    state,
    {
      payload: { vehicleID, tripID, endDepotID, waypoint }
    }: PayloadAction<{
      tripID: number;
      endDepotID: number;
      vehicleID: number;
      waypoint: WaypointTotal;
    }>
  ) => {
    state.currentPlan.RouteTotal[vehicleID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID][endDepotID] = { ...waypoint, changed: true };
    return state;
  }
});
