import './styles.less';
import { FC } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/style.css';
import cn from 'classnames';

export const ILSPhoneInput: FC<PhoneInputProps> = ({ value = '7', disabled, ...props }) => {
  return (
    <PhoneInput
      disabled={disabled}
      inputClass={'phone-input-number'}
      buttonClass={cn('phone-input-region', { disabled })}
      dropdownClass={'phone-input-region-list'}
      containerClass={'phone-input'}
      searchClass={'phone-input-search'}
      country={'ru'}
      localization={ru}
      value={value}
      {...props}
    />
  );
};
