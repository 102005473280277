import { Vehicle } from '@common/types';

type VehicleTenderSelectType = {
  value: number;
  label: string;
};

export const getVehicleTenderSelect = (vehicle: Vehicle[]) => {
  return vehicle?.reduce((previousValue: VehicleTenderSelectType[], vehicle) => {
    if (vehicle) {
      let profileProperty = [];
      const { Name, RegNumber, ID } = vehicle;
      if (ID) {
        profileProperty.push(Name ? `Модель: ${Name}` : `Автомобиль №${ID}`);
        RegNumber && profileProperty.push('Рег. знак: ' + RegNumber);
        if (vehicle.VehicleProfile) {
          const { MaxMass, MaxVolume } = vehicle.VehicleProfile;
          MaxMass && profileProperty.push('Грузоподъемность: ' + MaxMass + ' кг');
          MaxVolume && profileProperty.push('Объем: ' + MaxVolume + ' м3');
        }
        previousValue.push({ value: ID, label: profileProperty.join(', ') });
      }
      return previousValue;
    }
    return previousValue;
  }, []);
};
