import { ILSUserNotify } from '@common/components';
import { compareAsString } from '@common/utils';
import { useAppSelector } from '@core/hooks';
import { PlanningProgressStatus } from '@modules/planning/children/by-date/constants';
import { FC, useEffect, useState } from 'react';
import { ControlsComponent } from '../components/controls';
import { EMULATION_NOTIFY_FINISH } from '../constants';
import {
  currentEmulationDateTimeStringSelector,
  endEmulationDateTimeStringSelector,
  progressSelector,
  startEmulationDateTimeStringSelector
} from '../selectors';

interface Props {
  createConfig: (emulationSpeed?: number) => void;
}

// Контейнер для элементов управления в Эмуляторе

export const ControlsContainer: FC<Props> = ({ createConfig }) => {
  const startDateTime = useAppSelector(startEmulationDateTimeStringSelector);
  const currentDateTime = useAppSelector(currentEmulationDateTimeStringSelector);
  const endDateTime = useAppSelector(endEmulationDateTimeStringSelector);
  const progress = useAppSelector(progressSelector);

  const controlsProps = { startDateTime, currentDateTime, endDateTime, progress, createConfig };
  const [watchedProgress, setWatchedProgress] = useState(progress);

  //NOTE для вывода уведомления об окончании эмуляции
  useEffect(() => {
    if (watchedProgress !== progress) {
      if (compareAsString(progress, PlanningProgressStatus.CompletePlanning)) {
        ILSUserNotify(...EMULATION_NOTIFY_FINISH);
      }
      setWatchedProgress(progress);
    }
  }, [progress]);

  return <ControlsComponent {...controlsProps} />;
};

