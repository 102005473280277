import { createReducer } from '@reduxjs/toolkit';
import { changeDriverInTripRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { compareAsString, createArrayFromIndexedArray, onFailure, onFulfill, onRequest } from '@common/utils';
import { ChangeDriverInTripPayloadAction } from '@modules/planning/children/by-date/sagas/trip/change-driver-in-trip';
import { Driver } from '@common/types';

export const changeDriverInTripReducer = createReducer(initialState, {
  [changeDriverInTripRoutine.SUCCESS]: (state, { payload: { tripID, driverID, vehicleID } }: ChangeDriverInTripPayloadAction) => {
    const DriverID = [driverID];
    const DriverEnum = {
      [driverID]:
        createArrayFromIndexedArray(state.project?.Driver).find(({ ID }) => compareAsString(ID, driverID))?.Name ?? `Водитель №${driverID}`
    };
    state.currentPlan.RouteTotal[vehicleID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].DriverID = DriverID;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].DriverEnum = DriverEnum;

    const moreThenOneTripInRoute = Object.keys(state.currentPlan.RouteTotal[vehicleID].TripTotal).length > 1;
    const newDriverForRoute = !state.currentPlan.RouteTotal[vehicleID].DriverID.some((id: Driver['ID']) => compareAsString(id, driverID));

    if (moreThenOneTripInRoute && newDriverForRoute) {
      state.currentPlan.RouteTotal[vehicleID].DriverID = state.currentPlan.RouteTotal[vehicleID].DriverID.concat(driverID);
      state.currentPlan.RouteTotal[vehicleID].DriverEnum = { ...state.currentPlan.RouteTotal[vehicleID].DriverEnum, ...DriverEnum };
    } else {
      state.currentPlan.RouteTotal[vehicleID].DriverID = DriverID;
      state.currentPlan.RouteTotal[vehicleID].DriverEnum = DriverEnum;
    }
    return state;
  },
  [changeDriverInTripRoutine.REQUEST]: onRequest,
  [changeDriverInTripRoutine.FAILURE]: onFailure,
  [changeDriverInTripRoutine.FULFILL]: onFulfill
});
