import { DOT, EMPTY_STRING, SPACE } from '@common/constants';
import { useEffect, useRef } from 'react';

type UseVirtualTableSettings = {
  deps?: Array<any>;
  tableContaierClassName?: string;
  rowTableClassName?: string;
  scrollYHeight?: number;
  rowHeight?: number;
};

export const useVirtualTableSettings = ({
  deps = [],
  tableContaierClassName,
  rowTableClassName,
  scrollYHeight,
  rowHeight
}: UseVirtualTableSettings) => {
  const virtualTablebody = useRef<HTMLDivElement | null>(null);
  const virtualRow = useRef<HTMLTableRowElement | null>(null);

  useEffect(() => {
    virtualTablebody.current = document.querySelector(
      `${tableContaierClassName ? DOT + tableContaierClassName + SPACE : EMPTY_STRING}.ant-table-body`
    );
    virtualRow.current = document.querySelector(
      `${rowTableClassName ? DOT + rowTableClassName + SPACE : EMPTY_STRING}.ant-table-body tr.row`
    );
  }, deps);

  const tableHeight =
    (virtualTablebody.current?.clientHeight ?? 0) < (scrollYHeight ?? 0) ? scrollYHeight : virtualTablebody.current?.clientHeight;
  const tableRowHeight = (virtualRow.current?.clientHeight ?? 0) < (rowHeight ?? 0) ? rowHeight : virtualRow.current?.clientHeight;

  const overscanRowCount = Math.ceil((tableHeight ?? 0) / (tableRowHeight ?? 0));

  return { overscanRowCount };
};

