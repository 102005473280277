import { IILSTableComponentProps, Key, TableRecord } from '@common/types';
import { getActiveIndex } from './get-active-index';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { getActiveDataSourceIndexByPage } from './get-active-data-source-index-by-page';

//NOTE +1 нужен для кратных pageSize элементов
// getActiveIndex для 21 записи будет равен 20,
// и при делении на 20 будет 2 страница, но в таблице это третья

/* 
   dataSourceArrayIndex - index строки в контексте массива данных
   getActiveDataSourceIndexByPage - @return index строки в контексте активной страницы
*/
export const getActivePage = (
  dataSource: TableRecord[] | undefined,
  focusRowKey: Key | undefined,
  pageSize: IILSTableComponentProps<TableRecord>['pageSize'] = DEFAULT_PAGE_SIZE
) => {
  const dataSourceArrayIndex = getActiveIndex(dataSource, focusRowKey);
  const focusPage = Math.ceil((dataSourceArrayIndex + 1) / pageSize);
  return { focusPage, focusRowArrayIndex: getActiveDataSourceIndexByPage({ focusPage, pageSize, dataSourceArrayIndex }) };
};
