import { apiInstance } from '@core/api/instance';

export const RoadGraphTest = {
  getShortest: (params: {}) => {
    const api = apiInstance();

    return api.post('/NewTms/MapEditor/getShortest', { ...params });
  },
  getForbiddenZones: (params: {}) => {
    const api = apiInstance();

    return api.post('/NewTms/ForbiddenZones/index', {});
  },
  addForbiddenZone: (params: {}) => {
    const api = apiInstance();

    return api.post('/NewTms/MapEditor/addForbiddenZone', { ...params });
  }
};
