import { DocumentAction, DocumentType } from '@modules/documents/types/document-type';
import { Document } from '@modules/documents/types/document';
import { dateToString } from '@modules/documents/helpers/date-to-string';

export const addOptionsButton = (getItems: any, documents?: Document[], pageType?: DocumentType, isChildren?: boolean) =>
  documents?.map((document) => {
    let documentWithButton;
    const dates = {
      Created: dateToString(document.Created),
      Archive: dateToString(document.Archive),
      Edited: dateToString(document.Edited),
      Deleted: dateToString(document.Deleted)
    };
    if (isChildren) {
      // если children оставляем поля ID TypeName Button Dates
      documentWithButton = {
        ...dates,
        ID: document.ID,
        TypeName: document.TypeName,
        DocumentAttachment: document.DocumentAttachment,
        Button: {
          icon: '/assets/icons/options.svg',
          items: getItems(document.Type, document),
          openKeys: [DocumentAction.Send, DocumentAction.SendItem]
        }
      };
    } else {
      documentWithButton = {
        ...document,
        ...dates,

        Button: {
          icon: '/assets/icons/options.svg',
          items: getItems(pageType || document.Type, document),
          openKeys: [DocumentAction.Send, DocumentAction.SendItem]
        }
      };
    }

    if (Array.isArray(documentWithButton['children'])) {
      const children = documentWithButton['children'];

      documentWithButton['children'] = addOptionsButton(getItems, children, undefined, true);
    }

    return documentWithButton;
  });
