import { IEmulatorStore } from '@modules/emulator/common/types/store';

export const initialState: IEmulatorStore = {
  isFetching: false,
  availableDates: [],
  activeProjectID: null,
  projects: {},
  planData: {
    isFetching: false
  },
  emulatorStatus: {
    lastUpdate: undefined,
    isFetching: false,
    status: undefined,
    command: undefined,
    errorText: undefined,
    startEmulationTime: undefined,
    endEmulationTime: undefined,
    currentEmulationTime: undefined,
    progress: undefined,
    configs: {
      isFetching: false,
      lastUpdate: undefined,
      EmulationSpeed: undefined
    }
  }
};

export default initialState;

