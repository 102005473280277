import { ILSTypographyText } from '@components/index';
import { IILSCellStringProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { isString } from 'lodash';
import { EMPTY_STRING } from '@common/constants';

export const ILSCellString = <R extends TableRecord>({ children, toggleEdit }: IILSCellStringProps<R>): JSX.Element => {
  const [node, title] = children;

  return (
    <ILSTypographyText
      title={isString(title) ? title : EMPTY_STRING}
      children={[node, title]}
      ellipsis
      onClick={toggleEdit}
      className={'cell-wrapper-editable'}
    />
  );
};
