import { hideEmptyColumns, scheme2config } from '@common/utils/table';
import { FC } from 'react';
import { CellType, ColumnTable, FilterCellType, OrderUploadFileType, TableRecord } from '@common/types';
import { ILSPlanningDataViewTable } from '@modules/data-validate/containers/table';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { order2Table } from '@modules/planning/children/data/utils/decorators';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';

export const ILSDataViewOrderTable: FC<IDataTableProps> = ({ dataSource, scheme, config, dictionary, hideEmpty, ...props }) => {
  const { columns } = scheme2config(scheme, dataSource, hideEmpty ?? false);

  const configTable = {
    ...hideEmptyColumns(dataSource, config, hideEmpty ?? false),
    columns: columns.map((column: ColumnTable<TableRecord>, i: number) => {
      const format = column.type === CellType.Date ? ILS_MAIN_INTERFFACE_DATE_FORMAT : undefined;
      return {
        ...column,
        format,
        dateParseFormat: format,
        filtering: column.type?.includes('select') ? FilterCellType.Array : FilterCellType.String,
        sorting: columns.length - i
      };
    })
  };

  return (
    <ILSPlanningDataViewTable
      scheme={scheme}
      hideEmpty={hideEmpty}
      dictionary={dictionary}
      config={configTable}
      testId={'data-order-table'}
      dataSource={order2Table(dataSource as OrderUploadFileType[])}
      {...props}
    />
  );
};
