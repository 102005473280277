import { FC } from 'react';
import { ILSDivider } from '@components/index';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { FakePreCostProposal } from '@modules/orders-and-trips/types/fake-pre-cost';
import { ProposalItemForm } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-item-form';
import { useContractToCreateFromFakeTender } from '@modules/orders-and-trips/hooks/use-contract-to-create-from-fake-tender';
import { ProposalCard } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-card';

interface IProps {
  currency: FakePreCostProposal['currency'];
  alias: FakePreCostProposal['name'];
  description?: FakePreCostProposal['description'];
  cost: FakePreCostProposal['price'];
  id: FakePreCostProposal['id'];
  info: FakePreCostProposal['info'];
  term: FakePreCostProposal['term'];
  section: NewOrderFormTab;
  isAuth: boolean;

  handleModalVisibility(): void;
}

export const ProposalItem: FC<IProps> = ({
  handleModalVisibility,
  alias,
  section,
  cost,
  description,
  id,
  currency,
  term,
  isAuth,
  info
}) => {
  const {
    buttonChildren,
    buttonType,
    handleFormVisibility,
    onFinish,
    showForm,
    confirmModalVisible,
    submitButtonProps,
    handleShowConfirmModal
  } = useContractToCreateFromFakeTender();

  return (
    <li className={'fake-pre-cost__card'}>
      <ProposalCard
        alias={alias}
        term={term}
        description={description}
        cost={cost}
        currency={currency}
        buttonChildren={buttonChildren}
        buttonType={buttonType}
        handleClick={isAuth ? handleFormVisibility : handleModalVisibility}
      />
      {isAuth && showForm && (
        <ProposalItemForm
          alias={alias}
          price={cost}
          info={info}
          confirmModalVisible={confirmModalVisible}
          submitButtonProps={submitButtonProps}
          handleShowConfirmModal={handleShowConfirmModal}
          id={id}
          onFinish={onFinish}
          section={section}
        />
      )}
      <ILSDivider />
    </li>
  );
};
