import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningGetConfigsRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { DefaultPlannerConfigsResponse } from '../../../../types/api';

export const getPlanningConfigsReducer = createReducer(initialState, {
  [planningGetConfigsRoutine.REQUEST]: (state) => {
    state.defaultPlannerConfigs.loading = true;
    return state;
  },
  [planningGetConfigsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: DefaultPlannerConfigsResponse;
    }>
  ) => {
    const { data } = action.payload;
    state.defaultPlannerConfigs.data = data;
    return state;
  },
  [planningGetConfigsRoutine.FULFILL]: (state) => {
    state.defaultPlannerConfigs.loading = false;
    state.defaultPlannerConfigs.lastUpdate = Date.now();
    return state;
  }
});
