import { IILSTableComponentProps, Key, Reference, TableRecord } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { getFocusRefRow } from '@components/data-display/table/utils/helpers/get-focus-ref-row';

export const getInitialActiveRowKey = <R extends TableRecord>({
  focusRow,
  dataSource = [],
  reference = {}
}: {
  dataSource: IILSTableComponentProps<R>['dataSource'];
  focusRow: IILSTableComponentProps<R>['focusRow'];
  reference?: Reference<R>;
}): Key => {
  const [firstRecord] = dataSource;
  const { record, recordDataIndex, cellType } = reference;
  const referenceFocusRow = getFocusRefRow({ record, recordDataIndex, cellType });

  /** приоритетным является фокус с ключем, контролируемый из конкретного модуля контейнером таблицы = focusRow
   *
   * если фокус не управляется контейнером – предполагаем, что таблица вложенная и её фокус контролируется recordDataIndex
   * родительской таблицы = referenceFocusRow
   *
   * в остальных случаях берем ключ первой строки dataSource - firstRecord
   *
   *  если таблица пустая – устанавливаем пустой ключ */
  return focusRow?.key ?? referenceFocusRow ?? firstRecord?.key ?? EMPTY_STRING;
};
