import { IDocumentsPayload } from '@modules/documents/types/document-type';
import { documentCreateDealRoutine, documentsIndexRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';

function* documentCreateDealWorker(action: PayloadAction<{ form: IDocumentsPayload }>) {
  const { request, failure, fulfill } = documentCreateDealRoutine;

  try {
    yield put(request());

    yield call(Documents.createDeal, action.payload.form);

    yield put(documentsIndexRoutine());

    ILSUserNotify(Notify.Success, 'Сделка успешно создана');
  } catch (error) {
    yield put(failure({ error }));
    ILSUserNotify(Notify.Error, error as any, 3);
  } finally {
    yield put(fulfill());
  }
}

export function* documentCreateDealWatcher() {
  yield takeEvery(documentCreateDealRoutine.trigger, documentCreateDealWorker);
}
