import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';

export const PlannerPlanningOpenOrderTableButton: FC<{ handleShowOrderTable: (val: boolean) => void }> = ({ handleShowOrderTable }) => {
  return (
    <ILSButtonTooltip
      icon={'open-orders'}
      onClick={() => handleShowOrderTable(true)}
      title={'Открыть таблицу с нераспределенными заявками'}
    />
  );
};
