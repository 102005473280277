import { compareAsString } from '@common/utils';
import { Dictionary, Vehicle } from '@common/types';
import { PlanResponse } from '@common/types/dictionaries/plan';
import { PlanNotices } from '@modules/planning/children/by-date/types/notice-type';

export const getNotice = ({ planNotice = [], ID }: { ID: Vehicle['ID']; planNotice: PlanResponse['PlanNotice'] }) => {
  const notices = (planNotice ?? []).reduce(
    (notices: PlanNotices, notice) => {
      if (compareAsString(notice.VehicleID, ID)) {
        if (notice.WaypointID) {
          notices[Dictionary.Waypoint].notices.push(notice);
        } else if (notice.TripID) {
          notices[Dictionary.Trip].notices.push(notice);
        } else {
          notices[Dictionary.Route].notices.push(notice);
        }
      }
      return notices;
    },
    {
      [Dictionary.Route]: { notices: [] },
      [Dictionary.Trip]: { notices: [] },
      [Dictionary.Waypoint]: { notices: [] }
    }
  );

  return { notices };
};
