import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { monitoringVehicleDetailsRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringSettingsParameterShowAllVehiclesSelector } from '@modules/settings/selectors/monitoring';

type VehicleDetailsRequest = {
  from: string;
  till: string;
};

// Получает детализированную информацию по автомобилю (data)
function* monitoringVehicleDetailsWorker(action: PayloadAction<VehicleDetailsRequest>) {
  const { request, success, failure, fulfill } = monitoringVehicleDetailsRoutine;
  const { from, till } = action.payload;
  const showAllVehicles: number = yield select(monitoringSettingsParameterShowAllVehiclesSelector);

  try {
    yield put(request({ from, till }));

    const { data } = yield call(MonitoringAPI.getEventDetails, { from, till, showAllVehicles });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringVehicleDetailsWatcher() {
  yield takeLatest(monitoringVehicleDetailsRoutine.trigger, monitoringVehicleDetailsWorker);
}
