import { IVehicleMonitoringState } from '@modules/vehicle-monitoring/types/store';

const initialState: IVehicleMonitoringState = {
  data: null,
  isFetching: false,
  error: [],
  lastUpdate: null
};

export default initialState;
