import { EnhancedStore } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { createPersistedReducer } from './create-persisted-reducer';
import { createPersistor } from './create-persistor';
import { PersistConfigModified } from './PersistConfigModified';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { GetDefaultMiddlewareOptions } from '@core/types/redux';
import { getWrapper } from './Wrapper';

export const createPersistedStore = <S>(
  reducer: Reducer,
  createStore: (reducer: Reducer, options: any) => EnhancedStore,
  persistConfig: PersistConfigModified<S>
) => {
  const persistedReducer = createPersistedReducer<S>(persistConfig, reducer);
  const options: GetDefaultMiddlewareOptions = {
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  };
  const store = createStore(persistedReducer, options);
  const persistor = createPersistor<S>(store);

  const onModuleChange = (currentReducersKeys: string[]) => {
    persistConfig.keys = currentReducersKeys;
    persistor.persist({ force: true });
  };

  const Wrapper = getWrapper(persistor);

  return { store, onModuleChange, Wrapper, persistor };
};

