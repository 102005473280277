import { SAVED_PARAMS } from '@common/constants/url';

/**
 * Очищает параметры, передавать search параметры из URL,
 * можно дополнить разрешенные параметры, а также указать список параметров, которые нужно исключить
 * вернет строку для подстановки
 * @param search
 * @param allowList
 * @param exclude
 */

export const searchParamsFilter = (search: string | undefined | null, allowList?: string[], exclude?: string[]) => {
  if (!search || search.length === 0) return '';
  const ulr = new URLSearchParams(search);
  const newUrl = new URLSearchParams();
  SAVED_PARAMS.forEach((key) => {
    let item = ulr.get(key);
    if (item !== undefined && item !== null) {
      newUrl.append(key, item);
    }
  });
  if (allowList) {
    allowList.forEach((key) => {
      let item = ulr.get(key);
      if (item !== undefined && item !== null) {
        newUrl.append(key, item);
      }
    });
  }
  if (exclude) {
    exclude.forEach((key) => {
      let item = newUrl.get(key);
      if (item !== undefined && item !== null) {
        newUrl.delete(key);
      }
    });
  }
  return newUrl.toString().length ? `?${newUrl.toString()}` : '';
};
