import { useExternalTransportContract } from '@modules/orders-and-trips/hooks/use-external-transport-contract';
import { useDispatch } from 'react-redux';
import { getFakeTripContractRoutine } from '@modules/orders-and-trips/actions';
import { FakeTripContractValues } from '@modules/orders-and-trips/types/fake-pre-cost';

export const useTemplateContractToCreateFromFakeTender = () => {
  const dispatch = useDispatch();

  const { isFetching, contractTemplate } = useExternalTransportContract();

  const getTemplateContractToCreateFromFakeTender = (payload: FakeTripContractValues) => {
    dispatch(getFakeTripContractRoutine(payload));
  };

  return { isFetching, contractTemplate, getTemplateContractToCreateFromFakeTender };
};
