import { ILSTabPane, ILSTabs } from '@common/components';
import { EmptyComponent } from '@modules/orders-and-trips/components/empty-component';
import { RIGHT_BLOCK_TAB_CONTAINERS } from '@modules/orders-and-trips/constants/display-blocks';
import { useRightTabsContainer } from '@modules/orders-and-trips/hooks/use-right-tabs-container';
import { FormType, RightMenuMainTabs } from '@modules/orders-and-trips/types/general';
import { FC } from 'react';

interface IProps {
  showForm: FormType | null;
}

export const RightTabsContainer: FC<IProps> = ({ showForm }) => {
  const { activeTab, setActiveTab, activeTrip, ACTIVE_TABS } = useRightTabsContainer();

  if (showForm) return null;

  if (!activeTrip) return <EmptyComponent />;

  return (
    <ILSTabs
      type="card"
      destroyInactiveTabPane
      className="right-container"
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key as RightMenuMainTabs)}
    >
      {Object.keys(ACTIVE_TABS).map((tabKey) => {
        const TabComponent = RIGHT_BLOCK_TAB_CONTAINERS[tabKey];
        return (
          <ILSTabPane tab={ACTIVE_TABS[tabKey]} key={tabKey}>
            <TabComponent />
          </ILSTabPane>
        );
      })}
    </ILSTabs>
  );
};
