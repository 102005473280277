import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { roadGraphSetSegmentRoutine } from '../actions';
import { RoadGraphEditor } from '../api';

import { PayloadAction } from '@reduxjs/toolkit';

function* roadGraphSetSegmentWorker(
  action: PayloadAction<{
    values: {};
    roads: string;
    points: string;
  }>
) {
  const { request, failure, success, fulfill } = roadGraphSetSegmentRoutine;
  const { values, roads, points } = action.payload;
  try {
    yield put(request());
    yield call(RoadGraphEditor.setSegment, { ...values });
    yield call(RoadGraphEditor.getSegments, { points, roads });
    yield put(success({ values, roads }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    delay(3000);
    yield put(fulfill());
  }
}

export function* roadGraphSetSegmentWatcher() {
  yield takeLatest(roadGraphSetSegmentRoutine.trigger, roadGraphSetSegmentWorker);
}
