import { Dictionary, Keys, TableRecord } from '@common/types';
import { isEmpty } from 'lodash';

export const getIdsFromNotDeliveredOrderTableByDictionary = (selectedRowKeys: Keys, selectedRows: TableRecord[]) => {
  if (!isEmpty(selectedRowKeys) && isEmpty(selectedRows)) {
    return {
      [Dictionary.Order]: selectedRowKeys
    };
  }

  return selectedRows?.reduce(
    (result, row) => {
      if (row.dictionary === Dictionary.Order && row.ID) {
        result[Dictionary.Order].push(row.ID);
      }
      return result;
    },
    {
      [Dictionary.Order]: []
    } as { [Dictionary.Order]: number[] }
  );
};

