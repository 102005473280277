import { CellType, DateFormat, FilterCellType, ShowDeleted, TableColumnName, TableConfig } from '@common/types';
import { DRIVER_OPTIONS, NEW_DRIVER } from '@common/models/driver/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const DRIVER_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  export: true,
  copying: true,
  multiEditing: true,
  reloading: true,
  adding: NEW_DRIVER,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Код',
      dataIndex: TableColumnName.DriverCode,
      key: TableColumnName.DriverCode,
      editable: true,
      active: false,
      type: CellType.Input,
      sorting: 2,
      ellipsis: true,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'ФИО водителя',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 1,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Телефон водителя',
      dataIndex: TableColumnName.Phone,
      key: TableColumnName.Phone,
      editable: true,
      active: true,
      type: CellType.Phone,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Удалён',
      dataIndex: TableColumnName.Deleted,
      key: TableColumnName.Deleted,
      invisible: true,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 1
    },
    {
      title: 'Паспорт',
      dataIndex: TableColumnName.PassportNumber,
      key: TableColumnName.PassportNumber,
      active: false,

      editable: true,
      ellipsis: true,
      sorting: 1,
      type: CellType.Input,

      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Дата выдачи',
      dataIndex: TableColumnName.PassportDate,
      key: TableColumnName.PassportDate,
      dateParseFormat: DateFormat.YYYYMMDDDash,
      active: false,

      editable: true,
      ellipsis: true,
      type: CellType.Date,
      sorting: 31,
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Кем выдан паспорт',
      dataIndex: TableColumnName.PassportIssuedBy,
      key: TableColumnName.PassportIssuedBy,
      active: false,

      editable: true,
      sorting: 1,
      ellipsis: true,
      type: CellType.Input,

      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Водительское удостоверение',
      dataIndex: TableColumnName.LicenseNumber,
      key: TableColumnName.LicenseNumber,
      active: false,

      editable: true,
      ellipsis: true,
      sorting: 1,
      type: CellType.Input,

      filtering: FilterCellType.String,
      grouping: true
    },
    //Добавить столбец "Мобильное приложение" (TMS-5757)
    {
      title: 'Мобильное приложение',
      dataIndex: TableColumnName.MobileApplication,
      key: TableColumnName.MobileApplication,
      active: false,

      // Данное поле не доступно для редактирования. (TMS-5757)
      editable: false,
      ellipsis: true,
      sorting: 1,
      type: CellType.Bool,

      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Название расписания работы',
      dataIndex: TableColumnName.DriverTimelineID,
      key: TableColumnName.DriverTimelineID,
      active: true,

      editable: true,
      ellipsis: true,
      sorting: 1,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Тип',
      dataIndex: TableColumnName.Type,
      key: TableColumnName.Type,
      active: true,

      editable: true,
      sorting: 1,
      ellipsis: true,
      type: CellType.Select,
      options: DRIVER_OPTIONS,
      fieldNames: BASE_FIELD_NAMES,

      filtering: FilterCellType.Array,
      grouping: true
    },
    {
      title: 'Специфики',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: false,

      sorting: 1,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Транспортная компания',
      dataIndex: TableColumnName.TransportCompanyID,
      key: TableColumnName.TransportCompanyID,
      editable: true,
      active: false,
      type: CellType.SelectAPI,

      sorting: 2,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Зоны доставки',
      dataIndex: TableColumnName.ZoneID,
      key: TableColumnName.ZoneID,
      active: false,

      editable: true,
      sorting: 1,
      ellipsis: true,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Машины',
      dataIndex: TableColumnName.VehicleID,
      key: TableColumnName.VehicleID,
      active: true,

      sorting: 1,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Приоритет',
      dataIndex: TableColumnName.Priority,
      key: TableColumnName.Priority,
      active: false,

      sorting: 1,
      editable: true,
      ellipsis: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Режим работы авто',
      titleTooltip: 'Значения в этой колонке переопределяют режим работы авто, который указан в таблице "Парк машин"',
      dataIndex: TableColumnName.VehicleTimelineID,
      key: TableColumnName.VehicleTimelineID,
      active: false,

      editable: true,
      sorting: 1,
      ellipsis: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Склад',
      dataIndex: TableColumnName.WarehouseID,
      key: TableColumnName.WarehouseID,
      active: false,

      sorting: 1,
      editable: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Шаблоны рейсов',
      dataIndex: TableColumnName.TripTemplateID,
      key: TableColumnName.TripTemplateID,
      active: false,

      editable: true,
      sorting: 1,
      ellipsis: true,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Кол-во рейсов',
      dataIndex: TableColumnName.DriverRoutes,
      key: TableColumnName.DriverRoutes,
      active: false,
      editable: false,
      sorting: 1,
      ellipsis: true,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: '',
      titleText: 'Рейтинг водителя',
      titleTooltip: 'Рейтинг водителя',
      titleIcon: 'driver-rating',
      dataIndex: TableColumnName.DriverRating,
      key: TableColumnName.DriverRating,
      active: false,

      editable: false,
      ellipsis: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    }
  ]
};

export type DriverColumns =
  | TableColumnName.ID
  | TableColumnName.DriverCode
  | TableColumnName.Name
  | TableColumnName.Phone
  | TableColumnName.Deleted
  | TableColumnName.PassportNumber
  | TableColumnName.PassportDate
  | TableColumnName.PassportIssuedBy
  | TableColumnName.LicenseNumber
  | TableColumnName.DriverTimelineID
  | TableColumnName.Type
  | TableColumnName.RestrictionID
  | TableColumnName.ZoneID
  | TableColumnName.VehicleID
  | TableColumnName.Priority
  | TableColumnName.VehicleTimelineID
  | TableColumnName.WarehouseID
  | TableColumnName.TripTemplateID
  | TableColumnName.DriverRoutes
  | TableColumnName.TransportCompanyID;
