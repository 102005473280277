import { createReducer } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-search/store';
import { tenderOfferIndexRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderOfferDeleteReducer = createReducer(initialState, {
  [tenderOfferIndexRoutine.REQUEST]: onRequest,
  [tenderOfferIndexRoutine.FAILURE]: onFailure,
  [tenderOfferIndexRoutine.FULFILL]: onFulfill
});
