import { apiInstance } from '@core/api/instance';
import { IPlanningAPI } from '@modules/planning/types/api';
import { Action, DictionariesEndpoint, Dictionary } from '@common/types';
import { getAPIURL } from '@common/api/helpers';
import { ContentType } from '@common/types/general';

export const PlanningAPI: IPlanningAPI = {
  getTotal: ({ dictionary, ...params }) => {
    return apiInstance().post(getAPIURL(Dictionary.Planning, `get${dictionary}Total` as DictionariesEndpoint), params);
  },
  update: (params) => {
    const { planID, field, value } = params;
    const api = apiInstance();

    return api.post('/NewTms/PlanManager/changePlanManager', { planID, field, value });
  },
  getProjects: ({ from, till }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetProjects), { from, till }),
  getProject: ({ projectID = undefined }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetProject), { projectID }),
  getPlan: ({ ID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetPlan), { ID }),
  getPlanEfficiency: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetPlanEfficiency), params),
  validate: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.Validate), params),
  startPlanning: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.StartPlanning), params),
  checkPlanning: ({ plans, projectID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.checkPlanning), { plans, projectID }),
  donePlanning: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.donePlanning), { planID }),
  cancelPlanning: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.cancelPlanning), { planID }),
  enoughPlanning: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.enoughPlanning), { planID }),
  createPlan: ({ projectID, name }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.createPlan), { projectID, name }),
  delete: ({ dictionary, ...params }) => {
    let actionName;
    switch (dictionary) {
      case Dictionary.Trip:
        actionName = Dictionary.Trips;
        break;
      case Dictionary.Waypoint:
        actionName = Dictionary.Waypoints;
        break;
      case Dictionary.Route:
        actionName = Dictionary.Route;
        break;
      default:
        actionName = dictionary;
    }
    actionName = `${Action.Delete}${actionName}`;
    return apiInstance().post(getAPIURL(Dictionary.Planning, actionName as DictionariesEndpoint), params);
  },
  copyPlan: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.copyPlan), { planID }),
  clearPlan: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.clearPlan), { planID }),
  setTripStatus: ({ status, tripIDs }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.setTripStatus), { status, tripIDs }),
  setForExecution: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.setForExecution), { planID }),
  unsetForExecution: ({ planID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.unsetForExecution), { planID }),
  addEndDepotToTrip: ({ tripID, endDepotID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.addEndDepotToTrip), {
      tripID,
      endDepotID
    }),
  moveWaypointToTrip: ({ tripID, waypointID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.moveWaypointToTrip), {
      tripID,
      waypointID
    }),
  moveWaypointInTrip: ({ tripID, sourceWaypointIDs, targetWaypointID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.moveWaypointInTrip), {
      tripID,
      sourceWaypointIDs,
      targetWaypointID
    }),
  createTrip: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.createTrip), params),
  changeVehicle: ({ oldVehicleID, newVehicleID, planID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.changeVehicle), {
      oldVehicleID,
      newVehicleID,
      planID
    }),
  exchangeVehicles: ({ sourceVehicleID, targetVehicleID, planID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.exchangeVehicles), {
      sourceVehicleID,
      targetVehicleID,
      planID
    }),
  unionTrips: ({ sourceVehicleID, targetVehicleID, planID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.unionTrips), {
      sourceVehicleID,
      targetVehicleID,
      planID
    }),
  addOrderForVehicle: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.addOrderForVehicle), params),
  getPlanningConfigs: () => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetPlanningConfigs), {}),
  getTrack: ({ points }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.getTrack), { points }),
  getRoute: ({ planID, vehicleID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.getRoute), { planID, vehicleID }),
  getTrip: ({ ID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.getTrip), { ID }),
  getWaypoint: ({ ID }) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.getWaypoint), { ID }),
  changeDriverInTrip: ({ driverID, tripID }) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.changeDriverInTrip), {
      id: tripID,
      driverId: driverID
    }),
  changeDriverInRoute: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.changeDriverInRoute), params),
  changeExpeditorInRoute: (params) =>
    apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.changeExpeditorInRoute), params),
  changeExpeditorInTrip: (params) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.changeExpeditorInTrip), params),
  deleteTenderFromPlanner: ({ ids }) =>
    apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.deleteFromRoute), { data: { ids } }),

  //TODO отдельный API
  getSharedLink: ({ tripID, link }) =>
    apiInstance().post(getAPIURL(Dictionary.VehicleMonitoring, DictionariesEndpoint.getSharedLink), { tripID, link }),
  sendObserverLinks: ({ planID, link }) =>
    apiInstance().post(getAPIURL(Dictionary.VehicleMonitoring, DictionariesEndpoint.sendObserverLinks), {
      planID,
      link
    }),
  dataExport: ({ planID, file, format }) =>
    apiInstance().post(
      getAPIURL(Dictionary.Data, DictionariesEndpoint.Export),
      {
        planID,
        file,
        format
      },
      { responseType: 'blob' }
    ),
  getPlannerParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/index', JSON.stringify(values)),
  createPlannerParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/create', JSON.stringify(values)),
  mergePlannerParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/merge', JSON.stringify(values))
};

