import { ILSButton } from '@components/index';
import { FC } from 'react';

interface Props {
  startStopCaption: string;
  pauseResumeCaption: string;
  startStopIsDisabled: boolean;
  pauseResumeIsDisabled: boolean;
  handleClickStart: () => void;
  handleClickPause: () => void;
}

// Компонент для отображения кнопок старта / остановки и паузы / возобновления работы
const ILSActionButtonsComponent: FC<Props> = ({
  startStopCaption,
  pauseResumeCaption,
  startStopIsDisabled,
  pauseResumeIsDisabled,
  handleClickStart,
  handleClickPause
}) => {
  return (
    <>
      <ILSButton type="primary" disabled={startStopIsDisabled} className="mt-3" onClick={handleClickStart}>
        {startStopCaption}
      </ILSButton>
      <ILSButton type="primary" disabled={pauseResumeIsDisabled} className="mt-3 ml-3" onClick={handleClickPause}>
        {pauseResumeCaption}
      </ILSButton>
    </>
  );
};

export default ILSActionButtonsComponent;
