import { CellType } from '@common/types';
import { ArgsHandleSave, GetComponentProps, TableRecord } from '@common/types/components/table/common';
import { SelectedRowKeys } from '@common/types/components/table/table';
import { ILS_MAIN_INTERFFACE_TIME_FORMAT } from '@common/types/general/date-time';
import { timeToDouble } from '@common/utils';
import moment from 'moment';

type Arg = {
  value: any;
  type: string;
  format?: any;
};

type ReturnCellType =
  | {
      type: CellType;
      format?: string;
      handleSave?: (...args: ArgsHandleSave) => void;
    }
  | undefined;

type MultipleCellDataProps = {
  onCell: GetComponentProps<TableRecord> | undefined;
  index: number;
  selectedRowKeys: SelectedRowKeys;
  dataIndex: string;
  multipleCellData: Object;
};

//NOTE Преобразует данные для сохранения обратно в данные для использования в ячейке
//TODO дополнительно добавить преобразования для других типов
export const savedCellToCell = ({ value, type, format }: Arg) => {
  switch (type) {
    case CellType.TimeDouble:
      return format ? timeToDouble(moment(value, format).format(ILS_MAIN_INTERFFACE_TIME_FORMAT)) : timeToDouble(value);
    default:
      return value;
  }
};

export const getMultipleCellData = ({ onCell, index, selectedRowKeys, dataIndex, multipleCellData }: MultipleCellDataProps) => {
  const record = {
    ...multipleCellData[dataIndex],
    ID: [...selectedRowKeys],
    key: [...selectedRowKeys],
    dataIndex
  };
  return {
    cell: onCell?.({ ...record }, index) as ReturnCellType,
    record
  };
};

