import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSDropdownButton, ILSTabPane, ILSTabs } from '@common/components';
import { DocumentType, modalTemplateTitle } from '@modules/documents/types/document-type';
import { documentTypeOptions } from '@modules/documents/constants/document-type-options';
import { createButtonItems } from '@modules/documents/constants/dropdownButton-items';
import { MenuInfo } from 'rc-menu/lib/interface';
import { getFormFieldsRoutine } from '@modules/documents/children/upload/actions';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { ModulePath } from '@common/types';
import { getTableConfigSelector, getDocumentType } from '@modules/documents/selectors';
import { documentsIndexRoutine, getDocumentMetricsRoutine, setDocumentType } from '@modules/documents/actions';
import ILSDocumentsTable from '@modules/documents/components/documents-table';
import ViewDocumentModal from '@modules/documents/components/view-document-modal';
import DependentListModal from '@modules/documents/components/dependent-list-modal';
import { useSearchParams } from '@common/hooks/history/use-search-params';

const ILSDocumentTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useAppHistory();
  const active = useAppSelector(getDocumentType);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const queryhDocumentType = useSearchParams('type');
  let { current: searchDocumentType } = useRef<string | null>(null);
  const tableConfig = useAppSelector(getTableConfigSelector);

  useEffect(() => {
    searchDocumentType = queryhDocumentType;
    const currentDocType = Object.values(DocumentType).includes(searchDocumentType as DocumentType)
      ? (searchDocumentType as DocumentType)
      : DocumentType.Deal;
    dispatch(setDocumentType(currentDocType));
    dispatch(getDocumentMetricsRoutine({ type: searchDocumentType ?? currentDocType }));
    searchDocumentType = null;
  }, []);

  const goToDocumentUpload = (documentType: DocumentType) => {
    dispatch(getFormFieldsRoutine(documentType));
    push(`${ModulePath.Documents}${ModulePath.DocumentsUpload}`);
  };

  const onMenuClick = (menuInfo: MenuInfo) => {
    setSelectedMenuItem(menuInfo.key);

    if (menuInfo.key.startsWith('upload')) {
      goToDocumentUpload(menuInfo.keyPath[1] as DocumentType);
    }

    if (menuInfo.key === DocumentType.Custom) {
      goToDocumentUpload(DocumentType.Custom);
    }

    if (modalTemplateTitle[menuInfo.key]) {
      setIsModalVisible(true);
    }
  };

  const setActive = (documentType: DocumentType) => {
    dispatch(documentsIndexRoutine({ documentType, loadMetrics: true }));
  };

  const createDropdownButton = <ILSDropdownButton text="Создать" items={createButtonItems} onMenuClick={onMenuClick} />;

  return (
    <>
      <ILSTabs activeKey={active} onChange={(tab) => setActive(tab as any)} tabBarExtraContent={createDropdownButton}>
        {documentTypeOptions.map((o) => (
          <ILSTabPane tab={o.label} key={o.value} />
        ))}
      </ILSTabs>

      {/* <div
        style={{
          overflow: 'hidden'
        }}
      > */}
      <ILSDocumentsTable key={active} tableName={active} tableConfig={tableConfig} />
      {/* </div> */}

      <DependentListModal
        selectedMenu={selectedMenuItem}
        setActive={setActive}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <ViewDocumentModal />
    </>
  );
};

export default ILSDocumentTypeSelect;

