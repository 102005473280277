import { Alert } from 'antd';
import { FC } from 'react';
import { Notify } from '@common/types';
import { AlertProps } from 'antd/lib/alert';
import { ILSSVG } from '@components/custom/icons/svg';
import { AlertNotify } from '@common/types/general/conditions';

export interface IILSAlertProps extends Omit<AlertProps, 'type'> {
  type?: AlertNotify;
}

export const ILSAlert: FC<IILSAlertProps> = ({ type, ...props }) => {
  if (type === Notify.Bad) {
    return <Alert {...props} type={Notify.Error} icon={<ILSSVG icon={'bad'} />} />;
  }

  return <Alert type={type} {...props} />;
};
