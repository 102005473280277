import { ILSSpinCenter } from '@components/index';
import { FC } from 'react';
import { User } from '@common/types';
import { useSelector } from 'react-redux';
import { usersPaneSelector } from '@modules/account/selectors';
import { getUserRolesString } from '@modules/account/utils/decorators/get-user-roles-string';
import { getUserAvatar } from '@modules/account/utils';
import { AccountUserCardComponent } from '@modules/account/components/user/user-card';
import { compareAsString } from '@common/utils';

interface Props {
  activeUserID?: User['ID'];
  toggleForm: (show: boolean, user: null | User) => any;
}

export const AccountUsersCardsComponent: FC<Props> = ({ activeUserID, toggleForm }) => {
  const { users, currentUserID, isLoading } = useSelector(usersPaneSelector);

  if (isLoading) {
    return <ILSSpinCenter />;
  }

  return (
    <div className={'users'}>
      {users.map((user) => {
        const { Name, Login, Phone, ID, UserRole, Attachments } = user;
        const roles = getUserRolesString(UserRole);
        const avatar = getUserAvatar(Attachments);
        const isCurrentUserCard = compareAsString(currentUserID, ID);
        const active = compareAsString(activeUserID, ID);

        return (
          <AccountUserCardComponent
            key={`user_${ID}`}
            user={user}
            userID={ID}
            name={Name}
            email={Login}
            phone={Phone}
            roles={roles}
            avatar={avatar}
            isCurrentUserCard={isCurrentUserCard}
            active={active}
            toggleForm={toggleForm}
          />
        );
      })}
    </div>
  );
};
