import { passwordChangeRoutine } from '@modules/settings/actions';
import { PassWordChange } from '@modules/settings/types';
import { useDispatch } from 'react-redux';

export const usePasswordChange = () => {
  const dispatch = useDispatch();

  const onPasswordChange = ({ newPassword, oldPassword }: PassWordChange) => {
    const payload = { newPassword, currentPassword: oldPassword };
    dispatch(passwordChangeRoutine(payload));
  };

  return { onPasswordChange };
};
