import { FC, useMemo } from 'react';
import { IILSSearchAutoCompleteProps, ILSSearchAutoComplete } from '@components/data-entry/autocomplete/search';
import { useSelector } from 'react-redux';
import { addressSuggestionAutocompleteSelector } from '@common/feature/suggestion-autocomplete/address/selectors/selector';
import { DEFAULT_SUGGESTION_AUTOCOMPLETE_OPTIONS } from '@common/feature/suggestion-autocomplete/address/constants';

interface IProps extends IILSSearchAutoCompleteProps {
  hasDefaultOptions?: boolean;
}

export const ILSAddressSuggestionAutocomplete: FC<IProps> = ({ hasDefaultOptions = true, ...props }) => {
  const { addressOptions, isLoading } = useSelector(addressSuggestionAutocompleteSelector);
  const options = useMemo(
    () => addressOptions ?? (hasDefaultOptions ? DEFAULT_SUGGESTION_AUTOCOMPLETE_OPTIONS : undefined),
    [addressOptions]
  );

  return <ILSSearchAutoComplete status={isLoading ? 'warning' : undefined} options={options} {...props} />;
};
