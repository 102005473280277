import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentApi } from '@common/feature/payment/api';
import { getBalanceHistoryRoutine } from '@common/feature/payment/actions';

function* getBalanceHistoryWorker() {
  const { request, success, failure, fulfill } = getBalanceHistoryRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PaymentApi.getBalanceHistory);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getBalanceHistoryWatcher() {
  yield takeLatest(getBalanceHistoryRoutine.trigger, getBalanceHistoryWorker);
}

