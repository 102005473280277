import { getDecoratedOrders } from '@common/models/order/decorators/get-decorated-orders';
import { TableColumnName } from '@common/types';
import { ReduceNumberOperand } from '@common/models/order/constants';

export const countOrdersColumnTotalByName = ({
  orders,
  column,
  operand
}: {
  orders: ReturnType<typeof getDecoratedOrders>;
  column: TableColumnName;
  operand?: ReduceNumberOperand;
}): number => {
  const result = orders.reduce((result, order) => {
    return result + order[column];
  }, 0);
  switch (operand) {
    case ReduceNumberOperand.Ceil:
      return Math.ceil(result);
    case ReduceNumberOperand.Fixed:
      return +result.toFixed(2);
    case ReduceNumberOperand.Floor:
      return Math.floor(result);
    default:
      return result;
  }
};
