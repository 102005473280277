import { Dictionary, IILSTableComponentProps, PlanStatus, TableColumnName } from '@common/types';
import { RoutesTableRecord } from '@modules/planning/children/by-date/types';
import { EDITABLE_STATUSES, NOT_EDITABLE_ROW_DICTIONARIES } from '@modules/planning/children/by-date/constants';

export const canEditRoutes =
  ({ refData }: { refData: IILSTableComponentProps<RoutesTableRecord>['refData'] }) =>
  (record: RoutesTableRecord, dataIndex: TableColumnName): boolean => {
    if (NOT_EDITABLE_ROW_DICTIONARIES.includes(record.dictionary ?? Dictionary.Empty)) return false;
    if (Object.keys(refData ?? {}).includes(dataIndex)) return true;

    switch (dataIndex) {
      case TableColumnName.Status:
        return EDITABLE_STATUSES.includes(record.Status.toString() as PlanStatus);
      case TableColumnName.Comment:
        return record.dictionary === Dictionary.Trip;
      default:
        return false;
    }
  };
