import { Keys, MapMarkerSelectType, MarkerPlanning, MarkerType } from '@common/types';
import { orderMarker, pointMarker } from '@core/components/map/icons';
import { SelectedOrders } from '@modules/planning/types/active-items';
import { UnresolvedMarkerSourceValue } from '@modules/settings/types/planning';
import L from 'leaflet';
import { useEffect } from 'react';
import { MapMarkerPlanning, PolylineItem, SelectedOrdersLasso } from '../../types/map-container';
import { useCheckTypeResolved } from './map-check-selected-callback';
import { DEFAULT_ORDER_COLOR } from '@common/constants';
import { checkIfMarkerIsSelected } from '@modules/planning/children/by-date/utils/check-unresolverd-selected-type';
import { getSelectedTypeResolved } from '@modules/planning/children/by-date/utils/check-resolved-selected-type';

export const useOrdersFocus = (
  // focusOrders: ActiveOrders[] | undefined,
  mapMarkers: Record<
    string,
    {
      mark: MarkerPlanning;
      lMark: L.Marker;
      layer: L.MarkerClusterGroup | undefined;
    }
  >,
  mapPolylines: Record<string, PolylineItem>,
  selectedOrdersLasso: SelectedOrdersLasso,
  selectedOrders: SelectedOrders,
  selectedOnTarget: UnresolvedMarkerSourceValue
) => {
  const checkTypeResolved = useCheckTypeResolved(selectedOnTarget, selectedOrders);

  useEffect(() => {
    const selectedOrder = selectedOrdersLasso?.orderIDs;
    const refreshMarkers: L.Marker[] = [];
    //По нераспределенным заявкам
    Object.keys(mapMarkers).forEach((markerKey) => {
      const element = mapMarkers[markerKey];
      const item = element?.mark;

      const curSelected = checkIfMarkerIsSelected(element.lMark);

      if (selectedOrder?.length) {
        if (item?.type === MarkerType.Order && item.data?.ID) {
          const selected = isSelectedUnResolvedMarker(item, selectedOrder, selectedOrders);
          if (selected && !curSelected) {
            updateOrderMarkerIcon(item, selected, refreshMarkers, element);
          } else if (!selected && curSelected) {
            updateOrderMarkerIcon(item, selected, refreshMarkers, element);
          }
        }
      } else {
        //NOTE clear all selected
        if (curSelected && item.data) {
          const newIcon = orderMarker(item.color ?? DEFAULT_ORDER_COLOR, false, String(item.data?.ID), item.data);
          if (element.lMark.isPopupOpen()) {
            element.lMark.closePopup();
          }
          refreshMarkers.push(element.lMark);
          element.lMark.setIcon(newIcon);
        }
      }
    });
    if (mapMarkers && Object.values(mapMarkers).length) {
      if (Object.values(mapMarkers)[0].layer) {
        Object.values(mapMarkers)[0].layer?.refreshClusters(refreshMarkers);
      }
    }
  }, [ mapMarkers, selectedOrders]);

  useEffect(() => {
    //По заявкам в трекам
    Object.keys(mapPolylines).forEach((trackKey) => {
      const polyline = mapPolylines[trackKey];
      if (polyline?.lMarker) {
        const item = polyline.lMarker;
        const mItem: MarkerPlanning | undefined = polyline.poly?.point;
        const curSelected = getSelectedTypeResolved(item);

        if (selectedOrders?.length) {
          const itemData = item.options.data;

          if (mItem && item.options?.data?.type === MarkerType.Waypoint && selectedOrdersLasso) {
            const selected: MapMarkerSelectType = checkTypeResolved(itemData);
            // let selected: MapMarkerSelectType = orders.findIndex((orS) => selectedOrders && selectedOrders.includes(orS));
            if (curSelected !== selected) {
              updateWaypointMarkerIcon(mItem, item, selected);
            }
          }
        } else {
          //@todo clear all selected
          if (curSelected !== MapMarkerSelectType.None && item.options.data && mItem) {
            updateWaypointMarkerIcon(mItem, item, MapMarkerSelectType.None);
          }
        }
      }
    });
  }, [selectedOrders, mapPolylines, checkTypeResolved]);
};

const isSelectedUnResolvedMarker = (marker: MarkerPlanning, selectedOrderFromSummary?: Keys, selectedOrders?: SelectedOrders) => {
  return Boolean(
    marker.data?.ID && selectedOrderFromSummary?.includes(marker.data.ID) && selectedOrders?.find((itemID) => marker?.data?.ID === itemID)
  );
};

const updateOrderMarkerIcon = (
  marker: MarkerPlanning,
  selected: boolean,
  refreshMarkers: L.Marker[],
  element: {
    mark: MarkerPlanning;
    lMark: L.Marker;
    layer: L.MarkerClusterGroup | undefined;
  }
) => {
  const newIcon = orderMarker(marker.color ?? DEFAULT_ORDER_COLOR, selected, String(marker.data?.ID), marker.data);
  refreshMarkers.push(element.lMark);
  element.lMark.setIcon(newIcon);
};

const updateWaypointMarkerIcon = (waypointMarker: MarkerPlanning, mapMarker: MapMarkerPlanning, selected: MapMarkerSelectType) => {
  const newIcon = pointMarker(
    waypointMarker.data,
    waypointMarker.color as string,
    waypointMarker.number as number | string,
    waypointMarker.figure,
    selected,
    String(mapMarker.options?.data?.ID),
    waypointMarker.isHome,
    waypointMarker.homeNumber
  );
  mapMarker.setIcon(newIcon);
};
