import { FC } from 'react';
import { IButtonProps, ILSButton } from '@components/general/buttons';
import { useSelector } from 'react-redux';
import { fetchSelector } from '@modules/tender-platform/selectors';

interface IProps extends IButtonProps {}

export const TenderPlatformMenuButton: FC<IProps> = ({ ...props }) => {
  const isFetching = useSelector(fetchSelector);

  return <ILSButton loading={isFetching} disabled={isFetching} className="menu-button" type="primary" {...props} />;
};
