import { DEFAULT_SOURCE_TARGET_LENGTH } from '@modules/demand/constants';
import { directionDecorator } from '@modules/demand/utils/direction-decorator';
import { FC } from 'react';
import { DemandListItemTenderLotComponent } from '../components/tender-lot/demand-list-item-tender-lot';
import { TenderLotDemandRow } from '../types/store';
import { timestampToDateTimeString } from '@common/utils';
import { isObject } from 'lodash';
import { FormatType } from '@common/types/general/date-time';

interface Props {
  listItem: TenderLotDemandRow;
}

// Контейнер для отображения одной заявки в разделе "Заявки и рейсы"
export const DemandListItemTenderLotContainer: FC<Props> = ({ listItem }) => {
  const { Data } = listItem;

  const tenderInfo = { ID: Data?.Tender?.ID, name: Data?.Tender?.Name };

  const source = directionDecorator(Data?.RegionalCitySender, Data?.Source?.Name, DEFAULT_SOURCE_TARGET_LENGTH);
  const target = directionDecorator(Data?.RegionalCityRecipient, Data?.Target?.Name, DEFAULT_SOURCE_TARGET_LENGTH);

  const tripInfo = [];

  if (Data?.Trips && isObject(Data?.Trips)) {
    for (let key of Object.keys(Data?.Trips)) {
      tripInfo.push({
        ID: Data?.Trips[key].ID,
        vechicleName: Data?.Trips[key].Vehicle?.Name,
        vechicleRegNumber: Data?.Trips[key].Vehicle?.RegNumber,
        vechicleDriverName: Data?.Trips[key].Driver?.Name,
        vechicleDriverPhone: Data?.Trips[key].Driver?.Phone,
        tripBegin: Data?.Trips[key].TripBegin ? timestampToDateTimeString(Data?.Trips[key].TripBegin, FormatType.Datetime) : '',
        tripEnd: Data?.Trips[key].TripEnd ? timestampToDateTimeString(Data?.Trips[key].TripEnd, FormatType.Datetime) : ''
      });
    }
  }

  return (
    <DemandListItemTenderLotComponent
      listItem={listItem}
      tenderInfo={tenderInfo}
      source={source}
      target={target}
      tripInfo={tripInfo[0] || {}}
    />
  );
};
