import { FC } from 'react';
import { ILSCountdown } from '@common/components';
import { DateFormat } from '@common/types';
import { ILSTypographyTitle } from '@components/general/typography';
import { StatisticProps } from 'antd/lib/statistic/Statistic';

interface IProps {
  deadline: StatisticProps['value'];
}

export const ILSAuctionCountdown: FC<IProps> = ({ deadline }) => {
  return (
    <div className="timer">
      <ILSTypographyTitle level={4}>До конца аукциона осталось:</ILSTypographyTitle>
      <ILSCountdown className="countdown" format={DateFormat.DaysCountdown} value={deadline} />
    </div>
  );
};
