import { ProjectVehicle, Vehicle } from '@common/types';
import { head, isArray, isEmpty } from 'lodash';

export const getProjectVehicle = ({
  vehicle,
  projectVehicles
}: {
  vehicle: Vehicle;
  projectVehicles?: Array<ProjectVehicle> | null;
}): ProjectVehicle | undefined => {
  if (isEmpty(vehicle.ProjectVehicle)) {
    return projectVehicles?.find(({ VehicleID }) => VehicleID === vehicle.ID);
  }

  return isArray(vehicle.ProjectVehicle) ? head(vehicle.ProjectVehicle) : vehicle.ProjectVehicle;
};
