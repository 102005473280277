import { call, put, takeLatest } from 'redux-saga/effects';
import { getUnresolvedOrdersRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaginationType } from '@modules/orders-and-trips/types/general';
import { INIT_PAGINATION_PARAMS } from '@modules/orders-and-trips/constants/general';

function* getUnresolvedOrdersWorker(
  action: PayloadAction<{
    resetActiveRecord?: boolean;
    params?: PaginationType;
  }>
) {
  const { request, success, failure, fulfill } = getUnresolvedOrdersRoutine;
  const { resetActiveRecord, params } = action.payload ?? {};
  const { page, perPage } = params ?? INIT_PAGINATION_PARAMS;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getOrders, { page, perPage });
    yield put(success({ data, resetActiveRecord }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getUnresolvedOrdersWatcher() {
  yield takeLatest(getUnresolvedOrdersRoutine.trigger, getUnresolvedOrdersWorker);
}

