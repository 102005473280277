import { FC, RefObject } from 'react';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import { ILSPlanningResultMapContainer, IPlanningMapProps } from '@modules/planning/children/by-date/containers/result/map-container';
import { IPlanningResultTablesContainerProps } from '@modules/planning/children/by-date/types';
import { ILSPlanningResultTablesContainer } from '@modules/planning/children/by-date/containers/result/tables-container';
import { PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';
import { PlannerBlocksFullScreen } from '@core/decorators/split-sizes';
import { useFullScreenButtons } from '@modules/planning/children/by-date/hooks/use-full-screen-buttons';

export interface IPlanningResult
  extends Omit<IPlanningMapProps, 'recordMapButtons' | 'fullTable' | 'selectedElements' | 'selectedOrders' | 'setSelectedOrders'>,
    Omit<
      IPlanningResultTablesContainerProps,
      | 'settingsTableButtons'
      | 'fullMap'
      | 'selectedElements'
      | 'handleSelectedElements'
      | 'handleSelectedRowKeys'
      | 'selectedRowsIds'
      | 'selectedOrders'
      | 'setSelectedOrders'
      | 'onTableRowClick'
    > {}

interface IProps extends IPlanningResult {
  mapRefExtraFromContainer: RefObject<HTMLDivElement>;
  initialSizes: Array<number>;

  onFullChange(arg: PlannerBlocksFullScreen | PlannerSettingsParameterFullScreen): void;

  handleResize(_: number, allSizes: number[]): void;
}

export const PlannerPlanningSplitSizeComponent: FC<IProps> = ({
  initialSizes,
  handleShowVehicleTable,
  handleShowOrderTable,
  showOrderTable,
  showVehicleTable,
  mapRefExtraFromContainer,
  handleResize,
  setMarkerDragging,
  markerDragging,
  onItemDrop,
  onFullChange,
  setIsAfterDragging,
  setTriggerMapFocus,
  triggerMapFocus
}) => {
  const { fullMap, fullTable, tableButtons, mapButtons } = useFullScreenButtons(onFullChange, initialSizes);

  return (
    <section className="ils-planning">
      <ReactSplit
        direction={SplitDirection.Horizontal}
        initialSizes={initialSizes}
        gutterClassName={'custom-gutter-horizontal'}
        onResizeFinished={handleResize}
      >
        <ILSPlanningResultTablesContainer
          fullMap={fullMap}
          handleShowOrderTable={handleShowOrderTable}
          onItemDrop={onItemDrop}
          setTriggerMapFocus={setTriggerMapFocus}
          markerDragging={markerDragging}
          settingsTableButtons={tableButtons}
        />
        <ILSPlanningResultMapContainer
          fullTable={fullTable}
          mapRefExtraFromContainer={mapRefExtraFromContainer}
          markerDragging={markerDragging}
          setMarkerDragging={setMarkerDragging}
          showVehicleTable={showVehicleTable}
          showOrderTable={showOrderTable}
          handleShowOrderTable={handleShowOrderTable}
          handleShowVehicleTable={handleShowVehicleTable}
          triggerMapFocus={triggerMapFocus}
          setIsAfterDragging={setIsAfterDragging}
          onItemDrop={onItemDrop}
          recordMapButtons={mapButtons}
        />
      </ReactSplit>
    </section>
  );
};
