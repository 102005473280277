import { EMPTY_STRING } from '@common/constants';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';
import { Dictionary, IndexedArray, TableColumnName, WaypointTotal } from '@common/types';
import { TripTotal } from '@common/types/dictionaries/trip';
import { compareAsString, createArrayFromIndexedArray, timestampToDateTimeStringWithTZ, toFixed2 } from '@common/utils';
import { EfficiencyTipHTML, NotificationTipHTML } from '@modules/planning/children/by-date/components/result/tips';
import { waypointsToChildren } from '@modules/planning/children/by-date/decorators/table/waypoints-to-children';
import { FocusOnMapItem } from '@modules/planning/children/by-date/types';
import { sortBy } from 'lodash';
import { IEfficiencyTipProps } from '../../components/result/tips/efficiency';
import { PlanNotices } from '@modules/planning/children/by-date/types/notice-type';
import { getNotificationIconType } from '@modules/planning/children/by-date/decorators/notice/get-notification-icon-type';

export const tripsToChildren = (
  trips: IndexedArray<TripTotal & { changed?: boolean }>,
  focusOnMapItem: FocusOnMapItem,
  notices: PlanNotices
) => {
  return sortBy(createArrayFromIndexedArray(trips), ['Number'], ['asc']).map((trip) => {
    const {
      ClientCount,
      CargoCount,
      TenderID,
      WaypointTotal,
      FullName,
      RouteColor,
      DriverID,
      ExpeditorID,
      Efficiency,
      VehicleID,
      StartTime,
      StartTimeOffset,
      EndTime,
      EndTimeOffset,
      Penalty,
      Distance,
      Mass,
      Volume
    } = trip;
    const color = getVehicleRouteColor({ RouteColor, ID: trip.VehicleID });
    const efficiency = EfficiencyTipHTML({ trip: { color, Efficiency, VehicleID }, hideHeader: false } as IEfficiencyTipProps);
    const dictionary = Dictionary.Trip;

    const ownNotifications = notices.Trip.notices.filter(({ TripID }) => compareAsString(TripID, trip.ID));
    const childNotifications = notices.Waypoint.notices.filter(({ TripID }) => compareAsString(TripID, trip.ID));

    return {
      ...trip,
      key: trip.ID,
      dictionary,
      WaypointTotal: null,
      [TableColumnName.FullName]: `${FullName} (${ClientCount})`,
      FullName_icon: 'route',
      FullName_tip: 'Рейс: один рейс одной машины, по доставке или перевозке грузов. В скобках (к-во клиентов)',
      [TableColumnName.ID]: trip.ID,
      [TableColumnName.DriverID]: DriverID ? [trip.DriverID] : DriverID,
      [TableColumnName.ExpeditorID]: ExpeditorID ? [trip.ExpeditorID] : ExpeditorID,
      [TableColumnName.RouteColor]: color,
      RouteColorChanged: trip.changed,
      [TableColumnName.StartTime]: timestampToDateTimeStringWithTZ(StartTime, StartTimeOffset),
      [TableColumnName.EndTime]: timestampToDateTimeStringWithTZ(EndTime, EndTimeOffset),
      [TableColumnName.TenderID]: TenderID || EMPTY_STRING,
      [TableColumnName.Model]: EMPTY_STRING,
      [TableColumnName.Action]: EMPTY_STRING,
      [TableColumnName.Cargo]: `${ClientCount}/${CargoCount}`,
      [TableColumnName.Penalty]: toFixed2(Penalty),
      [TableColumnName.Distance]: toFixed2(Distance),
      [TableColumnName.Mass]: toFixed2(Mass),
      [TableColumnName.Volume]: toFixed2(Volume),
      [TableColumnName.Efficiency]: Efficiency?.MaxEfficiency,
      [TableColumnName.Notification]: getNotificationIconType(ownNotifications),
      Notification_htmltip: NotificationTipHTML({ ownNotifications, childNotifications }),
      Efficiency_htmltip: efficiency,
      Efficiency_htmlmodal: efficiency,
      [TableColumnName.MassPercent]: Efficiency.MassPercent,
      [TableColumnName.VolumePercent]: Efficiency.VolumePercent,
      [TableColumnName.BoxPercent]: Efficiency.BoxesPercent,
      [TableColumnName.PalletPercent]: Efficiency.PalletsPercent,
      [TableColumnName.DurationPercent]: Efficiency.DurationPercent,
      [TableColumnName.DistancePercent]: Efficiency.DistancePercent,
      [TableColumnName.ClientPercent]: Efficiency.ClientsPercent,
      [TableColumnName.OrderPercent]: Efficiency.OrdersPercent,
      [TableColumnName.FocusOnMap]: focusOnMapItem,
      children: waypointsToChildren(WaypointTotal as IndexedArray<WaypointTotal & { changed: boolean }>, focusOnMapItem, notices)
    };
  });
};
