import { ILSMap } from '@core/containers/map';
import L from 'leaflet';
import { MutableRefObject, useEffect } from 'react';
import { TrackMapLayersGroup } from './init';
import { getDataForVehicleMonitoringMap } from '@modules/vehicle-monitoring/decorators';
import { IVehicleMonitoringData } from '@modules/vehicle-monitoring/types/models';
import { drawPolylines } from '@core/components/map/helpers/draw-polylines';
import { drawMarkers } from '@core/components/map/helpers/draw-marker';

type useShowPolylineEffect = (arg0: {
  Map: MutableRefObject<ILSMap | null>;
  mapLayersGroup: TrackMapLayersGroup;
  data: IVehicleMonitoringData | null;
}) => void;

export const useShowPolyline: useShowPolylineEffect = ({ Map, mapLayersGroup, data }) => {
  const { polyline, marker } = getDataForVehicleMonitoringMap(data);
  const { isInit, markerLayer, trackLayer } = mapLayersGroup;

  useEffect(() => {
    // Если isInit то markerLayer и trackLayer - L.LayerGroup
    if (Map.current && isInit) {
      trackLayer?.clearLayers();
      markerLayer?.clearLayers();
      drawPolylines(Map, polyline, markerLayer as L.LayerGroup, trackLayer as L.LayerGroup);
      drawMarkers(Map, marker, markerLayer as L.LayerGroup);
    }
  }, [polyline, marker]);
};

