import { createRoutine } from 'redux-saga-routines';

export enum UserAction {
  LOG_IN = 'USER/LOG_IN',
  LOG_OUT = 'USER/LOG_OUT',
  REGISTER_REQUEST = 'USER/REGISTER_REQUEST',
  REGISTER = 'USER/REGISTER',
  RECOVERY_REQUEST = 'USER/RECOVERY_REQUEST',
  RECOVERY = 'USER/RECOVERY',
  ROLE = 'USER/GET_ROLE',
  CHECK_TOKEN = 'USER/CHECK_TOKEN',
  GET_PROJECTS = 'USER/GET_PROJECTS',
  CHANGE_USER_INFO = 'USER/CHANGE_INFO'
}

export const loginActions = createRoutine(UserAction.LOG_IN);
export const logoutActions = createRoutine(UserAction.LOG_OUT);

export const registerRequestActions = createRoutine(UserAction.REGISTER_REQUEST);
export const registerActions = createRoutine(UserAction.REGISTER);

export const recoveryRequestActions = createRoutine(UserAction.RECOVERY_REQUEST);
export const recoveryActions = createRoutine(UserAction.RECOVERY);

export const userGetRoleRoutine = createRoutine(UserAction.ROLE);

export const checkTokenRoutine = createRoutine(UserAction.CHECK_TOKEN);
export const getAccountRoutine = createRoutine(UserAction.GET_PROJECTS);
export const changeUserInfoRoutine = createRoutine(UserAction.CHANGE_USER_INFO);

export const currentUserNotifierRoutines = [
  loginActions,
  logoutActions,
  registerRequestActions,
  registerActions,
  recoveryRequestActions,
  recoveryActions
];

