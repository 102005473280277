import moment from 'moment';
import { Moment } from 'moment';
import { range } from 'lodash';

export const disableTimeBeforeNow = (current?: Moment) => ({
  disabledHours: () => disabledHour(current),
  disabledMinutes: () => disabledMinutes(current)
});

const disabledHour = (current?: Moment) => {
  if (current && current?.isBefore(moment())) {
    return range(0, 24).splice(0, moment().hour());
  }
};

const disabledMinutes = (current?: Moment) => {
  if (current && current?.isBefore(moment())) {
    return range(0, 60).splice(0, moment().minute());
  }
};

