import { resetCatalogInfoRoutine } from '@modules/catalog/actions';
import { put, takeLatest } from 'redux-saga/effects';

function* resetCatalogInfoWorker() {
  const { success, failure, fulfill } = resetCatalogInfoRoutine;

  try {
    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* resetCatalogInfoWatcher() {
  yield takeLatest(resetCatalogInfoRoutine.trigger, resetCatalogInfoWorker);
}
