import { mockSimpleAccount } from '@modules/tender-platform/__tests/__mocks/store/users';
import { mockTenderCreatePath, mockTenderIDPath } from '@modules/tender-platform/__tests/__mocks/store/paths';

export const mockVehicleTypes = {
  '1': {
    ID: 1,
    Name: 'Тент'
  },
  '2': {
    ID: 2,
    Name: 'Борт'
  },
  '3': {
    ID: 3,
    Name: 'Фургон'
  },
  '4': {
    ID: 4,
    Name: 'Фургон изотерм'
  },
  '5': {
    ID: 5,
    Name: 'Фургон гидроборт'
  },
  '6': {
    ID: 6,
    Name: 'Автоцистерна'
  },
  '7': {
    ID: 7,
    Name: 'Контейнеровоз'
  },
  '8': {
    ID: 8,
    Name: 'Рефрижератор'
  },
  '9': {
    ID: 9,
    Name: 'Самосвал'
  },
  '10': {
    ID: 10,
    Name: 'Кран-манипулятор'
  },
  '11': {
    ID: 11,
    Name: 'Тягач'
  },
  '12': {
    ID: 12,
    Name: 'Миксер'
  },
  '13': {
    ID: 13,
    Name: 'Автокран'
  },
  '14': {
    ID: 14,
    Name: 'Бензовоз'
  },
  '15': {
    ID: 15,
    Name: 'Топливозаправщик'
  },
  '16': {
    ID: 16,
    Name: 'Зерновоз'
  },
  '17': {
    ID: 17,
    Name: 'Сортаментовоз'
  },
  '18': {
    ID: 18,
    Name: 'Мусоровоз'
  },
  '19': {
    ID: 19,
    Name: 'Снегоуборщик'
  },
  '20': {
    ID: 20,
    Name: 'Муковоз'
  },
  '21': {
    ID: 21,
    Name: 'Лесовоз'
  },
  '22': {
    ID: 22,
    Name: 'Бетононасос'
  },
  '23': {
    ID: 23,
    Name: 'Бункеровоз'
  },
  '24': {
    ID: 24,
    Name: 'Молоковоз'
  },
  '25': {
    ID: 25,
    Name: 'Автовышка'
  },
  '26': {
    ID: 26,
    Name: 'Автобус'
  },
  '27': {
    ID: 27,
    Name: 'Фургон кунг'
  },
  '28': {
    ID: 28,
    Name: 'Фургон цельномет'
  },
  '29': {
    ID: 29,
    Name: 'Эвакуатор'
  },
  '30': {
    ID: 30,
    Name: 'Автовоз'
  }
};
export const mockRestrictions = [
  {
    ID: 2619,
    Name: 'заморозка',
    Deleted: null,
    RestrictionGroupID: null,
    RestrictionGroupEnum: []
  },
  {
    ID: 3198,
    Name: 'Заморозка',
    Deleted: null,
    RestrictionGroupID: null,
    RestrictionGroupEnum: []
  },
  {
    ID: 3199,
    Name: 'Огнеопасно',
    Deleted: null,
    RestrictionGroupID: null,
    RestrictionGroupEnum: []
  },
  {
    ID: 3202,
    Name: 'Боится сырости',
    Deleted: null,
    RestrictionGroupID: null,
    RestrictionGroupEnum: []
  },
  {
    ID: 3400,
    Name: 'Огнеопасно при > +30',
    Deleted: null,
    RestrictionGroupID: null,
    RestrictionGroupEnum: []
  }
];
export const mockTenderStateSimpleAccountCreating = {
  tenderAction: 'create',
  currentTender: null,
  currentTenderDrivers: [],
  currentTenderVehicles: [],
  accountUsers: mockSimpleAccount,
  vehicleTypes: mockVehicleTypes,
  documents: {},
  isFetching: false,
  error: [],
  tenderTemplateData: null
};
export const mockTenderStateSimpleAccount = {
  Tender: {
    ...mockTenderStateSimpleAccountCreating,
    tenderAction: 'ID',
    currentTender: {
      ID: 75,
      AccountID: 432,
      StatusID: 7,
      UserID: 553,
      Private: 1,
      Type: 1,
      Name: 'приватик',
      Description: null,
      OfferStartDT: 1676024100,
      OfferEndDT: 1676031300,
      ExecutionStartDT: 1676034360,
      ExecutionEndDT: 1676045160,
      MinCarrierRating: 0,
      MinDriverRating: 0,
      MinDriverRoutes: 0,
      TenderLot: {
        ID: 70,
        TenderID: 75,
        SourceID: 925705,
        TargetID: 925706,
        IsTemplate: 0,
        SeparateVehicle: 1,
        StartPrice: 23456,
        MinPrice: 22334,
        VehicleLoadType: null,
        VAT: 1,
        Schedule: {
          dates: ['2023-02-10']
        },
        Currency: 'RUB',
        TemplateName: null,
        Contact: null,
        RegionalCitySender: 'загон',
        RegionalCityRecipient: 'прогон',
        Distance: 7,
        Source: {
          ID: 925705,
          DepotTypeID: 1,
          Name: 'г Казань, ул Михаила Зевахина, д 2',
          LatLon: {
            lat: 55.79435832,
            lon: 49.11149735
          },
          LatLonVerified: 1,
          DepotCode: '4e695a4ad697d52bd1ceebc6899a4016',
          Description: null,
          AddressDescription: 'г Казань, ул Михаила Зевахина, д 2',
          Delay: null,
          WorktimeID: null,
          DepotOverloadID: null,
          ICode: null,
          AddressGUID: null,
          AccountID: 0,
          Deleted: null,
          Radius: null,
          Queue: null,
          ZoneID: null,
          DistanceFromCity: null,
          ClientContact: null,
          Info: null,
          Value1: null,
          Value2: null,
          Value3: null,
          VisitPrice: null,
          UTCOffset: 3,
          TimeZone: 'Europe/Moscow',
          AllowKeepTrailer: 0
        },
        Target: {
          ID: 925706,
          DepotTypeID: 1,
          Name: 'г Казань, ул Прогонная, д 20',
          LatLon: {
            lat: 55.73795113,
            lon: 49.09671335
          },
          LatLonVerified: 1,
          DepotCode: 'd690984fc9d5dd03031b7b8bfc9b460d',
          Description: null,
          AddressDescription: 'г Казань, ул Прогонная, д 20',
          Delay: null,
          WorktimeID: null,
          DepotOverloadID: null,
          ICode: null,
          AddressGUID: null,
          AccountID: 0,
          Deleted: null,
          Radius: null,
          Queue: null,
          ZoneID: null,
          DistanceFromCity: null,
          ClientContact: null,
          Info: null,
          Value1: null,
          Value2: null,
          Value3: null,
          VisitPrice: null,
          UTCOffset: 3,
          TimeZone: 'Europe/Moscow',
          AllowKeepTrailer: 0
        },
        TenderLotPosition: [
          {
            ID: 131,
            TenderLotID: 70,
            RestrictionID: null,
            Mass: 1,
            Volume: 0,
            Width: 0,
            Height: 0,
            Length: 0,
            Comment: null,
            Amount: 2
          }
        ],
        TenderLotUser: [],
        VehicleType: [
          {
            ID: 1,
            Name: 'Тент'
          },
          {
            ID: 2,
            Name: 'Борт'
          }
        ]
      },
      User: {
        ID: 553,
        AccountID: 432,
        Name: 'Водила',
        Login: 'fanase3347@ukbob.com',
        Phone: '70000000000'
      },
      TenderParticipants: [
        {
          ID: '2',
          TenderID: 75,
          AccountID: 430,
          Account: {
            ID: 430,
            Name: 'ООО "Тест-релиз"',
            Description: null,
            Info: {
              INN: '1234567890'
            }
          }
        },
        {
          ID: '3',
          TenderID: 75,
          AccountID: 434,
          Account: {
            ID: 434,
            Name: 'Бенида',
            Description: null,
            Info: {
              INN: '1685005986'
            }
          }
        },
        {
          ID: '4',
          TenderID: 75,
          AccountID: 440,
          Account: {
            ID: 440,
            Name: 'быстро отвезём',
            Description: null,
            Info: {
              INN: '4567896542'
            }
          }
        }
      ],
      StatusConfig: {
        ID: 7,
        Text: 'Подведение итогов',
        TriggerText: null,
        TextColor: 0,
        BadgeColor: 13158600,
        Type: 1,
        Alias: 'SummingResults',
        OriginalStatus: 0,
        Validate: 0,
        Sign: 0,
        ControlCoordinates: 0,
        Rollback: 0,
        CanSet: 1,
        TransitionScheme: [
          {
            ID: 8,
            Text: 'Утверждение итогов',
            TriggerText: 'Утвердить итоги',
            TextColor: 0,
            BadgeColor: 13158600,
            Type: 1,
            Alias: 'ApprovalResults',
            OriginalStatus: 0,
            Validate: 0,
            Sign: 0,
            ControlCoordinates: 0,
            Rollback: 0,
            CanSet: 1
          },
          {
            ID: 10,
            Text: 'Отменен',
            TriggerText: 'Отменить',
            TextColor: 0,
            BadgeColor: 13158600,
            Type: 2,
            Alias: 'Cancelled',
            OriginalStatus: 0,
            Validate: 0,
            Sign: 0,
            ControlCoordinates: 0,
            Rollback: 0,
            CanSet: 1
          }
        ]
      },
      TenderOffer: [
        {
          ID: 3,
          DT: '2023-02-10 11:15:41',
          TenderID: 75,
          TenderLotID: 70,
          AccountID: 440,
          UserID: 563,
          StatusID: 16,
          Price: 23455,
          Volume: 100,
          Accepted: 0,
          AllocatedVolume: 100,
          CarrierRatingID: null,
          DriverRatingID: null,
          DriverID: 699457,
          VehicleID: 68207,
          Driver: {
            ID: 699457,
            Name: 'Шнурок',
            DriverCode: '1',
            Deleted: null,
            Phone: '79874563211',
            PassportNumber: null,
            PassportDate: null,
            PassportIssuedBy: null,
            LicenseNumber: null,
            LicenseDate: null,
            LicenseCategory: null,
            Birthday: null,
            Priority: null,
            Type: null,
            DriverRating: null,
            TenderRoutes: 0,
            TransportCompanyID: null
          },
          Vehicle: {
            ID: 68207,
            Name: 'делориан',
            Model: null,
            RegNumber: null,
            Comment: null,
            Deleted: null,
            VehicleGroupID: null,
            AccountID: 440,
            RouteColor: null,
            StartDepotID: null,
            EndDepotID: null,
            VehicleProfileID: null,
            VehicleTimelineID: 5168,
            VehicleOverloadID: null,
            VehicleTariffID: null,
            SpeedProfileID: null,
            DriverID: null,
            ExpeditorID: null,
            ICode: null,
            ForbiddenZones: null,
            VehicleCode: null,
            TakeOnlyOrdersInZones: 0,
            Priority: null,
            ReleaseDate: null,
            Info: null,
            UseMode: null,
            ForMarket: null,
            TripDeviationDistance: null,
            IsAutomotive: 1,
            FrontCouplingTypeID: null,
            RearCouplingTypeID: null,
            TransportCompanyID: null,
            VehicleTypeID: 1
          },
          Account: {
            ID: 440,
            Name: 'быстро отвезём',
            Description: null,
            Info: {
              INN: '4567896542'
            }
          },
          StatusConfig: {
            ID: 16,
            Text: 'Отправлено',
            TriggerText: 'Утвердить',
            TextColor: 0,
            BadgeColor: 13158600,
            Type: 1,
            Alias: 'Sent',
            OriginalStatus: 0,
            Validate: 0,
            Sign: 0,
            ControlCoordinates: 0,
            Rollback: 0,
            CanSet: 1
          },
          CarrierRating: null,
          DriverRating: 2.5
        }
      ]
    },
    documents: {
      '132': {
        ID: 132,
        Subject: 'documents',
        UrlOriginal: 'https://release.intelogis.ru/upload/tender/documents/75/132.jpeg',
        UrlThumbnail: null,
        OriginalName: '14080583101515.jpeg',
        Extension: 'jpeg'
      },
      '133': {
        ID: 133,
        Subject: 'documents',
        UrlOriginal: 'https://release.intelogis.ru/upload/tender/documents/75/133.jpeg',
        UrlThumbnail: null,
        OriginalName: 'f_aW1nLmdlbGlvcGhvdG8uY29tL3VmYS8wNl91ZmEuanBnP19faWQ9MTE0MzA2.jpeg',
        Extension: 'jpeg'
      }
    }
  }
};

export const mockTenderState = {
  tenderAction: 'ID',
  currentTender: {
    ID: 1196,
    AccountID: 382,
    StatusID: 20,
    UserID: 737,
    Private: 0,
    Type: 1,
    Name: 'Новый тендер 808',
    Description: null,
    OfferStartDT: 1675424940,
    OfferEndDT: 1675442880,
    ExecutionStartDT: 1675510980,
    ExecutionEndDT: 1675597380,
    MinCarrierRating: 0,
    MinDriverRating: 0,
    MinDriverRoutes: 0,
    User: {
      ID: 737,
      AccountID: 382,
      Name: 'Александров А.И.',
      Login: 'kgpelrf71elrmnf@tmp-mail.ru',
      Phone: '79064570025'
    },
    TenderParticipants: [],
    StatusConfig: {
      ID: 20,
      Text: 'Отменен',
      TriggerText: 'Отменить',
      TextColor: 0,
      BadgeColor: 13158600,
      Type: 2,
      Alias: 'Cancelled',
      OriginalStatus: 0,
      Validate: 0,
      Sign: 0,
      ControlCoordinates: 0,
      Rollback: 0,
      CanSet: '1',
      TransitionScheme: [
        {
          ID: 2,
          Text: 'Новый',
          TriggerText: 'Создать',
          TextColor: 0,
          BadgeColor: 15865586,
          Type: 0,
          Alias: 'New',
          OriginalStatus: 0,
          Validate: 0,
          Sign: 0,
          ControlCoordinates: 0,
          Rollback: 0,
          CanSet: 1
        }
      ]
    },
    TenderOffer: []
  },
  currentTenderDrivers: [],
  currentTenderVehicles: [],
  accountUsers: {
    '525': {
      ID: 525,
      AccountID: 382,
      Name: 'TectAccount (админ)',
      Login: 'test1@test1.ru',
      Phone: '77777777777'
    },
    '528': {
      ID: 528,
      AccountID: 382,
      Name: 'TectAccount (пользователь логистики)',
      Login: 'test4@test1.ru',
      Phone: '79123111111'
    },
    '737': {
      ID: 737,
      AccountID: 382,
      Name: 'Александров А.И.',
      Login: 'kgpelrf71elrmnf@tmp-mail.ru',
      Phone: '79064570025'
    }
  },
  vehicleTypes: mockVehicleTypes,
  documents: {
    '1452': {
      ID: 1452,
      Subject: 'documents',
      UrlOriginal: 'https://react.intelogis.ru/upload/tender/documents/1196/1452.rar',
      UrlThumbnail: null,
      OriginalName: 'Без названия.rar',
      Extension: 'rar'
    },
    '1453': {
      ID: 1453,
      Subject: 'documents',
      UrlOriginal: 'https://react.intelogis.ru/upload/tender/documents/1196/1453.rar',
      UrlThumbnail: null,
      OriginalName: 'Без названия.rar',
      Extension: 'rar'
    }
  },
  isFetching: false,
  error: []
};
export const mockTenderOwnStateCreate = {
  carriers: {
    carriers: {},
    list: {},
    loading: false
  },
  tenderData: null,
  tenders: [],
  tenderAction: '',
  isFetching: false,
  error: [],
  users: null,
  accountUsers: null,
  contacts: null,
  addressList: null,
  templateList: undefined,
  restrictions: mockRestrictions
};

export const mockTestOptionsTenderOwnCreate = {
  initialStore: {
    Tender: mockTenderStateSimpleAccountCreating,
    TenderOwn: mockTenderOwnStateCreate
  },
  initialRoute: mockTenderCreatePath
};

export const mockTestOptionsTenderOwnTenderID = {
  initialStore: {
    ...mockTenderStateSimpleAccount,
    TenderOwn: mockTenderOwnStateCreate
  },
  initialRoute: mockTenderIDPath
};
