import { CellType, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const NEW_CONTACT = {
  Name: 'Новый контакт'
};
export const CONTACT_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_CONTACT,
  reloading: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 230,
      ellipsis: true,

      sorting: 2,
      filtering: 'string'
    },
    {
      title: 'Эл.почта',
      dataIndex: 'Email',
      key: 'Email',
      editable: true,
      active: true,
      type: CellType.Email,
      width: 200,

      sorting: 1,
      filtering: 'string',
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Номер телефона',
      dataIndex: 'Phone',
      key: 'Phone',
      editable: true,
      active: true,
      type: CellType.Phone,
      width: 200,
      ellipsis: true,
      isFixedColumn: true,

      filtering: 'string'
    },
    {
      title: 'Комментарий',
      dataIndex: 'Comment',
      key: 'Comment',
      editable: true,
      ellipsis: true,
      active: true,
      type: CellType.Input,

      width: 300
    }
  ]
};
