import { FC, useEffect, useState } from 'react';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { ILSForm, ILSFormItem, ILSInput, ILSPopconfirm, ILSSkeleton, ILSTooltip } from '@common/components';
import { InputName } from '@components/data-entry/input';
import classnames from 'classnames';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { projectNameSelector } from '@modules/planning/children/by-date/selectors/project';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';

interface IProps {
  hasTooltip?: boolean;
}

export const PlannerProjectTitle: FC<IProps> = ({ hasTooltip }) => {
  const name = useSelector(projectNameSelector);
  const projectIsFetching = useSelector(projectIsFetchingSelector);
  const projectID = useSelector(activeProjectIdSelector);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [projectTitle, setProjectTitle] = useState(name);

  useEffect(() => {
    form.setFieldsValue({ [InputName.Name]: name });
    setProjectTitle(name);
  }, [form, name]);

  const onFinish = () => {
    const projectTitle = form.getFieldsValue().Name;
    if (projectTitle !== name) {
      setProjectTitle(projectTitle);
      setOpen(true);
    }
    return;
  };

  const onCancel = () => {
    setOpen(false);
    setProjectTitle(name);
    form.resetFields();
  };

  const onConfirm = () => {
    dispatch(
      dictionaryPlannerRoutine({
        values: { Name: projectTitle },
        dictionary: Dictionary.Project,
        operation: DictionariesEndpoint.Update,
        projectListUpdate: true,
        ids: [projectID]
      })
    );
    setOpen(false);
  };

  return (
    <ILSSkeleton paragraph={false} round loading={projectIsFetching} active>
      {projectID && (
        <ILSTooltip title={hasTooltip ? `${name} (№${projectID})` : null}>
          <ILSPopconfirm
            visible={open}
            disabled={!open}
            title={`Изменить название проекта на ${projectTitle}`}
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText="Изменить"
            cancelText="Отмена"
          >
            <ILSForm form={form} onBlur={onFinish} name={'Project'} onFinish={onFinish} initialValues={{ [InputName.Name]: name }}>
              <ILSFormItem name={'Name'}>
                <ILSInput className={classnames('input-header')} maxLength={80} placeholder="Введите название проекта" />
              </ILSFormItem>
            </ILSForm>
          </ILSPopconfirm>
        </ILSTooltip>
      )}
    </ILSSkeleton>
  );
};
