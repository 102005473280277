export enum ErrorMessage {
  EmptyFormField = 'Поля не должны быть пустыми. Для удаления нажмите крестик,если это возможно'
}

export const DEFAULT_APP_ERROR_MESSAGE = 'Ошибка передачи или обработки данных';

/**
 *  Длительность уведомлений с ошибками
 */
export const EROROR_MESSAGE_DURATION = 60;
