import { apiInstance } from '@core/api/instance';
import { IMonitoringAPI } from '@modules/monitoring/types/api';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

/*
 * Необходимые параметры:
 * type - тип просматриваемой таблицы
 * from - дата начала просматриваемых событий мониторинга
 * till - дата окончания просматриваемых событий мониторинга
 * updates - есть ли обновления данных
 * vehicleID - ID автомобиля
 * headers - заголовки в экспортной таблице экселя
 * rows - строки в экспортной таблице экселя
 * DependentKey - ключ зависимости
 * Value - значение
 * Type - тип зависимости
 * IMEI - IMEI трекера
 */

const baseMonitoringURL = '/NewTms/Monitoring/'; // Основной путь до апи бэкэнда раздела мониторинга
const baseTrackerURL = '/NewTms/Tracker/'; // Основной путь до апи бэкэнда по трекерам
const baseVehicleTrackerURL = '/NewTms/VehicleTracker/'; // Основной путь до апи бэкэнда по привязкам трекеров к автомобилю

export const MonitoringAPI: IMonitoringAPI = {
  setWaypointStatus: ({ waypointID, statusID }) =>
    apiInstance().post(getAPIURL(Dictionary.Monitoring, DictionariesEndpoint.SetWaypointStatus), { waypointID, statusID }),
  setTripStatus: ({ id, status }) =>
    apiInstance().post(getAPIURL(Dictionary.Monitoring, DictionariesEndpoint.SetTripStatus), { id, status }),
  // Получает весь массив данных в зависимости от типа открытой вкладки (type)
  getDashboard: ({
    type,
    from,
    till,
    /** backend может некорректно интерпретировать undefined, поэтому ставим false */
    updates = false
  }) =>
    apiInstance().post(baseMonitoringURL + 'getDashboard', {
      type,
      from,
      till,
      updates
    }),

  // Получает текущие метрики
  getMetrics: ({ from, till }) => apiInstance().post(baseMonitoringURL + 'getMetrics', { from, till }),

  // Получает текущие метрики в зависимости от выбранного автомобиля
  getMetricsByVehicle: ({ from, till, vehicleID }) =>
    apiInstance().post(baseMonitoringURL + 'getMetrics', {
      from,
      till,
      vehicleID
    }),

  /** Получает данные о нарушениях безопасности дорожного движения */
  getBDD: ({
    from,
    till,
    vehicleID,
    /** backend может некорректно интерпретировать undefined, поэтому ставим false */
    updates = false
  }) =>
    apiInstance().post(
      baseMonitoringURL + 'getBDD',
      vehicleID === undefined ? { from, till, updates } : { from, till, vehicleID, updates }
    ),

  // Получает данные вложений
  getAttachments: ({ waypointID }) => apiInstance().post(baseMonitoringURL + 'getAttachments', { waypointID }),

  // Получает данные план / факт
  getPlanFact: ({ vehicleID, from, till }) =>
    apiInstance().post(baseMonitoringURL + 'getPlanFact', {
      vehicleID,
      from,
      till
    }),

  // Получает данные трека
  getTrack: ({ vehicleID, from, till, updates = false }) =>
    /** backend может некорректно интерпретировать undefined, поэтому ставим false */
    apiInstance().post(baseMonitoringURL + 'getTrack', { vehicleID, from, till, updates: updates || false }),

  // Получает данные о событиях
  getEventDetails: ({ from, till, showAllVehicles }) =>
    apiInstance().post(baseMonitoringURL + 'getEventDetails', { from, till, showAllVehicles }),

  // Получает последние данные с трекера
  getTrackerLastInfoDetails: ({ vehicleID, from, till }) =>
    apiInstance().post(baseMonitoringURL + 'getTrackerLastInfo', { vehicleID, from, till }),

  // Отправляет данные (заголовки, строки) для экспорта в эксель файл
  exportExel: ({ headers, rows }) => apiInstance().post(baseMonitoringURL + 'export', { headers, rows }),

  // Получает список статусов точек
  getPointsStatusesList: () => apiInstance().post(baseMonitoringURL + 'getPointsStatusesList', {}),

  // Устанавливает текущий таб
  setViewDashboard: ({ DependentKey, Value, Type }) =>
    apiInstance().post(baseMonitoringURL + 'setViewed', {
      DependentKey,
      Value,
      Type
    }),

  // Получает данные о прогрессе доставок
  getProgress: (params) => apiInstance().post(baseMonitoringURL + 'getProgress', params),

  // Получает все трекеры
  getVehicleTrackers: () => apiInstance().post(baseVehicleTrackerURL + 'index', {}),
  //Удаляет связь трекера и ТС
  deleteVehicleTrackers: ({ IDs }) => apiInstance().post(baseVehicleTrackerURL + 'delete', { data: { ids: [IDs] } }),
  //Изменяет связь трекера и ТС
  updateVehicleTrackers: (values) => apiInstance().post(baseVehicleTrackerURL + 'update', { data: values }),

  // Создаёт привязку трекера к автомобилю
  createVehicleTracker: (params) => apiInstance().post(baseVehicleTrackerURL + 'create', { data: { values: [params] } }),

  // Получает все трекеры
  getTrackers: () => apiInstance().post(baseTrackerURL + 'index', {}),

  // Создаёт трекер
  createTracker: (values) => apiInstance().post(baseTrackerURL + 'create', { data: { values } }),

  // Обновляет трекер
  updateTracker: (values) => apiInstance().post(baseTrackerURL + 'update', { data: values }),

  // Удаляет трекер
  deleteTracker: ({ IMEI }) => apiInstance().post(baseTrackerURL + 'delete', { IMEI })
};

