import { DepotRecordKeys, Dictionary } from '@common/types';
import { ValuesType } from '@modules/catalog/types/catalog';
import { catalogSetFocusRowRoutine, catalogUpdateRoutine } from '@modules/catalog/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { refreshDictionary } from '@modules/catalog/helpers/sagas/refresh-dictionary';
import { CatalogUpdatePayload } from '@modules/catalog/types/actions';
import { isEmpty } from 'lodash';

export function* catalogUpdateWorker(action: CatalogUpdatePayload) {
  const { dictionary, ids, dataIndex, value, saveDictionary } = action.payload;
  let values: ValuesType = action.payload.values;
  const { request, success, failure, fulfill } = catalogUpdateRoutine;
  try {
    yield put(request({ dictionary }));
    values = values ?? { [dataIndex]: value };
    if (dictionary === Dictionary.Depot) {
      if (
        !(DepotRecordKeys.LAT_LON_VERIFIED in values) &&
        (DepotRecordKeys.LAT_LON in values || DepotRecordKeys.ADDRESS_DESCRIPTION in values)
      ) {
        values[DepotRecordKeys.LAT_LON_VERIFIED] = 0;
      }
    }
    const {
      data: { data }
    } = yield call(DictionaryAPI.update, { dictionary, ids, values });

    const newRows = checkAndCreateArrayFromObject(data);

    if (!isEmpty(newRows)) {
      yield put(catalogSetFocusRowRoutine({ key: newRows[newRows.length - 1].ID, dictionary }));
    }

    const payload = {
      data: newRows,
      dictionary,
      saveDictionary
    };

    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield refreshDictionary(dictionary);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogUpdateWatcher() {
  yield takeEvery(catalogUpdateRoutine.trigger, catalogUpdateWorker);
}
