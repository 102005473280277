import { useAppSelector } from '@core/hooks';
import { deepEqual } from 'fast-equals';
import { FC } from 'react';
import { ILSDemandHeaderComponent } from '../components/header/demand-header';
import { loadParamsFilterSelector } from '../selectors';
import { GetListRequest, RequestParamsFilter } from '../types/api';
import { HeaderCreateButton } from '../types/button';
import { DemandTabType } from '../types/tabs';

interface IProps {
  activeKey: DemandTabType;
  onChange: (tab: string) => void;
  hintData: { [K in DemandTabType]?: number };
  hasDesync: boolean;
  onReload: () => void;
  onLoad: (newLoadParams: GetListRequest) => void;
  buttonHandler: { [K in HeaderCreateButton]?: () => void };
}

// Контейнер содержащий хедер и подкомпоненты управления
export const ILSDemandHeaderContainer: FC<IProps> = ({ onLoad, ...props }) => {
  const filter = useAppSelector(loadParamsFilterSelector) ?? {};

  const onChangeFilter = (newFilter: Partial<RequestParamsFilter>) => {
    if (!deepEqual(filter, newFilter)) {
      onLoad({ filter: newFilter });
    }
  };
  return <ILSDemandHeaderComponent {...props} filter={filter} onChangeFilter={onChangeFilter} />;
};
