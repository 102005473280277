import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';
import { isEmpty } from 'lodash';
import { IILSTableComponentProps, Keys, TableRecord } from '@common/types';

interface IProps {
  onClick: IILSTableComponentProps<TableRecord>['handleBindTimeZone'];
  selectedRows: Keys | [];
}

export const ILSBindTimeZoneActionButton: FC<IProps> = ({ onClick, selectedRows }) => {
  const handleClick = () => onClick?.(selectedRows);

  return (
    <ILSButtonTooltip
      icon={'timezone'}
      disabled={isEmpty(selectedRows)}
      onClick={handleClick}
      title={'Получить временные зоны по координатам'}
    />
  );
};
