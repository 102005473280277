import { FC, useCallback, useEffect, useState } from 'react';
import {
  defaultTariffsSelector,
  paramRefreshSelector,
  paramSelector,
  tariffRefreshSelector,
  tariffSelector
} from '@modules/catalog/selectors/tariffs';
import { useDispatch, useSelector } from 'react-redux';
import { catalogCreateRoutine, catalogDeleteRoutine, catalogUpdateRoutine, getTariffRoutine } from '../../actions';
import { ILSCatalogTariffContainer, TariffPanel } from './tariff-container';
import { ILSTariffDeleteConfirmComponent } from '../../components/tariff/delete-confirm';
import { Dictionary, Key, Keys, VehicleTariff } from '@common/types';
import { ForceDeleteType, Value, ValuesType } from '@modules/catalog/types/catalog';

export const ILSCatalogTariff: FC = () => {
  const dispatch = useDispatch();
  const tariffs = useSelector(tariffSelector);
  const params = useSelector(paramSelector);
  const defaultItem = useSelector(defaultTariffsSelector);
  const tariffRefresh = useSelector(tariffRefreshSelector);
  const paramRefresh = useSelector(paramRefreshSelector);

  const [activePanel, setActivePanel] = useState(TariffPanel.tariffConstructor);
  const [confirm, setConfirm] = useState<any | boolean>(false);
  const [activeItem, setActiveItem] = useState<
    | ({
        Formula: any;
        Calc: any;
      } & VehicleTariff)
    | null
  >(defaultItem);

  useEffect(() => {
    getInitial();
  }, []);

  useEffect(() => {
    if (tariffRefresh || paramRefresh) {
      getInitial();
    }
    if (tariffs && !tariffRefresh && !paramRefresh) {
      tariffs.find((tariff) => {
        if (tariff.ID === activeItem?.ID) {
          setActiveItem({
            ...tariff,
            Formula: tariff.TripPriceFormulaDraft ?? tariff.TripPriceFormula,
            Calc: tariff.TripPriceFormula
          });
        }
      });
    }
  }, [tariffRefresh, paramRefresh, tariffs]);
  //TODO by the way, what is this?
  useEffect(() => {}, [tariffs, params]);

  const getInitial = () => {
    //TODO 1 dispatch
    dispatch(getTariffRoutine({ dictionary: Dictionary.VehicleTariff }));
  };

  const changeActiveItem = useCallback((dictionary: Dictionary, item: any) => {
    if (item) {
      const newActiveItem =
        dictionary === Dictionary.VehicleTariff
          ? {
              Formula: item.TripPriceFormulaDraft ?? item.TripPriceFormula,
              Calc: item.TripPriceFormula
            }
          : {
              Formula: item.Formula,
              Calc: item.Formula
            };
      setActiveItem({ ...item, ...newActiveItem });
    }
  }, []);

  const handleSave = (dictionary: Dictionary, Name: string, formula: any, id?: Key) => {
    const field = dictionary === Dictionary.VehicleTariff ? 'TripPriceFormula' : 'Formula';
    const values = {
      Name,
      [field]: formula
    };
    const payload: {
      dictionary: Dictionary;
      id?: Key;
      values: ValuesType | Value;
    } = {
      dictionary,
      values
    };
    if (id) {
      payload.id = id;
      dispatch(catalogUpdateRoutine(payload));
    } else {
      payload.values = [values];
      dispatch(catalogCreateRoutine(payload));
    }
  };

  const handleDelete = (dictionary: Dictionary, id: Key, forceDelete = false) => {
    let allowDelete = true;
    const payload: {
      dictionary: Dictionary;
      ids: Keys;
      forceDelete?: ForceDeleteType | number;
    } = {
      dictionary: dictionary,
      ids: [id]
    };
    if (forceDelete) {
      payload.forceDelete = 1;
    } else {
      if (dictionary === Dictionary.VehicleTariffPart) {
        const tariffsUseParam: Array<any> = [];
        tariffs.map((t: any) => {
          if (t.TripPriceFormulaDraft && t.TripPriceFormulaDraft.includes('UserParam_' + id)) {
            tariffsUseParam.push(t);
          }
          return 1;
        });
        if (tariffsUseParam.length) {
          allowDelete = false;
          setConfirm({ tariffs: tariffsUseParam, dictionary, id });
        }
      }
    }
    if (allowDelete) {
      dispatch(catalogDeleteRoutine(payload));
      setActiveItem(null);
      setConfirm(false);
    }
  };

  return (
    <>
      <ILSCatalogTariffContainer
        activeItem={activeItem}
        changeActiveItem={changeActiveItem}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        params={params}
        tariffs={tariffs}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
      {confirm && <ILSTariffDeleteConfirmComponent confirm={confirm} tariffs={confirm.tariffs} handleDelete={handleDelete} />}
    </>
  );
};
