import { takeEvery } from 'redux-saga/effects';
import { settingsRoutinesWithNotifier } from '../actions';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { getNotificationMessage } from '../utils/helpers/get-notification-message';
import { SettingsAction } from '../types/actions';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { ErrorAPI } from '@common/types';

/**метод преобразования типа redux-action к типу операции из перечисления Action
 * @param actionName - например CREATE, UPDATE, DELETE, CLONE....
 */
const getActionFromRoutine = (actionName: string): SettingsAction => {
  return actionName as SettingsAction;
};

const worker = notifierWorkerCreator<{} & ErrorAPI, SettingsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'settings',
  getNotificationMessage
});

const errorWorker = notifierWorkerCreator<ErrorAPI, SettingsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'settings',
  getNotificationMessage,
  getErrorMessage
});

export function* settingsNotifierWatcher() {
  for (let routine of settingsRoutinesWithNotifier) {
    yield takeEvery(routine.REQUEST, worker);
    yield takeEvery(routine.SUCCESS, worker);
    yield takeEvery(routine.FAILURE, errorWorker);
  }
}
