import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { demandChangeActiveTabRoutine } from '../actions';

import { DemandTabType } from '../types/tabs';

// Меняет активную вкладку (все либо только проекты либо только лоты)
function* changeActiveTabWorker(action: PayloadAction<{ activeTab: DemandTabType }>) {
  const { activeTab } = action.payload;
  const { request, success, failure, fulfill } = demandChangeActiveTabRoutine;

  try {
    yield put(request());

    yield put(success({ activeTab }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* changeActiveTabWatcher() {
  yield takeLatest(demandChangeActiveTabRoutine.trigger, changeActiveTabWorker);
}
