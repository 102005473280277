import { planSaveRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanStatus, TableColumnName, Trip } from '@common/types';
import { PlanningAPI } from '@modules/planning/api';
import { PlanID } from '@common/types/dictionaries/plan';
import { planTripIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { isEmpty } from 'lodash';
import { throwError } from '@common/utils/validators/check-data-for-error';

export type PlanSavePayloadAction = PayloadAction<{
  planID: number;
  key: TableColumnName;
  value: unknown;
}>;

function* planSaveWorker(action: PlanSavePayloadAction) {
  const { request, success, failure, fulfill } = planSaveRoutine;
  const { planID, key, value } = action.payload;

  try {
    switch (key) {
      case TableColumnName.Status:
        yield put(request({ message: 'Установка статуса...' }));
        const tripIDs: Array<Trip['ID']> = yield select(planTripIDsSelector);
        if (isEmpty(tripIDs)) {
          throwError({ message: 'Нельзя менять статус у плана без рейсов' });
        }
        yield call(PlanningAPI.setTripStatus, { tripIDs, status: value as PlanStatus });
        break;
      case TableColumnName.Name:
        yield put(request({ message: 'Сохранение названия...' }));
        yield call(PlanningAPI.update, { planID, field: key, value });
        break;
      default:
        yield put(request({ message: 'Сохранение...' }));
        break;
    }
    const payload: {
      message: string;
      key: TableColumnName;
      value: unknown;
      planID: PlanID;
    } = {
      message: 'План успешно изменён',
      key,
      value,
      planID
    };
    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planSaveWatcher() {
  yield takeLatest(planSaveRoutine.trigger, planSaveWorker);
}
