import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticsCommonAPI } from '../types/api';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { IndexedArray } from '@common/types';
import { AnalyticsActions } from '../types/actions';

export const analyticsUpdateWidgetPositionRoutineFactory = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<IndexedArray<AnalyticsWidget>>) {
    const { payload } = action;
    const { request, success, failure, fulfill } = actions.analyticsUpdateWidgetRoutine;
    try {
      yield put(request(payload));

      yield put(success(payload));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsUpdateWidgetRoutine.trigger, worker);
  }

  return watcher;
};
