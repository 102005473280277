import { CellType, TableConfig } from '@common/types';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export const PLANNER_PLANNING_PARAMETERS_TABLE_CONFIG: TableConfig = {
  deleting: true,
  reloading: true,
  unchangeable: true,
  hideClearButtons: true,
  copy: true,
  adding: {},
  columns: [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      active: false,
      type: CellType.Input
    },
    {
      title: 'Название конфигурации',
      dataIndex: 'Name_',
      key: 'Name_',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 500,
      ellipsis: true,

      sorting: 3,
      filtering: 'string'
    },
    {
      title: 'Контекст',
      dataIndex: 'DefaultValue_',
      key: 'DefaultValue_',
      active: true,
      editable: true,
      type: CellType.Select,
      width: 200,
      fieldNames: BASE_FIELD_NAMES,

      options: [
        { value: '0', label: 'приватная конфигурация' },
        { value: '1', label: 'публичная конфигурация' },
        { value: '2', label: 'отключена' },
        { value: '3', label: 'фиксированная' },
        { value: '4', label: 'для подбора' }
      ]
    }
  ]
};
