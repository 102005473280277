import '../styles.less';
import { CurrentBalance } from '@common/feature/payment/components/current-balance';
import { PaymentModal } from '@common/feature/payment/components/payment-modal';
import { usePaymentContainer } from '@common/feature/payment/hooks/use-payment-container';

export const PaymentContainer = () => {
  const { balance, balanceIsFetching, visible, modalType, handleClickMenuItem, handleShowModal, openInvoiceViewer } = usePaymentContainer();

  return (
    <div className="payment-container">
      <CurrentBalance balance={balance} balanceIsFetching={balanceIsFetching} handleClickMenuItem={handleClickMenuItem} />
      {visible && (
        <PaymentModal modalType={modalType} showForm={visible} handleShowModal={handleShowModal} openInvoiceViewer={openInvoiceViewer} />
      )}
    </div>
  );
};

