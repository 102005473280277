import { PlanningAPI } from '@modules/planning/api';
import { ChangeExpeditorInRouteRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { changeExpeditorInRouteRoutine } from '../../actions';

export type ChangeExpeditorInRoutePayloadAction = PayloadAction<ChangeExpeditorInRouteRequest>;

function* planningChangeExpeditorInRouteWorker(action: ChangeExpeditorInRoutePayloadAction) {
  const { request, success, failure, fulfill } = changeExpeditorInRouteRoutine;
  try {
    const { expeditorID, planID, vehicleID } = action.payload;
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.changeExpeditorInRoute, { expeditorID, planID, vehicleID });
    yield put(success({ message: data, expeditorID, vehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningChangeExpeditorInRouteWatcher() {
  yield takeLatest(changeExpeditorInRouteRoutine.trigger, planningChangeExpeditorInRouteWorker);
}
