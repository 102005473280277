import { DepotOverload } from './depot';
import { OrderAction } from './order';
import { SpeedProfile } from '@common/types/dictionaries/speed-profile';
import { TrailerAction } from './trailer';
import { Trip } from './trip';
import { Vehicle, VehicleOverload, VehicleTariff, VehicleTimeline } from './vehicle';
import { Waypoint } from './waypoint';
import { Efficiency, ID, IndexedArray, Project, ProjectModel, Ramp, TimeStamp } from '@common/types';
import { RouteTotal } from './route';

//TODO Сделать только числа
export enum PlanStatus {
  /** можно полностью редактировать*/
  Open = '0',
  /** можно только менять порядок заявок внутри*/
  ReorderingOnly = '1',
  /** можно только добавлять другие заявки*/
  AdditionsOnly = '2',
  /** ничего нельзя*/
  Closed = '3',
  TripWithDifferentStatuses = '4',
  OnExecution = '100',
  Started = '101',
  Execution = '102',
  Advance = '103',
  BeingLate = '104',
  Return = '105',
  EarlyReturn = '106',
  LateReturn = '107',
  Completed = '200',
  FinishedOutrunning = '201',
  FinishedDelay = '202',
  Failed = '203',
  Canceled = '204',
  CompletedLateWithUnvisited = '205',
  PartiallyFinishedOutrunning = '206',
  PartiallyFinishedDelay = '207',
  Invalid = '208',
  FPDeleted = '404'
}

export enum Provider {
  ILS = 'ILS',
  Veeroute = 'Veeroute',
  Yandex = 'Yandex'
}

export type PlanTotal = {
  TypePlanning: string;
  PlanConfig:
    | [Provider, ID | string]
    | {
        Provider: Provider;
        ID: string | number;
      };
  Mass: number;
  Pallets: number;
  Boxes: number;
  Volume: number;
  ClientCount: number;
  LoadDuration: number;
  UnloadDuration: number;
  Cost: number;
  WaitingDuration: number;
  LatenessDuration: number;
  PenaltyTotal: number;
  RoutesCount: number;
  Duration: number;
  StartTime: number;
  StartTimeOffset: number | null;
  EndTime: number;
  EndTimeOffset: number | null;
  Status: PlanStatus;
  Order: number;
  ResolvedOrder: number;
  UnresolvedOrder: number;
  RoadDuration: number;
  MoveDuration: number;
  RestDuration: number;
  Distance: number;
  AverageSpeed: number;
  Dimension: {
    length: number;
    width: number;
    height: number;
  };
  Number: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H';
  FullName: string;
  ProjectVehicleCount: number;
  TripPrice: number;
  Efficiency: number;
};

export type Plan = {
  ID: number;
  ProjectID?: Project['ID'] | null;
  Name: string;
  PlanConfigID?: number;
  VehicleOverloadID: VehicleOverload['ID'];
  VehicleTimelineID: VehicleTimeline['ID'];
  DepotOverloadID: DepotOverload['ID'];
  SpeedProfileID: SpeedProfile['ID'];
  VehicleTariffID: VehicleTariff['ID'];
  Changed: TimeStamp | null;
  OriginalID: number;
  CalculateStart: TimeStamp | null;
  OptimizeStart: TimeStamp | null;
  CalculateDuration: TimeStamp | null;
  Status: PlanStatus | null;
  CountIteration: number;
  ProviderName: Provider | null;
  ExternalPlanConfigID: string | null;
  PlanningStart: TimeStamp | null;
  Comment: string | null;
  NameFull: string | null; // Название плана полностью, включая код провайдера и конфигурацию планирования: для всплывающей подсказки
  Info: null | string;
  PlanTotal: PlanTotal;
};

export type PlanResponse = Plan & {
  Project: ProjectModel;
  PlanConfig: PlanConfigModel | null;
  PlanAnalytics: [];
  PlanNotice: Array<PlanNotice>;
  PlanPenalty: Array<PlanPenalty>;
  PlanParam?: Array<PlanParam>;
  Ramp: Array<Ramp>;
  Efficiency: Efficiency;
  RouteTotal: IndexedArray<RouteTotal>;
};

export type PlanID = Plan['ID'];
export type PlanIDs = Array<PlanID>;

export type PlanNotice = {
  ID: number;
  StringKey: string;
  Name?: string;
  Value: number;
  Limit: number;
  NoticeType: number;
  PlanID: Plan['ID'];
  VehicleID: Vehicle['ID'];
  TripID: Trip['ID'];
  WaypointID: Waypoint['ID'];
  OrderActionID: OrderAction['ID'];
  TrailerActionID: TrailerAction['ID'] | null;
  //true если удалось распарсить сообщение в форматированную строку
  IsParsed?: boolean;
};
// TODO | {}, иногда бекенд возвращает PlanNotice: [{},{},{}];

export type PlanPenalty = {
  ID: number;
  StringKey: string;
  PlanID: Plan['ID'];
  VehicleID: Vehicle['ID'];
  TripID: Trip['ID'];
  Coefficient: number;
  Value: number;
};

export type PlanningConfig = {
  id: string;
  PlanGroupID_: string;
  DefaultValue_: string;
  Name_: string;
  Index_: string;
  Def: string;
};

export type PlanParam = {
  ID: number;
  StringKey: string;
  Name: string;
};

export enum PlanningAlgorithm {
  ILS = 'Алгоритм I',
  Veeroute = 'Алгоритм V',
  Yandex = 'Алгоритм Y'
}

export enum ParamsType {
  Slider = 'slider',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Number = 'number'
}

export enum ILSParams {
  countIteration = 'countIteration',
  preplan = 'preplan'
}

export enum VeerouteParams {
  planning_time = 'planning_time',
  planning_type = 'planning_type'
}

export type Parameters = {
  default: number;
  label: string;
  range: Array<number>;
  type: ParamsType;
};

export type Params = Record<ILSParams, Parameters> | Record<VeerouteParams, Parameters>;
export type Configs = Record<ID, string>;

export type Config = { name: string; label: string; configs: Configs; params: Params };
export type PlanConfig = Record<Provider, Config>;
export type PlanConfigModel = {
  ID: number;
  Name: string;
  Index: string;
  ProviderName?: Provider | null;
};

export type PlanConfigGroup = Omit<PlanConfig, 'DefaultValue_' | 'Index_'> & { StringKey_: string };

export type PlanConfigFull = PlanConfig & {
  Items: Array<{ Items: Array<PlanConfigParam> } & PlanConfig>;
};

export type PlanConfigParam = Omit<PlanConfig, 'PlanGroupID_'> & {
  Value_: string;
  DefaultValue_: string;
  StringKey_: string;
  Version_: string;
  PlanParamID_: string;
  PlanConfigID_: string;
  IsDefault_: number;
};
