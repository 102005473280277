import { Dictionary, IILSTableProps, TableRecord } from '@common/types';
import { ILSTable } from '@components/index';
import { tableCustom } from '@core/containers/table-custom';
import { tenderCarriersTableConfig } from '@modules/tender-platform/children/tender-own/utils/table-configs/carriers';
import { head } from 'lodash';

export const ILSCarriersTable = tableCustom(
  (props) => {
    return (
      <ILSTable
        {...props}
        scroll={{ x: 300, y: 500 }}
        className="tender-table"
        focusRow={{ key: head(props.dataSource as IILSTableProps<TableRecord>['dataSource'])?.key }}
      />
    );
  },
  tenderCarriersTableConfig,
  Dictionary.TenderCarrier
);
