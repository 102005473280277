export enum MarkerType {
  Order = 'order',
  Waypoint = 'point',
  Client = 'client',
  Storage = 'storage',
  Load = 'load',
  Color = 'color',
  ForTrack = 'forTrack',
  Car = 'car',
  Destination = 'destination',
  OrderCluster = 'order-cluster',
  Mixed = 'mixed',
  Point = 'point',
  Focus = 'focus',
  Default = ''
}
