import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { TableColumnName } from '@common/types';
import { int2color } from '@common/decorators/color';
import { ForwarderPointStatusesChooseAvailable, ForwarderPointStatusesResponse } from '@modules/settings/types/forwarder-point-statuses';

export const forwarderPointStatuses2table = (forwarderPointStatuses: ForwarderPointStatusesResponse) => {
  return createArrayFromIndexedArray(forwarderPointStatuses).map((forwarderPointStatus) => {
    return {
      ...forwarderPointStatus,
      key: forwarderPointStatus.ID,
      [TableColumnName.SignColor]: int2color(forwarderPointStatus?.SignColor),
      [TableColumnName.IconUrl]: forwarderPointStatus?.Icon,
      //"Возможность установить" по-умолчанию включена (TMS-5745)
      [TableColumnName.ChooseAvailable]: forwarderPointStatus?.ChooseAvailable ?? ForwarderPointStatusesChooseAvailable.Yes,
      ForbiddenToDelete: forwarderPointStatus.IsMaster
    };
  });
};
