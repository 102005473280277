import { FC, useState } from 'react';
import { useAppDispatch } from '@core/hooks';
import { ILSButton, ILSDropdownButton, ILSTabPane, ILSTabs } from '@common/components';
import { MenuInfo } from 'rc-menu/lib/interface';
import { createDealTemplateRoutine, createTemplateRoutine } from '@modules/documents/children/templates/actions';
import { DocumentType } from '@modules/documents/types/document-type';
import { tabs } from '../constants/tabs';
import { Tabs } from '../types/tabs';
import '../styles.less';

const dropdownMenu = [
  { label: 'Шаблон договора', key: DocumentType.Contract },
  { label: 'Шаблон приложения', key: DocumentType.ContractAnnex },
  { label: 'Шаблон транспортной накладной', key: DocumentType.LadingBill },
  { label: 'Шаблон маршрутного листа', key: DocumentType.RouteList },
  { label: 'Шаблон другого документа', key: DocumentType.Custom }
];

const ILSDocumentsTemplates: FC = () => {
  const dispatch = useAppDispatch();
  const [activeTabKey, setActiveTabKey] = useState<string>(Tabs.DEALS);

  const createDealTemplate = () => {
    dispatch(createDealTemplateRoutine());
  };

  const onMenuClick = (menuInfo: MenuInfo) => {
    dispatch(createTemplateRoutine(menuInfo.key));
  };

  const createButton =
    activeTabKey === Tabs.DEALS ? (
      <ILSButton type="primary" title={'Создать шаблон сделки'} onClick={createDealTemplate}>
        Создать
      </ILSButton>
    ) : (
      <ILSDropdownButton text="Создать" items={dropdownMenu} onMenuClick={onMenuClick} />
    );

  return (
    <>
      <ILSTabs activeKey={activeTabKey} onChange={setActiveTabKey} tabBarExtraContent={createButton}>
        {tabs.map(({ label, component }) => (
          <ILSTabPane tab={label} key={label}>
            {activeTabKey === label && component}
          </ILSTabPane>
        ))}
      </ILSTabs>
    </>
  );
};

export default ILSDocumentsTemplates;
