import { DictionaryAPI } from '@common/api/common';
import { CatalogActions, catalogDeleteRoutine } from '@modules/catalog/actions';
import { CatalogNotificationStatuses } from '@modules/catalog/helpers/get-notification-message';
import { call, put, takeLatest } from 'redux-saga/effects';
import { refreshDictionary } from '@modules/catalog/helpers/sagas/refresh-dictionary';
import { CatalogDeletePayload } from '@modules/catalog/types/actions';

export function* catalogDeleteWorker(action: CatalogDeletePayload) {
  const { dictionary, ids, saveDictionary } = action.payload;
  const { request, success, failure, fulfill } = catalogDeleteRoutine;
  const notifyKey = `delete-from-dictionary-key-${dictionary}`;
  try {
    yield put(request({ dictionary, notifyKey }));
    yield call(DictionaryAPI.delete, {
      dictionary,
      ids
    });

    yield put(
      success({
        dictionary,
        ids,
        saveDictionary,
        notifyKey,
        notificationMessage:
          ids?.length === 1 && CatalogNotificationStatuses?.[CatalogActions.DELETE]?.successSingle
            ? CatalogNotificationStatuses?.[CatalogActions.DELETE]?.successSingle
            : CatalogNotificationStatuses?.[CatalogActions.DELETE]?.success
      })
    );
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield refreshDictionary(dictionary);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogDeleteWatcher() {
  yield takeLatest(catalogDeleteRoutine.trigger, catalogDeleteWorker);
}
