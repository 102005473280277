import { IndexedArray, PlanNotice } from '@common/types';
import { TNoticeType } from '@modules/planning/children/by-date/types';
import { parseNoticeType } from '@modules/planning/children/by-date/helpers/plan/parse-notice-type';
import { isEmpty } from 'lodash';
import { TRANSLATE_NOTIFICATION_DICTIONARY } from '@modules/planning/children/by-date/constants/content';

export function translateNotification(notice: PlanNotice, noticeTypes?: IndexedArray<TNoticeType>): PlanNotice {
  const noticeName = !isEmpty(noticeTypes) ? parseNoticeType(notice, noticeTypes) : null;

  return {
    ...notice,
    IsParsed: Boolean(noticeName),
    Name: noticeName || TRANSLATE_NOTIFICATION_DICTIONARY[notice.StringKey] || notice.StringKey
  };
}
