import { createSelector } from 'reselect';
import { EmulatorState } from '../types/store';

// Возвращает конфигурацию эмуляции
export const emulationConfigsSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.configs,
  (configs) => configs
);

// Возвращает скорость эмуляции
export const emulationSpeedSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.configs.EmulationSpeed,
  (emulationSpeed) => emulationSpeed
);
