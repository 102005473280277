import { IILSTableProps, Key, TableRecord } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';

export const getActiveIndex = <R extends TableRecord>(dataSource: IILSTableProps<R>['dataSource'], activeKey: Key | undefined) => {
  let index = 0;
  dataSource?.find((row, i) => {
    const key = row?.key || row?.ID || row?.rowKey;
    if (compareAsString(key, activeKey)) index = i;
  });
  return index;
};
