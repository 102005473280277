import { TenderPlatformBiddingComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/bidding';
import { TenderPlatformConditionComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/condition';
import { TenderPlatformCargoContainer } from '@modules/tender-platform/containers/tender-card/lots/form/cargo';
import { TenderPlatformLotTemplateFieldset } from '@modules/tender-platform/containers/tender-card/lots/template';
import { FC } from 'react';
import { TenderPlatformRouteContainer } from '@modules/tender-platform/containers/tender-card/lots/form/route';

interface IProps {
  disabled: boolean;
  fromPlanner: boolean;
}

export const TenderPlatformLotFormComponent: FC<IProps> = ({ disabled, fromPlanner }) => {
  return (
    <fieldset className={'tender-platform-card-form-fieldset-lot'}>
      <TenderPlatformCargoContainer disabled={disabled} fromPlanner={fromPlanner} />
      <TenderPlatformRouteContainer disabled={disabled} fromPlanner={fromPlanner} />
      <TenderPlatformBiddingComponent disabled={disabled} />
      <TenderPlatformConditionComponent disabled={disabled} fromPlanner={fromPlanner} />
      {!disabled && !fromPlanner && <TenderPlatformLotTemplateFieldset />}
    </fieldset>
  );
};
