import { useState } from 'react';

export const useChangeAvatar = ({ onAvatarChange, fileMaxSize }: { onAvatarChange: (e: any) => void; fileMaxSize?: number }) => {
  const [edit, setEdit] = useState(false);
  const [fileList, setFileList] = useState([]);

  const onFileChange = ({ fileList: newFileList }: { fileList: any }) => {
    if (fileMaxSize && newFileList[0]?.size > fileMaxSize) {
      return null;
    }

    setFileList(newFileList);
    setEdit(true);
    if (newFileList[0]) {
      onAvatarChange(newFileList[0]);
    }
  };
  return { edit, fileList, onFileChange };
};
