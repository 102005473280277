import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningClearRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { IndexedArray, PlanStatus, Provider } from '@common/types';
import { PlanID, PlanTotal } from '@common/types/dictionaries/plan';
import { INITIAL_CURRENT_PLAN } from '@modules/planning/children/by-date/constants/store';

export const planClearReducer = createReducer(initialState, {
  [planningClearRoutine.SUCCESS]: (state, action: PayloadAction<{ planID: PlanID; planTotal: IndexedArray<PlanTotal> }>) => {
    const { planID, planTotal } = action.payload;
    const plan = {
      Name: state.project?.Plan?.[planID].Name as string,
      NameFull: state.project?.Plan?.[planID].Name as string,
      Comment: null,
      CountIteration: 0,
      DepotOverloadID: 0,
      ID: planID,
      Info: null,
      OptimizeStart: null,
      OriginalID: 0,
      ProjectID: state.project?.ID,
      SpeedProfileID: 0,
      VehicleOverloadID: 0,
      VehicleTariffID: 0,
      VehicleTimelineID: 0,
      PlanConfigID: +Object.keys(state.defaultPlannerConfigs.data?.ILS.configs ?? {})[0],
      Changed: Date.now(),
      CalculateStart: null,
      CalculateDuration: null,
      Status: PlanStatus.Open,
      ProviderName: Provider.ILS,
      ExternalPlanConfigID: null,
      PlanningStart: null,
      PlanTotal: Object.values(planTotal)[0]
    };
    if (state.project?.Plan?.[plan.ID]) {
      state.project.Plan[plan.ID] = plan;
    } else if (state.project){
      state.project.Plan = { [plan.ID]: plan };
    }
    state.currentPlan = INITIAL_CURRENT_PLAN;
    return state;
  }
});

