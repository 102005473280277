import { FC } from 'react';
import { ILSCollapse, ILSCollapsePanel } from '@common/components';
import { useSelector } from 'react-redux';
import { DEFAULT_OPEN_TABLE } from '@modules/planning/children/data/constants';
import { collapsePanelsSelector } from '@modules/planning/children/data/selectors';
import { Dictionary } from '@common/types';
import { PlannerDataViewTable } from '@modules/planning/children/data/containers/view/tables';
import { PlanningDataCounter } from '@modules/planning/children/data/components/upload/counter';
import CustomScroll from 'react-customscroll';

interface IProps {
  hideEmpty: boolean;
}

export const PlannerDataSchemeCollapse: FC<IProps> = ({ hideEmpty }) => {
  const collapsePanels = useSelector(collapsePanelsSelector);

  return (
    <CustomScroll>
      <ILSCollapse defaultActiveKey={DEFAULT_OPEN_TABLE} className="main-collapse planning-data-view-collapse" accordion>
        {collapsePanels.map((dictionary) => {
          return (
            <ILSCollapsePanel key={dictionary} className={dictionary} header={<PlanningDataCounter dictionary={dictionary} />}>
              <PlannerDataViewTable hideEmpty={hideEmpty} dictionary={dictionary as Dictionary} />
            </ILSCollapsePanel>
          );
        })}
      </ILSCollapse>
    </CustomScroll>
  );
};
