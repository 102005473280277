import { ParserTariffParameters } from '@modules/catalog/types/tariff';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { parseTariff } from '@modules/catalog/helpers/tariff/parse-tariff';
import { tariffMathFunctionsList } from '@modules/catalog/constants/tariff';

export function parseFunction(formula: string, toMath = true, spaces = false, params?: ParserTariffParameters): string {
  if (spaces && params) {
    return parseTariff(formula.replace(JoinChar.Space, EMPTY_STRING), params).viewFormula;
  }

  for (let i = 0; i < tariffMathFunctionsList.length; i++) {
    //замена функций на понятные для js функции
    const find = toMath ? tariffMathFunctionsList[i].key : tariffMathFunctionsList[i].func;
    const replace = toMath ? tariffMathFunctionsList[i].func : tariffMathFunctionsList[i].key;
    if (formula.includes(find)) {
      formula = formula.replaceAll(find, replace);
    }
  }

  return formula;
}
