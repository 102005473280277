import { FC } from 'react';
import { FormFieldNames, FormLabels } from '@modules/orders-and-trips/types/form';
import { FakePreCostProposalSortOption, FakePreCostSortUnit } from '@modules/orders-and-trips/types/fake-pre-cost';
import { SortDirection } from '@common/types';
import { CurrencySign } from '@common/constants';
import { ILSDatePicker, ILSTimePicker } from '@common/components';
import { disableDateBeforeToday } from '@common/utils/helpers/date-time/disable-date-before-today';
import { disableTimeBeforeNow } from '@common/utils/helpers/date-time/disable-time-before-now';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';

export type FormFieldData = {
  Component: FC<unknown>;
  name: FormFieldNames;
  label: FormLabels;
  defaultProps?: Record<string, unknown>;
};

export const DATE_TIME_BLOCK_LOAD: FormFieldData[] = [
  {
    Component: ILSDatePicker,
    name: FormFieldNames.LoadDate,
    label: FormLabels.LoadDate,
    defaultProps: {
      disabledDate: disableDateBeforeToday,
      disabledTime: disableTimeBeforeNow
    }
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.LoadStart,
    label: FormLabels.LoadStart,
    defaultProps: {
      allowClear: false
    }
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.LoadEnd,
    label: FormLabels.LoadEnd,
    defaultProps: {
      allowClear: false
    }
  }
];

export const DATE_TIME_BLOCK_UNLOAD: FormFieldData[] = [
  {
    Component: ILSDatePicker,
    name: FormFieldNames.UnloadDate,
    label: FormLabels.UnloadDate
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.UnloadStart,
    label: FormLabels.UnloadStart,
    defaultProps: {
      allowClear: false
    }
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.UnloadEnd,
    label: FormLabels.UnloadEnd,
    defaultProps: {
      allowClear: false
    }
  }
];

export const proposalInitialSortOption: FakePreCostProposalSortOption = {
  unit: FakePreCostSortUnit.Price,
  direction: SortDirection.Asc
};

export const proposalSortOptionsList = [FakePreCostSortUnit.Price, FakePreCostSortUnit.Term];

export const PROPOSAL_SORT_OPTIONS_TITLES = {
  [FakePreCostSortUnit.Price]: `По стоимости ${CurrencySign.RUB}`,
  [FakePreCostSortUnit.Term]: (
    <div className="children">
      <p>По сроку доставки</p>
      <ILSSVG icon="clock" />
    </div>
  )
};

export const SERVICES_WITH_CANCEL_WARNING = [TenderOutFastDeliveryServices.BaikalService, TenderOutFastDeliveryServices.Pek];
