import { createSelector } from 'reselect';
import { SettingsStore, SettingsValues } from '@modules/settings/types/store';

export const planningSettingsSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings.values.planning,
  (planningSettingsParameters) => planningSettingsParameters as SettingsValues['planning']
);

export const planningRedisSettings = createSelector(planningSettingsSelector, (planningSettingsParameters) => {
  const { useRedis } = planningSettingsParameters.parameters ?? {};
  return { useRedis };
});

export const planningGeocodeSettings = createSelector(planningSettingsSelector, (planningSettingsParameters) => {
  const { geocodeService } = planningSettingsParameters.parameters ?? {};
  return { geocodeService };
});

export const planningMapSettingsSelector = createSelector(planningSettingsSelector, (planningSettingsParameters) => {
  const {
    mapZoomCluster,
    disableClustering,
    enableAutoFocusOnChangeTrip,
    unresolvedMarkerSource,
    hideLinesOnTrackAbsent,
    useRedis,
    activeWidth,
    inactiveWidth,
    drawPairForUnresolved
  } = planningSettingsParameters.parameters ?? {};
  return {
    mapZoomCluster,
    disableClustering,
    enableAutoFocusOnChangeTrip,
    unresolvedMarkerSource,
    hideLinesOnTrackAbsent,
    useRedis,
    activeWidth,
    inactiveWidth,
    drawPairForUnresolved
  };
});
