import { EMPTY_STRING, VATLabel, VATValue } from '@common/constants';
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general/date-time';
import { compareAsString, timestampToDatetimeString } from '@common/utils';
import { mockVehicleTypes } from '@modules/tender-platform/__tests/__mocks/store/tender';
import {
  dataForGroupTenderCreationSelector,
  initialDataForGroupTenderCreationSelector
} from '@modules/tender-platform/children/tender-own/selectors/tender';
import { RouteList, RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TENDER_FORM_TABLE_FIELDS_PROPERTIES, TenderFormTableField } from '@modules/tender-platform/constants/content';
import {
  SeparateVehicle,
  SeparateVehicleLabel,
  TenderIconTitle,
  TenderType,
  TenderTypeLabel,
  TenderVisibilityStatus,
  VehicleLoadType,
  VehicleLoadTypeLabel
} from '@modules/tender-platform/types';
import { createSelector } from 'reselect';

export const routeListSelector = (id: RoutesID) =>
  createSelector(dataForGroupTenderCreationSelector, (dataForGroupTenderCreationSelector) => {
    return { route: dataForGroupTenderCreationSelector?.[id]?.RouteList } as { route: RouteList };
  });
// TODO чтобы не захламлять множеством break,
//  может сделаем map-object, где будет ключ-значение, в качестве ключа field, а в качестве значения - нужный маппинг
export const routesDataSelector = (field: TenderFormTableField) =>
  createSelector(initialDataForGroupTenderCreationSelector, (dataForGroupTenderCreation) => {
    const data = {};
    for (let id in dataForGroupTenderCreation) {
      data[id] = {};
      const value = dataForGroupTenderCreation?.[id]?.[field] ?? TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field].defaultValue;

      switch (field) {
        case TenderFormTableField.Type:
          data[id][field] = TenderTypeLabel[TenderType[value]];
          break;
        case TenderFormTableField.Private:
          data[id][field] = TenderIconTitle[TenderVisibilityStatus[value]];
          break;
        case TenderFormTableField.VehicleLoadType:
          data[id][field] = VehicleLoadTypeLabel[VehicleLoadType[value]];
          break;
        case TenderFormTableField.Vat:
          data[id][field] = VATLabel[VATValue[value]];
          break;
        case TenderFormTableField.SeparateVehicle:
          data[id][field] = SeparateVehicleLabel[SeparateVehicle[value]];
          break;
        case TenderFormTableField.CountShipments:
          data[id][field] = value;
          break;
        case TenderFormTableField.Schedule:
          data[id][field] = value;
          break;
        case TenderFormTableField.RouteList:
          break;
        case TenderFormTableField.OfferStartDT:
        case TenderFormTableField.OfferEndDT:
        case TenderFormTableField.ExecutionStartDT:
        case TenderFormTableField.ExecutionEndDT:
          data[id][field] = timestampToDatetimeString(value, true, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT);
          break;
        case TenderFormTableField.VehicleType:
          data[id][field] = Object.values(mockVehicleTypes).find(({ ID }) => compareAsString(value, ID))?.Name;
          break;
        default:
          data[id][field] = value ?? EMPTY_STRING;
      }
    }
    return {
      data
    };
  });
