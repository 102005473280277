import { isEmpty, isNil, isString } from 'lodash';
import { Keys, TableRecord } from '@common/types';
import { findRowByIDInNestedDataSource } from '@modules/catalog/helpers';
import { getRecordsFromDataSourceById, getSelectedRowsWithoutGroupIDs } from '@common/components/data-display/table/utils';

const uniqueObjects = (arr: TableRecord[]) => {
  const uniqueMap = new Map();

  // Итерируемся по массиву объектов
  arr.forEach((obj) => {
    // Проверяем, есть ли объект с таким id в Map
    if (!uniqueMap.has(obj.key)) {
      // Если нет, добавляем его в Map
      uniqueMap.set(obj.key, obj);
    }
  });

  // Возвращаем массив уникальных объектов из значений Map
  return Array.from(uniqueMap.values());
};

const flattenArray = (arr: TableRecord[]) => {
  return arr.reduce((result: TableRecord[], obj) => {
    result.push(obj);
    if (obj.children) {
      result = result.concat(flattenArray(obj.children));
      // delete obj.children; // Optional: remove children property if needed
    }
    return result;
  }, []);
};

/**
 * Функция возвращает все данные строк таблицы (tableRecord[]) по массиву полученных ID.
 * Также функция проверяет, есть ли в массиве ID группирующие строки.
 * @param selectedRowKeys массив ID выделенных строк.
 * @param dataSource Array<Record<string, any>> - данные таблицы
 * @returns tableRecord[]
 */

export const getRecordsToDelete = (selectedRowKeys: Keys = [], dataSource?: any) => {
  if (isEmpty(selectedRowKeys) || isNil(dataSource)) return [];

  const hasGrouping = selectedRowKeys?.some(isString);
  let removeRecords;

  if (hasGrouping) {
    const idsWithoutGroupIDs = getSelectedRowsWithoutGroupIDs(selectedRowKeys);
    removeRecords = idsWithoutGroupIDs.reduce((result: TableRecord[], id) => {
      const row = findRowByIDInNestedDataSource(dataSource, id);
      if (row) {
        result.push(row as TableRecord);
      }
      return result;
    }, []);
  } else {
    const flattenedArray = dataSource.some((record: TableRecord) => record.children) ? uniqueObjects(flattenArray(dataSource)) : dataSource;
    removeRecords = getRecordsFromDataSourceById(selectedRowKeys, flattenedArray);
  }
  return removeRecords;
};
