import { Dictionary, ReferenceTablesData, Vehicle } from '@common/types';
import { compareAsString } from '@common/utils';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { createOptionsForTrailer2 } from '@modules/catalog/helpers/table/create-options-for-trailer-2';

export const getTrailersRefData = ({
  vehicleData,
  RearCouplingTypeID
}: {
  vehicleData: Array<Vehicle>;
  RearCouplingTypeID: Vehicle['RearCouplingTypeID'];
}) => {
  const notIsAutomotiveVehicles = vehicleData?.filter(({ IsAutomotive, Deleted }: Vehicle) => !IsAutomotive && !Deleted);
  const refData: ReferenceTablesData = {
    Trailer1ID: {
      options: notIsAutomotiveVehicles.filter((vehicle: Vehicle) => compareAsString(vehicle.FrontCouplingTypeID, RearCouplingTypeID)),
      dictionary: Dictionary.Vehicle,
      fieldNames: DEFAULT_FIELD_NAMES
    },
    Trailer2ID: {
      options: createOptionsForTrailer2({ notIsAutomotiveVehicles }),
      dictionary: Dictionary.Vehicle,
      fieldNames: DEFAULT_FIELD_NAMES
    }
  };
  return refData;
};
