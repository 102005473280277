import { Dispatch } from 'redux';
import { History } from 'history';
import { selectOneVehicleRoutine, setActiveVehicleRoutine } from '@modules/monitoring/children/event-details/actions';
import qs from 'query-string';
import { ModulePath } from '@common/types';
import { MonitoringQueryParams } from '@modules/monitoring/constants';
import { TabType } from '@modules/monitoring/children/event-details/types/event-details-tabs';

export const openVehicleDetails = (waypointID: number | undefined, VehicleID: number | undefined, dispatch: Dispatch, history: History) => {
  dispatch(setActiveVehicleRoutine({ activeVehicleID: VehicleID }));
  dispatch(selectOneVehicleRoutine({ vehicleID: VehicleID }));
  setTimeout(() => {
    const url = qs.stringifyUrl({
      url: `${ModulePath.Monitoring}${ModulePath.MonitoringDetails}`
    });
    history.push(url, {
      [MonitoringQueryParams.ACTIVE_TAB]: waypointID ? TabType.PlanFact : TabType.Track,
      [MonitoringQueryParams.WAYPOINT_ID]: waypointID,
      [MonitoringQueryParams.FOCUS_ON_MAP]: true
    });
  });
};

export const openVehicleDetByPage = (tabType: TabType, VehicleID: number | undefined, dispatch: Dispatch, history: History) => {
  dispatch(selectOneVehicleRoutine({ vehicleID: VehicleID }));
  setTimeout(() => {
    const url = qs.stringifyUrl({
      url: `${ModulePath.Monitoring}${ModulePath.MonitoringDetails}`
    });
    history.push(url, {
      [MonitoringQueryParams.ACTIVE_TAB]: tabType,
      [MonitoringQueryParams.FOCUS_ON_MAP]: true
    });
  });
};
