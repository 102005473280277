import moment from 'moment';
import { ContractTemplateData, ContractTemplateResponseData } from '@modules/orders-and-trips/types/contract-template';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general';
import { DASH } from '@common/constants';

export const getContractDataToTemplate = (contractData: ContractTemplateResponseData): ContractTemplateData => {
  return {
    ...contractData,
    Report: {
      ...contractData.Report,
      ContractDate: moment.unix(contractData.Report?.ContractDate).format(ILS_MAIN_INTERFFACE_DATE_FORMAT),
      SourceDateTime: contractData.Report?.SourceDateTime
        ? moment
            .unix(contractData.Report?.SourceDateTime)
            .utcOffset(contractData.Report?.UTCOffset)
            .format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT)
        : DASH
    }
  };
};

