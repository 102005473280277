import { CellType, ColumnTable, TableRecord } from '@common/types';

//TODO перенести все конфиги таблиц в модули
export const AttachmentColumn: ColumnTable<TableRecord> = {
  type: CellType.ButtonClickableDropdown,
  dataIndex: 'Attachments',
  title: 'Посмотреть вложения',
  titleText: 'Кнопка - Посмотреть вложения',
  key: 'Attachments',
  ellipsis: true,

  width: 100
};
export const DriverComment: ColumnTable<TableRecord> = {
  type: CellType.String,
  dataIndex: 'LastComment',
  title: 'Комментарий водителя из мобильного приложения',
  key: 'LastComment',
  ellipsis: true,
  editable: false,

  width: 100
};
export const LastStatus: ColumnTable<TableRecord> = {
  type: CellType.String,
  dataIndex: 'LastStatus',
  title: 'Статус из мобильного приложения',
  key: 'LastStatus',
  ellipsis: true,
  editable: false,

  width: 100
};
export const LastStatusDT: ColumnTable<TableRecord> = {
  type: CellType.TimeStringUtc,
  dataIndex: 'LastStatusDT',
  title: 'Время установки статуса из мобильного приложения',
  key: 'LastStatusDT',
  ellipsis: true,
  editable: false
};
