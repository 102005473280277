import { FC } from 'react';
import { ILSPlanningResultTableConfirmButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/button-confirm';
import { useSelector } from 'react-redux';
import { FileProtectOutlined } from '@ant-design/icons/lib/icons';
import { Plan } from '@common/types';
import { plannerPlanningPlanTableForExecuteButtonSelector } from '@modules/planning/children/by-date/selectors/components/execute-button';

interface IProps {
  inactive?: boolean;
  handleSetExecution(planID: Plan['ID']): void;
  handleUnsetExecution(planID: Plan['ID']): void;
}

export const PlannerPlanningProjectTableForExecuteButtonContainer: FC<IProps> = ({ handleSetExecution, handleUnsetExecution }) => {
  const { btnText, confirmText, disabled, isPlanBeforeExecution, activePlanID, isPlanNotEditable } = useSelector(
    plannerPlanningPlanTableForExecuteButtonSelector
  );

  const handleExecution = () => {
    if (disabled || !activePlanID) return;
    if (isPlanBeforeExecution) {
      handleSetExecution(activePlanID);
    } else {
      handleUnsetExecution(activePlanID);
    }
  };

  if (isPlanNotEditable) return <></>;

  return (
    <ILSPlanningResultTableConfirmButton
      buttonTooltip={btnText}
      confirmText={confirmText}
      onConfirm={handleExecution}
      icon={<FileProtectOutlined className={'ils-svg primary'} />}
      disabled={disabled}
    />
  );
};
