import { IILSTableHeaderProps, ILSTableHeader } from '@components/data-display/table/components/ils-table-header';
import { useTableTitle } from '@components/data-display/table/hooks/use-table-title';
import { ActiveTableParameters, FilterSort, TableRecord } from '@common/types/components/table';
import { Keys } from '@common/types';
import { handleCreateAndSelect } from '@components/data-display/table/utils/helpers/handlers/handle-create-and-select';
import { OnFocusRow } from '@components/data-display/table/hooks/use-focus-active-page-and-row';

export interface GetTableTitleParameters<R extends TableRecord> extends Omit<IILSTableHeaderProps<R>, 'handleCreateSelect' | 'focusedRow'> {
  active: ActiveTableParameters;
  onFocusRow: OnFocusRow;
  selectedRowKeys: Keys;
  clearSorting(): void;
  clearFilters(): void;
  clearSelectedRowKeys(): void;
  filterSortInfo: FilterSort;
}

export const renderTableTitle = <R extends TableRecord>({
  handleBindTimeZone,
  actionTableButtons,
  settingsTableButtons,
  customClearFilters,
  clearSorting,
  closeTableButton,
  hasFilters,
  clearFilters,
  tableTitle,
  tableWidgets,
  recordTableButtons,
  handleOpenExportModal,
  clearSelectedRowKeys,
  selectedRowKeys,
  handleDelete,
  handleCheckDelete,
  handleCheckCoords,
  isModal,
  loading,
  config,
  uploadConfiguration,
  onCopy,
  handleUpload,
  handleReload,
  handleRestore,
  handleDownload,
  dataSource,
  dictionary,
  active,
  filterSortInfo,
  notificationEnabled,
  showDeleted,
  handleCreate,
  dictionaryLoad,
  onFocusRow,
  refData,
  refTable,
  setShowReference,
  setSelectedRowKeys,
  setShowDeleted
}: GetTableTitleParameters<R>) => {
  const { hasHeader } = useTableTitle({
    tableTitle,
    config,
    recordTableButtons,
    tableWidgets,
    actionTableButtons,
    settingsTableButtons
  });

  return () =>
    hasHeader && (
      <ILSTableHeader
        //@ts-ignore
        setShowReference={setShowReference}
        refTable={refTable}
        refData={refData}
        handleOpenExportModal={handleOpenExportModal}
        closeTableButton={closeTableButton}
        hasFilters={hasFilters}
        uploadConfiguration={uploadConfiguration}
        focusedRow={active.key}
        onCopy={onCopy}
        loading={loading}
        dataSource={dataSource}
        tableTitle={tableTitle}
        tableWidgets={tableWidgets}
        recordTableButtons={recordTableButtons}
        actionTableButtons={actionTableButtons}
        selectedRowKeys={selectedRowKeys}
        clearSorting={clearSorting}
        clearFilters={clearFilters}
        clearSelectedRowKeys={clearSelectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        config={config}
        handleDownload={handleDownload}
        handleReload={handleReload}
        dictionaryLoad={dictionaryLoad}
        handleDelete={handleDelete}
        handleUpload={handleUpload}
        handleCheckDelete={handleCheckDelete}
        handleBindTimeZone={handleBindTimeZone}
        handleCreateSelect={handleCreateAndSelect({ handleCreate, onFocusRow })}
        handleRestore={handleRestore}
        showDeleted={showDeleted}
        setShowDeleted={setShowDeleted}
        handleCheckCoords={handleCheckCoords}
        dictionary={dictionary}
        isModal={isModal}
        settingsTableButtons={settingsTableButtons}
        filterSortInfo={filterSortInfo}
        customClearFilters={customClearFilters}
        notificationEnabled={notificationEnabled}
      />
    );
};
