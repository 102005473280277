import { planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { routesIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { RememberFocusNExpandRows } from '@modules/planning/children/by-date/types/record';

/**
 * rememberFocusNExpandRowsWorker - запоминает фокус и открытые/закрытые строки
 *
 * @param action
 */
function* rememberFocusNExpandRowsWorker(action: PayloadAction<RememberFocusNExpandRows>) {
  const { success, failure, fulfill } = planningRememberFocusNExpandRowsRoutine;
  const routeIDs: number[] = yield select(routesIDsSelector);

  try {
    yield put(success({ ...action.payload, routeIDs }));
  } catch (error) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* rememberFocusNExpandRowsWatcher() {
  yield takeLatest(planningRememberFocusNExpandRowsRoutine.trigger, rememberFocusNExpandRowsWorker);
}
