import { ILSButton, ILSTooltip } from '@components/index';
import { ReactSVG } from 'react-svg';
import React, { FC } from 'react';
import { IMarkerState } from '@modules/road-graph/route-test/types/map';

interface Props {
  title: string;
  data: IMarkerState | undefined;
  handleClick: (title: string) => void;
}

const ILSRoadGraphTestSetMarkerButton: FC<Props> = (props) => {
  const { title, data, handleClick } = props;

  return (
    <ILSTooltip placement="right" title={'Установка начального маркера'}>
      <ILSButton
        className={`map-control-btn map-set-marker-btn ${data?.moving ? 'active' : ''}`.trim()}
        onClick={() => handleClick(title)}
        icon={<ReactSVG src={'/assets/icons/road-graph/location.svg'} />}
      >
        {title}
      </ILSButton>
    </ILSTooltip>
  );
};
export default ILSRoadGraphTestSetMarkerButton;
