import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { Dictionary, Order, TableName } from '@common/types';

export const selectedTableRowsSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.selectedTableRows,
  (selectedTableRows) => selectedTableRows
);

export const selectedRouteTableRowsSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.selectedTableRows,
  (selectedTableRows) => {
    return {
      selectedTripsIDs: selectedTableRows[TableName.RoutesTable]?.recordIDs?.[Dictionary.Trip] ?? [],
      selectedRoutesIDs: selectedTableRows[TableName.RoutesTable]?.recordIDs?.[Dictionary.Route] ?? [],
      selectedWaypointIDs: selectedTableRows[TableName.RoutesTable]?.recordIDs?.[Dictionary.Waypoint] ?? []
    };
  }
);

export const selectedOrderTableRowsSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.selectedTableRows,
  (selectedTableRows) => {
    return {
      selectedOrders: (selectedTableRows[TableName.PlanningNotDeliveredOrder]?.recordIDs?.[Dictionary.Order] ??
        selectedTableRows[TableName.PlanningNotDeliveredOrder]?.recordIDs ??
        []) as Array<Order['ID']>
    };
  }
);
