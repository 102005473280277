import { Dictionary, IndexedArray, TableColumnName } from '@common/types';
import { OrderActionTotal } from '@common/types/dictionaries/order';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { sortDataSourceByNumberColumn } from '@modules/planning/children/by-date/helpers/tables/sort-data-source-by-number-column';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';

export const orderActionsToChildren = (orderActions: IndexedArray<OrderActionTotal> | Array<never>) => {
  return sortDataSourceByNumberColumn(orderActions).map((orderAction) => {
    const color = getVehicleRouteColor({ RouteColor: orderAction.RouteColor, ID: orderAction.VehicleID });
    return {
      ...orderAction,
      key: orderAction.ID,
      OrderID: orderAction.OrderID,
      FullName: orderAction.FullName || 'Груз',
      FullName_icon: '/planning/' + (orderAction.ActionType ? 'bottom-arrow' : 'top-arrow'),
      dictionary: Dictionary.OrderAction,
      [TableColumnName.Restriction]: Object.values(orderAction.RestrictionEnum ?? {}).join(JoinChar.Comma),
      [TableColumnName.ID]: orderAction.ID,
      [TableColumnName.RouteColor]: color,
      [TableColumnName.Status]: orderAction.Status,
      [TableColumnName.Notification]: EMPTY_STRING
    };
  });
};
