import { TenderFormField } from '@modules/tender-platform/constants/content';
import { FormItemProps } from 'antd';
import { RouteField } from '@modules/tender-platform/types';
import { Field } from '@common/constants';
import { validateTenderLotMinPrice } from '@modules/tender-platform/utils/helpers/form/validators/validate-tender-lot-min-price';

export type FormRules<F extends string> = Record<F, FormItemProps['rules']>;

export const REGIONAL_CITIES_FORM_ITEMS = [TenderFormField.RegionalCitySender, TenderFormField.RegionalCityRecipient];
export const ROUTE_FORM_ITEMS: Array<RouteField> = [Field.Source, Field.Target];

export const RULE_FOR_TENDER_LOT_MIN_PRICE = [
  ({ getFieldValue }: { getFieldValue(name: TenderFormField): string }) => ({
    validator: (_: any, value: string) => validateTenderLotMinPrice(value, getFieldValue)
  })
];
