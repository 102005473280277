import { call, put, takeLatest } from 'redux-saga/effects';
import { DataSchemeAPI } from '@modules/planning/children/scheme-editor/api';
import { getCurrentSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';

function* getCurrentSchemeWorker() {
  const { request, success, failure, fulfill } = getCurrentSchemeRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(DataSchemeAPI.getCurrentScheme);
    yield put(success({ scheme: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getCurrentSchemeWatcher() {
  yield takeLatest(getCurrentSchemeRoutine.trigger, getCurrentSchemeWorker);
}
