import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import locale_ru from 'antd/es/date-picker/locale/ru_RU';
import locale_en_us from 'antd/es/date-picker/locale/en_US';

export const getLocale = (locale_string: PickerLocale | string = locale_ru) => {
  switch (locale_string) {
    case 'ru_RU':
      return locale_ru;
    case 'en_US':
      return locale_en_us;
    default:
      return locale_ru;
  }
};
