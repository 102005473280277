import { ILSButton, ILSSkeleton } from '@common/components';
import { useInterval } from '@common/hooks/use-interval';
import { DateFormat, IndexedArray, ModulePath } from '@common/types';
import { MonitoringServedDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { concatRoutes } from '@common/utils/general/concatRoutes';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringExportExelRoutine } from '@modules/monitoring/actions';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import classNames from 'classnames';
import moment from 'moment';
import { FC, memo, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { getTrackDataRoutine } from '../../event-details/actions';
import { MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS } from '../../event-details/configs/api-intervals';
import { useLoadDetails } from '../../event-details/hooks/use-load-details';
import { tracksSelector } from '../../event-details/selectors';
import ILSDeliveryStatusInfoComponent from '../components/delivery-status-info';
import { dashboardTableComponents } from '../configs/dashboard-table';
import { DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../constants';
import MonitoringDashboardContextMenuContainer from '../containers/context-menu';
import { DashboardTables } from '../types';
import { ContextMenuState } from '../types/context-menu';
import { ILSMonitoringTableServed } from './components';
import { StartedVehicles } from './components/started-vehicles';
import { dataTypeVariants } from '@modules/monitoring/types/graph';
import {
  forwarderPointStatusesSubMenuSelector,
  tripStatusSubMenuSelector
} from '@modules/monitoring/children/dashboard/selectors/dashboard';
import { openVehicleDetails } from '@modules/monitoring/helpers/open-vehicle-details';

interface IProps {
  currentTable?: DashboardTables;
  tableProps?: {
    dataSource: Array<any> | IndexedArray<any>;
    dataSourceAll?: IndexedArray<MonitoringServedDataModel> | Array<MonitoringServedDataModel>;
    handleUpdateView: (id: string | number | string[], value: 0 | 1) => void;
  };
  isFetching: boolean | undefined;
}

// Основной контейнер для компонентов таблиц
export const MonitoringTableContainer: FC<IProps> = ({ currentTable, tableProps, isFetching }) => {
  if (!tableProps?.dataSource) return null;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);
  const { submenu, isActive: isActivePointMenu } = useAppSelector(
    forwarderPointStatusesSubMenuSelector({ dispatch, currentTableName: currentTable })
  );
  const { submenu: tripStatusSubMenu, isActive: isActiveTripMenu } = useAppSelector(
    tripStatusSubMenuSelector({ dispatch, currentTableName: currentTable })
  );

  //True Если endDate это текущий или более поздний день
  const isCurrentDay = Boolean(
    moment(endDate, DateFormat.YYYYMMDDDash).utc(false).unix() >=
      moment(moment().utc(false).format(DateFormat.YYYYMMDDDash), DateFormat.YYYYMMDDDash).utc(false).unix()
  );

  const [contextMenu, setContextMenu] = useState<ContextMenuState>({});
  const tableRef = useRef<HTMLDivElement>(null);

  const [selectedVehicleID, setSelectedVehicleID] = useState<undefined | string>(undefined);

  const VehicleTracks = useAppSelector(tracksSelector);

  const onSelectedVehicleID = useCallback(
    (vehicleID: string) => {
      if (vehicleID !== selectedVehicleID) {
        setTimeout(() => setSelectedVehicleID(vehicleID));
      }
    },
    [setSelectedVehicleID, selectedVehicleID]
  );

  const loadTrackDetails = useLoadDetails({
    dispatch,
    storeData: VehicleTracks,
    updateInterval: MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.TRACK,
    routine: getTrackDataRoutine,
    selectorData: { startDate, endDate },
    active: !!(selectedVehicleID && currentTable === DashboardTablesObjMetrics.FuelDrain),
    payload: {
      vehicleID: selectedVehicleID,
      updates:
        selectedVehicleID === VehicleTracks.loadedVehicleId &&
        Object.keys(VehicleTracks.data).length > 0 &&
        VehicleTracks.lastUpdateInterval?.from === startDate &&
        VehicleTracks.lastUpdateInterval?.till === endDate
    }
  });

  useInterval(loadTrackDetails, MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.TRACK, [
    selectedVehicleID,
    VehicleTracks.loadedVehicleId,
    startDate,
    endDate
  ]);

  // TODO Плохое название для функции
  const onContextMenu = useCallback(
    (record: any, e: MouseEvent) => {
      setContextMenu({});
      const tableCoordinates = tableRef?.current?.getBoundingClientRect();

      if (tableCoordinates) {
        const x = e.clientX - (tableCoordinates.x ?? 0);
        const y = e.clientY - (tableCoordinates.y ?? 0);
        const maxHeight = tableCoordinates.height * y;

        setTimeout(() => {
          setContextMenu({ record, coords: { x, y }, maxHeight, visible: true });
        }, 100);
      }
    },
    [tableRef, setContextMenu]
  );
  const loadExel = useCallback(
    (exelData: MonitoringExelData | undefined, fileName: string) => {
      if (exelData) {
        const { headers, rows } = exelData;
        dispatch(monitoringExportExelRoutine({ headers: headers, rows: rows, fileName }));
      }
    },
    [monitoringExportExelRoutine]
  );

  const handleVehicleDetails = ({ WaypointID, VehicleID }: any) => {
    openVehicleDetails(WaypointID, VehicleID, dispatch, history);
  };

  const contextMenuList = [
    //NOTE установка статуса доступна только для WAYPOINT
    { text: 'Установить статус', submenu, isActive: isActivePointMenu },
    { text: 'Установить статус рейса', submenu: tripStatusSubMenu, isActive: isActiveTripMenu },
    {
      text: 'Детализация',
      handler: handleVehicleDetails
    }
  ];
  const tableClass = currentTable ? ' ' + dashboardTableComponents?.[currentTable]?.tableClass : '';

  const monitoringDeliveryUrl = concatRoutes([ModulePath.Monitoring, ModulePath.MonitoringDelivery]);
  const goToMonitoringDelivery = () => history.push(monitoringDeliveryUrl);

  const Component = currentTable ? dashboardTableComponents?.[currentTable]?.table : undefined;
  const SubHeadComponent = currentTable ? dashboardTableComponents?.[currentTable]?.headSubComponent : undefined;
  const table = Component ? (
    <Component
      {...tableProps}
      onContextOpen={onContextMenu}
      onSelectedVehicleID={onSelectedVehicleID}
      selectedVehicleID={selectedVehicleID}
      loadExel={loadExel}
    />
  ) : (
    <ILSMonitoringTableServed {...tableProps} isCurrentDay={isCurrentDay} onContextOpen={onContextMenu} loadExel={loadExel} />
  );
  const sub = SubHeadComponent ? (
    <div className="ils-monitoring-dashboard-sub-wrapper">
      <SubHeadComponent
        {...tableProps}
        contextMenuList={contextMenuList}
        tillDate={endDate}
        title={(currentTable && dashboardTableComponents?.[currentTable]?.title) || ''}
        selectedVehicleID={selectedVehicleID}
        listOfGraph={[dataTypeVariants.Fuel, dataTypeVariants.Speed]}
      />
    </div>
  ) : undefined;

  return (
    <ILSSkeleton loading={!Object.values(tableProps?.dataSource)?.length && isFetching} active paragraph={{ rows: 13 }}>
      {sub}
      {!currentTable || currentTable === DashboardTablesNumberMetrics.Served || currentTable === DashboardTablesNumberMetrics.Total ? (
        <StartedVehicles dataSource={tableProps?.dataSource} />
      ) : null}
      <div className={classNames('ils-monitoring-dashboard-table-wrapper', tableClass)}>
        <div className="display-relative" ref={tableRef}>
          {table}
        </div>

        <MonitoringDashboardContextMenuContainer
          setContextMenu={setContextMenu}
          visible={contextMenu['visible']}
          record={contextMenu['record']}
          coords={contextMenu['coords']}
          maxHeight={contextMenu['maxHeight']}
          menu={[contextMenuList]}
        />
      </div>
      {!currentTable || currentTable === DashboardTablesNumberMetrics.Served || currentTable === DashboardTablesNumberMetrics.Total ? (
        <div className="ils-monitoring-dashboard-table-statuses">
          <ILSDeliveryStatusInfoComponent />
          <ILSButton type="primary" onClick={goToMonitoringDelivery}>
            Перейти в монитор доставки
          </ILSButton>
        </div>
      ) : null}
    </ILSSkeleton>
  );
};

export const ILSMonitoringTableContainer = memo(MonitoringTableContainer);
