import { IndexedArray } from '@common/types';
import { UnviewedData } from '@common/types/dictionaries/monitoring';
import { MonitoringWaypointUnviewedData } from '@common/types/dictionaries/monitoring-waypoint';
import { MONITORING_IMEI_DT_SEPARATOR } from '../constants';

// Функции для обновления данных о просмотренных событиях в таблицах

export const updateViewedTableWaypointData = <T extends (UnviewedData & { ID: number } | null |  undefined)>(
  waypointData: IndexedArray<T>,
  dependentKey: string | Array<string>,
  value: 0 | 1
): IndexedArray<T> => {
  if (waypointData) {
    for (let ind in waypointData) {
      const wp = waypointData[ind];
      if (wp?.ID && checkWP(wp.ID, dependentKey)) {
        wp.Viewed = value;
      }
    }
  }
  return waypointData;
};

export const updateViewedTableTripData = <
  T extends UnviewedData & { ID: number } & {
    Waypoint: IndexedArray<MonitoringWaypointUnviewedData | null | undefined>;
  }
>(
  tripData: IndexedArray<T>,
  dependentKey: string | Array<string>,
  value: 0 | 1
): IndexedArray<T> => {
  if (tripData) {
    for (let ind in tripData) {
      const trip = tripData[ind];
      if (checkTrip(trip, dependentKey)) {
        trip.Viewed = value;
      }
    }
  }
  return tripData;
};

export const updateViewedTableSensorData = <T extends UnviewedData & { IMEI?: number; Imei?: number; DT: number }>(
  data: IndexedArray<T>,
  dependentKey: string | Array<string>,
  value: 0 | 1
): IndexedArray<T> => {
  for (const key in data) {
    const item = data[key];
    if (checkSensor(item.IMEI || item.Imei, item.DT, dependentKey)) {
      item.Viewed = value;
    }
  }
  return data;
};
export const updateViewedTableFuelData = <T extends UnviewedData & { IMEI?: number; Imei?: number; DT: number }>(
  data: IndexedArray<T>,
  dependentKey: string | Array<string>,
  value: 0 | 1
): IndexedArray<T> => {
  if (!data || !Object.values(data)?.length) return data;
  for (const ind in data) {
    const item = data[ind];
    if (checkSensor(item.IMEI || item.Imei, item.DT, dependentKey)) {
      item.Viewed = value;
    }
  }
  return data;
};

const checkWP = (ID: number, dependentKey: string | Array<string>) => {
  if (Array.isArray(dependentKey)) {
    return dependentKey.includes(ID.toString());
  } else {
    return dependentKey.toString() === ID.toString();
  }
};

const checkSensor = (IMEI: number | undefined, DT: number, dependentKey: string | Array<string>) => {
  if (!IMEI) return false;
  if (Array.isArray(dependentKey)) {
    return dependentKey.find((item) => {
      const [itemImei, itemDT] = item.split(MONITORING_IMEI_DT_SEPARATOR);
      return itemImei === IMEI.toString() && itemDT === DT.toString();
    });
  } else {
    const [itemImei, itemDT] = dependentKey.split(MONITORING_IMEI_DT_SEPARATOR);
    return itemImei === IMEI.toString() && itemDT === DT.toString();
  }
};

function checkTrip<
  T extends UnviewedData & { ID: number } & {
    Waypoint: IndexedArray<MonitoringWaypointUnviewedData | undefined | null>;
  }
>(trip: T, dependentKey: string | string[]): boolean {
  return Boolean(trip?.Waypoint && !!Object.values(trip.Waypoint)?.every((wp) => wp?.ID && checkWP(wp.ID, dependentKey)));
}

