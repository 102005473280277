import { put, takeLatest } from 'redux-saga/effects';
import { clearStateRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { InitialState } from '@modules/orders-and-trips/types/general';

function* clearStateWorker(action: PayloadAction<{ key: keyof InitialState }>) {
  const { success, failure, fulfill } = clearStateRoutine;

  try {
    yield put(success(action.payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* clearStateWatcher() {
  yield takeLatest(clearStateRoutine.trigger, clearStateWorker);
}
