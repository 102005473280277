import { TableConfig } from '@common/types';
import { CELL_TYPE_MIN_WIDTH } from '@components/data-display/table/constants';
import { getColumnWidthByTypeOrName } from './column/get-column-width-by-type-or-name';

export const createTableConfig = (config: TableConfig) => {
  return {
    ...config,
    columns: config?.columns.map((column) => ({
      ...column,
      style: { minWidth: getColumnWidthByTypeOrName(CELL_TYPE_MIN_WIDTH[column.type], column.title) }
    }))
  };
};
