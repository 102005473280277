import { getBoardsCommonReducer } from '@modules/analytics/common/reducers/get-boards';
import { getBoardSetsCommonReducer } from '@modules/analytics/common/reducers/get-board-sets';
import reduceReducers from 'reduce-reducers';
import initialState from '../store';
import { selectBoardCommonReducer } from '@modules/analytics/common/reducers/select-board';
import { selectBoardSetCommonReducer } from '@modules/analytics/common/reducers/select-board-set';
import { analyticsTenderActions } from '../actions';
import { analyticsGetBoardWidgetReducer } from '@modules/analytics/common/reducers/get-board-widget';
import { analyticsGetWidgetDataReducer } from '@modules/analytics/common/reducers/get-widget-data';
import { setParamsCommonReducer } from '@modules/analytics/common/reducers/set-params';
import analyticsBoardDateRangeReducer from '@modules/analytics/common/reducers/board-date-range';

const AnalyticsTenderRootReducer = reduceReducers(
  initialState,
  getBoardsCommonReducer(analyticsTenderActions),
  getBoardSetsCommonReducer(analyticsTenderActions),
  selectBoardCommonReducer(analyticsTenderActions),
  selectBoardSetCommonReducer(analyticsTenderActions),
  analyticsGetBoardWidgetReducer(analyticsTenderActions),
  analyticsGetWidgetDataReducer(analyticsTenderActions),
  setParamsCommonReducer(analyticsTenderActions),
  analyticsBoardDateRangeReducer(analyticsTenderActions)
);

export default AnalyticsTenderRootReducer;
