import { FC, HTMLAttributes, ReactNode } from 'react';
import { IDragDropContainer } from 'react-drag-drop-container';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { NEW_ROW_KEY_TO_ADD } from '@components/data-display/table/constants';
import { Key, Keys, TimeStamp } from '@common/types/general';
import { ActiveType, CellType, Dictionary, IILSTableProps, IndexedArray, ReactDispatch } from '@common/types';
import { TableTitle } from './table';
import { ITablePropsFunction } from './functions';
import { ColumnTable, TableColumnName } from './column';
// todo не должно быть в модуле
import { IRecord } from '@modules/catalog/types/catalog';
import { CascaderProps, SelectProps } from 'antd/es';

export const enum ShowDeleted {
  No,
  Yes
}

export type DataSource = Array<TableRecord>;

export enum ColumnsActiveType {
  Focus = 'focus',
  Selected = 'selected'
}

export type Reference<R extends TableRecord> = {
  title?: string;
  record?: R;
  handleSave?: ITablePropsFunction<R>['handleSave'];
  recordDataIndex?: ColumnTable<R>['dataIndex'];
  dataIndex?: ColumnTable<R>['dataIndex'];
  component?: FC<any>;
  errors?: IILSTableProps<R>['errors'];
  cellType?: CellType;
};

export type ShowReference<R extends TableRecord> = { reference: Reference<R> } | boolean;
export type SetShowReference<R extends TableRecord> = ReactDispatch<ShowReference<R>>;

export type ReferenceTable = {
  /**
   * Используется для заголовка таблицы и для подсказки при наведении на кнопку открытия модальных окон
   * так же позволяет каждой кнопке создавать собственное сообщение в зависимости от record
   */
  title: TableTitle | string; // legacy: | string
  component?: FC<any>;
  dictionary?: Dictionary;
  dataSource?: any; // legacy
};

export type ColumnOption = Array<Option> | Array<object> | IndexedArray<object>;
export type ColumnOptionFunction = (record: TableRecord) => ColumnOption;

export type ReferenceTableData = {
  options: ColumnOption | ColumnOptionFunction;
  dictionary: Dictionary;
  fieldNames: SelectProps['fieldNames'] | CascaderProps<any>['fieldNames'];
};

export type ReferenceTablesData = Partial<Record<Partial<TableColumnName>, ReferenceTableData>>;

export type ReferenceTables =
  | Partial<Record<Partial<TableColumnName>, ReferenceTable>>
  | Partial<Record<Partial<TableColumnName>, (record: any) => ReferenceTable>>;

export type DragInfo<T = any> = {
  key: Key;
  active?: boolean;
  dictionary?: Array<Dictionary> | Array<Key>;
  handler?(e: IDragDropContainer<T>, record: TableRecord): void;
};

export type Option = {
  value: Key;
  label: string;
  disabled?: boolean;
};

export interface IOption<V, L extends string> {
  value: V;
  label: L;
}

export type TreeSelectOption = {
  children?: Array<Option>;
} & Option;

export type Options = Array<object>;

export type TreeSelectOptions = Record<string, TreeSelectOption>;

export type IconOption = {
  value: string | number;
  label: ReactNode;
  hide?: boolean;
  disabled?: boolean;
  icon?: string;
  tooltip?: string;
};

export type IconOptions = Array<IconOption>;

export type Variants = {
  data: Options;
  treeData?: Array<TreeSelectOption>;
  dictionary?: Dictionary;
  valueDecorator?<T>(value: T): Array<number>;
  labelField?: string;
  isGenerated?: boolean; //NOTE пометка что data содержит уже преобразованные данные  в Options
} & Partial<TreeSelectOption>;

export type VariantsOption = Partial<Variants> & IconOptions;

export type DictionariesInfo = Partial<Record<Dictionary, DictionaryInfo>>;
export type DictionaryInfo = {
  isFetching: boolean;
  lastUpdate: TimeStamp | undefined | null;
};

export type AddingModel = Partial<Record<TableColumnName | 'key' | 'update', any>>;
export type AddingVirtualModel = {
  rowKey: typeof NEW_ROW_KEY_TO_ADD;
};

export type Adding = AddingModel | AddingVirtualModel;

export type ActiveRow = Partial<Record<TableColumnName, string>> & {
  key: Key;
};

export type TableRecord = Partial<Record<Partial<TableColumnName>, any>> &
  Partial<{
    rowKey: string | number;
    dictionary: Dictionary;
    rowParams: Record<string, any>;
    handleClick: (record: TableRecord, dataIndex: ColumnTable<TableRecord>['dataIndex']) => void;
    Items: any;
    children: Array<TableRecord>;
    actualExpandRowKeys: Keys;
    selectType: ActiveType;
  }> & {
    key: Key;
  };

export type TableDecoratedDataSource = Array<TableRecord>;

export interface ICatalogExportParams {
  headers: { name: string }[];
  format: string;
  header: boolean | 0 | 1;
  dictionary: Dictionary;
  filters:
    | {
        Name: string;
        ClientContact: string;
        DepotTypeID: string;
        Description: string;
        AddressDescription: string;
      }
    | {};
}

export type FilterState = { searchText: string; searchedColumn: ColumnTable<TableRecord>['dataIndex']; strictSearch: boolean };

export type FilterSort = {
  filtered: Record<string, FilterValue | null> | null;
  sorted: SorterResult<any> | SorterResult<any>[] | null;
  pagination: TablePaginationConfig;
};

export type CellRecord = Partial<Record<TableColumnName | 'dictionary' | 'rowParams', any>> & Partial<IRecord> & { children?: ReactNode };
export type ArgsHandleSave = [
  record: any,
  dataIndex: TableColumnName,
  value: any,
  rowIndex?: any,
  dictionary?: Dictionary | undefined,
  cellRecord?: CellRecord | CellRecord[]
];

export type GetComponentProps<T> = (data: T, index?: number) => HTMLAttributes<HTMLElement>;
