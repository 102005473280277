import { Modal, ModalProps } from 'antd';
import { FC } from 'react';
import './styles.less';

export const ILSModal: FC<ModalProps> = ({ okText, cancelText, onCancel, onOk, ...props }) => {
  return (
    <div onClick={({ nativeEvent }) => nativeEvent.stopPropagation()}>
      <Modal
        okText={onCancel ? okText ?? 'Выбрать' : undefined}
        cancelText={onCancel ? cancelText ?? 'Отмена' : undefined}
        {...props}
        onCancel={onCancel}
        onOk={onOk}
      />
    </div>
  );
};
