import { FC } from 'react';
import { TablesWithMapComponent } from '../components/table-with-map';
import { useUpdateGetPointsStatusesList } from '@modules/monitoring/children/event-details/hooks/use-update-get-points-statuses-list';
import { useResizer } from '@modules/monitoring/children/event-details/hooks/use-resizer';

interface Props {
  showMap?: boolean;
  handleDateSelect: (date: string[]) => void;
  handleRedirect: (name: string) => void;
}

// Контейнер для компонента для разделения элементов на splitter
export const TableWithMapContainer: FC<Props> = ({ showMap = true, handleDateSelect, handleRedirect }) => {
  const { tablesMapSplitSizes, tablesSplitSizes, handleResize } = useResizer();
  const { maxInterval } = useUpdateGetPointsStatusesList();

  return (
    <TablesWithMapComponent
      showMap={showMap}
      handleResize={handleResize}
      initialSizes={tablesMapSplitSizes}
      tablesSplitInitialSizes={tablesSplitSizes}
      handleDateSelect={handleDateSelect}
      handleRedirect={handleRedirect}
      maxInterval={maxInterval}
    />
  );
};
