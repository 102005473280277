import { FC } from 'react';
import { TRANSPORT_COMPANY_IMG } from '@modules/orders-and-trips/constants/img';
import { CurrencySign, DASH } from '@common/constants';
import { ILSButton } from '@common/components';
import { ButtonProps } from 'antd';
import { FakePreCostProposal } from '@modules/orders-and-trips/types/fake-pre-cost';

interface IProps {
  currency: FakePreCostProposal['currency'];
  alias: FakePreCostProposal['name'];
  description?: FakePreCostProposal['description'];
  cost: FakePreCostProposal['price'];
  term: FakePreCostProposal['term'];
  buttonType: ButtonProps['type'];
  buttonChildren: ButtonProps['children'];

  handleClick(): void;
}

export const ProposalCard: FC<IProps> = ({ alias, description, term, cost, currency, buttonType, buttonChildren, handleClick }) => {
  return (
    <div className={'fake-pre-cost__card-item'}>
      <div>{alias && <img src={TRANSPORT_COMPANY_IMG[alias]} width={130} alt={`Логотип ${alias}`} />}</div>
      <div className={'fake-pre-cost__card-item-description'}>{description}</div>
      <div className={'fake-pre-cost__card-item-term'}>{term}</div>
      <div className={'fake-pre-cost__card-item-cost'}>{cost ? `${cost} ${CurrencySign[currency]}` : DASH}</div>
      <ILSButton
        disabled={!cost}
        className={'fake-pre-cost__button_shipment'}
        type={buttonType}
        children={buttonChildren}
        onClick={handleClick}
      />
    </div>
  );
};
