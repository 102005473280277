import { ILSCollapse, ILSCollapsePanel } from '@common/components';
import { ExpandIconPosition } from '@common/components/data-display/collapse/types';
import { TableSettings } from '@modules/settings/containers/common-settings/table-settings/table-settings';

export const CommonSettings = () => {
  return (
    <ILSCollapse bordered={false} accordion expandIconPosition={ExpandIconPosition.Right} defaultActiveKey={'table_settings'}>
      <ILSCollapsePanel key={'table_settings'} header={'Настройки таблиц'}>
        <TableSettings />
      </ILSCollapsePanel>
    </ILSCollapse>
  );
};
