import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { VehicleProfile } from '@common/types';

export const vehicleProfile2table = (data: Array<VehicleProfile>) => {
  return createArrayFromIndexedArray(data).map((item, i) => {
    return {
      ...item,
      key: item.ID ?? 'row' + i
    };
  });
};
