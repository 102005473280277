import { useMemo } from 'react';
import { getIndexActiveColumn } from '@modules/planning/children/by-date/helpers';
import { TableColumnName, TableConfig } from '@common/types';
import { isNil } from 'lodash';

export const useIndexExpandColumn = (config: TableConfig) => {
  const indexExpandColumn = useMemo(() => {
    const index = getIndexActiveColumn(config, TableColumnName.FullName);
    return !isNil(index) ? index + 2 : 0;
  }, [config]);

  return { indexExpandColumn };
};
