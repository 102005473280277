export enum ProfileTab {
  user = 'user',
  account = 'account',
  users = 'users'
}

export enum ProfileTabName {
  user = 'Учетная запись',
  account = 'Моя компания',
  users = 'Пользователи'
}
