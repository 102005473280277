import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { SPEED_PROFILE_TABLE_CONFIG, speedProfiles2table } from '@common/models/speed-profile';

export const ILSCatalogSpeedProfile: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return <ILSCatalogTable {...props} dictionary={Dictionary.SpeedProfile} dataDecorator={speedProfiles2table} />;
  },
  SPEED_PROFILE_TABLE_CONFIG,
  CatalogDictionary.SpeedProfile,
  true
);
