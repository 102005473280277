import { ReferenceTablesData, TableColumnName } from '@common/types';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';
import { Depots } from '@common/types/dictionaries/depot';

export const getCrossDockRefData = (depot: Depots) => {
  const depotOptions = createDepotCascaderOptions(depot);
  const refData: ReferenceTablesData = {
    [TableColumnName.DepotID]: depotOptions
  };
  return { refData };
};
