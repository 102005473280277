export enum DocFilesTypes {
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Bmp = 'bmp',
  Pdf = 'pdf'
}
