import { createRoutine } from 'redux-saga-routines';

export const PaymentActionType = {
  getInvoiceTemplate: 'PAYMENT/GET_INVOICE_TEMPLATE',
  getCurrentBalance: 'PAYMENT/GET_CURRENT_BALANCE',
  getBalanceHistoryRoutine: 'PAYMENT/GET_BALANCE_HISTORY',
};

export const getInvoiceTemplateRoutine = createRoutine(PaymentActionType.getInvoiceTemplate);
export const getCurrentBalanceRoutine = createRoutine(PaymentActionType.getCurrentBalance);
export const getBalanceHistoryRoutine = createRoutine(PaymentActionType.getBalanceHistoryRoutine);

export const PaymentRoutinesWithNotifier = [getInvoiceTemplateRoutine];

