import { CreateButton, HeaderCreateButton } from '../types/button';

// Массив с заголовками для кнопок перехода в другие разделы
export const demandCreateButtons: Array<CreateButton> = [
  {
    key: HeaderCreateButton.Self,
    label: 'Заявка для собственного транспорта',
    buttonProps: { disabled: true, title: 'В разработке' }
  },
  {
    key: HeaderCreateButton.Tender,
    label: 'Заявка для размещения на тендерной площадке'
  },
  {
    key: HeaderCreateButton.Project,
    label: 'Проект для формирования рейсов в планировщике'
  }
];
