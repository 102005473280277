import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { getTripDocumentsRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { TripDocument } from '@modules/orders-and-trips/types/trip';

export const getTripDocumentsReducer = createReducer(initialState, {
  [getTripDocumentsRoutine.REQUEST]: (state: InitialState) => {
    state.tripDocuments.isFetching = true;
    return state;
  },
  [getTripDocumentsRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: Array<TripDocument>;
      tripID: string;
    }>
  ) => {
    const { data, tripID } = action.payload;
    state.tripDocuments.documents = { ...state.tripDocuments.documents, [tripID]: data };
    return state;
  },
  [getTripDocumentsRoutine.FAILURE]: onFailure,
  [getTripDocumentsRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripDocuments.isFetching = false;
    return state;
  }
});

