import { DocumentType } from '@modules/documents/types/document-type';
import { DocumentsStore, DocumentState } from '@modules/documents/types/document-state';

export const initialState: DocumentsStore = {
  fields: [],
  templates: [],
  reportList: {},
  dependentList: null,
  currentDocument: null,
  clearTrashMode: null,
  isViewDocumentModelOpen: false,
  isFetching: false,
  error: [],
  documentType: DocumentType.Deal,
  documentState: DocumentState.Active,
  isDocumentFetching: false,
  contractors: {
    isFetching: false,
    error: [],
    data: []
  },
  metrics: {
    isFetching: false
  },
  inboxSendDocuments: {
    data: [],
    docEnum: null,
    isFetching: false
  }
};
