import { DASH, EMPTY_CELL, JoinChar, NOT_ENTERED } from '@common/constants';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { TenderOfferFormValues } from '@modules/tender-platform/children/tender-search/types/offer';
import { TENDER_LOT_POSITIONS } from '@modules/tender-platform/constants';
import { LotPosition, LotPositionSign, TenderLot } from '@modules/tender-platform/types';

export const tenderOfferToForm = (
  lot: TenderLot | undefined,
  offer: TenderOffer | undefined,
  VehicleID: any[]
): TenderOfferFormValues | undefined => {
  if (!lot) return undefined;

  const Route = `${lot.Source?.Name} – ${lot.Target?.Name}`;
  const [position] = lot?.TenderLotPosition?.length ? lot.TenderLotPosition : [EMPTY_CELL];
  const Cargo = position
    ? Object.entries(position)
        .reduce((prev: Array<Array<string>>, [key, value]) => {
          if (!value || !TENDER_LOT_POSITIONS.includes(key as LotPosition)) {
            return prev;
          }
          // @ts-ignore
          prev.push([value, LotPositionSign[key]].join(JoinChar.Space));
          return prev;
        }, [])
        .join('; ')
    : NOT_ENTERED;

  return {
    ...lot,
    LotID: lot.ID,
    OfferID: offer?.ID,
    Direction: `${lot.RegionalCitySender ?? NOT_ENTERED} ${DASH} ${lot.RegionalCityRecipient ?? NOT_ENTERED}`,
    UserID: undefined,
    Alias: offer?.StatusConfig?.Text ?? 'Введите цену, чтобы стать участником',
    Route,
    Volume: 0,
    Cargo,
    TenderOffer: { ...offer, price: offer?.Price, VehicleID },
    MinPrice: lot.MinPrice,
    StartPrice: lot.StartPrice,
    price: offer?.Price,
    VehicleID,
    DriverID: offer?.DriverID
  };
};
