import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { deleteReportRoutine } from '../../actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';

export const deleteReportReducer = createReducer(initialState, {
  [deleteReportRoutine.REQUEST]: onRequest,
  [deleteReportRoutine.SUCCESS]: (state) => state,
  [deleteReportRoutine.FAILURE]: onFailure,
  [deleteReportRoutine.FULFILL]: onFulfill
});
