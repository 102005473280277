import { AbacPermissions } from '@abac/types';

export const permissions: AbacPermissions = {
  MAIN_LAYOUT: 'MAIN_LAYOUT',
  LIMITED_LAYOUT: 'LIMITED_LAYOUT',
  LOGIN: 'LOGIN',
  NON_AUTH_PAGES: 'NON_AUTH_PAGES'
};

export default permissions;
