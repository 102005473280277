import { Upload, UploadProps } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import { FC } from 'react';
import { checkBeforeUploadFiles } from '@components/data-entry/upload/helpers';
import { RcFile } from 'antd/es/upload';

const { Dragger } = Upload;

interface IProps extends UploadProps {
  fileMaxSize?: number;
  defaultPermittedTypes?: Array<string>;
  defaultFormats?: Array<string>;
}

export const ILSUpload: FC<IProps> = ({ fileMaxSize, defaultPermittedTypes, defaultFormats, beforeUpload, ...props }) => {
  const handleUpload = (file: RcFile, fileList: RcFile[]) =>
    checkBeforeUploadFiles({ file, beforeUpload, defaultPermittedTypes, defaultFormats, fileMaxSize, fileList });

  return <Upload beforeUpload={handleUpload} {...props} />;
};

export const ILSCropUpload: FC<IProps> = ({ ...props }) => {
  return <Upload {...props} />;
};

export const ILSDragger: FC<DraggerProps & IProps> = ({ fileMaxSize, defaultPermittedTypes, defaultFormats, beforeUpload, ...props }) => {
  const handleUpload = (file: RcFile, fileList: RcFile[]) =>
    checkBeforeUploadFiles({ file, beforeUpload, defaultPermittedTypes, defaultFormats, fileMaxSize, fileList });
  return <Dragger beforeUpload={handleUpload} {...props} />;
};
