import { EMPTY_STRING } from '@common/constants';
import { PaymentInitialState } from '@common/feature/payment/types/general';

export const paymentInitialState: PaymentInitialState = {
  isFetching: false,
  error: [],
  invoice: {
    template: EMPTY_STRING,
    data: EMPTY_STRING
  },
  currentBalance: {
    sum: 0,
    lastUpdate: null,
    isFetching: false
  },
  balanceHistory: {
    transactions: [],
    isFetching: false
  }
};

