import { DocumentAction, IDocumentsUpdatePayload } from '@modules/documents/types/document-type';
import { currentDocumentRoutine, documentsIndexRoutine, documentUpdateRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* documentUpdateWorker({ payload }: { payload: IDocumentsUpdatePayload }) {
  const { request, success, fulfill } = documentUpdateRoutine;

  try {
    yield put(request());

    const { data } = yield call(Documents.update, payload);

    yield put(success(Object.values(data.data)[0]));

    switch (payload.action) {
      case DocumentAction.Delete:
        ILSUserNotify(Notify.Success, 'Документ перемещен в корзину');
        break;
      case DocumentAction.Archive:
        ILSUserNotify(Notify.Success, 'Документ перемещен в архив');
        break;
      case DocumentAction.RestoreArchive:
        ILSUserNotify(Notify.Success, 'Документ восстановлен в архив');
        break;
      case DocumentAction.RestoreDocument:
        ILSUserNotify(Notify.Success, 'Документ восстановлен в рабочие документы');
        break;
      case DocumentAction.Update:
        ILSUserNotify(Notify.Success, 'Документ изменен');
        break;
    }
    yield put(documentsIndexRoutine());

    if (payload.updateCurrentDoc) {
      yield put(currentDocumentRoutine({ documentId: payload?.data?.id, isOpenModal: true }));
    }
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  } finally {
    yield put(fulfill());
  }
}

export function* documentUpdateWatcher() {
  yield takeEvery(documentUpdateRoutine.trigger, documentUpdateWorker);
}
