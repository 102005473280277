import { IndexedArray } from '@common/types';
import {
  AnalyticsFormDataWidgetDataMetricsModel,
  AnalyticsFormDataWidgetDataModel,
  AnalyticsWidget,
  BoardWidgetParams
} from '@common/types/dictionaries/analytics';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { FC, FormEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import transformFormDataWidgetData from '../../utils/transform-form-data-widget-data';
import { ILSInput } from '@common/components';

type Props = {
  widget: AnalyticsWidget;
  data?: AnalyticsFormDataWidgetDataModel;
  params: IndexedArray<BoardWidgetParams> | undefined;
  storeParams?: IndexedArray<{ name: string; value: string | number | undefined }>;
  handleSetParams: (params: IndexedArray<{ name: string; value: string | number | undefined }>) => void;
  hideWidget: boolean;
  loaded?: boolean;
  loading?: boolean;
};

type ColProps = {
  col: { [key: string]: AnalyticsFormDataWidgetDataMetricsModel & { value: string; icon?: string } };
};

const ILSFormDataWidget: FC<Props> = ({ data, loaded, hideWidget, params = {}, handleSetParams, storeParams = {}, widget }) => {
  const transformedData = data?.values && Object.keys(data.values).length !== 0 ? transformFormDataWidgetData(data) : { '1': {} };
  const [status, setStatus] = useState<'error' | undefined>(undefined);

  useEffect(() => {
    setStatus(widget.error ? 'error' : undefined);
  }, [widget.error]);

  const noData = useMemo(() => {
    return !data?.values || Object.keys(data.values).length === 0;
  }, [data]);

  const [inputValue, setInputValue] = useState<IndexedArray<{ name: string; value: string | number | undefined }>>({});

  const onSubmit = (e: SyntheticEvent) => {
    handleSetParams(inputValue);
    e.preventDefault();
  };

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setStatus(undefined);
    const newValue = { ...inputValue };
    newValue[e.currentTarget.name] = { name: e.currentTarget.name, value: e.currentTarget.value };
    setInputValue(newValue);
  };

  const paramsToAsk = useMemo(() => {
    return Object.values(params).filter((item) => item.Name !== 'Till' && item.Name !== 'From');
  }, [params]);

  return (
    <div className="analytics-form-data-widget">
      <Row gutter={32}>
        {Object.keys(transformedData).map((item, idx) => (
          <Col span={24 / Object.keys(transformedData).length} key={item + '' + idx}>
            {idx === 0 && (
              <div
                className={classNames('analytics-form-data-widget-input', {
                  'analytics-form-data-widget-input-hidden': !loaded || noData
                })}
              >
                <form onSubmit={onSubmit}>
                  {paramsToAsk.map((param) => (
                    <div key={param.Name} className="analytics-form-data-widget-textfield">
                      <label htmlFor={param?.Name} className="label">
                        {param?.Label}
                      </label>
                      <ILSInput
                        type="text"
                        name={param?.Name}
                        id={param?.Name}
                        status={status}
                        onChange={handleChange}
                        defaultValue={storeParams[param.Name]?.value}
                      />
                    </div>
                  ))}
                </form>
              </div>
            )}
            {!hideWidget && <ILSWidgetCol col={transformedData[item] as ColProps['col']} />}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const ILSWidgetCol: FC<ColProps> = ({ col }) => {
  return (
    <>
      {Object.values(col).map((item, idx) => (
        <div key={item.name + ' ' + idx} className="analytics-form-data-widget-textfield">
          <span className="label">{item.title}</span>
          <div className="value">
            {item?.icon && <ReactSVG src="/assets/icons/calendar.svg" />}
            {item.value}
          </div>
        </div>
      ))}
    </>
  );
};

export default ILSFormDataWidget;
