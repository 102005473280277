import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify, NotifyDurationInSecond } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { PlanAnalyticAction, plannerPlanAnalyticRoutinesWithNotifier } from '@modules/plan-analytic/actions';

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: PlanAnalyticAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || EMPTY_STRING;
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): PlanAnalyticAction => {
  return actionName as PlanAnalyticAction;
};

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, PlanAnalyticAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'PLANNER',
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

const errorWorker = notifierWorkerCreator<ErrorAPI, PlanAnalyticAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'PLANNER',
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* plannerPlanAnalyticNotifierWatcher() {
  for (const routine of plannerPlanAnalyticRoutinesWithNotifier) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
