import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { getPointsStatusesList } from '@modules/monitoring/children/event-details/actions';

// Получает список со статусами точек
function* getPointsStatusesListWorker() {
  const { success, failure } = getPointsStatusesList;
  try {
    const { data } = yield call(MonitoringAPI.getPointsStatusesList);

    yield put(success(data.data));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  }
}

export function* getPointsStatusesListWatcher() {
  yield takeLatest(getPointsStatusesList.trigger, getPointsStatusesListWorker);
}
