import { Dictionary, TableName } from '@common/types';
import { PlanningAPI } from '@modules/planning/api';
import { ProjectRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, spawn } from 'redux-saga/effects';
import {
  getPlannerParamsRoutine,
  planningCheckRoutine,
  planningGetConfigsRoutine,
  planningGetPlanEfficiencyRoutine,
  planningGetPlanRoutine,
  planningGetProjectCurrentRoutine,
  planningRememberFocusNExpandRowsRoutine,
  statusDistanceMatrixRoutine
} from '../../actions';
import { planningGetPlanCancelTasks } from '../plan/get-plan';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { ILSAppRegistry } from '../../../../../../index';
import { takeLatestWithSaveTask } from '@core/sagas/custom-effects/take-latest-with-save-task';
import { getCurrentSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';
import { setActiveProjectIdRoutine } from '@modules/planning/actions';
import { FocusNExpandRowsChangingType } from '@modules/planning/children/by-date/types/tables-container';

export type GetProject = ProjectRequest & {
  justUpdateInfo?: boolean;
  savedText: string;
  savedNotificationCloseDelay?: number | null;
  notifyKey?: string;
  initial: boolean;
};

type GetProjectPayload = PayloadAction<GetProject>;

export function* planningGetProjectCurrentWorker(action: GetProjectPayload) {
  const { request, success, failure, fulfill } = planningGetProjectCurrentRoutine;
  const { projectID } = action.payload;

  try {
    yield spawn(planningGetPlanCancelTasks);
    yield put(request({ projectID }));

    const {
      data: { data: project }
    } = yield call(PlanningAPI.getProject, { projectID });

    yield all([
      put(planningGetConfigsRoutine()),
      put(getPlannerParamsRoutine.trigger()),
      put(setActiveProjectIdRoutine.trigger({ activeProjectID: projectID, projectName: project.Name }))
    ]);

    const planIDs = project.hasOwnProperty(Dictionary.Plan) ? Object.keys(project.Plan) : EMPTY_ARRAY_DATA;
    const ID = planIDs[0];
    const payload = { ID, planNumber: 0, planCount: planIDs.length };
    yield put(planningGetPlanRoutine.trigger(payload));

    yield all([
      put(planningCheckRoutine.trigger({ plans: planIDs })),
      put(
        success({
          project,
          activePlanID: ID
        })
      )
    ]);
    /** при переключении, обновлении проекта, сбрасываем последнюю сохраненную сфокусированную строку */
    yield put(
      planningRememberFocusNExpandRowsRoutine.success({
        tableName: TableName.RoutesTable,
        changingType: FocusNExpandRowsChangingType.UnsetFocus
      })
    );
    yield all([put(getCurrentSchemeRoutine.trigger()), put(statusDistanceMatrixRoutine.trigger({ projectID }))]);
    yield put(planningGetPlanEfficiencyRoutine({ planID: planIDs, projectID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningGetProjectCurrentWatcher() {
  yield takeLatestWithSaveTask<typeof planningGetProjectCurrentWorker>({
    saga: planningGetProjectCurrentWorker,
    routine: planningGetProjectCurrentRoutine,
    saveTask: ILSAppRegistry?.sagaInjector?.setTask,
    taskName: 'planningGetProjectCurrentWorker'
  });
}
