import { emulatorChangeEmulationSpeedRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { EmulatorAPI } from '../api';

// Меняет скорость эмуляции у выбранного плана (planID, emulationSpeed)
function* emulatorChangeEmulationSpeedWorker(
  action: PayloadAction<{
    emulationSpeed: number;
    planID: number;
  }>
) {
  const { success, failure, fulfill } = emulatorChangeEmulationSpeedRoutine;
  const { emulationSpeed, planID } = action.payload;

  try {
    yield call(EmulatorAPI.updateConfig, { planID, emulationSpeed });
    yield put(success({ emulationSpeed }));
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorChangeEmulationSpeedWatcher() {
  yield takeLatest(emulatorChangeEmulationSpeedRoutine.TRIGGER, emulatorChangeEmulationSpeedWorker);
}
