import { useDispatch, useSelector } from 'react-redux';
import { reportListOptionsSelector } from '@modules/documents/selectors';
import { AddReportRequest } from '@modules/documents/types/documents-api';
import { saveReportRoutine } from '@modules/documents/actions';
import { Project } from '@common/types';

export const useReportForm = ({ handleShow, projectId }: { handleShow(): void; projectId: Project['ID'] }) => {
  const dispatch = useDispatch();
  const options = useSelector(reportListOptionsSelector);

  const createReport = (values: AddReportRequest) => {
    const payload = { ...values, projectId };
    dispatch(saveReportRoutine(payload));
    handleShow();
  };
  return { options, createReport };
};
