import { ILSButton, ILSForm, ILSFormItem, ILSInput } from '@components/index';
import { Dispatch, FC, SetStateAction } from 'react';

interface IILSPCF {
  inputValue?: string;
  setConfirm: Dispatch<SetStateAction<boolean>>;
  confirm: any;
  onClick: (b1: any, b2?: boolean) => void;
}

export const ILSPlanContextForm: FC<IILSPCF> = ({ inputValue, setConfirm, confirm, onClick }) => {
  const onCancel = () => setConfirm(false);
  const onFinish = (value: {}) => onClick({ ...confirm, inputValue: value[confirm.field] }, true);

  return (
    <ILSForm onFinish={onFinish} className={'plan-add-form'}>
      <ILSFormItem label={confirm.text} name={confirm.field}>
        <ILSInput defaultValue={inputValue} />
      </ILSFormItem>
      <div className="flex-between-center mt-2">
        <ILSButton onClick={onCancel}>Отмена</ILSButton>
        <ILSButton htmlType={'submit'} type="primary">
          Изменить
        </ILSButton>
      </div>
    </ILSForm>
  );
};
