import { AccountRole, User, UserRole, UserRoleOptions } from '@common/types';
import { AccountRoleOptions } from '@common/types/dictionaries';

export type IconName = string;
export type IconPathConfig = {
  originPath?: boolean;
  iconModule?: string;
  iconDirectory?: string;
};

export type Guest = Partial<User> & { AuthToken: '' };
export type AppRoles = Array<UserRole | AccountRole>;
export type AppRolesOptions = AccountRoleOptions & UserRoleOptions;
export type AppUserRoles = Array<UserRole>;
export type AppAccountRoles = Array<AccountRole>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type DeepRequired<T> = T extends object
  ? {
      [P in keyof T]-?: DeepRequired<T[P]>;
    }
  : T;

export enum ContentType {
  MutipartFormdata = 'multipart/form-data',
  ApplicationJson = 'application/json'
}

