import { call, put, takeLatest } from 'redux-saga/effects';
import { getTotalCountRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* getTotalCountWorker() {
  const { success, failure } = getTotalCountRoutine;
  try {
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTotalCount);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* getTotalCountWatcher() {
  yield takeLatest(getTotalCountRoutine.trigger, getTotalCountWorker);
}
