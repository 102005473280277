import ILSParamTypesComponent from './param-types';
import { ParamComponentProps } from '@modules/planning/children/params/types/components';

const ILSParamComponent = (props: ParamComponentProps) => {
  const { param } = props;

  return (
    <div key={param.id} className="mb-1 param-row">
      <div className="name">
        <div dangerouslySetInnerHTML={{ __html: '<div>' + param.Name_ + '</div>' }} />
      </div>
      <div className="default">
        <div className="py-1 px-2 default-value">
          <ILSParamTypesComponent {...props} defaultValues={true} disabled={true} />
        </div>
      </div>
      <div className="value mr-1 flex-center-center">
        <ILSParamTypesComponent {...props} />
      </div>
    </div>
  );
};

export default ILSParamComponent;
