import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSSkeleton, ILSTable } from '@common/components';
import { inboxConfig } from '@modules/documents/children/inbox/constants/inbox-configs';
import { decorateInboxData } from '@modules/documents/children/inbox/helpers/decorateInboxData';
import { getInboxSendDocuments } from '@modules/documents/selectors';
import { getInboxSendDocumentsRoutine } from '@modules/documents/actions';
import { useGetInboxMenu } from '@modules/documents/children/inbox/helpers/use-get-inbox-menu';
import { DocumentsType } from '@modules/documents/types/document-state';

const InboxDocumentTable = () => {
  const dispatch = useAppDispatch();
  const { data, isFetching } = useAppSelector(getInboxSendDocuments);
  const optionButton = useGetInboxMenu();
  const dataSource = decorateInboxData(data, optionButton);

  useEffect(() => {
    dispatch(getInboxSendDocumentsRoutine({ type: DocumentsType.Inbox }));
  }, []);

  return (
    <ILSSkeleton loading={isFetching}>
      <ILSTable
        config={inboxConfig}
        //TODO
        dataSource={dataSource as any}
        tableName={DocumentsType.Inbox}
        scroll={{ x: 400, y: 600 }}
        className="editable-table"
      />
    </ILSSkeleton>
  );
};

export default InboxDocumentTable;
