import { ILSDatePicker, ILSInput, ILSSelect, ILSSlider, ILSTextArea } from '@components/index';
import {
  DATE_TIME_FORM_FIELDS,
  INPUT_TYPE_NUMBER_FORM_FIELDS,
  SELECT_FORM_FIELDS,
  SLIDER_FORM_FIELDS,
  TEXTAREA_FORM_FIELDS
} from '@modules/orders-and-trips/constants/form';
import { FormFieldNames, FormInputTypes } from '@modules/orders-and-trips/types/form';

export const getFormField = ({ fieldName }: { fieldName: FormFieldNames }) => {
  switch (true) {
    case DATE_TIME_FORM_FIELDS.includes(fieldName):
      return {
        Component: ILSDatePicker,
        InputType: FormInputTypes.DateTime
      };
    case SLIDER_FORM_FIELDS.includes(fieldName):
      return {
        Component: ILSSlider,
        InputType: FormInputTypes.Slider
      };
    case TEXTAREA_FORM_FIELDS.includes(fieldName):
      return {
        Component: ILSTextArea,
        InputType: FormInputTypes.TextArea
      };
    case SELECT_FORM_FIELDS.includes(fieldName):
      return {
        Component: ILSSelect,
        InputType: FormInputTypes.Select
      };
    case INPUT_TYPE_NUMBER_FORM_FIELDS.includes(fieldName):
      return {
        Component: ILSInput,
        InputType: FormInputTypes.InputNumber
      };
    default:
      return {
        Component: ILSInput,
        InputType: FormInputTypes.InputText
      };
  }
};
