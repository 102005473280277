import { TimeFormat } from '@common/types';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToDatetimeString } from '@common/utils';
import { isEmpty } from 'lodash';

export const createWaypointComments = (waypoint: MonitoringWaypointModel) => {
  let LastComment = waypoint.LastComment;
  let LastComment_htmltip = waypoint.LastStatusDT && waypoint.LastComment ? createRowTip(waypoint.LastStatusDT, waypoint.LastComment) : '';
  let lastCommentDT = waypoint.LastStatusDT;
  if (!isEmpty(waypoint.WaypointFactManual)) {
    const commentArr: { dt: number; comment: string }[] = [];
    Object.values(waypoint.WaypointFactManual).forEach((wpManual) => {
      if (wpManual.Comment && (wpManual.DateTimeEnd || wpManual.DateTimeStart)) {
        commentArr.unshift({ dt: wpManual.DateTimeStart ?? wpManual.DateTimeEnd, comment: wpManual.Comment });
      }
    });
    if (!isEmpty(commentArr)) {
      commentArr.sort((a, b) => b.dt - a.dt);
      if (lastCommentDT) {
        LastComment = commentArr[0].comment;
      }
      LastComment_htmltip = commentArr.map(({ dt, comment }) => createRowTip(dt, comment)).join('\n');
    }
  }

  return { LastComment, LastComment_htmltip };
};

const createRowTip = (dt: number, comment: string) => `<div class="row history-comment">[${timestampToDatetimeString(dt, true, TimeFormat.HHmmssColon)}]: ${comment} </div>`;
