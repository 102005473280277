import { trackAddTrackToQueryRoutine } from '../actions';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { PointType } from '@common/utils/get-track/types';
import { PayloadAction } from '@reduxjs/toolkit';

function* addTrackToQuery(
  action: PayloadAction<{
    points: { [pointKey: string]: PointType };
  }>
) {
  const { request, success, failure, fulfill } = trackAddTrackToQueryRoutine;
  const { points } = action.payload;

  try {
    yield put(request());
    yield delay(500);
    yield put(success({ points }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* AddTrackToQueryWatcher() {
  yield takeLatest(trackAddTrackToQueryRoutine.trigger, addTrackToQuery);
}

