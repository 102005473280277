import { FC, useMemo, useRef, useState } from 'react';
import { BoardViewContainer } from '../containers/board-view';
import { BoardSetsListContainer } from '../containers/board-sets-list';
import { BoardsListContainer } from '../containers/boards-list';
import { ILSDateSelectContainer } from '../containers/date-select';
import { ILSButton, ILSModal, ILSSpin, ILSTitle } from '@common/components';
import { MAKE_REPORT_CAPTION } from '@modules/analytics/common/constants';
import { boardToPdf } from '@modules/analytics/common/utils/board-to-pdf';
import { useAppSelector } from '@core/hooks';
import { boardsSelector, widgetDataSelector } from '../selectors';
import { Spin } from 'antd';
import useGeneratePDF from '@modules/analytics/common/hooks/use-generate-pdf';
import { IModalBoards, TBoardWidgets } from '../types/modal-board';
import classNames from 'classnames';
// import { IModalBoards, TBoardWidgets } from '../types/modal-board';
// import useGeneratePDF from '@modules/analytics/common/hooks/use-generate-pdf';

interface Props {
  title: string;
}

const BoardWithSettings: FC<Props> = ({ title }) => {
  const ref = useRef<HTMLDivElement>(null);
  const boards = useAppSelector(boardsSelector);
  const widgetData = useAppSelector(widgetDataSelector);
  const [isActive, setIsActive] = useState(false);
  const [modalBoards, setModalBoards] = useState<IModalBoards[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // State нужен для отслежки загрузки всех виджетов, чтобы пользователь не мог
  // скачать PDF пока не загрузятся все виджеты
  const [boardWidgets, setBoardWidgets] = useState<{ [key: string]: boolean }>({});

  // Для спинера, когда идет скачивание PDF
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const closeModal = () => setIsActive(false);

  const { generatePDF } = useGeneratePDF({
    boards: boards.data,
    ref: modalRef,
    setIsActive,
    setIsLoading,
    setModalBoards,
    setBoardWidgets
  });

  const modalData = useMemo(() => {
    const boardWidgets: TBoardWidgets[] = modalBoards.map((item) => [item.From, item.Till, item.data]);

    return modalBoards.map((item, i) => (
      <BoardViewContainer
        forPDF={true}
        key={i}
        boardTitle={item.BoardTitle}
        from={item.From}
        till={item.Till}
        setBoardWidgets={setBoardWidgets}
        widgets={boardWidgets[i][2]}
      />
    ));
  }, [modalBoards, boards]);

  const savePdf = async () => {
    setDownloadingPDF(true);
    const boardElement = modalRef.current;

    if (boardElement) {
      boardElement.style.overflow = 'visible'; //чтобы попала вся доска и то, что внизу тоже
      boardToPdf({
        board: boardElement,
        canvasHeight: ref?.current ? ref.current.clientHeight : 400,
        canvasWidth: boardElement.clientWidth - 10,
        fileName: `${title}.pdf`,
        setDownloadingPDF
      }).then((res) => {
        boardElement.style.overflowY = 'scroll';
        boardElement.style.overflowX = 'hidden';
        closeModal();
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="analytics-planning-component">
        <div className="analytics-planning-component-board">
          <div className="analytics-planning-board-header">
            <ILSTitle title={title} />
            <ILSButton onClick={generatePDF} className="export-button mt-2">
              {MAKE_REPORT_CAPTION}
            </ILSButton>
          </div>
          <div ref={ref}>
            <BoardViewContainer />
          </div>
        </div>
        <div className="analytics-planning">
          <BoardSetsListContainer />
          <BoardsListContainer />
          <ILSDateSelectContainer />
        </div>

        <ILSModal
          closable={false}
          onCancel={closeModal}
          onOk={savePdf}
          okButtonProps={{
            disabled: !Object.values(boardWidgets || {}).every((item) => item)
          }}
          visible={isActive}
          width={ref.current?.offsetWidth}
          okText={'Сохранить'}
          destroyOnClose={true}
        >
          <ILSSpin spinning={!Object.values(boardWidgets).every((item) => item) || downloadingPDF}>
            <div className={classNames('analytics-scroll', 'analytics-pdf-modal')} ref={modalRef}>
              {modalData}
            </div>
          </ILSSpin>
        </ILSModal>
      </div>
    </Spin>
  );
};

export default BoardWithSettings;
