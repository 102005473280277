import { DateTimeFormat, IndexedArray, TableRecord, VehicleTracker } from '@common/types';
import { timestampToDatetimeString } from '@common/utils';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import last from 'lodash/last';

export const data2Table = (
  data: Array<VehicleTracker> | IndexedArray<VehicleTracker> | undefined | Array<TableRecord> = [],
  vehicleID?: number | null | string
): Array<
  TableRecord & {
    TrackerIMEI: number;
  }
> => {
  const result: Array<
    TableRecord & {
      TrackerIMEI: number;
    }
  > = [];

  if (!data || !Object.values(data).length) {
    return result;
  }

  for (const value of Object.values(data)) {
    if (compareAsString(value.VehicleID, vehicleID)) {
      result.push({
        ...value,
        Till: value.Till ? timestampToDatetimeString(value.Till, true, DateTimeFormat.DDMMYYYYPeriodHHMM) : null,
        From: value.From ? timestampToDatetimeString(value.From, true, DateTimeFormat.DDMMYYYYPeriodHHMM) : null,

        key: value.ID
      });
    }
  }
  return last(result)?.TrackerIMEI === '' ? result : result.reverse();
};

