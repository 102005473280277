import { Dictionary } from '@common/types';
import { FC } from 'react';
import { CloseOutlined } from '@ant-design/icons/lib/icons';
import { ILSButtonTooltip } from '@common/components';

type PropsCloseButton = {
  handleClose: (dictionary: Dictionary) => void | undefined;
  dictionary: Dictionary;
};

export const ILSCloseButton: FC<PropsCloseButton> = ({ handleClose, dictionary }) => {
  if (!handleClose) return null;
  return (
    <ILSButtonTooltip
      title={'Закрыть таблицу'}
      className="close-btn-small"
      onClick={() => handleClose(dictionary)}
      icon={<CloseOutlined className={'ils-svg silver pointer'} />}
    />
  );
};
