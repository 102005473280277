import './styles.less';
import { defineModule } from '@core/index';
import { schemeEditorReducer } from './reducers';
import * as schemeEditorSagas from './sagas';
import { getAllModuleSagas } from '@core/sagas';
import { ModulePath, ModuleTitle } from '@common/types';
import { PlannerSchemeEditor } from '@modules/planning/children/scheme-editor/containers/scheme-editor';

export default defineModule({
  title: ModuleTitle.SchemeEditor,
  path: ModulePath.SchemeEditor,
  component: PlannerSchemeEditor,
  reducer: schemeEditorReducer,
  sagas: getAllModuleSagas(schemeEditorSagas),
  persist: false
});
