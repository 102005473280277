import { FC } from 'react';
import { CreateUser } from '@modules/account/types/user';
import { DictionariesEndpoint, User } from '@common/types';
import { useAvatar } from '@modules/account/utils/hooks/use-avatar';
import { EMPTY_ARRAY_DATA, EMPTY_OBJECT_DATA, EMPTY_STRING } from '@common/constants';
import { AccountUserFormComponent } from '@modules/account/components/user/form';
import { useDispatch } from 'react-redux';
import { ILSButton } from '@common/components';
import { userRoutine } from '@modules/account/actions';
import { ProfileTab } from '@modules/account/types';
import { checkHasChangesInProfileForm } from '@modules/account/utils/helpers/check-has-changes-in-profile-form';
import { notifyUserAboutNoChanges } from '@components/feedback/ils-user-notify';

interface IProps {
  user: User | null;
  visibleForm: boolean;

  handleReset(): void;

  toggleForm(show: boolean, user: null | User): void;
}

export const AccountUsersFormContainer: FC<IProps> = ({ user, handleReset, toggleForm, visibleForm }) => {
  const dispatch = useDispatch();
  const userAvatar = user?.Attachments?.[0]?.UrlOriginal || null;
  const { newAvatar, onAvatarChange } = useAvatar(userAvatar);

  const initialValues = user
    ? {
        Name: user.Name,
        Phone: user.Phone,
        Login: user.Login,
        UserRole: user?.UserRole ?? EMPTY_ARRAY_DATA
      }
    : EMPTY_OBJECT_DATA;
  const handleCancel = () => toggleForm(false, null);
  const showForm = () => toggleForm(true, null);

  const handleCreate = (values: CreateUser[]) => {
    dispatch(userRoutine({ operation: DictionariesEndpoint.Create, values }));
    handleReset();
  };

  const handleUpdate = (values: CreateUser, id: User['ID']) => {
    dispatch(userRoutine({ operation: DictionariesEndpoint.Update, values, id }));
    handleReset();
  };

  const onFinish = (values: CreateUser) => {
    if (checkHasChangesInProfileForm({ values, initialValues, avatar: userAvatar, newAvatar })) {
      notifyUserAboutNoChanges();
      return;
    }
    const newValues = {
      ...values,
      UserImage: typeof newAvatar === 'string' ? newAvatar : newAvatar?.originFileObj ?? EMPTY_STRING
    };

    if (user) {
      handleUpdate(newValues, user.ID);
    } else {
      handleCreate([newValues]);
    }
  };

  return (
    <>
      {visibleForm ? (
        <AccountUserFormComponent
          name={ProfileTab.users}
          avatar={newAvatar}
          handleCancel={handleCancel}
          onAvatarChange={onAvatarChange}
          onFinish={onFinish}
          initialValues={initialValues}
        />
      ) : (
        <ILSButton type="primary" onClick={showForm} children={'Добавить пользователя'} />
      )}
    </>
  );
};
