import { createReducer } from '@reduxjs/toolkit';
import { TenderState } from '@modules/tender-platform/types';
import initialState from '@modules/tender-platform/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { tenderChangeTemplateRoutine } from '@modules/tender-platform/actions';
import { TenderTemplateChangeAction } from '@modules/tender-platform/types/tender';

export const changeTenderTemplateReducer = createReducer(initialState, {
  [tenderChangeTemplateRoutine.REQUEST]: onRequest,
  [tenderChangeTemplateRoutine.FAILURE]: onFailure,
  [tenderChangeTemplateRoutine.FULFILL]: onFulfill,
  [tenderChangeTemplateRoutine.SUCCESS]: (state: TenderState, action: TenderTemplateChangeAction) => {
    const { TenderTemplate } = action.payload;
    if (TenderTemplate) {
      if (TenderTemplate?.TenderLot) {
        if (Array.isArray(TenderTemplate.TenderLot)) {
          TenderTemplate.TenderLot.forEach((lot) => {
            if (lot?.Schedule) {
              lot.Schedule = { dates: [] };
            }
          });
        }
      }
      state.tenderTemplateData = { ...TenderTemplate };
    }
    return state;
  }
});

