import { createSelector } from 'reselect';
import { PlannerLocalConf, PlanningState, PlanningStore, RootState } from '@modules/planning/types/store';
import { PlanningAvailableDates } from '@modules/planning/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const activeProjectIdSelector = createSelector(
  (state: PlanningState) => state.Planning.activeProjectID,
  (activeProjectID) => (activeProjectID ? Number(activeProjectID) : null)
);

export const hasActiveProjectSelector = createSelector(
  (state: PlanningState) => state.Planning,
  (planning: PlanningStore) => Boolean(planning.activeProjectID || planning.created)
);

export const availableDatesSelector = createSelector(
  (state: PlanningState) => state.Planning.availableDates,
  (availableDates: PlanningAvailableDates | null) => availableDates ?? []
);

export const isDateLoadingSelector = createSelector(
  (state: PlanningState) => state.Planning.isDateLoading,
  (isDateLoading: PlanningStore['isDateLoading']) => isDateLoading
);

export const projectsSelector = createSelector(
  (state: PlanningState) => state.Planning.projects,
  (projects: PlanningStore['projects']) => projects
);

export const projectsOnDateSelector = createSelector(projectsSelector, (projects: PlanningStore['projects']) => {
  return createArrayFromIndexedArray(projects);
});

//todo state PlanningByDate в general?
export const statusSelector = createSelector(
  (state: RootState) => state.PlanningByDate.status,
  (status) => status
);

export const plannerSettingsSelector = createSelector(
  (state: PlanningState) => state.Planning.settings,
  (settings) => settings
);

export const planningParamsSelector = createSelector(
  (state: RootState) => state.PlanningParams?.localConf,
  (localConf: PlannerLocalConf) => localConf
);

