export const vehicleTypeOptions = [
  {
    value: '1',
    label: 'Тент'
  },
  {
    value: '2',
    label: 'Борт'
  },
  {
    value: '3',
    label: 'Фургон'
  },
  {
    value: '4',
    label: 'Фургон изотерм'
  },
  {
    value: '5',
    label: 'Фургон гидроборт'
  },
  {
    value: '6',
    label: 'Автоцистерна'
  },
  {
    value: '7',
    label: 'Контейнеровоз'
  },
  {
    value: '8',
    label: 'Рефрижератор'
  },
  {
    value: '9',
    label: 'Самосвал'
  },
  {
    value: '10',
    label: 'Кран-манипулятор'
  },
  {
    value: '11',
    label: 'Тягач'
  },
  {
    value: '12',
    label: 'Миксер'
  },
  {
    value: '13',
    label: 'Автокран'
  },
  {
    value: '14',
    label: 'Бензовоз'
  },
  {
    value: '15',
    label: 'Топливозаправщик'
  },
  {
    value: '16',
    label: 'Зерновоз'
  },
  {
    value: '17',
    label: 'Сортаментовоз'
  },
  {
    value: '18',
    label: 'Мусоровоз'
  },
  {
    value: '19',
    label: 'Снегоуборщик'
  },
  {
    value: '20',
    label: 'Муковоз'
  },
  {
    value: '21',
    label: 'Лесовоз'
  },
  {
    value: '22',
    label: 'Бетононасос'
  },
  {
    value: '23',
    label: 'Бункеровоз'
  },
  {
    value: '24',
    label: 'Молоковоз'
  },
  {
    value: '25',
    label: 'Автовышка'
  },
  {
    value: '26',
    label: 'Автобус'
  },
  {
    value: '27',
    label: 'Фургон кунг'
  },
  {
    value: '28',
    label: 'Фургон цельномет'
  },
  {
    value: '29',
    label: 'Эвакуатор'
  },
  {
    value: '30',
    label: 'Автовоз'
  }
];
