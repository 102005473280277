import axios from 'axios';
import { apiInstance } from '@core/api/instance';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary, IILSDictionaryAPI, ShowDeleted } from '@common/types';
import { DICTIONARIES_WITH_DATA_PROJECT_PARAMETER, DICTIONARIES_WITH_PROJECT_PARAMETER } from '@common/api/constants';
import { patchUpdateValues } from '@common/api/helpers/dictionary/patch-update-values';
import { head } from 'lodash';

export const DictionaryAPI: IILSDictionaryAPI = {
  operation: ({
    values,
    dictionary,
    ids = [],
    showDeleted = ShowDeleted.No,
    projectID,
    operation = DictionariesEndpoint.Index,
    getFullData = false,
    activeProjectID
  }) => {
    const url = getAPIURL(dictionary, operation);
    switch (operation) {
      case DictionariesEndpoint.Create:
        return apiInstance().post(url, { data: { values } });
      case DictionariesEndpoint.Update:
        return apiInstance().post(url, {
          data: {
            id: ids,
            values: patchUpdateValues({ dictionary, values, activeProjectID })
          }
        });
      case DictionariesEndpoint.MultipleUpdate:
        return apiInstance().post(url, { data: values });
      case DictionariesEndpoint.Delete:
        return apiInstance().post(url, { data: ids ? { ids } : { values } });
      case DictionariesEndpoint.Restore:
        return apiInstance().post(url, { ids });
      default:
        return apiInstance().post(url, {
          data: projectID && DICTIONARIES_WITH_DATA_PROJECT_PARAMETER.includes(dictionary) ? { ids, projectID } : { ids },
          showDeleted,
          getFullData,
          projectID: DICTIONARIES_WITH_PROJECT_PARAMETER.includes(dictionary) ? activeProjectID : undefined
        });
    }
  },
  read: ({ dictionary, ids = [], showDeleted = ShowDeleted.No, projectID, getFullData = false }) => {
    const data = projectID ? { ids, projectID } : { ids };
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Index), { data, showDeleted, getFullData });
  },
  update: ({ dictionary, ids, values }) => {
    const id = head(ids);
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Update), { data: { id, values } });
  },
  multipleUpdate: ({ dictionary, data }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.MultipleUpdate), { data });
  },
  create: ({ dictionary, values }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Create), { data: { values } });
  },
  delete: ({ dictionary, ids }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Delete), { data: { ids } });
  },
  isInUse: ({ dictionary, ids }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.IsInUse), { data: { ids } });
  },
  restore: ({ dictionary, ids = [] }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Restore), { ids });
  },
  bindTimeZone: ({ dictionary = Dictionary.Depot, depots }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.bindTimeZone), { depots });
  },
  getCoordinates: ({ address }) => {
    const apiKey = process.env.REACT_APP_YANDEX_MAPS_API_KEY;
    return axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&results=1&geocode=${address}`);
  },
  //	headers: {
  // "Referer": "https://www.scrapingbee.com/",
  // 	"Referrer-Policy": "strict-origin-when-cross-origin"
  // },
  copyOrdersToProject: (params) => apiInstance().post(getAPIURL(Dictionary.Order, DictionariesEndpoint.CopyOrdersToProject), params),
  export: ({ headers, format, header, dictionary, filters }) => {
    const filter = Object.keys(filters).reduce((prev, cur) => {
      prev[cur] = filters[cur][0];
      return prev;
    }, {});

    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Export), {
      data: { headers, format, header, ...(Object.values(filter).length && { filter }) }
    });
  },
  clone: ({ dictionary, ids }) => {
    return apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Clone), { data: { ids } });
  }
};
