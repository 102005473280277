import { GeneralState, PayloadActionError } from '@common/types';
import { UnifiedRoutine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';

//TODO разбить по файлам
export const onFulfill = <S extends GeneralState, P>(state: S) => {
  state.isFetching = false;
  state.error = [];
  return state;
};

export const onRequest = <S extends GeneralState, P>(state: S) => {
  state.isFetching = true;
  return state;
};

export const onFailure = <S extends GeneralState, P>(state: S, action: PayloadActionError) => {
  const { error } = action.payload;
  state.error = error;
  return state;
};
const emptyReducer = <S>(state: S) => state;

export const handleReducer = <S extends GeneralState, P>(
  routine: UnifiedRoutine,
  reducer: {
    REQUEST?(state: S, action?: PayloadAction<P>): S;
    SUCCESS(state: S, action: PayloadAction<P>): S;
    FAILURE?(state: S, action?: PayloadActionError): S;
    FULFILL?(state: S, action?: PayloadAction<P>): S;
  }
) => {
  const { REQUEST, FAILURE, SUCCESS, FULFILL } = reducer;
  return {
    [routine.REQUEST]: (state: S, action?: PayloadAction<P>) => {
      return { ...onRequest(state), ...REQUEST?.(state, action) };
    },
    [routine.SUCCESS]: (state: S, action: PayloadAction<P>) => SUCCESS(state, action),
    [routine.FAILURE]: (state: S, action: PayloadAction<P> & PayloadActionError) => {
      return { ...onFailure(state, action), ...FAILURE?.(state, action) };
    },
    [routine.FULFILL]: (state: S, action?: PayloadAction<P>) => {
      return { ...onFulfill(state), ...FULFILL?.(state, action) };
    }
  };
};

export const handleEmptyReducer = <S>(routine: UnifiedRoutine) => {
  return {
    [routine.REQUEST]: emptyReducer,
    [routine.SUCCESS]: emptyReducer,
    [routine.FAILURE]: emptyReducer,
    [routine.FULFILL]: emptyReducer
  };
};
