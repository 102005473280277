import { isEmpty } from 'lodash';
import { Depot, Keys, Order } from '@common/types';
import { order2PlannerTable } from '@common/models/order/decorators';

export const getOrderIdsFromAllIds = ({
  allOrdersIDs,
  record,
  ids
}: {
  allOrdersIDs: Keys;
  record: ReturnType<typeof order2PlannerTable>[number];
  ids: Array<Order['ID']> | Order['ID'] | Depot['ID'];
}) => {
  const allIDsAsNumber = allOrdersIDs.map(Number);
  if (Array.isArray(record.ID)) {
    const selectedCheckboxOrders = record.ID.filter((id) => allIDsAsNumber.includes(Number(id)));
    return isEmpty(selectedCheckboxOrders) ? ids : selectedCheckboxOrders;
  } else {
    const isOrder = allIDsAsNumber.includes(Number(record.ID));
    return isOrder
      ? ids
      : 'children' in record
      ? record.children.map(({ ID }: ReturnType<typeof order2PlannerTable>[number]['children']) => Number(ID))
      : ids;
  }
};
