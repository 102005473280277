import { createReducer } from '@reduxjs/toolkit';
import { TenderChangeAction, TenderState, TenderStatus, TenderType } from '@modules/tender-platform/types';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import initialState from '@modules/tender-platform/store';
import { filterByAccountID } from '@common/utils/helpers/filters/filter-by-id';
import { replaceUpdatedItem } from '@common/utils/helpers/array/replace-updated-item';
import { AcceptedOffer, TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { cleanDateTimeTenderFields } from '../utils/helpers/form/clean-date-time-tender-fields';
import { EMPTY_ARRAY_DATA } from '@common/constants';

export const changeTenderReducer = createReducer(initialState, {
  [tenderChangeRoutine.REQUEST]: onRequest,
  [tenderChangeRoutine.FAILURE]: onFailure,
  [tenderChangeRoutine.FULFILL]: onFulfill,
  [tenderChangeRoutine.SUCCESS]: (state: TenderState, action: TenderChangeAction) => {
    const { operation, TenderParticipants, TenderLot, TenderOffer, ids, dictionary, StatusConfig, Tender, TenderOfferID } = action.payload;
    if (state.currentTender) {
      const offers = state.currentTender.TenderOffer ?? EMPTY_ARRAY_DATA;
      const participants = state.currentTender.TenderParticipants ?? EMPTY_ARRAY_DATA;
      switch (operation) {
        case DictionariesEndpoint.Create:
          if (TenderLot) {
            state.currentTender.TenderLot = TenderLot;
            state.tenderTemplateData = null;
            state.temporaryLotDistance = null;
            break;
          }
          if (TenderOffer) {
            state.currentTender.TenderOffer = TenderOffer;
            break;
          }
          if (TenderParticipants) {
            state.currentTender.TenderParticipants = participants?.concat(TenderParticipants) ?? TenderParticipants;
            break;
          }
          break;
        case DictionariesEndpoint.Delete:
          switch (dictionary) {
            case Dictionary.TenderParticipants:
              state.currentTender.TenderParticipants = filterByAccountID(participants, ids);
              break;
            case Dictionary.TenderLot:
              state.currentTender.TenderLot = undefined;
              break;
            case Dictionary.TenderOffer:
              state.currentTender.TenderOffer = undefined;
              break;
          }
          break;
        case DictionariesEndpoint.Update:
          if (TenderLot) {
            state.currentTender.TenderLot = TenderLot;
            state.tenderTemplateData = null;
            state.temporaryLotDistance = null;
            break;
          }
          if (TenderOffer) {
            const [offer] = TenderOffer;
            state.currentTender.TenderOffer = replaceUpdatedItem(offer, offers);
            if (state.currentTender.Type === TenderType.Auction) state.currentTender.TenderOfferCurrent = { Price: offer.Price };
            break;
          }
          if (TenderParticipants) {
            state.currentTender.TenderParticipants = replaceUpdatedItem(TenderParticipants[0], participants);
            break;
          }
          if (Tender) {
            state.currentTender = { ...state.currentTender, ...Tender } as typeof Tender;
            break;
          }
          break;
        case DictionariesEndpoint.SetStatus:
          if (StatusConfig && Dictionary.Tender === dictionary) {
            if (state.currentTender?.StatusConfig?.Alias === TenderStatus.Cancelled && StatusConfig.Alias === TenderStatus.New) {
              state.currentTender = cleanDateTimeTenderFields(state.currentTender);
            }
            state.currentTender.StatusConfig = StatusConfig;
          }
          if (StatusConfig && Dictionary.TenderOffer === dictionary && TenderOfferID) {
            const offer = state.currentTender.TenderOffer?.find(({ ID }) => compareAsString(ID, TenderOfferID)) as TenderOffer;
            const offerWithNewStatus = { ...offer, StatusConfig };
            if (offers) {
              state.currentTender.TenderOffer = replaceUpdatedItem(offerWithNewStatus, offers);
            }
          }
          break;
        case DictionariesEndpoint.Accept:
          if (TenderOffer) {
            const [{ ID }] = TenderOffer;
            state.currentTender.TenderOffer = state.currentTender.TenderOffer?.map((offer) =>
              offer.ID === ID ? { ...offer, Accepted: AcceptedOffer.Yes } : offer
            );
          }
          break;
        case DictionariesEndpoint.tenderTemplateData:
          if (TenderLot) state.tenderTemplateData = { ...TenderLot[0] };
      }
    }
    if (state.tenderTemplateData) {
      switch (operation) {
        case DictionariesEndpoint.Reset:
          state.tenderTemplateData = null;
          state.temporaryLotDistance = null;
          break;
      }
    }
    return state;
  }
});

