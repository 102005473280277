import { useMountedState } from '@common/hooks/use-mounted-state';
import { Dragging, IndexedArray, MarkerType, PlanStatus } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { MarkerItem, PolylineItem } from '@modules/planning/children/by-date/types';
import { SelectedOrders } from '@modules/planning/types';
import { UnresolvedMarkerSourceValue } from '@modules/settings/types/planning';
import { deepEqual } from 'fast-equals';
import L from 'leaflet';
import { MouseEvent, MutableRefObject, useCallback, useEffect, useState } from 'react';

import { SelectedOrdersLasso, SelectedOrdersLassoType } from '@modules/planning/children/by-date/types/map-container';
import { isEmpty } from 'lodash';
import {
  dispatchClusterClick,
  dispatchMarkerEvent,
  dispatchPolylineEvent,
  findClusterLayerByChildIcon,
  getUnderMarker
} from '../utils/drag-marker';
import { isWaypointClickData } from '@common/utils/map';

type MarkerTripHandlerArgType = {
  dragging: Dragging;
  mapPolylines: MutableRefObject<Record<string, PolylineItem>>;
};
//Отвечает за статусы рейсов и ID рейсов в DD контейнере
export const useMarkerTripHandlers = ({ mapPolylines, dragging }: MarkerTripHandlerArgType) => {
  const [dragMaxMarkerStatus, setDragMaxMarkerStatus] = useState<PlanStatus | undefined>(undefined);
  const [dragMarkerTripIDs, setDragMarkerTripIDs] = useState<number[]>([]);
  const isMounted = useMountedState();

  const changeDragMaxMarkerStatus = (...args: Parameters<typeof setDragMaxMarkerStatus>) => {
    if (isMounted()) {
      setDragMaxMarkerStatus(...args);
    }
  };
  const changeSetDragMarkerTripIDs = (...args: Parameters<typeof setDragMarkerTripIDs>) => {
    if (isMounted()) {
      setDragMarkerTripIDs(...args);
    }
  };
  useEffect(() => {
    let maxTripStatus: PlanStatus = PlanStatus.Open;
    const currentTripIDs: number[] = [];
    if (dragging.waypointIDs?.length && dragging.type === MarkerType.Point && Object.values(mapPolylines.current).length) {
      Object.keys(mapPolylines.current).forEach((trackKey) => {
        const mItem = mapPolylines.current[trackKey];
        if (!isEmpty(mItem.poly.point)) {
          const mark = mItem.poly.point;

          if (
            isWaypointClickData(mark?.clickData) &&
            dragging.waypointIDs?.includes(Number(mark.clickData.WaypointID)) &&
            mItem.poly.tripStatus &&
            Number(maxTripStatus) < Number(mItem.poly.tripStatus)
          ) {
            maxTripStatus = String(mItem.poly.tripStatus) as PlanStatus;
          }

          if (
            isWaypointClickData(mark?.clickData) &&
            dragging.waypointIDs?.includes(Number(mark.clickData?.WaypointID)) &&
            mark.clickData?.TripID &&
            !currentTripIDs.includes(Number(mark.clickData?.TripID))
          ) {
            currentTripIDs.push(Number(mark.clickData.TripID));
          }
        }
      });
    }
    if (maxTripStatus !== dragMaxMarkerStatus) {
      changeDragMaxMarkerStatus(maxTripStatus);
    }
    if (!deepEqual(currentTripIDs, dragMarkerTripIDs)) {
      changeSetDragMarkerTripIDs(currentTripIDs);
    }
  }, [dragging]);
  return { dragMarkerTripIDs, dragMaxMarkerStatus, changeDragMaxMarkerStatus, changeSetDragMarkerTripIDs };
};

type MarkerEventHandlerArgType = {
  dragging: Dragging;
  map: MutableRefObject<ILSMap | null>;
  mapPolylines: MutableRefObject<Record<string, PolylineItem>>;
  mapMarkers: MutableRefObject<Record<string, MarkerItem>>;
  eventType: string | undefined;
  orderCluster: (L.MarkerClusterGroup & { _featureGroup?: { _layers?: IndexedArray<L.MarkerCluster & { _icon?: any }> } }) | undefined;
};
//Отвечает за перебрасывание событий на оригинальные элементы под DD контейнером
export const useMarkerEventHandler = ({ eventType, mapMarkers, mapPolylines, dragging, orderCluster, map }: MarkerEventHandlerArgType) => {
  const onMouseOut = useCallback(() => {
    if (eventType === MarkerType.Order) {
      dispatchMarkerEvent(mapMarkers, dragging, 'mouseout', map);
    } else if (eventType === MarkerType.Point) {
      dispatchPolylineEvent(mapPolylines, dragging, 'mouseout');
    }
  }, [eventType, dragging]);
  const onMouseOver = useCallback(() => {
    if (eventType === MarkerType.Order) {
      dispatchMarkerEvent(mapMarkers, dragging, 'mouseover', map);
    } else if (eventType === MarkerType.Point) {
      dispatchPolylineEvent(mapPolylines, dragging, 'mouseover');
    }
  }, [eventType, dragging?.originalID]);

  const onMarkerClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (eventType === MarkerType.Order) {
        dispatchMarkerEvent(mapMarkers, dragging, 'click', map);
      } else if (eventType === MarkerType.Point) {
        dispatchPolylineEvent(mapPolylines, dragging, 'click');
      } else {
        const element = getUnderMarker(e);
        if (eventType === MarkerType.OrderCluster) {
          if (element) {
            const mapClusterLayer = findClusterLayerByChildIcon(orderCluster, element);

            if (mapClusterLayer) {
              dispatchClusterClick(mapClusterLayer, map);
            } else {
              element.click();
            }
          }
        } else {
          if (element) element.click();
        }
      }
    },
    [eventType, orderCluster, dragging?.originalID]
  );

  return { onMouseOut, onMouseOver, onMarkerClick };
};

type SummaryDataArgType = {
  dragging: Dragging;
  selectedOrdersLasso: SelectedOrdersLasso;
  unresolvedMarkerSource: UnresolvedMarkerSourceValue;
  selectedOrders: SelectedOrders;
};
//Отвечает за модификацию данных, если есть данные по выделенным элементам и DD контейнер находится над ними
export const useSummaryData = ({ selectedOrdersLasso, selectedOrders, dragging, unresolvedMarkerSource }: SummaryDataArgType) => {
  //Если перемещаем выделенный маркер, то в dragData указываются все выделенные маркеры в summaryData
  if (selectedOrdersLasso && selectedOrders?.length) {
    if (
      dragging.selected &&
      dragging.type?.includes(MarkerType.Order) &&
      selectedOrdersLasso.type === SelectedOrdersLassoType.UnResolvedOrder
    ) {
      if (dragging.orderIDs?.find((marker) => selectedOrders.includes(Number(marker)))) {
        dragging.orderIDs = selectedOrders.slice();
      }
    } else if (dragging.type?.includes(MarkerType.Point) && selectedOrdersLasso.type === SelectedOrdersLassoType.ResolvedOrder) {
      //unresolvedMarkerSource
      if (
        dragging.selected &&
        dragging.originalID &&
        selectedOrdersLasso.waypointIDs.find((selectedWPID) => dragging.waypointIDs?.includes(Number(selectedWPID)))
      ) {
        //Если выделены не все заявки из точек, то перемещаем считаем, что перемещаются заявки
        if (!selectedOrdersLasso.orderIDs.every((lassoOrderID) => selectedOrders?.includes(Number(lassoOrderID)))) {
          dragging.type = MarkerType.Order;
        }

        dragging.orderIDs = selectedOrders.slice();
      }
    }
  }
};

