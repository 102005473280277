import { FC, FormEvent } from 'react';
import { WidgetParamComponentProps } from '../../types/components/params';

export const ILSAnalyticsWidgetStringParam: FC<WidgetParamComponentProps> = ({ widgetParam }) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
  };
  return (
    <div key={widgetParam.Name} className="analytics-form-data-widget-textfield">
      <label htmlFor={widgetParam?.Name} className="label">
        {widgetParam?.Label}
      </label>
      <input type="text" name={widgetParam?.Name} id={widgetParam?.Name} onChange={handleChange} />
    </div>
  );
};
