import { ProjectState, Restriction } from '@common/types';
import { pickByRestrictionId } from '@modules/planning/children/by-date/decorators/filters/pick-by-restriction-id';

export const filteredData = (
  order: ProjectState['Order'],
  vehicle: ProjectState['Vehicle'],
  filteredRestrictions: Array<Restriction['ID']>
) => {
  const filteredOrder = pickByRestrictionId(order ?? [], filteredRestrictions);
  const filteredVehicle = pickByRestrictionId(vehicle ?? [], filteredRestrictions);

  return {
    Order: filteredOrder,
    Vehicle: filteredVehicle
  };
};
