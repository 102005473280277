import { MonitoringChartVehicle } from '@modules/monitoring/types/chart';

// Здесь находятся необходимые хелперы для расчетов диаграмм
export const getTimes = (chartData: Array<MonitoringChartVehicle>) => {
  return chartData.reduce<{ start?: number; end?: number }[]>((acc, { data }) => {
    const times = data.map(({ start, end }) => ({ start, end }));
    return acc.concat(times);
  }, []);
};

export const getXMax = (times: { start?: number; end?: number }[]) => {
  return times.map(({ end }) => end).sort((a, b) => (b || 0) - (a || 0))[0] || 0;
};

export const getXMin = (times: { start?: number; end?: number }[], xMax: number) => {
  return times.map(({ start }) => start).sort((a, b) => (a || xMax) - (b || xMax))[0] || 0;
};
