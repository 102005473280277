import { ILSTabPane, ILSTabs } from '@common/components';
import { FC } from 'react';
import { demandTabs } from '../../configs/demand-tabs';
import { DemandTabType } from '../../types/tabs';

interface IProps {
  activeKey: DemandTabType;
  onChange: (tab: string) => void;
  hintData: { [K in DemandTabType]?: number };
}

// Компонент отвечающий за отображение табов (всего плашек, проектов, тендерных лотов)
export const ILSDemandHeaderTabsComponent: FC<IProps> = ({ activeKey, onChange, hintData }) => {
  return (
    <ILSTabs onChange={onChange} activeKey={activeKey} type="line" className="ils-demand-tabs">
      {demandTabs.map((tab) => {
        return (
          <ILSTabPane
            key={tab.key}
            tab={
              <div>
                {tab.label}
                <div className="ils-demand-tabs-hint">{hintData?.[tab.key]}</div>
              </div>
            }
          />
        );
      })}
    </ILSTabs>
  );
};
