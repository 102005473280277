import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { copyConfigRoutine } from '../actions';
import { IData } from '@modules/planning/children/params/types/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const copyParamsConfigReducer = createReducer(initialState, {
  [copyConfigRoutine.REQUEST]: onRequest,
  [copyConfigRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      config: IData[];
    }>
  ) => {
    const { config } = action.payload;
    if (state.data) {
      state.data = [...state.data, ...config];
    }
    return state;
  },
  [copyConfigRoutine.FAILURE]: onFailure,
  [copyConfigRoutine.FULFILL]: onFulfill
});
