import { ColumnTable, FilterCellType, IILSTableProps, Option, TableRecord, VariantsOption } from '@common/types';
import { compareAsString, getEnumField, isOption } from '@common/utils';
import { EMPTY_FILTER_CAPTION } from '@components/data-display/table/constants';
import { getDefaultOptionForSelect } from '@components/decorators';
import { isArray, isEmpty, isNil } from 'lodash';
import { ID_STRING } from '@common/utils/helpers/object/get-enum-field';
import { EMPTY_STRING } from '@common/constants';

const checkFilterRecord = (
  recordValue: Option | Array<Option> | string | number | Array<string | number>,
  searchValue: number | string | boolean
) => {
  if (isArray(recordValue)) {
    return recordValue.some((recordItem) => {
      if (isOption(recordItem)) {
        return compareAsString(recordItem.value, searchValue);
      }
      return compareAsString(recordItem, searchValue);
    });
  }
  if (isOption(recordValue)) {
    return compareAsString(recordValue.value, searchValue);
  }
  return compareAsString(recordValue, searchValue);
};

type getListFilterArg<R extends TableRecord> = {
  dataIndex: ColumnTable<R>['dataIndex'];
  type: ColumnTable<R>['filtering'];
  defaultVariants: VariantsOption;
  dataSource: IILSTableProps<R>['dataSource'];
};

export function getListFilterProps<R extends TableRecord>({ dataIndex, dataSource, defaultVariants, type }: getListFilterArg<R>) {
  if (isEmpty(dataSource) || isNil(dataSource)) return false;
  const options: VariantsOption = isEmpty(defaultVariants)
    ? Object.values(
        dataSource.reduce((acc, record) => {
          const option = getDefaultOptionForSelect({
            record,
            dataIndex
          });
          acc[option.value] = option;
          return acc;
        }, {})
      )
    : defaultVariants;
  const filters: { value: any; text: any }[] = [];

  dataSource.forEach((data) => {
    const dataValue = data[dataIndex];
    if (!isNil(dataValue)) {
      const enumInModel = data[getEnumField(dataIndex)];
      if (type === FilterCellType.Cascader && isArray(dataValue) && dataValue.length === 2) {
        const value = dataValue[1];
        const refRow = getRefRow({ options, value });
        const label = !isNil(enumInModel) ? Object.values(enumInModel ?? {}) : value;
        const text = refRow?.label ?? label;
        if (!filters.some((filter) => compareAsString(value, filter.value))) {
          //TODO добавить логику для возможности выбора в фильтре пустых
          if (!isNil(value)) {
            filters.push({ value, text });
          }
        }
      } else if (Array.isArray(dataValue)) {
        dataValue.forEach((value) => {
          let label;
          if (isOption(value)) {
            label = value.label;
            value = value.value;
          }

          const refRow = getRefRow({ options, value });
          const text = label ?? refRow?.label ?? value;
          if (!filters.some((filter) => compareAsString(value, filter.value))) {
            //TODO добавить логику для возможности выбора в фильтре пустых
            if (!isNil(value)) {
              filters.push({ value, text });
            }
          }
        });
      } else {
        const customVariants = filters.filter(({ value }) => compareAsString(value, dataValue));
        if (!isEmpty(customVariants)) return;
        const refRow = getRefRow({ options, value: dataValue });

        const text =
          refRow?.label ??
          ((dataIndex.includes(ID_STRING) && data[dataIndex.replace(ID_STRING, EMPTY_STRING)]?.Name) || dataValue.toString());
        dataValue === EMPTY_FILTER_CAPTION
          ? filters.unshift({
              value: dataValue,
              text: text
            })
          : filters.push({
              value: dataValue,
              text: text
            });
      }
    }
  });

  const onFilter: ColumnTable<R>['onFilter'] = (value, record) =>
    !isNil(record[dataIndex]) ? checkFilterRecord(record[dataIndex], value) : value === EMPTY_FILTER_CAPTION;

  return isEmpty(filters)
    ? null
    : {
        filters,
        onFilter
      };
}

const getRefRow = ({ options, value }: { options: VariantsOption; value: any }) =>
  Array.isArray(options) ? options.find((variant) => compareAsString(variant.value, value)) : options;
