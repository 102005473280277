import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { ID, IndexedArray } from '@common/types';

export const data2table = <T extends { ID: ID }>(data?: Array<T> | IndexedArray<T> | null) => {
  return createArrayFromIndexedArray(data).map((item, i) => {
    return {
      ...item,
      key: item.ID ?? 'row' + i
    };
  });
};
