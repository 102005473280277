import { vehicleMonitoringSetLinksRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { Plan } from '@common/types';
import { VEHICLE_MONITORING_PATH } from '@common/constants/general/path';

function* vehicleMonitoringSetLinksWorker() {
  const { failure } = vehicleMonitoringSetLinksRoutine;

  try {
    //TODO loader for user info
    const planID: Plan['ID'] = yield select(activePlanIDSelector);
    yield call(PlanningAPI.sendObserverLinks, { planID, link: VEHICLE_MONITORING_PATH });
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* vehicleMonitoringSetLinksWatcher() {
  yield takeLatest(vehicleMonitoringSetLinksRoutine.trigger, vehicleMonitoringSetLinksWorker);
}
