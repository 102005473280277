import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringGetTrackersRoutine } from '@modules/monitoring/actions';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

// Получает трекеры с бэка, записывает в data2Array
function* monitoringGetTrackersWorker() {
  const { request, success, failure, fulfill } = monitoringGetTrackersRoutine;

  try {
    yield put(request());

    const { data } = yield call(MonitoringAPI.getTrackers);
    const data2Array = createArrayFromIndexedArray(data.data);

    yield put(success(data2Array));
  } catch (error) {
    console.error(error);

    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringGetTrackersWatcher() {
  yield takeLatest(monitoringGetTrackersRoutine.trigger, monitoringGetTrackersWorker);
}
