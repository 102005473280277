import { tableCustom } from '@core/containers/table-custom';
import { DRIVER_TIMELINE_TABLE_CONFIG } from '@common/models/driver-timeline/table';
import { driverTimelines2table } from '@common/models/driver-timeline/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogDriverTimeline: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return (
      <ILSCatalogTable
        {...props}
        dictionary={Dictionary.DriverTimeline}
        dataDecorator={driverTimelines2table}
        handleDecorator={table2data}
      />
    );
  },
  DRIVER_TIMELINE_TABLE_CONFIG,
  CatalogDictionary.DriverTimeline,
  true
);
