import { ILSDragger } from '@common/components';
import { RcFile } from 'antd/lib/upload';
import { PaperClipOutlined } from '@ant-design/icons/lib/icons';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { AccountFileAttachment } from '@modules/account/types/attachments';
import { SPACE } from '@common/constants';
import { AccountAttachmentDocument } from '@modules/account/components/attachments/attachment-document';
import { AccountAttachmentSubject } from '@common/types';

interface IProps {
  title: string;
  documentsBySubject: AccountFileAttachment[] | undefined;
  description: string;
  permittedFileTypes: string[];
  handleDeleteDocument(id: number): void;
  subject: AccountAttachmentSubject;
  handleCheckBeforeUpload(file: RcFile, subject: AccountAttachmentSubject): void;
}

export const AccountFileDragger: FC<IProps> = ({
  title,
  subject,
  description,
  handleCheckBeforeUpload,
  handleDeleteDocument,
  documentsBySubject,
  permittedFileTypes
}) => {
  return (
    <div>
      <h3>
        {title} ({!isEmpty(documentsBySubject) ? 'загружено' : 'не загружено'})
      </h3>
      <ILSDragger
        name="file"
        showUploadList={false}
        disabled={!isEmpty(documentsBySubject)}
        beforeUpload={(file: RcFile, FileList: RcFile[]) => handleCheckBeforeUpload(file, subject)}
      >
        <PaperClipOutlined />
        {description}
        <br />
        Допустимые форматы: {SPACE + permittedFileTypes.map((fileType) => fileType + SPACE)}
      </ILSDragger>
      {
        <div>
          {documentsBySubject?.map((document) => (
            <AccountAttachmentDocument document={document} key={`${document.id}`} onDelete={handleDeleteDocument} />
          ))}
        </div>
      }
    </div>
  );
};
