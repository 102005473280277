import { IILSCellNotEditableCellProps } from '@common/types/components/table/cell';
import { ILSProgress } from '@components/index';
import { isNull, isNumber, isUndefined } from 'lodash';
import { TableRecord } from '@common/types';
import { CELL_DEFAULT_ERROR, showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

//TODO add file ant test
export const progressBarColors = {
  low: '#d24747',
  lessThanHalf: '#ffff66',
  moreThanHalf: '#ffa605',
  high: '#43cc4c',
  full: '#50b993'
};
export const getProgressStrokeColor = (progress: number) => {
  return progress < 30
    ? progressBarColors.low
    : progress < 50
    ? progressBarColors.lessThanHalf
    : progress < 80
    ? progressBarColors.moreThanHalf
    : progress < 100
    ? progressBarColors.high
    : progressBarColors.full;
};

export const ILSCellProgress = <R extends TableRecord>({ children }: IILSCellNotEditableCellProps<R>): JSX.Element | null => {
  const [node, value] = children;
  const progress = Number(value);

  if (isUndefined(value)) {
    return <></>;
  }

  if (!isNumber(progress) || isNaN(progress) || isNull(value)) {
    showUserAndDevError({ error: CELL_DEFAULT_ERROR });
    return <></>;
  }

  const strokeColor = getProgressStrokeColor(progress);

  return (
    <>
      {node}
      <ILSProgress
        strokeColor={strokeColor}
        size="small"
        status="active"
        className={'cell-progress'}
        showInfo
        percent={Math.floor(progress)}
      />
    </>
  );
};
