import { FC } from 'react';
import { IReferenceArticle } from '@modules/reference/types/models';

interface ILSReferenceDescriptionComponentProps {
  article: IReferenceArticle;
}

const ILSReferenceDescriptionComponent: FC<ILSReferenceDescriptionComponentProps> = ({ article }) => {
  const content = article.blocks.map((item, key) => {
    if (item.type === 'header') return <h2 key={key}>{item.data.text}</h2>;
    if (item.type === 'paragraph') return <p key={key}>{item.data.text}</p>;
    if (item.type === 'list') {
      return (
        <ul key={key}>
          {item.data.items!.map((_, key) => (
            <li key={key}>item</li>
          ))}
        </ul>
      );
    }
    if (item.type === 'image') return <img key={key} src={item.data.file!.url} alt="article pic" />;
    if (item.type === 'delimiter') return <div key={key} className="delimiter" />;
    return '';
  });

  return <div className="article px-3 mb-3">{content}</div>;
};

export default ILSReferenceDescriptionComponent;
