import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogMapRoutine } from '@modules/catalog/actions';
import { ValueType } from '@modules/catalog/types/catalog';

const mapStatusReducer = createReducer(initialState, {
  [catalogMapRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      id: KeyType;
      value: ValueType;
    }>
  ) => {
    let { dictionary, id, value } = action.payload;
    state[dictionary].map = { ...state[dictionary].map, [id]: value };
    return state;
  }
});

export default mapStatusReducer;
