import { CellType, DateFormat, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { TenderVisibilityIcon } from '@modules/tender-platform/types';
import { UnitOfMeasurement } from '@common/constants/general/unit-of-measurement';

export const tenderListConfig: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: false,
  configClassName: 'tender-platform-list-config',
  columns: [
    {
      title: '№',
      dataIndex: TableColumnName.ID,
      key: TableColumnName.ID,

      active: true,
      width: 60,
      sorting: 1,
      type: CellType.Input
    },
    {
      title: 'Исполнитель',
      dataIndex: TableColumnName.Carrier,
      key: TableColumnName.Carrier,
      width: 120,
      type: CellType.String,
      active: true,

      editable: false,
      filtering: FilterCellType.String,
      ellipsis: true,
      invisible: true
    },
    {
      title: 'Откуда\n(направление)',
      dataIndex: TableColumnName.RegionalCitySender,
      key: TableColumnName.RegionalCitySender,
      width: 145,
      type: CellType.String,
      active: true,

      editable: false,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Куда\n(направление)',
      dataIndex: TableColumnName.RegionalCityRecipient,
      key: TableColumnName.RegionalCityRecipient,
      width: 120,
      type: CellType.String,
      active: true,

      editable: false,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Дистанция\n(км)',
      dataIndex: TableColumnName.Distance,
      key: TableColumnName.Distance,
      width: 100,
      type: CellType.String,
      active: true,

      editable: false,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Заказчик',
      dataIndex: 'CargoOwner',
      key: 'CargoOwner',
      type: CellType.Input,
      width: 130,
      active: true,

      editable: false,
      sorting: 5,
      filtering: 'string',
      ellipsis: true
    },
    {
      title: 'Имя',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      width: 130,
      type: CellType.String,
      active: true,

      editable: false,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Тип\nтендера',
      dataIndex: TableColumnName.Type,
      key: TableColumnName.Type,
      type: CellType.String,
      width: 110,
      active: true,

      editable: false,
      sorting: 1,
      filtering: FilterCellType.Array,
      ellipsis: true
    },
    {
      title: '',
      titleText: 'Видимость',
      titleTooltip: 'Видимость (Открытый / Закрытый)',
      titleIcon: TenderVisibilityIcon.Close,
      dataIndex: TableColumnName.Private,
      key: TableColumnName.Private,
      width: 60,
      type: CellType.Icon,
      active: true,

      editable: false,
      sorting: 2,
      ellipsis: true
    },
    {
      title: 'Дата начала\nсбора предложений',
      dataIndex: TableColumnName.OfferStartDT,
      key: TableColumnName.OfferStartDT,
      width: 190,
      type: CellType.DateTimeString,
      format: DateFormat.HHmmDDMMYYYYPeriod,
      active: true,

      editable: false,
      sorting: 3,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Дата окончания\nсбора предложений',
      dataIndex: TableColumnName.OfferEndDT,
      key: TableColumnName.OfferEndDT,
      width: 190,
      type: CellType.DateTimeString,
      format: DateFormat.HHmmDDMMYYYYPeriod,
      active: true,

      editable: false,
      sorting: 4,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Дата начала\nисполнения договора',
      dataIndex: TableColumnName.ExecutionStartDT,
      key: TableColumnName.ExecutionStartDT,
      width: 200,
      type: CellType.DateTimeString,
      format: DateFormat.HHmmDDMMYYYYPeriod,
      active: true,

      editable: false,
      sorting: 5,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Дата окончания\nисполнения договора',
      dataIndex: TableColumnName.ExecutionEndDT,
      key: TableColumnName.ExecutionEndDT,
      type: CellType.DateTimeString,
      format: DateFormat.HHmmDDMMYYYYPeriod,
      width: 200,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Завершение\nаукциона',
      dataIndex: TableColumnName.Countdown,
      key: TableColumnName.Countdown,
      type: CellType.Countdown,
      countdown: { text: 'Завершён' },
      width: 100,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true,
      invisible: true
    },
    {
      title: `Масса\n${UnitOfMeasurement.Kg}`,
      dataIndex: TableColumnName.Mass,
      key: TableColumnName.Mass,
      type: CellType.String,
      width: 120,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Тип ТС',
      dataIndex: TableColumnName.VehicleType,
      key: TableColumnName.VehicleType,
      type: CellType.String,
      width: 100,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Статус\nтендера',
      dataIndex: TableColumnName.Status,
      key: TableColumnName.Status,
      type: CellType.String,
      width: 130,
      active: false,

      editable: false,
      sorting: 7,
      filtering: FilterCellType.Array,
      ellipsis: true
    },
    {
      title: 'Ответственный\nорганизатор',
      dataIndex: TableColumnName.UserID,
      key: TableColumnName.UserID,
      width: 160,
      active: false,

      editable: true,
      sorting: 8,
      filtering: FilterCellType.Array,
      ellipsis: true,
      type: CellType.SelectAPI,

      allowClear: false
    },
    {
      title: '',
      titleText: 'Минимальный\nрейтинг компании',
      titleTooltip: 'Минимальный рейтинг компании',
      titleIcon: 'carrier-rating',
      dataIndex: TableColumnName.MinCarrierRating,
      key: TableColumnName.MinCarrierRating,
      active: true,

      editable: false,
      sorting: 9,
      type: CellType.Int
    },
    {
      title: '',
      titleText: 'Минимальный\nрейтинг водителя',
      titleTooltip: 'Минимальный рейтинг водителя',
      titleIcon: 'driver-rating',
      dataIndex: TableColumnName.MinDriverRating,
      key: TableColumnName.MinDriverRating,
      width: 60,
      active: true,

      editable: false,
      sorting: 8,
      type: CellType.Int
    },
    {
      title: 'Базовая ставка',
      dataIndex: TableColumnName.BasePrice,
      key: TableColumnName.BasePrice,
      type: CellType.String,
      width: 70,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Минимальная цена',
      dataIndex: TableColumnName.CurrentPrice,
      key: TableColumnName.CurrentPrice,
      type: CellType.String,
      width: 70,
      active: true,

      editable: false,
      sorting: 6,
      filtering: FilterCellType.String,
      ellipsis: true
    }
  ]
};

export const COLUMNS_ONLY_FOR_CARRIER = [
  TableColumnName.CargoOwner,
  TableColumnName.Lots,
  TableColumnName.CurrentPrice,
  TableColumnName.BasePrice
];

export const tenderOwnConfig = {
  ...tenderListConfig,
  columns: tenderListConfig.columns.filter(({ dataIndex }) => !COLUMNS_ONLY_FOR_CARRIER.includes(dataIndex as TableColumnName))
};

export const tenderSearchConfig = {
  ...tenderListConfig,
  columns: tenderListConfig.columns.map((column) => {
    switch (column.dataIndex) {
      case TableColumnName.UserID:
        return { ...column, editable: false };
      default:
        return { ...column };
    }
  })
};
