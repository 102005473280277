import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { trackGetItemRoutine } from '../actions';
import { TrackStore } from '@modules/track/types';
import { Dictionary, IndexedArray, RouteTotal, TripTotal, WaypointTotal } from '@common/types';

const getPlan = createReducer(initialState, {
  [trackGetItemRoutine.REQUEST]: (state: TrackStore, action: PayloadAction<{ dictionary: Dictionary }>) => {
    const { dictionary } = action.payload;
    state[dictionary] = null;
    state.isFetching = true;
    return state;
  },
  [trackGetItemRoutine.SUCCESS]: (
    state: TrackStore,
    action: PayloadAction<{ dictionary: Dictionary; data: IndexedArray<RouteTotal | TripTotal | WaypointTotal> }>
  ) => {
    const { data, dictionary } = action.payload;
    state[dictionary] = data;
    state.lastUpdate = Date.now();
    return state;
  },
  [trackGetItemRoutine.FAILURE]: (state: TrackStore, action) => {
    const { error } = action.payload;
    state.error = error;
    return state;
  },
  [trackGetItemRoutine.FULFILL]: (state: TrackStore) => {
    state.isFetching = false;
    return state;
  }
});

export default getPlan;
