// Здесь находятся необходимые константы для расчетов диаграмм

export const CURRENT_TIME_TEXT = 'Текущее время';
export const TIME_OFFSET = 160;

export enum CHART_SIZE {
  Max = 60,
  PlanOnRoad = 0,
  PlanInWaypoint = 45,
  FactInWaypoint = 30,
  FactOnRoad = 15
}
