import { createArrayFromIndexedArray } from '@common/utils';
import { ProjectCatalogState } from '@common/types/dictionaries/project';
import { Vehicle } from '@common/types';

export const getTransportCompaniesOptions = (vehicles: ProjectCatalogState<Vehicle>) => {
  const transportCompaniesEnum = createArrayFromIndexedArray(vehicles).reduce((transportCompanies, { TransportCompanyEnum }) => {
    transportCompanies = { ...transportCompanies, ...TransportCompanyEnum };
    return transportCompanies;
  }, {});
  return Object.entries(transportCompaniesEnum).map(([ID, Name]) => ({ ID, Name }));
};
