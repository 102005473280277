import React, { FC, useEffect, useState } from 'react';
import '../styles.less';
import { useAppSelector } from '@core/hooks';
import { errorSelector, fetchSelector, savingSelector, successSelector } from '../selectors';
import { ILSSpin, ILSUserNotify } from '@common/components';
import { useDispatch } from 'react-redux';
import { roadGraphCleanRouteRoutine, roadGraphGetShortestRoutine } from '@modules/road-graph/route-test/actions';
import { RoadGraphContext } from '../context';
import { ICoord, IMarkerState } from '@modules/road-graph/route-test/types/map';
import ILSRoadGraphTestButtonsContainer from '@modules/road-graph/route-test/containers/buttons-container';
import ILSRoadGraphContainer from '@modules/road-graph/common/container';
import { Notify } from '@common/types';

const ILSRoadGraphTest: FC = () => {
  const error = useAppSelector(errorSelector);
  const isSaving = useAppSelector(savingSelector);
  const isFetching = useAppSelector(fetchSelector);
  const success = useAppSelector(successSelector);
  const dispatch = useDispatch();

  const [coords, setCoords] = useState<ICoord | undefined>(undefined);
  const markerState: IMarkerState = {
    moving: false,
    coords: undefined
  };
  const [trackMarker, setTrackMarker] = useState<{
    A: IMarkerState;
    B: IMarkerState;
  }>({
    A: { ...markerState },
    B: { ...markerState }
  });

  const [showDepots, setShowDepots] = useState(false);
  const [showZones, setShowZones] = useState<{
    show: boolean;
    selectedZones: Array<number>;
  }>({
    show: false,
    selectedZones: []
  });

  useEffect(() => {
    if (error?.length) {
      ILSUserNotify(Notify.Error, error, 3, 'route-test');
    }
    if (isSaving) {
      ILSUserNotify(Notify.Loading, isSaving, 60, 'route-test');
    }
    if (success) {
      ILSUserNotify(Notify.Success, success, 3, 'route-test');
    }
  }, [error, isSaving, success]);

  //загрузка трека
  const getTrack = () => {
    if (!(trackMarker?.A?.coords?.lon && trackMarker?.A?.coords?.lat && trackMarker?.B?.coords?.lon && trackMarker?.B?.coords?.lat)) return;

    const coordinates =
      trackMarker?.A?.coords?.lon +
      ',' +
      trackMarker?.A?.coords?.lat +
      ';' +
      trackMarker?.B?.coords?.lon +
      ',' +
      trackMarker?.B?.coords?.lat;

    const payload = {
      coordinates: coordinates,
      overview: 'full',
      geometries: 'polyline6',
      steps: 'true'
    };
    if (showZones.selectedZones?.length) {
      payload['zones_limit'] = showZones.selectedZones.join(';');
    }
    dispatch(roadGraphGetShortestRoutine(payload));
  };

  useEffect(() => {
    getTrack();
  }, [trackMarker, showZones.selectedZones]);

  const handleClean = () => {
    setTrackMarker({
      A: { ...markerState },
      B: { ...markerState }
    });
    dispatch(roadGraphCleanRouteRoutine());
  };
  const handleReverse = () => {
    setTrackMarker({
      A: trackMarker.B,
      B: trackMarker.A
    });
  };

  const contextValue = {
    showDepots,
    setShowDepots,
    showZones,
    setShowZones,
    trackMarker,
    setTrackMarker,
    coords,
    setCoords,
    handleClean,
    handleReverse
  };
  return (
    <>
      <ILSRoadGraphContainer showDepots={showDepots} setShowDepots={setShowDepots} />
      <ILSSpin spinning={isFetching}>
        <RoadGraphContext.Provider value={contextValue}>
          <ILSRoadGraphTestButtonsContainer />
        </RoadGraphContext.Provider>
      </ILSSpin>
    </>
  );
};

export default ILSRoadGraphTest;
