import { PlanPenalty } from '@common/types';
import { MAX_TOOLTIP_ITEM } from '@modules/planning/children/by-date/constants/tables';
import { PenaltyTipHTML } from '@modules/planning/children/by-date/components/result/tips';

export const getPenaltiesTip = (penalty: PlanPenalty[], penaltyPrice: number) => {
  const penaltiesWithValues = penalty?.filter(({ Coefficient, Value }) => Coefficient && Value);

  const needParted = penaltiesWithValues.length > MAX_TOOLTIP_ITEM;

  return PenaltyTipHTML({
    penalty: needParted ? penaltiesWithValues.slice(0, MAX_TOOLTIP_ITEM) : penaltiesWithValues,
    isParted: needParted,
    total: needParted ? penaltyPrice : 0
  });
};
