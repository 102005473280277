import './styles.less';
import { FC } from 'react';
import { ILSHeader } from '@common/components';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import cn from 'classnames';

interface IProps {
  className?: string;
}

export const ILSDemo: FC<IProps> = ({ children, className }) => {
  return (
    <div className={'demo'}>
      <ILSHeader>
        <Scope name={ModuleScope.Logo} extraProps={{ unAuthorizedPages: true }} />
        <Scope name={ModuleScope.AuthNav} />
      </ILSHeader>
      <section className={cn('demo_section', className)}>{children}</section>
    </div>
  );
};
