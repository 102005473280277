import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { RouteTestStore } from '../types';
import { roadGraphCleanRouteRoutine } from '@modules/road-graph/route-test/actions';
import { PayloadActionError } from '@common/types';

const roadGraphCleanRoute = createReducer(initialState, {
  [roadGraphCleanRouteRoutine.REQUEST]: (state: RouteTestStore) => {
    state.isFetching = true;
    return state;
  },
  [roadGraphCleanRouteRoutine.SUCCESS]: (state: RouteTestStore) => {
    state.trackData = null;
    state.isFetching = false;
    return state;
  },
  [roadGraphCleanRouteRoutine.FAILURE]: (state: RouteTestStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isFetching = false;
    state.error = error;
    return state;
  }
});

export default roadGraphCleanRoute;
