import { FC, MouseEvent } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, withRouter } from 'react-router-dom';
import { ILSMenu } from '@components/index';
import { useLocation } from 'react-router';
import { searchParamsFilter } from '@common/decorators/url-params';
import { default as H } from 'history';
import { Match } from '@modules/sidebar/types/component';
import { IComposedModule } from '@modules/sidebar/types/sidebar-menu';
//новое меню для работы с antd следующих версий
import { getMenuItem } from '@components/navigation/menu';

export interface IProps {
  activeKey?: string;
  collapsed: boolean;
  history?: H.History;
  location?: H.Location;
  match?: Match;
  navMenu: Array<IComposedModule>;
  staticContext?: string;
  toggleCollapsed: (e: MouseEvent<HTMLElement>) => void;
}

const ILSSidebarMenuComponent: FC<IProps> = ({ navMenu, activeKey, collapsed }) => {
  const { search } = useLocation();
  const filterSearch = searchParamsFilter(search);

  const content = navMenu.map((item) =>
    item?.submenu
      ? getMenuItem(
          item.title,
          item.key,
          collapsed ? (
            <Link className="head-menu-link" to={item.pathname + filterSearch}>
              <ReactSVG src={item.icon as string} />
            </Link>
          ) : (
            <ReactSVG src={item.icon as string} />
          ),
          [
            ...(collapsed
              ? [
                  getMenuItem(
                    <div className="sub-menu-title" key={'header' + item.key}>
                      <h3>{item.title}</h3>
                    </div>,
                    'header' + item.key
                  )
                ]
              : []),
            ...item.submenu?.map((subItem) => getMenuItem(<Link to={subItem.pathname + filterSearch}>{subItem.title}</Link>, subItem.key))
          ]
        )
      : getMenuItem(
          item.title,
          item.key,
          <Link className="menu-link" to={item.pathname + filterSearch}>
            {<ReactSVG src={item.icon as string} />}
          </Link>
        )
  );

  return <ILSMenu defaultSelectedKeys={['1']} defaultOpenKeys={['1']} mode="inline" selectedKeys={[activeKey ?? '1']} items={content} />;
};

export default withRouter<any, any>(ILSSidebarMenuComponent);
