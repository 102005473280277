import { DictionariesEndpoint, DictionaryDeleteRequest, DictionaryOperation } from '@common/types/general/api/dictionary';
import { Dictionary, IILSTableComponentProps, TableRecord } from '@common/types';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';

export const handleDeleteOrderCargo =
  ({ dispatch }: { dispatch: Dispatch }): IILSTableComponentProps<TableRecord>['handleDelete'] =>
  (ids) => {
    if (!ids) return;
    const payload: DictionaryOperation<DictionaryDeleteRequest> & {
      dictionaryFetching?: boolean;
    } = {
      ids,
      dictionary: Dictionary.OrderCargo,
      operation: DictionariesEndpoint.Delete
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };
