import { useModal } from '@common/hooks/components/use-modal';
import { useTemplateContractToCreateFromFakeTender } from '@modules/orders-and-trips/hooks/use-template-contract-to-create-from-fake-tender';
import { FakeContractPayload, FakeTripContractValues } from '@modules/orders-and-trips/types/fake-pre-cost';
import { RcFile } from 'antd/es/upload';
import { addOrderToServiceFromFakeRoutine, clearStateRoutine } from '@modules/orders-and-trips/actions';
import { uploadPdfFromStimulsoft } from '@common/utils/stimulsoft/upload-pdf-from-stimulsoft';
import { ORDERS_TRIPS_CONTRACT_NAME } from '@modules/orders-and-trips/constants/documents';
import { ButtonTypes } from '@common/types/components';
import { useDispatch, useSelector } from 'react-redux';
import { tripsContractTemplateSelector } from '@modules/orders-and-trips/selectors/trip';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const useFakeContractModal = ({ handleFormVisibility }: { handleFormVisibility: () => void }) => {
  const dispatch = useDispatch();
  const contractTemplate = useSelector(tripsContractTemplateSelector);

  const { visible: confirmModalVisible, handleShow: handleShowConfirmModal } = useModal();
  const { getTemplateContractToCreateFromFakeTender } = useTemplateContractToCreateFromFakeTender();

  const handleOpenConfirmModal = (payload: FakeTripContractValues) => {
    handleShowConfirmModal();
    getTemplateContractToCreateFromFakeTender(payload);
  };

  const uploadContract = (contractFile: File) => {
    const payload: FakeContractPayload = { attachment: contractFile as RcFile, handleFormVisibility };
    return dispatch(addOrderToServiceFromFakeRoutine(payload));
  };

  const confirmExternalCompanyExecute = () => {
    uploadPdfFromStimulsoft({ template: contractTemplate, fileName: ORDERS_TRIPS_CONTRACT_NAME, onUpload: uploadContract });
    handleShowConfirmModal();
  };

  const handleCancel = () => {
    handleShowConfirmModal();
    const payload: { key: keyof InitialState } = { key: 'tripContract' };
    dispatch(clearStateRoutine(payload));
  };

  const submitButtonProps = {
    [ButtonTypes.Cancel]: handleCancel,
    [ButtonTypes.Confirm]: confirmExternalCompanyExecute
  };

  return { confirmModalVisible, handleOpenConfirmModal, submitButtonProps, handleShowConfirmModal };
};
