import { ILSFormItem, ILSInputNumber, ILSSelect, ILSTextArea } from '@common/components';
import { IEnum } from '@common/types';
import {
  CARGO_INPUT_STEP,
  DEFAULT_INPUT_STEP,
  FORM_CARGO_SIZE_FIELDS,
  MIN_NUMBER_INPUT_VALUE,
  ORDER_AND_TRIP_FORM_RULES
} from '@modules/orders-and-trips/constants/form';
import { FormFieldNames, FormSections } from '@modules/orders-and-trips/types/form';
import { FC } from 'react';

interface IProps {
  index: number | string;
  restriction: IEnum<string>[];
  disabled: boolean;
  pointIndex: number;
}

export const CargoBlock: FC<IProps> = ({ index, restriction, disabled, pointIndex }) => {
  return (
    <div className="order-action-form-cargo order-cargo">
      <ILSFormItem
        name={[pointIndex, FormSections.Cargo, FormFieldNames.RestrictionID, index]}
        label="Специфика"
        rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.RestrictionID]}
      >
        <ILSSelect options={restriction} placeholder={'Выберите специфику груза'} disabled={disabled} />
      </ILSFormItem>
      <div className="flex-horizontal flex-wrap order-cargo-params">
        {FORM_CARGO_SIZE_FIELDS.map((field) => {
          return (
            <ILSFormItem
              key={FormSections.Cargo + index + field.name}
              name={[pointIndex, FormSections.Cargo, field.name, index]}
              label={field.label}
              rules={ORDER_AND_TRIP_FORM_RULES[field.name]}
            >
              <ILSInputNumber
                step={FormFieldNames.Amount === field.name ? DEFAULT_INPUT_STEP : CARGO_INPUT_STEP}
                placeholder={field.placeholder}
                disabled={disabled}
                min={MIN_NUMBER_INPUT_VALUE}
              />
            </ILSFormItem>
          );
        })}
      </div>
      <ILSFormItem
        name={[pointIndex, FormSections.Cargo, FormFieldNames.Comment, index]}
        label="Комментарий"
        rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.Comment]}
      >
        <ILSTextArea placeholder={'Введите комментарий'} disabled={disabled} />
      </ILSFormItem>
    </div>
  );
};

