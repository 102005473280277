import { FC } from 'react';
import { DemandListItemProjectContainer } from '../containers/demand-list-item-project';
import { DemandItemType } from '../types/api';
import { DemandRow } from '../types/store';
import { DemandListItemTenderLotContainer } from './../containers/demand-list-item-tender-lot';

interface Props {
  listItem: DemandRow;
}

// Компонент для отображения одной плашки в разделе "Заявки и рейсы"
export const DemandListItemComponent: FC<Props> = ({ listItem }) => {
  const { Type } = listItem;

  switch (Type) {
    case DemandItemType.Project:
      return <DemandListItemProjectContainer listItem={listItem} />;
    case DemandItemType.TenderLot:
      return <DemandListItemTenderLotContainer listItem={listItem} />;
    default:
      return null;
  }
};
