import { TIMEZONE_OFFSET } from '@common/constants/general/date-time';
import { useCallback } from 'react';

export const useGetWaypointTimeZone = () => {
  const getWpTimeZone = useCallback(
    (waypointOffset?: number | null | undefined) => {
      return waypointOffset ? waypointOffset * 60 : -TIMEZONE_OFFSET;
    },
    [TIMEZONE_OFFSET]
  );

  return {
    getWpTimeZone
  };
};
