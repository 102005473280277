import { Key } from '@common/types';
import { MapActivePlan } from '@modules/planning/children/by-date/types/map-container';
import { isEmpty } from 'lodash';
import { compareAsString } from '@common/utils';

export const getFocusItem = ({
  key,
  waypointMapData
}: {
  key?: Key | null | undefined;
  waypointMapData: MapActivePlan['waypointMapData'];
}): ((Arg: {
  VehicleID: number | null | undefined;
  TripID: number | null | undefined;
  WaypointID: number | null | undefined;
}) => boolean) => {
  if (!key) return () => false;
  if (isEmpty(waypointMapData)) return () => false;

  if (waypointMapData?.[key]) {
    return ({ WaypointID }) => compareAsString(key, WaypointID);
  } else {
    for (const wpID in waypointMapData) {
      const item = waypointMapData[wpID];
      if (compareAsString(item.TripID, key)) {
        return ({ TripID }) => compareAsString(key, TripID);
      } else if (compareAsString(item.VehicleID, key)) {
        return ({ VehicleID }) => compareAsString(key, VehicleID);
      }
    }
  }

  return () => true;
};
