import { DictionariesEndpoint } from '@common/types';

export const getMessageForOperation = (
  operation: DictionariesEndpoint,
  message?: {
    request: string;
    success: string;
    failure: string;
    fulfill: string;
  }
) => {
  switch (operation) {
    case DictionariesEndpoint.Index:
    case DictionariesEndpoint.Read:
      return (
        message ?? {
          request: '',
          success: '',
          failure: '',
          fulfill: ''
        }
      );
    case DictionariesEndpoint.Create:
      return (
        message ?? {
          request: 'Добавление...',
          success: 'Добавлено',
          failure: 'Ошибка при добавлении',
          fulfill: ''
        }
      );
    case DictionariesEndpoint.Update:
      return (
        message ?? {
          request: 'Изменение параметра...',
          success: 'Параметр изменен',
          failure: 'Ошибка изменения',
          fulfill: ''
        }
      );
    case DictionariesEndpoint.Delete:
      return (
        message ?? {
          request: 'Удаление...',
          success: '',
          failure: 'Ошибка при удалении',
          fulfill: ''
        }
      );
    default:
      return {
        request: '',
        success: '',
        failure: '',
        fulfill: ''
      };
  }
};
