import { ASSETS_BASE_PATH, ORIGIN_PATH } from '@common/constants/general/path';
import { IconName, ModulePath } from '@common/types';
import { IconPathConfig } from '@common/types/general/general';

export const getSRCIconSVG = (
  iconName: IconName,
  iconConfig: IconPathConfig = {
    originPath: false,
    iconModule: '/',
    iconDirectory: ASSETS_BASE_PATH
  }
) => {
  const { originPath = false, iconModule = ModulePath.Dashboard, iconDirectory = ASSETS_BASE_PATH } = iconConfig;
  return originPath ? `${ORIGIN_PATH}${iconDirectory}${iconModule}${iconName}.svg` : `${iconDirectory}${iconModule}${iconName}.svg`;
};
