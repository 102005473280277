import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { sortBoardsRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';

export const sortBoardsReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [sortBoardsRoutine.REQUEST]: (state) => {
    return state;
  },
  [sortBoardsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: AnalyticsBoardModel[];
    }>
  ) => {
    const { data } = action.payload;

    if (data.length && state.Boards.data) {
      const res = {};

      for (let item of data) {
        res[item.ID] = item;
      }

      state.Boards.data = res;
    }

    return state;
  },
  [sortBoardsRoutine.FAILURE]: (state) => {
    return state;
  },
  [sortBoardsRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.Boards.isFetching = false;
    return state;
  }
});
