import './styles.less';
import { FC } from 'react';
import { Calendar, CalendarProps } from 'react-multi-date-picker';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';

export const ILS_CALENDAR_PICKER_DISABLED_PICKED = {
  disabled: true,
  style: { border: '1px solid #4c91ff', borderRadius: '2px', color: '#4c91ff' }
};
export const ILS_CALENDAR_PICKER_BASE = {
  disabled: false
};

export const ILSCalendarPicker: FC<CalendarProps> = ({ format = ILS_MAIN_INTERFFACE_DATE_FORMAT, disabled = false, ...props }) => {
  return <Calendar className={'ils-calendar-picker'} format={format} {...props} multiple />;
};
