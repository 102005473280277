import { FC } from 'react';
import { ILSFormItem } from '@components/index';
import { Field, getPlaceholder, Placeholder } from '@common/constants/general';
import { LotFormFieldLabel, RouteField } from '@modules/tender-platform/types/lot';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import cn from 'classnames';

interface IProps {
  disabled: boolean;
  field: RouteField;
  onSetSelectedAddress: () => void;
}

export const TenderPlatformWaypointComponent: FC<IProps> = ({ field, disabled, onSetSelectedAddress }) => {
  const name = `${field}${Field.Address}`;
  const addressRules = [
    {
      required: !disabled,
      message: Field.Target ? 'Пожалуйста, введите адрес погрузки' : 'Пожалуйста, введите адрес разгрузки'
    }
  ];
  const className = cn(name, 'no-margin tender-form-fieldset-item');

  return (
    <ILSFormItem className={className} label={LotFormFieldLabel[name]} name={name} rules={addressRules}>
      <ILSSuggestionAutoComplete
        disabled={disabled}
        onSelect={onSetSelectedAddress}
        field={Field.Address}
        placeholder={getPlaceholder(Placeholder[Field.Address], disabled)}
      />
    </ILSFormItem>
  );
};
