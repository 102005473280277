import {
  demandActiveTab,
  demandTabMetrics,
  hasDesyncSelector,
  isAllUploadedSelector,
  listSelector,
  loadParamsSelector
} from '@modules/demand/selectors';
import { accountRoleSelector } from '@modules/current-user/selectors/auth';
import { createSelector } from 'reselect';

export const demandSelector = createSelector(
  listSelector,
  isAllUploadedSelector,
  hasDesyncSelector,
  loadParamsSelector,
  demandTabMetrics,
  demandActiveTab,
  accountRoleSelector,
  (list, isAllUploaded, hasDesync, loadParams, hintData, activeTab, roles) => {
    return { list, isAllUploaded, hasDesync, loadParams, hintData, activeTab, roles };
  }
);
