import moment from 'moment';
import { ILSTimeRangePicker } from '@components/index';
import { ILS_MAIN_INTERFFACE_TIME_FORMAT } from '@common/types/general/date-time';
import { doubleToTime } from '@common/utils';
import { isArray, isEmpty } from 'lodash';
import { IILSCellTimeRangeProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellTimeRange = <R extends TableRecord>({
  format = ILS_MAIN_INTERFFACE_TIME_FORMAT,
  children,
  toggleEdit,
  save
}: IILSCellTimeRangeProps<R>): JSX.Element | null => {
  const [, period] = children;

  if (!isArray(period)) {
    showUserAndDevError({ error: 'Дата должна быть в формате (MomentInput | TimeStamp)[]' });
    return <></>;
  }

  const [startTime, endTime] = period;

  const defaultValue = [moment(doubleToTime(startTime), format), moment(doubleToTime(endTime), format)];

  if (isEmpty(period)) return null;

  const onChange = ([start, end]: any) => {
    if (!start || !end) return;
    save([start.format(format), end.format(format)]);
  };

  return (
    <ILSTimeRangePicker
      open
      autoFocus
      className={'cell-wrapper-editable-full'}
      //TODO defaultValue type fix
      //@ts-ignore
      defaultValue={defaultValue}
      onBlur={toggleEdit}
      onChange={onChange}
    />
  );
};
