import { ILSTooltip } from '@components/index';
import { ILSButton } from '@components/general/buttons';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectedOrderTableRowsSelector,
  selectedRouteTableRowsSelector
} from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { Key, Keys, TableName } from '@common/types';
import { planningFocusNExpandRowIdsRoutesTableSelector } from '@modules/planning/children/by-date/selectors/planning';
import { planTripIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { compareAsString } from '@common/utils';

interface IProps {
  handleShowVehicleTable(val: boolean): void;

  addOrderForVehicle(existTrip: boolean, tripIDForOrder?: Key): void;
}

export const getStatesExistRouteForButton = ({
  selectedTripsIDs,
  activeRowTripID
}: {
  selectedTripsIDs: Keys;
  activeRowTripID: number | undefined;
}) => {
  const hasTrip = !isEmpty(selectedTripsIDs) || Boolean(activeRowTripID);

  if (!hasTrip) {
    return { title: 'Выберите рейс в активном плане', disabled: true };
  }

  const selectMoreThenOne = selectedTripsIDs.length > 1;
  if (selectMoreThenOne) {
    return { title: 'Необходимо выбрать один рейс', disabled: true };
  }
  return { title: `Добавить заявки в рейс №${selectedTripsIDs[0] ?? activeRowTripID}`, disabled: false };
};

export const useAddOrderForVehicleButtons = () => {
  const { selectedTripsIDs } = useSelector(selectedRouteTableRowsSelector);
  const { selectedOrders } = useSelector(selectedOrderTableRowsSelector);
  const { focusRow } = useSelector(planningFocusNExpandRowIdsRoutesTableSelector(TableName.RoutesTable));
  const tripIDs = useSelector(planTripIDsSelector);
  const activeRowTripID = tripIDs.find((id) => compareAsString(id, focusRow.key));
  const { title, disabled } = getStatesExistRouteForButton({ selectedTripsIDs, activeRowTripID });
  const tripIDForOrder = selectedTripsIDs[0] ?? activeRowTripID;

  return { hasButton: !isEmpty(selectedOrders), title, disabled, tripIDForOrder };
};

export const ILSCChosenVehicle: FC<IProps> = ({ addOrderForVehicle, handleShowVehicleTable }) => {
  const { title, disabled, hasButton, tripIDForOrder } = useAddOrderForVehicleButtons();

  if (!hasButton) return null;

  return (
    <div className="ils-choosed-vehicle">
      <ILSTooltip title={title}>
        <ILSButton
          className="ils-vehicle-btn"
          disabled={disabled}
          icon={<ReactSVG src={'/assets/icons/track-new.svg'} />}
          onClick={() => addOrderForVehicle(true, tripIDForOrder)}
        >
          {!disabled ? 'Добавить заявки' : 'Выберите рейс'}
        </ILSButton>
      </ILSTooltip>
      <ILSTooltip title={'Выберите новый автомобиль'}>
        <ILSButton
          onClick={() => handleShowVehicleTable?.(true)}
          className="ils-vehicle-btn"
          icon={<ReactSVG src={'/assets/icons/track-wait.svg'} />}
        >
          Выбрать авто
        </ILSButton>
      </ILSTooltip>
    </div>
  );
};

