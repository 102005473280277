import { call, put, takeLatest } from 'redux-saga/effects';
import { searchCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* searchCarriersWorker(
  action: PayloadAction<{
    query: string;
  }>
) {
  const { request, success, failure, fulfill } = searchCarriersRoutine;
  const { query } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderOwn.searchCarriers, { q: query });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* searchCarriersWatcher() {
  yield takeLatest(searchCarriersRoutine.trigger, searchCarriersWorker);
}
