import { useSelector } from 'react-redux';
import { tripsContractFetchSelector, tripsContractTemplateSelector } from '@modules/orders-and-trips/selectors/trip';

export const useExternalTransportContract = () => {
  const contractTemplate = useSelector(tripsContractTemplateSelector);
  const isFetching = useSelector(tripsContractFetchSelector);

  return {
    contractTemplate,
    isFetching
  };
};

