import { ILSButton, ILSButtonTooltip } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import { IActionButtonProps } from '@modules/orders-and-trips/types/general';
import classNames from 'classnames';
import { FC, memo } from 'react';

export const ActionButton: FC<IActionButtonProps> = memo(({ click, isFetching, disabled, text, tooltip, className, title, ...props }) => {
  return (
    <>
      {tooltip ? (
        <ILSButtonTooltip
          title={title ?? EMPTY_STRING}
          onClick={click}
          type="primary"
          className={classNames('action-button black', className)}
          disabled={disabled}
          {...props}
        >
          {text}
        </ILSButtonTooltip>
      ) : (
        <ILSButton
          loading={isFetching}
          onClick={click}
          type="primary"
          className={classNames('action-button black', className)}
          disabled={disabled}
          {...props}
        >
          {text}
        </ILSButton>
      )}
    </>
  );
});

