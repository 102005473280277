import { DictionariesEndpoint, Errors, Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { takeLatest } from 'redux-saga/effects';
import { AccountAction, accountRoutinesToNotify, UserAction, userRoutinesToNotify } from '../../actions';
import { EMPTY_STRING } from '@common/constants/general';
import { DEFAULT_ACCOUNT_MESSAGES, DEFAULT_USER_MESSAGES } from '@modules/account/constants';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { NotifyMessage } from '@common/types/general/reducers';

const getDataNotificationMessage = <T extends string>(defaultMessage: NotifyMessage<T>, actions: any) => {
  return (
    action: T,
    type: Notify.Loading | Notify.Success | Notify.Error,
    payload?: { message: string; hideSuccessMessage?: string; operation?: DictionariesEndpoint }
  ) => {
    if (payload?.hideSuccessMessage) return payload.hideSuccessMessage;
    return payload?.message || defaultMessage?.[payload?.operation ?? actions?.[action]]?.[type] || EMPTY_STRING;
  };
};

const accountWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, AccountAction>({
  getActionFromRoutine: (actionName: string) => actionName as AccountAction,
  getKeyPrefix: () => 'account',
  getNotificationMessage: getDataNotificationMessage<AccountAction>(DEFAULT_ACCOUNT_MESSAGES, AccountAction)
});

const accountErrorWorker = notifierWorkerCreator<{ error: Errors }, AccountAction>({
  getActionFromRoutine: (actionName: string) => actionName as AccountAction,
  getKeyPrefix: () => 'account',
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

const userWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, UserAction>({
  getActionFromRoutine: (actionName: string) => actionName as UserAction,
  getKeyPrefix: () => 'user',
  getNotificationMessage: getDataNotificationMessage<UserAction>(DEFAULT_USER_MESSAGES, UserAction)
});

const userErrorWorker = notifierWorkerCreator<{ error: Errors }, UserAction>({
  getActionFromRoutine: (actionName: string) => actionName as UserAction,
  getKeyPrefix: () => 'user',
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* accountNotifierWatcher() {
  for (const routine of accountRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, accountWorker);
    yield takeLatest(routine.SUCCESS, accountWorker);
    yield takeLatest(routine.FAILURE, accountErrorWorker);
  }
  for (const routine of userRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, userWorker);
    yield takeLatest(routine.SUCCESS, userWorker);
    yield takeLatest(routine.FAILURE, userErrorWorker);
  }
}
