import './styles.less';
import { FC } from 'react';
import { ILSButton, ILSDragger, ILSUserNotify } from '@common/components';
import { ReactSVG } from 'react-svg';
import { allowedFileExtensions, fileMaxSize } from '@modules/documents/children/upload/constants/file-upload-validation';
import { Notify } from '@common/types';

interface IILSDocumentsFileInputProps {
  onChange: (file: File) => void;
}

const ILSDocumentsFileInput: FC<IILSDocumentsFileInputProps> = ({ onChange }) => {
  const beforeUpload = (file: File) => {
    if (!file.size) {
      ILSUserNotify(Notify.Error, 'Нельзя загружать пустые файлы', 3);
      return false;
    }

    if (file.size > fileMaxSize) {
      ILSUserNotify(Notify.Error, 'Максимальный размер файла 20МБ', 3);
      return false;
    }

    const fileExtension = file.name.split('.').pop();

    if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
      ILSUserNotify(Notify.Error, 'Допустимые форматы файлов: jpg, jpeg, png, bmp, doc, docx, pdf, xls, xlsx', 3);
      return false;
    }

    onChange(file);
    return false;
  };

  return (
    <ILSDragger beforeUpload={beforeUpload} className="documents-upload-container" showUploadList={false}>
      <div className="ant-upload-drag-icon mb-3">
        <ReactSVG src={'/assets/icons/documents/upload.svg'} />
      </div>
      <div className="font-18">Перенесите документ в это поле</div>
      <div className="font-18 hint mb-2">или нажмите</div>
      <ILSButton type="primary">Выбрать файл на устройстве</ILSButton>
    </ILSDragger>
  );
};

export default ILSDocumentsFileInput;
