import { Dictionary, VehicleTableColumn, VehicleUploadFileType } from '@common/types';
import { getTakeForPlanner } from '@common/models/vehicle/helpers/get-take-for-planner';
import { int2color } from '@common/decorators/color';

export const plannerDataVehicleToTable = (data: VehicleUploadFileType[], dictionary: Dictionary) => {
  const { VehicleTimelineMaxTrips } = VehicleTableColumn;
  return data?.map((record: VehicleUploadFileType, index: number) => {
    const TakeForPlanner = getTakeForPlanner(record);
    return {
      ...record,
      RegNumber: record.RegNumber?.toString().toUpperCase() ?? '',
      IsTrailer: !Number(record.IsTrailer) ? 1 : 0,
      [VehicleTimelineMaxTrips]: record[VehicleTimelineMaxTrips] ? Number(record[VehicleTimelineMaxTrips]) : 0,
      key: record.VehicleCode ? record.VehicleCode : dictionary + index,
      fileIndex: index,
      TakeForPlanner,
      RouteColor: int2color(record.RouteColor)
    };
  });
};
