import {
  CellType,
  DateFormat,
  FilterCellType,
  ShowDeleted,
  TableColumnName,
  TableConfig,
  VehiclePriority,
  VehicleTakeForPlanner,
  VehicleUseMode
} from '@common/types';
import { AlignType } from 'rc-table/lib/interface';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';
import { VehiclePriorityLabel } from '@common/types/dictionaries/vehicle';

export const VEHICLE_PRIORITY_OPTIONS = [
  {
    value: VehiclePriority.Own,
    label: VehiclePriorityLabel.Own
  },
  {
    value: VehiclePriority.Partner,
    label: VehiclePriorityLabel.Partner
  },
  {
    value: VehiclePriority.Hired,
    label: VehiclePriorityLabel.Hired
  }
];

export enum VehicleUseModeLabel {
  NotUsed = 'Нет',
  AsVirtual = 'Как виртуальное',
  AsReal = 'Как реальное'
}

export const VEHICLE_USE_MODE_OPTIONS = [
  {
    value: VehicleUseMode.NotUsed,
    label: VehicleUseModeLabel.NotUsed
  },
  {
    value: VehicleUseMode.AsVirtual,
    label: VehicleUseModeLabel.AsVirtual
  },
  {
    value: VehicleUseMode.AsReal,
    label: VehicleUseModeLabel.AsReal
  }
];

export const NEW_VEHICLE = {
  Name: 'Новый автомобиль',
  TakeForPlanner: VehicleTakeForPlanner.NotTaken,
  TakeOnlyOrdersInZones: 0,
  IsAutomotive: 1
};

export const VEHICLE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  export: true,
  copying: true,
  multiEditing: true,
  reloading: true,
  adding: NEW_VEHICLE,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Код',
      dataIndex: TableColumnName.VehicleCode,
      key: TableColumnName.VehicleCode,
      editable: true,
      active: false,
      type: CellType.Input,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Название',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 5,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Модель',
      dataIndex: TableColumnName.Model,
      key: TableColumnName.Model,
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 4,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Гос. номер',
      dataIndex: TableColumnName.RegNumber,
      key: TableColumnName.RegNumber,
      editable: true,
      active: true,
      type: CellType.RegNumber,

      sorting: 3,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Тип транспортного средства',
      dataIndex: TableColumnName.VehicleTypeID,
      key: TableColumnName.VehicleTypeID,
      editable: true,
      active: true,
      type: CellType.SelectAPI,

      sorting: 1,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Цвет маршрута',
      dataIndex: TableColumnName.RouteColor,
      key: TableColumnName.RouteColor,
      editable: true,
      active: false,

      type: CellType.Color,
      sorting: 8,
      ellipsis: true
    },
    {
      title: 'Выезд из',
      dataIndex: TableColumnName.StartDepotID,
      key: TableColumnName.StartDepotID,
      active: true,

      editable: true,
      type: CellType.CascaderAPI,
      sorting: 2,
      ellipsis: false,
      grouping: true
    },
    {
      title: 'Возврат в',
      dataIndex: TableColumnName.EndDepotID,
      key: TableColumnName.EndDepotID,
      active: true,

      editable: true,
      type: CellType.CascaderAPI,
      sorting: 10,

      ellipsis: false,
      grouping: true
    },
    {
      title: 'Использовать',
      dataIndex: TableColumnName.TakeForPlanner,
      key: TableColumnName.TakeForPlanner,
      active: false,

      editable: true,
      invisible: true,
      sorting: 11,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Дата старта',
      dataIndex: TableColumnName.StartDate,
      key: TableColumnName.StartDate,
      invisible: true,
      active: false,

      editable: true,
      ellipsis: true,
      type: CellType.Date,
      sorting: 12,
      grouping: true
    },
    {
      title: 'Выезд в проекте',
      dataIndex: TableColumnName.StartTime,
      key: TableColumnName.StartTime,
      invisible: true,
      titleText: 'Выезд в проекте',
      titleTooltip: 'Время необходимо указывать по местному часовому поясу, откуда будет осуществляться выезд',
      active: false,

      editable: true,
      isFixedColumn: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      sorting: 13,
      grouping: true
    },
    {
      title: 'Приоритет',
      dataIndex: TableColumnName.Priority,
      key: TableColumnName.Priority,
      active: false,

      editable: true,
      sorting: 14,
      type: CellType.Select,
      ellipsis: true,
      fieldNames: BASE_FIELD_NAMES,

      options: VEHICLE_PRIORITY_OPTIONS,
      grouping: true
    },
    {
      title: 'Водитель',
      dataIndex: TableColumnName.DriverID,
      key: TableColumnName.DriverID,
      active: true,

      editable: true,
      type: CellType.SelectAPI,
      sorting: 15,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Экспедитор',
      dataIndex: TableColumnName.ExpeditorID,
      key: TableColumnName.ExpeditorID,
      active: false,

      editable: true,
      ellipsis: true,
      sorting: 17,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Профиль',
      titleTip: 'Характеристики профиля автомобиля',
      dataIndex: TableColumnName.VehicleProfileID,
      key: TableColumnName.VehicleProfileID,
      active: true,
      sorting: 18,

      editable: true,
      type: CellType.SelectAPI,
      ellipsis: true,
      closeSelectOnScroll: true,
      grouping: true
    },
    {
      title: 'Грузоподъемность, кг',
      dataIndex: TableColumnName.VehicleProfileMaxMass,
      key: TableColumnName.VehicleProfileMaxMass,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 20,
      grouping: true
    },
    {
      title: 'Объем кузова, м3',
      dataIndex: TableColumnName.VehicleProfileMaxVolume,
      key: TableColumnName.VehicleProfileMaxVolume,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 21,
      grouping: true
    },
    {
      title: 'Паллет, шт',
      dataIndex: TableColumnName.VehicleProfileMaxPallets,
      key: TableColumnName.VehicleProfileMaxPallets,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 22,
      grouping: true
    },
    {
      title: 'Коробов, шт',
      dataIndex: TableColumnName.VehicleProfileMaxBoxes,
      key: TableColumnName.VehicleProfileMaxBoxes,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 23,
      grouping: true
    },
    {
      title: 'Стоимость, руб',
      dataIndex: TableColumnName.VehicleProfileMaxCost,
      key: TableColumnName.VehicleProfileMaxCost,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 24,
      grouping: true
    },
    {
      title: 'Длина кузова, мм',
      dataIndex: TableColumnName.VehicleProfileMaxUnitLength,
      key: TableColumnName.VehicleProfileMaxUnitLength,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 25,
      grouping: true
    },
    {
      title: 'Ширина кузова, мм',
      dataIndex: TableColumnName.VehicleProfileMaxUnitWidth,
      key: TableColumnName.VehicleProfileMaxUnitWidth,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 26,
      grouping: true
    },
    {
      title: 'Высота кузова, мм',
      dataIndex: TableColumnName.VehicleProfileMaxUnitHeight,
      key: TableColumnName.VehicleProfileMaxUnitHeight,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 27,
      grouping: true
    },
    {
      title: 'Марка',
      dataIndex: TableColumnName.VehicleProfileCarMark,
      key: TableColumnName.VehicleProfileCarMark,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 28,
      grouping: true
    },
    {
      title: 'Средняя скорость, км/ч',
      dataIndex: TableColumnName.VehicleProfileAverageSpeed,
      key: TableColumnName.VehicleProfileAverageSpeed,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 29,
      grouping: true
    },
    {
      title: 'Расход топлива',
      dataIndex: TableColumnName.VehicleProfileFuelConsumption,
      key: TableColumnName.VehicleProfileFuelConsumption,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      sorting: 30,
      grouping: true
    },
    {
      title: 'Длительность погрузки',
      dataIndex: TableColumnName.VehicleProfileLoadDuration,
      key: TableColumnName.VehicleProfileLoadDuration,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.TimeDouble,
      ellipsis: true,
      sorting: 31,
      grouping: true
    },
    {
      title: 'Длительность разгрузки',
      dataIndex: TableColumnName.VehicleProfileUnloadDuration,
      key: TableColumnName.VehicleProfileUnloadDuration,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.TimeDouble,
      ellipsis: true,
      sorting: 32,
      grouping: true
    },
    {
      title: 'Режим работы автомобиля',
      dataIndex: TableColumnName.VehicleTimelineID,
      key: TableColumnName.VehicleTimelineID,
      active: true,

      editable: true,
      sorting: 33,
      type: CellType.SelectAPI,

      closeSelectOnScroll: true,
      grouping: true
    },
    {
      title: 'Клиентов, шт',
      dataIndex: TableColumnName.VehicleTimelineMaxClients,
      key: TableColumnName.VehicleTimelineMaxClients,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 35,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Заказов, шт',
      dataIndex: TableColumnName.VehicleTimelineMaxOrders,
      key: TableColumnName.VehicleTimelineMaxOrders,
      align: 'center' as AlignType,
      active: false,
      editable: false,
      sorting: 36,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Время в рейсе',
      dataIndex: TableColumnName.VehicleTimelineMaxDuration,
      key: TableColumnName.VehicleTimelineMaxDuration,
      active: true,
      editable: false,
      type: CellType.TimeDouble,
      ellipsis: true,
      sorting: 37,
      grouping: true
    },
    {
      title: 'Рейсов, шт',
      dataIndex: TableColumnName.VehicleTimelineMaxTrips,
      key: TableColumnName.VehicleTimelineMaxTrips,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 24,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Часов без отдыха',
      dataIndex: TableColumnName.VehicleTimelineDurationOfWork,
      key: TableColumnName.VehicleTimelineDurationOfWork,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 38,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Часов отдыха',
      dataIndex: TableColumnName.VehicleTimelineDurationOfRest,
      key: TableColumnName.VehicleTimelineDurationOfRest,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 39,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Работ.до пер.',
      dataIndex: TableColumnName.VehicleTimelineDurationOfFirstWork,
      key: TableColumnName.VehicleTimelineDurationOfFirstWork,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Первый отдых',
      dataIndex: TableColumnName.VehicleTimelineDurationOfFirstRest,
      key: TableColumnName.VehicleTimelineDurationOfFirstRest,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Работа в день, ч',
      dataIndex: TableColumnName.VehicleTimelineWorkPerDay,
      key: TableColumnName.VehicleTimelineWorkPerDay,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Режим отдыха',
      dataIndex: TableColumnName.VehicleTimelineDriverRestMode,
      key: TableColumnName.VehicleTimelineDriverRestMode,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Км в рейсе',
      dataIndex: TableColumnName.VehicleTimelineMaxDistance,
      key: TableColumnName.VehicleTimelineMaxDistance,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Км без отдыха',
      dataIndex: TableColumnName.VehicleTimelineDistanceOfWork,
      key: TableColumnName.VehicleTimelineDistanceOfWork,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Время в маршруте',
      dataIndex: TableColumnName.VehicleTimelineMaxDurationRoute,
      key: TableColumnName.VehicleTimelineMaxDurationRoute,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Км в маршруте',
      dataIndex: TableColumnName.VehicleTimelineMaxDistanceRoute,
      key: TableColumnName.VehicleTimelineMaxDistanceRoute,
      align: 'center' as AlignType,
      editable: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Выезд',
      dataIndex: TableColumnName.VehicleTimelineMinStartTime,
      key: TableColumnName.VehicleTimelineMinStartTime,
      editable: false,
      type: CellType.TimeDouble,
      sorting: 40,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Превышения',
      dataIndex: TableColumnName.VehicleOverloadID,
      key: TableColumnName.VehicleOverloadID,
      active: true,

      sorting: 22,
      editable: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Скорости',
      dataIndex: TableColumnName.SpeedProfileID,
      key: TableColumnName.SpeedProfileID,
      active: true,

      editable: true,
      sorting: 24,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Специфика',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: true,

      editable: true,
      sorting: 25,
      type: CellType.SelectMultipleApi,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Транспортная компания',
      dataIndex: TableColumnName.TransportCompanyID,
      key: TableColumnName.TransportCompanyID,
      editable: true,
      active: true,
      type: CellType.SelectAPI,

      sorting: 48,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Тариф',
      dataIndex: TableColumnName.VehicleTariffID,
      key: TableColumnName.VehicleTariffID,
      active: true,

      editable: true,
      sorting: 26,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Запрет',
      dataIndex: TableColumnName.ForbiddenZoneID,
      key: TableColumnName.ForbiddenZoneID,
      active: true,

      sorting: 27,
      editable: true,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Шаблон рейса',
      dataIndex: TableColumnName.TripTemplateID,
      key: TableColumnName.TripTemplateID,
      active: true,

      sorting: 28,
      editable: true,
      type: CellType.SelectMultipleApi,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Зоны доставки',
      dataIndex: TableColumnName.FriendZoneID,
      key: TableColumnName.FriendZoneID,
      active: true,

      editable: true,
      sorting: 29,
      type: CellType.SelectMultipleApi,

      titleTooltip: 'Подсказка: Зона доставки - используется для планирования по зонам',
      grouping: true
    },
    {
      title: 'В зоне',
      ellipsis: true,
      dataIndex: TableColumnName.TakeOnlyOrdersInZones,
      key: TableColumnName.TakeOnlyOrdersInZones,
      active: true,
      required: false,

      sorting: 30,
      editable: true,
      type: CellType.Bool,
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Доступность',
      dataIndex: TableColumnName.ReleaseDate,
      key: TableColumnName.ReleaseDate,
      active: false,

      editable: true,
      ellipsis: true,
      type: CellType.Date,
      sorting: 31,
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Режим исп.',
      dataIndex: TableColumnName.UseMode,
      key: TableColumnName.UseMode,
      active: true,
      sorting: 40,

      editable: true,
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,
      options: VEHICLE_USE_MODE_OPTIONS,
      grouping: true
    },
    {
      title: 'Cамоходное',
      dataIndex: TableColumnName.IsAutomotive,
      key: TableColumnName.IsAutomotive,
      active: false,
      required: false,

      editable: true,
      sorting: 43,
      type: CellType.Bool,
      ellipsis: true,
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Время выезда строго',
      dataIndex: TableColumnName.StrictStartTime,
      key: TableColumnName.StrictStartTime,
      active: false,
      required: false,

      editable: true,
      sorting: 41,
      type: CellType.Bool,
      ellipsis: true,
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Перед. сцепка',
      dataIndex: TableColumnName.FrontCouplingTypeID,
      key: TableColumnName.FrontCouplingTypeID,
      active: false,
      sorting: 44,
      required: false,

      editable: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Задн. сцепка',
      dataIndex: TableColumnName.RearCouplingTypeID,
      key: TableColumnName.RearCouplingTypeID,
      active: false,
      required: false,

      editable: true,
      type: CellType.SelectAPI,
      sorting: 45,
      grouping: true
    },
    {
      title: 'Редакт. автосцепки',
      dataIndex: TableColumnName.RoadTrain,
      key: TableColumnName.RoadTrain,
      active: false,
      type: CellType.StringMultiple,
      grouping: true
    },
    {
      title: 'Комментарий',
      dataIndex: TableColumnName.Comment,
      key: TableColumnName.Comment,
      active: false,

      editable: true,
      sorting: 47,
      ellipsis: true,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    }
  ]
};

/** Колонки смежных таблиц не редактируются из таблицы автомобили */
export const VEHICLE_NOT_EDITABLE_COLUMN = [
  TableColumnName.VehicleProfileMaxMass,
  TableColumnName.VehicleProfileMaxVolume,
  TableColumnName.VehicleProfileMaxPallets,
  TableColumnName.VehicleProfileMaxBoxes,
  TableColumnName.VehicleProfileMaxCost,
  TableColumnName.VehicleProfileMaxUnitLength,
  TableColumnName.VehicleProfileMaxUnitWidth,
  TableColumnName.VehicleProfileMaxUnitHeight,
  TableColumnName.VehicleProfileCarMark,
  TableColumnName.VehicleProfileAverageSpeed,
  TableColumnName.VehicleProfileFuelConsumption,
  TableColumnName.VehicleProfileLoadDuration,
  TableColumnName.VehicleProfileUnloadDuration,
  TableColumnName.VehicleTimelineMaxClients,
  TableColumnName.VehicleTimelineMaxOrders,
  TableColumnName.VehicleTimelineMaxTrips,
  TableColumnName.VehicleTimelineMaxClients,
  TableColumnName.VehicleTimelineMaxDuration,
  TableColumnName.VehicleTimelineDurationOfWork,
  TableColumnName.VehicleTimelineDurationOfRest,
  TableColumnName.VehicleTimelineDurationOfFirstWork,
  TableColumnName.VehicleTimelineDurationOfFirstRest,
  TableColumnName.VehicleTimelineWorkPerDay,
  TableColumnName.VehicleTimelineDriverRestMode,
  TableColumnName.VehicleTimelineMaxDistance,
  TableColumnName.VehicleTimelineDistanceOfWork,
  TableColumnName.VehicleTimelineMaxDurationRoute,
  TableColumnName.VehicleTimelineMaxDistanceRoute,
  TableColumnName.VehicleTimelineMinStartTime
];
const VEHICLE_FILTER_COLUMNS = [TableColumnName.StartDate, TableColumnName.StartTime, TableColumnName.TakeForPlanner];
const VEHICLE_TABLE_PLANNER_COLUMN_EDITABLE = VEHICLE_FILTER_COLUMNS;
const VEHICLE_TABLE_PLANNER_COLUMN_UPDATE = [TableColumnName.Name, TableColumnName.StartDate, TableColumnName.StartTime];

export const VEHICLE_TABLE_CONFIG_PLANNER: TableConfig = {
  ...VEHICLE_TABLE_CONFIG,
  deleting: false,
  reloading: false,
  adding: false,
  closeButton: true,
  approvedOrder: true,
  selecting: false,
  filtered: true,
  multiEditing: false,
  externalFocusControl: true,
  export: false,
  copying: false,
  showDeleted: ShowDeleted.No,
  configClassName: 'table-config-planner-vehicle',
  columns: VEHICLE_TABLE_CONFIG.columns.map(({ dataIndex, ...column }) => {
    return {
      ...column,
      dataIndex,
      invisible: false,
      update: VEHICLE_TABLE_PLANNER_COLUMN_UPDATE.includes(dataIndex as TableColumnName),
      droppable: TableColumnName.Name === dataIndex,
      editable: VEHICLE_TABLE_PLANNER_COLUMN_EDITABLE.includes(dataIndex as TableColumnName)
    };
  })
};

export const VEHICLE_VALIDATION_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  adding: NEW_VEHICLE,
  columns: [
    {
      title: 'Код',
      dataIndex: 'VehicleCode',
      key: 'VehicleCode',
      editable: true,
      active: false,
      filtering: 'string',
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      sorting: 10
    },
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Модель',
      dataIndex: 'Model',
      key: 'Model',
      editable: true,
      active: false,
      type: CellType.Input,
      width: 110,
      ellipsis: true,

      sorting: 4,
      filtering: 'string'
    },
    {
      title: 'Гос. номер',
      dataIndex: 'RegNumber',
      key: 'RegNumber',
      editable: true,
      active: true,
      type: CellType.RegNumber,

      sorting: 3,
      filtering: 'string',
      ellipsis: true
    },
    {
      title: 'Цвет маршрута',
      dataIndex: 'RouteColor',
      key: 'RouteColor',
      editable: true,
      active: false,
      filtering: 'string',

      type: CellType.Color,
      sorting: 1,
      ellipsis: true
    },
    {
      title: 'Точка выезда',
      dataIndex: 'StartDepot.DepotCode',
      key: 'StartDepot.DepotCode',
      active: true,
      filtering: 'string',

      editable: true,

      type: CellType.Input,
      sorting: 2,
      ellipsis: true
    },
    {
      title: 'Точка возврата автомобиля',
      dataIndex: 'EndDepot.DepotCode',
      key: 'EndDepot.DepotCode',
      active: false,
      filtering: 'string',

      editable: true,
      width: 160,
      type: CellType.Input,
      sorting: 1,
      ellipsis: true
    },
    {
      title: 'Использовать',
      dataIndex: 'TakeForPlanner',
      key: 'TakeForPlanner',
      active: true,
      filtering: 'string',

      editable: true,

      sorting: 1,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Дата старта',
      dataIndex: 'StartDate',
      key: 'StartDate',
      active: true,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      width: 140,
      format: DateFormat.DDMMYYYYPeriod,
      type: CellType.Date,
      sorting: 1
    },
    {
      title: 'Выезд в проекте',
      dataIndex: 'StartTime',
      key: 'StartTime',
      active: true,
      filtering: 'string',

      editable: true,

      type: CellType.TimeDouble,
      ellipsis: true,
      sorting: 1
    },
    {
      title: 'Приоритет',
      dataIndex: 'Priority',
      key: 'Priority',
      active: false,

      editable: true,

      sorting: 1,
      type: CellType.Select,
      ellipsis: true,
      fieldNames: BASE_FIELD_NAMES,

      options: VEHICLE_PRIORITY_OPTIONS,
      filtering: 'array'
    },
    {
      title: 'Водитель',
      dataIndex: 'Driver.DriverCode',
      key: 'Driver.DriverCode',
      active: false,

      editable: false,
      width: 160,
      type: CellType.Input,
      sorting: 1,
      ellipsis: true,
      filtering: 'array'
    },
    {
      title: 'Название профиля автомобиля',
      dataIndex: 'VehicleProfile.Name',
      key: 'VehicleProfile.Name',
      editable: false,
      active: true,
      type: CellType.Input,
      width: 160,
      ellipsis: true,

      sorting: 3,
      filtering: 'string'
    },
    {
      title: 'Грузоподъемность, кг',
      dataIndex: 'VehicleProfile.MaxMass',
      key: 'VehicleProfile.MaxMass',
      editable: false,
      active: true,
      filtering: 'string',
      type: CellType.Float,
      width: 180,

      sorting: 2
    },
    {
      title: 'Рейсов, шт',
      dataIndex: 'VehicleTimeline.MaxTrips',
      key: 'VehicleTimeline.MaxTrips',
      editable: false,
      active: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Объем кузова, м3',
      dataIndex: 'VehicleProfile.MaxVolume',
      key: 'VehicleProfile.MaxVolume',
      editable: false,
      active: true,
      filtering: 'string',
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Паллет, шт',
      dataIndex: 'VehicleProfile.MaxPallets',
      key: 'VehicleProfile.MaxPallets',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Коробов, шт',
      dataIndex: 'VehicleProfile.MaxBoxes',
      key: 'VehicleProfile.MaxBoxes',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Стоимость, руб',
      dataIndex: 'VehicleProfile.MaxCost',
      key: 'VehicleProfile.MaxCost',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длина кузова, мм',
      dataIndex: 'VehicleProfile.MaxUnitLength',
      key: 'VehicleProfile.MaxUnitLength',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Ширина кузова, мм',
      dataIndex: 'VehicleProfile.MaxUnitWidth',
      key: 'VehicleProfile.MaxUnitWidth',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Высота кузова, мм',
      dataIndex: 'VehicleProfile.MaxUnitHeight',
      key: 'VehicleProfile.MaxUnitHeight',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Средняя скорость, км/ч',
      dataIndex: 'VehicleProfile.AverageSpeed',
      key: 'VehicleProfile.AverageSpeed',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Расход топлива',
      dataIndex: 'VehicleProfile.FuelConsumption',
      key: 'VehicleProfile.FuelConsumption',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длительность погрузки',
      dataIndex: 'VehicleProfile.LoadDuration',
      key: 'VehicleProfile.LoadDuration',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.TimeDouble
    },
    {
      title: 'Длительность разгрузки',
      dataIndex: 'VehicleProfile.UnloadDuration',
      key: 'VehicleProfile.UnloadDuration',
      editable: false,
      active: false,
      filtering: 'string',
      type: CellType.TimeDouble
    },
    {
      title: 'Специфики',
      dataIndex: 'Restriction',
      key: 'Restriction',
      active: false,
      filtering: 'string',
      editable: true,
      ellipsis: true,
      type: CellType.Input
    },
    {
      title: 'Шаблоны рейсов',
      dataIndex: 'TripTemplate',
      key: 'TripTemplate',
      active: false,
      filtering: 'string',
      editable: true,
      ellipsis: true,
      width: 160,
      type: CellType.Input
    },
    {
      title: 'Режим исп.',
      dataIndex: 'UseMode',
      key: 'UseMode',
      active: false,
      filtering: 'array',
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,
      options: VEHICLE_USE_MODE_OPTIONS
    },
    {
      title: 'Cамоходное',
      dataIndex: 'IsTrailer',
      key: 'IsTrailer',
      active: false,
      required: false,
      editable: true,
      sorting: 1,
      type: CellType.Bool
    },
    {
      title: 'Код передней сцепки',
      dataIndex: 'FrontCouplingType.CouplingCode',
      key: 'FrontCouplingType.CouplingCode',
      active: false,
      sorting: 1,
      required: false,
      editable: false,

      type: CellType.Input,

      allowClear: true
    },
    {
      title: 'Название передней сцепки',
      dataIndex: 'FrontCouplingType.Name',
      key: 'FrontCouplingType.Name',
      active: false,
      sorting: 1,
      required: false,
      editable: false,

      type: CellType.Input,

      allowClear: true
    },
    {
      title: 'Код задней сцепки',
      dataIndex: 'RearCouplingType.CouplingCode',
      key: 'RearCouplingType.CouplingCode',
      active: false,
      required: false,
      editable: false,

      type: CellType.Input,
      sorting: 1,

      allowClear: true
    },
    {
      title: 'Название задней сцепки',
      dataIndex: 'RearCouplingType.Name',
      key: 'RearCouplingType.Name',
      active: false,
      required: false,
      editable: false,

      type: CellType.Input,
      sorting: 1,

      allowClear: true
    },
    {
      title: 'Редактирование автосцепки',
      dataIndex: TableColumnName.RoadTrain,
      key: TableColumnName.RoadTrain,
      active: false,
      required: false,
      editable: true,
      sorting: 1,
      type: CellType.StringMultiple
    }
  ]
};

export const VEHICLE_CATALOG_TABLE_CONFIG: TableConfig = {
  ...VEHICLE_TABLE_CONFIG,
  columns: VEHICLE_TABLE_CONFIG.columns.filter(({ key }) => !VEHICLE_FILTER_COLUMNS.includes(key as TableColumnName))
};

export const VEHICLES_TABLE_SCROLL_WIDTH = 500;
export const VEHICLES_TABLE_SCROLL_HEIGHT = `calc(100vh - 693px)`;
export const VEHICLES_TABLE_SCROLL_SIZE = { x: VEHICLES_TABLE_SCROLL_WIDTH, y: VEHICLES_TABLE_SCROLL_HEIGHT };
