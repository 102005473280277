import { AccountUsersPaneComponent } from "@modules/account/components/users";
import { AccountContainer } from "@modules/account/containers/account";
import { AccountUserContainer } from "@modules/account/containers/user";
import { ProfileTab } from "@modules/account/types/index";

export const ACCOUNT_PANES_COMPONENTS = {
  [ProfileTab.account]: <AccountContainer />,
  [ProfileTab.user]: <AccountUserContainer />,
  [ProfileTab.users]: <AccountUsersPaneComponent />
};
