import { sortBy } from 'lodash';
import { createArrayFromIndexedArray } from '@common/utils';
import { IndexedArray } from '@common/types';
import { TripTotal } from '@common/types/dictionaries/trip';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';
import { OrderActionTotal } from '@common/types/dictionaries/order';
import { SplitChar } from '@common/constants';

export const sortDataSourceByNumberColumn = <Total extends TripTotal | WaypointTotal | OrderActionTotal>(
  records: IndexedArray<Total> | Array<Total>
): Array<Total> => {
  return sortBy(createArrayFromIndexedArray(records), (record) => {
    return Number(record.Number.split(SplitChar.Dot).pop());
  });
};
