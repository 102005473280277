import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { DataSchemeAPI } from '@modules/planning/children/scheme-editor/api';
import { getCurrentSchemeRoutine, getInitialRoutine, uploadSchemeIniRoutine } from '@modules/planning/children/scheme-editor/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { SchemeIni } from '@modules/planning/children/scheme-editor/types/scheme';

function* uploadSchemeIniWorker(action: PayloadAction<SchemeIni>) {
  const { request, success, failure, fulfill } = uploadSchemeIniRoutine;
  const { file } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(DataSchemeAPI.uploadSchemeIni, { file });
    yield put(success({ message: data }));
    yield put(getInitialRoutine.trigger());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield delay(3000);
    yield put(getCurrentSchemeRoutine.trigger());
    yield put(fulfill());
  }
}

export function* uploadSchemeIniWatcher() {
  yield takeLatest(uploadSchemeIniRoutine.trigger, uploadSchemeIniWorker);
}
