import { ILSSpin } from '@common/components';
import { useAccountAttachment } from '@modules/account/utils/hooks/use-account-attachment';
import { FC } from 'react';
import { renderAccountAttachmentComponent } from '@modules/account/utils/helpers/render-attachment-component';
import { AccountAttachmentCategory } from '@modules/account/types/attachments';

interface IProps {
  accountAttachmentCategory: AccountAttachmentCategory;
}

export const AccountAttachmentContainer: FC<IProps> = ({ accountAttachmentCategory }) => {
  const { handleCheckBeforeUpload, handleDeleteDocument, documents, isFetching } = useAccountAttachment({ accountAttachmentCategory });

  return (
    <ILSSpin className="extra-attachments" tip={'Загрузка документов...'} spinning={isFetching}>
      {renderAccountAttachmentComponent({ accountAttachmentCategory, documents, handleCheckBeforeUpload, handleDeleteDocument })}
    </ILSSpin>
  );
};

