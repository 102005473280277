import { Notify, Plan, PlanID } from '@common/types';
import { compareAsString } from '@common/utils';
import { ILSUserNotify } from '@common/components';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { handlePlanConfirmForExecution } from '@modules/planning/children/by-date/helpers/plan/handle-plan-confirm-for-execution';
import { PlanNamesEnum } from '@modules/planning/children/by-date/selectors/project';

export const handlePlanForExecution =
  ({
    activePlanID,
    planOnExecution,
    planNames,
    checkDriversBeforeHandlePlanOnExecution,
    hasTender
  }: {
    hasTender: boolean;
    activePlanID: PlanID | null;
    planOnExecution: Plan | undefined;
    planNames: PlanNamesEnum;
    checkDriversBeforeHandlePlanOnExecution({ planID, unsetPlanID }: { planID: PlanID; unsetPlanID?: PlanID }): void;
  }) =>
  (planID: Plan['ID']) => {
    if (hasTender && compareAsString(activePlanID, planID)) {
      ILSUserNotify(
        Notify.Error,
        'План с рейсами, выставленными на торги на Тендерной площадке нельзя принять к самостоятельному исполнению'
      );
      return;
    }

    if (planOnExecution) {
      const unsetPlanID = planOnExecution?.ID;
      if (compareAsString(unsetPlanID, planID)) return;
      const text = `Вы точно хотите переместить исполнение с плана "${planOnExecution.PlanTotal?.FullName}" на план "${planNames[planID]}"?`;
      ILSUserNotifyConfirm(
        'Внимание',
        text,
        handlePlanConfirmForExecution({
          planID,
          unsetPlanID,
          checkDriversBeforeHandlePlanOnExecution
        })
      );
    } else {
      checkDriversBeforeHandlePlanOnExecution({
        planID
      });
    }
  };
