import { DateTime, Double, GeohashType, ILSAPILatLonLiteral, TimeStamp } from '../general';
import { Depot } from './depot';
import { OrderAction, OrderActionTotal } from './order';
import { TrailerAction } from './trailer';
import { ShortWorktime } from './worktime';
import { IEnum, IndexedArray, PlanStatus, Restriction, Trip, Vehicle } from '@common/types';
import { PlanID } from '@common/types/dictionaries/plan';

export type WaypointTotal = {
  PlanID: PlanID;
  WaypointID: Waypoint['ID'];
  PrevWaypointID: Waypoint['ID'] | null;
  VehicleID: Vehicle['ID'];
  DepotID: Depot['ID'];
  DepotTypeID: Depot['DepotTypeID'];
  DepotWorktime: ShortWorktime | null;
  TripID: Trip['ID'];
  RouteColor: Vehicle['RouteColor'];
  LoadedCargo: number;
  UnloadedCargo: number;
  LatLon: ILSAPILatLonLiteral;
  GeoHash: GeohashType;
  Comment: string;
  Code: string;
  Mass: number;
  Number: string;
  Pallets: number;
  Boxes: number;
  Volume: number;
  Cost: number;
  UnitWidth: number;
  UnitLength: number;
  UnitHeight: number;
  RestrictionEnum: IEnum<Restriction['Name']>;
  FullName: string;
  CargoCount: number;
  ClientCount: number;
  StartTime: TimeStamp;
  EndTime: TimeStamp;
  UTCOffset?: Depot['UTCOffset'];
  DeliveryDate: TimeStamp | null;
  Duration: Double;
  RoadDuration: Double;
  MoveDuration: Double;
  RestDuration: Double;
  Distance: number;
  AverageSpeed: number;
  LoadDuration: Double;
  UnloadDuration: Double;
  WaitingDuration: Double;
  LatenessDuration: Double;
  LoadDepot: string | null;
  UnloadDepot: string | null;
  TripTemplate: string;
  Dimensions: string;
  Worktime: [Double | null, Double | null];
  RampID: string;
  RampName: string;
  RampStartTime: Double;
  RampEndTime: Double;
  Info: Depot['AddressDescription'];
  // Status: PlanStatus;
  PlanStatus: PlanStatus; //TODO for what?
  OrderActionTotal: IndexedArray<OrderActionTotal> | Array<never>;
};

export type WaypointModel = {
  ID: number;
  WaypointCode: string | null;
  Ind: number;
  ArrivalTime: Double;
  DepartureTime: Double;
  DistanceFromPrevPoint: number;
  WaitingDuration: number;
  LatenessDuration: number;
  GeoHash: string;
  TripID?: number;
  DepotID: number;
  OriginalID: number;
  WorktimeStart: number;
  WorktimeEnd: number;
  StartDate: DateTime | null;
  EndDate: DateTime | null;
  WorktimeStartExcess: number;
  WorktimeEndExcess: number;
  FixedTrack: FixedTrack;
  Comment: string | null;
  DurationOfRest: number;
  DurationOfMove: number;
  StatusID: number | null;
  FactArrive?: number | null;
  FactDepart?: number | null;
  //TODO выяснить существуют ли подобные ключи и откуда они взялись в декораторе src/modules/planning/children/by-date/utils/decorators/table/waypoint.ts
  DurationOfLate?: number | null;
  DurationOfWait?: number | null;
  Depot: Depot;
};

export type Waypoint = WaypointModel & {
  RampWaypoint: [];
  OrderAction: Array<OrderAction> | null;
  TrailerAction: Array<TrailerAction> | null;
};

export enum FixedTrack {
  No,
  Yes
}
