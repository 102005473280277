import { Dictionary } from '@common/types';
import { OrderActionRecord, RoutesTableRecord } from '@modules/planning/children/by-date/types/record';

export const getContextMenuForOrderAction = ({
  tableEditRow,
  handleDelete,
  record
}: {
  record: OrderActionRecord;
  tableEditRow(table: Dictionary, id: number): void;
  handleDelete(record: RoutesTableRecord): void;
}) => {
  const onEditOrder = () => {
    tableEditRow(Dictionary.Order, record.OrderID);
  };

  return [
    [{ text: 'Редактировать заявку', handler: onEditOrder }],
    [
      {
        text: 'Удалить заявку',
        handler: handleDelete
      }
    ]
  ];
};
