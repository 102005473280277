import { ZoneType } from '@common/types';

export const NEW_ZONE = {
  Name: 'Новая_зона',
  Type: ZoneType.Delivery
};
export const NEW_SURVEILLANCE_ZONE = {
  Name: 'Новая_зона_наблюдения',
  Type: ZoneType.Surveillance
};

export const ZONE_TYPE_OPTIONS = [
  { value: ZoneType.Delivery, label: 'Зона доставки' },
  { value: ZoneType.Surveillance, label: 'Зона наблюдения' }
];
