import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-search/store';
import { tenderNotifySettingsRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { TenderSearchStore } from '../types';
import { NotificationsInfo } from '../types/notifications';

export const notificationSettingsReducer = createReducer(initialState, {
  [tenderNotifySettingsRoutine.REQUEST]: onRequest,
  [tenderNotifySettingsRoutine.SUCCESS]: (
    state: TenderSearchStore,
    action: PayloadAction<{
      data: NotificationsInfo;
    }>
  ) => {
    const { data } = action.payload;
    state.notificationSettings = data;
    return state;
  },
  [tenderNotifySettingsRoutine.FULFILL]: onFulfill
});
