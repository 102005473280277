import { call, put, takeLatest } from 'redux-saga/effects';
import { TariffAPI } from '@modules/catalog/api';
import { Dictionary } from '@common/types';
import { catalogReadRoutine, getTariffRoutine } from '@modules/catalog/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* worker(action: PayloadAction<{ dictionary: Dictionary }>) {
  const { success, failure, fulfill, request } = getTariffRoutine;
  const { dictionary } = action.payload;
  try {
    yield put(request({ dictionary }));

    const {
      data: { data }
    } = yield call(TariffAPI.operation, { dictionary });

    yield put(success({ data, dictionary }));
    yield put(catalogReadRoutine({ dictionary: Dictionary.VehicleTariff }));
    yield put(catalogReadRoutine({ dictionary: Dictionary.VehicleTariffPart }));
  } catch (error) {
    yield put(failure({ dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* getTariffWatcher() {
  yield takeLatest(getTariffRoutine.trigger, worker);
}

