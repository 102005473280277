import { MarkerPlanning } from '@common/types';
import { MapClickData } from '@common/types/components/map/map';
import { orderTip } from '@core/components/map/icon-tips';
import { ILSMap } from '@core/containers/map';
import { useSideAction } from '@core/utils/map/side-action';
import { MapLayers, MarkerItem } from '@modules/planning/children/by-date/types';
import { addMarkerH } from '@modules/planning/children/by-date/utils/add-marker-helper';
import { createMarkerOrderKey } from '@modules/planning/children/by-date/utils/create-layer-key';
import { deepEqual } from 'fast-equals';
import L from 'leaflet';
import { isEmpty, omit } from 'lodash';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

export type PlanningMarker = Array<
  | MarkerPlanning & {
      selected?: boolean | undefined;
    }
>;

export const useMapMarkers = (
  Map: MutableRefObject<ILSMap | null>,
  mapLayersGroup: MapLayers,
  markers: PlanningMarker,
  mapMarkers: Record<string, MarkerItem>,
  activePlanID: number | null,
  setMapMarkers: Dispatch<
    SetStateAction<
      Record<
        string,
        {
          mark: MarkerPlanning;
          lMark: L.Marker;
          layer: L.MarkerClusterGroup | undefined;
        }
      >
    >
  >,
  handleItemClick: ((record?: MapClickData) => void) | undefined
) => {
  const [markerRenderPlanID, setMarkerRenderPlanID] = useState(activePlanID);

  useEffect(() => {
    if (Map.current && mapLayersGroup.isInit) {
      // новый массив с текущими маркерами, которые на карте
      const mapMarkers_n: Record<
        string,
        {
          lMark: L.Marker;
          mark: MarkerPlanning;
          layer: L.MarkerClusterGroup | undefined;
        }
      > = {};
      let updateM = false;

      //Для добавления сразу всех элементов на карту
      const { addToSide, runSideAction } = useSideAction(Map);
      const { addToSide: addToSideDelete, runSideAction: runSideDelete } = useSideAction(Map, true);
      const addMarker = (
        marker: MarkerPlanning & {
          selected?: boolean | undefined;
        }
      ) => addMarkerH(marker, mapLayersGroup, handleItemClick, Map.current!, addToSide);

      const addAllMarkers = () => {
        markers.forEach((marker) => {
          if (!isEmpty(marker.coords)) {
            const addRes = addMarker(marker);
            if (!updateM) updateM = true;
            const markerKey = createMarkerOrderKey(marker);
            if (markerKey) {
              mapMarkers_n[markerKey] = {
                lMark: addRes.lMark,
                mark: marker,
                layer: addRes.layer
              };
            }
          }
        });
      };
      if (activePlanID !== markerRenderPlanID) {
        mapLayersGroup.orderCluster?.clearLayers();
        if (!updateM) updateM = true;
        addAllMarkers();
        setMarkerRenderPlanID(activePlanID);
      } else if (!markers.length && mapMarkers.length) {
        mapLayersGroup.orderCluster?.clearLayers();
        if (!updateM) updateM = true;
      } else if (Object.keys(mapMarkers).length === 0) {
        addAllMarkers();
      } else {
        //Индексы маркеров, которые останутся на карте
        const mapMarkerCheckedKeys: string[] = [];
        //Список маркеров, для добавления на карту
        const marker2AddToMap: (MarkerPlanning & {
          selected?: boolean | undefined;
        })[] = [];

        markers.forEach((marker) => {
          if (marker) {
            const markerKey = createMarkerOrderKey(marker);
            if (markerKey) {
              const mapMark = mapMarkers?.[markerKey];
              if (!mapMark || !deepEqual(marker, mapMark.mark)) {
                if (!mapMark || !deepEqual(omit(marker, ...MarkerPropsWithoutRender), omit(mapMark?.mark, ...MarkerPropsWithoutRender))) {
                  marker2AddToMap.push(marker);
                } else {
                  if (marker.pointData) {
                    mapMark.lMark.bindPopup(orderTip(marker.pointData));
                  }
                  mapMarkerCheckedKeys.push(markerKey);
                  mapMark.mark = marker;
                }
              } else {
                mapMarkerCheckedKeys.push(markerKey);
              }
            }
          }
        });
        Object.keys(mapMarkers).forEach((markerKey) => {
          const mItem = mapMarkers[markerKey];
          if (!mapMarkerCheckedKeys.includes(markerKey)) {
            if (!updateM) updateM = true;
            addToSideDelete(mItem.lMark, mItem.layer);
          } else {
            mapMarkers_n[markerKey] = mItem;
          }
        });
        marker2AddToMap.forEach((marker) => {
          const markerKey = createMarkerOrderKey(marker);
          if (!isEmpty(marker.coords) && markerKey) {
            const addRes = addMarker(marker);
            if (!updateM) updateM = true;
            mapMarkers_n[markerKey] = {
              lMark: addRes.lMark,
              mark: marker,
              layer: addRes.layer
            };
          }
        });
      }
      if (updateM) {
        runSideDelete();
        runSideAction();
        setMapMarkers(mapMarkers_n);
      }
    }
  }, [markers, mapLayersGroup.isInit]);
};
const MarkerPropsWithoutRender: Array<keyof MarkerPlanning> = ['pointData'];

export default { useMapMarkers };

