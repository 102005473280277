import { useEffect, useRef } from 'react';
import CustomScroll from 'react-customscroll';

type CustomScrollTypeOffReadonly = { -readonly [key in keyof CustomScroll]: CustomScroll[key] };

interface IProps {
  configContainer: React.RefObject<HTMLDivElement>;
  customScrollRef: React.RefObject<
    CustomScrollTypeOffReadonly & {
      customScrollHolderRef: React.RefObject<any>;
      scrollBlock: { scrollTop: number };
    }
  >;
}
/*
 * Позволяет скроллить список колонок в окне конфигурации таблицы,
 * при перетаскивании их с помощью drag'n drop
 */
export const useTableConfigDragNDropScroll = ({ configContainer, customScrollRef }: IProps) => {
  let { current: mouseOn } = useRef(false);
  let { current: scrollContainerCoords } = useRef<{ topY: number; bottomY: number } | null>(null);
  useEffect(() => {
    let interval: NodeJS.Timer;
    function setScrollData(e: MouseEvent) {
      scrollContainerCoords = null;
      const target = e.target as SVGElement;
      if (target?.dataset.src?.includes('move') || target?.ownerSVGElement?.dataset?.src?.includes('move')) {
        mouseOn = true;
        const { top, bottom } = customScrollRef.current?.customScrollHolderRef.current.getBoundingClientRect();
        scrollContainerCoords = { topY: top, bottomY: bottom };
      }
    }

    function resetScrollData(e: globalThis.MouseEvent) {
      mouseOn = false;
      scrollContainerCoords = null;
      clearInterval(interval);
    }

    function mouseMove(e: globalThis.MouseEvent) {
      clearInterval(interval);
      if (mouseOn && scrollContainerCoords) {
        const { y } = e;
        const scrollDown = y > scrollContainerCoords.bottomY;
        const scrollUp = y < scrollContainerCoords.topY;
        interval = setInterval(() => {
          if (customScrollRef.current) {
            if (scrollDown) {
              customScrollRef.current.scrollBlock.scrollTop += 50;
            } else if (scrollUp && customScrollRef.current.scrollBlock.scrollTop > 0) {
              customScrollRef.current.scrollBlock.scrollTop -= 50;
            }
          }
        }, 200);
      }
    }
    configContainer.current?.addEventListener('mousedown', setScrollData);
    window.addEventListener('mouseup', resetScrollData);
    configContainer.current?.addEventListener('mousemove', mouseMove);
    return () => {
      configContainer.current?.removeEventListener('mousedown', setScrollData);
      configContainer.current?.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', resetScrollData);
    };
  }, []);
};
