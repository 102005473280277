import { ILSInputNumber } from '@components/index';
import { KeyboardEvent } from 'react';
import { IILSCellUintProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { getCellInputValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-value';
import { EMPTY_STRING } from '@common/constants';

export const ILSCellUfloat = <R extends TableRecord>({ children, save, style, min = 0, max, step }: IILSCellUintProps<R>): JSX.Element => {
  const [node, value = EMPTY_STRING] = children;

  const handleOnKeyDown = ({ key, preventDefault }: KeyboardEvent<HTMLInputElement>) => {
    if (['-'].includes(key)) {
      preventDefault();
    }
  };

  const handleSave = (e: any) => save(e.target.value);

  return (
    <>
      {node}
      <ILSInputNumber
        defaultValue={getCellInputValue(value)}
        min={min}
        max={max}
        bordered
        type="number"
        onPressEnter={handleSave}
        onBlur={handleSave}
        autoFocus
        step={step}
        style={style}
        onKeyDown={handleOnKeyDown}
        className={'cell-wrapper-editable-full'}
      />
    </>
  );
};
