import { isBoolean } from 'lodash';
import { EMPTY_STRING } from '@common/constants';
import { parseDateFromServerToDateString } from '@common/utils/helpers/date-time/parsers';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { MomentInput } from 'moment';
import { DateFormat, DateTimeFormat, TimeFormat, TimeStamp } from '@common/types';

export const getDateStringCellValue = ({
  date,
  showTime,
  dateParseFormat,
  interfaceFormat
}: {
  date?: MomentInput | TimeStamp | null | boolean;
  showTime?: boolean;
  dateParseFormat?: DateFormat | DateTimeFormat | TimeFormat;
  interfaceFormat?: DateFormat | DateTimeFormat | TimeFormat;
}) => {
  if (isBoolean(date)) {
    showUserAndDevError({ error: 'ILSCellDateString - MomentInput | TimeStamp | null | undefined' });
  }
  return isBoolean(date) || date === EMPTY_STRING
    ? undefined
    : parseDateFromServerToDateString({ date, withTime: showTime, parseFormat: dateParseFormat, interfaceFormat });
};
