import { EMPTY_STRING } from '@common/constants';
import { compareStrings, createArrayFromIndexedArray } from '@common/utils';
import { DataType } from '@modules/catalog/types/catalog';

/** Функция для сортировки данных каталога, индентична функции сортировки options sortOptionsByFieldNames
 * применяется для отображения значений в таблице и вариантах select в идентично отсортированном порядке.
 */

export const sortCatalogData = (data: DataType) => {
  return createArrayFromIndexedArray(data)
    .slice()
    .sort((first, second) => {
      if (first['Name'] === second['Name']) return 0;
      if (first['Name'] === EMPTY_STRING) return 1;
      if (second['Name'] === EMPTY_STRING) return -1;
      return compareStrings(
        first['Name']?.toString().toUpperCase() || EMPTY_STRING,
        second['Name']?.toString().toUpperCase() || EMPTY_STRING
      );
    });
};

