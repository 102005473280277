import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import roadGraphReducer from '@modules/road-graph/common/reducers';
import * as roadGraphRootSagas from '@modules/road-graph/common/sagas';
import RoadGraphEditor from '@modules/road-graph/editor';
import RoadGraphTest from '@modules/road-graph/route-test';
import { ILSRoadGraph } from './road-graph-container';

export default defineModule({
  title: ModuleTitle.RoadGraph,
  path: ModulePath.RoadGraph,
  component: ILSRoadGraph,
  children: {
    RoadGraphTest,
    RoadGraphEditor
  } as any,
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/road-graph.svg',
  reducer: roadGraphReducer,
  sagas: 'object' === typeof roadGraphRootSagas ? Object.values(roadGraphRootSagas).map((s) => s()) : []
});
