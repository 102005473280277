import { ACCOUNT_FACSIMILE_PERMITTED_FILE } from '@modules/account/constants/account';
import { RcFile } from 'antd/lib/upload';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkFileBeforeUpload } from '@modules/account/utils/helpers/file-before-upload';
import { accountAttachmentCreateRoutine, accountAttachmentDeleteRoutine, accountAttachmentIndexRoutine } from '@modules/account/actions';
import { AccountAttachmentCategory, AccountAttachmentsDependent } from '@modules/account/types/attachments';
import { AccountAttachmentSubject } from '@common/types';
import { accountExtraAttachmentDocumentsSelector, accountIDSelector } from '@modules/account/selectors/account';
import { ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { AccountStore } from '@modules/account/types';

export const useAccountAttachment = ({ accountAttachmentCategory }: { accountAttachmentCategory: AccountAttachmentCategory }) => {
  const dispatch = useDispatch();
  const { documents, isFetching } = useSelector((state: AccountStore) =>
    accountExtraAttachmentDocumentsSelector(state, accountAttachmentCategory)
  );
  const accountID = useSelector(accountIDSelector);

  useEffect(() => {
    dispatch(
      accountAttachmentIndexRoutine({ accountAttachmentCategory, dependent: AccountAttachmentsDependent.Account, dependentID: accountID })
    );
  }, []);

  const handleUploadDocuments = (file: RcFile | Array<RcFile>, subject: AccountAttachmentSubject) => {
    dispatch(
      accountAttachmentCreateRoutine({
        dependentID: accountID,
        subject,
        dependent: AccountAttachmentsDependent.Account,
        attachment: file,
        accountAttachmentCategory
      })
    );
  };

  const handleCheckBeforeUpload = (file: RcFile, subject: AccountAttachmentSubject) => {
    checkFileBeforeUpload({ file, permittedFileExtensions: ACCOUNT_FACSIMILE_PERMITTED_FILE, handleUploadDocuments, subject });
  };

  const handleDeleteDocument = (documentID: number) => {
    ILSUserNotifyConfirm('Внимание', 'Удалить вложение?', () => {
      dispatch(
        accountAttachmentDeleteRoutine({
          id: documentID,
          accountAttachmentCategory,
          dependent: AccountAttachmentsDependent.Account,
          dependentID: accountID
        })
      );
    });
  };

  return {
    documents,
    isFetching,
    handleCheckBeforeUpload,
    handleDeleteDocument
  };
};

