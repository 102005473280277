import cn from 'classnames';
import { FC } from 'react';
import { isEmpty } from 'lodash';
import { PaperClipOutlined } from '@ant-design/icons/lib/icons';
import { RcFile } from 'antd/lib/upload/interface';
import {
  ORDERS_TRIPS_DOCUMENTS_HEADER_TITLE,
  ORDERS_TRIPS_PERMITTED_EXTENSIONS_TITLE,
  ORDERS_TRIPS_UPLOAD_TITLE
} from '@modules/orders-and-trips/constants/documents';
import { TripDocument } from '@modules/orders-and-trips/types/trip';
import { ILSDragger } from '@common/components';
import { DocumentsUploadCard } from '@modules/orders-and-trips/components/documents/documents-upload-card';

interface IProps {
  documents: Array<TripDocument>;
  uploadIsDisabled: boolean;
  isFetching: boolean;
  permittedExtensionsTitle: string;
  handleCheckBeforeUpload: (file: RcFile) => void;
  handleDeleteDocument: (documentID: number) => void;
}

export const DocumentsUpload: FC<IProps> = ({ documents, uploadIsDisabled, permittedExtensionsTitle, handleCheckBeforeUpload, handleDeleteDocument }) => {
  return (
    <div className="documents-upload">
      <h2>{ORDERS_TRIPS_DOCUMENTS_HEADER_TITLE}</h2>
      <ILSDragger name="file" showUploadList={false} disabled={uploadIsDisabled} beforeUpload={handleCheckBeforeUpload}>
        <div className={cn('documents-upload-dropzone', { 'documents-upload-dropzone_disabled': uploadIsDisabled })}>
          <div className="documents-upload-dropzone__heading">
            <PaperClipOutlined />
            <span>{ORDERS_TRIPS_UPLOAD_TITLE}</span>
          </div>
          <span>
            {ORDERS_TRIPS_PERMITTED_EXTENSIONS_TITLE}
            <span>{permittedExtensionsTitle}</span>
          </span>
        </div>
      </ILSDragger>
      {!isEmpty(documents) && (
        <div className="documents-upload-list">
          {documents.map((document) => (
            <DocumentsUploadCard document={document} key={`${document.id}`} disabled={uploadIsDisabled} onDelete={handleDeleteDocument} />
          ))}
        </div>
      )}
    </div>
  );
};