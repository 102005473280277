import { FC } from 'react';
import { RAMP_TYPE_TABLE_CONFIG, rampType2table } from '@common/models/ramp-type';
import { useAppSelector } from '@core/hooks';
import { projectCommonDictionarySelector } from '@modules/planning/children/by-date/selectors/project';
import { CatalogDictionary, Dictionary, ReferenceTablesData } from '@common/types';
import {
  IILSTablePlanningComponentProps,
  PlannerPlanningTablesContainer
} from '@modules/planning/children/by-date/containers/result/tables/table';
import { tableCustom } from '@core/containers/table-custom';
import { table2data } from '@components/data-display/table/utils/decorators';

const PlanningRampType: FC<IILSTablePlanningComponentProps> = (props) => {
  const { Restriction } = useAppSelector((state) => projectCommonDictionarySelector(state, [Dictionary.Restriction]));

  const refData: ReferenceTablesData = {
    RestrictionID: Restriction
  };

  return (
    <PlannerPlanningTablesContainer
      {...props}
      refData={refData}
      dictionary={Dictionary.RampType}
      pagination={false}
      dataDecorator={rampType2table}
      handleDecorator={table2data}
      className="result-table"
    />
  );
};

export const ILSRampTypePlanningTable = tableCustom(PlanningRampType, RAMP_TYPE_TABLE_CONFIG, CatalogDictionary.RampType);
