import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI, Keys, ShowDeleted } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogDeleteLocalRoutine } from '@modules/catalog/actions';
import { ForceDeleteType, ICatalogDataItem, SaveDictionaryType, ValuesType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';

const deleteLocalDictionaryReducer = createReducer(initialState, {
  [catalogDeleteLocalRoutine.REQUEST]: onRequest,
  [catalogDeleteLocalRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      ids: Keys;
      values: ValuesType;
      forceDelete: ForceDeleteType;
      saveDictionary: SaveDictionaryType;
      showDeleted: ShowDeleted;
    }>
  ) => {
    let { dictionary, ids, saveDictionary, forceDelete, showDeleted } = action.payload;

    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;
    let oldData = state[dictionary].data;
    if (ids.length > 0) {
      if (saveDictionary) {
        //для удаления записи из родительского справочника
        oldData = oldData.map((item: ICatalogDataItem) => {
          if (item.ID === saveDictionary.recordID) {
            if (showDeleted) {
              item[saveDictionary.childDict] = item[saveDictionary.childDict].map((childItem) => {
                if (ids.includes(childItem.ID)) {
                  childItem['Deleted'] = Math.floor(Date.now() / 1000);
                }
                return childItem;
              });
            } else {
              item[saveDictionary.childDict] = item[saveDictionary.childDict].filter((childItem) => !ids.includes(childItem.ID));
            }
          }
          return item;
        });
      } else {
        if (showDeleted) {
          oldData = oldData.map((item: ICatalogDataItem) => {
            if (ids.includes(item.ID)) {
              item['Deleted'] = Math.floor(Date.now() / 1000);
            }
            return item;
          });
        } else {
          oldData = oldData.filter((item: ICatalogDataItem) => !ids.includes(item.ID) && item);
        }
      }
    }

    state[dictionary].data = oldData;
    if (forceDelete) state[dictionary].refresh = true;

    return state;
  },
  [catalogDeleteLocalRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogDeleteLocalRoutine.FULFILL]: onFulfill
});

export default deleteLocalDictionaryReducer;
