import { Anchor, AnchorLinkProps, AnchorProps } from 'antd';
import { FC } from 'react';

const { Link } = Anchor;

export const ILSAnchor: FC<AnchorProps> = (props) => {
  return <Anchor {...props} />;
};

export const ILSLink: FC<AnchorLinkProps> = (props) => {
  return <Link {...props} />;
};
