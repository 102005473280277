import { Dictionary, Keys } from '@common/types';
import { DictionaryAPI } from '@common/api/common';
import { AxiosResponse } from 'axios';
import { isString } from 'lodash';
import { EMPTY } from '@common/constants';
import { getIdsWithoutGroupIds } from '@modules/catalog/helpers';

const usedDictionaries = [Dictionary.Driver, Dictionary.Depot, Dictionary.Vehicle];

/** Функция проверяет, где используются записи */
export const handleCheckDeleteRows =
  ({ dataSourceForTable, dictionary }: { dictionary: Dictionary; dataSourceForTable: any }) =>
  (keys: Keys): Promise<AxiosResponse | undefined> => {
    const hasGrouping = keys?.some((id) => isString(id) && id !== EMPTY);
    const idsWithoutGroupIds = getIdsWithoutGroupIds(keys, dataSourceForTable);
    const ids = hasGrouping ? idsWithoutGroupIds : keys;
    return dictionary && ids && usedDictionaries.includes(dictionary)
      ? (DictionaryAPI.isInUse({
          dictionary,
          ids
        }) as Promise<AxiosResponse>)
      : new Promise((res) => {
          res(undefined);
        });
  };
