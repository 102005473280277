import { Keys } from '@common/types';
import { OrderCardCargoDetails } from '@modules/orders-and-trips/components/orders/order-card-cargo-details';
import { OrderCardDepots } from '@modules/orders-and-trips/components/orders/order-card-depots';
import { OrderCardInfo } from '@modules/orders-and-trips/components/orders/order-card-info';
import { ActiveRecord } from '@modules/orders-and-trips/types/general';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { FC, memo } from 'react';

interface IProps {
  order: DecoratedOrders[number];
  activeOrderRecord: ActiveRecord | null;
  handleActiveOrder(activeRecordID: number): void;
  onSelectOrders(data: { ID: number; checked: boolean }): void;
  selectedOrders: Keys;
}

export const OrderCardContainer: FC<IProps> = memo(({ order, activeOrderRecord, handleActiveOrder, onSelectOrders, selectedOrders }) => {
  const isSelected = selectedOrders.includes(order.ID);
  return (
    <div
      onClick={() => handleActiveOrder(order?.ID)}
      className={classNames('order-card', { active: activeOrderRecord?.ID === order.ID, select: isSelected })}
    >
      <OrderCardInfo onSelectOrders={onSelectOrders} order={order} isSelected={isSelected} />
      <OrderCardDepots order={order} />
      <OrderCardCargoDetails order={order} />
    </div>
  );
});

