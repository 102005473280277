import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify, NotifyDurationInSecond } from '@common/types';
import { PlanningAction, planningByDateRoutinesToNotify } from '@modules/planning/children/by-date/actions';
import { defaultStatuses } from '@modules/planning/children/by-date/constants';
import { EMPTY_STRING } from '@common/constants';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: PlanningAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || defaultStatuses[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): PlanningAction => {
  return actionName as PlanningAction;
};

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, PlanningAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'planning_by_date',
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

const errorWorker = notifierWorkerCreator<ErrorAPI, PlanningAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'planning_by_date',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* planningByDateNotifierWatcher() {
  for (const routine of planningByDateRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
