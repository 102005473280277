import { PermittedUploadFileType } from '@common/constants/general/path';
import { downloadFileAsBlobLink } from '@common/utils/file/download-file-as-blob-link';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const downloadTripDocument = async (documentLink: string) => {
  try {
    const response = await fetch(documentLink);
    const { data } = await response.json();
    downloadFileAsBlobLink({
      data: data?.data,
      mimeType: `application/${data?.ext}` as PermittedUploadFileType,
      fileName: data?.name
    });

    return data;
  } catch (error) {
    showUserAndDevError({ error });
  }
};

