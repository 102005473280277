import { ITableSearchConfig } from '@modules/tender-platform/children/tender-search/types/filter';
import { vehicleTypeOptions } from '@modules/tender-platform/constants/filter';
import { UnitOfMeasurement } from '@common/constants/general/unit-of-measurement';
import { TENDER_TYPE_OPTIONS } from '@modules/tender-platform/constants';

export const filterTableConfig: Array<ITableSearchConfig> = [
  {
    type: 'autocomplete',
    placeholder: 'Любое',
    dash: true,
    tooltip: 'Откуда (направление)',
    filter: {
      key: 1,
      method: 'includes',
      param: 'TenderSummary',

      secondParamLevel: 'RegionalCitySender',
      value: ''
    }
  },
  {
    type: 'autocomplete',
    placeholder: 'Любое',
    dash: false,
    tooltip: 'Куда (направление)',
    filter: {
      key: 2,
      method: 'includes',
      param: 'TenderSummary',
      secondParamLevel: 'RegionalCityRecipient',
      value: ''
    }
  },
  {
    type: 'select',
    multiple: true,
    placeholder: 'Любой',
    dash: false,
    tooltip: 'Тип тендера',
    filter: {
      key: 3,
      method: 'filterMultiple',
      param: 'Type',
      value: []
    },
    options: TENDER_TYPE_OPTIONS
  },
  {
    type: 'select',
    multiple: true,
    placeholder: 'Любой',
    dash: false,
    tooltip: 'Тип ТС',
    filter: {
      key: 4,
      method: 'filterMultiple',
      param: 'TenderSummary',
      secondParamLevel: 'VehicleType',
      value: []
    },
    options: vehicleTypeOptions
  },
  {
    type: 'number',
    placeholder: 'от',
    dash: true,
    tooltip: `Масса, ${UnitOfMeasurement.Kg}`,
    filter: {
      key: 5,
      method: 'rangeMin',
      param: 'TenderSummary',
      secondParamLevel: 'Mass',
      value: 0
    },
    style: {
      width: 64,
      marginLeft: 8
    },
    tooltipStyle: {
      marginLeft: '14px'
    }
  },
  {
    type: 'number',
    placeholder: 'до',
    dash: false,
    filter: {
      key: 6,
      method: 'rangeMax',
      param: 'TenderSummary',
      secondParamLevel: 'Mass',
      value: 100000
    },
    style: {
      width: '84px'
    }
  },
  {
    type: 'input',
    placeholder: 'Все',
    dash: false,
    tooltip: 'Заказчик',
    filter: {
      key: 7,
      method: 'includes',
      param: 'Account',
      secondParamLevel: 'Name',
      value: ''
    },
    style: {
      width: 138
    }
  },
  {
    type: 'date',
    placeholder: 'Любое',
    dash: true,
    tooltip: 'Интервал сбора предложений',
    filter: {
      key: 8,
      method: 'dateMin',
      param: 'OfferStartDT',
      value: ''
    },
    style: {
      width: 78,
      marginLeft: '6vw',
      padding: 4
    },
    tooltipStyle: {
      width: 240,
      marginLeft: '6vw'
    }
  },
  {
    type: 'date',
    placeholder: 'Любое',
    dash: false,
    filter: {
      key: 9,
      method: 'dateMax',
      param: 'OfferEndDT',
      value: ''
    },
    style: {
      width: 78,
      padding: 4
    }
  },
  {
    type: 'date',
    placeholder: 'Любое',
    dash: true,
    tooltip: 'Интервал исполнения контракта',
    filter: {
      key: 10,
      method: 'dateMin',
      param: 'ExecutionStartDT',
      value: ''
    },
    style: {
      width: 78,
      padding: 4
    },
    tooltipStyle: {
      width: 240,
      marginLeft: 0
    }
  },
  {
    type: 'date',
    placeholder: 'Любое',
    dash: false,
    filter: {
      key: 11,
      method: 'dateMax',
      param: 'ExecutionEndDT',
      value: ''
    },
    style: {
      width: 78,
      padding: 4
    }
  }
];
