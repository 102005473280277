import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { saveReportRoutine } from '../../actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';

export const saveReportReducer = createReducer(initialState, {
  [saveReportRoutine.REQUEST]: onRequest,
  [saveReportRoutine.SUCCESS]: (state) => state,
  [saveReportRoutine.FAILURE]: onFailure,
  [saveReportRoutine.FULFILL]: onFulfill
});
