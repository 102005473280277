export const data2table = (data: any) => {
  if ('object' === typeof data) {
    return data.map((item: any) => {
      return {
        ...item,
        key: item.id
      };
    });
  } else {
    return [];
  }
};

export const selectOptions = (items: any) => {
  const options = [];
  const keys = items.Value_EnumKeys.split('|');
  const labels = items.Value_Enum.split('|');

  for (let i = 1; i < keys.length; i++) {
    options.push({ label: labels[i] ?? keys[i], value: keys[i] });
  }

  return options;
};
