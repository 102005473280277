import { ILSButton, ILSUserNotify } from '@common/components';
import { ILSUserNotifyConfirmCustom } from '@common/components/feedback/ils-user-notify/custom-user-notify';
import { defaultGeohashEncode } from '@common/decorators/latlon-geohash';
import { DepotRecordKeys, Notify, NotifyDurationInSecond } from '@common/types';
import { GetAddressResponse, GetCoordinatesResponse } from '@common/types/general/api/address';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';

export type respDataFromGeocode = {
  coords: [number, number] | undefined;
  address: string | undefined;
};

type CheckCoordsModal = (
  address: string | undefined,
  coords: string | undefined,
  respDataByCoords: GetAddressResponse | undefined,
  respDataByAddress: GetCoordinatesResponse | undefined,
  options: {
    showModal?: boolean;
    updatePriority?: CoordinatesUpdatePriority;
  }
) => Promise<{ [K: string]: any }>;
const NOT_FILL = 'Не указано';
const MESSAGE_COORD_IS_CORRECT = 'Координаты совпадают с адресом';
const MESSAGE_NOTIFY_KEY = 'COORD_CHECK_SUCCESSED';
const NOTIFY_MS_TO_AUTO_CLOSE = 300000; //Время автоматического закрытия окна
const USER_FREQUENCY_MS = 100; //Частота проверок действий пользователя
const PRECISION_FOR_COORDINATES = 5; //Точность для сравнения координат. Кол-во знаков после запятой

/**
 * Вызов модального окна для сравнения координат и данных
 * Возвращает промис, который выполнится, когда пользователь выберет один из вариантов сохранения, если будут различия данных,
 * либо когда истечет срок для показа окна
 * В данных ответа содержатся свойства, которые будет необходимо перезаписать в исходном объекте
 * @param address текущий адрес
 * @param coordinates текущие координаты
 * @param respDataByCoords данные с проверки по координатам
 * @param respDataByAddress данные с проверки по адресу
 * @param showModal
 * @param updatePriority
 */
export const checkCoordsModalFromRoutine: CheckCoordsModal = (
  address,
  coordinates,
  respDataByCoords,
  respDataByAddress,
  { showModal = true, updatePriority = CoordinatesUpdatePriority.never }
) => {
  let showNotify = false; // Показывать ли модальное окно и ожидать результат действия в Promise
  let resp: { [K: string]: any } = {};

  function updateCoords(coords: [number, number] | [string, string] | undefined, address: string | undefined) {
    if (coords?.[0] && coords?.[1]) {
      const geoHash = defaultGeohashEncode([Number(coords[0]), Number(coords[1])]);
      resp = { LatLon: geoHash, AddressDescription: address, LatLonVerified: 1 };
    } else {
      returnEmpty();
    }
  }

  const updateVerify = () => {
    resp = { [DepotRecordKeys.LAT_LON_VERIFIED]: 1 };
  };

  function returnEmpty() {
    showNotify = false;
  }

  const onClick = (respData: respDataFromGeocode) => {
    updateCoords(respData.coords, respData.address);
  };

  if (coordinates && address) {
    const equalByCoords = respDataByCoords?.find((item) => isEqualByCoords(address, item));
    if ((respDataByAddress && !isEqualByAddress(address, coordinates, respDataByAddress)) || !equalByCoords) {
      if (showModal) {
        showNotify = true;
      } else {
        showNotify = false;
        updateVerify();
        if (updatePriority === CoordinatesUpdatePriority.byAddress && address && respDataByAddress) {
          updateCoords([respDataByAddress.Latitude, respDataByAddress.Longitude], address);
        } else if (updatePriority === CoordinatesUpdatePriority.byCoords && coordinates && respDataByCoords) {
          updateCoords(coordinates.split(',').map((coordinate) => Number(coordinate)) as [number, number], respDataByCoords[0].value);
        }
      }
    } else {
      ILSUserNotify(Notify.Success, MESSAGE_COORD_IS_CORRECT, NotifyDurationInSecond.Four, MESSAGE_NOTIFY_KEY);
      showNotify = false;
      updateVerify();
    }
  } else if (coordinates && respDataByCoords) {
    updateCoords(coordinates.split(',').map((coordinate) => Number(coordinate)) as [number, number], respDataByCoords[0].value);
  } else if (address && respDataByAddress) {
    updateCoords([respDataByAddress.Latitude, respDataByAddress.Longitude], address);
  }
  let notify: { destroy: () => void };
  if (showNotify && (respDataByCoords || respDataByAddress)) {
    notify = createNotify(address, coordinates, respDataByCoords, respDataByAddress, onClick, returnEmpty);
  } else {
    returnEmpty();
  }
  return new Promise((res, _rej) => {
    const checkChoice = () => {
      if (!showNotify) {
        res(resp);
      } else if (resp && Object.keys(resp).length) {
        res(resp);
      } else {
        setTimeout(checkChoice, USER_FREQUENCY_MS);
      }
    };
    checkChoice();
    setTimeout(() => {
      notify?.destroy();
      res(resp);
    }, NOTIFY_MS_TO_AUTO_CLOSE);
  });
};

const createNotify = (
  address: string | undefined,
  coordinates: string | undefined,
  respDataByCoords: GetAddressResponse | undefined,
  respDataByAddress: GetCoordinatesResponse | undefined,
  onClick: (respData: respDataFromGeocode) => void,
  returnEmpty: () => void
) => {
  const notify = ILSUserNotifyConfirmCustom(
    'Различные данные по точкам',
    <>
      <p>
        <b>Текущие данные:</b>
      </p>
      <p>Адрес: {address?.replaceAll('+', ', ') ?? NOT_FILL}</p>
      <p>Координаты: {coordinates?.replaceAll(',', ', ') ?? NOT_FILL}</p>

      {respDataByCoords?.length ? (
        <>
          <p>
            <b>Данные по координатам:</b>
          </p>
          <p>Адрес: {respDataByCoords[0].value || NOT_FILL}</p>
          <p>Координаты: {coordinates?.replaceAll(',', ', ') || NOT_FILL}</p>
        </>
      ) : null}
      {respDataByAddress ? (
        <>
          <p>
            <b>Данные по адресу:</b>
          </p>
          <p>Адрес: {address ?? NOT_FILL}</p>
          <p>Координаты: {respDataByAddress ? `${respDataByAddress.Latitude},${respDataByAddress.Longitude}` : NOT_FILL}</p>
        </>
      ) : null}
    </>,
    undefined,
    undefined,
    undefined,
    <>
      {respDataByAddress ? (
        <ILSButton
          className="mr-1"
          type="primary"
          onClick={() => {
            onClick({ address, coords: [Number(respDataByAddress.Latitude), Number(respDataByAddress.Longitude)] });
            notify.destroy();
          }}
        >
          Сохранить по адресу
        </ILSButton>
      ) : null}
      {respDataByCoords ? (
        <ILSButton
          className="mr-1"
          type="primary"
          onClick={() => {
            onClick({
              address: respDataByCoords[0].value,
              coords: coordinates?.split(',').map((coordinate) => Number(coordinate)) as [number, number]
            });
            notify.destroy();
          }}
        >
          Сохранить по координатам
        </ILSButton>
      ) : null}

      <ILSButton
        className="mr-1"
        onClick={() => {
          notify.destroy();
          returnEmpty();
        }}
      >
        Оставить без изменений
      </ILSButton>
    </>
  );
  return notify;
};

const isEqualByAddress = (address: string | undefined, coords: string | undefined, respDataByAddress: GetCoordinatesResponse) => {
  return (
    isEqualCoordinates(coords, [Number(respDataByAddress.Latitude), Number(respDataByAddress.Longitude)]) &&
    address === respDataByAddress.Name
  );
};
const isEqualByCoords = (address: string | undefined, respDataByCoords: GetAddressResponse[number]) => {
  return address?.trim() === respDataByCoords.value.trim();
};

const isEqualCoordinates = (currentCoordinate: string | undefined, respCoordinate: [number, number] | undefined) => {
  if (currentCoordinate === respCoordinate) return true;
  if (currentCoordinate) {
    const currentCoordinateParsed = currentCoordinate
      .split(',')
      .map((coordinate) => Number(coordinate)?.toFixed(PRECISION_FOR_COORDINATES));
    if (
      currentCoordinateParsed &&
      respCoordinate &&
      currentCoordinateParsed?.[0] === respCoordinate?.[0]?.toFixed(PRECISION_FOR_COORDINATES) &&
      currentCoordinateParsed?.[1] === respCoordinate?.[1]?.toFixed(PRECISION_FOR_COORDINATES)
    ) {
      return true;
    }
  }
  return false;
};

type CheckCoordsYandex = (
  address: string | undefined,
  coords: string | undefined,
  respDataByCoords: string | undefined,
  respDataByAddress: number[] | undefined,
  options: {
    showModal?: boolean;
    updatePriority?: CoordinatesUpdatePriority;
  }
) => Promise<{ [K: string]: any }>;
//TODO После превратить checkCoordsModalFromRoutine и createNotify в универсальные функции с одинаковым respDataByCoords и respDataByAddress
export const checkCoordsModalFromRoutineYandex: CheckCoordsYandex = (address, coords, respDataByCoords, respDataByAddress, options) => {
  const respDataByCoordsTransformed: Parameters<CheckCoordsModal>[2] =
    coords && respDataByCoords
      ? [
          {
            unrestricted_value: respDataByCoords,
            value: respDataByCoords,
            data: []
          }
        ]
      : undefined;

  const respDataByAddressTransformed: Parameters<CheckCoordsModal>[3] =
    address && respDataByAddress
      ? {
          Latitude: respDataByAddress[0],
          Longitude: respDataByAddress[1],
          Name: address
        }
      : undefined;
  return checkCoordsModalFromRoutine(address, coords, respDataByCoordsTransformed, respDataByAddressTransformed, options);
};

