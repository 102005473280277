import { PlanNotice } from '@common/types';

export const countNoticesInPlanNotice = (planNotice: PlanNotice[]) => {
  return planNotice?.reduce(
    (notices, { NoticeType }) => {
      if (NoticeType && NoticeType > 1) {
        if (NoticeType > 2) {
          notices.error++;
        } else {
          notices.warning++;
        }
      }

      return notices;
    },
    { error: 0, warning: 0 }
  );
};
