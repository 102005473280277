import { DictionariesEndpoint, Dictionary } from '@common/types';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

export enum PlanningAction {
  GET_PROJECT_CURRENT = 'GET_PROJECT_CURRENT',
  DELETE = 'DELETE',
  COPY = 'COPY',
  CLEAR = 'CLEAR',
  GET_PLAN = 'GET_PLAN',
  SAVE_ROUTE = 'SAVE_ROUTE',
  SAVE_TRIP = 'SAVE_TRIP',
  SAVE_PLAN = 'SAVE_PLAN',
  ADD_CREATED_PLAN = 'ADD_CREATED_PLAN',
  VALIDATE = 'VALIDATE',
  ADD_END_WAYPOINT = 'ADD_END_WAYPOINT',
  ORDER_FOR_VEHICLE = 'ORDER_FOR_VEHICLE',
  ADD_TRACK_REDIS = 'ADD_TRACK_REDIS',
  CHANGE_VEHICLE = 'CHANGE_VEHICLE',
  CREATE = 'CREATE',
  CREATE_TRIP = 'CREATE_TRIP',
  DONE = 'DONE',
  ENOUGH = 'ENOUGH',
  EXCHANGE_VEHICLE = 'EXCHANGE_VEHICLE',
  GET_CONFIGS = 'GET_CONFIGS',
  GET_TRACK = 'GET_TRACK',
  GET_LINK = 'GET_LINK',
  MOVE_WAYPOINT_IN_TRIP = 'MOVE_WAYPOINT_IN_TRIP',
  MOVE_WAYPOINT = 'MOVE_WAYPOINT',
  RESTRICTION_FILTER = 'RESTRICTION_FILTER',
  SET_LINKS = 'SET_LINKS',
  SET_EXECUTION = 'SET_EXECUTION',
  START = 'START',
  UNION_TRIPS = 'UNION_TRIPS',
  UNSET_EXECUTION = 'UNSET_EXECUTION',
  CANCEL = 'CANCEL',
  CLEANUP_MATRIX = 'CLEANUP_MATRIX',
  DELETE_MATRIX = 'DELETE_MATRIX',
  START_MATRIX = 'START_MATRIX',
  STATUS_MATRIX = 'STATUS_MATRIX',
  CHANGE = 'CHANGE',
  CHANGE_DRIVER_IN_ROUTE = 'CHANGE_DRIVER_IN_ROUTE',
  CHANGE_DRIVER_IN_TRIP = 'CHANGE_DRIVER_IN_TRIP',
  CHANGE_EXPEDITOR_IN_ROUTE = 'CHANGE_EXPEDITOR_IN_ROUTE',
  UPDATE_DECORATED_PLAN = 'UPDATE_DECORATED_PLAN',
  RUN_CHECK = 'RUN_CHECK',
  CHECK = 'CHECK',
  CHANGE_STATUS = 'CHANGE_STATUS',
  SET_CONFIG = 'SET_CONFIG',
  CHANGE_EXPEDITOR_IN_TRIP = 'CHANGE_EXPEDITOR_IN_TRIP',
  MANAGER_UPDATE = 'MANAGER_UPDATE',
  GET_TRACK_START = 'GET_TRACK_START',
  GET_TRACK_STOP = 'GET_TRACK_STOP',
  USE_REDIS = 'USE_REDIS',
  DELETE_TENDER_FOR_ROUTE = 'DELETE_TENDER_FOR_ROUTE',
  ORDER_COPY_TO_PROJECT = 'ORDER_COPY_TO_PROJECT',
  GET_PLANNER_PARAMS = 'GET_PLANNER_PARAMS',
  MAP_WAITING_MODE = 'MAP_WAITING_MODE',
  DEPOT_BIND_TIME_ZONE = 'DEPOT_BIND_TIME_ZONE',
  SWITCHERS_HANDLE = 'SWITCHERS_HANDLE',
  PLANNER_GET_COORDS = 'PLANNER_GET_COORDS',
  REMEMBER_SELECTED_ROWS = 'REMEMBER_SELECTED_ROWS',
  CHANGE_VALIDATION_STATUS = 'CHANGE_VALIDATION_STATUS',
  REMEMBER_FOCUS_AND_EXPAND_ROWS = 'REMEMBER_FOCUS_AND_EXPAND_ROWS',
  GET_PLAN_EFFICIENCY = 'GET_PLAN_EFFICIENCY',
  RELOAD = 'RELOAD'
}

const createPlanningRoutine = (actionType: PlanningAction) => createRoutine(`PLANNING/${actionType}`);

const DICTIONARY_PLANNER_ACTION_TYPE = 'DICTIONARY_PLANNER/OPERATION';
export const dictionaryPlannerRoutine = createRoutine(DICTIONARY_PLANNER_ACTION_TYPE);
export const multipleUpdateRoutine = createRoutine(DICTIONARY_PLANNER_ACTION_TYPE, {
  trigger: ({ data, dictionary }: { data: Array<unknown>; dictionary: Dictionary }) => ({
    dictionary,
    operation: DictionariesEndpoint.MultipleUpdate,
    values: data
  })
});

export const orderWithCargoUpdatingRoutine = createRoutine('DICTIONARY_PLANNER/ORDER/OPERATION');

export const planningGetProjectCurrentRoutine = createPlanningRoutine(PlanningAction.GET_PROJECT_CURRENT);
export const planningDeleteRoutine = createPlanningRoutine(PlanningAction.DELETE);
export const planningCopyRoutine = createPlanningRoutine(PlanningAction.COPY);
export const planningReloadRoutine = createPlanningRoutine(PlanningAction.RELOAD);
export const planningClearRoutine = createPlanningRoutine(PlanningAction.CLEAR);
export const planningGetPlanRoutine = createPlanningRoutine(PlanningAction.GET_PLAN);
export const planningGetPlanEfficiencyRoutine = createPlanningRoutine(PlanningAction.GET_PLAN_EFFICIENCY);
export const addCreatedPlanRoutine = createPlanningRoutine(PlanningAction.ADD_CREATED_PLAN);

export const planningValidateRoutine = createPlanningRoutine(PlanningAction.VALIDATE);

export const planningUpdatePlansAndRoutesAction = createPlanningRoutine(PlanningAction.UPDATE_DECORATED_PLAN);

export const planningStartRoutine = createPlanningRoutine(PlanningAction.START);
export const planningRunCheckAction = createPlanningRoutine(PlanningAction.RUN_CHECK);
export const planningCheckRoutine = createPlanningRoutine(PlanningAction.CHECK);
export const planningDoneRoutine = createPlanningRoutine(PlanningAction.DONE);
export const planningCancelRoutine = createPlanningRoutine(PlanningAction.CANCEL);

export const planningChangeStatusRoutine = createPlanningRoutine(PlanningAction.CHANGE_STATUS);

export const planningSetConfigRoutine = createPlanningRoutine(PlanningAction.SET_CONFIG);

export const planningSetExecutionRoutine = createPlanningRoutine(PlanningAction.SET_EXECUTION);
export const planningUnsetExecutionRoutine = createPlanningRoutine(PlanningAction.UNSET_EXECUTION);

export const createTripRoutine = createPlanningRoutine(PlanningAction.CREATE_TRIP);
export const unionTripsRoutine = createPlanningRoutine(PlanningAction.UNION_TRIPS);
export const changeVehicleRoutine = createPlanningRoutine(PlanningAction.CHANGE_VEHICLE);
export const exchangeVehiclesRoutine = createPlanningRoutine(PlanningAction.EXCHANGE_VEHICLE);

export const changeDriverInTripRoutine = createPlanningRoutine(PlanningAction.CHANGE_DRIVER_IN_TRIP);
export const changeExpeditorInTripRoutine = createPlanningRoutine(PlanningAction.CHANGE_EXPEDITOR_IN_TRIP);
export const changeDriverInRouteRoutine = createPlanningRoutine(PlanningAction.CHANGE_DRIVER_IN_ROUTE);
export const changeExpeditorInRouteRoutine = createPlanningRoutine(PlanningAction.CHANGE_EXPEDITOR_IN_ROUTE);

export const addOrderForVehicleRoutine = createPlanningRoutine(PlanningAction.ORDER_FOR_VEHICLE);

export const moveWaypointToTripRoutine = createPlanningRoutine(PlanningAction.MOVE_WAYPOINT);
export const moveWaypointInTripRoutine = createPlanningRoutine(PlanningAction.MOVE_WAYPOINT_IN_TRIP);
export const addEndDepotToTripRoutine = createPlanningRoutine(PlanningAction.ADD_END_WAYPOINT);

export const planSaveRoutine = createPlanningRoutine(PlanningAction.SAVE_PLAN);
export const routeSaveRoutine = createPlanningRoutine(PlanningAction.SAVE_ROUTE);
export const tripSaveRoutine = createPlanningRoutine(PlanningAction.SAVE_TRIP);

export const updateManagerRoutine = createPlanningRoutine(PlanningAction.MANAGER_UPDATE);

export const planningGetConfigsRoutine = createPlanningRoutine(PlanningAction.GET_CONFIGS);

export const vehicleMonitoringGetLinkRoutine = createPlanningRoutine(PlanningAction.GET_LINK);
export const vehicleMonitoringSetLinksRoutine = createPlanningRoutine(PlanningAction.SET_LINKS);

export const planningGetTrackStartAction = createPlanningRoutine(PlanningAction.GET_TRACK_START);
export const planningGetTrackStopAction = createPlanningRoutine(PlanningAction.GET_TRACK_STOP);
export const planningGetTrackRoutine = createPlanningRoutine(PlanningAction.GET_TRACK);
export const planningUseRedisRoutine = createPlanningRoutine(PlanningAction.USE_REDIS);
export const planningAddTrackToQueryRoutine = createPlanningRoutine(PlanningAction.ADD_TRACK_REDIS);

export const planningDeleteTenderForRouteRoutine = createPlanningRoutine(PlanningAction.DELETE_TENDER_FOR_ROUTE);

export const planningRestrictionFilterRoutine = createPlanningRoutine(PlanningAction.RESTRICTION_FILTER);

export const cleanupDistanceMatrixRoutine = createPlanningRoutine(PlanningAction.CLEANUP_MATRIX);
export const startDistanceMatrixRoutine = createPlanningRoutine(PlanningAction.START_MATRIX);
export const deleteDistanceMatrixRoutine = createPlanningRoutine(PlanningAction.DELETE_MATRIX);
export const statusDistanceMatrixRoutine = createPlanningRoutine(PlanningAction.STATUS_MATRIX);

export const copyOrdersToProjectRoutine = createPlanningRoutine(PlanningAction.ORDER_COPY_TO_PROJECT);

export const getPlannerParamsRoutine = createPlanningRoutine(PlanningAction.GET_PLANNER_PARAMS);

export const mapWaitingModeRoutine = createPlanningRoutine(PlanningAction.MAP_WAITING_MODE);
export const bindTimeZoneForDepotsRoutine = createPlanningRoutine(PlanningAction.DEPOT_BIND_TIME_ZONE);

export const handleSwitchersRoutine = createPlanningRoutine(PlanningAction.SWITCHERS_HANDLE);

export const planningGetCoordsRoutine = createPlanningRoutine(PlanningAction.PLANNER_GET_COORDS);

export const planningRememberSelectedRowsRoutine = createPlanningRoutine(PlanningAction.REMEMBER_SELECTED_ROWS);

export const planningChangeValidationStatusRoutine = createPlanningRoutine(PlanningAction.CHANGE_VALIDATION_STATUS);

export const planningRememberFocusNExpandRowsRoutine = createPlanningRoutine(PlanningAction.REMEMBER_FOCUS_AND_EXPAND_ROWS);

export const planningByDateRoutinesToNotify: UnifiedRoutine<(payload?: unknown) => Action>[] = [
  planningGetPlanRoutine,
  planningValidateRoutine,
  planningStartRoutine,
  planningDoneRoutine,
  planningCancelRoutine,
  planningChangeStatusRoutine,
  planningDeleteRoutine,
  planningCopyRoutine,
  planningClearRoutine,
  planningSetExecutionRoutine,
  planningUnsetExecutionRoutine,
  createTripRoutine,
  unionTripsRoutine,
  changeVehicleRoutine,
  exchangeVehiclesRoutine,
  changeDriverInTripRoutine,
  changeDriverInRouteRoutine,
  addOrderForVehicleRoutine,
  moveWaypointToTripRoutine,
  moveWaypointInTripRoutine,
  addEndDepotToTripRoutine,
  planSaveRoutine,
  planningGetConfigsRoutine,
  vehicleMonitoringGetLinkRoutine,
  vehicleMonitoringSetLinksRoutine,
  planningGetTrackRoutine,
  planningRestrictionFilterRoutine,
  cleanupDistanceMatrixRoutine,
  startDistanceMatrixRoutine,
  deleteDistanceMatrixRoutine,
  statusDistanceMatrixRoutine,
  copyOrdersToProjectRoutine,
  dictionaryPlannerRoutine,
  getPlannerParamsRoutine,
  planningDeleteTenderForRouteRoutine,
  dictionaryPlannerRoutine,
  planningGetCoordsRoutine,
  planningReloadRoutine
];
