import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { deleteOrderRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const deleteOrderReducer = createReducer(initialState, {
  [deleteOrderRoutine.REQUEST]: (state: InitialState) => {
    state.orders.isFetching = true;
    return state;
  },
  [deleteOrderRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      id: number;
      prevOrderID?: number;
    }>
  ) => {
    const { id, prevOrderID } = action.payload;
    if (prevOrderID) {
      state.orders.activeRecord = { key: Number(prevOrderID), ID: Number(prevOrderID) };
    } else {
      state.orders.activeRecord = { key: undefined, ID: undefined };
    }

    delete state.orders.data?.[id];
    return state;
  },
  [deleteOrderRoutine.FAILURE]: onFailure,
  [deleteOrderRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.orders.isFetching = false;
    return state;
  }
});

