import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderChangeTemplateRoutine } from '@modules/tender-platform/actions';
import { TenderLot } from '@modules/tender-platform/types';
import { TenderTemplate } from '@modules/tender-platform/types/store';

function* tenderChangeLotTemplateWorker(
  action: PayloadAction<{
    TenderTemplate: TenderLot | TenderTemplate | null;
  }>
) {
  const { request, failure, fulfill, success } = tenderChangeTemplateRoutine;
  const { TenderTemplate } = action.payload;
  try {
    yield put(request());
    yield put(success({ TenderTemplate }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderChangeLotTemplateWatcher() {
  yield takeLatest(tenderChangeTemplateRoutine.trigger, tenderChangeLotTemplateWorker);
}
