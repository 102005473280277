import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { MonitoringState } from '@modules/monitoring/types/store';
import { DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../constants';

//TODO UNUSED files удалить
// Видимо - это замоканные данные, не нашел ссылок на них, можно удалить?
export const metricsData: MonitoringState['monitoringMetrics']['data'] = {
  [DashboardTablesNumberMetrics.Total]: 40,
  [DashboardTablesNumberMetrics.Served]: 20,
  [DashboardTablesObjMetrics.OnLate]: {
    Total: 4,
    Unviewed: 2
  },
  [DashboardTablesObjMetrics.OnTime]: {
    Total: 2,
    Unviewed: 1
  },
  [DashboardTablesObjMetrics.FuelDrain]: {
    Total: 1,
    Unviewed: 1
  },
  [DashboardTablesObjMetrics.Undelivered]: {
    Total: 5,
    Unviewed: 2
  },
  [DashboardTablesObjMetrics.BrokenSequence]: {
    Total: 3,
    Unviewed: 0
  },
  [DashboardTablesObjMetrics.Violations]: {
    Total: 4,
    Unviewed: 4
  }
};

// Аналогично - массив без переменной, можно удалить?
[
  { header: '20 из 40', sub: 'Обслужено точек' },
  { header: '4', sub: 'Доставлено в срок' },
  { header: '2', sub: 'Доставлено с опозданием' },
  { header: '1', sub: 'Не доставлено' },
  { header: '5', sub: 'Нарушений последовательности' },
  { header: '3', sub: 'Нарушение ПДД' },
  { header: '4', sub: 'Слив топлива' }
];

export enum DELIVERY_STATUSES_COLORS {
  green = '#50B993',
  yellow = '#FFA605',
  red = '#D24747',
  grey = '#C4C4C4'
}

// Статусы доставок
export const deliveryStatuses = [
  { text: 'Доставлено в срок', color: DELIVERY_STATUSES_COLORS.green, label: MonitoringWaypointEventStatusType.InTime },
  { text: 'Доставлено с опозданием', color: DELIVERY_STATUSES_COLORS.yellow, label: MonitoringWaypointEventStatusType.OnLate },
  { text: 'Не доставлено', color: DELIVERY_STATUSES_COLORS.red, label: MonitoringWaypointEventStatusType.Undelivered },
  { text: 'В очереди на доставку', color: DELIVERY_STATUSES_COLORS.grey, label: MonitoringWaypointEventStatusType.Wait }
];
