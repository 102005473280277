import { planningCopyRoutine, planningGetPlanRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanRequest } from '@modules/planning/types';
import { Dictionary } from '@common/types';
import { throwError } from '@common/utils/validators/check-data-for-error';

export type PlanningCopyPayloadAction = PayloadAction<PlanRequest>;

function* planningCopyWorker(action: PlanningCopyPayloadAction) {
  const { request, failure, fulfill, success } = planningCopyRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.copyPlan, { planID });
    const [plan] = data;
    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID: plan.ID, dictionary: Dictionary.Plan });
    if (!planTotal) {
      throwError({ message: 'Для отображения необходимо обновить проект' });
    } else {
      yield put(success({ plan, planTotal }));
    }
    yield put(planningGetPlanRoutine({ ID: plan.ID, hideSuccessMessage: true }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningCopyWatcher() {
  yield takeLatest(planningCopyRoutine.trigger, planningCopyWorker);
}
