import { Dictionary, Keys, TableName, TableRecord } from '@common/types';
import { useDispatch } from 'react-redux';
import { planningRememberSelectedRowsRoutine } from '@modules/planning/children/by-date/actions';
import { getIdsFromRouteTableByDictionary } from '@modules/planning/children/by-date/helpers/tables/get-ids-from-route-table-by-dictionary';
import { isEmpty } from 'lodash';
import { getIdsFromNotDeliveredOrderTableByDictionary } from '@modules/planning/children/by-date/helpers/tables/get-ids-from-not-delivery-order-table-by-dictionary';

export const useSelectedRowActions = () => {
  const dispatch = useDispatch();
  let recordIDs: Partial<Record<Dictionary, Keys>>;
  const onSelectRows = (selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined) => {
    switch (tableName) {
      case TableName.RoutesTable:
        recordIDs = getIdsFromRouteTableByDictionary(selectedRows);
        break;
      case TableName.PlanningNotDeliveredOrder:
        recordIDs = getIdsFromNotDeliveredOrderTableByDictionary(selectedRowKeys, selectedRows);
        break;
      default:
        console.error('Не передано название (tableName) таблицы');
    }
    if (tableName && !isEmpty(recordIDs)) {
      dispatch(planningRememberSelectedRowsRoutine({ tableName, recordIDs }));
    }
  };
  const onSelectRowsWithRecord = () => {
    //TODO Если нужна будет реализация с record - тип OnSelectWithRecordNAllRecordParams
  };

  const onResetSelectedRows = (tableName: string) => {
    dispatch(planningRememberSelectedRowsRoutine({ tableName }));
  };
  return { onSelectRows, onSelectRowsWithRecord, onResetSelectedRows };
};
