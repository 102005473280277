import { call, put, takeLatest } from 'redux-saga/effects';
import {
  controlConfigRoutine,
  createConfigRoutine,
  deleteConfigRoutine,
  getConfigsRoutine,
  getConfigTemplateRoutine,
  importConfigRoutine,
  updateConfigRoutine
} from '@modules/planning/children/params/actions';
import { PlanningParams } from '@modules/planning/children/params/api';
import { IData, ITemplate } from '@modules/planning/children/params/types/store';
import { IDeleteAction, IImportAction, IUpdateAction } from '@modules/planning/children/params/types/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { ControlConfigOperation } from '@modules/planning/children/params/types/api';
import { planningGetConfigsRoutine } from '@modules/planning/children/by-date/actions';
import { IValue } from '@modules/planning/children/params/types/reducer';

export { copyParamsConfigWatcher } from '@modules/planning/children/params/sagas/copy-config';
export { updateExternalConfigWatcher } from '@modules/planning/children/params/sagas/update-external-params';

//TODO: разбить на файлы аналогично с другими модулями
function* planningParamsWorker() {
  const { request, success, failure, fulfill } = getConfigsRoutine;

  try {
    yield put(request());

    const { data }: { data: { data: IData[]; success: boolean } } = yield call<any>(PlanningParams.index, {});

    yield put(success({ data: data.data, activeConfig: data.data[0] }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningTemplateWorker() {
  const { request, success, failure, fulfill } = getConfigTemplateRoutine;

  try {
    yield put(request());

    const { data }: { data: { data: ITemplate; success: boolean } } = yield call<any>(PlanningParams.getTemplate, {});

    yield put(success({ template: data.data.template, conf: data.data.conf }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningCreateConfigWorker() {
  const { request, success, failure, fulfill } = createConfigRoutine;

  try {
    yield put(request());

    yield call<any>(PlanningParams.create, {});

    const { data }: { data: { data: IData[]; success: boolean } } = yield call<any>(PlanningParams.index, {});

    yield put(
      success({
        data: data.data,
        activeConfig: data.data && data.data.length && data.data[data.data.length - 1]
      })
    );
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningUpdateConfigWorker(action: IUpdateAction) {
  const { request, success, failure, fulfill } = updateConfigRoutine;
  const { values } = action.payload;

  try {
    yield put(request());

    yield call<any>(PlanningParams.update, { values });

    yield put(getConfigsRoutine.trigger());

    yield put(success({ values }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningDeleteConfigWorker(action: IDeleteAction) {
  const { request, success, failure, fulfill } = deleteConfigRoutine;
  const { ConfigID } = action.payload;

  try {
    yield put(request());

    yield call(PlanningParams.delete, { ConfigID });

    yield put(success({ ConfigID: ConfigID }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningImportConfigWorker(action: IImportAction) {
  const { request, success, failure, fulfill } = importConfigRoutine;
  const { config } = action.payload;

  try {
    yield put(request());

    const { data } = yield call(PlanningParams.import, { config });

    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

function* planningControlConfigWorker(
  action: PayloadAction<{
    operation: ControlConfigOperation;
    values?: IValue;
    ConfigID?: string[];
  }>
) {
  const { failure, fulfill } = controlConfigRoutine;
  const { operation } = action.payload;
  if (operation) {
    try {
      switch (operation) {
        case ControlConfigOperation.Create:
          yield put(createConfigRoutine.trigger());
          break;
        case ControlConfigOperation.Update:
          yield put(updateConfigRoutine.trigger({ values: action.payload?.values }));
          break;
        case ControlConfigOperation.Delete:
          yield put(deleteConfigRoutine.trigger({ ConfigID: action.payload.ConfigID }));
      }
      yield put(planningGetConfigsRoutine.trigger({ forceUpdate: true }));
    } catch (error) {
      yield put(failure({ error: error }));
    } finally {
      yield put(fulfill());
    }
  }
}

export function* planningControlConfigWatcher() {
  yield takeLatest(controlConfigRoutine.trigger, planningControlConfigWorker);
}

export function* planningGetConfigWatcher() {
  yield takeLatest(getConfigsRoutine.trigger, planningParamsWorker);
}

export function* planningGetTemplateWatcher() {
  yield takeLatest(getConfigTemplateRoutine.trigger, planningTemplateWorker);
}

export function* planningCreateConfigWatcher() {
  yield takeLatest(createConfigRoutine.trigger, planningCreateConfigWorker);
}

export function* planningUpdateConfigWatcher() {
  yield takeLatest(updateConfigRoutine.trigger, planningUpdateConfigWorker);
}

export function* planningDeleteConfigWatcher() {
  yield takeLatest(deleteConfigRoutine.trigger, planningDeleteConfigWorker);
}

export function* planningImportConfigWatcher() {
  yield takeLatest(importConfigRoutine.trigger, planningImportConfigWorker);
}
