import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILSRampPlanningTable } from '@modules/planning/children/by-date/components/tables/ramp';
import { ILSWindowTable } from '@components/data-display/table/components/ils-window';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { DictionariesEndpoint, Dictionary, TableRecord } from '@common/types';
import { useAppSelector } from '@core/hooks';
import { planningByDateInfoSelector } from '@modules/planning/children/by-date/selectors/project';
import { SetShowReference } from '@common/types/components/table/common';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { DictionaryOperation, DictionaryUpdateRequest } from '@common/types/general/api/dictionary';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

interface IProps {
  setShowRampSchedule: SetShowReference<TableRecord>;
}

export const ILSResultRampScheduleContainer: FC<IProps> = ({ setShowRampSchedule }) => {
  const dispatch = useDispatch();
  const activePlan = useSelector(activePlanIDSelector);
  const info = useAppSelector(planningByDateInfoSelector);
  const { catalogLoad } = useCatalog();

  const handleSave = (dictionary: Dictionary, record: any, value: any) => {
    const payload: DictionaryOperation<DictionaryUpdateRequest> & {
      dictionaryFetching?: boolean;
    } = {
      dictionary,
      ids: [record.ID],
      values: {
        ...record,
        ...value
      },
      operation: DictionariesEndpoint.Update,
      dictionaryFetching: true
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };

  return (
    <ILSWindowTable
      hasRowSelection={false}
      dictionaryLoad={catalogLoad}
      title={`Расписание работы рамп для плана №${activePlan} (Yard Management System)`}
      setShow={setShowRampSchedule}
      component={ILSRampPlanningTable}
      handleSave={handleSave}
      //@ts-ignore
      dictionaryInfo={info}
    />
  );
};
