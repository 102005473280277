import { monitoringGetVehicleTrackersRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringState } from '../types/store';

// Устанавливает данные по привязкам трекеров к автомобилям
const monitoringGetVehicleTrackersReducer = createReducer<MonitoringState>(initialState as MonitoringState, {
  [monitoringGetVehicleTrackersRoutine.REQUEST]: (state) => {
    state.vehicleTrackers.isFetching = true;
    return state;
  },
  [monitoringGetVehicleTrackersRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const data = action.payload;
    state.vehicleTrackers.data = data;

    state.vehicleTrackers.lastUpdate = new Date().getTime();
    return state;
  },
  [monitoringGetVehicleTrackersRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringGetVehicleTrackersRoutine.FULFILL]: (state) => {
    state.vehicleTrackers.isFetching = false;
    return state;
  }
});

export default monitoringGetVehicleTrackersReducer;
