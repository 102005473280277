import { IndexedArray } from '@common/types';
import { MonitoringTripServedDataModel } from '@common/types/dictionaries/monitoring-trip';
import { FC, useMemo } from 'react';

interface IProps {
  dataSource: Array<MonitoringTripServedDataModel> | IndexedArray<MonitoringTripServedDataModel>;
}

// Компонент отображающий количество стартовавших маршрутов
export const StartedVehicles: FC<IProps> = ({ dataSource }) => {
  const totalStartedVehicles = Object.keys(dataSource).length;
  const allRoutesArray = Object.values(dataSource);

  const startedVehicles = useMemo(() => {
    return allRoutesArray.reduce((acc, item: MonitoringTripServedDataModel) => {
      let tripsArray = Object.values(item?.Trip ?? {});

      acc += tripsArray.reduce((accumulator, arrayItem) => {
        if (+arrayItem.Status > 100) {
          accumulator += 1;
        }
        return accumulator;
      }, 0);

      return acc;
    }, 0);
  }, [allRoutesArray]);

  return (
    <div className="ils-monitoring-dashboard-started-vehicles">
      Стартовали {startedVehicles} маршрутов из {totalStartedVehicles}
    </div>
  );
};
