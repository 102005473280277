import { apiInstance } from '@core/api/instance';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary, PlanID, Trip, Vehicle, Waypoint } from '@common/types';

export const Track = {
  getTotal: ({
    dictionary,
    ...params
  }: {
    dictionary: Dictionary;
    waypointID?: Waypoint['ID'] | Array<Waypoint['ID']>;
    vehicleID?: Vehicle['ID'] | Array<Vehicle['ID']>;
    planID?: PlanID;
    tripID?: Trip['ID'] | Array<Trip['ID']>;
  }) => {
    return apiInstance().post(getAPIURL(Dictionary.Planning, `get${dictionary}Total` as DictionariesEndpoint), params);
  },
  getProject: (params: { projectID: number | string }) => {
    const { projectID } = params;
    const api = apiInstance();

    return api.post('/NewTms/Planning/getProject', { projectID });
  },
  getTrack: (params: { points: number | string }) => {
    const { points } = params;
    const api = apiInstance();

    return api.post('/NewTms/Planning/getTrack', { points });
  },
  getPlan: (params: { ID: number | string }) => {
    return apiInstance().post('/NewTms/Planning/getPlan', params);
  }
};
