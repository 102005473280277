import { FC, useEffect, useState } from 'react';
import '../styles.less';
import { useAppSelector } from '@core/hooks';
import { errorSelector, fetchSelector, savingSelector, successSelector } from '../selectors';
import { ILSSpin, ILSUserNotify } from '@common/components';
import ILSRoadGraphEditorMapContainer from '@modules/road-graph/editor/containers/map-container';
import ILSRoadGraphEditorSegmentEditContainer from '@modules/road-graph/editor/containers/segment-edit-container';
import { RoadGraphEditorContext } from '@modules/road-graph/editor/context';
import ILSRoadGraphContainer from '@modules/road-graph/common/container';
import { ActiveRoad } from '@modules/road-graph/editor/types';
import { Notify } from '@common/types';

const ILSRoadGraphEditor: FC = () => {
  const error = useAppSelector(errorSelector);
  const isSaving = useAppSelector(savingSelector);
  const isFetching = useAppSelector(fetchSelector);
  const success = useAppSelector(successSelector);
  // const dispatch = useDispatch()
  const [edit, setEdit] = useState<null | ActiveRoad>(null);
  const [showDepots, setShowDepots] = useState<boolean>(false);

  useEffect(() => {
    if (error && error.length) {
      ILSUserNotify(Notify.Error, error, 3, 'editor');
    }
    if (isSaving) {
      ILSUserNotify(Notify.Loading, isSaving, 60, 'editor');
    }
    if (success) {
      ILSUserNotify(Notify.Success, success, 3, 'editor');
    }
  }, [error, isSaving, success]);

  const contextValue = {
    edit,
    setEdit,
    showDepots,
    setShowDepots
  };
  return (
    <>
      <ILSRoadGraphContainer showDepots={showDepots} setShowDepots={setShowDepots} />
      <ILSSpin spinning={isFetching}>
        <RoadGraphEditorContext.Provider value={contextValue}>
          <div className="road-graph-content editor">
            <ILSRoadGraphEditorMapContainer />
            <div className="map-right-buttons">
              <ILSRoadGraphEditorSegmentEditContainer />
            </div>
          </div>
        </RoadGraphEditorContext.Provider>
      </ILSSpin>
    </>
  );
};

export default ILSRoadGraphEditor;
