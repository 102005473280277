import './styles.less';
import cn from 'classnames';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { getSRCIconSVG } from '@common/decorators/path';

export type LocalDocumentType = { id: number; name: string; fileName: string; filePath: string | null; extension: string; img?: string };

interface IProps {
  document: LocalDocumentType;
  disabled?: boolean;
}

export const DownloadLocalDocumentCard: FC<IProps> = ({ document, disabled }) => {
  const documentPath = process.env.PUBLIC_URL + document.filePath;

  return (
    <div className="document-download-card">
      <div className="document-download-card__group">
        <p className="document-download-card__extension">{document.extension}</p>
        <p className="document-download-card__name">{document.fileName}</p>
      </div>
      <div className="document-download-card__group">
        <div className={cn('document-download-card__link', { disabled })}>
          <a href={documentPath} download={document.fileName} target="_blank" rel="noreferrer">
            <ReactSVG className={'svg-container'} src={getSRCIconSVG('download')} />
          </a>
        </div>
      </div>
    </div>
  );
};

