import { Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { takeLatest } from 'redux-saga/effects';
import { tenderRoutinesToNotify } from '../../actions';
import { defaultTenderMessages, TenderAction } from '../../constants/default-message';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';

const getTenderDataNotificationMessage = <T extends string | number | symbol>(defaultMessage: { [key in T]: Object }) => {
  return (action: T, type: Notify.Loading | Notify.Success | Notify.Error, payload?: { message: string; hideSuccessMessage?: string }) => {
    if (payload?.hideSuccessMessage) return payload.hideSuccessMessage;
    return payload?.message || defaultMessage?.[action]?.[type] || 'Загружено';
  };
};

const tenderWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, TenderAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderAction,
  getKeyPrefix: () => 'tender_search',
  getNotificationMessage: getTenderDataNotificationMessage<TenderAction>(defaultTenderMessages)
});

const tenderErrorWorker = notifierWorkerCreator<{ error: string | string[] }, TenderAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderAction,
  getKeyPrefix: () => 'tender_search',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* tenderSearchNotifierWatcher() {
  for (const routine of tenderRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, tenderWorker);
    yield takeLatest(routine.SUCCESS, tenderWorker);
    yield takeLatest(routine.FAILURE, tenderErrorWorker);
  }
}
