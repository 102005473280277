import { useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '../../selectors';
import { MAX_DAYS_INTERVAL } from '../../constants';
import { MONITORING_DATE_FORMAT } from '../../config';
import { changeDateFormat } from '@common/utils/helpers/date-time/date-time';
import { ILSRangePickerIntervalComponent } from '@common/components/data-entry/range-picker-interval/range-picker-interval';

interface Props {
  handleDate: (date: string[]) => void;
  maxInterval: string | number | boolean | undefined;
}

// Компонент для выбора диапазона дат
export const ILSMonitoringRangePickerComponent = (props: Props) => {
  const { handleDate, maxInterval } = props;
  let { startDate, endDate } = useAppSelector(monitoringDateSelector);

  return (
    <ILSRangePickerIntervalComponent
      handleDate={handleDate}
      maxInterval={maxInterval}
      startDate={startDate}
      endDate={endDate}
      changeDateFormat={changeDateFormat}
      DATE_FORMAT={MONITORING_DATE_FORMAT}
      MAX_DAYS_INTERVAL={MAX_DAYS_INTERVAL}
    />
  );
};
