import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import { FC } from 'react';
import '../styles.less';

export const ILSPageHeader: FC = () => {
  return (
    <>
      <Scope name={ModuleScope.HeaderLeft} />
      <Scope name={ModuleScope.HeaderRight} />
    </>
  );
};
