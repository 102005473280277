import { FC } from 'react';
import { getSRCIconSVG } from '@common/decorators/path';
import { PLANNING_STATUSES } from '@common/constants/custom';

export const ILSPlanningStatusTooltip: FC = () => {
  return (
    <ul className="status-tip">
      <li className="status-tip-header">
        <h4>Статус плана и его изменений</h4>
      </li>
      {Object.values(PLANNING_STATUSES).map(({ icon, text }) => {
        const iconSRC = getSRCIconSVG(icon, { originPath: true });
        return (
          <li key={icon}>
            <img src={iconSRC} alt={icon} />
            {text}
          </li>
        );
      })}
    </ul>
  );
};
