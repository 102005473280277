import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderOfferGetVehicleRoutine } from '../actions';
import { TenderAPI } from '@modules/tender-platform/api';

function* tenderOfferGetVehicleWorker(
  action: PayloadAction<{
    id: number | string;
  }>
) {
  const { request, success, failure, fulfill } = tenderOfferGetVehicleRoutine;
  const { id } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderAPI.getVehiclesForTender, { tenderID: id });
    yield put(success({ vehicles: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferGetVehicleWatcher() {
  yield takeLatest(tenderOfferGetVehicleRoutine.trigger, tenderOfferGetVehicleWorker);
}
