import { generateUUID } from '@common/utils/general/uuid';
import { DepotRequest, DepotTypeId } from '@common/types';

export const NEW_CLIENT: DepotRequest = {
  DepotCode: generateUUID(),
  Name: 'Новый клиент',
  DepotTypeID: DepotTypeId.Client
};

export const NEW_GARAGE: DepotRequest = {
  DepotCode: generateUUID(),
  Name: 'Новый склад/гараж',
  DepotTypeID: DepotTypeId.Storage
};

export enum DepotType {
  Client = 'Клиент',
  Storage = 'Склад',
  CrossDock = 'Кросс-докинг',
  Monitoring = 'Объект мониторинга',
  LoadDepot = 'Точка сбора'
}

export const DEPOT_TYPE_OPTIONS = [
  {
    ID: DepotTypeId.Client,
    Name: DepotType.Client
  },
  {
    ID: DepotTypeId.Storage,
    Name: DepotType.Storage
  },
  {
    ID: DepotTypeId.CrossDock,
    Name: DepotType.CrossDock
  },
  {
    ID: DepotTypeId.Monitoring,
    Name: DepotType.Monitoring
  },
  {
    ID: DepotTypeId.LoadDepot,
    Name: DepotType.LoadDepot
  }
];
