import { IData } from '@modules/planning/children/params/types/store';

type ConfigItemAbstractItem = { Value_?: string; Items?: Array<ConfigItemAbstractItem>; StringKey_: string; DefaultValue_?: string };
export const transformConfig = (config: IData): any => {
  const template = {
    Name: config.Name_,
    Enabled: config['Enabled'] ?? 0
  };

  if (config.Items) {
    return transformConfigItemsRecursive(config.Items, template, true);
  } else {
    return template;
  }
};

/**
 * При экспорте конфигурации элементы ниже первого уровня объединяются.
 *
 * Например, все штрафы из всех подгрупп штрафов выгружаться на верхний уровень штрафов
 *
 * Выгружаются только измененные значения
 * **/
const transformConfigItemsRecursive = (
  items: Array<ConfigItemAbstractItem>,
  config: Record<string, any>,
  firstLevel: boolean = false
): Record<string, any> => {
  items
    .filter((x) => !x.Value_ || (!!x.Value_ && x.DefaultValue_ !== x.Value_))
    .forEach((item) => {
      if (item.Items && item.StringKey_) {
        if (firstLevel) {
          config[item.StringKey_] = transformConfigItemsRecursive(item.Items, {});
        } else {
          //для уровней ниже первого при выгрузке нет деления на группы
          transformConfigItemsRecursive(item.Items, config);
        }
      } else {
        config[item.StringKey_] = item.Value_;
      }
    });
  return config;
};
