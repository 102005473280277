import { FC, memo } from 'react';
import { PlannerPlanningPlanTableContextMenuPopover } from '@modules/planning/children/by-date/components/result/context-menu/popover';
import { PlansTableRecord } from '@modules/planning/children/by-date/types';
import { getPlanContextMenu } from '@modules/planning/children/by-date/decorators/table/context-menu/plans/get-plan-context-menu';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';

export interface IPlannerPlansTableContextMenuContainerProps {
  visible: boolean;
  record: PlansTableRecord;
  coords: {
    x: number;
    y: number;
  };
  maxHeight: number;

  onContextClose(): void;

  handleShowOrderTable(val: boolean): void;

  setTriggerMapFocus: SetTriggerMapFocus;
}

export const PlannerPlansTableContextMenuContainer: FC<IPlannerPlansTableContextMenuContainerProps> = memo(
  ({ onContextClose, visible, record, coords, maxHeight, handleShowOrderTable, setTriggerMapFocus }) => {
    const menu = getPlanContextMenu({
      record,
      setTriggerMapFocus,
      handleShowOrderTable
    });
    return (
      <>
        {visible && (
          <PlannerPlanningPlanTableContextMenuPopover
            menu={menu}
            record={record}
            coords={coords}
            maxHeight={maxHeight}
            onContextClose={onContextClose}
          />
        )}
      </>
    );
  }
);
