import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { getReportsListRoutine } from '../../actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { ReportList } from '@modules/documents/types/document-type';

export const getReportsListReducer = createReducer(initialState, {
  [getReportsListRoutine.REQUEST]: onRequest,
  [getReportsListRoutine.SUCCESS]: (state, { payload: { data } }: PayloadAction<{ data: ReportList }>) => {
    state.reportList = data;
    return state;
  },
  [getReportsListRoutine.FAILURE]: onFailure,
  [getReportsListRoutine.FULFILL]: onFulfill
});
