import { Plan } from '@common/types';
import { PLANNER_PLANNING_PATH } from '@common/types/modules/module';
import history from '@common/utils/history';
import { createTenderForRouteRoutine, tenderDocumentUploadRoutine } from '@modules/tender-platform/actions';
import { TenderAPI } from '@modules/tender-platform/api';
import { createDataForCreateTenderForRouteSelector, planIDSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { DataForTenderCreation } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderFromPlannerOperationButton } from '@modules/tender-platform/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';
import { isEmpty } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* createTenderForRouteWorker(
  action: PayloadAction<{
    operation: TenderFromPlannerOperationButton;
    url: string;
    dataFiles: {
      [k: string | number]: UploadFile[];
    };
  }>
) {
  const { success, failure, request, fulfill } = createTenderForRouteRoutine;
  const { operation, url, dataFiles } = action.payload;
  try {
    yield put(request({}))
    const payload: DataForTenderCreation = yield select(createDataForCreateTenderForRouteSelector);
    const planID: Plan['ID'] = yield select(planIDSelector);

    const {
      data: { data }
    } = yield call(TenderAPI.updateTenderFromPlanner, { payload, planID, operation });
    yield put(success({ data }));
    yield call(history.push, operation.includes('Go') ? url : PLANNER_PLANNING_PATH);

    if (!isEmpty(dataFiles) && data) {
      const routes = Object.keys(dataFiles);
      for (let routeId of routes) {
        yield put(
          tenderDocumentUploadRoutine.trigger({
            tenderID: data[routeId],
            attachment: dataFiles[routeId]?.map((file) => file?.originFileObj)
          })
        );
      }
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put (fulfill({}))
  }
}

export function* createTenderForRouteWatcher() {
  yield takeLatest(createTenderForRouteRoutine.trigger, createTenderForRouteWorker);
}
