import { GetDashboardRequest } from '@common/types/dictionaries/monitoring';
import { MonitoringAPI } from '@modules/monitoring/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { monitoringDashboardAllInfoRoutine } from '../actions';
import { DashboardTablesNumberMetrics, apiMethodsNames } from '../constants/tables';
import { dashboardAllCheckNeedUpdateSelector } from '../selectors/dashboard';

type DashboardRequest = {
  from: string;
  till: string;
  updates?: boolean;
};

// Получает данные по всем таблицам сразу
function* monitoringDashboardAllInfoWorker(action: PayloadAction<DashboardRequest>) {
  const { request, success, failure, fulfill } = monitoringDashboardAllInfoRoutine;
  const { from, till, updates } = action.payload;

  try {
    yield put(request({ from, till, withoutNotification: updates  }));

    const requestData: GetDashboardRequest = {
      type: apiMethodsNames[DashboardTablesNumberMetrics.Served],
      from,
      till,
      updates
    };

    const { data } = yield call(MonitoringAPI.getDashboard, requestData);

    yield put(success({ data: data.data, updates, withoutNotification: updates  }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringDashboardAllInfoWatcher() {
  yield takeLatest(monitoringDashboardAllInfoRoutine.trigger, monitoringDashboardAllInfoWorker);
}

export function* monitoringDashboardAllUpdate() {
  const { payload, canUpdate } = yield select((state) => dashboardAllCheckNeedUpdateSelector(state));
  if (canUpdate) {
    yield put(monitoringDashboardAllInfoRoutine.trigger(payload));
  }
}
