import { useContext, useEffect } from 'react';
import { tenderActionRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { Action } from '@common/types';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { Dispatch } from 'redux';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { useSearchParams } from '@common/hooks/history/use-search-params';

export const useTender = (dispatch: Dispatch) => {
  const tenderPlatformChildrenModule = useContext(ILSTenderModuleContext);
  const tenderID = useSearchParams(TenderAction.Tender);

  useEffect(() => {
    if (tenderID) {
      const id = Number(tenderID);
      if (id) {
        dispatch(tenderReadRoutine({ id, tenderPlatformChildrenModule }));
      } else if (tenderID === Action.Create) {
        dispatch(tenderReadRoutine({ id: null, tenderPlatformChildrenModule }));
        dispatch(tenderActionRoutine({ tenderAction: TenderAction.Create, tender: null }));
      }
    }
    return () => {
      dispatch(tenderReadRoutine({ id: null }));
    };
  }, [tenderID]);
};
