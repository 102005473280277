import { createReducer } from '@reduxjs/toolkit';
import { setLanguage } from '@core/actions/lang-switch';
import { ILangSwitchState } from '@core/types/store';

const initialState: ILangSwitchState = {
  currentLanguage: 'ru',
  languages: {
    ru: 'Русский',
    en: 'English'
  }
};

const LangSwitch = createReducer(initialState, (builder) => {
  builder.addCase(setLanguage, (state, action) => {
    const { language } = action.payload;
    state.currentLanguage = language;
  });
});

export default LangSwitch;
