import { FC } from 'react';
import { ILSTypographyText } from '@components/index';
import { OrderActionType, OrderActionTypeLabel } from '@common/types/dictionaries/order';
import { RouteList } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { UnitOfMeasurement } from '@common/constants/general/unit-of-measurement';

interface IProps {
  route: RouteList;
}

export const TenderPlatformRouteListComponent: FC<IProps> = ({ route }) => {
  return (
    <ILSTypographyText className={'ant-input ant-input-disabled tender-from-planner-form-table-fieldset-row route'}>
      {route.map(({ Mass, Date, Address, ActionType }) => {
        return (
          <span key={Date + Mass}>
            <ILSTypographyText strong children={`${OrderActionTypeLabel[OrderActionType[ActionType]]}:`} />
            <p children={`${Mass} ${UnitOfMeasurement.Kg} - ${Date}`} />
            <p children={`Адрес точки: ${Address}`} />
          </span>
        );
      })}
    </ILSTypographyText>
  );
};
