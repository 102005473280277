import { planningAddTrackToQueryRoutine } from '../actions';
import { put, takeEvery } from 'redux-saga/effects';
import { PointType } from '@common/utils/get-track/types';
import { PayloadAction } from '@reduxjs/toolkit';

function* addTrackToQuery(
  action: PayloadAction<{
    points: Record<string, PointType>;
  }>
) {
  const { request, success, failure } = planningAddTrackToQueryRoutine;
  const { points } = action.payload;

  try {
    yield put(request());

    yield put(success({ points }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* AddTrackToQueryWatcher() {
  yield takeEvery(planningAddTrackToQueryRoutine.trigger, addTrackToQuery);
}
