import { Vehicle } from '@common/types';
import { compareAsString } from '@common/utils';
//record: ReturnType<typeof roadTrain2table>[number]
export const createOptionsForTrailer2 =
  ({ notIsAutomotiveVehicles }: { notIsAutomotiveVehicles: Array<Vehicle> }) =>
  (record: any) => {
    const trailer1 = notIsAutomotiveVehicles.find((vehicle: Vehicle) => compareAsString(vehicle.ID, record?.Trailer1ID));
    const trailer2options = notIsAutomotiveVehicles.filter((vehicle: Vehicle) =>
      compareAsString(vehicle.FrontCouplingTypeID, trailer1?.RearCouplingTypeID)
    );
    return trailer1 ? trailer2options ?? [] : [];
  };
