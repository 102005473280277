import { ErrorAPI, Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { OrdersNTripsRoutinesWithNotifier } from '@modules/orders-and-trips/actions';
import { takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { EMPTY_STRING } from '@common/constants';
import { OrdersNTripsAction, defaultStatuses } from '@modules/orders-and-trips/constants/default-message';

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: OrdersNTripsAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || defaultStatuses[action]?.[type] || EMPTY_STRING;
};

export default getDataNotificationMessage;

export const getActionFromRoutine = (actionName: string) => actionName as OrdersNTripsAction;

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, OrdersNTripsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'orders_and_trips',
  getNotificationMessage: getDataNotificationMessage
});

const errorWorker = notifierWorkerCreator<ErrorAPI, OrdersNTripsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'orders_and_trips',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* ordersNTripsNotifierWatcher() {
  for (let routine of OrdersNTripsRoutinesWithNotifier) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
