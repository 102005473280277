import { ILSButton, ILSModal } from '@common/components';
import { useModalWindow } from '@modules/orders-and-trips/hooks/use-modal-window';

export const ModalWindowContainer = () => {
  const { onClose, visible, ModalComponent, title } = useModalWindow();

  return (
    <ILSModal
      onCancel={onClose}
      footer={<ILSButton onClick={onClose} type={'primary'} children={'Закрыть'} />}
      title={title}
      visible={visible}
    >
      <ModalComponent />
    </ILSModal>
  );
};

