import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningUseRedisRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';

const planningUseRedisReducer = createReducer(initialState, {
  [planningUseRedisRoutine.SUCCESS]: (state, action: PayloadAction<{ useRedis: boolean }>) => {
    const { useRedis } = action.payload;
    state.useRedis = useRedis;
    return state;
  }
});

export default planningUseRedisReducer;
