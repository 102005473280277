import { JoinChar, WEEK_DAYS_OPTIONS } from '@common/constants';
import { DRIVER_OPTIONS } from '@common/models/driver';
import { CONDITIONS_FOR_CHECK, TRIP_TEMPLATE_MODE_OPTIONS } from '@common/models/trip-template';
import { VEHICLE_PRIORITY_OPTIONS, VEHICLE_USE_MODE_OPTIONS } from '@common/models/vehicle/constants';
import { CellType, ColumnTable, DepotRecordKeys, TableColumnName, TableRecord } from '@common/types';
import { compareAsString, createArrayFromIndexedArray, doubleToTime, timestampToDateTimeString } from '@common/utils';
import { isEmpty, isNumber } from 'lodash';
import { getGroupRowTitleFromEnum } from './get-group-row-title-from-enum';
import { replaceIDToEnumInDataIndex } from './replace-id-to-enum-in-dataindex';
import { ZONE_TYPE_OPTIONS } from '@common/models/zone/constants';
import { FormatType } from '@common/types/general/date-time';

/**
 * Функция создает корректное название для группирующей строки. Напр. для tableDataIndex "DepotTypeID" получаем значение 1,
 * в таблице необходимо отображать не числовое значение "Тип точки: 1", а строковое значение - "Тип точки: Клиент".
 * @param tableDataIndex - название колонки для группировки;
 * @param tableRow - текущая строка таблицы;
 * @param columnData - текущая колонка таблицы;
 * @returns - название группирующей строки;
 */

export const getGroupRowTitle = (
  tableDataIndex: ColumnTable<TableRecord>['dataIndex'],
  tableRow: Partial<Record<string, any>>,
  columnData?: ColumnTable<TableRecord>
) => {
  if (columnData?.type === CellType.TimeDouble) {
    return !isNaN(tableRow[tableDataIndex]) ? doubleToTime(tableRow[tableDataIndex] ?? 0) : null;
  }

  if (columnData?.type === CellType.Bool) {
    return tableRow[tableDataIndex] ? 'Да' : tableRow[tableDataIndex] !== null ? 'Нет' : null;
  }

  if (columnData?.type === CellType.Date && tableDataIndex !== TableColumnName.PassportDate) {
    return tableRow[tableDataIndex] && isNumber(tableRow[tableDataIndex])
      ? timestampToDateTimeString(tableRow[tableDataIndex], FormatType.Date)
      : null;
  }

  switch (tableDataIndex) {
    case DepotRecordKeys.LAT_LON:
      return tableRow[tableDataIndex] && `${tableRow[tableDataIndex]?.lat}, ${tableRow[tableDataIndex]?.lon}`;

    case TableColumnName.DepotTypeID:
    case TableColumnName.WorktimeID:
    case TableColumnName.DepotOverloadID:
    case TableColumnName.ContactID:
    case TableColumnName.DriverID:
    case TableColumnName.ExpeditorID:
    case TableColumnName.VehicleProfileID:
    case TableColumnName.VehicleTimelineID:
    case TableColumnName.VehicleOverloadID:
    case TableColumnName.SpeedProfileID:
    case TableColumnName.TransportCompanyID:
    case TableColumnName.VehicleTariffID:
    case TableColumnName.FrontCouplingTypeID:
    case TableColumnName.RearCouplingTypeID:
    case TableColumnName.DriverTimelineID:
    case TableColumnName.WarehouseID:
    case TableColumnName.RestrictionGroupID:
    case TableColumnName.RestrictionID:
    case TableColumnName.ZoneID:
    case TableColumnName.ForbiddenZoneID:
    case TableColumnName.TripTemplateID:
    case TableColumnName.VehicleID:
    case TableColumnName.DepotID:
      return getGroupRowTitleFromEnum(tableDataIndex, tableRow);

    case TableColumnName.EndDepotID:
    case TableColumnName.StartDepotID:
      const tableEnum = replaceIDToEnumInDataIndex(tableDataIndex);
      return (tableEnum && tableRow[tableEnum]?.[tableRow[tableDataIndex]?.[1]]) || null;

    case TableColumnName.FriendZoneID:
      if ('ZoneCode' in tableRow) {
        return tableRow[tableDataIndex]?.join(JoinChar.Comma);
      }
      return getGroupRowTitleFromEnum(tableDataIndex, tableRow);

    case TableColumnName.Priority:
      // Priority в "Водители"
      if (TableColumnName.PassportDate in tableRow) {
        return tableRow[tableDataIndex];
      } else {
        // Priority в "Парк машин"
        return VEHICLE_PRIORITY_OPTIONS.find(({ value }) => value === tableRow[tableDataIndex])?.label;
      }
    case TableColumnName.UseMode:
      return VEHICLE_USE_MODE_OPTIONS.find(({ value }) => value === tableRow[tableDataIndex])?.label;
    case TableColumnName.TemplateMode:
      return TRIP_TEMPLATE_MODE_OPTIONS.find(({ value }) => value === tableRow[tableDataIndex])?.label;
    case TableColumnName.CondForMin:
      return CONDITIONS_FOR_CHECK.find(({ value }) => value === tableRow[tableDataIndex])?.label;
    case TableColumnName.Type:
      if ('ZoneCode' in tableRow) {
        return ZONE_TYPE_OPTIONS.find(({ value }) => value === tableRow[tableDataIndex])?.label;
      }
      return DRIVER_OPTIONS.find(({ value }) => value === tableRow[tableDataIndex])?.label;

    case 'WorkSchedule':
      return !isEmpty(tableRow[tableDataIndex])
        ? WEEK_DAYS_OPTIONS.filter(({ value }) => tableRow[tableDataIndex]?.some((day: string) => day?.toString() === value))
            ?.map(({ label }) => label)
            ?.join(JoinChar.Comma)
        : null;

    default:
      return tableRow[tableDataIndex];
  }
};

/**
 * Функция принимает текущий уровень dataSource и группируем данные таблицы по определенному значению dataIndex.
 * Функция использует groupingKey - текущее название колонки, напр. "Тип точки", и значение "Типа точки" текущей строки, напр. "Клиент".
 * Создается группирующая строка с groupingKey = 'DepotType_Клиент'. Все последующие строки проверяют, значение типа точки, если совпадают,
 * то помещаются в существующую группу, либо создают новую.
 * @param dataSource - данные таблицы;
 * @param tableDataIndex - название колонки для группировки;
 * @param columns - название колонки для группировки;
 * @returns - данные таблицы после группировки;
 */

export const groupDataSourceByDataIndex = (
  dataSource: Array<Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>>,
  tableDataIndex: ColumnTable<TableRecord>['dataIndex'],
  columns: Array<ColumnTable<TableRecord>>
) => {
  const groupedIndexedArray = dataSource.reduce((rowsByDataIndex, tableRow) => {
    const columnData = columns?.find((column) => compareAsString(column.dataIndex, tableDataIndex));
    const groupTitle = columnData?.title || columnData?.titleText;
    const groupValue = getGroupRowTitle(tableDataIndex, tableRow, columnData) ?? 'Нет данных';

    const groupingKey = `${tableDataIndex}_${groupValue}`;
    const key = `${tableDataIndex}_${groupValue}_${tableRow['ID']}`;

    if (!(groupingKey in rowsByDataIndex)) {
      rowsByDataIndex[groupingKey] = {
        ...tableRow,
        Name: `${groupTitle}: ${groupValue} (1)`,
        groupingKey: groupingKey,
        key: key,
        ID: key,
        children: [tableRow]
      };
    } else {
      rowsByDataIndex[groupingKey].children.push(tableRow);
      rowsByDataIndex[groupingKey].Name = `${groupTitle}: ${groupValue} (${rowsByDataIndex[groupingKey]?.children?.length})`;
    }

    return rowsByDataIndex;
  }, {} as Record<string, Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>>);

  return createArrayFromIndexedArray(groupedIndexedArray);
};

/**
 * Функция recursiveGrouping используется внутри функции getDataSourceByGrouping для того, чтобы проверять, если текущий уровень dataSource имеет children,
 * то текущий уровень уже имеет группировку, необходимо провести группировку на следующем уровне.
 * @param dataSource - данные таблицы;
 * @param tableDataIndex - название колонки для группировки;
 * @param columns - колонки таблицы;
 * @returns - данные таблицы после группировки;
 */

const recursiveGrouping = (
  dataSource: Array<Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>>,
  tableDataIndex: ColumnTable<TableRecord>['dataIndex'],
  columns: Array<ColumnTable<TableRecord>>
) => {
  if (!dataSource[0]?.children) {
    return groupDataSourceByDataIndex(dataSource, tableDataIndex, columns);
  } else {
    return dataSource?.map((tableRow) => {
      const groupedChildren: Array<Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>> = recursiveGrouping(
        tableRow?.children,
        tableDataIndex,
        columns
      );
      return { ...tableRow, children: groupedChildren };
    });
  }
};

/**
 * Функция-декоратор принимает dataSource для раздела "Справочники" и группирует данные по названию колонок (dataIndex).
 * Напр. если необходимо сделать группировку по "Тип точки" и "Адрес" для данных таблицы "Точки", то все точки будут разделены по типам,
 * и внутри типов по адресам.
 * На верхнем уровне находятся группирующие строки, эти объекты имеют children, в которых находятся элементы, которые были сгруппированы по dataIndex.
 * @param dataSource - данные таблицы;
 * @param colsForGrouping - массив с dataIndex (название колонок для группировки);
 * @param columns - колонки таблицы;
 * @returns - данные таблицы после группировки;
 */

export const getDataSourceByGrouping = (
  dataSource: Array<Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>>,
  colsForGrouping: Array<ColumnTable<TableRecord>['dataIndex']>,
  columns: Array<ColumnTable<TableRecord>>
) => {
  let result: Array<Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>> = dataSource;

  colsForGrouping.forEach((tableDataIndex) => {
    const dataSourceHasCurrentDataIndex = result.some((tableRow) => tableDataIndex in tableRow);
    if (dataSourceHasCurrentDataIndex) {
      result = recursiveGrouping(result, tableDataIndex, columns);
    }
  });

  return result;
};
