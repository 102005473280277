import { CellType, ShowDeleted, TableConfig } from '@common/types';
import { BDD_VARIANTS, POINT_SERVICE_VARIANTS } from '../../constants';
import { VehicleTableColumnData } from '../../types/table';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export enum Desequence {
  AsPlan = '0',
  Replace = '2'
}
export enum DesequenceLabel {
  AsPlan = 'Как в плане',
  Replace = 'Поменять местами 2 точки'
}

// Конфигуратор для главной таблицы
export const vehicleTableConfig: TableConfig = {
  deleting: false,
  selecting: true,
  copying: false,
  adding: false,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  multiEditing: true,
  export: false,
  columns: [
    {
      title: '№',
      dataIndex: VehicleTableColumnData.ID,
      key: 'ID',

      active: false,

      sorting: 7,
      ellipsis: true,

      type: CellType.Input
    },
    {
      title: 'Название ТС',
      dataIndex: VehicleTableColumnData.Name,
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Нарушения БДД',
      dataIndex: VehicleTableColumnData.BDD,
      key: 'BDD',
      editable: true,
      active: true,
      type: CellType.SelectMultiple,
      width: 150,
      ellipsis: true,

      fieldNames: BASE_FIELD_NAMES,

      options: BDD_VARIANTS,
      sorting: 5,
      filtering: 'array'
    },
    {
      title: 'Выезд из стартовой точки',
      dataIndex: VehicleTableColumnData.StartPointDelta,
      key: 'StartPointDelta',
      editable: true,
      active: true,
      type: CellType.Select,
      options: POINT_SERVICE_VARIANTS,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Прибытие на точки',
      dataIndex: VehicleTableColumnData.NextPointsArrivalDelta,
      key: 'NextPointsArrivalDelta',
      editable: true,
      active: true,
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,

      options: POINT_SERVICE_VARIANTS,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Убытие из точек',
      dataIndex: VehicleTableColumnData.NextPointsDepartureDelta,
      key: 'NextPointsDepartureDelta',
      editable: true,
      active: true,
      type: CellType.Select,
      options: POINT_SERVICE_VARIANTS,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Скорость движения\nпо маршруту',
      dataIndex: VehicleTableColumnData.SpeedDelta,
      key: 'SpeedDelta',
      active: true,

      editable: true,
      sorting: 1,
      width: 120,
      ellipsis: true,
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,

      options: [
        {
          value: '0',
          label: 'Точно по графику'
        },
        {
          value: '10',
          label: 'Быстрее на 10 км/ч'
        },
        {
          value: '-10',
          label: 'Медленнее на 10 км/ч'
        }
      ]
    },
    {
      title: 'Нарушение\nпоследовательности',
      dataIndex: VehicleTableColumnData.Desequence,
      key: 'Desequence',
      active: true,
      sorting: 1,

      editable: true,
      width: 100,
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,

      options: [
        {
          value: '0',
          label: 'Как в плане'
        },
        {
          value: '2',
          label: 'Поменять местами 2 точки'
        }
      ]
    }
  ]
};
