import { Dictionary, ID, IndexedArray } from '@common/types';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { isEmpty } from 'lodash';
import { getDefaultLabelByDictionary } from '@common/utils';

export const createRecordDictionaryEnumField = <R extends { ID: ID; Name: string | null }>(
  record: Array<R> | R | IndexedArray<R> | null | undefined,
  dictionary?: Dictionary
): [] | Record<ID, string | null> => {
  const enumField = (checkAndCreateArrayFromObject(record) as Array<R>).reduce((prev: Record<ID, string | null>, { ID, Name }) => {
    prev[ID] = Name ?? getDefaultLabelByDictionary(ID, dictionary);
    return prev;
  }, {});
  return isEmpty(enumField) ? [] : enumField;
};
