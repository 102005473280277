import { ILSResult } from '@components/feedback/result';
import { ILSButton } from '@components/index';
import { useHistory } from 'react-router';
import { ModulePath } from '@common/types';
import {
  ANALYTICS_DOUBLE_ROLE_WARNING,
  ANALYTICS_GO_TO_ACCOUNT,
  ANALYTICS_NO_ROLE_WARNING
} from '@modules/analytics/common/constants/warnings';
import { FC } from 'react';
import { useAppSelector } from '@core/hooks';

export const AnalyticsRoleWarning: FC = ({ children }) => {
  const history = useHistory();
  const roles: Array<string> | undefined = useAppSelector((store) => store.Auth?.user?.Account?.AccountRole);
  const rolesCount = roles?.length || 0;
  const onClick = () => {
    history.push(`${ModulePath.Account}/account`);
  };
  return (
    <>
      {rolesCount !== 1 ? (
        <ILSResult
          title={rolesCount ? ANALYTICS_DOUBLE_ROLE_WARNING : ANALYTICS_NO_ROLE_WARNING}
          status="warning"
          extra={<ILSButton onClick={onClick}>{ANALYTICS_GO_TO_ACCOUNT}</ILSButton>}
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
