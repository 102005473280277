import { TenderFormTableField } from '@modules/tender-platform/constants/content';
import { isEmpty } from 'lodash';

export const validateCustomFieldValue = ({ value, field }: { value: any; field: TenderFormTableField }) => {
  if (field === TenderFormTableField.StartPrice) {
    return value <= 0;
  } else if (typeof value === 'number') {
    return value < 0;
  }
  return isEmpty(value);
};
