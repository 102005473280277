import { AuctionFormField, AuctionFormValues, TenderOfferFormItem } from '@modules/tender-platform/children/tender-search/constants/offer';
import { AuctionInfo, Tender, TenderLot, TenderStatus } from '@modules/tender-platform/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { CurrentTender } from '@modules/tender-platform/types/store';
import moment from 'moment';
import { SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';
import { TENDER_ENDED_STATUSES } from '../constants/tender';
import { isEmpty } from 'lodash';
import { OVERED_AUCTION_TENDERS } from '@modules/tender-platform/children/tender-search/selectors/tenders-by-status';

export const auctionToForm = (
  tender: CurrentTender,
  offerVehicle: any[],
  lot?: TenderLot,
  offer?: TenderOffer
): { initialValues: AuctionFormValues; info: AuctionInfo } => {
  const { TenderOfferCurrent, OfferEndDT } = tender as Tender;
  const { MinPrice = 0, StartPrice = 0 } = lot ?? { MinPrice: 0, StartPrice: 0 };
  const currentPrice = TenderOfferCurrent?.Price;
  const endTime = OfferEndDT * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  const initialValues = {
    [TenderFormField.StartPrice]: StartPrice,
    [TenderFormField.MinPrice]: Number(MinPrice),
    [AuctionFormField.CurrentPrice]: currentPrice ?? StartPrice ?? 0,
    [AuctionFormField.MyPrice]: 0,
    [TenderOfferFormItem.Price]: undefined
  };
  // Текущее предложение – лучшее (лучшее – это минимальное)
  const isBestPrice = compareAsString(currentPrice, MinPrice);
  const info = {
    isOver: moment(endTime).isBefore(moment()) || OVERED_AUCTION_TENDERS.includes(tender?.StatusConfig?.Alias as TenderStatus),
    canChangeOffer: !isBestPrice,
    endTime,
    isOfferMine: false,
    isBestPrice,
    isMyAndBest: false,
    accepted: Boolean(offer?.Accepted)
  };

  // Если мы не делали предложений – этих данных достаточно
  if (isEmpty(offer)) return { initialValues, info };
  // Мы делали предложение:
  const { Price } = offer;
  // Текущая цена - наша
  const isOfferMine = compareAsString(Price, currentPrice);
  // Текущая цена наша и лучшая
  const isMyAndBest = isBestPrice && isOfferMine;

  // Можно менять предложение если:
  // 1. Мы не делали предложение и другая цена не равна минимальной
  // 2. Мы делали предложение и другая цена не равна минимальной
  // 3. Мы делали предложение, наша цена не минимальная
  // 4. Аукцион не завершен и не на исполнении
  // 5. Аукцион не отменен
  const canChangeOffer = (!isMyAndBest || !isBestPrice) && !TENDER_ENDED_STATUSES.includes(tender?.StatusConfig.Alias);
  return {
    initialValues: {
      ...initialValues,
      [AuctionFormField.MyPrice]: Price,
      [TenderOfferFormItem.TenderOffer]: {
        ...offer,
        VehicleID: offerVehicle
      }
    },
    info: {
      ...info,
      canChangeOffer,
      isOfferMine,
      isBestPrice,
      isMyAndBest
    }
  };
};
