import React, { FC, useContext, useEffect } from 'react';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { forbiddenZonesSelector } from '@modules/road-graph/route-test/selectors';
import { UPDATE_INTERVAL } from '@common/constants/general';
import { roadGraphGetForbiddenZonesRoutine } from '@modules/road-graph/route-test/actions';
import ILSRoadGraphTestPermitZonesMenu from '@modules/road-graph/route-test/components/forbidden-zones-menu';
import ILSRoadGraphTestPermitZonesButton from '@modules/road-graph/route-test/components/buttons/forbidden-zones';

const ILSRoadGraphTestPermitZonesContainer: FC = () => {
  const { showZones, setShowZones } = useContext(RoadGraphContext) ?? {};
  const forbiddenZones = useAppSelector(forbiddenZonesSelector);
  const data = forbiddenZones?.data;
  const dispatch = useAppDispatch();

  //загрузка зон ограничения
  const zonesLoad = () => {
    const lastUpdate = forbiddenZones?.lastUpdate;
    const checkInterval = Date.now() - parseInt(UPDATE_INTERVAL);
    //проверяем время последнего обновления и запрашиваем данные, если интервал пройден
    if (!lastUpdate || checkInterval > lastUpdate) {
      dispatch(roadGraphGetForbiddenZonesRoutine());
    }
  };
  useEffect(() => {
    zonesLoad();
  }, []);

  const openMenu = () => {
    if (!setShowZones || !showZones) return;
    setShowZones({
      ...showZones,
      show: true
    });
  };
  const closeMenu = () => {
    if (!setShowZones || !showZones) return;
    setShowZones({
      ...showZones,
      show: false
    });
  };
  const onSelect = (checked: boolean, id: number) => {
    if (!setShowZones || !showZones) return;
    let selectedZones = showZones.selectedZones.slice();
    if (checked) {
      selectedZones.push(id);
    } else {
      selectedZones = selectedZones.filter((s) => s !== id);
    }
    setShowZones({
      ...showZones,
      selectedZones: selectedZones
    });
  };

  return (
    <>
      {showZones?.show ? (
        <ILSRoadGraphTestPermitZonesMenu data={data} closeMenu={closeMenu} onSelect={onSelect} />
      ) : (
        <ILSRoadGraphTestPermitZonesButton openMenu={openMenu} />
      )}
    </>
  );
};

export default ILSRoadGraphTestPermitZonesContainer;
