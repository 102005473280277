import { int2color } from '@common/decorators/color';
import {
  CatalogTotalCount,
  DepotType,
  DepotTypeId,
  Dictionary,
  ForbiddenZone,
  IndexedArray,
  Layer,
  LayerType,
  MarkerPlanning,
  PolygonPlanning,
  ZoneType
} from '@common/types';
import { getLatLngExpression } from '@common/utils';
import {
  catalogTotalCountSelector,
  projectCrossDockSelector,
  projectDepotSelector,
  projectForbiddenZoneSelector,
  projectZoneSelector
} from '@modules/planning/children/by-date/selectors/project';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const layerCrossDockSelector = createSelector(projectCrossDockSelector, (CrossDock) => {
  if (isEmpty(CrossDock)) return [] as PolygonPlanning[];
  return Object.values(CrossDock).reduce((acc: PolygonPlanning[], cd) => {
    if (cd.BorderHash) {
      acc.push({
        ID: cd.ID,
        Name: cd.Name,
        coords: cd.BorderHash,
        color: int2color(cd.Color)
      });
    }
    return acc;
  }, []);
});

export const layerDepotSelector = createSelector(projectDepotSelector, (Depot) => {
  const loadDepotMarkers: MarkerPlanning[] = [],
    clientMarkers: MarkerPlanning[] = [],
    storageMarkers: MarkerPlanning[] = [];
  const result = { loadDepotMarkers, clientMarkers, storageMarkers };
  if (isEmpty(Depot)) return result;

  Object.values(Depot).forEach((depot) => {
    const marker: MarkerPlanning = {
      ID: depot.ID,
      coords: getLatLngExpression(depot.LatLon ?? []),
      pointData: depot['pointData']
    };

    if (depot && depot.DepotTypeID) {
      if (depot.DepotTypeID === DepotTypeId.Client) {
        clientMarkers.push({
          ...marker,
          type: DepotType.Client
        });
      }
      if (depot.DepotTypeID === DepotTypeId.Storage) {
        storageMarkers.push({
          ...marker,
          type: DepotType.Storage
        });
      }
      if (depot.DepotTypeID === DepotTypeId.LoadDepot) {
        loadDepotMarkers.push({
          ...marker,
          type: DepotType.Load
        });
      }
    }
  });
  return result;
});

export const layerForbiddenZoneSelector = createSelector(projectForbiddenZoneSelector, (ForbiddenZones) => {
  if (isEmpty(ForbiddenZones)) return [] as PolygonPlanning[];
  return Object.values(ForbiddenZones as IndexedArray<ForbiddenZone>).reduce((acc: PolygonPlanning[], fz) => {
    if (fz.Points) {
      acc.push({
        ID: fz.ID,
        Name: fz.Name,
        coords: fz.Points,
        color: int2color(fz.Color ? fz.Color : 0)
      });
    }
    return acc;
  }, []);
});
export const layerZoneSelector = createSelector(projectZoneSelector, (Zone) => {
  const deliveryZone: PolygonPlanning[] = [],
    surveillanceZone: PolygonPlanning[] = [];
  const result = { deliveryZone, surveillanceZone };
  if (isEmpty(Zone)) return result;
  Object.values(Zone).forEach((fz) => {
    if (fz.BorderHash) {
      if (fz.Type === ZoneType.Delivery) {
        deliveryZone.push({
          ID: fz.ID,
          Name: fz.Name,
          coords: fz.BorderHash,
          color: int2color(fz.Color ? fz.Color : 0)
        });
      } else if (fz.Type === ZoneType.Surveillance) {
        surveillanceZone.push({
          ID: fz.ID,
          Name: fz.Name,
          coords: fz.BorderHash,
          color: int2color(fz.Color ? fz.Color : 0)
        });
      }
    }
  });
  return result;
});

type ArgMapLayersDecorator = {
  storageMarkers: MarkerPlanning[];
  loadDepotMarkers: MarkerPlanning[];
  clientMarkers: MarkerPlanning[];
  deliveryZone: PolygonPlanning[];
  surveillanceZone: PolygonPlanning[];
  crossDock: PolygonPlanning[];
  forbiddenZone: PolygonPlanning[];
  catalogCount?: CatalogTotalCount;
};

const isDisabledLayer = ({ catalogCount, dictionary }: { dictionary: Dictionary } & Pick<ArgMapLayersDecorator, 'catalogCount'>) =>
  catalogCount?.[dictionary] === 0;

export const MapLayersDecorator = ({
  clientMarkers,
  crossDock,
  forbiddenZone,
  deliveryZone,
  loadDepotMarkers,
  storageMarkers,
  surveillanceZone,
  catalogCount
}: ArgMapLayersDecorator): IndexedArray<Layer> => {
  return {
    1: {
      ID: 1,
      Name: 'Склады',
      dictionary: Dictionary.Depot,
      active: false,
      disabled: isDisabledLayer({ dictionary: Dictionary.Depot, catalogCount }),
      type: LayerType.Marker,
      data: storageMarkers
    },
    2: {
      ID: 2,
      Name: 'Зоны доставки',
      dictionary: Dictionary.Zone,
      disabled: isDisabledLayer({ dictionary: Dictionary.Zone, catalogCount }),
      active: false,
      type: LayerType.Polygon,
      data: deliveryZone
    },
    3: {
      ID: 3,
      Name: 'Зоны кросс-докинга',
      dictionary: Dictionary.CrossDock,
      disabled: isDisabledLayer({ dictionary: Dictionary.CrossDock, catalogCount }),
      active: false,
      type: LayerType.Polygon,
      data: crossDock
    },
    4: {
      ID: 4,
      Name: 'Зоны ограничения',
      dictionary: Dictionary.ForbiddenZone,
      disabled: isDisabledLayer({ dictionary: Dictionary.ForbiddenZone, catalogCount }),
      active: false,
      type: LayerType.Polygon,
      data: forbiddenZone
    },
    5: {
      ID: 5,
      Name: 'Клиенты',
      dictionary: Dictionary.Depot,
      disabled: isDisabledLayer({ dictionary: Dictionary.Depot, catalogCount }),
      active: false,
      type: LayerType.Marker,
      data: clientMarkers
    },
    6: {
      ID: 6,
      Name: 'Точки сбора',
      dictionary: Dictionary.Depot,
      disabled: isDisabledLayer({ dictionary: Dictionary.Depot, catalogCount }),
      active: false,
      type: LayerType.Marker,
      data: loadDepotMarkers
    },
    7: {
      ID: 7,
      Name: 'Зоны наблюдения',
      dictionary: Dictionary.Zone,
      disabled: isDisabledLayer({ dictionary: Dictionary.Zone, catalogCount }),
      active: false,
      type: LayerType.Polygon,
      data: surveillanceZone
    }
  };
};

export const layerDataSelector = createSelector(
  layerCrossDockSelector,
  layerDepotSelector,
  layerForbiddenZoneSelector,
  layerZoneSelector,
  catalogTotalCountSelector,
  (crossDock, { clientMarkers, loadDepotMarkers, storageMarkers }, forbiddenZone, { deliveryZone, surveillanceZone }, catalogCount) => {
    return MapLayersDecorator({
      crossDock,
      clientMarkers,
      loadDepotMarkers,
      storageMarkers,
      forbiddenZone,
      deliveryZone,
      surveillanceZone,
      catalogCount
    });
  }
);
