import { TableConfig } from '@common/types';
import { storedConfigToTableConfig } from '@core/utils/table/table-config-transforms';
import { ITableStoreConfig } from '@core/types/table-custom';

/**
 * Объединение типового конфига таблицы и сохраненного
 */
export const mergeTableConfigs = (config: TableConfig, storedConfig?: ITableStoreConfig | null): TableConfig => {
  if (!storedConfig) return config;

  const savedConfig = storedConfigToTableConfig(storedConfig, config);

  const childConfigs = Object.keys(config?.childConfigs || {}).reduce((acc, curr) => {
    if (config?.childConfigs?.[curr]) acc[curr] = mergeTableConfigs(config.childConfigs[curr], storedConfig?.childConfigs?.[curr]);
    return acc;
  }, {});

  const hasChild = Object.keys(childConfigs).length !== 0;

  return { ...config, ...savedConfig, ...(hasChild && { childConfigs }) };
};
