import { tableConfigSaveSettingsRoutine } from '@modules/settings/actions';
import { TableConfigSaveTrigger } from '@modules/settings/types/table-config';
import { useDispatch } from 'react-redux';

export const useTableSettings = () => {
  const dispatch = useDispatch();
  const handleTableSettings = (trigger: TableConfigSaveTrigger) => {
    dispatch(tableConfigSaveSettingsRoutine({ trigger }));
  };
  return { handleTableSettings };
};
