import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ProjectAPI } from '@common/api/common';
import { ProjectCreateValues } from '@common/types';
import { copyOrdersToProjectRoutine } from '@modules/planning/children/by-date/actions';
import { createPlanRoutine, getProjectsRoutine, planningCreateProjectRoutine } from '@modules/planning/actions';
import { getStartEndMonthDays } from '@common/utils/helpers/date-time/date-time';
import { FileData } from '@modules/planning/children/data/types/data';
import { dataImportRoutine } from '@modules/planning/children/data/actions';
import moment from 'moment';
import { EMPTY_STRING } from '@common/constants';

export function* planningCreateProjectWorker(action: PayloadAction<ProjectCreateValues & { files?: FileData }>) {
  const { request, success, failure, fulfill } = planningCreateProjectRoutine;
  const { Name, Start, planID, files } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(ProjectAPI.create, { Name, Start });

    const [project] = data;

    if (planID) {
      yield put(copyOrdersToProjectRoutine.trigger({ planID, project }));
    }

    if (project.ID) {
      yield put(createPlanRoutine.trigger({ projectID: project.ID, name: 'План', getPlan: false }));
    }

    if (files) {
      yield put(dataImportRoutine.trigger({ projectID: project.ID, files }));
    } else {
      yield put(success());
    }

    /** запрос на проекты должен выполняться даже при импорте, т.к.
     при переходе в планирование у пользователя неактуальная информация в модальном окне выбора проекта */
    const { Start: projectStart } = project;
    const dates = getStartEndMonthDays(moment.unix(Number(projectStart)));
    if (dates) {
      const [from, till] = dates;
      if (from && till) {
        yield put(getProjectsRoutine({ from, till, message: EMPTY_STRING }));
      }
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningCreateProjectWatcher() {
  yield takeLatest(planningCreateProjectRoutine.trigger, planningCreateProjectWorker);
}
