import { Dictionary } from '@common/types';
import { SchemeInfo, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types/scheme';
import { MenuForSchemes } from '@modules/planning/children/scheme-editor/constants';
import { SchemeAction } from '@modules/planning/children/scheme-editor/types/actions';
import { IILSSelectProps } from '@components/data-entry/select/types';

export interface RecordType extends SchemeInfo {
  key: string;
  options: IILSSelectProps['options'];
  typeContainer: TypeContainer;
}

export type SchemeEditorTransferKeys = Array<string>;
export type MenuSchemes = Partial<Record<MenuForSchemes, boolean>>;
export type MenuSchemesSwitcher = [string, boolean][];
export type SchemesSwitcher = Record<SchemeSwitchDictionary | Dictionary.Intro | Dictionary.Options, boolean>;
export type SchemaSwitcherEntries = Array<[SchemeSwitchDictionary, boolean]>;

export type SchemeEditorDefaultMessage = Record<
  SchemeAction,
  //TODO это повторяется во всех notifier, нужно рефакторить: вынести в common
  {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  }
>;

export enum TypeContainer {
  Current = 'current',
  Max = 'max'
}
