import { useState } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useModal } from '@common/hooks/components/use-modal';
import { PaymentModalType } from '@common/feature/payment/types/general';
import { useLoadCurrentBalance } from '@common/feature/payment/hooks/use-current-balance';

interface ReturnValues {
  // текуший баланс - отформатированная строка 
  balance: string;
  // состояние загрузки текущего баланса 
  balanceIsFetching: boolean;
  // состояние отображаение модального окна 
  visible: boolean;
  // тип модального окна
  modalType: PaymentModalType;
  // колбек, который вызывается при клике на элемент меню, открывает определенное модальное окно
  handleClickMenuItem(menuInfo: MenuInfo): void;
  // колбек, который открывает или закрывает модальное окно
  handleShowModal(): void;
  // колбек, которые открывает модальное окно для отображения счета
  openInvoiceViewer(): void;
}

export const usePaymentContainer = (): ReturnValues => {
  const { visible, handleShow } = useModal();
  const [modalType, setModalType] = useState<PaymentModalType>(PaymentModalType.PayWithCard);

  const { balance, balanceIsFetching } = useLoadCurrentBalance();

  const handleClickMenuItem = (menuInfo: MenuInfo) => {
    handleShow();
    setModalType(menuInfo.key as PaymentModalType);
  };

  const openInvoiceViewer = () => {
    setModalType(PaymentModalType.InvoiceViewer);
  };

  return {
    balance,
    balanceIsFetching,
    visible,
    modalType,
    handleClickMenuItem,
    handleShowModal: handleShow,
    openInvoiceViewer
  };
};

