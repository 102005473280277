import { ILSSVG } from '@common/components/custom/icons/svg';
import { Currency, DASH, JoinChar, SPACE } from '@common/constants';
import { VEHICLE_LOAD_BY_TYPE } from '@modules/orders-and-trips/constants/form';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { DecoratedTrips, TripStatusTitle } from '@modules/orders-and-trips/types/trip';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { FC, memo } from 'react';

//TODO убрать моки и сделать типы как только будет готов бэк
interface IProps {
  trip: DecoratedTrips[number];
  vehicleTypes: VehicleType | null;
}

export const TripDetails: FC<IProps> = memo(({ trip, vehicleTypes }) => {
  const tripDetails = trip.details;
  const price = tripDetails?.price ? Number(tripDetails?.price).toLocaleString('ru') + JoinChar.Space + Currency.RUB : DASH;
  return (
    <div className="trip-card-details details">
      <div className="details-distance distance">
        <div className="distance-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.TRIP} />
        </div>
        <div className="distance-length">Только туда {tripDetails.distance} км</div>
      </div>
      <div className="details-vehicle vehicle">
        <div className="vehycle-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.VEHICLE} />
        </div>
        <div className="vehycle-type">Тип ТС ({vehicleTypes?.[tripDetails?.vehicleType]?.Name ?? 'не определен'})</div>
      </div>
      <div className="details-loading loading">
        <div className="loading-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.DOWNLOAD_TYPE} />
        </div>
        <div className="loading-type">{VEHICLE_LOAD_BY_TYPE[tripDetails?.loadingType]}</div>
      </div>
      <div className="details-mass tonage">
        <div className="tonage-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.MASS} />
        </div>
        <div className="tonage-quantity">{tripDetails.mass} кг</div>
      </div>
      <div className="details-volume volume">
        <div className="volume-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.VOLUME} />
        </div>
        <div className="tonage-quantity">{tripDetails.volume} м3</div>
      </div>
      <div className="details-trip-status trip-status">
        <div className="trip-status-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.PROGRESS} />
        </div>
        <div className="trip-status-now">
          <span>{TripStatusTitle[trip.status]}</span>
        </div>
      </div>
      <div className="details-price price">
        <div className="price-icon">
          <ILSSVG config={SVG_CONFIG} icon={SVG.PRICE} />
        </div>
        <div className="trip-status-now">
          <span>{price}</span>
        </div>
      </div>
    </div>
  );
});

