import { isEmpty, isNil } from 'lodash';
import { createSelector } from 'reselect';
import { AccountStore } from '@modules/account/types';
import { Account } from '@common/types';
import { decodeHtml } from '@common/decorators/decode-html';
import { EMPTY_CELL, EMPTY_OBJECT_DATA } from '@common/constants';
import { generateRolesSelect, getUserAvatar } from '@modules/account/utils';
import { AccountAttachmentCategory } from '../types/attachments';

export const dataSelector = createSelector(
  (state: AccountStore) => state.Account.data,
  (data) => data
);

export const initialValuesSelector = createSelector(dataSelector, (data) => {
  const initialValues = data
    ? {
        ID: data.ID,
        Name: decodeHtml(data.Name),
        City: data.Info?.City,
        FactAddress: data.Info?.FactAddress ?? EMPTY_CELL,
        YurAddress: data.Info?.YurAddress ?? EMPTY_CELL,
        Phone: data.Info?.Phone,
        BIK: data.Info?.BIK ?? EMPTY_CELL,
        Bank: data.Info?.Bank ?? EMPTY_CELL,
        BankAccount: data.Info?.BankAccount ?? EMPTY_CELL,
        INN: data?.Info?.INN ?? EMPTY_CELL,
        AccountVAT: data.Info?.AccountVAT ? Number(data.Info?.AccountVAT) : undefined,
        AccountCode: data.AccountCode ?? EMPTY_CELL,
        AccountRole: data.AccountRole,
        KPP: data?.Info?.KPP ?? EMPTY_CELL,
        CorrespondentAccount: data.Info?.CorrespondentAccount ?? EMPTY_CELL,
        OrganizationTypeID: data.Info?.OrganizationTypeID ?? EMPTY_CELL
      }
    : (EMPTY_OBJECT_DATA as Account);
  const initialAccountCode = initialValues?.AccountCode;
  //NOTE: Так как initialValues содержат data.ID (ID конкретного user),
  //они пустые только в том случае, если не записались в state
  const isLoading = isEmpty(initialValues) || isNil(initialAccountCode);
  return { initialValues, isLoading, initialAccountCode };
});

export const accountAvatarSelector = createSelector(dataSelector, (data) => {
  return data?.Avatar ?? getUserAvatar(data?.Attachments);
});

export const accountRolesSelector = createSelector(
  (state: AccountStore) => state.Account.accountRoles,
  (accountRoles) => accountRoles
);

export const accountRolesOptionsSelector = createSelector(accountRolesSelector, (accountRoles) => generateRolesSelect(accountRoles));

export const organizationTypesOptionsSelector = createSelector(
  (state: AccountStore) => state.Account.organizationTypes,
  (organizationTypes) => Object.entries(organizationTypes ?? {}).map(([key, value]) => ({ label: value, value: Number(key) }))
);

export const accountIDSelector = createSelector(
  (state: AccountStore) => state.Account.data,
  (data) => data?.ID
);

export const accountExtraAttachmentDocumentsSelector = createSelector(
  (state: AccountStore, accountAttachmentCategory: AccountAttachmentCategory) => ({
    extraAttachments: state.Account.extraAttachments,
    accountAttachmentCategory
  }),
  (data) => ({ isFetching: data.extraAttachments.isFetching, documents: data.extraAttachments[data.accountAttachmentCategory] ?? [] })
);

