import { ILSForm, ILSFormItem } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import {
  FORM_FIELD_DEFAULT_PROPS,
  TRIP_FOR_TENDER_FORM_FIELD_NAMES,
  TRIP_FOR_TENDER_FORM_RULES
} from '@modules/orders-and-trips/constants/form';
import { FormLabels, FormName, FormPlaceholders, TimeLineLabels, TimeLinePoints } from '@modules/orders-and-trips/types/form';
import { getFormField } from '@modules/orders-and-trips/helpers/get-form-field';
import { FormInstance } from 'antd/es';
import { FC } from 'react';
import { TripToTenderSubmit } from './blocks/trip-to-tender-submit';
import { ILSTimeline, ILSTimelineItem } from '@common/components/data-display/timeline';

//TODO типизировать пропсы
interface IProps {
  onFinish: any;
  form: FormInstance;
  activeTripID: string | number;
}

const mockTrip = [
  { type: TimeLinePoints.Load, date: '20.08.2023 18:00', address: 'г.Москва, ул.1905 года, д.68' },
  { type: TimeLinePoints.Unload, date: '21.08.2023 14:00', address: 'г.Тверь, Заволжский р-н, д.68' },
  { type: TimeLinePoints.Unload, date: '23.08.2023 17:00', address: 'г.Санкт-Петербург, Лиговский пр-т' }
];

export const TripToTenderFormComponent: FC<IProps> = ({ onFinish, form, activeTripID }) => {
  return (
    <ILSForm className="tender-trip-form" name={FormName.TripToTender} onFinish={onFinish} form={form}>
      <h2>{`Размещение рейса №${activeTripID} на тендерной площадке`}</h2>
      {TRIP_FOR_TENDER_FORM_FIELD_NAMES.map((fieldName) => {
        const { Component, InputType } = getFormField({ fieldName });
        const props = FORM_FIELD_DEFAULT_PROPS[InputType];
        return (
          <ILSFormItem name={fieldName} label={FormLabels[fieldName]} rules={TRIP_FOR_TENDER_FORM_RULES[fieldName]}>
            <Component {...(props as any)} placeholder={FormPlaceholders[fieldName] ?? EMPTY_STRING} />
          </ILSFormItem>
        );
      })}

      <h2>Маршрут</h2>
      <ILSTimeline className="order-action-form-timeline" mode={'left'}>
        {mockTrip.map((point) => {
          return (
            <ILSTimelineItem label={TimeLineLabels[point.type]}>
              Дата: {point.date}
              <br />
              Адрес: {point.address}
            </ILSTimelineItem>
          );
        })}
      </ILSTimeline>
      <TripToTenderSubmit form={FormName.TripToTender} />
    </ILSForm>
  );
};
