import { isEmpty, isNil, isString } from 'lodash';
import { getDeletedRowIDsInGroupedDataSource } from '@modules/catalog/helpers/get-deleted-row-ids-in-grouped-datasource';
import { catalogRestoreRoutine } from '@modules/catalog/actions';
import { useCatalogTableDataSourceSelector } from '@modules/catalog/hooks/use-catalog-table-data-source-selector';
import { Dispatch } from 'redux';
import { Dictionary, Keys } from '@common/types';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';

/**
 * Функция восстановления строк в таблице раздела Справочники.
 *
 * Если восстановление по клику на кнопку "Восстановить" в шапке таблицы,
 * ids - это набор ids для удаления, без id группирующих строк.
 *
 * Если клик по кнопке восстановления в строке, то в ids может быть:
 * id элемента
 * id группирующей строки (тогда необходимо найти все вложенные элементы).
 */
export const restoreTableRows =
  ({
    dataSourceForTable,
    dispatch,
    dictionary,
    saveDictionary
  }: {
    dataSourceForTable: ReturnType<typeof useCatalogTableDataSourceSelector>['dataSourceForTable'];
    dispatch: Dispatch;
    dictionary: Dictionary;
    saveDictionary: IPropsILSCatalogTable['saveDictionary'];
  }) =>
  (ids: Keys | null) => {
    const groupId = isString(ids?.[0]) ? ids?.[0] : null;
    const idsFromGroup = getDeletedRowIDsInGroupedDataSource(dataSourceForTable, groupId);

    if (!isEmpty(idsFromGroup) && !isNil(idsFromGroup)) {
      dispatch(catalogRestoreRoutine({ dictionary, ids: idsFromGroup, saveDictionary }));
    } else {
      dispatch(catalogRestoreRoutine({ dictionary, ids, saveDictionary }));
    }
  };
