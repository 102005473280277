import { ChangeEvent, FC } from 'react';
import { ExternalParamsConfig } from '../types/external';
import { ParamsType, Provider } from '@common/types';
import { ILSCheckbox, ILSInputNumber, ILSRadio, ILSRadioGroup, ILSTooltip } from '@common/components';
import { Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { renderSlider } from '@modules/planning/children/params/helpers/render-slider';
import { Param } from '@modules/planning/types/api';

interface IProps {
  configList: Record<Provider, ExternalParamsConfig>;
  configParams: Partial<Record<Param, ExternalParamsConfig>>;

  onChange(key: string, newValue: any): void;
}

const CONFIG_IS_EMPTY = 'Дополнительные параметры в разработке';

export const ILSPlanningExternalConfigsComponent: FC<IProps> = ({ onChange, configList, configParams }) => {
  return (
    <div className="planning-params-external-config-list">
      {(Object.keys(configList ?? {}) as Array<Provider>)?.map((configKey) => {
        const configItem = configList[configKey];
        switch (configItem.type) {
          case ParamsType.Slider:
            return renderSlider({ configItem, configParams, onChange, configKey });
          case ParamsType.Radio:
            return (
              <div key={configKey}>
                <Typography.Text className="w-100 d-flex m-1" title={configItem.label}>
                  {configItem.label}
                </Typography.Text>
                <ILSRadioGroup
                  defaultValue={configParams?.[configKey] ? configParams?.[configKey] : configItem.default}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(configKey, e?.target?.value)}
                >
                  <Space direction="vertical">
                    {configItem?.range?.map((itemRadio) => (
                      <ILSTooltip key={itemRadio.value} title={itemRadio.tip}>
                        <ILSRadio value={itemRadio.value}>{itemRadio.label}</ILSRadio>
                      </ILSTooltip>
                    ))}
                  </Space>
                </ILSRadioGroup>
              </div>
            );
          case ParamsType.Checkbox:
            return (
              <div key={configKey}>
                <ILSCheckbox
                  checked={configParams?.[configKey] ? configParams?.[configKey] : configItem.default}
                  onChange={(e: CheckboxChangeEvent) => {
                    onChange(configKey, Number(e.target.checked));
                  }}
                >
                  {configItem.label}
                </ILSCheckbox>
              </div>
            );
          case ParamsType.Number:
            return (
              <div key={configKey} className="pt-3">
                <Typography.Text title={configItem.label}>
                  {configItem.label}: {configParams?.[configKey] ? configParams?.[configKey] : configItem.default}
                </Typography.Text>
                <div className="w-25">
                  <ILSInputNumber
                    onChange={(value) => {
                      onChange(configKey, value);
                    }}
                    defaultValue={configParams?.[configKey] ? configParams?.[configKey] : configItem.default}
                    min={configItem?.range?.[0]}
                    max={configItem?.range?.[1]}
                    step={configItem?.range?.[2]}
                  />
                </div>
              </div>
            );
          default:
            return null;
        }
      }) ?? CONFIG_IS_EMPTY}
    </div>
  );
};
