import { FC, useEffect, useState } from 'react';
import { ILSCollapse, ILSCollapsePanel, ILSSpin, ILSTitle } from '@components/index';
import { confSelector, dataSelector, savingSelector } from '../selectors';
import '../styles.less';
import { controlConfigRoutine, getConfigsRoutine, getConfigTemplateRoutine, updateExternalConfigRoutine } from '../actions';
import ILSParamsCollapseComponent from '@modules/planning/children/params/components/collapse';
import { useAppSelector } from '@core/hooks';
import { IValue } from '@modules/planning/children/params/types/reducer';
import ILSPlanningParamsImportExportContainer from '@modules/planning/children/params/containers/import-export-container';
import { IData } from '@modules/planning/children/params/types/store';
import { deepEqual } from 'fast-equals';
import { ExpandIconPosition } from '@common/components/data-display/collapse/types';
import { ILSPlanningExternalConfigsComponent } from '@modules/planning/children/params/components/external-config';
import { Provider } from '@common/types';
import { UPDATE_INTERVAL } from '@common/constants';
import { planningGetConfigsRoutine } from '@modules/planning/children/by-date/actions';
//TODO почему селектор из другого модуля?
import { defaultPlannerConfigsSelector } from '@modules/planning/children/by-date/selectors/config';
import { useDispatch } from 'react-redux';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { ControlConfigOperation } from '@modules/planning/children/params/types/api';
import CustomScroll from 'react-customscroll';
import { getBlockHeightByChildrenElements } from '@common/utils/general/get-block-height-by-children-elements';
import { ParamsSaveButtons } from '../components/params-save-buttons';
import { planningParamsSelector } from '@modules/planning/selectors';

const ILSPlanningParams: FC = () => {
  const data = useAppSelector(dataSelector);
  const configurations = useAppSelector(confSelector);
  const isSaving = useAppSelector(savingSelector);
  const externalConf = useAppSelector(planningParamsSelector);
  const planningConfigs = useAppSelector(defaultPlannerConfigsSelector);

  const dispatch = useDispatch();
  const planningConfigsData = planningConfigs?.data;
  const dataSource = createArrayFromIndexedArray(planningConfigsData);
  const [create, setCreate] = useState<boolean>(false);
  const [selectedConfigs, setSelectedConfigs] = useState<IData[]>([]);

  const onCreate = () => {
    setCreate(true);
  };

  const handleCreate = () => dispatch(controlConfigRoutine({ operation: ControlConfigOperation.Create }));

  const handleSave = (values: IValue) => dispatch(controlConfigRoutine({ operation: ControlConfigOperation.Update, values }));

  const handleDelete = (ConfigID: string[]) => dispatch(controlConfigRoutine({ operation: ControlConfigOperation.Delete, ConfigID }));

  //Получение Выделенных или активных элементов
  const handleSelectedElements = (rowData: any | Array<any>) => {
    let configs: IData[] = [];
    if (rowData && rowData.length > 0) {
      rowData.forEach((row: IData) => {
        configs.push(row);
      });
    }
    if (!deepEqual(configs, selectedConfigs)) {
      setSelectedConfigs(configs);
    }
  };

  const onChangeParams = (provider: Provider, key: string, value: any) =>
    dispatch(
      updateExternalConfigRoutine({
        provider,
        key,
        value
      })
    );

  const handleGetConfigs = () => dispatch(planningGetConfigsRoutine({ forceUpdate: true }));

  useEffect(() => {
    dispatch(getConfigsRoutine());
    dispatch(getConfigTemplateRoutine());
  }, []);

  useEffect(() => {
    const checkInterval = Date.now() - parseInt(UPDATE_INTERVAL);
    //проверяем время последнего обновления и запрашиваем данные, если интервал пройден
    if (!data || !planningConfigs?.lastUpdate || checkInterval > planningConfigs.lastUpdate) {
      handleGetConfigs();
    }
  }, []);

  return (
    <div className="planning-params-wrapper">
      <ParamsSaveButtons />
      <CustomScroll>
        <ILSCollapse
          className="main-collapse planning-params-main p-2"
          bordered={false}
          accordion
          expandIconPosition={ExpandIconPosition.Right}
        >
          <ILSCollapsePanel key={'PlanningParams'} header={'Настройка конфигураций для планирования внутренними сервисами'}>
            <ILSTitle title={create ? 'Сохранить конфигурацию настроек' : 'Выбрать конфигурацию настроек'} />
            <ILSSpin spinning={isSaving}>
              <div className="planning-params bg-white p-3">
                <ILSPlanningParamsImportExportContainer selectedConfigs={selectedConfigs} />
                <ILSParamsCollapseComponent
                  data={data}
                  create={create}
                  setCreate={setCreate}
                  handleCreate={handleCreate}
                  handleSave={handleSave}
                  handleDelete={handleDelete}
                  onCreate={onCreate}
                  configurations={configurations}
                  handleSelectedElements={handleSelectedElements}
                />
              </div>
            </ILSSpin>
          </ILSCollapsePanel>
          {Boolean(dataSource.length) &&
            dataSource.map(({ params, name, label }) => {
              return (
                <ILSCollapsePanel key={name} header={`Настройка параметров для: ${label}`}>
                  <div
                    className="planning-params-max-500"
                    style={{ height: getBlockHeightByChildrenElements({ countChilds: Object.keys(params).length }) }}
                  >
                    <CustomScroll>
                      <ILSPlanningExternalConfigsComponent
                        onChange={(key: string, value: any) => onChangeParams(name, key, value)}
                        configList={params as any}
                        configParams={externalConf?.[name]}
                      />
                    </CustomScroll>
                  </div>
                </ILSCollapsePanel>
              );
            })}
        </ILSCollapse>
      </CustomScroll>
    </div>
  );
};

export default ILSPlanningParams;

