import { ILSTable, ILSUserNotify } from '@components/index';
import { ColumnTable, DictionariesEndpoint, Notify, RoadTrainTableColumn, TableRecord, VehicleTableColumn } from '@common/types';
import { FC } from 'react';
import { generateUUID } from '@common/utils/general/uuid';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { EMPTY_STRING } from '@common/constants';
import { useSelector } from 'react-redux';
import { fetchSelector } from '@modules/planning/children/data/selectors';

export const ILSPlanningDataViewTable: FC<IDataTableProps> = ({
  dataSource,
  dictionary,
  refTable,
  refData,
  config,
  scheme,
  handleChange,
  hideEmpty,
  handleAdd,
  testId,
  record,
  isEdit,
  tableValue2Data,
  isExistRecord,
  ...props
}) => {
  const isFetching = useSelector(fetchSelector);
  const handleSave = (record: any, dataIndex: ColumnTable<TableRecord>['dataIndex'], value: string, rowIndex: number) => {
    const error = isEdit?.(record, dataIndex);
    if (error) {
      if (typeof error === 'string') ILSUserNotify(Notify.Error, error);
      return;
    }
    handleChange({
      row: record.fileIndex ?? Number(rowIndex),
      cell: dataIndex,
      value: tableValue2Data ? tableValue2Data(dataIndex, value) : value,
      file: dictionary,
      operation: DictionariesEndpoint.Update,
      key: record.key
    });
  };

  const handleDelete = (rowKeys: string[], records: Array<any>) => {
    if (!records) {
      records = dataSource.filter(({ key }) => rowKeys.includes(String(key)));
    }
    handleChange({
      file: dictionary,
      rows: records.map(({ fileIndex }) => fileIndex),
      operation: DictionariesEndpoint.DeleteRows
    });
  };

  const handleCreate = (newRow: any) => {
    const error = isExistRecord?.(record?.VehicleCode);
    if (error) {
      if (typeof error === 'string') ILSUserNotify(Notify.Error, error);
      return;
    }
    //избавляемся от мутаций
    const addRow = newRow && { ...newRow };
    //итерируемся по элементам строки, которую хотим добавить, генерируем уникальные ключи, заполняем поля данными из current record,
    const values = Object.entries(addRow).map(([cell, value]) => {
      switch (cell) {
        case RoadTrainTableColumn.RoadTrainCode:
          value = generateUUID();
          break;
        case RoadTrainTableColumn.VehicleVehicleCode:
          value = record ? record.VehicleCode : EMPTY_STRING;
          break;
        case VehicleTableColumn.VehicleCode:
          value = generateUUID();
      }
      return { cell, value: String(value) };
    });
    handleChange({ file: dictionary, values, operation: DictionariesEndpoint.Add });
  };

  return (
    <ILSTable
      dataSource={dataSource}
      {...props}
      isFetching={isFetching}
      className="editable-table"
      data-testid={testId}
      config={config}
      dictionary={dictionary}
      tableName={dictionary}
      refTable={refTable}
      refData={refData}
      handleCreate={handleCreate}
      handleSave={handleSave}
      handleDelete={handleDelete}
    />
  );
};

