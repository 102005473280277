import { createSelector } from 'reselect';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { activeTripSelector } from '@modules/orders-and-trips/selectors/trip';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const tripTimelinesSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips?.tripTimelines?.timelines,
  (timelines) => timelines
);

export const tripTimelinesIsFetchingSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips?.tripTimelines?.isFetching,
  (isFetching) => isFetching
);

export const activeTripTimelineSelector = createSelector(tripTimelinesSelector, activeTripSelector, (timelines, activeTrip) => {
  const activeTripTimeline = activeTrip?.ID ? timelines?.[activeTrip.ID] : EMPTY_ARRAY_DATA;
  return activeTripTimeline;
});

