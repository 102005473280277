import { isEmpty } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { checkCoordsModalFromRoutineYandex } from '@common/utils/get-coords-modal/get-coordinates-modal';
import { ErrorPayload } from '@common/types/general/check-coords';
import { UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';
import { ArgCheckCoord, DictionariesEndpoint, UpdateOption } from '@common/types/general';
import { getDataByAddressOrByCoords } from '@common/sagas/get-data-by-address-or-by-coords';

export function* checkCoordsYandex(
  { address, coords, ID, dictionary }: ArgCheckCoord,
  options: UpdateOption,
  errorPayload: ErrorPayload,
  updateRoutine: UnifiedRoutine<(payload?: any) => Action>
) {
  let addressByCoordsY: string | undefined, coordsByAddressY: number[] | undefined;

  if (coords?.[0] && coords?.[1]) {
    addressByCoordsY = yield call(getDataByAddressOrByCoords, errorPayload.getAddressError, coords);
  }
  if (address) {
    coordsByAddressY = yield call(getDataByAddressOrByCoords, errorPayload.getCoordinatesError, address);
  }
  const values: Record<string, any> = yield call(
    checkCoordsModalFromRoutineYandex,
    address,
    coords?.join(',') as string | undefined,
    addressByCoordsY,
    coordsByAddressY,
    options
  );
  if (!isEmpty(values) && ID && dictionary) {
    yield put(updateRoutine.trigger({ dictionary, ids: [ID], values, LatLonVerified: 1, operation: DictionariesEndpoint.Update }));
  }
}
