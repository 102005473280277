import DocumentTemplatesStore from '../types';

const initialState: DocumentTemplatesStore = {
  documentTemplates: [],
  documentSets: [],
  currentDealTemplate: {
    template: null,
    isViewTemplateModelOpen: false
  },
  isFetching: false
};

export default initialState;
