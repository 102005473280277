import { Errors, Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { takeLatest } from 'redux-saga/effects';
import { TenderPlatformAction, tenderRoutinesToNotify } from '../../actions';
import { EMPTY_STRING } from '@common/constants/general';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { DEFAULT_TENDER_MESSAGES, TenderNotifierAction } from '@modules/tender-platform/constants';

const getTenderDataNotificationMessage = <T extends string | number | symbol>(defaultMessage: { [key in T]: Object }) => {
  return (action: T, type: Notify.Loading | Notify.Success | Notify.Error, payload?: { message: string; hideSuccessMessage?: string }) => {
    if (payload?.hideSuccessMessage) return payload.hideSuccessMessage;
    return payload?.message || defaultMessage?.[action]?.[type] || EMPTY_STRING;
  };
};

const tenderNotifierWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, TenderNotifierAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderNotifierAction,
  getKeyPrefix: () => 'tender',
  getNotificationMessage: getTenderDataNotificationMessage<TenderNotifierAction>(DEFAULT_TENDER_MESSAGES)
});

const tenderNotifierErrorWorker = notifierWorkerCreator<{ error: Errors }, TenderPlatformAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderPlatformAction,
  getKeyPrefix: () => 'tender',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* tenderNotifierWatcher() {
  for (const routine of tenderRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, tenderNotifierWorker);
    yield takeLatest(routine.SUCCESS, tenderNotifierWorker);
    yield takeLatest(routine.FAILURE, tenderNotifierErrorWorker);
  }
}
