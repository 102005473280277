import { emulatorGetPlanRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Project } from '@common/types';
import { EmulatorGetPlanRequest } from '../types/api';

// Получает с бэкэнда массив автомобилей и ID плана (vehicles, planID)
function* emulatorGetPlanWorker(action: PayloadAction<EmulatorGetPlanRequest>) {
  const { request, success, failure, fulfill } = emulatorGetPlanRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    const { data }: { data: { data: Array<Project> } } = yield call(EmulatorAPI.getPlanVehicles, { planID });
    const vehicles = data.data;

    yield put(success({ vehicles, planID }));
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorGetPlanWatcher() {
  yield takeLatest(emulatorGetPlanRoutine.TRIGGER, emulatorGetPlanWorker);
}
