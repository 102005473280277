import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel, TrackerLastInfoModel, TrackGeoModel } from '@common/types/dictionaries/monitoring-vehicle';
import { SensorEventTypeText } from '@modules/monitoring/children/event-details/constants/event-text';
import { MonitoringBDDEventsStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { isEmpty, isObject } from 'lodash';

type DataToTableFunction<R> = (
  data?: IndexedArray<MonitoringBDDAndFuelDrainModel>,
  geoTrackData?: IndexedArray<IndexedArray<TrackGeoModel>>
) => Array<
  R & {
    key: string;
    time: string;
    eventName: string;
    address: string;
  }
>;

export const data2table: DataToTableFunction<
  {
    ID: number;
    key: string;
  } & Partial<TrackerLastInfoModel>
> = (vehicleData, geoTrackData) => {
  const res: ReturnType<typeof data2table> = [];

  if (!vehicleData || !Object.values(vehicleData).length) {
    return res;
  }
  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (isEmpty(vehicle?.VehicleTracker)) {
      continue;
    }

    for (const trackerIndex in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerIndex];

      if (isEmpty(tracker?.SensorMapping) || !Object.keys(tracker.SensorMapping)?.length) {
        continue;
      }

      for (const sensorMappingType in tracker.SensorMapping) {
        const sensorData = tracker.SensorMapping[sensorMappingType];

        if (sensorData && isObject(sensorData)) {
          if (!MonitoringBDDEventsStatusType.includes(+sensorMappingType)
          || !sensorData.SensorValue
          || !Object.values(sensorData.SensorValue).length) {
            continue;
          }
          for (const sensorValueIndex in sensorData.SensorValue) {
            const sensorValue = sensorData.SensorValue[sensorValueIndex];
            let rowObj = {
              key: sensorData.ID?.toString() + '#' + sensorValue.Imei + '#' + sensorValue.DT,
              ID: sensorData.ID,
              time: sensorValue.DT ? timestampToDateTimeString(sensorValue.DT) : '',
              eventName: SensorEventTypeText?.[sensorMappingType],
              address: geoTrackData?.[sensorValue.Imei]?.[sensorValue.DT]?.GeoAddress?.FullName || ''
            };

            res.push(rowObj);
          }
        }
      }
    }
  }
  return res;
};
