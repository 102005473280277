import { IPlanningByDateStore } from '@modules/planning/children/by-date/types';
import { getInitialState } from '@common/utils/helpers/store/get-initial-state';
import { Dictionary, Module, TableColumnName } from '@common/types';
import { ValidationStatus } from '../types/validate';
import { INITIAL_CURRENT_PLAN, INITIAL_INFO } from '@modules/planning/children/by-date/constants/store';

const initialPlanningByDateState: IPlanningByDateStore = {
  initial: false,
  project: null,
  activePlanID: null,
  planningCheckData: null,
  validationData: {},
  planningConfigByPlan: null,
  error: [],
  tripLink: null,
  planPoints: {
    planDataQuery: {},
    trackData: {},
    isFetching: false
  },
  useRedis: false,
  status: null,
  filters: {
    restriction: {
      IDs: []
    }
  },
  matrix: { loading: true, status: null },
  defaultPlannerConfigs: { lastUpdate: 0, loading: false },
  params: { loading: false, defaultPlannerParams: {}, lastUpdate: 0 },
  isModalSuccessfullyAdded: false,
  isMapWaitingMode: false,
  groupSwitchers: {
    [Dictionary.Order]: { [TableColumnName.TargetID]: false, [TableColumnName.SourceID]: false }
  },
  currentPlan: INITIAL_CURRENT_PLAN,
  info: INITIAL_INFO,
  selectedTableRows: {},
  userSettings: {
    table: {}
  },
  validationStatus: ValidationStatus.Stop
};

export const initialState = getInitialState<IPlanningByDateStore>(initialPlanningByDateState, Module.PlanningByDate);

