import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getTripNOrderDataRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { FormType, InitialState } from '@modules/orders-and-trips/types/general';
import { DraftTripNOrder } from '@modules/orders-and-trips/types/trip';

export const getTripNOrderDataReducer = createReducer(initialState, {
  [getTripNOrderDataRoutine.REQUEST]: (state: InitialState) => {
    state.serviceData.isFetching = true;
    return state;
  },
  [getTripNOrderDataRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: DraftTripNOrder;
      formType: FormType;
    }>
  ) => {
    const { data, formType } = action.payload ?? {};
    if (formType) {
      state.serviceData.form[formType] = data;
    } else {
      state.serviceData.form = {};
    }
    return state;
  },
  [getTripNOrderDataRoutine.FAILURE]: onFailure,
  [getTripNOrderDataRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.serviceData.isFetching = false;
    return state;
  }
});

