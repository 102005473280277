import { FC, useEffect } from 'react';
import ILSDocumentTypeSelect from '@modules/documents/components/document-type-select';
import { useAppDispatch } from '@core/hooks';
import { documentsIndexRoutine } from '@modules/documents/actions';
import { archiveDocumentsParams } from '@modules/documents/constants/document-params';

const ILSDocumentsArchive: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(documentsIndexRoutine(archiveDocumentsParams));
  }, []);

  return (
    <ILSDocumentTypeSelect />
  );
};

export default ILSDocumentsArchive;
