import CustomScroll from 'react-customscroll';
import ILSParamComponent from './param';
import { PlanningParamsProps } from '@modules/planning/children/params/types/components';
import { FC } from 'react';
import { IDataItemInnerItem } from '@modules/planning/children/params/types/store';
import classnames from 'classnames';

const ILSPlanningParamsComponent: FC<PlanningParamsProps> = (props) => {
  const { params, handleSave, isChild, type } = props;

  const onChange = (param: IDataItemInnerItem, val: string, type: string) => {
    let values = {
      [param.id]: {
        type: type,
        Value: val,
        PlanParamID: param.PlanParamID_,
        PlanConfigID: param.PlanConfigID_,
        Def: param.Def
      }
    };
    //проверка, что сохраняем только если значение изменилось
    const valueChanged = param.Def === 'FloatPenalty' ? Number(param.Value_) !== Number(val) : param.Value_ !== val;
    if (valueChanged) {
      handleSave(values);
    }
  };
  return (
    <div>
      {!isChild ? (
        <div className="mb-1 pr-2 param-header param-row">
          <div className="name">
            <div>Название</div>
          </div>
          <div className="default">
            <label className="flex-center-center">По умолчанию</label>
          </div>
          <div className="value mr-1">
            <label className="flex-center-center">Значение</label>
          </div>
        </div>
      ) : (
        <div className="pr-2">
          <div className="mb-1 pr-2 sub-param-header" dangerouslySetInnerHTML={{ __html: (params as any)?.Name_ }} />
        </div>
      )}

      <div className={classnames({ 'param-list': !isChild })}>
        <CustomScroll>
          {params?.Items?.map((p: any) => {
            if (p?.Items?.length) {
              return <ILSPlanningParamsComponent {...props} handleSave={handleSave} params={p} type={type} isChild={true} />;
            } else {
              return <ILSParamComponent {...props} key={p.id} param={p} onChange={onChange} />;
            }
          })}
        </CustomScroll>
      </div>
    </div>
  );
};

export default ILSPlanningParamsComponent;
