import { initialState } from '../store';
import reduceReducers from 'reduce-reducers';
import { userReducer } from '@modules/account/reducers/user';
import { accountReducer } from '@modules/account/reducers/account';
import { organizationTypesReducer } from '@modules/account/reducers/organization-types';
import { accountAttachmentCreateReducer } from '@modules/account/reducers/create-attachment';
import { accountAttachmentIndexReducer } from '@modules/account/reducers/get-attachments';
import { accountAttachmentDeleteReducer } from './delete-attachment';

export const accountRootReducer = reduceReducers(
  initialState,
  accountReducer,
  userReducer,
  organizationTypesReducer,
  accountAttachmentCreateReducer,
  accountAttachmentIndexReducer,
  accountAttachmentDeleteReducer
);

