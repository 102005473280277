import { VehicleOptionsBlock } from '@modules/orders-and-trips/components/forms/blocks/vehicle-options';
import { FormType } from '@modules/orders-and-trips/types/general';
import { FC } from 'react';
import { AdditionalOptionsBlock } from '@modules/orders-and-trips/components/forms/blocks/additional-options';
import { VehicleTypesOptions } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';

interface IProps {
  showForm: FormType | null;
  vehicleTypes: VehicleTypesOptions;
}

export const TransportRequirementSection: FC<IProps> = ({ showForm, vehicleTypes }) => {
  if (showForm !== FormType.Trip) return null;
  return (
    <div className="transport-requirement">
      <h2>Требования к автомобилю</h2>
      <VehicleOptionsBlock vehicleTypes={vehicleTypes} />
      <AdditionalOptionsBlock />
    </div>
  );
};
