import { call, put, takeLatest } from 'redux-saga/effects';
import { cancelTripRoutine, switchTripStatusRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { TripStatus } from '../types/trip';

function* cancelTripWorker(
  action: PayloadAction<{
    tripID: number;
    alias: string;
  }>
) {
  const { request, success, failure, fulfill } = cancelTripRoutine;
  const { tripID, alias } = action.payload ?? {};
  try {
    yield put(request());
    yield call(OrdersNTripsApi.removeOrderFromService, { tripID, alias });
    yield put(success());
    yield put(switchTripStatusRoutine.trigger({ status: TripStatus.Canceled }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* cancelTripWatcher() {
  yield takeLatest(cancelTripRoutine.trigger, cancelTripWorker);
}
