import { ILSButtonTooltip, ILSTable } from '@components/index';
import { ILSDataInfoComponentProps } from '@modules/data-validate/components/info';
import { ColumnTable, Dictionary, IndexedArray, ReferenceTablesData, TableRecord, ValidationData } from '@common/types';
import { TErrorValidationTable } from '@modules/data-validate/types/types';
import { useAppSelector } from '@core/hooks';
import { schemeSelector } from '@modules/planning/children/data/selectors';
import { ILSWindowTable } from '@common/components/data-display/table/components/ils-window';
import { failPayload2Data, getConfigForErrorTable } from '@modules/data-validate/utils';
import { useDispatch } from 'react-redux';
import { tableDataChangeRoutine } from '@modules/planning/children/data/actions';
import { ChangeDataPayload } from '@modules/planning/children/data/types/components';
import { DATA_VALIDATION_TABLES } from '@modules/data-validate/constants';
import { SetShowReference, ShowReference } from '@common/types/components/table/common';

interface ILSDataTablesComponentProps<R extends TableRecord> extends Omit<ILSDataInfoComponentProps, 'data'> {
  errorList: ValidationData[];
  type: TErrorValidationTable;
  showReference: ShowReference<R>;
  dictionary: Dictionary;
  text?: string;
  refData?: ReferenceTablesData;
  refTable?: any;
  map?: any;
  columns?: ColumnTable<R>[];
  codeField: string;
  codes: Array<string>;
  failRows?: Array<number>;
  dataSource: any;
  rowMap: IndexedArray<number>;
  setShowReference: SetShowReference<R>;
}

const ILSValidateUploadTableComponent = <R extends TableRecord>({
  handleUpdate,
  type,
  handleDelete,
  showAll,
  errorList,
  dictionary,
  map,
  codeField,
  dataSource,
  showReference,
  setShowReference,
  columns,
  codes,
  rowMap,
  failRows,
  ...props
}: ILSDataTablesComponentProps<R>): JSX.Element => {
  if (!DATA_VALIDATION_TABLES[dictionary]) {
    return (
      <div className="data-table position-relative">
        <div className={'bg-white px-3 pb-5'}>
          <ILSTable dictionary={dictionary} dataSource={errorList as any} config={{} as any} columns={columns} />
        </div>
      </div>
    );
  }

  const scheme = useAppSelector(schemeSelector);

  const dispatch = useDispatch();
  const { title, config, component: Component }: any = DATA_VALIDATION_TABLES[dictionary];

  const currentConfig = getConfigForErrorTable(config);

  const refData: ReferenceTablesData = {
    [dictionary]: {
      data: errorList
    }
  };

  const handleChange = (payload: ChangeDataPayload) => {
    dispatch(tableDataChangeRoutine(failPayload2Data(payload, rowMap)));
  };

  return (
    <div className="data-table position-relative">
      <div className={'bg-white px-3 pb-5'}>
        <ILSTable
          {...props}
          //@ts-ignore TODO why errors has so many types?
          errors={props.errors}
          refData={refData}
          refTable={DATA_VALIDATION_TABLES}
          dictionary={dictionary}
          dataSource={errorList}
          // @ts-ignore
          columns={columns}
        />
        {Component && (
          <ILSButtonTooltip
            title={'Показать ошибки в разделе ' + title}
            type="text"
            className="ml-1 open-reference"
            style={{ width: '30px' }}
            icon={'open'}
            onClick={() => setShowReference(true)}
          />
        )}
      </div>
      {Component && showReference && (
        <ILSWindowTable
          title={title}
          component={Component}
          setShow={setShowReference}
          dictionary={dictionary}
          errors={errorList}
          filterRows={codes}
          scheme={scheme[dictionary]}
          config={currentConfig}
          dataSource={dataSource}
          tableClassName="validate-errors-catalog"
          handleChange={handleChange}
          hideEmpty
        />
      )}
    </div>
  );
};

export default ILSValidateUploadTableComponent;
