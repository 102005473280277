import { isEmpty } from 'lodash';
import { JoinChar } from '@common/constants';
import { useDetailsMapInit } from '@modules/orders-and-trips/hooks/use-details-map-init';
import { useShowMapDetails } from '@modules/orders-and-trips/hooks/use-show-map-details';
import { DETAILS_CONTENT } from '@modules/orders-and-trips/types/details';

export const DetailsMap = ({ detailsContent }: { detailsContent: DETAILS_CONTENT }) => {
  const { mapLayers, Map, mapRef, mapPoints } = useDetailsMapInit({ detailsContent });
  useShowMapDetails({ Map, mapLayers, mapPoints: mapPoints.coordinates, detailsContent });

  return (
    <div>
      {!isEmpty(mapPoints.cities) && <p className="route-map__text">{mapPoints.cities.join(JoinChar.Dash)}</p>}
      <div className="map-container" ref={mapRef}>
        <div id="ils-map" className="details-map" />
      </div>
    </div>
  );
};

