import { FC, useState } from 'react';
import { ILSUserNotify } from '@common/components';
import { Dictionary, Notify } from '@common/types';
import { ILSMonitoringMapMenuComponent } from './btn-menu';
import { ILSMonitoringMapMenuBtn } from './btn';
import { LayerMenu } from '@modules/monitoring/types/layer';
import { isEmpty } from 'lodash';

interface IPropsILSMapMenuProps {
  show: boolean;
  setShow: (a: boolean) => void;
  menu: LayerMenu[] | null;
  onChange: (value: boolean | null, item?: { ID: number } | null, clear?: boolean | null) => void;
  icon: string | null;
  tooltipText: string | null;
  itemText?: string | null;
  table?: boolean | null;
  active?: (string | number)[] | [] | null;
  dictionary?: Dictionary;
  handleDictionaryLoad?(): void;
}
interface IPropsILSCatalogLayerMenuProps {
  activeLayers: number[];
  setActiveLayers: (layers: number[]) => void;
  layerMenu: LayerMenu[];
  handleDictionaryLoad?(): void;
  dictionary?: Dictionary;
}
export const ILSMonitoringLayerMenu: FC<IPropsILSCatalogLayerMenuProps> = ({
  activeLayers,
  setActiveLayers,
  layerMenu,
  dictionary,
  handleDictionaryLoad
}) => {
  const [showLayersMenu, setShowLayersMenu] = useState<boolean>(false);
  ///test
  const onLayerChange = (
    value: boolean | null,
    layer?: {
      ID: number;
      type?: string;
      Name?: string;
      entity?: string;
    } | null,
    clear?: boolean | null
  ) => {
    if (!clear && layer && layer.ID) {
      if (value) {
        setActiveLayers([...activeLayers, layer.ID]);
      } else {
        setActiveLayers(activeLayers.filter((r) => r !== layer.ID && r));
      }
    } else {
      setActiveLayers([]);
    }

    if (isEmpty( layerMenu) || layerMenu.every(item=>item.isEmpty)) {
      ILSUserNotify(Notify.Warning, `Отсутсвуют данные`, 1);
    }
  };

  return (
    <ILSMapMenu
      show={showLayersMenu}
      setShow={setShowLayersMenu}
      menu={layerMenu}
      active={activeLayers}
      tooltipText={'Слои карты'}
      itemText={'слои карты'}
      onChange={onLayerChange}
      icon={'show-layers'}
      dictionary={dictionary}
      handleDictionaryLoad={handleDictionaryLoad}
    />
  );
};

const ILSMapMenu: FC<IPropsILSMapMenuProps> = (props) => {
  const { show } = props;

  return show ? <ILSMonitoringMapMenuComponent {...props} /> : <ILSMonitoringMapMenuBtn {...props} />;
};
