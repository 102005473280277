import { createSelector } from 'reselect';
import { planningConfigsDataSelector, planningPlanConfigRefDataSelector } from '@modules/planning/children/by-date/selectors/config';
import { Dictionary, ReferenceTablesData, TableColumnName, TableRecord } from '@common/types';
import { getTypePlanningOptions } from '@modules/planning/children/by-date/helpers';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { plansToTable } from '@modules/planning/children/by-date/decorators/table/plans-to-table';
import { PlanningByDateState, PlansTableRecord } from '@modules/planning/children/by-date/types';
import { isPlanningPlansWithProgressSelector } from '@modules/planning/children/by-date/selectors/planning';
import { projectPlansSelector } from '@modules/planning/children/by-date/selectors/project';
import { PlanningCell } from '@modules/planning/children/by-date/types/planning-config';

export const refDataForPlansTableSelector = createSelector(
  planningPlanConfigRefDataSelector,
  planningConfigsDataSelector,
  (PlanConfig, planningPlanConfigsData) => {
    const refData: ReferenceTablesData = {
      [TableColumnName.PlanConfig]: PlanConfig,
      [TableColumnName.TypePlanning]: {
        options: (record: TableRecord) => {
          return getTypePlanningOptions(record as PlansTableRecord, planningPlanConfigsData);
        },
        fieldNames: BASE_FIELD_NAMES,
        dictionary: Dictionary.Empty
      }
    };
    return { refData };
  }
);

export const dataSourceForPlansTableSelector = createSelector(
  projectPlansSelector,
  isPlanningPlansWithProgressSelector,
  (_: PlanningByDateState, Planning: PlanningCell) => {
    return Planning;
  },
  (plans, checkData, Planning) => {
    const dataSource = plansToTable({ plans, Planning, checkData });
    return { dataSource };
  }
);
