import { FC } from 'react';
import { ILSButton } from '@components/index';
import { SettingsModalConfirmContainer } from '@modules/settings/components/actions/confirm-save';
import { useSettingsActions } from '@modules/settings/utils/hooks/use-settings-actions';

export const SettingsActions: FC = () => {
  const { confirm, resetSettings, setDefaultSettings, setConfirm } = useSettingsActions();
  return (
    <div className="flex-between-center mt-5">
      <ILSButton onClick={setDefaultSettings} children={'Настройки по умолчанию'} />
      {confirm && <SettingsModalConfirmContainer type={confirm} setConfirm={setConfirm} resetSettings={resetSettings} />}
    </div>
  );
};

