import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { MessageContent } from '@common/constants';

export const CELL_DEFAULT_ERROR = 'Переданы некоренные данные в ячейку';
export const USER_DEFAULT_ERROR = `${MessageContent.SomethingWentWrong} Обратитесь в техническую поддержку приложения.`;

type ShowUserAndDevErrorType = {
  error: Error | Error['message'] | unknown;
  userError?: string;
};
export const showUserAndDevError = ({ error, userError = USER_DEFAULT_ERROR }: ShowUserAndDevErrorType) => {
  ILSUserNotify(Notify.Error, userError);

  console.error(error);
};
