import { CellType, ShowDeleted, TableConfig } from '@common/types';
import { NEW_WORKTIME } from '@common/models/worktime';
import { ID_COLUMN } from '@common/models/all/columns';

export const WORKTIME_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_WORKTIME,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 7,
      filtering: 'string'
    },
    {
      title: 'Начало погрузки',
      dataIndex: 'LoadStart',
      key: 'LoadStart',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true,

      sorting: 6
    },
    {
      title: 'Конец погрузки',
      dataIndex: 'LoadEnd',
      key: 'LoadEnd',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true,

      sorting: 5
    },
    {
      title: 'Начало разгрузки',
      dataIndex: 'UnloadStart',
      key: 'UnloadStart',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true,

      sorting: 4
    },
    {
      title: 'Конец разгрузки',
      dataIndex: 'UnloadEnd',
      key: 'UnloadEnd',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true,

      sorting: 3
    },
    {
      title: 'Начало обеда',
      dataIndex: 'LunchStart',
      key: 'LunchStart',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true,

      sorting: 2
    },
    {
      title: 'Конец обеда',
      dataIndex: 'LunchEnd',
      key: 'LunchEnd',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 150,

      sorting: 1,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true
    }
  ]
};
