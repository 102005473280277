import { ILSTable, ILSUserNotify } from '@common/components';
import { NEW_ROW_KEY } from '@common/components/data-display/table/constants';
import { tableTrackerConfig, tableTrackerDateToTable } from '@common/table-configs/monitoring/tracker';
import { ColumnTable, Dictionary, IndexedArray, Keys, Notify, RecordTableButton, TableColumnName } from '@common/types';
import { MonitoringOnLateDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { Tracker } from '@common/types/dictionaries/tracker';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { tableCustom } from '@core/containers/table-custom';
import { useAppSelector } from '@core/hooks';
import {
  monitoringCreateTrackerLocalRoutine,
  monitoringCreateTrackerRoutine,
  monitoringDeleteTrackerRoutine,
  monitoringGetTrackersRoutine,
  monitoringUpdateTrackerRoutine
} from '@modules/monitoring/actions';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import { ChildTable, MonitoringChildTableContainer } from '@modules/monitoring/components/tables/child-table-container';
import { monitoringTrackersSelector } from '@modules/monitoring/selectors/trackers';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource?: Array<MonitoringOnLateDataModel> | IndexedArray<MonitoringOnLateDataModel>;
  onContextOpen?: (record: MonitoringOnLateDataModel, e: MouseEvent) => void;
  columns?: ColumnTable<MonitoringOnLateDataModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel?: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  handleUpdateView?: ((id: string | number | string[], value: 0 | 1) => void) | undefined;
  record: any;
  vehicles?: any;
  focusRow?: any;
}

//TODO нужно сделать и использовать отдельные хуки с useEffect и useHandler
// Компонент отвечающий за дочернюю таблицу трекеров
export const MonitoringTableTracker: FC<IProps> = ({
  dataSource = [],
  recordTableButtons,
  loadExel,
  onContextOpen,
  handleUpdateView,
  record,
  ...props
}) => {
  const dispatch = useDispatch();
  const { setFocusRow, focusRow } = useFocusRow(props.focusRow);
  const [newRecordIMEI, setNewRecordIMEI] = useState('');
  const [oldFocusedRow, setOldRow] = useState<{ key: number; IMEI: string | number }>({ key: -1, IMEI: '' });
  const trackerData = useAppSelector(monitoringTrackersSelector);

  useEffect(() => {
    dispatch(monitoringGetTrackersRoutine());
  }, []);

  useEffect(() => {
    if (Array.isArray(trackerData) && newRecordIMEI) {
      const trackerIndex = trackerData.findIndex((tracker) => tracker.IMEI === newRecordIMEI);
      if (trackerIndex !== -1) {
        setFocusRow({ key: trackerIndex.toString() });
        setNewRecordIMEI('');
      }
    }
  }, [trackerData]);

  useEffect(() => {
    if (Array.isArray(trackerData) && oldFocusedRow) {
      const rowIndex = trackerData.findIndex((tracker) => tracker.IMEI === oldFocusedRow.IMEI);
      if (rowIndex === -1) {
        setFocusRow({ key: +oldFocusedRow.key - 1 >= 0 ? (+oldFocusedRow.key - 1).toString() : '0' });
      }
    }
  }, [oldFocusedRow]);

  const decoratedData = tableTrackerDateToTable(trackerData);

  const isValidIMEI = (IMEI: string | number | null | undefined) => {
    return !(isNil(IMEI) || isNaN(+IMEI) || IMEI === '');
  };

  const handleReload = async () => {
    dispatch(monitoringGetTrackersRoutine());
  };

  const handleCreate = () => {
    if (last(decoratedData)?.IMEI !== '') dispatch(monitoringCreateTrackerLocalRoutine({ IMEI: '' }));
    setFocusRow({ key: decoratedData?.length.toString() });
  };

  const handleDelete = ([key]: Keys, [{ IMEI }]: Array<Tracker>) => {
    dispatch(monitoringDeleteTrackerRoutine(IMEI));
    setOldRow({ key: key as number, IMEI });
  };

  const handleSave = (record: Partial<Tracker>, dataIndex: TableColumnName, value: string) => {
    if (isNil(value) || value === '') return;
    if (dataIndex !== 'IMEI' && !isValidIMEI(record.IMEI)) {
      dispatch(monitoringCreateTrackerLocalRoutine({ ...record, [dataIndex]: value }));
      return;
    }
    if (dataIndex === 'IMEI' && !isValidIMEI(value)) {
      ILSUserNotify(Notify.Error, 'Поле "IMEI Трекера" может содержать только цифры', 1);
      return;
    }
    if (dataIndex !== 'IMEI' && isValidIMEI(record.IMEI)) {
      dispatch(monitoringUpdateTrackerRoutine({ IMEI: record.IMEI, values: { ...record, [dataIndex]: value } }));
      return;
    }
    dispatch(monitoringCreateTrackerRoutine({ ...record, IMEI: +value }));
    setNewRecordIMEI(value);
  };

  const onCanEdit = (record: typeof decoratedData[number], dataIndex: TableColumnName) => {
    return !(record.IMEI && dataIndex === 'IMEI' && !compareAsString(record.rowKey, NEW_ROW_KEY));
  };
  const handleSelectedElements = (record: any, keys: any[]) => {
    if (keys.length && keys[0] === NEW_ROW_KEY) {
      setFocusRow({ key: NEW_ROW_KEY });
    }
  };
  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        onCanEdit={onCanEdit}
        focusRow={focusRow}
        handleSelectedElements={handleSelectedElements}
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtons}
        config={tableTrackerConfig}
        size={'small'}
        className="monitoring editable-table"
        bordered
        dictionary={Dictionary.Tracker}
        onContextOpen={onContextOpen}
        handleReload={handleReload}
        handleCreate={handleCreate}
        dataSource={decoratedData}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringTracker = tableCustom(MonitoringTableTracker, tableTrackerConfig, Dictionary.Tracker);

export const MonitoringTrackerTable: FC<ChildTable> = (props) => {
  return <MonitoringChildTableContainer {...props} renderFunc={ILSMonitoringTracker} />;
};
