import { createSelector } from 'reselect';
import { activeTripSelector, tripsDataSelector } from '@modules/orders-and-trips/selectors/trip';
import { getTripMapDetails } from '@modules/orders-and-trips/helpers/get-trip-map-details';
import { EMPTY } from '@common/constants';
import { InitialState } from '../types/general';

export const mapActionPointsTripSelector = createSelector(
  tripsDataSelector,
  activeTripSelector,
  (tripsDataSelector, activeTripSelector) => {
    return getTripMapDetails(tripsDataSelector[activeTripSelector?.ID ?? EMPTY]);
  }
);

export const mapRoutesSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.routes,
  activeTripSelector,
  (routes, activeTripSelector) => {
    if (activeTripSelector?.ID && routes[activeTripSelector?.ID]) {
      return routes[activeTripSelector?.ID].map((coordsHash: string) => {
        return {
          color: `#442230`,
          weight: 5,
          coords: coordsHash
        };
      });
    }
    return [];
  }
);
