import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { copyConfigRoutine } from '../actions';
import { Dictionary } from '@common/types';
import { PlanningParams } from '@modules/planning/children/params/api';
import { IData } from '@modules/planning/children/params/types/store';

function* copyParamsConfigWorker(
  action: PayloadAction<{
    ConfigID: IData['id'];
    dictionary: Dictionary;
    config: IData;
  }>
) {
  const { success, failure, fulfill, request } = copyConfigRoutine;
  const { ConfigID, dictionary, config } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningParams.copyConfig, { dictionary, ConfigID, Context: config.DefaultValue_ });
    yield put(success({ config: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* copyParamsConfigWatcher() {
  yield takeLatest(copyConfigRoutine.trigger, copyParamsConfigWorker);
}
