import { AddressState, SuggestionAutoCompleteStore } from '@common/feature/suggestion-autocomplete/types';

export const ADDRESS_INITIAL_STATE: AddressState = {
  addresses: null,
  loading: false
};

export const INITIAL_SUGGESTION_AUTO_COMPLETE_STATE: SuggestionAutoCompleteStore = {
  address: ADDRESS_INITIAL_STATE
};
