import { SettingsFieldType } from '@modules/settings/types/container';

export const ONE_TO_EIGHTEEN_PX_OPTIONS = [
  { value: 1, label: '1px' },
  { value: 2, label: '2px' },
  { value: 3, label: '3px' },
  { value: 4, label: '4px' },
  { value: 5, label: '5px' },
  { value: 6, label: '6px' },
  { value: 7, label: '7px' },
  { value: 8, label: '8px' },
  { value: 9, label: '9px' },
  { value: 10, label: '10px' },
  { value: 11, label: '11px' },
  { value: 12, label: '12px' },
  { value: 13, label: '13px' },
  { value: 14, label: '14px' },
  { value: 15, label: '15px' },
  { value: 16, label: '16px' },
  { value: 17, label: '17px' },
  { value: 18, label: '18px' }
];

export const ONE_TO_FIVE_PX_OPTIONS = ONE_TO_EIGHTEEN_PX_OPTIONS.slice(0, 5);

export const DEFAULT_PLANNER_PARAMS = {
  planning: {
    name: 'Планирование',
    sections: {
      parameters: {
        name: 'Параметры',
        data: {
          activeWidth: {
            name: 'Толщина активных треков',
            value: 5,
            defaultValue: 5,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {
              line: 1
            },
            options: ONE_TO_FIVE_PX_OPTIONS
          },
          inactiveWidth: {
            name: 'Толщина неактивных треков',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {
              line: 1
            },
            options: ONE_TO_FIVE_PX_OPTIONS
          },
          activeColored: {
            name: 'Цветные активные треки',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox,
            active: 0,
            extraParams: {},
            options: []
          },
          inactiveColored: {
            name: 'Цветные активные треки',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox,
            active: 0,
            extraParams: {},
            options: []
          },
          useFigureMarker: {
            name: 'Использовать маркеры привязанные к окнам доставки ',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox,
            active: 0,
            extraParams: {
              attention: '(Экспериментальная функция)',
              description:
                'Ширина окна от 0 до 4 часов - треугольный маркер, ширина окна от 4 часов - квадратный маркер, окно в рамках 21:00-06:00 - круглый маркер'
            },
            options: []
          },
          useRedis: {
            name: 'Использовать Redis для получения треков',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox,
            active: 1,
            extraParams: {},
            options: []
          },
          geocodeService: {
            name: 'Сервис определения координат',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: [
              { label: 'Yandex', value: 1 },
              { label: 'DaData', value: 2 }
            ]
          },
          disableClustering: {
            name: 'Отключить группировку точек на карте',
            value: 0,
            defaultValue: 0,
            type: SettingsFieldType.Checkbox,
            active: 1,
            extraParams: {},
            options: []
          },
          mapZoomCluster: {
            name: 'Масштаб отключения группировки точек',
            value: 18,
            defaultValue: 18,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: ONE_TO_EIGHTEEN_PX_OPTIONS
          },
          validateOnPlanning: {
            name: 'Проверка данных перед планированием',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: [
              { label: 'Никогда', value: 0 },
              { label: 'Всегда', value: 1 }
            ]
          },
          validateOnImport: {
            name: 'Проверка данных перед импортом',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: [
              { label: 'Никогда', value: 0 },
              { label: 'Всегда', value: 1 }
            ]
          },
          interruptOnValidationErrorLevel: {
            name: 'Прервать выполнение планирования при валидации',
            value: 0,
            defaultValue: 0,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: [
              {
                value: 0,
                label: 'Никогда'
              },
              {
                value: 1,
                label: 'Если есть ошибки'
              },
              {
                value: 2,
                label: 'Если есть ошибки и предупреждения'
              }
            ]
          },
          unresolvedMarkerSource: {
            name: 'Маркеры неразвезенных грузов отображать в',
            value: 'target',
            defaultValue: 'target',
            type: SettingsFieldType.Select,
            active: 0,
            extraParams: {},
            options: [
              {
                value: 'target',
                label: 'Клиент разгрузки'
              },
              {
                value: 'source',
                label: 'Клиент загрузки'
              }
            ]
          },
          drawPairForUnresolved: {
            name: 'Рисовать пару для точки',
            value: 0,
            defaultValue: 0,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {
              attention: '**Для корректной работы параметра у всех точек должны быть указаны координаты'
            },
            options: [
              {
                value: 0,
                label: 'Никогда'
              },
              {
                value: 1,
                label: 'При выделении'
              },
              {
                value: 2,
                label: 'Всегда'
              }
            ]
          },
          hideLinesOnTrackAbsent: {
            name: 'Скрывать прямые при отсутствии треков',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox, // 1 - 1 (да), 0 - 0 (нет)
            active: 1,
            extraParams: {},
            options: []
          },
          enableAutoFocusOnChangeTrip: {
            name: 'Фокусироваться на измененных рейсах',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox, // 1 - 1 (да), 0 - 0 (нет)
            active: 1,
            extraParams: {},
            options: []
          },
          activeLockTooltip: {
            name: 'Подсказки на пиктограмме ЗАМОК',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Checkbox, // 1 - 1 (да), 0 - 0 (нет)
            active: 1,
            extraParams: {},
            options: []
          }
        }
      }
    }
  }
};
