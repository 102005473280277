import { FC } from 'react';
import CustomScroll from 'react-customscroll';
import { isEmpty } from 'lodash';
import { ILSButton, ILSCheckboxGroup } from '@components/index';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

interface IProps extends CheckboxGroupProps {
  handleShow(): void;
}

export const PlannerPlanningRestrictionMenuComponent: FC<IProps> = ({ handleShow, options, onChange, value = EMPTY_ARRAY_DATA }) => {
  const onClear = () => onChange?.(EMPTY_ARRAY_DATA);

  return (
    <div className={'table-btn-menu with-scroll'}>
      <CustomScroll>
        {isEmpty(options) ? (
          <span>В данном плане специфики не найдены</span>
        ) : (
          <ILSCheckboxGroup value={value} className={'table-btn-menu-list'} options={options} onChange={onChange} />
        )}
      </CustomScroll>
      <div className="table-btn-menu-buttons-restriction">
        <ILSButton onClick={onClear}>Сброс</ILSButton>
        <ILSButton type="primary" onClick={handleShow} children={'OK'} />
      </div>
    </div>
  );
};
