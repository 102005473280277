import { createReducer } from '@reduxjs/toolkit';
import { tableDataChangeRoutine } from '@modules/planning/children/data/actions';
import { initialState } from '@modules/planning/children/data/store';
import { DictionariesEndpoint } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { EMPTY_ARRAY_DATA } from '@common/constants';

export const tableDataChangeReducer = createReducer(
  initialState,
  {
    [tableDataChangeRoutine.REQUEST]: onRequest,
    [tableDataChangeRoutine.SUCCESS]: (state, action) => {
      const { data, files, file, row, rows, operation } = action.payload;
      switch (operation) {
        case DictionariesEndpoint.Update:
          state.data[file][row] = data;
          break;
        case DictionariesEndpoint.DeleteRows:
          state.data[file] = state.data[file].filter((row: number, i: number) => !rows?.includes(i));
          break;
        case DictionariesEndpoint.Add:
          state.data[file] = (state.data[file] ?? EMPTY_ARRAY_DATA).concat(data);
          break;
      }
      if (files) {
        state.files = files;
      }
      return state;
    },
    [tableDataChangeRoutine.FAILURE]: onFailure,
    [tableDataChangeRoutine.FULFILL]: onFulfill
  }
  // handleReducer<typeof initialState, ChangeDataPayload & { data: Data; files: FileData }>(tableDataChangeRoutine, {
  //   SUCCESS: (state, action) => {
  //     const { data, files, file, row, rows, operation } = action.payload;
  //     switch (operation) {
  //       case DictionariesEndpoint.Update:
  //         state.data[file][row] = data;
  //         break;
  //       case DictionariesEndpoint.DeleteRows:
  //         state.data[file] = state.data[file].filter((row: number, i: number) => !rows?.includes(i));
  //         break;
  //       case DictionariesEndpoint.Add:
  //         state.data[file] = state.data[file].concat(data);
  //         break;
  //     }
  //     if (files) {
  //       state.files = files;
  //     }
  //     return state;
  //   }
  // })
);
