import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { RouteTestStore } from '../types';
import { roadGraphGetForbiddenZonesRoutine } from '@modules/road-graph/route-test/actions';
import { IForbiddenZone } from '@modules/road-graph/route-test/types/map';
import { PayloadActionError } from '@common/types';

const roadGraphForbiddenZones = createReducer(initialState, {
  [roadGraphGetForbiddenZonesRoutine.REQUEST]: (state: RouteTestStore) => {
    state.isFetching = true;
    return state;
  },
  [roadGraphGetForbiddenZonesRoutine.SUCCESS]: (
    state: RouteTestStore,
    action: PayloadAction<{
      data: IForbiddenZone[];
    }>
  ) => {
    const { data } = action.payload;
    state.isFetching = false;
    state.forbiddenZones!.data = data;
    state.forbiddenZones!.lastUpdate = Date.now();
    return state;
  },
  [roadGraphGetForbiddenZonesRoutine.FAILURE]: (state: RouteTestStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isFetching = false;
    state.error = error;
    return state;
  },
  [roadGraphGetForbiddenZonesRoutine.FULFILL]: (state: RouteTestStore) => {
    state.error = [];
    return state;
  }
});

export default roadGraphForbiddenZones;
