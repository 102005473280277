import { useDispatch, useSelector } from 'react-redux';
import { availableTransportCompanyIntegrationsFetchSelector, fakePreCostSelector } from '@modules/orders-and-trips/selectors/integrations';
import { getFakePreCostRoutine } from '@modules/orders-and-trips/actions';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { FakePreCostValues } from '../types/fake-pre-cost';
import { convertMomentInString } from '@modules/orders-and-trips/helpers/form/date/convert-moment-in-string';
import { useProposalListSort } from '@modules/orders-and-trips/hooks/use-proposal-list-sort';

export const useFakePreCost = (type: NewOrderFormTab) => {
  const dispatch = useDispatch();
  const fakePreCost = useSelector(fakePreCostSelector);
  const isFetching = useSelector(availableTransportCompanyIntegrationsFetchSelector);
  const { sortProposals, activeSortOption, sortProposalList } = useProposalListSort({ fakePreCost });

  /** Запрашивает предварительную стоимость доставки по трем параметрам */
  const getFakePreCost = (indexedValues: FakePreCostValues) => {
    const payload = {
      type,
      ...indexedValues
    };
    convertMomentInString(payload);
    dispatch(getFakePreCostRoutine(payload));
  };

  return { getFakePreCost, activeSortOption, fakePreCost, isFetching, proposalList: sortProposalList, sortProposals };
};
