import { ILSButton } from '@common/components';
import { IndexedArray } from '@common/types';
import { AbstractBoard, AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import React, { FC } from 'react';

type Props = {
  newItemButtons: {
    copyText: string;
    newItemText: string;
  };
  listData: IndexedArray<AnalyticsBoardSetModel | AbstractBoard> | undefined;
  setIsCopySet: (value: boolean) => void;
  createItem: () => void;
};

const AnalyticsListNewItem: FC<Props> = ({ newItemButtons, listData, setIsCopySet, createItem }) => {
  return (
    <div className="board new-set">
      <div className="list-item">
        <div className="new-set-row-value">{newItemButtons.copyText}</div>
        <ILSButton
          disabled={!(listData && Object.values(listData)?.length)}
          className="btn-choose btn-new-row-value"
          onClick={() => setIsCopySet(true)}
        >
          Выбрать
        </ILSButton>
      </div>
      <div className="list-item">
        <div className="new-set-row-value">{newItemButtons.newItemText}</div>
        <ILSButton className="btn-choose" onClick={() => createItem()}>
          Выбрать
        </ILSButton>
      </div>
    </div>
  );
};

export default AnalyticsListNewItem;
