import { useState } from 'react';
import { User } from '@common/types';

export const useUserForm = () => {
  const [form, setForm] = useState<{ show: boolean; user: null | User }>({ show: false, user: null });

  const handleReset = () => setForm({ show: false, user: null });

  const toggleForm = (show: boolean, user: null | User) => {
    handleReset();
    setTimeout(() => {
      //для обновления пользователя в форме
      setForm({ show, user });
    }, 100);
  };

  const { show, user } = form;
  const userID = user?.ID;
  return { show, user, handleReset, toggleForm, userID };
};
