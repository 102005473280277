import { ILSTagEmpty } from '@components/index';
import { ILSSelectCloseOnScroll } from '@components/data-display/table/components/ils-select-scroll-close';
import { IILSCellSelectMultipleApiProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';

export const ILSCellSelectMultipleApi = <R extends TableRecord>({
  children,
  save,
  tableRef,
  toggleEdit,
  dictionary,
  dictionaryLoad,
  dictionaryInfo,
  options,
  showSearch,
  fieldNames
}: IILSCellSelectMultipleApiProps<R>): JSX.Element => {
  const [node, defaultValue] = children;
  const loading = dictionaryInfo?.[dictionary]?.isFetching;
  useApiOptions({ dictionary, dictionaryLoad });

  return (
    <>
      {node}
      <ILSSelectCloseOnScroll
        autoFocus
        open
        allowClear
        fieldNames={fieldNames}
        onChange={save}
        value={defaultValue}
        onBlur={toggleEdit}
        filterOption={filterOption(fieldNames)}
        tagRender={ILSTagEmpty}
        options={options}
        maxTagCount={1}
        loading={Boolean(loading)}
        mode={'multiple'}
        showSearch={showSearch}
        tableRef={tableRef}
        className={'cell-wrapper-editable-full'}
      />
    </>
  );
};
