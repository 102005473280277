import { useEffect } from 'react';
import { Dictionary, ITablePropsFunction, TableRecord } from '@common/types';

export const useApiOptions = <R extends TableRecord>({
  dictionary,
  dictionaryLoad
}: {
  dictionaryLoad?: ITablePropsFunction<R>['dictionaryLoad'];
  dictionary?: Dictionary;
}) => {
  useEffect(() => {
    if (dictionary && dictionaryLoad) {
      dictionaryLoad(dictionary);
    } else {
      process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test' &&
        console.error(`Необходимо передать props dictionaryLoad ${dictionaryLoad} /dictionary: ${dictionary}`);
    }
  }, []);
};
