import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import Sidebar from './containers';

export default defineModule({
  title: 'Боковая панель навигации',
  component: Sidebar,
  useScope: ModuleScope.SiderNav,
  inScope: ModuleScope.Sider
});
