import { SearchOutlined } from '@ant-design/icons';
import { ILSButton } from '@common/components/general/buttons';
import { EMPTY_STRING } from '@common/constants';
import { ColumnTable, TableRecord } from '@common/types';
import { Input, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Dispatch, Key, SetStateAction } from 'react';
import { FilterState } from '@common/types/components/table/common';
import { ILSCheckbox, ILSForm } from '@common/components';
import { useFilterSearchModeCheckbox } from '@components/data-display/table/utils/helpers/filter/hooks/use-filter-search-mode-checkbox';

const handleSearch =
  <R extends TableRecord>(
    selectedKeys: Array<Key>,
    confirm: FilterDropdownProps['confirm'],
    dataIndex: ColumnTable<R>['dataIndex'],
    setFilter: Dispatch<SetStateAction<FilterState>>,
    strictSearch: boolean = false
  ) =>
  () => {
    confirm();
    setFilter({
      searchText: selectedKeys[0]?.toString(),
      searchedColumn: dataIndex,
      strictSearch
    });
  };

const handleReset = (
  clearFilters: () => void,
  confirm: FilterDropdownProps['confirm'],
  filter: { searchText: Key; searchedColumn: any },
  setFilter: Dispatch<SetStateAction<FilterState>>
) => {
  clearFilters();
  setFilter({
    ...filter,
    searchText: EMPTY_STRING,
    strictSearch: false
  });
  confirm();
};

export const renderFilterDropdown = <R extends TableRecord>({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  searchInput,
  title,
  dataIndex,
  setFilterState,
  filterState
}: FilterDropdownProps & {
  dataIndex: ColumnTable<R>['dataIndex'];
  filterState: FilterState;
  setFilterState: Dispatch<SetStateAction<FilterState>>;
  searchInput: InputRef | null;
  title: ColumnTable<R>['title'];
}) => {
  const { strictSearch, handleStrictSearchMode } = useFilterSearchModeCheckbox(filterState.strictSearch);
  return (
    <ILSForm className={'filter-dropdown'}>
      <ILSCheckbox
        checked={strictSearch}
        onChange={handleStrictSearchMode}
        className={'filter-dropdown-checkbox'}
        label={'По точному совпадению'}
      />
      <Input
        className={'filter-dropdown-input'}
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Поиск "${title}"`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={handleSearch(selectedKeys, confirm, dataIndex, setFilterState, strictSearch)}
      />
      <ILSButton
        className={'filter-dropdown-search'}
        type="primary"
        onClick={handleSearch(selectedKeys, confirm, dataIndex, setFilterState, strictSearch)}
        icon={<SearchOutlined />}
        size="small"
      >
        Поиск
      </ILSButton>
      <ILSButton
        className={'filter-dropdown-reset'}
        onClick={() => clearFilters && handleReset(clearFilters, confirm, filterState, setFilterState)}
        size="small"
      >
        Сброс
      </ILSButton>
    </ILSForm>
  );
};
