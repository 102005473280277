import { ILSMap } from '@core/containers/map';
import { MutableRefObject, useEffect } from 'react';
import { Marker } from '@modules/road-graph/route-test/types';

export const useMapInit = (Map: MutableRefObject<any>, marker?: Marker[]) => {
  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    curMap.addRuler();

    if (marker?.length) {
      Map.current.drawMarkers(marker);
    }

    return () => Map.current.deinit();
  }, []);
};

export default { useMapInit };

