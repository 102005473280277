import { QUESTIONS_SECTIONS_TITLE } from '@common/feature/questions/constants/index';
import { QuestionsHeader } from '@common/feature/questions/components/questions-header';
import { QuestionsRulesList } from '@common/feature/questions/components/questions-rules-list';

export const QuestionsRulesContainer = () => {
  return (
    <section className="questions-section">
      <QuestionsHeader title={QUESTIONS_SECTIONS_TITLE.TRANSPORTATION_RULES} />
      <div className="questions-section-content">
        <QuestionsRulesList />
      </div>
    </section>
  );
};

