import '../styles.less';
import { useDispatch } from 'react-redux';
import { ILSSpin, ILSTabPane, ILSTabs } from '@components/index';
import { ProfileTab, ProfileTabName } from '@modules/account/types/tabs';
import { useAccountTabs } from '@modules/account/utils/hooks/use-account-tabs';
import { ACCOUNT_PANES } from '@modules/account/constants';
import { ACCOUNT_PANES_COMPONENTS } from '@modules/account/constants/account-panes-components';

export const ILSAccount = () => {
  const dispatch = useDispatch();
  const { currentActiveTab, onChangeTab, adminAccess, isSaving, isFetching } = useAccountTabs({ dispatch });

  return (
    <ILSSpin spinning={Boolean(isSaving || isFetching || !currentActiveTab)}>
      <ILSTabs className={'account'} type="card" activeKey={currentActiveTab} onChange={onChangeTab}>
        {ACCOUNT_PANES.reduce((prev, pane) => {
          const isUsersPane = pane === ProfileTab.users;
          ((adminAccess && isUsersPane) || !isUsersPane) &&
            prev.push(
              <ILSTabPane className={`account-tab ${pane}`} tab={ProfileTabName[pane]} key={pane}>
                {ACCOUNT_PANES_COMPONENTS[pane]}
              </ILSTabPane>
            );
          return prev;
        }, [] as Array<JSX.Element>)}
      </ILSTabs>
    </ILSSpin>
  );
};
