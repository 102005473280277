import { call, put, takeLatest } from 'redux-saga/effects';
import { RoadGraphTest } from '@modules/road-graph/route-test/api';
import { roadGraphAddForbiddenZoneRoutine } from '@modules/road-graph/route-test/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* roadGraphAddForbiddenZoneWorker(action: PayloadAction<{}>) {
  const { request, success, failure, fulfill } = roadGraphAddForbiddenZoneRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(RoadGraphTest.addForbiddenZone, { ...action.payload });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* roadGraphAddForbiddenZoneWatcher() {
  yield takeLatest(roadGraphAddForbiddenZoneRoutine.trigger, roadGraphAddForbiddenZoneWorker);
}
