import { getFormFieldsRoutine } from '@modules/documents/children/upload/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DocumentsUpload } from '@modules/documents/children/upload/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { DocumentType } from '@modules/documents/types/document-type';

function* getFormFieldsWorker(action: PayloadAction<DocumentType>) {
  const { request, success, failure, fulfill } = getFormFieldsRoutine;

  try {
    yield put(request());

    const { data } = yield call(DocumentsUpload.getFormFields, action.payload);

    yield put(success(data.data));
  } catch (e) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* getFormFieldsWatcher() {
  yield takeLatest(getFormFieldsRoutine.TRIGGER, getFormFieldsWorker);
}
