import { useDispatch, useSelector } from 'react-redux';
import { mainActiveTabSelector } from '@modules/orders-and-trips/selectors';
import { MainTabs, MainTabsContainer } from '@modules/orders-and-trips/types/general';
import { switchActiveMainTabRoutine } from '@modules/orders-and-trips/actions';

export const useFullBlock = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(mainActiveTabSelector(MainTabsContainer.leftBlock));

  const setActiveTab = (key: MainTabs) => {
    dispatch(switchActiveMainTabRoutine({ tab: key, container: MainTabsContainer.leftBlock }));
  };

  const onTabChange = (key: string) => setActiveTab(key as MainTabs);

  return { activeTab, setActiveTab, onTabChange };
};
