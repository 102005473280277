import { monitoringGetProgressRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringDashboardState } from '../types/store';
import { GetProgressResponse } from '@common/types/dictionaries/monitoring';

// Устанавливает информацию о текущем прогрессе
const monitoringGetProgressReducer = createReducer<MonitoringDashboardState>(initialState as MonitoringDashboardState, {
  [monitoringGetProgressRoutine.REQUEST]: (state) => {
    state.progress.isFetching = true;
    return state;
  },
  [monitoringGetProgressRoutine.SUCCESS]: (state, action: PayloadAction<GetProgressResponse>) => {
    if (!action.payload) return state;
    state.progress.data = action.payload;
    return state;
  },
  [monitoringGetProgressRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringGetProgressRoutine.FULFILL]: (state) => {
    state.progress.isFetching = false;
    return state;
  }
});

export default monitoringGetProgressReducer;
