import { call, put, takeLatest } from 'redux-saga/effects';
import { accountAttachmentCreateRoutine, accountAttachmentIndexRoutine } from '@modules/account/actions';
import { AccountAttachmentSubject } from '@common/types';
import { AccountAttachmentAPI } from '@modules/account/api/account-attachment';
import { PayloadAction } from '@reduxjs/toolkit';
import { AccountAttachmentCategory, AccountAttachmentsDependent } from '@modules/account/types/attachments';
import { RcFile } from 'antd/lib/upload';

export function* accountAttachmentWorker(
  action: PayloadAction<{
    dependentID: number;
    subject: AccountAttachmentSubject;
    dependent: AccountAttachmentsDependent.Account;
    attachment: RcFile | Array<RcFile>;
    accountAttachmentCategory: AccountAttachmentCategory;
  }>
) {
  const { failure, fulfill, success } = accountAttachmentCreateRoutine;
  const { dependentID, subject, dependent, attachment, accountAttachmentCategory } = action.payload;

  try {
    const {
      data: { data }
    } = yield call(AccountAttachmentAPI.create, { dependentID, subject, dependent, attachment });
    if (data) {
      yield put(accountAttachmentIndexRoutine.trigger({ accountAttachmentCategory, dependent, dependentID }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* accountAttachmentWatcher() {
  yield takeLatest(accountAttachmentCreateRoutine.trigger, accountAttachmentWorker);
}
