import { ILSTable } from '@common/components';
import { FC, useState } from 'react';
import { tableViolationsConfig, tableViolationsDateToTable } from '@common/table-configs/monitoring-dashboard/violations';
import { tableCustom } from '@core/containers/table-custom';
import { ColumnTable, Dictionary, IndexedArray, RecordTableButton } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { addToTableExelButton } from '../../utils/exel-button';
import { addToTableShowViewedButton, hasUnviewed, tableViewedFilter } from '../../utils/viewed-button';
import { ILSDashboardViewedButtonComponent } from '../../components/viewed-button';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource: Array<MonitoringBDDAndFuelDrainModel> | IndexedArray<MonitoringBDDAndFuelDrainModel>;
  onContextOpen?: (record: MonitoringBDDAndFuelDrainModel, e: MouseEvent) => void;
  columns: ColumnTable<MonitoringBDDAndFuelDrainModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  handleUpdateView?: ((id: string | number | string[] | number[], value: 0 | 1) => void) | undefined;
}

// Контейнер таблицы Безопасности Дорожного движения
export const MonitoringTableDeliveryViolations: FC<IProps> = ({
  dataSource,
  onContextOpen,
  loadExel,
  recordTableButtons,
  handleUpdateView,
  ...props
}) => {
  const [showAll, setShowAll] = useState(true);
  const { setFocusRow, focusRow } = useFocusRow();

  const decoratedData = tableViewedFilter(tableViolationsDateToTable(dataSource, handleUpdateView), showAll);

  recordTableButtons = addToTableExelButton(recordTableButtons, loadExel, decoratedData, props.columns, Dictionary.Violations);
  recordTableButtons = addToTableShowViewedButton(recordTableButtons, showAll, (val: boolean) => showAll !== val && setShowAll(val));

  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        focusRow={focusRow}
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtons}
        config={tableViolationsConfig}
        dataSource={decoratedData}
        size={'small'}
        scroll={{ y: 'calc(75vh - 430px)' }}
        pagination={false}
        className="editable-table"
        bordered
        dictionary={Dictionary.Violations}
        onContextOpen={onContextOpen}
      />

      <ILSDashboardViewedButtonComponent
        key={Dictionary.Violations + 'button'}
        disabled={!hasUnviewed(decoratedData)}
        onClick={() => {
          handleUpdateView?.(
            tableViewedFilter(decoratedData, false).map((item) => item.dependentKey),
            1
          );
        }}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringViolations = tableCustom(MonitoringTableDeliveryViolations, tableViolationsConfig, Dictionary.Violations);
