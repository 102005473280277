import { tableCustom } from '@core/containers/table-custom';
import { depotOverloads2table } from '@common/models/depot-overload/decorators';
import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators';
import { tableConfig } from '@common/models/depot-overload/config/table';

const PlanningDepotOverload: FC<IILSTablePlanningComponentProps> = (props) => {
  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={props.config}
      dataDecorator={depotOverloads2table}
      handleDecorator={table2data}
      dictionary={Dictionary.DepotOverload}
    />
  );
};

export const DepotOverloadTable = tableCustom(PlanningDepotOverload, tableConfig, CatalogDictionary.DepotOverload);
