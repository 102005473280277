import { CellType, TableConfig } from '@common/types';
import { AttachmentColumn, DriverComment, LastStatus, LastStatusDT } from '../../../monitoring/common/column';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: '№',
      dataIndex: 'ID',
      key: 'ID',
      active: true,
      type: CellType.Int,
      ellipsis: true,
      width: 60,

      sorting: 3,

      invisible: true
    },
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Рег.Номер',
      dataIndex: 'Number',
      key: 'Number',
      active: true,
      type: CellType.RegNumber,
      ellipsis: true,
      filtering: 'array',
      width: 70,

      sorting: 3
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      width: 80,

      sorting: 3
    },
    {
      title: 'Адрес доставки',
      dataIndex: 'Address',
      key: 'Address',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Время прибытия фактическое',
      dataIndex: 'WaypointFactArrive',
      key: 'WaypointFactArrive',
      active: true,
      type: CellType.TimeStringUtc,
      ellipsis: true,

      sorting: 3
    },
    {
      title: 'Время прибытия по плану',
      dataIndex: 'WaypointPlanArrive',
      key: 'WaypointPlanArrive',
      active: true,
      type: CellType.TimeStringUtc,
      ellipsis: true,

      sorting: 3
    },
    // {
    //   title: 'Статус качества обслуживания',
    //   dataIndex: 'Status',
    //   key: 'Status',
    //   active: true,
    //   type: CellType.Select,
    //   ellipsis: true,
    //   filtering: 'array',
    //   width: 100,
    //   editable: false,
    //   options: [
    //     { label: 'Не посещена', value: 0 },
    //     { label: 'Обслуживается', value: 1 },
    //     { label: 'Посещена', value: 2 },
    //     { label: 'Посещена с допустимым опережением', value: 3 },
    //     { label: ' Посещена с допустимым опозданием', value: 4 },
    //     { label: ' Посещена с опережением', value: 5 },
    //     { label: ' Посещена с опозданием', value: 6 }
    //   ],
    //
    //   sorting: 3,
    //
    // },
    //TODO временно (refactor need) заменяем select на строку
    {
      title: 'Статус качества обслуживания',
      dataIndex: 'Status',
      key: 'Status',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100
      // editable: false,
      // options: [
      //   { label: 'Не посещена', value: 0 },
      //   { label: 'Обслуживается', value: 1 },
      //   { label: 'Посещена', value: 2 },
      //   { label: 'Посещена с допустимым опережением', value: 3 },
      //   { label: ' Посещена с допустимым опозданием', value: 4 },
      //   { label: ' Посещена с опережением', value: 5 },
      //   { label: ' Посещена с опозданием', value: 6 }
      // ],
      //
      // sorting: 3,
    },
    {
      title: 'Комментарий',
      dataIndex: 'Comment',
      key: 'Comment',
      active: true,
      type: CellType.String,
      ellipsis: true,
      filtering: 'string',
      width: 100,

      sorting: 3
    },
    AttachmentColumn,
    DriverComment,
    LastStatus,
    LastStatusDT,
    {
      title: 'Просмотрено',
      titleText: 'Просмотрено',
      titleTooltip: 'Просмотрено',
      dataIndex: 'Viewed',
      key: 'Viewed',
      active: true,
      type: CellType.IconAction, //to new => checkboxIcon
      ellipsis: true,
      width: 40,

      sorting: 3
    }
    //убрали до реализации функционала
    // {
    //   title: 'Избранное',
    //   dataIndex: 'Favourite',
    //   key: 'Favourite',
    //   active: true,
    //   type: CellType.Select, //to new => checkboxIcon
    //   ellipsis: true,
    //   options: [
    //     { label: ' ', value: 0 },
    //     { label: 'Избранное', value: 1 }
    //   ],
    //   width: 70,
    //   editable: false,
    //
    //   sorting: 3,
    //
    //   filtering: 'array'
    // }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: false,
  copying: false
};
