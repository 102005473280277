import { colorList } from '@common/constants';
import { COLOR_BLACK } from '@common/constants/colors';

export type ColorType = string;
export const int2color = (val: number | string | null): ColorType => {
  let valN = typeof val === 'string' ? parseInt(val) : val;
  if (!valN || !Number.isInteger(valN)) return COLOR_BLACK;
  let color = valN.toString(16);
  while (color.length < 6) color = '0' + color;
  return '#' + color;
};

export const color2int = (val: ColorType): number => {
  if ('undefined' === typeof val || val.substring(0, 1) !== '#' || val.length !== 7) return 0;
  return parseInt('0x' + val.substring(1, 7));
};

export const color4vehicle = (vehicleID: number): ColorType => {
  const colors = colorList;
  if (!colors || !colors.length || !vehicleID) {
    return COLOR_BLACK;
  }
  if (vehicleID < 0) {
    vehicleID = -vehicleID;
  }
  return colors[vehicleID % colors.length]['RGB'];
};

export const hex2rgb = (hex: string) => {
  const red = parseInt(hex[1] + hex[2], 16);
  const green = parseInt(hex[3] + hex[4], 16);
  const blue = parseInt(hex[5] + hex[6], 16);
  return `rgb(${red}, ${green}, ${blue})`;
};
