import { ILSModal } from '@components/index';
import { EfficiencyTip } from '@modules/planning/children/by-date/components/result/tips';
import { FC, MouseEvent } from 'react';
import { TripsEfficiency } from '@modules/planning/children/by-date/containers/result/efficiency/modal';

interface ILSREMC {
  trips: TripsEfficiency;
  isModalVisible: boolean;
  handleOk: (e: MouseEvent) => void;
}

// TODO: remove inline styles from everywhere
export const ILSResultEfficiencyModalComponent: FC<ILSREMC> = ({ trips, isModalVisible, handleOk }) => {
  return (
    <>
      {isModalVisible && (
        <ILSModal
          title={'Эффективность по выделенным рейсам'}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          okText="Ок"
          className="cell-modal"
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            {trips?.map((trip, i) => <EfficiencyTip trip={trip} hideHeader={i > 0} />) ?? 'Нет выделенных рейсов'}
          </div>
        </ILSModal>
      )}
    </>
  );
};
