import {
  AnalyticsBoardModel,
  AnalyticsFormDataWidgetDataModel,
  AnalyticsInfoWidgetModel,
  AnalyticsWidget,
  AnalyticsWidgetValue,
  BoardDefaults,
  WidgetType
} from '@common/types/dictionaries/analytics';
import { FC, useMemo } from 'react';
import { WidgetLayout } from '@modules/analytics/common/components/widget/widget-layout';
import { ILSWidgetError } from '@modules/analytics/common/components/widget/widget-error';
import { createAnalyticsTableConfig } from '@modules/analytics/common/utils/create-table-config';
import AnalyticsTablesContainer from '@modules/analytics/common/tables';
import AnalyticsTableSummary from '@modules/analytics/common/tables/components/summary';
import ILSAnalyticsBarChartContainer from '@modules/analytics/common/charts';
import ILSFormDataWidget from '@modules/analytics/common/components/form-data';
import ILSInfoWidget from '@modules/analytics/common/components/widget-info';
import ILSWidgetMetrics from '@modules/analytics/common/components/widget-metrics';
import { IndexedArray } from '@common/types';
import ILSInfoArrayWidget from '@modules/analytics/common/components/widget-info-array';
import ILSTextWidget from '@modules/analytics/common/components/widget-text';
import ILSAnalyticsAreaChartComponent from '@modules/analytics/common/charts/components/area-chart';
import ILSAnalyticsGroupedChartComponent from '@modules/analytics/common/charts/components/grouped-chart';
import { convertAnalyticsWidgetTableData } from '@modules/analytics/common/utils/convert-table-data';

interface AnalyticsWidgetComponentProps {
  widget: AnalyticsWidget;
  editMode: boolean;
  hideWidget: boolean;
  setText: (text: string) => void;
  setIsFixed: (fixed: boolean) => void;
  isIntersect: boolean;
  board: HTMLElement | null | undefined;
  handleSelectBoard?: (item: AnalyticsBoardModel, defaults: BoardDefaults) => void;
  widgetParams: IndexedArray<{ name: string; value: string | number | undefined }>;
  handleSetParams: (params: IndexedArray<{ name: string; value: string | number | undefined }>) => void;
  hideName?: boolean;
  children?: JSX.Element;
  hideTableBorders?: boolean;
}
export const AnalyticsWidgetComponent: FC<AnalyticsWidgetComponentProps> = (props) => {
  const {
    widget,
    editMode,
    hideWidget,
    setText,
    isIntersect,
    setIsFixed,
    handleSelectBoard,
    widgetParams,
    board,
    handleSetParams,
    hideName,
    children,
    hideTableBorders
  } = props;

  const title = hideName ? '' : widget.ShortName || widget.Name || '';

  const height = ((board?.offsetHeight || 0) * (widget.Size?.Height || 0)) / 100;

  const tableData = useMemo(() => {
    if (widget.Type === WidgetType.table) {
      return convertAnalyticsWidgetTableData({
        widgetData: widget.Data?.values as Array<IndexedArray<AnalyticsWidgetValue>>,
        metrics: widget.Data?.metrics || {},
        actions: widget.Data?.actions,
        handleSelectBoard
      });
    }
    return [];
  }, [widget.Data, widget.Type]);

  const Component: JSX.Element = useMemo(() => {
    if (editMode) {
      return (
        <WidgetLayout
          widget={widget}
          onChangeText={setText}
          name={widget?.Name || widget.ID.toString() || widget?.Description || widget?.ClassName?.toString() || ''}
          isIntersect={isIntersect}
          setIsFixed={setIsFixed}
        />
      );
    }
    if (widget.error && widget.Type !== WidgetType.formData) {
      return <ILSWidgetError hideWidget={hideWidget} />;
    }
    switch (widget.Type) {
      case WidgetType.table:
        const config = createAnalyticsTableConfig(widget.Data?.metrics, widget?.ClassName, widget.Data?.actions);
        return (
          <AnalyticsTablesContainer
            tableProps={{
              dataSource: tableData,
              config: {
                ...config,
                className: widget?.ClassName,
                title
              },

              ...(widget.Data?.total &&
                widget.Data?.chart && {
                  showSummary: <AnalyticsTableSummary data={Object.values(widget.Data.total)} chart={widget.Data.chart} />
                })
            }}
            hideWidget={hideWidget}
            params={widget.Params}
            storeParams={widgetParams}
            loading={widget.loading}
            children={children}
            hideBorder={hideTableBorders}
          />
        );
      case WidgetType.barGraphVertical:
      case WidgetType.barGraphHorizontal:
        return (
          <ILSAnalyticsBarChartContainer
            type={widget.Type}
            height={height}
            metrics={Object.keys(widget.Data?.metrics || {})}
            data={Object.values(widget.Data?.values || {})}
            axisConfig={widget.Data?.info}
            loading={widget.loading || false}
            vertical={widget.Type === WidgetType.barGraphVertical}
            config={{
              title,
              className: widget?.ClassName
            }}
            name={title}
            children={children}
          />
        );

      case WidgetType.formData:
        return (
          <ILSFormDataWidget
            widget={widget}
            hideWidget={hideWidget}
            handleSetParams={handleSetParams}
            params={widget.Params}
            storeParams={widgetParams}
            data={widget.Data as AnalyticsFormDataWidgetDataModel}
            loading={widget.loading}
            loaded={widget.loaded}
          />
        );

      case WidgetType.info:
        return (
          <ILSInfoWidget
            hideWidget={hideWidget}
            data={widget.Data as { info: AnalyticsInfoWidgetModel; widget: any[] }}
            params={widget.Params}
            storeParams={widgetParams}
            loading={widget.loading || false}
          />
        );
      case WidgetType.metrics:
        return <ILSWidgetMetrics data={widget.Data?.info as IndexedArray<AnalyticsInfoWidgetModel>} />;
      case WidgetType.infoArray:
        return <ILSInfoArrayWidget hideWidget={hideWidget} widget={widget} />;
      case WidgetType.text:
        return <ILSTextWidget widget={widget} hideWidget={hideWidget} />;
      case WidgetType.lineChart:
        return (
          <ILSAnalyticsAreaChartComponent
            hideWidget={hideWidget}
            data={widget.Data?.values as any}
            height={height}
            type={widget.Type}
            axisConfig={widget.Data?.metrics}
            axis={widget.Data?.axis}
            loading={widget.loading || false}
            config={{
              title,
              className: widget?.ClassName
            }}
            name={title}
            children={children}
          />
        );
      case WidgetType.groupedChart:
        return (
          <ILSAnalyticsGroupedChartComponent
            hideWidget={hideWidget}
            data={widget.Data?.values as any}
            type={widget.Type}
            axisConfig={widget.Data?.metrics}
            loading={widget.loading}
            config={{
              title,
              className: widget?.ClassName
            }}
            name={title}
            children={children}
          />
        );
      default:
        return <h3>Нет виджета</h3>;
    }
  }, [widget, editMode, widgetParams, hideWidget, isIntersect]);
  return <>{Component}</>;
};

