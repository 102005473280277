import { createContext } from 'react';
import { AnalyticsTenderContextType } from '../types/context';

const empty = () => {};

const initialContext = {
  handleSelectBoardSet: empty,
  handleSelectBoard: empty,
  handleSetParams: empty,
  handleGetWidgetData: empty,
  handleReviewsModalOpen: empty,
  handleReviewsModalClose: empty,
  reviewsModalActive: false,
  modalWidget: null,
  handleSetModalWidget: empty
};

export const AnalyticsTenderContext = createContext<AnalyticsTenderContextType>(initialContext);
