import { FC } from 'react';
import { ILSProgress } from '@common/components/feedback/progress';
import { PROGRESS_COLOR, PROGRESS_TRAIL_COLOR } from '@modules/monitoring/children/dashboard/constants';

interface IProps {
  percent?: number;
  strokeColor?: string;
  trailColor?: string;
  label?: string;
}

// Компонент отвечающий за отображение прогресс доставок клиентам
export const DashboardProgress: FC<IProps> = ({
  percent = 0,
  strokeColor = PROGRESS_COLOR,
  trailColor = PROGRESS_TRAIL_COLOR,
  label = ''
}) => (
  <div className="monitoring-dashboard-progress-container">
    <div className="monitoring-dashboard-progress-label">{label}</div>
    <ILSProgress
      percent={percent}
      status="active"
      showInfo={true}
      strokeLinecap="square"
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
  </div>
);
