import { Dictionary, TableColumnName, Trip, Vehicle } from '@common/types';
import { Dispatch } from 'redux';
import {
  changeDriverInRouteRoutine,
  changeDriverInTripRoutine,
  changeExpeditorInRouteRoutine,
  changeExpeditorInTripRoutine,
  dictionaryPlannerRoutine,
  routeSaveRoutine,
  tripSaveRoutine
} from '../../../../actions';
import { DictionariesEndpoint } from '@common/types/general/api/dictionary';
import { RouteRecord, RoutesTableRecord, TripRecord } from '@modules/planning/children/by-date/types';
import { NOT_EDITABLE_ROW_DICTIONARIES } from '@modules/planning/children/by-date/constants';

export const handleRoutesTableUpdate = (dispatch: Dispatch) => (record: RoutesTableRecord, key: TableColumnName, value: unknown) => {
  const dictionary = record?.dictionary;

  if (NOT_EDITABLE_ROW_DICTIONARIES.includes(dictionary)) {
    return;
  }

  switch (key) {
    case TableColumnName.Status:
      switch (dictionary) {
        case Dictionary.Trip:
          const payloadTrip: {
            tripID: Trip['ID'];
            vehicleID: Vehicle['ID'];
            key: TableColumnName;
            value: unknown;
          } = {
            value,
            tripID: record.ID,
            vehicleID: record.VehicleID,
            key
          };
          dispatch(tripSaveRoutine(payloadTrip));
          break;
        case Dictionary.Route:
          const payload: {
            vehicleID: Vehicle['ID'];
            key: TableColumnName;
            value: unknown;
          } = {
            value,
            vehicleID: record.VehicleID,
            key
          };
          dispatch(routeSaveRoutine(payload));
          break;
        default:
          break;
      }
      break;
    case TableColumnName.DriverID:
      const driverID = (value as Array<number>)[(value as Array<number>).length - 1];
      if (dictionary === Dictionary.Route) {
        dispatch(changeDriverInRouteRoutine({ planID: (record as RouteRecord).PlanID, vehicleID: record.VehicleID, driverID }));
      } else {
        dispatch(
          changeDriverInTripRoutine({ planID: (record as TripRecord).PlanID, vehicleID: record.VehicleID, driverID, tripID: record.ID })
        );
      }
      break;
    case TableColumnName.ExpeditorID:
      if (dictionary === Dictionary.Route) {
        dispatch(
          changeExpeditorInRouteRoutine({
            planID: (record as RouteRecord).PlanID,
            vehicleID: record.VehicleID,
            expeditorID: value
          })
        );
      } else {
        //TODO changeExpeditorInTripRoutine not work (backend)
        dispatch(
          changeExpeditorInTripRoutine({
            planID: (record as TripRecord).PlanID,
            vehicleID: record.VehicleID,
            expeditorID: value,
            tripID: record.ID
          })
        );
      }
      break;
    case TableColumnName.Comment:
      if (dictionary === Dictionary.Trip) {
        dispatch(
          dictionaryPlannerRoutine({
            dictionary: Dictionary.Trip,
            operation: DictionariesEndpoint.Update,
            ids: record.ID,
            values: {
              [key]: value
            }
          })
        );
      }
      break;
    default:
      break;
  }
};
