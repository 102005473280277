import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { DecoratedTrips, TripAction, TripContactPerson, TripStatus } from '@modules/orders-and-trips/types/trip';
import { activeTripSelector, tripStatusSelector, tripsDataSelector } from '@modules/orders-and-trips/selectors/trip';
import { extraVehicleTypesSelector } from '@modules/orders-and-trips/selectors/extra-data';
import { cancelTripRoutine } from '../actions';
import { ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { CANCEL_TRIP_NOTIFY } from '@modules/orders-and-trips/constants/general';
import { Currency, DASH, JoinChar } from '@common/constants';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';
import { Driver, Vehicle } from '@common/types';
import { useDeleteTrip } from './use-details-container/use-delete-trip';

interface ReturnValues {
  tripDetails?: DecoratedTrips[number]['details'];
  tripStatus: TripStatus;
  tripCost?: string;
  vehicleTypes: VehicleType | null;
  contactPerson: TripContactPerson;
  tripActions?: Array<TripAction>;
  cancelTrip(): void;
  cancelAllowed: boolean;
  deleteTrip(): void;
  deleteAllowed: boolean;
  tripService: TenderOutFastDeliveryServices | null;
  tripDriver: Driver | null;
  tripVehicle: Vehicle | null;
}

export const useDetailsContainer = (): ReturnValues => {
  const vehicleTypes = useSelector(extraVehicleTypesSelector);
  const tripsData = useSelector(tripsDataSelector);
  const activeTrip = useSelector(activeTripSelector);
  const status = useSelector(tripStatusSelector);
  const dispatch = useDispatch();

  /** Контактные данные менеджера с ЦЭ-ИЛС */
  /**  TODO: получать данные менеджера с бэка, пока что данные менеджера нигде не сохраняются */
  const contactPerson: TripContactPerson = {
    name: 'не определено',
    phone: 'не определено'
  };

  const trip: DecoratedTrips[number] = activeTrip?.ID && !isEmpty(tripsData) ? tripsData?.[activeTrip?.ID] : undefined;
  const tripDetails = trip?.details;
  const tripCost = tripDetails?.price ? Number(tripDetails?.price).toLocaleString('ru') + JoinChar.Space + Currency.RUB : DASH;
  const tripActions = trip?.actions;
  const tripService = tripDetails?.service;
  const tripDriver = tripDetails?.driver ?? null;
  const tripVehicle = tripDetails?.vehicle ?? null;
  const cancelAllowed = !tripService || ![TripStatus.onRegistration, TripStatus.Issued, TripStatus.Pending].includes(status);
  const deleteAllowed = [TripStatus.New, TripStatus.Canceled].includes(status);

  const cancelTrip = () => {
    ILSUserNotifyConfirm('Внимание', CANCEL_TRIP_NOTIFY, () => {
      dispatch(cancelTripRoutine({ tripID: activeTrip?.ID, alias: tripService }));
    });
  };

  const { deleteTrip } = useDeleteTrip({ dispatch, status, activeTripID: activeTrip?.ID });

  return {
    tripDetails,
    tripStatus: status,
    tripCost,
    vehicleTypes,
    contactPerson,
    tripActions,
    cancelTrip,
    cancelAllowed,
    deleteTrip,
    deleteAllowed,
    tripService,
    tripDriver,
    tripVehicle
  };
};

