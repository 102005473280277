import { planningGetConfigsRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { DefaultPlannerConfigs } from '../types';
import { defaultPlannerConfigsSelector } from '../selectors/config';
import { UPDATE_INTERVAL } from '@common/constants';
import { PayloadAction } from '@reduxjs/toolkit';

function* planningGetConfigsWorker(
  action: PayloadAction<{
    forceUpdate: boolean;
  }>
) {
  const { request, success, failure } = planningGetConfigsRoutine;

  const { data, lastUpdate }: DefaultPlannerConfigs = yield select(defaultPlannerConfigsSelector);
  if (!data || !lastUpdate || lastUpdate + Number(UPDATE_INTERVAL) < new Date().getTime() || action.payload?.forceUpdate) {
    try {
      yield put(request({ lastUpdate: Date.now() }));

      const {
        data: { data }
      } = yield call(PlanningAPI.getPlanningConfigs);
      yield put(success({ data }));
    } catch (error) {
      yield put(failure({ error }));
    }
  }
}

export function* planningGetConfigsWatcher() {
  yield takeLeading(planningGetConfigsRoutine.trigger, planningGetConfigsWorker);
}
