import { NEW_VEHICLE_TIMELINE, REST_MODE_OPTIONS } from '../constants';
import { CellType, FilterCellType, ShowDeleted, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export const VEHICLE_TIMELINE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_VEHICLE_TIMELINE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 9,
      filtering: FilterCellType.String
    },
    {
      title: 'Клиентов, шт',
      dataIndex: 'MaxClients',
      key: 'MaxClients',
      editable: true,
      active: true,
      type: CellType.Uint,
      align: 'center',

      sorting: 8,
      filtering: FilterCellType.String
    },
    {
      title: 'Заказов, шт',
      dataIndex: 'MaxOrders',
      key: 'MaxOrders',
      editable: true,
      active: false,
      type: CellType.Uint,
      align: 'center',
      filtering: FilterCellType.String,

      sorting: 2
    },
    {
      title: 'Время в рейсе',
      dataIndex: 'MaxDuration',
      key: 'MaxDuration',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 7
    },
    {
      title: 'Часов без отдыха',
      dataIndex: 'DurationOfWork',
      key: 'DurationOfWork',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 6
    },
    {
      title: 'Часов отдыха',
      dataIndex: 'DurationOfRest',
      key: 'DurationOfRest',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 5
    },
    {
      title: 'Работ.до пер.',
      dataIndex: 'DurationOfFirstWork',
      key: 'DurationOfFirstWork',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 4
    },
    {
      title: 'Первый отдых',
      dataIndex: 'DurationOfFirstRest',
      key: 'DurationOfFirstRest',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 3
    },
    {
      title: 'Работа в день, ч',
      dataIndex: 'WorkPerDay',
      key: 'WorkPerDay',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      align: 'center',

      sorting: 2
    },
    {
      title: 'Режим отдыха',
      dataIndex: TableColumnName.DriverRestMode,
      key: TableColumnName.DriverRestMode,
      editable: true,
      active: true,
      type: CellType.Select,

      sorting: 1,
      fieldNames: BASE_FIELD_NAMES,

      options: REST_MODE_OPTIONS
    },
    {
      title: 'Км в рейсе',
      dataIndex: 'MaxDistance',
      key: 'MaxDistance',
      editable: true,
      active: false,
      type: CellType.Ufloat,
      align: 'center',
      filtering: FilterCellType.String,

      sorting: 1
    },
    {
      title: 'Км без отдыха',
      dataIndex: 'DistanceOfWork',
      key: 'DistanceOfWork',
      editable: true,
      active: false,
      type: CellType.Ufloat,
      align: 'center',
      filtering: FilterCellType.String,
      sorting: 9
    },
    {
      title: 'Время в маршруте',
      dataIndex: 'MaxDurationRoute',
      key: 'MaxDurationRoute',
      editable: true,
      active: false,
      type: CellType.TimeDouble,
      align: 'center',
      sorting: 7
    },
    {
      title: 'Км в маршруте',
      dataIndex: 'MaxDistanceRoute',
      key: 'MaxDistanceRoute',
      editable: true,
      active: false,
      type: CellType.Ufloat,
      align: 'center',
      filtering: FilterCellType.String,

      sorting: 7
    },
    {
      title: 'Рейсов, шт',
      dataIndex: 'MaxTrips',
      key: 'MaxTrips',
      editable: true,
      active: false,
      type: CellType.Uint,

      filtering: FilterCellType.String,
      sorting: 7,
      align: 'center'
    },
    {
      title: 'Выезд',
      dataIndex: 'MinStartTime',
      key: 'MinStartTime',
      editable: true,
      active: false,
      type: CellType.TimeDouble,
      sorting: 7,
      align: 'center'
    }
  ]
};
