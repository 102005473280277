import { useAppDispatch } from '@core/hooks';
import { useSelector } from 'react-redux';
import { monitoringSettingsSelector } from '@modules/settings/selectors';
import { useEffect } from 'react';
import { getPointsStatusesList } from '@modules/monitoring/children/event-details/actions';

export const useUpdateGetPointsStatusesList = () => {
  const dispatch = useAppDispatch();
  const maxInterval = useSelector(monitoringSettingsSelector).parameters.monitoringMaxDateInvterval;

  useEffect(() => {
    dispatch(getPointsStatusesList.trigger());
  }, []);
  return { maxInterval };
};
