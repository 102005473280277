import { IndexedArray, OrderActionTotal, OrdersIDs } from '@common/types';

export const orderActionDecorator = (orderActions: IndexedArray<OrderActionTotal> | Array<never>) => {
  const OrdersWP: OrdersIDs = [];

  for (const id in orderActions) {
    OrdersWP.push(orderActions[id].OrderID);
  }

  return { Orders: Array.from(new Set(OrdersWP)) };
};
