import Highlighter from 'react-highlight-words';
import { ILSInput, ILSTypographyText } from '@components/index';
import React, { ChangeEvent, FC } from 'react';

interface IFilterInput {
  state: string;
  setState: (state: string) => void;
  searchField: string;
}

export const FilterInput: FC<IFilterInput> = (props) => {
  const { state, setState, searchField } = props;

  return (
    <div className="p-2">
      <ILSInput
        placeholder={`Поиск ${searchField}`}
        className="mb-2"
        allowClear
        value={state}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
      />
    </div>
  );
};
type listOnFilterType = null | JSX.Element;
export const listOnFilter = (text: string, state: string): listOnFilterType => {
  const filteredValue = text?.toString().toLowerCase().includes(state?.toLowerCase());

  if (!filteredValue) {
    return <ILSTypographyText ellipsis title={text} children={text} />;
  }

  return (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[state]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />
  );
};
