import { ProjectPlanning } from '@modules/planning/children/by-date/types';

export const INITIAL_PROJECT_CATALOG: Omit<
  ProjectPlanning,
  | 'Name'
  | 'Start'
  | 'ID'
  | 'ProjectID'
  | 'Changed'
  | 'Comment'
  | 'Created'
  | 'IsMain'
  | 'MatrixCalc'
  | 'MatrixNull'
  | 'MatrixSize'
  | 'MatrixUpdated'
> = {
  Contact: null,
  Order: null,
  Plan: null,
  Vehicle: null,
  Depot: null,
  Driver: null,
  DepotOverload: null,
  FriendZone: null,
  ForbiddenZone: null,
  CrossDock: null,
  VehicleOverload: null,
  VehicleProfile: null,
  VehicleTimeline: null,
  VehicleType: {},
  Worktime: null,
  Restriction: null,
  Cargo: null,
  ProjectVehicle: null,
  RoadTrain: null,
  CouplingType: null,
  DriverTimeline: null,
  TransportCompany: null,
  Zone: null,
  Ramp: null,
  RampType: null,
  Info: {
    Order: {
      isFetching: false,
      lastUpdate: null
    },
    Plan: {
      isFetching: false,
      lastUpdate: null
    },
    Vehicle: {
      isFetching: false,
      lastUpdate: null
    },
    Depot: {
      isFetching: false,
      lastUpdate: null
    },
    Driver: {
      isFetching: false,
      lastUpdate: null
    },
    DepotOverload: {
      isFetching: false,
      lastUpdate: null
    },
    PermitZone: {
      isFetching: false,
      lastUpdate: null
    },
    ForbiddenZone: {
      isFetching: false,
      lastUpdate: null
    },
    CrossDock: {
      isFetching: false,
      lastUpdate: null
    },
    VehicleOverload: {
      isFetching: false,
      lastUpdate: null
    },
    VehicleProfile: {
      isFetching: false,
      lastUpdate: null
    },
    VehicleTimeline: {
      isFetching: false,
      lastUpdate: null
    },
    Worktime: {
      isFetching: false,
      lastUpdate: null
    },
    Restriction: {
      isFetching: false,
      lastUpdate: null
    },
    Cargo: {
      isFetching: false,
      lastUpdate: null
    },
    ProjectVehicle: {
      isFetching: false,
      lastUpdate: null
    },
    RoadTrain: {
      isFetching: false,
      lastUpdate: null
    },
    CouplingType: {
      isFetching: false,
      lastUpdate: null
    },
    DriverTimeline: {
      isFetching: false,
      lastUpdate: null
    },
    TransportCompany: {
      isFetching: false,
      lastUpdate: null
    },
    Zone: {
      isFetching: false,
      lastUpdate: null
    },
    Ramp: {
      isFetching: false,
      lastUpdate: null
    },
    RampType: {
      isFetching: false,
      lastUpdate: null
    }
  },
  catalogTotalCount: {}
};

export const INITIAL_INFO = {
  currentPlan: { lastUpdate: null, isFetching: false },
  plans: { 0: { lastUpdate: null, isFetching: false } },
  project: { lastUpdate: null, isFetching: false }
};

export const INITIAL_CURRENT_PLAN = {
  RouteTotal: {}
};
