import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { userGetRoleRoutine } from '@modules/current-user/actions';
import { AccountRole, IndexedArray } from '@common/types/dictionaries';

export const userGetRoleReducer = createReducer(authInitialState, {
  [userGetRoleRoutine.REQUEST]: onRequest,
  [userGetRoleRoutine.FAILURE]: onFailure,
  [userGetRoleRoutine.FULFILL]: onFulfill,
  [userGetRoleRoutine.SUCCESS]: (state, action: PayloadAction<{ data: IndexedArray<AccountRole> }>) => {
    const { data } = action.payload;
    state.accountRoles = Object.values<AccountRole>(data);
    return state;
  }
});
