import { IndexedArray } from '@common/types';
import { AnalyticsWidgetCatalogModel, WidgetType } from '@common/types/dictionaries/analytics';
import { generateUUID } from '@common/utils/general/uuid';
import { useAppSelector } from '@core/hooks';
import { selectedBoard } from '@modules/analytics/planning/children/planning-analytics/selectors';
import { Empty } from 'antd';
import React, { DragEvent, FC, useContext, useMemo } from 'react';

import { AnalyticsDraggableContext } from '@modules/analytics/common/context/draggable-context';
import { getPreview } from '@modules/analytics/common/utils/widget/get-preview';

type Props = {
  data?: IndexedArray<AnalyticsWidgetCatalogModel>;
  activeFilter: string;
};

const ILSWidgetCatalogList: FC<Props> = ({ data, activeFilter }) => {
  const boardID = useAppSelector(selectedBoard);

  const { setDraggableWidget } = useContext(AnalyticsDraggableContext);

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const filteredData = useMemo(() => {
    return Object.values(data).filter((item) => item.Category === activeFilter);
  }, [activeFilter]);

  const dragStart = (e: DragEvent, item: AnalyticsWidgetCatalogModel) => {
    e.dataTransfer.clearData();

    if (e.target instanceof HTMLDivElement) {
      const widget = {
        ID: generateUUID(),
        Type: item.Type[0] as WidgetType
      };
      setDraggableWidget && setDraggableWidget(widget);

      e.dataTransfer.setData(
        'text/plain',
        JSON.stringify({
          ...item,
          ID: widget.ID,
          BoardID: boardID,
          isNew: true
        })
      );
    }
  };
  return (
    <div className="analytics-widget-catalog-list">
      {filteredData &&
        filteredData.map((item) => (
          <div key={item.Name} className="analytics-widget-catalog-list-item" onDragStart={(e) => dragStart(e, item)} draggable="true">
            <div className="analytics-widget-catalog-list-item-img">
              {getPreview(item) && <img className="preview" src={getPreview(item)} alt={item.Description} />}
            </div>
            <div className="analytics-widget-catalog-list-item-name">
              {item.Name} {item.Description && ` - ${item.Description}`}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ILSWidgetCatalogList;
