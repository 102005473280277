import { defaultMarker } from '@core/components/map/icons';
import { IDrawLocal } from '../types/constants';

export const DRAW_LOCAL: IDrawLocal = {
  draw: {
    toolbar: {
      actions: {
        title: 'Отмена',
        text: 'Отмена отрисовки'
      },
      finish: {
        title: 'Закончить отрисовку',
        text: 'Закончить'
      },
      undo: {
        title: 'Удалить последнюю точку',
        text: 'Удалить последнее'
      },
      buttons: {
        polyline: 'Линия',
        polygon: 'Полигон',
        rectangle: 'Прямоугольник',
        circle: 'Круг',
        marker: 'Маркер',
        circlemarker: 'Круговой маркер'
      }
    },
    handlers: {
      circle: {
        tooltip: { start: 'Нажмите на кнопку и перемещайте мышь, чтобы нарисовать круг' },
        radius: 'Радиус'
      },
      circlemarker: { tooltip: { start: 'Кликните по карте, чтобы расположить круговой маркер.' } },
      marker: { tooltip: { start: 'Кликните по карте, чтобы расположить маркер.' } },
      polygon: {
        tooltip: {
          start: 'Нажмите на кнопку мыши, чтобы начать рисовать многоугольник.',
          cont: 'Кликните, чтобы продолжить рисовать многоугольника.',
          end: 'Кликните по первой точке, чтобы закончить отрисовку многоугольника.'
        }
      },
      polyline: {
        error: '<strong>Ошибка:</strong> острые углы недопустимы!',
        tooltip: {
          start: 'Нажмите на кнопку мыши, чтобы начать рисовать линию.',
          cont: 'Нажмите на кнопку мыши, чтобы продолжить рисовать линии.',
          end: 'Кликните по последней точке, чтобы закончить отрисовку линии.'
        }
      },
      rectangle: {
        tooltip: {
          start: 'Нажмите на кнопку и перемещайте мышь, чтобы нарисовать прямоугольник.'
        }
      },
      simpleshape: { tooltip: { end: 'Отпустите кнопку мыши, чтобы закончить отрисовку.' } }
    },
    marker:{
        icon: defaultMarker
    }
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: 'Сохранить изменения',
          text: 'Сохранить'
        },
        cancel: {
          title: 'Сбросить изменения',
          text: 'Отмена'
        },
        clearAll: {
          title: 'Очистить все слои',
          text: 'Очистить все'
        }
      },
      buttons: {
        edit: 'Изменить слои',
        editDisabled: 'Нет слоев для изменения',
        remove: 'Удалить слои',
        removeDisabled: 'Нет слоев для удаления'
      }
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'Для изменения перемещайте точки или маркеры.',
          subtext: 'Нажмите на отменить, чтобы вернуть изменения.'
        }
      },
      remove: { tooltip: { text: 'Нажмите на фигуру для ее удаления.' } }
    }
  },
  position: 'topright'
};

export const MAP_INIT_ERROR_MESSAGE = 'Map is not init';

export const MAP_SIZES = {
  width: '100%',
  height: '100%'
};
