import { VehicleTimeline } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils';

export const vehicleTimelines2table = (vehicleTimelines: Array<VehicleTimeline>) => {
  return createArrayFromIndexedArray(vehicleTimelines).map((vehicleTimeline, i) => {
    return {
      ...vehicleTimeline,
      key: vehicleTimeline.ID ?? 'row' + i
    };
  });
};
