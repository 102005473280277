import { DictionariesEndpoint, DictionaryOperationRequest } from '@common/types/general/api/dictionary';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { dictionaryPlannerRoutine, orderWithCargoUpdatingRoutine } from '../../actions';
import { CARGO_MODEL_FIELDS } from '@common/models/cargo/config';
import { Cargo, Dictionary, TableColumnName, TableRecord } from '@common/types';
import { cleanIsNilObjectFields } from '@common/utils/helpers/object/clean-empty-fields';
import { DictionaryAPI } from '@common/api/common';
import { head } from 'lodash';

/** Worker создаёт новый груз,
 * сохраняя неизмененным остальные параметры ранее присвоенного груза
 * затем вызывает стандартную рутину обновления заявки
 * обновляя не переданные поля, а CargoID для заявки */
export function* orderWithCargoUpdatingWorker(
  action: PayloadAction<
    DictionaryOperationRequest & {
      dictionaryFetching?: boolean;
      projectListUpdate?: boolean;
      record: TableRecord;
      messages?: {
        request: string;
        success: string;
        failure: string;
        fulfill: string;
      };
    }
  >
) {
  try {
    /** Формируем Груз с полями из заявки (record), обновляя только параметр, который пришел в values */
    const cargo = CARGO_MODEL_FIELDS.reduce((cargo, key) => {
      switch (key) {
        /** Подменяем название */
        case TableColumnName.Name:
          cargo[key] = `Груз к заявке №${action.payload.record.ID}`;
          return cargo;
        /** Очищаем название */
        case TableColumnName.Description:
          cargo[key] = null;
          return cargo;
        /** values создания Груза должны иметь параметр IsWeight (мы создаем "не весовой" груз) */
        case TableColumnName.IsWeight:
          cargo[key] = 0;
          return cargo;
        default:
          cargo[key] = action.payload.values[key] || action.payload.record[key];
          return cargo;
      }
    }, {});

    /** values создания Груза должны быть массивом + убираем пустые поля */
    const values = [cleanIsNilObjectFields(cargo)];

    const params: DictionaryOperationRequest = {
      dictionary: Dictionary.Cargo,
      operation: DictionariesEndpoint.Create,
      values
    };

    /** Создаём Груз */
    const {
      data: { data }
    } = yield call(DictionaryAPI.operation, params);

    const cargoID = head(data as Array<Cargo>)?.ID;

    /** Прикрепляем Груз к заявке */
    if (cargoID) {
      yield put(
        dictionaryPlannerRoutine.trigger({
          ...action.payload,
          values: { [TableColumnName.CargoID]: [cargoID] }
        })
      );
    } else {
      new Error('Не удалось обновить заявку');
    }
  } catch (error) {
    yield put(orderWithCargoUpdatingRoutine.failure({ error }));
  }
}

export function* orderWithCargoUpdatingWatcher() {
  yield takeEvery(orderWithCargoUpdatingRoutine.trigger, orderWithCargoUpdatingWorker);
}
