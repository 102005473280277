import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { getTenderNotifySettingsRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearchStore } from '@modules/tender-platform/children/tender-search/types';
import { onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { NotificationsInfo } from '../types/notifications';

export const getNotificationSettings = createReducer(initialState, {
  [getTenderNotifySettingsRoutine.REQUEST]: onRequest,
  [getTenderNotifySettingsRoutine.SUCCESS]: (
    state: TenderSearchStore,
    action: PayloadAction<{
      data: NotificationsInfo;
    }>
  ) => {
    const { data } = action.payload;
    state.notificationSettings = data;
    return state;
  },
  [getTenderNotifySettingsRoutine.FULFILL]: onFulfill
});
