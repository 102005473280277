import { useSelector } from 'react-redux';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { catalogueTableDataSourceSelector } from '../selectors/components/catalog-table-data-source-selector';
import { Dictionary, TableConfig } from '@common/types';
import { getColsForGrouping } from '@core/selectors/grouping';

interface IProps {
  dataDecorator?: any;
  data?: any;
  filterID?: number;
  childrenDictionary?: Dictionary;
  childrenData?: any;
  config: TableConfig;
  tableName?: string;
}

export const useCatalogTableDataSourceSelector = ({
  dataDecorator,
  data,
  filterID,
  childrenDictionary,
  childrenData,
  config,
  tableName
}: IProps) => {
  const dataSource = dataDecorator?.(data, filterID, childrenDictionary ? childrenData : EMPTY_ARRAY_DATA) ?? data;
  const colsForGrouping = useSelector((state) => getColsForGrouping(state, config, tableName));

  const dataSourceForTable = useSelector((state) => catalogueTableDataSourceSelector(state, dataSource, colsForGrouping, config?.columns));
  return { dataSourceForTable };
};
