import { Dictionary, ProjectVehicle, TableColumnName, Vehicle } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils';
import { getTakeForPlanner } from '@common/models/vehicle/helpers/get-take-for-planner';
import { isNil } from 'lodash';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators/get-vehicle-route-color';
import { EMPTY_STRING } from '@common/constants';
import { getProjectVehicle } from '@common/models/vehicle/helpers/get-project-vehicle';

export const plannerPlanningVehiclesToTable = ({
  vehicles,
  projectVehicles
}: {
  vehicles: Array<Vehicle>;
  projectVehicles?: Array<ProjectVehicle> | null;
}) => {
  return createArrayFromIndexedArray(vehicles).map((vehicle) => {
    const ProjectVehicle = getProjectVehicle({ vehicle, projectVehicles });
    const TakeForPlanner = getTakeForPlanner(vehicle, ProjectVehicle);
    const rowParams = {
      isDeleted: vehicle?.Deleted
    };

    const isSpeedProfileDeleted = !isNil(vehicle.SpeedProfileDeleted);
    const isVehicleTariffDeleted = !isNil(vehicle.VehicleTariffDeleted);

    return {
      ...vehicle,
      key: vehicle.ID,
      SpeedProfileID: isSpeedProfileDeleted ? null : vehicle.SpeedProfileID,
      SpeedProfileEnum: isSpeedProfileDeleted ? null : vehicle.SpeedProfileEnum,
      VehicleTariffID: isVehicleTariffDeleted ? null : vehicle.VehicleTariffID,
      VehicleTariffEnum: isVehicleTariffDeleted ? null : vehicle.VehicleTariffEnum,
      StartDepotID: vehicle.StartDepotID && [vehicle.StartDepot?.DepotTypeID, vehicle.StartDepotID],
      EndDepotID: vehicle.EndDepotID && [vehicle.EndDepot?.DepotTypeID, vehicle.EndDepotID],
      Deleted: Boolean(vehicle.Deleted),
      [TableColumnName.TripTemplateID]:
        vehicle.TripTemplateEnum && !Array.isArray(vehicle.TripTemplateEnum)
          ? Object.keys(vehicle.TripTemplateEnum).map((key) => parseInt(key))
          : [],
      TripTemplateEnum: vehicle.TripTemplateEnum,
      ForbiddenZones: vehicle.ForbiddenZoneID,
      RouteColor: getVehicleRouteColor(vehicle),
      StartTime: ProjectVehicle?.StartTime ?? EMPTY_STRING,
      StartDate: ProjectVehicle?.StartDate || null,
      TakeForPlanner,
      dictionary: Dictionary.Vehicle,
      rowParams
    };
  });
};
