import { TenderStatus } from '@modules/tender-platform/types';
import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';
import { TableColumnName } from '@common/types';

export enum CardTabTitle {
  GeneralInfo = 'Общая информация',
  Lots = 'Лот',
  Offers = 'Предложения',
  Offer = 'Предложение',
  Participants = 'Список участников',
  Summary = 'Сводная таблица',
  Documents = 'Документы',
  Accept = 'Принять участие',
  Cargo = 'Груз',
  Route = 'Маршрут',
  Condition = 'Условия заказа',
  Template = 'Сохраненные шаблоны'
}

export enum CardTab {
  GeneralInfo = 'GeneralInfo',
  Lots = 'Lots',
  Offers = 'Offers',
  Participants = 'Participants',
  Summary = 'Summary',
  Documents = 'Documents',
  Cargo = 'Cargo',
  Route = 'Route',
  Condition = 'Condition',
  Template = 'Template'
}

export const TENDER_CREATE_FROM_PLANNER_MENU = {
  Name: CardTab.GeneralInfo,
  Mass: CardTab.Cargo,
  RouteList: CardTab.Route,
  StartPrice: CardTab.Condition,
  Documents: CardTab.Documents
};

export const TENDER_STARTED_MENU = [
  CardTab.GeneralInfo,
  CardTab.Cargo,
  CardTab.Route,
  CardTab.Condition,
  CardTab.Documents,
  CardTab.Offers
];
export const TENDER_CREATING_MENU = [
  CardTab.GeneralInfo,
  CardTab.Cargo,
  CardTab.Route,
  CardTab.Condition,
  CardTab.Template,
  CardTab.Documents
];
export const TENDER_UPDATING_MENU = [CardTab.GeneralInfo, CardTab.Cargo, CardTab.Route, CardTab.Condition, CardTab.Documents];
export const TENDER_RESTART_MENU = TENDER_UPDATING_MENU;
export const TENDER_CREATING_FROM_PLANNER_MENU = TENDER_UPDATING_MENU;
export const LOT_TABS = [CardTab.Cargo, CardTab.Route, CardTab.Condition, CardTab.Template];

export enum ListTabTitle {
  New = 'Новые',
  Active = 'Active',
  Winner = 'Winner',
  Tenders = 'Все тендеры',
  CollectingOffers = 'Активные',
  Overed = 'Завершённые',
  Offers = 'Участвую',
  OnApproval = 'На утверждении',
  UnderRevision = 'На доработке',
  Approved = 'Утвержден',
  SummingResults = 'Подведение итогов',
  ApprovalResults = 'Утверждение итогов',
  Executed = 'На исполнении',
  Cancelled = 'Отменен',
  Completed = 'Исполнен',
  NotTake = 'Не состоялся'
}

export enum ListTab {
  New = 'New',
  Tenders = 'Tenders',
  Offers = 'Offers',
  Active = 'Active',
  Overed = 'Overed',
  Winner = 'Winner',
  OnApproval = 'OnApproval',
  UnderRevision = 'UnderRevision',
  Approved = 'Approved',
  CollectingOffers = 'CollectingOffers',
  SummingResults = 'SummingResults',
  ApprovalResults = 'ApprovalResults',
  Executed = 'Executed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  NotTake = 'NotTake'
}

export const LIST_TABS_CARGO_OWNER = [
  ListTab.Tenders,
  ListTab.New,
  ListTab.OnApproval,
  ListTab.UnderRevision,
  ListTab.Approved,
  ListTab.CollectingOffers,
  ListTab.SummingResults,
  ListTab.ApprovalResults,
  ListTab.Executed,
  ListTab.Completed,
  ListTab.Cancelled,
  ListTab.NotTake
];

export const LIST_TABS_CARGO_OWNER_SIMPLE = [
  ListTab.Tenders,
  ListTab.New,
  ListTab.CollectingOffers,
  ListTab.SummingResults,
  ListTab.ApprovalResults,
  ListTab.Executed,
  ListTab.Completed,
  ListTab.Cancelled,
  ListTab.NotTake
];

export const LIST_TABS_CARRIER = [ListTab.CollectingOffers, ListTab.Overed, ListTab.Offers, ListTab.Executed, ListTab.Completed];
export const FORMED_TENDERS = [
  TenderStatus.SummingResults,
  TenderStatus.ApprovalResults,
  TenderStatus.Executed,
  TenderStatus.Cancelled,
  TenderStatus.Completed,
  TenderStatus.NotTake
];
export const NOT_EDITABLE_TENDERS = [TenderStatus.Approved, TenderStatus.CollectingOffers].concat(FORMED_TENDERS);
export const TENDER_WITH_RESET_OFFERS = [TenderStatus.New, TenderStatus.Cancelled];
export const NOT_EDITABLE_TENDERS_LOGISTICIAN = NOT_EDITABLE_TENDERS.concat(TenderStatus.UnderRevision);

export const MAX_DATA_COUNT = 9999;
export const EXCLUDE_CARRIER_COLUMN_IF_NOT_TABS = [ListTab.Completed, ListTab.Executed];
export const EXCLUDE_COLUMNS_IF_EXECUTED = [ListTab.Executed];
export const EXCLUDED_COLUMNS = [TableColumnName.Carrier, TableColumnName.Countdown];

export const PERMITTED_UPLOAD_TENDER_FILE_TYPES = [
  PermittedUploadFileType.png,
  PermittedUploadFileType.jpeg,
  PermittedUploadFileType.pdf,
  PermittedUploadFileType.msword,
  PermittedUploadFileType.zip,
  PermittedUploadFileType.rar,
  PermittedUploadFileType.documentWordML,
  PermittedUploadFileType.sheetML,
  PermittedUploadFileType.excel,
  PermittedUploadFileType.zipCompressed,
  PermittedUploadFileType.textPlain
];

export const PERMITTED_UPLOAD_TENDER_FILE_EXTENSIONS: Array<PermittedUploadFileExtensions> = [
  PermittedUploadFileExtensions.jpg,
  PermittedUploadFileExtensions.jpeg,
  PermittedUploadFileExtensions.png,
  PermittedUploadFileExtensions.gif,
  PermittedUploadFileExtensions.bmp,
  PermittedUploadFileExtensions.zip,
  PermittedUploadFileExtensions.rar,
  PermittedUploadFileExtensions.doc,
  PermittedUploadFileExtensions.docx,
  PermittedUploadFileExtensions.pdf,
  PermittedUploadFileExtensions.xls,
  PermittedUploadFileExtensions.xlsx
];

export const PERMITTED_UPLOAD_TENDER_FILE_MAX_SIZE = 20971520;
export const MAX_UPLOAD_TENDER_FILES = 10;

export const TENDER_UPLOAD_CONFIGURATION = {
  fileMaxSize: PERMITTED_UPLOAD_TENDER_FILE_MAX_SIZE,
  defaultPermittedTypes: PERMITTED_UPLOAD_TENDER_FILE_TYPES,
  defaultFormats: PERMITTED_UPLOAD_TENDER_FILE_EXTENSIONS,
  buttonText: 'Добавить документ'
};

export const DELAY_FOR_GET_DISTANCE_QUERY = 4000;
