import { call, put, takeLatest } from 'redux-saga/effects';
import { tenderLotIndexRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderChangeTemplateRoutine } from '@modules/tender-platform/actions';

function* tenderLotIndexWorker(
  action: PayloadAction<{
    lotID: number | string;
  }>
) {
  const { request, failure, fulfill } = tenderLotIndexRoutine;
  const { lotID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.indexLot, { lotID });
    yield put(tenderChangeTemplateRoutine.trigger({ TenderTemplate: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderLotIndexWatcher() {
  yield takeLatest(tenderLotIndexRoutine.trigger, tenderLotIndexWorker);
}
