import { createReducer } from '@reduxjs/toolkit';
import { referenceRoutine } from '../actions';
import initialState from '../store';

const reference = createReducer(initialState, {
  [referenceRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [referenceRoutine.SUCCESS]: (state, action: ReturnType<typeof referenceRoutine.success>) => {
    const { level1, level2, level3 } = action.payload;

    state.isFetching = false;
    state.level1 = level1;
    state.level2 = level2;
    state.level3 = level3;
    return state;
  }
});

export default reference;
