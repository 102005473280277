import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogCloneRoutine } from '@modules/catalog/actions';
import { DataType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';

const cloneDictionaryReducer = createReducer(initialState, {
  [catalogCloneRoutine.REQUEST]: onRequest,
  [catalogCloneRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: DataType;
      dictionary: Dictionary;
    }>
  ) => {
    let { data, dictionary } = action.payload;
    let oldData = state[dictionary].data;

    const arrayOfData = Object.values(data);

    for (let item of arrayOfData) {
      oldData.push({ ...item, key: item.ID });
    }

    state[dictionary].data = oldData;

    return state;
  },
  [catalogCloneRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;

    return state;
  },
  [catalogCloneRoutine.FULFILL]: onFulfill
});

export default cloneDictionaryReducer;
