import { createSelector } from 'reselect';
import { isEmpty, omitBy, pick, pickBy } from 'lodash';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { routesToTable } from '@modules/planning/children/by-date/decorators/table/routes-to-table';
import { PlanResponse } from '@common/types/dictionaries/plan';
import { Dictionary, Order, ReferenceTablesData, TableColumnName, Trip, Vehicle } from '@common/types';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';
import {
  projectCommonDictionarySelector,
  projectDepotSelector,
  projectForbiddenZoneSelector,
  projectOrderSelector,
  projectProjectVehicleSelector,
  projectZoneSelector,
  vehiclesSelector
} from '@modules/planning/children/by-date/selectors/project';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { activeRestrictionIDsFiltersSelector } from '@modules/planning/children/by-date/selectors/restriction/active-restriction-ids-filters';
import { pickByRestrictionId } from '@modules/planning/children/by-date/decorators/filters/pick-by-restriction-id';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';

export const currentPlanSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate?.currentPlan,
  (currentPlan) => currentPlan as PlanResponse
);

export const vehicleIDByTripIDSelector = createSelector(
  currentPlanSelector,
  (state: PlanningByDateState, tripID?: Trip['ID']) => tripID,
  (currentPlan, tripID) =>
    Object.values(currentPlan.RouteTotal).find(({ TripTotal }) => {
      return Object.keys(TripTotal).some((id) => compareAsString(id, tripID));
    })?.Vehicle.ID
);

export const routesDataSourceSelector = createSelector(
  currentPlanSelector,
  (_: PlanningByDateState, setTriggerMapFocus: SetTriggerMapFocus) => setTriggerMapFocus,
  (currentPlan, setTriggerMapFocus) => {
    return routesToTable(currentPlan, setTriggerMapFocus);
  }
);

export const activePlanNoticeSelector = createSelector(currentPlanSelector, (plan) => plan?.PlanNotice);

export const activePlanPenaltiesSelector = createSelector(currentPlanSelector, (plan) => plan?.PlanPenalty);

export const planRoutesSelector = createSelector(currentPlanSelector, (currentPlan) => {
  return currentPlan?.RouteTotal;
});

export const planResolvedOrdersIDsSelector = createSelector(currentPlanSelector, (currentPlan) => {
  const orders = createArrayFromIndexedArray(currentPlan?.RouteTotal).reduce((resolvedOrdersIDs: Array<Order['ID']>, { TripTotal }) => {
    createArrayFromIndexedArray(TripTotal).forEach(({ WaypointTotal }) =>
      createArrayFromIndexedArray(WaypointTotal).forEach(({ OrderActionTotal }) => {
        createArrayFromIndexedArray(OrderActionTotal).forEach(({ OrderID }) => {
          resolvedOrdersIDs.push(OrderID);
        });
      })
    );
    return resolvedOrdersIDs;
  }, []);
  return new Set(orders);
});

export const planResolvedOrdersSelector = createSelector(
  projectOrderSelector,
  planResolvedOrdersIDsSelector,
  (allOrders, resolvedOrdersIDs) => {
    return pickBy(allOrders, (order, orderID) => {
      return resolvedOrdersIDs.has(Number(orderID));
    });
  }
);

export const planUnresolvedOrdersSelector = createSelector(
  projectOrderSelector,
  planResolvedOrdersIDsSelector,
  (allOrders, resolvedOrdersIDs) => {
    return omitBy(allOrders, (order, orderID) => {
      return resolvedOrdersIDs.has(Number(orderID));
    });
  }
);
export const unresolvedOrderIsEmptySelector = createSelector(planUnresolvedOrdersSelector, (UnresolvedOrders) => {
  return isEmpty(UnresolvedOrders);
});

export const planTripIDsSelector = createSelector(currentPlanSelector, (currentPlan) => {
  return createArrayFromIndexedArray(currentPlan?.RouteTotal).reduce((tripIDs: Array<Trip['ID']>, route) => {
    Object.keys(route.TripTotal).forEach((id) => tripIDs.push(Number(id)));
    return tripIDs;
  }, []);
});

export const routeTripIDsSelector = createSelector(
  currentPlanSelector,
  (_: PlanningByDateState, routeID: Vehicle['ID']) => routeID,
  (currentPlan, routeID) => {
    const route = Object.values(currentPlan.RouteTotal ?? {}).find(({ Vehicle }) => compareAsString(routeID, Vehicle.ID));
    return Object.keys(route?.TripTotal ?? {}).map(Number);
  }
);

export const routesIDsSelector = createSelector(currentPlanSelector, (currentPlan) => {
  return Object.keys(currentPlan.RouteTotal).map(Number);
});

export const routesSelectedSelector = createSelector(
  currentPlanSelector,
  selectedRouteTableRowsSelector,
  (currentPlan, { selectedRoutesIDs }) => {
    return createArrayFromIndexedArray(pick(currentPlan.RouteTotal, selectedRoutesIDs));
  }
);

export const routesSubMenuItemsSelector = createSelector(currentPlanSelector, (currentPlan) => {
  return Object.values(currentPlan.RouteTotal)?.map(({ Vehicle, ...route }) => {
    return {
      ID: Vehicle.ID,
      name: route.FullName
    };
  });
});

export const tendersSelector = createSelector(currentPlanSelector, (currentPlan) => {
  return Object.values(currentPlan.RouteTotal)?.reduce((tenders, route) => {
    tenders[route.Vehicle.ID] = route.TenderID;
    return tenders;
  }, {});
});

export const planHasTenderSelector = createSelector(tendersSelector, (tenders) => {
  return Object.values(tenders).some(Boolean);
});

export const planUsedVehiclesInRoutesSelector = createSelector(planRoutesSelector, (RouteTotal) => {
  return createArrayFromIndexedArray(RouteTotal).map((route) => {
    return route.Vehicle;
  });
});

export const planUsedVehiclesIDsSelector = createSelector(planUsedVehiclesInRoutesSelector, (planUsedVehicles) => {
  const vehiclesIDs = planUsedVehicles.map((vehicle) => {
    return vehicle.ID;
  });
  return new Set(vehiclesIDs);
});

export const planUsedVehiclesSelector = createSelector(vehiclesSelector, planUsedVehiclesIDsSelector, (vehicles, planUsedVehiclesIDs) => {
  return pickBy(vehicles, (vehicle, vehicleID) => {
    return planUsedVehiclesIDs.has(Number(vehicleID));
  });
});

export const vehiclesForDataSourceSelector = createSelector(
  vehiclesSelector,
  planUsedVehiclesIDsSelector,
  activeRestrictionIDsFiltersSelector,
  (projectVehicles, usedVehiclesIDs, activeRestrictionIDs) => {
    const notUsedVehicle = omitBy(projectVehicles, (vehicle, vehicleID) => {
      return usedVehiclesIDs.has(Number(vehicleID));
    });
    return pickByRestrictionId(notUsedVehicle, activeRestrictionIDs);
  }
);

export const DICTIONARY_FOR_PLANNING_VEHICLE = [
  Dictionary.Vehicle,
  Dictionary.SpeedProfile,
  Dictionary.TripTemplate,
  Dictionary.VehicleTariff,
  Dictionary.VehicleTimeline,
  Dictionary.VehicleProfile,
  Dictionary.VehicleOverload,
  Dictionary.RoadTrain,
  Dictionary.Restriction,
  Dictionary.Depot,
  Dictionary.Driver,
  Dictionary.CouplingType,
  Dictionary.VehicleType,
  Dictionary.ForbiddenZone,
  Dictionary.Zone
];

export const vehiclesValidationDataSourceSelector = createSelector(
  vehiclesSelector,
  projectProjectVehicleSelector,
  (vehicleData, projectVehicle) => {
    const vehiclesValidationDataSource = plannerPlanningVehiclesToTable({
      vehicles: createArrayFromIndexedArray(vehicleData),
      projectVehicles: createArrayFromIndexedArray(projectVehicle)
    });
    return { vehiclesValidationDataSource };
  }
);

export const planningVehicleTableOptionsComponentSelector = createSelector(
  (state: PlanningByDateState) => projectCommonDictionarySelector(state, DICTIONARY_FOR_PLANNING_VEHICLE),
  (options) => options
);

export const vehiclesRefDataSelector = createSelector(
  projectDepotSelector,
  projectForbiddenZoneSelector,
  projectZoneSelector,
  planningVehicleTableOptionsComponentSelector,
  (
    Depot,
    ForbiddenZone,
    Zone,
    {
      Driver,
      CouplingType,
      VehicleProfile,
      VehicleTimeline,
      VehicleOverload,
      SpeedProfile,
      Restriction,
      VehicleTariff,
      TripTemplate,
      RoadTrain,
      VehicleType
    }
  ) => {
    const depotReference = createDepotCascaderOptions(Depot);

    const refData: ReferenceTablesData = {
      StartDepotID: depotReference,
      EndDepotID: depotReference,
      DriverID: Driver,
      ExpeditorID: Driver,
      RearCouplingTypeID: CouplingType,
      FrontCouplingTypeID: CouplingType,
      VehicleProfileID: VehicleProfile,
      VehicleTimelineID: VehicleTimeline,
      VehicleOverloadID: VehicleOverload,
      SpeedProfileID: SpeedProfile,
      RestrictionID: Restriction,
      VehicleTariffID: VehicleTariff,
      ForbiddenZoneID: {
        options: ForbiddenZone,
        dictionary: Dictionary.ForbiddenZone,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      TripTemplateID: TripTemplate,
      FriendZoneID: {
        options: Zone,
        dictionary: Dictionary.Zone,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RoadTrain,
      [TableColumnName.VehicleTypeID]: VehicleType
    };

    return { refData };
  }
);

export const planningNotFilteredVehiclesVehicleSelector = createSelector(
  vehiclesForDataSourceSelector,
  projectProjectVehicleSelector,
  (vehicleData, projectVehicle) => {
    const vehiclesFullDataSource = plannerPlanningVehiclesToTable({
      vehicles: createArrayFromIndexedArray(vehicleData),
      projectVehicles: createArrayFromIndexedArray(projectVehicle)
    });
    return { vehiclesFullDataSource };
  }
);
