import { documentsIndexRoutine, getDocumentMetricsRoutine, suggestContractorRoutine } from '@modules/documents/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { DocumentIndexPayload, DocumentType } from '@modules/documents/types/document-type';
import { getDocumentState, getDocumentType } from '@modules/documents/selectors';
import { DocumentState } from '@modules/documents/types/document-state';
import { PayloadAction } from '@reduxjs/toolkit';

export function* documentsIndexWorker(
  action: PayloadAction<{
    documentType?: DocumentType;
    loadMetrics?: boolean;
  }>
) {
  const { request, success, failure, fulfill } = documentsIndexRoutine;

  try {
    yield put(request());

    let currentDocumentType: DocumentType = yield select(getDocumentType);

    if (action.payload?.documentType) {
      currentDocumentType = action.payload.documentType;
      if (action.payload?.loadMetrics) {
        yield put(getDocumentMetricsRoutine.trigger({ type: currentDocumentType }));
      }
    }

    const state: DocumentState = yield select(getDocumentState);
    const payload: DocumentIndexPayload = {
      type: currentDocumentType,
      deleted: +(state === DocumentState.Deleted),
      archive: +(state === DocumentState.Archived),
      ...action.payload
    };

    const { data } = yield call(Documents.indexDocuments, payload);

    const fields = data.data.metrics ? Object.values(data.data.metrics) : [];

    yield put(
      success({
        data: Object.values(data.data.data),
        fields,
        documentType: currentDocumentType
      })
    );
    yield put(suggestContractorRoutine.trigger({ resetData: [] }));
  } catch (e) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* documentsIndexWatcher() {
  yield takeLatest(documentsIndexRoutine.trigger, documentsIndexWorker);
}

