import { FC } from 'react';
import { ILSButton } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import { TripDocument } from '@modules/orders-and-trips/types/trip';
import { downloadTripDocument } from '@modules/orders-and-trips/helpers/download-trip-document';

interface IProps {
  document: TripDocument;
  disabled: boolean;
  onDelete: (documentID: number) => void;
}

export const DocumentsUploadCard: FC<IProps> = ({ document }) => {
  const fileExtension = document?.extension ?? document?.name.split('.').pop() ?? EMPTY_STRING;

  const handleDownloadDocument = () => {
    downloadTripDocument(document?.link);
  };

  return (
    <div className="documents-upload-card">
      <div className="documents-upload-card__group">
        <p className="documents-upload-card__extension">{fileExtension}</p>
        <p className="documents-upload-card__name">{document.name}</p>
      </div>
      <div className="documents-upload-card__group">
        <ILSButton icon="download" type="text" className="documents-upload-card__icon" onClick={handleDownloadDocument} />
        {/* Кнопка удаления документа. Не удаляю компонент, на случай, если необходимо будет вернуть. */}
        {/* <ILSButton icon="delete" type="text" className="documents-upload-card__icon" onClick={handleDelete} disabled={disabled} /> */}
      </div>
    </div>
  );
};
