import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { deleteBoardRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';

export const deleteBoardReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [deleteBoardRoutine.REQUEST]: (state, action: PayloadAction<AnalyticsBoardModel>) => {
    state.Boards.updatingBoard = action?.payload?.ID;
    state.Boards.isFetching = true;
    return state;
  },
  [deleteBoardRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      ID: string | number;
    }>
  ) => {
    const { ID } = action.payload;

    if (ID && state.Boards?.data) {
      const newSets = Object.values(state.Boards?.data).filter((item) => item.ID !== ID);

      state.Boards.data = newSets.reduce((prev, cur) => {
        prev[cur.ID] = cur;

        return prev;
      }, {});
    }

    return state;
  },
  [deleteBoardRoutine.FAILURE]: (state) => {
    return state;
  },
  [deleteBoardRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.Boards.isFetching = false;
    state.Boards.updatingBoard = undefined;
    return state;
  }
});
