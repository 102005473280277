import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { changeDriverInRouteRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { Driver, Vehicle } from '@common/types';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';

export const changeDriverInRouteReducer = createReducer(initialState, {
  [changeDriverInRouteRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      driverID: Driver['ID'];
      vehicleID: Vehicle['ID'];
    }>
  ) => {
    const { driverID, vehicleID } = action.payload;
    const DriverID = [driverID];
    const DriverEnum = {
      [driverID]:
        createArrayFromIndexedArray(state.project?.Driver).find(({ ID }) => compareAsString(ID, driverID))?.Name ?? `Водитель №${driverID}`
    };
    state.currentPlan.RouteTotal[vehicleID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].DriverID = DriverID;
    state.currentPlan.RouteTotal[vehicleID].DriverEnum = DriverEnum;

    for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].DriverID = DriverID;
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].DriverEnum = DriverEnum;
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
    }
    return state;
  },
  [changeDriverInRouteRoutine.REQUEST]: onRequest,
  [changeDriverInRouteRoutine.FAILURE]: onFailure,
  [changeDriverInRouteRoutine.FULFILL]: onFulfill
});
