import { FC, memo, useState } from 'react';
import { ILSTypographyTitle } from '@common/components/general/typography';
import { ILSModal, ILSSwitch } from '@components/index';
import { CommonError } from '@modules/planning/children/by-date/components/tables/common-error';
import { COMMON, ERROR_TABLE_DICTIONARY, ERROR_TYPES } from '@modules/planning/children/by-date/constants';
import { ErrorTable } from '@modules/planning/children/by-date/containers/validate/error-table';
import { ILSPlanningValidateSummary } from '@modules/planning/children/by-date/containers/validate/summary';
import { useValidation } from '@modules/planning/children/by-date/hooks/use-validation';
import { ValidateSummaryInfo } from './validate-summary-info';

export const ILSPlanningValidateContainer: FC = memo(() => {
  const {
    onValidatePlan,
    handleCancel,
    validationData,
    activePlanID,
    errorComponent,
    showValidateContainer,
    setShowValidateContainer,
    setComponent,
    activePlanErrors,
    onUpdateErrorRecordsFromTable,
    TableWithErrorRecords,
    onStartPlanning
  } = useValidation();

  const [showAll, setShowAll] = useState<boolean>(false);

  return (
    <>
      <ILSPlanningValidateSummary
        activePlanErrors={activePlanErrors}
        onValidatePlan={onValidatePlan}
        setShowValidateContainer={setShowValidateContainer}
      />
      <ValidateSummaryInfo onStartPlanning={onStartPlanning} />
      {activePlanID && (
        <>
          {validationData[activePlanID] && (
            <ILSModal
              visible={showValidateContainer}
              onOk={onUpdateErrorRecordsFromTable}
              onCancel={handleCancel}
              okText={'Обновить'}
              cancelText="Вернуться"
              className="planning-validation-container"
            >
              <ErrorTable setComponent={setComponent} errorComponent={errorComponent} />
              {!!errorComponent.errors.length && errorComponent.errorType && errorComponent.table && errorComponent.table !== COMMON ? (
                <>
                  <ILSSwitch
                    disabled={!(errorComponent.failKeys?.length && errorComponent.failFieldName)}
                    checked={!showAll}
                    onChange={() => setShowAll(!showAll)}
                    checkedChildren="Показать все"
                    unCheckedChildren="Только ошибки"
                  />
                  <ILSTypographyTitle
                    children={ERROR_TABLE_DICTIONARY[errorComponent.table] + ': ' + ERROR_TYPES[errorComponent.errorType]}
                    className={'no-margin text-center'}
                    level={5}
                  />
                  {TableWithErrorRecords && (
                    <TableWithErrorRecords
                      showAll={showAll}
                      errors={errorComponent.errors}
                      failFieldName={errorComponent.failFieldName}
                      failKeys={errorComponent.failKeys}
                    />
                  )}
                </>
              ) : (
                errorComponent.errorType && <CommonError dataSource={errorComponent.errors} kind={errorComponent.errorType} />
              )}
            </ILSModal>
          )}
        </>
      )}
    </>
  );
});
