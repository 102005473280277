import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/by-date/store';
import { addOrderForVehicleRoutine } from '@modules/planning/children/by-date/actions';
import { isEmpty } from 'lodash';
import { RouteTotal } from '@common/types/dictionaries/route';
import { IndexedArray, PlanTotal } from '@common/types';
import { AddOrderForVehicleResponse } from '@modules/planning/types';

export const addOrderForVehicleReducer = createReducer(initialState, {
  [addOrderForVehicleRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      routeTotal: IndexedArray<RouteTotal>;
      planTotal: IndexedArray<PlanTotal>;
      waypointID: AddOrderForVehicleResponse['waypointID'];
    }>
  ) => {
    const { routeTotal, planTotal, waypointID } = action.payload;

    for (const planID in planTotal) {
      if (state.project?.Plan) {
        state.project.Plan[planID] = { ...state.project.Plan[planID], PlanTotal: planTotal[planID] };
      }
    }

    if (isEmpty(state.currentPlan.RouteTotal)) {
      state.currentPlan.RouteTotal = routeTotal;
    } else {
      for (const vehicleID in routeTotal) {
        state.currentPlan.RouteTotal[vehicleID] = { ...routeTotal[vehicleID], changed: true };

        for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
          state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
          waypointID.forEach((id) => {
            if (state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[id]) {
              state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[id].changed = true;
            }
          });
        }
      }
    }

    return state;
  }
});
