import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { Dictionary } from '@common/types';
import { projectInfoByDictionarySelector, projectPlansSelector } from '@modules/planning/children/by-date/selectors/project';

export const fetchSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate?.isFetching,
  (isFetching) => isFetching
);

export const projectIsFetchingSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.info.project.isFetching,
  (isFetching) => isFetching
);

export const plansIsFetchingSelector = createSelector(projectPlansSelector, (plans) => plans.isFetching);

export const projectDictionaryIsFetchingSelector = createSelector(
  [(state: PlanningByDateState, dictionary: Dictionary) => projectInfoByDictionarySelector(state, dictionary)],
  (catalog) => catalog?.isFetching
);
