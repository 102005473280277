import { IndexedArray, Keys } from '@common/types';
import { isArray, isEmpty, NumericDictionary, pick } from 'lodash';

export const filterObjectByKeys = <T, K extends keyof T>(
  object: IndexedArray<T> | Array<T> | NumericDictionary<T>,
  filterKeys: Keys | K | undefined
): IndexedArray<T> => {
  if (isArray(filterKeys) && !isEmpty(filterKeys)) {
    return pick(object, ...filterKeys) as IndexedArray<T>;
  }
  return object as IndexedArray<T>;
};
