import { ILSSpin } from '@common/components';
import { OrderNTripFormComponent } from '@modules/orders-and-trips/components/forms/order-and-trip-form';
import { useOrderNTripForm } from '@modules/orders-and-trips/hooks/use-order-and-trip-form';
import { FormType } from '@modules/orders-and-trips/types/general';
import { FC } from 'react';

interface IProps {
  showForm: FormType | null;
}

export const OrderNTripFormContainer: FC<IProps> = ({ showForm }) => {
  if (!showForm) return null;
  const {
    form,
    formTitle,
    onFieldsChange,
    submitButtonProps,
    onFinish,
    isFetching,
    restriction,
    formTrip,
    activeRecordID,
    disabled,
    vehicleTypes,
    lockedButtons
  } = useOrderNTripForm({
    showForm
  });
  return (
    <ILSSpin tip={'Выполняется загрузка данных...'} spinning={isFetching}>
      <OrderNTripFormComponent
        activeRecordID={activeRecordID}
        formTrip={formTrip}
        restriction={restriction}
        vehicleTypes={vehicleTypes}
        showForm={showForm}
        submitButtonProps={submitButtonProps}
        form={form}
        formTitle={formTitle}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
        lockedButtons={lockedButtons}
      />
    </ILSSpin>
  );
};

