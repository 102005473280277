import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { dataValidateRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { validatedData } from '@modules/planning/decorators/validate-data';

export const dataValidateReducer = createReducer(initialState, {
  [dataValidateRoutine.REQUEST]: onRequest,
  [dataValidateRoutine.SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.validateData = validatedData(data);
    return state;
  },
  [dataValidateRoutine.FAILURE]: onFailure,
  [dataValidateRoutine.FULFILL]: onFulfill
});
