import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { TenderOffer, TenderOfferFormValues } from '@modules/tender-platform/children/tender-search/types/offer';
import { deepEqual } from 'fast-equals';

export const checkBeforeTenderOfferCompetition = (
  TenderOffer: Partial<TenderOfferFormValues>,
  tenderOffer: TenderOffer,
  MinPrice: number | null | undefined,
  StartPrice: number
) => {
  const { price, UserID } = TenderOffer;
  const currentTenderOffer = {
    ...TenderOffer,
    VehicleID: Array.isArray(TenderOffer?.VehicleID) ? TenderOffer?.VehicleID?.[1] : TenderOffer?.VehicleID
  };

  const actualTenderOffer = {
    price: tenderOffer?.Price,
    UserID: tenderOffer?.UserID,
    VehicleID: tenderOffer?.VehicleID,
    DriverID: tenderOffer?.DriverID
  };
  if (!TenderOffer.VehicleID || (Array.isArray(TenderOffer.VehicleID) && TenderOffer.VehicleID?.length <= 1)) {
    ILSUserNotify(Notify.Error, `Пожалуйста, выберите автомобиль`, 3, 'tender-search');
    return false;
  }
  if (!TenderOffer.DriverID) {
    ILSUserNotify(Notify.Error, `Пожалуйста, выберите водителя`, 3, 'tender-search');
    return false;
  }
  if (UserID !== tenderOffer?.UserID) {
    ILSUserNotify(Notify.Error, `Сначала необходимо сделать предложение`, 3, 'tender-search');
    return false;
  }
  if (MinPrice && StartPrice && MinPrice > StartPrice) {
    ILSUserNotify(Notify.Error, `Лот создан Грузовладельцем некорректно, минимальная цена выше стартовой`, 3, 'tender-search');
    return false;
  }

  if (price && price !== tenderOffer?.Price && ((MinPrice && MinPrice > price) || (StartPrice && price > StartPrice))) {
    ILSUserNotify(Notify.Error, `Стоимость должна быть больше ${MinPrice} и меньше ${StartPrice}`, 3, 'tender-search');
    return false;
  }

  if (deepEqual(actualTenderOffer, currentTenderOffer)) {
    ILSUserNotify(Notify.Error, `Вы не изменили значения полей Вашего предложения`);
    return false;
  }

  return true;
};
