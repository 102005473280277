import { RoadTrainUploadFileType } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const roadTrainData2Table = (data: RoadTrainUploadFileType[] | undefined, type: string) => {
  return createArrayFromIndexedArray(data).map((RoadTrain: RoadTrainUploadFileType, index: number) => {
    return {
      ...RoadTrain,
      key: RoadTrain['Vehicle.VehicleCode'] ? RoadTrain['Vehicle.VehicleCode'] + String(index) : type + index,
      fileIndex: index
    };
  });
};
