import { call, put, takeEvery } from 'redux-saga/effects';
import { setBoardWidget } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { SetBoardWidgetRequest } from '@common/types/dictionaries/analytics';

function* worker(action: PayloadAction<SetBoardWidgetRequest>) {
  const { request, success, failure, fulfill } = setBoardWidget;
  try {
    yield put(request(action.payload));

    const { data } = yield call(AnalyticsPlanningAPI.setBoardWidget, action.payload);

    yield put(success({ data: data.data }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsSetBoardWidgetWatcher() {
  yield takeEvery(setBoardWidget.TRIGGER, worker);
}
