import { ILSSlider, ILSTypographyText } from '@common/components';
import { AnalyticsTableWidgetTotalInfo } from '@common/types/dictionaries/analytics';
import { Chart, Interval, Axis, Tooltip, Effects } from 'bizcharts';
import { FC, useState, useMemo } from 'react';
import { SUMMARY_TABLE_CHART_DEFAULT_HEIGHT, SUMMARY_TABLE_CHART_DEFAULT_SLIDER } from '../../constants';
import { isChartValuesEmpty } from '../../utils/isChartValuesEmpty';

const SummaryChart: FC<{ data: { date: string; value: number }[]; charItem: AnalyticsTableWidgetTotalInfo }> = (props) => {
  const { data } = props;

  const [tableChartRange, setTableChartRange] = useState(SUMMARY_TABLE_CHART_DEFAULT_SLIDER);

  const transFormedData = useMemo(() => {
    const newData = data.slice(0, tableChartRange);

    return [...newData];
  }, [tableChartRange]);

  const handleAfterChange = (count: number) => {
    setTableChartRange?.(count);
  };

  return transFormedData?.length && !isChartValuesEmpty(data) ? (
    <>
      <Chart
        scale={{
          value: {
            nice: true
          },
          date: {
            nice: true
          }
        }}
        height={SUMMARY_TABLE_CHART_DEFAULT_HEIGHT}
        autoFit
        data={transFormedData}
      >
        <Interval position="date*value" />
        <Tooltip shared data-html2canvas-ignore>
          {(title, items) => {
            return (
              <div className="mt-1 mb-1 chart-tooltip">
                <h4 className="mb-1 date">{title}</h4>
                {items ? (
                  items.map((item) => {
                    return <div className="value">{item.value}</div>;
                  })
                ) : (
                  <div>Нет данных</div>
                )}
              </div>
            );
          }}
        </Tooltip>
        <Axis
          name="date"
          label={{
            style: {
              opacity: 0
            }
          }}
        />

        <Effects>
          {(chart: any) => {
            chart.option('scrollbar', {
              type: 'horizontal'
            });
          }}
        </Effects>
      </Chart>

      <div className="diagram-td-range">
        <ILSSlider
          onAfterChange={(e) => handleAfterChange(e)}
          defaultValue={SUMMARY_TABLE_CHART_DEFAULT_SLIDER}
          min={1}
          max={data.length}
        />
      </div>
    </>
  ) : (
    <ILSTypographyText>Нет данных</ILSTypographyText>
  );
};

export default SummaryChart;
