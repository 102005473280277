import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

export const tenderIndexRoutine = createRoutine('TENDER/INDEX');

export const tenderCreateRoutine = createRoutine('TENDER/CREATE');
export const tenderUpdateRoutine = createRoutine('TENDER/UPDATE');
export const tenderDeleteRoutine = createRoutine('TENDER/DELETE');
export const tenderSetStatusRoutine = createRoutine('TENDER/SET_STATUS');
export const tenderAppointResponsibleRoutine = createRoutine('TENDER/APPOINT_RESPONSIBLE');
export const tenderTemplateFullRoutine = createRoutine('TENDER_TEMPLATE/FULL');

export const tenderLotIndexRoutine = createRoutine('TENDER_LOT/INDEX');
export const tenderLotCreateRoutine = createRoutine('TENDER_LOT/CREATE');
export const tenderLotUpdateRoutine = createRoutine('TENDER_LOT/UPDATE');
export const tenderLotDeleteRoutine = createRoutine('TENDER_LOT/DELETE');
export const tenderLotWinnerChooseRoutine = createRoutine('TENDER_LOT/CHOOSE_WINNER');
export const tenderGetLotTemplateRoutine = createRoutine('TENDER_LOT/GET_LOT_TEMPLATE');

export const tenderReadRestrictionRoutine = createRoutine('TENDER_LOT/READ_RESTRICTION_CATALOG');

export const tenderParticipantCreateRoutine = createRoutine('TENDER_PARTICIPANT/CREATE');
export const tenderParticipantDeleteRoutine = createRoutine('TENDER_PARTICIPANT/DELETE');
export const tenderParticipantConfirmRoutine = createRoutine('TENDER_PARTICIPANT/CONFIRM');

export const getCarriersRoutine = createRoutine('TENDER_CARRIER/INDEX');
export const addCarriersRoutine = createRoutine('TENDER_CARRIER/CREATE');
export const deleteCarriersRoutine = createRoutine('TENDER_CARRIER/DELETE');
export const searchCarriersRoutine = createRoutine('TENDER_CARRIER/SUGGESTION');

export const tenderRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  tenderIndexRoutine,
  tenderCreateRoutine,
  tenderUpdateRoutine,
  tenderSetStatusRoutine,
  tenderAppointResponsibleRoutine,
  tenderDeleteRoutine
];

export const tenderLotRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  tenderLotIndexRoutine,
  tenderLotCreateRoutine,
  tenderLotUpdateRoutine,
  tenderLotDeleteRoutine,
  tenderLotWinnerChooseRoutine
];

export const tenderParticipantRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  tenderParticipantCreateRoutine,
  tenderParticipantConfirmRoutine,
  tenderParticipantDeleteRoutine,
  getCarriersRoutine,
  addCarriersRoutine,
  deleteCarriersRoutine,
  searchCarriersRoutine
];

