import { RowProps } from 'antd/lib/grid/row';
import { PermittedUploadFileType } from '@common/constants/general/path';
import { JoinChar } from '@common/constants';

export enum UserBlocked {
  Locked = 'locked',
  Unlocked = 'unlocked'
}

export const GUTTER = {
  xs: 12,
  sm: 12,
  md: 24,
  lg: 24
};

export const USERS_COLUMN_SPAN = 12;
export const USER_CARD_BODY_STYLE = { display: 'flex', gap: '1em', padding: 0 };
export const USERS_GUTTER: RowProps['gutter'] = [GUTTER, GUTTER];
export const PERMITTED_UPLOAD_FILE_TYPE_AVATAR = [PermittedUploadFileType.png, PermittedUploadFileType.jpeg].join(JoinChar.Comma);
export const AVATAR_MAX_SIZE = 10485760;
export const NO_ACCOUNT_ROLES = 'Нет ролей';
