import { ILSMap, ILSMarker } from '@core/containers/map';
import { IndexedArray, VehicleModel } from '@common/types';
import { MonitoringVehicleEventsModel, TrackerLastInfoModel } from '@common/types/dictionaries/monitoring-vehicle';
import L from 'leaflet';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { trackerInfoToMarkers } from '../utils/map/map-marker-helper';
import { useAppSelector } from '@core/hooks';
import { tracksSelector } from '@modules/monitoring/children/event-details/selectors';
import { isEmpty } from 'lodash';

export type VehicleMarkerMapped = { tracker: TrackerLastInfoModel & VehicleModel; mapMarker: ILSMarker };

/**
 * Хук для отображения на карте переданных данных о транспортных средствах
 * @Map - карта
 * @cluster  -  кластер, если нужен
 * @vehicleEvents - данные которые приходят с сервера
 * @currentMarkers - текущие отображаемые маркеры
 * @setCurrentMarkers - метод обновления текущих маркеров для хранения в state
 */
export const useVehicleMarker = (args: {
  Map: MutableRefObject<any>;
  cluster?: L.MarkerClusterGroup;
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>;
  currentMarkers?: IndexedArray<VehicleMarkerMapped>;
  setCurrentMarkers: Dispatch<SetStateAction<IndexedArray<VehicleMarkerMapped> | undefined>>;
  selectedVehicleIDs?: (string | number)[];
  activeVehicleID?: string | number;
  vehicleFocusHandler?: (activeVehicleID: number | string) => void;
}) => {
  const { Map, cluster, currentMarkers, setCurrentMarkers, vehicleEvents, selectedVehicleIDs, activeVehicleID, vehicleFocusHandler } = args;
  const [isUpdating, setIsUpdating] = useState(false);
  const selectedVehicleId = useAppSelector(tracksSelector)?.selectedVehicleId;

  const updateMarkers = (events: IndexedArray<MonitoringVehicleEventsModel>) => {
    try {
      const curMap: ILSMap = Map.current;

      if (curMap) {
        let incomingTrackers = {};
        //extract lastInfo from incoming vehicle events to incomingTrackers
        if (!isEmpty(selectedVehicleIDs) || activeVehicleID) {
          for (const id in events) {
            const vehicle = events[id];
            //activeVehicleID - синее выделение строки
            //selectedVehicleId - золотое выделение строки
            if (
              !selectedVehicleIDs?.includes(vehicle.ID?.toString()) &&
              activeVehicleID !== vehicle.ID?.toString() &&
              selectedVehicleId !== vehicle.ID
            ) {
              continue;
            }
            for (const imei in vehicle.VehicleTracker) {
              if (vehicle.VehicleTracker?.[imei]?.Tracker?.TrackerLastInfo) {
                incomingTrackers[imei] = {
                  ...vehicle,
                  ...vehicle.VehicleTracker[imei].Tracker.TrackerLastInfo
                };
              }
            }
          }
        }

        const updatedMarkersInfo = trackerInfoToMarkers<TrackerLastInfoModel & VehicleModel>({
          incomingTrackers,
          mapObject: curMap,
          existingMarkers: currentMarkers,
          vehicleFocusHandler
        });

        setCurrentMarkers(updatedMarkersInfo);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (isUpdating) return;
    setIsUpdating(true);
    setTimeout(() => {
      updateMarkers(vehicleEvents);
    });
  }, [Map, cluster, vehicleEvents, selectedVehicleIDs, activeVehicleID, vehicleFocusHandler]);
};
export default { useVehicleMarker };

