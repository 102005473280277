import { FC } from 'react';
import { useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { MonitoringEventDetailsStore } from '../../types/store';
import { DetailsTableComponent } from '../../components/tables/details-table';

interface EventsDetailsTableProps {
  title: string;
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
  selectedVehicleID: number | undefined;
}

/**
 * Контейнер для отображения одной из таблиц с детализацией (Маршрут, План/Факт, Датчики, БДД)
 */
export const DetailsSubTableContainer: FC<EventsDetailsTableProps> = ({ title, vehicleSensor, selectedVehicleID }) => {
  const dataSource = vehicleSensor.data;
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);

  return (
    <DetailsTableComponent
      title={title}
      selectedVehicleID={selectedVehicleID}
      dataSource={dataSource}
      startDate={startDate}
      endDate={endDate}
    />
  );
};
