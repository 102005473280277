import { setTableMapSizesRoutine } from '@modules/monitoring/children/event-details/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { TableMapSizesPayload } from '@modules/monitoring/children/event-details/types/resizer';

function* worker(action: TableMapSizesPayload) {
  const { tablesMapSplitSizes, tablesSplitSizes } = action.payload;
  const { success, failure } = setTableMapSizesRoutine;
  try {
    yield put(success({ tablesMapSplitSizes, tablesSplitSizes }));
  } catch (error) {
    console.error(error);
    yield put(failure());
  }
}

export function* setTableMapSizesWatcher() {
  yield takeLatest(setTableMapSizesRoutine.trigger, worker);
}
