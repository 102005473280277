import { IVehicleMonitoringData } from '@modules/vehicle-monitoring/types/models';
import { PayloadAction } from '@reduxjs/toolkit';
import { VehicleMonitoringIndexParams } from './api';

export type IVehicleMonitoringIndexTriggerPayload = PayloadAction<VehicleMonitoringIndexParams>;

export interface IVehicleMonitoringIndexSuccessPayload {
  data: IVehicleMonitoringData;
  lastUpdate: number;
}

export enum VehicleMonitoringAction {
  INDEX = 'VEHICLE_MONITORING/INDEX'
}
