import initialState from '../store';
import reduceReducers from 'reduce-reducers';
import { searchTender } from '@modules/tender-platform/children/tender-search/reducers/search-tender';
import { appointResponsibleTenderOfferReducer } from '@modules/tender-platform/children/tender-search/reducers/appoint-responsible-tender-offer';
import { tenderOfferIndexReducer } from '@modules/tender-platform/children/tender-search/reducers/offer-index';
import { tenderOfferDeleteReducer } from '@modules/tender-platform/children/tender-search/reducers/offer-delete';
import { tenderWithMyParticipation } from '@modules/tender-platform/children/tender-search/reducers/my-tenders';
import { getNotificationSettings } from '@modules/tender-platform/children/tender-search/reducers/get-notify-settings';
import { notificationSettingsReducer } from '@modules/tender-platform/children/tender-search/reducers/notify-settings';
import { tenderFilterReducer } from '@modules/tender-platform/children/tender-search/reducers/tender-filter'
import { removeTenderFilterReducer } from '@modules/tender-platform/children/tender-search/reducers/remove-tender-filter';

export const tenderSearchReducer = reduceReducers(
  initialState,
  tenderOfferIndexReducer,
  searchTender,
  appointResponsibleTenderOfferReducer,
  tenderWithMyParticipation,
  tenderOfferDeleteReducer,
  getNotificationSettings,
  notificationSettingsReducer,
  tenderFilterReducer,
  removeTenderFilterReducer
);
