import { IEditableCell, TableRecord } from '@common/types/components';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { compareAsString } from '@common/utils';
import { CellChildren } from '@common/types/components/table/cell';
import { isArray, isNil } from 'lodash';

//TODO unit
export const getStringFromVariants = ({
  options,
  variant,
  fieldNames
}: {
  options: IEditableCell<TableRecord>['options'];
  variant?: CellChildren[1];
  fieldNames: IEditableCell<TableRecord>['fieldNames'];
}): string => {
  if (isNil(fieldNames) || isNil(fieldNames.value) || isNil(fieldNames.label)) return EMPTY_STRING;

  if (isArray(variant)) {
    return variant.map((selected) => getStringFromVariants({ options, variant: selected, fieldNames })).join(JoinChar.Comma);
  }

  return options?.find((option) => compareAsString(option[fieldNames.value!], variant))?.[fieldNames.label] ?? EMPTY_STRING;
};
