import { FC } from 'react';
import cn from 'classnames';
import { ILSAvatar, ILSImgCrop } from '@components/index';
import { ILSCropUpload } from '@components/data-entry/upload';
import { Avatar } from '@modules/account/types/user';
import { AVATAR_MAX_SIZE, PERMITTED_UPLOAD_FILE_TYPE_AVATAR } from '@modules/account/constants/user';
import { preventAntdRequest } from '@modules/account/utils/helpers/prevent-antd-request';
import { handleAvatarPreview } from '@modules/account/utils/helpers/handle-avatar-preview';
import { useChangeAvatar } from '@modules/account/utils/hooks/use-change-avatar';

interface Props {
  avatar: Avatar;
  disabled?: boolean;

  onAvatarChange(e: any): void;
}

export const AccountAvatarComponent: FC<Props> = ({ onAvatarChange, disabled, avatar }) => {
  const { edit, fileList, onFileChange } = useChangeAvatar({ onAvatarChange, fileMaxSize: AVATAR_MAX_SIZE });

  return (
    <div className="avatar-upload position-relative mb-3">
      <div className={cn('circle', { edit, saved: !edit })}>
        {!disabled && (
          <ILSImgCrop rotate shape="round" fileMaxSize={AVATAR_MAX_SIZE}>
            <ILSCropUpload
              listType="picture-card"
              fileList={fileList}
              customRequest={preventAntdRequest}
              accept={PERMITTED_UPLOAD_FILE_TYPE_AVATAR}
              onChange={onFileChange}
              onPreview={handleAvatarPreview}
            >
              {fileList.length < 1 && '+ Загрузить'}
            </ILSCropUpload>
          </ILSImgCrop>
        )}
        {!edit && <ILSAvatar src={avatar} />}
      </div>
    </div>
  );
};
