import { call, put, takeLatest } from 'redux-saga/effects';
import { startDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { DistanceMatrix } from '@modules/planning/children/by-date/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningRequest } from '@modules/planning/types/api';

function* startDistanceMatrixWorker(action: PayloadAction<PlanningRequest>) {
  const { request, success, failure, fulfill } = startDistanceMatrixRoutine;
  const { projectID, planID } = action.payload;

  try {
    yield put(request());
    const { data } = yield call(DistanceMatrix.start, { projectID, planID });
    if (data?.data && projectID) {
      const { data } = yield call(DistanceMatrix.status, { projectID });
      yield put(success({ data: data.data }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* startDistanceMatrixWatcher() {
  yield takeLatest(startDistanceMatrixRoutine.trigger, startDistanceMatrixWorker);
}
