import { useState } from 'react';
import { ShowReference, TableRecord } from '@common/types/components/table/common';

/** связанные таблицы */
export const useReferenceTable = <R extends TableRecord>() => {
  const [showReference, setShowReference] = useState<ShowReference<R>>(false);

  const handleCloseReferenceTable = () => {
    setShowReference(false);
  };

  return { showReference, setShowReference, handleCloseReferenceTable };
};
