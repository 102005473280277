import { REACT_APP_SIDEBAR_HIDE } from '@core/constants/env-variables';

export const checkVisibility = (module: { MODULE: string }): boolean => {
  const hiddenPathes = process.env[REACT_APP_SIDEBAR_HIDE];

  if (!hiddenPathes) return true;

  const hiddenArray = hiddenPathes.split(',');

  return !hiddenArray.some((x) => x.trim() === module.MODULE);
};
