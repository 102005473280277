import { FC } from 'react';
import { ILSTypographyParagraph, ILSTypographyTitle } from '@components/general/typography';
import { ILSSVG } from '@components/custom/icons/svg';
import classnames from 'classnames';
import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { ILSButton, ILSInputSearch } from '@components/index';
import { preventDefaultEnter } from '@common/utils/helpers/prevent-default-enter';
import { useSearchElement } from '@modules/planning/children/scheme-editor/hooks/use-serach-element';
import { SchemeKeyType } from '@modules/planning/children/scheme-editor/constants';

interface IProps {
  descend?: boolean;
  scheme: SchemeSwitchDictionary;

  handleSort?(): void;

  handleFilter?(search: string): void;
  addNewKey?: ({ key, title, description }: { key: string; title?: string; description?: string }) => void;
}

export const SchemeEditorTransferTitle: FC<IProps> = ({ addNewKey = () => {}, scheme, descend, handleSort, handleFilter }) => {
  const onSearch = useSearchElement(handleFilter);

  const onSort = () => handleSort?.();

  const addNew = (key: string) => {
    return () => {
      addNewKey({
        key,
        title: '',
        description: ''
      });
    };
  };

  return (
    <>
      <div className={'scheme-editor-scheme-title'}>
        <div>
          <ILSTypographyTitle level={5} children={scheme} />
        </div>
        {Boolean(handleSort) ? (
          <>
            <ILSButton
              disabled={false}
              onClick={onSort}
              className={classnames('scheme-editor-scheme-sort-button', { descend })}
              icon={<ILSSVG className={classnames('scheme-editor-scheme-sort-button', { descend })} icon={'sort'} />}
            />
            <div className={'scheme-editor-scheme-search-form'}>
              <ILSInputSearch
                loading={false}
                placeholder={'введите строку поиска'}
                allowClear
                onChange={onSearch}
                onKeyDown={preventDefaultEnter}
              />
            </div>
          </>
        ) : (
          <>
            <ILSButton disabled={false} onClick={addNew(SchemeKeyType.Empty)}>
              + Empty
            </ILSButton>
            <ILSButton disabled={false} onClick={addNew(SchemeKeyType.Info)}>
              + Info
            </ILSButton>
          </>
        )}
      </div>
      <ILSTypographyParagraph>{handleSort ? 'Выберите, чтобы добавить в текущую схему' : 'Поля текущей схемы'}</ILSTypographyParagraph>
    </>
  );
};
