import { AppRoles } from '@common/types/general/general';
import { AccountRoleLabel, UserRoleLabel } from '@common/types';
import { isEmpty } from 'lodash';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { DISABLED_ROLES } from '@modules/account/constants/account';

export const generateRolesSelect = (data: AppRoles) => {
  if (!data || isEmpty(data)) return EMPTY_ARRAY_DATA;

  return data.map((value) => {
    const label = UserRoleLabel[value] ?? AccountRoleLabel[value];
    const disabled = DISABLED_ROLES.includes(value);
    return { value, label, disabled };
  });
};

