import { tenderDeleteRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { useDispatch, useSelector } from 'react-redux';
import { tenderByStatusSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { currentUserSelector } from '@modules/current-user/selectors/auth';
import { isEmpty } from 'lodash';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

export const useTenderDelete = () => {
  const dispatch = useDispatch();

  const { Tenders } = useSelector(tenderByStatusSelector);
  const currentUser = useSelector(currentUserSelector);

  const handleDelete = (tenderIDs: number[]) => {
    const tendersToDelete = tenderIDs?.reduce((prev, tenderID) => {
      if (Tenders?.[tenderID]?.UserID === currentUser.ID) {
        prev.push(tenderID);
      }
      return prev;
    }, [] as number[]);
    if (!isEmpty(tendersToDelete)) {
      dispatch(tenderDeleteRoutine({ ids: tendersToDelete }));
    } else {
      ILSUserNotify(Notify.Error, 'У вас нет прав для удаления тендера!', 2);
    }
  };

  return {
    handleDelete
  };
};

