import { put, takeLatest } from 'redux-saga/effects';
import { removeTenderFilterRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* removeTenderFilterWorker(action: PayloadAction<{ key: number }>) {
  const { key } = action.payload;
  const { success } = removeTenderFilterRoutine;
  yield put(success(key));
}

export function* removeTenderFilterWatcher() {
  yield takeLatest(removeTenderFilterRoutine.trigger, removeTenderFilterWorker);
}