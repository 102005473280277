import { ColumnTable, Option } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { isString } from 'lodash';

export const createCheckboxOptionsFromColumns = <R>(columns: ColumnTable<R>[]) => {
  return columns.reduce((columnsForGrouping, column) => {
    if (column.grouping) {
      const { title, titleText, dataIndex } = column;
      const label = title && isString(title) ? title : titleText || EMPTY_STRING;
      columnsForGrouping.push({
        value: dataIndex,
        label
      });
    }
    return columnsForGrouping;
  }, [] as Option[]);
};
