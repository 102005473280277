import { FC } from 'react';
import { SettingsForwarderPointStatusesGroupTableComponent } from '@modules/settings/components/mobile-application/settings-forwarder-point-statuses-group-table';
import { useDispatch, useSelector } from 'react-redux';
import { settingsForwarderPointStatusesTableGroupSelector } from '@modules/settings/selectors/components/settings-forwarder-point-statuses-group-table';
import { forwarderPointStatusesGroupTableConfig } from '@modules/settings/config/mobile-application/settings-forwarder-point-statuses-group-table-config';
import { Dictionary } from '@common/types';

export const SettingsForwarderPointStatusesGroupTableContainer: FC = () => {
  const dispatch = useDispatch();
  const { handleSave, refData, dataSource, handleCreate, handleDelete, focusRow } = useSelector(
    settingsForwarderPointStatusesTableGroupSelector(dispatch)
  );

  return (
    <SettingsForwarderPointStatusesGroupTableComponent
      dictionary={Dictionary.ForwarderPointStatusesGroup}
      config={forwarderPointStatusesGroupTableConfig}
      handleDelete={handleDelete}
      handleCreate={handleCreate}
      handleSave={handleSave}
      refData={refData}
      dataSource={dataSource}
      focusRow={focusRow}
    />
  );
};
