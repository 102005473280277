import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { setActiveModalWindowRoutine } from '@modules/orders-and-trips/actions';
import { ModalWindowContainerType } from '@modules/orders-and-trips/types/general';

export const setActiveModalWindowReducer = createReducer(initialState, {
  [setActiveModalWindowRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      activeModal: ModalWindowContainerType | null;
    }>
  ) => {
    const { activeModal } = action.payload;
    state.activeModalWindow = activeModal;
    return state;
  },
  [setActiveModalWindowRoutine.FAILURE]: (state) => {
    return state;
  }
});
