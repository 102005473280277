import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI, Key } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { onFulfill } from '@modules/catalog/reducers/helpers';

export const catalogSetFocusRowReducer = createReducer(initialState, {
  [catalogSetFocusRowRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      key: Key;
      dictionary: Dictionary;
    }>
  ) => {
    const { key, dictionary } = action.payload;
    state[dictionary].focusRow = { key };
    return state;
  },
  [catalogSetFocusRowRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogSetFocusRowRoutine.FULFILL]: onFulfill
});
