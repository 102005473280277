import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { unionTripsRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { RouteTotal } from '@common/types/dictionaries/route';
import { IndexedArray, PlanTotal } from '@common/types';

export const unionTripsReducer = createReducer(initialState, {
  [unionTripsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      sourceVehicleID: number;
      route: IndexedArray<RouteTotal>;
      planTotal: IndexedArray<PlanTotal>;
        }>
  ) => {
    const { sourceVehicleID, route, planTotal } = action.payload;
    
    for (const planID in planTotal) {
      if (state.project?.Plan) {
        state.project.Plan[planID] = { ...state.project.Plan[planID], PlanTotal: planTotal[planID] };
      }
    }
    for (const vehicleID in route) {
      state.currentPlan.RouteTotal[vehicleID] = { ...route[vehicleID], changed: true };
    }
    delete state.currentPlan.RouteTotal[sourceVehicleID];
    return state;
  }
});

