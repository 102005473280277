import React from 'react';
import { ChartSchemaDataSetType } from '@modules/monitoring/types/chart';

// Компонент для отображения настраиваемого тултипа
const ChartCustomTooltip = ({ ds }: ChartSchemaDataSetType | any) => {
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      width: 600
    }
  };

  return (
    <div style={styles.container}>
      <ul className="custom-ul-for-tooltip">
        <li>
          <b>Название клиента:</b> {ds.clientName}
        </li>
        <li>
          <b>Адрес клиента:</b> {ds.clientAddress}
        </li>
        <li>
          <b>Время прибытия по плану:</b> {ds.startTime}
        </li>
        <li>
          <b>Фактическое время прибытия</b>
          {ds.factTime}
        </li>
      </ul>
    </div>
  );
};

export default ChartCustomTooltip;
