import { ColumnTable, Notify, TableColumnName, TableRecord, ValidationData } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { stringIncludes } from '@common/utils/general/string-includes';
import { getHighLevelError } from '@common/components/data-display/table/utils/helpers/get-high-level-error';
import { TILDA_SEPARATOR } from '@common/components/data-display/table/constants';
import { DOT, JoinChar } from '@common/constants/general';

export const errorCell = (record: any, errors: Array<ValidationData> | undefined, dataIndex: ColumnTable<TableRecord>['dataIndex']) => {
  if (errors?.length) {
    let dataIndexToString = dataIndex?.toString();
    let errorNumber = 1;
    return errors?.reduce((resultErrorData, { FailFieldName, FailKeys, Fields_, Type, Name_ }) => {
      if (FailKeys?.length && FailFieldName && Fields_) {
        const failID = FailKeys.find((key) => compareAsString(key, record[FailFieldName]));
        if (failID) {
          if (!Array.isArray(Fields_)) Fields_ = [Fields_];
          const existLetterID = dataIndexToString?.indexOf(TableColumnName.ID);
          if (~existLetterID && TableColumnName.ID !== dataIndex) dataIndexToString = dataIndexToString.slice(0, existLetterID);
          const failColumn = Fields_.find((key) => stringIncludes(dataIndexToString, key));
          if (failColumn) {
            if (!resultErrorData.length) {
              resultErrorData.push({ Type: Type ?? Notify.Warning, Name: errorNumber + '. ' + Name_ });
            } else {
              errorNumber++;
              resultErrorData[0] = {
                Type: getHighLevelError(resultErrorData[0].Type, Type),
                Name: resultErrorData[0].Name + TILDA_SEPARATOR + errorNumber + DOT + JoinChar.Space + Name_
              };
            }
          }
        }
      }
      return resultErrorData;
    }, [] as Array<{ Type: string; Name: string }>);
  }
  return undefined;
};
