import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';

// Нотификации для пользователя
export const demandNotify: { [K: string]: Parameters<typeof ILSUserNotify> } = {
  notPermittedRoleForCreate: [
    Notify.Error,
    'Только грузовладельцы и организации с двойной ролью могут создавать тендеры',
    NotifyDurationInSecond.Five,
    'DEMAND/NOT_PERMITTED_ROLE'
  ]
};
