import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringDeleteTrackerRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import isNil from 'lodash/isNil';

// Участвует в удалении трекера
function* monitoringDeleteTrackerWorker(action: PayloadAction<number | string>) {
  const { request, success, failure, fulfill } = monitoringDeleteTrackerRoutine;

  const IMEI = action.payload;

  if (isNil(IMEI) || IMEI === '') {
    yield put(success(IMEI));
  } else {
    try {
      yield put(request());
      yield call(MonitoringAPI.deleteTracker, { IMEI: action.payload });
      yield put(success(action.payload));
    } catch (error) {
      yield put(failure({ error }));
    } finally {
      yield put(fulfill());
    }
  }
}

export function* monitoringDeleteTrackerWatcher() {
  yield takeLatest(monitoringDeleteTrackerRoutine.trigger, monitoringDeleteTrackerWorker);
}
