import { TimePicker, TimePickerProps } from 'antd';
import { FC } from 'react';
import { ILS_MAIN_INTERFFACE_TIME_FORMAT } from '@common/types/general/date-time';
import { ILSSVG } from '@components/custom/icons/svg';
import { TimeRangePickerProps } from 'antd/lib/time-picker';
import { ClockCircleOutlined } from '@ant-design/icons/lib/icons';

const { RangePicker } = TimePicker;

export const ILSTimePicker: FC<TimePickerProps> = ({ showNow = false, format = ILS_MAIN_INTERFFACE_TIME_FORMAT, ...props }) => {
  return <TimePicker suffixIcon={<ILSSVG icon={'clock'} />} format={format} showNow={showNow} {...props} />;
};

export const ILSTimeRangePicker: FC<TimeRangePickerProps> = ({ format = ILS_MAIN_INTERFFACE_TIME_FORMAT, ...props }) => {
  return <RangePicker suffixIcon={<ClockCircleOutlined className={'ils-svg primary'} />} format={format} {...props} />;
};
