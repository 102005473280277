import { useCallback, useRef } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

//TODO: Проверить работу метода после того, как будет готов TenderPlatformNavigation
export const useDataFiles = () => {
  const dataFiles = useRef<{ [k: string | number]: UploadFile[] }>({});
  const onSetDataFiles = useCallback((routeId: any, file: UploadFile[]) => {
    dataFiles.current[routeId] = file;
  }, []);

  return { dataFiles, onSetDataFiles };
};
