import { CellType, DepotRecordKeys, FilterCellType, ShowDeleted, TableColumnName, TableConfig } from '@common/types';
import { DEPOT_TYPE_OPTIONS, NEW_CLIENT } from '@common/models/depot/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const DEPOT_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  export: true,
  copying: true,
  adding: NEW_CLIENT,
  multiEditing: true,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  getCoordinates: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Наименование',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 5,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Код',
      dataIndex: TableColumnName.DepotCode,
      key: TableColumnName.DepotCode,
      editable: true,
      active: false,
      type: CellType.Input,
      sorting: 10,
      ellipsis: true,

      grouping: true
    },
    {
      title: 'Тип точки',
      dataIndex: TableColumnName.DepotTypeID,
      key: TableColumnName.DepotTypeID,
      active: true,

      editable: true,
      sorting: 5,
      type: CellType.Select,
      options: DEPOT_TYPE_OPTIONS,
      fieldNames: DEFAULT_FIELD_NAMES,
      filtering: FilterCellType.Array,
      grouping: true
    },
    {
      title: 'Описание',
      dataIndex: TableColumnName.Description,
      key: TableColumnName.Description,
      active: false,
      sorting: 5,

      editable: true,
      ellipsis: true,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Адрес',
      dataIndex: TableColumnName.AddressDescription,
      key: TableColumnName.AddressDescription,
      active: true,

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.AddressAutocomplete,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Цвет',
      dataIndex: TableColumnName.Color,
      key: TableColumnName.Color,
      active: false,

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.Color,
      grouping: false
    },
    {
      title: 'Контакт',
      dataIndex: TableColumnName.ClientContact,
      key: TableColumnName.ClientContact,
      active: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Контакты',
      dataIndex: TableColumnName.ContactID,
      key: TableColumnName.ContactID,
      active: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      filtering: FilterCellType.Array,
      type: CellType.SelectMultipleApi,
      grouping: true
    },
    {
      title: 'Координаты',
      dataIndex: DepotRecordKeys.LAT_LON,
      key: DepotRecordKeys.LAT_LON,
      active: true,
      required: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      type: CellType.Geo,
      grouping: true
    },
    {
      title: 'Проверенные координаты',
      dataIndex: DepotRecordKeys.LAT_LON_VERIFIED,
      key: DepotRecordKeys.LAT_LON_VERIFIED,
      active: false,
      required: false,
      editable: true,
      ellipsis: true,
      sorting: 5,
      type: CellType.Bool,
      align: 'center',
      grouping: true
    },
    {
      title: 'Задержка',
      dataIndex: TableColumnName.Delay,
      key: TableColumnName.Delay,
      active: true,

      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble,
      sorting: 3,
      align: 'center',
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Можно оставлять прицеп',
      dataIndex: TableColumnName.AllowKeepTrailer,
      key: TableColumnName.AllowKeepTrailer,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.Bool,
      align: 'center',
      grouping: true
    },
    {
      title: 'Режим работы',
      dataIndex: TableColumnName.WorktimeID,
      key: TableColumnName.WorktimeID,
      active: true,
      editable: true,
      ellipsis: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Начало погрузки',
      dataIndex: TableColumnName.WorktimeLoadStart,
      key: TableColumnName.WorktimeLoadStart,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Конец погрузки',
      dataIndex: TableColumnName.WorktimeLoadEnd,
      key: TableColumnName.WorktimeLoadEnd,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Начало разгрузки',
      dataIndex: TableColumnName.WorktimeUnloadStart,
      key: TableColumnName.WorktimeUnloadStart,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Конец разгрузки',
      dataIndex: TableColumnName.WorktimeUnloadEnd,
      key: TableColumnName.WorktimeUnloadEnd,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Превышения',
      dataIndex: TableColumnName.DepotOverloadID,
      key: TableColumnName.DepotOverloadID,
      active: true,

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Превыш.нач.разгрузки',
      dataIndex: TableColumnName.DepotOverloadUnloadStartOverload,
      key: TableColumnName.DepotOverloadUnloadStartOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble,
      grouping: true
    },
    {
      title: 'Превыш.конц.разгрузки',
      dataIndex: TableColumnName.DepotOverloadUnloadEndOverload,
      key: TableColumnName.DepotOverloadUnloadEndOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble,
      grouping: true
    },
    {
      title: 'Превыш.нач.погрузки',
      dataIndex: TableColumnName.DepotOverloadLoadStartOverload,
      key: TableColumnName.DepotOverloadLoadStartOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble,
      grouping: true
    },
    {
      title: 'Превыш.конц.погрузки',
      dataIndex: TableColumnName.DepotOverloadLoadEndOverload,
      key: TableColumnName.DepotOverloadLoadEndOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble,
      grouping: true
    },
    {
      title: 'Специфика',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: true,
      editable: true,
      ellipsis: true,
      sorting: 5,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Шаблон рейса',
      dataIndex: TableColumnName.TripTemplateID,
      key: TableColumnName.TripTemplateID,
      active: true,

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.SelectAPI,
      grouping: true
    },
    {
      title: 'Расстояние до города',
      dataIndex: TableColumnName.DistanceFromCity,
      key: TableColumnName.DistanceFromCity,
      active: false,

      editable: true,
      ellipsis: true,
      sorting: 5,
      filtering: FilterCellType.String,
      type: CellType.Float,
      grouping: true
    },
    {
      title: 'Радиус',
      dataIndex: TableColumnName.Radius,
      key: TableColumnName.Radius,
      active: false,

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Зона наблюдения',
      dataIndex: TableColumnName.ZoneID,
      key: TableColumnName.ZoneID,
      active: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      type: CellType.SelectAPI,

      titleTooltip: 'Подсказка: Зона наблюдения - используется для наблюдения посещения зоны',
      grouping: true
    },
    {
      title: 'Стоимость посещения',
      dataIndex: TableColumnName.VisitPrice,
      key: TableColumnName.VisitPrice,
      active: false,

      editable: true,
      ellipsis: true,
      type: CellType.Float,
      sorting: 2,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Очередь погр/разг',
      dataIndex: TableColumnName.Queue,
      key: TableColumnName.Queue,
      active: false,

      editable: true,
      ellipsis: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 1,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Значение 1',
      dataIndex: TableColumnName.Value1,
      key: TableColumnName.Value1,
      active: false,

      editable: true,
      ellipsis: true,
      sorting: 2,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Значение 2',
      dataIndex: TableColumnName.Value2,
      key: TableColumnName.Value2,
      active: false,
      sorting: 2,

      editable: true,
      ellipsis: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Значение 3',
      dataIndex: TableColumnName.Value3,
      key: TableColumnName.Value3,
      active: false,

      editable: true,
      sorting: 2,
      ellipsis: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Cмещение времени',
      dataIndex: TableColumnName.UTCOffset,
      key: TableColumnName.UTCOffset,
      active: false,

      editable: false,
      ellipsis: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Временная зона',
      dataIndex: TableColumnName.TimeZone,
      key: TableColumnName.TimeZone,
      active: false,

      editable: false,
      sorting: 5,
      ellipsis: true,
      type: CellType.TimeStringUtc,
      grouping: true
    }
  ]
};

export const depotConfigForValidate: TableConfig = {
  ...DEPOT_TABLE_CONFIG,
  deleting: true,
  //NOTE selecting: true - for handleBindTimeZone
  selecting: true,
  export: false,
  copying: false,
  adding: false,
  multiEditing: false,
  reloading: false,
  showDeleted: undefined,
  getCoordinates: true
};
