import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { User } from '@common/types';
import { ILSButtonTooltip } from '@components/index';
import { useUserBlocking } from '@modules/account/utils/hooks/use-user-blocking';
import { useUserActions } from '@modules/account/utils/hooks/use-user-actions';
import { DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

interface IProps {
  user: User;

  toggleForm(show: boolean, user: User | null): void;
}

export const renderAccountUserCardActions = ({ user, toggleForm }: IProps) => {
  const dispatch = useDispatch();
  const { ID: userID, Blocked } = user;
  const { handleBlockUser, blockedTitle, blocked } = useUserBlocking({ dispatch, userID, Blocked });
  const { handleChangeUser, handleDeleteUser } = useUserActions({ dispatch, user, userID, toggleForm });

  return [
    <ILSButtonTooltip title="Изменение пользователя" shape="circle" type="primary" onClick={handleChangeUser} icon={<EditOutlined />} />,
    <ILSButtonTooltip
      title="Удаление пользователя"
      shape="circle"
      type="primary"
      className="bg-error border-error"
      onClick={handleDeleteUser}
      icon={<DeleteOutlined />}
    />,
    <ILSButtonTooltip
      shape="circle"
      type="primary"
      buttonClassName={classnames('bg-info border-info', { blocked })}
      placement={'left'}
      icon={blocked ? <UnlockOutlined /> : <LockOutlined />}
      onClick={handleBlockUser}
      title={blockedTitle}
    />
  ];
};
