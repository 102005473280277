import { FC, useEffect, useState } from 'react';
import { ProjectDemandRow, TripItem } from '../types/store';
import { DemandListItemProjectComponent } from '../components/projects/demand-list-item-project';

interface Props {
  listItem: ProjectDemandRow;
}

// Контейнер для отображения одного проекта в разделе "Заявки и рейсы"
export const DemandListItemProjectContainer: FC<Props> = ({ listItem }) => {
  const [showAllTrips, setShowAllTrips] = useState<boolean>(false);
  const [isVisibleButton, setIsVisisbleButton] = useState<boolean>(false);
  const [itemsToShow, setItemsToShow] = useState<TripItem[]>([]);

  const projectID = listItem.Data.ID;
  const projectDT = listItem.DateTime;
  const projectName = listItem.Data.Name;
  const tripsLength = listItem.Data?.Trips?.length;

  useEffect(() => {
    if (showAllTrips) {
      setItemsToShow(listItem.Data?.Trips);
      setIsVisisbleButton(false);
    } else if (listItem.Data?.Trips?.length < 6) {
      setItemsToShow(listItem.Data?.Trips);
      setIsVisisbleButton(false);
    } else {
      setItemsToShow(listItem.Data?.Trips?.slice(0, 5));
      setIsVisisbleButton(true);
    }
  }, [showAllTrips]);

  const handleClickShowMore = () => {
    setShowAllTrips(true);
    setIsVisisbleButton(false);
  };

  return (
    <DemandListItemProjectComponent
      projectID={projectID}
      projectDT={projectDT}
      projectName={projectName}
      tripsLength={tripsLength}
      itemsToShow={itemsToShow}
      isVisibleButton={isVisibleButton}
      handleClickShowMore={handleClickShowMore}
    />
  );
};
