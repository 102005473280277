import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify, NotifyDurationInSecond } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { SCHEME_EDITOR_DEFAULT_MESSAGE, SCHEME_EDITOR_KEY_PREFIX } from '@modules/planning/children/scheme-editor/constants';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { SchemeAction } from '@modules/planning/children/scheme-editor/types';
import { schemeRoutinesWithNotifier } from '@modules/planning/children/scheme-editor/actions';

//TODO методы повторяются в каждой Notifier саге, меняется только тип (generic как решение) и то, не везде нужно рефакторить

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: SchemeAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || SCHEME_EDITOR_DEFAULT_MESSAGE?.[action]?.[type] || EMPTY_STRING;
};

export default getDataNotificationMessage;
//TODO методы повторяются ы каждой Notifier саге, меняется только тип (generic как решение) и то, не везде нужно рефакторить
const getActionFromRoutine = (actionName: string): SchemeAction => actionName as SchemeAction;

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, SchemeAction>({
  getActionFromRoutine,
  getKeyPrefix: () => SCHEME_EDITOR_KEY_PREFIX,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

const errorWorker = notifierWorkerCreator<ErrorAPI, SchemeAction>({
  getActionFromRoutine,
  getKeyPrefix: () => SCHEME_EDITOR_KEY_PREFIX,
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* planningDataNotifierWatcher() {
  for (let routine of schemeRoutinesWithNotifier) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
