import { cleanEmptyFields } from '@common/utils/helpers/object/clean-empty-fields';
import { useAppSelector } from '@core/hooks';
import { accountRoleSelector } from '@modules/current-user/selectors/auth';
import { FC } from 'react';
import { ILSDemandFiltersComponent } from '../components/header/filters';
import { GetListRequest, RequestParamsFilter } from '../types/api';

interface IProps {
  filter: GetListRequest['filter'];
  onChangeFilter: (f: RequestParamsFilter) => void;
  hasDesync: boolean;
  onReload: () => void;
}

// Контейнер, который отвечает за фильтры
export const ILSDemandFiltersContainer: FC<IProps> = (props) => {
  const { filter } = props;

  const onChangeFilter: (values: Partial<RequestParamsFilter>) => void = (values) => {
    const x = { ...filter, ...values };
    cleanEmptyFields(x, { needClearEmptyString: true });
    props.onChangeFilter(x);
  };

  const account = useAppSelector(accountRoleSelector);

  return <ILSDemandFiltersComponent {...props} onChangeFilter={onChangeFilter} account={account} />;
};
