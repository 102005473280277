import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { RoadGraphEditorStore } from '../types';
import { roadGraphGetSegmentsRoutine } from '../actions';
import { PayloadActionError } from '@common/types';

const roadGraphGetSegments = createReducer(initialState, {
  [roadGraphGetSegmentsRoutine.REQUEST]: (
    state: RoadGraphEditorStore,
    action: PayloadAction<{
      points: string;
      roads: string;
    }>
  ) => {
    const { points, roads } = action.payload;
    state.geoHash = points;
    state.roads = roads;
    state.isSaving = 'Получение данных...';
    return state;
  },
  [roadGraphGetSegmentsRoutine.SUCCESS]: (
    state: RoadGraphEditorStore,
    action: PayloadAction<{
      data: [];
    }>
  ) => {
    const { data } = action.payload;
    state.roadData = data;
    state.isSaving = false;
    state.success = 'Данные получены';
    return state;
  },
  [roadGraphGetSegmentsRoutine.FAILURE]: (state: RoadGraphEditorStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isSaving = false;
    state.error = error;
    return state;
  },
  [roadGraphGetSegmentsRoutine.FULFILL]: (state: RoadGraphEditorStore) => {
    state.error = [];
    state.success = false;
    return state;
  }
});

export default roadGraphGetSegments;
