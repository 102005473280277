import { monitoringDateRoutine } from '../actions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringState } from '../types/store';

// Устанавливает даты начала и окончания периода мониторинга
const monitoringDateReducer = createReducer<MonitoringState>(initialState as MonitoringState, {
  [monitoringDateRoutine.REQUEST]: () => {},
  [monitoringDateRoutine.SUCCESS]: (state, action: PayloadAction<{ date: string[]; metricsInterval: number | undefined }>) => {
    const { date } = action.payload;

    state.metricsInterval.startDate = date[0] || '';
    state.metricsInterval.endDate = date[1] || '';

    return state;
  },
  [monitoringDateRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringDateRoutine.FULFILL]: (state) => {
    return {
      ...state
    };
  }
});

export default monitoringDateReducer;
