import { useEffect } from 'react';
import { checkTokenRoutine, getAccountRoutine, logoutActions } from '@modules/current-user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelector, currentUserSelector, tokenSelector } from '@modules/current-user/selectors/auth';

export const useToken = () => {
  const dispatch = useDispatch();

  const token = useSelector(tokenSelector);
  const user = useSelector(currentUserSelector);
  const account = useSelector(accountSelector);

  useEffect(() => {
    if (user.Token) {
      dispatch(checkTokenRoutine());
      if (!account) {
        dispatch(getAccountRoutine());
      }
    }
  }, [user.Token]);

  // в случае если токен в store не совпадает с сохраненным
  useEffect(() => {
    if (user.Token && token && user.Token !== token) {
      dispatch(logoutActions());
    }
  }, [token, user.Token]);
};
