import { CellType, DateFormat, DateTimeFormat, FilterCellType, TimeFormat } from '@common/types';
import { SchemeKeyType } from '@modules/planning/children/scheme-editor/constants';
import {
  ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
  ILS_MAIN_INTERFFACE_TIME_FORMAT
} from '@common/types/general/date-time';

export type ColumnConverted = {
  type: CellType;
  format?: DateFormat | TimeFormat | DateTimeFormat;
  dateParseFormat?: DateFormat | TimeFormat | DateTimeFormat;
};

export const convertServerTypeToInterfaceType = ({ type, isTime }: { type: SchemeKeyType; isTime: boolean }): ColumnConverted => {
  const ILS_DATE_COLUMN = {
    type: CellType.Date,
    format: ILS_MAIN_INTERFFACE_DATE_FORMAT,
    filtering: FilterCellType.String,
    sorting: 1
  };
  const ILS_TIME_COLUMN = {
    type: CellType.Time,
    format: ILS_MAIN_INTERFFACE_TIME_FORMAT,
    filtering: FilterCellType.String,
    sorting: 1
  };
  const ILS_DATE_TIME_COLUMN = {
    type: CellType.DateTime,
    format: ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
    filtering: FilterCellType.String,
    sorting: 1
  };
  switch (type) {
    case SchemeKeyType.DDMMYYYYPeriod:
      return {
        ...ILS_DATE_COLUMN,
        dateParseFormat: DateFormat.DDMMYYYYPeriod
      };
    case SchemeKeyType.YYYYMMDDDash:
      return {
        ...ILS_DATE_COLUMN,
        dateParseFormat: DateFormat.YYYYMMDDDash
      };
    case SchemeKeyType.DATETIME:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.DMYPeriodHISColon
      };
    // количество часов, возможно не целое
    case SchemeKeyType.H:
    // количество часов, целое
    case SchemeKeyType.HI:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.HH
      };
    //количество секунд, возможно не целое
    case SchemeKeyType.S:
    //количество секунд, целое
    case SchemeKeyType.SI:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.SS
      };
    case SchemeKeyType.HHMM:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.HHMM
      };
    case SchemeKeyType.HHMMColonDDMDot:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.HHMMColonDDMDot
      };
    case SchemeKeyType.HHMMSSColon:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.HHMMColon
      };
    case SchemeKeyType.ISO8601:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.ISO8601
      };
    case SchemeKeyType.YYYYMMDD:
      return {
        ...ILS_DATE_COLUMN,
        dateParseFormat: DateFormat.YYYYMMDD
      };
    case SchemeKeyType.YYYYMMDDDashHHMMColon:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.YYYYMMDDDashHHMMColon
      };
    case SchemeKeyType.YYYYMMDDDashHHMMSSColon:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.YYYYMMDDDashHHMMSSColon
      };
    case SchemeKeyType.YYYYMMDDHHMM:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.YYYYMMDDHHMM
      };
    case SchemeKeyType.YYYYMMDDHHMMSS:
      return {
        ...ILS_DATE_TIME_COLUMN,
        dateParseFormat: DateTimeFormat.YYYYMMDDHHMMSS
      };
    case SchemeKeyType.HHMMColon:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.HHMMColon
      };
    // количество минут, целое
    case SchemeKeyType.MI:
    // количество минут, возможно не целое
    case SchemeKeyType.M:
      return {
        ...ILS_TIME_COLUMN,
        dateParseFormat: TimeFormat.mm
      };

    case SchemeKeyType.KILO:
    case SchemeKeyType.MEGA:
    case SchemeKeyType.MICRO:
    case SchemeKeyType.MILLI:
    case SchemeKeyType.NUMBER:
    case SchemeKeyType.INTEGER:
    case SchemeKeyType.INT:
      return {
        type: CellType.Int
      };
    case SchemeKeyType.REAL:
    case SchemeKeyType.FLOAT:
    case SchemeKeyType.CommaDouble:
    case SchemeKeyType.DOUBLE:
      return isTime
        ? {
            ...ILS_TIME_COLUMN,
            type: CellType.TimeDouble
          }
        : {
            type: CellType.Float
          };
    case SchemeKeyType.OVERLOAD:
    case SchemeKeyType.PERCENT:
      return {
        type: CellType.Percent
      };
    case SchemeKeyType.DEGMIN:
    case SchemeKeyType.DEGMINSEC:
      return {
        type: CellType.Geo
      };
    case SchemeKeyType.VARCHAR:
    case SchemeKeyType.STRING:
    default:
      return {
        type: CellType.Input
      };
  }
};
