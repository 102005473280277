export { DEFAULT_ORDER_COLOR } from './order';
export interface IColorItem {
  id: string;
  RGB: string;
  HSB: string;
  name: string;
  marker?: string;
}

export const BASE_ROUTE_COLOR = '#FF0000';
export const COLOR_BLACK = '#000000';

export const colorList: Array<IColorItem> = [
  { id: 'navy', RGB: '#000080', HSB: '240/100/50', name: 'флотский' },
  { id: 'violet', RGB: '#EE82EE', HSB: '300/45/93', name: 'темно-лиловый, фиолетовый', marker: 'violet' },
  { id: 'green', RGB: '#008000', HSB: '120/100/50', name: 'зеленый', marker: 'green' },
  { id: 'steelblue', RGB: '#4682B4', HSB: '207/61/71', name: 'голубовато-стальной', marker: 'blue-dark' },
  { id: 'darkturquoise', RGB: '#00CED1', HSB: '181/100/82', name: 'темно-бирюзовый' },
  { id: 'teal', RGB: '#008080', HSB: '180/100/50', name: '   teal - чирок (птица)' },
  { id: 'darkcyan', RGB: '#008B8B', HSB: '180/100/55', name: 'темно-циановый' },
  { id: 'cadetblue', RGB: '#5F9EA0', HSB: '182/41/63', name: ' ' },
  { id: 'slategray', RGB: '#708090', HSB: '210/22/56', name: 'аспидно-серый' },
  { id: 'lightslategray', RGB: '#778899', HSB: '210/22/60', name: 'светлый аспидно-серый' },
  { id: 'darkgreen', RGB: '#006400', HSB: '120/100/39', name: 'темно-зеленый', marker: 'green-dark' },
  { id: 'orange', RGB: '#FFA500', HSB: '39/100/100', name: 'оранжевый', marker: 'orange' },
  { id: 'purple', RGB: '#800080', HSB: '300/100/50', name: 'пурпурный', marker: 'purple' },
  { id: 'red', RGB: '#FF0000', HSB: '0/100/100', name: 'красный', marker: 'orange-dark' },
  { id: 'blue', RGB: '#0000FF', HSB: '240/100/100', name: 'голубой (синий)' },
  { id: 'darkseagreen', RGB: '#8FBC8F', HSB: '120/24/74', name: 'темный морской волны' },
  { id: 'lightcoral', RGB: '#F08080', HSB: '0/47/94', name: 'светло-коралловый' },
  { id: 'chartreuse', RGB: '#7FFF00', HSB: '90/100/100', name: 'шартрез (бледно-зеленый)' },
  { id: 'brown', RGB: '#A52A2A', HSB: '0/75/65', name: 'коричневый' },
  { id: 'firebrick', RGB: '#B22222', HSB: '0/81/70', name: 'кирпичный' },
  { id: 'olivedrab', RGB: '#6B8E23', HSB: '80/75/56', name: 'нежно-оливковый' },
  { id: 'yellowgreen', RGB: '#9ACD32', HSB: '80/76/80', name: 'желто-зеленый' },
  { id: 'darkolivegreen', RGB: '#556B2F', HSB: '82/56/42', name: 'темный оливково-зеленый' },
  { id: 'olive', RGB: '#808000', HSB: '60/100/50', name: 'оливковый' },
  { id: 'darksalmon', RGB: '#E9967A', HSB: '15/48/91', name: 'темно-лососевый' },
  { id: 'orangered', RGB: '#FF4500', HSB: '16/100/100', name: 'оранжево-красный' },
  { id: 'lightsalmon', RGB: '#FFA07A', HSB: '17/52/100', name: 'светло-лососевый' },
  { id: 'sienna', RGB: '#A0522D', HSB: '19/72/63', name: 'сиена (охра)' },
  { id: 'saddlebrown', RGB: '#8B4513', HSB: '25/86/55', name: 'кожаного седла' },
  { id: 'chocolate', RGB: '#D2691E', HSB: '25/86/82', name: 'шоколадный' },
  { id: 'darkblue', RGB: '#00008B', HSB: '240/100/55', name: 'темно-синий' },
  { id: 'salmon', RGB: '#FA8072', HSB: '6/54/98', name: 'лососевый' },
  { id: 'forestgreen', RGB: '#228B22', HSB: '120/76/55', name: 'лесной зелени' },
  { id: 'limegreen', RGB: '#32CD32', HSB: '120/76/80', name: 'зеленого лайма  лайм - цитрус, похожий на зеленый лимон' },
  { id: 'seagreen', RGB: '#2E8B57', HSB: '146/67/55', name: 'морской волны' },
  { id: 'mediumseagreen', RGB: '#3CB371', HSB: '147/66/70', name: 'умеренный морской волны' },
  { id: 'maroon', RGB: '#800000', HSB: '0/100/50', name: 'густой красно-коричневый' },
  { id: 'darkred', RGB: '#8B0000', HSB: '0/100/55', name: 'темно-красный' },
  { id: 'goldenrod', RGB: '#DAA520', HSB: '43/85/85', name: 'золотисто-красный' },
  { id: 'darkgoldenrod', RGB: '#B8860B', HSB: '43/94/72', name: 'темный золотисто-красный' },
  { id: 'darkkhaki', RGB: '#BDB76B', HSB: '56/43/74', name: 'темный хаки' },
  { id: 'mediumaquamarine', RGB: '#66CDAA', HSB: '160/50/80', name: 'умеренный аквамариновый' },
  { id: 'lightseagreen', RGB: '#20B2AA', HSB: '177/82/70', name: 'светлый морской волны' },
  { id: 'indianred', RGB: '#CD5C5C', HSB: '0/55/80', name: ' ' },
  { id: 'peru', RGB: '#CD853F', HSB: '30/69/80', name: ' ' },
  { id: 'dimgray', RGB: '#696969', HSB: '0/0/41', name: 'тускло-серый' },
  { id: 'gray', RGB: '#808080', HSB: '0/0/50', name: 'серый' },
  { id: 'midnightblue', RGB: '#191970', HSB: '240/78/44', name: 'синей полночи' },
  { id: 'mediumblue', RGB: '#0000CD', HSB: '240/100/80', name: 'умеренный голубой (синий)' },
  { id: 'darkslateblue', RGB: '#483D8B', HSB: '248/56/55', name: 'темный аспидно-синий' },
  { id: 'slateblue', RGB: '#6A5ACD', HSB: '248/56/80', name: 'аспидно-синий' },
  { id: 'darkmagenta', RGB: '#8B008B', HSB: '300/100/55', name: 'темный фуксин' },
  { id: 'mediumslateblue', RGB: '#7B68EE', HSB: '249/56/93', name: 'умеренный аспидно-синий' },
  { id: 'mediumpurple', RGB: '#9370DB', HSB: '260/49/86', name: 'умеренный пурпурный' },
  { id: 'blueviolet', RGB: '#8A2BE2', HSB: '271/81/89', name: 'сине-фиолетовый' },
  { id: 'indigo', RGB: '#4B0082', HSB: '275/100/51', name: 'индиго' },
  { id: 'dodgerblue', RGB: '#1E90FF', HSB: '210/88/100', name: ' ' },
  { id: 'cornflowerblue', RGB: '#6495ED', HSB: '219/58/93', name: 'васильковый' },
  { id: 'royalblue', RGB: '#4169E0', HSB: '225/71/88', name: 'королевский голубой' },
  { id: 'darkorchid', RGB: '#9932CC', HSB: '280/75/80', name: 'темно-лиловый' },
  { id: 'orchid', RGB: '#DA70D6', HSB: '302/49/85', name: 'лиловый' },
  { id: 'mediumvioletred', RGB: '#C71585', HSB: '322/89/78', name: 'умеренный лилово-красный' },
  { id: 'darkviolet', RGB: '#9400D3', HSB: '282/100/83', name: 'темно-фиолетовый' },
  { id: 'mediumorchid', RGB: '#BA55D3', HSB: '288/60/83', name: 'умеренный лиловый' },
  { id: 'rosybrown', RGB: '#BC8F8F', HSB: '0/24/74', name: 'розово-коричневый' },
  { id: 'plum', RGB: '#DDA0DD', HSB: '300/28/87', name: 'сливовый (темно-фиолетовый с оттенками бордового)' },
  { id: 'crimson', RGB: '#DC143C', HSB: '348/91/86', name: 'малиновый, темно-красный, кармазинный' },
  { id: 'coral', RGB: '#FF7F50', HSB: '16/69/100', name: 'коралловый' },
  { id: 'greenyellow', RGB: '#ADFF2F', HSB: '84/82/100', name: 'зелено-желтый' },
  { id: 'darkslategray', RGB: '#2F4F4F', HSB: '180/41/31', name: 'темный аспидно-серый (темно-синевато-серый)' },
  { id: 'deepskyblue', RGB: '#00BFFF', HSB: '195/100/100', name: 'насыщенный небесно-голубой' },
  { id: 'fuchsia', RGB: '#FF00FF', HSB: '300/100/100', name: 'фуксии' },
  { id: 'deeppink', RGB: '#FF1493', HSB: '328/92/100', name: 'насыщенный розовый' },
  { id: 'hotpink', RGB: '#FF69B4', HSB: '330/59/100', name: 'яркий розовый' },
  { id: 'palevioletred', RGB: '#DB7093', HSB: '340/49/86', name: 'бледный лилово-красный' },
  { id: 'tomato', RGB: '#FF6347', HSB: '9/72/100', name: 'томатный' },
  { id: 'black', RGB: COLOR_BLACK, HSB: '0/0/0', name: 'черный' }
];
