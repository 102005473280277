import { createSelector } from 'reselect';
import { accountUsersOptionsSelector, currentTenderMyOfferSelector } from '@modules/tender-platform/selectors/current-tender';
import { tenderOfferInitialValuesFormSelector } from '@modules/tender-platform/children/tender-search/selectors/components/offer-initial-values';

export const tenderOfferFormSelector = createSelector(
  tenderOfferInitialValuesFormSelector,
  accountUsersOptionsSelector,
  currentTenderMyOfferSelector,
  ({ initialValues }, userOptions, offer) => {
    const tenderOfferID = offer.ID;
    return { initialValues, userOptions, tenderOfferID };
  }
);
