import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ILSAppRegistry } from '../../index';
import { Store } from 'redux';
import { logoutActions } from '@modules/current-user/actions';
import { ContentType } from '@common/types/general/general';
import { isUndefined, omitBy } from 'lodash';

const buildFormDataRequest = (data: any): FormData => {
  const formData = new FormData();
  buildFormData(formData, omitBy(data, isUndefined));
  return formData;
};

const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((key) => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      formData.append(parentKey!, JSON.stringify([]));
    }
  } else {
    formData.append(parentKey!, data === null ? '' : data);
  }
};

export const apiInstance = (config: Partial<AxiosRequestConfig & { contentType: ContentType }> = {}): AxiosInstance => {
  const store: Store = ILSAppRegistry.store;
  const { Auth } = store.getState();
  const { token }: { token: string } = Auth;
  let AxiosConfig: AxiosRequestConfig = {
    baseURL: config.baseURL ?? process.env.REACT_APP_API_HOST,
    headers: config.headers ?? {
      post: {
        'Content-Type': config.contentType ?? ContentType.MutipartFormdata
      }
    },
    transformRequest: [buildFormDataRequest],
    timeout: config.timeout ?? Number(process.env.REACT_APP_API_REQUEST_TIMEOUT),
    withCredentials: true
  };

  if (config.contentType === ContentType.ApplicationJson) {
    delete AxiosConfig.transformRequest;
  }

  if (token) {
    AxiosConfig.headers.Authorization = `Bearer ${token}`;
  }

  const axiosInstance: AxiosInstance = axios.create(AxiosConfig);

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.request && error.request.status) {
        if (error.request.status === 401) {
          store.dispatch({ type: logoutActions.SUCCESS, payload: { hideSuccessMessage: true } });
        }
      }

      if (error.response && error.response.data && error.response.data.data && error.response.data.data.errors) {
        return Promise.reject(error.response.data.data.errors);
      }

      return Promise.reject([error.message]);
    }
  );

  return axiosInstance;
};
