import { CellType, TableColumnName, TableConfig } from '@common/types';
import { CURRENCY_OPTIONS } from '@common/constants';
import { NEW_CARGO } from '@common/models/cargo/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const CARGO_TABLE_COLUMNS: TableConfig['columns'] = [
  {
    title: 'Кол-во грузов',
    dataIndex: 'Amount',
    key: 'Amount',
    editable: false,
    active: false,
    type: CellType.Int,
    sorting: 5
  },
  {
    title: 'Масса, кг',
    dataIndex: TableColumnName.Mass,
    key: TableColumnName.Mass,
    editable: true,
    active: false,
    type: CellType.Float,
    sorting: 5
  },
  {
    title: 'Объем, м3',
    dataIndex: TableColumnName.Volume,
    key: TableColumnName.Volume,
    editable: true,
    active: false,
    type: CellType.Float,
    sorting: 5
  },
  {
    title: 'Паллет, шт',
    dataIndex: TableColumnName.Pallets,
    key: TableColumnName.Pallets,
    editable: true,
    active: false,
    type: CellType.Float,
    sorting: 5
  },
  {
    title: 'Коробов, шт',
    dataIndex: TableColumnName.Boxes,
    key: TableColumnName.Boxes,
    editable: true,
    active: false,
    type: CellType.Float,
    sorting: 5
  },
  {
    title: 'Длит.Погрузки',
    dataIndex: 'LoadDuration',
    key: 'LoadDuration',
    editable: false,
    active: false,
    type: CellType.TimeDouble,
    sorting: 5
  },
  {
    title: 'Длит.Разгрузки',
    dataIndex: 'UnloadDuration',
    key: 'UnloadDuration',
    editable: false,
    sorting: 5,
    active: false,
    type: CellType.TimeDouble
  },
  {
    title: 'Задержка (на точке разгрузки)',
    dataIndex: 'Delay',
    key: 'Delay',
    editable: false,
    sorting: 5,
    active: false,
    type: CellType.TimeDouble
  },
  {
    title: 'Стоимость',
    dataIndex: 'Cost',
    key: 'Cost',
    editable: false,
    active: false,
    sorting: 5,
    type: CellType.Float
  }
];

export const CARGO_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  reloading: true,
  copying: true,
  adding: NEW_CARGO,
  columns: [
    ID_COLUMN,
    {
      title: 'Код груза',
      dataIndex: 'CargoCode',
      key: 'CargoCode',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,
      sorting: 1
    },
    {
      title: 'Штрихкод',
      dataIndex: 'Barcode',
      key: 'Barcode',
      editable: true,
      active: false,
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      sorting: 1
    },
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,
      sorting: 3,
      filtering: 'string'
    },
    {
      title: 'Масса, кг',
      dataIndex: 'Mass',
      key: 'Mass',
      editable: true,
      active: true,
      type: CellType.Float,
      sorting: 2,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Объем, м3',
      dataIndex: 'Volume',
      key: 'Volume',
      editable: true,
      active: true,
      type: CellType.Float,
      sorting: 1,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Паллет, шт',
      dataIndex: 'Pallets',
      key: 'Pallets',
      editable: true,
      active: false,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Коробов, шт',
      dataIndex: 'Boxes',
      key: 'Boxes',
      editable: true,
      active: false,
      type: CellType.Float,
      sorting: 1,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Весовой товар',
      dataIndex: 'IsWeight',
      key: 'IsWeight',
      editable: true,
      active: true,
      required: true,
      type: CellType.Bool,
      width: 100
    },
    {
      title: 'Ширина, м',
      dataIndex: 'UnitWidth',
      key: 'UnitWidth',
      editable: true,
      active: false,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Высота, м',
      dataIndex: 'UnitHeight',
      key: 'UnitHeight',
      editable: true,
      active: false,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длина, м',
      dataIndex: 'UnitLength',
      key: 'UnitLength',
      editable: true,
      active: false,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длит.Погрузки',
      dataIndex: 'LoadDuration',
      key: 'LoadDuration',
      editable: true,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Длит.Разгрузки',
      dataIndex: 'UnloadDuration',
      key: 'UnloadDuration',
      editable: true,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Описание',
      dataIndex: 'Description',
      key: 'Description',
      editable: true,
      active: false,
      type: CellType.Input,
      ellipsis: true,
      sorting: 1
    },
    {
      title: 'Приоритет',
      dataIndex: 'Priority',
      key: 'Priority',
      editable: true,
      active: false,
      type: CellType.Int,
      sorting: 1,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Стоимость',
      dataIndex: 'Cost',
      key: 'Cost',
      editable: true,
      active: false,
      width: 113,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Валюта',
      dataIndex: 'Currency',
      key: 'Currency',
      active: false,
      editable: true,
      type: CellType.Select,
      options: CURRENCY_OPTIONS,
      fieldNames: BASE_FIELD_NAMES
    }
  ]
};

export const CARGO_MODEL_FIELDS = CARGO_TABLE_CONFIG.columns.map(({ dataIndex }) => dataIndex);
