import {
  carMarker,
  colorMarker,
  customMarker,
  defaultMarker,
  destinationMarker,
  orderMarker,
  pointMarker
} from '@core/components/map/icons';
import { ILSMap } from '@core/containers/map';
import { Marker } from '@core/types/map';
import { Track } from '@modules/track/types';
import L, { LatLngExpression } from 'leaflet';
import { MutableRefObject, useEffect } from 'react';
import { TrackMapLayersGroup } from './init';

import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import homeMarker from '@core/components/map/icons/storage.svg';
import loadMarker from '@core/components/map/icons/take.svg';
import clientMarker from '@core/components/map/icons/user.svg';
import { setOrderTrackFitBound } from '@modules/planning/children/by-date/hooks/map/use-order-track-fit-bound';
import { IMapTrack } from '@common/types';

type useShowPolylineEffect = (arg0: {
  Map: MutableRefObject<ILSMap | null>;
  trackPolylines: Track[];
  mapLayersGroup: TrackMapLayersGroup;
}) => void;

export const useShowPolyline: useShowPolylineEffect = ({ Map, trackPolylines, mapLayersGroup }) => {
  const { isInit, markerLayer, trackLayer } = mapLayersGroup;

  useEffect(() => {
    // Если isInit то markerLayer и trackLayer - L.LayerGroup
    if (Map.current && isInit) {
      trackLayer?.clearLayers();
      markerLayer?.clearLayers();
      drawPolylines(Map, trackPolylines, markerLayer as L.LayerGroup, trackLayer as L.LayerGroup);
      setOrderTrackFitBound({ Map, polyline: trackPolylines as IMapTrack[] });
    }
  }, [trackPolylines]);
};

const drawPolylines = (Map: MutableRefObject<ILSMap | null>, polylines: Track[], markerLayer: L.LayerGroup, trackLayer: L.LayerGroup) => {
  if (Map.current) {
    let markers: Marker[] = [];

    if (polylines && polylines.length) {
      polylines.forEach((polyline) => {
        const marker = polyline.point && polyline.point.marker;
        let dashedArr = [2, 5];
        if (polyline.weight) {
          dashedArr = [polyline.weight && polyline.weight < 3 ? polyline.weight * 3 : polyline.weight * 2, polyline.weight * 5];
        }

        const coords = polyline.coords && typeof polyline.coords === 'string' ? GeohashDecodePoints(polyline.coords) : polyline.coords;
        if (Map.current && coords) {
          if (marker) {
            markers = markers.concat(marker);
          }

          const polyLine = L.polyline(coords as LatLngExpression[], {
            color: `${polyline.color}B3` ?? '#4C91FFB3',
            weight: polyline.weight ?? 3,
            dashArray: polyline?.dashed ? dashedArr.join(', ') : undefined
          });

          polyLine.addTo(trackLayer as L.LayerGroup);
        }
      });
    }

    if (markers && markers.length) {
      drawMarkers(Map, markers, markerLayer, true);
    }
  }
};

const drawMarkers = (Map: MutableRefObject<ILSMap | null>, markers: Marker[], markerLayer: L.LayerGroup, polylineMarkers?: boolean) => {
  if (Map.current) {
    if (markers && markers.length) {
      if (!polylineMarkers) {
        markers = markers;
      }

      markers.forEach((marker) => {
        const ID = marker.data?.ID;
        const { color = '#4C91FF' } = marker;

        let icon;
        switch (marker.type) {
          case 'color':
            icon = colorMarker(color);
            break;
          case 'order':
            icon = orderMarker(color, false, ID, marker.data);
            break;
          case 'point':
            icon = pointMarker(marker.data, color, marker.number ?? 0, marker.figure, false, ID);
            break;
          case 'forTrack':
            icon = customMarker(clientMarker, '#2358C6');
            break;
          case 'storage':
            icon = customMarker(homeMarker, '#4C91FF');
            break;
          case 'client':
            icon = customMarker(clientMarker, '#2358C6');
            break;
          case 'load':
            icon = customMarker(loadMarker, '#50B993');
            break;
          case 'car':
            icon = carMarker();
            break;
          case 'destination':
            icon = destinationMarker(marker.text ?? '', '#4C91FF');
            break;
          default:
            icon = defaultMarker;
        }

        if (marker.coords) {
          const coords = Object.values(marker.coords);
          const draggable = (marker.type && marker.type === 'point') as boolean;
          const newMarker = L.marker(coords as LatLngExpression, {
            icon: icon,
            draggable: draggable,
            // @ts-ignore
            data: marker.data
          });

          if (marker.pointData) {
            if (marker.type === 'forTrack') {
              newMarker.bindPopup(markerTip(marker.pointData));
            }
          }

          newMarker.addTo(markerLayer);
        }
      });
    }
  }
};

export type TData = {
  Text?: string | null;
  ArrivalTime?: string | number | null;
  DepartureTime?: string | number | null;
};

export const markerTip = (data: TData) =>
  `
		<div class="point-tip font-13">
			<div class="title mb-1">${data.Text ?? ''}</div>

			${
        data.ArrivalTime
          ? `<div class="row">
						<div class="label short">Прибытие:</div>
						<div class="value last">${data.ArrivalTime}</div>
					</div>`
          : ''
      }
      ${
        data.DepartureTime
          ? `<div class="row">
						<div class="label short">Отбытие:</div>
						<div class="value last">${data.DepartureTime}</div>
					</div>`
          : ''
      }
		</div>
	`;

