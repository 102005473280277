import { Popover, PopoverProps } from 'antd';
import { FC } from 'react';

export const ILSPopover: FC<PopoverProps> = ({ children, mouseLeaveDelay = 0, ...props }) => {
  return (
    <Popover {...props} mouseLeaveDelay={mouseLeaveDelay}>
      {children}
    </Popover>
  );
};
