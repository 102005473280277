import { Double, Key, TimeStamp } from '../general';
import { Account } from './account';
import { Driver } from './driver';
import { Plan, PlanStatus } from './plan';
import { Vehicle } from './vehicle';
import { Waypoint, WaypointTotal } from './waypoint';
import { Efficiency, IEnum, IndexedArray, Restriction, TransportCompany } from '@common/types';

export type TripTotal = {
  ID: Trip['ID'];
  TripID: Trip['ID'];
  FullName: string;
  Number: string;
  RouteColor: Vehicle['RouteColor'];
  Status: PlanStatus;
  TenderID?: number;
  TransportCompanyEnum: IEnum<TransportCompany['Name']>;
  DriverID: Driver['ID'] | null;
  ExpeditorID: Driver['ID'] | null;
  DriverEnum: IEnum<Driver['Name']>;
  ExpeditorEnum: IEnum<Driver['Name']>;
  Comment: Trip['Comment'];
  CargoCount: number;
  ClientCount: number;
  Distance: number;
  Duration: Double;
  RoadDuration: Double;
  MoveDuration: Double;
  RestDuration: Double;
  WaitingDuration: Double;
  LatenessDuration: Double;
  Penalty: number;
  StartTime: TimeStamp;
  EndTime: TimeStamp;
  StartTimeOffset?: number | null;
  EndTimeOffset?: number | null;
  DeliveryDate: TimeStamp;
  TripTemplate: string;
  /** Всегда 0, не ясно откуда вообще в планировщике какой-либо факт */
  FactCost: number;
  Cost: number;
  CostPerKM: number;
  CostPerKG: number;
  LoadDuration: Double;
  UnloadDuration: Double;
  Mass?: number;
  Volume?: number;
  Pallets?: number;
  Boxes?: number;
  Dimensions?: string;
  AverageSpeed: number;
  PlanID: Plan['ID'];
  VehicleID: Vehicle['ID'];
  VehicleEndDepotID: Vehicle['EndDepotID'];
  RestrictionEnum: IEnum<Restriction['Name']>;
  Efficiency: Efficiency;
  WaypointTotal: IndexedArray<WaypointTotal>;
};

export type TripModel = {
  ID: number;
  Ind: number;
  PlanID: string;
  FactDistance: number | null;
  TransportCompanyID: null;
  TripBegin: TimeStamp | null;
  TripEnd: TimeStamp | null;
  VehicleID: Vehicle['ID'];
  Status: PlanStatus | number;
  StatusID: keyof PlanStatus | null;
  DriverID: Driver['ID'] | null;
  Info: Partial<{
    ExpeditorStatusChanges: Array<{
      DT: string;
      Status: number;
      UserID: Key;
    }>;
  }> | null;
  Comment: string | null;
  ExpeditorID: Driver['ID'] | null;
};

export type Trip = TripModel & {
  TransportCompanyEnum: IEnum<TransportCompany['Name']>;
  Vehicle: Vehicle;
  Waypoint: Array<Waypoint>;
  DriverEnum: IEnum<Driver['Name']>;
};

export type TripTemplate = {
  ID: number;
  Name: string;
  Code: string | null;
  MinCost: number | null;
  MinPoints: number | null;
  CondForMin: CondForMin | null;
  AccountID: Account['ID'];
  Color: number | null;
  Deleted: string | null;
  TemplateMode: TemplateMode | null;
};

export enum TemplateMode {
  Disabled,
  FullTrip,
  OneTrip,
  ManyTrip
}

export enum TemplateModeValue {
  Disabled = 'Disabled',
  FullTrip = 'FullTrip',
  OneTrip = 'OneTrip',
  ManyTrip = 'ManyTrip'
}

export enum TemplateModeLabel {
  Disabled = 'Отключен',
  FullTrip = 'Полный рейс',
  OneTrip = 'Один рейс',
  ManyTrip = 'Много рейсов'
}

export enum TemplateModeDescription {
  Disabled = 'Этот шаблон отключен и больше не используется.',
  FullTrip = 'В этом режиме ВСЕ заявки в точках шаблона формируют ОДИН рейс, несмотря на возможные ошибки.',
  OneTrip = 'В этом режиме заявки в точках шаблона формируют только ОДИН рейс БЕЗ ОШИБОК, некоторые заявки в точках шаблона могут оказаться не развезенными и будут распланированы без шаблонов.',
  ManyTrip = 'По этом шаблону можно построить несколько рейсов БЕЗ ОШИБОК из набора машин связанных с шаблоном. Заявки шаблона, которые не удалось развести машинами шаблона будут распланированы без шаблонов'
}

export enum CondForMin {
  BothCondition,
  OneOfCondition
}

export enum CondForMinLabel {
  BothCondition = 'И',
  OneOfCondition = 'ИЛИ'
}
