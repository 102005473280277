import { EMPTY_STRING } from '@common/constants';
import { parseDateFromServerToUnix, parseDateMultiply } from '@common/utils';
import { DataForTenderCreation } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TENDER_FORM_TABLE_FIELDS_PROPERTIES, TenderFormTableField } from '@modules/tender-platform/constants/content';

export const createInitialDataForGroupTenderCreation = (dataForGroupTenderCreation: DataForTenderCreation) => {
  const data = {};
  for (let id in dataForGroupTenderCreation) {
    data[id] = {};
    for (const field in TenderFormTableField) {
      if (field !== TenderFormTableField.RouteList) {
        const value = dataForGroupTenderCreation?.[id]?.[field] ?? TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field]?.value;
        switch (field) {
          case TenderFormTableField.OfferStartDT:
          case TenderFormTableField.OfferEndDT:
          case TenderFormTableField.ExecutionStartDT:
          case TenderFormTableField.ExecutionEndDT:
            data[id][field] = parseDateFromServerToUnix(value);
            break;
          case TenderFormTableField.Schedule:
            data[id][field] = parseDateMultiply(value ?? dataForGroupTenderCreation?.[id]?.[TenderFormTableField.ExecutionStartDT]);
            break;
          case TenderFormTableField.CountShipments:
            data[id][field] = value ?? TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field]?.defaultValue;
            break;
          default:
            data[id][field] = value ?? EMPTY_STRING;
        }
      }
    }
  }

  return data;
};
