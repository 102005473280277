import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { onFailure } from '@common/utils/helpers/reducers';
import { getBalanceHistoryRoutine } from '@common/feature/payment/actions';
import { PaymentBalanceHistoryResponse, PaymentInitialState } from '@common/feature/payment/types/general';
import { paymentInitialState } from '@common/feature/payment/store';

export const getBalanceHistoryReducer = createReducer(paymentInitialState, {
  [getBalanceHistoryRoutine.REQUEST]: (state: PaymentInitialState) => {
    state.balanceHistory.isFetching = true;
    return state;
  },
  [getBalanceHistoryRoutine.SUCCESS]: (
    state: PaymentInitialState,
    action: PayloadAction<{
      data: Array<PaymentBalanceHistoryResponse>;
    }>
  ) => {
    const { data } = action.payload;
    state.balanceHistory.transactions = data;
    return state;
  },
  [getBalanceHistoryRoutine.FAILURE]: onFailure,
  [getBalanceHistoryRoutine.FULFILL]: (state: PaymentInitialState) => {
    state.error = [];
    state.balanceHistory.isFetching = false;
    return state;
  }
});

