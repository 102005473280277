import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { documentGetDependentListRoutine, resetDependentList } from '@modules/documents/actions';
import { IDependentList } from '@modules/documents/types/document-state';

export const documentsDependentList = createReducer(initialState, {
  //GetDependentList
  [documentGetDependentListRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },

  [documentGetDependentListRoutine.SUCCESS]: (
    state,
    action: {
      type: string;
      payload: IDependentList;
    }
  ) => {
    state.dependentList = action.payload;

    return state;
  },

  [documentGetDependentListRoutine.FAILURE]: (
    state,
    action: {
      type: string;
      payload: {
        error: string[];
      };
    }
  ) => {
    state.error = action.payload.error;
    return state;
  },

  [documentGetDependentListRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  },

  [resetDependentList.type]: (state) => {
    state.dependentList = null;
    return state;
  }
});
