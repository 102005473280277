import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTripTimelineRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* getTripTimelineWorker(
  action: PayloadAction<{
    tripID: number;
  }>
) {
  const { request, success, failure, fulfill } = getTripTimelineRoutine;
  const { tripID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripTimeline, { tripID });
    yield put(success({ data, tripID: tripID.toString() }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripTimelineWatcher() {
  yield takeLatest(getTripTimelineRoutine.trigger, getTripTimelineWorker);
}

