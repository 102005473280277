import { Depot, Dictionary, Notify, NotifyDurationInSecond } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { catalogBindTimeZoneRoutine } from '../actions';
import { DictionaryAPI } from '@common/api/common';
import { BIND_TIMEZONE_ERROR } from '../constants';
import { ILSUserNotify } from '@common/components';

// координаты по адресу
function* catalogBindTimeZoneWorker(
  action: PayloadAction<{
    dictionary: Dictionary.Depot;
    depots: Array<Depot>;
  }>
) {
  const { dictionary, depots } = action.payload;
  const { request, success, failure, fulfill } = catalogBindTimeZoneRoutine;

  try {
    yield put(request({ dictionary }));

    const depotsParam = depots.reduce((depotsRes: Array<{ id: string | number; latLon: { lat: number; lon: number } }>, depot) => {
      if (typeof depot.LatLon?.lat === 'number' && typeof depot.LatLon?.lon === 'number') {
        depotsRes.push({
          id: depot.ID,
          latLon: {
            lat: depot.LatLon.lat,
            lon: depot.LatLon.lon
          }
        });
      }
      return depotsRes;
    }, []);
    if (depotsParam.length) {
      const { data } = yield call(DictionaryAPI.bindTimeZone, {
        dictionary,
        depots: depotsParam
      });
      yield put(success({ dictionary, records: data.data }));
    } else {
      ILSUserNotify(Notify.Error, BIND_TIMEZONE_ERROR.EmptyParams, NotifyDurationInSecond.Two);
    }
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogBindTimeZoneWatcher() {
  yield takeLatest(catalogBindTimeZoneRoutine.trigger, catalogBindTimeZoneWorker);
}
