import { NavLink } from 'react-router-dom';
import { ILSPageHeader } from '@components/index';
import { ILSRecoveryFormComponent } from '../components/recovery-form';
import { recoveryActions, recoveryRequestActions } from '@modules/current-user/actions';
import { fetchSelector } from '../selectors/auth';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const ILSRecoveryForm: FC = () => {
  const isFetching = useSelector(fetchSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const token = queryParams.get('token');

  const onFinish = (values: any) => {
    dispatch(recoveryRequestActions(values));
  };

  useEffect(() => {
    if (token) dispatch(recoveryActions({ token }));
  }, [token]);

  return (
    <div className="recovery-form">
      <NavLink to="/login">
        <ILSPageHeader className="site-page-header" onBack={() => null} subTitle="Вернуться к авторизации" />
      </NavLink>
      <ILSRecoveryFormComponent isFetching={isFetching} onFinish={onFinish} />
    </div>
  );
};

export default ILSRecoveryForm;
