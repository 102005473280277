import { IValidationErrorTypesModel, IValidationTablesModel } from '@modules/data-validate/types/models';
import { Dictionary, Notify, TableConfig } from '@common/types';
import { ValidationKind } from '@common/types/dictionaries/validation';
import { AlertNotify } from '@common/types/general/conditions';
import { FC } from 'react';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { PLANNER_DATA_DRIVER_TABLE_CONFIG } from '@modules/planning/children/data/configs/driver';
import { depotTableConfig } from '@modules/planning/children/data/configs/depot';
import { orderTableConfig } from '@modules/planning/children/data/configs/order';
import { ILSDataViewOrderTable } from '@modules/data-validate/components/table/tables/order';
import { ILSDataViewDepotTable } from '@modules/data-validate/components/table/tables/depot';
import { ILSDataViewDriverTable } from '@modules/data-validate/components/table/tables/driver';
import { ILSDataViewVehicleTable } from '@modules/data-validate/components/table/tables/vehicle';
import { VEHICLE_VALIDATION_TABLE_CONFIG } from '@common/models/vehicle/constants';

export const validationErrorTypes: IValidationErrorTypesModel = {
  Err: {
    type: Notify.Error,
    text: 'Ошибки',
    textForCount: 'Ошибок'
  },
  Bad: {
    type: Notify.Bad,
    text: 'Критические ошибки',
    textForCount: 'Критических ошибок'
  },
  Wrn: {
    type: Notify.Warning,
    text: 'Предупреждения',
    textForCount: 'Предупреждений'
  },
  Inf: {
    type: Notify.Info,
    text: 'Инфо',
    textForCount: 'Инфо'
  }
};

export const VALIDATION_CONTENT = {
  [Notify.Error]: 'Ошибок',
  [Notify.Bad]: 'Критических ошибок',
  [Notify.Warning]: 'Предупреждений',
  [Notify.Info]: 'Инфо'
};

export const VALIDATION_TABLES: IValidationTablesModel = {
  [Dictionary.Vehicle]: {
    text: 'Автомобили',
    reference: 1
  },
  [Dictionary.Driver]: {
    text: 'Водители',
    reference: 1
  },
  [Dictionary.Depot]: {
    text: 'Точки',
    reference: 1
  },
  [Dictionary.Order]: {
    text: 'Заявки',
    reference: 1
  },
  Common: { text: 'Общее' },
  ServicesAvailable: { text: 'Доступность сервиса' },
  Distances: { text: 'Матрица расстояний' }
};

export const ERRORS = [ValidationKind[Notify.Error], ValidationKind[Notify.Bad]] as unknown as Array<AlertNotify>;
export const WARNINGS = [ValidationKind[Notify.Warning]] as unknown as Array<AlertNotify>;
export const INFO = [ValidationKind[Notify.Info]] as unknown as Array<AlertNotify>;
export const VALIDATION_KINDS: Array<AlertNotify> = [Notify.Error, Notify.Info, Notify.Warning, Notify.Bad];

export const DATA_VALIDATION_TABLES: Partial<
  Record<Dictionary, { title: string; component: FC<IDataTableProps>; reference: number; config: TableConfig; dictionary: Dictionary }>
> = {
  [Dictionary.Vehicle]: {
    title: 'Автомобили',
    reference: 1,
    component: ILSDataViewVehicleTable,
    config: VEHICLE_VALIDATION_TABLE_CONFIG,
    dictionary: Dictionary.Vehicle
  },
  [Dictionary.Driver]: {
    title: 'Водители',
    reference: 1,
    component: ILSDataViewDriverTable,
    config: PLANNER_DATA_DRIVER_TABLE_CONFIG,
    dictionary: Dictionary.Driver
  },
  [Dictionary.Depot]: {
    title: 'Точки',
    reference: 1,
    component: ILSDataViewDepotTable,
    config: depotTableConfig,
    dictionary: Dictionary.Depot
  },
  [Dictionary.Order]: {
    title: 'Заявки',
    reference: 1,
    component: ILSDataViewOrderTable,
    config: orderTableConfig,
    dictionary: Dictionary.Order
  }
};
