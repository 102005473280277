import { FUEL_POINT_TYPES_DRAIN } from '@common/table-configs/monitoring-dashboard/on-fuel-drain/decorators';
import { DateFormat, DateTimeFormat } from '@common/types';
import { MonitoringSensorEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToDatetimeString } from '@common/utils/helpers/date-time/date-time';
import { GraphDecorator } from '@modules/monitoring/children/dashboard/types/graph-decorator';
import { GRAPH_OPEN_DOOR_SIZE } from '@modules/monitoring/components/graph/constants/graph-table';
import { dataTypeVariants, MonitoringGraphDataItem, MonitoringGraphFuelDrainData } from '@modules/monitoring/types/graph';

const GRAPH_TIME_FORMAT = DateTimeFormat.YYYYMMDDDashHHMMSS;
const toGraphTime = (TS: number) => timestampToDatetimeString(TS, true, GRAPH_TIME_FORMAT);

// Декоратор для превращения данных рейсов в данные для biz-charts;
// возвращает массив точек;
// biz-charts объединит точки в линию по атрибуту color, здесь dataType;
//TODO рефакторинг
export const monitoringGraphDecorator: GraphDecorator = (dataSource, showDate, selectedVehicleID, vehicleTracks, listOfGraph) => {
  const toShowDateCheck = (TS: number) => timestampToDatetimeString(TS, true, DateFormat.YYYYMMDDDash) === showDate;

  const res: (Omit<MonitoringGraphDataItem, 'x'> & { x: number })[] = [];

  if (!dataSource || !Object.values(dataSource).length || !vehicleTracks || !Object.values(vehicleTracks).length) {
    return [] as MonitoringGraphFuelDrainData;
  }

  for (const trackerID in vehicleTracks.data) {
    const currentTrack = vehicleTracks.data[trackerID]?.Tracker?.Track;

    for (const waypointID in currentTrack) {
      const waypoint = currentTrack[waypointID];

      if (waypoint.Speed && waypoint.DT && toShowDateCheck(waypoint.DT) && listOfGraph.includes(dataTypeVariants.Speed)) {
        res.push({
          x: waypoint.DT,
          y: +waypoint.Speed.toFixed(2),
          dataType: `${dataTypeVariants.Speed}`
        });
      }
    }
  }

  for (const vehicleID in dataSource) {
    if (!!selectedVehicleID && vehicleID !== selectedVehicleID.toString()) {
      continue;
    }

    const vehicle = dataSource?.[vehicleID];
    if (!vehicle?.VehicleTracker) continue;

    for (const vehicleTrackerID in vehicle.VehicleTracker) {
      const vehicleTracker = vehicle.VehicleTracker?.[vehicleTrackerID];

      for (const fuelKeyPointID in vehicleTracker.FuelKeyPoints) {
        const fuelKeyPoint = vehicleTracker?.FuelKeyPoints[fuelKeyPointID];

        if (fuelKeyPoint.fuelafter && fuelKeyPoint.DT && toShowDateCheck(fuelKeyPoint.DT)) {
          const sharpDrain = FUEL_POINT_TYPES_DRAIN.includes(fuelKeyPoint.pointtype);
          const dataType = `${dataTypeVariants.Fuel}`;

          if (fuelKeyPoint.fuelbefore && sharpDrain && listOfGraph.includes(dataTypeVariants.Fuel)) {
            res.push({
              x: fuelKeyPoint.DT,
              y: fuelKeyPoint.fuelbefore,
              dataType
            });
          }
          res.push({
            x: fuelKeyPoint.DT,
            y: fuelKeyPoint.fuelafter,
            dataType
          });
        }
      }
      if (listOfGraph) {
        for (const sensorMappingID in vehicleTracker?.SensorMapping) {
          if (
            sensorMappingID === MonitoringSensorEventStatusType.SensorTemp1 ||
            sensorMappingID === MonitoringSensorEventStatusType.SensorTemp2
          ) {
            const sensorMapping = vehicleTracker?.SensorMapping[sensorMappingID];
            for (const sensorValueID in sensorMapping?.SensorValue) {
              const sensorValue = sensorMapping.SensorValue?.[sensorValueID];
              if (listOfGraph.includes(dataTypeVariants.Temperature1) || listOfGraph.includes(dataTypeVariants.Temperature2)) {
                res.push({
                  x: sensorValue.DT,
                  y: sensorValue.Value,
                  dataType:
                    sensorMappingID === MonitoringSensorEventStatusType.SensorTemp1
                      ? `${dataTypeVariants.Temperature1}`
                      : `${dataTypeVariants.Temperature2}`
                });
              }
            }
          }
        }
        for (const sensorMappingID in vehicleTracker?.SensorMapping) {
          if (sensorMappingID === MonitoringSensorEventStatusType.SensorOpenDoor && listOfGraph.includes(dataTypeVariants.OpenDoor)) {
            const sensorMapping = vehicleTracker?.SensorMapping[sensorMappingID];
            let pastSensorValue;
            for (const sensorValueID in sensorMapping?.SensorValue) {
              const sensorValue = sensorMapping.SensorValue?.[sensorValueID];
              if (pastSensorValue) {
                res.push({
                  x: sensorValue.DT,
                  y: pastSensorValue.Value === 1 ? GRAPH_OPEN_DOOR_SIZE : pastSensorValue.Value,
                  dataType: `${dataTypeVariants.OpenDoor}`
                });
              }
              res.push({
                x: sensorValue.DT,
                y: sensorValue.Value === 1 ? GRAPH_OPEN_DOOR_SIZE : sensorValue.Value,
                dataType: `${dataTypeVariants.OpenDoor}`
              });
              pastSensorValue = sensorValue;
            }
          }
        }
      }
    }
  }

  return res
    .sort((a, b) => a.x - b.x)
    .map((item) => {
      return { ...item, x: toGraphTime(item.x) } as MonitoringGraphDataItem;
    }) as MonitoringGraphFuelDrainData;
};
