import { Depot, IMapTrack, IndexedArray, MarkerPlanning, MarkerType, Order, ProjectState, Worktime } from '@common/types';
import { assign, isEmpty, isNil, omit } from 'lodash';
import { createArrayFromIndexedArray, doubleToTime, getLatLngExpression } from '@common/utils';
import { DEFAULT_ORDER_COLOR, EMPTY_STRING } from '@common/constants';
import { int2color } from '@common/decorators/color';
import { PlanningSettingsKeys, UnresolvedMarkerSourceValue } from '@modules/settings/types';
import { PlanningParameters } from '@modules/settings/types/store';
import { LatLngExpression } from 'leaflet';

type MapOrdersParameters = Pick<PlanningParameters, PlanningSettingsKeys.UnresolvedMarkerSource>;

export const MapOrders = (
  order: Order[],
  depots: ProjectState['Depot'],
  projectWorktime: ProjectState['Worktime'],
  { unresolvedMarkerSource = UnresolvedMarkerSourceValue.Target }: MapOrdersParameters
) => {
  const orderMarkers: MarkerPlanning[] = [];
  const line: Pick<IMapTrack, 'ID' | 'weight' | 'coords' | 'color'>[] = [];
  const depotIDsFromOrder = new Set<Depot['ID']>();

  order.forEach((o) => {
    if (!depots) return;
    let source: Depot, target: Depot;

    source = depots[o.SourceID ?? EMPTY_STRING] ?? 5;
    target = depots[o.TargetID ?? EMPTY_STRING] ?? null;
    const color = getOrderColor({ unresolvedMarkerSource, source, target });
    if (o.SourceID) {
      depotIDsFromOrder.add(o.SourceID);
    }
    if (o.TargetID) {
      depotIDsFromOrder.add(o.TargetID);
    }
    const targetWorktime: Worktime | null = projectWorktime && target?.WorktimeID ? projectWorktime[target.WorktimeID] : null;
    const orderWorktime: Worktime | null = projectWorktime && o?.WorktimeID ? projectWorktime[o.WorktimeID] : null;
    if (source && target) {
      const data = {
        ID: o.ID,
        Restriction: o.Restriction,
        Mass: o.Mass,
        Pallets: o.Pallets,
        Boxes: o.Boxes,
        Volume: o.Volume,
        Cost: o.Cost,
        OutOfTrip: true,
        type: MarkerType.Order
      };
      const coords = unresolvedMarkerSource === UnresolvedMarkerSourceValue.Target ? target.LatLon : source.LatLon;

      orderMarkers.push({
        coords: getLatLngExpression(coords ?? []),
        type: 'order',
        color,
        clickData: {
          ID: o.ID
        },
        data,
        pointData: {
          Title: o.Comment,
          OrderCode: o.OrderCode,
          SourceAddress: source.AddressDescription,
          TargetAddress: target.AddressDescription,
          SourceClient: source.Name,
          TargetClient: target.Name,
          Mass: o.Mass?.toFixed(2),
          Pallets: o.Pallets?.toFixed(2),
          CargoCount: o.Amount,
          UnloadDate: o.UnloadDate?.toString() ?? '',

          LoadStart: doubleToTime(orderWorktime?.LoadStart ?? 0),
          LoadEnd: doubleToTime(orderWorktime?.LoadEnd ?? 0),
          UnloadStart: doubleToTime(orderWorktime?.UnloadStart ?? 0),
          UnloadEnd: doubleToTime(orderWorktime?.UnloadEnd ?? 0),

          TargetLoadStart: doubleToTime(targetWorktime?.LoadStart ?? 0),
          TargetLoadEnd: doubleToTime(targetWorktime?.LoadEnd ?? 0),
          TargetUnloadStart: doubleToTime(targetWorktime?.UnloadStart ?? 0),
          TargetUnloadEnd: doubleToTime(targetWorktime?.UnloadEnd ?? 0)
        }
      });

      if (source.LatLon && target.LatLon) {
        line.push({
          coords: [Object.values(source.LatLon), Object.values(target.LatLon)] as LatLngExpression[],
          color: color,
          weight: 2,
          ID: o.ID ?? null
        });
      }
    }
  });
  return { orderMarkers, line, depotIDsFromOrder };
};

export const MapOrdersWithSettings = (orderMarkers: MarkerPlanning[], settings?: MapOrdersParameters) => {
  if (orderMarkers && orderMarkers.length) {
    const markers: MarkerPlanning[] = orderMarkers.map((o) => {
      const order = { ...o };
      if (o?.data) {
        if (o.data.SourceCoords && o.data.TargetCoords) {
          const coords = settings?.unresolvedMarkerSource === 'target' ? o.data.TargetCoords : o.data.SourceCoords;
          if (coords) {
            assign(order, { coords: getLatLngExpression(coords) });
          }
        }
      }
      return order;
    });
    return { notResolvedMarker: markers };
  }
  return { notResolvedMarker: [] as MarkerPlanning[] };
};

type GetOrderColorFunc = (arg: { unresolvedMarkerSource?: UnresolvedMarkerSourceValue; target: Depot; source: Depot }) => string;

export const getOrderColor: GetOrderColorFunc = ({ unresolvedMarkerSource, target, source }) => {
  if (unresolvedMarkerSource === UnresolvedMarkerSourceValue.Target) {
    if (!isNil(target?.Color)) {
      return int2color(target.Color);
    }
  } else {
    if (!isNil(source?.Color)) {
      return int2color(source.Color);
    }
  }
  return DEFAULT_ORDER_COLOR;
};

export type OrderDepot = {
  LatLon: {} | null;
  pointData: {};
};

export const orderMap = (orders: IndexedArray<Order> | Array<Order>, resolvedOrderIDs: Set<number | string>) => {
  if (!isEmpty(orders)) {
    const unresolvedOrder = omit(orders, ...Array.from(resolvedOrderIDs)) as IndexedArray<Order>;
    const unresolvedOrderIDs = Object.keys(unresolvedOrder).map(Number);
    return {
      unresolvedOrderIDs,
      unresolvedOrder: createArrayFromIndexedArray(unresolvedOrder)
    };
  }
  return {
    unresolvedOrder: [] as Order[],
    unresolvedOrderIDs: [] as Array<Order['ID']>
  };
};
