import { planningChangeStatusRoutine } from '@modules/planning/children/by-date/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningStatus } from '@modules/planning/types';

function* planningChangeStatusWorker(
  action: PayloadAction<{
    status: PlanningStatus;
  }>
) {
  const { success, failure } = planningChangeStatusRoutine;
  const { status } = action.payload;
  try {
    yield put(success({ status }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
  }
}

export function* planningChangeStatusWatcher() {
  yield takeLatest(planningChangeStatusRoutine.trigger, planningChangeStatusWorker);
}
