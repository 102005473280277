import { ILSPlanningResultTableConfirmButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/button-confirm';
import { FC } from 'react';
import { FileExcelOutlined } from '@ant-design/icons/lib/icons';
import { useSelector } from 'react-redux';
import { activeIsFetchingSelector, activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { projectExceededMinimumSelector } from '@modules/planning/children/by-date/selectors/project';
import { isActivePlanPlanningSelector } from '@modules/planning/children/by-date/selectors/planning';

interface IProps {
  inactive?: boolean;
  onePlanRemains?: boolean;

  handleDelete(): void;
}

export const PlannerPlanningDeletePlanButton: FC<IProps> = ({ handleDelete }) => {
  const projectExceededMinimum = useSelector(projectExceededMinimumSelector);
  const confirmText = 'Удалить выбранный план перевозок?';
  const activePlanID = useSelector(activePlanIDSelector);
  const isFetching = useSelector(activeIsFetchingSelector);
  const isPlanning = useSelector(isActivePlanPlanningSelector);
  const tooltipText = projectExceededMinimum ? 'В проекте должен быть хотя бы один план' : confirmText;

  const disabled = Boolean(!activePlanID || projectExceededMinimum || isFetching || isPlanning);

  const onDelete = () => {
    !disabled && handleDelete();
  };

  return (
    <ILSPlanningResultTableConfirmButton
      buttonTooltip={tooltipText}
      confirmText={confirmText}
      icon={<FileExcelOutlined className={'ils-svg error'} />}
      onConfirm={onDelete}
      disabled={disabled}
    />
  );
};
