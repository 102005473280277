import { createSelector } from 'reselect';
import { forwarderPointStatuses2table } from '@modules/settings/utils/helpers/decorators/forwarder-point-statuses-to-table';
import { DictionariesEndpoint, Dictionary, IILSTableProps, Keys, Notify } from '@common/types';
import { forwarderPointStatusesIconsURLOptionsSelector, forwarderPointStatusesSelector } from '@modules/settings/selectors';
import { Dispatch } from 'redux';
import { mobileApplicationDictionaryOperationRoutine } from '@modules/settings/actions';
import { ILSUserNotify } from '@common/components';
import { ForwarderPointStatuses } from '@modules/settings/types';
import { forwarderPointStatusesFocusRowSelector } from '@modules/settings/selectors/mobile-application';

export const settingsForwarderPointStatusesTableDataSourceSelector = createSelector(
  forwarderPointStatusesSelector,
  (forwarderPointStatuses) => forwarderPointStatuses2table(forwarderPointStatuses)
);

export const settingsForwarderPointStatusesTableSelector = (dispatch: Dispatch) =>
  createSelector(
    settingsForwarderPointStatusesTableDataSourceSelector,
    forwarderPointStatusesIconsURLOptionsSelector,
    forwarderPointStatusesFocusRowSelector,
    (dataSource, refData, focusRowID) => {
      const mobileApplicationDictionary = Dictionary.ForwarderPointStatuses;
      const handleSave: IILSTableProps<any>['handleSave'] = (record, dataIndex, value) => {
        // 3. Указанные на рисунке строки являются системными - их нельзя редактировать и удалять
        if (Boolean(record.IsMaster)) {
          ILSUserNotify(Notify.Error, 'Системные статусы нельзя изменить');
        } else {
          dispatch(
            mobileApplicationDictionaryOperationRoutine({
              mobileApplicationDictionary,
              ids: [record?.ID],
              operation: DictionariesEndpoint.Update,
              values: { [dataIndex]: value }
            })
          );
        }
      };

      const handleCreate = (values: object) => {
        dispatch(
          mobileApplicationDictionaryOperationRoutine({
            mobileApplicationDictionary,
            operation: DictionariesEndpoint.Create,
            values
          })
        );
      };

      const handleDelete = (ids: Keys | null) => {
        dispatch(
          mobileApplicationDictionaryOperationRoutine({
            mobileApplicationDictionary,
            ids,
            operation: DictionariesEndpoint.Delete
          })
        );
      };

      const onCanEdit = (record: ForwarderPointStatuses) => !record.IsMaster;

      return {
        dataSource,
        handleSave,
        handleCreate,
        handleDelete,
        onCanEdit,
        refData,
        focusRow: focusRowID ? { key: focusRowID } : null
      };
    }
  );

