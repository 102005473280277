import { IILSCellAlertProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellAlert = <R extends TableRecord>({ children, record, dataIndex, activeCell }: IILSCellAlertProps<R>): JSX.Element => {
  const [node, value] = children;

  const isActive = record?.dictionary + dataIndex === activeCell;

  const handleClick = () => {
    record?.handleClick?.(record, dataIndex);
  };

  return (
    <div className={isActive ? 'error-container error-container_active' : 'error-container'} onClick={handleClick}>
      {node}
      <div className={'error-square error-square-' + dataIndex} />
      <div>{value}</div>
    </div>
  );
};
