import { createSelector } from 'reselect';
import { PlanningByDateState } from '../types/store';
import { Depot, Dictionary, IndexedArray, PlanStatus, ReferenceTableData, Vehicle, PlanID, PlanTotal } from '@common/types';
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from '@common/constants';
import { isEmpty } from 'lodash';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { MAX_PLAN_COUNT } from '@common/constants/custom/statuses';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';
import { ProjectCatalogState } from '@common/types/dictionaries/project';
import { getTransportCompaniesOptions } from '@modules/planning/children/by-date/helpers/selectors/get-transport-companies-options';

export const projectSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.project,
  (project) => project
);

export const projectPlansSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.project?.Plan,
  (plans) => plans ?? {}
);

export type PlanNamesEnum = Record<PlanID, PlanTotal['FullName']>;

export const projectPlansNamesSelector = createSelector(projectPlansSelector, (plans) => {
  const planEnums: PlanNamesEnum = {};
  for (const id in plans) {
    planEnums[id] = plans[id].PlanTotal.FullName;
  }
  return planEnums;
});

export const projectNameSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.project?.Name,
  (Name) => Name || EMPTY_STRING
);

export const planningByDateInfoSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.info,
  (info) => info
);

export const projectInfoSelector = createSelector(projectSelector, (project) => project?.Info);

export const projectInfoByDictionarySelector = createSelector(
  [(state: PlanningByDateState, dictionary: Dictionary) => projectInfoSelector(state)?.[dictionary]],
  (info) => info
);

export const isInitialSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.initial,
  (initial) => initial
);

export const projectPlansOptionsSelector = createSelector(projectPlansSelector, (plans) => Object.values(plans));
export const projectPlanIDsSelector = createSelector(projectPlansSelector, (plans) => Object.keys(plans));

export const projectPlanOnExecutionSelector = createSelector(projectPlansSelector, (plans) =>
  Object.values(plans).find(({ PlanTotal }) => compareAsString(PlanTotal.Status, PlanStatus.OnExecution))
);

export const plansIDsSelector = createSelector(projectPlansSelector, (plans) => Object.keys(plans));
export const plansCounterSelector = createSelector(plansIDsSelector, (plansIDs) => plansIDs.length);
export const projectExceededLimitSelector = createSelector(plansCounterSelector, (plansCounter) => plansCounter >= MAX_PLAN_COUNT);
export const projectExceededMinimumSelector = createSelector(plansCounterSelector, (plansCounter) => plansCounter <= 1);

export const projectLastUpdateSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.info.project.lastUpdate,
  (lastUpdate) => lastUpdate
);

export const projectStartDateSelector = createSelector(projectSelector, (project) => project?.Start);

export const projectIDSelector = createSelector(projectSelector, (project) => project?.ID);

export const projectCrossDockSelector = createSelector(projectSelector, (project) => project?.CrossDock);

export const projectDepotSelector = createSelector(projectSelector, (project) => project?.Depot ?? ({} as IndexedArray<Depot>));
export const projectDepotIDSSelector = (ids: Array<Depot['ID']>) =>
  createSelector(projectDepotSelector, (Depot) => {
    if (isEmpty(Depot)) return EMPTY_ARRAY_DATA;
    return ids.reduce((depots, id) => {
      depots.push(Depot[id]);
      return depots;
    }, [] as Array<Depot>);
  });

export const projectForbiddenZoneSelector = createSelector(projectSelector, (project) => project?.ForbiddenZone ?? {});

export const projectZoneSelector = createSelector(projectSelector, (project) => project?.Zone ?? {});

export const projectOrderSelector = createSelector(projectSelector, (project) => project?.Order);

export const projectVehicleOverloadSelector = createSelector(projectSelector, (project) => project?.VehicleOverload);

export const projectVehicleProfileSelector = createSelector(projectSelector, (project) => project?.VehicleProfile);

export const projectVehicleTimelineSelector = createSelector(projectSelector, (project) => project?.VehicleTimeline);

export const projectWorktimeSelector = createSelector(projectSelector, (project) => project?.Worktime ?? {});

export const projectDepotOverloadSelector = createSelector(projectSelector, (project) => project?.DepotOverload ?? {});

export const projectProjectVehicleSelector = createSelector(projectSelector, (project) => project?.ProjectVehicle);

export const vehiclesSelector = createSelector(projectSelector, (project) => {
  return project?.Vehicle ?? {};
});

export const projectDriverSelector = createSelector(projectSelector, (project) => project?.Driver ?? {});

export const projectCargoSelector = createSelector(projectSelector, (project) => project?.Cargo ?? {});

export const projectRampSelector = createSelector(projectSelector, (project) => project?.Ramp ?? {});

export const vehiclesTransportCompanyOptionsSelector = createSelector(vehiclesSelector, (vehicles: ProjectCatalogState<Vehicle>) => {
  return getTransportCompaniesOptions(vehicles);
});

export const projectRampTypeSelector = createSelector(projectSelector, (project) => project?.RampType ?? {});

export const projectCatalogDictionarySelector = createSelector(
  projectSelector,
  (state: PlanningByDateState, dictionary: Dictionary) => dictionary,
  (project, dictionary) => createArrayFromIndexedArray(project?.[dictionary])
);

export const projectDictionaryInfoSelector = (dictionary: Dictionary) =>
  createSelector(planningByDateInfoSelector, (Info) => Info?.[dictionary]);

export const projectCommonDictionarySelector = createSelector(
  projectSelector,
  (_: PlanningByDateState, dictionaries: Dictionary[]) => dictionaries,
  (project, dictionaries) => {
    return dictionaries.reduce((prev: Record<Dictionary, ReferenceTableData>, dictionary: Dictionary) => {
      prev[dictionary] = {
        options: project?.[dictionary],
        dictionary,
        fieldNames: DEFAULT_FIELD_NAMES
      };
      return prev;
    }, {} as Record<Dictionary, ReferenceTableData>);
  }
);

export const projectOrderCargoOldSelector = createSelector(
  projectSelector,
  (_: PlanningByDateState, orderID: number | string) => orderID,
  (project, orderID) => project?.['Order']?.[orderID]?.['OrderCargo']
);

export const projectDataSelector = createSelector(
  projectCrossDockSelector,
  projectDepotSelector,
  projectForbiddenZoneSelector,
  projectZoneSelector,
  projectOrderSelector,
  vehiclesSelector,
  projectVehicleOverloadSelector,
  projectVehicleProfileSelector,
  projectVehicleTimelineSelector,
  projectWorktimeSelector,
  (CrossDock, Depot, ForbiddenZone, Zone, Order, Vehicle, VehicleOverload, VehicleProfile, VehicleTimeline, Worktime) => ({
    projectData: {
      CrossDock,
      Depot,
      ForbiddenZone,
      Zone,
      Order,
      Vehicle,
      VehicleOverload,
      VehicleProfile,
      VehicleTimeline,
      Worktime
    }
  })
);

export const catalogTotalCountSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.project?.catalogTotalCount,
  (catalogTotalCount) => catalogTotalCount
);

