import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderOfferSetStatusRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint, Dictionary } from '@common/types';

function* tenderOfferSetStatusWorker(
  action: PayloadAction<{
    tenderOfferID: number | string;
    statusID: number | string;
  }>
) {
  const { request, failure, fulfill } = tenderOfferSetStatusRoutine;
  const { tenderOfferID, statusID } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderSearch.setStatus, { tenderOfferID, statusID });
    const [TenderOfferID] = Object.keys(data);
    const [StatusConfig] = Object.values(data);
    yield put(
      tenderChangeRoutine.trigger({
        StatusConfig,
        TenderOfferID,
        operation: DictionariesEndpoint.SetStatus,
        dictionary: Dictionary.TenderOffer
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferSetStatusWatcher() {
  yield takeEvery(tenderOfferSetStatusRoutine.trigger, tenderOfferSetStatusWorker);
}
