import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { call, takeEvery } from 'redux-saga/effects';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { downloadDocumentFromTemplate } from '@modules/documents/helpers/download-document-from-template';
import { downloadDocument } from '@modules/documents/children/templates/actions';

function* downloadDocumentWorker(action: PayloadAction<number>) {
  try {
    const { data } = yield call(DocumentTemplatesAPI.getTemplate, undefined, action.payload);

    downloadDocumentFromTemplate(data.data);
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* downloadDocumentWatcher() {
  yield takeEvery(downloadDocument, downloadDocumentWorker);
}
