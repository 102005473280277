import { FC, ReactNode, useState } from 'react';
import { PlannerPlanningMenuButton } from '../../table-head/action-buttons/btn';
import { PlannerPlanningMapMenu } from './menu';
import { Key } from '@common/types';

export const PlannerPlanningMapMenuButton: FC<{
  content?: string | null;
  activeItems?: (string | number)[] | null;
  tooltipText?: string | null;
  icon: ReactNode | string;
  menu:
    | {
        ID: Key;
        Name: string | null;
      }[]
    | null;
  onChange: (value: boolean | null, item?: { ID: Key } | null, clear?: boolean | null) => void;
  emptyText?: string;
}> = ({ content, activeItems, tooltipText, icon, menu, onChange, emptyText }) => {
  const [visible, handleShow] = useState<boolean>(false);

  if (!visible) {
    return (
      <PlannerPlanningMenuButton
        table={false}
        show={visible}
        content={content}
        setShow={handleShow}
        active={activeItems}
        tooltipText={tooltipText}
        icon={icon}
      />
    );
  }

  return <PlannerPlanningMapMenu emptyText={emptyText} setShow={handleShow} menu={menu} active={activeItems} onChange={onChange} />;
};
