import { ILSPlanningMatrixComponent } from '@modules/planning/children/by-date/components/result/matrix/matrix';
import { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MatrixAction } from '@modules/planning/children/by-date/types';
import {
  cleanupDistanceMatrixRoutine,
  deleteDistanceMatrixRoutine,
  startDistanceMatrixRoutine
} from '@modules/planning/children/by-date/actions';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import ILSMatrixStatusPolling from '@modules/planning/children/by-date/components/result/matrix/matrix-status-polling';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { matrixIsLoadingSelector, matrixStatusSelector } from '@modules/planning/children/by-date/selectors/plan';

export const ILSPlanningMatrixContainer: FC = memo(() => {
  const dispatch = useDispatch();
  const planID = useSelector(activePlanIDSelector);
  const projectID = useSelector(activeProjectIdSelector);
  const matrix = useSelector(matrixStatusSelector);
  const isLoading = useSelector(matrixIsLoadingSelector);

  const [calculating, setCalculating] = useState(false);

  useEffect(() => {
    if (matrix?.LastSize && calculating) setCalculating(false);
  }, [matrix, calculating]);

  const handleClick = (action: MatrixAction) => {
    switch (action) {
      case MatrixAction.Cleanup:
        projectID && dispatch(cleanupDistanceMatrixRoutine({ projectID }));
        break;
      case MatrixAction.Start:
        dispatch(startDistanceMatrixRoutine({ projectID, planID }));
        setCalculating(true);
        break;
      case MatrixAction.Delete:
        dispatch(deleteDistanceMatrixRoutine({ projectID, planID }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ILSPlanningMatrixComponent handleClick={handleClick} matrix={matrix} isLoading={isLoading} />
      {calculating && <ILSMatrixStatusPolling projectID={projectID} />}
    </>
  );
});
