import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteTripRoutine, getTotalCountRoutine, switchTripStatusRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { DeleteTripFields } from '../types/form';

function* deleteTripWorker(
  action: PayloadAction<
    {
      tripID: number;
      status: TripStatus;
    } & DeleteTripFields
  >
) {
  const { request, success, failure, fulfill } = deleteTripRoutine;
  const { tripID, status, deleteWithOrders } = action.payload ?? {};
  try {
    yield put(request());
    yield call(OrdersNTripsApi.deleteTrip, { tripID, deleteWithOrders });
    yield put(success());
    yield put(switchTripStatusRoutine.trigger({ status }));
    yield put(getTotalCountRoutine.trigger());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteTripWatcher() {
  yield takeLatest(deleteTripRoutine.trigger, deleteTripWorker);
}

