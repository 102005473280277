import { ILSButtonTooltip, ILSTooltip, ILSTypographyText } from '@components/index';
import '../styles.less';
import { CurrenUser } from '../types/components';
import { FC } from 'react';
import { EMPTY_CELL } from '@common/constants/general';
import { ILSSVG } from '@components/custom/icons/svg';
import { LogoutOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { ModulePath } from '@common/types';
import { PaymentContainer } from '@common/feature/payment/containers/payment-container';
import { ShowQuestionsButton } from '@common/feature/questions/components/buttons/show-questions-button';

export const CurrentUserComponent: FC<CurrenUser> = ({ user: { Avatar, Name = EMPTY_CELL }, logOut }) => {
  const { pathname } = useLocation();
  return (
    <div className={'current-user'}>
      <>
        {Avatar ? (
          <img src={Avatar} alt={Name} className={'current-user-avatar'} />
        ) : (
          <ILSSVG className={'current-user-avatar-empty'} icon={'user'} />
        )}
      </>
      <ILSTooltip placement="top" title={Name}>
        <ILSTypographyText className="current-user-name">{Name}</ILSTypographyText>
      </ILSTooltip>
      <ILSButtonTooltip
        placement="bottomLeft"
        className="current-user-logout"
        size={'small'}
        icon={<LogoutOutlined size={20} />}
        onClick={logOut}
        title={'Выйти'}
      />
      {pathname === ModulePath.OrdersNTrips && (
        <div className="current-user-orders-and-trips">
          <ShowQuestionsButton />
          <PaymentContainer />
        </div>
      )}
    </div>
  );
};
