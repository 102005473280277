import { createSelector } from 'reselect';
import { AnalyticsTenderMainStore } from '../types/store';

export { boardInfoSelector, boardSetsSelector, boardsSelector } from './board';

export const widgetParamsSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.WidgetParams
);
export const widgetsSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.Widgets
);
