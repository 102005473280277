import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { AnalyticsStore } from '../types/store';
import { WidgetCommonParam } from '../constants';
import { AnalyticsActions } from '../types/actions';

const analyticsBoardDateRangeReducer = (actions: AnalyticsActions) =>
  createReducer(initialState, {
    [actions.analyticsCommonSetBoardDateRoutine.REQUEST]: (state: AnalyticsStore) => {
      return state;
    },
    [actions.analyticsCommonSetBoardDateRoutine.SUCCESS]: (
      state: AnalyticsStore,
      action: PayloadAction<{
        date: [string, string];
      }>
    ) => {
      const { date } = action.payload;
      state.WidgetParams = {
        From: { name: WidgetCommonParam.From, value: date[0] },
        Till: { name: WidgetCommonParam.Till, value: date[1] }
      };

      return state;
    },
    [actions.analyticsCommonSetBoardDateRoutine.FAILURE]: (state: AnalyticsStore) => {
      return state;
    },
    [actions.analyticsCommonSetBoardDateRoutine.FULFILL]: (state: AnalyticsStore) => {
      return state;
    }
  });

export default analyticsBoardDateRangeReducer;
