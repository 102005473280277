import { TRIP_STATUSES } from '@common/constants/custom';

export const ACCESSED_STATUS = ['101', '200', '204'];

export const SELECTED_TRIP_STATUS = Object.keys(TRIP_STATUSES).reduce((statuses: { status: number; label: string }[], status) => {
  if (ACCESSED_STATUS.includes(status)) {
    statuses.push({ status: Number(status), label: TRIP_STATUSES[status] });
  }
  return statuses;
}, []);
