import { FC } from 'react';
import { useModal } from '@common/hooks/components/use-modal';
import { PlannerExportTypes } from '@modules/planning/children/by-date/constants/export';
import { useSelector } from 'react-redux';
import { currentSchemeKeysSelector } from '@modules/planning/children/scheme-editor/selectors';
import { ExportDataContainer } from '@modules/planning/children/by-date/containers/result/export-data-container';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { ButtonExportExcel } from '@components/general/buttons/components/button-export-excel';

export const PlannerPlanningExportExcelDataContainer: FC = () => {
  const currentSchemeKeys = useSelector(currentSchemeKeysSelector);
  const activePlanID = useSelector(activePlanIDSelector);

  const existExportData = !!currentSchemeKeys.find((exportSchemeKey) => PlannerExportTypes[exportSchemeKey]);

  const { visible, handleShow, handleOpen } = useModal();

  const title = !existExportData ? 'Нет схем для выгрузки данных' : undefined;

  return (
    <>
      <ButtonExportExcel title={title} disabled={!existExportData} onClick={handleOpen} />
      <ExportDataContainer
        activePlanID={activePlanID}
        currentSchemeKeys={currentSchemeKeys}
        handleExportData={handleShow}
        showExportDataContainer={visible}
      />
    </>
  );
};
