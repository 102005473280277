import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { deleteBoardSetRoutine } from '../actions';
import initialState from '../store';

export const deleteBoardSetsReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [deleteBoardSetRoutine.REQUEST]: (state, action: PayloadAction<{ id: number | string }>) => {
    state.BoardSets.updatingSet = action.payload.id;
    state.BoardSets.deleting = true;
    return state;
  },
  [deleteBoardSetRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      id: string | number;
    }>
  ) => {
    const { id } = action.payload;

    if (id && state.BoardSets?.data) {
      const newSets = Object.values(state.BoardSets?.data).filter((item) => item.ID !== id);

      state.BoardSets.data = newSets.reduce((prev, cur) => {
        prev[cur.ID] = cur;

        return prev;
      }, {});
    }

    return state;
  },
  [deleteBoardSetRoutine.FAILURE]: (state) => {
    return state;
  },
  [deleteBoardSetRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.Boards.updatingBoard = undefined;
    state.BoardSets.deleting = false;
    return state;
  }
});
