import { EMPTY_STRING } from '@common/constants';
import { STATUS_VARIANTS_PROJECT } from '@common/models/project';
import { CellType, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { ILSPlanningStatusTooltip } from '../components/result/status-tooltip';
import { PlacementType } from '@common/types/components/table/cell';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export const titleTooltipFullName =
  'Маршрут: набор рейсов одной машины, выполняющей заявки на доставку или перевозку грузов. В скобках (к-во рейсов / к-во клиентов)';

export const ROUTES_TABLE_CONFIG: TableConfig = {
  name: 'План',
  reloading: false,
  deleting: true,
  selecting: true,
  copying: false,
  hideClearButtons: true,
  mouseLeaveDelay: 0,
  configClassName: 'plan-table-current-plan',
  columns: [
    {
      title: '',
      titleTooltip: '№',
      dataIndex: TableColumnName.Number,
      key: TableColumnName.Number,
      type: CellType.String,
      active: true,
      ellipsis: true,
      isFixedColumn: true,
      update: true
    },
    {
      title: 'ID',
      dataIndex: TableColumnName.ID,
      key: TableColumnName.ID,
      isFixedColumn: true,
      type: CellType.Int,
      width: 60,
      active: false
    },
    {
      title: EMPTY_STRING,
      titleText: 'Цвет',
      titleTooltip: 'Цвет',
      titleIcon: 'color-palette',
      dataIndex: TableColumnName.RouteColor,
      key: TableColumnName.RouteColor,
      ellipsis: true,
      size: 1,
      type: CellType.Color,
      figure: 'circle',
      align: 'center',
      active: true,
      isFixedColumn: true,
      editable: true
    },
    {
      title: EMPTY_STRING,
      titleIcon: 'check-mark',
      titleText: 'Статус',
      dataIndex: TableColumnName.Status,
      key: TableColumnName.Status,
      type: CellType.SelectIcon,
      isFixedColumn: true,
      active: true,
      align: 'center',
      ellipsis: false,
      editable: true,
      options: STATUS_VARIANTS_PROJECT,
      fieldNames: BASE_FIELD_NAMES,

      titleTooltip: ILSPlanningStatusTooltip,
      closeSelectOnScroll: true,
      classOverlayTitleTooltip: 'ils-tooltip'
    },
    {
      title: EMPTY_STRING,
      titleIcon: 'warning',
      titleText: 'Уведомления',
      titleTooltip: 'Уведомления к элементам таблицы',
      dataIndex: TableColumnName.Notification,
      key: TableColumnName.Notification,
      type: CellType.Icon,
      active: true,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Название',
      dataIndex: TableColumnName.FullName,
      key: TableColumnName.FullName,
      titleTooltip: titleTooltipFullName,
      titleTooltipPlaceMent: PlacementType.bottomLeft,
      type: CellType.Input,
      sorting: 2,
      filtering: FilterCellType.String,
      ellipsis: true,
      droppable: true,
      active: true,
      width: 250
    },
    {
      title: 'Номер тендера',
      dataIndex: TableColumnName.TenderID,
      key: TableColumnName.TenderID,
      type: CellType.Input,
      width: 120,
      ellipsis: true,
      active: false,
      editable: false
    },
    {
      title: 'Модель',
      dataIndex: TableColumnName.Model,
      key: TableColumnName.Model,
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Транспортная компания',
      dataIndex: TableColumnName.TransportCompanyName,
      key: TableColumnName.TransportCompanyName,
      type: CellType.Input,
      width: 180,
      ellipsis: true,
      active: false,
      editable: false
    },
    {
      title: 'Водитель',
      dataIndex: TableColumnName.DriverID,
      key: TableColumnName.DriverID,
      type: CellType.SelectMultipleApi,
      editable: true,
      active: false,
      closeSelectOnScroll: true
    },
    {
      title: 'Комментарий',
      dataIndex: TableColumnName.Comment,
      key: TableColumnName.Comment,
      type: CellType.Input,
      width: 200,
      ellipsis: true,
      active: false,
      editable: true
    },
    {
      title: 'Грузы',
      dataIndex: TableColumnName.Cargo,
      key: TableColumnName.Cargo,
      type: CellType.Int,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Код',
      dataIndex: TableColumnName.Code,
      key: TableColumnName.Code,
      type: CellType.Input,
      ellipsis: true,
      width: 100,
      active: false
    },
    {
      title: 'Инфо',
      dataIndex: TableColumnName.Info,
      key: TableColumnName.Info,
      type: CellType.Input,
      ellipsis: true,
      width: 100,
      active: false
    },
    {
      title: 'Клиенты',
      dataIndex: TableColumnName.Client,
      key: TableColumnName.Client,
      type: CellType.Int,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Начало',
      dataIndex: TableColumnName.StartTime,
      key: TableColumnName.StartTime,
      type: CellType.TimeStringUtc,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Конец',
      dataIndex: TableColumnName.EndTime,
      key: TableColumnName.EndTime,
      type: CellType.TimeStringUtc,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Дата доставки',
      dataIndex: TableColumnName.DeliveryDate,
      key: TableColumnName.DeliveryDate,
      type: CellType.Date,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.',
      dataIndex: TableColumnName.Duration,
      key: TableColumnName.Duration,
      type: CellType.Duration,
      width: 100,
      ellipsis: true,
      active: false,
      sorting: 3
    },
    {
      title: 'Длит.пути',
      dataIndex: TableColumnName.RoadDuration,
      key: TableColumnName.RoadDuration,
      type: CellType.DoubleRangeTimeString,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.движения',
      dataIndex: TableColumnName.MoveDuration,
      key: TableColumnName.MoveDuration,
      width: 120,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.отдыха',
      dataIndex: TableColumnName.RestDuration,
      key: TableColumnName.RestDuration,
      type: CellType.DoubleRangeTimeString,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Дистанция',
      dataIndex: TableColumnName.Distance,
      key: TableColumnName.Distance,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false,
      sorting: 1
    },
    {
      title: 'Сред.скорость',
      dataIndex: TableColumnName.AverageSpeed,
      key: TableColumnName.AverageSpeed,
      type: CellType.Float,
      width: 110,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.погрузки',
      dataIndex: TableColumnName.LoadDuration,
      key: TableColumnName.LoadDuration,
      width: 110,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.разгрузки',
      dataIndex: TableColumnName.UnloadDuration,
      key: TableColumnName.UnloadDuration,
      width: 110,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Окно работы',
      dataIndex: TableColumnName.Worktime,
      key: TableColumnName.Worktime,
      type: CellType.RangeTime,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Вес',
      titleTooltip: 'Количество кг. - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Mass,
      key: TableColumnName.Mass,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Паллет',
      titleTooltip: 'Количество паллет - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Pallets,
      key: TableColumnName.Pallets,
      type: CellType.Int,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Коробок',
      titleTooltip: 'Количество коробок - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Boxes,
      key: TableColumnName.Boxes,
      type: CellType.Int,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Объем',
      titleTooltip: 'Количество м3 - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Volume,
      key: TableColumnName.Volume,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'ДхШхВ',
      dataIndex: TableColumnName.Dimensions,
      key: TableColumnName.Dimensions,
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Точка погрузки',
      dataIndex: TableColumnName.LoadDepot,
      key: TableColumnName.LoadDepot,
      type: CellType.Input,
      width: 120,
      ellipsis: true,
      active: false
    },
    {
      title: 'Точка разгрузки',
      dataIndex: TableColumnName.UnloadDepot,
      key: TableColumnName.UnloadDepot,
      type: CellType.Input,
      width: 120,
      ellipsis: true,
      active: false
    },
    {
      title: 'Ожидание',
      dataIndex: TableColumnName.WaitingDuration,
      key: TableColumnName.WaitingDuration,
      type: CellType.DoubleRangeTimeString,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Опоздание',
      dataIndex: TableColumnName.LatenessDuration,
      key: TableColumnName.LatenessDuration,
      type: CellType.DoubleRangeTimeString,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'ID рампы',
      dataIndex: TableColumnName.RampID,
      key: TableColumnName.RampID,
      type: CellType.Int,
      width: 120,
      ellipsis: true,
      active: false
    },
    {
      title: 'Название рампы',
      dataIndex: TableColumnName.RampName,
      key: TableColumnName.RampName,
      type: CellType.Input,
      width: 180,
      ellipsis: true,
      active: false
    },
    {
      title: 'Заезд на рампу',
      dataIndex: TableColumnName.RampStartTime,
      key: TableColumnName.RampStartTime,

      width: 120,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Съезд с рампы',
      dataIndex: TableColumnName.RampEndTime,
      key: TableColumnName.RampEndTime,

      width: 120,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Специфика',
      dataIndex: TableColumnName.Restriction,
      key: TableColumnName.Restriction,
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Запрет',
      dataIndex: TableColumnName.ForbiddenZoneID,
      key: TableColumnName.ForbiddenZoneID,
      type: CellType.Input,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Зоны доставки',
      dataIndex: TableColumnName.Zone,
      key: TableColumnName.Zone,
      type: CellType.Input,
      width: 120,
      ellipsis: true,
      active: false
    },
    {
      title: 'Вес %',
      dataIndex: TableColumnName.MassPercent,
      key: TableColumnName.MassPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Объем %',
      dataIndex: TableColumnName.VolumePercent,
      key: TableColumnName.VolumePercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Коробок %',
      dataIndex: TableColumnName.BoxPercent,
      key: TableColumnName.BoxPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Паллет %',
      dataIndex: TableColumnName.PalletPercent,
      key: TableColumnName.PalletPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.%',
      dataIndex: TableColumnName.DurationPercent,
      key: TableColumnName.DurationPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Дист.%',
      dataIndex: TableColumnName.DistancePercent,
      key: TableColumnName.DistancePercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Клиенты %',
      dataIndex: TableColumnName.ClientPercent,
      key: TableColumnName.ClientPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Грузы %',
      dataIndex: TableColumnName.OrderPercent,
      key: TableColumnName.OrderPercent,
      type: CellType.Percent,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Шаблон рейса',
      dataIndex: TableColumnName.TripTemplate,
      key: TableColumnName.TripTemplate,
      type: CellType.Input,
      width: 110,
      ellipsis: true,
      active: false
    },
    {
      title: 'Порядок погр/разг',
      dataIndex: TableColumnName.LoadOrder,
      key: TableColumnName.LoadOrder,
      type: CellType.Input,
      width: 180,
      ellipsis: true,
      active: false
    },
    {
      title: 'Экспедитор',
      dataIndex: TableColumnName.ExpeditorID,
      key: TableColumnName.ExpeditorID,
      type: CellType.SelectAPI,
      active: false,
      closeSelectOnScroll: true
    },
    {
      title: 'Цена',
      dataIndex: TableColumnName.Cost,
      key: TableColumnName.Cost,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Штрафы',
      dataIndex: TableColumnName.Penalty,
      key: TableColumnName.Penalty,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Цена за км',
      dataIndex: TableColumnName.CostPerKM,
      key: TableColumnName.CostPerKM,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Цена за кг',
      dataIndex: TableColumnName.CostPerKG,
      key: TableColumnName.CostPerKG,
      type: CellType.Float,
      width: 100,
      ellipsis: true,
      active: false
    },
    {
      title: 'Эффективность',
      dataIndex: TableColumnName.Efficiency,
      key: TableColumnName.Efficiency,
      type: CellType.Percent,
      width: 150,
      ellipsis: true,
      active: false,
      sorting: 1
    },
    {
      titleTooltip: 'Колонка, позволяющая перейти к объекту строки на карте (Маршрут/Рейс/Заказ/Склад)',
      titleIcon: 'right-arrow',
      fixed: 'right',
      dataIndex: TableColumnName.FocusOnMap,
      key: TableColumnName.FocusOnMap,
      type: CellType.IconAction,
      active: false,
      width: 30
    }
  ]
};
