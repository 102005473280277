import React, { FC } from 'react';
import { Chart, Interval, Legend, Tooltip } from 'bizcharts';
import { BarChartProps } from '../../types/components/charts';
import { tooltipNameTranslator } from '../../utils/chart-tooltip';
import { graphVerticalChartLegendInfo } from '../../constants';
import { LegendItem } from 'bizcharts/lib/interface';
import { ILSSkeleton } from '@common/components';
import { AnalyticsChartNoData } from '@modules/analytics/common/charts/components/chart-no-data';
import { useForceUpdate } from '@modules/analytics/common/hooks/use-force-update';

const ILSAnalyticsClusteredChartComponent: FC<BarChartProps> = ({
  config,
  height = 200,
  data,
  axisConfig,
  metrics = ['value'],
  loading,
  name,
  children
}) => {
  useForceUpdate(loading);
  let chartIns: any;
  let legendMap = {};

  const changeHandler = (ev: any) => {
    const { item } = ev;
    const { value } = item;
    const checked = !item.unchecked;

    legendMap[value] = checked;

    chartIns.filter('type', (val: any) => {
      return legendMap[val] !== false;
    });
    chartIns.render(true);
  };

  if (!data?.length && !loading) {
    return <AnalyticsChartNoData name={name} hideBorder={!!children} />;
  }

  return (
    <div className="analytics-chart">
      <ILSSkeleton loading={loading}>
        <div className="mb-2">{config?.title}</div>
        <div>
          <Chart height={height} padding={[50, 20]} data={data} autoFit onGetG2Instance={(c: any) => (chartIns = c)}>
            <Tooltip shared customItems={tooltipNameTranslator} />
            <Interval
              adjust={[
                {
                  type: 'dodge',
                  marginRatio: 0
                }
              ]}
              color={['type', ['#D24747', '#43CC4C', '#4C91FF']]}
              position="date*monthAverageAmount"
            />
            <Legend custom={true} items={graphVerticalChartLegendInfo as LegendItem[]} position="top-left" onChange={changeHandler} />
          </Chart>
        </div>
      </ILSSkeleton>
    </div>
  );
};

export default ILSAnalyticsClusteredChartComponent;
