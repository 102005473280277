import { FC, useEffect, useRef } from 'react';
import { IPlanningResultTablesContainerProps } from '@modules/planning/children/by-date/types';
import { PlannerPlanningPlansTableContainer } from '@modules/planning/children/by-date/containers/result/tables/plans';
import { useSelectedRowActions, useActiveFocusRow } from '@modules/planning/children/by-date/hooks';
import { PlannerPlanningRoutesTableContainer } from '@modules/planning/children/by-date/containers/result/tables/routes';
import { Dictionary } from '@common/types';

export interface IPlannerPlanningPlansAndRoutesProps
  extends Pick<
    IPlanningResultTablesContainerProps,
    'onItemDrop' | 'markerDragging' | 'handleShowOrderTable' | 'setTriggerMapFocus' | 'settingsTableButtons'
  > {}

export const PlannerPlanningPlansAndRoutes: FC<IPlannerPlanningPlansAndRoutesProps> = ({
  onItemDrop,
  markerDragging,
  settingsTableButtons,
  handleShowOrderTable,
  setTriggerMapFocus
}) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { onSelectRows, onSelectRowsWithRecord } = useSelectedRowActions();
  const { onTableRowClick } = useActiveFocusRow();

  return (
    <div ref={tableRef} className="plan-table">
      <PlannerPlanningPlansTableContainer
        tableRef={tableRef}
        settingsTableButtons={settingsTableButtons}
        onTableRowClick={onTableRowClick}
        handleShowOrderTable={handleShowOrderTable}
        setTriggerMapFocus={setTriggerMapFocus}
      />
      <PlannerPlanningRoutesTableContainer
        dragInfo={{
          key: 'trip-target',
          dictionary: [Dictionary.Trip, Dictionary.Waypoint],
          handler: onItemDrop,
          active: markerDragging
        }}
        settingsTableButtons={settingsTableButtons}
        tableRef={tableRef}
        onSelectRows={onSelectRows}
        onTableRowClick={onTableRowClick}
        onSelectRowsWithRecord={onSelectRowsWithRecord}
        handleShowOrderTable={handleShowOrderTable}
        setTriggerMapFocus={setTriggerMapFocus}
      />
    </div>
  );
};

