import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getTotalCountRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const getTotalCountReducer = createReducer(initialState, {
  [getTotalCountRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: { orders: number; trips: number };
    }>
  ) => {
    const { data } = action.payload;
    state.orders.total = data.orders;
    state.trips.total = data.trips;
    return state;
  },
  [getTotalCountRoutine.FAILURE]: onFailure
});

