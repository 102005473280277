import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';
import { Dictionary } from '@common/types';
import { UploadButton } from '@modules/planning/children/data/types/components';

export const PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_TYPES: Array<PermittedUploadFileType> = [
  PermittedUploadFileType.excel,
  PermittedUploadFileType.sheetML
];
export const PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_EXTENSIONS: Array<PermittedUploadFileExtensions> = [
  PermittedUploadFileExtensions.xls,
  PermittedUploadFileExtensions.xlsx
];
export const PLANNER_DATA_UPLOAD_EXCEL_FORMATS: Array<PermittedUploadFileType | PermittedUploadFileExtensions> = [
  PermittedUploadFileType.excel,
  PermittedUploadFileType.sheetML,
  PermittedUploadFileExtensions.xls,
  PermittedUploadFileExtensions.xlsx
];

export const PERMITTED_UPLOAD_PLANNER_DATA_TEXT_TYPES: Array<PermittedUploadFileType> = [
  PermittedUploadFileType.textPlain,
  PermittedUploadFileType.textCSV
];
export const PERMITTED_UPLOAD_PLANNER_DATA_TEXT_EXTENSIONS: Array<PermittedUploadFileExtensions> = [
  PermittedUploadFileExtensions.csv,
  PermittedUploadFileExtensions.txt
];

export const PLANNER_DATA_UPLOAD_TEXT_FORMATS: Array<PermittedUploadFileType | PermittedUploadFileExtensions> = [
  PermittedUploadFileExtensions.csv,
  PermittedUploadFileExtensions.txt
];

export enum FileName {
  Example = 'ils-example',
  Template = 'ils-template',
  Txt = 'txt',
  Csv = 'csv',
  Excel = 'excel',
  Ini = 'ini'
}

export enum FileType {
  Txt = 'txt',
  Csv = 'csv',
  Excel = 'xcl',
  Ini = 'ini'
}

export const UploadButtons: Record<FileType, UploadButton> = {
  [FileType.Txt]: {
    text: FileName.Txt,
    icon: FileType.Txt
  },
  [FileType.Csv]: {
    text: FileName.Csv,
    icon: FileType.Csv
  },
  [FileType.Excel]: {
    className: 'success',
    text: FileName.Excel,
    icon: FileType.Excel
  },
  [FileType.Ini]: {
    text: FileName.Ini,
    icon: FileType.Ini
  }
};

export enum PlanningDataUploadForm {
  DataUploadForm = 'DataUploadForm'
}

export const DEFAULT_OPEN_TABLE = [Dictionary.Order];
export const NOT_VISIBLE_SCHEME_KEYS = [
  Dictionary.Options,
  Dictionary.SimplePlan,
  Dictionary.Trip,
  Dictionary.OrderAction,
  Dictionary.Waypoint,
  Dictionary.Plan
];

export const TIME_TABLE_COLUMNS = [
  'Cargo.LoadDuration',
  'Cargo.UnloadDuration',
  'AvailableFrom',
  'Delay',
  'Worktime.LoadStart',
  'Worktime.LoadEnd',
  'Worktime.UnloadStart',
  'Worktime.UnloadEnd',
  'Worktime.LunchStart',
  'Worktime.LunchEnd',
  'StartTime',
  'VehicleProfile.LoadDuration',
  'VehicleProfile.UnloadDuration',
  'DriverTimeline.StartTime',
  'DriverTimeline.WorkPerWeek',
  'DriverTimeline.WorkPerTwoWeek'
];
