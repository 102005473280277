import { getSRCIconSVG } from '@common/decorators/path';
import classnames from 'classnames';
import { ILSTooltip } from '@components/data-display/tooltip';
import { ReactSVG } from 'react-svg';
import { IILSCellIconProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { isString } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellIcon = <R extends TableRecord>({ children, alt }: IILSCellIconProps<R>): JSX.Element => {
  const [, icon] = children;

  if (!isString(icon)) {
    showUserAndDevError({ error: 'Иконка должна быть строкой - названием иконки в папке public -> assets -> icons' });
    return <></>;
  }

  return (
    <ILSTooltip title={alt}>
      {icon && <ReactSVG className={classnames('error-icon icon-cell', 'error-icon')} alt={alt ?? icon} src={getSRCIconSVG(icon)} />}
    </ILSTooltip>
  );
};
