import { ILSSelect } from '@components/index';
import { FC } from 'react';
import { EMULATION_SPEED_VARIANTS } from '../../constants';

interface Props {
  isDisabled: boolean;
  handleChange?: (value: number) => void;
  value: number;
}

// Компонент для селекта для выбора скорости эмуляции

const ILSEmulationSpeedComponent: FC<Props> = ({ isDisabled, handleChange, value = 0 }) => {
  return (
    <ILSSelect
      className="ils-emulator-emulation-speed-select ml-3"
      options={EMULATION_SPEED_VARIANTS}
      value={value}
      onChange={handleChange}
      disabled={isDisabled}
    />
  );
};

export default ILSEmulationSpeedComponent;
