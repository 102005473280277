import { ModulePath, Notify, NotifyDurationInSecond } from '@common/types';
import { delay, put } from 'redux-saga/effects';
import { userNotifyRoutine } from '@core/actions/user-notification';
import { PayloadAction } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';

const DEFAULT_ERROR_MESSAGE = 'Ошибка';
const PARSING_ERROR_MESSAGE = 'Ошибка парсинга названия экшена';

enum RoutineActionOperation {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

/**метод преобразования типа экшена (FAILURE, SUCCESS, REQUEST) к типу уведомления*/
const getNotifyType = (actionType: RoutineActionOperation): Notify.Success | Notify.Error | Notify.Loading => {
  switch (actionType) {
    case RoutineActionOperation.FAILURE:
      return Notify.Error;
    case RoutineActionOperation.SUCCESS:
      return Notify.Success;
    case RoutineActionOperation.REQUEST:
      return Notify.Loading;
  }
};

/**метод получения индентификатора для уведомления*/
const getKey = (
  prefix: string,
  keySuffix: string,
  action: string,
  notifyKey: string | undefined
): { loadingKey: string; fulfillKey: string; errorKey: string } => {
  const generateSpecificKey = (type: Notify) => `${notifyKey}-${type}`;
  const generateKey = (type: Notify) => `${prefix}-${type}-${action}-${keySuffix}`;
  //NOTE у loadingKey и fulfillKey должен быть одинаковый ключ чтобы загрывать и обновлять уведомления о загрузках
  if (notifyKey) {
    return {
      loadingKey: generateSpecificKey(Notify.Success),
      fulfillKey: generateSpecificKey(Notify.Success),
      errorKey: generateSpecificKey(Notify.Error)
    };
  }
  return {
    loadingKey: generateKey(Notify.Loading),
    fulfillKey: generateKey(Notify.Loading),
    errorKey: generateKey(Notify.Loading)
  };
};

//TODO common getNotificationMessage: ({
//     action,
//     notifyType,
//     payload,
//     defaultStatuses
//   }: {
//     defaultStatuses?: Record<
//       TAction,
//       {
//         loading: string;
//         success: string;
//         successPlural?: string;
//         error?: string;
//       }
//     >;
//     action: TAction;
//     notifyType: Notify.Loading | Notify.Error | Notify.Success;
//     payload?: TPayload;
//   }) => string;

/**Метод создания воркера для отображения уведомления пользователя на основании рутины и payload
 *
 * Action type должен быть в формате AAA/BBB/CCC, где
 *
 *   AAA - это название модуля или подмодуля,
 *
 *   BBB - название действия TAction,
 *
 *   CCC - тип действия  - REQUEST|SUCCESS|FAILURE,
 *
 * Payload может принимать notifyKey: string, для того, чтобы возможно было реализовать сложную цепочку сообщений объединенных операций
 *
 * Payload может принимать errorNotifyKey: string, в этом случае для ошибок не будет генерироваться уникальный ключ
 *
 * Payload может принимать withoutNotification: boolean, в случае true -не будет оповещения
 *
 * Payload может принимать notificationCloseDelay: задержка при закрытии сообщения в статусе Notify.Loading
 *
 * @getActionFromRoutine  - метод преобразования действия рутины в типовой TAction
 * @getKeyPrefix - метод который должен принимать action payload возврашать перфикс ключа уведомлений
 * @getNotificationMessage  - метод получения текста сообщения. Принимает что-то из перечисления TAction, Notify, payload - опционально, возвращает string
 * @getErrorMessage - если указан - вызывается для получения сообщения об ошибке, если не указан, то ошибка берется из getNotificationMessage
 * @successDuration - время показа сообщения об успехе по умолчанию 1 секунда
 */
export const notifierWorkerCreator = <TPayload, TAction extends PropertyKey>(args: {
  getActionFromRoutine: (action: string) => TAction;
  getKeyPrefix: (payload: TPayload) => string;
  getNotificationMessage: (action: TAction, notifyType: Notify.Loading | Notify.Error | Notify.Success, payload?: TPayload) => string;
  getErrorMessage?: (payload: TPayload) => string | string[];
  successDuration?: NotifyDurationInSecond;
}) => {
  const { getActionFromRoutine, getKeyPrefix, getNotificationMessage, getErrorMessage, successDuration } = args;
  return function* (
    action: PayloadAction<
      TPayload & { notifyKey?: string; errorNotifyKey?: string; withoutNotification?: boolean; notificationCloseDelay?: number | null }
    >
  ) {
    /**
     * Время через которое автоматически закроется уведомление
     * undefined - установится время по умолчанию = 1
     * null - уведомление закроется по истечению duration
     */
    let closeDelay = action?.payload?.notificationCloseDelay === undefined ? 1 : action.payload.notificationCloseDelay;
    const withoutNotification = action?.payload?.withoutNotification;

    const actionTypeSplitted: string[] = action.type.split(ModulePath.Dashboard);

    if (actionTypeSplitted.length != 3) {
      console.error(PARSING_ERROR_MESSAGE);
      return;
    }

    const keySuffix = actionTypeSplitted[0];
    const actionTypeOperation: TAction = getActionFromRoutine(actionTypeSplitted[1]);
    const actionTypeResult: RoutineActionOperation = RoutineActionOperation[actionTypeSplitted[2]];

    if (!actionTypeResult || !actionTypeOperation) {
      console.error(PARSING_ERROR_MESSAGE);
      return;
    }

    const notifyType: Notify = getNotifyType(actionTypeResult);
    const prefix = getKeyPrefix(action.payload);

    const { loadingKey, fulfillKey } = getKey(prefix, keySuffix, actionTypeOperation.toString(), action.payload?.notifyKey);

    if (withoutNotification) return;

    switch (notifyType) {
      case Notify.Loading:
        const loadingMessage = getNotificationMessage(actionTypeOperation, notifyType, action.payload);
        if (loadingMessage) {
          yield put(
            userNotifyRoutine.trigger({
              type: notifyType,
              status: loadingMessage,
              key: loadingKey
            })
          );
        }
        break;
      case Notify.Success:
        const successMessage = getNotificationMessage(actionTypeOperation, notifyType, action.payload);
        if (successMessage) {
          yield put(
            userNotifyRoutine.trigger({
              type: notifyType,
              status: successMessage,
              key: fulfillKey,
              duration: successDuration || NotifyDurationInSecond.One
            })
          );
        }
        break;
      case Notify.Error:
        const error = getErrorMessage?.(action.payload);
        if (error instanceof Error) {
          console.error(error); //todo: тут надо как-то умнее выводить такие ошибки... +++ сейчас все вотчеры просто падают
        } else {
          yield put(
            userNotifyRoutine.trigger({
              type: notifyType,
              key: action.payload?.errorNotifyKey,
              status: error || getNotificationMessage(actionTypeOperation, notifyType) || DEFAULT_ERROR_MESSAGE
            })
          );
        }
    }

    // Закрываем сообщение о загрузке, за исключением случая (Notify.Loading && !withoutNotification)
    if ((notifyType !== Notify.Loading || (notifyType === Notify.Loading && withoutNotification)) && isNumber(closeDelay)) {
      if (closeDelay) yield delay(closeDelay * 1000);
      yield put(userNotifyRoutine.fulfill({ key: loadingKey }));
    }
  };
};
