import { FC, memo } from 'react';
import { OwnVehicleTableComponent } from '../tables/own-vehicle-table-component';
import { VehicleMockOrdersNTrips } from '@modules/orders-and-trips/mock/vehicles';
import { Keys } from '@common/types';

interface IProps {
  recordTableButtons?: any;
  onSelectRows?: (newSelectRows: Keys) => void;
}

export const OwnTransport: FC<IProps> = memo(({ ...props }) => {
  return <OwnVehicleTableComponent {...props} dataSource={VehicleMockOrdersNTrips} />;
});

