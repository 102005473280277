import { FC } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { ILSTypographyLink } from '@common/components/general/typography';
import { getLinkHref } from '@modules/current-user/helpers/logistic-services/get-link-href';
import { LogisticServiceType } from '@modules/current-user/types/logistic-servises';
import { renderLogisticServiceBadge } from '@modules/current-user/helpers/logistic-services/render-logistic-service-badge';
import { LOGISTIC_SERVICE_FAST_DELIVERY_TITLE } from '@modules/current-user/constants/logistic-servises';
import { getAnchorBrowserContext } from '@modules/current-user/helpers/logistic-services/get-anchor-browser-context';

type IProps = {
  service: LogisticServiceType;
};

export const LogisticServicesCard: FC<IProps> = ({ service }) => {
  const isActive = Boolean(service?.link);
  const isFastDelivery = service.title === LOGISTIC_SERVICE_FAST_DELIVERY_TITLE;
  const anchorBrowserContext = getAnchorBrowserContext(service.link);

  return (
    <li key={service.title}>
      <ILSTypographyLink disabled={!isActive} href={getLinkHref(service.link)} target={anchorBrowserContext}>
        <div className={classNames('logistic-services__card', { active: isActive, 'fast-delivery': isFastDelivery })}>
          <div className="logistic-services__card-icon">
            {renderLogisticServiceBadge({ isActive, isFastDelivery })}
            <ReactSVG className="logistic-services__icon" alt={service.title} src={service.icon} />
          </div>

          <p>{service.title}</p>
        </div>
      </ILSTypographyLink>
    </li>
  );
};

