import { FC } from 'react';
import { ILSDivider } from '@common/components';
import { ILSTypographyTitle } from '@components/general/typography';
import { CardTab } from '@modules/tender-platform/constants';

interface IProps {
  title: string;
  id?: CardTab;
}

export const TenderPlatformDividerTitle: FC<IProps> = ({ title, id, ...props }) => {
  return <ILSDivider {...props} children={<ILSTypographyTitle id={id} children={title} level={3} />} />;
};
