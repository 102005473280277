import { DemandRow, IDemandStore } from '../types/store';

// Утилита для обновления данных списка
export const updateDemandData = (
  data: IDemandStore['list'],
  updateData: IDemandStore['list'],
  hasDesync: boolean,
  forceUpdate?: boolean
): { newList: IDemandStore['list']; hasDesync: IDemandStore['hasDesync'] } => {
  if (forceUpdate) {
    return { newList: updateData, hasDesync: false };
  }
  if (data.length) {
    const lastDemand = data[data.length - 1];
    const index = updateData.findIndex((demand) => isEqualDemandRow(lastDemand, demand)) + 1;
    if (index) {
      return {
        newList: data.concat(updateData.filter((newDemand) => !data.find((existDemand) => isEqualDemandRow(newDemand, existDemand)))),
        hasDesync: true
      };
    }
    return { newList: data.concat(updateData.slice(index)), hasDesync: hasDesync };
  } else {
    return { newList: updateData, hasDesync: false };
  }
};

// Утилита для сравнения строк списка
export const isEqualDemandRow = (demand1: DemandRow, demand2: DemandRow): boolean => {
  return demand1.DateTime === demand2.DateTime && demand1.Type === demand2.Type && demand1.Data.ID === demand2.Data.ID;
};
