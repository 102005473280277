import '../styles.less';
import { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { RouteConfig } from 'react-router-config';
import { ILSDivider } from '@common/components';
import { PlannerDataView } from '@modules/planning/children/data/containers/view/section';
import { PlannerDataUpload } from '@modules/planning/children/data/containers/upload/section';
import { useDispatch, useSelector } from 'react-redux';
import { uploadedDataSelector } from '@modules/planning/children/data/selectors';
import { isSelectorEmpty } from '@common/utils/helpers/store/is-selector-empty';
import { useAppSelector } from '@core/hooks';
import { planningSettingsSelector } from '@modules/settings/selectors';
import { getUploadedDataRoutine, getUploadTemplateRoutine } from '@modules/planning/children/data/actions';

interface IProps<Params extends { [K in keyof Params]?: string } = {}> extends RouteComponentProps<Params> {
  route?: RouteConfig | undefined;
}

export const PlannerData: FC<IProps> = () => {
  const dispatch = useDispatch();
  const data = useSelector(uploadedDataSelector);

  const needData = isSelectorEmpty(data, true);
  const settings = useAppSelector(planningSettingsSelector);
  const validateOnImport = !!settings?.parameters?.validateOnImport;

  useEffect(() => {
    needData && dispatch(getUploadedDataRoutine());
    dispatch(getUploadTemplateRoutine());
  }, []);

  return (
    <section className={'planning-data'}>
      <PlannerDataUpload />
      <ILSDivider type={'vertical'} />
      <PlannerDataView validateOnImport={validateOnImport} />
    </section>
  );
};
