import { FC, useState } from 'react';
import './styles.less';
import { countries } from './countries';
import { ILSFormItem, ILSInput, ILSSelect, ILSTooltip } from '@common/components';
import { ILSSVG } from '@components/custom/icons/svg';
import { flagTagRender } from '@components/data-entry/input/input-reg-number/flag';
import { InputProps } from 'antd';
import { KeyboardCode } from '@common/constants/general';
import { EventBlurNFocus } from '@common/types/components/table/cell';

export interface IILSInputRegNumberProps extends InputProps {
  regNumber: string;

  handleSave(value: string): void;

  toggleEdit?(): void;
}

export const ILSInputRegNumber: FC<IILSInputRegNumberProps> = ({ regNumber, handleSave, toggleEdit }) => {
  const [activeCountry, setActiveCountry] = useState(countries[0]);
  const [newRegNumber, setNewRegNumber] = useState(() => regNumber?.toUpperCase());
  const { regex, placeholder } = activeCountry;

  const onSave = ({ target: { value } }: EventBlurNFocus) => {
    handleSave(value.toUpperCase());
    toggleEdit?.();
    return;
  };

  const onKeyDown = (e: EventBlurNFocus) => {
    const { code } = e;
    if (code === KeyboardCode.Enter) {
      onSave(e);
    }
  };

  const handleChange = (value: string) => setActiveCountry((prev) => countries.find(({ code }) => code === value) ?? prev);

  return (
    <ILSFormItem className={'reg-number'} hasFeedback rules={[{ pattern: regex, message: 'No Space or Special Characters Allowed' }]}>
      <ILSInput
        addonBefore={
          <ILSSelect
            options={countries.map(({ code, country }) => {
              return {
                code,
                label: (
                  <ILSTooltip title={country}>
                    <ILSSVG icon={code} config={{ iconModule: '/flags/' }} />
                  </ILSTooltip>
                )
              };
            })}
            tagRender={flagTagRender}
            defaultValue={'ru'}
            onChange={handleChange}
            fieldNames={{ value: 'code', label: 'label' }}
            dropdownMatchSelectWidth={false}
          />
        }
        value={newRegNumber}
        onChange={({ target: { value } }) => setNewRegNumber(value.toUpperCase().replace(/\s/g, ''))}
        defaultValue={newRegNumber}
        placeholder={placeholder ?? 'Введите номер'}
        autoFocus
        onBlur={onSave}
        onKeyDown={onKeyDown}
      />
    </ILSFormItem>
  );
};
