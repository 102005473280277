import { FC } from 'react';
import { ILSModal } from '@common/components';
import { PAYMENT_MODAL_TITLES } from '@common/feature/payment/constants/general';
import { PaymentModalType } from '@common/feature/payment/types/general';
import { renderPaymentModalContent } from '@common/feature/payment/utils/helpers/render-payment-modal-content';

interface IProps {
  modalType: PaymentModalType;
  showForm: boolean;
  handleShowModal(): void;
  openInvoiceViewer(): void;
}

export const PaymentModal: FC<IProps> = ({ modalType, showForm, handleShowModal, openInvoiceViewer }) => {
  return (
    <ILSModal title={PAYMENT_MODAL_TITLES[modalType]} footer={null} visible={showForm} onCancel={handleShowModal}>
      {renderPaymentModalContent({ modalType, handleShowModal, openInvoiceViewer })}
    </ILSModal>
  );
};

