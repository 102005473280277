import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { getProjectsRoutine, planningCreateProjectRoutine } from '@modules/planning/actions';
import { dataImportRoutine } from '@modules/planning/children/data/actions';
import { plannerDataProjectFormSelector } from '@modules/planning/children/data/selectors';
import { useModal } from '@common/hooks/components/use-modal';
import { DateFormat, Notify, Project } from '@common/types';
import { ProjectSelectValues } from '@modules/planning/children/data/types';
import { TIMEZONE } from '@common/constants/general/date-time';
import { PROJECT_OPTIONS, PROJECT_OPTIONS_WITH_CURRENT, ProjectOptionStatus } from '@modules/planning/children/data/constants/upload';
import { PlanningDataUploadForm } from '@modules/planning/children/data/constants';
import { getFromTillMonth } from '@common/utils/helpers/date-time/date-time';
import { filterByDateProjects } from '@modules/planning/utils/helpers/filter-by-date-projects';
import { ILSForm, ILSRadioGroup, ILSUserNotify } from '@components/index';
import { renderProjectOptionStatus } from '@modules/planning/children/data/utils/helpers/tables';

export const PlannerDataProjectForm: FC = () => {
  const dispatch = useDispatch();
  const { files, availableDates, disabledSubmit, projects, isLoading, dayProjectsInitial, activeProject, activeProjectID } =
    useSelector(plannerDataProjectFormSelector);

  const { visible, setVisible } = useModal();

  const [option, setOption] = useState(activeProjectID ? ProjectOptionStatus.CurrentProject : ProjectOptionStatus.CreateNewProject);
  const [values, setValues] = useState<ProjectSelectValues>({ Start: moment(), timezone: TIMEZONE, Name: 'Проект' });
  const [dayProjects, setDayProjects] = useState<Array<Project>>(dayProjectsInitial);
  const [projectID, setProjectID] = useState<Project['ID']>(dayProjects?.[0]?.ID);

  const onCancel = () => setVisible(false);

  const setProject = () => {
    setProjectID(projectID ?? dayProjects?.[0]?.ID);
    onCancel();
  };

  const chooseProject = (ID: Project['ID']) => setProjectID(ID);
  const handleChange = (e: RadioChangeEvent) => {
    setOption(e.target.value);
  };

  const onSelect = (date: Moment | null) => {
    if (date) {
      setDayProjects(filterByDateProjects(projects, date));
      setValues((initial) => ({ ...initial, date }));
    }
  };
  useEffect(() => {
    setVisible(!Boolean(option) && dayProjects.length > 0);
  }, [dayProjects, option, values]);

  const onFinish = (val: { Start: moment.Moment; timezone: string; Name: string }) => {
    if (disabledSubmit) return;
    switch (option) {
      case ProjectOptionStatus.CreateNewProject:
        const { Name, Start } = val;
        return dispatch(planningCreateProjectRoutine({ files, Start: Start.format(DateFormat.YYYYMMDDSlash), Name }));
      case ProjectOptionStatus.ChooseProject:
        if (projectID) {
          return dispatch(dataImportRoutine({ files, projectID }));
        } else {
          ILSUserNotify(Notify.Warning, 'Необходимо выбрать проект');
          break;
        }
      case ProjectOptionStatus.CurrentProject:
        return dispatch(dataImportRoutine({ files, activeProjectID }));
      default:
        ILSUserNotify(Notify.Warning, 'Необходимо выбрать проект');
    }
  };

  const onPanelChange = (e: Moment) => {
    const [from, till] = getFromTillMonth(e, 1) as [string, string];
    dispatch(getProjectsRoutine({ from, till }));
  };

  return (
    <ILSForm
      className={classNames('planning-data-upload-form', { 'planning-data-upload-form-with-project': activeProjectID })}
      initialValues={values}
      name={PlanningDataUploadForm.DataUploadForm}
      onFinish={onFinish}
    >
      <ILSRadioGroup
        optionType="button"
        buttonStyle="solid"
        value={option}
        onChange={handleChange}
        options={activeProjectID ? PROJECT_OPTIONS_WITH_CURRENT : PROJECT_OPTIONS}
      />
      {renderProjectOptionStatus({
        projectOptionStatus: option,
        values,
        availableDates,
        projectID,
        dayProjects,
        activeProjectID,
        activeProject,
        isLoading,
        visible,
        onPanelChange,
        onSelect,
        onCancel,
        chooseProject,
        setProject
      })}
    </ILSForm>
  );
};
