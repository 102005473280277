import { FC, MutableRefObject } from 'react';
import { tableCustom } from '@core/containers/table-custom';
import { ILSTable } from '@common/components';
import { ROUTES_TABLE_CONFIG } from '@modules/planning/children/by-date/config/plan';
import { useDispatch, useSelector } from 'react-redux';
import { routesDataSourceSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { ILSResultEfficiencyButton } from '@modules/planning/children/by-date/containers/result/efficiency';
import { DragInfo, Keys, TableName, TableRecord } from '@common/types';
import { handleRoutesTableUpdate } from '@modules/planning/children/by-date/helpers/tables/handlers/routes/handle-routes-table-update';
import { focusOnMap } from '@modules/planning/children/by-date/helpers/tables/handlers/routes/focus-on-map';
import { useRoutesDragNDrop } from '@modules/planning/children/by-date/hooks/tables/routes/use-routes-drag-n-drop';
import { useRoutesTenderButtons } from '@modules/planning/children/by-date/hooks/tables/routes/use-routes-tender-buttons';
import { IPlanningResultTablesContainerProps, PlanningByDateState, RoutesTableRecord } from '@modules/planning/children/by-date/types';
import { useContextMenu } from '@modules/planning/children/by-date/hooks';
import { PlannerPlanningRoutesTableContextMenuContainer } from '@modules/planning/children/by-date/containers/result/context-menu/routes';
import { OnSelectWithRecordNAllRecordParams } from '@common/types/components/table/functions';
import {
  activeIsFetchingSelector,
  activePlanFullNameSelector,
  activePlanIDSelector
} from '@modules/planning/children/by-date/selectors/active-plan';
import { refDataForRoutesTableSelector } from '@modules/planning/children/by-date/selectors/components/tables/ref-data-for-tables';
import { plansIDsSelector } from '@modules/planning/children/by-date/selectors/project';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { canEditRoutes } from '@modules/planning/children/by-date/helpers/tables/can-edit';
import {
  isActivePlanPlanningSelector,
  planningFocusNExpandRowIdsRoutesTableSelector
} from '@modules/planning/children/by-date/selectors/planning';
import { plannerSettingsSelector } from '@modules/planning/selectors';
import { useExpandableRow } from '@modules/planning/children/by-date/hooks/tables/use-expandable-row';
import { useIndexExpandColumn } from '@modules/planning/children/by-date/hooks/tables/routes/use-index-expand-column';
import { useRoutesTableScroll } from '@modules/planning/children/by-date/hooks/tables/routes/use-routes-table-scroll';
import { useRoutesTableLocale } from '@modules/planning/children/by-date/hooks/tables/routes/use-routes-table-locale';
import { useVirtualTableSettings } from '@common/hooks/use-virtual-table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { planningDeleteRoutine, planningRememberSelectedRowsRoutine } from '@modules/planning/children/by-date/actions';
import { handleDeleteRouteTablePayload } from '@modules/planning/children/by-date/helpers/tables/handle-delete-route-table-payload';

export interface IPlannerPlanningRoutesTableContainerProps
  extends Pick<
    IPlanningResultTablesContainerProps,
    'setTriggerMapFocus' | 'handleShowOrderTable' | 'settingsTableButtons' | 'onTableRowClick'
  > {
  tableRef: MutableRefObject<HTMLDivElement | null>;
  dragInfo: DragInfo;

  onSelectRows(selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined): void;

  onSelectRowsWithRecord(data: OnSelectWithRecordNAllRecordParams): void;
}

export const PlannerPlanningRoutesTableContainer: FC<IPlannerPlanningRoutesTableContainerProps> = ({
  setTriggerMapFocus,
  tableRef,
  handleShowOrderTable,
  dragInfo,
  onSelectRows,
  onSelectRowsWithRecord,
  settingsTableButtons = [],
  onTableRowClick
}) => {
  const dispatch = useDispatch();
  const {
    table: { showPlans }
  } = useSelector(plannerSettingsSelector);
  const { refData } = useSelector(refDataForRoutesTableSelector);

  const isPlanning = useSelector(isActivePlanPlanningSelector);

  const dataSource = useSelector((state: PlanningByDateState) => routesDataSourceSelector(state, setTriggerMapFocus));

  const { dragHandler } = useRoutesDragNDrop({ tableRef });
  const { onContextOpen, onContextClose, contextMenu, hasContextMenu } = useContextMenu({ tableRef });

  const { tenderButtons } = useRoutesTenderButtons();
  const recordTableButtons = [
    ...tenderButtons,
    {
      Component: ILSResultEfficiencyButton
    },
    ...settingsTableButtons
  ];
  const { focusRow, expandedRowKeys } = useSelector(
    planningFocusNExpandRowIdsRoutesTableSelector(TableName.RoutesTable, dataSource?.[0]?.ID)
  );
  const activePlanID = useSelector(activePlanIDSelector);
  const planName = useSelector(activePlanFullNameSelector);
  const planIDs = useSelector(plansIDsSelector);
  const planCounter = planIDs.length;
  const loading = useSelector(activeIsFetchingSelector);
  const { catalogLoad } = useCatalog();
  const { externalExpandableProps } = useExpandableRow();
  const { overscanRowCount } = useVirtualTableSettings({
    deps: [dataSource, expandedRowKeys],
    tableContaierClassName: 'plan-table-current-plan',
    rowTableClassName: 'plan-table-current-plan',
    scrollYHeight: 500,
    rowHeight: 40
  });

  const onDelete = (ids: Keys, records: RoutesTableRecord[]) => {
    records.forEach((record) => {
      const payload = handleDeleteRouteTablePayload(record);
      dispatch(planningDeleteRoutine(payload));
    });

    handleShowOrderTable(false);
    dispatch(planningRememberSelectedRowsRoutine({ recordIDs: [], tableName: TableName.PlanningNotDeliveredOrder }));
  };

  return (
    <>
      <RoutesTableComponent
        handleDelete={onDelete}
        loading={loading}
        onSelectRows={onSelectRows}
        planCounter={planCounter}
        dictionaryLoad={catalogLoad}
        showPlans={showPlans}
        dragInfo={dragInfo}
        dragHandler={dragHandler}
        refData={refData}
        onCanEdit={canEditRoutes({ refData })}
        tableTitle={`План ${planName} (№${activePlanID})`}
        handleSave={handleRoutesTableUpdate(dispatch)}
        onContextOpen={onContextOpen}
        onTableRowDoubleClick={focusOnMap(setTriggerMapFocus)}
        recordTableButtons={recordTableButtons}
        isPlanning={isPlanning}
        dataSource={isPlanning ? [] : dataSource}
        onSelectRowsWithRecord={onSelectRowsWithRecord}
        onTableRowClick={onTableRowClick}
        focusRow={focusRow}
        externalExpandableProps={externalExpandableProps}
        overscanRowCount={overscanRowCount}
        defaultPageSize={dataSource?.length ?? DEFAULT_PAGE_SIZE}
      />
      {hasContextMenu && (
        <PlannerPlanningRoutesTableContextMenuContainer
          onContextClose={onContextClose}
          visible={contextMenu?.visible}
          record={contextMenu?.record}
          coords={contextMenu?.coords}
          maxHeight={contextMenu?.maxHeight}
          setTriggerMapFocus={setTriggerMapFocus}
          handleShowOrderTable={handleShowOrderTable}
        />
      )}
    </>
  );
};

const RoutesTableComponent = tableCustom(
  ({ planCounter = 1, showPlans = true, ...props }) => {
    const { indexExpandColumn } = useIndexExpandColumn(props.config);
    const { scroll } = useRoutesTableScroll({ planCounter, showPlans });
    const { locale } = useRoutesTableLocale(props.isPlanning);

    return (
      <ILSTable
        {...props}
        className="result-table plan"
        expandIconColumnIndex={indexExpandColumn}
        pagination={false}
        scroll={scroll}
        locale={locale}
        isScrollToActiveRow={false}
      />
    );
  },
  ROUTES_TABLE_CONFIG,
  TableName.RoutesTable
);
