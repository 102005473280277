import { IndexedArray } from '@common/types';
import { MonitoringVehicleEventsModel } from '@common/types/dictionaries/monitoring-vehicle';

/**
 * Получение координат для автомобиля
 */
export const getVehicleBounds = (
  selectedVehicleIDs: (string | number)[] | undefined,
  activeVehicleID: string | number | undefined,
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>,
  selectedVehicleId: any
): (string | number | undefined)[][] => {
  const bounds = [];
  if (activeVehicleID) {
    for (const id in vehicleEvents) {
      const vehicle = vehicleEvents[id];
      if (
        !selectedVehicleIDs?.includes(vehicle.ID?.toString()) &&
        activeVehicleID !== vehicle.ID?.toString() &&
        selectedVehicleId !== vehicle.ID
      ) {
        continue;
      }
      for (const imei in vehicle.VehicleTracker) {
        if (vehicle.VehicleTracker[imei].Tracker.TrackerLastInfo) {
          bounds.push([
            vehicle.VehicleTracker[imei].Tracker.TrackerLastInfo?.Latitude,
            vehicle.VehicleTracker[imei].Tracker.TrackerLastInfo?.Longitude
          ]);
        }
      }
    }
  }
  return bounds;
};

/**
 * Получение координат для маркеров
 */
export const getWayPointsBounds = (
  selectedVehicleIDs: (string | number)[] | undefined,
  activeVehicleID: string | number | undefined,
  selectedVehicleID: string | number | undefined,
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>
): (string | number | undefined)[][] => {
  const bounds = [];
  if (selectedVehicleIDs?.length || activeVehicleID) {
    for (const eventID in vehicleEvents) {
      const vehicle = vehicleEvents[eventID];

      if (
        !vehicle.Trip ||
        (activeVehicleID?.toString() !== vehicle.ID?.toString() &&
          !selectedVehicleIDs?.includes(vehicle.ID?.toString()) &&
          selectedVehicleID?.toString() !== vehicle.ID?.toString())
      ) {
        continue;
      }

      for (const tripID in vehicle.Trip) {
        const trip = vehicle.Trip[tripID];
        if (!trip.Waypoint) continue;
        for (const wpID in trip.Waypoint) {
          const wp = trip.Waypoint[wpID];
          if (wp.Depot) {
            const coords = Object.values(wp.Depot.LatLon ?? {});
            bounds.push(coords);
          }
        }
      }
    }
  }
  return bounds;
};

/**
 * Получение координат для маркеров
 */
export const getWayPointsBoundsByWaypointId = (
  waypointId: number,
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>
): (string | number | undefined)[][] => {
  const bounds = [];
  for (const eventID in vehicleEvents) {
    const vehicle = vehicleEvents[eventID];
    if (!vehicle.Trip) {
      continue;
    }
    for (const tripID in vehicle.Trip) {
      const trip = vehicle.Trip[tripID];
      if (!trip.Waypoint) continue;
      for (const wpID in trip.Waypoint) {
        const wp = trip.Waypoint[wpID];
        if (wp.ID === waypointId) {
          const coords = Object.values(wp?.Depot?.LatLon ?? {});
          bounds.push(coords);
        }
      }
    }
  }
  return bounds;
};
/**
 * Получение координат планового трека
 */
export const getTripsCoords = (
  trips: (MonitoringVehicleEventsModel['Trip'] & IndexedArray<{ ForbiddenZones?: number[] | null }>) | undefined,
  cache: (a: string) => any
): (string | number | undefined)[][] => {
  if (trips) {
    return Object.values(trips)
      .flatMap((trip) => Object.values(trip.Waypoint))
      .flatMap((point) => (point?.GeoHash ? cache(point.GeoHash) : []));
  }
  return [];
};
