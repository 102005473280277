import { call, put, takeLatest } from 'redux-saga/effects';
import { FileInfo } from '@modules/planning/children/data/types/data';
import { checkConflictRoutine, uploadRoutine } from '@modules/planning/children/data/actions';
import { Data } from '@modules/planning/children/data/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { DataConflict } from '@modules/planning/children/data/types';

function* checkConflictWorker(action: PayloadAction<{ file: File } & DataConflict>) {
  const { request, success, failure, fulfill } = checkConflictRoutine;
  const { file, type } = action.payload;

  try {
    yield put(request());
    const {
      data: {
        data: { files }
      }
    } = yield call(Data.check);

    const hasConflict = Boolean(Object.values(files as Array<FileInfo>).filter(({ fileDataRowCount }) => fileDataRowCount).length);
    if (!hasConflict) {
      yield put(uploadRoutine.trigger({ file, type }));
    } else {
      yield put(success({ conflict: { hasConflict, type } }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* checkConflictWatcher() {
  yield takeLatest(checkConflictRoutine.trigger, checkConflictWorker);
}
