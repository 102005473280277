import { apiInstance } from '@core/api/instance';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { ITenderOwnAPI } from '@modules/tender-platform/children/tender-own/types/api';

export const TenderOwn: ITenderOwnAPI = {
  // Tender API
  index: () => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Index), {}),
  create: ({ values }) => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Create), { data: { values } }),
  update: ({ id, values }) =>
    apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Update), {
      data: {
        id,
        values
      }
    }),
  delete: ({ ids }) =>
    apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Delete), {
      data: {
        ids
      }
    }),
  setStatus: ({ tenderID, statusID }) =>
    apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.SetStatus), { data: { tenderID, statusID } }),
  appointResponsible: ({ tenderID, userID }) =>
    apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.AppointResponsible), { tenderID, userID }),

  // TenderLot API
  indexLot: ({ lotID }) => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.Index), { lotID }),
  getTemplateFull: (payload) => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.getTemplateFull), { ...payload }),
  createLot: ({ tenderID, values }) =>
    apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.Create), { data: { tenderID, values } }),
  updateLot: ({ id, values }) => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.Update), { data: { id, values } }),
  deleteLot: ({ ids }) => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.Delete), { data: { ids } }),
  suggestContact: ({ q }) => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.ContactSuggestions), { q }),
  getLotTemplate: () => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.GetLotTemplateEnum)),
  getAccountUsers: () => apiInstance().get(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.GetAccountUsers)),

  // TenderOffer API
  chooseWinnerLot: ({ id }) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.Accept), { data: { id } }),

  // TenderParticipants API
  createParticipant: ({ tenderID, ids }) =>
    apiInstance().post(getAPIURL(Dictionary.TenderParticipants, DictionariesEndpoint.Create), {
      tenderID,
      accountID: ids
    }),
  deleteParticipant: ({ tenderID, ids }) =>
    apiInstance().post(getAPIURL(Dictionary.TenderParticipants, DictionariesEndpoint.Delete), {
      tenderID,
      accountID: ids
    }),
  suggestParticipant: ({ q }) => apiInstance().post(getAPIURL(Dictionary.TenderParticipants, DictionariesEndpoint.Suggestions), { q }),

  // TenderCarrier API
  getCarriers: ({ ids = [] }) => apiInstance().post(getAPIURL(Dictionary.TenderCarrier, DictionariesEndpoint.Index), { data: { ids } }),
  addCarriers: ({ ids }) => apiInstance().post(getAPIURL(Dictionary.TenderCarrier, DictionariesEndpoint.Create), { data: { ids } }),
  deleteCarriers: ({ ids }) => apiInstance().post(getAPIURL(Dictionary.TenderCarrier, DictionariesEndpoint.Delete), { data: { ids } }),
  searchCarriers: ({ q }) => apiInstance().post(getAPIURL(Dictionary.TenderCarrier, DictionariesEndpoint.Suggestions), { q }),

  // Address API
  suggestAddress: ({ q }) => apiInstance().post(getAPIURL(Dictionary.Address, DictionariesEndpoint.Suggestions), { q })
};

