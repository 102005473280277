import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import ILSReferenceDescriptionComponent from '../components/reference-description';
import { FC } from 'react';
import { IReferenceLevel } from '@modules/reference/types/store';
import { ExpandIconPosition } from '@components/data-display/collapse/types';

interface PanelHeaderProps {
  name: string;
  getArticles: (params: { cat1: number; cat2?: number; cat3?: number }) => void;
  cat1ID: number;
  cat2ID?: number;
  cat3ID?: number;
}

const PanelHeader: FC<PanelHeaderProps> = ({ name, getArticles, cat1ID, cat2ID, cat3ID }) => {
  const onClick = (cat1ID: number, cat2ID?: number, cat3ID?: number) => {
    cat1ID && getArticles({ cat1: cat1ID, cat2: cat2ID, cat3: cat3ID });
  };

  return (
    <div className="panel-header" onClick={() => onClick(cat1ID, cat2ID, cat3ID)}>
      {name}
    </div>
  );
};

interface ILSReferenceCollapseComponentProps {
  level1: IReferenceLevel;
  level2: IReferenceLevel;
  level3: IReferenceLevel;
  getArticles: (params: { cat1: number; cat2?: number; cat3?: number }) => void;
}

const ILSReferenceCollapseComponent: FC<ILSReferenceCollapseComponentProps> = (props) => {
  const { level1, level2, level3, getArticles } = props;

  const onChange = () => {
    setTimeout(function () {
      let x = document.querySelectorAll(
        '.ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-item-active:not(.paneldesc):not(:first-child)>.ant-collapse-header'
      );

      if (x.length) {
        for (let i = 0; i < x.length; i++) {
          // @ts-ignore
          x[i].click();
        }
      }
    }, 100);
  };

  const content = level1.categories.map((item, key) => (
    <ILSCollapsePanel key={'panel' + key} header={<PanelHeader name={item.name} cat1ID={item.id} getArticles={getArticles} />}>
      <ILSCollapse defaultActiveKey={['level1desc']} accordion expandIconPosition={ExpandIconPosition.Right} onChange={onChange}>
        {level1.article && (
          <ILSCollapsePanel header="Описание" key={'level1desc'}>
            <ILSReferenceDescriptionComponent article={level1.article} />
          </ILSCollapsePanel>
        )}
        {level2.categories &&
          level2.categories.map((subItem, subKey) => (
            <ILSCollapsePanel
              key={'subpanel' + subKey}
              header={<PanelHeader name={subItem.name} cat1ID={item.id} cat2ID={subItem.id} getArticles={getArticles} />}
            >
              <ILSCollapse defaultActiveKey={['level2desc']} accordion expandIconPosition={ExpandIconPosition.Right} onChange={onChange}>
                {level2.article && (
                  <ILSCollapsePanel header="Описание" key={'level2desc'}>
                    <ILSReferenceDescriptionComponent article={level2.article} />
                  </ILSCollapsePanel>
                )}
                {level3.categories &&
                  level3.categories.map((subItem2, subKey2) => (
                    <ILSCollapsePanel
                      key={'subpanel' + subKey2}
                      header={
                        <PanelHeader
                          name={subItem2.name}
                          cat1ID={item.id}
                          cat2ID={subItem.id}
                          cat3ID={subItem2.id}
                          getArticles={getArticles}
                        />
                      }
                    >
                      <ILSReferenceDescriptionComponent article={level3.article!} />
                    </ILSCollapsePanel>
                  ))}
              </ILSCollapse>
            </ILSCollapsePanel>
          ))}
      </ILSCollapse>
    </ILSCollapsePanel>
  ));

  return (
    <>
      <ILSCollapse className="main-collapse" onChange={onChange} bordered={false} accordion>
        {content}
      </ILSCollapse>
    </>
  );
};

export default ILSReferenceCollapseComponent;
