import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { planningCreateProjectRoutine } from '@modules/planning/actions';
import { CATALOG_RELOAD } from '@core/constants/core';
import { ReloadEnum } from '@core/types/constants';

export const createProjectReducer = createReducer(initialState, {
  [planningCreateProjectRoutine.REQUEST]: onRequest,
  [planningCreateProjectRoutine.SUCCESS]: (state) => {
    state.created = true;
    localStorage.setItem(CATALOG_RELOAD, ReloadEnum.YES);
    return state;
  },
  [planningCreateProjectRoutine.FAILURE]: onFailure,
  [planningCreateProjectRoutine.FULFILL]: onFulfill
});
