import { MutableRefObject, useState } from 'react';
import { ILSMap } from '@core/containers/map';

export const useRuler = ({ Map }: { Map: MutableRefObject<ILSMap | null> }) => {
  const [rulerIsActive, setRulerIsActive] = useState(false);

  const triggerRuler = () => {
    if (Map.current) {
      Map.current.toggleRuler();
      setRulerIsActive(Map.current.rulerIsActive());
    }
  };

  return { rulerIsActive, triggerRuler };
};

