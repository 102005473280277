import { ILSTable } from '@common/components';
import { tableEventDetailsMainConfig } from '@common/table-configs/monitoring-event-details/main-table';
import { data2table } from '@common/table-configs/monitoring-event-details/main-table/decorators';
import { Dictionary, IILSTableProps } from '@common/types';
import { FC } from 'react';
import { tablePropConfig } from '../../configs/table';
import { MonitoringDetailsContextType } from '../../types/details-context';

interface Props extends Omit<IILSTableProps<any>, 'config'> {
  activeVehicleID: number | string;
  handleDoubleClick: MonitoringDetailsContextType['handleDoubleClick'];
  decoratedData: ReturnType<typeof data2table>;
}

// Компонент для отображения основной таблицы в детализации событий
export const MainTableComponent: FC<Props> = ({
  onSelectRows,
  handleDoubleClick,
  activeVehicleID,
  handleSelectedElements,
  rowClass,
  decoratedData,
  ...props
}) => {
  return (
    <div className={'position-relative h-100 catalog-item'}>
      <div className="ils-monitoring-details-main-table position-relative">
        <ILSTable
          {...props}
          {...tablePropConfig}
          onSelectRows={onSelectRows}
          config={tableEventDetailsMainConfig}
          dataSource={decoratedData}
          size={'small'}
          className="editable-table"
          handleSelectedElements={handleSelectedElements}
          focusRow={
            activeVehicleID && decoratedData.length
              ? {
                  key: activeVehicleID.toString()
                }
              : undefined
          }
          bordered
          tableName={Dictionary.EventDetails}
          dictionary={Dictionary.EventDetails}
          onTableRowDoubleClick={handleDoubleClick}
          rowClass={rowClass}
        />
      </div>
    </div>
  );
};
