import { Dispatch, SetStateAction, useEffect } from 'react';
import { Project } from '@common/types';
import { LastUpdate } from '@modules/catalog/types/catalog';

/* Хук позволяет отслеживать значение projectID, и если значение false, фильтр по проекту выключается.
 * напр. после добавления новой строки в каталог необходимо сбрасывать фильтр по проекту.
 */

export const useUncheckCatalogProjectButton = ({
  lastUpdate,
  projectID,
  setChecked
}: {
  lastUpdate: LastUpdate;
  projectID: Project['ID'] | boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    if (lastUpdate && !projectID) {
      setChecked(false);
    }
  }, [projectID]);
};

