import { Dictionary } from '@common/types';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { monitoringCatalogSelector } from '../selectors/catalog';
import { isEmpty } from 'lodash';
import { UPDATE_INTERVAL } from '@common/constants';
import { monitoringCatalogReadRoutine } from '../actions';

export const monitoringUseDictionaryLoadHandler = () => {
  const dispatch = useAppDispatch();
  const catalogData = useAppSelector(monitoringCatalogSelector);
  const refCatalogData = useRef(catalogData);

  useEffect(() => {
    refCatalogData.current = catalogData;
  }, [catalogData]);

  /**
   * Проверяет загружены ли данные по справочнику, если нет - запросит их
   */
  const handleDictionaryLoad = useCallback((dictionary: Dictionary) => {
    if (
      dictionary &&
      (isEmpty(refCatalogData.current?.[dictionary]) ||
        !refCatalogData.current?.[dictionary]?.lastUpdate ||
        refCatalogData.current[dictionary].lastUpdate + Number(UPDATE_INTERVAL) < new Date().getTime())
    ) {
      dispatch(monitoringCatalogReadRoutine({ dictionary }));
    }
  }, []);

  return { handleDictionaryLoad, refCatalogData, catalogData };
};
