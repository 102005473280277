import { extraVehicleTypesSelector } from '@modules/orders-and-trips/selectors/extra-data';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getVehicleTypesRoutine } from '@modules/orders-and-trips/actions';
import { isEmpty } from 'lodash';
import { vehicleTypesToForm } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';

export const useVehicleTypes = () => {
  const dispatch = useDispatch();

  const vehicleTypes = useSelector(extraVehicleTypesSelector);

  useEffect(() => {
    if (isEmpty(vehicleTypes)) {
      dispatch(getVehicleTypesRoutine());
    }
  }, []);

  return { vehicleTypes: vehicleTypesToForm(vehicleTypes) };
};
