import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { monitoringDateRoutine } from '@modules/monitoring/actions';
import ILSMonitoringDeliveryComponent from '../components';
import '../styles.less';
import { useHistory } from 'react-router';
import { ModulePath } from '@common/types';
import { MonitoringQueryParams } from '@modules/monitoring/constants';
import qs from 'query-string';
import { useLoadMetrics } from '@modules/monitoring/hooks/use-load-metrics';

const ILSMonitoringDelivery = () => {
  const dispatch = useAppDispatch();
  const { maxInterval } = useAppSelector(monitoringDateSelector);

  const history = useHistory();

  const handleRedirect = (name: string) => {
    const url = qs.stringifyUrl({
      url: `${ModulePath.Monitoring}${ModulePath.MonitoringDashboard}`,
      query: {
        [MonitoringQueryParams.TAB_NAME]: name
      }
    });

    history.push(url);
  };

  const handleDateSelect = (date: string[]) => {
    dispatch(
      monitoringDateRoutine({
        date,
        metricsInterval: maxInterval
      })
    );
  };

  useLoadMetrics();

  return <ILSMonitoringDeliveryComponent handleDateSelect={handleDateSelect} handleRedirect={handleRedirect} />;
};

export default ILSMonitoringDelivery;
