import { Statistic, StatisticProps } from 'antd';
import { FC } from 'react';
import { countdownValueType } from 'antd/lib/statistic/utils';

const { Countdown } = Statistic;

export const ILSStatistic: FC<StatisticProps> = (props) => {
  return <Statistic {...props} />;
};

export const ILSCountdown: FC<
  StatisticProps & {
    value?: countdownValueType;
    format?: string;
    onFinish?: () => void;
    onChange?: (value?: countdownValueType) => void;
    valueStyle?: object;
  }
> = ({ className, ...props }) => {
  return <Countdown className={className} {...props} />;
};
