import { FC } from 'react';
import { ILSTypographyTitle } from '@components/general/typography';
import { Dictionary } from '@common/types';
import { SchemaSwitcherEntries } from '@modules/planning/children/scheme-editor/types';
import { useSelector } from 'react-redux';
import { isFetchingSelector } from '@modules/planning/children/scheme-editor/selectors';
import { PlannerDragNDropSchemeUploadContainer } from '@modules/planning/children/scheme-editor/containers/upload';
import { ILSDivider, ILSFormItem, ILSSwitch } from '@common/components';
import { ALWAYS_VISIBLE_SWITCHER } from '@modules/planning/children/scheme-editor/constants';

interface IProps {
  schemes: SchemaSwitcherEntries;

  onChange(checked: boolean, event: Event, tab: Dictionary): void;
}

export const SchemaEditorIntro: FC<IProps> = ({ schemes, onChange }) => {
  const isFetching = useSelector(isFetchingSelector);
  return (
    <section className={'scheme-editor-intro'}>
      <PlannerDragNDropSchemeUploadContainer isFetching={isFetching} />
      <ILSTypographyTitle level={5} children={'Для начала работы отметьте нужные разделы, затем выберите раздел вверху страницы.'} />
      <ILSDivider />
      <ul className={'scheme-editor-intro-list'}>
        {schemes.map(([scheme, checked]) => {
          if (scheme !== ALWAYS_VISIBLE_SWITCHER) {
            const handleChange = (checked: boolean, event: Event) => onChange(checked, event, scheme);

            return (
              <ILSFormItem name={[scheme, 'active']} key={scheme} className={'scheme-editor-intro-list-item'}>
                <ILSSwitch
                  checked={checked}
                  className={'scheme-editor-intro-list-item-option'}
                  onChange={handleChange}
                  checkedChildren={scheme}
                  unCheckedChildren={scheme}
                  title={scheme}
                />
              </ILSFormItem>
            );
          }
        })}
      </ul>
    </section>
  );
};
