import { Empty } from 'antd';
import { FC } from 'react';

export const ILSEmptyDataComponent: FC = () => {
  return (
    <Empty
      className="planning-data-view-empty"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={`Данный раздел предназначен для отображения загруженных, но не сохраненных данных.`}
    />
  );
};
