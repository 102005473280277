import { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export const useFilterSearchModeCheckbox = (initial: boolean) => {
  const [strictSearch, setStrictSearch] = useState(() => initial || false);
  const handleStrictSearchMode = (e: CheckboxChangeEvent) => {
    setStrictSearch(e.target.checked);
  };

  return { strictSearch, handleStrictSearchMode };
};
