import moment from 'moment';
import { FC } from 'react';
import { TripTimelineType } from '@modules/orders-and-trips/types/trip';
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general';
import {
  TRIP_TIMELINE_ACTION,
  TRIP_TIMELINE_CREATED,
  TRIP_TIMELINE_CREATOR,
  TRIP_TIMELINE_HEADER
} from '@modules/orders-and-trips/constants/general';

type IProps = {
  timelineEvents?: Array<TripTimelineType>; /* Список событий по заявке */
};	

export const TripTimeline: FC<IProps> = ({ timelineEvents }) => {
  return (
    <div className="trip-timeline">
      <h2>{TRIP_TIMELINE_HEADER}</h2>
      <div className="trip-timeline__headers flex-between-center">
        <span className="trip-timeline__created">{TRIP_TIMELINE_CREATED}</span>
        <span className="trip-timeline__title">{TRIP_TIMELINE_ACTION}</span>
        <span className="trip-timeline__creator">{TRIP_TIMELINE_CREATOR}</span>
      </div>
      <div className="trip-timeline__rows">
        {timelineEvents?.map((timeline) => {
          const createdDateTime = moment(timeline.created).format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT);
          return (
            <div className="flex-between-center" key={timeline.created}>
              <span className="trip-timeline__created">{createdDateTime}</span>
              <span className="trip-timeline__title">{timeline.title}</span>
              <span className="trip-timeline__creator">{timeline.creator?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

