import { calcFormula } from '@modules/catalog/helpers';
import { getFormulaForCalculation } from '@modules/catalog/helpers/tariff/parse-tariff';
import { useCallback, useState } from 'react';
import { MIN_TARIFF_VALUE } from '@modules/catalog/components/tariff/constants/general';

type UseCheckTariffContent = {
  rawFormula: string;
  formulaParams: Record<string, { name: string }>;
};

export const useCheckTariffContent = ({ formulaParams, rawFormula }: UseCheckTariffContent) => {
  const [params, setParams] = useState(() => {
    if (formulaParams) {
      return Object.keys(formulaParams).reduce((result, key) => {
        result[key] = String(MIN_TARIFF_VALUE);
        return result;
      }, {});
    }
    return {};
  });

  const onSetParams = useCallback(
    (paramkey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setParams((prevParams) => {
        const currentParams = { ...prevParams };
        currentParams[paramkey] = !e.target.value ? MIN_TARIFF_VALUE : Number(e.target.value);
        return currentParams;
      });
    },
    []
  );

  const forCalculation = getFormulaForCalculation(params, rawFormula);
  const calculationResult = calcFormula(forCalculation);

  return { calculationResult, params, onSetParams };
};

