import { useModal } from '@common/hooks/components/use-modal';
import { useState } from 'react';
import { TenderFromPlannerOperationButton } from '@modules/tender-platform/constants';
import { TenderOperations, TenderOperationsArgument } from '@modules/tender-platform/constants/content';

export const useTenderPlannerOperationModal = () => {
  const { visible, handleShow } = useModal();

  const [operation, setOperation] = useState<TenderOperations>(TenderFromPlannerOperationButton.Save);

  const handleModal = ({ operation }: TenderOperationsArgument) => {
    setOperation(operation);
    handleShow();
  };
  return { visible, operation, handleModal, handleShow };
};
