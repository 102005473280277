import { call, put, takeLatest } from 'redux-saga/effects';
import { RoadGraphTest } from '@modules/road-graph/route-test/api';
import { roadGraphGetForbiddenZonesRoutine } from '@modules/road-graph/route-test/actions';

function* roadGraphGetForbiddenZonesWorker() {
  const { request, success, failure, fulfill } = roadGraphGetForbiddenZonesRoutine;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(RoadGraphTest.getForbiddenZones, {});

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* roadGraphGetForbiddenZonesWatcher() {
  yield takeLatest(roadGraphGetForbiddenZonesRoutine.trigger, roadGraphGetForbiddenZonesWorker);
}
