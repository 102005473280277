import { call, put, takeLatest } from 'redux-saga/effects';
import { updateTripRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* updateTripWorker(
  action: PayloadAction<{
    tripID: number;
  }>
) {
  const { request, success, failure, fulfill } = updateTripRoutine;
  const { tripID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripsData, { tripID });
    yield put(success({ tripID, data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* updateTripWatcher() {
  yield takeLatest(updateTripRoutine.trigger, updateTripWorker);
}
