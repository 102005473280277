import { FC } from 'react';
import '@modules/analytics/planning/children/planning-analytics/styles.less';
import BoardWithSettings from './board-with-settings';

interface Props {
  title: string;
}

const ILSAnalyticsPlanningComponent: FC<Props> = (props) => {
  return <BoardWithSettings {...props} />;
};

export default ILSAnalyticsPlanningComponent;
