import { ILSButton, ILSTooltip, ILSTypographyText } from '@components/index';
import { Scope } from '@core/containers/scope';
import { useAppSelector } from '@core/hooks';
import { ModuleScope } from '@core/types';
import { siderMenuRenderer } from '@modules/sidebar/helpers';
import { useContext } from 'react';
import CustomScroll from 'react-customscroll';
import { ReactSVG } from 'react-svg';
import { AppContext } from '../../../App';
import '../styles.less';
import { Module, Props } from '@modules/sidebar/types/component';
import { planningSettingsSelector } from '@modules/settings/selectors';

const ILSSidebar = (props: Props) => {
  const planningSettings = useAppSelector(planningSettingsSelector);
  const isValidateOnImport = Boolean(planningSettings?.parameters?.validateOnImport);

  const version = process.env.REACT_APP_BUILD;
  const { rules, roles } = useContext(AppContext) ?? {};

  const renderer = (modules: Module[], props: Props) => siderMenuRenderer(modules, props, isValidateOnImport);

  return (
    <div className="ils-sidebar">
      <div className="menu">
        <ILSButton type="primary" onClick={props.toggleCollapsed}>
          <ReactSVG src={'/assets/icons/sidebar/menu-' + (props.collapsed ? 'open' : 'close') + '.svg'} />
        </ILSButton>
        <CustomScroll>
          <Scope name={ModuleScope.SiderNav} renderer={renderer} extraProps={{ ...props, rules, roles }} />
        </CustomScroll>
        {version && (
          <ILSTooltip title={`Версия ПО: ${version}`}>
            <ILSTypographyText className={'ils-sidebar-version'} ellipsis>
              {version}
            </ILSTypographyText>
          </ILSTooltip>
        )}
      </div>
    </div>
  );
};

export default ILSSidebar;

