import { SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';
import { DateTimeFormat, IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel, MonitoringFuelDrainDecoratorReturnedModel } from '@common/types/dictionaries/monitoring-vehicle';
import { timestampToDatetimeString } from '@common/utils';

import { viewedContent } from '@common/table-configs/monitoring-dashboard/common/cell-viewed-content';
import { MONITORING_IMEI_DT_SEPARATOR } from '@modules/monitoring/children/dashboard/constants';
import { isObject } from 'lodash';

type DataToTableFunction<R> = (arg: {
  dataSource: Array<MonitoringBDDAndFuelDrainModel> | IndexedArray<MonitoringBDDAndFuelDrainModel>;
  handleView?: (id: string | number | string[] | number[], value: 0 | 1) => void;
}) => Array<
  R & {
    key: string;
    viewed: 0 | 1;
    dependentKey: string;
  }
>;
export const FUEL_POINT_TYPES_DRAIN = [2, 3];

export const data2table: DataToTableFunction<MonitoringFuelDrainDecoratorReturnedModel> = ({ dataSource, handleView }) => {
  const res: ReturnType<typeof data2table> = [];
  const vehicleData = isObject(dataSource) ? Object.values(dataSource) : dataSource;
  const viewedCell = viewedContent(1, handleView, 'dependentKey');
  const notViewedCell = viewedContent(0, handleView, 'dependentKey');

  if (!vehicleData.length) {
    return res;
  }

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (!vehicle?.VehicleTracker || !Object.keys(vehicle.VehicleTracker || {})?.length) {
      continue;
    }

    for (const trackerIndex in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerIndex];

      if (!tracker.FuelKeyPoints || !Object.keys(tracker.FuelKeyPoints)?.length) {
        continue;
      }
      let prevDT = tracker.From;
      for (const fuelKeyPointsIndex in tracker.FuelKeyPoints) {
        const fuelData = tracker.FuelKeyPoints[fuelKeyPointsIndex];
        // TODO: для определения скорости в момент слива топлива нужна функция: которая сравнивает по IMEI и DT события в FuelKeyPoints и в Tracks. При совпадении выводит скорость.

        if (fuelData && isObject(fuelData)) {
          const viewed: 0 | 1 = fuelData?.Viewed || 0;
          const dependentKey = fuelData.IMEI + MONITORING_IMEI_DT_SEPARATOR + fuelData.DT;
          const EventTime = timestampToDatetimeString(fuelData.DT, true, DateTimeFormat.HHmmssDMMYY);
          let rowObj = {
            ID: vehicle.ID,
            VehicleID: vehicle.ID,
            Name: vehicle.Name,
            Number: vehicle.RegNumber || '',
            DriverName: vehicle?.Driver?.Name || '',
            Phone: vehicle?.Driver?.Phone || '',
            Speed: '',
            rowKey: `${fuelData.IMEI}#${fuelData.DT}`,
            key: `${fuelData.IMEI}#${fuelData.DT}`,
            TimeInterval: prevDT ? [prevDT * SECONDS_TO_MILLISECONDS_MULTIPLIER, fuelData.DT * SECONDS_TO_MILLISECONDS_MULTIPLIER] : [],
            EventTime,
            FuelDrain: (fuelData?.fuelafter - fuelData?.fuelbefore)?.toFixed(2) || undefined,
            Viewed: viewed ? viewedCell : notViewedCell,
            dependentKey,
            viewed,
            Favourite: 1
          };
          prevDT = fuelData.DT;
          if (FUEL_POINT_TYPES_DRAIN.includes(fuelData.pointtype) && fuelData?.fuelbefore - fuelData?.fuelafter > 0) {
            res.push(rowObj);
          }
        }
      }
    }
  }

  return res;
};
