import { ILSTimePickerStatic } from '@components/custom/data-display/time-picker';
import { ILS_MAIN_API_TIME_FORMAT } from '@common/types/general/date-time';
import { IILSCellTimeStringProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { isCellTimeStringChildrenValid } from '@components/data-display/table/utils/helpers/cells/is-cell-time-string-children-valid';
import { parseTimeFromServerToTimeString } from '@common/utils/helpers/date-time/parsers';

export const ILSCellTimeString = <R extends TableRecord>({
  children,
  double = false,
  toggleEdit,
  dateParseFormat = ILS_MAIN_API_TIME_FORMAT
}: IILSCellTimeStringProps<R>): JSX.Element => {
  const [node, time] = children;

  if (!isCellTimeStringChildrenValid(time, dateParseFormat, double)) {
    return <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={undefined} />;
  }

  return (
    <ILSTimePickerStatic
      className={'cell-wrapper-editable'}
      onClick={toggleEdit}
      children={[node, parseTimeFromServerToTimeString({ time, parseFormat: dateParseFormat, double })]}
    />
  );
};
