import { Dictionary, TableName, TableRecord } from '@common/types';
import { compareAsString } from '@common/utils';
import { planningGetPlanRoutine, planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { FocusNExpandRowsChangingType } from '@modules/planning/children/by-date/types/tables-container';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useActiveFocusRow = () => {
  const dispatch = useDispatch();
  const activePlanID = useSelector(activePlanIDSelector);

  const onTableRowClick = useCallback(
    (record: TableRecord, expand?: boolean, ctrl?: boolean, tableName?: TableName) => {
      switch (record.dictionary) {
        case Dictionary.Plan:
          if (!compareAsString(activePlanID, record.ID)) {
            dispatch(planningGetPlanRoutine({ ID: record.ID }));
            /** при переключении планов сбрасываем сохраненную сфокусированную строку, 
             * чтобы в новом плане установилась по умолчанию на первую */
            dispatch(
              planningRememberFocusNExpandRowsRoutine.success({
                tableName: TableName.RoutesTable,
                changingType: FocusNExpandRowsChangingType.UnsetFocus
              })
            );
          }
          break;
        default:
          dispatch(
            planningRememberFocusNExpandRowsRoutine.success({
              tableName,
              ID: record.key ?? record.ID,
              changingType: FocusNExpandRowsChangingType.Focus
            })
          );
      }
    },
    [activePlanID]
  );

  return { onTableRowClick };
};

