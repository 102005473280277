import { FC, useCallback } from 'react';
import { ILSAlert, ILSModal } from '@components/index';
import { useSelector } from 'react-redux';
import { tariffsParserSelector } from '@modules/catalog/selectors/tariffs';
import { calcFormula, parseTariff } from '@modules/catalog/helpers';
import { useModal } from '@common/hooks/components/use-modal';
import { CheckFormulaParameters } from '@modules/catalog/types/tariff';
import { DEFAULT_INITIALIZATION_FUCTION, EMPTY_STRING } from '@common/constants';
import { CheckTariffContent } from './check-tariff-content';
import { Notify } from '@common/types';

interface IProps {
  modal?: CheckFormulaParameters['modal'];
  showFormula: CheckFormulaParameters['showFormula'];
  name: CheckFormulaParameters['name'];

  setCheckFormula?(p: CheckFormulaParameters | null): void;
}

export const CatalogTariffFormulaAndResultContainer: FC<IProps> = ({
  modal,
  showFormula = EMPTY_STRING,
  name = EMPTY_STRING,
  setCheckFormula = DEFAULT_INITIALIZATION_FUCTION
}) => {
  const tariffsParser = useSelector(tariffsParserSelector);
  const { forCalculation, viewFormula, formulaParams } = parseTariff(showFormula, tariffsParser);
  const { visible, handleShow } = useModal(true);

  const handleCancel = useCallback(() => {
    handleShow();
    setCheckFormula(null);
  }, []);

  if (!modal) {
    const calculationResult = calcFormula(forCalculation);
    return (
      <div className="mt-2 mr-2">
        <div className="bg-white p-3 tariff-field bordered rounded">
          <div dangerouslySetInnerHTML={{ __html: viewFormula }} />
        </div>
        <ILSAlert
          type={!calculationResult.check ? Notify.Error : Notify.Success}
          message={`Результат вычисления: ${calculationResult.message}`}
        />
      </div>
    );
  }

  return (
    <ILSModal title={name} visible={visible} onOk={handleCancel} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
      <CheckTariffContent formulaParams={formulaParams} viewFormula={viewFormula} rawFormula={showFormula} />
    </ILSModal>
  );
};

