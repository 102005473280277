import { LatLng, LatLngExpression, LatLngLiteral, LatLngTuple } from 'leaflet';
import { isArray, isNumber } from 'lodash';

export type Latitude = LatLngLiteral['lat'] | LatLng['lat'];
export type Longitude = LatLngLiteral['lng'] | LatLng['lng'];
export type PrecisionType = number;
export type GeohashType = string;
export type GeoCoordType = LatLngTuple;
export type ILSAPILatLonLiteral = { lat: Latitude; lon: Longitude };
export type Coordinates = ILSAPILatLonLiteral | LatLngExpression | LatLngExpression[] | GeohashType;
export type PolylineCoordinates = Array<LatLngExpression[]> | GeohashType | Array<LatLngExpression>;

export const isLatLngTuple = (latLon: unknown): latLon is LatLngTuple => {
  return isArray(latLon) && latLon.length === 2 && latLon.every(isNumber);
};

export type LatLngObject = LatLng | LatLngLiteral;

export enum hashType {
  GR = 'GR',
  GW = 'GW',
  SL = 'SL',
  TZ = 'TZ',
  TL = 'TL',
  PA = 'PA'
}

export type hashTypeString = keyof typeof hashType;

export interface IPoint {
  lat: Latitude;
  lon: Longitude;
  speed?: number;
}

export interface IGeohash {
  minmax: {
    [key in hashTypeString]: Array<IPoint>;
  };
  precision: {
    [key in hashTypeString]: number;
  };
  base32: string;
}

