import '../styles.less';
import { FC, useEffect } from 'react';
import { dataSelector } from '../selectors';
import { vehicleMonitoringIndexRoutine } from '@modules/vehicle-monitoring/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '@common/hooks/history/use-search-params';
import { VehicleMonitoringMapContainer } from '@modules/vehicle-monitoring/containers/map';

const ILSVehicleMonitoring: FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const view = useSearchParams('view');

  useEffect(() => {
    if (view) {
      const interval = setInterval(() => {
        dispatch(vehicleMonitoringIndexRoutine({ view }));
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [view, data]);

  return (
    <div className="vehicle-monitoring-content">
      <VehicleMonitoringMapContainer data={data} />
    </div>
  );
};

export default ILSVehicleMonitoring;
