import '../styles.less';
import { ILSButton, ILSDragger, ILSTypographyText } from '@components/index';
import { FC, ReactNode } from 'react';
import { UploadProps } from 'antd';
import cn from 'classnames';
import { JoinChar } from '@common/constants';
import { UploadButton } from '@modules/planning/children/data/types';
import { ILSTypographyParagraph, ILSTypographyTitle } from '@components/general/typography';
import { SchemeDictionary } from '@modules/planning/children/scheme-editor/types';
import { PlannerFunctions } from '@modules/planning/types/components';

interface IProps extends UploadProps, Pick<PlannerFunctions, 'handleUpload'> {
  title?: SchemeDictionary | ReactNode;
  isFetching: boolean;
  handleChange?: UploadProps['onChange'];
  button: Array<UploadButton>;
  defaultPermittedTypes: Array<string>;
  defaultFormats: Array<string>;
}

export const PlannerDragNDropDocumentUploadComponent: FC<IProps> = ({
  isFetching,
  fileList,
  handleChange,
  accept,
  button,
  defaultFormats,
  defaultPermittedTypes,
  title,
  handleUpload
}) => {
  const text = button.map(({ text }) => text).join(JoinChar.Comma);
  const className = button.map(({ className }) => className).join(JoinChar.Comma);

  const beforeUpload = (file: File) => {
    const type = typeof title === 'string' ? (title as SchemeDictionary) : null;
    handleUpload({ file, type });
  };

  return (
    <ILSDragger
      defaultFormats={defaultFormats}
      defaultPermittedTypes={defaultPermittedTypes}
      className={cn('planner-upload-drag-n-drop', className)}
      showUploadList={false}
      fileList={fileList}
      onChange={handleChange}
      disabled={isFetching}
      beforeUpload={beforeUpload}
      name="file"
      accept={accept}
    >
      <ILSTypographyParagraph>
        <ILSTypographyTitle level={5} children={title ?? 'Шаблон импорта'} />
        <ILSTypographyText
          className={'planner-upload-drag-n-drop-text'}
          children={`Перетащите файл ${text} сюда
      или`}
        />
      </ILSTypographyParagraph>
      <ILSButton
        className={cn('planner-upload-drag-n-drop-button', className)}
        loading={isFetching}
        disabled={isFetching}
        children={`Выберите файл на устройстве`}
        type={'primary'}
      />
      {fileList?.map((file) => (
        <p key={`${file.uid}`} className="ant-upload-hint">
          {file.name}
        </p>
      ))}
    </ILSDragger>
  );
};
