import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { couplingTypes2table } from '@common/models/coupling-type/decorators';
import { COUPLING_TYPE_TABLE_CONFIG } from '@common/models/coupling-type/config/table';

export const ILSCatalogCouplingType: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return <ILSCatalogTable {...props} dataDecorator={couplingTypes2table} dictionary={Dictionary.CouplingType} />;
  },
  COUPLING_TYPE_TABLE_CONFIG,
  CatalogDictionary.CouplingType,
  true
);
