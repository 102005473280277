import { deepEqual } from 'fast-equals';
import { useCallback, useState } from 'react';
import { OnLassoChange, SelectedOrdersLasso } from '../../types/map-container';

type UseMapLassoSelect = {};

type resetSelectLasso = () => void;

export const useMapLassoSelect = ({}: UseMapLassoSelect) => {
  const [selectedOrdersLasso, setSelectedOrdersLasso] = useState<SelectedOrdersLasso>();
  const onLassoChange: OnLassoChange = useCallback((keys, type, waypointIDs) => {
    setSelectedOrdersLasso((prev) => {
      const newSelected = { orderIDs: keys, type, waypointIDs } as SelectedOrdersLasso;
      if (!deepEqual(newSelected, prev)) {
        return newSelected;
      }
      return prev;
    });
  }, []);
  const resetLassoSelect: resetSelectLasso = useCallback(() => setSelectedOrdersLasso(undefined), []);
  return { selectedOrdersLasso, onLassoChange, resetLassoSelect };
};
