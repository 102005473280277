import { DemandTab, DemandTabType } from '../types/tabs';

// Массив с названиями табов для переключения между разными типами плашек
export const demandTabs: Array<DemandTab> = [
  {
    key: DemandTabType.All,
    label: 'Все'
  },
  {
    key: DemandTabType.TenderLot,
    label: 'Заявки'
  },
  {
    key: DemandTabType.Project,
    label: 'Проекты'
  }
];
