import { TenderLot } from '@modules/tender-platform/types';
import { EMPTY_STRING, NOT_ENTERED, RATING } from '@common/constants';
import { lotToForm } from '@modules/tender-platform/utils/decorators/lot-to-form';
import { parseDateFromServerToPicker } from '@common/utils/helpers/date-time/parsers';
import { CurrentTender } from '@modules/tender-platform/types/store';

export const tender2Form = (tender: CurrentTender = {} as CurrentTender) => {
  return {
    ...tender,
    Description: tender?.Description?.length ? tender?.Description : EMPTY_STRING,
    OfferStartDT: parseDateFromServerToPicker(tender?.OfferStartDT, true),
    OfferEndDT: parseDateFromServerToPicker(tender?.OfferEndDT, true),
    ExecutionStartDT: parseDateFromServerToPicker(tender?.ExecutionStartDT, true),
    ExecutionEndDT: parseDateFromServerToPicker(tender?.ExecutionEndDT, true),
    UserID: tender?.User?.Name ?? NOT_ENTERED,
    MinCarrierRating: tender?.MinCarrierRating ?? RATING.min,
    MinDriverRating: tender?.MinDriverRating ?? RATING.min
  };
};

export const tenderLotCurrent2Form = (
  tender: CurrentTender = {} as CurrentTender,
  lot: TenderLot | null = {} as TenderLot,
  creating: boolean = false
) => {
  return creating
    ? {
        MinCarrierRating: tender?.MinCarrierRating ?? RATING.min,
        MinDriverRating: tender?.MinDriverRating ?? RATING.min,
        ...lotToForm(lot ? lot : null)
      }
    : {
        ...tender2Form(tender),
        ...lotToForm(lot),
        ID: tender?.ID
      };
};
