import { FC } from 'react';
import { ILSSelect } from './select';
import { DictionariesInfo, Dictionary } from '@common/types';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';

interface IProps extends IILSSelectProps {
  dictionary: Dictionary;
  dictionaryLoad(dictionary: Dictionary): void;
  dictionaryInfo?: DictionariesInfo;
}

export const ILSSelectApi: FC<IProps> = ({ dictionary, dictionaryLoad, dictionaryInfo, placeholder, options, fieldNames, ...props }) => {
  const loading = dictionaryInfo?.[dictionary]?.isFetching;

  useApiOptions({ dictionary, dictionaryLoad });

  return (
    <ILSSelect
      {...props}
      labelInValue
      fieldNames={fieldNames}
      filterOption={filterOption(fieldNames)}
      placeholder={placeholder}
      options={options}
      allowClear
      showSearch
      loading={Boolean(loading)}
      className={'cell-wrapper-editable-full'}
    />
  );
};
