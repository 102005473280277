import { FC } from 'react';
import CustomScroll from 'react-customscroll';
import { isEmpty } from 'lodash';
import { ColumnTable, TableRecord } from '@common/types/components';
import { ILSCheckboxGroup } from '@common/components';
import { createCheckboxOptionsFromColumns } from '@core/helpers/create-checkbox-options-from-columns';
import { useGroupingButtons, useGroupingSorting } from '@core/hooks';

interface IProps {
  columns: ColumnTable<TableRecord>[];
  storedTableGrouping?: ColumnTable<TableRecord>['dataIndex'][];

  onGrouping?(colsForGrouping: ColumnTable<TableRecord>['dataIndex'][]): void;

  closeGroupingPopover(): void;
}

const TableCustomGroupingContainer: FC<IProps> = ({ columns, storedTableGrouping, onGrouping, closeGroupingPopover }) => {
  const groupingCheckboxOptions = createCheckboxOptionsFromColumns(columns);

  const {
    checkboxGroupValues,
    isResetButtonEnabled,
    handleChangeCheckboxValues,
    handleAcceptGrouping,
    handleResetGrouping,
    handleCancelGrouping
  } = useGroupingSorting({ storedTableGrouping, onGrouping, closeGroupingPopover });

  const { groupingButtons } = useGroupingButtons({
    handleAcceptGrouping,
    handleResetGrouping,
    handleCancelGrouping,
    isResetButtonEnabled
  });

  return (
    <div className="grouping-container" data-testid="grouping-container">
      <CustomScroll>
        {!isEmpty(groupingCheckboxOptions) && (
          <ILSCheckboxGroup
            value={checkboxGroupValues}
            onChange={handleChangeCheckboxValues}
            options={groupingCheckboxOptions}
            className="grouping-checkboxes"
          />
        )}
      </CustomScroll>
      <div className={'grouping-buttons'}>
        {groupingButtons.map(({ Component, props }, index) => (
          <Component key={`button-${index}`} {...props} />
        ))}
      </div>
    </div>
  );
};

export default TableCustomGroupingContainer;
