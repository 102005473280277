import { planningDeleteTenderForRouteRoutine } from '../../actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { Vehicle } from '@common/types';
import { tendersSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { Tender } from '@modules/tender-platform/types';
import { pickBy } from 'lodash';
import { PlanningAPI } from '@modules/planning/api';
import { ILSUserConfirm } from '@common/components';

function* deleteTenderForRouteWorker() {
  const { request, failure, fulfill, success } = planningDeleteTenderForRouteRoutine;

  try {
    yield put(request());
    const { selectedRoutesIDs }: { selectedRoutesIDs: Array<Vehicle['ID']> } = yield select(selectedRouteTableRowsSelector);
    const tenders: Record<Vehicle['ID'], Tender['ID']> = yield select(tendersSelector);

    const ids = pickBy(tenders, (tenderID, vehicleID) => {
      return selectedRoutesIDs.map(Number).includes(Number(vehicleID));
    });
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.deleteTenderFromPlanner, { ids: Object.values(ids) });

    const savedText = `${data}, тендеры успешно удалены`;

    ILSUserConfirm({ text: savedText, onCloseText: 'Ок' });
    yield put(success({ ids: selectedRoutesIDs }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteTenderForRouteWatcher() {
  yield takeEvery(planningDeleteTenderForRouteRoutine.trigger, deleteTenderForRouteWorker);
}
