import { AbacPermissions } from '@abac/types';
import { GlobalSpin } from '@common/components/feedback/spin/global-spin';
import { Account, ModuleTitle, UserRole } from '@common/types';
import { Titler } from '@common/utils/page-title';
import { accountSelector, currentUserSelector } from '@modules/current-user/selectors/auth';
import { ErrorBoundary } from '@pages/error-boundary';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import { createContext, FC } from 'react';
import { AbacProvider, AllowedTo } from 'react-abac';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { AppProps } from 'types';
import './App.less';
import AuthPage from './pages/auth/auth-page-container';
import ErrorPage from './pages/error/error-page-container';
import LimitedPage from './pages/limited/limited-page-container';
import { permissions as commonPermissions } from './abac/permissions';
import { isArray, isString } from 'lodash';
import { AppRoles } from '@common/types/general/general';
import { useToken } from '@core/hooks/use-token';
import { useYm } from '@core/hooks/use-ym';
import { getModulesAccess } from '@core/modules';
import { getPageComponent } from '@core/helpers/get-page-component';
import { getAppTitle } from '@core/helpers/get-app-title';

interface IAppContext {
  account: Account | null;
  permissions: {};
  rules: {};
  roles: AppRoles;
  title: string;
}

export const AppContext = createContext<IAppContext>({
  account: null,
  permissions: {},
  rules: {},
  roles: [UserRole.guest],
  title: ModuleTitle.Main
});

const App: FC<AppProps> = (props) => {
  const user = useSelector(currentUserSelector);
  const account = useSelector(accountSelector);
  const hasProjects = Boolean(account?.ProjectCount);

  useToken();
  useYm();

  const { rules, permissions, roles } = getModulesAccess(user, hasProjects);
  const { permission, PageComponent, route, location } = getPageComponent(props);

  const contextValue = {
    account,
    rules,
    permissions,
    roles,
    title: getAppTitle(props)
  };

  const renderAllowed = () => {
    let showSpin = true;

    //Так как у неавторизованных никогда не будет account и редиректа на авторизацию, то сразу render custom page
    if (
      (isString(permission) && permission === commonPermissions.NON_AUTH_PAGES) ||
      (isArray(permission) && permission.includes(commonPermissions.NON_AUTH_PAGES))
    ) {
      showSpin = false;
    }
    if (!account && showSpin) {
      return <GlobalSpin />;
    }
    return renderRoutes(props?.route?.routes);
  };

  return (
    <ConfigProvider locale={ruRU}>
      <AbacProvider user={user} roles={roles ?? [UserRole.guest]} rules={rules}>
        <Titler location={location!} route={props?.route!} currentRoute={route!} user={user} />
        <AppContext.Provider value={contextValue}>
          <AllowedTo
            yes={() => (
              <ErrorBoundary location={location!} route={props?.route!} currentRoute={route!} user={user}>
                {PageComponent ? (
                  <PageComponent {...props} /> //для кастомных страниц
                ) : (
                  renderAllowed()
                )}
              </ErrorBoundary>
            )}
            perform={permissions['MAIN_LAYOUT']}
            no={() =>
              permissions?.[permission] ? (
                <AllowedTo
                  perform={permissions?.[permission as keyof AbacPermissions]}
                  yes={renderAllowed}
                  no={() => (
                    <AllowedTo
                      perform={permissions['LIMITED_LAYOUT']}
                      yes={() => <LimitedPage />}
                      no={() => (user.UserRole && !user.UserRole.length ? <ErrorPage {...props} /> : <AuthPage {...props} />)}
                    />
                  )}
                />
              ) : (
                <AllowedTo
                  perform={permissions['LIMITED_LAYOUT']}
                  yes={() => <LimitedPage />}
                  no={() => (user.UserRole && !user.UserRole.length ? <ErrorPage {...props} /> : <AuthPage {...props} />)}
                />
              )
            }
          />
        </AppContext.Provider>
      </AbacProvider>
    </ConfigProvider>
  );
};

export default App;
