import { useAppSelector } from '@core/hooks';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  demandChangeActiveTabRoutine,
  demandGetCountsRunAction,
  demandGetCountsStopAction,
  demandGetListRoutine
} from '../actions';
import { DemandListComponent } from '../components/demand-list';
import { DEFAULT_REQUEST_PARAMS } from '../constants';
import { GetListRequest } from '../types/api';
import { HeaderCreateButton } from '../types/button';
import { DemandTabType } from '../types/tabs';
import { ILSDemandHeaderContainer } from './demand-header';
import qs from 'query-string';
import { AccountRole, ModulePath } from '@common/types';
import { ILSUserNotify } from '@common/components';
import { demandNotify } from '../configs/notify';
import { useTenderOpen } from '@modules/tender-platform/utils/hooks';
import { demandSelector } from '@modules/demand/selectors/components/demand';
import { useDispatch } from 'react-redux';

// Основной контейнер, который включает в себя заголовочный контейнер и список проектов и лотов
export const DemandContainer = () => {
  const dispatch = useDispatch();
  const { list, roles, activeTab, hasDesync, hintData, isAllUploaded, loadParams } = useAppSelector(demandSelector);

  const { push: pushHistory } = useHistory();
  const { redirectToTenderCreation } = useTenderOpen();


  useEffect(() => {
    dispatch(demandGetCountsRunAction());
    return () => {
      dispatch(demandGetCountsStopAction());
    };
  }, []);

  useEffect(() => {
    if (!list?.length) {
      const newLoadParams = {
        ...loadParams,
        ...DEFAULT_REQUEST_PARAMS,
        filter: { ...loadParams.filter, type: activeTab || DemandTabType.All }
      };
      updateDemandList(true, newLoadParams);
    } else if (activeTab && loadParams.filter?.type !== activeTab) {
      const newLoadParams = { ...loadParams, ...DEFAULT_REQUEST_PARAMS, filter: { ...loadParams.filter, type: activeTab } };
      updateDemandList(true, newLoadParams);
    }
  }, [activeTab]);

  const updateDemandList = (forceUpdate: boolean = false, newLoadParams?: GetListRequest) => {
    if (forceUpdate || !isAllUploaded) {
      dispatch(demandGetListRoutine({ ...newLoadParams, forceUpdate }));
    }
  };

  const loadNextDemands = () => {
    if (!isAllUploaded) {
      if (list.length && typeof loadParams.page === 'number') {
        dispatch(
          demandGetListRoutine({
            ...loadParams,
            page: loadParams.page + 1
          })
        );
      } else {
        updateDemandList();
      }
    }
  };

  const onChangeTab = (tab: string) => {
    if (tab in DemandTabType) {
      dispatch(demandChangeActiveTabRoutine({ activeTab: tab }));
    }
  };

  const buttonHandler: { [K in HeaderCreateButton]?: () => void } = {
    Tender: () => {
      if (roles?.length && roles.includes(AccountRole.cargoOwner)) {
        redirectToTenderCreation();
      } else {
        ILSUserNotify(...demandNotify.notPermittedRoleForCreate);
      }
    },
    Project: () => {
      const url = qs.stringifyUrl({
        url: `${ModulePath.Planner}${ModulePath.Data}`
      });
      pushHistory(url);
    }
  };

  return (
    <>
      <ILSDemandHeaderContainer
        hasDesync={hasDesync}
        onReload={() => updateDemandList(true, { ...loadParams, ...DEFAULT_REQUEST_PARAMS })}
        onLoad={(newLoadParams: GetListRequest) => updateDemandList(true, { ...loadParams, ...DEFAULT_REQUEST_PARAMS, ...newLoadParams })}
        activeKey={activeTab}
        onChange={onChangeTab}
        hintData={hintData}
        buttonHandler={buttonHandler}
      />
      <DemandListComponent list={list} loadNextDemands={loadNextDemands} />
    </>
  );
};

