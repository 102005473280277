import { Dictionary, Notify, PlanStatus } from '@common/types';
import { PlanningStatus } from '@modules/planning/types';

export const PLANNING_RESULT_ERRORS = {
  TRIP_IS_LOCK: {
    MESSAGE: 'Рейс заблокирован для изменений',
    TYPE: Notify.Warning,
    DURATION: 3,
    KEY: 'TripLockError'
  },
  WAYPOINT_IS_LOCK: {
    MESSAGE: 'Выбранная точка находится в заблокированном рейсе',
    TYPE: Notify.Warning,
    DURATION: 3,
    KEY: 'TripLockError'
  },
  CAR_NOT_USED_IN_PROJECT: {
    MESSAGE: 'Машина не используется в проекте',
    TYPE: Notify.Warning,
    DURATION: 3,
    KEY: 'TripLockError'
  },
  WAYPOINT_IN_TRIP: {
    MESSAGE: 'Точки уже находятся в рейсе',
    TYPE: Notify.Warning,
    DURATION: 2,
    KEY: 'planning'
  }
};

export const TABLE_WITH_MAP_DEPENDENCIES = [
  Dictionary.Depot,
  Dictionary.FriendZone,
  Dictionary.Zone,
  Dictionary.ForbiddenZone,
  Dictionary.CrossDock
];

export const TABLES_WITH_ORDER_DEPENDENCIES_UPDATE = [
  Dictionary.OrderCargo,
  Dictionary.Cargo,
  Dictionary.Depot,
  Dictionary.Contact,
  Dictionary.Restriction,
  Dictionary.Worktime
];
export const TABLES_WITH_DEPOT_DEPENDENCIES_UPDATE = [Dictionary.Worktime];

export const PLAN_STATUSES_FOR_UNSET = [PlanStatus.OnExecution, PlanStatus.TripWithDifferentStatuses];

export const DICTIONARIES_LIST_FOR_FOCUS_DBLCLICK = [Dictionary.Route, Dictionary.Trip, Dictionary.Waypoint];

export const SPLIT_COMPONENT_STATUSES = [PlanningStatus.Planning, PlanningStatus.Result];
export const PLAN_RESULT_BLOCK = 'PlanResultBlock';
export const PLAN_RESULT_BLOCK_MIN_PERCENT = [20, 10];
export const PLAN_RESULT_BLOCK_DEFAULT_PERCENT = [50, 50];
export const PLAN_RESULT_BLOCK_DEFAULT_VISIBLE = [true, true];
export const NOT_EDITABLE_ROW_DICTIONARIES = [Dictionary.Waypoint, Dictionary.OrderAction];
export const EDITABLE_STATUSES = [
  PlanStatus.Open,
  PlanStatus.ReorderingOnly,
  PlanStatus.AdditionsOnly,
  PlanStatus.Closed,
  PlanStatus.TripWithDifferentStatuses
];
