import { Dictionary, ITablePropsFunction, TableColumnName, TableRecord } from '@common/types';
import { ILSTable } from '@components/index';
import { canEditCatalogElement } from '@modules/catalog/helpers';
import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { catalogSelector, infoSelector } from '../selectors';
import { IPropsILSCatalogTable } from '../types/components';
import { swapToOriginalDictionary } from '../helpers/swap-catalog-dictionary';
import { useSelectedRows } from '@modules/catalog/hooks/table/use-selected-rows';
import { useTableHandlers } from '@modules/catalog/hooks/table/use-table-handlers';
import { useCatalogTableDataSourceSelector } from '../hooks/use-catalog-table-data-source-selector';
import { CatalogState } from '@modules/catalog/types/store';
import { getFocusRefRow } from '@components/data-display/table/utils/helpers/get-focus-ref-row';
import { CatalogRead } from '@modules/catalog/types/actions';
import { handleDictionaryLoad } from '@modules/catalog/helpers/table/handlers/handle-dictionary-load';
import { handleShowDeleted } from '@modules/catalog/helpers/table/handlers/handle-show-deleted';
import { useVirtualTableSettings } from '@common/hooks/use-virtual-table';

const rowSelection = {
  checkStrictly: false
};

export const ILSCatalogTable: FC<IPropsILSCatalogTable> = ({
  dataDecorator,
  handleDecorator,
  removeAddRow,
  setRemoveAddRow,
  onCheckSaveError,
  onCanEdit,
  saveDictionary,
  childrenDictionary = Dictionary.Empty,
  handleCustomCheckboxOnChange,
  ...props
}) => {
  const dispatch = useDispatch();
  const selectDict = catalogSelector();
  const dictionary = swapToOriginalDictionary(props.dictionary);
  const data = useSelector((state: CatalogState) => selectDict(state, props.dictionary, 'data'));
  const isFetching = useSelector((state: CatalogState) => selectDict(state, dictionary, 'isFetching'));
  const focusRow = useSelector((state: CatalogState) => selectDict(state, dictionary, 'focusRow'));
  const showDeleted = useSelector((state: CatalogState) => selectDict(state, dictionary, 'showDeleted'));
  const childrenData = useSelector((state: CatalogState) => selectDict(state, childrenDictionary, 'data'));
  const info = useSelector((state: CatalogState) => infoSelector(state));

  //NOTE: Хранит последнюю удаленную строку между рендерами
  const lastDeletedRowRef = useRef<{ index: null; id: null } | null>(null);

  const dataSource = props.dataSource ?? data;

  const { dataSourceForTable } = useCatalogTableDataSourceSelector({
    dataDecorator,
    data: dataSource,
    filterID: props?.filterID,
    childrenDictionary,
    childrenData,
    config: props?.config,
    tableName: props.tableName
  });

  const { selectedRows, onSelectRows } = useSelectedRows({ data, dictionary, handleCustomCheckboxOnChange });

  const { overscanRowCount } = useVirtualTableSettings({ deps: [dataSourceForTable] });

  const {
    handleTableCheckDelete,
    handleTableDelete,
    handleTableRestore,
    handleTableReload,
    handleTableCreate,
    handleTableSave,
    handleCopy
  } = useTableHandlers({
    onCheckSaveError,
    handleDecorator,
    dictionary,
    config: props.config,
    saveDictionary,
    filterID: props.filterID,
    setRemoveAddRow,
    dataSource,
    dataSourceForTable,
    lastDeletedRowRef,
    showDeleted
  });

  // Обновляем основной справочник
  useEffect(() => {
    const payload: CatalogRead = {
      dictionaryFetching: false,
      withoutNotification: true,
      dictionary,
      showDeleted,
      childrenDictionary
    };
    /** Если открытая таблица – вложенная (модальное окно с таблицей связанных моделей) */
    if (props.reference) {
      const { record, recordDataIndex, cellType } = props.reference;
      /** ставим фокус на строке соответствующей связанной модели основного справочника (ID) */
      payload.rowIDToFocus = getFocusRefRow({ record, recordDataIndex, cellType });
    }
    handleDictionaryLoad({ dispatch, payload })(dictionary);
  }, [dictionary]);

  const onCanEditCatalog: ITablePropsFunction<TableRecord>['onCanEdit'] = (record, dataIndex: TableColumnName) =>
    canEditCatalogElement(record, dataIndex, props?.config, onCanEdit);

  return (
    <ILSTable
      {...props}
      focusRow={focusRow}
      dictionary={dictionary}
      loading={Boolean(dictionary !== Dictionary.Zone && isFetching)}
      dataSource={dataSourceForTable}
      className="editable-table"
      handleSave={handleTableSave}
      handleReload={handleTableReload}
      handleCreate={handleTableCreate}
      handleDelete={handleTableDelete}
      onCanEdit={onCanEditCatalog}
      rowSelection={rowSelection}
      handleCheckDelete={handleTableCheckDelete}
      handleRestore={handleTableRestore}
      showDeleted={showDeleted}
      setShowDeleted={handleShowDeleted({ dictionary, dispatch, childrenDictionary }) as never}
      dictionaryLoad={handleDictionaryLoad({ dispatch })}
      dictionaryInfo={info}
      selectedRows={selectedRows}
      onCopy={handleCopy}
      onSelectRows={onSelectRows}
      overscanRowCount={overscanRowCount}
    />
  );
};

