import { documentCreateRoutine } from '@modules/documents/actions';
import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';

export const documentsTemplateCreate = createReducer(initialState, {
  //Create Document from template
  [documentCreateRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [documentCreateRoutine.SUCCESS]: (
    state,
    action: {
      type: string;
      payload: any;
    }
  ) => {
    state.data?.push(action.payload);
    state.isFetching = false;
    return state;
  },

  [documentCreateRoutine.FAILURE]: (
    state,
    action: {
      type: string;
      payload: {
        error: string[];
      };
    }
  ) => {
    state.error = action.payload.error;
    return state;
  },

  [documentCreateRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
