import { createSelector } from 'reselect';
import { currentTenderSelector, currentTenderStatusConfigSelector } from '@modules/tender-platform/selectors/current-tender';
import { TenderStatus, TenderType } from '@modules/tender-platform/types';

export const menuButtonsSelector = createSelector(
  currentTenderSelector,
  currentTenderStatusConfigSelector,
  (currentTender, statusConfig) => {
    const { ID, Type } = currentTender;
    const isAuction = Type === TenderType.Auction;
    const isExecuted = statusConfig?.Alias === TenderStatus.Executed;

    return { ID, isAuction, isExecuted };
  }
);
