import { planningGetPlanEfficiencyRoutine } from '@modules/planning/children/by-date/actions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure } from '@common/utils';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanID, PlanIDs } from '@common/types/dictionaries/plan';

export const planningGetPlanEfficiencyReducer = createReducer(initialState, {
  [planningGetPlanEfficiencyRoutine.FAILURE]: onFailure,
  [planningGetPlanEfficiencyRoutine.REQUEST]: (
    state,
    {
      payload: { planID }
    }: PayloadAction<{
      planID: PlanIDs;
    }>
  ) => {
    planID.forEach((id) => {
      state.info.plans[id] = { isFetching: true, lastUpdate: null };
    });
    return state;
  },
  [planningGetPlanEfficiencyRoutine.FULFILL]: (
    state,
    {
      payload: { planID }
    }: PayloadAction<{
      planID: PlanIDs;
    }>
  ) => {
    planID.forEach((id) => {
      state.info.plans[id].isFetching = false;
    });
    return state;
  },
  [planningGetPlanEfficiencyRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      planID: PlanIDs;
      data: Record<PlanID, number>;
    }>
  ) => {
    const { data } = action.payload;
    if (!state.project) return state;
    Object.entries(data).forEach(([planID, Efficiency]) => {
      if (state.project?.Plan?.[planID]) {
        state.project.Plan[planID].PlanTotal.Efficiency = Efficiency;
      }
    });
    return state;
  }
});

