import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import { ModulePath } from '@common/types';
import { useLink } from '@common/hooks/history/use-link';
import { FolderOpenOutlined } from '@ant-design/icons/lib/icons';
import { IButtonTooltipProps } from '@components/general/buttons/types';

export const PlannerPlanningUploadDataToCurrentProjectButton: FC<IButtonTooltipProps> = ({ disabled }) => {
  const url = ModulePath.Planner + ModulePath.Data;
  const { handleClick } = useLink(url);

  return (
    <ILSButtonTooltip
      disabled={disabled}
      title={'Загрузить дополнительные данные в проект'}
      icon={<FolderOpenOutlined className={'ils-svg primary'} />}
      onClick={handleClick}
    />
  );
};
