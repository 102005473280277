import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { dropBoardWidgetRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';

export const dropWidgetBoardReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [dropBoardWidgetRoutine.REQUEST]: (state, action: PayloadAction<AnalyticsBoardModel>) => {
    return state;
  },
  [dropBoardWidgetRoutine.SUCCESS]: (state, action: PayloadAction<{ widget: AnalyticsWidget }>) => {
    const { widget } = action.payload;

    state.Widgets = { ...state.Widgets, [widget.ID]: widget };

    return state;
  },
  [dropBoardWidgetRoutine.FAILURE]: (state) => {
    return state;
  },
  [dropBoardWidgetRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    return state;
  }
});
