export enum TenderAction {
  INDEX = 'INDEX',
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  SET_STATUS = 'SET_STATUS',
  APPOINT_RESPONSIBLE = 'APPOINT_RESPONSIBLE',
  DELETE = 'DELETE'
}

export enum TenderLotAction {
  INDEX = 'INDEX',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export enum TenderParticipantAction {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  CONFIRM = 'CONFIRM'
}

export enum TenderOfferAction {
  ACCEPT = 'ACCEPT'
}

export const defaultTenderMessages: {
  [key in TenderAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  INDEX: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка загрузки' },
  READ: { loading: 'Загрузка тендера..', success: 'Тендер загружен', error: 'Ошибка загрузки тендера' },
  CREATE: {
    loading: 'Создание тендера...',
    success: 'Тендер успешно создан',
    error: 'Возникла ошибка при создании тендера'
  },
  DELETE: { loading: 'Удаление тендера...', success: 'Тендер успешно удален', error: 'Ошибка удаления тендера' },
  UPDATE: { loading: 'Сохранение данных...', success: 'Данные сохранены', error: 'Ошибка сохранения' },
  SET_STATUS: { loading: 'Смена статуса...', success: 'Статус изменён', error: 'Ошибка смены статуса' },
  APPOINT_RESPONSIBLE: { loading: 'Назначение...', success: 'Ответственный назначен', error: 'Ошибка назначения' }
};

export const defaultTenderLotMessages: {
  [key in TenderLotAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  INDEX: { loading: 'Загрузка лота...', success: 'Лот загружен', error: 'Ошибка загрузки' },
  CREATE: { loading: 'Создание лота...', success: 'Лот успешно создан', error: 'Возникла ошибка при создании лота' },
  UPDATE: { loading: 'Сохранение данных...', success: 'Данные сохранены', error: 'Ошибка сохранения' },
  DELETE: { loading: 'Удаление лота...', success: 'Лот удалён', error: 'Ошибка удаления' }
};

export const defaultTenderParticipantMessages: {
  [key in TenderParticipantAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  CREATE: { loading: 'Добавление участника...', success: 'Участник добавлен', error: 'Ошибка добавления' },
  DELETE: { loading: 'Удаление участника...', success: 'Участник удалён', error: 'Ошибка удаления' },
  CONFIRM: { loading: 'Утверждение участника...', success: 'Участник утверждён', error: 'Ошибка утверждения' }
};

export const defaultTenderOfferMessages: {
  [key in TenderOfferAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  ACCEPT: { loading: 'Утверждение предложения...', success: 'Предложение утверждено', error: 'Ошибка утверждения' }
};

export const defaultPermittedTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/zip',
  '.rar',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-zip-compressed',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

