import { call, put, takeEvery } from 'redux-saga/effects';
import { documentDeleteFromTrashRoutine } from '@modules/documents/actions';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* documentDeleteFromTrashWorker({ payload }: { payload: { id: number } }) {
  const { request, success, fulfill } = documentDeleteFromTrashRoutine;

  try {
    yield put(request());
    yield call(Documents.deleteFromTrash, { ids: payload.id });
    yield put(success(payload));

    ILSUserNotify(Notify.Success, 'Документ удален');
  } catch (e) {
    ILSUserNotify(Notify.Error, 'Невозможно удалить документ');
  } finally {
    yield put(fulfill());
  }
}

export function* documentDeleteFromTrashWatcher() {
  yield takeEvery(documentDeleteFromTrashRoutine.trigger, documentDeleteFromTrashWorker);
}
