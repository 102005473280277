import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ProjectModel } from '@common/types';
import { planningUpdateProjectRoutine } from '@modules/planning/actions';

export function* planningUpdateProjectWorker(action: PayloadAction<{ project: ProjectModel; ID: number }>) {
  const { success, failure, fulfill } = planningUpdateProjectRoutine;
  const { project, ID } = action.payload;

  try {
    yield put(success({ project, ID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningUpdateProjectWatcher() {
  yield takeLatest(planningUpdateProjectRoutine.trigger, planningUpdateProjectWorker);
}
