import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticsCommonAPI } from '../types/api';
import { BoardDefaults, GetBoardSetRequest } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const analyticsGetBoardSetsRoutineFactory = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<GetBoardSetRequest & BoardDefaults>) {
    const { payload } = action;
    const { request, success, failure, fulfill } = actions.analyticsCommonGetBoardSetsRoutine;

    try {
      yield put(request());
      const { data } = yield call(api.boardSetIndex, payload);
      yield put(success({ data: data.data, defaults: { ...payload.defaults } }));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonGetBoardSetsRoutine.TRIGGER, worker);
  }

  return watcher;
};
