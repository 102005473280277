import { IndexedArray } from '@common/types';
import { getTrackStateWithSaveState } from '@common/utils/get-track/get-track-state';
import { PointTypeApi } from '@common/utils/get-track/types';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { loadPlanTrackRoutine } from '../actions';
import { initialState } from '../store';

// Загружает плановые точки трэка
export const loadPlanTrackReducer = createReducer(initialState, {
  [loadPlanTrackRoutine.REQUEST]: (state) => {
    state.planPoints.isFetching = true;
    return state;
  },
  [loadPlanTrackRoutine.SUCCESS]: (state, action: PayloadAction<{ data: any; points: IndexedArray<PointTypeApi> }>) => {
    const { data, points } = action.payload;
    getTrackStateWithSaveState(data, points, state.planPoints.trackData);
    state.planPoints.isFetching = false;
    return state;
  },
  [loadPlanTrackRoutine.FAILURE]: (state) => {
    state.planPoints.isFetching = false;
    return state;
  },
  [loadPlanTrackRoutine.FULFILL]: (state) => {
    state.planPoints.isFetching = false;
    return state;
  }
});
