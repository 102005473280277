import { FC } from 'react';
import { Dictionary, IILSTableProps } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { ILSTable } from '@common/components';
import { forwarderPointStatusesGroupTableConfig } from '@modules/settings/config/mobile-application/settings-forwarder-point-statuses-group-table-config';

interface IProps extends IILSTableProps<any> {}

// Кнопка скрытия/отображения столбцов должна быть полностью функциональна (TMS-5755)
export const SettingsForwarderPointStatusesGroupTableComponent: FC<IProps> = tableCustom(
  ({ ...props }) => {
    return <ILSTable {...props} />;
  },
  forwarderPointStatusesGroupTableConfig,
  Dictionary.ForwarderPointStatusesGroup
);
