import { planningDeleteRoutine } from '@modules/planning/children/by-date/actions';
import { Dictionary } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { PlansTableRecord } from '@modules/planning/children/by-date/types/record';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const useDeletePlan = () => {
  const dispatch = useDispatch();
  const activePlanID = useSelector(activePlanIDSelector);

  const handleDelete = (record?: PlansTableRecord) => {
    dispatch(planningDeleteRoutine({ planID: record?.ID ?? record?.PlanID ?? activePlanID, dictionary: Dictionary.Plan }));
  };

  return { handleDelete };
};
