import reduceReducers from 'reduce-reducers';
import { initialState } from '../store';
import getDemandListReducer from './get-list';
import changeActiveTabReducer from './change-active-tab';
import getDemandCountsReducer from './get-counts';

// Корневой редьюсер, который "объединяет" все редьюсеры в один
const demandRootReducer = reduceReducers(initialState, getDemandListReducer, changeActiveTabReducer, getDemandCountsReducer);

export default demandRootReducer;
