import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/account/store';
import { accountAttachmentCreateRoutine } from '@modules/account/actions';
import { onFailure } from '@common/utils';

export const accountAttachmentCreateReducer = createReducer(initialState, {
  [accountAttachmentCreateRoutine.REQUEST]: (state: typeof initialState) => {
    state.extraAttachments.isFetching = true;
    return state;
  },
  [accountAttachmentCreateRoutine.FAILURE]: onFailure,
  [accountAttachmentCreateRoutine.FULFILL]: (state: typeof initialState) => {
    state.extraAttachments.isFetching = false;
    return state;
  }
});
