import { FakePreCostRequest, FakeTripContractPayloadAction, FakeTripContractRequest } from '@modules/orders-and-trips/types/fake-pre-cost';

export const normalizeFakeTripContractRequest = ({
  payload,
  values
}: {
  payload: FakeTripContractPayloadAction['payload'];
  values: FakePreCostRequest;
}): FakeTripContractRequest => {
  return {
    ...values,
    ...payload,
    Source: { ...values.Source, ...payload.Source },
    Target: { ...values.Target, ...payload.Target }
  };
};
