import { createSelector } from 'reselect';
import {
  adminAccessSelector,
  adminOptionsForRolesSelector,
  currentUserIDSelector,
  userAvatarSelector,
  userInitialValuesSelector,
  usersWithoutDriversSelector
} from '@modules/account/selectors/user';
import {
  accountAvatarSelector,
  accountRolesOptionsSelector,
  dataSelector,
  initialValuesSelector,
  organizationTypesOptionsSelector
} from '@modules/account/selectors/account';
import { fetchSelector, savingSelector } from '@modules/account/selectors/common';
import { isEmpty } from 'lodash';

export const tabsSelector = createSelector(
  adminAccessSelector,
  fetchSelector,
  savingSelector,
  dataSelector,
  (adminAccess, isFetching, isSaving, data) => {
    return { adminAccess, isFetching, isSaving, data };
  }
);

export const companyPaneSelector = createSelector(
  adminAccessSelector,
  initialValuesSelector,
  accountRolesOptionsSelector,
  accountAvatarSelector,
  organizationTypesOptionsSelector,
  (adminAccess, { initialValues, isLoading, initialAccountCode }, roles, accountAvatar, organizationTypes) => {
    return { adminAccess, initialValues, isLoading, initialAccountCode, roles, accountAvatar, organizationTypes };
  }
);

export const userPaneSelector = createSelector(
  userInitialValuesSelector,
  adminAccessSelector,
  adminOptionsForRolesSelector,
  ({ initialValues, isLoading }, adminAccess, options) => {
    return { initialValues, adminAccess, isLoading, options };
  }
);

export const userFormSelector = createSelector(
  userInitialValuesSelector,
  userAvatarSelector,
  currentUserIDSelector,
  ({ initialValues }, { userAvatar }, currentUserID) => {
    return { initialValues, userAvatar, currentUserID };
  }
);

//https://jira.intelogis.ru/browse/TMS-6017
//Убрать водителей и экспедиторов из раздела Аккаунт (их нельзя видеть)
export const usersPaneSelector = createSelector(usersWithoutDriversSelector, currentUserIDSelector, (users, currentUserID) => {
  // Список users не может быть пустым,
  // так как в любом случае мы находимся в каком-либо аккаунте,
  // если текущего user (активная сессия) нет в списке - это баг
  const isLoading = isEmpty(users);
  return { users, currentUserID, isLoading };
});

