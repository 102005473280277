import { CellType } from '@common/types';
import { FC } from 'react';
import { TripEfficiency } from '@modules/planning/children/by-date/containers/result/efficiency/modal';
import { isEmpty } from 'lodash';
import { DASH } from '@common/constants';
import { convertSecondsToTimeHhMm } from '@common/utils/helpers/date-time/convert-seconds-to-time-hh-mm';

export enum EfficiencyKey {
  Mass = 'Mass',
  Volume = 'Volume',
  Cost = 'Cost',
  Clients = 'Clients',
  Orders = 'Orders',
  Boxes = 'Boxes',
  Pallets = 'Pallets',
  Duration = 'Duration',
  Distance = 'Distance'
}

export enum EfficiencyParameter {
  Peak = 'Peak',
  Max = 'Max',
  Percent = 'Percent',
  PeakPercent = 'PeakPercent',
  MaxOverload = 'MaxOverload'
}

const row: Record<EfficiencyKey, { text: string; type: CellType }> = {
  [EfficiencyKey.Mass]: { text: 'Вес', type: CellType.Float },
  [EfficiencyKey.Volume]: { text: 'Объем', type: CellType.Float },
  [EfficiencyKey.Boxes]: { text: 'Коробов', type: CellType.Float },
  [EfficiencyKey.Pallets]: { text: 'Паллет', type: CellType.Float },
  [EfficiencyKey.Duration]: { text: 'Длит', type: CellType.Time },
  [EfficiencyKey.Distance]: { text: 'Дист', type: CellType.Float },
  [EfficiencyKey.Clients]: { text: 'Клиенты', type: CellType.Int },
  [EfficiencyKey.Orders]: { text: 'Грузы', type: CellType.Int },
  [EfficiencyKey.Cost]: { text: 'Стоимость', type: CellType.Float }
};

export interface IEfficiencyTipProps {
  trip: TripEfficiency;
  hideHeader: boolean;
}

export const renderEfficiencyCell = ({
  EfficiencyKey,
  EfficiencyParameter
}: {
  EfficiencyParameter?: number;
  EfficiencyKey: EfficiencyKey;
}) => {
  return (
    <>
      {!EfficiencyParameter ? (
        DASH
      ) : (
        <>
          {row[EfficiencyKey].type === CellType.Int && EfficiencyParameter}
          {row[EfficiencyKey].type === CellType.Time && convertSecondsToTimeHhMm(EfficiencyParameter)}
          {row[EfficiencyKey].type === CellType.Float && parseFloat(EfficiencyParameter.toString()).toFixed(2)}
        </>
      )}
    </>
  );
};

export const EfficiencyTip: FC<IEfficiencyTipProps> = ({ trip, hideHeader }) => {
  if (isEmpty(trip)) return null;

  const { Efficiency } = trip;

  if (isEmpty(Efficiency)) return null;

  return (
    <div className="efficiency-window">
      {!hideHeader && (
        <>
          <div>
            <div>
              <b>Описание:</b>
            </div>
            <div>
              <b>Общее:</b> суммарный показатель
            </div>
            {
              <div>
                <b>Пиковое:</b> максимальное значение по показателю
              </div>
            }
            {
              <div>
                <b>Допустимое:</b> допустимый предел для текущего автомобиля
              </div>
            }
            {
              <div>
                <b>Допуск превышения:</b> допустимое превышение предела показателя
              </div>
            }
            <div>
              <b>Средний процент загрузки:</b> среднее значение по показателю
            </div>
            <div>
              <b>Средний процент использования:</b> максимальное среднее значение по показателю
            </div>

            <div>
              <b>Эффективность:</b> средний процент эффективности по рейсам (максимальный показатель среди допустимых параметров)
            </div>
          </div>
          <div className="row">
            <div className="label" />
            <div className="item">
              <b>Общее</b>
            </div>
            {
              <div className="peak">
                <b>Пиковое</b>
              </div>
            }
            {
              <div className="profile">
                <b>Допустимое</b>
              </div>
            }
            {
              <div className="allow-over">
                <b>Допуск превыш.</b>
              </div>
            }
            <div className="load">
              <b>% загрузки</b>
            </div>
            <div className="use">
              <b>% использования</b>
            </div>
          </div>
        </>
      )}
      <div className="eff-table">
        {<div className="eff-bg" style={{ backgroundColor: trip?.color || '' }} />}
        {(Object.keys(row) as Array<EfficiencyKey>).map((EfficiencyKey) => {
          return (
            <div key={EfficiencyKey} className="row">
              <div className="label" key="label">
                <b>{row[EfficiencyKey].text}</b>
              </div>
              <div className="item" key="item">
                {renderEfficiencyCell({ EfficiencyKey, EfficiencyParameter: Efficiency[EfficiencyKey] })}
              </div>
              <div className="peak" key="peak">
                {renderEfficiencyCell({
                  EfficiencyKey,
                  EfficiencyParameter: Efficiency[EfficiencyKey + EfficiencyParameter.Peak]
                })}
              </div>
              <div className="profile" key="profile">
                {renderEfficiencyCell({
                  EfficiencyKey,
                  EfficiencyParameter: Efficiency[EfficiencyKey + EfficiencyParameter.Max]
                })}
              </div>
              <div className="allow-over" key="allow-over">
                {renderEfficiencyCell({
                  EfficiencyKey,
                  EfficiencyParameter: Efficiency[EfficiencyKey + EfficiencyParameter.MaxOverload]
                })}
              </div>
              <div className="load" key="load">
                {renderEfficiencyCell({
                  EfficiencyKey,
                  EfficiencyParameter: Efficiency[EfficiencyKey + EfficiencyParameter.PeakPercent]
                })}
              </div>
              <div className="use" key="use">
                {renderEfficiencyCell({
                  EfficiencyKey,
                  EfficiencyParameter: Efficiency[EfficiencyKey + EfficiencyParameter.Percent]
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
