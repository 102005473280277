import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningDeleteRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { Dictionary, Vehicle } from '@common/types';
import { INITIAL_CURRENT_PLAN } from '@modules/planning/children/by-date/constants/store';
import { isEmpty } from 'lodash';
import { PlanningDeleteSuccessActionPayload } from '@modules/planning/children/by-date/sagas/delete';

export const planningDeleteReducer = createReducer(initialState, {
  [planningDeleteRoutine.FAILURE]: (
    state,
    action: PayloadAction<{
      vehicleID?: Vehicle['ID'];
    }>
  ) => {
    const { vehicleID } = action.payload;
    if (vehicleID) {
      delete state.currentPlan.RouteTotal[vehicleID];
    }
    return state;
  },
  [planningDeleteRoutine.REQUEST]: (
    state,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;

    switch (dictionary) {
      case Dictionary.Plan:
        if (state.activePlanID) {
          state.info.plans[state.activePlanID].isFetching = true;
        }
        break;
      case Dictionary.Route:
      case Dictionary.Trip:
      case Dictionary.Waypoint:
      case Dictionary.OrderAction:
        state.info.currentPlan.isFetching = true;
        break;
      default:
        break;
    }
    return state;
  },
  [planningDeleteRoutine.FULFILL]: (
    state,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;

    switch (dictionary) {
      case Dictionary.Plan:
        if (state.activePlanID) {
          state.info.plans[state.activePlanID].isFetching = false;
          state.info.plans[state.activePlanID].lastUpdate = Date.now();
        }
        break;
      case Dictionary.Route:
      case Dictionary.Trip:
      case Dictionary.Waypoint:
      case Dictionary.OrderAction:
        state.info.currentPlan.isFetching = false;
        state.info.currentPlan.lastUpdate = Date.now();

        break;
      default:
        break;
    }
    return state;
  },
  [planningDeleteRoutine.SUCCESS]: (state, action: PlanningDeleteSuccessActionPayload) => {
    const { dictionary, planID, activePlanID, currentPlanDeleting = false, routeTotal, planTotal } = action.payload;

    switch (dictionary) {
      case Dictionary.Plan:
        if (state.project?.Plan) {
          delete state.project.Plan[planID];
        }
        if (currentPlanDeleting) {
          state.activePlanID = activePlanID;
          state.currentPlan = INITIAL_CURRENT_PLAN;
        }
        break;
      case Dictionary.Route:
      case Dictionary.Trip:
      case Dictionary.Waypoint:
      case Dictionary.OrderAction:
        if (!isEmpty(planTotal)) {
          if (state.project?.Plan) {
            state.project.Plan[planID] = { ...state.project.Plan[planID], PlanTotal:planTotal };
          }
        }
        if (!isEmpty(routeTotal)) {
          state.currentPlan.RouteTotal[routeTotal.Vehicle.ID] = { ...routeTotal, changed: true };
        }
        break;
      default:
        break;
    }
    return state;
  }
});

