import { useSelector } from 'react-redux';
import { companyPaneSelector } from '@modules/account/selectors';
import { useAvatar } from '@modules/account/utils/hooks/use-avatar';
import { useEffect, useState } from 'react';
import { AccountFormValues } from '@modules/account/types';
import { cleanIsNilObjectFields } from '@common/utils/helpers/object/clean-empty-fields';
import { generateUUID } from '@common/utils';
import { Dispatch } from 'redux';
import { accountRoutine } from '@modules/account/actions';
import { DictionariesEndpoint } from '@common/types';
import { checkHasChangesInProfileForm } from '@modules/account/utils/helpers/check-has-changes-in-profile-form';
import { notifyUserAboutNoChanges } from '@components/feedback/ils-user-notify';

export const useAccountForm = ({ dispatch }: { dispatch: Dispatch }) => {
  const { initialValues, adminAccess, isLoading, initialAccountCode, accountAvatar, roles, organizationTypes } =
    useSelector(companyPaneSelector);
  const { newAvatar, onAvatarChange } = useAvatar(accountAvatar);

  useEffect(() => {
    setAccountCode(initialAccountCode);
  }, [initialAccountCode]);

  const [accountCode, setAccountCode] = useState<string>(initialAccountCode);

  const onFinish = (values: AccountFormValues) => {
    if (
      checkHasChangesInProfileForm({ values: { ...values, ID: initialValues?.ID }, initialValues, avatar: accountAvatar, newAvatar }) ||
      !initialValues?.ID
    ) {
      notifyUserAboutNoChanges();
      return;
    }
    const hasAvatar = typeof newAvatar === 'string';
    const payload = {
      values: {
        ...cleanIsNilObjectFields(values),
        AccountCode: accountCode ?? initialValues.AccountCode,
        AccountImage: !hasAvatar ? newAvatar?.originFileObj : newAvatar
      },
      id: initialValues.ID,
      operation: DictionariesEndpoint.Update
    };
    dispatch(accountRoutine(payload));
    onAvatarChange(null);
  };

  const handleGenerateCode: any = (value?: string | null) => setAccountCode(typeof value === 'string' ? value : generateUUID());

  return {
    adminAccess,
    isLoading,
    roles,
    onFinish,
    handleGenerateCode,
    initialValues,
    avatar: newAvatar,
    onAvatarChange,
    accountCode,
    organizationTypes
  };
};

