import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorGetProjectsRoutine } from '../actions';
import { IndexedArray } from '@common/types';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { ProjectEmulator } from '../types/emulator';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { FormatType } from '@common/types/general/date-time';

// Возвращает индексный массив проектов (projects) и массив с доступными датами (availableDates)
const emulatorGetProjectsReducer = createReducer(initialState, {
  [emulatorGetProjectsRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.isFetching = true;
    return state;
  },
  [emulatorGetProjectsRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      projects: IndexedArray<ProjectEmulator>;
    }>
  ) => {
    const { projects } = action.payload;

    const availableDates = Object.values(projects)
      ?.map(({ Start }) => timestampToDateTimeString(Start, FormatType.Date))
      .filter((date, i, availableDates) => availableDates.indexOf(date) === i); //Получает даты всех проектов и фильтрует дубликаты

    state.projects = projects;
    state.availableDates = availableDates;
    state.isFetching = false;
    return state;
  },
  [emulatorGetProjectsRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.isFetching = false;
    return state;
  },
  [emulatorGetProjectsRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.isFetching = false;
    return state;
  }
});

export default emulatorGetProjectsReducer;
