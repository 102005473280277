import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderSetStatusRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender } from '@modules/tender-platform/types';
import { tenderChangeRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint, Dictionary } from '@common/types';

function* tenderSetStatusWorker(
  action: PayloadAction<{
    tenderID: Tender['ID'];
    statusID: number;
  }>
) {
  const { request, failure, fulfill } = tenderSetStatusRoutine;
  const { tenderID, statusID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.setStatus, { tenderID, statusID });
    yield put(
      tenderChangeRoutine.trigger({
        StatusConfig: data,
        operation: DictionariesEndpoint.SetStatus,
        dictionary: Dictionary.Tender
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(tenderReadRoutine.trigger({ id: tenderID }));
    yield put(fulfill());
  }
}

export function* tenderSetStatusWatcher() {
  yield takeEvery(tenderSetStatusRoutine.trigger, tenderSetStatusWorker);
}
