export enum OrdersNTripsAction {
  INIT = 'INIT',
  DEPOT_SHORT = 'DEPOT_SHORT',
  RESTRICTION_ENUM = 'RESTRICTION_ENUM',
  ORDERS = 'ORDERS',
  ADD_UNALLOCATED_ORDER = 'ADD_UNALLOCATED_ORDER',
  CREATE_TRIP = 'CREATE_TRIP',
  GET_TRIP_DATA_FOR_ORDERS = 'GET_TRIP_DATA_FOR_ORDERS',
  GET_TRIPS_DATA = 'GET_TRIPS_DATA',
  VEHICLE_TYPES_INDEX = 'VEHICLE_TYPES_INDEX',
  GET_AVAILABLE_INTEGRATIONS = 'GET_AVAILABLE_INTEGRATIONS',
  GET_PRE_COST = 'GET_PRE_COST',
  ADD_ORDER_TO_SERVICE = 'ADD_ORDER_TO_SERVICE',
  REMOVE_ORDER_FROM_SERVICE = 'REMOVE_ORDER_FROM_SERVICE',
  DELETE_TRIP = 'DELETE_TRIP',
  DELETE_ORDER = 'DELETE_ORDER',
  UPDATE_ORDER = 'UPDATE_ORDER',
  IMPORT_ORDERS = 'IMPORT_ORDERS'
}

export const defaultStatuses: {
  [key in OrdersNTripsAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  INIT: { loading: 'Инициализация параметров модуля...', success: 'Успешно' },
  DEPOT_SHORT: { loading: 'Загружаем список доступных точек...', success: 'Точки загружены' },
  RESTRICTION_ENUM: { loading: 'Получаем специфики...', success: 'Специфики загружены' },
  ORDERS: { loading: 'Заявки загружаются...', success: 'Заявки загружены' },
  ADD_UNALLOCATED_ORDER: { loading: 'Создаем заявку...', success: 'Заявка успешно создана' },
  CREATE_TRIP: { loading: 'Создаем рейс...', success: 'Рейс успешно создан ' },
  GET_TRIP_DATA_FOR_ORDERS: { loading: 'Выстраиваем последовательность точек погрузки/разгрузки...', success: 'Успешно' },
  GET_TRIPS_DATA: { loading: 'Рейсы загружаются...', success: 'Рейсы загружены' },
  VEHICLE_TYPES_INDEX: { loading: 'Загружаем типы ТС...', success: 'Успешно' },
  GET_AVAILABLE_INTEGRATIONS: { loading: 'Получаем список доступных интеграций...', success: 'Доступные интеграции загружены' },
  GET_PRE_COST: { loading: 'Получаем предварительную стоимость доставки...', success: 'Успешно' },
  ADD_ORDER_TO_SERVICE: { loading: 'Отправляем рейс в транспортную компанию', success: 'Успешно' },
  REMOVE_ORDER_FROM_SERVICE: { loading: 'Рейс отменяется', success: 'Рейс успешно отменен' },
  DELETE_TRIP: { loading: 'Рейс удаляется', success: 'Рейс успешно удален' },
  DELETE_ORDER: { loading: 'Заявка удаляется', success: 'Заявка успешно удалена' },
  UPDATE_ORDER: { loading: 'Данные обновляются', success: 'Данные успешно обновлены' },
  IMPORT_ORDERS: { loading: 'Файл загружается', success: 'Файл загружен' }
};

