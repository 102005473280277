import { MonitoringVehicleMovementSpeedIcon, MonitoringVehicleMovementSpeedTitle } from '@common/types/dictionaries/monitoring-vehicle';

// Заголовки и иконки для диапазона скоростей
export const MonitoringVehicleMovementSpeedType = {
  NoData: {
    status: MonitoringVehicleMovementSpeedTitle.NoData, // Нет данных
    icon: MonitoringVehicleMovementSpeedIcon.NoData
  },
  Stop: {
    status: MonitoringVehicleMovementSpeedTitle.Stop, // Остановка
    icon: MonitoringVehicleMovementSpeedIcon.Stop
  },
  VerySlow: {
    status: MonitoringVehicleMovementSpeedTitle.VerySlow, // Очень медленно
    icon: MonitoringVehicleMovementSpeedIcon.VerySlow
  },
  Slow: {
    status: MonitoringVehicleMovementSpeedTitle.Slow, // Медленно
    icon: MonitoringVehicleMovementSpeedIcon.Slow
  },
  Medium: {
    status: MonitoringVehicleMovementSpeedTitle.Medium, // Средняя скорость
    icon: MonitoringVehicleMovementSpeedIcon.Medium
  },
  Hight: {
    status: MonitoringVehicleMovementSpeedTitle.Hight, // Высокая скорость
    icon: MonitoringVehicleMovementSpeedIcon.Hight
  },
  NoVehicleTracker: {
    status: MonitoringVehicleMovementSpeedTitle.NoVehicleTracker, // Нет трекера
    icon: MonitoringVehicleMovementSpeedIcon.NoVehicleTracker
  }
};
