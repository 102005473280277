import './styles.less';
import { FC } from 'react';
import DatePicker, { CalendarProps, DatePickerProps } from 'react-multi-date-picker';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';
import { ILSSpinCircle, ILSTooltip } from '@common/components';
import { mapDataToString } from '@common/utils/helpers/components/map-data-to-string';
import { MONTHS, WEEKDAYS } from '@common/constants/options';
import { CalendarOutlined } from '@ant-design/icons/lib/icons';
import { PlacementType } from '@common/types/components';

export const ILS_DATE_PICKER_MULTIPLE_DISABLE_STYLE = {
  disabled: true,
  style: { color: '#cccccc' }
};

export const ILSDatePickerMultiple: FC<CalendarProps & DatePickerProps & { isLoading?: boolean }> = ({
  format = ILS_MAIN_INTERFFACE_DATE_FORMAT,
  isLoading = false,
  disabled = false,
  placeholder = 'Выберите даты',
  ...props
}) => {
  return (
    <ILSTooltip title={mapDataToString(props.value)} placement={PlacementType.topLeft}>
      <div className="multiple-datepicker">
        {isLoading ? <ILSSpinCircle spinning={isLoading} /> : <CalendarOutlined className={'ils-svg primary'} />}
        <DatePicker
          inputClass={'multiple-datepicker-input'}
          weekStartDayIndex={1}
          weekDays={WEEKDAYS}
          months={MONTHS}
          format={format}
          disabled={disabled || isLoading}
          placeholder={placeholder}
          {...props}
          multiple
        />
      </div>
    </ILSTooltip>
  );
};
