import { DocumentType, documentTypeToLabelMap } from '@modules/documents/types/document-type';

export const documentTypeOptions: { label: string; value: DocumentType }[] = [
  { value: DocumentType.Deal, label: documentTypeToLabelMap[DocumentType.Deal] },
  { value: DocumentType.Contract, label: documentTypeToLabelMap[DocumentType.Contract] },
  { value: DocumentType.ContractAnnex, label: documentTypeToLabelMap[DocumentType.ContractAnnex] },
  { value: DocumentType.LadingBill, label: documentTypeToLabelMap[DocumentType.LadingBill] },
  { value: DocumentType.RouteList, label: documentTypeToLabelMap[DocumentType.RouteList] },
  { value: DocumentType.Custom, label: documentTypeToLabelMap[DocumentType.Custom] }
];

export const shortDocumentTypeOptions: { label: string; value: DocumentType }[] = [
  { value: DocumentType.Contract, label: documentTypeToLabelMap[DocumentType.Contract] },
  { value: DocumentType.ContractAnnex, label: documentTypeToLabelMap[DocumentType.ContractAnnex] },
  { value: DocumentType.LadingBill, label: documentTypeToLabelMap[DocumentType.LadingBill] },
  { value: DocumentType.RouteList, label: documentTypeToLabelMap[DocumentType.RouteList] },
  { value: DocumentType.Custom, label: documentTypeToLabelMap[DocumentType.Custom] }
];
