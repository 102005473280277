import { FC, useContext, useEffect, useMemo, useState } from 'react';
import ILSWidgetWrapper from '../components/widget/widget-wrapper';
import { WidgetProps } from '../types/container';
import { AnalyticsWidgetBoardContext } from '@modules/analytics/common/context/widget-board-context';
import { getWidgetParamName } from '@modules/analytics/common/utils/get-widget-param-name';
import { BoardWidgetParams, WidgetType } from '@common/types/dictionaries/analytics';

type ParamWidgetValue = { IsWidgetParam: boolean | undefined; name: string; value: string | number | undefined };

/**контейнер виджета, управляет логикой получения данных виджетом
 * widgetParams - сохраненные в с сторе значения параметров, которые виджет может использовать
 * */
const ILSAnalyticsWidgetContainer: FC<WidgetProps> = (props) => {
  const { widget } = props;
  const { widgetParams, hideWidgetsOnError, widgetsError, handleGetWidgetData, stretchable, editMode } =
    useContext(AnalyticsWidgetBoardContext);

  const showParameterless = !stretchable;

  const [hideWidget, setHideWidget] = useState(true);

  const [params, setParams] = useState<Array<{ name: string; value: string | number | undefined; IsWidgetParam?: boolean }> | undefined>();

  const requiredParams = useMemo(() => Object.values(widget?.Params || {}).filter((item) => item.Required === 1), []);

  const optionalParams = useMemo(() => Object.values(widget?.Params || {}).filter((item) => item.Required === 0), []);

  //функция возвращает только измененные параметры
  const getParamsValues = (inputParams: BoardWidgetParams[]): Array<ParamWidgetValue> => {
    if (!widgetParams) return [];
    return inputParams.reduce((acc: ParamWidgetValue[], curr) => {
      const paramName = curr.IsWidgetParam ? getWidgetParamName(curr.Name, widget) : curr.Name;
      const paramValue = widgetParams[paramName];
      //если параметр не менялся - не возвращаем его
      const currentValue = params?.find((x) => x.name === curr.Name);

      if (params && currentValue?.value === paramValue?.value) return acc;

      if (paramValue?.value && paramValue.value !== '' && paramValue?.value !== undefined) {
        acc.push({ ...paramValue, IsWidgetParam: curr.IsWidgetParam });
      } else {
        acc.push({ name: curr.Name, value: undefined, IsWidgetParam: curr.IsWidgetParam });
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    let optionalParamsValues = getParamsValues(optionalParams);

    if (requiredParams.length === 0) {
      setHideWidget(false);
      setParams(optionalParamsValues || []);
      return;
    }
    if (widgetParams) {
      let requiredParamsValues = getParamsValues(requiredParams);

      const isParamsReady = requiredParamsValues ? requiredParamsValues.every((p) => p.value !== undefined) : false;
      const newParams = [...requiredParamsValues, ...optionalParamsValues];
      if (newParams.length > 0) {
        setParams(isParamsReady ? [...(params?.filter((p) => newParams.every((x) => x.name !== p.name)) || []), ...newParams] : undefined);
      }

      setHideWidget(!isParamsReady && !showParameterless);
    } else {
      setHideWidget(!showParameterless);
      return;
    }
  }, [widgetParams]);

  useEffect(() => {
    if (widgetsError && hideWidgetsOnError) setHideWidget(true);
  }, [widgetsError, hideWidgetsOnError]);

  useEffect(() => {
    if (params && requiredParams.length <= params.length) {
      const requestParams = {};
      params.forEach((p) => {
        requestParams[p.name] = p.value;
      });
      if (widget.ID && !widget.loaded && !widget.isNew && widget.Type !== WidgetType.text) {
        handleGetWidgetData({ id: widget.ID, params: requestParams });
        setHideWidget(false);
      }
    }
  }, [params, widget.isNew]);

  return (
    <>
      <ILSWidgetWrapper {...props} hideWidget={hideWidget} />
    </>
  );
};

export default ILSAnalyticsWidgetContainer;
