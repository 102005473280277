import { ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';

export const selectBoardSuccessCommonWatcher = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<AnalyticsBoardModel>) {
    const { trigger, failure } = actions.analyticsGetBoardWidgetRoutine;
    try {
      if (action.payload?.ID) {
        yield put(trigger({ BoardID: action.payload?.ID }));
      }
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonSelectBoardRoutine.SUCCESS, worker);
  }

  return watcher;
};
