import moment from 'moment';
import { createSelector } from 'reselect';
import { currentTenderAuctionPriceSelector, tenderOffersSelector } from '@modules/tender-platform/selectors';
import { CurrencySign } from '@common/constants';
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general/date-time';

export const auctionCargoOwnerSelector = createSelector(currentTenderAuctionPriceSelector, tenderOffersSelector, (Price, TenderOffers) => {
  const winner = TenderOffers?.find(({ Accepted }) => Accepted);
  const hasWinner = Price && winner;
  const auctionInfo = hasWinner
    ? `Победитель участник №${winner?.UserID} с предложенной ценой ${Price}${CurrencySign.RUB}`
    : `Наилучшая цена на ${moment().format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT)}: ${Price ?? 0}${CurrencySign.RUB}`;
  return {
    auctionInfo
  };
});
