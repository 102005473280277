import { planningRestrictionFilterRoutine } from '@modules/planning/children/by-date/actions';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Restriction } from '@common/types';
import { PlannerPlanningMenuButton } from '@modules/planning/children/by-date/components/result/table-head/action-buttons/btn';
import { PlannerPlanningRestrictionMenuComponent } from '@modules/planning/children/by-date/components/result/table-head/restriction';
import { useModal } from '@common/hooks/components/use-modal';
import { restrictionMenuSelector } from '@modules/planning/children/by-date/selectors/components/restriction-menu';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { ILSPopover } from '@common/components';
import { UnorderedListOutlined } from '@ant-design/icons/lib/icons';

export type onRestrictionChange = (ids: Array<Restriction['ID'] | string>) => void;

export const PlannerPlanningRestrictionMenu: FC = () => {
  const dispatch = useDispatch();
  const { options, activeRestrictionIDs, pickedRestriction } = useSelector(restrictionMenuSelector);
  const { visible, handleShow } = useModal();

  const onRestrictionChange: CheckboxGroupProps['onChange'] = (ids = []) => {
    dispatch(planningRestrictionFilterRoutine({ ids }));
  };

  return (
    <ILSPopover
      visible={visible}
      content={
        <PlannerPlanningRestrictionMenuComponent
          handleShow={handleShow}
          options={options}
          value={activeRestrictionIDs}
          onChange={onRestrictionChange}
        />
      }
    >
      <PlannerPlanningMenuButton
        table
        icon={<UnorderedListOutlined className={'ils-svg primary'} />}
        show={visible}
        setShow={handleShow}
        content={pickedRestriction}
        active={activeRestrictionIDs}
        tooltipText={'Фильтр специфик'}
      />
    </ILSPopover>
  );
};
