import { useDispatch } from 'react-redux';
import { useAppSelector } from '@core/hooks';
import { getDocumentsTemplates } from '@modules/documents/selectors';
import { DeleteTemplateRequest } from '@modules/documents/types/documents-api';
import { deleteReportRoutine } from '@modules/documents/actions';
import { Project } from '@common/types';

export const useTemplateList = ({ projectId }: { projectId: Project['ID'] }) => {
  const dispatch = useDispatch();
  const documentTemplates = useAppSelector(getDocumentsTemplates);

  const handleDeleteReport = (values: DeleteTemplateRequest) => {
    const payload = { ...values, projectId };
    dispatch(deleteReportRoutine(payload));
  };
  return { documentTemplates, handleDeleteReport };
};
