import { tableCustom } from '@core/containers/table-custom';
import { RESTRICTION_TYPE_TABLE_CONFIG } from '@common/models/restriction-group/config/table';
import { data2table } from '@common/models/restriction-group/decorators';
import { FC } from 'react';
import { Dictionary } from '@common/types';
import {
  IILSTablePlanningComponentProps,
  PlannerPlanningTablesContainer
} from '@modules/planning/children/by-date/containers/result/tables/table';
import { table2data } from '@components/data-display/table/utils/decorators';

const ILSRestrictionGroup: FC<IILSTablePlanningComponentProps> = (props) => {
  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={RESTRICTION_TYPE_TABLE_CONFIG}
      handleDecorator={table2data}
      dictionary={Dictionary.RestrictionGroup}
      dataDecorator={data2table}
    />
  );
};

export const ILSRestrictionGroupTable = tableCustom(ILSRestrictionGroup, RESTRICTION_TYPE_TABLE_CONFIG, Dictionary.RestrictionGroup);
