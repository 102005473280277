import { ILSSelectCloseOnScroll } from '@components/data-display/table/components/ils-select-scroll-close';
import { IILSCellSelectProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';

export const ILSCellSelect = <R extends TableRecord>({
  children,
  options,
  save,
  tableRef,
  toggleEdit,
  fieldNames
}: IILSCellSelectProps<R>): JSX.Element => {
  const [, value] = children;

  return (
    <ILSSelectCloseOnScroll
      autoFocus
      open
      fieldNames={fieldNames}
      className={'cell-wrapper-editable-full'}
      filterOption={filterOption(fieldNames)}
      dropdownMatchSelectWidth={false}
      tableRef={tableRef}
      onChange={save}
      value={value}
      defaultValue={value}
      options={options}
      onBlur={toggleEdit}
    />
  );
};
