export type PlanningAvailableDates = Array<string>;

export enum PlanningStatus {
  Validate = 'validate',
  Planning = 'planning',
  Result = 'result',
  Prepare = 'prepare',
  Checking = 'checking',
  Enough = 'enough'
}

export enum PlanningActionType {
  PLANNING_GET_PROJECTS = 'PLANNING/GET_PROJECTS',
  PLANNING_SET_ACTIVE_PROJECT_ID = 'PLANNING/SET_ACTIVE_PROJECT_ID'
}

export enum PlanningValidationType {
  Replan = 'Replan'
}

export enum PlannerSettingsParameter {
  Table = 'table',
  FullScreen = 'fullScreen'
}

export enum PlannerSettingsParameterFullScreen {
  Table = 'table',
  Map = 'map'
}
