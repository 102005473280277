import ILSMapMenu from '@modules/catalog/components/map/btn-menu/index';
import { FC, useState } from 'react';
import { ILSUserNotify } from '@common/components';
import { IPropsILSCatalogLayerMenuProps } from '@modules/catalog/types/components';
import { Notify } from '@common/types';

const ILSCatalogLayerMenu: FC<IPropsILSCatalogLayerMenuProps> = ({
  activeLayers,
  setActiveLayers,
  layers,
  data,
  dictionary,
  handleDictionaryLoad
}) => {
  const [showLayersMenu, setShowLayersMenu] = useState<boolean>(false);

  const onLayerChange = (
    value: boolean | null,
    layer?: {
      ID: number;
      type?: string;
      Name?: string;
      entity?: string;
    } | null,
    clear?: boolean | null
  ) => {
    if (!clear && layer && layer.ID) {
      if (value) {
        setActiveLayers([...activeLayers, layer.ID]);
      } else {
        setActiveLayers(activeLayers.filter((r) => r !== layer.ID && r));
      }
    } else {
      setActiveLayers([]);
    }

    if (!data.polygon.length && value && layer?.entity === 'ForbiddenZone') {
      ILSUserNotify(Notify.Warning, `Отсутсвуют ${layer.Name?.toLowerCase()}`, 1);
    }
  };

  return (
    <ILSMapMenu
      show={showLayersMenu}
      setShow={setShowLayersMenu}
      // @ts-ignore
      menu={layers}
      active={activeLayers}
      tooltipText={'Слои карты'}
      itemText={'слои карты'}
      onChange={onLayerChange}
      icon={'show-layers'}
      dictionary={dictionary}
      handleDictionaryLoad={handleDictionaryLoad}
    />
  );
};

export default ILSCatalogLayerMenu;
