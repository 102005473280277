import { UnifiedRoutine } from 'redux-saga-routines';
import { Action, Dispatch } from 'redux';
import { DetailsSelectorData, DetailsStoreData } from '../types/hooks';

// Хук для загрузки детализаци
export const useLoadDetails = <TStoreType extends DetailsStoreData>(args: {
  storeData: TStoreType;
  routine: UnifiedRoutine<(payload?: any) => Action>;
  updateInterval: number;
  selectorData: DetailsSelectorData;
  dispatch: Dispatch;
  active: boolean;
  payload?: any;
}) => {
  return function () {
    if (!args.active) return;
    const { selectorData, storeData, dispatch, routine, payload, updateInterval } = args;
    const { startDate, endDate } = selectorData;
    const hasDate = startDate && endDate;
    const isNotEqualDate = storeData.lastUpdateInterval?.from !== startDate || storeData.lastUpdateInterval?.till !== endDate;
    const timeIsOver =
      !storeData.lastUpdate ||
      (storeData.lastUpdate + updateInterval < new Date().getTime() &&
        storeData.lastUpdateInterval?.from === startDate &&
        storeData.lastUpdateInterval?.till === endDate);
    if (hasDate && !storeData.isFetching && (isNotEqualDate || timeIsOver)) {
      dispatch(routine({ from: startDate, till: endDate, ...payload }));
    }
  };
};
