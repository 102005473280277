import { useState } from 'react';
import { ButtonProps } from 'antd';

export const useProposalForm = () => {
  const [showForm, setShowForm] = useState(false);
  const buttonType: ButtonProps['type'] = showForm ? undefined : 'primary';
  const buttonChildren = showForm ? 'Отменить' : 'Оформить заказ';

  const handleFormVisibility = () => setShowForm((prev) => !prev);
  return { showForm, buttonType, buttonChildren, handleFormVisibility };
};
