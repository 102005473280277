import { DictionaryAPI } from '@common/api/common';
import { Dictionary } from '@common/types';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { catalogMultipleUpdateRoutine } from '@modules/catalog/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

export function* catalogMultipleUpdateWorker(
  action: PayloadAction<{
    dictionary: Dictionary;
    data: Array<any>;
  }>
) {
  const { dictionary, data: updateData } = action.payload;
  const { request, success, failure, fulfill } = catalogMultipleUpdateRoutine;

  try {
    yield put(request({ dictionary }));

    const {
      data: { data }
    } = yield call(DictionaryAPI.multipleUpdate, { data: updateData, dictionary });
    const payload = {
      data: checkAndCreateArrayFromObject(data),
      dictionary
    };
    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogMultipleUpdateWatcher() {
  yield takeEvery(catalogMultipleUpdateRoutine.trigger, catalogMultipleUpdateWorker);
}

