import { DefaultUserParams, SettingsValues } from '@modules/settings/types/store';

export const getValuesFromSettingsObjectBySection = (settings: DefaultUserParams) => {
  return Object.keys(settings).reduce((result, moduleKey) => {
    const allSections = settings[moduleKey].sections;

    Object.keys(allSections).forEach((sectionKey) => {
      const singleSectionData = allSections[sectionKey].data;

      Object.keys(singleSectionData).forEach((paramkey) => {
        if (!result[moduleKey]) result[moduleKey] = {};
        if (!result[moduleKey][sectionKey]) result[moduleKey][sectionKey] = {};

        result[moduleKey][sectionKey][paramkey] = singleSectionData[paramkey].value;
      });
    });

    return result;
  }, {} as SettingsValues);
};
