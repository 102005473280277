import { FC } from 'react';
import { ILSTable } from '@components/index';
import { tableCustom } from '@core/containers/table-custom';
import { useAppSelector } from '@core/hooks';
import { Dictionary, IILSTableProps, Key, ReferenceTablesData, TableColumnName } from '@common/types';
import { tenderAppointResponsibleRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import { useAbac } from 'react-abac';
import { accountUsersSelector } from '@modules/tender-platform/selectors';
import { tenderOwnConfig } from '@modules/tender-platform/utils/table-config/tender-list';
import { Tender } from '@modules/tender-platform/types';
import { EXCLUDE_CARRIER_COLUMN_IF_NOT_TABS, ListTab } from '@modules/tender-platform/constants/tender-card';
import { fetchSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { TENDER_TABLE_SCROLL_Y } from '@modules/tender-platform/constants';

const ILSTenderOwnTable: FC<IILSTableProps<Tender & { key: Key }> & { isOver: boolean; currentTab: ListTab }> = ({
  isOver,
  currentTab,
  ...props
}) => {
  const dispatch = useDispatch();
  const accountUsers = useAppSelector(accountUsersSelector);
  const loading = useSelector(fetchSelector);
  const { userHasPermissions } = useAbac();

  const refData: ReferenceTablesData = {
    [TableColumnName.UserID]: {
      options: accountUsers,
      dictionary: Dictionary.User,
      fieldNames: DEFAULT_FIELD_NAMES
    }
  };

  const handleSave = ({ ID: tenderID }: any, _: string, userID: string) => {
    if (tenderID) {
      dispatch(tenderAppointResponsibleRoutine({ tenderID, userID }));
    }
  };

  let columns = props.columns;
  if (!userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_CHANGE_RESPONSIBLE)) {
    columns = columns?.map((column) =>
      column.dataIndex === TableColumnName.UserID
        ? {
            ...column,
            editable: false
          }
        : column
    );
  }

  if (!EXCLUDE_CARRIER_COLUMN_IF_NOT_TABS.includes(currentTab as ListTab)) {
    columns = columns?.filter((column) => column.dataIndex !== TableColumnName.Carrier);
  } else {
    columns = columns?.map((column) => {
      return column.dataIndex === TableColumnName.Carrier ? { ...column, invisible: false } : column;
    });
  }

  if (currentTab !== ListTab.CollectingOffers) {
    columns = columns?.filter((column) => column.dataIndex !== TableColumnName.Countdown);
  } else {
    columns = columns?.map((column) => {
      return column.dataIndex === TableColumnName.Countdown ? { ...column, invisible: false } : column;
    });
  }

  return (
    <ILSTable
      {...props}
      focusRow={{ key: props.dataSource?.[0]?.key }}
      loading={loading}
      scroll={{ y: TENDER_TABLE_SCROLL_Y }}
      columns={columns}
      className="tender-table"
      handleSave={handleSave}
      refData={refData}
    />
  );
};

export const ILSTenderOwnTableContainer = tableCustom(ILSTenderOwnTable, tenderOwnConfig, Dictionary.TenderOwn);
