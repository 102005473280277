import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { planAnalyticGetRoutine } from '../actions';

const planAnalytic = createReducer(initialState, {
  //GET
  [planAnalyticGetRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [planAnalyticGetRoutine.SUCCESS]: (
    state,
    action: {
      type: string;
      payload: {
        data: any;
      };
    }
  ) => {
    const { data } = action.payload;
    state.data = data;
    state.isFetching = false;
    return state;
  },
  [planAnalyticGetRoutine.FAILURE]: (state) => {
    state.isFetching = false;
    return state;
  },
  [planAnalyticGetRoutine.FULFILL]: (state) => {
    return { ...state };
  }
});
export default planAnalytic;
