import { PointType, QuickHull } from '@common/utils/map/quickhills';
import { ConvexPolygon, Dictionary } from '@common/types';
import { isArray, isNumber } from 'lodash';
import { isLatLngOrLatLngLiteral } from '@common/utils';

export const MapConvex = (ConvexPolygon: ConvexPolygon[], planID: number | string) => {
  if (!ConvexPolygon || !ConvexPolygon.length) return [];
  const convexPolygon: ConvexPolygon[] = [];
  if (ConvexPolygon?.length) {
    ConvexPolygon.forEach((polygon) => {
      if (isArray(polygon?.coords) && polygon?.coords?.length) {
        let color = polygon.color;
        let points: Array<PointType> = [];
        let convexpoints: [number, number][] = [];
        polygon.coords.forEach((c) => {
          if (Array.isArray(c) && c[0] && c[1]) {
            points.push({ x: c[0], y: c[1] });
          } else {
            if (!isNumber(c) && isLatLngOrLatLngLiteral(c) && c.lat && c.lng) {
              points.push({ x: c.lat, y: c.lng });
            }
          }
        });
        const coords = QuickHull(points);
        if (coords?.length) {
          coords.forEach((c) => {
            if (c.x && c.y) convexpoints.push([c.x, c.y]);
          });
        }

        convexPolygon?.push({
          color,
          coords: convexpoints,
          clickData: {
            PlanID: planID,
            TripID: polygon.TripID ?? '',
            VehicleID: polygon.VehicleID ?? '',
            dictionary: Dictionary.Trip
          }
        });
      }
    });
  }
  return convexPolygon;
};
