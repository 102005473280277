import { IndexedArray, RouteTotal, Trip, Vehicle } from '@common/types';

export const getRoutesIdsByTripCondition = ({
  routes,
  deletedSourceTripIDs,
  sourceTrip
}: {
  sourceTrip: Trip[];
  deletedSourceTripIDs: Trip['ID'][];
  routes: IndexedArray<RouteTotal>;
}) => {
  const sourceTrips = Object.values(sourceTrip);

  const { sourceTripRoutesIDs, deletedSourceTripRoutesIDs } = sourceTrips.reduce(
    (ids: { deletedSourceTripRoutesIDs: Array<Vehicle['ID']>; sourceTripRoutesIDs: Array<Vehicle['ID']> }, { VehicleID, ID }) => {
      if (deletedSourceTripIDs.includes(ID) && Object.keys(routes[VehicleID].TripTotal).length === 1) {
        ids.deletedSourceTripRoutesIDs.push(VehicleID);
      } else {
        ids.sourceTripRoutesIDs.push(VehicleID);
      }
      return ids;
    },
    { deletedSourceTripRoutesIDs: [], sourceTripRoutesIDs: [] }
  );
  return { sourceTripRoutesIDs, deletedSourceTripRoutesIDs };
};
