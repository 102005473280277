import { monitoringActiveDashboardTabRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { DashboardTables } from '../types';
import { initialState } from '../store';
import { MonitoringDashboardState } from '../types/store';
import { DashboardTablesNumberMetrics } from '../constants';

// Записывает в стор ативную вкладку с соответствующей таблицей
const monitoringDashboardReducer = createReducer<MonitoringDashboardState>(initialState as MonitoringDashboardState, {
  [monitoringActiveDashboardTabRoutine.REQUEST]: (state) => {
    return state;
  },
  [monitoringActiveDashboardTabRoutine.SUCCESS]: (state, action: PayloadAction<{ activeTab: DashboardTables | undefined }>) => {
    const { activeTab } = action.payload;

    state.selectedDashboard = activeTab || DashboardTablesNumberMetrics.Served;

    return state;
  },
  [monitoringActiveDashboardTabRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringActiveDashboardTabRoutine.FULFILL]: (state) => {
    return state;
  }
});

export default monitoringDashboardReducer;
