import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PlacementType } from '@common/types/components';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { planningReloadRoutine } from '@modules/planning/children/by-date/actions';

export const PlannerPlanningReloadPlanButton: FC = () => {
  const dispatch = useDispatch();
  const activePlanID = useSelector(activePlanIDSelector);
  const disabled = !activePlanID;

  const onReload = () => {
    !disabled && dispatch(planningReloadRoutine({ planID: activePlanID }));
  };

  return (
    <ILSButtonTooltip
      placement={PlacementType.bottom}
      title={'Перезагрузить выделенный план'}
      disabled={disabled}
      onClick={onReload}
      icon={<SyncOutlined className={'ils-svg primary'} />}
    />
  );
};
