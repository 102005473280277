import { SetBoardWidgetBatchResponse } from '@common/types/dictionaries/analytics';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { setBoardWidgetBatch } from '../actions';
import initialState from '../store';

export const setBoardWidgetBatchReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [setBoardWidgetBatch.REQUEST]: (state) => {
    state.Boards.savingWidgets = true;
    return state;
  },
  [setBoardWidgetBatch.SUCCESS]: (state, action: PayloadAction<{ data: SetBoardWidgetBatchResponse }>) => {
    const { data } = action.payload;
    state.Widgets = Object.values(data.widgets).reduce((prev, cur) => {
      prev[cur.ID] = { ...state.Widgets[cur.ID], ...cur };

      return prev;
    }, {});

    return state;
  },
  [setBoardWidgetBatch.FULFILL]: (state) => {
    state.Boards.savingWidgets = false;
    return state;
  }
});
