import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import { ILSSettingBlockComponent } from '@modules/settings/components/block';
import { SettingsChangePasswordFormComponent } from '@modules/settings/components/password-change';
import { ChangeSettings } from '@modules/settings/types/container';
import CustomScroll from 'react-customscroll';
import { ExpandIconPosition } from '@components/data-display/collapse/types';
import { settingsSaveRoutine } from '@modules/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MobileApplication } from '@modules/settings/containers/mobile-application/mobile-application';
import { CommonSettings } from '@modules/settings/containers/common-settings/common-settings';
import { settingsDataSelector } from '@modules/settings/selectors/account';

export const SettingsListContainer = () => {
  const settingsData = useSelector(settingsDataSelector);
  const dispatch = useDispatch();

  const onSettingsChange = ({ category, section, parameter, value }: ChangeSettings) => {
    dispatch(
      settingsSaveRoutine({
        value: {
          [category]: {
            sections: {
              [section]: {
                data: {
                  [parameter]: {
                    value
                  }
                }
              }
            }
          }
        }
      })
    );
  };

  return (
    <div className="settings scrollable-collapse">
      <CustomScroll>
        <ILSCollapse className="main-collapse" bordered={false} accordion expandIconPosition={ExpandIconPosition.Right}>
          <ILSCollapsePanel key={'panel_password'} header={'Смена пароля'}>
            <SettingsChangePasswordFormComponent />
          </ILSCollapsePanel>
          {Object.entries(settingsData ?? {}).map(([moduleKey, moduleSettings]) => {
            return (
              moduleSettings && (
                <ILSCollapsePanel key={moduleKey} header={moduleSettings.name}>
                  <div className={'short-box'}>
                    <ILSSettingBlockComponent onSettingsChange={onSettingsChange} category={moduleKey} sections={moduleSettings.sections} />
                  </div>
                </ILSCollapsePanel>
              )
            );
          })}
          <ILSCollapsePanel key={'panel_MobileApplication'} header={'Мобильное приложение'}>
            <MobileApplication />
          </ILSCollapsePanel>
          <ILSCollapsePanel key={'panel_CommonSettings'} header={'Общие настройки'}>
            <CommonSettings />
          </ILSCollapsePanel>
        </ILSCollapse>
      </CustomScroll>
    </div>
  );
};

