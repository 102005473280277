import { ILSFormItem, ILSInput, ILSInputNumber, ILSPhoneInput } from '@common/components';
import { ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { FormFieldNames, FormLabels, FormPlaceholders } from '@modules/orders-and-trips/types/form';
import { FormType } from '@modules/orders-and-trips/types/general';
import { FC } from 'react';

interface IProps {
  pointIndex: number;
  showForm: FormType | null;
}

export const Contact: FC<IProps> = ({ pointIndex, showForm }) => {
  if (showForm !== FormType.Trip) return null;
  return (
    <>
      <ILSFormItem
        name={[pointIndex, FormFieldNames.Contact]}
        label={FormLabels.Contact}
        rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.Contact]}
      >
        <ILSInput placeholder={FormPlaceholders.Contact} />
      </ILSFormItem>
      <ILSFormItem
        name={[pointIndex, FormFieldNames.Phone]}
        label={FormLabels.Phone}
        rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.Phone]}
      >
        <ILSPhoneInput placeholder={FormPlaceholders.Phone} />
      </ILSFormItem>
    </>
  );
};
