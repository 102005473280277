import reduceReducers from 'reduce-reducers';
import { initialState } from '../store';
import { detailsTabChangeReducer } from './details-tab-change';
import { monitoringgetEventAllDataReducer } from './get-details';
import { monitoringgetVehicleTrackDataReducer } from './get-track';
import { monitoringSelectOneVehicleReducer } from './select-one-vehicle';
import { activeVehicleReducer } from './active-vehicle';
import { monitoringUpdateLastInfoReducer } from './update-last-info';
import { monitoringSensorEventsReducer } from './get-sensor-events';
import { monitoringPointsStatusesListReducer } from './points-statuses-list';
import { setTableMapSizesReducer } from '@modules/monitoring/children/event-details/reducers/set-table-map-sizes';

// Корневой редьсер подмодуля, объединяет все редьюсеры
const monitoringDetailsRootReducer = reduceReducers(
  initialState,
  detailsTabChangeReducer,
  activeVehicleReducer,
  monitoringgetEventAllDataReducer,
  monitoringUpdateLastInfoReducer,
  monitoringgetVehicleTrackDataReducer,
  monitoringSelectOneVehicleReducer,
  monitoringSensorEventsReducer,
  monitoringPointsStatusesListReducer,
  setTableMapSizesReducer
);

export default monitoringDetailsRootReducer;
