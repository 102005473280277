import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';

export enum AccountAction {
  DICTIONARY_OPERATION = 'ACCOUNT/DICTIONARY_OPERATION',
  GET_INITIAL = 'ACCOUNT/GET_INITIAL',
  ORGANIZATION_TYPES = 'ACCOUNT/ORGANIZATION_TYPES'
}

export enum UserAction {
  DICTIONARY_OPERATION = 'USER/DICTIONARY_OPERATION'
}

export enum AttachmentAction {
  CREATE = 'ATTACHMENT/CREATE',
  INDEX = 'ATTACHMENT/INDEX',
  DELETE = 'ATTACHMENT/DELETE'
}

export const getInitialRoutine = createRoutine(AccountAction.GET_INITIAL);
export const accountRoutine = createRoutine(AccountAction.DICTIONARY_OPERATION);
export const userRoutine = createRoutine(UserAction.DICTIONARY_OPERATION);

export const organizationTypesRoutine = createRoutine(AccountAction.ORGANIZATION_TYPES);

export const accountAttachmentCreateRoutine = createRoutine(AttachmentAction.CREATE);
export const accountAttachmentIndexRoutine = createRoutine(AttachmentAction.INDEX);
export const accountAttachmentDeleteRoutine = createRoutine(AttachmentAction.DELETE);

export const accountRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [accountRoutine];
export const userRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [userRoutine];

