import { FC } from 'react';
import { TripStatus, TripStatusTitle } from '@modules/orders-and-trips/types/trip';
import { isEmpty } from 'lodash';
import { ActiveRecord, PaginationType } from '@modules/orders-and-trips/types/general';
import { ILSSpin } from '@common/components';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { Dictionary } from '@common/types';
import { VirtualBoxContainer } from '@modules/orders-and-trips/containers/vitual-box-container';
import { IVirtualBoxContainerProps } from '@modules/orders-and-trips/types/virtual-list';

interface IProps {
  tripStatus: TripStatus;
  onSetActiveTrip: (ID: number) => void;
  activeTrip: ActiveRecord;
  activeTripCssClass: string | null;
  dataSource: IVirtualBoxContainerProps['dataSource'];
  loadTrips(): void;
  isFetching: boolean;
  pagination: Required<PaginationType>;
  vehicleTypes: VehicleType | null;
}

export const TripCardsContainer: FC<IProps> = ({
  activeTripCssClass,
  isFetching,
  loadTrips,
  tripStatus,
  activeTrip,
  onSetActiveTrip,
  dataSource,
  vehicleTypes
}) => {
  const existTrips = !isEmpty(dataSource[Dictionary.Trip]);
  return (
    <div className="orders-and-trips-cards">
      <ILSSpin tip={'Рейсы загружаются, пожалуйста подождите...'} spinning={isFetching}>
        {existTrips ? (
          <VirtualBoxContainer
            dataSource={dataSource}
            dictionary={Dictionary.Trip}
            listItemComponentProps={{
              activeTripCssClass,
              activeTrip,
              onSetActiveTrip,
              vehicleTypes,
              tripStatus
            }}
            onLoadData={loadTrips}
            key={Dictionary.Trip}
          />
        ) : (
          <>Рейсы со статусом "{TripStatusTitle[tripStatus]}" не найдены</>
        )}
      </ILSSpin>
    </div>
  );
};

