import { ILSButton } from '@common/components';
import { demandCreateButtons } from '@modules/demand/configs/demand-buttons';
import { HeaderCreateButton } from '@modules/demand/types/button';
import { FC } from 'react';

interface IProps {
  handlers?: { [K in HeaderCreateButton]?: () => void };
}

// Компонент для отображения кнопок перехода в хедере модуля
export const ILSDemandButtonsComponent: FC<IProps> = ({ handlers }) => {
  return (
    <div className="create-buttons-wrapper bg-white">
      <div className="button-annotation">Создать</div>
      {demandCreateButtons.map((item) => (
        <ILSButton
          title={item.label}
          key={item.key}
          type="default"
          className="ils-demand-create-button"
          {...item.buttonProps}
          onClick={handlers?.[item.key]}
        >
          <div>{item.label}</div>
        </ILSButton>
      ))}
    </div>
  );
};
