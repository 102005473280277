import { TemplateData } from '../children/templates/types/api';
import { generateReportFromJson } from './generate-report-from-json';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

declare var Stimulsoft: any;

export function downloadDocumentsFromTemplates(templates: TemplateData[]) {
  const reports = mockDownloadDocumentFromTemplate(templates);

  const zip = JSZip();

  //NOTE: формируем набор .pdf файлов для скачивания
  reports.forEach((report, i) => {
    report.renderAsync(() => {
      const blob = new Blob([new Uint8Array(report.exportDocument(Stimulsoft.Report.StiExportFormat.Pdf))], {
        type: 'application/pdf'
      });
      zip.file(`file-${i + 1}.pdf`, blob);
    });
  });

  //NOTE: Ставим скачивание ZIP-архива в очередь макротасок, чтобы дождаться формироваия .pdf файлов
  setTimeout(() => {
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      const currentDate = new Date().getTime();
      const fileName = `combined-${currentDate}.zip`;
      FileSaver.saveAs(zipFile, fileName);
      ILSUserNotify(Notify.Success, `Файл скачан!`);
    });
  }, 500);
}

function mockDownloadDocumentFromTemplate(data: TemplateData[]) {
  return data.map(generateReportFromJson);
}

