import { Depot, Dictionary, Order, PlanStatus, Provider, Restriction, TableRecord, Vehicle, Waypoint } from '@common/types';
import { RouteTotal, TrackDetailsType } from '@common/types/dictionaries/route';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';
import { Coordinates, PolylineCoordinates } from '@common/types/general/geohash';
import { MapLayer } from '@modules/catalog/types/components';
import { SelectedOrders } from '@modules/planning/types';
import { Track } from '@modules/track/types';
import L from 'leaflet';
import { MarkerType } from './marker';

//todo map types типы карт должны лежать в картах
export type PolyWithRecord = Partial<Polygon> & {
  record: TableRecord;
};
export type MarkerWithRecord = Partial<MarkerPlanning> & {
  record: TableRecord;
};
export type MapSettings = {
  edit: boolean;
  remove: boolean;
  type: string;
  draw?: boolean;
};
export type MapItems = {
  markers: {
    marker: MarkerWithRecord;
    lMarker?: L.Marker;
  }[];
  polygons: {
    poly: PolyWithRecord;
    lPoly?: L.Polygon;
  }[];
  layer?: L.FeatureGroup | L.LayerGroup;
};
export type DepotData = {
  ID: Number;
  Name?: string;
  active?: boolean;
  dictionary?: string;
  entity?: string;
  field: string;
  type: string;
  data?: MapLayer[];
};

export enum ActiveType {
  Selected = 'selected',
  Focus = 'focus'
}

export enum LatLonVerified {
  No,
  Yes
}

export type Polygon = {
  color: string | undefined;
  coords: Coordinates;
  type?: string;
  clickData?: any;
};

export interface PolygonPlanning {
  ID: number | string;
  Name?: string | null;
  color: string;
  coords: Coordinates;
  TripID?: number | string | null;
  PlanID?: number | string | null;
  VehicleID?: number | string | null;
}

export type Polyline = {
  active: boolean;
  point: any;
  color: string | undefined;
  weight: number;
  dashed: boolean;
  coords: Coordinates;
  type: string;
  clickData?: any;
};

export type Marker = {
  color: string;
  coords: Coordinates;
  type: string;
  selected: boolean;
  number: number;
  figure: string;
  data?: any;
  clickData?: any;
  pointData?: any;
  text?: string;
  noCluster?: boolean;
};

export interface ActiveItems {
  dictionary: string | null;
  VehicleID?: number | string | null | undefined;
  TripID?: number | string | null;
  WaypointID?: number | string | null;
  PlanID?: number | string | null;
  ctrl?: {
    dictionary?: string | null;
    value: any;
  };
  expand?: boolean;
  expandChild?: boolean;
  type?: ActiveType;
}

export interface ActiveOrders {
  OrderID?: string | number;
  WaypointID?: string | number;
  type: ActiveType;
  Mass?: number;
  Boxes?: number;
  ID?: string | number;
  Volume?: number;
  Pallets?: number;
}

export interface ResolvedOrderMap {
  sourceOrders?: number[];
  targetOrders?: number[];
  waypointID?: number;
}
export interface OrderMap extends ResolvedOrderMap {
  Boxes: number;
  Cost: number;
  ID: number;
  Mass: number;
  OutOfTrip: boolean;
  Pallets: number;
  Restriction: [];
  Volume: number;
  type: MarkerType;
  orderID?: number[];
}

export type TrackActiveData = {
  data: IMapTrack['clickData'] & Partial<IMapTrack['point']> & { tripStatus?: string };
} | null;

export type TrackClickData = {
  PlanID: number | null;
  VehicleID?: number | null;
  TripID?: number | null;
  WaypointID?: number | null;
  dictionary?: Dictionary;
};

export interface IMapTrackData {
  depot: Depot | null | undefined;
  order: Order[] | null;
  isHome: boolean | number | null;
}

export interface IMapTrack {
  color: string;
  coords: PolylineCoordinates;
  forbiddenZoneIDs?: number[];
  vehicle?: RouteTotal['Vehicle'];
  TripID?: number | null;
  tripStatus?: PlanStatus | null;
  trackData?: TrackDetailsType & {
    providerName: Provider;
  };
  waypoint?: WaypointTotal;
  orderID?: (number | string)[] | null;
  number?: number;
  weight?: number;
  ID?: number | string | null;
  dashed?: boolean | string;
  data?: IMapTrackData;
  nativeCoords?: Track['nativeCoords'];
  clickData?: TrackClickData;
  active?: boolean;
  point?: MarkerPlanning;
}

export interface ConvexPolygon {
  color: string;
  coords: Coordinates;
  PlanID?: number | null;
  TripID?: number | null;
  VehicleID?: number | null;
  clickData?: {
    PlanID: number | string | null;
    TripID: number | string | null;
    VehicleID: number | string | null;
    dictionary: string | null;
  };
}

export enum LayerType {
  Polygon = 'polygon',
  Marker = 'marker'
}
export interface Layer {
  ID: number;
  Name: string | null;
  dictionary?: Dictionary;
  active: boolean; //Активно отображение
  disabled?: boolean; //Доступна ли активация
  type: LayerType;
  data: any[];
}

export type MarkerPointClickData = {
  PlanID?: number;
  WaypointID: number | undefined;
  TripID: number | null | undefined;
  VehicleID: number | null | undefined;
  dictionary?: Dictionary;
};
export type MapClickData = TrackClickData | MarkerPointClickData | MarkerOrderClickData;

export type MarkerOrderClickData = {
  ID: number;
};
export interface MarkerPlanning {
  ID?: number;
  color?: string;
  coords: Coordinates;
  type?: string | null;
  vehicle?: Vehicle | null;
  tripID?: number | null;
  waypointID?: number | null;
  orders?: Order[] | null;
  number?: number | string;
  homeNumber?: number;
  isHome?: number | boolean | null;
  figure?: MarkerFigure;
  data?: {
    Mass?: number;
    Pallets?: number;
    Boxes?: number;
    Volume?: number;
    Cost?: number;
    OutOfTrip?: boolean;
    Restriction?: Array<Partial<Restriction>>;
    SourceCoords?: Coordinates | null;
    TargetCoords?: Coordinates | null;
    ID?: number;
    WaypointID?: string | number | null; //NOTE обязателен для MarkerType.Point
    VehicleID?: string | number | null; //NOTE обязателен для MarkerType.Point
    TripID?: string | number | null; //NOTE обязателен для MarkerType.Point
    tripStatus?: PlanStatus; //NOTE обязателен для MarkerType.Point
    type?: MarkerType;
  } & ResolvedOrderMap;
  pointData?: {
    Title?: string | null;
    OrderCode?: string | null;
    SourceAddress?: string | null;
    TargetAddress?: string | null;
    SourceClient?: string | null;
    TargetClient?: string | null;
    Mass?: string | number | null;
    Pallets?: string | number | null;
    CargoCount?: string | number | null;
    UnloadDate?: string | null;
    LoadStart?: string | number | null;
    LoadEnd?: string | number | null;
    UnloadStart?: string | number | null;
    UnloadEnd?: string | number | null;
    PointText?: string | null;
    ClientText?: string | null;
    Address?: string | null;
    Boxes?: number;
    Volume?: number;
    Cost?: number;
    TargetUnloadStart?: string | number | null;
    TargetUnloadEnd?: string | number | null;
    TargetLoadEnd?: string | number | null;
    TargetLoadStart?: string | number | null;
  };
  clickData?: MarkerPointClickData | MarkerOrderClickData;
}

export type Dragging = {
  // markers?: number[] ; //Всегда число, ID (waypoint | order)
  orderIDs?: SelectedOrders;
  waypointIDs?: Array<Waypoint['ID']>;
  originalID?: number; //ID элемента над которым находится DD
  data?: any;
  mapX?: any;
  mapY?: any;
  x?: number;
  y?: number;
  selected?: boolean;
  type?: MarkerType;
  className?: string;
  width?: number;
  height?: number;
  //NOTE появляются только на dragEnd
  tripStatus?: PlanStatus | undefined; //NOTE появляются только на dragEnd
  tripIds?: number[] | null;
};

export enum MapMarkerSelectType {
  Selected = 'selected',
  PartiallySelected = 'partially',
  None = ''
}

export enum MarkerFigure {
  Triangle = 'triangle',
  Circle = 'circle',
  Square = 'square'
}
export enum MarkerFigureWidth {
  Default,
  Hover,
  Select
}

export enum MarkerDataAttribute {
  MarkerType = 'data-type',
  WaypointID = 'data-waypointID',
  OrderID = 'data-orderID',
  OrderIDs = 'data-orderIDs' //NOTE JSON с массивом ордеров
}
