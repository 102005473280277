import { Menu, MenuItemProps, MenuProps, SubMenuProps } from 'antd';
import { MenuItemGroupProps } from 'antd/lib/menu';
import { FC, Key, ReactNode } from 'react';
import { MenuCustomProps, MenuItem } from './types';

const { SubMenu } = Menu;

export const ILSMenu: FC<MenuProps | MenuCustomProps> = (props) => {
  return <Menu {...props} />;
};

export const ILSSubMenu: FC<SubMenuProps> = (props) => {
  return <SubMenu {...props} />;
};

export const ILSMenuItem: FC<MenuItemProps> = (props) => {
  return <Menu.Item {...props} />;
};

export const ILSMenuItemGroup: FC<MenuItemGroupProps> = (props) => {
  return <Menu.ItemGroup {...props} />;
};

export function getMenuItem(label: ReactNode, key: Key, icon?: ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}
