import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { createPlanRoutine } from '@modules/planning/actions';

export const createPlanReducer = createReducer(initialState, {
  [createPlanRoutine.REQUEST]: onRequest,
  [createPlanRoutine.SUCCESS]: (state) => state,
  [createPlanRoutine.FAILURE]: onFailure,
  [createPlanRoutine.FULFILL]: onFulfill
});
