import { ILSModal } from '@components/index';
import { FC, ReactNode } from 'react';
import { ButtonText, ValidateInfoType } from '@modules/planning/children/by-date/types/tender-from-planner';
import { ModalProps } from 'antd';
import { merge } from 'lodash';

interface IProps {
  text: ButtonText;
  handler: () => void;
  handleShow(): void;
  visible: boolean;
  rowsAmount: number;
  validateInfo?: ValidateInfoType;
}

export const PlannerTenderPlatformConfirmModal: FC<IProps> = ({ handler, text, handleShow, visible, rowsAmount, validateInfo }) => {
  let title: string | ReactNode = `Подтвердите действие.`;
  let additionalProps: ModalProps = {};
  let children: string | ReactNode = text.modalTitle;

  if (validateInfo?.success === false) {
    children = validateInfo.errorText;
    merge(additionalProps, validateInfo.modalProps);
  }

  return (
    <ILSModal
      title={title}
      visible={visible}
      onOk={handler}
      onCancel={handleShow}
      okText={rowsAmount > 1 ? `${text.onOkText} (${rowsAmount})` : text.onOkText}
      cancelText="Нет"
      className="trip-tender-platform-modal"
      {...additionalProps}
    >
      {children}
    </ILSModal>
  );
};
