import { RouteGraphStore } from '@modules/road-graph/common/types/store';

const initialState: RouteGraphStore = {
  isFetching: false,
  error: [],
  isSaving: false,
  catalog: {
    Depot: null,
    Info: null
  }
};

export default initialState;
