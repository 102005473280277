import { createReducer } from '@reduxjs/toolkit';
import { getInvoiceTemplateRoutine } from '@common/feature/payment/actions';
import { PaymentInitialState } from '@common/feature/payment/types/general';
import { paymentInitialState } from '@common/feature/payment/store';
import { onFailure } from '@common/utils/helpers/reducers';

export const getInvoiceTemplateReducer = createReducer(paymentInitialState, {
  [getInvoiceTemplateRoutine.REQUEST]: (state: PaymentInitialState) => {
    state.isFetching = true;
    return state;
  },
  [getInvoiceTemplateRoutine.SUCCESS]: (state: PaymentInitialState, action: { payload: { template: string; data: string } }) => {
    const { template, data } = action.payload;
    state.invoice = {
      template,
      data
    };
    return state;
  },
  [getInvoiceTemplateRoutine.FAILURE]: onFailure,
  [getInvoiceTemplateRoutine.FULFILL]: (state: PaymentInitialState) => {
    state.error = [];
    state.isFetching = false;
    return state;
  }
});

