import { FC } from 'react';
import { DASH } from '@common/constants';
import { TripStatus, TripStatusTitle } from '@modules/orders-and-trips/types/trip';
import { TRANSPORT_COMPANY_IMG } from '@modules/orders-and-trips/constants/img';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';

interface IProps {
  tripStatus: TripStatus;
  tripCost?: string;
  tripService: TenderOutFastDeliveryServices | null;
}

export const DetailsStatusPrice: FC<IProps> = ({ tripStatus, tripCost, tripService }) => {
  return (
    <div className="details-header__column">
      <div>
        <span className="details-header__title">Статус рейса: </span>
        <span className="details-header__value">{TripStatusTitle[tripStatus]}</span>
      </div>
      <div>
        <span className="details-header__title">По цене: </span>
        <span className="details-header__value">{tripCost}</span>
      </div>
      <hr />
      <div>{tripService && <img src={TRANSPORT_COMPANY_IMG[tripService]} width={130} />}</div>
    </div>
  );
};

