import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { planningPlanTableContainerTenderButtonsSelector } from '@modules/planning/children/by-date/selectors/components/planning-plan-table-container-tender-buttons';

export const useRoutesTenderButtons = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { recordTableButtons } = useSelector(
    planningPlanTableContainerTenderButtonsSelector({
      push,
      dispatch
    })
  );

  return { tenderButtons: recordTableButtons };
};
