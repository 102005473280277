import { createSelector } from 'reselect';
import { currentTenderSelector } from '@modules/tender-platform/selectors';
import { currentTenderMyOfferSelector } from '@modules/tender-platform/selectors/current-tender';
import { auctionInitialValuesFormSelector } from '@modules/tender-platform/children/tender-search/selectors/components/offer-initial-values';

export const auctionFormSelector = createSelector(
  auctionInitialValuesFormSelector,
  currentTenderMyOfferSelector,
  ({ initialValues, info }, tenderOffer) => {
    return {
      initialValues,
      info,
      tenderOfferID: tenderOffer.ID
    };
  }
);

export const auctionSelector = createSelector(currentTenderSelector, currentTenderMyOfferSelector, (currentTender, tenderOffer) => {
  return {
    tenderID: currentTender.ID,
    tenderOfferID: tenderOffer.ID,
    Price: tenderOffer.Price
  };
});
