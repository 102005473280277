import { Keys, MarkerType, OrderMap, TableName, TableRecord } from '@common/types';
import { deepEqual } from 'fast-equals';
import L from 'leaflet';
import { isArray, isEmpty, isFunction, isObject } from 'lodash';
import { useEffect } from 'react';
import { OnLassoChange, SelectedOrdersLassoType } from '../../types/map-container';

type MarkerOptionsData = OrderMap;

const updateSelectType = (currentType?: SelectedOrdersLassoType, optionType?: MarkerOptionsData['type']) => {
  if (currentType !== SelectedOrdersLassoType.UnResolvedOrder && optionType === MarkerType.Order) {
    return SelectedOrdersLassoType.UnResolvedOrder;
  } else if (!currentType && optionType === MarkerType.Waypoint) {
    return SelectedOrdersLassoType.ResolvedOrder;
  }
  return currentType;
};
export const useMapLasso = (
  lassoLayers: any[] | null,
  onLassoChange: OnLassoChange,
  setLassoLayers: (v: boolean) => void,
  onSelectRows: (selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined) => void
) => {
  useEffect(() => {
    if (!isEmpty(lassoLayers)) {
      const orderKeys: Keys = [],
        layersData: MarkerOptionsData[] = [],
        waypointIDs: Keys = [];
      let type: SelectedOrdersLassoType | undefined;

      const addLayerData = (layerData: MarkerOptionsData[] | MarkerOptionsData) => {
        if (isArray(layerData)) {
          layerData?.forEach((dItem) => {
            if (!layersData.find((item) => item.ID === dItem.ID)) {
              layersData.push(dItem);
              type = updateSelectType(type, dItem.type);
            }
          });
        } else {
          if (isObject(layerData) && !layersData.find((item) => item.ID === (layerData as MarkerOptionsData)?.ID)) {
            layersData.push(layerData);
            type = updateSelectType(type, layerData.type);
          }
        }
      };

      lassoLayers?.forEach((l) => {
        if (
          //Для кластера
          isFunction(l.getAllChildMarkers)
        ) {
          const markers = l.getAllChildMarkers();
          if (markers && markers.length && l?.options?.opacity === 1) {
            markers.forEach((mark: L.Marker) => {
              if (!isEmpty(mark.options.data)) {
                addLayerData(mark.options.data);
              }
            });
          }
        } else if (l._childCount && l._iconObj?.options?.data) {
          const iconData = l._iconObj.options.data;
          if (iconData?.length) {
            const childData = JSON.parse(iconData) as MarkerOptionsData[] | MarkerOptionsData;
            addLayerData(childData);
          }
        } else if (!isEmpty(l?.options?.data)) {
          const dataOwner = l.options?.children?._owner?.memoizedProps?.data;

          if (!isEmpty(dataOwner) && !deepEqual(dataOwner, l.options.data)) {
            addLayerData(dataOwner);
          } else {
            addLayerData(l.options.data);
          }
        }
      });
      layersData.forEach((option) => {
        if (option?.type === MarkerType.Waypoint && type === SelectedOrdersLassoType.ResolvedOrder) {
          const waypointID = option.waypointID;
          const ordersIDs = option.targetOrders;
          if (waypointID && !waypointIDs.includes(waypointID) && ordersIDs?.length) {
            orderKeys.push(...ordersIDs);
            waypointIDs.push(waypointID);
          }
        } else if (option?.type === MarkerType.Order) {
          const ordersIDs = option.ID;
          orderKeys.push(ordersIDs);
        }
      });

      if (type) {
        onLassoChange(orderKeys, type, waypointIDs);
        onSelectRows(orderKeys, [], TableName.PlanningNotDeliveredOrder);
      }
    }
    setLassoLayers(false);
  }, [lassoLayers]);
};

