import { Dictionary, Notify, NotifyDurationInSecond } from '@common/types';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';
import { isEmpty } from 'lodash';
import { ILSUserNotify } from '@common/components';
import { GET_COORDS_ERROR } from '@common/constants/get-coords/get-coords-error';

export type HandleCheckCoordsType = {
  arg: HandleCheckCoordsArgType;
  getCoordinates: (arg: HandleCheckCoordsArgType) => void;
};

export type HandleCheckCoordsArgType = {
  records: { ID: number; coords?: number[]; address?: string; dictionary: Dictionary }[];
  showModal?: boolean;
  updatePriority?: CoordinatesUpdatePriority;
};

export const handleCheckCoords = ({ arg, getCoordinates }: HandleCheckCoordsType) => {
  if (!isEmpty(arg.records)) {
    getCoordinates(arg);
  } else {
    if (!arg.showModal) {
      ILSUserNotify(Notify.Warning, GET_COORDS_ERROR.EmptyDepots, NotifyDurationInSecond.Two, GET_COORDS_ERROR.EmptyDepots);
    } else {
      ILSUserNotify(Notify.Error, GET_COORDS_ERROR.EmptyParams, NotifyDurationInSecond.Two, GET_COORDS_ERROR.EmptyParams);
    }
  }
};
