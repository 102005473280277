/**
 * Функция находит ID всех элементов в массиве с группирующими строками внутри определенной группы.
 * На верхнем уровне находятся группирующие строки, эти объекты имеют children, в которых находятся элементы, которые были сгруппированы по общему dataIndex.
 * Например, группа с ID DepotType_1, включает все элементы, которые имеют такой же тип точки.
 * @param dataSource Array<Record<string, any>> - dataSouce с группирующими строками
 * @param ID ID группы, внутри которой необходимо найти ID всех элементов.
 * @returns возвращает либо массив найденных ID, либо null;
 */

export const findNestedChildrenIDs = (dataSource: Array<Record<string, any>>, ID: string) => {
  const groupRow = findRowByIDInNestedDataSource(dataSource, ID);

  if (groupRow) {
    return getNestedIDs(groupRow);
  }
  return null;
};

/**
 * Функция возвращает определенную строку по ID в массиве с группирующими строками.
 * @param dataSource Array<Record<string, any>> - dataSouce с группирующими строками.
 * @param ID ID строки, которую необходимо найти.
 * @returns возвращает строку, либо undefined;
 */

export const findRowByIDInNestedDataSource = (
  dataSource: Array<Record<string, any>>,
  ID: string | number
): Record<string, any> | undefined => {
  for (let dataRow of dataSource) {
    if (dataRow.ID === ID) {
      return dataRow;
    } else if (dataRow?.children) {
      const result = findRowByIDInNestedDataSource(dataRow?.children, ID);
      if (result) {
        return result;
      }
    }
  }
};

/**
 * Функция перебирает все элементы в определенной группе и возвращает ID всех элементов, не включая ID групп.
 * @param tableRow Record<string, any> - группирующая строка со вложенными элементами в children.
 * @returns возвращает массив ID всех вложенных элементов, либо [];
 */

export const getNestedIDs = (tableRow: Record<string, any>) => {
  const ids: number[] = [];

  if (!tableRow?.children && 'ID' in tableRow) {
    ids.push(tableRow?.ID);
  } else {
    tableRow?.children?.forEach((childRow: Record<string, any>) => {
      ids.push(...getNestedIDs(childRow));
    });
  }

  return ids;
};

