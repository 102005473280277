import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  tenderGetVehicleNDriverRoutine,
  tenderOfferGetDriverRoutine,
  tenderOfferGetVehicleRoutine
} from '@modules/tender-platform/actions';

function* tenderGetVehicleNDriverWorker(
  action: PayloadAction<{
    id: number | string;
  }>
) {
  const { request, failure, fulfill } = tenderGetVehicleNDriverRoutine;
  const { id } = action.payload;

  try {
    yield put(request());
    yield all([put(tenderOfferGetVehicleRoutine.trigger({ id })), put(tenderOfferGetDriverRoutine.trigger({ id }))]);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderGetVehicleNDriverWatcher() {
  yield takeLatest(tenderGetVehicleNDriverRoutine.trigger, tenderGetVehicleNDriverWorker);
}
