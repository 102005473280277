import { Tender } from '@modules/tender-platform/types';
import { Errors, IndexedArray, Plan, Restriction, User } from '@common/types';
import { AccountCarriers, Carriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { LotTemplate } from '@modules/tender-platform/types/lot';
import { DataForTenderCreation } from '@modules/tender-platform/children/tender-own/types/route-tenders';

export enum TenderAction {
  Start = '',
  Tender = 'tender',
  Create = 'create',
  Lot = 'lot',
  Read = 'read',
  ID = 'ID',
  List = 'list'
}

export type TenderContact = IndexedArray<User> | null;
export type TenderContactState = {
  contactOptions: TenderContact;
  loading: boolean;
  error: string;
};

export type TenderOwnStore = {
  tenderAction: TenderAction;
  tenders: IndexedArray<Tender> | null;
  carriers: { myCarriers: Carriers; search: AccountCarriers; loading: boolean };
  templateList: LotTemplate | undefined;
  tenderData: Tender | null;
  isFetching: boolean;
  error: Errors;
  users: IndexedArray<User> | string | null;
  accountUsers: IndexedArray<User> | null;
  contacts: TenderContactState;
  restrictions: Array<Restriction> | null;
  dataForGroupTenderCreation: DataForTenderCreation | {};
  initialDataForGroupTenderCreation: {};
  planID: Plan['ID'] | null;
  replicateDocument: boolean;
};

export type TenderOwnState = { TenderOwn: TenderOwnStore };
