import { FC, memo } from 'react';
import { ILSButtonTooltip } from '@common/components';
import { ASSETS_DIRECTORY_PATHS } from '@common/constants/general/path';
import { IILSTableProps, ITablePropsFunction, ShowDeleted, TableRecord } from '@common/types';
import { Key } from 'antd/es/table/interface';

interface IProps
  extends Pick<ITablePropsFunction<TableRecord>, 'handleReload'>,
    Pick<IILSTableProps<TableRecord>, 'showDeleted' | 'setShowDeleted'> {
  selectedRowKeys: Array<Key>;
  isFetching: boolean;
}

const iconSrc = `${ASSETS_DIRECTORY_PATHS.catalog}/show-deleted`;

const ILSShowDeletedActionButton: FC<IProps> = ({
  isFetching,
  handleReload,
  selectedRowKeys = [],
  showDeleted = ShowDeleted.No,
  setShowDeleted
}) => {
  const onClick = () => {
    const updatedShowDeleted = showDeleted === ShowDeleted.Yes ? ShowDeleted.No : ShowDeleted.Yes;
    handleReload?.(selectedRowKeys, updatedShowDeleted);
    setShowDeleted?.(updatedShowDeleted);
  };

  const title = showDeleted ? 'Выключить режим отображения удаленных' : 'Включить режим отображения удаленных';
  return (
    <ILSButtonTooltip
      aria-label={title}
      className={'show-deleted'}
      placement="bottom"
      title={title}
      isLoading={isFetching}
      disabled={isFetching}
      onClick={onClick}
      icon={iconSrc}
    />
  );
};

export default memo(ILSShowDeletedActionButton);
