import React, { FC, useContext } from 'react';
import '../styles.less';
import { roadGraphSetSegmentRoutine } from '@modules/road-graph/editor/actions';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSButton } from '@components/index';
import ILSRoadGraphEditorSegmentEditForm from '@modules/road-graph/editor/components/edit-form';
import { ReactSVG } from 'react-svg';
import { RoadGraphEditorContext } from '@modules/road-graph/editor/context';
import { geoHashSelector, roadsSelector } from '@modules/road-graph/editor/selectors';

const ILSRoadGraphEditorSegmentEditContainer: FC = () => {
  const { edit, setEdit } = useContext(RoadGraphEditorContext) ?? {};
  const dispatch = useAppDispatch();
  const roads = useAppSelector(roadsSelector);
  const geoHash = useAppSelector(geoHashSelector);

  const handleSave = (values: any) => {
    if (!edit?.edge) return;
    dispatch(
      roadGraphSetSegmentRoutine({
        values: {
          ...values,
          edge: edit?.edge
        },
        roads,
        points: geoHash
      })
    );
    setEdit && setEdit(null);
  };

  return (
    <>
      {edit && (
        <div className="summary-info rounded bordered">
          <h3 className="pt-2 px-3 pb-1">Участок дороги</h3>
          <ILSButton className="close" onClick={() => setEdit && setEdit(null)} icon={<ReactSVG src={'/assets/icons/close.svg'} />} />
          <ILSRoadGraphEditorSegmentEditForm handleSave={handleSave} />
        </div>
      )}
    </>
  );
};

export default ILSRoadGraphEditorSegmentEditContainer;
