import { createModuleAction } from '@common/utils/general/create-module-action';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { EventDetailsAction } from '../types/api';
import { TabChangePayload, TabType } from '../types/event-details-tabs';

const MODULE_NAME = 'MONITORING_EVENT_DETAILS';

export const createDetailsRoutine = (name: EventDetailsAction) => createRoutine(createModuleAction(MODULE_NAME, name));

export const getEventAllDataRoutine = createDetailsRoutine(EventDetailsAction.GetDetails); // Получает все необходимые данные
export const setActiveVehicleRoutine = createDetailsRoutine(EventDetailsAction.SetActiveVehicle); // Устанавливает активным выбранный автомобиль
export const getTrackDataRoutine = createDetailsRoutine(EventDetailsAction.GetTrack); // Получает данные трэка
export const selectOneVehicleRoutine = createDetailsRoutine(EventDetailsAction.SelectOneVehicle); // Выбирает один автомобиль для работы фокусировки
export const updateLastInfoRoutine = createDetailsRoutine(EventDetailsAction.UpdateLastInfo); // Обновляет последнюю поступившую информацию
export const getSensorEventsRoutine = createDetailsRoutine(EventDetailsAction.GetSensorEvents); // Получает события датчиков
export const getPointsStatusesList = createDetailsRoutine(EventDetailsAction.GetPointsStatusesList); // Получает список со статусами точек
export const setTableMapSizesRoutine = createDetailsRoutine(EventDetailsAction.SetTableMapSizes); // Записывает размеры карты в память браузера

// Устанавливает активным нужный таб
export const setActiveTabRoutine = createRoutine(createModuleAction(MODULE_NAME, EventDetailsAction.SetActiveTab), {
  trigger: (payload: TabChangePayload) => ({ ...payload }),
  success: (payload: { activeTab?: TabType }) => ({ ...payload })
});

// Работа с нотификациями
export const monitoringEventDetailsRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [getEventAllDataRoutine];
