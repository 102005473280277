import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Tender, TenderState } from '@modules/tender-platform/types';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { tenderReadRoutine } from '@modules/tender-platform/actions';
import initialState from '@modules/tender-platform/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const readTenderReducer = createReducer(initialState, {
  [tenderReadRoutine.REQUEST]: onRequest,
  [tenderReadRoutine.FAILURE]: onFailure,
  [tenderReadRoutine.FULFILL]: onFulfill,
  [tenderReadRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      tender: Tender | null;
    }>
  ) => {
    const { tender } = action.payload;
    if (tender) {
      state.tenderAction = TenderAction.ID;
      state.currentTender = tender;
    } else {
      state.tenderAction = TenderAction.Create;
      state.currentTender = tender;
    }
    return state;
  }
});
