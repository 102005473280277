import { FC } from 'react';
import { ILSDropdown } from '@components/navigation/dropdown';
import { ILSMenu } from '@components/navigation/menu';
import { ILSButton } from '@components/index';
import { ILSDropdownButtonProps } from '@components/general/buttons/types';

export const ILSDropdownButton: FC<ILSDropdownButtonProps> = ({ text, items, dropdown, button, onMenuClick }) => {
  const menu = <ILSMenu items={items} onClick={onMenuClick} />;

  return (
    <ILSDropdown overlay={menu} placement="bottomLeft" trigger={['click']} {...dropdown}>
      <ILSButton size={'small'} type="text" {...button}>
        {text}
      </ILSButton>
    </ILSDropdown>
  );
};
