import { getTemplateRoutine, resetCurrentTemplate } from '@modules/documents/children/templates/actions';
import { Dispatch } from 'redux';
import { DocumentReport, DocumentTemplate } from '@modules/documents/children/templates/types/api';

export const getNewTemplate =
  ({
    dispatch,
    template,
    projectId
  }: {
    dispatch: Dispatch;
    template: DocumentReport | DocumentTemplate | undefined;
    projectId?: number | null;
  }) =>
  (deps: object) => {
    dispatch(resetCurrentTemplate());
    dispatch(getTemplateRoutine({ templateId: template?.ID, deps: { ...deps, projectId } }));
  };
