import { TabPaneProps, Tabs, TabsProps } from 'antd';
import { FC } from 'react';

const { TabPane } = Tabs;

export const ILSTabs: FC<TabsProps> = (props) => {
  return <Tabs {...props} />;
};

export const ILSTabPane: FC<TabPaneProps> = (props) => {
  return <TabPane {...props} />;
};
