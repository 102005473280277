import { Dictionary, IndexedArray, Options, TableColumnName } from '@common/types';
import { checkAndCreateArrayFromObject, getDefaultLabelByDictionary } from '@common/utils';
import { LabelField } from '@common/types/components/select';
import { getLabelForSelect } from '@components/decorators';
import { EMPTY_STRING } from '@common/constants';

export const generateSelect = <T extends Partial<Record<TableColumnName, any>>>({
  data,
  valueField = TableColumnName.ID,
  labelField = TableColumnName.Name,
  isValueNumber = true,
  dictionary = Dictionary.Empty
}: {
  data: T | Array<T> | IndexedArray<T> | undefined | null;
  valueField?: string;
  labelField?: LabelField<any>;
  isValueNumber?: boolean;
  multiple?: boolean;
  dictionary?: Dictionary;
}): Options => {
  return checkAndCreateArrayFromObject(data).map((record) => {
    const value = isValueNumber ? record?.[valueField] ?? EMPTY_STRING : String(record?.[valueField]);
    const label = getLabelForSelect(labelField, record) ?? getDefaultLabelByDictionary(value, dictionary);
    return {
      value,
      label
    };
  });
};
