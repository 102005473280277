import { defineModule } from '@core/index';
import { tenderSearchReducer } from '@modules/tender-platform/children/tender-search/reducers';
import { ILSTenderSearch } from './containers';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import * as tenderSearchRootSagas from '@modules/tender-platform/children/tender-search/sagas';
import { ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';

export default defineModule({
  title: ModuleTitle.TenderSearch,
  path: ModulePath.TenderSearch,
  component: ILSTenderSearch,
  reducer: tenderSearchReducer,
  sagas: getAllModuleSagas(tenderSearchRootSagas),
  permission: TENDER_PLATFORM_PERMISSIONS.TENDER_SEARCH,
  persist: false
});
