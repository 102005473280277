import { FC, useContext, useState } from 'react';
import { ILSFormItem, ILSInput, ILSRadioGroup, ILSSelect, ILSTextArea } from '@components/index';
import { Module } from '@common/types';
import { useSelector } from 'react-redux';
import { TENDER_LOT_POSITIONS, TENDER_LOT_POSITIONS_VOLUMES, TENDER_LOT_POSITIONS_WHL } from '@modules/tender-platform/constants/lot-card';
import { Placeholder } from '@common/constants/general';
import { getPlaceholder } from '@common/constants/general/form';
import { LotFormFieldLabel, LotPosition, PositionType } from '@modules/tender-platform/types';
import { InputType } from '@components/data-entry/input';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { LOT_POSITION_VOLUME_OPTIONS } from '@modules/tender-platform/constants/options';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { onKeyPressInteger } from '@common/utils/helpers/form/validateIntegerInput';
import { restrictionsOptionsSelector } from '@modules/tender-platform/children/tender-own/selectors/restrictions';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';

interface IProps {
  fromPlanner: boolean;
  disabled: boolean;
  number: number;
}

export const TenderPlatformCargoFieldsComponent: FC<IProps> = ({ number, disabled, fromPlanner }) => {
  const tenderPlatformChildrenModule = useContext(ILSTenderModuleContext);
  const options = useSelector(restrictionsOptionsSelector(tenderPlatformChildrenModule));
  const [position, setPosition] = useState<PositionType>(() => (fromPlanner ? PositionType.Volume : PositionType.WHL));

  const onTypeChange = (e: CheckboxChangeEvent) => {
    const { value } = e.target;
    setPosition(value);
  };

  return (
    <fieldset className="block-item Cargo">
      {TENDER_LOT_POSITIONS.map((position) => (
        <ILSFormItem
          key={position}
          className={`block-item-${position}`}
          label={LotFormFieldLabel[position]}
          name={[TenderFormField.TenderLotPosition, number, LotPosition[position]]}
          rules={[
            {
              required: !disabled && !fromPlanner && (position === LotPosition.Mass || position === LotPosition.Amount),
              message: position === LotPosition.Mass ? 'Пожалуйста, введите массу' : 'Пожалуйста, введите количество'
            }
          ]}
        >
          {position === LotPosition.RestrictionID ? (
            <ILSSelect
              allowClear
              showArrow={!disabled && !fromPlanner}
              placeholder={getPlaceholder(Placeholder.ChooseValue, disabled)}
              fieldNames={DEFAULT_FIELD_NAMES}
              options={options}
              disabled={disabled || fromPlanner}
            />
          ) : position === LotPosition.Comment ? (
            <ILSTextArea rows={4} disabled={disabled} placeholder={getPlaceholder(Placeholder.AddComment, disabled)} />
          ) : position === LotPosition.Amount ? (
            <ILSInput
              min={0}
              step="1"
              type={InputType.Number}
              placeholder={getPlaceholder(Placeholder.Integer, disabled)}
              disabled={disabled || fromPlanner}
              onKeyPress={onKeyPressInteger}
            />
          ) : (
            <ILSInput
              min={0}
              step="0.01"
              type={InputType.Number}
              placeholder={getPlaceholder(Placeholder.Float, disabled)}
              disabled={disabled || fromPlanner}
            />
          )}
        </ILSFormItem>
      ))}
      {tenderPlatformChildrenModule === Module.TenderSearch ? (
        TENDER_LOT_POSITIONS_VOLUMES.map((position) => {
          return (
            <ILSFormItem
              key={position}
              className={`block-item-${position}`}
              label={LotFormFieldLabel[position]}
              name={[TenderFormField.TenderLotPosition, number, LotPosition[position]]}
            >
              <ILSInput step="0.01" min={0} type={InputType.Number} placeholder={getPlaceholder(Placeholder.Float, disabled)} disabled />
            </ILSFormItem>
          );
        })
      ) : (
        <>
          <ILSFormItem className={`block-item-position`}>
            <ILSRadioGroup
              value={position}
              options={LOT_POSITION_VOLUME_OPTIONS}
              className="lot-radio"
              disabled={disabled || fromPlanner}
              optionType="button"
              buttonStyle="solid"
              onChange={onTypeChange}
            />
          </ILSFormItem>
          {position === PositionType.WHL ? (
            TENDER_LOT_POSITIONS_WHL.map((position) => {
              return (
                <ILSFormItem
                  key={position}
                  className={`block-item-${position}`}
                  label={LotFormFieldLabel[position]}
                  name={[TenderFormField.TenderLotPosition, number, LotPosition[position]]}
                >
                  <ILSInput
                    step="0.01"
                    min={0.01}
                    type={InputType.Number}
                    placeholder={getPlaceholder(Placeholder.Float, disabled)}
                    disabled={disabled || fromPlanner}
                  />
                </ILSFormItem>
              );
            })
          ) : (
            <ILSFormItem
              key={LotPosition.Volume}
              className={`block-item-${LotPosition.Volume}`}
              label={LotFormFieldLabel.Volume}
              name={[TenderFormField.TenderLotPosition, number, LotPosition.Volume]}
            >
              <ILSInput
                step="0.01"
                min={0.01}
                type={InputType.Number}
                placeholder={getPlaceholder(Placeholder.Float, disabled)}
                disabled={disabled || fromPlanner}
              />
            </ILSFormItem>
          )}
        </>
      )}
    </fieldset>
  );
};
