import { ILSTable } from '@common/components';
import { VEHICLE_CATALOG_TABLE_CONFIG } from '@common/models/vehicle/constants';
import { Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { vehicleTableConfigForOrdersNTrips } from '@modules/orders-and-trips/config/vehicle-table';

//TODO cделать props tableName
const TableComponent = (props: any) => {
  return (
    <ILSTable
      {...props}
      scroll={props?.scroll ?? { x: 500, y: 300 }}
      //config={config}
      //tableConfig={config}
      tableName="ordersNTrips"
      className="unresolved-table"
      dictionary={Dictionary.Order}
    />
  );
};

export const OwnVehicleTableComponent = tableCustom(TableComponent, vehicleTableConfigForOrdersNTrips, Dictionary.Vehicle);
