import { ILSUserNotify, ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import {
  EXTERNAL_ALL_TRANSPORT_COMPANY_CONFIRM_TEXT,
  EXTERNAL_TRANSPORT_COMPANY_CONFIRM_TEXT
} from '@modules/orders-and-trips/constants/general';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { head, isEmpty } from 'lodash';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';
import { EMPTY_STRING } from '@common/constants';
import { availableTransportCompanyIntegrationsSelector, preCostSelector } from '@modules/orders-and-trips/selectors/integrations';
import { PreCost, PreCostServiceType, TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';
import { activeTripSelector, tripsContractTemplateSelector } from '@modules/orders-and-trips/selectors/trip';
import { Notify } from '@common/types';
import {
  addOrderToServiceRoutine,
  getPreCostRoutine,
  getTripContractRoutine,
  uploadTripDocumentsRoutine
} from '@modules/orders-and-trips/actions';
import { preCostToDisplay } from '@modules/orders-and-trips/decorators/pre-cost-to-display';
import { MOCK_TRANSPORT_COMPANY } from '@modules/orders-and-trips/mock/transport-company';
import { useModal } from '@common/hooks/components/use-modal';
import { ButtonTypes } from '@common/types/components';
import { uploadPdfFromStimulsoft } from '@common/utils/stimulsoft/upload-pdf-from-stimulsoft';
import { ORDERS_TRIPS_CONTRACT_NAME } from '@modules/orders-and-trips/constants/documents';

export const useExternalTransportCompany = () => {
  const dispatch = useDispatch();
  const { availableTransportCompany, isFetching } = useSelector(availableTransportCompanyIntegrationsSelector);
  const activeTrip = useSelector(activeTripSelector);
  const preCost: PreCost = useSelector(preCostSelector);

  const { visible, handleShow } = useModal();
  const [selectedFastDeliveryServiceName, setSelectedFastDeliveryServiceName] = useState<TenderOutFastDeliveryServices | null>(null);
  const contractTemplate = useSelector(tripsContractTemplateSelector);

  /** Запрашивает предварительную стоимость доставки */
  const getPreCost = (service: TenderOutFastDeliveryServices[] | string) => {
    if (!activeTrip?.ID) {
      ILSUserNotify(Notify.Error, 'Выберите рейс!');
    }
    dispatch(getPreCostRoutine({ tripID: activeTrip?.ID, alias: service }));
  };

  /** Отправляет рейс в транспортную компанию */
  const addOrderToService = (fastDeliveryServiceName: TenderOutFastDeliveryServices) => {
    const activeTripID = activeTrip?.ID ?? null;
    if (activeTripID && activeTripID in preCost) {
      const serviceData: PreCostServiceType[number] = preCost[activeTripID].services?.find(
        (service: PreCostServiceType[number]) => service.name === fastDeliveryServiceName
      );
      dispatch(
        addOrderToServiceRoutine({
          alias: fastDeliveryServiceName,
          tripID: activeTripID,
          extra: head(createArrayFromIndexedArray(serviceData?.prices))
        })
      );
      setSelectedFastDeliveryServiceName(null);
    } else {
      console.error('Не выбран активный рейс');
    }
  };

  /** При открытии модального окна со счетом заявкой сохраняем выбранную транспортную компанию в state, чтобы при submit формы передать в addOrderToService;
   * также находим id стоимости из preCost, чтобы передать в запрос на получение данных для счета-заявки.
   */
  const handleOpenConfirmModal = (fastDeliveryServiceName?: TenderOutFastDeliveryServices) => {
    if (!fastDeliveryServiceName) {
      ILSUserNotify(Notify.Error, 'Нет активного сервиса для отправки данных!');
      return;
    }
    handleShow();
    setSelectedFastDeliveryServiceName(fastDeliveryServiceName);

    const services: PreCostServiceType = preCost[activeTrip?.ID ?? EMPTY_STRING]?.services;
    if (!isEmpty(services)) {
      const currentService = services.find((service) => compareAsString(service.name, fastDeliveryServiceName));
      const prices = head(createArrayFromIndexedArray(currentService?.prices));
      const integrationID = prices?.id;
      dispatch(getTripContractRoutine({ tripID: activeTrip?.ID, alias: fastDeliveryServiceName, integrationID }));
    } else {
      console.error('Необходимо передать integrationID');
    }
  };

  const chooseCompany = (fastDeliveryServiceName?: TenderOutFastDeliveryServices, deliveryAvailable?: boolean) => {
    !deliveryAvailable
      ? ILSUserNotifyConfirm(
          'Внимание',
          fastDeliveryServiceName ? EXTERNAL_TRANSPORT_COMPANY_CONFIRM_TEXT : EXTERNAL_ALL_TRANSPORT_COMPANY_CONFIRM_TEXT,
          () => {
            getPreCost(fastDeliveryServiceName ? [fastDeliveryServiceName] : EMPTY_STRING);
          }
        )
      : handleOpenConfirmModal(fastDeliveryServiceName);
  };

  const uploadContract = (contractFile: File) => {
    return dispatch(uploadTripDocumentsRoutine({ dependentID: activeTrip?.ID, attachment: contractFile, subject: 'contract' }));
  };

  const confirmExternalCompanyExecute = () => {
    if (selectedFastDeliveryServiceName) {
      addOrderToService(selectedFastDeliveryServiceName);
      uploadPdfFromStimulsoft({ template: contractTemplate, fileName: ORDERS_TRIPS_CONTRACT_NAME, onUpload: uploadContract });
      handleShow();
    }
  };

  const submitButtonProps = {
    [ButtonTypes.Cancel]: handleShow,
    [ButtonTypes.Confirm]: confirmExternalCompanyExecute
  };

  return {
    chooseCompany,
    availableTransportCompany: [...availableTransportCompany, ...MOCK_TRANSPORT_COMPANY],
    isFetching,
    preCost: preCostToDisplay(activeTrip, preCost),
    confirmModalVisible: visible,
    handleShowConfirmModal: handleShow,
    confirmExternalCompanyExecute,
    submitButtonProps
  };
};
