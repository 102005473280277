import { Keys } from '@common/types';

/**
 * Функция фильтрации выбранных заявок. Если заявки объединены по клиентам, то в массив выбранных заявок попадают ключи заявок,
 * а также ключи строк группирующих по клиентам.
 * Напр. ['Клиент1', 1, 2, 'Клиент2', 3, 4]
 * После удаления заявок необходимо убрать их и ключи группирующих строк из массива.
 * @param selectedOrders массив из ключей выбранных заявок, а также ключей группирующих строк;
 * @param keys массив ключей заявок на удаление;
 * @returns отфильтрованный массив без ключей элементов, которые необходимо удалить, а также без группирующих строк;
 */

export const filterSelectedOrdersAfterDelete = (selectedOrders: Keys | null, keys: Keys) => {
  if (!selectedOrders?.length || !keys.length) {
    return selectedOrders;
  }

  return (
    selectedOrders?.filter((row) =>
      keys?.every((key) => {
        const isClient = typeof row === 'string';
        const isOrder = key !== row;
        return (isOrder && !isClient) || null;
      })
    ) || null
  );
};

