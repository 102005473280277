import { FC, useEffect, useState } from 'react';
import { ILSChooseProjectModal } from '../components/choose-project';
import { ILSUserNotify } from '@common/components';
import { Notify, Project } from '@common/types';
import { useSelector } from 'react-redux';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { Moment } from 'moment/moment';

interface IProps {
  projectList: Array<Project>;
  getProject: (ID: number, setStatus?: boolean) => void;
  manager?: boolean;
  getPlanManager?: (ID: number, project?: Project) => void;
  visible: boolean;
  onCloseModal?: (date: Moment) => void;
  handleShow(): void;
  currentPickedDate?: Moment;
}

export const ILSChooseProjectContainer: FC<IProps> = ({
  currentPickedDate,
  onCloseModal,
  projectList,
  handleShow,
  getProject,
  manager,
  getPlanManager,
  visible
}) => {
  const activeProjectID = useSelector(activeProjectIdSelector);

  const [selected, setSelected] = useState(projectList?.[0]?.ID);
  useEffect(() => {
    const currentProject = projectList.find((project) => project.ID === activeProjectID);
    setSelected(() => {
      if (currentProject?.ID) {
        return currentProject.ID;
      }
      return projectList?.[0]?.ID;
    });
  }, [projectList, activeProjectID]);
  const onChange = (ID: number) => {
    setSelected(ID);
  };

  const getProjectData = () => {
    if (selected) {
      if (manager && getPlanManager) {
        getPlanManager(selected);
      } else {
        getProject(selected);
      }
      handleShow();
    } else {
      ILSUserNotify(Notify.Error, 'Произошла ошибка');
    }
  };

  const handleCloseModal = () => {
    if (currentPickedDate && onCloseModal) {
      onCloseModal(currentPickedDate);
      handleShow();
    }
  };

  return (
    <ILSChooseProjectModal
      selected={selected}
      visible={visible}
      list={projectList}
      onOk={getProjectData}
      onCancel={handleCloseModal}
      onChange={onChange}
    />
  );
};
