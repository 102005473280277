import { ILSModal, ILSSkeleton } from '@common/components';
import { downloadFileByUrl } from '@common/utils/helpers/download-file-by-url';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { currentDocumentRoutine, setDocumentEnum } from '@modules/documents/actions';
import { getCurrentTemplate } from '@modules/documents/children/templates/selectors';
import ILSDocumentTemplateViewer from '@modules/documents/components/document-template-viewer';
import ILSViewDocMenu from '@modules/documents/components/view-doc-menu';
import ViewDoc from '@modules/documents/components/view-dociment';
import { getCurrentDocumentState, getIsDocumentFetching, getIsViewDocumentModelOpen } from '@modules/documents/selectors';
import React, { FC, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import './styles.less';
import { downloadDocument } from '@modules/documents/children/templates/actions';

export interface DocsState {
  uri: string;
  fileType: string;
  fileName: string;
  id: number | null;
}

const ViewDocumentModal: FC<{ isInbox?: boolean }> = ({ isInbox }) => {
  const dispatch = useAppDispatch();
  const currentDocument = useAppSelector(getCurrentDocumentState);
  const modalOpen = useAppSelector(getIsViewDocumentModelOpen);
  const isFetching = useAppSelector(getIsDocumentFetching);
  const templateData = useAppSelector(getCurrentTemplate);
  const [submit, setSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [docs, setDocs] = useState<DocsState>({
    uri: '',
    fileType: '',
    fileName: '',
    id: null
  });

  useEffect(() => {
    if (currentDocument) {
      setDocs({
        uri: currentDocument.DocumentAttachment[0]?.UrlOriginal,
        fileType: currentDocument.DocumentAttachment[0]?.Extension,
        fileName: currentDocument.DocumentAttachment[0]?.OriginalName,
        id: currentDocument.ID
      });
    }
  }, [currentDocument]);

  const handleClose = () => {
    setFormDisabled(true);
    dispatch(currentDocumentRoutine({ document: null, isOpenModal: false }));
    dispatch(setDocumentEnum(null));
  };

  const handleSubmit = () => {
    setSubmit(!submit);
  };

  const handleDownload = () => {
    if (currentDocument?.DocumentTemplateID) {
      currentDocument?.ID && dispatch(downloadDocument(currentDocument.ID));
    } else {
      downloadFileByUrl(currentDocument?.DocumentAttachment[0].UrlOriginal || '');
    }
  };

  const buttons = [
    {
      key: 1,
      icon: '/assets/icons/close.svg',
      handleClick: handleClose
    },
    {
      key: 2,
      icon: formDisabled ? '/assets/icons/documents/edit.svg' : '/assets/icons/documents/save.svg',
      handleClick: () => (formDisabled ? setFormDisabled(false) : handleSubmit())
    },
    {
      key: 3,
      icon: '/assets/icons/documents/download-modal.svg',
      handleClick: () => handleDownload()
    }
  ];

  return (
    <ILSModal
      width="60vw"
      visible={modalOpen}
      onCancel={handleClose}
      footer={false}
      keyboard={false}
      closable={false}
      destroyOnClose={true}
      className={'modal-body'}
    >
      <div className={'buttons-container'}>
        {!isInbox ? (
          buttons.map(({ icon, handleClick, key }) => <ReactSVG key={key} onClick={handleClick} className={'button '} src={icon} />)
        ) : (
          <ReactSVG key={buttons[0].key} onClick={buttons[0].handleClick} className={'button '} src={buttons[0].icon} />
        )}
      </div>
      <ILSViewDocMenu submit={submit} setFormDisabled={setFormDisabled} docs={docs} formDisabled={formDisabled} isInbox={isInbox} />
      <ILSSkeleton loading={isFetching} active>
        {docs.uri ? (
          <div className={'view-container'}>
            {docs.fileName && (
              <div className={'document-header'}>
                <div className={'header-name'}>{docs.fileName}</div>
              </div>
            )}
            <ViewDoc doc={docs} />
          </div>
        ) : currentDocument?.DocumentTemplateID ? (
          <ILSDocumentTemplateViewer height={'79vh'} templateData={templateData} scrollbarsMode />
        ) : (
          <div className={'no-linked-doc'}>Нет привязанного документа</div>
        )}
      </ILSSkeleton>
    </ILSModal>
  );
};
export default ViewDocumentModal;
