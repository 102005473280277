import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import Reference from './containers';
import reference from './reducers';
import { referenceWatcher } from './sagas';

export default defineModule({
  title: 'Справка',
  path: '/reference',
  component: Reference,
  reducer: reference,
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/reference.svg',
  sagas: [referenceWatcher()]
});
