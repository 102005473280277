import { FakePreCostProposals, FakePreCostResponse } from '@modules/orders-and-trips/types/fake-pre-cost';
import { head, isEmpty } from 'lodash';
import { getProposalsFromServices } from '@modules/orders-and-trips/helpers/fake-pre-cost/get-proposals-from-services';

export const getProposalList = (fakePreCost: FakePreCostResponse): FakePreCostProposals => {
  if (!fakePreCost) return [];

  const preCost = head(Object.values(fakePreCost));
  if (isEmpty(preCost)) return [];

  const { services, tenderId } = preCost;
  if (isEmpty(services)) return [];
  return services.map((service) => getProposalsFromServices(service, tenderId)).flat();
};
