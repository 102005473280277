import { ILSButton, ILSCheckbox } from '@components/index';
import CustomScroll from 'react-customscroll';
import { FC, useEffect } from 'react';
import { Dictionary } from '@common/types';
import { LayerMenu } from '@modules/monitoring/types/layer';

interface IPropsILSMapMenuComponent {
  setShow: (a: boolean) => void;
  menu: LayerMenu[] | null;
  onChange: (value: boolean | null, item?: { ID: number } | null, clear?: boolean | null) => void;
  table?: boolean | null;
  active?: (string | number)[] | null;
  dictionary?: Dictionary;
  handleDictionaryLoad?(): void;
}
export const ILSMonitoringMapMenuComponent: FC<IPropsILSMapMenuComponent> = ({
  setShow,
  menu,
  onChange,
  table,
  active,
  dictionary,
  handleDictionaryLoad
}) => {


  useEffect(() => {
    handleDictionaryLoad?.()
  }, []);
  return (
    <div className={`p-1 bordered ${table ? 'table-btn-menu' : 'map-btn-menu'}`}>
      <div className="my-2 menu-content px-2">
        <CustomScroll>
          {Boolean(menu && menu?.length) ? (
            menu?.map((item) => (
              <div className="mb-2" key={item.ID}>
                <ILSCheckbox
                  checked={Boolean(active?.includes(item.ID))}
                  onChange={(e) => {
                    onChange(e.target.checked, item);
                  }}
                >
                  {item.name}
                </ILSCheckbox>
              </div>
            ))
          ) : (
            <span>В данном каталоге специфики не найдены</span>
          )}
        </CustomScroll>
      </div>
      <div className="flex-between-center m-1">
        <ILSButton onClick={() => onChange(null, null, true)}>Сброс</ILSButton>
        <ILSButton type="primary" onClick={() => setShow(false)}>
          OK
        </ILSButton>
      </div>
    </div>
  );
};

