import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/account/store';
import { accountRoutine } from '@modules/account/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { AccountReducerPayload } from '@modules/account/types/api';
import { DictionariesEndpoint } from '@common/types';

export const accountReducer = createReducer(initialState, {
  [accountRoutine.REQUEST]: onRequest,
  [accountRoutine.SUCCESS]: (state, action: AccountReducerPayload) => {
    const { data, operation } = action.payload;
    switch (operation) {
      case DictionariesEndpoint.Index:
      case DictionariesEndpoint.Update:
        state.data = data;
        break;
      case DictionariesEndpoint.getRoleEnum:
        state.accountRoles = Object.values(data);
        break;
      default:
        break;
    }
    return state;
  },
  [accountRoutine.FAILURE]: onFailure,
  [accountRoutine.FULFILL]: onFulfill
});
