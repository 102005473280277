import { Dictionary } from '@common/types';
import { IDefaultState } from '@modules/catalog/types/store';
import { put, select } from 'redux-saga/effects';
import { catalogReadRoutine } from '@modules/catalog/actions';
import { getCurrentFiltersFromStore } from '@modules/catalog/helpers/sagas/get-current-filters-from-store';
import { catalogDictionaryFiltersSelector } from '@modules/catalog/selectors';

export function* refreshWithCurrentFilters(dictionary: Dictionary) {
  const FilterProjectDictionary: Pick<IDefaultState, 'showDeleted' | 'projectID'> = yield select((state) =>
    catalogDictionaryFiltersSelector(state, dictionary)
  );
  yield put(
    catalogReadRoutine.trigger({
      ...getCurrentFiltersFromStore(FilterProjectDictionary),
      dictionary,
      withoutNotification: true
    })
  );
}
