import { MomentInput } from 'moment';
import { DateFormat, DateTimeFormat, TimeFormat, TimeStamp } from '@common/types';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general';
import { EMPTY_STRING } from '@common/constants';
import { parseDateFromServer } from '@common/utils';

export const parseDateFromServerToDateString = ({
  date,
  parseFormat,
  interfaceFormat,
  withTime = false
}: {
  date?: MomentInput | TimeStamp | null;
  withTime?: boolean;
  parseFormat?: DateFormat | DateTimeFormat | TimeFormat | null;
  interfaceFormat?: DateFormat | DateTimeFormat | TimeFormat;
}): string => {
  const format = interfaceFormat ?? (withTime ? ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT : ILS_MAIN_INTERFFACE_DATE_FORMAT);
  const output = parseDateFromServer(date, withTime, parseFormat);
  return output.isValid() ? output.format(format) : EMPTY_STRING;
};
