import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { TenderOfferFormValues } from '@modules/tender-platform/children/tender-search/types/offer';

export const checkBeforeTenderOfferAuction = (
  Price: number,
  StartPrice: number,
  MinPrice: number,
  price: number,
  TenderOffer: Partial<TenderOfferFormValues>
) => {
  if (!TenderOffer.VehicleID || (Array.isArray(TenderOffer.VehicleID) && TenderOffer.VehicleID?.length <= 1)) {
    ILSUserNotify(Notify.Error, `Пожалуйста, выберите автомобиль`, 3, 'tender-search');
    return false;
  }
  if (!TenderOffer.DriverID) {
    ILSUserNotify(Notify.Error, `Пожалуйста, выберите водителя`, 3, 'tender-search');
    return false;
  }
  if (price === Price) {
    ILSUserNotify(Notify.Warning, 'Предложенная сумма не может равняться текущему предложению', NotifyDurationInSecond.Three);
    return false;
  }
  if (price < MinPrice) {
    ILSUserNotify(Notify.Warning, 'Предложенная сумма должна больше минимальной цены', NotifyDurationInSecond.Three);
    return false;
  }

  if (Boolean(StartPrice) && price > StartPrice) {
    ILSUserNotify(Notify.Warning, 'Предложенная сумма должна меньше стартовой цены', NotifyDurationInSecond.Three);
    return false;
  }

  return true;
};
