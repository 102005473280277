import { PlanningResult } from '@modules/planning/children/by-date/containers/result/result';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NO_PROJECT_CHOSEN } from '@modules/planning/children/by-date/constants';
import { PlannerPlanningMenuContainer } from '@modules/planning/containers/menu';
import { useCheckPlanning, useProjectAndPlanQueryParams } from '@modules/planning/children/by-date/hooks';
import { planningByDateSelector } from '@modules/planning/children/by-date/selectors/components/by-date';
import { projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { ILSSpinCenter } from '@common/components';

export const PlanningByDateContainer: FC = () => {
  const { noStatus, projectID } = useSelector(planningByDateSelector);
  const projectIsFetching = useSelector(projectIsFetchingSelector);

  useCheckPlanning();
  useProjectAndPlanQueryParams();

  return (
    <>
      {noStatus ? (
        <div className="font-16">
          <PlannerPlanningMenuContainer hasContentForProject={false} />
          {!projectID && <>{NO_PROJECT_CHOSEN}</>}
          {projectIsFetching && <ILSSpinCenter tip={'Загрузка проекта'} />}
        </div>
      ) : (
        <PlanningResult />
      )}
    </>
  );
};
