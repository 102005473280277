import { apiInstance } from '@core/api/instance';
import { IEmulatorAPI } from '../types/api';

/*
 * Необходимые параметры:
 * from - дата начала эмуляции
 * till - дата окончания эмуляции
 * planID - план текущей эмуляции
 * emulationSpeed - выбранная скорость эмуляции
 * values - значения настроек из таблицы настроек для автомобилей
 * vehicleIDs - ID'шники автомобилей участвующих в эмуляции
 */

/**
 * Путь до контролера эмулятора
 */
const baseEmulatorURL = '/NewTms/Emulator/'; // 

export const EmulatorAPI: IEmulatorAPI = {
  getProjects: ({ from, till }) => apiInstance().post(baseEmulatorURL + 'getProjects', { from, till }),
  getPlanVehicles: ({ planID }) => apiInstance().post(baseEmulatorURL + 'getPlanVehicles', { planID }),
  start: ({ planID }) => apiInstance().post(baseEmulatorURL + 'startEmulation', { planID }),
  stop: ({ planID }) => apiInstance().post(baseEmulatorURL + 'stopEmulation', { planID }),
  pause: ({ planID }) => apiInstance().post(baseEmulatorURL + 'pauseEmulation', { planID }),
  checkStatus: ({ planID }) => apiInstance().post(baseEmulatorURL + 'checkStatus', { planID }),
  createConfig: ({ planID, emulationSpeed }) =>
    apiInstance().post(baseEmulatorURL + 'createConfig', {
      planID,
      emulationSpeed
    }),
  getConfig: ({ planID }) => apiInstance().post(baseEmulatorURL + 'getConfig', { planID }),
  updateConfig: ({ planID, emulationSpeed }) =>
    apiInstance().post(baseEmulatorURL + 'updateConfig', {
      planID,
      emulationSpeed
    }),
  saveConfigVehicle: ({ planID, values, vehicleIDs }) =>
    apiInstance().post(baseEmulatorURL + 'saveConfigVehicle', { planID, values, vehicleIDs })
};

