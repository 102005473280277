import { Dictionary, ReferenceTablesData, TableColumnName, Zone } from '@common/types';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { createSelector } from 'reselect';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { compareAsString } from '@common/utils';
import { filterCatalogData } from '@modules/catalog/helpers/catalog-dictionary-filter';

export const deliveryZoneTableOptionsSelector = createSelector(catalogDictionaryDataSelector(Dictionary.Zone), (DeliveryZone) => {
  const zones = filterCatalogData(DeliveryZone, Dictionary.DeliveryZone);

  const refData: ReferenceTablesData = {
    [TableColumnName.FriendZoneID]: {
      options: (record) => {
        return zones.filter((zone: Zone) => {
          return !compareAsString(zone.ID, record.ID);
        });
      },
      dictionary: Dictionary.Zone,
      fieldNames: DEFAULT_FIELD_NAMES
    }
  };
  return { refData };
});
