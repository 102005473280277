import { MetricsData } from '@modules/monitoring/types/store';
import { FC } from 'react';
import { DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../../constants/tables';

interface IProps {
  dashboardName: string;
  data: MetricsData['data'];
}

// Компонент заголовка метрик
export const DashboardMetricsHeader: FC<IProps> = ({ dashboardName, data }) => {
  const total =
    dashboardName !== DashboardTablesNumberMetrics.Served
      ? data?.[dashboardName as DashboardTablesObjMetrics]?.Total ?? 0
      : `${data?.[DashboardTablesNumberMetrics.Served] ?? 0} из ${data?.[DashboardTablesNumberMetrics.Total] || 0}`;

  const unviewed =
    dashboardName !== DashboardTablesNumberMetrics.Total && data?.[dashboardName].Unviewed > 0 ? (
      <sup className="header-index">{data?.[dashboardName].Unviewed}</sup>
    ) : null;

  return (
    <div className="header">
      {total}
      {unviewed}
    </div>
  );
};
