import { isValueType, ValueType } from '@components/data-entry/input/helpers/is-value-type';
import { isNull } from 'lodash';
import { CELL_DEFAULT_ERROR, showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const isValidCellValue = (value: unknown): value is ValueType | undefined | null => {
  if (isNull(value)) return true;
  if (!isValueType(value)) {
    showUserAndDevError({ error: CELL_DEFAULT_ERROR });
    return false;
  }
  return true;
};
