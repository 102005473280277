import { CatalogStateType } from '@modules/catalog/types/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';

export const onFulfill = (
  state: CatalogStateType,
  action: PayloadAction<{
    dictionary: Dictionary;
  }>
) => {
  const { dictionary } = action.payload;
  state[dictionary].isFetching = false;
  state[dictionary].error = [];
  return state;
};

export const onRequest = (
  state: CatalogStateType,
  action: PayloadAction<{
    dictionary: Dictionary;
  }>
) => {
  const { dictionary } = action.payload;
  state[dictionary].isFetching = true;
  state[dictionary].focusRow = null;
  return state;
};
