import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { TenderStatus } from '@modules/tender-platform/types';
import { FC } from 'react';
import { TenderInfo, TenderLotDemandRow, TripInfo } from '../../types/store';
import { DemandTenderLotTripComponent } from './demand-tender-lot-trip';
import { FormatType } from '@common/types/general/date-time';

interface Props {
  listItem: TenderLotDemandRow;
  tenderInfo: TenderInfo;
  source: { name: string; title: string };
  target: { name: string; title: string };
  tripInfo: TripInfo;
}

// Компонент для отображения одной заявки в разделе "Заявки и рейсы"
export const DemandListItemTenderLotComponent: FC<Props> = ({ listItem, tenderInfo, source, target, tripInfo }) => {
  const { DateTime, Data, Owner, Carrier } = listItem;

  return (
    <div className="ils-demand ils-project-wrapper">
      <div className="ils-demand ils-demand-list-item" key={Data.ID}>
        <div className="ils-demand-list-item-wrapper">
          <div className="ils-demand-list-item-project-wrapper">
            <span className="ils-demand-order-background">Заявка</span>
            <span className="ils-demand-project-created">{timestampToDateTimeString(DateTime, FormatType.Date)}</span>
            <span className="ils-demand-project-id" title={source.title !== '' ? `${source.title}` : ''}>
              Откуда: "{source.name}"
            </span>
            <span className="ils-demand-project-id" title={target.title !== '' ? `${target.title}` : ''}>
              Куда: "{target.name}"
            </span>
          </div>

          <div className="ils-demand-list-item-order-lot-wrapper">
            <span className="ils-demand-list-item-order-lot-info">Статус тендера: "{Data?.Tender?.Status?.Text}"</span>
            <span className="ils-demand-list-item-order-lot-info">{Owner ? `Заказчик: "${Owner?.Name || ''}"` : ''}</span>
            <span className="ils-demand-list-item-order-lot-info">
              {Carrier && (TenderStatus.Completed === Data.Tender?.Status?.Alias || TenderStatus.Executed === Data.Tender?.Status?.Alias)
                ? `Исполнитель: "${Carrier?.Name || ''}"`
                : ''}
            </span>
            <span className="ils-demand-list-item-order-lot-info">Тендер ID: "{tenderInfo.ID}"</span>
            <span className="ils-demand-list-item-order-lot-info">Название тендера: "{tenderInfo.name}"</span>
          </div>
        </div>
      </div>

      {Data?.Trips && <DemandTenderLotTripComponent tripInfo={tripInfo} />}
    </div>
  );
};
