import { Notify, NotifyDurationInSecond } from '@common/types';
import { ILSUserNotify, ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';

export const getConfirmExecutionText = ({ isPlanBeforeExecution }: { isPlanBeforeExecution: boolean }) => {
  if (isPlanBeforeExecution) {
    return 'Принять выбранный план к исполнению?';
  } else {
    return 'Отозвать выбранный план с исполнения?';
  }
};

export const handleConfirm = ({
  isPlanChooseCorrect,
  confirmText,
  onConfirm
}: {
  isPlanChooseCorrect: boolean;
  confirmText: string;
  onConfirm(): void;
}) => {
  if (isPlanChooseCorrect) {
    ILSUserNotifyConfirm(confirmText, undefined, onConfirm);
  } else {
    ILSUserNotify(Notify.Error, 'Не выбран план перевозок', NotifyDurationInSecond.Three);
  }
};
