import { createRoutine } from 'redux-saga-routines';

export const getConfigsRoutine = createRoutine('PLANNING_PARAMS_PARAMS/GET_CONFIGS');

export const getConfigTemplateRoutine = createRoutine('PLANNING_PARAMS/GET_TEMPLATE');

export const createConfigRoutine = createRoutine('PLANNING_PARAMS/CREATE_CONFIG');

export const updateConfigRoutine = createRoutine('PLANNING_PARAMS/UPDATE_CONFIG');

export const deleteConfigRoutine = createRoutine('PLANNING_PARAMS/DELETE_CONFIG');

export const importConfigRoutine = createRoutine('PLANNING_PARAMS/IMPORT_CONFIG');

export const controlConfigRoutine = createRoutine('PLANNING_PARAMS/CONTROL_CONFIG');

export const updateExternalConfigRoutine = createRoutine('PLANNING_PARAMS/UPDATE_EXTERNAL_CONFIG');
export const copyConfigRoutine = createRoutine('PLANNING_PARAMS_PARAMS/COPY');

export const paramsRoutinesWithErrorNotifier = [
  copyConfigRoutine,
  getConfigsRoutine,
  getConfigTemplateRoutine,
  createConfigRoutine,
  updateConfigRoutine,
  deleteConfigRoutine,
  importConfigRoutine
];
