import { ILSTable, ILSUserNotify } from '@common/components';
import { Dictionary, Key, Notify } from '@common/types';
import { downloadFileByUrl } from '@common/utils/helpers/download-file-by-url';
import { tableCustom } from '@core/containers/table-custom';
import { tenderDocumentDeleteRoutine, tenderDocumentDownloadRoutine, tenderDocumentUploadRoutine } from '@modules/tender-platform/actions';
import { defaultPermittedTypes } from '@modules/tender-platform/children/tender-own/constants/default-message';
import { PERMITTED_UPLOAD_TENDER_FILE_MAX_SIZE, TENDER_UPLOAD_CONFIGURATION } from '@modules/tender-platform/constants/tender-card';
import { document2Table } from '@modules/tender-platform/utils/decorators/document-to-table';
import { currentTenderSelector, tenderDocumentsSelector } from '@modules/tender-platform/selectors';
import { tenderDocumentsConfig } from '@modules/tender-platform/utils/table-config/documents';
import { TenderDocuments } from '@modules/tender-platform/types';
import { UploadChangeParam } from 'antd/es/upload';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTenderPlatformPermissions } from '@modules/tender-platform/abac/hooks/permissions';

const ILSTenderDocumentsTableComponent: FC = () => {
  const dispatch = useDispatch();
  const { canDeleteDocument, isTenderEditable } = useTenderPlatformPermissions();
  const dataSource = useSelector(tenderDocumentsSelector);
  const currentTender = useSelector(currentTenderSelector);

  const onClick = ({ UrlOriginal, Name }: { UrlOriginal: string; Name: string }) => downloadFileByUrl(UrlOriginal, Name);

  const Download = { icon: 'download', onClick };

  const handleDelete = canDeleteDocument
    ? (ids: Array<TenderDocuments['ID']>) => {
        dispatch(tenderDocumentDeleteRoutine({ ids }));
      }
    : undefined;

  const onFileChange = ({ file }: UploadChangeParam) => {
    const { size, type } = file;
    const isCorrectSize = size && size < PERMITTED_UPLOAD_TENDER_FILE_MAX_SIZE;
    const isCorrectType = type && defaultPermittedTypes.includes(type);
    !isCorrectType && ILSUserNotify(Notify.Error, 'Формат данного файла не доступен для загрузки');
    if (isCorrectSize && isCorrectType) {
      dispatch(
        tenderDocumentUploadRoutine({
          tenderID: currentTender?.ID,
          attachment: file
        })
      );
    }
  };

  const onClickDownload = (selectedRowKeys: Array<Key>) => {
    dispatch(tenderDocumentDownloadRoutine({ selectedRowKeys, currentTender }));
  };

  return (
    <ILSTable
      focusRow={{ key: dataSource?.[0]?.ID }}
      scroll={{ x: 300, y: 500 }}
      handleDownload={onClickDownload}
      handleDelete={handleDelete}
      uploadConfiguration={TENDER_UPLOAD_CONFIGURATION}
      handleUpload={onFileChange}
      dataSource={document2Table(dataSource).map((document) => ({ ...document, Download }))}
      config={{ ...tenderDocumentsConfig, uploading: isTenderEditable }}
    />
  );
};

export default tableCustom(ILSTenderDocumentsTableComponent, tenderDocumentsConfig, Dictionary.TenderDocuments);
