import { Skeleton } from 'antd';
import { SkeletonImageProps } from 'antd/lib/skeleton/Image';
import { FC } from 'react';
import { SkeletonAvatarProps, SkeletonButtonProps, SkeletonInputProps } from './types';
import { SkeletonProps } from 'antd/lib/skeleton/Skeleton';

export const ILSSkeleton: FC<SkeletonProps> = (props) => {
  return <Skeleton {...props} />;
};

export const ILSSkeletonButton: FC<SkeletonButtonProps> = (props) => {
  return <Skeleton.Button {...props} />;
};

export const ILSSkeletonAvatar: FC<SkeletonAvatarProps> = (props) => {
  return <Skeleton.Avatar {...props} />;
};

export const ILSSkeletonInput: FC<SkeletonInputProps> = (props) => {
  return <Skeleton.Input {...props} />;
};

export const ILSSkeletonImage: FC<SkeletonImageProps> = (props) => {
  return <Skeleton.Image {...props} />;
};
