import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { createTemplateRoutine, getDocumentTemplatesRoutine } from '@modules/documents/children/templates/actions';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* createTemplateWorker(action: PayloadAction<number>) {
  try {
    yield call(DocumentTemplatesAPI.createTemplate, { data: { values: [{ Type: action.payload }] } });
    yield put(getDocumentTemplatesRoutine());
    ILSUserNotify(Notify.Success, 'Шаблон успешно создан');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* createTemplateWatcher() {
  yield takeEvery(createTemplateRoutine.trigger, createTemplateWorker);
}
