import { SECONDS_TO_DAYS_MULTIPLIER } from '@common/constants/general/date-time';
import { viewedContent } from '@common/table-configs/monitoring-dashboard/common/cell-viewed-content';
import { DepotCode, IndexedArray } from '@common/types';
import { MonitoringUndeliveredModel } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToTimeStringWithUTC } from '@common/utils/helpers/date-time/date-time';

import { FileAttachment } from '@common/types/dictionaries';
import { isEmpty, isObject } from 'lodash';
import { createWaypointComments } from '../../common/collect-waypoints-comments';
import { getTextViewStatus } from '../../common/forward-status-view';
import { isShouldWaypointBeTaken } from '../../utils/waypoints';

type DataToTableFunction<R> = (arg: {
  dataSource: Array<MonitoringUndeliveredModel> | IndexedArray<MonitoringUndeliveredModel>;
  handleView?: (id: string | number | string[] | number[], value: 0 | 1) => void;
  showAttachment?: (arg: { files: Array<FileAttachment> }) => any;
  getWpTimeZone: (waypointOffset: number | null | undefined) => number;
}) => Array<
  Omit<R, 'LastStatusDT' | 'LastStatus'> & {
    key: string;
    Name: string;
    DriverName: string;
    Phone: string;
    LastStatusDT: string | undefined;
    LastStatus: string | undefined;
    ID: number;
    viewed: 0 | 1;
  }
>;

export const data2table: DataToTableFunction<MonitoringWaypointModel> = ({ dataSource, handleView, showAttachment, getWpTimeZone }) => {
  const res: ReturnType<typeof data2table> = [];
  const viewedCell = viewedContent(1, handleView);
  const notViewedCell = viewedContent(0, handleView);
  const vehicleData = isObject(dataSource) ? Object.values(dataSource) : dataSource;

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (!vehicle.Trip || !Object.keys(vehicle.Trip)?.length) {
      continue;
    }

    for (const tripIndex in vehicle.Trip) {
      const trip = vehicle.Trip[tripIndex];

      if (!trip.Waypoint || !Object.keys(trip.Waypoint)?.length) {
        continue;
      }

      for (const waypointIndex in trip.Waypoint) {
        const waypoint = trip.Waypoint[waypointIndex];
        const viewed: 0 | 1 = waypoint?.Viewed || 0;
        if (
          waypoint?.Depot?.DepotTypeID === Number(DepotCode.Storage) ||
          waypoint?.Depot?.ID === Number(vehicle.StartDepotID) ||
          waypoint?.Depot?.ID === Number(vehicle.EndDepotID)
        ) {
          continue;
        }
        if (!isShouldWaypointBeTaken(waypoint, vehicle)) continue;

        const Driver = trip?.Driver || vehicle?.Driver;
        const Attachments = !isEmpty(waypoint?.Attachments)
          ? showAttachment?.({
              files: Object.values(waypoint.Attachments as Array<FileAttachment> | IndexedArray<FileAttachment>)
            })
          : undefined;
        let { LastComment, LastComment_htmltip } = createWaypointComments(waypoint);

        const rowObj = {
          ...waypoint,
          Address: waypoint?.Depot?.Name || '',
          Attachments,
          Name: vehicle.Name,
          DriverName: Driver?.Name || '',
          WaypointPlanArrive: timestampToTimeStringWithUTC(
            waypoint.ArrivalTime * SECONDS_TO_DAYS_MULTIPLIER + trip.ProjectStart,
            getWpTimeZone(waypoint?.Depot?.UTCOffset)
          ),
          Phone: Driver?.Phone || '',
          key: !waypoint.ID ? `row#${vehicleIndex}#${tripIndex}#${waypointIndex}` : String(waypoint.ID),
          tripStatus: trip.Status, //NOTE для работы с колбеками
          TripID: trip.ID, //NOTE для работы с колбеками
          Comment: waypoint.Comment,
          LastComment,
          LastComment_htmltip,
          LastStatus: getTextViewStatus(waypoint.LastStatus),
          LastStatusDT: waypoint.LastStatusDT
            ? timestampToTimeStringWithUTC(waypoint.LastStatusDT, getWpTimeZone(waypoint?.Depot?.UTCOffset))
            : undefined,
          Photo: '',
          Viewed: viewed ? viewedCell : notViewedCell,
          viewed,
          Favourite: 1,
          WaypointID: waypoint.ID || undefined,
          VehicleID: vehicle.ID
        };

        res.push(rowObj);
      }
    }
  }

  return res;
};
