// Состояние карты
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';
import { ValueType } from '@modules/catalog/types/catalog';
import { catalogMapRoutine } from '@modules/catalog/actions';
import { put, takeLatest } from 'redux-saga/effects';

function* catalogMapWorker(
  action: PayloadAction<{
    dictionary: Dictionary;
    id: KeyType;
    value: ValueType;
  }>
) {
  const { dictionary, id, value } = action.payload;
  const { success, failure, fulfill } = catalogMapRoutine;

  try {
    yield put(success({ dictionary, id, value }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogMapWatcher() {
  yield takeLatest(catalogMapRoutine.trigger, catalogMapWorker);
}
