import { SortDirection } from '@common/types/general';

export const sortedObjectKeys = (obj: object, direction: SortDirection): Array<string> => {
  return Object.keys(obj).sort((a, b) => {
    return direction === SortDirection.Desc ? parseInt(b) - parseInt(a) : parseInt(a) - parseInt(b);
  });
};

export const filterObject = (obj: any, removeElem: any[], filterKey = true) => {
  const asArray = Object.entries({ ...obj });
  let filteredArray: any = [];

  if (filterKey) {
    filteredArray = [...asArray].filter(([key, value]) => !removeElem.includes(key));
  } else {
    filteredArray = [...asArray].filter(([key, value]) => !removeElem.includes(value));
  }
  return Object.fromEntries(filteredArray);
};
