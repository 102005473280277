import { ILSFormItem, ILSInputNumber, ILSSelect } from '@common/components';
import { FormFieldNames, FormLabels, FormSections } from '@modules/orders-and-trips/types/form';
import { DateTimeBlock } from '@modules/orders-and-trips/components/forms/blocks/date-time';
import { ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { ActionTypeID } from '@modules/orders-and-trips/types/trip';
import { FC } from 'react';
import { IEnum, Option } from '@common/types';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { Field } from '@common/constants';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { FormType } from '@modules/orders-and-trips/types/general';
import { Contact } from '../blocks/contact';

interface IProps {
  disabled: boolean;
  actionCargo: string[];
  restriction: IEnum<string>[];
  pointIndex: number;
  showForm: FormType | null;
}

export const TargetSection: FC<IProps> = ({ showForm, disabled, actionCargo, restriction, pointIndex }) => {
  return (
    <>
      <div className="order-action-form-location flex-horizontal">
        <ILSFormItem
          name={[pointIndex, FormFieldNames.TargetID]}
          label={FormLabels.Target}
          rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.TargetID]}
        >
          <ILSSuggestionAutoComplete disabled={disabled} field={Field.Address} />
        </ILSFormItem>
      </div>
      {actionCargo.map((_, index) => {
        return (
          <div className="target-cargo flex-horizontal" key={index}>
            <ILSFormItem
              name={[pointIndex, FormSections.Cargo, FormFieldNames.RestrictionID, index]}
              label="Специфика"
              rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.RestrictionID]}
            >
              <ILSSelect options={restriction} placeholder={'Выберите специфику груза'} disabled={disabled} />
            </ILSFormItem>
            <ILSFormItem
              key={FormSections.Cargo + index + FormFieldNames.Mass}
              name={[pointIndex, FormSections.Cargo, FormFieldNames.Mass, index]}
              label={LotFormFieldLabel.Mass}
              rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.Mass]}
            >
              <ILSInputNumber placeholder={'Масса груза'} disabled={disabled} />
            </ILSFormItem>
          </div>
        );
      })}

      <DateTimeBlock
        showForm={showForm}
        key={ActionTypeID.Unload + pointIndex}
        pointIndex={pointIndex}
        pointType={ActionTypeID.Unload}
        disabled={disabled}
      />
      <Contact showForm={showForm} pointIndex={pointIndex} />
    </>
  );
};

