import { useSelector } from 'react-redux';
import { accountSelector, currentUserSelector, tokenSelector } from '@modules/current-user/selectors/auth';

export const useAuth = () => {
  const user = useSelector(currentUserSelector);
  const token = useSelector(tokenSelector);
  const account = useSelector(accountSelector);

  return { isAuth: Boolean(token) };
};
