import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { apiInstance } from '@core/api/instance';
import moment from 'moment';
import { DateFormat } from '@common/types';

const getWidgetDataForPDFBoards = async (
  from: number,
  till: number,
  widget: AnalyticsWidget,
  params?: Array<{ name: string; value: string | number | undefined }>
) => {
  const requestParams = params?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  const { data } = await apiInstance().post('/NewTms/AnalyticsPlanning/getWidgetData', {
    ID: widget.ID,
    From: moment.unix(from as number).format(DateFormat.YYYYMMDDDash),
    Till: moment.unix(till as number).format(DateFormat.YYYYMMDDDash),
    ...requestParams
  });

  return data.data;
};

export default getWidgetDataForPDFBoards;
