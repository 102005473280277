import { useAppSelector } from '@core/hooks';
import { ReplaceParamsKeysFunc, getParamsKeysInFormula, replaceParamsKeysInFormula } from '@modules/catalog/helpers/tariff/parse-tariff';
import { tariffsParserSelector } from '@modules/catalog/selectors/tariffs';
import { ActiveTabType } from '@modules/catalog/types/tariff';
import { createTariffButtonHTML } from '../../components/create-button';

type Arg = {
  formula: string;
};

//TODO Сейчас восстанавливает ActiveTabType.const, нужно еще обработать другие виды
export const useFormulaToEditorContent = ({ formula }: Arg) => {
  const params = useAppSelector(tariffsParserSelector);

  const { formulaParams, paramsKeysInStringSorted } = getParamsKeysInFormula(formula, params);

  const replaceFunc: ReplaceParamsKeysFunc = ({ paramKey }) =>
    createTariffButtonHTML(ActiveTabType.const, {
      desc: formulaParams[paramKey]?.desc,
      example: '',
      name: formulaParams[paramKey]?.name,
      variable: '',
      id: paramKey
    });

  const viewFormula = replaceParamsKeysInFormula({ formula, paramsKeysInStringSorted, replaceFunc });
  return { initFormula: viewFormula };
};

