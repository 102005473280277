import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';
import { IndexedArray } from '@common/types';

export const setWidgetParamsWatcher = (api: IAnalyticsCommonAPI, actions: AnalyticsActions): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<IndexedArray<{ name: string; value: string | number | undefined }>>) {
    const { success, failure, fulfill } = actions.analyticsCommonSetWidgetParamsRoutine;
    try {
      yield put(success(action.payload));
    } catch (error) {
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonSetWidgetParamsRoutine.TRIGGER, worker);
  }

  return watcher;
};
