import { useInterval } from '@common/hooks/use-interval';
import { ModulePath, TableRecord } from '@common/types';
import { compareAsString } from '@common/utils';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringDateRoutine, monitoringMetricsRoutine } from '@modules/monitoring/actions';
import {
  getSensorEventsRoutine,
  getTrackDataRoutine,
  selectOneVehicleRoutine,
  setActiveTabRoutine,
  setActiveVehicleRoutine
} from '@modules/monitoring/children/event-details/actions';
import { tracksSelector, vehicleEventsSelector, vehicleSensorSelector } from '@modules/monitoring/children/event-details/selectors';
import { MonitoringDetailsContextType } from '@modules/monitoring/children/event-details/types/details-context';
import { TabType } from '@modules/monitoring/children/event-details/types/event-details-tabs';
import { MonitoringQueryParams } from '@modules/monitoring/constants';
import { useLoadMetrics } from '@modules/monitoring/hooks/use-load-metrics';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import classNames from 'classnames';
import qs from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getEventAllDataRoutine } from '../actions';
import { ILSMonitoringEventDetailsComponent } from '../components';
import { MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS } from '../configs/api-intervals';
import { useLoadDetails } from '../hooks/use-load-details';
import { useUpdateSensorEvents } from '../hooks/use-load-sensor';
import { useUpdateLastInfo } from '../hooks/use-update-last-info';
import '../styles.less';
import { MonitoringDetailsContext } from './context';

/**
 * Основной контейнер детализации событий
 */
//TODO рефакторинг, распределить функции в хуки
const ILSMonitoringEventDetails = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const prevRef = useRef<any>({
    startDate: null,
    endDate: null
  });

  const { maxInterval, startDate, endDate } = useAppSelector(monitoringDateSelector);
  const VehicleEvents = useAppSelector(vehicleEventsSelector);
  const VehicleTracks = useAppSelector(tracksSelector);
  const vehicleSensor = useAppSelector(vehicleSensorSelector);

  const [selectedVehicleIDs, setSelectedVehicleIDs] = useState<(number | string)[]>([]);

  const onActiveTab = (activeKey: TabType) => {
    dispatch(setActiveTabRoutine({ activeKey }));
  };

  const [waypointId, setWaypointId] = useState<number | undefined>(undefined);
  const [focusOnMap, setFocusOnMap] = useState<boolean>(false);

  const handleRedirect = (name: string) => {
    const url = qs.stringifyUrl({
      url: `${ModulePath.Monitoring}${ModulePath.MonitoringDashboard}`,
      query: {
        [MonitoringQueryParams.TAB_NAME]: name
      }
    });
    history.push(url);
  };

  const handleDateSelect = (date: string[]) => {
    dispatch(
      monitoringDateRoutine({
        date,
        metricsInterval: maxInterval
      })
    );
  };

  const handleTabChange = (activeKey: string | number) => {
    dispatch(setActiveTabRoutine({ activeKey }));
  };

  const handleSelectedRowKeys: MonitoringDetailsContextType['handleSelectedRowKeys'] = (selectedKeys) => {
    setSelectedVehicleIDs(selectedKeys);
    return selectedKeys;
  };

  const fitMapByWaypointId = (waypointId: number | undefined) => {
    setWaypointId(waypointId);
    setFocusOnMap(true);
  };
  const updateSensorEvents = (selectedVehicleId: string | number) => {
    const updates =
      vehicleSensor.lastUpdateInterval?.from === startDate &&
      vehicleSensor.lastUpdateInterval?.till === endDate &&
      compareAsString(selectedVehicleId, vehicleSensor.loadedVehicleId);
    dispatch(getSensorEventsRoutine({ vehicleID: selectedVehicleId, from: startDate, till: endDate, updates }));
  };
  const handleDoubleClick = (record: TableRecord) => {
    if (VehicleTracks.selectedVehicleId !== record.ID) {
      setFocusOnMap(true);
      updateSensorEvents(record.ID);
    }
    dispatch(selectOneVehicleRoutine({ vehicleID: VehicleTracks.selectedVehicleId === record.ID ? '' : record.ID }));
  };

  const loadEventDetails = useLoadDetails({
    dispatch,
    storeData: VehicleEvents,
    updateInterval: MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.EVENT_DETAILS,
    routine: getEventAllDataRoutine,
    selectorData: { startDate, endDate },
    active: true
  });

  const loadTrackDetails = useLoadDetails({
    dispatch,
    storeData: VehicleTracks,
    updateInterval: MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.TRACK,
    routine: getTrackDataRoutine,
    selectorData: { startDate, endDate },
    active: !!VehicleTracks.selectedVehicleId,
    payload: {
      vehicleID: VehicleTracks.selectedVehicleId,
      updates:
        VehicleTracks.selectedVehicleId === VehicleTracks.loadedVehicleId &&
        Object.keys(VehicleTracks.data).length > 0 &&
        VehicleTracks.lastUpdateInterval?.from === startDate &&
        VehicleTracks.lastUpdateInterval?.till === endDate
    }
  });

  const vehicleFocusHandler = (newActiveVehicleID: number | string) => {
    dispatch(setActiveVehicleRoutine({ activeVehicleID: String(newActiveVehicleID) }));
  };

  const updateQueryParams = (activeTab: string | undefined, waypointId: string | number | undefined, focusOnMap: boolean | undefined) => {
    if (activeTab && Object.values(TabType).includes(activeTab as TabType)) {
      onActiveTab(activeTab as TabType);
    }
    if (waypointId && Number(waypointId)) {
      setWaypointId(Number(waypointId));
    }
    setFocusOnMap(Boolean(focusOnMap));
  };

  const clearQueryParams = () => {
    setFocusOnMap(false);
    setWaypointId(undefined);
  };
  useEffect(() => {
    return () => {
      dispatch(selectOneVehicleRoutine({ vehicleID: null }));
    };
  }, []);

  //необходимо для работы фокусировки после перехода из другого подмодуля
  useEffect(() => {
    if (typeof history?.location?.state === 'object') {
      const { activeTab, waypointID, focusOnMap } = history.location.state as {
        [MonitoringQueryParams.ACTIVE_TAB]?: string;
        [MonitoringQueryParams.WAYPOINT_ID]?: number;
        [MonitoringQueryParams.FOCUS_ON_MAP]?: boolean;
      };
      updateQueryParams(activeTab, waypointID, focusOnMap);

      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    if (prevRef.current.startDate && prevRef.current.endDate) {
      dispatch(selectOneVehicleRoutine({ vehicleID: null }));
    }
    if (startDate && endDate) {
      dispatch(
        monitoringMetricsRoutine({
          from: startDate,
          till: endDate,
          selectedVehicleId: VehicleTracks.selectedVehicleId
        })
      );
    }
    prevRef.current.startDate = startDate;
    prevRef.current.endDate = endDate;
  }, [startDate, endDate]);

  useInterval(loadEventDetails, MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.EVENT_DETAILS, [startDate, endDate]);
  useInterval(loadTrackDetails, MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.TRACK, [
    VehicleTracks.selectedVehicleId,
    VehicleTracks.loadedVehicleId
  ]);
  useUpdateLastInfo(selectedVehicleIDs, dispatch);
  useUpdateSensorEvents();
  useLoadMetrics();

  useEffect(() => {
    useLoadDetails({
      dispatch,
      storeData: VehicleEvents,
      updateInterval: MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.EVENT_DETAILS,
      routine: getEventAllDataRoutine,
      selectorData: { startDate, endDate },
      active: true
    });
  }, []);

  const rowClass = (record: TableRecord) => {
    return classNames({ 'selected-row': record.key === VehicleTracks.selectedVehicleId?.toString() });
  };

  const contextValue: MonitoringDetailsContextType = {
    selectedVehicleIDs,
    focusOnMap,
    waypointId,
    fitMapByWaypointId,
    clearQueryParams,
    onChangeActiveTab: handleTabChange,
    handleSelectedRowKeys,
    handleDoubleClick,
    vehicleFocusHandler,
    rowClass
  };

  return (
    <MonitoringDetailsContext.Provider value={contextValue}>
      <ILSMonitoringEventDetailsComponent handleDateSelect={handleDateSelect} handleRedirect={handleRedirect} />
    </MonitoringDetailsContext.Provider>
  );
};

export default ILSMonitoringEventDetails;
