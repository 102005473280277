import { call, put, takeLatest } from 'redux-saga/effects';
import { tenderReadRestrictionRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { Dictionary, ShowDeleted } from '@common/types';
import { DictionaryAPI } from '@common/api/common';

function* tenderReadRestrictionWorker() {
  const { request, success, failure, fulfill } = tenderReadRestrictionRoutine;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(DictionaryAPI.read, { dictionary: Dictionary.Restriction, ids: [], showDeleted: ShowDeleted.No });

    yield put(success({ restrictions: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderReadRestrictionWatcher() {
  yield takeLatest(tenderReadRestrictionRoutine.trigger, tenderReadRestrictionWorker);
}
