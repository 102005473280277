import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { RoadGraphEditorStore } from '../types';
import { roadGraphSetSegmentRoutine } from '../actions';
import { PayloadActionError } from '@common/types';

const roadGraphSetSegment = createReducer(initialState, {
  [roadGraphSetSegmentRoutine.REQUEST]: (state: RoadGraphEditorStore) => {
    state.isSaving = 'Сохранение...';
    return state;
  },
  [roadGraphSetSegmentRoutine.SUCCESS]: (state: RoadGraphEditorStore) => {
    state.success = 'Данные сохранены';
    state.isSaving = false;
    return state;
  },
  [roadGraphSetSegmentRoutine.FAILURE]: (state: RoadGraphEditorStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isSaving = false;
    state.error = error;
    return state;
  },
  [roadGraphSetSegmentRoutine.FULFILL]: (state: RoadGraphEditorStore) => {
    state.error = [];
    state.success = false;
    return state;
  }
});

export default roadGraphSetSegment;
