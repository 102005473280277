import {
  ILSButton,
  ILSButtonTooltip,
  ILSCollapse,
  ILSCollapsePanel,
  ILSForm,
  ILSFormItem,
  ILSRadioGroup,
  ILSSelect,
  ILSSpinCenter
} from '@components/index';
import { AccountAvatarComponent } from '../components/form/avatar';
import ILSCopy from '@components/general/copy/Copy';
import { CompanyFormItem, CompanyFormItemLabel, CompanyFormItemMessage } from '@modules/account/constants';
import { Label, Placeholder } from '@common/constants/general';
import { COMPANY_FORM_ITEMS, COMPANY_FORM_PAY_DATA_ITEMS, REQUIRED_COMPANY_FIELDS } from '@modules/account/constants/account';
import { ButtonAction } from '@components/general/buttons';
import { ProfileTab } from '@modules/account/types/tabs';
import CustomScroll from 'react-customscroll';
import { FormItemMessage } from '@common/constants/general/form';
import { useDispatch } from 'react-redux';
import { useAccountForm } from '@modules/account/utils/hooks/use-account-form';
import { VAT_OPTIONS } from '@common/constants';
import { renderFormInput } from '../utils/helpers/render-form-input';
import { AccountAttachmentContainer } from './attachment';
import { AccountAttachmentCategory } from '../types/attachments';

export const AccountContainer = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    roles,
    handleGenerateCode,
    adminAccess,
    onFinish,
    accountCode,
    onAvatarChange,
    initialValues,
    avatar,
    organizationTypes
  } = useAccountForm({ dispatch });

  if (isLoading) {
    return <ILSSpinCenter />;
  }

  return (
    <div className="account-form account">
      <CustomScroll>
        <ILSForm name={ProfileTab.account} layout={'vertical'} onFinish={onFinish} initialValues={initialValues}>
          <AccountAvatarComponent disabled={!adminAccess} avatar={avatar} onAvatarChange={onAvatarChange} />
          {COMPANY_FORM_ITEMS.map((field) => {
            const required = REQUIRED_COMPANY_FIELDS.includes(field);
            const rules = [{ required, message: CompanyFormItemMessage[field] ?? FormItemMessage[field] }];
            return (
              <ILSFormItem className={'account-form-item'} label={CompanyFormItemLabel[field] ?? Label[field]} name={field} rules={rules}>
                {renderFormInput({ field, adminAccess, options: organizationTypes })}
              </ILSFormItem>
            );
          })}
          <ILSFormItem className="account-form-item" label={<h3>Код компании</h3>} name={CompanyFormItem.AccountCode}>
            <ILSButtonTooltip
              className={'code-generate'}
              children={'a-z'}
              onClick={handleGenerateCode}
              title={'Сгенерировать код'}
              disabled={!adminAccess}
            />
            <ILSCopy value={accountCode} handleChange={handleGenerateCode} className="code-copy" disabled={!adminAccess} />
          </ILSFormItem>
          <ILSFormItem className={'account-form-item'} label={Label.AccountRole} name={CompanyFormItem.AccountRole}>
            <ILSSelect mode="multiple" allowClear placeholder={Placeholder.ChooseValue} disabled={!adminAccess} options={roles} />
          </ILSFormItem>
          <ILSCollapse className={'account-form-item'}>
            <ILSCollapsePanel forceRender key={'PayData'} header={<h3>Платежные данные</h3>}>
              {COMPANY_FORM_PAY_DATA_ITEMS.map((field) => {
                const required = REQUIRED_COMPANY_FIELDS.includes(field);
                const rules = [{ required, message: CompanyFormItemMessage[field] ?? FormItemMessage[field] }];
                return (
                  <ILSFormItem
                    className={'account-form-item'}
                    label={CompanyFormItemLabel[field] ?? Label[field]}
                    name={field}
                    rules={rules}
                  >
                    {renderFormInput({ field, adminAccess })}
                  </ILSFormItem>
                );
              })}
            </ILSCollapsePanel>
          </ILSCollapse>
          <ILSFormItem className={'account-form-item'} name={CompanyFormItem.AccountVAT} label={<h3>Условия работы и оплаты</h3>}>
            <ILSRadioGroup optionType="button" buttonStyle="solid" disabled={!adminAccess} options={VAT_OPTIONS} />
          </ILSFormItem>
        </ILSForm>
        {adminAccess && <AccountAttachmentContainer accountAttachmentCategory={AccountAttachmentCategory.Facsimile} />}
      </CustomScroll>
      {adminAccess && <ILSButton action={ButtonAction.Save} form={ProfileTab.account} type="primary" htmlType="submit" />}
    </div>
  );
};

