import { EventDetailsAction } from '../types/api';

// Сообщения нотификаций в зависимости от происходящего процесса
export const defaultStatuses: {
  [key in EventDetailsAction]?: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  GET_EVENT_DETAILS: { loading: 'Загрузка данных...', success: 'Данные загружены' }
};
