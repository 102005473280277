import { ILSButtonTooltip } from '@components/index';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { handleConfirm } from '@modules/planning/children/by-date/helpers/plan/execution';

interface IProps {
  icon: ReactNode;
  buttonTooltip: string;
  confirmText: string;
  onConfirm: () => void;
  disabled?: boolean;
}

export const ILSPlanningResultTableConfirmButton: FC<IProps> = ({ icon, buttonTooltip, confirmText, onConfirm, disabled = false }) => {
  const activePlanID = useSelector(activePlanIDSelector);
  const isPlanChooseCorrect = Boolean(activePlanID);

  const handleClick = () => handleConfirm({ isPlanChooseCorrect, onConfirm, confirmText });

  return <ILSButtonTooltip disabled={disabled} icon={icon} placement="bottom" onClick={handleClick} title={buttonTooltip} />;
};
