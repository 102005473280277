import { FC } from 'react';
import { FormLabels, TimeLineLabels } from '@modules/orders-and-trips/types/form';
import { ActionTypeID, TripAction } from '@modules/orders-and-trips/types/trip';
import { ILSTimeline, ILSTimelineItem } from '@common/components/data-display/timeline';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import moment from 'moment';
import { DateTimeFormat } from '@common/types';
import { DASH } from '@common/constants';

interface IProps {
  tripActions?: TripAction[];
}

export const DetailsRoute: FC<IProps> = ({ tripActions }) => {
  return (
    <div className="details-route">
      <h2>Маршрут</h2>
      <ILSTimeline className="order-action-form-timeline" mode={'left'}>
        {tripActions?.map((action) => {
          const tripDateTime = action.arrivalTimestamp
            ? moment.unix(action.arrivalTimestamp).utcOffset(action.UTCOffset).format(DateTimeFormat.YYYYMMDDDashHHMM)
            : DASH;
          return (
            <ILSTimelineItem
              dot={<ILSSVG config={SVG_CONFIG} icon={action.actionType ? SVG.DOWNLOAD : SVG.UNLOAD} className={'timeline-svg'} />}
              label={TimeLineLabels[ActionTypeID[action.actionType]]}
              key={action.ind}
            >
              {`${FormLabels.Date}: ${tripDateTime}`}
              <br />
              {`${FormLabels.Address}: ${action.address}`}
              <br />
              {`${FormLabels.Contact}: ${action.contactName}`}
              <br />
              {`${FormLabels.Phone}: ${action.contactPhone}`}
            </ILSTimelineItem>
          );
        })}
      </ILSTimeline>
    </div>
  );
};
