import { tableCustom } from '@core/containers/table-custom';
import { VEHICLE_OVERLOAD_TABLE_CONFIG } from '@common/models/vehicle-overload/config/table';
import { vehicleOverload2table } from '@common/models/vehicle-overload/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogVehicleOverload: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return (
      <ILSCatalogTable
        {...props}
        dictionary={Dictionary.VehicleOverload}
        dataDecorator={vehicleOverload2table}
        handleDecorator={table2data}
      />
    );
  },
  VEHICLE_OVERLOAD_TABLE_CONFIG,
  CatalogDictionary.VehicleOverload,
  true
);
