import { useVehicleFilters } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-filters';
import { useVehicleTableFocusRow } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-table-focus-row';
import { useDispatch } from 'react-redux';
import { useVehicleData } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-data';
import { IPlannerPlanningVehicleTableContainerProps } from '@modules/planning/children/by-date/containers/result/tables/vehicle-table';
import { useVehicleButtons } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-buttons';
import { handleUpdateRow } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-update-row';
import { Dictionary } from '@common/types';
import { tableData2Vehicles } from '@common/models/vehicle/decorators';

export const useVehicleTable = ({
  addOrderForVehicle,
  orderSummary
}: Pick<IPlannerPlanningVehicleTableContainerProps, 'orderSummary' | 'addOrderForVehicle'>) => {
  const dispatch = useDispatch();
  const { loading } = useVehicleData();

  const { dataSource, setActiveFiltersData, customClearFilters } = useVehicleFilters();
  const handleSave = handleUpdateRow({
    dispatch,
    dictionary: Dictionary.Vehicle,
    handleDecorator: tableData2Vehicles
  });

  const { actionTableButtons } = useVehicleButtons({ setActiveFiltersData, orderSummary, addOrderForVehicle });
  const { focusRow, onTableRowClick } = useVehicleTableFocusRow({ dataSource });

  return { customClearFilters, focusRow, actionTableButtons, dataSource, loading, handleSave, onTableRowClick };
};
