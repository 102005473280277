import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { getTemplateRoutine, resetCurrentTemplate } from '@modules/documents/children/templates/actions';
import DocumentTemplatesStore from '@modules/documents/children/templates/types';
import { TemplateData } from '@modules/documents/children/templates/types/api';

export const getTemplate = createReducer(initialState, {
  [getTemplateRoutine.TRIGGER]: (state: DocumentTemplatesStore, action: { payload: number }) => {
    state.currentTemplateId = action.payload;
    return state;
  },
  [getTemplateRoutine.REQUEST]: (state: DocumentTemplatesStore) => {
    state.isFetching = true;
    return state;
  },
  [getTemplateRoutine.SUCCESS]: (state: DocumentTemplatesStore, action: { payload: TemplateData }) => {
    state.currentTemplate = action.payload;
    return state;
  },
  [getTemplateRoutine.FULFILL]: (state: DocumentTemplatesStore) => {
    state.isFetching = false;
    return state;
  },
  [resetCurrentTemplate.type]: (state: DocumentTemplatesStore) => {
    state.currentTemplate = undefined;
    return state;
  }
});
