import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { copyTemplateRoutine, getDocumentTemplatesRoutine } from '@modules/documents/children/templates/actions';

function* copyTemplateWorker(action: PayloadAction<number>) {
  try {
    yield call(DocumentTemplatesAPI.copyTemplate, action.payload);
    yield put(getDocumentTemplatesRoutine());
    ILSUserNotify(Notify.Success, `Копия успешно создана`);
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* copyTemplateWatcher() {
  yield takeEvery(copyTemplateRoutine.trigger, copyTemplateWorker);
}
