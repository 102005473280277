import { ILSButton, ILSFormItem, ILSUpload } from '@components/index';
import { CardTab } from '@modules/tender-platform/constants';
import { UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { FC, useEffect, useState } from 'react';
import { getFile } from '@modules/tender-platform/utils/helpers/form/documents/get-file';
import { MAX_UPLOAD_TENDER_FILES, TENDER_UPLOAD_CONFIGURATION } from '@modules/tender-platform/constants/tender-card';

export const TenderPlatformUploadFile: FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    return () => setFileList([]);
  }, []);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  return (
    <ILSFormItem name={CardTab.Documents} getValueFromEvent={getFile}>
      <ILSUpload
        {...TENDER_UPLOAD_CONFIGURATION}
        defaultFileList={[...fileList]}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        className={'documents-upload'}
      >
        {Boolean(fileList.length < MAX_UPLOAD_TENDER_FILES) && (
          <ILSButton icon={'upload-file'} className="documents-upload-button" children={TENDER_UPLOAD_CONFIGURATION.buttonText} />
        )}
      </ILSUpload>
    </ILSFormItem>
  );
};
