import { ActiveRoad, RoadType } from '@modules/road-graph/editor/types';
import { ROAD_TYPES } from '@modules/road-graph/editor/constants';
import { benchmarkTimer } from '@common/utils/general/benchmark';

export const getRoadTypesForZoom = (zoom: number) => {
  let roads: string[] = [];

  Object.keys(ROAD_TYPES).forEach((type) => {
    if (zoom >= ROAD_TYPES[type].min && zoom <= ROAD_TYPES[type].max) {
      roads.push(type);
    }
  });
  return roads.join(',');
};
export const roadData2Track = (roadData: Array<RoadType> | null, editRoad: ActiveRoad | null | undefined) => {
  const timer = benchmarkTimer('roadData2Track', 360);

  if (!roadData?.length) return;

  const tracks = roadData.map((track) => {
    const coords = track.points;
    let color = '#ffd700',
      weight = 2;

    if (editRoad?.edge === track.edge) {
      color = '#D24747';
      weight = 5;
    } else if (track.road && ROAD_TYPES[track.road]) {
      color = ROAD_TYPES[track.road].color;
      weight = ROAD_TYPES[track.road].weight;
    }
    return {
      coords: coords,
      color: color,
      weight: weight,
      clickData: {
        edge: track.edge,
        speed: track.speed,
        road: track.road
      }
    };
  });
  timer.stop();
  return tracks;
};
