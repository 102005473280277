import { flatModules, getModules } from '@core/modules';
import Modules from '@modules/index';
import { IModuleModel } from '@core/types/models';
import { ModuleScope } from './types';

export const getScopeModules = (scope: ModuleScope | ModuleScope[]): IModuleModel[] => {
  if (Array.isArray(scope)) {
    let res: IModuleModel[] = [];

    scope.forEach((s) => {
      if ('string' !== typeof s) return;
      res = res.concat(getScopeModules(s));
    });

    return res;
  }

  if ('string' !== typeof scope) return [];

  const modules = flatModules(getModules(Modules));
  return modules.filter((module) => -1 !== module.inScope!.indexOf(scope));
};
