import { catalogRestoreRoutine, catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { refreshDictionary } from '@modules/catalog/helpers/sagas/refresh-dictionary';
import { CatalogRestorePayload } from '@modules/catalog/types/actions';
import { head } from 'lodash';

function* catalogRestoreWorker(action: CatalogRestorePayload) {
  const { request, success, failure, fulfill } = catalogRestoreRoutine;
  const { dictionary, ids, saveDictionary } = action.payload;

  try {
    yield put(request({ dictionary }));
    yield call(DictionaryAPI.restore, { dictionary, ids });
    yield put(catalogSetFocusRowRoutine({ key: head(ids), dictionary }));

    yield put(success({ dictionary, ids, saveDictionary }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield refreshDictionary(dictionary);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogRestoreWatcher() {
  yield takeLatest(catalogRestoreRoutine.trigger, catalogRestoreWorker);
}
