import { PayloadActionError } from '@common/types';
import { updateMonitoringData } from '@modules/monitoring/utils/update-data';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getSensorEventsRoutine } from '../actions';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';

// Устанавливает данные датчиков
export const monitoringSensorEventsReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [getSensorEventsRoutine.REQUEST]: (state, action: PayloadAction<any>) => {
    state.VehicleSensor.isFetching = true;
    return state;
  },
  [getSensorEventsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ vehicleID: number; updates: boolean; data: any; from: string; till: string }>
  ) => {
    const { data, from, till, vehicleID, updates } = action.payload;
    state.VehicleSensor.isFetching = false;
    if (updates) {
      state.VehicleSensor.data = updateMonitoringData(state.VehicleSensor.data, data);
    } else {
      state.VehicleSensor.data = data;
    }
    state.VehicleSensor.loadedVehicleId = vehicleID;

    state.VehicleSensor.lastUpdate = new Date().getTime();
    state.VehicleSensor.lastUpdateInterval = {
      from,
      till
    };

    return state;
  },
  [getSensorEventsRoutine.FAILURE]: (state, action: PayloadActionError & PayloadAction<any>) => {
    const {} = action.payload;
    state.VehicleSensor.isFetching = false;
    state.VehicleSensor.lastUpdate = undefined;
    state.VehicleSensor.loadedVehicleId = undefined;
    state.VehicleSensor.lastUpdateInterval = undefined;

    return state;
  },
  [getSensorEventsRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    return state;
  }
});

