import { ILSDemandFiltersContainer } from '@modules/demand/containers/filter';
import { GetListRequest, RequestParamsFilter } from '@modules/demand/types/api';
import { HeaderCreateButton } from '@modules/demand/types/button';
import { FC } from 'react';
import { DemandTabType } from '../../types/tabs';
import { ILSDemandButtonsComponent } from './buttons';
import { ILSDemandHeaderTabsComponent } from './tabs';

interface IProps {
  activeKey: DemandTabType;
  onChange: (tab: string) => void;
  hintData: { [K in DemandTabType]?: number };
  hasDesync: boolean;
  onReload: () => void;
  buttonHandler: { [K in HeaderCreateButton]?: () => void };
  filter: GetListRequest['filter'];
  onChangeFilter: (f: Partial<RequestParamsFilter>) => void;
}

// Компонент для отображения хедера модуля
export const ILSDemandHeaderComponent: FC<IProps> = ({
  activeKey,
  onChange,
  hintData,
  hasDesync = false,
  onReload,
  buttonHandler,
  filter,
  onChangeFilter
}) => {
  return (
    <div className="ils-demand-header">
      <div className="ils-demand-header-settings-group">
        <ILSDemandHeaderTabsComponent activeKey={activeKey} hintData={hintData} onChange={onChange} />
        <ILSDemandFiltersContainer filter={filter} onChangeFilter={onChangeFilter} onReload={onReload} hasDesync={hasDesync} />
      </div>
      <div className="ils-demand-header-buttons">
        <ILSDemandButtonsComponent handlers={buttonHandler} />
      </div>
    </div>
  );
};
