import { FC } from 'react';
import { ILSTable } from '@components/index';
import { tableCustom } from '@core/containers/table-custom';
import { forwarderPointStatusesTableConfig } from '@modules/settings/config/mobile-application/forwarder-point-statuses-table-config';
import { Dictionary } from '@common/types/dictionaries/dictionaries';
import { IILSTableProps } from '@common/types';

interface IProps extends IILSTableProps<any> {}

// Кнопка скрытия/отображения столбцов должна быть полностью функциональна (TMS-5745)
export const SettingsForwarderPointStatusesTableComponent: FC<IProps> = tableCustom(
  ({ ...props }) => {
    return <ILSTable {...props} />;
  },
  forwarderPointStatusesTableConfig,
  Dictionary.ForwarderPointStatuses
);
