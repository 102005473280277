import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringUpdateTrackerRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import { UpdateTrackerRequest } from '@common/types/dictionaries/monitoring';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

// Обновление данных трекера
function* monitoringUpdateTrackerWorker(action: PayloadAction<UpdateTrackerRequest>) {
  const { IMEI, values } = action.payload;
  const { request, success, failure, fulfill } = monitoringUpdateTrackerRoutine;
  delete values.key;
  const sanitizedParams = omitBy(values, isNil);
  try {
    yield put(request());

    const { data } = yield call(MonitoringAPI.updateTracker, { IMEI, values: sanitizedParams });

    yield put(success(data.data[IMEI]));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringUpdateTrackerWatcher() {
  yield takeLatest(monitoringUpdateTrackerRoutine.trigger, monitoringUpdateTrackerWorker);
}
