import { FC } from 'react';
import moment, { Moment } from 'moment';
import { DateGeneral } from '@common/types';
import { ILSDatePicker, ILSFormItem, ILSInput, ILSSelect, ILSTextArea } from '@components/index';
import { TENDER_TYPE_OPTIONS, TENDER_VISIBILITY_OPTIONS } from '@modules/tender-platform/constants';
import { checkIsDateDisabled } from '@modules/tender-platform/utils/decorators/disabled-date';
import { TenderInfo } from '@modules/tender-platform/children/tender-search/types';
import { ILSTenderRating } from '@modules/tender-platform/components/tender-card/tender/rating';
import { TENDER_DATE_PICKERS, TENDER_RATING_INPUTS, TenderFormField, TenderFormLabel } from '@modules/tender-platform/constants/content';
import { Field, RuleMessage } from '@common/constants/general/form';
import { RATING } from '@common/constants/custom/rating';
import { Col, Row } from 'antd';
import { Lot3Col } from '@modules/tender-platform/constants/layout';
import { useWatch } from 'antd/lib/form/Form';
import { BASE_FIELD_NAMES } from '@common/constants/options';

interface IProps {
  values?: TenderInfo;
  isTenderEditable?: boolean;
}

export const TenderPlatformTenderFormComponent: FC<IProps> = ({ values, isTenderEditable = false }) => {
  const visibility = useWatch(TenderFormField.Private);
  const ID = useWatch(Field.ID);

  const disabled = !isTenderEditable;

  const handleDisabledDatePatch = (minDate?: DateGeneral, maxDate?: DateGeneral) => (current: Moment) =>
    checkIsDateDisabled(current, minDate, maxDate);

  return (
    <fieldset className={'tender-platform-card-form-fieldset'}>
      <Row>
        <Col {...Lot3Col}>
          <fieldset className={'tender-platform-card-form-fieldset-tender'}>
            {ID && (
              <ILSFormItem label="Номер тендера на платформе" name={Field.ID}>
                <ILSInput disabled />
              </ILSFormItem>
            )}
            <ILSFormItem
              label={TenderFormLabel.Name}
              name={Field.Name}
              rules={[{ required: isTenderEditable, message: RuleMessage.Name, whitespace: true }]}
            >
              <ILSInput disabled={disabled} />
            </ILSFormItem>
            <ILSFormItem
              label={TenderFormLabel.Type}
              name={Field.Type}
              rules={[{ required: isTenderEditable, message: RuleMessage.ChooseValue }]}
            >
              <ILSSelect showArrow={isTenderEditable} options={TENDER_TYPE_OPTIONS} disabled={disabled} />
            </ILSFormItem>
            <ILSFormItem
              // getValueProps={visibility}
              label={TenderFormLabel.Private}
              name={TenderFormField.Private}
              rules={[{ required: isTenderEditable, message: RuleMessage.ChooseValue }]}
            >
              <ILSSelect
                value={visibility}
                showArrow={isTenderEditable}
                fieldNames={BASE_FIELD_NAMES}
                options={TENDER_VISIBILITY_OPTIONS}
                disabled={disabled}
              />
            </ILSFormItem>
            <ILSFormItem label={TenderFormLabel.Description} name={Field.Description}>
              <ILSTextArea className={'description'} rows={4} disabled={disabled} />
            </ILSFormItem>
          </fieldset>
        </Col>
        <Col {...Lot3Col}>
          <fieldset className={'tender-platform-card-form-fieldset-tender'}>
            {TENDER_DATE_PICKERS.map((picker) => {
              return (
                <ILSFormItem
                  key={picker}
                  label={TenderFormLabel[picker]}
                  name={TenderFormField[picker]}
                  rules={[{ required: isTenderEditable, message: RuleMessage.InputValue }]}
                >
                  <ILSDatePicker
                    showTime
                    className="item"
                    showNow={false}
                    disabled={disabled}
                    disabledDate={handleDisabledDatePatch(moment(), values?.[picker])}
                  />
                </ILSFormItem>
              );
            })}
          </fieldset>
        </Col>
        <Col {...Lot3Col}>
          <fieldset className={'tender-platform-card-form-fieldset-tender'}>
            {TENDER_RATING_INPUTS.map((input) => {
              return (
                ((!Boolean(visibility) && input === TenderFormField.MinCarrierRating) || input !== TenderFormField.MinCarrierRating) && (
                  <ILSTenderRating
                    key={input}
                    label={TenderFormLabel[input]}
                    name={TenderFormField[input]}
                    inputValue={values?.[input] ?? RATING.min}
                    disabled={disabled}
                  />
                )
              );
            })}
          </fieldset>
        </Col>
      </Row>
    </fieldset>
  );
};
