import { ILSTableRowActions } from '@components/data-display/table/components/ils-row-action';
import { TABLE_COLUMN_FIXED_POSITION } from '../constants';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { UseTableRowButtons } from '@common/types/components/table/hooks';
import { TableRecord, TableRowButtons } from '@common/types/components/table';

export const useTableRowButtons = <R extends TableRecord>({
  tableColumns,
  handleCheckDelete,
  handleDelete,
  handleRestore,
  handleOpen,
  deleting,
  selectedRowKeys,
  setSelectedRowKeys
}: UseTableRowButtons<R>) => {
  const [disabled, setDisable] = useState(false);
  const handleDisable = (disabled: boolean) => setDisable(disabled);
  //NOTE: операций в строке
  const tableRowButtons: TableRowButtons = {
    details: {
      isUse: Boolean(handleOpen)
    },
    delete: {
      isUse: Boolean(deleting && handleDelete)
    }
  };
  const isUse = Object.values(tableRowButtons).filter(({ isUse }) => isUse);
  const defaultParams = {
    key: 'action',
    fixed: TABLE_COLUMN_FIXED_POSITION.right,
    width: 35 * isUse.length
  };

  !isEmpty(isUse) &&
    tableColumns?.push({
      ...defaultParams,
      onCell: () => {
        return {
          ...defaultParams
        };
      },
      render: (text: string, record: R, rowIndex: number) => (
        <ILSTableRowActions
          selectedRowKeys={selectedRowKeys}
          disabled={disabled}
          handleDisable={handleDisable}
          handleDelete={handleDelete}
          handleCheckDelete={handleCheckDelete}
          handleRestore={handleRestore}
          handleOpen={handleOpen}
          hasDetails={Boolean(handleOpen)}
          hasDelete={Boolean(deleting && handleDelete)}
          record={record}
          rowIndex={rowIndex}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )
    });
};
