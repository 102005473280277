import { changeVehicleRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { NewVehicleRequest, OldVehicleRequest, PlanRequest } from '@modules/planning/types/api';
import { Dictionary } from '@common/types';

function* changeVehicleWorker(action: PayloadAction<OldVehicleRequest & NewVehicleRequest & PlanRequest>) {
  const { request, failure, fulfill, success } = changeVehicleRoutine;
  const { oldVehicleID, newVehicleID, planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.changeVehicle, { oldVehicleID, newVehicleID, planID });
    const {
      data: { data: routeTotal }
    } = yield call(PlanningAPI.getTotal, { planID, vehicleID: newVehicleID, dictionary: Dictionary.Route });
    yield put(success({ message: data, routeTotal, oldVehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* changeVehicleWatcher() {
  yield takeLatest(changeVehicleRoutine.trigger, changeVehicleWorker);
}
