import { createReducer } from '@reduxjs/toolkit';
import {
  resetTableConfig,
  setTableCols,
  setTableColsOrder,
  setTableColWidth,
  setTableConfig,
  setTableDefault,
  setTableGrouping,
  setTablePageSize
} from '../actions/table-custom';
import { ITablesConfigState } from '@core/types/store';
import { ColumnTable, TableConfig } from '@common/types';
import { columnToSaveColumnConfig, configToSaveTableConfig } from '@core/utils/table/table-config-transforms';
import { IColumnStoreConfig, ITableStoreConfig } from '@core/types/table-custom';

const initialState: ITablesConfigState = {};

/**
 Функция возвращает новый конфиг для записи в сторе





 * @param args
 */
const createNewTableConfig = <R>(args: {
  item?: ColumnTable<R>;
  config: TableConfig;
  changeAllTo?: boolean;
  width?: number;
  child?: string;
}): ITableStoreConfig => {
  const { item, config, changeAllTo = undefined, width, child } = args;
  const newConfig = configToSaveTableConfig(config);
  if (!child) {
    // @ts-ignore
    newConfig.columns = config?.columns?.map((column: ColumnTable<R>) => {
      let newCol: IColumnStoreConfig<R> = { key: column.key || column.dataIndex.toString() };
      if (changeAllTo !== undefined && typeof changeAllTo === 'boolean' && !width) {
        if (!column.invisible && !column.dontHide) {
          newCol = columnToSaveColumnConfig({ ...column, active: changeAllTo });
        }
      } else {
        if (item && column.key === item.key) {
          newCol = columnToSaveColumnConfig({ ...column, ...(width ? { width } : { active: !item.active }) });
        } else {
          newCol = columnToSaveColumnConfig({ ...column });
        }
      }
      return newCol;
    });
  } else {
    if (config?.childConfigs?.[child] && newConfig?.childConfigs?.[child]) {
      newConfig.childConfigs[child] = createNewTableConfig({ item, config: config.childConfigs[child], changeAllTo });
    }
  }

  return newConfig;
};

const TablesConfig = createReducer(initialState, (builder) => {
  //установка видимости столбцов таблицы
  builder.addCase(setTableCols, (state, action) => {
    const { tableName, config, col, child, changeAllTo } = action.payload;
    state[tableName] = { ...createNewTableConfig({ item: col, config, child, changeAllTo }) };
  });
  //установка порядка колонок
  builder.addCase(setTableColsOrder, (state, action) => {
    const { tableName, config, cols, child } = action.payload;
    state[tableName] = { ...createNewTableConfig({ config: { ...config, columns: cols }, child }) };
  });

  //сброс настроек таблицы к типовой конфигурации
  builder.addCase(setTableDefault, (state, action) => {
    const { tableName } = action.payload;
    state[tableName] = {} as TableConfig;
  });

  //установка количества записей на странице таблицы
  builder.addCase(setTablePageSize, (state, action) => {
    const { tableName, size } = action.payload;
    state[tableName] = { ...state[tableName], pageSize: size };
  });
  //установка ширины колонки таблицы
  builder.addCase(setTableColWidth, (state, action) => {
    const { tableName, width, dataIndex, config, child } = action.payload;
    const item = config?.columns.find((x) => x.dataIndex === dataIndex);
    const childTable = config?.childConfigs?.[child] && child;
    if (item) {
      state[tableName] = { ...createNewTableConfig({ item, config, child: childTable, width }) };
    }
  });

  //сбрасывает все настройки таблиц
  builder.addCase(resetTableConfig, (state) => {
    state = {};
    return state;
  });

  //устанавливает настройки таблиц пришедшие с бэка
  builder.addCase(setTableConfig, (state, action) => {
    const { config } = action.payload;
    state = { ...config };
    return state;
  });
  // сохранение значений группировки таблицы
  builder.addCase(setTableGrouping, (state, action) => {
    const { tableName, colsForGrouping } = action.payload;
    state[tableName] = { ...state[tableName], grouping: colsForGrouping };
  });
});

export default TablesConfig;
