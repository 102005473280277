import { FC } from 'react';
import {
  Dictionary,
  ReferenceTablesData,
  RoadTrainTableColumn,
  RoadTrainUploadFileType,
  VehicleTableColumn,
  VehicleUploadFileType
} from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { useStatic } from '@common/hooks/use-static';
import { ROAD_TRAIN_TABLE_CONFIG } from '@common/models/roadtrain/config';
import { ILSPlanningDataViewTable } from '@modules/data-validate/containers/table';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { roadTrainData2Table } from '@modules/planning/children/data/utils/decorators';

const ILSDataViewRoadTrain: FC<IDataTableProps> = ({ record, referenceDataSource, dataSource, ...props }) => {
  const roadTrainDataSource = roadTrainData2Table(referenceDataSource, Dictionary.RoadTrain) as RoadTrainUploadFileType[];
  const roadTrainFileDataRef = useStatic(referenceDataSource);

  const onCanEdit = (RoadTrain: RoadTrainUploadFileType, colIndex: string) => {
    const editable = props.config?.columns?.find(({ dataIndex }) => dataIndex === colIndex)?.editable;
    if (colIndex === RoadTrainTableColumn.Trailer2VehicleCode) return !!RoadTrain['Trailer1.VehicleCode'];
    return Boolean(editable);
  };

  const isExistRecord = (VehicleCode: string | number) => {
    const error = roadTrainFileDataRef.current.filter(
      (roadTrain: RoadTrainUploadFileType) => roadTrain['Vehicle.VehicleCode'] === VehicleCode
    ).length;
    if (error) {
      return 'Ошибка добавления! Автопоезд для данного автомобиля уже существует';
    }
    return false;
  };

  const frontTrailer = (dataSource as VehicleUploadFileType[])?.filter((vehicle) => {
    return record!['RearCouplingType.CouplingCode'] === vehicle['FrontCouplingType.CouplingCode'] && Boolean(Number(vehicle.IsTrailer));
  });

  const fetchRearTrailer = () => {
    if (frontTrailer) {
      //находим автопоезд принадлежащий нашему автомобилю (record VehicleCode) в массиве автопоездов
      const roadTrain: RoadTrainUploadFileType[] = roadTrainFileDataRef.current.filter((roadTrain: RoadTrainUploadFileType) => {
        return roadTrain['Vehicle.VehicleCode'] === record!.VehicleCode;
      });

      if (roadTrain.length) {
        //находим frontTrailer принадлежащий нашему автомобилю в массиве ТС по идентификатору автопоезда
        const frontTrailer = (dataSource as VehicleUploadFileType[]).filter(
          (vehicle) => vehicle?.VehicleCode === roadTrain[0]['Trailer1.VehicleCode']
        );

        if (frontTrailer.length) {
          //ищем все прицепы, кроме frontTrailer, передняя сцепка которых соответствует задней сцепке frontTrailer
          return (dataSource as VehicleUploadFileType[]).filter(
            (vehicle) =>
              vehicle['FrontCouplingType.CouplingCode'] === frontTrailer[0]['RearCouplingType.CouplingCode'] &&
              Boolean(Number(vehicle.IsTrailer)) &&
              vehicle?.VehicleCode !== frontTrailer[0].VehicleCode
          );
        }
      }
    }
    return [];
  };
  const trailers = {
    options: frontTrailer,
    dictionary: Dictionary.RoadTrain,
    fieldNames: { value: VehicleTableColumn.VehicleCode, label: VehicleTableColumn.Name }
  };
  const refData: ReferenceTablesData = {
    [RoadTrainTableColumn.Trailer1VehicleCode]: trailers,
    [RoadTrainTableColumn.Trailer2VehicleCode]: {
      options: fetchRearTrailer(),
      dictionary: Dictionary.RoadTrain,
      fieldNames: { value: VehicleTableColumn.VehicleCode, label: VehicleTableColumn.Name }
    }
  };

  return (
    <ILSPlanningDataViewTable
      {...props}
      dataSource={roadTrainDataSource}
      config={ROAD_TRAIN_TABLE_CONFIG}
      refData={refData}
      dictionary={Dictionary.RoadTrain}
      onCanEdit={onCanEdit}
      isExistRecord={isExistRecord}
    />
  );
};

export const ILSPlanningDataRoadTrainTable = tableCustom(ILSDataViewRoadTrain, ROAD_TRAIN_TABLE_CONFIG, Dictionary.RoadTrain);
