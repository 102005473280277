import { List, ListProps } from 'antd';
import React, { FC } from 'react';
import { ItemProps, MetaProps } from './types';

export const ILSList: FC<ListProps<any>> = (props) => {
  return <List {...props} />;
};

export const ILSListItem: FC<ItemProps> = (props) => {
  return <List.Item {...props} />;
};

export const ILSListItemMeta: FC<MetaProps> = (props) => {
  return <List.Item.Meta {...props} />;
};
