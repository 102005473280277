import {
  planningDeleteRoutine,
  planningGetPlanRoutine,
  planningRememberFocusNExpandRowsRoutine
} from '@modules/planning/children/by-date/actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { DictionariesEndpoint, Dictionary, OrderAction, Plan, TableName, Trip, Vehicle, Waypoint } from '@common/types';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { PlanID, PlanIDs, PlanResponse, PlanTotal } from '@common/types/dictionaries/plan';
import { projectPlanIDsSelector } from '@modules/planning/children/by-date/selectors/project';
import { compareAsString } from '@common/utils';
import { RouteTotal } from '@common/types/dictionaries/route';
import { isArray } from 'lodash';
import { currentPlanSelector } from '../selectors/current-plan';
import { FocusNExpandRowsChangingType } from '../types/tables-container';

export type PlanningDeleteTriggerPayload = {
  dictionary: Dictionary;
  planID?: PlanID;
  vehicleID?: Vehicle['ID'];
  tripIDs?: Array<Trip['ID']>;
  waypointIDs?: Array<Waypoint['ID']>;
  orderActionID?: Array<OrderAction['ID']>;
};
export type PlanningDeleteSuccessPayload = {
  currentPlanDeleting?: boolean;
  activePlanID: PlanID;
  planID: PlanID;
  dictionary: Dictionary;
  routeTotal?: RouteTotal;
  planTotal?: PlanTotal;
  message: string;
};

export type PlanningDeleteTriggerActionPayload = PayloadAction<PlanningDeleteTriggerPayload>;
export type PlanningDeleteSuccessActionPayload = PayloadAction<PlanningDeleteSuccessPayload>;

function* planningDeleteWorker(action: PlanningDeleteTriggerActionPayload) {
  const { success, failure, request, fulfill } = planningDeleteRoutine;
  const { tripIDs, dictionary, waypointIDs, vehicleID, orderActionID, planID } = action.payload;
  try {
    yield put(request({ dictionary }));
    const activePlanID: Plan['ID'] = yield select(activePlanIDSelector);

    const {
      data: { data }
    } = yield call(PlanningAPI.delete, {
      tripIDs,
      planID: planID || activePlanID,
      dictionary,
      waypointIDs,
      vehicleID,
      orderActionID
    });

    const successPayload: PlanningDeleteSuccessPayload = {
      currentPlanDeleting: false,
      activePlanID: activePlanID,
      planID: planID || activePlanID,
      dictionary,
      message: data
    };

    if (planID) {
      /** в случае удаления плана нам нужно переключиться на первый план в ставшемся списке */
      const planIDs: PlanIDs = yield select(projectPlanIDsSelector);
      const [firstPlan] = planIDs.slice().filter((id) => !compareAsString(id, activePlanID));

      if (firstPlan) {
        successPayload.activePlanID = firstPlan;
        successPayload.currentPlanDeleting = true;
        yield all([put(planningGetPlanRoutine({ ID: firstPlan })), put(success(successPayload))]);
      }
    } else {
      const {
        data: { data: planTotal }
      } = yield call(PlanningAPI.getTotal, { planID: activePlanID, dictionary: Dictionary.Plan });

      const {
        data: { data: routeTotal }
      } = yield call(PlanningAPI.getTotal, { planID: activePlanID, vehicleID, dictionary: Dictionary.Route });

      successPayload.planTotal = Object.values(planTotal)[0] as PlanTotal;
      successPayload.routeTotal = Object.values(routeTotal)[0] as RouteTotal;
      yield put(success(successPayload));
      /** Меняем активную строку в маршруте */
      if (routeTotal) {
        yield put(
          planningRememberFocusNExpandRowsRoutine({
            lastModifiedData: {
              routeTotal,
              tripIDs,
              dictionary,
              waypointIDs,
              vehicleID,
              orderActionID,
              planID,
              operation: DictionariesEndpoint.Delete
            },
            tableName: TableName.RoutesTable,
            changingType: FocusNExpandRowsChangingType.CheckAndUpdate
          })
        );
      }
    }
  } catch (error) {
    if (isArray(error) && error.includes('Указанный маршрут не найден') && vehicleID) {
      yield put(failure({ error: 'Маршрут был полностью удалён, так как он пуст', vehicleID }));
      const currentPlan: PlanResponse = yield select(currentPlanSelector);

      /** Меняем активный маршрут, если текущий маршрут не найден*/
      yield put(
        planningRememberFocusNExpandRowsRoutine({
          lastModifiedData: {
            routeTotal: null,
            tripIDs,
            dictionary,
            waypointIDs,
            vehicleID,
            orderActionID,
            planID,
            operation: DictionariesEndpoint.Delete,
            currentPlan
          },
          changingType: FocusNExpandRowsChangingType.CheckAndUpdate,
          tableName: TableName.RoutesTable
        })
      );
    } else {
      yield put(failure({ error }));
    }
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* planningDeleteWatcher() {
  yield takeEvery(planningDeleteRoutine.trigger, planningDeleteWorker);
}
