import { TrackDetailsType } from '@common/types/dictionaries/route';
import { PointType, PointTypeApi } from './types';
import { isEmpty } from 'lodash';
import { Provider } from '@common/types';
export const KEY_SEPARATOR = '#';

export const createTrackKeyByPoint = (point: PointType) => {
  const keysValues = [point.from.latitude, point.from.longitude, point.to.latitude, point.to.longitude, point.forbiddenZones];
  extendTrackKeys(keysValues, point);
  return KEY_SEPARATOR + keysValues.join(KEY_SEPARATOR);
};

export const createTrackKeyByApiPoint = (point: PointTypeApi) => {
  const keysValues = [point.From.Latitude, point.From.Longitude, point.To.Latitude, point.To.Longitude, point.ForbiddenZones];
  extendTrackKeys(keysValues, point);
  return KEY_SEPARATOR + keysValues.join(KEY_SEPARATOR);
};

const extendTrackKeys = (
  keysValues: Array<string | number | undefined>,
  point: { Details?: TrackDetailsType; ProviderName?: Provider }
) => {
  if (point.ProviderName) {
    keysValues.push(point.ProviderName);
  }
  if (!isEmpty(point.Details)) {
    keysValues.push(...Object.values(point.Details));
  }
};
