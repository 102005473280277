export enum ProjectOptionStatus {
  ChooseProject,
  CreateNewProject,
  CurrentProject
}

export const PROJECT_BUTTONS = {
  CHOOSE_PROJECT: 'Выбрать проект',
  CREATE_NEW_PROJECT: 'Новый проект',
  CURRENT_PROJECT: 'Текущий проект'
};

export const PROJECT_OPTIONS = [
  { label: PROJECT_BUTTONS.CHOOSE_PROJECT, value: ProjectOptionStatus.ChooseProject },
  { label: PROJECT_BUTTONS.CREATE_NEW_PROJECT, value: ProjectOptionStatus.CreateNewProject }
];

export const PROJECT_OPTIONS_WITH_CURRENT = [
  ...PROJECT_OPTIONS,
  { label: PROJECT_BUTTONS.CURRENT_PROJECT, value: ProjectOptionStatus.CurrentProject }
];

