import { ILSFormItem, ILSRadioGroup, ILSSelect } from '@common/components';
import { LOAD_TYPE_OPTIONS, ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { LOAD_TYPE_RADIO_OPTIONS } from '@modules/orders-and-trips/constants/general';
import { VehicleTypesOptions } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';
import { FormFieldNames, FormLabels, FormPlaceholders, FormSections } from '@modules/orders-and-trips/types/form';
import { FC } from 'react';

interface IProps {
  vehicleTypes: VehicleTypesOptions;
}

export const VehicleOptionsBlock: FC<IProps> = ({ vehicleTypes }) => {
  return (
    <div className="vehicle-options">
      <div className="vehicle-options-body flex-horizontal">
        <ILSFormItem
          name={[FormSections.TransportRequirement, FormFieldNames.VehicleType]}
          label={FormLabels.VehicleType}
          rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.VehicleType]}
        >
          <ILSSelect options={vehicleTypes} placeholder={FormPlaceholders.VehicleType} />
        </ILSFormItem>
        <ILSFormItem
          name={[FormSections.TransportRequirement, FormFieldNames.VehicleLoadType]}
          label={FormLabels.VehicleLoadType}
          rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.VehicleLoadType]}
        >
          <ILSSelect options={LOAD_TYPE_OPTIONS} placeholder={FormPlaceholders.VehicleLoadType} />
        </ILSFormItem>
        <ILSFormItem
          name={[FormSections.TransportRequirement, FormFieldNames.VehicleUnloadType]}
          label={FormLabels.VehicleUnloadType}
          rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.VehicleUnloadType]}
        >
          <ILSSelect options={LOAD_TYPE_OPTIONS} placeholder={FormPlaceholders.VehicleUnloadType} />
        </ILSFormItem>
      </div>
      <ILSFormItem label={FormLabels.Loading} name={[FormSections.TransportRequirement, FormFieldNames.Loading]}>
        <ILSRadioGroup options={LOAD_TYPE_RADIO_OPTIONS} />
      </ILSFormItem>
    </div>
  );
};
