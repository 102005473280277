import { EMPTY_CELL } from '@common/constants/general';
import { TenderDocuments } from '@modules/tender-platform/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { IndexedArray } from '@common/types';

export const document2Table = (documents?: IndexedArray<TenderDocuments>) => {
  return createArrayFromIndexedArray(documents).map((document) => {
    const { Size } = document;
    return {
      ...document,
      key: document.ID,
      Name: `${document.Subject}_${document.ID}`,
      Size: Size ? `${Size} МБ` : EMPTY_CELL
    };
  });
};
