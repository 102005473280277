import { tableCustom } from '@core/containers/table-custom';
import { VEHICLE_PROFILE_TABLE_CONFIG } from '@common/models/vehicle-profile/config/table';
import { vehicleProfile2table } from '@common/models/vehicle-profile/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@common/components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogVehicleProfile: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return (
      <ILSCatalogTable
        {...props}
        dictionary={Dictionary.VehicleProfile}
        handleDecorator={table2data}
        dataDecorator={vehicleProfile2table}
      />
    );
  },
  VEHICLE_PROFILE_TABLE_CONFIG,
  CatalogDictionary.VehicleProfile,
  true
);
