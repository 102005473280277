import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { clearStateRoutine } from '@modules/orders-and-trips/actions';

export const useFakePreCostTabs = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<NewOrderFormTab>(NewOrderFormTab.Documents);

  const handleActiveTab = (key: string) => {
    const payload: { key: keyof InitialState } = { key: 'availableIntegrations' };
    dispatch(clearStateRoutine(payload));
    setActiveTab(key as NewOrderFormTab);
  };

  return { activeTab, handleActiveTab };
};
