import { ModulePath } from '@common/types';
import { useLocation } from 'react-router';
import { DocumentState } from '@modules/documents/types/document-state';

export const useGetDocumentState = (): DocumentState => {
  const { pathname } = useLocation();

  switch (pathname) {
    case ModulePath.Documents + ModulePath.DocumentsInbox:
      return DocumentState.Inbox;
    case ModulePath.Documents + ModulePath.DocumentsSent:
      return DocumentState.Sent;
    case ModulePath.Documents + ModulePath.DocumentsTemplates:
      return DocumentState.Templates;
    case ModulePath.Documents + ModulePath.DocumentsArchive:
      return DocumentState.Archived;
    case ModulePath.Documents + ModulePath.DocumentsTrash:
      return DocumentState.Deleted;
    default:
      return DocumentState.Active;
  }
};
