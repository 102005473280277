import { generateOptionsSchemeTypeFromAvailableOptions } from '@modules/planning/children/scheme-editor/utils/helpers/generate-options-scheme-type-from-available-options';
import { SchemeColumn, SchemeInfo, TypeContainer } from '@modules/planning/children/scheme-editor/types';

export const createDataForScheme = (
  schemeColumn: SchemeColumn,
  keys: Array<string>,
  typeContainer: TypeContainer,
  availableTypes: SchemeInfo['availableTypes']
) => {
  return keys.map((field) => {
    const { type } = schemeColumn[field];

    return {
      ...schemeColumn[field],
      typeContainer,
      key: field,
      options: generateOptionsSchemeTypeFromAvailableOptions({ availableTypes: availableTypes[field], type })
    };
  });
};
