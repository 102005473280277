import { createSelector } from 'reselect';
import {
  addedParticipantsSelector,
  carriersFetchingSelector,
  carriersListArraySelector
} from '@modules/tender-platform/children/tender-own/selectors/carriers';
import { getCarriersOptions } from '@modules/tender-platform/children/tender-own/utils/helprers';

export const tenderPlatformCarriersFormSelector = createSelector(
  carriersListArraySelector,
  carriersFetchingSelector,
  addedParticipantsSelector,
  (carriers, isLoading, ids) => {
    const options = getCarriersOptions({ carriers, ids });
    return { isLoading, options };
  }
);
