/** Тип данных для записи в таблице автомобилей */
export type VehicleTableRecord = {
  /**  Ключ */
  key: string;
  /**  Ключ строки */
  rowKey: string;
  /**  ID автомобиля */
  ID: number;
  /**  Название автомобиля */
  Name: string;
  /**  Разница во времени со стартовой точкой */
  StartPointDelta?: string;
  /**  Разница во времени с прибытием на следующую точку */
  NextPointsArrivalDelta?: string;
  /**  Разница во времени с отправлением со следующей точки */
  NextPointsDepartureDelta?: string;
  /**  Разница в скорости */
  SpeedDelta?: number | string;
  /**  Есть ли нарушение последовательности и его номер */
  Desequence?: number;
  /**  Есть ли нарушение в безопасности дорожного движения */
  BDD?: string;
};

/**  Названия словаря эмулятора */
export enum EmulatorDictionary {
  /**  Свойства автомобиля */
  VehicleProps = 'EmulatorVehicle'
}

export type PointService = Record<string, string>;

// TODO Описать позже тип для БДД
export type EmulatorBDD = any;

/**  Свойства эмулируемого автомобиля */
export type VehicleEmulatorProps = {
  /**  Стартовая точка */
  startPoint: PointService;
  /**  Конечная точка */
  endPoint: PointService;
  /**  Скорость */
  speed: number;
  /**  Отклонения от планового трека */
  planTrackDeviations: number;
  /**  Нарушения безопасности дорожного движения */
  bdd: Array<EmulatorBDD>;
};

