import { useEffect } from 'react';

export const useStimulSoft = () => {
  useEffect(() => {
    const stimulsoftReportsScript = document.createElement('script');
    const stimulsoftViewerScript = document.createElement('script');
    const stimulsoftDesignersScript = document.createElement('script');

    stimulsoftReportsScript.src = '/assets/scripts/stimulsoft/stimulsoft.reports.js';
    stimulsoftViewerScript.src = '/assets/scripts/stimulsoft/stimulsoft.viewer.js';
    stimulsoftDesignersScript.src = '/assets/scripts/stimulsoft/stimulsoft.designer.js';

    [stimulsoftReportsScript, stimulsoftViewerScript, stimulsoftDesignersScript].forEach((script) => {
      script.async = false;
    });

    const stimulsoftDesignerCss = document.createElement('link');
    const stimulsoftViewerCss = document.createElement('link');

    [stimulsoftDesignerCss, stimulsoftViewerCss].forEach((linkCss) => {
      linkCss.rel = 'stylesheet';
    });

    stimulsoftViewerCss.href = '/assets/scripts/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css';
    stimulsoftDesignerCss.href = '/assets/scripts/stimulsoft/stimulsoft.designer.office2013.whiteblue.css';

    document.body.appendChild(stimulsoftDesignerCss);
    document.body.appendChild(stimulsoftViewerCss);
    document.body.appendChild(stimulsoftReportsScript);
    document.body.appendChild(stimulsoftViewerScript);
    document.body.appendChild(stimulsoftDesignersScript);

    return () => {
      document.body.removeChild(stimulsoftDesignerCss);
      document.body.removeChild(stimulsoftViewerCss);
      document.body.removeChild(stimulsoftReportsScript);
      document.body.removeChild(stimulsoftViewerScript);
      document.body.removeChild(stimulsoftDesignersScript);
    };
  }, []);
};
