import { getSRCIconSVG } from '@common/decorators/path';
import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/modules';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { documentsRootReducer } from '@modules/documents/reducers';
import DocumentsAll from './children/all';
import DocumentsArchive from './children/archive';
import DocumentsInbox from './children/inbox';
import DocumentsSent from './children/sent';
import DocumentsTemplates from './children/templates';
import DocumentsTrash from './children/trash';
import DocumentsUpload from './children/upload';
import Documents from './containers';
import * as documentIndexRootSagas from './sagas';

export default defineModule({
  title: ModuleTitle.Documents,
  path: ModulePath.Documents,
  component: Documents,
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG(ModulePath.Documents, { iconModule: '/sidebar' }),
  children: {
    DocumentsAll,
    DocumentsInbox,
    DocumentsSent,
    DocumentsTemplates,
    DocumentsArchive,
    DocumentsTrash,
    DocumentsUpload
  } as any,
  reducer: documentsRootReducer,
  sagas: getAllModuleSagas(documentIndexRootSagas)
});
