import { AnalyticsWidget, AnalyticsWidgetCatalogModel, WidgetType } from '@common/types/dictionaries/analytics';
import React, { DragEvent, RefObject, useContext, useState } from 'react';
import { AnalyticsDraggableContext } from '@modules/analytics/common/context/draggable-context';

const WIDGET_INITIAL_WIDTH = 20;
const WIDGET_INITIAL_HEIGHT = 20;

type Props = {
  boardRef: RefObject<HTMLDivElement> | undefined;
  dropWidgetOnBoard?: (widget: AnalyticsWidget) => void;
  checkWidgets?: (widget: AnalyticsWidget) => boolean;
};

const useDragWidget = (props: Props) => {
  const { boardRef, dropWidgetOnBoard, checkWidgets = () => true } = props;

  const context = useContext(AnalyticsDraggableContext);

  const [boardDroppable, setBoardDroppable] = useState(false);
  const [newWidget, setNewWidget] = useState<AnalyticsWidget | null>(null);
  const [dropWidgetPosition, setDropWidgetPosition] = useState<{ Left: number; Top: number }>({
    Left: 0,
    Top: 0
  });

  const getData = (e: React.DragEvent) => {
    const data: AnalyticsWidgetCatalogModel & { ID: string; BoardID: number; isNew: boolean } = JSON.parse(
      e.dataTransfer.getData('text/plain')
    );
    return data;
  };

  const dragOver = (e: DragEvent) => {
    e.preventDefault();
    setBoardDroppable(true);

    const x = e.clientX;
    const y = e.clientY;

    const clientRect = boardRef?.current?.getBoundingClientRect();

    const clientX = clientRect?.left || 0;
    const clientY = clientRect?.top || 0;

    const positionX = x - clientX;
    const positionY = y - clientY;

    const widgetPosition = {
      Left: (positionX / (clientRect?.width || 1)) * 100 - WIDGET_INITIAL_WIDTH / 2,
      Top: (positionY / (clientRect?.height || 1)) * 100 - WIDGET_INITIAL_HEIGHT / 2
    };
    setDropWidgetPosition(widgetPosition);

    //проверка на пересечение с другими виджетами
    const checkResult = checkWidgets({
      ID: context.draggableWidget?.ID || '',
      Type: context.draggableWidget?.Type || WidgetType.info,
      Position: widgetPosition,
      Size: { Width: WIDGET_INITIAL_WIDTH, Height: WIDGET_INITIAL_HEIGHT }
    });

    //проверка на пересечение границ доски
    const isOverBoard =
      widgetPosition.Left < 0 ||
      widgetPosition.Top < 0 ||
      widgetPosition.Left + WIDGET_INITIAL_WIDTH > 100 ||
      widgetPosition.Top + WIDGET_INITIAL_HEIGHT > 100;

    setBoardDroppable(checkResult && !isOverBoard);
  };

  const dragLeave = (e: DragEvent) => {
    e.preventDefault();
    setBoardDroppable(false);
  };

  const getWidget = (data: AnalyticsWidgetCatalogModel & { ID: string; BoardID: number; isNew: boolean }): AnalyticsWidget => {
    return {
      ID: data.ID,
      BoardID: data.BoardID,
      Position: dropWidgetPosition,
      Size: { Width: 20, Height: 20 },
      Type: data.Type[0] as WidgetType,
      Name: data.Name,
      ClassName: data.ClassName,
      Description: data.Description,
      Params: data.Params,
      isNew: data.isNew
    };
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();

    if (boardDroppable) {
      const data = getData(e);
      const newWidget = getWidget(data);
      if (boardDroppable) dropWidgetOnBoard?.(newWidget as AnalyticsWidget);
      setNewWidget(newWidget as AnalyticsWidget);
    }

    setBoardDroppable(false);
  };

  const onDragEnd = (e: DragEvent) => {
    setBoardDroppable(false);
  };

  return {
    boardDroppable,
    dropWidgetPosition,
    dragOver,
    dragLeave,
    onDrop,
    newWidget,
    onDragEnd
  };
};

export default useDragWidget;
