import { ILSTabPane, ILSTabs } from '@common/components';
import { FC } from 'react';
import { DetailsSubTableContainer } from '../../containers/tables/details-sub-table';
import { EventDetailsTab } from '../../types';
import { TabType } from '../../types/event-details-tabs';
import { MonitoringEventDetailsStore } from '../../types/store';

interface DetailsTabProps {
  tabs: Array<EventDetailsTab>;
  activeTab?: TabType;
  waypointId?: number;
  handleChange?: (activeKey: string | number) => void;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
  selectedVehicleID: number | undefined;
}

/**
 * Компонент для отрисовки табов
 */
export const DetailsTabComponent: FC<DetailsTabProps> = ({ tabs, activeTab, handleChange, selectedVehicleID, ...props }) => {
  return (
    <ILSTabs type="line" activeKey={activeTab} onChange={handleChange} className="ils-monitoring-details-tab bg-white">
      {tabs.map((tab) => {
        const TabComponent = tab.component;
        return (
          <ILSTabPane key={tab.key} tab={tab.label}>
            {TabComponent ? (
              <TabComponent tab={tab} {...props} />
            ) : (
              <DetailsSubTableContainer title={tab.label} vehicleSensor={props.vehicleSensor} selectedVehicleID={selectedVehicleID} />
            )}
          </ILSTabPane>
        );
      })}
    </ILSTabs>
  );
};
