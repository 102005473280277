import { useEffect, useState } from 'react';
import { SortDirection } from '@common/types';
import {
  FakePreCostProposals,
  FakePreCostProposalSortOption,
  FakePreCostResponse,
  FakePreCostSortUnit
} from '@modules/orders-and-trips/types/fake-pre-cost';
import { orderBy } from 'lodash';
import { compareAsString } from '@common/utils';
import { getProposalList } from '@modules/orders-and-trips/helpers/fake-pre-cost/get-proposal-list';
import { proposalInitialSortOption } from '@modules/orders-and-trips/constants/fake-pre-cost';
import { getSortDirection } from '@common/utils/helpers/sort/get-sort-direction';
import { getSorterForProposalsByPrice } from '@modules/orders-and-trips/helpers/fake-pre-cost/proposals/get-sorter-for-proposals-by-price';

export const useProposalListSort = ({ fakePreCost }: { fakePreCost: FakePreCostResponse }) => {
  const proposalList = getProposalList(fakePreCost);
  const [activeSortOption, setActiveSortOption] = useState<FakePreCostProposalSortOption>(proposalInitialSortOption);
  const [sortProposalList, setSortProposalList] = useState<FakePreCostProposals>(proposalList);

  const sortProposalsByPrice = getSorterForProposalsByPrice({ setSortProposalList, proposalList });

  const sortProposals = (sortUnit: FakePreCostSortUnit) => () => {
    const isSameUnit = compareAsString(sortUnit, activeSortOption.unit);
    const direction = isSameUnit ? getSortDirection(activeSortOption.direction) : SortDirection.Asc;
    const isDesc = compareAsString(direction, SortDirection.Desc);

    switch (sortUnit) {
      case FakePreCostSortUnit.Term:
        setSortProposalList(() => {
          const byTerm = orderBy(proposalList, ['minDelivery']);
          return isDesc ? byTerm.reverse() : byTerm;
        });
        break;
      case FakePreCostSortUnit.Price:
      default:
        sortProposalsByPrice(isDesc);
        break;
    }
    setActiveSortOption({
      unit: sortUnit,
      direction
    });
  };

  useEffect(() => {
    sortProposalsByPrice(false);
  }, [fakePreCost]);

  return { sortProposalList, activeSortOption, sortProposals };
};
