import classNames from 'classnames';
import { FC, forwardRef } from 'react';
import './styles.less';

interface IProps {
  className?: string;
  width?: number;
  scrollX?: boolean;
  ref?: React.Ref<HTMLDivElement | null>;
  style?: Record<string, string>;
}

export const ILSCustomScrollCss: FC<IProps> = forwardRef(({ children, style, className, width, scrollX }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(className, 'custom-scroll-css', scrollX ? 'custom-scroll-css-horizontal' : 'custom-scroll-css-vertical')}
      style={{ width: `${width}px`, ...style }}
    >
      {children}
    </div>
  );
});

