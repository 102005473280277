import { BgColorsOutlined, EditOutlined } from '@ant-design/icons';
import { IILSCellColorStaticProps } from '@common/types/components/table/cell';
import cn from 'classnames';
import { TableRecord } from '@common/types';
import { COLOR_BLACK } from '@common/constants/colors';
import { isNil, isString } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellColorStatic = <R extends TableRecord>({
  children,
  record,
  dataIndex,
  toggleEdit
}: IILSCellColorStaticProps<R>): JSX.Element => {
  const [node, color] = children;

  if (!(isString(color) || isNil(color))) {
    showUserAndDevError({ error: 'Цвет должен быть строкой HEX/rgb()/string' });
    return (
      <div onClick={toggleEdit} className={cn('cell-wrapper-editable color-cell', { view: !toggleEdit })}>
        {node}
      </div>
    );
  }

  return (
    <div
      onClick={toggleEdit}
      className={cn('cell-wrapper-editable color-cell', { view: !toggleEdit })}
      style={{ backgroundColor: color ?? COLOR_BLACK }}
    >
      {node}
      {toggleEdit && <BgColorsOutlined className={'ils-svg white'} />}
      {record?.[dataIndex + 'Changed'] && <EditOutlined className="color-cell-changed" />}
    </div>
  );
};
