import { ILSButton, ILSPopover } from '@components/index';
import { Dictionary, ITablePropsFunction, ReferenceTables, ReferenceTablesData, TableConfig, TableRecord } from '@common/types';
import { useModal } from '@common/hooks/components/use-modal';
import { ILSTableAddingForm } from '@components/data-display/table/components/action-buttons/adding/form';
import { ILSTableButtonCreate } from '@components/data-display/table/components/action-buttons/adding/button-create';
import { SetShowReference } from '@common/types/components/table/common';
import { ButtonAction } from '@components/general/buttons';

interface IProps<R extends TableRecord> {
  columns: TableConfig['columns'];
  adding: TableConfig['adding'];
  headerButtonsParams: TableConfig['headerButtonsParams'];
  handleCreate: ITablePropsFunction<R>['handleCreate'];
  dictionaryLoad: ITablePropsFunction<R>['dictionaryLoad'];
  handleSave?: ITablePropsFunction<R>['handleSave'];
  setShowReference: SetShowReference<R>;
  isFetching?: boolean;
  refData?: ReferenceTablesData;
  refTable?: ReferenceTables;
  dictionary?: Dictionary;
}

export const TableAddingButton = <R extends TableRecord>({
  refData,
  columns,
  adding,
  headerButtonsParams,
  handleCreate,
  dictionaryLoad,
  isFetching = false,
  dictionary,
  handleSave,
  refTable,
  setShowReference
}: IProps<R>): JSX.Element => {
  const { visible, handleClose, handleOpen } = useModal();

  const isButtonFormWithRequiredColumnFields = columns.some(({ required }) => required);
  const setShowReferenceCloseModal: SetShowReference<R> = (...args) => {
    handleClose();
    setShowReference(...args);
  };

  if (isButtonFormWithRequiredColumnFields) {
    return (
      <ILSPopover
        visible={visible}
        arrowContent={false}
        placement="right"
        content={
          <ILSTableAddingForm
            handleCreate={handleCreate}
            columns={columns}
            handleClose={handleClose}
            dictionaryLoad={dictionaryLoad}
            refData={refData}
            handleSave={handleSave}
            refTable={refTable}
            dictionary={dictionary}
            setShowReference={setShowReferenceCloseModal}
          />
        }
        trigger="click"
      >
        <ILSButton
          action={ButtonAction.Add}
          loading={isFetching}
          disabled={isFetching}
          type="primary"
          className="add"
          onClick={handleOpen}
        />
      </ILSPopover>
    );
  }

  return (
    <ILSTableButtonCreate handleCreate={handleCreate} isFetching={isFetching} adding={adding} headerButtonsParams={headerButtonsParams} />
  );
};
