import { MarkerType } from '@common/types';

export const PAIR_PARAMS = {
  weight: 2,
  color: '#4169E1'
};
export const MARKER_ORDER_COLOR: string = '#D24747';
export const DRAW_PAIR_ALWAYS = 2;
export const DRAW_PAIR_FOCUS = 1;
export const DRAW_PAIR_FORBIDDEN = 0;

export const ORDERS_TYPES_FOR_COUNT_ON_MAP = [MarkerType.Order];
export const POPUP_MAX_WIDTH = {
  point: 400,
  order: 600,
  default: 300
};
