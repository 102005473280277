export const DEFAULT_SUGGESTION_AUTOCOMPLETE_OPTIONS = [
  { value: 'г. Москва', label: 'Москва' },
  { value: 'г. Санкт-Петербург', label: 'Санкт-Петербург' },
  { value: 'г. Краснодар', label: 'Краснодар' },
  { value: 'г. Новосибирск', label: 'Новосибирск' },
  { value: 'г. Екатеринбург', label: 'Екатеринбург' },
  { value: 'г. Казань', label: 'Казань' },
  { value: 'г. Нижний Новгород', label: 'Нижний Новгород' },
  { value: 'г. Красноярск', label: 'Красноярск' },
  { value: 'г. Челябинск', label: 'Челябинск' },
  { value: 'г. Самара', label: 'Самара' },
  { value: 'г. Уфа', label: 'Уфа' },
  { value: 'г. Ростов-на-Дону', label: 'Ростов-на-Дону' },
  { value: 'г. Омск', label: 'Омск' },
  { value: 'г. Воронеж', label: 'Воронеж' },
  { value: 'г. Пермь', label: 'Пермь' },
  { value: 'г. Волгоград', label: 'Волгоград' }
];
