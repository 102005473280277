import { PlanID } from '@common/types';

export const handlePlanConfirmForExecution =
  ({
    planID,
    unsetPlanID,
    checkDriversBeforeHandlePlanOnExecution
  }: {
    planID: PlanID;
    unsetPlanID: PlanID;
    checkDriversBeforeHandlePlanOnExecution({ planID, unsetPlanID }: { planID: PlanID; unsetPlanID?: PlanID }): void;
  }) =>
  () =>
    checkDriversBeforeHandlePlanOnExecution({ planID, unsetPlanID });
