import { tariffFunctions, tariffMathFunction, tariffOperation } from '@modules/catalog/types/tariff';
import { all, create } from 'mathjs';

export const tariffListKeys = [
  'MaxMass',
  'FuelConsumption',
  'StartDate',
  'EndDate',
  'StartZone',
  'EndZone',
  'Priority',
  'Distance',
  'Duration',
  'Clients',
  'ClientsZone',
  'OrdersZone',
  'AllCost',
  'AllLoadDuration',
  'AllUnloadDuration',
  'VisitsCount',
  'ReturnOrders',
  'OrdersSelectedZone',
  'FarthestZone',
  'StartWeekDay',
  'Loader',
  'Expeditor'
] as const;

export const tariffFunctionsList: tariffFunctions = {
  Сумма: {
    name: 'Сумма',
    desc: 'Сумма (значение1;значение2:значение4) – возвращает сумму своих аргументов, где «;» - и, «:» - с-до',
    example: ' , '
  },
  Срзнач: {
    name: 'Срзнач',
    desc: 'Срзнач (значение1;значение2:значение4) – возвращает среднее арифметическое своих аргументов',
    example: ' , , '
  },
  Если: {
    name: 'Если',
    desc: 'Если (логическое выражение;значение если истина;значение если ложь) – проверяется условие, если выполняется возвращает одно значение, если нет то другое',
    example: ' , , '
  },
  Макс: {
    name: 'Макс',
    desc: 'Макс (значение1;значение2:значение4) – Возвращает наибольшее значение из списка аргументов',
    example: ' , '
  },
  Мин: {
    name: 'Мин',
    desc: 'Мин (значение1;значение2:значение4) – Возвращает наименьшее значение из списка аргументов',
    example: ' , '
  },
  Мод: { name: 'Мод', desc: 'Мод (число) – возвращает модуль числа', priority: 0, example: ' ' },
  Округл: {
    name: 'Округл',
    desc: 'Округл (число;число разрядов) – Округляется число до указанного числа десятичных разрядов',
    example: ' , 1'
  },
  ОкруглВверх: {
    name: 'ОкруглВверх',
    desc: 'ОкруглВверх (число;число разрядов) – Округляется число до ближайшего большего по модулю до указанного числа десятичных разрядов',
    example: ' , 1'
  },
  ОкруглВниз: {
    name: 'ОкруглВниз',
    desc: 'ОкруглВниз (число;число разрядов) – Округляется число до ближайшего меньшего по модулю до указанного числа десятичных разрядов',
    example: ' , 1'
  },
  Сегодня: { name: 'Сегодня', desc: 'Сегодня – возвращает текущую дату', example: '' },
  Дата: {
    name: 'Дата',
    desc: 'Дата (формат даты) – возвращает дату в формате гггг-мм-дд, в скобках задается формат (дд-день, мм-месяц, гггг – год, гггг-мм-дд – год-месяц-день)',
    example: '"гггг-мм-дд"'
  }
};

export const tariffMathFunctionsList: Array<tariffMathFunction> = [
  { key: 'Сумма(', func: 'math.sum(' },
  { key: 'Срзнач(', func: 'math.mean(' },
  { key: 'Если(', func: 'math.ifelse(' },
  { key: 'Макс(', func: 'math.max(' },
  { key: 'Мин(', func: 'math.min(' },
  { key: 'Мод(', func: 'math.abs(' },
  { key: 'Округл(', func: 'math.round(' },
  { key: 'ОкруглВверх(', func: 'math.ceil(' },
  { key: 'ОкруглВниз(', func: 'math.floor(' },
  { key: 'Сегодня(', func: 'math.today(' },
  { key: 'Дата(', func: 'math.date(' }
];

export const tariffOperations: Array<tariffOperation> = [
  { name: 'addition', sign: '+' },
  { name: 'deduction', sign: '-' },
  { name: 'multiplication', sign: '*' },
  { name: 'division', sign: '/' },
  { name: 'openParenthesis', sign: '(' },
  { name: 'closeParenthesis', sign: ')' },
  { name: 'more', sign: '>' },
  { name: 'less', sign: '<' },
  { name: 'equal', sign: '=' },
  { name: 'conjunction', sign: '^' },
  { name: 'and', sign: 'и' },
  { name: 'or', sign: 'или' },
  { name: 'semicolon', sign: ';' }
];

export const tariffNumbers: Array<tariffOperation> = [
  { name: '0', sign: '0' },
  { name: '1', sign: '1' },
  { name: '2', sign: '2' },
  { name: '3', sign: '3' },
  { name: '4', sign: '4' },
  { name: '5', sign: '5' },
  { name: '6', sign: '6' },
  { name: '7', sign: '7' },
  { name: '8', sign: '8' },
  { name: '9', sign: '9' }
];

export const tariffs = [
  {
    id: 1,
    name: 'Доставка по Москве',
    formula: 'Distance * 200 + Duration * 100'
  },
  {
    id: 2,
    name: 'Московская область',
    formula: 'Сумма(Distance * 100, Duration * 50)'
  },
  {
    id: 3,
    name: 'Стандартный',
    formula: 'UserParam_1 + UserParam_2'
  }
];

export const params = [
  {
    id: 1,
    name: 'Стоимость за км',
    formula: 'Distance * 10'
  },
  {
    id: 2,
    name: 'Стоимость за час',
    formula: 'Duration * 50'
  }
];

//TODO global scope
export const MATH = create(all);

export const REGEXP_FOR_ZONES = new RegExp(/[а-яёa-z]/, 'giu');
export const REGEXP_FOR_SPLITTER = new RegExp(/[\*\+\-\/\(\)]/, 'giu');
export const ALLOWED_KEYS_FOR_EDITOR = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace', '.', ';', ':'];

export const SPECIFIC_KEYS_MUST_BE_FIRST_IN_PARSER = ['AllUnloadDuration', 'AllLoadDuration'];

export const STYLES_FOR_EDITOR =
  'body { font-family:Helvetica,Arial,sans-serif; font-size:16px; line-height: 35px}' +
  'input[type="number"]{width: 40px;} ' +
  'body#tinymce p *{margin: 0 2px; white-space:nowrap}' +
  '.formulaBtn{background: #fff;padding: 3px 3px; line-height: 20px; border: 1px solid #ccc; transition: all .1s; cursor: move !important;}' +
  '.formulaBtn > .delete-link{transition: all .1s;}' +
  '.formulaBtn:hover{background: #4C91FF; color: white;}' +
  '.formulaBtn:hover > .delete-link{color: #FF2929;}' +
  '.formulaBtn--func{background: #f0f8ff; padding: 5px;}' +
  '.formulaBtn--func:hover > span > a{background: #4C91FF; color: white;}' +
  '.formulaBtn--param{background: #F8F9FA; padding: 3px;}';
