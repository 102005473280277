import { ILSTypographyText } from '@components/index';
import { dataToCellTypeStringWithUtcHint } from '@components/data-display/table/utils/decorators/cell-decorators/cell-type-string-with-utc-hint';
import { IILSCellTimeStringWithUTCProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellTimeStringWithUTC = <R extends TableRecord>({
  children,
  ellipsis,
  record,
  style
}: IILSCellTimeStringWithUTCProps<R>): JSX.Element => {
  const [, time] = children ?? [];
  const { hint, content } = dataToCellTypeStringWithUtcHint(time, record);
  return <ILSTypographyText ellipsis={ellipsis} title={hint} style={style} children={content} />;
};
