import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

export const checkImgResolution = (image: File) => {
  let fr = new FileReader();
  fr.onload = function () {
    var img = new Image();
    img.onload = function () {
      if (Number(img.width) >= 2000 || Number(img.height) >= 2000) {
        ILSUserNotify(Notify.Error, 'Максимальное разрешение изображения 2000х2000 пикселей, выберите другое изображение', 3);
      }
    };
    //@ts-ignore
    img.src = fr.result;
  };

  fr.readAsDataURL(image);
};
