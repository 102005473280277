import { ILSButton, ILSTooltip } from '@common/components';
import { ReactSVG } from 'react-svg';
import React, { FC } from 'react';

interface Props {
  openMenu: () => void;
}

const ILSRoadGraphTestPermitZonesButton: FC<Props> = (props) => {
  const { openMenu } = props;

  return (
    <ILSTooltip placement="right" title={'Зоны ограничения'}>
      <ILSButton
        className={'map-control-btn map-zones-btn'}
        onClick={openMenu}
        icon={<ReactSVG src={'/assets/icons/road-graph/permit-zones.svg'} />}
      />
    </ILSTooltip>
  );
};
export default ILSRoadGraphTestPermitZonesButton;
