import { Modal, notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import { ReactNode, useEffect, useMemo } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { getSRCIconSVG } from '@common/decorators/path';
import { EROROR_MESSAGE_DURATION } from '@common/constants';
import { getOutputNotify } from './outer-notify';
import { NotifyContent } from '@core/types/actions';
import { PlacementType } from '@common/types/components';

const { confirm } = Modal;

//todo сделать объект вместо множества аргументов
export const ILSUserNotify = (
  type: Notify,
  content: NotifyContent,
  duration: NotifyDurationInSecond = NotifyDurationInSecond.Ten,
  key: string | undefined = undefined,
  icon?: ReactNode,
  onClick?: () => void, //Клик по самому уведомлению
  onClose?: () => void, //Клик по кнопке закрыть, либо по кнопке отмены, если есть
  message?: string,
  placement: PlacementType = PlacementType.bottomRight,
  onConfirm?: () => void, // Это кнопка для подтверждения, работает только с type = Notify.Confirm
  okText?: string,
  showFull: boolean = false
) => {
  let description = content;

  const displayFull = () =>
    ILSUserNotify(
      type,
      content,
      0, // 0 - для того чтобы уведомление не закрылось самостоятельно
      key,
      icon,
      onClick,
      onClose,
      message,
      placement,
      onConfirm,
      okText,
      true
    );

  if (content && (Array.isArray(content) || typeof content === 'object')) {
    description = getOutputNotify({ content, type, showFull, displayFull });
  }
  const baseClassName = 'bg-component notification-large notification-bordered-left';

  notification.config({
    maxCount: NotifyDurationInSecond.Three
  });

  switch (type) {
    case Notify.Loading:
      const loadingIcon = (
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('height', '25');
            svg.setAttribute('width', '25');
          }}
          src={getSRCIconSVG('loading-blue')}
        />
      );
      notification[Notify.Info]({
        message,
        className: baseClassName,
        description,
        duration,
        key,
        placement: placement as NotificationPlacement,
        icon: icon ?? loadingIcon,
        onClick: onClick ?? (() => {}),
        onClose: onClose ?? (() => {})
      });
      break;
    case Notify.Error:
      const errorIcon = (
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('height', '25');
            svg.setAttribute('width', '25');
          }}
          src={getSRCIconSVG('error-notification')}
        />
      );
      notification[type]({
        message,
        className: baseClassName,
        description,
        key,
        duration: duration ?? EROROR_MESSAGE_DURATION,
        placement: placement as NotificationPlacement,
        icon: errorIcon,
        onClick: onClick ?? (() => {}),
        onClose: onClose ?? (() => {})
      });
      break;
    case Notify.Confirm:
      confirm({
        okButtonProps: { id: 'confirm-modal-yes' },
        cancelButtonProps: { id: 'confirm-modal-cancel' },
        title: message,
        content,
        icon: icon ?? <ExclamationCircleOutlined />,
        onOk:
          (() => {
            onConfirm?.();
            onClose?.();
          }) ?? (() => {}),
        onCancel: onClose ?? (() => {}),
        cancelText: 'Нет',
        okText: okText || 'Да'
      });
      break;
    default:
      notification[type]({
        message,
        className: baseClassName,
        description,
        key,
        duration,
        placement,
        onClick: onClick ?? (() => {}),
        onClose: onClose ?? (() => {})
      });
      break;
  }
};

type UserNotifyConfirm = (
  message?: Parameters<typeof ILSUserNotify>[7],
  content?: Parameters<typeof ILSUserNotify>[1],
  onOk?: Parameters<typeof ILSUserNotify>[9],
  onCancel?: Parameters<typeof ILSUserNotify>[6],
  icon?: Parameters<typeof ILSUserNotify>[4],
  okText?: string
) => void;
//TODO Может отрефакторить на передачу пропсов объектом?
export const ILSUserNotifyConfirm: UserNotifyConfirm = (message, content, onOk, onCancel, icon, okText) => {
  ILSUserNotify(
    Notify.Confirm,
    content,
    NotifyDurationInSecond.Infinity,
    undefined,
    icon,
    undefined,
    onCancel,
    message,
    undefined,
    onOk,
    okText
  );
};

type item = {
  params: Parameters<typeof ILSUserNotify>;
  condition: boolean;
};

/**
 * @deprecated уведомления по загрузкам перенесены в саги
 * @param _name В будущем будет удалено, сейчас не используется
 * @param Notify
 */
export const useUserNotify = (_name?: string, ...Notify: Array<item>): void => {
  const params: item['params'] | undefined = useMemo(() => {
    for (let ind = 0; ind < Notify.length; ind++) {
      if (Notify[ind].condition) {
        return Notify[ind].params;
      }
    }
    return;
  }, [Notify]);
  const stringParams = JSON.stringify(params);
  useEffect(() => {
    if (!params) return;
    ILSUserNotify(...params);
  }, [stringParams]);
};

export const ILSUserNotifyClose = (key: string) => {
  notification.close(key);
};

export const notifyUserAboutNoChanges = () =>
  ILSUserNotify(Notify.Warning, 'Вы не внесли изменений для сохранения', NotifyDurationInSecond.Ten);

/**
 * Переопределенный порядок параметров для вызова уведомления
 */
export const ILSShowMessage = <
  T extends {
    TYPE: Parameters<typeof ILSUserNotify>[0];
    MESSAGE: Parameters<typeof ILSUserNotify>[1];
    DURATION: Parameters<typeof ILSUserNotify>[2];
    KEY: Parameters<typeof ILSUserNotify>[3];
  }
>({
  DURATION,
  KEY,
  MESSAGE,
  TYPE
}: T) => ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
