import { createSelector } from 'reselect';
import { IActiveConfig, IConf, IData, IStore, ITemplate, RootState } from '@modules/planning/children/params/types/store';

export const dataSelector = createSelector(
  (state: RootState) => state.PlanningParams.data,
  (data: IData[]) => data
);
export const confSelector = createSelector(
  (state: RootState) => state.PlanningParams.conf,
  (conf: IConf) => conf
);
export const templateSelector = createSelector(
  (state: RootState) => state.PlanningParams.template,
  (template: ITemplate) => template
);
export const activeConfigSelector = createSelector(
  (state: RootState) => state.PlanningParams.activeConfig,
  (activeConfig: IActiveConfig) => activeConfig
);
export const fetchSelector = createSelector(
  (state: RootState) => state.PlanningParams.isFetching,
  (isFetching: boolean) => isFetching
);
export const savingSelector = createSelector(
  (state: RootState) => state.PlanningParams.isSaving,
  (isSaving: boolean) => isSaving
);
export const errorSelector = createSelector(
  (state: RootState) => state.PlanningParams.error,
  (error: string[]) => error
);

