import { TableRecord } from '@common/types';
import { isRecordDroppable } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/is-record-droppable';
import { DragEvent, MutableRefObject } from 'react';

export const handleRecordDragStart =
  ({ selectRecord }: { selectRecord: MutableRefObject<TableRecord | undefined> }) =>
  (record: TableRecord | undefined) =>
    isRecordDroppable(record?.dictionary)
      ? (_e: DragEvent | undefined, record: TableRecord | undefined) => {
        selectRecord.current = record || undefined;
        }
      : undefined;
