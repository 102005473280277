import { tableCustom } from '@core/containers/table-custom';
import { RESTRICTION_TYPE_TABLE_CONFIG } from '@common/models/restriction-group/config/table';
import { data2table } from '@common/models/restriction-group/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';

export const ILSCatalogRestrictionGroup: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return <ILSCatalogTable {...props} dictionary={Dictionary.RestrictionGroup} dataDecorator={data2table} />;
  },
  RESTRICTION_TYPE_TABLE_CONFIG,
  CatalogDictionary.RestrictionGroup,
  true
);
