import { FC, useState } from 'react';
import { CellType, TableRecord } from '@common/types';
import { ILSCellSelectMultiple } from './cell-types/editable/ils-cell-select-multiple';
import { ILSCellInput } from './cell-types/editable/ils-cell-input';
import { ILSCellInteger } from './cell-types/editable/ils-cell-int';
import { ILSCellFloat } from './cell-types/editable/ils-cell-float';
import { ILSCellColor } from './cell-types/editable/ils-cell-color';
import { ILSCellDate } from './cell-types/editable/ils-cell-date';
import { ILSCellBool } from './cell-types/editable/ils-cell-bool';
import { ILSCellPhone } from './cell-types/editable/ils-cell-phone';
import { ILSCellTimeRange } from './cell-types/editable/ils-cell-range-time';
import { ILSCellGeo } from './cell-types/editable/ils-cell-geo';
import { ILSCellSelectApi } from './cell-types/editable/ils-cell-select-api';
import { ILSCellSelectMultipleApi } from './cell-types/editable/ils-cell-select-multiple-api';
import { ILSCellSelectIcon } from './cell-types/editable/ils-cell-select-icon';
import { ILSCellRegNumber } from './cell-types/editable/ils-cell-reg-number';
import { ILSCellEmail } from './cell-types/editable/ils-cell-email';
import { ILSCellUfloat } from './cell-types/editable/ils-cell-ufloat';
import { ILSCellProgress } from './cell-types/static/ils-cell-progress';
import { ILSCellIconAction } from './cell-types/static/ils-cell-icon-action';
import { ILSCellSelect } from './cell-types/editable/ils-cell-select';
import { ILSCellAntd } from '@components/data-display/table/components/cell-types/static/ils-cell-antd';
import { ILSCellBadgeText } from './cell-types/static/ils-cell-badge-text';
import { ILSDropdownClickableCellButton } from '@components/general/buttons/components/button-cell-dropdown-clickable';
import { ILSCellMenuButton } from './cell-types/static/ils-cell-menu-button';
import { ILSCellStatus } from '@components/data-display/table/components/cell-types/static/ils-cell-status';
import { ILSCellCascaderApi } from '@components/data-display/table/components/cell-types/editable/ils-cell-cascader-api';
import { ILSCellAlert } from '@components/data-display/table/components/cell-types/static/ils-cell-alert';
import { ILSCellConfigurableCascaderApi } from './cell-types/editable/ils-cell-configurable-cascader-api';
import { ILSCellTextAction } from '@components/data-display/table/components/cell-types/static/ils-cell-text-action';
import { ILSCellAddressAutocomplete } from '@components/data-display/table/components/cell-types/editable/ils-cell-address-autocomplete';
import { ILSCellSelectIconAPI } from '@components/data-display/table/components/cell-types/editable/ils-cell-select-icon-api';
import { ILSCellTime } from '@components/data-display/table/components/cell-types/editable/ils-cell-time';
import { ILSCellString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-string';
import { IEditableCell } from '@common/types/components/table/cell';
import { ILSCellBoolString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-bool-string';
import { ILSCellCascaderString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-cascader-string';
import { ILSCellSelectString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-select-string';
import { ILSCellTimeString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-time-string';
import { ILSCellDateString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-date-string';
import { ILSCellColorStatic } from '@components/data-display/table/components/cell-types/toggle/ils-cell-color-static';
import { ILSCellSelectAPIString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-select-api-string';
import { ILSCellGeoString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-geo-string';
import { ILSCellStringMultiple } from '@components/data-display/table/components/cell-types/static/ils-cell-string-multiple';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { CellRecord } from '@common/types/components/table/common';
import { transformReactHighlightNodeIntoText } from '@components/data-display/table/utils/helpers/cells/transform-react-highlight-node-into-text';
import { ILSDropdownCellButton } from '@components/general/buttons/components';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

const CELL_INTEGER = [CellType.Int, CellType.Uint];

export const ILSTypeEditableCell: FC<IEditableCell<TableRecord>> = ({
  type,
  format,
  placeholder,
  dateParseFormat,
  children,
  record,
  dataIndex,
  activeCell,
  tableRef,
  rowIndex,
  ellipsis = true,
  editable = false,
  dictionary,
  allowClear = true,
  handleSave,
  maxField,
  minField,
  validationParameters,
  selectProps,
  options = [],
  fieldNames = DEFAULT_FIELD_NAMES,
  dictionaryInfo,
  dictionaryLoad,
  showParent
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  const toggleEdit = () => setEditing(!editing);

  const save = (value: unknown, cellRecord?: CellRecord | CellRecord[]) => {
    try {
      handleSave?.(record, dataIndex, value, rowIndex, dictionary, cellRecord);
    } catch (error) {
      showUserAndDevError({ userError: 'Ошибка сохранения', error });
    } finally {
      toggleEdit();
    }
  };

  const { step, min, max, maxLength } = validationParameters ?? {
    step: CELL_INTEGER.includes(type) ? 1 : 0.1,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    maxLength: 500
  };
  const { dropdownMenuColumnStyle, showSearch } = selectProps ?? { showSearch: true };

  if (!editing) {
    switch (type) {
      // Время
      case CellType.Time:
        return <ILSCellTimeString dateParseFormat={dateParseFormat} children={children} toggleEdit={toggleEdit} />;
      case CellType.TimeDouble:
        return <ILSCellTimeString double children={children} toggleEdit={toggleEdit} />;
      // Календари
      case CellType.Date:
        return <ILSCellDateString format={format} dateParseFormat={dateParseFormat} children={children} toggleEdit={toggleEdit} />;
      case CellType.DateTime:
        return <ILSCellDateString format={format} dateParseFormat={dateParseFormat} showTime children={children} toggleEdit={toggleEdit} />;
      // Каскадеры
      case CellType.CascaderConfigAPI:
      case CellType.CascaderAPI:
        return (
          <ILSCellCascaderString
            showParent={showParent}
            fieldNames={fieldNames}
            options={options}
            record={record}
            dataIndex={dataIndex}
            children={children}
            toggleEdit={toggleEdit}
          />
        );
      // Селекты
      case CellType.Select:
      case CellType.SelectMultiple:
        return <ILSCellSelectString showArrow fieldNames={fieldNames} options={options} children={children} toggleEdit={toggleEdit} />;
      case CellType.SelectIconAPI:
      case CellType.SelectIcon:
        return <ILSCellSelectString icon fieldNames={fieldNames} options={options} children={children} toggleEdit={toggleEdit} />;
      case CellType.SelectMultipleApi:
      case CellType.SelectAPI:
        return <ILSCellSelectAPIString children={children} record={record} dataIndex={dataIndex} toggleEdit={toggleEdit} />;
      //
      case CellType.Bool:
        return <ILSCellBoolString toggleEdit={toggleEdit} children={children} />;
      case CellType.Color:
        return <ILSCellColorStatic record={record} dataIndex={dataIndex} toggleEdit={toggleEdit} children={children} />;
      case CellType.Geo:
        return <ILSCellGeoString toggleEdit={toggleEdit} children={children} record={record} dataIndex={dataIndex} />;
      default:
        return <ILSCellString children={children} ellipsis={ellipsis} toggleEdit={toggleEdit} />;
    }
  }

  /** Первый элемент children у antd – может быть Element, например expandIcon, второй элемент обрабатывается нами */
  const [node = <></>, value] = transformReactHighlightNodeIntoText(children);

  switch (type) {
    // Время
    case CellType.Time:
      return <ILSCellTime format={format} children={[node, value]} toggleEdit={toggleEdit} save={save} dateParseFormat={dateParseFormat} />;
    case CellType.TimeDouble:
      return (
        <ILSCellTime
          double
          format={format}
          children={[node, value]}
          toggleEdit={toggleEdit}
          save={save}
          dateParseFormat={dateParseFormat}
        />
      );
    // Календари
    case CellType.Date:
      return (
        <ILSCellDate
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          maxField={maxField}
          dateParseFormat={dateParseFormat}
          minField={minField}
          record={record}
          format={format}
          save={save}
          children={[node, value]}
        />
      );
    case CellType.DateTime:
    case CellType.DateTimeString:
      return (
        <ILSCellDate
          //отличие только в showTime
          showTime
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          maxField={maxField}
          dateParseFormat={dateParseFormat}
          minField={minField}
          record={record}
          format={format}
          save={save}
          children={[node, value]}
        />
      );
    // Каскадеры
    case CellType.CascaderAPI:
      return (
        <ILSCellCascaderApi
          save={save}
          tableRef={tableRef}
          allowClear={allowClear}
          toggleEdit={toggleEdit}
          children={[node, value]}
          dictionaryInfo={dictionaryInfo}
          dictionaryLoad={dictionaryLoad}
          dictionary={dictionary}
          options={options}
          fieldNames={fieldNames}
          dropdownMenuColumnStyle={dropdownMenuColumnStyle}
          showSearch={showSearch}
        />
      );
    case CellType.CascaderConfigAPI:
      return (
        <ILSCellConfigurableCascaderApi
          save={save}
          tableRef={tableRef}
          allowClear={allowClear}
          toggleEdit={toggleEdit}
          children={[node, value]}
          dictionaryInfo={dictionaryInfo}
          dictionaryLoad={dictionaryLoad}
          dictionary={dictionary}
          options={options}
          fieldNames={fieldNames}
          dropdownMenuColumnStyle={dropdownMenuColumnStyle}
          showSearch={showSearch}
        />
      );
    // Селекты
    case CellType.Select:
      return (
        <ILSCellSelect
          showSearch={showSearch}
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          save={save}
          children={[node, value]}
          tableRef={tableRef}
        />
      );
    case CellType.SelectAPI:
      return (
        <ILSCellSelectApi
          dictionary={dictionary}
          dictionaryInfo={dictionaryInfo}
          dictionaryLoad={dictionaryLoad}
          showSearch={showSearch}
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          tableRef={tableRef}
          save={save}
          children={[node, value]}
        />
      );
    case CellType.SelectIcon:
      return (
        <ILSCellSelectIcon
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          tableRef={tableRef}
          save={save}
          children={[node, value]}
        />
      );
    case CellType.SelectIconAPI:
      return (
        <ILSCellSelectIconAPI
          dictionary={dictionary}
          dictionaryInfo={dictionaryInfo}
          dictionaryLoad={dictionaryLoad}
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          tableRef={tableRef}
          save={save}
          children={[node, value]}
          editable={editable}
        />
      );
    case CellType.SelectMultiple:
      return (
        <ILSCellSelectMultiple
          showSearch={showSearch}
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          tableRef={tableRef}
          save={save}
          children={[node, value]}
        />
      );
    case CellType.SelectMultipleApi:
      return (
        <ILSCellSelectMultipleApi
          dictionary={dictionary}
          dictionaryInfo={dictionaryInfo}
          dictionaryLoad={dictionaryLoad}
          showSearch={showSearch}
          options={options}
          fieldNames={fieldNames}
          toggleEdit={toggleEdit}
          allowClear={allowClear}
          tableRef={tableRef}
          save={save}
          children={[node, value]}
        />
      );
    // Автокомплитеры
    case CellType.AddressAutocomplete:
      return <ILSCellAddressAutocomplete toggleEdit={toggleEdit} save={save} children={[node, value]} />;
    // Инпуты
    case CellType.Input:
    case CellType.String:
      return (
        <ILSCellInput
          placeholder={placeholder}
          validationParameters={validationParameters}
          save={save}
          children={[node, value]}
          maxLength={maxLength}
          toggleEdit={toggleEdit}
        />
      );
    case CellType.Int:
    case CellType.Uint:
      return <ILSCellInteger step={step} min={min} save={save} children={[node, value]} max={max} toggleEdit={toggleEdit} />;
    case CellType.Float:
      return <ILSCellFloat min={min} step={step} save={save} children={[node, value]} max={max} toggleEdit={toggleEdit} />;
    case CellType.Ufloat:
      return <ILSCellUfloat step={step} save={save} children={[node, value]} max={max} min={min} toggleEdit={toggleEdit} />;
    case CellType.DoubleRangeTimeString:
      return <ILSCellTimeString double children={[node, value]} />;
    case CellType.RangeTime:
      return <ILSCellTimeRange format={format} children={[node, value]} toggleEdit={toggleEdit} save={save} />;
    case CellType.Bool:
      return <ILSCellBool toggleEdit={toggleEdit} editable={editable} save={save} children={[node, value]} />;
    case CellType.Phone:
      return <ILSCellPhone toggleEdit={toggleEdit} save={save} children={[node, value]} />;
    case CellType.RegNumber:
      return <ILSCellRegNumber format={format} toggleEdit={toggleEdit} save={save} children={[node, value]} />;
    case CellType.Email:
      return <ILSCellEmail children={[node, value]} toggleEdit={toggleEdit} save={save} />;
    //Иконки
    case CellType.IconAction:
      return <ILSCellIconAction record={record} dataIndex={dataIndex} />;
    case CellType.TextAction:
      return <ILSCellTextAction record={record} dataIndex={dataIndex} />;
    case CellType.Alert:
      return <ILSCellAlert children={[node, value]} dataIndex={dataIndex} activeCell={activeCell} record={record} />;
    //Кнопки
    case CellType.Button:
      return <ILSDropdownCellButton record={record} dataIndex={dataIndex} />;
    case CellType.MenuButton:
      return <ILSCellMenuButton children={[node, value]} />;
    case CellType.ButtonClickableDropdown:
      return <ILSDropdownClickableCellButton record={record} dataIndex={dataIndex} />;
    // Специфические
    case CellType.Color:
      return <ILSCellColor toggleEdit={toggleEdit} save={save} children={[node, value]} />;
    case CellType.Geo:
      return <ILSCellGeo save={save} toggleEdit={toggleEdit} children={[node, value]} record={record} dataIndex={dataIndex} />;
    case CellType.Progress:
      return <ILSCellProgress children={[node, value]} />;
    case CellType.BadgeText:
      return <ILSCellBadgeText children={[node, value]} />;
    case CellType.Status:
      return <ILSCellStatus children={[node, value]} handleSave={handleSave} record={record} dataIndex={dataIndex} />;
    case CellType.StringMultiple:
      return <ILSCellStringMultiple children={[node, value]} />;
    // В случае если данным типам присвоят значение Editable true могут возникать ошибки, происходит вывод обычной строки
    case CellType.Countdown:
    case CellType.Icon:
    case CellType.LineSeries:
    case CellType.Percent:
    case CellType.Text:
    case CellType.TimeStringUtc:
    default:
      return <ILSCellAntd children={[node, value]} />;
  }
};
