import { Layout } from 'antd';
import React, { FC } from 'react';
import { ContentProps, FooterProps, HeaderProps, LayoutProps, SiderProps } from './types';

const { Header, Footer, Sider, Content } = Layout;

export const ILSLayout: FC<LayoutProps> = (props) => {
  return <Layout {...props} />;
};

export const ILSHeader: FC<HeaderProps> = (props) => {
  return <Header className="ils-header" {...props} />;
};

export const ILSSider: FC<SiderProps> = (props) => {
  return <Sider {...props} />;
};

export const ILSContent: FC<ContentProps> = (props) => {
  return <Content {...props} />;
};

export const ILSFooter: FC<FooterProps> = (props) => {
  return <Footer {...props} />;
};
