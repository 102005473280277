import { tripSaveRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanStatus, Project, TableColumnName, Trip, Vehicle } from '@common/types';
import { PlanningAPI } from '@modules/planning/api';
import { PlanID } from '@common/types/dictionaries/plan';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export type TripSaveTriggerPayload = {
  message?: string;
  tripID: Trip['ID'];
  vehicleID: Vehicle['ID'];
  key: TableColumnName;
  value: unknown;
};
export type TripSaveSuccessPayload = TripSaveTriggerPayload & { planID: PlanID };
export type TripSavePayloadAction = PayloadAction<TripSaveTriggerPayload>;

function* tripSaveWorker(action: TripSavePayloadAction) {
  const { request, success, failure, fulfill } = tripSaveRoutine;
  const { key, value, vehicleID, tripID } = action.payload;

  try {
    switch (key) {
      case TableColumnName.Status:
        yield put(request({ message: 'Установка статуса...' }));
        yield call(PlanningAPI.setTripStatus, { tripIDs: [tripID], status: value as PlanStatus });
        break;
      default:
        yield put(request({ message: 'Сохранение...' }));
        break;
    }
    const planID: Project['ID'] = yield select(activePlanIDSelector);

    const payload: TripSaveSuccessPayload = {
      message: 'Рейс успешно изменён',
      key,
      value,
      vehicleID,
      tripID,
      planID
    };

    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tripSaveWatcher() {
  yield takeLatest(tripSaveRoutine.trigger, tripSaveWorker);
}
