import { put, takeLatest } from 'redux-saga/effects';
import { clearAddressSuggestRoutine } from '@common/feature/suggestion-autocomplete/address/actions';

function* clearAddressSuggestWorker() {
  const { success } = clearAddressSuggestRoutine;
  yield put(success());
}

export function* clearAddressSuggestWatcher() {
  yield takeLatest(clearAddressSuggestRoutine.trigger, clearAddressSuggestWorker);
}
