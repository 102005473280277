import { MapActivePlan } from '@modules/planning/children/by-date/types/map-container';
import { PlanningParameters } from '@modules/settings/types/store';
import { PlanningSettingsKeys } from '@modules/settings/types';
import { GeoCoordType, IMapTrack, Key, Keys } from '@common/types';
import { PointWithData } from '@common/utils/get-track/types';
import { isString, omit } from 'lodash';
import { getBoundsForPolylines } from '@common/utils';
import { createTrackKeyByPoint } from '@common/utils/get-track/create-track-key';
import { getFocusItem } from '@modules/planning/children/by-date/decorators/map/get-focus-item';
import { isNeedBounds } from '@modules/planning/children/by-date/decorators/map/is-need-bounds';
import { getPointData } from '@modules/planning/children/by-date/decorators/map/get-point-data';
import { PolylineWeight } from '@core/components/map/constants/settings';

export const MapLinesWithSettings = (
  mapData?: Pick<MapActivePlan, 'track' | 'waypointMapData'>,
  settings?:
    | Pick<
        PlanningParameters,
        | PlanningSettingsKeys.UseRedis
        | PlanningSettingsKeys.HideLinesOnTrackAbsent
        | PlanningSettingsKeys.ActiveWidth
        | PlanningSettingsKeys.InactiveWidth
      >
    | undefined,
  activeItems?: { selectedRoutesIDs: Keys; selectedTripsIDs: Keys; selectedWaypointIDs: Keys },
  trackData?: Record<string, PointWithData> | undefined,
  cashGeohash?: (hashCoords: string) => GeoCoordType[],
  focusRow?: { key: Key | null | undefined }
) => {
  if (!mapData?.track || !mapData?.track.length) return [];

  const focusItem = getFocusItem({ key: focusRow?.key, waypointMapData: mapData.waypointMapData });

  const polylines: IMapTrack[] = mapData?.track?.map((polyline) => {
    const vehicleForbiddenZones = polyline?.forbiddenZoneIDs?.join(';');
    const WaypointID = polyline.waypoint?.WaypointID;
    const TripID = polyline.TripID;
    const VehicleID = polyline?.vehicle?.ID;

    const isSelectedVehicle = VehicleID && activeItems?.selectedRoutesIDs.includes(VehicleID);
    const isSelectedTrip = TripID && activeItems?.selectedTripsIDs.includes(TripID);
    const isSelectedWaypoint = WaypointID && activeItems?.selectedWaypointIDs.includes(WaypointID);
    const color = polyline.color;
    let coords = polyline.coords;
    const active: boolean =
      Boolean(isSelectedVehicle || isSelectedTrip || isSelectedWaypoint) ||
      focusItem({
        WaypointID,
        TripID,
        VehicleID
      });
    const showMarker: boolean = active;

    /** Ставить вместо coords - nativeCoords
     Только если есть у начальной и конечной точки есть координаты и
     Включена загрузка с редиса для временного отображения или отключено сокрытие прямых */
    if (isNeedBounds({ polyline, settings, coords })) {
      coords = getBoundsForPolylines([polyline]);
    }

    if (coords && typeof coords !== 'string') {
      if (Array.isArray(coords) && coords.length === 2) {
        if (trackData && settings?.useRedis) {
          const Details = omit(polyline?.trackData, 'providerName');
          const key = createTrackKeyByPoint({
            from: { latitude: coords[0][0], longitude: coords[0][1] },
            to: { latitude: coords[1][0], longitude: coords[1][1] },
            forbiddenZones: vehicleForbiddenZones,
            ProviderName: polyline?.trackData?.providerName,
            Details
          });

          const data = trackData[key];
          if (data && data.track) {
            coords = data.track;
          } else {
            if (settings?.hideLinesOnTrackAbsent) {
              coords = polyline.coords;
            }
          }
        }
      }
    }

    if (isString(coords) && cashGeohash) {
      coords = cashGeohash(coords);
    }

    const weight = Number(
      settings
        ? active
          ? settings.activeWidth ?? PolylineWeight.activeWidth
          : settings.inactiveWidth ?? PolylineWeight.inactiveWidth
        : PolylineWeight.inactiveWidth
    );

    const point = showMarker ? getPointData({ polyline, TripID, VehicleID, color }) : undefined;

    //TODO нужно забрать из polyline только нужные свойства для карты
    return {
      ...polyline,
      coords,
      active: showMarker,
      color,
      weight,
      point
    };
  });

  return polylines;
};
