import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { DictionariesEndpoint, Dictionary, IILSTableProps, Keys } from '@common/types';
import { forwarderPointStatusesGroup2table } from '@modules/settings/utils/helpers/decorators/forwarder-point-statuses-group-to-table';
import { forwarderPointStatusesGroupSelector } from '@modules/settings/selectors';
import { mobileApplicationDictionaryOperationRoutine } from '@modules/settings/actions';
import {
  forwarderPointStatusesGroupFocusRowSelector,
  forwarderPointStatusesOptionsSelector
} from '@modules/settings/selectors/mobile-application';

export const settingsForwarderPointStatusesTableGroupDataSourceSelector = createSelector(
  forwarderPointStatusesGroupSelector,
  (forwarderPointStatusesGroup) => forwarderPointStatusesGroup2table(forwarderPointStatusesGroup)
);

export const settingsForwarderPointStatusesTableGroupSelector = (dispatch: Dispatch) =>
  createSelector(
    settingsForwarderPointStatusesTableGroupDataSourceSelector,
    forwarderPointStatusesOptionsSelector,
    forwarderPointStatusesGroupFocusRowSelector,
    (dataSource, refData, focusRowID) => {
      const mobileApplicationDictionary = Dictionary.ForwarderPointStatusesGroup;

      const handleSave: IILSTableProps<any>['handleSave'] = (record, dataIndex, value) => {
        dispatch(
          mobileApplicationDictionaryOperationRoutine({
            mobileApplicationDictionary,
            ids: [record.ID],
            operation: DictionariesEndpoint.Update,
            values: { [dataIndex]: value }
          })
        );
      };

      const handleCreate = (values: object) => {
        dispatch(
          mobileApplicationDictionaryOperationRoutine({
            mobileApplicationDictionary,
            operation: DictionariesEndpoint.Create,
            values
          })
        );
      };

      const handleDelete = (ids: Keys | null) => {
        dispatch(
          mobileApplicationDictionaryOperationRoutine({
            mobileApplicationDictionary,
            ids,
            operation: DictionariesEndpoint.Delete
          })
        );
      };

      return {
        dataSource,
        handleSave,
        handleCreate,
        handleDelete,
        focusRow: focusRowID ? { key: focusRowID } : null,
        refData
      };
    }
  );

