import { routeSaveRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanStatus, TableColumnName, Trip, Vehicle } from '@common/types';
import { PlanningAPI } from '@modules/planning/api';
import { routeTripIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { isEmpty } from 'lodash';
import { throwError } from '@common/utils/validators/check-data-for-error';

export type RouteSavePayloadAction = PayloadAction<{
  vehicleID: Vehicle['ID'];
  key: TableColumnName;
  value: unknown;
}>;

function* routeSaveWorker(action: RouteSavePayloadAction) {
  const { request, success, failure, fulfill } = routeSaveRoutine;
  const { key, value, vehicleID } = action.payload;

  try {
    switch (key) {
      case TableColumnName.Status:
        yield put(request({ message: 'Установка статуса...' }));
        const tripIDs: Array<Trip['ID']> = yield select((state) => routeTripIDsSelector(state, vehicleID));
        if (isEmpty(tripIDs)) {
          throwError({ message: 'Нельзя менять статус у маршрута без рейсов' });
        }
        yield call(PlanningAPI.setTripStatus, { tripIDs, status: value as PlanStatus });
        break;
      default:
        yield put(request({ message: 'Сохранение...' }));
        break;
    }
    const payload: {
      vehicleID: Vehicle['ID'];
      message: string;
      key: TableColumnName;
      value: unknown;
    } = {
      message: 'Маршрут успешно изменён',
      vehicleID,
      key,
      value
    };
    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* routeSaveWatcher() {
  yield takeLatest(routeSaveRoutine.trigger, routeSaveWorker);
}
