import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { createOrderRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { FormType, InitialState } from '@modules/orders-and-trips/types/general';
import { IndexedArray, Order } from '@common/types';

export const createOrderReducer = createReducer(initialState, {
  [createOrderRoutine.REQUEST]: (state: InitialState) => {
    state.extraData.isFetching = true;
    state.orders.isFetching = true;
    return state;
  },
  [createOrderRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      order: IndexedArray<Order>;
    }>
  ) => {
    const { order } = action.payload;
    state.orders.data = {
      ...state.orders.data,
      ...order
    };
    const orderID = Object.values(order ?? {})?.[0]?.ID;
    state.orders.activeRecord = { key: orderID, ID: orderID };
    state.showForm = FormType.UpdateOrder;
    return state;
  },
  [createOrderRoutine.FAILURE]: onFailure,
  [createOrderRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.orders.isFetching = false;
    state.extraData.isFetching = false;
    return state;
  }
});

