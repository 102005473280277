import { sortBy } from 'lodash';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';
import { Dictionary, IndexedArray, PlanResponse, RouteTotal, TableColumnName, TripTotal } from '@common/types';
import { createArrayFromIndexedArray, timestampToDateTimeStringWithTZ, toFixed2 } from '@common/utils';
import { tripsToChildren } from '@modules/planning/children/by-date/decorators/table/trips-to-children';
import { getNotice } from '@modules/planning/children/by-date/decorators/notice/get-notice';
import { getPenaltiesTip } from '@modules/planning/children/by-date/decorators/penalties/get-penalties-tip';
import { getFocusOnMapItem } from '@modules/planning/children/by-date/helpers/tables/get-focus-on-map-item';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { NotificationTipHTML } from '@modules/planning/children/by-date/components/result/tips';
import { getNotificationIconType } from '@modules/planning/children/by-date/decorators/notice/get-notification-icon-type';

export const routesToTable = (plan: PlanResponse, setTriggerMapFocus: SetTriggerMapFocus) => {
  //TODO helper for sort
  return sortBy(createArrayFromIndexedArray(plan?.RouteTotal), ['Number'], ['asc']).map((route) => {
    const {
      Vehicle: { ID, Name, Model, RegNumber, Deleted },
      TripTotal,
      Comment,
      FullName,
      CargoCount,
      ClientCount,
      RestrictionEnum,
      ZoneEnum,
      ForbiddenZoneEnum,
      StartTime,
      EndTime,
      StartTimeOffset,
      EndTimeOffset,
      Distance,
      Mass,
      Penalty,
      Volume
    } = route;
    const { notices } = getNotice({ planNotice: plan.PlanNotice, ID });
    const focusOnMapItem = getFocusOnMapItem(setTriggerMapFocus);
    const dictionary = Dictionary.Route;
    const ownNotifications = notices.Route.notices;

    return {
      ...route,
      TripTotal: null,
      key: ID,
      dictionary,
      [TableColumnName.VehicleID]: ID,
      [TableColumnName.ID]: ID,
      [TableColumnName.RouteColor]: getVehicleRouteColor(route.Vehicle),
      [TableColumnName.Model]: Model,
      RouteColorChanged: (route as RouteTotal & { changed: boolean }).changed,
      [TableColumnName.FullName]: `${FullName || `${Name} ${RegNumber}`} ${Deleted ? '(удалён)' : EMPTY_STRING}`,
      FullName_iconLabel: route.TenderID ? 'ТП' : EMPTY_STRING,
      FullName_icon: `vehicle${Deleted ? '-red' : EMPTY_STRING}`,
      FullName_tip: Deleted ? 'Автомобиль удалён из справочника автомобилей' : EMPTY_STRING,
      [TableColumnName.Restriction]: Object.values(RestrictionEnum ?? {}).join(JoinChar.Comma),
      [TableColumnName.ForbiddenZoneID]: Object.values(ForbiddenZoneEnum ?? {}).join(JoinChar.Comma),
      [TableColumnName.Zone]: Object.values(ZoneEnum ?? {}).join(JoinChar.Comma),
      [TableColumnName.TenderID]: route.TenderID,
      [TableColumnName.Comment]: plan.Comment || Comment,
      [TableColumnName.Cargo]: CargoCount,
      [TableColumnName.Client]: ClientCount,
      [TableColumnName.StartTime]: timestampToDateTimeStringWithTZ(StartTime, StartTimeOffset),
      [TableColumnName.EndTime]: timestampToDateTimeStringWithTZ(EndTime, EndTimeOffset),
      [TableColumnName.Penalty]: toFixed2(Penalty),
      [TableColumnName.Distance]: toFixed2(Distance),
      [TableColumnName.Mass]: toFixed2(Mass),
      [TableColumnName.Volume]: toFixed2(Volume),
      [TableColumnName.FocusOnMap]: focusOnMapItem,
      [TableColumnName.Notification]: getNotificationIconType(ownNotifications.concat(notices.Trip.notices, notices.Waypoint.notices)),
      Notification_htmltip: NotificationTipHTML({
        ownNotifications,
        childNotifications: notices.Trip.notices.concat(notices.Waypoint.notices)
      }),
      Penalty_htmltip: getPenaltiesTip(plan.PlanPenalty, route.Penalty),
      [TableColumnName.Efficiency]: route.Efficiency?.MaxEfficiency,
      [TableColumnName.MassPercent]: route.Efficiency?.MassPercent,
      [TableColumnName.VolumePercent]: route.Efficiency?.VolumePercent,
      [TableColumnName.BoxPercent]: route.Efficiency?.BoxesPercent,
      [TableColumnName.PalletPercent]: route.Efficiency?.PalletsPercent,
      [TableColumnName.DurationPercent]: route.Efficiency?.DurationPercent,
      [TableColumnName.DistancePercent]: route.Efficiency?.DistancePercent,
      [TableColumnName.ClientPercent]: route.Efficiency?.ClientsPercent,
      [TableColumnName.OrderPercent]: route.Efficiency?.OrdersPercent,
      Efficiency_htmltip: 'Эффективность маршрута рассчитана как средний процент эффективности по рейсам',
      children: tripsToChildren(TripTotal as IndexedArray<TripTotal & { changed: boolean }>, focusOnMapItem, notices)
    };
  });
};
