import { isEqualIDRow, isEqualRow } from '@common/utils/table';
import { useEffect } from 'react';
import { MapData, MapProps } from '../types/components';
import { Dictionary } from '@common/types';

export const useMap = (
  dictionary: Dictionary | undefined,
  mapProps: MapProps | null,
  activeItem: any[],
  mapSize: number,
  showMap: boolean,
  mapData: MapData,
  setMapData: (mapData: MapData) => void
) => {
  useEffect(() => {
    if (mapProps && showMap) {
      let newMapData: MapData;
      newMapData = {
        mapProps,
        dataSource: activeItem,
        activeKeys: activeItem.map((item: any) => (item ? item.ID ?? item.rowKey ?? item.key : undefined)).filter((r) => r),
        dictionary,
        widthPercent: Number(mapSize) === 0 ? 41 : Number(mapSize)
      };
      if (mapData && dictionary === mapData.dictionary && activeItem.length === mapData.activeKeys.length) {
        if (mapData.dataSource.length === 0) return;

        for (let ind = 0; ind < mapData.dataSource.length; ind++) {
          let oldRow = mapData.dataSource[ind];
          let newRow = activeItem.find((it) =>
            isEqualIDRow(it, [oldRow.ID || undefined, oldRow.rowKey || undefined, oldRow.key || undefined])
          );

          if (!isEqualRow(newRow, oldRow, mapProps.field)) {
            setMapData(newMapData); //BorderHash
            return;
          }
        }
      } else {
        setMapData(newMapData);
      }
    } else {
      setMapData(null);
    }
  }, [dictionary, mapProps, activeItem, mapSize, showMap, setMapData]);
};
