import { createSelector } from 'reselect';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { IntegrationTypes } from '@modules/orders-and-trips/types/integrations';

export const availableTransportCompanyIntegrationsFetchSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.availableIntegrations.isFetching,
  (isFetching) => isFetching
);

export const availableTransportCompanyIntegrationsSelector = createSelector(
  availableTransportCompanyIntegrationsFetchSelector,
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.availableIntegrations.data,
  (isFetching, availableIntegrations) => ({
    availableTransportCompany: availableIntegrations?.[IntegrationTypes.tenderOutFastDelivery] ?? [],
    isFetching
  })
);

export const preCostSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.availableIntegrations.preCost,
  (preCost) => preCost
);

export const fakePreCostSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.availableIntegrations.fakePreCost,
  (preCost) => preCost
);

export const fakePreCostValuesSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.availableIntegrations.fakePreCostValues,
  (values) => values
);
