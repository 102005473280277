import { EMPTY } from '@common/constants';
import { filterObject } from '@common/utils';
import { isNeedLocalSaveRecord } from '@modules/catalog/helpers/is-need-local';
import { catalogCreateLocalRoutine, catalogCreateRoutine, catalogUpdateRoutine } from '@modules/catalog/actions';
import { createCatalogPayload } from '@modules/catalog/helpers/table/create-catalog-payload';
import { Dictionary } from '@common/types';
import { Dispatch } from 'redux';
import { patchCatalogPayloadForDepotChildTable } from '@modules/catalog/helpers/table/patch-catalog-payload-for-depot-child-table';
import { IRecord, SaveDictionaryType, Value } from '@modules/catalog/types/catalog';

export const handleUpdateRow =
  ({ dispatch }: { dispatch: Dispatch }) =>
  (dictionary: Dictionary, record: IRecord, values: Value, saveDictionary?: SaveDictionaryType) => {
    //Запись с ID === EMPTY, сохраняется только в локальном state, при изменении полей пытаемся сохранить запись на сервере
    if (record.ID === EMPTY) {
      //удаляем лишние поля из record, чтобы сервер, при сохранении присвоил правильный ID
      const data = filterObject({ ...record }, ['ID', 'key', 'selectType']);

      const payload = {
        dictionary,
        values: [Object.assign(data, values)],
        saveDictionary
      };

      const isLocal = isNeedLocalSaveRecord({ dictionary, data });
      if (isLocal) {
        dispatch(catalogCreateLocalRoutine(payload));
      } else {
        dispatch(catalogCreateRoutine(payload));
      }
    } else {
      const payload = createCatalogPayload(dictionary, record, values, saveDictionary);
      if (payload) {
        if (dictionary === Dictionary.Depot) {
          patchCatalogPayloadForDepotChildTable({ values, payload, record });
        }
        dispatch(catalogUpdateRoutine(payload));
      }
    }
  };
