import { createModuleAction } from '@common/utils/general/create-module-action';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { DashboardAction } from '../types/api';

const MODULE_NAME = 'MONITORING';

export const createDetailsRoutine = (name: DashboardAction) => createRoutine(createModuleAction(MODULE_NAME, name));

export const monitoringActiveDashboardTabRoutine = createDetailsRoutine(DashboardAction.SetActiveDashboardTab); // Рутина устанавливающая активную вкладку с нужной таблицей
export const monitoringDashboardInfoRoutine = createDetailsRoutine(DashboardAction.GetDashboardInfo); // Получает информацию для таблицы активной вкладки
export const monitoringDashboardAllInfoRoutine = createDetailsRoutine(DashboardAction.GetDashboardAllInfo); // Рутина получает информацию для всех таблиц
export const monitoringDashboardUpdateViewRoutine = createDetailsRoutine(DashboardAction.UpdateViewDashboard); // Рутина для обновления данных
export const monitoringGetProgressRoutine = createDetailsRoutine(DashboardAction.GetProgress); // Получает информацию для прогресс баров

export const monitoringDashboardRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [monitoringDashboardInfoRoutine]; // Работа с нотификациями
