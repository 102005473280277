import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { convertToMB } from '@common/utils/helpers/download-file-by-url';
import { UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { isNil } from 'lodash';

export type BeforeUploadFiles = {
  beforeUpload: UploadProps['beforeUpload'];
  file: RcFile;
  fileList: RcFile[];
  fileMaxSize?: number;
  defaultPermittedTypes?: Array<string>;
  defaultFormats?: Array<string>;
};

const checkExelVendorRegular = /^application.+excel$/gi;

export const checkBeforeUploadFiles = ({
  file,
  beforeUpload,
  fileMaxSize,
  defaultPermittedTypes,
  defaultFormats,
  fileList
}: BeforeUploadFiles) => {
  if (file.type && defaultPermittedTypes && !defaultPermittedTypes?.includes(file.type)) {
    //!NOTE отдельный кейс для файлов созданных экселем
    if (file.type.match(checkExelVendorRegular)?.length && !isNil(file?.name)) {
      if (!defaultFormats?.some((format) => file.name.slice(-format.length).includes(format))) {
        ILSUserNotify(Notify.Error, `Неверный формат файла. Можно загружать следующие файлы:  ${defaultFormats?.join(', ')}`, 3);
      }
    } else {
      ILSUserNotify(Notify.Error, `Неверный формат файла. Можно загружать следующие файлы:  ${defaultFormats?.join(', ')}`, 3);
    }
  }

  if (file.size && fileMaxSize && file.size > fileMaxSize) {
    ILSUserNotify(Notify.Error, `Максимальный размер файла ${convertToMB(fileMaxSize)}МБ`, 3);
  }

  if (!file.size) {
    ILSUserNotify(Notify.Error, 'Нельзя загружать пустые файлы', 3);
  }
  beforeUpload?.(file, fileList);
  return false;
};
