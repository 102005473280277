import { Cargo, DepotTypeId, Orders, ProjectState, Restriction, TableColumnName } from '@common/types';
import { NOT_EXIST_DEPOT, ReduceNumberOperand } from '@common/models/order/constants';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';
import { getDecoratedOrders } from '@common/models/order/decorators/get-decorated-orders';
import { countOrdersColumnTotalByName } from '@common/models/order/decorators/count-orders-column-total-by-name';
import { countMinOrderDeliveryDate } from '@common/models/order/decorators/count-min-order-delivery-date';
import { countOrderLoadUnloadStartEnd } from '@common/models/order/decorators/count-order-load-unload-start-end';

export const getOrdersDataSourceByTargetId = ({
  orders,
  resultOrders,
  depots
}: {
  orders: Orders;
  resultOrders: ReturnType<typeof getDecoratedOrders>;
  depots?: ProjectState['Depot'];
}) => {
  const ordersByTargetID = orders?.reduce(
    (ordersByTargetID: Record<DepotTypeId, Partial<Record<TableColumnName | string, any>>>, order) => {
      const targetID = order.TargetID ?? NOT_EXIST_DEPOT;
      const Target = targetID ? depots?.[targetID] : null;
      const sourceID = order.SourceID ?? NOT_EXIST_DEPOT;
      const Source = sourceID ? depots?.[sourceID] : null;

      const key = `Source${sourceID}Target${targetID}`;
      if (!(key in ordersByTargetID)) {
        const children = resultOrders.filter(({ TargetID, SourceID }) => {
          if (targetID && sourceID) {
            return compareAsString(TargetID?.[1], targetID) && compareAsString(SourceID?.[1], sourceID);
          } else if (targetID && !sourceID) {
            return compareAsString(TargetID?.[1], targetID) && !SourceID?.[1];
          } else if (!targetID && sourceID) {
            return compareAsString(SourceID?.[1], sourceID) && !TargetID?.[1];
          } else {
            return !SourceID?.[1] && !TargetID?.[1];
          }
        });
        const { UnloadStartOverload, UnloadEndOverload, LoadStartOverload, LoadEndOverload, TargetZoneID, TargetZoneEnum } = children[0];
        const LoadStart = countOrderLoadUnloadStartEnd({
          orders: children,
          dateOrderAction: TableColumnName.LoadStart
        });
        const LoadEnd = countOrderLoadUnloadStartEnd({ orders: children, dateOrderAction: TableColumnName.LoadEnd });
        const UnloadStart = countOrderLoadUnloadStartEnd({
          orders: children,
          dateOrderAction: TableColumnName.UnloadStart
        });
        const UnloadEnd = countOrderLoadUnloadStartEnd({
          orders: children,
          dateOrderAction: TableColumnName.UnloadEnd
        });
        const key = `Target${targetID}Source${sourceID}`;
        ordersByTargetID[key] = {
          key,
          ...Target,
          OrderCode: key,
          Source,
          Target,
          SourceID: sourceID ? [Source?.DepotTypeID, sourceID] : [],
          TargetID: targetID ? [Target?.DepotTypeID, targetID] : [],
          SourceEnum: sourceID ? { [sourceID]: Source?.Name ?? `Точка ${sourceID}` } : [],
          TargetEnum: targetID ? { [targetID]: Target?.Name ?? `Точка ${targetID}` } : [],
          TargetAddress: Target?.AddressDescription,
          LoadStart,
          LoadEnd,
          UnloadStart,
          UnloadEnd,
          CargoID: children
            .reduce((cargoIDs, order) => {
              order.CargoID && cargoIDs.push(order.CargoID);
              return cargoIDs;
            }, [] as Array<Array<Cargo['ID']>>)
            .flat(),
          CargoEnum: children.reduce((cargoEnums, order) => {
            return { ...cargoEnums, ...order.CargoEnum };
          }, {} as Record<number, string | null>),
          RestrictionID: children
            .reduce((cargoIDs, order) => {
              order.RestrictionID && cargoIDs.push(order.RestrictionID as Array<number>);
              return cargoIDs;
            }, [] as Array<Array<Restriction['ID']>>)
            .flat(),
          RestrictionEnum: children.reduce((RestrictionEnums, order) => {
            return { ...RestrictionEnums, ...order.RestrictionEnum };
          }, {} as Record<number, string | null>),
          LoadDate: countMinOrderDeliveryDate({ orders: children, dateOrderAction: TableColumnName.LoadDate }),
          UnloadDate: countMinOrderDeliveryDate({ orders: children, dateOrderAction: TableColumnName.UnloadDate }),
          Amount: countOrdersColumnTotalByName({ orders: children, column: TableColumnName.Amount }),
          Mass: countOrdersColumnTotalByName({
            orders: children,
            column: TableColumnName.Mass,
            operand: ReduceNumberOperand.Fixed
          }),
          Volume: countOrdersColumnTotalByName({
            orders: children,
            column: TableColumnName.Volume,
            operand: ReduceNumberOperand.Fixed
          }),
          Pallets: countOrdersColumnTotalByName({
            orders: children,
            column: TableColumnName.Pallets,
            operand: ReduceNumberOperand.Fixed
          }),
          Boxes: countOrdersColumnTotalByName({
            orders: children,
            column: TableColumnName.Boxes,
            operand: ReduceNumberOperand.Fixed
          }),
          Cost: countOrdersColumnTotalByName({
            orders: children,
            column: TableColumnName.Cost,
            operand: ReduceNumberOperand.Fixed
          }),
          LoadDuration: countOrdersColumnTotalByName({ orders: children, column: TableColumnName.LoadDuration }) + (Target?.Delay ?? 0),
          UnloadDuration: countOrdersColumnTotalByName({ orders: children, column: TableColumnName.UnloadDuration }) + (Target?.Delay ?? 0),
          UnloadStartOverload,
          UnloadEndOverload,
          LoadStartOverload,
          LoadEndOverload,
          TargetZoneID,
          TargetZoneEnum,
          children
        };
      }
      return ordersByTargetID;
    },
    {} as Record<DepotTypeId, Partial<Record<TableColumnName | string, any>>>
  );
  return createArrayFromIndexedArray(ordersByTargetID);
};
