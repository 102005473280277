import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringCreateVehicleTrackerRoutine } from '../actions';

// Создаёт привязки трекеров к автомобилям
export const monitoringCreateVehicleTrackerReducer = createReducer(initialState, {
  [monitoringCreateVehicleTrackerRoutine.REQUEST]: (state) => {
    state.vehicleTrackers.isFetching = true;

    return state;
  },
  [monitoringCreateVehicleTrackerRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const { data } = action.payload;

    state.vehicleTrackers.data = data;
    state.vehicleTrackers.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringCreateVehicleTrackerRoutine.FAILURE]: (state) => {
    state.vehicleTrackers.lastUpdate = undefined;

    return state;
  },
  [monitoringCreateVehicleTrackerRoutine.FULFILL]: (state) => {
    state.vehicleTrackers.isFetching = false;
    return state;
  }
});
