import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';
import { getPointsStatusesList } from '@modules/monitoring/children/event-details/actions';
import { PointsStatusesList } from '@common/types/dictionaries/monitoring';

// Устанавливает в список значения статусов точек
export const monitoringPointsStatusesListReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [getPointsStatusesList.REQUEST]: (state) => {
    state.PointsStatusesList.isFetching = true;
    return state;
  },
  [getPointsStatusesList.SUCCESS]: (state, action: PayloadAction<PointsStatusesList>) => {
    state.PointsStatusesList.data = action.payload;
    return state;
  },
  [getPointsStatusesList.FAILURE]: (state, action: PayloadAction<string>) => {
    state.PointsStatusesList.isFetching = false;
    state.PointsStatusesList.error = action.payload;
    return state;
  },
  [getPointsStatusesList.FULFILL]: (state) => {
    state.PointsStatusesList.isFetching = false;
    return state;
  }
});
