import { useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '../../selectors';
import { MONITORING_DATE_FORMAT } from '../../config';
import { changeDateFormat } from '@common/utils/helpers/date-time/date-time';
import { ILSDataPickerIntervalComponent } from '@common/components/data-entry/data-picker-interval/data-picker-interval';

interface Props {
  handleDate: (date: string[]) => void;
}

// Компонент для выбора даты
export const ILSMonitoringDatePickerComponent = (props: Props) => {
  const { handleDate } = props;
  let { startDate, endDate } = useAppSelector(monitoringDateSelector);

  return (
    <ILSDataPickerIntervalComponent
      handleDate={handleDate}
      startDate={startDate}
      endDate={endDate}
      changeDateFormat={changeDateFormat}
      DATE_FORMAT={MONITORING_DATE_FORMAT}
    />
  );
};
