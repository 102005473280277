import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { catalogHandleMobileApplicationActionRoutine } from '@modules/catalog/actions';
import { Driver } from '@common/types';
import { isEmpty } from 'lodash';
import { HandleMobileApplicationAction } from '@modules/catalog/types/functions';
import {
  CatalogDeleteDriverFromMobileApplicationButtonComponent,
  CatalogInviteDriverToMobileApplicationButtonComponent,
  CatalogResendPasswordToDriverMobileApplicationButtonComponent
} from '@modules/catalog/components/tables/buttons';

interface IProps {
  cantInvite: boolean;
  cantDelete: boolean;
  selectedDrivers: Array<Driver['ID']>;
}

export const CatalogMobileApplicationButtons: FC<IProps> = ({ cantInvite, cantDelete, selectedDrivers }) => {
  const dispatch = useDispatch();

  const handleMobileApplicationAction: HandleMobileApplicationAction = ({ operation, driverIDs }) => {
    dispatch(catalogHandleMobileApplicationActionRoutine({ operation, driverIDs }));
  };

  return (
    <>
      {!isEmpty(selectedDrivers) && (
        <>
          <CatalogDeleteDriverFromMobileApplicationButtonComponent
            disabled={cantInvite}
            selectedDrivers={selectedDrivers}
            handleMobileApplicationAction={handleMobileApplicationAction}
          />
          <CatalogInviteDriverToMobileApplicationButtonComponent
            disabled={cantDelete}
            selectedDrivers={selectedDrivers}
            handleMobileApplicationAction={handleMobileApplicationAction}
          />
          <CatalogResendPasswordToDriverMobileApplicationButtonComponent
            disabled={cantInvite}
            selectedDrivers={selectedDrivers}
            handleMobileApplicationAction={handleMobileApplicationAction}
          />
        </>
      )}
    </>
  );
};
