import { useInterval } from '@common/hooks/use-interval';
import { useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { deepEqual } from 'fast-equals';
import { Dispatch } from 'redux';
import { updateLastInfoRoutine } from '../actions';
import { MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS } from '../configs/api-intervals';
import { activeVehicleIDSelector, lastInfoRequestSelector } from '../selectors';

/**
 * Обновление trackerLastInfo в eventDetails для выделенных ТС
 */
export const useUpdateLastInfo = (selectedVehicleIDs: Array<number | string>, dispatch: Dispatch) => {
  const lastInfoRequest = useAppSelector(lastInfoRequestSelector);
  const activeVehicleID = useAppSelector(activeVehicleIDSelector);
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);

  const updateLastInfo = () => {
    if (!lastInfoRequest.isFetching && startDate && endDate && (selectedVehicleIDs?.length || activeVehicleID)) {
      let vehicleID: (string | number)[] = [];
      if (selectedVehicleIDs?.length) {
        vehicleID = selectedVehicleIDs.slice();
      }
      if (activeVehicleID && !vehicleID.includes(activeVehicleID.toString())) {
        vehicleID.push(activeVehicleID.toString());
      }
      const updates =
        lastInfoRequest.from === startDate && lastInfoRequest.till === endDate && deepEqual(vehicleID.sort(), lastInfoRequest.vehicleIDs);
      dispatch(updateLastInfoRoutine({ vehicleID, from: startDate, till: endDate, updates }));
    }
  };

  useInterval(updateLastInfo, MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.LAST_INFO, [
    startDate,
    endDate,
    selectedVehicleIDs,
    activeVehicleID
  ]);
};
