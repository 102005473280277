import { FC } from 'react';
import { ILSButton, ILSSwitch } from '@common/components';
import { ButtonAction } from '@components/general/buttons';
import { Module } from '@common/types';
import { MenuForSchemes } from '@modules/planning/children/scheme-editor/constants';
import { MenuSchemesSwitcher } from '@modules/planning/children/scheme-editor/types';
import { DownloadSchemeIniButton } from '@modules/planning/children/scheme-editor/containers/download-scheme-ini-button';

interface IProps {
  menu: MenuSchemesSwitcher;

  onChange(checked: boolean, event: Event, tab: MenuForSchemes): void;
}

export const SchemaEditorMenuList: FC<IProps> = ({ menu, onChange }) => {
  return (
    <ul className={'scheme-editor-menu'}>
      {menu.map(([item, checked]) => {
        const handleChange = (checked: boolean, event: Event) => onChange(checked, event, item as MenuForSchemes);
        return (
          <li key={item} className={'scheme-editor-menu-item'}>
            <ILSSwitch checked={checked} onChange={handleChange} unCheckedChildren={item} checkedChildren={item} />
          </li>
        );
      })}
      <li className={'scheme-editor-menu-item'}>
        <ILSButton htmlType={'submit'} form={Module.SchemeEditor} action={ButtonAction.Save} type={'primary'} />
      </li>
      <DownloadSchemeIniButton />
    </ul>
  );
};
