import { FC } from 'react';
import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { ILSCatalogVehicleTimeline } from './vehicle-timeline';
import { ILSCatalogDriverTimeline } from './driver-timeline';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { CatalogDictionary, Dictionary } from '@common/types';
import { driver2table, DRIVER_TABLE_CONFIG } from '@common/models/driver';
import { useSelector } from 'react-redux';
import { useDriverInviteWithVerification } from '../../hooks/table/use-driver-invite-with-verification';
import { table2driver } from '@common/models/driver/decorators';
import { driverTableOptionsSelector } from '@modules/catalog/selectors/options';

const refTable = {
  DriverTimelineID: {
    title: 'Расписание работы водителя',
    component: ILSCatalogDriverTimeline
  },
  VehicleTimelineID: {
    title: 'Режим работы автомобиля',
    component: ILSCatalogVehicleTimeline
  }
};

export const CatalogDriverContainer: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    const { refData } = useSelector(driverTableOptionsSelector);
    const { actionTableButtons, handleSelectedDrivers } = useDriverInviteWithVerification();
    return (
      <ILSCatalogTable
        {...props}
        handleCustomCheckboxOnChange={handleSelectedDrivers}
        recordTableButtons={props.recordTableButtons.concat(actionTableButtons)}
        dictionary={Dictionary.Driver}
        dataDecorator={driver2table}
        handleDecorator={table2driver}
        refTable={refTable}
        refData={refData}
      />
    );
  },
  DRIVER_TABLE_CONFIG,
  CatalogDictionary.Driver,
  true
);
