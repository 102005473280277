import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { documentsGetTemplatesRoutine } from '../actions';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

const documentsTemplate = createReducer(initialState, {
  //GET
  [documentsGetTemplatesRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [documentsGetTemplatesRoutine.SUCCESS]: (
    state,
    action: {
      type: string;
      payload: {
        data: DocumentTemplate[];
      };
    }
  ) => {
    const { data } = action.payload;
    state.templates = data;
    return state;
  },
  [documentsGetTemplatesRoutine.FAILURE]: (
    state,
    action: {
      type: string;
      payload: {
        error: string[];
      };
    }
  ) => {
    state.error = action.payload.error;
    return state;
  },
  [documentsGetTemplatesRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});

export default documentsTemplate;
