import { FC } from 'react';
import { ILSTypographyText } from '@common/components';

interface IProps {
  role: string;
  className: string;
  underline?: boolean;
}

export const ILSTenderRole: FC<IProps> = ({ role, className, underline }) => {
  return (
    <li>
      <ILSTypographyText className={className} underline={underline}>
        {role}
      </ILSTypographyText>
    </li>
  );
};
