import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, Driver, Notify, NotifyDurationInSecond } from '@common/types';
import { catalogHandleMobileApplicationActionRoutine, catalogReadRoutine } from '@modules/catalog/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DriverTableButtons } from '@modules/catalog/constants';
import { MobileApplicationAPI } from '@common/api/common/mobile-application';
import { ILSUserNotify } from '@common/components';

function* catalogMobileApplicationWorker(action: PayloadAction<{ operation: DriverTableButtons; driverIDs: Array<Driver['ID']> }>) {
  const { operation, driverIDs } = action.payload;
  const { success, failure, fulfill } = catalogHandleMobileApplicationActionRoutine;
  const dictionary = Dictionary.Driver;
  try {
    const {
      data: { data, smsWarning }
    } = yield call(MobileApplicationAPI.handleMobileApplicationActionWithDriver, { operation, driverIDs });
    //TODO  исправить .join(`\n`) после рефакторинга ILSUserNotify и ErrorContentComponent
    if (smsWarning) {
      ILSUserNotify(Notify.Error, smsWarning.join(`\n`));
    }
    if (data) {
      ILSUserNotify(Notify.Success, data, NotifyDurationInSecond.Infinity);
    }
    yield put(success({ dictionary, message: data }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(catalogReadRoutine.trigger({ dictionary, withoutNotification: true }));
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogMobileApplicationWatcher() {
  yield takeLatest(catalogHandleMobileApplicationActionRoutine.trigger, catalogMobileApplicationWorker);
}
