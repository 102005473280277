import { FC } from 'react';
import { TenderPlatformRouteListComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/route/route-list';
import { useSelector } from 'react-redux';
import { currentTenderRouteListSelector } from '@modules/tender-platform/selectors/current-tender';

interface IProps {}

export const TenderPlatformRouteListContainer: FC<IProps> = () => {
  const route = useSelector(currentTenderRouteListSelector);
  return (
    <>
      Подробнее
      <TenderPlatformRouteListComponent route={route} />
    </>
  );
};
