import { DocumentsNavigationItem } from '@modules/documents/types/documents-navigation-item';
import { ModulePath, ModuleTitle } from '@common/types';

export const navigationItems: DocumentsNavigationItem[] = [
  { title: ModuleTitle.DocumentsAll, path: ModulePath.DocumentsAll },
  { title: ModuleTitle.DocumentsInbox, path: ModulePath.DocumentsInbox },
  { title: ModuleTitle.DocumentsSent, path: ModulePath.DocumentsSent },
  { title: ModuleTitle.DocumentsTemplates, path: ModulePath.DocumentsTemplates },
  { title: ModuleTitle.DocumentsArchive, path: ModulePath.DocumentsArchive },
  { title: ModuleTitle.DocumentsTrash, path: ModulePath.DocumentsTrash }
];
