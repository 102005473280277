import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { dataClearRoutine } from '@modules/planning/children/data/actions';
import { onFailure } from '@common/utils/helpers/reducers';

export const dataClearReducer = createReducer(initialState, {
  [dataClearRoutine.SUCCESS]: (state) => {
    state.data = [];
    state.files = null;
    state.conflict = { hasConflict: false, type: null };
    return state;
  },
  [dataClearRoutine.FAILURE]: onFailure
});
