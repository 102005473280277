import { ILSButtonTooltip } from '@common/components';
import { ColumnTable, RecordTableButton, TableRecord } from '@common/types';
import { createExelData, MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { ReactSVG } from 'react-svg';

// Кнопка для экспорта в excel
export const addToTableExelButton = (
  recordTableButtons: Array<RecordTableButton> | undefined,
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void,
  decoratedData: any,
  columns: Pick<ColumnTable<TableRecord>, 'title' | 'type' | 'dataIndex'>[],
  tableName: string
) => {
  const exelButton = [
    {
      Component: ILSButtonTooltip,
      props: {
        placement: 'right',
        icon: <ReactSVG src="/assets/icons/excel.svg" className="excel-icon" />,
        title: `Экспорт данных в Excel формате`,
        onClick: () => loadExel(createExelData(decoratedData, columns), tableName)
      }
    }
  ];

  if (recordTableButtons) {
    return recordTableButtons?.concat(exelButton);
  } else {
    return exelButton;
  }
};
