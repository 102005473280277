import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { addCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { Carriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const addCarrierReducer = createReducer(initialState, {
  [addCarriersRoutine.REQUEST]: onRequest,
  [addCarriersRoutine.FAILURE]: onFailure,
  [addCarriersRoutine.FULFILL]: onFulfill,
  [addCarriersRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      carriers: Carriers;
    }>
  ) => {
    const { carriers } = action.payload;
    state.carriers.myCarriers = { ...state.carriers.myCarriers, ...carriers };
    return state;
  }
});
