import reduceReducers from 'reduce-reducers';
import { initialState } from '@modules/documents/store';
import documentIndex from './document-index';
import documentsTemplate from '@modules/documents/reducers/document-template';
import { documentsDependentList } from '@modules/documents/reducers/dependent-list';
import { documentsTemplateCreate } from '@modules/documents/reducers/document-template-create';
import { documentUpdate } from '@modules/documents/reducers/document-update';
import { documentDeleteFromTrash } from './document-delete-from-trash';
import { currentDocument } from '@modules/documents/reducers/current-document';
import { documentsTemplateCreateDeal } from '@modules/documents/reducers/document-template-deal-create';
import { getClearTrashMode } from './get-clearTrashMode';
import { setClearTrashMode } from './set-clearTrashMode';
import { suggestContractorMode } from './suggest-contractor';
import { deleteReportReducer, getReportsListReducer, saveReportReducer } from './reports';
import documentMetrics from './document-metrics';
import inboxSendDocuments from '@modules/documents/reducers/inbox-send-documents';

export const documentsRootReducer = reduceReducers(
  initialState,
  documentIndex,
  documentsTemplate,
  documentsDependentList,
  documentsTemplateCreate,
  documentUpdate,
  documentDeleteFromTrash,
  currentDocument,
  getClearTrashMode,
  setClearTrashMode,
  documentsTemplateCreateDeal,
  suggestContractorMode,
  documentMetrics,
  inboxSendDocuments,
  saveReportReducer,
  deleteReportReducer,
  getReportsListReducer
);
