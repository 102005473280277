import { WidgetType } from '@common/types/dictionaries/analytics';
import { FC } from 'react';
import { BarChartProps } from '../types/components/charts';
import ILSAnalyticsBarChartComponent from './components/bar-chart';
import ILSAnalyticsClusteredChartComponent from './components/clustered-chart';

const ILSAnalyticsBarChartContainer: FC<BarChartProps> = (props) => {
  if (props.type === WidgetType.barGraphHorizontal || props.type === WidgetType.barGraphVertical) {
    return <ILSAnalyticsBarChartComponent {...props} />;
  } else {
    return <ILSAnalyticsClusteredChartComponent {...props} />;
  }
};

export default ILSAnalyticsBarChartContainer;
