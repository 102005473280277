import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/children/upload/store';
import { setFileNameAction } from '@modules/documents/children/upload/actions';
import { DocumentUploadStore } from '@modules/documents/children/upload/types/store';

export const setFileName = createReducer(initialState, {
  [setFileNameAction.type]: (state: DocumentUploadStore, action: PayloadAction<string>) => {
    state.fileName = action.payload;
    return state;
  }
});
