import { FC } from 'react';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import { handleCheckBeforeCrop } from '@common/components/data-entry/image-crop/helpers';
interface IProps extends ImgCropProps {
  fileMaxSize?: number;
}

export const ILSImgCrop: FC<IProps> = ({ fileMaxSize, ...props }) => {
  return <ImgCrop beforeCrop={handleCheckBeforeCrop(fileMaxSize)} {...props} />;
};
