import { apiInstance } from '@core/api/instance';
import { Dictionary } from '@common/types';

export const RoadGraphTest = {
  catalogRead: (params: { dictionary: Dictionary; keys: (string | number)[] }) => {
    const { dictionary, keys } = params;
    const api = apiInstance();

    return api.post('/NewTms/' + dictionary + '/index', {
      dictionary,
      keys
    });
  }
};
