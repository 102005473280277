import { createModuleAction } from '@common/utils/general/create-module-action';
import { AnalyticsActionsTypes, getCommonAnalyticsActions } from '@modules/analytics/common/actions';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

const MODULE_NAME = 'Analytics_Tender';

export type AnalyticsTenderActionsType = AnalyticsActionsTypes;

export const createAnalyticsRoutine = (name: AnalyticsTenderActionsType) => createRoutine(createModuleAction(MODULE_NAME, name));

export const analyticsTenderActions = getCommonAnalyticsActions(MODULE_NAME);

export const AnalyticsTenderRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  analyticsTenderActions.analyticsGetBoardWidgetRoutine,
  analyticsTenderActions.analyticsCommonGetBoardsRoutine
];
export const AnalyticsTenderRoutinesToNotifyOnlyErrors: UnifiedRoutine<(payload?: any) => Action>[] = [
  analyticsTenderActions.analyticsGetWidgetDataRoutine
];
