import { planningRestrictionFilterRoutine } from '@modules/planning/children/by-date/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Restriction } from '@common/types';

export type RestrictionFilterPayloadAction = PayloadAction<{
  ids: Array<Restriction['ID']>;
}>;

function* planningRestrictionFilterWorker(action: RestrictionFilterPayloadAction) {
  const { success, failure, fulfill } = planningRestrictionFilterRoutine;
  const { ids = [] } = action.payload;

  try {
    yield put(success({ ids }));
  } catch (error) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* planningRestrictionFilterWatcher() {
  yield takeLatest(planningRestrictionFilterRoutine.trigger, planningRestrictionFilterWorker);
}
