import { createSelector } from 'reselect';
import {
  activePlanBeforeExecutionSelector,
  activePlanFactExecutionStartedSelector,
  activePlanIDSelector
} from '@modules/planning/children/by-date/selectors/active-plan';
import { EXECUTION } from '@modules/planning/children/by-date/constants';
import { getConfirmExecutionText } from '@modules/planning/children/by-date/helpers/plan/execution';
import { Plan } from '@common/types';

export const getExecutionParameters = ({
  activePlanID,
  isPlanBeforeExecution,
  isPlanNotEditable
}: {
  activePlanID: Plan['ID'] | null;
  isPlanNotEditable: boolean;
  isPlanBeforeExecution: boolean;
}) => {
  const disabled = !activePlanID || isPlanNotEditable;
  const btnText = isPlanBeforeExecution ? EXECUTION.SET : EXECUTION.UNSET;
  const confirmText = getConfirmExecutionText({ isPlanBeforeExecution });
  return {
    btnText,
    confirmText,
    disabled
  };
};

export const plannerPlanningPlanTableForExecuteButtonSelector = createSelector(
  activePlanIDSelector,
  activePlanBeforeExecutionSelector,
  activePlanFactExecutionStartedSelector,
  (activePlanID, isPlanBeforeExecution, isPlanNotEditable) => {
    const { btnText, confirmText, disabled } = getExecutionParameters({ activePlanID, isPlanBeforeExecution, isPlanNotEditable });
    return {
      btnText,
      confirmText,
      disabled,
      isPlanBeforeExecution,
      activePlanID,
      isPlanNotEditable
    };
  }
);
