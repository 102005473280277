import { ILSCheckbox, ILSForm, ILSFormItem } from '@common/components';
import { ILSTypographyParagraph } from '@common/components/general/typography';
import { DELETE_TRIP_NOTIFY } from '@modules/orders-and-trips/constants/general';
import { DeleteTripFields, DeleteTripKeys } from '@modules/orders-and-trips/types/form';
import { FormInstance } from 'antd/es';

interface IProps {
  form: FormInstance<DeleteTripFields>;
}
const FORM_NAME = 'deleteTripForm';

export const DeleteTripForm = ({ form }: IProps) => {
  return (
    <>
      <ILSTypographyParagraph>{DELETE_TRIP_NOTIFY}</ILSTypographyParagraph>
      <ILSForm form={form} name={FORM_NAME}>
        <ILSFormItem name={DeleteTripKeys.DeleteWithOrders} valuePropName={'checked'} normalize={Boolean}>
          <ILSCheckbox label="Удалить вместе с заявками" defaultChecked={false} />
        </ILSFormItem>
      </ILSForm>
    </>
  );
};
