import { useAppSelector } from '@core/hooks';
import { FC, useContext } from 'react';
import { DetailsTabContainer } from '@modules/monitoring/children/event-details/containers/details/details-tab';
import {
  activeTabSelector,
  currentVehicleSensorSelector,
  tracksSelector,
  vehicleEventsSelector
} from '@modules/monitoring/children/event-details/selectors';
import { MonitoringDetailsContext } from './context';
import { detailsTabs } from '../configs/details-tabs';
import CustomScroll from 'react-customscroll';

/**
 * Контейнер по работе с таблицей с детальными данными
 *
 *  */
export const DetailsTableContainer: FC = ({}) => {
  const context = useContext(MonitoringDetailsContext);
  const activeTab = useAppSelector(activeTabSelector);
  const vehicleEvents = useAppSelector(vehicleEventsSelector);
  const vehicleSensor = useAppSelector(currentVehicleSensorSelector);
  const tracks = useAppSelector(tracksSelector);

  const eventProps = {
    vehicleEvents,
    vehicleSensor,
    tracks
  };
  return (
    <CustomScroll>
      <DetailsTabContainer
        tabs={detailsTabs}
        {...eventProps}
        activeTab={activeTab}
        waypointId={context?.waypointId}
        handleChange={context?.onChangeActiveTab}
      />
    </CustomScroll>
  );
};
