import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardModel, BoardDefaults } from '@common/types/dictionaries/analytics';

import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';

export const selectBoardCommonWatcher = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<AnalyticsBoardModel & BoardDefaults>) {
    const { success, failure, fulfill } = actions.analyticsCommonSelectBoardRoutine;
    try {
      yield put(success(action.payload));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonSelectBoardRoutine.TRIGGER, worker);
  }

  return watcher;
};
