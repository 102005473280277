import { PlanID, PlanTotal, Project, Provider } from '@common/types';
import { PlanningAPI } from '@modules/planning/api';
import { planningChangeValidationStatusRoutine, planningValidateRoutine } from '@modules/planning/children/by-date/actions';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { planningParamsConfigSelector } from '@modules/planning/children/by-date/selectors/config';
import { TPlanningConfig } from '@modules/planning/children/by-date/types';
import { PlanningAndValidationRequestConfig, ValidationStatus } from '@modules/planning/children/by-date/types/validate';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* planningValidateWorker(
  action: PayloadAction<{
    planID?: PlanID;
    update?: boolean;
  }>
) {
  const { request, success, failure, fulfill } = planningValidateRoutine;
  const { update, planID } = action.payload;

  try {
    yield put(request({ update }));
    const activePlanID: PlanID = yield select(activePlanIDSelector);
    const projectID: Project['ID'] = yield select(activeProjectIdSelector);

    //TODO to selector
    const { PlanConfig, TypePlanning }: PlanTotal = yield select((state) => state.PlanningByDate.project?.Plan?.[planID || activePlanID]?.PlanTotal);
    const [provider, planConfigID] = Object.values(PlanConfig);

    const params: Record<Provider, TPlanningConfig['params']> = yield select(planningParamsConfigSelector);

    const requestPayload: PlanningAndValidationRequestConfig = {
      planID: planID ?? activePlanID,
      planConfigID,
      operation: TypePlanning,
      validationType: TypePlanning,
      providerName: provider as Provider,
      params: params?.[provider],
      projectID
    };

    const {
      data: { data }
    } = yield call(PlanningAPI.validate, requestPayload);

    yield put(success({ validationData: data, ...requestPayload }));

    if (!update) {
      yield put(planningChangeValidationStatusRoutine.success({ status: ValidationStatus.Success }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningValidateWatcher() {
  yield takeLatest(planningValidateRoutine.trigger, planningValidateWorker);
}
