import { useSelector } from 'react-redux';
import { catalogFilterByProjectSelector } from '@modules/catalog/selectors/components/catalog-filter-project';
import { catalogReadRoutine } from '@modules/catalog/actions';
import { Dictionary } from '@common/types';
import { Dispatch } from 'redux';
import { useState } from 'react';
import { catalogDictionaryFiltersSelector, dictionaryLastUpdateSelector } from '@modules/catalog/selectors';
import { CatalogState } from '@modules/catalog/types/store';
import { useUncheckCatalogProjectButton } from '@modules/catalog/hooks/use-uncheck-catalog-project-button';

export const useCatalogFilterProjectButton = ({ dictionary, dispatch }: { dictionary: Dictionary; dispatch: Dispatch }) => {
  //TODO mapStateToProps
  const { defaultChecked } = useSelector(catalogFilterByProjectSelector);
  const lastUpdate = useSelector((state: CatalogState) => dictionaryLastUpdateSelector(state, dictionary));
  const { projectID } = useSelector((state: CatalogState) => catalogDictionaryFiltersSelector(state, dictionary));
  const [checked, setChecked] = useState<boolean>(!lastUpdate ? Boolean(defaultChecked) : Boolean(projectID));

  useUncheckCatalogProjectButton({ lastUpdate, projectID, setChecked });

  const handleClick = (byProject: boolean) => {
    setChecked(byProject);
    dispatch(
      catalogReadRoutine({
        dictionary,
        dictionaryLoad: true,
        projectID: byProject ? defaultChecked : false
      })
    );
  };

  return { handleClick, checked, defaultChecked };
};
