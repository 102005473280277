import { Zone } from '@common/types';
import { isEmpty } from 'lodash';

export const createZoneEnums = (zones?: Array<Zone>) => {
  if (isEmpty(zones)) return {};

  return zones?.reduce((zoneEnums, zone) => {
    zoneEnums[zone.ID] = zone.Name || `Зона №${zone.ID}`;
    return zoneEnums;
  }, {});
};
