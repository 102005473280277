import { FC } from 'react';
import { ILSTypographyText } from '@components/general/typography';
import { ILSBadge } from '@common/components';
import { Dictionary, DictionaryTitle } from '@common/types/dictionaries/dictionaries';
import { useSelector } from 'react-redux';
import { uploadedDataCountSelector } from '@modules/planning/children/data/selectors/data';

interface IProps {
  dictionary: Dictionary;
}

const MAX_DATA_COUNT = 9999;

export const PlanningDataCounter: FC<IProps> = ({ dictionary }) => {
  const counter = useSelector(uploadedDataCountSelector);

  return (
    <ILSBadge overflowCount={MAX_DATA_COUNT} className={'planning-data-view-badge'} count={counter[dictionary]}>
      <ILSTypographyText>{DictionaryTitle[dictionary]}</ILSTypographyText>
      {!Boolean(counter[dictionary]) && <ILSTypographyText className={'planning-data-view-no-data'} children={': Не загружены'} />}
    </ILSBadge>
  );
};
