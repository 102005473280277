import { ILSSpin } from '@common/components';
import { usePaymentBalanceHistory } from '@common/feature/payment/hooks/use-payment-balance-history-container';
import { PaymentBalanceHistory } from '@common/feature/payment/components/payment-balance-history/payment-balance-history';

export const PaymentBalanceHistoryContainer = () => {
  const { balanceHistory, isFetching } = usePaymentBalanceHistory();

  return (
    <ILSSpin tip={'Операции загружаются, пожалуйста подождите...'} spinning={isFetching}>
      <PaymentBalanceHistory balanceHistory={balanceHistory} />
    </ILSSpin>
  );
};

