export enum AccountAttachmentCategory {
  Facsimile = 'facsimile'
}

export enum AccountAttachmentsDependent {
  Account = 'account'
}

export type AccountFileAttachment = {
  extension: string;
  id: number;
  link: string;
  name: string;
  type: string;
};

