import { FC } from 'react';

interface Props {
  text: string;
  onClick?: () => void;
}

const SelectItems: FC<Props> = ({ onClick, text }) => {
  return (
    <span onClick={onClick} className={'excel-export-select-items'}>
      {text}
    </span>
  );
};

export default SelectItems;

