import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticsCommonAPI } from '../types/api';
import { GetWidgetDataRequest } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';
import { getWidgetDataErrorText } from '@modules/analytics/common/utils/widget/get-error-text';
import { isEmpty } from 'lodash';

export const analyticsGetWidgetDataRoutineFactory = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<GetWidgetDataRequest>) {
    const { payload } = action;
    const { request, success, failure, fulfill } = actions.analyticsGetWidgetDataRoutine;
    try {
      yield put(request({ ID: payload.ID }));

      const { data } = yield call(api.getWidgetData, payload);
      //проверка data - не может быть массивом или пустым объектом
      if (Array.isArray(data.data) || isEmpty(data)) {
        yield put(failure({ ID: payload.ID, error: getWidgetDataErrorText(payload.ID), errorNotifyKey: 'get-widget-data-error-key' }));
      } else {
        yield put(success({ data: data.data, widgetID: payload.ID }));
      }
    } catch (error) {
      yield put(failure({ ID: payload.ID, error, errorNotifyKey: 'get-widget-data-error-key' }));
    } finally {
      yield put(fulfill({ ID: payload.ID }));
    }
  }

  return function* watcher() {
    yield takeEvery(actions.analyticsGetWidgetDataRoutine.trigger, worker);
  };
};
