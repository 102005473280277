import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningDoneRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanningStatus } from '@modules/planning/types';
import { isEmpty } from 'lodash';
import { PlanningProcessStatus, PlanningProgressStatus } from '@modules/planning/children/by-date/constants';
import { IndexedArray, PlanTotal } from '@common/types';

export const donePlanningReducer = createReducer(initialState, {
  [planningDoneRoutine.SUCCESS]: (state, action: PayloadAction<{ planID: string | number, planTotal: IndexedArray<PlanTotal> }>) => {
    const { planID, planTotal } = action.payload;
    if (!isEmpty(state.planningCheckData)) {
      state.planningCheckData[planID].Status = PlanningProcessStatus.IsDone;
      state.planningCheckData[planID].Progress = PlanningProgressStatus.CompletePlanning;
    }
    for (const planID in planTotal) {
      if (state.project?.Plan) {
        state.project.Plan[planID] = { ...state.project.Plan[planID], PlanTotal: planTotal[planID] };
      }
    }
    state.status = PlanningStatus.Result;
    return state;
  }
});
