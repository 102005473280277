import { DefaultPlannerConfigsResponse } from '@modules/planning/types';
import { Provider } from '@common/types';

export const getConfiguration = ({ defaultConfig, localConf }: { defaultConfig?: DefaultPlannerConfigsResponse; localConf: any }) => {
  return defaultConfig
    ? Object.keys(defaultConfig ?? {}).reduce(
        (result, provider) => {
          const providerConfigAllParams = defaultConfig[provider]?.params;
          const defaultProviderParams = Object.keys(providerConfigAllParams).reduce((summary, property) => {
            summary[property] = providerConfigAllParams[property]?.default;
            return summary;
          }, {} as Record<string, any>);
          result[provider] = { ...defaultProviderParams, ...localConf[provider] };
          return result;
        },
        {} as {
          [key in Provider]: Record<string, any>;
        }
      )
    : null;
};
