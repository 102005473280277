import { Dictionary, Key, ResponseAPI, Trip, Waypoint } from '@common/types';
import { PlanID } from '@common/types/dictionaries/plan';
import { PlanningAPI } from '@modules/planning/api';
import { moveWaypointInTripRoutine } from '@modules/planning/children/by-date/actions';
import { RouteTotalResponse } from '@modules/planning/children/by-date/sagas/trip/move-waypoint-to-trip';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { TripRequest, sourceWaypointIDsRequest, targetWaypointIDRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MoveWaypointInTripPayloadSucces } from '../../reducers/trip/move-waypoint-in-trip';

export type MoveWaypointInTripResponse = {
  data: ResponseAPI<{
    Trip: Trip;
    error: Record<Key, string[]>;
  }>;
};
export type MoveWaypointInTripPayload = {
  tripID: number;
  /** Точки, которые будут пытаться оказаться после или перед targetWaypointID */
  sourceWaypointIDs: Waypoint['ID'][];
  /** Точка после которой будут точки */
  targetWaypointID: Waypoint['ID'];
};

function* moveWaypointInTripWorker(action: PayloadAction<MoveWaypointInTripPayload>) {
  const { request, success, failure, fulfill } = moveWaypointInTripRoutine;
  const { tripID, sourceWaypointIDs, targetWaypointID } = action.payload;

  try {
    yield put(request());

    const payload: sourceWaypointIDsRequest & targetWaypointIDRequest & TripRequest = {
      sourceWaypointIDs,
      targetWaypointID,
      tripID
    };

    const {
      data: {
        data: { Trip, error }
      }
    }: MoveWaypointInTripResponse = yield call(PlanningAPI.moveWaypointInTrip, payload);

    const planID: PlanID = yield select(activePlanIDSelector);

    if (!isEmpty(error)) {
      const errors = Object.keys(error).map((id: string) => `Точка №${id}: ${error[id]}`);
      yield put(failure({ error: errors }));
    }

    const {
      data: { data: routeTotal }
    }: RouteTotalResponse = yield call(PlanningAPI.getTotal, {
      planID,
      vehicleID: Trip.VehicleID,
      dictionary: Dictionary.Route
    });

    yield put(success({ routeTotal, tripID: Trip.ID, waypointIds: sourceWaypointIDs } as MoveWaypointInTripPayloadSucces));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* moveWaypointInTripWatcher() {
  yield takeLatest(moveWaypointInTripRoutine.trigger, moveWaypointInTripWorker);
}

