import { useCallback } from 'react';
import { TableProps } from 'antd/lib/table/Table';
import { UseTableSummary } from '@common/types/components/table/hooks';
import { isEmpty } from 'lodash';
import { TableRecord } from '@common/types';

export const useTableSummary = <R extends TableRecord>({ customSummary }: UseTableSummary<R>) => {
  return useCallback(
    (dataSource: TableProps<R>['dataSource']) => {
      if (isEmpty(dataSource)) return null;
      if (customSummary) {
        return customSummary;
      }
      return null;
    },
    [customSummary]
  );
};
