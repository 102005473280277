import { PointType, PointTypeApi } from '@common/utils/get-track/types';
import { RouteTrack } from '@common/types/dictionaries/route';
import { isEmpty, isNil } from 'lodash';

/**
 * Для Преобразования из полученных точек в планах в данные для получения треков
 */
export const plansPointsToGetTrack = (
  planData: {
    [pointKey: string]: PointType;
  },
  init?: { [K: string]: PointTypeApi }
): { [K: string]: PointTypeApi } => {
  const result: { [K: string]: PointTypeApi } = init ? { ...init } : {};
  if (isEmpty(planData)) return result;
  
  for (const key in planData) {
    const item = planData[key];
    if (!result?.[key]) {
      result[key] = {
        From: {
          Latitude: item.from.latitude,
          Longitude: item.from.longitude
        },
        To: {
          Latitude: item.to.latitude,
          Longitude: item.to.longitude
        },
        ForbiddenZones: item.forbiddenZones ? String(item.forbiddenZones) : ''
      };
      if (item.ProviderName) {
        result[key].ProviderName = item.ProviderName;
      }
      if (!isEmpty(item.Details)) {
        result[key].Details = item.Details;
      }
    }
  }

  return result;
};

//Для очистки уже полученных данных перед отправкой запроса
export const filterPointsToGetTrack = (PointWithData: RouteTrack) => {
  const result: RouteTrack = {};
  Object.keys(PointWithData).forEach((key, index) => {
    if (PointWithData[key] && isNil(PointWithData[key].track)) {
      if (PointWithData[key].Key && typeof PointWithData[key].Key === 'string') {
        result[index] = PointWithData[key].Key as string;
      } else {
        result[index] = PointWithData[key] as PointTypeApi;
      }
    }
  });

  return result;
};

