import { Modal } from 'antd';

export const confirmPromise = () =>
  new Promise<boolean>((resolve) => {
    Modal.confirm({
      icon: null,
      title: null,
      content: 'Документ успешно сформирован',
      cancelButtonProps: { type: 'primary' },
      cancelText: 'Ок',
      onCancel: () => resolve(false),
      okText: 'Перейти',
      onOk: () => resolve(true)
    });
  });
