import { ILSSelect } from '@components/index';
import { compareAsString } from '@common/utils';
import { IILSCellSelectIconAPIProps } from '@common/types/components/table/cell';
import { IconOptions, TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isString } from 'lodash';

//TODO unify logic
export const ILSCellSelectIconAPI = <R extends TableRecord>({
  children,
  options,
  editable,
  save,
  toggleEdit
}: IILSCellSelectIconAPIProps<R>): JSX.Element => {
  const [node, iconValue] = children;

  if (!editable) {
    if (!isString(iconValue)) {
      showUserAndDevError({ error: 'Иконка должна быть строкой' });
      return <></>;
    }
    const src = options?.find(({ value }) => compareAsString(iconValue, value))?.label;
    return <img className="svg-container" alt="icon" src={src} />;
  }

  const variants = (options as IconOptions).reduce((prev, { value, label }) => {
    prev.push({
      value,
      label: <img className="svg-container" alt="icon" src={label as string} />
    });

    return prev;
  }, [] as IconOptions);

  const onChange = (val: string) => save(val);

  return (
    <>
      {node}
      <ILSSelect
        autoFocus
        open
        dropdownMatchSelectWidth={false}
        showArrow={false}
        className={'icon-only'}
        onChange={onChange}
        value={iconValue}
        options={variants}
        onBlur={toggleEdit}
      />
    </>
  );
};
