import { FakePreCostProposal, FakeTripContractValues, ProposalValues } from '@modules/orders-and-trips/types/fake-pre-cost';
import { useProposalForm } from '@modules/orders-and-trips/hooks/use-proposal-form';
import { useFakeContractModal } from '@modules/orders-and-trips/hooks/use-fake-contract-modal';
import { convertMomentInString } from '@modules/orders-and-trips/helpers/form/date/convert-moment-in-string';

export const useContractToCreateFromFakeTender = () => {
  const { showForm, handleFormVisibility, buttonChildren, buttonType } = useProposalForm();
  const { handleOpenConfirmModal, confirmModalVisible, submitButtonProps, handleShowConfirmModal } = useFakeContractModal({
    handleFormVisibility
  });

  const onFinish = (
    values: ProposalValues & { alias: FakePreCostProposal['name'] } & {
      extra: { id: FakePreCostProposal['id']; info: FakePreCostProposal['info']; price: FakePreCostProposal['price'] };
    }
  ) => {
    const payload: FakeTripContractValues = values;
    convertMomentInString(payload);
    handleOpenConfirmModal(payload);
  };

  return {
    confirmModalVisible,
    handleOpenConfirmModal,
    handleShowConfirmModal,
    submitButtonProps,
    buttonType,
    buttonChildren,
    handleFormVisibility,
    onFinish,
    showForm
  };
};
