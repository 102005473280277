import { FC } from 'react';
import { VALIDATION_KINDS } from '@modules/data-validate/constants';
import { ILSAlert, ILSSpinCenter } from '@common/components';

interface IProps {
  isFetching: boolean;
  validationData:
    | 0
    | {
        error: number;
        warning: number;
        info: number;
        bad: number;
      }
    | null
    | undefined;
}

export const PlannerPlanningValidationSummaryComponent: FC<IProps> = ({ isFetching, validationData }) => {
  return (
    <ul className="validation-summary-info">
      {VALIDATION_KINDS.map((item) => {
        return (
          <ILSAlert
            key={item}
            message={
              isFetching ? (
                <ILSSpinCenter size={'small'} />
              ) : (
                <li className="validation-summary-info-alert">{validationData?.[item] ?? '––'}</li>
              )
            }
            type={item}
            showIcon={!isFetching}
          />
        );
      })}
    </ul>
  );
};
