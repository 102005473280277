import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';
import { tenderInfoSelector } from '@modules/tender-platform/selectors/permissions';
import { TENDER_PLATFORM_PERMISSIONS, TENDER_PLATFORM_PERMISSIONS_FOR_EDITING } from '@modules/tender-platform/abac/permissions';
import { useSearchParams } from '@common/hooks/history/use-search-params';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { currentTenderMyOfferSelector, hasCurrentTenderAcceptedOfferSelector } from '@modules/tender-platform/selectors/current-tender';

export const useTenderPlatformPermissions = () => {
  const { userHasPermissions } = useAbac();
  const tenderID = useSearchParams(TenderAction.Tender);
  //NOTE: Добавлено условие, tenderInfoSelector - меняет свое значение с опозданием,
  // во вложенных компонентах успевает отработать useEffect до того, как childComponent получит правильный пропс
  const { statusAlias, tenderUserID } = useSelector(tenderInfoSelector);
  const hasCurrentTenderAcceptedOffer = useSelector(hasCurrentTenderAcceptedOfferSelector);
  const tenderOfferUserID = useSelector(currentTenderMyOfferSelector).UserID;
  const isTenderEditable =
    statusAlias || tenderID === TenderAction.Create
      ? userHasPermissions(TENDER_PLATFORM_PERMISSIONS_FOR_EDITING, { statusAlias, tenderUserID })
      : false;

  const canDeleteDocument = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_REMOVE_DOCUMENT, statusAlias);

  const canRate =
    userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_RATE_CARRIER, { statusAlias, tenderUserID }) && hasCurrentTenderAcceptedOffer;

  const canViewRatingModal = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_VIEW_RATE_MODAL, { statusAlias, tenderOfferUserID });

  return { isTenderEditable, canDeleteDocument, canRate, canViewRatingModal };
};
