import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { IndexedArray, TransportCompany } from '@common/types';

export const transportCompanies2table = (transportCompanies?: Array<TransportCompany> | IndexedArray<TransportCompany>) => {
  return checkAndCreateArrayFromObject(transportCompanies).map((transportCompany) => {
    return {
      ...transportCompany,
      key: transportCompany?.ID
    };
  });
};
