import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTenderPlatformPermissions } from '@modules/tender-platform/abac/hooks/permissions';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { ILSTenderCardMenu } from '@modules/tender-platform/containers/tender-card/menu/card-menu';
import { currentTenderSelector, tenderActionSelector } from '@modules/tender-platform/selectors';
import { EMPTY_STRING, NOT_ENTERED } from '@common/constants';
import { TenderPlatformMainTitleComponent } from '@modules/tender-platform/components/tender-card/cards-title';
import { TenderOwnTendersFromPlannerContainer } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/tenders-from-planner';
import { TenderPlatformTenderCardContainer } from '@modules/tender-platform/containers/tender-card/tender-card';
import { useTender, useTenderCardMenu, useTenderCreating } from '@modules/tender-platform/utils/hooks';
import { useDataFiles } from '@modules/tender-platform/utils/hooks/use-data-files';

export const ILSTenderCardsContainer: FC = () => {
  const dispatch = useDispatch();
  useTender(dispatch);
  const tenderAction = useSelector(tenderActionSelector);
  const currentTender = useSelector(currentTenderSelector);
  const { isTenderEditable } = useTenderPlatformPermissions();
  const { menu, setMenu } = useTenderCardMenu({ currentTender, isTenderEditable });
  const { isTendersCreatingFromPlanner } = useTenderCreating();
  const { dataFiles, onSetDataFiles } = useDataFiles();

  const cardsPageTitle =
    tenderAction === TenderAction.ID && Number(currentTender?.ID) && currentTender?.Name
      ? `${currentTender?.Name} №${currentTender?.ID}` ?? NOT_ENTERED
      : `Создание тендера ${isTendersCreatingFromPlanner ? `из Планировщика` : EMPTY_STRING}`.trim();

  return (
    <>
      <TenderPlatformMainTitleComponent cardsPageTitle={cardsPageTitle} />
      <ILSTenderCardMenu isTendersCreatingFromPlanner={isTendersCreatingFromPlanner} dataFiles={dataFiles} menu={menu} setMenu={setMenu} />
      {isTendersCreatingFromPlanner ? (
        <TenderOwnTendersFromPlannerContainer dataFiles={dataFiles} onSetDataFiles={onSetDataFiles} />
      ) : (
        <TenderPlatformTenderCardContainer isTenderEditable={isTenderEditable} menu={menu} />
      )}
    </>
  );
};
