import './styles.less';
import { FC, ReactNode } from 'react';
import { ILSSwitch, ILSTooltip } from '@common/components';
import { IILSSVGProps, ILSSVG } from '@components/custom/icons/svg';
import { SwitchProps, TooltipProps } from 'antd';
import { isString } from 'lodash';

interface IProps extends SwitchProps {
  unCheckedIcon: IILSSVGProps['icon'] | ReactNode;
  checkedIcon?: IILSSVGProps['icon'] | ReactNode;
  tooltipTitle: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
}

export const ILSButtonSwitch: FC<IProps> = ({ unCheckedIcon, checkedIcon, tooltipTitle, tooltipPlacement = 'rightBottom', ...props }) => {
  const unCheckedChildren = isString(unCheckedIcon) ? <ILSSVG className={'ils-switch-icon'} icon={unCheckedIcon} /> : unCheckedIcon;
  const checkedChildren =
    isString(checkedIcon) || isString(unCheckedChildren) ? (
      <ILSSVG className={'ils-switch-icon'} icon={(checkedIcon ?? unCheckedChildren) as string} />
    ) : (
      checkedIcon || unCheckedChildren
    );
  return (
    <ILSTooltip placement={tooltipPlacement} title={tooltipTitle}>
      <ILSSwitch {...props} className={'ils-switch'} unCheckedChildren={unCheckedChildren} checkedChildren={checkedChildren} />
    </ILSTooltip>
  );
};
