import { COLUMNS_WITN_MIN_MAX_PROPERTY } from '@common/models/all/columns';
import { TableColumnName } from '@common/types/components/table/column';
import { convertServerTypeToInterfaceType } from '@common/utils/helpers/ils-custom/convert-server-type-to-interface-type';
import { SchemeInfo } from '@modules/planning/children/scheme-editor/types';
import { FilterCellType } from '@common/types/components/table/cell';

export const getTableColumnsForDataTableFromScheme = (scheme: Record<TableColumnName, SchemeInfo>) => {
  return (Object.entries(scheme) as unknown as Array<[TableColumnName, SchemeInfo]>).map(
    ([dataIndex, { title, type, description, isTime, required }]) => {
      const convertedSettings = convertServerTypeToInterfaceType({ type, isTime: Boolean(isTime) });

      return {
        key: dataIndex,
        dataIndex,
        ...convertedSettings,
        title,
        titleTooltip: description,
        editable: true,
        active: true,
        width: 150,
        ellipsis: true,
        filtering: convertedSettings.type?.includes('select') ? FilterCellType.Array : FilterCellType.String,
        required,
        sorting: true,
        min: COLUMNS_WITN_MIN_MAX_PROPERTY[dataIndex]?.min,
        max: COLUMNS_WITN_MIN_MAX_PROPERTY[dataIndex]?.max
      };
    }
  );
};
