import { useMemo } from 'react';
import {
  EXTRA_BLOCKS_PLANNING_TABLE_HEIGHT,
  PLAN_TABLE_CLOSE_HEIGHT,
  PLAN_TABLE_ROW_HEIGHT,
  PLAN_TABLE_SCROLL_WIDTH
} from '@modules/planning/children/by-date/constants';

export const useRoutesTableScroll = ({ planCounter, showPlans }: { planCounter: number; showPlans: boolean }) => {
  const scroll = useMemo(() => {
    const planTableOpenHeight = planCounter * PLAN_TABLE_ROW_HEIGHT + EXTRA_BLOCKS_PLANNING_TABLE_HEIGHT;
    const y = !showPlans ? `calc(100vh - ${PLAN_TABLE_CLOSE_HEIGHT}px` : `calc(100vh - ${planTableOpenHeight}px)`;
    return {
      x: PLAN_TABLE_SCROLL_WIDTH,
      y
    };
  }, [planCounter, showPlans]);

  return { scroll };
};
