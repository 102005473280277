import React, { FC, useEffect, useMemo, useState } from 'react';
import { WidgetParamComponentProps } from '../../types/components/params';
import { ILSSelect, ILSSelectOption } from '@common/components';
import css from 'classnames';
import { getWidgetParamName } from '@modules/analytics/common/utils/get-widget-param-name';

export const AnalyticsWidgetFilterParam: FC<WidgetParamComponentProps> = ({
  widget,
  widgetParam,
  handleSetParams = () => {},
  savedParams
}) => {
  const { Filter } = widget;

  const paramFilter = useMemo(() => Filter && Filter[widgetParam.Name], [widgetParam, widget]);
  const items = useMemo(() => (paramFilter ? Object.values(paramFilter.items) : []), [paramFilter]);
  const paramName = getWidgetParamName(paramFilter?.name || '', widget);
  const defaultValue = items.find((x) => x.data === savedParams[paramName]?.value)?.title;
  const [selectedValue, setSelectedValue] = useState(defaultValue || items[0]?.title || '');
  useEffect(() => {
    if (defaultValue) setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (selectedValue === '' && items.length > 0) setSelectedValue(items[0]?.title);
  }, [items]);

  const handleChange = (value: string | number) => {
    if (!paramFilter) return;

    if (savedParams[paramName]?.value === value) return;

    const newValues = {
      [paramName]: {
        name: paramFilter.name,
        value
      }
    };
    const title = items.find((x) => x.data === value)?.title;
    setSelectedValue(title || '');
    handleSetParams(newValues);
  };
  const title = useMemo(() => {
    if (selectedValue) return `${widget.ShortName || widget.Name} "${selectedValue}"` || '';
    return widget.ShortName || widget.Name || '';
  }, [widget.ShortName, widget.Name, selectedValue]);

  return (
    <div key={widgetParam.Name} className={css('analytics-widget-filter')}>
      <label>{title}</label>
      <div>
        <ILSSelect onChange={handleChange} value={null} placeholder={paramFilter?.placeholder}>
          {items.map((item) => (
            <ILSSelectOption key={widgetParam.Name + item?.data} value={item?.data}>
              {item.title}
            </ILSSelectOption>
          ))}
        </ILSSelect>
      </div>
    </div>
  );
};
