import { createReducer } from '@reduxjs/toolkit';
import { TableColumnName } from '@common/types';
import { planSaveRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanSavePayloadAction } from '@modules/planning/children/by-date/sagas/plan/save';
import { compareAsString } from '@common/utils';

export const savePlanReducer = createReducer(initialState, {
  [planSaveRoutine.SUCCESS]: (state, action: PlanSavePayloadAction) => {
    const { key, value, planID } = action.payload;
    if (state.project?.Plan) {
      state.project.Plan[planID][key] = value;
      if (state.project.Plan[planID]?.PlanTotal) {
        state.project.Plan[planID].PlanTotal![key] = value;
      }
    }

    switch (key) {
      case TableColumnName.Status:
        if (compareAsString(planID, state.activePlanID)) {
          const routes = state.currentPlan.RouteTotal;
          for (const id in routes) {
            routes[id][key] = value;
            const trips = routes[id].TripTotal;
            for (const tripID in trips) {
              const trip = trips[tripID];
              trip[key] = value;
              const waypoints = trip.WaypointTotal;
              for (const waypointID in waypoints) {
                const waypoint = waypoints[waypointID];
                waypoint[key] = value;
                // TODO backed should return Status, not PlanStatus
                waypoint.PlanStatus = value;
                const orderAction = waypoint.OrderActionTotal;
                for (const orderActionID in orderAction) {
                  orderAction[orderActionID][key] = value;
                }
              }
            }
          }
        }
        break;
      case TableColumnName.Name:
        if (state.project?.Plan) {
          state.project.Plan[planID].Name = value as string;
          if (state.project.Plan[planID]?.PlanTotal?.FullName) {
            state.project.Plan[planID].PlanTotal.FullName = value as string;
          }
        }

        break;
      default:
        break;
    }

    return state;
  }
});
