export const EXECUTION = { SET: 'Принять план к исполнению', UNSET: 'Отозвать план с исполнения' };
export const initialContextMenuData = {
  coords: {
    x: 0,
    y: 0
  },
  maxHeight: 0,
  visible: false
};
export const CANCEL_PLANNING_TEXT = 'Отменить планирование';
export const NO_PROJECT_CHOSEN = 'Выберите дату для отображения данных';

export const TRANSLATE_NOTIFICATION_DICTIONARY = {
  DurationRouteOverMax: 'Превышение продолжительности маршрута:',
  UnresolvedOrders: 'Нераспределенных заявок',
  TripsOvermax: 'Превышение количества рейсов',
  TripsOverload: 'Превышение количества рейсов',
  OneWayWaypoints: 'Отношение самого большого плеча рейса к расстоянию от склада до самой удалённой точки:',
  Waiting: 'Ожидание начала работы точки:',
  WaitOver: 'Превышение допустимого ожидания начала работы точки:',
  VolumeOvermax: 'Превышение максимального объема:',
  VolumeOverload: 'Превышение объема груза:',
  ExclusiveOrderIgnored: 'Проигнорирован флаг условия доставки груза:',
  ArrivingAtLunch: 'Прибытие в точку в обед:',
  Lateness: 'Опоздание выезда из точки:',
  LateOver: 'Превышение допустимого опоздания:',
  DoubledOrders: 'Дублирующихся заявок:',
  WrongCrossdocking: 'Режим кроссдокинга соблюден неправильно:',
  PalletsOvermax: 'Количество паллет в рейсе:',
  MultiTripViolation: 'Конфигурация не допускает многорейсовые маршруты, рейсов в маршруте:',
  DistanceOverload: 'Превышение по расстоянию:',
  DurationOvermax: 'Продолжительность рейса превысила ограничение:',
  DurationOverload: 'Превышение продолжительности: ',
  OrdersOverload: 'Превышение количества заявок:',
  ClientsOverload: 'Превышение количества клиентов:',
  ClientsOvermax: 'Превышение количества клиентов для ТС:',
  MassOvermax: 'Масса груза превысила грузоподъемность:',
  MassOverload: 'Превышение массы груза:',
  PalletsOverload: 'Превышение количества паллет:',
  BoxesOverload: 'Превышение количества коробов:',
  BoxesOvermax: 'Количество коробов в рейсе превысило допустимое для ТС:',
  UnitLengthOverload: 'Превышение максимальной длины груза:',
  UnitWidthOverload: 'Превышение максимальной ширины груза:',
  UnitHeightOverload: 'Превышение максимальной высоты груза :',
  Incompatible: 'Грузы несовместимы для совместной перевозки:',
  CarWrongDate: 'Дата приезда в точку не соответствует указанной дате машины:',
  CargoWrongDate: 'Дата приезда в точку не соответствует указанным датам в грузе:',
  DistanceRouteOverload: 'Перегрузка маршрута расстояния:',
  DurationRouteOverload: 'Превышение продолжительности маршрута:',
  DurationRouteOvermax: 'Превышение продолжительности маршрута:',
  UseCarOptionIgnored: 'В плане используется машина с выключенной опцией возможности использования',
  PlaceInTripIgnored: 'Проигнорирован порядок развоза заказа:',
  DeniedLateIgnored: 'Проигнорировано свойство заявки о запрете на опоздание:',
  WrongRampsForCar: 'В точке ни одна рампа не подходит для машины:',
  NotAcceptableZone: 'Груз недопустим для перевозки этой машиной из-за разницы в зонах:',
  NotAcceptableRestrZone: 'Груз недопустим для перевозки этой машиной по запрещенным зонам машины:',
  NotAcceptableCategory: 'Груз недопустим для перевозки этой машиной из-за разницы специфик:',
  WrongCarTripTemplate: 'Нарушено правило шаблона рейса - другая машина:',
  UncompliteTripTemplate: 'Полному шаблону рейса не хватает точек в набор:',
  NoRampOnWaypointWithRamps: 'Без рампы:',
  RampOverbooking: 'Бронирование рампы:',
  RampWorktimeViolation: 'Нарушение рабочего времени рампы:',
  RampsIsNotAcceptable: 'Рампы неприемлемы:',
  RampDurationOverWorktime: 'Продолжительность рампы сверхурочное время:',
  WrongOrdersWorktimes: 'Некорректные заказы:',
  DriverTripsAtSameTime: 'Одинаковые поездки',
  LunchWaiting: 'Ожидание обеда',
  DistanceOvermax: 'Расстояние свыше макс.',
  OrdersOvermax: 'Заказы свыше макс.',
  TeleportFound: 'Первая точка рейса не совпадает с последней точкой предыдущего рейса:',
  Test: 'Тест'
};
