import { useEffect, useState } from 'react';
import { ILSModal, ILSSkeleton } from '@common/components';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import SuccessItem from '@modules/documents/components/success-item';
import {
  documentCreateDealRoutine,
  documentCreateRoutine,
  documentGetDependentListRoutine,
  documentsGetTemplatesRoutine,
  resetDependentList,
  resetError
} from '@modules/documents/actions';
import { getDocumentsDependentList, getDocumentsErrors, getDocumentsTemplates, getIsFetchingSelector } from '@modules/documents/selectors';
import {
  DocumentTemplateType,
  DocumentType,
  IDocumentsPayload,
  modalTemplateNotFound,
  modalTemplateTitle,
  modalTemplateType
} from '@modules/documents/types/document-type';
import { getDocumentSetsRoutine } from '@modules/documents/children/templates/actions';
import { documentTemplatesSelector } from '@modules/documents/children/templates/selectors';
import DependentListItem from '@modules/documents/components/dependent-list-modal/dependent-list-item';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

interface IDependentListItem extends DocumentTemplate {
  name?: string | undefined;
  value?: number | undefined;
  from?: string | undefined;
  route?: string | undefined;
  to?: string | undefined;
  winner?: string | undefined;
}

export type ModalListItem = Partial<IDependentListItem>;

interface IModalList {
  alias?: string;
  name: string;
  list: ModalListItem[];
}

interface Props {
  setActive: (type: DocumentType) => void;
  setIsModalVisible: any;
  isModalVisible: boolean;
  selectedMenu: string | null;
}

const DependentListModal = ({ setActive, setIsModalVisible, isModalVisible, selectedMenu }: Props) => {
  const isFetching = useAppSelector(getIsFetchingSelector);
  const { isFetching: isFetchingTemplate } = useAppSelector(documentTemplatesSelector);
  const dependentList = useAppSelector(getDocumentsDependentList);
  const templates = useAppSelector(getDocumentsTemplates);
  const { documentSets } = useAppSelector(documentTemplatesSelector);

  const error = useAppSelector(getDocumentsErrors);
  const dispatch = useAppDispatch();

  const [form, setForm] = useState<IDocumentsPayload | {}>({});
  const [list, setList] = useState<IModalList[]>([]);
  const [count, setCount] = useState(0);

  const handleCancel = () => {
    setIsModalVisible(false);
    setCount(0);
    setForm({});
    setList([]);
  };

  const handleClickSelect = (i: ModalListItem) => {
    if (count === 0) {
      if (selectedMenu === DocumentTemplateType.Deal) {
        setForm({ ...form, setId: i.ID });
        dispatch(documentGetDependentListRoutine({ setId: i.ID }));
      } else {
        setForm({ ...form, type: i.Type, template: i.ID });
        dispatch(documentGetDependentListRoutine({ type: i.Type }));
      }
    } else {
      setCount((prev) => prev + 1);
      setForm({ ...form, [list[count].alias as string]: i.value });
    }
  };

  const handleFollow = () => {
    handleCancel();
    if (selectedMenu === DocumentTemplateType.Deal) {
      setActive(DocumentType.Deal);
    }
    if ('type' in form) {
      setActive(form.type);
    }
  };

  useEffect(() => {
    if (!list[count] && Object.keys(form).length && !error.length) {
      if (selectedMenu === DocumentTemplateType.Deal) {
        dispatch(documentCreateDealRoutine({ form }));
      } else {
        dispatch(documentCreateRoutine({ form }));
      }
    }
  }, [count]);

  useEffect(() => {
    if (isModalVisible) {
      // getting dependent list after opening modal
      if (selectedMenu === DocumentTemplateType.Deal) {
        dispatch(getDocumentSetsRoutine({ noEmpty: 1 }));
      } else {
        selectedMenu && dispatch(documentsGetTemplatesRoutine({ type: modalTemplateType[selectedMenu] }));
      }
    } else {
      // resetting dependent list after closing modal
      dispatch(resetDependentList());
      dispatch(resetError());
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (Array.isArray(dependentList)) {
      setList((prevState) => [...prevState, ...dependentList]);
      setCount((prev) => prev + 1);
    } else if (dependentList?.list?.length) {
      setList((prevState) => [...prevState, dependentList]);
      setCount((prev) => prev + 1);
    }
  }, [dependentList]);

  useEffect(() => {
    if (selectedMenu) {
      const array =
        selectedMenu === DocumentTemplateType.Deal ? documentSets : [...templates.filter((i) => `template-${i.Type}` === selectedMenu)];

      setList([
        {
          name: modalTemplateTitle[selectedMenu],
          list: array
        }
      ]);
    }
  }, [selectedMenu, templates, documentSets]);

  return (
    <ILSModal onCancel={handleCancel} title={list[count]?.name} visible={isModalVisible} footer={false}>
      <ILSSkeleton loading={isFetching || isFetchingTemplate} active paragraph={{ rows: 13 }}>
        {list[count] ? (
          <div className={'template-modal-block'}>
            {list[count]?.list?.length ? (
              list[count].list.map((i) => <DependentListItem item={i} handleClickSelect={handleClickSelect} />)
            ) : (
              <>
                <span className={'not-found'}>{list[count]?.name} не найдены.</span>
                {selectedMenu && modalTemplateNotFound[selectedMenu] ? (
                  <span className={'not-found-message'}>{modalTemplateNotFound[selectedMenu]}</span>
                ) : null}
              </>
            )}
          </div>
        ) : (
          <SuccessItem error={error?.[0]} handleCancel={handleCancel} handleFollow={handleFollow} />
        )}
      </ILSSkeleton>
    </ILSModal>
  );
};
export default DependentListModal;
