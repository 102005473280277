import { ILSButton, ILSTooltip } from '@common/components';
import { ReactSVG } from 'react-svg';
import React, { FC, useContext } from 'react';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';

interface Props {}

const ILSRoadGraphTestReverseButton: FC<Props> = (props) => {
  const { handleReverse } = useContext(RoadGraphContext) ?? {};

  return (
    <ILSTooltip placement="right" title={'Поменять местами точки старта и финиша'}>
      <ILSButton className={'map-control-btn'} onClick={handleReverse} icon={<ReactSVG src={'/assets/icons/road-graph/reverse.svg'} />} />
    </ILSTooltip>
  );
};
export default ILSRoadGraphTestReverseButton;
