import initialState from '../store';
import reduceReducers from 'reduce-reducers';
import { indexTender } from '@modules/tender-platform/children/tender-own/reducers/tender/tender-index';
import { getLotTemplateTender } from '@modules/tender-platform/children/tender-own/reducers/lots/get-lot-template';
import { tenderReadRestrictionRead } from '@modules/tender-platform/children/tender-own/reducers/lots/read-restriction-catalog';
import { appointResponsibleTender } from '@modules/tender-platform/children/tender-own/reducers/tender/appoint-responsible-tender';
import { deleteCarrierReducer } from '@modules/tender-platform/children/tender-own/reducers/carriers/delete-carriers';
import { addCarrierReducer } from '@modules/tender-platform/children/tender-own/reducers/carriers/add-carriers';
import { getCarriersReducer } from '@modules/tender-platform/children/tender-own/reducers/carriers/get-carriers';
import { searchCarriers } from '@modules/tender-platform/children/tender-own/reducers/carriers/seacrh-carriers';
import { createTenderFromRouteReducer } from './tender/add-tender-from-route';
import { updateTenderForRouteReducer } from '@modules/tender-platform/children/tender-own/reducers/tender/update-tender-creation-data';
import { createTenderForRouteReducer } from '@modules/tender-platform/children/tender-own/reducers/tender/create-tender-for-route';
import { approveReplicateDocumentReducer } from './tender/replicate-documents';

export const tenderOwnRootReducer = reduceReducers(
  initialState,
  indexTender,
  getLotTemplateTender,
  tenderReadRestrictionRead,
  appointResponsibleTender,
  deleteCarrierReducer,
  addCarrierReducer,
  getCarriersReducer,
  searchCarriers,
  createTenderFromRouteReducer,
  updateTenderForRouteReducer,
  createTenderForRouteReducer,
  approveReplicateDocumentReducer
);
