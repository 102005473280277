import { setTablePageSize } from '@core/actions/table-custom';
import { DEFAULT_RECORDS_FROM_PAGE, FIRST_PAGE, MAX_RECORDS_FROM_PAGE } from '@components/data-display/table/constants';
import { useDispatch } from 'react-redux';
import { getPageSizeOptions } from '@components/data-display/table/utils/helpers/get-page-size_options';
import { isNil } from 'lodash';
import { UsePagination } from '@common/types/components/table/hooks';
import { TablePaginationConfig } from 'antd';
import { TableRecord } from '@common/types';

export const usePagination = <R extends TableRecord>({
  dataSource,
  pageSizeOptions,
  pageSize,
  active,
  onFocusRow,
  tableName,
  pagination
}: UsePagination<R>) => {
  const dispatch = useDispatch();

  const handlePageSize = (size: number, tableName: string) => {
    dispatch(setTablePageSize({ size, tableName }));
  };

  /** Записывает pageSize при изменении в store (по дефолту 10 рекордов на странице) */
  const onChangePageSize = (size: number, tableName: string) => {
    if (isNaN(size) && dataSource) {
      size = dataSource?.length;
      if (dataSource.length > MAX_RECORDS_FROM_PAGE) {
        size = dataSource?.length;
      }
    }
    handlePageSize(Number(size), tableName);
  };

  const defaultPagination: TablePaginationConfig = {
    current: active?.page || FIRST_PAGE,
    onChange: (page, rowsPerPage) => {
      const firstRowIndex = rowsPerPage * (page - 1);
      onFocusRow?.({ page, key: dataSource?.[firstRowIndex].key });
    },
    onShowSizeChange: (_, size) => {
      if (tableName) {
        onChangePageSize(size, tableName);
      }
    },
    ...{ pageSize },
    pageSizeOptions: pageSizeOptions ?? getPageSizeOptions(dataSource),
    showSizeChanger: dataSource && dataSource?.length > DEFAULT_RECORDS_FROM_PAGE,
    className: `ils-pagination`
  };

  /**  Если не передано pagination={false}, считаем пагинацию включенной (дефолтной) */
  const tablePaginationConfig: TablePaginationConfig | false | undefined = isNil(pagination) ? defaultPagination : pagination;

  return { tablePaginationConfig };
};
