import { Driver, Options, TableColumnName, TableRecord, User } from '@common/types';
import { DASH } from '@common/constants';
import { CurrentTender } from '@modules/tender-platform/types/store';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';

enum Status {
  exception = 'exception',
  success = 'success',
  normal = 'normal'
}

enum StatusText {
  success = 'Победитель',
  exception = 'Участник',
  normal = 'Выбрать победителем'
}

export interface TenderOfferRecord extends TableRecord {
  offerID: TenderOffer['ID'];
  ID: TenderOffer['ID'];
  UserID: User['ID'];
  INN: string;
  Name: string;
  MinCarrierRating: TenderOffer['CarrierRating'] | string;
  Vehicles: string;
  DriverName: string;
  MinDriverRating: TenderOffer['DriverRating'] | Driver['DriverRating'] | string;
  Route: Driver['TenderRoutes'] | string;
  Price: TenderOffer['Price'] | string;
  Accepted: Options;
}

export const tenderOffers2table = (tender: CurrentTender): TenderOfferRecord[] => {
  return (
    tender?.TenderOffer?.map((offer) => {
      const value = tender.TenderOffer?.some(({ Accepted }) => Accepted)
        ? offer.Accepted
          ? Status.success
          : Status.exception
        : Status.normal;
      const { Vehicle, Driver, ID, UserID, Account, CarrierRating, Price, DriverRating } = offer;
      return {
        offerID: ID,
        key: ID,
        [TableColumnName.ID]: ID,
        [TableColumnName.UserID]: UserID ?? DASH,
        [TableColumnName.INN]: Account?.Info?.INN ?? DASH,
        [TableColumnName.Name]: Account?.Name ?? DASH,
        [TableColumnName.MinCarrierRating]: CarrierRating ?? DASH,
        [TableColumnName.Vehicles]: Vehicle ? Vehicle.Name || `Автомобиль перевозчика №${Vehicle.ID}` : DASH,
        [TableColumnName.DriverName]: Driver ? Driver.Name || `Водитель перевозчика №${Driver.ID}` : DASH,
        [TableColumnName.MinDriverRating]: DriverRating ?? Driver?.DriverRating ?? DASH,
        [TableColumnName.Route]: Driver?.TenderRoutes ?? DASH,
        [TableColumnName.Price]: Price ?? DASH,
        [TableColumnName.Accepted]: [{ value, label: StatusText[value] }]
      };
    }) ?? []
  );
};
