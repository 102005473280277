import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { contact2table } from '@common/models/contact/decorators';
import { CONTACT_TABLE_CONFIG } from '@common/models/contact/config/table';

export const ILSCatalogContact: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return <ILSCatalogTable {...props} dataDecorator={contact2table} dictionary={Dictionary.Contact} />;
  },
  CONTACT_TABLE_CONFIG,
  CatalogDictionary.Contact,
  true
);
