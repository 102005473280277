import { useDispatch, useSelector } from 'react-redux';
import { showQuestionsSelector } from '@common/feature/questions/selectors';
import { showQuestionsRoutine } from '@common/feature/questions/actions';

export const useShowQuestions = () => {
  const dispatch = useDispatch();
  const showQuestions = useSelector(showQuestionsSelector);

  const handleCloseQuestionsContainer = () => {
    dispatch(showQuestionsRoutine.success({ value: false }));
  };

  return { showQuestions, handleCloseQuestionsContainer };
};

