import { Adding, TableRecord } from '@common/types';
import { GetTableTitleParameters } from '@components/data-display/table/components/render-components/render-table-title';
import { NEW_ROW } from '@components/data-display/table/constants';

export const handleCreateAndSelect =
  <R extends TableRecord>({ handleCreate, onFocusRow }: Pick<GetTableTitleParameters<R>, 'handleCreate' | 'onFocusRow'>) =>
  (addRow: Adding) => {
    const defaultRow = Object({ ...NEW_ROW, ...addRow });
    handleCreate?.(defaultRow);
    onFocusRow({ key: NEW_ROW.key });
  };
