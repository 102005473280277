import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderParticipantDeleteRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { ParticipantsAction } from '@modules/tender-platform/children/tender-own/types/participants';
import { DictionariesEndpoint, Dictionary } from '@common/types';

function* tenderParticipantDeleteWorker(action: ParticipantsAction) {
  const { request, failure, fulfill } = tenderParticipantDeleteRoutine;
  const { tenderID, ids } = action.payload;

  try {
    yield put(request());
    yield call(TenderOwn.deleteParticipant, { tenderID, ids });
    yield put(
      tenderChangeRoutine.trigger({
        ids,
        operation: DictionariesEndpoint.Delete,
        dictionary: Dictionary.TenderParticipants
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderParticipantDeleteWatcher() {
  yield takeEvery(tenderParticipantDeleteRoutine.trigger, tenderParticipantDeleteWorker);
}
