import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { getUploadTemplateRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const getUploadTemplateReducer = createReducer(
  initialState,
  {
    [getUploadTemplateRoutine.REQUEST]: onRequest,
    [getUploadTemplateRoutine.SUCCESS]: (state, action) => {
      const { data, addExample } = action.payload;
      state.templateFile = data;
      state.exampleFile = addExample;
      return state;
    },
    [getUploadTemplateRoutine.FAILURE]: onFailure,
    [getUploadTemplateRoutine.FULFILL]: onFulfill
  }
  // handleReducer<typeof initialState, { data: string; addExample: string }>(getUploadTemplateRoutine, {
  //   SUCCESS: (state, action) => {
  //     const { data, addExample } = action.payload;
  //     state.templateFile = data;
  //     state.exampleFile = addExample;
  //     return state;
  //   }
  // })
);
