import { createSelector } from 'reselect';
import { AnalyticsTenderMainStore } from '../types/store';

export const boardInfoSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.BoardInfo
);

export const boardsSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.Boards
);

export const boardSetsSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.BoardSets
);

export const widgetsSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => state.AnalyticsTender.Widgets
);

export const widgetsErrorSelector = createSelector(
  (state: { AnalyticsTender: AnalyticsTenderMainStore }) => state,
  (state) => Object.values(state.AnalyticsTender.Widgets).some((x) => x.error)
);
