import { useDispatch, useSelector } from 'react-redux';
import { resizerSelector } from '@modules/monitoring/children/event-details/selectors/resizer';
import { HandleResize, TableMapSizes } from '@modules/monitoring/children/event-details/types/resizer';
import { setTableMapSizesRoutine } from '@modules/monitoring/children/event-details/actions';

export const useResizer = () => {
  const dispatch = useDispatch();
  const { tablesMapSplitSizes, tablesSplitSizes } = useSelector(resizerSelector);

  const handleResize: HandleResize = (component) => (_, sizes) => {
    const payload: TableMapSizes = { [component]: sizes };
    dispatch(setTableMapSizesRoutine(payload));
  };

  return { tablesMapSplitSizes, tablesSplitSizes, handleResize };
};
