import { call, put, select, takeEvery } from 'redux-saga/effects';
import { tenderIndexRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { accountUsersSelector } from '@modules/tender-platform/selectors';
import { tenderGetAccountUsersRoutine } from '@modules/tender-platform/actions';
import { IndexedArray, User } from '@common/types';
import { isSelectorEmpty } from '@common/utils/helpers/store/is-selector-empty';

function* tenderIndexWorker() {
  const { request, success, failure, fulfill } = tenderIndexRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.index);

    const accountUsers: IndexedArray<User> = yield select(accountUsersSelector);
    if (isSelectorEmpty(accountUsers)) {
      yield put(tenderGetAccountUsersRoutine.trigger());
    }
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderIndexWatcher() {
  yield takeEvery(tenderIndexRoutine.trigger, tenderIndexWorker);
}
