import { IndexedArray } from '@common/types';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { deepEqual } from 'fast-equals';

//TODO: написать тест на функцию
export const hasChangedWidgets = (
  widgets: IndexedArray<AnalyticsWidget> | null,
  changedWidgets?: IndexedArray<AnalyticsWidget> | null
): boolean => {
  if (changedWidgets !== null && !deepEqual(widgets, changedWidgets)) return true;
  if (!widgets) return false;
  const widgetArray = Object.values(widgets);
  return widgetArray.some((x) => (x?.isNew && !x?.isDeleted) || (!x?.isNew && x?.isDeleted));
};
