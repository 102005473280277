import { TenderOutFastDeliveryServices } from '../types/integrations';

export const TRANSPORT_COMPANY_IMG_FOLDER = process.env.PUBLIC_URL + '/assets/images/transport-company/';
export const TRANSPORT_COMPANY_IMG = {
  [TenderOutFastDeliveryServices.Gruzovichkof]: TRANSPORT_COMPANY_IMG_FOLDER + 'gf.png',
  [TenderOutFastDeliveryServices.Sdek]: TRANSPORT_COMPANY_IMG_FOLDER + 'sdek.png',
  [TenderOutFastDeliveryServices.Pek]: TRANSPORT_COMPANY_IMG_FOLDER + 'pek.png',
  // [TenderOutFastDeliveryServices.Yandex]: TRANSPORT_COMPANY_IMG_FOLDER + 'yandex_delivery.png',
  [TenderOutFastDeliveryServices.YandexExpress]: TRANSPORT_COMPANY_IMG_FOLDER + 'yandex_express.png',
  [TenderOutFastDeliveryServices.Dostavista]: TRANSPORT_COMPANY_IMG_FOLDER + 'dostavista.png',
  [TenderOutFastDeliveryServices.Cse]: TRANSPORT_COMPANY_IMG_FOLDER + 'kse.png',
  [TenderOutFastDeliveryServices.BaikalService]: TRANSPORT_COMPANY_IMG_FOLDER + 'baikal.svg'
};

