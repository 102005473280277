import { CellType, DepotTableColumn, TableConfig } from '@common/types';
import { DEPOT_TYPE_OPTIONS, NEW_CLIENT } from '@common/models/depot/constants';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const depotTableConfig: TableConfig = {
  deleting: true,
  selecting: true,
  adding: NEW_CLIENT,
  columns: [
    {
      title: 'Код',
      dataIndex: DepotTableColumn.DepotCode,
      key: DepotTableColumn.DepotCode,
      editable: true,
      active: false,
      filtering: 'string',
      type: CellType.Input,
      sorting: 10,
      width: 150,
      ellipsis: true
    },
    {
      title: 'Наименование',
      dataIndex: DepotTableColumn.Name,
      key: DepotTableColumn.Name,
      editable: true,
      active: true,
      type: CellType.Input,
      width: 150,
      ellipsis: true,

      sorting: 5,
      filtering: 'string'
    },
    {
      title: 'Тип точки',
      dataIndex: DepotTableColumn.DepotType,
      key: DepotTableColumn.DepotType,
      active: true,

      width: 100,
      editable: true,
      sorting: 5,
      type: CellType.Select,
      fieldNames: DEFAULT_FIELD_NAMES,

      options: DEPOT_TYPE_OPTIONS,
      filtering: 'array'
    },
    {
      title: 'Дополнительное описание',
      dataIndex: DepotTableColumn.Description,
      key: DepotTableColumn.Description,
      active: false,
      sorting: 5,

      editable: true,
      ellipsis: true,
      width: 250,
      type: CellType.Input,
      filtering: 'string'
    },
    {
      title: 'Почтовый адрес',
      dataIndex: DepotTableColumn.AddressDescription,
      key: DepotTableColumn.AddressDescription,
      active: false,

      editable: true,
      sorting: 5,
      ellipsis: true,
      width: 250,
      type: CellType.Input,
      filtering: 'string'
    },
    {
      title: 'Контактная информация',
      dataIndex: DepotTableColumn.ClientContact,
      key: DepotTableColumn.ClientContact,
      active: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      width: 100,
      type: CellType.Input,
      filtering: 'string'
    },
    {
      title: 'Контакты',
      dataIndex: DepotTableColumn.Contact,
      key: DepotTableColumn.Contact,
      active: true,
      filtering: 'string',

      sorting: 5,
      editable: true,
      ellipsis: true,
      width: 180,
      type: CellType.Input
    },
    {
      title: 'Широта',
      dataIndex: DepotTableColumn.Latitude,
      key: DepotTableColumn.Latitude,
      active: false,
      filtering: 'string',
      required: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      width: 90,
      type: CellType.Float
    },
    {
      title: 'Долгота',
      dataIndex: DepotTableColumn.Longitude,
      key: DepotTableColumn.Longitude,
      active: false,
      filtering: 'string',
      required: false,

      sorting: 5,
      editable: true,
      ellipsis: true,
      width: 90,
      type: CellType.Float
    },
    {
      title: 'Задержка',
      dataIndex: DepotTableColumn.Delay,
      key: DepotTableColumn.Delay,
      active: true,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble,
      width: 80,
      sorting: 3
    },
    {
      title: 'Можно оставлять прицеп',
      dataIndex: DepotTableColumn.AllowKeepTrailer,
      key: DepotTableColumn.AllowKeepTrailer,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      type: CellType.Bool,
      width: 80,
      sorting: 10
    },
    {
      title: 'Начало погрузки',
      dataIndex: DepotTableColumn.WorktimeLoadStart,
      key: DepotTableColumn.WorktimeLoadStart,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 6
    },
    {
      title: 'Конец погрузки',
      dataIndex: DepotTableColumn.WorktimeLoadEnd,
      key: DepotTableColumn.WorktimeLoadEnd,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 5
    },
    {
      title: 'Начало разгрузки',
      dataIndex: DepotTableColumn.WorktimeUnloadStart,
      key: DepotTableColumn.WorktimeUnloadStart,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 4
    },
    {
      title: 'Конец разгрузки',
      dataIndex: DepotTableColumn.WorktimeUnloadEnd,
      key: DepotTableColumn.WorktimeUnloadEnd,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 3
    },
    {
      title: 'Начало обеда',
      dataIndex: DepotTableColumn.WorktimeLunchStart,
      key: DepotTableColumn.WorktimeLunchStart,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 2
    },
    {
      title: 'Конец обеда',
      dataIndex: DepotTableColumn.WorktimeLunchEnd,
      key: DepotTableColumn.WorktimeLunchEnd,
      editable: true,
      active: true,
      filtering: 'string',
      type: CellType.TimeDouble,
      width: 100,

      sorting: 1
    },
    {
      title: 'Специфики',
      dataIndex: DepotTableColumn.Restriction,
      key: DepotTableColumn.Restriction,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      width: 160,
      type: CellType.Input,

      sorting: 10
    },
    {
      title: 'Шаблоны рейсов',
      dataIndex: DepotTableColumn.TripTemplate,
      key: DepotTableColumn.TripTemplate,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      width: 160,
      type: CellType.Input,

      sorting: 10
    },
    {
      title: 'Расстояние до города',
      dataIndex: DepotTableColumn.DistanceFromCity,
      key: DepotTableColumn.DistanceFromCity,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      sorting: 5,
      type: CellType.Float,
      width: 100
    },
    {
      title: 'Радиус',
      dataIndex: DepotTableColumn.Radius,
      key: DepotTableColumn.Radius,
      active: false,
      filtering: 'string',

      editable: true,
      sorting: 5,
      ellipsis: true,
      type: CellType.Float,
      width: 80
    },
    {
      title: 'Стоимость посещения',
      dataIndex: DepotTableColumn.VisitPrice,
      key: DepotTableColumn.VisitPrice,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      type: CellType.Float,
      width: 100,
      sorting: 2
    },
    {
      title: 'Очередь погр/разг',
      dataIndex: DepotTableColumn.Queue,
      key: DepotTableColumn.Queue,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Значение 1',
      dataIndex: DepotTableColumn.Value1,
      key: DepotTableColumn.Value1,
      active: false,
      filtering: 'string',

      editable: true,
      ellipsis: true,
      sorting: 2,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Значение 2',
      dataIndex: DepotTableColumn.Value2,
      key: DepotTableColumn.Value2,
      active: false,
      filtering: 'string',
      sorting: 2,

      editable: true,
      ellipsis: true,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Значение 3',
      dataIndex: DepotTableColumn.Value3,
      key: DepotTableColumn.Value3,
      active: false,
      filtering: 'string',

      editable: true,
      sorting: 2,
      ellipsis: true,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    }
  ]
};
