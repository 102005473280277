import { int2color } from '@common/decorators/color';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { CrossDock, TableColumnName } from '@common/types';
import { isArray } from 'lodash';

export const crossDocks2table = (crossDocks: Array<CrossDock>) => {
  return checkAndCreateArrayFromObject(crossDocks).map((crossDock, i) => {
    return {
      ...crossDock,
      [TableColumnName.DepotID]: crossDock?.DepotID && [crossDock.DepotTypeID, crossDock?.DepotID],
      key: crossDock.ID ?? 'row' + i,
      Color: int2color(String(crossDock.Color))
    };
  });
};

export const table2crossDocks = (record: ReturnType<typeof crossDocks2table>[number], dataIndex: TableColumnName, value: any) => {
  switch (dataIndex) {
    case TableColumnName.DepotID:
      if (isArray(value)) {
        const onlyDepotID = value.length === 1;
        if (onlyDepotID) {
          const [depotId] = value;
          return depotId;
        } else {
          const [, depotId] = value;
          return depotId;
        }
      }
      return value;
    default:
      return value;
  }
};
