import { useAppDispatch, useAppSelector } from '@core/hooks';
import { useEffect, useMemo, useState } from 'react';
import ILSAnalyticsTenderComponent from '../components';
import { boardSetsSelector, boardsSelector, widgetsSelector } from '../selectors/board';
import { AnalyticsTenderContextType } from '../types/context';
import { AnalyticsBoardModel, AnalyticsBoardSetModel, BoardDefaults, GetWidgetDataRequest } from '@common/types/dictionaries/analytics';
import { AnalyticsTenderContext } from '../context';
import { analyticsTenderActions } from '../actions';
import { IndexedArray } from '@common/types';
import { useHistory } from 'react-router';
import { AnalyticsTenderQueryParams } from '../constants';
import { iterateParams } from '@common/utils/route/iterate-params';
// import ILSInfoModalTenderComponent from '../components/info-modal';
import { AnalyticsTenderModalWidget } from '../types/widgets';
import ILSInfoModalTenderComponent from '@modules/analytics/common/components/info-modal';

const ILSAnalyticsTenderContainer = () => {
  const dispatch = useAppDispatch();
  const boardSets = useAppSelector(boardSetsSelector);
  const boards = useAppSelector(boardsSelector);
  const widgets = useAppSelector(widgetsSelector);
  const account = useAppSelector((store) => store.Auth.user.Account);
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const queryBoardId = queryParams.get(AnalyticsTenderQueryParams.BOARD_ID);
  const queryBoardSetId = queryParams.get(AnalyticsTenderQueryParams.BOARD_SET_ID);
  const [reviewsModalActive, setReviewsModalActive] = useState(false);
  const [modalWidget, setModalWidget] = useState<AnalyticsTenderModalWidget[] | null>(null);

  useEffect(() => {
    const params = iterateParams(
      queryParams.entries(),
      Object.values(AnalyticsTenderQueryParams).map((x) => x.toLowerCase())
    );
    dispatch(
      analyticsTenderActions.analyticsCommonGetBoardSetsRoutine({
        role: account.AccountRole[0],
        defaults: { boardId: queryBoardId, boardSetId: queryBoardSetId, params }
      })
    );
  }, []);

  const showBoardSets = useMemo(() => {
    return Object.keys(boardSets.data).length > 1;
  }, [boardSets]);

  const handleSelectBoardSet = (item: AnalyticsBoardSetModel, defaults?: BoardDefaults) => {
    dispatch(analyticsTenderActions.analyticsCommonSelectBoardSetRoutine({ ...item, defaults: { ...defaults?.defaults } }));
  };

  const handleSelectBoard = (item: AnalyticsBoardModel, defaults?: BoardDefaults) => {
    dispatch(analyticsTenderActions.analyticsCommonSelectBoardRoutine({ ...item, defaults: { ...defaults?.defaults } }));
  };
  const boardTitle = useMemo(() => {
    return boards?.selectedBoard ? boards?.data[boards.selectedBoard]?.Name : null;
  }, [boards]);

  const handleSetParams = (params: IndexedArray<{ name: string; value: string | number | undefined }>) => {
    dispatch(analyticsTenderActions.analyticsCommonSetWidgetParamsRoutine(params));
  };

  const handleGetWidgetData = (args: { id: string | number; params: any }) => {
    const requestParams: GetWidgetDataRequest = { ID: args.id.toString(), ...args.params };
    dispatch(analyticsTenderActions.analyticsGetWidgetDataRoutine(requestParams));
  };

  const handleReviewsModalOpen = () => setReviewsModalActive(true);
  const handleReviewsModalClose = () => setReviewsModalActive(false);

  const handleSetModalWidget = (widget: AnalyticsTenderModalWidget[]) => setModalWidget(widget);

  const contextValue: AnalyticsTenderContextType = {
    handleSelectBoardSet,
    handleSelectBoard,
    handleSetParams,
    handleGetWidgetData,
    handleReviewsModalOpen,
    handleReviewsModalClose,
    reviewsModalActive,
    handleSetModalWidget,
    modalWidget
  };

  return (
    <AnalyticsTenderContext.Provider value={contextValue}>
      <ILSAnalyticsTenderComponent title={boardTitle} showBoardSets={showBoardSets} />
      <ILSInfoModalTenderComponent handleClose={handleReviewsModalClose} visible={reviewsModalActive} widgets={modalWidget} />
    </AnalyticsTenderContext.Provider>
  );
};

export default ILSAnalyticsTenderContainer;
