import { FC } from 'react';
import { TitleProps } from 'antd/lib/typography/Title';
import { Typography } from 'antd';
import classnames from 'classnames';

const { Title } = Typography;

export const ILSTypographyTitle: FC<TitleProps> = ({ className, ...props }) => {
  return <Title {...props} className={classnames(className, 'ils-title')} />;
};
