export const PRIMARY_VEHICLE_TYPES = ['Тент', 'Фургон изотерм', 'Рефрижератор'];

export const VEHICLE_PROFILE_TYPE_OPTIONS = [
  { value: 1, label: '1 т - Хендай Портер, ГАЗ Соболь' },
  { value: 1.5, label: '1.5 т - Газель и аналоги' },
  { value: 2, label: '2 т - Газель с удлиненным кузовом' },
  { value: 3, label: '3 т - Бычок и аналоги' },
  { value: 5, label: '5 т - Mercedes Altego и аналоги' },
  { value: 7, label: '7 т - Mercedes Altego, MAN TGL, Volvo FL' },
  { value: 10, label: '10 т - Mercedes Actros, MAN TGL, Volvo' },
  { value: 20, label: '20 т - полуприцеп Фуры' }
];
