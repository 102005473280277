import { JoinChar } from '@common/constants';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';
import { DepotTypeId, Dictionary, IndexedArray, TableColumnName } from '@common/types';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';
import { compareAsString, timestampToDateTimeStringWithTZ, toFixed2 } from '@common/utils';
import { orderActionsToChildren } from '@modules/planning/children/by-date/decorators/table/order-actions-to-children';
import { sortDataSourceByNumberColumn } from '@modules/planning/children/by-date/helpers/tables/sort-data-source-by-number-column';
import { FocusOnMapItem } from '@modules/planning/children/by-date/types';
import { NotificationTipHTML } from '@modules/planning/children/by-date/components/result/tips';
import { PlanNotices } from '@modules/planning/children/by-date/types/notice-type';
import { getNotificationIconType } from '@modules/planning/children/by-date/decorators/notice/get-notification-icon-type';

export const waypointsToChildren = (
  waypoints: IndexedArray<WaypointTotal & { changed: boolean }>,
  focusOnMapItem: FocusOnMapItem,
  notices: PlanNotices
) => {
  return sortDataSourceByNumberColumn(waypoints).map((waypoint) => {
    const { CargoCount, ClientCount, RestrictionEnum, RouteColor, StartTime, UTCOffset, EndTime, Mass, Volume, Distance } = waypoint;
    const isDepotStorage = waypoint.DepotTypeID === DepotTypeId.Storage;
    const icon = isDepotStorage ? 'home' : 'location';
    const color = getVehicleRouteColor({ RouteColor, ID: waypoint.VehicleID });
    const dictionary = Dictionary.Waypoint;
    const ownNotifications = notices.Waypoint.notices.filter(({ WaypointID }) => compareAsString(waypoint.WaypointID, WaypointID));

    return {
      ...waypoint,
      key: waypoint.WaypointID,
      dictionary,
      OrderActionTotal: null,
      FullName_icon: `/planning/${icon}`,
      [TableColumnName.StartTime]: timestampToDateTimeStringWithTZ(StartTime, UTCOffset),
      [TableColumnName.EndTime]: timestampToDateTimeStringWithTZ(EndTime, UTCOffset),
      [TableColumnName.ID]: waypoint.WaypointID,
      [TableColumnName.RouteColor]: color,
      RouteColorChanged: waypoint.changed,
      [TableColumnName.Cargo]: `${waypoint.LoadedCargo}/${waypoint.UnloadedCargo}/${CargoCount}`,
      Cargo_htmltip: 'Кол-во погруженных/кол-во разгруженных/Всего грузов',
      [TableColumnName.Status]: waypoint.PlanStatus,
      [TableColumnName.Distance]: toFixed2(Distance),
      [TableColumnName.Mass]: toFixed2(Mass),
      [TableColumnName.Volume]: toFixed2(Volume),
      [TableColumnName.Client]: ClientCount,
      [TableColumnName.Restriction]: Object.values(RestrictionEnum ?? {}).join(JoinChar.Comma),
      [TableColumnName.FocusOnMap]: focusOnMapItem,
      [TableColumnName.Notification]: getNotificationIconType(ownNotifications),
      Notification_htmltip: NotificationTipHTML({
        ownNotifications,
        childNotifications: []
      }),
      children: orderActionsToChildren(waypoint.OrderActionTotal)
    };
  });
};
