import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsStore } from '../types/store';
import { AnalyticsActions } from '../types/actions';
import initialState from '@modules/analytics/common/store';
import { GetBoardWidgetResponse } from '@common/types/dictionaries/analytics';
import { getWidgetParamName } from '@modules/analytics/common/utils/get-widget-param-name';

export const analyticsGetBoardWidgetReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsGetBoardWidgetRoutine.REQUEST]: (state) => {
      state.Widgets = {};
      return state;
    },
    [actions.analyticsGetBoardWidgetRoutine.SUCCESS]: (state, action: PayloadAction<GetBoardWidgetResponse>) => {
      const { data } = action.payload;
      state.Widgets = data;
      //найдем есть ли виджеты с предопределнными параметрами и установим значение параметров в стор
      Object.values(data).forEach((w) => {
        if (w.Filter) {
          Object.values(w.Filter).forEach((f) => {
            const filterItems = Object.values(f.items);
            const paramName = getWidgetParamName(f.name, w);
            state.WidgetParams[paramName] = { name: f.name, value: filterItems.length > 0 ? Object.values(f.items)[0].data : undefined };
          });
        }
      });
      return state;
    },
    [actions.analyticsGetBoardWidgetRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsGetBoardWidgetRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
      return state;
    }
  });
