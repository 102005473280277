import { ILSDatePicker } from '@common/components';
import moment from 'moment';
import { useMemo } from 'react';
import { IDatePickerProps } from '@components/data-entry/datepicker';
import { EMPTY_STRING } from '@common/constants';
import { DateFormat } from '@common/types';

interface Props {
  handleDate: (date: string[]) => void;
  startDate: string | number | undefined;
  endDate: string | number | undefined;
  changeDateFormat: (date: string) => string;
  DATE_FORMAT: DateFormat;
}

export const ILSDataPickerIntervalComponent = (props: Props) => {
  const { handleDate, startDate, endDate, changeDateFormat, DATE_FORMAT } = props;

  const handleClear = () => {
    handleDate([EMPTY_STRING, EMPTY_STRING]);
  };

  const changeHandler = (date: string[] | string) => {
    if (typeof date === 'string') {
      if (date) {
        handleDate([changeDateFormat(date), changeDateFormat(date)]);
      } else {
        handleClear();
      }
    }
  };

  const datePickerProps: IDatePickerProps = useMemo(() => {
    const value = startDate ? moment(startDate) : endDate ? moment(endDate) : undefined;

    return {
      className: 'date-picker',
      onChange: (_momentDate: any, stringDate: string) => changeHandler(stringDate),
      defaultValue: value,
      value,
      format: DATE_FORMAT
    };
  }, [startDate, endDate]);

  return <ILSDatePicker {...datePickerProps} />;
};
