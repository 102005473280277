import { FC, ReactNode } from 'react';
import { Reducer } from 'redux';
import { AbacPermissions, AbacRules } from '@abac/types';
import { TTranslateModel } from '@core/types/models';
import { getSRCIconSVG } from '@common/decorators/path';
import { ModuleScope } from '@core/types/scope';

export enum Module {
  DemoOrdersNTrips = 'DemoOrdersNTrips',
  OrdersNTrips = 'OrdersNTrips',
  Analytics = 'Analytics',
  Planner = 'Planner',
  PlanningByDate = 'PlanningByDate',
  PlanningManager = 'PlanningManager',
  PlanningParams = 'PlanningParams',
  PlanningData = 'PlanningData',
  PlanAnalytic = 'PlanAnalytic',
  PlanningSettings = 'PlanningSettings',
  Catalog = 'Catalog',
  Data = 'Data',
  Documents = 'Documents',
  Monitoring = 'Monitoring',
  Emulator = 'Emulator',
  RoadGraph = 'RoadGraph',
  Account = 'Account',
  Settings = 'Settings',
  DashBoard = 'DashBoard',
  TenderPlatform = 'TenderPlatform',
  TenderOwn = 'TenderOwn',
  TenderSearch = 'TenderSearch',
  Demand = 'Demand',
  Params = 'Params',
  SchemeEditor = 'SchemeEditor',
  Common = 'Common',
  Payment = 'Payment',
  Questions = 'Questions'
}

export enum ModulePath {
  CustomWithoutAuthPage = '/all-user-pages',
  DemoOrdersNTrips = '/demo/orders-and-trips',
  Sidebar = '/sidebar',
  Login = '/login',
  Register = '/register',
  Recovery = '/recovery',
  Account = '/account',
  Analytics = '/Analytics',
  AnalyticsTender = '/analytics-tender',
  AnalyticsTenderMain = '/main',
  AnalyticsTenderReports = '/reports',
  AnalyticsPlanning = '/analytics-planning',
  AnalyticsPlanningBoards = '/boards',
  AnalyticsPlanningReports = '/reports',
  Catalog = '/catalog',
  Dashboard = '/',
  Documents = '/documents',
  DocumentsAll = '/all',
  DocumentsInbox = '/inbox',
  DocumentsSent = '/sent',
  DocumentsTemplates = '/templates',
  DocumentsArchive = '/archive',
  DocumentsTrash = '/trash',
  DocumentsUpload = '/upload',
  Planner = '/planner',
  Planning = '/planning',
  PlanAnalytic = '/plan-analytic',
  RoadGraph = '/road-graph',
  RouteTest = '/route-test',
  RouteEditor = '/editor',
  Manager = '/manager',
  Date = '/date',
  Data = '/data',
  Monitoring = '/monitoring',
  Emulator = '/emulator',
  MonitoringDashboard = '/dashboard',
  MonitoringDelivery = '/delivery',
  MonitoringDetails = '/details',
  TenderPlatform = '/tender-platform',
  TenderOwn = '/tender-own',
  TenderSearch = '/search',
  New = '/new',
  Demand = '/demand',
  Params = '/params',
  SchemeEditor = '/scheme-editor',
  Settings = '/settings',
  OrdersNTrips = '/orders-and-trips',
  LogisticServices = '/logistic-services',
}

export const PLANNER_PLANNING_PATH = `${ModulePath.Planner}${ModulePath.Planning}`;
export const PLANNER_MANAGER_PATH = `${ModulePath.Planner}${ModulePath.Manager}`;
export const PLANNER_PARAMS_PATH = `${ModulePath.Planner}${ModulePath.Params}`;
export const PLANNER_NEW_PATH = `${ModulePath.Planner}${ModulePath.New}`;
export const PLANNER_DATA_PATH = `${ModulePath.Planner}${ModulePath.Data}`;

export enum ModuleTitle {
  Account = 'Аккаунт',
  Main = 'ILS Online',
  Analytics = 'Аналитика',
  AnalyticsTender = 'Аналитика тендерной площадки',
  AnalyticsTenderReports = 'Сохраненные отчеты',
  AnalyticsTenderMain = 'Главная',
  AnalyticsPlanning = 'Аналитика перевозок',
  AnalyticsPlanningBoards = 'Главная',
  AnalyticsPlanningReports = 'Сохраненные отчеты',
  Catalog = 'Справочники',
  Data = 'Данные',
  Dashboard = 'Рабочий стол',
  Demand = 'Заявки и рейсы',
  Documents = 'Документы',
  DocumentsAll = 'Все',
  DocumentsInbox = 'Входящие',
  DocumentsInboxTitle = 'Входящие заявки и уведомления',
  DocumentsSent = 'Отправленные',
  DocumentsSentTitle = 'Отправленные заявки и уведомления',
  DocumentsTemplates = 'Шаблоны',
  DocumentsArchive = 'Архив',
  DocumentsTrash = 'Корзина',
  DocumentsUpload = 'Загрузка документа',
  Emulator = 'Эмулятор',
  Monitoring = 'Мониторинг',
  MonitoringDashboard = 'Текущая сводка',
  MonitoringDelivery = 'Монитор доставки',
  MonitoringDetails = 'Детализация событий',
  New = 'Новое',
  Params = 'Параметры',
  Planner = 'Планировщик',
  Planning = 'Планирование',
  PlanAnalytic = 'Аналитика плана',
  PlanningManager = 'Менеджер планов',
  PlanningSettings = 'Настройки планирования для внешнего доступа',
  Result = 'Результат',
  RoadGraph = 'Редактор графа дорог',
  RouteTest = 'Тест маршрута',
  RouteEditor = 'Редактор дорог',
  Settings = 'Настройки',
  SchemeEditor = 'Редактор схем',
  Title = 'Заголовок модуля',
  TenderPlatform = 'Тендерная площадка',
  TenderOwn = 'Мои тендеры',
  TenderSearch = 'Поиск тендеров',
  OrdersNTrips = 'Заявки и рейсы'
}

export enum FeatureModule {
  SuggestionAutoComplete = 'SuggestionAutoComplete',
  Payment = 'Payment',
  Questions = 'Questions'
}

export type Access = {
  rules: AbacRules;
  permissions: AbacPermissions;
};

export interface IModule {
  MODULE?: string;
  title?: ModuleTitle;
  name?: string;
  path?: string; // Path enum TMS-1233
  fullPath?: string;
  fullTitle?: string;
  exact?: boolean;
  component?: FC | ReactNode;
  sagas?: Generator[];
  reducer?: Reducer;
  children?: Record<Module, IModule>;
  inScope?: ModuleScope[] | ModuleScope;
  useScope?: ModuleScope[] | ModuleScope;
  icon?: ReturnType<typeof getSRCIconSVG>;
  api?: Record<string, FC>;
  permission?: string[] | string;
  lang?: TTranslateModel | null;
  deps?: Array<Module>;
  access?: Access;
}
