import { FC } from 'react';

export const ILSCellBadgeText: FC = ({ children }) => {
  const value = Array.isArray(children?.[1]) ? children?.[1][0] : children?.[1];
  const color = Array.isArray(children?.[1]) ? children?.[1][1] : null;
  return (
    <>
      {color && <div className="cell-badge" style={{ background: `${color}` }} />}
      {value}
    </>
  );
};
