import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { vehiclesSelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary } from '@common/types';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { projectDictionaryIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';

export const useVehicleData = () => {
  const dispatch = useDispatch();
  const vehicles = useSelector(vehiclesSelector);
  const loading = useSelector((state: PlanningByDateState) => projectDictionaryIsFetchingSelector(state, Dictionary.Vehicle));

  useEffect(() => {
    dispatch(
      dictionaryPlannerRoutine({
        dictionary: Dictionary.Vehicle,
        dictionaryFetching: true
      })
    );
  }, []);

  return { loading, vehicles };
};
