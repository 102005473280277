import { isArray, isEmpty } from 'lodash';
import { IILSCellStatusProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export enum CellTypeStatusButton {
  exception = 'exception',
  success = 'success',
  normal = 'normal'
}

export const ILSCellStatus = <R extends TableRecord>({ children, handleSave, record, dataIndex }: IILSCellStatusProps<R>): JSX.Element => {
  if (!isArray(children[1]) || isEmpty(children[1])) {
    return <></>;
  }

  const [node, [{ value, label }]] = children;

  if (!value || !label) {
    console.error(`Необходимо передать статус в формате {value: 'foo', label: 'bar'}`);
    return <></>;
  }

  const handleClick = () => {
    handleSave?.(record, dataIndex, record.ID);
  };

  return (
    <div onClick={value === CellTypeStatusButton.normal ? handleClick : undefined} className={`cell-type-status cell-type-status-${value}`}>
      {node}
      {label}
    </div>
  );
};
