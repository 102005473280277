import { Result } from 'antd';
import React, { FC } from 'react';
import classNames from 'classnames';

export const AnalyticsChartNoData: FC<{ name?: string | null; hideBorder: boolean }> = ({ name, hideBorder }) => {
  return (
    <div className={classNames('analytics-empty-chart', { 'analytics-widget-border': !hideBorder })}>
      {name && <div className={'ml-2 mt-2'}>{name}</div>}
      <Result className={'analytics-empty-chart-nodata'} status="info" subTitle={`Нет данных`} />
    </div>
  );
};
