import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringAttachmentsRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';

type AttachmentsRequest = {
  waypointID: string;
};

// TODO проверить использование
// Получает данные вложений (data)
function* monitoringAttachmentsWorker(action: PayloadAction<AttachmentsRequest>) {
  const { request, success, failure, fulfill } = monitoringAttachmentsRoutine;
  const { waypointID } = action.payload;

  try {
    yield put(request({ waypointID }));

    const { data } = yield call(MonitoringAPI.getAttachments, { waypointID });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringAttachmentsWatcher() {
  yield takeLatest(monitoringAttachmentsRoutine.trigger, monitoringAttachmentsWorker);
}
