import { FC, memo } from 'react';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';
import {
  DecoratedIntegrationServices,
  IntegrationAllServiceNames,
  IntegrationTypes,
  PreCostToDisplay
} from '@modules/orders-and-trips/types/integrations';
import { isEmpty } from 'lodash';
import { ILSSpin } from '@common/components';
import { JoinChar } from '@common/constants';

interface IProps {
  company: DecoratedIntegrationServices[IntegrationTypes.tenderOutFastDelivery][number];
  chooseCompany(id?: IntegrationAllServiceNames, existPreCost?: boolean): void;
  preCost: PreCostToDisplay | null;
  isFetching: boolean;
  index: number;
}

export const TransportCompany: FC<IProps> = memo(({ company, chooseCompany, preCost, isFetching, index }) => {
  const deliveryAvailable = !isEmpty(preCost?.[company.id]?.data) && !preCost?.[company.id].errors;

  return (
    <div className="transport-item-company company">
      <div className="company-img">
        <img src={company.img} width={100} alt={company.name} />
      </div>
      <div className="company-name">{company.name}</div>
      <div className="company-info">
        {preCost?.[company.id]?.data?.map((info) => {
          return (
            <>
              <div className="company-price">
                <strong>{info.cost}</strong>
              </div>
              <div className="company-description">{info.description}</div>
            </>
          );
        }) ?? <ILSSpin spinning={isFetching}>{JoinChar.Dash}</ILSSpin>}
      </div>
      <div className="company-action">
        <ActionButton
          isFetching={isFetching}
          text={!deliveryAvailable ? 'Запросить информацию' : 'Оформить доставку'}
          className="black width-190"
          click={() => chooseCompany(company.id, deliveryAvailable)}
          disabled={Boolean(company?.active)}
        />
      </div>
    </div>
  );
});

