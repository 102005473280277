import React, { FC } from 'react';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { deepEqual } from 'fast-equals';
import { getTemplateRoutine } from '@modules/documents/children/templates/actions';
import ILSUpdateDocumentForm from '@modules/documents/components/update-document-form';
import { DocumentAction, DocumentType, IDocumentsUpdatePayload } from '@modules/documents/types/document-type';
import { currentDocumentRoutine, documentUpdateRoutine } from '@modules/documents/actions';
import { getCurrentDocumentState, getDocumentsEnum } from '@modules/documents/selectors';
import { DocsState } from '@modules/documents/components/view-document-modal';
import { DocEnum } from '@modules/documents/types/document-state';

interface Props {
  isInbox?: boolean;
  formDisabled: boolean;
  docs: DocsState;
  setFormDisabled: (value: boolean) => void;
  submit: boolean;
}

const ILSViewDocMenu: FC<Props> = ({ isInbox, formDisabled, docs, setFormDisabled, submit }) => {
  const dispatch = useAppDispatch();
  const docEnum = useAppSelector(getDocumentsEnum);
  const currentDocument = useAppSelector(getCurrentDocumentState);

  const handleSelect = (i: DocEnum) => {
    dispatch(currentDocumentRoutine({ documentId: i.id, isOpenModal: true }));
    if (currentDocument?.DocumentTemplateID !== 0) {
      dispatch(getTemplateRoutine({ docId: i.id }));
    }
  };

  const handleSave = (payload: object, oldValues: object, type: DocumentType) => {
    const payloadData: IDocumentsUpdatePayload = {
      data: { id: currentDocument?.ID as number, values: { Type: type } },
      action: DocumentAction.Update,
      ...payload
    };

    if (!deepEqual(payload, oldValues)) {
      dispatch(documentUpdateRoutine(payloadData));
    }

    setFormDisabled(true);
  };
  return (
    <>
      {!isInbox ? (
        <div className={'form-container'}>
          <ILSUpdateDocumentForm document={currentDocument} disabled={formDisabled} submit={submit} updateDocument={handleSave} />
        </div>
      ) : (
        <>
          {docEnum && (
            <div className={'select-container'}>
              {docEnum.map((doc) => (
                <div key={doc?.id} className={classnames('doc', { current: doc?.id === docs?.id })} onClick={() => handleSelect(doc)}>
                  {doc?.name}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ILSViewDocMenu;
