import '../styles.less';
import { authNavLinks } from '@modules/auth-nav/constants/nav-auth-links';
import { ILSAuthNavLink } from '@modules/auth-nav/components/auth-nav-link';

export const ILSAuthNav = () => {
  return (
    <div className="auth-nav">
      {authNavLinks.map((authNavLink) => (
        <ILSAuthNavLink modulePath={authNavLink.modulePath} title={authNavLink.title} key={authNavLink.title} />
      ))}
    </div>
  );
};

