import { TableConfig } from '@common/types';

export const getConfigWithoutGrouping = (config: TableConfig): TableConfig => {
  return {
    ...config,
    columns: config.columns.map((column) => {
      return {
        ...column,
        grouping: false
      };
    })
  };
};
