import { FC, FunctionComponent } from 'react';
import { ProposalSearchForm } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-search-form';
import { ProposalList } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-list';
import { useFakePreCost } from '@modules/orders-and-trips/hooks/use-fake-pre-cost';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';

interface IProps {
  activeTab: NewOrderFormTab;
  FormFields: FunctionComponent;
  section: NewOrderFormTab;
}

export const Proposal: FC<IProps> = ({ activeTab, FormFields, section }) => {
  const { proposalList, getFakePreCost, isFetching, activeSortOption, sortProposals } = useFakePreCost(activeTab);

  return (
    <>
      <ProposalSearchForm children={<FormFields />} section={section} onFinish={getFakePreCost} />
      <ProposalList sortProposals={sortProposals} activeSortOption={activeSortOption} proposalList={proposalList} section={section} isFetching={isFetching} />
    </>
  );
};
