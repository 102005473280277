import { Select } from 'antd';
import { FC, forwardRef } from 'react';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { OptionProps } from 'antd/lib/select';
import { ILS_TABLE_CLASSNAME } from '@common/components/data-display/table/constants';
import { EMPTY_STRING } from '@common/constants';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';
import { Placeholder } from '@components/common/forms/constants';

const { Option } = Select;

export const ILSSelect: FC<IILSSelectProps> = forwardRef(
  ({ showSearch = true, disabled, placeholder = Placeholder.ChooseValue, showArrow, noPopupContainer, ...props }, ref) => {
    const { multipleCell, popupOnTable, ...rest } = props;
    const getPopupContainer: IILSSelectProps['getPopupContainer'] =
      (multipleCell || popupOnTable
        ? (trigger: HTMLDivElement) => trigger.closest(`.${ILS_TABLE_CLASSNAME}`) || (document.querySelector('body') as HTMLBodyElement)
        : (trigger: HTMLDivElement) => trigger.parentElement || trigger) || undefined;
    return (
      <Select
        showArrow={!disabled && showArrow}
        showSearch={showSearch}
        getPopupContainer={noPopupContainer ? undefined : getPopupContainer}
        fieldNames={rest.fieldNames ?? BASE_FIELD_NAMES}
        filterOption={filterOption(rest.fieldNames ?? BASE_FIELD_NAMES)}
        {...rest}
        ref={ref}
        disabled={disabled}
        placeholder={disabled ? EMPTY_STRING : placeholder}
      />
    );
  }
);

export const ILSSelectOption: FC<OptionProps> = (props) => {
  return <Option {...props} children={props.children} />;
};
