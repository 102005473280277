import { ILSDropdown } from '@common/components';
import { MILLISECONDS_TO_DAYS_MULTIPLIER } from '@common/constants';
import { formatTimeString } from '@common/utils/helpers/date-time/date-time';
import { MonitoringChartContextMenu } from '@modules/monitoring/children/dashboard/components/context-menu/context-chart-menu';
import { ContextMenuItem } from '@modules/monitoring/children/dashboard/types/context-menu';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { MonitoringChartVehicle } from '../../types/chart';
import { ChartSchema } from './chart-shema';
import { CHART_DAY_TIMES } from './configs/chart-time';
import { CHART_SIZE, CURRENT_TIME_TEXT, TIME_OFFSET } from './constants/chart-table';
import { getTimes, getXMax, getXMin } from '@modules/monitoring/components/chart/helpers/graph-helpers';

interface Props {
  chartData: Array<MonitoringChartVehicle>;
  useCurrentTime: boolean;
  contextMenuList: ContextMenuItem[];
}

// Основной компонент диаграммы доставок в модуле Мониторинга
export const ILSChartTable: FC<Props> = ({ chartData, useCurrentTime = false, contextMenuList, ...props }) => {
  const currentDateTime = new Date();

  const currentDay = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate());

  const showChart = Boolean(chartData.length);

  const tickItem = CHART_DAY_TIMES.map((item, key) => (
    <div className="time" key={item + key}>
      <div key="time">{item.substring(0, 5)}</div>
    </div>
  ));

  const times = getTimes(chartData);
  const xMax = moment.unix(getXMax(times)).endOf('day').unix();
  const xMin = moment.unix(getXMin(times, xMax)).startOf('day').unix();

  const chartItem = chartData.length
    ? chartData.map((item, index) => (
        <div className="d-flex chart-row" key={item.id}>
          <ILSDropdown
            overlay={
              <MonitoringChartContextMenu
                items={contextMenuList}
                record={{ VehicleID: item.id }}
                onClick={() => void {}}
                onContextClose={() => void {}}
              />
            }
            trigger={['contextMenu']}
          >
            <div className="chart-label">{item.name}</div>
          </ILSDropdown>
          <div className="chart-area">
            <ChartSchema data={item.data} xMin={xMin} xMax={xMax} />
          </div>
        </div>
      ))
    : null;

  const [currentTime, setCurrentTime] = useState<string | undefined>(undefined);

  const w = ((currentDateTime.getTime() - currentDay.getTime()) / MILLISECONDS_TO_DAYS_MULTIPLIER) * 100;

  useEffect(() => {
    if (useCurrentTime) {
      setCurrentTime(formatTimeString(new Date().toTimeString()));
      const timer = setInterval(() => {
        setCurrentTime(formatTimeString(new Date().toTimeString()));
      }, 60000);
      return () => clearInterval(timer);
    }
  }, []);

  const timeOffset = ((w - 50) * -TIME_OFFSET) / 50;

  const currentTimeItem = currentTime ? (
    <div className="annotation position-relative" style={{ left: `calc(${w.toFixed(2)}% + ${timeOffset}px)` }}>
      <div className="text">{`${currentTime} ${CURRENT_TIME_TEXT}`}</div>
    </div>
  ) : null;
  const currentTimeLine = currentTime ? (
    <div className="vertical-line" style={{ left: `${w}%`, height: `${CHART_SIZE.Max * chartData.length}px` }} />
  ) : null;

  return (
    <div className="chart-container">
      {showChart ? (
        <>
          <div className="chart-header">
            <div className="flex-between-center ticks">{tickItem}</div>
          </div>
          <div className="position-relative chart-inner">
            {chartItem}
            {currentTimeLine}
            {currentTimeItem}
          </div>
        </>
      ) : null}
    </div>
  );
};
