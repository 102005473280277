import { FC, useContext } from 'react';
import { AppContext } from '../../../App';
import { ILSCard } from '@common/components';
import { useLocation } from 'react-router';
import { ILSTenderPlatformAccountInfo } from '@modules/tender-platform/components/account-info';
import { pickUserRole } from '@modules/tender-platform/utils/helpers/pick-user-role';
import { AccountRoles, UserRoles } from '@common/types/dictionaries';
import { ACCOUNT_ROLE_LIST, USER_ROLE_LIST } from '@modules/account/constants';

export const ILSTenderPlatformAccountInfoContainer: FC = () => {
  const { roles } = useContext(AppContext) ?? {};
  const userRole = pickUserRole(USER_ROLE_LIST, roles) as UserRoles;
  const accountRole = pickUserRole(ACCOUNT_ROLE_LIST, roles) as AccountRoles;
  const { pathname } = useLocation();

  return (
    <ILSCard size={'small'} className={'tender-roles'}>
      <ILSTenderPlatformAccountInfo accountRole={accountRole} userRole={userRole} path={pathname} />
    </ILSCard>
  );
};
