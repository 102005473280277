import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsStore } from '../types/store';
import initialState from '@modules/analytics/common/store';
import { IndexedArray } from '@common/types';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const analyticsUpdateWidgetReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsUpdateWidgetRoutine.REQUEST]: (state) => {
      return state;
    },
    [actions.analyticsUpdateWidgetRoutine.SUCCESS]: (state, action: PayloadAction<IndexedArray<AnalyticsWidget>>) => {
      const widgets = Object.values(action.payload);
      widgets.forEach((widget) => {
        if (widget.ID) {
          state.Widgets[widget.ID] = { ...state.Widgets[widget.ID], ...widget };
        }
      });
      return state;
    },
    [actions.analyticsUpdateWidgetRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsUpdateWidgetRoutine.FULFILL]: (state) => {
      return state;
    }
  });
