import { CoordError } from '@common/types/general/check-coords';
import { showError } from '@common/utils/coords-error/coords-error';
import { getAddressByCoordsYandex, getCoordsByAddressYandex } from '@core/utils/get-geocode';
import { call } from 'redux-saga/effects';

export function* getDataByAddressOrByCoords(errorObj: CoordError, payload: string | number[]) {
  let data: string | number[] | undefined;
  const getData = typeof payload === 'object' ? getAddressByCoordsYandex : getCoordsByAddressYandex;

  try {
    data = typeof payload === 'object' ? yield call(getData, payload[0], payload[1]) : yield call(getData, payload);
    return data;
  } catch (error) {
    showError(errorObj);
  }
}
