import { apiInstance } from '@core/api/instance';
import { IDocumentTemplatesAPI } from '../types/api';

const DocumentTemplatesAPI: IDocumentTemplatesAPI = {
  indexDocumentTemplates: () => apiInstance().post('/newTms/DocumentTemplate/index', {}),
  getDocumentTemplates: (data) => apiInstance().post('/newTms/DocumentTemplate/getTemplatesArray ', data),
  indexDocumentSets: (data) => apiInstance().post('/newTms/DocumentSet/index', data),
  getTemplate: (templateId, docId, deps) =>
    apiInstance().post('newTms/DocumentTemplate/getTemplate', docId ? { docId, ...deps } : { templateId, ...deps }),
  updateTemplate: (templateId: number, template: string) =>
    apiInstance().post('newTms/DocumentTemplate/update', {
      data: { id: templateId, values: template }
    }),
  deleteAttachTemplate: (data) => apiInstance().post('newTms/DocumentSet/deleteTemplate', data),
  updateDealAttach: (data) => apiInstance().post('newTms/DocumentSet/update', data),
  copyTemplate: (templateId) => apiInstance().post('newTms/DocumentTemplate/copyTemplate', { templateId }),
  createDealTemplate: () => apiInstance().post('newTms/DocumentSet/create'),
  deleteDocumentSet: (ids: number) => apiInstance().post('newTms/DocumentSet/delete', { ids }),
  deleteDocumentTemplate: (ids) => apiInstance().post('newTms/DocumentTemplate/delete', { ids }),
  createTemplate: (data) => apiInstance().post('newTms/DocumentTemplate/create', data)
};

export default DocumentTemplatesAPI;

