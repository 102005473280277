import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringUpdateVehicleTrackersRoutine } from '../actions';
import { compareAsString } from '@common/utils';
import { MonitoringVehicleTrackerModel } from '@common/types/dictionaries/monitoring-vehicle';

export const monitoringUpdateVehicleTrackerReducer = createReducer(initialState, {
  [monitoringUpdateVehicleTrackersRoutine.REQUEST]: (state) => {
    state.vehicleTrackers.isFetching = true;
    return state;
  },
  [monitoringUpdateVehicleTrackersRoutine.SUCCESS]: (state, action: PayloadAction<MonitoringVehicleTrackerModel>) => {
    const updatedTracker = action.payload;
    let trackers = state.vehicleTrackers.data.map((tracker) => {
      if (compareAsString(tracker.ID, updatedTracker.ID)) {
        return updatedTracker;
      } else {
        return tracker;
      }
    });
    if (trackers) {
      state.vehicleTrackers.data = trackers;
      state.vehicleTrackers.lastUpdate = new Date().getTime();
    }
    return state;
  },
  [monitoringUpdateVehicleTrackersRoutine.FAILURE]: (state) => {
    state.vehicleTrackers.lastUpdate = undefined;
    return state;
  },
  [monitoringUpdateVehicleTrackersRoutine.FULFILL]: (state) => {
    state.vehicleTrackers.isFetching = false;
    return state;
  }
});
