import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { recoveryActions } from '@modules/current-user/actions';

export const recoveryReducer = createReducer(authInitialState, {
  [recoveryActions.REQUEST]: onRequest,
  [recoveryActions.FAILURE]: onFailure,
  [recoveryActions.FULFILL]: onFulfill,
  [recoveryActions.SUCCESS]: (state) => state
});
