import { ILSButton } from '@common/components';
import { ModalListItem } from '@modules/documents/components/dependent-list-modal';
import './styles.less';

interface Props {
  item: ModalListItem;
  handleClickSelect: (i: ModalListItem) => void;
}

const DependentListItem = ({ item, handleClickSelect }: Props) => (
  <div key={item?.ID || item?.value} className={item?.route ? 'item-block' : 'flex-between-center'}>
    <div>
      <span>{item?.Name || <b>{item?.name}</b>}</span>
      {item?.route && (
        <div>
          <b>Направление:</b> {item.route}
        </div>
      )}
      {item?.from && (
        <div>
          <b>Адрес погрузки:</b> {item.from}
        </div>
      )}
      {item?.to && (
        <div>
          <b>Адрес разгрузки:</b> {item.to}
        </div>
      )}
      {item?.winner && (
        <div>
          <b>Перевозчик:</b> {item.winner}
        </div>
      )}
    </div>
    <ILSButton className={'ml-5'} type="primary" onClick={() => handleClickSelect(item)}>
      Выбрать
    </ILSButton>
  </div>
);

export default DependentListItem;
