import { plannerSettingsRoutine } from '@modules/planning/actions';
import { PlannerSettingsParameter } from '@modules/planning/types/planning';
import { useDispatch, useSelector } from 'react-redux';
import { plannerSettingsSelector } from '@modules/planning/selectors';
import { Dictionary } from '@common/types';
import { EMPTY_STRING } from '@common/constants';

export const usePlansCollapse = () => {
  const dispatch = useDispatch();
  const {
    table: { showPlans }
  } = useSelector(plannerSettingsSelector);
  const defaultActiveKey = showPlans ? Dictionary.Project : EMPTY_STRING;

  const onChangeCollapsePanel = () => {
    dispatch(plannerSettingsRoutine({ parameter: PlannerSettingsParameter.Table, data: { showPlans: !showPlans } }));
  };
  return { onChangeCollapsePanel, defaultActiveKey };
};
