import { MentionProps, Mentions } from 'antd';
import { OptionProps } from 'antd/lib/mentions';
import { FC } from 'react';

const { Option } = Mentions;

export const ILSMentions: FC<MentionProps> = (props) => {
  return <Mentions {...props} />;
};

export const ILSMentionOption: FC<OptionProps> = (props) => {
  return <Option {...props} />;
};
