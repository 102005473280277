import { tableCustom } from '@core/containers/table-custom';
import { FORBIDDEN_ZONE_TABLE_CONFIG } from '@common/models/forbidden-zone/config/table';
import { forbiddenZones2table } from '@common/models/forbidden-zone/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { FC, useEffect, useMemo } from 'react';
import { catalogSelector } from '../../selectors';
import { useSelector } from 'react-redux';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { CatalogDictionary, Dictionary } from '@common/types';
import { CatalogState } from '@modules/catalog/types/store';

export const ILSCatalogForbiddenZone: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    const selectDict = useMemo(catalogSelector, []);
    const permitZoneTypeData = useSelector((state: CatalogState) => selectDict(state, Dictionary.ForbiddenZone, 'data'));

    useEffect(() => {
      setMapProps?.({
        field: 'Points',
        type: 'polygon',
        colorField: 'Color',
        dontEdit: false
      });
    }, []);

    return (
      <ILSCatalogTable {...props} dataDecorator={forbiddenZones2table} dictionary={Dictionary.ForbiddenZone} dataSource={permitZoneTypeData} />
    );
  },
  FORBIDDEN_ZONE_TABLE_CONFIG,
  CatalogDictionary.ForbiddenZone,
  true
);
