import { getVehicleRouteColor } from '@common/models/vehicle/decorators/get-vehicle-route-color';
import { MarkerType } from '@common/types';
import { Latitude, Longitude, VehicleTrackerMonitoring } from '@common/types/dictionaries/vehicle';
import { DEFAULT_MAP_CENTER } from '@core/containers/map';
import { IVehicleMonitoringData, VehicleMonitoringMapData } from '@modules/vehicle-monitoring/types/models';
import { LatLngTuple } from 'leaflet';
import { isNil } from 'lodash';
import { VEHICLE_MONITORING_MAP_NO_DATA } from '../constants';

export const getDataForVehicleMonitoringMap = (data: IVehicleMonitoringData | null) => {
  const output: VehicleMonitoringMapData = VEHICLE_MONITORING_MAP_NO_DATA;

  if (isNil(data)) return output;

  const { Vehicle, Track, VehicleTracker } = data;
  const color = getVehicleRouteColor(Vehicle);

  output.polyline = [{ coords: Track, color, weight: 5, active: true }];

  if (VehicleTracker?.[0]?.TrackerLastInfo?.Latitude && VehicleTracker?.[0]?.TrackerLastInfo?.Longitude) {
    const { Latitude, Longitude } = VehicleTracker[0].TrackerLastInfo;

    output.marker = getCarMarker({ Latitude, Longitude });
  }

  return output;
};

export const getCarMarker = ({ Latitude, Longitude }: { Latitude: Latitude; Longitude: Longitude }) => {
  return [{ coords: { lat: Latitude, lon: Longitude }, type: MarkerType.Car }];
};

export const getVehicleCoordinates = (vehicleTracker?: VehicleTrackerMonitoring): LatLngTuple => {
  if (vehicleTracker?.TrackerLastInfo?.Latitude && vehicleTracker?.TrackerLastInfo?.Longitude) {
    const { Latitude, Longitude } = vehicleTracker.TrackerLastInfo;

    return [Latitude, Longitude];
  }
  return DEFAULT_MAP_CENTER;
};

