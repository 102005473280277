import { ILSPlanningResultTableConfirmButton } from './button-confirm';
import { FC } from 'react';
import { vehicleMonitoringSetLinksRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ShareAltOutlined } from '@ant-design/icons/lib/icons';
import { vehicleMonitoringSendLinksButtonDisabledSelector } from '@modules/planning/children/by-date/selectors/components/tables/buttons/vehicle-monitoring-send-links';

export const PlanningPlannerVehicleMonitoringSendLinksButtonContainer: FC = () => {
  const { disabled } = useSelector(vehicleMonitoringSendLinksButtonDisabledSelector);

  //TODO use-vehicle-monitoring-send-links.tsx
  const dispatch = useDispatch();
  const handleVehicleMonitoringSetLinks = () => {
    dispatch(vehicleMonitoringSetLinksRoutine());
  };
  //
  return (
    <ILSPlanningResultTableConfirmButton
      disabled={disabled}
      buttonTooltip={disabled ? 'План должен быть на исполнении' : 'Отправить ссылки клиентам для наблюдения за заказом'}
      confirmText={'Отправить ссылки клиентам для наблюдения за заказом?'}
      onConfirm={handleVehicleMonitoringSetLinks}
      icon={<ShareAltOutlined className={'ils-svg primary'} />}
    />
  );
};
