import { createReducer } from '@reduxjs/toolkit';
import initialState from '@modules/documents/children/templates/store';
import { currentDealTemplateRoutine } from '@modules/documents/children/templates/actions';
import { DocumentSet } from '@modules/documents/children/templates/types/api';

export const currentTemplate = createReducer(initialState, {
  [currentDealTemplateRoutine.TRIGGER]: (
    state,
    action: {
      type: string;
      payload: { template: DocumentSet; isOpenModal: boolean };
    }
  ) => {
    state.currentDealTemplate.template = action.payload.template;
    state.currentDealTemplate.isViewTemplateModelOpen = action.payload.isOpenModal;
    return state;
  }
});
