import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { getDocumentMetricsRoutine } from '@modules/documents/actions';
import { FormField } from '@modules/documents/children/upload/types/form-field';

const documentMetrics = createReducer(initialState, {
  [getDocumentMetricsRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [getDocumentMetricsRoutine.SUCCESS]: (state, action: { payload: { fields: FormField[] } }) => {
    state.fields = action.payload.fields;
    return state;
  },
  [getDocumentMetricsRoutine.FAILURE]: (state) => {
    state.fields = [];
    return state;
  },
  [getDocumentMetricsRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});

export default documentMetrics;
