import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningCancelRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { isEmpty } from 'lodash';
import { PlanningProcessStatus } from '@modules/planning/children/by-date/constants';
import { PlanningStatus } from '@modules/planning/types';

export const cancelPlanningReducer = createReducer(initialState, {
  [planningCancelRoutine.SUCCESS]: (state, action: PayloadAction<{ planID: string | number }>) => {
    const { planID } = action.payload;
    if (!isEmpty(state.planningCheckData)) {
      state.planningCheckData[planID].Status = PlanningProcessStatus.IsCanceled;
      state.planningCheckData[planID].Progress = null;
    }
    state.status = PlanningStatus.Result;
    return state;
  }
});
