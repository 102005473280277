import { call, put, takeEvery } from 'redux-saga/effects';
import { accountRoutine } from '../actions/index';
import { AccountAPI } from '../api/account';
import { checkTokenRoutine } from '@modules/current-user/actions';
import { AccountPayload } from '@modules/account/types/api';

export function* accountWorker(action: AccountPayload) {
  const { request, success, failure, fulfill } = accountRoutine;
  const { operation, values, id } = action.payload;
  try {
    yield put(request({ operation }));
    yield put(checkTokenRoutine());

    const {
      data: { data }
    } = yield call(AccountAPI.accountOperation, { operation, values, id });
    yield put(success({ data, operation }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation }));
  }
}

export function* accountGetWatcher() {
  yield takeEvery(accountRoutine.trigger, accountWorker);
}
