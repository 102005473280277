import permissions from '../permissions';
import { AbacRules } from '@abac/types';
import { UserRole } from '@common/types';

const rules: AbacRules = {
  [UserRole.administrator]: {
    [permissions.MAIN_LAYOUT]: true,
    [permissions.LIMITED_LAYOUT]: false,
    [permissions.LOGIN]: false,
    [permissions.NON_AUTH_PAGES]: true
  },
  [UserRole.manager]: {
    [permissions.MAIN_LAYOUT]: true,
    [permissions.LIMITED_LAYOUT]: false,
    [permissions.LOGIN]: false,
    [permissions.NON_AUTH_PAGES]: true
  },
  [UserRole.guest]: {
    [permissions.MAIN_LAYOUT]: false,
    [permissions.LIMITED_LAYOUT]: false,
    [permissions.LOGIN]: true,
    [permissions.NON_AUTH_PAGES]: true
  },
  [UserRole.driver]: {
    [permissions.MAIN_LAYOUT]: false,
    [permissions.LIMITED_LAYOUT]: true,
    [permissions.LOGIN]: false,
    [permissions.NON_AUTH_PAGES]: true
  }
};

export default rules;
