import { useSelector } from 'react-redux';
import { invoiceTemplateFetchSelector, invoiceTemplateSelector } from '@common/feature/payment/selectors';
import { useStimulsoftInit } from '@common/hooks/use-stimulsoft-init';
import { ILSSpin } from '@common/components';
import { memo } from 'react';

export const InvoiceViewerContainer = memo(() => {
  const invoiceTemplate = useSelector(invoiceTemplateSelector);
  const invoiceTemplateIsFetching = useSelector(invoiceTemplateFetchSelector);
  const { StimulSoftViewer } = useStimulsoftInit({
    template: invoiceTemplate,
    viewerID: 'viewerID',
    isFetching: invoiceTemplateIsFetching
  });

  return (
    <div className="invoice-viewer-container">
      <ILSSpin
        tip={'Счет загружается, пожалуйста подождите...'}
        spinning={invoiceTemplateIsFetching}
        className="invoice-viewer-container-spinner"
      >
        <StimulSoftViewer />
      </ILSSpin>
    </div>
  );
});

