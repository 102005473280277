import { ColumnTable, Dictionary, TableRecord } from '@common/types';
import { CellType } from '@common/types/components/table/cell';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CELL_TYPE_MIN_WIDTH, INITIAL_COLUMN_RESIZE_DATA, TABLE_COLUMN_MIN_WIDTH_BY_TYPE } from '../constants';
import { setTableColWidth } from '@core/actions/table-custom';
import { useDispatch } from 'react-redux';
import { EMPTY_STRING } from '@common/constants';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { ResizeDataType } from '@common/types/components/table/table';
import { UseResizeColumn } from '@common/types/components/table/hooks';

/** Хук для работы изменения ширины колонок */
export const useResizeColumn = <R extends TableRecord>({ tableName, newConfig }: UseResizeColumn) => {
  const tableRef = useRef<HTMLDivElement | null>(null);

  const onResizeStart = useCallback((e: MouseEvent, column: ColumnTable<R>, dictionary: Dictionary) => {
    isMouseDown.current = true;
    setResize({
      startX: e.clientX,
      column,
      dictionary
    });
  }, []);

  const dispatch = useDispatch();
  const leftPos = (tableRef.current && tableRef.current.getBoundingClientRect().x) ?? 0;

  const [resize, setResize] = useState<ResizeDataType<R>>(() => INITIAL_COLUMN_RESIZE_DATA);
  const onResizeColumn = (newWidth: number, dataIndex: ColumnTable<R>['dataIndex']) => {
    if (!tableName) {
      showUserAndDevError({
        userError: 'Функция изменения столбцов недоступна',
        error: `Необходимо передать prop tableName`
      });
      return;
    }
    dispatch(
      setTableColWidth({
        tableName,
        child: EMPTY_STRING,
        width: newWidth,
        dataIndex,
        config: newConfig
      })
    );
  };

  //Note: Проверяем нажата ли клавиша мыши
  const isMouseDown = useRef(false);

  useEffect(() => {
    const onMouseUp = ({ clientX }: globalThis.MouseEvent) => {
      isMouseDown.current = false;
      if (resize?.startX && resize.column && resize.column.dataIndex) {
        const { dataIndex } = resize.column;
        const shiftX = clientX - resize.startX;

        let newWidth: number =
          typeof resize.column?.width === 'number'
            ? resize.column.width + shiftX
            : CELL_TYPE_MIN_WIDTH[resize.column.type ?? CellType.String] + shiftX;
        let minWidth = 10;
        if (resize.column.sorting) {
          minWidth += 30;
        }
        if (resize.column.filtering) {
          minWidth += 30;
        }
        const minWidthByType = TABLE_COLUMN_MIN_WIDTH_BY_TYPE[resize.column.type ?? CellType.String] ?? 30;
        if (minWidth < minWidthByType) {
          minWidth = minWidthByType;
        }
        if (resize.column.style?.minWidth && Number(resize.column.style.minWidth) > minWidth) {
          minWidth = Number(resize.column.style?.minWidth);
        }
        if (newWidth < minWidth) {
          newWidth = minWidth;
        }
        if (resize.column.style?.maxWidth && Number(resize.column.style.maxWidth) < newWidth) {
          newWidth = Number(resize.column.style?.maxWidth);
        }
        if (newWidth > 10) {
          // todo: нужно добавить всем таблицам tableName и config для resize, иначе он не будет работать
          onResizeColumn(newWidth, dataIndex);
        }
      }
      window.removeEventListener('mousemove', documentMouseMove);
      window.removeEventListener('mouseup', documentMouseUp);
      setResize(INITIAL_COLUMN_RESIZE_DATA);
    };

    const onMouseMove = ({ clientX }: globalThis.MouseEvent) => {
      setResize((prevResizedData) => {
        return isMouseDown.current
          ? {
              ...prevResizedData,
              x: clientX
            }
          : prevResizedData;
      });
    };

    function documentMouseMove(event: globalThis.MouseEvent) {
      onMouseMove(event);
    }
    function documentMouseUp(event: globalThis.MouseEvent) {
      onMouseUp(event);
    }

    window.addEventListener('mousemove', documentMouseMove);
    window.addEventListener('mouseup', documentMouseUp);
    return () => {
      window.removeEventListener('mousemove', documentMouseMove);
      window.removeEventListener('mouseup', documentMouseUp);
    };
  }, [resize]);
  const left = resize?.x && resize?.x - leftPos;
  return { onResizeStart, resize, left, tableRef };
};
