import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { DASH } from '@common/constants';
import { Dictionary, Driver, TableColumnName } from '@common/types';
import { createRecordDictionaryEnumField } from '@common/utils/helpers/models/create-record-dictionary-enum-field';
import { DriverMobileApplication } from '@common/types/dictionaries/driver';
import { getValuesForSelectMultiple } from '@components/decorators';

export const driver2table = (drivers: Array<Driver>) => {
  return createArrayFromIndexedArray(drivers).map((driver, i) => {
    const { DriverTimeline, VehicleTimeline, Warehouse, TripTemplate, Restriction, Vehicle, Zone } = driver;
    return {
      ...driver,
      key: driver.ID ?? 'row' + i,
      DriverRoutes: driver?.TenderRoutes ?? DASH,
      DriverRating: driver?.DriverRating ?? DASH,
      DriverTimelineID: DriverTimeline?.ID,
      VehicleTimelineID: VehicleTimeline?.ID,
      [TableColumnName.WarehouseID]: Warehouse?.ID,
      //TODO backend должен WarehouseEnum и не возвращать driver.Warehouse
      WarehouseEnum: Warehouse?.ID ? { [Warehouse.ID]: Warehouse.Name } : [],
      // Во всех аккаунтах заполнить данное поле значением "нет" (TMS-5757)
      [TableColumnName.MobileApplication]: driver.MobileApplication ?? DriverMobileApplication.No,
      TripTemplateID: getValuesForSelectMultiple({ data: TripTemplate }),
      RestrictionID: getValuesForSelectMultiple({ data: Restriction }),
      VehicleID: getValuesForSelectMultiple({ data: Vehicle }),
      ZoneID: getValuesForSelectMultiple({ data: Zone }),
      DriverTimelineEnum: createRecordDictionaryEnumField(DriverTimeline, Dictionary.DriverTimeline),
      VehicleTimelineEnum: createRecordDictionaryEnumField(VehicleTimeline, Dictionary.VehicleTimeline),
      TripTemplateEnum: createRecordDictionaryEnumField(TripTemplate, Dictionary.TripTemplate),
      RestrictionEnum: createRecordDictionaryEnumField(Restriction, Dictionary.Restriction),
      VehicleEnum: createRecordDictionaryEnumField(Vehicle, Dictionary.Vehicle),
      ZoneEnum: createRecordDictionaryEnumField(Zone, Dictionary.Zone)
    };
  });
};

export const table2driver = (record: Driver, dataIndex: TableColumnName, value: any) => {
  switch (dataIndex) {
    case TableColumnName.WarehouseID:
      return value?.[1] ?? null;
    default:
      return value;
  }
};
