import { useAppDispatch, useAppSelector } from '@core/hooks';
import ILSDateSelect from '@modules/analytics/common/components/date-select';
import { WidgetCommonParam } from '@modules/analytics/common/constants';
import { useContext, useMemo } from 'react';
import { commonRoutine, ModuleActionType } from '../actions';
import { AnalyticsPlanningContext } from '../context';
import { boardsSelector, widgetParamsSelector } from '../selectors';
import moment, { Moment } from 'moment';
import { DateFormat } from '@common/types';
import { getBoardDateRange, getBoardDateRangeFormatted } from '@modules/analytics/common/utils/get-board-date-range';

export const ILSDateSelectContainer = () => {
  const { handleSetParams } = useContext(AnalyticsPlanningContext);
  const params = useAppSelector(widgetParamsSelector);
  const board = useAppSelector(boardsSelector);

  const dispatch = useAppDispatch();

  const selectedBoard = useMemo(() => {
    if (board.selectedBoard) {
      return board.data[board.selectedBoard];
    }
    return undefined;
  }, [board]);

  const dates: { from: Moment; till: Moment } = useMemo(() => {
    if (selectedBoard) {
      return getBoardDateRange(selectedBoard);
    }
    return { from: moment(), till: moment() };
  }, [selectedBoard]);

  const onSelect = (date: { from: Moment; till: Moment }) => {
    handleSetParams({
      From: { name: WidgetCommonParam.From, value: date.from.format(DateFormat.YYYYMMDDDash) },
      Till: { name: WidgetCommonParam.Till, value: date.till.format(DateFormat.YYYYMMDDDash) }
    });
  };

  const onSave = (date: { from: Moment; till: Moment }) => {
    if (selectedBoard) {
      if (selectedBoard && date.from && date.till) {
        dispatch(
          commonRoutine({
            data: [{ ...selectedBoard, From: date.from.unix(), Till: date.till.unix() }],
            method: 'boardUpdate',
            routine: ModuleActionType.Update_Board,
            isIndexed: true
          })
        );
      }
    }
  };

  const onCancel = () => {
    if (selectedBoard) {
      const { from, till } = getBoardDateRangeFormatted(selectedBoard);

      handleSetParams({
        ...params,
        From: { name: WidgetCommonParam.From, value: from },
        Till: { name: WidgetCommonParam.Till, value: till }
      });
    }
  };

  return <ILSDateSelect onSelect={onSelect} onSave={onSave} onCancel={onCancel} dates={dates} />;
};
