import { Provider } from '@common/types';
import { DefaultPlannerConfigsResponse } from '@modules/planning/types';

export const getPlanningOperation = ({
  providerName,
  defaultPlannerConfigsData,
  prepTypePlanning
}: {
  providerName?: Provider;
  defaultPlannerConfigsData?: DefaultPlannerConfigsResponse;
  prepTypePlanning: string | null;
}) => {
  if (providerName && defaultPlannerConfigsData) {
    const availableOperation = defaultPlannerConfigsData[providerName]?.operation;
    if (!prepTypePlanning || !availableOperation.range.map((it) => it.value).includes(prepTypePlanning)) {
      return availableOperation.default;
    }
  }
  return prepTypePlanning;
};
