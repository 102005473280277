import { call, put, takeLatest } from 'redux-saga/effects';
import { getTenderNotifySettingsRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearch } from '../../api';

function* getNotificationSettingsWorker() {
  const { request, success, fulfill, failure } = getTenderNotifySettingsRoutine;

  try {
    yield put(request());
    const { data } = yield call(TenderSearch.getNotifySettings);
    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getNotificationSettingsWatcher() {
  yield takeLatest(getTenderNotifySettingsRoutine.trigger, getNotificationSettingsWorker);
}
