import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { setActiveVehicleRoutine } from '../actions';
import { ActiveVehiclePayload } from '../types/active-vehicle';

// Устанавливает ID активного автомобиля
function* setActiveVehicle(action: PayloadAction<ActiveVehiclePayload>) {
  const { activeVehicleID } = action.payload;
  const { success, failure } = setActiveVehicleRoutine;
  try {
    yield put(success({ activeVehicleID }));
  } catch (error) {
    console.error(error);
    yield put(failure());
  }
}

export function* setActiveVehicleWatcher() {
  yield takeLatest(setActiveVehicleRoutine.trigger, setActiveVehicle);
}
