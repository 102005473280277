import { useAppDispatch } from '@core/hooks';
import { MenuProps } from 'antd';
import { ModulePath } from '@common/types';
import { useLocation } from 'react-router';
import { ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { DocumentAction, DocumentType } from '@modules/documents/types/document-type';
import SendMenuItem from '@modules/documents/components/send-menu-item';
import { Document } from '@modules/documents/types/document';
import { downloadDocument, downloadDocuments, getTemplateRoutine } from '../children/templates/actions';
import { currentDocumentRoutine, documentDeleteFromTrashRoutine, documentUpdateRoutine, saveAsTemplateRoutine } from '../actions';
import { isEmpty } from 'lodash';
import { downloadFileByRemoteURL } from '@common/utils/file/download-file-by-remote-url';

// Создание меню действий

export const useDocumentIndexMenuItems = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  return (type: DocumentType, document: Document): MenuProps['items'] => {
    const menuItemView = {
      label: 'Просмотр',
      key: DocumentAction.View,
      onClick: () => {
        dispatch(currentDocumentRoutine({ documentId: document.ID, isOpenModal: true }));
        if (document.DocumentTemplateID) {
          dispatch(getTemplateRoutine({ docId: document.ID }));
        }
      }
    };

    const menuItemSendToArchive = {
      label: 'Переместить в архив',
      key: DocumentAction.Archive,
      onClick: () =>
        dispatch(
          documentUpdateRoutine({
            data: { id: document.ID, values: { Archive: 1, Deleted: 0 } },
            action: DocumentAction.Archive
          })
        )
    };

    const menuItemDelete = {
      label: 'Удалить',
      key: DocumentAction.Delete,
      onClick: () =>
        dispatch(
          documentUpdateRoutine({
            data: { id: document.ID, values: { Deleted: 1, Archive: 0 } },
            action: DocumentAction.Delete
          })
        )
    };

    const menuItemRestoreDocument = {
      label: 'Восстановить в рабочие документы',
      key: DocumentAction.RestoreDocument,
      onClick: () =>
        dispatch(
          documentUpdateRoutine({
            data: { id: document.ID, values: { Deleted: 0, Archive: 0 } },
            action: DocumentAction.RestoreDocument
          })
        )
    };

    const menuItemDownload = {
      label: 'Скачать',
      key: DocumentAction.Download,
      onClick: () => {
        switch (true) {
          case !isEmpty(document?.children):
            dispatch(downloadDocuments(document.ID));
            break;
          case !!document?.DocumentTemplateID:
            dispatch(downloadDocument(document.ID));
            break;
          case !isEmpty(document?.DocumentAttachment):
            document?.DocumentAttachment.forEach((attachment) => {
              setTimeout(() => {
                downloadFileByRemoteURL(attachment?.UrlOriginal, attachment?.OriginalName);
              }, 500);
            });
            break;
        }
      }
    };

    const menuItemSend = {
      label: 'Отправить',
      key: DocumentAction.Send,
      children: [
        {
          label: <SendMenuItem data={document} />,
          key: DocumentAction.SendItem,
          popupClassName: 'menu-container'
        }
      ]
    };

    if (pathname === `${ModulePath.Documents}${ModulePath.DocumentsArchive}`) {
      return [
        { ...menuItemView, label: 'Открыть' },
        menuItemRestoreDocument,
        { ...menuItemDelete, label: 'Удалить в корзину' },
        menuItemDownload
      ];
    }

    if (pathname === `${ModulePath.Documents}${ModulePath.DocumentsTrash}`) {
      return [
        { ...menuItemView, label: 'Открыть' },
        {
          label: 'Восстановить в',
          key: 2,
          children: [
            { ...menuItemRestoreDocument, label: 'Рабочие документы' },
            {
              label: 'Архив',
              key: DocumentAction.RestoreArchive,
              onClick: () =>
                dispatch(
                  documentUpdateRoutine({
                    data: { id: document.ID, values: { Deleted: 0, Archive: 1 } },
                    action: DocumentAction.RestoreArchive
                  })
                )
            }
          ]
        },
        {
          label: 'Удалить безвозвратно',
          key: 3,
          onClick: () =>
            ILSUserNotifyConfirm('Вы уверены, что хотите удалить документ без возможности восстановления?', undefined, () =>
              dispatch(documentDeleteFromTrashRoutine({ id: document.ID }))
            )
        },
        menuItemDownload
      ];
    }

    switch (type) {
      case DocumentType.Deal:
        return [
          menuItemView,
          {
            label: 'Сделку сохранить как шаблон сделки',
            key: DocumentAction.SaveAsTemplate,
            onClick: () => dispatch(saveAsTemplateRoutine({ id: document.ID }))
          },
          menuItemSendToArchive,
          menuItemDelete,
          menuItemDownload,
          menuItemSend
        ];
      case DocumentType.Contract:
      case DocumentType.ContractAnnex:
      case DocumentType.LadingBill:
      case DocumentType.RouteList:
        return [menuItemView, menuItemSendToArchive, menuItemDelete, menuItemDownload, menuItemSend];
      case DocumentType.Custom:
        return [{ ...menuItemView, label: 'Открыть' }, menuItemDelete, menuItemDownload, menuItemSend];
    }
  };
};

