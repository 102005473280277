import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { WritableDraft } from 'immer/dist/internal';
import { isEmpty } from 'lodash';
import { updateViewedTableSensorData } from './update-view-data';

// Функция для обновления просмотренных событий в таблице БДД
export const updateViewBDD = (
  bddData: WritableDraft<MonitoringBDDAndFuelDrainModel>[] | IndexedArray<WritableDraft<MonitoringBDDAndFuelDrainModel>>,
  DependentKey: Array<string> | string,
  Value: 0 | 1
) => {
  if (isEmpty(bddData) ) return;
  for (const vehicleIndex in bddData) {
    const vehicle = bddData[vehicleIndex];

    if (isEmpty(vehicle?.VehicleTracker)) {
      continue;
    }

    for (const trackerIndex in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerIndex];

      if (isEmpty(tracker?.SensorMapping)) {
        continue;
      }

      for (const sensorMappingIndex in tracker.SensorMapping) {
        const sensorData = tracker.SensorMapping[sensorMappingIndex];

        if (isEmpty(sensorData?.SensorValue)) {
          continue;
        }
        sensorData.SensorValue = updateViewedTableSensorData(sensorData.SensorValue, DependentKey, Value);
      }
    }
  }
};
