import { createReducer } from '@reduxjs/toolkit';
import { changeExpeditorInRouteRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { ChangeExpeditorInRoutePayloadAction } from '@modules/planning/children/by-date/sagas/route/change-expeditor-in-route';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';

export const changeExpeditorInRouteReducer = createReducer(initialState, {
  [changeExpeditorInRouteRoutine.SUCCESS]: (state, action: ChangeExpeditorInRoutePayloadAction) => {
    const { expeditorID, vehicleID } = action.payload;
    const ExpeditorID = [expeditorID];
    const ExpeditorEnum = {
      [expeditorID]:
        createArrayFromIndexedArray(state.project?.Driver).find(({ ID }) => compareAsString(ID, expeditorID))?.Name ??
        `Экспедитор №${expeditorID}`
    };
    state.currentPlan.RouteTotal[vehicleID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].ExpeditorID = ExpeditorID;
    state.currentPlan.RouteTotal[vehicleID].ExpeditorEnum = ExpeditorEnum;

    for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].ExpeditorID = ExpeditorID;
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].ExpeditorEnum = ExpeditorEnum;
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
    }
    return state;
  },
  [changeExpeditorInRouteRoutine.REQUEST]: onRequest,
  [changeExpeditorInRouteRoutine.FAILURE]: onFailure,
  [changeExpeditorInRouteRoutine.FULFILL]: onFulfill
});
