import { Dictionary, TableRecord } from '@common/types';
import { EDITABLE_PLAN_STATUSES, HIGHLIGHTED_RECORDS } from '@common/constants/custom/statuses';
import { compareAsString } from '@common/utils';
import { MutableRefObject } from 'react';

//TODO проверить tripStatus и TripID в selectRecord
export function highlightDraggableRow(record: TableRecord | undefined, selectRecord: MutableRefObject<TableRecord | undefined>) {
  if (compareAsString(selectRecord.current?.key, record?.key)) return false;
  // @ts-ignore
  const condition = EDITABLE_PLAN_STATUSES.includes(selectRecord.current?.tripStatus?.toString());
  return (
    (HIGHLIGHTED_RECORDS.includes(record?.dictionary || Dictionary.Empty) &&
      condition &&
      // @ts-ignore
      compareAsString(selectRecord.current?.TripID, record?.TripID)) ||
    condition
  );
}
