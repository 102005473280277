import { FC } from 'react';
import { ILSDropdownButton } from '@components/index';
import { ILSDropdownCellButtonProps } from '@components/general/buttons/types';

export const ILSDropdownCellButton: FC<ILSDropdownCellButtonProps> = ({ record, dataIndex }) => {
  if (!dataIndex || !record[dataIndex]) return null;
  const { items, dropdown, button, icon } = record[dataIndex];

  return (
    <ILSDropdownButton
      items={items}
      button={{
        icon: icon && <img alt="icon" className="svg-container" src={icon as string} />,
        ...button
      }}
      dropdown={{ openClassName: 'keep-open', ...dropdown }}
    />
  );
};
