import { Dictionary } from '@common/types';
import { CATALOG_SWAPED_DICTIONARY, CATALOG_SWAPED_ORIGINAL_DICTIONARY } from '../constants/catalogs';

/**
 * Меняет dictionary на dictionary который будет использоваться для работы с api
 * @param dictionary
 * @returns
 */
export const swapToOriginalDictionary = (dictionary: Dictionary): Dictionary => {
  if (CATALOG_SWAPED_DICTIONARY.includes(dictionary)) {
    return CATALOG_SWAPED_ORIGINAL_DICTIONARY?.[dictionary] || dictionary;
  }
  return dictionary;
};
