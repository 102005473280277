import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanID, Project } from '@common/types';
import { DictionaryAPI } from '@common/api/common';
import { copyOrdersToProjectRoutine } from '@modules/planning/children/by-date/actions';

function* copyOrdersToProjectWorker(action: PayloadAction<{ planID: PlanID; project: Project }>) {
  const { request, success, failure } = copyOrdersToProjectRoutine;
  const { planID, project } = action.payload;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(DictionaryAPI.copyOrdersToProject, { planIds: [planID], toProject: project.ID });

    const message = `Проект ${project.Name} №${project.ID} создан, ${data}`;

    yield put(success({ message, project }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* copyOrdersToProjectWatcher() {
  yield takeLatest(copyOrdersToProjectRoutine.trigger, copyOrdersToProjectWorker);
}
