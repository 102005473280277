import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { setTableConfig } from '@modules/documents/children/templates/constants/table-config';
import TemplatesModal from '@modules/documents/children/templates/components/templates-modal/templates-modal';
import { ILSSkeleton, ILSTable } from '@common/components';
import { addOptionsButtonTemplates } from '@modules/documents/helpers/table-options-button-templates';
import { withChildrenKeys } from '@modules/documents/helpers';
import { useChildrenMenuItems, useParentMenuItems } from '@modules/documents/children/templates/components/sets-table/use-menu-items';
import { documentTemplatesSelector } from '@modules/documents/children/templates/selectors';
import { getDocumentSetsRoutine, updateDealAttachRoutine, updateTemplateRoutine } from '@modules/documents/children/templates/actions';
import { Tabs } from '@modules/documents/children/templates/types/tabs';
import { IILSTableComponentProps, TableRecord } from '@common/types';

const ILSSetsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { documentSets, isFetching } = useAppSelector(documentTemplatesSelector);
  const generateParentMenuItems = useParentMenuItems();
  const generateChildrenMenuItems = useChildrenMenuItems();

  useEffect(() => {
    dispatch(getDocumentSetsRoutine());
  }, []);

  const setsWithButtons = addOptionsButtonTemplates(documentSets, generateParentMenuItems, generateChildrenMenuItems);

  const dataSource = withChildrenKeys(setsWithButtons);
  const allSetsKeys = dataSource?.map(({ key }) => key);

  const handleSave: IILSTableComponentProps<TableRecord>['handleSave'] = (record, dataIndex, value) => {
    if (record.Type) {
      dispatch(updateTemplateRoutine({ templateId: record.ID, template: { Name: value }, isDeal: true }));
    } else {
      dispatch(updateDealAttachRoutine({ data: { id: record.ID, name: value }, isChangeName: true }));
    }
  };

  return (
    <>
      <ILSSkeleton loading={isFetching} active>
        <ILSTable
          handleSave={handleSave}
          //TODO
          dataSource={dataSource as any}
          config={setTableConfig}
          cellClassName={() => 'document-table-cell'}
          expandable={{
            defaultExpandedRowKeys: allSetsKeys
          }}
          tableName={Tabs.DEALS}
          scroll={{ x: 400, y: 500 }}
          className="editable-table"
        />
      </ILSSkeleton>
      <TemplatesModal />
    </>
  );
};

export default ILSSetsTable;
