import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { WidgetProps } from '../../types/container';
import classNames from 'classnames';
import useResize from '../../hooks/use-resize';
import EditableWidget from './editable-widget';
import { DEFAULT_WIDGET_TEXT, MAX_WIDGET_SIZE } from '../../constants';
import { WidgetSize } from '../../types/widget-position';
import { ILSAnalyticsWidgetParams } from './widget-params';
import { useListenWindowResize } from '@modules/analytics/common/hooks/use-listen-window-resize';
import { getContentSize } from '@modules/analytics/common/utils/widget/get-content-size';
import { AnalyticsWidgetBoardContext } from '@modules/analytics/common/context/widget-board-context';
import { useRefReady } from '@modules/analytics/common/hooks/use-ref-ready';
import { AnalyticsWidgetComponent } from '@modules/analytics/common/components/widget/widget-component';

const ILSWidgetWrapper: FC<WidgetProps & { editMode?: boolean; board?: HTMLElement | null; hideWidget: boolean }> = ({
  widget,
  hideWidget
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const [contentSize, setContentSize] = useState<WidgetSize>({ x: 0, y: 0 });
  const [isIntersect, setIsIntersect] = useState(false);
  const [text, setText] = useState(widget?.Text || DEFAULT_WIDGET_TEXT);
  const [isFixed, setIsFixed] = useState(false);

  const refReady = useRefReady();

  const {
    stretchable,
    editMode,
    widgetParams,
    handleChange = () => {},
    board,
    onRenderWidget = () => {},
    handleSetParams = () => {},
    checkWidgets = () => true,
    deleteBoardWidget = () => {},
    handleSelectBoard
  } = useContext(AnalyticsWidgetBoardContext);

  //событие  срабатывающее после окончания перетаскивания/изменения размера виджета на доске
  const onRelease = () => {
    if (isChanged) {
      const { top, left } = positionAndSize.position;
      const { x, y } = positionAndSize.size;
      if (
        !checkWidgets({
          ...widget,
          Position: { Top: top, Left: left },
          Size: { Width: x, Height: y }
        })
      ) {
        undo();
      }
    }
  };

  const onWidgetChanged = () => {
    const { top, left } = positionAndSize.position;
    const { x, y } = positionAndSize.size;
    handleChange({
      ...widget,
      Position: { Top: top, Left: left },
      Size: { Width: x, Height: y },
      Text: text
    });
  };

  const { undo, pointerDrag, pointerRight, pointerBottom, pointerLeft, pointerTop, positionAndSize, isChanged } = useResize({
    widgetPosition: { left: widget.Position?.Left || 0, top: widget.Position?.Top || 0 },
    widgetSize: { x: widget.Size?.Width || 0, y: widget.Size?.Height || 0 },
    ref: ref,
    parent: board,
    onRelease,
    isFixed
  });

  const currentWidgetParams = useMemo(() => {
    return Object.values(widget?.Params || {}).filter((x) => x.IsWidgetParam);
  }, [widget]);

  const hasParams = useMemo(() => {
    return currentWidgetParams.length > 0;
  }, [currentWidgetParams]);

  useEffect(() => {
    if (isChanged) {
      const { top, left } = positionAndSize.position;
      const { x, y } = positionAndSize.size;
      const intersect = checkWidgets({
        ...widget,
        Position: { Top: top, Left: left },
        Size: { Width: x, Height: y }
      });
      setIsIntersect(!intersect);
      onWidgetChanged();
    }
  }, [positionAndSize]);

  useEffect(() => {
    if (text !== widget?.Text) onWidgetChanged();
  }, [text]);

  //для растягиваемых досок получим фактическую высота контента виджета
  useEffect(() => {
    if (stretchable && !editMode && (widget.loaded || widget.error)) {
      getContentSize({
        element: componentRef.current,
        parentElement: board,
        condition: refReady,
        callBack: setContentSize
      });
    }
  }, [widget.loaded, widget.loading, refReady, getContentSize, componentRef]);

  //при изменении окна получим пересчитаем высоту контента виджета
  useListenWindowResize({
    callback: () => {
      getContentSize({ element: componentRef.current, parentElement: board, condition: refReady, callBack: setContentSize });
    },
    condition: !editMode && stretchable
  });

  //при изменении значения  высоты контента вызываем коллбэк доски для перестроения всех виджетов
  useEffect(() => {
    if (!editMode && stretchable) onRenderWidget(widget, contentSize);
  }, [contentSize]);

  if (editMode) {
    return (
      <EditableWidget
        widget={widget}
        pointerBottom={pointerBottom}
        pointerDrag={pointerDrag}
        pointerLeft={pointerLeft}
        pointerTop={pointerTop}
        pointerRight={pointerRight}
        deleteBoardWidget={deleteBoardWidget}
        ref={ref}
      >
        <AnalyticsWidgetComponent
          widget={widget}
          widgetParams={widgetParams}
          board={board}
          editMode={editMode}
          handleSetParams={handleSetParams}
          hideWidget={hideWidget}
          setIsFixed={setIsFixed}
          setText={setText}
          isIntersect={isIntersect}
          handleSelectBoard={handleSelectBoard}
        />
      </EditableWidget>
    );
  }

  return (
    <div
      style={{
        width: widget.Size?.Width ? `${widget.Size?.Width}%` : MAX_WIDGET_SIZE,
        height: widget.Size?.Height ? `${widget.Size?.Height}%` : MAX_WIDGET_SIZE,
        left: widget.Position?.Left ? `${widget.Position?.Left}%` : 0,
        top: widget.Position?.Top ? `${widget.Position?.Top}%` : 0
      }}
      className={classNames('analytics-widget-wrapper', 'analytics-scroll', {
        'analytics-widget-wrapper-stretchable': stretchable,
        'analytics-widget-wrapper-loading': widget.loading
      })}
      ref={ref}
    >
      <div ref={componentRef}>
        <AnalyticsWidgetComponent
          widget={widget}
          widgetParams={widgetParams}
          board={board}
          editMode={editMode}
          handleSetParams={handleSetParams}
          hideWidget={hideWidget}
          setIsFixed={setIsFixed}
          setText={setText}
          isIntersect={isIntersect}
          handleSelectBoard={handleSelectBoard}
          hideName={hasParams}
          hideTableBorders={stretchable}
        >
          <>
            {(!hideWidget || widget.error) && (
              <div className="pt-2">
                <ILSAnalyticsWidgetParams
                  widgetParams={currentWidgetParams}
                  widget={widget}
                  handleSetParams={handleSetParams}
                  savedParams={widgetParams}
                />
              </div>
            )}
          </>
        </AnalyticsWidgetComponent>
      </div>
    </div>
  );
};

export default ILSWidgetWrapper;
