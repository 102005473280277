import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import { catalogUpdateRoutine } from '@modules/catalog/actions';
import { Dictionaries, SaveDictionaryType } from '@modules/catalog/types/catalog';
import initialState from '@modules/catalog/store';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';
import { replaceUpdatedItem } from '@common/utils/helpers/array/replace-updated-item';

const updateDictionaryReducer = createReducer(initialState, {
  [catalogUpdateRoutine.REQUEST]: onRequest,
  [catalogUpdateRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: Array<Dictionaries>;
      dictionary: Dictionary;
      saveDictionary: SaveDictionaryType;
    }>
  ) => {
    let { data, dictionary, saveDictionary } = action.payload;
    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;
    if (saveDictionary) {
      //для изменения записи в родительском справочнике
      state[dictionary].data = state[dictionary].data.map((item: Dictionaries) => {
        if (item.ID === saveDictionary.recordID) {
          data.forEach((updatedItem) => {
            item[saveDictionary.childDict] = replaceUpdatedItem(updatedItem, item[saveDictionary.childDict]);
          });
        }
        return item;
      });
    } else {
      data.forEach((updatedItem) => {
        state[dictionary].data = replaceUpdatedItem(updatedItem, state[dictionary].data);
      });
    }
    return state;
  },
  [catalogUpdateRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogUpdateRoutine.FULFILL]: onFulfill
});

export default updateDictionaryReducer;
