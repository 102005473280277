import { FC } from 'react';
import { DETAILS_CONTENT } from '@modules/orders-and-trips/types/details';
import { detailsRadioButtons } from '@modules/orders-and-trips/constants/general';
import { DetailsContentButton } from '@modules/orders-and-trips/components/details/details-content-button';

interface IProps {
  detailsContent: DETAILS_CONTENT;
  handleChangeDetailsComponent: (value: DETAILS_CONTENT) => void;
}

export const DetailsContentButtons: FC<IProps> = ({ detailsContent, handleChangeDetailsComponent }) => {
  return (
    <div className="details-content-buttons">
      {detailsRadioButtons.map((button) => (
        <DetailsContentButton
          button={button}
          detailsContent={detailsContent}
          handleChangeDetailsComponent={handleChangeDetailsComponent}
          key={button?.value}
        />
      ))}
    </div>
  );
};

