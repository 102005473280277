import { createTripRoutine, planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanID } from '@common/types/dictionaries/plan';
import { Depot, DictionariesEndpoint, Dictionary, TableName, Vehicle } from '@common/types';
import { FocusNExpandRowsChangingType } from '../../types/tables-container';

export type CreateTripPayload = {
  planID: PlanID;
  vehicleID: Vehicle['ID'];
  startDepotID?: Depot['ID'];
  startDepot: boolean;
};
export type CreateTripAction = PayloadAction<CreateTripPayload>;

function* createTripWorker(action: CreateTripAction) {
  const { request, failure, success, fulfill } = createTripRoutine;
  const { planID, startDepotID, vehicleID, startDepot } = action.payload;

  try {
    yield put(request());
    const {
      data: {
        data: { trip }
      }
    } = yield call(PlanningAPI.createTrip, { planID, startDepotID, vehicleID, startDepot });
    const {
      data: { data: tripTotal }
    } = yield call(PlanningAPI.getTotal, { planID, tripID: trip.ID, dictionary: Dictionary.Trip });
    yield put(success({ tripTotal, vehicleID }));

    /** Меняем/фокусируемся активный trip */
    yield put(
      planningRememberFocusNExpandRowsRoutine({
        changingType: FocusNExpandRowsChangingType.CheckAndUpdate,
        tableName: TableName.RoutesTable,
        lastModifiedData: {
          vehicleID,
          tripIDs: [trip.ID],
          dictionary: Dictionary.Trip,
          operation: DictionariesEndpoint.Create
        }
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* createTripWatcher() {
  yield takeLatest(createTripRoutine.trigger, createTripWorker);
}
