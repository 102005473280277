import { defineModule } from '@core/index';
import { MainPage } from './main-page-container';
import { ModuleScope } from '@core/types';
import { ModulePath } from '@common/types';

export default defineModule({
  title: 'Главная',
  path: ModulePath.Dashboard,
  component: MainPage,
  useScope: [ModuleScope.Sider, ModuleScope.Header, ModuleScope.Breadcrumbs]
});
