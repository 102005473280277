import { useState } from 'react';
import { Dictionary } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { IVirtualBoxContainerProps } from '@modules/orders-and-trips/types/virtual-list';

type UseOrdersAndTripsFilter = {
  dataSource: IVirtualBoxContainerProps['dataSource'];
  dictionary: Dictionary.Trip | Dictionary.Order;
};

export const useOrdersAndTripsFilter = ({ dataSource, dictionary }: UseOrdersAndTripsFilter) => {
  const [filterID, setFilterID] = useState<string | number>(EMPTY_STRING);
  const handleChangeFilterID = (value: string | number) => {
    setFilterID(value ?? EMPTY_STRING);
  };

  const filteredDataSource = {
    ...dataSource,
    [dictionary]: [...dataSource[dictionary] ?? []]?.filter((singleData) => singleData.ID?.toString().includes(filterID.toString()))
  };

  return { filteredDataSource, filterID, handleChangeFilterID };
};

