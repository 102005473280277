import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { tenderGetLotTemplateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { LotTemplate } from '@modules/tender-platform/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const getLotTemplateTender = createReducer(initialState, {
  [tenderGetLotTemplateRoutine.REQUEST]: onRequest,
  [tenderGetLotTemplateRoutine.FAILURE]: onFailure,
  [tenderGetLotTemplateRoutine.FULFILL]: onFulfill,
  [tenderGetLotTemplateRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      data: LotTemplate;
    }>
  ) => {
    const { data } = action.payload;
    state.templateList = data;
    return state;
  }
});
