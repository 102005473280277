import { call, put, takeLatest } from 'redux-saga/effects';
import { tenderCreateRoutine, tenderLotCreateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { formToSaveData } from '@modules/tender-platform/utils/decorators/form-to-save-data';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { Tender } from '@modules/tender-platform/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { tenderDocumentUploadRoutine } from '@modules/tender-platform/actions';

function* tenderCreateWorker(action: PayloadAction<{ values: Record<TenderFormField, any>; url: string }>) {
  const { request, success, failure, fulfill } = tenderCreateRoutine;
  const { values, url } = action.payload;
  let tenderID: Tender['ID'] | null = null;
  try {
    yield put(request());

    const { tender, lot, documents } = formToSaveData(values);

    const {
      data: { data }
    } = yield call(TenderOwn.create, { values: [tender] });

    tenderID = createArrayFromIndexedArray<Tender>(data)[0]?.ID;

    if (tenderID) {
      if (documents) {
        yield put(tenderDocumentUploadRoutine.trigger({ tenderID, attachment: documents }));
      }
      yield put(tenderLotCreateRoutine.trigger({ tenderID, values: [lot], url }));
      yield put(success({ tender }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderCreateWatcher() {
  yield takeLatest(tenderCreateRoutine.trigger, tenderCreateWorker);
}

