import { CellType, TableConfig } from '@common/types';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: 'IMEI Трекера',
      dataIndex: 'IMEI',
      key: 'IMEI',
      active: true,
      type: CellType.Input,
      width: 150,

      ellipsis: true,
      editable: true,
      required: false,
      sorting: 3
    },
    {
      title: 'Серийный номер',
      dataIndex: 'SerialNo',
      key: 'SerialNo',
      active: true,
      type: CellType.Input,
      width: 150,

      ellipsis: true,

      editable: true
    },
    {
      title: 'Номер сим-карты',
      dataIndex: 'SimNumber',
      key: 'SimNumber',
      active: true,
      type: CellType.Input,
      width: 150,

      ellipsis: true,

      editable: true
    },
    {
      title: 'Тариф сим-карты',
      dataIndex: 'SimTariff',
      key: 'SimTariff',
      active: true,
      type: CellType.Input,
      width: 150,

      ellipsis: true,

      editable: true
    }
  ],
  adding: true,
  deleting: true,
  reloading: true,
  selecting: false,
  copying: false,
  externalFocusControl: true
};
