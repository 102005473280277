import { FC } from 'react';
import { PaymentModalType } from '@common/feature/payment/types/general';
import { PAYMENT_MODAL_TITLES } from '@common/feature/payment/constants/general';
import { InvoiceFormContainer } from '@common/feature/payment/containers/invoice-form-container';
import { InvoiceViewerContainer } from '@common/feature/payment/containers/invoice-viewer-container';
import { PaymentBalanceHistoryContainer } from '@common/feature/payment/containers/balance-history-container';

interface IProps {
  modalType: PaymentModalType | null;
  handleShowModal(): void;
  openInvoiceViewer(): void;
}

export const renderPaymentModalContent: FC<IProps> = ({ modalType, handleShowModal, openInvoiceViewer }) => {
  switch (modalType) {
    case PaymentModalType.PayWithCard:
      return <p>Раздел "{PAYMENT_MODAL_TITLES[modalType]}" в разработке.</p>;

    case PaymentModalType.BalanceHistory:
      return <PaymentBalanceHistoryContainer />;

    case PaymentModalType.CreateInvoice:
      return <InvoiceFormContainer handleShowModal={handleShowModal} openInvoiceViewer={openInvoiceViewer} />;

    case PaymentModalType.InvoiceViewer:
      return <InvoiceViewerContainer />;

    default:
      return <>Способ оплаты не найден</>;
  }
};

