import { FC } from 'react';
import classnames from 'classnames';

interface IProps {
  className?: string;
}

export const ILSExpandRow: FC<IProps> = ({ children, className, ...props }) => (
  <div className={classnames('expand-row', className)} {...props}>
    {children}
  </div>
);
