import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getUnresolvedOrdersRoutine } from '@modules/orders-and-trips/actions';
import { IndexedArray, Order } from '@common/types';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState, PaginationType } from '@modules/orders-and-trips/types/general';
import { compareAsString } from '@common/utils';

export const getUnresolvedOrdersReducer = createReducer(initialState, {
  [getUnresolvedOrdersRoutine.REQUEST]: (state: InitialState) => {
    state.orders.isFetching = true;
    return state;
  },
  [getUnresolvedOrdersRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: {
        data: IndexedArray<Order>;
        pager: Required<PaginationType>;
      };
      resetActiveRecord?: boolean;
    }>
  ) => {
    const { data, resetActiveRecord } = action.payload;
    const newOrders = data.data;
    state.orders = {
      ...state.orders,
      data: compareAsString(data?.pager.page, 1) ? newOrders : { ...state.orders.data, ...newOrders },
      pagination: data?.pager
    };
    if (resetActiveRecord) {
      state.orders.activeRecord = null;
    }
    return state;
  },
  [getUnresolvedOrdersRoutine.FAILURE]: onFailure,
  [getUnresolvedOrdersRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.orders.isFetching = false;
    return state;
  }
});

