import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { copyBoardRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';

export const copyBoardReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [copyBoardRoutine.REQUEST]: (state) => {
    state.Boards.adding = true;
    return state;
  },
  [copyBoardRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsBoardModel>;
    }>
  ) => {
    const { data } = action.payload;

    if (data && state.Boards?.data) {
      state.Boards.data = { ...state.Boards?.data, ...data };
    }

    return state;
  },
  [copyBoardRoutine.FAILURE]: (state) => {
    return state;
  },
  [copyBoardRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.Boards.adding = false;
    return state;
  }
});
