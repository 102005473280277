import { isEmpty, NumericDictionary, pickBy } from 'lodash';
import { IndexedArray, Restriction } from '@common/types';

export const pickByRestrictionId = <R extends { RestrictionID?: Array<Restriction['ID']> }>(
  modelsWithRestriction?: IndexedArray<R> | Array<R> | NumericDictionary<R> | null,
  restrictionIDs?: Array<Restriction['ID']> | null
): IndexedArray<R> => {
  return pickBy(modelsWithRestriction, (modelWithRestriction) => {
    return isEmpty(restrictionIDs) || restrictionIDs?.some((RestrictionID) => modelWithRestriction.RestrictionID?.includes(RestrictionID));
  }) as IndexedArray<R>;
};
