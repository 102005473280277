import { IEnum, IndexedArray, Order } from '@common/types';
import { DepotShort } from '@common/types/dictionaries/depot';
import { DecoratedTrips, DraftTripNOrder, TripDocumentsType, TripStatus, TripTimelinesType } from '@modules/orders-and-trips/types/trip';
import { CARGO } from '@modules/orders-and-trips/constants/general';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { DecoratedIntegrationServices, PreCost } from '@modules/orders-and-trips/types/integrations';
import { ButtonTypes } from '@common/types/components';
import { ContractTemplateType } from '@modules/orders-and-trips/types/contract-template';
import { FakePreCostRequest, FakePreCostResponse, FakeTripContractRequest } from '@modules/orders-and-trips/types/fake-pre-cost';

export interface InitialState {
  isFetching: boolean;
  error: Array<any>;
  extraData: {
    isFetching: boolean;
    depot: DepotShort | null;
    restriction: IEnum<string> | null;
    vehicleType: VehicleType | null;
    countTripsByStatus: null;
  };
  orders: {
    isFetching: boolean;
    activeRecord: ActiveRecord;
    data: IndexedArray<Order>;
    pagination: Required<PaginationType>;
    total: number;
  };
  trips: {
    isFetching: boolean;
    activeRecord: ActiveRecord;
    tripStatus: TripStatus;
    tripsCount: IEnum<string> | null;
    data: DecoratedTrips;
    pagination: Required<PaginationType>;
    routes: Record<number, string>;
    updateTrips: null | TripStatus;
    total: number;
  };
  transport: {
    own: {};
    external: {};
  };
  availableIntegrations: {
    isFetching: boolean;
    data: DecoratedIntegrationServices | null;
    preCost: PreCost;
    fakePreCost: FakePreCostResponse;
    fakePreCostValues: (FakePreCostRequest | FakeTripContractRequest) | {};
  };
  filters: {};
  showForm: FormType | null;
  tripDocuments: {
    isFetching: boolean;
    documents: TripDocumentsType | null;
  };
  tripTimelines: {
    isFetching: boolean;
    timelines: TripTimelinesType | null;
  };
  serviceData: {
    isFetching: boolean;
    form: Partial<Record<FormType, DraftTripNOrder>>;
  };
  activeTab: {
    leftBlock: MainTabs;
  };
  tripContract: {
    contractTemplate: ContractTemplateType;
    isFetching: boolean;
  };
  activeModalWindow: ModalWindowContainerType | null;
}

export type ActiveRecord = { key?: string | number; ID?: string | number } | null;

export enum CollapsePanels {
  Filters = 'filters',
  Switches = 'Switches',
  Unresolved = 'unresolved'
}

export enum RightMenuMainTabs {
  SearchPerformer = 'SearchPerformer',
  Details = 'Details',
  Documents = 'Documents',
  Services = 'Services',
  Payment = 'Payment',
  Insurance = 'Insurance'
}

export enum TransportType {
  MyTransport = 'MyTransport',
  TransportCompany = 'TransportCompany',
  TenderTransport = 'TenderTransport'
}

export enum FormType {
  Trip = 'Trip',
  CreateOrder = 'CreateOrder',
  UpdateOrder = 'UpdateOrder'
}

export enum RecordType {
  Trip = 'trip',
  Order = 'order',
  All = 'all'
}

export type CargoItem = typeof CARGO;

export type PaginationType = {
  page: number;
  perPage?: number;
  total?: number;
};

export interface IActionButtonProps {
  click: () => void | null;
  disabled?: boolean;
  text: string;
  tooltip?: boolean;
  className?: string;
  title?: string;
  form?: string;
  isFetching?: boolean;
  type?: 'text' | 'link' | 'default' | 'ghost' | 'primary' | 'dashed' | undefined;
}

export interface IFormSubmitButtonsType extends Omit<IActionButtonProps, 'click'> {
  ghost?: boolean;
  htmlType?: string;
  click?: ButtonTypes | null;
  buttonType: ButtonTypes;
}

export enum MainTabs {
  Order = 'order',
  Trip = 'trip',
  NewOrder = 'newOrder',
  MyApplications = 'myApplications'
}

export enum MainTabsContainer {
  leftBlock = 'leftBlock',
  rightBlock = 'rightBlock'
}

export enum ModalWindowContainerType {
  ImportExport = 'ImportExport'
}

export enum ModalWindowTitles {
  ImportExport = 'Импорт заявок'
}
