import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { onFailure } from '@common/utils/helpers/reducers';
import { moveSchemeKeysRoutine } from '@modules/planning/children/scheme-editor/actions';
import { RecordType, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { Dictionary } from '@common/types';

export const moveSchemeKeysReducer = createReducer(INITIAL_STATE, {
  [moveSchemeKeysRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ newCurrentKeys: Array<RecordType['title']>; newKey?: RecordType['title']; dictionary: SchemeSwitchDictionary }>
  ) => {
    const { newCurrentKeys, newKey, dictionary } = action.payload;
    if (dictionary !== Dictionary.Options) {
      if (newKey) {
        //dictionaryExistInCurrentScheme && newKeyFromMaxScheme
        if (state.currentScheme?.[dictionary] && state.maxScheme?.[dictionary]?.[newKey]) {
          state.currentScheme[dictionary] = {
            ...state.currentScheme[dictionary],
            [newKey]: state.maxScheme[dictionary][newKey]
          };
          state.currentSchemeKeysInDictionaries[dictionary] = newCurrentKeys;
        } else {
          if (state.currentScheme && state.maxScheme?.[dictionary]?.[newKey]) {
            state.currentScheme[dictionary] = {
              [newKey]: state.maxScheme[dictionary][newKey]
            };
            state.currentSchemeKeysInDictionaries[dictionary] = newCurrentKeys;
          }
        }
      } else {
        state.currentSchemeKeysInDictionaries[dictionary] = newCurrentKeys;
      }
    }
    return state;
  },
  [moveSchemeKeysRoutine.FAILURE]: onFailure
});
