import { ILSMenu, ILSMenuItem, ILSPopover, ILSSubMenu } from '@components/index';
import { Link } from 'react-router-dom';
import { FC, MouseEvent, useState } from 'react';
import { generateUUID } from '@common/utils/general/uuid';

interface IILSPCML {
  menu: Array<any>;
  onClick(e: MouseEvent, b1?: boolean, b2?: boolean): void;
}

export const ILSPlanContextMenuList: FC<IILSPCML> = ({ menu, onClick }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [loadSubmenu, setLoadSubMenu] = useState(false);

  const addMenuItem = (item: any, handler: any, uuid: any, text: string = '') => {
    return (
      <ILSMenuItem
        key={uuid()}
        onClick={() => {
          handler
            ? onClick(
                {
                  ...item,
                  handler
                },
                false,
                true
              )
            : onClick(item);
        }}
        className={`${item.repeated ? 'repeated-element' : ''}`}
      >
        {text || item.text || item.name}
      </ILSMenuItem>
    );
  };

  const addSubMenu = (subItem: any, handler: any, uuid: any) => {
    return (
      <ILSSubMenu title={subItem.text} key={uuid()} popupClassName="planning-sub-menu">
        {subItem.childMenu.map((childItem: any) => {
          return addMenuItem(childItem, handler, uuid);
        })}
      </ILSSubMenu>
    );
  };

  const onLoadSubmenu = () => {
    setLoadSubMenu((loadSubmenu) => {
      if (loadSubmenu) return loadSubmenu;
      return !loadSubmenu;
    });
  };

  return (
    <>
      {menu.filter(Boolean).map((list) => {
        return (
          <ILSMenu key={generateUUID()} className={'menu'} triggerSubMenuAction={'click'} onMouseOver={onLoadSubmenu}>
            {list?.filter(Boolean).map((item: any) => {
              if (item) {
                let text = item.text;
                if (item.link) {
                  text = (
                    <Link to={item.link} target="_blank">
                      {item.text}
                    </Link>
                  );
                }
                if (item.popover) {
                  text = (
                    <ILSPopover
                      content={item.popover}
                      title={item.title}
                      trigger="click"
                      visible={popoverVisible}
                      onVisibleChange={() => setPopoverVisible(!popoverVisible)}
                    >
                      {item.text}
                    </ILSPopover>
                  );
                }
                return item.submenu ? (
                  <ILSSubMenu
                    data-title="Кликните на выпадающий список"
                    title={item.text}
                    key={generateUUID()}
                    popupClassName="planning-sub-menu"
                  >
                    {item.submenu.map((subItem: any) => {
                      return !subItem.childMenu
                        ? addMenuItem(subItem, item.handler, generateUUID)
                        : //NOTE: loadSubmenu - загружаем subMenu только после того, как загрузится сам контейнер
                        !loadSubmenu
                        ? null
                        : addSubMenu(subItem, item.handler, generateUUID);
                    })}
                  </ILSSubMenu>
                ) : (
                  addMenuItem(item, null, generateUUID, text)
                );
              }
            })}
          </ILSMenu>
        );
      })}
    </>
  );
};
