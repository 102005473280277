import { EMPTY_ARRAY_DATA } from '@common/constants';
import { useCheckboxGroup } from './use-checkbox-group';
import { ColumnTable, TableRecord } from '@common/types';

interface IProps {
  storedTableGrouping?: ColumnTable<TableRecord>['dataIndex'][];
  onGrouping?: (colsForGrouping: ColumnTable<TableRecord>['dataIndex'][]) => void;
  closeGroupingPopover: () => void;
}

export const useGroupingSorting = ({ storedTableGrouping, onGrouping, closeGroupingPopover }: IProps) => {
  const { checkboxGroupValues, handleChangeCheckboxValues, handleResetCheckboxValues } = useCheckboxGroup(storedTableGrouping);

  const isResetButtonEnabled = checkboxGroupValues?.some((checkbox) => checkbox);

  const handleAcceptGrouping = () => {
    onGrouping?.(checkboxGroupValues as ColumnTable<TableRecord>['dataIndex'][]);
    closeGroupingPopover();
  };

  const handleResetGrouping = () => {
    onGrouping?.(EMPTY_ARRAY_DATA);
    closeGroupingPopover();
    handleResetCheckboxValues();
  };

  const handleCancelGrouping = () => {
    handleChangeCheckboxValues?.(storedTableGrouping || EMPTY_ARRAY_DATA);
    closeGroupingPopover();
  };

  return {
    checkboxGroupValues,
    isResetButtonEnabled,
    handleChangeCheckboxValues,
    handleAcceptGrouping,
    handleResetGrouping,
    handleCancelGrouping
  };
};
