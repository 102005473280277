import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsStore } from '../types/store';
import { AnalyticsActions } from '../types/actions';
import initialState from '../store';
import { AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';

export const getBoardSetsCommonReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonGetBoardSetsRoutine.REQUEST]: (state) => {
      state.BoardSets.isFetching = true;
      state.BoardSets.selectedBoardSet = undefined;
      state.Boards.selectedBoard = undefined;
      state.Boards.isFetching = true;
      state.WidgetParams = {};
      state.Widgets = {};
      return state;
    },
    [actions.analyticsCommonGetBoardSetsRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        data: IndexedArray<AnalyticsBoardSetModel>;
      }>
    ) => {
      const { data } = action.payload;
      state.BoardSets.data = data;
      return state;
    },
    [actions.analyticsCommonGetBoardSetsRoutine.FAILURE]: (state) => {
      state.Boards.isFetching = false;
      return state;
    },
    [actions.analyticsCommonGetBoardSetsRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
      state.BoardSets.isFetching = false;
      return state;
    }
  });
