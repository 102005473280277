import { handleCell } from '@components/data-display/table/utils/helpers/cells/on-cell';
import { DEFAULT_FILTER_SEARCH_STATE, NOT_ELLIPSIS_COLUMN, NOT_SORTER_CELL_TYPES } from '@components/data-display/table/constants';
import { filter } from '@components/data-display/table/utils';
import { InputRef } from 'antd';
import { ColumnTable, ReferenceTableData, TableRecord } from '@common/types';
import { useRef, useState } from 'react';
import { shouldCellUpdateFactory } from '@components/data-display/table/utils/helpers/should-cell-update-factory';
import { compareAsString } from '@common/utils';
import { getCellOptions, getColumnOptions } from '@components/data-display/table/utils/helpers/column/get-column-options';
import { UseColumns } from '@common/types/components/table/hooks';
import { comparator } from '@components/data-display/table/utils/helpers/cells/comparator';
import { isCellUniqOptions } from '@components/data-display/table/utils/helpers/cells/is-cell-uniq-options';
import { FilterState } from '@common/types/components/table/common';
import { getColumnWidth } from '@components/data-display/table/utils/helpers/column/get-column-width';
import { getColumnTitle } from '@components/data-display/table/utils/helpers/column/get-column-title';

export const useColumns = <R extends TableRecord>({
  columns,
  config,
  dictionary,
  dictionaryLoad,
  dictionaryInfo,
  cellClassName,
  onResizeStart,
  dragInfo,
  handleSave,
  refTable,
  tableRef,
  dontEdit,
  onCanEdit,
  errors,
  setShowReference,
  activeCell,
  refData,
  sorted,
  dataSource,
  filtered
}: UseColumns<R>) => {
  const filterInput = useRef();
  const [filterState, setFilterState] = useState<FilterState>(() => DEFAULT_FILTER_SEARCH_STATE);

  return ((columns ?? config?.columns ?? []) as ColumnTable<R>[])?.reduce((columns: ColumnTable<R>[], column) => {
    if (!column.invisible && (column.active || typeof column.active === 'undefined')) {
      const referenceData = refData?.[column.dataIndex] as ReferenceTableData;
      const fieldNames = referenceData?.fieldNames ?? column.fieldNames;
      const columnOptions = isCellUniqOptions(referenceData?.options)
        ? undefined
        : getColumnOptions({
            variants: referenceData?.options ?? column.options,
            fieldNames,
            needSortByLabelField: column.needSortByLabelField
          });
      const compare = (firstRecord: R, secondRecord: R) =>
        comparator({ firstRecord, secondRecord, column, options: columnOptions, dictionary });

      const onCell = (record: R, rowIndex: number | undefined) => {
        const cellOptions = isCellUniqOptions(referenceData?.options)
          ? getCellOptions({
              getOptions: referenceData.options,
              fieldNames,
              record,
              needSortByLabelField: column.needSortByLabelField
            })
          : undefined;

        return handleCell({
          record,
          rowIndex,
          cellClassName,
          dontEdit,
          onCanEdit,
          handleSave,
          refTable,
          errors,
          column,
          referenceData,
          dragInfo,
          setShowReference,
          activeCell,
          tableRef,
          dictionaryLoad,
          dictionaryInfo,
          options: cellOptions ?? columnOptions,
          fieldNames
        });
      };

      columns.push({
        onCell,
        type: column.type,
        editable: column.editable,
        showRefButton: column.showRefButton,
        align: column.align,
        dataIndex: column.dataIndex,
        width: getColumnWidth({
          width: column.width,
          type: column.type,
          title: column.title,
          style: column.style
        }),
        ellipsis: NOT_ELLIPSIS_COLUMN.includes(column.type) ? undefined : column.ellipsis,
        title: getColumnTitle({
          column,
          config,
          dictionary,
          onResizeStart,
          sorted,
          dataSource,
          tableRef
        }),
        sorter: NOT_SORTER_CELL_TYPES.includes(column.type)
          ? false
          : {
              compare,
              multiple: dataSource?.length
            },
        //@ts-ignore TODO
        sortOrder: compareAsString(sorted?.field, column.dataIndex) ? sorted?.order : null,
        ...filter(filtered, column, refData, filterState, setFilterState, filterInput as unknown as InputRef, dataSource),
        shouldCellUpdate: shouldCellUpdateFactory(column, config, dragInfo),
        fixed: column.fixed,
        showParent: column.showParent
      });
    }
    return columns;
  }, []);
};
