import { setActiveProjectIdRoutine } from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Project } from '@common/types';

function* planningSetActiveProjectIdWorker(action: PayloadAction<{ activeProjectID: Project['ID']; projectName: Project['Name'] }>) {
  const { success, failure, fulfill } = setActiveProjectIdRoutine;
  const { activeProjectID, projectName } = action.payload;

  try {
    const message = `Вы переключились на проект: ${projectName} (№${activeProjectID})`;
    yield put(success({ activeProjectID, message }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningSetActiveProjectIdWatcher() {
  yield takeLatest(setActiveProjectIdRoutine.trigger, planningSetActiveProjectIdWorker);
}
