import { PlannerDataActionType } from '@modules/planning/children/data/actions';

export const DEFAULT_NOTIFY_PLANNER_DATA_CONTENT: {
  [key in PlannerDataActionType]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  [PlannerDataActionType.CLEAR]: { loading: 'Очистка данных...', success: 'Данные очищены' },
  [PlannerDataActionType.GET_PROJECTS]: { loading: 'Поиск проектов...', success: 'Загружено' },
  [PlannerDataActionType.UPLOAD]: { loading: 'Загрузка данных...', success: 'Данные загружены' },
  [PlannerDataActionType.GET_UPLOAD_TEMPLATE]: { loading: 'Подготовка шаблонов...', success: 'Шаблоны подготовлены' },
  [PlannerDataActionType.UPLOAD_CONFLICT]: { loading: 'Идёт проверка наличия данных...', success: 'Проверка наличия данных завершена' },
  [PlannerDataActionType.TABLE_CHANGE]: { loading: 'Сохранение изменений...', success: 'Изменения сохранены' },
  [PlannerDataActionType.GET_UPLOADED_DATA]: { loading: 'Проверка ранее загруженных файлов...', success: 'Загружено' },
  [PlannerDataActionType.GET_PLANNING_CONFIGS]: { loading: 'Загрузка списка конфигураций...', success: 'Конфигурации загружены' },
  [PlannerDataActionType.IMPORT]: {
    loading: 'Импорт данных...',
    success: 'Данные успешно импортированы, загрузите новые или переходите к планированию'
  },
  [PlannerDataActionType.UPLOAD_TXT]: { loading: 'Загрузка данных...', success: 'Данные загружены' },
  [PlannerDataActionType.VALIDATE]: { loading: 'Валидация данных...', success: 'Данные о валидации загружены' }
};

export const PLANNER_DATA_CONTENT = {
  UPLOAD_ORDER_INFO_NOTIFY: 'Точки погрузки/Точки разгрузки из файла "Заявки" будут автоматически добавлены в справочник без координат'
};
