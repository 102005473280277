import { FC } from 'react';
import { Moment } from 'moment';
import { PlanningAvailableDates } from '@modules/planning/types';
import { ProjectSelectValues } from '@modules/planning/children/data/types';
import { ILSDatePicker, ILSFormItem } from '@common/components';
import { renderAvailableDates } from '@common/components/data-entry/datepicker/render-available-dates';

interface IProps {
  values: ProjectSelectValues;
  availableDates: PlanningAvailableDates;
  isLoading: boolean;
  onPanelChange: (e: Moment) => void;
  onSelect: (date: Moment | null) => void;
}

export const PlannerDataDatePickerForm: FC<IProps> = ({ values, availableDates, isLoading, onPanelChange, onSelect }) => {
  const handleStartDateRender = (current: Moment) => {
    return renderAvailableDates(current, availableDates);
  };
  const handlePanelChange = () => onPanelChange(values.Start);

  return (
    <ILSFormItem name={'Start'}>
      <ILSDatePicker
        dateRender={handleStartDateRender}
        className="date"
        isLoading={isLoading}
        disabled={isLoading}
        onPanelChange={onPanelChange}
        onOpenChange={handlePanelChange}
        onSelect={onSelect}
      />
    </ILSFormItem>
  );
};

