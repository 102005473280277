import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { waypointMarker } from '@core/components/map/icons';
import homeMarker from '@core/components/map/icons/storage.svg';
import { ILSMarker } from '@core/containers/map';
import { LayerHandler } from '@core/types/map';
import { customMarkerWithNumber } from '@modules/monitoring/children/event-details/utils/map/helpers/custom-marker-with-number';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import { WPTipProps } from '../../types/utils';
import { waypointTooltip } from './tooltips';

// Утилита для создания маркеров точек
export const createWaypointMarker = (
  marker: {
    ID: string;
    coords: [number, number];
    tooltipProps: WPTipProps;
    EventStatusType: MonitoringWaypointEventStatusType;
    Number: string;
    isNotGarage: boolean;
  },
  handlers?: {
    [K: string]: LayerHandler<L.Marker>;
  }
) => {
  const popupT = waypointTooltip(marker.tooltipProps);
  if (!marker.isNotGarage) {
  }
  const icon = marker.isNotGarage
    ? waypointMarker(
        Object.values(MonitoringWaypointEventStatusType).includes(marker.EventStatusType)
          ? marker.EventStatusType
          : MonitoringWaypointEventStatusType.OnLate,
        marker.Number
      )
    : customMarkerWithNumber(homeMarker, marker.Number);
  return !isEmpty(marker.coords)
    ? new ILSMarker({
        coords: marker.coords,
        popup: [popupT],
        options: {
          icon,
          data: {
            Number: marker.Number,
            EventStatusType: marker.EventStatusType
          }
        },
        handlers
      })
    : undefined;
};

// Утилита создания иконки маркера точки
export const createMonitoringWPMarker = () => {
  return new L.DivIcon({
    iconSize: new L.Point(24, 24),
    className: 'my-div-icon',
    html: '<div class="marker-order ' + '"' + '" ' + 'id="marker_' + '">' + '</div>'
  });
};
