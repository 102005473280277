import { ILSButton, ILSForm, ILSFormItem, ILSInput, ILSInputPassword } from '@components/index';
import { ReactSVG } from 'react-svg';
import { LoginForm } from '../types/components';
import { FC } from 'react';

const ILSLoginFormComponent: FC<LoginForm> = (props) => {
  const { onFinish, isFetching } = props;

  return (
    <ILSForm layout={'vertical'} onFinish={onFinish} className="p-3">
      <ILSFormItem
        label="Логин"
        name="login"
        rules={[
          {
            required: true,
            message: 'Пожалуйста, введите логин'
          }
        ]}
      >
        <ILSInput />
      </ILSFormItem>

      <ILSFormItem
        label="Пароль"
        name="password"
        rules={[
          {
            required: true,
            message: 'Пожалуйста, введите пароль'
          }
        ]}
      >
        <ILSInputPassword />
      </ILSFormItem>

      <ILSFormItem>
        <ILSButton type="primary" htmlType="submit">
          {isFetching && <ReactSVG className="svg-container" src={'/assets/icons/loading.svg'} />}
          Войти
        </ILSButton>
      </ILSFormItem>
    </ILSForm>
  );
};

export default ILSLoginFormComponent;
