import { Keys } from '@common/types';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { CarOutlined } from '@ant-design/icons';

export const useOwnTransport = () => {
  const [selectedVehicle, setVehicle] = useState<Keys>([]);

  const onAddVehicle = () => {
    //TODO Реализовать логику добавления автомобиля
  };

  const onSelectRows = (newSelectRows: Keys) => {
    setVehicle(newSelectRows);
  };

  const recordTableButtons = [
    {
      Component: ActionButton,
      props: {
        text: 'Выбрать авто',
        click: () => onAddVehicle(),
        disabled: isEmpty(selectedVehicle),
        tooltip: true,
        title: !isEmpty(selectedVehicle) ? 'Нажмите, чтобы подтвердить' : 'Выберите автомобиль в таблице',
        icon: <CarOutlined />
      }
    }
  ];
  return { recordTableButtons, onSelectRows };
};

