import moment from 'moment';
import { DateTimeFormat } from '@common/types';
import { isEmpty, isNil, isObject } from 'lodash';
import { DocumentsFilterFields, DocumentsFilterFieldsEnum } from '@modules/documents/types/document';

export const prepareDocumentsPayload = (payload: DocumentsFilterFields) => {
  const preparedPayload = { ...payload };
  return Object.keys(preparedPayload).reduce((result, key) => {
    switch (true) {
      case moment.isMoment(preparedPayload[key]):
        let momentdata = preparedPayload[key];
        if (key == DocumentsFilterFieldsEnum.CreatedFrom) momentdata = moment(momentdata).startOf('day');
        if (key == DocumentsFilterFieldsEnum.CreatedTill) momentdata = moment(momentdata).endOf('day');
        result[key] = moment(momentdata).utc().format(DateTimeFormat.YYYYMMDDDashHHMMS);
        break;
      case isEmpty(preparedPayload[key]) && isObject(preparedPayload[key]):
      case isNil(preparedPayload[key]):
        return result;
      default:
        result[key] = preparedPayload[key];
    }
    return result;
  }, {});
};
