import { FC } from 'react';
import { ILSButton } from '@common/components';

interface IProps {
  isFetching: boolean;

  handleClick(join?: boolean): void;
}

export const ILSPlanningUploadModalButtons: FC<IProps> = ({ isFetching, handleClick }) => {
  return (
    <div className="flex-between-center" key="footer">
      <ILSButton
        loading={isFetching}
        disabled={isFetching}
        children={'Заменить данные'}
        key="back"
        type="primary"
        onClick={() => handleClick()}
      />
      <ILSButton
        loading={isFetching}
        disabled={isFetching}
        children={'Соединить данные'}
        key="submit"
        type="primary"
        className="success"
        onClick={() => handleClick(true)}
      />
    </div>
  );
};
