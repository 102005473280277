import { MonitoringAPI } from '@modules/monitoring/api';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { monitoringExportExelRoutine } from '../actions';
import { loadExelFile } from '../utils/load-file';

type DashboardRequest = MonitoringExelData & { fileName: string };

// Метод для экспорта эксель файла, отправляет на бэк - заголовки и строки (headers, rows)
function* monitoringExportExelWorker(action: PayloadAction<DashboardRequest>) {
  const { fileName, headers, rows } = action.payload;
  const { request, success, failure } = monitoringExportExelRoutine;

  try {
    yield put(request());
    yield delay(100);

    const { data } = yield call(MonitoringAPI.exportExel, { headers, rows });
    if (data.data) {
      loadExelFile(data.data, fileName);
    }
    yield put(success());
  } catch (error) {
    console.error(error);
    yield put(failure({ error }));
  }
}

export function* monitoringExportExelWatcher() {
  yield takeLatest(monitoringExportExelRoutine.trigger, monitoringExportExelWorker);
}
