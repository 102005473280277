import { ColumnTable, Dictionary, Notify, NotifyDurationInSecond, TableRecord } from '@common/types';
import { getColumnOptions } from '@components/data-display/table/utils/helpers/column/get-column-options';
import { compare } from '@components/data-display/table/utils';
import { ILSUserNotify } from '@common/components';

export const comparator = <R extends TableRecord>({
  firstRecord,
  secondRecord,
  column,
  options,
  dictionary
}: {
  firstRecord: R;
  secondRecord: R;
  column: ColumnTable<R>;
  options: ReturnType<typeof getColumnOptions>;
  dictionary?: Dictionary;
}): number => {
  try {
    return compare(firstRecord, secondRecord, String(column.dataIndex), column.type, options, dictionary);
  } catch (e) {
    ILSUserNotify(Notify.Warning, 'Функция сортировки для данной колонки в разработке', NotifyDurationInSecond.Two, 'sorting-error-key');
    return 0;
  }
};
