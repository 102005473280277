import { ILSTable } from '@common/components';
import { tableServedConfig, tableServedDateToTable } from '@common/table-configs/monitoring-dashboard/served';
import { ColumnTable, Dictionary, IndexedArray, RecordTableButton, TableColumnName } from '@common/types';
import { MonitoringServedDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { tableCustom } from '@core/containers/table-custom';
import { useAppSelector } from '@core/hooks';
import { monitoringVehicleTrackersSelector } from '@modules/monitoring/selectors/trackers';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { FC, memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { MonitoringVehicleTrackerTable } from '@modules/monitoring/components/tables/table-vehicle-tracker/table-vehicle-tracker';
import { addToTableExelButton } from '../../utils/exel-button';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import { useGetWaypointTimeZone } from '@modules/monitoring/hooks/use-get-timezone';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource: Array<MonitoringServedDataModel> | IndexedArray<MonitoringServedDataModel>;
  onContextOpen?: (record: MonitoringServedDataModel, e: MouseEvent) => void;
  columns: ColumnTable<MonitoringServedDataModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  isCurrentDay?: boolean;
}

// Контейнер главной таблицы «Обслужено»
export const MonitoringTableServed: FC<IProps> = ({ dataSource, onContextOpen, loadExel, recordTableButtons, isCurrentDay, ...props }) => {
  const dispatch = useDispatch();

  const vehicleTrackers = useAppSelector(monitoringVehicleTrackersSelector);
  const { getWpTimeZone } = useGetWaypointTimeZone();

  const history = useHistory();
  const { setFocusRow, focusRow } = useFocusRow();
  const { decoratedData, recordTableButtonsTable } = useMemo(() => {
    const decoratedData = tableServedDateToTable({ dataSource, dispatch, history, vehicleTrackers, isCurrentDay, getWpTimeZone });
    const recordTableButtonsTable = addToTableExelButton(recordTableButtons, loadExel, decoratedData, props.columns, Dictionary.Served);
    return { decoratedData, recordTableButtonsTable };
  }, [dataSource, vehicleTrackers, isCurrentDay, getWpTimeZone]);

  const refTable: any = {
    Tracker: (record: any) => ({
      title: `Привязка трекера на автомашину ${dataSource?.[record.VehicleID]?.Model || ''} гос.номер: ${
        dataSource?.[record.VehicleID]?.RegNumber || ''
      }`,
      component: MonitoringVehicleTrackerTable
    })
  };

  return (
    <MonitoringTableTopLayout className="served">
      <ILSTable
        {...props}
        focusRow={focusRow}
        virtual={false} //TODO отключена пока для ILSCellLineSeries не сделан механизм по обновлению transform в момент рендера
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtonsTable}
        config={tableServedConfig}
        dataSource={decoratedData}
        scroll={{ y: 'calc(100vh - 510px)' }}
        refTable={refTable}
        pagination={false}
        size={'small'}
        className="editable-table"
        cellClassName={(_record, _rowIndex, dataIndex) => {
          if (dataIndex === TableColumnName.Deliveries) {
            return 'ils-delivery-status-cell';
          }
          return '';
        }}
        bordered
        dictionary={Dictionary.Served}
        onContextOpen={onContextOpen}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringTableServed = tableCustom(memo(MonitoringTableServed), tableServedConfig, Dictionary.Served);
