import { FC } from 'react';
import { ChromePicker } from 'react-color';
import { ILSButton } from '@common/components';
import { ChromePickerProps } from 'react-color/lib/components/chrome/Chrome';

interface IProps extends ChromePickerProps {
  handleClose(): void;
}

export const ILSColorPicker: FC<IProps> = ({ color, handleClose, onChange, ...props }) => {
  return (
    <div className="color-picker">
      <div className="color-picker-cover" onClick={handleClose} />
      <div>
        <ChromePicker {...props} color={color} onChange={onChange} />
        <ILSButton type="primary" className="color-ok-btn" onClick={handleClose}>
          OK
        </ILSButton>
      </div>
    </div>
  );
};
