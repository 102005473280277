import { SECONDS_TO_DAYS_MULTIPLIER } from '@common/constants/general/date-time';
import { IndexedArray, VehicleTracker } from '@common/types';
import { MonitoringTrip, MonitoringTripServedDataModel } from '@common/types/dictionaries/monitoring-trip';
import { MonitoringServedDataModel, MonitoringVehicle } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringTripWaypointServedModel, MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToDateTimeStringWithUTC } from '@common/utils/helpers/date-time/date-time';
import { openVehicleDetails } from '@modules/monitoring/helpers/open-vehicle-details';
import { History } from 'history';
import { isArray, isEmpty, isNil, isObject } from 'lodash';
import { Dispatch } from 'redux';
import { getTextViewStatus } from '../../common/forward-status-view';
import { isShouldWaypointBeTaken } from '../../utils/waypoints';
import { TRIP_STATUSES } from '@common/constants/custom';
import { getTrackerDataForTable } from '@common/utils/tracker/get-tracker-data-for-table';

export type DataToTableFunction<R> = (arg: {
  dataSource: Array<MonitoringServedDataModel> | IndexedArray<MonitoringServedDataModel>;
  dispatch: Dispatch;
  history: History;
  vehicleTrackers?: VehicleTracker[];
  isCurrentDay?: boolean;
  getWpTimeZone: (waypointOffset: number | null | undefined) => number;
}) => Array<
  R & {
    key: string;
    Deliveries: Array<MonitoringTripWaypointServedModel> | null | undefined;
    Delivered: string;
    Tracker: string;
    VehicleTracker?: MonitoringVehicle['VehicleTracker'];
  }
>;

export const data2table: DataToTableFunction<MonitoringTripServedDataModel> = ({
  dataSource,
  dispatch,
  history,
  vehicleTrackers,
  isCurrentDay,
  getWpTimeZone
}) => {
  const res: ReturnType<typeof data2table> = [];
  const vehicleData = isObject(dataSource) && !isArray(dataSource) ? Object.values(dataSource) : dataSource;
  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (isNil(vehicle?.Trip) || isEmpty(vehicle.Trip)) {
      continue;
    }
    let tripNumber = 0;
    for (const tripIndex in vehicle.Trip) {
      tripNumber++;
      const trip: MonitoringTrip = vehicle.Trip[tripIndex];

      if (isEmpty(trip?.Waypoint)) {
        continue;
      }

      let servedWaypoints = 0;
      let allWaypoints = 0;

      for (const waypointIndex in trip.Waypoint) {
        const waypoint = trip.Waypoint[waypointIndex];

        if (!isShouldWaypointBeTaken(waypoint, vehicle)) continue;

        allWaypoints += 1;

        if (
          waypoint.EventStatusType === MonitoringWaypointEventStatusType.InTime ||
          waypoint.EventStatusType === MonitoringWaypointEventStatusType.OnLate
        ) {
          servedWaypoints += 1;
        }
      }

      const Tracker = getTrackerDataForTable({
        isCurrentDay,
        vehicleTrackers,
        vehicle
      });

      const Driver = trip?.Driver || vehicle?.Driver;

      const rowObj = {
        ...trip,
        Name: vehicle.Name,
        Phone: Driver?.Phone,
        DriverName: Driver?.Name,
        Delivered: `${servedWaypoints}/${allWaypoints}`,
        VehicleID: vehicle.ID,
        TripID: trip.ID, //NOTE для работы с колбеками
        tripStatus: trip.Status, //NOTE для работы с колбеками
        key: `row#${vehicleIndex}#${tripIndex}`,
        Status: trip.Status ? TRIP_STATUSES[trip.Status] : '',
        Deliveries: Object.values(trip.Waypoint)
          .sort((a, b) => a.Ind - b.Ind)
          .map((wp, index) => {
            return {
              ...wp,
              Number: `${tripNumber}.${1 + index}`,
              clientName: wp?.Depot?.Name,
              clientAddress: wp?.Depot?.AddressDescription,
              waypointStatus: wp.EventStatusType,
              startTime: trip.TripBegin
                ? timestampToDateTimeStringWithUTC(
                    trip?.ProjectStart + wp.ArrivalTime * SECONDS_TO_DAYS_MULTIPLIER,
                    getWpTimeZone(wp?.Depot?.UTCOffset)
                  )
                : '',
              factTime: wp.FactArrive ? timestampToDateTimeStringWithUTC(wp.FactArrive, getWpTimeZone(wp?.Depot?.UTCOffset)) : '',
              status: wp?.EventStatusType || MonitoringWaypointEventStatusType.Wait,
              statusType: wp?.LastStatus?.Type,
              lastStatus: getTextViewStatus(wp.LastStatus),
              lastStatusDT: wp?.LastStatusDT ? timestampToDateTimeStringWithUTC(wp.LastStatusDT, getWpTimeZone(wp?.Depot?.UTCOffset)) : '',
              onClick: (item: { waypointID: number | undefined; VehicleID: number | undefined }) =>
                handleVehicleDetails(item, dispatch, history)
            };
          }),
        Tracker,
        VehicleTracker: vehicle.VehicleTracker || []
      };
      //@ts-ignore
      res.push(rowObj);
    }
  }
  return res;
};
const handleVehicleDetails = (
  item: { waypointID: number | undefined; VehicleID: number | undefined },
  dispatch: Dispatch,
  history: History
) => {
  openVehicleDetails(item.waypointID, item.VehicleID, dispatch, history);
};
