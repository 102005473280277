import { isNeedLocalSaveRecord } from '@modules/catalog/helpers/is-need-local';
import { EMPTY } from '@common/constants';
import { catalogCreateLocalRoutine, catalogCreateRoutine } from '@modules/catalog/actions';
import { Dispatch } from 'redux';
import { Dictionary } from '@common/types';
import { SaveDictionaryType, Value } from '@modules/catalog/types/catalog';

export const handleCreateRow =
  ({ dispatch }: { dispatch: Dispatch }) =>
  (dictionary: Dictionary, values: Value, saveDictionary?: SaveDictionaryType) => {
    const payload = {
      dictionary,
      values: [values],
      saveDictionary
    };
    //Если ID === EMPTY сохраняем запись локально, без отправки на сервер
    const isLocal = isNeedLocalSaveRecord({ dictionary, data: values });
    if (values.ID === EMPTY || isLocal) {
      dispatch(catalogCreateLocalRoutine(payload));
    } else {
      dispatch(catalogCreateRoutine(payload));
    }
  };
