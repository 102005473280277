import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getPlannerParamsRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { IData } from '@modules/planning/types/params';
import { ParamsKeys } from '@modules/planning/children/by-date/constants/params';

export const getPlannerParamsReducer = createReducer(initialState, {
  [getPlannerParamsRoutine.REQUEST]: (state) => {
    state.params.loading = true;
    return state;
  },
  [getPlannerParamsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data?: IData[];
      hasNoChanges: boolean;
    }>
  ) => {
    const { data, hasNoChanges } = action.payload;

    if (!data || hasNoChanges) return state;

    const defaultPlannerParams = Object.values(data)
      .filter((config) => config?.Items?.length)
      .reduce((result, config) => {
        const noticesParams = config.Items.find((x) => x.StringKey_ === ParamsKeys.PlanNotices);
        const noticeTypes = noticesParams?.Items.reduce((acc, notice) => {
          acc[notice.StringKey_] = { name: notice.Name_ };
          return acc;
        }, {});
        result[config.PlanConfigID_] = { PlanNotices: noticeTypes || {} };
        return result;
      }, {});

    state.params = { defaultPlannerParams, lastUpdate: Date.now() };

    return state;
  },
  [getPlannerParamsRoutine.FULFILL]: (state) => {
    state.params.loading = false;
    return state;
  },
  [getPlannerParamsRoutine.FAILURE]: (state) => {
    state.params = { loading: false, defaultPlannerParams: {}, lastUpdate: 0 };
    return state;
  }
});
