import { isNil } from 'lodash';
import { findNestedChildrenIDs, findRowByIDInNestedDataSource } from '@modules/catalog/helpers/index';

/**
 * Функция находит все вложенные элементы группы и возвращает ID тех элементов, у которых ключ Deleted true.
 * @param dataSourceForTable
 * @param dataSourceForTable
 * @param groupId
 * @returns id[] | null
 */

export const getDeletedRowIDsInGroupedDataSource = (dataSourceForTable?: Array<Record<string, any>>, groupId?: string | null) => {
  if (isNil(dataSourceForTable) || isNil(groupId)) {
    return null;
  }
  const nestedIDs = findNestedChildrenIDs(dataSourceForTable, groupId);
  return nestedIDs?.reduce((result, key) => {
    const row = findRowByIDInNestedDataSource(dataSourceForTable, key);
    if (row?.Deleted) {
      result.push(row.ID);
    }
    return result;
  }, [] as number[]);
};
