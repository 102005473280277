import { FC } from 'react';
import css from 'classnames';
import { IndexedArray } from '@common/types';
import { AnalyticsWidgetDataInfo } from '@common/types/dictionaries/analytics';
import { infoValueToString } from '@modules/analytics/common/utils/info-value-to-string';

type Props = {
  data: IndexedArray<AnalyticsWidgetDataInfo>;
};

const ILSWidgetMetrics: FC<Props> = ({ data }) => {
  const arr = data ? Object.values(data) : [];

  return (
    <div className="analytics-metrics-widget">
      {arr.map((item, index) => (
        <div key={item.title} className="analytics-metrics-widget-info">
          <h4 className={css('analytics-metrics-widget-info-value', { active: index === 0 })}>{infoValueToString(item.value)}</h4>
          <h4 className="analytics-metrics-widget-info-text">{item.title}</h4>
        </div>
      ))}
    </div>
  );
};

export default ILSWidgetMetrics;
