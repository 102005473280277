import { ILSAlert, ILSButton, ILSPopconfirm } from '@common/components';
import { TableConfigSettings } from '@modules/settings/config/common-settings/table-settings/config';
import { TableConfigSaveTrigger } from '@modules/settings/types/table-config';
import { useTableSettings } from '@modules/settings/utils/hooks/use-table-settings';

export const TableSettings = () => {
  const { handleTableSettings } = useTableSettings();
  return (
    <div>
      <ILSAlert message={TableConfigSettings.Title} showIcon />
      <br />
      {TableConfigSettings.Actions.map((Action) => {
        return (
          <ILSPopconfirm
            key={Action.Title}
            onConfirm={() => handleTableSettings(Action.Trigger)}
            title={Action.Info}
            okText="Да"
            cancelText="Нет"
          >
            <ILSButton type="primary" ghost danger={Action.Trigger === TableConfigSaveTrigger.Reset}>
              {Action.Title}
            </ILSButton>
            &nbsp;
          </ILSPopconfirm>
        );
      })}
    </div>
  );
};
