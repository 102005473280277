import { EMPTY_STRING } from '@common/constants/general';

export enum Placeholder {
  Email = 'mail@mail.ru',
  Name = 'ФИО',
  Float = '0.000',
  Integer = '0',
  Phone = '+7 (000) 000 - 00 - 00',
  AddComment = 'Добавить комментарий',
  ChooseValue = 'Выберите значение',
  InputValue = 'Введите значение',
  Number = '1',
  ChooseWeekDays = 'Выберите дни недели',
  Address = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  FactAddress = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  YurAddress = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  City = 'Введите город',
  KPP = 'Введите КПП',
  OrganizationTypeID = 'Выберите тип организации'
}

export enum FormItemMessage {
  Name = 'Пожалуйста, введите ФИО!',
  Phone = 'Пожалуйста, введите номер!',
  Login = 'Пожалуйста, введите email!',
  UserRole = 'Пожалуйста, выберете пользователя',
  City = 'Пожалуйста, введите город!'
}

export enum Field {
  Currency = 'Currency',
  ID = 'ID',
  Email = 'Email',
  Name = 'Name',
  Phone = 'Phone',
  Address = 'Address',
  City = 'City',
  Source = 'Source',
  Contact = 'Contact',
  Target = 'Target',
  Type = 'Type',
  Description = 'Description',
  Date = 'Date'
}

export enum Label {
  Email = 'Почта',
  Name = 'ФИО',
  Phone = 'Телефон',
  Login = 'Email',
  UserRole = 'Роль пользователя',
  INN = 'ИНН компании',
  KPP = 'КПП',
  City = 'Город',
  FactAddress = 'Почтовый адрес',
  YurAddress = 'Юридический адрес',
  AccountRole = 'Роль компании для доступа к Тендерной площадке',
  Bank = 'Банк',
  BIK = 'БИК',
  BankAccount = 'Расчетный счет',
  CorrespondentAccount = 'Кор/с',
  OrganizationTypeID = 'Организационно-правовая форма'
}

export enum RuleMessage {
  ChooseValue = 'Пожалуйста, выберите значение',
  InputValue = 'Пожалуйста, введите значение',
  Name = 'Пожалуйста, введите Имя',
  Phone = 'Пожалуйста, введите номер телефона',
  Email = 'Пожалуйста, введите электронную почту'
}

// todo Placeholder[field] ?
export const getPlaceholder = (placeholder: Placeholder, isEmpty: boolean) => (isEmpty ? EMPTY_STRING : placeholder);
export const DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE = 20971520;

