import '../styles.less';
import { FC, useContext, useEffect, useState } from 'react';
import { ILSTabPane, ILSTabs } from '@components/index';
import { ILSTendersTableComponent } from '@modules/tender-platform/children/tender-search/components/search-tender-table';
import { fetchSelector, tenderByStatusSelector } from '@modules/tender-platform/children/tender-search/selectors';
import { tender2Table } from '@modules/tender-platform/utils/decorators/tender-to-table';
import { AccountRole, ITablePropsFunction } from '@common/types';
import { Tender } from '@modules/tender-platform/types';
import { useDispatch, useSelector } from 'react-redux';
import { tenderSearchRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { tenderGetAccountUsersRoutine } from '@modules/tender-platform/actions';
import { LIST_TABS_CARRIER, ListTab, ListTabTitle } from '@modules/tender-platform/constants/tender-card';
import { ILSTenderTabHeader } from '@modules/tender-platform/components/tab-header';
import { AppContext } from '../../../../../App';

export const ILSTenderSearchListContainer: FC<{ handleOpen: ITablePropsFunction<Tender>['handleOpen'] }> = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(fetchSelector);
  const TABS_DATA = useSelector(tenderByStatusSelector);
  const [activeKey, setActiveKey] = useState(ListTab.CollectingOffers);
  const { roles } = useContext(AppContext) ?? {};
  const hasFilters = roles?.includes(AccountRole.carrier);

  useEffect(() => {
    dispatch(tenderSearchRoutine());
    dispatch(tenderGetAccountUsersRoutine());
  }, []);

  const onTabClick = (key: string) => setActiveKey(key as ListTab);

  return (
    <div className={'tender-platform-list'}>
      <ILSTabs activeKey={activeKey} defaultActiveKey={activeKey} className={'tender-platform-list-content'} onTabClick={onTabClick}>
        {LIST_TABS_CARRIER.map((tab) => {
          const data = TABS_DATA[tab];
          const dataSource: Array<Tender> = Array.isArray(data) ? data : Object.keys(data);
          return (
            <ILSTabPane key={ListTab[tab]} tab={<ILSTenderTabHeader count={dataSource.length} children={ListTabTitle[tab]} />}>
              <ILSTendersTableComponent
                {...props}
                currentTab={tab}
                hasFilters={hasFilters}
                loading={isFetching}
                dataSource={tender2Table(dataSource)}
                notificationEnabled
              />
            </ILSTabPane>
          );
        })}
      </ILSTabs>
    </div>
  );
};
