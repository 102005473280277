import { addSchemeKeysRoutine, moveSchemeKeysRoutine } from '@modules/planning/children/scheme-editor/actions';
import { SchemeEditorTransferBlock } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/transfer/transfer-block';
import { SchemeEditorKeysTypes } from '@modules/planning/children/scheme-editor/components/scheme-keys-types';
import { schemeEditorTransferContainerSelector } from '@modules/planning/children/scheme-editor/selectors/components';
import { sortByKeys } from '@modules/planning/children/scheme-editor/utils/helpers/sort-by-keys';
import { searchAndFilterKeysInSchemeTransfer } from '@modules/planning/children/scheme-editor/utils/helpers/search-and-filter-keys-in-scheme-transfer';
import { SchemeDragInfo, SchemeSwitchDictionary, TypeContainer } from '@modules/planning/children/scheme-editor/types';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuSchemes } from '@modules/planning/children/scheme-editor/types/components';

interface IProps {
  dictionary: SchemeSwitchDictionary;
  displayedForSchemes: MenuSchemes;
}

export const SchemeEditorTransferContainer: FC<IProps> = ({ dictionary, displayedForSchemes: { type, max } }) => {
  const dispatch = useDispatch();
  const { extraData, currentData } = useSelector(schemeEditorTransferContainerSelector(dictionary));
  const [descend, setDescend] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');

  const handleMoveSchemeKey = ({ source, target, typeContainer }: SchemeDragInfo) => {
    dispatch(moveSchemeKeysRoutine({ target, source, typeContainer, dictionary }));
  };

  const addNewKey = (args: { key: string; title?: string; description?: string }) => {
    const { key, title, description } = args;
    dispatch(addSchemeKeysRoutine({ key, title, description, dictionary }));
  };

  const handleSort = () => {
    setDescend(!descend);
  };

  const handleFilter = (search: string) => {
    setFilter(search);
  };

  const sortedData = sortByKeys(
    extraData.filter((x) => searchAndFilterKeysInSchemeTransfer(filter, x)),
    descend
  );

  return (
    <section className={'scheme-editor-scheme'}>
      <SchemeEditorTransferBlock
        addNewKey={addNewKey}
        max
        handleMoveSchemeKey={handleMoveSchemeKey}
        scheme={dictionary}
        data={currentData}
        typeContainer={TypeContainer.Current}
      />
      <SchemeEditorTransferBlock
        descend={descend}
        max={max}
        handleMoveSchemeKey={handleMoveSchemeKey}
        handleSort={handleSort}
        handleFilter={handleFilter}
        scheme={dictionary}
        data={sortedData}
        typeContainer={TypeContainer.Max}
      />
      <SchemeEditorKeysTypes type={Boolean(type)} />
    </section>
  );
};
