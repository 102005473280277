import { depot2table } from '@common/models/depot/decorator';
import { RESTRICTION_TABLE_CONFIG } from '@common/models/restriction/config';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { tableCustom } from '@core/containers/table-custom';
import { planningRestrictionsTableComponentSelector } from '@modules/planning/children/by-date/selectors/components/tables/restrictions';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ILSRestrictionGroupTable } from './restriction-group';
import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';

const REFERENCE_TABLES = {
  RestrictionGroupID: {
    title: 'Группы специфик',
    dictionary: Dictionary.RestrictionGroup,
    component: ILSRestrictionGroupTable
  }
};

const PlanningRestriction: FC<IILSTablePlanningComponentProps> = (props) => {
  const { refData } = useSelector(planningRestrictionsTableComponentSelector);
  return (
    <PlannerPlanningTablesContainer
      {...props}
      refData={refData}
      refTable={REFERENCE_TABLES}
      dataDecorator={depot2table}
      handleDecorator={table2data}
      dictionary={Dictionary.Restriction}
    />
  );
};

export const RestrictionTable = tableCustom(PlanningRestriction, RESTRICTION_TABLE_CONFIG, CatalogDictionary.Restriction);
