import { AxiosResponse } from 'axios';
import { ErrorAPIData, Project, ResponseAPI } from '@common/types';
import { PlanningRequest } from '@modules/planning/types/api';

export type MatrixStatus = {
  LastUpdated: null;
  LastSize: null;
  LastCalc: null;
  LastNull: null;
  Percent?: number;
  Count?: number;
  Total?: number;
  MatrixPercent?: number;
  MatrixCounter?: number;
  MatrixVariants?: number;
  Estimate?: string;
};

export enum MatrixAction {
  Start = 'start',
  Delete = 'delete',
  Cleanup = 'cleanup'
}

export type MatrixResponse = {
  result: 1;
};

export interface IDistanceMatrixAPI {
  start(params: PlanningRequest): Promise<AxiosResponse<ResponseAPI<MatrixResponse> | ErrorAPIData>>;

  delete(params: PlanningRequest): Promise<AxiosResponse<ResponseAPI<MatrixResponse> | ErrorAPIData>>;

  status(params: { projectID: Project['ID'] }): Promise<AxiosResponse<ResponseAPI<MatrixStatus> | ErrorAPIData>>;

  cleanup(params: { projectID: Project['ID'] }): Promise<AxiosResponse<ResponseAPI<true> | ErrorAPIData>>;
}
