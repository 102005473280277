import { ILSInput, ILSPhoneInput, ILSSelect } from '@common/components';
import { IILSSelectProps } from '@common/components/data-entry/select/types';
import { Placeholder } from '@common/constants';
import { COMPANY_FORM_ITEM_LENGTH, CompanyFormItem } from '@modules/account/constants';

export const renderFormInput = ({
  field,
  adminAccess,
  options
}: {
  field: CompanyFormItem;
  adminAccess: boolean;
  options?: IILSSelectProps['options'];
}) => {
  switch (field) {
    case CompanyFormItem.Phone:
      return <ILSPhoneInput disabled={!adminAccess} />;
    case CompanyFormItem.OrganizationTypeID:
      return <ILSSelect placeholder={Placeholder[field]} options={options} disabled={!adminAccess} />;
    default:
      return (
        <ILSInput
          minLength={COMPANY_FORM_ITEM_LENGTH[field]?.min}
          maxLength={COMPANY_FORM_ITEM_LENGTH[field]?.max}
          placeholder={Placeholder[field]}
          disabled={!adminAccess}
        />
      );
  }
};
