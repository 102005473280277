import { put, takeLatest } from 'redux-saga/effects';
import {
  addEndDepotToTripRoutine,
  addOrderForVehicleRoutine,
  mapWaitingModeRoutine,
  moveWaypointInTripRoutine,
  moveWaypointToTripRoutine
} from '@modules/planning/children/by-date/actions';
import { UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';

function* blur_worker() {
  try {
    yield put(mapWaitingModeRoutine.trigger());
  } catch {}
}
function* blur_fulfill_worker() {
  try {
    yield put(mapWaitingModeRoutine.fulfill());
  } catch {}
}
export function* mapWaitingModeWatcher() {
  for (let routine of routinesToBlur) {
    yield takeLatest(routine.trigger, blur_worker);
    yield takeLatest(routine.fulfill, blur_fulfill_worker);
  }
}

const routinesToBlur: Array<UnifiedRoutine<(payload?: any) => Action>> = [
  addEndDepotToTripRoutine,
  addOrderForVehicleRoutine,
  moveWaypointInTripRoutine,
  moveWaypointToTripRoutine
];
