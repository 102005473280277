import { useInterval } from '@common/hooks/use-interval';
import { compareAsString } from '@common/utils';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { Dispatch } from 'redux';
import { getSensorEventsRoutine } from '../actions';
import { MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS } from '../configs/api-intervals';
import { activeTabSelector, tracksSelector, vehicleSensorSelector } from '../selectors';
import { TabType } from '../types/event-details-tabs';

/**
 * Хук для обновления событий датчика автомобиля
 */
export const useUpdateSensorEvents = () => {
  const selectedVehicleId = useAppSelector(tracksSelector)?.selectedVehicleId;
  const vehicleSensor = useAppSelector(vehicleSensorSelector);
  const activeTab = useAppSelector(activeTabSelector);
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);

  const dispatch: Dispatch = useAppDispatch();

  const updateSensorEvents = () => {
    if (
      !vehicleSensor.isFetching &&
      startDate &&
      endDate &&
      selectedVehicleId &&
      (activeTab === TabType.Bdd || activeTab === TabType.Sensor) &&
      (!vehicleSensor.lastUpdate ||
        vehicleSensor.lastUpdate + MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.GET_SENSOR_EVENTS < new Date().getTime() ||
        !compareAsString(selectedVehicleId, vehicleSensor.loadedVehicleId))
    ) {
      const updates =
        vehicleSensor.lastUpdateInterval?.from === startDate &&
        vehicleSensor.lastUpdateInterval?.till === endDate &&
        compareAsString(selectedVehicleId, vehicleSensor.loadedVehicleId);

      dispatch(getSensorEventsRoutine({ vehicleID: selectedVehicleId, from: startDate, till: endDate, updates }));
    }
  };

  useInterval(updateSensorEvents, MONITORING_EVENTS_DETAILS_UPDATE_INTERVALS.GET_SENSOR_EVENTS, [
    startDate,
    endDate,
    selectedVehicleId,
    vehicleSensor.loadedVehicleId,
    vehicleSensor.lastUpdateInterval,
    activeTab
  ]);
};

