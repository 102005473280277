import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EMPTY_STRING } from '@common/constants';
import { ButtonTypes } from '@common/types/components';
import { ICreateInvoiceFormValues, PaymentFormFieldNames } from '@common/feature/payment/types/form';
import { getInvoiceTemplateRoutine } from '@common/feature/payment/actions';

interface IProps {
  handleShowModal(): void;
  openInvoiceViewer(): void;
}

export const useInvoiceForm = ({ handleShowModal, openInvoiceViewer }: IProps) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const onFinish = (values: ICreateInvoiceFormValues) => {
    const invoiceSum = values[PaymentFormFieldNames.InvoiceSum];
    const numberValue = Number(invoiceSum.replace(/\s/g, EMPTY_STRING));
    dispatch(getInvoiceTemplateRoutine({ invoiceSum: numberValue }));
    openInvoiceViewer();
  };

  const submitButtonProps = {
    [ButtonTypes.Cancel]: handleShowModal
  };

  return { form, submitButtonProps, onFinish };
};

