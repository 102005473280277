import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { getEventAllDataRoutine } from '../actions';
import { DashboardInfoRequest } from '../types/api';
import { monitoringSettingsParameterShowAllVehiclesSelector } from '@modules/settings/selectors/monitoring';

// Загружает необходимые данные (данные по автомобилю и данные по плану)
function* monitoringDashboardInfoWorker(action: PayloadAction<DashboardInfoRequest>) {
  const { request, success, failure, fulfill } = getEventAllDataRoutine;
  const { from, till } = action.payload;

  try {
    yield put(request({ from, till }));
    const showAllVehicles: number = yield select(monitoringSettingsParameterShowAllVehiclesSelector);

    const {
      data: {
        data: { Vehicle, Plan }
      }
    } = yield call(MonitoringAPI.getEventDetails, { from, till, showAllVehicles });

    yield put(success({ data: Vehicle, Plan, from, till }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringDashboardInfoWatcher() {
  yield takeLatest(getEventAllDataRoutine.trigger, monitoringDashboardInfoWorker);
}
