import { call, put, takeLatest } from 'redux-saga/effects';
import { importOrdersRoutine, getUnresolvedOrdersRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* importOrdersWorker(
  action: PayloadAction<{
    file: File;
  }>
) {
  const { failure } = importOrdersRoutine;
  const { file } = action.payload ?? {};
  try {
    yield call(OrdersNTripsApi.importOrders, { file });
    yield put(getUnresolvedOrdersRoutine.trigger());
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* importOrdersWatcher() {
  yield takeLatest(importOrdersRoutine.trigger, importOrdersWorker);
}
