import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import classNames from 'classnames';
import React, { ForwardedRef } from 'react';
import { MAX_WIDGET_SIZE } from '../../constants';

type Props = {
  widget: AnalyticsWidget;
  pointerTop: (e: React.PointerEvent) => void;
  pointerLeft: (e: React.PointerEvent) => void;
  pointerDrag: (e: React.PointerEvent) => void;
  pointerRight: (e: React.PointerEvent) => void;
  pointerBottom: (e: React.PointerEvent) => void;
  deleteBoardWidget?: (widget: AnalyticsWidget) => void;
  children: JSX.Element;
};
const EditableWidget = React.forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { widget, children, pointerTop, pointerLeft, pointerBottom, pointerDrag, pointerRight, deleteBoardWidget } = props;
  return (
    <div
      style={{
        width: widget.Size?.Width ? `${widget.Size?.Width}%` : MAX_WIDGET_SIZE,
        height: widget.Size?.Height ? `${widget.Size?.Height}%` : MAX_WIDGET_SIZE,
        left: widget.Position?.Left ? `${widget.Position?.Left}%` : 0,
        top: widget.Position?.Top ? `${widget.Position?.Top}%` : 0
      }}
      className={classNames('analytics-widget-wrapper', 'analytics-widget-edit')}
      ref={ref}
    >
      <div className={classNames('analytics-widget-edit-body')}>
        <div className={classNames('analytics-widget-delete')} onClick={() => deleteBoardWidget?.(widget)}>
          &times;
        </div>
        <div className={classNames('analytics-widget-top-resizer')} onPointerDown={pointerTop} />
        <div className={classNames('analytics-widget-left-resizer')} onPointerDown={pointerLeft} />
        <div className={classNames('analytics-widget-right-resizer')} onPointerDown={pointerRight} />
        <div className={classNames('analytics-widget-bottom-resizer')} onPointerDown={pointerBottom} />
        <div className={classNames('analytics-widget-edit-component')} onPointerDown={pointerDrag}>
          {children}
        </div>
      </div>
    </div>
  );
});

export default EditableWidget;
