import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, IndexedArray, TableRecord } from '@common/types';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { useCatalogInTable } from '@modules/planning/children/by-date/hooks/use-catalog-in-table';
import { handleCopyRow } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-copy-row';
import { handleUpdateRow } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-update-row';
import { handleCreateRow } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-create-row';
import { handleDeleteRows } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-delete-rows';
import { handleRestoreRows } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-restore-rows';
import { handleCheckDeleteRows } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-check-delete-rows';
import { planningRestrictionsTableComponentSelector } from '@modules/planning/children/by-date/selectors/components/tables/table';

export const usePlanningTable = ({
  dictionary,
  dataDecorator,
  handleDecorator
}: {
  dictionary: Dictionary;
  handleDecorator?(row: any, dataIndex?: string | number, value?: any): any;
  dataDecorator(data: Array<unknown> | IndexedArray<unknown>): Array<unknown & TableRecord>;
}) => {
  const dispatch = useDispatch();

  const { projectID, dataSource, isFetching, info } = useSelector((state: PlanningByDateState) =>
    planningRestrictionsTableComponentSelector(state, dictionary, dataDecorator)
  );
  const { handleReload, setShowDeleted, showDeleted, catalogLoad } = useCatalogInTable(dictionary);

  const onCopy = useCallback(handleCopyRow({ dispatch })!, [dictionary]);
  const handleSave = useCallback(handleUpdateRow({ dispatch, projectID, handleDecorator, dictionary }), [dictionary]);
  const handleCreate = useCallback(handleCreateRow({ dispatch, handleDecorator, dictionary }), [dictionary]);
  const handleDelete = useCallback(handleDeleteRows({ dispatch, dictionary }), [dictionary]);
  const handleRestore = useCallback(handleRestoreRows({ dispatch, dictionary }), [dictionary]);
  const handleCheckDelete = useCallback(handleCheckDeleteRows(dictionary), [dictionary]);

  return {
    handleReload,
    catalogLoad,
    showDeleted,
    setShowDeleted,
    onCopy,
    handleSave,
    handleCreate,
    handleDelete,
    handleRestore,
    isFetching,
    info,
    dataSource,
    handleCheckDelete
  };
};
