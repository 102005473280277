import { ILSButton, ILSInput, ILSTooltip } from '@common/components';
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import css from 'classnames';
import { AnalyticsBoardModel, AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import { EditableNameState } from '../../types/components/board-sets';
import { InputRef, Spin } from 'antd';
import useDragSort from '../../hooks/use-drag-sort';

type Props = {
  listData: (AnalyticsBoardSetModel | AnalyticsBoardModel)[];
  handleCancel: () => void;
  setNewItem: Dispatch<SetStateAction<boolean>>;
  newItem: boolean;
  handleEditName: (values: { id: number | string | null; editable: boolean }) => void;
  editableName: EditableNameState;
  handleUpdateItem: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
  handleUpdateByPenClick: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
  handleDeleteItem: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
  handleDisableItem: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
  sortItemList: (list: (AnalyticsBoardSetModel | AnalyticsBoardModel)[], itemID: number | string, idx: number) => void;
  updatingItem: number | string | undefined;
  newItemAddTitle: string;
  defaultItemTitle?: string;
  listTitle: string;
  adding: boolean | undefined;
};

const ILSAnalyticsEditableListComponent: FC<Props> = ({
  editableName,
  handleEditName,
  listData,
  handleCancel,
  setNewItem,
  handleUpdateByPenClick,
  newItem,
  handleDeleteItem,
  handleDisableItem,
  sortItemList,
  updatingItem,
  newItemAddTitle,
  defaultItemTitle,
  listTitle,
  adding
}) => {
  const [name, setName] = useState('');
  const ref = useRef<InputRef | null>(null);
  const [holding, setHolding] = useState(false);

  const { dragEndHandler, dragOverHandler, dragStartHandler, dropHandler, droppable } = useDragSort<
    AnalyticsBoardSetModel | AnalyticsBoardModel
  >({
    list: listData,
    setHolding,
    sortList: sortItemList
  });

  const onBlur = () => {
    setTimeout(() => {
      handleEditName({ id: null, editable: false });
      setName('');
    }, 100);
  };

  const confirmEditing = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    handleUpdateByPenClick({
      ...item,
      Name: name
    });
  };

  const onClick = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    handleEditName({ id: item.ID, editable: true });
    setTimeout(() => {
      ref.current?.focus();
    }, 0);
  };

  const onDisable = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    handleDisableItem({
      ...item,
      Active: item.Active ? 0 : 1
    });
  };

  return (
    <>
      <div className="header">
        <div className="title">{listTitle}</div>
        <button className="close" onClick={handleCancel}>
          &times;
        </button>
      </div>

      <div className="list analytics-scroll" data-testid="analytics-edit-list">
        {listData.map((item: any, idx) => (
          <div
            key={item.ID}
            className={css('list-item', { dropping: droppable == item.ID })}
            onDragStart={(e) => dragStartHandler(e, item, idx)}
            onDragLeave={(e) => dragEndHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
            onDragOver={(e) => dragOverHandler(e, item)}
            onDrop={(e) => dropHandler(e, item, idx)}
            draggable={holding}
          >
            {updatingItem === item.ID ? (
              <Spin spinning={true} />
            ) : (
              <>
                <ILSButton
                  className="btn-action move"
                  type="link"
                  onMouseDown={() => setHolding(true)}
                  onMouseUp={() => setHolding(false)}
                  icon={<ReactSVG className="svg-container" src={'/assets/icons/up-down-arrows.svg'} />}
                />

                {editableName.editable && editableName.id === item.ID ? (
                  <ILSInput
                    ref={ref}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={onBlur}
                    maxLength={25}
                    className="edit-name-input"
                    defaultValue={item.Name || defaultItemTitle}
                    onPressEnter={() => confirmEditing(item)}
                  />
                ) : (
                  <ILSTooltip title={item.Name || defaultItemTitle}>
                    <div className={css('name', { disabled: item.Active === 0 })}> {item.Name || defaultItemTitle}</div>
                  </ILSTooltip>
                )}

                <div className="actions">
                  <ILSButton
                    className={css('btn-action', { disabled: item.Active === 0 })}
                    type="link"
                    onClick={() => onDisable(item)}
                    icon={<ReactSVG className="svg-container" src={'/assets/icons/eye.svg'} />}
                  />

                  {editableName.editable && item.ID == editableName.id ? (
                    <ILSButton
                      className={'btn-action edit-name'}
                      type="link"
                      onClick={() => confirmEditing(item)}
                      icon={<ReactSVG className="svg-container" src={'/assets/icons/confirm.svg'} />}
                    />
                  ) : (
                    <ILSButton
                      className={'btn-action edit-name'}
                      type="link"
                      onClick={() => onClick(item)}
                      icon={<ReactSVG className="svg-container" src={'/assets/icons/pen.svg'} />}
                    />
                  )}

                  <ILSButton
                    data-testid={`analytics-board-delete-button-${item?.ID}`}
                    className="btn-action edit-name"
                    type="link"
                    onClick={() => handleDeleteItem(item)}
                    icon={<ReactSVG className="svg-container" src={'/assets/icons/delete.svg'} />}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {adding && <Spin spinning={true} />}

      <div className="list-item">
        <div className="plus" onClick={() => setNewItem((prev) => !prev)}>
          &#43;
        </div>
        <div className="new-set-row-value">{newItemAddTitle}</div>
        <div className={css('arrow-right', { active: newItem })} onClick={() => setNewItem((prev) => !prev)} />
      </div>
    </>
  );
};

export default ILSAnalyticsEditableListComponent;
