import { defineModule } from '@core/index';
import { OrdersNTripsContainer } from './containers';
import { Module, ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import ordersNTripsRootReducer from './reducers';
import * as rootSagas from './sagas/';

/** Модуль "Заявки и рейсы" - это "входная страница" всего проекта. */
export default defineModule({
  title: ModuleTitle.OrdersNTrips,
  path: ModulePath.OrdersNTrips,
  component: OrdersNTripsContainer,
  reducer: ordersNTripsRootReducer,
  sagas: getAllModuleSagas(rootSagas),
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/demand.svg',
  deps: [Module.Common, Module.Payment, Module.Questions],
  persist: false
});
