import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { changeDriverInTripRoutine } from '../../actions';
import { PlanningAPI } from '@modules/planning/api';

export type ChangeDriverInTripPayloadAction = PayloadAction<{
  tripID: number;
  driverID: number;
  vehicleID: number;
}>;

function* planningChangeDriverInTripWorker(action: ChangeDriverInTripPayloadAction) {
  const { request, success, failure, fulfill } = changeDriverInTripRoutine;
  const { tripID, driverID, vehicleID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.changeDriverInTrip, { tripID, driverID });
    yield put(success({ message: data, tripID, driverID, vehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningChangeDriverInTripWatcher() {
  yield takeLatest(changeDriverInTripRoutine.trigger, planningChangeDriverInTripWorker);
}
