import { useHistory } from 'react-router';
import { PARAMS_KEY } from '@modules/planning/children/by-date/constants';
import { useEffect } from 'react';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { useDispatch, useSelector } from 'react-redux';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { planningGetProjectCurrentRoutine } from '@modules/planning/children/by-date/actions';

export const useProjectAndPlanQueryParams = () => {
  const dispatch = useDispatch();
  const projectID = useSelector(projectIDSelector);
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const paramsProjectID = queryParams.get(PARAMS_KEY.PROJECT_ID);
  const paramsPlanID = queryParams.get(PARAMS_KEY.PLAN_ID);
  const isSameProject = compareAsString(paramsProjectID, projectID);

  useEffect(() => {
    if (isSameProject) {
      queryParams.delete(PARAMS_KEY.PROJECT_ID);
      if (paramsPlanID) {
        queryParams.delete(PARAMS_KEY.PLAN_ID);
      }
      history.replace({
        search: queryParams.toString()
      });
    }
  }, [projectID]);

  //NOTE: Если в адресной передан ProjectID получить данные проекта. Работает от переданных query параметров
  useEffect(() => {
    if ((!isSameProject && paramsProjectID) || paramsPlanID) {
      const payload = { projectID: paramsProjectID, activePlanID: paramsPlanID, initial: true };
      dispatch(planningGetProjectCurrentRoutine(payload));
    }
  }, [paramsProjectID, paramsPlanID]);

  useEffect(() => {
    //NOTE: Запрашивает текущий проект при первом рендеринге компонента если есть поле PlanningByDAte -> project -> ID или query параметр paramsProjectID
    if (projectID && !paramsProjectID) {
      dispatch(planningGetProjectCurrentRoutine({ projectID, initial: true }));
    }
  }, []);
};
