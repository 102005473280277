import { call, put, takeLatest } from 'redux-saga/effects';
import { planAnalyticGetRoutine } from '../actions';
import { PlanAnalytic } from '../api';

function* planAnalyticGetWorker(action: {
  type: string;
  payload: {
    planID: number;
    strPlanningDateTime: string;
  };
}) {
  const { request, success, failure, fulfill } = planAnalyticGetRoutine;
  const { planID, strPlanningDateTime } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(PlanAnalytic.get, { planID, strPlanningDateTime });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planAnalyticGetWatcher() {
  yield takeLatest(planAnalyticGetRoutine.trigger, planAnalyticGetWorker);
}
