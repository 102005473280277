import { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { DETAILS_CONTENT } from '@modules/orders-and-trips/types/details';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { DetailsContentButtons } from '@modules/orders-and-trips/components/details/details-content-buttons';
import { renderDetailsContent } from '@modules/orders-and-trips/helpers/render-details-content';
import { DecoratedTrips } from '@modules/orders-and-trips/types/trip';

interface IProps {
  tripDetails?: DecoratedTrips[number]['details'];
  vehicleTypes: VehicleType | null;
}

export const DetailsContent: FC<IProps> = ({ tripDetails, vehicleTypes }) => {
  if (isEmpty(tripDetails)) return null;
  const [detailsContent, setDetailsContent] = useState<DETAILS_CONTENT>(DETAILS_CONTENT.GENERAL);
  const handleChangeDetailsComponent = (detailsContent: DETAILS_CONTENT) => setDetailsContent(detailsContent);

  return (
    <div className="details-content">
      <div className="details-content-header">
        <div className="details-content-distance">
          <ILSSVG config={SVG_CONFIG} icon={SVG.TRIP} />
          <div>Только туда {tripDetails.distance} км</div>
        </div>
        <DetailsContentButtons detailsContent={detailsContent} handleChangeDetailsComponent={handleChangeDetailsComponent} />
      </div>
      {renderDetailsContent({ detailsContent, tripDetails, vehicleTypes })}
    </div>
  );
};
