import { put, takeEvery } from 'redux-saga/effects';
import { markBoardWidget } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

function* worker(action: PayloadAction<{ widget: AnalyticsWidget }>) {
  const { request, success, failure, fulfill } = markBoardWidget;

  const { widget } = action.payload;
  try {
    yield put(request(widget));

    yield put(success({ ID: widget.ID }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsMarkBoardWidgetWatcher() {
  yield takeEvery(markBoardWidget.TRIGGER, worker);
}
