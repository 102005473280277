import { createReducer } from '@reduxjs/toolkit';
import { getDocumentTemplatesRoutine } from '../actions';
import initialState from '../store';
import DocumentTemplatesStore from '../types';
import { DocumentTemplate } from '../types/api';

export const getDocumentTemplates = createReducer(initialState, {
  [getDocumentTemplatesRoutine.REQUEST]: (state: DocumentTemplatesStore) => {
    state.isFetching = true;
    return state;
  },
  [getDocumentTemplatesRoutine.SUCCESS]: (
    state: DocumentTemplatesStore,
    action: {
      type: string;
      payload: {
        data: DocumentTemplate[];
      };
    }
  ) => {
    const { data } = action.payload;
    state.documentTemplates = data;
    return state;
  },
  [getDocumentTemplatesRoutine.FULFILL]: (state: DocumentTemplatesStore) => {
    state.isFetching = false;
    return state;
  }
});
