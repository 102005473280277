import { createContext } from 'react';
import { ICoord, IMarkerState } from '@modules/road-graph/route-test/types/map';

interface RoadGraphValues {
  showDepots: boolean;
  setShowDepots: (s: boolean) => void;
  showZones: {
    show: boolean;
    selectedZones: Array<number>;
  };
  setShowZones: (s: { show: boolean; selectedZones: Array<number> }) => void;
  trackMarker: {
    A: IMarkerState;
    B: IMarkerState;
  };
  setTrackMarker: (s: any) => void;
  coords: undefined | ICoord;
  setCoords: (s: undefined | ICoord) => void;
  handleClean: () => void;
  handleReverse: () => void;
}

export const RoadGraphContext = createContext<RoadGraphValues | null>(null);
