import {
  GetBoardSetRequest,
  GetBoardsRequest,
  GetBoardWidgetRequest,
  GetWidgetDataRequest,
  SetBoardWidgetBatchRequest,
  SetBoardWidgetRequest
} from '@common/types/dictionaries/analytics';
import { apiInstance } from '@core/api/instance';
import { IAnalyticsPlanningAPI } from '../types/api';

const baseUrl = '/NewTms/AnalyticsPlanning/';

export const AnalyticsPlanningAPI: IAnalyticsPlanningAPI = {
  boardSetIndex: (params: GetBoardSetRequest) => apiInstance().post(baseUrl + 'boardSetIndex', params),
  boardIndex: (params: GetBoardsRequest) => apiInstance().post(baseUrl + 'boardIndex', params),
  getBoardWidget: (params: GetBoardWidgetRequest) => apiInstance().post(baseUrl + 'getBoardWidget', params),
  getWidgetData: (params: GetWidgetDataRequest) => apiInstance().post(baseUrl + 'getWidgetData', params),
  getWidgetCatalog: () => apiInstance().get(baseUrl + 'getWidgetCatalog'),
  deleteBoardSet: (id: string) => apiInstance().post(baseUrl + 'boardSetDelete', { ID: id }),
  deleteBoard: (id: string) => apiInstance().post(baseUrl + 'boardDelete', { ID: id }),
  setBoardWidget: (params: SetBoardWidgetRequest) => apiInstance().post(baseUrl + 'setBoardWidget', params),
  setBoardWidgetBatch: (params: SetBoardWidgetBatchRequest) => apiInstance().post(baseUrl + 'setBoardWidgetBatch', params),
  deleteWidget: (id: number | string) => apiInstance().post(baseUrl + 'deleteBoardWidget', { ID: id }),
  batch: (payload) => apiInstance().post(baseUrl + 'batch', payload)
};
