import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

/**
 Usage: onFinishedFailed in ILSForm
 */
export const handleFinishFailedNotify = ({ errorFields }: ValidateErrorEntity) => {
  const errors = errorFields.map(({ errors }) => errors).join('; ');
  ILSUserNotify(Notify.Error, errors);
};