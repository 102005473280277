import '../styles.less';
import { Redirect, Switch, useLocation } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { ModulePath } from '@common/types';
import { AppContext } from '../../../App';
import { SUB_MODULES_WITHOUT_ACCESS } from '@modules/planning/constants/planner';
import { useAppSelector } from '@core/hooks';
import { hasActiveProjectSelector } from '@modules/planning/selectors';
import { usePlanningParams } from '../hooks/use-planning-params';
import { useStimulSoft } from '@common/hooks/stimulsoft/use-stimulsoft';

interface IProps {
  route: {
    component: ReactNode | JSX.Element;
    routes: RouteConfig[];
  };
}

export const ILSPlanner: FC<IProps> = ({ route: { routes } }) => {
  useStimulSoft()
  const { account } = useContext(AppContext);
  const hasActiveProject = useAppSelector(hasActiveProjectSelector);
  const accessToPlanning = useMemo(() => account?.ProjectCount || hasActiveProject, [hasActiveProject, account?.ProjectCount]);
  const [fromUrl, setFromUrl] = useState<ModulePath>(ModulePath.Planner);
  const [toUrl, setToUrl] = useState<string>(() => `${ModulePath.Planner}${accessToPlanning ? ModulePath.Planning : ModulePath.New}`);
  const { pathname } = useLocation();
  const subModuleWithoutAccess =
    pathname !== `${ModulePath.Planner}${accessToPlanning ? ModulePath.Planning : ModulePath.New}` &&
    SUB_MODULES_WITHOUT_ACCESS.includes(pathname);

  usePlanningParams();

  useEffect(() => {
    if (accessToPlanning) {
      if (pathname === `${ModulePath.Planner}${ModulePath.New}`) {
        setFromUrl(pathname as ModulePath);
        setToUrl(`${ModulePath.Planner}${ModulePath.Planning}`);
      } else {
        return undefined;
      }
    } else if (subModuleWithoutAccess) {
      setFromUrl(pathname as ModulePath);
    } else {
      setFromUrl(ModulePath.Planner);
      setToUrl(`${ModulePath.Planner}${ModulePath.Planning}`);
    }
  }, [accessToPlanning]);

  return (
    <>
      <Switch>
        <Redirect from={fromUrl} exact to={toUrl} />
      </Switch>
      {renderRoutes(routes)}
    </>
  );
};
