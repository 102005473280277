import { tableCustom } from '@core/containers/table-custom';
import { FC } from 'react';
import { CargoTable } from './cargo';
import { Dictionary, IILSTableProps, TableRecord } from '@common/types';
import { ORDER_CARGO_TABLE_CONFIG } from '@common/models/order-cargo/config/table';
import { ILSTable } from '@common/components';
import { handleCreateOrderCargo } from '@modules/planning/children/by-date/helpers/tables/handlers/order-cargo/handle-create-order-cargo';
import { handleUpdateOrderCargo } from '@modules/planning/children/by-date/helpers/tables/handlers/order-cargo/handle-update-order-cargo';
import { handleDeleteOrderCargo } from '@modules/planning/children/by-date/helpers/tables/handlers/order-cargo/handle-delete-order-cargo';
import { useOrderCargoTable } from '@modules/planning/children/by-date/hooks/tables/order-cargo/use-order-cargo-table';

const ORDER_CARGO_REF_TABLE = {
  CargoID: {
    title: 'Грузы',
    component: CargoTable
  }
};

interface IProps extends IILSTableProps<TableRecord> {
  record: TableRecord;
}

const PlannerPlanningOrderCargoTableContainer: FC<IProps> = ({ reference: { record } = {}, ...props }) => {
  const { dataSource, refData, dispatch } = useOrderCargoTable(record);

  return (
    <ILSTable
      {...props}
      handleDelete={handleDeleteOrderCargo({ dispatch })}
      handleSave={handleUpdateOrderCargo({ dispatch })}
      handleCreate={handleCreateOrderCargo({ record, dispatch })}
      dataSource={dataSource}
      dictionary={Dictionary.OrderCargo}
      refTable={ORDER_CARGO_REF_TABLE}
      refData={refData}
    />
  );
};

export const PlannerPlanningOrderCargoTableComponent = tableCustom(
  PlannerPlanningOrderCargoTableContainer,
  ORDER_CARGO_TABLE_CONFIG,
  Dictionary.OrderCargo
);
