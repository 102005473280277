import { Depot, Dictionary } from '@common/types';
import { getParentCatalogIndex } from '@common/utils/helpers/object/get-enum-field';
import { timeToDouble } from '@common/utils';
import { CatalogUpdate } from '@modules/catalog/types/actions';
import { IRecord, Value } from '@modules/catalog/types/catalog';
import { SplitChar } from '@common/constants';

export const patchCatalogPayloadForDepotChildTable = ({
  values,
  payload,
  record
}: {
  record: IRecord;
  values: Value;
  payload: CatalogUpdate;
}) => {
  const [changedDataIndex] = Object.keys(values);
  if (changedDataIndex.includes(Dictionary.Worktime + SplitChar.Dot)) {
    const worktimeID = (record as Depot).WorktimeID;
    if (worktimeID) {
      payload.dictionary = Dictionary.Worktime;
      payload.values = { [getParentCatalogIndex(changedDataIndex)]: timeToDouble(values[changedDataIndex]) };
      payload.ids = [worktimeID];
    }
  }
};
