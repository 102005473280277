import { createReducer } from '@reduxjs/toolkit';
import { getDocumentSetsRoutine } from '../actions';
import initialState from '../store';
import DocumentTemplatesStore from '../types';
import { DocumentSet } from '../types/api';

export const getDocumentSets = createReducer(initialState, {
  [getDocumentSetsRoutine.REQUEST]: (state: DocumentTemplatesStore) => {
    state.isFetching = true;
    return state;
  },
  [getDocumentSetsRoutine.SUCCESS]: (
    state: DocumentTemplatesStore,
    action: {
      type: string;
      payload: {
        data: DocumentSet[];
      };
    }
  ) => {
    const { data } = action.payload;
    state.documentSets = data;
    return state;
  },
  [getDocumentSetsRoutine.FULFILL]: (state: DocumentTemplatesStore) => {
    state.isFetching = false;
    return state;
  }
});
