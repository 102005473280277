import { PayloadAction } from '@reduxjs/toolkit';

export type MonitoringEventDetailsResizer = {
  tablesMapSplitSizes: [number, number];
  tablesSplitSizes: [number, number];
};

export enum MonitoringEventDetailsResizeComponent {
  map = 'tablesMapSplitSizes',
  tables = 'tablesSplitSizes'
}

export type TableMapSizes = Partial<MonitoringEventDetailsResizer>;
export type TableMapSizesPayload = PayloadAction<TableMapSizes>;
export type onResizeFinished = (pairIdx: number, newSizes: number[]) => void;
export type HandleResize = (component: MonitoringEventDetailsResizeComponent) => onResizeFinished;
