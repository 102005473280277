import { createSelector } from 'reselect';
import { activePlanMapDecorator } from '../../../decorators/map/map';
import { currentPlanSelector } from '../../current-plan';
import { projectDepotSelector, projectOrderSelector, projectWorktimeSelector, vehiclesSelector } from '../../project';
import { activeRestrictionIDsFiltersSelector } from '../../restriction/active-restriction-ids-filters';

export const mapPlanDataSelector = createSelector(
  vehiclesSelector,
  projectOrderSelector,
  projectDepotSelector,
  projectWorktimeSelector,
  currentPlanSelector,
  activeRestrictionIDsFiltersSelector,
  (Vehicle, Order, Depot, Worktime, plan, activeRestrictionIDs) => {
    return activePlanMapDecorator(plan, { Depot, Order: Order || null, Vehicle: Vehicle || null, Worktime }, activeRestrictionIDs);
  }
);
