import { isArray, isNil, isObject } from 'lodash';
import { Keys } from '@common/types';

export const getIDsArrayFromValue = (value: unknown): Keys => {
  if (isNil(value)) return [];

  if (isObject(value)) {
    const ids = isArray(value) ? value : Object.values(value);
    return ids.some((id) => isNaN(Number(id))) ? [] : ids.map(Number);
  }

  return isNaN(Number(value)) ? [] : [Number(value)];
};
