import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { tenderAppointResponsibleRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { Tender } from '@modules/tender-platform/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { User } from '@common/types';

export const appointResponsibleTender = createReducer(initialState, {
  [tenderAppointResponsibleRoutine.REQUEST]: onRequest,
  [tenderAppointResponsibleRoutine.FAILURE]: onFailure,
  [tenderAppointResponsibleRoutine.FULFILL]: onFulfill,
  [tenderAppointResponsibleRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      tenderID: Tender['ID'];
      userID: number;
      User: User;
    }>
  ) => {
    const { tenderID, userID, User } = action.payload;
    if (state.tenders) {
      state.tenders = {
        ...state.tenders,
        [tenderID]: { ...state.tenders[tenderID], UserID: userID, User }
      };
    }
    return state;
  }
});
