import { createSelector } from 'reselect';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { activeTripSelector } from '@modules/orders-and-trips/selectors/trip';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const tripDocumentsSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips?.tripDocuments?.documents,
  (documents) => documents
);

export const tripDocumentsIsFetchingSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips?.tripDocuments?.isFetching,
  (isFetching) => isFetching
);

export const activeTripDocumentsSelector = createSelector(tripDocumentsSelector, activeTripSelector, (documents, activeTrip) => {
  const activeTripDocuments = activeTrip?.ID ? documents?.[activeTrip.ID] : EMPTY_ARRAY_DATA;
  return activeTripDocuments;
});

