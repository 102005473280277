import { FC, MutableRefObject, useState } from 'react';
import { TenderOwnFormTableVirtualInput } from '@modules/tender-platform/children/tender-own/components/create-from-planner/virtual-input';
import { TENDER_FORM_TABLE_FIELDS_PROPERTIES, TenderFormTableField } from '@modules/tender-platform/constants/content';
import { ITenderRotesFunctions, RoutesID, RoutesIDs } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { dataRoutesEditingSelector, routesDataSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { updateTenderForRouteRoutine } from '@modules/tender-platform/actions';
import { UploadFile } from 'antd/lib/upload/interface';

interface IProps {
  routes: RoutesIDs;
  field: TenderFormTableField;
  onSetDataFiles: (routeId: RoutesID, file: UploadFile[]) => void;
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
}

export const TenderOwnFormTableFieldsetRow: FC<IProps> = ({ routes, field, onSetDataFiles, dataFiles }) => {
  const dispatch = useDispatch();
  const editingValuesInRoutes = useSelector(dataRoutesEditingSelector);
  const { data } = useSelector(routesDataSelector(field));

  const [editing, setEditing] = useState(editingValuesInRoutes);

  const handleEditing = ({ id, field, editing = false }: { id: RoutesID; field: TenderFormTableField; editing: boolean }) => {
    setEditing((prev) => ({ ...prev, [id]: { ...prev[id], [field]: editing } }));
  };

  const handleSave: ITenderRotesFunctions['handleSave'] = ({ id, field, value }) => {
    dispatch(updateTenderForRouteRoutine({ ids: [id], field, value }));
  };

  return (
    <div className={'tender-from-planner-form-table-fieldset-row'}>
      {routes.map((id) => {
        return (
          <TenderOwnFormTableVirtualInput
            key={field + id}
            id={id}
            field={field}
            editing={editing?.[id]?.[field]}
            value={data?.[id]?.[field]}
            handleEditing={handleEditing}
            editable={TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field]?.editable}
            handleSave={handleSave}
            onSetDataFiles={onSetDataFiles}
            dataFiles={dataFiles}
          />
        );
      })}
    </div>
  );
};
