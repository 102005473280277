import React, { FC } from 'react';
import { Col, ColProps, Row, RowProps } from 'antd';

export const ILSRow: FC<RowProps> = (props) => {
  return <Row {...props} />;
};

export const ILSCol: FC<ColProps> = (props) => {
  return <Col {...props} />;
};
