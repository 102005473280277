import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, ModulePath, Notify, NotifyDurationInSecond } from '@common/types';
import { monitoringDashboardRoutinesToNotify } from '../../actions';
import { DashboardAction, defaultStatuses } from '../../constants/default-messages';
import { getErrorMessage } from '@common/utils';

//TODO рефакторинг
//Похоже что в каждом модуле требуется одинаковый или похожий функционал по созданию саг с уведомлениями

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: DashboardAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || defaultStatuses[action][type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): DashboardAction => {
  return actionName as DashboardAction;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, DashboardAction>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Monitoring}${ModulePath.MonitoringDashboard}`,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, DashboardAction>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Monitoring}${ModulePath.MonitoringDashboard}`,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* monitoringDashboardNotifierWatcher() {
  for (let routine of monitoringDashboardRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
