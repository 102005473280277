import { TrackAbstract, TrackValid } from '../types/map';

// Утилита фильтации валидных треков
export const filterInvalidTrack = (track: TrackAbstract) =>
  Object.keys(track).reduce((clearTrack, currentDT) => {
    if (
      track[currentDT]?.Type !== null &&
      typeof track[currentDT]?.Type === 'number' &&
      Number(track[currentDT].Type) >= 0 &&
      track[currentDT]?.Valid !== null &&
      typeof track[currentDT]?.Valid === 'number' &&
      Number(track[currentDT].Valid) === TrackValid.Valid
    ) {
      clearTrack[currentDT] = track[currentDT];
    }
    return clearTrack;
  }, {}) as TrackAbstract;
