import { tenderTemplateFullRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderChangeTemplateRoutine } from '@modules/tender-platform/actions';
import { isArray } from 'lodash';

export function* tenderTemplateFullWorker(
  action: PayloadAction<{
    lotID: number | string;
    tenderID?: number | string;
  }>
) {
  const { request, failure, fulfill } = tenderTemplateFullRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.getTemplateFull, action.payload);
    yield put(tenderChangeTemplateRoutine.trigger({ TenderTemplate: isArray(data) ? data[0] : data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderTemplateFullWatcher() {
  yield takeLatest(tenderTemplateFullRoutine.trigger, tenderTemplateFullWorker);
}
