import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogBindTimeZoneRoutine } from '@modules/catalog/actions';
import { ICatalogDataItem } from '@modules/catalog/types/catalog';
import { onRequest } from '@modules/catalog/reducers/helpers';

const bindTimeZoneReducer = createReducer(initialState, {
  [catalogBindTimeZoneRoutine.REQUEST]: onRequest,
  [catalogBindTimeZoneRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      records: Array<{
        id: number;
        latitude: number;
        longitude: number;
        timezone: string;
        UTCOffset: number;
      }>;
    }>
  ) => {
    let { records, dictionary } = action.payload;
    let oldData = state[dictionary].data;
    oldData = oldData.map((item: ICatalogDataItem) => {
      const updatedRecord = records.find((record) => record.id && Number(record.id) === Number(item.ID));
      if (updatedRecord?.UTCOffset || updatedRecord?.timezone) {
        return {
          ...item,
          ...{
            UTCOffset: updatedRecord.UTCOffset,
            TimeZone: updatedRecord.timezone
          }
        };
      }
      return item;
    });
    state[dictionary].data = oldData;
    return state;
  },
  [catalogBindTimeZoneRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogBindTimeZoneRoutine.FULFILL]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;
    state[dictionary].isFetching = false;
    state[dictionary].error = [];
    state[dictionary].execStatus = undefined;
    return state;
  }
});

export default bindTimeZoneReducer;
