import { FC } from 'react';
import { useSelector } from 'react-redux';
import { routesSelectedSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { ILSResultEfficiencyModalComponent } from '../../../components/result/efficiency/modal';
import { sortBy } from 'lodash';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';
import { Efficiency } from '@common/types';

interface IILSREMC {
  isModalVisible: boolean;
  handleShow(): void;
}

export type TripEfficiency = {
  Efficiency: Efficiency;
  color: string | null;
  VehicleID: number;
};
export type TripsEfficiency = TripEfficiency[];

export const ILSResultEfficiencyModalContainer: FC<IILSREMC> = ({ isModalVisible, handleShow }) => {
  const routes = useSelector(routesSelectedSelector);

  const tripsEfficiency: TripsEfficiency = [];

  routes.forEach((route) => {
    for (const tripID in route.TripTotal) {
      tripsEfficiency.push({
        Efficiency: route.TripTotal[tripID].Efficiency,
        color: getVehicleRouteColor(route.Vehicle),
        VehicleID: route.Vehicle.ID
      });
    }
  });

  return (
    <ILSResultEfficiencyModalComponent
      handleOk={handleShow}
      trips={sortBy(tripsEfficiency, ['VehicleID'], ['asc'])}
      isModalVisible={isModalVisible}
    />
  );
};
