import { FC } from 'react';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { VEHICLE_LOAD_BY_TYPE } from '@modules/orders-and-trips/constants/form';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';

interface IProps {
  tripDetails: {
    distance: number;
    mass: number;
    volume: number;
    vehicleType: number;
    loadingType: string;
  };
  vehicleTypes: VehicleType | null;
}

export const DetailsContentGeneral: FC<IProps> = ({ tripDetails, vehicleTypes }) => {
  return (
    <div className="details-content-general">
      <div className="details-content-group">
        <ILSSVG config={SVG_CONFIG} icon={SVG.VEHICLE} />
        <span className="vehycle-type">Тип ТС ({vehicleTypes?.[tripDetails.vehicleType]?.Name ?? 'не определен'})</span>
      </div>

      <div className="details-content-group">
        <span>Погрузка: {VEHICLE_LOAD_BY_TYPE?.[tripDetails.loadingType]}</span>
      </div>

      <div className="details-content-group">
        <ILSSVG config={SVG_CONFIG} icon={SVG.MASS} />
        <span className="tonage-quantity">{tripDetails.mass} кг</span>
      </div>

      <div className="details-content-group">
        <ILSSVG config={SVG_CONFIG} icon={SVG.VOLUME} />
        <span className="tonage-quantity">{tripDetails.volume} м3</span>
      </div>
    </div>
  );
};

