import { isNil, isObject, transform } from 'lodash';

/**
 * Функция создающая новый объект исключая null и undefined свойства, в том числе и во вложенных объектах
 * Не меняет исходный объект
 * Не удаляет свойства из массива
 * Используется рекурсия
 * @param obj исходный объект
 * 
 * @example
 * obj = {
      a: 1,
      b: [2, undefined, 4],
      c: undefined,
      d: null,
      e: {
        f: null
      }
    }
 * return { a: 1, b: [2, undefined, 4], e: {} }
 */
export const cleanNil = <T extends Object>(obj: T): T =>
  transform(obj, function (result: T, value: any, key: string) {
    if (isObject(value)) {
      result[key] = cleanNil(value);
    } else if (!isNil(value)) {
      result[key] = value;
    }
  });
