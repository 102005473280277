import { FC, memo } from 'react';
import { MainMapContainer } from '../containers/main-map';
import { MainTableContainer } from '../containers/main-table';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import { DetailsTableContainer } from '../containers/details-table';
import { ILSDashboardMetricsContainer } from '@modules/monitoring/children/dashboard/containers/dashboard-metrics';
import ILSMonitoringDateSelectComponent from '@modules/monitoring/components/date-select';
import { MIN_MAP_SPLIT_SIZE, MIN_TABLE_SPLIT_SIZE } from '../configs/table';
import { EMPTY_STRING } from '@common/constants';
import { HandleResize, MonitoringEventDetailsResizeComponent } from '@modules/monitoring/children/event-details/types/resizer';

interface Props {
  showMap?: boolean;
  initialSizes?: [number, number];
  tablesSplitInitialSizes?: [number, number];
  handleDateSelect: (date: string[]) => void;
  handleRedirect: (name: string) => void;
  handleResize: HandleResize;
  maxInterval: string | number | boolean | undefined;
}

//NOTE это UI компонент для разделения элементов на splitter
export const TablesWithMapComponent: FC<Props> = memo(
  ({ showMap, handleResize, initialSizes, tablesSplitInitialSizes, handleDateSelect, handleRedirect, maxInterval }) => {
    return (
      <ReactSplit
        direction={SplitDirection.Horizontal}
        initialSizes={initialSizes}
        minWidths={MIN_MAP_SPLIT_SIZE}
        classes={[EMPTY_STRING, 'ils-monitoring-map-layout']}
        gutterClassName={showMap ? 'ils-monitoring-details-gutter-horizontal' : 'hide-gutter'}
        onResizeFinished={handleResize(MonitoringEventDetailsResizeComponent.map)}
      >
        <div className="ils-monitoring-main-wrapper">
          <ILSMonitoringDateSelectComponent handleDate={handleDateSelect} maxInterval={maxInterval} />
          <ILSDashboardMetricsContainer handleRedirect={handleRedirect} />
          <div className="ils-monitoring-table-wrapper">
            <ReactSplit
              direction={SplitDirection.Vertical}
              initialSizes={tablesSplitInitialSizes}
              classes={['main-table', 'bottom-component']}
              gutterClassName={'ils-monitoring-details-gutter-vertical'}
              minHeights={MIN_TABLE_SPLIT_SIZE}
            >
              <MainTableContainer />
              <DetailsTableContainer />
            </ReactSplit>
          </div>
        </div>
        <MainMapContainer />
      </ReactSplit>
    );
  }
);
