import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { updateTemplateRoutine } from '@modules/documents/children/templates/actions';
import DocumentTemplatesStore from '@modules/documents/children/templates/types';
import { TemplateData } from '@modules/documents/children/templates/types/api';

export const updateTemplate = createReducer(initialState, {
  [updateTemplateRoutine.REQUEST]: (state: DocumentTemplatesStore) => {
    state.isFetching = true;
    return state;
  },
  [updateTemplateRoutine.SUCCESS]: (state: DocumentTemplatesStore, action: { payload: TemplateData }) => {
    state.currentTemplate = action.payload;
    return state;
  },
  [updateTemplateRoutine.FULFILL]: (state: DocumentTemplatesStore) => {
    state.isFetching = false;
    return state;
  }
});
