import { ChangePasswordPayload } from '@modules/settings/types/reducers';
import { passwordChangeRoutine } from '@modules/settings/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SettingsAPI } from '@modules/settings/api';

function* passwordChangeWorker(action: ChangePasswordPayload) {
  const { request, success, failure, fulfill } = passwordChangeRoutine;

  try {
    yield put(request({ isFetching: true }));
    const { currentPassword, newPassword } = action.payload;
    yield call(SettingsAPI.changePassword, { currentPassword, newPassword });

    yield put(success({}));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

export function* passwordChangeWatcher() {
  yield takeLatest(passwordChangeRoutine.trigger, passwordChangeWorker);
}
