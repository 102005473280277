import MainPage from '@pages/main';
import AuthPage from '@pages/auth';
import NotFoundPage from '@pages/404';
import ErrorPage from '@pages/error';
import LimitedPage from '@pages/limited';
import CustomForAuthPage from '@pages/custom-for-auth';
import CustomForNonAuthPage from '@pages/custom-for-non-auth';

const Pages = {
  AuthPage,
  CustomForAuthPage,
  CustomForNonAuthPage,
  MainPage,
  LimitedPage,
  ErrorPage,
  NotFoundPage // должна быть последней, чтоб маршрут был последним
};

export default Pages;
