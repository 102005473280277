import { createSelector } from 'reselect';
import { TenderSearchState } from '@modules/tender-platform/children/tender-search/types/store';
import { Tender } from '@modules/tender-platform/types';

export const tendersSelector = createSelector(
  (state: TenderSearchState) => state.TenderSearch.tenders,
  (tenders: Array<Tender>) => tenders
);

export const myTendersSelector = createSelector(
  (state: TenderSearchState) => state.TenderSearch.myTenders,
  (tenders: Array<Tender>) => tenders
);

export const tenderFiltersSelector = createSelector(
  (state: TenderSearchState) => state.TenderSearch.filters,
  (filters) => filters
);
