import { DOCUMENTS_EXTENSION } from '@common/constants/general/path';
import { LocalDocumentType } from '@common/components/common/document-download-card/document-download-card';

export const EXTERNAL_TRANSPORT_COMPANIES: Array<LocalDocumentType> = [
  {
    id: 0,
    name: 'ГрузовичкоФ',
    img: '/assets/images/transport-company/gf.png',
    filePath: '/assets/files/gruzovichkof.pdf',
    fileName: 'Правила перевозок ГрузовичкоФ',
    extension: DOCUMENTS_EXTENSION.pdf
  },
  {
    id: 1,
    name: 'СДЭК',
    img: '/assets/images/transport-company/sdek.png',
    filePath: '/assets/files/sdek.pdf',
    fileName: 'Правила перевозок СДЭК',
    extension: DOCUMENTS_EXTENSION.pdf
  },
  {
    id: 2,
    name: 'ПЭК',
    img: '/assets/images/transport-company/pek.png',
    filePath: null,
    fileName: 'Правила перевозок ПЭК',
    extension: DOCUMENTS_EXTENSION.pdf
  },
  {
    id: 3,
    name: 'Yandex Express',
    img: '/assets/images/transport-company/yandex_express.png',
    filePath: null,
    fileName: 'Правила перевозок Yandex Express',
    extension: DOCUMENTS_EXTENSION.pdf
  },
  {
    id: 4,
    name: 'Деловые линии',
    img: '/assets/images/transport-company/dl.png',
    filePath: null,
    fileName: 'Правила перевозок Деловые линии',
    extension: DOCUMENTS_EXTENSION.pdf
  },
  {
    id: 5,
    name: 'КСЕ',
    img: '/assets/images/transport-company/kse.png',
    filePath: null,
    fileName: 'Правила перевозок КСЕ',
    extension: DOCUMENTS_EXTENSION.pdf
  }
];

