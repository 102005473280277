import { ILSPhoneInput } from '@components/index';
import { EMPTY_STRING, KeyboardCode, PHONE_PREFIX } from '@common/constants/general';
import { EventBlurNFocus, IILSCellPhoneProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isNil, isString } from 'lodash';

export const ILSCellPhone = <R extends TableRecord>({ toggleEdit, children, save }: IILSCellPhoneProps<R>): JSX.Element => {
  const [node, phone = EMPTY_STRING] = children;

  const onSave = (e: EventBlurNFocus) => {
    if (e.target.value === phone) {
      toggleEdit?.();
      return;
    } else {
      e.key === KeyboardCode.Enter && e.stopPropagation();
      if (e.target.value.trim() === PHONE_PREFIX) {
        save(null);
      } else {
        save(e.target.value);
      }
    }
  };

  if (!isString(phone) && !isNil(phone)) {
    showUserAndDevError({ error: 'Телефон должен быть передан строкой' });
    return <ILSPhoneInput inputProps={{ autoFocus: true }} onBlur={onSave} onEnterKeyPress={onSave} value={EMPTY_STRING} />;
  }

  return (
    <>
      {node}
      <ILSPhoneInput inputProps={{ autoFocus: true }} onBlur={onSave} onEnterKeyPress={onSave} value={phone ?? EMPTY_STRING} />
    </>
  );
};
