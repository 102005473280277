import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getTripsRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState, PaginationType } from '@modules/orders-and-trips/types/general';
import { TripResponse, TripStatus } from '@modules/orders-and-trips/types/trip';
import { tripsTodata } from '@modules/orders-and-trips/decorators/trips-to-data';
import { compareAsString } from '@common/utils';
import { IndexedArray } from '@common/types';

export const getTripsReducer = createReducer(initialState, {
  [getTripsRoutine.REQUEST]: (state: InitialState) => {
    state.trips.isFetching = true;
    return state;
  },
  [getTripsRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: {
        data: IndexedArray<TripResponse>;
        pager: Required<PaginationType>;
      };
      source: TripStatus;
    }>
  ) => {
    const { data, source } = action.payload;
    const newTrips = tripsTodata(data?.data ?? {});
    state.trips = {
      ...state.trips,
      data: compareAsString(data?.pager.page, 1) ? newTrips : { ...state.trips.data, ...newTrips },
      activeRecord: null,
      tripStatus: source,
      pagination: data?.pager
    };
    return state;
  },
  [getTripsRoutine.FAILURE]: onFailure,
  [getTripsRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.trips.isFetching = false;
    return state;
  }
});

