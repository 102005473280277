import { FC } from 'react';
import { RoutesIDs } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderOwnFormTableRouteHeaderWithValidation } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/route-header-with-validation';
import { ILSAlert } from '@common/components';
import { useSelector } from 'react-redux';
import { validateIsAllRoutesDoneSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { Notify } from '@common/types';

interface IProps {
  routes: RoutesIDs;
}

export const TenderOwnFormTableHeader: FC<IProps> = ({ routes }) => {
  const disabled = useSelector(validateIsAllRoutesDoneSelector);

  return (
    <div className={'tender-from-planner-form-table-header'}>
      <ILSAlert
        type={disabled ? Notify.Error : Notify.Success}
        message={disabled ? 'Заполните все обязательные поля' : 'Тендеры готовы для сохранения'}
        className={'tender-from-planner-form-table-header-info'}
      />
      {routes.map((id) => {
        return <TenderOwnFormTableRouteHeaderWithValidation key={id} id={id} />;
      })}
    </div>
  );
};
