import { FC } from 'react';
import { ILSForm } from '@common/components';
import { ILSTimeline, ILSTimelineItem } from '@common/components/data-display/timeline';
import { FieldChangeData, FormTrip, ITripFormValues, TimeLineLabels } from '@modules/orders-and-trips/types/form';
import { ButtonTypes } from '@common/types/components';
import { FormInstance } from 'antd/es/form/Form';
import { OrdersNTripsubmitBlock } from '@modules/orders-and-trips/components/forms/blocks/order-and-trip-submit';
import { SourceSection } from '@modules/orders-and-trips/components/forms/sections/source-section';
import { TargetSection } from '@modules/orders-and-trips/components/forms/sections/target-section';
import { TransportRequirementSection } from '@modules/orders-and-trips/components/forms/sections/transport-requirement-section';
import { FormType } from '@modules/orders-and-trips/types/general';
import { IEnum } from '@common/types';
import { ActionTypeID } from '@modules/orders-and-trips/types/trip';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { VehicleTypesOptions } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';

interface IProps {
  onFinish(formValues: ITripFormValues): void;

  form: FormInstance<any>;
  formTitle: string;
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
  showForm: FormType | null;
  restriction: IEnum<string>[];
  vehicleTypes: VehicleTypesOptions;
  formTrip: FormTrip;
  disabled: boolean;
  activeRecordID: number | string | undefined;

  onFieldsChange(changedFields: FieldChangeData[], allFields: FieldChangeData[]): void;

  lockedButtons: Partial<Record<ButtonTypes, boolean>>;
}

export const OrderNTripFormComponent: FC<IProps> = ({
  onFinish,
  form,
  formTitle,
  vehicleTypes,
  submitButtonProps,
  showForm,
  formTrip,
  restriction,
  disabled,
  activeRecordID,
  onFieldsChange,
  lockedButtons
}) => {
  return (
    <ILSForm onFieldsChange={onFieldsChange} className="order-action-form" name={'order-action-form'} onFinish={onFinish} form={form}>
      <h2>
        {formTitle} {activeRecordID ? ` №${activeRecordID}` : activeRecordID}
      </h2>
      <ILSTimeline className="order-action-form-timeline" mode={'left'}>
        {formTrip?.map((action, index) => {
          switch (action.ActionType) {
            case ActionTypeID.Load:
              return (
                <ILSTimelineItem
                  dot={<ILSSVG config={SVG_CONFIG} icon={SVG.DOWNLOAD} className={'timeline-svg'} />}
                  label={TimeLineLabels.Load}
                  key={index}
                >
                  <SourceSection
                    pointIndex={index}
                    actionCargo={action?.Cargo}
                    restriction={restriction}
                    disabled={disabled}
                    showForm={showForm}
                  />
                </ILSTimelineItem>
              );
            case ActionTypeID.Unload:
              return (
                <ILSTimelineItem
                  dot={<ILSSVG config={SVG_CONFIG} icon={SVG.UNLOAD} className={'timeline-svg'} />}
                  label={TimeLineLabels.Unload}
                  key={index}
                >
                  <TargetSection
                    pointIndex={index}
                    actionCargo={action?.Cargo}
                    restriction={restriction}
                    disabled={disabled}
                    showForm={showForm}
                  />
                </ILSTimelineItem>
              );
          }
        })}
      </ILSTimeline>
      <TransportRequirementSection showForm={showForm} vehicleTypes={vehicleTypes} />
      <OrdersNTripsubmitBlock lockedButtons={lockedButtons} submitButtonProps={submitButtonProps} showForm={showForm} />
    </ILSForm>
  );
};
