import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringUpdateTrackerRoutine } from '../actions';

// Обновляет информацию по трекеру
export const monitoringUpdateTrackerReducer = createReducer(initialState, {
  [monitoringUpdateTrackerRoutine.REQUEST]: (state) => {
    state.trackers.isFetching = true;

    return state;
  },
  [monitoringUpdateTrackerRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const updatedTracker = action.payload;
    const trackerIndex = state.trackers.data.findIndex((tracker) => tracker.IMEI === updatedTracker.IMEI);
    if (trackerIndex !== -1) {
      state.trackers.data[trackerIndex] = updatedTracker;
      state.trackers.lastUpdate = new Date().getTime();
    }

    return state;
  },
  [monitoringUpdateTrackerRoutine.FAILURE]: (state) => {
    state.trackers.lastUpdate = undefined;

    return state;
  },
  [monitoringUpdateTrackerRoutine.FULFILL]: (state) => {
    state.trackers.isFetching = false;
    return state;
  }
});
