import { getEnumField } from '@common/utils';
import { ENUM_STRING } from '@common/utils/helpers/object/get-enum-field';
import { getEnumForMultipleCell } from '@components/data-display/table/utils/helpers/get-enum-for-multiple-cell';
import { savedCellToCell } from '@components/data-display/table/utils/decorators/multie-edit-cell-decorator';
import { CellType, Dictionary, ReactDispatch, TableColumnName } from '@common/types';
import { CellRecord } from '@common/types/components';

export type MultipleCellDataArgs = {
  dataIndex: TableColumnName;
  value: unknown;
  type: CellType;
  format?: string;
  dictionary?: Dictionary;
  cellRecord?: CellRecord | CellRecord[];
};

export const setMultipleCellDataHandler =
  ({ dataIndex, value, type, format, dictionary, cellRecord }: MultipleCellDataArgs) =>
  (multipleCellData: Record<TableColumnName, unknown> | {}) => ({
    ...multipleCellData,
    [dataIndex]: {
      [getEnumField(dataIndex) || dictionary + ENUM_STRING]: getEnumForMultipleCell({ cellRecord, value }),
      value: savedCellToCell({ value, type, format })
    }
  });

export const handleMultipleCellData =
  ({ setMultipleCellData }: { setMultipleCellData: ReactDispatch<Record<TableColumnName, unknown> | {}> }) =>
  ({ dataIndex, value, type, format, dictionary, cellRecord }: MultipleCellDataArgs) => {
    setMultipleCellData(setMultipleCellDataHandler({ dataIndex, value, type, format, dictionary, cellRecord }));
  };
