import { FilesLocalForage } from '@common/utils/general/files-local-forage';

export const useFilesStorage = () => {
  const saveFile = (key: string, value: any) => {
    return FilesLocalForage.setItem(key, value);
  };

  const loadFile = (key: string) => {
    return FilesLocalForage.getItem(key);
  };

  return { saveFile, loadFile };
};
