import { IndexedArray } from '@common/types';
import { AbstractBoard, AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { IModalBoards } from '@modules/analytics/planning/children/planning-analytics/types/modal-board';
import analyticsService from '../service';
import { sortListBySerial } from '@modules/analytics/common/utils/sort-boards';

interface Props {
  ref: React.RefObject<HTMLDivElement>;
  boards: IndexedArray<AbstractBoard>;
  setIsLoading: (value: boolean) => void;
  setModalBoards: (data: IModalBoards[]) => void;
  setIsActive: (value: boolean) => void;
  setBoardWidgets: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

const useGeneratePDF = (props: Props) => {
  const { boards, setIsLoading, setModalBoards, setIsActive, setBoardWidgets } = props;

  const generatePDF = async () => {
    let res: [string, number, number, IndexedArray<AnalyticsWidget>][] = [];
    let boardWidgets: { [key: string]: boolean } = {};

    setIsLoading(true);
    for (let board of sortListBySerial(boards)) {
      const data = await analyticsService.getBoardsForPDF(board.ID);

      if (data) {
        res.push([board.Name as string, board.From as number, board.Till as number, data]);

        boardWidgets = {
          ...boardWidgets,
          ...Object.values(data).reduce((prev, curr) => {
            prev[curr.ID] = false;

            return prev;
          }, {})
        };
      }
    }

    const data = res
      .map((item) => ({
        BoardTitle: item[0],
        From: item[1],
        Till: item[2],
        data: item[3]
      }))
      .filter((item) => !Array.isArray(item.data));

    setModalBoards(data);
    setIsLoading(false);
    setIsActive(true);
    setBoardWidgets(boardWidgets);

    // Нужно для выгрузки в PDF

    //@ts-ignore

    // const boardElement = modalRef.current;
    // if (boardElement) {
    //   boardToPdf({
    //     board: boardElement,
    //     canvasHeight: boardElement.clientHeight,
    //     canvasWidth: boardElement.clientWidth,
    //     fileName: title
    //   });
    // }
  };

  return { generatePDF };
};

export default useGeneratePDF;
