import '../styles.less';
import CustomScroll from 'react-customscroll';
import { QuestionsVideoContainer } from '@common/feature/questions/containers/questions-video-container';
import { QuestionsAndAnswersContainer } from '@common/feature/questions/containers/questions-and-answers-container';
import { QuestionsRulesContainer } from '@common/feature/questions/containers/questions-rules-container';

export const QuestionsContainer = () => {
  return (
    <div className="questions-container">
      <CustomScroll>
        <QuestionsVideoContainer />
        <QuestionsAndAnswersContainer />
        <QuestionsRulesContainer />
      </CustomScroll>
    </div>
  );
};

