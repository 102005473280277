import { useMemo, useState } from 'react';
import { Dictionary } from '@common/types';

export const useTableExportModal = (dictionary?: Dictionary | null) => {
  const [openDictionary, setOpenDictionary] = useState<Dictionary | null>(null);

  const handleOpenExportModal = (dictionary: Dictionary | null) => setOpenDictionary(dictionary);

  const handleClose = () => setOpenDictionary(null);

  const visible = useMemo(() => {
    return openDictionary === dictionary;
  }, [dictionary, openDictionary]);

  return { visible, handleClose, handleOpenExportModal };
};
