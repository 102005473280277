import { MonitoringTripOnTimeDataModel } from '@common/types/dictionaries/monitoring-trip';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { monitoringDashboardUpdateViewRoutine } from '../actions/index';
import { DashboardTablesObjMetrics, TABLES_TRIP_VIEW, TABLES_WAYPOINT_VIEW } from '../constants';
import { DashboardSensorNameViewed } from '../constants/tables';
import { initialState } from '../store';
import { MonitoringDashboardState } from '../types/store';
import { updateViewBDD } from '../utils/update-bdd-view';
import { updateFuelPointView } from '../utils/update-fuel-point-view';
import { updateViewedTableTripData, updateViewedTableWaypointData } from '../utils/update-view-data';

// Обновляет данные метрик
//TODO рефакторинг
export const monitoringUpdateViewReducer = createReducer<MonitoringDashboardState>(initialState as MonitoringDashboardState, {
  [monitoringDashboardUpdateViewRoutine.REQUEST]: (state) => {
    return state;
  },
  [monitoringDashboardUpdateViewRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      activeTab: DashboardTablesObjMetrics | DashboardSensorNameViewed;
      DependentKey: Array<string> | string;
      Value: 0 | 1;
    }>
  ) => {
    const { activeTab, DependentKey, Value } = action.payload;
    if (activeTab) {
      if (activeTab === DashboardSensorNameViewed.FuelKeyPoint || activeTab === DashboardSensorNameViewed.SensorValue) {
        if (activeTab === DashboardSensorNameViewed.FuelKeyPoint) {
          const FuelDrainData = state.dashboardTableData?.[DashboardTablesObjMetrics.FuelDrain]?.data;
          updateFuelPointView(FuelDrainData, DependentKey, Value);
        } else {
          const bddData = state.dashboardTableData?.[DashboardTablesObjMetrics.Violations]?.data;
          updateViewBDD(bddData, DependentKey, Value);
        }
      } else if (
        state.dashboardTableData?.[activeTab]?.data &&
        (TABLES_WAYPOINT_VIEW.includes(activeTab) || TABLES_TRIP_VIEW.includes(activeTab))
      ) {
        for (const vehicleIndex in state.dashboardTableData[activeTab].data) {
          const vehicle = state.dashboardTableData[activeTab].data[vehicleIndex] as MonitoringTripOnTimeDataModel;
          if (!vehicle?.Trip || !Object.keys(vehicle.Trip).length) {
          }
          if (TABLES_TRIP_VIEW.includes(activeTab)) {
            vehicle.Trip = updateViewedTableTripData(vehicle.Trip, DependentKey, Value);
          } else {
            for (const tripIndex in vehicle.Trip) {
              const trip = vehicle.Trip[tripIndex];
              if (isEmpty(trip?.Waypoint)) {
                continue;
              }
              trip.Waypoint = updateViewedTableWaypointData(trip.Waypoint, DependentKey, Value);
            }
          }
        }
      }
    }

    return state;
  },
  [monitoringDashboardUpdateViewRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringDashboardUpdateViewRoutine.FULFILL]: (state) => {
    return state;
  }
});

