import moment from 'moment';
import { FormFieldNames, RequestOrderData, IOrdeFormValues } from '@modules/orders-and-trips/types/form';
import { CARGO_FIELDS, TIME_FORM_FIELDS } from '@modules/orders-and-trips/constants/form';
import { isNil } from 'lodash';
import { DateTimeFormat } from '@common/types';

export const orderFormValuesToData = (formValues: IOrdeFormValues): RequestOrderData => {
  return Object.values(formValues).reduce((summary, formData, index) => {
    Object.keys(formData).forEach((key) => {
      switch (true) {
        case [FormFieldNames.LoadDate, FormFieldNames.UnloadDate].includes(key as FormFieldNames):
          if (moment.isMoment(formData[key])) {
            summary[key] = moment(formData[key]).unix();
            if (key === FormFieldNames.LoadDate) {
              summary[FormFieldNames.LoadDateLocal] = moment(formData[key]).format(DateTimeFormat.YYYYMMDDDashHHMM);
            }
          }
          break;
        case [FormFieldNames.SourceID, FormFieldNames.TargetID].includes(key as FormFieldNames):
          summary[key] = formData[key];
          break;
        case TIME_FORM_FIELDS.includes(key as FormFieldNames):
          if (moment.isMoment(formData[key])) {
            summary[key] = moment(formData[key]).unix();
          }
          break;
        case key === FormFieldNames.Cargo:
          summary[key] = CARGO_FIELDS.reduce((cargoSummary: RequestOrderData[FormFieldNames.Cargo], cargoField) => {
            formData[key]?.[cargoField]?.forEach((value: string | number, index: number) => {
              if (!isNil(value)) {
                if (!cargoSummary[index]) cargoSummary[index] = {} as RequestOrderData[FormFieldNames.Cargo][0];
                cargoSummary[index][cargoField] = value;
              }
            });
            return cargoSummary;
          }, []);
          break;
        default:
          summary[key] = formData[key];
      }
    }, {});
    return summary;
  }, {} as RequestOrderData);
};

