import { call, put, takeLatest } from 'redux-saga/effects';
import { getAvailableIntegrationsRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* getAvailableIntegrationsWorker() {
  const { request, success, failure, fulfill } = getAvailableIntegrationsRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getAvailableIntegrations);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getAvailableIntegrationsWatcher() {
  yield takeLatest(getAvailableIntegrationsRoutine.trigger, getAvailableIntegrationsWorker);
}
