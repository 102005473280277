import { apiInstance } from '@core/api/instance';
import { IAccountAttachmentsAPI } from '../types/api';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const AccountAttachmentAPI: IAccountAttachmentsAPI = {
  index: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Index), payload),
  create: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Create), payload),
  delete: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Delete), payload)
};
