import { initialState } from '@modules/account/store';
import { accountAttachmentIndexRoutine } from '@modules/account/actions';
import { onFailure } from '@common/utils';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { AccountAttachmentCategory, AccountFileAttachment } from '@modules/account/types/attachments';

export const accountAttachmentIndexReducer = createReducer(initialState, {
  [accountAttachmentIndexRoutine.REQUEST]: (state: typeof initialState) => {
    state.extraAttachments.isFetching = true;
    return state;
  },
  [accountAttachmentIndexRoutine.SUCCESS]: (
    state: typeof initialState,
    action: PayloadAction<{
      accountAttachmentCategory: AccountAttachmentCategory;
      data: AccountFileAttachment[];
    }>
  ) => {
    const { accountAttachmentCategory, data } = action.payload;
    state.extraAttachments[accountAttachmentCategory] = data;
    return state;
  },
  [accountAttachmentIndexRoutine.FAILURE]: onFailure,
  [accountAttachmentIndexRoutine.FULFILL]: (state: typeof initialState) => {
    state.extraAttachments.isFetching = false;
    return state;
  }
});
