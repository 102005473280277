export enum PlanningSettingsKeys {
  Target = 'target',
  ActiveWidth = 'activeWidth',
  InactiveWidth = 'inactiveWidth',
  ActiveColored = 'activeColored',
  InactiveColored = 'inactiveColored',
  UseFigureMarker = 'useFigureMarker',
  ValidateOnPlanning = 'validateOnPlanning',
  ValidateOnImport = 'validateOnImport',
  InterruptOnValidationErrorLevel = 'interruptOnValidationErrorLevel',
  UnresolvedMarkerSource = 'unresolvedMarkerSource',
  DrawPairForUnresolved = 'drawPairForUnresolved',
  HideLinesOnTrackAbsent = 'hideLinesOnTrackAbsent',
  UseRedis = 'useRedis',
  MapZoomCluster = 'mapZoomCluster',
  GeocodeService = 'geocodeService',
  DisableClustering = 'disableClustering',
  EnableAutoFocusOnChangeTrip = 'enableAutoFocusOnChangeTrip',
  ActiveLockTooltip = 'activeLockTooltip'
}

export enum UnresolvedMarkerSourceValue {
  Target = 'target',
  Source = 'source'
}

export enum GeocodeService {
  Yandex = 1,
  DaData = 2
}
