import { Depot } from './depot';
import { Restriction } from './restriction';
import { Vehicle, VehicleTimeline } from './vehicle';
import { Zone } from './zone';
import { TripTemplate } from './trip';
import { Account, ID, IEnum, RatingDependent, TimeStamp, TransportCompany, User } from '@common/types';

export type DriverRating = {
  AccountID: Account['ID'];
  Comment: string;
  Dependent: RatingDependent.Driver;
  DependentID: ID;
  ID: ID;
  RatingCriteriaID: ID;
  RatingValue: number;
  SetAccountID: ID;
};

export type DriverModel = {
  ID: number;
  Name: string;
  Phone: string | null;
  PassportNumber: string | null;
  PassportDate: string | null;
  PassportIssuedBy: string | null;
  LicenseNumber: string | null;
  LicenseDate: string | null;
  LicenseCategory: string | null;
  Birthday: number | null;
  DriverCode: string | null;
  Type: number | null;
  Priority: number | null;
  TenderRoutes?: number;
  DriverRating?: DriverRating | number | null;
  Deleted: TimeStamp | null;
  TransportCompanyID?: number | null;
  MobileApplication?: DriverMobileApplication | null;
  Info: null;
  DriverTimelineID: DriverTimeline['ID'] | null;
  VehicleTimelineID: VehicleTimeline['ID'] | null;
};

export type Driver = DriverModel & {
  TransportCompanyEnum: IEnum<TransportCompany['Name']>;
  DriverTimeline?: DriverTimeline;
  Restriction: Array<Restriction> | Restriction;
  Vehicle: Array<Vehicle> | Vehicle;
  VehicleTimeline?: VehicleTimeline;
  Warehouse?: Depot;
  Zone: Array<Zone> | Zone;
  TripTemplate: Array<TripTemplate> | TripTemplate;
  User?: Array<User>;
};

export type DriverTimeline = {
  ID: number;
  Name: string | null;
  StartTime?: number | null;
  WeekDays: string | null;
  Deleted: TimeStamp | null;
  DaysOfWork?: number | null;
  DaysOfRest?: number | null;
  WorkPerWeek?: number | null;
  WorkPerTwoWeek?: number | null;
};
export type DriverTimelines = Array<DriverTimeline>;

export enum DriverMobileApplication {
  No,
  Yes
}

export enum DriverRestMode {
  RestOff = 0,
  WithoutUploading = 1,
  RestDuringUploading = 2,
  WorkDuringUploading = 3
}

export enum DriverType {
  Driver,
  Expeditor,
  Dispatcher,
  Another
}

export enum DriverTypeLabel {
  Driver = 'Водитель',
  Expeditor = 'Экспедитор',
  Dispatcher = 'Диспетчер',
  Another = 'Другое'
}

export enum SpeedDelta {
  SlowerTen = '-10',
  FastTen = '10',
  ExactInTime = '0'
}

export enum SpeedDeltaLabel {
  SlowerTen = 'Медленнее на 10 км/ч',
  FastTen = 'Быстрее на 10 км/ч',
  ExactInTime = 'Точно по графику'
}

export enum NextPointsDepartureDelta {
  SlowerTen = '-10',
  FastTen = '10',
  ExactInTime = '0'
}

export enum NextPointsDepartureDeltaLabel {
  SlowerTen = 'С опережением 10 мин',
  FastTen = 'С опозданием 10 мин',
  ExactInTime = 'По расписанию'
}

export enum DriverRestModeLabel {
  RestOff = 'Режим отдыха водителя выключен при планировании',
  WithoutUploading = 'Планирование с учетом режима отдыха водителя без учета времени разгрузки',
  RestDuringUploading = 'Режим отдыха водителя с учетом отдыха во время разгрузки',
  WorkDuringUploading = 'Режим отдыха водителя с учетом работы во время разгрузки'
}

export type DriverUploadFileType = {
  [k in DriverTableColumn]: string | number | '';
};

export enum DriverTableColumn {
  DriverCode = 'DriverCode',
  DriverTimelineDaysOfRest = 'DriverTimeline.DaysOfRest',
  DriverTimelineDaysOfWork = 'DriverTimeline.DaysOfWork',
  DriverTimelineName = 'DriverTimeline.Name',
  DriverTimelineStartTime = 'DriverTimeline.StartTime',
  DriverTimelineWeekDays = 'DriverTimeline.WeekDays',
  DriverTimelineWorkPerTwoWeek = 'DriverTimeline.WorkPerTwoWeek',
  DriverTimelineWorkPerWeek = 'DriverTimeline.WorkPerWeek',
  LicenseNumber = 'LicenseNumber',
  Name = 'Name',
  PassportDate = 'PassportDate',
  PassportIssuedBy = 'PassportIssuedBy',
  PassportNumber = 'PassportNumber',
  Phone = 'Phone',
  Restriction = 'Restriction',
  TripTemplate = 'TripTemplate',
  Type = 'Type',
  WarehouseDepotCode = 'Warehouse.DepotCode',
  fileIndex = 'fileIndex',
  key = 'key'
}
