import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningSetConfigRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { Plan, Provider } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { first } from 'lodash';
import { TPlanningConfig } from '../../types';
import { getPlanningOperation } from '../../utils/get-planing-operation';

export const setPlanningConfigReducer = createReducer(initialState, {
  [planningSetConfigRoutine.REQUEST]: onRequest,
  [planningSetConfigRoutine.FAILURE]: onFailure,
  [planningSetConfigRoutine.FULFILL]: onFulfill,
  [planningSetConfigRoutine.SUCCESS]: (
    state,
    {
      payload: { planConfigID, operation, planID, providerName, externalPlanConfigID }
    }: PayloadAction<Pick<TPlanningConfig, 'externalPlanConfigID' | 'providerName' | 'operation' | 'planConfigID'> & { planID: Plan['ID'] }>
  ) => {
    if (!state.planningConfigByPlan) state.planningConfigByPlan = {};
    if (!state.planningConfigByPlan[planID]) {
      state.planningConfigByPlan[planID] = {
        planConfigID: null,
        operation: null,
        providerName: null,
        externalPlanConfigID: null,
        params: {}
      };
    }
    const lastPlanConfigID = state.planningConfigByPlan[planID]?.planConfigID;
    const lastOperation = state.planningConfigByPlan[planID]?.operation;
    const lastProviderName = state.planningConfigByPlan[planID]?.providerName;
    const lastExternalPlanConfigID = state.planningConfigByPlan[planID]?.externalPlanConfigID;

    const prepTypePlanning: string | null = operation || state.project?.Plan?.[planID]?.PlanTotal?.TypePlanning || lastOperation || null;

    const newProviderName: Provider =
      providerName ||
      (first(Object.values(state.project?.Plan?.[planID]?.PlanTotal?.PlanConfig ?? {})) as Provider) ||
      lastProviderName ||
      Provider.ILS;

    const newTypePlanning: string | null = getPlanningOperation({
      defaultPlannerConfigsData: state.defaultPlannerConfigs?.data,
      prepTypePlanning,
      providerName: newProviderName
    });

    const newPlanConfigID = planConfigID || lastPlanConfigID;
    const newExternalPlanConfigID = externalPlanConfigID || lastExternalPlanConfigID;
    const newPlanConfigIDForTable = externalPlanConfigID || planConfigID;

    state.planningConfigByPlan[planID] = {
      planConfigID: newPlanConfigID,
      operation: newTypePlanning,
      externalPlanConfigID: newExternalPlanConfigID,
      providerName: newProviderName,
      params: {
        ...state.planningConfigByPlan[planID].params
      }
    };

    if (state.project?.Plan?.[planID]?.PlanTotal) {
      if (newTypePlanning) {
        state.project.Plan[planID].PlanTotal.TypePlanning = newTypePlanning;
      }
      if (newProviderName && newPlanConfigIDForTable) {
        state.project.Plan[planID].PlanTotal.PlanConfig = [newProviderName, newPlanConfigIDForTable];
      }
    }

    return state;
  }
});
