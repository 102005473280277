import { timestampToDatetimeString } from '@common/utils';
import { DASH, EMPTY_ARRAY_DATA } from '@common/constants';
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general';
import { PaymentBalanceHistoryModified, PaymentBalanceHistoryResponse } from '@common/feature/payment/types/general';
import { formatBalanceSum } from '@common/feature/payment/utils/helpers/format-balance-sum';

export const getModifiedBalanceHistory = (balanceHistory: Array<PaymentBalanceHistoryResponse>) => {
  return balanceHistory
    ? balanceHistory.reduce((result, transaction) => {
        const transactionModified = {
          id: transaction.ID,
          tripID: transaction?.DependentID ? `№${transaction?.DependentID}` : DASH,
          dateTime: timestampToDatetimeString(transaction?.DT, true, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT) ?? DASH,
          balanceOffset: formatBalanceSum(transaction?.BalanceOffset),
          comment: transaction?.Comment ? transaction?.Comment : DASH
        };
        result.push(transactionModified);
        return result;
      }, [] as Array<PaymentBalanceHistoryModified>)
    : EMPTY_ARRAY_DATA;
};

