import { FC } from 'react';
import { RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { useSelector } from 'react-redux';
import { routeListSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { TenderPlatformRouteListComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/route/route-list';

interface IProps {
  id: RoutesID;
}

export const TenderOwnFormTableRouteList: FC<IProps> = ({ id }) => {
  const { route } = useSelector(routeListSelector(id));

  return <TenderPlatformRouteListComponent route={route} />;
};
