import { ActiveRowType, DataType, ExecStatusType, LastUpdate } from './catalog';
import { Dictionary, ErrorAPI, Project, ShowDeleted, TimeStamp } from '@common/types';

export interface IDefaultState {
  isFetching: boolean;
  lastUpdate: TimeStamp | null;
  data: DataType;
  error: ErrorAPI | null;
  execStatus: ExecStatusType;
  focusRow: ActiveRowType;
  refresh: boolean;
  showDeleted: ShowDeleted;
  projectID: Project['ID'] | false;
  parameters: {} | null;
  map: {
    visible: boolean;
    widthPercent: number;
  };
}

export const dictionaries = [
  Dictionary.Cargo,
  Dictionary.Contact,
  Dictionary.CrossDock,
  Dictionary.Depot,
  Dictionary.DepotOverload,
  Dictionary.Driver,
  Dictionary.DriverTimeline,
  Dictionary.Order,
  Dictionary.OrderCargo,
  Dictionary.ForbiddenZone,
  Dictionary.PermitZoneType,
  Dictionary.Restriction,
  Dictionary.RestrictionGroup,
  Dictionary.SpeedProfile,
  Dictionary.TripTemplate,
  Dictionary.Vehicle,
  Dictionary.VehicleOverload,
  Dictionary.VehicleProfile,
  Dictionary.VehicleTariff,
  Dictionary.VehicleTariffPart,
  Dictionary.VehicleTimeline,
  Dictionary.VehicleType,
  Dictionary.RoadTrain,
  Dictionary.CouplingType,
  Dictionary.Worktime,
  Dictionary.Zone,
  Dictionary.TransportCompany
] as const;

export interface IInfoItem {
  isFetching: boolean;
  lastUpdate: LastUpdate;
}

export type Info = {
  [K in typeof dictionaries[number]]: IInfoItem;
};
export type StateFields = {
  [K in typeof dictionaries[number]]: IDefaultState;
};

export type CatalogStateType = StateFields & { info: Info };
export type CatalogState = { Catalog: CatalogStateType };
export type Name = keyof IDefaultState;
