import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceHistoryRoutine } from '@common/feature/payment/actions';
import { paymentBalanceHistoryFetchSelector, paymentBalanceHistorySelector } from '@common/feature/payment/selectors/balance-history';
import { getModifiedBalanceHistory } from '@common/feature/payment/utils/helpers/get-modified-balance-history';

export const usePaymentBalanceHistory = () => {
  const dispatch = useDispatch();
  const balanceHistory = useSelector(paymentBalanceHistorySelector);
  const isFetching = useSelector(paymentBalanceHistoryFetchSelector);

  useEffect(() => {
    dispatch(getBalanceHistoryRoutine());
  }, []);

  const modifiedBalanceHistory = getModifiedBalanceHistory(balanceHistory);

  return { balanceHistory: modifiedBalanceHistory, isFetching };
};

