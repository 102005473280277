import { FC } from 'react';
import { AddReportFormComponent } from '@modules/documents/components/add-template-form/add-template-form';
import { useReportList } from '@modules/documents/hooks/use-report-list';
import { useReportForm } from '@modules/documents/hooks/use-report-form';
import { ReportsListRequest } from '@modules/documents/types/documents-api';
import { Project } from '@common/types';

interface IProps {
  projectId: Project['ID'];
  module: ReportsListRequest['module'];
  handleShow(): void;
}

export const AddReportForm: FC<IProps> = ({ handleShow, module, projectId }) => {
  const { options, createReport } = useReportForm({ handleShow, projectId });
  useReportList({ module });

  return <AddReportFormComponent options={options} onFinish={createReport} />;
};
