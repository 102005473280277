import { call, put, takeLatest } from 'redux-saga/effects';
import { Data } from '@modules/planning/children/data/api';
import { dataValidateRoutine, getUploadedDataRoutine } from '@modules/planning/children/data/actions';

function* getUploadedDataWorker() {
  const { request, success, failure, fulfill } = getUploadedDataRoutine;

  try {
    yield put(request());
    const { data: initial } = yield call(Data.getUploadedData);

    if (!Boolean(initial)) {
      yield put(success({ data: [], files: null, scheme: {} }));
    } else {
      const { data, scheme, files } = initial.data;
      yield put(success({ data, scheme, files }));
    }

    yield put(dataValidateRoutine());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getUploadedDataWatcher() {
  yield takeLatest(getUploadedDataRoutine.trigger, getUploadedDataWorker);
}
