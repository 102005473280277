import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { monitoringCreateTrackerLocalRoutine } from '../actions';
import { CreateTrackerRequest } from '@common/types/dictionaries/monitoring';

// Создаёт новую строчку в таблице трекеров
function* monitoringCreateTrackerLocalWorker(action: PayloadAction<CreateTrackerRequest>) {
  const { success, failure } = monitoringCreateTrackerLocalRoutine;

  try {
    yield put(success(action.payload));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* monitoringCreateTrackeLocalWatcher() {
  yield takeLatest(monitoringCreateTrackerLocalRoutine.trigger, monitoringCreateTrackerLocalWorker);
}
