import { isEmpty } from 'lodash';
import { PLAN_NOTICE_TYPE } from '@modules/planning/children/by-date/constants/params';
import { PlanNotice } from '@common/types';

export const getNotificationIconType = (currentNotices: PlanNotice[]) => {
  if (isEmpty(currentNotices)) {
    return PLAN_NOTICE_TYPE[0];
  }

  if (currentNotices.every((notice) => notice.NoticeType < 3)) {
    if (currentNotices.every((notice) => notice.NoticeType < 2)) {
      return PLAN_NOTICE_TYPE[1];
    }
    return PLAN_NOTICE_TYPE[2];
  }

  return PLAN_NOTICE_TYPE[3];
};
