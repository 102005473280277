import { MessageContent } from '@common/constants';

export const checkNoData = ({ data, message }: { data: any; message: string }) => {
  if (!data) throw Error(message);
};

export const checkIsErrorData = ({ data, message }: { data: any; message: string }) => {
  if (data) throw Error(message);
};

export const throwError = ({ message = MessageContent.SomethingWentWrong }: { message?: string }) => {
  throw new Error(message);
};
