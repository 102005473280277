import { GlobalSpin } from '@common/components/feedback/spin/global-spin';
import { ComponentType } from 'react';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

type getWrapperT = (persistor: Persistor) => ComponentType;

export const getWrapper: getWrapperT =
  persistor =>
  ({ children }) =>
    (
      <PersistGate
        loading={<GlobalSpin />}
        //@ts-ignore
        persistor={persistor}
      >
        {children}
      </PersistGate>
    );
