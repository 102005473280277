import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardSetModel, BoardDefaults } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';

export const selectBoardSetCommonWatcher = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<AnalyticsBoardSetModel & BoardDefaults>) {
    const { success, failure, fulfill } = actions.analyticsCommonSelectBoardSetRoutine;
    try {
      yield put(success(action.payload));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonSelectBoardSetRoutine.TRIGGER, worker);
  }

  return watcher;
};
