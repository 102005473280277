import { FC, useEffect, useState } from 'react';
import { TemplateData } from '@modules/documents/children/templates/types/api';
import { generateReportFromJson } from '@modules/documents/helpers/generate-report-from-json';

declare var Stimulsoft: any;

const ILSDocumentTemplateViewer: FC<{
  scrollbarsMode?: boolean;
  templateData?: TemplateData;
  onReportUpdate?: (report: string) => void;
  height?: string;
}> = ({ scrollbarsMode, templateData, onReportUpdate, height }) => {
  const [report, setReport] = useState<any>();
  const [edit, setEdit] = useState(false);

  const onSave = (args: any) => {
    onReportUpdate?.(args.report.saveToJsonString());
    setEdit(false);
  };

  const createViewer = () => {
    const options = new Stimulsoft.Viewer.StiViewerOptions();
    options.toolbar.showDesignButton = !!onReportUpdate;
    options.appearance.scrollbarsMode = scrollbarsMode;
    if (height) options.height = height;
    const viewerInst = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    viewerInst.renderHtml('reportViewer');
    viewerInst.report = report;
    viewerInst.onDesignReport = () => {
      setEdit(true);
    };
  };

  const createDesigner = () => {
    const options = new Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.allowChangeWindowTitle = false;
    options.appearance.showSaveDialog = false;
    if (height) options.height = height;
    const designerInst = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
    designerInst.renderHtml('reportDesigner');
    designerInst.report = report;
    designerInst.onSaveReport = onSave;
    designerInst.onSaveAsReport = onSave;
  };

  useEffect(() => {
    Stimulsoft.Base.StiLicense.loadFromFile('/assets/scripts/stimulsoft/license.key');
    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/scripts/stimulsoft/ru.xml');
  }, []);

  useEffect(() => {
    if (!templateData) return;
    setReport(generateReportFromJson(templateData));
  }, [templateData]);

  useEffect(() => {
    if (!report) return;

    edit ? createDesigner() : createViewer();
  }, [edit, report]);

  return (
    <>
      {!edit && <div id="reportViewer" />}
      {edit && <div id="reportDesigner" />}
    </>
  );
};

export default ILSDocumentTemplateViewer;
