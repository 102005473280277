import { FC, useState } from 'react';
import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import CustomScroll from 'react-customscroll';
import { ILSCatalogItemComponent } from './item';
import { IPropsILSCatalogCollapseComponent } from '@modules/catalog/types/components';
import { ExpandIconPosition } from '@components/data-display/collapse/types';
import classnames from 'classnames';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';

export const ILSCatalogCollapseComponent: FC<IPropsILSCatalogCollapseComponent> = ({
  catalogs,
  onPanelChange,
  showMap,
  activePanelKey,
  isMapVisible,
  setShowMap,
  ...props
}) => {
  const [fullScreen, setFullScreen] = useState<{
    set: boolean;
    key: string | number | null;
    changeShowMap: boolean;
  }>({ set: false, key: null, changeShowMap: !!showMap });

  const onFullChange = (key: string | number) => {
    let changeShowMap = false;
    if (!fullScreen.set && showMap) {
      changeShowMap = true;
      setShowMap(false);
    } else if (fullScreen.set && fullScreen.changeShowMap) {
      setShowMap(fullScreen.changeShowMap);
    }
    setFullScreen({ set: !fullScreen.set, key, changeShowMap });
  };

  return (
    <div className={isMapVisible ? 'scroll-menu' : 'full-menu'}>
      <CustomScroll>
        {/*{infoComponent}*/}
        <ILSCollapse
          className={classnames('main-collapse', { 'with-map': showMap })}
          bordered={false}
          accordion
          //NOTE должно быть true если используется с виртуальными таблицами (при повторном открытии рендерятся все записи)
          destroyInactivePanel
          expandIconPosition={ExpandIconPosition.Right}
          onChange={onPanelChange}
          activeKey={`panel${activePanelKey}`}
        >
          {catalogs.map(
            ({ component, dontShow, name, desc, ...item }, key) =>
              ((fullScreen.set && fullScreen.key === key) || !fullScreen.set) &&
              component &&
              !dontShow && (
                <ILSCollapsePanel key={`panel${key}`} header={name}>
                  {compareAsString(activePanelKey, key) && (
                    <CustomScroll>
                      <div className={fullScreen.set ? 'full-box' : 'short-box'}>
                        <ILSCatalogItemComponent
                          {...props}
                          showMap={Boolean(showMap)}
                          catalogs={catalogs}
                          item={{ component, dontShow, name, desc, ...item }}
                          MyComponent={component}
                          onFullChange={onFullChange}
                          collapseKey={key}
                          fullScreen={fullScreen}
                        />
                      </div>
                    </CustomScroll>
                  )}
                </ILSCollapsePanel>
              )
          )}
        </ILSCollapse>
      </CustomScroll>
    </div>
  );
};
