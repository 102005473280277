import { createReducer } from '@reduxjs/toolkit';
import { getInboxSendDocumentsRoutine, setDocumentEnum } from '@modules/documents/actions';
import { initialState } from '@modules/documents/store';
import { InboxSendDocument } from '@modules/documents/types/document-state';

const inboxSendDocuments = createReducer(initialState, {
  [getInboxSendDocumentsRoutine.REQUEST]: (state) => {
    state.inboxSendDocuments.isFetching = true;
    return state;
  },
  [getInboxSendDocumentsRoutine.SUCCESS]: (state, action: { payload: { data: InboxSendDocument[] } }) => {
    state.inboxSendDocuments.data = action.payload.data;
    return state;
  },
  [getInboxSendDocumentsRoutine.FAILURE]: (state) => {
    state.inboxSendDocuments.data = [];
    return state;
  },
  [getInboxSendDocumentsRoutine.FULFILL]: (state) => {
    state.inboxSendDocuments.isFetching = false;
    return state;
  },
  [setDocumentEnum.type]: (state, action) => {
    state.inboxSendDocuments.docEnum = action.payload;
  }
});

export default inboxSendDocuments;
