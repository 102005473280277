import { ILSButton } from '@common/components';
import { SplitChar } from '@common/constants';
import { PermittedUploadFileType } from '@common/constants/general/path';
import { AccountFileAttachment } from '@modules/account/types/attachments';
import { downloadAccountAttachment } from '@modules/account/utils/helpers/download-account-attachment';
import { FC } from 'react';

interface IProps {
  document: AccountFileAttachment;
  onDelete(id: number): void;
}

export const AccountAttachmentDocument: FC<IProps> = ({ document, onDelete }) => {
  const handleDownloadDocument = () => {
    downloadAccountAttachment(document?.link, PermittedUploadFileType.png);
  };

  const handleDelete = () => {
    onDelete(document?.id);
  };

  return (
    <div className="attachment-document">
      <div className="attachment-document-name">{document.name + SplitChar.Dot + document.extension}</div>
      <div>
        <ILSButton icon="download" type="text" onClick={handleDownloadDocument} />
        <ILSButton icon="delete" type="text" onClick={handleDelete} />
      </div>
    </div>
  );
};
