import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI, Keys } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogRestoreRoutine } from '@modules/catalog/actions';
import { ICatalogDataItem, SaveDictionaryType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';

const restoreDictionaryReducer = createReducer(initialState, {
  [catalogRestoreRoutine.REQUEST]: onRequest,
  [catalogRestoreRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      ids: Keys;
      saveDictionary: SaveDictionaryType;
    }>
  ) => {
    let { dictionary, ids, saveDictionary } = action.payload;

    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;
    let oldData = state[dictionary].data;
    if (ids.length > 0) {
      if (saveDictionary) {
        //для добавления удаления записи из родительского справочника
        oldData = oldData.map((item: ICatalogDataItem) => {
          if (item.ID === saveDictionary.recordID) {
            item[saveDictionary.childDict] = item[saveDictionary.childDict].map((childItem) => {
              if (ids.includes(childItem.ID)) {
                childItem['Deleted'] = null;
              }
              return childItem;
            });
          }
          return item;
        });
      } else {
        oldData = oldData.map((item: ICatalogDataItem) => {
          if (ids.includes(item.ID)) {
            item['Deleted'] = null;
          }
          return item;
        });
      }
    }

    state[dictionary].data = oldData;

    return state;
  },
  [catalogRestoreRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogRestoreRoutine.FULFILL]: onFulfill
});

export default restoreDictionaryReducer;
