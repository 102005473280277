import { ILSSpin } from '@common/components';
import { DocumentsUpload } from '@modules/orders-and-trips/components/documents/documents-upload';
import { useDocumentsContainer } from '@modules/orders-and-trips/hooks/use-documents-container';

export const DocumentsUploadContainer = () => {
  const { documents, uploadIsDisabled, isFetching, permittedExtensionsTitle, handleCheckBeforeUpload, handleDeleteDocument } = useDocumentsContainer();

  return (
    <ILSSpin tip={'Загрузка документов...'} spinning={isFetching}>
      <DocumentsUpload
        documents={documents}
        isFetching={isFetching}
        uploadIsDisabled={uploadIsDisabled}
        permittedExtensionsTitle={permittedExtensionsTitle}
        handleCheckBeforeUpload={handleCheckBeforeUpload}
        handleDeleteDocument={handleDeleteDocument}
      />
    </ILSSpin>
  );
};
