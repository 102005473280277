import { createSelector } from 'reselect';
import { DataScheme, PlanningDataStore } from '@modules/planning/children/data/types';
import { Dictionary } from '@common/types';
import { NOT_VISIBLE_SCHEME_KEYS } from '@modules/planning/children/data/constants';

export const schemeSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.scheme,
  (scheme: DataScheme | {}) => scheme
);

export const schemeDictionarySelector = (dictionary: Dictionary) =>
  createSelector(
    (state: PlanningDataStore) => state.PlanningData.scheme,
    (scheme) => scheme?.[dictionary] ?? {}
  );

export const schemeKeysSelector = createSelector(schemeSelector, (scheme) =>
  (Object.keys(scheme) as Array<Dictionary>).filter((scheme) => !NOT_VISIBLE_SCHEME_KEYS.includes(scheme))
);
