import { ILSCheckbox } from '@components/index';
import { FC } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IProps {
  name: string;
  type: string;
  value: string | number;
  attention: string | undefined;
  onChange(value: number | boolean | string): void;
}

export const ILSCheckboxItem: FC<IProps> = ({ name, attention, value, onChange }) => {
  return (
    <ILSCheckbox checked={!!value} onChange={(e: CheckboxChangeEvent) => onChange(e.target.checked)}>
      {name}
      {attention && <span className="text-error">{attention}</span>}
    </ILSCheckbox>
  );
};

