import { table2dataType } from '@common/table-configs/types/decorator';
import { TableColumnName } from '@common/types';

export const table2data: table2dataType = (record, dataIndex, value) => {
  switch (dataIndex) {
    case TableColumnName.TargetID:
    case TableColumnName.SourceID:
      const [, depotID] = value;
      return depotID;
    default:
      return value;
  }
};

