import { FC, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { useAppDispatch } from '@core/hooks';
import { ModulePath } from '@common/types';
import { setDocumentState } from '@modules/documents/actions';
import { useGetDocumentState } from '@modules/documents/helpers/use-get-document-state';
import { ILSCol, ILSRow } from '@common/components';
import ILSDocumentsNavigation from '@modules/documents/components/documents-navigation';
import ILSDocumentsSidePanel from '@modules/documents/components/side-panel';
import '../styles.less';
import { showSidePanelRoutes } from '@modules/documents/constants';
import { DocumentPath } from '@modules/documents/types/documents-navigation-item';
import { useStimulSoft } from '@common/hooks/stimulsoft/use-stimulsoft';

interface DocumentsProps {
  route: RouteConfig;
}

const ILSDocuments: FC<DocumentsProps> = ({ route }) => {
  useStimulSoft()
  const dispatch = useAppDispatch();
  const documentState = useGetDocumentState();
  const { pathname } = useLocation();
  const showSidePanel = showSidePanelRoutes.includes(pathname as DocumentPath);

  useEffect(() => {
    dispatch(setDocumentState(documentState));
  }, [documentState]);

  return (
    <section className="documents h-100">
      <ILSRow>
        <ILSDocumentsNavigation />
        <ILSCol span={showSidePanel ? 20 : 24} className="pr-3">
          <Switch>
            <Redirect from={ModulePath.Documents} exact to={`${ModulePath.Documents}${ModulePath.DocumentsAll}`} />
          </Switch>
          {renderRoutes(route?.routes)}
        </ILSCol>
        <ILSDocumentsSidePanel />
      </ILSRow>
    </section>
  );
};

export default ILSDocuments;
