import { useRef } from 'react';

export const useVirtualBox = ({ onLoadData }: { onLoadData(): void }) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const containerHeight = boxRef.current?.getBoundingClientRect().height ?? 900;
  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 5 < containerHeight) {
      onLoadData();
    }
  };
  return { boxRef, containerHeight, onScroll };
};

