import { ILSTable } from '@components/index';
import { TableColumnName, TableRecord } from '@common/types';
import { useAppSelector } from '@core/hooks';
import { FC } from 'react';
import { ERROR_TABLE_CONFIG } from '../../config/error';
import { errorToTable, getErrorByTypes, getFail } from '../../decorators/table/error-to-table';
import { validationDataSelector } from '@modules/planning/children/by-date/selectors/plan';
import { ErrorComponentType } from '../../types/validate';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

interface IProps {
  setComponent: (val: (data: ErrorComponentType) => ErrorComponentType) => void;
  errorComponent: ErrorComponentType;
}

export const ErrorTable: FC<IProps> = ({ setComponent, errorComponent }) => {
  const validationData = useAppSelector(validationDataSelector);
  const activePlanID = useAppSelector(activePlanIDSelector);

  const setErrorTableComponent = (record: TableRecord, dataIndex: TableColumnName) => {
    if (activePlanID && record?.dictionary) {
      const errorByTypes = getErrorByTypes(validationData[activePlanID]?.[record?.dictionary]);
      const { failKeys, failFieldName } = getFail(errorByTypes[dataIndex]);
      setComponent(() => {
        return {
          table: record.dictionary,
          errorType: dataIndex,
          errors: errorByTypes[dataIndex],
          failFieldName,
          failKeys,
          activeTable: record.dictionary + dataIndex
        };
      });
    }
  };
  const data = activePlanID && errorToTable(validationData[activePlanID], setErrorTableComponent);

  if (!data) {
    return <></>;
  }

  return (
    <ILSTable
      config={ERROR_TABLE_CONFIG}
      dataSource={data}
      className="error-planning-table"
      activeCell={errorComponent.activeTable}
      pagination={false}
    />
  );
};
