import { IndexedArray, User } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const combinePhoneAndContactName = (data: Array<User> | IndexedArray<User> | undefined | null) => {
  if (!data) return undefined;
  const result = (createArrayFromIndexedArray(data) as Array<User>).reduce((prev, record: User) => {
    if (!record?.ID) return prev;
    prev.push({ label: `${record?.Name ?? record?.ID} ${record?.Phone}`, value: record?.ID });
    return prev;
  }, [] as Array<{ label: string; value: number }>);
  return result?.length ? result : undefined;
};
