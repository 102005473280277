import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountUserFormComponent } from '@modules/account/components/user/form';
import { userPaneSelector } from '@modules/account/selectors/components/panes';
import { ILSSpinCenter } from '@common/components';
import { useSaveUserChanges } from '@modules/account/utils/hooks/use-save-user-changes';
import { ProfileTab } from '@modules/account/types';

export const AccountUserContainer: FC = () => {
  const dispatch = useDispatch();
  const { initialValues, adminAccess, isLoading, options } = useSelector(userPaneSelector);
  const { onFinish, userAvatar, onAvatarChange } = useSaveUserChanges({ dispatch });

  if (isLoading) {
    return <ILSSpinCenter />;
  }

  return (
    <AccountUserFormComponent
      options={options}
      name={ProfileTab.user}
      disabled={!adminAccess}
      initialValues={initialValues}
      avatar={userAvatar}
      onAvatarChange={onAvatarChange}
      onFinish={onFinish}
    />
  );
};
