import { FC } from 'react';
import { DecoratedTrips, TripContactPerson, TripStatus } from '@modules/orders-and-trips/types/trip';
import { DetailsContacts } from '@modules/orders-and-trips/components/details/details-contacts';
import { DetailsStatusPrice } from '@modules/orders-and-trips/components/details/details-status-price';
import { ILSButton } from '@common/components';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';
import { Driver, Vehicle } from '@common/types';

interface IProps {
  tripStatus: TripStatus;
  tripCost?: string;
  contactPerson: TripContactPerson;
  cancelTrip(): void;
  cancelAllowed: boolean;
  deleteTrip(): void;
  deleteAllowed: boolean;
  tripService: TenderOutFastDeliveryServices | null;
  tripDriver: Driver | null;
  tripVehicle: Vehicle | null;
}

export const DetailsHeader: FC<IProps> = ({
  tripStatus,
  cancelTrip,
  tripCost,
  cancelAllowed,
  deleteTrip,
  deleteAllowed,
  contactPerson,
  tripService,
  tripDriver,
  tripVehicle
}) => {
  return (
    <div className="details-header">
      <div className="details-header-title-group">
        <h2>Детали</h2>
        <div className="details-header-buttons">
          <ILSButton onClick={deleteTrip} disabled={!deleteAllowed} icon="delete" className="details-header-delete-button" type="default">
            Удалить рейс
          </ILSButton>
          <ILSButton type={'primary'} onClick={cancelTrip} disabled={cancelAllowed}>
            Отменить рейс
          </ILSButton>
        </div>
      </div>
      <div className="details-header__columns">
        <DetailsContacts contactPerson={contactPerson} tripDriver={tripDriver} tripVehicle={tripVehicle} />
        <DetailsStatusPrice tripService={tripService} tripStatus={tripStatus} tripCost={tripCost} />
      </div>
    </div>
  );
};

