import { ILSCheckbox, ILSForm, ILSFormItem, ILSModal, ILSSwitch } from '@common/components';
import { ButtonAction, ILSButton } from '@common/components/general/buttons';
import { ILSTooltip } from '@common/components/data-display/tooltip';
import { useModal } from '@common/hooks/components/use-modal';
import { useHandleConfigs } from '@common/hooks/use-handle-configs';
import { useSetRestricted } from '@common/hooks/use-set-restricted';
import { getTenderNotifySettingsRoutine, tenderNotifySettingsRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { currentNotificationSettings } from '@modules/tender-platform/children/tender-search/selectors';
import { NotificationSettingsFormField } from '@modules/tender-platform/constants/notification-settings';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceComponent } from './notification-settings-restrictions';

interface INoticationSettingsButtonProps {
  isFetching: boolean;
}

export const ILSNotificationSettingsButton: FC<INoticationSettingsButtonProps> = ({ isFetching }) => {
  const currentNotifications = useSelector(currentNotificationSettings);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { handleShow, visible } = useModal();

  const [isRestricted, setIsRestricted] = useState(
    Boolean(currentNotifications && (currentNotifications?.Source || currentNotifications?.Target))
  );

  useEffect(() => {
    setIsRestricted(Boolean(currentNotifications && (currentNotifications?.Source || currentNotifications?.Target)));
  }, [currentNotifications]);

  const onFinish = (values: Record<NotificationSettingsFormField, string | boolean | undefined>) => {
    dispatch(tenderNotifySettingsRoutine(values));
  };

  const onSwitchChange = () => {
    setIsRestricted(!isRestricted);
  };

  const handleGetConfigs = () => {
    dispatch(getTenderNotifySettingsRoutine());
  };
  //TODO refactor
  useHandleConfigs(handleGetConfigs);
  useSetRestricted(setIsRestricted, [currentNotifications.Enabled]);

  return (
    <>
      <ILSTooltip placement="bottom" title="Отображения и уведомления">
        <ILSButton className="ils-notification-settings" icon="notification-settings" onClick={handleShow} />
      </ILSTooltip>
      <ILSModal
        onCancel={handleShow}
        className={'notification-settings-modal'}
        footer={
          <>
            <ILSButton action={ButtonAction.Cancel} onClick={handleShow} />
            <ILSButton action={ButtonAction.Save} htmlType="submit" form="notify-settings" loading={isFetching} className="save" />
          </>
        }
        title={'Отображения и уведомления'}
        visible={visible}
      >
        <ILSForm
          onFinish={onFinish}
          form={form}
          name="notify-settings"
          layout={'vertical'}
          className="settings-form"
          initialValues={currentNotifications}
        >
          <div className="main-settings">
            <ILSFormItem name={NotificationSettingsFormField.Enabled} valuePropName="checked">
              <ILSCheckbox label="Присылать уведомления на почту" />
            </ILSFormItem>
            <div className="switch-restrictions">
              <span>Все</span>
              <ILSSwitch checked={isRestricted} onChange={onSwitchChange} />
              <span>Ограничить географию</span>
            </div>
          </div>
          {isRestricted && (
            <div className="restriction-place">
              <div className="restriction-place-container">
                <PlaceComponent name="Погрузка" />
                <PlaceComponent name="Разгрузка" />
              </div>
              <ILSButton className="switch-settings" icon="up-down-arrows" />
            </div>
          )}
        </ILSForm>
      </ILSModal>
    </>
  );
};
