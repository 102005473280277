import { WidgetType } from '@common/types/dictionaries/analytics';

export const widgetsPreviews = {
  CatalogHorizontalIntensityUseTransport: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/horizontal-intensity.png'
  },
  CatalogTableIntensityUseTransport: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/table-intensity.png'
  },
  CatalogInfoAverageUnloadingTime: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/average-unloading.png'
  },
  CatalogInfoTransportedVolume: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/transported-volume.png'
  },
  CatalogLineChartSequenceViolations: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/broken-sequence-graph.png'
  },
  CatalogText: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/textfield.png'
  },
  CatalogInfoCountVisits: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/amount-of-visits.png'
  },
  HorizontalLatePercent: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-graph.png'
  },
  TableLatePercent: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-table.png'
  },
  InfoLatePercent: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-info.png'
  },
  //
  LineChartOverrun: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-graph.png'
  },
  TableOverrun: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-table.png'
  },
  TableOverrunKilometer: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-table-km.png'
  },
  LineChartOverrunKilometer: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-graph-km.png'
  },
  InfoFines: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/fines-info.png'
  },
  HorizontalFines: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/fines-graph.png'
  },
  GroupedChartQualityOfService: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/quality-of-service-chart.png'
  },
  TableQualityOfService: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/quality-of-service-table.png'
  },
  HorizontalInTimePercent: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-chart.png'
  },
  TableLatePercentAverage: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-visits-percent-table.png'
  },
  HorizontalDeviation: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/horizontal-deviation-chart.png'
  },
  LineChartLatePercentAverage: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-average-chart.png'
  },
  LineChartLateMinutesAverage: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-percent-average-chart.png'
  },
  TableLateMinutesAverage: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/loading-volume-table.png'
  },
  LineChartSequenceViolationsWithFilter: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/sequence-graph.png'
  },
  HorizontalSequenceViolations: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/horizontal-intensity.png'
  },
  LineChartLatePercentFiltered: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/late-line-chart.png'
  },
  LineChartOverrunKilometerFiltered: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-graph-km.png'
  },
  LineChartOverrunPercentFiltered: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-graph.png'
  },
  HorizontalOverrun: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/horizontal-intensity.png'
  },
  LineChartDeviationFiltered: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/overrun-graph.png'
  },
  InfoCountVehicles: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/vehicle-count-info.png'
  }
};

export const widgetsTypePreviews: Record<WidgetType, { img: string }> = {
  [WidgetType.formData]: { img: process.env.PUBLIC_URL + '/assets/images/widgets/formData.png' },
  info: { img: process.env.PUBLIC_URL + '/assets/images/widgets/info.png' },
  infoArray: { img: process.env.PUBLIC_URL + '/assets/images/widgets/info.png' },
  metrics: { img: process.env.PUBLIC_URL + '/assets/images/widgets/metrics.png' },
  text: { img: process.env.PUBLIC_URL + '/assets/images/widgets/textfield.png' },
  [WidgetType.barGraphHorizontal]: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/barGraphHorizontal.png'
  },
  lineChart: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/lineChart.png'
  },
  [WidgetType.barGraphVertical]: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/barGraphVertical.png'
  },
  [WidgetType.groupedChart]: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/groupedChart.png'
  },
  [WidgetType.table]: {
    img: process.env.PUBLIC_URL + '/assets/images/widgets/table.png'
  }
};
