import { Keys, TableRecord } from '@common/types';
import { findNestedChildrenIDs } from '@modules/catalog/helpers';

/**
 * Функция возвращает ID всех элементов без ID групп в dataSource с группирующими строками.
 * Используется, например, при удалении или восстановлении группы, или части группы.
 *
 * 1) Если клик по кнопке 'Удалить' в table head, то ids - это все выделенные элементы (selectedValues), и они уже включают ids всех элементов на удаление.
 * в этом случае необходимо только отфильтровать массив;
 *
 * 2) Если удаление по клику на ячейку корзины, то в ids попадает только одно! строковое id группы, необходимо найти ID всех вложенных элементов;
 *
 * @param ids массив ID, в котором либо только один ID группы, либо ID группы и ID элементов;
 * @param dataSource dataSource с группирующими строками;
 * @returns возвращает массив ID элементов без ID групп;
 */
export const getIdsWithoutGroupIds = (ids?: Keys, dataSource?: Array<TableRecord>): Keys | [] => {
  let idsWithoutGroupIds: Keys | null | undefined;

  if (ids?.length === 1 && dataSource) {
    idsWithoutGroupIds = findNestedChildrenIDs(dataSource, String(ids[0]));
  } else {
    idsWithoutGroupIds = ids?.filter((id) => typeof id === 'number');
  }
  return idsWithoutGroupIds || [];
};
