export const NEW_TRANSPORT_COMPANY = {
  Name: 'Новая транспортная компания',
  TransportCompanyCode: null,
  INN: null,
  Address: null,
  KPP: null,
  OKPO: null,
  OGRN: null,
  OKATO: null
};
