import { Editor } from '@tinymce/tinymce-react';
import { DropTarget } from 'react-drag-drop-container';
import { FC, useState } from 'react';
import { Tariff } from '@modules/catalog/types/tariff';
import { parseTariffFormula } from '@modules/catalog/helpers';
import { ALLOWED_KEYS_FOR_EDITOR, STYLES_FOR_EDITOR, tariffNumbers, tariffOperations } from '@modules/catalog/constants/tariff';
import { useFormulaToEditorContent } from './helpers/hooks/use-tariff-parser-to-editor';
import { useAppSelector } from '@core/hooks';
import { paramSelector } from '@modules/catalog/selectors/tariffs';
import {
  TARIFF_CONST_BUTTON_CLASSNAME,
  TARIFF_CONST_BUTTON_DATA_ATTR,
  TARIFF_CONST_BUTTON_DELETE_LINK_CLASSNAME
} from '@modules/catalog/components/tariff/constants/general';

interface IILSTariffEditorComponent {
  tariff?: Tariff;
  editorRef?: any;

  setShowCheck(check: boolean): void;

  insertEditor(o: any): void;

  changeNewTariff(): void;
}

export const ILSTariffEditorComponent: FC<IILSTariffEditorComponent> = ({ tariff, editorRef, insertEditor, changeNewTariff }) => {
  const [, setTags] = useState<HTMLElement>();
  const params = useAppSelector(paramSelector);
  let operationsToolbar = '';
  let numbersToolbar = '';
  for (let i in tariffOperations) {
    //добавление разрешенных символов
    operationsToolbar += tariffOperations[i].name + 'Btn ';
    ALLOWED_KEYS_FOR_EDITOR.push(tariffOperations[i].sign);
  }

  for (let i in tariffNumbers) {
    //добавление цифр
    numbersToolbar += tariffNumbers[i].name + 'Btn ';
    ALLOWED_KEYS_FOR_EDITOR.push(tariffNumbers[i].sign);
  }

  let formula = tariff?.Formula ? tariff.Formula : '';
  formula = formula && parseTariffFormula(formula, params, true) ? parseTariffFormula(formula, params, true) : formula;

  const { initFormula } = useFormulaToEditorContent({ formula });
  const onDragEnter = (e: any) => {
    if (e.target.id === 'creator_ifr') {
      editorRef.current.focus();
    }
  };

  return (
    <DropTarget targetKey="drop-editor" onDragEnter={onDragEnter}>
      <Editor
        apiKey="b1z68p9sky7ji07o5347qeira9rrzng7xv1vgwyv81rdd9d3"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initFormula}
        id={tariff ? (params ? 'tariff_editor' : 'param_editor') : 'creator'}
        init={{
          placeholder: 'Создайте формулу для того, чтобы сохранить тариф',
          height: 278,
          toolbar: [operationsToolbar, numbersToolbar],
          menubar: 'custom',
          forced_root_block: 'p',
          apiKey: '7bdf58a1-e722-4868-bee9-b7e7c65a09b6',
          // menu: {custom: { title: 'Custom menu', items: 'customBtn0' }},
          // @ts-ignore
          selector: 'textarea',
          plugins: 'noneditable',
          setup: function (editor) {
            tariffOperations.concat(tariffNumbers).forEach((o) => {
              editor.ui.registry.addButton(o.name + 'Btn', {
                text: '<span class="btn">' + o.sign + '</span>',
                onAction: function () {
                  insertEditor(o.sign);
                }
              });
            });

            editor.on('keydown', function (e) {
              // запрет на ввод символов кроме разрешенных
              const key = e.key;
              const condition = (key >= '0' && key <= '9') || ALLOWED_KEYS_FOR_EDITOR.includes(key);
              if (!condition) e.preventDefault();
              setTimeout(() => {
                changeNewTariff();
              });
            });

            editor.on('change', function () {
              const els = editor.getContentAreaContainer();
              setTags(els);
            });

            editor.on('click', function (e) {
              if (e.target.classList.contains(TARIFF_CONST_BUTTON_DELETE_LINK_CLASSNAME)) {
                const elemDataVariableAttrToDelete = e.target
                  .closest(`.${TARIFF_CONST_BUTTON_CLASSNAME}`)
                  .getAttribute(TARIFF_CONST_BUTTON_DATA_ATTR);
                const elemsToDelete = editor.contentDocument.body.querySelectorAll(
                  `*[${TARIFF_CONST_BUTTON_DATA_ATTR}=${elemDataVariableAttrToDelete}]`
                );
                elemsToDelete.forEach((elem) => elem.remove());
                changeNewTariff();
              }
            });
          },
          content_style: STYLES_FOR_EDITOR
        }}
      />
    </DropTarget>
  );
};

