import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { monitoringCreateVehicleTrackerLocalRoutine } from '../actions';
import { VehicleTracker } from '@common/types';

interface IPayload {
  trackers: Array<VehicleTracker>;
  VehicleID: string;
}

// Создаёт новую строчку в таблице привязок трекеров
function* monitoringCreateVehicleTrackerLocalWorker(action: PayloadAction<IPayload>) {
  const { success, failure } = monitoringCreateVehicleTrackerLocalRoutine;
  const { trackers, VehicleID } = action.payload;
  try {
    yield put(success({ data: trackers, VehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* monitoringCreateVehicleTrackeLocalWatcher() {
  yield takeLatest(monitoringCreateVehicleTrackerLocalRoutine.trigger, monitoringCreateVehicleTrackerLocalWorker);
}
