import { RoadGraphEditorStore } from '../types/store';

const initialState: RoadGraphEditorStore = {
  isFetching: false,
  error: [],
  isSaving: false,
  success: false,
  geoHash: null,
  roads: null,
  catalog: {
    Depot: null,
    Info: null
  },
  roadData: null
};

export default initialState;
