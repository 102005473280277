import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { activeRecordRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState, ActiveRecord, RecordType } from '@modules/orders-and-trips/types/general';

export const activeRecordReducer = createReducer(initialState, {
  [activeRecordRoutine.REQUEST]: (state: InitialState) => {
    state.isFetching = true;
    return state;
  },
  [activeRecordRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      recordType: RecordType;
      value: ActiveRecord;
    }>
  ) => {
    const { recordType, value } = action.payload;
    switch (recordType) {
    case RecordType.All:
      state.orders.activeRecord = value;
      state.trips.activeRecord = value;
      break;
    case RecordType.Order:
      state.orders.activeRecord = value;
      state.trips.activeRecord = null;
      break;
    case RecordType.Trip:
      state.trips.activeRecord = value;
      state.orders.activeRecord = null;
      break;
    default:
      console.error('RecordType не определен');
      break;
    }
    return state;
  },
  [activeRecordRoutine.FAILURE]: onFailure,
  [activeRecordRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.isFetching = false;
    return state;
  }
});

