import { TableColumnName } from '@common/types';
import { DriverMobileApplication } from '@common/types/dictionaries/driver';

export const NEW_DRIVER = {
  [TableColumnName.Name]: 'Новый водитель',
  // При создании новой строки по-умолчанию задается значение "нет" (TMS-5757)
  [TableColumnName.MobileApplication]: DriverMobileApplication.No
};

export const DRIVER_OPTIONS = [
  { value: 0, label: 'Водитель' },
  { value: 1, label: 'Экспедитор' },
  { value: 2, label: 'Диспетчер' },
  { value: 3, label: 'Другое' }
];
