import { TModulesObj } from "@core/types/models";
import { flatModules } from "@core/modules";
import { Transform } from "redux-persist/es/types";
import { createTransform } from "redux-persist";

/* Кастомизация сохранения и извлечения данных из персист.
 *
 * @onPersist - метод преобразования во время сериализации
 * @initialState - дефолтное состояние стора
 *
 * Чтобы для модуля отрабатывала кастомная сериализация необходимо в defineModule задать persistTransform: TPersistTransform
 *
 * Пример,
 *
 * export const persistTransform: TPersistTransform<IPlanningByDateStore> = {
 *
 *  initialState,
 *
 *  onPersist: (inboundState: IPlanningByDateStore) => {
 *
 *     const savedState = { projectID: inboundState.project?.ID };
 *
 *     return savedState;
 *
 *   },
 *
 * };
 * */
export type TPersistTransform<StateType> = {
  initialState: StateType;
  onPersist?: ((inboundState: StateType) => any) | null;
};

/** Метод получение всех кастомных преобразваний для persist из модулей проекта
 * Возвращает массив transforms с преобразованиями, и массив blacklist
 * */
export const getModulesPersistTransform = (
  modules: TModulesObj,
  transforms: Array<Transform<any, any>> = [],
  blacklist: Array<string> = []
): { transforms: Array<Transform<any, any>>; blacklist: Array<string> } => {
  const flat = flatModules(modules);
  for (let i = 0; i < flat.length; i++) {
    if (flat.hasOwnProperty(i)) {
      let persist = flat[i].persist;

      if (persist !== undefined && persist !== null && [flat[i]['MODULE']!]) {
        //если исключен весь модуль  - то не смотрим на transform callback
        if (typeof persist === 'boolean') {
          !persist && blacklist.push(flat[i]['MODULE']!);
        } else {
          const moduleTransform = persist as TPersistTransform<any>;
          const persistTransform = createTransform(
            moduleTransform.onPersist || null,
            (outboundState: any) => {
              return { ...moduleTransform?.initialState, ...outboundState };
            },
            {
              whitelist: [flat[i]['MODULE']!]
            }
          );
          transforms.push(persistTransform);
        }
      }

      if (typeof flat[i].children === 'object' && Object.keys(flat[i].children!).length > 0) {
        getModulesPersistTransform(flat[i].children!, transforms, blacklist);
      }
    }
  }
  return { transforms, blacklist };
};
