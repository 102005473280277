import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { PayloadActionError } from '@common/types';
import { AnalyticsStore } from '../types/store';
import initialState from '@modules/analytics/common/store';
import { AnalyticsActions } from '../types/actions';
import { AnalyticsWidgetDataModel } from '@common/types/dictionaries/analytics';

export const analyticsGetWidgetDataReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsGetWidgetDataRoutine.REQUEST]: (state, action: PayloadAction<{ ID: number }>) => {
      if (state.Widgets[action.payload.ID]) {
        state.Widgets[action.payload.ID].loaded = false;
        state.Widgets[action.payload.ID].loading = true;
        state.Widgets[action.payload.ID].error = false;
      }
      return state;
    },
    [actions.analyticsGetWidgetDataRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{ data: AnalyticsWidgetDataModel; widgetID: string }>
    ) => {
      const { widgetID, data } = action.payload;
      if (state.Widgets[widgetID]) {
        state.Widgets[widgetID]['Data'] = data;
        if (data?.filter) state.Widgets[widgetID]['Filter'] = data.filter;
        state.Widgets[widgetID].loaded = true;
        state.Widgets[widgetID].loading = false;
      }
      return state;
    },
    [actions.analyticsGetWidgetDataRoutine.FAILURE]: (state, action: PayloadActionError & PayloadAction<{ ID: number }>) => {
      if (state.Widgets[action.payload.ID]) state.Widgets[action.payload.ID].error = true;
      return state;
    },
    [actions.analyticsGetWidgetDataRoutine.FULFILL]: (state, action: PayloadAction<{ ID: number }>) => {
      if (state.Widgets[action.payload.ID]?.loading) state.Widgets[action.payload.ID].loading = false;
      return state;
    }
  });
