import { useSelector } from 'react-redux';
import { paramTabsSelector } from '@modules/catalog/selectors/tariffs';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { ActiveTabType, ButtonType } from '@modules/catalog/types/tariff';

export const useTariffFormulaButtons = (activeTab: ActiveTabType = ActiveTabType.const) => {
  const { buttons } = useSelector(paramTabsSelector);
  const [visibleButtons, setVisibleButtons] = useState<ButtonType[]>(() => buttons[activeTab]);

  useEffect(() => {
    setVisibleButtons(buttons[activeTab]);
  }, [activeTab, buttons]);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    setVisibleButtons((prev) =>
      search.length ? prev.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) : buttons[activeTab]
    );
  };
  return { visibleButtons, onSearchChange };
};
