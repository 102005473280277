import { RouteRecord, TripRecord } from '@modules/planning/children/by-date/types';
import { DriverModel, IndexedArray, TableRecord } from '@common/types';
import { getDriversByTransportCompany } from '@modules/planning/children/by-date/helpers/get-drivers-by-transport-company';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const getDriversOptions =
  ({ drivers }: { drivers: IndexedArray<DriverModel> | Array<DriverModel> }) =>
  (record: TableRecord) => {
    try {
      const transportCompanyID =
        Number(
          Object.keys((record as TripRecord).TransportCompanyEnum ?? {})[0] ?? ((record as RouteRecord)?.Vehicle?.TransportCompanyID || 0)
        ) || null;
      return getDriversByTransportCompany({
        transportCompanyID,
        drivers
      });
    } catch (error) {
      showUserAndDevError({ error });
      return [];
    }
  };
