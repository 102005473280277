import { Key, Keys, ReactDispatch, TableConfig, TableRecord } from '@common/types';
import { OrderTableComponent } from '@modules/orders-and-trips/components/tables/order-table-component';
import { depotRefDataSelector } from '@modules/orders-and-trips/selectors/extra-data';
import { DepotVariants } from '@modules/orders-and-trips/types/depot';
import { ActiveRecord } from '@modules/orders-and-trips/types/general';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { FC } from 'react';
import { useSelector } from 'react-redux';

//TODO убрать моки и сделать типы как только будет готов бэк
interface IProps {
  orders?: DecoratedOrders;
  recordTableButtons?: any;
  onSelectRows?: (newSelectRows: Keys) => void;
  loading?: boolean;
  focusRow?: ActiveRecord;
  rowSelection?: { type: 'radio' | 'checkbox' };
  tableConfig?: TableConfig;
  handleFocusRow?: ({ key }: { key: Key }) => void;
}

export const OrderTableContainer: FC<IProps> = ({ orders, ...props }) => {
  const depotRefData = useSelector(depotRefDataSelector);
  return <OrderTableComponent {...props} refData={depotRefData} dataSource={orders} />;
};

