import { put, takeLatest } from 'redux-saga/effects';
import { getExtraDataRoutine, getRestrictionRoutine } from '@modules/orders-and-trips/actions';

function* getExtraDataWorker() {
  try {
    yield put(getRestrictionRoutine.trigger());
  } catch (e) {
    console.error(e);
  }
}

export function* getExtraDataWatcher() {
  yield takeLatest(getExtraDataRoutine.trigger, getExtraDataWorker);
}

