import { getProjectsRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '../api';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetProjectsRequest, Project } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { FormatType } from '@common/types/general/date-time';

function* planningGetProjectsWorker(action: PayloadAction<GetProjectsRequest & { message?: string }>) {
  const { request, success, failure, fulfill } = getProjectsRoutine;
  const { from, till, message } = action.payload;

  try {
    yield put(request());

    const { data }: { data: { data: Array<Project> } } = yield call(PlanningAPI.getProjects, { from, till });
    const projects = data.data;
    //TODO reduce + moment/date-parsers
    const availableDates = createArrayFromIndexedArray(projects)
      ?.map(({ Start }) => timestampToDateTimeString(Start, FormatType.Date))
      .filter((date, i, availableDates) => availableDates.indexOf(date) === i);
    yield put(success({ projects, availableDates, from, message }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningGetProjectsWatcher() {
  yield takeLatest(getProjectsRoutine.trigger, planningGetProjectsWorker);
}
