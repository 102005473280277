import { documentUploadRoutine } from '@modules/documents/children/upload/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DocumentsUpload } from '@modules/documents/children/upload/api';
import { documentTypeSelector } from '@modules/documents/children/upload/selectors';
import { DocumentType } from '@modules/documents/types/document-type';
import { FilesLocalForage } from '@common/utils/general/files-local-forage';
import { UPLOAD_DOCUMENT_FILE_KEY } from '@modules/documents/children/upload/constants/local-forage';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';

function* documentUploadWorker(action: PayloadAction<any>) {
  const { request, success, failure, fulfill } = documentUploadRoutine;

  try {
    yield put(request());

    const values = action.payload;
    const type: DocumentType = yield select(documentTypeSelector);
    const file: File = yield FilesLocalForage.getItem(UPLOAD_DOCUMENT_FILE_KEY);

    if (!file) {
      ILSUserNotify(Notify.Error, 'Необходимо добавить файл', 3);
      return;
    }

    yield call(DocumentsUpload.create, type, file, values);

    ILSUserNotify(Notify.Success, 'Документ успешно загружен');

    yield put(success());
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* documentUploadWatcher() {
  yield takeEvery(documentUploadRoutine.TRIGGER, documentUploadWorker);
}
