import { TenderOutFastDeliveryServices } from '../types/integrations';

export const MOCK_TRANSPORT_COMPANY = [
  {
    id: TenderOutFastDeliveryServices.Dl,
    name: 'Деловые линии',
    img: '/assets/images/transport-company/dl.png',
    active: 1
  }
];

