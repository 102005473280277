import { FC } from 'react';
import { EventDetailsTab } from '@modules/monitoring/children/event-details/types';
import { tableCustom } from '@core/containers/table-custom';
import { Dictionary } from '@common/types';
import { tableEventDetailsTrackConfig, tableEventDetailsTrackTable } from '@common/table-configs/monitoring-event-details/track-table';
import { MonitoringEventDetailsStore } from '../../types/store';
import { DetailsTrackTableComponent } from '../../components/details/details-track';

interface Props {
  tab: EventDetailsTab;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
}

/**
 * Контейнер для таблицы детализации маршрута
 *
 */

export const DetailsTrackTable: FC<Props> = ({ tab, vehicleSensor, tracks, vehicleEvents, ...props }) => {
  const decoratedData = tableEventDetailsTrackTable(vehicleEvents?.data, tracks.data, tracks.trackGeo, tracks.selectedVehicleId);

  return (
    <DetailsTrackTableComponent
      {...props}
      tab={tab}
      vehicleEvents={vehicleEvents}
      tracks={tracks}
      vehicleSensor={vehicleSensor}
      decoratedData={decoratedData}
    />
  );
};

export const DetailsTrackContainer = tableCustom(DetailsTrackTable, tableEventDetailsTrackConfig, Dictionary.EventDetailsTrack);
