import { ILSTypographyText } from '@common/components';
import { AnalyticsTableWidgetTotalChar, AnalyticsTableWidgetTotalInfo } from '@common/types/dictionaries/analytics';
import { FC } from 'react';
import SummaryChart from './summary-chart';

const AnalyticsTableSummary: FC<{
  data: AnalyticsTableWidgetTotalInfo[];
  chart: AnalyticsTableWidgetTotalChar[];
}> = (props) => {
  const { data, chart } = props;

  return (
    <>
      <tr className="analytics-table-summary">
        <td>
          <ILSTypographyText>Итого:</ILSTypographyText>
        </td>
        {data?.map((item, i) => (
          <td key={i} className="analytics-table-summary-td">
            <ILSTypographyText>{item.value}</ILSTypographyText>
          </td>
        ))}
      </tr>
      <tr className="analytics-table-summary">
        <td />
        {data?.map((item, i) => (
          <td key={i} className="diagram-td">
            <SummaryChart key={i} charItem={item} data={chart[item.data]} />
          </td>
        ))}
      </tr>
    </>
  );
};

export default AnalyticsTableSummary;
