import { logisticServicesContent } from '@modules/current-user/constants/logistic-servises';
import { LogisticServicesGroups } from '@modules/current-user/types/logistic-servises';
import { LogisticServicesCard } from '@modules/current-user/components/logistic-services/card';

export const LogisticServicesColumn = ({ serviceGroup }: { serviceGroup: LogisticServicesGroups }) => {
  return (
    <ul className="logistic-services__column">
      {logisticServicesContent[serviceGroup].map((service) => (
        <LogisticServicesCard service={service} />
      ))}
    </ul>
  );
};

