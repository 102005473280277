import { ILSMap } from '@core/containers/map';
import { IndexedArray } from '@common/types';
import { MonitoringVehicleEventsModel } from '@common/types/dictionaries/monitoring-vehicle';
import L from 'leaflet';
import { MutableRefObject, useEffect, useState } from 'react';
import { createWaypointMarker } from '../utils/map/map-waypoint-markers';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { useSideAction } from '@core/utils/map/side-action';
import { isShouldWaypointBeTaken } from '@common/table-configs/monitoring-dashboard/utils/waypoints';

// Хук для простановки маркеров точек
export const useWaypointMarker = (args: {
  Map: MutableRefObject<ILSMap | null>;
  cluster?: L.MarkerClusterGroup;
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>;
  selectedVehicleIDs?: (string | number)[];
  activeVehicleID?: string | number;
  selectedVehicleID?: string | number;
  vehicleFocusHandler?: (activeVehicleID: string | number) => void;
}) => {
  const { Map, cluster, vehicleEvents, selectedVehicleIDs, activeVehicleID, selectedVehicleID, vehicleFocusHandler } = args;
  const [wpMarkers, setWpMarkers] = useState({});
  const curMap = Map.current;
  useEffect(() => {
    if (curMap && cluster) {
      let incomingMarkers = {};
      const { addToSide: addToSideShow, runSideAction: runSideShow } = useSideAction(Map);
      const { addToSide: addToSideHide, runSideAction: runSideHide } = useSideAction(Map, true);
      if (selectedVehicleIDs?.length || activeVehicleID) {
        for (const eventID in vehicleEvents) {
          const vehicle = vehicleEvents[eventID];

          if (
            !vehicle.Trip ||
            (activeVehicleID?.toString() !== vehicle.ID?.toString() &&
              !selectedVehicleIDs?.includes(vehicle.ID?.toString()) &&
              selectedVehicleID?.toString() !== vehicle.ID?.toString())
          ) {
            continue;
          }
          let tripNumber = 0;
          for (const tripID in vehicle.Trip) {
            tripNumber++;
            const trip = vehicle.Trip[tripID];
            if (!trip.Waypoint) continue;
            for (const wpID in trip.Waypoint) {
              const wp = trip.Waypoint[wpID];
              const isNotGarage = isShouldWaypointBeTaken(wp, vehicle);
              if (wp.Depot) {
                const coords = Object.values(wp.Depot.LatLon ?? {});
                if (coords.length > 1) {
                  const marker = {
                    ID: eventID + '#' + tripID + '#' + wpID,
                    tooltipProps: {
                      name: wp.Depot.Name,
                      time: wp.EventStatusTime ? timestampToDateTimeString(wp.EventStatusTime) : '',
                      vehicleName: vehicle.Name || '',
                      tripID: trip.ID?.toString(),
                      DistanceFromPrevPoint: wp.DistanceFromPrevPoint?.toFixed(2)
                    },
                    /** TMS-9368 порядок точек должен идти по tripNumber затем по Waypoint.Ind */
                    Number: `${tripNumber}.${wp.Ind + 1}`,
                    coords: coords as [number, number],
                    lMark: undefined,
                    EventStatusType: wp.EventStatusType,
                    isNotGarage
                  };
                  let lMark = createWaypointMarker(marker, { popupopen: () => vehicleFocusHandler?.(vehicle.ID) })?.L;
                  incomingMarkers[marker.ID] = {
                    ...marker,
                    lMark
                  };
                }
              }
            }
          }
        }
      }
      if (Object.keys(wpMarkers).length) {
        for (const key in wpMarkers) {
          if (incomingMarkers[key]) {
            if (incomingMarkers[key].EventStatusType === wpMarkers[key].EventStatusType) {
              incomingMarkers[key].lMark = wpMarkers[key].lMark;
            } else {
              addToSideHide(wpMarkers[key].lMark, cluster);
            }
          } else {
            addToSideHide(wpMarkers[key].lMark, cluster);
          }
        }
        for (const key in incomingMarkers) {
          if (!wpMarkers[key]) {
            addToSideShow(incomingMarkers[key].lMark, cluster);
          }
        }
      } else {
        for (const key in incomingMarkers) {
          addToSideShow(incomingMarkers[key].lMark, cluster);
        }
      }
      runSideHide();
      runSideShow();
      setWpMarkers(incomingMarkers);
    }
  }, [Map, vehicleEvents, selectedVehicleIDs, activeVehicleID, vehicleFocusHandler]);
};

