import { Key, MarkerPlanning, PolygonPlanning } from '@common/types';
import { useAppSelector } from '@core/hooks';
import { layerDataSelector } from '@modules/planning/children/by-date/selectors/components/map/layers';
import { useMemo, useState } from 'react';
import { useCatalog } from '../use-catalog';
import { OnLayerChange } from '../../types/map-container';
import { projectDataSelector } from '@modules/planning/children/by-date/selectors/project';

type UseMapLayersArg = {};

export const useMapLayers = ({}: UseMapLayersArg) => {
  const [activeLayers, setActiveLayers] = useState<Key[]>([]);
  const mapLayersData = useAppSelector(layerDataSelector) ?? {};
  const { catalogLoad } = useCatalog();
  const { projectData } = useAppSelector(projectDataSelector);

  const layerData = useMemo(() => {
    let marker: Record<string, MarkerPlanning[]> = {},
      polygon: Record<string, PolygonPlanning[]> = {};
    activeLayers?.forEach((layerIDs) => {
      const layer = mapLayersData?.[layerIDs];
      if (layer?.data?.length && layer?.type) {
        if (layer.type === 'marker') {
          marker[layerIDs] = layer.data;
        }
        if (layer.type === 'polygon') {
          polygon[layerIDs] = layer.data;
        }
      }
    });
    return { marker, polygon };
  }, [activeLayers, projectData]);

  const onChangeLayer: OnLayerChange = (value, layer, clear) => {
    if (!clear && layer && layer.ID) {
      if (value) {
        setActiveLayers([...activeLayers, layer.ID]);
        const dictionary = mapLayersData[layer.ID].dictionary;
        if (dictionary) {
          catalogLoad(dictionary);
        }
      } else {
        setActiveLayers(activeLayers.filter((r) => r !== layer.ID && r));
      }
    } else {
      setActiveLayers([]);
    }
  };

  return { mapLayersData, activeLayers, onChangeLayer, layerData };
};
