import { Driver, IndexedArray, Vehicle } from '@common/types';
import { MonitoringBDDAndFuelDrainModel, MonitoringBDDDecoratorReturnedModel } from '@common/types/dictionaries/monitoring-vehicle';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';

import { viewedContent } from '@common/table-configs/monitoring-dashboard/common/cell-viewed-content';
import { MonitoringBDDEventsStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { MONITORING_IMEI_DT_SEPARATOR } from '@modules/monitoring/children/dashboard/constants';
import { isObject } from 'lodash';

type DataToTableFunction<R> = (
  data: Array<MonitoringBDDAndFuelDrainModel> | IndexedArray<MonitoringBDDAndFuelDrainModel>,
  handleView?: (id: string | number | string[] | number[], value: 0 | 1) => void
) => Array<
  R & {
    key: string;
    viewed: 0 | 1;
    dependentKey: string;
    ID: Vehicle['ID'];
    Name: Vehicle['Name'];
    Number: Vehicle['RegNumber'];
    DriverName: Driver['Name'];
    Phone: Driver['Phone'];
    DT: ReturnType<typeof timestampToDateTimeString>;
    rowKey: string;
    ViolationType: any;
    Comment: Vehicle['Comment'];
    Viewed: ReturnType<typeof viewedContent>;
    Favourite: number;
    VehicleID: Vehicle['ID'];
  }
>;

export const data2table: DataToTableFunction<MonitoringBDDDecoratorReturnedModel> = (data, handleView) => {
  const res: ReturnType<typeof data2table> = [];
  const vehicleData = isObject(data) ? Object.values(data) : data;
  const viewedCell = viewedContent(1, handleView, 'dependentKey');
  const notViewedCell = viewedContent(0, handleView, 'dependentKey');

  if (!vehicleData.length) {
    return res;
  }

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (!vehicle?.VehicleTracker || !Object.keys(vehicle.VehicleTracker || {})?.length) {
      continue;
    }

    for (const trackerIndex in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerIndex];

      if (!tracker.SensorMapping || !Object.keys(tracker.SensorMapping)?.length) {
        continue;
      }
      for (const sensorMappingIndex in tracker.SensorMapping) {
        const sensorData = tracker.SensorMapping[sensorMappingIndex];

        if (
          !MonitoringBDDEventsStatusType.includes(+sensorMappingIndex) ||
          !sensorData?.SensorValue ||
          !Object.values(sensorData.SensorValue)
        ) {
          continue;
        }
        for (const sensorValueIndex in sensorData.SensorValue) {
          const sensorValue = sensorData.SensorValue[sensorValueIndex];
          if (sensorData && isObject(sensorData)) {
            const viewed: 0 | 1 = sensorValue?.Viewed || 0;
            const dependentKey = sensorValue.Imei + MONITORING_IMEI_DT_SEPARATOR + sensorValue.DT;
            let rowObj = {
              ID: vehicle.ID,
              Name: vehicle.Name,
              Number: vehicle.RegNumber || '',
              DriverName: vehicle?.Driver?.Name || '',
              Phone: vehicle?.Driver?.Phone || '',
              DT: timestampToDateTimeString(sensorValue.DT),
              rowKey: sensorData.ID?.toString() + sensorValue.DT,
              key: sensorData.ID?.toString() + sensorValue.DT,
              ViolationType: sensorData.Sensor.Description,
              Comment: vehicle.Comment,
              Viewed: viewed ? viewedCell : notViewedCell,
              dependentKey,
              viewed,
              Favourite: 1,
              VehicleID: vehicle.ID
            };

            res.push(rowObj);
          }
        }
      }
    }
  }

  return res;
};
