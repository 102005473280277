import { useHistory } from 'react-router';

/**
 * Return:
 * callback handler for button-link
 * @param {string} path
 */
export const useLink = (path: string) => {
  const { push } = useHistory();
  const handleClick = () => {
    push(path);
  };

  return { handleClick };
};
