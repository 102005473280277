import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorGetConfigRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { GetConfigResponse } from '../types/api';

// Получает конфигурацию эмуляции с бэкэнда. Устанавливает время последнего обновления (lastUpdate),
// если есть ошибка - то устанавливает текст ошибки(emulatorStatus.errorText) и,
// если есть, статус эмуляции(data.Status)

const emulatorGetConfigReducer = createReducer(initialState, {
  [emulatorGetConfigRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.emulatorStatus.configs.isFetching = true;
    return state;
  },
  [emulatorGetConfigRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      data: GetConfigResponse;
      planID: number;
    }>
  ) => {
    const { data } = action.payload;
    if (data) {
      state.emulatorStatus.configs = {
        ...data,
        lastUpdate: new Date().getTime(),
        isFetching: false
      };
      state.emulatorStatus.errorText = data.ErrorText || undefined;

      state.emulatorStatus.status = data.Status || undefined;
    } else {
      state.emulatorStatus.configs.isFetching = false;
    }

    return state;
  },
  [emulatorGetConfigRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.emulatorStatus.configs.isFetching = false;
    return state;
  },
  [emulatorGetConfigRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.emulatorStatus.configs.isFetching = false;
    return state;
  }
});

export default emulatorGetConfigReducer;
