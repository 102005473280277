import { useMemo, useState } from 'react';
import { filterVehicleForOrdersDataSource } from '@modules/planning/children/by-date/helpers';
import { VehicleTableFilterFields } from '@modules/planning/children/by-date/types';
import { useSelector } from 'react-redux';
import { VEHICLE_TABLE_INITIAL_FILTERS } from '@modules/planning/children/by-date/constants/tables/vehicle';
import { planningNotFilteredVehiclesVehicleSelector } from '@modules/planning/children/by-date/selectors/current-plan';

export const useVehicleFilters = () => {
  const { vehiclesFullDataSource } = useSelector(planningNotFilteredVehiclesVehicleSelector);
  const [activeFiltersData, setActiveFiltersData] = useState<VehicleTableFilterFields>(VEHICLE_TABLE_INITIAL_FILTERS);

  const dataSource = useMemo(
    () => filterVehicleForOrdersDataSource(vehiclesFullDataSource, activeFiltersData),
    [activeFiltersData, vehiclesFullDataSource]
  );

  const clearFilters = () => setActiveFiltersData(VEHICLE_TABLE_INITIAL_FILTERS);

  const customClearFilters = {
    filtered: !!Object.values(activeFiltersData).filter((item) => item).length,
    clearFilters
  };
  return { dataSource, customClearFilters, setActiveFiltersData };
};
