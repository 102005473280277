import { IndexedArray } from '@common/types';
import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { clearContractorList, suggestContractorRoutine } from '../actions';

export const suggestContractorMode = createReducer(initialState, {
  [suggestContractorRoutine.REQUEST]: (state) => {
    state.contractors.data = [];
    state.contractors.isFetching = true;
    return state;
  },
  [suggestContractorRoutine.SUCCESS]: (
    state,
    action: {
      payload: {
        data: IndexedArray<any>;
      };
    }
  ) => {
    state.contractors.data = Object.values(action.payload.data);
    return state;
  },
  [suggestContractorRoutine.FAILURE]: (
    state,
    action: {
      payload: {
        error: string[];
      };
    }
  ) => {
    state.contractors.error = action.payload.error;
    state.contractors.data = [];
    return state;
  },
  [suggestContractorRoutine.FULFILL]: (state) => {
    state.contractors.isFetching = false;
    return state;
  },
  [clearContractorList.type]: (state) => {
    state.contractors.data = [];
  }
});
