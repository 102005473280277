import { CellType, FilterCellType, ShowDeleted, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const NEW_CROSS_DOCK = {
  Name: 'Новая зона'
};

export const CROSS_DOCK_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  export: true,
  copying: true,
  adding: NEW_CROSS_DOCK,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название зоны',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: 8,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Цвет',
      dataIndex: 'Color',
      key: 'Color',
      active: true,

      editable: true,
      align: 'center',
      isFixedColumn: true,
      sorting: 2,
      type: CellType.Color
    },
    {
      title: 'Точка',
      dataIndex: TableColumnName.DepotID,
      key: TableColumnName.DepotID,
      active: true,

      editable: true,
      ellipsis: false,
      align: 'center',
      isFixedColumn: true,
      type: CellType.CascaderAPI,
      grouping: true
    },
    {
      title: 'Масса в зоне',
      dataIndex: 'ZoneMass',
      key: 'ZoneMass',
      active: true,

      editable: true,
      align: 'center',
      isFixedColumn: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      sorting: 6,
      grouping: true
    },
    {
      title: 'Объем в зоне',
      dataIndex: 'ZoneVolume',
      key: 'ZoneVolume',
      active: true,

      editable: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      sorting: 5,
      align: 'center',
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Паллеты в зоне',
      dataIndex: 'ZonePallets',
      key: 'ZonePallets',
      active: true,

      editable: true,
      align: 'center',
      isFixedColumn: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 4,
      grouping: true
    },
    {
      title: 'Масса в точке',
      dataIndex: 'ClientMass',
      key: 'ClientMass',
      active: true,

      editable: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      sorting: 3,
      align: 'center',
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Объем в точке',
      dataIndex: 'ClientVolume',
      key: 'ClientVolume',
      active: true,

      editable: true,
      align: 'center',
      isFixedColumn: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      sorting: 2,
      grouping: true
    },
    {
      title: 'Паллеты в точке',
      dataIndex: 'ClientPallets',
      key: 'ClientPallets',
      active: true,

      editable: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 1,
      align: 'center',
      isFixedColumn: true,
      grouping: true
    },
    {
      title: 'Границы зоны',
      dataIndex: 'BorderHash',
      key: 'BorderHash',
      active: false,

      editable: false,
      sorting: 9,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    }
  ]
};
