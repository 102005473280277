import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { documentDeleteFromTrashRoutine } from '@modules/documents/actions';

export const documentDeleteFromTrash = createReducer(initialState, {
  [documentDeleteFromTrashRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [documentDeleteFromTrashRoutine.SUCCESS]: (
    state,
    action: {
      payload: {
        id: number;
      };
    }
  ) => {
    const data = state.data?.filter((document) => document.ID !== action.payload.id);

    return {
      ...state,
      data
    };
  },
  [documentDeleteFromTrashRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
