import { call, put, takeLatest } from 'redux-saga/effects';
import { widgetCatalogRoutine } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';

function* analyticsWidgetCatalogWorker(action: PayloadAction) {
  const { request, success, failure, fulfill } = widgetCatalogRoutine;
  try {
    yield put(request());

    const { data } = yield call(AnalyticsPlanningAPI.getWidgetCatalog);

    yield put(success({ data: data.data }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsWidgetCatalogWatcher() {
  yield takeLatest(widgetCatalogRoutine.trigger, analyticsWidgetCatalogWorker);
}
