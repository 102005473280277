import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'moment/locale/ru';
import { ILSDatePicker, ILSSpinCircle } from '@components/index';
import { FC, useEffect, useState } from 'react';
import { disabledDate } from '@common/utils/helpers/date-time/date-time';
import { TODAY } from '@common/constants/general';
import { DateFormat } from '@common/types';

export type IEmulatorDateProps = {
  isDateLoading: boolean;
  onMonthChange: any;
  onDateSelect: any;
  availableDates: Array<string>;
  defaultDate: any;
};

// Компонент для отображения дата пикера
const ILSEmulatorDateComponent: FC<IEmulatorDateProps> = ({ onMonthChange, onDateSelect, availableDates, defaultDate, isDateLoading }) => {
  let defDate = TODAY;

  //TODO не используется 
  const [pickerState, setPickerState] = useState<{
    value: number;
    set: boolean;  
    defaultValue: any;
    editing: boolean;
  }>({ value: defDate, set: true, defaultValue: defDate, editing: false });

  const onChange = (d: Moment | null) => {
    if (d) {
      onDateSelect(d.toDate());
      setPickerState({
        ...pickerState,
        value: d.toDate().getTime()
      });
    }
  };
  const onPanelChange = (d: any) => {
    if (d) {
      setPickerState({
        ...pickerState,
        value: d.toDate().getTime()
      });
      onMonthChange(d.toDate());
    }
  };
  const onOpenChange = (val: boolean) => {
    setPickerState({ ...pickerState, set: !val, editing: val });
  };

  useEffect(() => {
    if (defaultDate && (!pickerState.defaultValue || defaultDate !== pickerState.defaultValue)) {
      setPickerState({
        ...pickerState,
        value: defaultDate,
        defaultValue: defaultDate
      });
    }
  }, [defaultDate, pickerState]);

  return (
    <>
      <ILSDatePicker
        locale={locale}
        disabledDate={(current) => disabledDate(current, availableDates, false)}
        dateRender={(current) => {
          const isDisabled = disabledDate(current, availableDates);
          const style: { [k: string]: string } = {};
          if (isDisabled) {
            style.color = 'rgba(0, 0, 0, 0.25)';
          } else {
            style.backgroundColor = '#4C91FF';
            style.color = '#FFF';
          }
          return (
            <div className="ant-picker-cell-inner" style={style}>
              {current.date()}
            </div>
          );
        }}
        onPanelChange={onPanelChange}
        onChange={onChange}
        dropdownClassName="current-month-only"
        format={DateFormat.DDMMYYYYPeriod}
        defaultValue={moment(defDate)}
        defaultPickerValue={moment(defDate)}
        showToday={false}
        onOpenChange={onOpenChange}
      />
      <ILSSpinCircle spinning={isDateLoading} />
    </>
  );
};

export default ILSEmulatorDateComponent;
