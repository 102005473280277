import { FC } from 'react';
import { TripsContainer } from '@modules/orders-and-trips/containers/left/trips/trips-container';
import { RightBlock } from '@modules/orders-and-trips/containers/right/right-block';
import { ModalWindowContainer } from '@modules/orders-and-trips/containers/modal/modal-window';
import { ILSCol, ILSRow } from '@common/components/layout/grid';

export const MyApplications: FC = () => {
  return (
    <ILSRow className={'my-applications'}>
      <ILSCol span={14}>
        <TripsContainer />
        <ModalWindowContainer />
      </ILSCol>
      <ILSCol span={10}>
        <RightBlock />
      </ILSCol>
    </ILSRow>
  );
};
