import { catalogCloneRoutine, catalogReadRoutine, catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { CatalogClonePayload } from '@modules/catalog/types/actions';
import { checkAndCreateArrayFromObject } from '@common/utils';

export function* catalogCloneWorker(action: CatalogClonePayload) {
  const { dictionary, ids } = action.payload;
  const { request, success, failure, fulfill } = catalogCloneRoutine;

  try {
    yield put(request({ dictionary }));

    const {
      data: { data }
    } = yield call(DictionaryAPI.clone, { ids, dictionary });
    yield put(catalogSetFocusRowRoutine({ key: data.ID, dictionary }));
    yield put(
      success({
        data,
        dictionary
      })
    );
    /** Передаем id новой строки для фокусировки в catalog.read сагу */
    const newRows = checkAndCreateArrayFromObject(data);
    const rowIDToFocus = newRows[0].ID;

    yield put(
      catalogReadRoutine.trigger({
        dictionary,
        dictFetching: false,
        projectID: null,
        withoutNotification: true,
        uncheckProjectButton: true,
        rowIDToFocus: rowIDToFocus
      })
    );
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield delay(2000);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogCloneWatcher() {
  yield takeLatest(catalogCloneRoutine.trigger, catalogCloneWorker);
}

