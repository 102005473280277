import { table2dataType } from '@common/table-configs/types/decorator';
import { RestrictionGroup } from '@common/types';

export const data2table = (data: Array<RestrictionGroup>) => {
  if ('object' === typeof data) {
    return data.map((item, i) => {
      return {
        ...item,
        key: item.ID ?? 'row' + i
      };
    });
  } else {
    return [];
  }
};

export const table2data: table2dataType = (record, dataIndex, value) => {
  switch (dataIndex) {
    default:
      return value;
  }
};
