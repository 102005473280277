import { useEffect } from 'react';
import { getReportsListRoutine } from '@modules/documents/actions';
import { useDispatch } from 'react-redux';
import { ReportsListRequest } from '@modules/documents/types/documents-api';

export const useReportList = ({ module }: { module: ReportsListRequest['module'] }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportsListRoutine({ module }));
  }, []);
};
