import reduceReducers from 'reduce-reducers';
import { initialState } from '@modules/planning/children/data/store';
import { checkConflictReducer } from '@modules/planning/children/data/reducers/check-conflict';
import { getUploadTemplateReducer } from '@modules/planning/children/data/reducers/get-template';
import { getUploadedDataReducer } from '@modules/planning/children/data/reducers/get-uploaded-data';
import { dataUploadReducer } from '@modules/planning/children/data/reducers/upload';
import { tableDataChangeReducer } from '@modules/planning/children/data/reducers/change-table';
import { dataImportReducer } from '@modules/planning/children/data/reducers/import';
import { dataValidateReducer } from '@modules/planning/children/data/reducers/validate-data';
import { dataClearReducer } from '@modules/planning/children/data/reducers/clear';

export const planningDataReducer = reduceReducers(
  initialState,
  dataClearReducer,
  tableDataChangeReducer,
  checkConflictReducer,
  getUploadTemplateReducer,
  getUploadedDataReducer,
  dataImportReducer,
  dataUploadReducer,
  dataValidateReducer
);
