import { createSelector } from 'reselect';
import { DefaultUserParams, SettingsStore } from '@modules/settings/types/store';
import { DeepRequired } from '@common/types/general/general';

export const settingsDataSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings.data,
  (data) => data as DeepRequired<DefaultUserParams> | []
);

export const valuesSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings.values,
  (values) => values
);

export const fetchSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings.isFetching,
  (isFetching) => isFetching
);

