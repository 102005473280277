import '../styles.less';
import { FC } from 'react';
import { ILSTenderSearchListContainer } from '@modules/tender-platform/children/tender-search/containers/list';
import { Module } from '@common/types';
import { ILSTenderCardsContainer } from '@modules/tender-platform/containers/tender-card';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { useTenderOpen } from '@modules/tender-platform/utils/hooks';

export const ILSTenderSearch: FC = () => {
  const { search } = useAppHistory();
  const tender = new URLSearchParams(search).get(TenderAction.Tender);
  const { handleOpen } = useTenderOpen();

  return (
    <ILSTenderModuleContext.Provider value={Module.TenderSearch}>
      {tender ? <ILSTenderCardsContainer /> : <ILSTenderSearchListContainer handleOpen={handleOpen} />}
    </ILSTenderModuleContext.Provider>
  );
};
