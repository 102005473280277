import { createSelector } from 'reselect';
import { projectCargoSelector, projectIDSelector, projectOrderSelector } from '@modules/planning/children/by-date/selectors/project';
import { statusSelector } from '@modules/planning/selectors';
import { Dictionary, ReferenceTablesData } from '@common/types';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const planningResultSplitSelector = createSelector(
  projectIDSelector,
  activePlanIDSelector,
  statusSelector,
  (projectID, activePlanID, status) => {
    return { status, projectID, activePlanID };
  }
);

export const planningOrderCargoTableSelector = createSelector(projectCargoSelector, projectOrderSelector, (projectCargo, orders) => {
  const refData: ReferenceTablesData = {
    CargoID: {
      options: projectCargo,
      dictionary: Dictionary.Cargo,
      fieldNames: DEFAULT_FIELD_NAMES
    }
  };

  return { refData, orders };
});
