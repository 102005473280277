export enum Language {
  ru = 'Русский',
  en = 'English'
}

export enum LanguageCode {
  ru = 'ru',
  en = 'en'
}

export type LanguagesNames = keyof typeof Language;
