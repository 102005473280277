import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { dataImportRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { Project } from '@common/types';
import { CATALOG_RELOAD } from '@core/constants/core';
import { ReloadEnum } from '@core/types/constants';

export const dataImportReducer = createReducer(initialState, {
  [dataImportRoutine.REQUEST]: onRequest,
  [dataImportRoutine.SUCCESS]: (state, action: PayloadAction<{ projectID: Project['ID'] }>) => {
    const { projectID } = action.payload;
    state.projectID = projectID;
    localStorage.setItem(CATALOG_RELOAD, ReloadEnum.YES);
    return state;
  },
  [dataImportRoutine.FAILURE]: onFailure,
  [dataImportRoutine.FULFILL]: onFulfill
});
