import { useGeoHash } from '@common/hooks/use-geoHash';
import { IndexedArray, Plan, Provider } from '@common/types';
import {
  MonitoringGetTrackDataModel,
  MonitoringTrackModel,
  MonitoringVehicleEventsModel
} from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { SortDirection } from '@common/types/general';
import { bearing } from '@common/utils/general/bearing';
import { sortedObjectKeys } from '@common/utils/general/object-functions';
import { createTrackKeyByApiPoint } from '@common/utils/get-track/create-track-key';
import { PointTypeApi, PointWithData } from '@common/utils/get-track/types';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { arrowMarker } from '@core/components/map/icons';
import { ILSMap, ILSMarker, ILSPolyline } from '@core/containers/map';
import { useAppDispatch } from '@core/hooks';
import { loadPlanTrackRoutine } from '@modules/monitoring/actions';
import { deepEqual } from 'fast-equals';
import L, { LatLngExpression } from 'leaflet';
import { MutableRefObject, useEffect, useState } from 'react';
import { NO_INFO_MESSAGE } from '../constants/hooks';
import { MapEvent } from '../constants/map';
import { TabType } from '../types/event-details-tabs';
import { MapLayerGroupState, PointEventAbstract, TrackAbstract } from '../types/map';
import { generateInfoTemplate } from '../utils/map/generate-info';
import { pointsToPolylineInit } from '../utils/map/points-to-polyline-init';
import { rebuildDataTrackData } from '../utils/map/rebuild-track-data';
import { planTrackTooltip } from '../utils/map/tooltips';
import { filterInvalidTrack } from '../utils/track-filter-unvalid';

/**
 * Хук для отображения треков
 * @Map - карта
 *
 */
//TODO рефакторинг + добавить комментарии
export const useTracks = (args: {
  Map: MutableRefObject<ILSMap | null>;
  trackData?: IndexedArray<MonitoringGetTrackDataModel>;
  trips?: MonitoringVehicleEventsModel['Trip'] & IndexedArray<{ ForbiddenZones?: number[] | null }>;
  updates?: boolean;
  vehiclePlans?: IndexedArray<Plan>;
  show?: boolean;
  mapLayersGroup?: MapLayerGroupState;
  activeTab: TabType | undefined;
  activeVehicleID?: number;
  VehicleEvents: IndexedArray<MonitoringVehicleEventsModel>;
  loading: boolean | undefined;
  planTrackData?: {
    trackData: {
      [K: string]: PointWithData;
    };
    isFetching: boolean;
  } | null;
}) => {
  const {
    Map,
    trips,
    trackData,
    planTrackData,
    updates,
    show,
    mapLayersGroup,
    activeTab,
    VehicleEvents,
    activeVehicleID,
    loading,
    vehiclePlans
  } = args;
  const dispatch = useAppDispatch();
  const [existingEvents, setExistingEvents] = useState<IndexedArray<PointEventAbstract & { polyLine?: L.Polyline }>>({});
  const [currentTrack, setCurrentTrack] = useState<number | undefined>();

  const [planLayer, setPlanLayer] = useState<{
    [WaypointID: string]: {
      coords: LatLngExpression[] | LatLngExpression[][];
      handlers: {};
      options: {};
    };
  } | null>(null);

  const [arrowMarkers, setArrowMarkers] = useState<IndexedArray<L.Marker>>({});

  const cache = useGeoHash();

  const [zoom, setZoom] = useState<number>(0);

  useEffect(() => {
    const newTrack = activeVehicleID != currentTrack;

    if (newTrack || !show) {
      for (const existingEventKey in existingEvents) {
        const existingEvent = existingEvents[existingEventKey];
        existingEvent.polyLine?.remove();
      }

      mapLayersGroup?.markerGroup?.clearLayers();
      setArrowMarkers({});

      setExistingEvents({});
      setCurrentTrack(activeVehicleID);
      if (!show) return;
    }

    if (trackData && show) {
      const sortedExistingEvents = sortedObjectKeys(existingEvents, SortDirection.Asc);

      let lastDT: number = 0;
      let track: IndexedArray<MonitoringTrackModel> = {};
      for (const vehicleTrack in trackData) {
        if (trackData[vehicleTrack]?.Tracker?.Track) {
          track = { ...track, ...trackData[vehicleTrack].Tracker.Track };
        }
      }
      let incomingEvents = filterInvalidTrack(track as TrackAbstract);

      if (updates) {
        incomingEvents = rebuildDataTrackData(existingEvents, incomingEvents);
      }

      const newEvents = {};
      const notChangedEvents = {};

      const incomingEventsKeys = sortedObjectKeys(incomingEvents, SortDirection.Asc);

      //if rebuilding current track
      if (!newTrack) {
        const changedEvents = {};
        sortedExistingEvents.forEach((key) => {
          const existingEvent = existingEvents[key];
          const incomingEvent = incomingEvents[key];
          if (
            !incomingEvent ||
            (incomingEvent.Latitude === existingEvent.Latitude &&
              incomingEvent.Longitude === existingEvent.Longitude &&
              incomingEvent.Speed === existingEvent.Speed &&
              incomingEvent.IMEI === existingEvent.IMEI)
          ) {
            notChangedEvents[key] = existingEvent;
            lastDT = parseInt(key);
          } else {
            existingEvent.polyLine?.remove();
            changedEvents[key] = incomingEvents[key];
          }
        });
      }
      const newEventsKeys = lastDT && !newTrack ? incomingEventsKeys.filter((x) => parseInt(x) > lastDT) : incomingEventsKeys;

      //add new events with datetime bigger than DT
      newEventsKeys.reduce((previous, eventDT) => {
        const event = incomingEvents[eventDT];
        let polyLine: L.Polyline | null = null;
        if (previous) {
          const info = [
            generateInfoTemplate({
              Name: VehicleEvents[activeVehicleID as number]?.Name || '',
              DriverName: VehicleEvents[activeVehicleID as number]?.Driver?.Name || '',
              Time: timestampToDateTimeString(event.DT),
              Lat: event.Latitude,
              Lon: event.Longitude,
              Speed: Number(event.Speed).toFixed(2)
            })
          ];

          const init: any = pointsToPolylineInit(
            previous,
            event,
            info || NO_INFO_MESSAGE,
            VehicleEvents[activeVehicleID as number]?.RouteColor as unknown as string
          );

          polyLine = Map?.current!.addPolyline(init);
        }
        newEvents[eventDT] = { ...event, polyLine };
        return event;
      }, incomingEvents[lastDT] || null);

      const resultEvents: IndexedArray<PointEventAbstract & { polyLine?: L.Polyline }> = { ...notChangedEvents, ...newEvents };
      const resultKeys = Object.keys(resultEvents);
      let firstPoint = resultEvents[resultKeys[0]];
      let lastPoint = resultEvents[resultKeys[resultKeys.length - 1]];

      if ((firstPoint?.Longitude || 0) > (lastPoint?.Longitude || 0)) {
        [firstPoint, lastPoint] = [lastPoint, firstPoint];
      }

      setExistingEvents(resultEvents);
    }
  }, [trackData, currentTrack, activeVehicleID, show, zoom]);

  useEffect(() => {
    let previousEvent: (PointEventAbstract & { polyLine?: L.Polyline }) | undefined;
    let summaryDistanceForArrows = 0;

    const newArrowMarkers: IndexedArray<L.Marker> = {};

    for (const DT in existingEvents) {
      const event = existingEvents[DT];

      if (event.Latitude && event.Longitude && previousEvent?.Latitude && previousEvent.Latitude && previousEvent.Longitude) {
        const distance = Map?.current?.Map?.distance([previousEvent.Latitude, previousEvent.Longitude], [event.Latitude, event.Longitude]);

        if (distance && distance + summaryDistanceForArrows > 200000 / (zoom || 1)) {
          const marker: any = {
            coords: [previousEvent.Latitude, previousEvent.Longitude],
            handlers: {},
            options: {
              icon: arrowMarker(
                bearing(previousEvent.Latitude, previousEvent.Longitude, event.Latitude, event.Longitude),
                VehicleEvents[activeVehicleID as number]?.RouteColor as unknown as string
              )
            }
          };

          summaryDistanceForArrows = 0;

          if (arrowMarkers?.[DT]) {
            newArrowMarkers[DT] = arrowMarkers?.[DT];
          } else {
            const Lmarker = new ILSMarker(marker).L;

            mapLayersGroup?.markerGroup?.addLayer(Lmarker);
            newArrowMarkers[DT] = Lmarker;
          }
        } else if (distance) {
          summaryDistanceForArrows += distance;
        }
      }

      previousEvent = existingEvents[DT];
    }
    for (const DT in arrowMarkers) {
      if (!newArrowMarkers[DT]) {
        mapLayersGroup?.markerGroup?.removeLayer(arrowMarkers[DT]);
      }
    }

    setArrowMarkers(newArrowMarkers);
  }, [zoom, existingEvents]);

  useEffect(() => {
    const updateZoom = (e: any) => {
      if (e.target._zoom !== zoom) {
        setZoom(e.target._zoom);
      }
    };

    Map.current?.Map?.on(MapEvent.ZOOMEND, updateZoom);

    return () => {
      Map.current?.Map?.off(MapEvent.ZOOMEND, updateZoom);
      mapLayersGroup?.markerGroup?.clearLayers();
    };
  }, []);

  useEffect(() => {
    if (!trips) return;

    if (!show && planLayer) {
      mapLayersGroup?.polylineGroup?.clearLayers();
      setPlanLayer(null);
      return;
    }
    if (show) {
      //Слои которые нужно будет отрисовать на карте
      const newPlanLayer: {
        [WaypointID: string]: {
          coords: LatLngExpression[] | LatLngExpression[][];
          handlers: {};
          options: {};
        };
      } = {};
      //Список точек для загрузки из редиса
      const addToRequestPlanTrack: { [trackKey: string]: PointTypeApi } = {};
      for (const tripId in trips) {
        const trip = trips[tripId];
        const providerName: Provider | undefined | null = trip.PlanID ? vehiclePlans?.[trip.PlanID]?.ProviderName : null;

        let prevWaypoint: MonitoringWaypointModel | undefined;
        for (const waypointId in trip.Waypoint) {
          const waypoint = trip.Waypoint[waypointId];

          if (waypoint?.GeoHash || waypoint?.Depot?.LatLon) {
            //Плановый трек полученный сразу из плана
            const coords = waypoint?.GeoHash ? (cache(waypoint.GeoHash) as LatLngExpression[] | LatLngExpression[][]) : undefined;
            //Прямая между конечными точками треков
            let nativeCoords: LatLngExpression[] | LatLngExpression[][] = [];
            //Трек полученный из треков
            let trackRedisCoords: LatLngExpression[] | LatLngExpression[][] | null = null;

            if (waypoint?.Depot?.LatLon && prevWaypoint?.Depot?.LatLon) {
              nativeCoords = [
                Object.values(prevWaypoint?.Depot?.LatLon) as LatLngExpression,
                Object.values(waypoint?.Depot?.LatLon) as LatLngExpression
              ];
            }
            if (nativeCoords.flat()?.length === 4 && !coords) {
              const pointApi: PointTypeApi = {
                From: {
                  Latitude: nativeCoords[0][0],
                  Longitude: nativeCoords[0][1]
                },
                To: {
                  Latitude: nativeCoords[1][0],
                  Longitude: nativeCoords[1][1]
                },
                ForbiddenZones: trip.ForbiddenZones?.join(';') || ''
              };
              if (providerName && trip.PlanID && trip.ID && trip.VehicleID && waypoint.ID) {
                pointApi.ProviderName = providerName;
                pointApi.Details = {
                  PlanID: Number(trip.PlanID),
                  VehicleID: trip.VehicleID,
                  TripID: trip.ID,
                  WaypointID: waypoint.ID
                };
              }
              const trackKey = createTrackKeyByApiPoint(pointApi);
              if (!planTrackData?.trackData?.[trackKey] || planTrackData?.trackData[trackKey]?.track === undefined) {
                addToRequestPlanTrack[trackKey] = pointApi;
              } else {
                if (planTrackData.trackData[trackKey]?.track) {
                  trackRedisCoords = cache(planTrackData.trackData[trackKey].track as string) as
                    | LatLngExpression[]
                    | LatLngExpression[][]
                    | null;
                }
              }
            }

            const planRouteInit = {
              coords: coords || trackRedisCoords || nativeCoords || [],
              handlers: {
                click: (event: any) => {
                  const nextDepotName =
                    waypoint?.Depot?.Name + (waypoint?.Depot?.AddressDescription ? ` (${waypoint?.Depot?.AddressDescription})` : '');
                  const prevDepotName = prevWaypoint
                    ? prevWaypoint?.Depot?.Name +
                      (prevWaypoint?.Depot?.AddressDescription ? ` (${prevWaypoint?.Depot?.AddressDescription})` : '')
                    : undefined;

                  event.layer.setPopupContent(
                    planTrackTooltip({
                      tripID: tripId,
                      latitude: event.event.latlng.lat?.toFixed(4),
                      longitude: event.event.latlng.lng.toFixed(4),
                      name: activeVehicleID ? VehicleEvents[activeVehicleID]?.Name : undefined,
                      vehicleRegNumber: activeVehicleID ? VehicleEvents[activeVehicleID]?.RegNumber ?? undefined : undefined,
                      nextDepotName,
                      prevDepotName
                    })
                  );
                }
              },
              options: {
                color: VehicleEvents[activeVehicleID as number]?.RouteColor,
                dashArray: [6, 10]
              },
              popup: [planTrackTooltip({ tripID: tripId })]
            };
            newPlanLayer[waypointId] = planRouteInit;
          }
          prevWaypoint = waypoint;
        }
      }
      // поставновка на загрузку плановых треков из редиса
      if (Object.keys(addToRequestPlanTrack).length && !planTrackData?.isFetching) {
        dispatch(loadPlanTrackRoutine({ points: addToRequestPlanTrack }));
      }
      if (!deepEqual(newPlanLayer, planLayer)) {
        setPlanLayer(newPlanLayer);
      }
    }
  }, [activeVehicleID, planTrackData?.isFetching, show, activeTab, loading]);

  useEffect(() => {
    // Для отрисовки плановых треков
    if (planLayer && activeTab === TabType.PlanFact && show && mapLayersGroup?.polylineGroup) {
      mapLayersGroup.polylineGroup.clearLayers();
      for (let wpID in planLayer) {
        const l = new ILSPolyline(planLayer[wpID]).L;
        mapLayersGroup.polylineGroup.addLayer(l);
      }
    }

    if (activeTab !== TabType.PlanFact && planLayer && mapLayersGroup?.polylineGroup) {
      mapLayersGroup.polylineGroup.clearLayers();
      setPlanLayer(null);
    }
  }, [planLayer, activeTab, show, mapLayersGroup]);
};

