import { PlanResponse } from '@common/types/dictionaries/plan';
import { ProjectState } from '@common/types/dictionaries/project';
import { filteredData } from '@modules/planning/children/by-date/decorators/map/filter';
import { orderMap } from '@modules/planning/children/by-date/decorators/map/order';
import { routeMap } from '@modules/planning/children/by-date/decorators/map/route';
import { UnresolvedMarkerSourceValue } from '@modules/settings/types';
import { MapActivePlan } from '../../types/map-container';
import { MapConvex, MapLines, MapOrders } from './index';

//TODO Add UNIT
export const activePlanMapDecorator = (
  plan: PlanResponse,
  project: Pick<ProjectState, 'Order' | 'Vehicle' | 'Depot' | 'Worktime'> | null,
  filteredRestrictions: number[]
): MapActivePlan => {
  const projectOrder = project?.Order || {},
    projectVehicle = project?.Vehicle || {},
    projectDepot = project?.Depot || {},
    projectWorktime = project?.Worktime || {};

  const { Order, Vehicle } = filteredData(projectOrder, projectVehicle, filteredRestrictions);

  const { routeConvexPolygon, depotIDsFromWaypoint, resolvedOrderIDs, track, waypointMapData } = routeMap(
    plan,
    projectVehicle,
    Vehicle,
    projectDepot,
    Order,
    plan.ID
  );

  const { unresolvedOrder } = orderMap(Order, resolvedOrderIDs);

  //TODO убрать Хардкод настроек { unresolvedMarkerSource: 'target' }
  const { line, depotIDsFromOrder, orderMarkers } = MapOrders(unresolvedOrder, projectDepot, projectWorktime, {
    unresolvedMarkerSource: UnresolvedMarkerSourceValue.Target
  });

  const decoratedTrack = MapLines(track, plan);

  const convexPolygon = MapConvex(routeConvexPolygon, plan.ID);

  return {
    track: decoratedTrack,
    convexPolygon,
    line,
    orderMarkers,
    depotIDsFromOrder,
    depotIDsFromWaypoint,
    waypointMapData
  };
};
