import { createSelector } from 'reselect';
import { RouteGraphStore } from '@modules/road-graph/common/types';

export const catalogSelector = createSelector(
  (state: { RoadGraph: RouteGraphStore }) => state.RoadGraph.catalog,
  (catalog) => catalog
);
export const catalogInfoSelector = createSelector(
  (state: { RoadGraph: RouteGraphStore }) => state.RoadGraph.catalog.Info,
  (catalog) => catalog
);
export const depotsSelector = createSelector(
  (state: { RoadGraph: RouteGraphStore }) => state.RoadGraph.catalog.Depot,
  (Depot) => Depot
);
