import { AddressAPI } from '@common/api/common/address';
import { ILSUserNotify } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import { Dictionary, NotifyDurationInSecond, TableRecord } from '@common/types';
import { GetAddressResponse, GetCoordinatesResponse } from '@common/types/general/api/address';
import { getAddressByCoordsYandex, getCoordsByAddressYandex } from '@core/utils/get-geocode';
import { catalogGetCoordsRoutine, catalogMultipleUpdateRoutine, catalogUpdateRoutine } from '@modules/catalog/actions';
import { planningGeocodeSettings } from '@modules/settings/selectors/planning';
import { GeocodeService } from '@modules/settings/types/planning';
import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { GET_COORD_NOTIFY } from '../constants/get-coords';
import { CatalogUpdate } from '@modules/catalog/types/actions';
import { reduceUpdateCoords } from '@common/sagas/utils/get-coords/reduced-get-coords';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';
import { checkCoordsModalFromRoutine, checkCoordsModalFromRoutineYandex } from '@common/utils/get-coords-modal/get-coordinates-modal';

const MESSAGE_KEY = 'CATALOG_GET_COORDS';

type ArgCheckCoord = {
  address: string | undefined;
  coords: number[] | undefined;
  ID: number;
  dictionary: Dictionary;
};
type UpdateOption = {
  showModal?: boolean;
  updatePriority?: CoordinatesUpdatePriority;
};

function* catalogGetCoordsWorker(
  action: PayloadAction<{
    records: { ID: number; coords?: number[]; address?: string; dictionary: Dictionary }[];
    showModal?: boolean; //Использовать ли модальное окно для выбора сохранения результатов (с true обновление будет идти по одной записи за раз)
    updatePriority?: CoordinatesUpdatePriority; //Если не используется модальное окно, по каким данным делать сохранение
  }>
) {
  const { records, showModal, updatePriority } = action.payload;
  const { request, success, failure, fulfill } = catalogGetCoordsRoutine;

  let successMessage: undefined | string;
  let notificationCloseDelay: undefined | NotifyDurationInSecond;

  try {
    yield put(request({ dictionary: MESSAGE_KEY }));
    const { geocodeService } = yield select(planningGeocodeSettings);

    if (!showModal) {
      const { updatedCounts } = yield call(reduceUpdateCoords, {
        records,
        geocodeService,
        updatePriority,
        multipleUpdateRoutine: catalogMultipleUpdateRoutine
      });
      notificationCloseDelay = 3;
      successMessage = `Обновлено местоположений ${updatedCounts} из ${records.length}`;
    } else if (geocodeService === GeocodeService.Yandex) {
      for (const record of records) {
        const { address, coords, dictionary, ID } = record;
        yield call(checkCoordsYandex, { address, coords, dictionary, ID }, { showModal, updatePriority });
      }
    } else {
      for (const record of records) {
        const { address, coords, dictionary, ID } = record;
        yield call(checkCoordsDaData, { address, coords, dictionary, ID }, { showModal, updatePriority });
      }
    }

    yield put(success({ dictionary: MESSAGE_KEY, notificationMessage: successMessage, notificationCloseDelay }));
  } catch (error) {
    console.error(error);
    yield put(failure({ error, dictionary: MESSAGE_KEY }));
  } finally {
    yield put(fulfill());
  }
}

function* checkCoordsYandex({ address, coords, ID, dictionary }: ArgCheckCoord, options: UpdateOption) {
  let addressByCoordsY: string | undefined, coordsByAddressY: number[] | undefined;

  if (coords?.[0] && coords?.[1]) {
    try {
      addressByCoordsY = yield call(getAddressByCoordsYandex, coords[0], coords[1]);
    } catch (error) {
      showError(GET_COORD_NOTIFY.GET_ADDRESS_ERROR);
    }
  }
  if (address) {
    try {
      coordsByAddressY = yield call(getCoordsByAddressYandex, address);
    } catch (error) {
      showError(GET_COORD_NOTIFY.GET_COORDINATES_ERROR);
    }
  }
  const values: Record<string, any> = yield call(
    checkCoordsModalFromRoutineYandex,
    address,
    coords?.join(',') as string | undefined,
    addressByCoordsY,
    coordsByAddressY,
    options
  );
  if (!isEmpty(values) && ID && dictionary) {
    const payload = { dictionary, ids: [ID], values, LatLonVerified: 1 };
    yield put(catalogUpdateRoutine.trigger(payload));
  }
  yield delay(500);
}

function* checkCoordsDaData({ address, coords, dictionary, ID }: ArgCheckCoord, options: UpdateOption) {
  let respDataByAddress: GetCoordinatesResponse | undefined;
  let respDataByCoords: GetAddressResponse | undefined;

  if (coords?.[0] && coords?.[1]) {
    try {
      const { data } = yield call(AddressAPI.getAddress, { lat: coords[0], lon: coords[1] });
      if (data?.data?.length) {
        respDataByCoords = data.data as GetAddressResponse;
      }
    } catch (error) {
      showError(GET_COORD_NOTIFY.GET_ADDRESS_ERROR);
    }
  }
  if (address) {
    try {
      const { data: suggestionsData } = yield call(AddressAPI.suggestions, { q: address.replaceAll(',', EMPTY_STRING) });
      const { data } = yield call(AddressAPI.getCoordinates, { address: suggestionsData.data[0].value });
      respDataByAddress = data.data;
    } catch (error) {
      showError(GET_COORD_NOTIFY.GET_COORDINATES_ERROR);
    }
  }

  const values: Record<string, Partial<TableRecord>> = yield call(
    checkCoordsModalFromRoutine,
    address,
    coords?.join(','),
    respDataByCoords,
    respDataByAddress,
    options
  );
  if (values && Object.keys(values).length && dictionary && ID) {
    const payload: CatalogUpdate = { dictionary, ids: [ID], values: values as any };
    yield put(catalogUpdateRoutine.trigger(payload));
  }
  yield delay(100);
}

const showError = <K extends keyof typeof GET_COORD_NOTIFY, T extends typeof GET_COORD_NOTIFY[K]>(error: T) => {
  const { DURATION, KEY, MESSAGE, TYPE } = error;
  ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
};
export function* catalogGetCoordsWatcher() {
  yield takeEvery(catalogGetCoordsRoutine.trigger, catalogGetCoordsWorker);
}

