import React, { FC, useState } from 'react';
import { ILSModal } from '@components/index';
import { ReactSVG } from 'react-svg';

interface IProps {
  type: string | boolean;
  setConfirm(type: boolean | string): void;
  resetSettings(): void;
}

export const SettingsModalConfirmContainer: FC<IProps> = ({ type, setConfirm, resetSettings }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleOk = () => {
    handleCancel();
    resetSettings();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setConfirm(false);
  };

  return (
    <ILSModal
      title="Внимание!"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={type === 'default' ? 'Сбросить' : 'Сохранить'}
      cancelText="Отменить"
    >
      <div className="flex-center-center">
        <div className="warning-icon">
          <ReactSVG src={'/assets/icons/warning.svg'} />
        </div>
        <div className="ml-5">
          {type === 'default' ? (
            <>
              <p className="mb-3">Все пользовательские настройки будут заменены на настройки по умолчанию.</p>
              <p className="mb-3">Вы уверены, что хотите сбросить настройки до заводских?</p>
            </>
          ) : (
            <>
              <p className="mb-3">Вы внесли изменения в настройки, которые не сохранили.</p>
              <p className="mb-3">Сохранить внесенные изменения?</p>
            </>
          )}
        </div>
      </div>
    </ILSModal>
  );
};

