import { validateNewPassword } from '@modules/settings/utils/helpers/validators/validate-new-password';
import { comparePasswords } from '@modules/settings/utils/helpers/validators/compare-passwords';

export enum SettingsChangePasswordFormItem {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
  ConfirmPassword = 'confirm'
}

export const CHANGE_PASSWORD_FORM_SETTINGS_DEPENDENCIES = [SettingsChangePasswordFormItem.NewPassword];

export const CHANGE_PASSWORD_FORM_RULES = {
  NewPassword: [{ required: true, message: 'Введите новый пароль' }, { validator: validateNewPassword }],
  ConfirmPassword: [{ required: true, message: 'Подтвердите новый пароль' }, comparePasswords],
  OldPassword: [{ required: true, message: 'Введите старый пароль' }]
};

export enum SettingsChangePasswordButtonAction {
  SetDefault = 'default',
  SaveNew = 'new'
}
