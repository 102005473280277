import { planningClearRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningAPI } from '@modules/planning/api';
import { generateUUID } from '@common/utils/general/uuid';
import { Dictionary } from '@common/types';

function* planningClearWorker(
  action: PayloadAction<{
    planID: number;
  }>
) {
  const { request, failure, fulfill, success } = planningClearRoutine;
  const { planID } = action.payload;
  const notifyKey = generateUUID();
  try {
    yield put(request({ notifyKey }));
    const {
      data: { data }
    } = yield call(PlanningAPI.clearPlan, { planID });
    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });
    yield put(success({ message: data, notifyKey, planID, planTotal }));
  } catch (error) {
    yield put(failure({ error, notifyKey }));
  } finally {
    yield put(fulfill({ notifyKey }));
  }
}

export function* planningClearWatcher() {
  yield takeLatest(planningClearRoutine.trigger, planningClearWorker);
}
