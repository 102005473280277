import { Track } from '@modules/track/types/track';
import { tripDecorator } from '@modules/track/decorators/trip';
import { IndexedArray, RouteTotal } from '@common/types';

export const routeDecorator = (routes: IndexedArray<RouteTotal> | null): { tracks: Track[] } => {
  const tracksPlan: Track[] = [];

  for (const vehicleID in routes) {
    const route = routes[vehicleID];

    const { tracks } = tripDecorator(route.TripTotal, route.Vehicle);
    tracks.forEach((track) => {
      tracksPlan.push(track);
    });
  }

  return { tracks: tracksPlan };
};
