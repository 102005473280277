import { useLocation } from 'react-router';
import { useEffect } from 'react';
import ym from 'react-yandex-metrika';

export const useYm = () => {
  /* Для отображения переходов в Яндекс Метрике ловим изменения пути и отправляем hit */
  const location4YM = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_USE_YM) {
      ym('hit', location4YM.pathname);
    }
  }, [location4YM]);
};
