import { ColumnTable, TableColumnName } from '@common/types';
import { compareAsString } from '@common/utils';
import { order2PlannerTable } from '@common/models/order/decorators';
import { orderConfig } from '@modules/planning/children/by-date/config/order';

export const canEditOrder = (
  record: ReturnType<typeof order2PlannerTable>[number],
  dataIndex: ColumnTable<ReturnType<typeof order2PlannerTable>[number]>['dataIndex']
) => {
  switch (dataIndex) {
    case TableColumnName.LoadStart:
    case TableColumnName.UnloadStart:
    case TableColumnName.LoadEnd:
    case TableColumnName.UnloadEnd:
      return Boolean(record.WorktimeID);
    default:
      return Boolean(orderConfig.columns.find((column) => compareAsString(column.dataIndex, dataIndex))?.editable);
  }
};
