import { FC } from 'react';

interface Props {
  dateTime: string | undefined;
}

// Компонент для отображения даты и времени

export const ILSEmulatorDateTime: FC<Props> = ({ dateTime }) => {
  return <span className="ils-emulator-date-time">{dateTime}</span>;
};
