import { Dictionary, PlanStatus } from '@common/types';
import { MonitoringVehicleStatus } from '@common/types/dictionaries/monitoring-vehicle';

export const PLANNING_STATUSES = {
  [PlanStatus.Open]: { tooltip: 'открыт для изменений', text: 'открыт для изменений', icon: '/planning/status/unlocked' },
  [PlanStatus.AdditionsOnly]: {
    tooltip: 'открыт только для добавлений',
    text: 'открыт только для добавлений',
    icon: '/planning/status/half-locked'
  },
  [PlanStatus.ReorderingOnly]: {
    tooltip: 'открыт только для смены порядка',
    text: 'открыт только для смены порядка',
    icon: '/planning/status/fixed'
  },
  [PlanStatus.Closed]: { tooltip: 'закрыт для изменений', text: 'закрыт для изменений', icon: '/planning/status/locked' },
  [PlanStatus.TripWithDifferentStatuses]: {
    tooltip: 'рейсы в разных статусах',
    text: 'рейсы в разных статусах',
    icon: '/planning/status/mixed'
  },
  [PlanStatus.OnExecution]: { tooltip: 'на исполнении', text: 'на исполнении', icon: '/planning/status/100' },
  [PlanStatus.Started]: { tooltip: 'начат', text: 'начат', icon: '/planning/status/101' },
  [PlanStatus.Execution]: { tooltip: 'исполнение', text: 'исполнение', icon: '/planning/status/102' },
  [PlanStatus.Advance]: { tooltip: 'опережение', text: 'опережение', icon: '/planning/status/103' },
  [PlanStatus.BeingLate]: { tooltip: 'опоздание', text: 'опоздание', icon: '/planning/status/104' },
  [PlanStatus.Return]: { tooltip: 'возвращение', text: 'возвращение', icon: '/planning/status/105' },
  [PlanStatus.EarlyReturn]: { tooltip: 'возвращение с опережением', text: 'возвращение с опережением', icon: '/planning/status/106' },
  [PlanStatus.LateReturn]: { tooltip: 'возвращение с опозданием', text: 'возвращение с опозданием', icon: '/planning/status/107' },
  [PlanStatus.Completed]: { tooltip: 'завершён', text: 'завершён', icon: '/planning/status/200' },
  [PlanStatus.FinishedOutrunning]: { tooltip: 'завершён с опережением', text: 'завершён с опережением', icon: '/planning/status/201' },
  [PlanStatus.FinishedDelay]: { tooltip: 'завершён с опозданием', text: 'завершён с опозданием', icon: '/planning/status/202' },
  [PlanStatus.Failed]: { tooltip: 'не выполнен', text: 'не выполнен', icon: '/planning/status/203' },
  [PlanStatus.Canceled]: { tooltip: 'отменён', text: 'отменён', icon: '/planning/status/204' },
  [PlanStatus.CompletedLateWithUnvisited]: {
    tooltip: 'завершен, есть не посещенные',
    text: 'завершен, есть не посещенные',
    icon: '/planning/status/ending-late'
  },
  [PlanStatus.PartiallyFinishedOutrunning]: {
    tooltip: 'завершён с опережением, есть не посещённые точки',
    text: 'завершён с опережением, есть не посещённые точки',
    icon: '/planning/status/206'
  },
  [PlanStatus.PartiallyFinishedDelay]: {
    tooltip: 'завершён с опозданием, есть не посещённые точки',
    text: 'завершён с опозданием, есть не посещённые точки',
    icon: '/planning/status/207'
  },
  [PlanStatus.Invalid]: { tooltip: 'отклонён, как некорректный', text: 'отклонён, как некорректный', icon: '/planning/status/208' }
};

export const STATUSES_FOR_SELECTION = [
  PlanStatus.Open,
  PlanStatus.ReorderingOnly,
  PlanStatus.AdditionsOnly,
  PlanStatus.Closed,
  PlanStatus.TripWithDifferentStatuses
];
export const DISABLED_STATUSES_FOR_SELECTION = [PlanStatus.TripWithDifferentStatuses];
export const EDITABLE_PLAN_STATUSES = [PlanStatus.Open, PlanStatus.AdditionsOnly, PlanStatus.ReorderingOnly];
export const HIGHLIGHTED_RECORDS = [Dictionary.Waypoint, Dictionary.Trip];
export const DRAGGABLE_RECORDS = HIGHLIGHTED_RECORDS.concat([Dictionary.OrderAction]);
export const MAX_PLAN_COUNT = 8;
export const VEHICLE_TRIPS_STATUSES = {
  [MonitoringVehicleStatus.NoPlan]: { text: 'Нет плана', icon: 'close_round_bg_black' },
  [MonitoringVehicleStatus.During]: { text: 'В процессе', icon: 'play_round_bg_blue' },
  [MonitoringVehicleStatus.DuringWithLates]: { text: 'В процессе, есть опоздания', icon: 'warn_round_bg_yellow' },
  [MonitoringVehicleStatus.ReturnBase]: { text: 'Возвращается на базу', icon: 'arr_right_round_bg_green' },
  [MonitoringVehicleStatus.ReturnBaseWithLates]: { text: 'Возвращается на базу с опозданием', icon: 'arr_right_round_bg_red' },
  [MonitoringVehicleStatus.CompleteWithLates]: { text: 'Завершён с опозданиями', icon: 'succ_round_bg_red' },
  [MonitoringVehicleStatus.Complete]: { text: 'Завершён', icon: 'succ_round_bg_green' }
};

export const TRIP_STATUSES = {
  100: 'Рейс поставлен на исполнение',
  101: 'Рейс начат, груза нет',
  102: 'Рейс в процессе, машина с грузом',
  103: 'Рейс в процессе, машина с грузом, есть опережение',
  104: 'Рейс в процессе, машина с грузом, есть опоздание',
  105: 'Возврат на базу, груза нет',
  106: 'Возврат на базу, груза нет, есть опережение',
  107: 'Возврат на базу, груза нет, есть опоздание',
  200: 'Завершён',
  201: 'Завершён с опережением',
  202: 'Завершён с опозданием',
  203: 'Не выполнен',
  204: 'Отменён',
  205: 'Завершён, есть не посещённые точки',
  206: 'Завершён с опережением, есть не посещённые точки',
  207: 'Завершён с опозданием, есть не посещённые точки',
  208: 'Отклонён, как некорректный'
};
