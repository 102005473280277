import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningAddTrackToQueryRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { addTrackToQueryState } from '@common/utils/get-track/add-track-to-query-state';
import { PointType } from '@common/utils/get-track/types';

export const addTrackToQueryReducer = createReducer(initialState, {
  [planningAddTrackToQueryRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      points: Record<string, PointType>;
    }>
  ) => {
    const { points } = action.payload;
    state.planPoints.planDataQuery = addTrackToQueryState(state.planPoints.planDataQuery, points);
    return state;
  }
});
