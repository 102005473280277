import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import '../styles.less';
import { MAP_SIZES } from '@core/constants/map';
import { useMapInit } from '../hooks/map-init';
import { useAppSelector } from '@core/hooks';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';
import { Marker } from '@modules/road-graph/route-test/types';
import { depotsSelector } from '@modules/road-graph/common/selectors';
import { Depot } from '@common/types';
import { forbiddenZonesSelector, trackDataSelector } from '@modules/road-graph/route-test/selectors';
import { decodePolyline } from '@modules/road-graph/route-test/decorators';
import { Polygon } from '@core/types/map';

const ILSRoadGraphTestMapContainer: FC = () => {
  const { coords, setCoords, trackMarker, setTrackMarker, showDepots, showZones } = useContext(RoadGraphContext) ?? {};
  const depotData = useAppSelector(depotsSelector);
  const Map = useRef<any>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const trackData = useAppSelector(trackDataSelector);
  const forbiddenZones = useAppSelector(forbiddenZonesSelector);

  //инициализация карты
  useMapInit(Map);

  const depotMarkers = useMemo(() => {
    const depotMarkers: Marker[] = [];
    if (depotData?.length && showDepots) {
      depotData.forEach((depot: Depot) => {
        if (depot.LatLon) {
          depotMarkers.push({
            coords: depot.LatLon
          });
        }
      });
    }
    return depotMarkers;
  }, [showDepots, depotData]);

  //отрисовка депотов, точек трека
  useEffect(() => {
    if (Map) {
      const markers: Marker[] = depotMarkers.slice();

      if (trackMarker?.A?.coords) {
        markers.push({
          coords: trackMarker?.A?.coords,
          type: 'destination',
          text: 'A',
          noCluster: true
        });
      }
      if (trackMarker?.B?.coords) {
        markers.push({
          coords: trackMarker?.B?.coords,
          type: 'destination',
          text: 'B',
          noCluster: true
        });
      }

      Map.current.drawMarkers(markers);
    }
  }, [trackMarker, depotMarkers]);

  useEffect(() => {
    if (Map && (trackMarker?.A?.moving || trackMarker?.B?.moving)) {
      Map.current.clickOnMap(setCoords);
    }
  }, [trackMarker]);

  //отрисовка трека
  useEffect(() => {
    if (Map?.current) {
      const polylines = [];
      if (trackData?.routes && trackData.routes[0]?.geometry) {
        const coords = decodePolyline(trackData?.routes[0]?.geometry);
        polylines.push({
          color: '#4C91FF',
          coords: coords,
          weight: 5
        });
      }
      Map.current.drawPolylines(polylines);
    }
  }, [trackData]);

  useEffect(() => {
    if (Map?.current) {
      let polygons: Polygon[] = [];
      if (forbiddenZones?.data?.length) {
        polygons = forbiddenZones?.data
          .filter((fz) => showZones?.selectedZones.includes(fz.Zone))
          .map((zone) => {
            return {
              color: 'red',
              coords: zone.Points
            };
          });
      }
      Map.current.addPolygons(polygons);
    }
  }, [showZones, forbiddenZones?.data]);

  const handleSetCoords = () => {
    if (!coords || !(trackMarker?.A?.moving || trackMarker?.B?.moving) || !setTrackMarker) {
      return;
    }

    const set = trackMarker?.A?.moving ? 'A' : 'B';
    setTrackMarker({
      ...trackMarker,
      [set]: {
        moving: false,
        coords: coords
      }
    });
    setCoords && setCoords(undefined);
  };
  useEffect(() => {
    handleSetCoords();
  }, [coords]);

  return (
    <div className="map-container" ref={mapRef}>
      <div id="ils-map" style={{ ...MAP_SIZES }} />
    </div>
  );
};

export default ILSRoadGraphTestMapContainer;
