import reduceReducers from 'reduce-reducers';
import { paymentInitialState } from '@common/feature/payment/store';
import { getInvoiceTemplateReducer } from '@common/feature/payment/reducers/get-invoice-template';
import { getCurrentBalanceReducer } from '@common/feature/payment/reducers/get-current-balance';
import { getBalanceHistoryReducer } from '@common/feature/payment/reducers/get-balance-history';

const paymentReducer = reduceReducers(paymentInitialState, getInvoiceTemplateReducer, getCurrentBalanceReducer, getBalanceHistoryReducer);

export default paymentReducer;

