import { OwnTransport } from '@modules/orders-and-trips/components/transport/own-transport';
import { useOwnTransport } from '@modules/orders-and-trips/hooks/use-own-transport';

export const OwnTransportContainer = () => {
  const { recordTableButtons, onSelectRows } = useOwnTransport();
  return (
    <>
      <OwnTransport onSelectRows={onSelectRows} />
      <div>
        {recordTableButtons.map(({ Component, props }) => {
          return <Component {...props} />;
        })}
      </div>
    </>
  );
};

