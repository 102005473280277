import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { dateToString } from '@modules/documents/helpers/date-to-string';
import { DocumentSetChild } from '../children/templates/types/api';

export const addOptionsButtonTemplates = <T>(
  documents: Array<T & { ID?: number; Created: number; Deleted: number }>,
  getItems: (document: T) => ItemType[],
  menuItemsChildren?: (document: DocumentSetChild) => ItemType[],
  parentDocId?: number
) =>
  documents.map((document, key) => {
    const docWithAdditionalProperties = {
      ...document,
      key,
      Created: dateToString(document.Created),
      Deleted: dateToString(document.Deleted),
      parentDocId
    };

    const documentWithButton = {
      ...docWithAdditionalProperties,
      Button: {
        icon: '/assets/icons/options.svg',
        items: getItems(docWithAdditionalProperties)
      }
    };

    if (Array.isArray(documentWithButton['children']) && menuItemsChildren) {
      const children = documentWithButton['children'];

      documentWithButton['children'] = addOptionsButtonTemplates(children, menuItemsChildren, undefined, document.ID);
    }

    return documentWithButton;
  });
