import { call, put, takeLatest } from 'redux-saga/effects';
import { Data } from '@modules/planning/children/data/api';
import { dataValidateRoutine } from '@modules/planning/children/data/actions';

function* getValidateDataWorker() {
  const { request, success, failure, fulfill } = dataValidateRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(Data.validateData);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getValidateDataWatcher() {
  yield takeLatest(dataValidateRoutine.trigger, getValidateDataWorker);
}
