type ObjectParamType = {
  [K: string | number]: any;
};
type SimpleObjectType = {
  [K: string | number]: string | number | boolean | undefined | null | Symbol | BigInt;
};

export const simpleStringify = (object: ObjectParamType): string => {
  var simpleObject: SimpleObjectType = {};
  for (var prop in object) {
    if (!object.hasOwnProperty(prop)) {
      continue;
    }
    if (typeof object[prop] == 'object') {
      continue;
    }
    if (typeof object[prop] == 'function') {
      continue;
    }
    simpleObject[prop] = object[prop];
  }
  return JSON.stringify(simpleObject); // returns cleaned up JSON
};
