import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTripTimelineRoutine } from '@modules/orders-and-trips/actions';
import { activeTripSelector } from '@modules/orders-and-trips/selectors/trip';
import { activeTripTimelineSelector, tripTimelinesIsFetchingSelector } from '@modules/orders-and-trips/selectors/timelines';

export const useTripTimelineContainer = () => {
  const dispatch = useDispatch();
  const activeTrip = useSelector(activeTripSelector);
  const tripTimeline = useSelector(activeTripTimelineSelector);
  const isFetching = useSelector(tripTimelinesIsFetchingSelector);

  // TODO: строка закомментирована, пока не готова задача бэка на получение списка событий 10384
  // useEffect(() => {
  // dispatch(getTripTimelineRoutine({ tripID: activeTrip?.ID }));
  // }, [activeTrip?.ID]);
  // TODO: убрать isFetching false, когда будет бэк, сейчас undefined, и спиннер работает
  return { tripTimeline, isFetching: false };
};

