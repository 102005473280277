import { AnalyticsFormDataWidgetDataModel, TransformedFormDataModel } from '@common/types/dictionaries/analytics';

export default function transformFormDataWidgetData(data: AnalyticsFormDataWidgetDataModel): TransformedFormDataModel {
  const arrOfData = Object.values(data.values).map((item) => {
    return {
      col: data.metrics[item.name].col,
      title: data.metrics[item.name].title,
      order: data.metrics[item.name].order,
      icon: data.metrics[item.name].icon,
      ...item
    };
  });

  let res = {};

  for (let item of arrOfData) {
    if (res[item.col]) {
      res[item.col] = {
        ...res[item.col],
        [item.name]: item
      };
    } else {
      res[item.col] = {
        [item.name]: item
      };
    }
  }

  return res;
}
