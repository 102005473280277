import { FC } from 'react';
import { CardTab, CardTabTitle } from '@modules/tender-platform/constants';
import { EMPTY_STRING } from '@common/constants';
import { ILSTypographyTitle } from '@components/general/typography';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { ILSDivider } from '@common/components';

interface IProps {
  tenderOfferID: TenderOffer['ID'];
}

export const TenderSearchOfferTitleComponent: FC<IProps> = ({ tenderOfferID }) => {
  return (
    <ILSDivider
      key={`${CardTab.Documents}divider`}
      orientation={'left'}
      children={
        <ILSTypographyTitle
          key={CardTab.Offers}
          id={CardTab.Offers}
          level={2}
          children={`${CardTabTitle.Offer} ${tenderOfferID ? `№ ${tenderOfferID}` : EMPTY_STRING}`}
        />
      }
    />
  );
};
