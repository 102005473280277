import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSButton, ILSModal, ILSSkeleton } from '@common/components';
import {
  currentDealTemplateRoutine,
  getDocumentTemplatesRoutine,
  updateDealAttachRoutine
} from '@modules/documents/children/templates/actions';
import { documentTemplatesSelector, getCurrentDealTemplate } from '@modules/documents/children/templates/selectors';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

const TemplatesModal = () => {
  const dispatch = useAppDispatch();
  const { template, isViewTemplateModelOpen } = useAppSelector(getCurrentDealTemplate);
  const { documentTemplates, isFetching } = useAppSelector(documentTemplatesSelector);

  const handleClose = () => {
    dispatch(currentDealTemplateRoutine({ record: null, isViewTemplateModelOpen: false }));
  };

  const handleSelect = (i: DocumentTemplate) => {
    if (template) {
      dispatch(updateDealAttachRoutine({ data: { id: template.ID, templateID: [i.ID] } }));
      handleClose();
    }
  };

  useEffect(() => {
    if (isViewTemplateModelOpen) {
      dispatch(getDocumentTemplatesRoutine());
    }
  }, [isViewTemplateModelOpen]);

  return (
    <ILSModal onCancel={handleClose} title={'Выберите шаблон'} visible={isViewTemplateModelOpen} footer={false}>
      <ILSSkeleton loading={isFetching} active>
        <div className={'template-modal-block'}>
          {documentTemplates.map((i) => (
            <div key={i?.ID} className={'flex-between-center'}>
              <span>{i?.Name}</span>
              <ILSButton type="primary" onClick={() => handleSelect(i)}>
                Выбрать
              </ILSButton>
            </div>
          ))}
        </div>
      </ILSSkeleton>
    </ILSModal>
  );
};

export default TemplatesModal;
