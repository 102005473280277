import { createContext, FC } from 'react';
import { ILSContent, ILSLayout } from '@components/index';
import { renderRoutes } from 'react-router-config';
import CustomScroll from 'react-customscroll';
import { IPageProps } from '@pages/types';

export const RouteContext = createContext<IPageProps | null>(null);

const CustomForAuthPage: FC<IPageProps> = (props) => {
  return (
    <ILSLayout>
      <ILSLayout className="ils-wrapper">
        <ILSContent className="ils-content">
          {/* контекст для передачи навигации на рабочий стол  */}
          <RouteContext.Provider value={props}>
            {/* child routes won't render without this */}
            <CustomScroll>{renderRoutes(props.route.routes)}</CustomScroll>
          </RouteContext.Provider>
        </ILSContent>
      </ILSLayout>
    </ILSLayout>
  );
};

export default CustomForAuthPage;
