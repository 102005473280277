import { FC } from 'react';
import { ILSTabPane, ILSTabs } from '@components/index';
import { NEW_ORDER_FORM_TABS, NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { useFakePreCostTabs } from '@modules/orders-and-trips/hooks/use-fake-pre-cost-tabs';
import { Proposal } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal';

export const FakePreCostTabContainer: FC = () => {
  const { activeTab, handleActiveTab } = useFakePreCostTabs();

  return (
    <ILSTabs className={'fake-pre-cost'} destroyInactiveTabPane type="card" activeKey={activeTab} onChange={handleActiveTab}>
      {NEW_ORDER_FORM_TABS.map(({ FormFields, key, title }) => {
        return (
          <ILSTabPane className={'fake-pre-cost__wrapper'} tab={title} key={key}>
            {key === NewOrderFormTab.ProjectLogistics ? (
              <FormFields />
            ) : (
              <Proposal activeTab={activeTab} FormFields={FormFields} section={key} />
            )}
          </ILSTabPane>
        );
      })}
    </ILSTabs>
  );
};
