import { isEmpty } from 'lodash';
import { PaymentBalanceHistoryModified } from '@common/feature/payment/types/general';
import { PaymentBalanceHistoryCell } from '@common/feature/payment/components/payment-balance-history/cell/payment-balance-history-cell';
import { PaymentBalanceHistoryRow } from '@common/feature/payment/components/payment-balance-history/row/payment-balance-history-row';
import { transactionDataToRow } from '@common/feature/payment/utils/decorators/transaction-data-to-row';

export const PaymentBalanceHistoryRows = ({ balanceHistory }: { balanceHistory: PaymentBalanceHistoryModified[] }) => {
  return (
    <div className="payment-balance-history-rows">
      {!isEmpty(balanceHistory) ? (
        balanceHistory?.map((transaction) => (
          <PaymentBalanceHistoryRow transaction={transactionDataToRow(transaction)} key={transaction.id} />
        ))
      ) : (
        <PaymentBalanceHistoryCell title="Нет операций" />
      )}
    </div>
  );
};
