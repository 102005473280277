import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { tenderReadRestrictionRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { Restriction } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderReadRestrictionRead = createReducer(initialState, {
  [tenderReadRestrictionRoutine.REQUEST]: onRequest,
  [tenderReadRestrictionRoutine.FAILURE]: onFailure,
  [tenderReadRestrictionRoutine.FULFILL]: onFulfill,
  [tenderReadRestrictionRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      restrictions: Array<Restriction>;
    }>
  ) => {
    const { restrictions } = action.payload;
    state.restrictions = restrictions;
    return state;
  }
});
