import { ErrorAPI, Notify } from '@common/types';
import { SettingsAction } from '../../types/actions';
import { EMPTY_STRING } from '@common/constants';

const statuses: Partial<
  Record<
    SettingsAction,
    {
      loading: string;
      success: string;
      error?: string;
    }
  >
> = {
  [SettingsAction.GET_SETTINGS]: { loading: 'Загрузка...', success: 'Загружено' },
  [SettingsAction.SAVE_SETTINGS]: { loading: 'Сохранение настроек...', success: 'Настройки сохранены' },
  [SettingsAction.CHANGE_PASSWORD]: { loading: 'Изменение пароля...', success: 'Пароль изменен' },
  [SettingsAction.MOBILE_APPLICATION_DICTIONARY_OPERATION]: { loading: 'Обработка запроса...', success: 'Успешно' },
  [SettingsAction.TABLE_CONFIG]: { loading: 'Сохранение настроек...', success: 'Настройки сохранены' }
};

export const getNotificationMessage = (
  action: SettingsAction,
  notifyType: Notify.Success | Notify.Error | Notify.Loading,
  payload?: {
    hideSuccessMessage?: boolean;
  } & ErrorAPI
) => {
  if (payload?.hideSuccessMessage) {
    return EMPTY_STRING;
  }
  if (payload?.error) {
    return EMPTY_STRING;
  }

  return statuses?.[SettingsAction[action]]?.[notifyType] || EMPTY_STRING;
};

