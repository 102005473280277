import { TemplateData } from '@modules/documents/children/templates/types/api';
import { generateReportFromJson } from '@modules/documents/helpers/generate-report-from-json';
import { downloadFileByUrl } from '@common/utils/helpers/download-file-by-url';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

declare var Stimulsoft: any;

export const downloadDocumentFromTemplate = (template: TemplateData) => {
  const report = generateReportFromJson(template);

  report.renderAsync(() => {
    const blob = new Blob([new Uint8Array(report.exportDocument(Stimulsoft.Report.StiExportFormat.Pdf))], { type: 'application/pdf' });
    downloadFileByUrl(URL.createObjectURL(blob));
    ILSUserNotify(Notify.Success, `Файл скачан!`);
  });
};
