import { CSSProperties, FC, ReactNode } from 'react';
import { ColumnType } from 'antd/es/table';
import { CellType, IEditableCell, INotEditableCell, PlacementType } from './cell';

export enum TableColumnName {
  CommentDriver = 'CommentDriver',
  OrderCode = 'OrderCode',
  TargetAddress = 'TargetAddress',
  CargoDescription = 'CargoDescription',
  Organization = 'Organization',
  AvailableFrom = 'AvailableFrom',
  OrderID = 'OrderID',
  DepotTypeEnum = 'DepotTypeEnum',
  Trailer1ID = 'Trailer1ID',
  RampTypeID = 'RampTypeID',
  RestrictionGroupID = 'RestrictionGroupID',
  DepotTypeID = 'DepotTypeID',
  ID = 'ID',
  Type = 'Type',
  Name = 'Name',
  OriginalName = 'OriginalName',
  Carrier = 'Carrier',
  Mass = 'Mass',
  CurrentPrice = 'CurrentPrice',
  BasePrice = 'BasePrice',
  DriverCode = 'DriverCode',
  Deleted = 'Deleted',
  Delay = 'Delay',
  ClientContact = 'ClientContact',
  ContactID = 'ContactID',
  AllowKeepTrailer = 'AllowKeepTrailer',
  DepotOverloadID = 'DepotOverloadID',
  DepotOverload = 'DepotOverload',
  DistanceFromCity = 'DistanceFromCity',
  Radius = 'Radius',
  WorkPerWeek = 'WorkPerWeek',
  WorkPerTwoWeek = 'WorkPerTwoWeek',
  VisitPrice = 'VisitPrice',
  Queue = 'Queue',
  Value1 = 'Value1',
  Value2 = 'Value2',
  Value3 = 'Value3',
  UTCOffset = 'UTCOffset',
  TimeZone = 'TimeZone',
  CargoID = 'CargoID',
  DepotOverloadUnloadStartOverload = 'DepotOverloadUnloadStartOverload',
  DepotOverloadUnloadEndOverload = 'DepotOverloadUnloadEndOverload',
  DepotOverloadLoadStartOverload = 'DepotOverloadLoadStartOverload',
  DepotOverloadLoadEndOverload = 'DepotOverloadLoadEndOverload',
  FullName = 'FullName',
  PassportDate = 'PassportDate',
  PassportIssuedBy = 'PassportIssuedBy',
  DriverTimelineID = 'DriverTimelineID',
  ZoneID = 'ZoneID',
  Priority = 'Priority',
  WarehouseID = 'WarehouseID',
  DriverRoutes = 'DriverRoutes',
  Selection = 'Selection',
  Expanded = 'Expanded',
  Count = 'Count',
  Currency = 'Currency',
  Color = 'Color',
  Description = 'Description',
  BorderHash = 'BorderHash',
  Points = 'Points',
  RegNumber = 'RegNumber',
  DateTime = 'DateTime',
  StartDepotID = 'StartDepotID',
  EndDepotID = 'EndDepotID',
  DriverID = 'DriverID',
  DriverName = 'DriverName',
  VehicleProfileID = 'VehicleProfileID',
  VehicleTimelineID = 'VehicleTimelineID',
  VehicleOverloadID = 'VehicleOverloadID',
  SpeedProfileID = 'SpeedProfileID',
  RestrictionID = 'RestrictionID',
  VehicleTariffID = 'VehicleTariffID',
  ForbiddenZones = 'ForbiddenZones',
  TripTemplateID = 'TripTemplateID',
  FriendZoneID = 'FriendZoneID',
  RoadTrain = 'RoadTrain',
  RearCouplingTypeID = 'RearCouplingTypeID',
  FrontCouplingTypeID = 'FrontCouplingTypeID',
  SourceID = 'SourceID',
  TargetID = 'TargetID',
  WorktimeID = 'WorktimeID',
  OrderCargo = 'OrderCargo',
  DepotCode = 'DepotCode',
  DepotType = 'DepotType',
  Depot = 'Depot',
  DepotID = 'DepotID',
  LatLon = 'LatLon',
  LatLonVerified = 'LatLonVerified',
  Phone = 'Phone',
  PassportNumber = 'PassportNumber',
  LicenseNumber = 'LicenseNumber',
  TakeForPlanner = 'TakeForPlanner',
  TakeOnlyOrdersInZones = 'TakeOnlyOrdersInZones',
  IsAutomotive = 'IsAutomotive',
  StrictStartTime = 'StrictStartTime',
  DeniedLate = 'DeniedLate',
  OrderSeries = 'OrderSeries',
  RequireTailLift = 'RequireTailLift',
  RequirePalletTruck = 'RequirePalletTruck',
  RequireLoaders = 'RequireLoaders',
  RequireUnloaders = 'RequireUnloaders',
  Volume = 'Volume',
  IsWeight = 'IsWeight',
  CouplingCode = 'CouplingCode',
  VehicleID = 'VehicleID',
  VehicleProfileCode = 'VehicleProfileCode',
  VehicleOverloadCode = 'VehicleOverloadCode',
  VehicleTimelineCode = 'VehicleTimelineCode',
  ZoneCode = 'ZoneCode',
  Zone = 'Zone',
  LoadDate = 'LoadDate',
  UserID = 'UserID',
  Price = 'Price',
  User = 'User',
  Cargo = 'Cargo',
  StartPrice = 'StartPrice',
  MinPrice = 'MinPrice',
  Provider = 'Provider',
  Condition = 'Condition',
  Comment = 'Comment',
  Accepted = 'Accepted',
  Progress = 'Progress',
  CalculateDuration = 'CalculateDuration',
  Private = 'Private',
  Company = 'Company',
  OfferStartDT = 'OfferStartDT',
  OfferEndDT = 'OfferEndDT',
  ExecutionStartDT = 'ExecutionStartDT',
  ExecutionEndDT = 'ExecutionEndDT',
  Countdown = 'Countdown',
  Status = 'Status',
  Lots = 'Lots',
  Payment = 'Payment',
  StatusAlias = 'StatusAlias',
  CargoOwner = 'CargoOwner',
  Download = 'Download',
  Size = 'Size',
  INN = 'INN',
  IsTrailer = 'IsTrailer',
  RoadTrainCode = 'RoadTrainCode',
  VehicleVehicleCode = 'Vehicle.VehicleCode',
  Trailer1VehicleCode = 'Trailer1.VehicleCode',
  Trailer2VehicleCode = 'Trailer2.VehicleCode',
  Trailer2ID = 'Trailer2ID',
  ForProject = 'ForProject',
  VehicleCode = 'VehicleCode',
  Alias = 'Alias',
  Route = 'Route',
  Trip = 'Trip',
  VehicleType = 'VehicleType',
  AccountID = 'AccountID',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRating = 'MinDriverRating',
  DriverRating = 'DriverRating',
  Found = 'Found',
  RegionalCityRecipient = 'RegionalCityRecipient',
  RegionalCitySender = 'RegionalCitySender',
  TransportCompanyCode = 'TransportCompanyCode',
  Address = 'Address',
  Email = 'Email',
  KPP = 'KPP',
  OKPO = 'OKPO',
  OGRN = 'OGRN',
  OKATO = 'OKATO',
  CheckingAccount = 'CheckingAccount',
  CorrespondentAccount = 'CorrespondentAccount',
  Bank = 'Bank',
  BIK = 'BIK',
  CountIteration = 'CountIteration',
  Planning = 'Planning',
  Vehicles = 'Vehicles',
  ResolvedOrder = 'ResolvedOrder',
  UnresolvedOrder = 'UnresolvedOrder',
  Model = 'Model',
  RouteColor = 'RouteColor',
  StartDepotCode = 'StartDepotCode',
  StartTime = 'StartTime',
  StartDate = 'StartDate',
  ReleaseDate = 'ReleaseDate',
  Owner = 'Owner',
  TransporeonID = 'TransporeonID',
  TransporeonRegNumber = 'TransporeonRegNumber',
  TransporeonTrailerRegNumber1 = 'TransporeonTrailerRegNumber1',
  TransporeonTrailerRegNumber2 = 'TransporeonTrailerRegNumber2',
  TransporeonDescription = 'TransporeonDescription',
  TransporeonChanged = 'TransporeonChanged',
  TransporeonDriverID = 'TransporeonDriverID',
  UseMode = 'UseMode',
  TransporeonCarMark = 'TransporeonCarMark',
  TransporeonMaxMass = 'TransporeonMaxMass',
  Warehouse = 'Warehouse',
  ForbiddenZoneID = 'ForbiddenZoneID',
  PlanConfig = 'PlanConfig',
  TypePlanning = 'TypePlanning',
  PermitZone = 'PermitZone',
  VehicleTypeID = 'VehicleTypeID',
  WeekDays = 'WeekDays',
  DaysOfWork = 'DaysOfWork',
  DaysOfRest = 'DaysOfRest',
  Number = 'Number',
  Notification = 'Notification',
  Action = 'Action',
  Driver = 'Driver',
  Code = 'Code',
  Info = 'Info',
  Client = 'Client',
  EndTime = 'EndTime',
  DeliveryDate = 'DeliveryDate',
  Duration = 'Duration',
  RoadDuration = 'RoadDuration',
  MoveDuration = 'MoveDuration',
  RestDuration = 'RestDuration',
  Distance = 'Distance',
  AverageSpeed = 'AverageSpeed',
  LoadDuration = 'LoadDuration',
  UnloadDuration = 'UnloadDuration',
  Worktime = 'Worktime',
  Pallets = 'Pallets',
  Boxes = 'Boxes',
  Dimensions = 'Dimensions',
  LoadDepot = 'LoadDepot',
  UnloadDepot = 'UnloadDepot',
  WaitingDuration = 'WaitingDuration',
  LatenessDuration = 'LatenessDuration',
  RampID = 'RampID',
  RampName = 'RampName',
  RampStartTime = 'RampStartTime',
  RampEndTime = 'RampEndTime',
  Restriction = 'Restriction',
  MassPercent = 'MassPercent',
  VolumePercent = 'VolumePercent',
  BoxPercent = 'BoxPercent',
  PalletPercent = 'PalletPercent',
  DurationPercent = 'DurationPercent',
  RouteDurationPercent = 'RouteDurationPercent',
  DistancePercent = 'DistancePercent',
  RouteDistancePercent = 'RouteDistancePercent',
  ClientPercent = 'ClientPercent',
  OrderPercent = 'OrderPercent',
  TripTemplate = 'TripTemplate',
  LoadOrder = 'LoadOrder',
  ExpeditorID = 'ExpeditorID',
  FreightPassword = 'FreightPassword',
  Cost = 'Cost',
  CostPerKM = 'CostPerKM',
  CostPerKG = 'CostPerKG',
  FactCost = 'FactCost',
  Efficiency = 'Efficiency',
  FocusOnMap = 'FocusOnMap',
  Penalty = 'Penalty',
  TemplateMode = 'TemplateMode',
  TenderID = 'TenderID',
  UnloadDate = 'UnloadDate',
  TargetContactID = 'TargetContactID',
  SourceContactID = 'SourceContactID',
  TargetZoneID = 'TargetZoneID',
  TargetZoneEnum = 'TargetZoneEnum',
  TransportCompanyID = 'TransportCompanyID',
  TransportCompanyName = 'TransportCompanyName',
  WorktimeUnloadStart = 'Worktime.UnloadStart',
  WorktimeUnloadEnd = 'Worktime.UnloadEnd',
  WorktimeLoadStart = 'Worktime.LoadStart',
  WorktimeLoadEnd = 'Worktime.LoadEnd',
  VehicleProfileMaxMass = 'VehicleProfile.MaxMass',
  VehicleProfileMaxVolume = 'VehicleProfile.MaxVolume',
  VehicleProfileMaxPallets = 'VehicleProfile.MaxPallets',
  VehicleProfileMaxBoxes = 'VehicleProfile.MaxBoxes',
  VehicleProfileMaxCost = 'VehicleProfile.MaxCost',
  VehicleProfileMaxUnitLength = 'VehicleProfile.MaxUnitLength',
  VehicleProfileMaxUnitWidth = 'VehicleProfile.MaxUnitWidth',
  VehicleProfileMaxUnitHeight = 'VehicleProfile.MaxUnitHeight',
  VehicleProfileCarMark = 'VehicleProfile.CarMark',
  VehicleProfileAverageSpeed = 'VehicleProfile.AverageSpeed',
  VehicleProfileFuelConsumption = 'VehicleProfile.FuelConsumption',
  VehicleProfileLoadDuration = 'VehicleProfile.LoadDuration',
  VehicleProfileUnloadDuration = 'VehicleProfile.UnloadDuration',
  VehicleTimelineMaxClients = 'VehicleTimeline.MaxClients',
  VehicleTimelineMaxOrders = 'VehicleTimeline.MaxOrders',
  VehicleTimelineMaxDuration = 'VehicleTimeline.MaxDuration',
  VehicleTimelineDurationOfWork = 'VehicleTimeline.DurationOfWork',
  VehicleTimelineDurationOfRest = 'VehicleTimeline.DurationOfRest',
  VehicleTimelineDurationOfFirstWork = 'VehicleTimeline.DurationOfFirstWork',
  VehicleTimelineDurationOfFirstRest = 'VehicleTimeline.DurationOfFirstRest',
  VehicleTimelineWorkPerDay = 'VehicleTimeline.WorkPerDay',
  VehicleTimelineDriverRestMode = 'VehicleTimeline.DriverRestMode',
  VehicleTimelineMaxDistance = 'VehicleTimeline.MaxDistance',
  VehicleTimelineDistanceOfWork = 'VehicleTimeline.DistanceOfWork',
  VehicleTimelineMaxDurationRoute = 'VehicleTimeline.MaxDurationRoute',
  VehicleTimelineMaxDistanceRoute = 'VehicleTimeline.MaxDistanceRoute',
  VehicleTimelineMaxTrips = 'VehicleTimeline.MaxTrips',
  VehicleTimelineMinStartTime = 'VehicleTimeline.MinStartTime',
  IMEI = 'IMEI',
  TrackerIMEI = 'TrackerIMEI',
  From = 'From',
  Till = 'Till',
  UnloadStartOverload = 'UnloadStartOverload',
  UnloadEndOverload = 'UnloadEndOverload',
  LoadStartOverload = 'LoadStartOverload',
  LoadEndOverload = 'LoadEndOverload',
  MaxDuration = 'MaxDuration',
  DurationOfWork = 'DurationOfWork',
  DurationOfRest = 'DurationOfRest',
  DurationOfFirstWork = 'DurationOfFirstWork',
  DurationOfFirstRest = 'DurationOfFirstRest',
  WorkPerDay = 'WorkPerDay',
  MaxDurationOverload = 'MaxDurationOverload',
  MaxDurationRouteOverload = 'MaxDurationRouteOverload',
  MinStartTimeOverload = 'MinStartTimeOverload',
  LoadStart = 'LoadStart',
  LoadEnd = 'LoadEnd',
  UnloadStart = 'UnloadStart',
  UnloadEnd = 'UnloadEnd',
  LunchStart = 'LunchStart',
  LunchEnd = 'LunchEnd',
  MaxDurationRoute = 'MaxDurationRoute',
  MinStartTime = 'MinStartTime',
  DeliveryDayLimit = 'DeliveryDayLimit',
  ExclusiveOrder = 'ExclusiveOrder',
  PlaceInTrip = 'PlaceInTrip',
  WarehouseAllowKeepTrailer = 'Warehouse.AllowKeepTrailer',
  IconUrl = 'IconUrl',
  ChooseAvailable = 'ChooseAvailable',
  AvailableStatusesID = 'AvailableStatusesID',
  MobileApplication = 'MobileApplication',
  SignColor = 'SignColor',
  Icon = 'Icon',
  AddressDescription = 'AddressDescription',
  UnLoadDate = 'UnloadDate',
  Deliveries = 'Deliveries',
  DriverRestMode = 'DriverRestMode',
  Desequence = 'Desequence',
  NextPointsDepartureDelta = 'NextPointsDepartureDelta',
  SpeedDelta = 'SpeedDelta',
  CondForMin = 'CondForMin',
  Amount = 'Amount',
  TotalFactDriveTime = 'TotalFactDriveTime',
  TotalFactStopsTime = 'TotalFactStopsTime',
  TotalPlanDriveTime = 'TotalPlanDriveTime',
  TripEndFact = 'TripEndFact',
  TripEndPlan = 'TripEndPlan',
  TripBeginFact = 'TripBeginFact',
  TripBeginPlan = 'TripBeginPlan',
  TotalPlanTime = 'TotalPlanTime',
  TotalPlanStopsTime = 'TotalPlanStopsTime',
  TotalFactTime = 'TotalFactTime',
  SourceAddress = 'SourceAddress'
}

export interface ColumnTable<R>
  extends ColumnType<R>,
    Omit<
      IEditableCell<R>,
      | 'record'
      | 'children'
      | 'columnKey'
      | 'handleSave'
      | 'dictionary'
      | 'rowIndex'
      | 'activeCell'
      | 'tableRef'
      | 'dictionaryInfo'
      | 'dictionaryLoad'
    >,
    Omit<INotEditableCell<R>, 'record' | 'children' | 'columnKey' | 'handleSave' | 'dictionary' | 'rowIndex' | 'activeCell'> {
  type: CellType;
  //todo refactor: without string (Enum)
  dataIndex: TableColumnName | string;
  //TODO key not optional + without string (Enum)
  key?: TableColumnName | string;
  //TODO вынести отдельные строки по типу ячейки как countdown
  input?: {
    number?: { max: number; min: number };
    text?: { maxLength?: number };
    email?: {};
    placeholder?: string;
  };
  datePicker?: {};
  needSortByLabelField?: boolean;
  maxLengthInput?: number;
  titleIcon?: string;
  isFixedColumn?: boolean;
  active?: boolean | number;
  //todo refactor: boolean
  sorting?: number | boolean;
  style?: CSSProperties;
  editable?: boolean;
  ellipsis?: boolean;
  allowClear?: boolean;
  textWrap?: boolean;
  sortOrder?: any;
  figure?: string;
  titleTip?: string;
  subTitle?: string;
  droppable?: boolean;
  filtering?: string;
  required?: boolean;
  invisible?: boolean;
  grouping?: boolean;
  dontHide?: boolean;
  titleText?: string;
  titleTooltip?: string | FC;
  titleTooltipPlaceMent?: PlacementType;
  classOverlayTitleTooltip?: string;
  codeField?: any;
  //TODO ReactNode | string and what  as field
  tooltip?: any;
  icon?: ReactNode;
  size?: number;
  showSearch?: boolean;
  update?: boolean;
  lineSeriesProps?: {
    size: number;
    gap: number;
  };
  closeSelectOnScroll?: boolean;
  placeholder?: string;
  showRefButton?: boolean;
  fullWidth?: boolean;
}

