import { restriction2table, RESTRICTION_TABLE_CONFIG } from '@common/models/restriction';
import { CatalogDictionary, Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ILSCatalogTable } from '../../containers/table-container';
import { ILSCatalogRestrictionGroup } from './restriction-group';
import { restrictionTableOptionsSelector } from '@modules/catalog/selectors/options';

const refTable = {
  RestrictionGroupID: {
    title: 'Группы несовместимости специфик',
    component: ILSCatalogRestrictionGroup
  }
};

export const ILSCatalogRestriction: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    const { refData } = useSelector(restrictionTableOptionsSelector);

    return (
      <ILSCatalogTable
        {...props}
        dictionary={Dictionary.Restriction}
        dataDecorator={restriction2table}
        refData={refData}
        refTable={refTable}
      />
    );
  },
  RESTRICTION_TABLE_CONFIG,
  CatalogDictionary.Restriction,
  true
);
