export const tableToData = (
  record: any,
  dataIndex: number | string | undefined,
  value: any,
  cell2data: (ind: number | string | undefined, val: string | number) => string | number | null
) => {
  let result: string | number | any = {};
  if (record && !dataIndex) {
    result = {};
    Object.keys(record).map((i) => {
      result[i] = cell2data(i, record[i]);
      return 1;
    });
  } else {
    result = cell2data(dataIndex, value);
  }
  return result;
};
