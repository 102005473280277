import { ILSModal, ILSTypographyText } from '@common/components';
import { useCallback, useState } from 'react';
import { ViewDoc } from '../components/doc/view-doc';
import { FileAttachment } from '@common/types/dictionaries';
import { isEmpty } from 'lodash';

//Хук для открытия модальных окон с документами
export const useModalDoc = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [file, setFile] = useState<FileAttachment | undefined>(undefined);
  const DocViewModalContent =
    file?.Extension && file.UrlOriginal ? (
      <ILSModal
        visible={openModal}
        onCancel={() => {
          setOpenModal(false);
          setFile(undefined);
        }}
        cancelText="Закрыть"
        okButtonProps={{
          hidden: true
        }}
        destroyOnClose
      >
        <ILSTypographyText>{file?.OriginalName?.toString()}</ILSTypographyText>
        <ViewDoc doc={{ fileName: file?.OriginalName || '', fileType: file.Extension, uri: file?.UrlOriginal }} />
      </ILSModal>
    ) : null;

  const openFile = useCallback(({ file }: { file: FileAttachment }) => {
    if (file?.Extension && file.UrlOriginal) {
      setFile(file);
      setOpenModal(true);
    }
  }, []);
  return { DocViewModalContent, openFile };
};

//колбек для создания dropdown меню с кнопками открытия вложений
export const showAttachment =
  ({ openFile }: { openFile: ({ file }: { file: FileAttachment }) => void }) =>
  ({ files }: { files: Array<FileAttachment> }) => {
    if (isEmpty(files)) return undefined;
    const icon = '/assets/icons/page.svg';
    const items = files.map((file: FileAttachment, index) => ({
      label: file.OriginalName ?? `Вложение №${index + 1}`,
      key: 'Open' + index,
      onClick: () => {
        openFile({ file });
      }
    }));
    return {
      items,
      icon
    };
  };
