import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/children/upload/store';
import { getFormFieldsRoutine } from '@modules/documents/children/upload/actions';
import { DocumentUploadStore } from '@modules/documents/children/upload/types/store';
import { FormField } from '@modules/documents/children/upload/types/form-field';
import { DocumentType } from '@modules/documents/types/document-type';

export const getFormFields = createReducer(initialState, {
  [getFormFieldsRoutine.TRIGGER]: (state: DocumentUploadStore, action: PayloadAction<DocumentType>) => {
    state.documentType = action.payload;
    return state;
  },
  [getFormFieldsRoutine.REQUEST]: (state: DocumentUploadStore) => {
    state.isFetching = true;
    return state;
  },
  [getFormFieldsRoutine.SUCCESS]: (state: DocumentUploadStore, action: PayloadAction<FormField[]>) => {
    state.formModel = action.payload;
  },
  [getFormFieldsRoutine.FULFILL]: (state: DocumentUploadStore) => {
    state.isFetching = false;
    return state;
  }
});
