import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { monitoringDashboardUpdateViewRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import { apiMethodsNames, DashboardSensorNameViewed, DashboardTablesObjMetrics } from '../constants/tables';

type DashboardRequest = {
  activeTab: DashboardTablesObjMetrics | DashboardSensorNameViewed;
  DependentKey: Array<string> | string;
  Value: 0 | 1;
};

// Обновляет метрики для активного таба
function* monitoringDashboardUpdateViewWorker(action: PayloadAction<DashboardRequest>) {
  const { request, success, failure, fulfill } = monitoringDashboardUpdateViewRoutine;
  const { activeTab, DependentKey, Value } = action.payload;

  try {
    yield put(request({ activeTab }));

    yield call(MonitoringAPI.setViewDashboard, { Type: apiMethodsNames[activeTab], DependentKey, Value });

    yield put(success({ activeTab, DependentKey, Value }));
  } catch (error) {
    yield put(failure({ error, activeTab }));
  } finally {
    yield put(fulfill({ activeTab }));
  }
}

export function* monitoringDashboardUpdateViewWatcher() {
  yield takeEvery(monitoringDashboardUpdateViewRoutine.trigger, monitoringDashboardUpdateViewWorker);
}
