import { DriverTimeline } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { splitStringWeekdays } from '@common/utils/helpers/string/split-string-weekdays';

export const driverTimelines2table = (driverTimelines: Array<DriverTimeline>) => {
  return createArrayFromIndexedArray(driverTimelines)?.map((driverTimeline, i) => {
    return {
      ...driverTimeline,
      key: driverTimeline.ID ?? 'row' + i,
      WeekDays: splitStringWeekdays(driverTimeline.WeekDays)
    };
  });
};
