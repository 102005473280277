import { IndexedArray } from '@common/types';

export const sortListBySerial = <T extends { Active: number; Serial: number }>(
  list?: IndexedArray<T>,
  filter?: (item: T) => boolean
): Array<T> => {
  return list
    ? Object.values(list)
        .filter((item) => item.Active && (!filter || filter(item)))
        .sort((a, b) => a.Serial - b.Serial)
    : [];
};
