import { AnalyticsBoardModel, AnalyticsBoardSetModel, CreateBoardParams } from '@common/types/dictionaries/analytics';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import ILSAnalyticsEditableListWindowComponent from '@modules/analytics/common/components/edit-list';
import ILSAnalyticsListComponent from '@modules/analytics/common/components/list';
import { DEFAULT_BOARD_TITLE } from '@modules/analytics/common/constants';

import { FC, useContext, useRef } from 'react';
import { commonRoutine, ModuleActionType } from '../actions';

import { BOARDS_TITLE, COPY_BOARD_TITLE, COPY_EXISTING_BOARD_BTN_TEXT, CREATE_EMPTY_BOARD_TEXT, NEW_BOARD_TITLE } from '../constants';
import { AnalyticsPlanningContext } from '../context';
import { boardSetsSelector, boardsSelector } from '../selectors';

interface Props {}

/**
 * Контейнер списка досок
 */
export const BoardsListContainer: FC<Props> = () => {
  const boards = useAppSelector(boardsSelector);
  const boardSets = useAppSelector(boardSetsSelector);
  const {
    handleSelectBoard,
    editBoardList,
    handleEditBoardList,
    handleCreateBoard,
    handleCopyBoard,
    handleDeleteBoard,
    handleDisableBoard,
    handleUpdateBoard
  } = useContext(AnalyticsPlanningContext) ?? {};
  const boardsRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const sortBoardsList = (list: (AnalyticsBoardModel | AnalyticsBoardSetModel)[], boardID: string | number, idx: number) => {
    // dispatch(sortBoardsRoutine({ data: items, itemID: boardID, positionIndex: idx }));

    dispatch(
      commonRoutine({
        data: [...list],
        method: 'boardUpdate',
        routine: ModuleActionType.Sort_Boards,
        intoReducer: list
      })
    );
  };

  return (
    <>
      <ILSAnalyticsListComponent
        onEdit={handleEditBoardList}
        classNames={['boards-list']}
        selectedId={boards.selectedBoard}
        handleClick={(data) => handleSelectBoard(data as AnalyticsBoardModel)}
        data={boards.data}
        isFetching={boards.isFetching}
        updatingItem={boards?.updatingBoard}
        listRef={boardsRef}
        listTitle={BOARDS_TITLE}
        defaultListItemTitle={DEFAULT_BOARD_TITLE}
        editable={!!boardSets.selectedBoardSet}
      />

      {editBoardList && (
        <ILSAnalyticsEditableListWindowComponent
          listData={boards?.data}
          createItem={(item) => handleCreateBoard(item as CreateBoardParams)}
          listRef={boardsRef}
          handleEditItem={handleEditBoardList}
          editable={editBoardList}
          updatingItem={boards?.updatingBoard}
          adding={boards?.adding}
          defaultItemTitle={DEFAULT_BOARD_TITLE}
          updateItem={(item) => handleUpdateBoard?.(item)}
          copyItem={(id) => handleCopyBoard?.(id)}
          deleteItem={(item) => handleDeleteBoard?.(item)}
          disableItem={(item) => handleDisableBoard?.(item)}
          sortListItems={sortBoardsList}
          newItemAddTitle={NEW_BOARD_TITLE}
          copyItemListTitle={COPY_BOARD_TITLE}
          listTitle={BOARDS_TITLE}
          newItemButtons={{
            copyText: COPY_EXISTING_BOARD_BTN_TEXT,
            newItemText: CREATE_EMPTY_BOARD_TEXT
          }}
        />
      )}
    </>
  );
};
