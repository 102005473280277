import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { DistanceMatrix } from '@modules/planning/children/by-date/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningRequest } from '@modules/planning/types/api';

function* deleteDistanceMatrixWorker(action: PayloadAction<PlanningRequest>) {
  const { request, success, failure, fulfill } = deleteDistanceMatrixRoutine;
  const { projectID, planID } = action.payload;

  try {
    yield put(request());
    const { data } = yield call(DistanceMatrix.delete, { projectID, planID });
    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteDistanceMatrixWatcher() {
  yield takeLatest(deleteDistanceMatrixRoutine.trigger, deleteDistanceMatrixWorker);
}
