import { call, put, takeEvery } from 'redux-saga/effects';
import { getDocumentSetsRoutine } from '../actions';
import DocumentTemplatesAPI from '../api';
import { PayloadAction } from '@reduxjs/toolkit';

function* getDocumentSetsWorker(action: PayloadAction<{ noEmpty?: number }>) {
  const { request, success, fulfill } = getDocumentSetsRoutine;

  try {
    yield put(request());

    const { data } = yield call(DocumentTemplatesAPI.indexDocumentSets, action.payload);

    yield put(success({ data: Object.values(data.data) }));
  } catch (e) {
  } finally {
    yield put(fulfill());
  }
}

export function* getDocumentSetsWatcher() {
  yield takeEvery(getDocumentSetsRoutine.trigger, getDocumentSetsWorker);
}
