import { createSelector } from 'reselect';
import { RoadGraphEditorStore } from '../types';

export const errorSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.error,
  (error) => error
);
export const fetchSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.isFetching,
  (isFetching) => isFetching
);
export const savingSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.isSaving,
  (isSaving) => isSaving
);
export const successSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.success,
  (success) => success
);
export const geoHashSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.geoHash,
  (geoHash) => geoHash
);
export const roadsSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.roads,
  (roads) => roads
);
export const roadDataSelector = createSelector(
  (state: { RoadGraphEditor: RoadGraphEditorStore }) => state.RoadGraphEditor.roadData,
  (roadData) => roadData
);
