import { defineModule } from '@core/index';
import RoadGraphTest from './containers';
import * as roadGraphTestRootSagas from '../route-test/sagas';
import routeTestReducer from '@modules/road-graph/route-test/reducers';
import { ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';

export default defineModule({
  title: ModuleTitle.RouteTest,
  path: ModulePath.RouteTest,
  component: RoadGraphTest,
  reducer: routeTestReducer,
  sagas: getAllModuleSagas(roadGraphTestRootSagas)
});
