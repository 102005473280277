import { CROSS_DOCK_TABLE_CONFIG } from '@common/models/cross-dock/config/table';
import { crossDocks2table, table2crossDocks } from '@common/models/cross-dock/decorators';
import { CatalogDictionary, Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ILSCatalogTable } from '../../containers/table-container';
import { crossDockTableOptionsSelector } from '@modules/catalog/selectors/options/cross-dock';

export const ILSCatalogCrossDock: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    const { refData } = useSelector(crossDockTableOptionsSelector);

    useEffect(() => {
      setMapProps?.({
        field: 'BorderHash',
        type: 'polygon',
        colorField: 'Color'
      });
    }, []);

    return (
      <ILSCatalogTable
        {...props}
        setMapProps={setMapProps}
        dictionary={Dictionary.CrossDock}
        dataDecorator={crossDocks2table}
        handleDecorator={table2crossDocks}
        refData={refData}
      />
    );
  },
  CROSS_DOCK_TABLE_CONFIG,
  CatalogDictionary.CrossDock,
  true
);
