import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import MonitoringDetails from './containers';
import monitoringDetails from './reducers';
import * as MonitoringEventDetailsSagas from './sagas';
import { persistTransformMonitoringDetailsStore } from '@modules/monitoring/children/event-details/utils/persist-transform-monitoring-details-store';

/*
 * Детализация событий - это подмодуль нашего продукта, который отвечает
 * за вкладку "Детализация событий" модуля Мониторинг.
 * Содержит в таблицы рейсов, табы с таблицами и графиками детализации событий и карту.
 * Основной файл - "входная точка" в подмодуль находится здесь
 * - src\modules\monitoring\children\event-details\index.ts
 */

export default defineModule({
  title: ModuleTitle.MonitoringDetails,
  path: ModulePath.MonitoringDetails,
  component: MonitoringDetails,
  reducer: monitoringDetails,
  sagas: getAllModuleSagas(MonitoringEventDetailsSagas),
  persist: persistTransformMonitoringDetailsStore
});
