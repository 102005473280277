import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PaymentApi } from '@common/feature/payment/api';
import { TimeStamp } from '@common/types';
import { compareAsString } from '@common/utils';
import { getCurrentBalanceRoutine } from '@common/feature/payment/actions';
import { currentBalanceLastUpdateSelector } from '@common/feature/payment/selectors/index';

function* getCurrentBalanceWorker() {
  const { request, success, failure, fulfill } = getCurrentBalanceRoutine;
  try {
    const lastUpdate: TimeStamp | null = yield select(currentBalanceLastUpdateSelector);
    yield put(request());
    const {
      data: { data }
    } = yield call(PaymentApi.getCurrentBalance);

    const { LastUpdate: newLastUpdate } = data;

    if (!lastUpdate || !compareAsString(lastUpdate, newLastUpdate)) {
      yield put(success({ data }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getCurrentBalanceWatcher() {
  yield takeLatest(getCurrentBalanceRoutine.trigger, getCurrentBalanceWorker);
}

