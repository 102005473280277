import { EMPTY_STRING } from '@common/constants';
import { FormType } from '@modules/orders-and-trips/types/general';

export const getOrderAndTripFormTitle = (showForm: FormType | null) => {
  switch (showForm) {
    case FormType.Trip:
      return 'Создание рейса';
    case FormType.CreateOrder:
      return 'Создание заявки';
    case FormType.UpdateOrder:
      return 'Редактирование заявки';
    default:
      return EMPTY_STRING;
  }
};

