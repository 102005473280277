import '../styles.less';
import { FC, useEffect, useState } from 'react';
import { ILSSwitch } from '@components/index';
import { DataValidateParamsComponent } from '../components/params';
import { DataValidateErrorsComponent } from '../components/errors';
import { TActiveValidationTable, TChangeActiveValidationTable, TErrorValidationTable } from '@modules/data-validate/types/types';
import { ILSValidateProps } from '@modules/data-validate/types/containers';
import { isEmpty } from 'lodash';

const ILSValidate: FC<ILSValidateProps> = ({ data, validateData, isPlanning, dataSchema, useShowButton = true, ...props }) => {
  const [showAll, setShowAll] = useState<boolean>(true);
  const [activeCategory, setActiveCategory] = useState<TErrorValidationTable | null>(null);
  const [activePanel, setActivePanel] = useState<string | number | (string | number)[] | undefined>();
  const [activeTable, setActiveTable] = useState<TActiveValidationTable>({
    table: null,
    kind: null,
    errorItem: null
  });

  const changeActiveTable: TChangeActiveValidationTable = (table, kind, error) => {
    setActiveTable({ table, kind, errorItem: error });
  };

  const changeActiveCategory = (dictionary: TErrorValidationTable) => {
    setActivePanel(undefined);
    setActiveCategory(dictionary);
  };

  useEffect(() => {
    if (activeCategory && showAll && activeCategory !== activeTable.table) {
      setActiveTable({ table: activeCategory, kind: null, errorItem: null });
    }
  }, [showAll, activeCategory]);

  const onChange = (val: boolean) => setShowAll(!val);
  const { table } = activeTable;
  const dataSource = (table && data?.[table]) || [];

  return (
    <>
      {!isEmpty(validateData) && (
        <>
          {useShowButton && (
            <ILSSwitch
              className="show-all"
              checked={!showAll}
              onChange={onChange}
              checkedChildren="Показать все"
              unCheckedChildren="Только ошибки"
            />
          )}
          <div className="validate-container">
            <DataValidateParamsComponent
              isPlanning={Boolean(isPlanning)}
              errorsByDictionary={validateData}
              activeCategory={activeCategory}
              changeActiveCategory={changeActiveCategory}
              changeActiveTable={changeActiveTable}
              showAll={showAll}
            />
            {activeCategory && (
              <DataValidateErrorsComponent
                {...props}
                errorsByDictionary={validateData}
                activePanel={activePanel}
                setActivePanel={setActivePanel}
                activeCategory={activeCategory}
                activeTable={activeTable}
                changeActiveTable={changeActiveTable}
                showAll={showAll}
                isPlanning={isPlanning}
                setActiveCategory={setActiveCategory}
                dataSource={dataSource}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ILSValidate;
