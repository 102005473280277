import { useSelector } from 'react-redux';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { PlanStatus } from '@common/types';
import { stringifyUrl } from 'query-string';
import { TripRecord } from '@modules/planning/children/by-date/types/record';
import { useVehicleMonitoringLink } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/trip/use-veehicle-monitoring-link';
import { useAddVehicleEndDepotToTrip } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/trip/use-add-vehicle-end-depot-to-trip';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { useDriverActions } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/trip/use-driver-actions';
import { isEmpty } from 'lodash';

export const getContextMenuForTrip = ({
  handleDelete,
  setTriggerMapFocus,
  record
}: {
  record: TripRecord;

  setTriggerMapFocus: SetTriggerMapFocus;

  handleDelete(record: TripRecord): void;
}) => {
  const projectID = useSelector(projectIDSelector);
  const onExecution = record.Status >= PlanStatus.OnExecution;

  const { ButtonForCopyLink } = useVehicleMonitoringLink(record);
  const { addVehicleEndDepotToTrip } = useAddVehicleEndDepotToTrip(record);
  const { onChangeDriver, driversSubMenu } = useDriverActions(record);
  const hasDriversForChange = !isEmpty(driversSubMenu);

  const link = stringifyUrl({
    url: '/pages/track',
    query: {
      dictionary: record.dictionary,
      project: projectID,
      plan: record.PlanID,
      trip: record.ID,
      vehicle: record.VehicleID
    }
  });

  return [
    [
      {
        text: 'Перейти к объекту на карте',
        handler: (record: TripRecord) => setTriggerMapFocus([record])
      },
      {
        text: 'Открыть на отдельной странице',
        link
      }
    ],
    [
      hasDriversForChange && {
        text: 'Заменить водителя',
        handler: onChangeDriver,
        submenu: driversSubMenu
      },
      record.VehicleEndDepotID && {
        text: 'Добавить точку возврата машины',
        handler: addVehicleEndDepotToTrip
      },
      onExecution && {
        text: ButtonForCopyLink,
        title: 'Ссылка для просмотра автомобиля'
      }
    ],
    [
      !onExecution && {
        text: 'Удалить рейс',
        handler: handleDelete
      }
    ]
  ];
};
