import { ColumnTable, TableRecord } from '@common/types';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { ICoreState } from '@core/types/store';
import { getDataSourceByGrouping } from '@modules/catalog/helpers/get-data-source-by-grouping';

export const catalogueTableDataSourceSelector = createSelector(
  (
    _state?: ICoreState,
    dataSource?: any,
    _colsForGrouping?: ColumnTable<TableRecord>['dataIndex'][],
    _columns?: ColumnTable<TableRecord>[]
  ) => dataSource,
  (
    _state?: ICoreState,
    _dataSource?: any,
    colsForGrouping?: ColumnTable<TableRecord>['dataIndex'][],
    _columns?: ColumnTable<TableRecord>[]
  ) => colsForGrouping,
  (
    _state?: ICoreState,
    _dataSource?: any,
    _colsForGrouping?: ColumnTable<TableRecord>['dataIndex'][],
    columns?: ColumnTable<TableRecord>[]
  ) => columns,
  (dataSource, colsForGrouping, columns) => {
    if (!isEmpty(colsForGrouping)) {
      return getDataSourceByGrouping(dataSource, colsForGrouping as string[], columns as ColumnTable<TableRecord>[]);
    }

    return dataSource;
  }
);
