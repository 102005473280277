import { ILSUserNotify } from '@common/components/feedback/ils-user-notify';
import { Notify } from '@common/types';
import { convertToMB } from '@common/utils';

export const handleCheckBeforeCrop = (fileMaxSize?: number) => (file: File) => {
  return handleCheckSize(file, fileMaxSize);
};

export const handleCheckSize = (file: File, fileMaxSize?: number) => {
  if (file && fileMaxSize && file.size > fileMaxSize) {
    ILSUserNotify(Notify.Error, `Максимальный размер файла ${convertToMB(fileMaxSize)}МБ`, 3);
    return false;
  }
  if (!file.size) {
    ILSUserNotify(Notify.Error, 'Нельзя загружать пустые файлы', 3);
    return false;
  }
  return true;
};

