import { Adding, Dictionary } from '@common/types';
import { NEW_WORKTIME } from '@common/models/worktime';
import { NEW_CLIENT, NEW_GARAGE } from '@common/models/depot/constants';
import { NEW_DRIVER } from '@common/models/driver';
import { NEW_RESTRICTION, restriction2table } from '@common/models/restriction';
import { NEW_SPEED_PROFILE, speedProfiles2table } from '@common/models/speed-profile';
import { NEW_TRIP_TEMPLATE, tripTemplates2table } from '@common/models/trip-template';
import { NEW_VEHICLE } from '@common/models/vehicle/constants';
import { NEW_MODEL } from '@components/data-display/table/constants';
import { NEW_ORDER } from '@common/models/order/constants';
import { NEW_CARGO } from '@common/models/cargo/constants';
import { NEW_DEPOT_OVERLOAD } from '@common/models/depot-overload/config/table';
import { NEW_VEHICLE_PROFILE } from '@common/models/vehicle-profile/config/table';
import { NEW_CONTACT } from '@common/models/contact/config/table';
import { NEW_TRANSPORT_COMPANY } from '@common/models/transport-company/constants';
import { NEW_ROAD_TRAIN } from '@common/models/roadtrain/config';
import { NEW_CROSS_DOCK } from '@common/models/cross-dock/config/table';
import { plannerDataVehicleToTable, tableData2Vehicles } from '@common/models/vehicle/decorators';
import { worktime2table } from '@common/models/worktime/decorators';
import { depotOverloads2table } from '@common/models/depot-overload/decorators';
import { vehicleOverload2table } from '@common/models/vehicle-overload/decorators';
import { vehicleTimelines2table } from '@common/models/vehicle-timeline/decorators';
import { vehicleProfile2table } from '@common/models/vehicle-profile/decorators';
import { data2table } from '@components/data-display/table/utils/decorators/data-to-table';
import { rampType2table } from '@common/models/ramp-type';
import { cargo2table } from '@common/models/cargo/decorators';
import { driverTimelines2table } from '@common/models/driver-timeline/decorators';
import { depot2Table, driver2Table, order2Table, roadTrainData2Table } from '@modules/planning/children/data/utils/decorators';
import { contact2table } from '@common/models/contact/decorators';
import { transportCompanies2table } from '@common/models/transport-company/decorators';
import { couplingTypes2table } from '@common/models/coupling-type/decorators';
import { crossDocks2table } from '@common/models/cross-dock/decorators';
import { NEW_VEHICLE_TIMELINE } from '@common/models/vehicle-timeline/constants';
import { SchemeDictionary } from '@modules/planning/children/scheme-editor/types';
import { zone2table } from '@common/models/zone/decorators';
import { NEW_ZONE } from '@common/models/zone/constants';

export { COLUMNS_WITN_MIN_MAX_PROPERTY } from '@common/models/all/columns';

export const TABLE_CONFIG_ADDING: Record<SchemeDictionary, Adding> = {
  [Dictionary.Worktime]: NEW_WORKTIME,
  [Dictionary.DepotOverload]: NEW_DEPOT_OVERLOAD,
  [Dictionary.VehicleOverload]: NEW_MODEL,
  [Dictionary.VehicleTimeline]: NEW_VEHICLE_TIMELINE,
  [Dictionary.VehicleProfile]: NEW_VEHICLE_PROFILE,
  [Dictionary.VehicleTariff]: NEW_MODEL,
  [Dictionary.SpeedProfile]: NEW_SPEED_PROFILE,
  [Dictionary.RestrictionGroup]: NEW_MODEL,
  [Dictionary.Restriction]: NEW_RESTRICTION,
  [Dictionary.Zone]: NEW_ZONE,
  [Dictionary.TripTemplate]: NEW_TRIP_TEMPLATE,
  [Dictionary.RampType]: NEW_MODEL,
  [Dictionary.Cargo]: NEW_CARGO,
  [Dictionary.DriverTimeline]: NEW_WORKTIME,
  [Dictionary.Driver]: NEW_DRIVER,
  [Dictionary.Contact]: NEW_CONTACT,
  [Dictionary.TransportCompany]: NEW_TRANSPORT_COMPANY,
  [Dictionary.CouplingType]: NEW_MODEL,
  [Dictionary.VehicleBox]: NEW_MODEL,
  [Dictionary.Depot]: NEW_CLIENT,
  [Dictionary.Garage]: NEW_GARAGE,
  [Dictionary.Client]: NEW_CLIENT,
  [Dictionary.DepotGatheringSchedule]: NEW_MODEL,
  [Dictionary.Vehicle]: NEW_VEHICLE,
  [Dictionary.RoadTrain]: NEW_ROAD_TRAIN,
  [Dictionary.Productor]: NEW_MODEL,
  [Dictionary.ProductorVehicle]: NEW_MODEL,
  [Dictionary.Order]: NEW_ORDER,
  [Dictionary.CrossDock]: NEW_CROSS_DOCK,
  [Dictionary.Trip]: NEW_MODEL,
  [Dictionary.Waypoint]: NEW_MODEL,
  [Dictionary.OrderAction]: NEW_MODEL,
  [Dictionary.Plan]: NEW_MODEL,
  [Dictionary.SimplePlan]: NEW_MODEL
};

export const TABLE_DATA_TO_TABLE_DECORATORS: Record<SchemeDictionary, unknown> = {
  [Dictionary.Vehicle]: plannerDataVehicleToTable,
  [Dictionary.Worktime]: worktime2table,
  [Dictionary.DepotOverload]: depotOverloads2table,
  [Dictionary.VehicleOverload]: vehicleOverload2table,
  [Dictionary.VehicleTimeline]: vehicleTimelines2table,
  [Dictionary.VehicleProfile]: vehicleProfile2table,
  [Dictionary.VehicleTariff]: data2table,
  [Dictionary.SpeedProfile]: speedProfiles2table,
  [Dictionary.RestrictionGroup]: data2table,
  [Dictionary.Restriction]: restriction2table,
  [Dictionary.Zone]: zone2table,
  [Dictionary.TripTemplate]: tripTemplates2table,
  [Dictionary.RampType]: rampType2table,
  [Dictionary.Cargo]: cargo2table,
  [Dictionary.DriverTimeline]: driverTimelines2table,
  [Dictionary.Driver]: driver2Table,
  [Dictionary.Contact]: contact2table,
  [Dictionary.TransportCompany]: transportCompanies2table,
  [Dictionary.CouplingType]: couplingTypes2table,
  [Dictionary.VehicleBox]: data2table,
  [Dictionary.Depot]: depot2Table,
  [Dictionary.Garage]: depot2Table,
  [Dictionary.Client]: depot2Table,
  [Dictionary.DepotGatheringSchedule]: data2table,
  [Dictionary.RoadTrain]: roadTrainData2Table,
  [Dictionary.Productor]: data2table,
  [Dictionary.ProductorVehicle]: data2table,
  [Dictionary.Order]: order2Table,
  [Dictionary.CrossDock]: crossDocks2table,
  [Dictionary.Trip]: data2table,
  [Dictionary.Waypoint]: data2table,
  [Dictionary.OrderAction]: data2table,
  [Dictionary.Plan]: data2table,
  [Dictionary.SimplePlan]: data2table
};
export const TABLE_TABLE_TO_DATA_DECORATORS = {
  [Dictionary.Vehicle]: tableData2Vehicles
};

//TODO не готово к импорту, кроме авто
