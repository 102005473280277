import { Provider } from '@common/types';
import { DefaultPlannerConfigsResponse } from '@modules/planning/types/api';
import { PlansTableRecord } from '@modules/planning/children/by-date/types';

const getTypePlanning = (recordProvider: Provider, configs: DefaultPlannerConfigsResponse | undefined) => {
  return configs?.[recordProvider]?.operation?.range;
};
export const getTypePlanningOptions = (record: PlansTableRecord, configs: DefaultPlannerConfigsResponse | undefined) => {
  const provider = record?.PlanConfig?.[0] || Provider.ILS;
  return getTypePlanning(provider, configs) ?? [];
};
