//@ts-ignore
import storage from 'redux-persist-indexeddb-storage';
import { STORAGE_ROOT_DB, STORAGE_ROOT_KEY } from './constants/store';
import { PersistConfigModified } from './persist/PersistConfigModified';
import { ICoreState } from './types/store';
import { StateKeyName } from '@core/constants/core';
import { runMigration } from './persist/run-migration';

// Config can be mutated from outside
export const persistConfig: PersistConfigModified<ICoreState> = {
  key: STORAGE_ROOT_KEY,
  storage: storage(STORAGE_ROOT_DB),
  multiKeys: true,
  // initial keys are static
  keys: [StateKeyName.Fixed, StateKeyName.Auth],
  migrate: (state) => {
    return Promise.resolve(runMigration(state));
  }
};
