export type PassWordChange = {
  oldPassword: string;
  newPassword: string;
  confirm: string;
};

export type ChangeSettings = {
  category: string;
  section: string;
  parameter: string;
  value: number | string | boolean;
};

export enum SettingsFieldType {
  Select = 'select',
  Checkbox = 'checkbox',
  Number = 'number'
}

