import { ModulePath } from '@common/types';

export const authNavLinks = [
  {
    modulePath: ModulePath.LogisticServices,
    title: 'Все сервисы'
  },
  {
    modulePath: ModulePath.Login,
    title: 'Вход'
  },
  {
    modulePath: ModulePath.Register,
    title: 'Регистрация'
  }
];

