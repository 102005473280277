import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderLotWinnerChooseRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint } from '@common/types';

function* tenderLotWinnerChooseWorker(
  action: PayloadAction<{
    offerID: TenderOffer['ID'];
  }>
) {
  const { request, fulfill } = tenderLotWinnerChooseRoutine;
  const { offerID } = action.payload;

  try {
    yield put(request());
    yield call(TenderOwn.chooseWinnerLot, { id: offerID });

    yield put(tenderChangeRoutine.trigger({ TenderOffer: [{ ID: offerID }], operation: DictionariesEndpoint.Accept }));
  } catch (error) {
    yield put(tenderChangeRoutine.failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderLotWinnerChooseWatcher() {
  yield takeEvery(tenderLotWinnerChooseRoutine.trigger, tenderLotWinnerChooseWorker);
}
