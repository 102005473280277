import { FC } from 'react';
import { FULL_BLOCK_MAIN_TABS } from '@modules/orders-and-trips/constants/display-blocks';
import { ILSTabPane, ILSTabs } from '@common/components';
import { useFullBlock } from '@modules/orders-and-trips/hooks/use-full-block';

export const FullBlock: FC = () => {
  const { activeTab, onTabChange } = useFullBlock();
  return (
    <ILSTabs destroyInactiveTabPane type="card" activeKey={activeTab} onChange={onTabChange}>
      {FULL_BLOCK_MAIN_TABS.map(({ Component, key, title }) => {
        return (
          <ILSTabPane forceRender={false} tab={title} key={key}>
            <Component />
          </ILSTabPane>
        );
      })}
    </ILSTabs>
  );
};
