import { AccountAttachmentSubject } from '@common/types';
import { AccountFacsimileComponent } from '@modules/account/components/attachments/facsimile-component';
import { AccountAttachmentCategory, AccountFileAttachment } from '@modules/account/types/attachments';
import { RcFile } from 'antd/lib/upload';

type RenderAccountAttachmentComponent = {
  accountAttachmentCategory: AccountAttachmentCategory;
  handleCheckBeforeUpload(file: RcFile, subject: AccountAttachmentSubject): void;
  handleDeleteDocument(id: number): void;
  documents: AccountFileAttachment[] | null;
};

export const renderAccountAttachmentComponent = ({
  accountAttachmentCategory,
  handleDeleteDocument,
  handleCheckBeforeUpload,
  documents
}: RenderAccountAttachmentComponent) => {
  switch (accountAttachmentCategory) {
    case AccountAttachmentCategory.Facsimile:
      return (
        <AccountFacsimileComponent
          documents={documents}
          handleDeleteDocument={handleDeleteDocument}
          handleCheckBeforeUpload={handleCheckBeforeUpload}
        />
      );
    default:
      return <></>;
  }
};
