import { createSelector } from 'reselect';
import { ICoreState } from '@core/types/store';
import { TableConfig, TableName } from '@common/types';
import { mergeTableConfigs } from '@core/utils/table/merge-table-configs';
import { EMPTY_OBJECT_DATA } from '@common/constants';
import { DEFAULT_RECORDS_FROM_PAGE } from '@components/data-display/table/constants';
import { ITableStoreConfig } from '@core/types/table-custom';
import { createTableConfig } from '@components/data-display/table/utils/helpers';
import { getConfigWithoutGrouping } from '@components/data-display/table/utils/helpers/config/get-config-without-grouping';

export const tableCustomSelector = createSelector(
  (state: ICoreState, tableName?: TableName | string) =>
    ((tableName && state.fixed?.TablesConfig?.[tableName]) as ITableStoreConfig) ?? (EMPTY_OBJECT_DATA as ITableStoreConfig),
  (TableConfig: ITableStoreConfig) => TableConfig ?? (EMPTY_OBJECT_DATA as ITableStoreConfig)
);

export const tableCustomConfigSelector = createSelector(
  tableCustomSelector,
  (_state: ICoreState, tableName: TableName | string, _tableConfig: TableConfig, _groupable: boolean) => tableName,
  (_state: ICoreState, _tableName: TableName | string, tableConfig: TableConfig, _groupable: boolean) => tableConfig,
  (_state: ICoreState, _tableName: TableName | string, tableConfig: TableConfig, groupable: boolean) => groupable ?? false,
  (storedConfig: ITableStoreConfig, _tableName: TableName | string | undefined, tableConfig: TableConfig, groupable: boolean) => {
    const mergeTableConfig = mergeTableConfigs(tableConfig, storedConfig);
    const configWithWidth = createTableConfig(mergeTableConfig);
    const configGroupingSettings = groupable ? configWithWidth : getConfigWithoutGrouping(configWithWidth);
    return { config: configGroupingSettings };
  }
);
//unit?
export const tableCustomConfigForILSTableSelector = createSelector(
  (state: ICoreState, _tableConfig: TableConfig, tableName?: TableName | string) => tableCustomSelector(state, tableName),
  (_state: ICoreState, tableConfig: TableConfig, _tableName?: TableName | string) => tableConfig,
  (_state: ICoreState, _tableConfig: TableConfig, tableName?: TableName | string) => tableName,
  (storedConfig: ITableStoreConfig, tableConfig: TableConfig, tableName?: TableName | string) => {
    let config = storedConfig;
    if (tableConfig) {
      config = mergeTableConfigs(tableConfig, storedConfig);
    }
    const pageSize = config?.pageSize ?? DEFAULT_RECORDS_FROM_PAGE;
    if (!tableName) {
      return { newConfig: tableConfig || EMPTY_OBJECT_DATA, pageSize };
    }
    return { newConfig: config, pageSize };
  }
);

export const tableConfigSelector = (config: TableConfig, tableName?: TableName | string) =>
  createSelector(
    (state: ICoreState) => tableCustomConfigForILSTableSelector(state, config, tableName),
    (config) => config
  );
