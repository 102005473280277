import { ILSModal, ILSSpin } from '@common/components';
import { IndexedArray } from '@common/types';
import { AbstractBoard, AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { useAppSelector } from '@core/hooks';
import { savingBoardWidgetsSelector } from '@modules/analytics/planning/children/planning-analytics/selectors';
import { FC, useEffect, useState } from 'react';
import ILSWidgetBoard from './widget-board';
import { hasChangedWidgets } from '@modules/analytics/common/utils/has-сhanged-widgets';
import { useVisibleWidgets } from '@modules/analytics/common/hooks/use-visible-widgets';

interface ILSWidgetWindowProps {
  board: AbstractBoard;
  visible: boolean;
  widgets?: IndexedArray<AnalyticsWidget> | null;
  onClose: () => void;
  onSaveWidgets?: (widgets: IndexedArray<AnalyticsWidget>) => void;
  onCancelWidgetsUpdate?: () => void;
  deleteBoardWidget?: (widget: AnalyticsWidget) => void;
  dropWidgetOnBoard?: (widget: AnalyticsWidget) => void;
}
//Модальное окно доски в режиме редактиврания
const ILSWidgetWindow: FC<ILSWidgetWindowProps> = ({
  widgets = {},
  board,
  visible,
  onClose,
  onSaveWidgets = () => {},
  deleteBoardWidget = () => {},
  dropWidgetOnBoard = () => {},
  onCancelWidgetsUpdate = () => {}
}) => {
  const [changedWidgets, setChangedWidgets] = useState<IndexedArray<AnalyticsWidget> | null>(null);
  const savingBoardWidgets = useAppSelector(savingBoardWidgetsSelector); //TODO: селектор аналитики перевозок не должен быть в общшем компоненте

  const visibleChangedWidgets = useVisibleWidgets(changedWidgets);
  const visibleWidgets = useVisibleWidgets(widgets);

  const onCancel = () => {
    setChangedWidgets(null);
    onCancelWidgetsUpdate();
    onClose();
  };

  const onOk = () => {
    if (hasChangedWidgets(widgets, changedWidgets)) {
      onSaveWidgets(changedWidgets || {});
    } else {
      onClose();
    }
    setChangedWidgets(null);
  };

  const handleChange = (widget: AnalyticsWidget) => {
    const saveWidgets = { ...widgets, ...changedWidgets };
    if (!widget.ID) return;
    saveWidgets[widget.ID] = widget;
    setChangedWidgets(saveWidgets);
  };

  const onDeleteBoardWidget = (widget: AnalyticsWidget) => {
    setChangedWidgets({ ...changedWidgets, [widget.ID]: { ...widget, isDeleted: true } });
    deleteBoardWidget(widget);
  };

  useEffect(() => {
    if (!savingBoardWidgets) {
      onClose();
    }
  }, [savingBoardWidgets]);

  return (
    <ILSModal
      visible={visible}
      title={board.Name}
      onOk={onOk}
      okText={'ОК'}
      cancelButtonProps={{
        disabled: savingBoardWidgets
      }}
      okButtonProps={{
        disabled: savingBoardWidgets
      }}
      onCancel={onCancel}
      wrapClassName="analytics-window-wrapper"
      className="analytics-window"
      width={'70vw'}
      destroyOnClose={true}
      maskClosable={false}
    >
      <ILSSpin spinning={savingBoardWidgets || false}>
        <ILSWidgetBoard
          widgets={visibleWidgets}
          deleteBoardWidget={onDeleteBoardWidget}
          handleChange={handleChange}
          dropWidgetOnBoard={dropWidgetOnBoard}
          editMode={true}
          changedWidgets={visibleChangedWidgets}
        />
      </ILSSpin>
    </ILSModal>
  );
};
export default ILSWidgetWindow;
