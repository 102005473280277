import { apiInstance } from '@core/api/instance';
import { IData, ITemplate } from '@modules/planning/children/params/types/store';
import { ICreateResponse, IDeleteResponse, IImportResponse, IUpdateParams } from '@modules/planning/children/params/types/api';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { AxiosResponse } from 'axios';
import { getAPIURL } from '@common/api/helpers';

export type ConfigID = string;

interface IPlanningParamsAPI {
  copyConfig({ dictionary, ConfigID, Context }: { dictionary: Dictionary; ConfigID: any; Context: string }): Promise<AxiosResponse>;

  update(params: { values: IUpdateParams }): Promise<AxiosResponse>;

  index(): Promise<AxiosResponse<IData[]>>;

  getTemplate(): Promise<AxiosResponse<ITemplate>>;

  create(): Promise<AxiosResponse<ICreateResponse>>;

  delete({ ConfigID }: { ConfigID: ConfigID[] }): Promise<AxiosResponse<IDeleteResponse>>;

  import({ config }: { config: {} }): Promise<AxiosResponse<IImportResponse>>;
}

export const PlanningParams: IPlanningParamsAPI = {
  index: () => apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.Index)),
  getTemplate: () => apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.GetTemplate)),
  update: ({ values }) => apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.Update), { data: values }),
  create: () => apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.Create), {}),
  delete: ({ ConfigID }) =>
    apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.Delete), {
      ConfigID
    }),
  import: ({ config }) => apiInstance().post(getAPIURL(Dictionary.PlannerParams, DictionariesEndpoint.Import), { config }),
  copyConfig: ({ dictionary, ConfigID, Context }) =>
    apiInstance().post(getAPIURL(dictionary, DictionariesEndpoint.Copy), { ConfigID, Context })
};
