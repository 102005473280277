import { Keys } from '@common/types';
import { useEffect } from 'react';
/**
 * Хук проверяет количество заявок в таблице нераспределенных заявок, 
 * и если заявок нет, то необходимо закрыть таблицу с автомобилями.
 */

export const useCloseVehiclesTableAfterOrdersDelete = (allOrdersIDs?: Keys, handleShowVehicleTable?: (val: boolean) => void) => {
  useEffect(() => {
    if (!allOrdersIDs?.length) {
      handleShowVehicleTable?.(false);
    }
  }, [allOrdersIDs]);
};

