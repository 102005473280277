import { FC } from 'react';
import { ILSAlert, ILSButton, ILSForm, ILSFormItem } from '@components/index';
import { PROJECT_LOGISTIC, PROJECT_LOGISTIC_FORM_FIELDS } from '@modules/orders-and-trips/constants/project-logistics';
import { useProjectLogisticsForm } from '@modules/orders-and-trips/hooks/use-project-logistics-form/use-project-logistics-form';
import { getProjectLogisticFormRules } from '@modules/orders-and-trips/helpers/project-logistics/get-project-logistic-form-rules';
import { ButtonContent } from '@common/constants';
import { Notify } from '@common/types';

const PROJECT_LOGISTICS_MESSAGE = `Данный раздел предназначен для решения Ваших нетиповых логистических задач. Например, Вам нужны специализированные
погрузочно-разгрузочные работы, переместить негабаритные грузы, мультимодальные перевозки или любые другие сложные услуги. Мы можем
разработать комплексные логистические решения, которые учитывают бюджет, груз, назначение груза и условия его доставки. Пожалуйста,
  заполните анкету и наши специалисты немедленно помогут Вам найти оптимальное решение.`;

export const ProjectLogistics: FC = () => {
  const { sendProjectLogisticForm } = useProjectLogisticsForm();

  return (
    <>
      <ILSAlert message={PROJECT_LOGISTICS_MESSAGE} type={Notify.Info} />
      <ILSForm
        name={PROJECT_LOGISTIC}
        className={`fake-pre-cost__form fake-pre-cost__form_${PROJECT_LOGISTIC}`}
        onFinish={sendProjectLogisticForm}
      >
        {PROJECT_LOGISTIC_FORM_FIELDS.map(({ Component, label, valuePropName = 'value', info }) => {
          const isCheckbox = valuePropName === 'checked';
          const rules = getProjectLogisticFormRules({ isCheckbox });

          if (!label) return null;

          return (
            <ILSFormItem
              rules={rules}
              className={`fake-pre-cost__form-item fake-pre-cost__form-item_${PROJECT_LOGISTIC}`}
              tooltip={info}
              valuePropName={valuePropName}
              key={label}
              name={label}
              label={!isCheckbox && label}
            >
              <Component children={isCheckbox ? label : undefined} />
            </ILSFormItem>
          );
        })}
      </ILSForm>
      <ILSButton children={ButtonContent.Send} form={PROJECT_LOGISTIC} type={'primary'} htmlType={'submit'} />
    </>
  );
};
