import { createModuleAction } from '@common/utils/general/create-module-action';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

const MODULE_NAME = 'MONITORING_DELIVERY';

export enum ActionType {
  Get_Chart_Data = 'DELIVERY_CONTROL'
}

export const createDetailsRoutine = (name: ActionType) => createRoutine(createModuleAction(MODULE_NAME, name));

export const getChartData = createDetailsRoutine(ActionType.Get_Chart_Data);

export const monitoringDeliveryRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [];
