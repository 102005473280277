import { planningStartRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PlanningAndValidationRequestConfig } from '@modules/planning/children/by-date/types/validate';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { Keys, PlanID, PlanTotal, Project, Provider } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { DefaultPlannerConfigs, TPlanningConfig } from '@modules/planning/children/by-date/types';
import { defaultPlannerConfigsSelector, planningParamsConfigSelector } from '@modules/planning/children/by-date/selectors/config';

function* planningStartWorker(action: PayloadAction<{ planID?: PlanID }>) {
  const { request, success, failure, fulfill } = planningStartRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    const activePlanID: PlanID = yield select(activePlanIDSelector);
    const projectID: Project['ID'] = yield select(activeProjectIdSelector);
    //TODO to selector
    const { PlanConfig, TypePlanning }: PlanTotal = yield select(
      (state) => state.PlanningByDate.project.Plan[planID || activePlanID].PlanTotal
    );
    const defaultPlannerConfigs: DefaultPlannerConfigs = yield select(defaultPlannerConfigsSelector);
    const [provider, planConfigID] = Object.values(PlanConfig);
    const params: Record<Provider, TPlanningConfig['params']> = yield select(planningParamsConfigSelector);

    const requestPayload: PlanningAndValidationRequestConfig = {
      planID: planID ?? activePlanID,
      planConfigID,
      operation: TypePlanning,
      validationType: TypePlanning,
      providerName: provider as Provider,
      params: params?.[provider],
      projectID
    };

    const { selectedTripsIDs }: Record<'selectedTripsIDs', Keys> = yield select(selectedRouteTableRowsSelector);

    const {
      data: {
        data: { plans }
      }
    } = yield call(PlanningAPI.startPlanning, { ...requestPayload, trips: selectedTripsIDs });

    yield put(success({ plans }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningStartWatcher() {
  yield takeLatest(planningStartRoutine.trigger, planningStartWorker);
}

