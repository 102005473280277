import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { StatusConfig, Tender, TenderModule, TenderStatus } from '@modules/tender-platform/types';
import {
  tenderChangeRoutine,
  tenderDocumentsRoutine,
  tenderGetAccountUsersRoutine,
  tenderGetVehicleNDriverRoutine,
  tenderGetVehicleTypesRoutine,
  tenderReadRoutine
} from '@modules/tender-platform/actions';
import { tenderReadRestrictionRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { currentTenderStatusConfigSelector } from '@modules/tender-platform/selectors';
import { DictionariesEndpoint, Module } from '@common/types';

export function* tenderReadWorker(
  action: PayloadAction<{
    id: Tender['ID'];
    tenderPlatformChildrenModule?: TenderModule;
  }>
) {
  const { request, success, failure, fulfill } = tenderReadRoutine;
  const { id, tenderPlatformChildrenModule = Module.TenderOwn } = action.payload;

  try {
    yield put(request());
    yield all([
      put(tenderGetAccountUsersRoutine.trigger()),
      put(tenderGetVehicleTypesRoutine.trigger()),
      put(tenderReadRestrictionRoutine.trigger())
    ]);

    if (id) {
      const {
        data: { data }
      } = yield call(TenderAPI.read, { id });
      const [tender] = createArrayFromIndexedArray<Tender>(data);

      if (tender) {
        yield put(tenderChangeRoutine.trigger({ operation: DictionariesEndpoint.Reset }));
      }

      yield put(success({ tender }));
      if (tender.ID) {
        yield all([put(tenderDocumentsRoutine.trigger({ tenderID: tender.ID }))]);
        const tenderStatus: StatusConfig = yield select(currentTenderStatusConfigSelector);
        if (tenderPlatformChildrenModule === Module.TenderSearch && tenderStatus.Alias === TenderStatus.CollectingOffers) {
          yield put(tenderGetVehicleNDriverRoutine.trigger({ id: tender.ID }));
        }
      }
    } else {
      yield put(success({ data: null }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderReadWatcher() {
  yield takeLatest(tenderReadRoutine.trigger, tenderReadWorker);
}
