import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { Dictionary } from '@common/types';

export const monitoringDateRoutine = createRoutine('MONITORING/DATE'); // Рутина для работы с интервалом дат
export const monitoringMetricsRoutine = createRoutine('MONITORING/METRICS'); // Рутина получающая данные метрик
export const loadPlanTrackRoutine = createRoutine('MONITORING/PLAN_TRACK'); // Рутина загрузки планового трэка
export const monitoringAttachmentsRoutine = createRoutine('MONITORING/ATTACHMENTS'); // Загрузка данных из attachments
export const monitoringPlanFactRoutine = createRoutine('MONITORING/PLAN_FACT'); // Загрузка план / факт данных
export const monitoringTrackRoutine = createRoutine('MONITORING/TRACK'); // Рутина получающая данные о треке
export const monitoringVehicleDetailsRoutine = createRoutine('MONITORING/VEHICLE_DETAILS'); // Рутина для загрузки данных об автомобиле
export const monitoringExportExelRoutine = createRoutine('MONITORING/EXPORT_EXEL'); // Рутина для экспорта данных в эксель файл

export const monitoringGetVehicleTrackersRoutine = createRoutine('MONITORING/GET_VEHICLE_TRACKERS'); // Получение трекеров с привязкой к автомобилю
export const monitoringCreateVehicleTrackerRoutine = createRoutine('MONITORING/CREATE_VEHICLE_TRACKER'); // Создание привязки трекера к автомобилю
export const monitoringCreateVehicleTrackerLocalRoutine = createRoutine('MONITORING/CREATE_VEHICLE_TRACKER_LOCAL'); // Создание привязки трекера к автомобилю локально, чтобы добавлялась строчка в таблицу
export const monitoringDeleteVehicleTrackerLocalRoutine = createRoutine('MONITORING/DELETE_VEHICLE_TRACKER_LOCAL'); // Удаление привязки трекера к автомобилю локально, чтобы удалялась строчка из таблицы

export const monitoringGetTrackersRoutine = createRoutine('MONITORING/GET_TRACKERS'); // Получение всех трекеров
export const monitoringCreateTrackerRoutine = createRoutine('MONITORING/CREATE_TRACKER'); // Создание трекера
export const monitoringCreateTrackerLocalRoutine = createRoutine('MONITORING/CREATE_TRACKER_LOCAL'); // Создание трекера локально, чтобы добавлялась строчка в таблицу
export const monitoringUpdateTrackerRoutine = createRoutine('MONITORING/UPDATE_TRACKER'); // Обновление данных трекера
export const monitoringDeleteTrackerRoutine = createRoutine('MONITORING/DELETE_TRACKER'); // Удаление трекера
export const monitoringUpdateVehicleTrackersRoutine = createRoutine('MONITORING/UPDATE_VEHICLE_TRACKER'); // Обновление данных связи трекера и ТС
export const monitoringDeleteVehicleTrackersRoutine = createRoutine('MONITORING/DELETE_VEHICLE_TRACKER'); // Удаление связи трекера и ТС
export const monitoringForwarderPointStatusesRoutine = createRoutine(`MONITORING/${Dictionary.ForwarderPointStatuses}`);
export const monitoringCatalogReadRoutine = createRoutine(`MONITORING/CATALOG_READ`);
export const monitoringSetWaypointStatusRoutine = createRoutine(`MONITORING/SET_WAYPOINT_STATUS`); // Устанавливает статус waypoint (мобильное приложение)
export const monitoringSetTripStatusRoutine = createRoutine(`MONITORING/SET_TRIP_STATUS`); // Устанавливает статус trip (мобильное приложение)

export const monitoringRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  monitoringAttachmentsRoutine,
  monitoringPlanFactRoutine,
  monitoringVehicleDetailsRoutine,
  monitoringExportExelRoutine,
  monitoringGetVehicleTrackersRoutine,
  monitoringCreateVehicleTrackerRoutine,
  monitoringGetTrackersRoutine,
  monitoringCreateTrackerRoutine,
  monitoringUpdateTrackerRoutine,
  monitoringDeleteTrackerRoutine,
  loadPlanTrackRoutine,
  monitoringCreateVehicleTrackerLocalRoutine,
  monitoringCreateTrackerLocalRoutine,
  monitoringDeleteVehicleTrackerLocalRoutine,
  monitoringUpdateVehicleTrackersRoutine,
  monitoringDeleteVehicleTrackersRoutine,
  monitoringSetTripStatusRoutine
];
