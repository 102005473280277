import { FC, FocusEvent } from 'react';
import { IILSSearchAutoCompleteProps, ILSSearchAutoComplete } from '@components/data-entry/autocomplete/search';
import { Field, Placeholder } from '@common/constants';
import { useDispatch } from 'react-redux';
import { addressSuggestionRoutine } from '@common/feature/suggestion-autocomplete/address/actions';
import { onCloseAddressesCompleter } from '@common/feature/suggestion-autocomplete/address/utils/helpers';
import { ILSAddressSuggestionAutocomplete } from '@common/feature/suggestion-autocomplete/address/contsiners/address-suggestion-autocomplete';

interface IProps extends Omit<IILSSearchAutoCompleteProps, 'handleSearch' | 'field'> {
  field?: Field;
  hasDefaultOptions?: boolean;
}

const ADRESS_COMPLITERS = [Field.Address, Field.City];

export const ILSSuggestionAutoComplete: FC<IProps> = ({ disabled, field = Field.Address, onBlur, ...props }) => {
  const dispatch = useDispatch();

  const handleSearch = (query: string, field: Field) => {
    switch (field) {
      case Field.Address:
        dispatch(addressSuggestionRoutine({ query }));
        break;
      case Field.City:
        dispatch(addressSuggestionRoutine({ query, onlyCity: true }));
        break;
    }
    return;
  };

  const clearOptions = () => {
    onCloseAddressesCompleter(false, dispatch);
  };

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    onBlur?.(e);
    clearOptions();
  };

  const sharedProps = {
    field,
    placeholder: Placeholder[field] ?? 'Введите значение, для поиска адреса',
    dropdownMatchSelectWidth: false,
    disabled,
    showArrow: !disabled,
    showSearch: !disabled,
    handleSearch,
    ...props,
    onBlur: handleBlur,
    onDropdownVisibleChange: clearOptions
  };

  const componentToRender = () => {
    switch (true) {
      case ADRESS_COMPLITERS.includes(field):
        return <ILSAddressSuggestionAutocomplete {...sharedProps} />;
      default:
        return <ILSSearchAutoComplete notFoundContent={'Нет опций для данного поля'} options={[]} {...sharedProps} />;
    }
  };

  return componentToRender();
};
