import { PermittedUploadFileType } from '@common/constants/general/path';
import { StimulsoftTemplateData } from '@common/types/general/stimulsoft';
import { generateStimulsoftReportFromJson } from '@common/utils/stimulsoft/generate-stimulsoft-report-from-json';

declare var Stimulsoft: any;

interface IProps {
  template: StimulsoftTemplateData;
  fileName: string;
  onUpload: (file: File) => void;
}

export const uploadPdfFromStimulsoft = ({ template, fileName, onUpload }: IProps) => {
  const report = generateStimulsoftReportFromJson(template);

  report.renderAsync(() => {
    report.exportDocumentAsync((pdfData: any) => {
      const blob = new Blob([new Uint8Array(pdfData)], { type: PermittedUploadFileType.pdf });
      const file = new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type });
      return onUpload(file);
    }, Stimulsoft.Report.StiExportFormat.Pdf);
  });
};

