import { Dictionary, IndexedArray, Keys, MessageResponse, OrderToProjectRequest, Project, ShowDeleted } from '@common/types';
import { ICatalogExportParams } from '@common/types/components/table/common';
import { APIResponse } from '@common/types/general/api/api';
import { Key } from 'react';

export enum DictionariesEndpoint {
  addOrderToServiceFromFakeTender = 'addOrderToServiceFromFakeTender',
  sendProjectLogisticData = 'sendProjectLogisticData',
  FakePreCost = 'fakePreCost',
  changeExpeditorInTrip = 'changeExpeditorInTrip',
  Accept = 'accept',
  Add = 'add',
  AppointResponsible = 'appointResponsible',
  Check = 'Check',
  Clone = 'clone',
  ContactSuggestions = 'contactSuggestions',
  CopyOrdersToProject = 'copyOrdersToProject',
  Create = 'create',
  Delete = 'delete',
  DeleteRows = 'deleteRows',
  Download = 'download',
  Export = 'export',
  GetAccountUsers = 'getAccountUsers',
  GetAllDocuments = 'getAllDocuments',
  GetLotTemplateEnum = 'getLotTemplateEnum',
  GetProjects = 'getProjects',
  GetUploadedData = 'GetUploadedData',
  GetUploadTemplate = 'GetUploadTemplate',
  GetLotDistance = 'getLotDistance',
  Index = 'index',
  IsInUse = 'isInUse',
  Import = 'import',
  Read = 'read',
  Restore = 'restore',
  Reset = 'reset',
  tenderTemplateData = 'tenderTemplateData',
  Search = 'search',
  SetStatus = 'setStatus',
  Suggestions = 'suggestions',
  Update = 'update',
  MultipleUpdate = 'multipleUpdate',
  Upload = 'upload',
  View = 'view',
  GetPlanningConfigs = 'getPlanningConfigs',
  getVehiclesForTender = 'getVehiclesForTender',
  getDriversForTender = 'getDriversForTender',
  setDriverRating = 'setDriverRating',
  setCarrierRating = 'setCarrierRating',
  withMyParticipation = 'withMyParticipation',
  GetProject = 'getProject',
  GetPlan = 'getPlan',
  GetPlanEfficiency = 'getPlanEfficiency',
  Validate = 'validate',
  StartPlanning = 'startPlanning',
  checkPlanning = 'checkPlanning',
  donePlanning = 'donePlanning',
  cancelPlanning = 'cancelPlanning',
  enoughPlanning = 'enoughPlanning',
  createPlan = 'createPlan',
  copyPlan = 'copyPlan',
  clearPlan = 'clearPlan',
  setTripStatus = 'setTripStatus',
  setForExecution = 'setForExecution',
  unsetForExecution = 'unsetForExecution',
  addEndDepotToTrip = 'addEndDepotToTrip',
  moveWaypointToTrip = 'moveWaypointToTrip',
  moveWaypointInTrip = 'moveWaypointInTrip',
  createTrip = 'createTrip',
  changeVehicle = 'changeVehicle',
  exchangeVehicles = 'exchangeVehicles',
  unionTrips = 'unionTrips',
  addOrderForVehicle = 'addOrderForVehicle',
  getMonthBudget = 'getMonthBudget',
  saveMonthBudget = 'saveMonthBudget',
  getSharedLink = 'getSharedLink',
  sendObserverLinks = 'sendObserverLinks',
  getTrip = 'getTrip',
  getTrack = 'getTrack',
  getRoute = 'getRoute',
  getWaypoint = 'getWaypoint',
  changeDriverInRoute = 'changeDriverInRoute',
  createFromRoute = 'createFromRoute',
  updateFromRoute = 'updateFromRoute',
  deleteFromRoute = 'deleteFromRoute',
  changeDriverInTrip = 'changeDriverInTrip',
  calculateMatrixStart = 'calculateMatrixStart',
  calculateMatrixDelete = 'calculateMatrixDelete',
  calculateMatrixStatus = 'calculateMatrixStatus',
  matrixCleanup = 'matrixCleanup',
  Copy = 'copy',
  GetTemplate = 'getTemplate',
  getNotificationParams = 'getNotificationParams',
  setNotificationParams = 'setNotificationParams',
  uploadScheme = 'uploadScheme',
  curScheme = 'curScheme',
  maxScheme = 'maxScheme',
  uploadTxt = 'uploadTxt',
  uploadSchemeIni = 'uploadSchemeIni',
  getCurrentSchemeIni = 'curSchemeIni',
  IconURLIndex = 'IconURLIndex',
  RegisterDriverUser = 'registerDriverUser',
  ResetDriverUserPassword = 'resetDriverUserPassword',
  DeleteDriverUser = 'deleteDriverUser',
  getRoleEnum = 'getRoleEnum',
  getTemplateFull = 'getTemplateFull',
  bindTimeZone = 'bindTimeZone',
  getFormulaConstants = 'getFormulaConstants',
  changeExpeditorInRoute = 'changeExpeditorInRoute',
  SetWaypointStatus = 'setWaypointStatus',
  SetTripStatus = 'setTripStatus',
  Orders = 'orders',
  GetTripsCount = 'getTripsCount',
  Short = 'short',
  Enum = 'enum',
  AddUnallocatedOrder = 'addUnallocatedOrder',
  GetTripDataForOrders = 'getTripDataForOrders',
  GetTripsData = 'getTripsData',
  GetAvailableIntegrations = 'getAvailableIntegrations',
  GetPreCost = 'getPreCost',
  AddOrderToService = 'addOrderToService',
  GetTripTimeline = 'getTripTimeline',
  RemoveOrderFromService = 'removeOrderFromService',
  DeleteTrip = 'deleteTrip',
  UpdateOrder = 'updateOrder',
  BalanceInvoiceTemplate = 'balanceInvoiceTemplate',
  BalanceInvoiceData = 'balanceInvoiceData',
  GetTrack = 'getTrack',
  GetBalance = 'getBalance',
  getBalanceHistory = 'getBalanceHistory',
  OrganizationTypes = 'getOrganizationTypes',
  GetContractData = 'getContractData',
  GetFakeTripContract = 'getTripContractFromFakeTender',
  UpdateUnallocatedOrder = 'updateUnallocatedOrder',
  GetTotalCount = 'getTotalCount',
  GetExportTemplate = 'getExportTemplate',
  ImportOrders = 'importOrders'
}

export type CatalogTotalCount = Partial<Record<Dictionary, number>>;

export type DictionaryIDs = {
  ids?: Keys;
};

export type DictionaryCreateRequest = {
  values: any;
};

export type DictionaryReadRequest = Partial<DictionaryIDs> & {
  showDeleted?: ShowDeleted;
  projectID?: number | false;
  getFullData?: boolean;
  activeProjectID?: Project['ID'] | null;
};

export type DictionaryUpdateRequest = DictionaryIDs & DictionaryCreateRequest & { activeProjectID?: Project['ID'] | null };
export type DictionaryMultipleUpdateRequest = { data: Array<Record<string, any>> };

export type DictionaryDeleteRequest = DictionaryIDs;
export type DictionaryCloneRequest = DictionaryIDs;
export type DictionaryRestoreRequest = DictionaryIDs;
export type DictionaryIsInUseRequest = DictionaryIDs;

export type DictionaryParameter = {
  dictionary: Dictionary;
};
export type DictionariesEndpointParameter = { operation?: DictionariesEndpoint };

export type DictionaryRequest<R> = R & DictionaryParameter;
export type DictionaryOperation<R> = DictionaryRequest<R> & DictionariesEndpointParameter;

export type DictionaryOperationRequest = DictionaryRequest<
  DictionaryCreateRequest &
    DictionaryReadRequest &
    DictionaryUpdateRequest &
    DictionaryDeleteRequest &
    DictionaryCloneRequest &
    DictionaryRestoreRequest &
    DictionaryIsInUseRequest &
    DictionariesEndpointParameter
>;
export type IsInUseResponse = { data: Record<Key, IndexedArray<Keys>> };

export interface IILSDictionaryAPI {
  operation(params: DictionaryOperationRequest): APIResponse<any>;

  read(params: DictionaryRequest<DictionaryReadRequest>): APIResponse<any>;

  update(params: DictionaryRequest<DictionaryUpdateRequest>): APIResponse<any>;

  create(params: DictionaryRequest<DictionaryCreateRequest>): APIResponse<any>;

  multipleUpdate(params: DictionaryRequest<DictionaryMultipleUpdateRequest>): APIResponse<any>;

  delete(params: DictionaryRequest<DictionaryDeleteRequest>): APIResponse<any>;

  isInUse(params: DictionaryRequest<DictionaryIsInUseRequest>): APIResponse<IsInUseResponse>;

  restore(params: DictionaryRequest<DictionaryRestoreRequest>): APIResponse<any>;

  clone(params: DictionaryRequest<DictionaryCloneRequest>): APIResponse<any>;

  getCoordinates(params: { address: string }): APIResponse<any>;

  copyOrdersToProject(params: OrderToProjectRequest): MessageResponse;

  export(params: ICatalogExportParams): APIResponse<any>;

  bindTimeZone(params: {
    dictionary?: Dictionary;
    depots: Array<{
      id: string | number;
      latLon: {
        lat: number;
        lon: number;
      };
    }>;
  }): MessageResponse;
}
