import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { tenderOfferCreateRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { tenderChangeRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { TenderOfferAction } from '@modules/tender-platform/children/tender-search/types/offer';
import { DictionariesEndpoint } from '@common/types';

function* tenderOfferCreateWorker(action: TenderOfferAction) {
  const { request, failure, fulfill } = tenderOfferCreateRoutine;
  const { tenderID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderSearch.create, action.payload);
    yield put(tenderChangeRoutine.trigger({ TenderOffer: Array.isArray(data) ? data : [data], operation: DictionariesEndpoint.Create }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(tenderReadRoutine.trigger({ id: tenderID }));
    yield put(fulfill());
  }
}

export function* tenderOfferCreateWatcher() {
  yield takeEvery(tenderOfferCreateRoutine.trigger, tenderOfferCreateWorker);
}
