import { DocumentType } from '@modules/documents/types/document-type';
import { MenuProps } from 'antd';

export const createButtonItems: MenuProps['items'] = [
  { label: 'Сделка (комплект документов)', key: `template-${DocumentType.Deal}` },
  {
    label: 'Договор',
    key: DocumentType.Contract,
    children: [
      {
        label: 'Создать из шаблона',
        key: `template-${DocumentType.Contract}`
      },
      {
        label: 'Прикрепить готовый документ',
        key: `upload-${DocumentType.Contract}`
      }
    ]
  },
  {
    label: 'Приложение',
    key: DocumentType.ContractAnnex,
    children: [
      {
        label: 'Создать из шаблона',
        key: `template-${DocumentType.ContractAnnex}`
      },
      {
        label: 'Прикрепить готовый документ',
        key: `upload-${DocumentType.ContractAnnex}`
      }
    ]
  },
  {
    label: 'ТТН',
    key: DocumentType.LadingBill,
    children: [
      {
        label: 'Создать из шаблона',
        key: `template-${DocumentType.LadingBill}`
      },
      {
        label: 'Прикрепить готовый документ',
        key: `upload-${DocumentType.LadingBill}`
      }
    ]
  },
  {
    label: 'Маршрутный лист',
    key: DocumentType.RouteList,
    children: [
      {
        label: 'Создать из шаблона',
        key: `template-${DocumentType.RouteList}`
      },
      {
        label: 'Прикрепить готовый документ',
        key: `upload-${DocumentType.RouteList}`
      }
    ]
  },
  { label: 'Загрузить файл', key: DocumentType.Custom }
];
