import { ILSCheckbox } from '@common/components';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { DASH, JoinChar } from '@common/constants';
import { Order } from '@common/types';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { isEmpty } from 'lodash';
import { FC } from 'react';

interface IProps {
  order: DecoratedOrders[number];
}

export const OrderCardCargoDetails: FC<IProps> = ({ order }) => {
  return (
    <div className="order-card-cargo-details cargo">
      <div className="cargo-mass">
        <ILSSVG config={SVG_CONFIG} icon={SVG.MASS} />:<span>{order.Mass} кг</span>
      </div>
      <div className="cargo-volume">
        <ILSSVG config={SVG_CONFIG} icon={SVG.VOLUME} />:<span>{order.Volume} м3</span>
      </div>
      <div className="cargo-amount">
        <ILSSVG config={SVG_CONFIG} icon={SVG.CHECK_AMOUNT} />:<span>{order.Amount ?? DASH} шт.</span>
      </div>
      <div className="cargo-restriction">
        <ILSSVG config={SVG_CONFIG} icon={SVG.RESTRICTION} />:
        <span>
          {Object.values(!isEmpty(order.RestrictionEnum) ? order.RestrictionEnum : { DASH })?.map((restriction) => {
            return restriction + JoinChar.Space;
          })}
        </span>
      </div>
    </div>
  );
};
