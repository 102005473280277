import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { initialState } from '@modules/settings/store';
import { mobileApplicationDictionaryOperationRoutine } from '@modules/settings/actions';
import { MobileApplicationDictionaryPayload } from '@common/types/dictionaries/mobile-application';
import { DictionariesEndpoint } from '@common/types';
import { ForwarderPointStatusesIconsURLResponse } from '@modules/settings/types/forwarder-point-statuses';
import { isEmpty } from 'lodash';

export const mobileApplicationDictionaryOperationReducer = createReducer(initialState, {
  [mobileApplicationDictionaryOperationRoutine.REQUEST]: onRequest,
  [mobileApplicationDictionaryOperationRoutine.SUCCESS]: (state, action: MobileApplicationDictionaryPayload) => {
    const { mobileApplicationDictionary, ids, operation, data } = action.payload;
    switch (operation) {
      case DictionariesEndpoint.Index:
        state.mobileApplication[mobileApplicationDictionary] = data;
        break;
      case DictionariesEndpoint.Update:
        if (!isEmpty(data)) {
          ids?.forEach((id) => {
            state.mobileApplication[mobileApplicationDictionary][id] = data[id];
          });
        }
        break;
      case DictionariesEndpoint.Create:
        state.mobileApplication[mobileApplicationDictionary] = {
          ...state.mobileApplication[mobileApplicationDictionary],
          ...data
        };
        break;
      case DictionariesEndpoint.Delete:
        ids?.forEach((id) => {
          delete state.mobileApplication[mobileApplicationDictionary][id];
        });
        break;
      case DictionariesEndpoint.IconURLIndex:
        state.mobileApplication.iconsURL = data as ForwarderPointStatusesIconsURLResponse;
        break;
      default:
        break;
    }
    return state;
  },
  [mobileApplicationDictionaryOperationRoutine.FAILURE]: onFailure,
  [mobileApplicationDictionaryOperationRoutine.FULFILL]: onFulfill
});
