import { Tag } from 'antd';
import { MouseEvent } from 'react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

export const flagTagRender = ({ label, value, closable, onClose }: CustomTagProps) => {
  const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag color={value} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
};
