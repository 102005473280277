import { FC } from 'react';
import { ILSAlert } from '@common/components';
import { Notify } from '@common/types';

import { useCheckTariffContent } from './hooks/use-check-tariff-content';
import { MIN_TARIFF_VALUE } from './constants/general';

interface IProps {
  rawFormula: string;
  viewFormula: string;
  formulaParams: Record<string, { name: string }>;
}

export const CheckTariffContent: FC<IProps> = ({ rawFormula, viewFormula, formulaParams }) => {
  const { calculationResult, params, onSetParams } = useCheckTariffContent({ formulaParams, rawFormula });

  if (!calculationResult.check) return <ILSAlert type={Notify.Error} message={calculationResult.message} />;

  return (
    <div className="mt-2 mr-2">
      <div className="bg-white p-3 tariff-field bordered rounded">
        <b>{viewFormula}</b>
        {Object.keys(params).map((paramkey) => {
          return (
            <div className="d-flex flex-start-center ">
              <div className="p-3 w-50">{formulaParams[paramkey].name}</div>
              <div className="w-50">
                <input type="number" min={MIN_TARIFF_VALUE} value={params[paramkey]} onChange={onSetParams(paramkey)} />
              </div>
            </div>
          );
        })}
        <ILSAlert type={Notify.Success} message={`Результат вычисления: ${calculationResult.message}`} />
      </div>
    </div>
  );
};

