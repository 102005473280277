import { createReducer } from '@reduxjs/toolkit';
import { PlanStatus, TableColumnName } from '@common/types';
import { routeSaveRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { RouteSavePayloadAction } from '@modules/planning/children/by-date/sagas/route/save';
import { compareAsString } from '@common/utils';

export const routeSaveReducer = createReducer(initialState, {
  [routeSaveRoutine.SUCCESS]: (state, action: RouteSavePayloadAction) => {
    const { key, value, vehicleID } = action.payload;
    const routes = state.currentPlan.RouteTotal;
    const route = routes[vehicleID];

    route[key] = value;
    route.changed = true;

    switch (key) {
      case TableColumnName.Status:
        const routesArray = Object.values(routes);
        if (routesArray.length > 1) {
          const isDifferentStatuses = routesArray.some(({ Status }) => {
            return !compareAsString(Status, value as PlanStatus);
          });
          if (state.project?.Plan) {
            const planID = state.activePlanID;
            if (planID) {
              if (isDifferentStatuses) {
                state.project.Plan[planID][key] = PlanStatus.TripWithDifferentStatuses;
                state.project.Plan[planID].PlanTotal[key] = PlanStatus.TripWithDifferentStatuses;
              } else {
                state.project.Plan[planID][key] = value as PlanStatus;
                state.project.Plan[planID].PlanTotal[key] = value as PlanStatus;
              }
            }
          }
        }

        const trips = route.TripTotal;
        for (const tripID in trips) {
          const trip = trips[tripID];
          trip[key] = value;
          trip.changed = true;
          const waypoints = trip.WaypointTotal;
          for (const waypointID in waypoints) {
            const waypoint = waypoints[waypointID];
            waypoint[key] = value;
            // TODO backed should return Status, not PlanStatus
            waypoint.PlanStatus = value;
            const orderAction = waypoint.OrderActionTotal;
            for (const orderActionID in orderAction) {
              orderAction[orderActionID][key] = value;
            }
          }
        }
        break;
      default:
        break;
    }

    return state;
  }
});
