import { isEmpty, isNil } from 'lodash';
import { doubleToTime, timestampToDateTimeStringWithTZ } from '@common/utils';
import { IMapTrack, MarkerFigure } from '@common/types/components/map/map';
import { getMarkerFigure } from '@modules/planning/children/by-date/decorators/map/get-marker-figure';
import { DASH } from '@common/constants';
import { LatLngTuple } from 'leaflet';
import { Dictionary, MarkerType, PlanStatus, Trip, Vehicle } from '@common/types';

export const getPointData = ({
  polyline,
  TripID,
  VehicleID,
  color
}: {
  polyline: Pick<IMapTrack, 'data' | 'waypoint' | 'number' | 'vehicle' | 'tripStatus' | 'clickData'>;
  TripID: Trip['ID'] | undefined | null;
  color: IMapTrack['color'];
  VehicleID: Vehicle['ID'] | undefined;
}) => {
  if (isNil(polyline.data)) return undefined;
  const { depot, isHome, order } = polyline.data;

  if (isNil(depot)) return undefined;
  if (isEmpty(depot.LatLon)) return undefined;

  if (isNil(order)) return undefined;

  const waypoint = polyline.waypoint;
  if (!waypoint) return undefined;
  const WaypointID = waypoint.WaypointID;

  const depotWorktime = waypoint?.DepotWorktime;
  const LoadEnd = depotWorktime && doubleToTime(depotWorktime?.LoadEnd ?? 0);
  const LoadStart = depotWorktime && doubleToTime(depotWorktime?.LoadStart ?? 0);
  const depotUnloadStart = depotWorktime?.UnloadStart;
  const depotUnloadEnd = depotWorktime?.UnloadEnd;

  const UnloadStart: number = waypoint?.Worktime?.[0] || depotUnloadStart || 0;
  const UnloadEnd: number = waypoint?.Worktime?.[1] || depotUnloadEnd || 0;
  const isDefinedWorktime = !isNil(waypoint?.Worktime?.[0] ?? depotUnloadStart) && !isNil(waypoint?.Worktime?.[1] ?? depotUnloadEnd);

  const worktimeWindow = isDefinedWorktime && UnloadEnd - UnloadStart;

  const figure: MarkerFigure = getMarkerFigure({ worktimeWindow, isDefinedWorktime, UnloadStart, UnloadEnd });

  const pointData = {
    PointText: 'Точка: ' + polyline.number + ' рейса ' + TripID,
    RouteText: 'Маршрут: ' + (polyline?.vehicle?.Name ?? polyline?.vehicle?.Model + ' ' + polyline?.vehicle?.RegNumber),
    ClientText: 'Клиент: ' + depot?.Name,
    Address: depot?.AddressDescription,
    DistanceFromPrevPoint: (polyline.waypoint?.Distance ?? 0).toFixed(2),
    EndDate: !waypoint?.EndTime ? DASH : timestampToDateTimeStringWithTZ(waypoint.EndTime, waypoint.UTCOffset),
    UnloadStart: (UnloadStart || depotUnloadStart) && doubleToTime(UnloadStart || depotUnloadStart),
    UnloadEnd: (UnloadEnd || depotUnloadEnd) && doubleToTime(UnloadEnd || depotUnloadEnd),
    LoadEnd,
    LoadStart,
    ArrivalTime: !waypoint?.StartTime ? DASH : timestampToDateTimeStringWithTZ(waypoint.StartTime, waypoint.UTCOffset),
    TimeZone: polyline?.data?.depot?.TimeZone ?? 'не определен',
    Mass: polyline.waypoint?.Mass?.toFixed(2),
    Pallets: polyline.waypoint?.Pallets?.toFixed(2)
  };

  return {
    ID: WaypointID,
    coords: Object.values(depot.LatLon) as LatLngTuple,
    type: MarkerType.Waypoint,
    figure,
    color,
    number: !isHome && polyline.number ? polyline.number : undefined,
    homeNumber: polyline.number,
    isHome,
    pointData,
    data: {
      ID: WaypointID,
      waypointID: WaypointID,
      TripID: TripID,
      tripStatus: polyline.tripStatus?.toString() as PlanStatus,
      sourceOrders: order.filter((orderItem) => orderItem.SourceID === depot!.ID).map((orderItem) => orderItem.ID) || [],
      targetOrders: order.filter((orderItem) => orderItem.TargetID === depot!.ID).map((orderItem) => orderItem.ID) || [],
      type: MarkerType.Waypoint
    },
    clickData: {
      PlanID: (polyline.clickData && polyline.clickData.PlanID) || undefined,
      WaypointID: WaypointID,
      TripID: TripID,
      VehicleID: VehicleID,
      dictionary: Dictionary.Waypoint
    }
  };
};
