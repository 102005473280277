import { FC } from 'react';
import { FormType } from '@modules/orders-and-trips/types/general';
import { EMPTY_STRING } from '@common/constants';
import { FormSubmitButtons } from '@modules/orders-and-trips/constants/display-blocks';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';
import { ButtonTypes } from '@common/types/components';

interface IProps {
  showForm: FormType | null;
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
  lockedButtons: Partial<Record<ButtonTypes, boolean>>;
}

export const OrdersNTripsubmitBlock: FC<IProps> = ({ submitButtonProps, showForm, lockedButtons }) => {
  if (!showForm) return null;
  return (
    <div className="order-action-form-submit">
      {FormSubmitButtons[showForm]().map((defaultProps) => (
        <ActionButton
          {...defaultProps}
          disabled={lockedButtons[defaultProps.buttonType]}
          click={submitButtonProps[defaultProps.click ?? EMPTY_STRING]}
        />
      ))}
    </div>
  );
};

