import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { GET_WIDGET_DATA_ERROR } from '../../constants';
//компонент, отображающий ошибку виджета
export const ILSWidgetError: FC<{ hideWidget: boolean }> = ({ hideWidget }) => {
  return (
    <div>
      {!hideWidget && (
        <>
          <ReactSVG src={'/assets/icons/error.svg'} />
          {GET_WIDGET_DATA_ERROR}
        </>
      )}
    </div>
  );
};
