import { useMemo } from 'react';
import { MapLinesWithSettings } from '@modules/planning/children/by-date/decorators/map';
import { PlanningSettingsKeys } from '@modules/settings/types';
import { useCachedGeohash } from '@core/components/map/hooks/use-geocash';
import { planningMapSettingsSelector } from '@modules/settings/selectors/planning';
import { useSelector } from 'react-redux';
import { planningFocusNExpandRowIdsRoutesTableSelector } from '@modules/planning/children/by-date/selectors/planning';
import { TableName } from '@common/types';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { planningGetTrackData } from '@modules/planning/children/by-date/selectors/plan-points';
import { MapActivePlan } from '@modules/planning/children/by-date/types/map-container';

export const usePolyline = ({ mapData, trackDataChanges }: { mapData: MapActivePlan; trackDataChanges: any }) => {
  const trackData = useSelector(planningGetTrackData);
  const { focusRow } = useSelector(planningFocusNExpandRowIdsRoutesTableSelector(TableName.RoutesTable));
  const allActiveItems = useSelector(selectedRouteTableRowsSelector);
  const cachedGeohash = useCachedGeohash();
  const planningMapSettings = useSelector(planningMapSettingsSelector);

  const polyline = useMemo(
    () =>
      mapData && allActiveItems
        ? MapLinesWithSettings(
            mapData,
            {
              [PlanningSettingsKeys.UseRedis]: planningMapSettings.useRedis,
              [PlanningSettingsKeys.HideLinesOnTrackAbsent]: planningMapSettings.hideLinesOnTrackAbsent,
              [PlanningSettingsKeys.ActiveWidth]: planningMapSettings.activeWidth,
              [PlanningSettingsKeys.InactiveWidth]: planningMapSettings.inactiveWidth
            },
            allActiveItems,
            trackData,
            cachedGeohash,
            focusRow
          )
        : [],
    [mapData, planningMapSettings, allActiveItems, focusRow, trackDataChanges]
  );

  return { polyline };
};
