import { ErrorAPI, Notify } from '@common/types';
import { takeLatest } from 'redux-saga/effects';
import { EMPTY_STRING } from '@common/constants';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { PaymentAction, defaultStatuses } from '@common/feature/payment/constants/default-message';
import { PaymentRoutinesWithNotifier } from '@common/feature/payment/actions';

/** Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: PaymentAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || defaultStatuses[action]?.[type] || EMPTY_STRING;
};

export default getDataNotificationMessage;

export const getActionFromRoutine = (actionName: string) => actionName as PaymentAction;

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, PaymentAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'payment',
  getNotificationMessage: getDataNotificationMessage
});

const errorWorker = notifierWorkerCreator<ErrorAPI, PaymentAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'payment',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* PaymentNotifierWatcher() {
  for (let routine of PaymentRoutinesWithNotifier) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}

