import { TimeStamp } from '@common/types';
import moment from 'moment';
import { parseDateFromServer } from '@common/utils';

export const parseDateFromServerForRangePicker = (from?: string | TimeStamp, till?: string | TimeStamp): moment.Moment[] | undefined => {
  const f = parseDateFromServer(from);
  const t = parseDateFromServer(till);
  if (!f || !t) return undefined;
  return [f, t];
};
