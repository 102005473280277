import { useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { EMPTY_ARRAY_DATA } from '@common/constants';

export const useCheckboxGroup = (defaultValues?: string[]) => {
  const [checkboxGroupValues, setCheckboxGroupValues] = useState<CheckboxValueType[]>(defaultValues || EMPTY_ARRAY_DATA);
  const handleChangeCheckboxValues = (values: CheckboxValueType[]) => setCheckboxGroupValues(values);
  const handleResetCheckboxValues = () => setCheckboxGroupValues(EMPTY_ARRAY_DATA);

  return { checkboxGroupValues, handleChangeCheckboxValues, handleResetCheckboxValues };
};

