import { Track } from '@modules/track/types';
import { orderActionDecorator } from '@modules/track/decorators/order-action';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators/get-vehicle-route-color';
import { IndexedArray, RouteVehicle, WaypointTotal } from '@common/types';

export const waypointDecorator = (waypoints: IndexedArray<WaypointTotal> | null, vehicle: RouteVehicle): { tracks: Track[] } => {
  const tracksTrip: Track[] = [];
  const color = getVehicleRouteColor(vehicle);

  for (const waypointID in waypoints) {
    const waypoint = waypoints[waypointID];
    const { Orders } = orderActionDecorator(waypoint.OrderActionTotal);
    const prevPoint = waypoint.PrevWaypointID ? waypoints[waypoint.PrevWaypointID] : undefined;

    const wpTrack: Track = {
      color,
      coords: waypoint.GeoHash,
      vehicle,
      waypoint,
      orders: Orders,
      number: +(waypoint.Number.split('.').pop() || 0),
      nativeCoords: {
        from: prevPoint?.LatLon,
        to: waypoint.LatLon
      }
    };

    tracksTrip.push(wpTrack);

    // if (prevWaypoint) {
    //   TracksTrip.push({
    //     color,
    //     coords: null,
    //     waypoint: prevWaypoint,
    //     number: +(prevWaypoint.Number.split('.').pop() || 0)
    //   });
    // }
  }

  return {
    tracks: tracksTrip
  };
};
