import { Depot, Dictionary, Notify, NotifyDurationInSecond } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { bindTimeZoneForDepotsRoutine, dictionaryPlannerRoutine } from '../actions';
import { projectDepotIDSSelector } from '@modules/planning/children/by-date/selectors/project';
import { DictionaryAPI } from '@common/api/common';
import { ILSUserNotify } from '@common/components';
import { isEmpty } from 'lodash';

const preparePayload = (depots: Array<Depot>) => {
  return depots.reduce((depotsRes: Array<{ id: string | number; latLon: { lat: number; lon: number } }>, depot) => {
    if (typeof depot.LatLon?.lat === 'number' && typeof depot.LatLon?.lon === 'number') {
      depotsRes.push({
        id: depot.ID,
        latLon: {
          lat: depot.LatLon.lat,
          lon: depot.LatLon.lon
        }
      });
    }
    return depotsRes;
  }, []);
};

// координаты по адресу
function* bindTimeZoneForDepotsWorker(
  action: PayloadAction<{
    ids: Array<Depot['ID']>;
  }>
) {
  const { ids } = action.payload;
  const dictionary = Dictionary.Depot;
  const { request, success, failure, fulfill } = bindTimeZoneForDepotsRoutine;
  try {
    const depots: Array<Depot> = yield select(projectDepotIDSSelector(ids));
    yield put(request({ dictionary }));
    const depotsParam = preparePayload(depots);
    if (isEmpty(depotsParam)) {
      ILSUserNotify(Notify.Error, 'Выбраны точки без координат', NotifyDurationInSecond.Two);
    } else {
      const {
        data: { data }
      } = yield call(DictionaryAPI.bindTimeZone, {
        depots: depotsParam
      });
      yield put(success({ dictionary, records: data }));
    }
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(dictionaryPlannerRoutine.trigger({ dictionary }));
    yield put(fulfill({ dictionary }));
  }
}

export function* bindTimeZoneForDepotsWatcher() {
  yield takeLatest(bindTimeZoneForDepotsRoutine.trigger, bindTimeZoneForDepotsWorker);
}
