import { Cargo, Depot, DepotOverload, Dictionary, IndexedArray, Order, Worktime } from '@common/types';
import { createArrayFromIndexedArray, createRecordDictionaryEnumField } from '@common/utils';
import { EMPTY_CELL } from '@common/constants';
import { isEmpty } from 'lodash';
import { cargoDescriptionsToString } from '@common/models/cargo/decorators';
import { ProjectState } from '@common/types/dictionaries/project';
import { getIDsArrayFromValue, getValuesForSelectMultiple } from '@components/decorators';

const EMPTY_CLIENT_DEPOT_OVERLOAD = { UnloadStartOverload: null, UnloadEndOverload: null, LoadStartOverload: null, LoadEndOverload: null };
const EMPTY_WORKTIME = { LoadEnd: null, LoadStart: null, UnloadEnd: null, UnloadStart: null, Name: undefined };

export const getDecoratedOrders = ({
  orders,
  DepotOverload,
  Worktime,
  depots
}: {
  Worktime?: ProjectState['Worktime'];
  depots?: ProjectState['Depot'];
  DepotOverload?: ProjectState['DepotOverload'];
  orders: Array<Order & { Source?: Depot; Target?: Depot }> | IndexedArray<Order & { Source?: Depot; Target?: Depot }>;
}) => {
  return createArrayFromIndexedArray(orders).map((order) => {
    const target = order.Target ?? (order.TargetID ? depots?.[order.TargetID] : null);
    const source = order.Source ?? (order.SourceID ? depots?.[order.SourceID] : null);

    let LoadDuration = 0;
    let UnloadDuration = 0;
    createArrayFromIndexedArray(order.OrderCargo).forEach((orderCargo) => {
      if (orderCargo.OrderID === order.ID) {
        let cargo: Cargo | undefined;
        if (!cargo && order?.Cargo?.length) {
          for (const ind in order.Cargo) {
            if (order.Cargo[ind].ID === orderCargo.CargoID) {
              cargo = order.Cargo[ind];
            }
          }
        }
        if (cargo) {
          const cf = orderCargo?.Amount ?? 1;
          LoadDuration += cf * (cargo.LoadDuration ?? 0);
          UnloadDuration += cf * (cargo.UnloadDuration ?? 0);
        }
      }
    });

    /** DepotOverloadID заявки (по сути === Target), если отсутствует, точки разгрузки Target */
    const DepotOverloadID = order.DepotOverloadID || target?.DepotOverloadID;
    const { UnloadStartOverload, UnloadEndOverload, LoadStartOverload, LoadEndOverload }: Partial<DepotOverload> =
      DepotOverloadID && !isEmpty(DepotOverload)
        ? DepotOverload[DepotOverloadID] ?? EMPTY_CLIENT_DEPOT_OVERLOAD
        : EMPTY_CLIENT_DEPOT_OVERLOAD;

    /** WorktimeID заявки, если отсутствует, берём WorktimeID точки разгрузки (Target) */
    const WorktimeID: Worktime['ID'] | null | undefined = order?.WorktimeID ?? order?.Worktime?.ID ?? target?.WorktimeID;
    const orderWorktime: Partial<Worktime> = !isEmpty(Worktime) && WorktimeID ? Worktime[WorktimeID] ?? EMPTY_WORKTIME : EMPTY_WORKTIME;
    const WorktimeName = orderWorktime?.Name ?? (WorktimeID && `Режим работы №${WorktimeID}`);

    /** Зона доставки. Поле invisible, пока не готова задача TMS-9763 */
    const TargetZoneID = target?.ZoneID || null;
    const TargetZoneName = (TargetZoneID && target?.ZoneEnum?.[TargetZoneID]) || null;
    const TargetZoneEnum = TargetZoneID
      ? createRecordDictionaryEnumField([{ ID: TargetZoneID, Name: TargetZoneName }], Dictionary.TargetZoneID)
      : null;

    return {
      ...orderWorktime,
      ...order,
      dictionary: Dictionary.Order,
      key: order?.ID ?? EMPTY_CELL,
      WorktimeID,
      WorktimeEnum: WorktimeID ? { [WorktimeID]: WorktimeName } : [],
      UnloadStartOverload,
      UnloadEndOverload,
      LoadStartOverload,
      LoadEndOverload,
      CargoID: getValuesForSelectMultiple({ data: order?.Cargo }),
      CargoDescription: cargoDescriptionsToString(order?.Cargo ?? []),
      CargoEnum: createRecordDictionaryEnumField(order.Cargo),
      LoadDuration,
      UnloadDuration,
      Delay: target?.Delay,
      Description: target?.Description,
      RestrictionEnum: order.RestrictionEnum ?? createRecordDictionaryEnumField(order?.Restriction),
      SourceID: [source?.DepotTypeID, order.SourceID],
      TargetID: [target?.DepotTypeID, order.TargetID],
      SourceContactID: order?.SourceContactEnum ? Object.keys(order?.SourceContactEnum) : getIDsArrayFromValue(order?.SourceContactID),
      TargetContactID: order?.TargetContactEnum ? Object.keys(order?.TargetContactEnum) : getIDsArrayFromValue(order?.TargetContactID),
      TargetAddress: target?.AddressDescription,
      SourceAddress: source?.AddressDescription,
      TargetZoneID,
      TargetZoneEnum
    };
  });
};

