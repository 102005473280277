import { all, put, takeLatest } from 'redux-saga/effects';
import { getCurrentSchemeRoutine, getInitialRoutine, getMaxSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';

function* getInitialWorker() {
  const { failure, fulfill } = getInitialRoutine;

  try {
    yield all([put(getCurrentSchemeRoutine.trigger()), put(getMaxSchemeRoutine.trigger())]);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getInitialWatcher() {
  yield takeLatest(getInitialRoutine.trigger, getInitialWorker);
}
