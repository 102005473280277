export const getActiveDataSourceIndexByPage = ({
  focusPage,
  pageSize,
  dataSourceArrayIndex
}: {
  focusPage: number;
  pageSize: number;
  dataSourceArrayIndex: number;
}) => {
  const rowIndex = dataSourceArrayIndex - (focusPage * pageSize - pageSize);
  return rowIndex > 0 ? rowIndex : 0;
};
