// export const DEFAULT_BOARD_SETS_TITLE = 'Новый набор';
// export const DEFAULT_BOARD_TITLE = 'Новая доска';

// Доски
export const BOARDS_TITLE = 'Доски';
export const NEW_BOARD_TITLE = 'Добавить новую доску';
export const COPY_BOARD_TITLE = 'Выберите доску для копирования';
export const COPY_EXISTING_BOARD_BTN_TEXT = 'Взять за основу доску';
export const CREATE_EMPTY_BOARD_TEXT = 'Создать пустую доску';

// Наборы
export const BOARD_SETS_TITLE = 'Наборы';
export const COPY_BOARD_SET_TITLE = 'Выберите набор для копирования';
export const NEW_BOARD_SET_TITLE = 'Добавить новый набор';
export const CREATE_EMPTY_BOARD_SET_TEXT = 'Создать пустой набор';
export const COPY_EXISTING_BOARD_SET_BTN_TEXT = 'Взять за основу набор';
