import { FC } from 'react';
import { ILSProgress } from '@common/components';
import { useSelector } from 'react-redux';
import { validateIsRouteDoneSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';

interface IProps {
  id: RoutesID;
}

export const TenderOwnFormTableRouteHeaderWithValidation: FC<IProps> = ({ id }) => {
  const { isRouteDone } = useSelector(validateIsRouteDoneSelector(id));

  return (
    <h2 className={'tender-from-planner-form-table-fieldset-row'}>
      {`Маршрут №${id}`}
      <ILSProgress status={isRouteDone ? 'success' : 'exception'} />
    </h2>
  );
};
