import { FC } from 'react';
import { ADDING_WITH_NO_KEYS } from '@common/constants/general';
import { OrderStatus } from '@common/models/order';
import { IILSTableProps, TableRecord } from '@common/types';
import { Dictionary } from '@common/types/dictionaries';
import { ILSTable } from '@components/index';
import { tableCustom } from '@core/containers/table-custom';
import { orderConfig } from '@modules/planning/children/by-date/config/order';
import { UNRESOLVED_ORDERS_TABLE_SETTINGS } from '@modules/planning/children/by-date/constants/tables/unresolved-order-table';

interface IProps extends IILSTableProps<TableRecord> {
  orderStatus: OrderStatus;
  showAddOrder: boolean;
}

export const ILSPlanningOrderTableComponent: FC<IProps> = tableCustom(
  ({ orderStatus, showAddOrder, ...props }) => {
    const config = {
      ...orderConfig,
      adding: showAddOrder ? ADDING_WITH_NO_KEYS : false,
      copying: false
    };

    const rowSelection = {
      checkStrictly: false
    };
    return (
      <ILSTable
        {...props}
        virtual
        focusRowCustomHighlight="active-row-custom"
        rowSelection={rowSelection}
        scroll={props?.scroll ?? { x: 500, y: 300 }}
        config={config}
        tableConfig={config}
        className="result-table unresolved-table"
        dictionary={Dictionary.Order}
        overscanRowCount={UNRESOLVED_ORDERS_TABLE_SETTINGS.OVERSCAN_ROW_COUNT}
      />
    );
  },
  orderConfig,
  Dictionary.NotDeliveredOrder
);
