import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { templateTableConfig } from '@modules/documents/children/templates/constants/table-config';
import { ILSSkeleton, ILSTable, ILSUserNotify } from '@common/components';
import { documentTemplatesSelector } from '../../selectors';
import {
  copyTemplateRoutine,
  deleteDocumentTemplateRoutine,
  getDocumentTemplatesRoutine,
  getTemplateRoutine,
  updateTemplateRoutine
} from '../../actions';
import ILSViewDocumentTemplateModal from '@modules/documents/components/view-document-template-modal';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';
import { addOptionsButtonTemplates } from '@modules/documents/helpers/table-options-button-templates';
import { Tabs } from '../../types/tabs';
import { Notify } from '@common/types';

const ILSTemplatesTable: FC = () => {
  const dispatch = useAppDispatch();
  const { documentTemplates, isFetching } = useAppSelector(documentTemplatesSelector);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const setMenuItems = (document: DocumentTemplate): ItemType[] => [
    { label: 'Открыть', key: 1, onClick: () => showTemplate(document.ID) },
    { label: 'Скопировать шаблон', key: 2, onClick: () => copyTemplate(document.ID) },
    { label: 'Удалить', key: 4, onClick: () => deleteTemplate(document.ID) }
  ];

  const copyTemplate = (templateId: number) => {
    dispatch(copyTemplateRoutine(templateId));
  };

  const showTemplate = (templateId: number) => {
    setViewModalOpen(true);
    dispatch(getTemplateRoutine({ templateId }));
  };

  const deleteTemplate = (documentId: number) => {
    dispatch(deleteDocumentTemplateRoutine(documentId));
  };

  useEffect(() => {
    dispatch(getDocumentTemplatesRoutine());
  }, []);

  const dataSource = addOptionsButtonTemplates(documentTemplates, (document) => setMenuItems(document));

  const handleSave = (record: any, value: any) => {
    if (value) {
      dispatch(updateTemplateRoutine({ templateId: record.ID, template: { Name: value } }));
      return;
    }
    ILSUserNotify(Notify.Warning, 'Значение поля не должно быть пустым');
  };

  return (
    <div>
      <ILSSkeleton loading={isFetching} active>
        <ILSTable
          handleSave={(record, dataIndex, value) => handleSave(record, value)}
          dataSource={dataSource}
          config={templateTableConfig}
          cellClassName={() => 'document-table-cell'}
          tableName={Tabs.DOCUMENTS}
          scroll={{ x: 400, y: 500 }}
          className="editable-table"
        />
      </ILSSkeleton>
      <ILSViewDocumentTemplateModal open={viewModalOpen} handleClose={() => setViewModalOpen(false)} />
    </div>
  );
};

export default ILSTemplatesTable;

