import { FC, useEffect } from 'react';
import { ILSCard, ILSForm, ILSFormItem, ILSInput, ILSInputNumber } from '@components/index';
import {
  AuctionFormField,
  AuctionFormFieldLabel,
  AuctionFormValues,
  TenderOfferFormItem
} from '@modules/tender-platform/children/tender-search/constants/offer';
import { TenderForm } from '@modules/tender-platform/types/platform';
import { ILSTypographyTitle } from '@components/general/typography';
import { ILSSelectCurrency } from '@components/data-entry/select';
import { ILSVehicleNDriverField } from '@modules/tender-platform/children/tender-search/components/offer/vehicle-n-driver';
import { useForm } from 'antd/es/form/Form';
import { TenderPlatformAuctionHeader } from '@modules/tender-platform/children/tender-search/components/auction/auction-header';
import { TenderPlatformAuctionInfo } from '@modules/tender-platform/children/tender-search/components/auction/auction-info';
import { useSelector } from 'react-redux';
import { auctionFormSelector } from '@modules/tender-platform/children/tender-search/selectors/components/auction';
import { MinPriceButton } from '@modules/tender-platform/children/tender-search/components/auction/min-price-button';
import { currentTenderAuctionPriceSelector } from '@modules/tender-platform/selectors';
import { TenderSearchOfferTitleComponent } from '@modules/tender-platform/children/tender-search/components/offer/offer-title';

interface IProps {
  onFinish(values: AuctionFormValues): void;
}

export const TenderPlatformAuctionRequestInfoComponent: FC<IProps> = ({ onFinish }) => {
  const current = useSelector(currentTenderAuctionPriceSelector);
  const { initialValues, info, tenderOfferID } = useSelector(auctionFormSelector);
  const { endTime, isBestPrice, isOfferMine, accepted, canChangeOffer, isMyAndBest, isOver } = info;
  const { CurrentPrice, MinPrice } = initialValues;
  const hasButtonMinPrice = !isOver && (!isBestPrice || !isMyAndBest);
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues
    });
  }, [initialValues]);

  return (
    <ILSForm
      form={form}
      className={'tender-platform-card-form-auction'}
      name={TenderForm.Auction}
      id={TenderForm.Auction}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <TenderSearchOfferTitleComponent tenderOfferID={tenderOfferID} />
      <TenderPlatformAuctionHeader hasCountdown={!isOver && !accepted} currentPrice={CurrentPrice} endTime={endTime} />
      <TenderPlatformAuctionInfo offerID={tenderOfferID} />
      {Boolean(current) && (
        <ILSCard className={'auction-info'} title={isOfferMine ? AuctionFormFieldLabel.MyPriceWin : AuctionFormFieldLabel.AnotherWinner}>
          <ILSTypographyTitle level={4} children={CurrentPrice} />
          <ILSSelectCurrency disabled />
        </ILSCard>
      )}
      {!isMyAndBest && (
        <>
          <ILSFormItem label={AuctionFormFieldLabel.MyPrice} name={AuctionFormField.MyPrice}>
            <ILSInput addonAfter={<ILSSelectCurrency disabled />} disabled />
          </ILSFormItem>
          {!isOver && (
            <ILSFormItem
              label={isBestPrice ? AuctionFormFieldLabel.AnotherWinner : AuctionFormFieldLabel.Price}
              name={TenderOfferFormItem.Price}
            >
              <ILSInputNumber addonAfter={<ILSSelectCurrency disabled />} disabled={!canChangeOffer} placeholder={String(MinPrice)} />
            </ILSFormItem>
          )}
        </>
      )}
      <ILSVehicleNDriverField disabled={!canChangeOffer} />
      {hasButtonMinPrice && <MinPriceButton tenderOfferID={tenderOfferID} onFinish={onFinish} />}
    </ILSForm>
  );
};
