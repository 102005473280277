import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectCreateValues } from '@common/types';
import { CreateProjectFormItem, ILSPlanningFormNewProjectComponent } from '@modules/planning/components/create-project';
import { planningCreateProjectRoutine } from '@modules/planning/actions';
import { prepareDateToServer } from '@common/utils';
import { projectPlansOptionsSelector } from '@modules/planning/children/by-date/selectors/project';
import { Moment } from 'moment';
import { PlanID } from '@common/types/dictionaries/plan';

interface IProps {
  showForm: boolean;

  handleFormModalVisible(): void;
}

export const ILSPlanningFormNewProjectContainer: FC<IProps> = ({ showForm, handleFormModalVisible }) => {
  const dispatch = useDispatch();
  const options = useSelector(projectPlansOptionsSelector);

  const createProject = ({ Name, Start, planID }: Record<CreateProjectFormItem, unknown>) => {
    const payload: ProjectCreateValues = {
      Name: Name as string,
      Start: prepareDateToServer(Start as Moment)
    };
    if (planID) {
      payload.planID = planID as PlanID;
    }
    dispatch(planningCreateProjectRoutine(payload));
  };

  return (
    <ILSPlanningFormNewProjectComponent
      showForm={showForm}
      createProject={createProject}
      handleFormModalVisible={handleFormModalVisible}
      options={options}
    />
  );
};
