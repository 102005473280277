import { FC, useEffect, useState } from 'react';
import { tableOnFuelDrainConfig, tableOnFuelDrainDateToTable } from '@common/table-configs/monitoring-dashboard/on-fuel-drain';
import { ActiveType, ColumnTable, Dictionary, IndexedArray, RecordTableButton } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { MonitoringBDDAndFuelDrainModel, MonitoringFuelDrainDecoratorReturnedModel } from '@common/types/dictionaries/monitoring-vehicle';
import { ILSTable } from '@common/components';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { addToTableExelButton } from '../../utils/exel-button';
import { addToTableShowViewedButton, hasUnviewed, tableViewedFilter } from '../../utils/viewed-button';
import { ILSDashboardViewedButtonComponent } from '../../components/viewed-button';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource: Array<MonitoringBDDAndFuelDrainModel> | IndexedArray<MonitoringBDDAndFuelDrainModel>;
  onContextOpen?: (record: MonitoringBDDAndFuelDrainModel, e: MouseEvent) => void;
  columns: ColumnTable<MonitoringBDDAndFuelDrainModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  onSelectedVehicleID: (vehicleID: string | undefined) => void;
  selectedVehicleID: number | string | undefined;
  handleUpdateView?: ((id: string | number | string[] | number[], value: 0 | 1) => void) | undefined;
}

// Контейнер таблицы подозрений на слив топливо
export const MonitoringTableDeliveryFuelDrain: FC<IProps> = ({
  dataSource,
  onContextOpen,
  recordTableButtons,
  loadExel,
  onSelectedVehicleID,
  selectedVehicleID,
  handleUpdateView,
  ...props
}) => {
  const [showAll, setShowAll] = useState(true);
  const { setFocusRow, focusRow } = useFocusRow();

  const decoratedData = tableViewedFilter(tableOnFuelDrainDateToTable({ dataSource, handleView: handleUpdateView }), showAll);
  recordTableButtons = addToTableExelButton(recordTableButtons, loadExel, decoratedData, props.columns, Dictionary.FuelDrain);
  recordTableButtons = addToTableShowViewedButton(recordTableButtons, showAll, (val: boolean) => showAll !== val && setShowAll(val));

  const [focusRowKey, setFocusRowKey] = useState<undefined | string>(undefined);

  const onFocusRow = (records: Array<MonitoringFuelDrainDecoratorReturnedModel & { selectType: ActiveType }>) => {
    for (let record of records) {
      if (record.selectType === ActiveType.Focus) {
        onSelectedVehicleID(record.VehicleID.toString());
        if (focusRowKey !== record.key) {
          setFocusRowKey(record.key.toString());
        }
      }
    }
  };

  useEffect(() => {
    if (decoratedData.length) {
      if (focusRowKey) {
        if (!decoratedData.find((record) => record.key === focusRowKey)) {
          setFocusRowKey(decoratedData[0].key);
        }
      } else {
        setFocusRowKey(decoratedData[0].key);
      }
    }
  }, [decoratedData]);

  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        focusRow={focusRow}
        setFocusRow={setFocusRow}
        handleSelectedElements={onFocusRow}
        recordTableButtons={recordTableButtons}
        config={tableOnFuelDrainConfig}
        dataSource={decoratedData}
        size={'small'}
        scroll={{ y: 'calc(75vh - 430px)' }}
        pagination={false}
        className="editable-table"
        bordered
        dictionary={Dictionary.FuelDrain}
        onContextOpen={onContextOpen}
      />

      <ILSDashboardViewedButtonComponent
        key={Dictionary.FuelDrain + 'button'}
        disabled={!hasUnviewed(decoratedData)}
        onClick={() => {
          handleUpdateView?.(
            tableViewedFilter(decoratedData, false).map((item) => item.dependentKey),
            1
          );
        }}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringFuelDrain = tableCustom(MonitoringTableDeliveryFuelDrain, tableOnFuelDrainConfig, Dictionary.FuelDrain);
