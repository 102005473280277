const getWidthCoefficient = (widths: number[]) => {
  let fullWidth = widths.reduce((a, b) => a + b, 0);
  return 100 / fullWidth;
};

export const getBlocksWidths = (widths: number[], minPercent: number[], blocksVisible?: PlannerBlocksFullScreen) => {
  if (blocksVisible && Object.values(blocksVisible).some((v) => !v)) {
    if (blocksVisible.map) {
      return [0, 100];
    } else {
      return [100, 0];
    }
  }
  const coefficient = getWidthCoefficient(widths);
  let newWidths = widths.map((width, i) => {
    let val = width * coefficient;
    if (val < minPercent[i]) {
      val = widths.length === 2 ? minPercent[1 - i] : minPercent[i];
    }
    return val;
  });

  let coefficient1 = getWidthCoefficient(newWidths);
  if (coefficient1 !== 1) {
    newWidths = newWidths.map((width) => {
      return width * coefficient1;
    });
  }
  return newWidths;
};

export type PlannerBlocksFullScreen = {
  table: boolean;
  map: boolean;
};

export const getSplitSizeBlocksParams = ({
  fullScreen,
  visibleBlocks,
  minPercent,
  percents
}: {
  fullScreen: PlannerBlocksFullScreen;
  percents: Array<number> | undefined;
  visibleBlocks: Array<boolean> | undefined;
  minPercent: number[];
}) => {
  let widths: Array<number>;
  if (percents && Array.isArray(percents) && Number(percents[0])) {
    widths = percents;
  } else {
    widths = [50, 50];
  }

  let sizes: Array<number> = [];
  if (visibleBlocks) {
    visibleBlocks?.forEach((visible: boolean, i: number) => {
      if (visible) {
        const size = widths[i];

        sizes.push(size);
      }
    });
  } else {
    sizes = widths;
  }

  const noFullScreen = !fullScreen.table && !fullScreen.map;

  const blocksVisible = {
    table: (noFullScreen || fullScreen.table) && (!visibleBlocks || visibleBlocks[0]),
    map: (noFullScreen || fullScreen.map) && (!visibleBlocks || visibleBlocks[1])
  };

  return {
    blocks: {
      sizes: getBlocksWidths(sizes, minPercent, blocksVisible),
      visible: blocksVisible
    }
  };
};
