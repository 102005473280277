import { createReducer, Draft, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringCreateTrackerLocalRoutine } from '../actions';
import { Tracker } from '@common/types/dictionaries/tracker';

// Создаёт новую строчку в таблице трекеров
export const monitoringCreateTrackerLocalReducer = createReducer(initialState, {
  [monitoringCreateTrackerLocalRoutine.SUCCESS]: (state, action: PayloadAction<Partial<Tracker>>) => {
    const virtualTracker = action.payload as Draft<Tracker>;
    if (state.trackers.data.length === 0) {
      state.trackers.data.push(virtualTracker);
      return state;
    } else {
      state.trackers.data[state.trackers.data.length - 1]?.IMEI === ''
        ? (state.trackers.data[state.trackers.data.length - 1] = virtualTracker)
        : state.trackers.data.push(virtualTracker);
      return state;
    }
  },
  [monitoringCreateTrackerLocalRoutine.FAILURE]: (state) => {
    return state;
  }
});
