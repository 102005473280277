import { StateKeyName } from '@core/constants/core';
import { setProductVersion } from '@core/persist/set-current-product-version';
import { ICoreState } from '@core/types/store';
import { loginActions, logoutActions } from '@modules/current-user/actions';
import { AnyAction } from 'redux';

export const clearStoreInterceptor = (state: ICoreState, action: AnyAction) => {
  let newState: any = null;
  if (action.type === logoutActions.SUCCESS) {
    if (!action.payload?.clearStore) {
      newState = { [StateKeyName.Fixed]: state[StateKeyName.Fixed] };
    } else {
      newState = { [StateKeyName.Auth]: state.Auth, [StateKeyName.Fixed]: {} };
    }
  }

  if (action.type === loginActions.SUCCESS) {
    setProductVersion();
  }
  return newState;
};

