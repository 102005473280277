import { getDecoratedOrders } from '@common/models/order/decorators/get-decorated-orders';
import { TableColumnName } from '@common/types';
import { isString } from 'lodash';

export const countMinOrderDeliveryDate = ({
  orders,
  dateOrderAction
}: {
  orders: ReturnType<typeof getDecoratedOrders>;
  dateOrderAction: TableColumnName;
}) => {
  const minDate = orders.reduce((minDate, order) => {
    const columnValue = order[dateOrderAction];
    if (columnValue && isString(columnValue)) {
      return Math.min(minDate, Date.parse(columnValue));
    }
    return minDate;
  }, Infinity);
  return Number.isFinite(minDate) ? minDate : undefined;
};
