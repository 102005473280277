import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import CustomForNonAuthPage from './custom-page-container';
import { ModulePath } from '@common/types';

export default defineModule({
  title: 'Главная',
  path: ModulePath.CustomWithoutAuthPage,
  component: CustomForNonAuthPage,
  useScope: [ModuleScope.CustomWithoutAuthPage]
});
