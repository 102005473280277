import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';

export enum TenderPlatformAction {
  TenderRead = 'TENDER/READ',
  TenderLotGetAccountUsers = 'TENDER_LOT/GET_ACCOUNT_USERS',
  TenderLotGetDistance = 'TENDER_LOT/GET_DISTANCE',
  TenderChangeTemplate = 'TENDER/CHANGE_TEMPLATE',
  TenderDocumentUpload = 'TENDER_DOCUMENT/UPLOAD',
  TenderDocumentIndex = 'TENDER_DOCUMENT/INDEX',
  TenderDocumentDelete = 'TENDER_DOCUMENT/DELETE',
  TenderDocumentDownload = 'TENDER_DOCUMENT/DOWNLOAD',
  TenderReplicateDocumentRoutine = 'TENDER_DOCUMENT/REPLICATE',
  TenderAction = 'TENDER/ACTION',
  TenderChange = 'TENDER/CHANGE',
  TenderVehicleTypes = 'TENDER/VEHICLE_TYPES',
  TenderOfferGetVehiclesAndDriversForTender = 'TENDER_OFFER/GET_VEHICLES_AND_DRIVERS_FOR_TENDER',
  TenderOfferGetVehiclesForTender = 'TENDER_OFFER/GET_VEHICLES_FOR_TENDER',
  TenderOfferGetDriversForTender = 'TENDER_OFFER/GET_DRIVERS_FOR_TENDER',
  TenderAddTenderForRoute = 'TENDER/ADD_TENDER_FOR_ROUTE',
  TenderUpdateTenderForRoute = 'TENDER/UPDATE_TENDER_FOR_ROUTE',
  TenderCreateTenderForRoute = 'TENDER/CREATE_TENDER_FOR_ROUTE',
  TenderOfferSetRatingRoutine = 'TENDER_OFFER/SET_RATING_ROUTINE'
}

export const tenderReadRoutine = createRoutine(TenderPlatformAction.TenderRead);
export const tenderGetAccountUsersRoutine = createRoutine(TenderPlatformAction.TenderLotGetAccountUsers);
export const tenderGetDistanceRoutine = createRoutine(TenderPlatformAction.TenderLotGetDistance);
export const tenderChangeTemplateRoutine = createRoutine(TenderPlatformAction.TenderChangeTemplate);
export const tenderDocumentUploadRoutine = createRoutine(TenderPlatformAction.TenderDocumentUpload);
export const tenderDocumentsRoutine = createRoutine(TenderPlatformAction.TenderDocumentIndex);
export const tenderDocumentDeleteRoutine = createRoutine(TenderPlatformAction.TenderDocumentDelete);
export const tenderDocumentDownloadRoutine = createRoutine(TenderPlatformAction.TenderDocumentDownload);
export const tenderActionRoutine = createRoutine(TenderPlatformAction.TenderAction);
export const tenderChangeRoutine = createRoutine(TenderPlatformAction.TenderChange);
export const tenderGetVehicleTypesRoutine = createRoutine(TenderPlatformAction.TenderVehicleTypes);

export const tenderGetVehicleNDriverRoutine = createRoutine(TenderPlatformAction.TenderOfferGetVehiclesAndDriversForTender);
export const tenderOfferGetVehicleRoutine = createRoutine(TenderPlatformAction.TenderOfferGetVehiclesForTender);
export const tenderOfferGetDriverRoutine = createRoutine(TenderPlatformAction.TenderOfferGetDriversForTender);

export const addTenderForRouteRoutine = createRoutine(TenderPlatformAction.TenderAddTenderForRoute);
export const updateTenderForRouteRoutine = createRoutine(TenderPlatformAction.TenderUpdateTenderForRoute);
export const createTenderForRouteRoutine = createRoutine(TenderPlatformAction.TenderCreateTenderForRoute);
export const approveReplicateDocumentRoutine = createRoutine(TenderPlatformAction.TenderReplicateDocumentRoutine);

export const tenderOfferSetRatingRoutine = createRoutine(TenderPlatformAction.TenderOfferSetRatingRoutine);

export const tenderRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  tenderDocumentDeleteRoutine,
  tenderDocumentUploadRoutine,
  tenderChangeRoutine,
  tenderChangeTemplateRoutine,
  tenderOfferSetRatingRoutine,
  addTenderForRouteRoutine,
  createTenderForRouteRoutine
];

