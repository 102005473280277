export enum PaymentFormFieldNames {
  InvoiceSum = 'InvoiceSum'
}

export enum PaymentFormLabels {
  InvoiceSum = 'Введите сумму, на которую хотите сформировать счет'
}

export enum PaymentFormPlaceholders {
  InvoiceSum = 'Сумма счета'
}

export enum PaymentFormInputTypes {
  Text = 'TEXT'
}

export enum PaymentFormNames {
  Invoice = 'invoice'
}

export interface ICreateInvoiceFormValues {
  [PaymentFormFieldNames.InvoiceSum]: string;
}
