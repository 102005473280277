import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import LimitedPage from './limited-page-container';

export default defineModule({
  title: 'Ограниченный доступ',
  path: '*',
  component: LimitedPage,
  useScope: [ModuleScope.Logo, ModuleScope.HeaderRight]
});
