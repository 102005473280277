import { HeaderTransportCompany } from '@modules/orders-and-trips/components/transport/external-transport-company/header';
import { TransportCompany } from '@modules/orders-and-trips/components/transport/external-transport-company/company';
import { ILSSpin } from '@common/components';
import { useExternalTransportCompany } from '@modules/orders-and-trips/hooks/use-external-transport-company';
import { ExternalTransportConfirmModal } from '@modules/orders-and-trips/components/transport/external-transport-company/external-transport-confirm-modal';
import { isEmpty } from 'lodash';
import { memo } from 'react';

export const ExternalTransportCompanyContainer = memo(() => {
  const { chooseCompany, availableTransportCompany, isFetching, preCost, confirmModalVisible, handleShowConfirmModal, submitButtonProps } =
    useExternalTransportCompany();
  return (
    <>
      <ILSSpin spinning={isFetching && isEmpty(availableTransportCompany)} tip={'Данные загружаются...'}>
        <div className="transport-company">
          <HeaderTransportCompany isFetching={isFetching} chooseCompany={chooseCompany} />
          <div className="transport-company-container">
            {availableTransportCompany.map((company, index) => {
              return (
                <TransportCompany
                  isFetching={isFetching}
                  key={company.id}
                  preCost={preCost}
                  chooseCompany={chooseCompany}
                  company={company}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </ILSSpin>
      <ExternalTransportConfirmModal
        confirmModalVisible={confirmModalVisible}
        handleShowConfirmModal={handleShowConfirmModal}
        submitButtonProps={submitButtonProps}
      />
    </>
  );
});
