import { createReducer } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { ISchemeEditorFullState } from '@modules/planning/children/scheme-editor/types';
import { uploadSchemeIniRoutine } from '@modules/planning/children/scheme-editor/actions';

export const uploadSchemeIniReducer = createReducer(INITIAL_STATE, {
  [uploadSchemeIniRoutine.REQUEST]: onRequest,
  [uploadSchemeIniRoutine.SUCCESS]: (state: ISchemeEditorFullState) => {
    return state;
  },
  [uploadSchemeIniRoutine.FAILURE]: onFailure,
  [uploadSchemeIniRoutine.FULFILL]: onFulfill
});
