import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { IndexedArray, TimeStamp } from '@common/types';

export const getRefData = <T extends { Deleted?: TimeStamp | null }>(
  data?: IndexedArray<T> | Array<T> | null,
  filtered: boolean = true
): Array<T> => {
  const refData = createArrayFromIndexedArray(data);
  return filtered ? refData.filter(({ Deleted }) => !Deleted) : refData;
};
