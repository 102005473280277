import { FC } from 'react';
import { ILSButton, ILSForm, ILSFormItem, ILSInputPassword } from '@components/index';
import { ButtonAction } from '@components/general/buttons';
import {
  CHANGE_PASSWORD_FORM_RULES,
  CHANGE_PASSWORD_FORM_SETTINGS_DEPENDENCIES,
  SettingsChangePasswordFormItem
} from '@modules/settings/constants';
import { usePasswordChange } from '@modules/settings/utils/hooks/use-password-change';

export const SettingsChangePasswordFormComponent: FC = () => {
  const { onPasswordChange } = usePasswordChange();

  return (
    <ILSForm name="passwordChange" layout={'vertical'} onFinish={onPasswordChange} className="w-33 p-3">
      <ILSFormItem
        name={SettingsChangePasswordFormItem.OldPassword}
        label="Старый пароль"
        rules={CHANGE_PASSWORD_FORM_RULES.OldPassword}
        hasFeedback
      >
        <ILSInputPassword />
      </ILSFormItem>
      <ILSFormItem
        name={SettingsChangePasswordFormItem.NewPassword}
        label="Новый пароль"
        rules={CHANGE_PASSWORD_FORM_RULES.NewPassword}
        hasFeedback
      >
        <ILSInputPassword />
      </ILSFormItem>
      <ILSFormItem
        dependencies={CHANGE_PASSWORD_FORM_SETTINGS_DEPENDENCIES}
        name={SettingsChangePasswordFormItem.ConfirmPassword}
        label="Подтвердите пароль"
        rules={CHANGE_PASSWORD_FORM_RULES.ConfirmPassword}
        hasFeedback
      >
        <ILSInputPassword />
      </ILSFormItem>
      <ILSFormItem wrapperCol={{ offset: 8, span: 16 }}>
        <ILSButton action={ButtonAction.Save} type="primary" htmlType="submit" />
      </ILSFormItem>
    </ILSForm>
  );
};
