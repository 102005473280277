import { ITableSearchConfig, TenderFilter } from '@modules/tender-platform/children/tender-search/types/filter';
import { ILSDatePicker, ILSInput, ILSTooltip } from '@components/index';
import { Field, SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';
import moment, { Moment } from 'moment';
import { Select } from 'antd/es';
import { Dispatch } from 'redux';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { disableDateRange } from '@modules/tender-platform/utils/helpers/disable-filters-date-range';
import { KeyboardCode } from '@common/constants/general';

export const renderSearchFilterTab = (
  filterTab: ITableSearchConfig,
  tendersFilters: Array<TenderFilter>,
  handleFilter: (filterValue: string, filter: TenderFilter) => void,
  handleClear: (filterValue: string, filter: TenderFilter) => void,
  dispatch: Dispatch,
  handleFilterChange: (query: Moment | null, filter: TenderFilter) => void
) => {
  const defaultValue = tendersFilters.find((filter) => filter.key === filterTab.filter.key)?.value;
  const defaultDateValue = defaultValue ? moment(Number(defaultValue) * SECONDS_TO_MILLISECONDS_MULTIPLIER) : undefined;

  switch (filterTab.type) {
    case 'input':
      return (
        <ILSInput
          placeholder={filterTab.placeholder}
          className="tender-filter-input"
          style={filterTab.style}
          // todo придумать реализацию debounce для инпута. defaultValue и delay в саге не поможет https://jira.intelogis.ru/browse/TMS-5880
          value={defaultValue}
          onChange={(e) => handleFilter(e.target.value, filterTab.filter)}
          allowClear
        />
      );
    case 'autocomplete':
      return (
        <ILSTooltip title={'Выберите значение из выпадающего списка или нажмите Enter'}>
          <ILSSuggestionAutoComplete
            allowClear
            className="tender-filter-autocomplete"
            style={filterTab.style}
            placeholder={filterTab.placeholder}
            onClear={() => handleClear('', filterTab.filter)}
            onKeyDown={(event) => {
              if (event.code === KeyboardCode.Enter) {
                //@ts-ignore
                handleFilter(event.target.value, filterTab.filter);
              }
            }}
            onSelect={(value: string) => handleFilter(value, filterTab.filter)}
            field={Field.City}
            value={defaultValue}
          />
        </ILSTooltip>
      );
    case 'number':
      const wrongMinMaxValue =
        Number(tendersFilters.find((filter) => filter.method === 'rangeMax')?.value) <
        Number(tendersFilters.find((filter) => filter.method === 'rangeMin')?.value);

      return (
        <ILSInput
          placeholder={filterTab.placeholder}
          className={`tender-filter-input ${wrongMinMaxValue && 'tender-filter-error'}`}
          style={filterTab.style}
          onChange={(e) => handleFilter(e.target.value, filterTab.filter)}
          type="number"
          min={filterTab.filter.method === 'rangeMax' ? 1 : 0}
          value={defaultValue}
        />
      );
    case 'date':
      const executionRange = ['ExecutionEndDT', 'ExecutionStartDT'].includes(filterTab.filter.param);

      return (
        <ILSDatePicker
          placeholder={filterTab.placeholder}
          className="tender-filter-input"
          style={filterTab.style}
          onChange={(e) => handleFilterChange(e, filterTab.filter)}
          value={defaultDateValue}
          noIcon={true}
          disabledDate={(current) =>
            executionRange
              ? disableDateRange({ current: current.valueOf(), tendersFilters, filterMethod: filterTab.filter.method, execution: true })
              : disableDateRange({ current: current.valueOf(), tendersFilters, filterMethod: filterTab.filter.method })
          }
          allowClear
        />
      );
    case 'select':
      return (
        <Select
          placeholder={filterTab.placeholder}
          className="tender-filter-select"
          mode={filterTab?.multiple ? 'multiple' : 'tags'}
          onChange={(query: string) => handleFilter(query, filterTab.filter)}
          options={filterTab?.options}
          // @ts-ignore
          value={defaultValue}
          maxTagCount={filterTab.multiple ? 'responsive' : undefined}
          allowClear
        />
      );
  }
};
