import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { CONDITIONS_FOR_CHECK, NEW_TRIP_TEMPLATE, TRIP_TEMPLATE_MODE_OPTIONS } from '@common/models/trip-template/constants';
import { CatalogTripTemplateTableTooltip } from '@modules/catalog/components/trip-template-tooltip';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export const TRIP_TEMPLATE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: false,
  export: true,
  adding: NEW_TRIP_TEMPLATE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,

      type: CellType.Input,
      sorting: 4,
      ellipsis: true,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Код',
      dataIndex: 'Code',
      key: 'Code',
      editable: true,
      active: true,
      ellipsis: true,

      sorting: 2,
      type: CellType.Input,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Cтоимость',
      dataIndex: 'MinCost',
      key: 'MinCost',
      editable: true,
      active: true,

      type: CellType.Float,
      filtering: FilterCellType.String,
      sorting: 3,
      grouping: true
    },
    {
      title: 'Кол-во точек',
      dataIndex: 'MinPoints',
      key: 'MinPoints',
      editable: true,
      active: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 2,
      grouping: true
    },
    {
      title: 'Режим использования',
      dataIndex: 'TemplateMode',
      key: 'TemplateMode',
      active: true,

      editable: true,
      type: CellType.Select,
      sorting: 4,
      options: TRIP_TEMPLATE_MODE_OPTIONS,
      fieldNames: BASE_FIELD_NAMES,

      titleTooltip: CatalogTripTemplateTableTooltip,
      classOverlayTitleTooltip: 'ils-tooltip',
      grouping: true
    },
    {
      title: 'Усл.проверки',
      dataIndex: 'CondForMin',
      key: 'CondForMin',
      active: true,

      editable: true,
      ellipsis: true,
      type: CellType.Select,
      options: CONDITIONS_FOR_CHECK,
      fieldNames: BASE_FIELD_NAMES,

      sorting: 1,
      grouping: true
    },
    {
      title: 'Цвет',
      dataIndex: 'Color',
      key: 'Color',
      active: true,

      editable: true,
      isFixedColumn: true,
      type: CellType.Color
    }
  ]
};
