import { put, takeLatest } from 'redux-saga/effects';
import { tenderFilterRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderFilter } from '@modules/tender-platform/children/tender-search/types/filter';

function* tenderFilterWorker(action: PayloadAction<{ filter: TenderFilter; filterValue: string }>) {
  const { filter, filterValue } = action.payload;
  const { success } = tenderFilterRoutine;
  yield put(success({ filter, filterValue }));
}

export function* tenderFilterWatcher() {
  yield takeLatest(tenderFilterRoutine.trigger, tenderFilterWorker);
}