import { FC } from 'react';
import ILSAnalyticFrame from '@modules/plan-analytic/components/frame';
import { ILSTree } from '@common/components';
import { externalAnalyticDecorator } from '@modules/plan-analytic/decorators/external';
import { useSelector } from 'react-redux';
import { dataSelector } from '@modules/plan-analytic/selectors';
import { ILSNoDataComponent } from '@components/common/no-data';

export const ILSPlanAnalyticComponent: FC<{ provider?: string | null | (string | null)[] }> = ({ provider = 'ILS' }) => {
  const data = useSelector(dataSelector);

  return (
    <>
      {data ? (
        <>
          {provider === 'ILS' ? (
            <ILSAnalyticFrame data={data} />
          ) : (
            <ILSTree
              multiple
              blockNode
              className="p-4 mt-5"
              showLine={{ showLeafIcon: false }}
              treeData={externalAnalyticDecorator(data) as ReturnType<typeof externalAnalyticDecorator>}
            />
          )}
        </>
      ) : (
        <div className={'plan-analytic-content-no-data'}>
          <ILSNoDataComponent text={'Нет данных по плану'} />
        </div>
      )}
    </>
  );
};
