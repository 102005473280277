import { FC } from 'react';
import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';
import { tableCustom } from '@core/containers/table-custom';
import { Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { cargo2table } from '@common/models/cargo/decorators';
import { CARGO_TABLE_CONFIG } from '@common/models/cargo';

const PlanningCargo: FC<IILSTablePlanningComponentProps> = (props) => {
  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={props.config}
      dataDecorator={cargo2table}
      handleDecorator={table2data}
      dictionary={Dictionary.Cargo}
    />
  );
};

export const CargoTable = tableCustom(PlanningCargo, CARGO_TABLE_CONFIG, Dictionary.Cargo);
