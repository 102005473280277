import { createSelector } from 'reselect';
import { TenderSearchState } from '@modules/tender-platform/children/tender-search/types/store';

export const fetchSelector = createSelector(
  (state: TenderSearchState) => state.TenderSearch.isFetching,
  (isFetching) => isFetching
);

export const currentNotificationSettings = createSelector(
  (state: TenderSearchState) => state.TenderSearch.notificationSettings,
  (notificationSettings) => notificationSettings
);

export {
  vehiclesAndDriversOptionsSelector,
  tenderDriverSelector,
  tenderVehicleSelector,
  tenderOfferVehiclesSelector
} from './vehicles-and-drivers';
export { myTendersSelector, tenderFiltersSelector, tendersSelector } from './tenders';
export { tenderByStatusSelector } from './tenders-by-status';
export {
  auctionSelector,
  auctionFormSelector,
  tenderOfferInitialValuesFormSelector,
  tenderOfferFormSelector,
  auctionInitialValuesFormSelector
} from './components';
