import { MarkerPlanning, MarkerType } from '@common/types';
import { ILSMap, ILSMarker } from '@core/containers/map';
import { marker2ILSMarker } from '../decorators/map/marker-to-ils-map-marker';
import { MapLayers } from '../types';
import { MapClickData } from '@common/types/components/map/map';

export const addMarkerH = (
  marker: MarkerPlanning & {
    selected?: boolean | undefined;
  },
  mapLayersGroup: MapLayers,
  onItemClick: ((record?: MapClickData) => void) | undefined,
  curMap: ILSMap,
  addToSide: (layer: L.Layer, group?: L.LayerGroup | L.MarkerClusterGroup | undefined) => void
): {
  lMark: L.Marker;
  layer: L.MarkerClusterGroup | undefined;
} => {
  if (marker && mapLayersGroup.isInit) {
    const lMark = new ILSMarker(marker2ILSMarker(marker, onItemClick)).L;
    switch (marker.type) {
      case MarkerType.Client:
        if (mapLayersGroup.clientCluster) {
          addToSide(lMark, mapLayersGroup.clientCluster);
          return {
            lMark,
            layer: mapLayersGroup.clientCluster
          };
        } else {
          addToSide(lMark);
          return {
            lMark,
            layer: undefined
          };
        }
      case MarkerType.Order:
        if (mapLayersGroup.orderCluster) {
          addToSide(lMark, mapLayersGroup.orderCluster);
          return {
            lMark,
            layer: mapLayersGroup.orderCluster
          };
        } else {
          addToSide(lMark);
          return {
            lMark,
            layer: undefined
          };
        }
      case MarkerType.Storage:
        if (mapLayersGroup.storageCluster) {
          addToSide(lMark, mapLayersGroup.storageCluster);
          return {
            lMark,
            layer: mapLayersGroup.storageCluster
          };
        } else {
          addToSide(lMark);
          return {
            lMark,
            layer: undefined
          };
        }
      default:
        if (mapLayersGroup.cluster) {
          addToSide(lMark, mapLayersGroup.cluster);
          return {
            lMark,
            layer: mapLayersGroup.cluster
          };
        } else {
          addToSide(lMark);
          return {
            lMark,
            layer: undefined
          };
        }
    }
  } else {
    const lMark = curMap.addMarker([marker2ILSMarker(marker, onItemClick)]);
    return {
      lMark,
      layer: undefined
    };
  }
};

