import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray, PayloadActionError } from '@common/types';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';
import { getTrackDataRoutine } from '../actions';
import { MonitoringGetTrackDataModel, TrackGeoModel } from '@common/types/dictionaries/monitoring-vehicle';
import { isEmpty } from 'lodash';
import { updateMonitoringData } from '@modules/monitoring/utils/update-data';

// Устанавливает данные маршрута
export const monitoringgetVehicleTrackDataReducer = createReducer<MonitoringEventDetailsStore>(
  initialState as MonitoringEventDetailsStore,
  {
    [getTrackDataRoutine.REQUEST]: (state) => {
      state.VehicleTracks.isFetching = true;
      return state;
    },
    [getTrackDataRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        data: {
          VehicleTracker: IndexedArray<MonitoringGetTrackDataModel>;
          TrackGeo: IndexedArray<IndexedArray<TrackGeoModel>>;
        };
        from: string;
        till: string;
        vehicleID: number;
        updates: boolean;
      }>
    ) => {
      const { data, from, till, vehicleID, updates } = action.payload;
      state.VehicleTracks.isFetching = false;

      if (updates) {
        if (!isEmpty(data?.VehicleTracker)) {
          state.VehicleTracks.data = updateMonitoringData(state.VehicleTracks.data, data.VehicleTracker);
          state.VehicleTracks.trackGeo = updateMonitoringData(state.VehicleTracks.trackGeo, data.TrackGeo);
        }
      } else {
        if (!isEmpty(data.VehicleTracker)) {
          state.VehicleTracks.data = data.VehicleTracker;
        } else {
          state.VehicleTracks.data = {};
        }
        if (!isEmpty(data.TrackGeo)) {
          state.VehicleTracks.trackGeo = data.TrackGeo;
        } else {
          state.VehicleTracks.trackGeo = {};
        }
      }

      state.VehicleTracks.lastUpdate = new Date().getTime();
      state.VehicleTracks.loadedVehicleId = vehicleID;
      state.VehicleTracks.lastUpdateInterval = {
        from,
        till
      };

      return state;
    },
    [getTrackDataRoutine.FAILURE]: (state, action: PayloadActionError & PayloadAction<any>) => {
      const {} = action.payload;
      state.VehicleTracks.isFetching = false;
      state.VehicleTracks.lastUpdate = undefined;
      state.VehicleTracks.lastUpdateInterval = undefined;

      return state;
    },
    [getTrackDataRoutine.FULFILL]: (state) => {
      state.VehicleTracks.isFetching = false;
      return state;
    }
  }
);
