import { MonitoringSensorEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';

// Датчики и их заголовки
export const SensorEventTypeText: { [K in MonitoringSensorEventStatusType]: string } = {
  [MonitoringSensorEventStatusType.SharpTurn]: 'Резкий поворот',
  [MonitoringSensorEventStatusType.SharpAcceleration]: 'Резкое ускорение',
  [MonitoringSensorEventStatusType.SharpBraking]: 'Резкое торможение',
  [MonitoringSensorEventStatusType.SensorTemp1]: 'Температура 1',
  [MonitoringSensorEventStatusType.SensorTemp2]: 'Температура 2',
  [MonitoringSensorEventStatusType.SensorOpenDoor]: 'Открытие двери'
};
