import { ColumnTable } from '@common/types';
import { columnsWithFixedWidth } from '../constants/doc-table-columns-types';

export function addFixedWidthToColumns<R>(columns: ColumnTable<R>[]): ColumnTable<R>[] {
  return columns.map((item) => {
    return {
      ...item,
      ...(columnsWithFixedWidth[item.dataIndex]
        ? {
            maxWidth: columnsWithFixedWidth[item.dataIndex].maxWidth,
            width: columnsWithFixedWidth[item.dataIndex].width
          }
        : {
            maxWidth: 300,
            width: 300
          })
    };
  });
}
