import { IMapTrack } from '@common/types';
import { LatLngExpression } from 'leaflet';

export const getBoundsForPolylines = (polyline: Readonly<IMapTrack[]>) => {
  return polyline.slice().reduce((bounds, marker) => {
    const latLonFromTo = marker.nativeCoords;

    const latLonFrom = latLonFromTo?.from;
    const latLonTo = latLonFromTo?.to;

    if (latLonFrom) {
      const latFrom = latLonFrom?.lat;
      const lonFrom = latLonFrom?.lon;
      bounds.push([latFrom, lonFrom]);
    }
    if (latLonTo) {
      const latTo = latLonTo?.lat;
      const lonTo = latLonTo?.lon;
      bounds.push([latTo, lonTo]);
    }
    return bounds;
  }, [] as LatLngExpression[]);
};
