import { createSelector } from 'reselect';
import { validationDataSelector, validationStatusSelector } from '../../plan';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { fetchSelector } from '@modules/planning/children/by-date/selectors/fetching';

export const validationSummaryInfoSelector = createSelector(
  validationStatusSelector,
  fetchSelector,
  validationDataSelector,
  activePlanIDSelector,
  (status, isFetching, validateData, activePlanID) => {
    const validationData = activePlanID && validateData?.[activePlanID]?.CountAll;
    return { validationData, activePlanID, isFetching, status };
  }
);
