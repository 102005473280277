import { ColumnTable, TableColumnName } from '@common/types';
import { compareAsString } from '@common/utils';
import { depot2table } from '@common/models/depot/decorator';
import { DEPOT_TABLE_CONFIG } from '@common/models/depot/config';

export const canEditDepot = (
  record: ReturnType<typeof depot2table>[number],
  dataIndex: ColumnTable<ReturnType<typeof depot2table>[number]>['dataIndex']
) => {
  switch (dataIndex) {
    case TableColumnName.WorktimeLoadStart:
    case TableColumnName.WorktimeUnloadStart:
    case TableColumnName.WorktimeLoadEnd:
    case TableColumnName.WorktimeUnloadEnd:
      return Boolean(record.WorktimeID);
    default:
      return Boolean(DEPOT_TABLE_CONFIG.columns.find((column) => compareAsString(column.dataIndex, dataIndex))?.editable);
  }
};
