import { exchangeVehiclesRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';

import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';

function* exchangeVehiclesWorker(
  action: PayloadAction<{
    sourceVehicleID: number;
    targetVehicleID: number;
    planID: number;
  }>
) {
  const { request, success, failure } = exchangeVehiclesRoutine;
  const { sourceVehicleID, targetVehicleID, planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data: message }
    } = yield call(PlanningAPI.exchangeVehicles, { sourceVehicleID, targetVehicleID, planID });
    const {
      data: { data: routeTotal }
    } = yield call(PlanningAPI.getTotal, {
      planID,
      vehicleID: [sourceVehicleID, targetVehicleID],
      dictionary: Dictionary.Route
    });

    yield put(
      success({
        message,
        routeTotal
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* exchangeVehiclesWatcher() {
  yield takeLatest(exchangeVehiclesRoutine.trigger, exchangeVehiclesWorker);
}
