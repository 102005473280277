import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { deleteTripDocumentRoutine } from '@modules/orders-and-trips/actions';
import { TripDocument } from '@modules/orders-and-trips/types/trip';
import { onFailure } from '@common/utils/helpers/reducers';
import { isEmpty } from 'lodash';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { compareAsString } from '@common/utils';

export const deleteTripDocumentsReducer = createReducer(initialState, {
  [deleteTripDocumentRoutine.REQUEST]: (state: InitialState) => {
    state.tripDocuments.isFetching = true;
    return state;
  },
  [deleteTripDocumentRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      tripID: string;
      documentID: number;
    }>
  ) => {
    const { tripID, documentID } = action.payload;
    const currentTripDocuments = state.tripDocuments?.documents?.[tripID];
    const filteredDocuments = !isEmpty(currentTripDocuments)
      ? currentTripDocuments?.filter((document: TripDocument) => !compareAsString(document?.id, documentID))
      : EMPTY_ARRAY_DATA;
    state.tripDocuments.documents = { ...state.tripDocuments.documents, [tripID]: filteredDocuments };
    return state;
  },
  [deleteTripDocumentRoutine.FAILURE]: onFailure,
  [deleteTripDocumentRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripDocuments.isFetching = false;
    return state;
  }
});

