import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import { catalogMultipleUpdateRoutine } from '@modules/catalog/actions';
import { Dictionaries } from '@modules/catalog/types/catalog';
import initialState from '@modules/catalog/store';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';
import { replaceUpdatedItem } from '@common/utils/helpers/array/replace-updated-item';

const multipleUpdateDictionaryReducer = createReducer(initialState, {
  [catalogMultipleUpdateRoutine.REQUEST]: onRequest,
  [catalogMultipleUpdateRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: Array<Dictionaries>;
      dictionary: Dictionary;
    }>
  ) => {
    let { data, dictionary } = action.payload;

    data.forEach((updatedItem) => {
      state[dictionary].data = replaceUpdatedItem(updatedItem, state[dictionary].data);
    });

    return state;
  },
  [catalogMultipleUpdateRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogMultipleUpdateRoutine.FULFILL]: onFulfill
});

export default multipleUpdateDictionaryReducer;
