import { ILSMonitoringDashboardChart } from '../components/dashboard/dasboard-table-chart';
import { ILSMonitoringDashboardGraphContainer } from '../containers/dashboard/dashboard-table-graph';
import { ILSMonitoringDashboardTableMetrics } from '../components/dashboard/dashboard-table-metrics';
import { DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../constants';
import {
  ILSMonitoringBrokenSequence,
  ILSMonitoringFuelDrain,
  ILSMonitoringOnLate,
  ILSMonitoringOnTime,
  ILSMonitoringUndelivered,
  ILSMonitoringViolations
} from '../tables/components';
import { DashboardTables } from '../types';

// Классы таблиц
export enum TableClasses {
  Small = 'small', // маленькие
  Medium = 'medium', // средние
  Large = 'large' // большие
}

// Компоненты таблиц - название, подкомпонент, класс таблицы, сама таблица и тайтл:
export const dashboardTableComponents = {
  [DashboardTablesObjMetrics.OnLate]: {
    headSubComponent: ILSMonitoringDashboardChart,
    tableClass: TableClasses.Small,
    table: ILSMonitoringOnLate,
    title: 'Доставлено с опозданием'
  },
  [DashboardTablesObjMetrics.OnTime]: {
    headSubComponent: undefined,
    tableClass: TableClasses.Large,
    table: ILSMonitoringOnTime,
    title: 'Доставлено в срок'
  },
  [DashboardTablesObjMetrics.FuelDrain]: {
    headSubComponent: ILSMonitoringDashboardGraphContainer,
    tableClass: TableClasses.Small,
    table: ILSMonitoringFuelDrain,
    title: 'Слив топлива'
  },
  [DashboardTablesObjMetrics.Undelivered]: {
    headSubComponent: ILSMonitoringDashboardChart,
    tableClass: TableClasses.Small,
    table: ILSMonitoringUndelivered,
    title: 'Не доставлено'
  },
  [DashboardTablesObjMetrics.BrokenSequence]: {
    headSubComponent: ILSMonitoringDashboardChart,
    tableClass: TableClasses.Small,
    table: ILSMonitoringBrokenSequence,
    title: 'Нарушений последовательности'
  },
  [DashboardTablesObjMetrics.Violations]: {
    headSubComponent: ILSMonitoringDashboardTableMetrics,
    tableClass: TableClasses.Medium,
    table: ILSMonitoringViolations,
    title: 'Нарушение БДД'
  }
};

// Название табов - сама переменная и её заголовок:
export const dashboardTabs: {
  [K in DashboardTables]: {
    subTitle: string;
  };
} = {
  [DashboardTablesNumberMetrics.Total]: {
    subTitle: 'Всего точек'
  },
  [DashboardTablesNumberMetrics.Served]: {
    subTitle: 'Обслужено точек'
  },
  [DashboardTablesObjMetrics.OnTime]: {
    subTitle: 'Доставлено в срок'
  },
  [DashboardTablesObjMetrics.OnLate]: {
    subTitle: 'Доставлено с опозданием'
  },
  [DashboardTablesObjMetrics.Undelivered]: {
    subTitle: 'Не доставлено'
  },
  [DashboardTablesObjMetrics.BrokenSequence]: {
    subTitle: 'Нарушений последовательности'
  },
  [DashboardTablesObjMetrics.Violations]: {
    subTitle: 'Нарушение БДД'
  },
  [DashboardTablesObjMetrics.FuelDrain]: {
    subTitle: 'Слив топлива'
  }
};
