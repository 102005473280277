import { changeDriverInRouteRoutine } from '@modules/planning/children/by-date/actions';
import { RouteRecord } from '@modules/planning/children/by-date/types/record';
import { useDispatch, useSelector } from 'react-redux';
import { projectDriverSelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary, Driver } from '@common/types';
import { getDriversByTransportCompany } from '@modules/planning/children/by-date/helpers/get-drivers-by-transport-company';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { useEffect } from 'react';

export type DriverFromSubMenu = {
  driverID: Driver['ID'];
  name: Driver['Name'];
};

export const useDriverActions = (record: RouteRecord) => {
  const dispatch = useDispatch();
  const drivers = useSelector(projectDriverSelector);
  const { catalogLoad } = useCatalog();

  useEffect(() => {
    catalogLoad(Dictionary.Driver);
  }, []);

  const transportCompanyID = record.Vehicle.TransportCompanyID;

  const driversSubMenu = getDriversByTransportCompany({
    drivers,
    transportCompanyID
  });

  const onChangeDriver = ({ driverID }: { driverID: number }) => {
    dispatch(changeDriverInRouteRoutine({ planID: record?.PlanID, vehicleID: record.ID, driverID }));
  };

  return { onChangeDriver, driversSubMenu };
};
