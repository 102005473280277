import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { getTrackDataRoutine } from '../actions';
import { GetTrackRequest } from '@common/types/dictionaries/monitoring';

// Получает треки
function* getTrackWorker(action: PayloadAction<GetTrackRequest>) {
  const { request, success, failure, fulfill } = getTrackDataRoutine;
  const requestParams = action.payload;

  try {
    yield put(request(requestParams));

    const { data } = yield call(MonitoringAPI.getTrack, requestParams);

    yield put(success({ ...requestParams, ...data }));
  } catch (error) {
    console.error(error);
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringGetTrackWatcher() {
  yield takeLatest(getTrackDataRoutine.trigger, getTrackWorker);
}
