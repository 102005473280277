import { FC } from 'react';
import { proposalSortOptionsList } from '@modules/orders-and-trips/constants/fake-pre-cost';
import { FakePreCostProposalSortOption, FakePreCostSortUnit } from '@modules/orders-and-trips/types/fake-pre-cost';
import { ProposalFilterButton } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-filter-button';

interface IProps {
  activeSortOption: FakePreCostProposalSortOption;
  sortProposals(sortUnit: FakePreCostSortUnit): () => void;
}

export const ProposalFilter: FC<IProps> = ({ activeSortOption, sortProposals }) => {
  return (
    <div className={'fake-pre-cost__card-filters'}>
      {proposalSortOptionsList.map((sortUnit) => (
        <ProposalFilterButton sortUnit={sortUnit} activeSortOption={activeSortOption} sortProposals={sortProposals} key={sortUnit} />
      ))}
    </div>
  );
};
