import { ILSButton } from '@common/components';
import { FC } from 'react';

interface IProps {
  disabled: boolean;
  onClick: () => void;
}

// Компонент кнопки «Отметить всё как прочитанное»
export const ILSDashboardViewedButtonComponent: FC<IProps> = ({ disabled, onClick }) => {
  return (
    <div className="ils-monitoring-viewed-button">
      <ILSButton title="Отметить все как прочитанное" type="primary" onClick={onClick} disabled={disabled}>
        Отметить все как прочитанное
      </ILSButton>
    </div>
  );
};
