import { IndexedArray } from '@common/types';
import {
  AnalyticsBoardModel,
  AnalyticsBoardSetModel,
  AnalyticsWidget,
  CreateBoardParams,
  GetWidgetDataRequest,
  SetBoardWidgetBatchItem,
  SetBoardWidgetBatchRequest,
  WidgetType,
  WidgetUpdateMethod
} from '@common/types/dictionaries/analytics';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  analyticsPlanningActions,
  commonRoutine,
  deleteBoardRoutine,
  dropBoardWidgetRoutine,
  markBoardWidget,
  ModuleActionType,
  setBoardWidgetBatch,
  widgetCatalogRoutine
} from '../actions';
import ILSAnalyticsPlanningComponent from '../components';
import { AnalyticsPlanningContext } from '../context';
import { boardSetsSelector, boardsSelector, widgetCatalogSelector, widgetsSelector } from '../selectors';
import { AnalyticsPlanningContextType } from '../types/context';
import ILSWidgetWindow from '@modules/analytics/common/components/board/widget-window';

import ILSWidgetCatalogComponent from '@modules/analytics/common/components/widget-catalog/catalog';
import { DEFAULT_BOARD_TITLE } from '@modules/analytics/tender/children/tender-analytics/constants';
import { getDeletedWidgets } from '@modules/analytics/common/utils/get-deleted-widgets';
import { AnalyticsDraggableProvider } from '@modules/analytics/common/context/draggable-context';
import { DEFAULT_WIDGET_TEXT } from '@modules/analytics/common/constants';

const ILSAnalyticsPlanningContainer = () => {
  const dispatch = useAppDispatch();
  const boardSets = useAppSelector(boardSetsSelector);
  const boards = useAppSelector(boardsSelector);
  const widgetCatalog = useAppSelector(widgetCatalogSelector);
  const widgets = useAppSelector(widgetsSelector);
  const account = useAppSelector((store) => store.Auth.user.Account);

  const [showBoardWindow, setShowBoardWindow] = useState<boolean>(false);
  const [editSet, setEditSet] = useState<boolean>(false);
  const [editBoardList, setEditBoardList] = useState<boolean>(false);

  useEffect(() => {
    dispatch(analyticsPlanningActions.analyticsCommonGetBoardSetsRoutine({ role: account.AccountRole[0] }));
  }, []);

  useEffect(() => {
    showBoardWindow && dispatch(widgetCatalogRoutine());
  }, [showBoardWindow]);

  const handleSelectBoardSet = (item: AnalyticsBoardSetModel) => {
    dispatch(analyticsPlanningActions.analyticsCommonSelectBoardSetRoutine(item));
  };

  const handleSelectBoard = (item: AnalyticsBoardModel) => {
    dispatch(analyticsPlanningActions.analyticsCommonSelectBoardRoutine(item));
  };
  const handleEditBoard = (item?: AnalyticsBoardModel) => {
    setShowBoardWindow(!showBoardWindow);
  };
  const handleCreateBoard = (params?: CreateBoardParams) => {
    const boardIndex = Object.values(boards.data).filter((x) => x.Name?.includes(DEFAULT_BOARD_TITLE)).length;
    const newName = boardIndex === 0 ? DEFAULT_BOARD_TITLE : `${DEFAULT_BOARD_TITLE} (${boardIndex})`;

    dispatch(
      commonRoutine({
        data: [{ BoardSetID: params?.BoardSetID || boardSets.selectedBoardSet, values: { Name: newName, ...params } }],
        method: 'boardCreate',
        routine: ModuleActionType.Create_Board,
        isIndexed: true
      })
    );
  };
  const handleCopyBoard = (ID: number) => {
    dispatch(
      commonRoutine({
        data: [{ ID }],
        method: 'boardCopy',
        routine: ModuleActionType.Copy_Board,
        isIndexed: true
      })
    );
  };

  const handleDeleteBoard = (ID: number | string) => {
    dispatch(deleteBoardRoutine({ ID }));
  };
  const handleUpdateBoard = (params: AnalyticsBoardModel | AnalyticsBoardSetModel) => {
    dispatch(
      commonRoutine({
        data: [{ ...params }],
        method: 'boardUpdate',
        routine: ModuleActionType.Update_Board,
        isIndexed: true
      })
    );
  };

  const handleEditSet = (value: boolean) => {
    setEditSet(value);
  };

  const handleEditBoardList = (value: boolean) => {
    setEditBoardList(value);
  };

  const handleSetParams = (params: IndexedArray<{ name: string; value: string | number | undefined }>) => {
    dispatch(analyticsPlanningActions.analyticsCommonSetWidgetParamsRoutine(params));
  };

  const handleGetWidgetData = (args: { id: string | number; params: any }) => {
    const requestParams: GetWidgetDataRequest = { ID: args.id.toString(), ...args.params };
    dispatch(analyticsPlanningActions.analyticsGetWidgetDataRoutine(requestParams));
  };

  const contextValue: AnalyticsPlanningContextType = {
    handleEditBoardList,
    handleSelectBoardSet,
    handleSelectBoard,
    handleEditBoard,
    handleEditSet,
    handleCreateBoard,
    handleCopyBoard,
    handleDeleteBoard,
    handleUpdateBoard,
    handleDisableBoard: handleUpdateBoard,
    handleGetWidgetData,
    handleSetParams,
    editSet,
    editBoardList
  };

  const handleCloseEditBoard = () => {
    setShowBoardWindow(false);
  };

  const boardSetTitle = useMemo(() => {
    return boardSets?.selectedBoardSet ? boardSets?.data[boardSets.selectedBoardSet]?.Name : null;
  }, [boardSets]);

  const board = useMemo(() => {
    return boards?.selectedBoard ? boards?.data[boards.selectedBoard] : null;
  }, [boards]);

  const deletedWidgets: IndexedArray<AnalyticsWidget> = useMemo(() => {
    return getDeletedWidgets(widgets, true);
  }, [widgets]);

  const onSaveWidgets = (widgets: IndexedArray<AnalyticsWidget>) => {
    const widgetsToDelete = Object.values(deletedWidgets).reduce((acc, widget) => {
      if (!widget.isNew) acc.push({ ID: widget.ID, Values: {}, Method: WidgetUpdateMethod.delete });
      return acc;
    }, [] as Array<SetBoardWidgetBatchItem>);

    const widgetsToUpsert = Object.values(widgets)
      .filter((x) => !x.isDeleted)
      .map((widget) => {
        return {
          ...(!widget.isNew && { ID: widget.ID }),
          Values: {
            ...(!widget.isNew && { ID: widget.ID }),
            BoardID: widget.BoardID,
            Top: widget.Position?.Top,
            Left: widget.Position?.Left,
            Width: widget.Size?.Width,
            Name: widget.Name,
            Height: widget.Size?.Height,
            ClassName: widget.ClassName,
            Params: widget.Params,
            Type: widget.Type,
            Text: widget.Text || (widget.Type === WidgetType.text ? DEFAULT_WIDGET_TEXT : '')
          },
          Method: WidgetUpdateMethod.set
        };
      });
    const changedWidgets = [...widgetsToDelete, ...widgetsToUpsert];
    if (changedWidgets.length > 0) {
      const params: SetBoardWidgetBatchRequest = { Widgets: changedWidgets, ...(board && { BoardID: board.ID }) };
      dispatch(setBoardWidgetBatch(params));
    }

    dispatch(analyticsPlanningActions.analyticsUpdateWidgetRoutine(widgets));
  };

  const onCancelWidgetsUpdate = () => {
    dispatch(analyticsPlanningActions.analyticsCommonCancelWidgetUpdatesRoutine());
  };
  const dropWidgetOnBoard = (widget: AnalyticsWidget) => {
    dispatch(
      dropBoardWidgetRoutine({
        widget
      })
    );
  };

  const handleDeleteBoardWidget = (widget: AnalyticsWidget) => {
    dispatch(markBoardWidget({ widget }));
  };

  return (
    <AnalyticsPlanningContext.Provider value={contextValue}>
      <AnalyticsDraggableProvider>
        <ILSAnalyticsPlanningComponent title={boardSetTitle || '...'} />
        {showBoardWindow && <ILSWidgetCatalogComponent loading={widgetCatalog?.isFetching} data={widgetCatalog?.data} />}
        {board && (
          <ILSWidgetWindow
            board={board}
            visible={showBoardWindow}
            onClose={handleCloseEditBoard}
            widgets={widgets}
            onSaveWidgets={onSaveWidgets}
            onCancelWidgetsUpdate={onCancelWidgetsUpdate}
            deleteBoardWidget={handleDeleteBoardWidget}
            dropWidgetOnBoard={dropWidgetOnBoard}
          />
        )}
      </AnalyticsDraggableProvider>
    </AnalyticsPlanningContext.Provider>
  );
};

export default ILSAnalyticsPlanningContainer;
