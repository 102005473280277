import { ColumnTable, TableColumnName, TableRecord } from '@common/types';
import { ColumnsType } from 'antd/lib/table';
import { RefObject, useEffect, useState } from 'react';
import { getArrayElementWidthByNodeElements } from '@components/data-display/table/utils/helpers/get-array-element-width-by-node-element';
import { SelectedRowKeys } from '@common/types/components/table/table';
import { useMultipleRowControl } from './use-multiple-row-control';
import { isEmpty } from 'lodash';
import { handleMultipleCellData } from '@components/data-display/table/utils/helpers/cells/handle-multiple-cell-data';

type MultipleRowType = {
  mappedCols: ColumnsType<any> | undefined;
  tableRef: RefObject<HTMLDivElement> | null | undefined;
  selectedRowKeys: SelectedRowKeys;
};

const UNEDITABLE_COLUMNS = [TableColumnName.ID, TableColumnName.VehicleCode, TableColumnName.RegNumber, TableColumnName.RouteColor, TableColumnName.Color];

export const useMultipleRow = ({ tableRef, mappedCols, selectedRowKeys }: MultipleRowType) => {
  const [multipleCellData, setMultipleCellData] = useState<Record<TableColumnName, unknown> | {}>({});
  const { row, tableRow, tableTDs } = useMultipleRowControl(tableRef);

  useEffect(() => {
    if (!isEmpty(multipleCellData)) {
      setMultipleCellData({});
    }
  }, [selectedRowKeys?.length]);

  const cols = mappedCols?.map((col) => {
    const currentCol = col as ColumnTable<TableRecord>;
    if (UNEDITABLE_COLUMNS.includes(currentCol.dataIndex as TableColumnName)) {
      return {
        ...col,
        editable: false,
        active: false,
        ellipsis: false
      } as ColumnTable<TableRecord>;
    }
    return currentCol;
  });
  return {
    row,
    colWidths: getArrayElementWidthByNodeElements(tableTDs),
    tableRow,
    cols,
    multipleCellData,
    onMultipleCellData: handleMultipleCellData({ setMultipleCellData })
  };
};
