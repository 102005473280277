import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningValidateRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { Provider, ValidationData } from '@common/types';
import { validatedData } from '@modules/planning/decorators/validate-data';
import { deepEqual } from 'fast-equals';
import { PlanningValidationType } from '@modules/planning/types/planning';

export const planningValidateReducer = createReducer(initialState, {
  [planningValidateRoutine.REQUEST]: (
    state,
    action: PayloadAction<{
      update: boolean;
    }>
  ) => {
    const { update } = action.payload;
    if (!update) {
      state.isFetching = true;
    }
    return state;
  },
  [planningValidateRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      validationData: Array<ValidationData>;
      planID: string | number;
      planConfigID: string;
      providerName: Provider;
      validationType: PlanningValidationType;
    }>
  ) => {
    const { validationData, planID, planConfigID, providerName, validationType } = action.payload;
    const prevValidateData = state.validationData[planID];
    const nextValidateData = validatedData(validationData, planConfigID, providerName, validationType);
    if (!deepEqual(prevValidateData, nextValidateData)) {
      state.validationData[planID] = nextValidateData;
    }
    return state;
  },
  [planningValidateRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
