import { useEffect, useState } from 'react';

export const useForceUpdate = (watch: any) => {
  const [, setForceUpdate] = useState();

  useEffect(() => {
    setTimeout(() => {
      setForceUpdate(watch);
    }, 0);
  }, [watch]);
};
