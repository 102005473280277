import { Dictionary, ReferenceTablesData, TableColumnName } from '@common/types';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { createSelector } from 'reselect';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';

export const restrictionTableOptionsSelector = createSelector(
  catalogDictionaryDataSelector(Dictionary.RestrictionGroup),
  (RestrictionGroup) => {
    const refData: ReferenceTablesData = {
      [TableColumnName.RestrictionGroupID]: {
        options: RestrictionGroup,
        dictionary: Dictionary.RestrictionGroup,
        fieldNames: DEFAULT_FIELD_NAMES
      }
    };
    return { refData };
  }
);
