import { DragEvent, MouseEvent } from 'react';
import { AxiosResponse } from 'axios';
import { Adding, CellRecord, ColumnOption, ColumnOptionFunction, ShowDeleted, TableRecord } from './common';
import { Dictionary, Key, TableColumnName } from '@common/types';
import { Keys } from '@common/types/general';
import { ColumnTable } from './column';
import { UploadChangeParam } from 'antd/es/upload';
import { CascaderProps, SelectProps } from 'antd/es';

export type ITablePropsFunction<R> = Partial<{
  handleUpdate: any;

  handleDownload(selectedRowKeys: Array<Key>): void;

  dictionaryLoad(dictionary: Dictionary): void;

  onCanEdit(record: R, dataIndex: ColumnTable<R>['dataIndex']): boolean;

  handleSelectedElements?(records: Array<R>, keys?: Keys): void;

  handleChangeDataSource?(dataSource?: Array<R>): void;

  onSelectRows(newSelectRows: Keys, selectedRows?: TableRecord[], tableName?: string): Keys | TableRecord[];

  onSelectRowsWithRecord(data: OnSelectWithRecordNAllRecordParams): void;

  handleFocusRow?({ key }: { key: Key }): void;

  handleSave(
    record: R,
    dataIndex: ColumnTable<R>['dataIndex'],
    value: any,
    rowIndex?: number | undefined,
    dictionary?: Dictionary,
    cellRecord?: CellRecord | CellRecord[]
  ): void;

  handleDelete(ids: Keys | null, records: Array<R>, _b?: any, inUseData?: {}): void | undefined; //?

  handleUpload(props: UploadChangeParam | File): void;

  handleRestore(ids: Keys | null, records?: R[], rowIndex?: string | TableColumnName | number): void | undefined;

  handleCreate(newRow: Adding): void;

  handleReload(selectedRowKeys: Keys, showDeleted: ShowDeleted): void;

  handleCheckCoords(ids: Keys): void;

  handleCheckDelete(ids?: Keys): Promise<
    | AxiosResponse<{
        data: Record<string, Partial<Record<Dictionary, Keys>>>;
      }>
    | undefined
  >;
  onResizeStart(e: MouseEvent, column: ColumnTable<R>, dictionary: Dictionary | undefined): void;

  handleOpen(_: any, record: R): void;

  handleBindTimeZone(keys: Keys): void;

  onContextOpen(...args: any): void;

  onTableRowClick(record: R, expand?: boolean, ctrl?: boolean, tableName?: string): void;

  onTableRowDoubleClick(record: R, expand?: boolean, ctrl?: boolean): void;

  cellClassName(record: R, rowIndex: number, dataIndex: ColumnTable<R>['dataIndex']): string;

  onCopy({ dictionary, rows }: { dictionary: Dictionary; rows: Keys }): void;

  setFocusRow(record: R): void;

  rowClass(record: R): string;

  handleOpenExportModal(dictionary: Dictionary | null): void;
}>;

export interface DragHandler<R> {
  onDragStart: (record: R) => ((e: DragEvent, record: R, key: string | number) => void) | undefined;
  onDrop: (record: R) => ((e: DragEvent, record: R, key: string | number) => void) | undefined;
  onHoverRecord: (record: R) => ((e: MouseEvent, record: R, key: string | number) => { ID: number | undefined }) | undefined;
  onDragEndDropCallback: (selectRecord: R, targetRecord: R) => void | undefined;
  onDragEnd: (record: R) => ((e: DragEvent) => void) | undefined;
  draggable: (record: R) => boolean;
}

export type GetColumnOptionsParameters = {
  variants?: ColumnOption;
  fieldNames?: SelectProps['fieldNames'] | CascaderProps<any>['fieldNames'];
  needSortByLabelField: ColumnTable<TableRecord>['needSortByLabelField'];
};

export type GetCellOptionsParameters = {
  getOptions?: ColumnOptionFunction;
  record: TableRecord;
  fieldNames?: SelectProps['fieldNames'] | CascaderProps<any>['fieldNames'];
  needSortByLabelField: ColumnTable<TableRecord>['needSortByLabelField'];
};

export enum RowSelectionType {
  All = 'All',
  Single = 'Single'
}

export type OnSelectWithRecordNAllRecordParams = {
  record: TableRecord | null;
  selected: boolean;
  selectedRows: TableRecord[];
  nativeEvent: Event | null;
  tableName: string | undefined;
  changeRows: TableRecord[] | null;
  rowSelectionType: RowSelectionType;
};
