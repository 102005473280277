import { call, put, takeLatest } from 'redux-saga/effects';
import { recoveryActions, recoveryRequestActions } from '@modules/current-user/actions';
import { Auth } from '../api/auth';
import { RecoveryAction } from '../types/saga';
import history from '@common/utils/history';
import { ModulePath } from '@common/types';

function* recoveryRequestWorker(action: RecoveryAction) {
  const { email, link } = action.payload;
  const { request, success, failure, fulfill } = recoveryRequestActions;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call<any>(Auth.recoveryRequest, { email, link });

    yield put(success({ message: data }));
    yield call(history.push, ModulePath.Login);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* recoveryRequestWatcher() {
  yield takeLatest(recoveryRequestActions.trigger, recoveryRequestWorker);
}

function* recoveryWorker(action: RecoveryAction) {
  const { token } = action.payload;
  const { request, success, failure, fulfill } = recoveryActions;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(Auth.recovery, { token });
    yield put(success({ message: data }));
    yield call(history.push, ModulePath.Login);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* recoveryWatcher() {
  yield takeLatest(recoveryActions.trigger, recoveryWorker);
}
