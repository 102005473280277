import './styles.less';
import { FC } from 'react';
import { Slider } from 'antd';
import { IILSTitleLineSeriesComponentProps } from '@common/types/components/table/cell';
import { preventDefaultStopPropagationForHandler } from '@common/utils/helpers/prevent-default-stop-propagation-for-handler';

export const ILSTitleLineSeriesComponent: FC<IILSTitleLineSeriesComponentProps> = ({ rangeLeft, rangeRight, onChange, isDisabled }) => {
  return (
    <div className="ils-title-line-series" onClick={preventDefaultStopPropagationForHandler}>
      <Slider
        className="ils-title-line-series-slider"
        range={{ draggableTrack: true }}
        defaultValue={[rangeLeft, rangeRight]}
        value={[rangeLeft, rangeRight]}
        onChange={onChange}
        tipFormatter={null}
        disabled={isDisabled}
      />
    </div>
  );
};
