import { ILSModal } from '@common/components';
import { useDispatch, useSelector } from 'react-redux';
import { planningChangeValidationStatusRoutine } from '@modules/planning/children/by-date/actions';
import { FC, useEffect } from 'react';
import { validationSummaryInfoSelector } from '@modules/planning/children/by-date/selectors/components/validation/validation-summary-info';
import { useModal } from '@common/hooks/components/use-modal';
import { ValidationStatus } from '@modules/planning/children/by-date/types/validate';
import { PlannerPlanningValidationSummaryComponent } from '@modules/planning/children/by-date/components/validate/validation-summary';

interface IProps {
  onStartPlanning: () => void;
}

export const ValidateSummaryInfo: FC<IProps> = ({ onStartPlanning }) => {
  const dispatch = useDispatch();
  const { status, isFetching, validationData } = useSelector(validationSummaryInfoSelector);
  const { visible, handleClose, handleOpen } = useModal(false);

  const handleCancel = () => {
    handleClose();
    dispatch(planningChangeValidationStatusRoutine.success({ status: ValidationStatus.Stop }));
  };

  const handleOk = () => {
    onStartPlanning();
    handleCancel();
  };

  useEffect(() => {
    if (status === ValidationStatus.Success) {
      handleOpen();
    }
  }, [status]);

  return (
    <ILSModal
      title="Результаты валидации изменились:"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={'Продолжить планирование'}
      cancelText="Остановить"
      className="planning-validation-container"
    >
      <PlannerPlanningValidationSummaryComponent validationData={validationData} isFetching={isFetching} />
      <h3>Продолжить планирование?</h3>
    </ILSModal>
  );
};
