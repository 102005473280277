import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ILSSpin } from '@common/components';
import { monitoringGetProgressSelector } from '@modules/monitoring/children/dashboard/selectors/get-progress';
import { DashboardProgress } from '@modules/monitoring/children/dashboard/components/dashboard-progress';
import { ILSTooltip } from '@components/index';
import { calculatePercent } from '@modules/monitoring/children/dashboard/utils/calculate-percent';
import { DashboardProgressLabels } from '../../constants';

// Контейнер отвечающий за данные для компонента прогресса доставок
export const DashboardProgressContainer: FC = () => {
  const { data, isFetching } = useSelector(monitoringGetProgressSelector);
  const { MassDelivered = 0, MassTotal = 0, ClientDelivered = 0, ClientTotal = 0, PlanDistanceTotal = 0, FactDistanceTotal = 0 } = data;
  const massPercent = calculatePercent(MassDelivered, MassTotal);
  const clientsPercent = calculatePercent(ClientDelivered, ClientTotal);
  const tooltip = `Доставлено ${MassDelivered?.toFixed(2) ?? 0} из ${MassTotal?.toFixed(2) ?? 0} кг. Обслужено ${ClientDelivered ?? 0} из ${
    ClientTotal ?? 0
  } точек.`;
  const factDistance = FactDistanceTotal ? `${FactDistanceTotal.toFixed(2)} км` : '-';
  const planDistance = PlanDistanceTotal ? `${PlanDistanceTotal.toFixed(2)} км` : '-';
  const distanceTooltip = `План. пробег/Факт. пробег: ${planDistance}/${factDistance}`;
  return (
    <div className="monitoring-dashboard-progress">
      <ILSSpin spinning={isFetching}>
        <ILSTooltip title={tooltip} destroyTooltipOnHide>
          <DashboardProgress percent={massPercent} label={DashboardProgressLabels.mass} />
          <DashboardProgress percent={clientsPercent} label={DashboardProgressLabels.clients} />
        </ILSTooltip>
        <ILSTooltip title={distanceTooltip} destroyTooltipOnHide>
          <div>
            <span>Общий пробег План/Факт: </span>
            {planDistance} / {factDistance}
          </div>
        </ILSTooltip>
      </ILSSpin>
    </div>
  );
};
