import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { Middleware, Reducer } from 'redux';
import { GetDefaultMiddlewareOptions } from './types/redux';
import { persistCleanup } from '@core/persist/persist-cleanup';
import { STORAGE_ROOT_DB } from '@core/constants/store';
import { persistConfig } from '@core/persist-config';
import { StateKeyName } from '@core/constants/core';

const configureRootStore = (reducer: Reducer, middleware: Middleware[], options: GetDefaultMiddlewareOptions): EnhancedStore => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(options).concat(middleware)
  });
};
export const clearAppStore = async (clearAllFromIndexDB = false, onClearStore?: any) => {
  localStorage.clear();
  persistCleanup(STORAGE_ROOT_DB, persistConfig.key, clearAllFromIndexDB ? [] : [StateKeyName.Fixed], onClearStore);
  const controller = new AbortController();
  controller.abort();
  // NOTE: Used only for NPM: redux-persist-indexeddb-storage
  // const dbs = await window.indexedDB.databases();
  // dbs.forEach((db) => {
  //   db.name && db.name !== STORAGE_ROOT_DB && window.indexedDB.deleteDatabase(db.name);
  // });
};

export default configureRootStore;

