import { DepotTypeId, Dictionary, IMapTrack } from '@common/types';
import { PlanResponse } from '@common/types/dictionaries/plan';
import { compareAsString } from '@common/utils';

export const MapLines = (track: IMapTrack[], plan: PlanResponse) => {
  if (!track || !track.length || !plan) return [];
  const polylines: IMapTrack[] = track.map((polyline) => {
    const { vehicle, waypoint } = polyline;
    const orders = polyline.data?.order || null;
    const depot = polyline.data?.depot || null;

    const isHome: boolean = compareAsString(waypoint?.DepotTypeID, DepotTypeId.Storage);
    const dashed = polyline.number === 1 || isHome;

    return {
      ...polyline,
      data: {
        depot: depot ?? null,
        order: orders,
        isHome: isHome
      },
      dashed: dashed,
      clickData: {
        PlanID: plan.ID,
        WaypointID: waypoint && waypoint.WaypointID,
        TripID: polyline.TripID,
        VehicleID: vehicle && vehicle.ID,
        dictionary: Dictionary.Trip
      }
    };
  });
  return polylines;
};
