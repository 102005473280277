import { FC } from 'react';
import { ILSSkeleton, ILSTable } from '@common/components';
import { useAppSelector } from '@core/hooks';
import { getAllDocumentsSelector, getIsFetchingSelector } from '@modules/documents/selectors';
import { DocumentType } from '@modules/documents/types/document-type';
import { useDocumentIndexMenuItems } from '@modules/documents/helpers/use-document-index-menu-items';
import { addOptionsButton } from '@modules/documents/helpers/table-options-button';
import { withChildrenKeys } from '@modules/documents/helpers';
import { tableCustom } from '@core/containers/table-custom';
import { ColumnTable, TableRecord } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { DOCUMENTS_HEIGHT_WITHOUT_TABLE } from '@modules/documents/constants/documents-config';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  tableName: DocumentType;
  tableConfig: {
    columns: ColumnTable<TableRecord>[];
    hideClearButtons: boolean;
  };
}

const ILSDocumentsTable: FC<IProps> = ({ tableConfig, tableName, ...props }) => {
  const documents = useAppSelector(getAllDocumentsSelector);
  const isFetching = useAppSelector(getIsFetchingSelector);
  const getMenuItems = useDocumentIndexMenuItems();
  const documentsWithButtons = addOptionsButton(getMenuItems, documents, tableName);
  const dataSource = withChildrenKeys(documentsWithButtons);
  const allDocumentKeys = dataSource?.map(({ key }) => key);
  const { setFocusRow, focusRow } = useFocusRow({ key: EMPTY_STRING });

  const activeRow = focusRow.key ? focusRow : { key: dataSource?.[0]?.key ?? dataSource?.[0]?.ID };

  return (
    <ILSSkeleton loading={isFetching || !dataSource} active>
      <ILSTable
        config={tableConfig}
        {...props}
        scroll={{ y: `calc(100vh - ${DOCUMENTS_HEIGHT_WITHOUT_TABLE}px)` }}
        cellClassName={() => 'document-table-cell'}
        expandable={{ defaultExpandedRowKeys: allDocumentKeys }}
        className="editable-table"
        tableName={tableName}
        dataSource={dataSource}
        focusRow={activeRow}
        setFocusRow={setFocusRow}
      />
    </ILSSkeleton>
  );
};

export default tableCustom(ILSDocumentsTable, { columns: [] });
