import { FC } from 'react';
import { PlannerProjectsDatePicker } from '../components/date';
import { Project, Status } from '@common/types';
import { ILSPlanningActionButtonsContainer } from '@modules/planning/children/by-date/containers/result/action-buttons';
import { Moment } from 'moment';
import { ILSSpinCenter } from '@common/components';
import { ILSChooseProjectContainer } from '@modules/planning/containers/choose-project';
import { isEmpty } from 'lodash';

type IProps = {
  isFetching: boolean;
  isDateLoading: boolean;
  currentPickedDate?: Moment;
  status?: Status.Error | Status.Warning | undefined;
  visible: boolean;
  onMonthChange(date: Moment): void;
  projectList: Array<Project> | null;
  handleShow(): void;
  onDatePick(date: Moment): void;
  getProject(ID: number, setStatus?: boolean): void;
  hasContentForProject: boolean;
  onCloseModal?: (date: Moment) => void;
};

export const PlannerPlanningMenuComponent: FC<IProps> = ({
  getProject,
  isFetching,
  onDatePick,
  currentPickedDate,
  isDateLoading,
  handleShow,
  onMonthChange,
  status,
  projectList,
  visible,
  hasContentForProject,
  onCloseModal
}) => {
  if (isFetching) {
    return <ILSSpinCenter spinning={isFetching} />;
  }

  return (
    <div className="plan-date">
      <PlannerProjectsDatePicker
        onDatePick={onDatePick}
        currentPickedDate={currentPickedDate}
        isDateLoading={isDateLoading}
        onMonthChange={onMonthChange}
        status={status}
      />
      {hasContentForProject && <ILSPlanningActionButtonsContainer handleShow={handleShow} projectList={projectList} />}
      {!isDateLoading && !isEmpty(projectList) && (
        <ILSChooseProjectContainer
          currentPickedDate={currentPickedDate}
          projectList={projectList!}
          getProject={getProject}
          visible={visible}
          handleShow={handleShow}
          onCloseModal={onCloseModal}
        />
      )}
    </div>
  );
};
