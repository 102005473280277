import React, { FC } from 'react';
import { Chart, Legend, Line, Point, Tooltip } from 'bizcharts';
import { IChartAxisScale, LineShapeType, MonitoringGraphFuelDrainData } from '@modules/monitoring/types/graph';
import GraphCustomTooltip from '@modules/monitoring/components/graph/graph-custom-tooltip';

interface Props {
  graphData: MonitoringGraphFuelDrainData;
  useCurrentTime: boolean;
  shape?: LineShapeType;
  chartScale?: {
    x: IChartAxisScale;
    y: IChartAxisScale;
  };
}

/*
 * Компонент отображающий графики датчиков.
 * В подпапках (src\modules\monitoring\components\graph) содержатся
 * необходимые конфиги и константы.
 */

export const ILSGraphTable: FC<Props> = ({ chartScale, graphData, useCurrentTime = false, shape, ...props }) => {
  return (
    <Chart scale={chartScale} padding={[60, 50, 60, 40]} autoFit height={320} data={graphData} interactions={['element-active']}>
      <Point position="x*y" color="dataType" shape="circle" visible={false} />
      <Line
        shape={shape}
        position="x*y"
        color="dataType"
        animate={false} //NOTE Ставить отключенным, из-за быстрой смены graphData могут быть проблемы отрисовки
      />
      <Tooltip shared={true} region={null}>
        {(time, data) => {
          return <GraphCustomTooltip time={time} data={data} />;
        }}
      </Tooltip>
      <Legend position={'top-left'} layout={'horizontal'} offsetX={10} />
    </Chart>
  );
};
