import { OrderUploadFileType } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const order2Table = (data: OrderUploadFileType[]) => {
  return createArrayFromIndexedArray(data).map((order: OrderUploadFileType, index: number) => {
    const key = Boolean(order.OrderCode) ? order.OrderCode : index;
    return {
      ...order,
      fileIndex: index,
      key
    };
  });
};
