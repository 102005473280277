export enum PlannerAction {
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE = 'CREATE',
  GET_PROJECTS = 'GET_PROJECTS',
  SET_ACTIVE_PROJECT_ID = 'SET_ACTIVE_PROJECT_ID',
  PARAMS_SET = 'PARAMS_SET',
  PARAMS_GET = 'PARAMS_GET'
}

export const defaultStatuses: {
  [key in PlannerAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  CREATE_PROJECT: { loading: 'Создание проекта...', success: 'Проект создан' },
  CREATE: { loading: 'Создание плана...', success: 'План создан' },
  GET_PROJECTS: { loading: 'Поиск проектов...', success: '' },
  SET_ACTIVE_PROJECT_ID: { loading: 'Переключение активного проекта...', success: 'Активный проект перключен' },
  PARAMS_SET: { loading: 'Сохранение настроек планирования...', success: 'Настройки сохранены' },
  PARAMS_GET: { loading: 'Загрузка параметров планирования...', success: 'Параметы планирования загружены' }
};

