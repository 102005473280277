import { addCreatedPlanRoutine, planningGetPlanRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningAPI } from '@modules/planning/api';
import { Dictionary, Plan } from '@common/types';

function* addCreatedPlanWorker(
  action: PayloadAction<{
    plan: Plan;
  }>
) {
  const { request, failure, fulfill, success } = addCreatedPlanRoutine;
  const { plan } = action.payload;
  try {
    yield put(request({ planID: plan.ID }));
    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID: plan.ID, dictionary: Dictionary.Plan });
    yield put(
      success({
        plan,
        planTotal
      })
    );
    yield put(planningGetPlanRoutine({ ID: plan.ID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ planID: plan.ID }));
  }
}

export function* addCreatedPlanWatcher() {
  yield takeLatest(addCreatedPlanRoutine.trigger, addCreatedPlanWorker);
}
