import Modules from '@modules/index';
import { flatModules } from './modules';
import createSagaMiddleware, { Task } from 'redux-saga';
import { all } from 'redux-saga/effects';
import { TModulesObj, TSagasObj } from '@core/types/models';
import { userNotifyCloseWatcher, userNotifyWatcher } from '@core/sagas/user-notify';
import { stopSagasWatcher } from '@core/sagas/stop-sagas';
import { StateKeyName } from '@core/constants/core';

const getModuleSagas = (modules: TModulesObj, sagas: TSagasObj = {}): TSagasObj => {
  const flat = flatModules(modules);

  for (let i = 0; i < flat.length; i++) {
    const saga = (Array.isArray(flat[i].sagas) ? flat[i].sagas : [flat[i].sagas]) as Generator[];
    const moduleSagas: Generator[] = [];

    for (let j = 0; j < saga.length; j++) {
      moduleSagas.push(saga[j]);
    }

    if (moduleSagas.length) {
      if (!sagas[flat[i].MODULE!]) {
        sagas[flat[i].MODULE!] = moduleSagas;
      } else {
        sagas[flat[i].MODULE!] = sagas[flat[i].MODULE!].concat(moduleSagas);
      }
    }

    if (typeof flat[i].children === 'object' && Object.keys(flat[i].children!).length > 0) {
      getModuleSagas(flat[i].children!, sagas);
    }
  }

  return sagas;
};

const createSagaInjector = (runSaga: any, initialSagas: TSagasObj) => {
  const injectedSagas = {};
  const initialKeys = Object.keys(initialSagas);
  const activeTasks = {};

  return {
    run: () => {
      Object.entries(initialSagas).forEach(([key, sagas]) => {
        const saga = function* () {
          yield all(sagas);
        };

        const task = runSaga(saga);
        injectedSagas[key] = task;
        return task;
      });
    },

    injectSaga: (key: string, sagas: Generator[]) => {
      if (injectedSagas[key]) return injectedSagas[key];

      const saga = function* () {
        yield all(sagas);
      };

      const task = runSaga(saga);
      injectedSagas[key] = task;
      return task;
    },

    replaceSagas: (keys: string[]) => {
      if (!keys) return;

      const keysToRemove = Object.keys(injectedSagas).filter((key) => ![...initialKeys, ...keys].includes(key));

      keysToRemove.forEach((key) => {
        delete injectedSagas[key];
      });
    },
    //NOTE: Метод сохраняет рабочие таски саг
    setTask: (data: { taskName: string; task: Task }) => {
      const { taskName, task } = data ?? {};
      if (taskName && task) {
        activeTasks[taskName] = task;
      }
    },
    //NOTE: Метод получает рабочие таски саг
    getTask: (taskName?: string) => {
      if (taskName) {
        return {
          [taskName]: activeTasks[taskName]
        };
      }
      return activeTasks;
    },
    //NOTE: Метод удаляет таски саг
    removeTask: (taskName: string) => {
      delete activeTasks[taskName];
    }
  };
};

export const sagaMiddleware = createSagaMiddleware();

export const sagaInjector = (isRootSagas: boolean) => {
  const moduleSagas = getModuleSagas(Modules);
  const sagas: TSagasObj = {};

  if (isRootSagas) {
    sagas[StateKeyName.Auth] = moduleSagas.Auth;
    sagas[StateKeyName.Settings] = moduleSagas.Settings;
    sagas[StateKeyName.Notification] = [userNotifyWatcher(), userNotifyCloseWatcher()];
    sagas[StateKeyName.StopSagas] = [stopSagasWatcher()];
  } else {
    for (const key in moduleSagas) {
      sagas[key] = moduleSagas[key];
    }
  }

  return createSagaInjector(sagaMiddleware.run, sagas);
};

// @ts-ignore
export const getAllModuleSagas = (sagas: {}) => (typeof sagas === 'object' ? Object.values(sagas).map((saga) => saga()) : []);
