import { PAYMENT_BALANCE_HISTORY_CELL_TITLES } from '@common/feature/payment/constants/general';
import { PaymentBalanceHistoryCell } from '@common/feature/payment/components/payment-balance-history/cell/payment-balance-history-cell';

export const PaymentBalanceHistoryHeader = () => {
  return (
    <div className="payment-balance-history-header">
      {Object.values(PAYMENT_BALANCE_HISTORY_CELL_TITLES).map((cellTitle) => (
        <PaymentBalanceHistoryCell title={cellTitle} key={cellTitle} />
      ))}
    </div>
  );
};
