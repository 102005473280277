import { CellType, TableConfig } from '@common/types';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: '№',
      dataIndex: 'ID',
      key: 'ID',
      active: true,
      type: CellType.Input,

      ellipsis: true,
      sorting: 3,

      invisible: true
    },
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 70,

      sorting: 3
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 70,

      sorting: 3
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: true,
      type: CellType.Input,
      filtering: 'string',
      ellipsis: true,
      width: 70,

      sorting: 3
    },
    {
      title: 'Доставлено/всего',
      dataIndex: 'Delivered',
      key: 'Delivered',
      active: true,
      type: CellType.Input,
      //   filtering: 'array',
      ellipsis: true,
      width: 70,

      sorting: 3
    },
    {
      title: 'Трекер',
      dataIndex: 'Tracker',
      key: 'Tracker',
      active: true,
      type: CellType.Text,
      filtering: 'string',
      titleTooltip: `Номера привязанных к ТС трекеров за выбранный период.\n Если выбран текущий день - отображается активный на данный момент трекер`,
      ellipsis: true,
      width: 70,

      sorting: 3,
      //TODO why showRefButton if we already have refTable?
      //NOTE showRefButton работает вне зависимости от возможности редактирования
      showRefButton: true
    },
    {
      title: '',
      dataIndex: 'Deliveries',
      key: 'Deliveries',
      titleText: 'Доставки',
      active: true,
      type: CellType.LineSeries,
      width: 400,
      sorting: false,
      showSearch: false,
      lineSeriesProps: {
        size: 30,
        gap: 8
      }
    },
    {
      title: 'Статус рейса',
      dataIndex: 'Status',
      key: 'Status',
      active: true,
      type: CellType.Input,
      width: 150,

      ellipsis: true,
      sorting: 3
    }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: false,
  copying: false
};
