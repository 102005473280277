export type VehicleType = Record<
  string,
  {
    ID: number;
    Name: string;
    Alias: string;
    GroupID: number;
    Feature: null;
    CouplingMode: number;
    Subject: string;
    Icon: null;
  }
>;

//TODO Вынести в общее + тендерная
export enum VehicleLoadType {
  Up,
  Side,
  Back
}
//TODO Вынести в общее + тендерная
export enum VehicleLoadTypeLabel {
  Up = 'Верхняя',
  Side = 'Боковая',
  Back = 'Задняя'
}

export enum VehicleBodyNameEnum {
  Tent = 'Тент'
}

