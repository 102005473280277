import { ICellProps, PlacementType } from '@common/types/components/table/cell';
import { ILSTooltip } from '@components/index';
import { TooltipProps } from 'antd';
import { FC, useMemo } from 'react';

interface IProps extends Pick<ICellProps<any>, 'dataIndex' | 'record' | 'column'> {
  tooltip: TooltipProps;
  showCellTip: boolean;
  setShowCellTip: (show: boolean) => void;
}

export const ILSCellTooltip: FC<IProps> = ({ children, tooltip, dataIndex, record, showCellTip, column, setShowCellTip }) => {
  const recordTooltip = useMemo(
    () =>
      tooltip ?? (record?.[dataIndex + '_htmltip'] || record?.[dataIndex + '_tip'] || record?.[column?.tooltip?.field]) ? (
        //TODO refactor
        <div
          dangerouslySetInnerHTML={{
            __html: record[dataIndex + '_htmltip'] ?? record[dataIndex + '_tip'] ?? record?.[column?.tooltip?.field]
          }}
        />
      ) : null,
    [record]
  );

  return (
    <ILSTooltip
      mouseLeaveDelay={0}
      visible={showCellTip}
      onVisibleChange={(isOpening: boolean) => {
        setShowCellTip(isOpening);
      }}
      placement={PlacementType.top}
      title={tooltip ?? recordTooltip}
    >
      {children}
    </ILSTooltip>
  );
};
