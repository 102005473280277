import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { planningSetParamsRoutine } from '@modules/planning/actions';
import { PlannerParamsResponse } from '@modules/planning/types/params';
import { isEmpty } from 'lodash';
import { defaultPlannerParams } from '@modules/planning/constants/planner';

export const setPlanningParamsReducer = createReducer(initialState, {
  [planningSetParamsRoutine.REQUEST]: (state) => {
    return state;
  },
  [planningSetParamsRoutine.SUCCESS]: (state, action: PayloadAction<PlannerParamsResponse>) => {
    const { value } = action.payload;
    if (value) {
      const currentValue = Object.keys(value).reduce(
        (acc, key) => {
          if (isEmpty(value[key])) {
            acc[key] = {};
          } else {
            acc[key] = value[key];
          }
          return acc;
        },
        { ...defaultPlannerParams }
      );
      state.localConf = currentValue;
    } else {
      state.localConf = { ...defaultPlannerParams };
    }
    return state;
  },
  [planningSetParamsRoutine.FAILURE]: (state) => {
    return state;
  },
  [planningSetParamsRoutine.FULFILL]: (state) => {
    return state;
  }
});

