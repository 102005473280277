import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { getDocumentSetsRoutine, updateDealAttachRoutine } from '@modules/documents/children/templates/actions';
import { IUpdateData } from '@modules/documents/children/templates/types/api';

function* updateDealAttachWorker(action: PayloadAction<IUpdateData>) {
  try {
    yield call(DocumentTemplatesAPI.updateDealAttach, action.payload.data);
    yield put(getDocumentSetsRoutine());
    if (!action.payload.isChangeName) {
      ILSUserNotify(Notify.Success, 'Шаблон успешно добавлен в шаблон сделки');
    }
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* updateDealAttachWatcher() {
  yield takeEvery(updateDealAttachRoutine.trigger, updateDealAttachWorker);
}
