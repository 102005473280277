import { TPersistTransform } from '@core/persist/transforms';
import { MonitoringEventDetailsStore } from '@modules/monitoring/children/event-details/types/store';
import { initialState } from '@modules/monitoring/children/event-details/store';
import { MONITORING_EVENT_DETAILS_RESIZER } from '@modules/monitoring/children/event-details/constants/store';

export const persistTransformMonitoringDetailsStore: TPersistTransform<MonitoringEventDetailsStore> = {
  initialState,
  // transform state being persisted
  onPersist: (inboundState?: MonitoringEventDetailsStore) => {
    return {
      resizer: inboundState?.resizer ?? MONITORING_EVENT_DETAILS_RESIZER
    };
  }
};
