import { FC } from 'react';
import { ILSCol, ILSSwitch } from '@common/components';
import { ILSEmptyDataComponent } from '@modules/planning/children/data/components/view/empty-data';
import { useSwitch } from '@common/hooks/components/use-switch';
import { useSelector } from 'react-redux';
import { uploadedDataSelector, validateDataSelector } from '@modules/planning/children/data/selectors';
import ILSValidate from '@modules/data-validate/containers';
import { useAppSelector } from '@core/hooks';
import { PlannerDataSchemeCollapse } from '@modules/planning/children/data/containers/view/collapse-with-scheme';

interface IPlanningDataViewProps {
  validateOnImport: boolean;
}

export const PlannerDataView: FC<IPlanningDataViewProps> = ({ validateOnImport }) => {
  const { onSwitchChange, isOn } = useSwitch();
  const data = useSelector(uploadedDataSelector);
  const validateData = useAppSelector(validateDataSelector);

  return (
    <ILSCol className={'planning-data-view'}>
      {Array.isArray(data) ? (
        <ILSEmptyDataComponent />
      ) : (
        <>
          {validateData && validateOnImport && <ILSValidate data={data} validateData={validateData} />}
          <ILSSwitch
            checked={isOn}
            onChange={onSwitchChange}
            checkedChildren="Показать пустые столбцы"
            unCheckedChildren="Скрыть пустые столбцы"
          />
          <PlannerDataSchemeCollapse hideEmpty={isOn} />
        </>
      )}
    </ILSCol>
  );
};
