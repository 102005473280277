import { DateFormat, DateTimeFormat, ILS_MAIN_API_TIME_FORMAT, TimeFormat, TimeStamp } from '@common/types/general/date-time';
import moment, { Moment, MomentInput } from 'moment';

export const parseTimeFromServer = ({
  time,
  parseFormat
}: {
  time?: MomentInput | TimeStamp | null;
  parseFormat?: DateFormat | DateTimeFormat | TimeFormat | null;
}): Moment | undefined => {
  const output = moment(time, parseFormat ?? ILS_MAIN_API_TIME_FORMAT);
  return output.isValid() ? output : undefined;
};
