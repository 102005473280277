import { Dispatch, FC, SetStateAction } from 'react';
import { ILSSVG } from '@components/custom/icons/svg';
import { ILSPlanningUploadModalButtons } from '@modules/planning/children/data/components/upload/modal/buttons';
import { ILSModal } from '@common/components';
import { uploadRoutine } from '@modules/planning/children/data/actions';
import { useDispatch } from 'react-redux';
import { UploadFile } from 'antd/es/upload/interface';
import { SchemeDictionary } from '@modules/planning/children/scheme-editor/types';

interface IProps {
  isFetching: boolean;
  visible: boolean;
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
  file: File | null;
  type: SchemeDictionary | null;
  handleShow(): void;
}

export const ILSPlanningDataModalConflict: FC<IProps> = ({ isFetching, visible, handleShow, setFileList, file, fileList, type }) => {
  const dispatch = useDispatch();

  const handleClick = (join: boolean = false) => {
    dispatch(uploadRoutine({ file, type, join }));
    handleShow();

    const lastFile = fileList.length - 1;

    if (join) {
      setFileList(fileList);
    } else {
      setFileList([fileList[lastFile]]);
    }
  };

  return (
    <ILSModal
      title={'Внимание!'}
      visible={visible}
      onOk={handleShow}
      onCancel={handleShow}
      cancelText="Отменить"
      footer={[<ILSPlanningUploadModalButtons handleClick={handleClick} isFetching={isFetching} />]}
    >
      <div className="flex-center-center" key="content">
        <div className="warning-icon" key="warning">
          <ILSSVG icon={'warning'} />
        </div>
        <div className="ml-5" key="text">
          <p>Данные уже загружены.</p>
          <p>Заменить или выполнить слияние с уже загруженными данными?</p>
        </div>
      </div>
    </ILSModal>
  );
};
