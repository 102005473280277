import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { ILSAccount } from './containers';
import { accountRootReducer } from './reducers';
import * as rootAccountSaga from './sagas';
import { getSRCIconSVG } from '@common/decorators/path';

export default defineModule({
  title: ModuleTitle.Account,
  path: ModulePath.Account,
  component: ILSAccount,
  reducer: accountRootReducer,
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG(ModulePath.Account, { iconModule: ModulePath.Sidebar }),
  sagas: getAllModuleSagas(rootAccountSaga),
  persist: false
});
