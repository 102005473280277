import { FC } from 'react';
import { EventDetailsTabProps } from '@modules/monitoring/children/event-details/types';
import { useAppSelector } from '@core/hooks';
import { activeVehicleIDSelector, vehicleSensorSelector } from '../../selectors';
import { DetailsSensorComponent } from '../../components/details/details-sensor';

/**
 * Контейнер для графиков детализации значений датчиков
 *
 */
export const DetailsSensorContainer: FC<EventDetailsTabProps> = ({ tab }) => {
  const vehicleSensor = useAppSelector(vehicleSensorSelector);
  const selectedVehicleID = useAppSelector(activeVehicleIDSelector);

  return <DetailsSensorComponent tab={tab} vehicleSensor={vehicleSensor} selectedVehicleID={selectedVehicleID} />;
};
