import { CellType, DateTimeFormat, TableColumnName, TableConfig } from '@common/types';

export const vehicleTrackerTableConfig: TableConfig = {
  columns: [
    {
      title: 'IMEI Трекера',
      dataIndex: TableColumnName.TrackerIMEI,
      key: TableColumnName.TrackerIMEI,
      active: true,
      type: CellType.SelectAPI,

      placeholder: 'Выберите трекер из списка',
      showSearch: true,
      width: 150,

      ellipsis: true,
      required: false,
      editable: true,
      allowClear: false,
      sorting: 3,

      showRefButton: true
    },
    {
      title: 'Дата начала',
      dataIndex: TableColumnName.From,
      key: TableColumnName.From,
      active: true,
      type: CellType.DateTime,
      format: DateTimeFormat.DDMMYYYYPeriodHHMM,
      dateParseFormat: DateTimeFormat.DDMMYYYYPeriodHHMM,
      width: 200,

      ellipsis: false,
      sorting: 3,

      allowClear: false,
      editable: true
    },
    {
      title: 'Дата окончания',
      dataIndex: TableColumnName.Till,
      key: TableColumnName.Till,
      active: true,
      type: CellType.DateTime,
      format: DateTimeFormat.DDMMYYYYPeriodHHMM,
      dateParseFormat: DateTimeFormat.DDMMYYYYPeriodHHMM,
      width: 200,

      ellipsis: false,
      sorting: 3,

      allowClear: false,
      editable: true
    },
    {
      title: 'Приоритет',
      dataIndex: 'Priority',
      key: 'Priority',
      active: true,
      type: CellType.Input,

      ellipsis: false,
      sorting: 3,

      editable: true
    }
  ],
  adding: {
    [TableColumnName.Till]: null,
    [TableColumnName.From]: null
  },
  deleting: true,
  reloading: true,
  selecting: false,
  copying: false
};
