import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { Contact } from '@common/types';

export const contact2table = (data: Array<Contact>) => {
  return createArrayFromIndexedArray(data).map((item, i) => {
    return {
      ...item,
      key: item.ID ?? 'row' + i
    };
  });
};
