import { call, put, takeLatest } from 'redux-saga/effects';
import { getExtraDataRoutine, getTripNOrderDataRoutine, showFormRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { FormType } from '@modules/orders-and-trips/types/general';
import { isEmpty } from 'lodash';

function* getTripNOrderDataWorker(
  action: PayloadAction<{
    ids: Array<string | number>;
    formType: FormType;
  }>
) {
  const { request, success, failure, fulfill } = getTripNOrderDataRoutine;
  try {
    yield put(request());
    const { ids, formType } = action.payload ?? {};
    let response = {} as {
      data: { data: [] };
    };
    if (!isEmpty(ids)) {
      yield put(getExtraDataRoutine.trigger());
      yield put(showFormRoutine({ formType }));
      response = yield call(OrdersNTripsApi.getTripNOrderData, { ids });
    }
    yield put(success({ data: response?.data?.data ?? [], formType }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripNOrderDataWatcher() {
  yield takeLatest(getTripNOrderDataRoutine.trigger, getTripNOrderDataWorker);
}

