import { put, takeLatest } from 'redux-saga/effects';
import { getArticleInfo, referenceRoutine } from '../actions';

function* referenceWorker(action: ReturnType<typeof referenceRoutine.trigger>) {
  const { cat1, cat2, cat3 } = action.payload;

  const { request, success, fulfill } = referenceRoutine;

  try {
    yield put(request({ isFetching: true }));

    const { level1, level2, level3 } = getArticleInfo(cat1, cat2!, cat3!);

    yield put(success({ level1: level1, level2: level2, level3: level3 }));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(fulfill());
  }
}

export function* referenceWatcher() {
  yield takeLatest(referenceRoutine.trigger, referenceWorker);
}
