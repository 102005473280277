import { createSelector } from 'reselect';
import { currentTenderLotSelector, currentTenderMyOfferSelector, currentTenderSelector } from '@modules/tender-platform/selectors';
import { auctionToForm } from '@modules/tender-platform/children/tender-search/decorators/auction-to-form';
import { tenderOfferToForm } from '@modules/tender-platform/children/tender-search/decorators/tender-offer-to-form';
import { tenderOfferVehiclesSelector } from '@modules/tender-platform/children/tender-search/selectors/vehicles-and-drivers';

export const tenderOfferInitialValuesFormSelector = createSelector(
  currentTenderSelector,
  currentTenderLotSelector,
  currentTenderMyOfferSelector,
  tenderOfferVehiclesSelector,
  (currentTender, tenderLot, tenderOffer, { VehicleID }) => {
    const initialValues = tenderOfferToForm(tenderLot, tenderOffer, VehicleID);
    return { initialValues };
  }
);

export const auctionInitialValuesFormSelector = createSelector(
  currentTenderSelector,
  currentTenderLotSelector,
  currentTenderMyOfferSelector,
  tenderOfferVehiclesSelector,
  (currentTender, tenderLot, tenderOffer, { VehicleID }) => {
    const { initialValues, info } = auctionToForm(currentTender, VehicleID, tenderLot, tenderOffer);
    return { initialValues, info };
  }
);
