import { Dictionary } from '@common/types';
import { DefaultPlannerConfigsResponse } from '@modules/planning/types/api';
import { BASE_CASCADER_FIELD_NAMES } from '@common/constants/options';
import { createArrayFromIndexedArray } from '@common/utils';
import { generateBaseFieldsNamesOptionsFromEnums } from '@components/decorators';

export const getConfigOptions = (configs: DefaultPlannerConfigsResponse | undefined) => {
  return {
    options: createArrayFromIndexedArray(configs).map(({ name: value, label, configs }) => {
      return {
        value,
        label,
        children: generateBaseFieldsNamesOptionsFromEnums(configs)
      };
    }),
    fieldNames: BASE_CASCADER_FIELD_NAMES,
    dictionary: Dictionary.PlanConfig
  };
};
