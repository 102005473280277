import { Dictionary } from '@common/types';
import { OrderCardContainer } from '@modules/orders-and-trips/containers/left/orders/order-card';
import { TripCardContainer } from '@modules/orders-and-trips/containers/left/trips/trip-card-container';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { DecoratedTrips } from '@modules/orders-and-trips/types/trip';
import { OrderItemProps, TripItemProps } from '@modules/orders-and-trips/types/virtual-list';

type RenderVirtualBoxItem = {
  listData: {
    [Dictionary.Order]?: DecoratedOrders[number];
    [Dictionary.Trip]?: DecoratedTrips[number];
  };
  listItemComponentProps: {
    [Dictionary.Order]?: OrderItemProps;
    [Dictionary.Trip]?: TripItemProps;
  };
  dictionary: Dictionary;
};

export function renderVirtualListItemComponent<T>({ listData, listItemComponentProps, dictionary }: RenderVirtualBoxItem) {
  const existComponentProps = Boolean(listData[dictionary] && listItemComponentProps[dictionary]);
  switch (true) {
    case Dictionary.Trip === dictionary && existComponentProps:
      return (
        <TripCardContainer
          key={[listData[Dictionary.Trip]?.ID].join('_')}
          trip={listData[Dictionary.Trip]}
          {...listItemComponentProps[dictionary]}
        />
      );
    case Dictionary.Order === dictionary && existComponentProps:
      return (
        <OrderCardContainer
          key={[listData[Dictionary.Order]?.ID].join('_')}
          order={listData[Dictionary.Order]}
          {...listItemComponentProps[dictionary]}
        />
      );
    default:
      return <></>;
  }
}

