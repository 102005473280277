import { IndexedArray } from '.';

//абстракции
export interface AbstractBoard {
  ID: string | number;
  Name: string | null;
  From: number | null;
  Till: number | null;
  Serial: number;
  Period?: string;
  Active: number;
  Widgets?: IndexedArray<AnalyticsWidget>;
  SubTitle?: string;
}

/**
 * Набор досок
 */
export type AnalyticsBoardSetModel = {
  ID: string | number;
  AccountID: string | number;
  Name: string | null;
  Description: string | null;
  Serial: number;
  Active: number;
  Category: string | null;
};

/**
 * Виджет каталога
 */

export type AnalyticsWidgetCatalogModel = {
  Name: string;
  Description: string;
  Type: string[];
  Serial: number;
  ClassName: string;
  Params?: IndexedArray<BoardWidgetParams>;
  Size: {
    Width: string | number;
    Height: string | number;
  };
  Category?: string;
};

/**
 * Доска аналитики
 */
export type AnalyticsBoardModel = AbstractBoard;

export type AnalyticsBoardSetResponse = {
  data: IndexedArray<AnalyticsBoardSetModel>;
};

export type BoardDefaults = {
  defaults: { boardId?: number; boardSetId?: number; params: IndexedArray<{ name: string; value: string | number | undefined }> };
};

export type GoToBoardParameters = { boardId: number | string; params: IndexedArray<{ name: string; value: string | number | undefined }> };

//виджеты

/**
 * типы виджетов
 */
export enum WidgetType {
  table = 'table',
  barGraphHorizontal = 'barGraphHorizontal',
  barGraphVertical = 'barGraphVertical',
  lineChart = 'lineChart',
  formData = 'formData',
  info = 'info',
  metrics = 'metrics',
  infoArray = 'infoArray',
  text = 'text',
  groupedChart = 'groupedChart'
}

export enum WidgetParamType {
  string = 'string',
  dateRange = 'dateRange',
  filter = 'filter'
}

/**
 * Расположение виджета
 */
export type BoardWidgetPosition = {
  Left: number;
  Top: number;
};

/**
 * Размеры виджета
 */
export type BoardWidgetSize = {
  Width?: number;
  Height?: number;
};

/**
 * Параметры, которые могут настраиваться в виджете
 */
export type BoardWidgetParams = {
  Type: WidgetParamType;
  Name: string;
  Required: 1 | 0;
  Label: string;
  Variants?: object;
  IsWidgetParam?: boolean;
};

/**
 * Фильтр для параметров виджета
 * */

export type AnalyticsWidgetFilter = {
  placeholder: string;
  name: string;
  items: IndexedArray<{ data: string | number; title: string }>;
};
/**
 *Виджет аналитики
 */
export type AnalyticsWidget = {
  ID: number | string;
  BoardID?: number | string;
  Name?: string | null;
  ShortName?: string | null;
  Description?: string | null;
  Type: WidgetType;
  Position?: BoardWidgetPosition;
  Size?: BoardWidgetSize;
  Filter?: IndexedArray<AnalyticsWidgetFilter>;
  Params?: IndexedArray<BoardWidgetParams>;
  ClassName?: string;
  Data?: AnalyticsWidgetDataModel;
  Text?: string | null;
  loading?: boolean;
  loaded?: boolean;
  error?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
};

//данные виджета

/**
 * Метрики виджета
 */
export type AnalyticsWidgetMetricsModel = {
  name: string | null;
  cellType?: string | null;
  lineCount?: number | null;
  title?: string | null;
  subTitle?: string | null;
  color?: string | number | null;
  stroke?: string | number | null;
  description?: string | null;
  type?: string | null;
  format?: [number, string, string];
  unit?: string;
  filtering?: Array<string> | string;
  sorting?: boolean | null;
  width?: number | null;
  minWidth?: number;
  textWrap?: boolean;
};
/**
 * Оси графика
 */
export type AnalyticsWidgetAxisModel = {
  x: string | null;
  y: string | null;
};

/**
 * Info виджет модель
 */

export type AnalyticsInfoWidgetModel = {
  title: string;
  text: string;
  value: string;
  unit?: string;
  link?: string;
  clickable?: boolean;
};

/**
 * Area Chart виджет модель
 */

export type AnalyticsLineChartSequenceViolations = {
  year: string;
  value: number;
};

/**
 * Значение для отображения
 */
export type AnalyticsWidgetValue = Record<string, string | number> & {
  data?: string | number;
  value?: number | string;
  color?: string | number;
  percent?: string | number;
  hidden?: boolean;
};

export type AnalyticsWidgetDataInfo = {
  title?: string | null;
  text?: string | null;
  value?: string | number | null;
  unit?: string | null;
  link?: string | null;
  info?: IndexedArray<AnalyticsWidgetDataInfo>;
};
export type AnalyticsWidgetDataActions = {
  label: string;
  name: string;
  boardID: number;
  boardSetID: number;
  params: IndexedArray<any>;
  actionCallback?: () => {};
};

export type AnalyticsTableWidgetTotalInfo = {
  data: string;
  value: string;
};
export type AnalyticsTableWidgetTotalChar = Record<
  string,
  {
    date: string;
    value: string;
  }
>;

export type AnalyticsWidgetDataModel = {
  axis?: AnalyticsWidgetAxisModel;
  metrics?: IndexedArray<AnalyticsWidgetMetricsModel>;
  values?:
    | IndexedArray<AnalyticsWidgetValue>
    | Array<AnalyticsWidgetValue>
    | IndexedArray<IndexedArray<AnalyticsWidgetValue>>
    | Array<IndexedArray<AnalyticsWidgetValue>>;
  total?: IndexedArray<AnalyticsTableWidgetTotalInfo>;
  chart?: AnalyticsTableWidgetTotalChar[];
  info?: AnalyticsWidgetDataInfo | Array<AnalyticsWidgetDataInfo>;
  filter?: IndexedArray<AnalyticsWidgetFilter>;
  toggles?: IndexedArray<{
    offCaption: string;
    onCaption: string;
    conditions: Array<{ data: string; condition: string; value: number | string }>;
  }>;
  actions?: AnalyticsWidgetDataActions[];
};

//запросы

export type AnalyticsCreateBoardSetResponse = {
  data: IndexedArray<AnalyticsBoardSetModel>;
};

export type AnalyticsSortBoardsAndSetResponse = {
  data: boolean;
};

export type GetBoardsRequest = {
  BoardSetID?: number;
};

export type GetBoardSetRequest = {
  role?: string;
};

export type GetBoardWidgetRequest = {
  BoardID: number | string;
};

export type GetBoardWidgetResponse = {
  data: IndexedArray<AnalyticsWidget>;
};

export type GetWidgetDataRequest = Record<string, string | number> & { ID: number | string };

export type CreateBoardSetParams = Pick<AnalyticsBoardSetModel, 'Active' | 'Description' | 'Name' | 'Category'>;

export type CreateBoardParams = { BoardSetID: number | string } & Pick<AnalyticsBoardModel, 'Name'>;
export type CopyBoardParams = Pick<AnalyticsBoardModel, 'ID'>;

export type SetBoardWidgetRequest = {
  ID: string | number;
  BoardID?: string | number;
  Name?: string;
  Description?: string;
  Type?: string;
  Left?: number;
  Top?: number;
  Width?: number;
  LefHeight?: number;
  ClassName?: string;
  Text?: string;
};
``;
export enum WidgetUpdateMethod {
  set = 'set',
  delete = 'delete'
}

export type SetBoardWidgetBatchItem = {
  ID?: string | number;
  Values: SetBoardWidgetRequest | {};
  Method: WidgetUpdateMethod;
};
export type SetBoardWidgetBatchRequest = {
  Widgets: Array<SetBoardWidgetBatchItem>;
  BoardID?: string | number;
};
export type SetBoardWidgetBatchResponse = {
  widgets: IndexedArray<AnalyticsWidget>;
};
export type SetBoardWidgetResponse = string | number | boolean;
export type DeleteBoardWidgetResponse = string | number | boolean;

export type GetWidgetDataResponse = {
  data: AnalyticsWidgetDataModel;
};

export type AnalyticsFormDataWidgetDataModel = {
  values: IndexedArray<{ value: string; name: string }>;
  metrics: Record<
    string,
    {
      title: string;
      name: string;
      col: number;
      order: number;
      icon?: boolean;
    }
  >;
};

export type AnalyticsFormDataWidgetDataMetricsModel = {
  title: string;
  name: string;
  col: number;
  order: number;
};

export type TransformedFormDataModel = Record<
  string,
  Record<
    string,
    {
      title: string;
      name: string;
      col: number;
      order: number;
      value: string;
      icon?: boolean;
    }
  >
>;

export type AnalyticsBatchResponse = {
  data: {
    data: {
      success: boolean;
      data: {
        result: any[];
      };
    }[];
  };
};
