import { FC, memo } from 'react';

interface Props {
  clientName?: string;
  clientAddress?: string;
  startTime?: string;
  factTime?: string;
  lastStatusDT?: string;
  lastStatus?: string;
}

// Тултип для диаграмм доставки
export const ILSMonitoringWaypointTooltip: FC<Props> = memo(({ clientName, clientAddress, startTime, factTime, lastStatusDT, lastStatus }) => {
  return (
    <div className="ils-monitoring-waypoint-tooltip point-tip font-13">
      <div className="row">
        <div className="label short">Название клиента:</div>
        <div className="value">{clientName}</div>
      </div>
      <div className="row">
        <div className="label short">Адрес клиента:</div>
        <div className="value">{clientAddress}</div>
      </div>
      <div className="row">
        <div className="label short">Время прибытия по плану:</div>
        <div className="value">{startTime}</div>
      </div>
      <div className="row">
        <div className="label short">Фактическое время прибытия:</div>
        <div className="value">{factTime}</div>
      </div>
      <div className="row">
        <div className="label short">Статус из мобильного приложения:</div>
        <div className="value">{lastStatus}</div>
      </div>
      <div className="row">
        <div className="label short">Время установки статуса:</div>
        <div className="value">{lastStatusDT}</div>
      </div>
    </div>
  );
});

