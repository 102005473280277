import { monitoringMetricsRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { MetricsData, MonitoringState } from '../types/store';
import { initialState } from '../store';
import { isObject } from 'lodash';

// Устанавливает данные метрик мониторинга
const monitoringMetricsReducer = createReducer<MonitoringState>(initialState as MonitoringState, {
  [monitoringMetricsRoutine.REQUEST]: (state, action: PayloadAction<{ from: string; till: string }>) => {
    state.monitoringMetrics.isFetching = true;
    return state;
  },
  [monitoringMetricsRoutine.SUCCESS]: (state, action: PayloadAction<MetricsData | undefined>) => {
    const data = action.payload;
    state.monitoringMetrics.isFetching = false;

    if (data && isObject(data)) {
      for (let key of Object.keys(data)) {
        state.monitoringMetrics[key] = data[key];
      }
    }

    state.monitoringMetrics.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringMetricsRoutine.FAILURE]: (state) => {
    state.monitoringMetrics.isFetching = false;
    return state;
  },
  [monitoringMetricsRoutine.FULFILL]: (state, action: PayloadAction<{}>) => {
    return state;
  }
});

export default monitoringMetricsReducer;
