import { call, put, takeEvery } from 'redux-saga/effects';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { createDealTemplateRoutine, getDocumentSetsRoutine } from '@modules/documents/children/templates/actions';

function* createDealTemplateWorker() {
  try {
    yield call(DocumentTemplatesAPI.createDealTemplate);
    yield put(getDocumentSetsRoutine());
    ILSUserNotify(Notify.Success, 'Шаблон сделки создан');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* createDealTemplateWatcher() {
  yield takeEvery(createDealTemplateRoutine.trigger, createDealTemplateWorker);
}
