import { ILSButton, ILSUserNotify } from '@common/components';
import { DataSchemeAPI } from '../api';
import { downloadFileByUrlFromBlob } from '@common/utils';
import { Notify } from '@common/types';

export const DownloadSchemeIniButton = () => {
  const onClick = async () => {
    ILSUserNotify(Notify.Loading, 'Схема экспортируются...', 3);
    const { data } = await DataSchemeAPI.getCurrentSchemeIni();
    downloadFileByUrlFromBlob(data, 'scheme.ini');
    data && ILSUserNotify(Notify.Success, 'Экспорт данных успешно завершен');
  };

  return (
    <li className={'scheme-editor-menu-item'}>
      <ILSButton children={'Скачать схему'} type={'primary'} onClick={onClick} />
    </li>
  );
};

