import { createSelector } from 'reselect';
import { Dictionary, ReferenceTablesData, TableColumnName } from '@common/types';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';
import { filterCatalogOptions, filterDriverOptionsByTransportCompany } from '@common/components/data-display/table/utils';
import { filterCatalogData } from '@modules/catalog/helpers/catalog-dictionary-filter';

export const vehicleTableOptionsSelector = createSelector(
  catalogDictionaryDataSelector(Dictionary.Depot),
  catalogDictionaryDataSelector(Dictionary.Driver),
  catalogDictionaryDataSelector(Dictionary.VehicleProfile),
  catalogDictionaryDataSelector(Dictionary.VehicleTimeline),
  catalogDictionaryDataSelector(Dictionary.VehicleOverload),
  catalogDictionaryDataSelector(Dictionary.SpeedProfile),
  catalogDictionaryDataSelector(Dictionary.Restriction),
  catalogDictionaryDataSelector(Dictionary.VehicleTariff),
  catalogDictionaryDataSelector(Dictionary.TripTemplate),
  catalogDictionaryDataSelector(Dictionary.VehicleType),
  catalogDictionaryDataSelector(Dictionary.TransportCompany),
  catalogDictionaryDataSelector(Dictionary.Zone),
  catalogDictionaryDataSelector(Dictionary.ForbiddenZone),
  catalogDictionaryDataSelector(Dictionary.RoadTrain),
  catalogDictionaryDataSelector(Dictionary.CouplingType),

  (
    depot,
    Driver,
    VehicleProfile,
    VehicleTimeline,
    VehicleOverload,
    SpeedProfile,
    Restriction,
    VehicleTariff,
    TripTemplate,
    VehicleType,
    TransportCompany,
    Zone,
    ForbiddenZone,
    RoadTrain,
    CouplingType
  ) => {
    const depotOptions = createDepotCascaderOptions(depot);
    const driverOptions = {
      options: filterDriverOptionsByTransportCompany(filterCatalogOptions(Driver)),
      dictionary: Dictionary.Driver,
      fieldNames: DEFAULT_FIELD_NAMES
    };
    const refData: ReferenceTablesData = {
      FrontCouplingTypeID: {
        options: CouplingType,
        dictionary: Dictionary.CouplingType,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RearCouplingTypeID: {
        options: CouplingType,
        dictionary: Dictionary.CouplingType,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RoadTrain: {
        options: RoadTrain,
        dictionary: Dictionary.RoadTrain,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      ForbiddenZoneID: {
        options: ForbiddenZone,
        dictionary: Dictionary.ForbiddenZone,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      FriendZoneID: {
        options: filterCatalogOptions(filterCatalogData(Zone, Dictionary.DeliveryZone)),
        dictionary: Dictionary.Zone,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      StartDepotID: depotOptions,
      EndDepotID: depotOptions,
      DriverID: driverOptions,
      ExpeditorID: driverOptions,
      VehicleProfileID: {
        options: filterCatalogOptions(VehicleProfile),
        dictionary: Dictionary.VehicleProfile,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      VehicleTimelineID: {
        options: filterCatalogOptions(VehicleTimeline),
        dictionary: Dictionary.VehicleTimeline,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      VehicleOverloadID: {
        options: filterCatalogOptions(VehicleOverload),
        dictionary: Dictionary.VehicleOverload,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      SpeedProfileID: {
        options: filterCatalogOptions(SpeedProfile),
        dictionary: Dictionary.SpeedProfile,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RestrictionID: {
        options: filterCatalogOptions(Restriction),
        dictionary: Dictionary.Restriction,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      VehicleTariffID: {
        options: VehicleTariff,
        dictionary: Dictionary.VehicleTariff,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      TripTemplateID: {
        options: filterCatalogOptions(TripTemplate),
        dictionary: Dictionary.TripTemplate,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      [TableColumnName.VehicleTypeID]: {
        options: VehicleType,
        dictionary: Dictionary.VehicleType,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      [TableColumnName.TransportCompanyID]: {
        options: TransportCompany,
        dictionary: Dictionary.TransportCompany,
        fieldNames: DEFAULT_FIELD_NAMES
      }
    };
    return { refData };
  }
);
