import { DictionariesEndpoint, Dictionary } from '@common/types';
import { BASE_URL } from '@common/api/constants';
import queryString, { stringifyUrl } from 'query-string';
import { Tender } from '@modules/tender-platform/types';

export const getAPIURL = (dictionary: Dictionary, endpoint: DictionariesEndpoint) => {
  return `/${BASE_URL}/${dictionary}/${endpoint}`;
};

export const getLink = (payload: { currentTender: Tender; selectedRowKeys: Array<number> }) => {
  const { currentTender, selectedRowKeys } = payload;
  const stringify = queryString.stringify({ ids: selectedRowKeys }, { arrayFormat: 'index' });
  return `${stringifyUrl({
    url: '',
    query: {
      tenderID: currentTender.ID
    }
  })}&${stringify}`;
};
