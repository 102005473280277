import { FC } from 'react';
import { Dictionary, IILSTableProps, IndexedArray, Key } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { MonitoringVehicleEventsModel } from '@common/types/dictionaries/monitoring-vehicle';
import { tableEventDetailsMainConfig, tableEventDetailsMainTable } from '@common/table-configs/monitoring-event-details/main-table';
import { MonitoringDetailsContextType } from '../../types/details-context';
import { MainTableComponent } from '../../components/tables/main-table';

interface Props extends Omit<IILSTableProps<MonitoringVehicleEventsModel & { key: Key }>, 'dataSource'> {
  dataSource: IndexedArray<MonitoringVehicleEventsModel>;
  activeVehicleID: number;
  handleDoubleClick: MonitoringDetailsContextType['handleDoubleClick'];
}

// Контейнер для отображения основной таблицы в детализации событий
export const MainTableContainer: FC<Props> = ({
  dataSource,
  onSelectRows,
  handleDoubleClick,
  activeVehicleID,
  handleSelectedElements,
  rowClass,
  ...props
}) => {
  const decoratedData = dataSource ? tableEventDetailsMainTable(dataSource) : [];

  return (
    <MainTableComponent
      {...props}
      onSelectRows={onSelectRows}
      handleDoubleClick={handleDoubleClick}
      activeVehicleID={activeVehicleID}
      handleSelectedElements={handleSelectedElements}
      rowClass={rowClass}
      decoratedData={decoratedData}
    />
  );
};

export const MonitoringMainTableContainer = tableCustom(MainTableContainer, tableEventDetailsMainConfig, Dictionary.EventDetails);
