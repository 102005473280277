import { Keys, TableRecord } from '@common/types';
import { SelectedOrders } from '@modules/planning/types';
import { Dispatch } from 'redux';
import { deleteOrders } from '@modules/planning/children/by-date/helpers/tables/handlers/order/delete-orders';
import { isEmpty, isString } from 'lodash';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';

/**  В таблице отсутствует кнопка удалить, есть только построчное удаление
 * При группировке по клиентам существует возможно удаления всех вложенных элементов
 * удаление возможно:
 * - при проставленном чекбоксе (может быть выбрано много заявок/клиентов или же только одна строка)
 * - без выделения чекбоксом, по нажатию кнопки
 * если выбрано несколько строк чекбоксом при группировке и хотя бы у одной из них нажата иконка удаления,
 * удаление применится ко всем строкам, поэтому выводим уведомление для подтверждения, поскольку для пользователя это не очевидно
 * в остальных случаях удаляем заявки клиента целиком (один чекбокс и клик или клик без чекбокса) */
export const handleDeleteOrders =
  ({
    selectedOrdersIDs,
    dispatch,
    onSelectRows
  }: {
    dispatch: Dispatch;
    onSelectRows(selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined): void;
    selectedOrdersIDs: SelectedOrders;
  }) =>
  (keys: Keys, records: TableRecord[]) => {
    if (isEmpty(selectedOrdersIDs)) {
      if (keys.some(isString)) {
        const ordersIDs: Keys = records[0].children?.map(({ ID }) => ID) ?? [];
        ILSUserNotifyConfirm(
          `Вы действительно хотите удалить все вложенные заявки (${ordersIDs?.length})?`,
          undefined,
          () => deleteOrders({ keys: ordersIDs, selectedOrders: selectedOrdersIDs, onSelectRows, dispatch }),
          undefined,
          undefined,
          'Удалить'
        );
      } else {
        deleteOrders({ keys, selectedOrders: selectedOrdersIDs, onSelectRows, dispatch });
      }
    } else {
      deleteOrders({ keys, selectedOrders: selectedOrdersIDs, onSelectRows, dispatch });
    }
  };
