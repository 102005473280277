import { ILSSpinCircle } from '@common/components';
import { FC } from 'react';
import { vehicleTableDecorator } from '../table/config/table-decorator';
import { EmulatorVehicleTable } from '../table/main-table';
import { IEmulatorStore, RecordMainTable } from '../types';
import { EmulatorConfigVehicle } from '../types/api';
import { Project } from '@common/types';
import moment from 'moment/moment';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';
import { VehicleTableColumnData } from '../types/table';

interface Props {
  vehicles: IEmulatorStore['planData']['vehicles'];
  activeProject: Project | undefined;
  isFetching: IEmulatorStore['planData']['isFetching'];
  loadedPlanID: IEmulatorStore['planData']['loadedPlanID'];
  vehicleProps?: Array<EmulatorConfigVehicle>;
  handleSave?: (record: RecordMainTable | { ID: Array<string>; key: Array<string> }, key: VehicleTableColumnData, value: any) => void;
  onCanEdit?: () => boolean;
}

// Компонент для отображения основной таблицы в Эмуляторе
export const MainTableComponent: FC<Props> = ({
  vehicles,
  isFetching,
  loadedPlanID,
  vehicleProps,
  handleSave,
  onCanEdit,
  activeProject
}) => {
  const dataSource = vehicleTableDecorator({ vehicles, vehiclesData: vehicleProps });
  return (
    <div className="ils-emulator-top-wrapper">
      <div className="wrapper-header">
        {loadedPlanID && activeProject ? (
          <div>{`Принятый на исполнение план: ${loadedPlanID} проект: ${activeProject.ID} (${moment
            .unix(activeProject.Start)
            .format(ILS_MAIN_INTERFFACE_DATE_FORMAT)})`}</div>
        ) : null}
        <ILSSpinCircle spinning={isFetching} />
      </div>
      <EmulatorVehicleTable dataSource={dataSource} handleSave={handleSave} onCanEdit={onCanEdit} />
    </div>
  );
};
