import '../styles.less';
import { ChangeEvent, FC } from 'react';
import { ILSFormItem, ILSInput } from '@common/components';
import { FormItemProps, InputProps } from 'antd';

interface IProps extends InputProps {
  labelAlign?: FormItemProps['labelAlign'];
  hasLabel?: true;

  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const ILSInputProjectName: FC<IProps> = ({ hasLabel, onChange, labelAlign = 'left', ...props }) => {
  return (
    <ILSFormItem labelAlign={labelAlign} label={hasLabel && 'Название'} name="Name" className="project-input-name">
      <ILSInput {...props} maxLength={80} placeholder="Введите название проекта" onChange={onChange} />
    </ILSFormItem>
  );
};

export default ILSInputProjectName;
