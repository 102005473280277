import { LotPosition, LotTabName } from '@modules/tender-platform/types/lot';

export const LOT_FORM_TABS = [LotTabName.Cargo, LotTabName.Route, LotTabName.Condition, LotTabName.Template];

export const TENDER_LOT_POSITIONS = [LotPosition.RestrictionID, LotPosition.Mass, LotPosition.Amount, LotPosition.Comment];

export const TENDER_LOT_POSITIONS_WHL = [LotPosition.Height, LotPosition.Length, LotPosition.Width];
export const TENDER_LOT_POSITIONS_VOLUMES = TENDER_LOT_POSITIONS_WHL.concat(LotPosition.Volume);

export const TENDER_LOT_TEMPLATE_EMPTY_LABEL = 'Без названия';
