import { ILSModal } from '@common/components';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import ILSWidgetWrapper from '@modules/analytics/common/components/widget/widget-wrapper';
import { AnalyticsTenderModalWidget } from '@modules/analytics/tender/children/tender-analytics/types/widgets';
import { Empty } from 'antd';
import { FC } from 'react';
import './styles.less';

interface Props {
  handleClose: () => void;
  visible: boolean;
  widgets: AnalyticsTenderModalWidget[] | null;
}

const ILSInfoModalTenderComponent: FC<Props> = ({ handleClose, visible, widgets }) => {
  return (
    <ILSModal
      title="Все отзывы"
      className="analytics-tender-info-modal"
      width={900}
      visible={visible}
      onCancel={handleClose}
      footer={false}
    >
      <div className="body">
        {widgets?.length ? (
          widgets.map((w, i) => (
            <ILSWidgetWrapper
              key={i}
              widget={
                {
                  BoardID: 1,
                  Type: w.type,
                  Data: w.data
                } as AnalyticsWidget
              }
              hideWidget={false}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </ILSModal>
  );
};

export default ILSInfoModalTenderComponent;
