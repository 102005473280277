import { IButtonProps } from '@common/components/general/buttons';

// Заголовок для кнопки создания
export enum HeaderCreateButton {
  Self = 'Self', // Заявка для собственного транспорта
  Tender = 'Tender', // Заявка для размещения на тендерной площадке
  Project = 'Project' // Проект для формирования рейсов в планировщике
}
// Создание кнопки
export type CreateButton = {
  key: HeaderCreateButton; // Ключ кнопки
  label: string; // Заголовок кнопки
  buttonProps?: Partial<IButtonProps>; // Свойства кнопки
};
