import { ILSTagEmpty } from '@components/index';
import { ILSSelectCloseOnScroll } from '@components/data-display/table/components/ils-select-scroll-close';
import { IILSCellSelectMultipleProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';

export const ILSCellSelectMultiple = <R extends TableRecord>({
  children,
  save,
  tableRef,
  toggleEdit,
  showSearch,
  allowClear,
  options,
  fieldNames
}: IILSCellSelectMultipleProps<R>): JSX.Element => {
  const [, defaultValue] = children;

  return (
    <ILSSelectCloseOnScroll
      autoFocus
      open
      allowClear={allowClear}
      showSearch={showSearch}
      onChange={save}
      defaultValue={defaultValue}
      maxTagCount={2}
      options={options}
      optionLabelProp={'label'}
      fieldNames={fieldNames}
      filterOption={filterOption(fieldNames)}
      mode={'multiple'}
      tableRef={tableRef}
      onBlur={toggleEdit}
      tagRender={ILSTagEmpty}
      className={'cell-wrapper-editable-full'}
    />
  );
};
