import { ZONE_TABLE_CONFIG } from '@common/models/zone/config';
import { CatalogDictionary, Dictionary, ReferenceTablesData } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { catalogRefDataSelector } from '@modules/catalog/selectors/options/options';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ILSCatalogTable } from '@modules/catalog/containers/table-container';
import { CatalogState } from '@modules/catalog/types/store';
import { zone2table } from '@common/models/zone/decorators';

const refDataConfig = [{ dictionary: Dictionary.DeliveryZone }];

export const ILSCatalogZone: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    const selectOptionsRef = useMemo(() => catalogRefDataSelector(refDataConfig), []);
    const refOption = useSelector((state: CatalogState) => selectOptionsRef(state));

    const refData: ReferenceTablesData = useMemo(
      () => ({
        FriendZoneID: refOption[Dictionary.DeliveryZone]
      }),
      [refOption]
    );

    useEffect(() => {
      setMapProps?.({
        field: 'BorderHash',
        type: 'polygon',
        colorField: 'Color'
      });
    }, []);

    return (
      <ILSCatalogTable {...props} setMapProps={setMapProps} dataDecorator={zone2table} dictionary={Dictionary.Zone} refData={refData} />
    );
  },
  ZONE_TABLE_CONFIG,
  CatalogDictionary.Zone,
  true
);
