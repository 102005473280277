import { createSelector } from 'reselect';
import { AccountStore } from '@modules/account/types/store';

export const fetchSelector = createSelector(
  (state: AccountStore) => state.Account.isFetching,
  (isFetching) => isFetching
);

export const savingSelector = createSelector(
  (state: AccountStore) => state.Account.isSaving,
  (isSaving) => isSaving
);
