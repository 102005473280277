import { AbacPermissions } from '@abac/types';

export const TENDER_PLATFORM_PERMISSIONS: AbacPermissions = {
  TENDER_OWN: 'TENDER_OWN',
  TENDER_SEARCH: 'TENDER_SEARCH',
  TENDER_EDIT: 'TENDER_EDIT',
  TENDER_CREATE: 'TENDER_CREATE',
  TENDER_CHANGE_RESPONSIBLE: 'TENDER_CHANGE_RESPONSIBLE',
  TENDER_SUMMARY: 'TENDER_SUMMARY',
  TENDER_OFFER_CHANGE: 'TENDER_OFFER_CHANGE',
  TENDER_OFFER_CHANGE_RESPONSIBLE: 'TENDER_OFFER_CHANGE_RESPONSIBLE',
  TENDER_OFFER_ON_APPROVAL: 'TENDER_OFFER_ON_APPROVAL',
  TENDER_OFFER_ON_CANCEL: 'TENDER_OFFER_ON_CANCEL',
  TENDER_RATE_CARRIER: 'TENDER_RATE_CARRIER',
  TENDER_VIEW_RATE_MODAL: 'TENDER_VIEW_RATE_MODAL',
  AUCTION_OFFERS: 'AUCTION_OFFERS',
  TENDER_ADD_DOCUMENTS: 'TENDER_ADD_DOCUMENTS',
  TENDER_REMOVE_DOCUMENT: 'TENDER_REMOVE_DOCUMENT'
};

export const TENDER_PLATFORM_PERMISSIONS_FOR_EDITING = [TENDER_PLATFORM_PERMISSIONS.TENDER_EDIT, TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE];
