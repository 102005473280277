import { VALIDATION_TABLES } from '../constants';
import { ILSAlert, ILSButton } from '@components/index';
import { FC } from 'react';
import { TChangeActiveValidationTable, TErrorValidationTable } from '@modules/data-validate/types/types';
import { Notify } from '@common/types';
import classnames from 'classnames';
import { IDecorateValidationData } from '@common/types/dictionaries/validation';
import { ILSTypographyTitle } from '@components/general/typography';
import { getErrorNotify, getErrorsByType } from '../utils';
import { useDispatch } from 'react-redux';
import { dataValidateRoutine } from '@modules/planning/children/data/actions';

interface IProps {
  isPlanning: boolean;
  errorsByDictionary: IDecorateValidationData;
  activeCategory: TErrorValidationTable | null;
  changeActiveTable: TChangeActiveValidationTable;
  changeActiveCategory: (dictionary: TErrorValidationTable) => void;
  showAll: boolean;
}

export const DataValidateParamsComponent: FC<IProps> = ({
  errorsByDictionary,
  isPlanning,
  changeActiveTable,
  activeCategory,
  changeActiveCategory,
  showAll
}) => {
  const dispatch = useDispatch();

  const updateValidateData = () => {
    dispatch(dataValidateRoutine());
  };
  return (
    <div className="alerts">
      <ILSTypographyTitle children={'Результаты валидации:'} className={'no-margin'} level={3} />
      {Object.keys(errorsByDictionary).map((dictionary) => {
        const showAlert = VALIDATION_TABLES[dictionary];
        const handleClick = () => {
          if (dictionary !== activeCategory) {
            changeActiveCategory(dictionary as TErrorValidationTable);
            changeActiveTable(null, null, null);
          }
        };

        const { Err, Wrn, Inf } = getErrorsByType(errorsByDictionary, dictionary as TErrorValidationTable);
        const errorNotify = getErrorNotify(Err, Wrn, Inf);
        if (!showAll && errorNotify !== Notify.Error) {
          return null;
        }
        return (
          <div key={dictionary + '-container'}>
            {showAlert && (
              <ILSAlert
                onClick={handleClick}
                message={VALIDATION_TABLES[dictionary].text ?? dictionary}
                className={classnames('cursor-pointer bg-white', { active: activeCategory === dictionary })}
                showIcon
                type={errorNotify}
              />
            )}
          </div>
        );
      })}
      {!isPlanning && (
        <ILSButton type="primary" onClick={updateValidateData}>
          Обновить результаты валидации
        </ILSButton>
      )}
    </div>
  );
};
