import { apiInstance } from '@core/api/instance';
import { DEFAULT_REQUEST_PARAMS } from '../constants';
import { IDemandAPI } from '../types/api';

/*
 * Необходимые параметры:
 * page  - номер текущей страницы
 * perPage  - количество лотов или проектов на страницу (для бесконечного скролла)
 * filter - Параметры фильтра (если он есть) для фильтрации проектов и тендерных лотов
 */

const baseDemandURL = '/NewTms/Demand/'; // Основной путь до апи бэкэнда

export const DemandAPI: IDemandAPI = {
  // Получает список всех проектов и тендерных лотов
  getList: ({ page = DEFAULT_REQUEST_PARAMS.page, perPage = DEFAULT_REQUEST_PARAMS.perPage, filter }) =>
    apiInstance().post(baseDemandURL + 'index', { 'pagination[page]': page, 'pagination[perPage]': perPage, filter }),
  // Получает количества: всего, только проектов, только лотов
  getCounts: ({}) => apiInstance().post(baseDemandURL + 'getCounts', {})
};
