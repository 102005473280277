import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { updateExternalConfigRoutine } from '../actions';
import { Provider } from '@common/types';
import { isEmpty } from 'lodash';

export const updateExternalConfigReducer = createReducer(initialState, {
  [updateExternalConfigRoutine.REQUEST]: (state) => {
    return state;
  },
  [updateExternalConfigRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      provider: Provider;
      key: string;
      value: string;
    }>
  ) => {
    const { provider, key, value } = action.payload;

    if (isEmpty(state.localConf[provider])) {
      state.localConf[provider] = {};
    }
    state.localConf[provider][key] = value;

    return state;
  },
  [updateExternalConfigRoutine.FAILURE]: (state) => {
    return state;
  },
  [updateExternalConfigRoutine.FULFILL]: (state) => {
    return state;
  }
});
