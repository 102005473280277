import { ActiveType, MapItems, TableRecord } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { useEffect, useState } from 'react';
import { LAYER_TYPES } from '@modules/catalog/hooks/map';
import { LatLngTuple } from 'leaflet';
import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { deepEqual } from 'fast-equals';
import { isObject, isString } from 'lodash';
import { compareAsString } from '@common/utils';

export const useMapFocus = <R extends TableRecord>(
  record: R,
  dataSource: R[] | undefined,
  type: string,
  field: string,
  mapTableItems: MapItems,
  Map: ILSMap | undefined | null
) => {
  const [focusItem, setFocusItem] = useState<(R & { _bound: Array<LatLngTuple> | LatLngTuple }) | null>(null);
  const clearFocus = () => {
    if (focusItem) {
      setFocusItem(null);
    }
  };
  const handleFocus = (record?: R) => {
    if (record) {
      const coords = record?.[field];
      const latLng = isString(coords)
        ? (GeohashDecodePoints(coords) as Array<LatLngTuple> | LatLngTuple)
        : isObject(coords)
        ? (Object.values(coords) as Array<LatLngTuple> | LatLngTuple)
        : undefined;
      if (latLng && !deepEqual({ ...record, _bound: latLng }, focusItem)) {
        setFocusItem({ ...record, _bound: latLng });
      } else {
        clearFocus();
      }
    } else {
      clearFocus();
    }
  };

  useEffect(() => {
    if (type === LAYER_TYPES.MARKER) {
      if (mapTableItems.markers.length === 1) {
        if (record) {
          handleFocus(record);
        } else if (dataSource?.length === 1) {
          handleFocus(dataSource.find(({ selectType }) => compareAsString(selectType, ActiveType.Focus)));
        }
      } else {
        clearFocus();
      }
    } else {
      clearFocus();
    }
  }, [mapTableItems]);
  useEffect(() => {
    if (focusItem?._bound && typeof focusItem._bound === 'object') {
      if (!focusItem._bound[0][0]) {
        Map?.fitBounds([focusItem._bound] as LatLngTuple[], { padding: [15, 15] });
      } else {
        Map?.fitBounds(focusItem._bound as LatLngTuple[], { padding: [15, 15] });
      }
    }
  }, [focusItem]);
};
