import permissions from '@abac/permissions';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import VehicleMonitoring from './containers';
import vehicleMonitoring from './reducers';
import { vehicleMonitoringIndexWatcher } from './sagas';
import { vehicleMonitoringNotifierWatcher } from '@modules/vehicle-monitoring/sagas/notifier';

export default defineModule({
  title: 'Мониторинг плана',
  path: '/vehicleMonitoring',
  component: VehicleMonitoring,
  reducer: vehicleMonitoring,
  inScope: [ModuleScope.CustomWithoutAuthPage],
  sagas: [vehicleMonitoringIndexWatcher(), vehicleMonitoringNotifierWatcher()],
  permission: permissions.NON_AUTH_PAGES
});
