import { ContextMenuItem } from '../../types/context-menu';
import { DashboardMenuItem } from './menu-item';

interface IProps {
  items: ContextMenuItem[];
  record: any;
  onClick(): void;
  onContextClose(): void;
}

// Компонент контекстного меню для диаграммы доставки
export const MonitoringChartContextMenu: React.FC<IProps> = ({ items, record, onClick, onContextClose }) => {
  return (
    <div className="ils-monitoring-chart-context-menu">
      <DashboardMenuItem record={record} item={items} onClick={onClick} onContextClose={onContextClose} menuIndex={0} />
    </div>
  );
};
