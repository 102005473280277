import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initRoutine, showFormRoutine } from '@modules/orders-and-trips/actions';

export const useOrdersAndTripsContainer = () => {
  //TODO Логика необходимая для выполнения при загрузке модуля
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initRoutine());
  }, []);
};

