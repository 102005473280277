import { FC, useEffect } from 'react';
import { DocumentType } from '@modules/documents/types/document-type';
import { ILSButton, ILSForm, ILSFormItem, ILSRow, ILSSelect, ILSSkeleton } from '@common/components';
import { documentTypeOptions } from '@modules/documents/children/upload/constants/document-type-options';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import {
  documentTypeSelector,
  fileNameSelector,
  formModelSelector,
  isFetchingSelector
} from '@modules/documents/children/upload/selectors';
import { documentUploadRoutine, getFormFieldsRoutine } from '@modules/documents/children/upload/actions';
import { FormFieldType } from '@modules/documents/children/upload/types/form-field';
import { Form } from 'antd';
import moment from 'moment';
import { FILE_NAME_ALIAS } from '@modules/documents/children/upload/constants/file-name-alias';
import FilterInput from '@modules/documents/components/filter-input';
import { prepareDocumentsPayload } from '@modules/documents/helpers/prepare-documents-payload';
import CustomScroll from 'react-customscroll';

const ILSDocumentUploadForm: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const documentType = useAppSelector(documentTypeSelector);
  const formModel = useAppSelector(formModelSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const fileName = useAppSelector(fileNameSelector);

  useEffect(() => {
    if (!form.getFieldInstance(FILE_NAME_ALIAS)) {
      form.setFieldsValue({ [FILE_NAME_ALIAS]: fileName });
    }
  }, [fileName]);

  const setDocumentType = (documentType: DocumentType) => {
    dispatch(getFormFieldsRoutine(documentType));
  };

  const createDocument = (values: object) => {
    let payload = { ...values };
    const currentTime = moment();
    if (payload['Created']) {
      const dateWithTime = moment(payload['Created'].toDate());
      dateWithTime.set({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
        second: currentTime.second()
      });

      payload = {
        ...payload,
        Created: dateWithTime
      };
    }
    const preparedPayload = prepareDocumentsPayload(payload);
    dispatch(documentUploadRoutine(preparedPayload));
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="right-scroll-block">
      <CustomScroll>
        <ILSForm form={form} layout="vertical" onFinish={createDocument}>
          <ILSRow className="flex-between-center">
            <h2>
              Заполнить
              <br />
              свойства документа
            </h2>
            <ILSButton className="filter-cancel" type="text" htmlType="button" onClick={onReset}>
              Очистить
            </ILSButton>
          </ILSRow>
          <ILSSkeleton loading={isFetching} active>
            <ILSFormItem label="Тип документа" className="mr-2">
              <ILSSelect
                options={documentTypeOptions}
                value={documentType}
                onChange={setDocumentType}
                placeholder="Выберите тип документа"
              />
            </ILSFormItem>
            {documentType &&
              formModel?.map((field) => (
                <ILSFormItem
                  label={field.name}
                  name={field.alias}
                  key={field.alias}
                  initialValue={
                    field.type === FormFieldType.Date
                      ? moment(field.default)
                      : field.alias === FILE_NAME_ALIAS
                      ? fileName ?? ''
                      : field.default ?? ''
                  }
                  className="mr-2"
                >
                  <FilterInput field={field} />
                </ILSFormItem>
              ))}
            {documentType && (
              <ILSButton type="primary" htmlType="submit">
                Сохранить
              </ILSButton>
            )}
          </ILSSkeleton>
        </ILSForm>
      </CustomScroll>
    </div>
  );
};

export default ILSDocumentUploadForm;

