import { useEffect } from 'react';

export const useListenWindowResize = (props: { callback: () => void; condition?: boolean; timeout?: number }) => {
  const { callback, condition = true, timeout = 250 } = props;

  useEffect(() => {
    if (condition) {
      let timeoutId: NodeJS.Timeout | null = null;
      const resizeListener = () => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(callback, timeout);
      };
      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
  }, [condition, callback]);
};
