import { TrackStore } from '@modules/track/types';

const initialState: TrackStore = {
  Depots: null,
  Plan: null,
  Route: null,
  Trip: null,
  Waypoint: null,
  PrevWaypoint: null,
  isFetching: false,
  error: [],
  lastUpdate: null,
  planPoints: {
    planDataQuery: {},
    trackData: {},
    isFetching: false
  }
};

export default initialState;
