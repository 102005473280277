import { ILSCheckboxItem } from '@modules/settings/components/items/item-checkbox';
import { ILSNumberItem } from '@modules/settings/components/items/item-number';
import { ILSSelectItem } from '@modules/settings/components/items/item-select';
import { SettingsFieldType } from '@modules/settings/types/container';
import { SingleSettingsParams } from '@modules/settings/types/store';
import { isEmpty } from 'lodash';

type UseSettingsComponent = {
  singleSettingsParams: SingleSettingsParams;
  onChange(value: number | boolean | string): void;
};

export const useSettingsComponent = ({ singleSettingsParams, onChange }: UseSettingsComponent) => {
  const { name, type, value, options, active } = singleSettingsParams ?? {};
  const extraParams = !isEmpty(singleSettingsParams?.extraParams) ? singleSettingsParams.extraParams : {};
  const { attention, description } = extraParams;

  const extraProps = {
    name,
    type,
    value,
    attention,
    options,
    onChange
  };

  let Component = null;
  switch (true) {
    case SettingsFieldType.Checkbox === singleSettingsParams.type:
      Component = <ILSCheckboxItem {...extraProps} />;
      break;
    case SettingsFieldType.Select === singleSettingsParams.type:
      Component = <ILSSelectItem {...extraProps} />;
      break;
    case SettingsFieldType.Number === singleSettingsParams.type:
      Component = <ILSNumberItem {...extraProps} />;
  }

  return { Component, description, attention, name, type, active };
};

