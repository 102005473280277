export const TODAY = Date.now();
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const UPDATE_INTERVAL: string = process.env.REACT_APP_API_REFERENCE_REQUEST_TIME ?? '600000';

export const ONE_DAY_INTERVAL: string = '86400000';
export const TIMEZONE_OFFSET = new Date().getTimezoneOffset();
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOURS = 60;
//TODO replace with moment.unix
export const SECONDS_IN_MINUTES = MINUTES_IN_HOURS;
export const SECONDS_TO_MILLISECONDS_MULTIPLIER = 1000;
export const MINUTES_TO_MILLISECONDS_MULTIPLIER = 60_000;
export const HOURS_TO_MILLISECONDS_MULTIPLIER = 360_0000;
export const MILLISECONDS_TO_DAYS_MULTIPLIER = 86_400_000;
export const SECONDS_TO_DAYS_MULTIPLIER = 86_400;
//end to-do
export const DELAY_FOR_SUGGESTIONS = 500;
