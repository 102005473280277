import { isEmpty, isNil } from 'lodash';
import { ActiveRecord } from '@modules/orders-and-trips/types/general';
import { PreCost, PreCostServiceType, PreCostToDisplay } from '@modules/orders-and-trips/types/integrations';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { createArrayFromIndexedArray } from '@common/utils';
import { getPreCostErrorMessage } from '@modules/orders-and-trips/decorators/get-pre-cost-error-message';

export const preCostToDisplay = (activeTrip: ActiveRecord, preCost: PreCost | null) => {
  if (activeTrip?.ID && !isEmpty(preCost)) {
    return preCost[activeTrip.ID]?.services?.reduce((result: PreCostToDisplay, service: PreCostServiceType[number]) => {
      if (!isEmpty(service.convertingErrors)) {
        result[service.name] = {
          data: (service.convertingErrors ?? [])?.map((error) => {
            return {
              priceID: EMPTY_STRING,
              description: getPreCostErrorMessage(error.message),
              cost: EMPTY_STRING
            };
          }),
          errors: true
        };
        //TODO сделать обработчик ошибок после того как будет готов бэк
      } else if (service.requestError) {
        result[service.name] = {
          data: [
            {
              priceID: EMPTY_STRING,
              description: 'Ошибка сервиса',
              cost: EMPTY_STRING
            }
          ],
          errors: true
        };
      } else if (!isNil(service.prices) && !isEmpty(service.prices)) {
        result[service.name] = {
          data: createArrayFromIndexedArray(service.prices).map((price) => {
            return {
              priceID: price.id,
              description: price.description || EMPTY_STRING,
              cost: price.price.toLocaleString('ru') + JoinChar.Space + price.currency
            };
          }),
          errors: false
        };
      }
      return result;
    }, {});
  }
  return null;
};
