import { FC } from 'react';
import { ILSCol } from '@common/components';
import { PlannerDataProjectForm } from '@modules/planning/children/data/components/upload/select-date';
import { PlannerDataActions } from '@modules/planning/children/data/containers/upload/actions';
import { PlannerDocumentUploadContainer } from '@modules/planning/children/data/containers/upload/upload';

export const PlannerDataUpload: FC = () => {
  return (
    <ILSCol className={'planning-data-upload'}>
      <PlannerDataProjectForm />
      <PlannerDocumentUploadContainer />
      <PlannerDataActions />
    </ILSCol>
  );
};
