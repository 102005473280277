import { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { ModulePath } from '@common/types';
import { ILSPageHeader } from '@common/components';

type Props = {
  modulePath: ModulePath;
  title: string;
};

export const ILSAuthNavLink: FC<Props> = ({ modulePath, title }) => {
  return (
    <NavLink to={modulePath} className={(isActive) => classNames('auth-nav__link', { 'auth-nav__link_active': isActive })}>
      <ILSPageHeader subTitle={title} />
    </NavLink>
  );
};

