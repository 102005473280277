import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { plannerSettingsRoutine } from '@modules/planning/actions';
import { initialState } from '@modules/planning/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const setPlannerSettingsReducer = createReducer(initialState, {
  [plannerSettingsRoutine.REQUEST]: onRequest,
  [plannerSettingsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      parameter: string;
      data: Record<string, boolean>;
    }>
  ) => {
    const { parameter, data } = action.payload;
    if (state.settings[parameter]) {
      state.settings[parameter] = {
        ...state.settings[parameter],
        ...data
      };
    }
    return state;
  },
  [plannerSettingsRoutine.FAILURE]: onFailure,
  [plannerSettingsRoutine.FULFILL]: onFulfill
});

