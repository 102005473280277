export enum PaymentAction {
  GET_INVOICE_TEMPLATE = 'GET_INVOICE_TEMPLATE'
}

export const defaultStatuses: {
  [key in PaymentAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  GET_INVOICE_TEMPLATE: { loading: 'Счет формируется...', success: 'Счет успешно сформирован' }
};

