import { FC } from 'react';
import { TRIP_TEMPLATE_MODES } from '@common/models/trip-template/constants';
import { ILSTypographyText } from '@common/components';
import { ILSTypographyTitle } from '@components/general/typography';
import { TemplateModeDescription, TemplateModeLabel } from '@common/types/dictionaries/trip';

export const CatalogTripTemplateTableTooltip: FC = () => {
  return (
    <ul className='w-100'>
      {TRIP_TEMPLATE_MODES.map((mode) => {
        return (
          <li key={mode}>
            <ILSTypographyTitle level={5}>{TemplateModeLabel[mode]}</ILSTypographyTitle>
            <ILSTypographyText ellipsis={false}>{TemplateModeDescription[mode]} </ILSTypographyText>
          </li>
        );
      })}
    </ul>
  );
};
