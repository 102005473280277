import { call, put, takeLatest } from 'redux-saga/effects';
import { tenderNotifySettingsRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { AddressAPI } from '@common/api/common/address';
import { ILSUserNotify } from '@common/components';
import { ErrorAPIData, Notify, ResponseAPI } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { TenderSearch } from '../../api';
import { NotificationSettingsFormField } from '@modules/tender-platform/constants/notification-settings';

type INotifySettingsPayload = {
  [NotificationSettingsFormField.Target]?: { [NotificationSettingsFormField.Name]: string; [NotificationSettingsFormField.Radius]: string };
  [NotificationSettingsFormField.Source]?: { [NotificationSettingsFormField.Name]: string; [NotificationSettingsFormField.Radius]: string };
  [NotificationSettingsFormField.Enabled]: number;
};

type RespCoordinates = {
  Latitude?: string;
  Longitude?: string;
  Name?: string;
};

function* notificationSettingsWorker(action: PayloadAction<INotifySettingsPayload>) {
  const { Enabled, Source, Target } = action.payload;
  const { request, fulfill, success } = tenderNotifySettingsRoutine;
  let sourceCoordinates;
  let targetCoordinates;

  try {
    yield put(request());

    if (Source) {
      const respSourceData: AxiosResponse<AxiosResponse<ResponseAPI<RespCoordinates> | ErrorAPIData>> = yield call(
        AddressAPI.getCoordinates,
        { address: Source.Name }
      );
      sourceCoordinates = respSourceData.data.data;
    }
    if (Target) {
      const resptargetData: AxiosResponse<AxiosResponse<ResponseAPI<RespCoordinates> | ErrorAPIData>> = yield call(
        AddressAPI.getCoordinates,
        { address: Target.Name }
      );
      targetCoordinates = resptargetData.data.data;
    }

    let params = {
      Enabled: Number(Enabled),
      Source: {
        ...sourceCoordinates,
        ...Source
      },
      Target: {
        ...targetCoordinates,
        ...Target
      }
    };

    const { data } = yield call(TenderSearch.setNotifySettings, params);
    yield put(success({ data: data.data[0] }));
  } catch (error) {
    ILSUserNotify(Notify.Error, 'Невозможно сохранить настройки', 3);
  } finally {
    yield put(fulfill());
  }
}

export function* notificationSettingsWatcher() {
  yield takeLatest(tenderNotifySettingsRoutine.trigger, notificationSettingsWorker);
}
