import { call, put, takeLatest } from 'redux-saga/effects';
import { cleanupDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { DistanceMatrix } from '@modules/planning/children/by-date/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ProjectModel } from '@common/types';

function* cleanupDistanceMatrixWorker(
  action: PayloadAction<{
    projectID: ProjectModel['ID'];
  }>
) {
  const { request, success, failure, fulfill } = cleanupDistanceMatrixRoutine;
  const { projectID } = action.payload;

  try {
    yield put(request());
    const { data } = yield call(DistanceMatrix.cleanup, { projectID });
    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* cleanupDistanceMatrixWatcher() {
  yield takeLatest(cleanupDistanceMatrixRoutine.trigger, cleanupDistanceMatrixWorker);
}
