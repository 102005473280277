import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { ValidateInfoType } from '../types/tender-from-planner';

export const BUUTONS_TO_TENDER_PLATFORM_TEXT = {
  add: {
    buttonTitle: 'Разместить маршрут на тендерной площадке',
    iconComponent: <PlusOutlined />,
    modalTitle: 'Вы действительно хотите разместить выделенные маршруты на Тендерной площадке?',
    onOkText: 'Отправить'
  },
  delete: {
    buttonTitle: 'Удалить маршрут с тендерной площадки',
    iconComponent: <MinusOutlined />,
    modalTitle: 'Вы действительно хотите удалить выделенные маршруты с Тендерной площадки?',
    onOkText: 'Удалить'
  },
  toTender: {
    buttonTitle: 'Перейти к тендеру',
    iconComponent: <RightOutlined />,
    modalTitle: 'Вы действительно хотите перейти в Тендерную площадку?',
    onOkText: 'Перейти'
  }
};

/**
 * Максимальное число маршрутов для импорта в ТП
 */
export const MAX_COUNT_ROUTES_TO_ADD = 30;

export const ErrorVariants: Record<string, ValidateInfoType> = {
  RouteStatusOnExecute: {
    success: false,
    errorText: (
      <p>
        Вы пытаетесь перенести на Тендерную площадку маршрут, находящийся на исполнении.
        <br />
        Уберите маршрут с исполнения, затем повторите перенос.
      </p>
    ),
    modalProps: {
      title: (
        <p className="warning-title">
          <ExclamationCircleOutlined className="warning-icon" /> Внимание
        </p>
      ),
      cancelText: 'ОК',
      cancelButtonProps: {},
      okButtonProps: { hidden: true }
    }
  },
  VehicleIsReal: {
    success: false,
    errorText: (
      <p>
        Вы пытаетесь перенести на Тендерную площадку маршрут, в котором указаны ваши реальные транспортные средства.
        <br />
        Смените автомобили на виртуальные, затем повторите перенос.
      </p>
    ),
    modalProps: {
      title: (
        <p className="warning-title">
          <ExclamationCircleOutlined className="warning-icon" /> Внимание
        </p>
      ),
      cancelText: 'ОК',
      cancelButtonProps: {},
      okButtonProps: { hidden: true }
    }
  },
  NoError: {
    success: true
  }
};
