import { deepEqual } from 'fast-equals';
import { Avatar } from '@modules/account/types';

export const checkHasChangesInProfileForm = ({
  values,
  initialValues,
  avatar,
  newAvatar
}: {
  values: any;
  initialValues: any;
  avatar: string | undefined | null;
  newAvatar: Avatar;
}) => {
  return deepEqual(values, initialValues) && avatar === newAvatar;
};
