import { createRoutine } from 'redux-saga-routines';
import { article, categories } from '../store';
import { IReferenceCategory } from '@modules/reference/types/models';
import { IReferenceActionSuccessPayload, IReferenceActionTriggerPayload } from '@modules/reference/types/actions';
import { IReferenceLevel } from '@modules/reference/types/store';

interface GetArticleInfoPayload {
  level1: IReferenceLevel;
  level2: IReferenceLevel;
  level3: IReferenceLevel;
}

export const referenceRoutine = createRoutine('REFERENCE/GET_ARTICLE_INFO', {
  trigger: (payload: IReferenceActionTriggerPayload) => payload,
  success: (payload: IReferenceActionSuccessPayload) => payload
});

export const getArticleInfo = (cat1ID: number, cat2ID?: number, cat3ID?: number): GetArticleInfoPayload => {
  let categories1 = categories,
    categories2: IReferenceCategory[] = [],
    categories3: IReferenceCategory[] = [],
    article1 = article,
    article2 = article,
    article3 = article;

  if (cat1ID && categories1) {
    categories2 = categories.filter((c) => c.id === cat1ID)[0].subCategories!;
  }

  if (cat2ID && categories2) {
    categories3 = categories2.filter((s) => s.id === cat2ID)[0].subCategories!;
  }

  const payload: GetArticleInfoPayload = {
    level1: { categories: categories1 ?? [], article: article1 ?? null },
    level2: { categories: categories2 ?? [], article: article2 ?? null },
    level3: { categories: categories3 ?? [], article: article3 ?? null }
  };

  return payload;
};
