import { FC, useState } from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { RecordType, SchemeDragData, SchemeDragInfo, TypeContainer } from '@modules/planning/children/scheme-editor/types';

interface IProps {
  typeContainer: TypeContainer;
  data: Array<RecordType>;

  handleMoveSchemeKey({ target, source, typeContainer }: SchemeDragInfo): void;
}

export const SchemeEditorEmptySchemeList: FC<IProps> = ({ typeContainer, data, handleMoveSchemeKey }) => {
  const [target, setTarget] = useState<RecordType['title']>('');

  const onHit = ({ dragData: { source } }: SchemeDragData) => {
    if (source.typeContainer !== typeContainer) {
      handleMoveSchemeKey({ target, source, typeContainer });
    } else {
      //при перемещении в дроп контейнер текущего списка - заменяем последний элемент
      const lastItem = data && data?.length > 1 ? data[data.length - 1] : null;
      const target = lastItem?.key;
      target && handleMoveSchemeKey({ target, source, typeContainer });
    }
  };

  const handleEnter = () => setTarget(typeContainer);
  return (
    <div className={'scheme-editor-scheme-empty-list'}>
      <DragDropContainer targetKey={'scheme'} dragData={{ source: { title: '', typeContainer } }}>
        <DropTarget onHit={onHit} onDragEnter={handleEnter} targetKey={'scheme'}>
          <fieldset style={{ height: '100%', width: '40vw' }} />
        </DropTarget>
      </DragDropContainer>
    </div>
  );
};
