import { Dispatch } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { first, isEmpty } from 'lodash';
import { ILSUserNotify } from '@common/components';
import { Dragging, MarkerType, ReactDispatch, TrackActiveData } from '@common/types';
import { compareAsString } from '@common/utils';
import { MapActivePlan, TrackDataType } from '@modules/planning/children/by-date/types/map-container';
import { IPlanningMapProps } from '@modules/planning/children/by-date/containers/result/map-container';
import { checkAccessMoveWpToTrip } from '@modules/planning/children/by-date/decorators/map/check-access-move';
import { MoveWaypointInTripPayload } from '@modules/planning/children/by-date/sagas/trip/move-waypoint-in-trip';
import {
  addOrderForVehicleRoutine,
  moveWaypointInTripRoutine,
  moveWaypointToTripRoutine
} from '@modules/planning/children/by-date/actions';

type UseMapDragEndArg = {
  setPolylineDataIDs: (newPolylineDataIDs: TrackActiveData | null) => void;
  mapData: MapActivePlan;
  resetSummaryData: (arg: { resetAll?: boolean }) => void;
  enableAutoFocusOnChangeTrip: string | number | boolean;
  setDragging: ReactDispatch<Dragging | null>;
  dispatch: Dispatch;
} & Pick<IPlanningMapProps, 'setIsAfterDragging'>;

export const useMapDragEnd = ({
  setPolylineDataIDs,
  mapData,
  setIsAfterDragging,
  dispatch,
  resetSummaryData,
  setDragging
}: UseMapDragEndArg) => {
  const handleMarkerDragEnd = useCallback(
    (dragData: Dragging, trackData?: TrackDataType) => {
      setPolylineDataIDs(null);
      if (!dragData || !trackData) return false;
      const { type, tripIds, orderIDs, waypointIDs } = dragData;

      const { TripID, VehicleID, PlanID } = trackData;

      if (!type || !TripID || (!waypointIDs && isEmpty(orderIDs))) {
        return false;
      }

      if (mapData?.track.length) {
        const error = checkAccessMoveWpToTrip(mapData.track, TripID, waypointIDs);
        if (error) {
          ILSUserNotify(error.TYPE, error.MESSAGE, error.DURATION, error.KEY);
          return;
        }
      }
      if ((type === MarkerType.Order || type === MarkerType.OrderCluster) && PlanID && !isEmpty(orderIDs)) {
        setIsAfterDragging(true);
        resetSummaryData({ resetAll: true });
        dispatch(
          addOrderForVehicleRoutine({
            orderID: orderIDs?.filter((item, index) => orderIDs.indexOf(item as number) === index), //DrugNDrop почему-то приходят маркеры с дублями, эта штука чтобы от них избавиться
            planID: PlanID,
            vehicleID: VehicleID,
            tripID: TripID
          })
        );
      } else if (
        type === MarkerType.Point &&
        tripIds?.includes(TripID) &&
        dragData.waypointIDs?.length === 1 &&
        dragData.originalID === first(dragData.waypointIDs)
      ) {
        if (compareAsString(trackData.WaypointID, first(dragData.waypointIDs))) {
          return; // Перенос точки на ту же точку
        }
        resetSummaryData({ resetAll: true });
        const payload: MoveWaypointInTripPayload = {
          sourceWaypointIDs: dragData?.waypointIDs,
          tripID: Number(TripID),
          targetWaypointID: Number(trackData.WaypointID)
        };
        dispatch(moveWaypointInTripRoutine(payload));
      } else if (type === MarkerType.Point) {
        const filteredWPIDs = waypointIDs?.filter((wpID) => mapData.waypointMapData[wpID].TripID !== TripID);
        resetSummaryData({ resetAll: true });
        if (!isEmpty(filteredWPIDs)) {
          dispatch(
            moveWaypointToTripRoutine({
              tripID: TripID,
              waypointID: filteredWPIDs,
              planID: PlanID
            })
          );
        }
      }

      setDragging(null); //сброс данных дд контейнера после завершения действия
    },
    [mapData]
  );
  return { handleMarkerDragEnd };
};
