import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { TenderState } from '@modules/tender-platform/types';
import { tenderDocumentDeleteRoutine } from '@modules/tender-platform/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderDocumentDeleteReducer = createReducer(initialState, {
  [tenderDocumentDeleteRoutine.REQUEST]: onRequest,
  [tenderDocumentDeleteRoutine.FAILURE]: onFailure,
  [tenderDocumentDeleteRoutine.FULFILL]: onFulfill,
  [tenderDocumentDeleteRoutine.SUCCESS]: (state: TenderState, action: PayloadAction<{ ids: Array<number> }>) => {
    const { ids } = action.payload;
    ids.forEach((id) => delete state.documents[id]);
    return state;
  }
});
