import { apiInstance } from '@core/api/instance';
import { IUserAPI } from '../types/api';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const UserAPI: IUserAPI = {
  userOperation: ({ operation, values, id }) => {
    const url = getAPIURL(Dictionary.User, operation);
    switch (operation) {
      case DictionariesEndpoint.Create:
        return apiInstance().post(url, { data: { values } });
      case DictionariesEndpoint.Delete:
        return apiInstance().post(url, { data: { ids: [id] } });
      case DictionariesEndpoint.Update:
        return apiInstance().post(url, { data: { id, values } });
      default:
        return apiInstance().post(url);
    }
  }
};
