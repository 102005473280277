const STORAGE = 'keyvaluepairs';
const READ_WRITE = 'readwrite';
const prefix = 'persist';

/**
 * формирование имени ключа в хранилище
 * */
const getKey = (rootKey: string, key: string) => {
  return `${prefix}:${rootKey}:${key}`;
};

/**
 *Очистка базы IndexDB вручную, т.к. ключи незагруженных модулей сам персист не очищает.
 * */
export const persistCleanup = (dbName: string, rootKey: string, exceptions: Array<string>, onClearStore?: any) => {
  let db: IDBDatabase;
  const DBOpenRequest = window.indexedDB.open(dbName);
  DBOpenRequest.onsuccess = () => {
    db = DBOpenRequest.result;
    clearData();
  };

  function clearData() {
    const transaction = db.transaction([STORAGE], READ_WRITE);

    transaction.onerror = (event: any) => {
      console.error(event);
    };

    const objectStore = transaction.objectStore(STORAGE);

    const request = objectStore.openCursor();

    request.onsuccess = (event: any) => {
      const cursor = event.target.result;
      if (cursor) {
        if (exceptions.every((x) => cursor.key !== getKey(rootKey, x))) {
          const deleteRequest = objectStore.delete(cursor.key);
          deleteRequest.onerror = (e: any) => {
            console.error(e);
          };
        }
        cursor.continue();
      } else {
        //Если передан callBack После того как все базы IndexDB очищены (cursor - вернул null), сбрасываем state
        onClearStore?.();
      }
    };
  }
};
