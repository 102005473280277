import { put, takeLatest } from 'redux-saga/effects';
import { activeRecordRoutine, getExtraDataRoutine, getTripNOrderDataRoutine, showFormRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormType, RecordType } from '@modules/orders-and-trips/types/general';

function* showFormWorker(
  action: PayloadAction<{
    formType: FormType | null;
  }>
) {
  const { request, success, failure, fulfill } = showFormRoutine;
  const { formType } = action.payload;
  try {
    yield put(request());
    yield put(success({ formType }));
    if ([FormType.CreateOrder, null].includes(formType)) {
      yield put(activeRecordRoutine.success({ recordType: RecordType.All, value: null }));
      yield put(getTripNOrderDataRoutine.success({ formType: null, data: {} }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
    if (formType === FormType.CreateOrder) {
      yield put(getExtraDataRoutine.trigger());
    }
  }
}

export function* showFormWatcher() {
  yield takeLatest(showFormRoutine.trigger, showFormWorker);
}

