import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { getConfigOptions } from '@modules/planning/children/by-date/helpers/tables/ref-options/plan/get-config-options';
import { getConfiguration } from '@modules/planning/children/by-date/helpers/selectors/get-configuration';
import { planningParamsSelector } from '@modules/planning/selectors';

export const defaultPlannerConfigsSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.defaultPlannerConfigs,
  (defaultPlannerConfigs) => defaultPlannerConfigs
);

export const planningConfigByPlanSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.planningConfigByPlan,
  (planningConfigByPlan) => planningConfigByPlan
);

export const planningConfigsDataSelector = createSelector(
  defaultPlannerConfigsSelector,
  (defaultPlannerConfigs) => defaultPlannerConfigs?.data
);

export const planningPlanConfigRefDataSelector = createSelector(planningConfigsDataSelector, (defaultPlannerConfigs) => {
  return getConfigOptions(defaultPlannerConfigs);
});

export const planningParamsConfigSelector = createSelector(
  planningConfigsDataSelector,
  planningParamsSelector,
  (defaultConfig, localConf) => {
    return getConfiguration({ defaultConfig, localConf });
  }
);

export const planningConfigurationSelector = createSelector(
  defaultPlannerConfigsSelector,
  planningConfigByPlanSelector,
  (defaultPlannerConfigs, planningConfigByPlan) => {
    return {
      defaultPlannerConfigs,
      planningConfigByPlan
    };
  }
);

