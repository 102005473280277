import { useEffect, useState } from 'react';
import { DEFAULT_ACCOUNT_TAB } from '@modules/account/constants/account';
import { getInitialRoutine } from '@modules/account/actions';
import { ModulePath } from '@common/types';
import { ProfileTab } from '@modules/account/types/tabs';
import { EMPTY_OBJECT_DATA, EMPTY_STRING } from '@common/constants';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { tabsSelector } from '@modules/account/selectors';

export const useAccountTabs = ({ dispatch }: { dispatch: Dispatch }) => {
  const { adminAccess, isFetching, isSaving } = useSelector(tabsSelector);
  const [currentActiveTab, setCurrentActiveTab] = useState(DEFAULT_ACCOUNT_TAB);

  useEffect(() => {
    dispatch(getInitialRoutine());
    //при входе в модуль определяем указана ли в пути активная вкладка
    const path = window.location.pathname.split(ModulePath.Dashboard).pop() as ProfileTab;
    setCurrentActiveTab(path ?? DEFAULT_ACCOUNT_TAB);
  }, []);

  //при смене активного tab меняем путь в адресной строке
  const onChangeTab = (accountActiveTab: string) => {
    setCurrentActiveTab(accountActiveTab as ProfileTab);
    window.history.replaceState(EMPTY_OBJECT_DATA, EMPTY_STRING, `${ModulePath.Account}/${accountActiveTab}`);
  };

  return { currentActiveTab, onChangeTab, adminAccess, isFetching, isSaving };
};
