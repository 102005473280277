import { defineModule } from '@core/modules';
import { Module } from '@common/types/modules/module';
import SuggestionAutoComplete from './suggestion-autocomplete';

export const Common = defineModule({
  title: Module.Common,
  children: {
    SuggestionAutoComplete
  } as any
});
