import { SelectProps } from 'antd/lib/select';
import { DocumentType } from '@modules/documents/types/document-type';

export const documentTypeOptions: SelectProps['options'] = [
  { value: DocumentType.Contract, label: 'Договор' },
  { value: DocumentType.ContractAnnex, label: 'Приложение' },
  { value: DocumentType.LadingBill, label: 'ТТН' },
  { value: DocumentType.RouteList, label: 'Маршрутный лист' },
  { value: DocumentType.Custom, label: 'Другой документ' }
];
