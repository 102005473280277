import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSSkeleton, ILSTable } from '@common/components';
import { sendConfig } from '@modules/documents/children/sent/constans/send-configs';
import { decorateSendDate } from '@modules/documents/children/sent/helpers/decorateSendData';
import { getInboxSendDocuments } from '@modules/documents/selectors';
import { getInboxSendDocumentsRoutine } from '@modules/documents/actions';
import { DocumentsType } from '@modules/documents/types/document-state';

const SendDocumentTable = () => {
  const dispatch = useAppDispatch();
  const { data, isFetching } = useAppSelector(getInboxSendDocuments);
  const dataSource = decorateSendDate(data);

  useEffect(() => {
    dispatch(getInboxSendDocumentsRoutine({ type: DocumentsType.Send }));
  }, []);

  return (
    <ILSSkeleton loading={isFetching}>
      <ILSTable
        config={sendConfig}
        //TODO
        dataSource={dataSource as any}
        tableName={DocumentsType.Send}
        scroll={{ x: 400, y: 600 }}
        className="editable-table"
      />
    </ILSSkeleton>
  );
};

export default SendDocumentTable;
