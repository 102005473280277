import { exchangeVehiclesRoutine, unionTripsRoutine } from '@modules/planning/children/by-date/actions';
import { RouteRecord } from '@modules/planning/children/by-date/types/record';
import { useDispatch, useSelector } from 'react-redux';
import { compareAsString } from '@common/utils';
import { routesSubMenuItemsSelector } from '@modules/planning/children/by-date/selectors/current-plan';

export type RoutesSubMenuItem = { ID: number; name: string };

export const useRouteActions = (record: RouteRecord) => {
  const dispatch = useDispatch();
  const routes = useSelector(routesSubMenuItemsSelector);

  const routesSubMenu = routes.slice().filter(({ ID }) => !compareAsString(ID, record.ID));

  //объединить рейсы
  const handleUnionTrips = (item: RoutesSubMenuItem) => {
    const payload = {
      sourceVehicleID: record.VehicleID,
      targetVehicleID: item.ID,
      planID: record.PlanID
    };
    dispatch(unionTripsRoutine(payload));
  };

  //обменять автомобили
  const handleExchangeVehicle = (item: RoutesSubMenuItem) => {
    const payload = {
      sourceVehicleID: record.VehicleID,
      targetVehicleID: item.ID,
      planID: record.PlanID
    };
    dispatch(exchangeVehiclesRoutine(payload));
  };

  return {
    routesSubMenu,
    handleUnionTrips,
    handleExchangeVehicle
  };
};
