import { call, put, takeLatest } from 'redux-saga/effects';
import { getFakePreCostRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { FakePreCostRequest } from '@modules/orders-and-trips/types/fake-pre-cost';
import { isEmpty } from 'lodash';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* getFakePreCostWorker(action: PayloadAction<FakePreCostRequest>) {
  const { request, success, failure, fulfill } = getFakePreCostRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getFakePreCost, action.payload);
    yield put(success({ data, values: action.payload }));
    if (isEmpty(data)) {
      ILSUserNotify(Notify.Error, 'Нет доступных предложений для интеграции');
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getFakePreCostWatcher() {
  yield takeLatest(getFakePreCostRoutine.trigger, getFakePreCostWorker);
}
