import { FC } from 'react';
import { ILSModal } from '@components/index';
import { ReactSVG } from 'react-svg';
import { IDType } from '@modules/catalog/types/catalog';
import { Dictionary } from '@common/types';
import { useModal } from '@common/hooks/components/use-modal';

interface IILSTariffDeleteConfirmComponent {
  tariffs: Array<any>;
  confirm: {
    dictionary: Dictionary;
    id: IDType;
  };

  handleDelete(dictionary: Dictionary, id: IDType, forceUpdate: boolean): void;
}

export const ILSTariffDeleteConfirmComponent: FC<IILSTariffDeleteConfirmComponent> = ({ tariffs, confirm, handleDelete }) => {
  const { visible, handleShow } = useModal();

  const handleOk = () => {
    handleDelete(confirm.dictionary, confirm.id, true);
    handleShow();
  };

  return (
    <ILSModal
      title="Внимание!"
      visible={visible}
      onOk={handleOk}
      onCancel={handleShow}
      okText={'Удалить параметр и все связанные тарифы'}
      cancelText="Отменить"
    >
      <div className="flex-center-center">
        <div className="warning-icon">
          <ReactSVG src={'/assets/icons/warning.svg'} />
        </div>
        <div className="ml-5">
          <p>Удаляемый параметр используется в тарифах:</p>
          <div className="white-bg mb-3">
            <ul>
              {tariffs?.map((t) => (
                <li key={t.ID}>{t.Name}</li>
              ))}
            </ul>
          </div>
          <p>Если Вы удалите параметр, то связанные тарифы тоже будут удалены</p>
        </div>
      </div>
    </ILSModal>
  );
};
