import { StepProps, Steps, StepsProps } from 'antd';
import { FC } from 'react';

const { Step } = Steps;

export const ILSSteps: FC<StepsProps> = (props) => {
  return <Steps {...props} />;
};

export const ILSStep: FC<StepProps> = (props) => {
  return <Step {...props} />;
};
