import { FC } from 'react';
import { ChangeSettings } from '@modules/settings/types';
import { SettingsFieldType } from '@modules/settings/types/container';
import { SingleSettingsParams } from '@modules/settings/types/store';
import { useSettingsComponent } from '@modules/settings/utils/hooks/use-settings-components';

interface IProps {
  singleSettingsParams: SingleSettingsParams;
  onSettingsChange(obj: ChangeSettings): void;
  category: string;
  section: string;
  parameter: string;
}

export const ILSSettingsItemComponent: FC<IProps> = ({ singleSettingsParams, onSettingsChange, category, section, parameter }) => {
  const onChange = (value: number | boolean | string) => {
    onSettingsChange({ category, section, parameter, value });
  };

  const {
    Component,
    description,
    attention,
    name,
    type,
    active: activeParameter
  } = useSettingsComponent({ singleSettingsParams, onChange });

  if (!activeParameter) return null;

  return (
    <div className="mb-3">
      <div>
        {type !== SettingsFieldType.Checkbox && (
          <div className="mb-1">
            {name}
            {attention && <span className="text-error">{attention}</span>}
          </div>
        )}
        <div>{Component}</div>
      </div>
      {description && <div className="font-12 mt-1 text-darkgrey">{description}</div>}
    </div>
  );
};

