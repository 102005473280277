import { createContext, FC, useState } from 'react';
import { AnalyticsDraggableContextType } from '@modules/analytics/common/types/context/draggable-context';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

export const initialContextDraggableContext: AnalyticsDraggableContextType = {};

export const AnalyticsDraggableContext = createContext<AnalyticsDraggableContextType>(initialContextDraggableContext);

export const AnalyticsDraggableProvider: FC = ({ children }) => {
  const [draggableWidget, setDraggableWidget] = useState<AnalyticsWidget>();
  const context = {
    draggableWidget,
    setDraggableWidget
  };

  return <AnalyticsDraggableContext.Provider value={context}>{children}</AnalyticsDraggableContext.Provider>;
};
