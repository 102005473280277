import { TableColumnName } from '@common/types';

const PLAN_CONFIG_ID = 'planConfigID';
const OPERATION = 'operation';

const PROVIDER_NAME = 'providerName';
const ExternalPlanConfig = 'externalPlanConfigID';

export const PLANNIG_CONFIG_KEYS = [PLAN_CONFIG_ID, OPERATION, PROVIDER_NAME, ExternalPlanConfig];
export const PLANNING_CONFIG_DICTIONARYS = [TableColumnName.PlanConfig, TableColumnName.TypePlanning];

export const MAX_PLAN_COUNT = 8;

export const TABLE_HEADER_BUTTONS_SCROLL_HEIGHT = 10;
export const PLAN_TABLE_SCROLL_WIDTH = 500;

export const PLAN_TABLE_ROW_HEIGHT = 35;
export const PLAN_TABLE_COLLAPSE_HEADER_HEIGHT = 55;
export const PLAN_TABLE_COLLAPSE_BODY_HEIGHT = 20;
export const PLAN_VALIDATION_BLOCK_HEIGHT = 36;
export const PLAN_TABLE_HEADER_HEIGHT = 62;
export const ROUTES_TABLE_BODY_HEIGHT = 225;

export const EXTRA_BLOCKS_PLANNING_TABLE_HEIGHT =
  PLAN_VALIDATION_BLOCK_HEIGHT +
  PLAN_TABLE_HEADER_HEIGHT +
  PLAN_TABLE_HEADER_HEIGHT +
  PLAN_TABLE_COLLAPSE_HEADER_HEIGHT +
  PLAN_TABLE_COLLAPSE_BODY_HEIGHT +
  ROUTES_TABLE_BODY_HEIGHT;
export const PLAN_TABLE_CLOSE_HEIGHT = PLAN_TABLE_HEADER_HEIGHT + 170 + PLAN_TABLE_COLLAPSE_HEADER_HEIGHT + PLAN_VALIDATION_BLOCK_HEIGHT;

export enum PlanningProcessStatus {
  IsPlanning = '0',
  IsDone = '1',
  IsCanceled = '2'
}

export enum PlanningProgressStatus {
  StartPlanning = 0,
  CompletePlanning = 100
}
