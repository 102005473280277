import { FC } from 'react';
import classnames from 'classnames';
import { TripItem } from '../../types/store';

interface Props {
  tripItem: TripItem;
  index: number;
  last: boolean;
}

// Компонент для отображения одного рейса в проектах
export const DemandProjectTripComponent: FC<Props> = ({ tripItem, index, last }) => {
  const { ID } = tripItem;
  const vechicleName = tripItem?.Vehicle?.Name;
  const vechicleRegNumber = tripItem?.Vehicle?.RegNumber;
  const vechicleDriverName = tripItem?.Driver?.Name;
  const vechicleDriverPhone = tripItem?.Driver?.Phone;

  return (
    <div className="ils-demand ils-demand-project-trip">
      <div className="ils-demand-project-trip-wrapper">
        <span className="ils-demand-trip-index">{index}</span>
        <span className="ils-demand-trip-background">Рейс</span>
        <span className="ils-demand-trip-id">ID: "{ID}"</span>
      </div>
      <div className="ils-demand-project-trip-info">
        <span className="ils-demand-trip-info-name">TC</span>
        <span className="ils-demand-trip-info-vechicle">{vechicleName}</span>
        <span className="ils-demand-trip-info-vechicle-number">{vechicleRegNumber}</span>
      </div>
      <div className={classnames('ils-demand-project-trip-driver-info', last ? 'ils-demand-project-trip-driver-info-last' : '')}>
        <span className="ils-demand-trip-info-name">Водитель</span>
        <span className="ils-demand-trip-info-driver-name">{vechicleDriverName}</span>
        <span className="ils-demand-trip-info-driver-phone">{vechicleDriverPhone}</span>
      </div>
    </div>
  );
};
