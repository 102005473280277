import { planningRememberSelectedRowsRoutine } from '@modules/planning/children/by-date/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ShortTableRecord } from '@modules/planning/children/by-date/types/record';
import { Keys } from '@common/types';

function* rememberSelectedRowsWorker(
  action: PayloadAction<{
    shortRecords?: ShortTableRecord[];
    recordIDs?: Keys;
    tableName: string;
  }>
) {
  const { success, failure, fulfill } = planningRememberSelectedRowsRoutine;
  const { shortRecords, recordIDs, tableName } = action.payload;

  try {
    yield put(success({ shortRecords, recordIDs, tableName }));
  } catch (error) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* rememberSelectedRowsWatcher() {
  yield takeLatest(planningRememberSelectedRowsRoutine.trigger, rememberSelectedRowsWorker);
}
