import { InitialState, MainTabs } from '@modules/orders-and-trips/types/general';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { INIT_PAGINATION_PARAMS } from '@modules/orders-and-trips/constants/general';
import { EMPTY_STRING } from '@common/constants';

export const initialState: InitialState = {
  isFetching: false,
  error: [],
  /** Служебные данные для отображения форм и таблиц*/
  extraData: {
    isFetching: false,
    depot: null,
    restriction: null,
    vehicleType: null,
    countTripsByStatus: null
  },
  /** Нераспределенные заявки*/
  orders: {
    isFetching: false,
    activeRecord: null,
    data: {},
    pagination: INIT_PAGINATION_PARAMS,
    total: 0
  },
  /** Рейсы */
  trips: {
    isFetching: false,
    activeRecord: null,
    /**Переключатель статусов*/
    tripStatus: TripStatus.Issued,
    tripsCount: null,
    updateTrips: null,
    data: [],
    pagination: INIT_PAGINATION_PARAMS,
    routes: {},
    total: 0
  },
  /** Транспорт: own - собственный, external - транспортные компании */
  transport: {
    own: {},
    external: {}
  },
  availableIntegrations: {
    isFetching: false,
    data: null,
    preCost: {},
    fakePreCostValues: {},
    fakePreCost: {}
  },
  /** Набор фильтров */
  filters: {},
  /** Показывать/не показывать форму создания заявки/маршрута */
  showForm: null,
  tripDocuments: {
    isFetching: false,
    documents: null
  },
  tripTimelines: {
    isFetching: false,
    timelines: null
  },
  serviceData: {
    isFetching: false,
    form: {}
  },
  activeTab: {
    leftBlock: MainTabs.NewOrder
  },
  tripContract: {
    contractTemplate: {
      template: EMPTY_STRING,
      data: EMPTY_STRING
    },
    isFetching: false
  },
  activeModalWindow: null
};
