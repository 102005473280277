import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FC } from 'react';
import { ILSSpinCircle } from '@common/components';
import {
  DateFormat,
  DateTimeFormat,
  ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
  TimeFormat
} from '@common/types/general/date-time';
import { getLocale } from '@common/utils/helpers/date-time/get-locale';
import { CalendarOutlined } from '@ant-design/icons/lib/icons';

const { RangePicker } = DatePicker;

export interface IDatePickerProps {
  isLoading?: boolean;
  showTime?: object | boolean;
  format?: DateFormat | TimeFormat | DateTimeFormat;
  noIcon?: boolean;
}

//TODO check DatePickerProps props for showTime
/**
 * В случае, если передать showTime, но указать формат без времени - работа time-picker может быть не корректна
 * */
export const ILSDatePicker: FC<IDatePickerProps & DatePickerProps> = ({
  locale,
  isLoading = false,
  showTime = false,
  placeholder = 'Выберите дату',
  noIcon = false,
  ...props
}) => {
  return (
    <DatePicker
      placeholder={placeholder}
      //@ts-ignore
      showTime={showTime}
      format={showTime ? ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT : ILS_MAIN_INTERFFACE_DATE_FORMAT}
      {...props}
      locale={getLocale(locale)}
      suffixIcon={noIcon ?? isLoading ? <ILSSpinCircle spinning={isLoading} /> : <CalendarOutlined className={'ils-svg primary'} />}
    />
  );
};

export const ILSRangePicker: FC<RangePickerProps> = ({
  locale,
  suffixIcon = false,
  format = ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ...props
}) => {
  return <RangePicker format={format} suffixIcon={suffixIcon} {...props} locale={getLocale(locale)} />;
};
