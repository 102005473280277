import { IndexedArray, Key, Layer } from '@common/types';
import { OnLayerChange } from '@modules/planning/children/by-date/types/map-container';
import { isEmpty } from 'lodash';
import { FC, memo } from 'react';
import { PlannerPlanningMapMenuButton } from './btn-menu';

interface ILSPlanningLayerMenuProps {
  activeLayers: Key[];
  onChangeLayer: OnLayerChange;
  layers: IndexedArray<Layer> | null;
}

/**
 * Кнопка с меню для слоев карты
 */
export const PlannerPlanningMapLayerMenu: FC<ILSPlanningLayerMenuProps> = memo(({ activeLayers, onChangeLayer, layers }) => {
  const maxCountLayers = layers
    ? Object.values(layers).reduce((acc, curr) => {
        if (!curr.disabled) {
          acc++;
        }
        return acc;
      }, 0)
    : 0;
  const pickedLayers = isEmpty(activeLayers) ? null : `${activeLayers.length}/${maxCountLayers}`;

  return (
    <PlannerPlanningMapMenuButton
      icon={'show-layers'}
      tooltipText={'Слои карты'}
      activeItems={activeLayers}
      menu={Object.values(layers ?? {})}
      onChange={onChangeLayer}
      content={pickedLayers}
    />
  );
});
