import { ILSMenu } from '@components/index';
import { FC } from 'react';
import { ContextMenuItem, ContextMenuRecord } from '../../types/context-menu';
import { DashboardContextMenuItem } from './context-menu-item';
import { isEmpty } from 'lodash';

interface Props {
  item: Array<ContextMenuItem | null>;
  //TODO Переименовать b1 и b2
  onClick?: (e: any, b1?: boolean, b2?: boolean) => void;
  record: ContextMenuRecord;
  onContextClose: () => void;
  menuIndex: number;
}

// Компонент пункта меню
export const DashboardMenuItem: FC<Props> = ({ item, onClick, record, onContextClose, menuIndex }) => {
  const handleClick = (record: any, menuItem: ContextMenuItem) => {
    if (menuItem) {
      menuItem.handler ? menuItem.handler(record) : onClick?.(item);
    }

    onContextClose();
  };
  if (isEmpty(item)) return null;
  return (
    <ILSMenu className={'menu'} key={menuIndex}>
      {item
        .filter((item) => item && (item.isActive?.({ record }) ?? !item?.inactive))
        .map((contextItem, index) =>
          DashboardContextMenuItem({
            item: contextItem as ContextMenuItem,
            index,
            handleClick,
            record
          })
        )}
    </ILSMenu>
  );
};
