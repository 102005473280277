import { createSelector } from 'reselect';
import { isEmpty, isNil, isObject } from 'lodash';
import { createArrayFromIndexedArray, createArrayFromObject } from '@common/utils';
import { IndexedArray, User, VehicleType } from '@common/types';
import { CurrentTender, Tender, TenderDocuments, TenderLot, TenderLotTemplate, TenderStore } from '@modules/tender-platform/types';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { TenderTemplate } from '../types/store';
import { createIndexedArrayFromArray } from '@common/utils/helpers/array/create-indexed-array-from-array';
import { combinePhoneAndContactName } from '@modules/tender-platform/utils/helpers/combine-phone-and-contact-name';

export const currentTenderSelector = createSelector(
  (state: TenderStore) => state.Tender?.currentTender,
  (currentTender: CurrentTender) => currentTender ?? ({} as Tender)
);

export const isCurrentTenderFromPlannerSelector = createSelector(currentTenderSelector, (currentTender) =>
  Boolean(currentTender?.FromPlanner)
);
export const currentTenderRouteListSelector = createSelector(currentTenderSelector, (currentTender) => currentTender?.RouteList ?? []);
export const currentTenderTypeSelector = createSelector(currentTenderSelector, (currentTender) => currentTender.Type);
export const currentTenderUserSelector = createSelector(currentTenderSelector, (currentTender) => currentTender.User ?? {});

export const isCurrentTenderDatesNilSelector = createSelector(currentTenderSelector, (currentTender) => {
  const { ExecutionEndDT, ExecutionStartDT, OfferEndDT, OfferStartDT } = currentTender;
  const isDatesNil = isNil(ExecutionEndDT) || isNil(ExecutionStartDT) || isNil(OfferEndDT) || isNil(OfferStartDT);
  return { isDatesNil };
});

export const currentTenderLotSelector = createSelector(currentTenderSelector, (currentTender) =>
  Array.isArray(currentTender?.TenderLot) ? currentTender?.TenderLot?.[0] : ({} as TenderLot)
);
export const currentTenderLotsSelector = createSelector(
  currentTenderSelector,
  (currentTender) => currentTender?.TenderLot ?? EMPTY_ARRAY_DATA
);

export const currentTenderTenderOfferSelector = createSelector(currentTenderSelector, (currentTender) => currentTender.TenderOffer);

export const currentTenderOffersSelector = createSelector(currentTenderTenderOfferSelector, (tenderOffer) =>
  createArrayFromObject(tenderOffer)
);

export const tenderOffersSelector = createSelector(currentTenderTenderOfferSelector, (tenderOffers) =>
  Array.isArray(tenderOffers) ? tenderOffers : isObject(tenderOffers) ? [tenderOffers] : ([] as Array<TenderOffer>)
);

export const currentTenderAcceptedOfferSelector = createSelector(currentTenderOffersSelector, (tenderOffer) =>
  tenderOffer?.find(({ Accepted }) => Accepted)
);

export const hasCurrentTenderAcceptedOfferSelector = createSelector(
  currentTenderAcceptedOfferSelector,
  (tenderOffer) => !isEmpty(tenderOffer)
);

export const currentTenderMyOfferSelector = createSelector(currentTenderOffersSelector, (TenderOffer) => {
  return TenderOffer[0] ?? {};
});

export const currentTenderParticipantsSelector = createSelector(
  currentTenderSelector,
  (currentTender) => currentTender.TenderParticipants ?? []
);

export const tenderTemplateDataSelector = createSelector(
  (state: TenderStore) => state.Tender?.tenderTemplateData,
  (tenderTemplateData: TenderTemplate | TenderLotTemplate) => tenderTemplateData
);

export const temporaryLotDistanceSelector = createSelector(
  (state: TenderStore) => state.Tender?.temporaryLotDistance,
  (temporaryLotDistance: number | null) => temporaryLotDistance
);

export const currentTenderTenderOffersSelector = createSelector(
  (state: TenderStore) => state.Tender.currentTender?.TenderOffer,
  (tenderOffer) => tenderOffer
);

export const acceptedTenderOfferSelector = createSelector(currentTenderTenderOffersSelector, (tenderOffer) =>
  Array.isArray(tenderOffer) ? tenderOffer?.some(({ Accepted }) => Accepted) : (tenderOffer as unknown as TenderOffer)?.Accepted
);

export const accountUsersSelector = createSelector(
  (state: TenderStore) => state.Tender.accountUsers,
  (accountUsers: IndexedArray<User>) => createIndexedArrayFromArray(accountUsers)
);

export const accountUsersOptionsWithPhoneSelector = createSelector(accountUsersSelector, (accountUsers) => {
  return combinePhoneAndContactName(accountUsers);
});

export const accountUsersOptionsSelector = createSelector(accountUsersSelector, (accountUsers: IndexedArray<User>) => {
  return createArrayFromIndexedArray(accountUsers);
});

export const tenderActionSelector = createSelector(
  (state: TenderStore) => state.Tender.tenderAction,
  (tenderAction: TenderAction) => tenderAction
);

export const tenderDocumentsSelector = createSelector(
  (state: TenderStore) => state.Tender.documents,
  (documents: IndexedArray<TenderDocuments>) => documents
);

export const tenderVehicleTypeSelector = createSelector(
  (state: TenderStore) => state.Tender.vehicleTypes,
  (vehicleTypes: IndexedArray<VehicleType>) => vehicleTypes
);

export const tenderVehicleTypeOptionsSelector = createSelector(tenderVehicleTypeSelector, (vehicleTypes: IndexedArray<VehicleType>) =>
  createArrayFromIndexedArray(vehicleTypes)
);

export const hasTenderWinnerSelector = createSelector(
  currentTenderSelector,
  (currentTender: CurrentTender) => !!currentTender?.TenderOffer?.some(({ Accepted }) => Accepted)
);

//STATUS CONFIG
export const currentTenderStatusConfigSelector = createSelector(currentTenderSelector, (currentTender) => currentTender.StatusConfig ?? {});
export const currentTenderAliasSelector = createSelector(currentTenderStatusConfigSelector, (StatusConfig) => StatusConfig.Alias);
export const currentTenderTransitionSchemeSelector = createSelector(currentTenderStatusConfigSelector, (StatusConfig) =>
  createArrayFromIndexedArray(StatusConfig.TransitionScheme)
);
