import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FC } from 'react';

const CheckboxGroup = Checkbox.Group;

export const ILSCheckbox: FC<CheckboxProps & { label?: string }> = ({ label, children, ...props }) => {
  return <Checkbox {...props}>{label ?? children}</Checkbox>;
};

export const ILSCheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  return <CheckboxGroup {...props} />;
};
