import { DepotTypeId, IndexedArray, RampResponse, TableColumnName } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { isArray } from 'lodash';

export const ramp2table = (ramps: IndexedArray<RampResponse> | Array<RampResponse>) => {
  return createArrayFromIndexedArray(ramps).map((ramp) => {
    return {
      ...ramp,
      key: ramp.ID,
      DepotID: [ramp.DepotTypeID ?? DepotTypeId.Storage, ramp.DepotID]
    };
  });
};

export const table2ramp = (record: ReturnType<typeof ramp2table>[number], dataIndex: TableColumnName, value: any) => {
  switch (dataIndex) {
    case TableColumnName.DepotTypeID:
      return value?.[0] ?? null;
    case TableColumnName.DepotID:
      if (isArray(value)) {
        const onlyDepotID = value.length === 1;
        if (onlyDepotID) {
          const [depotId] = value;
          return depotId;
        } else {
          const [, depotId] = value;
          return depotId;
        }
      }
      return value;
    default:
      return value;
  }
};
