import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';
import { updateLastInfoRoutine } from '../actions';
import { TrackerLastInfoModel } from '@common/types/dictionaries/monitoring-vehicle';

// Добавляет последние данные трекера
export const monitoringUpdateLastInfoReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [updateLastInfoRoutine.REQUEST]: (state) => {
    state.lastInfoRequest.isFetching = true;
    return state;
  },
  [updateLastInfoRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      vehicleID: Array<string>;
      from: string;
      till: string;
      data: IndexedArray<IndexedArray<TrackerLastInfoModel>>;
    }>
  ) => {
    const { data, from, till, vehicleID } = action.payload;
    state.lastInfoRequest.isFetching = false;
    state.lastInfoRequest.from = from;
    state.lastInfoRequest.till = till;
    state.lastInfoRequest.vehicleIDs = vehicleID.sort();

    if (data) {
      for (const vehicleID in data) {
        if (!data[vehicleID]) continue;
        for (const IMEI in data[vehicleID]) {
          const newTrackerLastInfo = data[vehicleID][IMEI];
          if (state.VehicleEvents?.data?.[vehicleID]?.VehicleTracker?.[IMEI]?.Tracker) {
            state.VehicleEvents.data[vehicleID].VehicleTracker![IMEI].Tracker.TrackerLastInfo = newTrackerLastInfo;
          }
        }
      }
    }
    return state;
  },
  [updateLastInfoRoutine.FAILURE]: (state) => {
    state.lastInfoRequest.isFetching = false;
    state.lastInfoRequest.from = undefined;
    state.lastInfoRequest.till = undefined;
    state.lastInfoRequest.vehicleIDs = undefined;
    return state;
  },
  [updateLastInfoRoutine.FULFILL]: (state) => {
    return state;
  }
});
