import { ID, IndexedArray, Key } from '@common/types';
import { checkAndCreateArrayFromObject } from '@common/utils';

export const getValuesForSelectMultiple = <R extends { ID: Key }>({
  data
}: {
  data: Array<R> | R | IndexedArray<R> | null | undefined;
}): Array<ID> => {
  return checkAndCreateArrayFromObject(data).map(({ ID }) => Number(ID));
};
