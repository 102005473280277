import { FC } from 'react';
import { Project } from '@common/types';
import { ILSTypographyText, ILSTypographyTitle } from '@common/components/general/typography';
import { parseDateFromServerToDateString } from '@common/utils/helpers/date-time/parsers';

interface IProps {
  activeProjectID: number | null;
  activeProject: Project | null;
}

export const PlannerDataCurrentProjectTitles: FC<IProps> = ({ activeProjectID, activeProject }) => {
  return (
    <>
      <ILSTypographyTitle children={`Проект №${activeProjectID}`} />
      <div className="planning-data-upload-form-titles-wrapper">
        <ILSTypographyText children={activeProject?.Name} />
        <ILSTypographyText children={parseDateFromServerToDateString({ date: activeProject?.Start })} />
      </div>
    </>
  );
};
