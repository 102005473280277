import { CellType, Dictionary, TableColumnName } from '@common/types';
import { decorateSelectMultipleData } from '@components/data-display/table/utils';
import { compareStrings } from '@common/utils';
import { getCascaderStringValue, getEnumField } from '@common/utils/helpers/object/get-enum-field';
import { EMPTY_STRING } from '@common/constants';
import { JoinChar } from '@common/constants/general';
import { getDefaultLabelByDictionary } from '@common/utils/helpers/get-default-label-by-dictionary';
import { isEmpty } from 'lodash';
import { getColumnOptions } from '@components/data-display/table/utils/helpers/column/get-column-options';

export const compare = (
  a: any,
  b: any,
  field: string | TableColumnName,
  type: CellType,
  options: ReturnType<typeof getColumnOptions>,
  dictionary?: Dictionary
): number => {
  const specificTypes = [
    CellType.Alert,
    CellType.Select,
    CellType.SelectIcon,
    CellType.SelectAPI,
    CellType.SelectMultiple,
    CellType.SelectMultipleApi,
    CellType.Input,
    CellType.String,
    CellType.StringMultiple,
    CellType.Color,
    CellType.Date,
    CellType.DateTime,
    CellType.Time,
    CellType.RangeTime,
    CellType.Phone,
    CellType.RegNumber,
    CellType.Text,
    CellType.Icon,
    CellType.Email,
    CellType.TimeStringUtc,
    CellType.Progress,
    CellType.CascaderAPI,
    CellType.AddressAutocomplete,
    CellType.DoubleRangeTimeString,
    CellType.DateTimeString
  ];
  let A, B;
  if (type && specificTypes.includes(type)) {
    const enumField = getEnumField(field);
    const enumNameA = enumField && a?.[enumField] && Object.values(a[enumField]);
    const enumNameB = enumField && b?.[enumField] && Object.values(b[enumField]);

    switch (type) {
      case CellType.SelectMultipleApi:
        const valA = decorateSelectMultipleData(isEmpty(enumNameA) ? a[field] : enumNameA);
        const valB = decorateSelectMultipleData(isEmpty(enumNameB) ? b[field] : enumNameB);
        A = valA?.length ? valA.join(JoinChar.Semicolon) : EMPTY_STRING;
        B = valB?.length ? valB.join(JoinChar.Semicolon) : EMPTY_STRING;
        break;
      case CellType.CascaderAPI:
        A = getCascaderStringValue(field, a, dictionary) || getDefaultLabelByDictionary(a[field], dictionary);
        B = getCascaderStringValue(field, b, dictionary) || getDefaultLabelByDictionary(b[field], dictionary);
        break;
      case CellType.Select:
      case CellType.SelectAPI:
        A = enumNameA?.length
          ? enumNameA[0] || getDefaultLabelByDictionary(a[field], dictionary)
          : getOptionValueSort(a[field], dictionary) ?? (a[field] || EMPTY_STRING);
        B = enumNameB?.length
          ? enumNameB[0] || getDefaultLabelByDictionary(b[field], dictionary)
          : getOptionValueSort(b[field], dictionary) ?? (b[field] || EMPTY_STRING);
        break;
      case CellType.SelectMultiple:
        A = a?.[field].length ? a[field].join(JoinChar.Semicolon) : EMPTY_STRING;
        B = b?.[field].length ? b[field].join(JoinChar.Semicolon) : EMPTY_STRING;
        break;
      case CellType.IconAction:
        A = a?.[field] ? a[field].value.toString() : EMPTY_STRING;
        B = b?.[field] ? b[field].value.toString() : EMPTY_STRING;
        break;
      default:
        A = a?.[field] || Number.isInteger(a[field]) ? a[field].toString() : EMPTY_STRING;
        B = b?.[field] || Number.isInteger(b[field]) ? b[field].toString() : EMPTY_STRING;
        break;
    }
    if (A === B) return 0;
    if (A === EMPTY_STRING) return 1;
    if (B === EMPTY_STRING) return -1;
    return compareStrings(A?.toString().toUpperCase() || EMPTY_STRING, B?.toString().toUpperCase() || EMPTY_STRING);
  } else {
    if (field === 'Efficiency') {
      if (a?.Name || b.Name) return 0;
    }
    if (field === 'ID') {
      A = a?.[field] ?? 0;
      B = b?.[field] ?? 0;
      return A - B;
    }
    switch (type) {
      case CellType.Int:
      case CellType.Uint:
      case CellType.Ufloat:
      case CellType.Float:
      case CellType.Percent:
      case CellType.TimeDouble:
        A = a?.[field] ?? 0;
        B = b?.[field] ?? 0;
        return A - B;
      case CellType.Bool:
        A = Number(a?.[field]);
        B = Number(b?.[field]);
        return A - B;
      case CellType.Geo:
        A = a?.[field]?.lat ?? 0;
        B = b?.[field]?.lat ?? 0;
        return A - B;
      // was return as specific
      case CellType.Alert:
      case CellType.Select:
      case CellType.SelectIcon:
      case CellType.SelectAPI:
      case CellType.SelectMultiple:
      case CellType.SelectMultipleApi:
      case CellType.Input:
      case CellType.String:
      case CellType.StringMultiple:
      case CellType.Color:
      case CellType.Date:
      case CellType.DateTime:
      case CellType.Time:
      case CellType.RangeTime:
      case CellType.Phone:
      case CellType.RegNumber:
      case CellType.Text:
      case CellType.Icon:
      case CellType.Email:
      case CellType.TimeStringUtc:
      case CellType.Progress:
      case CellType.IconAction:
      case CellType.CascaderAPI:
      case CellType.AddressAutocomplete:
      case CellType.DateTimeString:
      case CellType.DoubleRangeTimeString:
      // doesnt sorting
      case CellType.BadgeText:
      case CellType.Button:
      case CellType.ButtonClickableDropdown:
      case CellType.CascaderConfigAPI:
      case CellType.Countdown:
      case CellType.Duration:
      case CellType.LineSeries:
      case CellType.MenuButton:
      case CellType.SelectIconAPI:
      case CellType.TextAction:
      case CellType.Status:
      default:
        throw new Error();
    }
  }
};

const getOptionValueSort: (value: any, dictionary: Dictionary | undefined) => string = (value: any, dictionary: Dictionary | undefined) => {
  if (!value) return value;
  if (typeof value === 'object' && value.hasOwnProperty('value')) {
    if (value.label) {
      return value.label;
    } else {
      return getDefaultLabelByDictionary(value.value, dictionary);
    }
  } else if (Array.isArray(value)) {
    return value.map((item) => getOptionValueSort(item, dictionary)).join(',') || '';
  }
  return value.toString();
};
