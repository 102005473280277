import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { searchCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { AccountCarriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const searchCarriers = createReducer(initialState, {
  [searchCarriersRoutine.REQUEST]: onRequest,
  [searchCarriersRoutine.FAILURE]: onFailure,
  [searchCarriersRoutine.FULFILL]: onFulfill,
  [searchCarriersRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      data: AccountCarriers;
    }>
  ) => {
    const { data } = action.payload;
    state.carriers.search = data;
    return state;
  }
});
