import { IILSCellDurationStringProps } from '@common/types/components/table/cell';
import { ILSTimePickerStatic } from '@components/custom/data-display/time-picker';
import { countDuration } from '@common/utils/helpers/date-time';
import { isArray, isEmpty, isNumber } from 'lodash';
import { TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellDurationString = <R extends TableRecord>({ children, toggleEdit }: IILSCellDurationStringProps<R>): JSX.Element => {
  const [node, timestamps] = children;

  if (isEmpty(timestamps)) {
    return <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={undefined} />;
  }
  if (!isArray(timestamps) || !timestamps.some(isNumber)) {
    showUserAndDevError({ error: 'Дата должна быть в формате TimeStamp' });
    return <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={undefined} />;
  }

  const [start, end] = timestamps;
  const duration = countDuration(start, end);
  return <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, duration]} />;
};
