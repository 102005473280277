import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';
import { SaveDictionaryType, ValuesType } from '@modules/catalog/types/catalog';
import { CatalogActions, catalogCreateLocalRoutine } from '@modules/catalog/actions';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { EMPTY } from '@common/constants/general';
import { CatalogNotificationStatuses } from '@modules/catalog/helpers/get-notification-message';
import { CatalogLocalTypeRecord } from '../types/local';
import { isNil } from 'lodash';

function* catalogCreateLocalWorker(
  action: PayloadAction<{
    dictionary: Dictionary;
    values: ValuesType;
    saveDictionary: SaveDictionaryType;
  }>
) {
  const { dictionary, values, saveDictionary } = action.payload;
  const { success, failure, fulfill } = catalogCreateLocalRoutine;
  const notificationMessage = dictionary.match(/zone/gi)
    ? 'Строка добавлена, необходимо отрисовать полигон на карте для сохранения'
    : CatalogNotificationStatuses?.[CatalogActions.CREATE_LOCAL];
  try {
    //При создании новой записи, данные на сервер не отправляем, просто сохраняем новую запись в локальный state
    let { data }: { data: CatalogLocalTypeRecord[] } = yield select((state: any) => state?.Catalog?.[dictionary]);
    if (!data) data = [];
    if (Array.isArray(data) && data.length) {
      const lastDataID = data[data.length - 1].ID;
      if (lastDataID !== EMPTY) {
        yield put(
          success({
            data: [...data, ...values],
            dictionary,
            saveDictionary,
            notificationMessage
          })
        );
      } else {
        yield put(
          success({
            data: data.map((item) => {
              if (item.ID === lastDataID) {
                return { ...item, ...values.find((item) => isNil(item.ID)) };
              }
              return item;
            }),
            dictionary,
            saveDictionary
          })
        );
      }
    } else {
      yield put(
        success({
          data: [...values],
          dictionary,
          saveDictionary
        })
      );
    }
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield delay(2000);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogCreateLocalWatcher() {
  yield takeLatest(catalogCreateLocalRoutine.trigger, catalogCreateLocalWorker);
}
