import { call, put, takeLatest } from 'redux-saga/effects';
import { getRestrictionRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* getRestrictionWorker() {
  const { request, success, failure, fulfill } = getRestrictionRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getRestriction);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getRestrictionWatcher() {
  yield takeLatest(getRestrictionRoutine.trigger, getRestrictionWorker);
}
