import {
  ILSButton,
  ILSDatePicker,
  ILSDragger,
  ILSInput,
  ILSInputNumber,
  ILSRadioGroup,
  ILSSelect,
  ILSSlider,
  ILSTextArea
} from '@common/components';
import { Field, getPlaceholder, Placeholder, RATING } from '@common/constants';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { generateSelect } from '@components/decorators';
import { mockVehicleTypes } from '@modules/tender-platform/__tests/__mocks/store/tender';
import { ITenderRotesFunctions, RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { LOAD_TYPE_OPTIONS, TENDER_TYPE_OPTIONS, TENDER_VISIBILITY_OPTIONS } from '@modules/tender-platform/constants';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';
import { SEPARATE_VEHICLE_OPTIONS, TENDER_VAT_OPTIONS } from '@modules/tender-platform/constants/options';
import { UploadFile } from 'antd/lib/upload/interface';
import moment, { MomentInput } from 'moment';
import { ReactSVG } from 'react-svg';

export const renderFormInputForRouteTenders = ({
  field,
  handleEditing,
  value,
  handleSave,
  onChangeFileList,
  fileList,
  id
}: {
  id: RoutesID;
  field: TenderFormTableField;
  value: string;
  handleSave: ITenderRotesFunctions['handleSave'];
  handleEditing: ITenderRotesFunctions['handleEditing'];
  onChangeFileList: (routeId: RoutesID, file: UploadFile[]) => void;
  fileList: UploadFile[];
}) => {
  const placeholder = getPlaceholder(Placeholder.ChooseValue, false);

  const handleCancel = () => handleEditing({ id, field, editing: false });
  const onBlur = (e: any) => handleChange(e?.target?.value ?? e ?? value);
  const handleDatePick = (value: MomentInput) => handleChange(moment(value).utc(false).unix());

  const handleChange = (value: any) => {
    handleSave({ value, id, field });
    handleCancel();
  };

  switch (field) {
    case TenderFormTableField.SeparateVehicle:
      return (
        <ILSSelect
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={handleChange}
          onBlur={handleCancel}
          options={SEPARATE_VEHICLE_OPTIONS}
          placeholder={placeholder}
        />
      );

    case TenderFormTableField.VehicleLoadType:
      return (
        <ILSSelect
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={handleChange}
          onBlur={handleCancel}
          options={LOAD_TYPE_OPTIONS}
          placeholder={placeholder}
        />
      );
    case TenderFormTableField.Type:
      return (
        <ILSSelect
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={handleChange}
          onBlur={handleCancel}
          options={TENDER_TYPE_OPTIONS}
          placeholder={placeholder}
        />
      );
    case TenderFormTableField.Private:
      return (
        <ILSSelect
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={handleChange}
          onBlur={handleCancel}
          options={TENDER_VISIBILITY_OPTIONS}
          placeholder={placeholder}
        />
      );
    case TenderFormTableField.Vat:
      return (
        <ILSRadioGroup
          className={'tender-from-planner-form-table-fieldset-row lot-radio'}
          onChange={onBlur}
          onMouseLeave={handleCancel}
          options={TENDER_VAT_OPTIONS}
          optionType="button"
          buttonStyle="solid"
        />
      );
    case TenderFormTableField.RegionalCityRecipient:
    case TenderFormTableField.RegionalCitySender:
      return (
        <ILSSuggestionAutoComplete allowClear field={Field.City} className={'tender-platform-form-table-fieldset-row'} onBlur={onBlur} />
      );
    case TenderFormTableField.OfferEndDT:
    case TenderFormTableField.OfferStartDT:
      return (
        <ILSDatePicker
          onChange={handleDatePick}
          onBlur={handleCancel}
          showTime
          className="tender-from-planner-form-table-fieldset-row"
          showNow={false}
        />
      );
    case TenderFormTableField.MinCarrierRating:
    case TenderFormTableField.MinDriverRating:
    case TenderFormTableField.MinDriverRoutes:
      const rating = field === TenderFormTableField.MinDriverRoutes ? { ...RATING, step: Number(value) < 10 ? 1 : 10, max: 100 } : RATING;
      return (
        <ILSSlider
          className={'tender-from-planner-form-table-fieldset-row'}
          {...rating}
          onAfterChange={handleChange}
          tooltipPlacement={'left'}
        />
      );
    //TODO MULTIPLE and fix data[id][field] = Object.values(mockVehicleTypes).find(({ ID }) => compareAsString(value, ID))?.Name;
    //src/modules/tender-platform/children/tender-own/selectors/components/tenders-from-routes.tsx
    case TenderFormTableField.VehicleType:
      return (
        <ILSSelect
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={handleChange}
          onBlur={handleCancel}
          placeholder={placeholder}
          options={generateSelect({ data: mockVehicleTypes })}
        />
      );
    case TenderFormTableField.Description:
      return <ILSTextArea className={'tender-from-planner-form-table-fieldset-row description'} onBlur={onBlur} />;
    case TenderFormTableField.MinPrice:
    case TenderFormTableField.StartPrice:
      return <ILSInputNumber min={0} className={'tender-from-planner-form-table-fieldset-row'} onBlur={onBlur} />;
    case TenderFormTableField.Documents:
      return (
        <ILSDragger
          className={'tender-from-planner-form-table-fieldset-row'}
          onChange={(e) => {
            onChangeFileList(id, e.fileList);
            handleCancel();
          }}
          fileList={fileList}
          multiple
        >
          <div className="ant-upload-drag-icon mb-12">
            <ReactSVG src={'/assets/icons/documents/upload_mini.svg'} />
          </div>
          <ILSButton type="primary">Загрузить файл c устройства</ILSButton>
        </ILSDragger>
      );
    default:
      return <ILSInput className={'tender-from-planner-form-table-fieldset-row'} onBlur={onBlur} />;
  }
};
