import { createReducer } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-own/store';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';
import { addTenderForRouteRoutine } from '@modules/tender-platform/actions';
import { createInitialDataForGroupTenderCreation } from '@modules/tender-platform/children/tender-own/utils/helprers/create-initial-data-for-group-tender-creation';
import { CreateTenderFromRouteAction } from '@modules/tender-platform/children/tender-own/types/route-tenders';

export const createTenderFromRouteReducer = createReducer(initialState, {
  [addTenderForRouteRoutine.REQUEST]: (state) => {
    state.dataForGroupTenderCreation = {};
    state.initialDataForGroupTenderCreation = {};
    state.isFetching = true;
    return state;
  },
  [addTenderForRouteRoutine.FAILURE]: onFailure,
  [addTenderForRouteRoutine.FULFILL]: onFulfill,
  [addTenderForRouteRoutine.SUCCESS]: (state, action: CreateTenderFromRouteAction) => {
    const { data, planID } = action.payload;
    state.dataForGroupTenderCreation = data;
    state.initialDataForGroupTenderCreation = createInitialDataForGroupTenderCreation(data);
    state.planID = planID;
    return state;
  }
});
