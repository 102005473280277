import { createRoutine } from 'redux-saga-routines';
import { createAction } from '@reduxjs/toolkit';
import { DocumentType } from '@modules/documents/types/document-type';
import { DocEnum, DocumentState } from '@modules/documents/types/document-state';

export enum DocumentsActions {
  SAVE_REPORT = 'DOCUMENT/SAVE_REPORT',
  DELETE_REPORT = 'DOCUMENT/DELETE_REPORT',
  GET_REPORTS_LIST = 'DOCUMENT/GET_REPORTS_LIST'
}

export const documentsIndexRoutine = createRoutine('DOCUMENTS/INDEX');
export const documentsGetTemplatesRoutine = createRoutine('DOCUMENT/GET_TEMPLATES');
export const documentGetDependentListRoutine = createRoutine('DOCUMENT/TEMPLATES/GET_DEPENDENT_LIST');
export const resetDependentList = createAction('DOCUMENT/RESET_DEPENDENT_LIST');
export const documentCreateRoutine = createRoutine('DOCUMENT/CREATE_DOCUMENT');
export const documentCreateDealRoutine = createRoutine('DOCUMENT/CREATE_DEAL_DOCUMENT');
export const setDocumentType = createAction<DocumentType>('DOCUMENT/SET_DOCUMENT_TYPE');
export const setDocumentState = createAction<DocumentState>('DOCUMENT/SET_DOCUMENT_STATE');
export const documentUpdateRoutine = createRoutine('DOCUMENT/UPDATE_DOCUMENT');
export const documentDeleteFromTrashRoutine = createRoutine('DOCUMENT/DELETE_FROM_TRASH');
export const currentDocumentRoutine = createRoutine('DOCUMENT/CURRENT_DOCUMENT');
export const saveAsTemplateRoutine = createRoutine('DOCUMENT/SAVE_AS_TEMPLATE');
export const getClearTrashModeRoutine = createRoutine('DOCUMENT/GET_CLEAR_TRASH_MODE');
export const setClearTrashModeRoutine = createRoutine('DOCUMENT/SET_CLEAR_TRASH_MODE');
export const resetError = createAction('DOCUMENT/RESET_ERROR');
export const suggestContractorRoutine = createRoutine('DOCUMENT/SUGGEST_CONTRACTOR');
export const sendContractorRoutine = createRoutine('DOCUMENT/SEND_CONTRACTOR');
export const clearContractorList = createAction('DOCUMENT/CLEAR_CONTRACTOR_LIST');
export const getInboxSendDocumentsRoutine = createRoutine('DOCUMENT/GET_INBOX_SEND_DOCUMENTS');

export const saveReportRoutine = createRoutine(DocumentsActions.SAVE_REPORT);
export const deleteReportRoutine = createRoutine(DocumentsActions.DELETE_REPORT);
export const getReportsListRoutine = createRoutine(DocumentsActions.GET_REPORTS_LIST);

export const getDocumentMetricsRoutine = createRoutine('DOCUMENT/GET_DOCUMENT_METRICS');
export const changeDocumentStatusRoutine = createRoutine('DOCUMENT/CHANGE_DOCUMENT_STATUS');
export const setDocumentEnum = createAction<DocEnum[] | null>('DOCUMENT/SET_DOCUMENT_ENUM');

/**Рутины для которых нужно отображать уведомления */
export const documentsRoutinesWithNotifier = [saveReportRoutine, deleteReportRoutine, getReportsListRoutine];
