import { useEffect, useRef, useState } from 'react';
import { ILSMap } from '@core/containers/map';
import { DETAILS_CONTENT, DetailsMapLayers } from '@modules/orders-and-trips/types/details';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteByCoordsRoutine } from '@modules/orders-and-trips/actions';
import { mapActionPointsTripSelector } from '@modules/orders-and-trips/selectors/map';

export const useDetailsMapInit = ({ detailsContent }: { detailsContent: DETAILS_CONTENT }) => {
  const Map = useRef<ILSMap | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapLayers, setMapLayers] = useState<DetailsMapLayers>({
    trackLayer: undefined,
    markerLayer: undefined,
    isInit: false
  });
  const dispatch = useDispatch();
  const { mapPoints } = useSelector(mapActionPointsTripSelector);

  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    const trackLayer = curMap.addLayerGroup([]);
    const markerLayer = curMap.addLayerGroup([]);
    setMapLayers({
      trackLayer,
      markerLayer,
      isInit: true
    });
    dispatch(getRouteByCoordsRoutine({ points: mapPoints.route }));
    return () => Map?.current?.deinit();
  }, [mapPoints, detailsContent]);

  return { mapLayers, Map, mapRef, mapPoints };
};

