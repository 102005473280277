import '../styles.less';
import { FC, useEffect } from 'react';
import ILSDocumentsFileInput from '@modules/documents/children/upload/components/documents-file-input';
import { UPLOAD_DOCUMENT_FILE_KEY } from '@modules/documents/children/upload/constants/local-forage';
import { useFilesStorage } from '@common/hooks/use-files-storage';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { setFileNameAction } from '@modules/documents/children/upload/actions';
import { fileNameSelector } from '@modules/documents/children/upload/selectors';
import { ILSButton } from '@common/components';
import { useHistory } from 'react-router';

const ILSDocumentsUpload: FC = () => {
  const dispatch = useAppDispatch();
  const fileName = useAppSelector(fileNameSelector);
  const { loadFile, saveFile } = useFilesStorage();
  const { goBack } = useHistory();

  useEffect(() => {
    loadFile(UPLOAD_DOCUMENT_FILE_KEY).then((file) => file && dispatch(setFileNameAction((file as File).name)));
  }, []);

  const onFileChange = (file: File) => {
    saveFile(UPLOAD_DOCUMENT_FILE_KEY, file);
    dispatch(setFileNameAction(file.name));
  };

  return (
    <div className="position-relative">
      {fileName && <div className="position-absolute file-name">{fileName}</div>}
      <ILSDocumentsFileInput onChange={onFileChange} />
      <ILSButton className="back-button" onClick={goBack}>
        Назад
      </ILSButton>
    </div>
  );
};

export default ILSDocumentsUpload;
