import { ILSCheckbox } from '@components/index';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IILSCellBoolProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

/** Принимает любое значение, но ячейка приведёт любое значение к boolean */
export const ILSCellBool = <R extends TableRecord>({ children, save, toggleEdit }: IILSCellBoolProps<R>): JSX.Element => {
  const [node, value = false] = children;
  const isChecked = Boolean(value || false);

  /** Ячейка отдает число 1 | 0 */
  const onChange = (e: CheckboxChangeEvent) => save(+e.target.checked);

  return (
    <span className={'cell-wrapper'} onMouseLeave={toggleEdit}>
      {node}
      <ILSCheckbox className="cell-checkbox" checked={isChecked} onChange={onChange} />
    </span>
  );
};
