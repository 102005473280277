import { useDispatch } from 'react-redux';
import { TableColumnName } from '@common/types';
import { PLANNING_CONFIG_DICTIONARYS } from '@modules/planning/children/by-date/constants';
import { PlanningConfig, PlanningConfigEnum } from '@modules/planning/children/by-date/types';
import { isArray } from 'lodash';
import { isExternalProvider } from '@modules/planning/children/by-date/helpers';
import { PlansTableRecord } from '@modules/planning/children/by-date/types/record';
import { planningSetConfigRoutine, planSaveRoutine } from '@modules/planning/children/by-date/actions';
import { PlanID } from '@common/types/dictionaries/plan';

//TODO вынести в отдельный файл
export const getPayloadForSetConfig = ({ key, value, planID }: { planID: PlanID; key: TableColumnName; value: unknown }) => {
  const payload: PlanningConfig = {
    planID,
    config: {}
  };
  if (key === TableColumnName.PlanConfig) {
    if (isArray(value) && value.length === 2) {
      const [provider, configID] = value;
      payload.config[isExternalProvider(provider) ? PlanningConfigEnum.ExternalPlanConfig : PlanningConfigEnum.PlanConfig] = configID;
      payload.config[PlanningConfigEnum.Provider] = provider;
    }
  } else {
    payload.config[PlanningConfigEnum[key]] = value;
  }
  return payload;
};

export const useHandleSavePlan = () => {
  const dispatch = useDispatch();

  const handleSave = (record: PlansTableRecord, key: TableColumnName, value: unknown) => {
    const planID = record.ID ?? record.PlanID;
    /** Задаем конфигурацию планирования для конкретного плана */
    if (PLANNING_CONFIG_DICTIONARYS.includes(key)) {
      const payload = getPayloadForSetConfig({ key, planID, value });
      dispatch(planningSetConfigRoutine(payload));
      return;
    } else {
      dispatch(planSaveRoutine({ value, planID, key }));
    }
  };

  return { handleSave };
};
