import { isNil } from 'lodash';
import moment from 'moment';

function transformDateIntoDateWithTime(date: moment.Moment | Date) {
  const currentTime = moment();
  if (isNil(date)) return currentTime;

  const dateWithTime = moment(date);
  dateWithTime.set({
    hour: currentTime.hour(),
    minute: currentTime.minute(),
    second: currentTime.second(),
    millisecond: currentTime.millisecond()
  });

  return dateWithTime;
}

export default transformDateIntoDateWithTime;

