import { tableConfigSaveSettingsRoutine } from '@modules/settings/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SettingsAPI } from '@modules/settings/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { TableConfigSaveTrigger } from '../types/table-config';
import { ResponseAPI, TableConfig } from '@common/types';
import { AxiosResponse } from 'axios';
import { UserTableConfig } from '../types/store';
import { setTableConfig, resetTableConfig } from '@core/actions/table-custom';
import { RequestParams } from '@common/constants/settings/api';

function* tableConfigSaveWorker(
  action: PayloadAction<{
    trigger: TableConfigSaveTrigger;
  }>
) {
  const { request, success, failure, fulfill } = tableConfigSaveSettingsRoutine;
  const { trigger } = action.payload ?? {};

  try {
    yield put(request({ isFetching: true }));
    const tableConfig: TableConfig = yield select((state) => state?.fixed?.TablesConfig);

    let payload = {
      paramName: RequestParams.TableConfig,
      binding: RequestParams.BindingAccount,
      value: {}
    };

    let responseTableConfigData = {} as AxiosResponse<ResponseAPI<UserTableConfig>>;

    switch (true) {
      case trigger === TableConfigSaveTrigger.Reset:
        responseTableConfigData = yield call(SettingsAPI.mergeUserParams, payload);
        yield put(resetTableConfig());
        break;
      case trigger === TableConfigSaveTrigger.Recovery:
        responseTableConfigData = yield call(SettingsAPI.getUserParams, payload);
        yield put(setTableConfig({ config: responseTableConfigData.data?.data?.value ?? {} }));
        break;
      case trigger === TableConfigSaveTrigger.Save:
        payload = {
          ...payload,
          value: tableConfig
        };
        responseTableConfigData = yield call(SettingsAPI.mergeUserParams, payload);
        yield put(setTableConfig({ config: responseTableConfigData.data?.data?.value ?? {} }));
        break;
    }
    yield put(success({}));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tableConfigSaveWatcher() {
  yield takeLatest(tableConfigSaveSettingsRoutine.trigger, tableConfigSaveWorker);
}

