import { CellType, TableColumnName, TableConfig } from '@common/types';
import { AttachmentColumn, DriverComment, LastStatus, LastStatusDT } from '../../../monitoring/common/column';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: '№ план',
      dataIndex: TableColumnName.Number,
      key: TableColumnName.Number,
      type: CellType.Input,
      active: true,
      width: 60,
      ellipsis: true,
      isFixedColumn: true,
      update: true,
      titleTooltip: 'Номер по плану'
    },
    {
      title: '№ факт',
      dataIndex: 'numberFact',
      key: 'numberFact',
      type: CellType.Input,
      active: true,
      width: 60,
      ellipsis: true,
      isFixedColumn: true,
      update: true,
      titleTooltip: 'Номер по факту'
    },
    {
      title: 'Клиент',
      dataIndex: 'waypointName',
      key: 'waypointName',
      active: true,
      type: CellType.Input,
      width: 100,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      active: false,
      type: CellType.Input,
      width: 150,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      active: false,
      type: CellType.Input,
      width: 100,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Дистанция',
      dataIndex: 'distance',
      key: 'distance',
      active: true,
      type: CellType.Input,
      width: 70,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Статус',
      dataIndex: 'waypointStatus',
      key: 'waypointStatus',
      active: true,
      type: CellType.Input,
      width: 120,

      ellipsis: true
    },
    AttachmentColumn,
    DriverComment,
    LastStatus,
    LastStatusDT,
    {
      title: 'План. прибытие',
      dataIndex: 'planArrivalTime',
      key: 'planArrivalTime',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'Факт. прибытие',
      dataIndex: 'factArrivalTime',
      key: 'factArrivalTime',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'План. убытие',
      dataIndex: 'planDepartureTime',
      key: 'planDepartureTime',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'Факт. убытие',
      dataIndex: 'factDepartureTime',
      key: 'factDepartureTime',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'План. время стоянки',
      dataIndex: 'planParkingTime',
      key: 'planParkingTime',
      active: true,
      type: CellType.Duration,
      width: 140,

      ellipsis: true
    },
    {
      title: 'Факт. время стоянки',
      dataIndex: 'factParkingTime',
      key: 'factParkingTime',
      active: true,
      type: CellType.Duration,
      width: 140,

      ellipsis: true
    }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  copying: false,
  externalFocusControl: true
};
