import Highlighter from 'react-highlight-words';
import { EMPTY_STRING } from '@common/constants';
import { FilterDropdownProps, FilterValue } from 'antd/es/table/interface';
import { renderFilterDropdown } from './render-dropdown';
import { SearchOutlined } from '@ant-design/icons';
import { InputRef } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { ColumnTable, TableRecord } from '@common/types';
import { FilterState } from '@common/types/components/table/common';
import { handleRowFilter } from '@components/data-display/table/utils/helpers/filter/handle-row-filter';

type Arg<R extends TableRecord> = {
  dataIndex: ColumnTable<R>['dataIndex'];
  filterState: FilterState;
  setFilterState: Dispatch<SetStateAction<FilterState>>;
  searchInput: InputRef | null;
  filteredValue: FilterValue | null;
  title: ColumnTable<R>['title'];
};

export const getDefaultColumnSearchProps = <R extends TableRecord>({
  dataIndex,
  filterState,
  filteredValue,
  searchInput,
  setFilterState,
  title
}: Arg<R>) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, ...props }: FilterDropdownProps) =>
      renderFilterDropdown({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        searchInput,
        title,
        dataIndex,
        setFilterState,
        filterState,
        ...props
      }),
    filterIcon: <SearchOutlined />,
    onFilter: handleRowFilter(dataIndex, filterState.strictSearch),
    onFilterDropdownVisibleChange: (visible: boolean) => visible && setTimeout(() => searchInput?.select?.(), 100),
    render: (text: string | undefined) => {
      return filteredValue ? (
        <Highlighter
          caseSensitive={false}
          autoEscape
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[filterState.searchText]}
          textToHighlight={text?.toString() ?? filterState.searchText ?? EMPTY_STRING}
        />
      ) : (
        text
      );
    }
  };
};
