import { FC } from 'react';

interface IProps {
  tabs: JSX.Element[];
}

// Компонент отвечающий за отображение метрик
export const ILSDashboardMetricsComponent: FC<IProps> = ({ tabs }) => {
  return <div className="ils-monitoring-metrics-tabs">{tabs}</div>;
};
