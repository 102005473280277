import { FC } from 'react';
import { DragInfo } from '@common/types';
import { ILSPlanningVehicleTableComponent } from '@modules/planning/children/by-date/components/tables/vehicle';
import { canEditVehicle } from '@modules/planning/children/by-date/helpers/tables/can-edit/can-edit-vehicle';
import { OrderData } from '@modules/planning/children/by-date/types';
import { useVehicleTable } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-table';

export interface IPlannerPlanningVehicleTableContainerProps {
  dragInfo: DragInfo;
  orderSummary: OrderData | null;
  closeTableButton?: JSX.Element;

  addOrderForVehicle(existTrip: boolean, tripIDForOrder?: number): void;
}

export const PlannerPlanningVehicleTableContainer: FC<IPlannerPlanningVehicleTableContainerProps> = ({
  orderSummary,
  addOrderForVehicle,
  ...props
}) => {
  const { actionTableButtons, dataSource, loading, focusRow, customClearFilters, handleSave, onTableRowClick } = useVehicleTable({
    orderSummary,
    addOrderForVehicle
  });

  return (
    <ILSPlanningVehicleTableComponent
      {...props}
      loading={loading}
      actionTableButtons={actionTableButtons}
      dataSource={dataSource}
      onTableRowClick={onTableRowClick}
      onCanEdit={canEditVehicle}
      focusRow={focusRow}
      handleSave={handleSave}
      customClearFilters={customClearFilters}
      isScrollToActiveRow={false}
    />
  );
};

