import { FC } from 'react';
import { TenderOwnFormTableButtonForAll } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/button-for-all';
import { TenderOwnFormTableFieldTitle } from '@modules/tender-platform/children/tender-own/components/create-from-planner/field-title';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';

interface IProps {
  field: TenderFormTableField;
}

export const TenderOwnFormTableFieldButtonColumn: FC<IProps> = ({ field }) => {
  return (
    <div className={'tender-from-planner-form-table-fieldset-row head'}>
      <TenderOwnFormTableButtonForAll field={field} />
      <TenderOwnFormTableFieldTitle field={field} />
    </div>
  );
};
