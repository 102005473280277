import { IndexedArray, RampType } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const rampType2table = (rampTypes: Array<RampType> | IndexedArray<RampType>) => {
  return createArrayFromIndexedArray(rampTypes).map((rampType) => {
    return {
      ...rampType,
      key: rampType.ID
    };
  });
};
