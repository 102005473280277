import { emulatorGetProjectsRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetProjectsRequest, Project } from '@common/types';

// Получает с бэкэнда массив проектов (projects)
function* emulatorGetProjectsWorker(action: PayloadAction<GetProjectsRequest>) {
  const { request, success, failure, fulfill } = emulatorGetProjectsRoutine;
  const { from, till } = action.payload;

  try {
    yield put(request());

    const { data }: { data: { data: Array<Project> } } = yield call(EmulatorAPI.getProjects, { from, till });
    const projects = data.data;

    yield put(success({ projects }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorGetProjectsWatcher() {
  yield takeLatest(emulatorGetProjectsRoutine.trigger, emulatorGetProjectsWorker);
}
