import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorCheckStatusRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { CheckStatusResponse } from '../types/api';

/*
 * Устанавливает в настройках текущий статус и заодно обновляет информацию (
 * state.emulatorStatus.startEmulationTime = MinTripStart;             // Начальное время старта эмуляции
 * state.emulatorStatus.endEmulationTime = MaxTripEnd;                 // Конечное время эмуляции
 * state.emulatorStatus.currentEmulationTime = curEmulatedTime;        // Текущее эмулируемое время
 * state.emulatorStatus.progress = Progress; )                         // Текущий процент прогресса
 */

const emulatorCheckStatusReducer = createReducer(initialState, {
  [emulatorCheckStatusRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = true;
    return state;
  },
  [emulatorCheckStatusRoutine.SUCCESS]: (state: IEmulatorStore, action: PayloadAction<CheckStatusResponse>) => {
    const { Status, Command, Progress, MinTripStart, MaxTripEnd, curEmulatedTime } = action.payload;

    state.emulatorStatus.isFetching = false;

    state.emulatorStatus.status = Status;
    state.emulatorStatus.command = Command;
    state.emulatorStatus.startEmulationTime = MinTripStart;
    state.emulatorStatus.endEmulationTime = MaxTripEnd;
    state.emulatorStatus.currentEmulationTime = curEmulatedTime;
    state.emulatorStatus.progress = Progress;

    return state;
  },
  [emulatorCheckStatusRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorCheckStatusRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  }
});

export default emulatorCheckStatusReducer;
