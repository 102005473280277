import { ILSButton, ILSButtonTooltip } from '@components/index';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import { IILSButtonLinkProps } from '@components/general/buttons/types';

export const ILSButtonLink: FC<IILSButtonLinkProps> = ({
  link,
  text,
  tooltip = false,
  icon,
  onClick,
  placement,
  buttonClassName,
  tooltipClassName
}) => {
  return (
    <Link to={link}>
      {tooltip ? (
        <ILSButtonTooltip
          tooltipClassName={tooltipClassName}
          buttonClassName={buttonClassName}
          icon={icon}
          onClick={onClick}
          title={text}
          placement={placement}
        />
      ) : (
        <ILSButton className={buttonClassName} onClick={onClick}>
          {text}
        </ILSButton>
      )}
    </Link>
  );
};
