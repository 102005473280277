import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { Props } from './types';
import { ILSUserNotify } from '@common/components/feedback/ils-user-notify';
import { Notify, NotifyDurationInSecond } from '@common/types';

const ILSCopy: FC<Props> = (props) => {
  const { className, disabled, handleChange, value } = props;
  const [isCopied, setIsCopied] = useState<string>('Копировать');

  const handlerCopy = () => {
    if (value) {
      setIsCopied('Скопировано');
      copy(value);
    } else {
      ILSUserNotify(Notify.Warning, 'Отсутствуют данные для копирования', NotifyDurationInSecond.Two, 'ILSCopy');
    }
  };

  useEffect(() => {
    if (isCopied === 'Скопировано') {
      setTimeout(() => {
        setIsCopied('Копировать');
      }, 1000);
    }
  }, [isCopied]);
  return (
    <Input.Group
      compact
      className={className}
      style={{
        display: 'flex',
        flex: 1
      }}
    >
      <Input
        onChange={(event) => handleChange && handleChange(event.target.value)}
        value={value}
        disabled={disabled ?? true}
        style={{
          border: 'none',
          background: 'none',
          paddingLeft: 0
        }}
      />
      <Tooltip title={isCopied}>
        <Button disabled={!value} icon={<CopyOutlined />} onClick={handlerCopy} />
      </Tooltip>
    </Input.Group>
  );
};

export default ILSCopy;
