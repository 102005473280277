import { FC, useContext } from 'react';
import { MonitoringMainTableContainer } from '../containers/tables/main-table';
import { activeVehicleIDSelector, vehicleEventsSelector } from '../selectors';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { MonitoringDetailsContext } from './context';
import { ActiveType, TableRecord } from '@common/types';
import { setActiveVehicleRoutine } from '../actions';

interface Props {}

/**
 * Контейнер по работе с основной таблицей
 */
export const MainTableContainer: FC<Props> = ({}) => {
  const VehicleEvents = useAppSelector(vehicleEventsSelector);
  const activeVehicleID = useAppSelector(activeVehicleIDSelector);
  const { handleSelectedRowKeys, handleDoubleClick, rowClass } = useContext(MonitoringDetailsContext) ?? {};
  const dispatch = useAppDispatch();

  const handleSelectedElements = (record: TableRecord) => {
    if (Array.isArray(record) && record.length) {
      const focus = record.find((r) => r.selectType === ActiveType.Focus);
      if (focus && focus.key?.toString() !== activeVehicleID?.toString()) {
        dispatch(
          setActiveVehicleRoutine({
            activeVehicleID: focus.key?.toString()
          })
        );
      }
    }
  };

  return (
    <MonitoringMainTableContainer
      dataSource={VehicleEvents?.data}
      activeVehicleID={activeVehicleID}
      onSelectRows={handleSelectedRowKeys}
      handleDoubleClick={handleDoubleClick}
      handleSelectedElements={handleSelectedElements}
      rowClass={rowClass}
    />
  );
};
