import { PlanTrackTipProps, VehicleTipProps, WPTipProps } from '../../types/utils';

type createTipBlock = (params: { valueFirst?: string; valueLast?: string; label?: string; labelClass?: string; isShow: boolean }) => string;
const createTipBlock: createTipBlock = ({ valueFirst, valueLast, label, labelClass, isShow = true }) => {
  if (!isShow) return '';
  return `<div class="row">
  ${label ? `<div class="label ${labelClass}">${label}:</div>` : ''}
  ${valueFirst ? `<div class="value${valueLast ? ' first' : ''}">${valueFirst}</div>` : ''} 
  ${valueLast ? `<div class="value${valueFirst ? ' last' : ''}">${valueLast}</div>` : ''}
</div>`;
};

// Тултип точки
export const waypointTooltip = (props: WPTipProps) => {
  return `<div class="point-tip font-13">
  ${createTipBlock({
    valueFirst: props.vehicleName,
    label: 'Название автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.vehicleName)
  })}
  ${createTipBlock({
    valueFirst: props.tripID,
    label: 'Номер рейса:',
    labelClass: 'short',
    isShow: Boolean(props.tripID)
  })}
  ${createTipBlock({
    valueFirst: props.name,
    label: 'Название точки',
    labelClass: 'short',
    isShow: Boolean(props.name)
  })}
  ${createTipBlock({
    valueFirst: props.DistanceFromPrevPoint + ' км',
    label: 'Расстояние до предыдущей',
    isShow: Boolean(props.DistanceFromPrevPoint)
  })}
    </div>`;
};

// Тултип автомобиля
export const vehicleTooltip = (props: VehicleTipProps) => {
  return `<div class="point-tip font-13">
  ${createTipBlock({
    valueFirst: props.name,
    label: 'Название автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.name)
  })}
  ${createTipBlock({
    valueFirst: props.vehicleRegNumber,
    label: 'Номер автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.vehicleRegNumber)
  })}
  ${createTipBlock({
    valueFirst: props.speed + 'км/ч',
    label: 'Скорость автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.speed)
  })}
  ${createTipBlock({
    valueFirst: props.latitude,
    valueLast: props.longitude,
    label: 'Координаты',
    labelClass: 'short',
    isShow: Boolean(props.longitude && props.latitude)
  })}
  ${createTipBlock({
    valueFirst: props.changed,
    label: 'Последнее обновление',
    labelClass: 'short',
    isShow: Boolean(props.changed)
  })}
  </div>`;
};

// Тултип планового трека
export const planTrackTooltip = (props: PlanTrackTipProps) => {
  return `<div class="point-tip font-13">
  ${createTipBlock({
    valueFirst: props.name,
    label: 'Название автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.name)
  })}
  ${createTipBlock({
    valueFirst: props.vehicleRegNumber,
    label: 'Номер автомобиля',
    labelClass: 'short',
    isShow: Boolean(props.vehicleRegNumber)
  })}
  ${createTipBlock({
    valueFirst: props.tripID,
    label: 'Номер рейса',
    labelClass: 'short',
    isShow: Boolean(props.tripID)
  })}
  ${createTipBlock({
    valueFirst: props.prevDepotName,
    label: 'Предыдущая точка',
    labelClass: 'short',
    isShow: Boolean(props.prevDepotName)
  })}
  ${createTipBlock({
    valueFirst: props.nextDepotName,
    label: 'Следующая точка',
    labelClass: 'short',
    isShow: Boolean(props.nextDepotName)
  })}
  ${createTipBlock({
    valueFirst: props.latitude,
    valueLast: props.longitude,
    label: 'Координаты',
    labelClass: 'short',
    isShow: Boolean(props.longitude && props.latitude)
  })}
  </div>`;
};
