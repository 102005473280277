import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/account/store';
import { userRoutine } from '@modules/account/actions';
import { filterByID, onFailure, onFulfill, onRequest, replaceUpdatedItem } from '@common/utils';
import { UserReducerPayload } from '@modules/account/types/api';
import { DictionariesEndpoint } from '@common/types';

export const userReducer = createReducer(initialState, {
  [userRoutine.REQUEST]: onRequest,
  [userRoutine.SUCCESS]: (state, action: UserReducerPayload) => {
    const { data, operation, id } = action.payload;
    switch (operation) {
      case DictionariesEndpoint.Index:
        state.users = data;
        break;
      case DictionariesEndpoint.Update:
        state.users = replaceUpdatedItem(data[id], state.users);
        break;
      case DictionariesEndpoint.Create:
        state.users = state.users.concat(data);
        break;
      case DictionariesEndpoint.Delete:
        state.users = filterByID(state.users, [id]);
        break;

      default:
        break;
    }
    return state;
  },
  [userRoutine.FAILURE]: onFailure,
  [userRoutine.FULFILL]: onFulfill
});
