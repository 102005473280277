import { createContext } from 'react';
import { AnalyticsPlanningContextType } from '../types/context';

const empty = () => {};

const initialContext: AnalyticsPlanningContextType = {
  handleSelectBoardSet: empty,
  handleSelectBoard: empty,
  handleDeleteBoardSet: empty,
  handleEditBoard: empty,
  handleEditSet: empty,
  handleEditBoardList: empty,
  handleCreateBoard: empty,
  handleCopyBoard: empty,
  handleDeleteBoard: empty,
  handleUpdateBoard: empty,
  handleDisableBoard: empty,
  editSet: false,
  editBoardList: false,
  handleSetParams: empty,
  handleGetWidgetData: empty
};

export const AnalyticsPlanningContext = createContext<AnalyticsPlanningContextType>(initialContext);
