import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringCreateVehicleTrackerLocalRoutine } from '../actions';

// Создаёт новую строчку в таблице привязок трекеров
export const monitoringCreateVehicleTrackerLocalReducer = createReducer(initialState, {
  [monitoringCreateVehicleTrackerLocalRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const { data, VehicleID } = action.payload;
    state.vehicleTrackers.data = [...(data || []), { VehicleID, TrackerIMEI: '', ID: 'new-row' }];
    return state;
  },
  [monitoringCreateVehicleTrackerLocalRoutine.FAILURE]: (state) => {
    return state;
  }
});
