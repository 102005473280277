import { TenderState } from '@modules/tender-platform/types';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';

const initialState: TenderState = {
  tenderAction: TenderAction.Start,
  currentTender: null,
  currentTenderDrivers: [],
  currentTenderVehicles: [],
  accountUsers: {},
  vehicleTypes: {},
  documents: {},
  isFetching: false,
  error: [],
  tenderTemplateData: null,
  temporaryLotDistance: null
};

export default initialState;

