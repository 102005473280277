import './styles.less';

export const LogisticServicesTrafficLightBadge = () => {
  return (
    <div className="logistic-services__card-badge logistic-services__card-badge_traffic-light">
      <img src={`${process.env.PUBLIC_URL}/assets/images/traffic-light.png`} />
    </div>
  );
};

