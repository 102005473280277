import { FC, RefObject } from 'react';
import { ILSCell } from './ils-cell';
import classNames from 'classnames';
import cn from 'classnames';
import { MULTIPLE_EDIT_ROW_CLASSNAME } from '../constants';
import { TableColumnName } from '@common/types';
import { SelectedRowKeys } from '@common/types/components/table/table';
import { useMultipleRow } from '../hooks/row/use-multiple-row';
import { ArgsHandleSave } from '@common/types/components/table/common';
import { ColumnsType } from 'antd/lib/table';
import { getMultipleCellData } from '../utils/decorators/multie-edit-cell-decorator';

interface Props {
  tableRef?: RefObject<HTMLDivElement> | null | undefined;
  mappedCols: ColumnsType<any> | undefined;
  selectedRowKeys: SelectedRowKeys;
  bordered?: boolean | undefined;
  pagination: boolean;
}

export const ILSMultipleEditRow: FC<Props> = ({ tableRef, mappedCols, selectedRowKeys, bordered, pagination }) => {
  const { row, colWidths, tableRow, cols, multipleCellData, onMultipleCellData } = useMultipleRow({
    selectedRowKeys,
    mappedCols,
    tableRef
  });
  return (
    <div
      id={MULTIPLE_EDIT_ROW_CLASSNAME}
      className={classNames(MULTIPLE_EDIT_ROW_CLASSNAME, 'row', { 'with-pagination': pagination })}
      ref={row}
    >
      <tr className="row" style={{ width: tableRow?.clientWidth }}>
        <td style={{ width: colWidths?.[0] + Number(bordered) }} className={cn('cell cell-multiple-edit-row first', { bordered })}>
          Редакт:
        </td>
        {cols?.map(({ dataIndex, editable, align, onCell }, index) => {
          const width = colWidths?.[index + 1] + Number(bordered);
          const className = cn('cell cell-multiple-edit-row', { bordered, gray: !editable });
          const { cell, record } = getMultipleCellData({
            onCell,
            index,
            selectedRowKeys,
            dataIndex,
            multipleCellData
          });

          return (
            cell && (
              <ILSCell
                {...cell}
                style={{ width, textAlign: align }}
                className={className}
                record={record}
                handleSave={(...args: ArgsHandleSave) => {
                  if (cell.handleSave) {
                    onMultipleCellData({
                      dataIndex: dataIndex as TableColumnName,
                      value: args[2],
                      type: cell.type,
                      format: cell.format,
                      dictionary: args[4],
                      cellRecord: args[args.length - 1]
                    });
                    cell.handleSave(...args);
                  }
                }}
                editable={Boolean(editable)}
                children={[<></>, multipleCellData[dataIndex]?.value]}
                key={dataIndex}
                reference={undefined}
                // @ts-ignore
                multipleCell
              />
            )
          );
        })}
      </tr>
    </div>
  );
};
