import { FC } from 'react';
import cn from 'classnames';
import { ILSAvatar, ILSCard, ILSCardMeta } from '@common/components';
import { NO_ACCOUNT_ROLES } from '@modules/account/constants/user';
import { AccountUserCardDescriptionComponent } from '@modules/account/components/user/user-description';
import { renderAccountUserCardActions } from '@modules/account/containers/user/actions';
import { Avatar } from '@modules/account/types';
import { User } from '@common/types';

interface IProps {
  roles: string;
  avatar: Avatar;
  isCurrentUserCard: boolean;
  active: boolean;
  user: User;
  phone: User['Phone'];
  email: User['Login'];
  userID: User['ID'];
  name: User['Name'];

  toggleForm(show: boolean, user: User | null): void;
}

export const AccountUserCardComponent: FC<IProps> = ({
  phone,
  active,
  roles,
  email,
  isCurrentUserCard,
  userID,
  name,
  avatar,
  user,
  toggleForm
}) => {
  return (
    <ILSCard
      actions={isCurrentUserCard ? [] : renderAccountUserCardActions({ user, toggleForm })}
      title={name}
      extra={`(${roles || NO_ACCOUNT_ROLES})`}
      key={userID}
      className={cn('user', { active })}
    >
      <ILSCardMeta
        avatar={<ILSAvatar className={'user-avatar'} src={avatar} />}
        description={<AccountUserCardDescriptionComponent phone={phone} email={email} />}
      />
    </ILSCard>
  );
};
