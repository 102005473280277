import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { trackGetDepotsRoutine } from '../actions';
import { TrackStore } from '@modules/track/types';
import { Depot, IndexedArray } from '@common/types';
import { isArray } from 'lodash';

export const trackGetDepotsReducer = createReducer(initialState, {
  [trackGetDepotsRoutine.REQUEST]: (state: TrackStore) => {
    state.isFetching = true;
    return state;
  },
  [trackGetDepotsRoutine.SUCCESS]: (state: TrackStore, action: PayloadAction<{ depots: IndexedArray<Depot> }>) => {
    const { depots } = action.payload;
    state.Depots = isArray(depots)
      ? depots.reduce((depots, depot) => {
          depots[depot.ID] = depot;
          return depots;
        }, {})
      : depots;
    state.lastUpdate = Date.now();
    return state;
  },
  [trackGetDepotsRoutine.FAILURE]: (state: TrackStore, action) => {
    const { error } = action.payload;
    state.error = error;
    return state;
  },
  [trackGetDepotsRoutine.FULFILL]: (state: TrackStore) => {
    state.isFetching = false;
    return state;
  }
});
