import { FC, useState } from 'react';
import { ILSHeader, ILSLayout, ILSSider } from '@components/index';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';

const ILSDashboard: FC = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <ILSLayout>
      <ILSSider collapsible collapsed={collapsed} onCollapse={toggleCollapsed} className={'ils-sidebar'}>
        <Scope name={ModuleScope.Sider} extraProps={{ collapsed, toggleCollapsed, ...props }} />
      </ILSSider>
      <ILSLayout className="ils-wrapper">
        <ILSHeader>
          <Scope name={ModuleScope.Header} />
        </ILSHeader>
      </ILSLayout>
    </ILSLayout>
  );
};

export default ILSDashboard;
