import { Result } from 'antd';

const MonitoringSliderContainer = () => {
  return (
    <div className="ils-chart-table">
      <Result status="warning" title="Скоро тут появится слайдер..." />
    </div>
  );
};

export default MonitoringSliderContainer;
