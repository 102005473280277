import { ILSButton } from '@common/components/general/buttons';
import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';

type ClearFilterButtonProps = {
  isFetching: boolean;
  clearFilters(): void;
  clearFilterDisabled: boolean;
  withText?: boolean | undefined;
};

export const ILSClearFilterButton: FC<ClearFilterButtonProps> = ({ withText, isFetching, clearFilters, clearFilterDisabled }) => {
  if (withText) {
    return (
      <ILSButton loading={isFetching} className="clearFilters" onClick={clearFilters}>
        Очистить все фильтры
      </ILSButton>
    );
  }

  return (
    <ILSButtonTooltip
      loading={isFetching}
      placement="bottom"
      title="Очистить все фильтры"
      disabled={clearFilterDisabled}
      onClick={clearFilters}
      className="ils-clear-filter"
      icon={'clear-filter'}
    />
  );
};
