import { Option, Options, ReferenceTableData, TableColumnName } from '@common/types';
import { isOption } from '@common/utils';
import { isArray } from 'lodash';

type Arg = {
  referenceData: Options | undefined;
  dataIndex: string;
  refData: Partial<Record<Partial<TableColumnName>, ReferenceTableData>> | undefined;
};

export const createVariantsData = ({ referenceData, dataIndex, refData }: Arg) => {
  let filterVariants: any[] = [];
  if (referenceData && referenceData?.length > 1) {
    filterVariants = referenceData;
  } else if (refData?.[dataIndex]) {
    if ('function' === typeof refData[dataIndex]) {
      filterVariants = refData[dataIndex]();
    } else if (refData[dataIndex].data) {
      filterVariants = refData[dataIndex].data;
    } else if (refData[dataIndex].treeData) {
      const treeData: Array<{ children: any }> = Object.values(refData[dataIndex].treeData);
      treeData?.forEach((row) => {
        if (row.children?.length) {
          filterVariants = filterVariants.concat(row.children);
        }
      });
    } else {
      filterVariants = refData[dataIndex];
    }
  } else {
    filterVariants = referenceData ?? [];
  }
  if (isArray(filterVariants)) {
    filterVariants = filterVariants.map((item: Option | (object & { ID: number; Name?: string })) => {
      if (isOption(item)) {
        return item;
      } else {
        return { label: item.Name ?? `№${item.ID}`, value: item.ID };
      }
    });
  } else {
    filterVariants = [];
  }

  return { filterVariants };
};
