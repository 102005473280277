import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { RouteTestStore } from '../types';
import { roadGraphAddForbiddenZoneRoutine } from '@modules/road-graph/route-test/actions';
import { PayloadActionError } from '@common/types';

const roadGraphAddForbiddenZone = createReducer(initialState, {
  [roadGraphAddForbiddenZoneRoutine.REQUEST]: (state: RouteTestStore) => {
    state.isFetching = true;
    return state;
  },
  [roadGraphAddForbiddenZoneRoutine.SUCCESS]: (state: RouteTestStore) => {
    state.isFetching = false;
    return state;
  },
  [roadGraphAddForbiddenZoneRoutine.FAILURE]: (state: RouteTestStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isFetching = false;
    state.error = error;
    return state;
  },
  [roadGraphAddForbiddenZoneRoutine.FULFILL]: (state: RouteTestStore) => {
    state.error = [];
    return state;
  }
});

export default roadGraphAddForbiddenZone;
