import { NotifyDetailedContent } from '@core/types/actions';
import { isEmpty, isObject } from 'lodash';

export const isNotifyDetailedContent = (content: any): content is NotifyDetailedContent => {
  return !!(
    content &&
    isObject(content) &&
    'fullContent' in (content as NotifyDetailedContent) &&
    'shortContent' in (content as NotifyDetailedContent) &&
    !isEmpty((content as NotifyDetailedContent).fullContent) &&
    !isEmpty((content as NotifyDetailedContent).shortContent)
  );
};
