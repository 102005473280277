import { DefaultPlannerConfigsResponse } from '@modules/planning/types/api';
import { TPlanningConfig } from '../../types/store';
import { Provider } from '@common/types';

/**
 * Получение значений параметров планирования по умолчанию для выбранного источник планирования
 * @param defaultPlannerConfigs доступные настройки планирования полученные с
 * @param provider источник планирования ILS | Veeroute и другие
 * @returns объект параметров startPlanning
 */
export const getDefaultPlanConfig = (
  defaultPlannerConfigs:
    | {
        lastUpdate: number;
        loading: boolean;
        data?: DefaultPlannerConfigsResponse | undefined;
      }
    | null
    | undefined,
  provider: Provider = Provider.ILS
): {
  planConfigID: string | undefined;
  provider: Provider;
  operation: string | undefined; // Тип планирования по умолчанию
  params: Partial<TPlanningConfig['params']>; // Значения по умолчанию для параметров планирования
} => {
  const ConfigID =
    defaultPlannerConfigs?.data?.[provider]?.configs && Object.keys(defaultPlannerConfigs?.data?.[provider]?.configs).length
      ? Object.keys(defaultPlannerConfigs.data[provider].configs)[0]
      : undefined;
  const params =
    defaultPlannerConfigs?.data?.[provider]?.params && Object.keys(defaultPlannerConfigs?.data?.[provider]?.params).length
      ? Object.keys(defaultPlannerConfigs?.data?.[provider]?.params).reduce((acc, paramKey) => {
          acc[paramKey] = defaultPlannerConfigs?.data?.[provider]?.params[paramKey].default;
          return acc;
        }, {})
      : {};
  const operation: string | undefined =
    defaultPlannerConfigs?.data?.[provider]?.operation?.default ||
    defaultPlannerConfigs?.data?.[provider]?.operation?.range?.[0]?.value ||
    undefined;

  return { planConfigID: ConfigID, provider, params, operation };
};
