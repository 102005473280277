import { createContext, FC } from 'react';
import { ILSContent, ILSLayout } from '@components/index';
import { renderRoutes } from 'react-router-config';
import { IPageProps } from '@pages/types';

export const RouteContext = createContext<IPageProps | null>(null);

const CustomForNonAuthPage: FC<IPageProps> = (props) => {
  return (
    <ILSLayout>
      <ILSLayout className="ils-wrapper">
        <ILSContent className="ils-content">
          {/* контекст для передачи навигации на рабочий стол  */}
          <RouteContext.Provider value={props}>
            {/* child routes won't render without this */}
            {renderRoutes(props.route.routes)}
          </RouteContext.Provider>
        </ILSContent>
      </ILSLayout>
    </ILSLayout>
  );
};

export default CustomForNonAuthPage;
