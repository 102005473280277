import { INotifyPayload } from "@core/types/actions";
import { createRoutine } from "redux-saga-routines";

/**Рутина для отображения уведомлений пользователю
 * @trigger  вызывает уведомление
 * @fulfill  закрывает уведомление по переданному ключу
 */
export const userNotifyRoutine = createRoutine('CORE/NOTIFY', {
  trigger: (payload: INotifyPayload) => ({ ...payload }),
  fulfill: (payload: { key: string }) => ({ ...payload })
});
