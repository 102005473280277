import { Dictionary, IndexedArray, Zone, ZoneType } from '@common/types';
import { ICatalogDataItem } from '../types/catalog';
import { isObject, pickBy } from 'lodash';
import { compareAsString } from '@common/utils';

type FilterCatalogDataType = <V extends Array<K> | IndexedArray<K>, K extends ICatalogDataItem>(data: V, dictionary: Dictionary) => V;

// @ts-ignore
export const filterCatalogData: FilterCatalogDataType = (data, dictionary) => {
  switch (dictionary) {
    case Dictionary.DeliveryZone:
      // @ts-ignore
      return filterCatalogObjOrArray(data, isDeliveryZone);

    case Dictionary.SurveillanceZone:
      // @ts-ignore
      return filterCatalogObjOrArray(data, isSurveillanceZone);

    default:
      return data;
  }
};

type FilterCatalogObjOrArrayType = <V extends Array<K> | IndexedArray<K>, K extends ICatalogDataItem>(
  data: V,
  filter: (item: K) => Boolean
) => V;
const filterCatalogObjOrArray: FilterCatalogObjOrArrayType = (data, filterFunc) => {
  if (Array.isArray(data)) {
    return data.filter(filterFunc) as typeof data;
  } else if (isObject(data)) {
    return pickBy(data, filterFunc) as typeof data;
  }
  return data;
};

export const isDeliveryZone = (item: ICatalogDataItem & Partial<Omit<Zone, 'ID'>>) => compareAsString(item.Type, ZoneType.Delivery);
export const isSurveillanceZone = (item: ICatalogDataItem & Partial<Omit<Zone, 'ID'>>) => compareAsString(item.Type, ZoneType.Surveillance);
