import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { tenderIndexRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { Tender } from '@modules/tender-platform/types';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const indexTender = createReducer(initialState, {
  [tenderIndexRoutine.REQUEST]: onRequest,
  [tenderIndexRoutine.FAILURE]: onFailure,
  [tenderIndexRoutine.FULFILL]: onFulfill,
  [tenderIndexRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      data: { [key: string]: Tender };
    }>
  ) => {
    const { data } = action.payload;
    state.tenders = data;
    return state;
  }
});
