import { trackDataSelector } from '@modules/road-graph/route-test/selectors';
import { useAppSelector } from '@core/hooks';

const ILSRoadGraphTestSummaryInfo = () => {
  const trackData = useAppSelector(trackDataSelector);
  const { waypoints, routes } = trackData ?? {};

  let startPoint,
    endPoint,
    distance,
    calcTime,
    minTime,
    speeds: number[] = [];

  if (waypoints && waypoints[0]?.location) {
    const coords = waypoints[0].location.slice();
    startPoint = coords.reverse().join(', ');
  }
  if (waypoints && waypoints[waypoints.length - 1]?.location) {
    const coords = waypoints[waypoints.length - 1]?.location.slice();
    endPoint = coords.reverse().join(', ');
  }
  if (routes && routes[0].distance) {
    const routeDistance = routes[0].distance;
    const km = Math.floor(routeDistance / 1000);
    const m = routeDistance - km * 1000;
    distance = km + 'км ' + (Math.floor(m) + 'м');
  }
  if (routes && routes[0].duration) {
    const routeDuration = routes[0].duration;
    const days = Math.floor(routeDuration / 86400);
    const hours = Math.floor((routeDuration - days * 86400) / 3600);
    const minutes = Math.floor((routeDuration - days * 86400 - hours * 3600) / 60);
    minTime = (days ? days + 'д ' : '') + (hours ? hours + 'ч ' : '') + (minutes ? minutes + 'мин ' : '');
  }
  if (trackData?.startTime && trackData?.endTime) {
    const time = trackData?.endTime - trackData?.startTime;
    let minutes = new Date(time).getMinutes();
    let seconds = new Date(time).getSeconds();
    calcTime = (minutes ? minutes + 'мин ' : '') + (seconds ?? 1) + 'сек';
  }
  if (routes && routes![0].legs![0].steps) {
    routes![0].legs![0].steps.forEach((leg) => {
      if (Number(leg.speed) && !speeds.includes(leg.speed)) {
        speeds.push(leg.speed);
      }
    });
  }

  return (
    <>
      {trackData?.routes && trackData?.waypoints && (
        <div className="summary-info p-3 rounded bordered">
          <h3>Информация</h3>
          <div>
            <div>
              <b>Начало: </b>
              <span>{startPoint}</span>
            </div>
            <div>
              <b>Конец: </b>
              <span>{endPoint}</span>
            </div>
            <div>
              <b>Протяженность: </b>
              <span>{distance}</span>
            </div>
            <div>
              <b>Скорости: </b>
              <div className="speeds" title={speeds.join(';')}>
                {speeds.join('; ')}
              </div>
            </div>
            <div>
              <b>Мин.время: </b>
              <span>{minTime}</span>
            </div>
            {/*<div>*/}
            {/*	<b>Исп.зоны маяков: </b>*/}
            {/*	<span></span>*/}
            {/*</div>*/}
            <div>
              <b>Время расчета: </b>
              <span>{calcTime}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ILSRoadGraphTestSummaryInfo;
