import { planningGetPlanRoutine, planningReloadRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningAPI } from '@modules/planning/api';
import { Dictionary } from '@common/types';

function* planningReloadWorker(
  action: PayloadAction<{
    planID: number;
  }>
) {
  const { request, failure, fulfill, success } = planningReloadRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });

    yield put(planningGetPlanRoutine({ ID: planID }));

    yield put(success({ planTotal }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningReloadWatcher() {
  yield takeLatest(planningReloadRoutine.trigger, planningReloadWorker);
}
