import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, ModulePath, Notify, NotifyDurationInSecond } from '@common/types';
import { monitoringEventDetailsRoutinesToNotify } from '../../actions';
import { EventDetailsAction } from '../../types/api';
import { defaultStatuses } from '../../constants/default-messages';
import { getErrorMessage } from '@common/utils';

/**Метод получения текста уведомления для разных экшенов*/
//TODO общая проблема уведомлений
const getDataNotificationMessage = (
  action: EventDetailsAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || defaultStatuses?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): EventDetailsAction => {
  return actionName as EventDetailsAction;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, EventDetailsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Monitoring}${ModulePath.MonitoringDetails}`,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, EventDetailsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Monitoring}${ModulePath.MonitoringDetails}`,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* monitoringEventDetailsNotifierWatcher() {
  for (let routine of monitoringEventDetailsRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
