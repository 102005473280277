import { ILSMap } from '@core/containers/map';
import { MutableRefObject, useEffect } from 'react';

export const useMapInit = (Map: MutableRefObject<any>) => {
  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    curMap.addRuler();

    return () => Map.current.deinit();
  }, []);
};

export default { useMapInit };

