import { createContext } from 'react';
import { ActiveRoad } from '@modules/road-graph/editor/types';

interface RoadGraphEditorContextValues {
  edit: null | ActiveRoad;
  setEdit: (s: null | ActiveRoad) => void;
  showDepots: boolean;
  setShowDepots: (s: boolean) => void;
}

export const RoadGraphEditorContext = createContext<RoadGraphEditorContextValues | null>(null);
