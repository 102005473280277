import { Tender } from '@modules/tender-platform/types';
import { stringifyUrl } from 'query-string';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { Action, DictionariesEndpoint, ModulePath } from '@common/types';
import { tenderChangeRoutine, tenderGetVehicleTypesRoutine } from '@modules/tender-platform/actions';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { History } from 'history';

export const redirectToTenderCreation =
  ({ dispatch, push }: { dispatch: Dispatch; push: History['push'] }) =>
  () => {
    const link = stringifyUrl({
      url: `${ModulePath.TenderPlatform}${ModulePath.TenderOwn}`,
      query: {
        [TenderAction.Tender]: Action.Create
      }
    });
    dispatch(tenderGetVehicleTypesRoutine());
    push(link);
  };

export const useTenderOpen = () => {
  const { url, push } = useAppHistory();
  const dispatch = useDispatch();

  const handleOpen = (_: any, { ID }: Tender) => {
    dispatch(tenderChangeRoutine({ operation: DictionariesEndpoint.Reset }));
    const link = stringifyUrl({
      url,
      query: {
        [TenderAction.Tender]: ID
      }
    });
    push(link);
  };

  return { handleOpen, redirectToTenderCreation: redirectToTenderCreation({ dispatch, push }) };
};
