import { isTitledCell } from '@components/data-display/table/constants';
import { errorCell } from '@components/data-display/table/utils';
import { ColumnTable, Dictionary, ICellProps, IILSTableComponentProps, IILSTableProps, TableRecord, ValidationData } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { ICellSelectProps } from '@common/types/components/table/cell';
import { SetShowReference } from '@common/types/components/table/common';
import { SelectProps } from 'antd/lib/select';
import { IILSSelectProps } from '@components/data-entry/select/types';

export interface HandleCellProps<R extends TableRecord>
  extends Pick<
    IILSTableComponentProps<R>,
    | 'activeCell'
    | 'cellClassName'
    | 'dictionaryLoad'
    | 'dictionaryInfo'
    | 'handleSave'
    | 'onCanEdit'
    | 'refTable'
    | 'dragInfo'
    | 'dontEdit'
    | 'tableRef'
  > {
  fieldNames: SelectProps['fieldNames'];
  options: IILSSelectProps['options'];
  rowIndex: number | undefined;
  record: R;
  refTable: IILSTableProps<R>['refTable'];
  errors: ValidationData[] | undefined;
  column: ColumnTable<R>;
  referenceData: ICellSelectProps<R>['referenceData'];
  setShowReference: SetShowReference<R>;
}

export const handleCell = <R extends TableRecord>({
  record,
  rowIndex,
  cellClassName,
  dontEdit,
  onCanEdit,
  handleSave,
  refTable,
  errors,
  column,
  referenceData,
  dragInfo,
  setShowReference,
  activeCell,
  tableRef,
  dictionaryLoad,
  dictionaryInfo,
  options,
  fieldNames
}: HandleCellProps<R>): ICellProps<R> => {
  const {
    selectProps,
    validationParameters,
    ellipsis,
    tooltip,
    dataIndex,
    lineSeriesProps,
    allowClear,
    placeholder,
    style,
    key,
    fixed,
    type,
    droppable,
    minField,
    dateParseFormat,
    format,
    maxField,
    countdown,
    editable,
    title,
    showParent
  } = column;

  return {
    columnKey: key ?? dataIndex,
    editable: !dontEdit && (onCanEdit?.(record, String(key)) ?? editable),
    title: title ?? (record[dataIndex] && (isTitledCell(type ?? key) || ellipsis) ? record[dataIndex] : EMPTY_STRING),
    reference: refTable?.[dataIndex],
    error: errorCell(record, errors, dataIndex),
    tooltip: tooltip && ((tooltip.field && record[tooltip.field]) ?? tooltip.text),
    dragInfo:
      droppable &&
      dragInfo?.key &&
      dragInfo.handler &&
      (!dragInfo.dictionary || dragInfo.dictionary.includes(record.dictionary ?? Dictionary.Empty))
        ? dragInfo
        : null,
    selectProps: {
      ...selectProps,
      dictionaryLoad,
      dictionaryInfo,
      referenceData
    },
    record,
    rowIndex,
    handleSave,
    setShowReference,
    dictionary: referenceData?.dictionary ?? Dictionary.Empty,
    cellClassName,
    activeCell,
    tableRef,
    dictionaryLoad,
    dictionaryInfo,
    column,
    fieldNames,
    options,
    validationParameters,
    ellipsis,
    type,
    format,
    placeholder,
    style,
    dataIndex,
    countdown,
    allowClear,
    maxField,
    minField,
    dateParseFormat,
    fixed,
    lineSeriesProps,
    showParent
  };
};
