import { createReducer } from '@reduxjs/toolkit';
import { monitoringSetTripStatusRoutine } from '@modules/monitoring/actions';
import { initialState } from '@modules/monitoring/store';
import { MonitoringState } from '@modules/monitoring/types/store';

export const monitoringSetTripStatusReducer = createReducer<MonitoringState>(initialState, {
  [monitoringSetTripStatusRoutine.REQUEST]: (state) => {
    return state;
  },
  [monitoringSetTripStatusRoutine.SUCCESS]: (state) => {
    return state;
  },
  [monitoringSetTripStatusRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringSetTripStatusRoutine.FULFILL]: (state) => {
    return state;
  }
});
