import { emulatorCreateConfigRoutine, emulatorGetConfigRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { CreateConfigRequest } from '../types/api';

// Создаёт конфигурацию эмуляции у выбранного плана и записывает выбранную скорость эмуляции (emulationSpeed)
function* emulatorCreateConfigWorker(action: PayloadAction<CreateConfigRequest>) {
  const { request, success, failure, fulfill } = emulatorCreateConfigRoutine;
  const { planID, emulationSpeed } = action.payload;

  try {
    yield put(request());

    yield call(EmulatorAPI.createConfig, { planID, emulationSpeed });

    yield put(success({ planID, emulationSpeed }));

    yield put(emulatorGetConfigRoutine.trigger({ planID }));
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorCreateConfigWatcher() {
  yield takeLatest(emulatorCreateConfigRoutine.TRIGGER, emulatorCreateConfigWorker);
}
