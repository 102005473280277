import { ILSButton, ILSContent, ILSHeader, ILSLayout, ILSSider, ILSTypographyText } from '@common/components';
import history from '@common/utils/history';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import { Component, ErrorInfo } from 'react';
import { ILSTypographyParagraph, ILSTypographyTitle } from '@components/general/typography';
import { MessageContent } from '@common/constants';

export class ErrorBoundary extends Component<any, { error: null | Error; errorInfo: null | ErrorInfo; collapse: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null, collapse: true };
  }

  toggleCollapsed() {
    this.setState({ collapse: false });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ILSLayout className="ils-wrapper">
          <ILSSider onCollapse={this.toggleCollapsed} collapsed={this.state.collapse} collapsible className={'ils-sidebar'} />
          <ILSLayout className="ils-wrapper">
            <ILSContent className="ils-content">
              <ILSHeader>
                <Scope name={ModuleScope.Header} />
              </ILSHeader>
              <div
                className="ils-page-content"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '1vh'
                }}
              >
                <h2 style={{ fontSize: '5em' }}>
                  <ILSTypographyText>{MessageContent.SomethingWentWrong}</ILSTypographyText>
                </h2>
                <ILSButton type={'primary'} style={{ width: '50%' }} onClick={history.goBack}>
                  Назад
                </ILSButton>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  <ILSTypographyTitle level={5}>{this?.state?.error?.toString()}</ILSTypographyTitle>
                  <ILSTypographyParagraph children={this?.state?.errorInfo.componentStack?.toString()} />
                </details>
              </div>
            </ILSContent>
          </ILSLayout>
        </ILSLayout>
      );
    }
    return this.props.children;
  }
}
