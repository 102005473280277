import { createRoutine } from 'redux-saga-routines';
import { SettingsAction } from '@modules/settings/types';

export const settingsGetRoutine = createRoutine(SettingsAction.GET_SETTINGS);

export const settingsSaveRoutine = createRoutine(SettingsAction.SAVE_SETTINGS);

export const passwordChangeRoutine = createRoutine(SettingsAction.CHANGE_PASSWORD);

export const mobileApplicationDictionaryOperationRoutine = createRoutine(SettingsAction.MOBILE_APPLICATION_DICTIONARY_OPERATION);

export const mobileApplicationChangeFocusRowRoutine = createRoutine(SettingsAction.CHANGE_FOCUS_ROW);

export const tableConfigSaveSettingsRoutine = createRoutine(SettingsAction.TABLE_CONFIG);

/**Рутины для которых нужно отображать уведомления */
export const settingsRoutinesWithNotifier = [
  settingsGetRoutine,
  settingsSaveRoutine,
  passwordChangeRoutine,
  mobileApplicationDictionaryOperationRoutine,
  tableConfigSaveSettingsRoutine
];

