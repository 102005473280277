import { ILSPlanningCreateProjectButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/create-project';
import { PlanningPlannerVehicleMonitoringSendLinksButtonContainer } from '@modules/planning/children/by-date/components/result/table-head/buttons/vehicle-monitoring-send-links';
import { PlannerPlanningReloadPlanButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/reload';
import { PlannerPlanningCreatePlanButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/create-plan';
import { PlannerPlanningCopyPlanButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/copy';
import { PlannerPlanningDeletePlanButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/delete';
import { PlannerPlanningProjectTableForExecuteButtonContainer } from '@modules/planning/children/by-date/components/result/table-head/buttons/execute';
import { PlannerPlanningRampScheduleButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/ramp';
import { PlannerPlanningRestrictionMenu } from '@modules/planning/children/by-date/containers/result/context-menu/restriction';
import { PlannerPlanningUploadDataToCurrentProjectButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/upload-data';
import { PlannerPlanningOpenOrderTableButton } from '@modules/planning/children/by-date/containers/result/action-buttons/open-order-table';
import { checkVisibility } from '@common/utils';
import { DocumentsModalButton } from '@common/feature/documents-modal-button/documents-modal-button';
import { Module } from '@common/types';
import { useSelector } from 'react-redux';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { IPlanningResultTablesContainerProps } from '@modules/planning/children/by-date/types';
import { useHandleExecution } from '@modules/planning/children/by-date/hooks/tables/plans/use-handle-execution';
import { useDeletePlan } from '@modules/planning/children/by-date/hooks/tables/plans/use-delete-plan';
import { useCopyPlan } from '@modules/planning/children/by-date/hooks/tables/plans/use-copy-plan';
import { PlannerPlanningExportExcelDataContainer } from '@components/data-display/table/components/action-buttons/export-excel';

export const usePlansTableRecordButtons = ({
  handleShowOrderTable
}: {
  handleShowOrderTable: IPlanningResultTablesContainerProps['handleShowOrderTable'];
}) => {
  const { handleSetExecution, handleUnsetExecution } = useHandleExecution();
  const { handleDelete } = useDeletePlan();
  const { handleCopy } = useCopyPlan();
  const projectID = useSelector(activeProjectIdSelector);
  // Проверка отображение модуля Documents
  const documentsModalButton = checkVisibility({ MODULE: Module.Documents })
    ? [
        {
          Component: DocumentsModalButton,
          props: { projectID, module: Module.PlanningByDate }
        }
      ]
    : [];

  const tableButtons = [
    ...documentsModalButton,
    { Component: ILSPlanningCreateProjectButton },
    {
      Component: PlanningPlannerVehicleMonitoringSendLinksButtonContainer
    },
    {
      Component: PlannerPlanningReloadPlanButton
    },
    {
      Component: PlannerPlanningCreatePlanButton
    },
    {
      Component: PlannerPlanningCopyPlanButton,
      props: { handleCopy }
    },
    {
      Component: PlannerPlanningDeletePlanButton,
      props: { handleDelete }
    },
    {
      Component: PlannerPlanningProjectTableForExecuteButtonContainer,
      props: { handleSetExecution, handleUnsetExecution }
    },
    { Component: PlannerPlanningRampScheduleButton },
    {
      Component: PlannerPlanningRestrictionMenu
    },
    { Component: PlannerPlanningUploadDataToCurrentProjectButton },
    {
      Component: PlannerPlanningExportExcelDataContainer
    },
    { Component: PlannerPlanningOpenOrderTableButton, props: { handleShowOrderTable } }
  ];

  return { tableButtons };
};
