import { FC } from 'react';
import * as React from 'react';

interface IProps {
  children?: any;
}

// Обертка для компонентов таблиц для передачи стилей
export const EmulatorTableTopLayout: FC<IProps> = ({ children, ...props }) => {
  return (
    <div className="ils-emulator-table-top-layout" {...props}>
      {children}
    </div>
  );
};
