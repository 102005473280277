import { call, put, select, takeEvery } from 'redux-saga/effects';
import { tenderAppointResponsibleRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender } from '@modules/tender-platform/types';
import { IndexedArray, User } from '@common/types';
import { accountUsersSelector } from '@modules/tender-platform/selectors';

function* tenderAppointResponsibleWorker(
  action: PayloadAction<{
    tenderID: Tender['ID'];
    userID: User['ID'];
  }>
) {
  const { request, success, failure, fulfill } = tenderAppointResponsibleRoutine;
  const { tenderID, userID } = action.payload;
  try {
    yield put(request());

    yield call(TenderOwn.appointResponsible, { tenderID, userID });
    const users: IndexedArray<User> = yield select(accountUsersSelector);
    const User = users[userID];
    yield put(success({ tenderID, userID, User }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderAppointResponsibleWatcher() {
  yield takeEvery(tenderAppointResponsibleRoutine.trigger, tenderAppointResponsibleWorker);
}
