import { call, put, takeLatest } from 'redux-saga/effects';
import { vehicleMonitoringIndexRoutine } from '../actions';
import { VehicleMonitoring } from '../api';
import { IVehicleMonitoringIndexTriggerPayload } from '@modules/vehicle-monitoring/types/actions';

function* vehicleMonitoringIndexWorker(action: IVehicleMonitoringIndexTriggerPayload) {
  const { request, success, failure, fulfill } = vehicleMonitoringIndexRoutine;
  const { view } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(VehicleMonitoring.index, { view });

    yield put(success({ data, lastUpdate: Date.now() }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* vehicleMonitoringIndexWatcher() {
  yield takeLatest(vehicleMonitoringIndexRoutine.trigger, vehicleMonitoringIndexWorker);
}
