import scrollIntoView from 'scroll-into-view';

export function handleScroll(
  element,
  settings = {
    align: {
      top: 0,
      left: 0,
      topOffset: 0,
      leftOffset: 0
    }
  }
) {
  const someElement = document.querySelector(element);
  scrollIntoView(someElement, settings);
}
