import { ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { deleteTripRoutine } from '@modules/orders-and-trips/actions';
import { DeleteTripForm } from '@modules/orders-and-trips/components/forms/blocks/delete-trip';
import { DeleteTripFields } from '@modules/orders-and-trips/types/form';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { useForm } from 'antd/es/form/Form';
import { Dispatch } from 'redux';

type TArg = {
  dispatch: Dispatch;
  activeTripID?: string | number | undefined;
  status?: TripStatus;
};

export const useDeleteTrip = ({ dispatch, activeTripID, status }: TArg) => {
  const [form] = useForm<DeleteTripFields>();

  const deleteTrip = () => {
    ILSUserNotifyConfirm('Внимание', <DeleteTripForm form={form} />, () => {
      dispatch(deleteTripRoutine({ tripID: activeTripID, status, ...form.getFieldsValue() }));
    });
  };

  return { deleteTrip };
};
