import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import {
  projectCatalogDictionarySelector,
  projectIDSelector,
  projectInfoByDictionarySelector,
  projectInfoSelector
} from '@modules/planning/children/by-date/selectors/project';
import { Dictionary, IndexedArray, TableRecord } from '@common/types';

export const planningRestrictionsTableComponentSelector = createSelector(
  projectIDSelector,
  projectInfoSelector,
  (state: PlanningByDateState, dictionary: Dictionary) => projectCatalogDictionarySelector(state, dictionary),
  (state: PlanningByDateState, dictionary: Dictionary) => projectInfoByDictionarySelector(state, dictionary),
  (
    state: PlanningByDateState,
    dictionary: Dictionary,
    dataDecorator: (data: Array<unknown> | IndexedArray<unknown>) => Array<unknown & TableRecord>
  ) => dataDecorator,
  (projectID, info, catalog, dictionaryInfo, dataDecorator) => {
    const isFetching = dictionaryInfo?.isFetching;
    const dataSource = dataDecorator(catalog);
    return { isFetching, dataSource, projectID, info };
  }
);
