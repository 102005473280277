import {
  CrossDock,
  Depot,
  ForbiddenZone,
  ID,
  IndexedArray,
  Key,
  Keys,
  MarkerPlanning,
  MarkerType,
  Order,
  Vehicle,
  VehicleOverload,
  VehicleProfile,
  VehicleTimeline,
  Waypoint,
  Worktime,
  Zone
} from '@common/types';
import { ConvexPolygon, IMapTrack, MapClickData, ResolvedOrderMap } from '@common/types/components/map/map';
import { LayerHandlerResponse, Polygon } from '@core/types/map';
import { SelectedOrders } from '@modules/planning/types';
import L from 'leaflet';
import { MutableRefObject } from 'react';
import { PlanningParameters } from '@modules/settings/types/store';
import { PlanningSettingsKeys } from '@modules/settings/types';

export type MapActivePlan = {
  line: Pick<IMapTrack, 'ID' | 'weight' | 'coords' | 'color'>[];
  convexPolygon: ConvexPolygon[];
  track: IMapTrack[];
  orderMarkers: MarkerPlanning[];
  waypointMapData: Record<string, MapWaypointData>;
  depotIDsFromOrder: Set<Depot['ID']>;
  depotIDsFromWaypoint: Set<Depot['ID']>;
};

export type DragDataType = {
  type?: MarkerType;
  waypointID?: Waypoint['ID'] | Array<Waypoint['ID']> | null;
  vehicleID?: number | null;
  tripIds?: number[] | null;
  orderIDs?: SelectedOrders;
};
export type TrackDataType = {
  TripID?: number | null;
  VehicleID?: number | null;
  PlanID?: number | null;
  WaypointID?: number | null;
};

export type anyDataMapItemType = {
  data: MarkerPlanning['clickData'];
} | null;

export type MapMarkerPlanning = L.Marker & {
  options: {
    data?: MarkerPlanning['data'];
  };
};

export type PolylineItem = {
  poly: IMapTrack;
  lPoly: L.Polyline | undefined;
  lMarker?: MapMarkerPlanning;
  layer: L.LayerGroup | undefined;
  isDragOver?: boolean;
};
export type ConvexItem = {
  lConvex: L.Polygon;
  convex: Polygon;
  layer: L.LayerGroup | undefined;
};

export type MarkerItem = {
  mark: MarkerPlanning;
  lMark: MapMarkerPlanning;
  layer: L.MarkerClusterGroup | undefined;
};
export type MapLayers = {
  orderCluster: L.MarkerClusterGroup | undefined;
  clientCluster: L.MarkerClusterGroup | undefined;
  cluster: L.MarkerClusterGroup | undefined;
  storageCluster: L.MarkerClusterGroup | undefined;
  polylineGroup: L.LayerGroup | undefined;
  polygonGroup: L.LayerGroup | undefined;
  convexGroup: L.LayerGroup | undefined;
  isInit: boolean;
};

export type ProjectDataType = Partial<{
  CrossDock: IndexedArray<CrossDock> | null;
  Depot: IndexedArray<Depot> | null;
  FriendZone: IndexedArray<Zone> | null;
  ForbiddenZone: IndexedArray<ForbiddenZone> | null;
  Order: IndexedArray<Order> | null;
  Vehicle: IndexedArray<Vehicle> | null;
  VehicleOverload: IndexedArray<VehicleOverload> | null;
  VehicleProfile: IndexedArray<VehicleProfile> | null;
  VehicleTimeline: IndexedArray<VehicleTimeline> | null;
  Worktime: IndexedArray<Worktime> | null;
}>;

export type UseMapPairsUnresolvedType = (arg0: {
  Map: MutableRefObject<any>;
  markers: (
    | (MarkerPlanning & {
        selected?: boolean | undefined;
      })
    | undefined
  )[];
  lines: Pick<IMapTrack, 'ID' | 'weight' | 'coords' | 'color'>[] | undefined;
  planningMapSettings: Pick<PlanningParameters, PlanningSettingsKeys.DrawPairForUnresolved>;
  selectedOrders: SelectedOrders;
}) => void;

export type OnLayerChange = (value: boolean | null, item: { ID: Key } | null | undefined, clear?: boolean | null) => void;

export enum SelectedOrdersLassoType {
  ResolvedOrder = 'resolved',
  UnResolvedOrder = 'unresolved'
}

export type SelectedResolvedOrdersLasso = {
  type: SelectedOrdersLassoType.ResolvedOrder;
  orderIDs: Keys;
  waypointIDs: Keys;
};
export type SelectedUnResolvedOrdersLasso = {
  type: SelectedOrdersLassoType.UnResolvedOrder;
  orderIDs: Keys;
};
export type MapWaypointData = Pick<IMapTrack, 'tripStatus'> & TrackDataType & ResolvedOrderMap;
export type SelectedOrdersLasso = SelectedResolvedOrdersLasso | SelectedUnResolvedOrdersLasso | undefined;

export type OnLassoChange = (keys: Keys, type: SelectedOrdersLassoType, waypointIDs?: Keys) => void;
export type SetTriggerMapFocus = (arr: any[]) => void;

export type OnItemClick = (record?: MapClickData, layerResponse?: LayerHandlerResponse<L.Marker | L.Polyline>) => void;
