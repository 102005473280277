import { CARGO } from '@modules/orders-and-trips/constants/general';
import { FormType } from '@modules/orders-and-trips/types/general';
import { ActionTypeID, DraftTripNOrder } from '@modules/orders-and-trips/types/trip';
import { isEmpty } from 'lodash';

export const getFormTrip = (data: { draftTripNOrder?: DraftTripNOrder; showForm: FormType | null; isFetching: boolean }) => {
  const { draftTripNOrder, showForm, isFetching } = data ?? {};
  switch (showForm) {
    case FormType.CreateOrder:
    case FormType.UpdateOrder:
      return [
        { ActionType: ActionTypeID.Load, Cargo: [CARGO] },
        { ActionType: ActionTypeID.Unload, Cargo: [] }
      ];
    case FormType.Trip:
      if (isEmpty(draftTripNOrder) || isFetching) return [];
      return draftTripNOrder?.map((point) => {
        return {
          ActionType: point.ActionType,
          Cargo: point?.Cargo?.map(() => {
            return CARGO;
          })
        };
      });
    default:
      return [];
  }
};
