import { documentCreateDealRoutine } from '@modules/documents/actions';
import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';

export const documentsTemplateCreateDeal = createReducer(initialState, {
  //Create Deal from template
  [documentCreateDealRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },

  [documentCreateDealRoutine.FAILURE]: (
    state,
    action: {
      type: string;
      payload: {
        error: string[];
      };
    }
  ) => {
    state.error = action.payload.error;
    return state;
  },

  [documentCreateDealRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
