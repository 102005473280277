import { vehicleTableDecorator } from '../table/config/table-decorator';

export type RecordMainTable = ReturnType<typeof vehicleTableDecorator>[number];

export enum VehicleTableColumnData {
  ID = 'ID',
  Name = 'Name',
  BDD = 'BDD',
  StartPointDelta = 'StartPointDelta',
  NextPointsArrivalDelta = 'NextPointsArrivalDelta',
  NextPointsDepartureDelta = 'NextPointsDepartureDelta',
  SpeedDelta = 'SpeedDelta',
  Desequence = 'Desequence'
}
