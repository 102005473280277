import { ILSCascader } from '@components/index';
import { Keys, TableRecord } from '@common/types';
import { ILSCellCascaderApiIProps } from '@common/types/components/table/cell';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';
import { isArray, isNil, isNumber, isString } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export type SingleValueType = (string | number)[];
export type CascaderValueType = SingleValueType | SingleValueType[];
export const isCascaderValueType = (value: unknown): value is CascaderValueType => {
  return (isArray(value) && value.every((v) => isString(v) || isNumber(v) || isNil(v))) || isNil(value);
};

/** Эта ячейка (как и другие каскадные списки) работает только со вторым уровнем вложенности */
export const ILSCellCascaderApi = <R extends TableRecord>({
  save,
  tableRef,
  allowClear,
  toggleEdit,
  children,
  dictionaryInfo,
  dictionaryLoad,
  dictionary,
  options,
  fieldNames,
  dropdownMenuColumnStyle,
  showSearch
}: ILSCellCascaderApiIProps<R>): JSX.Element => {
  const [node, defaultValue = []] = children;
  const loading = dictionaryInfo?.[dictionary]?.isFetching;
  useApiOptions({ dictionary, dictionaryLoad });

  const onSelect = (value: Keys) => {
    save(value ?? null);
  };

  if (!isCascaderValueType(defaultValue)) {
    showUserAndDevError({ error: 'Каскадное значение - массив из двух value[] значений value и children[value]' });
    return <></>;
  }

  return (
    <>
      {node}
      <ILSCascader
        className={'cell-wrapper-editable-full'}
        autoFocus
        open
        showChild
        loading={Boolean(loading)}
        expandTrigger={'hover'}
        dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        showSearch={showSearch}
        tableRef={tableRef}
        defaultValue={defaultValue ?? undefined}
        onBlur={toggleEdit}
        allowClear={allowClear}
        options={options}
        onChange={onSelect}
        fieldNames={fieldNames}
        //NOTE: Относительно какого элемента отобразить выпадающий список
        getPopupContainer={() => document.querySelector('body')!}
      />
    </>
  );
};
