import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import React, { FC } from 'react';
import { DEFAULT_WIDGET_TEXT } from '../../constants';

type Props = {
  widget: AnalyticsWidget;
  hideWidget: boolean;
};

const ILSTextWidget: FC<Props> = ({ widget, hideWidget }) => {
  return <>{!hideWidget && <div style={{ opacity: widget.Text ? 1 : 0.5 }}>{widget.Text || DEFAULT_WIDGET_TEXT}</div>}</>;
};

export default ILSTextWidget;
