import { AddReportRequest } from '@modules/documents/types/documents-api';
import { TClearTrashMode } from '@modules/documents/types/document-state';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

export enum DocumentType {
  Deal = 'deal',
  Contract = 'contract',
  ContractAnnex = 'contractAnnex',
  LadingBill = 'ladingBill',
  RouteList = 'routeList',
  Bill = 'bill',
  Custom = 'custom',
  File = 'file',
  Empty = ''
}

export const DocumentTypeName: Record<DocumentType, string> = {
  [DocumentType.Deal]: 'Сделка',
  [DocumentType.Contract]: 'Договор',
  [DocumentType.ContractAnnex]: 'Приложение',
  [DocumentType.LadingBill]: 'ТТН',
  [DocumentType.RouteList]: 'Маршрутный лист',
  [DocumentType.Custom]: 'Другое',
  [DocumentType.Bill]: 'Счет',
  [DocumentType.Empty]: 'Пусто',
  [DocumentType.File]: 'Файл'
};

export enum DocumentAction {
  View = 'View',
  Delete = 'Delete',
  Archive = 'Archive',
  RestoreArchive = 'RestoreArchive',
  RestoreDocument = 'RestoreDocument',
  Download = 'Download',
  Update = 'Update',
  SaveAsTemplate = 'SaveAsTemplate',
  DeleteAttachTemplate = 'DeleteAttachTemplate',
  UpdateDealTemplate = 'UpdateDealTemplate',
  DeleteSet = 'DeleteSet',
  Send = 'Send',
  SendItem = 'SendItem'
}

export enum DocumentTemplateType {
  Deal = 'template-deal',
  Contract = 'template-contract',
  ContractAnnex = 'template-contractAnnex',
  LadingBill = 'template-ladingBill',
  RouteList = 'template-routeList'
}

export const documentTypeToLabelMap: Record<DocumentType, string> = {
  [DocumentType.Deal]: 'Сделки',
  [DocumentType.Contract]: 'Договоры',
  [DocumentType.ContractAnnex]: 'Приложения',
  [DocumentType.LadingBill]: 'ТТН',
  [DocumentType.RouteList]: 'Маршрутные листы',
  [DocumentType.Bill]: 'Счета',
  [DocumentType.Custom]: 'Другие',
  [DocumentType.Empty]: 'Пусто',
  [DocumentType.File]: 'Файл'
};

export const modalTemplateTitle: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Deal]: 'Шаблоны сделок',
  [DocumentTemplateType.Contract]: 'Шаблоны договоров',
  [DocumentTemplateType.ContractAnnex]: 'Шаблоны приложений',
  [DocumentTemplateType.LadingBill]: 'Шаблоны ТТН',
  [DocumentTemplateType.RouteList]: 'Шаблоны маршрутных листов'
};

export const modalTemplateNotFound: Partial<Record<DocumentTemplateType, string>> = {
  [DocumentTemplateType.Deal]: `Внимание! Видны только те шаблоны сделок, в составе которых есть "шаблон договора" и хотя бы один шаблон документа другого типа.`
};

export const modalTemplateType: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Deal]: DocumentType.Deal,
  [DocumentTemplateType.Contract]: DocumentType.Contract,
  [DocumentTemplateType.ContractAnnex]: DocumentType.ContractAnnex,
  [DocumentTemplateType.LadingBill]: DocumentType.LadingBill,
  [DocumentTemplateType.RouteList]: DocumentType.RouteList
};

export interface DocumentTemplateData {
  [key: string]: DocumentTemplate;
}

export interface UserOptions {
  clearTrashMode: TClearTrashMode;
}

export interface DocumentTemplateRes {
  data: DocumentTemplateData;
  success: boolean;
}

export interface IDocumentsPayload {
  type: DocumentType;
  template: number;
  setId: number;

  [key: string]: number | string;
}

export interface IDocumentsUpdatePayload {
  data: { id: number; values?: { Deleted?: number; Archive?: number; Type?: DocumentType } };
  action: DocumentAction;
  updateCurrentDoc?: boolean;
}

export interface IPayloadUpdate {
  [key: string]: string;
}

export interface DocumentIndexPayload {
  type: DocumentType;
  deleted?: number;
  archive?: number;
}

export interface IDocumentDeleteFromTrash {
  ids: number;
}

export interface IDependentListPayload {
  type: string;
  projectId?: number;
  setId: string;
}

export type DocumentsReportsOptions = Array<AddReportRequest>;
export type ReportList = Record<string, string>;

