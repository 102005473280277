import { documentsGetTemplatesRoutine, saveReportRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { SaveTemplateAction } from '@modules/documents/types/documents-actions';

function* worker(action: SaveTemplateAction) {
  const { success, fulfill, failure, request } = saveReportRoutine;
  const { name, alias, projectId } = action.payload;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Documents.saveReport, { name, alias });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(documentsGetTemplatesRoutine({ projectId }));
    yield put(fulfill());
  }
}

export function* saveReportWatcher() {
  yield takeEvery(saveReportRoutine.trigger, worker);
}
