import { NEW_MODEL } from '@components/data-display/table/constants';
import { CellType, DateFormat, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const ORDER_CARGO_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  unchangeable: true,
  adding: NEW_MODEL,
  columns: [
    ID_COLUMN,
    {
      title: 'Груз',
      dataIndex: 'CargoID',
      key: 'CargoID',
      editable: true,
      active: true,
      type: CellType.SelectAPI,
      closeSelectOnScroll: true,
      width: 150,
      required: true,
      sorting: 1
    },
    {
      title: 'Кол-во',
      dataIndex: 'Amount',
      key: 'Amount',
      editable: true,
      active: true,
      type: CellType.Int,
      required: true,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Дата партии',
      dataIndex: 'BatchDate',
      key: 'BatchDate',
      editable: true,
      active: true,
      type: CellType.Date,
      format: DateFormat.YYYYMMDDSlash,
      width: 150,
      sorting: true
    },
    {
      title: 'Заявка',
      dataIndex: 'OrderID',
      key: 'OrderID',
      invisible: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    }
  ]
};
