import { createSelector } from 'reselect';
import { currentSchemeKeysSelector, currentSchemeSelector } from '@modules/planning/children/scheme-editor/selectors/current-scheme';
import { Scheme, SchemesSwitcher } from '@modules/planning/children/scheme-editor/types';
import { allSchemesTabsSelector, maxSchemeSelector } from '@modules/planning/children/scheme-editor/selectors/max-scheme';
import { ALWAYS_VVISIBLE_SCHEME_TYPES } from '@modules/planning/children/scheme-editor/constants';

export const schemeInitialValuesSelector = createSelector(maxSchemeSelector, currentSchemeSelector, (maxScheme: Scheme, currentScheme) => {
  const initialValues = {};

  for (let dictionary in maxScheme) {
    if (currentScheme?.[dictionary]) {
      initialValues[dictionary] = { ...maxScheme[dictionary], ...currentScheme[dictionary], active: true };
    } else {
      initialValues[dictionary] = { ...maxScheme[dictionary], active: false };
    }
  }
  return initialValues;
});

export const displayedTabPanesSelector = createSelector(allSchemesTabsSelector, currentSchemeKeysSelector, (schemes, currentScheme) => {
  const currentPickedSchemes = currentScheme?.concat(ALWAYS_VVISIBLE_SCHEME_TYPES);
  return schemes.reduce((prev, scheme) => {
    prev[scheme] = currentPickedSchemes?.includes(scheme);
    return prev;
  }, {} as SchemesSwitcher);
});
