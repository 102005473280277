import { FC } from 'react';
import { ModalWindowContainer } from '@modules/orders-and-trips/containers/modal/modal-window';
import { FullBlock } from '@modules/orders-and-trips/containers/full/full';

interface IProps {}

export const OrdersNTripsMainFullContainer: FC<IProps> = ({ ...props }) => {
  return (
    <>
      <FullBlock />
      <ModalWindowContainer />
    </>
  );
};
