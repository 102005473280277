import { UserFormItem } from '@modules/account/constants';
import { ILSInput, ILSPhoneInput } from '@common/components';
import { UserRolesSelect } from '@modules/account/components/form/user-roles-select';
import { Placeholder } from '@common/constants';
import { IILSSelectProps } from '@components/data-entry/select/types';

export const renderInput = ({
  field,
  disabled = false,
  options
}: {
  field: UserFormItem;
  disabled?: boolean;
  options?: IILSSelectProps['options'];
}) => {
  switch (field) {
    case UserFormItem.Phone:
      return <ILSPhoneInput />;
    case UserFormItem.UserRole:
      return <UserRolesSelect options={options} disabled={disabled} />;
    default:
      return <ILSInput type={field === UserFormItem.Login ? 'email' : 'text'} placeholder={Placeholder[field]} />;
  }
};
