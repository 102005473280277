import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import {
  tenderOfferIndexRoutine,
  tenderSearchRoutine,
  tenderWithMyParticipationRoutine
} from '@modules/tender-platform/children/tender-search/actions';

function* tenderSearchWorker() {
  const { request, success, failure, fulfill } = tenderSearchRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderSearch.search);
    yield put(tenderOfferIndexRoutine.trigger());
    yield put(tenderWithMyParticipationRoutine.trigger());
    yield put(success({ tenders: Object.values(data) }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderSearchWatcher() {
  yield takeLatest(tenderSearchRoutine.trigger, tenderSearchWorker);
}
