import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { PlanResponse, PlanStatus } from '@common/types/dictionaries/plan';
import { mapWaitingModeSelector } from '@modules/planning/children/by-date/selectors/map-waiting-mode';
import { projectPlansSelector } from './project';

export const activePlanIDSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.activePlanID,
  (activePlanID) => Number(activePlanID)
);

export const activePlanSelector = createSelector(projectPlansSelector, activePlanIDSelector, (plans, activePlanID) => {
  return activePlanID ? plans[activePlanID] : ({} as PlanResponse);
});

export const activeIsFetchingSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.info.plans,
  activePlanIDSelector,
  mapWaitingModeSelector,
  (plans, activePlanID, mapWaitingMode) => {
    return activePlanID ? plans[activePlanID]?.isFetching || mapWaitingMode : false;
  }
);

export const activePlanStatusSelector = createSelector(
  activePlanSelector,
  (plan) => String(plan?.Status ?? plan?.PlanTotal?.Status ?? PlanStatus.Open) as PlanStatus
);

export const activePlanProviderSelector = createSelector(activePlanSelector, (plan) => plan?.ProviderName);

export const activePlanFullNameSelector = createSelector(activePlanSelector, (plan) => plan?.PlanTotal?.FullName);

export const isPlanStatusBeforeExecution = (status: PlanStatus) => {
  return Number(status) < Number(PlanStatus.OnExecution);
};
export const isPlanFactExecutionStarted = (status: PlanStatus) => {
  return Number(status) > Number(PlanStatus.OnExecution);
};

export const activePlanBeforeExecutionSelector = createSelector(activePlanStatusSelector, isPlanStatusBeforeExecution);
export const activePlanFactExecutionStartedSelector = createSelector(activePlanStatusSelector, isPlanFactExecutionStarted);
