import { useEffect, useMemo, useState } from 'react';
import { getActivePage } from '@components/data-display/table/utils/helpers/get-active-page';
import { DEFAULT_ACTIVE_TABLE_PARAMETERS } from '@components/data-display/table/constants';
import { ActiveTableParameters } from '@common/types/components/table/table';
import { UseFocusActivePageAndRow } from '@common/types/components/table/hooks';
import { Key, TableRecord } from '@common/types';
import { getInitialActiveRowKey } from '@components/data-display/table/utils/helpers';

export type OnFocusRow = ({ key, page }: { key?: Key; page?: number }) => void;

export const useFocusActivePageAndRow = <R extends TableRecord>({
  dataSource,
  pageSize,
  handleFocusRow,
  focusRow,
  reference
}: UseFocusActivePageAndRow<R>) => {
  const [active, setActive] = useState<ActiveTableParameters>(DEFAULT_ACTIVE_TABLE_PARAMETERS);
  const { focusRowArrayIndex } = useMemo(() => {
    return getActivePage(dataSource, active.key, pageSize);
  }, [active.key, pageSize, dataSource]);

  useEffect(() => {
    const key = getInitialActiveRowKey({ focusRow, dataSource, reference });
    const { focusPage } = getActivePage(dataSource, key, pageSize);
    setActive({
      key,
      page: focusPage,
      update: true
    });
  }, [focusRow]);

  const onFocusRow: OnFocusRow = ({ key, page }) => {
    const focusKey = key ?? active.key;
    handleFocusRow?.({ key: focusKey });
    setActive({
      key: focusKey,
      page: page ?? getActivePage(dataSource, focusKey, pageSize).focusPage,
      update: true
    });
  };

  return { focusRowArrayIndex, active, onFocusRow };
};
