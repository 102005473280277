import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC } from 'react';

interface IProps {
  children?: any;
  className?: string;
}

// Обертка для компонентов таблиц для передачи стилей
export const MonitoringTableTopLayout: FC<IProps> = ({ children, className, ...props }) => {
  return (
    <div className={classNames('ils-monitoring-table-top-layout', isEmpty(className) ? '' : className)} {...props}>
      {children}
    </div>
  );
};

