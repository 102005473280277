import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/by-date/store';
import { IndexedArray } from '@common/types';
import { PlanTotal } from '@common/types/dictionaries/plan';
import { planningReloadRoutine } from '@modules/planning/children/by-date/actions';

export const planningReloadReducer = createReducer(initialState, {
  [planningReloadRoutine.SUCCESS]: (state, action: PayloadAction<{ planTotal: IndexedArray<PlanTotal> }>) => {
    const { planTotal } = action.payload;

    for (const planID in planTotal) {
      if (state.project?.Plan?.[planID]?.PlanTotal) {
        state.project.Plan[planID].PlanTotal = planTotal[planID];
      }
    }

    return state;
  }
});
