import reduceReducers from 'reduce-reducers';
import { initialState } from '@modules/settings/store';
import { settingsGetReducer } from '@modules/settings/reducers/get-settings';
import { saveSettingsReducer } from '@modules/settings/reducers/save-settings';
import { mobileApplicationDictionaryOperationReducer } from '@modules/settings/reducers/mobile-application';
import { saveTableConfigReducer } from '@modules/settings/reducers/save-table-config';
import { mobileApplicationFocusRowReducer } from '@modules/settings/reducers/mobile-application-focus-row';

export const settingsReducer = reduceReducers(
  initialState,
  saveSettingsReducer,
  settingsGetReducer,
  mobileApplicationDictionaryOperationReducer,
  saveTableConfigReducer,
  mobileApplicationFocusRowReducer
);

