import { planningSetExecutionRoutine, planningUnsetExecutionRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* planningSetExecutionWorker(
  action: PayloadAction<{
    planID: number;
    unsetPlanID?: number;
  }>
) {
  const { request, failure, fulfill, success } = planningSetExecutionRoutine;
  const { planID, unsetPlanID } = action.payload;

  try {
    if (unsetPlanID) {
      yield put(planningUnsetExecutionRoutine({ planID: unsetPlanID }));
    }
    yield put(request());

    const {
      data: { data }
    } = yield call(PlanningAPI.setForExecution, { planID });

    yield put(success({ message: data, planID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningSetExecutionWatcher() {
  yield takeLatest(planningSetExecutionRoutine.trigger, planningSetExecutionWorker);
}
