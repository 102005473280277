import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_SUGGESTION_AUTO_COMPLETE_STATE } from '@common/feature/suggestion-autocomplete/address/store/store';
import { addressSuggestionRoutine } from '@common/feature/suggestion-autocomplete/address/actions';
import { AddressReducerPayload } from '@common/feature/suggestion-autocomplete/types';
import { filterTruthyCityValues } from '@common/feature/suggestion-autocomplete/address/utils/helpers';

export const suggestionAddress = createReducer(INITIAL_SUGGESTION_AUTO_COMPLETE_STATE, {
  [addressSuggestionRoutine.SUCCESS]: (state, action: PayloadAction<AddressReducerPayload>) => {
    const { addresses, onlyCity, query } = action.payload;

    if (addresses.length) {
      if (onlyCity) {
        state.address.addresses = filterTruthyCityValues(addresses, query, true);
      } else {
        state.address.addresses = addresses.map(({ value }) => value);
      }
    }

    return state;
  },
  [addressSuggestionRoutine.REQUEST]: (state) => {
    state.address.loading = true;
    return state;
  },
  [addressSuggestionRoutine.FULFILL]: (state) => {
    state.address.loading = false;
    return state;
  }
});
