import { FC } from 'react';
import { ILSFormItem, ILSInput, ILSRadioGroup, ILSRangePicker, ILSSelect } from '@components/index';
import { WEEK_DAYS_OPTIONS } from '@common/constants/options';
import { LOT_SCHEDUAL_OPTIONS } from '@modules/tender-platform/constants';
import { ScheduleType } from '@modules/tender-platform/types';
import { getPlaceholder, Placeholder } from '@common/constants/general';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { Moment } from 'moment';
import { parseDateFromServer } from '@common/utils/helpers/date-time/parsers';
import { ILS_MAIN_API_DATE_FORMAT, ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';
import { disabledDateNotInclusiveDate } from '@common/utils/helpers/date-time/disabled-dates';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/es/form/Form';
import { TenderPlatformFormCalendar } from '@modules/tender-platform/components/tender-card/lots/form/fields/schedule/calendar';

interface IProps {
  isLotEditable: boolean;
  fromPlanner: boolean;
}

const LOT_FIELDDS_WITH_RANGE_PICKER = [ScheduleType.Weekdays, ScheduleType.Interval];

export const TenderPlatformScheduleComponent: FC<IProps> = ({ isLotEditable, fromPlanner }) => {
  const form = useFormInstance();
  const ExecutionEndDT = useWatch(TenderFormField.ExecutionEndDT, form);
  const ExecutionStartDT = useWatch(TenderFormField.ExecutionStartDT, form);
  const schedule = useWatch(TenderFormField.Schedule, form);
  const disabled = !isLotEditable;
  const hasRangePicker = LOT_FIELDDS_WITH_RANGE_PICKER.includes(schedule);
  const startDate = parseDateFromServer(ExecutionStartDT);
  const endDate = parseDateFromServer(ExecutionEndDT);
  const minDate = startDate.format(ILS_MAIN_API_DATE_FORMAT);
  const maxDate = endDate.format(ILS_MAIN_API_DATE_FORMAT);
  const oneDayTender = startDate.isSame(endDate, 'date');
  const disabledDate = (current: Moment) => disabledDateNotInclusiveDate(current, startDate, endDate);

  return oneDayTender ? (
    <ILSFormItem
      className={'lot-shipping-counter disabled'}
      label={`Дата: ${startDate.format(ILS_MAIN_INTERFFACE_DATE_FORMAT)}`}
      name={TenderFormField.Dates}
      children={'Количество перевозок: 1'}
    />
  ) : (
    <>
      <ILSFormItem name={TenderFormField.Schedule}>
        <ILSRadioGroup
          options={LOT_SCHEDUAL_OPTIONS}
          className="lot-radio"
          disabled={disabled || fromPlanner}
          optionType="button"
          buttonStyle="solid"
        />
      </ILSFormItem>
      {hasRangePicker && (
        <ILSFormItem
          rules={[{ required: isLotEditable, message: 'Пожалуйста, выберите период' }]}
          label={'Период'}
          name={TenderFormField.Phase}
        >
          <ILSRangePicker disabledDate={disabledDate} disabled={disabled} />
        </ILSFormItem>
      )}
      {ScheduleType.Dates === schedule && (
        <TenderPlatformFormCalendar minDate={minDate} maxDate={maxDate} disabled={disabled || fromPlanner} disabledDate={disabledDate} />
      )}
      {ScheduleType.Weekdays === schedule && (
        <ILSFormItem
          rules={[{ required: isLotEditable, message: 'Пожалуйста, выберите дни недели' }]}
          label="Дни недели"
          name={TenderFormField.Weekdays}
        >
          <ILSSelect
            showArrow={isLotEditable}
            mode="multiple"
            disabled={disabled}
            placeholder={getPlaceholder(Placeholder.ChooseWeekDays, disabled)}
            options={WEEK_DAYS_OPTIONS}
          />
        </ILSFormItem>
      )}
      {ScheduleType.Interval === schedule && (
        <>
          <ILSFormItem
            tooltip={'если указано 0 дней - каждый день)'}
            rules={[{ required: isLotEditable, message: 'Пожалуйста, укажите промежуток' }]}
            name={TenderFormField.Interval}
          >
            <ILSInput
              placeholder={'(если указано 0 дней - каждый день)'}
              addonBefore="Каждые"
              addonAfter="дней"
              step={1}
              disabled={disabled}
              type="number"
            />
          </ILSFormItem>
        </>
      )}
    </>
  );
};
