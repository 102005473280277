import { RightMenuMainTabs } from '@modules/orders-and-trips/types/general';
import { TripActionType } from '@modules/orders-and-trips/types/trip';
import { DETAILS_CONTENT, DetailsRadioButtonType } from '@modules/orders-and-trips/types/details';

export const TRIP_ACTIONS = [TripActionType.Unload, TripActionType.Load];
export const SVG_CONFIG = { originPath: true, iconModule: '/orders-and-trips/' };
export const COLLAPSE_ARROW_ICON_CONFIG = { fontSize: '16px' };
export const NO_TRIPS_IMG_PATH = process.env.PUBLIC_URL + '/assets/images/no-trips.png';
export const EMPTY_BOX_IMG_PATH = process.env.PUBLIC_URL + '/assets/images/no-trips.png';

export const SVG = {
  RIGHT: 'right',
  TRIP: 'trip',
  VEHICLE: 'vehicle',
  MASS: 'tonage',
  VOLUME: 'volume',
  DELETE: 'delete',
  RIGHT_ARROW: 'right_arrow',
  RESTRICTION: 'restriction',
  FAST_DELIVERY: 'fast-delivery',
  CHECK_AMOUNT: 'check-amount',
  DOWNLOAD: 'download',
  UNLOAD: 'unload',
  DOWNLOAD_TYPE: 'download-type',
  PRICE: 'money',
  PROGRESS: 'progress',
  RIGHT_BOX: 'right-arrow-box'
};

export const RIGHT_BLOCK_TABS_DEFAULT = {
  [RightMenuMainTabs.Details]: 'Детали',
  [RightMenuMainTabs.Documents]: 'Документы'
  /*[RightMenuMainTabs.Services]: 'Услуги',
  [RightMenuMainTabs.Payment]: 'Оплата',
  [RightMenuMainTabs.Insurance]: 'Страхование'*/
};

export const RIGHT_BLOCK_TABS_WITH_SEARCH_PERFORMER = {
  [RightMenuMainTabs.SearchPerformer]: 'Поиск исполнителя',
  ...RIGHT_BLOCK_TABS_DEFAULT
};

export const EXTERNAL_TRANSPORT_COMPANY_CONFIRM_TEXT =
  'Вы действительно хотите отправить запрос стоимости заявки в выбранную транспортную компанию (платформу)?';

export const EXTERNAL_ALL_TRANSPORT_COMPANY_CONFIRM_TEXT =
  'Вы действительно хотите отправить запрос стоимости заявки во все доступные транспортные компании (платформы)?';

export const EXTERNAL_TRANSPORT_COMPANY_SET_EXECUTE_CONFIRM_TEXT = 'Вы действительно хотите отправить рейс в транспортную компанию?';

export const CANCEL_TRIP_NOTIFY = 'Вы действительно хотите отменить данный рейc?';

export const DELETE_ORDER_NOTIFY = 'Вы действительно хотите удалить данную заявку?';

export const DELETE_TRIP_NOTIFY = 'Вы действительно хотите удалить данный рейc?';

export const TRANSPORT_TYPE_RADIO_OPTIONS = [
  { label: 'Свой транспорт', value: 'MyTransport' },
  { label: 'Транспортная компания', value: 'TransportCompany' },
  { label: 'Разместить на ТП', value: 'TenderTransport' }
];

export const LOAD_TYPE_RADIO_OPTIONS = [
  { label: 'Отдельной машиной', value: 1 },
  { label: 'Отдельной машиной или догрузом', value: 2 }
];

export const MAX_ORDERS = 1;
export const CARGO = 'CARGO';

export const INIT_PAGINATION_PARAMS = {
  page: 1,
  perPage: 10,
  total: 0
};

export const detailsRadioButtons: Array<DetailsRadioButtonType> = [
  { value: DETAILS_CONTENT.GENERAL, label: 'Общее', iconConfig: { originPath: true, iconModule: '/alert/' }, iconName: 'info' },
  { value: DETAILS_CONTENT.MAP, label: 'Показать на карте', iconName: 'location' }
];

export const TRIP_TIMELINE_HEADER = 'События в целом по заказу ';
export const TRIP_TIMELINE_CREATED = 'Дата и время';
export const TRIP_TIMELINE_ACTION = 'Действие';
export const TRIP_TIMELINE_CREATOR = 'Ответственный';

export const COUNT_TRIPS_UPDATE_INTERVAL = 10000;

export const EXPORT_ICON_PREPARATION_STYLE = { fontSize: '18px' };
export const EXPORT_ICON_READY_STYLE = { fontSize: '18px', color: 'green' };

