//TODO выглядит как common code
// Делает загрузку эксель файла
export const loadExelFile = (file: WithImplicitCoercion<string>, name: string, format?: string) => {
  if (file) {
    const bin = Buffer.from(file, 'base64');
    const mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    const blob = new Blob([bin], { type: mediaType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${name || 'Файл'}.${format || 'xlsx'}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
