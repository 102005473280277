import { FC, memo } from 'react';
import { TripSingleAction } from '@modules/orders-and-trips/components/trip/trip-single-action';
import { DecoratedTrips, TripAction } from '@modules/orders-and-trips/types/trip';
import { TRANSPORT_COMPANY_IMG } from '@modules/orders-and-trips/constants/img';

interface IProps {
  trip: DecoratedTrips[number];
}

export const TripActions: FC<IProps> = memo(({ trip }) => {
  return (
    <div className="trip-card-actions-container">
      <div className="trip-card-actions action">
        {trip.actions.map((action: TripAction, index: number) => {
          return <TripSingleAction key={index} action={action} trip={trip} index={index} />;
        })}
      </div>
      <div className="trip-id">
        <b>Рейс номер №</b> <i>{trip.tripID}</i> <br />
        {trip.details?.service && <img src={TRANSPORT_COMPANY_IMG[trip.details.service]} width={100} />}
      </div>
    </div>
  );
});

