import { CellType, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const tenderDocumentsConfig: TableConfig = {
  deleting: true,
  uploading: true,
  selecting: true,
  adding: false,
  copying: false,
  hideClearButtons: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название документа',
      dataIndex: TableColumnName.OriginalName,
      key: TableColumnName.OriginalName,
      active: true,

      width: 150,
      editable: false,
      ellipsis: false,
      type: CellType.Input,
      sorting: 6,
      filtering: FilterCellType.String
    },
    {
      titleText: 'Кнопка - Скачать документ',
      titleTooltip: 'Скачать документ',
      title: 'Скачать',
      dataIndex: TableColumnName.Download,
      key: TableColumnName.Download,
      active: true,

      width: 30,
      editable: false,
      ellipsis: false,
      type: CellType.IconAction
    },
    {
      title: 'Размер документа',
      dataIndex: TableColumnName.Size,
      key: TableColumnName.Size,
      active: true,

      width: 30,
      editable: false,
      ellipsis: false,
      type: CellType.Input
    }
  ]
};
