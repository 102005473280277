import { createSelector } from 'reselect';
import { TenderOwnState } from '@modules/tender-platform/children/tender-own/types';

export const contactsSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn?.contacts,
  (contacts) => contacts
);

export const contactsOptionsSelector = createSelector(contactsSelector, (contacts) => contacts?.contactOptions);

export const contactsLoadingSelector = createSelector(contactsSelector, (contacts) => contacts?.loading);

export const contactsErrorSelector = createSelector(contactsSelector, (contacts) => contacts?.error);
