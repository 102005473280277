import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { BASE_FIELD_NAMES, WEEK_DAYS_OPTIONS } from '@common/constants/options';
import { NEW_WORKTIME } from '@common/models/worktime';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const DRIVER_TIMELINE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_WORKTIME,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 150,
      ellipsis: true,

      sorting: 4,
      filtering: FilterCellType.String
    },
    {
      title: 'Начало работы',
      dataIndex: 'StartTime',
      key: 'StartTime',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 120,

      sorting: 3
    },
    {
      title: 'Дни недели',
      dataIndex: 'WeekDays',
      key: 'WeekDays',
      editable: true,
      active: true,
      width: 150,

      sorting: 6,
      type: CellType.SelectMultiple,
      fieldNames: BASE_FIELD_NAMES,
      needSortByLabelField: false,
      options: WEEK_DAYS_OPTIONS
    },
    {
      title: 'Работа (дн.)',
      dataIndex: 'DaysOfWork',
      key: 'DaysOfWork',
      editable: true,
      active: true,
      validationParameters: {
        ...CELL_INTEGER_VALIDATION_PARAMETERS,
        max: 7
      },
      type: CellType.Int,
      filtering: FilterCellType.String
    },
    {
      title: 'Выходные (дн.)',
      dataIndex: 'DaysOfRest',
      key: 'DaysOfRest',
      editable: true,
      active: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: {
        ...CELL_INTEGER_VALIDATION_PARAMETERS,
        max: 7
      }
    },
    {
      title: 'Макс.работа в неделю, ч',
      dataIndex: 'WorkPerWeek',
      key: 'WorkPerWeek',
      editable: true,
      active: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: {
        ...CELL_FLOAT_VALIDATION_PARAMETERS,
        max: 168
      }
    },
    {
      title: 'Макс.работа за 2 недели, ч',
      dataIndex: 'WorkPerTwoWeek',
      key: 'WorkPerTwoWeek',
      editable: true,
      active: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      width: 150,
      validationParameters: {
        ...CELL_FLOAT_VALIDATION_PARAMETERS,
        max: 336
      }
    }
  ]
};
