import initialAnalyticsState from '@modules/analytics/common/store';
import { AnalyticsTenderMainStore } from '../types/store';

const initialState: AnalyticsTenderMainStore = {
  ...initialAnalyticsState,
  BoardInfo: {
    currentBoardID: null,
    currentBoardName: null
  }
};

export default initialState;
