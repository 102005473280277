import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { planningGetParamsRoutine } from '../actions';

/**
 * Хук для получения настроек планирования
 */

export const usePlanningParams = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(planningGetParamsRoutine());
  }, []);
};

