import { defineModule } from '@core/modules';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { FeatureModule } from '@common/types/modules/module';
import * as sagas from './address/sagas';
import { getAllModuleSagas } from '@core/sagas';
import { suggestionAutoCompleteReducer } from '@common/feature/suggestion-autocomplete/address/reducers';

export default defineModule({
  title: FeatureModule.SuggestionAutoComplete,
  component: ILSSuggestionAutoComplete,
  reducer: suggestionAutoCompleteReducer,
  sagas: getAllModuleSagas(sagas)
});
