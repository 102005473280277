import './styles.less';
import { FC } from 'react';
import classnames from 'classnames';
import { MessageContent } from '@common/constants/general/content';
import { ILSAutoComplete } from '@common/components';
import { Field } from '@common/constants';
import { AutoCompleteProps } from 'antd';

export const MIN_CHAR_FOR_QUERY = 2;

export interface IILSSearchAutoCompleteProps extends AutoCompleteProps {
  field: Field;

  handleSearch(query: string, field: Field): void;
}

export const ILSSearchAutoComplete: FC<IILSSearchAutoCompleteProps> = ({ field, handleSearch, className, ...props }) => {
  const onSearch = (query: string) => query.length > MIN_CHAR_FOR_QUERY && handleSearch(query, field);

  return (
    <ILSAutoComplete
      notFoundContent={MessageContent.NotFound}
      onSearch={onSearch}
      {...props}
      className={classnames('ils-search-autocomplete', className)}
    />
  );
};
