import { createReducer } from '@reduxjs/toolkit';
import { changeExpeditorInTripRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { compareAsString, createArrayFromIndexedArray, onFailure, onFulfill, onRequest } from '@common/utils';
import { ChangeExpeditorInTripPayloadAction } from '@modules/planning/children/by-date/sagas/trip/change-expeditor-in-trip';
import { Driver } from '@common/types';

export const changeExpeditorInTripReducer = createReducer(initialState, {
  [changeExpeditorInTripRoutine.SUCCESS]: (state, { payload: { tripID, expeditorID, vehicleID } }: ChangeExpeditorInTripPayloadAction) => {
    const ExpeditorID = [expeditorID];
    const ExpeditorEnum = {
      [expeditorID]:
        createArrayFromIndexedArray(state.project?.Driver).find(({ ID }) => compareAsString(ID, expeditorID))?.Name ??
        `Экспедитор №${expeditorID}`
    };
    state.currentPlan.RouteTotal[vehicleID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].ExpeditorID = ExpeditorID;
    state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].ExpeditorEnum = ExpeditorEnum;

    const moreThenOneTripInRoute = Object.keys(state.currentPlan.RouteTotal[vehicleID].TripTotal).length > 1;
    const newExpeditorForRoute = !state.currentPlan.RouteTotal[vehicleID].ExpeditorID.some((id: Driver['ID']) =>
      compareAsString(id, expeditorID)
    );

    if (moreThenOneTripInRoute && newExpeditorForRoute) {
      state.currentPlan.RouteTotal[vehicleID].ExpeditorID = state.currentPlan.RouteTotal[vehicleID].ExpeditorID.concat(expeditorID);
      state.currentPlan.RouteTotal[vehicleID].ExpeditorEnum = {
        ...state.currentPlan.RouteTotal[vehicleID].ExpeditorEnum,
        ...ExpeditorEnum
      };
    } else {
      state.currentPlan.RouteTotal[vehicleID].ExpeditorID = ExpeditorID;
      state.currentPlan.RouteTotal[vehicleID].ExpeditorEnum = ExpeditorEnum;
    }

    return state;
  },
  [changeExpeditorInTripRoutine.REQUEST]: onRequest,
  [changeExpeditorInTripRoutine.FAILURE]: onFailure,
  [changeExpeditorInTripRoutine.FULFILL]: onFulfill
});
