import { call, put, takeEvery } from 'redux-saga/effects';
import { Auth } from '@modules/current-user/api/auth';
import { userGetRoleRoutine } from '@modules/current-user/actions';

function* accountGetRoleWorker() {
  const { request, success, failure, fulfill } = userGetRoleRoutine;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Auth.getRole);

    yield put(success({ data }));
  } catch (error) {
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* accountGetRoleWatcher() {
  yield takeEvery(userGetRoleRoutine.trigger, accountGetRoleWorker);
}
