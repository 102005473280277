import { ILSSwitch, ILSTooltip } from '@components/index';
import { ILSSelect } from '@components/data-entry/select';
import { ILSSVG } from '@components/custom/icons/svg';
import { ILSTypographyTitle } from '@components/general/typography';
import {
  OrderData,
  PlanningByDateState,
  VehicleTableFilterFields,
  VehicleTableFilterStatus
} from '@modules/planning/children/by-date/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { useSelector } from 'react-redux';
import { vehiclesTransportCompanyOptionsSelector } from '@modules/planning/children/by-date/selectors/project';
import { VEHICLE_TABLE_FILTERS } from '@modules/planning/children/by-date/constants/tables/vehicle';
import { Dictionary } from '@common/types';
import { projectDictionaryIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { isEmpty } from 'lodash';

interface Props {
  setActiveFiltersData: Dispatch<SetStateAction<VehicleTableFilterFields>>;
  orderSummary: OrderData;
}

export const ILSFilteredVehicle: FC<Props> = ({ setActiveFiltersData, orderSummary }) => {
  const loading = useSelector((state: PlanningByDateState) => projectDictionaryIsFetchingSelector(state, Dictionary.Vehicle));
  const options = useSelector(vehiclesTransportCompanyOptionsSelector);
  const filterData = (activeFilter: VehicleTableFilterStatus, value?: string | number) => {
    setActiveFiltersData((prev) => {
      return {
        ...prev,
        [activeFilter]: Number(value)
      };
    });
  };
  const handleChangeCompany = (value: number) => filterData(VehicleTableFilterStatus.TransportCompanyID, value);

  return (
    <div className="ils-filter-data">
      <ILSTypographyTitle level={5} children={'Фильтры'} title={'Фильтры'} />
      {VEHICLE_TABLE_FILTERS.map(({ filterValue, icon, title, id }) => {
        const onClick = (check: boolean) => {
          filterValue === VehicleTableFilterStatus.ProjectVehicle
            ? filterData(VehicleTableFilterStatus.ProjectVehicle, Number(check))
            : filterData(filterValue, check ? orderSummary[filterValue] : null);
        };
        return (
          <ILSTooltip title={title} key={id}>
            <ILSSwitch
              loading={loading}
              defaultChecked={id === 0}
              onClick={onClick}
              disabled={orderSummary[filterValue] === 0}
              unCheckedChildren={<ILSSVG className={'ils-filter-data-icon'} icon={icon} />}
              checkedChildren={<ILSSVG className={'ils-filter-data-icon'} icon={icon} />}
            />
          </ILSTooltip>
        );
      })}
      <ILSSelect
        loading={loading}
        dropdownMatchSelectWidth={false}
        popupOnTable
        className={'ils-filter-data-select'}
        fieldNames={DEFAULT_FIELD_NAMES}
        disabled={isEmpty(options)}
        options={options}
        placeholder="Выбрать транспортную компанию..."
        allowClear
        onChange={handleChangeCompany}
      />
    </div>
  );
};
