import { ColumnTable, Notify, TableConfig, TableName, TableRecord } from '@common/types';
import { ReactNode } from 'react';
import { PlacementType } from '@common/types/components';

// todo actionPayload
export enum ActionType {
  Planning_GetProjects = 'PLANNING/GET_PROJECTS',
  Planning_Create = 'PLANNING/CREATE',
  Planning_SetActiveProjectID = 'PLANNING/SET_ACTIVE_PROJECT_ID',
  Emulator_GetProjects = 'EMULATOR/GET_PROJECTS',
  Planning_GetUserProjects = 'PLANNING/GET_USER_PROJECTS'
}

export interface ISetSplitSizesActionPayload {
  blockName: string;
  percents: Array<number>;
  visible?: Array<boolean>;
}

export interface ISetTableColsActionPayload<R> {
  tableName: TableName | string;
  config: TableConfig;
  col: ColumnTable<R>;
  child: string;
  changeAllTo: boolean | undefined;
}

export interface ISetTableDefaultActionPayload {
  tableName: TableName | string;
  config: TableConfig;
}

export interface ISetTableColsOrderActionPayload<R> {
  tableName: TableName | string;
  config: TableConfig;
  cols: ColumnTable<R>[];
  child: string;
}

export interface ISetTableColWidthActionPayload<R> {
  tableName: TableName | string;
  child: string;
  width: number;
  dataIndex: ColumnTable<R>['dataIndex'];
  config: TableConfig;
}

export interface ISetTablePageSizeActionPayload {
  tableName: TableName | string;
  size: number;
}

export interface ISetLanguageActionPayload {
  language: string;
}

//Тип для самостоятельного определения уведомления с подробностями
export type NotifyDetailedContent = {
  fullContent: string | ReactNode;
  shortContent: string | ReactNode;
};

//Типы контента который может использоваться в уведомлениях сагах
export type NotifyContent = string | string[] | ReactNode | ReactNode[] | NotifyDetailedContent;

export interface INotifyPayload {
  type: Notify.Loading | Notify.Error | Notify.Success;
  status: NotifyContent;
  icon?: ReactNode;
  placement?: PlacementType;
  key?: string;
  duration?: number;
}

export interface SetTableConfigActionPayload {
  config: any;
}

export interface ISetTableGroupingActionPayload {
  tableName: TableName | string;
  colsForGrouping: Array<ColumnTable<TableRecord>['dataIndex']>;
}
