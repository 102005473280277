import { FC } from 'react';
import { Form, FormItemProps, FormProps } from 'antd';
import { FormListProps } from 'antd/lib/form/FormList';
import { handleFinishFailedNotify } from '@common/utils/helpers/form/handle-finish-failed-notify';

export interface ILSFormItemProps extends FormItemProps {
  rules?: any;
  value?: string;
}

export const ILSFormList: FC<FormListProps> = ({ children, ...props }) => {
  return <Form.List {...props} children={children} />;
};

//todo убрать лишний интерфейс и исправить в проекте работу
export const ILSFormItem: FC<ILSFormItemProps> = (props) => {
  return <Form.Item {...props} />;
};

export const ILSForm: FC<FormProps> = (props) => {
  return <Form layout={'vertical'} onFinishFailed={handleFinishFailedNotify} {...props} />;
};
