import { put, takeLatest, call, ForkEffect } from 'redux-saga/effects';
import { commonRoutine, routines } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { IAnalyticsCommonAPI } from '@modules/analytics/common/types/api';
import { AnalyticsActions } from '@modules/analytics/common/types/actions';

interface IPayloadDataForMapping {
  ID?: string | number;
  BoardSetID?: string | Number;
  role?: string;
}

// payload нужно передавать в качестве массива объектов, saga преобразует его в нужный формат
// intoReducer параметр для данных, которые нужно передать в reducer без ожидания запроса с сервера
// isIndexed определяет передаваемые данные в reducer (indexed array или нет)
// routine - рутина, которую нужно тригерить

export const commonWatcher = (api: IAnalyticsCommonAPI, actions: AnalyticsActions): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<{ routine: any; data: any[]; method: string; intoReducer?: any; isIndexed?: boolean }>) {
    const { payload } = action;
    const { data, routine, method, intoReducer, isIndexed } = payload;

    const { failure, fulfill, request } = commonRoutine;
    const { success, failure: triggerFailure } = routines[routine];

    const mappedPayloadData = data.map((item: IPayloadDataForMapping) => ({
      Method: method,
      Values: {
        ...(item.ID && { ID: item.ID }),
        ...(item.BoardSetID && { BoardSetID: item.BoardSetID }),
        ...(item.role && { role: item.role }),
        ...(Object.values(item).length > 1 && { values: { ...item } })
      }
    }));

    try {
      yield put(request());

      const { data: res } = yield call(AnalyticsPlanningAPI.batch, {
        cmd: mappedPayloadData
      });

      const mappedResponse = res.data.map((item: any) => {
        if (isIndexed) {
          return item.data.result;
        } else {
          return Object.values(item.data.result)[0];
        }
      });

      if (intoReducer) {
        yield put(success({ data: intoReducer }));
      } else {
        yield put(success({ data: mappedResponse.length > 1 ? mappedResponse : mappedResponse[0] }));
      }
    } catch (error) {
      yield put(failure(error));
      yield put(triggerFailure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.batchRoutine, worker);
  }

  return watcher;
};
