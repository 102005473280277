import { SearchPerformerContainer } from '@modules/orders-and-trips/containers/right/tabs/search-performer-container';
import { ServicesContainer } from '@modules/orders-and-trips/containers/right/tabs/services-container';
import { DetailsContainer } from '@modules/orders-and-trips/containers/right/tabs/details-container';
import { DocumentsContainer } from '@modules/orders-and-trips/containers/right/tabs/documents-container';
import { PaymentContainer } from '@modules/orders-and-trips/containers/right/tabs/payment-container';
import { InsuranceContainer } from '@modules/orders-and-trips/containers/right/tabs/insurance-container';
import {
  FormType,
  IFormSubmitButtonsType,
  MainTabs,
  ModalWindowContainerType,
  RightMenuMainTabs,
  TransportType
} from '@modules/orders-and-trips/types/general';
import { ExternalTransportCompanyContainer } from '@modules/orders-and-trips/containers/right/switch-transport/external-transport-company-container';
import { OwnTransportContainer } from '@modules/orders-and-trips/containers/right/switch-transport/own-transport-container';
import { TenderTransportContainer } from '@modules/orders-and-trips/containers/right/switch-transport/tender-transport-container';
import { TripStatus, TripStatusTitle } from '@modules/orders-and-trips/types/trip';
import { ButtonTypes } from '@common/types/components';
import { BUTTON_TEXT } from '@common/constants';
import { TripsContainer } from '@modules/orders-and-trips/containers/left/trips/trips-container';
import { OrdersContainer } from '@modules/orders-and-trips/containers/left/orders/orders-container';
import { ImportNExportContainer } from '@modules/orders-and-trips/containers/modal/import-export/import-export';
import { FakePreCostTabContainer } from '@modules/orders-and-trips/containers/full/fake-pre-cost/fake-pre-cost-tab-container';
import { MyApplications } from '@modules/orders-and-trips/containers/full/my-applications/my-applications';

export const RIGHT_BLOCK_TAB_CONTAINERS = {
  [RightMenuMainTabs.SearchPerformer]: SearchPerformerContainer,
  [RightMenuMainTabs.Details]: DetailsContainer,
  [RightMenuMainTabs.Documents]: DocumentsContainer,
  [RightMenuMainTabs.Services]: ServicesContainer,
  [RightMenuMainTabs.Payment]: PaymentContainer,
  [RightMenuMainTabs.Insurance]: InsuranceContainer
};

export const TRANSPORT_TYPE_COMPONENTS = {
  [TransportType.MyTransport]: OwnTransportContainer,
  [TransportType.TransportCompany]: ExternalTransportCompanyContainer,
  [TransportType.TenderTransport]: TenderTransportContainer
};

export const MODAL_WINDOW_CONTAINERS = {
  [ModalWindowContainerType.ImportExport]: ImportNExportContainer
};

export const TRIP_CONTAINERS_BY_STATUS = [
  // {
  //   title: TripStatusTitle[TripStatus.New],
  //   containerStatus: TripStatus.New
  // },
  // {
  //   title: TripStatusTitle[TripStatus.onRegistration],
  //   containerStatus: TripStatus.onRegistration
  // },
  {
    title: TripStatusTitle[TripStatus.Issued],
    containerStatus: TripStatus.Issued
  },
  {
    title: TripStatusTitle[TripStatus.OnExecution],
    containerStatus: TripStatus.OnExecution
  },
  {
    title: TripStatusTitle[TripStatus.Finished],
    containerStatus: TripStatus.Finished
  },
  {
    title: TripStatusTitle[TripStatus.Canceled],
    containerStatus: TripStatus.Canceled
  },
  {
    title: TripStatusTitle[TripStatus.All],
    containerStatus: TripStatus.All
  }
];

export const FormSubmitButtons: Record<FormType, () => IFormSubmitButtonsType[]> = {
  [FormType.CreateOrder]() {
    return [
      {
        text: BUTTON_TEXT[ButtonTypes.Cancel],
        buttonType: ButtonTypes.Cancel,
        type: 'primary',
        ghost: true,
        htmlType: 'submit',
        click: ButtonTypes.Cancel
      },
      { text: BUTTON_TEXT[ButtonTypes.Save], buttonType: ButtonTypes.Save, type: 'primary', htmlType: 'submit', click: null }
    ];
  },
  [FormType.Trip]() {
    return this[FormType.CreateOrder]();
  },
  [FormType.UpdateOrder]() {
    return [
      {
        text: BUTTON_TEXT[ButtonTypes.Delete],
        buttonType: ButtonTypes.Delete,
        type: 'primary',
        ghost: true,
        icon: 'delete',
        click: ButtonTypes.Delete
      },
      { text: BUTTON_TEXT[ButtonTypes.Save], buttonType: ButtonTypes.Save, type: 'primary', htmlType: 'submit', click: null }
    ];
  }
};

export const LEFT_BLOCK_MAIN_TABS = [
  {
    title: 'Заявки',
    key: MainTabs.Order,
    Component: OrdersContainer
  },
  {
    title: 'Рейсы',
    key: MainTabs.Trip,
    Component: TripsContainer
  }
];

export const FULL_BLOCK_MAIN_TABS = [
  {
    title: 'Создание заявки',
    key: MainTabs.NewOrder,
    Component: FakePreCostTabContainer
  },
  {
    title: 'Мои заявки',
    key: MainTabs.MyApplications,
    Component: MyApplications
  }
];

export const ConfirmModalButtons: Array<IFormSubmitButtonsType> = [
  {
    text: BUTTON_TEXT[ButtonTypes.Cancel],
    type: 'primary',
    ghost: true,
    click: ButtonTypes.Cancel,
    buttonType: ButtonTypes.Cancel
  },
  {
    text: BUTTON_TEXT[ButtonTypes.Confirm],
    buttonType: ButtonTypes.Confirm,
    type: 'primary',
    click: ButtonTypes.Confirm
  }
];
