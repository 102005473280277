import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Tender, TenderState } from '@modules/tender-platform/types';
import { IndexedArray, User } from '@common/types';
import { tenderGetAccountUsersRoutine } from '@modules/tender-platform/actions';
import initialState from '@modules/tender-platform/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderGetAccountUsersReducer = createReducer(initialState, {
  [tenderGetAccountUsersRoutine.REQUEST]: onRequest,
  [tenderGetAccountUsersRoutine.FAILURE]: onFailure,
  [tenderGetAccountUsersRoutine.FULFILL]: onFulfill,
  [tenderGetAccountUsersRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      tenderID: Tender['ID'];
      data: IndexedArray<User>;
    }>
  ) => {
    const { data } = action.payload;
    state.accountUsers = data;
    return state;
  }
});
