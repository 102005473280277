import { Module, ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import MonitoringDashboard from './children/dashboard';
import MonitoringEventDetails from './children/event-details';
import MonitoringDeliveryControl from './children/monitoring-delivery';
import Monitoring from './containers';
import monitoringRootReducer from './reducers';
import * as rootSagas from './sagas/';

/*
 * Мониторинг - это модуль нашего продукта, который позволяет отслеживать текущую ситуацию в режиме реального времени.
 * Он позволяет переключаться между разными датами. Демонстрирует текущую ситуацию с автомобилями.
 * Основной файл - "входная точка" в модуль находится здесь - src\modules\monitoring\index.ts
 */

export default defineModule({
  title: ModuleTitle.Monitoring,
  path: ModulePath.Monitoring,
  component: Monitoring,
  children: {
    MonitoringDashboard,
    MonitoringDeliveryControl,
    MonitoringEventDetails
  } as any,
  reducer: monitoringRootReducer,
  sagas: getAllModuleSagas(rootSagas),
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/monitoring.svg',
  deps: [Module.Documents]
});
