import { updateTenderForRouteRoutine } from '@modules/tender-platform/actions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RoutesIDs } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';
import { firstRouteValueByFieldSelector, routesSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';

function* updateTenderForRouteWorker(action: PayloadAction<{ ids?: RoutesIDs; field: TenderFormTableField; value?: any }>) {
  const { success, failure } = updateTenderForRouteRoutine;
  let { ids, field, value } = action.payload;

  try {
    if (!ids) {
      const { routes } = yield select(routesSelector);
      ids = routes;
      // @ts-ignore
      value = yield select(firstRouteValueByFieldSelector(field, routes[0]));
    }
    yield put(success({ ids, field, value }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* updateTenderForRouteWatcher() {
  yield takeEvery(updateTenderForRouteRoutine.trigger, updateTenderForRouteWorker);
}
