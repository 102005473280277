import { ILSSkeleton } from '@common/components';
import { Chart, Interval, Legend, Tooltip } from 'bizcharts';
import { LegendItem } from 'bizcharts/lib/interface';
import React, { FC, useMemo } from 'react';
import { graphVerticalChartLegendInfo } from '../../constants';
import { GroupedChartProps } from '../../types/components/charts';
import { AnalyticsChartNoData } from '@modules/analytics/common/charts/components/chart-no-data';
import { useForceUpdate } from '@modules/analytics/common/hooks/use-force-update';

const ILSAnalyticsGroupedChartComponent: FC<GroupedChartProps> = ({ height = 200, config, hideWidget, loading, data, children, name }) => {
  useForceUpdate(loading);
  const transformedData = useMemo(() => {
    return data?.map((item, i) => ({ ...item, order: i + 1 })).sort((a, b) => b.order - a.order);
  }, [data]);

  const legendInfo = useMemo(() => {
    return graphVerticalChartLegendInfo.map((item) => {
      if (item.value === 'visits') {
        return { ...item, value: 'onTime' };
      } else {
        return item;
      }
    });
  }, []);

  let chartIns: any;
  let legendMap = {};

  const changeHandler = (ev: any) => {
    const { item } = ev;
    const { value } = item;
    const checked = !item.unchecked;

    legendMap[value] = checked;

    chartIns.filter('field', (val: any) => {
      return legendMap[val] !== false;
    });
    chartIns.render(true);
  };

  if (!data?.length && !loading) {
    return <AnalyticsChartNoData name={name} hideBorder={!!children} />;
  }

  if (hideWidget) {
    return <></>;
  }

  return (
    <div className="analytics-chart">
      <ILSSkeleton loading={loading}>
        <div>{config?.title}</div>
        <div>
          <Chart
            padding="auto"
            data={transformedData}
            height={height}
            scale={{
              data: {
                min: 0,
                nice: true
              }
            }}
            autoFit
            onGetG2Instance={(c: any) => (chartIns = c)}
          >
            <Interval
              adjust={[
                {
                  type: 'stack'
                }
              ]}
              size={20}
              color={['status', ['#43CC4C', '#D24747', '#4C91FF']]}
              position={'label*data'}
              label={{
                fields: ['data'],
                cfg: {
                  position: 'middle'
                }
              }}
            />
            <Legend custom={true} items={legendInfo as LegendItem[]} position="top-left" onChange={changeHandler} />
            <Tooltip shared data-html2canvas-ignore />
          </Chart>
        </div>
      </ILSSkeleton>
    </div>
  );
};

export default ILSAnalyticsGroupedChartComponent;
