import { FC, memo } from 'react';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';
import { CloudDownloadOutlined } from '@ant-design/icons';

interface IProps {
  isFetching: boolean;
  chooseCompany(): void;
}

export const HeaderTransportCompany: FC<IProps> = memo(({ isFetching, chooseCompany }) => {
  return (
    <>
      <h2 className="flex-horizontal between-center">
        <span>Доступные транспортные компании</span>
        <ActionButton
          isFetching={isFetching}
          type={'primary'}
          text={'Запросить у всех'}
          className="fetch-all black width-190"
          click={() => chooseCompany()}
        />
      </h2>
      <div className="header">
        <div className="header-logo">Компания</div>
        <div className="header-name">Название</div>
        <div className="header-price">Информация</div>
        <div className="header-action">Заказать</div>
      </div>
    </>
  );
});

