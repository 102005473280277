import { Track } from '@modules/track/types';
import { PointWithData } from '@common/utils/get-track/types';
import { PlanningParameters } from '@modules/settings/types/store';
import { BASE_ROUTE_COLOR, DASH } from '@common/constants';
import { isEmpty } from 'lodash';
import { Depot, DepotTypeId, IndexedArray, MarkerType } from '@common/types';
import { MarkerFigure } from '@common/types/components/map/map';
import { TData } from '@modules/track/hooks/map/show-polyline';
import { timestampToDateTimeStringWithTZ } from '@common/utils';

export const getMapTracksPolylines = (
  track: Track[],
  depots: IndexedArray<Depot> | null | undefined = {},
  settings?: PlanningParameters,
  trackData?: Record<string, PointWithData>
): Track[] => {
  if (isEmpty(track)) return [];

  return track.map((polyline: Track) => {
    const weight = 3;
    const color = polyline.color ?? BASE_ROUTE_COLOR;

    const depot = polyline.waypoint && depots?.[polyline.waypoint.DepotID];
    const isHome = polyline.waypoint?.DepotTypeID === DepotTypeId.Storage;

    const data: TData = {
      Text: depot?.Name ?? `${isHome ? 'Гараж' : 'Клиент'}`,
      ArrivalTime: !polyline.waypoint?.StartTime
        ? DASH
        : timestampToDateTimeStringWithTZ(polyline.waypoint.StartTime, polyline.waypoint.UTCOffset),
      DepartureTime: !polyline.waypoint?.StartTime
        ? DASH
        : timestampToDateTimeStringWithTZ(polyline.waypoint.StartTime, polyline.waypoint.UTCOffset)
    };

    const point: Track['point'] = {
      marker: {
        coords: polyline.waypoint?.LatLon ?? [],
        type: isHome ? MarkerType.Storage : MarkerType.ForTrack,
        figure: MarkerFigure.Triangle,
        color,
        number: isHome ? undefined : polyline.number ?? 0,
        pointData: data
      }
    };

    let coords = polyline.coords;

    if (polyline.nativeCoords && (settings?.useRedis || !settings?.hideLinesOnTrackAbsent)) {
      coords = Object.values(polyline.nativeCoords).map((point) => Object.values(point ?? {}));
    }

    if (coords && typeof coords !== 'string') {
      if (Array.isArray(coords) && coords.length === 2) {
        if (settings?.useRedis) {
          const data = Object.values(trackData ?? {}).find((item) => {
            return (
              item.From.Latitude === coords?.[0][0] &&
              item.From.Longitude === coords?.[0][1] &&
              item.To.Latitude === coords?.[1][0] &&
              item.To.Longitude === coords?.[1][1]
            );
          });
          if (data && data.track) {
            coords = data.track;
          } else {
            if (settings?.hideLinesOnTrackAbsent) {
              coords = polyline.coords;
            }
          }
        }
      }
    }
    return {
      ...polyline,
      color,
      coords,
      weight,
      active: true,
      point
    };
  });
};
