import { DictionariesEndpoint, DictionaryOperation, DictionaryUpdateRequest } from '@common/types/general/api/dictionary';
import { Dictionary, IILSTableComponentProps, TableRecord } from '@common/types';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';

export const handleUpdateOrderCargo =
  ({ dispatch }: { dispatch: Dispatch }): IILSTableComponentProps<TableRecord>['handleSave'] =>
  (record, dataIndex, value) => {
    const payload: DictionaryOperation<DictionaryUpdateRequest> & {
      dictionaryFetching?: boolean;
    } = {
      ids: [record.ID],
      dictionary: Dictionary.OrderCargo,
      values: { [dataIndex]: value },
      operation: DictionariesEndpoint.Update
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };
