import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getTripsCountRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { IEnum } from '@common/types';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { compareAsString } from '@common/utils';

export const getTripsCountReducer = createReducer(initialState, {
  [getTripsCountRoutine.REQUEST]: (state: InitialState) => {
    return state;
  },
  [getTripsCountRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: IEnum<string>;
      updateTripStatus?: TripStatus;
    }>
  ) => {
    const { data, updateTripStatus } = action.payload;
    if (
      updateTripStatus &&
      updateTripStatus !== TripStatus.New &&
      !compareAsString(state.trips.tripsCount?.[updateTripStatus], data[updateTripStatus])
    ) {
      state.trips.updateTrips = updateTripStatus;
    }
    state.trips.tripsCount = data;
    return state;
  },
  [getTripsCountRoutine.FAILURE]: onFailure,
  [getTripsCountRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    return state;
  }
});

