import { FC, memo } from 'react';
import { Dictionary, Order, TableName } from '@common/types';
import { getContextMenuForRoute } from '@modules/planning/children/by-date/decorators/table/context-menu/routes/get-context-menu-for-route';
import { OrderActionRecord, RouteRecord, RoutesTableRecord, TripRecord, WaypointRecord } from '@modules/planning/children/by-date/types';
import { getContextMenuForTrip } from '@modules/planning/children/by-date/decorators/table/context-menu/routes/get-context-menu-for-trip';
import { getContextMenuForWaypoint } from '@modules/planning/children/by-date/decorators/table/context-menu/routes/get-context-menu-for-waypoint';
import { getContextMenuForOrderAction } from '@modules/planning/children/by-date/decorators/table/context-menu/routes/get-context-menu-for-order-action';
import {
  planningDeleteRoutine,
  planningRememberFocusNExpandRowsRoutine,
  planningRememberSelectedRowsRoutine
} from '@modules/planning/children/by-date/actions';
import { useDispatch } from 'react-redux';
import { PlannerPlanningPlanTableContextMenuPopover } from '@modules/planning/children/by-date/components/result/context-menu/popover';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { handleDeleteRouteTablePayload } from '@modules/planning/children/by-date/helpers/tables/handle-delete-route-table-payload';

export interface IPlannerPlanningRoutesTableContextMenuContainerProps {
  onContextClose: () => void;
  handleShowOrderTable(val: boolean, rowID?: Order['ID']): void;
  setTriggerMapFocus: SetTriggerMapFocus;
  record: RoutesTableRecord;

  visible: boolean;
  coords: {
    x: number;
    y: number;
  };
  maxHeight: number;
}

export const PlannerPlanningRoutesTableContextMenuContainer: FC<IPlannerPlanningRoutesTableContextMenuContainerProps> = memo(
  ({ onContextClose, visible, record, coords, maxHeight, handleShowOrderTable, setTriggerMapFocus }) => {
    const dispatch = useDispatch();
    const dictionary = record?.dictionary;

    const handleDelete = (record: RoutesTableRecord) => {
      const payload = handleDeleteRouteTablePayload(record);
      dispatch(planningDeleteRoutine(payload));
      handleShowOrderTable(false);
      dispatch(planningRememberSelectedRowsRoutine({ recordIDs: [], tableName: TableName.PlanningNotDeliveredOrder }));
    };

    const tableEditRow = (table: Dictionary, rowID: number) => {
      switch (table) {
        case Dictionary.Order:
          dispatch(planningRememberSelectedRowsRoutine({ recordIDs: [rowID], tableName: TableName.PlanningNotDeliveredOrder }));
          dispatch(planningRememberFocusNExpandRowsRoutine({ ID: rowID, tableName: TableName.PlanningNotDeliveredOrder }));
          handleShowOrderTable(true);
          break;
        default:
          console.error('not implemented');
          break;
      }
    };

    let menu: any[];
    switch (dictionary) {
      case Dictionary.Route:
        menu = getContextMenuForRoute({ record: record as RouteRecord, tableEditRow, setTriggerMapFocus, handleDelete });
        break;
      case Dictionary.Trip:
        menu = getContextMenuForTrip({ record: record as TripRecord, setTriggerMapFocus, handleDelete });
        break;
      case Dictionary.Waypoint:
        menu = getContextMenuForWaypoint({ record: record as WaypointRecord, setTriggerMapFocus, handleDelete });
        break;
      case Dictionary.OrderAction:
        menu = getContextMenuForOrderAction({ record: record as OrderActionRecord, tableEditRow, handleDelete });
        break;
      default:
        menu = [];
        break;
    }

    return (
      <>
        {visible && (
          <PlannerPlanningPlanTableContextMenuPopover
            menu={menu}
            record={record}
            coords={coords}
            maxHeight={maxHeight}
            onContextClose={onContextClose}
          />
        )}
      </>
    );
  }
);
