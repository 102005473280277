import { call, put, takeLatest } from 'redux-saga/effects';
import { addOrderToServiceRoutine, deletePreCostRoutine, switchTripStatusRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ServicesResponsePrices, TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';
import { TripStatus } from '@modules/orders-and-trips/types/trip';

function* addOrderToServiceWorker(
  action: PayloadAction<{
    tripID: number;
    alias: TenderOutFastDeliveryServices;
    extra: ServicesResponsePrices;
  }>
) {
  const { request, success, failure, fulfill } = addOrderToServiceRoutine;
  const { tripID, alias, extra } = action.payload ?? {};
  try {
    yield put(request());
    yield call(OrdersNTripsApi.addOrderToService, { tripID, alias, extra });
    yield put(switchTripStatusRoutine({ status: TripStatus.onRegistration }));
    yield put(success());
  } catch (error) {
    yield put(deletePreCostRoutine.success({ tripID, alias }));
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addOrderToServiceWatcher() {
  yield takeLatest(addOrderToServiceRoutine.trigger, addOrderToServiceWorker);
}
