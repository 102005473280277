import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringGetProgressRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import { GetProgressRequest } from '@common/types/dictionaries/monitoring';
import { cleanEmptyFields } from '@common/utils/helpers/object/clean-empty-fields';

// Получает данные прогресса доставок
function* monitoringGetProgressWorker(action: PayloadAction<GetProgressRequest>) {
  const { request, success, failure, fulfill } = monitoringGetProgressRoutine;
  const params = action.payload;
  cleanEmptyFields(params);
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(MonitoringAPI.getProgress, params);
    yield put(success(data));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringGetProgressWatcher() {
  yield takeLatest(monitoringGetProgressRoutine.trigger, monitoringGetProgressWorker);
}
