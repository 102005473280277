import React, { MutableRefObject, useRef } from 'react';

/**
 * @template T
 * @param {T} value
 * @return {React.MutableRefObject<T>}
 */
export function useStatic<T>(value: T) {
  const ref: MutableRefObject<any> = useRef();
  ref.current = value;
  return ref;
}
