import { ILSAlert, ILSTooltip } from '@components/index';
import { isEmpty } from 'lodash';
import { IILSCellValidateAlertProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellValidateAlert = <R extends TableRecord>({
  error,
  message,
  showCellValidateTip,
  setShowCellValidateTip
}: IILSCellValidateAlertProps<R>): JSX.Element => {
  if (isEmpty(error) || !error) {
    return <>{message}</>;
  }

  const [{ Name, Type }] = error;
  return (
    <ILSTooltip title={Name} visible={showCellValidateTip} onVisibleChange={setShowCellValidateTip}>
      <ILSAlert type={Type} message={message} showIcon className={'cell-type-alert'} />
    </ILSTooltip>
  );
};
