import { ILSSwitch } from '@common/components';
import { FC } from 'react';

interface IProps {
  onChange: (val: boolean) => void;
  checked: boolean;
}

// Компонент свитчера – «Только новые / Показать все»
export const ILSDashboardViewedSwitcherComponent: FC<IProps> = ({ onChange, checked }) => {
  return (
    <div className="ils-monitoring-viewed-switcher">
      <ILSSwitch checked={checked} onChange={onChange} checkedChildren="Только новые" unCheckedChildren="Показать все" />
    </div>
  );
};
