import React, { FC, useEffect, useRef } from 'react';
import { MonitoringDashboardContextMenuComponent } from '../../components/context-menu';
import { ContextMenuItem, ContextMenuState } from '../../types/context-menu';

interface Props {
  setContextMenu: React.Dispatch<React.SetStateAction<ContextMenuState>>;
  visible: boolean;
  record: any;
  coords: {
    x: number;
    y: number;
  };
  maxHeight: number;
  menu: Array<Array<ContextMenuItem | null>>;
}

// Контейнер отвечающий за данные для контекстного меню
const MonitoringDashboardContextMenuContainer: FC<Props> = (props) => {
  const { setContextMenu, visible, record, coords, maxHeight, menu } = props;
  const menuRef = useRef<HTMLElement | undefined>(null);

  const onContextClose = () => {
    setContextMenu({ visible: false });
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!menuRef.current || !menuRef.current.contains(e.target as HTMLElement)) {
        onContextClose();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      {visible && (
        <MonitoringDashboardContextMenuComponent
          record={record}
          coords={coords}
          maxHeight={maxHeight}
          onContextClose={onContextClose}
          menu={menu}
          menuRef={menuRef}
        />
      )}
    </>
  );
};

export default React.memo(MonitoringDashboardContextMenuContainer);
