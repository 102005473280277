import { FC, MutableRefObject } from 'react';
import { FROM_TOP, MAX_HEIGHT_DEFAULT } from '../../constants';
import { ContextMenuItem, ContextMenuRecord } from '../../types/context-menu';
import { DashboardMenuItem } from './menu-item';

interface IProps {
  coords: {
    x: number;
    y: number;
  };
  onContextClose: () => void;
  record: ContextMenuRecord;
  menu: Array<Array<ContextMenuItem | null>>;
  maxHeight: number;
  //TODO Переименовать b1 и b2
  onClick?: (e: any, b2?: boolean, b3?: boolean) => void;
  menuRef: MutableRefObject<any>;
}

// Главный компонент контекстного меню
export const MonitoringDashboardContextMenuComponent: FC<IProps> = ({
  coords,
  record,
  menu,
  maxHeight,
  onClick,
  menuRef,
  onContextClose
}) => {
  let top = coords?.y;

  const menuHeight = menuRef?.current?.clientHeight ?? MAX_HEIGHT_DEFAULT;
  if (top + menuHeight > maxHeight) {
    top = maxHeight - menuHeight - FROM_TOP;
  }

  return (
    <div ref={menuRef} className="plan-context-menu" style={{ left: coords?.x + 'px', top: top + 'px' }}>
      {menu.map((item, ind: number) => {
        return DashboardMenuItem({ record, item: item, menuIndex: ind, onClick: onClick, onContextClose });
      })}
    </div>
  );
};

