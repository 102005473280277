import { FC } from 'react';
import { ILSButton, ILSForm } from '@components/index';
import { PointAddressFormItem } from '@modules/orders-and-trips/components/forms/form-items/point-address-form-item';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { Field, FORM_RULES, Label } from '@components/common/forms/constants';
import { FakePreCostValues } from '@modules/orders-and-trips/types/fake-pre-cost';

interface IProps {
  section: NewOrderFormTab;

  onFinish(indexedValues: FakePreCostValues): void;
}

export const ProposalSearchForm: FC<IProps> = ({ section, onFinish, children }) => {
  return (
    <>
      <ILSForm name={`${section}-fake-pre-cost`} className={'fake-pre-cost__form'} onFinish={onFinish}>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
          <PointAddressFormItem
            required
            rules={FORM_RULES[Field.SourceID]}
            className={'fake-pre-cost__form-item fake-pre-cost__form-item_address'}
            label={Label.SourcePoint}
            pointType={Field.Source}
          />
          <PointAddressFormItem
            required
            rules={FORM_RULES[Field.TargetID]}
            className={'fake-pre-cost__form-item fake-pre-cost__form-item_address'}
            label={Label.TargetPoint}
            pointType={Field.Target}
          />
        </fieldset>
        <fieldset>{children}</fieldset>
      </ILSForm>
      <ILSButton
        form={`${section}-fake-pre-cost`}
        htmlType={'submit'}
        className={'fake-pre-cost__button_search'}
        type={'primary'}
        children={'Рассчитать'}
        icon={'search'}
      />
    </>
  );
};
