import { ParserTariffParameters } from '@modules/catalog/types/tariff';
import { EMPTY_STRING } from '@common/constants';
import { SplitChar } from '@common/constants/general';
import { REGEXP_FOR_SPLITTER, REGEXP_FOR_ZONES, SPECIFIC_KEYS_MUST_BE_FIRST_IN_PARSER } from '@modules/catalog/constants/tariff';
import { isArray, isObject } from 'lodash';
import { MIN_TARIFF_VALUE } from '@modules/catalog/components/tariff/constants/general';

/**
 * Функция получения существующих параметров из формулы
 * @param formula
 * @param params
 * @returns {
 *  paramsKeysInStringSorted - отсортированный список ключей параметров в формуле
 *  formulaParams - объект с параметрами формулы
 * }
 */
export const getParamsKeysInFormula = (formula: string, params: ParserTariffParameters) => {
  const keys = params && Object.keys(params);
  const splitFormula = formula?.slice().split(REGEXP_FOR_SPLITTER);
  const formulaParams =
    splitFormula?.reduce((result, key) => {
      if (keys?.includes(key)) {
        result[key] = params[key];
      }
      return result;
    }, {}) ?? {};
  return {
    /** Сортируем и разворачиваем, чтобы корректно считались зоны, ставим переменные в начало массива,
     чтобы Duration не заменил AllUnloadDuration, AllLoadDuration раньше времени.
     * */
    paramsKeysInStringSorted: SPECIFIC_KEYS_MUST_BE_FIRST_IN_PARSER.concat(Object.keys(formulaParams).sort().reverse()),
    formulaParams
  };
};

export type ReplaceParamsKeysFunc = (arg: { substr: string; paramKey: string }) => string;
/**
 * Заменяет в формуле параметры на результат выполнения replaceFunc
 * @param paramsKeysInStringSorted
 * @param replaceFunc
 * @param formula
 * @returns
 */
export const replaceParamsKeysInFormula = ({
  formula,
  paramsKeysInStringSorted,
  replaceFunc
}: {
  paramsKeysInStringSorted: string[];
  replaceFunc: ReplaceParamsKeysFunc;
  formula: string;
}) => {
  return paramsKeysInStringSorted.reduce((prev, key) => {
    if (prev.includes(key)) {
      return prev.replaceAll(key, (substr) => replaceFunc({ substr, paramKey: key }));
    }
    return prev;
  }, formula);
};

export const getFormulaForCalculation = (keysForRawFormula: string[] | Record<string, string | number>, rawFormula: string) => {
  if (!isObject(keysForRawFormula)) return rawFormula;

  const isArrayKeys = isArray(keysForRawFormula);
  const currentDataForRawFormula = isArrayKeys ? keysForRawFormula : Object.keys(keysForRawFormula);

  return (
    currentDataForRawFormula
      .reduce((prev, key) => {
        if (prev.includes(key)) {
          return prev.replaceAll(key, () => (isArrayKeys ? String(MIN_TARIFF_VALUE) : String(currentDataForRawFormula[key])));
        }
        return prev;
      }, rawFormula)
      //mathjs читает float через точку, а не запятую
      .replaceAll(SplitChar.Comma, SplitChar.Dot)
      //убираем зоны (например КАД/МКАД/KAD/MKAD)
      .replaceAll(REGEXP_FOR_ZONES, EMPTY_STRING)
      // из зон и alias остались подчеркивания, их нужно сделать единицей, для примера расчёта
      .replaceAll('_', '1')
  );
};

export const parseTariff = (formula: string, params: ParserTariffParameters) => {
  //TODO алгоритм можно отрефакторить в будущем
  const { paramsKeysInStringSorted, formulaParams } = getParamsKeysInFormula(formula, params);

  const replaceFunc: ReplaceParamsKeysFunc = ({ paramKey }) => formulaParams[paramKey]?.name ?? paramKey;

  //создаем формулу на русском языке
  const viewFormula = replaceParamsKeysInFormula({ formula, paramsKeysInStringSorted, replaceFunc });

  //создаем формулу для mathjs (ключи меняем на единицу, представляем что любой параметр это 1)
  const forCalculation = getFormulaForCalculation(paramsKeysInStringSorted, formula);

  return { forCalculation, viewFormula, formulaParams };
};

