import { IChartAxisScale } from '@modules/monitoring/types/graph';

// Настройки графика датчиков

export enum StrokeGraphColor {
  Speed = 'yellow',
  Temperature = 'purple',
  Fuel = 'red',
  default = 'blue'
}

export enum BizChartsGraphColor {
  success = 'rgba(82, 196, 26, 1)',
  warn = 'rgb(252,167,0)',
  planColor = '#8a8989',
  planColorFeature = '#c5c4c4',
  undelivered = '#ea0000'
}

const x: IChartAxisScale = {
  type: 'time',
  mask: 'HH:mm:ss'
};

export const graphChartScale = {
  x,
  y: {}
};

export type GraphSchemaDataSetType = {
  time: string | undefined;
  data: any[] | undefined;
};
