import { apiInstance } from '@core/api/instance';
import { IDataAPI } from '@modules/planning/children/data/types';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { cleanEmptyFields } from '@common/utils/helpers/object/clean-empty-fields';

export const Data: IDataAPI = {
  getProjects: ({ till, from }) => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.GetProjects), { till, from }),
  // NOTE: при загрузке excel type не отправляем
  upload: ({ file, join }) => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.Upload), { file, join }),
  uploadTxt: ({ file, join, type }) => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.uploadTxt), { file, join, type }),
  change: ({ operation, ...data }) => {
    if (operation === DictionariesEndpoint.Update) {
      cleanEmptyFields(data);
    }
    return apiInstance().post(getAPIURL(Dictionary.Data, operation), { data: { ...data } });
  },
  getUploadTemplate: ({ addExample }) =>
    apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.GetUploadTemplate), { addExample }),
  check: () => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.Check)),
  getUploadedData: () => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.GetUploadedData)),
  view: ({ date }) => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.View), { data: { date } }),
  validateData: () =>
    apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.Validate), {
      params: {
        validationType: 'import',
        isValidationByRowNums: true
      }
    }),
  import: ({ files, projectID }) =>
    apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.Import), {
      files,
      projectID
    })
};
