import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router';
import { CATALOGS } from '../constants';
import { QUERY_PARAMS } from '@common/constants/url';
import { Dictionary } from '@common/types';

export const useCatalogTableByUrlSearchParams = ({
  activePanelKey,
  setActivePanelKey
}: {
  activePanelKey?: null | number;
  setActivePanelKey?: Dispatch<SetStateAction<number | null>>;
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchDictionary: Dictionary | null = params.get(QUERY_PARAMS.DICTIONARY) as Dictionary | null;

  useEffect(() => {
    const searchCatalog = CATALOGS.find((catalog) => catalog.dictionary === searchDictionary);
    if (!activePanelKey && searchCatalog) {
      setActivePanelKey?.(searchCatalog?.id - 1);
    }
  }, []);

  return { searchDictionary };
};
