import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getChartData } from '../actions';
import initialState from '../store';
import { GetChartDataAction } from '../types/reducers';

const monitoringControl = createReducer(initialState, {
  [getChartData.REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },
  [getChartData.SUCCESS]: (state, action: PayloadAction<GetChartDataAction>) => {
    const { chartData, ticks, colors, intervals } = action.payload;
    return { ...state, chartData, ticks, colors, intervals, isFetching: false };
  }
});

export default monitoringControl;
