import { useAppSelector } from '@core/hooks';
import { WidgetCommonParam } from '@modules/analytics/common/constants';
import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ILSBoardViewComponent from '../components/board/board-view';
import { AnalyticsTenderContext } from '../context';
import { boardsSelector, widgetParamsSelector, widgetsSelector } from '../selectors';
import { widgetsErrorSelector } from '@modules/analytics/tender/children/tender-analytics/selectors/board';

interface Props {}

/**
 * Контейнер выбранной доски
 */
export const BoardViewContainer: FC<Props> = ({}) => {
  const boards = useSelector(boardsSelector);
  const widgets = useAppSelector(widgetsSelector);
  const widgetParams = useAppSelector(widgetParamsSelector);
  const widgetsError = useAppSelector(widgetsErrorSelector);

  const board = useMemo(() => {
    return boards.selectedBoard ? boards?.data[boards.selectedBoard] : null;
  }, [boards.selectedBoard]);

  const { handleSetParams, handleGetWidgetData, handleSelectBoard } = useContext(AnalyticsTenderContext) ?? {};

  const selectDateRange = (date: [string, string]) => {
    handleSetParams({
      ...widgetParams,
      From: { name: WidgetCommonParam.From, value: date[0] },
      Till: { name: WidgetCommonParam.Till, value: date[1] }
    });
  };

  return (
    <>
      <ILSBoardViewComponent
        board={board}
        widgetsError={widgetsError}
        selectPeriod={selectDateRange}
        selectDateRange={selectDateRange}
        widgets={widgets}
        widgetParams={widgetParams}
        handleSetParams={handleSetParams}
        handleGetWidgetData={handleGetWidgetData}
        handleSelectBoard={handleSelectBoard}
      />
    </>
  );
};
