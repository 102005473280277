import { EMPTY_BOX_IMG_PATH, NO_TRIPS_IMG_PATH } from '@modules/orders-and-trips/constants/general';
import { mainActiveTabSelector } from '@modules/orders-and-trips/selectors';
import { MainTabs, MainTabsContainer } from '@modules/orders-and-trips/types/general';
import { useSelector } from 'react-redux';

export const EmptyComponent = () => {
  const activeTab = useSelector(mainActiveTabSelector(MainTabsContainer.leftBlock));
  return (
    <div className="empty-component">
      <h1>Выберите {activeTab === MainTabs.Order ? 'заявку' : 'рейс'}</h1>
      <div className="empty-img">
        <img src={activeTab === MainTabs.Order ? EMPTY_BOX_IMG_PATH : NO_TRIPS_IMG_PATH} />
      </div>
    </div>
  );
};

