import { MutableRefObject, useEffect, useState } from 'react';
import { initialContextMenuData } from '@modules/planning/children/by-date/constants/content';
import { useSelector } from 'react-redux';
import { TableRecord } from '@common/types';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export type PlanningContextMenuState = {
  record?: any;
  coords: {
    x: number;
    y: number;
  };
  maxHeight: number;
  visible: boolean;
};

type Arg = { tableRef?: MutableRefObject<HTMLDivElement | null> };

//TODO разбить на useContextMenu и usePlannerContextMenu - это может  быть универсальный хук таблицы, а закрытие при смене плана можно вынести
export const useContextMenu = ({ tableRef }: Arg) => {
  const activePlanID = useSelector(activePlanIDSelector);
  const [contextMenu, setContextMenu] = useState<PlanningContextMenuState>(initialContextMenuData);

  const hasContextMenu = activePlanID && contextMenu?.visible;

  useEffect(() => {
    onContextClose();
  }, [activePlanID]);

  const onContextOpen = (record: TableRecord, e: MouseEvent) => {
    const planTableCoordinates = tableRef?.current?.getBoundingClientRect();
    setContextMenu((prev) => {
      if (planTableCoordinates && !prev.visible) {
        const x = e.clientX - (planTableCoordinates.x ?? 0);
        const y = e.clientY;
        const maxHeight = planTableCoordinates.height + planTableCoordinates.y;
        return { record, coords: { x, y }, maxHeight, visible: true };
      }
      return prev;
    });
  };

  const onContextClose = () => {
    setContextMenu(initialContextMenuData);
  };

  return {
    onContextOpen,
    onContextClose,
    contextMenu,
    hasContextMenu
  };
};
