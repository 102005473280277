import { ILSButton } from '@common/components';
import { FormName } from '@modules/orders-and-trips/types/form';
import { FC } from 'react';

interface IProps {
  form: FormName;
}

export const TripToTenderSubmit: FC<IProps> = ({ ...props }) => {
  return (
    <div className="tender-trip-form-submit">
      <ILSButton type="primary" ghost htmlType="submit" {...props}>
        Заполнить форму
      </ILSButton>
      <ILSButton type="primary" htmlType="submit" {...props}>
        Разместить на ТП
      </ILSButton>
    </div>
  );
};

