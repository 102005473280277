import { createSelector } from 'reselect';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { Depot, IndexedArray } from '@common/types';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';

export const extraDataFetchSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.extraData.isFetching,
  (isFetching) => isFetching
);

export const extraDepotSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.extraData.depot,
  (depot) => depot
);

export const extraRestrictionSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.extraData.restriction,
  (restriction) => restriction
);

export const extraVehicleTypesSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.extraData.vehicleType,
  (vehicleTypes) => vehicleTypes
);

export const extraDataSelector = createSelector(
  extraDepotSelector,
  extraRestrictionSelector,
  extraVehicleTypesSelector,
  (depot, restriction, vehicleTypes) => ({
    depot,
    restriction,
    vehicleTypes
  })
);

export const depotRefDataSelector = createSelector(extraDepotSelector, (depots) => {
  const depotOptions = createDepotCascaderOptions(depots as IndexedArray<Depot>);
  return {
    SourceID: depotOptions,
    TargetID: depotOptions
  };
});

