import { TENDER_DATE_PICKERS } from '../../../constants/content';
import { Tender } from '../../../types';
import { TenderLotTemplate } from '../../../types/lot';

export const cleanDateTimeTenderFields = (tender: Tender | (Omit<Tender, 'TenderLot'> & { TenderLot: TenderLotTemplate })) => {
  const currentTender = { ...tender };

  TENDER_DATE_PICKERS.forEach((dateType) => {
    if (dateType in currentTender) {
      currentTender[dateType] = null;
    }
  });

  return currentTender;
};
