import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { generateUUID } from '@common/utils/general/uuid';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_FLOAT_VALIDATION_PARAMETERS, CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const NEW_VEHICLE_PROFILE = {
  Name: 'Новый профиль',
  VehicleProfileCode: generateUUID()
};

export const VEHICLE_PROFILE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_VEHICLE_PROFILE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Код',
      dataIndex: 'VehicleProfileCode',
      key: 'VehicleProfileCode',
      invisible: true,
      editable: false,
      active: true,
      type: CellType.Input,
      sorting: 2,
      ellipsis: true,

      filtering: FilterCellType.String
    },
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      filtering: FilterCellType.String,
      sorting: 3
    },
    {
      title: 'Грузоподъемность, кг',
      dataIndex: 'MaxMass',
      key: 'MaxMass',
      editable: true,
      active: true,
      type: CellType.Float,
      filtering: FilterCellType.String,
      align: 'center',

      sorting: 2,
      ellipsis: true
    },
    {
      title: 'Объем кузова, м3',
      dataIndex: 'MaxVolume',
      key: 'MaxVolume',
      editable: true,
      active: true,
      type: CellType.Float,
      align: 'center',
      ellipsis: true,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Паллет, шт',
      dataIndex: 'MaxPallets',
      key: 'MaxPallets',
      editable: true,
      active: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Коробов, шт',
      dataIndex: 'MaxBoxes',
      key: 'MaxBoxes',
      editable: true,
      active: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Стоимость, руб',
      dataIndex: 'MaxCost',
      key: 'MaxCost',
      editable: true,
      active: false,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длина кузова, мм',
      dataIndex: 'MaxUnitLength',
      key: 'MaxUnitLength',
      editable: true,
      active: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Ширина кузова, мм',
      dataIndex: 'MaxUnitWidth',
      key: 'MaxUnitWidth',
      editable: true,
      active: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Высота кузова, мм',
      dataIndex: 'MaxUnitHeight',
      key: 'MaxUnitHeight',
      editable: true,
      active: false,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Код профиля',
      dataIndex: 'CarCode',
      key: 'CarCode',
      invisible: true,
      type: CellType.Input,
      filtering: FilterCellType.String
    },
    {
      title: 'Марка',
      dataIndex: 'CarMark',
      key: 'CarMark',
      editable: true,
      active: false,
      type: CellType.Input,
      filtering: FilterCellType.String,
      ellipsis: true
    },
    {
      title: 'Средняя скорость, км/ч',
      dataIndex: 'AverageSpeed',
      key: 'AverageSpeed',
      editable: true,
      active: false,
      filtering: FilterCellType.String,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Расход топлива',
      dataIndex: 'FuelConsumption',
      key: 'FuelConsumption',
      editable: true,
      active: false,
      type: CellType.Float,
      filtering: FilterCellType.String,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Длительность погрузки',
      dataIndex: 'LoadDuration',
      key: 'LoadDuration',
      editable: true,
      active: false,
      sorting: 1,
      type: CellType.TimeDouble
    },
    {
      title: 'Длительность разгрузки',
      dataIndex: 'UnloadDuration',
      key: 'UnloadDuration',
      editable: true,
      active: false,
      sorting: 1,
      type: CellType.TimeDouble
    }
  ]
};
