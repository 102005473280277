import { useMemo } from 'react';
import { ILSAlert } from '@common/components';

export const useRoutesTableLocale = (isPlanning: boolean) => {
  const locale = useMemo(() => {
    return {
      emptyText: (
        <ILSAlert
          key="warning"
          message={isPlanning ? 'Таблица с рейсами будет отображена после завершения расчета' : 'В текущем плане нет ни одного маршрута'}
        />
      )
    };
  }, [isPlanning]);

  return { locale };
};
