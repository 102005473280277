import { FC, useEffect } from 'react';
import { useAppSelector } from '@core/hooks';
import { useDispatch } from 'react-redux';
import { UPDATE_INTERVAL } from '@common/constants/general';
import { roadGraphCatalogReadRoutine } from '@modules/road-graph/common/actions';
import { catalogInfoSelector } from '@modules/road-graph/common/selectors';
import ILSRoadGraphTestDepotsButton from '@modules/road-graph/common/components/show-depots';
import { Dictionary } from '@common/types';

interface Props {
  showDepots: boolean;
  setShowDepots: (s: boolean) => void;
}

const ILSRoadGraphContainer: FC<Props> = ({ showDepots, setShowDepots }) => {
  const info = useAppSelector(catalogInfoSelector);
  const dispatch = useDispatch();

  //загрузка справочников
  const catalogLoad = (dictionary: string) => {
    const lastUpdate = info && info[dictionary] && info[dictionary].lastUpdate;
    const checkInterval = Date.now() - parseInt(UPDATE_INTERVAL);
    //проверяем время последнего обновления и запрашиваем данные, если интервал пройден
    if (!lastUpdate || checkInterval > lastUpdate) {
      dispatch(
        roadGraphCatalogReadRoutine({
          dictionary,
          dictionaryFetching: true
        })
      );
    }
  };
  useEffect(() => {
    catalogLoad(Dictionary.Depot);
  }, []);

  return (
    <div className="position-relative">
      <ILSRoadGraphTestDepotsButton show={showDepots} setShow={setShowDepots} />
    </div>
  );
};

export default ILSRoadGraphContainer;
