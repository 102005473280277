import { CargoFormFields, FormFieldNames } from '@modules/orders-and-trips/types/form';
import { isNumber } from 'lodash';
import { DraftTripNOrder } from '@modules/orders-and-trips/types/trip';

export const cargoDataToForm = (cargos: DraftTripNOrder[number]['Cargo']) => {
  return cargos.reduce(
    (result, cargo) => {
      result[FormFieldNames.Comment].push(cargo.Comment);
      isNumber(cargo.Amount) && result[FormFieldNames.Amount].push(cargo.Amount);
      isNumber(cargo.UnitHeight) && result[FormFieldNames.Height].push(cargo.UnitHeight);
      isNumber(cargo.UnitLength) && result[FormFieldNames.Length].push(cargo.UnitLength);
      isNumber(cargo.UnitWidth) && result[FormFieldNames.Width].push(cargo.UnitWidth);
      isNumber(cargo.Mass) && result[FormFieldNames.Mass].push(cargo.Mass);
      isNumber(cargo.Volume) && result[FormFieldNames.Volume].push(cargo.Volume);
      result[FormFieldNames.RestrictionID].push(...Object.keys(cargo.RestrictionEnum ?? {}));
      return result;
    },
    {
      [FormFieldNames.Amount]: [],
      [FormFieldNames.Comment]: [],
      [FormFieldNames.Height]: [],
      [FormFieldNames.Length]: [],
      [FormFieldNames.Mass]: [],
      [FormFieldNames.Volume]: [],
      [FormFieldNames.Width]: [],
      [FormFieldNames.RestrictionID]: []
    } as Required<CargoFormFields>
  );
};
