import { plannerSettingsRoutine } from '@modules/planning/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

function* plannerSettingsWorker(
  action: PayloadAction<{
    parameter: string;
    data: Record<string, boolean>;
  }>
) {
  const { success, failure, fulfill } = plannerSettingsRoutine;
  const { parameter, data } = action.payload;

  try {
    yield put(success({ parameter, data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* plannerSettingsWatcher() {
  yield takeLatest(plannerSettingsRoutine.trigger, plannerSettingsWorker);
}
