import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import React, { FC, useContext, useEffect, useState } from 'react';
import ILSWidgetWrapper from '../components/widget/widget-wrapper';
import analyticsService from '../service';
import { AnalyticsWidgetBoardContext } from '@modules/analytics/common/context/widget-board-context';
import { useGetWidgetParams } from '@modules/analytics/common/hooks/use-get-widget-params';

interface Props {
  from?: string | number;
  till?: string | number;
  widget: AnalyticsWidget;
  board: HTMLElement | null;
  setBoardWidgets?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

const AnalyticsModalWidgetContainer: FC<Props> = ({ from, till, widget, board, setBoardWidgets }) => {
  const [data, setData] = useState<any>();

  const [loading, setLoading] = useState(true);

  const { widgetParams } = useContext(AnalyticsWidgetBoardContext);

  const { params } = useGetWidgetParams(widget, widgetParams);

  useEffect(() => {
    const fetchData = async () => {
      if (params) {
        setLoading(true);
        const data = await analyticsService.getWidgetDataForPDFBoards(from as number, till as number, widget, params);
        setData(data);

        setBoardWidgets?.((prev: any) => ({
          ...prev,
          [widget.ID]: true
        }));
        setLoading(false);
      }
    };

    fetchData();
  }, [params]);

  return <ILSWidgetWrapper widget={{ ...widget, loading, Data: data }} hideWidget={false} editMode={false} board={board} />;
};

export default AnalyticsModalWidgetContainer;
