import { deleteReportRoutine, documentsGetTemplatesRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { DeleteReportPayload } from '@modules/documents/types/documents-actions';

function* worker(action: DeleteReportPayload) {
  const { success, fulfill, failure, request } = deleteReportRoutine;
  const { projectId, id } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Documents.deleteReport, { id });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(documentsGetTemplatesRoutine({ projectId }));
    yield put(fulfill());
  }
}

export function* deleteReportWatcher() {
  yield takeEvery(deleteReportRoutine.trigger, worker);
}
