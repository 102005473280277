import { put, takeLatest } from 'redux-saga/effects';
import { dropBoardWidgetRoutine } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

function* analyticsDropBoardWidgetWorker(action: PayloadAction<{ widget: AnalyticsWidget }>) {
  const { success, failure, fulfill } = dropBoardWidgetRoutine;

  const { widget } = action.payload;

  try {
    yield put(success({ widget }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsDropBoardWidgetWatcher() {
  yield takeLatest(dropBoardWidgetRoutine.TRIGGER, analyticsDropBoardWidgetWorker);
}
