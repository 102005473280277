import { CellType, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const tableConfig: TableConfig = {
  columns: [
    ID_COLUMN,
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Марка ТС',
      dataIndex: 'Model',
      key: 'Model',
      active: true,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Рег.Номер',
      dataIndex: 'Number',
      key: 'Number',
      active: true,
      type: CellType.RegNumber,
      ellipsis: true
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: true,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: true,
      type: CellType.Phone,
      ellipsis: true
    },
    {
      title: 'План/Факт пробег км.',
      dataIndex: 'Distance',
      key: 'Distance',
      active: true,
      type: CellType.Text,
      ellipsis: true
    },
    {
      title: 'План/Факт/Всего клиенты(точки)',
      dataIndex: 'PlanFactDepot',
      key: 'PlanFactDepot',
      active: true,
      type: CellType.Text,
      ellipsis: true
    },
    {
      title: 'Статус движения',
      titleText: 'Статус движения',
      titleTooltip: 'Статус движения',
      dataIndex: 'SpeedIcon',
      key: 'SpeedIcon',
      active: true,
      type: CellType.Icon,
      ellipsis: true,
      sorting: false
    },
    {
      title: 'Скорость',
      dataIndex: 'Speed',
      key: 'Speed',
      active: true,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Зона',
      titleTip: 'Текущая зона',
      dataIndex: 'ZoneID',
      key: 'ZoneID',
      active: true,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Имя группы',
      dataIndex: 'GroupName',
      key: 'GroupName',
      active: true,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Комментарий',
      dataIndex: 'Comment',
      key: 'Comment',
      active: false,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Широта',
      dataIndex: 'Latitude',
      key: 'Latitude',
      active: false,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Долгота',
      dataIndex: 'Longitude',
      key: 'Longitude',
      active: false,
      type: CellType.String,

      ellipsis: true
    },
    {
      title: 'Старт/план',
      dataIndex: TableColumnName.TripBeginPlan,
      key: TableColumnName.TripBeginPlan,
      active: false,
      type: CellType.TimeStringUtc,

      ellipsis: true
    },
    {
      title: 'Финиш/план',
      dataIndex: TableColumnName.TripEndPlan,
      key: TableColumnName.TripEndPlan,
      active: false,
      type: CellType.TimeStringUtc,

      ellipsis: true
    },
    {
      title: 'Старт/факт',
      dataIndex: TableColumnName.TripBeginFact,
      key: TableColumnName.TripBeginFact,
      active: false,
      type: CellType.TimeStringUtc,

      ellipsis: true
    },
    {
      title: 'Финиш/факт',
      dataIndex: TableColumnName.TripEndFact,
      key: TableColumnName.TripEndFact,
      active: false,
      type: CellType.TimeStringUtc,
      ellipsis: true
    },
    {
      title: 'Общее время движения/план',
      dataIndex: TableColumnName.TotalPlanDriveTime,
      key: TableColumnName.TotalPlanDriveTime,
      active: false,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Общее время движения/факт',
      dataIndex: TableColumnName.TotalFactDriveTime,
      key: TableColumnName.TotalFactDriveTime,
      active: false,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Суммарное  план. время стоянок (чч:мм)',
      dataIndex: TableColumnName.TotalPlanStopsTime,
      key: TableColumnName.TotalPlanStopsTime,
      active: false,
      type: CellType.TimeStringUtc,

      ellipsis: true
    },
    {
      title: 'Суммарное факт. время стоянок (чч:мм)',
      active: false,
      type: CellType.Text,
      ellipsis: true,
      dataIndex: TableColumnName.TotalFactStopsTime,
      key: TableColumnName.TotalFactStopsTime
    },
    {
      title: 'Общее время/план',
      dataIndex: TableColumnName.TotalPlanTime,
      key: TableColumnName.TotalPlanTime,
      active: false,
      type: CellType.TimeStringUtc,
      ellipsis: true
    },
    {
      title: 'Общее время/факт',
      dataIndex: TableColumnName.TotalFactTime,
      key: TableColumnName.TotalFactTime,
      active: false,
      type: CellType.TimeStringUtc,
      ellipsis: true
    },
    {
      title: 'Статус рейсов',
      dataIndex: 'VehicleStatus',
      key: 'VehicleStatus',
      active: false,
      type: CellType.Text,
      ellipsis: true
    },
    {
      title: 'Суммарное факт. время стоянок (чч:мм)',
      dataIndex: 'TotalFactStopsTime',
      key: 'TotalFactStopsTime',
      active: false,
      type: CellType.Text,
      ellipsis: true
    }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: true,
  copying: false
};
