import { useAppSelector } from '@core/hooks';
import { FC } from 'react';
import { DetailsTabComponent } from '../../components/details/details-tab';
import { activeVehicleIDSelector } from '../../selectors';
import { EventDetailsTab } from '../../types';
import { TabType } from '../../types/event-details-tabs';
import { MonitoringEventDetailsStore } from '../../types/store';

interface DetailsTabProps {
  tabs: Array<EventDetailsTab>;
  activeTab?: TabType;
  waypointId?: number;
  handleChange?: (activeKey: string | number) => void;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
}

/**
 * Контейнер для отрисовки табов
 */
export const DetailsTabContainer: FC<DetailsTabProps> = ({ tabs, activeTab, handleChange, ...props }) => {
  const selectedVehicleID = useAppSelector(activeVehicleIDSelector);

  return (
    <DetailsTabComponent
      {...props}
      tabs={tabs}
      activeTab={activeTab}
      handleChange={handleChange}
      selectedVehicleID={selectedVehicleID}
    />
  );
};
