import { FC } from 'react';
import { ButtonText } from '@core/constants/core';
import { ILSButtonTooltip } from '@common/components';
import { IButtonTooltipProps } from '@components/general/buttons/types';

interface IProps {
  handleAcceptGrouping: () => void;
  handleResetGrouping: () => void;
  handleCancelGrouping: () => void;
  isResetButtonEnabled: boolean;
}

export const useGroupingButtons = ({ handleAcceptGrouping, handleResetGrouping, handleCancelGrouping, isResetButtonEnabled }: IProps) => {
  const groupingButtons: Array<{ Component: FC<IButtonTooltipProps>; props: IButtonTooltipProps }> = [
    {
      Component: ILSButtonTooltip,
      props: {
        title: ButtonText.ResetSorting,
        children: ButtonText.ResetSorting,
        onClick: handleResetGrouping,
        disabled: !isResetButtonEnabled
      }
    },
    {
      Component: ILSButtonTooltip,
      props: {
        title: ButtonText.Cancel,
        children: ButtonText.Cancel,
        onClick: handleCancelGrouping
      }
    },
    {
      Component: ILSButtonTooltip,
      props: {
        title: ButtonText.Accept,
        children: ButtonText.Accept,
        type: 'primary',
        onClick: handleAcceptGrouping
      }
    }
  ];

  return { groupingButtons };
};
