import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { updateBoardRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';

export const updateBoardReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [updateBoardRoutine.REQUEST]: (state, action: PayloadAction<AnalyticsBoardModel>) => {
    state.Boards.isFetching = true;
    state.Boards.updatingBoard = action?.payload?.ID;
    return state;
  },
  [updateBoardRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsBoardModel>;
    }>
  ) => {
    const { data } = action.payload;

    if (state.Boards?.data && Object.values(data).length) {
      state.Boards.data = Object.values(state.Boards?.data).reduce((prev, cur) => {
        if (data[cur.ID]) {
          prev[cur.ID] = data[cur.ID];
        } else {
          prev[cur.ID] = cur;
        }

        return prev;
      }, {});
    }

    return state;
  },
  [updateBoardRoutine.FAILURE]: (state) => {
    state.Boards.updatingBoard = undefined;
    return state;
  },
  [updateBoardRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.Boards.isFetching = false;
    state.Boards.updatingBoard = undefined;
    return state;
  }
});
