import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray, PayloadActionError } from '@common/types';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';
import { getEventAllDataRoutine } from '../actions';
import { MonitoringVehicleEventsModel } from '@common/types/dictionaries/monitoring-vehicle';
import { color4vehicle, int2color } from '@common/decorators/color';
import { BASE_ROUTE_COLOR } from '@common/constants';

// Устанавливает в стейт все данные
export const monitoringgetEventAllDataReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [getEventAllDataRoutine.REQUEST]: (state, action: PayloadAction<any>) => {
    state.VehicleEvents.isFetching = true;
    return state;
  },
  [getEventAllDataRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const { data, from, till, Plan } = action.payload;
    state.VehicleEvents.isFetching = false;
    state.VehicleEvents.data = data || {};
    state.VehicleEvents.Plan = Plan || {};

    state.VehicleEvents.data = Object.values(data as IndexedArray<MonitoringVehicleEventsModel>).reduce((prev, cur) => {
      prev[cur.ID] = {
        ...cur,
        RouteColor: cur.RouteColor ? int2color(cur.RouteColor) : cur.ID ? color4vehicle(cur.ID) : BASE_ROUTE_COLOR
      };

      return prev;
    }, {});

    state.VehicleEvents.lastUpdate = new Date().getTime();
    state.VehicleEvents.lastUpdateInterval = {
      from,
      till
    };

    return state;
  },
  [getEventAllDataRoutine.FAILURE]: (state, action: PayloadActionError & PayloadAction<any>) => {
    const {} = action.payload;
    state.VehicleEvents.isFetching = false;
    state.VehicleEvents.lastUpdate = undefined;
    state.VehicleEvents.lastUpdateInterval = undefined;

    return state;
  },
  [getEventAllDataRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    return state;
  }
});
