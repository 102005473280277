import { createSelector } from 'reselect';
import DocumentTemplatesStore from '../types';
import { getDocumentsTemplates } from '@modules/documents/selectors';
import { compareAsString } from '@common/utils';

export const documentTemplatesSelector = createSelector(
  (state: { DocumentsTemplates: DocumentTemplatesStore }) => state.DocumentsTemplates,
  (DocumentsTemplates: DocumentTemplatesStore) => DocumentsTemplates
);

export const getCurrentTemplate = createSelector(
  (state: { DocumentsTemplates: DocumentTemplatesStore }) => state.DocumentsTemplates,
  (DocumentsTemplates: DocumentTemplatesStore) => DocumentsTemplates.currentTemplate
);

export const getCurrentTemplateId = createSelector(
  (state: { DocumentsTemplates: DocumentTemplatesStore }) => state.DocumentsTemplates,
  (DocumentsTemplates: DocumentTemplatesStore) => DocumentsTemplates.currentTemplateId
);

export const getCurrentDealTemplate = createSelector(
  (state: { DocumentsTemplates: DocumentTemplatesStore }) => state.DocumentsTemplates,
  (DocumentsTemplates: DocumentTemplatesStore) => DocumentsTemplates.currentDealTemplate
);

export const getTemplatesFetching = createSelector(
  (state: { DocumentsTemplates: DocumentTemplatesStore }) => state.DocumentsTemplates,
  (DocumentsTemplates: DocumentTemplatesStore) => DocumentsTemplates.isFetching
);

export const documentTemplatesTypeSelector = createSelector(
  getDocumentsTemplates,
  getCurrentTemplateId,
  //@ts-ignore
  (DocumentsTemplates, { templateId }) => {
    return { type: DocumentsTemplates.find(({ ID }) => compareAsString(templateId, ID))?.Type };
  }
);
