import { getDecoratedOrders } from '@common/models/order/decorators';
import { IndexedArray } from '@common/types';
import { TripResponse } from '@modules/orders-and-trips/types/trip';
import { isEmpty } from 'lodash';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';

export const tripsTodata = (data: IndexedArray<TripResponse>) => {
  return Object.entries(data)?.reduce((resultTrips, [key, trip]) => {
    resultTrips[key] = {
      ID: key,
      ...trip,
      orders: isEmpty(trip.orders) ? [] : getDecoratedOrders({ orders: trip.orders })
    };
    return resultTrips;
  }, {} as Record<number, TripResponse & { orders: DecoratedOrders }>);
};

