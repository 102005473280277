import { defineModule } from '@core/modules';
import { ModulePath, ModuleTitle } from '@common/types';
import DocumentsTemplates from './containers';
import { templatesRootReducer } from './reducers';
import * as templatesRootSagas from './sagas';
import { getAllModuleSagas } from '@core/sagas';

export default defineModule({
  title: ModuleTitle.DocumentsTemplates,
  path: ModulePath.DocumentsTemplates,
  component: DocumentsTemplates,
  reducer: templatesRootReducer,
  sagas: getAllModuleSagas(templatesRootSagas)
});
