import { Account } from './account';
import { Depot } from './depot';
import { Driver, DriverRestMode } from './driver';
import { IEnum } from './enum';
import { Project } from './project';
import { Restriction } from './restriction';
import { CouplingType, ForbiddenZone, ID, SpeedProfile, TimeStamp, TransportCompany } from '@common/types';
import { TripTemplate } from './trip';
import { User } from './user';
import { Zone } from './zone';

export enum VehicleUseMode {
  NotUsed,
  AsVirtual,
  AsReal
}

export enum VehicleTakeForPlanner {
  NotTaken,
  Taken
}

export enum VehicleTakeOrdersOnlyInZones {
  No,
  Yes
}

export enum VehiclePriority {
  Own,
  Partner,
  Hired
}

export enum VehiclePriorityLabel {
  Own = 'Свой',
  Partner = 'Партнерский',
  Hired = 'Наёмный'
}

export enum VehicleForMarker {
  No,
  Yes
}

export enum VehicleIsAutomotive {
  No,
  Yes
}

export enum VehicleStrictStartTime {
  No,
  Yes
}

export type VehicleModel = {
  ID: number;
  Name: string;
  Model: string | null;
  RegNumber: string | null;
  Comment: string | null;
  Deleted: string | null;
  VehicleGroupID: number | null;
  AccountID: Account['ID'];
  RouteColor: number | null;
  StartDepotID: number | null;
  EndDepotID: number | null;
  VehicleProfileID: VehicleProfile['ID'] | null;
  VehicleProfile?: VehicleProfile | null;
  VehicleTimelineID: VehicleTimeline['ID'] | null;
  VehicleOverloadID: VehicleOverload['ID'] | null;
  VehicleTariffID: VehicleTariff['ID'] | null;
  SpeedProfileID: SpeedProfile['ID'] | null;
  DriverID: Driver['ID'] | null;
  ExpeditorID: Driver['ID'] | null;
  ICode: string | null;
  VehicleCode: string | null;
  TakeOnlyOrdersInZones: VehicleTakeOrdersOnlyInZones | null;
  Priority: VehiclePriority | null;
  ReleaseDate: TimeStamp | null;
  Info: JSON | null;
  UseMode: VehicleUseMode | null;
  ForMarket: VehicleForMarker | null;
  TripDeviationDistance: number | null;
  FriendZoneID?: Array<Zone['ID']>;
  ForbiddenZoneID?: Array<string | number>;
  IsAutomotive: VehicleIsAutomotive;
  StrictStartTime: VehicleStrictStartTime;
  FrontCouplingTypeID: CouplingType['ID'] | null;
  RearCouplingTypeID: CouplingType['ID'] | null;
  TransportCompanyID: TransportCompany['ID'] | null;
  VehicleTypeID: number | null;
  RestrictionID?: Array<Restriction['ID']>;
};

export type Vehicle = VehicleModel & {
  RoadTrain: [string, string] | [];
  StartDepotEnum: IEnum<Depot['Name']>;
  EndDepotEnum: IEnum<Depot['Name']>;
  DriverEnum: IEnum<Driver['Name']>;
  ExpeditorEnum: IEnum<Driver['Name']>;
  VehicleProfileEnum?: IEnum<VehicleProfile['Name']>;
  VehicleTimelineEnum?: IEnum<VehicleTimeline['Name']>;
  VehicleOverloadEnum: IEnum<VehicleOverload['Name']>;
  SpeedProfileEnum?: IEnum<SpeedProfile['Name']>;
  RestrictionEnum: IEnum<Restriction['Name']>;
  VehicleTariffEnum?: IEnum<VehicleTariff['Name']>;
  TripTemplateEnum: IEnum<TripTemplate['Name']>;
  ForbiddenZoneEnum: IEnum<ForbiddenZone['Name']>;
  FriendZoneEnum: IEnum<Zone['Name']>;
  VehicleTypeEnum: IEnum<VehicleType['Name']>;
  TransportCompanyID: TransportCompany['ID'] | null;
  VehicleTypeID: VehicleType['ID'] | null;
  FrontCouplingTypeEnum: IEnum<Vehicle['Name']>;
  RearCouplingTypeEnum: IEnum<Vehicle['Name']>;
  EndDepot?: Depot;
  StartDepot?: Depot;
  TransportCompanyEnum: IEnum<TransportCompany['Name']>;
  'VehicleProfile.Name'?: VehicleProfile['Name'];
  'VehicleProfile.MaxUnitLength'?: VehicleProfile['MaxUnitLength'];
  'VehicleProfile.MaxUnitWidth'?: VehicleProfile['MaxUnitWidth'];
  'VehicleProfile.MaxUnitHeight'?: VehicleProfile['MaxUnitHeight'];
  'VehicleProfile.CarCode'?: VehicleProfile['CarCode'];
  'VehicleProfile.CarMark'?: VehicleProfile['CarMark'];
  'VehicleProfile.AverageSpeed'?: VehicleProfile['AverageSpeed'];
  'VehicleProfile.FuelConsumption'?: VehicleProfile['FuelConsumption'];
  'VehicleProfile.MaxMass'?: VehicleProfile['MaxMass'];
  'VehicleProfile.MaxVolume'?: VehicleProfile['MaxVolume'];
  'VehicleProfile.Flags'?: VehicleProfile['Flags'];
  'VehicleProfile.MaxPallets'?: VehicleProfile['MaxPallets'];
  'VehicleProfile.MaxBoxes'?: VehicleProfile['MaxBoxes'];
  'VehicleProfile.MaxCost'?: VehicleProfile['MaxCost'];
  'VehicleProfile.MainScheme'?: VehicleProfile['MainScheme'];
  'VehicleProfile.SecondScheme'?: VehicleProfile['SecondScheme'];
  'VehicleProfile.VehicleTariffID'?: VehicleProfile['VehicleTariffID'];
  'VehicleProfile.Info'?: VehicleProfile['Info'];
  'VehicleProfile.LoadDuration'?: VehicleProfile['LoadDuration'];
  'VehicleProfile.UnloadDuration'?: VehicleProfile['UnloadDuration'];
  'VehicleTimeline.MaxClients'?: VehicleTimeline['MaxClients'];
  'VehicleTimeline.MaxOrders'?: VehicleTimeline['MaxOrders'];
  'VehicleTimeline.MinStartTime'?: VehicleTimeline['MinStartTime'];
  'VehicleTimeline.MaxDuration'?: VehicleTimeline['MaxDuration'];
  'VehicleTimeline.DurationOfWork'?: VehicleTimeline['DurationOfWork'];
  'VehicleTimeline.DurationOfRest'?: VehicleTimeline['DurationOfRest'];
  'VehicleTimeline.MaxDistance'?: VehicleTimeline['MaxDistance'];
  'VehicleTimeline.DistanceOfWork'?: VehicleTimeline['DistanceOfWork'];
  'VehicleTimeline.MaxDurationRoute'?: VehicleTimeline['MaxDurationRoute'];
  'VehicleTimeline.MaxDistanceRoute'?: VehicleTimeline['MaxDistanceRoute'];
  'VehicleTimeline.MaxTrips'?: VehicleTimeline['MaxTrips'];
  'VehicleTimeline.DurationOfFirstWork'?: VehicleTimeline['DurationOfFirstWork'];
  'VehicleTimeline.DurationOfFirstRest'?: VehicleTimeline['DurationOfFirstRest'];
  'VehicleTimeline.WorkPerDay'?: VehicleTimeline['WorkPerDay'];
  'VehicleTimeline.DriverRestMode'?: VehicleTimeline['DriverRestMode'];
  SpeedProfileDeleted?: SpeedProfile['Deleted'];
  VehicleTariffDeleted?: VehicleTariff['Deleted'];
  ProjectVehicle?: Array<ProjectVehicle> | ProjectVehicle;
};

export type VehicleResponse = Array<Vehicle>;

export type RouteVehicle = Pick<
  Vehicle,
  'ID' | 'Name' | 'RegNumber' | 'Model' | 'TransportCompanyID' | 'RouteColor' | 'Deleted' | 'UseMode'
> &
  Partial<Pick<Vehicle, 'StartDepotEnum' | 'EndDepotEnum' | 'ForbiddenZoneEnum'>>;

export type ProjectVehicle = {
  ID: number;
  VehicleID: number | null | undefined;
  ProjectID: Project['ID'];
  ProductorID: number | null;
  StartDate: number | null;
  StartTime: number | null;
  TakeForPlanner: VehicleTakeForPlanner;
};

export type VehicleOverload = {
  ID: number;
  Name: string;
  MaxUnitLengthOverload?: number | null;
  MaxUnitWidthOverload?: number | null;
  MaxUnitHeightOverload?: number | null;
  MaxDistanceOverload?: number | null;
  MaxClientsOverload?: number | null;
  MaxOrdersOverload?: number | null;
  MaxDurationOverload?: number | null;
  MinStartTimeOverload?: null;
  MaxTripsOverload?: number | null;
  MaxMassOverload?: number | null;
  MaxPalletsOverload?: number | null;
  MaxBoxesOverload?: number | null;
  MaxCostOverload?: number | null;
  MaxVolumeOverload?: number | null;
  MaxDistanceRouteOverload?: number | null;
  MaxDurationRouteOverload?: number | null;
  Deleted: null;
};
export type VehicleOverloads = Array<VehicleOverload>;

export type VehicleProfile = {
  ID: number;
  Name: string | null;
  MaxUnitLength: number | null;
  MaxUnitWidth: number | null;
  MaxUnitHeight: number | null;
  CarCode: string | null;
  CarMark: string | null;
  AverageSpeed: number | null;
  FuelConsumption: number | null;
  MaxMass: number | null;
  MaxVolume: number | null;
  Flags: number | null;
  MaxPallets: number | null;
  MaxBoxes: number | null;
  MaxCost: number | null;
  MainScheme: number | null;
  SecondScheme: number | null;
  VehicleTariffID: number | null;
  LoadDuration: number | null;
  UnloadDuration: number | null;
  Deleted: null;
  Info: null;
};
export type VehicleProfiles = Array<VehicleProfile>;

export type VehicleTariff = {
  ID: ID;
  Deleted: TimeStamp | null;
  Name: string;
  PlanPriceFormula: string | null;
  RoutePriceFormula: string | null;
  TripPriceFormula: string | null;
  PlanPriceFormulaDraft: string | null;
  RoutePriceFormulaDraft: string | null;
  TripPriceFormulaDraft: string | null;
  MaxDistanceRoute: number | null;
  MaxDurationRoute: number | null;
};
export type VehicleTariffs = Array<VehicleTariff>;

export type VehicleTariffPart = {
  ID: number;
  Name: string;
  Formula: string | null;
  AccountID: Account['ID'];
};

export type VehicleTimeline = {
  ID: number;
  Name: string;
  MaxClients: number | null;
  MaxOrders: number | null;
  MinStartTime: number | null;
  MaxDuration: number | null;
  DurationOfWork: number | null;
  DurationOfRest: number | null;
  MaxDistance: number | null;
  DistanceOfWork: number | null;
  MaxDurationRoute: number | null;
  MaxDistanceRoute: number | null;
  MaxTrips: number | null;
  DurationOfFirstWork: number | null;
  DurationOfFirstRest: number | null;
  WorkPerDay: number | null;
  DriverRestMode: DriverRestMode | null;
  Deleted: TimeStamp | null;
};
export type VehicleTimelines = Array<VehicleTimeline>;

export type Tracker = {
  IMEI: string | number;
  TrackerModelID: null;
  TrackerModelProfileID: null;
  Type: number;
  OldDeviceID: null;
  SerialNo: null;
  SimNumber: null;
  SimTariff: null;
  StatusID: null;
  Info: null;
  Created: TimeStamp;
  Changed: TimeStamp;
  Comment: null;
};

export type TrackerLastInfo = {
  IMEI: Tracker['IMEI'];
  DT: TimeStamp;
  Recieved: TimeStamp;
  Valid: null;
  SatCount: string;
  Latitude: Latitude;
  Longitude: Longitude;
  Speed: string;
  Azimuth: string;
  Odometer: null;
  Type: number;
  TypeChanged: null;
  Created: TimeStamp;
  Changed: TimeStamp;
  CarStatus: number;
};

export type VehicleTracker = {
  ID: number;
  VehicleID: Vehicle['ID'];
  TrackerIMEI: number | string;
  AccountID: Account['ID'];
  From: number | null;
  Till: number | null;
  Priority: number;
  UserID: User['ID'];
  Created: number;
  Changed: number;
};

/** 0 означает, что нет данных или они скрыты */
export type Latitude = number | 0;
/** 0 означает, что нет данных или они скрыты */
export type Longitude = number | 0;

export type VehicleMonitoringTrack = Array<[Latitude, Longitude]> | [];

export type VehicleTrackerMonitoring = VehicleTracker & {
  Tracker?: Tracker;
  TrackerLastInfo?: TrackerLastInfo;
};

export type VehicleUploadFileType = {
  [k in VehicleTableColumn]: string | number | '';
} &
  Record<string, unknown>;

export type VehicleType = {
  ID: number;
  Name: string;
  Alias: string;
  Group: number;
  CouplingMode: number;
  Subject: string;
  Icon: string | null;
  label?: string;
  value?: number;
};

export enum VehicleTableColumn {
  StartDepotID = 'StartDepotID',
  EndDepotID = 'EndDepotID',
  DriverDriverCode = 'Driver.DriverCode',
  EndDepotDepotCode = 'EndDepot.DepotCode',
  IsTrailer = 'IsTrailer',
  Model = 'Model',
  Name = 'Name',
  Priority = 'Priority',
  RegNumber = 'RegNumber',
  Restriction = 'Restriction',
  StartDate = 'StartDate',
  StartDepotDepotCode = 'StartDepot.DepotCode',
  StartTime = 'StartTime',
  TakeForPlanner = 'TakeForPlanner',
  TripTemplate = 'TripTemplate',
  UseMode = 'UseMode',
  VehicleCode = 'VehicleCode',
  VehicleProfileAverageSpeed = 'VehicleProfile.AverageSpeed',
  VehicleProfileFuelConsumption = 'VehicleProfile.FuelConsumption',
  VehicleProfileLoadDuration = 'VehicleProfile.LoadDuration',
  VehicleTimelineMaxTrips = 'VehicleTimeline.MaxTrips',
  VehicleProfileMaxBoxes = 'VehicleProfile.MaxBoxes',
  VehicleProfileMaxCost = 'VehicleProfile.MaxCost',
  VehicleProfileMaxMass = 'VehicleProfile.MaxMass',
  VehicleProfileMaxPallets = 'VehicleProfile.MaxPallets',
  VehicleProfileMaxUnitHeight = 'VehicleProfile.MaxUnitHeight',
  VehicleProfileMaxUnitLength = 'VehicleProfile.MaxUnitLength',
  VehicleProfileMaxUnitWidth = 'VehicleProfile.MaxUnitWidth',
  VehicleProfileMaxVolume = 'VehicleProfile.MaxVolume',
  VehicleProfileName = 'VehicleProfile.Name',
  VehicleProfileUnloadDuration = 'VehicleProfile.UnloadDuration',
  FrontCouplingTypeCouplingCode = 'FrontCouplingType.CouplingCode',
  FrontCouplingTypeName = 'FrontCouplingType.Name',
  RearCouplingTypeCouplingCode = 'RearCouplingType.CouplingCode',
  RearCouplingTypeName = 'RearCouplingType.Name',
  RoadTrain = 'RearCouplingType.Name',
  RouteColor = 'RouteColor',
  fileIndex = 'fileIndex',
  key = 'key'
}
