import { FC, useRef, useState } from 'react';
import { ILSTariffEditorComponent } from './editor';
import { TariffFormComponent } from './tabs';
import { Editor } from '@tinymce/tinymce-react';
import { Dictionary, Key } from '@common/types';
import { ButtonType, ITariffState, Tariff } from '@modules/catalog/types/tariff';
import { CatalogTariffFormulaAndResultContainer } from '@modules/catalog/components/tariff/form';
import { EMPTY_STRING } from '@common/constants';
import { ILSButton, ILSTypographyText } from '@components/index';
import { parseFormula } from '@modules/catalog/helpers';

export interface IILSTariffConstructor {
  tariff?: Tariff;
  dictionary: Dictionary;
  edit?: boolean;
  checking?: boolean | 1 | 0;
  setEdit?(p: boolean): void;

  handleSave(dictionary: Dictionary, name: string, formula: any, id?: Key): void;
}

export const ILSTariffConstructor: FC<IILSTariffConstructor> = ({ tariff, dictionary, checking, edit, setEdit, handleSave }) => {
  const editorRef = useRef<Editor['elementRef'] | null>(null);
  const [parser, setParser] = useState<ButtonType[]>([]);
  const [formulaDesc, setFormulaDesc] = useState('');
  const [newTariff, setNewTariff] = useState<ITariffState>({
    name: tariff?.Name ?? EMPTY_STRING,
    formula: tariff?.Formula ?? EMPTY_STRING,
    parsedFormula: tariff?.Formula ?? EMPTY_STRING
  });
  const [showCheck, setShowCheck] = useState(false);
  const [checkFormula, setCheckFormula] = useState<{
    name: string;
    formula: string;
    showFormula: string;
    modal: boolean;
  } | null>(null);

  const onFinish = ({ name, template }: { name: string; template: boolean }) => {
    const { formula } = newTariff;
    const id = tariff?.ID;
    const payload = parseFormula({ parser, formula });
    let currentDictionary = template ? Dictionary.VehicleTariffPart : dictionary ? dictionary : Dictionary.VehicleTariff;

    handleSave(currentDictionary, name || formula, payload, id);

    if (editorRef?.current) editorRef.current.setContent(EMPTY_STRING);
    setEdit?.(false);
  };

  const onCheck = () => {
    setCheckFormula({
      name: newTariff?.name || 'Новый тариф',
      formula: newTariff.formula,
      showFormula: parseFormula({ parser, formula: newTariff.formula }),
      modal: true
    });
  };

  const insertEditor = (val: string) => {
    const start = editorRef.current.selection.getRng().startContainer;
    const end = editorRef.current.selection.getRng().endContainer;

    if (start.tagName !== 'A' && end.tagName !== 'A' && start.parentElement !== 'a' && end.parentElement !== 'a') {
      editorRef.current.insertContent(val);
      changeNewTariff();
    }
  };

  const changeNewTariff = () => {
    const editorText = editorRef.current
      .getContent({ format: 'text' })
      .replace(/\u00a0/g, EMPTY_STRING)
      .replace(/\u00d7/g, EMPTY_STRING);

    const parsedFormula = parseFormula({ parser, formula: editorText });

    setShowCheck(Boolean(editorText));
    setNewTariff((prev) => ({
      ...prev,
      formula: editorText,
      parsedFormula
    }));
  };

  return (
    <div className="tariff">
      <div className="tariff-editor-zone">
        <TariffFormComponent
          showCheck={showCheck}
          edit={Boolean(edit)}
          defaultName={newTariff.name}
          tariff={tariff}
          onFinish={onFinish}
          setParser={setParser}
          insertEditor={insertEditor}
          setFormulaDesc={setFormulaDesc}
        />
        <ILSTariffEditorComponent
          editorRef={editorRef}
          tariff={tariff}
          insertEditor={insertEditor}
          changeNewTariff={changeNewTariff}
          setShowCheck={setShowCheck}
        />
      </div>
      <div className="tariff-desc">
        {formulaDesc && (
          <ILSTypographyText className={'tariff-desc-text'} title={formulaDesc} ellipsis>
            Описание: {formulaDesc}
          </ILSTypographyText>
        )}
        {showCheck && (
          <div className={'tariff-desc-btns'}>
            <ILSButton children={'Проверить'} type="ghost" onClick={onCheck} />
            <ILSButton children={'Сохранить'} form={'tariff'} className="create-btn" type="primary" htmlType={'submit'} />
          </div>
        )}
      </div>
      {checkFormula && (!tariff || edit) && (checking || !tariff) && (
        <CatalogTariffFormulaAndResultContainer
          modal={checkFormula.modal}
          name={checkFormula.name}
          showFormula={checkFormula.showFormula}
          setCheckFormula={setCheckFormula}
        />
      )}
    </div>
  );
};

