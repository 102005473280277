import { ILSModal } from '@components/index';
import { FC, MouseEvent, useState } from 'react';
import { IILSCellModalProps } from '@common/types/components/table/cell';

export const ILSCellModal: FC<IILSCellModalProps> = ({ title, content, setShow }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  const handleOk = (event: MouseEvent) => {
    event.stopPropagation();
    setIsModalVisible(false);
    setShow?.(false);
  };

  return (
    <ILSModal
      key={`cell-modal_${title}`}
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      okText="Ок"
      className="cell-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {content}
    </ILSModal>
  );
};
