import { createArrayFromErrorObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { DEFAULT_APP_ERROR_MESSAGE } from '@common/constants/general/errors';
import { ErrorAPI } from '@common/types';
import { ErrorAsObject } from '@common/types/general/api/api';
import { JoinChar } from '@common/constants';

export const getErrorMessage = ({ error }: ErrorAPI): string => {
  switch (typeof error) {
    case 'object':
      return createArrayFromErrorObject(error as Array<ErrorAsObject>).join(JoinChar.Semicolon);
    case 'string':
    default:
      return error ?? DEFAULT_APP_ERROR_MESSAGE;
  }
};
