import reduceReducers from 'reduce-reducers';
import AddTrackToQueryReducer from '@modules/track/reducers/add-track-to-query';
import getProject from '@modules/track/reducers/get-project';
import getPlan from '@modules/track/reducers/get-plan';
import getTrack from '@modules/track/reducers/get-track';
import initialState from '@modules/track/store';
import { trackGetDepotsReducer } from '@modules/track/reducers/get-depots';

const trackReducer = reduceReducers(initialState, getPlan, getProject, AddTrackToQueryReducer, getTrack, trackGetDepotsReducer);

export default trackReducer;
