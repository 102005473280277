import { Timeline } from 'antd';
import { TimeLineItemProps, TimeLineProps } from './types';
import { FC } from 'react';

export const ILSTimeline: FC<TimeLineProps> = (props) => {
  return <Timeline {...props} />;
};

export const ILSTimelineItem: FC<TimeLineItemProps> = (props) => {
  return <Timeline.Item {...props} />;
};
