import { mobileApplicationChangeFocusRowRoutine, mobileApplicationDictionaryOperationRoutine } from '@modules/settings/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MobileApplicationAPI } from '@common/api/common';
import { MobileApplicationDictionarySagaPayload } from '@common/types/dictionaries/mobile-application';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { settingsForwarderPointStatusesTableGroupDataSourceSelector as StatusesGroupSelector } from '@modules/settings/selectors/components/settings-forwarder-point-statuses-group-table';
import { settingsForwarderPointStatusesTableDataSourceSelector as StatusesSelector } from '@modules/settings/selectors/components/settings-forwarder-point-statuses-table';
import { getPrevRowID } from '@modules/settings/utils/helpers/get-prev-row-id';
import { compareAsString } from '@common/utils';

export function* mobileApplicationDictionaryOperationWorker(action: MobileApplicationDictionarySagaPayload) {
  const { request, success, failure, fulfill } = mobileApplicationDictionaryOperationRoutine;
  const { mobileApplicationDictionary, ids, operation, values } = action.payload;

  try {
    yield put(request());

    /** Если удаляем строку, сохраняем ID предыдущей строки */
    let prevRowID;
    if (compareAsString(operation, DictionariesEndpoint.Delete)) {
      const selector = compareAsString(mobileApplicationDictionary, Dictionary.ForwarderPointStatuses)
        ? StatusesSelector
        : StatusesGroupSelector;
      const settings: Array<{ ID?: number }> = yield select(selector);
      prevRowID = getPrevRowID({ dataSource: settings, deletedRowID: ids?.[0] });
    }

    const {
      data: { data }
    } = yield call(MobileApplicationAPI.mobileApplicationDictionaryOperation, {
      mobileApplicationDictionary,
      ids,
      operation,
      values
    });
    yield put(success({ mobileApplicationDictionary, data, operation, ids }));

    const focusRowID = compareAsString(operation, DictionariesEndpoint.Delete) ? prevRowID : Object.keys(data)?.[0];
    switch (operation) {
      case DictionariesEndpoint.Index:
      case DictionariesEndpoint.Create:
      case DictionariesEndpoint.Delete:
      case DictionariesEndpoint.Update:
        yield put(
          mobileApplicationChangeFocusRowRoutine.success({
            dictionary: mobileApplicationDictionary,
            focusRowID: focusRowID ?? undefined
          })
        );
        break;
      default:
        break;
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    if (operation === DictionariesEndpoint.Index) {
      switch (mobileApplicationDictionary) {
        case Dictionary.ForwarderPointStatuses:
          yield put(
            mobileApplicationDictionaryOperationRoutine.trigger({
              mobileApplicationDictionary,
              operation: DictionariesEndpoint.IconURLIndex
            })
          );
          break;
        case Dictionary.ForwarderPointStatusesGroup:
          yield put(
            mobileApplicationDictionaryOperationRoutine.trigger({
              mobileApplicationDictionary: Dictionary.ForwarderPointStatuses,
              operation: DictionariesEndpoint.Index
            })
          );
          break;
        default:
          break;
      }
    }
    yield put(fulfill());
  }
}

export function* mobileApplicationDictionaryOperationWatcher() {
  yield takeLatest(mobileApplicationDictionaryOperationRoutine.trigger, mobileApplicationDictionaryOperationWorker);
}

