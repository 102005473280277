import { useHistory } from 'react-router';

/**
 * Return the search string param from link
 * @param {string} searchParameter - Which parameters you need to.
 */
export const useSearchParams = (searchParameter: string) => {
  const {
    location: { search }
  } = useHistory();
  return new URLSearchParams(search).get(searchParameter);
};
