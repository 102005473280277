import reduceReducers from 'reduce-reducers';
import initialState from '../store';
import emulatorGetProjectsReducer from './get-projects';
import emulatorGetPlanReducer from './get-plan';
import emulatorStartReducer from './start-emulation';
import emulatorStopReducer from './stop-emulation';
import emulatorChangeEmulationSpeedReducer from './change-emulation-speed';
import emulatorCheckStatusReducer from './check-status';
import emulatorPauseReducer from './pause-emulation';
import emulatorCreateConfigReducer from './create-config';
import emulatorUpdateConfigReducer from './update-config';
import emulatorGetConfigReducer from './get-config';
import { emulatorSaveVehiclePropsReducer } from './update-vehicle-props';

const emulatorReducer = reduceReducers(
  initialState,
  emulatorGetProjectsReducer,
  emulatorGetPlanReducer,
  emulatorStartReducer,
  emulatorStopReducer,
  emulatorPauseReducer,
  emulatorChangeEmulationSpeedReducer,
  emulatorCheckStatusReducer,
  emulatorCreateConfigReducer,
  emulatorUpdateConfigReducer,
  emulatorGetConfigReducer,
  emulatorSaveVehiclePropsReducer
);

export default emulatorReducer;
