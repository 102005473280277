import { DayFraction } from '@common/types/general/date-time';
import { Restriction } from './restriction';

export type Cargo = {
  ID: number;
  CargoCode: string;
  Barcode: string | null;
  Name: string | null;
  Mass: number | null;
  Volume: number | null;
  Pallets: number | null;
  Boxes: number | null;
  UnitLength: number | null;
  UnitWidth: number | null;
  UnitHeight: number | null;
  Cost: number | null;
  Priority: number | null;
  LoadDuration: DayFraction | null;
  UnloadDuration: DayFraction | null;
  Description: string | null;
  Currency: string | null;
  MarkerColor: number | null;
  IsWeight: CargoIsWeight | null;
  Info: JSON | null;
  RestrictionID?: Array<Restriction['ID']>;
  Deleted?: number | null;
};

export enum CargoIsWeight {
  No,
  Yes
}
