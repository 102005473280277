import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { IndexedArray } from '@common/types';
import { useMemo } from 'react';
import { getDeletedWidgets } from '@modules/analytics/common/utils/get-deleted-widgets';

export const useVisibleWidgets = (widgets: IndexedArray<AnalyticsWidget> | null) => {
  return useMemo(() => {
    return getDeletedWidgets(widgets || {}, false);
  }, [widgets]);
};
