import { call, put, takeLatest } from 'redux-saga/effects';
import { DataSchemeAPI } from '@modules/planning/children/scheme-editor/api';
import { getMaxSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';

function* getMaxSchemeWorker() {
  const { request, success, failure, fulfill } = getMaxSchemeRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(DataSchemeAPI.getMaxScheme);

    yield put(success({ scheme: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getMaxSchemeWatcher() {
  yield takeLatest(getMaxSchemeRoutine.trigger, getMaxSchemeWorker);
}
