import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/account/store';
import { accountRoutine, organizationTypesRoutine } from '@modules/account/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { IEnum } from '@common/types';

export const organizationTypesReducer = createReducer(initialState, {
  [organizationTypesRoutine.REQUEST]: onRequest,
  [organizationTypesRoutine.SUCCESS]: (
    state: typeof initialState,
    action: PayloadAction<{
      data: IEnum<string>;
    }>
  ) => {
    const { data } = action.payload;
    state.organizationTypes = data;
    return state;
  },
  [accountRoutine.FAILURE]: onFailure,
  [accountRoutine.FULFILL]: onFulfill
});
