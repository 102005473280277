/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* Geohash encoding/decoding and associated functions   (c) Chris Veness 2014-2016 / MIT Licence  */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

import {
  GeoCoordType,
  GeohashType,
  hashTypeString,
  IGeohash,
  IPoint,
  Latitude,
  LatLngObject,
  Longitude,
  PrecisionType
} from '@common/types/general/geohash';

/**
 * Geohash encode, decode, bounds, neighbours.
 *
 * @namespace
 */

// Кодируем весь мир:
//Geohash.minmax = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
//Geohash.precision = 10; // для всего мира 10 символов дают точно метр - нам более чем достаточно

// Кодируем только РФ
//https://ru.wikipedia.org/wiki/%D0%9A%D1%80%D0%B0%D0%B9%D0%BD%D0%B8%D0%B5_%D1%82%D0%BE%D1%87%D0%BA%D0%B8_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8
//Координаты РФ Включая острова и эксклавы
//		Северная точка — окрестности мыса Флигели, Земля Франца-Иосифа, Архангельская область[1]
//		81°50′35″ с. ш. 59°14′22″ в. д.HGЯO
//		Южная точка — не именованная на картах точка с высотой свыше 3500 м расположена в 2,2 км к востоку от горы Рагдан и к юго-западу от гор Несен (3,7 км) и Базардюзю (7,3 км), Дагестан[2]
//		41°11′07″ с. ш. 47°46′54″
//		Западная точка — погранзастава Нормельн[3], Балтийская коса, Калининградская область[4]
//		54°27′45″ с. ш. 19°38′19″ в. д.HGЯO
//		Восточная точка — остров Ратманова, Чукотский автономный округ[5]
//		65°47′ с. ш. 169°01′ з. д.HGЯO
// Geohash.minmax['GR'] = [{lat: 40, lon: 20}, {lat: 80, lon: 160}];
// Geohash.precision['GR'] = 8; // Экспериментальным путем выяснили, что для региона РФ точности в 8 символов - достаточно
// Geohash.minmax['GW'] = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
// Geohash.precision['GW'] = 12;
// Geohash.minmax['SL'] = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
// Geohash.precision['SL'] = 12;
// Geohash.minmax['TZ'] = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
// Geohash.precision['TZ'] = 12;
// Geohash.minmax['TL'] = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
// Geohash.precision['TL'] = 12;
// Geohash.minmax['PA'] = [{lat: -90, lon: -180}, {lat: 90, lon: 180}];
// Geohash.precision['PA'] = 12;

let Geohash: IGeohash = {
  minmax: {
    GR: [
      { lat: 40, lon: 20 },
      { lat: 80, lon: 160 }
    ],
    GW: [
      { lat: -90, lon: -180 },
      { lat: 90, lon: 180 }
    ],
    SL: [
      { lat: -90, lon: -180 },
      { lat: 90, lon: 180 }
    ],
    TZ: [
      { lat: -90, lon: -180 },
      { lat: 90, lon: 180 }
    ],
    TL: [
      { lat: -90, lon: -180 },
      { lat: 90, lon: 180 }
    ],
    PA: [
      { lat: -90, lon: -180 },
      { lat: 90, lon: 180 }
    ]
  },
  precision: {
    GR: 8,
    GW: 12,
    SL: 12,
    TZ: 12,
    TL: 12,
    PA: 12
  },
  base32: '0123456789bcdefghjkmnpqrstuvwxyz'
};
/**
 * Encodes latitude/longitude to geohash, either to specified precision or to automatically
 * evaluated precision.
 *
 * @param   {number} lat - Latitude in degrees.
 * @param   {number} lon - Longitude in degrees.
 * @param   {number} [precision] - Number of characters in resulting geohash.
 * @param minmax
 * @param hashType
 * @param minmax
 * @param hashType
 * @returns {string} Geohash of supplied latitude/longitude.
 * @throws  Invalid geohash.
 *
 * @example
 *     let geohash = GeohashEncode(52.205, 0.119, 7); // geohash: 'u120fxw'
 */
export const GeohashEncode = (
  lat: Latitude,
  lon: Longitude,
  precision: PrecisionType | undefined,
  minmax: Array<IPoint> | undefined,
  hashType: hashTypeString | undefined
): GeohashType => {
  if (typeof hashType === 'undefined') {
    minmax = Geohash.minmax['GR'];
  } else {
    minmax = Geohash.minmax[hashType];
  }
  // infer precision?
  if (typeof hashType === 'undefined') {
    precision = Geohash.precision['GR']; // для

    // // refine geohash until it matches precision of supplied lat/lon
    // for (let p=1; p<=12; p++) {
    //     let hash = GeohashEncode(lat, lon, p);
    //     let posn = GeohashDecode(hash);
    //     if (posn.lat===lat && posn.lon===lon) return hash;
    // }
    // precision = 12; // set to maximum
  } else {
    precision = Geohash.precision[hashType];
  }

  lat = Number(lat);
  lon = Number(lon);
  precision = Number(precision);

  if (isNaN(lat) || isNaN(lon) || isNaN(precision)) {
    throw new Error('Invalid geohash');
  }

  let idx: number = 0; // index into base32 map
  let bit: number = 0; // each char holds 5 bits
  let evenBit: boolean = true;
  let geohash: GeohashType = '';

  let latMin = minmax[0].lat,
    latMax = minmax[1].lat;
  let lonMin = minmax[0].lon,
    lonMax = minmax[1].lon;

  while (geohash.length < precision) {
    if (evenBit) {
      // bisect E-W longitude
      let lonMid = (lonMin + lonMax) / 2;
      if (lon >= lonMid) {
        idx = idx * 2 + 1;
        lonMin = lonMid;
      } else {
        idx = idx * 2;
        lonMax = lonMid;
      }
    } else {
      // bisect N-S latitude
      let latMid = (latMin + latMax) / 2;
      if (lat >= latMid) {
        idx = idx * 2 + 1;
        latMin = latMid;
      } else {
        idx = idx * 2;
        latMax = latMid;
      }
    }
    evenBit = !evenBit;

    if (++bit === 5) {
      // 5 bits gives us a character: append it and start over
      geohash += Geohash.base32.charAt(idx);
      bit = 0;
      idx = 0;
    }
  }

  return geohash;
};

export const defaultGeohashEncode = (coords:[number, number]) => GeohashEncode(Number(coords[0]), Number(coords[1]), undefined, undefined, 'GW')

/// Набор гео-точек вычисляется такми образом:
///  - GR два символа префиска Geohasg Russia
///  - далее через каждые Geohash.precision символов - координаты
export function GeohashEncodePoints(points: Array<Array<number>>, hashType?: hashTypeString) {
  if (typeof hashType === 'undefined') hashType = 'GW';
  let res = hashType;
  for (let i = 0; i < points.length; i++) {
    res += GeohashEncode(points[i][0], points[i][1], undefined, undefined, hashType);
  }
  return res;
}

/** Набор гео-точек вычисляется таки образом:
- GR два символа префиска Geohash Russia
- далее через каждые Geohash.precision символов координата точки */
export function GeohashDecodePoints(str: string): Array<GeoCoordType> {
  let res: Array<GeoCoordType> | null;
  try {
    res = [];
    let hashType: hashTypeString = str.substring(0, 2) as hashTypeString;
    if (!Object.keys(Geohash.minmax).includes(str.substring(0, 2))) {
      return res;
    }
    if (Geohash.minmax[hashType] === undefined) return res;
    if (hashType === 'SL') {
      for (let i = 2; i < str.length; i += Geohash.precision[hashType] + 3) {
        let p = GeohashDecode(str.substr(i, Geohash.precision[hashType]), hashType);
        if (
          p.lat >= Geohash.minmax[hashType][0].lat + 1 &&
          p.lat <= Geohash.minmax[hashType][1].lat - 1 &&
          p.lng >= Geohash.minmax[hashType][0].lon + 1 &&
          p.lng <= Geohash.minmax[hashType][1].lon + 1
        ) {
          res.push([p.lat, p.lng]);
        }
      }
    } else if (hashType === 'TZ') {
      for (let j = 2; j < str.length; j += Geohash.precision[hashType] + 18) {
        let pt = GeohashDecode(str.substr(j, Geohash.precision[hashType]), hashType);
        if (
          pt.lat >= Geohash.minmax[hashType][0].lat + 1 &&
          pt.lat <= Geohash.minmax[hashType][1].lat - 1 &&
          pt.lng >= Geohash.minmax[hashType][0].lon + 1 &&
          pt.lng <= Geohash.minmax[hashType][1].lon + 1
        ) {
          res.push([pt.lat, pt.lng]);
        }
      }
    } else if (hashType === 'TL') {
      for (let k = 2; k < str.length; k += Geohash.precision[hashType] + 2) {
        let pl = GeohashDecode(str.substr(k, Geohash.precision[hashType]), hashType);
        if (
          pl.lat >= Geohash.minmax[hashType][0].lat + 1 &&
          pl.lat <= Geohash.minmax[hashType][1].lat - 1 &&
          pl.lng >= Geohash.minmax[hashType][0].lon + 1 &&
          pl.lng <= Geohash.minmax[hashType][1].lon + 1
        ) {
          res.push([pl.lat, pl.lng]);
        }
      }
    } else {
      for (let l = 2; l < str.length; l += Geohash.precision[hashType]) {
        let po = GeohashDecode(str.substr(l, Geohash.precision[hashType]), hashType);
        if (
          po.lat >= Geohash.minmax[hashType][0].lat + 1 &&
          po.lat <= Geohash.minmax[hashType][1].lat - 1 &&
          po.lng >= Geohash.minmax[hashType][0].lon + 1 &&
          po.lng <= Geohash.minmax[hashType][1].lon + 1
        ) {
          res.push([po.lat, po.lng]);
        }
      }
    }
  } catch {
    res = [];
  }
  return res;
}

/**
 * Decode geohash to latitude/longitude (location is approximate centre of geohash cell,
 *     to reasonable precision).
 *
 * @param   {string} geohash - Geohash string to be converted to latitude/longitude.
 * @param hashType
 * @returns {{lat:number, lon:number}} (Center of) geohashed location.
 * @throws  Invalid geohash.
 *
 * @example
 *     let latlon = GeohashDecode('u120fxw'); // latlon: { lat: 52.205, lon: 0.1188 }
 */
export function GeohashDecode(geohash: GeohashType, hashType?: hashTypeString): LatLngObject {
  let minmax;
  if (typeof hashType === 'undefined') {
    minmax = Geohash.minmax['GR'];
  } else {
    minmax = Geohash.minmax[hashType];
  }

  let bounds = GeohashBounds(geohash, minmax); // <-- the hard work
  // now just determine the centre of the cell...

  let latMin = bounds.sw.lat,
    lonMin = bounds.sw.lon;
  let latMax = bounds.ne.lat,
    lonMax = bounds.ne.lon;

  // cell centre
  let lat = (latMin + latMax) / 2;
  let lon = (lonMin + lonMax) / 2;

  // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
  const latFixed = Math.floor(2 - Math.log(latMax - latMin) / Math.LN10);
  const lonFixed = Math.floor(2 - Math.log(lonMax - lonMin) / Math.LN10);
  lat = Number(lat.toFixed(latFixed > 0 && latFixed < 100 ? latFixed : 1));
  lon = Number(lon.toFixed(lonFixed > 0 && lonFixed < 100 ? lonFixed : 1));

  return { lat: lat, lng: lon };
}

/**
 * Returns SW/NE latitude/longitude bounds of specified geohash.
 *
 * @param   {string} geohash - Cell that bounds are required of.
 * @param minmax
 * @returns {{sw: {lat: number, lon: number}, ne: {lat: number, lon: number}}}
 * @throws  Invalid geohash.
 */
export function GeohashBounds(geohash: GeohashType, minmax: IPoint[]): { sw: IPoint; ne: IPoint } {
  if (typeof minmax === 'undefined') minmax = Geohash.minmax['GR'];
  if (geohash.length === 0) throw new Error('Invalid geohash');

  geohash = geohash.toLowerCase();

  let evenBit = true;
  let latMin = minmax[0].lat,
    latMax = minmax[1].lat;
  let lonMin = minmax[0].lon,
    lonMax = minmax[1].lon;

  for (let i = 0; i < geohash.length; i++) {
    let chr = geohash.charAt(i);
    let idx = Geohash.base32.indexOf(chr);
    if (idx === -1) throw new Error('Invalid geohash');

    for (let n = 4; n >= 0; n--) {
      let bitN = (idx >> n) & 1;
      if (evenBit) {
        // longitude
        let lonMid = (lonMin + lonMax) / 2;
        if (bitN === 1) {
          lonMin = lonMid;
        } else {
          lonMax = lonMid;
        }
      } else {
        // latitude
        let latMid = (latMin + latMax) / 2;
        if (bitN === 1) {
          latMin = latMid;
        } else {
          latMax = latMid;
        }
      }
      evenBit = !evenBit;
    }
  }

  return {
    sw: { lat: latMin, lon: lonMin },
    ne: { lat: latMax, lon: lonMax }
  };
}

type direction = 'N' | 'S' | 'E' | 'W' | 'n' | 's' | 'e' | 'w';
type directionLow = 'n' | 's' | 'e' | 'w';

/**
 * Determines adjacent cell in given direction.
 *
 * @param   geohash - Cell to which adjacent cell is required.
 * @param   direction - Direction from geohash (N/S/E/W).
 * @returns {string} Geocode of adjacent cell.
 * @throws  Invalid geohash.
 */
export function GeohashAdjacent(geohash: GeohashType, direction: direction): string {
  // based on github.com/davetroy/geohash-js

  geohash = geohash.toLowerCase();
  direction = direction.toLowerCase() as directionLow;

  if (geohash.length === 0) throw new Error('Invalid geohash');
  if ('nsew'.indexOf(direction) === -1) throw new Error('Invalid direction');

  let neighbour = {
    n: ['p0r21436x8zb9dcf5h7kjnmqesgutwvy', 'bc01fg45238967deuvhjyznpkmstqrwx'],
    s: ['14365h7k9dcfesgujnmqp0r2twvyx8zb', '238967debc01fg45kmstqrwxuvhjyznp'],
    e: ['bc01fg45238967deuvhjyznpkmstqrwx', 'p0r21436x8zb9dcf5h7kjnmqesgutwvy'],
    w: ['238967debc01fg45kmstqrwxuvhjyznp', '14365h7k9dcfesgujnmqp0r2twvyx8zb']
  };
  let border = {
    n: ['prxz', 'bcfguvyz'],
    s: ['028b', '0145hjnp'],
    e: ['bcfguvyz', 'prxz'],
    w: ['0145hjnp', '028b']
  };

  let lastCh = geohash.slice(-1); // last character of hash
  let parent = geohash.slice(0, -1); // hash without last character

  let type = geohash.length % 2;

  // check for edge-cases which don't share general prefix
  if (border[direction][type].indexOf(lastCh) !== -1 && parent !== '') {
    parent = GeohashAdjacent(parent, direction);
  }

  // append letter for direction to parent
  return parent + Geohash.base32.charAt(neighbour[direction][type].indexOf(lastCh));
}

/**
 * Returns all 8 adjacent cells to specified geohash.
 *
 * @param   {string} geohash - Geohash neighbours are required of.
 * @returns {{n,ne,e,se,s,sw,w,nw: string}}
 * @throws  Invalid geohash.
 */
export function GeohashNeighbours(geohash: GeohashType) {
  return {
    n: GeohashAdjacent(geohash, 'n'),
    ne: GeohashAdjacent(GeohashAdjacent(geohash, 'n'), 'e'),
    e: GeohashAdjacent(geohash, 'e'),
    se: GeohashAdjacent(GeohashAdjacent(geohash, 's'), 'e'),
    s: GeohashAdjacent(geohash, 's'),
    sw: GeohashAdjacent(GeohashAdjacent(geohash, 's'), 'w'),
    w: GeohashAdjacent(geohash, 'w'),
    nw: GeohashAdjacent(GeohashAdjacent(geohash, 'n'), 'w')
  };
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
// if (typeof module != 'undefined' && module.exports) module.exports = Geohash // CommonJS, node.js
