import { IActionButtonProps } from '@modules/orders-and-trips/types/general';
import { FC, memo } from 'react';

interface IProps {
  orderActionButtons: {
    Component: React.FC<IActionButtonProps>;
    args: {
      text: string;
      click(): void;
      icon: JSX.Element;
      disabled?: boolean;
      tooltip?: boolean;
      title?: string;
    };
  }[];
}

export const OrderActionButtons: FC<IProps> = memo(({ orderActionButtons }) => {
  return (
    <div className={'orders-action-buttons flex-horizontal'}>
      {orderActionButtons.map(({ Component, args }) => {
        return <Component {...args} />;
      })}
    </div>
  );
});
