import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { generateUUID } from '@common/utils/general/uuid';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const NEW_OVERLOAD_TABLE_CONFIG = {
  Name: 'Новый профиль',
  VehicleOverloadCode: generateUUID()
};

export const VEHICLE_OVERLOAD_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_OVERLOAD_TABLE_CONFIG,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: FilterCellType.String,
      sorting: 2
    },
    {
      title: 'Клиенты, шт',
      dataIndex: 'MaxClientsOverload',
      key: 'MaxClientsOverload',
      editable: true,
      active: true,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Заказы, шт',
      dataIndex: 'MaxOrdersOverload',
      key: 'MaxOrdersOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Время рейса',
      dataIndex: 'MaxDurationOverload',
      key: 'MaxDurationOverload',
      editable: true,
      active: false,
      type: CellType.TimeDouble,
      align: 'center',
      ellipsis: true,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Пробег рейса, км',
      dataIndex: 'MaxDistanceOverload',
      key: 'MaxDistanceOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,

      sorting: 5,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Время маршрута',
      dataIndex: 'MaxDurationRouteOverload',
      key: 'MaxDurationRouteOverload',
      editable: true,
      active: false,
      type: CellType.TimeDouble,
      align: 'center',
      ellipsis: true,
      sorting: 7,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Пробег маршрута, км',
      dataIndex: 'MaxDistanceRouteOverload',
      key: 'MaxDistanceRouteOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,

      sorting: 8,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Рейсы, шт',
      dataIndex: 'MaxTripsOverload',
      key: 'MaxTripsOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 9,

      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Прев. выезда',
      dataIndex: 'MinStartTimeOverload',
      key: 'MinStartTimeOverload',
      editable: true,
      active: false,
      type: CellType.TimeDouble,
      align: 'center',
      ellipsis: true,
      sorting: 10
    },
    {
      title: 'Вес, кг',
      dataIndex: 'MaxMassOverload',
      key: 'MaxMassOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Объём, м3',
      dataIndex: 'MaxVolumeOverload',
      key: 'MaxVolumeOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Паллет, шт',
      dataIndex: 'MaxPalletsOverload',
      key: 'MaxPalletsOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Коробок, шт',
      dataIndex: 'MaxBoxesOverload',
      key: 'MaxBoxesOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Стоимость, руб',
      dataIndex: 'MaxCostOverload',
      key: 'MaxCostOverload',
      editable: true,
      active: false,
      type: CellType.Float,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Длина, мм',
      dataIndex: 'MaxUnitLengthOverload',
      key: 'MaxUnitLengthOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      sorting: 4,
      ellipsis: true,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Ширина, мм',
      dataIndex: 'MaxUnitWidthOverload',
      key: 'MaxUnitWidthOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Высота, мм',
      dataIndex: 'MaxUnitHeightOverload',
      key: 'MaxUnitHeightOverload',
      editable: true,
      active: false,
      type: CellType.Int,
      align: 'center',
      ellipsis: true,
      sorting: 4,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    }
  ]
};
