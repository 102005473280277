import { defineModule } from '@core/index';
import { ModulePath, ModuleTitle } from '@common/types';
import { ILSPlannerNew } from '@modules/planning/children/new/containers';
import { plannerRules } from '@modules/planning/abac/rules';
import { plannerPermissions } from '@modules/planning/abac/permissions';

export default defineModule({
  title: ModuleTitle.New,
  path: ModulePath.New,
  component: ILSPlannerNew,
  access: {
    rules: plannerRules,
    permissions: plannerPermissions
  },
  permission: [plannerPermissions.PLANNER_NEW]
});
