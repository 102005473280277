import ILSRoadGraphTestSetMarkerButton from '@modules/road-graph/route-test/components/buttons/set-makers/marker-btn';
import { useContext } from 'react';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';

const ILSRoadGraphTestSetMarkersButtons = () => {
  const { trackMarker, setTrackMarker } = useContext(RoadGraphContext) ?? {};
  const handleClick = (title: string) => {
    if (!setTrackMarker || !title || !trackMarker) return;
    setTrackMarker({
      ...trackMarker,
      [title]: {
        coords: undefined,
        moving: !trackMarker[title].moving
      }
    });
  };
  return (
    <>
      <ILSRoadGraphTestSetMarkerButton title={'A'} data={trackMarker?.A} handleClick={handleClick} />
      <ILSRoadGraphTestSetMarkerButton title={'B'} data={trackMarker?.B} handleClick={handleClick} />
    </>
  );
};
export default ILSRoadGraphTestSetMarkersButtons;
