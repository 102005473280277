import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { RouteGraphStore } from '../types';
import { roadGraphCatalogReadRoutine } from '../actions';
import { Dictionary } from '@common/types';

const catalogRead = createReducer(initialState, {
  [roadGraphCatalogReadRoutine.REQUEST]: (
    state: RouteGraphStore,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;
    if (state.catalog.Info) {
      state.catalog.Info[dictionary] = { isFetching: true, lastUpdate: null };
    }
    state.isFetching = true;
    return state;
  },
  [roadGraphCatalogReadRoutine.SUCCESS]: (
    state: RouteGraphStore,
    action: PayloadAction<{
      data: any;
      dictionary: Dictionary;
      keys: (number | string)[];
      lastUpdate: number | null | undefined;
    }>
  ) => {
    const { data, dictionary, lastUpdate } = action.payload;
    state.isFetching = false;
    if (data) {
      state.catalog[dictionary] = data;
      if (state.catalog.Info) {
        state.catalog.Info[dictionary] = { isFetching: true, lastUpdate: null };
      }
    }
    return state;
  },
  [roadGraphCatalogReadRoutine.FAILURE]: (
    state: RouteGraphStore,
    action: PayloadAction<{
      error: string[];
      dictionary: Dictionary;
      lastUpdate: number | null | undefined;
    }>
  ) => {
    const { error, dictionary, lastUpdate } = action.payload;
    state.isFetching = false;
    state.error = error;
    if (state.catalog.Info) {
      state.catalog.Info[dictionary].lastUpdate = lastUpdate;
      state.catalog.Info[dictionary].isFetching = false;
    }
    return state;
  },
  [roadGraphCatalogReadRoutine.FULFILL]: (state: RouteGraphStore) => {
    state.error = [];
    return state;
  }
});

export default catalogRead;
