import { ILSButton, ILSUserConfirm } from '@common/components';
import { IndexedArray } from '@common/types';
import { AnalyticsBoardModel, AnalyticsBoardSetModel, CreateBoardParams, CreateBoardSetParams } from '@common/types/dictionaries/analytics';
import React, { FC, MutableRefObject, useEffect, useMemo, useState } from 'react';
import { EditableNameState } from '../../types/components/board-sets';
import ILSAnalyticsEditableListComponent from './list';
import classNames from 'classnames';
import AnalyticsListNewItem from './new-item';
import { CONFIRM_DELETE_CAPTION } from '@modules/analytics/common/constants';

type Props = {
  editable: boolean;
  adding: boolean | undefined;
  handleEditItem: (value: boolean) => void;
  listRef: MutableRefObject<HTMLDivElement | null>;
  listData?: IndexedArray<AnalyticsBoardSetModel | AnalyticsBoardModel>;
  createItem: (item?: CreateBoardSetParams | CreateBoardParams) => void;
  updatingItem: number | string | undefined; // ID текущего обновляемого элемента листа
  newItemAddTitle: string; // Текст для строки листа (добавлени нового элемента)
  defaultItemTitle?: string; // Текст по умолчанию для элементов листа
  listTitle: string; // Заголов для листа
  //Текст для кнопок листа добавления нового элемента (наборов / досок)
  newItemButtons: {
    copyText: string;
    newItemText: string;
  };
  copyItemListTitle: string; // Title для списка существующих элементов

  updateItem: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
  copyItem: (id: number) => void;
  deleteItem: (id: string | number) => void;
  sortListItems: (list: (AnalyticsBoardSetModel | AnalyticsBoardModel)[], itemID: number | string, idx: number) => void;
  disableItem: (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => void;
};

const initialEditNameState = {
  id: null,
  editable: false
};

const ILSAnalyticsEditableListWindowComponent: FC<Props> = ({
  handleEditItem,
  listRef,
  listData,
  createItem,
  updatingItem,
  adding,
  newItemAddTitle,
  defaultItemTitle,
  listTitle,
  newItemButtons,
  updateItem,
  copyItem,
  sortListItems,
  disableItem,
  deleteItem,
  copyItemListTitle
}) => {
  const handleCancel = () => !confirming && handleEditItem(false);
  const [newItem, setNewItem] = useState(false);
  const [isCopySet, setIsCopySet] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [editName, setEditName] = useState<EditableNameState>(initialEditNameState);

  const windowSettings = useMemo(() => {
    return {
      width: listRef?.current?.offsetWidth,
      top: listRef?.current?.offsetTop,
      left: listRef?.current?.offsetLeft
    };
  }, []);

  const sortedListData = useMemo(() => {
    return listData ? Object.values(listData).sort((a, b) => a.Serial - b.Serial) : [];
  }, [listData]);

  const handleEditName = (values: { id: number | string | null; editable: boolean }) => {
    setEditName(values);
  };

  const handleUpdateBoardSet = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    updateItem(item);
  };

  const copyBoardSet = (id: number) => {
    copyItem(id);
  };

  const handleUpdateByPenClick = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    item.Name?.length && handleUpdateBoardSet(item);
    setEditName(initialEditNameState);
  };

  const handleDeleteItem = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    setConfirming(true);
    ILSUserConfirm({
      text: `Вы уверены, что хотите удалить "${item.Name}"?`,
      onConfirm: (values) => {
        if (values) {
          deleteItem(item.ID);
        }
      },
      confirmParams: {
        done: true
      },
      title: CONFIRM_DELETE_CAPTION,
      onClose: () => {
        setConfirming(false);
      }
    });
  };

  const handleDisableBoardSet = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    disableItem(item);
  };

  const sortBoardSetsList = (list: (AnalyticsBoardSetModel | AnalyticsBoardModel)[], itemID: number | string, idx: number) => {
    sortListItems(list, itemID, idx);
  };

  useEffect(() => {
    if (!newItem) {
      setIsCopySet(false);
    }
  }, [newItem]);

  return (
    <div className="analytics-sets-board-edit-root">
      <div className={classNames('analytics-sets-board-edit-offset')} onClick={handleCancel} />
      <div
        className={classNames('analytics-sets-board-edit-dialog', { disabled: confirming })}
        style={{
          position: 'absolute',
          right: windowSettings.left && ((windowSettings.left - 20) / (window.innerWidth * 100)).toFixed(2) + '%',
          top: 0
        }}
      >
        <div className="board sets analytics-scroll">
          <ILSAnalyticsEditableListComponent
            handleEditName={handleEditName}
            handleUpdateByPenClick={handleUpdateByPenClick}
            handleDisableItem={handleDisableBoardSet}
            handleUpdateItem={handleUpdateBoardSet}
            handleDeleteItem={handleDeleteItem}
            sortItemList={sortBoardSetsList}
            editableName={editName}
            listData={sortedListData}
            handleCancel={handleCancel}
            setNewItem={setNewItem}
            newItem={newItem}
            updatingItem={updatingItem}
            newItemAddTitle={newItemAddTitle}
            defaultItemTitle={defaultItemTitle}
            listTitle={listTitle}
            adding={adding}
          />
        </div>

        {newItem && (
          <AnalyticsListNewItem newItemButtons={newItemButtons} listData={listData} setIsCopySet={setIsCopySet} createItem={createItem} />
        )}

        {isCopySet && (
          <div className="board copy-set">
            <div className="header">
              <div className="title">{copyItemListTitle}</div>
              <button className="close" onClick={() => setIsCopySet(false)}>
                &times;
              </button>
            </div>
            <div className="copy-list analytics-scroll">
              {sortedListData.map((item) => (
                <div key={item.ID} className="list-item">
                  <div className="new-set-row-value">{item.Name || defaultItemTitle}</div>
                  <ILSButton className="btn-choose copy" onClick={() => copyBoardSet(+item.ID)}>
                    Выбрать
                  </ILSButton>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ILSAnalyticsEditableListWindowComponent;
