import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringMetricsRoutine } from '../actions';

type MetricsRequest = {
  from: string;
  till: string;
  selectedVehicleId: number | undefined;
};

// Получает данные метрик (data.data)
function* monitoringMetricsWorker(action: PayloadAction<MetricsRequest>) {
  const { request, success, failure, fulfill } = monitoringMetricsRoutine;
  const { from, till, selectedVehicleId } = action.payload;
  try {
    yield put(request({ from, till }));
    if (selectedVehicleId) {
      const { data } = yield call(MonitoringAPI.getMetricsByVehicle, { from, till, vehicleID: selectedVehicleId });
      yield put(success({ data: data.data }));
    } else {
      const { data } = yield call(MonitoringAPI.getMetrics, { from, till });
      yield put(success({ data: data.data }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringMetricsWatcher() {
  yield takeLatest(monitoringMetricsRoutine.trigger, monitoringMetricsWorker);
}
