import { VEHICLE_TRIPS_STATUSES } from '@common/constants/custom/statuses';
import { MonitoringVehicleStatus } from '@common/types/dictionaries/monitoring-vehicle';

const statusKeysConfig = [
  { complete: false, hasLates: false, key: MonitoringVehicleStatus.During },
  { complete: false, hasLates: true, key: MonitoringVehicleStatus.DuringWithLates },
  { complete: true, hasLates: true, key: MonitoringVehicleStatus.CompleteWithLates },
  { complete: true, hasLates: false, key: MonitoringVehicleStatus.Complete }
];
const defaultKey = MonitoringVehicleStatus.NoPlan;

export function getVehicleStatus(complete: boolean, hasLates?: boolean, hasTrips: boolean = true) {
  if (!hasTrips) return VEHICLE_TRIPS_STATUSES[defaultKey];
  const key =
    statusKeysConfig.find((item) => {
      return item.complete === complete && item.hasLates === hasLates;
    })?.key || defaultKey;

  return VEHICLE_TRIPS_STATUSES[key];
}
