import { FC } from 'react';
import { ILSAlert, ILSButton, ILSForm, ILSFormItem, ILSInput, ILSPhoneInput } from '@common/components';
import { Field, Label, Placeholder } from '@components/common/forms/constants';
import { FormLabels } from '@modules/orders-and-trips/types/form';
import { ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { useUserInfo } from '@common/hooks/use-user-info';
import { FakePreCostProposal, ProposalValues } from '@modules/orders-and-trips/types/fake-pre-cost';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import {
  ExternalTransportConfirmModal,
  IExternalTransportConfirmModalProps
} from '@modules/orders-and-trips/components/transport/external-transport-company/external-transport-confirm-modal';
import { DATE_TIME_BLOCK_LOAD, SERVICES_WITH_CANCEL_WARNING } from '@modules/orders-and-trips/constants/fake-pre-cost';
import { Notify } from '@common/types';
import { useStimulSoft } from '@common/hooks/stimulsoft/use-stimulsoft';

interface IProps extends IExternalTransportConfirmModalProps {
  id: FakePreCostProposal['id'];
  price: FakePreCostProposal['price'];
  info: FakePreCostProposal['info'];
  alias: FakePreCostProposal['name'];

  section: NewOrderFormTab;

  onFinish(
    values: ProposalValues & { alias: FakePreCostProposal['name'] } & {
      extra: { id: FakePreCostProposal['id']; info: FakePreCostProposal['info']; price: FakePreCostProposal['price'] };
    }
  ): void;
}

export const ProposalItemForm: FC<IProps> = ({
  section,
  id,
  onFinish,
  handleShowConfirmModal,
  confirmModalVisible,
  submitButtonProps,
  info,
  price,
  alias
}) => {
  useStimulSoft();
  const { userName, userPhone } = useUserInfo();

  const formName = `${section}-fake-pre-cost-add_${id + alias}`;
  const initialValues = {
    [Field.User]: { [Field.Contact]: userName, [Field.Phone]: userPhone }
  };

  const handleFinish = (values: ProposalValues) => onFinish({ ...values, extra: { id, info, price }, alias });

  return (
    <ILSForm initialValues={initialValues} className={'fake-pre-cost__form fake-pre-cost__form'} onFinish={handleFinish} name={formName}>
      <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_vertical fake-pre-cost__form-fieldset_contact'}>
          <legend>Контактное лицо на точке погрузки</legend>
          <ILSFormItem name={[Field.Source, Field.Name]} label={Label.Name} rules={ORDER_AND_TRIP_FORM_RULES[Field.Name]}>
            <ILSInput placeholder={Placeholder.CompanyName} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Source, Field.INN]} label={Label.INN} rules={ORDER_AND_TRIP_FORM_RULES[Field.INN]}>
            <ILSInput placeholder={Placeholder.INN} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Source, Field.KPP]} label={Label.KPP} rules={ORDER_AND_TRIP_FORM_RULES[Field.KPP]}>
            <ILSInput placeholder={Placeholder.KPP} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Source, Field.Contact]} label={FormLabels.Contact} rules={ORDER_AND_TRIP_FORM_RULES[Field.Contact]}>
            <ILSInput placeholder={Placeholder.Contact} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Source, Field.Phone]} label={FormLabels.Phone} rules={ORDER_AND_TRIP_FORM_RULES[Field.Phone]}>
            <ILSPhoneInput placeholder={Placeholder.Phone} />
          </ILSFormItem>
          {DATE_TIME_BLOCK_LOAD.map(({ Component, name, label, defaultProps }) => {
            return (
              <ILSFormItem required className={'fake-pre-cost__form-item'} key={name} name={name} label={label}>
                <Component {...(defaultProps ?? {})} />
              </ILSFormItem>
            );
          })}
        </fieldset>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_vertical fake-pre-cost__form-fieldset_contact'}>
          <legend>Контактное лицо на точке разгрузки</legend>
          <ILSFormItem name={[Field.Target, Field.Name]} label={Label.Name} rules={ORDER_AND_TRIP_FORM_RULES[Field.Name]}>
            <ILSInput placeholder={Placeholder.CompanyName} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Target, Field.INN]} label={Label.INN} rules={ORDER_AND_TRIP_FORM_RULES[Field.INN]}>
            <ILSInput placeholder={Placeholder.INN} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Target, Field.KPP]} label={Label.KPP} rules={ORDER_AND_TRIP_FORM_RULES[Field.KPP]}>
            <ILSInput placeholder={Placeholder.KPP} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Target, Field.Contact]} label={FormLabels.Contact} rules={ORDER_AND_TRIP_FORM_RULES[Field.Contact]}>
            <ILSInput placeholder={Placeholder.Contact} />
          </ILSFormItem>
          <ILSFormItem name={[Field.Target, Field.Phone]} label={FormLabels.Phone} rules={ORDER_AND_TRIP_FORM_RULES[Field.Phone]}>
            <ILSPhoneInput placeholder={Field.Phone} />
          </ILSFormItem>
        </fieldset>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_vertical fake-pre-cost__form-fieldset_contact'}>
          <legend>Контактное лицо</legend>
          <ILSFormItem name={[Field.User, Field.Contact]} label={FormLabels.Contact} rules={ORDER_AND_TRIP_FORM_RULES[Field.Contact]}>
            <ILSInput disabled placeholder={Placeholder.Contact} />
          </ILSFormItem>
          <ILSFormItem name={[Field.User, Field.Phone]} label={FormLabels.Phone} rules={ORDER_AND_TRIP_FORM_RULES[Field.Phone]}>
            <ILSPhoneInput disabled placeholder={Field.Phone} />
          </ILSFormItem>
        </fieldset>
        {SERVICES_WITH_CANCEL_WARNING.includes(alias) && (
          <ILSAlert
            type={Notify.Warning}
            message={'Внимание!!! Для транспортных компаний "ПЭК" и "Байкал Сервис" рейсы отменяются только через тех.поддержку!'}
          />
        )}
      </fieldset>
      <ILSButton form={formName} htmlType={'submit'} className={'fake-pre-cost__button_search'} type={'primary'} children={'Оформить'} />
      {confirmModalVisible && (
        <ExternalTransportConfirmModal
          confirmModalVisible={confirmModalVisible}
          handleShowConfirmModal={handleShowConfirmModal}
          submitButtonProps={submitButtonProps}
        />
      )}
    </ILSForm>
  );
};
