import { createRoutine } from 'redux-saga-routines';
import { createAction } from '@reduxjs/toolkit';

export const getDocumentTemplatesRoutine = createRoutine('DOCUMENT/GET_DOCUMENT_TEMPLATES');
export const getDocumentSetsRoutine = createRoutine('DOCUMENT/GET_DOCUMENT_SETS');
export const getTemplateRoutine = createRoutine('DOCUMENT/GET_TEMPLATE');
export const updateTemplateRoutine = createRoutine('DOCUMENT/UPDATE_TEMPLATE');
export const downloadDocument = createAction<number>('DOCUMENT/DOWNLOAD_DOCUMENT');
export const downloadDocuments = createAction<number>('DOCUMENT/DOWNLOAD_DOCUMENTS');
export const currentDealTemplateRoutine = createRoutine('DOCUMENT/CURRENT_DEAL_TEMPLATE');
export const deleteAttachTemplateRoutine = createRoutine('DOCUMENT/DELETE_ATTACH_TEMPLATE');
export const updateDealAttachRoutine = createRoutine('DOCUMENT/UPDATE_ATTACH_TEMPLATE');
export const copyTemplateRoutine = createRoutine('DOCUMENT/COPY_TEMPLATE');
export const resetCurrentTemplate = createAction('DOCUMENT/RESET_CURRENT_TEMPLATE');
export const deleteDocumentTemplateRoutine = createRoutine('DOCUMENT/DELETE_DOCUMENT_TEMPLATE');
export const createDealTemplateRoutine = createRoutine('DOCUMENT/CREATE_DEAL_TEMPLATE');
export const deleteDocumentSetRoutine = createRoutine('DOCUMENT/DELETE_DOCUMENT_SET');
export const createTemplateRoutine = createRoutine('DOCUMENT/CREATE_TEMPLATE');

