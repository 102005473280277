import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { exchangeVehiclesRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { RouteTotal } from '@common/types/dictionaries/route';
import { IndexedArray } from '@common/types';

export const exchangeVehiclesReducer = createReducer(initialState, {
  [exchangeVehiclesRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      routeTotal: IndexedArray<RouteTotal>;
    }>
  ) => {
    const { routeTotal } = action.payload;

    for (const vehicleID in routeTotal) {
      state.currentPlan.RouteTotal[vehicleID] = { ...routeTotal[vehicleID], changed: true };
    }

    return state;
  }
});
