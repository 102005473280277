import { call, put, select, takeLatest } from 'redux-saga/effects';
import { analyticsPlanningActions, deleteBoardRoutine } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';
import { AnalyticsStore } from '@modules/analytics/common/types/store';
import { sortListBySerial } from '@modules/analytics/common/utils/sort-boards';

export function* analyticsDeleteBoardWorker(action: PayloadAction<AnalyticsBoardModel>) {
  const { request, success, failure, fulfill } = deleteBoardRoutine;

  const { ID } = action.payload;

  try {
    yield put(request(action.payload));

    yield call(AnalyticsPlanningAPI.deleteBoard, ID);
    const state: AnalyticsStore = yield select((state) => state.AnalyticsPlanningBoards);

    if (state.Boards.selectedBoard === ID) {
      const sortedBoards = sortListBySerial(state.Boards.data, (item) => item.ID !== ID);
      yield put(analyticsPlanningActions.analyticsCommonSelectBoardRoutine(sortedBoards[0]));
    }
    yield put(success({ ID }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsDeleteBoardWatcher() {
  yield takeLatest(deleteBoardRoutine.TRIGGER, analyticsDeleteBoardWorker);
}
