import { TableColumnName, TableRecord } from '@common/types';
import { filterCatalogOptions } from '@common/components/data-display/table/utils';

export const filterCatalogOptionsWithRecord =
  <T extends Partial<Record<TableColumnName, any>>>(
    options: Array<T>,
    tableColumnName?: TableColumnName
  ): ((record?: TableRecord) => Array<T & { disabled: boolean }>) =>
  (record?: TableRecord) => {
    return filterCatalogOptions(options, record, tableColumnName);
  };
