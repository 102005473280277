import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { monitoringDateRoutine } from '../actions';

type DashboardRequest = {
  date: string[];
  metricsInterval: number;
};

// Получает диапазон дат и настройку - текущий максимальный интервал дат (date, metricsInterval)
function* monitoringDateWorker(action: PayloadAction<DashboardRequest>) {
  const { request, success, failure, fulfill } = monitoringDateRoutine;
  const { date, metricsInterval } = action.payload;

  try {
    yield put(request());

    yield put(success({ date, metricsInterval }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringDateWatcher() {
  yield takeLatest(monitoringDateRoutine.trigger, monitoringDateWorker);
}
