export const ACTIVE_WIDTH = 'activeWidth';
export const IN_ACTIVE_WIDTH = 'inactiveWidth';
export const ACTIVE_COLORED = 'activeColored';
export const IN_ACTIVE_COLORED = 'inactiveColored';
export const USE_FIGURE_MARKER = 'useFigureMarker';
export const USE_REDIS = 'useRedis';
export const DISABLE_CLUSTERING = 'disableClustering';
export const MAP_ZOOM_CLUSTER = 'mapZoomCluster';
export const VALIDATE_ON_PLANNING = 'validateOnPlanning';
export const VALIDATE_ON_IMPORT = 'validateOnImport';
export const INTER_RUPT_ON_VALIDATION_ERROR_LEVEL = 'interruptOnValidationErrorLevel';
export const UNRESOLVED_MARKER_SOURCE = 'unresolvedMarkerSource';
export const DRAW_PAIR_UNRESOLVED = 'drawPairForUnresolved';
export const HIDE_LINES_ON_TRACK_ABSENT = 'hideLinesOnTrackAbsent';
export const ENABLE_AUTO_FOCUS_ON_CHANGE_TRIP = 'enableAutoFocusOnChangeTrip';
export const ACTIVE_LOCK_TOOLTIP = 'activeLockTooltip';

export const SETTINGS_PLANNING_PARAMS = [
  ACTIVE_WIDTH,
  IN_ACTIVE_WIDTH,
  ACTIVE_COLORED,
  IN_ACTIVE_COLORED,
  USE_FIGURE_MARKER,
  USE_REDIS,
  DISABLE_CLUSTERING,
  MAP_ZOOM_CLUSTER,
  VALIDATE_ON_PLANNING,
  VALIDATE_ON_IMPORT,
  INTER_RUPT_ON_VALIDATION_ERROR_LEVEL,
  UNRESOLVED_MARKER_SOURCE,
  DRAW_PAIR_UNRESOLVED,
  HIDE_LINES_ON_TRACK_ABSENT,
  ENABLE_AUTO_FOCUS_ON_CHANGE_TRIP,
  ACTIVE_LOCK_TOOLTIP
];

export const InterruptOnValidationErrorLevel = {
  Never: 0,
  OnlyErrors: 1,
  ErrorsAndWarnings: 2
};
