import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { IMapTrack, MarkerPlanning, PlanStatus, TrackActiveData } from '@common/types';
import { MarkerFigureWidth } from '@common/types/components/map/map';
import { updateWidthMarker } from '@core/components/map/icons';
import { ILSMap } from '@core/containers/map';
import { LayerHandler } from '@core/types/map';
import { OnItemClick } from '@modules/planning/children/by-date/types/map-container';
import { getSelectedTypeResolved } from '@modules/planning/children/by-date/utils/check-resolved-selected-type';
import L, { LatLngExpression, LatLngTuple } from 'leaflet';
import { isEmpty, isObject, isString } from 'lodash';
import { anyDataMapItemType } from '../../types';
import { marker2ILSMarker } from './marker-to-ils-map-marker';

const createLPopupWithLassoButton = ({
  popup,
  setLassoLayers,
  options
}: {
  popup: Parameters<L.Marker['bindPopup']>;
  setLassoLayers: (layersData: any[]) => void;
  options: L.MarkerOptions & { data?: any };
}) => {
  const popupL = new L.Popup({ ...popup[1], className: 'waypoint-popup-container' });
  const contentPopup = L.DomUtil.create('div', 'waypoint-popup-wrapper');
  contentPopup.innerHTML = popup?.[0]?.toString()?.trim() as string;
  const btn = L.DomUtil.create('button', 'ant-btn ant-btn-default ils-vehicle-btn ils-button ils-show-order-btn', contentPopup);
  btn.innerText = 'Посмотреть заявки';
  L.DomEvent.on(btn, 'click', () => {
    setLassoLayers([{ options }]);
  });

  popupL.setContent(contentPopup);
  return popupL;
};

type PolyTrack2ILSPolyArg = {
  polyTrack: IMapTrack;
  setPolylineDataIDs: (D: TrackActiveData) => void;
  handleItemClick?: OnItemClick;
  setDraggableMarkerIDs?: (newMarkerData: anyDataMapItemType) => void;
  setLassoLayers: (layersData: any[]) => void;
};

type CollectDecMarkersArg = {
  markerPoly: MarkerPlanning;
  clickData: TrackActiveData | undefined;
} & PolyTrack2ILSPolyArg;

const collectDecMarkers = ({
  markerPoly,
  clickData,
  handleItemClick,
  setLassoLayers,
  setDraggableMarkerIDs,
  setPolylineDataIDs
}: CollectDecMarkersArg) => {
  const { data, color, figure, isHome, homeNumber, number } = markerPoly;

  const markerProps = marker2ILSMarker(markerPoly, handleItemClick, setDraggableMarkerIDs, {
    mouseover: (args) => {
      if (clickData && setPolylineDataIDs && args?.layer) {
        const curSelected = getSelectedTypeResolved(args.layer);
        const markerElement = args.layer.getElement()?.firstElementChild;
        const widthType = curSelected ? MarkerFigureWidth.Select : MarkerFigureWidth.Hover;
        updateWidthMarker(markerElement as HTMLElement, widthType, curSelected);

        setPolylineDataIDs(clickData);
      }
    },
    mouseout: (args) => {
      if (setPolylineDataIDs && args?.layer) {
        const curSelected = getSelectedTypeResolved(args.layer);
        const markerElement = args.layer.getElement()?.firstElementChild;
        const widthType = curSelected ? MarkerFigureWidth.Select : MarkerFigureWidth.Default;
        updateWidthMarker(markerElement as HTMLElement, widthType, curSelected);

        setPolylineDataIDs(null);
      }
    }
  });
  if (markerProps.popup) {
    markerProps.popup = [createLPopupWithLassoButton({ popup: markerProps.popup, setLassoLayers, options: markerProps.options })];
  }
  return markerProps;
};

export const polyTrack2ILSPoly = ({
  polyTrack,
  setPolylineDataIDs,
  handleItemClick,
  setDraggableMarkerIDs,
  setLassoLayers
}: PolyTrack2ILSPolyArg): {
  poly: Parameters<ILSMap['addPolyline']>[0][0] | undefined;
  marker: Parameters<ILSMap['addMarker']>[0][0] | undefined;
} => {
  const markerPoly = polyTrack.active && polyTrack.point && polyTrack.point;
  let markerProps: undefined | Parameters<ILSMap['addMarker']>[0][0];

  const dashedArr = polyTrack.weight ? [polyTrack.weight < 3 ? polyTrack.weight * 3 : polyTrack.weight * 2, polyTrack.weight * 5] : [];
  const coords: LatLngExpression[] = isString(polyTrack.coords)
    ? (GeohashDecodePoints(polyTrack.coords) as LatLngTuple[])
    : (polyTrack.coords as LatLngExpression[]);

  const option = {
    color: `${polyTrack.color}B3` ?? '#4C91FFB3',
    weight: polyTrack.weight ?? 3,
    dashArray: polyTrack.dashed ? dashedArr.join(', ') : undefined
  };
  let clickData: TrackActiveData | undefined;
  if (polyTrack.clickData) {
    clickData = {
      data: {
        ...polyTrack.clickData,
        tripStatus: polyTrack.tripStatus ? String(polyTrack.tripStatus) : PlanStatus.Open,
        TripID: polyTrack.TripID
      }
    };
  }

  const mouseover: LayerHandler<L.Polyline> = (args) => {
    const layer = args?.layer;
    layer?.setStyle({
      color: polyTrack.color ?? '#4C91FFB3',
      weight: 5,
      dashArray: polyTrack.dashed ? dashedArr.join(', ') : undefined
    });

    if (clickData) {
      setPolylineDataIDs?.(clickData);
    }
  };

  const mouseout: LayerHandler<L.Polyline> = (args) => {
    const layer = args?.layer;
    layer?.setStyle({
      color: `${polyTrack.color}B3` ?? '#4C91FF',
      weight: polyTrack.weight ?? 3,
      dashArray: polyTrack.dashed ? dashedArr.join(', ') : undefined
    });

    if (setPolylineDataIDs) {
      setPolylineDataIDs(null);
    }
  };

  const click: LayerHandler<L.Polyline> = () => {
    if (clickData) {
      handleItemClick?.(polyTrack.clickData);
    }
  };

  if (isObject(markerPoly)) {
    const { data } = markerPoly;

    if (!isEmpty(coords)) {
      if (!isEmpty(markerPoly.data)) {
        markerPoly.data = {
          ...data,
          tripStatus: polyTrack.tripStatus ? (String(polyTrack.tripStatus) as PlanStatus) : PlanStatus.Open,
          TripID: polyTrack.TripID
        };
      }
    }
    markerProps = collectDecMarkers({
      markerPoly,
      clickData,
      polyTrack,
      setLassoLayers,
      setPolylineDataIDs,
      handleItemClick,
      setDraggableMarkerIDs
    });
  }
  const polylineInit = {
    coords,
    handlers: {
      mouseover,
      mouseout,
      click
    },
    popup: undefined,
    options: option
  } as Parameters<ILSMap['addPolyline']>[0][0];

  return {
    poly: !isEmpty(coords) ? polylineInit : undefined,
    marker: markerProps
  };
};

export default { polyTrack2ILSPoly };

