import { ICellProps, TableRecord } from '@common/types';
import { useMemo } from 'react';

export const useReference = <R extends TableRecord>(reference: ICellProps<R>['reference'], record: R) =>
  useMemo(() => {
    if (reference) {
      if (typeof reference === 'function') {
        return reference(record);
      } else {
        return reference;
      }
    }
    return;
  }, [record, reference]);
