import { PlanningAction } from '@modules/planning/children/by-date/actions';

export const defaultStatuses: Partial<
  Record<
    PlanningAction,
    {
      loading: string;
      success: string;
      successPlural?: string;
      error?: string;
    }
  >
> = {
  RELOAD: { loading: 'Загрузка данных по плану...', success: 'План обновлен' },
  GET_PLAN: { loading: 'Загрузка данных по плану...', success: '' },
  VALIDATE: { loading: 'Валидация данных...', success: '', error: 'Ошибка валидации' },
  CLEAR: { loading: 'Очистка плана...', success: 'План очищен', error: 'Ошибка очистки плана' },
  COPY: {
    loading: 'Копирование плана...',
    success: 'План скопирован',
    successPlural: 'Планы скопированы',
    error: 'Ошибка копирования'
  },
  ADD_END_WAYPOINT: { loading: 'Добавление точки возврата...', success: 'Точка успешно перенесена' },
  ORDER_FOR_VEHICLE: { loading: 'Добавление в рейс...', success: 'Добавлено в рейс' },
  ADD_TRACK_REDIS: { loading: '', success: '', error: '' },
  CHANGE_VEHICLE: { loading: 'Замена транспортного средства...', success: 'Транспортное средство заменено' },
  CHANGE_DRIVER_IN_ROUTE: { loading: 'Замена водителя...', success: 'Водитель заменён' },
  CHANGE_DRIVER_IN_TRIP: { loading: 'Замена водителя...', success: 'Водитель заменён' },
  CHANGE_EXPEDITOR_IN_ROUTE: { loading: 'Замена экспедитора...', success: 'Экспедитор заменён' },
  [PlanningAction.ORDER_COPY_TO_PROJECT]: { loading: 'Копирование нераспределенных заявок...', success: 'Копирование завершено' },
  CREATE: { loading: 'Создание плана...', success: 'План создан' },
  CREATE_TRIP: { loading: 'Добавление нового рейса...', success: 'Рейс добавлен' },
  DELETE: { loading: 'Удаление...', success: 'Элементы удалены' },
  DONE: { loading: 'Завершение планирования...', success: 'Планирование завершено' },
  ENOUGH: { loading: 'Остановка планирования...', success: 'Остановлено', error: 'Ошибка' },
  EXCHANGE_VEHICLE: { loading: 'Обмен ТС...', success: 'Транспортные средства переставлены' },
  GET_CONFIGS: { loading: 'Загрузка настроек', success: 'Загружено' },
  GET_TRACK: { loading: 'Загрузка треков', success: 'Треки загружены', error: 'Ошибка' },
  GET_LINK: { loading: 'Загрузка данных', success: 'Загружено' },
  MOVE_WAYPOINT_IN_TRIP: { loading: 'Перемещение точек...', success: 'Точки успешно перенесены' },
  MOVE_WAYPOINT: { loading: 'Перемещение точек...', success: 'Точки успешно перенесены' },
  RESTRICTION_FILTER: { loading: 'Применение фильтра...', success: '', error: 'Ошибка применения фильтра' },
  SET_LINKS: { loading: 'Отправка ссылок клиентам...', success: 'Ссылки успешно отправлены' },
  SET_EXECUTION: { loading: 'Принятие плана в исполнение...', success: 'План принят в исполнение' },
  START: { loading: 'Начало планирования...', success: '' },
  UNION_TRIPS: { loading: 'Объединение рейсов...', success: 'Рейсы объединены' },
  UNSET_EXECUTION: { loading: 'Отзыв плана с исполнения...', success: 'План отозван с исполнения' },
  CANCEL: { loading: 'Остановка планирования...', success: 'Остановлено' },
  CLEANUP_MATRIX: { loading: 'Очистка неудачно завершённых расчётов...', success: 'Некорректные матрицы очищены' },
  START_MATRIX: { loading: 'Запуск расчёта матрицы...', success: 'Расчёт запущен' },
  DELETE_MATRIX: { loading: 'Удаление матрицы из расчёта...', success: 'Матрица удалена' },
  STATUS_MATRIX: {
    loading: 'Получение статуса расчёта матрицы...',
    success: 'Cтатус расчёта матрицы получен',
    error: 'Ошибка получение статуса расчёта матрицы'
  },
  CHANGE: {
    loading: 'Изменение...',
    success: 'Изменено'
  },
  GET_PROJECT_CURRENT: { loading: 'Загрузка проекта...', success: 'Проект загружен', error: 'Ошибка загрузки проекта' },
  SAVE_ROUTE: { loading: 'Сохранение данных...', success: 'Данные сохранены', error: 'Ошибка получения данных' },
  SAVE_TRIP: { loading: 'Сохранение данных...', success: 'Данные сохранены', error: 'Ошибка получения данных' },
  SAVE_PLAN: { loading: 'Сохранение плана...', success: 'План сохранен', error: 'Ошибка сохранения плана' },
  ADD_CREATED_PLAN: { loading: 'Добавление плана...', success: 'План добавлен', error: 'Не удалось добавить план' }
};
