import { call, put, takeLatest } from 'redux-saga/effects';
import { RoadGraphTest } from '@modules/road-graph/route-test/api';
import { roadGraphGetShortestRoutine } from '@modules/road-graph/route-test/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* roadGraphGetShortestWorker(
  action: PayloadAction<{
    coordinates: string;
    overview: string;
    geometries: string;
    steps: boolean;
  }>
) {
  const { request, success, failure, fulfill } = roadGraphGetShortestRoutine;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(RoadGraphTest.getShortest, { ...action.payload });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* roadGraphGetShortestWatcher() {
  yield takeLatest(roadGraphGetShortestRoutine.trigger, roadGraphGetShortestWorker);
}
