import { TemplateData } from '@modules/documents/children/templates/types/api';

declare var Stimulsoft: any;

export const generateReportFromJson = (templateData: TemplateData) => {
  Stimulsoft.Base.StiLicense.loadFromFile('/assets/scripts/stimulsoft/license.key');
  Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/scripts/stimulsoft/ru.xml');

  let reportInst = new Stimulsoft.Report.StiReport();
  reportInst.load(templateData.template);
  reportInst.dictionary.databases.clear();

  let dataSet = new Stimulsoft.System.Data.DataSet('dataNew');
  dataSet.readJson(JSON.stringify(templateData.data));
  reportInst.regData(dataSet.dataSetName, '', dataSet);

  return reportInst;
};
