import { defineModule } from '@core/modules';
import { ModulePath, ModuleTitle } from '@common/types';
import DocumentsUpload from './containers';
import { documentsUploadRootReducer } from '@modules/documents/children/upload/reducers';
import { getAllModuleSagas } from '@core/sagas';
import * as documentUploadRootSagas from './sagas';

export default defineModule({
  title: ModuleTitle.DocumentsUpload,
  path: ModulePath.DocumentsUpload,
  component: DocumentsUpload,
  reducer: documentsUploadRootReducer,
  sagas: getAllModuleSagas(documentUploadRootSagas)
});
