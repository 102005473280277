import { DictionariesEndpoint } from '@common/types';
import { AccountAction, UserAction } from '../actions';
import { EMPTY_STRING } from '@common/constants';
import { NotifyMessage } from '@common/types/general/reducers';

export const DEFAULT_ACCOUNT_MESSAGES: NotifyMessage<AccountAction> = {
  [DictionariesEndpoint.Index]: {
    loading: 'Загрузка данных аккаунта...',
    error: 'Не удалось получить данные аккаунта',
    success: 'Данные аккаунта загружены',
    successPlural: 'Данные аккаунта частично загружены'
  },
  [DictionariesEndpoint.Update]: {
    loading: 'Обновление данных аккаунта...',
    error: 'Не удалось обновить данные аккаунта',
    success: 'Данные успешно обновлены',
    successPlural: 'Данные частично обновлены'
  },
  [DictionariesEndpoint.getRoleEnum]: {
    loading: 'Загрузка доступных ролей...',
    error: 'Не удалось загрузить доступные роли',
    success: 'Доступные роли загружены',
    successPlural: 'Данные частично загружены'
  },
  [AccountAction.GET_INITIAL]: {
    loading: EMPTY_STRING,
    error: EMPTY_STRING,
    success: EMPTY_STRING
  }
};

export const DEFAULT_USER_MESSAGES: NotifyMessage<UserAction> = {
  [DictionariesEndpoint.Update]: {
    loading: 'Обновление данных пользователя...',
    error: 'Не удалось обновить данные пользователя',
    success: 'Данные успешно обновлены',
    successPlural: 'Данные частично обновлены'
  },
  [DictionariesEndpoint.Delete]: {
    loading: 'Удаление пользователя...',
    error: 'Не удалось удалить пользователя',
    success: 'Пользователь успешно удален',
    successPlural: EMPTY_STRING
  },
  [DictionariesEndpoint.Create]: {
    loading: 'Создание пользователя...',
    error: 'Не удалось создать пользователя',
    success: 'Пользователь успешно создан',
    successPlural: EMPTY_STRING
  },
  [DictionariesEndpoint.Index]: {
    loading: 'Загрузка пользователей...',
    error: 'Не удалось получить данные пользователей',
    success: 'Данные пользователей загружены',
    successPlural: 'Данные пользователей частично загружены'
  }
};
