import { Dictionary } from '@common/types';
import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { MenuSchemes } from '@modules/planning/children/scheme-editor/types/components';

export enum MenuForSchemes {
  code = 'code',
  max = 'max',
  type = 'type',
  help = 'help'
}

export enum SchemeKeyType {
  INT = 'INT',
  INTEGER = 'INTEGER',
  NUMBER = 'NUMBER',
  DOUBLE = 'DOUBLE',
  CommaDouble = 'COMMA-DOUBLE',
  KILO = 'KILO',
  MEGA = 'MEGA',
  MILLI = 'MILLI',
  MICRO = 'MICRO',
  FLOAT = 'FLOAT',
  REAL = 'REAL',
  OVERLOAD = 'OVERLOAD',
  PERCENT = 'PERCENT',
  HHMM = 'HHMM',
  HHMMColon = 'HH:MM',
  HHMMSSColon = 'HH:MM:SS',
  ISO8601 = 'ISO8601',
  H = 'H',
  HI = 'HI',
  M = 'M',
  MI = 'MI',
  S = 'S',
  SI = 'SI',
  STR = 'STR',
  STRING = 'STRING',
  VARCHAR = 'VARCHAR',
  YYYYMMDD = 'YYYYMMDD',
  YYYYMMDDHHMM = 'YYYYMMDDHHMM',
  YYYYMMDDHHMMSS = 'YYYYMMDDHHMMSS',
  YYYYMMDDDash = 'YYYY-MM-DD',
  YYYYMMDDDashHHMMColon = 'YYYY-MM-DD HH:MM',
  YYYYMMDDDashHHMMSSColon = 'YYYY-MM-DD HH:MM:SS',
  DDMMYYYYPeriod = 'DD.MM.YYYY',
  HHMMColonDDMDot = 'HH:MM DD.M',
  DATETIME = 'DATETIME',
  DEGMINSEC = 'DEGMINSEC',
  DEGMIN = 'DEGMIN',

  Info = 'Info',
  Empty = 'Empty'
}

export const SCHEMES_TYPE = [Dictionary.Intro];

export const MENU_FOR_SCHEMES_SWITCHER: MenuSchemes = {
  [MenuForSchemes.max]: true,
  [MenuForSchemes.type]: false
};

export const ALWAYS_VISIBLE_SWITCHER = Dictionary.Intro;
export const ALWAYS_VVISIBLE_SCHEME_TYPES: Array<SchemeSwitchDictionary> = [Dictionary.Intro];

export const TYPES = {
  INT: 'целое число',
  INTEGER: 'тоже, что и INT',
  NUMBER: ' тоже, что и INT',
  DOUBLE:
    'действительное число или время, определенное в количестве суток для полей типа время или интервал (в качестве разделителя целой и дробной части при импорте можно использовать точку или запятую, при экспорте - всегда точка)',
  'COMMA-DOUBLE':
    'действительное число или время, определенное в количестве суток для полей типа время или интервал (в качестве разделителя целой и дробной части при импорте можно использовать точку или запятую, при экспорте - всегда запятая)',
  KILO: 'действительное число, разделенное на 1000, при импорте полученное число умножается на 1000 перед сохранением в БД, при экспорте - делится',
  MEGA: 'действительное число, разделенное на 1000000, при импорте полученное число умножается на 1000000 перед сохранением в БД, при экспорте - делится',
  MILLI:
    'действительное число, домноженное на 1000, при импорте полученное число делится на 1000 перед сохранением в БД, при экспорте - помножается',
  MICRO:
    'действительное число, домноженное на 1000000, при импорте полученное число делится на 1000000 перед сохранением в БД, при экспорте - помножается',
  FLOAT: 'тоже, что и DOUBLE',
  REAL: 'тоже, что и DOUBLE',
  OVERLOAD: 'значение допустимого превышение либо в абсолютных значениях, либо в процентах если на конце указан символ %',
  PERCENT: 'значение допустимого превышение указанное в процентах',
  HHMM: 'часы (00-23) и минуты (00-60) без разделителей для полей типа время или интервал',
  'HH:MM': 'часы (00-23) и минуты (00-60) с разделителем двоеточие для полей типа время или интервал',
  'HH:MM:SS': 'часы (00-23), минуты (00-60) и секунды (00-60) с разделителем двоеточие для полей типа время или интервал',
  ISO8601: 'Дата / время в формате ISO 8601',
  H: 'количество часов, возможно не целое',
  HI: 'целое количество часов',
  M: 'количество минут, возможно не целое',
  MI: 'целое количество минут',
  S: 'количество секунд, возможно не целое',
  SI: 'целое количество секунд',
  STR: 'строковое значение',
  STRING: 'тоже, что и STR',
  VARCHAR: 'тоже, что и STR',
  YYYYMMDD: 'дата в формате ГодМесяцДень',
  YYYYMMDDHHMM: 'дата в формате ГодМесяцДеньЧасыМинуты',
  YYYYMMDDHHMMSS: 'дата в формате ГодМесяцДеньЧасыМинутыСекунды',
  'YYYY-MM-DD': 'дата в формате Год-Месяц-День',
  'YYYY-MM-DD HH:MM': 'дата в формате Год-Месяц-День Часы:Минуты',
  'YYYY-MM-DD HH:MM:SS': 'дата в формате Год-Месяц-День Часы:Минуты:Секунды',
  'DD.MM.YYYY': 'дата в формате День.Месяц.Год',
  'HH:MM DD.M': 'время/дата',
  DATETIME:
    'время/дата с пользовательским форматом (php формат даты и времени), например, col43=EndDepot.Worktime.LoadStart DATETIME d.m.Y H:i:s',
  DEGMINSEC: `"координаты в градусах°минутах'секундах" (примеры:="" 50°35′7.852624″,="" 38°10′18.088221″,="" 50°56'16.0",="" 38°39'20.7")"=""`,
  DEGMIN: "координаты в градусах°минутах' (примеры: 50°357.852624′, 38°10′, 50°5616.0', 38°3920.7')"
};

export const SCHEME_TYPES_ENTRIES = Object.entries(TYPES);
export const EMPTY_KEY_TEXT = 'Пустое поле';
export const INFO_KEY_TEXT = 'Инфо';
export const SCHEMA_KEY_SEPARATOR = '@';
