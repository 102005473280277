import { ILSTable } from '@common/components';
import { Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { orderTableConfigForOrdersNTrips } from '@modules/orders-and-trips/config/order-table';

//Сделать props tableName
const TableComponent = (props: any) => {
  return (
    <ILSTable
      {...props}
      scroll={props?.scroll ?? { x: 500, y: 600 }}
      tableConfig={props?.config}
      tableName="ordersNTrips"
      dictionary={Dictionary.Order}
    />
  );
};

export const OrderTableComponent = tableCustom(TableComponent, orderTableConfigForOrdersNTrips, Dictionary.NotDeliveredOrder);

