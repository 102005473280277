import { Dictionary, ShowDeleted } from '@common/types';
import {  isEmpty, throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useCatalog } from '../../hooks/use-catalog';
import { mapPlanDataSelector } from '../../selectors/components/map/plan-data';
import { projectDepotSelector } from '../../selectors/project';
import { projectDictionaryIsFetchingSelector } from '../../selectors/fetching';
import { useAppSelector } from '@core/hooks';

//TODO add UNIT 
export const useMapPlanData = () => {
  const Depot = useAppSelector(projectDepotSelector);
  const DepotFetching = useAppSelector((state)=>projectDictionaryIsFetchingSelector(state, Dictionary.Depot));

  const { catalogLoad } = useCatalog();
  const catalogLoadRef = useRef(catalogLoad)
  catalogLoadRef.current = catalogLoad

  const DepotFetchingRef =  useRef(DepotFetching);
  const DepotRef = useRef(Depot);
  DepotFetchingRef.current = DepotFetching
  

  const throttleLoad = useCallback(
    throttle(
      () => {
        if (isEmpty(DepotRef.current) && !DepotFetchingRef.current) {
          catalogLoadRef.current(Dictionary.Depot, ShowDeleted.Yes, true);
        }
      },
      2500,
      { leading: false, trailing: true }
    ),
    []
  );

  const mapData = useAppSelector(mapPlanDataSelector);
  
  useEffect(() => {
    DepotRef.current = Depot;
    if (isEmpty(Depot) && (!isEmpty(mapData?.depotIDsFromOrder) || !isEmpty(mapData?.depotIDsFromWaypoint))) {
      throttleLoad();
    }
  }, [mapData]);

  return { mapData };
};

