import { stopSagaRoutine } from '@core/actions/general';
import { call, cancel, takeLatest } from 'redux-saga/effects';
import { ILSAppRegistry } from '../../index';
import { Task } from 'redux-saga';

function* stopSagasWorker() {
  try {
    /**
     * getTask(taskName?:string) - список сохраненных тасок для отмены или одна таска для отмены,
     * removeTask(taskName:string) - удаляет таски
     */
    const { getTask, removeTask } = ILSAppRegistry?.sagaInjector ?? {};
    const tasks: Record<string, Task> = getTask?.();
    //NOTE: Перебираем запущенные таски
    for (let keyTask in tasks) {
      //NOTE: Если таска не отменена, отменяем ее
      if (!tasks[keyTask]?.isCancelled()) {
        yield cancel(tasks[keyTask]);
      }
      //NOTE: Удаляем таску из списка
      if (removeTask && getTask(keyTask)) {
        yield call(removeTask, keyTask);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * Корневая сага StopSagas - служит для остановки запущенных саг при выходе из приложения
 * срабатывает при вызове stopSagaRoutine
 */
export function* stopSagasWatcher() {
  yield takeLatest(stopSagaRoutine.trigger, stopSagasWorker);
}
