import { emulatorCheckStatusRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { CheckStatusRequest, CheckStatusResponse } from '../types/api';

// Получает данные о статусе эмуляции у выбранного плана
function* emulatorCheckStatusWorker(action: PayloadAction<CheckStatusRequest>) {
  const { request, success, failure, fulfill } = emulatorCheckStatusRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    const { data }: { data: { data: CheckStatusResponse } } = yield call(EmulatorAPI.checkStatus, { planID });

    yield put(success(data.data));
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorCheckStatusWatcher() {
  yield takeLatest(emulatorCheckStatusRoutine.TRIGGER, emulatorCheckStatusWorker);
}
