import { TimeStamp } from '@common/types';

export interface PaymentInitialState {
  isFetching: boolean;
  error: [];
  invoice: InvoiceTemplate;
  currentBalance: {
    sum: number;
    isFetching: boolean;
    lastUpdate: number | null;
  };
  balanceHistory: {
    transactions: Array<PaymentBalanceHistoryResponse>;
    isFetching: boolean;
  };
}

/** Тип содержимого модальных окон */
export enum PaymentModalType {
  CreateInvoice = 'CreateInvoice',
  PayWithCard = 'PayWithCard',
  BalanceHistory = 'BalanceHistory',
  InvoiceViewer = 'InvoiceViewer'
}

export interface InvoiceTemplate {
  template: string;
  data: string;
}

export interface PaymentCurrentBalanceResponse {
  ID: number;
  AccountID: number;
  CurrentBalance: number;
  LastUpdate: TimeStamp;
}

/** Тип данных операции (пополнение, списание), возвращаемых с бэка из истории изменения баланса аккаунта */
export interface PaymentBalanceHistoryResponse {
  ID: number;
  DT: TimeStamp;
  // Изменение баланса (с минусом - списание, с плюсом - пополнение)
  BalanceOffset: number;
  Comment: string;
  AccountID: number;
  UserID: number;
  Dependent: string;
  DependentID: number | null;
  Operation: string;
}

/** Операция (пополнение или списание) */
export enum PaymentBalanceHistoryTransaction {
  id = 'id',
  tripID = 'tripID',
  dateTime = 'dateTime',
  balanceOffset = 'balanceOffset',
  comment = 'comment'
}

/** Тип операции (пополнение или списание) */
export type PaymentBalanceHistoryModified = {
  [PaymentBalanceHistoryTransaction.id]: number;
  [PaymentBalanceHistoryTransaction.tripID]: number | string;
  [PaymentBalanceHistoryTransaction.dateTime]: string;
  [PaymentBalanceHistoryTransaction.balanceOffset]: string;
  [PaymentBalanceHistoryTransaction.comment]: string;
};
export type PaymentBalanceHistoryCellType = {
  content: string | number;
  className: string;
};

