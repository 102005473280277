import { Dictionary, IDecorateValidationData, Provider, ValidationData } from '@common/types';
import { ValidationDictionary } from '@common/types/dictionaries/dictionaries';
import { ValidationKind, ValidationTable, ValidationErrors } from '@common/types/dictionaries/validation';
import { PlanningValidationType } from '../types/planning';

export const validatedData = (
  data: ValidationData[],
  planConfigID?: string,
  providerName?: Provider,
  validationType?: PlanningValidationType
): IDecorateValidationData | null => {
  if (!data || !Array.isArray(data)) return null;

  const resultData: IDecorateValidationData = {
    Common: [],
    CountAll: {
      error: 0,
      warning: 0,
      info: 0,
      bad: 0
    }
  };

  resultData[ValidationTable.Common] = [];

  //NOTE: сохраняем конфигурацию, которая была выбрана при валидации (только для планировщика)
  if (planConfigID && providerName && validationType) {
    resultData[ValidationTable.Config] = {
      planConfigID,
      providerName,
      validationType
    };
  }

  data.forEach((record) => {
    //NOTE: Достанем из record Kind_  - тип ошибки и Sect_ - категорию (dictionary)
    const { Kind_, Sect_ } = record;
    record.Type = ValidationErrors[Kind_];

    // NOTE: Разобъем ошибки by Dictionary
    if (record[Kind_ + '_']) {
      //NOTE: Если категория (Sect_) есть в ValidateDictionary
      if (ValidationDictionary[Sect_]) {
        const dictionary = Dictionary[Sect_];
        if (!resultData[dictionary]) resultData[dictionary] = [];
        resultData[dictionary].push(record);
      } else {
        //NOTE: Если категории (Sect_) нет в Dictionary, записываем ее в Common (общее)
        resultData[ValidationTable.Common].push(record);
      }
    }

    // NOTE: Запишем общее кол-во ошибок. Счетчик по типу ошибок
    switch (Kind_) {
      case ValidationKind.bad:
        resultData.CountAll.bad += Number(record[Kind_ + '_']);
        break;
      case ValidationKind.error:
        resultData.CountAll.error += Number(record[Kind_ + '_']);
        break;
      case ValidationKind.warning:
        resultData.CountAll.warning += Number(record[Kind_ + '_']);
        break;
      case ValidationKind.info:
        resultData.CountAll.info += Number(record[Kind_ + '_']);
        break;
    }
  });

  return resultData;
};
