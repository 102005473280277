import { put, takeLatest } from 'redux-saga/effects';
import { getTripsRoutine, switchTripStatusRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { ActiveRecord } from '@modules/orders-and-trips/types/general';

function* switchTripStatusWorker(
  action: PayloadAction<{
    status: TripStatus;
    activeTrip?: ActiveRecord;
    isUpdate?: boolean;
  }>
) {
  const { request, failure, fulfill, success } = switchTripStatusRoutine;
  const { status, activeTrip, isUpdate } = action.payload ?? {};
  try {
    yield put(request());
    yield put(getTripsRoutine.trigger({ source: status, activeTrip }));
    yield put(success({ isUpdate }));
  } catch (error) {
    yield put(failure({ error } as any));
  } finally {
    yield put(fulfill());
  }
}

export function* switchTripStatusWatcher() {
  yield takeLatest(switchTripStatusRoutine.trigger, switchTripStatusWorker);
}

