import React from 'react';
import { GRAPH_LABEL_FOR_TOOLTIP } from '@modules/monitoring/components/graph/constants/graph-table';
import { GraphSchemaDataSetType } from '@modules/monitoring/children/dashboard/components/dashboard/configs/graph';

const GraphCustomTooltip = ({ time, data }: GraphSchemaDataSetType) => {
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      width: 200
    }
  };
  return (
    <div style={styles.container}>
      <ul className="custom-ul-for-graph-tooltip">
        <li className="custom-li-time-for-graph-tooltip">{time}</li>
        {data !== undefined &&
          data
            .map((item) => {
              let value = item.data.y;
              if (item.data.dataType === GRAPH_LABEL_FOR_TOOLTIP) {
                value = item.data.y === 0 ? 'Закрыто' : 'Открыто';
              }
              return {
                color: item.color,
                label: item.data.dataType,
                value: value
              };
            })
            .map((item, index) => {
              return (
                <li key={index.toString() + item.label + item.value} style={{ color: item.color }}>
                  <span className="custom-span-for-graph-tooltip">{`${item.label}:`}</span>
                  <span className="custom-span-for-graph-tooltip marg">{`${item.value}`}</span>
                </li>
              );
            })}
      </ul>
    </div>
  );
};
export default GraphCustomTooltip;

