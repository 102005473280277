import { call, put, takeLatest } from 'redux-saga/effects';
import { getUploadTemplateRoutine } from '@modules/planning/children/data/actions';
import { Data } from '@modules/planning/children/data/api';

function* getUploadTemplateWorker() {
  const { request, success, failure, fulfill } = getUploadTemplateRoutine;

  try {
    yield put(request());

    const {
      data: { data: addExample }
    } = yield call(Data.getUploadTemplate, { addExample: true });
    const {
      data: { data }
    } = yield call(Data.getUploadTemplate, { addExample: false });
    yield put(success({ data, addExample }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getUploadTemplateWatcher() {
  yield takeLatest(getUploadTemplateRoutine.trigger, getUploadTemplateWorker);
}
