import { createSelector } from 'reselect';
import { PlanAnalyticStore } from '../types/store';

export const fetchSelector = createSelector(
  (state: { PlanAnalytic: PlanAnalyticStore }) => state.PlanAnalytic.isFetching,
  (isFetching) => isFetching
);

export const dataSelector = createSelector(
  (state: { PlanAnalytic: PlanAnalyticStore }) => state.PlanAnalytic.data,
  (data) => data
);
export const lastUpdateSelector = createSelector(
  (state: { PlanAnalytic: PlanAnalyticStore }) => state.PlanAnalytic.lastUpdate,
  (lastUpdate) => lastUpdate
);
