import { TableRecord } from '@common/types';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';
import { highlightDraggableRow } from '@modules/planning/children/by-date/helpers';
import { RoutesTableDragHandler } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drag-end';
import { MouseEvent } from 'react';

export const handleRecordHover =
  ({ onChangeHoveredRecord, selectRecord }: RoutesTableDragHandler) =>
  (record: TableRecord | undefined) =>
    record
      ? (_e: MouseEvent | undefined, record: (TableRecord & Partial<WaypointTotal>) | undefined) => {
          if (record && selectRecord.current?.key) {
            if (highlightDraggableRow(record, selectRecord)) {
              onChangeHoveredRecord(record);
            } else {
              onChangeHoveredRecord();
            }
            const startTripID = (selectRecord.current as TableRecord & Partial<WaypointTotal>)?.TripID;
            const { TripID } = record;
            if (TripID && startTripID !== TripID) {
              return { ID: Number(TripID) };
            }
          }
          return { ID: undefined };
        }
      : undefined;

