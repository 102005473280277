import { Dictionary, Zone } from '@common/types';
import { getRefData } from '@components/data-display/table/utils/helpers/get-ref-data';
import { generateSelect } from '@components/decorators';
import { CatalogState, Name } from '@modules/catalog/types/store';
import { createSelector, createSelectorCreator, defaultEqualityCheck, defaultMemoize } from 'reselect';
import { catalogSimpleSelector } from '../index';
import { swapToOriginalDictionary } from '../../helpers/swap-catalog-dictionary';

export const catalogDepotsSelector = createSelector(
  (state: CatalogState) => state.Catalog.Depot.data,
  (depots) => depots
);

export const catalogDictionaryDataSelector = (dictionary: Dictionary) =>
  createSelector(
    (state: CatalogState) => state.Catalog[dictionary].data,
    (data) => data
  );

export const depotOptionsSelector = createSelector(catalogDepotsSelector, (depots) => {
  const dictionary = Dictionary.Depot;
  return {
    data: depots,
    dictionary,
    fieldsName: { value: 'ID', name: 'Name' }
  };
});

/**
 * @deprecated
 */
export const customCreateSelector = (size?: number) =>
  createSelectorCreator(defaultMemoize, {
    equalityCheck: defaultEqualityCheck,
    maxSize: size ?? 1
  });

const selectFilteredValues = (data: Array<any>, filterValue: string | number) => {
  const filteredRecords: Array<{ value: number; label: string }> = [];
  data.forEach((record: Zone) => {
    const value = +record.ID;
    const label = record.Name;
    if (record.Type === filterValue) {
      filteredRecords.push({ value, label });
    }
  });
  return filteredRecords;
};

export const catalogOptionsFromDataSelector = (dictionary: Dictionary, name: Name = 'data', filteredValue?: number | string) =>
  createSelector(
    (state: CatalogState) => catalogSimpleSelector(state, dictionary, name),
    (Catalog) => {
      return {
        data:
          filteredValue !== undefined
            ? selectFilteredValues(Catalog, filteredValue)
            : generateSelect({
                data: getRefData(Catalog),
                dictionary
              }),
        dictionary: swapToOriginalDictionary(dictionary),
        originalDictionary: dictionary
      };
    }
  );

//TODO Need Unit
/**
 * Функция для генерации данных refData ILSTable
 * @param dicts массив объектов с названием справочников
 * @returns объект с ключем названия словаря и значением которое необходимо для заполнения option в select
 *
 */
export const catalogRefDataSelector = (
  dicts: Array<{
    dictionary: Dictionary;
    name?: Name;
  }>
) => {
  const selectors = dicts.map((dict) => {
    return catalogOptionsFromDataSelector(dict.dictionary, dict.name || 'data');
  });
  return createSelector([...selectors], (...args) => {
    return args.reduce((ref, item) => {
      ref[item.originalDictionary] = item;
      return ref;
    }, {});
  });
};
