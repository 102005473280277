import { FC, useContext } from 'react';
import { ILSDivider } from '@components/index';
import { CardTab, CardTabTitle, LOT_TABS } from '@modules/tender-platform/constants/tender-card';
import { ILSTypographyTitle } from '@components/general/typography';
import { TenderPlatformTenderFormContainer } from '@modules/tender-platform/containers/tender-card/general';
import { Tender, TenderType } from '@modules/tender-platform/types';
import { Module } from '@common/types';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { TenderLotTemplate } from '@modules/tender-platform/types/lot';

interface IProps {
  isTenderEditable: boolean;
  menu: Array<CardTab>;
  currentTender:
    | Tender
    | (Omit<Tender, 'TenderLot'> & {
        TenderLot: TenderLotTemplate;
      });
}

export const ILSTenderCardsContent: FC<IProps> = ({ menu, currentTender, isTenderEditable }) => {
  const tenderPlatformChildrenModule = useContext(ILSTenderModuleContext);
  const isAuction = currentTender?.Type === TenderType.Auction && tenderPlatformChildrenModule === Module.TenderSearch;

  //NOTE: key + currentTender?.ID - меняет ключ компонента (для перерендера), если currentTender тендер изменился или создается новый тендер
  const CONTENT = {
    [CardTab.GeneralInfo]: (
      <TenderPlatformTenderFormContainer
        key={CardTab.GeneralInfo + currentTender?.ID}
        isAuction={isAuction}
        isTenderEditable={isTenderEditable}
      />
    )
  };

  return (
    <>
      {menu.reduce((prev, tab, index) => {
        if (LOT_TABS.includes(tab)) {
          return prev;
        }
        CONTENT[tab] &&
          prev.push(
            <div key={index}>
              <ILSDivider
                key={tab + 'divider'}
                orientation={'left'}
                children={<ILSTypographyTitle key={tab + 'title'} id={tab} level={2} children={CardTabTitle[tab]} />}
              />
              {CONTENT[tab]}
            </div>
          );
        return prev;
      }, [] as Array<JSX.Element>)}
    </>
  );
};
