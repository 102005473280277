import { FC } from 'react';
import { ILSFormItem, ILSSelect } from '@components/index';
import { FormPlaceholders } from '@modules/orders-and-trips/types/form';
import { VehicleTypesOptions } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';
import { DEFAULT_VEHICLE_FORM_RULES } from '@components/common/forms/vehicle/constants/rules';
import { ILSFormItemProps } from '@components/data-entry/form';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { Field, Label } from '../../../constants';

export interface IFormItemProps extends ILSFormItemProps {
  placeholder?: FormPlaceholders;
}

interface IProps extends IFormItemProps {
  vehicleTypes: VehicleTypesOptions;
  selectProps?: IILSSelectProps;
}

export const VehicleTypeFormField: FC<IProps> = ({
  vehicleTypes,
  selectProps = {},
  placeholder = FormPlaceholders.VehicleType,
  ...props
}) => {
  return (
    <ILSFormItem name={Field.VehicleType} label={Label.VehicleType} rules={DEFAULT_VEHICLE_FORM_RULES[Field.VehicleType]} {...props}>
      <ILSSelect options={vehicleTypes} placeholder={placeholder} {...selectProps} />
    </ILSFormItem>
  );
};
