import { createSelector } from 'reselect';
import { DemandState, IDemandStore } from '../types/store';

// isFetchingSelector	Возвращает булево значение - производится ли сейчас загрузка данных (Demand.isFetching)
export const isFetchingSelector = createSelector(
  (state: DemandState) => state.Demand.isFetching,
  (isFetching: IDemandStore['isFetching']) => isFetching
);

// isAllUploadedSelector	Возвращает булево значение - загружены ли все строки списка (Demand.isAllUploaded)
export const isAllUploadedSelector = createSelector(
  (state: DemandState) => state.Demand.isAllUploaded,
  (isAllUploaded: IDemandStore['isAllUploaded']) => isAllUploaded
);

// hasDesyncSelector	Возвращает булево значение - была ли десинхронизация (Demand.hasDesync)
export const hasDesyncSelector = createSelector(
  (state: DemandState) => state.Demand.hasDesync,
  (hasDesync: IDemandStore['hasDesync']) => hasDesync
);

// loadParamsSelector	Возвращает последние загруженные параметры (Demand.lastParams)
export const loadParamsSelector = createSelector(
  (state: DemandState) => state.Demand.lastParams,
  (lastParams: IDemandStore['lastParams']) => lastParams
);

// loadParamsSelector	Возвращает последние загруженные параметры (Demand.lastParams)
export const loadParamsFilterSelector = createSelector(loadParamsSelector, (lastParams) => lastParams?.filter);

// listSelector	Отдаёт список проектов и лотов (Demand.list)
export const listSelector = createSelector(
  (state: DemandState) => state.Demand.list,
  (list: IDemandStore['list']) => list
);

// demandActiveTab	Возвращает активный таб (Demand.activeTab)
export const demandActiveTab = createSelector(
  (state: DemandState) => state.Demand.activeTab,
  (activeTab) => activeTab
);

// demandTabMetrics	Возвращает количество проектов и лотов (Demand.demandMetrics)
export const demandTabMetrics = createSelector(
  (state: DemandState) => state.Demand.demandMetrics,
  (demandMetics: IDemandStore['demandMetrics']) => demandMetics
);

// demandLastUpdateSelector	Возвращает таймстамп последнего обновления (Demand.lastUpdate)
export const demandLastUpdateSelector = createSelector(
  (state: DemandState) => state.Demand.lastUpdate,
  (lastUpdate: IDemandStore['lastUpdate']) => lastUpdate
);
