import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { planningUpdatePlansAndRoutesAction } from '../../actions';
import { PlanningAPI } from '@modules/planning/api';
import { Dictionary, IndexedArray, Plan, Vehicle } from '@common/types';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { PlanTotal } from '@common/types/dictionaries/plan';
import { RouteTotal } from '@common/types/dictionaries/route';
import { routesIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';

function* worker(action: PayloadAction<{ updateRoutes: boolean }>) {
  const { success, failure, fulfill } = planningUpdatePlansAndRoutesAction;

  try {
    const { updateRoutes } = action.payload;
    const planID: Plan['ID'] = yield select(activePlanIDSelector);

    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });
    const payload: {
      planTotal?: IndexedArray<PlanTotal>;
      routeTotal?: IndexedArray<RouteTotal>;
    } = {
      planTotal
    };

    if (updateRoutes) {
      const vehicleID: Array<Vehicle['ID']> = yield select(routesIDsSelector);
      const {
        data: { data: routeTotal }
      } = yield call(PlanningAPI.getTotal, { planID, vehicleID, dictionary: Dictionary.Route });
      payload.routeTotal = routeTotal;
    }

    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningUpdatePlansAndRoutesWatcher() {
  yield takeLatest(planningUpdatePlansAndRoutesAction.trigger, worker);
}
