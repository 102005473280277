import { TabAction } from '@common/types/components';
import { SyntheticEvent, useMemo, useState } from 'react';

type UseCargo = {
  disabled: boolean;
  actionCargo: string[];
};

export const useCargo = ({ disabled, actionCargo }: UseCargo) => {
  const [cargos, setCargos] = useState<Array<{ label: string; key: string; closable?: boolean }>>([
    {
      label: 'Груз №1',
      key: '1',
      closable: false
    }
  ]);

  useMemo(() => {
    setCargos((prevCargos) => {
      if (actionCargo.length >= prevCargos.length) {
        return actionCargo.map((_, index) => {
          return {
            label: `Груз №${index + 1}`,
            key: String(index + 1),
            closable: index ? true : false
          };
        });
      }
      return prevCargos;
    });
  }, [actionCargo]);

  const handleSetCargos = (cargoKey?: string) => {
    if (disabled) return;
    setCargos((prevCargos) => {
      const currentCargos = [...prevCargos];
      if (cargoKey) return currentCargos.filter((cargo) => cargo.key !== cargoKey);
      const nextCargoID = currentCargos.length + 1;
      currentCargos.push({
        label: `Груз №${nextCargoID}`,
        key: String(nextCargoID)
      });
      return currentCargos;
    });
  };

  const onEdit = (cargoKey: string | SyntheticEvent, action: 'add' | 'remove') => {
    if (action === TabAction.Add) {
      handleSetCargos();
    } else if (typeof cargoKey === 'string') {
      handleSetCargos(cargoKey);
    }
  };

  return { onEdit, cargos };
};

