import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

export const tenderSearchRoutine = createRoutine('TENDER/SEARCH');
export const tenderWithMyParticipationRoutine = createRoutine('TENDER/WITH_MY_PARTICIPATION');
export const tenderNotifySettingsRoutine = createRoutine('TENDER/NOTIFY_SETTINGS');
export const getTenderNotifySettingsRoutine = createRoutine('TENDER/GET_TENDER_NOTIFY_SETTINGS');

export const tenderFilterRoutine = createRoutine('TENDER/FILTER')
export const removeTenderFilterRoutine = createRoutine('TENDER/REMOVE_FILTER')

export const tenderOfferIndexRoutine = createRoutine('TENDER_OFFER/INDEX');
export const tenderOfferCreateRoutine = createRoutine('TENDER_OFFER/CREATE');
export const tenderOfferUpdateRoutine = createRoutine('TENDER_OFFER/UPDATE');
export const tenderOfferDeleteRoutine = createRoutine('TENDER_OFFER/DELETE');
export const tenderOfferSetStatusRoutine = createRoutine('TENDER_OFFER/SET_STATUS');

export const tenderOfferAppointResponsibleRoutine = createRoutine('TENDER_OFFER/APPOINT_RESPONSIBLE');

export const tenderRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  tenderSearchRoutine,
  tenderWithMyParticipationRoutine,
  tenderNotifySettingsRoutine,
  getTenderNotifySettingsRoutine,
  tenderOfferIndexRoutine,
  tenderOfferCreateRoutine,
  tenderOfferUpdateRoutine,
  tenderOfferDeleteRoutine,
  tenderOfferSetStatusRoutine,
  tenderOfferAppointResponsibleRoutine
];
