import { NEW_WORKTIME } from '@common/models/worktime';
import { generateUUID } from '@common/utils/general/uuid';
import { DriverRestMode } from '@common/types';
import { DriverRestModeLabel } from '@common/types/dictionaries/driver';

export const REST_MODE_OPTIONS = [
  {
    value: DriverRestMode.RestOff,
    label: DriverRestModeLabel.RestOff
  },
  {
    value: DriverRestMode.WithoutUploading,
    label: DriverRestModeLabel.WithoutUploading
  },
  {
    value: DriverRestMode.RestDuringUploading,
    label: DriverRestModeLabel.RestDuringUploading
  },
  {
    value: DriverRestMode.WorkDuringUploading,
    label: DriverRestModeLabel.WorkDuringUploading
  }
];

export const NEW_VEHICLE_TIMELINE = {
  ...NEW_WORKTIME,
  VehicleTimelineCode: generateUUID()
};
