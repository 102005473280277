import { DateTimeFormat } from '@common/types';
import classNames from 'classnames';
import { pick } from 'lodash';

type MarkerPopupData = {
  ArrivalTime?: string | number | null;
  Title?: string | null;
  OrderCode?: string | number | null;
  SourceAddress?: string | null;
  TargetAddress?: string | null;
  SourceClient?: string | null;
  TargetClient?: string | null;
  Mass?: number | string | null;
  Pallets?: string | number | null;
  CargoCount?: string | number | null;
  UnloadDate?: string | number | null;
  LoadStart?: string | number | null;
  LoadEnd?: string | number | null;
  UnloadStart?: string | number | null;
  UnloadEnd?: string | number | null;
  TargetUnloadStart?: string | number | null;
  TargetUnloadEnd?: string | number | null;
  TargetLoadEnd?: string | number | null;
  TargetLoadStart?: string | number | null;
  PointText?: string | null;
  RouteText?: string | null;
  ClientText?: string | null;
  Address?: string | null;
  EndDate?: string | null;
  DistanceFromPrevPoint?: string | number | null;
  Code?: number | string | null;
  Restriction?: any[] | null;
  Delay?: string | number | null;
  WorktimeEnd?: string | number | null;
  WorktimeStart?: string | number | null;
  TimeZone?: string | number | null;
};

type ItemConfig = {
  label: string;
  classesLabel?: string;
};
type WorktimeTipValue = {
  LoadStart?: string | number | null | undefined;
  LoadEnd?: string | number | null | undefined;
  UnloadStart?: string | number | null | undefined;
  UnloadEnd?: string | number | null | undefined;
};

type ItemConfigRow = ItemConfig & { classesValue?: string; valueSufix?: string };

type ItemConfigWorktimeRow = ItemConfig & { valueCreator: (data: MarkerPopupData) => WorktimeTipValue };

type RowItemConfig = Record<string, ItemConfigRow>;

type WorktimeItemConfig = Record<string, ItemConfigWorktimeRow>;

const OrderItem_FirstColumn: RowItemConfig = {
  SourceAddress: {
    label: 'Адрес погрузки',
    classesLabel: 'short',
    classesValue: 'ellipsis-value-two-line'
  },
  TargetAddress: {
    label: 'Адрес разгрузки',
    classesLabel: 'short',
    classesValue: 'ellipsis-value-two-line'
  },
  SourceClient: {
    label: 'Клиент погрузки',
    classesLabel: 'short',
    classesValue: 'ellipsis-value-two-line'
  },
  TargetClient: {
    label: 'Клиент разгрузки',
    classesLabel: 'short',
    classesValue: 'ellipsis-value-two-line'
  },
  UnloadDate: {
    label: 'Дата доставки',
    classesLabel: 'short'
  }
};
const OrderItem_SecondColumn: RowItemConfig = {
  Mass: {
    label: 'Вес',
    valueSufix: ' кг',
    classesLabel: 'short'
  },
  Pallets: {
    label: 'Паллет',
    valueSufix: ' шт',
    classesLabel: 'short'
  },
  CargoCount: {
    label: 'Кол-во грузов',
    valueSufix: ' шт',
    classesLabel: 'short'
  }
};
const OrderItem_SecondColumn_Worktimes: WorktimeItemConfig = {
  Order: {
    label: 'Режим работы заявки',
    classesLabel: 'short',
    valueCreator: (data: MarkerPopupData): WorktimeTipValue => pick(data, 'LoadEnd', 'LoadStart', 'UnloadStart', 'UnloadEnd')
  },
  Target: {
    label: 'Окно работы точки разгрузки',
    classesLabel: 'short',
    valueCreator: ({ TargetLoadStart, TargetLoadEnd, TargetUnloadEnd, TargetUnloadStart }: MarkerPopupData): WorktimeTipValue => ({
      LoadEnd: TargetLoadEnd,
      LoadStart: TargetLoadStart,
      UnloadStart: TargetUnloadStart,
      UnloadEnd: TargetUnloadEnd
    })
  }
};

const createRows = ({ config, data }: { config: RowItemConfig; data: MarkerPopupData }) =>
  Object.entries(config)
    .map(([key, confItem]) => createRowItem({ ...confItem, value: data?.[key] }))
    .join('\n');

const createWorktimeRows = ({ config, data }: { config: WorktimeItemConfig; data: MarkerPopupData }) =>
  Object.entries(config)
    .map(([_, confItem]) => createRowItem_Worktime({ ...confItem, value: confItem.valueCreator(data) }))
    .join('\n');

const UTC_TIME_OFFSET = 5;
/** Для того чтобы оставить только дату в формате без дополнительного текста  */
const sliceLongFormatDate = (date: string | number) =>
  date.toString().slice(0, DateTimeFormat.DDMMYYYYPeriodHHMMColonSpaceZ.length + UTC_TIME_OFFSET);

export const orderTip = (data: MarkerPopupData) =>
  `
		<div class="point-tip font-12">
    <div class = "ellipsis-value-two-line mb-1" > <p class="title">${data.Title ?? ''}</p></div>
			
			<div class="row">
				<div class="label short">Код заявки:</div>
				<div class="value">${data.OrderCode ?? ''}</div>
			</div>
      <div class="row">
        <div class="column">
          ${createRows({ config: OrderItem_FirstColumn, data })}
        </div>
        <div class="column">
          ${createRows({ config: OrderItem_SecondColumn, data })}
          ${createWorktimeRows({ config: OrderItem_SecondColumn_Worktimes, data })}
        </div>
      </div>
		</div>
	`;

//TODO нужен рефакторинг
export const pointTip = (data: MarkerPopupData) => {
  const Worktime = createWorktime(data);

  return `
		<div class="point-tip font-13">
			<div class="title mb-1">${data.PointText ?? ''} ${data.RouteText ?? ''}</div>
			<div class="title mb-1">${data.ClientText ?? ''}</div>
			<div class="mb-1">${data.Address ?? ''}</div>
      ${Worktime}
			${
        data.ArrivalTime
          ? `<div class="row">
              <div class="label short">Прибытие:</div>
              <div class="value last">${sliceLongFormatDate(data.ArrivalTime)}</div>
            </div>`
          : ''
      }
			${
        data.TimeZone
          ? `<div class="row">
              <div class="label short">Часовой пояс:</div>
              <div class="value last">${data.TimeZone}</div>
            </div>`
          : ''
      }
			${
        data.EndDate
          ? `<div class="row">
              <div class="label short">Выезд:</div>
              <div class="value">${sliceLongFormatDate(data.EndDate)}</div>
            </div>`
          : ''
      }
      <div class="row">
        <div class="label">Вес(разгр.):</div>
        <div class="value last">${data.Mass} кг</div>
       </div>
      <div class="row">
        <div class="label">Паллет(разгр.):</div>
        <div class="value last">${data.Pallets} шт</div>
      </div>
			<div class="row">
				<div class="label">Расстояние до предыдущей:</div>
				<div class="value last">${data.DistanceFromPrevPoint ?? ''} км</div>
			</div>
		</div>
	`;
};

export const depotTip = (data: MarkerPopupData) =>
  `
		<div class="point-tip font-13">
			<div class="title mb-1">${data.PointText ?? ''}</div>
			<div class="mb-1">${data.Address ?? ''}</div>
			${
        data.Code
          ? `<div class="row">
					<div class="label short">Код:</div>
					<div class="value">${data.Code}</div>
				</div>`
          : ''
      }
			${
        data.Delay
          ? `<div class="row">
					<div class="label short">Задержка:</div>
					<div class="value">${data.Delay}</div>
				</div>`
          : ''
      }
			${
        data.Restriction && data.Restriction.length > 0
          ? `<div class="row">
					<div class="label short">Специфики:</div>
					<div class="value">${data.Restriction.join(', ')}</div>
				</div>`
          : ''
      }
		</div>
	`;

const createRowItem = ({
  label,
  value,
  valueSufix,
  classesLabel,
  classesValue
}: ItemConfigRow & {
  value?: string | number | null | undefined;
}) => `
<div class="row">
  <div class="${classNames('label', classesLabel)}">${label}:</div>
  <div class = "${classNames(classesValue)}"><p class="value" title="${value}">${value ? value + (valueSufix ?? '') : ''}</p></div>
</div>
`;

const createRowItem_Worktime = ({
  label,
  value: { LoadStart, LoadEnd, UnloadEnd, UnloadStart },
  classesLabel
}: ItemConfig & {
  value: WorktimeTipValue;
}) => `
<div class="row">
  <div class="${classNames('label', classesLabel)}">${label}:</div>
  <div class="value">
    <div>
      погрузка: ${LoadStart ?? ''} - ${LoadEnd ?? ''}
    </div>
    <div>
      разгрузка: ${UnloadStart ?? ''} - ${UnloadEnd ?? ''}
    </div>
  </div>
</div>
`;

const createWorktime = (data: WorktimeTipValue) =>
  data.UnloadStart || data.UnloadEnd || data.LoadStart || data.LoadEnd
    ? `
      <div class="row head">
        <div class="label short">Окно работы:</div>
        <div class="value first">Начало</div>
        <div class="value last">Конец</div>
      </div>
${
  data.UnloadStart || data.UnloadEnd
    ? `<div class="row">
          <div class="label short">Разгрузка:</div>
          <div class="value first">${data.UnloadStart}</div>
          <div class="value last">${data.UnloadEnd}</div>
        </div>`
    : ''
}
${
  data.LoadStart || data.LoadEnd
    ? `<div class="row">
          <div class="label short">Погрузка:</div>
          <div class="value first">${data.LoadStart}</div>
          <div class="value last">${data.LoadEnd}</div>
        </div>`
    : ''
}  `
    : '';
