import { MapMarkerSelectType } from "@common/types";
import { regexSelect, regexPartiallySelected } from "./marker-selected-regexp";

export const getSelectedTypeResolved = (layer: L.Marker): MapMarkerSelectType => {
  const curIcon = (layer?.getIcon() as L.DivIcon).options.html;
  if (typeof curIcon === 'string') {
    const matchSelect = curIcon.match(regexSelect);
    const matchPartiallySelected = curIcon.match(regexPartiallySelected);
    if (matchPartiallySelected?.length && matchPartiallySelected[0].length + 1 > MapMarkerSelectType.PartiallySelected.length) {
      return MapMarkerSelectType.PartiallySelected;
    } else if (matchSelect?.length && matchSelect[0].length + 1 > MapMarkerSelectType.Selected.length) {
      return MapMarkerSelectType.Selected;
    }
  } else if (typeof curIcon === 'object') {
    if (curIcon.classList.contains(MapMarkerSelectType.PartiallySelected)) {
      return MapMarkerSelectType.PartiallySelected;
    } else if (curIcon.classList.contains(MapMarkerSelectType.Selected)) {
      return MapMarkerSelectType.Selected;
    }
  }
  return MapMarkerSelectType.None;
};

