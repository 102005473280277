import { Notify, TableRecord } from '@common/types';
import { IILSCellEmailProps } from '@common/types/components/table/cell';
import { ILSInput, ILSUserNotify } from '@components/index';
import { InputRef } from 'antd';
import { isEmpty, isNull, isString } from 'lodash';
import { KeyboardEvent, useRef } from 'react';
import { MAIL_FORMAT } from '@common/constants/regex';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { EMPTY_STRING } from '@common/constants';

export const ILSCellEmail = <R extends TableRecord>({ children, toggleEdit, save }: IILSCellEmailProps<R>): JSX.Element => {
  const [node, email = EMPTY_STRING] = children;
  const inputRef = useRef<InputRef | null>(null);

  if (!isString(email) && !isNull(email)) {
    showUserAndDevError({ error: 'email должен быть строкой' });
    return <></>;
  }

  const handleSave = (email: string) => {
    if (email.match(MAIL_FORMAT) || !email) {
      save(email || null);
    } else {
      ILSUserNotify(Notify.Error, 'Некорректный E-mail', 3);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const currentEmail: string = inputRef.current?.input?.value ?? email ?? EMPTY_STRING;
    const allowed_keys = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace', 'Shift', 'CapsLock', '.', '_', '-', '@'];
    const allowed_letters = /^[a-zA-Z]*$/;
    const key = e.key;
    const condition = (key >= '0' && key <= '9') || allowed_keys.includes(key) || allowed_letters.test(key);

    if (
      !condition ||
      (key === '@' && currentEmail?.includes('@')) ||
      (isEmpty(currentEmail) && key === '.') ||
      (isEmpty(currentEmail) && key === '@')
    ) {
      e.preventDefault();
    }
  };

  const onSave = (e: any) => {
    handleSave(e.target.value);
    toggleEdit?.();
  };

  return (
    <>
      {node}
      <ILSInput
        ref={inputRef}
        className={'cell-wrapper-editable-full'}
        autoFocus
        type={'email'}
        defaultValue={email ?? EMPTY_STRING}
        pattern={MAIL_FORMAT}
        onPressEnter={onSave}
        onBlur={onSave}
        bordered
        allowClear={true}
        onKeyDown={handleOnKeyDown}
      />
    </>
  );
};
