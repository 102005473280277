import { Typography } from 'antd';
import { FC } from 'react';
import { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

interface IProps extends Partial<TextProps> {
  onDoubleClick?(): void;
}

export const ILSTypographyText: FC<IProps> = ({ ellipsis = true, ...props }) => {
  return <Text ellipsis={ellipsis} {...props} />;
};

export { ILSTypographyLink } from './link';
export { ILSTypographyTitle } from './title';
export { ILSTypographyParagraph } from './paragraph';
