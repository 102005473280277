import { EMPTY_STRING, PLUS_CHAR } from '@common/constants';
import {
  PaymentBalanceHistoryCellType,
  PaymentBalanceHistoryModified,
  PaymentBalanceHistoryTransaction
} from '@common/feature/payment/types/general';
import { PAYMENT_BALANCE_HISTORY_CELL_TITLES } from '@common/feature/payment/constants/general';

export const transactionDataToRow = (transaction: PaymentBalanceHistoryModified): PaymentBalanceHistoryCellType[] => {
  return Object.keys(PAYMENT_BALANCE_HISTORY_CELL_TITLES).reduce((transactionRow, transactionKey) => {
    const transactionCell = {
      className: EMPTY_STRING,
      content: transaction[transactionKey]
    };

    switch (transactionKey) {
      case PaymentBalanceHistoryTransaction.tripID:
        transactionCell.className = 'payment-balance-history-cell_number';
        break;
      case PaymentBalanceHistoryTransaction.balanceOffset:
        transactionCell.className = transaction[transactionKey].startsWith(PLUS_CHAR)
          ? 'payment-balance-history-cell_fill-account'
          : 'payment-balance-history-cell_write-off';
        break;
    }

    transactionRow.push(transactionCell);
    return transactionRow;
  }, [] as PaymentBalanceHistoryCellType[]);
};
