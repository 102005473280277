import { CARGO_TABLE_COLUMNS } from '@common/models/cargo';
import { ORDER_TABLE_CONFIG } from '@common/models/order/config';
import { TableColumnName, TableConfig } from '@common/types';
import { setActiveTableColumns } from '@modules/orders-and-trips/helpers/set-active-table-columns';

const EXCLUDE_COLUMNS = [
  TableColumnName.LoadEnd,
  TableColumnName.UnloadDate,
  TableColumnName.UnloadStart,
  TableColumnName.UnloadEnd,
  TableColumnName.SourceID,
  TableColumnName.TargetID
];
const INCLUDE_COLUMNS = [
  TableColumnName.SourceAddress,
  TableColumnName.RestrictionID,
  TableColumnName.Amount,
  TableColumnName.Mass,
  TableColumnName.Volume
];

const SOURCE_TABLE_COLUMNS = [...ORDER_TABLE_CONFIG.columns, ...CARGO_TABLE_COLUMNS];

export const orderTableConfigForOrdersNTrips: TableConfig = {
  ...ORDER_TABLE_CONFIG,
  adding: false,
  deleting: true,
  selecting: true,
  reloading: false,
  multiEditing: false,
  columns: SOURCE_TABLE_COLUMNS.map((column) => ({
    ...column,
    editable: false,
    active: setActiveTableColumns({ column, excludeColumns: EXCLUDE_COLUMNS, includeColumns: INCLUDE_COLUMNS })
  }))
};

export const orderTableConfigOnlyForTrips: TableConfig = {
  ...orderTableConfigForOrdersNTrips,
  selecting: false
};
