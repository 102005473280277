import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringDeleteVehicleTrackerLocalRoutine } from '../actions';

// TODO изучить безопасность
// Удаляет строчку в таблице привязок трекеров
export const monitoringDeleteVehicleTrackerLocalReducer = createReducer(initialState, {
  [monitoringDeleteVehicleTrackerLocalRoutine.SUCCESS]: (state) => {
    state.vehicleTrackers.data.pop();
    return state;
  },
  [monitoringDeleteVehicleTrackerLocalRoutine.FAILURE]: (state) => {
    return state;
  }
});
