import { ReactElement, ReactNode } from 'react';
import { Redirect, Switch } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { AnalyticsRoleWarning } from '@modules/analytics/common/container/warning/role-warning';

interface IProps {
  route: {
    component: ReactNode | JSX.Element | ReactElement;
    routes: RouteConfig[];
  };
}

const AnalyticsPlanning = (props: IProps) => {
  return (
    <AnalyticsRoleWarning>
      <Switch>
        <Redirect from={'/analytics-planning'} exact={true} to={'/analytics-planning/boards'} />
      </Switch>
      {/* child routes won't render without this */}
      {renderRoutes(props.route.routes)}
    </AnalyticsRoleWarning>
  );
};

export default AnalyticsPlanning;
