import { TrackActiveData } from '@common/types';
import { useRef } from 'react';
import { MarkerItem, PolylineItem } from '../../types';

type UseDragMarkerRefDataArg = {
  mapMarkers: Record<string, MarkerItem>;
  mapPolylines: Record<string, PolylineItem>;
  polylineDataIDs: TrackActiveData;
};

export const useDragMarkerRefData = ({ mapMarkers, mapPolylines, polylineDataIDs }: UseDragMarkerRefDataArg) => {
  const mapMarkersRef = useRef(mapMarkers);
  mapMarkersRef.current = mapMarkers;

  const mapPolylinesRef = useRef(mapPolylines);
  mapPolylinesRef.current = mapPolylines;

  const polylineDataIDsRef = useRef(polylineDataIDs);
  polylineDataIDsRef.current = polylineDataIDs;

  return { mapMarkersRef, mapPolylinesRef, polylineDataIDsRef };
};
