import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { deepEqual } from 'fast-equals';
import { FC, useEffect, useState } from 'react';
import { DashboardBDDMMetrics, DEFAULT_METRICS } from '../../constants';
import { DashboardBDDMetrics } from '../../types';
import { dashboardBDDTabs } from './configs/table-metrics';
import { bddMetricsDecorator } from './utils/bdd-metrics';

const headerDiv = (dashboardName: DashboardBDDMMetrics, data: DashboardBDDMetrics) => (
  <div className="header">
    {data?.[dashboardName].total}
    {data?.[dashboardName].unviewed > 0 ? <sup className="header-index">{data?.[dashboardName].unviewed}</sup> : null}
  </div>
);

interface IProps {
  dataSource: IndexedArray<MonitoringBDDAndFuelDrainModel>;
  title: string;
}

// Компонент табов с метриками
//@todo сводная информация для дочерней таблицы,
export const ILSMonitoringDashboardTableMetrics: FC<IProps> = ({ title, dataSource }) => {
  const [metrics, setMetrics] = useState({ ...DEFAULT_METRICS });
  useEffect(() => {
    const metricsNew = bddMetricsDecorator(dataSource);
    if (!deepEqual(metricsNew, metrics)) {
      setMetrics(metricsNew);
    }
  }, [dataSource]);

  const tabs = Object.keys(dashboardBDDTabs).map((dashboardName) => {
    return (
      <div className={`ils-monitoring-metrics-tab`} key={dashboardName}>
        {headerDiv(dashboardName as DashboardBDDMMetrics, metrics)}
        <div className="sub">{dashboardBDDTabs[dashboardName].subTitle}</div>
      </div>
    );
  });

  return (
    <div className="ils-monitoring-bdd-metrics">
      <h2 className="ils-monitoring-bdd-metrics-title">Сводная информация</h2>
      <div className="ils-monitoring-bdd-metrics-tabs">{tabs}</div>
    </div>
  );
};
