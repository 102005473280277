import { FC } from 'react';
import { ILSButtonTooltip, ILSUserConfirm } from '@common/components';
import { DriverTableButtons } from '@modules/catalog/constants/tables/mobile-application-buttons';
import { EMPTY_STRING } from '@common/constants';
import { HandleMobileApplicationAction } from '@modules/catalog/types/functions';
import { Driver } from '@common/types';

interface IProps {
  disabled: boolean;
  selectedDrivers: Array<Driver['ID']>;
  handleMobileApplicationAction: HandleMobileApplicationAction;
}

export const CatalogDeleteDriverFromMobileApplicationButtonComponent: FC<IProps> = ({
  disabled,
  handleMobileApplicationAction,
  selectedDrivers
}) => {
  const handleClick = () => {
    ILSUserConfirm({
      text: 'Вы действительно хотите удалить выбранных водителей из списка пользователей мобильным приложением?',
      onConfirm: handleMobileApplicationAction,
      confirmParams: { operation: DriverTableButtons.Delete, driverIDs: selectedDrivers },
      onConfirmText: `Удалить ${selectedDrivers.length ?? EMPTY_STRING}`,
      onCloseText: 'Нет'
    });
  };

  return (
    <ILSButtonTooltip
      children={'Удалить регистрацию'}
      disabled={disabled}
      title={'Система удалит данного водителя из списка пользователей мобильным приложением'}
      onClick={handleClick}
    />
  );
};
