import { put, takeLatest } from 'redux-saga/effects';
import { dataClearRoutine } from '@modules/planning/children/data/actions';

function* dataClearWorker() {
  const { success, failure } = dataClearRoutine;

  try {
    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* dataClearWatcher() {
  yield takeLatest(dataClearRoutine.trigger, dataClearWorker);
}
