import { Notify, NotifyDurationInSecond, PlanStatus, VehicleUseMode } from '@common/types';
import { ErrorVariants, MAX_COUNT_ROUTES_TO_ADD } from '../constants/to-tender';
import { ValidateFuncForButtonType } from '../types/tender-from-planner';
import { ILSUserNotify } from '@components/index';

export const checkRouteAdd: ValidateFuncForButtonType = ({ routes }) => {
  /**
   * Есть ли хоть маршрут со статусом большим или равным статусу исполнения
   */
  const RouteStatusError = !routes?.every((route) => Number(route.Status) < Number(PlanStatus.OnExecution));
  /**
   * Есть ли хоть одна реальная машина
   */
  const VehicleUseModeError = !!routes?.find((route) => Number(route?.Vehicle?.UseMode === VehicleUseMode.AsReal));

  if (RouteStatusError) {
    return ErrorVariants.RouteStatusOnExecute;
  }
  if (VehicleUseModeError) {
    return ErrorVariants.VehicleIsReal;
  }

  return ErrorVariants.NoError;
};

type checkBeforeType = (arg: { rowsAmount: number }) => (() => void) | undefined;

export const checkBeforeModalRouteAdd: checkBeforeType = ({ rowsAmount }) => {
  if (!rowsAmount) return;
  return rowsAmount > MAX_COUNT_ROUTES_TO_ADD
    ? () =>
        ILSUserNotify(
          Notify.Warning,
          `Максимальное кол-во маршрутов для импорта - ${MAX_COUNT_ROUTES_TO_ADD}`,
          NotifyDurationInSecond.Three,
          'PlannerTenderMaxRoutesWarning'
        )
    : undefined;
};
