import { LatLngTuple } from 'leaflet';
import { PointEventAbstract, PolyLineInit } from '../../types/map';

// Утилита создания многоугольника из точек
export const pointsToPolylineInit = (
  from: PointEventAbstract,
  to: PointEventAbstract,
  popupInfo: Array<string> | string,
  routeColor: string | null = 'red'
): Array<PolyLineInit> => {
  const coords = [[from.Latitude, from.Longitude] as LatLngTuple, [to.Latitude, to.Longitude] as LatLngTuple];

  const info = Array.isArray(popupInfo) ? popupInfo.map((text) => `<div>${text}</div>`).join('') : popupInfo;

  return [
    {
      coords,
      handlers: {},
      options: {
        color: routeColor || 'red',
        dashArray: null
      },
      popup: [info]
    }
  ];
};
