import { IMonitoringStore } from '@modules/monitoring/types';

// Утилита, которая собирает данные по рейсу выбранного автомобиля
export const collectVehicleTrip = (
  VehicleEventsData: IMonitoringStore['MonitoringEventDetails']['VehicleEvents']['data'],
  selectedVehicleId: number | undefined
) => {
  if (!selectedVehicleId || !VehicleEventsData) return {};
  return Object.values(VehicleEventsData)?.reduce((acc, cur) => {
    if (cur.ID === selectedVehicleId && cur?.Trip) {
      for (const tripID in cur.Trip) {
        acc[tripID] = { ...cur.Trip[tripID], ForbiddenZones: cur.ForbiddenZoneID };
      }
    }
    return acc;
  }, {});
};
