import reduceReducers from 'reduce-reducers';
import initialState from '@modules/catalog/store';
import readDictionaryReducer from '@modules/catalog/reducers/read';
import createDictionaryReducer from '@modules/catalog/reducers/create';
import createLocalReducer from '@modules/catalog/reducers/create-local';
import updateDictionaryReducer from '@modules/catalog/reducers/update';
import multipleUpdateDictionaryReducer from '@modules/catalog/reducers/multiple-update';
import mapStatusReducer from '@modules/catalog/reducers/map';
import getCoordinatesReducer from '@modules/catalog/reducers/get-coordinates';
import restoreDictionaryReducer from '@modules/catalog/reducers/restore';
import deleteDictionaryReducer from '@modules/catalog/reducers/delete';
import bindTimeZoneDictionaryReducer from '@modules/catalog/reducers/bind-timezone';
import cloneDictionaryReducer from '@modules/catalog/reducers/copy-row';
import deleteLocalDictionaryReducer from '@modules/catalog/reducers/delete-local';
import resetCatalogInfoReducer from '@modules/catalog/reducers/reset-catlog-info';
import { getTariffsReducer } from '@modules/catalog/reducers/tariff';
import { catalogSetFocusRowReducer } from '@modules/catalog/reducers/set-focus-row';

const catalogReducer = reduceReducers(
  initialState,
  createDictionaryReducer,
  createLocalReducer,
  deleteDictionaryReducer,
  getCoordinatesReducer,
  mapStatusReducer,
  readDictionaryReducer,
  restoreDictionaryReducer,
  updateDictionaryReducer,
  multipleUpdateDictionaryReducer,
  bindTimeZoneDictionaryReducer,
  deleteLocalDictionaryReducer,
  cloneDictionaryReducer,
  resetCatalogInfoReducer,
  getTariffsReducer,
  catalogSetFocusRowReducer
);

export default catalogReducer;
