import { TRANSPORT_COMPANY_IMG } from '@modules/orders-and-trips/constants/img';

export const ProposalEmptyImage = () => {
  const availableCompanies = Object.values(TRANSPORT_COMPANY_IMG);

  return (
    <div className={'proposal-empty-image'}>
      {availableCompanies.map((src) => {
        return <img key={src} src={src} width={130} height={42} alt={`Логотип`} />;
      })}
    </div>
  );
};
