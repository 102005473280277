import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { ILSDatePicker } from '@components/index';
import { FC, useCallback, useState } from 'react';
import { disabledDate } from '@common/utils/helpers/date-time/date-time';
import { PlanningAvailableDates } from '@modules/planning/types';
import { DateFormat, Status } from '@common/types';
import { useSelector } from 'react-redux';
import { availableDatesSelector } from '@modules/planning/selectors';
import { renderAvailableDates } from '@components/data-entry/datepicker/render-available-dates';
import { fetchSelector, projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';

export interface IPlanningDateProps {
  isDateLoading: boolean;
  availableDates?: PlanningAvailableDates;
  currentPickedDate?: Moment;
  status?: Status.Error | Status.Warning | undefined;

  onMonthChange(date: Moment): void;

  onDatePick(date: Moment): void;
}

export const PlannerProjectsDatePicker: FC<IPlanningDateProps> = ({
  onMonthChange,
  currentPickedDate,
  isDateLoading,
  status,
  onDatePick
}) => {
  const projectIsFetching = useSelector(projectIsFetchingSelector);
  const availableDates = useSelector(availableDatesSelector);
  const isFetching = useSelector(fetchSelector);

  const [currentData, setCurrentData] = useState<Moment | undefined | null>(moment(currentPickedDate));

  const onPanelChange = (date: Moment) => {
    onMonthChange(date);
    setCurrentData(date);
  };

  //NOTE Если кликаем на туже дату сами, то логика как будто дату поставили новую
  const onSelectHandler = useCallback(
    async (date: Moment | null) => {
      if (date) {
        await new Promise<Moment>((resolve) => {
          onPanelChange(date);
          resolve(date);
        });
        onDatePick(date);
      }
      setCurrentData(date ?? null);
    },
    [currentPickedDate]
  );

  const onOpenChange = (open: boolean) => {
    if (open && currentPickedDate) {
      onPanelChange(currentPickedDate);
    } else {
      setCurrentData(currentPickedDate);
    }
    setCurrentData((prevDate) => (!currentData ? null : prevDate));
  };

  const handleDisable = (current: Moment) => disabledDate(current, availableDates, false);

  return (
    <ILSDatePicker
      disabled={Boolean(isFetching || projectIsFetching)}
      onPanelChange={onPanelChange}
      onSelect={onSelectHandler}
      onOpenChange={onOpenChange}
      isLoading={isDateLoading}
      locale={locale}
      value={currentData}
      defaultValue={moment(currentPickedDate)}
      defaultPickerValue={moment(currentPickedDate)}
      format={DateFormat.DDMMYYYYPeriod}
      disabledDate={handleDisable}
      dateRender={(current) => renderAvailableDates(current, availableDates)}
      dropdownClassName="current-month-only"
      status={status}
    />
  );
};
