import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { Dictionary } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { useAppSelector } from '@core/hooks';
import L, { LatLngExpression } from 'leaflet';
import { isEmpty, isString } from 'lodash';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { monitoringLayerSelector } from '../selectors/catalog';
import { LayerItemMarker, LayerItemPolygon, LayerMenu } from '../types/layer';
import { monitoringUseDictionaryLoadHandler } from './use-load-dictionary';

/**
 * Хук для использования слоев карты
 * activeLayers - ID активных слоев карты
 * setActiveLayers - устанавливает активные слои по ID (передавать полностью весь список)
 * handleDictionaryLoad - При необходимости загрузит данные с сервера для отображения слоев
 */
export const useMapLayer = ({ Map, mapLayersGroup }: { Map: MutableRefObject<any>; mapLayersGroup: L.LayerGroup | undefined }) => {
  const { handleDictionaryLoad } = monitoringUseDictionaryLoadHandler();
  const { layerData } = useAppSelector(monitoringLayerSelector);

  // const [activeLayers, setActiveLayers] = useState<number[]>([]);
  const [layerMenu, setLayerMenu] = useState<LayerMenu[]>(
    layerData.map((layer) => {
      return {
        active: false,
        ID: layer.ID,
        isEmpty: isEmpty(layer.data),
        name: layer.name
      };
    })
  );
  const setActiveLayers: (layersIds: number[]) => void = (layersIds) =>
    setLayerMenu((prev) => {
      const newLayerMenu = prev.slice();
      return newLayerMenu.map((menuItem) => {
        menuItem.active = layersIds.includes(menuItem.ID);
        return menuItem;
      });
    });
  const activeLayers: number[] = useMemo(() => {
    return layerMenu.reduce((acc: number[], cur) => {
      if (cur.active) {
        acc.push(cur.ID);
      }
      return acc;
    }, []);
  }, [layerMenu]);

  const loadDictionaries: () => void = useCallback(() => {
    handleDictionaryLoad(Dictionary.Zone);
  }, []);

  const visibleLayers = useMemo(() => {
    const markers: LayerItemMarker[] = [];
    const polygons: LayerItemPolygon[] = [];
    for (let layer of layerMenu) {
      if (layer.active) {
        const activeLayer = layerData.find((layerDataItem) => layerDataItem.ID === layer.ID);
        if (!isEmpty(activeLayer?.data?.markers) && activeLayer?.data?.markers instanceof Array) {
          markers.push(...activeLayer.data.markers);
        }
        if (!isEmpty(activeLayer?.data?.polygons) && activeLayer?.data?.polygons instanceof Array) {
          polygons.push(...activeLayer.data.polygons);
        }
      }
    }
    return { markers, polygons, layer: mapLayersGroup };
  }, [layerMenu, layerData]);

  useMapLayersItems({ Map, mapLayersGroup, layerData: visibleLayers });

  return { activeLayers, setActiveLayers, layerMenu, handleDictionaryLoad: loadDictionaries };
};

type PolygonItem = {
  poly: LayerItemPolygon;
  lPoly?: L.Polygon;
};

type MapItems = {
  markers: LayerItemMarker[];
  polygons: LayerItemPolygon[];
  layer?: L.LayerGroup | undefined;
};

/**
 * NOTE нет эффекта для отображения маркеров.
 * Отвечает за отрисовку элементов на карте
 */
const useMapLayersItems = (x: { Map: MutableRefObject<any>; mapLayersGroup: L.LayerGroup | undefined; layerData: MapItems }) => {
  const [mapLayerPolygons, setMapLayerPolygons] = useState<{
    [layerKey: string]: {
      poly: PolygonItem['poly'];
      lPoly: L.Polygon;
      layer: L.LayerGroup | undefined;
    };
  }>({});

  useEffect(() => {
    if (x.Map) {
      const curMap: ILSMap = x.Map.current;

      const mapPolygon_n: {
        [layerKey: string]: {
          poly: PolygonItem['poly'];
          lPoly: L.Polygon;
          layer: L.LayerGroup | undefined;
        };
      } = {};
      let updatePG = false;

      const addPoly = (polygon: PolygonItem['poly']) => {
        const coords = isString(polygon.coords) ? GeohashDecodePoints(polygon.coords) : polygon.coords;
        if (curMap && !isEmpty(coords)) {
          if (!updatePG) updatePG = true;

          if (x.mapLayersGroup) {
            const lPoly = curMap.addPolygon(
              [
                coords as LatLngExpression[],
                { color: polygon.color },
                undefined,
                undefined,
                [`<div style="color:${polygon.color || 'black'};" >${polygon?.name}</div>`, { permanent: true, direction: 'top' }]
              ],
              x.mapLayersGroup
            );
            mapPolygon_n[polygon.itemKey] = {
              poly: polygon,
              lPoly: lPoly,
              layer: x.mapLayersGroup
            };
          }
        }
      };

      if (Object.keys(mapLayerPolygons).length === 0) {
        if (curMap) {
          if (!isEmpty(x.layerData?.polygons)) {
            x.layerData.polygons.forEach((polygon) => {
              if (polygon) {
                addPoly(polygon);
              }
            });
          }
        }
      } else {
        const mapPolygonCheckedKeys: string[] = [];

        Object.keys(x.layerData.polygons).forEach((polygonKey) => {
          const polygon = x.layerData.polygons[polygonKey];

          let mapPoly = mapLayerPolygons[polygon.itemKey];

          mapPolygonCheckedKeys.push(polygon.itemKey);
          if (!mapPoly && polygon) {
            addPoly(polygon);
          }
        });

        for (let layerKey in mapLayerPolygons) {
          const mItem = mapLayerPolygons[layerKey];

          if (!mapPolygonCheckedKeys.includes(layerKey)) {
            if (!updatePG) updatePG = true;
            if (mItem.layer) {
              mItem.layer.removeLayer(mItem.lPoly);
            } else {
              curMap.deleteAnyLayer(mItem.lPoly);
            }
          } else {
            mapPolygon_n[layerKey] = mItem;
          }
        }
      }
      if (updatePG) {
        setMapLayerPolygons(mapPolygon_n);
      }
    }
  }, [x.layerData.polygons]);
};

