import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { MonitoringSensorEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';

// Статусы эмуляции
export enum EmulatorStatus {
  Init = 'init',
  Emulation = 'emulation',
  Error = 'error',
  Pause = 'pause',
  Stop = 'stop',
  Finished = 'finished'
}

// Текст для нотификаций о статусе эмуляций
export const EmulatorStatusText: { [K in EmulatorStatus]: string } & { default: string } = {
  emulation: 'В процессе',
  error: 'Ошибка',
  finished: 'Эмуляция завершена',
  init: 'Инициализация',
  pause: 'Эмуляция на паузе',
  stop: 'Эмуляция прекращена',
  default: 'Не найден'
};

// Массив значений для параметра скорость эмуляции (emulationSpeed)
export const EMULATION_SPEED_VARIANTS = [
  { label: 'X1', value: 1 },
  { label: 'X2', value: 2 },
  { label: 'X5', value: 5 },
  { label: 'X10', value: 10 },
  { label: 'Мгновенно', value: 0 }
];

export const EMULATOR_UPDATE_STATUS_INTERVAL = 5000; // Интервал времени (5 секунд) через который обновляем статус эмуляции
export const EMULATOR_UPDATE_CONFIG_INTERVAL = 50000; // Интервал времени (50 секунд) через который обновляем конфигурацию эмуляции

// Нотификация завершения эмуляции
export const EMULATION_NOTIFY_FINISH: Parameters<typeof ILSUserNotify> = [
  Notify.Success,
  'Эмуляция завершена',
  NotifyDurationInSecond.Five,
  'EMULATION/FINISH'
];

// Варианты прибытия на точку
export const POINT_SERVICE_VARIANTS = [
  {
    value: '0',
    label: 'По расписанию'
  },
  {
    value: '10',
    label: 'С опозданием 10 мин'
  },
  {
    value: '-10',
    label: 'С опережением 10 мин'
  }
];

// Варианты нарушений безопасности дорожного движения
export const BDD_VARIANTS = [
  {
    value: MonitoringSensorEventStatusType.SharpAcceleration.toString(),
    label: 'Резкие ускорения'
  },
  {
    value: MonitoringSensorEventStatusType.SharpBraking.toString(),
    label: 'Резкие торможения'
  },
  {
    value: MonitoringSensorEventStatusType.SharpTurn.toString(),
    label: 'Резкие повороты'
  }
];

// Заголовки кнопок управления (action buttons)
export enum actionButtonsCaption {
  Start = 'Запустить',
  Pause = 'Пауза',
  Stop = 'Прекратить',
  Continue = 'Возобновить'
}

// Команды эмулятора
export enum emulatorCommands {
  Init = 'init',
  Pause = 'pause',
  Stop = 'stop',
  Continue = 'resume'
}
