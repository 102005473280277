import { FC, RefObject, useRef, useState } from 'react';
import classnames from 'classnames';
import CustomScroll from 'react-customscroll';
import { ILSButtonTooltip } from '@common/components';
import TableCustomConfigComponent from '@core/components/table-custom/config-item';
import { TableCustomComponentProps } from '@core/components/table-custom/index';
import { useTableConfigDragNDropScroll } from '@core/hooks/use-table-config-drag-and-drop-scroll';

//NOTE: в рамках согласовано 5 https://jira.intelogis.ru/browse/TMS-6562
const NUMBER_OF_COLUMNS_TO_SELECT_ALL = 5;

export interface ITableConfigConfigContainerProps
  extends Omit<
    TableCustomComponentProps<any>,
    'ChildTable' | 'recordTableButtons' | 'settingsTableButtons' | 'tableWidgets' | 'actionTableButtons' | 'onGrouping'
  > {
  handleShow(): void;
}

export const TableConfigConfigContainer: FC<ITableConfigConfigContainerProps> = ({ handleShow, ...props }) => {
  const { onSetDefault, config, activeColumns, onCheckboxChange } = props;
  const [dragIn, setDragIn] = useState<string>('');
  const visibleColumns = activeColumns.filter(({ invisible, dontHide }) => !invisible && !dontHide);
  const configColumns = config.columns.filter(({ invisible, dontHide }) => !invisible && !dontHide);
  //Чекбокс с изменением всех настроек
  let selectAllConfigComponent = null;
  const hasCheckAll = configColumns?.length > NUMBER_OF_COLUMNS_TO_SELECT_ALL;
  if (hasCheckAll) {
    const isAllChecked = !configColumns.find((column) => !column.active);
    const onCheckboxChangeAll = (_item: any, _childName: string) => {
      onCheckboxChange(_item, _childName, !isAllChecked);
    };
    selectAllConfigComponent = hasCheckAll && (
      <TableCustomConfigComponent
        {...props}
        // @ts-ignore
        item={{
          title: isAllChecked ? 'Снять со всех' : 'Выбрать все',
          dataIndex: '1',
          active: isAllChecked,
          fixed: true
        }}
        onCheckboxChange={onCheckboxChangeAll}
        index={1}
        key={'select-all' + isAllChecked}
        columns={config.columns}
      />
    );
  }

  const configContainer = useRef<HTMLDivElement>(null);
  const customScrollRef = useRef<
    CustomScroll & {
      customScrollHolderRef: RefObject<any>;
      scrollBlock: any;
    }
  >(null);

  useTableConfigDragNDropScroll({ configContainer, customScrollRef });

  return (
    <div ref={configContainer} className={classnames('config', { all: selectAllConfigComponent })}>
      {selectAllConfigComponent && <div className="select-all">{selectAllConfigComponent}</div>}
      <CustomScroll ref={customScrollRef}>
        {configColumns.map((item, index) => (
          <TableCustomConfigComponent
            {...props}
            item={item}
            index={index}
            key={item.dataIndex}
            dragIn={dragIn}
            setDragIn={setDragIn}
            columns={config.columns}
          />
        ))}
      </CustomScroll>
      <div className={'config-buttons'}>
        <ILSButtonTooltip
          children={'ОК'}
          title="Закрыть окно изменений"
          type="primary"
          onClick={handleShow}
          disabled={visibleColumns.length === 0}
        />
        <ILSButtonTooltip
          children={'Фильтр по умолчанию'}
          type="default"
          onClick={onSetDefault}
          title={'Возвращает настройки таблицы к установленным системой параметрам'}
        />
      </div>
    </div>
  );
};
