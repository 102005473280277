export enum TenderPlatformPaths {
  OwnList = '',
  Create = '?tender=create',
  CreateFromPlanner = '?tender=create-from-planner',
  Tender = '?tender=',
  CarriersList = '?carriers-list',
  Add = '?carriers-list=add',
  LotCreate = '&lot=create',
  Lot = '&lot=',
  Offers = `?offers=list`
}

export enum TenderForm {
  Lot = 'lot',
  Tender = 'tender',
  Offer = 'offer',
  Rate = 'rate',
  Auction = 'auction'
}
