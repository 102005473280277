import { CompassOutlined } from '@ant-design/icons/lib/icons';
import { ILSButtonTooltip } from '@common/components';
import { Keys } from '@common/types';
import { FC } from 'react';

interface IProps {
  selectedRowKeys: Keys;
  disabled: boolean;
  onClick(record: Keys): void;
}

export const ILSGetCoordinatesActionButton: FC<IProps> = ({ onClick, selectedRowKeys, disabled }) => {
  const handleClick = () => {
    onClick(selectedRowKeys);
  };
  return (
    <ILSButtonTooltip
      placement="bottom"
      disabled={disabled}
      icon={<CompassOutlined className={'ils-svg primary'} />}
      onClick={handleClick}
      title={'Получить координаты по адресу'}
    />
  );
};
