import { FC } from 'react';
import { TenderPlatformAuctionRequestInfoComponent } from '@modules/tender-platform/children/tender-search/components/auction/request-info';
import { useDispatch, useSelector } from 'react-redux';
import { tenderOfferCreateRoutine, tenderOfferUpdateRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { Driver, Vehicle } from '@common/types';
import { auctionSelector } from '@modules/tender-platform/children/tender-search/selectors/components/auction';
import { checkBeforeTenderOfferAuction } from '@modules/tender-platform/utils/helpers/check-before-tender-offer-auction';

export const TenderPlatformAuctionContainer: FC = () => {
  const dispatch = useDispatch();

  const { tenderID, tenderOfferID, Price } = useSelector(auctionSelector);

  const handleAuctionOffer = (values: { price: number; vehicleID: Vehicle['ID']; driverID: Driver['ID'] }, current: boolean) => {
    const payload = { tenderID, ...values };
    current ? dispatch(tenderOfferUpdateRoutine(payload)) : dispatch(tenderOfferCreateRoutine(payload));
  };

  const onFinish = ({ StartPrice, MinPrice, MyPrice, price, TenderOffer: { DriverID, VehicleID } }: any) => {
    const isSuccess = checkBeforeTenderOfferAuction(Price, StartPrice, MinPrice, price, { DriverID, VehicleID });
    if (isSuccess) {
      handleAuctionOffer(
        {
          price,
          vehicleID: Array.isArray(VehicleID) ? VehicleID[1] : VehicleID,
          driverID: DriverID
        },
        Boolean(tenderOfferID && MyPrice)
      );
    }
  };

  return <TenderPlatformAuctionRequestInfoComponent onFinish={onFinish} />;
};
