import { MonitoringVehicleColorDependSpeed, TrackerLastInfoModel } from '@common/types/dictionaries/monitoring-vehicle';

export const getVehicleMarkerMovementStatusColor = (
  trackerLastInfo: Partial<TrackerLastInfoModel> | undefined | null
): MonitoringVehicleColorDependSpeed => {
  if (!trackerLastInfo?.Speed && !trackerLastInfo?.IMEI) {
    return MonitoringVehicleColorDependSpeed.NoData;
  }
  const speed = trackerLastInfo?.Speed;
  const currentSpeed = Math.round(Number(speed));
  switch (true) {
    case currentSpeed >= 0 && currentSpeed <= 5:
      return MonitoringVehicleColorDependSpeed.Stop;
    case currentSpeed > 5 && currentSpeed <= 10:
      return MonitoringVehicleColorDependSpeed.VerySlow;
    case currentSpeed > 10 && currentSpeed <= 30:
      return MonitoringVehicleColorDependSpeed.Slow;
    case currentSpeed > 30 && currentSpeed < 70:
      return MonitoringVehicleColorDependSpeed.Medium;
    case currentSpeed > 70:
      return MonitoringVehicleColorDependSpeed.Hight;
    default:
      return MonitoringVehicleColorDependSpeed.NoData;
  }
};
