import { ILSModal, ILSRadioGroup, ILSSelect, ILSUserNotify } from '@common/components';
import { DOCUMENTS_EXTENSION, PermittedUploadFileExtensions } from '@common/constants/general/path';
import { Notify } from '@common/types';
import { downloadFileAsBlobLink } from '@common/utils/file/download-file-as-blob-link';
import { PlanningAPI } from '@modules/planning/api';
import { ChangeEvent, FC, useState } from 'react';
import { ExportFormatOptions, PlannerExportTypes } from '../../constants/export';
import { DefaultOptionType } from 'antd/lib/select';
import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { getErrorMessage } from '@common/utils';

interface IProps {
  showExportDataContainer: boolean;
  handleExportData: (isExport: boolean, selectData?: string | null) => void;
  currentSchemeKeys: SchemeSwitchDictionary[];
  activePlanID: number | null;
}

export const ExportDataContainer: FC<IProps> = ({ activePlanID, currentSchemeKeys, showExportDataContainer, handleExportData }) => {
  const [selectData, setSelect] = useState(null);
  const [selectExportFormat, setExportFormat] = useState(DOCUMENTS_EXTENSION.xlsx);

  const onExportData = async (key?: SchemeSwitchDictionary) => {
    if (!selectData) {
      ILSUserNotify(Notify.Error, 'Не выбрана схема для выгрузки файла!');
      return;
    }
    handleExportData(true);
    ILSUserNotify(Notify.Loading, 'Данные экспортируются...', 3);
    const exportDataKey = key ?? Object.keys(PlannerExportTypes).find((exportTypeKey) => exportTypeKey === selectData);
    if (exportDataKey && activePlanID) {
      try {
        const { data } = await PlanningAPI.dataExport({ planID: activePlanID, file: exportDataKey, format: selectExportFormat });
        downloadFileAsBlobLink({
          data,
          fileName: exportDataKey,
          fileExtension: ('.' + selectExportFormat) as PermittedUploadFileExtensions
        });
        setSelect(null);
        ILSUserNotify(Notify.Success, 'Экспорт данных успешно завершен');
      } catch (e: any) {
        ILSUserNotify(Notify.Error, getErrorMessage({ error: e }));
      }
    } else {
      ILSUserNotify(Notify.Error, 'Нет выбранных данных для экспорта');
    }
  };

  const getPlannerExportDataVariants = () => {
    return currentSchemeKeys?.reduce((result, key) => {
      if (PlannerExportTypes[key]) {
        result.push({ value: key, label: PlannerExportTypes[key] });
      }
      return result;
    }, [] as DefaultOptionType[]);
  };

  const onChangeExportFormat = (event: ChangeEvent<HTMLInputElement>) => {
    setExportFormat(event.target.value);
  };

  return (
    <ILSModal
      visible={showExportDataContainer}
      onOk={() => onExportData()}
      onCancel={() => handleExportData(false)}
      title="Экспорт результатов планирования"
      okText={'Экспортировать'}
      cancelText={'Выйти'}
    >
      <div className="planning-export">
        <h4>Выберите нужную схему для выгрузки</h4>
        <ILSSelect noPopupContainer onChange={setSelect} options={getPlannerExportDataVariants()} value={selectData} />
        <h4>Формат выгружаемого файла</h4>
        <ILSRadioGroup options={ExportFormatOptions} onChange={onChangeExportFormat} value={selectExportFormat} />
      </div>
    </ILSModal>
  );
};
