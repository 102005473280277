import { statusSelector } from '@modules/planning/selectors';
import { createSelector } from 'reselect';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';

export const planningByDateSelector = createSelector(projectIDSelector, statusSelector, (projectID, status) => {
  const noStatus = !status;
  return { noStatus, projectID };
});

export const groupSwitcherSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.groupSwitchers,
  (groupSwitchers) => groupSwitchers
);
