import { ButtonProps } from 'antd';
import { ILSButtonTooltip } from '@components/index';
import { MouseEventHandler } from 'react';
import { Reference, TableRecord } from '@common/types';
import { SetShowReference } from '@common/types/components/table/common';
import { preventDefaultStopPropagationForHandler } from '@common/utils/helpers/prevent-default-stop-propagation-for-handler';

interface IProps<R extends TableRecord> extends Pick<ButtonProps, 'disabled'>, Reference<R> {
  reference: Reference<R>;
  setShowReference: SetShowReference<R>;
}

export const ILSReferenceCellButton = <R extends TableRecord>({
  reference,
  setShowReference,
  record,
  errors,
  handleSave,
  recordDataIndex,
  cellType,
  disabled
}: IProps<R>): JSX.Element => {
  const onClick: MouseEventHandler<HTMLElement> = (e) => {
    preventDefaultStopPropagationForHandler(e);
    setShowReference({
      reference: {
        ...reference,
        record,
        errors,
        handleSave,
        recordDataIndex,
        cellType
      }
    });
  };

  return (
    <ILSButtonTooltip
      className="button-table-reference"
      key={`ref_${reference?.title}`}
      disabled={disabled}
      type="text"
      placement={'topLeft'}
      onClick={onClick}
      title={`Нажмите, чтобы редактировать справочник "${reference?.title}"`}
      icon={'open'}
    />
  );
};
