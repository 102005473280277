import { filterPointsToGetTrack, plansPointsToGetTrack } from '@common/utils/get-track/planPoint2getTrack';

//NOTE запускает отложенную загрузку еще не загруженных треков
export const loadTrack = (
  pointData: {
    planDataQuery: any;
    trackData: any;
    isFetching: boolean;
  },
  dispatch: any,
  action: any
) => {
  const newTrackData = plansPointsToGetTrack(pointData?.planDataQuery, pointData?.trackData);
  let timeout: ReturnType<typeof setTimeout> | undefined;
  if (pointData && !pointData.isFetching && pointData.planDataQuery) {
    if (!!Object.values(pointData.planDataQuery).length && Object.values(pointData.trackData).length === 0) {
      if (Object.values(newTrackData).length) {
        timeout = setTimeout(() => {
          dispatch(action({ points: newTrackData }));
        }, 300);
      }
    } else {
      if (Object.keys(filterPointsToGetTrack(newTrackData)).length) {
        timeout = setTimeout(() => {
          if (!pointData.isFetching) {
            dispatch(action({ points: newTrackData }));
          }
        }, 3000);
      }
    }
  }
  return () => {
    if (timeout) clearTimeout(timeout);
  };
};
