import { Key, Trip, Waypoint } from '@common/types';
import { SetTripStatusRequest, SetWaypointStatusRequest } from '@common/types/dictionaries/monitoring';
import { createArrayFromIndexedArray } from '@common/utils';
import { getMenuItem } from '@components/navigation/menu';
import { monitoringSetTripStatusRoutine, monitoringSetWaypointStatusRoutine } from '@modules/monitoring/actions';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { forwarderPointStatusesSelector } from '@modules/monitoring/selectors/statuses';
import { ForwarderPointStatuses } from '@modules/settings/types';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { monitoringDashboardInfoRoutine } from '../actions';
import { DashboardTables } from '../types';
import { MonitoringDashboardState } from '../types/store';
import { SELECTED_TRIP_STATUS } from '@modules/monitoring/constants/trip-status';

// Возвращает данные всех таблиц
export const dashboardInfoSelector = createSelector(
  (state: { MonitoringDashboard: MonitoringDashboardState }) => state.MonitoringDashboard.dashboardTableData,
  (dashboardTableData) => dashboardTableData
);

// Возвращает данные впо основному дашборду
export const dashboardAllInfoSelector = createSelector(
  (state: { MonitoringDashboard: MonitoringDashboardState }) => state.MonitoringDashboard.dashboardTableData.all,
  (dashboardTableData) => dashboardTableData
);

// Возвращает данные активной таблицы или данные всех таблиц
export const dashboardTableDataSelector = createSelector(
  (state: { MonitoringDashboard: MonitoringDashboardState }, activeTab: DashboardTables | undefined) =>
    activeTab && state.MonitoringDashboard.dashboardTableData[activeTab]
      ? state.MonitoringDashboard.dashboardTableData[activeTab]
      : state.MonitoringDashboard.dashboardTableData.all,
  (dashboardTableData) => dashboardTableData
);

// Возвращает данные для запроса обновления основного дашборда
export const dashboardAllCheckNeedUpdateSelector = createSelector(
  dashboardAllInfoSelector,
  monitoringDateSelector,
  (dashboardAllInfo, monitoringDate) => {
    if (
      dashboardAllInfo.from &&
      dashboardAllInfo.till &&
      dashboardAllInfo.from === monitoringDate.startDate &&
      dashboardAllInfo.till === monitoringDate.endDate
    ) {
      return {
        canUpdate: true,
        payload: {
          from: dashboardAllInfo.from,
          till: dashboardAllInfo.till
        }
      };
    }

    return {
      canUpdate: false,
      payload: {}
    };
  }
);
// const currentDashboardTable: DashboardData<any> = dashboardInfo[currentTableName as DashboardTables] || dashboardInfo['all'];

export const forwarderPointStatusesSubMenuSelector = ({
  dispatch,
  currentTableName
}: {
  dispatch: Dispatch;
  currentTableName?: DashboardTables | undefined;
}) =>
  createSelector(
    [forwarderPointStatusesSelector, (state) => dashboardTableDataSelector(state, currentTableName), () => currentTableName],
    (forwarderPointStatuses, currentDashboardTable, currentTableName) => {
      const handleDashboardUpdateCurrent = () => {
        const updates = currentDashboardTable.lastUpdate;
        if (!currentDashboardTable?.isFetching && currentDashboardTable.from && currentDashboardTable.till && updates) {
          dispatch(
            monitoringDashboardInfoRoutine({
              from: currentDashboardTable.from,
              till: currentDashboardTable.till,
              activeTab: currentTableName,
              updates
            })
          );
        }
      };
      const handleStatus = (statusID: ForwarderPointStatuses['ID'], { WaypointID }: { WaypointID: Waypoint['ID'] }) => {
        const payload: SetWaypointStatusRequest = {
          waypointID: WaypointID,
          statusID
        };
        dispatch(monitoringSetWaypointStatusRoutine(payload));
        setTimeout(handleDashboardUpdateCurrent, 1000);
      };
      const submenu = createArrayFromIndexedArray(forwarderPointStatuses).map(({ Name, ID }) => {
        const name = Name ?? `Статус №${ID}`;
        return { name, handler: handleStatus, ID, text: name };
      });
      const menu = [
        getMenuItem(
          'Установить статус',
          'title',
          null,
          submenu.map(({ name, ID }) => getMenuItem(name, ID))
        )
      ];
      const onMenuItemClick = ({ keyPath }: { keyPath: Array<Key> }) => {
        const [statusID, WaypointID] = keyPath;
        handleStatus(Number(statusID), { WaypointID: Number(WaypointID) });
      };
      const isActive = ({ record }: { record: object & { WaypointID?: number } }) => Boolean(record.WaypointID);

      return { menu, submenu, handleStatus, onMenuItemClick, isActive };
    }
  );

export const tripStatusSubMenuSelector = ({
  dispatch,
  currentTableName
}: {
  dispatch: Dispatch;
  currentTableName?: DashboardTables | undefined;
}) =>
  createSelector(
    [(state) => dashboardTableDataSelector(state, currentTableName), () => currentTableName],
    (currentDashboardTable, currentTableName) => {
      const handleDashboardUpdateCurrent = () => {
        const updates = currentDashboardTable.lastUpdate;
        if (!currentDashboardTable?.isFetching && currentDashboardTable.from && currentDashboardTable.till && updates) {
          dispatch(
            monitoringDashboardInfoRoutine({
              from: currentDashboardTable.from,
              till: currentDashboardTable.till,
              activeTab: currentTableName,
              updates
            })
          );
        }
      };
      const handleStatus = (status: SetTripStatusRequest['status'], { TripID }: { TripID: Trip['ID'] }) => {
        const payload: SetTripStatusRequest = {
          id: TripID,
          status
        };
        dispatch(monitoringSetTripStatusRoutine(payload));
        setTimeout(handleDashboardUpdateCurrent, 1000);
      };
      const isActive = ({ record }: { record: object & { TripID?: number } }) => Boolean(record.TripID);

      const submenu = SELECTED_TRIP_STATUS.map(({ status, label }) => {
        return { name: label, handler: handleStatus, ID: status, text: label };
      });
      return { submenu, handleStatus, isActive };
    }
  );

