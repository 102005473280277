import { EMPTY_STRING } from '@common/constants';
import { STATUS_VARIANTS_PROJECT } from '@common/models/project/constants';
import { CellType, TableColumnName, TableConfig } from '@common/types';
import { ILSPlanningStatusTooltip } from '@modules/planning/children/by-date/components/result/status-tooltip';
import { titleTooltipFullName } from '@modules/planning/children/by-date/config/plan';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export const PLANS_TABLE_CONFIG: TableConfig = {
  reloading: false,
  deleting: false,
  selecting: false,
  copying: false,
  hideClearButtons: true,
  columns: [
    {
      title: EMPTY_STRING,
      titleTooltip: '№',
      dataIndex: TableColumnName.Number,
      key: TableColumnName.Number,
      type: CellType.String,
      active: true,
      ellipsis: true,
      isFixedColumn: true,
      update: true,
      editable: false
    },
    ID_COLUMN,
    {
      title: EMPTY_STRING,
      titleIcon: 'check-mark',
      titleText: 'Статус',
      dataIndex: TableColumnName.Status,
      key: TableColumnName.Status,
      type: CellType.SelectIcon,
      isFixedColumn: true,
      active: true,
      align: 'center',
      ellipsis: false,
      editable: true,
      options: STATUS_VARIANTS_PROJECT,
      fieldNames: BASE_FIELD_NAMES,
      titleTooltip: ILSPlanningStatusTooltip,
      closeSelectOnScroll: true,
      classOverlayTitleTooltip: 'ils-tooltip'
    },
    {
      title: 'Название',
      dataIndex: TableColumnName.FullName,
      key: TableColumnName.FullName,
      titleTooltip: titleTooltipFullName,
      type: CellType.String,
      ellipsis: true,
      droppable: true,
      active: true,
      editable: false
    },
    {
      title: 'Конфигурация',
      dataIndex: TableColumnName.PlanConfig,
      key: TableColumnName.PlanConfig,
      type: CellType.CascaderConfigAPI,
      active: true,
      editable: true,
      align: 'left',
      ellipsis: false,
      selectProps: { dropdownMenuColumnStyle: { maxWidth: '100%', maxColumnWidth: '220px' } }
    },
    {
      title: 'Тип планирования',
      dataIndex: TableColumnName.TypePlanning,
      key: TableColumnName.TypePlanning,
      type: CellType.Select,
      closeSelectOnScroll: true,
      ellipsis: true,
      active: true,
      editable: true,
      align: 'left',
      allowClear: false,
      showSearch: false
    },
    {
      titleText: 'Кнопка для старта планирования',
      titleTooltip: 'Начать планирование',
      title: 'Планирование',
      dataIndex: TableColumnName.Planning,
      key: TableColumnName.Planning,
      active: true,
      editable: false,
      ellipsis: true,
      type: CellType.IconAction
    },
    {
      title: 'Прогресс',
      dataIndex: TableColumnName.Progress,
      key: TableColumnName.Progress,
      type: CellType.Progress,

      ellipsis: true,
      active: true,
      editable: false
    },
    {
      title: 'Длительность планирования',
      dataIndex: TableColumnName.CalculateDuration,
      key: TableColumnName.CalculateDuration,
      type: CellType.DoubleRangeTimeString,

      ellipsis: true,
      active: false,
      editable: false
    },
    {
      title: 'ТС',
      dataIndex: TableColumnName.Vehicles,
      key: TableColumnName.Vehicles,
      type: CellType.Int,

      ellipsis: true,
      active: false,
      editable: false
    },
    {
      title: 'Рейсов',
      dataIndex: TableColumnName.Trip,
      key: TableColumnName.Trip,
      type: CellType.Int,

      ellipsis: true,
      active: true,
      editable: false
    },
    {
      title: 'Распределенные заявки',
      dataIndex: TableColumnName.ResolvedOrder,
      key: TableColumnName.ResolvedOrder,
      type: CellType.Int,
      ellipsis: true,
      active: true,
      editable: false,
      titleTooltip: 'Клиентов/Распределенных заявок/Всего заявок'
    },
    {
      title: 'Нераспределенные заявки',
      dataIndex: TableColumnName.UnresolvedOrder,
      key: TableColumnName.UnresolvedOrder,
      type: CellType.Int,
      ellipsis: true,
      active: true,
      editable: false,
      titleTooltip: 'Нераспределенных заявок/Всего заявок'
    },
    {
      title: 'Штрафы',
      dataIndex: TableColumnName.Penalty,
      key: TableColumnName.Penalty,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Цена',
      dataIndex: TableColumnName.Cost,
      key: TableColumnName.Cost,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Факт. цена',
      dataIndex: TableColumnName.FactCost,
      key: TableColumnName.FactCost,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.',
      dataIndex: TableColumnName.Duration,
      key: TableColumnName.Duration,
      type: CellType.DoubleRangeTimeString,

      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.пути',
      dataIndex: TableColumnName.RoadDuration,
      key: TableColumnName.RoadDuration,
      type: CellType.DoubleRangeTimeString,

      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.движения',
      dataIndex: TableColumnName.MoveDuration,
      key: TableColumnName.MoveDuration,

      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.отдыха',
      dataIndex: TableColumnName.RestDuration,
      key: TableColumnName.RestDuration,

      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Дистанция',
      dataIndex: TableColumnName.Distance,
      key: TableColumnName.Distance,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Сред.скорость',
      dataIndex: TableColumnName.AverageSpeed,
      key: TableColumnName.AverageSpeed,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.погрузки',
      dataIndex: TableColumnName.LoadDuration,
      key: TableColumnName.LoadDuration,

      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Длит.разгрузки',
      dataIndex: TableColumnName.UnloadDuration,
      key: TableColumnName.UnloadDuration,

      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Начало',
      dataIndex: TableColumnName.StartTime,
      key: TableColumnName.StartTime,
      type: CellType.TimeStringUtc,

      ellipsis: true,
      active: false
    },
    {
      title: 'Конец',
      dataIndex: TableColumnName.EndTime,
      key: TableColumnName.EndTime,
      type: CellType.TimeStringUtc,

      ellipsis: true,
      active: false
    },
    {
      title: 'Вес',
      titleTooltip: 'Количество кг. - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Mass,
      key: TableColumnName.Mass,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'Паллет',
      titleTooltip: 'Количество паллет - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Pallets,
      key: TableColumnName.Pallets,
      type: CellType.Int,

      ellipsis: true,
      active: false
    },
    {
      title: 'Коробок',
      titleTooltip: 'Количество коробок - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Boxes,
      key: TableColumnName.Boxes,
      type: CellType.String,

      ellipsis: true,
      active: false
    },
    {
      title: 'Объем',
      titleTooltip: 'Количество м3 - загружено в точке / разгружено в точке',
      dataIndex: TableColumnName.Volume,
      key: TableColumnName.Volume,
      type: CellType.Float,

      ellipsis: true,
      active: false
    },
    {
      title: 'ДхШхВ',
      dataIndex: TableColumnName.Dimensions,
      key: TableColumnName.Dimensions,
      type: CellType.String,

      ellipsis: true,
      active: false
    },
    {
      title: 'Ожидание',
      dataIndex: TableColumnName.WaitingDuration,
      key: TableColumnName.WaitingDuration,
      type: CellType.DoubleRangeTimeString,

      ellipsis: true,
      active: false
    },
    {
      title: 'Опоздание',
      dataIndex: TableColumnName.LatenessDuration,
      key: TableColumnName.LatenessDuration,
      type: CellType.DoubleRangeTimeString,
      ellipsis: true,
      active: false
    },
    {
      title: 'Эффективность',
      dataIndex: TableColumnName.Efficiency,
      key: TableColumnName.Efficiency,
      type: CellType.Percent,
      ellipsis: true,
      active: true,
      editable: false
    }
  ]
};
