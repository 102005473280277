import { emulatorGetConfigRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetConfigRequest, GetConfigResponse } from '../types/api';

// Получает конфигурацию эмуляции у выбранного плана
function* emulatorGetConfigWorker(action: PayloadAction<GetConfigRequest>) {
  const { request, success, failure, fulfill } = emulatorGetConfigRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());
    const { data }: { data: { data: GetConfigResponse } } = yield call(EmulatorAPI.getConfig, { planID });

    yield put(success({ data: data.data, planID }));
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorGetConfigWatcher() {
  yield takeLatest(emulatorGetConfigRoutine.TRIGGER, emulatorGetConfigWorker);
}
