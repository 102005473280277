import { planningUnsetExecutionRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* planningUnsetExecutionWorker(
  action: PayloadAction<{
    planID: number;
  }>
) {
  const { request, failure, fulfill, success } = planningUnsetExecutionRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(PlanningAPI.unsetForExecution, { planID });

    yield put(success({ message: data, planID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningUnsetExecutionWatcher() {
  yield takeLatest(planningUnsetExecutionRoutine.trigger, planningUnsetExecutionWorker);
}
