import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { getTripTimelineRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { TripTimelineType } from '@modules/orders-and-trips/types/trip';

export const getTripTimelineReducer = createReducer(initialState, {
  [getTripTimelineRoutine.REQUEST]: (state: InitialState) => {
    state.tripTimelines.isFetching = true;
    return state;
  },
  [getTripTimelineRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: Array<TripTimelineType>;
      tripID: string;
    }>
  ) => {
    const { data, tripID } = action.payload;
    state.tripTimelines.timelines = { ...state.tripTimelines.timelines, [tripID]: data };
    return state;
  },
  [getTripTimelineRoutine.FAILURE]: onFailure,
  [getTripTimelineRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripTimelines.isFetching = false;
    return state;
  }
});

