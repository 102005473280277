import { useMapRefWithTriggerFocus } from '@modules/planning/children/by-date/hooks/map/use-map-ref-with-trigger-focus';
import { useMapTables } from '@modules/planning/children/by-date/hooks/tables/use-map-tables';
import { useMapDragNDrop } from '@modules/planning/children/by-date/hooks/use-map-drag-n-drop';
import { PlannerBlocksFullScreen } from '@core/decorators/split-sizes';
import { PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';
import { TableName } from '@common/types';

export const usePlanTableMapConnection = ({
  fullScreen,
  onFullChange,
  onResetSelectedRows
}: {
  fullScreen: PlannerBlocksFullScreen;
  onResetSelectedRows(tableName: TableName): void;
  onFullChange(arg: PlannerBlocksFullScreen | PlannerSettingsParameterFullScreen): void;
}) => {
  const { handleTriggerMapFocus, triggerMapFocus, mapRef } = useMapRefWithTriggerFocus({ fullScreen, onFullChange });
  const { handleShowOrderTable, handleShowVehicleTable, showVehicleTable, showOrderTable } = useMapTables({
    onFullChange,
    onResetSelectedRows
  });
  const { onItemDrop, markerDragging, setMarkerDragging, setIsAfterDragging } = useMapDragNDrop({
    handleShowOrderTable
  });

  return {
    handleTriggerMapFocus,
    triggerMapFocus,
    mapRef,
    handleShowVehicleTable,
    showVehicleTable,
    showOrderTable,
    onItemDrop,
    markerDragging,
    setMarkerDragging,
    setIsAfterDragging,
    handleShowOrderTable
  };
};
