import { TPersistTransform } from '@core/persist/transforms';
import { initialState } from '@modules/demand/store';
import { IDemandStore } from '@modules/demand/types';

export const persistTransform: TPersistTransform<IDemandStore> = {
  initialState,
  onPersist: (inboundState: IDemandStore) => {
    return {
      activeTab: inboundState?.activeTab ||  initialState.activeTab
    };
  }
};
