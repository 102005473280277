import { tenderDocumentsRoutine } from '@modules/tender-platform/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender } from '@modules/tender-platform/types';

function* tenderDocumentsWorker(action: PayloadAction<{ tenderID: Tender['ID'] }>) {
  const { request, success, failure, fulfill } = tenderDocumentsRoutine;
  const { tenderID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderAPI.index, { tenderID });
    yield put(success({ documents: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderDocumentsWatcher() {
  yield takeEvery(tenderDocumentsRoutine.trigger, tenderDocumentsWorker);
}
