import { emulatorUpdateConfigRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { UpdateConfigRequest } from '../types/api';

// Обновляет конфигурацию эмуляции у выбранного плана
function* emulatorUpdateConfigWorker(action: PayloadAction<UpdateConfigRequest>) {
  const { request, success, failure, fulfill } = emulatorUpdateConfigRoutine;
  const { planID, emulationSpeed } = action.payload;

  try {
    yield put(request());

    yield call(EmulatorAPI.updateConfig, { planID, emulationSpeed });

    yield put(success());
  } catch (error) {
    console.error(error);

    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorUpdateConfigWatcher() {
  yield takeLatest(emulatorUpdateConfigRoutine.TRIGGER, emulatorUpdateConfigWorker);
}
