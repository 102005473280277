import { PayloadAction } from '@reduxjs/toolkit';
import { currentDocumentRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ICurrentDocument } from '@modules/documents/types/document-state';

function* currentDocumentWorker(action: PayloadAction<ICurrentDocument>) {
  if (action.payload.documentId) {
    const { request, success, failure, fulfill } = currentDocumentRoutine;

    try {
      yield put(request());

      const { data } = yield call(Documents.getDocumentData, { id: action.payload.documentId, isInbox: action.payload.isInbox ? 1 : 0 });

      yield put(success(Object.values(data.data)[0]));
    } catch (e) {
      yield put(failure());
    } finally {
      yield put(fulfill());
    }
  }
}

export function* currentDocumentWatcher() {
  yield takeEvery(currentDocumentRoutine.trigger, currentDocumentWorker);
}
