import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { planningGetTrackStartAction, planningGetTrackStopAction } from '../../actions';

export const usePlanningLoadTrack = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(planningGetTrackStartAction());
    return () => {
      dispatch(planningGetTrackStopAction());
    };
  }, []);
};
