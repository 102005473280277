import ILSPlanningParamsComponent from './params-list';
import { ILSCollapse, ILSCollapsePanel, ILSTable } from '@components/index';
import { PLANNER_PLANNING_PARAMETERS_TABLE_CONFIG } from '../config/table';
import { tableCustom } from '@core/containers/table-custom';
import { data2table } from '../decorators';
import { ParamsCollapseProps } from '@modules/planning/children/params/types/components';
import { FC } from 'react';
import { Dictionary, IILSTableProps, TableRecord } from '@common/types';
import { ILSExpandRow } from '@components/data-display/table/components/ils-expand-row';
import { copyConfigRoutine } from '@modules/planning/children/params/actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@core/hooks';
import { fetchSelector } from '@modules/planning/children/params/selectors';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { UpdateParamType } from '../types/api';
import { IData } from '@modules/planning/types';

export const ILSParamsCollapseComponent: FC<ParamsCollapseProps> = ({ data, handleSave, ...props }) => {
  const dispatch = useDispatch();
  const isFetching = useAppSelector(fetchSelector);

  const onSave: IILSTableProps<any>['handleSave'] = (record, dataIndex, value) => {
    const values = {
      [record.id]: {
        type: UpdateParamType.ChangeConfig,
        Context: dataIndex === 'DefaultValue_' ? value : record.DefaultValue_,
        Name: dataIndex === 'Name_' ? value : record.Name_,
        PlanConfigID: record.PlanConfigID_,
        Def: record.Def,
        Index: record.Index_
      }
    };
    handleSave(values);
  };

  const expandedRowRender = {
    expandedRowRender: (record: TableRecord) => (
      <ILSExpandRow>
        <ILSCollapse className="collapse" bordered={false}>
          {record?.Items?.length && (
            <>
              <ILSCollapsePanel className="config-panel" key={'settings'} header={'Настроечные параметры'}>
                <ILSPlanningParamsComponent {...props} handleSave={handleSave} params={record.Items[1]} type="setting" />
              </ILSCollapsePanel>

              <ILSCollapsePanel className="config-panel" key={'fines'} header={'Штрафы'}>
                <ILSPlanningParamsComponent {...props} handleSave={handleSave} params={record.Items[0]} type="fine" />
              </ILSCollapsePanel>
            </>
          )}
        </ILSCollapse>
      </ILSExpandRow>
    )
  };

  const handleCopy: IILSTableProps<TableRecord & IData>['onCopy'] = ({ dictionary, rows }) => {
    const config = data.find(({ id }) => compareAsString(rows?.[0], id));
    const ConfigID = config?.PlanConfigID_;
    config && ConfigID && dispatch(copyConfigRoutine({ dictionary, ConfigID, config }));
  };

  const handleRowKey = ({ id }: any) => id;

  return (
    <ILSTable
      {...props}
      virtual={false}
      scroll={{ y: 500 }}
      loading={isFetching}
      onCopy={handleCopy}
      dictionary={Dictionary.PlannerParams}
      config={PLANNER_PLANNING_PARAMETERS_TABLE_CONFIG}
      tableName={Dictionary.PlannerParams}
      dataSource={data?.length && typeof data2table === 'function' ? data2table(data) : []}
      pagination={false}
      rowKey={handleRowKey}
      expandable={expandedRowRender}
      handleSave={onSave}
    />
  );
};

export default tableCustom(ILSParamsCollapseComponent, PLANNER_PLANNING_PARAMETERS_TABLE_CONFIG, Dictionary.PlannerParams);
