import { hideEmptyColumns } from '@common/utils/table';
import { FC } from 'react';
import { DepotUploadFileType } from '@common/types';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { ILSPlanningDataViewTable } from '@modules/data-validate/containers/table';
import { depot2Table } from '@modules/planning/children/data/utils/decorators';

export const ILSDataViewDepotTable: FC<IDataTableProps> = ({ dataSource, dictionary, config, hideEmpty, ...props }) => {
  return (
    <ILSPlanningDataViewTable
      hideEmpty={hideEmpty}
      dictionary={dictionary}
      config={hideEmptyColumns(dataSource, config, hideEmpty)}
      dataSource={depot2Table(dataSource as DepotUploadFileType[], dictionary)}
      {...props}
    />
  );
};
