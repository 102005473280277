import { FC } from 'react';
import { ILSEmpty, ILSModal, ILSSpin } from '@components/index';
import { isEmpty } from 'lodash';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { ProposalItem } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-item';
import { FakePreCostProposals, FakePreCostProposalSortOption, FakePreCostSortUnit } from '@modules/orders-and-trips/types/fake-pre-cost';
import { ProposalEmptyImage } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-empty-image';
import { ProposalFilter } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/proposal/proposal-filter';
import { useAuth } from '@common/hooks/use-auth';
import { useModal } from '@common/hooks/components/use-modal';

interface IProps {
  proposalList: FakePreCostProposals;
  activeSortOption: FakePreCostProposalSortOption;
  isFetching: boolean;
  section: NewOrderFormTab;

  sortProposals(sortUnit: FakePreCostSortUnit): () => void;
}

export const ProposalList: FC<IProps> = ({ proposalList, isFetching, section, activeSortOption, sortProposals }) => {
  const { isAuth } = useAuth();
  const { visible, handleShow } = useModal();

  if (isFetching) {
    return <ILSSpin tip={'Расчёт цен...'} spinning={isFetching} />;
  }

  if (isEmpty(proposalList)) {
    return (
      <ILSEmpty
        imageStyle={{ height: 'fit-content', padding: '1em' }}
        image={<ProposalEmptyImage />}
        description={'Для отображения начните поиск'}
      />
    );
  }

  return (
    <>
      <ProposalFilter activeSortOption={activeSortOption} sortProposals={sortProposals} />
      <ul className={'fake-pre-cost__card-list'}>
        {proposalList.map(({ price, term, name, description, currency, id, info }) => {
          return (
            <ProposalItem
              info={info}
              handleModalVisibility={handleShow}
              isAuth={isAuth}
              id={id}
              term={term}
              alias={name}
              description={description}
              cost={price}
              section={section}
              key={id + name}
              currency={currency}
            />
          );
        })}
      </ul>
      {!isAuth && (
        <ILSModal
          title={'Необходим полный доступ'}
          children={'Для оформления заявки на рейс необходимо зарегистрироваться на платформе'}
          visible={visible}
          onCancel={handleShow}
          onOk={handleShow}
          okText={'Закрыть'}
          cancelText="Отменить"
        />
      )}
    </>
  );
};
