import { FC, RefObject, useRef } from 'react';
import { ILSButtonTooltip, ILSPopover } from '@common/components';
import { PopoverProps } from 'antd/lib/popover';
import { IButtonTooltipProps } from '@components/general/buttons/types';
import { PlacementType } from '@common/types/components';
import CustomScroll from 'react-customscroll';
import { useTableConfigDragNDropScroll } from '@core/hooks/use-table-config-drag-and-drop-scroll';

export interface ITableCustomButtonWithPopoverProps extends Omit<PopoverProps, 'color'>, IButtonTooltipProps {
  'data-testid'?: string;
}

export const TableCustomButtonWithPopover: FC<ITableCustomButtonWithPopoverProps> = (props) => {
  const configContainer = useRef<HTMLDivElement>(null);
  const customScrollRef = useRef<
    CustomScroll & {
      customScrollHolderRef: RefObject<any>;
      scrollBlock: any;
    }
  >(null);

  useTableConfigDragNDropScroll({ configContainer, customScrollRef });

  return (
    <ILSPopover
      trigger={'click'}
      placement={PlacementType.bottomRight}
      visible={props.visible}
      onVisibleChange={props.onVisibleChange}
      arrowContent={false}
      content={props.content}
    >
      <ILSButtonTooltip
        placement={PlacementType.bottom}
        title={props.title}
        onClick={props.onClick}
        icon={props.icon}
        data-testid={props['data-testid']}
      />
    </ILSPopover>
  );
};
