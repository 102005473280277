import { ChangeSettingsPayload } from '@modules/settings/types/reducers';
import { settingsSaveRoutine } from '@modules/settings/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SettingsAPI } from '@modules/settings/api';
import { RequestParams } from '@common/constants/settings/api';

function* settingsSaveWorker(action: ChangeSettingsPayload) {
  const { request, success, failure, fulfill } = settingsSaveRoutine;
  const { value, rehydrate } = action.payload;

  try {
    yield put(request({ isFetching: true }));

    const {
      data: { data }
    } = yield call(SettingsAPI.mergeUserParams, {
      paramName: RequestParams.Settings,
      binding: RequestParams.BindingAccount,
      value
    });
    yield put(success({ value: data.value, rehydrate }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* settingsSaveWatcher() {
  yield takeLatest(settingsSaveRoutine.trigger, settingsSaveWorker);
}

