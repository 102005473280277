import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, Notify, NotifyDurationInSecond } from '@common/types';
import { defaultStatuses, MonitoringAction } from '@modules/monitoring/constants/default-messages';
import { monitoringRoutinesToNotify } from '@modules/monitoring/actions';
import { getErrorMessage } from '@common/utils';

/*
 * Метод получения текста уведомления для разных actions
 */
const getDataNotificationMessage = (
  action: MonitoringAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || defaultStatuses?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): MonitoringAction => {
  return actionName as MonitoringAction;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, MonitoringAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Monitoring,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, MonitoringAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Monitoring,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* monitoringDashboardNotifierWatcher() {
  for (let routine of monitoringRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
