import { ILSDivider } from '@common/components';
import { TripTimelineContainer } from '@modules/orders-and-trips/containers/right/timeline/trip-timeline-container';
import { DocumentsUploadContainer } from '@modules/orders-and-trips/containers/right/document-upload/document-upload-container';

export const DocumentsContainer = () => {
  return (
    <div className="documents-container">
      <DocumentsUploadContainer />
      <ILSDivider />
      <TripTimelineContainer />
    </div>
  );
};
