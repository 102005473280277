import { ILSButton } from '@common/components';
import { ILSDemandInputContainer } from '@modules/demand/containers/demand-input';
import { GetListRequest, RequestParamsFilter } from '@modules/demand/types/api';
import { FC } from 'react';
import { ILSDemandFilterRangeComponent } from './filter-range';
import { AccountRole } from '@common/types';

interface IProps {
  filter: GetListRequest['filter'];
  onChangeFilter: (f: Partial<RequestParamsFilter>) => void;
  account: Array<AccountRole> | null;
  hasDesync: boolean;
  onReload: () => void;
}

// Компонент содержащий инпуты фильтров
export const ILSDemandFiltersComponent: FC<IProps> = ({ filter, onChangeFilter, account, hasDesync = false, onReload }) => {
  return (
    <div className="ils-demand-filters">
      <div className="row">
        {account && account.includes(AccountRole.carrier) ? (
          <ILSDemandInputContainer filter={filter} onChangeFilter={onChangeFilter} placeholder="Заказчик" filterField="nameOwner" />
        ) : null}
        {account && account.includes(AccountRole.cargoOwner) ? (
          <ILSDemandInputContainer filter={filter} onChangeFilter={onChangeFilter} placeholder="Исполнитель" filterField="nameCarrier" />
        ) : null}

        <ILSDemandFilterRangeComponent onChangeRange={(from, till) => onChangeFilter({ ...filter, from, till })} />

        <ILSDemandInputContainer filter={filter} onChangeFilter={onChangeFilter} placeholder="Фильтр ТС" filterField="vehicle" />
        <ILSDemandInputContainer filter={filter} onChangeFilter={onChangeFilter} placeholder="ФИО Водителя" filterField="driver" />

        <ILSButton icon="reload" onClick={onReload} title="Обновить данные" className="reload-button" danger={hasDesync} />
      </div>
    </div>
  );
};
