import { ILSSpace } from '@components/index';
import { getValidMoment, parseDateFromServer } from '@common/utils/helpers/date-time';
import moment, { MomentInput } from 'moment';
import { IILSCellDateTimeStringProps } from '@common/types/components/table/cell';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { ILSDatePickerStatic } from '@components/custom/data-display/date-picker';
import { ILSTimePickerStatic } from '@components/custom/data-display/time-picker';
import { ILS_MAIN_API_DATE_FORMAT, ILS_MAIN_API_DATE_TIME_FORMAT, TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isMomentInput } from '@components/data-display/table/utils/helpers/cells/is-moment-input';
import { isNull } from 'lodash';
import { isEmptyStringDate } from '@common/components/data-display/table/utils/helpers/cells/is-empty-string-date';

export const ILSCellDateTimeString = <R extends TableRecord>({
  children,
  format,
  showTime
}: IILSCellDateTimeStringProps<R>): JSX.Element => {
  const currentFormat = format ?? (showTime ? ILS_MAIN_API_DATE_TIME_FORMAT : ILS_MAIN_API_DATE_FORMAT);
  const [node, date] = children;

  if (!isMomentInput(date, currentFormat) && !isNull(date) && !isEmptyStringDate(date)) {
    showUserAndDevError({ error: 'Дата должна быть в формате MomentInput | TimeStamp' });
    return (
      <ILSSpace direction={'vertical'}>
        {node}
        <ILSTimePickerStatic children={undefined} />
        <ILSDatePickerStatic children={undefined} />
      </ILSSpace>
    );
  }
  const datetime = getValidMoment(parseDateFromServer(date as MomentInput, showTime, currentFormat));
  const dateTimeToString = datetime ? moment(datetime).format(currentFormat)?.split(JoinChar.Space) : EMPTY_STRING;

  return (
    <ILSSpace direction={'vertical'}>
      {node}
      <ILSTimePickerStatic children={dateTimeToString?.[0]} />
      <ILSDatePickerStatic children={dateTimeToString?.[1]} />
    </ILSSpace>
  );
};
