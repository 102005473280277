import { ILSTabPane, ILSTabs } from '@common/components';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { TripCardsContainer } from '@modules/orders-and-trips/containers/left/trips/trip-cards-container';
import { TRIP_CONTAINERS_BY_STATUS } from '@modules/orders-and-trips/constants/display-blocks';
import { useTripContainer } from '@modules/orders-and-trips/hooks/use-trip-container';
import { EMPTY_STRING, SPACE, SplitChar } from '@common/constants';
import { Filters } from '@modules/orders-and-trips/components/filters';
import { UpdateTripsInfo } from '@modules/orders-and-trips/components/information/update-trips-info';

export const TripsContainer = () => {
  const {
    vehicleTypes,
    loadTrips,
    changeActiveTab,
    tripStatus,
    tripsData,
    onSetActiveTrip,
    activeTrip,
    isFetching,
    tripsCount,
    tripsPagination,
    filterID,
    handleChangeFilterID,
    updateTripStatus
  } = useTripContainer();

  return (
    <>
      <Filters filterID={filterID} handleChangeFilterID={handleChangeFilterID} />
      <UpdateTripsInfo changeActiveTab={changeActiveTab} tripStatus={tripStatus} updateTripStatus={updateTripStatus} />
      <ILSTabs type="card" destroyInactiveTabPane onChange={changeActiveTab} activeKey={tripStatus}>
        {TRIP_CONTAINERS_BY_STATUS.map(({ title, containerStatus }) => {
          const isNewTrips = tripStatus === TripStatus.New;
          const tabTitle = title + SPACE + SplitChar.Colon + SPACE + (tripsCount?.[containerStatus] ?? EMPTY_STRING);
          return (
            <ILSTabPane tab={tabTitle} key={containerStatus}>
              <TripCardsContainer
                key={containerStatus + title}
                onSetActiveTrip={onSetActiveTrip}
                activeTrip={activeTrip}
                tripStatus={tripStatus}
                activeTripCssClass={isNewTrips ? 'new' : null}
                pagination={tripsPagination}
                dataSource={tripsData}
                loadTrips={loadTrips}
                isFetching={isFetching}
                vehicleTypes={vehicleTypes}
              />
            </ILSTabPane>
          );
        })}
      </ILSTabs>
    </>
  );
};
