//Функция возвращает размеры содержания элемента в процентах относительно родительского элемента
export const getContentSize = (props: {
  element?: HTMLElement | null;
  parentElement?: HTMLElement | null;
  condition: boolean;
  callBack: (size: { x: number; y: number }) => void;
}) => {
  const { element, parentElement, condition, callBack } = props;
  if (element && parentElement && condition) {
    const x = (element.offsetWidth / parentElement.offsetWidth) * 100;
    const y = (element?.offsetHeight / parentElement?.offsetHeight) * 100;
    callBack({ x: x, y: y });
  }
};
