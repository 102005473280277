import moment from 'moment';
import { DataRangeOptionType } from '../config/date-select';

export default function convertPeriod(period: DataRangeOptionType): [string, string] {
  const format = 'YYYY-MM-DD';

  if (period === DataRangeOptionType.Custom) {
    return ['', ''];
  }

  if (period === DataRangeOptionType.ThisWeek) {
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    return [moment(weekStart).format(format), moment(weekEnd).format(format)];
  }

  if (period === DataRangeOptionType.LastWeek) {
    const startOfWeek = moment().subtract(1, 'weeks').startOf('week').format(format);
    const endOfWeek = moment().subtract(1, 'weeks').endOf('week').format(format);

    return [startOfWeek, endOfWeek];
  }

  if (period === DataRangeOptionType.ThisMonth) {
    const startOfMonth = moment().clone().startOf('month').format(format);
    const endOfMonth = moment().clone().endOf('month').format(format);

    return [startOfMonth, endOfMonth];
  }

  if (period === DataRangeOptionType.LastMonth) {
    const startOfMonth = moment().subtract(1, 'months').startOf('month').format(format);
    const endOfMonth = moment().subtract(1, 'months').endOf('month').format(format);

    return [startOfMonth, endOfMonth];
  }

  return ['', ''];
}
