import { FC } from 'react';
import { TableContainerProps } from '../types/tables';
import { AnalyticsWidgetTableComponent } from './components';
import css from 'classnames';
import { ILSSkeleton } from '@common/components';
import classNames from 'classnames';

const AnalyticsTablesContainer: FC<TableContainerProps> = ({
  tableProps,
  params,
  storeParams,
  hideWidget,
  loading,
  children,
  hideBorder
}) => {
  return (
    <>
      {!hideWidget && (
        <div className={classNames('analytics-table-container position-relative', { 'analytics-widget-border': !hideBorder })}>
          <div className={'pl-3 pr-3'}>{children}</div>
          <ILSSkeleton loading={loading}>
            {tableProps?.config?.title && (
              <div className={css('analytics-table-subheader', `analytics-table-subheader-${tableProps?.config?.className}`)}>
                {tableProps?.config?.title}
              </div>
            )}
            <AnalyticsWidgetTableComponent tableConfig={{ ...tableProps }} />
          </ILSSkeleton>
        </div>
      )}
    </>
  );
};

export default AnalyticsTablesContainer;
