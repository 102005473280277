import { createSelector } from 'reselect';
import { TrackStore } from '@modules/track/types';

export const fetchSelector = createSelector(
  (state: { Track: TrackStore }) => state.Track.isFetching,
  (isFetching) => isFetching
);

export const depotsSelector = createSelector(
  (state: { Track: TrackStore }) => state.Track.Depots,
  (Depots) => Depots
);

export const routeSelector = createSelector(
  (state: { Track: TrackStore }) => state.Track.Route,
  (route) => route
);

export const tripSelector = createSelector(
  (state: { Track: TrackStore }) => state.Track.Trip,
  (data) => data
);

export const waypointSelector = createSelector(
  (state: { Track: TrackStore }) => state.Track.Waypoint,
  (data) => data
);

export const trackGetPoints = createSelector(
  (state: { Track: TrackStore }) => state.Track.planPoints,
  (planPoints) => planPoints
);

export const trackGetTrackData = createSelector(trackGetPoints, (planPoints) => planPoints.trackData);
