import { FC } from 'react';
import { Project } from '@common/types';
import { ILSTypographyTitle } from '@common/components/general/typography';
import { ILSChooseProjectModal } from '@modules/planning/components/choose-project';
import { ILSNoDataComponent } from '@common/components/common/no-data';
import { isEmpty } from 'lodash';

interface IProps {
  dayProjects: Array<Project>;
  projectID?: number;
  visible: boolean;
  chooseProject: (ID: Project['ID']) => void;
  setProject: () => void;
  onCancel: () => void;
}

export const PlannerDataChooseProject: FC<IProps> = ({ dayProjects, projectID, visible, chooseProject, setProject, onCancel }) => {
  return (
    <>
      {!isEmpty(dayProjects) ? (
        <>
          <ILSTypographyTitle children={`${projectID ? `Проект №${projectID}` : 'Выберите дату'}`} />
          <ILSChooseProjectModal
            selected={projectID}
            onChange={chooseProject}
            list={dayProjects}
            visible={visible}
            onOk={setProject}
            onCancel={onCancel}
          />
        </>
      ) : (
        <ILSNoDataComponent
          className={'bg-white position-relative p-3 pr-5 flex-direction-column'}
          text={'нет проектов на выбранную дату'}
        />
      )}
    </>
  );
};
