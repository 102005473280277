import { fetchSelector } from '@modules/settings/selectors';
import { settingsGetRoutine } from '@modules/settings/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useUserAppSettings = () => {
  const isFetching = useSelector(fetchSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingsGetRoutine({ forceUpdate: true }));
  }, []);

  return { isFetching };
};

