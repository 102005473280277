import { ILSButton, ILSForm, ILSFormItem, ILSInput, ILSSpace } from '@components/index';
import { FC, useEffect } from 'react';
import { ButtonAction } from '@components/general/buttons';
import { Form } from 'antd';

interface IProps {
  planNumber: number;

  handleShowForm(): void;

  handleCreate(name: string): void;
}

export enum FormName {
  PlanCreate = 'createPlan'
}

const defaultValue = 'Новый план';

export const PlannerCreatePlanFormContainer: FC<IProps> = ({ handleShowForm, handleCreate, planNumber }) => {
  const [form] = Form.useForm();

  const onFinish = ({ Name }: any) => {
    handleCreate(Name ?? `${defaultValue} №${planNumber + 1}`);
    handleShowForm();
  };

  useEffect(() => {
    form.setFieldsValue({ Name: `${defaultValue} №${planNumber + 1}` });
  }, [form, planNumber]);

  return (
    <ILSForm form={form} className="planner-form-create-plan" name={FormName.PlanCreate} layout={'vertical'} onFinish={onFinish}>
      <ILSFormItem label="Название плана" name="Name">
        <ILSInput placeholder="Название плана" />
      </ILSFormItem>
      <ILSSpace>
        <ILSButton action={ButtonAction.Cancel} onClick={handleShowForm} />
        <ILSButton action={ButtonAction.Add} form={FormName.PlanCreate} type="primary" htmlType="submit" />
      </ILSSpace>
    </ILSForm>
  );
};

