import { ModulePath } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import history from '@common/utils/history';
import { clearAppStore } from '@core/store';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { checkTokenRoutine, loginActions, logoutActions } from '@modules/current-user/actions';
import { Auth } from '../api/auth';
import { LoggedInUser } from '../types/auth';
import { LoginAction } from '../types/saga';
import { settingsGetRoutine } from '@modules/settings/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { stopSagaRoutine } from '@core/actions/general';
import { ILSAppRegistry } from '../../../index';

function* loginWorker(action: LoginAction) {
  const { request, success, failure, fulfill } = loginActions;
  const { login, password } = action.payload;

  try {
    yield put(request());
    const { data }: LoggedInUser = yield call(Auth.login, {
      login,
      password
    });
    yield put(
      success({
        token: data.Token,
        user: data
      })
    );
    yield put(
      checkTokenRoutine.success({
        token: data.Token,
        user: data
      })
    );

    // !NOTE перед окончанием сначала ждем когда persist все сохранит
    yield call(ILSAppRegistry.persistor.flush);
    //!NOTE получаем настройки аккаунта при первом входе в приложение
    yield put(settingsGetRoutine.trigger());
    yield call(history.push, ModulePath.OrdersNTrips);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* loginWatcher() {
  yield takeLatest(loginActions.trigger, loginWorker);
}

function* logoutWorker(
  action: PayloadAction<{
    clearStore: boolean;
  }>
) {
  const { request, success, failure, fulfill } = logoutActions;

  try {
    //NOTE: Останавливаем саги, сохраненные в глобальной переменной с помощью кастомных эффектов саг
    yield put(stopSagaRoutine.trigger());
    yield put(request());
    yield call(Auth.logout);
    // в случае успеха отдать данные reducer
    yield put(success());
  } catch (error) {
    yield put(failure({ error: createArrayFromIndexedArray(error as Record<string, string>) }));
  } finally {
    yield put(fulfill());
    if (action.payload?.clearStore) {
      window.location.reload();
    }
  }
}

export function* logoutSuccessWatcher() {
  yield call(clearAppStore);
}

export function* logoutWatcher() {
  yield all([takeLatest(logoutActions.trigger, logoutWorker), takeLatest(logoutActions.success, logoutSuccessWatcher)]);
}
