import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { IndexedArray } from '@common/types';
import { getWidgetParamName } from '@modules/analytics/common/utils/get-widget-param-name';
import { useEffect, useMemo, useState } from 'react';

export const useGetWidgetParams = (
  widget: AnalyticsWidget,
  widgetParams: IndexedArray<{ name: string; value: string | number | undefined }>
) => {
  const [params, setParams] = useState<Array<{ name: string; value: string | number | undefined }> | undefined>(undefined);

  const optionalParams = useMemo(() => Object.values(widget?.Params || {}).filter((item) => item.Required === 0), []);

  useEffect(() => {
    const widgetRequestParams = optionalParams.reduce((acc: { name: string; value: string | number | undefined }[], curr) => {
      const paramName = getWidgetParamName(curr.Name, widget);

      const paramValue = widgetParams[paramName];

      const currentValue = params?.find((x) => x.name === curr.Name);

      if (params && currentValue && currentValue.value === paramValue.value) return acc;

      if (paramValue?.value) {
        acc.push({ ...paramValue });
      }

      return acc;
    }, []);

    if (widgetRequestParams.length > 0) {
      setParams([...(params?.filter((p) => widgetRequestParams.every((x) => x.name !== p.name)) || []), ...widgetRequestParams]);
    } else if (!params) {
      setParams([]);
    }
  }, [widgetParams]);

  return { params, setParams };
};
