import { FC } from 'react';
import { filterTableConfig } from '@modules/tender-platform/children/tender-search/table-configs/filters';
import { DASH } from '@common/constants';
import { ITableSearchConfig, TenderFilter } from '@modules/tender-platform/children/tender-search/types/filter';
import { removeTenderFilterRoutine, tenderFilterRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { useDispatch, useSelector } from 'react-redux';
import { tenderFiltersSelector } from '@modules/tender-platform/children/tender-search/selectors';
import { Moment } from 'moment/moment';
import { renderSearchFilterTab } from '@modules/tender-platform/utils/decorators/render-search-filter-tab';

export const ILSSearchFilter: FC = () => {
  const dispatch = useDispatch();
  const tendersFilters = useSelector(tenderFiltersSelector);

  const handleFilter = (filterValue: string, filter: TenderFilter) => {
    dispatch(tenderFilterRoutine({ filter, filterValue }));
  };

  const handleClear = (filterValue: string, filter: TenderFilter) => {
    if (filterValue === '') {
      const currentValue = tendersFilters.find((fil) => fil.key === filter.key)?.value;
      currentValue && dispatch(removeTenderFilterRoutine({ key: filter.key }));
    }
  };

  const handleFilterChange = (query: Moment | null, filter: TenderFilter) => {
    if (query === null) {
      dispatch(removeTenderFilterRoutine({ key: filter.key }));
      return;
    } else {
      handleFilter(String(query!.unix()), filter);
    }
  };

  return (
    <div className="tender-filter-wrapper">
      {filterTableConfig.map((filterTab: ITableSearchConfig) => (
        <>
          <div className="tender-filter" key={filterTab.filter.key}>
            {filterTab.tooltip && (
              <div className="tender-filter-tooltip" style={filterTab.tooltipStyle}>
                {filterTab.tooltip}
              </div>
            )}
            {renderSearchFilterTab(filterTab, tendersFilters, handleFilter, handleClear, dispatch, handleFilterChange)}
          </div>
          {filterTab.dash && <div className="tender-filter-dash">{DASH}</div>}
        </>
      ))}
    </div>
  );
};
