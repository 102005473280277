import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderLotDeleteRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { TenderLot } from '@modules/tender-platform/types';
import { DictionariesEndpoint, Dictionary } from '@common/types';

function* tenderLotDeleteWorker(
  action: PayloadAction<{
    ids: Array<TenderLot['ID']>;
  }>
) {
  const { request, failure, fulfill } = tenderLotDeleteRoutine;
  const { ids } = action.payload;

  try {
    yield put(request());
    yield call(TenderOwn.deleteLot, { ids });
    yield put(
      tenderChangeRoutine.trigger({
        ids,
        operation: DictionariesEndpoint.Delete,
        dictionary: Dictionary.TenderLot
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderLotDeleteWatcher() {
  yield takeEvery(tenderLotDeleteRoutine.trigger, tenderLotDeleteWorker);
}
