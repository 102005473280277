import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { ILSButton, ILSModal } from '@common/components';
import { ModalProps } from 'antd';
import {
  CardTab,
  TENDER_RESTART_MENU,
  TENDER_WITH_RESET_OFFERS,
  TenderButtonText,
  TenderOperationButton
} from '@modules/tender-platform/constants';
import { createTenderForRouteRoutine } from '@modules/tender-platform/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TenderOperations } from '@modules/tender-platform/constants/content';
import { currentTenderSelector } from '@modules/tender-platform/selectors';
import { Module } from '@common/types';
import { Status, Tender, TenderStatus } from '@modules/tender-platform/types';
import { compareAsString, createArrayFromIndexedArray } from '@common/utils';
import { UploadFile } from 'antd/lib/upload/interface';
import { ButtonAction } from '@components/general/buttons';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { useTenderPlatformPermissions } from '@modules/tender-platform/abac/hooks/permissions';
import { TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON } from '@modules/tender-platform/constants/modal-content';

interface IProps extends Omit<ModalProps, 'onCancel'> {
  dataFiles?: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
  operation: TenderOperations;
  setMenu: Dispatch<SetStateAction<Array<CardTab>>>;

  onCancel(): void;

  handleTenderSetStatus(tenderID: Tender['ID'], statusID: Status['ID']): void;
}

const OPERATION_STATUS = {
  [TenderOperationButton.ConfirmSetCancellationStatus]: TenderStatus.Cancelled,
  [TenderOperationButton.ConfirmSetExecutionStatus]: TenderStatus.Executed
};
export const TenderPlatformActionsModalContainer: FC<IProps> = ({
  visible,
  dataFiles,
  onCancel,
  operation,
  handleTenderSetStatus,
  setMenu
}) => {
  const dispatch = useDispatch();
  const { ID, StatusConfig } = useSelector(currentTenderSelector);
  const { url, goBack } = useAppHistory();
  const { canRate } = useTenderPlatformPermissions();
  const status = createArrayFromIndexedArray(StatusConfig?.TransitionScheme)?.find(({ Alias }) =>
    compareAsString(Alias, OPERATION_STATUS[operation])
  );

  const handleSave = () => {
    if (operation.includes(Module.Planner)) {
      dispatch(createTenderForRouteRoutine({ operation, url, dataFiles: dataFiles?.current }));
    } else if (operation.includes('Status') && status?.ID) {
      handleTenderSetStatus(ID, status.ID);
      if (TENDER_WITH_RESET_OFFERS.includes(status.Alias as TenderStatus)) {
        setMenu(TENDER_RESTART_MENU);
      }
    }
    onCancel();
  };

  const onClick = TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].htmlType
    ? onCancel
    : TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].type === 'link'
    ? goBack
    : handleSave;

  return (
    <ILSModal
      footer={
        <>
          {(canRate || operation !== TenderOperationButton.Rate) && (
            <ILSButton
              type={'primary'}
              children={TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].okText ?? TenderButtonText.Save}
              htmlType={TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].htmlType}
              form={TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].form}
              onClick={onClick}
            />
          )}
          <ILSButton action={ButtonAction.Cancel} onClick={onCancel} />
        </>
      }
      title={TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].modalTitle}
      visible={visible}
      onCancel={onCancel}
    >
      {TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation].modalContent}
    </ILSModal>
  );
};
