import { createSelector } from 'reselect';
import { SCHEMES_TYPE } from '@modules/planning/children/scheme-editor/constants';
import {
  ISchemeEditorState,
  OptionsSchemaDictionary,
  Scheme,
  SchemeEditorStore,
  SchemeSwitchDictionary
} from '@modules/planning/children/scheme-editor/types';
import { OptionsSchemaColumnKeys } from '@modules/planning/children/scheme-editor/types/scheme';
import { Dictionary } from '@common/types';

export const maxSchemeSelector = createSelector(
  (state: SchemeEditorStore) => state.PlannerSchemeEditor.maxScheme,
  (maxScheme: ISchemeEditorState['maxScheme']) => maxScheme ?? ({} as Scheme)
);

export const optionsSchemeSelector = createSelector(
  (state: SchemeEditorStore) => state.PlannerSchemeEditor.maxScheme?.Options as OptionsSchemaDictionary['Options'],
  (Options: OptionsSchemaDictionary['Options']) => Options
);

export const optionsSelector = createSelector(
  optionsSchemeSelector,
  (Options: OptionsSchemaDictionary['Options']) => Object.keys(Options) as Array<OptionsSchemaColumnKeys>
);

export const allSchemesTabsSelector = createSelector(
  maxSchemeSelector,
  (maxScheme: ISchemeEditorState['maxScheme']) => Object.keys(maxScheme ?? {}).concat(SCHEMES_TYPE) as Array<SchemeSwitchDictionary>
);

export const schemeSelectSelector = createSelector(maxSchemeSelector, (maxScheme: ISchemeEditorState['maxScheme']) => {
  const options = {};
  for (let schemeField in maxScheme) {
    if (schemeField !== Dictionary.Options) {
      options[schemeField] = {};
      for (let schemeDictionaryField in maxScheme[schemeField]) {
        options[schemeField][schemeDictionaryField] = maxScheme[schemeField][schemeDictionaryField].availableTypes;
      }
    }
  }
  return options;
});

export const schemeDictionarySelectSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(schemeSelectSelector, (options) => {
    return options[dictionary];
  });
