import React, { useState } from 'react';
import arrayMove from '../utils/arr-move';

type Props<T> = {
  list: T[];
  setHolding: React.Dispatch<React.SetStateAction<boolean>>;
  sortList: (list: T[], itemID: number | string, idx: number) => void;
};

const useDragSort = <T extends { ID: string | number; Serial: number }>(props: Props<T>) => {
  const { list, setHolding, sortList } = props;

  const [oldIndex, setOldIndex] = useState<number | null>(null);
  const [droppable, setDroppable] = useState<number | string | null>(null);
  const [startItemID, setStartItemID] = useState<number | string | null>(null);

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: T, idx: number) => {
    setOldIndex(idx);
    setStartItemID(item.ID);
  };

  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    setDroppable(null);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>, item: T) => {
    setDroppable(item.ID);
    e.preventDefault();
  };

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, item: T, idx: number) => {
    e.preventDefault();
    setDroppable(null);
    setHolding(false);

    const sortedArr = arrayMove<T>(list, oldIndex as number, idx).map((item, i) => ({ ...item, Serial: i + 1 })) as T[];

    setStartItemID(null);

    sortList(sortedArr, startItemID as number, idx);
  };

  return {
    oldIndex,
    droppable,
    dragStartHandler,
    dragEndHandler,
    dragOverHandler,
    dropHandler
  };
};

export default useDragSort;
