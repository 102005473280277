import { planningGetPlanEfficiencyRoutine } from '@modules/planning/children/by-date/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { generateUUID } from '@common/utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PlanIDs } from '@common/types/dictionaries/plan';
import { Project } from '@common/types';

function* planningGetPlanEfficiencyWorker(
  action: PayloadAction<{
    planID: PlanIDs;
    projectID: Project['ID'];
  }>
) {
  const { request, failure, fulfill, success } = planningGetPlanEfficiencyRoutine;
  const { planID, projectID } = action.payload;
  const notifyKey = generateUUID();
  try {
    yield put(request({ notifyKey, planID }));

    const {
      data: { data }
    } = yield call(PlanningAPI.getPlanEfficiency, { planID, projectID });

    yield put(success({ planID, data, notifyKey }));
  } catch (error) {
    yield put(failure({ error, notifyKey }));
  } finally {
    yield put(fulfill({ planID, notifyKey }));
  }
}

export function* planningGetPlanEfficiencyWatcher() {
  yield takeLatest(planningGetPlanEfficiencyRoutine.trigger, planningGetPlanEfficiencyWorker);
}
