import { FC, useEffect, useState } from 'react';
import {
  ILSButton,
  ILSCol,
  ILSForm,
  ILSFormItem,
  ILSInput,
  ILSModal,
  ILSRangePicker,
  ILSRow,
  ILSSelect,
  ILSSpin
} from '@common/components';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { getCurrentTemplate, getTemplatesFetching } from '@modules/documents/children/templates/selectors';
import ILSDocumentTemplateViewer from '@modules/documents/components/document-template-viewer';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';
import {
  documentCreateRoutine,
  documentGetDependentListRoutine,
  documentsGetTemplatesRoutine,
  resetDependentList
} from '@modules/documents/actions';
import { getDocumentsDependentList, getIsFetchingSelector } from '@modules/documents/selectors';
import { resetCurrentTemplate } from '@modules/documents/children/templates/actions';
import './styles.less';
import { Form } from 'antd';
import { DocumentsAddReportButtonComponent } from '@modules/documents/components/form-document-modal/add-report-button';
import { DependentListType, IDependentList } from '@modules/documents/types/document-state';
import { DocumentsTemplateList } from '@modules/documents/containers/template-list';
import { ReportsListRequest } from '@modules/documents/types/documents-api';
import { ROOT_PROJECT_ID_MONITORING } from '@modules/documents/constants/reports';
import { convertDocumentsFormData } from '@modules/documents/utils/convert-form-data';
import { DOC_FILE_TYPES } from '@modules/documents/constants/document-file-type';
import { DocumentType } from '@modules/documents/types/document-type';
import { getNewTemplate } from '@modules/documents/helpers/get-new-template';

const ILSDocumentsFormDocumentModal: FC<{
  open: boolean;
  handleClose: () => void;
  projectId?: number | null;
  module: ReportsListRequest['module'];
}> = ({ open, handleClose, projectId = ROOT_PROJECT_ID_MONITORING, module }) => {
  const [form] = Form.useForm();
  const templatesLoading = useAppSelector(getIsFetchingSelector);
  const dependenceList = useAppSelector(getDocumentsDependentList);
  const templateData = useAppSelector(getCurrentTemplate);
  const templateDataLoading = useAppSelector(getTemplatesFetching);
  const [template, setTemplate] = useState<DocumentTemplate>();
  const [dependencies, setDependencies] = useState<IDependentList[]>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(documentsGetTemplatesRoutine({ projectId: module && projectId }));
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(resetCurrentTemplate());
      dispatch(resetDependentList());
    }
  }, [open]);

  useEffect(() => {
    if (!template) return;
    form.resetFields();
    dispatch(documentGetDependentListRoutine({ type: template?.Type, projectId }));
  }, [template]);

  useEffect(() => {
    if (Array.isArray(dependenceList)) {
      setDependencies(dependenceList);
      //      setDependencies([...dependenceList, { name: 'Период', alias: 'Period' }]);
    } else if (dependenceList?.list?.length) {
      setDependencies([dependenceList]);
    } else {
      setDependencies(undefined);
    }
  }, [dependenceList]);

  const formTemplate = getNewTemplate({ dispatch, template, projectId });

  const onCancel = () => {
    dispatch(resetCurrentTemplate());
    dispatch(resetDependentList());
    handleClose();
    setTemplate(undefined);
  };

  const saveToDocuments = () => {
    dispatch(
      documentCreateRoutine({
        form: { type: template?.Type, template: template?.ID, projectId, ...convertDocumentsFormData(form.getFieldsValue()) },
        withModal: true
      })
    );
  };

  const renderDependenciesFormItem = ({ alias, name, type, multiple, ...d }: IDependentList) => {
    switch (type) {
      case DependentListType.Input:
        return <ILSInput />;
      case DependentListType.Period:
        return <ILSRangePicker />;
      default:
        return (
          <ILSSelect
            maxTagCount={1}
            mode={multiple ? 'multiple' : undefined}
            fieldNames={{ value: 'value', label: 'name' }}
            options={d?.list}
          />
        );
    }
  };

  return (
    <ILSModal
      visible={open}
      onCancel={onCancel}
      width="90vw"
      bodyStyle={{ height: '90vh' }}
      footer={false}
      keyboard={false}
      destroyOnClose
      centered
    >
      <ILSRow gutter={32}>
        <ILSCol span={6} className="position-relative">
          {(templatesLoading || templateDataLoading) && (
            <div className="position-absolute spinner-container flex-center-center">
              <ILSSpin size="large" />
            </div>
          )}
          <DocumentsAddReportButtonComponent projectId={projectId} module={module} />
          <DocumentsTemplateList projectId={projectId} template={template} setTemplate={setTemplate} />
          <ILSForm form={form} onFinish={formTemplate} className="dependencies-form">
            {dependencies?.map(({ alias, name, ...d }) => {
              return (
                <ILSFormItem className={'w-100'} name={alias} label={name} key={alias} rules={[{ required: true }]} required={false}>
                  {renderDependenciesFormItem({ alias, name, ...d })}
                </ILSFormItem>
              );
            })}
            {!!dependencies && (
              <ILSButton type="primary" htmlType="submit" className={'dependencies-form-button'}>
                Сформировать
              </ILSButton>
            )}
            {!!templateData && DOC_FILE_TYPES.includes(template?.Type ?? DocumentType.Empty) && (
              <ILSButton
                className={'dependencies-form-button'}
                children={`Сохранить в разделе "Документы"`}
                type="primary"
                onClick={saveToDocuments}
              />
            )}
          </ILSForm>
        </ILSCol>
        <ILSCol span={18}>
          {templateData ? (
            <ILSDocumentTemplateViewer scrollbarsMode templateData={templateData} height={'85vh'} />
          ) : (
            <h2 className="template-viewer-placeholder">Для просмотра выберите шаблон и заполните данные</h2>
          )}
        </ILSCol>
      </ILSRow>
    </ILSModal>
  );
};

export default ILSDocumentsFormDocumentModal;
