import { ModulePath } from '@common/types';

export const getRoutePathSegments = (pathname: string = window.location.pathname) => {
  return pathname === ModulePath.Dashboard
    ? [ModulePath.Dashboard]
    : pathname
        .split(ModulePath.Dashboard)
        .slice(1)
        .map((path) => `/${path}`);
};
