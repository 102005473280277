import '../styles.less';
import { useOrdersAndTripsContainer } from '../hooks/use-orders-and-trips-container';

import { useShowQuestions } from '@common/feature/questions/hooks/use-show-questions';
import { QuestionsContainer } from '@common/feature/questions/containers/questions-container';
import { OrdersNTripsMainFullContainer } from '@modules/orders-and-trips/containers/main/main-full';

export const OrdersNTripsContainer = () => {
  useOrdersAndTripsContainer();
  const { showQuestions } = useShowQuestions();

  return (
    <section className="orders-and-trips">
      {!showQuestions ? (
        /** Предыдущий вариант модуля релиз 2*/
        // <OrdersNTripsMainContainer />
        /** Текущий вариант модуля релиз 3 */
        <OrdersNTripsMainFullContainer />
      ) : (
        <QuestionsContainer />
      )}
    </section>
  );
};
