import { IILSCellIconActionProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellTextAction = <R extends TableRecord>({ record, dataIndex }: IILSCellIconActionProps<R>): JSX.Element | null => {
  if (dataIndex && record?.[dataIndex]) {
    const { value, onClick, ...recProps } = record[dataIndex];
    const handleClick = () => onClick?.(record);
    return (
      <div className="cursor-pointer" onClick={handleClick} {...recProps}>
        {value}
      </div>
    );
  }
  return null;
};
