import { AnalyticsActionsTypes } from '../actions';

export const analyticsDefaultStatuses: {
  [key in AnalyticsActionsTypes]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  [AnalyticsActionsTypes.SELECT_DATE]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.GET_WIDGET_DATA]: {
    loading: 'Загрузка данных...',
    success: 'Данные загружены'
  },
  [AnalyticsActionsTypes.GET_BOARD_WIDGET]: {
    loading: 'Загрузка  виджетов...',
    success: 'Виджеты загружены'
  },
  [AnalyticsActionsTypes.GET_BOARDS]: {
    loading: 'Загрузка  досок...',
    success: 'Доски загружены'
  },
  [AnalyticsActionsTypes.GET_BOARDS_SETS]: {
    loading: 'Загрузка наборов досок...',
    success: 'Наборы досок загружены'
  },
  [AnalyticsActionsTypes.UPDATE_WIDGET]: {
    loading: 'Обновление положения виджетов...',
    success: 'Виджеты сохранены'
  },
  [AnalyticsActionsTypes.SELECT_BOARD_SET]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.SELECT_BOARD]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.SET_PARAMS]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.SELECT_BOARD_DATE_RANGE]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.CANCEL_WIDGETS_UPDATE]: {
    loading: '',
    success: ''
  },
  [AnalyticsActionsTypes.BATCH]: {
    loading: '',
    success: ''
  }
};
