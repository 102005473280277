import { ILSButton, ILSUserNotify } from '@components/index';
import { ReactSVG } from 'react-svg';
import { Notify, NotifyDurationInSecond } from '@common/types';

function downloadObjectAsJson(exportObj: any, exportName: string) {
  let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  let downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
  ILSUserNotify(Notify.Success, 'Файл JSON успешно загружен', NotifyDurationInSecond.Three, Notify.Success);
}

const onExport = (name: string, json: any) => {
  downloadObjectAsJson(json, name);
};
const onCopy = (text: string) => {
  let input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  ILSUserNotify(Notify.Success, 'Ссылка скопирована в буфер обмена', NotifyDurationInSecond.Three, Notify.Success);
};
export const externalAnalyticDecorator = (data: { [K: string]: any }, level = 1) => {
  let tree = [];
  for (let ind in data) {
    let text = data[ind]['key'] ?? ind;
    if (text && ind) {
      if ('object' === typeof data[ind]) {
        const nextLevelData = Array.isArray(data[ind]) ? data[ind][0] : data[ind];
        let children = childDecorator(nextLevelData, level + 1, ind);
        const title = (
          <div className="export-row flex-between-center">
            {text}
            <ILSButton type="primary" onClick={() => onExport(text, nextLevelData)}>
              Экспорт в JSON
            </ILSButton>
          </div>
        );
        tree.push({ title: title, key: ind, children: children });
      }
    }
  }
  return tree;
};
const childDecorator = (data: { [K: string]: any }, level: number, fieldKey: string | number, copy?: any | boolean) => {
  let tree = [];
  if (Array.isArray(data)) {
    data.forEach((row, i) => {
      let text = row['key'] ?? i;
      let key = text + fieldKey + i;
      const add = { title: text, key: key };
      if (typeof row === 'object') {
        add['children'] = childDecorator(row, level + 1, key);
      } else {
        add['title'] = (
          <span>
            {add['title']} = <span>{row}</span>
          </span>
        );
      }
      tree.push({ ...add });
    });
  } else if (typeof data === 'object') {
    for (let ind in data) {
      let text = data[ind]['key'] ?? ind;
      let key = ind + fieldKey + level;
      const add = { title: text, key: key };
      if (typeof data[ind] === 'object') {
        add['children'] = childDecorator(data[ind], level + 1, key, ind === 'tracedata');
      } else {
        add['title'] = (
          <span>
            {add['title']} = <span>{data[ind]}</span>
          </span>
        );
      }
      if (copy) {
        const copyBtn = (
          <ILSButton
            className="ml-3"
            icon={<ReactSVG className="svg-container" src={'/assets/icons/planning/copy-plan.svg'} />}
            onClick={() => onCopy(data[ind])}
          />
        );
        add['title'] = (
          <div>
            {add['title']} {copyBtn}
          </div>
        );
      }
      tree.push({ ...add });
    }
  }
  return tree;
};
