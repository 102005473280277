import './styles.less';
import { FC } from 'react';
import { TableCustomButtonWithPopover } from '@core/components/table-custom/table-custom-button-with-popover';
import { TableCustomComponentProps } from '@core/components/table-custom/index';
import { useModal } from '@common/hooks/components/use-modal';
import { TableConfigConfigContainer } from '@core/components/table-custom/config-container';

interface IProps
  extends Omit<
    TableCustomComponentProps<any>,
    'ChildTable' | 'recordTableButtons' | 'settingsTableButtons' | 'tableWidgets' | 'actionTableButtons' | 'onGrouping'
  > {}

export const TableCustomConfigButton: FC<IProps> = (props) => {
  const { visible, handleShow } = useModal();

  return (
    <TableCustomButtonWithPopover
      visible={visible}
      title="Настроить конфигурацию таблицы"
      onClick={handleShow}
      icon={'change-table'}
      content={<TableConfigConfigContainer {...props} handleShow={handleShow} />}
    />
  );
};
