import { FC } from 'react';
import { User } from '@common/types';
import { ILSDivider } from '@common/components';
import { ILSTypographyParagraph } from '@components/general/typography';

interface IProps {
  phone: User['Phone'];
  email: User['Login'];
}

export const AccountUserCardDescriptionComponent: FC<IProps> = ({ phone, email }) => {
  return (
    <>
      <ILSTypographyParagraph className={'user-info'}>Телефон:</ILSTypographyParagraph>
      <ILSTypographyParagraph title={phone} className={'user-info'}>
        {phone}
      </ILSTypographyParagraph>
      <ILSDivider />
      <ILSTypographyParagraph className={'user-info'}>Email:</ILSTypographyParagraph>
      <ILSTypographyParagraph title={email} className={'user-info'}>
        {email}
      </ILSTypographyParagraph>
    </>
  );
};
