import { LotPosition, Schedule, ScheduleType, TenderLotPosition } from '@modules/tender-platform/types';
import { DateTimeFormat, Notify } from '@common/types';
import moment, { Moment } from 'moment';
import { cleanEmptyFieldsInArrayObject } from '@common/utils/helpers/object/clean-empty-fields';
import { ILSUserNotify } from '@components/index';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { VATValue } from '@common/constants';
import { formatDate, prepareDateToServer } from '@common/utils/helpers/date-time';
import { DateFormat, ILS_LOT_API_DATE_FORMAT } from '@common/types/general/date-time';
import { isMoment } from 'moment/moment';
import { RcFile } from 'antd/lib/upload';
import { createArrayValuesFromSelectOptions } from '@common/utils/general/create-array-values-from-select-options';
import { getObjectWithoutNilOrEmptyStringValues } from '@common/utils/helpers/object/get-object-without-nil-or-empty-string-values';

export enum TenderFormPart {
  tender = 'tender',
  lot = 'lot'
}

const TENDER_FIELDS = [
  TenderFormField.Name,
  TenderFormField.Type,
  TenderFormField.Private,
  TenderFormField.Description,
  TenderFormField.OfferStartDT,
  TenderFormField.OfferEndDT,
  TenderFormField.ExecutionStartDT,
  TenderFormField.ExecutionEndDT,
  TenderFormField.MinCarrierRating,
  TenderFormField.MinDriverRating,
  TenderFormField.MinDriverRoutes,
  TenderFormField.Documents
];

export const formToSaveData = (
  values: Record<TenderFormField, any>
): Record<TenderFormPart, Partial<Record<TenderFormField, any>>> & { documents: RcFile[] | null } => {
  delete values.ID;
  const oneDayTender = moment(values.ExecutionStartDT).isSame(values.ExecutionEndDT, 'date');
  let documents: RcFile[] | null = null;
  const tender = TENDER_FIELDS.reduce((prev, field) => {
    if (field === TenderFormField.Documents && values[field]) {
      documents = [...values[field]];
      delete values[field];
    }
    if (Object.values(values).some((value: any) => value < 0)) {
      ILSUserNotify(Notify.Error, 'Значения не могут быть отрицательными');
      return prev;
    }
    if (field.includes('Min')) {
      if (!values[field]) {
        values[field] = 0;
      }
    }
    if (isMoment(values[field])) {
      values[field] = prepareDateToServer(moment.utc(values[field]), DateTimeFormat.YYYYMMDDDashHHMM, true);
    }
    prev[field] = values[field];
    delete values[field];
    return prev;
  }, {});

  if (Object.values(values).some((value: any) => value < 0)) {
    ILSUserNotify(Notify.Error, 'Значения не могут быть отрицательными');
  }

  // -- LOT Schedule decorate --
  const [from, till] = Array.isArray(values.Phase) ? values.Phase : [];
  const Schedule: Schedule = {};

  if (oneDayTender) {
    const oneDayOfferDate = moment(values.ExecutionStartDT);
    const singleDate = prepareDateToServer(oneDayOfferDate, ILS_LOT_API_DATE_FORMAT, true);
    Schedule.dates = [singleDate];
  } else {
    if (values.Schedule === ScheduleType.Dates) {
      Schedule[values?.Schedule] = values.dates?.reduce((prev: Array<string>, date: string | Moment | Date) => {
        if (typeof date === 'string') {
          if (date.match(/\d/g)) {
            prev.push(date);
          }
          return prev;
        } else if (date instanceof Date) {
          prev.push(formatDate(date, DateFormat.YYYYMMDDDash));
        } else {
          prev.push(date.format(ILS_LOT_API_DATE_FORMAT));
        }
        return prev;
      }, []);
      delete values.dates;
    } else {
      Schedule[values?.Schedule] = values[values?.Schedule];
      Schedule.from = prepareDateToServer(from, ILS_LOT_API_DATE_FORMAT);
      Schedule.till = prepareDateToServer(till, ILS_LOT_API_DATE_FORMAT);
      delete values[values?.Schedule];
    }
  }
  cleanEmptyFieldsInArrayObject(values.SourceContact);
  cleanEmptyFieldsInArrayObject(values.TargetContact);
  delete values.Phase;
  if (!Schedule[values?.Schedule]) {
    delete Schedule[values?.Schedule];
  }

  // LOT Position decorate
  values[TenderFormField.TenderLotPosition]?.forEach((position: TenderLotPosition, i: number) => {
    values[TenderFormField.TenderLotPosition][i] = getObjectWithoutNilOrEmptyStringValues(position);
    if (Array.isArray(values[TenderFormField.TenderLotPosition][i][LotPosition.RestrictionID])) {
      values[TenderFormField.TenderLotPosition][i][LotPosition.RestrictionID] = null;
    }
    if (!values[TenderFormField.TenderLotPosition][i][LotPosition.Volume]) {
      values[TenderFormField.TenderLotPosition][i][LotPosition.Volume] =
        (values[TenderFormField.TenderLotPosition][i][LotPosition.Width] ?? 0) *
          (values[TenderFormField.TenderLotPosition][i][LotPosition.Height] ?? 0) *
          (values[TenderFormField.TenderLotPosition][i][LotPosition.Length] ?? 0) || null;
    }
  });

  const lot = {
    ...values,
    Schedule,
    [TenderFormField.VAT]: values.VAT ?? VATValue.No,
    [TenderFormField.VehicleType]: createArrayValuesFromSelectOptions(values.VehicleType),
    [TenderFormField.TenderLotUser]: createArrayValuesFromSelectOptions(values.TenderLotUser),
    [TenderFormField.SeparateVehicle]: Number(values.SeparateVehicle ?? 0),
    [TenderFormField.IsTemplate]: +values.hasOwnProperty(TenderFormField.TemplateName)
  };

  return {
    tender: getObjectWithoutNilOrEmptyStringValues(tender),
    lot: getObjectWithoutNilOrEmptyStringValues(lot),
    documents
  };
};
