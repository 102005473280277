import { put, takeLatest } from 'redux-saga/effects';
import { switchActiveMainTabRoutine, showFormRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { MainTabs, MainTabsContainer } from '@modules/orders-and-trips/types/general';

function* switchActiveMainTabWorker(
  action: PayloadAction<{
    tab: MainTabs;
    container: MainTabsContainer.leftBlock;
  }>
) {
  const { failure, fulfill, success } = switchActiveMainTabRoutine;
  const { tab, container } = action.payload ?? {};
  try {
    yield put(success({ tab, container }));
    yield put(showFormRoutine({ formType: null }));
  } catch (error) {
    yield put(failure({ error } as any));
  } finally {
    yield put(fulfill());
  }
}

export function* switchActiveMainTabWatcher() {
  yield takeLatest(switchActiveMainTabRoutine.trigger, switchActiveMainTabWorker);
}
