import { call, debounce, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { DELAY_FOR_SUGGESTIONS } from '@common/constants';
import { addressSuggestionRoutine } from '@common/feature/suggestion-autocomplete/address/actions';
import { AddressAPI } from '@common/api/common/address';
import { AddressSuggestionPayload } from '@common/types/general/api/address';

function* addressSuggestWorker(action: PayloadAction<AddressSuggestionPayload>) {
  const { request, success, failure, fulfill } = addressSuggestionRoutine;
  const { query, onlyCity } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(AddressAPI.suggestions, { q: query });

    yield put(success({ addresses: data, onlyCity, query }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addressSuggestWatcher() {
  yield debounce(DELAY_FOR_SUGGESTIONS, addressSuggestionRoutine.trigger, addressSuggestWorker);
}
