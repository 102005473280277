import { ILSButton, ILSForm, ILSFormItem, ILSInput, ILSTypographyText } from '@components/index';
import { useLocation } from 'react-router';
import { LoginForm } from '../types/components';
import { FC } from 'react';
import { ModulePath } from '@common/types';
import { ILSTypographyTitle } from '@components/general/typography';

export const ILSRecoveryFormComponent: FC<LoginForm> = ({ onFinish, isFetching }) => {
  const history = useLocation();
  const url = window.location.href;
  const link = url.replace(history.pathname, ModulePath.Dashboard);

  return (
    <ILSForm className={'recovery'} name={'recovery'} layout={'vertical'} onFinish={onFinish} initialValues={{ link }}>
      <ILSTypographyTitle level={1} children="Восстановление пароля" />
      <ILSTypographyText ellipsis={false}>
        Укажите свой email, под которым вы&nbsp;зарегистрированы на&nbsp;сайте и&nbsp;на&nbsp;него будет отправлена информация
        о&nbsp;восстановлении пароля.
      </ILSTypographyText>
      <ILSFormItem
        className={'recovery-form-item'}
        name="email"
        label="E-mail"
        rules={[
          { type: 'email', message: 'Некорректное значение E-mail' },
          { required: true, message: 'Пожалуйста, введите Ваш E-mail!' }
        ]}
      >
        <ILSInput />
      </ILSFormItem>
      <ILSFormItem className="hidden-input" name="link">
        <ILSInput type="hidden" />
      </ILSFormItem>
      <ILSButton form={'recovery'} loading={isFetching} type="primary" htmlType="submit">
        Отправить
      </ILSButton>
    </ILSForm>
  );
};
