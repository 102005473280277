import { planningRunCheckAction } from '../actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

//Разрешает выполнение запросов проверки планирования
export const useCheckPlanning = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(planningRunCheckAction({ run: true }));

    return () => {
      dispatch(planningRunCheckAction({ run: false }));
    };
  }, []);
};
