import { ILSSkeleton } from '@common/components';
import { IndexedArray } from '@common/types';
import { AnalyticsInfoWidgetModel, BoardWidgetParams } from '@common/types/dictionaries/analytics';
import { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { infoValueToString } from '@modules/analytics/common/utils/info-value-to-string';
import { AnalyticsTenderContext } from '@modules/analytics/tender/children/tender-analytics/context';
import { AnalyticsTenderModalWidget } from '@modules/analytics/tender/children/tender-analytics/types/widgets';
import { GET_WIDGET_DATA_ERROR } from '@modules/analytics/common/constants';

type Props = {
  data: { info: AnalyticsInfoWidgetModel; widget: AnalyticsTenderModalWidget[] };
  params: IndexedArray<BoardWidgetParams> | undefined;
  storeParams: IndexedArray<{ name: string; value: string | number | undefined }>;
  hideWidget: boolean;
  loading: boolean;
};

const ILSInfoWidget: FC<Props> = ({ data, params, storeParams, hideWidget, loading }) => {
  const widgetValue = useMemo(() => infoValueToString(data?.info?.value), [data]);
  const { handleReviewsModalOpen, handleSetModalWidget } = useContext(AnalyticsTenderContext);

  const handleClick = () => {
    handleSetModalWidget(data.widget);
    handleReviewsModalOpen();
  };

  return (
    <>
      {!hideWidget && (
        <ILSSkeleton loading={loading}>
          <div>
            <div
              onClick={handleClick}
              className={classNames('analytics-info-widget', {
                'analytics-info-widget-inactive': !data?.info?.link?.length && !data?.info?.clickable
              })}
            >
              <span className="value">
                {widgetValue} {data?.info?.unit}
              </span>
              <div className="title">{data?.info?.title !== undefined ? data?.info?.title : GET_WIDGET_DATA_ERROR} </div>
            </div>
          </div>
        </ILSSkeleton>
      )}
    </>
  );
};

export default ILSInfoWidget;
