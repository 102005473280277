import { DateTimeFormat, IndexedArray } from '@common/types';
import {
  MonitoringGetTrackDataModel,
  MonitoringVehicleEventsModel,
  TrackerLastInfoModel,
  TrackGeoModel
} from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointEventStatusType, MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToDatetimeString } from '@common/utils/helpers/date-time/date-time';
import { TrackType } from '@modules/monitoring/children/event-details/types/map';
import { EventType } from '../config';
import { isEmpty } from 'lodash';

type TableRecordProps = {
  eventType: EventType | null | undefined;
  eventDT: string;
  address: string;
  osmRoadName: string;
  speed: string;
  Latitude: string;
  Longitude: string;
};

type DataToTableFunction<R> = (
  data?: IndexedArray<MonitoringVehicleEventsModel>,
  vehicleTracksData?: IndexedArray<MonitoringGetTrackDataModel>,
  trackGeo?: IndexedArray<IndexedArray<TrackGeoModel>>,
  activeVehicleID?: number | undefined
) => Array<
  R & {
    key: string;
  } & TableRecordProps
>;

export const data2table: DataToTableFunction<
  {
    key: string;
  } & Partial<TrackerLastInfoModel>
> = (vehicleData, vehicleTracksData, trackGeo, activeVehicleID) => {
  const res: ReturnType<typeof data2table> = [];
  if (!vehicleData || !vehicleTracksData || !activeVehicleID) {
    return res;
  }
  for (const vehicleIndex in vehicleData) {
    const vehicle: MonitoringVehicleEventsModel = vehicleData[vehicleIndex];

    if (Number(activeVehicleID) !== Number(vehicleIndex) || isEmpty(vehicle?.VehicleTracker)) {
      continue;
    }
    const vehicleTrackerImei = Object.keys(vehicle.VehicleTracker)[0];

    getFactVisitedWaypoints(vehicle?.Trip);
    const trackList = Object.values(vehicleTracksData).reduce(
      (
        acc: {
          eventType: any;
          eventDT: number;
          address: string;
          osmRoadName: string;
          speed: string;
          Latitude: number | null;
          Longitude: number | null;
          Type?: number | null;
        }[],
        VehicleTrack
      ) => {
        if (VehicleTrack?.Tracker?.Track) {
          Object.keys(VehicleTrack.Tracker.Track).forEach((trackDTKey) => {
            const track = VehicleTrack.Tracker.Track[trackDTKey];

            if (track) {
              acc.push({
                eventType: undefined,
                eventDT: track.DT,
                address: trackGeo?.[track.IMEI]?.[trackDTKey]?.GeoAddress?.FullName || '',
                osmRoadName: trackGeo?.[track.IMEI]?.[trackDTKey]?.GeoRoad?.OsmName || '',
                speed: track.Speed?.toFixed(0) || '',
                Latitude: track.Latitude,
                Longitude: track.Longitude,
                Type: track.Type
              });
            }
          });
        }
        return acc;
      },
      []
    );

    let prevType: EventType | null | undefined, prevLatitude: undefined | number | null, prevLongitude: undefined | number | null;
    return trackList
      .sort((a, b) => a.eventDT - b.eventDT)
      .map((item, ind) => {
        const eventType = getEventType(
          item.Type,
          item.eventType,
          prevType,
          prevLatitude,
          prevLongitude,
          item.Latitude,
          item.Longitude,
          item.speed
        );
        const trackItem = {
          ...item,
          key: item.eventDT.toString() + '#' + vehicleTrackerImei + '#' + eventType + '#' + ind,
          eventDT: timestampToDatetimeString(item.eventDT, true, DateTimeFormat.DDMMYYYYPeriodHHMM),
          Latitude: item.Latitude?.toFixed(5) || '',
          Longitude: item.Longitude?.toFixed(5) || '',
          speed: item.speed ? item.speed + ' км/ч' : '',
          eventType
        };
        prevType = eventType;
        prevLatitude = item.Latitude;
        prevLongitude = item.Longitude;
        return trackItem;
      })
      .filter(Boolean) as ReturnType<typeof data2table>;
  }
  return res;
};

type GetFactVisitedWaypointsResult = MonitoringWaypointModel & {
  EventStatusType: MonitoringWaypointEventStatusType;
  EventStatusTime: number;
  eventType: EventType | null | undefined;
  eventDT: number;
  address: string;
  speed: string;
  Latitude: number | null;
  Longitude: number | null;
};

const getFactVisitedWaypoints = (trips?: MonitoringVehicleEventsModel['Trip']) => {
  if (isEmpty(trips)) {
    return [];
  }
  return Object.values(trips)
    .map((trip) => {
      return Object.values(trip.Waypoint)
        .map((wp) => {
          if (
            wp?.EventStatusTime &&
            trip.TripBegin &&
            (wp.EventStatusType === MonitoringWaypointEventStatusType.InTime ||
              wp.EventStatusType === MonitoringWaypointEventStatusType.OnLate)
          ) {
            return {
              ...wp,
              tripBegin: trip.TripBegin,
              eventDT: wp.EventStatusTime,
              eventType: EventType.Stop,
              address: wp?.Depot?.AddressDescription || '',
              speed: '',
              Latitude: wp?.Depot?.LatLon?.lat,
              Longitude: wp?.Depot?.LatLon?.lon
            };
          }
          return undefined;
        })
        .filter(Boolean);
    })
    .flat()
    .filter(Boolean)
    .sort(
      (w1, w2) => (w1 as GetFactVisitedWaypointsResult)?.EventStatusTime - (w2 as GetFactVisitedWaypointsResult)?.EventStatusTime
    ) as Array<GetFactVisitedWaypointsResult>;
};

const getEventType = (
  itemType: TrackType | number | undefined | null,
  eventType: EventType | null | undefined,
  prevEventType: EventType | null | undefined,
  prevLatitude: undefined | number | null,
  prevLongitude: undefined | number | null,
  currentLatitude: undefined | number | null,
  currentLongitude: undefined | number | null,
  currentSpeed: undefined | string | null
): EventType | undefined => {
  if (eventType === EventType.Stop) {
    return EventType.Stop;
  }
  if (prevEventType === undefined || prevEventType === null) {
    return EventType.Start;
  }
  if (itemType === TrackType.Road) {
    return EventType.InRoad;
  } else if (itemType === TrackType.Stop) {
    return EventType.Stop;
  } else if (itemType && itemType <= TrackType.Stop) {
    return EventType.Undefined;
  }
  if (prevLatitude === currentLatitude && prevLongitude === currentLongitude && !currentSpeed) {
    return EventType.Stop;
  } else if (prevEventType === EventType.Stop) {
    return EventType.InRoad;
  }
  if (itemType === TrackType.Road) {
    return EventType.InRoad;
  } else if (itemType === TrackType.Stop) {
    return EventType.Stop;
  } else if (itemType && itemType <= TrackType.Stop) {
    return EventType.Undefined;
  }
  if (currentSpeed) {
    return EventType.InRoad;
  } else {
    return EventType.Stop;
  }
};
