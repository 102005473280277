import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderGetLotTemplateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderGetDistanceRoutine } from '@modules/tender-platform/actions';

function* tenderGetLotTemplateWorker() {
  const { request, success, failure, fulfill } = tenderGetLotTemplateRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.getLotTemplate);
    yield put(success({ data }));
    if (!data?.Distance && data?.Source?.AddressDescription && data?.Target?.AddressDescription) {
      yield put(tenderGetDistanceRoutine({ source: data.Source.AddressDescription, target: data.Target.AddressDescription }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderGetLotTemplateWatcher() {
  yield takeEvery(tenderGetLotTemplateRoutine.trigger, tenderGetLotTemplateWorker);
}
