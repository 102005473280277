import { ModulePath } from '@common/types';

export const SUB_MODULES_WITHOUT_ACCESS = [`${ModulePath.Planner}${ModulePath.Planning}`, `${ModulePath.Planner}${ModulePath.Manager}`];

export const defaultPlannerParams = {
  ILS: {},
  Veeroute: {},
  Yandex: {}
};

