import { emulatorPauseRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { PauseRequest } from '../types/api';

// Ставит на паузу эмуляцию выбранного плана (planID)
function* emulatorPauseWorker(action: PayloadAction<PauseRequest>) {
  const { request, success, failure, fulfill } = emulatorPauseRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    yield call(EmulatorAPI.pause, { planID });

    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorPauseWatcher() {
  yield takeLatest(emulatorPauseRoutine.TRIGGER, emulatorPauseWorker);
}
