import { ILSPlanningResultTableConfirmButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/button-confirm';
import { FC } from 'react';
import { CopyOutlined } from '@ant-design/icons/lib/icons';
import { useSelector } from 'react-redux';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { projectExceededLimitSelector } from '@modules/planning/children/by-date/selectors/project';
import { PlanIDs } from '@common/types/dictionaries/plan';
import { MAX_PLAN_COUNT } from '@modules/planning/children/by-date/constants';

interface IProps {
  handleCopy(planIDs: PlanIDs): void;
}

export const PlannerPlanningCopyPlanButton: FC<IProps> = ({ handleCopy }) => {
  const confirmText = 'Скопировать выбранный план перевозок?';
  const activePlanID = useSelector(activePlanIDSelector);
  const projectExceededLimit = useSelector(projectExceededLimitSelector);
  const disabled = !activePlanID || projectExceededLimit;

  const onCopy = () => {
    !disabled && handleCopy([activePlanID]);
  };

  return (
    <ILSPlanningResultTableConfirmButton
      buttonTooltip={disabled ? `Достигнуто максимальное кол-во планов: ${MAX_PLAN_COUNT}` : 'Скопировать выбранный план перевозок'}
      confirmText={confirmText}
      onConfirm={onCopy}
      icon={<CopyOutlined className={'ils-svg primary'} />}
      disabled={disabled}
    />
  );
};
