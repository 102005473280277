import { Plan } from '@common/types';
import { planningSetExecutionRoutine, planningUnsetExecutionRoutine } from '@modules/planning/children/by-date/actions';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { useDispatch, useSelector } from 'react-redux';
import { projectPlanOnExecutionSelector, projectPlansNamesSelector } from '@modules/planning/children/by-date/selectors/project';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { compareAsString } from '@common/utils';
import { planHasTenderSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { handlePlanForExecution } from '@modules/planning/children/by-date/helpers/plan/handle-plan-for-execution';

export const useHandleExecution = () => {
  const dispatch = useDispatch();
  const planOnExecution = useSelector(projectPlanOnExecutionSelector);
  const planNames = useSelector(projectPlansNamesSelector);
  const hasTender = useSelector(planHasTenderSelector);
  const activePlanID = useSelector(activePlanIDSelector);

  const handleUnsetExecution = (planID: Plan['ID']) => {
    if (compareAsString(planID, planOnExecution?.ID)) {
      const text = `Вы точно хотите снять план с исполнения?`;
      ILSUserNotifyConfirm('Внимание', text, () => dispatch(planningUnsetExecutionRoutine({ planID })));
    } else {
      dispatch(planningUnsetExecutionRoutine({ planID }));
    }
  };

  const handlePlanExecution = ({ planID, unsetPlanID }: { planID: Plan['ID']; unsetPlanID?: Plan['ID'] }) => {
    dispatch(planningSetExecutionRoutine({ planID, unsetPlanID }));
  };

  const checkDriversBeforeHandlePlanOnExecution = ({ unsetPlanID, planID }: { planID: Plan['ID']; unsetPlanID?: Plan['ID'] }) => {
    //TODO add for PlanTotal DriversRepeated: Array<ID>
    if (planNames[activePlanID] === 'test') {
      ILSUserNotifyConfirm(
        'Внимание',
        `В плане ${planNames[activePlanID]} могут быть ошибки из-за повторяющихся водителей. Вы хотите продолжить?`,
        () => {
          handlePlanExecution({
            planID,
            unsetPlanID
          });
        }
      );
    } else {
      handlePlanExecution({
        planID,
        unsetPlanID
      });
    }
  };

  const handleSetExecution = handlePlanForExecution({
    hasTender,
    planOnExecution,
    checkDriversBeforeHandlePlanOnExecution,
    activePlanID,
    planNames
  });

  return { handleSetExecution, handleUnsetExecution };
};
