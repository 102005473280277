import { DOCUMENTS_EXTENSION } from '@common/constants/general/path';

export const ORDERS_TRIPS_PERMITTED_EXTENSIONS = [
  DOCUMENTS_EXTENSION.jpg,
  DOCUMENTS_EXTENSION.jpeg,
  DOCUMENTS_EXTENSION.png,
  DOCUMENTS_EXTENSION.pdf,
  DOCUMENTS_EXTENSION.doc,
  DOCUMENTS_EXTENSION.docx,
  DOCUMENTS_EXTENSION.xls,
  DOCUMENTS_EXTENSION.xlsx
];

export const ORDERS_TRIPS_DOCUMENTS_HEADER_TITLE = 'Документы';
export const ORDERS_TRIPS_PERMITTED_EXTENSIONS_TITLE = 'Допустимые форматы: ';
export const ORDERS_TRIPS_UPLOAD_TITLE = 'Загрузить';
export const ORDERS_TRIPS_READY_UPLOAD_TITLE = 'готов к загрузке';
export const ORDERS_TRIPS_CONTRACT_NAME = 'Заявка-счет.pdf';

