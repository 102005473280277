import { Moment } from 'moment';
import { NewOrderFormTab } from '@modules/orders-and-trips/constants/tabs';
import { SortDirection } from '@common/types';
import { PreCostServiceResponse, ServicesResponsePrice, TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';
import { PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';

export type FakePreCostValuesSource = { Address: string; Contact?: string; Phone?: string };
export type FakePreCostValuesTarget = FakePreCostValuesSource;

export type FakePreCostValuesDirections = {
  Source: Pick<FakePreCostValuesSource, 'Address'>;
  Target: Pick<FakePreCostValuesTarget, 'Address'>;
};

export type FakePreCostValuesCargo = FakePreCostValuesDirections & {
  Cargo: { Mass: number; Height: number; Length: number; Width: number; Amount: number };
};

export type FakePreCostValuesDocuments = FakePreCostValuesDirections & {
  Cargo: { Mass: number };
};

export type FakePreCostValuesVehicle = FakePreCostValuesDirections & {
  LoadDate: Moment;
  LoadStart: Moment;
  LoadEnd: Moment;
  UnloadDate: Moment;
  UnloadEnd: Moment;
  UnloadStart: Moment;
  VehicleProfile: { Mass: number };
  VehicleType: string;
  hydroBoard: boolean;
  loadersCount: boolean;
};

export type FakePreCostValues = FakePreCostValuesCargo | FakePreCostValuesDocuments | FakePreCostValuesVehicle;

export type FakePreCostRequestCargo = FakePreCostValuesCargo;
export type FakePreCostRequestDocuments = FakePreCostValuesDocuments;
export type FakePreCostRequestVehicle = Omit<
  FakePreCostValuesVehicle,
  'LoadDate' | 'LoadStart' | 'LoadEnd' | 'UnloadDate' | 'UnloadEnd' | 'UnloadStart'
> & {
  LoadDate: string;
  LoadStart: string;
  LoadEnd: string;
  UnloadDate: string;
  UnloadEnd: string;
  UnloadStart: string;
};

export type FakePreCostRequest = (FakePreCostRequestCargo | FakePreCostRequestDocuments | FakePreCostRequestVehicle) & {
  type: NewOrderFormTab;
};

export type FakeTenderId = string | number;

export type IntegrationServicesResponse = PreCostServiceResponse;

export type FakePreCostResponse = Record<
  FakeTenderId,
  {
    tenderId: FakeTenderId;
    services: Array<IntegrationServicesResponse>;
  }
>;

export type FakePreCostProposal = Pick<
  ServicesResponsePrice,
  'id' | 'price' | 'currency' | 'description' | 'maxDelivery' | 'minDelivery' | 'info'
> &
  Pick<IntegrationServicesResponse, 'name' | 'convertingErrors' | 'requestError'> & {
    tenderId: FakeTenderId;
    term: string;
  };
export type FakePreCostProposals = Array<FakePreCostProposal>;

export type ProposalValues = {
  Source: Omit<FakePreCostValuesSource, 'Address'>;
  Target: Omit<FakePreCostValuesTarget, 'Address'>;
  User?: Omit<FakePreCostValuesSource, 'Address'>;
};

export type FakeTripContractValues = ProposalValues & {
  alias: TenderOutFastDeliveryServices;
  extra: { id: FakePreCostProposal['id']; info: FakePreCostProposal['info']; price: FakePreCostProposal['price'] };
};
export type FakeTripContractPayloadAction = PayloadAction<FakeTripContractValues>;

export type FakeTripContractRequest = FakePreCostRequest & FakeTripContractValues;

export type FakeContract = { attachment: RcFile };
export type FakeContractPayload = FakeContract & { handleFormVisibility: () => void };

export type FakePreCostAddToServicePayloadAction = PayloadAction<FakeContractPayload>;
export type FakePreCostAddToServiceRequest = FakeTripContractRequest & FakeContract;

export enum FakePreCostSortUnit {
  Price = 'price',
  Term = 'term'
}

export type FakePreCostProposalSortOption = {
  unit: FakePreCostSortUnit;
  direction: SortDirection;
};
