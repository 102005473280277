export enum DashboardAction { // Типы экшенов
  GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO'
}

// Сообщения нотификаций в зависимости от происходящего процесса
export const defaultStatuses: {
  [key in DashboardAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  GET_DASHBOARD_INFO: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' }
};
