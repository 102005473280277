// Состояния эмулятора
export enum EmulatorAction {
  START = 'START',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
  FINISHED = 'FINISHED',
  UPDATE_CONFIG = 'UPDATE_CONFIG'
}

// Начальная и конечная фраза нотификации в зависимости от изменения состояния эмулятора
export const defaultStatuses: {
  [key in EmulatorAction]: {
    loading: string;
    success: string;
    error?: string;
  };
} = {
  START: { loading: 'Старт эмуляции...', success: 'Эмуляция запущена' },
  STOP: { loading: 'Остановка эмуляции...', success: 'Эмуляция остановлена' },
  PAUSE: { loading: 'Остановка эмуляции на паузу', success: 'Эмуляция остановлена на паузу' },
  FINISHED: { loading: 'Завершение эмуляции...', success: 'Эмуляция завершена' },
  UPDATE_CONFIG: { loading: 'Обновление настроек', success: 'Сохранено' }
};
