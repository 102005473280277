import { DocumentWithButtons } from '@modules/documents/types/document';
export const withChildrenKeys = (docs?: DocumentWithButtons[]) =>
  docs?.map((doc, docIdx) => {
    return {
      ...doc,
      key: 'parent' + (doc?.ID ?? docIdx),
      children: doc.children?.map((child, childIdx) => ({
        ...child,
        key: 'parent' + (doc?.ID ?? docIdx) + 'child' + (child?.ID ?? childIdx)
      }))
    };
  });
