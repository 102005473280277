import { createAction } from '@reduxjs/toolkit';
import {
  ISetTableColsActionPayload,
  ISetTableColsOrderActionPayload,
  ISetTableColWidthActionPayload,
  ISetTableDefaultActionPayload,
  ISetTablePageSizeActionPayload,
  ISetTableGroupingActionPayload,
  SetTableConfigActionPayload
} from '@core/types/actions';
import { TableRecord } from '@common/types';

//установка видимости столбцов таблицы
export const setTableCols = createAction<ISetTableColsActionPayload<TableRecord>>('table/SET_COLS');
export const setTableDefault = createAction<ISetTableDefaultActionPayload>('table/SET_DEFAULT');
export const setTableColsOrder = createAction<ISetTableColsOrderActionPayload<TableRecord>>('table/SET_ORDER');
export const setTableColWidth = createAction<ISetTableColWidthActionPayload<TableRecord>>('table/SET_WIDTH');
export const setTablePageSize = createAction<ISetTablePageSizeActionPayload>('table/SET_PAGE_SIZE');
export const setTableGrouping = createAction<ISetTableGroupingActionPayload>('table/SET_GROUPING');
export const resetTableConfig = createAction('table/RESET_TABLE_CONFIG');
export const setTableConfig = createAction<SetTableConfigActionPayload>('table/SET_TABLE_CONFIG');
