import { useTableGrouping } from '@core/hooks';
import { ColumnTable, TableRecord } from '@common/types';
import TableCustomGroupingContainer from './grouping-container';
import { GroupOutlined } from '@ant-design/icons/lib/icons';
import { TableCustomButtonWithPopover } from '@core/components/table-custom/table-custom-button-with-popover';

interface TableCustomGroupingButtonProps {
  columns: ColumnTable<TableRecord>[];
  storedTableGrouping?: ColumnTable<TableRecord>['dataIndex'][];
  onGrouping?(colsForGrouping: ColumnTable<TableRecord>['dataIndex'][]): void;
}

export const TableCustomGroupingButton = ({ columns, storedTableGrouping, onGrouping }: TableCustomGroupingButtonProps) => {
  const { groupingPopoverVisible, handleShow } = useTableGrouping();

  return (
    <TableCustomButtonWithPopover
      visible={groupingPopoverVisible}
      onVisibleChange={handleShow}
      title="Настроить группировку элементов по колонке"
      onClick={handleShow}
      icon={<GroupOutlined className={'ils-svg green'} />}
      data-testid="grouping-button"
      content={
        <TableCustomGroupingContainer
          columns={columns}
          storedTableGrouping={storedTableGrouping}
          onGrouping={onGrouping}
          closeGroupingPopover={handleShow}
        />
      }
    />
  );
};
