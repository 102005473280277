import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { Carriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { JoinChar, NOT_ENTERED } from '@common/constants/general';
import { User } from '@common/types';

export const carriers2Table = (carriers?: Carriers) =>
  createArrayFromIndexedArray(carriers).map((carrier) => {
    const { Carrier } = carrier;
    const users =
      (createArrayFromIndexedArray(Carrier?.User) as Array<User>).reduce((summaryUsers, user) => {
        if (user?.Name) {
          summaryUsers.push(user?.Name);
        }
        return summaryUsers;
      }, [] as string[]) || [];

    return {
      ...Carrier,
      ID: carrier.ID,
      key: carrier.ID,
      Name: Carrier.Name,
      INN: Carrier.Info?.INN ?? `Номер компании ${carrier.CarrierID}`,
      ClientContact: Carrier?.User?.[0]?.Name ?? NOT_ENTERED,
      Phone: Carrier.Info?.Phone ?? NOT_ENTERED,
      Rating: carrier.Rating ?? NOT_ENTERED,
      MinCarrierRating: carrier.Rating.UserRating ?? NOT_ENTERED,
      User: users?.length ? users.join(JoinChar.Comma) : NOT_ENTERED
    };
  });
