import { OrderStatus } from '@common/models/order';
import { ColumnTable, DictionariesEndpoint, Dictionary, Keys, TableName, TableRecord } from '@common/types';
import { ILSPlanningOrderTableComponent } from '@modules/planning/children/by-date/components/tables/order';
import { orderConfig } from '@modules/planning/children/by-date/config/order';
import { ORDER_REFERENCE_TABLES } from '@modules/planning/children/by-date/constants';
import { OrderData, PlanningByDateState } from '@modules/planning/children/by-date/types';
import { SelectedOrders } from '@modules/planning/types';
import { FC, useEffect } from 'react';
import { IDragDropContainer } from 'react-drag-drop-container';
import { useDispatch, useSelector } from 'react-redux';
import { saveOrderByDataIndex } from '@modules/planning/children/by-date/helpers/tables/handlers/order/save-order-by-data-index';
import { useOrderTableWidgetsNButtons } from '@modules/planning/children/by-date/hooks/tables/use-order-table-widgets-and-buttons';
import { order2PlannerTable } from '@common/models/order/decorators';
import { useCloseVehiclesTableAfterOrdersDelete } from '@modules/planning/children/by-date/hooks';
import { handleDeleteOrders } from '@modules/planning/children/by-date/helpers/tables/handlers/order/handle-delete-orders';
import { DictionaryCreateRequest, DictionaryOperation } from '@common/types/general/api/dictionary';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { orderTableSelector } from '@modules/planning/children/by-date/selectors/components/tables/map-tables';
import { planningFocusNExpandRowIdsSelector } from '@modules/planning/children/by-date/selectors/planning';
import { handleCheckDeleteRows } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-check-delete-rows';
import { canEditOrder } from '@modules/planning/children/by-date/helpers/tables/can-edit/can-edit-order';

interface IProps {
  orderStatus: OrderStatus;
  className?: string;
  showAddOrder?: boolean;
  handleShowVehicleTable: (val: boolean) => void;
  orderSummary: OrderData | null;
  dataSource: ReturnType<typeof order2PlannerTable>;
  selectedOrdersIDs: SelectedOrders;
  closeTableButton?: JSX.Element;
  pagination?: boolean;
  overscanRowCount?: number;
  showVehicleTable?: boolean | undefined;

  addOrderForVehicle(existTrip: boolean, tripIDForOrder?: number): void;

  onItemDrop(e: IDragDropContainer, record: ReturnType<typeof order2PlannerTable>[number]): void;

  onSelectRows(selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined): void;
}

export const ILSPlanningOrderTableContainer: FC<IProps> = ({
  orderStatus,
  className,
  addOrderForVehicle,
  handleShowVehicleTable,
  onItemDrop,
  orderSummary,
  selectedOrdersIDs,
  dataSource,
  showVehicleTable,
  onSelectRows,
  ...props
}) => {
  const dispatch = useDispatch();
  const { depotData, projectID, catalogInfo, allOrdersIDs, refData } = useSelector(orderTableSelector);
  const { focusRow } = useSelector((state: PlanningByDateState) =>
    planningFocusNExpandRowIdsSelector(state, TableName.PlanningNotDeliveredOrder)
  );

  useCloseVehiclesTableAfterOrdersDelete(allOrdersIDs, handleShowVehicleTable);
  const { catalogLoad } = useCatalog();

  useEffect(() => {
    catalogLoad(Dictionary.Worktime);
    catalogLoad(Dictionary.DepotOverload);
  }, []);

  const handleSave = (
    record: ReturnType<typeof order2PlannerTable>[number],
    dataIndex: ColumnTable<TableRecord>['dataIndex'],
    value: unknown
  ) => {
    saveOrderByDataIndex({ record, dataIndex, dispatch, value, allOrdersIDs });
  };

  const handleCreate = (adding: any) => {
    const payload: DictionaryOperation<DictionaryCreateRequest> = {
      dictionary: Dictionary.Order,
      values: [adding],
      operation: DictionariesEndpoint.Create
    };
    if (projectID) {
      if (depotData) {
        payload.values = payload.values.map((newOrder: { ProjectID: number }) => {
          newOrder.ProjectID = projectID;
          return newOrder;
        });
        dispatch(dictionaryPlannerRoutine(payload));
      } else {
        catalogLoad?.(Dictionary.Depot);
        setTimeout(() => {
          handleCreate(adding);
        }, 500);
      }
    }
  };

  const { settingsTableButtons, tableWidgets, modifiedProps } = useOrderTableWidgetsNButtons({
    addOrderForVehicle,
    handleShowVehicleTable,
    orderSummary,
    showVehicleTable,
    props
  });

  return (
    <ILSPlanningOrderTableComponent
      {...modifiedProps}
      {...props}
      className={className}
      tableWidgets={tableWidgets}
      orderStatus={orderStatus}
      tableName={TableName.PlanningNotDeliveredOrder}
      focusRow={focusRow}
      refTable={ORDER_REFERENCE_TABLES}
      dataSource={dataSource}
      selectedRows={selectedOrdersIDs}
      refData={refData}
      dictionaryLoad={catalogLoad}
      dictionaryInfo={catalogInfo}
      handleSave={handleSave}
      handleCreate={handleCreate}
      handleDelete={handleDeleteOrders({ selectedOrdersIDs, onSelectRows, dispatch })}
      handleCheckDelete={handleCheckDeleteRows(Dictionary.Order)}
      onSelectRows={onSelectRows}
      onCanEdit={canEditOrder}
      config={orderConfig}
      settingsTableButtons={settingsTableButtons}
    />
  );
};

