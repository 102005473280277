import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const BOTTOM_PADDING = 0;
export const boardToPdf = async (props: {
  board: HTMLDivElement;
  canvasHeight: number;
  canvasWidth: number;
  fileName?: string | null;
  setDownloadingPDF?: (value: boolean) => void;
}) => {
  const { board, canvasHeight, fileName, canvasWidth, setDownloadingPDF } = props;

  await html2canvas(board, {
    width: canvasWidth,
    height: board.scrollHeight,
    scale: 1,
    useCORS: true,
    logging: false,
    allowTaint: true,
    foreignObjectRendering: false,
    removeContainer: true,
    ignoreElements: (element: Element) => {
      return element.hasAttribute('data-html2canvas-ignore');
    }
  }).then(function (canvas) {
    let pageWidth = canvasWidth;
    let pageHeight = canvasHeight;

    const boardCoefficient = canvasHeight / canvasWidth;
    const orientation = boardCoefficient > 1 ? 'p' : 'l';

    let pdf = new jsPDF(orientation, 'px', 'a4');

    const pageCoefficient = pdf.internal.pageSize.height / pdf.internal.pageSize.width;
    const sizeCoefficient = boardCoefficient / pageCoefficient;

    const contentHeight = canvasHeight;
    for (let i = 0; i < Math.round(board.scrollHeight / contentHeight); i++) {
      const srcImg = canvas;
      const sX = 0;
      const sY = contentHeight * i;
      const sWidth = canvas.width;
      const sHeight = contentHeight;
      const dX = 0;
      const dY = 0;
      const dWidth = canvas.width;
      const dHeight = contentHeight;
      const onePageCanvas = document.createElement('canvas');
      onePageCanvas.setAttribute('width', pageWidth.toString());
      onePageCanvas.setAttribute('height', pageHeight.toString());
      let ctx = onePageCanvas.getContext('2d');

      ctx?.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
      const canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);
      if (ctx) {
        ctx.fillStyle = '#FFFFFF';
        ctx?.fillRect(0, 0, pageWidth, pageHeight);
      }
      const width = sizeCoefficient > 1 ? pdf.internal.pageSize.width / sizeCoefficient : pdf.internal.pageSize.width;

      if (i > 0) {
        pdf.addPage();
      }
      pdf.setPage(i + 1);

      pdf.addImage(canvasDataURL, 'PNG', 0, 0, width, 0);
    }
    pdf.save(fileName || 'board-download.pdf');
    setDownloadingPDF?.(false);

    ILSUserNotify(Notify.Success, 'Файл скачан', 0.1);
  });
};
