import { FC } from 'react';
import { SCHEME_TYPES_ENTRIES } from '@modules/planning/children/scheme-editor/constants';
import { ILSInput } from '@common/components';
import classnames from 'classnames';

interface IProps {
  type: boolean;
}

export const SchemeEditorKeysTypes: FC<IProps> = ({ type }) => {
  return (
    <aside className={classnames('scheme-editor-scheme-info', { hide: !type })}>
      {SCHEME_TYPES_ENTRIES.map(([type, description]) => (
        <ILSInput key={type} disabled value={description} addonBefore={type} />
      ))}
    </aside>
  );
};
