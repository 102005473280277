import { call, put } from 'redux-saga/effects';
import { AddressAPI } from '@common/api/common/address';
import { EMPTY_STRING } from '@common/constants';
import { GetAddressResponse, GetCoordinatesResponse } from '@common/types/general/api/address';

import { checkCoordsModalFromRoutine } from '@common/utils/get-coords-modal/get-coordinates-modal';
import { ErrorPayload } from '@common/types/general/check-coords';
import { UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';
import { ArgCheckCoord, DictionariesEndpoint, UpdateOption } from '@common/types/general';
import { showError } from '@common/utils/coords-error/coords-error';
import { isEmpty } from 'lodash';

export function* checkCoordsDaData(
  { address, coords, dictionary, ID }: ArgCheckCoord,
  options: UpdateOption,
  errorPayload: ErrorPayload,
  updateRoutine: UnifiedRoutine<(payload?: any) => Action>
) {
  let respDataByAddress: GetCoordinatesResponse | undefined = undefined;
  let respDataByCoords: GetAddressResponse | undefined = undefined;

  if (coords?.[0] && coords?.[1]) {
    try {
      const { data } = yield call(AddressAPI.getAddress, { lat: coords[0], lon: coords[1] });
      if (data?.data?.length) {
        respDataByCoords = data.data as GetAddressResponse;
      }
    } catch (error) {
      showError(errorPayload.getAddressError);
    }
  }
  if (address) {
    try {
      const { data: suggestionsData } = yield call(AddressAPI.suggestions, { q: address.replaceAll(',', EMPTY_STRING) });
      const { data } = yield call(AddressAPI.getCoordinates, { address: suggestionsData.data[0].value });
      respDataByAddress = data.data;
    } catch (error) {
      showError(errorPayload.getCoordinatesError);
    }
  }

  const values: Record<string, any> = yield call(
    checkCoordsModalFromRoutine,
    address,
    coords?.join(','),
    respDataByCoords,
    respDataByAddress,
    options
  );
  if (values && !isEmpty(values) && dictionary && ID) {
    yield put(updateRoutine.trigger({ dictionary, ids: [ID], values, operation: DictionariesEndpoint.Update }));
  }
}
