import '../styles.less';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ILSSpinCenter } from '@components/index';
import { fetchSelector } from '../selectors';
import { planAnalyticGetRoutine } from '../actions';
import { timestampToDateTimeStringWithSeconds } from '@common/utils/helpers/date-time/date-time';
import { parse } from 'query-string';
import { ILSPlanAnalyticComponent } from '@modules/plan-analytic/components';

export const ILSPlanAnalytic = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(fetchSelector);
  const { search } = useHistory().location;
  const { plan: planID, provider, timeFromStart } = parse(search);

  useEffect(() => {
    if (planID && timeFromStart && !isFetching) {
      const strPlanningDateTime = timestampToDateTimeStringWithSeconds(Number(timeFromStart), 0);
      planID && dispatch(planAnalyticGetRoutine({ planID, strPlanningDateTime }));
    }
  }, []);

  return (
    <div className="plan-analytic-content">
      {isFetching ? (
        <ILSSpinCenter tip={'Загрузка логов планирования'} spinning={isFetching} />
      ) : (
        <ILSPlanAnalyticComponent provider={provider} />
      )}
    </div>
  );
};
