import { call, put, select, takeLatest } from 'redux-saga/effects';
import { addOrderToServiceFromFakeRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { fakePreCostValuesSelector } from '@modules/orders-and-trips/selectors/integrations';
import {
  FakePreCostAddToServicePayloadAction,
  FakePreCostAddToServiceRequest,
  FakeTripContractRequest
} from '@modules/orders-and-trips/types/fake-pre-cost';

function* addOrderToServiceFromFakeWorker(action: FakePreCostAddToServicePayloadAction) {
  const { request, success, failure, fulfill } = addOrderToServiceFromFakeRoutine;
  try {
    const values: FakeTripContractRequest = yield select(fakePreCostValuesSelector);
    const { handleFormVisibility } = action.payload;
    const requestBody: FakePreCostAddToServiceRequest = { ...values, ...action.payload };
    yield put(request());
    yield call(OrdersNTripsApi.addOrderToServiceFromFake, requestBody);
    yield put(success({ message: 'Заявка создана, она находится на оформлении в Мои заявки' }));
    handleFormVisibility();
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addOrderToServiceFromFakeWatcher() {
  yield takeLatest(addOrderToServiceFromFakeRoutine.trigger, addOrderToServiceFromFakeWorker);
}
