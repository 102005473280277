import { takeLatest } from 'redux-saga/effects';
import { DocumentsActions, documentsRoutinesWithNotifier } from '../actions';
import { Module, Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { EMPTY_STRING } from '@common/constants';
import { NotifyMessage } from '@common/types/general/reducers';

export const DocumentsNotificationStatuses: Partial<
  Record<
    DocumentsActions,
    {
      loading: string;
      success: string;
      error?: string;
    }
  >
> = {
  [DocumentsActions.SAVE_REPORT]: { loading: 'Сохранение отчёта...', success: 'Отчёт добавлен в конец списка' },
  [DocumentsActions.DELETE_REPORT]: { loading: 'Удаление отчёта...', success: 'Отчёт удалён' },
  [DocumentsActions.GET_REPORTS_LIST]: { loading: 'Загрузка списка доступных отчётов...', success: 'Отчёты доступны для выбора' }
};

const getNotificationMessage = <T extends string>(defaultMessage: NotifyMessage<T>, actions: any) => {
  return (action: T, type: Notify.Loading | Notify.Success | Notify.Error, payload?: { message: string; hideSuccessMessage?: string }) => {
    if (payload?.hideSuccessMessage) return payload.hideSuccessMessage;
    return payload?.message || defaultMessage?.[actions?.[action]]?.[type] || EMPTY_STRING;
  };
};

/**метод получения префикса идентификатора для уведомления*/
const getKeyPrefix = () => Module.Documents;

/**метод преобразования типа action к типу операции из перечисления Action
 * @param actionName - например CREATE, UPDATE, DELETE, CLONE....
 */
const getActionFromRoutine = (actionName: string): DocumentsActions => {
  return actionName as DocumentsActions;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, DocumentsActions>({
  getActionFromRoutine,
  getKeyPrefix,
  getNotificationMessage: getNotificationMessage<DocumentsActions>(DocumentsNotificationStatuses, DocumentsActions)
});

let errorWorker = notifierWorkerCreator<{ error: string | string[]; notificationMessage?: string }, DocumentsActions>({
  getActionFromRoutine,
  getKeyPrefix,
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* documentsNotifierWatcher() {
  for (let routine of documentsRoutinesWithNotifier) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
