import { AnalyticsWidgetCatalogModel } from '@common/types/dictionaries/analytics';
import { widgetsPreviews, widgetsTypePreviews } from '@modules/analytics/common/config/widget-previews';

export const getPreview = (item: AnalyticsWidgetCatalogModel) => {
  const byClass = widgetsPreviews[item.ClassName]?.img;
  if (byClass) {
    return byClass;
  } else {
    return widgetsTypePreviews[item.Type[0]]?.img;
  }
};
