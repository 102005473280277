import { PayloadAction } from '@reduxjs/toolkit';
import { saveAsTemplateRoutine } from '@modules/documents/actions';
import { call, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* saveAsTemplateWorker(action: PayloadAction<{ id: number | string }>) {
  try {
    yield call(Documents.saveAsTemplate, action.payload);
    ILSUserNotify(Notify.Success, 'Шаблон сделки успешно создан');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* saveAsTemplateWatcher() {
  yield takeEvery(saveAsTemplateRoutine.trigger, saveAsTemplateWorker);
}
