import { useEffect } from 'react';
import { DataSource, DepotTypeId, MapItems, MapSettings, MarkerWithRecord, PolyWithRecord, TableRecord } from '@common/types';
import { deepEqual } from 'fast-equals';
import { ILSMap, ILSMarker, ILSPolygon } from '@core/containers/map';
import L, { FeatureGroup, LatLng, LatLngExpression, LatLngTuple, LayerGroup } from 'leaflet';
import { GeohashDecodePoints, GeohashEncodePoints } from '@common/decorators/latlon-geohash';
import { carMarker, colorMarker, customMarker, defaultMarker } from '@core/components/map/icons';
import { LControlDrawExt } from '@core/containers/map/draw-plugin';
import { int2color } from '@common/decorators/color';
import { CATALOG_DEFAULT_LAYERS } from '@modules/catalog/constants';
import { MapLayer } from '@modules/catalog/types/components';
import homeMarker from '@core/components/map/icons/storage.svg';
import clientMarker from '@core/components/map/icons/user.svg';
import loadMarker from '@core/components/map/icons/take.svg';
import { IRecord } from '@modules/catalog/types/catalog';
import { CollectedDictionaryLayers, LayerData } from '@modules/catalog/constants/catalogs';
import { getLatLngExpression } from '@common/utils';
import { isEmpty, isString } from 'lodash';

export enum LAYER_TYPES {
  MARKER = 'marker',
  POLYGON = 'polygon'
}

export const useTableMap = <R extends TableRecord>(
  record: R,
  dataSource: R[] | undefined,
  type: string,
  activeKeys: (string | number)[] | null | undefined,
  field: string,
  colorField: string | undefined,
  dontEdit: boolean | undefined,
  mapSettings: MapSettings | undefined,
  Map: ILSMap | undefined | null,
  mapDrawControl: LControlDrawExt | undefined,
  mapTableItems: MapItems,
  setMapSettings: (mapSettings: MapSettings) => void,
  setMapTableItems: (mapTableItems: MapItems) => void,
  setMapDrawControl: (drawControl: LControlDrawExt | undefined) => void,
  setIsMapEditActive: (isActiveEdit: boolean) => void,
  onSave: (record: R, newProp: any) => void
) => {
  useEffect(() => {
    let polygons: PolyWithRecord[] | undefined;
    let markers: MarkerWithRecord[] | undefined;

    const mapSettingsNew: {
      edit: boolean;
      remove: boolean;
      type: string;
      draw?: boolean;
    } = {
      edit: !dontEdit,
      remove: false,
      type
    };
    if (record) {
      if (type === LAYER_TYPES.MARKER && !markers) {
        markers = [
          {
            coords: record[field],
            color: colorField && record[colorField],
            type: LAYER_TYPES.MARKER,
            record: record
          }
        ];
      }
      if (type === LAYER_TYPES.POLYGON && !polygons) {
        polygons = [
          {
            coords: record[field],
            color: colorField && record[colorField],
            record: record,
            type: LAYER_TYPES.POLYGON
          }
        ];
      }
      mapSettingsNew.edit = !!record[field];
      mapSettingsNew.draw = !record[field];
    } else if (dataSource) {
      const data: R[] = [];
      dataSource.length &&
        activeKeys &&
        dataSource.forEach((d) => {
          if (
            activeKeys.length && //@ts-ignore
            (activeKeys.includes(d.ID) || activeKeys.includes(d.key) || (activeKeys.includes('addRow') && d.rowKey === 'addRow'))
          ) {
            data.push(d);
          }
        });
      if (type === LAYER_TYPES.MARKER) {
        //@ts-ignore
        markers = data.map((d: DataSource) => {
          if (!d[field]) mapSettingsNew.draw = true;
          if (d[field]) mapSettingsNew.edit = true;

          return {
            coords: d[field],
            record: d,
            type: LAYER_TYPES.MARKER,
            color: colorField && d[colorField]
          };
        });
      }
      if (type === LAYER_TYPES.POLYGON) {
        //@ts-ignore
        polygons = data.map((d: DataSource) => {
          if (!d[field]) mapSettingsNew.draw = true;
          if (d[field]) mapSettingsNew.edit = true;

          return {
            coords: d[field],
            record: d,
            type: LAYER_TYPES.POLYGON,
            color: colorField && d[colorField]
          };
        });
      }

      //запрет редактирования, если много элементов
      if (mapSettingsNew.edit && data.length > 1) {
        mapSettingsNew.edit = false;
        mapSettingsNew.draw = false;
      }
    }

    if (mapSettingsNew.draw) {
      clearMap(Map, mapTableItems);
    }

    let updateMapTableItems = false;

    const newMapTableItems: MapItems = {
      markers: [],
      polygons: []
    };

    if (mapTableItems.markers.length === markers?.length) {
      for (const ind in markers) {
        if (!mapTableItems.markers.find((mItem) => deepEqual(mItem.marker, markers![ind]))) {
          newMapTableItems.markers = markers.map((marker) => {
            return { marker: marker };
          });

          updateMapTableItems = true;
          break;
        }
      }
    } else {
      if (markers?.length || mapTableItems.markers.length !== 0) {
        newMapTableItems.markers = markers?.length
          ? markers.map((marker) => {
              return { marker: marker };
            })
          : [];

        updateMapTableItems = true;
      }
    }

    if (mapTableItems.polygons.length === polygons?.length) {
      for (const ind in polygons) {
        if (!mapTableItems.polygons.find((mItem) => deepEqual(mItem.poly, polygons![ind]))) {
          newMapTableItems.polygons = polygons.map((poly) => {
            return { poly: poly };
          });
          updateMapTableItems = true;
          break;
        }
      }
    } else if (polygons?.length || mapTableItems.polygons.length !== 0) {
      newMapTableItems.polygons = polygons?.length
        ? polygons.map((polygon) => {
            return { poly: polygon };
          })
        : [];

      updateMapTableItems = true;
    }

    if (!deepEqual(mapSettings, mapSettingsNew)) {
      setMapSettings(mapSettingsNew);
      updateMapTableItems = true;
    }

    if (updateMapTableItems) {
      clearMap(Map, mapTableItems);
      const resultMapItems = updateMapTableItem(
        Map,
        setIsMapEditActive,
        onSave,
        mapTableItems,
        newMapTableItems,
        mapSettingsNew,
        record,
        field
      );

      if (resultMapItems) {
        const { markers, polygons, layer, drawControl } = resultMapItems;
        setMapTableItems({
          markers,
          polygons,
          layer
        });
        if (drawControl !== mapDrawControl) {
          setMapDrawControl(drawControl);
        }
      }
    }
  }, [record, dataSource, type, field, colorField, activeKeys, dontEdit, Map]);
};

const clearMap = (Map: ILSMap | undefined | null, mapTableItems: MapItems) => {
  if (Map) {
    if (mapTableItems.layer) {
      mapTableItems.layer.remove();
    }
    mapTableItems.markers.forEach((mItem) => {
      if (mItem.lMarker && !mapTableItems.layer) {
        if (!mapTableItems.layer) {
          Map?.deleteAnyLayer(mItem.lMarker);
        }
      }
    });
    mapTableItems.polygons.forEach((mItem) => {
      if (mItem.lPoly && !mapTableItems.layer) {
        if (!mapTableItems.layer) {
          Map?.deleteAnyLayer(mItem.lPoly);
        }
      }
    });
  }
};

const updateMapTableItem = <R extends TableRecord>(
  Map: ILSMap | undefined | null,
  setIsMapEditActive: (a: boolean) => void,
  onSave: (record: R, newProp: any) => void,
  mapTableItems: MapItems,
  newMapTableItems: MapItems,
  mapSettings: MapSettings,
  record: R,
  field: string
) => {
  const { markers, polygons } = newMapTableItems;

  const drawPolygon = (drawControl: LControlDrawExt) => {
    if (drawControl) {
      if (drawControl.drawPolygon()) setIsMapEditActive(true);
    }
  };

  const drawMarker = (drawControl: LControlDrawExt) => {
    if (markers.length === 1 && (!markers?.[0]?.marker?.coords || !Object.keys(markers?.[0]?.marker?.coords).length)) {
      if (drawControl) {
        if (drawControl.drawMarker()) setIsMapEditActive(true);
      }
    }
  };

  if (Map) {
    let drawControl: undefined | LControlDrawExt = undefined;
    const onEditStop = () => {
      setIsMapEditActive(false);
    };

    setIsMapEditActive(false);
    if (mapSettings?.draw) {
      if (polygons.length) {
        const onPolygonCreate = (e: any) => {
          if (e._layers && Object.values(e._layers).length) {
            const newCoords = ((Object.values(e._layers)[0] as L.Polygon).getLatLngs()[0] as LatLng[])?.map((it) => [it.lat, it.lng]);
            if (newCoords) {
              const newGeohash = GeohashEncodePoints(newCoords);
              if (newGeohash && polygons![0]) {
                onSave(record ?? (polygons![0].poly as PolyWithRecord)?.record, { [field]: newGeohash });
              }
            }
          }
        };

        drawControl = Map.enableDrawing({
          onLayerCreate: onPolygonCreate,
          onEditStop,
          drawToolbarOption: {
            draw: {
              circlemarker: false,
              circle: false,
              marker: false,
              polyline: false,
              rectangle: false
            },
            edit: {
              remove: false
            }
          }
        });
        if (drawControl) {
          drawPolygon(drawControl);
        }
      }

      if (markers.length) {
        const onMarkerCreate = (e: any) => {
          if (e._layers && Object.values(e._layers).length) {
            const latLng = (Object.values(e._layers)[0] as L.Marker).getLatLng();

            const latLon = {
              lat: latLng.lat,
              lon: latLng.lng
            };

            onSave(record ?? markers?.[0]?.marker?.record, { [field]: latLon });
          }
        };

        drawControl = Map.enableDrawing({
          onLayerCreate: onMarkerCreate,
          onEditStop,
          drawToolbarOption: {
            draw: {
              circlemarker: false,
              circle: false,
              polygon: false,
              polyline: false,
              rectangle: false
            },
            edit: {
              remove: false
            }
          }
        });
        if (drawControl) {
          drawMarker(drawControl);
        }
      }
      return {
        markers: markers,
        polygons: polygons,
        layer: drawControl?.getEditLayerGroup(),
        drawControl: drawControl
      };
    } else if (mapSettings?.edit) {
      const mapPolygonItems: {
        poly: PolyWithRecord;
        lPoly: L.Polygon;
      }[] = [];

      const mapMarkersItems: {
        marker: MarkerWithRecord;
        lMark: L.Marker;
      }[] = [];
      let newLayerGroup: FeatureGroup | LayerGroup | undefined = undefined;
      if (polygons?.length) {
        polygons.forEach((mItem) => {
          if (mItem.poly.coords) {
            const { coords, color } = mItem.poly;
            const latLng = isString(coords) ? GeohashDecodePoints(coords) : coords;
            if (!isEmpty(latLng)) {
              const lPoly = new ILSPolygon(latLng as LatLngExpression[] | LatLngExpression[][], { color }).L;
              mapPolygonItems.push({
                poly: mItem.poly,
                lPoly
              });
            }
          }
        });
        const onLayerChange = (layers: { [K: string]: L.Layer }) => {
          if (layers && mapPolygonItems.length) {
            mapPolygonItems.forEach((mItem) => {
              const newCoords = (mItem.lPoly.getLatLngs()[0] as LatLng[])?.map((it) => [it.lat, it.lng]);
              if (newCoords) {
                const newGeohash = GeohashEncodePoints(newCoords);
                if (newGeohash) {
                  //@ts-ignore
                  onSave(record ?? mItem.poly.record, { [field]: newGeohash });
                }
              }
            });
          }
          setIsMapEditActive(false);
        };
        drawControl = Map.enableDrawing({
          layersToEdit: mapPolygonItems.map((m) => m.lPoly),
          onLayerChange,
          onEditStop,
          drawToolbarOption: {
            draw: false,
            edit: {
              remove: false
            }
          }
        });
        newLayerGroup = drawControl?.getEditLayerGroup();
      } else if (markers?.length) {
        markers.forEach((mItem) => {
          if (mItem.marker.coords) {
            const { coords } = mItem.marker;
            if (!isEmpty(coords)) {
              const lMark = new ILSMarker({
                coords: getLatLngExpression(coords) as LatLngExpression,
                options: { icon: defaultMarker }
              }).L;
              mapMarkersItems.push({
                marker: mItem.marker,
                lMark
              });
            }
          }
        });
        const onLayerChange = (layers: { [K: string]: L.Layer }) => {
          if (layers && mapMarkersItems.length) {
            mapMarkersItems.forEach((mItem) => {
              const newCoords = {
                lat: mItem.lMark.getLatLng()?.lat,
                lon: mItem.lMark.getLatLng()?.lng
              };
              if (newCoords.lat && newCoords.lon) {
                //@ts-ignore
                onSave(record ?? mItem.marker.record, { [field]: newCoords });
              }
            });
          }
          setIsMapEditActive(false);
        };
        drawControl = Map.enableDrawing({
          layersToEdit: mapMarkersItems.map((m) => m.lMark),
          onLayerChange,
          onEditStop,
          drawToolbarOption: {
            draw: false,
            edit: {
              remove: false
            }
          }
        });
        newLayerGroup = drawControl?.getEditLayerGroup();
      } else {
        Map.disableDrawing();
        setIsMapEditActive(false);
      }

      return {
        polygons: mapPolygonItems,
        markers: mapMarkersItems,
        layer: newLayerGroup,
        drawControl: drawControl
      };
    } else {
      Map.disableDrawing();
      setIsMapEditActive(false);

      const mapPolygonItems: {
        poly: PolyWithRecord;
        lPoly: L.Polygon;
      }[] = [];

      const mapMarkersItems: {
        marker: MarkerWithRecord;
        lMark: L.Marker;
      }[] = [];

      const tableItemsLayerGroup = Map.addLayerGroup([]);

      polygons.forEach((mItem) => {
        if (mItem.poly.coords) {
          const { coords, color } = mItem.poly;
          const latLng = isString(coords) ? GeohashDecodePoints(coords) : coords;
          if (!isEmpty(latLng)) {
            const lPoly = new ILSPolygon(latLng as LatLngExpression[] | LatLngExpression[][], { color }).L;
            lPoly.addTo(tableItemsLayerGroup);
            mapPolygonItems.push({
              poly: mItem.poly,
              lPoly
            });
          }
        }
      });
      markers.forEach((mItem) => {
        if (mItem.marker.coords) {
          const coords = Object.values(mItem.marker.coords);

          if (!isEmpty(coords)) {
            const lMark = new ILSMarker({
              coords: coords as LatLngExpression,
              options: { icon: defaultMarker }
            }).L;
            lMark.addTo(tableItemsLayerGroup);
            mapMarkersItems.push({
              marker: mItem.marker,
              lMark
            });
          }
        }
      });

      return {
        markers: mapMarkersItems,
        polygons: mapPolygonItems,
        layer: tableItemsLayerGroup,
        drawControl: drawControl
      };
    }
  }
};
export const useActiveLayers = (
  activeLayers: number[],
  catalogState: any | undefined,
  catalogDepotData: any[] | undefined,
  mapLayersItems: MapItems,
  mapTableItems: MapItems,
  dictionary: string | undefined,
  setMapLayersItems: (v: MapItems) => void,
  layerData: MapLayer[],
  setLayerData: (d: MapLayer[]) => void,
  Map: ILSMap | null
) => {
  useEffect(() => {
    if (Map) {
      let mapLayerGroup = mapLayersItems.layer;
      if (!mapLayerGroup) {
        mapLayerGroup = Map.addLayerGroup([]);
      }
      let updateLayersData = false;
      let updateLayersItem = false;

      const ObjectLayerData = CATALOG_DEFAULT_LAYERS.reduce((prev: { [key: number]: LayerData }, cur) => {
        prev[cur.ID] = cur;
        return prev;
      }, {});

      if (catalogDepotData) {
        const clients = catalogDepotData.filter((item) => item.DepotTypeID === DepotTypeId.Client && item.LatLon);
        const storages = catalogDepotData.filter((item) => item.DepotTypeID === DepotTypeId.Storage && item.LatLon);
        const dots = catalogDepotData.filter((item) => item.DepotTypeID === DepotTypeId.LoadDepot && item.LatLon);
        ObjectLayerData[1].data = clients;
        ObjectLayerData[2].data = storages;
        ObjectLayerData[5].data = dots;
      }
      const addToObjectLayer = (indexLayer: CollectedDictionaryLayers | string) => {
        if (ObjectLayerData[indexLayer]?.dictionary?.length) {
          if (catalogState?.[ObjectLayerData[indexLayer].dictionary]?.data?.length) {
            ObjectLayerData[indexLayer].data = catalogState[ObjectLayerData[indexLayer].dictionary].data;
            if (ObjectLayerData[indexLayer].filter) {
              ObjectLayerData[indexLayer].data = ObjectLayerData[indexLayer].data?.filter(ObjectLayerData[indexLayer].filter);
            }
          }
        }
      };
      Object.values(CollectedDictionaryLayers).forEach((indexDefaultLayer) => {
        addToObjectLayer(indexDefaultLayer);
      });

      const newLayerData = Object.values(ObjectLayerData) as MapLayer[];
      if (!deepEqual(newLayerData, layerData)) {
        updateLayersData = true;
        updateLayersItem = true;
      }

      const newLayerItems: MapItems = {
        markers: [],
        polygons: [],
        layer: mapLayerGroup
      };
      const markers: MarkerWithRecord[] = [];
      const polygons: PolyWithRecord[] = [];

      if (activeLayers?.length) {
        activeLayers.forEach((l) => {
          const layer: MapLayer | undefined = newLayerData.find((item: MapLayer) => item.ID === l);
          if (layer && layer.field) {
            let data: IRecord[] = layer.data ?? [];

            if (!data.length && layer.entity && catalogState[layer.entity] && typeof catalogState[layer.entity] === 'object') {
              data = Object.values(catalogState[layer.entity].data) ?? [];
            }

            if (data.length) {
              data.forEach((d) => {
                if (d[layer.field]) {
                  const itemID = d?.ID;
                  const itemDict = layer?.dictionary;

                  if (layer.type === 'marker') {
                    if (
                      itemDict !== dictionary ||
                      !mapTableItems.markers.length ||
                      !mapTableItems.markers.find((mItem) => mItem.marker.record?.ID === itemID)
                    ) {
                      //@ts-ignore
                      markers.push({
                        coords: d[layer!.field],
                        type: layer.markerType ? layer.markerType : undefined
                      });
                    }
                  }
                  if (layer.type === 'polygon') {
                    if (
                      itemDict !== dictionary ||
                      !mapTableItems.polygons.length ||
                      !mapTableItems.polygons.find((mItem) => mItem.poly.record?.ID === itemID)
                    ) {
                      //@ts-ignore
                      polygons.push({
                        coords: d[layer!.field],
                        color: int2color(d['Color'])
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }

      const mapMarkerCheckedKeys: number[] = [];
      const marker2AddToMap: MarkerWithRecord[] = [];

      markers.forEach((marker) => {
        let mapMark = mapLayersItems.markers.find((mItem, index) => {
          if (mItem.marker && marker && deepEqual(mItem.marker, marker)) {
            mapMarkerCheckedKeys.push(index);
            return true;
          }
        });
        if (!mapMark && marker) {
          marker2AddToMap.push(marker);
        }
      });

      mapLayersItems.markers.forEach((mItem, index) => {
        if (!mapMarkerCheckedKeys.includes(index)) {
          if (!updateLayersItem) updateLayersItem = true;
          if (mItem.lMarker) {
            if (mapLayersItems.layer) {
              mapLayersItems.layer.removeLayer(mItem.lMarker);
            } else {
              Map.deleteAnyLayer(mItem.lMarker);
            }
          }
        } else {
          newLayerItems.markers.push(mItem);
        }
      });

      marker2AddToMap.forEach((marker) => {
        if (!isEmpty(marker.coords) && mapLayerGroup) {
          const addRes = addTableMarker(marker, mapLayerGroup);
          if (!updateLayersItem) updateLayersItem = true;
          if (addRes) {
            newLayerItems.markers.push({
              lMarker: addRes.lMarker,
              marker: marker
            });
          }
        }
      });

      const mapPolyCheckedKeys: number[] = [];
      const ploy2AddToMap: PolyWithRecord[] = [];
      polygons.forEach((poly) => {
        let mapMark = mapLayersItems.polygons.find((mItem, index) => {
          if (mItem.poly && poly && deepEqual(mItem.poly, poly)) {
            mapPolyCheckedKeys.push(index);
            return true;
          }
        });
        if (!mapMark && poly) {
          ploy2AddToMap.push(poly);
        }
      });

      mapLayersItems.polygons.forEach((mItem, index) => {
        if (!mapPolyCheckedKeys.includes(index)) {
          if (!updateLayersItem) updateLayersItem = true;
          if (mItem.lPoly) {
            if (mapLayersItems.layer) {
              mapLayersItems.layer.removeLayer(mItem.lPoly);
            } else {
              Map.deleteAnyLayer(mItem.lPoly);
            }
          }
        } else {
          newLayerItems.polygons.push(mItem);
        }
      });

      ploy2AddToMap.forEach((poly) => {
        if (!isEmpty(poly.coords) && mapLayerGroup) {
          const addRes = addTablePolygon(poly, mapLayerGroup);

          if (!updateLayersItem) updateLayersItem = true;
          if (addRes) {
            newLayerItems.polygons.push({
              lPoly: addRes.lPoly,
              poly: poly
            });
          }
        }
      });

      if (updateLayersData) {
        setLayerData(newLayerData);
      }
      if (updateLayersItem) {
        setMapLayersItems(newLayerItems);
      }
    }
  }, [activeLayers, catalogState, catalogDepotData, Map, mapTableItems, dictionary]);
};
export const addTablePolygon = (
  poly: PolyWithRecord,
  mapLayerGroup: L.LayerGroup
):
  | {
      lPoly: L.Polygon;
      poly: PolyWithRecord;
      layer: L.LayerGroup | undefined;
    }
  | undefined => {
  if (poly.coords) {
    const coords = isString(poly.coords) ? GeohashDecodePoints(poly.coords) : poly.coords;

    if (!isEmpty(coords)) {
      const lPoly = new ILSPolygon(coords as LatLngExpression[], { color: poly.color }).L;

      lPoly.addTo(mapLayerGroup);
      return {
        lPoly,
        poly,
        layer: mapLayerGroup
      };
    }
  }
};
export const addTableMarker: <M extends Pick<MarkerWithRecord, 'coords' | 'type' | 'color'>>(
  marker: M,
  mapLayerGroup: L.LayerGroup
) =>
  | {
      lMarker: L.Marker;
      marker: M;
      layer: L.LayerGroup | undefined;
    }
  | undefined = (marker, mapLayerGroup) => {
  if (marker && marker.coords) {
    const markProps = marker2ConstructOption(marker);
    if (markProps) {
      const lMarker = new ILSMarker(markProps).L;
      lMarker.addTo(mapLayerGroup);
      return {
        lMarker,
        marker,
        layer: mapLayerGroup
      };
    }
  }
};

const marker2ConstructOption = (marker: Pick<MarkerWithRecord, 'coords' | 'color' | 'type'> & {}) => {
  if (!marker.coords) return;
  let icon;
  const coords = Object.values(marker.coords);

  switch (marker.type) {
    case 'color':
      icon = colorMarker(marker.color as string);
      break;
    case 'storage':
      icon = customMarker(homeMarker, '#4C91FF');
      break;
    case 'client':
      icon = customMarker(clientMarker, '#2358C6');
      break;
    case 'load':
      icon = customMarker(loadMarker, '#50B993');
      break;
    case 'car':
      icon = carMarker();
      break;
    default:
      icon = defaultMarker;
  }
  const markerInit: Parameters<ILSMap['addMarker']>[0][0] = {
    coords: coords as LatLngTuple,
    options: {
      data: marker['data'],
      icon: icon,
      draggable: false
    }
  };

  return markerInit;
};

