import { useState } from 'react';
import { useCloseAfterDragging } from '@modules/planning/children/by-date/hooks/tables/use-close-after-dragging';
import { useItemDrop } from '@modules/planning/children/by-date/hooks/use-item-drop';
import { useSelector } from 'react-redux';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const useMapDragNDrop = ({ handleShowOrderTable }: { handleShowOrderTable: (val: boolean) => void }) => {
  const activePlanID = useSelector(activePlanIDSelector);

  const [markerDragging, setMarkerDragging] = useState<boolean>(false);

  const { setIsAfterDragging } = useCloseAfterDragging({ handleShowOrderTable });
  //закидывание заявок в рейс с помощью drag'n'drop
  const { onItemDrop } = useItemDrop(activePlanID, setIsAfterDragging, handleShowOrderTable);
  return { markerDragging, setMarkerDragging, onItemDrop, setIsAfterDragging };
};
