import { DictionariesEndpoint, DictionaryCreateRequest, DictionaryOperation } from '@common/types/general/api/dictionary';
import { Dictionary, IILSTableComponentProps, OrderCargo, TableRecord } from '@common/types';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';

export const handleCreateOrderCargo =
  ({ record, dispatch }: { record: any; dispatch: Dispatch }): IILSTableComponentProps<TableRecord>['handleCreate'] =>
  (values) => {
    const payload: DictionaryOperation<DictionaryCreateRequest> & {
      dictionaryFetching?: boolean;
    } = {
      dictionary: Dictionary.OrderCargo,
      values: [
        {
          Amount: (values as OrderCargo).Amount,
          CargoID: (values as OrderCargo).CargoID,
          OrderID: record.ID
        }
      ],
      operation: DictionariesEndpoint.Create
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };
