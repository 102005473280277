import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { createTripRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { IndexedArray, Vehicle } from '@common/types';
import { TripTotal } from '@common/types/dictionaries/trip';

export const createTripReducer = createReducer(initialState, {
  [createTripRoutine.SUCCESS]: (
    state,
    { payload: { tripTotal, vehicleID } }: PayloadAction<{ tripTotal: IndexedArray<TripTotal>; vehicleID: Vehicle['ID'] }>
  ) => {
    for (const tripID in tripTotal) {
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID] = { ...tripTotal[tripID], changed: true };
    }
    return state;
  }
});
