import { Dictionary, TableRecord } from '@common/types';
import { isEmpty } from 'lodash';

export const getIdsFromRouteTableByDictionary = (selectedRows: TableRecord[]) => {
  const rowIdsByDictionary = selectedRows?.reduce(
    (result, row) => {
      if (row.ID) {
        if (row.dictionary === Dictionary.Trip) {
          result[Dictionary.Trip].push(row.ID);
        }
        if (row.dictionary === Dictionary.Route) {
          result[Dictionary.Route].push(row.ID);
          const trips = row.children;
          if (!isEmpty(trips)) {
            trips?.map((trip) => {
              result[Dictionary.Trip].push(trip.ID);
            });
          }
        }
        if (row.dictionary === Dictionary.Waypoint) {
          result[Dictionary.Waypoint].push(row.ID);
        }
      }
      return result;
    },
    { [Dictionary.Route]: [], [Dictionary.Trip]: [], [Dictionary.Waypoint]: [] } as {
      [Dictionary.Route]: (string | number)[];
      [Dictionary.Trip]: (string | number)[];
      [Dictionary.Waypoint]: (string | number)[];
    }
  );
  return {
    [Dictionary.Route]: Array.from(new Set(rowIdsByDictionary[Dictionary.Route])),
    [Dictionary.Trip]: Array.from(new Set(rowIdsByDictionary[Dictionary.Trip])),
    [Dictionary.Waypoint]: Array.from(new Set(rowIdsByDictionary[Dictionary.Waypoint]))
  };
};

