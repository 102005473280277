import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningCopyRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { IndexedArray, Plan } from '@common/types';
import { PlanTotal } from '@common/types/dictionaries/plan';

export const planningCopyReducer = createReducer(initialState, {
  [planningCopyRoutine.SUCCESS]: (state, action: PayloadAction<{ plan: Plan; planTotal: IndexedArray<PlanTotal> }>) => {
    const { plan, planTotal } = action.payload;
    const copiedPlan = { ...plan, PlanTotal: { ...plan, ...Object.values(planTotal)[0] } };
    if (state.project?.Plan) {
      state.project.Plan[plan.ID] = copiedPlan;
    } else if (state.project) {
      state.project.Plan = { [plan.ID]: copiedPlan };
    }
    return state;
  }
});
