import { ILSButtonTooltip } from '@components/index';
import { FC } from 'react';
import { ILSResultEfficiencyModalContainer } from './modal';
import { BarChartOutlined } from '@ant-design/icons/lib/icons';
import { useSelector } from 'react-redux';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { isEmpty } from 'lodash';
import { useModal } from '@common/hooks/components/use-modal';

export const ILSResultEfficiencyButton: FC = () => {
  const { selectedRoutesIDs } = useSelector(selectedRouteTableRowsSelector);
  const { visible, handleShow } = useModal();
  const disabled = isEmpty(selectedRoutesIDs);

  return (
    <>
      <ILSButtonTooltip
        onClick={handleShow}
        disabled={disabled}
        icon={<BarChartOutlined className={'ils-svg primary'} />}
        placement="bottom"
        title={!disabled ? 'Эффективность по выделенным рейсам' : 'Выделите рейсы для просмотра эффективности'}
      />
      {visible && <ILSResultEfficiencyModalContainer handleShow={handleShow} isModalVisible={visible} />}
    </>
  );
};
