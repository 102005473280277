import { useEffect } from 'react';
import { planningUseRedisRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch, useSelector } from 'react-redux';
import { planningRedisSettings } from '@modules/settings/selectors';

export const usePlanningRedis = () => {
  const dispatch = useDispatch();

  const { useRedis } = useSelector(planningRedisSettings);

  useEffect(() => {
    dispatch(planningUseRedisRoutine.success({ useRedis }));
  }, [useRedis]);
};
