import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setActiveTabRoutine } from '@modules/monitoring/children/event-details/actions';
import { TabType } from '@modules/monitoring/children/event-details/types/event-details-tabs';
import { MonitoringEventDetailsStore } from '@modules/monitoring/children/event-details/types/store';

// Меняет активный таб с таблицей или графиком
export const detailsTabChangeReducer = createReducer<MonitoringEventDetailsStore>({} as MonitoringEventDetailsStore, {
  [setActiveTabRoutine.SUCCESS]: (state, action: PayloadAction<{ activeTab: TabType }>) => {
    const { activeTab } = action.payload;
    if (activeTab) {
      state.activeTab = activeTab;
    }
    return state;
  }
});
