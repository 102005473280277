import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getProjectsRoutine } from '../actions';
import { PlanningAvailableDates } from '@modules/planning/types/planning';
import { ProjectPlanning } from '@modules/planning/children/by-date/types';
import { initialState } from '@modules/planning/store';
import { onFailure } from '@common/utils/helpers/reducers';

export const getProjectsReducer = createReducer(initialState, {
  [getProjectsRoutine.REQUEST]: (state) => {
    state.isDateLoading = true;
    return state;
  },
  [getProjectsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      projects: ProjectPlanning;
      availableDates: PlanningAvailableDates;
      from: string;
    }>
  ) => {
    const { projects, availableDates, from } = action.payload;
    state.projects = projects;
    state.fromDate = from;
    state.availableDates = availableDates;
    return state;
  },
  [getProjectsRoutine.FAILURE]: onFailure,
  [getProjectsRoutine.FULFILL]: (state) => {
    state.error = [];
    state.isDateLoading = false;
    return state;
  }
});
