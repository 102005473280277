import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningDeleteTenderForRouteRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { compareAsString, onFailure, onFulfill, onRequest } from '@common/utils';
import { PlanStatus, Vehicle } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { omitBy } from 'lodash';

export const planningDeleteTenderForRouteRoutineReducer = createReducer(initialState, {
  [planningDeleteTenderForRouteRoutine.REQUEST]: onRequest,
  [planningDeleteTenderForRouteRoutine.FAILURE]: onFailure,
  [planningDeleteTenderForRouteRoutine.FULFILL]: onFulfill,
  [planningDeleteTenderForRouteRoutine.SUCCESS]: (state, action: PayloadAction<{ ids: Vehicle['ID'][] }>) => {
    const { ids } = action.payload;
    ids.forEach((vehicleID) => {
      state.currentPlan.RouteTotal[vehicleID].TenderID = EMPTY_STRING;
      state.currentPlan.RouteTotal[vehicleID].changed = true;
      state.currentPlan.RouteTotal[vehicleID].Status = PlanStatus.Open;

      for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
        state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].Status = PlanStatus.Open;
        state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
        for (const waypointID in state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal) {
          state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID].Status = PlanStatus.Open;

          for (const orderActionID in state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID]
            .OrderActionTotal) {
            state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID].OrderActionTotal[orderActionID].Status =
              PlanStatus.Open;
          }
        }
      }
    });

    /** Меняем статус плана */
    const activePlanID = state.activePlanID;
    const plan = activePlanID && state.project?.Plan?.[activePlanID]?.PlanTotal;
    if (plan && activePlanID) {
      /** Если у всех маршрутов плана меняем статус, то меняем и статус плана */
      if (compareAsString(ids.length, plan.RoutesCount)) {
        state.project!.Plan![activePlanID].PlanTotal.Status = PlanStatus.Open;
      } else {
        /** Собираем неизмененные маршруты плана */
        const restRoutes = omitBy(state.currentPlan.RouteTotal, (vehicle, vehicleID) => ids.includes(Number(vehicleID)));
        /** Проверяем статус у неизмененных маршрутов плана, если у всех остальных статус открыт, то и план открыт */
        if (Object.values(restRoutes).every(({ Status }) => compareAsString(Status, PlanStatus.Open))) {
          state.project!.Plan![activePlanID].PlanTotal.Status = PlanStatus.Open;
        } else {
          /** в остальных случаях означает что план с серым замком */
          state.project!.Plan![activePlanID].PlanTotal.Status = PlanStatus.TripWithDifferentStatuses;
        }
      }
    }

    return state;
  }
});
