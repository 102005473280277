import { useAppDispatch } from '@core/hooks';
import { MenuProps } from 'antd';
import { InboxAction } from '@modules/documents/children/inbox/constants/inbox-configs';
import { getTemplateRoutine } from '@modules/documents/children/templates/actions';
import { DocumentAction } from '@modules/documents/types/document-type';
import { changeDocumentStatusRoutine, currentDocumentRoutine, setDocumentEnum } from '@modules/documents/actions';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { InboxSendDocument } from '@modules/documents/types/document-state';

export const useGetInboxMenu = () => {
  const dispatch = useAppDispatch();

  return (document: InboxSendDocument): MenuProps['items'] => {
    const openButton = {
      label: 'Открыть',
      key: DocumentAction.View,
      onClick: () => {
        dispatch(currentDocumentRoutine({ documentId: document.DocumentID, isOpenModal: true, isInbox: true }));
        dispatch(setDocumentEnum(document.DocumentEnum || null));
        if (document.DocumentTemplateID !== 0) {
          dispatch(getTemplateRoutine({ docId: document.DocumentID }));
        }
      }
    };

    const acceptButton = {
      label: 'Принять',
      key: InboxAction.Accept,
      onClick: () => {
        dispatch(changeDocumentStatusRoutine({ id: document.ID, status: 1 }));
      }
    };

    const declineButton = {
      label: 'Отклонить',
      key: InboxAction.Decline,
      onClick: () =>
        ILSUserNotifyConfirm(
          'Вы действительно хотите отклонить данный документ?',
          undefined,
          () => dispatch(changeDocumentStatusRoutine({ id: document.ID, status: 2 })),
          undefined,
          undefined,
          'Отклонить'
        )
    };

    const deleteButton = {
      label: 'Удалить',
      key: InboxAction.Delete,
      onClick: () =>
        ILSUserNotifyConfirm(
          'Вы действительно хотите удалить данный документ?',
          undefined,
          () => dispatch(changeDocumentStatusRoutine({ id: document.ID, status: 3 })),
          undefined,
          undefined,
          'Удалить'
        )
    };

    return [openButton, acceptButton, declineButton, deleteButton];
  };
};
