import { put, takeLatest } from 'redux-saga/effects';
import { changeUserInfoRoutine } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { User, IndexedArray } from '@common/types';

function* changeUserInfoWorker(action: PayloadAction<{ data: IndexedArray<User> }>) {
  const { success, failure, fulfill } = changeUserInfoRoutine;
  const { data } = action.payload;
  try {
    const user = Object.values(data ?? {})?.[0];
    yield put(success({ user }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* changeUserInfoWatcher() {
  yield takeLatest(changeUserInfoRoutine.trigger, changeUserInfoWorker);
}

