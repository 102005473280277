import { FC, MouseEvent } from 'react';
import { ILSPlanContextMenuHead } from '@modules/planning/children/by-date/components/result/context-menu/menu/menu-head';
import { ILSPlanContextMenuList } from '@modules/planning/children/by-date/components/result/context-menu/menu/menu-list';
import { PlansTableRecord, RoutesTableRecord } from '@modules/planning/children/by-date/types/record';
import { isEmpty } from 'lodash';

interface IProps {
  menu: Array<any>;
  record: RoutesTableRecord | PlansTableRecord;

  onClick(e: MouseEvent, b1?: boolean, b2?: boolean): void;

  onContextClose(): void;
}

export const ILSPlanContextMenu: FC<IProps> = ({ menu, onClick, record, onContextClose }) => {
  return (
    <>
      <ILSPlanContextMenuHead record={record} onContextClose={onContextClose} />
      {!isEmpty(menu) && <ILSPlanContextMenuList menu={menu} onClick={onClick} />}
    </>
  );
};
