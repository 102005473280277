import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import AnalyticsPlanningBoards from './children/planning-analytics';
import AnalyticsPlanningReports from './children/reports';
import AnalyticsPlanning from './containers';

export default defineModule({
  title: ModuleTitle.AnalyticsPlanning,
  path: ModulePath.AnalyticsPlanning,
  component: AnalyticsPlanning,
  children: { AnalyticsPlanningBoards, AnalyticsPlanningReports } as any,
  icon: '/assets/icons/sidebar/analytic.svg',
  inScope: [ModuleScope.SiderNav]
});
