import { WidgetParamType } from '@common/types/dictionaries/analytics';
import { FC, useMemo } from 'react';
import ILSAnalyticsBoardDateSelect from '../components/range-select';
import { ILSAnalyticsWidgetStringParam } from '../components/widget-param/string-param';
import { WidgetCommonParam } from '../constants';
import { AnalyticsWidgetFilterParam } from '@modules/analytics/common/components/widget-param/filter-param';
import { WidgetParamComponentProps } from '@modules/analytics/common/types/components/params';

export const ILSAnalyticsWidgetParamContainer: FC<WidgetParamComponentProps> = (props) => {
  const { widgetParam, widget, handleSetParams } = props;
  const Component: JSX.Element = useMemo(() => {
    if (widgetParam.Name === WidgetCommonParam.From && widgetParam.Type !== WidgetParamType.filter) {
      return <ILSAnalyticsBoardDateSelect />;
    }
    if (widgetParam.Name === WidgetCommonParam.Till) return <></>;
    switch (widgetParam.Type) {
      case WidgetParamType.string:
        return <ILSAnalyticsWidgetStringParam {...props} />;
      case WidgetParamType.dateRange:
        return <ILSAnalyticsBoardDateSelect />;
      case WidgetParamType.filter:
        return <AnalyticsWidgetFilterParam {...props} />;
      default:
        return <div>Параметр не определен</div>;
    }
  }, [handleSetParams, widgetParam]);
  return Component;
};
