import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, Notify, NotifyDurationInSecond } from '@common/types';
import { defaultStatuses, EmulatorAction } from '../../constants/default-messages';
import { emulatorRoutinesToNotify } from '../../actions';
import { getErrorMessage } from '@common/utils';

// Метод получения текста уведомления для разных экшенов
const getDataNotificationMessage = (
  action: EmulatorAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || defaultStatuses?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): EmulatorAction => {
  return actionName as EmulatorAction;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, EmulatorAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Emulator,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, EmulatorAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Emulator,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* emulatorNotifierWatcher() {
  for (let routine of emulatorRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
