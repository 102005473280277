import { all, put, takeLatest } from 'redux-saga/effects';
import { accountRoutine, getInitialRoutine, organizationTypesRoutine, userRoutine } from '@modules/account/actions';
import { DictionariesEndpoint } from '@common/types';

export function* getInitialWorker() {
  const { failure, fulfill } = getInitialRoutine;

  try {
    yield all([
      put(accountRoutine.trigger({ operation: DictionariesEndpoint.Index })),
      put(userRoutine.trigger({ operation: DictionariesEndpoint.Index })),
      put(accountRoutine.trigger({ operation: DictionariesEndpoint.getRoleEnum })),
      put(organizationTypesRoutine.trigger())
    ]);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getInitialWatcher() {
  yield takeLatest(getInitialRoutine.trigger, getInitialWorker);
}

