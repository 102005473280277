import { ILSButton } from '@common/components';

interface ISuccessItemProps {
  handleCancel: () => void;
  handleFollow: () => void;
  error: string;
}

const SuccessItem = ({ handleCancel, handleFollow, error }: ISuccessItemProps) => {
  return (
    <div className={'success-item'}>
      <div>{error ?? 'Документ успешно сформирован'}</div>
      <div className={'buttons'}>
        <ILSButton type="primary" onClick={handleCancel}>
          Ок
        </ILSButton>
        {!error && (
          <ILSButton type="primary" onClick={handleFollow}>
            Перейти
          </ILSButton>
        )}
      </div>
    </div>
  );
};

export default SuccessItem;
