import { ModulePath } from '@common/types';
import { AppProps } from '../../types';
import { head } from 'lodash';

export const getPageComponent = (props: AppProps) => {
  const location = props?.location?.pathname;
  let route = props?.route?.routes.find(({ path }) => path === location);
  //права доступа для abac

  //получение компонентов кастомных страниц
  let PageComponent: any = location !== ModulePath.Dashboard && route?.component ? route.component : false;
  //проверка доступности компонентов для неавторизованных пользователей
  let permission = route?.permission;

  //! Все ссылки на кастомные страницы должны иметь в пути ModulePath.CustomWithoutAuthPage
  //!TODO страницы кастомные, но название и суть страниц nonAuthPage, то есть для неавторизованных
  const nonAuthPage = props?.route?.routes.find(({ path }) => path === ModulePath.CustomWithoutAuthPage);

  if (nonAuthPage?.routes) {
    route = nonAuthPage?.routes?.find(({ path }) => path === location);
    //получение permission кастомных страниц
    //!TODO из permission страниц получаем только одно permission, а не массив
    permission = route?.permission && Array.isArray(route?.permission) ? head(route?.permission) : route?.permission;
    if (route?.component) {
      PageComponent = route.component;
    }
  }

  return { PageComponent, permission, route, location };
};
