import { createReducer } from '@reduxjs/toolkit';
import { emulatorStartRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { EmulatorStatus } from '../constants';

// Присваивает статусу эмуляции (emulatorStatus.status) состояние инициализации эмуляции (EmulatorStatus.Init)
const emulatorStartReducer = createReducer(initialState, {
  [emulatorStartRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = true;
    return state;
  },
  [emulatorStartRoutine.SUCCESS]: (state: IEmulatorStore) => {
    state.emulatorStatus.status = EmulatorStatus.Init;
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorStartRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorStartRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  }
});

export default emulatorStartReducer;
