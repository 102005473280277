import { EMPTY } from '@common/constants';
import { catalogDeleteLocalRoutine, catalogDeleteRoutine } from '@modules/catalog/actions';
import { Dictionary, Keys, ShowDeleted } from '@common/types';
import { SaveDictionaryType, ValuesType } from '@modules/catalog/types/catalog';
import { Dispatch } from 'redux';

export const handleDeleteRow = ({
  dictionary,
  ids,
  values,
  showDeleted,
  saveDictionary,
  dispatch
}: {
  dictionary: Dictionary;
  ids: Keys;
  values: ValuesType;
  showDeleted?: ShowDeleted;
  saveDictionary?: SaveDictionaryType;
  dispatch: Dispatch;
}) => {
  if (ids.includes(EMPTY)) {
    //Если ID === EMPTY удаляем запись локально, без отправки на сервер
    dispatch(
      catalogDeleteLocalRoutine({
        dictionary,
        ids: [EMPTY],
        values,
        showDeleted,
        saveDictionary
      })
    );
    const existIDs = ids.filter((it) => it !== EMPTY);
    if (existIDs.length) {
      dispatch(
        catalogDeleteRoutine({
          dictionary,
          ids: existIDs,
          values,
          showDeleted,
          saveDictionary
        })
      );
    }
  } else {
    dispatch(
      catalogDeleteRoutine({
        dictionary,
        ids,
        values,
        showDeleted,
        saveDictionary
      })
    );
  }
};
