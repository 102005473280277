import { DEFAULT_ORDER_COLOR } from '@common/constants/colors/order';
import { ActiveType, MarkerType } from '@common/types';
import cn from 'classnames';
import L, { MarkerCluster } from 'leaflet';
import { first, isEmpty } from 'lodash';
import { SelectedOrdersLasso } from '../../types/map-container';
import { MarkerDataAttribute } from '@common/types/components/map/map';

const regexSelect = /class="[\d\s\w\-_]*selected[\d\s\w\-_]*"/i;
const selectedColor = '#ffa605b8';
const defaultOpacity = '9a';
const defaultbackgroundColor = DEFAULT_ORDER_COLOR + defaultOpacity;

export const orderClusterCustomIcon = (cluster: MarkerCluster, selectedOrdersLasso: SelectedOrdersLasso) => {
  const count = cluster.getChildCount();
  const clusterChildMarkers = cluster.getAllChildMarkers();
  let childData: { ID: number }[] = [],
    ids: number[] = [],
    ifSelected: boolean = false,
    selectedCount: number = 0;

  let backgroundColor: string = defaultbackgroundColor;

  if (!isEmpty(selectedOrdersLasso?.orderIDs)) {
    selectedCount = clusterChildMarkers.length;
    clusterChildMarkers.length &&
      clusterChildMarkers.forEach((c) => {
        if (!isEmpty(c.options['data'])) {
          childData = [...childData, c.options['data']];
        }
      });
    childData.forEach((d: { ID: number }) => {
      if (d.ID) {
        ids.push(d.ID);
      }
      if (!isEmpty(selectedOrdersLasso?.orderIDs)) {
        selectedOrdersLasso?.orderIDs?.forEach((s) => {
          if (s === d.ID) ifSelected = true;
        });
      }
    });
  } else {
    clusterChildMarkers.length &&
      clusterChildMarkers.forEach((c) => {
        if (c) {
          const curIcon = (c.getIcon() as L.DivIcon).options.html;
          let curSelected = false;
          if (typeof curIcon === 'string') {
            const match = curIcon.match(regexSelect);
            if (match && match.length && match[0].length > 7) {
              curSelected = true;
            }
          } else if (typeof curIcon === 'object') {
            curSelected = curIcon.classList.contains(ActiveType.Selected);
          }
          if (selectedCount === 0) {
            childData = [...childData, c.options['data']];
          }
          if (curSelected) {
            ifSelected = true;
            selectedCount++;
            if (selectedCount === 1) {
              childData = [c.options['data']];
            } else {
              childData = [...childData, c.options['data']];
            }
          }
        }
        childData.forEach((d: { ID: number }) => {
          if (d.ID) {
            ids.push(d.ID);
          }
        });
      });
  }
  if (ifSelected) {
    backgroundColor = selectedColor;
  } else if (clusterChildMarkers.length) {
    const firstColor = first(clusterChildMarkers)?.options?.color;
    if (!isEmpty(firstColor) && clusterChildMarkers.every((marker) => marker.options.color === firstColor)) {
      backgroundColor = firstColor + defaultOpacity;
    }
  }

  const html = L.DomUtil.create('div', cn('marker-order-cluster', { [ActiveType.Selected]: ifSelected }));
  html.setAttribute('id', `marker_${ids}`);
  html.setAttribute(MarkerDataAttribute.MarkerType, MarkerType.OrderCluster);
  if(!isEmpty(childData)){
    html.setAttribute(MarkerDataAttribute.OrderIDs, JSON.stringify(childData.map(item=>item.ID)));
  }
  html.style.setProperty('background-color', backgroundColor);
  html.style.setProperty('box-shadow', `0 0 10px 5px ${backgroundColor}`);

  const htmlInner = L.DomUtil.create('span', '', html);
  htmlInner.innerHTML = `${ifSelected ? `${selectedCount > count ? ids.length : selectedCount} / ${count}` : count}`;

  return L.divIcon({
    html: html.outerHTML,
    // @ts-ignore
    className: cn('marker-cluster ils-marker-orders', { [ActiveType.Selected]: ifSelected }),

    iconSize: ifSelected ? new L.Point(50, 50) : new L.Point(40, 40)
  });
};

export const customClusterCustomIcon = (cluster: MarkerCluster) => {
  const count = cluster.getChildCount();

  const options = { cluster: 'marker-cluster' };

  return L.divIcon({
    html: `<div><span>${count}</span></div>`,
    className: `${options.cluster}`,
    iconSize: new L.Point(40, 40)
  });
};

export const clientClusterCustomIcon = (cluster: MarkerCluster) => {
  const count = cluster.getChildCount();

  const options = { cluster: 'marker-cluster ils-marker-clients' };

  return L.divIcon({
    html: `<div><span>${count}</span></div>`,
    className: `${options.cluster}`,
    iconSize: new L.Point(40, 40)
  });
};

export const storageClusterCustomIcon = (cluster: MarkerCluster) => {
  const count = cluster.getChildCount();

  const options = { cluster: 'marker-cluster ils-marker-storages' };

  return L.divIcon({
    html: `<div><span>${count}</span></div>`,
    className: `${options.cluster}`,
    iconSize: new L.Point(40, 40)
  });
};

export default {
  orderClusterCustomIcon,
  customClusterCustomIcon,
  clientClusterCustomIcon,
  storageClusterCustomIcon
};
