import { KeyboardCode } from '@common/constants/general';
import { ConvexPolygon, Dragging, MarkerPlanning, RecordTableButton, TableName, TrackActiveData } from '@common/types';
import { MapClickData } from '@common/types/components/map/map';
import { addTrackForLoading } from '@common/utils/get-track/add-track-for-loading';
import { isOrderClickData, isWaypointClickData } from '@common/utils/map';
import { ILSSpin } from '@components/index';
import { ILSMap } from '@core/containers/map';
import { useAppSelector } from '@core/hooks';
import { planningAddTrackToQueryRoutine } from '@modules/planning/children/by-date/actions';
import ILSPlanningDragMarker from '@modules/planning/children/by-date/components/result/map/drag-marker';
import { ILSPlanningMatrixContainer } from '@modules/planning/children/by-date/containers/result/matrix-container';
import { PlannerMapTablesContainer } from '@modules/planning/children/by-date/containers/result/tables/map-tables';
import { MapOrdersWithSettings } from '@modules/planning/children/by-date/decorators/map';
import { useSelectedRowActions } from '@modules/planning/children/by-date/hooks';
import { useItemClick } from '@modules/planning/children/by-date/hooks/map/use-item-click';
import { useOrderTrackFitBound } from '@modules/planning/children/by-date/hooks/map/use-order-track-fit-bound';
import { selectedOrderTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { mapWaitingModeSelector } from '@modules/planning/children/by-date/selectors/map-waiting-mode';
import { planningGetTrackData } from '@modules/planning/children/by-date/selectors/plan-points';
import { planningMapSettingsSelector } from '@modules/settings/selectors/planning';
import cn from 'classnames';
import { deepEqual } from 'fast-equals';
import { isEmpty } from 'lodash';
import { Dispatch, FC, memo, RefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDragDropContainer } from 'react-drag-drop-container';
import { useDispatch, useSelector } from 'react-redux';
import { mapMarkerDecorator } from '../../decorators/map/map-marker-decorator';
import { useMapPlanData } from '../../decorators/map/plan-data';
import {
  useDragDrop,
  useMapConvexItems,
  useMapDragPolyStyle,
  useMapInit,
  useMapLasso,
  useMapLayers,
  useMapLayersItems,
  useMapMarkers,
  useMapPairsUnresolved,
  useMapPolygons,
  useOrdersFocus,
  useResetSummary
} from '../../hooks/map';
import { usePlanningLoadTrack } from '../../hooks/map/load-track';
import { useMapLassoSelect } from '../../hooks/map/map-lasso-select';
import { useMapDragEnd } from '../../hooks/map/use-map-drag-end';
import { useDragMarkerRefData } from '../../hooks/map/use-map-ref-data-for-drag';
import { activePlanIDSelector } from '../../selectors/active-plan';
import { ConvexItem, MapLayers, MarkerItem, PolylineItem } from '../../types/map-container';
import { MAP_SIZES } from '@core/constants/map';
import { MapButtonsToolbar } from '@modules/planning/children/by-date/components/result/map/map-buttons-toolbar';
import { usePolyline } from '@modules/planning/children/by-date/hooks/map/use-polyline';

export interface IPlanningMapProps {
  fullTable: boolean;
  mapRefExtraFromContainer: RefObject<HTMLDivElement>;
  recordMapButtons: RecordTableButton[];
  triggerMapFocus: any[];
  showVehicleTable: boolean;
  showOrderTable: boolean;
  markerDragging: boolean;
  setMarkerDragging: Dispatch<SetStateAction<boolean>>;
  handleShowVehicleTable(val: boolean): void;
  handleShowOrderTable(val: boolean): void;
  onItemDrop(e: IDragDropContainer, record: any): void;
  setIsAfterDragging: Dispatch<SetStateAction<boolean>>;
}

export const ILSPlanningResultMapContainer: FC<IPlanningMapProps> = memo(
  ({
    recordMapButtons,
    triggerMapFocus,
    showVehicleTable,
    handleShowOrderTable,
    handleShowVehicleTable,
    markerDragging,
    onItemDrop,
    showOrderTable,
    setMarkerDragging,
    setIsAfterDragging,
    mapRefExtraFromContainer,
    fullTable
  }) => {
    const dispatch = useDispatch();
    const mapWaitingMode = useAppSelector(mapWaitingModeSelector);
    const trackData = useAppSelector(planningGetTrackData);
    const planningMapSettings = useAppSelector(planningMapSettingsSelector);
    const activePlanID = useAppSelector(activePlanIDSelector);
    const { selectedOrders } = useSelector(selectedOrderTableRowsSelector);
    const { onSelectRows, onResetSelectedRows } = useSelectedRowActions();

    const [showConvexPolygon, setShowConvexPolygon] = useState(false);
    const [lassoIsActive, setLassoIsActive] = useState(false);
    const [rulerIsActive, setRulerIsActive] = useState(false);
    const [trackDataChanges, setTrackDataChanges] = useState<any>({});
    const { mapZoomCluster, disableClustering, enableAutoFocusOnChangeTrip, unresolvedMarkerSource, useRedis } = planningMapSettings;

    const [polylineDataIDs, setPolylineDataIDs] = useState<TrackActiveData>(null);

    const Map = useRef<ILSMap | null>(null);

    const [dragging, setDragging] = useState<null | Dragging>(null);

    const { onLassoChange, resetLassoSelect, selectedOrdersLasso } = useMapLassoSelect({});

    const { onItemClick } = useItemClick({ onLassoChange });

    const [mapLayersGroup, setMapLayersGroup] = useState<MapLayers>({
      orderCluster: undefined,
      clientCluster: undefined,
      cluster: undefined,
      storageCluster: undefined,
      polylineGroup: undefined,
      polygonGroup: undefined,
      convexGroup: undefined,
      isInit: false
    });
    const [mapMarkers, setMapMarkers] = useState<Record<string, MarkerItem>>({});

    const [mapPolylines, setMapPolylines] = useState<Record<string, PolylineItem>>({});

    const [mapConvex, setMapConvex] = useState<ConvexItem[]>([]);

    const mapRef = useRef<HTMLDivElement>(null);

    //выделение лассо
    const [lassoLayers, setLassoLayers] = useState<null | any[]>(null);
    const handleLassoChange: typeof onLassoChange = (...args) => {
      handleShowOrderTable(true);
      onLassoChange(...args);
    };
    const closeTables = () => {
      handleShowOrderTable(false);
      handleShowVehicleTable(false);
    };

    const resetSelectedData = () => {
      resetLassoSelect();
      onResetSelectedRows(TableName.PlanningNotDeliveredOrder);

      //TODO обработку закрытия таблиц лучше разделить
      closeTables();
    };

    //инициализация карты
    const { onMouseLeave } = useMapInit(
      Map,
      mapZoomCluster ? Number(mapZoomCluster) : undefined,
      disableClustering,
      setLassoLayers,
      setPolylineDataIDs,
      selectedOrdersLasso,
      setMapLayersGroup
    );

    useEffect(() => {
      if (mapRef.current?.getBoundingClientRect) {
        const { x, y } = mapRef.current.getBoundingClientRect();
        setDragging({
          ...dragging,
          mapX: x,
          mapY: y
        });
      }
    }, []);

    useResetSummary({ resetSelectedData });

    /**
     * все данные карты по плану
     */
    const { mapData } = useMapPlanData();

    const { marker, convex } = useMemo(() => {
      if (mapData) {
        const { notResolvedMarker } = MapOrdersWithSettings(mapData?.orderMarkers, planningMapSettings);
        return {
          marker: mapMarkerDecorator(notResolvedMarker, {}),
          convex: showConvexPolygon ? mapData?.convexPolygon ?? [] : []
        };
      }
      return {
        marker: [] as MarkerPlanning[],
        convex: [] as ConvexPolygon[]
      };
    }, [planningMapSettings, mapData, showConvexPolygon]);

    const { activeLayers, layerData, mapLayersData, onChangeLayer } = useMapLayers({});

    //TODO add unit
    useEffect(() => {
      if (trackDataChanges && trackData && useRedis) {
        const checkList = {};
        let isNew = false;
        Object.keys(trackData).forEach((key) => {
          if (trackData[key].track) {
            checkList[key] = 1;
            if (!isNew && trackDataChanges[key] !== 1) {
              isNew = true;
            }
          }
        });
        if (isNew) {
          setTrackDataChanges(checkList);
        }
      }
    }, [trackData]);
    //Добавление треков на загрузку с редиса
    useEffect(() => {
      addTrackForLoading(planningMapSettings, mapData, trackData, dispatch, planningAddTrackToQueryRoutine);
    }, [planningMapSettings, mapData]);

    useMapLasso(lassoLayers, handleLassoChange, (b: boolean) => setLassoIsActive(b), onSelectRows);

    useEffect(() => {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === KeyboardCode.Escape) {
          setRulerIsActive(false);
        }
      };

      if (mapRef.current) {
        mapRef.current?.addEventListener('keydown', onKeyDown);
      }

      return () => {
        mapRef.current?.removeEventListener('keydown', onKeyDown);
      };
    }, []);

    //TODO add unit
    // TODO изменить логику в 9799
    const resetSummaryData = ({ resetAll = false, withCloseTable = false }) => {
      setLassoLayers(null);
      if ((resetAll || withCloseTable) && !isEmpty(selectedOrdersLasso)) {
        handleShowOrderTable(false);
        handleShowVehicleTable(false);
      }
      if (resetAll) {
        // NOTE Закрываем таблицы только при взаимодействиях с Summary
        onResetSelectedRows(TableName.PlanningNotDeliveredOrder);
        resetLassoSelect();
        setPolylineDataIDs(null);
      }
    };

    //TODO add unit
    const handleItemClick = useCallback((record?: MapClickData) => {
      onItemClick(record);
      if (isOrderClickData(record)) {
        onSelectRows([record.ID], [], TableName.PlanningNotDeliveredOrder);
        handleShowOrderTable(true);
      } else if (isWaypointClickData(record)) {
        handleShowOrderTable(false);
        handleShowVehicleTable(false);
        onResetSelectedRows(TableName.PlanningNotDeliveredOrder);
        resetLassoSelect();
      }
    }, []);

    useEffect(() => {
      resetSummaryData({ resetAll: false });
    }, [mapData]);

    //Загрузка треков
    usePlanningLoadTrack();

    const { handleMarkerDragEnd } = useMapDragEnd({
      dispatch,
      enableAutoFocusOnChangeTrip,
      mapData,
      resetSummaryData,
      setDragging,
      setIsAfterDragging,
      setPolylineDataIDs
    });

    const { polyline } = usePolyline({ trackDataChanges, mapData });
    //Для отображения нераспределенных заявок
    useMapMarkers(Map, mapLayersGroup, marker, mapMarkers, activePlanID, (newMapMarkers) => setMapMarkers(newMapMarkers), handleItemClick);
    //Для отображения рейсов и их точек
    useMapPolygons({
      Map,
      mapLayersGroup,
      trackPolylines: polyline,
      mapPolylines,
      activePlanID,
      setMapPolylines: (newMapPolylines) => setMapPolylines(newMapPolylines),
      setLassoLayers,
      handleItemClick,
      setPolylineDataIDs
    });

    //Для границ рейсов
    useMapConvexItems(Map, mapLayersGroup, convex, mapConvex, (v) => setMapConvex(v));
    //обновление элементов для слоев карты
    useMapLayersItems(Map, mapLayersGroup, layerData);

    //TODO починить фокус точек рейса
    // обновление стилей по фокусу на нераспределенные заявки и точки рейса
    useOrdersFocus(mapMarkers, mapPolylines, selectedOrdersLasso, selectedOrders, unresolvedMarkerSource);
    //Для выделения треков, при перемещении на них заявок
    useMapDragPolyStyle(mapPolylines, setMapPolylines, polylineDataIDs, markerDragging);
    //DragNDrop
    useDragDrop(Map, dragging, selectedOrdersLasso, markerDragging, marker, mapData.waypointMapData, (d: Dragging | null) =>
      setDragging((prevD) => (!deepEqual(d, prevD) ? d : prevD))
    );

    //Отображение линий, соединяющих точки погрузки / разгрузки, для нераспределенных заявок
    useMapPairsUnresolved({ Map, markers: marker, lines: mapData?.line, planningMapSettings, selectedOrders });

    useOrderTrackFitBound({ activePlanID, Map, markers: marker, polyline, triggerMapFocus, isInit: mapLayersGroup.isInit });

    //Использование данных через Ref для уменьшения ререндеров
    const { mapMarkersRef, mapPolylinesRef, polylineDataIDsRef } = useDragMarkerRefData({ mapMarkers, mapPolylines, polylineDataIDs });

    return (
      <div className={cn('h-100 position-relative')} onMouseLeave={(event) => onMouseLeave(event.target as HTMLElement)}>
        <div ref={mapRefExtraFromContainer} className={cn('h-100 position-relative ils-planning-map', { fullTable })}>
          <div className={cn('map-container', { 'blur-map': mapWaitingMode })} ref={mapRef} style={{ position: 'relative' }}>
            {mapWaitingMode && (
              <div className={'map-center-loader'}>
                <ILSSpin />
              </div>
            )}
            <div id="ils-map" style={MAP_SIZES} />
            <MapButtonsToolbar
              recordMapButtons={recordMapButtons}
              mapLayersData={mapLayersData}
              activeLayers={activeLayers}
              lassoIsActive={lassoIsActive}
              rulerIsActive={rulerIsActive}
              onChangeLayer={onChangeLayer}
              showConvexPolygon={showConvexPolygon}
              setShowConvexPolygon={setShowConvexPolygon}
              map={Map.current}
              setLassoIsActive={setLassoIsActive}
              setRulerIsActive={setRulerIsActive}
              resetSelectedData={resetSelectedData}
            />

            <ILSPlanningMatrixContainer />
            {showOrderTable && (
              <PlannerMapTablesContainer
                selectedOrdersLasso={selectedOrdersLasso}
                handleShowOrderTable={handleShowOrderTable}
                showVehicleTable={showVehicleTable}
                showOrderTable={showOrderTable}
                markerDragging={markerDragging}
                onItemDrop={onItemDrop}
                handleShowVehicleTable={handleShowVehicleTable}
                resetSummaryData={resetSummaryData}
                onSelectRows={onSelectRows}
              />
            )}
          </div>
          {dragging?.x && dragging?.y && (
            <ILSPlanningDragMarker
              dragging={dragging}
              polylineDataIDs={polylineDataIDsRef}
              handleMarkerDragEnd={handleMarkerDragEnd}
              // toggleMarkerDragging={toggleMarkerDragging}
              selectedOrdersLasso={selectedOrdersLasso}
              selectedOrders={selectedOrders}
              mapPolylines={mapPolylinesRef}
              mapMarkers={mapMarkersRef}
              onMouseLeave={onMouseLeave}
              orderCluster={mapLayersGroup.orderCluster}
              map={Map}
              markerDragging={markerDragging}
              unresolvedMarkerSource={unresolvedMarkerSource}
              setMarkerDragging={setMarkerDragging}
            />
          )}
        </div>
      </div>
    );
  }
);
