import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { monitoringDashboardInfoRoutine } from '../actions';
import { DashboardTables } from '../types';
import { MonitoringAPI } from '@modules/monitoring/api';
import { apiMethodsNames, DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../constants/tables';

type DashboardRequest = {
  activeTab: DashboardTables | undefined;
  from: string;
  till: string;
  updates?: boolean;
};

// Получает данные таблицы в зависимости от текущего активного таба
function* monitoringDashboardInfoWorker(action: PayloadAction<DashboardRequest>) {
  const { request, success, failure, fulfill } = monitoringDashboardInfoRoutine;
  const { activeTab, from, till, updates } = action.payload;

  try {
    yield put(request({ activeTab, from, till, withoutNotification: updates }));
    yield delay(1000); //TODO задержка чтобы не спамить запросами в случае неполадок

    const type = activeTab ? apiMethodsNames[activeTab] : apiMethodsNames[DashboardTablesNumberMetrics.Served];

    if (activeTab === DashboardTablesObjMetrics.Violations || activeTab === DashboardTablesObjMetrics.FuelDrain) {
      const { data } = yield call(MonitoringAPI.getBDD, {
        from,
        till
      });

      yield put(success({ data: data.data, activeTab }));
    } else {
      const { data } = yield call(MonitoringAPI.getDashboard, {
        type,
        from,
        till,
        updates
      });

      yield put(success({ data: data.data, activeTab, updates, withoutNotification: updates }));
    }
  } catch (error) {
    yield put(failure({ error, activeTab }));
  } finally {
    yield put(fulfill({ activeTab }));
  }
}

export function* monitoringDashboardInfoWatcher() {
  yield takeLatest(monitoringDashboardInfoRoutine.trigger, monitoringDashboardInfoWorker);
}
