import { parseFunction } from '@modules/catalog/helpers/tariff/parse-function';
import { MATH, tariffListKeys } from '@modules/catalog/constants/tariff';

export function parseTariffFormula(formula: string, params: any[], html = false) {
  //парсинг формулы для редактора
  try {
    formula = parseFunction(formula);
    let f = MATH.parse(formula);
    f.filter(function (node: math.MathNode | any) {
      if (node.isSymbolNode && node.name !== 'math') {
        if (!tariffListKeys.includes(node.name)) throw new Error();

        if (node.name.includes('UserParam_')) {
          const paramID = node.name.replace('UserParam_', '');
          const p = params && params.filter((p: { ID: number }) => p.ID === parseInt(paramID) && p);
          const text = html ? '<span>' + node.name + '</span>' : p[0].Name;
          formula = p && formula.replace(node.name, text);
        }
      }
      return 1;
    });
    formula = parseFunction(formula, false, true);
    return formula;
  } catch {
    return html ? null : formula;
  }
}
