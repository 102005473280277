import { AnalyticsStore } from '@modules/analytics/common/types/store';

const initialAnalyticsState: AnalyticsStore = {
  lastSelectedRange: ['', ''],
  selectedRange: ['', ''],
  WidgetData: {},
  Boards: {
    data: {},
    selectedBoard: undefined,
    isFetching: false,
    updatingBoard: undefined,
    adding: false,
    deleting: false,
    selectedDate: ['', '']
  },
  Widgets: {},
  WidgetParams: {},
  BoardSets: {
    data: {},
    selectedBoardSet: undefined,
    isFetching: false,
    updatingSet: undefined,
    adding: false,
    deleting: false
  }
};

export default initialAnalyticsState;
