import { FC } from 'react';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse/Collapse';
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';
import { ExpandIconPosition } from '@components/data-display/collapse/types';

const { Panel } = Collapse;

export const ILSCollapse: FC<CollapseProps> = ({ expandIconPosition = ExpandIconPosition.Right, ...props }) => {
  return <Collapse expandIconPosition={expandIconPosition as never} {...props} />;
};

export const ILSCollapsePanel: FC<CollapsePanelProps> = (props) => {
  return <Panel {...props} />;
};
