import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { TableColumnName } from '@common/types';
import { ForwarderPointStatusesGroupResponse } from '@modules/settings/types/forwarder-point-statuses';
import { getModelsId } from '@common/utils';

export const forwarderPointStatusesGroup2table = (forwarderPointStatusesGroup: ForwarderPointStatusesGroupResponse) => {
  return createArrayFromIndexedArray(forwarderPointStatusesGroup).map((forwarderPointStatusGroup) => {
    return {
      ...forwarderPointStatusGroup,
      key: forwarderPointStatusGroup.ID,
      [TableColumnName.AvailableStatusesID]: getModelsId(forwarderPointStatusGroup?.AvailableStatuses),
      AvailableStatusesEnum: forwarderPointStatusGroup?.AvailableStatuses?.reduce((enums, statusGroup) => {
        enums[statusGroup.ID] = statusGroup.Name;
        return enums;
      }, {})
    };
  });
};
