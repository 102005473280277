import { apiInstance } from '@core/api/instance';
import { AxiosResponse } from 'axios';
import { AuthAPI, LoggedInUser, RegisterRequestProps } from '../types/auth';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const Auth: AuthAPI = {
  index: () => apiInstance().get(getAPIURL(Dictionary.Account, DictionariesEndpoint.Index)),
  login: async (params) => {
    const { login, password } = params;
    const { data } = await apiInstance().post<Promise<AxiosResponse<LoggedInUser>>>('/NewTms/auth/signIn', {
      l: login.trim(),
      p: password
    });

    return data;
  },
  logout: () => {
    return apiInstance().post('/NewTms/auth/signOut');
  },
  registerRequest: async (params) => {
    const { values } = params;
    const { data } = await apiInstance().post<Promise<AxiosResponse<RegisterRequestProps>>>('/NewTms/auth/registerRequest', { ...values });
    return data;
  },
  register: async (params) => {
    const { token } = params;
    return await apiInstance().post('/NewTms/auth/register', { token });
  },
  recoveryRequest: async (params) => {
    const { email, link } = params;

    return await apiInstance().post('/NewTms/auth/resetPasswordRequest', {
      email,
      link
    });
  },
  recovery: (params) => {
    const { token } = params;

    return apiInstance().post('/NewTms/auth/resetPassword', { token });
  },
  getRole: () => {
    return apiInstance().post('/NewTms/Account/getRoleEnum');
  },
  checkToken: () => {
    return apiInstance().post('/NewTms/auth/me');
  }
};
