import { createSelector } from 'reselect';
import { projectCommonDictionarySelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary, ReferenceTablesData } from '@common/types';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';

export const planningRestrictionsTableComponentSelector = createSelector(
  (state: PlanningByDateState) => projectCommonDictionarySelector(state, [Dictionary.RestrictionGroup]),
  ({ RestrictionGroup }) => {
    const refData: ReferenceTablesData = {
      RestrictionGroupID: RestrictionGroup
    };
    return { refData };
  }
);
