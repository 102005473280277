import { useState } from 'react';

export const useModal = (initial: boolean = false) => {
  const [visible, setVisible] = useState(initial);
  const handleShow = () => setVisible(!visible);
  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);

  return { handleShow, visible, setVisible, handleClose, handleOpen };
};
