import { FC } from 'react';
import { MainTableMapContainer } from '../containers/main-table-with-map';

interface Props {
  handleDateSelect: (date: string[]) => void;
  handleRedirect: (name: string) => void;
}

// Основной компонент отвечающий за отображение модуля «Детализация событий»
export const ILSMonitoringEventDetailsComponent: FC<Props> = ({ handleDateSelect, handleRedirect }) => {
  return <MainTableMapContainer handleDateSelect={handleDateSelect} handleRedirect={handleRedirect} />;
};
