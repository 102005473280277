import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { generateUUID } from '@common/utils/general/uuid';
import { createPlanRoutine } from '@modules/planning/actions';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { Project } from '@common/types';
import { addCreatedPlanRoutine } from '@modules/planning/children/by-date/actions';

function* planningCreateWorker(
  action: PayloadAction<{
    projectID?: Project['ID'];
    name: string;
    getPlan?: boolean;
  }>
) {
  const { request, failure, fulfill, success } = createPlanRoutine;
  const { name, getPlan, projectID } = action.payload;
  const notifyKey = generateUUID();
  try {
    yield put(request({ notifyKey }));
    const activeProjectID: Project['ID'] = yield select(activeProjectIdSelector);
    const {
      data: { data }
    } = yield call(PlanningAPI.createPlan, { projectID: projectID || activeProjectID, name });

    if (getPlan) {
      yield put(addCreatedPlanRoutine({ plan: data }));
    }
    yield put(success({ notifyKey }));
  } catch (error) {
    yield put(failure({ error, notifyKey }));
  } finally {
    yield put(fulfill({ notifyKey }));
  }
}

export function* createPlanWatcher() {
  yield takeLatest(createPlanRoutine.trigger, planningCreateWorker);
}
