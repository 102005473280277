import { Notify, NotifyDurationInSecond } from '@common/types';
import { ErrorPayload } from '@common/types/general/check-coords';

export const GET_COORD_NOTIFY: ErrorPayload = {
  getCoordinatesError: {
    message: 'Не удалось получить координаты по адресу (введённый адрес не найден в базе адресов, проверьте корректность ввода)',
    type: Notify.Warning,
    duration: NotifyDurationInSecond.Five,
    key: 'CATALOG/GET_COORDINATES_ERROR'
  },
  getAddressError: {
    message: 'Не удалось получить адрес по координатам',
    type: Notify.Warning,
    duration: NotifyDurationInSecond.Five,
    key: 'CATALOG/GET_ADDRESS_ERROR'
  }
};
