import { FC, useEffect } from 'react';
import { statusDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { useAppDispatch } from '@core/hooks';

const ILSMatrixStatusPolling: FC<{ projectID: number | null }> = ({ projectID }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(statusDistanceMatrixRoutine({ projectID, withoutLoading: true, withoutNotification: true }));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
};

export default ILSMatrixStatusPolling;
