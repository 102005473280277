import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { ILSButtonTooltip } from '@common/components';
import { PlacementType } from '@common/types/components';
import { IButtonTooltipProps } from '@components/general/buttons/types';

interface IProps extends Omit<IButtonTooltipProps, 'icon' | 'title'> {
  title?: string;
}

export const ButtonExportExcel: FC<IProps> = ({ placement = PlacementType.bottom, title = 'Экспорт данных в Excel формате', ...props }) => {
  return (
    <ILSButtonTooltip
      {...props}
      placement={placement}
      title={title}
      icon={<ReactSVG src="/assets/icons/excel.svg" className="excel-icon" />}
    />
  );
};
