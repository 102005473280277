import { ILSCascader, ILSFormItem, ILSSelect } from '@common/components';
import { TenderOfferFormItem, TenderOfferFormItemLabel } from '@modules/tender-platform/children/tender-search/constants/offer';
import { vehiclesAndDriversOptionsSelector } from '@modules/tender-platform/children/tender-search/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  disabled: boolean;
}

export const ILSVehicleNDriverField: FC<IProps> = ({ disabled }) => {
  const { vehicleOptions, driverOptions } = useSelector(vehiclesAndDriversOptionsSelector);

  return (
    <>
      <ILSFormItem
        rules={[{ required: !disabled, message: 'Пожалуйста, выберите водителя' }]}
        label={TenderOfferFormItemLabel.Driver}
        name={[TenderOfferFormItem.TenderOffer, TenderOfferFormItem.DriverID]}
      >
        <ILSSelect placeholder={'Выберите водителя'} showArrow={!disabled} disabled={disabled} options={driverOptions} />
      </ILSFormItem>
      <ILSFormItem
        rules={[{ required: !disabled, message: 'Пожалуйста, выберите автомобиль' }]}
        label={TenderOfferFormItemLabel.Vehicle}
        name={[TenderOfferFormItem.TenderOffer, TenderOfferFormItem.VehicleID]}
      >
        <ILSCascader
          showChild
          expandTrigger="hover"
          placeholder={'Выберите автомобиль'}
          showArrow={!disabled}
          disabled={disabled}
          options={vehicleOptions}
        />
      </ILSFormItem>
    </>
  );
};
