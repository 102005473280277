import { planningUpdatePlansAndRoutesAction } from '../../actions';
import { IndexedArray } from '@common/types';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanTotal } from '@common/types/dictionaries/plan';
import { RouteTotal } from '@common/types/dictionaries/route';
import { isEmpty } from 'lodash';

export const planningUpdatePlansAndRoutesReducer = createReducer(initialState, {
  [planningUpdatePlansAndRoutesAction.SUCCESS]: (
    state,
    action: PayloadAction<{
      planTotal?: IndexedArray<PlanTotal>;
      routeTotal?: IndexedArray<RouteTotal>;
    }>
  ) => {
    const { planTotal, routeTotal } = action.payload;

    if (!isEmpty(planTotal)) {
      for (const planID in planTotal) {
        if (state.project?.Plan){
          state.project.Plan[planID] = { ...state.project.Plan[planID], PlanTotal: planTotal[planID] };
        }
      }
    }

    if (!isEmpty(routeTotal)) {
      for (const vehicleID in routeTotal) {
        state.currentPlan.RouteTotal[vehicleID] = { ...routeTotal[vehicleID], changed: true };
      }
    }

    return state;
  }
});
