import reduceReducers from 'reduce-reducers';
import monitoringDateReducer from './date-select';
import monitoringMetricsReducer from './metrics';
import monitoringGetVehicleTrackersReducer from './get-vehicle-trackers';
import { loadPlanTrackReducer } from './load-plan-track';
import { initialState } from '../store';
import { monitoringCreateVehicleTrackerReducer } from './create-vehicle-tracker';
import monitoringGetTrackersReducer from './get-trackers';
import { monitoringCreateTrackerReducer } from './create-tracker';
import { monitoringCreateVehicleTrackerLocalReducer } from './create-vehicle-tracker-local';
import { monitoringCreateTrackerLocalReducer } from './create-tracker-local';
import { monitoringDeleteTrackerReducer } from './delete-tracker';
import { monitoringUpdateTrackerReducer } from './update-tracker';
import { monitoringDeleteVehicleTrackerLocalReducer } from './delete-vehicle-tracker-local';
import { monitoringDeleteVehicleTrackerReducer } from '@modules/monitoring/reducers/delete-vehicle-tracker';
import { monitoringUpdateVehicleTrackerReducer } from '@modules/monitoring/reducers/update-vehicle-tracker';
import { monitoringStatusesReducer } from '@modules/monitoring/reducers/statuses/mobile-application';
import { monitoringSetWaypointStatusReducer } from '@modules/monitoring/reducers/statuses/set-waypoint-status';
import { monitoringSetTripStatusReducer } from '@modules/monitoring/reducers/statuses/set-trip-status';
import { monitoringReadDictionaryReducer } from '@modules/monitoring/reducers/catalog/read';

const monitoringRootReducer = reduceReducers(
  initialState,
  monitoringDateReducer,
  monitoringMetricsReducer,
  monitoringGetVehicleTrackersReducer,
  monitoringCreateVehicleTrackerReducer,
  monitoringGetTrackersReducer,
  monitoringCreateTrackerReducer,
  loadPlanTrackReducer,
  monitoringCreateVehicleTrackerLocalReducer,
  monitoringCreateTrackerLocalReducer,
  monitoringDeleteTrackerReducer,
  monitoringUpdateTrackerReducer,
  monitoringDeleteVehicleTrackerLocalReducer,
  monitoringDeleteVehicleTrackerReducer,
  monitoringUpdateVehicleTrackerReducer,
  monitoringStatusesReducer,
  monitoringSetWaypointStatusReducer,
  monitoringSetTripStatusReducer,
  monitoringReadDictionaryReducer
);

export default monitoringRootReducer;
