import { useState } from 'react';
import { PlannerBlocksFullScreen } from '@core/decorators/split-sizes';
import { PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';
import { TableName } from '@common/types';

export const useMapTables = ({
  onResetSelectedRows
}: {
  onFullChange: (arg: PlannerBlocksFullScreen | PlannerSettingsParameterFullScreen) => void;
  onResetSelectedRows(tableName: TableName): void;
}) => {
  const [showVehicleTable, setShowVehicleTable] = useState(false);
  const [showOrderTable, setShowOrderTable] = useState(false);

  const handleShowVehicleTable = (val: boolean) => {
    setShowVehicleTable(val);
  };

  const handleShowOrderTable = (val: boolean) => {
    setShowOrderTable(val);
    if (!val) {
      onResetSelectedRows(TableName.PlanningNotDeliveredOrder);
    }
  };

  return {
    showVehicleTable,
    showOrderTable,
    handleShowVehicleTable,
    handleShowOrderTable
  };
};
