import { AnalyticsBoardModel, AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import { FC, useCallback, useMemo } from 'react';
import css from 'classnames';
import { ILSListItem, ILSSpin } from '@common/components';

type Props = {
  item: AnalyticsBoardModel | AnalyticsBoardSetModel;
  handleClick?: (data: AnalyticsBoardModel | AnalyticsBoardSetModel) => void;
  showActions?: boolean;
  selectedId?: number | string;
  defaultListItemTitle: string;
  loading?: boolean;
};

const ILSListItemComponent: FC<Props> = ({ handleClick, item, selectedId, defaultListItemTitle, loading = false }) => {
  const onItemClick = useCallback(() => {
    handleClick?.(item);
  }, [handleClick, item]);

  const rowClass = useMemo(() => {
    return css({
      'analytics-list-item': true,
      'analytics-selected-item': item.ID?.toString() === selectedId?.toString()
    });
  }, [item, selectedId]);

  return (
    <div className={rowClass}>
      <ILSListItem key={item.ID} onClick={onItemClick}>
        {loading ? (
          <ILSSpin className="ml-4" spinning={true} />
        ) : (
          <div className="analytics-list-item-value">{item.Name || defaultListItemTitle}</div>
        )}
      </ILSListItem>
    </div>
  );
};

export default ILSListItemComponent;
