enum ReferrerPolicy {
  NoReferrer = 'no-referrer',
  NoReferrerDown = 'no-referrer-when-downgrade',
  Origin = 'origin',
  OriginCross = 'origin-when-cross-origin',
  SameOrigin = 'same-origin',
  StrictOrigin = 'strict-origin',
  StrictOriginCross = 'strict-origin-when-cross-origin',
  Unsafe = 'unsafe-url'
}

enum CrossOrigin {
  Anonymous = 'anonymous',
  UseCredentials = 'use-credentials'
}

type ScriptProps = {
  async?: boolean;
  crossOrigin?: CrossOrigin;
  referrerPolicy?: ReferrerPolicy;
};

export const getScript = <F extends Function>(
  url: string,
  tag: string,
  callback: F,
  { async = true, crossOrigin = CrossOrigin.Anonymous, referrerPolicy = ReferrerPolicy.NoReferrer }: ScriptProps
) => {
  if (!document.getElementById(tag)) {
    const customScript = document.createElement('script');
    customScript.setAttribute('id', tag);
    customScript.async = async;
    customScript.referrerPolicy = referrerPolicy;
    customScript.crossOrigin = crossOrigin;
    customScript.src = url;
    customScript.onload = callback();
    document.body.appendChild(customScript);
  } else {
    callback();
  }
};
