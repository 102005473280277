import { ILSDivider } from '@common/components';
import { ILSTypographyTitle } from '@common/components/general/typography';
import { TenderPlatformUploadFile } from '@modules/tender-platform/children/tender-own/containers/tender-card/documents/upload-file-component';
import { CardTab, CardTabTitle } from '@modules/tender-platform/constants/tender-card';
import ILSTenderDocumentsTableComponent from '@modules/tender-platform/components/tender-card/documents/table';
import { FC } from 'react';

interface IProps {
  isTenderCreating: boolean;
}

export const TenderPlatformDocuments: FC<IProps> = ({ isTenderCreating }) => {
  return (
    <fieldset className="documents">
      <ILSDivider
        key={`${CardTab.Documents}divider`}
        orientation={'left'}
        children={<ILSTypographyTitle level={2} id={CardTab.Documents} children={CardTabTitle.Documents} />}
      />
      {isTenderCreating ? <TenderPlatformUploadFile /> : <ILSTenderDocumentsTableComponent />}
    </fieldset>
  );
};
