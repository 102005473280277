import { useAppSelector } from '@core/hooks';
import { useEffect, useRef } from 'react';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

type UseResetSummaryType = (args: { resetSelectedData: () => void }) => void;

/**
 * хук, который выполнит сброс данных, при сменах между планами
 */
export const useResetSummary: UseResetSummaryType = ({ resetSelectedData }) => {
  const activePlanID = useAppSelector(activePlanIDSelector);
  const prevActivePlanID = useRef(activePlanID);

  useEffect(() => {
    if (prevActivePlanID.current !== activePlanID) {
      if (prevActivePlanID) {
        resetSelectedData();
      }
      prevActivePlanID.current = activePlanID;
    }
  }, [activePlanID]);
};
