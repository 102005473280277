import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { registerActions } from '@modules/current-user/actions';

export const registerReducer = createReducer(authInitialState, {
  [registerActions.REQUEST]: onRequest,
  [registerActions.FAILURE]: onFailure,
  [registerActions.FULFILL]: onFulfill,
  [registerActions.SUCCESS]: (state) => state
});
