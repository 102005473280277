import { Dispatch } from '@reduxjs/toolkit';
import { setTableGrouping } from '@core/actions/table-custom';
import { ColumnTable, Notify, TableRecord } from '@common/types';
import { ILSUserNotify } from '@common/components';

export const handleGrouping = (tableName: string, colsForGrouping: ColumnTable<TableRecord>['dataIndex'][], dispatch: Dispatch<any>) => {
  try {
    dispatch(setTableGrouping({ tableName, colsForGrouping }));
  } catch (err) {
    ILSUserNotify(Notify.Error, 'Ошибка группировки');
  }
};
