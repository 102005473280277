export enum FormLabels {
  Source = 'Точка погрузки',
  Target = 'Точка разгрузки',
  LoadDate = 'Дата погрузки',
  LoadStart = 'Начало погрузки',
  LoadEnd = 'Окончание погрузки',
  UnloadDate = 'Дата разгрузки',
  UnloadStart = 'Начало разгрузки',
  UnloadEnd = 'Окончание разгрузки',
  VehicleType = 'Тип ТС',
  VehicleUnloadType = 'Выгрузка',
  VehicleLoadType = 'Погрузка',
  Loading = 'Загрузка',
  GPSMonitoring = 'GPS - Мониторинг',
  GPSMonitoringDescription = 'Запросить у перевозчика GPS мониторинг груза',
  AmountVehicle = 'Количество машин',
  LoadCapacity = 'Грузоподъемность',
  Belts = 'Страховочные ремни',
  TenderName = 'Имя тендера на платформе',
  TenderType = 'Тип тендера',
  TenderVisibility = 'Видимость тендера',
  TenderDescription = 'Краткое описание',
  StartCollectOffer = 'Начало сбора предложений',
  EndCollectOffer = 'Окончание сбора предложений',
  StartContract = 'Начало исполнения договора',
  EndContract = 'Окончание исполнения договора',
  MinDriverRating = 'Минимальный рейтинг водителя',
  MinCarrierRating = 'Минимальный рейтинг перевозчика',
  MinDriverRoutes = 'Минимальное кол-во выполненных водителем рейсов',
  MaxWeight = 'Максимальный вес',
  Distance = 'Дистанция',
  SourcePoint = 'Откуда (направление)',
  TargetPoint = 'Куда (направление)',
  Contact = 'Контактное лицо',
  Phone = 'Номер телефона',
  Date = 'Дата',
  Address = 'Адрес'
}

export enum TimeLineLabels {
  Load = 'Погрузка',
  Unload = 'Разгрузка'
}

export enum FormPlaceholders {
  VehicleType = 'Выберите тип кузова автомобиля',
  VehicleUnloadType = 'Выберите тип выгрузки',
  VehicleLoadType = 'Выберите тип погрузки',
  TenderName = 'Введите название тендера',
  TenderType = 'Выберите тип тендера',
  TenderVisibility = 'Видимость тендера',
  TenderDescription = 'Введите краткое описание',
  Contact = 'Контактное лицо',
  Phone = 'Введите контактный номер телефона'
}
