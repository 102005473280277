import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RcFile } from 'antd/lib/upload';
import { uploadTripDocumentsRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* uploadTripDocumentsWorker(
  action: PayloadAction<{
    dependentID: number;
    attachment: RcFile;
    subject?: string;
  }>
) {
  const { request, success, failure, fulfill } = uploadTripDocumentsRoutine;
  const { dependentID, attachment, subject } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.uploadTripDocuments, {
      dependentID,
      attachment,
      dependent: 'tender',
      subject: subject ?? 'document'
    });
    yield put(success({ data, tripID: dependentID.toString() }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* uploadTripDocumentsWatcher() {
  yield takeLatest(uploadTripDocumentsRoutine.trigger, uploadTripDocumentsWorker);
}

