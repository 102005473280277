import '../styles.less';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CATALOG_RELOAD } from '@core/constants/core';
import { CATALOGS } from '@modules/catalog/constants';
import { resetCatalogInfoRoutine } from '../actions';
import { ILSCatalogComponent } from '@modules/catalog/components';

export const ILSCatalog: FC = () => {
  //для удаления строки добавления
  const [removeAddRow, setRemoveAddRow] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    //NOTE: Если был выполнен импорт новых данных
    if (localStorage.getItem(CATALOG_RELOAD)) {
      //NOTE: Сбрасываем lastUpdate, чтобы можно было при открытии таблицы обновить каталог
      dispatch(resetCatalogInfoRoutine());
    }
  }, []);

  return <ILSCatalogComponent catalogs={CATALOGS} removeAddRow={removeAddRow} setRemoveAddRow={setRemoveAddRow} />;
};
