import { createSelector } from 'reselect';
import { activePlanBeforeExecutionSelector, activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const vehicleMonitoringSendLinksButtonDisabledSelector = createSelector(
  activePlanIDSelector,
  activePlanBeforeExecutionSelector,
  (planID, isPlanBeforeExecution) => {
    const disabled = isPlanBeforeExecution || !planID;
    return { disabled };
  }
);
