import { clearAppStore } from '@core/store';
import { ICoreState } from '@core/types/store';
import { logoutActions } from '@modules/current-user/actions';
import { Store } from 'redux';

export const resetAllStorageData = ({ store }: { store: Store<ICoreState> }) => {
  clearAppStore(true, () => {
    store.dispatch(
      logoutActions({
        clearStore: true
      })
    );
  });
};

