import {
  RecordType,
  SchemeDragData,
  SchemeDragInfo,
  SchemeSwitchDictionary,
  TypeContainer
} from '@modules/planning/children/scheme-editor/types';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { FC, useState } from 'react';
import { InfoCard } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/info-card';
import { EmptyCard } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/empty/empty-card';
import { isEmpty, isInfo } from '@modules/planning/children/scheme-editor/utils/helpers/item-type-helpers';
import { SchemeCard } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/scheme-card';

interface IProps {
  item: RecordType;
  typeContainer: TypeContainer;
  scheme: SchemeSwitchDictionary;

  handleMoveSchemeKey({ target, source, typeContainer }: SchemeDragInfo): void;
}

export const SchemeEditorTransferCard: FC<IProps> = ({ typeContainer, scheme, item, handleMoveSchemeKey }) => {
  const [target, setTarget] = useState<RecordType['title']>('');

  const dragData: SchemeDragData['dragData'] = { source: { title: item.key, typeContainer } };

  const onHit = ({ dragData: { source } }: SchemeDragData) => handleMoveSchemeKey({ target, source, typeContainer });

  const handleEnter = () => setTarget(item.key);

  return (
    <DragDropContainer key={item.key} targetKey={'scheme'} dragData={dragData}>
      <DropTarget onHit={onHit} onDragEnter={handleEnter} targetKey={'scheme'}>
        <fieldset className={'scheme-editor-scheme-input'}>
          {isInfo(item) ? (
            <InfoCard item={item} scheme={scheme} />
          ) : isEmpty(item) ? (
            <EmptyCard item={item} scheme={scheme} />
          ) : (
            <SchemeCard item={item} scheme={scheme} />
          )}
        </fieldset>
      </DropTarget>
    </DragDropContainer>
  );
};
