import { TemplateMode } from '@common/types';
import { TemplateModeLabel, TemplateModeValue } from '@common/types/dictionaries/trip';

export const NEW_TRIP_TEMPLATE = {
  Name: 'Новый шаблон рейса'
};

export const TRIP_TEMPLATE_MODES = [
  TemplateModeValue.Disabled,
  TemplateModeValue.FullTrip,
  TemplateModeValue.OneTrip,
  TemplateModeValue.ManyTrip
];

export const TRIP_TEMPLATE_MODE_OPTIONS = TRIP_TEMPLATE_MODES.map((mode) => ({
  value: TemplateMode[mode],
  label: TemplateModeLabel[mode]
}));

export const CONDITIONS_FOR_CHECK = [
  { value: 0, label: 'И' },
  { value: 1, label: 'ИЛИ' }
];
