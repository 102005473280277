import { put, takeLatest } from 'redux-saga/effects';
import { addSchemeKeysRoutine } from '@modules/planning/children/scheme-editor/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';

function* worker(
  action: PayloadAction<{
    key: string;
    title?: string;
    description?: string;
    dictionary: SchemeSwitchDictionary;
  }>
) {
  const { success, failure } = addSchemeKeysRoutine;
  try {
    yield put(success(action.payload));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* addSchemeKeysWatcher() {
  yield takeLatest(addSchemeKeysRoutine.trigger, worker);
}
