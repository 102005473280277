import { ILSUserNotify } from '@common/components';
import { ILSUserNotifyClose } from '@common/components/feedback/ils-user-notify';
import { generateUUID } from '@common/utils/general/uuid';
import { userNotifyRoutine } from '@core/actions/user-notification';
import { INotifyPayload } from '@core/types/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';

function* notifyWorker(action: PayloadAction<INotifyPayload>) {
  const { type, status, icon, placement, key, duration } = action.payload;
  const content = status;
  const uuid = key || generateUUID();

  yield call(ILSUserNotify, type, content, duration || 10, uuid, icon, undefined, undefined, undefined, placement);
}

function* closeWorker(action: PayloadAction<{ key: string }>) {
  const { key } = action.payload;
  yield call(ILSUserNotifyClose, key);
}

/**
 * Watcher для отображения уведомлений
 */
export function* userNotifyWatcher() {
  yield takeLatest(userNotifyRoutine.trigger, notifyWorker);
}

/**
 * Watcher для закрытия уведомлений
 */
export function* userNotifyCloseWatcher() {
  yield takeLatest(userNotifyRoutine.FULFILL, closeWorker);
}
