import { ILSAlert, ILSButton } from '@common/components';
import { TripStatus, TripStatusTitle } from '@modules/orders-and-trips/types/trip';
import { FC } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Notify } from '@common/types';

interface IProps {
  tripStatus: TripStatus;
  changeActiveTab(tripStatus: TripStatus, isUpdate: boolean): void;
  updateTripStatus: TripStatus | null;
}

export const UpdateTripsInfo: FC<IProps> = ({ tripStatus, changeActiveTab, updateTripStatus }) => {
  if (!updateTripStatus || updateTripStatus !== tripStatus) return null;
  const onUpdateTrips = () => {
    changeActiveTab(tripStatus, true);
  };
  return (
    <ILSAlert
      message={`Часть рейсов из категории "${TripStatusTitle[tripStatus]}" изменили свой статус`}
      type={Notify.Warning}
      showIcon
      action={
        <ILSButton onClick={onUpdateTrips} icon={<ReloadOutlined />} type="ghost">
          Обновить
        </ILSButton>
      }
    />
  );
};
