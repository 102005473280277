import { useDispatch } from 'react-redux';
import { RouteRecord } from '@modules/planning/children/by-date/types';
import { createTrip } from '@modules/planning/children/by-date/helpers/tables/handlers/routes/create-trip';

export const useCreateTrip = (record: RouteRecord) => {
  const dispatch = useDispatch();
  const handleCreateTrip = createTrip({ record, dispatch });

  return { handleCreateTrip };
};
