import { MutableRefObject } from 'react';
import { ILSMap } from '@core/containers/map';
import { Track } from '@modules/track/types';
import L, { LatLngExpression } from 'leaflet';
import { Marker } from '@core/types/map';
import { isEmpty, isString } from 'lodash';
import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { drawMarkers } from '@core/components/map/helpers/draw-marker';

export const drawPolylines = (
  Map: MutableRefObject<ILSMap | null>,
  polylines: Track[],
  markerLayer: L.LayerGroup,
  trackLayer: L.LayerGroup
) => {
  if (Map.current) {
    let markers: Marker[] = [];
    if (!isEmpty(polylines)) {
      polylines.forEach((polyline) => {
        const marker = polyline.point && polyline.point.marker;
        let dashedArr = [2, 5];
        if (polyline.weight) {
          dashedArr = [polyline.weight && polyline.weight < 3 ? polyline.weight * 3 : polyline.weight * 2, polyline.weight * 5];
        }

        const coords = isString(polyline.coords) ? GeohashDecodePoints(polyline.coords) : polyline.coords;
        if (Map.current && !isEmpty(coords)) {
          if (marker) {
            markers = markers.concat(marker);
          }

          const polyLine = L.polyline(coords as LatLngExpression[], {
            color: `${polyline.color}B3` ?? '#0e6e19',
            weight: polyline.weight ?? 3,
            dashArray: polyline?.dashed ? dashedArr.join(', ') : undefined
          });

          polyLine.addTo(trackLayer as L.LayerGroup);
        }
      });
    }

    if (!isEmpty(markers)) {
      drawMarkers(Map, markers, markerLayer);
    }
  }
};

