import { FormField } from '@modules/documents/children/upload/types/form-field';
import { CellType, ColumnTable, TableRecord } from '@common/types';
import { setChildWithStyles } from '../children/templates/helpers';

export const generateColumns = (formFields: FormField[]): ColumnTable<TableRecord>[] => {
  return formFields.map(
    (f) =>
      ({
        title: f.name,
        type: CellType.Input,
        key: f.alias,
        dataIndex: f.alias,
        isFixedColumn: true,
        ellipsis: true,
        render: f.alias === 'TypeName' && setChildWithStyles,
        active: true
      } as ColumnTable<TableRecord>)
  );
};
