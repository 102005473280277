import { int2color } from '@common/decorators/color';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { TripTemplate } from '@common/types';

export const tripTemplates2table = (tripTemplates: Array<TripTemplate>) => {
  return createArrayFromIndexedArray(tripTemplates).map((tripTemplate, i) => {
    return {
      ...tripTemplate,
      key: tripTemplate.ID ?? 'row' + i,
      Color: int2color(String(tripTemplate.Color))
    };
  });
};
