import { FC } from 'react';
import { tableCustom } from '@core/containers/table-custom';
import { ILSTable } from '@components/index';
import { Dictionary, IILSTableProps, TableName, TableRecord } from '@common/types';
import { VEHICLE_TABLE_CONFIG_PLANNER, VEHICLES_TABLE_SCROLL_SIZE } from '@common/models/vehicle/constants';

interface IProps extends Omit<IILSTableProps<TableRecord>, 'config'> {}

export const ILSPlanningVehicleTableComponent: FC<IProps> = tableCustom(
  (props) => {
    return (
      <ILSTable
        {...props}
        scroll={VEHICLES_TABLE_SCROLL_SIZE}
        tableName={TableName.PlanningVehicle}
        className="result-table"
        dictionary={Dictionary.Vehicle}
      />
    );
  },
  VEHICLE_TABLE_CONFIG_PLANNER,
  Dictionary.Vehicle
);
