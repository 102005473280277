import { DOCUMENTS_EXTENSION } from '@common/constants/general/path';

export const PlannerExportTypes = {
  Trip: 'Рейсы',
  Waypoint: 'Список путевых точек',
  OrderAction: 'Список действий по заявкам',
  Plan: ' План перевозок для импорта',
  SimplePlan: 'Простой импорт плана (одна строка файла - одна точка с заявкой)'
};

export const ExportFormatOptions = [
  { label: DOCUMENTS_EXTENSION.xlsx, value: DOCUMENTS_EXTENSION.xlsx },
  { label: DOCUMENTS_EXTENSION.txt, value: DOCUMENTS_EXTENSION.txt }
];
