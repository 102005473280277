import { ILSFormItem, ILSInput, ILSTooltip, ILSTypographyText } from '@components/index';
import { RecordType, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { FC } from 'react';

interface IProps {
  item: RecordType;
  scheme: SchemeSwitchDictionary;
}

export const EmptyCard: FC<IProps> = ({ scheme, item }) => {
  return (
    <>
      <ILSFormItem initialValue={item.type} name={[scheme, item.key, 'type']} style={{ display: 'none' }} />
      <ILSTooltip title={item.title}>
        <ILSTypographyText className={'scheme-editor-scheme-input-title'} children={item.type} />
      </ILSTooltip>
      <ILSFormItem>
        <ILSInput defaultValue={item.title} disabled />
      </ILSFormItem>
    </>
  );
};
