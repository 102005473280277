import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { NEW_SPEED_PROFILE } from '@common/models/speed-profile/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@common/components/data-display/table/constants/cell';

export const SPEED_PROFILE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: true,
  export: true,
  adding: NEW_SPEED_PROFILE,
  showDeleted: ShowDeleted.Yes,
  reloading: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,

      sorting: 2,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Значение, км/ч',
      dataIndex: 'BaseValue',
      key: 'BaseValue',
      active: true,

      editable: true,
      align: 'center',
      isFixedColumn: true,
      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 3,
      grouping: true
    },
    {
      title: 'Автомагистрали, км/ч',
      dataIndex: 'Motorway',
      key: 'Motorway',
      active: true,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,

      sorting: 4,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Трассы, км/ч',
      dataIndex: 'Trunk',
      key: 'Trunk',
      active: true,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Региональные, км/ч',
      dataIndex: 'Primary',
      key: 'Primary',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Областные, км/ч',
      dataIndex: 'Secondary',
      key: 'Secondary',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Важные, км/ч',
      dataIndex: 'Tertiary',
      key: 'Tertiary',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Местные, км/ч',
      dataIndex: 'Residential',
      key: 'Residential',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 9,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Обычные, км/ч',
      dataIndex: 'Road',
      key: 'Road',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,

      sorting: 10,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Неклассифицированные, км/ч',
      dataIndex: 'Unclassified',
      key: 'Unclassified',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 13,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Служебные, км/ч',
      dataIndex: 'Service',
      key: 'Service',
      active: false,
      editable: true,

      sorting: 14,
      type: CellType.Int,
      filtering: FilterCellType.String,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Дворы, км/ч',
      dataIndex: 'LivingStreet',
      key: 'LivingStreet',
      active: false,
      editable: true,

      type: CellType.Int,
      filtering: FilterCellType.String,
      sorting: 15,

      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Съезды-Автомагистрали, км/ч',
      dataIndex: 'MotorwayLink',
      key: 'MotorwayLink',
      active: false,
      editable: true,

      sorting: 16,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Съезды-Региональные, км/ч',
      dataIndex: 'PrimaryLink',
      key: 'PrimaryLink',
      active: false,
      editable: true,

      sorting: 17,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Съезды-Областные, км/ч',
      dataIndex: 'SecondaryLink',
      key: 'SecondaryLink',
      active: false,
      editable: true,

      sorting: 18,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Съезды-Важные, км/ч',
      dataIndex: 'TertiaryLink',
      key: 'TertiaryLink',
      active: false,
      editable: true,

      sorting: 19,
      type: CellType.Int,
      filtering: FilterCellType.String,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS,
      grouping: true
    },
    {
      title: 'Коэфф-база',
      dataIndex: 'BaseFactor',
      key: 'BaseFactor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 20,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-будни',
      dataIndex: 'WeekdayFactor',
      key: 'WeekdayFactor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 21,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-выходные',
      dataIndex: 'WeekendFactor',
      key: 'WeekendFactor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 22,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №1',
      dataIndex: 'Zone1Factor',
      key: 'Zone1Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 23,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №2',
      dataIndex: 'Zone2Factor',
      key: 'Zone2Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 24,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №3',
      dataIndex: 'Zone3Factor',
      key: 'Zone3Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 25,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №4',
      dataIndex: 'Zone4Factor',
      key: 'Zone4Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 26,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №5',
      dataIndex: 'Zone5Factor',
      key: 'Zone5Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 27,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №6',
      dataIndex: 'Zone6Factor',
      key: 'Zone6Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 28,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №7',
      dataIndex: 'Zone7Factor',
      key: 'Zone7Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 29,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Коэфф-зона №8',
      dataIndex: 'Zone8Factor',
      key: 'Zone8Factor',
      active: false,
      editable: true,
      validationParameters: {
        step: 0.1
      },
      sorting: 30,
      type: CellType.Float,
      filtering: FilterCellType.String,
      grouping: true
    }
  ]
};
