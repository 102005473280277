import { FC, useContext } from 'react';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';
import { ButtonAction } from '@components/general/buttons';
import { TenderFromPlannerOperationButtonContainer } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/actions';
import { Status, Tender, TenderForm } from '@modules/tender-platform/types';
import { TenderPlatformTransitionSchemeStatusButtonsContainer } from '@modules/tender-platform/containers/tender-card/menu/transition-status-buttons';
import { Module } from '@common/types';
import { ILSOfferButtons } from '@modules/tender-platform/children/tender-search/components/approval-action-buttons';
import { TenderButtonText, TenderOperationButton } from '@modules/tender-platform/constants';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { useSelector } from 'react-redux';
import { fetchSelector, menuButtonsSelector } from '@modules/tender-platform/selectors';
import { useTenderPlatformPermissions } from '@modules/tender-platform/abac/hooks/permissions';
import { useTenderCreating } from '@modules/tender-platform/utils/hooks';
import { TenderOperationsArgument } from '@modules/tender-platform/constants/content';

// import { TenderPlatformButtonToPlanner } from '@modules/tender-platform/components/button-to-planner';

interface IProps {
  handleModal({ operation }: TenderOperationsArgument): void;

  handleTenderSetStatus(tenderID: Tender['ID'], statusID: Status['ID']): void;
}

export const TenderPlatformActionsButtonsContainer: FC<IProps> = ({ handleModal, handleTenderSetStatus }) => {
  const tenderPlatformChildrenModule = useContext(ILSTenderModuleContext);
  const isFetching = useSelector(fetchSelector);
  const { ID, isAuction } = useSelector(menuButtonsSelector);

  const { canViewRatingModal, isTenderEditable } = useTenderPlatformPermissions();
  const { isTendersCreatingFromPlanner } = useTenderCreating();

  //TODO patcher
  const cancelCreatingTender = () => handleModal({ operation: TenderOperationButton.Cancel });
  const goBack = () => handleModal({ operation: TenderOperationButton.Back });

  return (
    <>
      <TenderPlatformMenuButton
        onClick={ID ? goBack : cancelCreatingTender}
        action={ID ? ButtonAction.Back : ButtonAction.Cancel}
        type="link"
      />
      {isTendersCreatingFromPlanner ? (
        <TenderFromPlannerOperationButtonContainer handleModal={handleModal} />
      ) : (
        <>
          {/* TENDER_FORM BUTTONS */}
          {isTenderEditable && (
            <TenderPlatformMenuButton action={ButtonAction.Save} form={TenderForm.Tender} key="submit" htmlType="submit" />
          )}
          <TenderPlatformTransitionSchemeStatusButtonsContainer handleTenderSetStatus={handleTenderSetStatus} handleModal={handleModal} />
          {/* TENDER_OFFER BUTTONS */}
          {tenderPlatformChildrenModule === Module.TenderSearch && <ILSOfferButtons isAuction={isAuction} isFetching={isFetching} />}
          {/* TENDER_RATING BUTTONS */}
          {canViewRatingModal && (
            <TenderPlatformMenuButton
              children={TenderButtonText.SendCarrierRating}
              onClick={() => handleModal({ operation: TenderOperationButton.Rate })}
            />
          )}
          {/* Убираем кнопку до реализации обратной интеграции Планировщика и Тендерной площадки */}
          {/* {isExecuted && <TenderPlatformButtonToPlanner />} */}
        </>
      )}
    </>
  );
};
