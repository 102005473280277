import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringDeleteTrackerRoutine } from '../actions';

// Удаляет трекер
export const monitoringDeleteTrackerReducer = createReducer(initialState, {
  [monitoringDeleteTrackerRoutine.REQUEST]: (state) => {
    state.trackers.isFetching = true;

    return state;
  },
  [monitoringDeleteTrackerRoutine.SUCCESS]: (state, action: PayloadAction<number>) => {
    const trackerIMEI = action.payload;

    state.trackers.data = state.trackers.data.filter((tracker) => tracker.IMEI !== trackerIMEI);
    state.trackers.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringDeleteTrackerRoutine.FAILURE]: (state) => {
    state.trackers.lastUpdate = undefined;

    return state;
  },
  [monitoringDeleteTrackerRoutine.FULFILL]: (state) => {
    state.trackers.isFetching = false;
    return state;
  }
});
