import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { ILSForm, ILSFormItem, ILSSelect, ILSSkeleton } from '@common/components';
import { formModelSelector, isFetchingSelector } from '@modules/documents/children/upload/selectors';
import { Document } from '@modules/documents/types/document';
import { FieldFilteringType, FormFieldType } from '@modules/documents/children/upload/types/form-field';
import FilterInput from '@modules/documents/components/filter-input';
import { prepareDocumentsPayload } from '@modules/documents/helpers/prepare-documents-payload';
import { getFormFieldsRoutine } from '@modules/documents/children/upload/actions';
import { shortDocumentTypeOptions } from '@modules/documents/constants/document-type-options';
import { DocumentType, IPayloadUpdate } from '@modules/documents/types/document-type';
import transformDateIntoDateWithTime from '@modules/documents/utils/into-date-with-time';

const ILSUpdateDocumentForm: FC<{
  document: Document | null;
  disabled: boolean;
  submit: any;
  updateDocument: (payload: IPayloadUpdate, oldValues: IPayloadUpdate, type: DocumentType) => void;
}> = ({ document, disabled, submit, updateDocument }) => {
  if (!document) return null;

  const dispatch = useAppDispatch();
  const [form] = useForm();
  const formModel = useAppSelector(formModelSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const [firstSubmit, setFirstSubmit] = useState(true);
  const [type, setType] = useState(document?.Type || DocumentType.Contract);
  const [defaultFormValues, setDefaultFormValues] = useState<{ [key: string]: string | number }>({});

  useEffect(() => {
    dispatch(getFormFieldsRoutine(type));
  }, []);

  useEffect(() => {
    if (firstSubmit) {
      setFirstSubmit(false);
    } else {
      form.submit();
    }
  }, [submit]);

  // Чтобы сохранить дефолтные значения полей в store, для дальнейшего сравнения с измененными
  // в случае одинаковых значений - не отправлять запрос
  useEffect(() => {
    let oldValues: any = form.getFieldsValue();

    const dateWithTime = transformDateIntoDateWithTime(oldValues?.['Created']?.toDate());
    oldValues = {
      ...oldValues,
      Created: dateWithTime
    };

    setDefaultFormValues(oldValues);
  }, [document, isFetching]);

  const onFormSubmit = (values: object) => {
    let payload = { ...values };
    if (payload['Created']) {
      const dateWithTime = transformDateIntoDateWithTime(payload['Created'].toDate());

      payload = {
        ...payload,
        Created: dateWithTime
      };
    }

    const preparedPayload = prepareDocumentsPayload(payload);
    const preparedOldValues = prepareDocumentsPayload(defaultFormValues);

    updateDocument(preparedPayload, preparedOldValues, type);
  };

  return (
    <ILSForm form={form} layout="vertical" onFinish={onFormSubmit}>
      <ILSSkeleton loading={isFetching} active>
        <ILSFormItem label="Тип документа">
          <ILSSelect options={shortDocumentTypeOptions} disabled={disabled} value={type} onChange={(e) => setType(e)} />
        </ILSFormItem>
        {formModel?.map((field) =>
          field.filtering !== FieldFilteringType.None ? (
            <ILSFormItem
              label={field.name}
              name={field.alias}
              key={field.alias}
              initialValue={
                field.type === FormFieldType.Date
                  ? moment.unix(type === document?.Type ? document[field.alias] : field.default)
                  : type === document?.Type
                  ? document[field.alias]
                  : field.default
              }
            >
              <FilterInput field={field} disabled={disabled} />
            </ILSFormItem>
          ) : null
        )}
      </ILSSkeleton>
    </ILSForm>
  );
};

export default ILSUpdateDocumentForm;
