import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { RouteConfig } from 'react-router-config';
import { FakePreCostTabContainer } from '@modules/orders-and-trips/containers/full/fake-pre-cost/fake-pre-cost-tab-container';
import { ILSDemo } from '@modules/demo';

interface IProps<Params extends { [K in keyof Params]?: string } = {}> extends RouteComponentProps<Params> {
  route?: RouteConfig | undefined;
}

export const DemoOrdersAndTrips: FC<IProps> = () => {
  return (
    <ILSDemo className="orders-and-trips">
      <FakePreCostTabContainer />
    </ILSDemo>
  );
};
