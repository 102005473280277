import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify } from '@common/types';

import { defaultStatuses, ParamsAction } from '@modules/planning/children/params/constants';
import { paramsRoutinesWithErrorNotifier } from '@modules/planning/children/params/actions';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';

/**Метод получения текста уведомления для разных Action*/
const getDataNotificationMessage = (action: ParamsAction, type: Notify.Loading | Notify.Error | Notify.Success): string => {
  return defaultStatuses[action][type] || '';
};

const getActionFromRoutine = (actionName: string): ParamsAction => {
  return actionName as ParamsAction;
};

let errorWorker = notifierWorkerCreator<ErrorAPI, ParamsAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'planning_params',
  getNotificationMessage: getDataNotificationMessage,
  getErrorMessage
});

export function* planningParamsNotifierWatcher() {
  for (let routine of paramsRoutinesWithErrorNotifier) {
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
