import { OrderActionRecord, RouteRecord, RoutesTableRecord, TripRecord, WaypointRecord } from '@modules/planning/children/by-date/types';
import { compareAsString } from '@common/utils';
import { Dictionary, OrderAction, PlanStatus, Trip, Vehicle, Waypoint } from '@common/types';
import { PLANNING_RESULT_ERRORS } from '@modules/planning/children/by-date/constants';
import { ILSUserNotify } from '@common/components';
import { throwError } from '@common/utils/validators/check-data-for-error';
import { MessageContent } from '@common/constants';

export const handleDeleteRouteTablePayload = (record: RoutesTableRecord) => {
  if (!compareAsString(record.Status, PlanStatus.Open)) {
    const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.TRIP_IS_LOCK;
    ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
    return;
  }

  const payload: {
    dictionary: Dictionary;
    vehicleID?: Vehicle['ID'];
    tripIDs?: Array<Trip['ID']>;
    waypointIDs?: Array<Waypoint['ID']>;
    orderActionID?: Array<OrderAction['ID']>;
  } = {
    dictionary: record.dictionary
  };

  switch (record.dictionary) {
    case Dictionary.Route:
      payload.vehicleID = (record as RouteRecord).Vehicle.ID;
      break;
    case Dictionary.Trip:
      if ((record as TripRecord).ID) {
        payload.vehicleID = (record as TripRecord).VehicleID;
        payload.tripIDs = [record.ID];
      } else {
        throwError({ message: MessageContent.SomethingWentWrong });
      }
      break;
    case Dictionary.Waypoint:
      payload.vehicleID = (record as WaypointRecord).VehicleID;
      payload.tripIDs = [(record as WaypointRecord).TripID];
      payload.waypointIDs = [(record as WaypointRecord).ID];
      break;
    case Dictionary.OrderAction:
      payload.vehicleID = (record as OrderActionRecord).VehicleID;
      payload.tripIDs = [(record as OrderActionRecord).TripID];
      payload.waypointIDs = [(record as OrderActionRecord).WaypointID];
      payload.orderActionID = record.ID;
      break;
    default:
      break;
  }

  return payload;
};
