import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { tenderWithMyParticipationRoutine } from '@modules/tender-platform/children/tender-search/actions';

function* tenderWithMyParticipationWorker() {
  const { request, success, failure, fulfill } = tenderWithMyParticipationRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderSearch.tenderWithMyParticipation);

    yield put(success({ tenders: Object.values(data) }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderWithMyParticipationWatcher() {
  yield takeLatest(tenderWithMyParticipationRoutine.trigger, tenderWithMyParticipationWorker);
}
