import { DictionariesEndpoint, User } from '@common/types';
import { userRoutine } from '@modules/account/actions';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { Dispatch } from 'redux';

export const useUserBlocking = ({ dispatch, Blocked, userID }: { dispatch: Dispatch; Blocked: User['Blocked']; userID: User['ID'] }) => {
  const isBlocked = Blocked;
  const blockedTitle = isBlocked ? 'Разблокировать пользователя' : 'Заблокировать пользователя';

  const handleBlock = (id: User['ID'], Blocked: boolean) =>
    dispatch(userRoutine({ id, values: { Blocked }, operation: DictionariesEndpoint.Update }));
  const handleBlockUser = () => {
    const message = `${blockedTitle}?`;
    const confirmBlockUser = () => handleBlock?.(userID, !isBlocked);
    ILSUserNotifyConfirm(message, undefined, confirmBlockUser);
  };

  return { handleBlockUser, blockedTitle, blocked: Blocked };
};
