import { FC } from 'react';
import { TripInfo } from '../../types/store';

interface Props {
  tripInfo: TripInfo;
}

// Компонент для отображения одного рейса в заявках
export const DemandTenderLotTripComponent: FC<Props> = ({ tripInfo }) => {
  const { ID, vechicleName, vechicleRegNumber, vechicleDriverName, vechicleDriverPhone, tripBegin, tripEnd } = tripInfo;

  return (
    <div className="ils-demand ils-demand-tender-lot-trip">
      <div className="ils-demand-order-lot-trip-wrapper">
        <span className="ils-demand-order-lot-trip-background">Рейс</span>
        <span className="ils-demand-order-lot-trip-id">ID: "{ID}"</span>
        <span className="ils-demand-order-lot-trip-id">Дата и время начала рейса: "{tripBegin}"</span>
        <span className="ils-demand-order-lot-trip-id">Дата и время завершения рейса: "{tripEnd}"</span>
      </div>
      <div className="ils-demand-project-trip-info">
        <span className="ils-demand-trip-info-name">TC</span>
        <span className="ils-demand-trip-info-vechicle">{vechicleName}</span>
        <span className="ils-demand-trip-info-vechicle-number">{vechicleRegNumber}</span>
      </div>
      <div className="ils-demand-project-trip-info">
        <span className="ils-demand-trip-info-name">Водитель</span>
        <span className="ils-demand-trip-info-driver-name">{vechicleDriverName}</span>
        <span className="ils-demand-trip-info-driver-phone">{vechicleDriverPhone}</span>
      </div>
    </div>
  );
};
