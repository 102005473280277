import { memo } from 'react';
import { isEmpty } from 'lodash';
import { ILSUserNotify, ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { ILSButton, ILSUpload } from '@components/index';
import { ILSGetCoordinatesActionButton } from './action-buttons/get-coordinates';
import ILSShowDeletedActionButton from './action-buttons/show-deleted';
import { FilterSort, IILSTableComponentProps, ITablePropsFunction, Key, Keys, ShowDeleted, TableRecord } from '@common/types';
import ILSCopyRowActionButton from './action-buttons/copy-row';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { ILSTypographyTitle } from '@components/general/typography';
import { ILSClearSortButton } from '@components/data-display/table/components/action-buttons/clear-sort';
import { ILSClearFilterButton } from '@components/data-display/table/components/action-buttons/clear-filter';
import { getNumberSelectedRow } from '@common/utils/helpers/string/number-selected-row';
import { TableAddingButton } from '@components/data-display/table/components/action-buttons/adding/button';
import { ILSBindTimeZoneActionButton } from '@components/data-display/table/components/action-buttons/bind-time-zone';
import { getRecordsToDelete, getRowsIDsToRestore, onClickDeleteAndNotify } from '@components/data-display/table/utils/helpers';
import { exportDataRows } from '@components/general/export-excel/data';
import { ILSNotificationSettingsButton } from '@components/data-display/table/components/action-buttons/notification-settings';
import { ILSSearchFilter } from '@modules/tender-platform/children/tender-search/components/search-filters';
import { SetSelectedRowKeys, SetShowReference } from '@common/types/components';
import { ButtonExportExcel } from '@components/general/buttons/components/button-export-excel';

export interface IILSTableHeaderProps<R extends TableRecord>
  extends Pick<
      IILSTableComponentProps<R>,
      | 'config'
      | 'showDeleted'
      | 'recordTableButtons'
      | 'tableTitle'
      | 'tableWidgets'
      | 'actionTableButtons'
      | 'settingsTableButtons'
      | 'uploadConfiguration'
      | 'handleOpenExportModal'
      | 'dataSource'
      | 'dictionary'
      | 'dictionaryLoad'
      | 'notificationEnabled'
      | 'isModal'
      | 'closeTableButton'
      | 'customClearFilters'
      | 'hasFilters'
      | 'handleReload'
      | 'handleDelete'
      | 'handleUpload'
      | 'handleCheckDelete'
      | 'handleRestore'
      | 'handleCheckCoords'
      | 'handleBindTimeZone'
      | 'handleDownload'
      | 'refData'
      | 'refTable'
      | 'setShowDeleted'
    >,
    Partial<
      Omit<
        ITablePropsFunction<R>,
        | 'dictionaryLoad'
        | 'handleOpen'
        | 'handleUpdate'
        | 'onCanEdit'
        | 'onContextOpen'
        | 'onResizeStart'
        | 'onTableRowClick'
        | 'onTableRowDoubleClick'
        | 'handleSelectedElements'
      >
    > {
  loading: boolean;
  focusedRow: Key;
  filterSortInfo: FilterSort;
  selectedRowKeys: Keys | [];
  setShowReference: SetShowReference<R>;
  setSelectedRowKeys?: SetSelectedRowKeys;
  clearFilters(): void;
  clearSorting(): void;
  clearSelectedRowKeys(): void;
  handleCreateSelect: ITablePropsFunction<R>['handleCreate'];
}

export const ILSTableHeader = memo(
  <R extends TableRecord>({
    refData,
    loading = false,
    dataSource,
    tableTitle,
    recordTableButtons = [],
    tableWidgets = [],
    actionTableButtons = [],
    settingsTableButtons = [],
    closeTableButton: CloseButtonComponent,
    handleReload,
    handleDelete,
    dictionaryLoad,
    refTable,
    setShowReference,
    handleSave,
    handleUpload,
    handleCheckDelete,
    handleRestore,
    selectedRowKeys,
    config,
    clearFilters,
    clearSorting,
    clearSelectedRowKeys,
    setSelectedRowKeys,
    showDeleted = ShowDeleted.No,
    handleCheckCoords,
    handleBindTimeZone,
    dictionary,
    isModal,
    filterSortInfo: { sorted, filtered },
    onCopy,
    focusedRow,
    customClearFilters,
    notificationEnabled,
    handleDownload,
    uploadConfiguration,
    hasFilters,
    handleOpenExportModal,
    handleCreateSelect,
    setShowDeleted
  }: IILSTableHeaderProps<R>): JSX.Element => {
    const clearFilterDisabled = !customClearFilters?.filtered && (!filtered || !Object.values(filtered).filter(Boolean).length);
    const clearSortDisabled = !sorted || (!Array.isArray(sorted) && !sorted.column);
    const numberSelectedDownload = getNumberSelectedRow('Скачать', selectedRowKeys);
    // TODO: После удаления остается пустой пробел после строки Удалить
    const numberSelectedDelete = getNumberSelectedRow('Удалить', selectedRowKeys);

    const handleConfirmRestore = (selectedRowKeys: Array<Key>) => {
      // функция удаления по клику в шапке таблицы, в функцию попадают все выделенные элементы;
      const rows = getRowsIDsToRestore(selectedRowKeys, dataSource);
      handleRestore?.(rows);
      clearSelectedRowKeys?.();
    };

    //Вызывает окно с модальным окном, на кнопку "Да" будет вызываться onConfirm
    const onClickHandler = (confirmText: Parameters<typeof ILSUserNotify>[7], onConfirm: () => void) => {
      ILSUserNotifyConfirm(confirmText, undefined, onConfirm);
    };

    const onClickDelete = () => {
      const removeRecords = getRecordsToDelete(selectedRowKeys, dataSource);

      onClickDeleteAndNotify(
        'Удалить выбранные строки из таблицы?',
        selectedRowKeys,
        removeRecords,
        undefined,
        handleDelete,
        handleCheckDelete,
        setSelectedRowKeys,
        undefined,
        selectedRowKeys
      );
    };

    const onClickDownload = () => handleDownload?.(selectedRowKeys);
    const onReload = () => handleReload?.(selectedRowKeys, showDeleted);
    const onRestore = () => {
      onClickHandler('Восстановить выбранные строки в таблицу?', () => handleConfirmRestore(selectedRowKeys));
    };

    return (
      <div className="table-header actions">
        <div className="ils-scroll-header">
          {hasFilters && <ILSSearchFilter />}
          <ul className="ils-scroll-header-buttons">
            {Boolean(tableTitle) && <ILSTypographyTitle ellipsis level={4} className="table-header-title" children={tableTitle} />}
            {Boolean(tableWidgets.length) &&
              tableWidgets.map(
                ({ Component, props }, index) =>
                  Component && <Component disabled={loading} isLoading={loading} key={`tableWidgets-${index}-${dictionary}`} {...props} />
              )}
            {Boolean(actionTableButtons.length) &&
              actionTableButtons.map(
                ({ Component, props }, index) =>
                  Component && (
                    <Component disabled={loading} key={`actionTableButtons-${index}-${dictionary}`} isLoading={loading} {...props} />
                  )
              )}
            {Boolean(recordTableButtons.length) &&
              recordTableButtons.map(
                ({ Component, props }, index) =>
                  Component && (
                    <Component disabled={loading} isLoading={loading} key={`recordTableButtons-${index}-${dictionary}`} {...props} />
                  )
              )}
            {Boolean(config?.adding) && (
              <>
                <TableAddingButton
                  refData={refData}
                  columns={config.columns}
                  adding={config.adding}
                  headerButtonsParams={config.headerButtonsParams}
                  handleCreate={handleCreateSelect}
                  dictionaryLoad={dictionaryLoad}
                  isFetching={loading}
                  dictionary={dictionary}
                  refTable={refTable}
                  handleSave={handleSave}
                  setShowReference={setShowReference}
                />
                {Boolean(config?.copy) && (
                  <ILSCopyRowActionButton
                    focusedRow={focusedRow}
                    onCopy={onCopy}
                    selectedRows={selectedRowKeys}
                    dictionary={dictionary}
                    isIcon={false}
                  />
                )}
              </>
            )}
            {Boolean(config?.reloading && handleReload) && (
              <ILSButton loading={loading} disabled={loading} className="reload" onClick={onReload} children={'Обновить'} />
            )}
            {Boolean(config?.deleting && config?.selecting && !config?.widget && handleDelete && !showDeleted) && (
              <ILSButton
                loading={loading}
                onClick={onClickDelete}
                danger
                className="delete"
                disabled={!(selectedRowKeys && selectedRowKeys.length > 0)}
                children={`${numberSelectedDelete}`}
              />
            )}
            {Boolean(config?.uploading && uploadConfiguration && handleUpload) && (
              <ILSUpload
                accept={uploadConfiguration?.defaultPermittedTypes?.join(JoinChar.Comma) ?? EMPTY_STRING}
                defaultPermittedTypes={uploadConfiguration?.defaultPermittedTypes}
                defaultFormats={uploadConfiguration?.defaultFormats}
                fileMaxSize={uploadConfiguration?.fileMaxSize}
                showUploadList={false}
                onChange={handleUpload}
                listType={'text'}
                children={<ILSButton>{uploadConfiguration?.buttonText ?? 'Загрузить'}</ILSButton>}
              />
            )}
            {Boolean(config?.selecting && handleDownload) && (
              <ILSButton
                onClick={onClickDownload}
                className="downloadAllChecked"
                children={numberSelectedDownload}
                disabled={!(selectedRowKeys && selectedRowKeys.length > 0)}
              />
            )}
            {Boolean(config?.showDeleted && config?.selecting && handleRestore && showDeleted) && (
              <ILSButton
                onClick={onRestore}
                type="primary"
                ghost
                className="showDeleted"
                disabled={!Boolean(selectedRowKeys.length)}
                children={'Восстановить'}
              />
            )}
            {Boolean(config?.copying) && (
              <ILSCopyRowActionButton isIcon onCopy={onCopy} selectedRows={selectedRowKeys} dictionary={dictionary} />
            )}
            {Boolean(config?.showDeleted) && handleReload && (
              <ILSShowDeletedActionButton
                isFetching={loading}
                showDeleted={showDeleted}
                setShowDeleted={setShowDeleted}
                handleReload={handleReload}
                selectedRowKeys={selectedRowKeys}
              />
            )}
            {Boolean(config?.getCoordinates) && handleCheckCoords && (
              <ILSGetCoordinatesActionButton onClick={handleCheckCoords} disabled={isEmpty(dataSource)} selectedRowKeys={selectedRowKeys} />
            )}
            {Boolean(config?.export) && dictionary && handleOpenExportModal && (
              <ButtonExportExcel disabled={!exportDataRows[dictionary]} onClick={() => handleOpenExportModal(dictionary)} />
            )}
            {handleBindTimeZone && <ILSBindTimeZoneActionButton selectedRows={selectedRowKeys} onClick={handleBindTimeZone} />}
            {Boolean(notificationEnabled) && <ILSNotificationSettingsButton isFetching={loading} />}
            {Boolean(!config?.hideClearButtons) && (
              <>
                <ILSClearSortButton isFetching={loading} clearSorting={clearSorting} clearSortDisabled={clearSortDisabled} />
                <ILSClearFilterButton isFetching={loading} clearFilters={clearFilters} clearFilterDisabled={clearFilterDisabled} />
              </>
            )}
            {Boolean(settingsTableButtons.length) &&
              settingsTableButtons.map(
                ({ Component, props }, index) =>
                  Component && (
                    <Component isLoading={loading} disabled={loading} key={`settings-table-buttons-button-${index}`} {...props} />
                  )
              )}
            {Boolean(CloseButtonComponent && !isModal) && [CloseButtonComponent]}
          </ul>
        </div>
      </div>
    );
  }
);
