import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { tableConfig } from '@common/models/depot-overload/config/table';
import { depotOverloads2table } from '@common/models/depot-overload/decorators';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogDepotOverload: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return (
      <ILSCatalogTable {...props} dictionary={Dictionary.DepotOverload} dataDecorator={depotOverloads2table} handleDecorator={table2data} />
    );
  },
  tableConfig,
  CatalogDictionary.DepotOverload,
  true
);
