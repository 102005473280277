import { dateToString } from '@modules/documents/helpers/date-to-string';
import { DateFormat } from '@common/types';
import { MenuProps } from 'antd';
import { InboxSendDocument } from '@modules/documents/types/document-state';

export const decorateInboxData = (data: InboxSendDocument[], optionButtons?: (document: InboxSendDocument) => MenuProps['items']) => {
  return data?.map((doc, key) => ({
    ...doc,
    key,
    Button: {
      icon: '/assets/icons/options.svg',
      items: optionButtons ? optionButtons(doc) : []
    },
    Created: dateToString(doc.Created, DateFormat.HHmmDDMMYYYYPeriod)
  }));
};
