import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';
import { Dictionary, ErrorAPI } from '@common/types';
import { getTariffRoutine } from '@modules/catalog/actions';
import initialState from '@modules/catalog/store';

export const getTariffsReducer = createReducer(initialState, {
  [getTariffRoutine.SUCCESS]: (state, action) => {
    const { data, dictionary } = action.payload;
    state[dictionary].parameters = { ...state[dictionary].parameters, ...data };
    return state;
  },
  [getTariffRoutine.FAILURE]: (
    state,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [getTariffRoutine.FULFILL]: onFulfill,
  [getTariffRoutine.REQUEST]: onRequest
});
