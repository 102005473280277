import { Button, notification } from 'antd';
import { Notify } from '@common/types';
import { resetAllStorageData } from '@core/helpers/reset-all-storage-data';
import { productVersionConfirmData } from '@common/types/general/product-version';

interface IProps {
  store: any;
}

export const changeProductVersion = ({ store }: IProps) => {
  const key = 'changeProductVersion';

  const onClick = () => {
    resetAllStorageData({ store });
  };

  const onCancel = () => {
    notification.close(key);
  };

  const btn = (
    <div className="w-100 flex-between-center">
      <Button type="primary" size="small" onClick={onClick} children={productVersionConfirmData.execute} />
    </div>
  );

  notification.warn({
    message: productVersionConfirmData.title,
    description: productVersionConfirmData.description,
    duration: 0,
    onClose: onCancel,
    className: Notify.Confirm,
    placement: 'topLeft',
    btn,
    key
  });
};

