import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getVehicleTypesRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '../types/general';
import { VehicleType } from '../types/vehicle';

export const getVehicleTypesReducer = createReducer(initialState, {
  [getVehicleTypesRoutine.REQUEST]: (state: InitialState) => {
    state.extraData.isFetching = true;
    return state;
  },
  [getVehicleTypesRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: VehicleType;
    }>
  ) => {
    const { data } = action.payload;
    state.extraData.vehicleType = data;
    return state;
  },
  [getVehicleTypesRoutine.FAILURE]: onFailure,
  [getVehicleTypesRoutine.FULFILL]: (state: InitialState) => {
    state.extraData.isFetching = false;
    state.error = [];
    return state;
  }
});

