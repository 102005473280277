import { CellType, TableConfig } from '@common/types';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: '№',
      dataIndex: 'ID',
      key: 'ID',
      active: true,
      type: CellType.Int,
      ellipsis: true,
      width: 60,

      sorting: 3,

      invisible: true
    },
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Время',
      dataIndex: 'DT',
      key: 'DT',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'Рег.Номер',
      dataIndex: 'Number',
      key: 'Number',
      active: true,
      type: CellType.RegNumber,
      ellipsis: true,
      filtering: 'array',
      width: 70,

      sorting: 3
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: false,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3,

      invisible: true
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: false,
      type: CellType.Phone,
      ellipsis: true,
      width: 80,

      sorting: 3,

      invisible: true
    },
    {
      title: 'Вид нарушения',
      dataIndex: 'ViolationType',
      key: 'ViolationType',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Комментарий водителя',
      dataIndex: 'Comment',
      key: 'Comment',
      active: false,
      ellipsis: true,
      type: CellType.Input,
      filtering: 'array',
      width: 100,

      sorting: 3,

      invisible: true
    },
    {
      titleText: 'Кнопка - Посмотреть на карте',
      titleTooltip: 'Посмотреть на карте',
      titleIcon: 'location',
      dataIndex: 'FocusOnMap',
      key: 'FocusOnMap',
      type: CellType.IconAction,
      ellipsis: true
    },
    {
      title: 'Просмотрено',
      titleText: 'Просмотрено',
      titleTooltip: 'Просмотрено',
      dataIndex: 'Viewed',
      key: 'Viewed',
      active: true,
      type: CellType.IconAction, //to new => checkboxIcon
      ellipsis: true,

      sorting: 3
    }
    //убрали до реализации функционала
    // {
    //   title: 'Избранное',
    //   dataIndex: 'Favourite',
    //   key: 'Favourite',
    //   active: true,
    //   type: CellType.Select, //to new => checkboxIcon
    //   ellipsis: true,
    //   options: [
    //     { label: ' ', value: 0 },
    //     { label: 'Избранное', value: 1 }
    //   ],
    //   width: 70,
    //   editable: false,
    //
    //   sorting: 3,
    //
    //   filtering: 'array'
    // }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: false,
  copying: false
};
