import { FC, useState } from 'react';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { ILSSelect } from '@common/components';

export const SchemeTypeSelect: FC<IILSSelectProps> = (props) => {
  const [open, setOpen] = useState<boolean | undefined>(false);

  const selectPreventDragging = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const onDropdownVisibleChange = (open: boolean) => {
    props.onDropdownVisibleChange && props.onDropdownVisibleChange(open);
    setOpen(undefined);
  };

  return (
    <span onPointerDown={selectPreventDragging} onBlur={() => setOpen(undefined)}>
      <ILSSelect {...props} open={open} onDropdownVisibleChange={onDropdownVisibleChange} />
    </span>
  );
};
