import { DataRangeOptionType } from '@modules/analytics/common/config/date-select';

export const DEFAULT_BOARD_TITLE = 'Новая доска';
export const SUMMARY_INFO = 'Суммарная информация';
export const BOARD_NAME_PLACEHOLDER = '...';

export enum AnalyticsTenderQueryParams {
  BOARD_ID = 'boardId',
  BOARD_SET_ID = 'boardSetId'
}

export const DEFAULT_PERIOD = DataRangeOptionType.ThisWeek;
