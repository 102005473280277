import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { changeVehicleRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { RouteTotal } from '@common/types/dictionaries/route';
import { IndexedArray, Vehicle } from '@common/types';

export const changeVehicleReducer = createReducer(initialState, {
  [changeVehicleRoutine.REQUEST]: onRequest,
  [changeVehicleRoutine.FAILURE]: onFailure,
  [changeVehicleRoutine.FULFILL]: onFulfill,
  [changeVehicleRoutine.SUCCESS]: (state, action: PayloadAction<{ routeTotal: IndexedArray<RouteTotal>; oldVehicleID: Vehicle['ID'] }>) => {
    const { routeTotal, oldVehicleID } = action.payload;
    for (const vehicleID in routeTotal) {
      state.currentPlan.RouteTotal[vehicleID] = { ...routeTotal[vehicleID], changed: true };
    }
    delete state.currentPlan.RouteTotal[oldVehicleID];
    return state;
  }
});
