import { ILSButton } from '@common/components';
import { AnalyticsBoardModel, AnalyticsWidget } from '@common/types/dictionaries/analytics';
import css from 'classnames';
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import ILSWidgetBoard from '@modules/analytics/common/components/board/widget-board';
import { DateFormat, IndexedArray } from '@common/types';
import { prepareDateToServer } from '@common/utils';

interface Props {
  board: AnalyticsBoardModel | null;
  boardTitle?: string;
  forPDF?: boolean;
  from?: string | number;
  till?: string | number;
  widgets?: IndexedArray<AnalyticsWidget> | null;
  widgetParams?: IndexedArray<{ name: string; value: string | number | undefined }>;
  handleEditBoard?: (item?: AnalyticsBoardModel) => void;
  handleSetParams?: (params: IndexedArray<{ name: string; value: string | number | undefined }>) => void;
  handleGetWidgetData: (params: { id: string | number; params: any }) => void;
  savingWidgets?: boolean;
  setBoardWidgets?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

/**
 * Компонент выбранной доски
 */
export const BoardViewComponent: FC<Props> = ({
  widgets,
  boardTitle,
  widgetParams,
  board,
  handleGetWidgetData,
  handleEditBoard = () => {},
  forPDF,
  from,
  till,
  handleSetParams,
  setBoardWidgets
}) => {
  const onEditClick = (event: React.SyntheticEvent) => {
    board && handleEditBoard(board);
  };
  return (
    <div className="analytics-planning-board-view  bg-white">
      {board && (
        <div className="mr-3 ml-3">
          <div className={css('flex-between-center', 'analytics-board-header')}>
            <div className="analytics-board-title">
              {boardTitle || board.Name}{' '}
              {forPDF &&
                from &&
                till &&
                ` (${prepareDateToServer(from, DateFormat.DDMMYYYYPeriod)} - ${prepareDateToServer(till, DateFormat.DDMMYYYYPeriod)})`}
            </div>

            <div className="analytics-planning-board-button">
              {!forPDF && (
                <ILSButton
                  onClick={onEditClick}
                  className="grabber"
                  type="link"
                  icon={<ReactSVG className="svg-container" src={'/assets/icons/pencil.svg'} />}
                  data-html2canvas-ignore
                />
              )}
            </div>
          </div>
          <ILSWidgetBoard
            forPDF={forPDF}
            from={from}
            setBoardWidgets={setBoardWidgets}
            till={till}
            handleSetParams={handleSetParams}
            widgets={widgets}
            widgetParams={widgetParams}
            handleGetWidgetData={handleGetWidgetData}
          />
        </div>
      )}
    </div>
  );
};
