import { MonitoringBrokenSequenceDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';

export function transformTripToWaypointsInfo(
  data: MonitoringBrokenSequenceDataModel,
  deliveredStatuses: MonitoringWaypointEventStatusType[]
) {
  return Object.values(data?.Trip || {}).reduce(
    (prev, curr) => {
      let fact = 0;

      Object.values(curr.Waypoint || {}).forEach((item) => {
        if (item && deliveredStatuses.includes(item?.EventStatusType)) {
          fact += 1;
        }

        prev.running += item?.DistanceFromPrevPoint ?? 0;
      });

      prev = {
        ...prev,
        all: Object.values(curr.Waypoint || {}).length,
        fact: fact
      };

      return prev;
    },
    { fact: 0, all: 0, running: 0 }
  );
}
