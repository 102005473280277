import { createArrayFromIndexedArray } from '@common/utils';
import { EMPTY_STRING } from '@common/constants';
import { DriverFromSubMenu } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/route/use-driver-actions';
import { DriverModel, IndexedArray, TransportCompany } from '@common/types';

export const getDriversByTransportCompany = ({
  drivers,
  transportCompanyID
}: {
  drivers: IndexedArray<DriverModel> | Array<DriverModel>;
  transportCompanyID: TransportCompany['ID'] | null | undefined;
}) => {
  return createArrayFromIndexedArray(drivers).reduce((drivers: Array<DriverFromSubMenu>, driver) => {
    if ((driver.TransportCompanyID ?? EMPTY_STRING) === (transportCompanyID ?? EMPTY_STRING)) {
      drivers.push({ driverID: driver.ID, name: driver.Name });
    }
    return drivers;
  }, []);
};
