import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { updateBoardSetRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardModel, AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';

export const updateBoardSetsReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [updateBoardSetRoutine.REQUEST]: (state, action: PayloadAction<AnalyticsBoardModel>) => {
    state.BoardSets.deleting = true;
    state.BoardSets.updatingSet = action?.payload?.ID;
    return state;
  },
  [updateBoardSetRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsBoardSetModel>;
    }>
  ) => {
    const { data } = action.payload;

    if (state.BoardSets?.data && Object.values(data).length) {
      state.BoardSets.data = Object.values(state.BoardSets?.data).reduce((prev, cur) => {
        if (data[cur.ID]) {
          prev[cur.ID] = data[cur.ID];
        } else {
          prev[cur.ID] = cur;
        }

        return prev;
      }, {});
    }

    return state;
  },
  [updateBoardSetRoutine.FAILURE]: (state) => {
    return state;
  },
  [updateBoardSetRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.BoardSets.deleting = true;
    state.BoardSets.updatingSet = undefined;
    return state;
  }
});
