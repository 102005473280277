import { Dictionary, Keys, TableName, TableRecord } from '@common/types';
import { getCloseButtonComponent } from '@components/data-display/table/utils/helpers/get-close-button-component';
import { addOrderForVehicleRoutine } from '@modules/planning/children/by-date/actions';
import { ILSPlanningOrderTableContainer } from '@modules/planning/children/by-date/containers/result/tables/order-table';
import { PlannerPlanningVehicleTableContainer } from '@modules/planning/children/by-date/containers/result/tables/vehicle-table';
import { AddOrderForVehicle } from '@modules/planning/children/by-date/sagas/route/add-order-for-vehicle';
import { plannerMapTablesContainerSelector } from '@modules/planning/children/by-date/selectors/components/tables/map-tables';
import { selectedRouteTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import classnames from 'classnames';
import { isNumber } from 'lodash';
import { FC } from 'react';
import { IDragDropContainer } from 'react-drag-drop-container';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedOrdersLasso } from '@modules/planning/children/by-date/types/map-container';
import { planningFocusNExpandRowIdsRoutesTableSelector } from '@modules/planning/children/by-date/selectors/planning';

interface IProps {
  markerDragging: boolean;
  className?: string;
  showVehicleTable: boolean;
  showOrderTable: boolean;
  selectedOrdersLasso: SelectedOrdersLasso;

  handleShowVehicleTable(val: boolean): void;

  handleShowOrderTable(val: boolean): void;

  resetSummaryData(arg: { resetAll?: boolean; withCloseTable?: boolean }): void;

  onItemDrop(e: IDragDropContainer, record: any): void;

  onSelectRows(selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined): void;
}

export const PlannerMapTablesContainer: FC<IProps> = ({
  showVehicleTable,
  showOrderTable,
  markerDragging,
  onItemDrop,
  handleShowVehicleTable,
  className,
  selectedOrdersLasso,
  handleShowOrderTable,
  resetSummaryData,
  onSelectRows
}) => {
  const dispatch = useDispatch();

  const { dataSource, orderSummary, orderStatus, selectedOrders } = useSelector(
    plannerMapTablesContainerSelector({
      selectedOrdersLasso: selectedOrdersLasso
    })
  );

  const showAddOrder = Boolean(!selectedOrdersLasso?.orderIDs?.length);

  const { selectedTripsIDs, selectedRoutesIDs } = useSelector(selectedRouteTableRowsSelector);
  const focusRowVehicle = useSelector(planningFocusNExpandRowIdsRoutesTableSelector(TableName.PlanningVehicle));

  /** закидывание заявок в рейс с помощью кнопок */
  const addOrderForTripCallback = (existTrip: boolean, tripIDForOrder?: number) => {
    const vehicleID = existTrip ? Number(selectedRoutesIDs[0]) : Number(focusRowVehicle.focusRow.key);
    const tripID = existTrip ? Number(tripIDForOrder ?? selectedTripsIDs[0]) : undefined;
    /** убираем DepotCode (они строковые) в случае группировки по клиенту */
    const selectedOrdersIDs: AddOrderForVehicle['orderID'] = selectedOrders.filter(isNumber);
    const payload: AddOrderForVehicle = {
      vehicleID,
      orderID: selectedOrdersIDs,
      tripID
    };
    dispatch(addOrderForVehicleRoutine(payload));
    resetSummaryData({ resetAll: false, withCloseTable: true });
    // TODO отключено по TMS-9707, изменить логику в 9799
    // handleShowVehicleTable(false);
    // handleShowOrderTable(false);
  };

  const handleCloseTable = (dictionary: Dictionary) => {
    if (dictionary === Dictionary.Vehicle) {
      handleShowVehicleTable?.(false);
    }
    if (dictionary === Dictionary.Order) {
      resetSummaryData({ resetAll: true });
      handleShowVehicleTable?.(false);
      handleShowOrderTable?.(false);
    }
  };

  return (
    <section className={classnames('ils-map-table', { 'full-screen-table-map': showVehicleTable })}>
      {showVehicleTable && (
        <PlannerPlanningVehicleTableContainer
          dragInfo={{
            key: 'trip-target',
            handler: onItemDrop,
            active: markerDragging
          }}
          orderSummary={orderSummary}
          addOrderForVehicle={addOrderForTripCallback}
          closeTableButton={getCloseButtonComponent(Dictionary.Vehicle, handleCloseTable)}
        />
      )}
      {showOrderTable && (
        <ILSPlanningOrderTableContainer
          dataSource={dataSource}
          showAddOrder={showAddOrder}
          selectedOrdersIDs={selectedOrders}
          orderSummary={orderSummary}
          handleShowVehicleTable={handleShowVehicleTable}
          orderStatus={orderStatus}
          onItemDrop={onItemDrop}
          addOrderForVehicle={addOrderForTripCallback}
          className={className}
          showVehicleTable={showVehicleTable}
          closeTableButton={getCloseButtonComponent(Dictionary.Order, handleCloseTable)}
          onSelectRows={onSelectRows}
        />
      )}
    </section>
  );
};

