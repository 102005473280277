import { Notify, Plan, PlanID } from '@common/types';
import { planningCancelRoutine, planningStartRoutine, planningValidateRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PlansTableRecord } from '@modules/planning/children/by-date/types/record';
import { ILSUserConfirm, ILSUserNotify } from '@common/components';
import { CANCEL_PLANNING_TEXT } from '@modules/planning/children/by-date/constants';
import { planningSettingsSelector } from '@modules/settings/selectors';
import { activePlanErrorsSelector, validationDataSelector } from '@modules/planning/children/by-date/selectors/plan';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { isEmpty } from 'lodash';
import { InterruptOnValidationErrorLevel } from '@common/constants';

//todo all func must be callbacks
export const usePlanning = () => {
  const dispatch = useDispatch();
  const settings = useSelector(planningSettingsSelector);
  const validationData = useSelector(validationDataSelector);
  const activePlanErrors = useSelector(activePlanErrorsSelector);
  const activePlanID = useSelector(activePlanIDSelector);

  /** Старт валидации */
  const startValidation = (update?: boolean, planID?: PlanID) => {
    dispatch(planningValidateRoutine({ update, planID }));
  };

  /** Старт планирования после валидации с проверками ошибок */
  const startPlanningWithErrorsCheck = (planID?: PlanID) => {
    /** если есть activePlanErrors, проверяем перед планированием */
    if (!isEmpty(activePlanErrors) && planID && validationData[planID]) {
      switch (settings.parameters.interruptOnValidationErrorLevel) {
        case InterruptOnValidationErrorLevel.OnlyErrors:
          if (activePlanErrors?.error) {
            ILSUserNotify(
              Notify.Error,
              `Невозможно запустить планирование, необходимо исправить ошибки, кол-во: ${activePlanErrors.error}`
            );
          } else {
            startPlanning(planID);
          }
          break;
        case InterruptOnValidationErrorLevel.ErrorsAndWarnings:
          if (activePlanErrors?.error || activePlanErrors.warning) {
            ILSUserNotify(
              Notify.Error,
              `Невозможно запустить планирование, необходимо исправить ошибки и предупреждения, кол-во: ${
                activePlanErrors?.error + activePlanErrors.warning
              }`
            );
          } else {
            startPlanning(planID);
          }
          break;
        default:
          if (activePlanErrors?.bad) {
            ILSUserNotify(Notify.Error, `Невозможно запустить планирование, есть критические ошибки`);
          } else {
            startPlanning(planID);
          }
          break;
      }
    } else if (planID) {
      /** если нет activePlanErrors, запускаем планирование без проверки */
      startPlanning(planID);
    }
  };

  /** Старт планирования с включенной валидацией */
  const startPlanningWithValidation = (planID?: PlanID) => {
    if (isEmpty(activePlanErrors) || (planID && !validationData[planID])) {
      startValidation(false, planID);
      return;
    }
    startPlanningWithErrorsCheck(planID);
  };

  /** Старт планирования без запуска валидации */
  const startPlanning = (planID?: PlanID) => {
    dispatch(planningStartRoutine({ planID }));
  };

  /** Завершить планирование */
  const cancelPlanning = (planID: Plan['ID']) => {
    dispatch(planningCancelRoutine({ planID }));
  };

  const handlePlanning = (record: PlansTableRecord) => {
    const planID = record.ID;
    const isPlanning = record.Planning.icon !== 'right-arrow';
    if (isPlanning) {
      const onConfirm = () => cancelPlanning(planID);
      ILSUserConfirm({
        text: CANCEL_PLANNING_TEXT,
        onConfirm
      });
    } else {
      if (settings.parameters.validateOnPlanning) {
        startPlanningWithValidation(planID);
      } else {
        startPlanning(planID);
      }
    }
  };

  const Planning = { icon: 'right-arrow', onClick: handlePlanning };
  return { Planning, startValidation, startPlanning, startPlanningWithErrorsCheck, activePlanErrors };
};

