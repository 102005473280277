import { VALIDATION_TABLES, validationErrorTypes } from '../constants';
import { FC, ReactNode, useState } from 'react';
import { ILSValidateExtraProps, ILSValidateProps } from '@modules/data-validate/types/containers';
import { Dictionary, TActiveValidationTable, ValidationData } from '@common/types';
import { ILSValidatePlanningTableComponent } from '@modules/data-validate/components/table/planning-info-table';
import ILSValidateUploadTableComponent from './table/data-info-table';
import { ILSTypographyTitle } from '@components/general/typography';
import { EMPTY_STRING } from '@common/constants';
import { getFailData, getFailDataTableColumns } from '@modules/data-validate/utils';
import { commonErrorColumns } from '@common/table-configs/error/config';
import { ShowReference } from '@common/types/components/table/common';

export interface ILSDataInfoComponentProps extends ILSValidateProps, ILSValidateExtraProps {
  errorList: ValidationData[];
  dataSource: any;
  activeTable: TActiveValidationTable;
  tableContainer?(dictionary: Dictionary): ReactNode;
}

export type TValidationReference = { dictionary: Dictionary; text: string };

export const ILSDataInfoComponent: FC<ILSDataInfoComponentProps> = ({
  activeTable,
  validateData,
  errorList,
  dataSource,
  isPlanning,
  tableContainer,
  showErrorDescription,
  showAll,
  ...props
}) => {
  const { table, kind } = activeTable;

  if (!table) return <></>;

  const [showReference, setShowReference] = useState<ShowReference<any>>(false);

  //NOTE: Заголовок таблицы - dictionary
  const text = VALIDATION_TABLES[table]?.text ?? table ?? EMPTY_STRING;

  //NOTE: Заголовок таблицы - тип ошибки
  const title = `${text}: ${(kind && validationErrorTypes?.[kind]?.text) ?? EMPTY_STRING}`;

  //NOTE: Определяем есть ли ссылка на таблицу в справочнике
  const dictionary = VALIDATION_TABLES[table].reference && table;
  const codeField = dictionary + 'Code';
  const { codes, failKeys, failFieldName, failRows, failDataSource, dataSourceRowMap } = getFailData(
    errorList,
    dataSource,
    showAll,
    codeField
  );

  const Container = tableContainer?.(dictionary as Dictionary);
  const columns = getFailDataTableColumns(commonErrorColumns, kind);

  return (
    <div className=" table mb-3">
      <div className="flex-start-center flex-wrap-wrap">
        <ILSTypographyTitle children={title} level={4} />
      </div>
      <>
        {isPlanning ? (
          <ILSValidatePlanningTableComponent
            {...props}
            showAll={showAll}
            dataSource={failDataSource}
            errorList={errorList}
            activeTable={activeTable}
            type={table}
            text={text}
            codeField={codeField}
            codes={codes}
            failKeys={failKeys}
            failFieldName={failFieldName}
            Catalog={Container}
            showReference={showReference}
            setShowReference={setShowReference}
            dictionary={(VALIDATION_TABLES[table].reference && table) as Dictionary}
            columns={columns}
          />
        ) : (
          <ILSValidateUploadTableComponent
            {...props}
            showAll={showAll}
            dataSource={failDataSource}
            activeTable={activeTable}
            errorList={errorList}
            errors={validateData}
            type={table}
            dictionary={dictionary as Dictionary}
            text={text}
            codeField={codeField}
            failRows={failRows}
            rowMap={dataSourceRowMap}
            codes={codes}
            showReference={showReference}
            setShowReference={setShowReference}
            columns={columns}
          />
        )}
      </>
    </div>
  );
};
