import { FC } from 'react';
import { ILSInputNumber } from '@components/index';
import { EMPTY_STRING } from '@common/constants';
import { Option } from '@common/types';

interface IProps {
  name: string;
  type: string;
  value: string | number;
  attention: string | undefined;
  options: Option[];
  onChange(value: number | boolean | string): void;
}

export const ILSNumberItem: FC<IProps> = ({ onChange, value }) => {
  return (
    <div className="flex-start-center">
      <ILSInputNumber value={typeof value === 'string' ? value : EMPTY_STRING} className="w-50" onChange={onChange} />
    </div>
  );
};

