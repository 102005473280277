import { ColumnTable, Dictionary, Key, TableColumnName, TableRecord } from '@common/types';
import { ENUM_COLUMN_VARIANTS } from '@components/data-display/table/constants';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { EMPTY_STRING, SplitChar } from '@common/constants';

export const ENUM_STRING = 'Enum';
export const ID_STRING = 'ID';
export const NAME_STRING = 'Name';
export const TYPE_ENUM_STRING = 'TypeEnum';

export const getEnumField = (dataIndex: ColumnTable<TableRecord>['dataIndex']) => {
  if (!dataIndex?.includes(ID_STRING)) return EMPTY_STRING;
  const dataEnum = dataIndex?.replace(ID_STRING, ENUM_STRING);
  if (!dataEnum) return EMPTY_STRING;
  if (compareAsString(dataIndex, dataEnum)) return EMPTY_STRING;
  return dataEnum;
};

export const getParentCatalogIndex = (dataIndex: ColumnTable<TableRecord>['dataIndex']): TableColumnName | string => {
  try {
    return dataIndex.split(SplitChar.Dot)[1];
  } catch (e) {
    console.error(e);
    return EMPTY_STRING;
  }
};

export const getDictionaryFromField = (dataIndex: ColumnTable<TableRecord>['dataIndex']): Dictionary => {
  if (!dataIndex?.includes(ID_STRING)) return Dictionary.Empty;
  const dataEnum = dataIndex?.replace(ID_STRING, EMPTY_STRING);
  if (!dataEnum) return Dictionary.Empty;
  if (compareAsString(dataIndex, dataEnum)) return Dictionary.Empty;
  return dataEnum as Dictionary;
};

export const getCascaderField = (dataIndex: ColumnTable<TableRecord>['dataIndex']) => {
  const data = dataIndex?.replace(ID_STRING, '');
  return compareAsString(dataIndex, data) || !data ? EMPTY_STRING : data;
};
/*
Функция для получения строки с текущим представлением ячейки каскадера.
**/
export const getCascaderStringValue = (
  dataIndex: ColumnTable<TableRecord>['dataIndex'] | string,
  field?: any,
  dictionary?: Dictionary
): string => {
  //поле в котором содержится вся информация о выбранном значении
  const cascaderField = getCascaderField(dataIndex);

  //поле с типом
  const typeField = `${dictionary}${TYPE_ENUM_STRING}`;

  if (!field || !dictionary || !cascaderField || cascaderField === EMPTY_STRING || !field[cascaderField]) return EMPTY_STRING;

  const types = Object.values(field?.[cascaderField]?.[typeField] || {});

  const type = types[0] ? (types[0] as string) : EMPTY_STRING;

  return `${type} / ${field[cascaderField][NAME_STRING] || EMPTY_STRING}`;
};

export const getEnumOption = <T>(record: T, dataIndex?: TableColumnName | string) => {
  const enumField = dataIndex && getEnumField(dataIndex);
  const enumValue = enumField && record?.[enumField] && Object.entries(record?.[enumField]);
  const [[value, label]] = enumValue?.length ? enumValue : ENUM_COLUMN_VARIANTS;
  return { value, label };
};

export const getEnumOptions = <T>(record: T, dataIndex?: TableColumnName | string) => {
  const enumField = dataIndex && getEnumField(dataIndex);
  const enumValue: Array<[Key, string]> = enumField && record?.[enumField] && Object.entries(record?.[enumField]);
  return enumValue?.map(([value, label]) => ({ value, label }));
};
