import { FC, useMemo, useState } from 'react';
import { setTableCols, setTableColsOrder, setTableDefault } from '../actions/table-custom';
import { TableCustomComponent } from '../components/table-custom/index';
import { ColumnTable, IILSTableProps, TableConfig, TableName, TableRecord } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { tableCustomConfigSelector } from '@core/selectors/table-custom';
import { handleGrouping } from '@core/helpers/handle-grouping';

interface Props {
  dataSource?: any;
  tableName?: any;
  config?: TableConfig;
  [key: string]: any;
}

export const tableCustom = (ChildTable: FC<any>, tableConfig: TableConfig, tableName?: TableName | string, groupable = false) => {
  return (props: Props) => {
    return (
      <TableCustomContainer
        {...props}
        groupable={groupable}
        ChildTable={ChildTable}
        tableConfig={props?.tableConfig ?? tableConfig}
        tableName={props?.tableName ?? tableName}
      />
    );
  };
};

// todo: сделать вот так, чтобы HOC принимал props
// export const tableCustom = <R, P>(ChildTable: FC<any>, tableConfig: TableConfig, tableName: TableName | string) => {
//   return (props: IILSTableProps<R> & P) => (
//     <TableCustomContainer {...props} ChildTable={ChildTable} tableConfig={tableConfig} tableName={tableName} />
//   );
// };
interface IProps extends Omit<IILSTableProps<any>, 'config'> {
  groupable: boolean;
  ChildTable: FC<any>;
  tableConfig: TableConfig;
  tableName: TableName | string;
}

export const TableCustomContainer: FC<IProps> = ({ ChildTable, tableConfig, tableName, groupable, ...props }) => {
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const { config } = useSelector((state) => tableCustomConfigSelector(state, tableName, tableConfig, groupable));
  const dispatch = useDispatch();

  const onConfigChange = () => {
    setShowConfig((prev) => !prev);
  };

  const onCheckboxChange = (col: ColumnTable<TableRecord>, child: string, changeAllTo?: boolean) => {
    dispatch(setTableCols({ tableName, config, col, child, changeAllTo }));
  };

  const onOrderChange = (cols: ColumnTable<TableRecord>[], child: string) => {
    dispatch(setTableColsOrder({ tableName, config, cols, child }));
  };

  const onSetDefault = () => {
    dispatch(setTableDefault({ tableName, config: tableConfig }));
    onConfigChange();
  };

  const onGrouping = (colsForGrouping: ColumnTable<TableRecord>['dataIndex'][]) => {
    handleGrouping(tableName, colsForGrouping, dispatch);
  };

  const getActiveCols = (columns: ColumnTable<TableRecord>[]) => columns?.filter(({ active }) => active);
  const activeColumns = useMemo(() => getActiveCols(config?.columns ?? tableConfig.columns), [config?.columns]);

  return (
    <TableCustomComponent
      {...props}
      showConfig={showConfig}
      setShowConfig={setShowConfig}
      onCheckboxChange={onCheckboxChange}
      onConfigChange={onConfigChange}
      onOrderChange={onOrderChange}
      onSetDefault={onSetDefault}
      config={config}
      ChildTable={ChildTable}
      activeColumns={activeColumns}
      tableName={tableName}
      onGrouping={onGrouping}
    />
  );
};
