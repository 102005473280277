import { useAppDispatch, useAppSelector } from '@core/hooks';
import { FC, useContext, useRef } from 'react';
import { AnalyticsPlanningContext } from '../context';
import { boardSetsSelector } from '../selectors';
import { commonRoutine, deleteBoardSetRoutine, ModuleActionType } from '../actions';
import { AnalyticsBoardModel, AnalyticsBoardSetModel, CreateBoardSetParams } from '@common/types/dictionaries/analytics';
import ILSAnalyticsListComponent from '@modules/analytics/common/components/list';
import {
  BOARD_SETS_TITLE,
  COPY_BOARD_SET_TITLE,
  COPY_EXISTING_BOARD_SET_BTN_TEXT,
  CREATE_EMPTY_BOARD_SET_TEXT,
  NEW_BOARD_SET_TITLE
} from '../constants';
import ILSAnalyticsEditableListWindowComponent from '@modules/analytics/common/components/edit-list';
import { DEFAULT_BOARD_SETS_TITLE } from '@modules/analytics/common/constants';

interface Props {}

/**
 * Контейнер наборов досок
 */
export const BoardSetsListContainer: FC<Props> = () => {
  const setsBoardRef = useRef<HTMLDivElement | null>(null);
  const boardSets = useAppSelector(boardSetsSelector);
  const { handleSelectBoardSet, handleEditSet, editSet } = useContext(AnalyticsPlanningContext);

  const user = useAppSelector((store) => store.Auth.user.Account);

  const dispatch = useAppDispatch();

  const handleCreateBoardSet = (item?: CreateBoardSetParams) => {
    const length = boardSets?.data ? Object.values(boardSets?.data).length : 1;

    const Serial = { Serial: length > 1 ? length + 1 : length };

    dispatch(
      commonRoutine({
        data: [{ values: item ? { ...item, ...Serial } : Serial, role: user.AccountRole[0] }],
        method: 'boardSetCreate',
        routine: ModuleActionType.Create_Board_Set,
        isIndexed: true
      })
    );
  };

  const handleUpdateBoardSet = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    dispatch(
      commonRoutine({
        data: [{ ...item }],
        method: 'boardSetUpdate',
        routine: ModuleActionType.Update_Board_Set,
        isIndexed: true
      })
    );
  };

  const copyBoardSet = (id: number) => {
    dispatch(
      commonRoutine({
        data: [{ ID: id }],
        method: 'boardSetCopy',
        routine: ModuleActionType.Copy_Board_Set,
        isIndexed: true
      })
    );
  };

  const handleDeleteBoardSet = (id: string | number) => {
    dispatch(deleteBoardSetRoutine({ id }));
  };

  const handleDisableBoardSet = (item: AnalyticsBoardSetModel | AnalyticsBoardModel) => {
    dispatch(
      commonRoutine({
        data: [{ ...item }],
        method: 'boardSetUpdate',
        routine: ModuleActionType.Update_Board_Set,
        isIndexed: true
      })
    );
  };

  const sortBoardSetsList = (list: (AnalyticsBoardSetModel | AnalyticsBoardModel)[], itemID: number | string, idx: number) => {
    dispatch(
      commonRoutine({
        data: [...list],
        method: 'boardSetUpdate',
        routine: ModuleActionType.Sort_Board_Set,
        intoReducer: list
      })
    );
  };

  return (
    <>
      <ILSAnalyticsListComponent
        onEdit={handleEditSet}
        classNames={['boards-list']}
        handleClick={(data) => handleSelectBoardSet(data as AnalyticsBoardSetModel)}
        selectedId={boardSets?.selectedBoardSet}
        data={boardSets?.data}
        isFetching={boardSets?.isFetching}
        listRef={setsBoardRef}
        listTitle={BOARD_SETS_TITLE}
        defaultListItemTitle={DEFAULT_BOARD_SETS_TITLE}
        editable={true}
      />

      {editSet && (
        <ILSAnalyticsEditableListWindowComponent
          listData={boardSets?.data}
          createItem={(item) => handleCreateBoardSet(item as CreateBoardSetParams)}
          listRef={setsBoardRef}
          handleEditItem={handleEditSet}
          editable={editSet}
          updatingItem={boardSets?.updatingSet}
          adding={boardSets?.adding}
          updateItem={handleUpdateBoardSet}
          copyItem={copyBoardSet}
          deleteItem={handleDeleteBoardSet}
          disableItem={handleDisableBoardSet}
          sortListItems={sortBoardSetsList}
          newItemAddTitle={NEW_BOARD_SET_TITLE}
          copyItemListTitle={COPY_BOARD_SET_TITLE}
          defaultItemTitle={DEFAULT_BOARD_SETS_TITLE}
          listTitle={BOARD_SETS_TITLE}
          newItemButtons={{
            copyText: COPY_EXISTING_BOARD_SET_BTN_TEXT,
            newItemText: CREATE_EMPTY_BOARD_SET_TEXT
          }}
        />
      )}
    </>
  );
};
