import { createReducer } from '@reduxjs/toolkit';
import { ADDRESS_INITIAL_STATE, INITIAL_SUGGESTION_AUTO_COMPLETE_STATE } from '../store/store';
import { clearAddressSuggestRoutine } from '@common/feature/suggestion-autocomplete/address/actions';

export const suggestionClearReducer = createReducer(INITIAL_SUGGESTION_AUTO_COMPLETE_STATE, {
  [clearAddressSuggestRoutine.SUCCESS]: (state) => {
    state.address = ADDRESS_INITIAL_STATE;
    return state;
  }
});
