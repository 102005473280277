import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import { TENDER_FORM_TABLE_FIELDS_PROPERTIES, TenderFormTableField } from '@modules/tender-platform/constants/content';
import { useDispatch } from 'react-redux';
import { approveReplicateDocumentRoutine, updateTenderForRouteRoutine } from '@modules/tender-platform/actions';

interface IProps {
  field: TenderFormTableField;
}

export const TenderOwnFormTableButtonForAll: FC<IProps> = ({ field }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (field === TenderFormTableField.Documents) {
      dispatch(approveReplicateDocumentRoutine({ replicateDocument: true }));
    } else {
      dispatch(updateTenderForRouteRoutine({ field }));
    }
  };

  return (
    <ILSButtonTooltip
      disabled={!TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field]?.editable}
      onClick={handleClick}
      title={'Применить ко всем значения первого из Маршрутов'}
      icon={'arrow-right'}
    />
  );
};
