import { addEndDepotToTripRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningAPI } from '@modules/planning/api';
import { Dictionary } from '@common/types';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { PlanID } from '@common/types/dictionaries/plan';

function* addEndDepotToTripWorker(
  action: PayloadAction<{
    tripID: number;
    endDepotID: number;
    vehicleID: number;
  }>
) {
  const { request, failure, success, fulfill } = addEndDepotToTripRoutine;
  const { tripID, endDepotID, vehicleID } = action.payload;

  try {
    yield put(request());
    yield call(PlanningAPI.addEndDepotToTrip, { tripID, endDepotID });
    const planID: PlanID = yield select(activePlanIDSelector);
    const {
      data: { data: waypoint }
    } = yield call(PlanningAPI.getTotal, { planID, waypointID: endDepotID, dictionary: Dictionary.Waypoint });
    yield put(success({ tripID, endDepotID, vehicleID, waypoint }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addEndDepotToTripWatcher() {
  yield takeLatest(addEndDepotToTripRoutine.trigger, addEndDepotToTripWorker);
}
