import { DashboardTablesNumberMetrics, DashboardTablesObjMetrics } from '../children/dashboard/constants';
import { MonitoringState } from '../types/store';
import { VehicleTracker } from '@common/types';
import { Tracker } from '@common/types/dictionaries/tracker';

export const initialState: MonitoringState = {
  // Метрики раздела мониторинг
  monitoringMetrics: {
    data: {
      [DashboardTablesNumberMetrics.Total]: 0, // Всего точек
      [DashboardTablesNumberMetrics.Served]: 0, // Обслужено точек
      // Развезено во-время:
      [DashboardTablesObjMetrics.OnTime]: {
        Total: 0, // Всего
        Unviewed: 0 // Не просмотрено
      },
      // Доставлено с опозданием
      [DashboardTablesObjMetrics.OnLate]: {
        Total: 0,
        Unviewed: 0
      },
      // Не доставлено
      [DashboardTablesObjMetrics.Undelivered]: {
        Total: 0,
        Unviewed: 0
      },
      // Количество нарушений последовательностей доставки
      [DashboardTablesObjMetrics.BrokenSequence]: {
        Total: 0,
        Unviewed: 0
      },
      // Количество нарушений БДД (Безопасности дорожного движения)
      [DashboardTablesObjMetrics.Violations]: {
        Total: 0,
        Unviewed: 0
      },
      // Количество подозрений на слив топлива
      [DashboardTablesObjMetrics.FuelDrain]: {
        Total: 0,
        Unviewed: 0
      }
    },
    isFetching: false, // Идёт ли загрузка данных
    lastUpdate: undefined // Когда, и было ли, последнее обновление данных
  },
  catalog:{
  },
  // Интервал для которого доступны данные
  metricsInterval: {
    maxInterval: 3 * 24 * 60 * 60 // Максимальный доступный интервал - это трое суток
  },
  // Информация о привязках трекеров к автомобилям
  vehicleTrackers: {
    isFetching: false, // Идёт ли загрузка данных
    lastUpdate: undefined, // Когда, и было ли, последнее обновление данных
    data: [] as Array<VehicleTracker> // Массив данных с привязками трекеров к автомобилям
  },
  // Трекеры
  trackers: {
    isFetching: false, // Идёт ли загрузка данных
    lastUpdate: undefined, // Когда, и было ли, последнее обновление данных
    data: [] as Array<Tracker> // Массив данных о трекерах
  },
  // Плановые точки маршрута
  planPoints: {
    isFetching: false, // Плановые точки маршрута
    trackData: {} // Данные трека
  },
  forwarderPointStatuses: []
};
