import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { clearStateRoutine } from '@modules/orders-and-trips/actions';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { isArray } from 'lodash';
import { PayloadActionError } from '@common/types';
import { SplitChar } from '@common/constants';

export const clearStateReducer = createReducer(initialState, {
  [clearStateRoutine.SUCCESS]: (state: InitialState, action: PayloadAction<{ key: keyof InitialState }>) => {
    const { key } = action.payload;
    // @ts-ignore
    state[key] = initialState[key];
    return state;
  },
  [clearStateRoutine.FAILURE]: (state: InitialState, action: PayloadActionError) => {
    const { error } = action.payload;
    state.error = !isArray(error) ? [error] : [error.join(SplitChar.Semicolon)];
    return state;
  },
  [clearStateRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    return state;
  }
});
