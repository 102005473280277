import { useEffect, useRef, useState } from 'react';
import { PlannerBlocksFullScreen } from '@core/decorators/split-sizes';
import { PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';

export const useMapRefWithTriggerFocus = ({
  fullScreen,
  onFullChange
}: {
  fullScreen: PlannerBlocksFullScreen;
  onFullChange(arg: PlannerBlocksFullScreen | PlannerSettingsParameterFullScreen): void;
}) => {
  // Для тригера фокуса после отрисовки карты. Оно будет вызывать оригинальный триггер, когда карта появиться
  const [triggerMapFocusDelay, setTriggerMapFocusDelay] = useState<any[]>([]);
  const [triggerMapFocus, setTriggerMapFocus] = useState<any[]>([]);

  const mapRef = useRef<HTMLDivElement>(null);

  const handleTriggerMapFocus = (arr: any[]) => {
    if (arr?.length && fullScreen.table) {
      onFullChange(PlannerSettingsParameterFullScreen.Table);
      setTriggerMapFocusDelay(arr);
    } else {
      setTriggerMapFocus(arr);
    }
  };

  useEffect(() => {
    if (mapRef.current && triggerMapFocusDelay?.length) {
      setTimeout(() => {
        handleTriggerMapFocus(triggerMapFocusDelay);
        setTriggerMapFocusDelay([]);
        // TODO: refactor
      }, 500);
    }
  }, [mapRef.current, triggerMapFocusDelay]);

  useEffect(() => {
    if (triggerMapFocus && triggerMapFocus.length) {
      setTimeout(() => setTriggerMapFocus([]), 50);
    }
  }, [triggerMapFocus]);

  return { handleTriggerMapFocus, triggerMapFocus, mapRef };
};
