import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { PaymentBalanceHistoryTransaction, PaymentModalType } from '@common/feature/payment/types/general';

/** Названия для элементов меню */
export const PAYMENT_MENU_LABELS = {
  [PaymentModalType.PayWithCard]: 'Оплатить картой',
  [PaymentModalType.CreateInvoice]: 'Сформировать счет',
  [PaymentModalType.BalanceHistory]: 'Все операции'
};
/** Заголовки модального окна */
export const PAYMENT_MODAL_TITLES = {
  [PaymentModalType.PayWithCard]: 'Оплата картой',
  [PaymentModalType.CreateInvoice]: 'Формирование счета',
  [PaymentModalType.BalanceHistory]: 'Все операции'
};
/** Элементы меню */
export const PaymentMenuOptions: ItemType[] = [
  { label: PAYMENT_MENU_LABELS[PaymentModalType.PayWithCard], key: PaymentModalType.PayWithCard },
  { label: PAYMENT_MENU_LABELS[PaymentModalType.CreateInvoice], key: PaymentModalType.CreateInvoice },
  { label: PAYMENT_MENU_LABELS[PaymentModalType.BalanceHistory], key: PaymentModalType.BalanceHistory }
];

export const UPDATE_CURRENT_BALANCE_INTERVAL = 30000; // Временной интервал для апдейта текущего баланса (30 секунд)

/** Названия колонок для списка "Все операции" */
export const PAYMENT_BALANCE_HISTORY_CELL_TITLES = {
  [PaymentBalanceHistoryTransaction.tripID]: 'Номер рейса',
  [PaymentBalanceHistoryTransaction.dateTime]: 'Дата',
  [PaymentBalanceHistoryTransaction.balanceOffset]: 'Сумма',
  [PaymentBalanceHistoryTransaction.comment]: 'Комментарий'
};

