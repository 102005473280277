import { FC } from 'react';
import classnames from 'classnames';
import { compareAsString } from '@common/utils';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { ILSButton } from '@common/components';
import { SortDirection } from '@common/types';
import { FakePreCostProposalSortOption, FakePreCostSortUnit } from '@modules/orders-and-trips/types/fake-pre-cost';
import { PROPOSAL_SORT_OPTIONS_TITLES } from '@modules/orders-and-trips/constants/fake-pre-cost';

type IProps = {
  sortUnit: FakePreCostSortUnit;
  activeSortOption: FakePreCostProposalSortOption;
  sortProposals(sortUnit: FakePreCostSortUnit): () => void;
};

export const ProposalFilterButton: FC<IProps> = ({ sortUnit, activeSortOption, sortProposals }) => {
  const isActive = compareAsString(sortUnit, activeSortOption.unit);
  const isDesc = compareAsString(SortDirection.Desc, activeSortOption.direction);

  return (
    <ILSButton
      className={classnames('fake-pre-cost__card-filters-button', { active: isActive })}
      children={PROPOSAL_SORT_OPTIONS_TITLES[sortUnit]}
      onClick={sortProposals(sortUnit)}
      icon={<ILSSVG className={classnames('fake-pre-cost__card-filters-button-icon', { visible: isActive, desc: isDesc })} icon={'sort'} />}
    />
  );
};

