import { FC, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { ILSCatalogCollapseComponent } from './collapse';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import { ILSCatalogMapComponent } from './map';
import { RecordsTypeWithID } from '../types/catalog';
import { useDispatch, useSelector } from 'react-redux';
import { catalogFullStateSelector } from '../selectors';
import { isEqualIDRow, isEqualRow, RowData } from '@common/utils/table';
import { IPropsILSCatalogComponent, MapData, MapProps } from '../types/components';
import { useMap } from '../hooks/useMap';
import { ICatalogFunctions } from '@modules/catalog/context';
import { Dictionary } from '@common/types';
import { CATALOG_SHOW_MAP_DICTIONARY } from '../constants/catalogs';
import { EMPTY_STRING } from '@common/constants';
import { useCatalogTableByUrlSearchParams } from '../hooks/use-catalog-table-by-url-search-params';
import { color2int } from '@common/decorators/color';
import { handleCreateRow } from '@modules/catalog/helpers/table/handlers/handle-create-row';
import { handleDictionaryLoad } from '@modules/catalog/helpers/table/handlers/handle-dictionary-load';
import { catalogMapRoutine } from '@modules/catalog/actions';
import { handleUpdateRow } from '@modules/catalog/helpers/table/handlers/handle-update-row';
import CustomScroll from 'react-customscroll';

export const ILSCatalogComponent: FC<IPropsILSCatalogComponent> = ({ catalogs, ...props }) => {
  const dispatch = useDispatch();

  const handleSave = handleUpdateRow({ dispatch });
  const [activePanelKey, setActivePanelKey] = useState<number | null>(null);
  useCatalogTableByUrlSearchParams({ activePanelKey, setActivePanelKey });
  //TODO Не должны хранить все данные по фокусировкам и чекбоксам в смешку
  const [activeItem, setActiveItem] = useState<Array<any>>([]);
  //только для карты
  const [showMap, setShowMap] = useState<boolean | null>(null);
  const [mapData, setMapData] = useState<MapData>(null);
  const [mapProps, setMapProps] = useState<MapProps | null>(null);
  const [activeLayers, setActiveLayers] = useState<number[]>([]);

  const handleMap: ICatalogFunctions['handleMap'] = (dictionary, id, value) =>
    dispatch(
      catalogMapRoutine({
        dictionary,
        id,
        value
      })
    );

  const onMapChange = ({ dictionary }: any) => {
    if (dictionary) {
      handleMap?.(dictionary, 'visible', !showMap);
    }
    setShowMap(!showMap);
  };

  const onPanelChange = (panel: any) => {
    const dictionary = panel && parseInt(panel.replace('panel', EMPTY_STRING));
    setActivePanelKey(dictionary);
    const item = dictionary && catalogs[dictionary];
    setActiveItem([]);
    if (item?.map) {
      if (!showMap) setShowMap(!showMap);
    } else {
      if (showMap) setShowMap(!showMap);
    }
  };

  const handleResize = (_gutterIdx: any, allSizes: number[]) => {
    const mapPercent = allSizes?.length && allSizes[1];
    if (mapPercent && mapData && mapData.dictionary && handleMap) {
      handleMap(mapData.dictionary, 'widthPercent', parseInt(mapPercent.toString()));
    }
  };

  const handleMapProps = (props: MapProps) => {
    if (props !== mapProps) {
      if (props) {
        if (props.colorField !== mapProps?.colorField || props.field !== mapProps?.field || props.type !== mapProps?.type) {
          setMapProps(props);
        }
      } else {
        setMapProps(props);
      }
    }
  };

  const extraProps = {
    onPanelChange,
    showMap,
    setShowMap,
    activePanelKey,
    setActivePanelKey,
    onMapChange,
    setMapProps: handleMapProps
  };

  let item = activePanelKey ? catalogs[activePanelKey] : undefined;
  const dictionary: Dictionary = item?.dictionary ?? Dictionary.Empty;
  const catalogSelector = useMemo(catalogFullStateSelector, []);
  let dictState = useSelector((state) => catalogSelector(state, dictionary as Dictionary));
  const mapWidthPercent = (showMap && mapData && dictState?.map.widthPercent) ?? 0;

  const isMapVisible: boolean = Boolean(showMap && mapData && mapData.mapProps) || false;
  const tableSize = isMapVisible ? 100 - Number(mapWidthPercent) : 100;
  const mapSize = isMapVisible ? Number(mapWidthPercent) : 0;

  // данные для карты
  useMap(dictionary, mapProps, activeItem, mapSize, !!showMap, mapData, setMapData);

  const handleChangeDataSource = (newDataSource: any): void => {
    let update = false;
    let newActiveItem = [...activeItem];
    if (!isEmpty(newActiveItem)) {
      for (let ind = 0; ind < newActiveItem.length; ind++) {
        let actItem = newActiveItem[ind];
        if (actItem) {
          let updateItem = newDataSource.find((item: RowData) =>
            isEqualIDRow(item, [actItem.ID || undefined, actItem.rowKey || undefined, actItem.key || undefined])
          );
          if (updateItem) {
            if (!isEqualRow(actItem, updateItem, mapProps?.field)) {
              update = true;
              newActiveItem[ind] = updateItem;
            }
          } else {
            update = true;
            newActiveItem[ind] = undefined;
          }
        }
      }
    }
    if (update) {
      setActiveItem((prev) => (prev === activeItem ? newActiveItem.filter(Boolean) : prev));
    }
  };

  //Получение Выделенных или активных элементов
  const handleSelectedElements = (rowData: any) => {
    if (rowData !== activeItem) {
      if (rowData) {
        if (rowData.length) {
          if (rowData.length === activeItem.length) {
            for (let rdInd = 0; rdInd < rowData.length; rdInd++) {
              let newRow = rowData[rdInd];
              let rdOld = activeItem.find((at) =>
                isEqualIDRow(at, [newRow.ID || undefined, newRow.rowKey || undefined, newRow.key || undefined])
              );
              if (!isEqualRow(newRow, rdOld, mapProps?.field)) {
                setActiveItem(rowData);
                return;
              }
            }
          } else {
            setActiveItem(rowData);
          }
        }
      } else {
        setActiveItem(rowData);
      }
    }
  };

  //TODO на этой функции не применяются декораторы которые получают колбеки в таблицах
  const onSave = (record: any, newProps: any) => {
    if (record.Color) {
      record.Color = color2int(record.Color);
    }
    if (dictionary) {
      const values = newProps;
      handleSave?.(dictionary, record, values);

      if (!Object.keys(values).includes('LatLon')) {
        setMapData((mapData: MapData) => {
          const newData = {
            ...mapData,
            ruler: true
          };
          newData.dataSource = activeItem.map((it: any) => {
            if (isEqualIDRow(it, [(record as RecordsTypeWithID).ID || undefined, record.rowKey || undefined, record.key || undefined])) {
              it = {
                ...it,
                ...values
              };
            }
            return it;
          });
          return newData as MapData;
        });
      }
    }
  };

  return (
    <CustomScroll>
      <section className="catalog">
        <ReactSplit
          direction={SplitDirection.Horizontal}
          initialSizes={[tableSize, mapSize]}
          gutterClassName={showMap && mapData?.mapProps ? 'custom-gutter-horizontal' : 'hide-gutter'}
          onResizeFinished={handleResize}
        >
          <ILSCatalogCollapseComponent
            {...props}
            catalogs={catalogs}
            handleSelectedElements={handleSelectedElements}
            handleChangeDataSource={handleChangeDataSource}
            isMapVisible={isMapVisible}
            {...extraProps}
          />
          {showMap && mapData?.mapProps && CATALOG_SHOW_MAP_DICTIONARY.includes(dictionary) && (
            <ILSCatalogMapComponent
              {...props}
              {...mapData}
              handleSave={onSave}
              activeLayers={activeLayers}
              setActiveLayers={setActiveLayers}
              handleCreate={handleCreateRow({ dispatch })}
              handleDictionaryLoad={handleDictionaryLoad({ dispatch })}
            />
          )}
        </ReactSplit>
      </section>
    </CustomScroll>
  );
};
