import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { StatusConfig } from './status';
import { TenderLot } from './lot';
import {
  Account,
  AccountSimple,
  DictionariesEndpoint,
  Dictionary,
  IEnum,
  Module,
  Order,
  OrderActionType,
  Plan,
  Restriction,
  TimeStamp,
  Trip,
  User,
  UserSimple,
  Vehicle,
  VehicleType
} from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderParticipant } from '../children/tender-own/types';
import { OfferAccepted } from '../children/tender-search/types/offer';
import { StatisticProps } from 'antd/lib/statistic/Statistic';
import { RouteList } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderTemplate } from './store';

export enum TenderCreateFields {
  Name = 'Name',
  Type = 'Type',
  Private = 'Private',
  OfferStartDT = 'OfferStartDT',
  OfferEndDT = 'OfferEndDT',
  ExecutionStartDT = 'ExecutionStartDT',
  ExecutionEndDT = 'ExecutionEndDT',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRating = 'MinDriverRating',
  MinDriverRoutes = 'MinDriverRoutes'
}

export type TenderRoute = {
  ActionType: OrderActionType;
  Address: string;
  Date: string;
  Mass: number;
  Orders: Array<Order['ID']>;
  OrdersCount: number;
};

export type TenderTrip = {
  TenderID: Tender['ID'];
  TripID: Trip['ID'] | null;
  PlanID: Plan['ID'];
  VehicleID: Vehicle['ID'];
};

export type TenderSummary = {
  Mass: number;
  Volume: number;
  RegionalCitySender: string | null;
  RegionalCityRecipient: string | null;
  Distance: number;
  VehicleType: Array<VehicleType> | [] | IEnum<VehicleType['Name']>;
  StartPrice: TenderLot['StartPrice'];
  MinPrice: TenderLot['MinPrice'];
  VAT: TenderLot['VAT'];
  RestrictionID: Array<Restriction['ID']>;
  RestrictionEnum?: IEnum<Restriction['Name']>;
  TenderParticipantsCount?: number;
  TenderOfferCount?: number;
};

export enum TenderVisibilityStatus {
  Open,
  Close
}

export enum TenderVisibilityIcon {
  Open = 'unlocked',
  Close = 'locked'
}

export enum TenderIconTitle {
  Open = 'Открытый',
  Close = 'Закрытый'
}

export enum TenderType {
  Competition = 1,
  Auction = 2,
  RequestForProposal = 3,
  RequestForInformation = 4
}

export enum TenderFromPlanner {
  No,
  Yes
}

export enum TenderTypeLabel {
  Competition = 'Конкурс',
  Auction = 'Аукцион',
  RequestForProposal = 'Запрос предложений',
  RequestForInformation = 'Запрос информации'
}

export enum TenderStatus {
  New = 'New',
  OnApproval = 'OnApproval',
  UnderRevision = 'UnderRevision',
  Approved = 'Approved',
  CollectingOffers = 'CollectingOffers',
  SummingResults = 'SummingResults',
  ApprovalResults = 'ApprovalResults',
  Executed = 'Executed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  NotTake = 'NotTake'
}

export type Tender = {
  ID: number;
  Account: AccountSimple;
  AccountID: Account['ID'];
  UserID: User['ID'];
  StatusID: number;
  Private: TenderVisibilityStatus;
  Type: TenderType;
  Name: string;
  Description: string | null;
  OfferStartDT: TimeStamp;
  OfferEndDT: TimeStamp;
  OffersAccepted?: OfferAccepted[] | [];
  ExecutionStartDT: TimeStamp;
  ExecutionEndDT: TimeStamp;
  StatusConfig: StatusConfig;
  Lots?: number;
  TenderLot?: Array<TenderLot>;
  TenderParticipants?: TenderParticipant[];
  TenderOffer?: TenderOffer[];
  TenderOfferCurrent?: { Price: number };
  User?: UserSimple;
  MinCarrierRating: number | null;
  MinDriverRating: number | null;
  MinDriverRoutes: number | null;
  BasePrice: number;
  CurrentPrice: number;
  TenderTrip?: TenderTrip;
  FromPlanner?: TenderFromPlanner;
  TenderSummary?: TenderSummary;
  RouteList?: RouteList;
  // TODO: убрать поле TenderParticipantsCount на беке, перенесли в TenderSummary
  TenderParticipantsCount?: number;
  // TODO: убрать поле TenderOfferCount на беке, перенесли в TenderSummary
  TenderOfferCount?: number;
  // TODO: в ответе есть, в swagger поля нет
  Info?: null;
  // TODO: в ответе есть, в swagger поля нет
  Created?: TimeStamp | null;
  // TODO: в ответе есть, в swagger поля нет
  StartPrice?: number;
  // TODO: в ответе есть, в swagger поля нет
  PrepaymentPaid?: boolean | null;
  // TODO: в ответе есть, в swagger поля нет
  ClientInvoicePaid?: boolean | null;
  // TODO: в ответе есть, в swagger поля нет
  CarrierInvoicePaid?: boolean | null;
  // TODO: в ответе есть, в swagger поля нет
  FuelStatusID?: number | null;
};

export type TenderModule = Module.TenderOwn | Module.TenderSearch;

export type TenderOperation =
  | DictionariesEndpoint.SetStatus
  | DictionariesEndpoint.Delete
  | DictionariesEndpoint.Create
  | DictionariesEndpoint.Update
  | DictionariesEndpoint.Accept
  | DictionariesEndpoint.Reset
  | DictionariesEndpoint.tenderTemplateData;

export type TenderDictionary = Dictionary.Tender | Dictionary.TenderLot | Dictionary.TenderOffer | Dictionary.TenderParticipants;

export type AuctionInfo = {
  isOver: boolean;
  canChangeOffer: boolean;
  endTime: StatisticProps['value'];
  isOfferMine: boolean;
  isBestPrice: boolean;
  accepted: boolean;
  isMyAndBest: boolean;
};

export type TenderDocuments = {
  ID: number;
  Subject: string;
  UrlOriginal: string;
  UrlThumbnail: string;
  // todo продумать вариант на backend https://jira.intelogis.ru/browse/TMS-5883
  Size?: number;
};

export type TenderChangeAction = PayloadAction<{
  Tender?: Tender;
  StatusConfig?: StatusConfig;
  TenderLot?: TenderLot[];
  TenderOffer?: TenderOffer[];
  TenderOfferID?: TenderOffer['ID'];
  TenderParticipants?: TenderParticipant[];
  operation: TenderOperation;
  ids?: Array<TenderLot['ID'] | TenderOffer['ID'] | TenderParticipant['ID']>;
  dictionary?: TenderDictionary;
}>;

export type TenderTemplateChangeAction = PayloadAction<{
  TenderTemplate: TenderTemplate;
}>;

