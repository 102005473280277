import { LayerGroup } from 'leaflet';
import { IconPathConfig } from '@common/types';

export enum DETAILS_CONTENT {
  GENERAL = 'general',
  MAP = 'map'
}

export type DetailsMapLayers = {
  trackLayer: LayerGroup | undefined;
  markerLayer: LayerGroup | undefined;
  isInit: boolean;
};

export interface DetailsRadioButtonType {
  value: DETAILS_CONTENT;
  label: string;
  iconName: string;
  iconConfig?: IconPathConfig;
}

