import { apiInstance } from '@core/api/instance';
import { IDistanceMatrixAPI } from '@modules/planning/children/by-date/types';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const DistanceMatrix: IDistanceMatrixAPI = {
  start: (params) => apiInstance().post(getAPIURL(Dictionary.DistanceMatrix, DictionariesEndpoint.calculateMatrixStart), params),
  delete: (params) => apiInstance().post(getAPIURL(Dictionary.DistanceMatrix, DictionariesEndpoint.calculateMatrixDelete), params),
  status: (params) => apiInstance().post(getAPIURL(Dictionary.DistanceMatrix, DictionariesEndpoint.calculateMatrixStatus), params),
  cleanup: (params) => apiInstance().post(getAPIURL(Dictionary.DistanceMatrix, DictionariesEndpoint.matrixCleanup), params)
};
