import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { setActiveProjectIdRoutine } from '../actions';
import { initialState } from '@modules/planning/store';
import { Project } from '@common/types';
import { PlanningStatus } from '@modules/planning/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const setActiveProjectIdReducer = createReducer(initialState, {
  [setActiveProjectIdRoutine.REQUEST]: onRequest,
  [setActiveProjectIdRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      activeProjectID: Project['ID'];
    }>
  ) => {
    const { activeProjectID } = action.payload;
    state.activeProjectID = activeProjectID;
    state.status = PlanningStatus.Result;
    return state;
  },
  [setActiveProjectIdRoutine.FAILURE]: onFailure,
  [setActiveProjectIdRoutine.FULFILL]: onFulfill
});
