import { CargoFormFields } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/fields/cargo';
import { DocumentsFormFields } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/fields/documents';
import { VehicleFormFields } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/fields/vehicle';
import { ProjectLogistics } from '@modules/orders-and-trips/containers/full/fake-pre-cost/form-tabs/project-logistics/project-logistics';

export enum NewOrderFormTab {
  Cargo = 'cargo',
  Documents = 'documents',
  Vehicle = 'vehicle',
  ProjectLogistics = 'projectLogistics'
}

export const NEW_ORDER_FORM_TABS = [
  {
    title: 'Документы',
    key: NewOrderFormTab.Documents,
    FormFields: DocumentsFormFields
  },
  {
    title: 'Груз',
    key: NewOrderFormTab.Cargo,
    FormFields: CargoFormFields
  },
  {
    title: 'Выделенный транспорт',
    key: NewOrderFormTab.Vehicle,
    FormFields: VehicleFormFields
  },
  {
    title: 'Проектная логистика',
    key: NewOrderFormTab.ProjectLogistics,
    FormFields: ProjectLogistics
  }
];
