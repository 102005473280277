import { ILSInputNumber, ILSUserNotify } from '@components/index';
import { Notify, TableRecord } from '@common/types';
import { IILSCellNumberProps } from '@common/types/components/table/cell';
import { getCellInputValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-value';
import { EMPTY_STRING } from '@common/constants';

export const ILSCellFloat = <R extends TableRecord>({
  children,
  toggleEdit,
  save,
  min,
  max,
  step
}: IILSCellNumberProps<R>): JSX.Element => {
  const [node, value = EMPTY_STRING] = children;

  const saveValue = (val: number) => {
    if (typeof min !== 'undefined' && val < min) {
      const text = min === 0 ? 'отрицательным' : `меньше ${min}`;
      ILSUserNotify(Notify.Error, `Значение ячейки не может быть ${text}`);
    } else if (typeof max !== 'undefined' && val > max) {
      ILSUserNotify(Notify.Error, `Значение ячейки не может быть больше ${max}`);
    } else {
      save(val);
    }
  };

  const onSave = (e: any) => {
    saveValue(Number(e.target.value));
    toggleEdit?.();
  };

  return (
    <>
      {node}
      <ILSInputNumber
        className={'cell-wrapper-editable-full'}
        defaultValue={getCellInputValue(value)}
        min={min}
        max={max}
        bordered
        type="number"
        onPressEnter={onSave}
        onBlur={onSave}
        autoFocus
        step={step}
      />
    </>
  );
};
