import { SchemeInfo } from '@modules/planning/children/scheme-editor/types';
import { Options } from '@common/types';

export const generateOptionsSchemeTypeFromAvailableOptions = ({
  availableTypes,
  type
}: {
  availableTypes?: SchemeInfo['availableTypes'] | [] | null;
  type: SchemeInfo['type'];
}): Options => {
  return Array.isArray(availableTypes) && availableTypes.length
    ? availableTypes?.map((type) => ({ value: type, label: type }))
    : [{ value: type, label: type }];
};
