import { Dictionary } from '@common/types';
import { ILSPlanningOrder } from '@modules/planning/children/by-date/components/tables/order-validation';
import { ILSPlanningVehicle } from '@modules/planning/children/by-date/components/tables/vehicle-validation';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators';
import { ILSPlanningDepot } from '@modules/planning/children/by-date/components/tables/depot';

export const VALIDATION_KINDS = {
  ERROR: 'Err',
  BAD: 'Bad',
  WARNING: 'Wrn',
  INFO: 'Inf'
};

export const ERROR_TYPES = {
  error: 'ошибки',
  bad: 'критические ошибки',
  warning: 'предупреждения',
  info: 'информация'
};

export const ERROR_TABLE_DICTIONARY = {
  Driver: 'Водители',
  Vehicle: 'Автомобили',
  Order: 'Заявки',
  Depot: 'Точки',
  Common: 'Общее'
};

export const COMMON = 'Common';

export const LAST_TABLE_ROW = {
  CountAll: 'Итого'
};

export const VALIDATION_TABLES_FOR_PLANNING_BY_DICTIONARY = {
  [Dictionary.Order]: {
    title: 'Заявки',
    dictionary: Dictionary.Order,
    component: ILSPlanningOrder
  },
  [Dictionary.Vehicle]: {
    title: 'Автомобили',
    dictionary: Dictionary.Vehicle,
    component: ILSPlanningVehicle,
    decorator: plannerPlanningVehiclesToTable
  },
  [Dictionary.Depot]: {
    title: 'Точки',
    component: ILSPlanningDepot,
    dictionary: Dictionary.Depot
  }
};
