import { PlanNotice } from '@common/types';
import { isEmpty } from 'lodash';

export const getCurrentNotifications = ({
  ownNotifications,
  childNotifications
}: {
  ownNotifications: PlanNotice[];
  childNotifications: PlanNotice[];
}) => {
  return !isEmpty(ownNotifications) ? ownNotifications : childNotifications;
};
