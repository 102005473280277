import { createSelector } from 'reselect';
import { TenderType } from '@modules/tender-platform/types';
import { currentTenderSelector, currentTenderTypeSelector, tenderOffersSelector } from '@modules/tender-platform/selectors/current-tender';

export const currentTenderAuctionPriceSelector = createSelector(
  currentTenderSelector,
  (currentTender) => currentTender?.TenderOfferCurrent?.Price
);
export const isAuctionSelector = createSelector(currentTenderTypeSelector, (Type) => Type === TenderType.Auction);

export const auctionSummarySelector = createSelector(isAuctionSelector, tenderOffersSelector, (isAuction, tenderOffers) => ({
  hasAuctionSummary: Boolean(isAuction && tenderOffers.length)
}));
