import { CatalogRead } from '@modules/catalog/types/actions';
import { handleDictionaryLoad } from '@modules/catalog/helpers/table/handlers/handle-dictionary-load';
import { Dictionary, ShowDeleted } from '@common/types';
import { Dispatch } from 'redux';

export const handleShowDeleted =
  ({ dictionary, childrenDictionary, dispatch }: { childrenDictionary: Dictionary; dispatch: Dispatch; dictionary: Dictionary }) =>
  (showDeleted: ShowDeleted) => {
    const payload: CatalogRead = {
      dictionaryFetching: false,
      withoutNotification: true,
      dictionary,
      showDeleted,
      childrenDictionary
    };
    handleDictionaryLoad({ dispatch, payload })(dictionary);
  };
