import { createSelector } from 'reselect';
import {
  currentSchemeKeysInDictionariesSelector,
  currentSchemeKeysSelector
} from '@modules/planning/children/scheme-editor/selectors/current-scheme';
import { FullSchemaDictionary, SchemeSwitchDictionary, TypeContainer } from '@modules/planning/children/scheme-editor/types';
import { maxSchemeSelector, schemeDictionarySelectSelector } from '@modules/planning/children/scheme-editor/selectors/max-scheme';
import { createDataForScheme } from '@modules/planning/children/scheme-editor/utils/helpers/selctors';
import { Dictionary } from '@common/types';

export const extraSchemeSelector = createSelector(
  currentSchemeKeysInDictionariesSelector,
  maxSchemeSelector,
  currentSchemeKeysSelector,
  (currentSchemeKeysInDictionaries, maxScheme, currentSchemeKeys) => {
    const extraScheme = {} as FullSchemaDictionary;
    for (let dictionary in maxScheme) {
      if (dictionary !== Dictionary.Options && !currentSchemeKeys.includes(dictionary as SchemeSwitchDictionary)) {
        extraScheme[dictionary] = maxScheme[dictionary];
      }
    }
    for (let dictionary in currentSchemeKeysInDictionaries) {
      if (dictionary !== Dictionary.Options) {
        extraScheme[dictionary] = {};
        const currentSchemeKeys = currentSchemeKeysInDictionaries[dictionary];
        for (let key in maxScheme[dictionary]) {
          if (!currentSchemeKeys.includes(key) && key !== Dictionary.Options) {
            extraScheme[dictionary][key] = maxScheme[dictionary][key];
          }
        }
      }
    }

    return extraScheme;
  }
);

export const getDictionaryExtraSchemeSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(extraSchemeSelector, (extraScheme) => extraScheme?.[dictionary] ?? {});

export const getSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(getDictionaryExtraSchemeSelector(dictionary), (extraScheme) => Object.keys(extraScheme));

export const dictionaryExtraSchemeDataSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(
    getDictionaryExtraSchemeSelector(dictionary),
    getSelector(dictionary),
    schemeDictionarySelectSelector(dictionary),
    (schemeColumn, keys, availableTypes) => createDataForScheme(schemeColumn, keys, TypeContainer.Max, availableTypes)
  );
