import { DateTimeFormat, IndexedArray, VehicleModel } from '@common/types';
import { TrackerLastInfoModel } from '@common/types/dictionaries/monitoring-vehicle';
import { truckMarker } from '@core/components/map/icons';
import { ILSMap, ILSMarker } from '@core/containers/map';
import { LayerHandler } from '@core/types/map';
import { getVehicleMarkerMovementStatusColor } from '@modules/monitoring/children/event-details/utils/get-vehicle-marker-movement-status-color';
import L, { LatLngTuple } from 'leaflet';
import moment from 'moment';
import { MAP_BOUNDS_DEVIATION } from '../../constants';
import { azimutToDegree } from '../azimut-to-degree';
import { vehicleTooltip } from './tooltips';

// Утилита для создания тултипов для автомобилей
const createVehicleTooltip = (trackerInfo: TrackerLastInfoModel & VehicleModel) => {
  return vehicleTooltip({
    changed: trackerInfo.Recieved ? moment.unix(trackerInfo.Recieved).format(DateTimeFormat.DDMMYYYYPeriodHHMMColonSpaceZ) : '',
    speed: trackerInfo.Speed ? Number(trackerInfo.Speed).toFixed(2) : undefined,
    latitude: trackerInfo.Latitude ? Number(trackerInfo.Latitude).toFixed(6) : undefined,
    longitude: trackerInfo.Longitude ? Number(trackerInfo.Longitude).toFixed(6) : undefined,
    name: trackerInfo?.Name,
    vehicleRegNumber: trackerInfo?.RegNumber ?? undefined
  });
};

/**
 * Преобразование TrackerLastInfoModel в ILSMarker, который испоьзуется для отображения на карте
 * @vehicleMarker - TrackerLastInfoModel информация с датчика
 */
const markerToIlsMarker = (
  trackerInfo: TrackerLastInfoModel & VehicleModel,
  handlers?: {
    [K: string]: LayerHandler<L.Marker>;
  }
) => {
  const tooltip = createVehicleTooltip(trackerInfo);
  const color = getVehicleMarkerMovementStatusColor(trackerInfo);
  const markerInit: Parameters<ILSMap['addMarker']>[0][0] = {
    coords: [trackerInfo.Latitude, trackerInfo.Longitude] as LatLngTuple,
    options: {
      icon: truckMarker(azimutToDegree(trackerInfo.Azimuth || 0), color),
      draggable: false
    },
    popup: [tooltip],
    handlers
  };

  return new ILSMarker(markerInit);
};

//TODO рефакторинг
/**
 * Функция для обработки информации с абстрактного трекера. И отображении изменений на карте
 * @param args
 * @returns возвращает набор изменных данных IndexedArray<{ tracker: T; mapMarker: ILSMarker }>
 */
export const trackerInfoToMarkers = <T extends TrackerLastInfoModel & VehicleModel>(args: {
  incomingTrackers: IndexedArray<T>;
  mapObject: ILSMap;
  existingMarkers: IndexedArray<{ tracker: T; mapMarker: ILSMarker }> | undefined;
  clusterToAdd?: L.MarkerClusterGroup;
  vehicleFocusHandler?: (activeVehicleID: number | string) => void;
}): IndexedArray<{ tracker: T; mapMarker: ILSMarker }> | undefined => {
  const { existingMarkers, mapObject, incomingTrackers, clusterToAdd, vehicleFocusHandler } = args;
  let fitToBounds = false;
  const acitveMarkers = {};
  //copy from existingMarkers to acitveMarkers, only ones that exists in incomingTrackers
  if (existingMarkers) {
    for (const imei in existingMarkers) {
      const marker: { tracker: T; mapMarker: ILSMarker } = existingMarkers[imei];
      const existing = incomingTrackers[imei];
      if (!existing && marker) {
        if (clusterToAdd) {
          clusterToAdd.removeLayer(marker.mapMarker.LMarker);
        } else {
          mapObject.deleteAnyLayer(marker.mapMarker.LMarker);
        }
      } else {
        fitToBounds = false; //don't fit if any activeRoutes exists
        acitveMarkers[imei] = marker;
      }
    }
  }
  const bounds: { minLat?: number; maxLat?: number; minLng?: number; maxLng?: number } = {};
  //update and remove markers and trackers
  for (const imei in incomingTrackers) {
    const incomingTracker: T = incomingTrackers[imei];
    const lastInfo = {
      ...incomingTracker,
      Latitude: parseFloat(incomingTracker.Latitude.toString()),
      Longitude: parseFloat(incomingTracker.Longitude.toString()),
      Azimuth: incomingTracker.Azimuth ? parseFloat(incomingTracker.Azimuth.toString()) : 0
    };

    //TODO нужно вынести в отдельную функцию
    if (fitToBounds) {
      bounds.minLat = !bounds.minLat || bounds.minLat > lastInfo.Latitude ? lastInfo.Latitude : bounds.minLat;
      bounds.minLng = !bounds.minLng || bounds.minLng > lastInfo.Longitude ? lastInfo.Longitude : bounds.minLng;
      bounds.maxLng = !bounds.maxLng || bounds.maxLng < lastInfo.Longitude ? lastInfo.Longitude : bounds.maxLng;
      bounds.maxLat = !bounds.maxLat || bounds.maxLat < lastInfo.Latitude ? lastInfo.Latitude : bounds.maxLat;
    }

    const activeMarker: { tracker: T; mapMarker: ILSMarker } | undefined = acitveMarkers[imei];
    if (activeMarker) {
      const isNewCoords = activeMarker.tracker.Latitude !== lastInfo.Latitude || activeMarker.tracker.Longitude !== lastInfo.Longitude;
      const isNewAzimuth = lastInfo.Azimuth !== activeMarker.tracker.Azimuth;
      const isNewSpeed = lastInfo.Speed !== activeMarker.tracker.Speed;

      if (isNewCoords) {
        activeMarker.tracker.Latitude = lastInfo.Latitude;
        activeMarker.tracker.Longitude = lastInfo.Longitude;
        activeMarker.mapMarker.LMarker.setLatLng([lastInfo.Latitude, lastInfo.Longitude]);
      }
      if (isNewAzimuth) {
        const color = getVehicleMarkerMovementStatusColor(lastInfo);
        activeMarker.tracker.Azimuth = lastInfo.Azimuth;
        activeMarker.mapMarker.LMarker.setIcon(truckMarker(azimutToDegree(lastInfo.Azimuth), color));
      }
      if (isNewCoords || isNewCoords || isNewSpeed) {
        activeMarker.mapMarker.LMarker.setPopupContent(createVehicleTooltip(activeMarker.tracker));
      }
    } else {
      const mapMarker = markerToIlsMarker(lastInfo, { popupopen: () => vehicleFocusHandler?.(lastInfo.ID) });
      if (clusterToAdd) {
        clusterToAdd.addLayer(mapMarker.L);
      } else {
        mapObject.addAnyLayer(mapMarker.L);
      }
      acitveMarkers[imei] = { tracker: { ...incomingTracker }, mapMarker };
    }
  }
  if (fitToBounds && bounds?.minLng && bounds?.minLat && bounds?.maxLat && bounds?.maxLng) {
    mapObject.fitBounds(
      L.latLngBounds(
        [bounds.maxLat + MAP_BOUNDS_DEVIATION, bounds.minLng - MAP_BOUNDS_DEVIATION],
        [bounds.minLat - MAP_BOUNDS_DEVIATION, bounds.maxLng + MAP_BOUNDS_DEVIATION]
      )
    );
  }
  return acitveMarkers;
};

