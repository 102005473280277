import { defineModule } from '@core/index';
import * as dataRootSagas from '@modules/planning/children/data/sagas';
import { PlannerData } from '@modules/planning/children/data/containers/module';
import { getAllModuleSagas } from '@core/sagas';
import { planningDataReducer } from '@modules/planning/children/data/reducers';
import { ModulePath, ModuleTitle } from '@common/types';

export default defineModule({
  title: ModuleTitle.Data,
  path: ModulePath.Data,
  component: PlannerData,
  reducer: planningDataReducer,
  sagas: getAllModuleSagas(dataRootSagas),
  persist: false
});
