export const enum DataRangeOptionType {
  ThisWeek = 'Текущая неделя',
  LastWeek = 'Прошлая неделя',
  ThisMonth = 'Текущий месяц',
  LastMonth = 'Прошлый месяц',
  Custom = 'Выбрать интервал'
}

export const dateRangeOptions: Array<{ type: DataRangeOptionType; value: string }> = [
  {
    type: DataRangeOptionType.ThisWeek,
    value: 'Текущая неделя'
  },
  {
    type: DataRangeOptionType.LastWeek,
    value: 'Прошлая неделя'
  },
  {
    type: DataRangeOptionType.ThisMonth,
    value: 'Текущий месяц'
  },
  {
    type: DataRangeOptionType.LastMonth,
    value: 'Прошлый месяц'
  },
  {
    type: DataRangeOptionType.Custom,
    value: 'Выбрать интервал'
  }
];
