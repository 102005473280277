import { ColumnTable, TableConfig, TableRecord } from '@common/types';
import { IColumnStoreConfig, ITableStoreConfig } from '@core/types/table-custom';
import { CELL_TYPE_MIN_WIDTH } from '@components/data-display/table/constants';

const getKey = <R>(col: ColumnTable<R>): string => {
  return col.key || col.dataIndex.toString() || '';
};

/**
 * Преобразование ColumnTable к IColumnStoreConfig, для хранения в сторе.
 * Хранит логику, что хранить в сторе для колонки таблицы
 */
//TODO
export const columnToSaveColumnConfig = <R>(column: ColumnTable<R>): IColumnStoreConfig<R> => {
  return {
    width: Number(column.width) || Number(column.style?.minWidth) || CELL_TYPE_MIN_WIDTH[column.type],
    active: column.active,
    key: getKey(column)
  };
};

/**
 * Преобразование TableConfig к ITableStoreConfig, для хранения в сторе.
 * Хранит логику, что хранить в сторе для таблицы
 */
export const configToSaveTableConfig = (config: TableConfig): ITableStoreConfig => {
  const columns = config?.columns.map((col) => columnToSaveColumnConfig(col));

  const childConfigs = Object.keys(config?.childConfigs || {}).reduce((acc, curr) => {
    const childConfig = config?.childConfigs?.[curr] && configToSaveTableConfig(config?.childConfigs[curr]);
    if (childConfig) acc[curr] = childConfig;
    return acc;
  }, {});

  return {
    pageSize: config.pageSize,
    columns,
    ...(Object.keys(childConfigs).length && { childConfigs }),
    grouping: config?.grouping || []
  } as unknown as ITableStoreConfig;
};
/**
 * Преобразование сохраненных настроек таблицы ITableConfig, к TableConfig
 * @param storedConfig - сохраненные данные в сторе
 * @param config - типовой конфиг
 */
export const storedConfigToTableConfig = (storedConfig: ITableStoreConfig, config: TableConfig): TableConfig => {
  let columns: Array<ColumnTable<TableRecord>> = [];
  if (!storedConfig?.columns || !storedConfig.columns.length) {
    columns = config?.columns || [];
  } else {
    columns = storedConfig.columns.reduce((acc, col) => {
      const configColumn = config?.columns.find((x) => getKey(x) === col.key);
      if (configColumn) acc.push({ ...configColumn, ...col });
      return acc;
    }, [] as Array<ColumnTable<TableRecord>>);

    //проверим что все колонки в конфиге отражены в storedConfig
    const hasNewColumns = config?.columns.every((col) => storedConfig.columns?.find((x) => x.key !== getKey(col)));

    //если есть новый колонки - добавим их
    if (hasNewColumns) {
      config?.columns.forEach((col, i) => {
        if (storedConfig.columns?.every((x) => x.key !== getKey(col))) {
          columns.splice(i, 0, col);
        }
      });
    }
  }

  return { ...(storedConfig.pageSize && { pageSize: storedConfig.pageSize }), columns, grouping: storedConfig.grouping || [] };
};
