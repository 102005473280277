import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { TenderState } from '@modules/tender-platform/types';
import { tenderOfferGetDriverRoutine } from '@modules/tender-platform/actions';
import { Driver } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderOfferDriverReducer = createReducer(initialState, {
  [tenderOfferGetDriverRoutine.REQUEST]: onRequest,
  [tenderOfferGetDriverRoutine.FAILURE]: onFailure,
  [tenderOfferGetDriverRoutine.FULFILL]: onFulfill,
  [tenderOfferGetDriverRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      drivers: Array<Driver>;
    }>
  ) => {
    const { drivers } = action.payload;
    state.currentTenderDrivers = drivers;
    return state;
  }
});
