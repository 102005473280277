import { ILSButtonTooltip } from '@components/index';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { projectDictionaryIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { Dictionary } from '@common/types';
import { selectedOrderTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { isEmpty } from 'lodash';

export const ILSApprovedOrders: FC<{ addOrderForVehicle(existTrip: boolean, tripIDForOrder?: number): void }> = ({
  addOrderForVehicle
}) => {
  const loading = useSelector((state: PlanningByDateState) => projectDictionaryIsFetchingSelector(state, Dictionary.Vehicle));
  const { selectedOrders } = useSelector(selectedOrderTableRowsSelector);

  const handleClick = () => addOrderForVehicle(false);

  return (
    <div className="ils-approved-orders">
      <ILSButtonTooltip
        title={'Добавить заявки в выбранный автомобиль'}
        loading={loading}
        className="ils-approve-item"
        icon={'approve-orders'}
        onClick={handleClick}
        disabled={isEmpty(selectedOrders)}
      >
        принять
      </ILSButtonTooltip>
    </div>
  );
};

