import { CellType, TableColumnName, TableConfig } from '@common/types';
import { COLOR_BLACK } from '@common/constants/colors';

//Пользователь может редактировать любые строки (TMS-5755)
const commonColumnProperties = { editable: true, active: true, ellipsis: true, sorting: 2 };

export const forwarderPointStatusesGroupTableConfig: TableConfig = {
  // Пользователь может добавлять строки (TMS-5755)
  adding: {
    Name: 'Новая группа статусов',
    SignColor: COLOR_BLACK
    // Icon:
  },
  // Пользователь может удалять строки (TMS-5755)
  deleting: true,
  configClassName: 'mobile-application-forwarder-point-statuses-table-config',
  columns: [
    { title: '№ БД', dataIndex: TableColumnName.ID, key: TableColumnName.ID, invisible: true, type: CellType.Input },
    {
      title: 'Название',
      // В поле "Название" допускается вводить любые символы - максимальная длина 30 (TMS-5745)
      type: CellType.Input,
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      maxLengthInput: 30,
      // width: 150
      ...commonColumnProperties
    },
    //В поле "Доступные статусы" допускается выбирать любое кол-во из списка статусов (TMS-5755)
    {
      title: 'Доступные статусы',
      type: CellType.SelectMultipleApi,
      dataIndex: TableColumnName.AvailableStatusesID,
      key: TableColumnName.AvailableStatusesID,
      // width: 150,
      ...commonColumnProperties
    }
  ]
};
