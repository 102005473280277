import { VehicleTableColumn } from '@common/types';
import { isArray } from 'lodash';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators/planner-planning-vehicles-to-table';
import { timeToDouble } from '@common/utils';

export const tableData2Vehicles = (
  record: ReturnType<typeof plannerPlanningVehiclesToTable>[number],
  dataIndex: VehicleTableColumn,
  value: string | Array<string | number> | null | number | undefined
) => {
  switch (dataIndex) {
    case VehicleTableColumn.StartTime:
      return timeToDouble(value as string);
    case VehicleTableColumn.StartDepotID:
    case VehicleTableColumn.EndDepotID:
      if (isArray(value)) {
        const onlyDepotID = value.length === 1;
        if (onlyDepotID) {
          const [depotId] = value;
          return depotId;
        } else {
          const [, depotId] = value;
          return depotId;
        }
      }
      return value;
    default:
      return value;
  }
};
