import { isNil } from 'lodash';
import { ILSSlider, ILSTypographyText } from '@common/components';
import { ParamsType, Provider } from '@common/types';
import { ExternalParamsConfig } from '@modules/planning/children/params/types/external';
import { useEffect } from 'react';

export const renderSlider = ({
  configItem,
  configKey,
  onChange,
  configParams
}: {
  configItem: ExternalParamsConfig & { type: ParamsType.Slider };
  configParams: Record<string, any>;
  configKey: Provider;
  onChange(key: string, newValue: any): void;
}) => {
  if (!configItem) return <></>;
  const value = !isNil(configParams?.[configKey]) ? configParams?.[configKey] : configItem.default;
  if (!configItem.range) return <></>;
  const [min, max, step] = configItem.range;
  const handleChange = (value: any) => onChange(configKey, value);
  return (
    <div key={configKey} className="pt-3">
      <ILSTypographyText title={configItem.label}>
        {configItem.label}: {value}
      </ILSTypographyText>
      <ILSSlider value={value} onChange={handleChange} defaultValue={value} min={min} max={max} step={step} included />
    </div>
  );
};

