import { Radio, RadioGroupProps, RadioProps } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { RadioCustomProps } from './types';
import { FC } from 'react';

export const ILSRadio: FC<RadioProps> = (props) => {
  return <Radio {...props} />;
};

export const ILSRadioGroup: FC<RadioGroupProps | RadioCustomProps> = (props) => {
  return <Radio.Group {...props} />;
};

export const ILSRadioButton: FC<RadioButtonProps> = (props) => {
  return <Radio.Button {...props} />;
};
