import { DeleteVehicleTrackerRequest } from '@common/types/dictionaries/monitoring';
import { MonitoringAPI } from '@modules/monitoring/api';
import { PayloadAction } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringDeleteVehicleTrackersRoutine } from '../actions';
import { monitoringDashboardAllUpdate } from '../children/dashboard/sagas/get-dashboard-all';

function* monitoringDeleteVehicleTrackerWorker(action: PayloadAction<DeleteVehicleTrackerRequest>) {
  const { request, success, failure, fulfill } = monitoringDeleteVehicleTrackersRoutine;

  const trackerRequest = action.payload;
  if (isNil(trackerRequest) || trackerRequest.IDs.length === 0) {
    yield put(success(trackerRequest));
  } else {
    try {
      yield put(request());
      yield call(MonitoringAPI.deleteVehicleTrackers, action.payload);
      yield put(success(action.payload));
      yield call(monitoringDashboardAllUpdate);

    } catch (error) {
      yield put(failure({ error }));
    } finally {
      yield put(fulfill());
    }
  }
}

export function* monitoringDeleteVehicleTrackerWatcher() {
  yield takeLatest(monitoringDeleteVehicleTrackersRoutine.trigger, monitoringDeleteVehicleTrackerWorker);
}
