import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { documentsIndexRoutine, resetError, setDocumentState, setDocumentType } from '@modules/documents/actions';
import { Document } from '@modules/documents/types/document';
import { FormField } from '@modules/documents/children/upload/types/form-field';
import { DocumentType } from '@modules/documents/types/document-type';
import { DocumentState } from '@modules/documents/types/document-state';

const documentIndex = createReducer(initialState, {
  [documentsIndexRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    state.data = undefined;
    return state;
  },
  [documentsIndexRoutine.SUCCESS]: (state, action: { payload: { data: Document[]; fields: FormField[]; documentType?: DocumentType } }) => {
    state.data = action.payload.data;
    if (action.payload?.documentType) {
      state.documentType = action.payload.documentType;
    }
    return state;
  },
  [documentsIndexRoutine.FAILURE]: (state) => {
    state.data = [];
    return state;
  },
  [documentsIndexRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  },
  [setDocumentType.type]: (state, action: { payload: DocumentType }) => {
    state.documentType = action.payload;
    return state;
  },
  [setDocumentState.type]: (state, action: { payload: DocumentState }) => {
    state.documentState = action.payload;
  },
  [resetError.type]: (state) => {
    state.error = [];
    return state;
  }
});

export default documentIndex;
