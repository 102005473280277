import { createSelector } from 'reselect';
import { tenderByStatusSelector } from '@modules/tender-platform/children/tender-own/selectors/tenders-by-status';
import { accountUsersSelector } from '@modules/tender-platform/selectors';
import { LIST_TABS_CARGO_OWNER, LIST_TABS_CARGO_OWNER_SIMPLE } from '@modules/tender-platform/constants/tender-card';

export const ownListSelector = createSelector(tenderByStatusSelector, accountUsersSelector, (tenderTabsByStatus, users) => {
  const isSimpleAccount = Object.keys(users).length === 1;
  const tenserTabs = isSimpleAccount ? LIST_TABS_CARGO_OWNER_SIMPLE : LIST_TABS_CARGO_OWNER;

  return {
    tenderTabsByStatus,
    tenserTabs
  };
});
