import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentSchemeRoutine, uploadSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISchemeEditorState, SchemeValues } from '@modules/planning/children/scheme-editor/types';
import { currentSchemeKeysInDictionariesSelector } from '@modules/planning/children/scheme-editor/selectors/current-scheme';
import { schemeInitialValuesSelector } from '@modules/planning/children/scheme-editor/selectors';
import { SchemeFormInitialValues } from '@modules/planning/children/scheme-editor/types/scheme';
import { getActiveSwitchedValues } from '@modules/planning/children/scheme-editor/utils/helpers/get-active-switched-values';
import { DataSchemeAPI } from '@modules/planning/children/scheme-editor/api';
import { getKeysFromValues, renameChangedKeysInValues } from '@modules/planning/children/scheme-editor/utils/helpers/rename-changed-keys';

function* uploadSchemeWorker(action: PayloadAction<SchemeValues>) {
  const { request, success, failure, fulfill } = uploadSchemeRoutine;
  const { values } = action.payload;

  try {
    yield put(request());
    const currentSchemeKeysInDictionaries: ISchemeEditorState['currentSchemeKeysInDictionaries'] = yield select(
      currentSchemeKeysInDictionariesSelector
    );
    const schemeInitialValues: SchemeFormInitialValues = yield select(schemeInitialValuesSelector);

    const { scheme } = getActiveSwitchedValues({
      values: renameChangedKeysInValues(values),
      schemeInitialValues,
      currentSchemeKeysInDictionaries: getKeysFromValues(values, currentSchemeKeysInDictionaries)
    });
    const payload = { scheme };
    const {
      data: { data }
    } = yield call(DataSchemeAPI.uploadScheme, payload);
    yield put(success({ message: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(getCurrentSchemeRoutine.trigger());
    yield put(fulfill());
  }
}

export function* uploadSchemeWatcher() {
  yield takeLatest(uploadSchemeRoutine.trigger, uploadSchemeWorker);
}
