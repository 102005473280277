import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { User } from '@common/types';
import { tenderOfferAppointResponsibleRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const appointResponsibleTenderOfferReducer = createReducer(initialState, {
  [tenderOfferAppointResponsibleRoutine.REQUEST]: onRequest,
  [tenderOfferAppointResponsibleRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      tenderOfferID: TenderOffer['ID'];
      userID: User['ID'];
    }>
  ) => {
    const { tenderOfferID, userID } = action.payload;
    if (state.tenders) {
      state.tenders = {
        ...state.tenders,
        [tenderOfferID]: { ...state.tenders[tenderOfferID], UserID: userID }
      };
    }
    return state;
  },
  [tenderOfferAppointResponsibleRoutine.FAILURE]: onFailure,
  [tenderOfferAppointResponsibleRoutine.FULFILL]: onFulfill
});
