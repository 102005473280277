import { SplitChar } from '@common/constants';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators';
import { ConvexPolygon, Depot, DepotTypeId, IMapTrack, IndexedArray, Keys, Order, ProjectState, Vehicle } from '@common/types';
import { PlanResponse } from '@common/types/dictionaries/plan';
import { createArrayFromIndexedArray, getLatLngExpression } from '@common/utils';
import { LatLngExpression } from 'leaflet';
import { isEmpty, isNil, last } from 'lodash';
import { MapWaypointData } from '../../types/map-container';

export const routeMap = (
  plan: PlanResponse,
  vehicles: ProjectState['Vehicle'],
  filteredVehicles: IndexedArray<Vehicle> | Array<Vehicle>,
  depots: ProjectState['Depot'],
  orders: ProjectState['Order'],
  planID: number
) => {
  const resolvedOrderIDs = new Set<Order['ID']>(),
    depotIDsFromWaypoint = new Set<Depot['ID']>(),
    vehicleInUse: Keys = [],
    track: IMapTrack[] = [],
    routeConvexPolygon: ConvexPolygon[] = [];

  const waypointMapData: Record<string, MapWaypointData> = {};

  let color: string;

  const { RouteTotal } = plan;
  const Routes = createArrayFromIndexedArray(RouteTotal);
  Routes.forEach((route) => {
    const vehicle = route?.Vehicle;
    const forbiddenZoneIDs = Object.keys(route?.ForbiddenZoneEnum ?? {}).map(Number); //TODO getNumberKeysFromEnum

    if (route.Vehicle.ID) {
      if (vehicles) {
        Object.values(vehicles).forEach((v) => {
          if (v.ID === route.Vehicle.ID) {
            if (v.ID && !vehicleInUse.includes(v.ID) && (!filteredVehicles || filteredVehicles[v.ID])) {
              vehicleInUse.push(v.ID);
            }
            // vehicle = v;
          }
        });
      }
    }
    color = getVehicleRouteColor(vehicle);
    const Trips = createArrayFromIndexedArray(route?.TripTotal);
    Trips?.forEach((trip) => {
      const convexTrip: LatLngExpression[] = [];

      const Waypoints = createArrayFromIndexedArray(trip?.WaypointTotal);

      Waypoints?.forEach((waypoint, tripIndex) => {
        const OrderAction = createArrayFromIndexedArray(waypoint.OrderActionTotal);
        const WPnumber = Number(last(waypoint.Number.split(SplitChar.Dot))) || undefined;
        if (!isEmpty(waypoint?.OrderActionTotal) && OrderAction.find((orderAction) => orderAction.ActionType === 0)) {
          if (waypoint.DepotID) {
            depotIDsFromWaypoint.add(waypoint.DepotID);
            if (depots) {
              const depot = depots[waypoint.DepotID];
              if (depot && !(depot.DepotTypeID === DepotTypeId.Storage && (tripIndex === 0 || tripIndex + 1 === Waypoints.length))) {
                depot.LatLon && convexTrip.push(getLatLngExpression(depot.LatLon) as LatLngExpression);
              }
            }
          }
        }

        const orderActionOrderIDs: (number | string)[] = [];
        OrderAction?.forEach((oa) => {
          if (oa.OrderID && !resolvedOrderIDs.has(Number(oa.OrderID))) {
            const order = orders && orders[oa.OrderID];
            if (order) {
              resolvedOrderIDs.add(Number(oa.OrderID));
            }
          }
          if (oa.OrderID && !orderActionOrderIDs.includes(oa.OrderID)) {
            orderActionOrderIDs.push(oa.OrderID);
          }
        });

        const prevPoint = !isNil(waypoint.PrevWaypointID) && trip?.WaypointTotal?.[waypoint.PrevWaypointID];
        const prevDepot = prevPoint && depots?.[prevPoint.DepotID];
        const prevCoord = prevDepot && prevDepot.LatLon ? prevDepot.LatLon : undefined;
        let trackData: IMapTrack['trackData'];
        if (trip.ID && vehicle?.ID && waypoint.WaypointID && plan.ProviderName) {
          trackData = {
            PlanID: planID,
            TripID: trip.ID,
            VehicleID: vehicle?.ID,
            WaypointID: waypoint.WaypointID,
            providerName: plan.ProviderName
          };
        }
        const orderForLine: Order[] = [];
        if (!isNil(orders) && !isEmpty(orders) && !isNil(orderActionOrderIDs) && !isEmpty(orderActionOrderIDs)) {
          orderActionOrderIDs.forEach((orID) => {
            const order = orders && orders?.[orID];
            if (order) {
              orderForLine.push({
                ...order,
                Client: order?.TargetID ? depots?.[order.TargetID] : undefined
              });
            }
          });
        }
        const depot = waypoint && waypoint.DepotID ? depots?.[waypoint.DepotID] : null;
        const sourceOrders = orderForLine.filter((orderItem) => orderItem.SourceID === depot?.ID).map((orderItem) => orderItem.ID) || [];
        const targetOrders = orderForLine.filter((orderItem) => orderItem.TargetID === depot?.ID).map((orderItem) => orderItem.ID) || [];
        waypointMapData[waypoint.WaypointID] = {
          PlanID: planID,
          TripID: trip.TripID,
          VehicleID: vehicle?.ID,
          WaypointID: waypoint.WaypointID,
          tripStatus: trip.Status,
          sourceOrders,
          targetOrders
        };
        track.push({
          color: color,
          coords: [],
          forbiddenZoneIDs,
          data: {
            order: orderForLine,
            depot,
            isHome: null
          },
          nativeCoords: {
            from: prevCoord ? prevCoord : undefined,
            to: waypoint.DepotID ? depots?.[waypoint!.DepotID]?.LatLon : undefined
          },
          vehicle: vehicle,
          TripID: trip.TripID,
          tripStatus: trip.Status,
          waypoint,
          trackData,
          orderID: orderActionOrderIDs,
          number: WPnumber
        });
      });

      routeConvexPolygon.push({
        coords: convexTrip,
        TripID: trip.TripID,
        VehicleID: vehicle?.ID,
        PlanID: planID,
        color: color
      });
    });
  });

  return {
    vehicleInUse,
    waypointMapData,
    resolvedOrderIDs,
    depotIDsFromWaypoint,
    track,
    routeConvexPolygon
  };
};
