import { MutableRefObject, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { latLngBounds } from 'leaflet';
import { ILSMap } from '@core/containers/map';
import { drawMarkers } from '@core/components/map/helpers/draw-marker';
import { Coordinates, MarkerType } from '@common/types';
import { getBoundsForMarkers } from '@common/utils';
import { DETAILS_CONTENT, DetailsMapLayers } from '@modules/orders-and-trips/types/details';
import { useSelector } from 'react-redux';
import { mapRoutesSelector } from '@modules/orders-and-trips/selectors/map';
import { activeTripSelector } from '@modules/orders-and-trips/selectors/trip';
import { drawPolylines } from '@core/components/map/helpers/draw-polylines';

type useShowDetailsMarkersEffect = (arg0: {
  Map: MutableRefObject<ILSMap | null>;
  mapLayers: DetailsMapLayers;
  mapPoints: Array<{ latLon: Coordinates; type: MarkerType }>;
  detailsContent: DETAILS_CONTENT;
}) => void;

// NOTE: если будет редактирование заявок рейса, необходимо проработать фокусировку маркеров при редактировании заявки
export const useShowMapDetails: useShowDetailsMarkersEffect = ({ Map, mapLayers, mapPoints, detailsContent }) => {
  const polylines = useSelector(mapRoutesSelector);
  const activeTrip = useSelector(activeTripSelector);

  const { isInit, markerLayer, trackLayer } = mapLayers;
  const markers = mapPoints?.map((point) => ({ coords: point.latLon, type: point.type }));
  useEffect(() => {
    if (Map.current && isInit) {
      markerLayer?.clearLayers();

      drawMarkers(Map, markers, markerLayer as L.LayerGroup);
      drawPolylines(Map, polylines, markerLayer as L.LayerGroup, trackLayer as L.LayerGroup);

      const markerCoordinates = getBoundsForMarkers(markers);

      if (!isEmpty(markerCoordinates)) {
        const boundEntity = latLngBounds(markerCoordinates);
        if (boundEntity.isValid()) {
          Map.current.fitBounds(boundEntity);
        }
      }
    }
  }, [markers, isInit, polylines, activeTrip, detailsContent]);
};

