import { Track } from '@modules/track/types/track';
import { waypointDecorator } from '@modules/track/decorators/waypoint';
import { IndexedArray, RouteVehicle, TripTotal } from '@common/types';

export const tripDecorator = (trips: IndexedArray<TripTotal> | null, vehicle: RouteVehicle): { tracks: Track[] } => {
  const tracksRoute: Track[] = [];

  for (const tripID in trips) {
    const { tracks } = waypointDecorator(trips[tripID].WaypointTotal, vehicle);
    tracks.forEach((track) => {
      tracksRoute.push(track);
    });
  }

  return { tracks: tracksRoute };
};
