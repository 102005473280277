import { Dictionary, TableColumnName, Vehicle } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils';
import { isNil } from 'lodash';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators/get-vehicle-route-color';

export const catalogVehicleToTable = (vehicles: Array<Vehicle> = []) => {
  return createArrayFromIndexedArray(vehicles).reduce((vehicles, vehicle: Vehicle) => {
    const rowParams = {
      isDeleted: vehicle?.Deleted
    };
    const isSpeedProfileDeleted = !isNil(vehicle.SpeedProfileDeleted);
    const isVehicleTariffDeleted = !isNil(vehicle.VehicleTariffDeleted);

    vehicles.push({
      ...vehicle,
      key: vehicle.ID,
      SpeedProfileID: isSpeedProfileDeleted ? null : vehicle.SpeedProfileID,
      SpeedProfileEnum: isSpeedProfileDeleted ? null : vehicle.SpeedProfileEnum,
      VehicleTariffID: isVehicleTariffDeleted ? null : vehicle.VehicleTariffID,
      VehicleTariffEnum: isVehicleTariffDeleted ? null : vehicle.VehicleTariffEnum,
      StartDepotID: vehicle.StartDepotID && [vehicle.StartDepot?.DepotTypeID, vehicle.StartDepotID],
      EndDepotID: vehicle.EndDepotID && [vehicle.EndDepot?.DepotTypeID, vehicle.EndDepotID],
      Deleted: Boolean(vehicle.Deleted),
      [TableColumnName.TripTemplateID]:
        vehicle.TripTemplateEnum && !Array.isArray(vehicle.TripTemplateEnum)
          ? Object.keys(vehicle.TripTemplateEnum).map((key) => parseInt(key))
          : [],
      TripTemplateEnum: vehicle.TripTemplateEnum,
      ForbiddenZones: vehicle.ForbiddenZoneID,
      RouteColor: getVehicleRouteColor(vehicle),
      dictionary: Dictionary.Vehicle,
      rowParams
    });
    return vehicles;
  }, [] as Array<any>);
};
