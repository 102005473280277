import { Dictionary, Zone, ZoneType } from "@common/types";
import { LayerData, LayerEntytiType } from "../types/layer";

export const CATALOG_DEFAULT_LAYERS: Array<LayerData> = [

  {
    ID: 6,
    name: 'Зоны доставки',
    active: false,
    entity: Dictionary.DeliveryZone,
    field: 'BorderHash',
    type: LayerEntytiType.Polygon,
    dictionary: Dictionary.Zone,
    filter: (item: Zone) => Boolean(item.Type === ZoneType.Delivery)
  },
  {
    ID: 7,
    name: 'Зоны наблюдения',
    active: false,
    entity: Dictionary.SurveillanceZone,
    field: 'BorderHash',
    type: LayerEntytiType.Polygon,
    dictionary: Dictionary.Zone,
    filter: (item: Zone) => Boolean(item.Type === ZoneType.Surveillance)
  }
];