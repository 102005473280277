import { DEPOT_TABLE_CONFIG } from '@common/models/depot/config';
import { depot2table } from '@common/models/depot/decorator';
import { Dictionary, Keys, ReferenceTables } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILSCatalogTable } from '../../containers/table-container';
import { ILSCatalogContact } from './contact';
import { CatalogFilterProject } from '@modules/catalog/containers/catalog-filter-project';
import { depotTableOptionsSelector } from '@modules/catalog/selectors/options';
import { ILSCatalogWorktime } from '@modules/catalog/components/tables/worktime';
import { ILSCatalogDepotOverload } from '@modules/catalog/components/tables/depot-overload';
import { canEditDepot } from '@common/models/depot/helpers/can-edit-depot';
import { checkCoordinates } from '@common/utils/table/check-coordinates';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { MessageContent } from '@common/constants';
import { HandleCheckCoordsArgType } from '@common/utils/table/handle-check-coords';
import { catalogBindTimeZoneRoutine, catalogGetCoordsRoutine } from '@modules/catalog/actions';
import { catalogDepotsSelector } from '@modules/catalog/selectors/options/options';
import { ICatalogFunctions } from '@modules/catalog/context';

export const DEPOT_REF_TABLE: ReferenceTables = {
  ContactID: {
    title: 'Контакты',
    component: ILSCatalogContact
  },
  WorktimeID: {
    title: 'Режим работы точки',
    component: ILSCatalogWorktime
  },
  DepotOverloadID: {
    title: 'Превышения клиента',
    component: ILSCatalogDepotOverload
  }
};

export const DEPOT_ACTION_TABLE_BUTTONS = [
  {
    Component: CatalogFilterProject,
    props: {
      dictionary: Dictionary.Depot
    }
  }
];

export const ILSCatalogDepot: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    const dispatch = useDispatch();
    const dictionary = Dictionary.Depot;

    const dataSource = useSelector(catalogDepotsSelector);

    const { refData } = useSelector(depotTableOptionsSelector);
    const handleBindTimeZone: ICatalogFunctions['handleBindTimeZone'] = (dictionary, depots) => {
      if (dictionary && depots?.length) {
        dispatch(catalogBindTimeZoneRoutine({ dictionary, depots }));
      }
    };
    const getCoordinates = (arg: HandleCheckCoordsArgType) => {
      dispatch(catalogGetCoordsRoutine(arg));
    };
    /**
     * Функция получения координат, в случае ошибки выводит ошибку,
     * получает координаты
     * если есть ошибка показываем уведомление с сообщением
     */

    const handleTableCheckCoords = (ids: Keys) => {
      try {
        checkCoordinates(ids, dataSource, dictionary, getCoordinates);
      } catch (error) {
        showUserAndDevError({ error, userError: MessageContent.SomethingWentWrong });
      }
    };

    /**
     * Функция получения временной зоны
     * Если указать recordIDs, то временная зона будет получена для указанных записей
     * Если не указать то для всех
     */
    const handleTableBindTimeZone = (recordIDs: Keys) => {
      if (recordIDs.length) {
        const records = dataSource.filter((item) => recordIDs.includes(item.ID));
        handleBindTimeZone(dictionary, records);
      } else {
        handleBindTimeZone(dictionary, dataSource);
      }
    };

    useEffect(() => {
      setMapProps?.({
        field: 'LatLon',
        type: 'marker'
      });
    }, []);

    return (
      <ILSCatalogTable
        {...props}
        handleCheckCoords={handleTableCheckCoords}
        handleBindTimeZone={handleTableBindTimeZone}
        actionTableButtons={DEPOT_ACTION_TABLE_BUTTONS}
        tableName={Dictionary.Depot}
        config={DEPOT_TABLE_CONFIG}
        setMapProps={setMapProps}
        dictionary={dictionary}
        dataDecorator={depot2table}
        handleDecorator={table2data}
        onCanEdit={canEditDepot}
        refTable={DEPOT_REF_TABLE}
        refData={refData}
      />
    );
  },
  DEPOT_TABLE_CONFIG,
  Dictionary.Depot,
  true
);
