import { PayloadAction } from '@reduxjs/toolkit';
import { getTemplateRoutine } from '@modules/documents/children/templates/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Moment } from 'moment';
import { prepareDateToServer } from '@common/utils';
import { DateFormat } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { getObjectWithoutNilValues } from '@common/utils/helpers/object/get-object-without-nil-values';
import { documentTemplatesTypeSelector } from '@modules/documents/children/templates/selectors';
import { DocumentType } from '@modules/documents/types/document-type';

const REPORT_PERIOD_FORMAT = DateFormat.DDMMYYYYPeriod;

const getPeriod = (period?: [Moment, Moment]) => {
  if (!period) return null;
  return `${prepareDateToServer(period[0], REPORT_PERIOD_FORMAT)}~${prepareDateToServer(period[1], REPORT_PERIOD_FORMAT)}`;
};

function* getTemplateWorker({
  payload: { templateId, deps, docId }
}: PayloadAction<{ docId?: number; templateId?: number; deps?: object & { Period?: [Moment, Moment] } }>) {
  const { request, success, failure, fulfill } = getTemplateRoutine;

  try {
    yield put(request());
    const type: { type: DocumentType | undefined } = yield select(documentTemplatesTypeSelector);
    const payload = getObjectWithoutNilValues({ ...deps, ...type, Period: getPeriod(deps?.Period) });

    const { data } = yield call(DocumentTemplatesAPI.getTemplate, templateId, docId, payload);

    yield put(success(data.data));
  } catch (e) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* getTemplateWatcher() {
  yield takeEvery(getTemplateRoutine.trigger, getTemplateWorker);
}

