import { ExportData } from './types';

//TODO:неплохо бы убрать хардкод и строить схему выгрузки исходя из данных бэка. Возможно на части фронтенда оставить только перевод на русский
const exportDataRows: {
  Depot: ExportData[];
  Driver: ExportData[];
  Zone: ExportData[];
  ForbiddenZone: ExportData[];
  SpeedProfile: ExportData[];
  TripTemplate: ExportData[];
  CrossDock: ExportData[];
  Vehicle: ExportData[];
  Restriction: ExportData[];
  TransportCompany: ExportData[];
} = {
  Depot: [
    { title: 'Код', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Тип точки', value: 'DepotType', key: 'DepotType' },
    { title: 'Описание', value: 'Description', key: 'Description' },
    { title: 'Адрес', value: 'AddressDescription', key: 'AddressDescription' },
    { title: 'Контакт', value: 'ClientContact', key: 'ClientContact' },
    {
      title: 'Контакты',
      value: 'Contact',
      key: 'Contact',
      children: [
        { title: 'Контакты/Название', value: 'Contact.Name', key: 'Contact.Name' },
        { title: 'Контакты/Эл.почта', value: 'Contact.Email', key: 'Contact.Email' },
        { title: 'Контакты/Номер телефона', value: 'Contact.Phone', key: 'Contact.Phone' },
        { title: 'Контакты/Комментарий', value: 'Contact.Comment', key: 'Contact.Comment' },
        { title: 'Контакты/Название компании', value: 'Contact.CompanyName', key: 'Contact.CompanyName' }
      ]
    },
    { title: 'Координаты', value: 'LatLon', key: 'LatLon' },
    { title: 'Проверенные координаты', value: 'LatLonVerified', key: 'LatLonVerified' },
    { title: 'Задержка', value: 'Delay', key: 'Delay', type: 'HH:MM' },
    { title: 'Можно оставлять прицеп', value: 'AllowKeepTrailer', key: 'AllowKeepTrailer' },
    {
      title: 'Режим работы',
      value: 'Worktime',
      key: 'Worktime',
      type: 'HH:MM',
      children: [
        { title: 'Режим работы/ID', value: 'Worktime.ID', key: 'Worktime.ID' },
        { title: 'Режим работы/Название', value: 'Worktime.Name', key: 'Worktime.Name', type: 'HH:MM' },
        { title: 'Режим работы/Начало погрузки', value: 'Worktime.LoadStart', key: 'Worktime.LoadStart', type: 'HH:MM' },
        { title: 'Режим работы/Конец погрузки', value: 'Worktime.LoadEnd', key: 'Worktime.LoadEnd', type: 'HH:MM' },
        {
          title: 'Режим работы/Начало разгрузки',
          value: 'Worktime.UnloadStart',
          key: 'Worktime.UnloadStart',
          type: 'HH:MM'
        },
        { title: 'Режим работы/Конец разгрузки', value: 'Worktime.UnloadEnd', key: 'Worktime.UnloadEnd', type: 'HH:MM' },
        { title: 'Режим работы/Начало обеда', value: 'Worktime.LunchStart', key: 'Worktime.LunchStart', type: 'HH:MM' },
        { title: 'Режим работы/Конец обеда', value: 'Worktime.LunchEnd', key: 'Worktime.LunchEnd', type: 'HH:MM' }
      ]
    },
    {
      title: 'Превышения клиента',
      value: 'DepotOverload',
      key: 'DepotOverload',
      children: [
        { title: 'Превышения клиента/Название', value: 'DepotOverload.Name', key: 'DepotOverload.Name' },
        {
          title: 'Превышения клиента/Прев.нач.разгрузки',
          value: 'DepotOverload.UnloadStartOverload',
          key: 'DepotOverload.UnloadStartOverload',
          type: 'HH:MM'
        },
        {
          title: 'Превышения клиента/Прев.конец.разгрузки',
          value: 'DepotOverload.UnloadEndOverload',
          key: 'DepotOverload.UnloadEndOverload',
          type: 'HH:MM'
        },
        {
          title: 'Превышения клиента/Прев.нач.погрузки',
          value: 'DepotOverload.LoadStartOverload',
          key: 'DepotOverload.LoadStartOverload',
          type: 'HH:MM'
        },
        {
          title: 'Превышения клиента/Прев.конец.погрузки',
          value: 'DepotOverload.LoadEndOverload',
          key: 'DepotOverload.LoadEndOverload',
          type: 'HH:MM'
        }
      ]
    },
    { title: 'Специфика', value: 'Restriction', key: 'Restriction' },
    { title: 'Расстояние до города', value: 'DistanceFromCity', key: 'DistanceFromCity' },
    { title: 'Радиус', value: 'Radius', key: 'Radius' },
    { title: 'Стоимость посещения', value: 'VisitPrice', key: 'VisitPrice' },
    { title: 'Очередь погр/разг', value: 'Queue', key: 'Queue' },
    { title: 'Значение 1', value: 'Value1', key: 'Value1' },
    { title: 'Значение 2', value: 'Value2', key: 'Value2' },
    { title: 'Значение 3', value: 'Value3', key: 'Value3' }
  ],
  Driver: [
    { title: 'Код', value: 'ID', key: 'ID' },
    { title: 'ФИО Водителя', value: 'Name', key: 'Name' },
    { title: 'Телефон водителя', value: 'Phone', key: 'Phone' },
    { title: 'Паспорт', value: 'PassportNumber', key: 'PassportNumber' },
    { title: 'Дата выдачи', value: 'PassportDate', key: 'PassportDate' },
    { title: 'Кем выдан', value: 'PassportIssuedBy', key: 'PassportIssuedBy' },
    { title: 'Водительское удостоверение', value: 'LicenseNumber', key: 'LicenseNumber' },
    { title: 'Тип', value: 'Type', key: 'Type' },
    { title: 'Специфики', value: 'Restriction', key: 'Restriction' },
    { title: 'Зоны доставки', value: 'Zone', key: 'Zone' },
    { title: 'Приоритет', value: 'Priority', key: '1Priority' },
    { title: 'Машина', value: 'Vehicle', key: '1Vehicle' },
    { title: 'Склад', value: 'Warehouse', key: '1Warehouse' },
    { title: 'Шаблон рейсов', value: 'TripTemplate', key: '1TripTemplate' },
    {
      title: 'Расписание работ',
      value: 'DriverTimeline',
      key: 'DriverTimeline',
      children: [
        { title: 'Расписание работ/Название', value: 'DriverTimeline.Name', key: 'DriverTimeline.Name' },
        { title: 'Расписание работ/Начало работы', value: 'DriverTimeline.StartTime', key: 'DriverTimeline.StartTime' },
        { title: 'Расписание работ/Дни недели', value: 'DriverTimeline.WeekDays', key: 'DriverTimeline.WeekDays' },
        { title: 'Расписание работ/Работа(дн.)', value: 'DriverTimeline.DaysOfWork', key: 'DriverTimeline.DaysOfWork' },
        { title: 'Расписание работ/Выходные(дн.)', value: 'DriverTimeline.DaysOfRest', key: 'DriverTimeline.DaysOfRest' },
        {
          title: 'Расписание работ/Макс.работа в неделю, ч',
          value: 'DriverTimeline.WorkPerWeek',
          key: 'DriverTimeline.WorkPerWeek'
        },
        {
          title: 'Расписание работ/Макс.работа за 2 недели, ч',
          value: 'DriverTimeline.WorkPerTwoWeek',
          key: 'DriverTimeline.WorkPerTwoWeek'
        }
      ]
    },
    {
      title: 'Режим работ авто',
      value: 'VehicleTimeline',
      key: 'VehicleTimeline',
      children: [
        { title: 'Режим работ авто/Название', value: 'VehicleTimeline.Name', key: 'VehicleTimeline.Name' },
        { title: 'Режим работ авто/Клиент шт', value: 'VehicleTimeline.MaxClients', key: 'VehicleTimeline.MaxClients' },
        { title: 'Режим работ авто/Заказов шт', value: 'VehicleTimeline.MaxOrders', key: 'VehicleTimeline.MaxOrders' },
        {
          title: 'Режим работ авто/Время в рейсе',
          type: 'HH:MM',
          value: 'VehicleTimeline.MaxDuration',
          key: 'VehicleTimeline.MaxDuration'
        },
        {
          title: 'Режим работ авто/Часов без отдыха',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfWork',
          key: 'VehicleTimeline.DurationOfWork'
        },
        {
          title: 'Режим работ авто/Часов отдыха',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfRest',
          key: 'VehicleTimeline.DurationOfRest'
        },
        {
          title: 'Режим работ авто/Работ.до пер.',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfFirstWork',
          key: 'VehicleTimeline.DurationOfFirstWork'
        },
        {
          title: 'Режим работ авто/Первый отдых',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfFirstRest',
          key: 'VehicleTimeline.DurationOfFirstRest'
        },
        {
          title: 'Режим работ авто/Работа в день',
          type: 'HH:MM',
          value: 'VehicleTimeline.WorkPerDay',
          key: 'VehicleTimeline.WorkPerDay'
        },
        {
          title: 'Режим работ авто/Режим отдыха',
          value: 'VehicleTimeline.DriverRestMode',
          key: 'VehicleTimeline.DriverRestMode'
        },
        {
          title: 'Режим работ авто/Км. в рейсе',
          value: 'VehicleTimeline.MaxDistance',
          key: 'VehicleTimeline.MaxDistance'
        },
        {
          title: 'Режим работ авто/Км. без отдыха',
          value: 'VehicleTimeline.DistanceOfWork',
          key: 'VehicleTimeline.DistanceOfWork'
        },
        {
          title: 'Режим работ авто/Время в маршруте',
          value: 'VehicleTimeline.MaxDurationRoute',
          key: 'VehicleTimeline.MaxDurationRoute'
        },
        {
          title: 'Режим работ авто/Км в маршруте',
          value: 'VehicleTimeline.MaxDistanceRoute',
          key: 'VehicleTimeline.MaxDistanceRoute'
        },
        { title: 'Режим работ авто/Рейсов, шт', value: 'VehicleTimeline.MaxTrips', key: 'VehicleTimeline.MaxTrips' },
        { title: 'Режим работ авто/Выезд', value: 'VehicleTimeline.MinStartTime', key: 'VehicleTimeline.MinStartTime' }
      ]
    }
  ],
  Zone: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Тип', value: 'Type', key: 'Type' },
    { title: 'Цвет', value: 'Color', key: 'Color' },
    { title: 'График', value: 'WorkSchedule', key: 'WorkSchedule' },
    { title: 'Тариф', value: 'Tariff', key: 'Tariff' },
    { title: 'Код', value: 'ZoneCode', key: 'ZoneCode' },
    { title: 'Описание', value: 'Description', key: 'Description' },
    { title: 'Дружественные зоны', value: 'FriendZoneID', key: 'FriendZoneID' },
    { title: 'Очередь', value: 'Queue', key: 'Queue' },
    { title: 'Границы зоны', value: 'BorderHash', key: 'BorderHash' }
  ],
  ForbiddenZone: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Цвет', value: 'Color', key: 'Color' },
    { title: 'Индекс зоны', value: 'Zone', key: 'Zone' },
    { title: 'GEO', value: 'Points', key: 'Points' }
  ],
  SpeedProfile: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Значение, км/ч', value: 'BaseValue', key: 'BaseValue' },
    { title: 'Автомагистрали, км/ч', value: 'Motorway', key: 'Motorway' },
    { title: 'Трассы, км/ч', value: 'Trunk', key: 'Trunk' },
    { title: 'Региональные, км/ч', value: 'Primary', key: 'Primary' },
    { title: 'Областные, км/ч', value: 'Secondary', key: 'Secondary' },
    { title: 'Важные, км/ч', value: 'Tertiary', key: 'Tertiary' },
    { title: 'Местные, км/ч', value: 'Residential', key: 'Residential' },
    { title: 'Обычные, км/ч', value: 'Road', key: 'Road' },
    { title: 'Неклассифицированные, км/ч', value: 'Unclassified', key: 'Unclassified' },
    { title: 'Служебные, км/ч', value: 'Service', key: 'Service' },
    { title: 'Дворы, км/ч', value: 'LivingStreet', key: 'LivingStreet' },
    { title: 'Съезды-Автомагистрали, км/ч', value: 'MotorwayLink', key: 'MotorwayLink' },
    { title: 'Съезды-Региональные, км/ч', value: 'PrimaryLink', key: 'PrimaryLink' },
    { title: 'Съезды-Областные, км/ч', value: 'SecondaryLink', key: 'SecondaryLink' },
    { title: 'Съезды-Важные, км/ч', value: 'TertiaryLink', key: 'TertiaryLink' },
    { title: 'Коэфф-база', value: 'BaseFactor', key: 'BaseFactor' },
    { title: 'Коэфф-будни', value: 'WeekdayFactor', key: 'WeekdayFactor' },
    { title: 'Коэфф-выходные', value: 'WeekendFactor', key: 'WeekendFactor' },
    { title: 'Коэфф-зона№1', value: 'Zone1Factor', key: 'Zone1Factor' },
    { title: 'Коэфф-зона№2', value: 'Zone2Factor', key: 'Zone2Factor' },
    { title: 'Коэфф-зона№3', value: 'Zone3Factor', key: 'Zone3Factor' },
    { title: 'Коэфф-зона№4', value: 'Zone4Factor', key: 'Zone4Factor' },
    { title: 'Коэфф-зона№5', value: 'Zone5Factor', key: 'Zone5Factor' },
    { title: 'Коэфф-зона№6', value: 'Zone6Factor', key: 'Zone6Factor' },
    { title: 'Коэфф-зона№7', value: 'Zone7Factor', key: 'Zone7Factor' },
    { title: 'Коэфф-зона№8', value: 'Zone8Factor', key: 'Zone8Factor' }
  ],
  TripTemplate: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Код', value: 'Code', key: 'Code' },
    { title: 'Стоимость', value: 'MinCost', key: 'MinCost' },
    { title: 'Кол-во точек', value: 'MinPoints', key: 'MinPoints' },
    { title: 'Режим использования', value: 'TemplateMode', key: 'TemplateMode' },
    { title: 'Усл.проверки', value: 'CondForMin', key: 'CondForMin' },
    { title: 'Цвет', value: 'Color', key: 'Color' }
  ],
  CrossDock: [
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Цвет', value: 'Color', key: 'Color' },
    { title: 'Точка', value: 'Depot', key: 'Depot' },
    { title: 'Масса в зоне', value: 'ZoneMass', key: 'ZoneMass' },
    { title: 'Объем в зоне', value: 'ZoneVolume', key: 'ZoneVolume' },
    { title: 'Паллеты в зоне', value: 'ZonePallets', key: 'ZonePallets' },
    { title: 'Масса в точке', value: 'ClientMass', key: 'ClientMass' },
    { title: 'Объем в точке', value: 'ClientVolume', key: 'ClientVolume' },
    { title: 'Паллеты в точке', value: 'ClientPallets', key: 'ClientPallets' },
    { title: 'Границы зоны', value: 'BorderHash', key: 'BorderHash' }
  ],
  Restriction: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    {
      title: 'Группы',
      value: 'RestrictionGroup',
      key: 'RestrictionGroup',
      children: [
        { title: 'Группы/ID', value: 'RestrictionGroup.ID', key: 'RestrictionGroup.ID' },
        { title: 'Группы/Название', value: 'RestrictionGroup.Name', key: 'RestrictionGroup.Name' }
      ]
    }
  ],
  Vehicle: [
    { title: 'ID', value: 'ID', key: 'ID' },
    { title: 'Название', value: 'Name', key: 'Name' },
    { title: 'Модель', value: 'Model', key: 'Model' },
    { title: 'Гос. номер', value: 'RegNumber', key: 'RegNumber' },
    { title: 'Цвет маршрута', value: 'RouteColor', key: 'RouteColor' },
    { title: 'Выезд из', value: 'StartDepotEnum', key: 'StartDepotEnum' },
    { title: 'Возврат в', value: 'EndDepotEnum', key: 'EndDepotEnum' },
    { title: 'Приоритет', value: 'Priority', key: 'Priority' },
    {
      title: 'Расписание работ',
      value: 'VehicleTimeline',
      key: 'VehicleTimeline',
      children: [
        { title: 'Расписание работ/Название', value: 'VehicleTimeline.Name', key: 'VehicleTimeline.Name' },
        { title: 'Расписание работ/Клиент шт', value: 'VehicleTimeline.MaxClients', key: 'VehicleTimeline.MaxClients' },
        { title: 'Расписание работ/Заказов шт', value: 'VehicleTimeline.MaxOrders', key: 'VehicleTimeline.MaxOrders' },
        {
          title: 'Расписание работ/Время в рейсе',
          type: 'HH:MM',
          value: 'VehicleTimeline.MaxDuration',
          key: 'VehicleTimeline.MaxDuration'
        },
        {
          title: 'Расписание работ/Часов без отдыха',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfWork',
          key: 'VehicleTimeline.DurationOfWork'
        },
        {
          title: 'Расписание работ/Часов отдыха',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfRest',
          key: 'VehicleTimeline.DurationOfRest'
        },
        {
          title: 'Расписание работ/Работ.до пер.',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfFirstWork',
          key: 'VehicleTimeline.DurationOfFirstWork'
        },
        {
          title: 'Расписание работ/Первый отдых',
          type: 'HH:MM',
          value: 'VehicleTimeline.DurationOfFirstRest',
          key: 'VehicleTimeline.DurationOfFirstRest'
        },
        {
          title: 'Расписание работ/Работа в день',
          type: 'HH:MM',
          value: 'VehicleTimeline.WorkPerDay',
          key: 'VehicleTimeline.WorkPerDay'
        },
        {
          title: 'Расписание работ/Режим отдыха',
          value: 'VehicleTimeline.DriverRestMode',
          key: 'VehicleTimeline.DriverRestMode'
        },
        {
          title: 'Расписание работ/Км. в рейсе',
          value: 'VehicleTimeline.MaxDistance',
          key: 'VehicleTimeline.MaxDistance'
        },
        {
          title: 'Расписание работ/Км. без отдыха',
          value: 'VehicleTimeline.DistanceOfWork',
          key: 'VehicleTimeline.DistanceOfWork'
        },
        {
          title: 'Расписание работ/Время в маршруте',
          value: 'VehicleTimeline.MaxDurationRoute',
          key: 'VehicleTimeline.MaxDurationRoute'
        },
        {
          title: 'Расписание работ/Км в маршруте',
          value: 'VehicleTimeline.MaxDistanceRoute',
          key: 'VehicleTimeline.MaxDistanceRoute'
        },
        { title: 'Расписание работ/Рейсов, шт', value: 'VehicleTimeline.MaxTrips', key: 'VehicleTimeline.MaxTrips' },
        { title: 'Расписание работ/Выезд', value: 'VehicleTimeline.MinStartTime', key: 'VehicleTimeline.MinStartTime' }
      ]
    },
    { title: 'Водитель', value: 'DriverEnum', key: 'DriverEnum' },
    { title: 'Экспедитор', value: 'ExpeditorEnum', key: 'ExpeditorEnum' },
    { title: 'Скорости', value: 'SpeedProfileEnum', key: 'SpeedProfileEnum' },
    { title: 'Тариф', value: 'VehicleTariffEnum', key: 'VehicleTariffEnum' },
    { title: 'Запрет', value: 'ForbiddenZones', key: 'ForbiddenZones' },
    { title: 'Шаблоны рейсов', value: 'TripTemplateEnum', key: 'TripTemplateEnum' },
    { title: 'Геозоны', value: 'FriendZoneEnum', key: 'FriendZoneEnum' },
    { title: 'В зоне', value: 'TakeOnlyOrdersInZones', key: 'TakeOnlyOrdersInZones' },
    { title: 'Доступность', value: 'ReleaseDate', key: 'ReleaseDate' },
    { title: 'Режим исп.', value: 'UseMode', key: 'UseMode' },
    { title: 'Cамоходное', value: 'IsAutomotive', key: 'IsAutomotive' },
    { title: 'Время выезда строго', value: 'StrictStartTime', key: 'StrictStartTime' },
    { title: 'Комментарий', value: 'Comment', key: 'Comment' },
    {
      title: 'Профиль',
      value: 'VehicleProfile',
      key: 'VehicleProfile',
      children: [
        { title: 'Профиль/ID', value: 'VehicleProfile.ID', key: 'VehicleProfile.ID' },
        { title: 'Профиль/Название', value: 'VehicleProfile.Name', key: 'VehicleProfile.Name' },
        { title: 'Профиль/Грузоподъемность, кг', value: 'VehicleProfile.MaxMass', key: 'VehicleProfile.MaxMass' },
        { title: 'Профиль/Объем кузова, м3', value: 'VehicleProfile.MaxVolume', key: 'VehicleProfile.MaxVolume' },
        { title: 'Профиль/Паллет, шт', value: 'VehicleProfile.MaxPallets', key: 'VehicleProfile.MaxPallets' },
        { title: 'Профиль/Коробок шт.', value: 'VehicleProfile.MaxBoxes', key: 'VehicleProfile.MaxBoxes' },
        { title: 'Профиль/Стоимость, руб', value: 'VehicleProfile.MaxCost', key: 'VehicleProfile.MaxCost' },
        { title: 'Профиль/Длина кузова, мм', value: 'VehicleProfile.MaxUnitLength', key: 'VehicleProfile.MaxUnitLength' },
        { title: 'Профиль/Ширина кузова, мм', value: 'VehicleProfile.MaxUnitWidth', key: 'VehicleProfile.MaxUnitWidth' },
        {
          title: 'Профиль/Высота кузова, мм',
          value: 'VehicleProfile.MaxUnitHeight',
          key: 'VehicleProfile.MaxUnitHeight'
        },
        { title: 'Профиль/Марка', value: 'VehicleProfile.CarMark', key: 'VehicleProfile.CarMark' },
        {
          title: 'Профиль/Средняя скорость, км/ч',
          value: 'VehicleProfile.AverageSpeed',
          key: 'VehicleProfile.AverageSpeed'
        },
        {
          title: 'Профиль/Расход топлива',
          value: 'VehicleProfile.FuelConsumption',
          key: 'VehicleProfile.FuelConsumption'
        },
        {
          title: 'Профиль/Длительность погрузки',
          value: 'VehicleProfile.LoadDuration',
          key: 'VehicleProfile.LoadDuration'
        },
        {
          title: 'Профиль/Длительность разгрузки',
          value: 'VehicleProfile.UnloadDuration',
          key: 'VehicleProfile.UnloadDuration'
        }
      ]
    },
    {
      title: 'Превышения',
      value: 'VehicleOverload',
      key: 'VehicleOverload',
      children: [
        { title: 'Превышения/ID', value: 'VehicleOverload.ID', key: 'VehicleOverload.ID' },
        { title: 'Превышения/Название', value: 'VehicleOverload.Name', key: 'VehicleOverload.Name' },
        {
          title: 'Превышения/Клиенты, шт',
          value: 'VehicleOverload.MaxClientsOverload',
          key: 'VehicleOverload.MaxClientsOverload'
        },
        {
          title: 'Превышения/Заказы, шт',
          value: 'VehicleOverload.MaxOrdersOverload',
          key: 'VehicleOverload.MaxOrdersOverload'
        },
        {
          title: 'Превышения/Время рейса',
          value: 'VehicleOverload.MaxDurationOverload',
          key: 'VehicleOverload.MaxDurationOverload'
        },
        {
          title: 'Превышения/Пробег рейса, км',
          value: 'VehicleOverload.MaxDistanceOverload',
          key: 'VehicleOverload.MaxDistanceOverload'
        },
        {
          title: 'Превышения/Время маршрута',
          value: 'VehicleOverload.MaxDurationRouteOverload',
          key: 'VehicleOverload.MaxDurationRouteOverload'
        },
        {
          title: 'Превышения/Пробег маршрута, км',
          value: 'VehicleOverload.MaxDistanceRouteOverload',
          key: 'VehicleOverload.MaxDistanceRouteOverload'
        },
        {
          title: 'Превышения/Рейсы, шт',
          value: 'VehicleOverload.MaxTripsOverload',
          key: 'VehicleOverload.MaxTripsOverload'
        },
        {
          title: 'Превышения/Прев. выезда',
          value: 'VehicleOverload.MinStartTimeOverload',
          key: 'VehicleOverload.MinStartTimeOverload'
        },
        { title: 'Превышения/Вес, кг', value: 'VehicleOverload.MaxMassOverload', key: 'VehicleOverload.MaxMassOverload' },
        {
          title: 'Превышения/Объём, м3',
          value: 'VehicleOverload.MaxVolumeOverload',
          key: 'VehicleOverload.MaxVolumeOverload'
        },
        {
          title: 'Превышения/Паллет, шт',
          value: 'VehicleOverload.MaxPalletsOverload',
          key: 'VehicleOverload.MaxPalletsOverload'
        },
        {
          title: 'Превышения/Коробок, шт',
          value: 'VehicleOverload.MaxBoxesOverload',
          key: 'VehicleOverload.MaxBoxesOverload'
        },
        {
          title: 'Превышения/Стоимость, руб',
          value: 'VehicleOverload.MaxCostOverload',
          key: 'VehicleOverload.MaxCostOverload'
        },
        {
          title: 'Превышения/Длина, мм',
          value: 'VehicleOverload.MaxUnitLengthOverload',
          key: 'VehicleOverload.MaxUnitLengthOverload'
        },
        {
          title: 'Превышения/Ширина, мм',
          value: 'VehicleOverload.MaxUnitWidthOverload',
          key: 'VehicleOverload.MaxUnitWidthOverload'
        },
        {
          title: 'Превышения/Высота, мм',
          value: 'VehicleOverload.MaxUnitHeightOverload',
          key: 'VehicleOverload.MaxUnitHeightOverload'
        }
      ]
    }
    //TODO это пока не поддерживается бэком необходимо изменить ключи value у CouplingType и добавить возможность экспорта RoadTrain
    // {
    //   title: 'Перед. сцепка',
    //   value: 'CouplingTypeFront',
    //   key: 'CouplingTypeFront',
    //   children: [
    //     { title: 'Перед. сцепка/ID', value: 'CouplingTypeFront.ID', key: 'CouplingTypeFront' },
    //     { title: 'Перед. сцепка/Название', value: 'CouplingTypeFront.Name', key: 'CouplingTypeFront' }
    //   ]
    // },
    // {
    //   title: 'Задняя сцепка',
    //   value: 'CouplingTypeBack',
    //   key: 'CouplingTypeBack',
    //   children: [
    //     { title: 'Задняя сцепка/ID', value: 'CouplingTypeBack.ID', key: 'CouplingTypeBack' },
    //     { title: 'Задняя сцепка/Название', value: 'CouplingTypeBack.Name', key: 'CouplingTypeBack' }
    //   ]
    // },

    // {
    //   title: 'Редакт. автосцепки',
    //   value: 'RoadTrain',
    //   key: 'RoadTrain',
    //   children: [
    //     { title: 'Редакт. автосцепки/ID', value: 'RoadTrain.ID', key: 'RoadTrain' },
    //     { title: 'Редакт. автосцепки/Прицеп', value: 'RoadTrain.Trailer1ID', key: 'RoadTrain' },
    //     { title: 'Редакт. автосцепки/Прицеп 2', value: 'RoadTrain.Trailer2ID', key: 'RoadTrain' },
    //     { title: 'Редакт. автосцепки/Код', value: 'RoadTrain.RoadTrainCode', key: 'RoadTrain' }
    //   ]
    // }
  ],
  TransportCompany: [
    { title: 'ID компании', value: 'ID', key: 'ID' },
    { title: 'Наименование', value: 'Name', key: 'Name' },
    { title: 'Код компании', value: 'TransportCompanyCode', key: 'TransportCompanyCode' },
    { title: 'ИНН', value: 'INN', key: 'INN' },
    { title: 'КПП', value: 'KPP', key: 'KPP' },
    { title: 'ОГРН', value: 'OGRN', key: 'OGRN' },
    { title: 'ОКПО', value: 'OKPO', key: 'OKPO' },
    { title: 'OKATO', value: 'OKATO', key: 'OKATO' },
    { title: 'Адрес', value: 'Address', key: 'Address' },
    { title: 'Email', value: 'Email', key: 'Email' },
    { title: 'Телефон', value: 'Phone', key: 'Phone' },
    { title: 'Комментарий', value: 'Comment', key: 'Comment' },
    { title: 'Корресподентский счет', value: 'CorrespondentAccount', key: 'CorrespondentAccount' },
    { title: 'Рассчетный счет', value: 'CheckingAccount', key: 'CheckingAccount' },
    { title: 'Банк', value: 'Bank', key: 'Bank' },
    { title: 'БИК', value: 'BIK', key: 'BIK' },
    {
      title: 'Водитель',
      value: 'Driver',
      key: 'Driver',
      children: [
        { title: 'Водитель/Код', value: 'Driver.ID', key: 'Driver.ID' },
        { title: 'Водитель/ФИО Водителя', value: 'Driver.Name*', key: 'Driver.Name' },
        { title: 'Водитель/Телефон водителя', value: 'Driver.Phone*', key: 'Driver.Phone' },
        { title: 'Водитель/Паспорт', value: 'Driver.PassportNumber', key: 'Driver.PassportNumber' },
        { title: 'Водитель/Дата выдачи', value: 'Driver.PassportDate', key: 'Driver.PassportDate' },
        { title: 'Водитель/Кем выдан', value: 'Driver.PassportIssuedBy', key: 'Driver.PassportIssuedBy' },
        { title: 'Водитель/Водительское удостоверение', value: 'Driver.LicenseNumber', key: 'Driver.LicenseNumber' },
        { title: 'Водитель/Дата выдачи ВУ', value: 'Driver.LicenseDate', key: 'Driver.LicenseDate' },
        { title: 'Водитель/Рейтинг', value: 'Driver.DriverRating', key: 'Driver.DriverRating' }
      ]
    },
    {
      title: 'Автомобиль',
      value: 'Vehicle',
      key: 'Vehicle',
      children: [
        { title: 'Автомобиль/ID', value: 'Vehicle.ID', key: 'Vehicle.ID' },
        { title: 'Автомобиль/Название', value: 'Vehicle.Name', key: 'Vehicle.Name' },
        { title: 'Автомобиль/Модель', value: 'Vehicle.Model', key: 'Vehicle.Model' },
        { title: 'Автомобиль/Гос. номер', value: 'Vehicle.RegNumber', key: 'Vehicle.RegNumber' },
        { title: 'Автомобиль/Выезд из', value: 'Vehicle.StartDepotEnum', key: 'Vehicle.StartDepotEnum' },
        { title: 'Автомобиль/Возврат в', value: 'Vehicle.EndDepotEnum', key: 'Vehicle.EndDepotEnum' },
        { title: 'Автомобиль/Приоритет', value: 'Vehicle.Priority', key: 'Vehicle.Priority' }
      ]
    }
  ]
};

export { exportDataRows };

