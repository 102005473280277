import { isMoment } from 'moment';
import { ILS_MAIN_API_DATE_FORMAT } from '@common/types';
import { ILS_MAIN_API_TIME_FORMAT } from '@common/types/general/date-time';

export const convertMomentInString = (payload: object) => {
  for (const field in payload) {
    if (isMoment(payload[field])) {
      if (field.includes('Date')) {
        payload[field] = payload[field].format(ILS_MAIN_API_DATE_FORMAT);
      } else {
        payload[field] = payload[field].format(ILS_MAIN_API_TIME_FORMAT);
      }
    }
  }
};
