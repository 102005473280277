import React, { useEffect, useState } from 'react';
import { WidgetCoords, WidgetPosition, WidgetSize } from '../types/widget-position';
import { useRaf } from '@modules/analytics/common/hooks/use-raf';
import { RESIZABLE_Z_INDEX } from '@modules/analytics/common/constants';
type ResizeProps = {
  widgetSize: WidgetSize;
  widgetPosition: WidgetPosition;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  parent?: HTMLElement | null;
  onRelease?: () => void;
  minSizeX?: number;
  minSizeY?: number;
  isFixed?: boolean;
};

type DragDirection = 'right' | 'top' | 'left' | 'bottom' | 'drag';

/**
 * @widgetSize - размер виджета в процентах
 * @widgetPosition - положение левого верхнего угла в процентах от родительского контейнера
 * @parent родительский контейнер
 * @isFixed -если true - отключает перемещения
 * Возвращает
 * обработчики события "onPointerDown": pointerDrag, pointerRight, pointerBottom, pointerLeft, pointerTop
 *  @positionAndSize - новое положение и размер виджета
 */
const useResize = (props: ResizeProps) => {
  const { ref, widgetSize, widgetPosition, parent, onRelease = () => {}, minSizeX = 15, minSizeY = 15, isFixed = false } = props;
  const [initial, setInitial] = useState<{ direction: DragDirection; pos: WidgetCoords; size: WidgetSize; offset: WidgetPosition } | null>(
    null
  );

  const [parentSize, setParentSize] = useState<WidgetSize>({ x: 0, y: 0 });
  const [isChanged, setIsChanged] = useState(false);
  const [positionAndSize, setPositionAndSize] = useState<{ size: WidgetSize; position: WidgetPosition }>({
    size: widgetSize,
    position: widgetPosition
  });

  //initial widget position and size
  useEffect(() => {
    setPositionAndSize({ size: widgetSize, position: widgetPosition });
  }, []);

  //parent div size
  useEffect(() => {
    setParentSize({ x: parent?.offsetWidth || 1000, y: parent?.offsetHeight || 1000 });
  }, [parent?.offsetWidth, parent?.offsetHeight]);

  const roundPercent = (p: number) => {
    return Number(p.toFixed(5));
  };

  //методы пересчета смещения в пикселях в проценты относительно рабочего окна.
  const toPercentX = (x: number) => {
    return roundPercent((x / parentSize.x) * 100);
  };
  const toPercentY = (y: number) => {
    return roundPercent((y / parentSize.y) * 100);
  };
  const resizedWidth = (x: number) => {
    let w = toPercentX(x);
    const newWidth = w + positionAndSize.position.left > 100 ? 100 - positionAndSize.position.left : w;
    return newWidth > minSizeX ? newWidth : minSizeX;
  };
  const movedX = (left: number, checkMinSize: boolean = true, newPosition: { size: WidgetSize; position: WidgetPosition }) => {
    if (left < 0) return 0;
    let l = toPercentX(left);
    //проверка на границу доски по x
    l = l + newPosition.size.x > 100 ? 100 - newPosition.size.x : l;
    //проверка на минимальный размер виджета
    if (checkMinSize && newPosition.size.x === minSizeX && left >= newPosition.position.left) {
      l = newPosition.position.left;
    }
    return l;
  };
  const movedY = (top: number, checkMinSize: boolean = true, newPosition: { size: WidgetSize; position: WidgetPosition }) => {
    if (top < 0) return 0;
    let t = toPercentY(top);
    t = t + newPosition.size.y > 100 ? 100 - newPosition.size.y : t;
    if (checkMinSize && newPosition.size.y === minSizeY && top >= newPosition.position.top) {
      t = newPosition.position.top;
    }
    return t;
  };
  const resizedHeight = (y: number) => {
    let h = toPercentY(y);
    const newHeight = h + positionAndSize.position.top > 100 ? 100 - positionAndSize.position.top : h;
    return newHeight > minSizeY ? newHeight : minSizeY;
  };

  /**метод вычисления нового положения и размера виджета
   * xMove, yMove - координаты положения курсора.Если по координате нет изменений ее значение -undefined
   * resize - признак идет ли изменение размера виджета
   * move - признак идет изменения положения виджета
   * */
  const resize = (props: { xMove?: number; yMove?: number; resize?: boolean; move?: boolean }) => {
    const { xMove, yMove, resize, move } = props;
    let resizable = ref.current;
    //если  resize && mov === true -значит у виджета уменьшается размер (когда тянем с левый края в вправо или за верхний вниз
    //direction -направление изменение размера
    const direction = resize && move ? -1 : 1;
    let newPosition = { ...positionAndSize };
    if (!initial) return;

    let isMinWidth = false;
    let isMinHeight = false;

    if (resizable) {
      //у перетаскиваемого виджета устанавливаем z-index, чтобы он был поверх остальных
      if (resize || move) {
        resizable.style.zIndex = RESIZABLE_Z_INDEX.toString();
      }
      //если у компонента меняется размер
      if (resize) {
        //если меняется координата x
        if (xMove) {
          if (initial.direction !== 'left' || newPosition.position.left > 0 || xMove > initial.pos.x) {
            const width = resizedWidth(initial.size.x + (xMove - initial.pos.x) * direction);
            if (minSizeX !== width) {
              newPosition.size.x = width;
              resizable.style.width = `${width}%`;
            } else {
              isMinWidth = true;
            }
          }
        }
        //если меняется координата y
        if (yMove) {
          if (initial.direction !== 'top' || newPosition.position.top > 0 || yMove > initial.pos.y) {
            const height = resizedHeight(initial.size.y + (yMove - initial.pos.y) * direction);
            if (minSizeY !== height) {
              newPosition.size.y = height;
              resizable.style.height = `${height}%`;
            } else {
              isMinHeight = true;
            }
          }
        }
      }
      //если есть перемещение левого края и верхнего
      if (move) {
        if (xMove && !isMinWidth) {
          const left = movedX(initial.offset.left + xMove - initial.pos.x, resize, newPosition);
          newPosition.position.left = left;
          resizable.style.left = `${left}%`;
        }
        if (yMove && !isMinHeight) {
          const top = movedY(initial.offset.top + yMove - initial.pos.y, resize, newPosition);
          newPosition.position.top = top;
          resizable.style.top = `${top}%`;
        }
      }
      setPositionAndSize(newPosition);
    }
  };

  const rafResize = useRaf(resize);
  //подписка на события курсора после первичного нажатия
  useEffect(() => {
    if (initial) {
      const moveCallback = getCallback(initial.direction);
      document.addEventListener('pointermove', moveCallback);
      document.addEventListener('pointerup', release);
      document.addEventListener('pointercancel', cancel);
      return () => {
        document.removeEventListener('pointermove', moveCallback);
        document.removeEventListener('pointerup', release);
        document.removeEventListener('pointercancel', cancel);
      };
    }
  }, [initial]);

  // handlers for pointer move events
  const getCallback = (direction?: DragDirection) => {
    switch (direction) {
      case 'right':
        return resizeRight;
      case 'left':
        return resizeLeft;
      case 'top':
        return resizeTop;
      case 'bottom':
        return resizeBottom;
      case 'drag':
        return drag;
      default:
        return () => {};
    }
  };

  const beforeMove = (direction: DragDirection, ev: React.PointerEvent) => {
    let resizable = ref.current;
    setIsChanged(true);
    if (resizable) {
      setInitial({
        size: { x: resizable.offsetWidth, y: resizable.offsetHeight },
        offset: { left: resizable.offsetLeft, top: resizable.offsetTop },
        pos: { x: ev.clientX, y: ev.clientY },
        direction: direction
      });
    }
  };

  //отмена последнего изменения положения
  const undo = () => {
    let resizable = ref.current;
    if (!resizable) return;
    let newPosition = { ...positionAndSize };
    const left = toPercentX(initial?.offset.left || 0);
    const top = toPercentY(initial?.offset.top || 0);
    const width = toPercentX(initial?.size.x || 0);
    const height = toPercentY(initial?.size.y || 0);

    resizable.style.left = `${left}%`;
    resizable.style.top = `${top}%`;
    resizable.style.width = `${width}%`;
    resizable.style.height = `${height}%`;
    //сбрасываем z-index
    resizable.style.zIndex = `auto`;
    newPosition.position.left = left;
    newPosition.position.top = top;
    newPosition.size.x = width;
    newPosition.size.y = height;
    setPositionAndSize(newPosition);
  };

  const resizeRight = (e: PointerEvent) => {
    rafResize({ xMove: e.clientX, resize: true, move: false });
  };

  const resizeBottom = (e: PointerEvent) => {
    rafResize({ yMove: e.clientY, resize: true, move: false });
  };

  const resizeLeft = (e: PointerEvent) => {
    rafResize({ xMove: e.clientX, resize: true, move: true });
  };

  const resizeTop = (e: PointerEvent) => {
    rafResize({ yMove: e.clientY, resize: true, move: true });
  };

  const drag = (e: PointerEvent) => {
    rafResize({ yMove: e.clientY, xMove: e.clientX, resize: false, move: true });
  };

  const release = (e: PointerEvent) => {
    onRelease();
    rafResize.cancel();
    document.removeEventListener('pointermove', getCallback(initial?.direction));
    document.removeEventListener('pointerup', release);
    document.removeEventListener('pointercancel', cancel);
    let resizable = ref.current;
    //сбрасываем z-index
    if (resizable) resizable.style.zIndex = `auto`;
  };

  const cancel = (e: PointerEvent) => {
    document.removeEventListener('pointermove', getCallback(initial?.direction));
    document.removeEventListener('pointerup', release);
    document.removeEventListener('pointercancel', cancel);
  };

  //handlers for pointerdown
  const pointerRight = (ev: React.PointerEvent) => {
    beforeMove('right', ev);
    ev.preventDefault();
  };

  const pointerLeft = (ev: React.PointerEvent) => {
    beforeMove('left', ev);
    ev.preventDefault();
  };

  const pointerTop = (ev: React.PointerEvent) => {
    beforeMove('top', ev);
    ev.preventDefault();
  };

  const pointerBottom = (ev: React.PointerEvent) => {
    beforeMove('bottom', ev);
    ev.preventDefault();
  };

  const pointerDrag = (ev: React.PointerEvent) => {
    if (!isFixed) {
      beforeMove('drag', ev);
      ev.preventDefault();
    }
  };

  return { undo, pointerDrag, pointerRight, pointerBottom, pointerLeft, pointerTop, positionAndSize, isChanged };
};

export default useResize;
