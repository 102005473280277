import L from 'leaflet';
import { DRAW_LOCAL } from '@core/constants';
import 'leaflet-draw';

export type DrawPluginOptions = {
  layersToEdit?: Array<L.Layer>; // слои которые можно будет изменять
  onLayerChange?: (layers: { [K: string]: L.Layer }) => void; // callback при изменении слоя
  onLayerCreate?: (targetLayer: L.FeatureGroup) => void; // callback при создании нового слоя
  onEditStop?: (resp: string) => void; // callback на завершение редактирования
  drawToolbarOption?: any;
  // layerType?: 'Polygon' | 'Polyline' | 'Marker'
};
export type LControlDrawExt = L.Control.Draw & {
  clearListener: () => void;
  activeEdit: () => boolean | undefined;
  drawPolygon: () => boolean | undefined;
  drawMarker: () => boolean | undefined;
  getEditLayerGroup: () => L.FeatureGroup;
};

type ControlDrawWithHiddenProps = L.Control.Draw & {
  _toolbars?: {
    edit?: {
      _modes?: {
        edit?: {
          handler?: {
            enable: () => void;
            enabled: () => boolean;
          };
        };
      };
    };
    draw?: {
      _modes?: {
        polygon?: {
          handler?: {
            enable: () => void;
            enabled: () => boolean;
          };
        };
        marker?: {
          handler?: {
            enable: () => void;
            enabled: () => boolean;
          };
        };
      };
    };
  };
};

export const drawPlugin = (map: L.Map, editLayerGroup: L.FeatureGroup, options: DrawPluginOptions): LControlDrawExt => {
  editLayerGroup.addTo(map);
  editLayerGroup.clearLayers();

  if (options.layersToEdit?.length) {
    options.layersToEdit.forEach((editLayer) => {
      editLayer.addTo(editLayerGroup);
    });
  }

  //замена иконки маркера по умолчанию
  //Если разрешено добавление маркеров и не установлена другая иконка
  if (
    (options.drawToolbarOption.draw &&
      !options.drawToolbarOption.draw.marker &&
      Object.values(options.drawToolbarOption.draw).every((item) => !item)) ||
    (options.drawToolbarOption.draw.marker && !options.drawToolbarOption.draw.marker.icon)
  ) {
    if (typeof options.drawToolbarOption.draw.marker === 'object') {
      options.drawToolbarOption.draw.marker = { ...DRAW_LOCAL.draw.marker, ...options.drawToolbarOption.draw.marker };
    } else {
      options.drawToolbarOption.draw.marker = DRAW_LOCAL.draw.marker;
    }
  }
  const drawControl = new L.Control.Draw({
    ...DRAW_LOCAL,
    ...options.drawToolbarOption,
    position: 'bottomleft',
    edit: {
      ...options.drawToolbarOption.edit,
      featureGroup: editLayerGroup
    }
  });
  map.addControl(drawControl);

  drawControl.getContainer()?.classList.add('map-control-center-bottom');

  const changeControlPosition = () => {
    const { width } = map.getContainer()?.getBoundingClientRect();
    const drawContainer = drawControl.getContainer();
    if (width && drawContainer) {
      drawContainer.style.left = `${width / 2}px`;
    }
  };
  changeControlPosition();
  map.on('resize', changeControlPosition);
  const drawEvents = initDrawEventHandlers(
    {
      [L.Draw.Event.CREATED]: function (event: { layer: any }) {
        const newLayer = event.layer;

        const layerAfter = editLayerGroup.addLayer(newLayer);
        if (layerAfter && options.onLayerCreate) {
          options.onLayerCreate(layerAfter);
        }
      },
      [L.Draw.Event.EDITED]: (e: { layers: { _layers?: { [K: string]: L.Layer } } }) => {
        if (options?.onLayerChange && e.layers._layers) {
          options.onLayerChange(e.layers._layers);
        }
      },
      [L.Draw.Event.EDITSTOP]: (e: string) => {
        options.onEditStop && options.onEditStop(e);
      }
    },
    map
  );
  //Включает редактирование слоев
  const activeEdit = () => {
    if (drawControl) {
      (drawControl as ControlDrawWithHiddenProps)?._toolbars?.edit?._modes?.edit?.handler?.enable();
      return (drawControl as ControlDrawWithHiddenProps)?._toolbars?.edit?._modes?.edit?.handler?.enabled();
    }
  };
  //Активирует возможность нарисовать полигон
  //необходимо, чтобы drawToolbarOption.draw?.polygon !== false
  const drawPolygon = () => {
    if (drawControl) {
      (drawControl as ControlDrawWithHiddenProps)?._toolbars?.draw?._modes?.polygon?.handler?.enable();
      return (drawControl as ControlDrawWithHiddenProps)?._toolbars?.draw?._modes?.polygon?.handler?.enabled();
    }
  };

  const drawMarker = () => {
    if (drawControl) {
      (drawControl as ControlDrawWithHiddenProps)?._toolbars?.draw?._modes?.marker?.handler?.enable();
      return (drawControl as ControlDrawWithHiddenProps)?._toolbars?.draw?._modes?.marker?.handler?.enabled();
    }
  };

  const getEditLayerGroup = () => {
    return editLayerGroup;
  };
  let drawControlExt = drawControl as LControlDrawExt;
  drawControlExt.clearListener = () => {
    drawEvents.destroy();
    if (map) map.off('resize', changeControlPosition);
  };
  drawControlExt.activeEdit = activeEdit;
  drawControlExt.drawPolygon = drawPolygon;
  drawControlExt.drawMarker = drawMarker;
  drawControlExt.getEditLayerGroup = getEditLayerGroup;

  return drawControlExt;
};

type DrawHandler = (e: any) => void;

export const initDrawEventHandlers = (
  handlers: {
    [K: string]: DrawHandler;
  },
  map: L.Map
) => {
  if (map) {
    for (const eventType in handlers) {
      map.on(eventType, handlers[eventType]);
    }
  }

  return {
    destroy: () => {
      if (map) {
        for (const eventType in handlers) {
          map.off(eventType, handlers[eventType]);
        }
      }
    }
  };
};
