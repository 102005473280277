// Типы экшенов
export enum MonitoringAction {
  METRICS = 'METRICS',
  ATTACHMENTS = 'ATTACHMENTS',
  PLAN_FACT = 'PLAN_FACT',
  TRACK = 'TRACK',
  VEHICLE_DETAILS = 'VEHICLE_DETAILS',
  EXPORT_EXEL = 'EXPORT_EXEL',
  GET_VEHICLE_TRACKERS = 'GET_VEHICLE_TRACKERS',
  PLAN_TRACK = 'PLAN_TRACK',
  GET_TRACKERS = 'GET_TRACKERS',
  UPDATE_TRACKER = 'UPDATE_TRACKER',
  DELETE_TRACKER = 'DELETE_TRACKER',
  UPDATE_VEHICLE_TRACKER = 'UPDATE_VEHICLE_TRACKER',
  DELETE_VEHICLE_TRACKER = 'DELETE_VEHICLE_TRACKER',
  SET_TRIP_STATUS = 'SET_TRIP_STATUS'
}

// Сообщения нотификаций в зависимости от происходящего процесса
export const defaultStatuses: {
  [key in MonitoringAction]: {
    loading: string;
    success?: string;
    successPlural?: string;
    error?: string;
  };
} = {
  METRICS: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' },
  TRACK: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' },
  ATTACHMENTS: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' },
  PLAN_FACT: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' },
  VEHICLE_DETAILS: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка' },
  EXPORT_EXEL: { loading: 'Загрузка файла...', success: 'Файл загружен' },
  GET_VEHICLE_TRACKERS: { loading: 'Загрузка данных...', success: 'Данные загружены' },
  PLAN_TRACK: { loading: 'Загрузка плановых треков' },
  GET_TRACKERS: { loading: 'Загрузка данных...', success: 'Данные загружены' },
  UPDATE_TRACKER: { loading: 'Изменение данных...', success: 'Данные загружены' },
  DELETE_TRACKER: { loading: 'Удаление данных...', success: 'Данные удалены' },
  UPDATE_VEHICLE_TRACKER: { loading: 'Изменение данных...', success: 'Данные загружены' },
  DELETE_VEHICLE_TRACKER: { loading: 'Удаление данных...', success: 'Данные удалены' },
  SET_TRIP_STATUS: { loading: 'Установка статуса рейса...', success: 'Статус рейса установлен' }
};

