import { RecordType } from '@modules/planning/children/scheme-editor/types';

/**
 * Для новых элементов: target элемент всегда идет внизу
 * Для сортировки внутри текущего списка: при движении вверх target элемент смещаем вниз, при движении вниз target элемент смещаем вверх
 * @params alwaysMoveDown -заменяемы элемент - всегда идет вниз
 * */
export const substituteItems = (
  currentKeys: Array<RecordType['title']>,
  source: { title: string },
  target: string,
  alwaysMoveDown: boolean = false
): Array<RecordType['title']> => {
  let sourceIndex: number = currentKeys.findIndex((x) => x === source.title);
  return currentKeys.reduce((prev, cur, currentIndex) => {
    if (cur !== source.title) {
      if (target === cur) {
        const movingDown = sourceIndex < currentIndex;
        if (!alwaysMoveDown && movingDown) prev.push(cur); //если target element - не первый в списке двигаем его вверх
        prev.push(source.title);
        if (alwaysMoveDown || !movingDown) prev.push(cur); //если target element - первый в списке двигаем его вниз
      } else {
        prev.push(cur);
      }
    }

    return prev;
  }, [] as Array<RecordType['title']>);
};
