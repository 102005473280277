import { filterPointsToGetTrack, plansPointsToGetTrack } from '@common/utils/get-track/planPoint2getTrack';
import { PointType, PointTypeApi } from '@common/utils/get-track/types';
import { Action, Dispatch } from 'redux';
import { UnifiedRoutine } from 'redux-saga-routines';
import { createTrackKeyByPoint } from './create-track-key';
import { IMapTrack } from '@common/types';
import { isEmpty, omit } from 'lodash';
import { PlanningParameters } from '@modules/settings/types/store';
import { PlanningSettingsKeys } from '@modules/settings/types';

export const addTrackForLoading = (
  settingsMap: Pick<PlanningParameters, PlanningSettingsKeys.UseRedis> | undefined,
  mapData: { track: Array<IMapTrack> } | null | undefined,
  trackData: Record<string, PointTypeApi>,
  dispatch: Dispatch<any>,
  action: UnifiedRoutine<(payload?: any) => Action>
) => {
  if (settingsMap?.useRedis && mapData?.track?.length) {
    const items: Record<string, PointType> = mapData.track.reduce((acc: Record<string, PointType>, it) => {
      if (it && it?.nativeCoords?.from?.lat && it.nativeCoords?.from?.lon && it.nativeCoords?.to?.lat && it.nativeCoords?.to?.lon) {
        const Details = omit(it.trackData, 'providerName');
        const point: PointType = {
          from: {
            latitude: it.nativeCoords.from.lat,
            longitude: it.nativeCoords.from.lon
          },
          to: {
            latitude: it.nativeCoords.to.lat,
            longitude: it.nativeCoords.to.lon
          },
          forbiddenZones: it?.forbiddenZoneIDs ? it?.forbiddenZoneIDs?.join(';') : '',
          ProviderName: it.trackData?.providerName
        };
        if (!isEmpty(Details)) {
          point.Details = Details;
        }

        const key = createTrackKeyByPoint(point);
        acc[key] = point;
      }

      return acc;
    }, {});

    if (items && Object.keys(items)?.length) {
      const addTrackListToQuery = plansPointsToGetTrack(items, trackData);
      if (Object.values(filterPointsToGetTrack(addTrackListToQuery)).length) {
        dispatch(action({ points: items }));
      }
    }
  }
};
