import { Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { takeLatest } from 'redux-saga/effects';
import { tenderLotRoutinesToNotify, tenderParticipantRoutinesToNotify, tenderRoutinesToNotify } from '../../actions';
import {
  defaultTenderLotMessages,
  defaultTenderMessages,
  defaultTenderParticipantMessages,
  TenderAction,
  TenderLotAction,
  TenderParticipantAction
} from '../../constants/default-message';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { EMPTY_STRING } from '@common/constants';

const getTenderDataNotificationMessage = <T extends string | number | symbol>(defaultMessage: { [key in T]: Object }) => {
  return (action: T, type: Notify.Loading | Notify.Success | Notify.Error, payload?: { message: string; hideSuccessMessage?: string }) => {
    if (payload?.hideSuccessMessage) return payload.hideSuccessMessage;
    return payload?.message || defaultMessage?.[action]?.[type] || EMPTY_STRING;
  };
};

const tenderWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, TenderAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderAction,
  getKeyPrefix: () => 'tender_own',
  getNotificationMessage: getTenderDataNotificationMessage<TenderAction>(defaultTenderMessages)
});

const tenderErrorWorker = notifierWorkerCreator<{ error: string | string[] }, TenderAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderAction,
  getKeyPrefix: () => 'tender_own',
  getNotificationMessage: () => '',
  getErrorMessage
});

const tenderLotWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, TenderLotAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderLotAction,
  getKeyPrefix: () => 'tender_own_lot',
  getNotificationMessage: getTenderDataNotificationMessage<TenderLotAction>(defaultTenderLotMessages)
});

const tenderLotErrorWorker = notifierWorkerCreator<{ error: string | string[] }, TenderLotAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderLotAction,
  getKeyPrefix: () => 'tender_own_lot',
  getNotificationMessage: () => '',
  getErrorMessage
});

const tenderParticipantWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: string }, TenderParticipantAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderParticipantAction,
  getKeyPrefix: () => 'tender_own_participant',
  getNotificationMessage: getTenderDataNotificationMessage<TenderParticipantAction>(defaultTenderParticipantMessages)
});

const tenderParticipantErrorWorker = notifierWorkerCreator<{ error: string | string[] }, TenderParticipantAction>({
  getActionFromRoutine: (actionName: string) => actionName as TenderParticipantAction,
  getKeyPrefix: () => 'tender_own_participant',
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* tenderOwnNotifierWatcher() {
  for (const routine of tenderRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, tenderWorker);
    yield takeLatest(routine.SUCCESS, tenderWorker);
    yield takeLatest(routine.FAILURE, tenderErrorWorker);
  }
  for (const routine of tenderLotRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, tenderLotWorker);
    yield takeLatest(routine.SUCCESS, tenderLotWorker);
    yield takeLatest(routine.FAILURE, tenderLotErrorWorker);
  }
  for (const routine of tenderParticipantRoutinesToNotify) {
    yield takeLatest(routine.REQUEST, tenderParticipantWorker);
    yield takeLatest(routine.SUCCESS, tenderParticipantWorker);
    yield takeLatest(routine.FAILURE, tenderParticipantErrorWorker);
  }
}
