import { Tender } from '@modules/tender-platform/types';
import { tenderDocumentDownloadRoutine } from '@modules/tender-platform/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { getLink } from '@common/api/helpers';
import { downloadFileByUrlFromBlob } from '@common/utils/helpers/download-file-by-url-from-blob';

function* tenderDocumentDownloadWorker(action: PayloadAction<{ currentTender: Tender; selectedRowKeys: Array<number> }>) {
  const { failure } = tenderDocumentDownloadRoutine;

  try {
    const requestParams = getLink(action.payload);
    const { data } = yield call(TenderAPI.downloadAllChecked, { requestParams });
    downloadFileByUrlFromBlob(data, 'Архив');
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* tenderDocumentDownloadWatcher() {
  yield takeEvery(tenderDocumentDownloadRoutine.trigger, tenderDocumentDownloadWorker);
}
