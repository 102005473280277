import { PayloadAction } from '@reduxjs/toolkit';
import { addCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { Account } from '@common/types';

function* addCarriersWorker(
  action: PayloadAction<{
    ids: Array<Account['ID']>;
  }>
) {
  const { request, success, failure, fulfill } = addCarriersRoutine;
  const { ids } = action.payload;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderOwn.addCarriers, { ids });
    const carriers = data;
    yield put(success({ carriers }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addCarriersWatcher() {
  yield takeEvery(addCarriersRoutine.trigger, addCarriersWorker);
}
