import L from 'leaflet';

// Иконка маркера гаража
const markerIconGarage = (color: string) => {
  return (
    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 392.436 392.436" style="enable-background:new 0 0 392.436 392.436;" xml:space="preserve">' +
    '<path style="fill:#fff;" d="M196.186,21.786c-65.228,0-118.303,53.075-118.303,118.303c0,51.459,78.675,169.309,118.303,223.418c39.628-54.109,118.303-172.024,118.303-223.418C314.489,74.861,261.414,21.786,196.186,21.786z"/>' +
    '<g><path style="fill:' +
    color +
    ';" d="M196.186,0C118.933,0,56.097,62.836,56.097,140.089c0,74.149,126.061,241.196,131.426,248.178c4.073,5.624,13.188,5.495,17.39,0c5.301-7.111,131.426-174.028,131.426-248.178C336.275,62.836,273.438,0,196.186,0zM77.883,140.089c0-65.228,53.075-118.303,118.303-118.303s118.303,53.075,118.303,118.303c0,51.394-78.675,169.309-118.303,223.418C156.558,309.398,77.883,191.547,77.883,140.089z"/></g>' +
    '</svg>'
  );
};

// Кастомный маркер с номером
export const customMarkerWithNumber = (icon: string, number: string, color?: string | undefined) => {
  color = color ?? '#4C91FF';

  const html =
    '<div class="div-custom-marker-relative">' +
    markerIconGarage(color) +
    '<img src="' +
    icon +
    '" class="icon home" alt="icon" />' +
    '<span class="span-custom-marker-abs">' +
    number +
    '</span>' +
    '</div>';

  return new L.DivIcon({
    iconSize: new L.Point(45, 45),
    className: 'my-div-icon',
    html: html
  });
};
