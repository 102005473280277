import { createSelector } from 'reselect';
import { RouteTestStore } from '@modules/road-graph/route-test/types';

export const errorSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.error,
  (error) => error
);
export const fetchSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.isFetching,
  (isFetching) => isFetching
);
export const savingSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.isSaving,
  (isSaving) => isSaving
);
export const successSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.success,
  (success) => success
);
export const trackDataSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.trackData,
  (trackData) => trackData
);
export const forbiddenZonesSelector = createSelector(
  (state: { RoadGraphTest: RouteTestStore }) => state.RoadGraphTest.forbiddenZones,
  (ForbiddenZones) => ForbiddenZones
);
