import { ID } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';

/**
 * Return new array of objects, with replaced object which ID already exist in array
 * or array with new object
 * @param updatedItem
 * @param array
 */
export const replaceUpdatedItem = <T extends { ID: ID | string }, I extends { ID: ID | string }>(updatedItem: I, array: Array<T>) => {
  return array.map((item) => {
    return compareAsString(item.ID, updatedItem.ID) ? { ...updatedItem } : item ?? [updatedItem];
  });
};
