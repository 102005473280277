import { SearchOutlined } from '@ant-design/icons';
import { ColumnTable, FilterSort, TableRecord } from '@common/types';
import { InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { isObject } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { renderFilterDropdown } from './render-dropdown';
import { JoinChar } from '@common/constants';
import { FilterState } from '@common/types/components/table/common';

type Arg<R extends TableRecord> = {
  filtered: FilterSort['filtered'];
  column: Pick<ColumnTable<R>, 'title' | 'dataIndex'>;
  filterState: FilterState;
  setFilterState: Dispatch<SetStateAction<FilterState>>;
  searchInput: InputRef | null;
};

const filterSearchValue = (value: string | number | boolean, record?: string) => {
  let recordStringValue = record;
  if (isObject(record)) {
    recordStringValue = Object.values(record).join(JoinChar.Space);
  }
  return recordStringValue?.toString().toLowerCase().includes(value?.toString().toLowerCase()) ?? false;
};

export const getGeoFilterProps = <R extends TableRecord>({
  filtered,
  column: { dataIndex, title },
  filterState,
  setFilterState,
  searchInput
}: Arg<R>) => {
  const filteredValue = filtered && filtered?.[dataIndex];
  const onFilter: ColumnTable<R>['onFilter'] = (value, record) => filterSearchValue(value, record?.[dataIndex]);

  return {
    filteredValue,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, ...props }: FilterDropdownProps) =>
      renderFilterDropdown({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        searchInput,
        title,
        dataIndex,
        setFilterState,
        filterState,
        ...props
      }),
    filterIcon: <SearchOutlined />,
    onFilter,
    onFilterDropdownVisibleChange: (visible: boolean) => visible && setTimeout(() => searchInput?.select?.(), 100)
  };
};
