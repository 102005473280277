import { RefObject, useEffect, useRef } from 'react';

export const useMultipleRowControl = (tableRef: RefObject<HTMLDivElement> | null | undefined) => {
  const row = useRef<null | HTMLDivElement>(null);
  const tableRow = tableRef?.current?.querySelector('tr.row') as Element;
  const table = tableRef?.current?.querySelector('.ant-table-body') as Element;
  const tableTDs: NodeListOf<HTMLTableCellElement> = tableRow?.querySelectorAll('td');

  useEffect(() => {
    const onScrollLeft = () => {
      if (row?.current) {
        row.current.scrollLeft = table?.scrollLeft;
      }
    };
    table?.addEventListener('scroll', onScrollLeft);
    return () => table?.removeEventListener('scroll', onScrollLeft);
  }, [table]);

  return { row, tableRow, tableTDs };
};
