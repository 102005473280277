import { TableConfig } from '@common/types';

export const orderTableConfig: TableConfig = {
  deleting: true,
  selecting: true,
  adding: {
    DeniedLate: 0,
    OrderSeries: 0,
    RequireTailLift: 0,
    RequirePalletTruck: 0,
    RequireLoaders: 0,
    RequireUnloaders: 0
  },
  columns: []
};
