import { CellType } from '@common/types';

export const CELL_TYPE_MIN_WIDTH: Record<CellType, number> = {
  [CellType.Alert]: 100,
  [CellType.Select]: 160,
  [CellType.SelectIcon]: 40,
  [CellType.SelectAPI]: 160,
  [CellType.SelectMultiple]: 160,
  [CellType.SelectMultipleApi]: 160,
  [CellType.Input]: 150,
  [CellType.String]: 45,
  [CellType.Int]: 100,
  [CellType.Float]: 80,
  [CellType.Color]: 32,
  [CellType.Icon]: 32,
  [CellType.Date]: 120,
  [CellType.DateTime]: 190,
  [CellType.Time]: 80,
  [CellType.RangeTime]: 50,
  [CellType.Bool]: 80,
  [CellType.Phone]: 115,
  [CellType.DoubleRangeTimeString]: 100,
  [CellType.RegNumber]: 80,
  [CellType.Geo]: 160,
  [CellType.Text]: 70,
  [CellType.IconAction]: 30,
  [CellType.Percent]: 80,
  [CellType.Email]: 200,
  [CellType.Uint]: 80,
  [CellType.Ufloat]: 100,
  [CellType.TimeStringUtc]: 120,
  [CellType.Progress]: 130,
  [CellType.LineSeries]: 400,
  [CellType.BadgeText]: 150,
  [CellType.Button]: 50,
  [CellType.MenuButton]: 50,
  [CellType.Status]: 200,
  [CellType.CascaderAPI]: 160,
  [CellType.CascaderConfigAPI]: 120,
  // [CellType.Cascader]: 160,
  [CellType.StringMultiple]: 200,
  [CellType.DateTimeString]: 190,
  [CellType.Countdown]: 100,
  [CellType.ButtonClickableDropdown]: 40,
  [CellType.AddressAutocomplete]: 250,
  [CellType.TextAction]: 80,
  [CellType.SelectIconAPI]: 80,
  [CellType.TimeDouble]: 80,
  [CellType.Duration]: 90
};
export const DATE_PICKER_CELL_TYPES = [CellType.Date, CellType.DateTimeString, CellType.DateTime];
export const TIME_PICKER_CELL_TYPES = [CellType.DateTimeString, CellType.TimeDouble, CellType.TimeStringUtc];
export const SELECT_CELL_TYPES = [
  CellType.Select,
  CellType.SelectAPI,
  CellType.SelectMultiple,
  CellType.SelectMultipleApi,
  CellType.CascaderAPI
];
export const CASCADER_CELL_TYPES = [CellType.CascaderAPI];
export const API_ICON_PARAM = 'http';
export const NOT_FILTERING_CELL_TYPES = [
  CellType.IconAction,
  CellType.SelectIcon,
  CellType.SelectIconAPI,
  CellType.Icon,
  CellType.Color,
  CellType.ButtonClickableDropdown,
  CellType.TextAction,
  CellType.LineSeries
];
export const DATETIME_FILTERING_CELL_TYPES = [...TIME_PICKER_CELL_TYPES, ...DATE_PICKER_CELL_TYPES, CellType.Duration];

export const CELL_INTEGER_VALIDATION_PARAMETERS = {
  step: 1,
  min: 0
};

export const CELL_FLOAT_VALIDATION_PARAMETERS = {
  step: 0.1,
  min: 0
};
