import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { IndexedArray } from '@common/types';
import { tenderDocumentUploadRoutine } from '@modules/tender-platform/actions';
import { TenderDocuments, TenderState } from '@modules/tender-platform/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderDocumentUploadReducer = createReducer(initialState, {
  [tenderDocumentUploadRoutine.REQUEST]: onRequest,
  [tenderDocumentUploadRoutine.FAILURE]: onFailure,
  [tenderDocumentUploadRoutine.FULFILL]: onFulfill,
  [tenderDocumentUploadRoutine.SUCCESS]: (state: TenderState, action: PayloadAction<{ documents: IndexedArray<TenderDocuments> }>) => {
    const { documents } = action.payload;
    state.documents = { ...state.documents, ...documents };
    return state;
  }
});
