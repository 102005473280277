import { ILSContent, ILSHeader, ILSLayout, ILSSider } from '@components/index';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import { IPageProps } from '@pages/types';
import { createContext, FC, useState } from 'react';
import { renderRoutes } from 'react-router-config';

export const RouteContext = createContext<IPageProps | null>(null);

export const MainPage: FC<IPageProps> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <ILSLayout>
      <ILSSider collapsible collapsed={collapsed} onCollapse={toggleCollapsed} className={'ils-sidebar'}>
        <Scope name={ModuleScope.Sider} extraProps={{ collapsed, toggleCollapsed, ...props }} />
      </ILSSider>
      <ILSLayout className="ils-wrapper">
        <ILSHeader>
          <Scope name={ModuleScope.Header} />
        </ILSHeader>
        <ILSContent className="ils-content">
          <RouteContext.Provider value={props}>{renderRoutes(props.route.routes)}</RouteContext.Provider>
        </ILSContent>
      </ILSLayout>
    </ILSLayout>
  );
};
