import { orderBy } from 'lodash';
import { FakePreCostProposals } from '@modules/orders-and-trips/types/fake-pre-cost';
import { ReactDispatch } from '@common/types';

export const getSorterForProposalsByPrice =
  ({
    setSortProposalList,
    proposalList
  }: {
    proposalList: FakePreCostProposals;
    setSortProposalList: ReactDispatch<FakePreCostProposals>;
  }) =>
  (isDesc: boolean) => {
    setSortProposalList(() => {
      const nullable = proposalList.filter(({ price }) => !price);
      const notNullable = proposalList.filter(({ price }) => price);
      const byPrice = orderBy(notNullable, ['price']);
      const sorted = isDesc ? byPrice.reverse() : byPrice;
      return sorted.concat(nullable);
    });
  };
