import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { copyBoardSetRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';

export const copyBoardSetsReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [copyBoardSetRoutine.REQUEST]: (state) => {
    state.Boards.adding = true;
    return state;
  },
  [copyBoardSetRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsBoardSetModel>;
    }>
  ) => {
    const { data } = action.payload;

    const obj = Object.values(data);

    if (data && state.BoardSets?.data) {
      state.BoardSets.data = { ...state.BoardSets?.data, [obj[0].ID]: obj[0] };
    }

    return state;
  },
  [copyBoardSetRoutine.FAILURE]: (state) => {
    return state;
  },
  [copyBoardSetRoutine.FULFILL]: (state) => {
    state.Boards.adding = false;
    return state;
  }
});
