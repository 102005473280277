import { FC } from 'react';
import { TableWithMapContainer } from '../containers/table-with-map';

interface Props {
  handleDateSelect: (date: string[]) => void;
  handleRedirect: (name: string) => void;
}

/**
 * Контейнер Таблицы по транспорту вместе с картой
 * Здесь просто разделить данные которые нужны только таблице,
 * и которые нужны только карте
 */
export const MainTableMapContainer: FC<Props> = ({ handleDateSelect, handleRedirect }) => {
  return (
    <div className="ils-monitoring-details-top-layout position-relative">
      <TableWithMapContainer handleDateSelect={handleDateSelect} handleRedirect={handleRedirect} />
    </div>
  );
};
