import { addEndDepotToTripRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch } from 'react-redux';
import { TripRecord } from '@modules/planning/children/by-date/types';

export const useAddVehicleEndDepotToTrip = (record: TripRecord) => {
  const dispatch = useDispatch();

  const addVehicleEndDepotToTrip = () => {
    const payload = {
      tripID: record.ID,
      vehicleID: record.VehicleID,
      endDepotID: record.VehicleEndDepotID
    };
    dispatch(addEndDepotToTripRoutine(payload));
  };

  return { addVehicleEndDepotToTrip };
};
