import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { checkConflictRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';

export const checkConflictReducer = createReducer(initialState, {
  [checkConflictRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    state.conflict = { hasConflict: false, type: null };
    return state;
  },
  [checkConflictRoutine.SUCCESS]: (state, action) => {
    const { conflict } = action.payload;
    state.conflict = conflict;
    return state;
  },
  [checkConflictRoutine.FAILURE]: onFailure,
  [checkConflictRoutine.FULFILL]: onFulfill
});
