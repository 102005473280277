import { call, put, takeLatest } from 'redux-saga/effects';
import { setBoardWidgetBatch } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { SetBoardWidgetBatchRequest } from '@common/types/dictionaries/analytics';

function* worker(action: PayloadAction<SetBoardWidgetBatchRequest>) {
  const { request, success, failure, fulfill } = setBoardWidgetBatch;
  try {
    yield put(request());

    const { data } = yield call(AnalyticsPlanningAPI.setBoardWidgetBatch, action.payload);

    yield put(success({ data: data.data }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsSetBoardWidgetBatchWatcher() {
  yield takeLatest(setBoardWidgetBatch.TRIGGER, worker);
}
