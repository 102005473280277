import { Dictionary, TableName } from '@common/types';
import { MapClickData } from '@common/types/components/map/map';
import { isOrderClickData, isWaypointClickData } from '@common/utils/map';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { OnLassoChange, SelectedOrdersLassoType } from '@modules/planning/children/by-date/types/map-container';
import { useActiveFocusRow, useExpandableRow } from '@modules/planning/children/by-date/hooks';

type UseItemClickArg = {
  onLassoChange: OnLassoChange;
};

export const useItemClick = ({ onLassoChange }: UseItemClickArg) => {
  const { onTableRowClick } = useActiveFocusRow();

  const {
    externalExpandableProps: { onExpandedRowsChange }
  } = useExpandableRow();

  const onItemClick = useCallback((record?: MapClickData) => {
    if (isEmpty(record)) return;

    if (isOrderClickData(record)) {
      onLassoChange([record.ID], SelectedOrdersLassoType.UnResolvedOrder);
    } else if (isWaypointClickData(record)) {
      if (record.WaypointID && record.dictionary === Dictionary.Waypoint) {
        onTableRowClick({ ...record, key: record.WaypointID }, true, false, TableName.RoutesTable);
        if (record.VehicleID && record.TripID) {
          onExpandedRowsChange([record.TripID, record.VehicleID]);
        }
      } else if (record.TripID && record.dictionary === Dictionary.Trip) {
        onTableRowClick({ ...record, key: record.TripID }, true, false, TableName.RoutesTable);
        if (record.VehicleID) {
          onExpandedRowsChange([record.TripID, record.VehicleID]);
        }
      }
    }
  }, []);
  return { onItemClick };
};

