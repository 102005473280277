import { ILSButtonTooltip } from '@common/components';
import { PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';
import { useMemo } from 'react';

const handleFullScreen =
  (item: PlannerSettingsParameterFullScreen, onFullChange: (item: PlannerSettingsParameterFullScreen) => void) => () =>
    onFullChange(item);

export const useFullScreenButtons = (onFullChange: (item: PlannerSettingsParameterFullScreen) => void, initialSizes: Array<number>) => {
  const fullTable = initialSizes[0] === 100;
  const fullMap = initialSizes[1] === 100;

  const tableButtons = useMemo(() => {
    return [
      {
        Component: ILSButtonTooltip,
        props: {
          icon: `full-screen`,
          title: `Развернуть рабочую область на весь экран`,
          onClick: handleFullScreen(PlannerSettingsParameterFullScreen.Table, onFullChange),
          className: 'ils-full-screen-button'
        }
      }
    ];
  }, [fullTable]);

  const mapButtons = useMemo(() => {
    return [
      {
        Component: ILSButtonTooltip,
        props: {
          placement: 'right',
          icon: `full-screen`,
          title: `Развернуть карту на весь экран`,
          onClick: handleFullScreen(PlannerSettingsParameterFullScreen.Map, onFullChange)
        }
      }
    ];
  }, [fullMap]);

  return { tableButtons, mapButtons, fullTable, fullMap };
};
