import { ILSCheckbox, ILSInputNumber, ILSSelect } from '@components/index';
import { selectOptions } from '../decorators';
import { ParamTypesComponentProps } from '@modules/planning/children/params/types/components';
import { UpdateParamType } from '../types/api';

enum TypesConfig {
  Enum = 'Enum',
  Int = 'Int',
  Bool = 'Bool',
  Float = 'Float'
}
const ILSParamTypesComponent = (props: ParamTypesComponentProps) => {
  const { param, configurations, disabled, defaultValues } = props;

  let configuration;
  for (let conf in configurations) {
    if (conf === param?.Def || param?.Def.includes(TypesConfig.Int)) configuration = configurations[conf];
  }

  let options: any = [];
  if (configuration && configuration.Value_Type === TypesConfig.Enum) {
    options = selectOptions(configuration);
  }

  const defaultValue = defaultValues ? param?.DefaultValue_ : param?.Value_;
  const checkedValue = defaultValues ? param?.DefaultValue_ && param?.DefaultValue_ !== '0' : param?.Value_ && param?.Value_ !== '0';
  const onChange = (value: any) => {
    props?.onChange(
      {
        ...param,
        Def: param?.Def
      },
      value,
      UpdateParamType.ChangeParam
    );
  };
  if (configuration && !configuration?.Value_Type && param?.Def.includes(TypesConfig.Int)) {
    configuration = { ...configuration, Value_Type: TypesConfig.Int };
  }

  return (
    <>
      {configuration && configuration.Value_Type === TypesConfig.Int && (
        <ILSInputNumber
          defaultValue={defaultValue}
          disabled={disabled}
          onPressEnter={(e: any) => onChange(e.target.value)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
      )}

      {configuration && configuration.Value_Type === TypesConfig.Bool && (
        <ILSCheckbox defaultChecked={checkedValue as boolean} disabled={disabled} onChange={(e) => onChange(Number(e.target.checked))} />
      )}

      {configuration && configuration.Value_Type === TypesConfig.Float && (
        <ILSInputNumber
          defaultValue={defaultValue}
          step={0.1}
          disabled={disabled}
          onPressEnter={(e: any) => onChange(e.target.value)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
      )}

      {configuration && configuration.Value_Type === TypesConfig.Enum && (
        <ILSSelect
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={(val: any) => {
            onChange(val);
          }}
          options={options}
        />
      )}
    </>
  );
};

export default ILSParamTypesComponent;
