import {
  ColumnTable,
  FilterCellType,
  IDecorateValidationData,
  IndexedArray,
  TableColumnName,
  TableConfig,
  TableRecord,
  TErrorValidationTable,
  ValidationData
} from '@common/types';
import { AlertNotify, Notify } from '@common/types/general/conditions';
import { doubleToTime } from '@common/utils';
import { TIME_TABLE_COLUMNS } from '@modules/planning/children/data/constants';
import { ChangeDataPayload } from '@modules/planning/children/data/types/components';
import { ERRORS, INFO, WARNINGS } from '../constants';
import { ILSUserNotify } from '@common/components/feedback/ils-user-notify';

export const filterValidationKind = (array: any, errors: ValidationData[]) => {
  return (Array.isArray(errors) && errors?.filter(({ Kind_ }) => Kind_ && array.includes(Kind_))) || [];
};

export const getErrorsByType = (errorsByDictionary: IDecorateValidationData, dictionary: TErrorValidationTable) => {
  return {
    Err: filterValidationKind(ERRORS, errorsByDictionary[dictionary]),
    Wrn: filterValidationKind(WARNINGS, errorsByDictionary[dictionary]),
    Inf: filterValidationKind(INFO, errorsByDictionary[dictionary])
  };
};

export const getErrorNotify = (error: ValidationData[], warning: ValidationData[], info: ValidationData[]) => {
  return error.length ? Notify.Error : warning.length ? Notify.Warning : info.length ? Notify.Info : Notify.Success;
};

export const getFailData = (errorList: ValidationData[], dataSource: any[], showAll: boolean, codeField: string) => {
  const codes: Array<string> = [];
  let failKeys: Array<string> | undefined;
  let failFieldName: string | undefined;
  let failRows: Array<number> | undefined;
  let failFields: Array<string> = [];
  let failDataSource: Array<any> = [];
  const dataSourceRowMap: IndexedArray<number> = {};

  errorList?.map((error) => {
    error.Codes_?.split(';')?.forEach((code) => {
      if (!codes.includes(code)) codes.push(code);
    });

    failKeys = error.FailKeys;
    failFieldName = error.FailFieldName;
    failRows = error.FailRows ?? undefined;
    failFields = error.Fields_;
  });

  if (failRows?.length) {
    failDataSource = dataSource?.filter((d: any, ind: number) => d && failRows?.includes(ind));
  }
  if (codes?.length) {
    failDataSource = dataSource?.filter((dataItem: any) => {
      return dataItem?.[codeField] && codes.includes(dataItem[codeField].toString());
    });
  }

  dataSource?.forEach((dataItem: any, index: number) => {
    dataSourceRowMap[dataItem[codeField]] = index;
  });

  return {
    codes,
    failKeys,
    failFieldName,
    failRows,
    failFields,
    failDataSource,
    dataSourceRowMap
  };
};

export const getFailDataTableColumns = (commonErrorColumns: ColumnTable<TableRecord>[], kind: AlertNotify | null | undefined) => {
  return commonErrorColumns.map((column: any) => {
    if (column.dataIndex === TableColumnName.Found && kind) {
      const validationDataIndexFormat = `${kind}_`;
      return {
        ...column,
        dataIndex: validationDataIndexFormat,
        key: validationDataIndexFormat
      };
    }
    return { ...column, sorting: true, filtering: FilterCellType.String, adding: false, deleting: false };
  });
};

export const getConfigForErrorTable = (config: TableConfig) => {
  const newConfig = { ...config };
  newConfig.adding = false;
  newConfig.deleting = false;
  newConfig.selecting = false;
  return newConfig;
};

export const failPayload2Data = (payload: ChangeDataPayload, rowMap: IndexedArray<number>) => {
  const clearPayload = { ...payload };
  if (TIME_TABLE_COLUMNS.includes(String(payload.cell))) {
    clearPayload.value = doubleToTime(Number(clearPayload.value));
  }

  if (!payload?.key) {
    ILSUserNotify(Notify.Error, `Для редактирования ячеек с ошибками, требуется параметр key`);
  } else {
    clearPayload.row = rowMap[payload.key];
  }

  return clearPayload;
};
