import { LogisticServicesHeader } from '@modules/current-user/components/logistic-services/header/logistic-services-header';
import { LogisticServicesList } from '@modules/current-user/components/logistic-services/list/logistic-services-list';

const ILSLogisticServices = () => {
  return (
    <div className="logistic-services">
      <LogisticServicesHeader />
      <LogisticServicesList />
    </div>
  );
};

export default ILSLogisticServices;
