import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { demandGetCountsRoutine, demandGetListRoutine } from '../actions';
import { GetListRequest } from '../types/api';
import { DemandAPI } from './../api/index';
import { DemandRow } from '../types/store';
import { DEFAULT_REQUEST_PARAMS } from '../constants';

// Получает с бэкэнда главный список проектов и тендерных лотов
function* getDemandListWorker(action: PayloadAction<GetListRequest & { forceUpdate?: boolean }>) {
  const { request, success, failure, fulfill } = demandGetListRoutine;
  const { perPage = DEFAULT_REQUEST_PARAMS.perPage, page = DEFAULT_REQUEST_PARAMS.page, filter, forceUpdate = false } = action.payload;

  try {
    yield put(request());

    const { data }: { data: { data: Array<DemandRow> } } = yield call(DemandAPI.getList, { perPage, page, filter });
    const list = data.data;

    yield put(success({ list, params: { perPage, page, filter }, forceUpdate }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    if (forceUpdate) {
      yield put(demandGetCountsRoutine.trigger({}));
    }
    yield put(fulfill());
  }
}

export function* getDemandListWatcher() {
  yield takeEvery(demandGetListRoutine.trigger, getDemandListWorker);
}
