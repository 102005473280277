export enum RateCarrierFormItem {
  CarrierRating = 'CarrierRating',
  CarrierComment = 'CarrierComment',
  DriverRating = 'DriverRating',
  DriverComment = 'DriverComment'
}

export enum RateCarrierFormItemLabel {
  CarrierRating = 'Оценка компании (Перевозчика)',
  CarrierComment = 'Комментарий',
  DriverRating = 'Оценка Водителя',
  DriverComment = 'Комментарий'
}

export const RATE_CARRIER_FORM_ITEMS = [
  {
    rating: RateCarrierFormItem.CarrierRating,
    comment: RateCarrierFormItem.CarrierComment
  },
  { rating: RateCarrierFormItem.DriverRating, comment: RateCarrierFormItem.DriverComment }
];

export const TENDER_PLATFORM_MY_CARRIERS = 'Мои перевозчики';
