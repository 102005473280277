import { PlanningAPI } from '@modules/planning/api';
import { ChangeDriverInRouteRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { changeDriverInRouteRoutine } from '../../actions';

function* planningChangeDriverInRouteWorker(action: PayloadAction<ChangeDriverInRouteRequest>) {
  const { request, success, failure, fulfill } = changeDriverInRouteRoutine;
  const { driverID, planID, vehicleID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.changeDriverInRoute, { driverID, planID, vehicleID });
    yield put(success({ message: data, driverID, vehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningChangeDriverInRouteWatcher() {
  yield takeLatest(changeDriverInRouteRoutine.trigger, planningChangeDriverInRouteWorker);
}
