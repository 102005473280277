import { IndexedArray, PlanNotice } from '@common/types';
import { TNoticeType } from '@modules/planning/children/by-date/types';
import { doubleToTime } from '@common/utils';

/**
 * Функция принимает PlanNotice и объект с возможными типа уведомлений IndexedArray<TNoticeType>
 * @param notice -уведомление типа PlanNotice
 * @param  noticeTypes -список шаблонов уведомлений типа IndexedArray<TNoticeType>
 * @return отформатированное сообщение
 * */
export const parseNoticeType = (notice: PlanNotice, noticeTypes: IndexedArray<TNoticeType>) => {
  const closingTagSearchPattern = '</b>:';
  if (!noticeTypes) return null;
  if (!notice.StringKey) return null;
  let message = noticeTypes[notice.StringKey]?.name;
  if (!message) return null;
  try {
    const closingTagIndex = message.indexOf(closingTagSearchPattern);

    message = closingTagIndex !== -1 ? message.substring(closingTagIndex + closingTagSearchPattern.length, message.length).trim() : message;

    message = parseString(message, notice.Value);
    message = parseString(message, notice.Limit);
  } catch (e) {
    console.error(e);
    return null;
  }

  return message;
};

export const parseString = (message: string, value: any): string => {
  let res = message;
  try {
    const i = message.indexOf('%');
    const next = message.substring(i + 1, message.length).indexOf('%');
    const length = next === -1 ? message.length : next + i;
    if (i !== -1) {
      //берем только тут часть строки, где есть первая переменная и не учитываем в поиске вторую переменную
      const stringToSearch = message.substring(0, length);
      const isNumberWithAccuracy = stringToSearch.match(/%.\df/);
      const isFloat = stringToSearch.match(/%f/);
      const isTime = stringToSearch.match(/%t/);
      if (isNumberWithAccuracy) {
        const digits = Number.parseInt(isNumberWithAccuracy[0].substring(2, 3));
        res = message.replace(/%.\df/, (digits ? value.toFixed(digits) : Math.round(value)).toString());
      } else if (isFloat) {
        res = message.replace(/%f/, value);
      } else if (isTime) {
        res = message.replace(/%t/, doubleToTime(value));
      }
    }
  } catch (e) {
    console.error(e);
  }

  return res;
};
