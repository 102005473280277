import { Document } from '@modules/documents/types/document';
import { Module } from '@common/types';
import { FormField } from '@modules/documents/children/upload/types/form-field';
import { DocumentType, ReportList } from '@modules/documents/types/document-type';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

export enum DocumentState {
  Active = 'active',
  Inbox = 'inbox',
  Sent = 'sent',
  Archived = 'archived',
  Deleted = 'deleted',
  Templates = 'templates'
}

interface IDependentListItem {
  name: string;
  value: number;
}

export enum DependentListType {
  Period = 'Period',
  Input = 'Input'
}

export interface IDependentList {
  multiple?: 0 | 1;
  type?: DependentListType;
  alias: string;
  name: string;
  list: IDependentListItem[];
}

export interface ICurrentDocument {
  documentId: number;
  isOpenModal: boolean;
  isInbox?: boolean;
}

export type TClearTrashMode = '0' | '1';

export interface InboxSendDocument extends Document {
  ContractorEnum: { [key: number]: string };
  ContractorID: number;
  Status: number;
  DocumentID: number;
  DocumentEnum?: DocEnum[];
}

export enum DocumentsType {
  Inbox = 'inbox',
  Send = 'send'
}

export const DocumentStatus = {
  0: 'Получено',
  1: 'Принято',
  2: 'Отклонено',
  3: 'Удалено'
};

export interface DocEnum {
  id: number;
  name: string;
}

export interface DocumentsStore {
  data?: Document[];
  fields: FormField[];
  templates: DocumentTemplate[];
  reportList: ReportList;
  dependentList: IDependentList | IDependentList[] | null;
  currentDocument: null | Document;
  clearTrashMode: TClearTrashMode | null;
  isViewDocumentModelOpen: boolean;
  isFetching: boolean;
  error: string[];
  documentType: DocumentType;
  documentState: DocumentState;
  isDocumentFetching: boolean;
  contractors: {
    isFetching: boolean;
    error: string[];
    data: any;
  };
  metrics: {
    isFetching: boolean;
  };
  inboxSendDocuments: {
    data: InboxSendDocument[];
    docEnum: DocEnum[] | null;
    isFetching: boolean;
  };
}
export type DocumentsState = { [Module.Documents]: DocumentsStore };
