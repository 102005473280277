import { CellType, TableColumnName, TableConfig } from '@common/types';
import { color2int } from '@common/decorators/color';
import { ForwarderPointStatuses } from '@modules/settings/types';
import { COLOR_BLACK } from '@common/constants/colors';

const commonColumnProperties = { editable: true, active: true, ellipsis: true, sorting: 2 };

export const forwarderPointStatusesTableConfig: TableConfig = {
  // Пользователь может добавлять новые строки (TMS-5745)
  adding: {
    Name: 'Новый статус',
    SignColor: color2int(COLOR_BLACK),
    ChooseAvailable: 1,
    IsMaster: 0
  } as ForwarderPointStatuses,
  // Пользователь может удалить те строки, которые добавил (TMS-5745)
  deleting: true,
  configClassName: 'mobile-application-forwarder-point-statuses-table-config',
  columns: [
    { title: '№', dataIndex: TableColumnName.ID, key: TableColumnName.ID, invisible: true, type: CellType.Input },
    {
      title: 'Название',
      // В поле "Название" допускается вводить любые символы - максимальная длина 30 (TMS-5745)
      type: CellType.Input,
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      maxLengthInput: 30,
      // width: 150
      ...commonColumnProperties
    },
    {
      title: 'Цвет подписи',
      type: CellType.Color,
      dataIndex: TableColumnName.SignColor,
      key: TableColumnName.SignColor,
      // width: 100,
      ...commonColumnProperties
    },
    {
      title: 'Название/URL иконки',
      type: CellType.SelectIconAPI,
      dataIndex: TableColumnName.Icon,
      key: TableColumnName.Icon,

      // width: 100,
      ...commonColumnProperties,
      ellipsis: false
    },
    {
      title: 'Возможность установить',
      type: CellType.Bool,
      dataIndex: TableColumnName.ChooseAvailable,
      key: TableColumnName.ChooseAvailable,
      // width: 150,
      ...commonColumnProperties
    }
  ]
};
