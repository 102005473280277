/**
 * Преобразует число в отформатированную строку
 * @param n
 * @param separator  - разделитель разрядов
 * @param fixed
 * @returns строковое представление числа
 */
export const number2FormattedString = (n: number | string, separator?: string, fixed?: number): string => {
  if (typeof n === 'string') {
    n = Number(n);
  }
  const local = n.toFixed(fixed).toLocaleString();
  if (separator) {
    return local.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);
  }
  return local;
};
