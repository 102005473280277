import { StatusTenderOffer, TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';

export const TENDER_OFFER_CONTENT = {
  NO_OFFERS: 'Нет предложений',
  NOT_TAKEN: 'Нет предложения',
  NO_OFFERS_ON_APPROVAL: 'Нет предложений для утверждения',
  NO_LOTS_IN_TENDER: 'Нет лотов для принятия участия в тендере',
  BECOME_PARTICIPANT: 'Принять участие',
  BECOME_PARTICIPANT_TOOLTIP: 'Чтобы попасть в список участников нажмите кнопку и обязательно укажите цену своего предложения'
};

export const EDITABLE_OFFER_STATUSES = [
  StatusTenderOffer.Edited,
  StatusTenderOffer.OnApproval,
  StatusTenderOffer.UnderRevision,
  StatusTenderOffer.Return,
  StatusTenderOffer.Sent,
  undefined
];

export enum ILSAllowedField {
  AllowedForLot = 'AllowedForLot'
}

export enum ILSVehilceAllowedForLot {
  YES = 1,
  NO = 0
}

export enum TenderOfferFormItem {
  Direction = 'Direction',
  Route = 'Route',
  Volume = 'Volume',
  Cargo = 'Cargo',
  TenderOffer = 'TenderOffer',
  Price = 'price',
  UserID = 'UserID',
  Alias = 'Alias',
  LotID = 'LotID',
  OfferID = 'OfferID',
  MinPrice = 'MinPrice',
  StartPrice = 'StartPrice',
  DriverID = 'DriverID',
  VehicleID = 'VehicleID'
}

export enum TenderOfferFormItemLabel {
  Direction = 'Направление',
  Route = 'Маршрут',
  Volume = 'Требуемый объем',
  Cargo = 'Груз',
  TenderOffer = 'Предложение',
  Price = 'Стоимость',
  UserID = 'Ответственный',
  Alias = 'Статус предложения',
  LotID = 'Номер лота',
  OfferID = 'Номер предложения',
  MinPrice = 'Минимальная цена',
  StartPrice = 'Начальная цена',
  Driver = 'Водитель',
  Vehicle = 'Автомобиль'
}

export enum AuctionFormField {
  CurrentPrice = 'CurrentPrice',
  MyPrice = 'MyPrice'
}

export enum AuctionFormFieldLabel {
  CurrentPrice = 'Текущая стоимость',
  MyPrice = 'Моё предложение',
  Price = 'Новое предложение:',
  MyPriceWin = 'Ваше предложение наилучшее из всех',
  AnotherWinner = 'Другой участник объявил наилучшую цену'
}

export type AuctionFormFields = keyof typeof AuctionFormField & Partial<keyof typeof TenderFormField>;
export type AuctionFormValues = Record<AuctionFormField, number> & {
  [TenderFormField.StartPrice]: number;
  [TenderFormField.MinPrice]: number;
  [TenderOfferFormItem.Price]?: number;
  [TenderOfferFormItem.TenderOffer]?: TenderOffer;
};

export const OFFER_AUCTION_ITEMS = [
  TenderFormField.StartPrice,
  TenderFormField.MinPrice,
  AuctionFormField.CurrentPrice,
  AuctionFormField.MyPrice
];
