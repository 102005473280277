import { Option } from '@common/types';
import { compareAsString } from '@common/utils';

export const getLabelsForCascaderStringChildren = ({
  children,
  childrenValue,
  key,
  label
}: {
  children: Option[];
  childrenValue: Option['value'];
  key: string;
  label: string;
}) => {
  return (
    children?.find((childOption) => {
      return compareAsString(childOption[key], childrenValue);
    })?.[label] || 'Нет имени'
  );
};
