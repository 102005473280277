import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ILSButton, ILSForm, ILSFormItem, ILSRadio, ILSRadioGroup, ILSSkeleton, ILSSpace } from '@common/components';
import { useAppSelector } from '@core/hooks';
import { getClearTrashModeRoutine, setClearTrashModeRoutine } from '@modules/documents/actions';
import { getClearTrashMode, getIsFetchingSelector } from '@modules/documents/selectors';
import { TClearTrashMode } from '@modules/documents/types/document-state';

const ILSDocumentsClearTrashMode: FC = () => {
  const dispatch = useDispatch();
  const clearTrashMode = useAppSelector(getClearTrashMode);
  const isFetching = useAppSelector(getIsFetchingSelector);

  useEffect(() => {
    dispatch(getClearTrashModeRoutine());
  }, []);

  const submit = (values: { clearTrashMode: TClearTrashMode | null }) => {
    const newClearTrashMode = values.clearTrashMode;
    if (newClearTrashMode !== clearTrashMode) {
      dispatch(setClearTrashModeRoutine({ clearTrashMode: newClearTrashMode }));
    }
  };

  return (
    <ILSForm onFinish={submit} initialValues={{ clearTrashMode }}>
      <h2>Условия хранения документов</h2>
      <ILSSkeleton loading={isFetching} active>
        <ILSFormItem name="clearTrashMode">
          <ILSRadioGroup>
            <ILSSpace direction="vertical">
              <ILSRadio value="0">30 дней</ILSRadio>
              <ILSRadio value="1">100 документов</ILSRadio>
            </ILSSpace>
          </ILSRadioGroup>
        </ILSFormItem>
        <ILSButton type="primary" htmlType="submit">
          Применить
        </ILSButton>
      </ILSSkeleton>
    </ILSForm>
  );
};
export default ILSDocumentsClearTrashMode;
