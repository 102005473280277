import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { switchActiveMainTabRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState, MainTabs, MainTabsContainer } from '@modules/orders-and-trips/types/general';

export const switchActiveMainTabReducer = createReducer(initialState, {
  [switchActiveMainTabRoutine.REQUEST]: (state: InitialState) => {
    return state;
  },
  [switchActiveMainTabRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      tab: MainTabs;
      container: MainTabsContainer;
    }>
  ) => {
    const { tab, container } = action.payload;
    state.activeTab[container] = tab;
    return state;
  },
  [switchActiveMainTabRoutine.FAILURE]: onFailure,
  [switchActiveMainTabRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    return state;
  }
});
