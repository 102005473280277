import { PlanIDs } from '@common/types/dictionaries/plan';
import { planningCopyRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch } from 'react-redux';

export const useCopyPlan = () => {
  const dispatch = useDispatch();

  const handleCopy = (planID: PlanIDs) => {
    dispatch(planningCopyRoutine({ planID }));
  };
  return { handleCopy };
};
