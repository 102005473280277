import { defineModule } from '@core/index';
import RoadGraphEditor from './containers';
import * as roadGraphEditorRootSagas from './sagas';
import roadGraphEditorReducer from './reducers';
import { ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';

export default defineModule({
  title: ModuleTitle.RouteEditor,
  path: ModulePath.RouteEditor,
  component: RoadGraphEditor,
  reducer: roadGraphEditorReducer,
  sagas: getAllModuleSagas(roadGraphEditorRootSagas)
});
