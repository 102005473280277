import { FC } from 'react';
import classnames from 'classnames';
import { SchemeEditorTransferCard } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/transfer/transfer-card';
import { SchemeEditorTransferTitle } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/transfer/transfer-title';
import { RecordType, SchemeDragInfo, SchemeSwitchDictionary, TypeContainer } from '@modules/planning/children/scheme-editor/types';
import { SchemeEditorEmptySchemeList } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/empty/empty-scheme-list';
import { isEmpty } from 'lodash';

interface IProps {
  max?: boolean;
  scheme: SchemeSwitchDictionary;
  descend?: boolean;
  data: Array<RecordType>;
  typeContainer: TypeContainer;

  handleSort?(): void;

  handleFilter?(search: string): void;

  handleMoveSchemeKey({ target, source, typeContainer }: SchemeDragInfo): void;

  addNewKey?: ({ key, title, description }: { key: string; title?: string; description?: string }) => void;
}

export const SchemeEditorTransferBlock: FC<IProps> = ({
  max,
  scheme,
  descend,
  data,
  handleMoveSchemeKey,
  handleFilter,
  handleSort,
  typeContainer,
  addNewKey
}) => {
  const hasCards = !isEmpty(data);
  const transferBlockClassName = classnames(`scheme-editor-scheme-${typeContainer}`, { hide: !max });
  return (
    <div className={transferBlockClassName}>
      <SchemeEditorTransferTitle
        addNewKey={addNewKey}
        scheme={scheme}
        descend={descend}
        handleSort={handleSort}
        handleFilter={handleFilter}
      />
      {hasCards &&
        data.map((item) => (
          <SchemeEditorTransferCard
            handleMoveSchemeKey={handleMoveSchemeKey}
            scheme={scheme}
            typeContainer={typeContainer}
            key={item.key}
            item={item}
          />
        ))}
      <SchemeEditorEmptySchemeList data={data} typeContainer={typeContainer} handleMoveSchemeKey={handleMoveSchemeKey} />
    </div>
  );
};
