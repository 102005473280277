import './styles.less';
import { useEffect, useState } from 'react';
import { TableRecord } from '@common/types';
import { DEFAULT_LINESERIES_PROPS } from '../static/ils-cell-line-series';
import { ILSTitleLineSeriesComponent } from './ils-title-line-series-component';
import { IILSTitleLineSeriesContainerProps } from '@common/types/components/table/cell';

export const ILSTitleLineSeriesContainer = <R extends TableRecord>({
  dataIndex,
  lineSeriesProps,
  dataSource,
  tableRef,
  columnKey,
  width
}: IILSTitleLineSeriesContainerProps<R>): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [rangeLeft, setRangeLeft] = useState<number>(0);
  const [rangeRight, setRangeRight] = useState<number>(50);

  const maxLineLength = dataSource?.reduce((result, item) => {
    if (item[dataIndex] && Array.isArray(item[dataIndex]) && item[dataIndex].length > result) {
      result = item[dataIndex].length;
    }
    return result;
  }, 0);

  const lineSeriesElementsWidth =
    maxLineLength === 0 || !maxLineLength
      ? '100%'
      : maxLineLength * (lineSeriesProps?.size || DEFAULT_LINESERIES_PROPS.size) +
        (maxLineLength - 1) * (lineSeriesProps?.gap || DEFAULT_LINESERIES_PROPS.gap) +
        'px';

  const onChange = (value: number | [number, number]) => {
    setRangeLeft(value[1] < 50 ? 0 : value[1] - 50);
    setRangeRight(value[1] < 50 ? 50 : value[1]);
  };

  const shift = lineSeriesElementsWidth === '100%' ? 0 : ((parseInt(lineSeriesElementsWidth) - (width ? width : 0)) / 50) * rangeLeft;

  useEffect(() => {
    if (width) {
      setIsDisabled(parseInt(lineSeriesElementsWidth) <= width);
    }
  }, [lineSeriesElementsWidth, width]);

  useEffect(() => {
    if (tableRef?.current && columnKey) {
      const lineSeriesCellElements = tableRef.current.getElementsByClassName(columnKey);

      for (let i in lineSeriesCellElements) {
        if ((lineSeriesCellElements[i] as HTMLTableCellElement)?.style) {
          (lineSeriesCellElements[i] as HTMLTableCellElement).style.transform = `translate(${-shift}px, 0%)`;
        }
      }
    }
  }, [rangeLeft]);

  return <ILSTitleLineSeriesComponent rangeLeft={rangeLeft} rangeRight={rangeRight} onChange={onChange} isDisabled={isDisabled} />;
};
