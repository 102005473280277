import { ILSButtonTooltip, ILSPopover } from '@components/index';
import { FC } from 'react';
import { PlannerCreatePlanFormContainer } from '@modules/planning/components/create-plan';
import { useModal } from '@common/hooks/components/use-modal';
import { FileAddOutlined } from '@ant-design/icons/lib/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createPlanRoutine } from '@modules/planning/actions';
import { plansCounterSelector, projectExceededLimitSelector } from '@modules/planning/children/by-date/selectors/project';
import { MAX_PLAN_COUNT } from '@modules/planning/children/by-date/constants';

export const PlannerPlanningCreatePlanButton: FC = () => {
  const { visible, handleShow } = useModal();
  const plansCounter = useSelector(plansCounterSelector);
  const disabled = useSelector(projectExceededLimitSelector);

  //TODO use-handleCreate
  const dispatch = useDispatch();
  const handleCreate = (name: string) => {
    dispatch(createPlanRoutine({ name, getPlan: true }));
  };

  return (
    <ILSPopover
      visible={visible}
      arrowContent={false}
      placement="bottomRight"
      content={<PlannerCreatePlanFormContainer planNumber={plansCounter} handleShowForm={handleShow} handleCreate={handleCreate} />}
      trigger="click"
    >
      <ILSButtonTooltip
        placement="bottom"
        title={disabled ? `Достигнуто максимальное кол-во планов: ${MAX_PLAN_COUNT}` : 'Создать план перевозок'}
        onClick={handleShow}
        disabled={disabled}
        icon={<FileAddOutlined className={'ils-svg primary'} />}
      />
    </ILSPopover>
  );
};
