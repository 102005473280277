import { FC } from 'react';
import { PlannerPlanningSplitSizeComponent } from '@modules/planning/children/by-date/components/result/split-size';
import { usePlanningRedis, usePlanTableMapConnection } from '@modules/planning/children/by-date/hooks';
import { useResultInterface } from '@modules/planning/children/by-date/hooks/components/use-result-interface';
import { useSelectedRowActions } from '@modules/planning/children/by-date/hooks/tables/use-selected-row-actions';

interface IProps {}

export const PlanningResult: FC<IProps> = () => {
  const { onFullChange, blocks, handleResize, fullScreen } = useResultInterface();
  const { onResetSelectedRows } = useSelectedRowActions();

  const {
    setIsAfterDragging,
    markerDragging,
    setMarkerDragging,
    showOrderTable,
    showVehicleTable,
    handleShowVehicleTable,
    handleTriggerMapFocus,
    triggerMapFocus,
    mapRef,
    onItemDrop,
    handleShowOrderTable
  } = usePlanTableMapConnection({ fullScreen, onFullChange, onResetSelectedRows });

  usePlanningRedis();

  return (
    <PlannerPlanningSplitSizeComponent
      mapRefExtraFromContainer={mapRef}
      setTriggerMapFocus={handleTriggerMapFocus}
      handleResize={handleResize}
      onFullChange={onFullChange}
      markerDragging={markerDragging}
      setMarkerDragging={setMarkerDragging}
      showVehicleTable={showVehicleTable}
      showOrderTable={showOrderTable}
      handleShowOrderTable={handleShowOrderTable}
      handleShowVehicleTable={handleShowVehicleTable}
      triggerMapFocus={triggerMapFocus}
      setIsAfterDragging={setIsAfterDragging}
      onItemDrop={onItemDrop}
      initialSizes={blocks.sizes}
    />
  );
};
