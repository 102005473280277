import { ILSTypographyText } from '@components/index';
import { isObject } from 'lodash';
import { IILSCellGeoStringProps } from '@common/types/components/table/cell';
import { JoinChar } from '@common/constants';
import { TableRecord } from '@common/types';

export const ILSCellGeoString = <R extends TableRecord>({
  children,
  record,
  dataIndex,
  toggleEdit
}: IILSCellGeoStringProps<R>): JSX.Element => {
  const [node, childrenCoordinates] = children;
  const coordinates = isObject(childrenCoordinates) ? Object.values(childrenCoordinates).join(JoinChar.Space) : childrenCoordinates;
  const [lat, lon] = (dataIndex && record?.[dataIndex] && Object.values(record[dataIndex])) || [null, null];

  const latLon = coordinates ?? (lat && lon && `${lat.toFixed(6)}, ${lon.toFixed(6)}`);

  return <ILSTypographyText onClick={toggleEdit} className={'cell-wrapper-editable'} ellipsis children={[node, latLon]} />;
};
