import { SelectProps } from 'antd/lib/select';

export enum FormFieldType {
  Int = 'int',
  Float = 'float',
  Date = 'datetime',
  Select = 'select',
  String = 'string'
}

export enum FieldFilteringType {
  String = 'string',
  Array = 'array',
  None = 'none'
}

export interface FormField {
  name: string;
  description?: string;
  alias: string;
  format?: { precision: number; decimalSeparator: string };
  type: FormFieldType;
  variants?: SelectProps['options'];
  default?: any;
  filtering?: FieldFilteringType;
  minValue?: number;
  multiselect?: number;
  visible?: boolean;
  sorting?: boolean;
}
