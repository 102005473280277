import { CellType, TableConfig } from '@common/types';
import { BASE_FIELD_NAMES } from '@common/constants/options';

export enum EventType {
  Undefined = 1,
  Start = 2,
  InRoad = 3,
  Stop = 4
}

export const tableConfig: TableConfig = {
  columns: [
    {
      title: 'Тип',
      dataIndex: 'eventType',
      key: 'eventType',
      active: true,
      type: CellType.Select,
      width: 100,

      sorting: 3,

      ellipsis: true,
      editable: false,
      filtering: 'array',
      fieldNames: BASE_FIELD_NAMES,

      options: [
        { label: '', value: EventType.Undefined },
        { label: 'Старт', value: EventType.Start },
        { label: 'Движение', value: EventType.InRoad },
        { label: 'Остановка', value: EventType.Stop }
      ]
    },
    {
      title: 'Время',
      dataIndex: 'eventDT',
      key: 'eventDT',
      active: true,
      type: CellType.TimeStringUtc,
      width: 120,

      ellipsis: true
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      active: false,
      type: CellType.Input,
      width: 150,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Дорога',
      dataIndex: 'osmRoadName',
      key: 'osmRoadName',
      active: false,
      type: CellType.String,
      width: 150,

      sorting: 3,
      ellipsis: true
    },
    {
      title: 'Скорость',
      dataIndex: 'speed',
      key: 'speed',
      active: false,
      type: CellType.Input,
      width: 100,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Широта',
      dataIndex: 'Latitude',
      key: 'Latitude',
      active: false,
      type: CellType.Input,

      sorting: 3,

      ellipsis: true
    },

    {
      title: 'Долгота',
      dataIndex: 'Longitude',
      key: 'Longitude',
      active: false,
      type: CellType.Input,

      sorting: 3,

      ellipsis: true
    }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  copying: false
};
