import { Dispatch } from 'redux';
import { clearAddressSuggestRoutine } from '@common/feature/suggestion-autocomplete/address/actions';

export const filterTruthyCityValues = (array: Array<any>, filterQuery: string, removeDuplicates?: boolean) => {
  let res = array.map(({ data }) => {
    if (data.city?.toLowerCase().match(filterQuery.toLowerCase())) {
      return data.city;
    }
  });
  if (removeDuplicates) {
    // @ts-ignore
    res = [...new Set(res)].filter(Boolean);
  } else {
    res = res.filter(Boolean);
  }
  return res;
};

export const onCloseAddressesCompleter = (open: boolean, dispatch: Dispatch) => {
  if (!open) {
    dispatch(clearAddressSuggestRoutine());
  }
};
