import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import emulatorReducer from '@modules/emulator/common/reducers';
import { ILSEmulatorContainer } from './common/containers/index';
import * as rootSagas from './common/sagas';

/*
 * Эмулятор - это специальный сервис созданный для внутреннего тестирования модуля Мониторинга.
 * Он позволяет эмулировать всю необходимую информацию: треки, время прибытия и убытия автомобилей из точек маршрута.
 * В настройках предусмотрена регулировка скорости эмуляции (на данный момент работает "мгновенная" настройка скорости (21.03.23)).
 * Основной файл - "входная точка" в модуль находится здесь - src\modules\emulator\index.ts
 */

export default defineModule({
  title: 'Эмулятор исполнения маршрутов',
  path: '/emulator',
  component: ILSEmulatorContainer,
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/emulator.svg',
  reducer: emulatorReducer,
  sagas: getAllModuleSagas(rootSagas),
  persist: false
});
