import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { uploadRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const dataUploadReducer = createReducer(initialState, {
  [uploadRoutine.REQUEST]: onRequest,
  [uploadRoutine.SUCCESS]: (state, action) => {
    const { scheme, data, files } = action.payload;
    state.scheme = scheme;
    state.data = data;
    state.files = files;
    state.conflict = { hasConflict: false, type: null };
    return state;
  },
  [uploadRoutine.FAILURE]: onFailure,
  [uploadRoutine.FULFILL]: onFulfill
});
