import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderOfferAppointResponsibleRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { User } from '@common/types';
import { currentUserSelector } from '@modules/current-user/selectors/auth';

function* tenderOfferAppointResponsibleWorker(
  action: PayloadAction<{
    tenderOfferID: TenderOffer['ID'];
    userID: User['ID'];
  }>
) {
  const { request, success, failure, fulfill } = tenderOfferAppointResponsibleRoutine;
  const { tenderOfferID, userID } = action.payload;

  try {
    yield put(request());
    const user: User = yield select(currentUserSelector);
    if (userID || user.ID) {
      yield call(TenderSearch.appointResponsible, { tenderOfferID, userID: userID ?? user.ID });
    }
    yield put(success({ tenderOfferID, userID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferAppointResponsibleWatcher() {
  yield takeEvery(tenderOfferAppointResponsibleRoutine.trigger, tenderOfferAppointResponsibleWorker);
}
