import { createSelector } from 'reselect';
import { currentTenderSelector, currentTenderStatusConfigSelector } from '@modules/tender-platform/selectors/current-tender';

export const tenderInfoSelector = createSelector(
  currentTenderSelector,
  currentTenderStatusConfigSelector,
  (currentTender, statusConfig) => {
    return {
      tenderUserID: currentTender?.User?.ID,
      statusAlias: statusConfig?.Alias
    };
  }
);
