import { useEffect, useState } from 'react';
import { RightMenuMainTabs } from '@modules/orders-and-trips/types/general';
import { tripsAllDataSelector } from '@modules/orders-and-trips/selectors/trip';
import { useSelector } from 'react-redux';
import { TripStatus } from '../types/trip';
import { RIGHT_BLOCK_TABS_DEFAULT, RIGHT_BLOCK_TABS_WITH_SEARCH_PERFORMER } from '@modules/orders-and-trips/constants/general';

export const useRightTabsContainer = () => {
  const [activeTab, setActiveTab] = useState(RightMenuMainTabs.SearchPerformer);
  const { activeTrip, tripStatus } = useSelector(tripsAllDataSelector);
  const ACTIVE_TABS = tripStatus === TripStatus.New ? RIGHT_BLOCK_TABS_WITH_SEARCH_PERFORMER : RIGHT_BLOCK_TABS_DEFAULT;

  useEffect(() => {
    setActiveTab((prev) => {
      const newActiveTab = Object.keys(ACTIVE_TABS)[0] as RightMenuMainTabs;
      if (newActiveTab !== prev) {
        return newActiveTab;
      }
      return prev;
    });
  }, [ACTIVE_TABS]);

  return {
    activeTab,
    setActiveTab,
    activeTrip,
    tripStatus,
    ACTIVE_TABS
  };
};
