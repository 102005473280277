import { hideEmptyColumns } from '@common/utils/table';
import { FC } from 'react';
import { CellType, DateFormat, DriverUploadFileType } from '@common/types';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { ILSPlanningDataViewTable } from '@modules/data-validate/containers/table';
import { driver2Table } from '@modules/planning/children/data/utils/decorators';

export const ILSDataViewDriverTable: FC<IDataTableProps> = ({ dataSource, hideEmpty, config, dictionary, ...props }) => {
  const tableConfig = hideEmptyColumns(dataSource as DriverUploadFileType[], config, hideEmpty);

  return (
    <ILSPlanningDataViewTable
      hideEmpty={false}
      dictionary={dictionary}
      config={{
        ...tableConfig,
        columns: tableConfig.columns.map((c) => ({
          ...c,
          dateParseFormat: c.type === CellType.Date ? DateFormat.DDMMYYYYPeriod : undefined
        }))
      }}
      dataSource={driver2Table(dataSource as DriverUploadFileType[], dictionary)}
      {...props}
    />
  );
};
