import { createReducer } from '@reduxjs/toolkit';
import { deleteDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';

export const deleteDistanceMatrixReducer = createReducer(initialState, {
  [deleteDistanceMatrixRoutine.REQUEST]: (state) => {
    state.matrix.loading = true;
    return state;
  },
  [deleteDistanceMatrixRoutine.SUCCESS]: (state) => {
    state.matrix.status = { LastUpdated: state.matrix.status?.LastUpdated || null, LastSize: null, LastCalc: null, LastNull: null };
    return state;
  },
  [deleteDistanceMatrixRoutine.FULFILL]: (state) => {
    state.matrix.loading = false;
    return state;
  }
});
