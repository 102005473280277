import { ILSFormItem, ILSInput, ILSTooltip, ILSTypographyText } from '@common/components';
import { RecordType, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { FC } from 'react';
import { SchemeKeyType } from '@modules/planning/children/scheme-editor/constants';
import { getInfoName } from '@modules/planning/children/scheme-editor/utils/helpers/item-type-helpers';
import { SchemeTypeSelect } from '@modules/planning/children/scheme-editor/components/scheme-type-select';

interface IProps {
  item: RecordType;
  scheme: SchemeSwitchDictionary;
}

export const InfoCard: FC<IProps> = ({ scheme, item }) => {
  const preventDragging = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.focus();
  };

  return (
    <>
      <ILSFormItem initialValue={item.type} name={[scheme, item.key, 'type']} style={{ display: 'none' }} />
      <ILSTooltip title={item.key}>
        <ILSTypographyText className={'scheme-editor-scheme-input-title'} children={'Info@'} />
      </ILSTooltip>
      <ILSFormItem name={[scheme, item.key, 'partKey']}>
        <ILSInput defaultValue={getInfoName(item.key)} onPointerDown={preventDragging} />
      </ILSFormItem>
      <SchemeTypeSelect dropdownMatchSelectWidth={false} options={item.options} value={SchemeKeyType.STR} />
      <ILSTooltip title={item.description}>
        <ILSFormItem name={[scheme, item.key, 'description']}>
          <ILSInput defaultValue={item.description} onPointerDown={preventDragging} />
        </ILSFormItem>
      </ILSTooltip>
    </>
  );
};
