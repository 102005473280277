export const SEARCH_BENCHMARK_START = 'benchmark=enabled';
export const SEARCH_BENCHMARK_START_SOFT = 'benchmark=soft';
const empty = {
  stop: () => {}
}; // для уменьшения затрат памяти
export const benchmarkTimer = function (name: string, timeout?: number) {
  const dictionary = !window?.location?.search || typeof window?.location?.search !== 'string' || window?.location?.search.length < 1;
  const dictEnable = !dictionary && window?.location?.search.indexOf(SEARCH_BENCHMARK_START) !== -1;
  const dictSoft = !dictionary && window?.location?.search.indexOf(SEARCH_BENCHMARK_START_SOFT) !== -1;
  const dictNumber =
    !dictionary &&
    window?.location?.search.match(/(?:benchmark=)(\d)+/) &&
    Array.isArray(window.location.search.match(/(?:benchmark=)(\d)+/))
      ? window.location.search.match(/(?:benchmark=)(\d)+/)
      : false;
  if (dictionary && (!dictEnable || !dictSoft || !(dictNumber && dictNumber[1].length))) {
    return empty;
  } else {
    const startTime = new Date();
    return {
      stop: function () {
        const endTime = new Date();
        let timeSpent = endTime.getTime() - startTime.getTime();
        if (dictSoft || (dictNumber && dictNumber[1].length)) {
          if (dictSoft && typeof timeout === 'number' && timeout < timeSpent) {
            console.warn(`Bench: ${name} закончено за: ${timeSpent}ms`);
          }
          if (dictNumber && dictNumber[1].length && Number(dictNumber[1]) < timeSpent) {
            console.warn(`Bench: ${name} закончено за: ${timeSpent}ms`);
          }
        } else {
          console.warn(`Bench: ${name} закончено за: ${timeSpent}ms`);
        }
      }
    };
  }
};
