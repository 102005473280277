import { ILSMap } from '@core/containers/map';
import L from 'leaflet';
import { MutableRefObject, useEffect, useState } from 'react';
import { useAppSelector } from '@core/hooks';
import { activeTabSelector, tracksSelector, vehicleSensorSelector } from '../selectors';
import { TabType } from '../types/event-details-tabs';
import { createEventMarker } from '../utils/map/map-event-marker';
import { isEmpty } from 'lodash';

// Устанавливает маркеры событий на карте
export const useEventMarker = (args: { Map: MutableRefObject<ILSMap | null>; cluster?: L.LayerGroup }) => {
  const { Map, cluster } = args;
  const [eventMarkers, setEventMarkers] = useState({});
  const selectedVehicleId = useAppSelector(tracksSelector)?.selectedVehicleId;
  const vehicleSensor = useAppSelector(vehicleSensorSelector);
  const trackEvents = useAppSelector(tracksSelector);
  const activeTab = useAppSelector(activeTabSelector);
  const curMap = Map.current;

  useEffect(() => {
    if (curMap && cluster) {
      let incomingMarkers = {};
      if (selectedVehicleId && activeTab === TabType.Bdd && Object.values(trackEvents.data)?.length) {
        for (const vehicleID in vehicleSensor.data) {
          const vehicle = vehicleSensor.data[vehicleID];
          if (isEmpty(vehicle?.VehicleTracker)) continue;

          for (const IMEI in vehicle.VehicleTracker) {
            const vehicleTracker = vehicle.VehicleTracker[IMEI];

            if (!vehicleTracker.SensorMapping) continue;
            for (const sensorType in vehicleTracker.SensorMapping) {
              const sensorsItem = vehicleTracker.SensorMapping[sensorType];
              if (!sensorsItem.SensorValue) continue;
              for (const ind in sensorsItem.SensorValue) {
                const event = sensorsItem.SensorValue[ind];
                const track = trackEvents.data?.[IMEI]?.Tracker?.Track?.[event.DT];
                if (event && track) {
                  const marker = {
                    ID: sensorType + '#' + event.Imei + '#' + event.DT,
                    coords: [track?.Latitude, track?.Longitude] as [number, number],
                    EventStatusType: Number(sensorType)
                  };

                  let lMark = createEventMarker(marker)?.L;
                  incomingMarkers[marker.ID] = {
                    ...marker,
                    lMark
                  };
                }
              }
            }
          }
        }
      }

      if (Object.keys(eventMarkers).length) {
        for (const key in eventMarkers) {
          if (incomingMarkers[key]) {
            incomingMarkers[key].lMark = eventMarkers[key].lMark;
          } else {
            cluster?.removeLayer(eventMarkers[key].lMark);
          }
        }
        for (const key in incomingMarkers) {
          if (!eventMarkers[key]) {
            Map.current?.addAnyLayer(incomingMarkers[key].lMark, cluster);
          }
        }
      } else {
        for (const key in incomingMarkers) {
          Map.current?.addAnyLayer(incomingMarkers[key].lMark, cluster);
        }
      }
      setEventMarkers(incomingMarkers);
    }
  }, [Map, cluster, vehicleSensor, selectedVehicleId, trackEvents, activeTab]);
};

