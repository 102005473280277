import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { Account } from '@common/types';
import { getAccountRoutine } from '@modules/current-user/actions';

export const accountReducer = createReducer(authInitialState, {
  [getAccountRoutine.REQUEST]: onRequest,
  [getAccountRoutine.FAILURE]: onFailure,
  [getAccountRoutine.FULFILL]: onFulfill,
  [getAccountRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      account: Account;
    }>
  ) => {
    const { account } = action.payload;
    state.account = account;
    return state;
  }
});
