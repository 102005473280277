import reduceReducers from 'reduce-reducers';
import { initialState } from '@modules/orders-and-trips/store';
import { showFormReducer } from '@modules/orders-and-trips/reducers/show-form';
import { getUnresolvedOrdersReducer } from '@modules/orders-and-trips/reducers/get-unresolved-orders';
import { getRestrictionReducer } from '@modules/orders-and-trips/reducers/get-restriction';
import { getDepotReducer } from '@modules/orders-and-trips/reducers/get-depot';
import { createOrderReducer } from '@modules/orders-and-trips/reducers/create-order';
import { activeRecordReducer } from '@modules/orders-and-trips/reducers/active-record';
import { switchTripStatusReducer } from '@modules/orders-and-trips/reducers/switch-trip-status';
import { getTripNOrderDataReducer } from '@modules/orders-and-trips/reducers/get-trip-and-order-data';
import { getTripsReducer } from '@modules/orders-and-trips/reducers/get-trips';
import { getVehicleTypesReducer } from '@modules/orders-and-trips/reducers/get-vehicle-types';
import { createTripReducer } from '@modules/orders-and-trips/reducers/create-trip';
import { getTripsCountReducer } from '@modules/orders-and-trips/reducers/get-trips-count';
import { getAvailableIntegrationsReducer } from '@modules/orders-and-trips/reducers/get-available-integrations';
import { getPreCostReducer } from '@modules/orders-and-trips/reducers/get-pre-cost';
import { getTripDocumentsReducer } from '@modules/orders-and-trips/reducers/get-trip-documents';
import { uploadTripDocumentsReducer } from '@modules/orders-and-trips/reducers/upload-trip-documents';
import { deleteTripDocumentsReducer } from '@modules/orders-and-trips/reducers/delete-trip-document';
import { getTripTimelineReducer } from '@modules/orders-and-trips/reducers/get-trip-timeline';
import { deleteTripReducer } from '@modules/orders-and-trips/reducers/delete-trip';
import { updateOrderReducer } from '@modules/orders-and-trips/reducers/update-order';
import { deleteOrderReducer } from '@modules/orders-and-trips/reducers/delete-order';
import { getRouteByCoordsReducer } from '@modules/orders-and-trips/reducers/get-route-by-coords';
import { switchActiveMainTabReducer } from '@modules/orders-and-trips/reducers/switch-main-tab';
import { getTripContractReducer } from '@modules/orders-and-trips/reducers/get-trip-contract';
import { updateTripReducer } from '@modules/orders-and-trips/reducers/update-trip';
import { setActiveModalWindowReducer } from '@modules/orders-and-trips/reducers/set-active-modal-window';
import { getTotalCountReducer } from '@modules/orders-and-trips/reducers/get-total-count';
import { deletePreCostReducer } from '@modules/orders-and-trips/reducers/delete-pre-cost';
import { importOrdersReducer } from '@modules/orders-and-trips/reducers/import-orders';
import { getFakePreCostReducer } from '@modules/orders-and-trips/reducers/get-fake-pre-cost';
import { clearStateReducer } from '@modules/orders-and-trips/reducers/clear-state';
import { getFakeTripContractReducer } from '@modules/orders-and-trips/reducers/get-fake-trip-contract';

const ordersNTripsRootReducer = reduceReducers(
  initialState,
  showFormReducer,
  getUnresolvedOrdersReducer,
  getRestrictionReducer,
  getDepotReducer,
  createOrderReducer,
  activeRecordReducer,
  switchTripStatusReducer,
  getTripNOrderDataReducer,
  getTripsReducer,
  getVehicleTypesReducer,
  createTripReducer,
  getTripsCountReducer,
  getAvailableIntegrationsReducer,
  getPreCostReducer,
  updateOrderReducer,
  getTripDocumentsReducer,
  uploadTripDocumentsReducer,
  deleteTripDocumentsReducer,
  getTripTimelineReducer,
  deleteOrderReducer,
  deleteTripReducer,
  getRouteByCoordsReducer,
  switchActiveMainTabReducer,
  getTripContractReducer,
  updateTripReducer,
  setActiveModalWindowReducer,
  getTotalCountReducer,
  deletePreCostReducer,
  importOrdersReducer,
  getFakePreCostReducer,
  clearStateReducer,
  getFakeTripContractReducer
);

export default ordersNTripsRootReducer;
