import { useDispatch, useSelector } from 'react-redux';
import { planningClearRoutine, planSaveRoutine } from '@modules/planning/children/by-date/actions';
import { ILSUserNotify } from '@components/index';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { plansIDsSelector, projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { ModulePath, Notify } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { stringifyUrl } from 'query-string';
import { MAX_PLAN_COUNT } from '@modules/planning/children/by-date/constants/planning-config';
import { useHandleExecution } from '@modules/planning/children/by-date/hooks/tables/plans/use-handle-execution';
import { getExecutionParameters } from '@modules/planning/children/by-date/selectors/components/execute-button';
import {
  activePlanProviderSelector,
  isPlanFactExecutionStarted,
  isPlanStatusBeforeExecution
} from '@modules/planning/children/by-date/selectors/active-plan';
import { useDeletePlan } from '@modules/planning/children/by-date/hooks/tables/plans/use-delete-plan';
import { useCopyPlan } from '@modules/planning/children/by-date/hooks/tables/plans/use-copy-plan';
import { PlanningByDateState, PlansTableRecord } from '@modules/planning/children/by-date/types';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { planPlanningStartTimeSelector } from '@modules/planning/children/by-date/selectors/planning';

export const getPlanContextMenu = ({
  setTriggerMapFocus,
  record: { ID, FullName, Status, dictionary },
  handleShowOrderTable
}: {
  record: PlansTableRecord;
  setTriggerMapFocus: SetTriggerMapFocus;
  handleShowOrderTable: (val: boolean, rowID?: number) => void;
}) => {
  const dispatch = useDispatch();
  const ProviderName = useSelector(activePlanProviderSelector);
  const PlanningStart = useSelector((state: PlanningByDateState) => planPlanningStartTimeSelector(state, ID));
  const planIDs = useSelector(plansIDsSelector);
  const projectID = useSelector(projectIDSelector);
  const isPlanBeforeExecution = isPlanStatusBeforeExecution(Status);
  const isPlanNotEditable = isPlanFactExecutionStarted(Status);

  const { btnText, disabled } = getExecutionParameters({ activePlanID: ID, isPlanBeforeExecution, isPlanNotEditable });
  const { handleDelete } = useDeletePlan();
  const { handleSetExecution, handleUnsetExecution } = useHandleExecution();
  const { handleCopy } = useCopyPlan();

  const link = stringifyUrl({
    url: '/pages/track',
    query: {
      dictionary,
      project: projectID,
      plan: ID,
      style: 3
    }
  });

  const analyticLink = PlanningStart
    ? stringifyUrl(
        {
          url: `/pages${ModulePath.PlanAnalytic}`,
          query: {
            plan: ID,
            timeFromStart: PlanningStart,
            provider: ProviderName ?? EMPTY_STRING
          }
        },
        {
          skipNull: true,
          skipEmptyString: true,
          sort: false
        }
      )
    : null;

  const copyPlan = () => {
    if (ID) {
      ILSUserNotifyConfirm(`Копировать выбранный план ${FullName}?`, undefined, () => handleCopy([ID]));
    } else {
      ILSUserNotify(Notify.Error, `Ошибка копирования плана ${FullName}`);
    }
  };

  const deletePlan = (record: PlansTableRecord) => ILSUserNotifyConfirm('Удалить выбранный план?', undefined, () => handleDelete(record));
  //очистка плана
  const handleCLear = () => {
    ID && dispatch(planningClearRoutine({ planID: ID }));
    handleShowOrderTable?.(false);
  };

  //изменение плана
  const handlePlanChange = (record: PlansTableRecord, key: string, value: unknown) => {
    dispatch(
      planSaveRoutine({
        record,
        key,
        value,
        planID: ID
      })
    );
  };

  const canEditPlan = !disabled && isPlanBeforeExecution;

  const handleExecution = (record: PlansTableRecord) => {
    isPlanBeforeExecution ? handleSetExecution(record.ID) : handleUnsetExecution(record.ID);
  };

  return [
    [
      {
        text: 'Перейти к объекту на карте',
        handler: setTriggerMapFocus ? (record: PlansTableRecord) => setTriggerMapFocus([record]) : undefined
      },
      {
        text: 'Открыть на отдельной странице',
        link
      }
    ],
    [
      {
        text: 'Изменить название',
        handler: handlePlanChange,
        confirm: true,
        field: 'Name'
      }
    ],
    !disabled && [
      {
        text: btnText,
        handler: handleExecution
      }
    ],
    [
      planIDs.length < MAX_PLAN_COUNT && {
        text: 'Скопировать план перевозок',
        handler: copyPlan
      },
      canEditPlan && {
        text: 'Очистить план перевозок',
        handler: handleCLear
      },
      analyticLink && {
        text: 'Показать статистику планирования',
        link: analyticLink
      }
    ],
    canEditPlan && [
      {
        text: 'Удалить план',
        handler: deletePlan
      }
    ]
  ];
};
