import { IEnum, Order, Restriction, Vehicle } from '@common/types';
import { createSelector } from 'reselect';
import { projectOrderSelector, vehiclesSelector } from '@modules/planning/children/by-date/selectors/project';
import { createArrayFromIndexedArray } from '@common/utils';
import { planRoutesSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { RouteTotal } from '@common/types/dictionaries/route';

type ModelWithRestrictionEnum = { RestrictionEnum?: IEnum<Restriction['Name']> };
type ArrayModelWithRestrictionEnum<M extends ModelWithRestrictionEnum> = Array<M>;

export const activePlanRestrictionDataSelector = createSelector(
  projectOrderSelector,
  vehiclesSelector,
  planRoutesSelector,
  (projectOrder, projectVehicle, planRoutes) => {
    const orders = createArrayFromIndexedArray(projectOrder);
    const vehicles = createArrayFromIndexedArray(projectVehicle);
    const routes = createArrayFromIndexedArray(planRoutes);
    const planModelsWithRestrictionEnum = ([] as ArrayModelWithRestrictionEnum<Order | Vehicle | RouteTotal>).concat(
      orders,
      vehicles,
      routes
    );

    return planModelsWithRestrictionEnum.reduce((restrictionEnums, { RestrictionEnum }) => {
      return { ...restrictionEnums, ...RestrictionEnum };
    }, {});
  }
);
