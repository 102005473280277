import { ILSRangePicker } from '@common/components';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { getValidMoment, parseDateFromServer } from '@common/utils/helpers/date-time';
import { EMPTY_STRING } from '@common/constants';
import { DateFormat } from '@common/types';

interface Props {
  handleDate: (date: string[]) => void;
  maxInterval: string | number | boolean | undefined;
  startDate: string | number | undefined;
  endDate: string | number | undefined;
  changeDateFormat: (date: string) => string;
  DATE_FORMAT: DateFormat;
  MAX_DAYS_INTERVAL: number;
}

export const ILSRangePickerIntervalComponent = (props: Props) => {
  const { handleDate, maxInterval, startDate, endDate, changeDateFormat, DATE_FORMAT, MAX_DAYS_INTERVAL } = props;

  const [selectedStart, setSelectedStart] = useState<string>(EMPTY_STRING);
  const [selectedEnd, setSelectedEnd] = useState<string>(EMPTY_STRING);

  //NOTE: Стейт для размонтирования компонента, необходимо для сброса календаря до текущего месяца, при удалении значений в строке RangePicker'a
  const [keyRangePicker, setKeyRangePicker] = useState(false);

  const changeHandler = (date: string[] | string) => {
    if (typeof date === 'string') {
      if (date) {
        handleDate([changeDateFormat(date), changeDateFormat(date)]);
      } else {
        handleClear();
      }
      return;
    } else if (!Array.isArray(date) && !date[0] && !date[1]) {
      handleClear();
      return;
    }

    const changedDate = [!date[0] ? '' : changeDateFormat(date[0]), !date[1] ? '' : changeDateFormat(date[1])];

    setSelectedStart(changedDate[0]);
    setSelectedEnd(changedDate[1]);

    handleDate(changedDate);
  };

  const handleClear = () => {
    setSelectedStart(EMPTY_STRING);
    setSelectedEnd(EMPTY_STRING);

    handleDate([EMPTY_STRING, EMPTY_STRING]);

    //NOTE: При изменении стейта, компонент RangePicker будет размонтирован, а затем смонтирован новый экземпляр компонента, так как ключ изменится.
    setKeyRangePicker((prev) => {
      return !prev;
    });
  };

  const disabledDate = (d: Moment) => {
    if (selectedStart && !selectedEnd) {
      return (
        d.startOf('day') >
        moment(selectedStart)
          .startOf('day')
          .add(Number(maxInterval || MAX_DAYS_INTERVAL) - 1, 'day')
      );
    }
    if (!selectedStart && selectedEnd) {
      return (
        d.startOf('day') <
        moment(selectedEnd)
          .startOf('day')
          .subtract(Number(maxInterval || MAX_DAYS_INTERVAL) - 1, 'day')
      );
    }
    return false;
  };

  //@ts-ignore
  const rangeProps: RangePickerProps = useMemo(() => {
    const currentRangeDate = [
      getValidMoment(parseDateFromServer(startDate, false, DateFormat.YYYYMMDDDash)),
      getValidMoment(parseDateFromServer(endDate, false, DateFormat.YYYYMMDDDash))
    ];
    return {
      className: 'range-picker',
      onCalendarChange: (momentDates: any, date: string[]) => {
        if (!momentDates) {
          setSelectedStart(EMPTY_STRING);
          setSelectedEnd(EMPTY_STRING);
          handleClear();
        } else if (date[0] && !date[1]) {
          setSelectedStart(date[0]);
          setSelectedEnd(EMPTY_STRING);
          changeHandler([date[0], EMPTY_STRING]);
        } else if (!date[0] && date[1]) {
          setSelectedStart(EMPTY_STRING);
          setSelectedEnd(date[1]);
          changeHandler([EMPTY_STRING, date[1]]);
        } else if (date[0] && date[1]) {
          setSelectedStart(date[0]);
          setSelectedEnd(date[1]);
          changeHandler(date);

          if (
            moment(date[1], DateFormat.DDMMYYYYPeriod).diff(moment(date[0], DateFormat.DDMMYYYYPeriod), 'days') >
            Number(maxInterval || MAX_DAYS_INTERVAL)
          ) {
            if (startDate !== changeDateFormat(date[0])) {
              setSelectedStart(date[0]);
              setSelectedEnd(EMPTY_STRING);
              changeHandler([date[0], EMPTY_STRING]);
            }

            if (endDate !== changeDateFormat(date[1])) {
              setSelectedStart(EMPTY_STRING);
              setSelectedEnd(date[1]);
              changeHandler([EMPTY_STRING, date[1]]);
            }
          }
        }
      },
      picker: 'date',
      format: DATE_FORMAT,
      disabledDate,
      defaultValue: currentRangeDate,
      value: currentRangeDate
    };
  }, [startDate, endDate]);

  return (
    <ILSRangePicker
      {...rangeProps}
      //@ts-ignore
      key={keyRangePicker}
    />
  );
};
