import {
  ScheduleType,
  SeparateVehicle,
  SeparateVehicleLabel,
  TenderType,
  TenderVisibilityStatus,
  VehicleLoadType,
  VehicleLoadTypeLabel
} from '@modules/tender-platform/types';
import { TenderTypeLabel } from '@modules/tender-platform/types/tender';
import { IsTemplate, IsTemplateLabel, PositionType } from '@modules/tender-platform/types/lot';
import { VAT_OPTIONS } from '@common/constants';

export const SEPARATE_VEHICLE_OPTIONS = [
  { label: SeparateVehicleLabel.No, value: SeparateVehicle.No },
  { label: SeparateVehicleLabel.Yes, value: SeparateVehicle.Yes }
];

export const IS_TEMPLATE_OPTIONS = [
  { label: IsTemplateLabel.No, value: IsTemplate.No },
  { label: IsTemplateLabel.Yes, value: IsTemplate.Yes }
];

export const LOAD_TYPE_OPTIONS = [
  { label: VehicleLoadTypeLabel.Up, value: VehicleLoadType.Up.toString() },
  { label: VehicleLoadTypeLabel.Side, value: VehicleLoadType.Side.toString() },
  { label: VehicleLoadTypeLabel.Back, value: VehicleLoadType.Back.toString() }
];

export const TENDER_TYPE_OPTIONS = [
  { label: TenderTypeLabel.Competition, value: TenderType.Competition },
  { label: TenderTypeLabel.Auction, value: TenderType.Auction }
  //TODO: раскомментировать строки, когда будет готов функционал
  //{ label: TenderTypeLabel.RequestForProposal, value: TenderType.RequestForProposal },
  //{ label: TenderTypeLabel.RequestForInformation, value: TenderType.RequestForInformation }
];

export const TENDER_VISIBILITY_OPTIONS = [
  { label: 'Открытый', value: TenderVisibilityStatus.Open },
  { label: 'Закрытый', value: TenderVisibilityStatus.Close }
];

export const LOT_SCHEDUAL_OPTIONS = [
  { value: ScheduleType.Dates, label: 'Выбранные даты' },
  {
    value: ScheduleType.Weekdays,
    label: 'По дням недели'
  },
  { value: ScheduleType.Interval, label: 'Через N дней' }
];

export const LOT_POSITION_VOLUME_OPTIONS = [
  { value: PositionType.WHL, label: 'Д/Ш/В' },
  {
    value: PositionType.Volume,
    label: 'Объём'
  }
];

export const TENDER_VAT_OPTIONS = VAT_OPTIONS.slice(0, 2);
