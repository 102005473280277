import { ACCOUNT_FACSIMILE } from '@modules/account/constants/account';
import { AccountFileDragger } from '@modules/account/components/attachments/file-dragger';
import { FC } from 'react';
import { AccountFileAttachment } from '@modules/account/types/attachments';
import { RcFile } from 'antd/lib/upload';
import { AccountAttachmentSubject } from '@common/types';

interface IProps {
  documents: AccountFileAttachment[] | null;
  handleDeleteDocument(id: number): void;
  handleCheckBeforeUpload(file: RcFile, subject: AccountAttachmentSubject): void;
}

export const AccountFacsimileComponent: FC<IProps> = ({ handleDeleteDocument, documents, handleCheckBeforeUpload }) => {
  return (
    <div className="extra-attachments-facsimile">
      {ACCOUNT_FACSIMILE.map(({ subject, title, description, permittedFiles }) => {
        const documentsBySubject = documents?.filter((document) => document.type === subject);
        return (
          <AccountFileDragger
            title={title}
            description={description}
            handleDeleteDocument={handleDeleteDocument}
            documentsBySubject={documentsBySubject}
            permittedFileTypes={permittedFiles}
            subject={subject}
            handleCheckBeforeUpload={handleCheckBeforeUpload}
            key={title + subject}
          />
        );
      })}
    </div>
  );
};
