import { FC } from 'react';
import { ILSBadge, ILSFormItem, ILSSlider } from '@common/components';
import { TenderFormField, TenderFormLabel } from '@modules/tender-platform/constants/content';
import { RATING } from '@common/constants/custom/rating';
import { useWatch } from 'antd/es/form/Form';

interface IProps {
  label: TenderFormLabel;
  name: TenderFormField;
  inputValue?: number;
  disabled?: boolean;
}

export const ILSTenderRating: FC<IProps> = ({ label, name, inputValue = RATING.min, disabled = false }) => {
  const value = useWatch(name);
  const rating = name === TenderFormField.MinDriverRoutes ? { ...RATING, step: value < 10 ? 1 : 10, max: 100 } : RATING;
  return (
    <ILSBadge showZero className={'ils-tender-form-rating-counter'} count={value ?? inputValue}>
      <ILSFormItem className={'ils-tender-form-rating-input'} label={label} name={name}>
        <ILSSlider {...rating} value={value} tooltipPlacement={'left'} disabled={disabled} />
      </ILSFormItem>
    </ILSBadge>
  );
};
