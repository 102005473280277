import { getSRCIconSVG } from '@common/decorators/path';
import { ReactSVG } from 'react-svg';
import { IILSCellIconActionProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellIconAction = <R extends TableRecord>({ record, dataIndex }: IILSCellIconActionProps<R>): JSX.Element | null => {
  if (dataIndex && record?.[dataIndex]) {
    const { icon, onClick, ...recProps } = record[dataIndex];
    const handleClick = () => onClick?.(record);
    return (
      <div className="cell-wrapper-icon-action" onClick={handleClick} {...recProps}>
        {icon && <ReactSVG className="svg-container" src={getSRCIconSVG(icon)} />}
      </div>
    );
  }
  return null;
};
