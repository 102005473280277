import { ReactNode } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

export const ILSUserNotifyConfirmCustom = (
  title: ReactNode,
  content: ReactNode,
  onOk: (() => void) | undefined,
  onCancel: (() => void) | undefined,
  icon: ReactNode | undefined,
  footer: ReactNode
) => {
  const contentWithFooter = (
    <>
      {content}
      <hr />
      {footer}
    </>
  );

  return confirm({
    title: title,
    content: contentWithFooter,
    icon: icon ?? <ExclamationCircleOutlined />,
    onOk: onOk,
    onCancel: onCancel,
    okButtonProps: { style: { display: 'none' } },
    cancelButtonProps: { style: { display: 'none' } }
  });
};
