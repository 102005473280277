import { RecordTableButton } from '@common/types';
import { ILSDashboardViewedSwitcherComponent } from '../components/viewed-switcher';

// Добавляет в таблицы кнопку просмотрено / не просмотрено
export const addToTableShowViewedButton = (
  recordTableButtons: Array<RecordTableButton> | undefined,
  checked: boolean,
  onChange: (val: boolean) => void
) => {
  const viewedButton = [
    {
      Component: ILSDashboardViewedSwitcherComponent,
      props: {
        placement: 'left',
        checked,
        onChange
      }
    }
  ];

  if (recordTableButtons) {
    return recordTableButtons?.concat(viewedButton);
  } else {
    return viewedButton;
  }
};

export const tableViewedFilter: <T extends { viewed: 0 | 1 }>(dataSource: Array<T>, showAll: boolean) => Array<T> = (
  dataSource,
  showAll
) => {
  if (!showAll) {
    return dataSource.filter((item) => !item?.viewed);
  }
  return dataSource;
};

export const hasUnviewed: <T extends { viewed: 0 | 1 }>(dataSource: Array<T>) => boolean = (dataSource) => {
  return !!dataSource.find((item) => !item?.viewed);
};
