import { call, put, takeLatest } from 'redux-saga/effects';
import { updateOrderRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { RequestOrderData } from '@modules/orders-and-trips/types/form';

function* updateOrderWorker(
  action: PayloadAction<{
    id: number;
    orderData: RequestOrderData;
  }>
) {
  const { request, success, failure, fulfill } = updateOrderRoutine;
  const { id, orderData } = action.payload ?? {};
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.updateUnallocatedOrder, { id, ...orderData });
    yield put(success({ order: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* updateOrderWatcher() {
  yield takeLatest(updateOrderRoutine.trigger, updateOrderWorker);
}

