import { IndexedArray } from '@common/types';
import { createModuleAction } from '@common/utils/general/create-module-action';
import { AnalyticsActionsTypes, getCommonAnalyticsActions } from '@modules/analytics/common/actions';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';

const MODULE_NAME = 'Analytics_DELIVERY';

export enum ModuleActionType {
  Get_Widget_Catalog = 'GET_WIDGET_CATALOG',
  Create_Board_Set = 'CREATE_BOARD_SET',
  Copy_Board_Set = 'COPY_BOARD_SET',
  Update_Board_Set = 'UPDATE_BOARD_SET',
  Delete_Board_Set = 'DELETE_BOARD_SET',
  Sort_Board_Set = 'SORT_BOARD_SET',
  Create_Board = 'CREATE_BOARD',
  Copy_Board = 'COPY_BOARD',
  Update_Board = 'UPDATE_BOARD',
  Delete_Board = 'DELETE_BOARD',
  Sort_Boards = 'SORT_BOARDS',
  Set_Board_Widget = 'SET_BOARD_WIDGET',
  Set_Board_Widget_Batch = 'SET_BOARD_WIDGET_BATCH',
  Mark_Widget = 'MARK_WIDGET',
  Delete_Widget = 'DELETE_WIDGET',
  Drop_Board_Widget = 'DROP_BOARD_WIDGET',
  Batch = 'Batch'
}

export type AnalyticsPlanningActionsType = AnalyticsActionsTypes | ModuleActionType;

export const createAnalyticsRoutine = (name: AnalyticsPlanningActionsType) => createRoutine(createModuleAction(MODULE_NAME, name));

export const analyticsPlanningActions = getCommonAnalyticsActions(MODULE_NAME);

export const widgetCatalogRoutine = createAnalyticsRoutine(ModuleActionType.Get_Widget_Catalog);

export const createBoardSetRoutine = createAnalyticsRoutine(ModuleActionType.Create_Board_Set);
export const copyBoardSetRoutine = createAnalyticsRoutine(ModuleActionType.Copy_Board_Set);
export const updateBoardSetRoutine = createAnalyticsRoutine(ModuleActionType.Update_Board_Set);
export const deleteBoardSetRoutine = createAnalyticsRoutine(ModuleActionType.Delete_Board_Set);
export const sortBoardSetsRoutine = createAnalyticsRoutine(ModuleActionType.Sort_Board_Set);
export const sortBoardsRoutine = createAnalyticsRoutine(ModuleActionType.Sort_Boards);

export const createBoardRoutine = createAnalyticsRoutine(ModuleActionType.Create_Board);
export const copyBoardRoutine = createAnalyticsRoutine(ModuleActionType.Copy_Board);
export const updateBoardRoutine = createAnalyticsRoutine(ModuleActionType.Update_Board);
export const deleteBoardRoutine = createAnalyticsRoutine(ModuleActionType.Delete_Board);
export const setBoardWidget = createAnalyticsRoutine(ModuleActionType.Set_Board_Widget);
export const setBoardWidgetBatch = createAnalyticsRoutine(ModuleActionType.Set_Board_Widget_Batch);
export const markBoardWidget = createAnalyticsRoutine(ModuleActionType.Mark_Widget);
export const deleteBoardWidget = createAnalyticsRoutine(ModuleActionType.Delete_Widget);
export const dropBoardWidgetRoutine = createAnalyticsRoutine(ModuleActionType.Drop_Board_Widget);

export const routines: IndexedArray<UnifiedRoutine> = {
  [ModuleActionType.Sort_Boards]: sortBoardsRoutine,
  [ModuleActionType.Sort_Board_Set]: sortBoardSetsRoutine,
  [ModuleActionType.Update_Board]: updateBoardRoutine,
  [ModuleActionType.Update_Board_Set]: updateBoardSetRoutine,
  [ModuleActionType.Copy_Board]: copyBoardRoutine,
  [ModuleActionType.Copy_Board_Set]: copyBoardSetRoutine,
  [ModuleActionType.Create_Board]: createBoardRoutine,
  [ModuleActionType.Create_Board_Set]: createBoardSetRoutine
};

export const commonRoutine = createAnalyticsRoutine(AnalyticsActionsTypes.BATCH);

export const AnalyticsPlanningRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  analyticsPlanningActions.analyticsGetBoardWidgetRoutine,
  analyticsPlanningActions.analyticsCommonGetBoardsRoutine,
  analyticsPlanningActions.analyticsCommonGetBoardSetsRoutine
];

export const AnalyticsPlanningRoutinesToNotifyErrors: UnifiedRoutine<(payload?: any) => Action>[] = [
  copyBoardRoutine,
  createBoardRoutine,
  createBoardSetRoutine,
  copyBoardSetRoutine,
  deleteBoardWidget,
  deleteBoardRoutine,
  updateBoardRoutine,
  setBoardWidgetBatch
];
