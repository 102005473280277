import { Moment } from 'moment';
import { prepareDateToServer } from '@common/utils';
import { IEditableCell, IEditableCellProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

type Args<R extends TableRecord> = {
  save: IEditableCellProps<R>['save'];
  dateParseFormat: IEditableCell<R>['dateParseFormat'];
  showTime?: boolean;
};

export const handleDatePickerChange =
  <R extends TableRecord>({ save, dateParseFormat, showTime }: Args<R>) =>
  (date: Moment | null) => {
    if (!date) {
      save(null);
    } else {
      showTime && save(prepareDateToServer(date, dateParseFormat, showTime));
    }
    return;
  };

export const handleDatePickerSelect =
  <R extends TableRecord>({ save, dateParseFormat, showTime }: Args<R>) =>
  (date: Moment | null) => {
    if (showTime || !date) return;
    save(prepareDateToServer(date, dateParseFormat, showTime));
  };
