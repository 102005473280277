import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILSTable } from '@components/index';
import { ColumnTable, DictionariesEndpoint, Dictionary, RoadTrainTableColumn, TableRecord, VehicleTableColumn } from '@common/types';
import { generateUUID } from '@common/utils/general/uuid';
import { IDataTableProps } from '@modules/planning/children/data/types';
import { fetchSelector, plannerDataViewTableSelector } from '@modules/planning/children/data/selectors';
import { hideEmptyColumns } from '@common/utils/table';
import { tableDataChangeRoutine } from '@modules/planning/children/data/actions';
import { TABLE_TABLE_TO_DATA_DECORATORS } from '@common/models/all';

interface IProps {
  dictionary: Dictionary;
  hideEmpty: boolean;
}

export const PlannerDataViewTable: FC<IProps> = ({ dictionary, hideEmpty }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(fetchSelector);
  const { config, dataSource } = useSelector(plannerDataViewTableSelector(dictionary));

  const handleChange: IDataTableProps['handleChange'] = (payload) => {
    dispatch(tableDataChangeRoutine(payload));
  };

  const handleUpdate = (record: any, cell: ColumnTable<TableRecord>['dataIndex'], value: string, rowIndex: number) => {
    handleChange({
      row: record.fileIndex ?? Number(rowIndex),
      cell,
      value: TABLE_TABLE_TO_DATA_DECORATORS[dictionary]?.(record, cell, value) ?? value,
      file: dictionary,
      operation: DictionariesEndpoint.Update,
      key: record.key
    });
  };

  const handleDelete = (rowKeys: string[], records: Array<any>) => {
    if (!records) {
      records = (dataSource as IDataTableProps['dataSource']).filter(({ key }) => rowKeys.includes(String(key)));
    }
    handleChange({
      file: dictionary,
      rows: records.map(({ fileIndex }) => fileIndex),
      operation: DictionariesEndpoint.DeleteRows
    });
  };

  const handleCreate = (newRow: any) => {
    //TODO вернуть функционал сделав его универсальным
    // const error = isExistRecord?.(record?.VehicleCode);
    // if (error) {
    //   if (typeof error === 'string') ILSUserNotify(Notify.Error, error);
    //   return;
    // }
    //избавляемся от мутаций
    const addRow = newRow && { ...newRow };
    //итерируемся по элементам строки, которую хотим добавить, генерируем уникальные ключи, заполняем поля данными из current record,
    const values = Object.entries(addRow).map(([cell, value]) => {
      switch (cell) {
        case RoadTrainTableColumn.RoadTrainCode:
        case VehicleTableColumn.VehicleCode:
          value = generateUUID();
          break;
      }
      return { cell, value: String(value) };
    });
    handleChange({ file: dictionary, values, operation: DictionariesEndpoint.Add });
  };

  const tableConfig = hideEmptyColumns(dataSource, config, hideEmpty);
  return (
    <ILSTable
      scroll={{ x: 400 }}
      rowKey={(record) => record.key}
      columns={tableConfig?.columns}
      dataSource={dataSource}
      isFetching={isFetching}
      className="editable-table"
      data-testid={dictionary}
      config={tableConfig}
      dictionary={dictionary}
      tableName={dictionary}
      handleCreate={handleCreate}
      handleSave={handleUpdate}
      handleDelete={handleDelete}
    />
  );
};
