import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Props } from 'react-svg/dist/types';
import { getSRCIconSVG } from '@common/decorators/path';
import { IconName, IconPathConfig } from '@common/types/general/general';
import classnames from 'classnames';

export interface IILSSVGProps extends Omit<Props, 'src'> {
  icon: IconName;
  config?: IconPathConfig;
}

export const ILSSVG: FC<IILSSVGProps> = ({ icon, config, className, ...props }) => {
  const classNames = classnames('ils-svg', className as string);
  return <ReactSVG {...props} className={classNames} src={getSRCIconSVG(icon, config)} />;
};
