import { Depot, Dictionary, ShowDeleted } from '@common/types';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { catalogDeleteLocalRoutine, catalogUpdateRoutine } from '@modules/catalog/actions';
import { ForceDeleteType, SaveDictionaryType, ValuesType } from '@modules/catalog/types/catalog';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { CatalogUpdate } from '@modules/catalog/types/actions';

function* catalogDeleteWorker(
  action: PayloadAction<{
    dictionary: Dictionary;
    ids: Array<string | number>;
    values: ValuesType;
    forceDelete: ForceDeleteType;
    saveDictionary: SaveDictionaryType;
    showDeleted: ShowDeleted;
  }>
) {
  const { dictionary, ids, values, forceDelete, saveDictionary, showDeleted } = action.payload;
  const { request, success, failure, fulfill } = catalogDeleteLocalRoutine;

  try {
    yield put(request({ dictionary }));
    yield put(
      success({
        dictionary,
        ids,
        values,
        saveDictionary,
        forceDelete,
        showDeleted
      })
    );
    const depots: Array<Depot> = yield select((state) => state.Catalog.Depot.data);
    const filterIDs = ids.map(Number);
    switch (dictionary) {
      case Dictionary.Restriction:
        if (depots.length) {
          for (let i = 0; i < depots.length; i++) {
            const restriction = checkAndCreateArrayFromObject(depots[i].Restriction)?.map((res) => res.ID);
            const shouldVU = restriction?.some((id) => filterIDs.includes(id));
            if (shouldVU) {
              const value = restriction?.filter((id) => !filterIDs.includes(id));
              const payload: CatalogUpdate = {
                dictionary: Dictionary.Depot,
                ids: [depots[i].ID],
                dataIndex: dictionary + 'ID',
                values: value
              };
              yield put(catalogUpdateRoutine.trigger(payload));
            }
          }
        }
        break;
    }
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogDeleteLocalWatcher() {
  yield takeLatest(catalogDeleteLocalRoutine.trigger, catalogDeleteWorker);
}
