import { useAppDispatch } from '@core/hooks';
import { DocumentAction } from '@modules/documents/types/document-type';
import { currentDealTemplateRoutine, deleteDocumentSetRoutine, deleteAttachTemplateRoutine } from '@modules/documents/children/templates/actions';
import { DocumentSet, DocumentSetChild } from '../../types/api';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export const useParentMenuItems = () => {
  const dispatch = useAppDispatch();

  return (document: DocumentSet): ItemType[] => ([
    {
      label: 'Добавить шаблон документа',
      key: DocumentAction.UpdateDealTemplate,
      onClick: () => dispatch(currentDealTemplateRoutine({ template: document, isOpenModal: true })),
    },
    {
      label: 'Удалить',
      key: DocumentAction.DeleteSet,
      onClick: () => dispatch(deleteDocumentSetRoutine({ id: document.ID })),
    }
  ]);
}

export const useChildrenMenuItems = () => {
  const dispatch = useAppDispatch();

  return (document: DocumentSetChild & { parentDocId?: number }): ItemType[] => ([
    {
      label: 'Удалить шаблон из сделки',
      key: DocumentAction.DeleteAttachTemplate,
      onClick: () => dispatch(deleteAttachTemplateRoutine({ id: document.parentDocId, templateID: [document.ID] }))
    }
  ]);
}