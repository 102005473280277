import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { deleteDocumentSetRoutine, getDocumentSetsRoutine } from '../actions';
import DocumentTemplatesAPI from '../api';

function* deleteDocumentSetWorker(action: PayloadAction<{ id: number }>) {
  try {
    yield call(DocumentTemplatesAPI.deleteDocumentSet, action.payload.id);
    yield put(getDocumentSetsRoutine());
    ILSUserNotify(Notify.Success, 'Шаблон сделки успешно удален');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any);
  }
}

export function* deleteDocumentSetWatcher() {
  yield takeEvery(deleteDocumentSetRoutine.trigger, deleteDocumentSetWorker);
}
