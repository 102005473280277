import { apiInstance } from '@core/api/instance';
import { IAccountAPI } from '../types/api';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const AccountAPI: IAccountAPI = {
  accountOperation: ({ operation, values, id }) => {
    const url = getAPIURL(Dictionary.Account, operation);
    switch (operation) {
      case DictionariesEndpoint.Update:
        return apiInstance().post(url, { data: { id, values } });
      default:
        return apiInstance().post(url);
    }
  }
};

