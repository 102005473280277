import { CellType, RoadTrainTableColumn, TableConfig } from '@common/types';

export const NEW_ROAD_TRAIN = {
  RoadTrainCode: 'Введите код связки',
  [RoadTrainTableColumn.VehicleVehicleCode]: '',
  VehicleID: null
};

export const ROAD_TRAIN_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_ROAD_TRAIN,
  reloading: true,
  columns: [
    {
      title: 'Код связки',
      dataIndex: RoadTrainTableColumn.RoadTrainCode,
      key: RoadTrainTableColumn.RoadTrainCode,
      active: true,
      editable: true,

      ellipsis: true,
      type: CellType.Input,
      width: 50
    },
    {
      title: 'Код автомобиля',
      dataIndex: RoadTrainTableColumn.VehicleVehicleCode,
      key: RoadTrainTableColumn.VehicleVehicleCode,
      active: true,
      editable: false,
      width: 80,
      type: CellType.Uint
    },
    {
      title: 'Код прицепа 1',
      dataIndex: RoadTrainTableColumn.Trailer1VehicleCode,
      key: RoadTrainTableColumn.Trailer1VehicleCode,
      active: true,

      editable: true,
      width: 190,
      type: CellType.SelectAPI
    },
    {
      title: 'Код прицепа 2',
      dataIndex: RoadTrainTableColumn.Trailer2VehicleCode,
      key: RoadTrainTableColumn.Trailer2VehicleCode,
      active: true,

      editable: true,
      width: 190,
      type: CellType.SelectAPI
    },
    {
      title: 'Использовать только в проекте',
      dataIndex: RoadTrainTableColumn.ForProject,
      key: RoadTrainTableColumn.ForProject,
      active: true,
      editable: true,
      required: false,
      type: CellType.Int,
      width: 80
    }
  ]
};
