import { DictionariesEndpoint, Dictionary, Project, TableColumnName, TableRecord } from '@common/types';
import { DictionaryOperation, DictionaryUpdateRequest } from '@common/types/general/api/dictionary';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';
import { isNil } from 'lodash';
import { timeToDouble } from '@common/utils';
import { getParentCatalogIndex } from '@common/utils/helpers/object/get-enum-field';

export const handleUpdateRow =
  ({
    handleDecorator,
    dictionary,
    projectID,
    dispatch
  }: {
    dispatch: Dispatch;
    handleDecorator?(row: any, dataIndex?: string | number, value?: any): any;
    dictionary: Dictionary;
    projectID?: Project['ID'] | undefined;
  }) =>
  (record: TableRecord, dataIndex: TableColumnName | string, value: unknown) => {
    value = handleDecorator?.(record, dataIndex, value) ?? value;
    const values = { [dataIndex]: value };
    if (!isNil(record?.[dataIndex]) && record[dataIndex] === value) return;
    const { ID } = record;

    const payload: DictionaryOperation<DictionaryUpdateRequest> & {
      dictionaryFetching?: boolean;
      projectID: false;
    } = { ids: [ID], dictionary, operation: DictionariesEndpoint.Update, values, projectID: false };

    switch (dictionary) {
      case Dictionary.Order:
        if (!projectID) return;
        if (TableColumnName.TargetID in values || TableColumnName.SourceID in values) {
          payload.values = { ...values, ProjectID: projectID };
        }
        break;
      case Dictionary.Depot:
        if (dataIndex.includes(Dictionary.Worktime)) {
          payload.dictionary = Dictionary.Worktime;
          payload.ids = [record.WorktimeID];
          payload.values = { [getParentCatalogIndex(dataIndex)]: timeToDouble(value as string | null) };
        }
        break;
      default:
        break;
    }

    dispatch(dictionaryPlannerRoutine(payload));
  };
