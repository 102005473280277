import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringCreateTrackerRoutine } from '../actions';

// Создаёт новый трекер
export const monitoringCreateTrackerReducer = createReducer(initialState, {
  [monitoringCreateTrackerRoutine.REQUEST]: (state) => {
    state.trackers.isFetching = true;

    return state;
  },
  [monitoringCreateTrackerRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const { data } = action.payload;

    state.trackers.data = { ...state.trackers.data };
    state.trackers.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringCreateTrackerRoutine.FAILURE]: (state) => {
    state.trackers.lastUpdate = undefined;

    return state;
  },
  [monitoringCreateTrackerRoutine.FULFILL]: (state) => {
    state.trackers.isFetching = false;
    return state;
  }
});
