import { call, put, takeEvery } from 'redux-saga/effects';
import { setClearTrashModeRoutine } from '../actions';
import { Documents } from '../api';
import { UserOptions } from '../types/document-type';

function* setClearTrashModeWorker({ payload }: { payload: UserOptions }) {
  const { request, success, failure, fulfill } = setClearTrashModeRoutine;
  const { clearTrashMode } = payload;
  try {
    yield put(request());
    const { data } = yield call(Documents.setUserOptions, { clearTrashMode });

    yield put(success({ clearTrashMode: data.data.clearTrashMode }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* setClearTrashModeWatcher() {
  yield takeEvery(setClearTrashModeRoutine.trigger, setClearTrashModeWorker);
}
