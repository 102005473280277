import { StimulsoftTemplateData } from '@common/types/general/stimulsoft';

declare var Stimulsoft: any;

export const generateStimulsoftReportFromJson = (templateData: StimulsoftTemplateData) => {
  let reportInst = new Stimulsoft.Report.StiReport();
  reportInst.load(templateData.template);
  reportInst.dictionary.databases.clear();

  let dataSet = new Stimulsoft.System.Data.DataSet('dataNew');
  dataSet.readJson(JSON.stringify(templateData.data));
  reportInst.regData(dataSet.dataSetName, '', dataSet);

  return reportInst;
};

