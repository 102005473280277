import { createRoutine } from 'redux-saga-routines';
import { DataSchemeAction } from '@modules/planning/children/scheme-editor/types';

export const uploadSchemeRoutine = createRoutine(DataSchemeAction.UploadScheme);
export const getCurrentSchemeRoutine = createRoutine(DataSchemeAction.CurScheme);
export const getMaxSchemeRoutine = createRoutine(DataSchemeAction.MaxScheme);
export const getInitialRoutine = createRoutine(DataSchemeAction.GetInitialData);
export const uploadSchemeIniRoutine = createRoutine(DataSchemeAction.UploadSchemeIni);

/** Actions without API */
export const moveSchemeKeysRoutine = createRoutine(DataSchemeAction.MoveSchemeKeys);
export const addSchemeKeysRoutine = createRoutine(DataSchemeAction.AddNewKey);

/** Рутины для которых нужно отображать уведомления */
export const schemeRoutinesWithNotifier = [uploadSchemeRoutine, getCurrentSchemeRoutine, getMaxSchemeRoutine, uploadSchemeIniRoutine];
