import { useEffect, useState } from 'react';

export const useRefReady = () => {
  // define state variable defining the ref as not yet ready
  const [refReady, setRefReady] = useState(false);

  // On first mount, set the variable to true, as the ref is now available
  useEffect(() => {
    setRefReady(true);
  }, []);

  return refReady;
};
