import { tableCustom } from '@core/containers/table-custom';
import { WORKTIME_TABLE_CONFIG } from '@common/models/worktime/config/table';
import { worktime2table } from '@common/models/worktime/decorators';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { CatalogDictionary, Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogWorktime: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return <ILSCatalogTable {...props} dictionary={Dictionary.Worktime} dataDecorator={worktime2table} handleDecorator={table2data} />;
  },
  WORKTIME_TABLE_CONFIG,
  CatalogDictionary.Worktime,
  true
);
