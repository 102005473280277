import { put, takeEvery } from 'redux-saga/effects';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { TenderChangeAction } from '@modules/tender-platform/types';

function* tenderChangeWorker(action: TenderChangeAction) {
  const { request, success, failure, fulfill } = tenderChangeRoutine;

  try {
    yield put(request());
    yield put(success(action.payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderChangeWatcher() {
  yield takeEvery(tenderChangeRoutine.trigger, tenderChangeWorker);
}
