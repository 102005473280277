import { FC } from 'react';
import { renderRoleBlock } from '@modules/tender-platform/utils/helpers/render-role-block';
import { AccountRoles, UserRoles } from '@common/types/dictionaries';

export const ILSTenderPlatformAccountInfo: FC<{
  userRole: UserRoles;
  accountRole: AccountRoles;
  path: string;
}> = ({ userRole, accountRole, path }) => {
  return (
    <>
      {renderRoleBlock(accountRole, 'компании на площадке:', path)}
      {renderRoleBlock(userRole, 'пользователя:')}
    </>
  );
};
