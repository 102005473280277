import { useAppDispatch, useAppSelector } from '@core/hooks';
import { FC, useEffect, useRef, useState } from 'react';
import { emulatorSaveVehiclePropsRoutine } from '../actions';
import { MainTableComponent } from '../components/main-table';
import { EmulatorStatus } from '../constants';
import { emulationConfigsSelector, planSelector, projectsSelector, statusSelector } from '../selectors';
import { tableVehicleToData } from '../table/config/table-decorator';
import { RecordMainTable, VehicleTableColumnData } from '../types';
import { IndexedArray, Project } from '@common/types';

interface Props {}

// Контейнер для основной таблицы в Эмуляторе

export const MainTableContainer: FC<Props> = () => {
  const { vehicles, isFetching, loadedPlanID } = useAppSelector(planSelector);
  const projects = useAppSelector<IndexedArray<Project>>(projectsSelector);
  const dispatch = useAppDispatch();
  const emulationConfigs = useAppSelector(emulationConfigsSelector);
  const status = useAppSelector(statusSelector);
  const actualStatus = status || emulationConfigs?.Status;

  let isNotEditable = !!actualStatus && actualStatus !== EmulatorStatus.Error;
  
  /**
   * Данные выбранного проекта
   * NOTE можно использовать селектор
   */
  const [activeProject, setActiveProject] = useState<Project | undefined>(undefined);

  /**
   * Используется как ref чтобы в handleSave были наиболее точные данные
   * NOTE на самом деле можно перенести в сагу 
   */
  const refPlanID = useRef({ loadedPlanID, emulationConfigs });

  /**
   * Обновляет данные таблицы, если выбранный план был загружен
   * NOTE можно заменить на селектор
   */
  useEffect(() => {
    refPlanID.current = { loadedPlanID, emulationConfigs };
    let find = Object.values(projects).find((p) => Number(Object.keys(p.PlanEnum ?? {})[0]) === loadedPlanID);
    setActiveProject(find);
  }, [loadedPlanID, emulationConfigs]);

  /**
   * callback для сохранения данных настроект эмуляции автомобиля
   */
  const handleSave = (records: RecordMainTable | { ID: Array<string>; key: Array<string> }, key: VehicleTableColumnData, value: any) => {
    let vehicleIDs: number[];
    if (records.key && Array.isArray(records.key)) {
      vehicleIDs = records.key.map((item) => Number(item));
    } else {
      vehicleIDs = [Number(records.key)];
    }
    if (vehicleIDs.length && refPlanID.current?.loadedPlanID && key) {
      dispatch(
        emulatorSaveVehiclePropsRoutine({
          planID: refPlanID.current.loadedPlanID,
          vehicleIDs,
          values: { [key]: tableVehicleToData(key, value) },
          createConfig: !refPlanID.current?.emulationConfigs?.PlanID
        })
      );
    }
  };

  return (
    <MainTableComponent
      vehicles={vehicles}
      activeProject={activeProject}
      onCanEdit={isNotEditable ? () => false : undefined}
      vehicleProps={emulationConfigs.EmulatorConfigVehicle}
      isFetching={isFetching}
      loadedPlanID={loadedPlanID}
      handleSave={handleSave}
    />
  );
};
