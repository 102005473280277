import { ColumnTable, DragInfo, TableConfig, TableRecord } from '@common/types';
import { deepEqual } from 'fast-equals';

const checkDeepRowEquality = <R>(rec: R, prev: R) => {
  return !deepEqual(rec, prev);
};

const checkWatch =
  (updateWatch: string) =>
  <R>(rec: R, prev: R) => {
    return rec[updateWatch] !== prev[updateWatch];
  };

const checkArrayWatch =
  (updateWatch: Array<string>) =>
  <R>(rec: R, prev: R) => {
    let isEqual = true;
    updateWatch.forEach((watch) => {
      if (rec[watch] !== prev[watch]) {
        isEqual = false;
        return;
      }
    });
    return !isEqual;
  };
/**Возвращает функцию shouldCellUpdate, которая используется для определения надо ли делать ререндер ячейки или нет.
 *
 *  Приоритет:
 *
 *     1 - если для конкретной колонки задана shouldCellUpdate
 *
 *     2 - если указаны конкретные столбцы/столбец (config.updateWatch), по которым сравнивать - смотрим на них
 *
 *     3 - если для конифга таблицы указано свойство checkRecordOnUpdate - проверяем всю запись целиком
 *
 *     4 - дефолтное поведение компонента ячейки
 *
 *  @column: ColumnTable
 *
 *  @config: TableConfig
 *
 *  @dragInfo: DragInfo
 **/
export const shouldCellUpdateFactory = <R extends TableRecord>(
  column: ColumnTable<R>,
  config: TableConfig,
  dragInfo?: DragInfo | null
): ((rec: R, prev: R) => boolean) | undefined => {
  //если dragInfo?.active или column.fixed не делаем дальнейшую проверку - используем штатный метод
  if (dragInfo?.active || column?.fixed) return undefined;

  const updateWatch = config?.updateWatch;

  //Если указано правло для ячейки - берем его
  if (column.shouldCellUpdate) return column.shouldCellUpdate;
  //Если указаны конкретные столбцы, по которым стравнивать - смотрим на них
  if (updateWatch) {
    if (!Array.isArray(updateWatch)) {
      return checkWatch(updateWatch);
    } else {
      return checkArrayWatch(updateWatch);
    }
  }
  //если указано свойство проверки checkRecordOnUpdate и не выполнены предудыщие условия - сравниваем всю запись целиком
  if (config?.checkRecordOnUpdate) return checkDeepRowEquality;

  //если ничего не указано - возвращаем undefined
  return undefined;
};
