import { ILSButton, ILSCheckbox, ILSTypographyText } from '@components/index';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { ReactSVG } from 'react-svg';
import { Dispatch, FC, SetStateAction } from 'react';
import { TableCustomComponentProps } from './index';
import { ColumnTable, TableConfig, TableName, TableRecord } from '@common/types/components';

interface TableCustomConfigComponentProps extends Omit<TableCustomComponentProps<any>, 'ChildTable'> {
  item: ColumnTable<TableRecord>;
  index: number;
  key: string | number;
  dragIn?: string;

  setDragIn?(_: string, childName?: string): void;

  columns: ColumnTable<TableRecord>[];
  childName?: string;
  showConfig: boolean;

  onCheckboxChange(item: ColumnTable<TableRecord>, child: string, changeAllTo?: boolean): void;

  onConfigChange(): void;

  onOrderChange(cols: ColumnTable<TableRecord>[], child: string): void;

  onSetDefault(): void;

  config: TableConfig;
  activeColumns: ColumnTable<TableRecord>[];
  tableName: TableName | string;
  setShowConfig: Dispatch<SetStateAction<boolean>>;
}

const TableCustomConfigComponent: FC<TableCustomConfigComponentProps> = ({
  onCheckboxChange,
  onOrderChange,
  config,
  item,
  index,
  dragIn,
  setDragIn,
  childName,
  columns
}) => {
  const handleEnter = (item: ColumnTable<TableRecord>) => {
    setDragIn?.(String(item.dataIndex), childName);
  };

  const handleDrop = (e: any, item: ColumnTable<TableRecord>) => {
    e.stopPropagation();
    let { list, element }: { list: ColumnTable<TableRecord>[]; element: ColumnTable<TableRecord> } = e.dragData;

    const newList = list.reduce((columns, column) => {
      if (column.dataIndex !== element.dataIndex) {
        if (item.dataIndex === column.dataIndex) {
          columns.push(element);
        }
        columns.push(column);
      }
      return columns;
    }, [] as ColumnTable<TableRecord>[]);

    onOrderChange(newList, childName!);
    setDragIn?.('');
  };

  const columnTitle = Boolean(item.title)
    ? item.title
    : item.titleText ?? (typeof item.titleTooltip === 'string' ? item.titleTooltip : null);
  const onChange = () => onCheckboxChange(item, childName!);
  const CheckBox = (
    <ILSCheckbox checked={Boolean(item.active)} onChange={onChange}>
      <ILSTypographyText ellipsis style={{ maxWidth: 300 }}>
        {columnTitle}
      </ILSTypographyText>
    </ILSCheckbox>
  );

  return item.fixed ? (
    <div key={index} className="py-1 w-100">
      {CheckBox}
    </div>
  ) : (
    <DragDropContainer
      key={index}
      targetKey="boxItem"
      dragData={{ list: columns ?? config?.columns, element: item }}
      dragHandleClassName="grabber"
      disappearDraggedElement
      onDragEnd={() => {
        setDragIn?.('');
      }}
    >
      <DropTarget onHit={(e: any) => handleDrop(e, item)} onDragEnter={() => handleEnter(item)} targetKey="boxItem">
        {dragIn === item.dataIndex && <div className="p-3" />}
        <div className="py-1 pr-2 w-100 flex-between-center">
          {CheckBox}
          <ILSButton className="grabber" icon={<ReactSVG className="svg-container" src={'/assets/icons/move.svg'} />} />
        </div>
      </DropTarget>
    </DragDropContainer>
  );
};

export default TableCustomConfigComponent;
