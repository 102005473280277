import { CellRecord } from '@common/types/components/table/common';
import { isArray } from 'lodash';

export const getEnumForMultipleCell = ({ cellRecord }: { cellRecord: CellRecord | CellRecord[] | undefined; value: any }) => {
  if (!cellRecord) return {};
  const record = isArray(cellRecord) ? cellRecord : [cellRecord];

  return record?.reduce((result, singleRecord) => {
    if (singleRecord) {
      result[singleRecord.ID] = singleRecord.Name;
    }
    return result;
  }, {} as any);
};
