import { ColumnTable, TableColumnName } from '@common/types';

export type SetActiveColumnPropType = {
  column: ColumnTable<any>;
  excludeColumns: TableColumnName[];
  includeColumns: TableColumnName[];
};

export const setActiveTableColumns = ({ column, excludeColumns, includeColumns }: SetActiveColumnPropType) => {
  if (excludeColumns.includes(column.dataIndex as TableColumnName)) {
    return false;
  }
  if (includeColumns.includes(column.dataIndex as TableColumnName)) {
    return true;
  }
  return column.active;
};
