import { CellType, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { NEW_TRANSPORT_COMPANY } from '@common/models/transport-company/constants';
import { ID_COLUMN } from '@common/models/all/columns';

export const TRANSPORT_COMPANY_TABLE_CONFIG: TableConfig = {
  adding: NEW_TRANSPORT_COMPANY,
  deleting: true,
  selecting: true,
  copying: true,
  reloading: true,
  multiEditing: false, // Мультиредактирование отключено согласовано в TMS-6569
  hideClearButtons: false,
  export: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Наименование',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      type: CellType.Input,
      required: true,
      editable: true,
      sorting: 3,
      filtering: FilterCellType.String,

      active: true,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Код компании',
      dataIndex: TableColumnName.TransportCompanyCode,
      key: TableColumnName.TransportCompanyCode,
      type: CellType.Int,
      required: false,
      editable: true,
      sorting: 4,
      filtering: FilterCellType.String,
      active: true,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'ИНН',
      dataIndex: TableColumnName.INN,
      key: TableColumnName.INN,
      type: CellType.Int,
      required: true,
      editable: true,
      sorting: 5,
      filtering: FilterCellType.String,
      active: true,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Адрес',
      dataIndex: TableColumnName.Address,
      key: TableColumnName.Address,
      type: CellType.AddressAutocomplete,
      required: false,
      editable: true,
      sorting: 6,
      filtering: FilterCellType.String,
      active: true,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Email',
      dataIndex: TableColumnName.Email,
      key: TableColumnName.Email,
      type: CellType.Email,
      editable: true,
      sorting: 7,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Телефон',
      dataIndex: TableColumnName.Phone,
      key: TableColumnName.Phone,
      type: CellType.Phone,
      editable: true,
      sorting: 8,
      filtering: FilterCellType.String,
      active: true,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Комментарий',
      dataIndex: TableColumnName.Comment,
      key: TableColumnName.Comment,
      type: CellType.Input,
      editable: true,
      sorting: 9,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'КПП',
      dataIndex: TableColumnName.KPP,
      key: TableColumnName.KPP,
      type: CellType.Int,
      required: true,
      editable: true,
      sorting: 10,
      filtering: FilterCellType.String,
      active: true,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'ОКПО',
      dataIndex: TableColumnName.OKPO,
      key: TableColumnName.OKPO,
      type: CellType.Int,
      required: true,
      editable: true,
      active: true,
      sorting: 11,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'ОГРН',
      dataIndex: TableColumnName.OGRN,
      key: TableColumnName.OGRN,
      type: CellType.Int,
      required: true,
      editable: true,
      active: true,
      sorting: 12,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'ОКАТО',
      dataIndex: TableColumnName.OKATO,
      key: TableColumnName.OKATO,
      type: CellType.Int,
      required: true,
      editable: true,
      active: true,
      sorting: 13,
      filtering: FilterCellType.String,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Расчетный счет',
      dataIndex: TableColumnName.CheckingAccount,
      key: TableColumnName.CheckingAccount,
      type: CellType.Int,
      editable: true,
      sorting: 14,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Корреспондентский счет',
      dataIndex: TableColumnName.CorrespondentAccount,
      key: TableColumnName.CorrespondentAccount,
      type: CellType.Int,
      editable: true,
      sorting: 5,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'Банк',
      dataIndex: TableColumnName.Bank,
      key: TableColumnName.Bank,
      type: CellType.Input,
      editable: true,
      sorting: 16,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    },
    {
      title: 'БИК',
      dataIndex: TableColumnName.BIK,
      key: TableColumnName.BIK,
      type: CellType.Int,
      editable: true,
      sorting: 17,
      filtering: FilterCellType.String,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    }
  ]
};
