import { Tender, TenderStatus } from '@modules/tender-platform/types';
import { IndexedArray } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';

export const filterTendersByStatus = (statuses: Array<TenderStatus>, tenders: Array<Tender> | IndexedArray<Tender> | null) =>
  createArrayFromIndexedArray(tenders).filter((tender) => statuses.includes(tender.StatusConfig?.Alias as TenderStatus));

export const filterTendersByStatusAndAccepted = (
  statuses: Array<TenderStatus>,
  tenders: Array<Tender> | IndexedArray<Tender> | null,
  accepted: boolean = true
) =>
  filterTendersByStatus(statuses, tenders).filter(({ TenderOffer }) =>
    Array.isArray(TenderOffer)
      ? TenderOffer.some(({ Accepted }) => (accepted ? Accepted : !Accepted))
      : accepted
      ? (TenderOffer as unknown as TenderOffer)?.Accepted
      : !(TenderOffer as unknown as TenderOffer)?.Accepted
  );
