import { FC } from 'react';
import { ILSButton } from '@components/index';
import { MatrixAction } from '@modules/planning/children/by-date/types';

interface IProps {
  hasMatrix: boolean;
  handleClick: (action: MatrixAction) => void;
}

const ILSPlanningMatrixButtons: FC<IProps> = ({ handleClick, hasMatrix }) => {
  return (
    <ul className={'planning-matrix-buttons'}>
      <ILSButton onClick={() => handleClick(MatrixAction.Start)}>Расчет матрицы расстояний</ILSButton>
      <ILSButton onClick={() => handleClick(MatrixAction.Delete)} disabled={!hasMatrix}>
        Удаление матрицы расстояний
      </ILSButton>
      <ILSButton onClick={() => handleClick(MatrixAction.Cleanup)}>Пересчёт некорректных значений матрицы</ILSButton>
    </ul>
  );
};

export default ILSPlanningMatrixButtons;
