import { ILSFormItemProps } from '@components/data-entry/form';
import { Project } from '@common/types';

export const ADD_REPORT_FORM = 'document-report';

export enum AddReportFormItem {
  Name = 'name',
  Alias = 'alias'
}

export const ADD_REPORT_FORM_RULES: Record<AddReportFormItem, ILSFormItemProps['rules']> = {
  [AddReportFormItem.Name]: [{ required: true }],
  [AddReportFormItem.Alias]: [{ required: true }]
};

export const ADD_REPORT_FORM_SELECT_FIELD_NAMES = {
  label: AddReportFormItem.Name,
  value: AddReportFormItem.Alias
};

export const ROOT_PROJECT_ID_MONITORING: Project['ID'] = 1;
