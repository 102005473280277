import { Input, InputProps } from 'antd';
import { GroupProps, InputRef, PasswordProps, SearchProps, TextAreaProps } from 'antd/lib/input';
import { FC, forwardRef } from 'react';
import { MAX_COMMENT_CHARS, Placeholder } from '@common/constants/general';

const { TextArea, Search, Group, Password } = Input;

export enum InputType {
  Color = 'color',
  Email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  Number = 'number',
  range = 'range',
  reset = 'reset',
  submit = 'submit',
  text = 'text',
  url = 'url'
}

export enum InputName {
  Name = 'Name'
}

export const ILSInput = forwardRef<InputRef, InputProps>(({ type, placeholder, ...props }, ref) => {
  switch (type) {
    case InputType.Email:
      placeholder = Placeholder.Email;
      break;
  }
  return (
    <Input
      inputMode={InputType.Email ? 'email' : undefined}
      min={type === InputType.Number ? 0 : undefined}
      ref={ref}
      type={type}
      placeholder={placeholder}
      {...props}
    />
  );
});

export const ILSTextArea: FC<TextAreaProps> = ({ maxLength = MAX_COMMENT_CHARS, ...props }) => {
  return <TextArea maxLength={maxLength} {...props} />;
};

export const ILSInputSearch: FC<SearchProps> = (props) => {
  return <Search {...props} />;
};

export const ILSInputGroup: FC<GroupProps> = (props) => {
  return <Group {...props} />;
};

export const ILSInputPassword: FC<PasswordProps> = (props) => {
  return <Password {...props} />;
};

