import { DepotUploadFileType } from '@common/types';
import { DEPOT_TYPE_OPTIONS, DepotType } from '@common/models/depot/constants';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { createArrayFromIndexedArray } from '@common/utils';

export const depot2Table = (depots: DepotUploadFileType[], type: string) => {
  return createArrayFromIndexedArray(depots).map((depot: DepotUploadFileType, i: number) => {
    return {
      ...depot,
      AllowKeepTrailer: depot.AllowKeepTrailer && Number(depot.AllowKeepTrailer),
      key: depot.DepotCode ? depot.DepotCode : type + i,
      fileIndex: i,
      DepotType: DEPOT_TYPE_OPTIONS.find(({ ID }) => compareAsString(ID, depot.DepotType))?.Name ?? DepotType.Client
    };
  });
};
