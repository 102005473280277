import { put, select, takeLatest, call } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { planningGetParamsRoutine } from '@modules/planning/actions';
import { planningParamsSelector } from '@modules/planning/selectors';
import { PlannerLocalConf } from '@modules/planning/types/store';
import { defaultPlannerParams } from '@modules/planning/constants/planner';
import { PlanningAPI } from '@modules/planning/api';
import { RequestParams } from '@common/constants/settings/api';
import { PlannerParamsPayload, PlannerParamsResponse } from '@modules/planning/types/params';

export function* planningGetParamsWorker() {
  const { success, failure, fulfill, request } = planningGetParamsRoutine;

  try {
    yield put(request());

    const params: PlannerLocalConf = yield select(planningParamsSelector);
    let plannerSettings: PlannerParamsResponse;

    const payload: PlannerParamsPayload = {
      binding: RequestParams.BindingAccount,
      paramName: RequestParams.PlannerParams,
      value: params ?? null
    };

    const {
      data: { data }
    } = yield call(PlanningAPI.getPlannerParams, payload);

    plannerSettings = data;

    //Note: Если настройки аккаунта (пользователя) отсутствуют добавляем дефолтные
    if (isEmpty(plannerSettings.value)) {
      const {
        data: { data }
      } = yield call(PlanningAPI.createPlannerParams, {
        ...payload,
        value: defaultPlannerParams
      });
      plannerSettings = data;
    }
    yield put(success({ value: plannerSettings.value }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningGetParamsWatcher() {
  yield takeLatest(planningGetParamsRoutine.trigger, planningGetParamsWorker);
}

