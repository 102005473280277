import history from '@common/utils/history';
import { QUERY_PARAMS } from '@common/constants/url';
import { IDType } from '@modules/catalog/types/catalog';
import { Dictionary } from '@common/types';
import { compareAsString } from '@common/utils';

/** Устанавливаем фокусировку в catalog read саге,
 * несколько вариантов:
 * 1) если в url query params есть id;
 * 2) если в payload передается rowIDToFocus:
 *  - при добавлении строки rowIDToFocus - это id новой добавленной строки;
 *  - при копировании строки rowIDToFocus - это id новой скопированной строки;
 *  - при удалении строки не вызывается catalog.read. В редьюсере удаления своя логика установки фокуса.
 *  - при включении режима отображения удаленных rowIDToFocus - это последняя сохраненная удаленная строка (lastDeletedRowRef).
 * 3) если этих значений нет, то устанавливаем фокус на первую строку data
 */

export const getFocusRowForCatalogRead = ({
  rowIDToFocus,
  firstDataRowID,
  dictionary
}: {
  rowIDToFocus?: string | null;
  firstDataRowID?: IDType;
  dictionary: Dictionary;
}) => {
  const queryParams = new URLSearchParams(history.location.search);
  const searchId = queryParams.get(QUERY_PARAMS.ID);
  const searchDictionary = queryParams.get(QUERY_PARAMS.DICTIONARY);
  const searchFocusKey = compareAsString(searchDictionary, dictionary) ? Number(searchId) : null;

  return searchFocusKey ?? rowIDToFocus ?? firstDataRowID ?? null;
};
