import { call, put, select, takeLatest } from 'redux-saga/effects';
import { analyticsPlanningActions, deleteBoardSetRoutine } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { AnalyticsStore } from '@modules/analytics/common/types/store';
import { sortListBySerial } from '@modules/analytics/common/utils/sort-boards';

function* analyticsDeleteBoardSetWorker(action: PayloadAction<{ id: string | number }>) {
  const { request, success, failure, fulfill } = deleteBoardSetRoutine;

  const { id } = action.payload;

  try {
    yield put(request(action.payload));

    yield call(AnalyticsPlanningAPI.deleteBoardSet, id);
    const state: AnalyticsStore = yield select((state) => state.AnalyticsPlanningBoards);

    if (state.BoardSets.selectedBoardSet === id) {
      const sortedList = sortListBySerial(state.BoardSets.data, (item) => item.ID !== id);
      if (sortedList.length === 0) {
        yield put(analyticsPlanningActions.analyticsCommonSelectBoardRoutine(undefined));
      }
      yield put(analyticsPlanningActions.analyticsCommonSelectBoardSetRoutine(sortedList[0]));
    }

    yield put(success({ id }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsDeleteBoardSetWatcher() {
  yield takeLatest(deleteBoardSetRoutine.TRIGGER, analyticsDeleteBoardSetWorker);
}
