import initialAnalyticsState from '@modules/analytics/common/store';
import { AnalyticsPlanningStore } from '../types/store';

const initialState: AnalyticsPlanningStore = {
  ...initialAnalyticsState,
  BoardSets: {
    data: {},
    selectedBoardSet: undefined,
    isFetching: false
  },
  widgetCatalog: {
    data: {},
    isFetching: false
  }
};

export default initialState;
