import { ILSUserNotify } from '@common/components/feedback/ils-user-notify';
import { Notify, NotifyDurationInSecond, Project, ReactDispatch, Status } from '@common/types';
import { getStartEndMonthDays, parseDateFromServer } from '@common/utils';
import { useAppDispatch } from '@core/hooks';
import { getProjectsRoutine } from '@modules/planning/actions';
import { isDateLoadingSelector } from '@modules/planning/selectors';
import { Moment } from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { planningGetProjectCurrentRoutine } from '@modules/planning/children/by-date/actions';

const NOTIFY_KEY_NO_PROJECTS = 'NOTIFY_KEY_NO_PROJECTS';

type UseDatepickProjectHandlerArg = {
  projectStart: number | undefined;
  currentPickedDate: Moment;
  setCurrentPickedDate: ReactDispatch<Moment>;
  projects: Project[];
  setProjectListToChoose: ReactDispatch<Array<Project> | null>;
  setStatus: ReactDispatch<Status.Error | Status.Warning | undefined>;
  handleShow: () => void;
};

export const useDatepickProjectHandler = ({
  projectStart,
  projects,
  setCurrentPickedDate,
  setStatus,
  setProjectListToChoose,
  handleShow,
  currentPickedDate
}: UseDatepickProjectHandlerArg) => {
  const dispatch = useAppDispatch();

  const onMonthChange = useCallback((date: Moment) => {
    const [from, till] = getStartEndMonthDays(date);
    dispatch(getProjectsRoutine({ from, till }));
  }, []);

  //получение данных проекта
  const getProject = useCallback((projectID: string | number, setStatus?: boolean | undefined) => {
    dispatch(planningGetProjectCurrentRoutine({ projectID, setStatus }));
  }, []);

  const isDateLoading = useSelector(isDateLoadingSelector);
  const onDatePickHandler = (date: Moment) => {
    const dateProjects = projects.filter(({ Start }) => parseDateFromServer(Start).isSame(date, 'date'));

    if (!dateProjects.length) {
      if (projectStart) {
        setCurrentPickedDate(parseDateFromServer(projectStart));
        onMonthChange(parseDateFromServer(projectStart));
        setStatus(undefined);
      } else {
        setCurrentPickedDate(date);
        setStatus(Status.Error);
      }
      ILSUserNotify(Notify.Warning, 'В выбранной дате отсутствуют проекты', NotifyDurationInSecond.Three, NOTIFY_KEY_NO_PROJECTS);
    } else if (dateProjects.length > 1) {
      setStatus(undefined);
      setProjectListToChoose(dateProjects);
      handleShow();
    } else if (date.isSame(currentPickedDate)) {
      setProjectListToChoose(null);
      setStatus(undefined);
      getProject(dateProjects[0].ID);
    } else {
      setStatus(undefined);
      setCurrentPickedDate(date);
      setProjectListToChoose(null);
      getProject(dateProjects[0].ID);
    }
  };

  /** NOTE ref нужен, чтобы вызов был с актуальными проектами */
  const onDatePickCallback = useRef(onDatePickHandler);
  useEffect(() => {
    onDatePickCallback.current = onDatePickHandler;
  }, [projects, currentPickedDate]);

  /** NOTE ref нужен для того что бы отслеживать статус загрузки в момент вызова */
  const isDateLoadingRef = useRef(isDateLoading);
  useEffect(() => {
    /** NOTE вызываем onDatePickTriggerRef после загрузки проектов на выбранный месяц */
    if (!isDateLoading) {
      onDatePickTriggerRef.current();
    }
    isDateLoadingRef.current = isDateLoading;
  }, [isDateLoading]);
  /** NOTE ref нужен для отложенного вызова onDatePickCallback
   * Без использования ref в модальном окне отображаются проекты за другую дату
   * */
  const onDatePickTriggerRef = useRef<() => void>(() => void 0);

  const onDatePick = (date: Moment) => {
    if (isDateLoadingRef.current) {
      onDatePickTriggerRef.current = () => {
        onDatePickCallback.current(date);
        onDatePickTriggerRef.current = () => void 0;
      };
    } else {
      onDatePickCallback.current(date);
    }
  };
  return { onDatePick, onMonthChange, getProject, isDateLoading };
};
