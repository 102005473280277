import { FC } from 'react';
import { ILSButton } from '@common/components';
import { CloseCircleOutlined } from '@ant-design/icons';

interface IProps {
  title: string;
  showCloseButton?: boolean;
  onCloseIconClick?: () => void;
}

export const QuestionsHeader: FC<IProps> = ({ title, showCloseButton = false, onCloseIconClick }) => {
  return (
    <div className="questions-section-header">
      <h2>{title}</h2>
      {showCloseButton && (
        <ILSButton
          size="small"
          className="questions-section-header-close-button"
          icon={<CloseCircleOutlined size={25} />}
          onClick={onCloseIconClick}
        />
      )}
    </div>
  );
};

