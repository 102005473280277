import { put, takeLatest } from 'redux-saga/effects';
import { getChartData } from '../actions';
import { chartData } from '../store';
import { getChartNewData, getChartTicksScale } from '@common/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetChartData } from '../types/sagas';

function* monitoringControlWorker(action: PayloadAction<GetChartData>) {
  const { request, success } = getChartData;

  try {
    yield put(request({ isFetching: true }));

    const { period, tickCount, intervals } = action.payload;

    const colors = ['violet', 'orange', 'green', 'coral'];
    const ticks = getChartTicksScale(tickCount, period);
    const newData = getChartNewData(chartData, period);

    yield put(success({ chartData: newData, ticks, colors, intervals }));
  } catch (error) {
    console.error(error);
  }
}

export function* monitoringControlWatcher() {
  yield takeLatest(getChartData.trigger, monitoringControlWorker);
}
