import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, debounce } from 'redux-saga/effects';
import { loadPlanTrackRoutine } from '../actions';
import { PlanningAPI } from '@modules/planning/api';
import { ID, Track } from '@common/types';

type loadPlanTrackRequest = {
  points: Record<ID, Track>;
};

// Получает точки плана ({ data: data.data, points })
function* loadPlanTrackWorker(action: PayloadAction<loadPlanTrackRequest>) {
  const { request, success, failure, fulfill } = loadPlanTrackRoutine;
  const { points } = action.payload;

  try {
    yield put(request());

    const { data } = yield call(PlanningAPI.getTrack, { points: Object.values(points) });

    yield put(success({ data: data.data, points }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* loadPlanTrackWatcher() {
  yield debounce(3000, loadPlanTrackRoutine.trigger, loadPlanTrackWorker);
}
