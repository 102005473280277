import { FC } from 'react';
import { EventDetailsTab } from '@modules/monitoring/children/event-details/types';
import { tableCustom } from '@core/containers/table-custom';
import { Dictionary } from '@common/types';
import { tableEventDetailsBDDConfig, tableEventDetailsBDDTable } from '@common/table-configs/monitoring-event-details/bdd-table';
import { MonitoringEventDetailsStore } from '../../types/store';
import { DetailsBddTableComponent } from '../../components/details/details-bdd';

interface Props {
  tab: EventDetailsTab;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
}

/**
 * Контейнер для таблицы детализации БДД
 *
 */
export const DetailsBddTable: FC<Props> = ({ tab, vehicleSensor, tracks, vehicleEvents, ...props }) => {
  const decoratedData = tableEventDetailsBDDTable(vehicleSensor?.data, tracks?.trackGeo);

  return (
    <DetailsBddTableComponent
      {...props}
      tab={tab}
      vehicleSensor={vehicleSensor}
      tracks={tracks}
      vehicleEvents={vehicleEvents}
      decoratedData={decoratedData}
    />
  );
};

export const DetailsBddContainer = tableCustom(DetailsBddTable, tableEventDetailsBDDConfig, Dictionary.EventDetailsBDD);
