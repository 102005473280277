import { ILSFormItem, ILSTabPane, ILSTabs } from '@common/components';
import { FormFieldNames, FormLabels } from '@modules/orders-and-trips/types/form';
import { CargoBlock } from '@modules/orders-and-trips/components/forms/blocks/cargo';
import { DateTimeBlock } from '@modules/orders-and-trips/components/forms/blocks/date-time';
import { FC } from 'react';
import { useCargo } from '@modules/orders-and-trips/hooks/use-cargo';
import { ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { ActionTypeID } from '@modules/orders-and-trips/types/trip';
import { IEnum, Option } from '@common/types';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { Field } from '@common/constants';
import { FormType } from '@modules/orders-and-trips/types/general';
import { Contact } from '@modules/orders-and-trips/components/forms/blocks/contact';

interface IProps {
  restriction: IEnum<string>[];
  disabled: boolean;
  actionCargo: string[];
  pointIndex: number;
  showForm: FormType | null;
}

export const SourceSection: FC<IProps> = ({ restriction, showForm, disabled, actionCargo, pointIndex }) => {
  const { onEdit, cargos } = useCargo({ disabled, actionCargo });
  return (
    <>
      <div className="order-action-form-location flex-horizontal">
        <ILSFormItem
          name={[pointIndex, FormFieldNames.SourceID]}
          label={FormLabels.Source}
          rules={ORDER_AND_TRIP_FORM_RULES[FormFieldNames.SourceID]}
        >
          <ILSSuggestionAutoComplete disabled={disabled} field={Field.Address} />
        </ILSFormItem>
      </div>
      <DateTimeBlock
        showForm={showForm}
        key={ActionTypeID.Load + pointIndex}
        pointIndex={pointIndex}
        pointType={ActionTypeID.Load}
        disabled={disabled}
      />
      <ILSTabs type="editable-card" onEdit={onEdit}>
        {cargos.map((cargo, index) => {
          return (
            <ILSTabPane tab={cargo.label} key={cargo.key} closable={cargo.closable}>
              <CargoBlock pointIndex={pointIndex} restriction={restriction} index={index} disabled={disabled} />
            </ILSTabPane>
          );
        })}
      </ILSTabs>
      <Contact showForm={showForm} pointIndex={pointIndex} />
    </>
  );
};

