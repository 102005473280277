import { put, call, takeEvery } from 'redux-saga/effects';
import { deleteBoardWidget } from '@modules/analytics/planning/children/planning-analytics/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningAPI } from '../api';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

function* worker(action: PayloadAction<{ widget: AnalyticsWidget }>) {
  const { request, success, failure, fulfill } = deleteBoardWidget;

  const { widget } = action.payload;
  try {
    yield put(request(widget));

    if (!widget.isNew) {
      yield call(AnalyticsPlanningAPI.deleteWidget, widget.ID);
    }

    yield put(success({ ...widget }));
  } catch (error) {
    console.error(error);
    yield put(failure(error));
  } finally {
    yield put(fulfill());
  }
}

export function* analyticsDeleteBoardWidgetWatcher() {
  yield takeEvery(deleteBoardWidget.TRIGGER, worker);
}
