interface IDocumentStateParams {
  archive: number;
  deleted: number;
}

export const allDocumentsParams: IDocumentStateParams = {
  archive: 0,
  deleted: 0,
};

export const archiveDocumentsParams: IDocumentStateParams = {
  archive: 1,
  deleted: 0,
};

export const deletedDocumentsParams: IDocumentStateParams = {
  archive: 0,
  deleted: 1,
};
