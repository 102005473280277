import { IndexedArray } from '@common/types';

export const iterateParams = (
  iterator: IterableIterator<[string, string]>,
  exceptions: Array<string>
): IndexedArray<{ name: string; value: string | number | undefined }> => {
  const paramsArray = Array.from(iterator);

  const res: IndexedArray<{ name: string; value: string | number | undefined }> = paramsArray.reduce((acc, curr) => {
    const [name, value] = curr;
    if (!Object.values(exceptions).includes(name.toLowerCase())) {
      acc[name] = { name: name, value: value };
    }
    return acc;
  }, {});

  return res;
};
