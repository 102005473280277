import { UnresolvedMarkerSourceValue } from '@modules/settings/types/planning';
import { useCallback } from 'react';
import { MapMarkerSelectType, ResolvedOrderMap } from '@common/types/components/map/map';
import { SelectedOrders } from '@modules/planning/types';

//Проверит все ли заявки находятся среди выделенных
export const useCheckTypeResolved = (unresolvedMarkerSource: UnresolvedMarkerSourceValue, selectedOrders: SelectedOrders) =>
  useCallback(
    (record?: ResolvedOrderMap | null): MapMarkerSelectType => {
      if (unresolvedMarkerSource === UnresolvedMarkerSourceValue.Source) {
        if (record?.sourceOrders?.length && record?.sourceOrders?.every((it) => selectedOrders?.includes(it))) {
          return MapMarkerSelectType.Selected;
        } else {
          return record?.sourceOrders?.find((it) => selectedOrders?.includes(it))
            ? MapMarkerSelectType.PartiallySelected
            : MapMarkerSelectType.None;
        }
      } else {
        if (record?.targetOrders?.length && record?.targetOrders?.every((it) => selectedOrders?.includes(it))) {
          return MapMarkerSelectType.Selected;
        } else {
          return record?.targetOrders?.find((it) => selectedOrders?.includes(it))
            ? MapMarkerSelectType.PartiallySelected
            : MapMarkerSelectType.None;
        }
      }
    },
    [unresolvedMarkerSource, selectedOrders]
  );
