import { FC } from 'react';
import { ILSCard } from '@common/components';
import { ILSTypographyTitle } from '@components/general/typography';
import { useSelector } from 'react-redux';
import { auctionCargoOwnerSelector } from '@modules/tender-platform/children/tender-own/selectors/auction';

export const ILSAuctionSummary: FC = () => {
  const { auctionInfo } = useSelector(auctionCargoOwnerSelector);
  return (
    <ILSCard>
      <ILSTypographyTitle children={auctionInfo} level={2} />
    </ILSCard>
  );
};
