import reduceReducers from 'reduce-reducers';
import initialState from '../store';
import { getBoardsCommonReducer } from '@modules/analytics/common/reducers/get-boards';
import { analyticsGetBoardWidgetReducer } from '@modules/analytics/common/reducers/get-board-widget';
import { analyticsGetWidgetDataReducer } from '@modules/analytics/common/reducers/get-widget-data';
import { widgetCatalogReducer } from './widget-catalog';

import { analyticsUpdateWidgetReducer } from '@modules/analytics/common/reducers/update-widget';
import { updateBoardSetsReducer } from './update-board-sets';
import { createBoardSetsReducer } from './create-board-set';
import { deleteBoardSetsReducer } from './delete-board-set';
import { selectBoardCommonReducer } from '@modules/analytics/common/reducers/select-board';
import { selectBoardSetCommonReducer } from '@modules/analytics/common/reducers/select-board-set';
import { getBoardSetsCommonReducer } from '@modules/analytics/common/reducers/get-board-sets';
import { analyticsPlanningActions } from '../actions';

import { sortBoardSetsReducer } from './sort-board-sets';
import { createBoardReducer } from './create-board';
import { copyBoardReducer } from './copy-board';
import { deleteBoardReducer } from './delete-board';
import { updateBoardReducer } from './update-board';
import { sortBoardsReducer } from './sort-boards';
import { copyBoardSetsReducer } from './copy-board-set';
import { setBoardWidgetReducer } from './set-board-widget';
import { markBoardWidgetReducer } from './mark-board-widget';
import { dropWidgetBoardReducer } from './drop-board-widget';
import { setParamsCommonReducer } from '@modules/analytics/common/reducers/set-params';
import { WidgetCommonParam } from '@modules/analytics/common/constants';
import { AnalyticsStore } from '@modules/analytics/common/types/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardModel, BoardDefaults } from '@common/types/dictionaries/analytics';
import { analyticsCancelWidgetsUpdateReducer } from '@modules/analytics/common/reducers/cancel-widgets-update';
import { deleteBoardWidgetReducer } from '@modules/analytics/planning/children/planning-analytics/reducers/delete-board-widget';
import { getBoardDateRangeFormatted } from '@modules/analytics/common/utils/get-board-date-range';
import { setBoardWidgetBatchReducer } from '@modules/analytics/planning/children/planning-analytics/reducers/set-board-widget-batch';

const onSelectBoardSuccess = (state: AnalyticsStore, action: PayloadAction<AnalyticsBoardModel & BoardDefaults>) => {
  if (!action.payload?.ID) return state;
  const selectedBoard = state.Boards.data[action.payload.ID];
  if (selectedBoard && selectedBoard.From && selectedBoard.Till) {
    const { from, till } = getBoardDateRangeFormatted(selectedBoard);
    state.WidgetParams = {
      From: { name: WidgetCommonParam.From, value: from },
      Till: { name: WidgetCommonParam.Till, value: till }
    };
  }
  return state;
};

const AnalyticsPlanningRootReducer = reduceReducers(
  initialState,
  getBoardSetsCommonReducer(analyticsPlanningActions),
  getBoardsCommonReducer(analyticsPlanningActions),
  getBoardSetsCommonReducer(analyticsPlanningActions),
  analyticsGetBoardWidgetReducer(analyticsPlanningActions),
  analyticsGetWidgetDataReducer(analyticsPlanningActions),
  widgetCatalogReducer,
  analyticsUpdateWidgetReducer(analyticsPlanningActions),
  analyticsCancelWidgetsUpdateReducer(analyticsPlanningActions),
  updateBoardSetsReducer,
  createBoardSetsReducer,
  deleteBoardSetsReducer,
  selectBoardCommonReducer(analyticsPlanningActions, {
    onSuccess: onSelectBoardSuccess
  }),
  selectBoardSetCommonReducer(analyticsPlanningActions),
  setParamsCommonReducer(analyticsPlanningActions),
  sortBoardSetsReducer,
  createBoardReducer,
  copyBoardReducer,
  deleteBoardReducer,
  updateBoardReducer,
  sortBoardsReducer,
  copyBoardSetsReducer,
  setBoardWidgetReducer,
  setBoardWidgetBatchReducer,
  markBoardWidgetReducer,
  deleteBoardWidgetReducer,
  dropWidgetBoardReducer
);

export default AnalyticsPlanningRootReducer;
