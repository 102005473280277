import { ILSFormItem } from '@common/components';
import { DATE_TIME_SOURCE_BLOCK, DATE_TIME_TARGET_BLOCK, ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { FormType } from '@modules/orders-and-trips/types/general';
import { FC } from 'react';

interface IProps {
  pointType: number;
  disabled: boolean;
  pointIndex: number;
  showForm: FormType | null;
}

export const DateTimeBlock: FC<IProps> = ({ pointType, disabled, pointIndex, showForm }) => {
  const dateTimeFields = pointType ? DATE_TIME_SOURCE_BLOCK : DATE_TIME_TARGET_BLOCK;
  return (
    <div className="order-action-form-date flex-horizontal">
      {dateTimeFields.map(({ Component, name, label, noDisplay, defaultProps }) => {
        if (noDisplay.includes(showForm as FormType)) return null;
        return (
          <ILSFormItem name={[pointIndex, name]} label={label} rules={ORDER_AND_TRIP_FORM_RULES[name]}>
            <Component {...(defaultProps ?? {})} disabled={disabled} />
          </ILSFormItem>
        );
      })}
    </div>
  );
};

