import { defineModule } from '@core/modules';
import { PaymentContainer } from '@common/feature/payment/containers/payment-container';
import { FeatureModule } from '@common/types/modules/module';
import paymentReducer from '@common/feature/payment/reducers';
import { getAllModuleSagas } from '@core/sagas';
import * as rootSagas from '@common/feature/payment/sagas';

export default defineModule({
  title: FeatureModule.Payment,
  component: PaymentContainer,
  reducer: paymentReducer,
  sagas: getAllModuleSagas(rootSagas)
});
