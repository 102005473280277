import React from 'react';

/** Тип для формирования табов
 * @key -  ключ
 * @label - подпись
 * @component  - компонент для отрисовки React.FC<EventDetailsTabProps>
 * @initial - изначально активный таб
 */
export type EventDetailsTab = {
  key: TabType;
  label: string;
  initial?: boolean;
  component?: React.FC<EventDetailsTabProps>;
};

// Запрос на изменение активного таба
export type TabChangePayload = {
  activeKey: string | number; // Ключ активного таба
};

// Свойства таба
export type EventDetailsTabProps = {
  tab: EventDetailsTab; // Таб
};

// Тип таба
export enum TabType {
  Track = 'TRACK', // Трек
  PlanFact = 'PLAN_FACT', // Таблица план / факт
  Sensor = 'SENSOR', // График с информацией датчиков
  Bdd = 'BDD' // Таб Безопасности Дорожного Движения
}
