import { put, select, takeLatest, call } from 'redux-saga/effects';
import { planningSetParamsRoutine } from '@modules/planning/actions';
import { planningParamsSelector } from '@modules/planning/selectors';
import { PlannerLocalConf } from '@modules/planning/types/store';
import { PlanningAPI } from '@modules/planning/api';
import { RequestParams } from '@common/constants/settings/api';
import { PlannerParamsPayload } from '@modules/planning/types/params';
import { PayloadAction } from '@reduxjs/toolkit';
import { defaultPlannerParams } from '@modules/planning/constants/planner';

export function* planningSetParamsWorker(action: PayloadAction<{ resetParams: boolean }>) {
  const { success, failure, fulfill, request } = planningSetParamsRoutine;
  const { resetParams } = action.payload;

  try {
    yield put(request());

    const params: PlannerLocalConf = yield select(planningParamsSelector);
    const payload: PlannerParamsPayload = {
      binding: RequestParams.BindingAccount,
      paramName: RequestParams.PlannerParams,
      value: resetParams ? defaultPlannerParams : params
    };

    if (resetParams) {
      yield call(PlanningAPI.mergePlannerParams, {
        ...payload,
        value: null
      });
    }

    const {
      data: { data }
    } = yield call(PlanningAPI.mergePlannerParams, payload);

    yield put(success({ value: data.value }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningSetParamsWatcher() {
  yield takeLatest(planningSetParamsRoutine.trigger, planningSetParamsWorker);
}

