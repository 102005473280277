import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { ColumnTable, IILSTableProps, TableConfig, TableName, TableRecord } from '@common/types';
import classnames from 'classnames';
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from '@common/constants';
import { TableCustomGroupingButton } from './grouping-button';
import { TableCustomConfigButton } from '@core/components/table-custom/config-button';

export interface TableCustomComponentProps<R extends TableRecord> extends IILSTableProps<R> {
  showConfig: boolean;
  onCheckboxChange: (item: ColumnTable<R>, child: string, changeAllTo?: boolean) => void;
  onConfigChange: () => void;
  onOrderChange: (cols: ColumnTable<R>[], child: string) => void;
  onSetDefault: () => void;
  config: TableConfig;
  ChildTable: FC<{ columns: ColumnTable<R>[] }>;
  activeColumns: ColumnTable<R>[];
  tableName: TableName | string;
  setShowConfig: Dispatch<SetStateAction<boolean>>;
  onGrouping?(colsForGrouping: ColumnTable<TableRecord>['dataIndex'][]): void;
}

export const TableCustomComponent: FC<TableCustomComponentProps<any>> = ({
  activeColumns,
  ChildTable,
  onCheckboxChange,
  onConfigChange,
  onOrderChange,
  onSetDefault,
  setShowConfig,
  showConfig,
  ...props
}) => {
  const { config, recordTableButtons, settingsTableButtons, tableWidgets, actionTableButtons, onGrouping } = props;

  const configButton = !config.unchangeable
    ? [
        {
          Component: TableCustomConfigButton,
          props: {
            activeColumns,
            ChildTable,
            onCheckboxChange,
            onConfigChange,
            onOrderChange,
            onSetDefault,
            setShowConfig,
            showConfig,
            ...props
          }
        }
      ]
    : [];

  const isTableWithGrouping = config?.columns.some((column) => column.grouping);

  const groupingButton = isTableWithGrouping
    ? [
        {
          Component: TableCustomGroupingButton,
          props: {
            columns: config?.columns,
            storedTableGrouping: config?.grouping,
            onGrouping
          }
        }
      ]
    : [];

  const tableButtons = useMemo(
    () => [...configButton, ...groupingButton, ...(settingsTableButtons ?? EMPTY_ARRAY_DATA)],
    [settingsTableButtons, config?.grouping, config.columns]
  );

  const childTableProps = {
    ...props,
    recordTableButtons,
    tableWidgets,
    actionTableButtons,
    settingsTableButtons: tableButtons
  };

  return (
    <section className={classnames('table-config', { [config?.configClassName ?? EMPTY_STRING]: config.configClassName })}>
      <ChildTable columns={activeColumns} {...childTableProps} />
    </section>
  );
};
