import moment, { Moment } from 'moment';
import { ILSButton, ILSTimePicker } from '@components/index';
import { ILS_MAIN_INTERFFACE_TIME_FORMAT } from '@common/types/general/date-time';
import { getTimeForCell } from '@components/data-display/table/utils/helpers/cells/time';
import { doubleToTime } from '@common/utils';
import { IILSCellTimeProps } from '@common/types/components/table/cell';
import { ButtonAction } from '@components/general/buttons';
import { TableRecord } from '@common/types';

export const ILSCellTime = <R extends TableRecord>({
  double,
  format = ILS_MAIN_INTERFFACE_TIME_FORMAT,
  children,
  toggleEdit,
  save,
  dateParseFormat = ILS_MAIN_INTERFFACE_TIME_FORMAT
}: IILSCellTimeProps<R>): JSX.Element => {
  const [node, time] = children;
  const defaultValue = double ? moment(doubleToTime(Number(time || 0)), format) : getTimeForCell(Number(time), format, dateParseFormat);

  const handleChange = (time: Moment | null) => {
    if (!time) return;
    save(time.format(format));
  };

  return (
    <>
      {node}
      <ILSTimePicker
        className={'cell-wrapper-editable'}
        renderExtraFooter={() => <ILSButton action={ButtonAction.Cancel} onClick={toggleEdit} />}
        open
        autoFocus
        defaultValue={defaultValue}
        onChange={handleChange}
        allowClear={false}
      />
    </>
  );
};
