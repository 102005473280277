import { ILSRadioGroup } from '@common/components';
import { TRANSPORT_TYPE_COMPONENTS } from '@modules/orders-and-trips/constants/display-blocks';
import { TRANSPORT_TYPE_RADIO_OPTIONS } from '@modules/orders-and-trips/constants/general';
import { TransportType } from '@modules/orders-and-trips/types/general';
import { FC, memo, useState } from 'react';

export const SearchPerformerContainer: FC = () => {
  const [activeTransportType, setActiveTransportType] = useState<TransportType>(TransportType.TransportCompany);
  const Content = TRANSPORT_TYPE_COMPONENTS[activeTransportType];
  return (
    <>
      <ILSRadioGroup
        optionType="button"
        className="search-performer"
        options={TRANSPORT_TYPE_RADIO_OPTIONS}
        defaultValue={activeTransportType}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActiveTransportType(e.target.value as TransportType)}
      />
      <Content />
    </>
  );
};
