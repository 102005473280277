import { IndexedArray, Vehicle, Waypoint } from '@common/types';
import { RouteTotal } from '@common/types/dictionaries/route';
import { moveWaypointToTripRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export const moveWaypointToTripReducer = createReducer(initialState, {
  [moveWaypointToTripRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      routesTotal: IndexedArray<RouteTotal>;
      waypointIDs: Array<Waypoint['ID']>;
      deletedSourceTripRoutesIDs: Array<Vehicle['ID']>;
    }>
  ) => {
    const { routesTotal, waypointIDs, deletedSourceTripRoutesIDs } = action.payload;

    for (const vehicleID in routesTotal) {
      /** Добавляем карандаш рейсам у маршрута из которого перенесли точку */
      state.currentPlan.RouteTotal[vehicleID] = { ...routesTotal[vehicleID], changed: true };
      for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
        /** Добавляем карандаш рейсам у маршрута в который перенесли точку */
        state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
        waypointIDs.forEach((id) => {
          if (state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID]?.WaypointTotal?.[id]) {
            state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[id].changed = true;
          }
        });
      }
    }

    deletedSourceTripRoutesIDs.forEach((vehicleID) => {
      delete state.currentPlan.RouteTotal[vehicleID];
    });

    return state;
  }
});

