import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import MonitoringControl from './containers';
import monitoringControl from './reducer';
import { monitoringControlWatcher } from './sagas';

export default defineModule({
  title: ModuleTitle.MonitoringDelivery,
  path: ModulePath.MonitoringDelivery,
  component: MonitoringControl,
  reducer: monitoringControl,
  sagas: [monitoringControlWatcher()]
});
