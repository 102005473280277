import { TenderStore } from '@modules/tender-platform/types';
import { createSelector } from 'reselect';
import { currentTenderSelector } from './current-tender';

export { auctionSummarySelector, isAuctionSelector, currentTenderAuctionPriceSelector } from './auctions';
export { menuButtonsSelector } from './components';
export {
  currentTenderSelector,
  currentTenderLotSelector,
  currentTenderParticipantsSelector,
  currentTenderStatusConfigSelector,
  currentTenderTypeSelector,
  tenderOffersSelector,
  currentTenderMyOfferSelector,
  acceptedTenderOfferSelector,
  accountUsersSelector,
  currentTenderAcceptedOfferSelector,
  currentTenderOffersSelector,
  currentTenderAliasSelector,
  currentTenderTenderOfferSelector,
  currentTenderTenderOffersSelector,
  currentTenderTransitionSchemeSelector,
  currentTenderUserSelector,
  hasCurrentTenderAcceptedOfferSelector,
  hasTenderWinnerSelector,
  isCurrentTenderDatesNilSelector,
  tenderTemplateDataSelector,
  temporaryLotDistanceSelector,
  tenderActionSelector,
  tenderDocumentsSelector,
  tenderVehicleTypeSelector,
  accountUsersOptionsSelector,
  currentTenderLotsSelector,
  currentTenderRouteListSelector,
  isCurrentTenderFromPlannerSelector
} from './current-tender';
export { tenderInfoSelector } from './permissions';

export const fetchSelector = createSelector(
  (state: TenderStore) => state.Tender.isFetching,
  (isFetching: boolean) => isFetching
);

