import { CatalogStateType, dictionaries, IDefaultState, Info, StateFields } from '../types/store';
import { ShowDeleted } from '@common/types';

const defaultState: IDefaultState = {
  isFetching: false,
  lastUpdate: null,
  data: [],
  error: null,
  execStatus: {},
  focusRow: undefined,
  refresh: false,
  showDeleted: ShowDeleted.No,
  projectID: false,
  parameters: null,
  map: {
    visible: true,
    widthPercent: 40
  }
};

const stateFields: StateFields | any = {};

const info: Info | any = {};

dictionaries.forEach((d) => {
  stateFields[d] = defaultState;
  info[d] = {
    isFetching: false,
    lastUpdate: null
  };
});

const initialState: CatalogStateType = {
  ...stateFields,
  info
};

export default initialState;
