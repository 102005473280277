import { CellType, ColumnTable, TableRecord } from '@common/types';

export type MonitoringExelData = { headers: ColumnTable<any>['title'][]; rows: Array<Array<string>> };
export const ACCESS_CELL_TYPES: Array<CellType> = [
  CellType.Input,
  CellType.RegNumber,
  CellType.Bool,
  CellType.Date,
  CellType.Select,
  CellType.Color,
  CellType.DateTime,
  CellType.Email,
  CellType.Float,
  CellType.Geo,
  CellType.Int,
  CellType.Percent,
  CellType.Phone,
  CellType.TimeStringUtc,
  CellType.Ufloat,
  CellType.Uint,
  CellType.SelectMultiple
];

// Helper, который помогает подготовить данные для выгрузки в excel файл
export const createExelData: <T>(
  d: Array<T>,
  c: Pick<ColumnTable<TableRecord>, 'title' | 'type' | 'dataIndex'>[]
) => MonitoringExelData | undefined = (dataSource, columns) => {
  if (!dataSource.length || !columns.length) return undefined;
  const headers = createHeader(columns);
  const rows = createData(dataSource, columns);
  return { headers, rows };
};

export const createHeader = <R>(columns: Pick<ColumnTable<R>, 'title' | 'type' | 'dataIndex'>[]): MonitoringExelData['headers'] => {
  return columns.reduce((acc: ColumnTable<R>['title'][], cur) => {
    if (cur.title && cur.dataIndex && ACCESS_CELL_TYPES.includes(cur.type)) {
      acc.push(cur.title);
    }
    return acc;
  }, [] as ColumnTable<R>['title'][]);
};
type IndexItem = {
  index: string;
  type: CellType;
};
export const createData: <T>(
  d: Array<T>,
  c: Pick<ColumnTable<TableRecord>, 'title' | 'type' | 'dataIndex'>[]
) => MonitoringExelData['rows'] = (dataSource, columns) => {
  const dataIndexes: Array<IndexItem> = columns.reduce((acc: IndexItem[], cur) => {
    if (cur.title && cur.dataIndex && ACCESS_CELL_TYPES.includes(cur.type)) {
      acc.push({ index: cur.dataIndex.toString(), type: cur.type });
    }
    return acc;
  }, [] as IndexItem[]);
  const data: Array<Array<string>> = dataSource.map((record) =>
    dataIndexes.map((indexItem) => cellToString(record?.[indexItem.index], indexItem.type))
  );

  return data;
};

//TODO добавить кейсы для селектов, чтобы они получали видимые значения
export const cellToString = (value: any, cellType: CellType) => {
  switch (cellType) {
    case CellType.Input:
      return value;
    default:
      return value?.toString() || '';
  }
};
