import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { DecodeGeohashFunc, DecodedGeohash } from '@core/types/map';
import { useMemo } from 'react';

// Кэширование для декодированных координат
export const useCachedGeohash = () => {
  return useMemo((): DecodeGeohashFunc => {
    const cache: DecodedGeohash = {};
    return function (hashCoords: string) {
      if (!hashCoords) return [];
      if (cache[hashCoords] === null || cache[hashCoords] === undefined) {
        cache[hashCoords] = GeohashDecodePoints(hashCoords);
      }
      return cache[hashCoords];
    };
  }, []);
};
