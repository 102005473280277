import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { ActionType } from '@core/types';
import { EmulatorAction } from '../types/action';
import { Action } from 'redux';

/**  Получает план и автомобили, входящие в этот план */
export const emulatorGetPlanRoutine = createRoutine(EmulatorAction.GetPlan);
/**  Получает проекты, у которых есть план принятый к исполнению */
export const emulatorGetProjectsRoutine = createRoutine(ActionType.Emulator_GetProjects);
/**  Стартует Эмулятор */
export const emulatorStartRoutine = createRoutine(EmulatorAction.Start);
/**  Останавливает текущую эмуляцию */
export const emulatorStopRoutine = createRoutine(EmulatorAction.Stop);
/**  Ставит на паузу эмуляцию */
export const emulatorPauseRoutine = createRoutine(EmulatorAction.Pause);
/**  Рутина связанная с селектом скорости эмуляции */
export const emulatorChangeEmulationSpeedRoutine = createRoutine(EmulatorAction.ChangeEmulationSpeed);
/**  Рутина для проверки статуса эмуляции */
export const emulatorCheckStatusRoutine = createRoutine(EmulatorAction.CheckStatus);
/**  Создаёт конфигурацию для эмулятора */
export const emulatorCreateConfigRoutine = createRoutine(EmulatorAction.CreateConfig);
/**  Получает конфигурацию */
export const emulatorGetConfigRoutine = createRoutine(EmulatorAction.GetConfig);
/**  Сохраняет значения свойств для автомобилей из таблицы настроек */
export const emulatorSaveVehiclePropsRoutine = createRoutine(EmulatorAction.SaveVehicleProps);
/**  Делает апдейт конфигурации после изменений */
export const emulatorUpdateConfigRoutine = createRoutine(EmulatorAction.UpdateConfig);

/**  Список рутин для которых подключены уведомления */
export const emulatorRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [
  emulatorStartRoutine,
  emulatorSaveVehiclePropsRoutine,
  emulatorStopRoutine,
  emulatorPauseRoutine
];
