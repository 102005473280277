import { Dictionary, ShowDeleted } from '@common/types';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch, useSelector } from 'react-redux';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { DictionaryOperationRequest, DictionaryReadRequest, DictionaryRequest } from '@common/types/general/api/dictionary';

export const useCatalog = (
  payload?: DictionaryOperationRequest & {
    dictionaryFetching?: boolean;
    projectListUpdate?: boolean;
  }
) => {
  const dispatch = useDispatch();
  const projectID = useSelector(projectIDSelector);

  const catalogLoad = (dictionary: Dictionary, showDeleted?: ShowDeleted, useProjectID?: boolean, getFullData?: boolean) => {
    if (!dictionary) return;
    const payloadTrigger: Partial<DictionaryRequest<DictionaryReadRequest> & { dictionaryFetching?: boolean }> = {
      dictionaryFetching: true,
      ...(payload ?? {}),
      ids: [],
      dictionary,
      showDeleted,
      getFullData
    };
    if ((useProjectID || dictionary === Dictionary.Order) && projectID) {
      payloadTrigger.projectID = Number(projectID);
    }
    dispatch(dictionaryPlannerRoutine(payloadTrigger));
  };
  return { catalogLoad };
};
