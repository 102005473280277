import { AccountState } from '../types/store';
import { getInitialState } from '@common/utils';
import { Module } from '@common/types';

export const INITIAL_ACCOUNT_STATE: AccountState = {
  data: null,
  users: [],
  accountRoles: [],
  organizationTypes: null,
  extraAttachments: {
    isFetching: false,
    facsimile: null
  }
};

export const initialState = getInitialState(INITIAL_ACCOUNT_STATE, Module.Account);

