import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { activeRestrictionIDsFiltersSelector } from '@modules/planning/children/by-date/selectors/restriction/active-restriction-ids-filters';
import { activePlanRestrictionDataSelector } from '@modules/planning/children/by-date/selectors/restriction/active-plan-restriction-data';

export const restrictionMenuSelector = createSelector(
  activeRestrictionIDsFiltersSelector,
  activePlanRestrictionDataSelector,
  (activeRestrictionIDs, restrictions) => {
    const options = Object.entries(restrictions).map(([ID, Name]) => {
      return { value: Number(ID), label: Name as string };
    });
    const pickedRestriction = isEmpty(activeRestrictionIDs) ? null : `${activeRestrictionIDs.length}/${options?.length}`;
    return { options, activeRestrictionIDs, pickedRestriction };
  }
);
