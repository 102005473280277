import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanStatus } from '@common/types';
import { PlanID } from '@common/types/dictionaries/plan';
import { planningUnsetExecutionRoutine } from '@modules/planning/children/by-date/actions';
import { compareAsString } from '@common/utils';

export const planningUnsetExecutionReducer = createReducer(initialState, {
  [planningUnsetExecutionRoutine.SUCCESS]: (state, action: PayloadAction<{ planID: PlanID }>) => {
    const { planID } = action.payload;

    if (state.project?.Plan) {
      state.project.Plan[planID].PlanTotal.Status = PlanStatus.Open;
    }

    if (compareAsString(planID, state.activePlanID)) {
      for (const vehicleID in state.currentPlan.RouteTotal) {
        state.currentPlan.RouteTotal[vehicleID].Status = PlanStatus.Open;
        for (const tripID in state.currentPlan.RouteTotal[vehicleID].TripTotal) {
          state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].Status = PlanStatus.Open;
          for (const waypointID in state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal) {
            state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID].Status = PlanStatus.Open;
            for (const orderActionID in state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID]
              .OrderActionTotal) {
              state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[waypointID].OrderActionTotal[orderActionID].Status =
                PlanStatus.Open;
            }
          }
        }
      }
    }
    return state;
  }
});
