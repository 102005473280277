import { USE_CORE_STATE } from '@core/constants';
import { allModules, getModules } from '@core/modules';
import { createRootReducer } from '@core/reducers';
import { getRoutes } from '@core/routes';
import { sagaInjector as createSagaInjector, sagaMiddleware } from '@core/sagas';
import Modules from '@modules/index';
import Pages from '@pages/index';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import App from '../App';
import { persistConfig } from '@core/persist-config';
import { createPersistedStore } from '@core/persist/create-persisted-store';
import configureRootStore from '@core/store';
import { getModulesPersistTransform } from '@core/persist/transforms';
import { runCheckProductVersion } from '@core/helpers/run-check-product-version';

const run = async (versionProductCheckInterval: NodeJS.Timer | null) => {
  const modules = getModules(Modules);
  const pages = getModules(Pages);
  const sagaInjector = createSagaInjector(USE_CORE_STATE);
  const reducerManager = createRootReducer({ modules, rootReducers: USE_CORE_STATE });
  const middleware = [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false
    }),
    sagaMiddleware
  ];
  const routes = getRoutes(pages, App);

  const reducer = reducerManager.reduce;

  const { transforms, blacklist } = getModulesPersistTransform(modules);
  persistConfig.transforms = transforms;
  persistConfig.blacklist = blacklist;
  const { store, Wrapper, onModuleChange, persistor } = createPersistedStore(
    reducer,
    (reducer, options) => configureRootStore(reducer, middleware, options),
    persistConfig
  );

  store['reducerManager'] = reducerManager;
  store['sagaInjector'] = sagaInjector;

  const registry = {
    store,
    routes,
    Wrapper,
    modules: allModules(modules, store),
    onModuleChange,
    sagaInjector,
    persistor
  };

  sagaInjector.run();

  if (!versionProductCheckInterval) {
    versionProductCheckInterval = runCheckProductVersion(store);
  }

  return registry;
};

export default run;

