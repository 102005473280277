import { Key } from '@common/types';
import { compareAsString } from '@common/utils';

type Props = {
  dataSource: Array<{ ID?: number }>;
  deletedRowID?: number | Key;
};

export const getPrevRowID = ({ dataSource, deletedRowID }: Props) => {
  const deletedRowIndex = deletedRowID ? dataSource?.findIndex((dataSource) => compareAsString(dataSource?.ID, deletedRowID)) : undefined;
  return deletedRowIndex ? dataSource?.[deletedRowIndex - 1]?.ID : undefined;
};

