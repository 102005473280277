import { Dictionary, Keys } from '@common/types';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';
import { isEmpty, isNumber, isObject } from 'lodash';
import { handleCheckCoords, HandleCheckCoordsArgType } from '@common/utils/table/handle-check-coords';

type CheckItem = { ID: number; coords?: number[]; address?: string; dictionary: Dictionary };

export const checkCoordinates = (
  ids: Keys,
  dataSource: Array<any>,
  dictionary: Dictionary,
  getCoordinates: (arg: HandleCheckCoordsArgType) => void
) => {
  const isCheckAllRecords = isEmpty(ids);
  //NOTE Если выбраны все, оставляем только те у которых нет части данных
  const dataSourceWithCoordinatesOrAddress = isCheckAllRecords
    ? dataSource.filter(
        ({ LatLon, AddressDescription }) => (isObject(LatLon) && Object.values(LatLon).every(isNumber)) !== !isEmpty(AddressDescription)
      )
    : dataSource.filter(({ ID }: any) => ids.includes(ID));

  const dataSourceForCheck: Array<CheckItem> = dataSourceWithCoordinatesOrAddress?.reduce((prev, item) => {
    const checkItem: CheckItem = { ID: item.ID, dictionary };

    if (item.LatLon && typeof item.LatLon === 'object') {
      checkItem.coords = Object.values(item.LatLon);
    }

    if (item.AddressDescription) {
      checkItem.address = item.AddressDescription;
    }

    prev.push(checkItem);
    return prev;
  }, []);
  const showModal = dataSourceForCheck.length === 1 && !isCheckAllRecords;
  handleCheckCoords({
    arg: {
      records: dataSourceForCheck,
      showModal,
      updatePriority: CoordinatesUpdatePriority.byAddress
    },
    getCoordinates
  });
};
