import { ILSButton } from '@common/components';
import { FC } from 'react';
import { AuctionFormValues } from '@modules/tender-platform/children/tender-search/constants/offer';
import { useSelector } from 'react-redux';
import { currentTenderLotSelector } from '@modules/tender-platform/selectors';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

interface IProps {
  onFinish?(values: AuctionFormValues): void;
}

export const TenderPlatformTakeWithMinPriceButton: FC<IProps> = ({ onFinish, children = 'Принять' }) => {
  const form = useFormInstance();
  const { MinPrice } = useSelector(currentTenderLotSelector);
  return (
    <ILSButton
      children={children}
      disabled={!MinPrice}
      className="grey"
      onClick={() => onFinish?.({ ...form?.getFieldsValue(), price: MinPrice })}
    />
  );
};
