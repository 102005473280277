import { FC } from 'react';
import { DetailsSubTableContainer } from '@modules/monitoring/children/event-details/containers/tables/details-sub-table';
import { EventDetailsTab } from '../../types';
import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';

interface Props {
  tab: EventDetailsTab;
  vehicleSensor: {
    data: IndexedArray<MonitoringBDDAndFuelDrainModel>;
    lastUpdate?: number;
    isFetching: boolean;
    loadedVehicleId?: number;
    lastUpdateInterval?: {
      from: string;
      till: string;
    };
  };
  selectedVehicleID: number | undefined;
}

/**
 * Таблица детализации - График показания датчиков
 *
 */
export const DetailsSensorComponent: FC<Props> = ({ tab, vehicleSensor, selectedVehicleID }) => {
  return (
    <>
      <DetailsSubTableContainer title={tab.label} vehicleSensor={vehicleSensor} selectedVehicleID={selectedVehicleID} />
    </>
  );
};
