import { FC, forwardRef, useEffect, useState } from 'react';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { ILSSelect } from '@common/components';

export const ILSSelectCloseOnScroll: FC<IILSSelectProps> = forwardRef(
  ({ tableRef, onDropdownVisibleChange: onOpen, showSearch = true, tokenSeparators = [','], ...props }, ref) => {
    const el = tableRef?.current;
    const [open, setOpen] = useState(false);

    useEffect(() => {
      const scrollHandler = () => setOpen(false);
      //TODO Если две таблицы то не работает нужно доработать
      const scrollContainer = el?.closest('.ant-table-body') || document.querySelector('.ant-table-body');
      scrollContainer?.addEventListener('scroll', scrollHandler);

      const removeListener = () => {
        scrollContainer?.removeEventListener('scroll', scrollHandler);
      };

      return () => removeListener();
    }, []);

    useEffect(() => {
      onOpen?.(open);
    }, [open]);

    return (
      <ILSSelect
        {...props}
        tokenSeparators={tokenSeparators}
        showSearch={showSearch}
        ref={ref}
        open={props.open || open}
        onDropdownVisibleChange={(isOpening: boolean) => {
          setOpen(isOpening);
        }}
        getPopupContainer={() => document.querySelector('body') as HTMLBodyElement}
      />
    );
  }
);
