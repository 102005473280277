import { createSelector } from 'reselect';
import {
  ISchemeEditorState,
  SchemeColumn,
  SchemeEditorStore,
  SchemeSwitchDictionary,
  TypeContainer
} from '@modules/planning/children/scheme-editor/types';
import { createDataForScheme } from '@modules/planning/children/scheme-editor/utils/helpers/selctors';
import { schemeDictionarySelectSelector } from '@modules/planning/children/scheme-editor/selectors/max-scheme';

export const currentSchemeKeysSelector = createSelector(
  (state: SchemeEditorStore) => state.PlannerSchemeEditor.currentSchemeDictionaries,
  (currentSchemeKeys: ISchemeEditorState['currentSchemeDictionaries']) => currentSchemeKeys
);

export const currentSchemeKeysInDictionariesSelector = createSelector(
  (state: SchemeEditorStore) => state.PlannerSchemeEditor.currentSchemeKeysInDictionaries,
  (currentSchemeKeysInDictionaries: ISchemeEditorState['currentSchemeKeysInDictionaries']) => currentSchemeKeysInDictionaries
);

export const getSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(
    currentSchemeKeysInDictionariesSelector,
    (currentSchemeKeysInDictionaries: ISchemeEditorState['currentSchemeKeysInDictionaries']) =>
      currentSchemeKeysInDictionaries?.[dictionary] ?? []
  );

export const currentSchemeSelector = createSelector(
  (state: SchemeEditorStore) => state.PlannerSchemeEditor.currentScheme,
  (currentScheme: ISchemeEditorState['currentScheme']) => currentScheme
);

export const getDictionaryCurrentSchemeSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(currentSchemeSelector, (currentScheme) => currentScheme?.[dictionary] ?? ({} as SchemeColumn));

export const dictionaryCurrentSchemeDataSelector = (dictionary: SchemeSwitchDictionary) =>
  createSelector(
    getDictionaryCurrentSchemeSelector(dictionary),
    getSelector(dictionary),
    schemeDictionarySelectSelector(dictionary),
    (schemeColumn, keys, availableTypes) => createDataForScheme(schemeColumn, keys, TypeContainer.Current, availableTypes)
  );
