import { LatLonVerified } from '../components';
import { Double, ID, TimeStamp } from '../general';
import { Account } from './account';
import { Contact } from './contact';
import { IEnum, IndexedArray } from './enum';
import { Restriction } from './restriction';
import { TripTemplate } from './trip';
import { Worktime } from './worktime';
import { Zone } from './zone';
import { generateUUID } from '@common/utils/general/uuid';
import { ILSAPILatLonLiteral } from '@common/types/general/geohash';

export type DepotModel = {
  ID: number;
  DepotTypeID: DepotTypeId;
  Name: string;
  Color: number | null;
  LatLon: ILSAPILatLonLiteral | null;
  DepotCode: string;
  Description: string | null;
  AddressDescription: string | null;
  Delay: number | null;
  WorktimeID: Worktime['ID'] | null;
  DepotOverloadID: DepotOverload['ID'] | null;
  AccountID: Account['ID'];
  Deleted: TimeStamp | null;
  Radius: number | null;
  Queue: number | null; //(0, null) - без очереди
  ZoneID: number | null;
  DistanceFromCity: number | null;
  ClientContact: string | null;
  Info: JSON | null;
  Value1: number | null;
  Value2: number | null;
  Value3: number | null;
  VisitPrice: number | null;
  UTCOffset: number | null;
  TimeZone: string | null;
  LatLonVerified: LatLonVerified | null;
  ICode: null;
  AddressGUID: null;
  AllowKeepTrailer: 0 | 1;
};

export type Depot = DepotModel & {
  Worktime?: Worktime | null;
  ContactEnum?: IEnum<Contact['Name']>;
  WorktimeEnum?: IEnum<Worktime['Name']>;
  DepotOverloadEnum?: IEnum<DepotOverload['Name']>;
  Restriction?: Array<Restriction>;
  TripTemplate?: Array<TripTemplate>;
  ZoneEnum?: IEnum<Zone['Name']>;
  DepotOverload?: DepotOverload | null;
  DepotTypeEnum?: IEnum<DepotType[DepotTypeId]>;
};
export type Depots = Array<Depot> | IndexedArray<Depot>;
export type DepotResponse = Depot;

export type DepotOverload = {
  ID: ID;
  Name: string;
  UnloadStartOverload: Double | null;
  UnloadEndOverload: Double | null;
  LoadStartOverload: Double | null;
  LoadEndOverload: Double | null;
  Deleted: TimeStamp | null;
};

export type DepotOverloads = Array<DepotOverload>;

export type DepotUploadFileType = {
  [k in DepotTableColumn]: string | number | '';
};

export enum DepotCode {
  Client = '1',
  Storage = '2',
  CrossDock = '3',
  Monitoring = '4',
  LoadDepot = '5'
}

export enum DepotRecordKeys {
  LAT_LON = 'LatLon',
  ADDRESS_DESCRIPTION = 'AddressDescription',
  LAT_LON_VERIFIED = 'LatLonVerified'
}

export enum DepotType {
  Client = 'client',
  Storage = 'storage',
  CrossDock = 'crossDock',
  Monitoring = 'monitoring',
  Load = 'load',
  LoadDepot = 'load'
}

export enum DepotTypeId {
  NoDepot,
  Client,
  Storage,
  CrossDock,
  Monitoring,
  LoadDepot
}

export type DepotRequest = {
  Name: Depot['Name'];
  DepotTypeID: DepotTypeId;
  DepotCode?: ReturnType<typeof generateUUID>;
};

export enum DepotTableColumn {
  DepotCode = 'DepotCode',
  Name = 'Name',
  DepotType = 'DepotType',
  Description = 'Description',
  AddressDescription = 'AddressDescription',
  ClientContact = 'ClientContact',
  Contact = 'Contact',
  Latitude = 'Latitude',
  Longitude = 'Longitude',
  Delay = 'Delay',
  AllowKeepTrailer = 'AllowKeepTrailer',
  WorktimeLoadStart = 'Worktime.LoadStart',
  WorktimeLoadEnd = 'Worktime.LoadEnd',
  WorktimeUnloadStart = 'Worktime.UnloadStart',
  WorktimeUnloadEnd = 'Worktime.UnloadEnd',
  WorktimeLunchStart = 'Worktime.LunchStart',
  WorktimeLunchEnd = 'Worktime.LunchEnd',
  Restriction = 'Restriction',
  TripTemplate = 'TripTemplate',
  DistanceFromCity = 'DistanceFromCity',
  Radius = 'Radius',
  VisitPrice = 'VisitPrice',
  Queue = 'Queue',
  Value1 = 'Value1',
  Value2 = 'Value2',
  Value3 = 'Value3',
  fileIndex = 'fileIndex',
  key = 'key'
}

export type DepotShort = Record<
  string,
  {
    ID: string | number;
    Name: string;
    AddressDescription: string;
    DepotTypeID: number | string;
    DepotTypeEnum: Record<string, string>;
  }
>;

