export function dateTimeToChartDateFormat(d: Date): string {
  //формат yyyy-mm-dd hh:mm:ss
  let time =
    [d.getFullYear(), (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1), (d.getDate() < 10 ? '0' : '') + d.getDate()].join('-') +
    ' ' +
    [
      (d.getHours() < 10 ? '0' : '') + d.getHours(),
      (d.getMinutes() < 10 ? '0' : '') + d.getMinutes(),
      (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
    ].join(':');
  return time;
}

export function getChartTicksScale(tickCount: number, period: Array<string | number>, type = 'date') {
  //шкала для графика
  const current = type === 'date' ? new Date() : 0;
  let params;
  if (type === 'date') {
    params = { current: new Date(), period1: Date.parse(String(period[0])), period2: Date.parse(String(period[1])) };
  } else {
    params = { current: 0, period1: Number(period[0]), period2: Number(period[1]) };
  }

  let ticks = [];
  for (let i = 0; i <= tickCount; i++) {
    let tick: number | string = params.period1 + ((params.period2 - params.period1) / tickCount) * i;
    tick = type === 'date' ? dateTimeToChartDateFormat(new Date(tick)) : tick.toString();
    ticks.push(tick);
  }
  let ticks1 = [type === 'date' && current !== 0 ? dateTimeToChartDateFormat(current) : current.toString()],
    ticks2 = [type === 'date' && current !== 0 ? dateTimeToChartDateFormat(current) : current.toString()];
  ticks.forEach(function (item, i) {
    const item1 = type === 'date' ? new Date(item) : item;
    if (item1 < current) {
      ticks1.push(item.toString());
    } else {
      ticks2.push(item.toString());
    }
  });

  return [ticks, ticks1, ticks2];
}

type DataSubItem = {
  [K: string | number]: any;
  y: Array<string | number>;
  current?: boolean;
};
type DataItemType = {
  data: Array<DataSubItem>;
  data1?: Array<DataSubItem>;
  data2?: Array<DataSubItem>;
  name: string;
};
type DataType = Array<DataItemType>;

export function getChartNewData(data: DataType, period: Array<string | number>, type = 'date') {
  //данные для графика
  const current = type === 'date' ? new Date() : 0;
  const currentFormatted = current ? dateTimeToChartDateFormat(current) : 0;
  let newData: DataType = [];

  data.forEach(function (dataItem) {
    let subdata1: Array<DataSubItem> = [],
      subdata2: Array<DataSubItem> = [];

    dataItem.data.forEach(function (item) {
      let t1 = new Date(item.y[0]);
      let t2 = new Date(item.y[1]);
      let p1 = new Date(period[0]);
      let p2 = new Date(period[1]);
      if (t2 >= p1 && t1 <= p2) {
        if (t1 < current && t2 > current) {
          subdata1.push({ ...item, y: [item.y[0], currentFormatted], current: true });
          subdata2.push({ ...item, y: [currentFormatted, item.y[1]], current: true });
        } else {
          let newItem;
          if (t1 < p1 || t2 > p2) {
            if (t1 < p1) newItem = { ...item, y: [period[0], item.y[1]] };
            if (t2 > p2) newItem = { ...item, y: [item.y[0], period[1]] };
          } else {
            newItem = { ...item };
          }
          if (newItem) {
            if (t1 < current) {
              subdata1.push(newItem);
            } else {
              subdata2.push(newItem);
            }
          }
        }
      }
    });

    //добавляем крайние точки для корректной отрисовки графиков
    if (subdata1.length) subdata1.unshift({ x: dataItem.name, y: [currentFormatted, currentFormatted], invisible: true });
    if (subdata2.length) subdata2.unshift({ x: dataItem.name, y: [currentFormatted, currentFormatted], invisible: true });
    subdata1.unshift({ x: dataItem.name, y: [period[0], period[0]], invisible: true });
    subdata2.unshift({ x: dataItem.name, y: [period[1], period[1]], invisible: true });

    newData.push({ ...dataItem, data: [], data1: subdata1, data2: subdata2 });
  });

  return newData;
}
