import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { tenderGetDistanceRoutine } from '@modules/tender-platform/actions';
import { onFailure } from '@common/utils/helpers/reducers';

type Place = {
  Latitude: string;
  Longitude: string;
  Name: string;
  success: boolean;
};

type Payload = {
  Distance: number;
  Source?: Place;
  Target?: Place;
};

export const tenderGetDistanceReducer = createReducer(initialState, {
  [tenderGetDistanceRoutine.FAILURE]: onFailure,
  [tenderGetDistanceRoutine.SUCCESS]: (
    state: any,
    action: PayloadAction<{
      data: Payload;
      source: string;
      target: string;
    }>
  ) => {
    const { data } = action.payload;
    state.temporaryLotDistance = data?.Distance?.toFixed(2);
    return state;
  }
});
