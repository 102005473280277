import { DictionariesEndpoint, Dictionary, Keys, TableName, TableRecord } from '@common/types';
import { Dispatch } from 'redux';
import { SelectedOrders } from '@modules/planning/types';
import { filterSelectedOrdersAfterDelete } from '@modules/planning/children/by-date/helpers';
import { DictionaryDeleteRequest, DictionaryOperation } from '@common/types/general/api/dictionary';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';

export const deleteOrders = ({
  keys,
  dispatch,
  selectedOrders,
  onSelectRows
}: {
  keys: Keys;
  dispatch: Dispatch;
  selectedOrders: SelectedOrders;
  onSelectRows(selectedRowKeys: Keys, selectedRows: TableRecord[], tableName: string | undefined): void;
}) => {
  const payload: DictionaryOperation<DictionaryDeleteRequest> & {
    dictionaryFetching?: boolean;
  } = {
    dictionary: Dictionary.Order,
    ids: keys,
    operation: DictionariesEndpoint.Delete
  };
  dispatch(dictionaryPlannerRoutine(payload));
  const selectedOrdersCurrent = filterSelectedOrdersAfterDelete(selectedOrders, keys);
  //TODO типизировать корректно компонент при рефакторинг (selectedOrdersCurrent: (number | string)[], сейчас number[])
  onSelectRows(selectedOrdersCurrent ?? [], [], TableName.PlanningNotDeliveredOrder);
};
