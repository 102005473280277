import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { changeExpeditorInTripRoutine } from '../../actions';
import { PlanningAPI } from '@modules/planning/api';
import { ChangeExpeditorParams } from '@modules/planning/types/api';
import { Vehicle } from '@common/types';

export type ChangeExpeditorInTripPayloadAction = PayloadAction<ChangeExpeditorParams & { vehicleID: Vehicle['ID'] }>;

function* planningChangeExpeditorInTripWorker(action: ChangeExpeditorInTripPayloadAction) {
  const { request, success, failure, fulfill } = changeExpeditorInTripRoutine;
  const { tripID, expeditorID, vehicleID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.changeExpeditorInTrip, { tripID, expeditorID });
    yield put(success({ message: data, tripID, expeditorID, vehicleID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningChangeExpeditorInTripWatcher() {
  yield takeLatest(changeExpeditorInTripRoutine.trigger, planningChangeExpeditorInTripWorker);
}
