import { IDemandStore } from '../types/store';
import { DemandTabType } from '../types/tabs';

export const initialState: IDemandStore = {
  isFetching: false,
  isAllUploaded: false,
  hasDesync: false,
  lastUpdate: undefined,
  lastParams: {},
  list: [],
  activeTab: DemandTabType.All,
  demandMetrics: {}
};
