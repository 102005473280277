import { IMapTrack, MarkerPlanning } from "@common/types";
import { KEY_SEPARATOR } from "@common/utils/get-track/create-track-key";

export const createMarkerOrderKey = (marker: MarkerPlanning) => marker.type + '#' + marker.data?.ID;

export const createTrackKey = (track: IMapTrack) =>
  'P' + track.clickData?.PlanID + 'V' + track.clickData?.VehicleID + 'T' + track.clickData?.TripID + 'W' + track.clickData?.WaypointID;

export const createLayerKey = ({ layerID, itemID }: { layerID: number | string; itemID?: number | string }): string =>
  String(layerID + KEY_SEPARATOR + itemID);
