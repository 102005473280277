import { Project } from './project';
import { Trailer } from './trailer';
import { Vehicle } from './vehicle';

export type RoadTrain = {
  ID: number;
  VehicleID: Vehicle['ID'];
  Trailer1ID: Trailer['ID'] | null;
  Trailer2ID: Trailer['ID'] | null;
  RoadTrainCode: string | null;
  ProjectID: Project['ID'] | null;
};

export type RoadTrainUploadFileType = {
  [k in RoadTrainTableColumn]: string | number | '';
};

export enum RoadTrainTableColumn {
  RoadTrainCode = 'RoadTrainCode',
  VehicleVehicleCode = 'Vehicle.VehicleCode',
  Trailer1VehicleCode = 'Trailer1.VehicleCode',
  Trailer2VehicleCode = 'Trailer2.VehicleCode',
  ForProject = 'ForProject',
  fileIndex = 'fileIndex',
  key = 'key'
}
