import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { Tender, TenderState } from '@modules/tender-platform/types';
import { tenderActionRoutine, tenderChangeRoutine } from '@modules/tender-platform/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderActionReducer = createReducer(initialState, {
  [tenderChangeRoutine.REQUEST]: onRequest,
  [tenderActionRoutine.FAILURE]: onFailure,
  [tenderActionRoutine.FULFILL]: onFulfill,
  [tenderActionRoutine.SUCCESS]: (state: TenderState, action: PayloadAction<{ tenderAction: TenderAction; tender: Tender | null }>) => {
    const { tenderAction, tender } = action.payload;
    state.currentTender = tender ?? null;
    state.tenderAction = tenderAction;
    if (!tender?.ID) {
      state.documents = {};
    }
    return state;
  }
});
