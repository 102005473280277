import { CellType, TableConfig } from '@common/types';

export const sendConfig: TableConfig = {
  hideClearButtons: true,
  columns: [
    {
      title: 'Дата отправки',
      dataIndex: 'Created',
      type: CellType.Input,
      width: 100
    },
    {
      title: 'Кому',
      dataIndex: 'ContractorName',
      type: CellType.Input,
      width: 120
    },
    {
      title: 'Тип документа',
      dataIndex: 'Type',
      type: CellType.Input,
      align: 'center',
      width: 50
    },
    {
      title: 'Название документа',
      dataIndex: 'Name',
      type: CellType.Input,
      width: 350
    },
    {
      title: 'ID Документа',
      dataIndex: 'DocumentID',
      type: CellType.Input,
      align: 'center',
      width: 70
    },
    {
      title: 'Статус',
      dataIndex: 'Status',
      type: CellType.Input,
      width: 70
    }
  ]
};

