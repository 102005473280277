import { useAppDispatch } from '@core/hooks';
import { documentsIndexRoutine } from '@modules/documents/actions';
import { allDocumentsParams } from '@modules/documents/constants/document-params';
import { FC, useEffect } from 'react';
import ILSDocumentTypeSelect from '../../../components/document-type-select'

const ILSDocumentsAll: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(documentsIndexRoutine(allDocumentsParams));
  }, []);

  return (
    <ILSDocumentTypeSelect />
  );
};

export default ILSDocumentsAll;
