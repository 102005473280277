import './styles.less';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ModulePath } from '@common/types';
import { navigationItems } from '@modules/documents/constants/documents-navigation';
import { useLocation } from 'react-router';
import ILSDocumentsSearch from '../documents-search';

const ILSDocumentsNavigation: FC = () => {
  const { pathname } = useLocation();

  if (pathname === `${ModulePath.Documents}${ModulePath.DocumentsUpload}`) return null;

  return (
    <div className="nav-container">
      <div className={'nav-container-links'}>
        {navigationItems.map((i) => (
          <NavLink to={`${ModulePath.Documents}${i.path}`} key={i.path} activeClassName="active" className="font-16 font-600">
            {i.title}
          </NavLink>
        ))}
      </div>
      <ILSDocumentsSearch />
    </div>
  );
};

export default ILSDocumentsNavigation;
