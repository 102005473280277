import { FC, useState } from 'react';
import { ALWAYS_VISIBLE_SWITCHER, MENU_FOR_SCHEMES_SWITCHER, MenuForSchemes } from '@modules/planning/children/scheme-editor/constants';
import { SchemaEditorMenuList } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/menu-list';
import { ILSTabPane, ILSTabs } from '@common/components';
import { SchemaEditorIntro } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/intro';
import { SchemeEditorTransferContainer } from '@modules/planning/children/scheme-editor/containers/transfer';
import { Dictionary } from '@common/types';
import { SchemaSwitcherEntries, SchemesSwitcher, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { SchemaEditorOptionsContainer } from '@modules/planning/children/scheme-editor/containers/options';
import CustomScroll from 'react-customscroll';

interface IProps {
  displayedTabPanesSchemes: SchemesSwitcher;
}

export const SchemeEditorTabs: FC<IProps> = ({ displayedTabPanesSchemes }) => {
  const [displayedTabPanes, setDisplayedTabPanes] = useState(displayedTabPanesSchemes);
  const [displayedForSchemes, setDisplayedForSchemes] = useState(MENU_FOR_SCHEMES_SWITCHER);
  const schemes = Object.entries(displayedTabPanes) as SchemaSwitcherEntries;
  const menu = Object.entries(displayedForSchemes);

  const onChange = (checked: boolean, event: Event, tab: Dictionary) => {
    setDisplayedTabPanes((displayedTabPanes) => {
      return { ...displayedTabPanes, [tab]: checked };
    });
  };

  const onMenuChange = (checked: boolean, event: Event, tab: MenuForSchemes) => {
    setDisplayedForSchemes((displayedForSchemes) => {
      return { ...displayedForSchemes, [tab]: checked };
    });
  };

  const componentToRender = (scheme: SchemeSwitchDictionary) => {
    switch (true) {
      case scheme === ALWAYS_VISIBLE_SWITCHER:
        return <SchemaEditorIntro onChange={onChange} schemes={schemes} />;
      case scheme === Dictionary.Options:
        return <SchemaEditorOptionsContainer />;
      default:
        return <SchemeEditorTransferContainer displayedForSchemes={displayedForSchemes} dictionary={scheme} />;
    }
  };

  return (
    <ILSTabs
      defaultActiveKey={ALWAYS_VISIBLE_SWITCHER}
      type="card"
      tabBarExtraContent={<SchemaEditorMenuList menu={menu} onChange={onMenuChange} />}
    >
      {schemes.map(([scheme, visibility]) => {
        return (
          visibility && (
            <ILSTabPane className={'scheme-editor-tab'} tab={scheme} key={scheme}>
              <CustomScroll>{componentToRender(scheme)}</CustomScroll>
            </ILSTabPane>
          )
        );
      })}
    </ILSTabs>
  );
};
