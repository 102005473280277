import { isEmpty } from 'lodash';
import { TableColumnName, TableRecord } from '@common/types';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { compareAsString, createArrayFromObject } from '@common/utils';

/**
 * Функция фильтрует варианты для выбора в ячейке select. Удаленные варианты, которые не используются в строке, не включаются в список.
 * Удаленный вариант, который используется в текущей строке, должен присутствовать в списке, но с флагом disabled.
 * Можно не передавать tableColumnName и record, тогда функция просто фильтрует удаленные значения в options;
 * @param options - варианты для выбора в ячейке;
 * @param record - данные строки;
 * @param tableColumnName - название текущей колонки;
 */

export const filterCatalogOptions = <T extends Partial<Record<TableColumnName, any>>>(
  options?: Array<T>,
  record?: TableRecord,
  tableColumnName?: TableColumnName
): Array<T & { disabled: boolean }> => {
  if (isEmpty(options)) return EMPTY_ARRAY_DATA;
  const recordColumnData = getColumnTableRecord(record, tableColumnName);

  const optionsWithoutDeleted =
    options?.reduce((acc: Array<any>, option) => {
      const isDuplicate = recordColumnData?.some((recordElement) => compareAsString(recordElement.ID, option.ID));
      if (!option.Deleted && !isDuplicate) {
        acc.push(option);
      }
      return acc;
    }, [] as Array<any>) ?? EMPTY_ARRAY_DATA;
  if (!isEmpty(recordColumnData) && recordColumnData) {
    return [...recordColumnData, ...optionsWithoutDeleted];
  }
  return optionsWithoutDeleted;
};

export const getColumnTableRecord = (record?: TableRecord, tableColumnName?: TableColumnName): Array<TableRecord> | null => {
  if (!tableColumnName || isEmpty(record?.[tableColumnName])) return null;

  let recordColumnData: Array<TableRecord> | null;

  switch (tableColumnName) {
    case TableColumnName.Restriction:
    case TableColumnName.TripTemplate:
      recordColumnData = record?.[tableColumnName];
      break;
    case TableColumnName.DepotOverload:
      recordColumnData = createArrayFromObject(record?.[tableColumnName]);
      break;
    default:
      return null;
  }

  return recordColumnData?.map((record) => ({ ...record, disabled: Boolean(record?.Deleted) })) ?? null;
};

