import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderOfferUpdateRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { tenderChangeRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint } from '@common/types';
import { TenderOfferAction } from '@modules/tender-platform/children/tender-search/types/offer';

function* tenderOfferUpdateWorker(action: TenderOfferAction) {
  const { request, failure, fulfill } = tenderOfferUpdateRoutine;
  const { tenderID } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderSearch.update, action.payload);

    yield put(tenderChangeRoutine.trigger({ TenderOffer: Object.values(data), operation: DictionariesEndpoint.Update }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(tenderReadRoutine.trigger({ id: tenderID }));
    yield put(fulfill());
  }
}

export function* tenderOfferUpdateWatcher() {
  yield takeEvery(tenderOfferUpdateRoutine.trigger, tenderOfferUpdateWorker);
}
