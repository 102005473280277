import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningStartRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { CheckPlanningResponse, StartPlanningResponse } from '../../../../types/api';
import { PlanningStatus } from '@modules/planning/types';
import { PlanningProgressStatus } from '@modules/planning/children/by-date/constants';
import { onFulfill } from '@common/utils';

export const startPlanningReducer = createReducer(initialState, {
  [planningStartRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [planningStartRoutine.FULFILL]: onFulfill,
  [planningStartRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      plans: StartPlanningResponse['plans'];
    }>
  ) => {
    const { plans } = action.payload;
    state.planningCheckData = Object.keys(plans).reduce((checkData, planID) => {
      checkData[planID] = {
        Status: PlanningStatus.Planning,
        Progress: PlanningProgressStatus.StartPlanning
      };
      return checkData;
    }, {} as CheckPlanningResponse);
    state.status = PlanningStatus.Planning;
    return state;
  },
  [planningStartRoutine.FAILURE]: (
    state,
    action: PayloadAction<{
      error: Array<string>;
      validate: boolean;
    }>
  ) => {
    const { validate } = action.payload;
    state.status = validate ? PlanningStatus.Validate : PlanningStatus.Result;
    return state;
  }
});
