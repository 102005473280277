import { getReportsListRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ReportsListRequest } from '@modules/documents/types/documents-api';

function* worker(action: PayloadAction<ReportsListRequest>) {
  const { success, fulfill, failure, request } = getReportsListRoutine;
  const { module } = action.payload;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Documents.getReportsList, { module });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getReportsListWatcher() {
  yield takeEvery(getReportsListRoutine.trigger, worker);
}
