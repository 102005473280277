import { PLANNING_STATUSES } from '@common/constants/custom';
import { PlanStatus } from '@common/types';
import { DISABLED_STATUSES_FOR_SELECTION, STATUSES_FOR_SELECTION } from '@common/constants/custom/statuses';

export const STATUS_VARIANTS_PROJECT = Object.values(PLANNING_STATUSES).map(({ icon, text, tooltip }, i) => {
  const value = Object.keys(PLANNING_STATUSES)[i];
  return {
    value,
    label: '',
    hide: !STATUSES_FOR_SELECTION.includes(value as PlanStatus),
    disabled: DISABLED_STATUSES_FOR_SELECTION.includes(value as PlanStatus),
    icon,
    text,
    tooltip: tooltip ?? text
  };
});
