import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/children/upload/store';
import { documentUploadRoutine } from '@modules/documents/children/upload/actions';
import { DocumentUploadStore } from '@modules/documents/children/upload/types/store';

export const documentUpload = createReducer(initialState, {
  [documentUploadRoutine.REQUEST]: (state: DocumentUploadStore) => {
    state.isFetching = true;
    return state;
  },
  [documentUploadRoutine.FULFILL]: (state: DocumentUploadStore) => {
    state.isFetching = false;
    return state;
  }
});
