import { ILSTable } from '@common/components';
import { tableUndeliveredConfig, tableUndeliveredDataToTable } from '@common/table-configs/monitoring-dashboard/undelivered';
import { ColumnTable, Dictionary, IndexedArray, RecordTableButton } from '@common/types';
import { MonitoringUndeliveredModel } from '@common/types/dictionaries/monitoring-vehicle';
import { tableCustom } from '@core/containers/table-custom';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import { useGetWaypointTimeZone } from '@modules/monitoring/hooks/use-get-timezone';
import { showAttachment, useModalDoc } from '@modules/monitoring/hooks/use-modal-view-doc';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { FC, useState } from 'react';
import { ILSDashboardViewedButtonComponent } from '../../components/viewed-button';
import { addToTableExelButton } from '../../utils/exel-button';
import { addToTableShowViewedButton, hasUnviewed, tableViewedFilter } from '../../utils/viewed-button';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource: Array<MonitoringUndeliveredModel> | IndexedArray<MonitoringUndeliveredModel>;
  onContextOpen?: (record: MonitoringUndeliveredModel, e: MouseEvent) => void;
  columns: ColumnTable<MonitoringUndeliveredModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  handleUpdateView?: ((id: string | number | string[] | number[], value: 0 | 1) => void) | undefined;
}

// Контейнер таблицы «Не доставлено»
export const MonitoringTableUndelivered: FC<IProps> = ({
  dataSource,
  onContextOpen,
  loadExel,
  recordTableButtons,
  handleUpdateView,
  ...props
}) => {
  const [showAll, setShowAll] = useState(true);
  const { setFocusRow, focusRow } = useFocusRow();
  const { DocViewModalContent, openFile } = useModalDoc();
  const { getWpTimeZone } = useGetWaypointTimeZone();

  const decoratedData = tableViewedFilter(
    tableUndeliveredDataToTable({ dataSource, handleView: handleUpdateView, showAttachment: showAttachment({ openFile }), getWpTimeZone }),
    showAll
  );
  recordTableButtons = addToTableExelButton(recordTableButtons, loadExel, decoratedData, props.columns, Dictionary.Undelivered);
  recordTableButtons = addToTableShowViewedButton(recordTableButtons, showAll, (val: boolean) => showAll !== val && setShowAll(val));

  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        focusRow={focusRow}
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtons}
        config={tableUndeliveredConfig}
        dataSource={decoratedData}
        size={'small'}
        scroll={{ y: 'calc(75vh - 430px)' }}
        pagination={false}
        className="editable-table"
        bordered
        dictionary={Dictionary.Undelivered}
        onContextOpen={onContextOpen}
      />
      <ILSDashboardViewedButtonComponent
        key={Dictionary.Undelivered + 'button'}
        disabled={!hasUnviewed(decoratedData)}
        onClick={() => {
          handleUpdateView?.(
            tableViewedFilter(decoratedData, false).map((item) => item.ID.toString()),
            1
          );
        }}
      />
      {DocViewModalContent}
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringUndelivered = tableCustom(MonitoringTableUndelivered, tableUndeliveredConfig, Dictionary.Undelivered);
