import { ILSTable } from '@components/index';
import { tableCustom } from '@core/containers/table-custom';
import { tenderSearchConfig } from '@modules/tender-platform/utils/table-config/tender-list';
import { TableName } from '@common/types/components';
import { getTenderSearchTableColumns } from '@modules/tender-platform/children/tender-search/helpers/get-tender-search-table-columns';

const TENDERS_TABLE_SCROLL = { x: 300, y: 500 };

export const ILSTendersTableComponent = tableCustom(
  ({ onCanEdit, currentTab, ...props }) => {
    const columns = getTenderSearchTableColumns({ columns: props.columns, currentTab });
    return (
      <ILSTable
        columns={columns}
        scroll={TENDERS_TABLE_SCROLL}
        onCanEdit={onCanEdit}
        className="tender-table"
        {...props}
        focusRow={{ key: props.dataSource?.[0]?.key }}
      />
    );
  },
  tenderSearchConfig,
  TableName.TenderListCarrier
);
