import { createSelector } from 'reselect';
import { tableCustomConfigForILSTableSelector } from './table-custom';
import { ICoreState } from '@core/types/store';
import { TableConfig, TableName } from '@common/types';

export const getColsForGrouping = createSelector(
  (state: ICoreState, tableConfig: TableConfig, tableName?: TableName | string) =>
    tableCustomConfigForILSTableSelector(state, tableConfig, tableName),
  (config: { newConfig: TableConfig }) => {
    return config?.newConfig?.grouping || [];
  }
);

