import { DictionariesEndpoint, Dictionary } from '@common/types';
import { Dispatch } from 'redux';
import { DictionaryCreateRequest, DictionaryOperation } from '@common/types/general/api/dictionary';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';

export const handleCreateRow =
  ({
    dispatch,
    dictionary,
    handleDecorator
  }: {
    dispatch: Dispatch;
    handleDecorator?(row: any, dataIndex?: string | number, value?: any): any;
    dictionary: Dictionary;
  }) =>
  (newRow: any) => {
    const createRow = handleDecorator
      ? Object.entries(newRow).reduce((result, [dataIndex, value]) => {
          result[dataIndex] = handleDecorator(newRow, dataIndex, value);
          return result;
        }, newRow)
      : newRow;
    const payload: DictionaryOperation<DictionaryCreateRequest> & {
      dictionaryFetching?: boolean;
    } = {
      dictionary,
      values: [createRow],
      operation: DictionariesEndpoint.Create
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };
