import L, { FeatureGroup } from 'leaflet';

export type IDrawLocal = {
  draw: {
    toolbar: {
      actions: Action;
      finish: Action;
      undo: Action;
      buttons: Buttons;
    };
    handlers: {
      circle: Handlers;
      circlemarker: Handlers;
      marker: Handlers;
      polygon: Handlers;
      polyline: Handlers;
      rectangle: Handlers;
      simpleshape: Handlers;
    };
  };
  edit: {
    toolbar: {
      actions: {
        save: Action;
        cancel: Action;
        clearAll: Action;
      };
      buttons: Buttons;
    };
    handlers: {
      edit: Handlers;
      remove: Handlers;
    };
    featureGroup?: FeatureGroup;
  };
} & Omit<Partial<L.Control.DrawConstructorOptions>, 'edit'> & {
    edit: Omit<Partial<L.Control.DrawConstructorOptions['edit']>, 'featureGroup'>;
  };

type Action = {
  title: string;
  text: string;
};

type Buttons = {
  polyline?: string;
  polygon?: string;
  rectangle?: string;
  circle?: string;
  marker?: string;
  circlemarker?: string;
  edit?: string;
  editDisabled?: string;
  remove?: string;
  removeDisabled?: string;
};

type Handlers = {
  error?: string;
  tooltip: {
    start?: string;
    end?: string;
    cont?: string;
    text?: string;
    subtext?: string;
  };
  radius?: string;
};

export enum ReloadEnum {
  YES = 'YES',
  NO = 'NO'
}
