import { ILSPopover, ILSSpin, ILSTooltip } from '@components/index';
import { FC } from 'react';
import { MatrixAction, MatrixStatus } from '@modules/planning/children/by-date/types';
import { msToTime } from '@common/utils/helpers/date-time/date-time';
import ILSPlanningMatrixButtons from '@modules/planning/children/by-date/components/result/matrix/matrix-buttons';
import { PlacementType } from '@common/types/components/table/cell';

interface IProps {
  matrix: MatrixStatus | null | undefined;
  isLoading: boolean | undefined;
  handleClick: (action: MatrixAction) => void;
}

export const ILSPlanningMatrixComponent: FC<IProps> = ({ handleClick, isLoading, matrix }) => {
  const now = msToTime(new Date().getTime());
  const message = matrix
    ? matrix.Percent != null
      ? `Расчет матрицы: ${matrix.Count || matrix.MatrixCounter || 0} / ${matrix.Total || matrix.MatrixVariants || 0} (${matrix.Percent}%)${
          matrix.Estimate ? ' Осталось времени: ' + matrix.Estimate : ''
        }`
      : `Матрицы: ${matrix.LastSize ?? ''} ${matrix.LastCalc ?? ''} обновлены ${matrix.LastUpdated ?? now}`
    : 'Нет данных о матрицах';

  return (
    <ILSPopover
      trigger={'click'}
      placement={PlacementType.bottomLeft}
      content={<ILSPlanningMatrixButtons hasMatrix={!!matrix?.LastSize} handleClick={handleClick} />}
    >
      <ILSTooltip placement={PlacementType.topLeft} title={'Нажмите для совершения действий с матрицей'}>
        <div className={'leaflet-control-attribution leaflet-control matrix'}>{isLoading ? <ILSSpin /> : message}</div>
      </ILSTooltip>
    </ILSPopover>
  );
};
