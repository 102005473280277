import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsStore } from '../types/store';
import initialState from '../store';
import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const getBoardsCommonReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonGetBoardsRoutine.REQUEST]: (state) => {
      state.Boards.isFetching = true;
      state.Boards.selectedBoard = undefined;
      state.WidgetParams = { From: state.WidgetParams.From, Till: state.WidgetParams.Till };
      return state;
    },
    [actions.analyticsCommonGetBoardsRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        data: IndexedArray<AnalyticsBoardModel>;
      }>
    ) => {
      const { data } = action.payload;
      if (!state.BoardSets.isFetching) state.Boards.data = data;
      return state;
    },
    [actions.analyticsCommonGetBoardsRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsCommonGetBoardsRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
      state.Boards.isFetching = false;
      return state;
    }
  });
