import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningChangeStatusRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanningStatus } from '@modules/planning/types';

export const changePlanningStatusReducer = createReducer(initialState, {
  [planningChangeStatusRoutine.SUCCESS]: (state, action: PayloadAction<{ status: PlanningStatus }>) => {
    const { status } = action.payload;
    state.status = status;
    return state;
  }
});
