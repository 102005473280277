import reduceReducers from 'reduce-reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { accountReducer } from '@modules/current-user/reducers/account';
import { userGetRoleReducer } from '@modules/current-user/reducers/account-roles';
import { checkTokenReducer } from '@modules/current-user/reducers/check-token';
import { loginReducer } from '@modules/current-user/reducers/login';
import { logoutReducer } from '@modules/current-user/reducers/logout';
import { recoveryReducer } from '@modules/current-user/reducers/recovery';
import { recoveryRequestReducer } from '@modules/current-user/reducers/recovery-request';
import { registerReducer } from '@modules/current-user/reducers/register';
import { registerRequestReducer } from '@modules/current-user/reducers/register-request';
import { changeUserInfoReducer } from './change-user-info';

export const authReducer = reduceReducers(
  authInitialState,
  accountReducer,
  userGetRoleReducer,
  checkTokenReducer,
  loginReducer,
  logoutReducer,
  recoveryReducer,
  recoveryRequestReducer,
  registerReducer,
  registerRequestReducer,
  changeUserInfoReducer
);
