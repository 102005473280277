import { CellChildren } from '@common/types/components/table/cell';
import { isValidElement, ReactElement } from 'react';

export const isReactElement = (element: any): element is ReactElement => {
  return element && typeof element === 'object' && element.hasOwnProperty('props') && isValidElement(element);
};

export const transformReactHighlightNodeIntoText = (children: CellChildren): CellChildren => {
  const [extra, node] = children;

  if (isReactElement(node) && node.props.textToHighlight) {
    return [extra, node.props.textToHighlight];
  } else {
    return children;
  }
};
