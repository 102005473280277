import { createContext } from 'react';
import { AnalyticsBoardModel, AnalyticsWidget, BoardDefaults } from '@common/types/dictionaries/analytics';
import { AnalyticsWidgetBoardContextType } from '@modules/analytics/common/types/context/widget-board-context';
import { IndexedArray } from '@common/types';

export const initialWidgetBoardContext: AnalyticsWidgetBoardContextType = {
  hideWidgetsOnError: false,
  deleteBoardWidget(widget: AnalyticsWidget): void {},
  editMode: false,
  handleGetWidgetData(params: { id: string | number; params: any }): void {},
  handleSelectBoard(item: AnalyticsBoardModel, defaults: BoardDefaults): void {},
  handleSetParams(params: IndexedArray<{ name: string; value: string | number | undefined }>): void {},
  stretchable: false,
  widgetParams: {}
};

export const AnalyticsWidgetBoardContext = createContext<AnalyticsWidgetBoardContextType>(initialWidgetBoardContext);
