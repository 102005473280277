import {
  MonitoringVehicleMovementSpeedIcon,
  MonitoringVehicleMovementSpeedTitle,
  TrackerLastInfoModel
} from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringVehicleMovementSpeedType } from '../constants/movement-status';

// Утилита, которая возвращает статус в зависимости от скорости
export const getVehicleMovementStatus = (
  trackerLastInfo: Partial<TrackerLastInfoModel> | undefined | null
): { status: MonitoringVehicleMovementSpeedTitle; icon: MonitoringVehicleMovementSpeedIcon } => {
  if (!trackerLastInfo?.Speed && !trackerLastInfo?.IMEI) {
    return MonitoringVehicleMovementSpeedType.NoData;
  }
  const speed = trackerLastInfo?.Speed;
  const currentSpeed = Math.round(Number(speed));
  switch (true) {
    case currentSpeed >= 0 && currentSpeed <= 5:
      return MonitoringVehicleMovementSpeedType.Stop;
    case currentSpeed > 5 && currentSpeed <= 10:
      return MonitoringVehicleMovementSpeedType.VerySlow;
    case currentSpeed > 10 && currentSpeed <= 30:
      return MonitoringVehicleMovementSpeedType.Slow;
    case currentSpeed > 30 && currentSpeed < 70:
      return MonitoringVehicleMovementSpeedType.Medium;
    case currentSpeed > 70:
      return MonitoringVehicleMovementSpeedType.Hight;
    default:
      return MonitoringVehicleMovementSpeedType.NoData;
  }
};
