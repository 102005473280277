import { createReducer } from '@reduxjs/toolkit';
import { monitoringSetWaypointStatusRoutine } from '@modules/monitoring/actions';
import { initialState } from '@modules/monitoring/store';
import { MonitoringState } from '@modules/monitoring/types/store';

export const monitoringSetWaypointStatusReducer = createReducer<MonitoringState>(initialState, {
  [monitoringSetWaypointStatusRoutine.REQUEST]: (state) => {
    return state;
  },
  [monitoringSetWaypointStatusRoutine.SUCCESS]: (state) => {
    return state;
  },
  [monitoringSetWaypointStatusRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringSetWaypointStatusRoutine.FULFILL]: (state) => {
    return state;
  }
});
