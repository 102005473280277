import { ModulePath, ModuleTitle } from '@common/types';
import './styles.less';
import { defineModule } from '@core/index';
import ILSAnalyticsTenderContainer from './container';
import { getAllModuleSagas } from '@core/sagas';
import * as allSagas from './sagas/index';
import AnalyticsTenderRootReducer from './reducers/index';

export default defineModule({
  title: ModuleTitle.AnalyticsTenderMain,
  path: ModulePath.AnalyticsTenderMain,
  component: ILSAnalyticsTenderContainer,
  sagas: getAllModuleSagas(allSagas),
  reducer: AnalyticsTenderRootReducer,
  persist: false
});
