import { ButtonTypes } from '@common/types/components';

export const BUTTON_TEXT = {
  [ButtonTypes.Cancel]: 'Отмена',
  [ButtonTypes.Save]: 'Сохранить',
  [ButtonTypes.Edit]: 'Редактировать',
  [ButtonTypes.Delete]: 'Удалить',
  [ButtonTypes.Form]: 'Сформировать',
  [ButtonTypes.Confirm]: 'Подтвердить',
};

