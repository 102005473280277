import Frame from 'react-frame-component';
import '../styles.less';
import { FC } from 'react';

type Props = {
  data: {
    [key: string]: any;
  };
};

const ILSAnalyticFrame: FC<Props> = (props) => {
  const { data } = props;
  let string = '';
  Object.keys(data).length &&
    Object.keys(data).forEach((row) => {
      string += row + '\n';
    });

  const content =
    '<!DOCTYPE html>\n' +
    '<html lang="ru">\n' +
    '<head>\n' +
    '    <meta charset="UTF-8">\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
    '    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">\n' +
    '    <link rel="stylesheet" href="/assets/libs/internal-plan-analytic/style.css">\n' +
    '    <link href="favicon.ico" rel="icon" type="image/x-icon" />\n' +
    '    <title>Редактор схемы</title>\n' +
    '</head>\n' +
    '<body>\n' +
    '    <div id="ils-task-log-analize">\n' +
    '    <div class="ils-task-log-body"></div>\n' +
    '    </div>\n' +
    '    <div id="ils-task-log-fulltext">\n' +
    '<pre>' +
    string +
    '</pre>' +
    '    </div>\n' +
    '    <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossorigin="anonymous"></script>\n' +
    '    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>\n' +
    '    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>\n' +
    '    <script src="/assets/libs/internal-plan-analytic/chart.bundle.js"></script>\n' +
    '    <script src="/assets/libs/internal-plan-analytic/ils-chart.js"></script>\n' +
    '    <script src="/assets/libs/internal-plan-analytic/script.js" type="module"></script>\n' +
    '</body>\n' +
    '</html>';

  return <Frame initialContent={content} children={null} />;
};

export default ILSAnalyticFrame;
