import { ILSButtonTooltip } from '@common/components';
import { MAP_SIZES } from '@core/constants/map';
import { ILSMap } from '@core/containers/map';
import classnames from 'classnames';
import { FC, useRef, useState } from 'react';
import { useTrackMapInit } from '../hooks/map/init';
import { useShowPolyline } from '../hooks/map/show-polyline';
import { Track } from '../types';

interface IProps {
  polyline: Track[];
}

export const TrackMapContainer: FC<IProps> = ({ polyline }) => {
  const [rulerIsActive, setRulerIsActive] = useState(false);
  const Map = useRef<ILSMap | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  //инициализация карты
  const { mapLayersGroup } = useTrackMapInit({ Map });
  //отображение треков
  useShowPolyline({ Map, trackPolylines: polyline, mapLayersGroup });

  const triggerRuler = () => {
    if (Map.current) {
      Map.current.toggleRuler();
      setRulerIsActive(Map.current.rulerIsActive());
    }
  };

  return (
    <div className={classnames('map-container')} ref={mapRef} style={{ position: 'relative' }}>
      <div id="ils-map" style={MAP_SIZES} />
      <div className={'map-buttons'}>
        <ILSButtonTooltip
          className={classnames({ active: rulerIsActive })}
          icon={'/map/ruler'}
          onClick={triggerRuler}
          title={'Включить линейку'}
          placement={'right'}
        />
      </div>
    </div>
  );
};

