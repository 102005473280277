import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { updateLastInfoRoutine } from '../actions';
import { UpdateLastInfoDashboardRequest } from '../types/api';

// Получает последние данные с трекера автомобиля
function* monitoringUpdateLastInfoWorker(action: PayloadAction<UpdateLastInfoDashboardRequest>) {
  const { request, success, failure, fulfill } = updateLastInfoRoutine;
  const { vehicleID, from, till, updates } = action.payload;
  try {
    yield put(request({ vehicleID, from, till, updates }));

    const { data } = yield call(MonitoringAPI.getTrackerLastInfoDetails, { vehicleID, from, till, updates });

    yield put(success({ data: data.data, from, till, updates, vehicleID }));
  } catch (error) {
    yield put(failure({ error, from, till, updates }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringUpdateLastWatcher() {
  yield takeLatest(updateLastInfoRoutine.trigger, monitoringUpdateLastInfoWorker);
}
