import { plansPointsToGetTrack } from '@common/utils/get-track/planPoint2getTrack';
import { createSelector } from 'reselect';
import { PlanningByDateState } from '../types/store';
import { PointType } from '@common/utils/get-track/types';

export const planningGetPoints = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.planPoints,
  (planPoints) => planPoints
);
export const planningGetTrackData = createSelector(planningGetPoints, (planPoints) => planPoints.trackData);
export const planningGetTrackDataIsFetching = createSelector(planningGetPoints, (planPoints) => planPoints.isFetching);

export const planningGetQueryPoints = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.planPoints.planDataQuery,
  (state: PlanningByDateState) => state.PlanningByDate.planPoints.trackData,
  (planDataQuery, trackData) => {
    return plansPointsToGetTrack(planDataQuery, trackData);
  }
);

export const planningGetTrackDataByPoint = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.planPoints.trackData,
  (_state: PlanningByDateState, point: PointType) => point,
  (trackData, point) =>
    Object.values(trackData).find(
      (track) =>
        track.ForbiddenZones === point.forbiddenZones &&
        track.From.Latitude === point.from.latitude &&
        track.From.Longitude === point.from.longitude &&
        track.To.Latitude === point.to.latitude &&
        track.To.Longitude === point.to.longitude
    )
);

export const tripLinkSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate?.tripLink,
  (tripLink) => tripLink
);
