import { PayloadAction } from '@reduxjs/toolkit';
import { getCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { Account } from '@common/types';

function* getCarriersWorker(
  action: PayloadAction<{
    ids?: Array<Account['ID']>;
  }>
) {
  const { request, success, failure, fulfill } = getCarriersRoutine;
  const { ids } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.getCarriers, { ids });
    yield put(success({ carriers: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getCarriersWatcher() {
  yield takeLatest(getCarriersRoutine.trigger, getCarriersWorker);
}
