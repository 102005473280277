import { ILSCheckbox, ILSInputNumber, ILSTextArea } from '@common/components';
import { VEHICLE_PROFILE_TYPE_OPTIONS } from '@components/common/forms/vehicle/constants/options';
import { JoinChar } from '@common/constants';
import { ProjectLogisticsFormFieldData } from '@modules/orders-and-trips/types/project-logistics';

export const PROJECT_LOGISTIC = 'project-logistic';

export const PROJECT_LOGISTIC_FORM_FIELDS: ProjectLogisticsFormFieldData[] = [
  {
    label: 'Города оказания услуги',
    Component: ILSTextArea
  },
  {
    label: 'Рейсов в месяц',
    info: 'Сколько рейсов планируется в месяц',
    Component: ILSInputNumber
  },
  {
    label: 'Когда планируется запустить',
    Component: ILSTextArea
  },
  {
    label: 'Что возим',
    Component: ILSTextArea
  },
  {
    label: 'Тип перевозки',
    info: 'Развоз по Физическим/Юридическим лицам; 1 точка выгрузки; иное)',
    Component: ILSTextArea
  },
  {
    label: 'Кому доставляем',
    info: 'По Физическим/Юридическим лицам; иное)',
    Component: ILSTextArea
  },
  { label: 'Кол-во паллет', Component: ILSInputNumber },
  {
    label: 'Грузоподъемность',
    Component: ILSTextArea,
    info: VEHICLE_PROFILE_TYPE_OPTIONS.map(({ label }) => label).join(`${JoinChar.Semicolon}\n`)
  },
  {
    label: 'Тип транспорта',
    Component: ILSTextArea
  },
  {
    label: 'Требования к водителям',
    info: 'Мед. книжка, водитель-грузчик, грузчики, тележки, иные требования)',
    Component: ILSTextArea
  },
  {
    label: 'Требования к ТС',
    info: 'Санобработка, мульти-режимные, иные требования)',
    Component: ILSTextArea
  },
  {
    label: 'Нужен ли возврат документов и когда',
    Component: ILSTextArea
  },
  {
    label: 'Сколько нужно машин для старта',
    Component: ILSInputNumber
  },
  {
    label: 'Во сколько осуществляется погрузка',
    Component: ILSTextArea
  },
  {
    label: 'Адрес/адреса складов погрузки',
    info: 'Реестром или примерное количество/расположение',
    Component: ILSTextArea
  },
  {
    label: 'Адрес/адреса складов разгрузки',
    info: 'Реестром или примерное количество/расположение',
    Component: ILSTextArea
  },
  {
    label: 'Кто грузит? Каким образом осуществляется погрузка?',
    info: 'Навалом, на паллеты, в коробах',
    Component: ILSTextArea
  },
  {
    label: 'Кто разгружает',
    info: 'Склад; водитель; грузчики; иное',
    Component: ILSTextArea
  },
  {
    label: 'Средний пробег на маршруте',
    Component: ILSInputNumber
  },
  {
    label: 'Среднее время маршрута',
    info: 'В часах',
    Component: ILSInputNumber
  },
  {
    label: 'Среднее количество точек на маршруте',
    Component: ILSInputNumber
  },
  {
    label: 'Тарификация',
    info: 'Фиксированная ставка за маршрут, почасовая, за точку, за километр пробега, за коробку/паллету',
    Component: ILSTextArea
  },
  {
    label: 'Готовы ли заранее сообщать потребность в транспорте (за х дней)',
    valuePropName: 'checked',
    Component: ILSCheckbox
  },
  {
    label: 'Будут ли готовые маршруты',
    Component: ILSCheckbox,
    valuePropName: 'checked'
  },
  {
    label: 'Нужен ли возврат документов/тары на склад',
    Component: ILSCheckbox,
    valuePropName: 'checked'
  },
  {
    label: 'Возможен ли возврат в другой день',
    Component: ILSCheckbox,
    valuePropName: 'checked'
  }
];
