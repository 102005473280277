import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { roadGraphCatalogReadRoutine } from '../actions';
import { RoadGraphTest } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';

function* catalogReadWorker(
  action: PayloadAction<{
    dictionary: Dictionary;
    dictionaryFetching: boolean;
    keys: Array<string | number>;
  }>
) {
  const { request, success, failure, fulfill } = roadGraphCatalogReadRoutine;
  const { dictionary, keys, dictionaryFetching } = action.payload;

  try {
    yield put(
      request({
        dictionary,
        dictionaryFetching,
        keys
      })
    );
    yield delay(10);

    const { data } = yield call(RoadGraphTest.catalogRead, { dictionary, keys });

    yield put(
      success({
        data: data.data,
        dictionary,
        keys,
        lastUpdate: Date.now()
      })
    );
  } catch (error) {
    yield put(failure({ error, dictionary, lastUpdate: undefined }));
  } finally {
    yield delay(2000);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogReadWatcher() {
  yield takeLatest(roadGraphCatalogReadRoutine.trigger, catalogReadWorker);
}
