import { UserAuth } from '@common/types';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { authInitialState } from '../store/auth';
import { changeUserInfoRoutine } from '../actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';

export const changeUserInfoReducer = createReducer(authInitialState, {
  [changeUserInfoRoutine.REQUEST]: onRequest,
  [changeUserInfoRoutine.FAILURE]: onFailure,
  [changeUserInfoRoutine.FULFILL]: onFulfill,
  [changeUserInfoRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      user: UserAuth;
    }>
  ) => {
    const { user } = action.payload;
    state.user.Name = user.Name;
    return state;
  }
});
