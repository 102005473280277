import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unresolvedOrderIsEmptySelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { ReactDispatch } from '@common/types';

type UseCloseAfterDraggingType = (arg: { handleShowOrderTable: (val: boolean) => void }) => {
  setIsAfterDragging: ReactDispatch<boolean>;
};
/**
 * Хук для закрытия таблицы заявок после перемещения
 */
export const useCloseAfterDragging: UseCloseAfterDraggingType = ({ handleShowOrderTable }) => {
  const [isAfterDragging, setIsAfterDragging] = useState(false);
  const isEmptyUnresolved = useSelector(unresolvedOrderIsEmptySelector);
  useEffect(() => {
    if (isAfterDragging && isEmptyUnresolved) {
      handleShowOrderTable(false);
    }
    setIsAfterDragging(false);
  }, [isEmptyUnresolved]);
  return { setIsAfterDragging };
};
