import { cell2dataType } from '@common/table-configs/types/decorator';
import { TIME_COLUMNS_TO_DOBLE } from '@components/data-display/table/constants';
import { TableColumnName } from '@common/types';
import { timeToDouble } from '@common/utils';

export const cell2data: cell2dataType = (dataIndex, value) => {
  switch (true) {
    case TIME_COLUMNS_TO_DOBLE.includes(dataIndex as TableColumnName):
      return timeToDouble(value);
    case dataIndex === TableColumnName.DepotID:
      return value?.[1] ?? null;
    default:
      return value;
  }
};
