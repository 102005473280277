import { FC } from 'react';
import { UsergroupAddOutlined } from '@ant-design/icons/lib/icons';
import { ILSButtonSwitch } from '@components/data-entry/switch/button-switch';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { handleSwitchersRoutine } from '@modules/planning/children/by-date/actions';
import { Dictionary, TableColumnName } from '@common/types';
import { orderTableTargetSwitchersSelector } from '@modules/planning/children/by-date/selectors/components/tables/map-tables';

export const GroupByTargetButton: FC = () => {
  const dispatch = useDispatch();
  const isOn = useSelector(orderTableTargetSwitchersSelector);

  const handleGroupByTarget = (isOn: boolean) => {
    dispatch(handleSwitchersRoutine({ dictionary: Dictionary.Order, dataIndex: TableColumnName.TargetID, isOn }));
  };

  return (
    <ILSButtonSwitch
      checked={isOn}
      onChange={handleGroupByTarget}
      unCheckedIcon={
        <UsergroupAddOutlined
          //TODO эту логику можно спустить ниже
          className={cn('ils-svg', {
            primary: !isOn,
            white: isOn
          })}
        />
      }
      tooltipTitle={'Сгруппировать по клиентам'}
    />
  );
};
