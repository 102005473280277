import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { WritableDraft } from 'immer/dist/internal';
import { updateViewedTableFuelData } from './update-view-data';

// Функция для обновления просмотренных событий в таблице Подозрения на слив топлива
export const updateFuelPointView = (
  fuelDrainData: WritableDraft<MonitoringBDDAndFuelDrainModel>[] | IndexedArray<WritableDraft<MonitoringBDDAndFuelDrainModel>>,
  DependentKey: Array<string> | string,
  Value: 0 | 1
) => {
  if (!fuelDrainData && !Object.values(fuelDrainData).length) return;

  for (const vehicleIndex in fuelDrainData) {
    const vehicle = fuelDrainData[vehicleIndex];

    if (!vehicle?.VehicleTracker || !Object.keys(vehicle.VehicleTracker || {})?.length) {
      continue;
    }

    for (const trackerIndex in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerIndex];

      if (!tracker.FuelKeyPoints || !Object.keys(tracker.FuelKeyPoints)?.length) {
        continue;
      }
      tracker.FuelKeyPoints = updateViewedTableFuelData(tracker.FuelKeyPoints, DependentKey, Value);
    }
  }
};
