import { ILSMenuItem } from '@components/index';
import { Link } from 'react-router-dom';
import { RenderItemProps } from '../../types/context-menu';
import { DashboardContextMenuSubItem } from './сontext-menu-sub-item';

// Компонент пункта контекстного меню
//NOTE RenderFunction - не использовать как компонент
export const DashboardContextMenuItem = ({ item, handleClick, record, index }: RenderItemProps) => {
  if (item) {
    let text: string | JSX.Element = item.text;
    if (item.link) {
      text = (
        <Link key={'menu' + 'item' + index} to={item.link} target="_blank">
          {item.text}
        </Link>
      );
    }

    return item.submenu ? (
      DashboardContextMenuSubItem({ item, index, handleClick, record })
    ) : (
      <ILSMenuItem onClick={() => handleClick(record, item)} key={'menu' + 'item' + index} className={item.inactive ? 'inactive' : ''}>
        {text}
      </ILSMenuItem>
    );
  } else {
    return null;
  }
};

