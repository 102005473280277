import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { initialState } from '@modules/settings/store';
import { settingsSaveRoutine } from '@modules/settings/actions';
import { ChangeSettingsPayload } from '@modules/settings/types/reducers';
import { getValuesFromSettingsObjectBySection } from '@modules/settings/utils/get-values-from-settings-object';

export const saveSettingsReducer = createReducer(initialState, {
  [settingsSaveRoutine.REQUEST]: onRequest,
  [settingsSaveRoutine.SUCCESS]: (state: typeof initialState, action: ChangeSettingsPayload) => {
    const { value, rehydrate } = action.payload;
    state.data = value;
    state.values = getValuesFromSettingsObjectBySection(value);
    if (rehydrate) {
      state.diffSettings = null;
    }
    return state;
  },
  [settingsSaveRoutine.FAILURE]: onFailure,
  [settingsSaveRoutine.FULFILL]: onFulfill
});
