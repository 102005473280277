import { ScaleConfig } from 'bizcharts';

export enum dataTypeVariants {
  Speed = 'Скорость',
  Temperature1 = 'Температура 1',
  Temperature2 = 'Температура 2',
  Fuel = 'Топливо',
  OpenDoor = 'Открытие двери',
  default = 'По умолчанию'
}

export type MonitoringGraphDataItem = {
  x: string; // '2022-10-24 00:00:00'
  y: number; // Значение параметра
  dataType: dataTypeVariants | string; // Тип значения
};

export type MonitoringGraphFuelDrainData = Array<MonitoringGraphDataItem>;

export interface IChartAxisScale extends ScaleConfig {
  type?: 'linear' | 'linear-strict' | 'log' | 'pow' | 'time' | 'quantize' | 'quantile' | 'cat' | 'timeCat' | 'identity';
}

export type LineShapeType = 'line' | 'smooth' | 'dot' | 'dash' | 'hv' | 'vh' | 'hvh' | 'vhv' | undefined;
