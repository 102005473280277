import { ILSGraphTable } from '@modules/monitoring/components/graph/monitoring-graph-table';
import { IChartAxisScale, MonitoringGraphFuelDrainData } from '@modules/monitoring/types/graph';
import { FC, memo } from 'react';

interface IProps {
  title: string;
  useCurrentTime: boolean;
  graphData: MonitoringGraphFuelDrainData;
  chartScale: {
    x: IChartAxisScale;
    y: {};
  };
}

// Компонент графика датчиков

//@todo сама диаграмма будет общим компонентом,
// а здесь будут передаваться данные для ее отображения и настройки
export const ILSMonitoringDashboardGraphComponent: FC<IProps> = memo(({ title, graphData, useCurrentTime, chartScale }) => {
  return <ILSGraphTable graphData={graphData} useCurrentTime={useCurrentTime} chartScale={chartScale} />;
});

