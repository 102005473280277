import { call, put, takeLatest } from 'redux-saga/effects';
import { activeRecordRoutine, getTripsRoutine } from '@modules/orders-and-trips/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { ActiveRecord, PaginationType, RecordType } from '@modules/orders-and-trips/types/general';
import { INIT_PAGINATION_PARAMS } from '@modules/orders-and-trips/constants/general';

function* getTripsWorker(
  action: PayloadAction<{
    source: TripStatus;
    params?: PaginationType;
    activeTrip?: ActiveRecord;
  }>
) {
  const { request, success, failure, fulfill } = getTripsRoutine;
  const { source, params, activeTrip } = action.payload ?? {};
  const { page, perPage } = params ?? INIT_PAGINATION_PARAMS;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripsData, { source, page, perPage });
    yield put(success({ data, source }));

    if (activeTrip) {
      yield put(activeRecordRoutine.success({ recordType: RecordType.Trip, value: activeTrip }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripsWatcher() {
  yield takeLatest(getTripsRoutine.trigger, getTripsWorker);
}

