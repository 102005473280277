import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { initialState } from '@modules/settings/store';
import { tableConfigSaveSettingsRoutine } from '@modules/settings/actions';

export const saveTableConfigReducer = createReducer(initialState, {
  [tableConfigSaveSettingsRoutine.REQUEST]: onRequest,
  [tableConfigSaveSettingsRoutine.SUCCESS]: (state: typeof initialState) => {
    return state;
  },
  [tableConfigSaveSettingsRoutine.FAILURE]: onFailure,
  [tableConfigSaveSettingsRoutine.FULFILL]: onFulfill
});
