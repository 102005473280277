import { ProfileTab } from '@modules/account/types/tabs';
import { getObjectKeysWithType } from '@common/types/helpers/conditional';
import { AccountAttachmentSubject, AccountRole, UserRole, UserRoleLabel, UserRoleOption } from '@common/types';
import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';

export enum UserFormItem {
  UserRole = 'UserRole',
  Name = 'Name',
  Login = 'Login',
  Phone = 'Phone'
}

export enum AccountFormItemLabel {
  Name = 'Фамилия Имя Отчество',
  Phone = 'Телефон',
  Login = 'Email',
  UserRole = 'Роль пользователя'
}

export enum AccountFormItemMessage {
  Name = 'Пожалуйста, введите ФИО!',
  Phone = 'Пожалуйста, введите номер!',
  Login = 'Пожалуйста, введите email!',
  UserRole = 'Роль пользователя'
}

export const REQUIRED_USER_FIELDS = [UserFormItem.Name, UserFormItem.Phone, UserFormItem.Login];

export const USER_FORM_ITEMS = getObjectKeysWithType<UserFormItem>(UserFormItem);

export enum CompanyFormItem {
  Name = 'Name',
  Phone = 'Phone',
  INN = 'INN',
  City = 'City',
  FactAddress = 'FactAddress',
  YurAddress = 'YurAddress',
  AccountRole = 'AccountRole',
  Bank = 'Bank',
  BIK = 'BIK',
  BankAccount = 'BankAccount',
  CorrespondentAccount = 'CorrespondentAccount',
  AccountVAT = 'AccountVAT',
  AccountCode = 'AccountCode',
  KPP = 'KPP',
  OrganizationTypeID = 'OrganizationTypeID'
}

export enum CompanyFormItemLabel {
  Name = 'Название компании'
}

export enum CompanyFormItemMessage {
  Name = 'Пожалуйста, введите название компании!'
}

export const COMPANY_FORM_ITEMS: Array<CompanyFormItem> = [
  CompanyFormItem.Name,
  CompanyFormItem.OrganizationTypeID,
  CompanyFormItem.INN,
  CompanyFormItem.KPP,
  CompanyFormItem.City,
  CompanyFormItem.FactAddress,
  CompanyFormItem.YurAddress,
  CompanyFormItem.Phone
];

export const COMPANY_FORM_ITEM_LENGTH: Partial<Record<CompanyFormItem, { min: number; max: number }>> = {
  //ИНН юридического лица — последовательность из 10 арабских цифр
  INN: { min: 10, max: 10 },
  //БИК банка Банковский идентификационный код (БИК) – 9-значный уникальный код кредитной организации
  BIK: { min: 9, max: 9 },
  //Расчетный счет всегда состоит из 20 цифр.
  BankAccount: { min: 20, max: 20 },
  // Город ограничить в 15 символов
  City: { min: 2, max: 15 },
  // КПП
  KPP: { min: 9, max: 9 }
};
export const COMPANY_FORM_PAY_DATA_ITEMS: Array<CompanyFormItem> = [
  CompanyFormItem.Bank,
  CompanyFormItem.BIK,
  CompanyFormItem.BankAccount,
  CompanyFormItem.CorrespondentAccount
];

export const REQUIRED_COMPANY_FIELDS: Array<CompanyFormItem> = [
  CompanyFormItem.Name,
  CompanyFormItem.Phone,
  CompanyFormItem.City,
  CompanyFormItem.INN,
  CompanyFormItem.OrganizationTypeID
];

export const USER_ROLE_OPTIONS: Array<UserRoleOption> = [
  {
    value: UserRole.manager,
    label: UserRoleLabel.manager
  },
  //https://jira.intelogis.ru/browse/TMS-6017
  // Убрать водителей и экспедиторов из раздела Аккаунт (их нельзя создать)
  // {
  //   value: Roles.Driver,
  //   label: 'водитель'
  // },
  {
    value: UserRole.administrator,
    label: UserRoleLabel.administrator
  }
];
export const USER_ROLE_LIST = getObjectKeysWithType<UserRole>(UserRole);
export const ACCOUNT_ROLE_LIST = getObjectKeysWithType<AccountRole>(AccountRole);

export const DEFAULT_ACCOUNT_TAB = ProfileTab.user;
export const ACCOUNT_PANES = getObjectKeysWithType<ProfileTab>(ProfileTab);

export const ACCOUNT_FACSIMILE_PERMITTED_FILE = [PermittedUploadFileExtensions.png];

export const ACCOUNT_FACSIMILE = [
  {
    title: 'Печать',
    description: 'Добавить печать',
    permittedFiles: ACCOUNT_FACSIMILE_PERMITTED_FILE,
    subject: AccountAttachmentSubject.Stamp
  },
  {
    title: 'Подпись',
    description: 'Добавить подпись',
    permittedFiles: ACCOUNT_FACSIMILE_PERMITTED_FILE,
    subject: AccountAttachmentSubject.Sign
  }
];

export const DISABLED_ROLES: Array<AccountRole | UserRole> = [AccountRole.carrier_simple, AccountRole.intermediary];

