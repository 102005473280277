import { PayloadAction } from '@reduxjs/toolkit';
import { getDocumentSetsRoutine, getDocumentTemplatesRoutine, updateTemplateRoutine } from '@modules/documents/children/templates/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { TemplateData } from '@modules/documents/children/templates/types/api';

function* updateTemplateWorker(action: PayloadAction<{ templateId: number; template: string; isDeal: boolean }>) {
  const { request, success, failure, fulfill } = updateTemplateRoutine;

  try {
    yield put(request());

    const { data } = yield call(DocumentTemplatesAPI.updateTemplate, action.payload.templateId, action.payload.template);

    const currentTemplate: TemplateData = {
      template: (Object.values(data.data) as any)?.[0]?.Data,
      data: '{}'
    };

    if (action.payload.isDeal) {
      yield put(getDocumentSetsRoutine());
    } else {
      yield put(getDocumentTemplatesRoutine());
    }

    yield put(success(currentTemplate));
  } catch (e) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* updateTemplateWatcher() {
  yield takeEvery(updateTemplateRoutine.trigger, updateTemplateWorker);
}
