export interface DocumentsNavigationItem {
  title: string;
  path: string;
}

export enum DocumentPath {
  All = '/documents/all',
  Archive = '/documents/archive',
  Trash = '/documents/trash',
  Upload = '/documents/upload'
}
