import { useAppSelector } from '@core/hooks';
import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BoardViewComponent } from '../components/board-view';
import { AnalyticsPlanningContext } from '../context';

import { boardsSelector, widgetParamsSelector, widgetsSelector } from '../selectors';
import { IndexedArray } from '@common/types';
import { AbstractBoard, AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { useVisibleWidgets } from '@modules/analytics/common/hooks/use-visible-widgets';

interface Props {
  board?: AbstractBoard;
  boardTitle?: string;
  widgets?: IndexedArray<AnalyticsWidget>;
  forPDF?: boolean;
  from?: string | number;
  till?: string | number;
  setBoardWidgets?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

/**
 * Контейнер выбранной доски
 */

export const BoardViewContainer: FC<Props> = (props) => {
  const { handleEditBoard, handleSetParams, handleGetWidgetData } = useContext(AnalyticsPlanningContext) ?? {};
  const boards = useSelector(boardsSelector);
  const widgets = useAppSelector(widgetsSelector);
  const widgetParams = useAppSelector(widgetParamsSelector);

  const visibleWidgets = useVisibleWidgets(widgets);

  const board = useMemo(() => {
    return boards.selectedBoard ? boards?.data[boards.selectedBoard] : null;
  }, [boards.selectedBoard]);

  return (
    <>
      <BoardViewComponent
        from={props.from}
        till={props.till}
        forPDF={props.forPDF}
        boardTitle={props.boardTitle}
        handleEditBoard={handleEditBoard}
        handleSetParams={handleSetParams}
        handleGetWidgetData={handleGetWidgetData}
        board={props.board ? props.board : board}
        widgets={props.widgets ? props.widgets : visibleWidgets}
        widgetParams={widgetParams}
        savingWidgets={boards.savingWidgets}
        setBoardWidgets={props?.setBoardWidgets}
      />
    </>
  );
};
