import { FC } from 'react';
import { ILSButtonSwitch } from '@components/data-entry/switch/button-switch';
import { useDispatch } from 'react-redux';
import { Dictionary } from '@common/types';
import { useCatalogFilterProjectButton } from '@modules/catalog/hooks/use-catalog-filter-project-button';

interface IProps {
  dictionary: Dictionary;
}

export const CatalogFilterProject: FC<IProps> = ({ dictionary }) => {
  const dispatch = useDispatch();
  const { checked, handleClick, defaultChecked } = useCatalogFilterProjectButton({ dictionary, dispatch });

  return (
    <ILSButtonSwitch
      disabled={!Boolean(defaultChecked)}
      checked={checked}
      onChange={handleClick}
      unCheckedIcon={'project-filter'}
      tooltipTitle={`Фильтр по текущему проекту №${defaultChecked}`}
    />
  );
};
