import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import classNames from 'classnames';
import L, { MarkerCluster } from 'leaflet';

const MARKER_CLASS_NAME = 'cluster-waypoint';

/**
 * Отрисовка иконки кластера
 * @param cluster
 * @returns Иконка кластера
 */
export const clusterIcon = (cluster: MarkerCluster) => {
  const count = cluster.getChildCount();

  return L.divIcon({
    html: `<div><span>${count}</span></div>`,
    className: MARKER_CLASS_NAME,
    iconSize: new L.Point(40, 40)
  });
};

//NOTE иконка класстера с точками маршрута
export const clusterWaypointIcon = (cluster: MarkerCluster) => {
  const childs = cluster.getAllChildMarkers() as Array<
    L.Marker & {
      options: {
        data: { Number: string | undefined; EventStatusType: MonitoringWaypointEventStatusType };
      };
    }
  >;
  let number = '';
  let tooltip: string | undefined = undefined;
  let iconType = MonitoringWaypointEventStatusType.Wait;
  const numbers = sortWaypointNumbers(childs?.map((ch) => ch.options?.data?.Number).filter(Boolean) as string[]);

  if (childs) {
    number = numbers?.[0] || '';
    tooltip = numbers.join(',');
    let firstType = childs?.[0]?.options?.data?.EventStatusType;
    if (firstType && childs.every((mark) => mark.options.data.EventStatusType === firstType)) {
      iconType = firstType;
    }
  }
  if (tooltip !== undefined) {
    cluster.bindTooltip(() => tooltip || '', { direction: 'top' });
  }

  return L.divIcon({
    html: `<div><span>${number}...</span></div>`,
    className: classNames(MARKER_CLASS_NAME, iconType),
    iconSize: new L.Point(40, 40)
  });
};

/**
 * Сортирует по порядку массив чисел с учетом их разделителя
 * @param numbers набор строк с разделителями в одинаковом формате (1.1 или 1.1.1 и тд.)
 * @param separator
 */
export const sortWaypointNumbers = (numbers: Array<string>, separator: string = '.') =>
  numbers?.sort((a, b) => {
    const aNumbers = a?.split(separator);
    const bNumbers = b?.split(separator);

    for (const ind in aNumbers) {
      if (Number(aNumbers[ind]) - Number(bNumbers?.[ind]) > 0) {
        return 1;
      } else if (Number(aNumbers[ind]) - Number(bNumbers?.[ind]) < 0) {
        return -1;
      }
    }
    return 0;
  }) || [];
