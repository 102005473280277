import { FC, useContext, useEffect, useState } from 'react';
import { EventDetailsTab } from '@modules/monitoring/children/event-details/types';
import { tableCustom } from '@core/containers/table-custom';
import { Dictionary } from '@common/types';
import {
  tableEventDetailsPlanFactConfig,
  tableEventDetailsPlanFactTable
} from '@common/table-configs/monitoring-event-details/plan-fact-table';
import { MonitoringEventDetailsStore } from '../../types/store';
import { PointsStatusesListSelector } from '@modules/monitoring/children/event-details/selectors';
import { useAppSelector } from '@core/hooks';
import { MonitoringDetailsContext } from '@modules/monitoring/children/event-details/containers/context';
import { DetailsPlanFactTableComponent } from '../../components/details/details-plan-fact';
import { showAttachment, useModalDoc } from '@modules/monitoring/hooks/use-modal-view-doc';

interface Props {
  waypointId?: number;
  tab: EventDetailsTab;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
}

/**
 * Контейнер для таблицы детализации план/факт
 */

export const DetailsPlanFactTable: FC<Props> = ({ tab, vehicleSensor, tracks, vehicleEvents, ...props }) => {
  const pointsStatusesList = useAppSelector(PointsStatusesListSelector);
  const monitoringContext = useContext(MonitoringDetailsContext);
  const { DocViewModalContent, openFile } = useModalDoc();

  const decoratedData = tableEventDetailsPlanFactTable(
    vehicleEvents?.data,
    tracks.selectedVehicleId,
    pointsStatusesList?.data,
    showAttachment({ openFile })
  );
  const [focusRow, setFocusRow] = useState(props?.waypointId ? { key: String(props.waypointId) } : undefined);

  useEffect(() => {
    if (!focusRow) {
      const focusRecord = decoratedData.find((dd) => props.waypointId === dd.ID);
      if (focusRecord) {
        setFocusRow(focusRecord);
      }
    }
  }, [props.waypointId]);

  const focusOnMapByWaypoint = (val: any) => {
    monitoringContext?.fitMapByWaypointId(val.ID);
  };

  return (
    <DetailsPlanFactTableComponent
      {...props}
      tab={tab}
      vehicleSensor={vehicleSensor}
      tracks={tracks}
      vehicleEvents={vehicleEvents}
      focusRow={focusRow}
      focusOnMapByWaypoint={focusOnMapByWaypoint}
      decoratedData={decoratedData}
      modalContent={DocViewModalContent}
    />
  );
};

export const DetailsPlanFactContainer = tableCustom(DetailsPlanFactTable, tableEventDetailsPlanFactConfig, Dictionary.EventDetailsPlanFact);
