import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getFakeTripContractRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { normalizeFakeTripContractRequest } from '@modules/orders-and-trips/helpers/fake-pre-cost/normalize-fake-trip-contract-request';
import { FakePreCostRequest, FakeTripContractPayloadAction } from '@modules/orders-and-trips/types/fake-pre-cost';
import { fakePreCostValuesSelector } from '@modules/orders-and-trips/selectors/integrations';

function* getFakeTripContractWorker(action: FakeTripContractPayloadAction) {
  const { request, success, failure, fulfill } = getFakeTripContractRoutine;

  try {
    const values: FakePreCostRequest = yield select(fakePreCostValuesSelector);
    yield put(request());
    const requestBody = normalizeFakeTripContractRequest({ payload: action.payload, values });

    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getFakeTripContract, requestBody);
    yield put(success({ data, values: requestBody }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getFakeTripContractWatcher() {
  yield takeLatest(getFakeTripContractRoutine.trigger, getFakeTripContractWorker);
}
