import { ButtonType } from '@modules/catalog/types/tariff';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { SplitChar } from '@common/constants/general';

export const parseFormula = ({ parser, formula }: { parser: Array<ButtonType>; formula: string }) => {
  return parser
    .reduce((prev, button) => {
      const regex = new RegExp(`(${button.name})`, 'g');
      if (prev.match(regex)) {
        return prev.replaceAll(regex, () => button.variable);
      }
      return prev;
    }, formula)
    .replaceAll(JoinChar.Space, EMPTY_STRING)
    .replaceAll(SplitChar.Comma, SplitChar.Dot);
};
