export type ICreateResponse = Record<
  string,
  {
    db: string;
  }
>;

export type IDeleteResponse = {
  result: number;
  error: number;
};

export type IUpdateParams = {
  Context: string;
  Def: string;
  Index: string | null;
  Name: string;
  PlanConfigID: string;
  type: string;
};

export type IImportResponse = {
  result: number;
  error: number;
};

export enum UpdateParamType {
  ChangeConfig = 'changeConfig',
  ChangeParam = 'changeParam'
}

export enum ControlConfigOperation {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}
