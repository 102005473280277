import { ILSSelectCloseOnScroll } from '@components/data-display/table/components/ils-select-scroll-close';
import { IILSCellSelectApiProps } from '@common/types/components/table/cell';
import { BaseOptionType } from 'rc-select/lib/Select';
import { IRecord } from '@modules/catalog/types/catalog';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';
import { TableRecord } from '@common/types';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';

export const ILSCellSelectApi = <R extends TableRecord>({
  children,
  save,
  allowClear = true,
  tableRef,
  placeholder,
  toggleEdit,
  options,
  fieldNames,
  dictionaryInfo,
  dictionary,
  dictionaryLoad,
  showSearch
}: IILSCellSelectApiProps<R>): JSX.Element => {
  const [node, value] = children;
  const loading = dictionaryInfo?.[dictionary]?.isFetching;
  useApiOptions({ dictionary, dictionaryLoad });

  const onChange = (option: BaseOptionType | undefined, record: IRecord) => save(option?.value ?? null, record);

  return (
    <>
      {node}
      <ILSSelectCloseOnScroll
        autoFocus
        open
        labelInValue
        fieldNames={fieldNames}
        filterOption={filterOption(fieldNames)}
        placeholder={placeholder}
        options={options}
        value={value || undefined}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        tableRef={tableRef}
        loading={Boolean(loading)}
        onBlur={toggleEdit}
        className={'cell-wrapper-editable-full'}
      />
    </>
  );
};
