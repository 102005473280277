import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { ILSCol, ILSMenu, ILSRow, ILSSkeleton } from '@components/index';
import { ILSCatalogFormulaViewComponent } from './item';
import CustomScroll from 'react-customscroll';
import { FilterInput, listOnFilter } from '@common/utils/general/list-filter';
import { Dictionary, Key } from '@common/types';
import { isEmpty } from 'lodash';
import { ILSTariffConstructor } from '@modules/catalog/components/tariff/constructor';
import { compareAsString } from '@common/utils';

type ItemType = any;

export interface IILSCatalogTariffsList {
  items: Array<ItemType>;
  activeItem: any;
  dictionary: Dictionary;
  isFetching: boolean;
  checking?: boolean | 1 | 0;

  changeActiveItem(dictionary: Dictionary, item: any): void;

  handleSave(dictionary: Dictionary, name: string, formula: any, id: Key): void;

  handleDelete(dictionary: Dictionary, id: Key): void;
}

export const mapMenu = ({ items, activeItem, filterState }: { items: Array<ItemType>; activeItem: { ID: Key }; filterState: string }) => {
  return items.map(({ ID, Name }) => {
    return {
      label: listOnFilter(Name, filterState),
      key: ID,
      icon: (
        <ReactSVG
          className="svg-container"
          src={'/assets/icons/' + (activeItem && activeItem.ID === ID ? 'left' : 'right') + '-arrow.svg'}
        />
      )
    };
  });
};

export const ILSCatalogTariffsList: FC<IILSCatalogTariffsList> = ({
  items,
  activeItem,
  changeActiveItem,
  dictionary,
  isFetching,
  handleDelete,
  handleSave,
  checking
}) => {
  const getTariffData = ({ key, items }: { key: Key; items: Array<ItemType> }) => {
    setEdit(false);
    changeActiveItem(
      dictionary,
      items.find(({ ID }) => compareAsString(ID, key))
    );
  };
  const [edit, setEdit] = useState(false);

  const [filterState, setFilterState] = useState(''); // фильтрация списка тарифов

  return (
    <ILSSkeleton loading={isFetching} active paragraph={{ rows: 9 }}>
      <ILSRow className="ils-content-menu pt-2" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <ILSCol span={6} className="gutter-row">
          <FilterInput searchField="тарифа" state={filterState} setState={setFilterState} />
          <CustomScroll>
            <ILSMenu
              onClick={({ key }) => getTariffData({ key, items })}
              items={mapMenu({ items, activeItem, filterState })}
              defaultSelectedKeys={[activeItem?.ID]}
              mode="inline"
              selectedKeys={[activeItem?.ID]}
            />
          </CustomScroll>
        </ILSCol>
        <ILSCol span={18} className="pl-4 gutter-row">
          <CustomScroll>
            {edit ? (
              <ILSTariffConstructor
                checking={checking}
                handleSave={handleSave}
                edit={edit}
                setEdit={setEdit}
                tariff={activeItem}
                dictionary={dictionary}
              />
            ) : (
              !isEmpty(items) && (
                <ILSCatalogFormulaViewComponent
                  activeItem={activeItem}
                  handleDelete={handleDelete}
                  dictionary={dictionary}
                  checking={checking}
                  setEdit={setEdit}
                />
              )
            )}
          </CustomScroll>
        </ILSCol>
      </ILSRow>
    </ILSSkeleton>
  );
};
