import { FC } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { TenderPlatformPaths } from '@modules/tender-platform/types';
import { TenderOwnMyCarriers } from '@modules/tender-platform/children/tender-own/containers/carriers';
import { searchCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';

export const ILSTenderCarriersCard: FC = () => {
  const dispatch = useDispatch();
  const {
    push,
    location: { pathname, search }
  } = useHistory();
  const showForm = search === TenderPlatformPaths.Add;

  const handleClick = (path: TenderPlatformPaths) => {
    switch (path) {
      case TenderPlatformPaths.Add:
        dispatch(searchCarriersRoutine({ query: '' }));
        break;
      default:
        break;
    }
    push(`${pathname}${path}`);
  };

  return <TenderOwnMyCarriers showForm={showForm} handleClick={handleClick} />;
};
