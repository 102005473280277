import { IndexedArray, User } from '@common/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { TenderLotFields } from '@modules/tender-platform/types';

const getTenderLotUser = (userData: [string] | string, users?: IndexedArray<User>) => {
  return Array.isArray(userData)
    ? userData.reduce((summaryLotUsers, userID) => {
        if (users?.[userID]) {
          summaryLotUsers.push(users[userID]);
        }
        return summaryLotUsers;
      }, [] as User[])
    : users?.[userData];
};

export const getLotFormData = (values: Record<TenderFormField, any>, users?: IndexedArray<User>) => {
  return (
    (values &&
      Object.entries(values).reduce((onlyLotData, [key, property]) => {
        if (key in TenderLotFields) {
          switch (key) {
            case TenderLotFields.TenderLotUser:
              onlyLotData[key] = getTenderLotUser(property, users);
              break;
            default:
              onlyLotData[key] = property;
          }
        }
        return onlyLotData;
      }, {} as Record<TenderFormField, any>)) ||
    null
  );
};
