import { tenderGetVehicleTypesRoutine } from '@modules/tender-platform/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';

function* tenderGetVehicleTypesWorker() {
  const { request, success, failure, fulfill } = tenderGetVehicleTypesRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderAPI.getVehicleTypes);
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderGetVehicleTypesWatcher() {
  yield takeLatest(tenderGetVehicleTypesRoutine.trigger, tenderGetVehicleTypesWorker);
}
