import classNames from 'classnames';
import { IViewedCell } from '../common/types';
import { ID } from '@common/types';

type ViewedContentFunction = <T>(
  viewed: 1 | 0,
  handleView?: (ids: Array<ID>, viewed: 0 | 1) => void,
  param? : keyof T | string
) => IViewedCell

export const viewedContent: ViewedContentFunction = (viewed, handleView, param = 'ID') => ({
  icon: viewed ? 'eye-close' : 'eye-open',
  className: classNames('cursor-pointer', viewed ? 'icon-viewed' : 'icon-unviewed'),
  title: viewed ? 'Отметить как не просмотрено' : 'Отметить как просмотрено',
  value: viewed,
  onClick: (record: any) => {
    handleView?.(record[param], viewed ? 0 : 1);
  } 
});
