import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify, NotifyDurationInSecond } from '@common/types';
import { EMPTY_STRING } from '@common/constants';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { vehicleMonitoringNotifyRoutine } from '@modules/vehicle-monitoring/actions';
import { VehicleMonitoringAction } from '../types/actions';

export const DEFAULT_VEHICLE_MONITORING_ACTION_STATUSES: {
  [key in VehicleMonitoringAction]: {
    loading: string;
    success: string;
    error?: string;
  };
} = {
  [VehicleMonitoringAction.INDEX]: {
    loading: '',
    success: '',
    error: 'Ошибка загрузки рейса для отслеживания'
  }
};

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: VehicleMonitoringAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || DEFAULT_VEHICLE_MONITORING_ACTION_STATUSES[VehicleMonitoringAction[action]]?.[type] || EMPTY_STRING;
};

const getActionFromRoutine = (actionName: string): VehicleMonitoringAction => actionName as VehicleMonitoringAction;

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, VehicleMonitoringAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'VehicleMonitoring',
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

const errorWorker = notifierWorkerCreator<ErrorAPI, VehicleMonitoringAction>({
  getActionFromRoutine,
  getKeyPrefix: () => 'VehicleMonitoring',
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* vehicleMonitoringNotifierWatcher() {
  for (let routine of vehicleMonitoringNotifyRoutine) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
