import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';
import { TENDER_PLATFORM_MY_CARRIERS } from '@modules/tender-platform/children/tender-own/constants/content';

interface IProps {
  onClick(): void;
}

export const ILSMyCarriersButton: FC<IProps> = ({ onClick }) => (
  <ILSButtonTooltip
    placement="bottom"
    title={TENDER_PLATFORM_MY_CARRIERS}
    onClick={onClick}
    className="my-carriers"
    icon={'my-carriers'}
    data-testid="my-carriers-button"
  />
);
