import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DictionariesEndpoint, Dictionary, IILSTableProps, TableRecord, VehicleTableColumn } from '@common/types';
import { DictionaryOperation, DictionaryUpdateRequest } from '@common/types/general/api/dictionary';
import { VEHICLE_TABLE_CONFIG_PLANNER } from '@common/models/vehicle/constants';
import { tableCustom } from '@core/containers/table-custom';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { ILSTable } from '@common/components';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { tableData2Vehicles } from '@common/models/vehicle/decorators/table-data-2-vehicles';
import { vehiclesRefDataSelector, vehiclesValidationDataSourceSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { canEditVehicleInValidation } from '@modules/planning/children/by-date/helpers/tables';

const ILSPlanningVehicleTable: FC<{ failFieldName: any; failKeys: any; showAll: boolean }> = ({
  failFieldName,
  failKeys,
  showAll,
  ...props
}) => {
  const dispatch = useDispatch();
  const { catalogLoad } = useCatalog();
  const { vehiclesValidationDataSource } = useSelector(vehiclesValidationDataSourceSelector);
  const { refData } = useSelector(vehiclesRefDataSelector);

  useEffect(() => {
    dispatch(
      dictionaryPlannerRoutine({
        dictionary: Dictionary.Vehicle,
        dictionaryFetching: true
      })
    );
  }, []);

  const dataSource = useMemo(() => {
    return showAll
      ? vehiclesValidationDataSource
      : vehiclesValidationDataSource.filter((row) => {
          return failFieldName && row[failFieldName] && failKeys?.includes(row[failFieldName]?.toString());
        });
  }, [showAll, vehiclesValidationDataSource]);

  useEffect(() => {
    catalogLoad(Dictionary.VehicleType);
  }, []);

  const handleSave: IILSTableProps<TableRecord>['handleSave'] = (record, dataIndex, value) => {
    const values = { [dataIndex]: tableData2Vehicles(record as never, dataIndex as VehicleTableColumn, value) };
    if (Object.values(values)[0] === record[Object.keys(values)[0]]) return;
    const { ID } = record;
    const payload: DictionaryOperation<DictionaryUpdateRequest> & {
      dictionaryFetching?: boolean;
      projectID: false;
    } = { ids: [ID], dictionary: Dictionary.Vehicle, operation: DictionariesEndpoint.Update, values, projectID: false };
    dispatch(dictionaryPlannerRoutine(payload));
  };

  return (
    <ILSTable
      {...props}
      dataSource={dataSource}
      config={VEHICLE_TABLE_CONFIG_PLANNER}
      refData={refData}
      handleSave={handleSave}
      onCanEdit={canEditVehicleInValidation}
    />
  );
};

export const ILSPlanningVehicle = tableCustom(ILSPlanningVehicleTable, VEHICLE_TABLE_CONFIG_PLANNER, Dictionary.Vehicle);
