import { DELIVERY_ZONE_CONFIG } from '@common/models/zone/config';
import { CatalogDictionary, Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ILSCatalogTable } from '../../containers/table-container';
import { deliveryZoneTableOptionsSelector } from '@modules/catalog/selectors/options';
import { zone2table } from '@common/models/zone/decorators';

export const ILSCatalogDeliveryZone: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    const { refData } = useSelector(deliveryZoneTableOptionsSelector);

    useEffect(() => {
      setMapProps?.({
        field: 'BorderHash',
        type: 'polygon',
        colorField: 'Color'
      });
    }, []);

    return (
      <ILSCatalogTable
        {...props}
        setMapProps={setMapProps}
        dataDecorator={zone2table}
        dictionary={Dictionary.DeliveryZone}
        refData={refData}
      />
    );
  },
  DELIVERY_ZONE_CONFIG,
  CatalogDictionary.DeliveryZone,
  true
);
