import { setActiveVehicleRoutine } from './../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { MonitoringEventDetailsStore } from '@modules/monitoring/children/event-details/types/store';

// Устанавливает в стейт ID выбранного автомобиля
export const activeVehicleReducer = createReducer<MonitoringEventDetailsStore>({} as MonitoringEventDetailsStore, {
  [setActiveVehicleRoutine.SUCCESS]: (state, action: PayloadAction<{ activeVehicleID: number }>) => {
    const { activeVehicleID } = action.payload;

    state.activeVehicleID = activeVehicleID;

    return state;
  }
});
