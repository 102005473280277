import { loginActions } from '@modules/current-user/actions';
import ILSLoginFormComponent from '../components/login-form';
import { currentUserErrorSelector } from '@modules/current-user/selectors/auth';
import { ILSUserNotify } from '@components/index';
import { FC, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { fetchSelector } from '../selectors/auth';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { LoginProps } from '../types/auth';
import { ModulePath, Notify } from '@common/types';

const ILSLoginForm: FC = () => {
  const isFetching = useAppSelector(fetchSelector);
  const error = useAppSelector(currentUserErrorSelector);
  const dispatch = useAppDispatch();

  const onFinish = (values: LoginProps) => {
    dispatch(loginActions(values));
  };

  useEffect(() => {
    if (!!error?.length) {
      ILSUserNotify(Notify.Error, error);
    }
  }, [error]);

  return (
    <div className="login-form">
      <ILSLoginFormComponent isFetching={isFetching} onFinish={onFinish} />
      <div className="flex-between-center px-3 mb-3">
        <NavLink to={ModulePath.Recovery}>Восстановление пароля</NavLink>
        <NavLink to={ModulePath.Register}>Регистрация</NavLink>
      </div>
    </div>
  );
};

export default ILSLoginForm;
