import '../../../styles.less';
import { InputRef } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import {
  CellType,
  ColumnTable,
  FilterCellType,
  FilterSort,
  IILSTableProps,
  ReferenceTableData,
  TableColumnName,
  TableRecord
} from '@common/types';
import { FilterState } from '@common/types/components/table/common';
import {
  CASCADER_CELL_TYPES,
  DATETIME_FILTERING_CELL_TYPES,
  NOT_FILTERING_CELL_TYPES,
  SELECT_CELL_TYPES
} from '@components/data-display/table/constants/cell';
import { getDefaultColumnSearchProps } from './common-search-props';
import { createVariantsData } from './create-variants';
import { datetimeFilterProps } from './datatime-filter';
import { getGeoFilterProps } from './geo-filter';
import { getListFilterProps } from './list';

export const filter = <R extends TableRecord>(
  filtered: FilterSort['filtered'],
  { dataIndex, filtering = FilterCellType.String, options, title, type, format }: ColumnTable<R>,
  refData: Partial<Record<Partial<TableColumnName>, ReferenceTableData>> | undefined,
  filterState: FilterState,
  setFilterState: Dispatch<SetStateAction<FilterState>>,
  searchInput: InputRef | null,
  dataSource: IILSTableProps<R>['dataSource']
) => {
  const filteredValue = filtered && filtered?.[dataIndex];

  if (NOT_FILTERING_CELL_TYPES.includes(type)) return false;

  if (SELECT_CELL_TYPES.includes(type)) filtering = FilterCellType.Array;

  if (type === CellType.Geo) {
    return getGeoFilterProps({
      column: { dataIndex, title },
      filtered,
      filterState,
      searchInput,
      setFilterState
    });
  }

  if (DATETIME_FILTERING_CELL_TYPES.includes(type)) {
    return datetimeFilterProps({
      column: { dataIndex, filtering, options, title, type, format },
      dataIndex,
      filtered,
      filterState,
      searchInput,
      setFilterState
    });
  }

  const { filterVariants } = createVariantsData({ referenceData: options, dataIndex, refData });

  if (filtering === FilterCellType.Array) {
    const filterType = CASCADER_CELL_TYPES.includes(type) ? FilterCellType.Cascader : filtering;
    return {
      ...getListFilterProps({ dataIndex, type: filterType, defaultVariants: filterVariants, dataSource }),
      filteredValue
    };
  }

  return {
    ...getDefaultColumnSearchProps({ dataIndex, filterState, setFilterState, searchInput, filteredValue, title }),
    filteredValue
  };
};
