import { ILSTable } from '@common/components';
import { NEW_ROW_KEY } from '@common/components/data-display/table/constants';
import { UPDATE_INTERVAL } from '@common/constants/general/date-time';
import { vehicleTrackerTableConfig, VehicleTrackerTableDecorator } from '@common/table-configs/monitoring/vehicle-tracker';
import {
  ColumnTable,
  DateTimeFormat,
  Dictionary,
  IILSTableProps,
  IndexedArray,
  Key,
  RecordTableButton,
  ReferenceTablesData,
  TableRecord,
  VehicleTracker
} from '@common/types';
import { CreateVehicleTrackerRequest } from '@common/types/dictionaries/monitoring';
import { MonitoringOnLateDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { getObjectWithoutNilOrEmptyStringValues } from '@common/utils/helpers/object/get-object-without-nil-or-empty-string-values';
import { isEmptyString } from '@common/utils/helpers/object/is-nil-or-empty-string';
import { tableCustom } from '@core/containers/table-custom';
import { useAppSelector } from '@core/hooks';
import {
  monitoringCreateVehicleTrackerLocalRoutine,
  monitoringCreateVehicleTrackerRoutine,
  monitoringDeleteVehicleTrackerLocalRoutine,
  monitoringDeleteVehicleTrackersRoutine,
  monitoringGetVehicleTrackersRoutine,
  monitoringUpdateVehicleTrackersRoutine
} from '@modules/monitoring/actions';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import {
  monitoringVehicleTrackerRefDataSelector,
  monitoringVehicleTrackersInfoSelector,
  monitoringVehicleTrackersSelector
} from '@modules/monitoring/selectors/trackers';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { isString } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ChildTable, MonitoringChildTableContainer } from '../child-table-container';
import { MonitoringTrackerTable } from '../table-tracker/table-tracker';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

const refTable: any = {
  TrackerIMEI: (record: any) => ({
    title: 'Трекеры',
    component: MonitoringTrackerTable,
    record
  })
};

interface IProps {
  dataSource?: Array<MonitoringOnLateDataModel> | IndexedArray<MonitoringOnLateDataModel>;
  onContextOpen?: (record: any, e: MouseEvent) => void;
  columns?: ColumnTable<TableRecord>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel?: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  handleUpdateView?: ((id: string | number | string[], value: 0 | 1) => void) | undefined;
  record: {
    [key: string]: any;
    VehicleTracker: IndexedArray<VehicleTracker> | null;
    VehicleID: Key | null | undefined;
  };
  vehicles?: any;
  focusRow?: any;
}

const toUTCDateTime = (dateString: string) => {
  return moment(dateString, DateTimeFormat.DDMMYYYYPeriodHHMM).utc(false).format(DateTimeFormat.YYYYMMDDDashHHMMS);
};

//TODO нужно сделать и использовать отдельные хуки с useEffect и useHandler
// Компонент таблицы привязки трекеров к автомобилю
export const MonitoringTableVehicleTracker: FC<IProps> = ({
  recordTableButtons,
  loadExel,
  onContextOpen,
  handleUpdateView,
  record,
  ...props
}) => {
  const { setFocusRow, focusRow } = useFocusRow(props.focusRow);
  const vehicleID = record?.VehicleID;
  const dispatch = useDispatch();

  const vehicleTrackers = useAppSelector(monitoringVehicleTrackersSelector);
  const trackerRefData = useAppSelector(monitoringVehicleTrackerRefDataSelector);
  const { lastUpdate: vehicleTrackersUpdated } = useAppSelector(monitoringVehicleTrackersInfoSelector);
  const needsUpdate = moment().diff(moment(vehicleTrackersUpdated), 'minutes') > +UPDATE_INTERVAL / 1000;

  const decoratedData = VehicleTrackerTableDecorator(vehicleTrackers, vehicleID);

  const refData: ReferenceTablesData = trackerRefData;

  useEffect(() => {
    if (isEmpty(vehicleTrackers) || needsUpdate) handleReload();
    return () => {
      dispatch(monitoringDeleteVehicleTrackerLocalRoutine());
    };
  }, []);

  const handleReload = () => {
    dispatch(monitoringGetVehicleTrackersRoutine());
  };
  const isValidIMEI = (TrackerIMEI: string | number | null | undefined) => {
    return !(isNil(TrackerIMEI) || isNaN(+TrackerIMEI) || TrackerIMEI === '');
  };

  const handleCreate = (values: CreateVehicleTrackerRequest) => {
    if (!isEmptyString(values.TrackerIMEI)) {
      dispatch(
        monitoringCreateVehicleTrackerRoutine({
          From: moment().utc(false).format(DateTimeFormat.YYYYMMDDDashHHMMS),
          VehicleID: vehicleID,
          TrackerIMEI: values.TrackerIMEI
        })
      );
      return;
    }
    if (compareAsString(last(vehicleTrackers)?.TrackerIMEI, '')) return;
    if (isNil(values.TrackerIMEI)) {
      dispatch(
        monitoringCreateVehicleTrackerLocalRoutine({
          trackers: vehicleTrackers,
          VehicleID: vehicleID
        })
      );
    }
  };

  const handleSave: IILSTableProps<TableRecord>['handleSave'] = (record, dataIndex, value) => {
    const params = {
      From: record.From ? toUTCDateTime(record.From) : moment().utc(false).format(DateTimeFormat.YYYYMMDDDashHHMMS),
      Till: record.Till ? toUTCDateTime(record.Till) : undefined,
      VehicleID: record.VehicleID,
      TrackerIMEI: record?.TrackerIMEI
    };
    if (dataIndex === 'TrackerIMEI') {
      params.TrackerIMEI = value;
    }
    if (dataIndex !== 'TrackerIMEI' && !isValidIMEI(record.TrackerIMEI)) {
      dispatch(monitoringCreateVehicleTrackerRoutine(getObjectWithoutNilOrEmptyStringValues(params)));
      return;
    }
    if (dataIndex === 'From' || dataIndex === 'Till') {
      value = value && isString(value) ? toUTCDateTime(value) : value;
    }
    if (isValidIMEI(record.TrackerIMEI)) {
      dispatch(
        monitoringUpdateVehicleTrackersRoutine({
          id: record.ID,
          values: { [dataIndex]: value }
        })
      );
      return;
    }
  };

  const handleSelectedElements = (record: any, keys: any[]) => {
    if (keys.length && keys[0] === NEW_ROW_KEY) {
      setFocusRow({ key: NEW_ROW_KEY });
    }
  };
  const handleDelete: IILSTableProps<TableRecord>['handleDelete'] = (_, record) => {
    const [{ ID }] = record;
    const payload = { IDs: ID };
    dispatch(monitoringDeleteVehicleTrackersRoutine(payload));
  };

  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        focusRow={focusRow}
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtons}
        config={vehicleTrackerTableConfig}
        size={'small'}
        className="monitoring editable-table"
        bordered
        dictionary={Dictionary.VehicleTracker}
        onContextOpen={onContextOpen}
        dataSource={decoratedData}
        refTable={refTable}
        refData={refData}
        handleCreate={handleCreate}
        handleReload={handleReload}
        handleSave={handleSave}
        handleDelete={handleDelete}
        handleSelectedElements={handleSelectedElements}
        cellClassName={(_record, _rowIndex, dataIndex) => {
          return dataIndex === 'TrackerIMEI' ? 'table-tracker-select' : '';
        }}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringVehicleTracker = tableCustom(MonitoringTableVehicleTracker, vehicleTrackerTableConfig, Dictionary.VehicleTracker);

export const MonitoringVehicleTrackerTable: FC<ChildTable> = (props) => {
  return <MonitoringChildTableContainer {...props} renderFunc={ILSMonitoringVehicleTracker} />;
};
