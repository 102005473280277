import { ReactSVG } from 'react-svg';
import { getSRCIconSVG } from '@common/decorators/path';

export const LogisticServicesHeader = () => {
  return (
    <div className="logistic-services__header">
      <h1>
        Добро пожаловать в логистические сервисы
        <ReactSVG className={'logo-image'} src={getSRCIconSVG('logo')} />
      </h1>
    </div>
  );
};

