import moment from 'moment';
import { DateFormat, DateTimeFormat, TimeFormat } from '@common/types';
import { doubleToTime, getValidMoment } from '@common/utils';

//NOTE: moment обрабатывает форматы
// m mm	0..59	Minutes
// s ss	0..59	Seconds
// то есть в случае, когда минут/секунд > 60, их нужно обрабатывать как duration и трансформировать в moment
const SPECIFIC_MOMENT_FORMAT: Array<DateFormat | TimeFormat | DateTimeFormat> = [TimeFormat.mm, TimeFormat.SS];

export const getTimeForCell = (
  timeString: string | number,
  format: DateFormat | TimeFormat | DateTimeFormat,
  dateParseFormat: DateFormat | TimeFormat | DateTimeFormat
) => {
  if (SPECIFIC_MOMENT_FORMAT.includes(dateParseFormat)) {
    switch (dateParseFormat) {
      case TimeFormat.mm:
        return moment(moment.duration(timeString, 'minutes').asHours(), format);
      case TimeFormat.SS:
        return moment(moment.duration(timeString, 'seconds').asHours(), format);
    }
  }
  return getValidMoment(moment(typeof timeString === 'string' ? timeString : doubleToTime(timeString), format));
};
