import { getPreCostErrorMessage } from '@modules/orders-and-trips/decorators/get-pre-cost-error-message';
import { JoinChar } from '@common/constants';
import { ServicesResponsePrice } from '@modules/orders-and-trips/types/integrations';
import { IntegrationServicesResponse } from '@modules/orders-and-trips/types/fake-pre-cost';

export const getProposalDescription = ({
  description,
  convertingErrors
}: {
  description?: ServicesResponsePrice['description'];
  convertingErrors: IntegrationServicesResponse['convertingErrors'];
}) => {
  return description || convertingErrors?.map(({ message }) => getPreCostErrorMessage(message)).join(JoinChar.Semicolon);
};
