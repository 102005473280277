import { createSelector } from 'reselect';
import {
  filterTendersByStatus,
  filterTendersByStatusAndAccepted
} from '@modules/tender-platform/utils/decorators/filter-tenders-by-status';
import { TenderStatus } from '@modules/tender-platform/types';
import { ListTab } from '@modules/tender-platform/constants/tender-card';
import { filterArrayByValues } from '@modules/tender-platform/children/tender-search/decorators/filter-array-by-values';
import {
  myTendersSelector,
  tenderFiltersSelector,
  tendersSelector
} from '@modules/tender-platform/children/tender-search/selectors/tenders';

export const OVERED_TENDERS = [TenderStatus.Executed, TenderStatus.Cancelled, TenderStatus.Completed, TenderStatus.NotTake];
export const OVERED_AUCTION_TENDERS = OVERED_TENDERS.concat(TenderStatus.SummingResults);
export const PARTICIPATE_TENDERS = [TenderStatus.CollectingOffers, TenderStatus.ApprovalResults, TenderStatus.SummingResults];

export const tenderByStatusSelector = createSelector(
  tendersSelector,
  myTendersSelector,
  tenderFiltersSelector,
  (tenders, myTenders, filters) => {
    const filteredTenders = filterArrayByValues(tenders, filters);
    const filteredMyTenders = filterArrayByValues(myTenders, filters);

    const collectingOffersTenders = filterTendersByStatus([TenderStatus.CollectingOffers], filteredTenders);
    const executedTenders = filterTendersByStatusAndAccepted([TenderStatus.Executed], filteredMyTenders);
    // Во вкладке "Исполнен" не могу быть тендеры, исполненные не текущим пользователем,
    // filteredMyTenders также отвечает за фильтрацию доступа к чужим отзывам
    // не следует использовать filteredTenders, иначе нужна abac проверка и изменения на беке
    const completedTenders = filterTendersByStatusAndAccepted([TenderStatus.Completed], filteredMyTenders);
    const overedTenders = filterTendersByStatusAndAccepted(OVERED_TENDERS, filteredMyTenders, false);
    const participation = filterTendersByStatus(PARTICIPATE_TENDERS, filteredMyTenders);

    return {
      [ListTab.Tenders]: tenders,
      [ListTab.Overed]: overedTenders,
      [ListTab.CollectingOffers]: collectingOffersTenders,
      [ListTab.Offers]: participation,
      [ListTab.Executed]: executedTenders,
      [ListTab.Completed]: completedTenders
    };
  }
);
