export const getPageSizeOptions = (data: any = 1): Array<string> => {
  if (data.length <= 30) {
    return ['10', '20', 'все записи'];
  }

  if (data.length <= 50) {
    return ['10', '20', '30', 'все записи'];
  }

  if (data.length <= 100) {
    return ['10', '20', '30', '50', 'все записи'];
  }

  if (data.length > 100) {
    return ['10', '20', '30', '50', '100', 'все записи'];
  }
  return [];
};
