import { useRef, useCallback, useEffect } from 'react';

/**
 * Хук для проверки состояние компонента
 * @returns возвращает функцию () => boolean;
 * true компонент находится на странице
 * false компонент удален, либо будет удаляться
 */
export const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
