import { useDispatch, useSelector } from 'react-redux';
import { currentBalanceFetchSelector, currentBalanceSumSelector } from '@common/feature/payment/selectors';
import { getCurrentBalanceRoutine } from '@common/feature/payment/actions';
import { useInterval } from '@common/hooks/use-interval';
import { UPDATE_CURRENT_BALANCE_INTERVAL } from '@common/feature/payment/constants/general';

export const useLoadCurrentBalance = () => {
  const dispatch = useDispatch();
  const balanceSum = useSelector(currentBalanceSumSelector);
  const balanceIsFetching = useSelector(currentBalanceFetchSelector);

  const loadCurrentBalance = () => {
    dispatch(getCurrentBalanceRoutine());
  };

  const formattedBalance = balanceSum.toLocaleString('ru-RU');

  useInterval(loadCurrentBalance, UPDATE_CURRENT_BALANCE_INTERVAL, []);

  return { balance: formattedBalance, balanceIsFetching };
};

