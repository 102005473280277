import moment, { Moment } from 'moment';
import { DateFormat, DateTimeFormat, ILS_MAIN_API_DATE_FORMAT, TimeFormat, TimeStamp } from '@common/types';
import { ILS_MAIN_API_DATE_TIME_FORMAT } from '@common/types/general';
import {
  ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
  UNIX_TIME_STAMP_CHARS
} from '@common/types/general/date-time';

export const prepareDateToServer = (
  date: TimeStamp | Moment | string,
  format?: DateFormat | TimeFormat | DateTimeFormat,
  withTime: boolean = false
) => {
  let prepareFormat: DateFormat | TimeFormat | DateTimeFormat;
  if (format) {
    prepareFormat = format;
  } else {
    prepareFormat = withTime ? ILS_MAIN_API_DATE_TIME_FORMAT : ILS_MAIN_API_DATE_FORMAT;
  }
  if (moment.isMoment(date)) {
    return date.format(prepareFormat);
  } else if (typeof date === 'string') {
    return moment.utc(date, withTime ? ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT : ILS_MAIN_INTERFFACE_DATE_FORMAT).format(prepareFormat);
  } else {
    if (date.toString().length <= UNIX_TIME_STAMP_CHARS) {
      return moment.unix(date).utc().format(prepareFormat);
    }
    return moment(date).utc().format(prepareFormat);
  }
};
