import { changeDocumentStatusRoutine, getInboxSendDocumentsRoutine } from '@modules/documents/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { DocumentsType } from '@modules/documents/types/document-state';

function* changeDocumentStatusWorker({ payload }: { payload: { id: number; status: number } }) {
  const { request } = changeDocumentStatusRoutine;
  try {
    yield put(request());
    yield call(Documents.setDocumentStatus, payload);

    switch (payload.status) {
      case 1:
        ILSUserNotify(Notify.Success, 'Документ успешно перенесен в ваши рабочие документы');
        break;
      case 2:
        ILSUserNotify(Notify.Success, 'Документ отклонен');
        break;
      case 3:
        ILSUserNotify(Notify.Success, 'Документ удален');
        break;
      default:
        break;
    }

    yield put(getInboxSendDocumentsRoutine({ type: DocumentsType.Inbox }));
  } catch (e) {
    ILSUserNotify(Notify.Error, e as any, 3);
  }
}

export function* changeDocumentStatusWatcher() {
  yield takeLatest(changeDocumentStatusRoutine, changeDocumentStatusWorker);
}
