import { Plan, Provider } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { getDefaultPlanConfig } from '@modules/planning/children/by-date/helpers/plan/get-default-plan-config';
import { isExternalProvider } from '@modules/planning/children/by-date/helpers/plan/is-external-plan-config';
import { TPlanningConfig } from '@modules/planning/children/by-date/types';
import { DefaultPlannerConfigsResponse } from '@modules/planning/types/api';
import { isEmpty } from 'lodash';

// NOTE: Настройки для валидации и планирования
export const getActivePlanConfig = (
  currentPlan: Plan,
  activePlanID: number,
  planningConfigByPlan: Record<string, TPlanningConfig> | null,
  defaultPlannerConfigs: {
    lastUpdate: number;
    loading: boolean;
    data?: DefaultPlannerConfigsResponse | undefined;
  }
) => {
  if (!isEmpty(currentPlan) && activePlanID) {
    const [providerPlan, configIDPlan] = Object.values(currentPlan.PlanTotal?.PlanConfig ?? {}) as [Provider, string | number];
    const typePlanning = currentPlan.PlanTotal?.TypePlanning;
    const provider = planningConfigByPlan?.[activePlanID]?.providerName || providerPlan || Provider.ILS;

    let { operation, planConfigID: ConfigID } = getDefaultPlanConfig(defaultPlannerConfigs, provider);

    const isExternalProvide = isExternalProvider(provider);

    let externalPlanConfigID;
    let planConfigID;

    if (isExternalProvide) {
      externalPlanConfigID = planningConfigByPlan?.[activePlanID]?.externalPlanConfigID || configIDPlan;
    } else {
      planConfigID = planningConfigByPlan?.[activePlanID]?.planConfigID || configIDPlan?.toString();
    }

    if (compareAsString(currentPlan?.PlanConfigID, '0') && compareAsString(planConfigID, currentPlan?.PlanConfigID)) {
      planConfigID = ConfigID;
    }

    return {
      providerName: provider,
      operation: typePlanning || operation,
      planConfigID,
      externalPlanConfigID
    };
  }
  return null;
};
