import { FC } from 'react';
import { ILSFormItem, ILSInputNumber } from '@common/components';
import { CARGO_INPUT_STEP, MIN_NUMBER_INPUT_VALUE, ORDER_AND_TRIP_FORM_RULES } from '@modules/orders-and-trips/constants/form';
import { Field, Label, Placeholder } from '@components/common/forms/constants';
import { UnitOfMeasurement } from '@common/constants/general/unit-of-measurement';

const DOCUMENTS_CARGO_FIELD_NAME = Field.Mass;
const DOCUMENTS_CARGO_AMOUNT = 0;
const DOCUMENTS_MASS = { tip: '(средний вес тысячи листов А4 плотностью 80)', max: 5 };
const DOCUMENTS_MAX_MASS_TOOLTIP = `Максимальный вес - ${DOCUMENTS_MASS.max} кг ${DOCUMENTS_MASS.tip}`;

export const DocumentsFormFields: FC = () => {
  return (
    <ILSFormItem
      tooltip={DOCUMENTS_MAX_MASS_TOOLTIP}
      name={[Field.Cargo, DOCUMENTS_CARGO_AMOUNT, DOCUMENTS_CARGO_FIELD_NAME]}
      label={Label[DOCUMENTS_CARGO_FIELD_NAME]}
      rules={ORDER_AND_TRIP_FORM_RULES[DOCUMENTS_CARGO_FIELD_NAME]}
      className={'fake-pre-cost__form-item'}
    >
      <ILSInputNumber
        addonAfter={UnitOfMeasurement.Kg}
        step={CARGO_INPUT_STEP}
        placeholder={Placeholder[DOCUMENTS_CARGO_FIELD_NAME]}
        min={MIN_NUMBER_INPUT_VALUE}
        max={DOCUMENTS_MASS.max}
      />
    </ILSFormItem>
  );
};
