import { compareAsString } from '@common/utils';
import { PLANNING_RESULT_ERRORS } from '../../constants';
import { IMapTrack, Notify, PlanStatus } from '@common/types';

type ErrorObject = {
  MESSAGE: string;
  TYPE: Notify;
  DURATION: number;
  KEY: string;
};

export const checkAccessMoveWpToTrip = (tracks: IMapTrack[], targetTripID: number, moveMarkersIDs?: number[]): ErrorObject | null => {
  if (tracks.length) {
    for (const i in tracks) {
      if (tracks[i].TripID === targetTripID) {
        if (!compareAsString(tracks[i].tripStatus, PlanStatus.Open) && !compareAsString(tracks[i].tripStatus, PlanStatus.AdditionsOnly)) {
          return PLANNING_RESULT_ERRORS.TRIP_IS_LOCK;
        }
      }
      if (
        tracks[i].waypoint?.WaypointID &&
        moveMarkersIDs?.includes(Number(tracks[i].waypoint!.WaypointID)) &&
        !compareAsString(tracks[i].tripStatus, PlanStatus.Open)
      ) {
        return PLANNING_RESULT_ERRORS.WAYPOINT_IS_LOCK;
      }
    }
  }
  return null;
};
