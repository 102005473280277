import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, Notify, NotifyDurationInSecond } from '@common/types';
import { demandRoutinesToNotify } from '@modules/demand/actions';
import { DemandNotifyMessage } from '@modules/demand/constants';
import { DemandAction } from '@modules/demand/types/action';
import { getErrorMessage } from '@common/utils';

/**Сага для получения текста уведомления для разных экшенов*/
const getDataNotificationMessage = (
  action: DemandAction,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || DemandNotifyMessage?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): DemandAction => {
  return actionName as DemandAction;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, DemandAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Demand,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, DemandAction>({
  getActionFromRoutine,
  getKeyPrefix: () => Module.Demand,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* demandNotifierWatcher() {
  for (let routine of demandRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
