import { useEffect } from 'react';
import { useModal } from '@common/hooks/components/use-modal';
import { useDispatch, useSelector } from 'react-redux';
import { activeModalWindowSelector } from '@modules/orders-and-trips/selectors';
import { setActiveModalWindowRoutine } from '@modules/orders-and-trips/actions';
import { MODAL_WINDOW_CONTAINERS } from '@modules/orders-and-trips/constants/display-blocks';
import { ModalWindowTitles } from '@modules/orders-and-trips/types/general';
import { EMPTY_STRING } from '@common/constants';

export const useModalWindow = () => {
  const { handleClose, handleOpen, visible } = useModal(false);
  const activeModalWindow = useSelector(activeModalWindowSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeModalWindow) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [activeModalWindow]);

  const onClose = () => {
    dispatch(setActiveModalWindowRoutine.success({ activeModal: null }));
    handleClose();
  };

  const ModalComponent = activeModalWindow ? MODAL_WINDOW_CONTAINERS[activeModalWindow] : EMPTY_STRING;
  const title = activeModalWindow ? ModalWindowTitles[activeModalWindow] : EMPTY_STRING;

  return { onClose, handleClose, handleOpen, visible, activeModalWindow, ModalComponent, title };
};

