import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { ILSButtonTooltip } from '@common/components';
import { Status, Tender, TenderStatus } from '@modules/tender-platform/types';
import { TenderOperationButton } from '@modules/tender-platform/constants';
import {
  acceptedTenderOfferSelector,
  currentTenderLotSelector,
  currentTenderSelector,
  fetchSelector
} from '@modules/tender-platform/selectors';
import { currentTenderTransitionSchemeSelector, isCurrentTenderDatesNilSelector } from '@modules/tender-platform/selectors/current-tender';
import { TenderOperationsArgument } from '@modules/tender-platform/constants/content';

interface IProps {
  handleTenderSetStatus(tenderID: Tender['ID'], statusID: Status['ID']): void;

  handleModal({ operation }: TenderOperationsArgument): void;
}

export const TenderPlatformTransitionSchemeStatusButtonsContainer: FC<IProps> = ({ handleTenderSetStatus, handleModal }) => {
  const isFetching = useSelector(fetchSelector);
  const TenderLot = useSelector(currentTenderLotSelector);
  const hasLots = !isEmpty(TenderLot);
  const { isDatesNil } = useSelector(isCurrentTenderDatesNilSelector);
  const transitionScheme = useSelector(currentTenderTransitionSchemeSelector);
  const hasAcceptedOffers = useSelector(acceptedTenderOfferSelector);
  const { ID } = useSelector(currentTenderSelector);

  const handleSetStatus = (statusID: number, alias: TenderStatus) => {
    switch (alias) {
      case TenderStatus.Completed:
        handleModal({ operation: TenderOperationButton.ConfirmSetExecutionStatus });
        break;
      case TenderStatus.Cancelled:
        handleModal({ operation: TenderOperationButton.ConfirmSetCancellationStatus });
        break;
      default:
        handleTenderSetStatus(ID, statusID);
        break;
    }
  };

  return (
    <>
      {transitionScheme?.map(({ Alias, TriggerText, Text, ID }) => {
        const isDisabled =
          (Alias !== TenderStatus.Cancelled && Alias !== TenderStatus.UnderRevision && !hasLots) ||
          (Alias === TenderStatus.OnApproval && isDatesNil) ||
          (Alias === TenderStatus.ApprovalResults && !hasAcceptedOffers);
        const isInvisible = Boolean(!hasLots && !ID && Alias !== TenderStatus.New);
        const handleClick = () => handleSetStatus(ID, Alias as TenderStatus);

        return (
          !isInvisible && (
            <ILSButtonTooltip
              className={'menu-button'}
              disabled={isFetching || isDisabled}
              type="primary"
              children={TriggerText ?? Text}
              key={ID}
              onClick={handleClick}
              title={`Тендер перейдёт в статус "${Text}"`}
              placement={'rightBottom'}
            />
          )
        );
      })}
    </>
  );
};
