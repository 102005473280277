import { IndexedArray } from '@common/types';

export enum ForwarderPointStatusesChooseAvailable {
  No,
  Yes
}

export enum ForwarderPointStatusesSistemDeleteStatus {
  Yes,
  No
}

export type ForwarderPointStatusesIcon = string;
export type ForwarderPointStatusesIconUrl = string;

export enum MonitoringStatusType {
  /**
   * Доставка не была завершена
   */
  Negative,
  /**
   * Статус определяется полученными событиями и их временем
   */
  Positive
}

export type ForwarderPointStatuses = {
  ID: number;
  Name: string | null;
  SignColor: string | number | null;
  ChooseAvailable: ForwarderPointStatusesChooseAvailable | null;
  IsMaster: ForwarderPointStatusesSistemDeleteStatus;
  Icon: ForwarderPointStatusesIcon;
  IconUrl: ForwarderPointStatusesIconUrl;
  Type?: MonitoringStatusType | null;
};

export type ForwarderPointStatusesGroup = {
  ID: number;
  Name: string | null;
  AvailableStatuses: Array<ForwarderPointStatuses> | null;
};

export type ForwarderPointStatusesResponse = IndexedArray<ForwarderPointStatuses>;
export type ForwarderPointStatusesGroupResponse = IndexedArray<ForwarderPointStatusesGroup>;

export type ForwarderPointStatusesIconsURLResponse = Record<ForwarderPointStatusesIcon, ForwarderPointStatusesIconUrl>;

