import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';

import { PointTypeApi } from '@common/utils/get-track/types';
import { filterPointsToGetTrack } from '@common/utils/get-track/planPoint2getTrack';
import { trackGetTrackRoutine } from '@modules/track/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* trackGetTrackWorker(action: PayloadAction<{ points: { [K: string]: PointTypeApi } }>) {
  const { request, success, failure, fulfill } = trackGetTrackRoutine;
  const { points } = action.payload;

  try {
    yield put(request({ points }));
    const searchPoint = filterPointsToGetTrack(points);
    const { data } = yield call(PlanningAPI.getTrack, { points: searchPoint });

    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield delay(1000);
    yield put(fulfill());
  }
}

export function* trackGetTrackWatcher() {
  yield takeEvery(trackGetTrackRoutine.trigger, trackGetTrackWorker);
}
