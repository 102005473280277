import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getPreCostRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { PreCost, PreCostServiceType } from '@modules/orders-and-trips/types/integrations';
import { isArray, isEmpty } from 'lodash';
import { PayloadActionError } from '@common/types';
import { JoinChar, SplitChar } from '@common/constants';

export const getPreCostReducer = createReducer(initialState, {
  [getPreCostRoutine.REQUEST]: (state: InitialState) => {
    state.availableIntegrations.isFetching = true;
    return state;
  },
  [getPreCostRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: PreCost;
    }>
  ) => {
    const { data } = action.payload;
    const tripIds = Object.keys(data ?? {});
    const newServices = [] as PreCostServiceType;
    tripIds?.forEach((tripId) => {
      if (tripId in state.availableIntegrations.preCost) {
        if (data[tripId]?.services) {
          data[tripId].services.forEach((service: PreCostServiceType[number]) => {
            if (!isEmpty(state.availableIntegrations.preCost[tripId]?.services)) {
              state.availableIntegrations.preCost[tripId].services.forEach((existingService: PreCostServiceType[number]) => {
                if (existingService.name !== service.name) {
                  newServices.push(service);
                }
              });
            } else if (
              state.availableIntegrations.preCost[tripId]?.services &&
              isEmpty(state.availableIntegrations.preCost[tripId]?.services)
            ) {
              state.availableIntegrations.preCost[tripId].services = data[tripId]?.services;
            }
          });
          state.availableIntegrations.preCost[tripId].services = [...state.availableIntegrations.preCost[tripId].services, ...newServices];
        }
      } else {
        state.availableIntegrations.preCost = {
          ...state.availableIntegrations.preCost,
          ...data
        };
      }
    });
    return state;
  },
  [getPreCostRoutine.FAILURE]: (state: InitialState, action: PayloadActionError) => {
    const { error } = action.payload;
    state.error = !isArray(error) ? [error] : [error.join(SplitChar.Semicolon)];
    return state;
  },
  [getPreCostRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.availableIntegrations.isFetching = false;
    return state;
  }
});

