import { FC } from 'react';
import { ITablePropsFunction, SelectedRowKeys, SetSelectedRowKeys } from '@common/types';
import { ILSButton } from '@common/components';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { DeleteOutlined, MenuUnfoldOutlined, RestOutlined } from '@ant-design/icons/lib/icons';
import { onClickDeleteAndNotify } from '../utils/helpers/on-click-delete-and-notify';

interface IProps extends Partial<Pick<ITablePropsFunction<any>, 'handleDelete' | 'handleOpen' | 'handleCheckDelete' | 'handleRestore'>> {
  record: any;
  rowIndex: number;
  disabled: boolean;
  selectedRowKeys: SelectedRowKeys;
  setSelectedRowKeys: SetSelectedRowKeys;
  hasDetails: boolean;
  hasDelete: boolean;
  handleDisable(disabled: boolean): void;
}

export const ILSTableRowActions: FC<IProps> = ({
  handleDelete,
  handleCheckDelete,
  record,
  rowIndex,
  handleOpen,
  handleRestore,
  handleDisable,
  disabled,
  setSelectedRowKeys,
  hasDetails,
  hasDelete,
  selectedRowKeys
}) => {
  const deleted = record?.Deleted;

  const onRestoreRow = () => {
    ILSUserNotifyConfirm('Восстановить запись в таблицу?', undefined, () => handleRestore?.([record?.key], [record], rowIndex));
  };

  const onDeleteRow = () => {
    onClickDeleteAndNotify(
      'Удалить запись из таблицы?',
      [record?.key ?? record.ID],
      [record],
      rowIndex,
      handleDelete,
      handleCheckDelete,
      setSelectedRowKeys,
      handleDisable,
      selectedRowKeys
    );
  };

  const onOpen = () => handleOpen?.(record?.key, record);

  return (
    <div style={{ display: 'flex' }}>
      {hasDetails && <ILSButton title={'Подробнее...'} className="edit" type="link" onClick={onOpen} icon={<MenuUnfoldOutlined />} />}
      {hasDelete && !record?.ForbiddenToDelete && (
        <ILSButton
          disabled={disabled}
          title={deleted ? 'Восстановление строки' : 'Удаление строки'}
          className="delete"
          type="link"
          onClick={deleted ? onRestoreRow : onDeleteRow}
          icon={deleted ? <RestOutlined className={'ils-svg primary'} /> : <DeleteOutlined className={'ils-svg error'} />}
        />
      )}
    </div>
  );
};
