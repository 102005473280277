import { ColumnTable, IndexedArray, TableRecord } from '@common/types';
import { createWaypointComments } from '@common/table-configs/monitoring-dashboard/common/collect-waypoints-comments';
import { getTextViewStatus } from '@common/table-configs/monitoring-dashboard/common/forward-status-view';
import { FileAttachment } from '@common/types/dictionaries';
import { PointsStatusesList } from '@common/types/dictionaries/monitoring';
import { MonitoringVehicleEventsModel, TrackerLastInfoModel } from '@common/types/dictionaries/monitoring-vehicle';
import { timestampToDateStringWithUTC } from '@common/utils';
import { timestampToTimeStringWithUTC } from '@common/utils/helpers/date-time/date-time';
import { timeFromStart } from '@modules/monitoring/utils/time-from-start';
import { isEmpty } from 'lodash';

type DataToTableFunction<R> = (
  data?: IndexedArray<MonitoringVehicleEventsModel>,
  activeVehicleID?: number | undefined,
  pointsStatusesList?: PointsStatusesList,
  showAttachment?: (arg: { files: Array<FileAttachment> }) => any
) => Array<
  Omit<R, 'LastStatusDT' | 'LastStatus'> & {
    key: string;
    waypointName: string;
    comment: string;
    address: string;
    distance: string;
    waypointStatus: string;
    planArrivalTime?: string;
    factArrivalTime?: string;
    planDepartureTime?: string;
    factDepartureTime?: string;
    planParkingTime: number[];
    factParkingTime: number[];
    Number: string;
    numberFact: string;
  }
>;

export const sortWaypointByTripAndIndPlan = (waypoints: ReturnType<typeof data2table>) => {
  return waypoints.sort((a, b) => {
    /** TMS-9368 порядок точек по tripNumber и Waypoint.Ind хранится в Number */
    if (a.Number < b.Number) {
      return -1;
    }
    if (a.Number > b.Number) {
      return 1;
    }
    return 0;
  });
};

function genTimeWithTooltipDate(
  timestamp: number | null | undefined,
  dataIndex: ColumnTable<TableRecord>['dataIndex'],
  offsetTimeZone: number
) {
  if (!timestamp) {
    return { [dataIndex]: '' };
  }
  const tooltipKey = dataIndex + '_tip';
  const time = timestampToTimeStringWithUTC(timestamp, offsetTimeZone);
  const date = timestampToDateStringWithUTC(timestamp, offsetTimeZone);
  return { [tooltipKey]: date, [dataIndex]: time };
}

export const data2table: DataToTableFunction<
  {
    ID: number;
    key: string;
  } & Partial<TrackerLastInfoModel>
> = (vehicleData, activeVehicleID, pointsStatusesList, showAttachment) => {
  const res: ReturnType<typeof data2table> = [];

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (isEmpty(vehicle?.Trip) || Number(activeVehicleID) !== Number(vehicle?.ID)) {
      continue;
    }
    let tripNumber = 0;
    for (const tripIndex in vehicle.Trip) {
      tripNumber++;

      const trip = vehicle.Trip[tripIndex];

      if (isEmpty(trip?.Waypoint)) {
        continue;
      }
      for (const waypointIndex in trip.Waypoint) {
        const waypoint = trip.Waypoint[waypointIndex];

        const offsetTimeZoneWPInMinutes = (waypoint?.Depot?.UTCOffset || 3) * 60;
        //TODO для статусов возможно больше не нужно получать pointsStatusesList, в LastStatus сразу содержится сущность
        const waypointManual = typeof waypoint.WaypointFactManual === 'object' && Object.values(waypoint.WaypointFactManual)?.pop();
        const waypointStatus = waypointManual
          ? pointsStatusesList?.[waypointManual?.Status]
          : pointsStatusesList?.[waypoint.WaypointFact?.WaypointStatus?.toString() || ''];
        //

        const planArrivalTime = genTimeWithTooltipDate(
          timeFromStart(trip?.ProjectStart, waypoint.ArrivalTime),
          'planArrivalTime',
          offsetTimeZoneWPInMinutes
        );
        const planDepartureTime = genTimeWithTooltipDate(
          timeFromStart(trip?.ProjectStart, waypoint.DepartureTime),
          'planDepartureTime',
          offsetTimeZoneWPInMinutes
        );
        const factArrivalTime = genTimeWithTooltipDate(waypoint.FactArrive, 'factArrivalTime', offsetTimeZoneWPInMinutes);
        const factDepartureTime = genTimeWithTooltipDate(waypoint.FactDepart, 'factDepartureTime', offsetTimeZoneWPInMinutes);
        const Attachments = !isEmpty(waypoint?.Attachments)
          ? showAttachment?.({
              files: Object.values(waypoint.Attachments as Array<FileAttachment> | IndexedArray<FileAttachment>)
            })
          : undefined;

        const { LastComment, LastComment_htmltip } = createWaypointComments(waypoint);

        const rowObj = {
          ...waypoint,
          Attachments,
          waypointName: waypoint?.Depot?.Name || '',
          address: waypoint?.Depot?.AddressDescription || '',
          ...planArrivalTime,
          ...factArrivalTime,
          ...planDepartureTime,
          ...factDepartureTime,
          key: !waypoint.ID ? `row#${vehicleIndex}#${tripIndex}#${waypointIndex}` : String(waypoint.ID),
          /** TMS-9368 Плановый порядковый номер - Waypoint.Ind, + 1 для массива (массивы начинаются с 0) */
          Number: `${tripNumber}.${waypoint.Ind + 1}`,
          numberFact: waypoint?.FactInd ? `${tripNumber}.${waypoint.FactInd}` : '-',
          comment: waypoint.Comment || '',
          LastComment,
          LastComment_htmltip,
          LastStatusDT: waypoint.LastStatusDT ? timestampToTimeStringWithUTC(waypoint.LastStatusDT, 180) : undefined,
          LastStatus: getTextViewStatus(waypoint.LastStatus),
          distance: waypoint?.DistanceFromPrevPoint ? waypoint.DistanceFromPrevPoint.toFixed(2) + ' км' : '',
          waypointStatus: waypointStatus || 'Нет данных',
          planParkingTime:
            trip?.ProjectStart && waypoint.DepartureTime && waypoint.ArrivalTime
              ? [timeFromStart(trip?.ProjectStart, waypoint.ArrivalTime), timeFromStart(trip?.ProjectStart, waypoint.DepartureTime)]
              : [0, 0],
          factParkingTime: waypoint.FactDepart && waypoint.FactArrive ? [waypoint.FactArrive, waypoint.FactDepart] : [0, 0]
        };

        res.push(rowObj);
      }
    }
  }
  /** TMS-9368 порядок точек должен идти по tripNumber затем по Waypoint.Ind */
  return sortWaypointByTripAndIndPlan(res);
};
