import { DocumentType } from '../types/document-type';

export enum DocFilesTypes {
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Bmp = 'bmp',
  Pdf = 'pdf'
}

export const DOC_FILE_TYPES = [
  DocumentType.Bill,
  DocumentType.Contract,
  DocumentType.ContractAnnex,
  DocumentType.Custom,
  DocumentType.Deal,
  DocumentType.LadingBill,
  DocumentType.RouteList
];

