import { FC, MutableRefObject } from 'react';
import { TenderOwnFormTableHeader } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/header';
import { TenderOwnFormTableBody } from '@modules/tender-platform/children/tender-own/components/create-from-planner/body';
import { useSelector } from 'react-redux';
import { routesSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { ILSSpinCenter } from '@common/components';
import { isEmpty } from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';
import { RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';

interface IProps {
  onSetDataFiles: (routeId: RoutesID, file: UploadFile[]) => void;
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
}

export const TenderOwnFormTableContainer: FC<IProps> = ({ onSetDataFiles, dataFiles }) => {
  const { routes } = useSelector(routesSelector);

  if (isEmpty(routes)) {
    return (
      <div className={'tender-from-planner-form-table'}>
        <ILSSpinCenter />
      </div>
    );
  }
  return (
    <div className={'tender-from-planner-form-table'}>
      <TenderOwnFormTableHeader routes={routes} />
      <TenderOwnFormTableBody dataFiles={dataFiles} onSetDataFiles={onSetDataFiles} routes={routes} />
    </div>
  );
};
