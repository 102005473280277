import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { addCreatedPlanRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanRequest } from '@modules/planning/types/api';
import { onFailure } from '@common/utils';
import { Plan, PlanTotal } from '@common/types/dictionaries/plan';
import { IndexedArray } from '@common/types';
import { INITIAL_CURRENT_PLAN } from '@modules/planning/children/by-date/constants/store';

export type AddCreatedPlanPayloadAction = PayloadAction<{ planTotal: IndexedArray<PlanTotal>; plan: Plan }>;

export const addCreatedPlanReducer = createReducer(initialState, {
  [addCreatedPlanRoutine.FAILURE]: onFailure,
  [addCreatedPlanRoutine.REQUEST]: (state, { payload: { planID } }: PayloadAction<PlanRequest>) => {
    state.activePlanID = planID;
    state.info.plans[planID] = { isFetching: true, lastUpdate: null };
    return state;
  },
  [addCreatedPlanRoutine.FULFILL]: (state, { payload: { planID } }: PayloadAction<PlanRequest>) => {
    state.info.plans[planID].isFetching = false;
    return state;
  },
  [addCreatedPlanRoutine.SUCCESS]: (state, action: AddCreatedPlanPayloadAction) => {
    const { plan, planTotal } = action.payload;
    state.activePlanID = plan.ID;
    state.currentPlan = INITIAL_CURRENT_PLAN;
    if (state.project?.Plan) {
      state.project.Plan[plan.ID] = { ...plan, PlanTotal: Object.values(planTotal)[0] };
    } else if (state.project) {
      state.project.Plan = { [plan.ID]: { ...plan, PlanTotal: Object.values(planTotal)[0] } };
    }

    return state;
  }
});

