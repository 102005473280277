import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getPlannerParamsRoutine } from '@modules/planning/children/by-date/actions';
import { PlanningParamsApi } from '@modules/planning/children/by-date/api/planner-params';
import { ONE_DAY_INTERVAL } from '@common/constants/general/date-time';

function* worker() {
  const { success, failure, fulfill } = getPlannerParamsRoutine;
  try {
    const lastUpdate: number | undefined = yield select((state) => state.PlanningByDate.params?.lastUpdate);
    const checkInterval = Date.now() - parseInt(ONE_DAY_INTERVAL);
    if (!lastUpdate || checkInterval > lastUpdate) {
      const {
        data: { data }
      } = yield call(PlanningParamsApi.index);

      yield put(success({ data }));
    } else {
      yield put(success({ hasNoChanges: true }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getPlannerParamsWatcher() {
  yield takeLatest(getPlannerParamsRoutine.trigger, worker);
}
