import { call, put, takeLatest } from 'redux-saga/effects';
import { MobileApplicationAPI } from '@common/api/common';
import { MobileApplicationDictionarySagaPayload } from '@common/types/dictionaries/mobile-application';
import { Dictionary } from '@common/types';
import { monitoringForwarderPointStatusesRoutine } from '@modules/monitoring/actions';

function* worker(action: MobileApplicationDictionarySagaPayload) {
  const { request, success, failure, fulfill } = monitoringForwarderPointStatusesRoutine;

  try {
    const { mobileApplicationDictionary = Dictionary.ForwarderPointStatuses, ids, operation, values } = action.payload;
    yield put(request());
    const {
      data: { data }
    } = yield call(MobileApplicationAPI.mobileApplicationDictionaryOperation, {
      mobileApplicationDictionary,
      ids,
      operation,
      values
    });
    yield put(success({ data, operation, ids }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringStatusesWatcher() {
  yield takeLatest(monitoringForwarderPointStatusesRoutine.trigger, worker);
}
