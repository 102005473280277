import { useAppSelector } from '@core/hooks';
import { monitoringMetricsSelector } from '@modules/monitoring/selectors';
import { MetricsData } from '@modules/monitoring/types/store';
import { FC, memo, useEffect } from 'react';
import { ILSDashboardMetricsComponent } from '../components/dashboard-metrics';
import { DashboardMetricsHeader } from '../components/dashboard/dashboard-metrics-header';
import { dashboardTabs } from '../configs/dashboard-table';
import { DashboardTablesNumberMetrics } from '../constants/tables';
import classnames from 'classnames';
import { DashboardTables } from '../types';

interface IProps {
  activeTab?: DashboardTables | undefined;
  handleRedirect?: (dashboardName: string) => void;
  setActiveTab?(newActiveTable: DashboardTables | undefined): void;
}

// Контейнер отвечающий за данные для метрик дашбоарда
const DashboardMetricsContainer: FC<IProps> = ({ activeTab, setActiveTab, handleRedirect }) => {
  const { data } = useAppSelector(monitoringMetricsSelector);

  const handleActiveTab = (dashboardName: DashboardTables) => {
    if (handleRedirect) {
      handleRedirect(dashboardName);
      return;
    }
    setActiveTab?.(dashboardName as DashboardTables);
  };

  useEffect(() => {
    if (!activeTab) {
      setActiveTab?.(DashboardTablesNumberMetrics.Served);
    }
  }, [activeTab]);

  const tabs = [];

  for (const dashboardName in dashboardTabs) {
    if (dashboardName === DashboardTablesNumberMetrics.Total) continue;
    tabs.push(
      <div
        className={classnames('ils-monitoring-metrics-tab', { active: dashboardName === activeTab })}
        key={dashboardName}
        onClick={() => handleActiveTab(dashboardName as DashboardTables)}
      >
        <DashboardMetricsHeader dashboardName={dashboardName} data={data as MetricsData['data']} />
        <div className="sub">{dashboardTabs[dashboardName].subTitle}</div>
      </div>
    );
  }

  return <ILSDashboardMetricsComponent tabs={tabs} />;
};

export const ILSDashboardMetricsContainer = memo(DashboardMetricsContainer);
