import { FC, useMemo } from 'react';
import { Col, Row } from 'antd';
import { ILSDashboardMetricsContainer } from '@modules/monitoring/children/dashboard/containers/dashboard-metrics';
import { ILSMonitoringTableContainer } from '../tables';
import { DashboardTables } from '../types';
import ILSMonitoringDateSelectComponent from '@modules/monitoring/components/date-select';
import { IndexedArray } from '@common/types';
import { MonitoringServedDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { DashboardProgressContainer } from '@modules/monitoring/children/dashboard/containers/get-progress';

interface IProps {
  activeTab: DashboardTables | undefined;
  dataSource: any[] | IndexedArray<any>;
  dataSourceAll?: IndexedArray<MonitoringServedDataModel> | Array<MonitoringServedDataModel>;
  handleDashboardDate: (date: string[]) => void;
  handleUpdateView: (id: string | number | string[], value: 0 | 1) => void;
  isFetching: boolean | undefined;
  maxInterval: string | number | boolean | undefined;
  vehicleID?: string;
  from: string | number | undefined;
  till: string | number | undefined;

  setActiveTab(newActiveTable: DashboardTables | undefined): void;
}

//TODO вынести  <Row gutter={16}> ... с метриками и прогрессом в отдельный компонент
// Основной компонент отвечающий за отображение модуля «Текущая сводка»
const ILSMonitoringDashboardComponent: FC<IProps> = ({
  handleDashboardDate,
  dataSource,
  activeTab,
  setActiveTab,
  isFetching,
  dataSourceAll,
  handleUpdateView,
  maxInterval
}) => {
  const tableProps = useMemo(() => ({
    dataSource,
    dataSourceAll,
    handleUpdateView
  }), [dataSource, dataSourceAll, handleUpdateView])
  return (
    <div className="ils-monitoring-dashboard">
      <div className="mb-40">
        <ILSMonitoringDateSelectComponent handleDate={handleDashboardDate} maxInterval={maxInterval} />
        <Row gutter={16}>
          <Col xl={{ span: 24 }} xxl={{ span: 10 }}>
            <ILSDashboardMetricsContainer activeTab={activeTab} setActiveTab={setActiveTab} />
          </Col>
          <Col xl={{ span: 24 }} xxl={{ span: 14 }}>
            <DashboardProgressContainer />
          </Col>
        </Row>
      </div>
      <div className="ils-monitoring-table">
        <div className="table">
          <ILSMonitoringTableContainer
            tableProps={tableProps}
            currentTable={activeTab}
            isFetching={isFetching}
          />
        </div>
      </div>
    </div>
  );
};

export default ILSMonitoringDashboardComponent;
