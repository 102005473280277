import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsStore } from '../types/store';

import initialState from '../store';
import { AnalyticsBoardModel, BoardDefaults } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const selectBoardCommonReducer = (
  actions: AnalyticsActions,
  callback?: {
    onSuccess(state: AnalyticsStore, action: PayloadAction<AnalyticsBoardModel & BoardDefaults>): AnalyticsStore;
  }
) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonSelectBoardRoutine.TRIGGER]: (state) => {
      state.Widgets = {};
      state.WidgetParams = { From: state.WidgetParams.From, Till: state.WidgetParams.Till };
      return state;
    },
    [actions.analyticsCommonSelectBoardRoutine.REQUEST]: (state) => {
      return state;
    },
    [actions.analyticsCommonSelectBoardRoutine.SUCCESS]: (state, action: PayloadAction<AnalyticsBoardModel & BoardDefaults>) => {
      if (action.payload?.defaults?.params) {
        state.WidgetParams = action.payload.defaults.params;
      }
      state.Boards.selectedBoard = action.payload?.ID;

      if (callback?.onSuccess) state = callback.onSuccess(state, action);

      return state;
    },
    [actions.analyticsCommonSelectBoardRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsCommonSelectBoardRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
      return state;
    }
  });
