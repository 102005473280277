import { FC } from 'react';
import { AccountUsersFormContainer } from '@modules/account/containers/users/form';
import { AccountUsersCardsComponent } from '@modules/account/components/user/user-cards';
import CustomScroll from 'react-customscroll';
import { useUserForm } from '@modules/account/utils/hooks/use-user-form';

export const AccountUsersPaneComponent: FC = () => {
  const { show, user, handleReset, toggleForm, userID } = useUserForm();

  return (
    <CustomScroll>
      <AccountUsersFormContainer visibleForm={show} handleReset={handleReset} user={user} toggleForm={toggleForm} />
      <AccountUsersCardsComponent activeUserID={userID} toggleForm={toggleForm} />
    </CustomScroll>
  );
};
