import { AccountRole, AccountRoleLabel, ModulePath, UserRoleLabel } from '@common/types';
import { ILSTenderRole } from '@modules/tender-platform/components/tender-role';
import { ILSTypographyText } from '@common/components';
import { AccountRoles } from '@common/types/dictionaries/account';
import { UserRoles } from '@common/types/dictionaries';

export const renderRoleBlock = (roles: UserRoles | AccountRoles, roleText: string, path?: string) => {
  const isDoubleRole = roles.length > 1;
  return (
    <ul className={'roles'}>
      <ILSTypographyText strong>{`Рол${isDoubleRole ? 'и' : 'ь'} ${roleText}`}</ILSTypographyText>
      {path
        ? roles.map((role) => {
            const isCarrier = role === AccountRole.carrier && path.includes(`${ModulePath.TenderPlatform}${ModulePath.TenderSearch}`);
            const isOwner = role === AccountRole.cargoOwner && path.includes(`${ModulePath.TenderPlatform}${ModulePath.TenderOwn}`);
            const isUnderlined = isDoubleRole && (isCarrier || isOwner);
            const label = AccountRoleLabel[role] ?? UserRoleLabel[role];
            return <ILSTenderRole key={label} className={'role'} underline={isUnderlined} role={label} />;
          })
        : roles.map((role) => <ILSTenderRole key={UserRoleLabel[role]} className={'role'} role={UserRoleLabel[role]} />)}
    </ul>
  );
};
