import { flatModules } from './modules';
import { getScopeModules } from './scopes';
import { IModuleModel, IRouteModel, TModulesObj } from '@core/types/models';
import { FC } from 'react';
import { checkDefaultRootRedirect, getRouteComponent } from '@core/utils/get-route-component';
import { ModulePath } from '@common/types';

export const getRoutes = (pages: TModulesObj, App: FC): { component: FC; routes: IRouteModel[] }[] => [
  { component: App, routes: getChildRoutes(pages, null) }
];

const getAllChildren = (module: IModuleModel): TModulesObj => {
  const children = (module.children ? Object.values(module.children) : []).concat(getScopeModules(module.useScope! ?? null));
  return children.reduce((acc: TModulesObj, module) => {
    acc[module.MODULE!] = module;
    return acc;
  }, {});
};

const getChildRoutes = (modules: TModulesObj, parentPath: string | null, parentRoute: IRouteModel | null = null): IRouteModel[] => {
  const routes: IRouteModel[] = [];

  flatModules(modules).forEach((module) => {
    if (!module.component || !module.path) {
      if (parentRoute) {
        const childRoutes = getChildRoutes(getAllChildren(module), parentPath, parentRoute);

        if (childRoutes.length) {
          parentRoute.routes ? parentRoute.routes.concat(childRoutes) : (parentRoute.routes = childRoutes);
        }
      }

      return;
    }

    const route: IRouteModel = {
      path: parentPath && parentPath !== ModulePath.Dashboard ? parentPath + module.path : module.path,
      exact: module.exact ?? false,
      component: getRouteComponent(module, parentPath),
      title: module.title,
      permission: module.permission!
    };

    const children = getAllChildren(module);

    if (!Object.keys(children).length) {
      routes.push(route);
      return;
    }

    const childRoutes = getChildRoutes(children, route.path, route);

    if (Object.keys(childRoutes).length) {
      route.routes = childRoutes;
    }

    //для корневого пути проверим загрузиться ли модуль с корневым путем, если нет, но есть переадресация - добавим соответствующий маршрут
    if (route.path === ModulePath.Dashboard && !parentPath) {
      const rootRedirect = checkDefaultRootRedirect(route);
      if (rootRedirect) route.routes ? route.routes.push(rootRedirect) : (route.routes = [rootRedirect]);
    }

    routes.push(route);
  });

  return routes;
};
