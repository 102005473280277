import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { deleteAttachTemplateRoutine, getDocumentSetsRoutine } from '@modules/documents/children/templates/actions';
import { AttachData } from '@modules/documents/children/templates/types/api';

function* deleteAttachTemplateWorker(action: PayloadAction<AttachData>) {
  try {
    yield call(DocumentTemplatesAPI.deleteAttachTemplate, action.payload);
    yield put(getDocumentSetsRoutine());
    ILSUserNotify(Notify.Success, 'Шаблон успешно удален из шаблона сделки');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* deleteAttachTemplateWatcher() {
  yield takeEvery(deleteAttachTemplateRoutine.trigger, deleteAttachTemplateWorker);
}
