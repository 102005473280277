import { ILSSwitch } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { FC } from 'react';

interface IProps {
  order: DecoratedOrders[number];
  isSelected: boolean;
  onSelectOrders(data: { ID: number; checked: boolean }): void;
}

export const OrderCardInfo: FC<IProps> = ({ order, isSelected, onSelectOrders }) => {
  return (
    <div className="order-card-cargo-info info">
      <div className="info-number flex-horizontal" onClick={(e) => e.stopPropagation()}>
        <ILSSwitch
          size="small"
          onChange={(e) => {
            onSelectOrders({ ID: order.ID, checked: e });
          }}
          checked={isSelected}
        />
        Заявка № {order.ID}
      </div>
      <div className="info-date">
        Дата погрузки:
        <span>
          {order.LoadDate} {order.LoadStart ?? EMPTY_STRING}
        </span>
      </div>
    </div>
  );
};

