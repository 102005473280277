import { Errors, Notify } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { takeLatest } from 'redux-saga/effects';
import { EMPTY_STRING } from '@common/constants/general';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';
import { NotifyMessage } from '@common/types/general/reducers';
import { currentUserNotifierRoutines, UserAction } from '@modules/current-user/actions';

export const DEFAULT_USER_MESSAGES: NotifyMessage<UserAction> = {
  [UserAction.RECOVERY_REQUEST]: {
    loading: 'Создание пароля...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  },
  [UserAction.REGISTER_REQUEST]: {
    loading: 'Создание пароля...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  },
  [UserAction.REGISTER]: {
    loading: 'Регистрация...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  },
  [UserAction.RECOVERY]: {
    loading: 'Сброс пароля...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  },
  [UserAction.LOG_OUT]: {
    loading: 'Выход...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  },
  [UserAction.LOG_IN]: {
    loading: 'Авторизация...',
    error: 'Ошибка запроса',
    success: 'Успешно'
  }
};

const getDataNotificationMessage = <T extends string>(defaultMessage: NotifyMessage<T>, actions: any) => {
  return (action: T, type: Notify.Loading | Notify.Success | Notify.Error, payload?: { message: string; hideSuccessMessage?: boolean }) => {
    if (payload?.hideSuccessMessage) return EMPTY_STRING;
    return payload?.message || defaultMessage?.[actions?.[action]]?.[type] || EMPTY_STRING;
  };
};

export const userWorker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, UserAction>({
  getActionFromRoutine: (actionName: string) => actionName as UserAction,
  getKeyPrefix: () => 'USER',
  getNotificationMessage: getDataNotificationMessage<UserAction>(DEFAULT_USER_MESSAGES, UserAction)
});

const userErrorWorker = notifierWorkerCreator<{ error: Errors }, UserAction>({
  getActionFromRoutine: (actionName: string) => actionName as UserAction,
  getKeyPrefix: () => 'USER',
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* currentUserNotifierWatcher() {
  for (const routine of currentUserNotifierRoutines) {
    yield takeLatest(routine.REQUEST, userWorker);
    yield takeLatest(routine.SUCCESS, userWorker);
    yield takeLatest(routine.FAILURE, userErrorWorker);
  }
}
