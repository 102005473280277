import { useEffect, useRef } from 'react';
import { Table } from 'antd';
import { ILSMultipleEditRow } from '@components/data-display/table/components/ils-multiple-edit-row';
import { TABLE_LOCALE } from '@components/data-display/table/constants';
import { TableRecord } from '@common/types';
import { EMPTY_FUNCTION } from '@common/constants';
import { useTableScroll } from '@common/components/data-display/table/hooks/use-table-scroll';
import { useTableRowButtons } from '@common/components/data-display/table/hooks/use-table-row-buttons';
import { useSelectRow } from '@components/data-display/table/hooks/use-select-row';
import { useFilterSortInfo } from '@components/data-display/table/hooks/use-filter-sort-info';
import { useFocusActivePageAndRow } from '@components/data-display/table/hooks/use-focus-active-page-and-row';
import { usePagination } from '@components/data-display/table/hooks/use-pagination';
import { useExpandRows } from '@components/data-display/table/hooks/use-expand-rows';
import { useTableComponents } from '@components/data-display/table/hooks/use-table-components';
import { renderTableTitle } from '@components/data-display/table/components/render-components/render-table-title';
import { IILSTableComponentProps } from '@common/types/components/table';
import { useTableSummary } from '@components/data-display/table/hooks/use-table-summary';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { getSelectedRowsWithoutGroupIDs } from '@common/components/data-display/table/utils/helpers';
import { useColumns } from '@components/data-display/table/hooks/use-columns';

export const ILSTableComponent = <R extends TableRecord>({
  closeTableButton,
  hasFilters,
  loading = false,
  scroll,
  size = 'small',
  config,
  handleSave = EMPTY_FUNCTION,
  refData,
  refTable,
  columns,
  dontEdit,
  errors,
  dictionary,
  dataSource,
  deleting,
  expandable,
  onTableRowClick,
  onCanEdit,
  onContextOpen,
  accordionDictionary,
  onTableRowDoubleClick,
  dragHandler,
  setShowReference,
  setFocusRow,
  codeField,
  dictionaryLoad,
  dictionaryInfo,
  dragInfo,
  handleOpen,
  handleDelete,
  handleRestore,
  showDeleted,
  handleCheckDelete,
  pagination,
  tableRef,
  bordered = true,
  rowClass,
  pageSizeOptions,
  onResizeStart,
  notHighlightFocusRow,
  focusRowCustomHighlight,
  recordsPerPage,
  expandIconColumnIndex,
  activeCell,
  tableName,
  rowSelection,
  cellClassName,
  virtual = true,
  rowKey,
  uploadConfiguration,
  onCopy,
  tableTitle,
  tableWidgets,
  recordTableButtons,
  actionTableButtons,
  handleDownload,
  handleReload,
  handleUpload,
  handleBindTimeZone,
  setShowDeleted,
  handleCheckCoords,
  isModal,
  settingsTableButtons,
  customClearFilters,
  notificationEnabled,
  overscanRowCount,
  handleOpenExportModal,
  customSummary,
  handleSelectedElements,
  selectedRows,
  onSelectRows,
  focusRow,
  pageSize = DEFAULT_PAGE_SIZE,
  handleCreate,
  handleChangeDataSource,
  onSelectRowsWithRecord,
  externalExpandableProps,
  handleFocusRow,
  isScrollToActiveRow = true,
  ...props
}: IILSTableComponentProps<R>): JSX.Element => {
  // Обновление данных у родителей
  useEffect(() => {
    handleChangeDataSource?.(dataSource);
  }, [dataSource]);

  const dataSourceRef = useRef<R[] | undefined>(undefined);

  const { focusRowArrayIndex, onFocusRow, active } = useFocusActivePageAndRow({
    dataSource,
    pageSize,
    handleFocusRow,
    focusRow,
    reference: props.reference
  });

  const { clearSelectedRowKeys, selectedRowKeys, setSelectedRowKeys, rowSelectionInner } = useSelectRow({
    handleSelectedElements,
    dataSource,
    selectedRows,
    onSelectRows,
    active,
    rowSelection,
    selecting: config?.selecting,
    onSelectRowsWithRecord,
    tableName
  });

  const { tableComponents, virtualTableRef, scrollY, onRow } = useTableComponents({
    virtual,
    overscanRowCount,
    scroll,
    dragHandler,
    active,
    onTableRowDoubleClick,
    onTableRowClick,
    onContextOpen,
    dictionary,
    focusRowCustomHighlight,
    rowClass,
    onFocusRow,
    notHighlightFocusRow,
    selectedRowKeys,
    setFocusRow,
    tableName
  });

  const { expandableProp } = useExpandRows({
    dataSource,
    dataSourceRef,
    expandable,
    tableName
  });

  const { tablePaginationConfig } = usePagination({
    dataSource,
    pageSizeOptions,
    pageSize,
    tableName,
    active,
    onFocusRow,
    pagination
  });

  useTableScroll({ virtual, virtualTableRef, focusRowArrayIndex, isScrollToActiveRow });

  const { clearFilters, clearSorting, handleChange, filterSortInfo } = useFilterSortInfo<R>({
    current: active.page,
    customClearFilters,
    pageSize
  });

  const tableColumns = useColumns({
    columns,
    config,
    refTable,
    tableRef,
    dontEdit,
    onCanEdit,
    handleSave,
    activeCell,
    setShowReference,
    dictionary,
    dictionaryLoad,
    dictionaryInfo,
    dragInfo,
    errors,
    cellClassName,
    onResizeStart,
    refData,
    dataSource,
    sorted: filterSortInfo.sorted,
    filtered: filterSortInfo.filtered
  });

  const summary = useTableSummary({ customSummary });

  useTableRowButtons({
    tableColumns,
    deleting: config?.deleting || deleting,
    handleDelete,
    handleCheckDelete,
    handleOpen,
    handleRestore,
    selectedRowKeys,
    setSelectedRowKeys
  });

  const selectedRowKeysWithoutGroupIds = getSelectedRowsWithoutGroupIDs(selectedRowKeys);
  const isMultipleEditRow =
    dataSource && config?.selecting && config?.multiEditing && selectedRowKeysWithoutGroupIds && selectedRowKeysWithoutGroupIds.length > 1;

  return (
    <Table
      showSorterTooltip
      locale={{
        ...TABLE_LOCALE,
        ...props.locale
      }}
      rowKey={rowKey ?? (({ key, ID }) => key ?? ID)}
      summary={summary}
      onChange={handleChange}
      loading={loading}
      rowSelection={rowSelectionInner}
      scroll={scrollY}
      components={tableComponents}
      dataSource={dataSource}
      pagination={tablePaginationConfig}
      size={size}
      bordered={bordered}
      onRow={onRow}
      {...props}
      columns={tableColumns}
      expandable={externalExpandableProps ?? expandableProp}
      expandIconColumnIndex={expandIconColumnIndex}
      title={renderTableTitle({
        handleBindTimeZone,
        actionTableButtons,
        settingsTableButtons,
        customClearFilters,
        clearSorting,
        closeTableButton,
        hasFilters,
        clearFilters,
        tableTitle,
        tableWidgets,
        recordTableButtons,
        handleOpenExportModal,
        clearSelectedRowKeys,
        selectedRowKeys,
        handleDelete,
        handleCheckDelete,
        handleCheckCoords,
        isModal,
        loading: Boolean(loading),
        config,
        uploadConfiguration,
        onCopy,
        dictionaryLoad,
        handleUpload,
        handleReload,
        handleRestore,
        handleDownload,
        dataSource,
        dictionary,
        active,
        filterSortInfo,
        notificationEnabled,
        showDeleted,
        handleCreate,
        onFocusRow,
        setSelectedRowKeys,
        refData,
        refTable,
        setShowReference,
        setShowDeleted
      })}
      footer={() =>
        isMultipleEditRow && (
          <ILSMultipleEditRow
            selectedRowKeys={selectedRowKeysWithoutGroupIds}
            pagination={!!pagination}
            tableRef={tableRef}
            bordered={bordered}
            mappedCols={tableColumns}
          />
        )
      }
    />
  );
};
