import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { vehicleMonitoringGetLinkRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';

export const getTripLinkReducer = createReducer(initialState, {
  [vehicleMonitoringGetLinkRoutine.REQUEST]: (state) => {
    state.tripLink = null;
    return state;
  },
  [vehicleMonitoringGetLinkRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      link: string;
    }>
  ) => {
    const { link } = action.payload;
    state.tripLink = link;
    return state;
  }
});
