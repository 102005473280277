import { FC } from 'react';
import { ILSTenderCardsContent } from '@modules/tender-platform/containers/tender-card/cards-content';
import { CardTab } from '@modules/tender-platform/constants';
import { useSelector } from 'react-redux';
import { currentTenderSelector, fetchSelector } from '@modules/tender-platform/selectors';
import { ILSSpin } from '@common/components';

interface IProps {
  isTenderEditable: boolean;
  menu: Array<CardTab>;
}

export const TenderPlatformTenderCardContainer: FC<IProps> = ({ isTenderEditable, menu }) => {
  const currentTender = useSelector(currentTenderSelector);
  const isFetching = useSelector(fetchSelector);

  return (
    <section className={'tender-platform-card'} key="tender-platform-card">
      <ILSSpin className={'tender-platform-card'} spinning={isFetching}>
        <ILSTenderCardsContent isTenderEditable={isTenderEditable} menu={menu} currentTender={currentTender} />
      </ILSSpin>
    </section>
  );
};
