import { AddressAPI } from '@common/api/common';
import { ILSShowMessage } from '@common/components';
import { EMPTY_STRING } from '@common/constants';
import { first, isEmpty } from 'lodash';
import { GET_COORD_NOTIFY } from '@modules/catalog/constants/get-coords';

/**
 * Упрощенный вариант функции получения адресу по координатам (DaData)
 */
export const getAddressByCoordsDaData = async (lat: number, lon: number) => {
  const geocode = await getGeocodeCoords([lat, lon]);
  if (!isEmpty(geocode) && first(geocode)?.value) {
    return first(geocode)?.value;
  } else return undefined;
};

/**
 * Упрощенный вариант функции получения координат по адресу (DaData)
 */
export const getCoordsByAddressDaData = async (address: string): Promise<[number, number] | undefined> => {
  const geocode = await getGeocodeAddress(address);

  if (!isEmpty(geocode)) {
    return [geocode.Latitude, geocode.Longitude];
  } else return undefined;
};

const getGeocodeCoords = async ([lat, lon]: [number, number]) => {
  if (lat && lon) {
    try {
      const { data } = await AddressAPI.getAddress({ lat, lon });
      if (data?.data?.length) {
        return data.data;
      }
    } catch (error) {
      ILSShowMessage(GET_COORD_NOTIFY.GET_ADDRESS_ERROR);
    }
  }
};

const getGeocodeAddress = async (address: string) => {
  if (address) {
    try {
      const { data: suggestionsData } = await AddressAPI.suggestions({ q: address.replaceAll(',', EMPTY_STRING) });
      if (!isEmpty(suggestionsData?.data) && first(suggestionsData?.data)?.value) {
        const { data } = await AddressAPI.getCoordinates({ address: first(suggestionsData.data)!.value });
        return data.data;
      }
    } catch (error) {
      ILSShowMessage(GET_COORD_NOTIFY.GET_COORDINATES_ERROR);
    }
  }
};
