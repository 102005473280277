import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { updateTripRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState, PaginationType } from '@modules/orders-and-trips/types/general';
import { TripResponse } from '@modules/orders-and-trips/types/trip';
import { tripsTodata } from '@modules/orders-and-trips/decorators/trips-to-data';
import { IndexedArray } from '@common/types';
import { deepEqual } from 'fast-equals';

export const updateTripReducer = createReducer(initialState, {
  [updateTripRoutine.REQUEST]: (state: InitialState) => {
    return state;
  },
  [updateTripRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: {
        data: IndexedArray<TripResponse>;
        pager: Required<PaginationType>;
      };
      tripUpdateRequest?: boolean;
      tripID?: number;
    }>
  ) => {
    const { data, tripID } = action.payload;
    if (tripID) {
      const newTrip = tripsTodata(data?.data ?? {});
      if (!deepEqual(state.trips.data[tripID], newTrip[tripID])) {
        state.trips.data = {
          ...state.trips.data,
          ...newTrip
        };
      }
    }
    return state;
  },
  [updateTripRoutine.FAILURE]: onFailure,
  [updateTripRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    return state;
  }
});
