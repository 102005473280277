import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { handleScroll } from '@common/utils/general/scroll-to-element';
import { CardTab } from '@modules/tender-platform/constants';
import { ILSDivider, ILSMenu, ILSSkeleton } from '@components/index';
import { ILSTypographyTitle } from '@components/general/typography';
import { TenderPlatformMenuActionsContainer } from '@modules/tender-platform/containers/tender-card/menu/actions';
import { useSelector } from 'react-redux';
import { currentTenderStatusConfigSelector, fetchSelector } from '@modules/tender-platform/selectors';
import { mapTenderMenu } from '@modules/tender-platform/utils/helpers/tender-menu';
import { isEmpty } from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';

interface IProps {
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
  menu: Array<CardTab>;
  setMenu: Dispatch<SetStateAction<Array<CardTab>>>;
  isTendersCreatingFromPlanner: boolean;
}

const FORM_TABLE_HEADER_HEIGHT = 35 * 1.5;

export const ILSTenderCardMenu: FC<IProps> = ({ menu, setMenu, dataFiles, isTendersCreatingFromPlanner }) => {
  const statusConfig = useSelector(currentTenderStatusConfigSelector);
  const isFetching = useSelector(fetchSelector);
  const menuSettings = {
    align: {
      top: 0,
      left: 0,
      topOffset: isTendersCreatingFromPlanner ? FORM_TABLE_HEADER_HEIGHT : 0,
      leftOffset: 0
    }
  };

  return (
    <aside className={'tender-platform-menu menu-fixed'}>
      <ILSSkeleton active loading={isFetching}>
        {!isEmpty(menu) && (
          <ILSMenu
            className={'tender-platform-menu-navigation'}
            onClick={({ key }) => handleScroll(`#${key}`, menuSettings)}
            items={mapTenderMenu(menu)}
          />
        )}
      </ILSSkeleton>
      <ILSDivider children={<ILSTypographyTitle level={5} children={statusConfig?.Text ?? 'Новое'} />} />
      <TenderPlatformMenuActionsContainer dataFiles={dataFiles} setMenu={setMenu} />
    </aside>
  );
};
