import { useInterval } from '@common/hooks/use-interval';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringMetricsRoutine } from '../actions';
import { UPDATE_METRICS_INTERVAL } from '../constants';
import { monitoringDateSelector, monitoringMetricsSelector } from '../selectors';
import { tracksSelector } from '@modules/monitoring/children/event-details/selectors';
import { useEffect } from 'react';
import { checkDaysDiff } from '../children/dashboard/utils/check-days-diff';

// Хук для загрузки данных метрик
export const useLoadMetrics = () => {
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);
  const { lastUpdate } = useAppSelector(monitoringMetricsSelector);
  const { selectedVehicleId } = useAppSelector(tracksSelector);
  const dispatch = useAppDispatch();
  const loadMetrics = () => {
    if (
      endDate &&
      startDate &&
      checkDaysDiff(startDate, endDate) &&
      (!lastUpdate || lastUpdate + UPDATE_METRICS_INTERVAL < new Date().getTime())
    ) {
      dispatch(monitoringMetricsRoutine({ from: startDate, till: endDate, selectedVehicleId }));
    }
  };
  useEffect(() => {
    if (endDate && startDate && checkDaysDiff(startDate, endDate)) {
      dispatch(
        monitoringMetricsRoutine({
          from: startDate,
          till: endDate
        })
      );
    }
  }, [startDate, endDate]);
  useInterval(loadMetrics, UPDATE_METRICS_INTERVAL, [startDate, endDate, lastUpdate]);
};

