import '../styles.less';
import { FC } from 'react';
import { Reference, TableRecord } from '@common/types';
import { ILSModal } from '@common/components/feedback/modal';
import { ILSButton } from '@common/components';

export interface IILSWindowTableProps {
  visible: boolean;
  reference: Reference<TableRecord>;

  handleCloseReferenceTable(): void;
}

export const ILSReferenceTable: FC<IILSWindowTableProps> = ({ visible, reference, handleCloseReferenceTable }) => {
  const Component = reference.component;

  return (
    <ILSModal
      onCancel={handleCloseReferenceTable}
      footer={<ILSButton onClick={handleCloseReferenceTable} type={'primary'} children={'Закрыть'} />}
      className="table-modal"
      title={reference.title}
      visible={Component && visible}
    >
      {Component && <Component reference={reference} />}
    </ILSModal>
  );
};
