import { CellType, DateFormat, TableColumnName, TableConfig } from '@common/types';
import { DELIVERY_DAY_LIMIT_OPTIONS, EXCLUSIVE_ORDER_OPTIONS, NEW_ORDER, PLACE_IN_TRIP_OPTIONS } from '@common/models/order/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { CELL_FLOAT_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const ORDER_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  reloading: true,
  multiEditing: true,
  hideClearButtons: false,
  adding: NEW_ORDER,
  columns: [
    ID_COLUMN,
    {
      title: 'Код',
      dataIndex: TableColumnName.OrderCode,
      key: TableColumnName.OrderCode,
      editable: true,
      active: false,
      type: CellType.Input,
      ellipsis: true
    },
    {
      title: 'Название',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true
    },
    {
      title: 'Точка загрузки',
      dataIndex: TableColumnName.SourceID,
      key: TableColumnName.SourceID,
      editable: true,
      active: true,
      type: CellType.CascaderAPI,
      showParent: false
    },
    {
      title: 'Точка разгрузки',
      dataIndex: TableColumnName.TargetID,
      key: TableColumnName.TargetID,
      editable: true,
      active: true,
      type: CellType.CascaderAPI,
      showParent: false
    },
    {
      title: 'Зона доставки',
      dataIndex: TableColumnName.TargetZoneID,
      key: TableColumnName.TargetZoneID,
      editable: false,
      active: true,
      type: CellType.SelectAPI,
      invisible: true
    },
    {
      title: 'Адрес погрузки',
      dataIndex: 'SourceAddress',
      key: 'SourceAddress',
      editable: false,
      active: false,
      type: CellType.String
    },
    {
      title: 'Адрес разгрузки',
      dataIndex: TableColumnName.TargetAddress,
      key: TableColumnName.TargetAddress,
      editable: false,
      active: true,
      type: CellType.String
    },
    {
      title: 'Начало погрузки',
      dataIndex: TableColumnName.LoadStart,
      key: TableColumnName.LoadStart,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Конец погрузки',
      dataIndex: TableColumnName.LoadEnd,
      key: TableColumnName.LoadEnd,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Начало разгрузки',
      dataIndex: TableColumnName.UnloadStart,
      key: TableColumnName.UnloadStart,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Конец разгрузки',
      dataIndex: TableColumnName.UnloadEnd,
      key: TableColumnName.UnloadEnd,
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      ellipsis: true,
      isFixedColumn: true
    },
    {
      title: 'Режим работы',
      dataIndex: TableColumnName.WorktimeID,
      key: TableColumnName.WorktimeID,
      editable: true,
      active: false,
      type: CellType.SelectAPI
    },
    {
      title: 'Грузы',
      dataIndex: TableColumnName.CargoID,
      key: TableColumnName.CargoID,
      editable: true,
      active: true,
      type: CellType.SelectMultipleApi,
      closeSelectOnScroll: true,
      showSearch: true,
      update: true
    },
    {
      title: 'Специфики',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi
    },
    {
      title: 'Доставить в/до',
      dataIndex: TableColumnName.DeliveryDayLimit,
      key: TableColumnName.DeliveryDayLimit,
      editable: true,
      active: false,
      type: CellType.Select,
      options: DELIVERY_DAY_LIMIT_OPTIONS,
      fieldNames: BASE_FIELD_NAMES
    },
    {
      title: 'Дата погрузки',
      dataIndex: TableColumnName.LoadDate,
      key: TableColumnName.LoadDate,
      editable: true,
      active: true,
      type: CellType.Date,
      maxField: TableColumnName.UnloadDate,
      dateParseFormat: DateFormat.YYYYMMDDDash
    },
    {
      title: 'Дата разгрузки',
      dataIndex: TableColumnName.UnloadDate,
      key: TableColumnName.UnloadDate,
      editable: true,
      active: true,
      type: CellType.Date,
      minField: TableColumnName.LoadDate,
      dateParseFormat: DateFormat.YYYYMMDDDash
    },
    {
      title: 'Комментарий',
      dataIndex: TableColumnName.Comment,
      key: TableColumnName.Comment,
      editable: true,
      active: false,
      type: CellType.Input,
      ellipsis: true
    },
    {
      title: 'Описание груза',
      dataIndex: TableColumnName.CargoDescription,
      key: TableColumnName.CargoDescription,
      editable: false,
      active: false,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Описание',
      dataIndex: TableColumnName.Description,
      key: TableColumnName.Description,
      editable: false,
      active: false,
      type: CellType.String,
      ellipsis: true
    },
    {
      title: 'Отдельно',
      dataIndex: TableColumnName.ExclusiveOrder,
      key: TableColumnName.ExclusiveOrder,
      editable: true,
      active: false,
      type: CellType.Select,
      options: EXCLUSIVE_ORDER_OPTIONS,
      fieldNames: BASE_FIELD_NAMES
    },
    {
      title: 'Порядок',
      dataIndex: TableColumnName.PlaceInTrip,
      key: TableColumnName.PlaceInTrip,
      active: false,
      editable: true,
      type: CellType.Select,
      fieldNames: BASE_FIELD_NAMES,
      options: PLACE_IN_TRIP_OPTIONS
    },
    {
      title: 'Без опоздания',
      dataIndex: TableColumnName.DeniedLate,
      key: TableColumnName.DeniedLate,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Контакт на точке загрузки',
      dataIndex: TableColumnName.SourceContactID,
      key: TableColumnName.SourceContactID,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi
    },
    {
      title: 'Контакт на точке разгрузки',
      dataIndex: TableColumnName.TargetContactID,
      key: TableColumnName.TargetContactID,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi
    },
    {
      title: 'Заказчик транспорта',
      dataIndex: TableColumnName.Organization,
      key: TableColumnName.Organization,
      active: false,
      editable: true,
      type: CellType.Input,
      ellipsis: true
    },
    {
      title: 'Группа заявок',
      dataIndex: TableColumnName.OrderSeries,
      key: TableColumnName.OrderSeries,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Гидроборт',
      dataIndex: TableColumnName.RequireTailLift,
      key: TableColumnName.RequireTailLift,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Роклы',
      dataIndex: TableColumnName.RequirePalletTruck,
      key: TableColumnName.RequirePalletTruck,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Загрузчики',
      dataIndex: TableColumnName.RequireLoaders,
      key: TableColumnName.RequireLoaders,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Разгрузчики',
      dataIndex: TableColumnName.RequireUnloaders,
      key: TableColumnName.RequireUnloaders,
      active: false,
      editable: true,
      type: CellType.Bool,
      ellipsis: true
    },
    {
      title: 'Доступно с',
      dataIndex: TableColumnName.AvailableFrom,
      key: TableColumnName.AvailableFrom,
      active: false,
      editable: true,
      type: CellType.TimeDouble,
      ellipsis: true
    },
    {
      title: 'Значение 1',
      dataIndex: TableColumnName.Value1,
      key: TableColumnName.Value1,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Значение 2',
      dataIndex: TableColumnName.Value2,
      key: TableColumnName.Value2,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Значение 3',
      dataIndex: TableColumnName.Value3,
      key: TableColumnName.Value3,
      active: false,
      editable: true,
      ellipsis: true,
      type: CellType.Float,
      validationParameters: CELL_FLOAT_VALIDATION_PARAMETERS
    },
    {
      title: 'Превыш.нач.разгрузки',
      dataIndex: TableColumnName.UnloadStartOverload,
      key: TableColumnName.UnloadStartOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Превыш.конц.разгрузки',
      dataIndex: TableColumnName.UnloadEndOverload,
      key: TableColumnName.UnloadEndOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Превыш.нач.погрузки',
      dataIndex: TableColumnName.LoadStartOverload,
      key: TableColumnName.LoadStartOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Превыш.конц.погрузки',
      dataIndex: TableColumnName.LoadEndOverload,
      key: TableColumnName.LoadEndOverload,
      editable: false,
      active: false,
      type: CellType.TimeDouble
    },
    {
      title: 'Комментарий (водитель)',
      dataIndex: TableColumnName.CommentDriver,
      key: TableColumnName.CommentDriver,
      editable: false,
      active: false,
      type: CellType.String
    },
    {
      title: 'Регулярный',
      dataIndex: TableColumnName.Queue,
      key: TableColumnName.Queue,
      editable: false,
      active: false,
      type: CellType.Input
    }
  ]
};
