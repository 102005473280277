import { Option } from '@common/types';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { isArray, isEmpty } from 'lodash';
import { PRIMARY_VEHICLE_TYPES } from '@components/common/forms/vehicle/constants/options';

export const vehicleTypesToForm = (vehicleTypes: VehicleType | null) => {
  if (isEmpty(vehicleTypes) || isArray(vehicleTypes) || !vehicleTypes) return [];
  return Object.values(vehicleTypes).reduce(
    (result, vehicleType) => {
      const option = { value: String(vehicleType.ID), label: vehicleType.Name };
      if (PRIMARY_VEHICLE_TYPES.includes(vehicleType.Name)) {
        result[0].options.push(option);
      } else {
        result[1].options.push(option);
      }
      return result;
    },
    [
      {
        label: 'Часто используемые',
        options: [] as Option[]
      },
      {
        label: 'Прочие',
        options: [] as Option[]
      }
    ]
  );
};

export type VehicleTypesOptions = ReturnType<typeof vehicleTypesToForm>;
