import { CellType, TableConfig } from '@common/types';
import { PlacementType } from '@common/types/components';

export const ERROR_TABLE_CONFIG: TableConfig = {
  reloading: false,
  deleting: false,
  selecting: false,
  copying: false,
  hideClearButtons: true,
  mouseLeaveDelay: 0,
  popoverPosition: PlacementType.leftTop,
  columns: [
    {
      title: 'Валидация',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input
    },
    {
      title: 'Ошибки',
      titleIcon: 'error-notification',
      dataIndex: 'error',
      key: 'error',
      active: true,
      type: CellType.Alert,
      width: 100
    },
    {
      title: 'Инфо',
      titleIcon: 'info',
      dataIndex: 'info',
      key: 'Info',
      active: true,
      type: CellType.Alert,
      width: 100
    },
    {
      title: 'Предупреждения',
      titleIcon: 'warning',
      dataIndex: 'warning',
      key: 'Warning',
      active: true,
      type: CellType.Alert,
      width: 150
    },
    {
      title: 'Критические',
      titleIcon: 'bad',
      dataIndex: 'bad',
      key: 'Warning',
      active: true,
      type: CellType.Alert,
      width: 130
    }
  ]
};
