import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import { Project } from '@common/types';
import { planningGetProjectCurrentRoutine } from '@modules/planning/children/by-date/actions';
import { projectSelector } from '@modules/planning/children/by-date/selectors/project';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { ArrowsAltOutlined, SyncOutlined } from '@ant-design/icons/lib/icons';
import { projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';

interface IProps {
  projectList: Array<Project> | null;

  handleShow(): void;
}

export const ILSPlanningActionButtonsContainer: FC<IProps> = ({ projectList, handleShow }) => {
  const dispatch = useDispatch();
  const projectID = useSelector(projectSelector)?.ID ?? useSelector(activeProjectIdSelector);
  const projectIsFetching = useSelector(projectIsFetchingSelector);

  return (
    <>
      {projectID && (
        <>
          {projectList && projectList.length > 1 && (
            <ILSButtonTooltip
              disabled={projectIsFetching}
              data-testid={'choose-project-button'}
              icon={<ArrowsAltOutlined className={'ils-svg primary'} />}
              onClick={handleShow}
              title={'Выбрать проект'}
            />
          )}
          <ILSButtonTooltip
            disabled={projectIsFetching}
            icon={<SyncOutlined className={'ils-svg primary'} />}
            onClick={() => dispatch(planningGetProjectCurrentRoutine({ projectID }))}
            title={'Обновить проект'}
          />
        </>
      )}
    </>
  );
};
