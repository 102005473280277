import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningUpdateProjectRoutine } from '../actions';
import { initialState } from '@modules/planning/store';
import { onFailure } from '@common/utils/helpers/reducers';
import { ProjectModel } from '@common/types';

export const updateProjectsReducer = createReducer(initialState, {
  [planningUpdateProjectRoutine.REQUEST]: (state) => {
    state.isSaving = true;
    return state;
  },
  [planningUpdateProjectRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      project: ProjectModel;
      ID: number;
    }>
  ) => {
    const { project, ID } = action.payload;
    if (ID in state.projects) {
      state.projects[ID] = project;
    }
    return state;
  },
  [planningUpdateProjectRoutine.FAILURE]: onFailure,
  [planningUpdateProjectRoutine.FULFILL]: (state) => {
    state.error = [];
    state.isSaving = false;
    return state;
  }
});
