import '../styles.less';
import { ILSSpin } from '@components/index';
import { SettingsListContainer } from '@modules/settings/containers/list';
import { SettingsActions } from '@modules/settings/components/actions/actions';
import { useUserAppSettings } from '@modules/settings/utils/hooks/use-user-app-settings';
import { InfoContainer } from '@modules/settings/containers/info-container.tsx/info-container';

export const ILSSettings = () => {
  const { isFetching } = useUserAppSettings();

  return (
    <ILSSpin className="w-100" spinning={Boolean(isFetching)}>
      <section className={'settings'}>
        <InfoContainer />
        <SettingsListContainer />
        <SettingsActions />
      </section>
    </ILSSpin>
  );
};

