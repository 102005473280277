import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { RouteTestStore } from '../types';
import { roadGraphGetShortestRoutine } from '@modules/road-graph/route-test/actions';
import { RoadData } from '@modules/road-graph/editor/types';
import { PayloadActionError } from '@common/types';

const roadGraphGetShortest = createReducer(initialState, {
  [roadGraphGetShortestRoutine.REQUEST]: (state: RouteTestStore) => {
    state.trackData = {
      startTime: Date.now(),
      endTime: Date.now(),
      routes: undefined,
      waypoints: undefined
    };
    state.isFetching = true;
    return state;
  },
  [roadGraphGetShortestRoutine.SUCCESS]: (
    state: RouteTestStore,
    action: PayloadAction<{
      data: RoadData;
    }>
  ) => {
    const { data } = action.payload;
    state.trackData = {
      ...state.trackData,
      ...data,
      endTime: Date.now()
    };
    state.isFetching = false;
    return state;
  },
  [roadGraphGetShortestRoutine.FAILURE]: (state: RouteTestStore, action: PayloadActionError) => {
    const { error } = action.payload;
    state.isFetching = false;
    state.error = error;
    return state;
  },
  [roadGraphGetShortestRoutine.FULFILL]: (state: RouteTestStore) => {
    state.error = [];
    return state;
  }
});

export default roadGraphGetShortest;
