import { initialState } from '@modules/planning/children/by-date/store';
import { IPlanningByDateStore } from '@modules/planning/children/by-date/types';
import { TPersistTransform } from '@core/persist/transforms';

export const persistTransform: TPersistTransform<IPlanningByDateStore> = {
  initialState,
  // transform state being persisted
  onPersist: (inboundState?: IPlanningByDateStore) => {
    return {
      project: inboundState?.project?.ID ? { ID: inboundState.project?.ID } : null,
      validationData: inboundState?.validationData,
      planningConfigByPlan: inboundState?.planningConfigByPlan,
      params: inboundState?.params,
      groupSwitchers: inboundState?.groupSwitchers
    };
  }
};

