import { isEmpty, isNil, isObject } from 'lodash';
import { findCurrentActiveTracker } from '@common/utils/tracker/find-actual-tracker';
import { VehicleTracker } from '@common/types';
import { MonitoringServedDataModel } from '@common/types/dictionaries/monitoring-vehicle';

export const UNDEFINED_TRACKER_IMEI = 'Нет';

export type GetTrackerDataForTableArguments = {
  vehicleTrackers?: VehicleTracker[];
  isCurrentDay?: boolean;
  vehicle: MonitoringServedDataModel;
};

export const getTrackerDataForTable = ({ isCurrentDay, vehicleTrackers, vehicle }: GetTrackerDataForTableArguments) => {
  /** Если хотим получить данные на текущую дату, то отображать только активные трекеры на текущий момент */
  if (isCurrentDay) {
    /** Если есть список трекеров, то ищем трекеры в нём */
    if (!isEmpty(vehicleTrackers) && !isNil(vehicleTrackers)) {
      return findCurrentActiveTracker(Object.values(vehicleTrackers), vehicle.ID)?.TrackerIMEI?.toString() || UNDEFINED_TRACKER_IMEI;
      /** Иначе берем трекер действительный на текущую дату из трекеров привязанных к автомобилю */
    } else if (!isEmpty(vehicle.VehicleTracker) && isObject(vehicle.VehicleTracker) && !isNil(vehicle.VehicleTracker)) {
      return findCurrentActiveTracker(Object.values(vehicle.VehicleTracker), vehicle.ID)?.TrackerIMEI?.toString() || UNDEFINED_TRACKER_IMEI;
      /** Если нет списка трекеров и привязанных к машине трекеров - пишем "Нет" */
    } else {
      return UNDEFINED_TRACKER_IMEI;
    }
    /** Если хотим получить данные не на текущую дату, то берем все трекеры из трекеров привязанных к автомобилю, и выводим их строкой */
  } else {
    return (
      (vehicle?.VehicleTracker &&
        Object.values(vehicle.VehicleTracker)
          .map((tracker) => tracker.TrackerIMEI)
          .join('; ')) ||
      /** В случае, если к машине не было привязано трекеров выводим "Нет" */
      UNDEFINED_TRACKER_IMEI
    );
  }
};
