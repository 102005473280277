import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorCreateConfigRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';

// Создаёт конфигурацию эмуляции, задаёт используемый план ID (planData.loadedPlanID) и,
// если задана, то устанавливает скорость эмуляции(emulationSpeed)

const emulatorCreateConfigReducer = createReducer(initialState, {
  [emulatorCreateConfigRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.planData.isFetching = true;
    return state;
  },
  [emulatorCreateConfigRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      planID: number | string;
      emulationSpeed?: number;
    }>
  ) => {
    const { planID, emulationSpeed } = action.payload;
    state.planData.isFetching = false;
    state.planData.loadedPlanID = planID;
    if (emulationSpeed) {
      state.emulatorStatus.configs.EmulationSpeed = emulationSpeed;
    }

    return state;
  },
  [emulatorCreateConfigRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  },
  [emulatorCreateConfigRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  }
});

export default emulatorCreateConfigReducer;
