import React, { FC } from 'react';
import { TreeSelect, TreeSelectProps } from 'antd';
import { TreeNodeProps } from './types';

const { TreeNode } = TreeSelect;

export const ILSTreeSelect: FC<TreeSelectProps> = (props) => {
  return <TreeSelect {...props} />;
};

export const ILSTreeNode: FC<TreeNodeProps> = (props) => {
  return <TreeNode {...props} />;
};
