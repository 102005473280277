import { CardTab } from '@modules/tender-platform/constants';
import { Status, Tender } from '@modules/tender-platform/types';
import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { TenderPlatformActionsModalContainer } from '@modules/tender-platform/containers/tender-card/menu/modal';
import { useTenderPlannerOperationModal } from '@modules/tender-platform/utils/hooks/use-tender-planner-operation-modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { TenderPlatformActionsButtonsContainer } from '@modules/tender-platform/containers/tender-card/menu/buttons';
import { tenderSetStatusRoutine } from '@modules/tender-platform/children/tender-own/actions';

interface IProps {
  dataFiles?: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
  setMenu: Dispatch<SetStateAction<Array<CardTab>>>;
}

export const TenderPlatformMenuActionsContainer: FC<IProps> = ({ setMenu, dataFiles }) => {
  const dispatch = useDispatch();
  const { visible, handleModal, operation, handleShow } = useTenderPlannerOperationModal();

  const handleTenderSetStatus = (tenderID: Tender['ID'], statusID: Status['ID']) =>
    dispatch(
      tenderSetStatusRoutine({
        tenderID,
        statusID
      })
    );

  return (
    <div className="tender-platform-card-buttons menu-buttons">
      <TenderPlatformActionsButtonsContainer handleModal={handleModal} handleTenderSetStatus={handleTenderSetStatus} />
      <TenderPlatformActionsModalContainer
        setMenu={setMenu}
        dataFiles={dataFiles}
        visible={visible}
        handleTenderSetStatus={handleTenderSetStatus}
        onCancel={handleShow}
        operation={operation}
      />
    </div>
  );
};
