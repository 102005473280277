import { FC } from 'react';
import { ILSFormItem, ILSInputNumber } from '@common/components';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import cn from 'classnames';

export const TenderPlatformDistanceComponent: FC = () => {
  const className = cn(TenderFormField.Distance, 'no-margin tender-form-fieldset-item');

  return (
    <ILSFormItem className={className} label={LotFormFieldLabel.Distance} name={TenderFormField.Distance}>
      <ILSInputNumber disabled addonAfter={'км'} placeholder={'Расчет дистанции...'} />
    </ILSFormItem>
  );
};
