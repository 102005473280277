import { pick } from 'lodash';
import { int2color } from '@common/decorators/color';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { Zone } from '@common/types';
import { splitStringWeekdays } from '@common/utils/helpers/string/split-string-weekdays';
import { createZoneEnums } from '@common/components/data-display/table/utils/helpers';

export const zone2table = (zones: Array<Zone>) => {
  return createArrayFromIndexedArray(zones).map((zone) => {
    const zoneEnums = createZoneEnums(zones);
    const FriendZoneID = zone.FriendZoneID?.map(Number) ?? [];
    return {
      ...zone,
      key: zone.ID,
      Color: int2color(String(zone.Color)),
      WorkSchedule: splitStringWeekdays(zone.WorkSchedule),
      FriendZoneID,
      FriendZoneEnum: pick(zoneEnums, FriendZoneID)
    };
  });
};
