import { Coordinates, MarkerType } from '@common/types';
import { DecoratedTrips } from '@modules/orders-and-trips/types/trip';
import { RoutePointsRequest } from '@modules/orders-and-trips/types/map';
import { isEmpty } from 'lodash';

/** На карте необходимо отображать координаты рейса, а в заголовке карты - города рейса. */
export const getTripMapDetails = (trip?: DecoratedTrips[number]) => {
  const mapPoints = trip?.actions.reduce(
    (res, action) => {
      action.city && res.cities.push(action.city);
      if (!isEmpty(action.latLon)) {
        res.coordinates.push({ latLon: action.latLon, type: action.actionType ? MarkerType.Load : MarkerType.Client });
        const coords = {
          Latitude: action.latLon.lat,
          Longitude: action.latLon.lon
        };
        const direction = action.actionType ? 'From' : 'To';
        res.route[res.routeIndex] = {
          ...res.route[res.routeIndex],
          [direction]: coords
        };
        if (!action.actionType) {
          res.routeIndex++;
        }
      }
      return res;
    },
    { cities: [], coordinates: [], route: [], routeIndex: 0 } as {
      cities: Array<string>;
      coordinates: Array<{ latLon: Coordinates; type: MarkerType }>;
      route: RoutePointsRequest;
      routeIndex: number;
    }
  ) ?? { cities: [], coordinates: [], route: [] };
  return { mapPoints };
};

