import { FC } from 'react';
import { ILSButton, ILSModal, ILSTypographyText } from '@common/components';
import { TenderButtonText } from '@modules/tender-platform/constants/content';
import { ILSSVG } from '@components/custom/icons/svg';
import { useModal } from '@common/hooks/components/use-modal';
import { ILSTenderOfferSubmitButton } from '@modules/tender-platform/children/tender-search/components/offer/submit-button';
import { ButtonAction } from '@components/general/buttons';
import { TenderPlatformTakeWithMinPriceButton } from '@modules/tender-platform/children/tender-search/components/auction/take-with-min-price-button';
import { AuctionFormValues } from '@modules/tender-platform/children/tender-search/constants/offer';
import { currentTenderLotSelector } from '@modules/tender-platform/selectors';
import { useSelector } from 'react-redux';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';

interface IProps {
  isFetching: boolean;
  takeWithMin?: boolean;

  onFinish?(values: AuctionFormValues): void;
}

export const ILSTenderOfferModal: FC<IProps> = ({ onFinish, children, takeWithMin = false }) => {
  const { handleShow, visible } = useModal();
  const { MinPrice } = useSelector(currentTenderLotSelector);

  const MODAL_CONTENT = [
    { text: 'Мною внимательно прочитаны все условия запроса, описанные в лоте.' },
    {
      text: 'Я изучил прикрепленную документацию и согласен подписать (в случае выбора меня в качестве победителя) договор оказания услуг на указанных в ней условиях без изменений.'
    },
    {
      text: 'Принимая правила проведения данного запроса ценовых предложений, Перевозчик гарантирует, что действует от имени юридического лица, которое зарегистрировано в системе. Все действия, а именно ставки, загруженные документы и прочее, направлены исключительно для формирования предложения по данному целевому запросу.'
    }
  ];

  return (
    <>
      <TenderPlatformMenuButton
        onClick={handleShow}
        children={children ?? TenderButtonText.AddOffer}
        disabled={Boolean(children && !MinPrice)}
        type={children ? 'default' : 'primary'}
      />
      <ILSModal
        onCancel={handleShow}
        className={'tender-offer-modal'}
        footer={
          <>
            <ILSButton action={ButtonAction.Cancel} onClick={handleShow} />
            {takeWithMin ? (
              <TenderPlatformTakeWithMinPriceButton onFinish={onFinish} />
            ) : (
              <ILSTenderOfferSubmitButton handleShow={handleShow} isModal />
            )}
          </>
        }
        title={'Согласие на принятие участия в тендерной процедуре'}
        visible={visible}
      >
        {MODAL_CONTENT.map(({ text }) => {
          return (
            <li key={text.length} className={'tender-offer-modal-item'}>
              <ILSSVG icon={'bool-true'} />
              <ILSTypographyText children={text} />
            </li>
          );
        })}
      </ILSModal>
    </>
  );
};
