import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { initialState } from '@modules/settings/store';
import { settingsGetRoutine } from '@modules/settings/actions';
import { ChangeSettingsPayload } from '@modules/settings/types/reducers';
import { getValuesFromSettingsObjectBySection } from '../utils/get-values-from-settings-object';

export const settingsGetReducer = createReducer(initialState, {
  [settingsGetRoutine.REQUEST]: onRequest,
  [settingsGetRoutine.SUCCESS]: (state: typeof initialState, action: ChangeSettingsPayload) => {
    const { value, diffSettings } = action.payload;
    state.data = value;
    state.values = getValuesFromSettingsObjectBySection(value);
    if (diffSettings) {
      state.diffSettings = diffSettings;
    }
    return state;
  },
  [settingsGetRoutine.FAILURE]: onFailure,
  [settingsGetRoutine.FULFILL]: onFulfill
});
