import { RouteTestStore } from '@modules/road-graph/route-test/types';

const initialState: RouteTestStore = {
  isFetching: false,
  error: [],
  isSaving: false,
  success: false,
  trackData: {
    startTime: undefined,
    endTime: undefined,
    routes: undefined,
    waypoints: undefined
  },
  forbiddenZones: {
    data: [],
    lastUpdate: null
  }
};

export default initialState;
