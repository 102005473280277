import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Dictionary, TableRecord } from '@common/types';
import { monitoringCreateVehicleTrackerRoutine, monitoringGetTrackersRoutine } from '@modules/monitoring/actions';
import { useAppSelector } from '@core/hooks';
import { monitoringTrackersInfoSelector } from '@modules/monitoring/selectors/trackers';

export interface ChildTable {
  catalogDictionary?: Dictionary;
  filterRows?: any;
  renderFunc: (props: any) => JSX.Element;
}

// Компонент отвечающий за создание таблиц в модальном окне (Привязки трекеров к автомобилю и таблицу самих трекеров)
export const MonitoringChildTableContainer: FC<ChildTable> = ({ renderFunc, ...props }) => {
  const dispatch = useDispatch();
  const trackerInfo = useAppSelector(monitoringTrackersInfoSelector);
  const handleSave = (dictionary: Dictionary, record: TableRecord, values: any) => {
    if (Object.values(values)[0] === record[Object.keys(values)[0]]) return;
    const { ID } = record;

    const payload = { dictionary, values, id: ID };
    dispatch(monitoringCreateVehicleTrackerRoutine(payload));
  };
  const dictionaryLoad = (dictionary: Dictionary) => {
    if (dictionary === Dictionary.Tracker && !trackerInfo.isFetching && !trackerInfo.lastUpdate) {
      dispatch(monitoringGetTrackersRoutine());
    }
  };
  const handleCreate = (dictionary: Dictionary, values: any) => {
    const payload = {
      dictionary,
      values: [values]
    };

    dispatch(monitoringCreateVehicleTrackerRoutine(payload));
  };

  return <div className="position-relative catalog-item">{renderFunc({ ...props, handleSave, handleCreate, dictionaryLoad })}</div>;
};
