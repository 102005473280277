import { getInitialState } from '@common/utils/helpers/store/get-initial-state';
import { Module } from '@common/types';
import { ISchemeEditorState } from '@modules/planning/children/scheme-editor/types';

const initialSchemeEditorState: ISchemeEditorState = {
  maxScheme: null,
  currentScheme: {},
  currentSchemeDictionaries: [],
  currentSchemeKeysInDictionaries: {}
};

export const INITIAL_STATE = getInitialState<ISchemeEditorState>(initialSchemeEditorState, Module.SchemeEditor);
