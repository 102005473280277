import { ILSProgress } from '@common/components/feedback/progress';
import { FC } from 'react';

import { ILSEmulatorDateTime } from './date-time/date-time';
import ILSEmulationSpeedContainer from './../containers/emulation-speed/emulation-speed';
import { ILSActionButtonsContainer } from '../containers/action-buttons/action-buttons';

interface Props {
  startDateTime: string | undefined;
  currentDateTime: string | undefined;
  endDateTime: string | undefined;
  progress: number | undefined;
  createConfig: (emulationSpeed?: number) => void;
}

// Компонент для отображения элементов управления в Эмуляторе

export const ControlsComponent: FC<Props> = ({ startDateTime, currentDateTime, endDateTime, progress, createConfig }) => {
  return (
    <div className="ils-emulator-controls">
      <div className="ils-emulator-dates-wrapper">
        <ILSEmulatorDateTime dateTime={startDateTime} />
        <ILSEmulatorDateTime dateTime={currentDateTime} />
        <ILSEmulatorDateTime dateTime={endDateTime} />
      </div>
      <ILSProgress className="big" percent={progress ?? undefined} showInfo={false} />
      <ILSActionButtonsContainer />
      <ILSEmulationSpeedContainer createConfig={createConfig} />
    </div>
  );
};
