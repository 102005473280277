import { FC } from 'react';
import { useSelector } from 'react-redux';
import { optionsSelector } from '@modules/planning/children/scheme-editor/selectors/max-scheme';
import { SchemaEditorOptionsComponent } from '@modules/planning/children/scheme-editor/components/schemas-tab-panes/options';

export const SchemaEditorOptionsContainer: FC = () => {
  const options = useSelector(optionsSelector);

  return <SchemaEditorOptionsComponent options={options} />;
};
