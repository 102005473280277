import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringEventDetailsStore } from '../types/store';
import { selectOneVehicleRoutine } from '../actions';

// Устанавливает значения даннх для одного автомобиля
export const monitoringSelectOneVehicleReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [selectOneVehicleRoutine.SUCCESS]: (state, action: PayloadAction<{ vehicleID: number }>) => {
    const { vehicleID } = action.payload;
    state.VehicleTracks.selectedVehicleId = vehicleID;
    if (state.VehicleTracks.loadedVehicleId !== vehicleID && vehicleID) {
      state.VehicleTracks.loadedVehicleId = undefined;
      state.VehicleTracks.data = {};
      state.VehicleTracks.isFetching = false;
    }
    state.VehicleTracks.lastUpdate = 0;
    return state;
  }
});
