import { Dictionary, Plan } from '@common/types';
import { compareAsString } from '@common/utils';
import { generateUUID } from '@common/utils/general/uuid';
import { PlanningAPI } from '@modules/planning/api';
import {
  planningDoneRoutine,
  planningGetPlanEfficiencyRoutine,
  planningGetPlanRoutine
} from '@modules/planning/children/by-date/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { activePlanIDSelector } from '../../selectors/active-plan';
import { projectIDSelector } from '../../selectors/project';

function* planningDoneWorker(
  action: PayloadAction<{
    planID: number;
  }>
) {
  const { request, failure, success } = planningDoneRoutine;
  const { planID } = action.payload;
  const notifyKey = generateUUID();
  try {
    yield put(request({ notifyKey }));
    const {
      data: { data }
    } = yield call(PlanningAPI.donePlanning, { planID });
    const selectedID: Plan['ID'] = yield select(activePlanIDSelector);
    const projectID: number | undefined = yield select(projectIDSelector)

    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });
    yield put(success({ planID, message: data, planTotal }));
    yield put(planningGetPlanEfficiencyRoutine({ planID:[planID], projectID }));
    if (compareAsString(selectedID, planID)) {
      yield put(planningGetPlanRoutine({ ID: planID }));
    }
  } catch (error) {
    yield put(failure({ error, notifyKey }));
  }
}

export function* planningDoneWatcher() {
  yield takeEvery(planningDoneRoutine.trigger, planningDoneWorker);
}

