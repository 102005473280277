import { ILSMap } from '@core/containers/map';
import { MutableRefObject } from 'react';
import L from 'leaflet';

export const addLayersToMap = (
  Map: MutableRefObject<ILSMap | null>,
  layers: Array<L.Layer>,
  layerGroup: L.MarkerClusterGroup | L.LayerGroup | undefined
) => {
  if (Map.current && layers.length) {
    if (layerGroup) {
      if (typeof (layerGroup as L.MarkerClusterGroup)?.addLayers === 'function') {
        try {
          (layerGroup as L.MarkerClusterGroup).addLayers(layers);
        } catch (error) {
          layers.forEach((layer) => {
            layerGroup.addLayer(layer);
          });
          console.error(error);
        }
      } else {
        layers.forEach((layer) => {
          layerGroup.addLayer(layer);
        });
      }
    } else {
      layers.forEach((layer) => {
        Map.current?.addAnyLayer(layer);
      });
    }
  }
};
export const removeLayersToMap = (
  Map: MutableRefObject<ILSMap | null>,
  layers: Array<L.Layer>,
  layerGroup: L.MarkerClusterGroup | L.LayerGroup | undefined
) => {
  if (Map.current && layers.length) {
    if (layerGroup) {
      if (typeof (layerGroup as L.MarkerClusterGroup)?.addLayers === 'function') {
        try {
          (layerGroup as L.MarkerClusterGroup).removeLayers(layers);
        } catch (error) {
          layers.forEach((layer) => {
            layerGroup.removeLayer(layer);
          });
          console.error(error);
        }
      } else {
        layers.forEach((layer) => {
          layerGroup.removeLayer(layer);
        });
      }
    } else {
      layers.forEach((layer) => {
        Map.current?.deleteAnyLayer(layer);
      });
    }
  }
};

