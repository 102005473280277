import { Project, TimeStamp } from '@common/types';
import { catalogReadRoutine, catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { TODAY } from '@common/constants';
import { IDefaultState } from '@modules/catalog/types/store';
import { catalogFilterByProjectSelector } from '@modules/catalog/selectors/components/catalog-filter-project';
import { catalogDictionaryFiltersSelector, dictionaryLastUpdateSelector } from '@modules/catalog/selectors';
import { CatalogReadPayload } from '@modules/catalog/types/actions';
import { sortCatalogData } from '@modules/catalog/helpers/sort-catalog-data';
import { getFocusRowForCatalogRead } from '@modules/catalog/helpers/get-focus-row-for-catalog-read';
import { head } from 'lodash';

export function* catalogReadWorker(action: CatalogReadPayload) {
  const {
    dictionary,
    ids,
    dictionaryFetching,
    showDeleted,
    withoutNotification,
    successMessage,
    childrenDictionary,
    uncheckProjectButton,
    rowIDToFocus
  } = action.payload;
  const { request, success, failure, fulfill, trigger } = catalogReadRoutine;
  const notifyKey = action.payload?.notifyKey || `create-dictionary-key-${dictionary}`;
  try {
    const lastUpdate: TimeStamp | null = yield select((state) => dictionaryLastUpdateSelector(state, dictionary));
    let byProject;
    if (!lastUpdate) {
      const { defaultChecked }: { defaultChecked: Project['ID'] | null } = yield select(catalogFilterByProjectSelector);
      byProject = defaultChecked;
    }
    const filterProjectDictionary: Pick<IDefaultState, 'showDeleted' | 'projectID'> = yield select((state) =>
      catalogDictionaryFiltersSelector(state, dictionary)
    );
    let projectID = byProject ?? action.payload.projectID ?? filterProjectDictionary.projectID;
    // Флаг uncheckProjectButton передается из catalogCreateWorker, чтобы после создания сбрасывался фильтр по проекту;
    if (uncheckProjectButton) {
      projectID = false;
    }

    yield put(request({ dictionary, ids, dictionaryFetching, showDeleted, projectID, withoutNotification, notifyKey }));

    const {
      data: { data: catalog }
    } = yield call(DictionaryAPI.read, {
      dictionary,
      ids,
      projectID,
      showDeleted: showDeleted ?? filterProjectDictionary.showDeleted
    });

    const data = sortCatalogData(catalog);

    yield put(
      success({
        data,
        dictionary,
        ids,
        lastUpdate: TODAY,
        withoutNotification: withoutNotification || childrenDictionary,
        notifyKey,
        notificationMessage: successMessage,
        projectID,
        showDeleted: showDeleted ?? filterProjectDictionary.showDeleted
      })
    );

    const focusKey = getFocusRowForCatalogRead({ rowIDToFocus, firstDataRowID: head(data)?.ID, dictionary });
    yield put(catalogSetFocusRowRoutine({ key: focusKey, dictionary }));
  } catch (error) {
    yield put(failure({ error, dictionary, withoutNotification }));
  } finally {
    if (childrenDictionary) {
      yield put(
        trigger({
          dictionary: childrenDictionary,
          notifyKey,
          withoutNotification,
          successMessage,
          projectID: uncheckProjectButton && null,
          uncheckProjectButton
        })
      );
    }
    yield put(fulfill({ dictionary, dictionaryFetching }));
  }
}

export function* catalogReadWatcher() {
  yield takeEvery(catalogReadRoutine.trigger, catalogReadWorker);
}
