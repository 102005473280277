import { CellType } from '@common/types';

export const getFocusRefRow = ({
  record,
  recordDataIndex,
  cellType
}: {
  record: any;
  recordDataIndex: string | undefined;
  cellType: CellType | undefined;
}) => {
  const recordFieldData = recordDataIndex && record?.[recordDataIndex];
  if (Array.isArray(recordFieldData)) {
    switch (cellType) {
      case CellType.CascaderAPI:
        return recordFieldData[1];
      default:
        return recordFieldData[0];
    }
  } else {
    return recordFieldData;
  }
};
