import { FC } from 'react';
import { ILSButton, ILSButtonTooltip } from '@common/components';
import { ButtonContent } from '@common/constants';
import { ITablePropsFunction, TableConfig, TableRecord } from '@common/types';
import { ButtonAction } from '@components/general/buttons';
import { isBoolean, isNil } from 'lodash';

interface IProps {
  adding: TableConfig['adding'];
  headerButtonsParams: TableConfig['headerButtonsParams'];
  handleCreate: ITablePropsFunction<TableRecord>['handleCreate'];
  isFetching?: boolean;
}

export const ILSTableButtonCreate: FC<IProps> = ({ isFetching, handleCreate, headerButtonsParams, adding }) => {
  const { add } = headerButtonsParams ?? { add: null };

  const handleAdd = () => {
    const newRow = isBoolean(adding) || isNil(adding) ? {} : adding;
    handleCreate?.(newRow);
  };

  if (add?.icon) {
    return (
      <ILSButtonTooltip
        className={add.className}
        icon={add.icon}
        onClick={handleAdd}
        title={add.iconText ?? ButtonContent.Add}
        data-testid="add-btn"
      />
    );
  }
  return (
    <ILSButton action={ButtonAction.Add} loading={isFetching} disabled={isFetching} type="primary" className="add" onClick={handleAdd} />
  );
};
