import { FC } from 'react';
import classnames from 'classnames';

export interface IProps {
  title: string;
  className?: string;
}

export const ILSTitle: FC<IProps> = ({ title, className, ...props }) => {
  return (
    <h1 {...props} className={classnames('ils-title', className)}>
      {title}
    </h1>
  );
};
