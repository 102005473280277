import { FC, useEffect } from 'react';
import { Dictionary, Key } from '@common/types';
import { Tariff } from '@modules/catalog/types/tariff';
import { TariffPanel } from '@modules/catalog/containers/tariff/tariff-container';
import { VehicleTariffs } from '@common/types/dictionaries/vehicle';
import { ILSCatalogTariffsList } from '@modules/catalog/components/tariff/list';
import { compareAsString } from '@common/utils';

export interface IILSCatalogTariffItem {
  activePanel: TariffPanel;
  activeItem: Tariff;
  items: VehicleTariffs;
  dictionary: Dictionary;
  isFetching: boolean;
  panelName: TariffPanel;

  changeActiveItem(dictionary: Dictionary, item: any): void;

  handleSave(dictionary: Dictionary, name: string, formula: any, id: Key): void;

  handleDelete(dictionary: Dictionary, id: Key): void;
}

export const ILSCatalogTariffItem: FC<IILSCatalogTariffItem> = ({
  activePanel,
  activeItem,
  items,
  changeActiveItem,
  dictionary,
  isFetching,
  panelName,
  handleDelete,
  handleSave
}) => {
  //статус выполнения операций
  useEffect(() => {
    const item = items.find((item: any) => compareAsString(activeItem?.ID, item.ID));
    changeActiveItem(dictionary, item);
  }, [items, dictionary]);

  return (
    <ILSCatalogTariffsList
      handleSave={handleSave}
      handleDelete={handleDelete}
      isFetching={isFetching}
      items={items}
      dictionary={dictionary}
      checking={activePanel === panelName ? 1 : 0}
      activeItem={activeItem}
      changeActiveItem={changeActiveItem}
    />
  );
};
