import { call, put, takeLatest } from 'redux-saga/effects';
import { registerActions, registerRequestActions } from '../actions';
import { Auth } from '../api/auth';
import { RegisterAction } from '../types/saga';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import history from '@common/utils/history';
import { ModulePath } from '@common/types';

function* registerRequestWorker(action: RegisterAction) {
  const { request, failure, success, fulfill } = registerRequestActions;
  const { values } = action.payload;

  try {
    yield put(request());

    const { data } = yield call(Auth.registerRequest, {
      values
    });
    yield put(success({ message: data }));
    yield call(history.push, ModulePath.Login);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* registerRequestWatcher() {
  yield takeLatest(registerRequestActions.trigger, registerRequestWorker);
}

function* registerWorker(action: RegisterAction) {
  const { token } = action.payload;
  const { request, failure, success, fulfill } = registerActions;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Auth.register, { token });

    yield put(success({ message: data }));
    yield call(history.push, ModulePath.Login);
  } catch (error) {
    yield put(failure({ error: createArrayFromIndexedArray(error as Record<string, string>) }));
  } finally {
    yield put(fulfill());
  }
}

export function* registerWatcher() {
  yield takeLatest(registerActions.trigger, registerWorker);
}
