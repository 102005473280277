import history from '@common/utils/history';
import { InjectAsyncStore } from '@core/HOC/InjectAsyncStore';
import { default as run } from '@core/run';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { YMInitializer } from 'react-yandex-metrika';
import * as Sentry from "@sentry/react";

export let ILSAppRegistry: any;
let versionProductCheckInterval: NodeJS.Timer | null = null;

if (process.env.REACT_APP_USE_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

run(versionProductCheckInterval).then((result) => {
  ILSAppRegistry = result;
  ReactDOM.render(
    <StrictMode>
      <Provider store={ILSAppRegistry.store}>
        <Router history={history}>
          <InjectAsyncStore Wrapper={result.Wrapper} onModuleChange={result.onModuleChange}>
            {/* Инициализация счетчика Яндекс.Метрики,
                если есть соответствующая переменная окружения */
              process.env.REACT_APP_USE_YM
              && <YMInitializer
                accounts={[94509395]}
                options={
                  {
                    defer: true,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                  }
                }
                version="2"
              />
            }
            {renderRoutes(ILSAppRegistry.routes, { history })}
          </InjectAsyncStore>
        </Router>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  );
});

