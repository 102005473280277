import { FC } from 'react';
import { ILSDivider } from '@common/components';
import { ILSTypographyTitle } from '@components/general/typography';

interface IProps {
  cardsPageTitle: string;
}

export const TenderPlatformMainTitleComponent: FC<IProps> = ({ cardsPageTitle }) => {
  return (
    <ILSDivider
      className={'tender-platform-divider horizontal-title'}
      orientation={'left'}
      children={<ILSTypographyTitle level={1} className={'tender-platform-title'} children={cardsPageTitle} />}
    />
  );
};
