import { isEmpty, isNil } from 'lodash';
import { JoinChar } from '@common/constants';
import { TableColumnName } from '@common/types';
import { replaceIDToEnumInDataIndex } from './replace-id-to-enum-in-dataindex';

/**
 * Функция получает dataIndex, который заканчивается на ID, находит соответствующий enum, и получает данные строки по этому enum.
 * Далее функция возвращает все значения enum в виде строки.
 * Если в enum множество элементов, то они будут соеденены запятой.
 * @param dataIndex - название колонки;
 * @param tableRow - данные строки;
 * @returns - название колонки
 */

export const getGroupRowTitleFromEnum = (dataIndex?: TableColumnName | string, tableRow?: Partial<Record<string, any>>) => {
  const dataEnum = replaceIDToEnumInDataIndex(dataIndex);
  if (!isNil(dataEnum) && !isNil(tableRow) && dataEnum in tableRow) {
    return tableRow[dataEnum] && !isEmpty(tableRow[dataEnum]) ? Object.values(tableRow[dataEnum])?.join(JoinChar.Comma) : null;
  }
  return null;
};
