import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderParticipantCreateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { ParticipantsAction } from '@modules/tender-platform/children/tender-own/types/participants';
import { DictionariesEndpoint } from '@common/types';

function* tenderParticipantCreateWorker(action: ParticipantsAction) {
  const { request, failure, fulfill } = tenderParticipantCreateRoutine;
  const { tenderID, ids } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderOwn.createParticipant, { tenderID, ids });
    yield put(
      tenderChangeRoutine.trigger({
        TenderParticipants: Object.values(data),
        operation: DictionariesEndpoint.Create
      })
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderParticipantCreateWatcher() {
  yield takeEvery(tenderParticipantCreateRoutine.trigger, tenderParticipantCreateWorker);
}
