import { FC } from 'react';
import { IPropsILSCatalogItemComponent } from '@modules/catalog/types/components';
import { ILSButtonTooltip } from '@components/general/buttons/components/tooltip-button';
import classNames from 'classnames';
import { DICTIONARIES_WITH_MAP } from '@modules/catalog/constants';

export const ILSCatalogItemComponent: FC<IPropsILSCatalogItemComponent> = ({
  handleChangeDataSource,
  handleSelectedElements,
  setMapProps,
  MyComponent,
  onFullChange,
  collapseKey,
  fullScreen,
  hideFullScreen,
  item,
  showMap,
  onMapChange,
  codeField,
  errors,
  ...props
}) => {
  const recordTableButtons = [
    {
      Component: ILSButtonTooltip,
      props: {
        icon: `${fullScreen?.set ? 'small' : 'full'}-screen`,
        title: fullScreen?.set ? `Свернуть рабочую область до обычного размера` : `Развернуть рабочую область на весь экран`,
        onClick: () => onFullChange?.(collapseKey)
      }
    }
  ];

  if (DICTIONARIES_WITH_MAP.includes(item?.dictionary)) {
    recordTableButtons.push({
      Component: ILSButtonTooltip,
      props: {
        icon: 'location',
        title: Boolean(showMap) ? 'Закрыть карту' : 'Открыть карту',
        onClick: () => onMapChange?.(item)
      }
    });
  }

  return (
    <div className={classNames({ 'catalog-item': !hideFullScreen })}>
      <MyComponent
        {...props}
        recordTableButtons={!hideFullScreen ? recordTableButtons : []}
        handleChangeDataSource={handleChangeDataSource}
        handleSelectedElements={handleSelectedElements}
        setMapProps={setMapProps}
        codeField={codeField}
        errors={errors}
      />
    </div>
  );
};
