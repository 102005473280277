import { ILSDivider } from '@common/components';
import { useDetailsContainer } from '@modules/orders-and-trips/hooks/use-details-container';
import { DetailsContent } from '@modules/orders-and-trips/components/details/details-content';
import { DetailsHeader } from '@modules/orders-and-trips/components/details/details-header';
import { DetailsRoute } from '@modules/orders-and-trips/components/details/details-route';

export const DetailsContainer = () => {
  const {
    tripDetails,
    tripStatus,
    tripCost,
    vehicleTypes,
    contactPerson,
    tripActions,
    cancelTrip,
    cancelAllowed,
    deleteTrip,
    deleteAllowed,
    tripService,
    tripDriver,
    tripVehicle
  } = useDetailsContainer();

  return (
    <div className="details">
      <DetailsHeader
        tripStatus={tripStatus}
        tripCost={tripCost}
        contactPerson={contactPerson}
        cancelAllowed={cancelAllowed}
        cancelTrip={cancelTrip}
        deleteAllowed={deleteAllowed}
        deleteTrip={deleteTrip}
        tripService={tripService}
        tripDriver={tripDriver}
        tripVehicle={tripVehicle}
      />
      <ILSDivider />
      <DetailsContent tripDetails={tripDetails} vehicleTypes={vehicleTypes} />
      <ILSDivider />
      <DetailsRoute tripActions={tripActions} />
    </div>
  );
};

