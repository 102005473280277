import moment from 'moment';
import { DateFormat } from '@common/types';

export enum OrderStatus {
  Resolved,
  Unresolved
}
export const NOT_EXIST_DEPOT = 0;

export enum ReduceNumberOperand {
  Ceil = 'ceil',
  Fixed = 'fixed',
  Floor = 'floor'
}
const tmr = moment().add(1, 'days').format(DateFormat.DDMMYYYYPeriod);

export const NEW_ORDER = {
  LoadDate: tmr,
  DeniedLate: 0,
  OrderSeries: 0,
  RequireTailLift: 0,
  RequirePalletTruck: 0,
  RequireLoaders: 0,
  RequireUnloaders: 0
};

export const DELIVERY_DAY_LIMIT_OPTIONS = [
  {
    value: 0,
    label: 'Доставить до'
  },
  {
    value: 1,
    label: 'Доставить в'
  }
];

export const EXCLUSIVE_ORDER_OPTIONS = [
  {
    value: 0,
    label: 'Нет'
  },
  {
    value: 1,
    label: 'Заявка отдельно'
  },
  {
    value: 2,
    label: 'Клиент отдельно'
  }
];

export const PLACE_IN_TRIP_OPTIONS = [
  {
    value: 0,
    label: 'Обычный'
  },
  {
    value: 3,
    label: 'Первый'
  },
  {
    value: 4,
    label: 'Не первый'
  },
  {
    value: 1,
    label: 'Последний'
  },
  {
    value: 2,
    label: 'Не последний'
  }
];
