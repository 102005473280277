import { Cascader } from 'antd';
import { FC } from 'react';
import { CascaderProps } from 'antd/es';

export const ILSCascader: FC<CascaderProps<any> & { tableRef?: any; showChild: boolean }> = ({
  tableRef,
  //@ts-ignore TODO check maxColumnWidth
  dropdownMenuColumnStyle = { maxWidth: '220px', maxColumnWidth: '220px', width: '500px' },
  showSearch = true,
  showChild,
  getPopupContainer,
  disabled,
  ...props
}) => {
  return (
    <Cascader
      {...props}
      dropdownMenuColumnStyle={dropdownMenuColumnStyle}
      showSearch={showSearch}
      getPopupContainer={getPopupContainer}
      disabled={disabled}
      showCheckedStrategy={showChild ? Cascader.SHOW_CHILD : Cascader.SHOW_PARENT}
    />
  );
};
