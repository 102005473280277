import React from 'react';
import { ILSCountdown } from '@components/index';
import { DateFormat, TableRecord } from '@common/types';
import { IILSCellCountdownProps } from '@common/types/components/table/cell';
import moment from 'moment';
import { isMomentInput } from '@components/data-display/table/utils/helpers/cells/is-moment-input';
import { getCellInputValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-value';

const style = {
  'font-size': '16px'
};

export const COUNTDOWN_DEFAULT_TEXT = 'Время истекло';

export const ILSCellCountdown = <R extends TableRecord>({
  children,
  text = COUNTDOWN_DEFAULT_TEXT
}: IILSCellCountdownProps<R>): JSX.Element => {
  const [node, value] = children;
  const countdown = isMomentInput(value) ? value : undefined;

  if (!moment(countdown).isAfter(moment())) {
    return (
      <>
        {node}
        {text}
      </>
    );
  }
  return (
    <>
      {node}
      <ILSCountdown value={getCellInputValue(value)} valueStyle={style} format={DateFormat.DaysCountdown} />
    </>
  );
};
