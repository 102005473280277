import { FC } from 'react';
import { ILSSpin } from '@common/components';
import { ButtonTypes } from '@common/types/components';
import { useExternalTransportContract } from '@modules/orders-and-trips/hooks/use-external-transport-contract';
import { ExternalTransportConfirmModalButtons } from '@modules/orders-and-trips/components/transport/external-transport-company/external-transport-confirm-modal-buttons';
import { useStimulsoftInit } from '@common/hooks/use-stimulsoft-init';

interface IProps {
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
}

export const ExternalTransportContractContainer: FC<IProps> = ({ submitButtonProps }) => {
  const { contractTemplate, isFetching } = useExternalTransportContract();

  const { StimulSoftViewer } = useStimulsoftInit({ template: contractTemplate, viewerID: 'viewerID', isFetching });

  return (
    <div className="external-transport-contract-container">
      <ILSSpin
        tip={'Заявка-счет загружается, пожалуйста подождите...'}
        spinning={isFetching}
        className="external-transport-contract-spinner"
      >
        <StimulSoftViewer />
        <ExternalTransportConfirmModalButtons submitButtonProps={submitButtonProps} />
      </ILSSpin>
    </div>
  );
};

