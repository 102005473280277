import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorUpdateConfigRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';

// Обновляет конфигурацию эмуляции, обновляет используемый план ID (planData.loadedPlanID) и,
// если задана, то обновляет скорость эмуляции(emulationSpeed)

const emulatorUpdateConfigReducer = createReducer(initialState, {
  [emulatorUpdateConfigRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.planData.isFetching = true;
    return state;
  },
  [emulatorUpdateConfigRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      planID: number | string;
      emulationSpeed?: number;
    }>
  ) => {
    const { planID, emulationSpeed } = action.payload;

    state.planData.isFetching = false;
    state.planData.loadedPlanID = planID;
    if (emulationSpeed) {
      state.emulatorStatus.configs.EmulationSpeed = emulationSpeed;
    }

    return state;
  },
  [emulatorUpdateConfigRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  },
  [emulatorUpdateConfigRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  }
});

export default emulatorUpdateConfigReducer;
