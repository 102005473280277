import { FC, memo } from 'react';
import { ILSCollapse, ILSCollapsePanel } from '@common/components';
import { ExpandIconPosition } from '@common/components/data-display/collapse/types';
import { CollapsePanels } from '@modules/orders-and-trips/types/general';
import { CaretRightOutlined } from '@ant-design/icons/lib/icons';
import { COLLAPSE_ARROW_ICON_CONFIG } from '@modules/orders-and-trips/constants/general';
import { OrderTableContainer } from '@modules/orders-and-trips/containers/left/order-table-container';
import { DecoratedTrips } from '@modules/orders-and-trips/types/trip';
import { orderTableConfigOnlyForTrips } from '@modules/orders-and-trips/config/order-table';

//TODO убрать моки и сделать типы как только будет готов бэк
interface IProps {
  trip: DecoratedTrips[number];
}

export const TripOrdersContaner: FC<IProps> = memo(({ trip }) => {
  return (
    <div className="trip-card-orders">
      <ILSCollapse
        expandIconPosition={ExpandIconPosition.Left}
        bordered={false}
        className="orders-and-trips-collapse"
        accordion
        destroyInactivePanel
        expandIcon={({ isActive }) => <CaretRightOutlined style={COLLAPSE_ARROW_ICON_CONFIG} rotate={isActive ? 90 : 0} />}
      >
        <ILSCollapsePanel key={CollapsePanels.Filters} header={'Показать/скрыть заявки рейса'}>
          <OrderTableContainer tableConfig={orderTableConfigOnlyForTrips} orders={trip.orders} />
        </ILSCollapsePanel>
      </ILSCollapse>
    </div>
  );
});

