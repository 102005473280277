import { Spin, SpinProps } from 'antd';
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import './styles.less';

export const ILSSpin: FC<SpinProps> = (props) => {
  return <Spin {...props} />;
};

export const ILSSpinCircle: FC<SpinProps> = (props) => {
  const icon = <ReactSVG src={'/assets/icons/loading-blue.svg'} />;

  return <Spin {...props} indicator={icon} />;
};

export const ILSSpinCenter: FC<SpinProps> = (props) => {
  return (
    <div className="ils-spin-center">
      <Spin {...props} />
    </div>
  );
};
