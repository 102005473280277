import { CATALOG_TABLE_NAMES } from '@modules/catalog/constants';

export const inUseDecorator = (data: any) => {
  const resultMessage: Array<string> = [];

  if (data && typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      let textItem = `Строка с ключом ${key} используется:`;
      let arrayDictKeys: string[] = [];
      if (data[key]) {
        Object.keys(data[key]).forEach((dictName) => {
          arrayDictKeys.push(
            `В таблице ${Object.keys(CATALOG_TABLE_NAMES).includes(dictName) ? CATALOG_TABLE_NAMES[dictName].text : dictName}:${data[key][
              dictName
            ].join(', ')}.`
          );
        });
      }
      resultMessage.push(textItem);
      resultMessage.push(...arrayDictKeys);
    });
  }
  resultMessage.push('Подтвердить удаление?');
  return resultMessage;
};
