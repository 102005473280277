export const VehicleMockOrdersNTrips = [
  {
    ID: 60677,
    Name: 'New one',
    Model: '111wer',
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#EE82EE',
    StartDepotID: [2, 778300],
    EndDepotID: [2, 778300],
    VehicleProfileID: null,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: null,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '1wefd2',
    TakeOnlyOrdersInZones: 0,
    Priority: null,
    ReleaseDate: 1685468700,
    Info: null,
    UseMode: 2,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: 26,
    StartDepot: {
      ID: 778300,
      DepotTypeID: 2,
      Name: 'Гараж_москвааааа',
      LatLon: {
        lat: 55.90596001,
        lon: 38.13648513
      },
      LatLonVerified: 0,
      DepotCode: 'Гараж_Москва_1',
      Description: null,
      AddressDescription: null,
      Delay: null,
      WorktimeID: 22782,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: null,
      TimeZone: null,
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 778300,
      DepotTypeID: 2,
      Name: 'Гараж_москвааааа',
      LatLon: {
        lat: 55.90596001,
        lon: 38.13648513
      },
      LatLonVerified: 0,
      DepotCode: 'Гараж_Москва_1',
      Description: null,
      AddressDescription: null,
      Delay: null,
      WorktimeID: 22782,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: null,
      TimeZone: null,
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '778300': 'Гараж_москвааааа'
    },
    EndDepotEnum: {
      '778300': 'Гараж_москвааааа'
    },
    DriverEnum: [],
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: {
      '26': 'Автобус'
    },
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    RestrictionEnum: [],
    RestrictionID: [],
    key: 60677,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: 1,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 53806,
    Name: 'NewCar',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#DA70D6',
    StartDepotID: null,
    EndDepotID: null,
    VehicleProfileID: null,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: null,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: null,
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: null,
    Info: null,
    UseMode: 1,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepotEnum: [],
    EndDepotEnum: [],
    DriverEnum: [],
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    RestrictionEnum: [],
    RestrictionID: [],
    key: 53806,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: 1,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 53813,
    Name: 'NewCar',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#FF7F50',
    StartDepotID: null,
    EndDepotID: null,
    VehicleProfileID: null,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: null,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: null,
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: null,
    Info: null,
    UseMode: 1,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepotEnum: [],
    EndDepotEnum: [],
    DriverEnum: [],
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    RestrictionEnum: [],
    RestrictionID: [],
    key: 53813,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: 1,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 44722,
    Name: 'газель6',
    Model: null,
    RegNumber: 'Т827КО',
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#9400D3',
    StartDepotID: [2, 778300],
    EndDepotID: [2, 778300],
    VehicleProfileID: 5758,
    VehicleTimelineID: 4399,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 687380,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '6',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: 1681603200,
    Info: null,
    UseMode: 2,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 778300,
      DepotTypeID: 2,
      Name: 'Гараж_москвааааа',
      LatLon: {
        lat: 55.90596001,
        lon: 38.13648513
      },
      LatLonVerified: 0,
      DepotCode: 'Гараж_Москва_1',
      Description: null,
      AddressDescription: null,
      Delay: null,
      WorktimeID: 22782,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: null,
      TimeZone: null,
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 778300,
      DepotTypeID: 2,
      Name: 'Гараж_москвааааа',
      LatLon: {
        lat: 55.90596001,
        lon: 38.13648513
      },
      LatLonVerified: 0,
      DepotCode: 'Гараж_Москва_1',
      Description: null,
      AddressDescription: null,
      Delay: null,
      WorktimeID: 22782,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: null,
      TimeZone: null,
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '778300': 'Гараж_москвааааа'
    },
    EndDepotEnum: {
      '778300': 'Гараж_москвааааа'
    },
    DriverEnum: [],
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Профиль авто №5758',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': null,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': null,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': 5,
    'VehicleProfile.MaxBoxes': 20,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '5758': 'Профиль авто №5758'
    },
    'VehicleTimeline.MaxClients': 3,
    'VehicleTimeline.MaxOrders': 0,
    'VehicleTimeline.MinStartTime': 0,
    'VehicleTimeline.MaxDuration': 0,
    'VehicleTimeline.DurationOfWork': 0,
    'VehicleTimeline.DurationOfRest': 0,
    'VehicleTimeline.MaxDistance': 0,
    'VehicleTimeline.DistanceOfWork': 0,
    'VehicleTimeline.MaxDurationRoute': 0,
    'VehicleTimeline.MaxDistanceRoute': 0,
    'VehicleTimeline.MaxTrips': 0,
    'VehicleTimeline.DurationOfFirstWork': 0,
    'VehicleTimeline.DurationOfFirstRest': 0,
    'VehicleTimeline.WorkPerDay': 0,
    'VehicleTimeline.DriverRestMode': null,
    VehicleTimelineEnum: {
      '4399': 'create RoutePriceFormula test api data'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 44722,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: 1,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49458,
    Name: 'тест12',
    Model: '',
    RegNumber: '',
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#E9967A',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8485,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 689782,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_12',
    TakeOnlyOrdersInZones: 1,
    Priority: null,
    ReleaseDate: 1673971740,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '689782': 'Тест12'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': 2,
    'VehicleProfile.MaxBoxes': 10,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8485': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49458,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49459,
    Name: 'тест2',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#FF4500',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8520,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 691335,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_2',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: null,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '691335': 'тест2'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': null,
    'VehicleProfile.MaxBoxes': null,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8520': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49459,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49460,
    Name: 'тест3',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#FFA07A',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8520,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 691937,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_3',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: null,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '691937': 'adflhg dfgd'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': null,
    'VehicleProfile.MaxBoxes': null,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8520': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49460,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49461,
    Name: 'тест4',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#A0522D',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8520,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 691935,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_4',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: 1684755960,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '691935': 'adflhg dfgd'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': null,
    'VehicleProfile.MaxBoxes': null,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8520': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49461,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49462,
    Name: 'тест5',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#8B4513',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8520,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 691927,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_5',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: 1680690300,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '691927': 'adflhg dfgd'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': null,
    'VehicleProfile.MaxBoxes': null,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8520': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49462,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  },
  {
    ID: 49463,
    Name: 'тест6',
    Model: null,
    RegNumber: null,
    Comment: null,
    Deleted: false,
    VehicleGroupID: null,
    AccountID: 382,
    RouteColor: '#D2691E',
    StartDepotID: [2, 617035],
    EndDepotID: [2, 617035],
    VehicleProfileID: 8520,
    VehicleTimelineID: null,
    VehicleOverloadID: null,
    VehicleTariffID: null,
    SpeedProfileID: null,
    DriverID: 694521,
    ExpeditorID: null,
    ICode: null,
    VehicleCode: '2_6',
    TakeOnlyOrdersInZones: null,
    Priority: null,
    ReleaseDate: null,
    Info: null,
    UseMode: null,
    ForMarket: null,
    TripDeviationDistance: null,
    IsAutomotive: 1,
    FrontCouplingTypeID: null,
    RearCouplingTypeID: null,
    TransportCompanyID: null,
    VehicleTypeID: null,
    StartDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    EndDepot: {
      ID: 617035,
      DepotTypeID: 2,
      Name: 'Склад Кубинская 76/1',
      LatLon: {
        lat: 59.84660399,
        lon: 30.29496392
      },
      LatLonVerified: 0,
      DepotCode: '2_1',
      Description: null,
      AddressDescription: 'Кубинская улица, 76к3, Санкт-Петербург, 196240',
      Delay: 0.0034722222222222,
      WorktimeID: 21710,
      DepotOverloadID: null,
      ICode: null,
      AddressGUID: null,
      AccountID: 382,
      Deleted: null,
      Radius: null,
      Queue: null,
      ZoneID: null,
      DistanceFromCity: null,
      ClientContact: null,
      Info: null,
      Value1: null,
      Value2: null,
      Value3: null,
      VisitPrice: null,
      UTCOffset: 3,
      TimeZone: 'Europe/Moscow',
      AllowKeepTrailer: 0,
      Contact: null,
      DepotTypeEnum: {
        '2': 'Склад'
      }
    },
    StartDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    EndDepotEnum: {
      '617035': 'Склад Кубинская 76/1'
    },
    DriverEnum: {
      '694521': 'Тест6'
    },
    ExpeditorEnum: [],
    VehicleOverloadEnum: [],
    TripTemplateEnum: [],
    VehicleTypeEnum: [],
    FriendZoneEnum: [],
    FrontCouplingTypeEnum: [],
    RearCouplingTypeEnum: [],
    TransportCompanyEnum: [],
    ForbiddenZoneEnum: {},
    ForbiddenZoneID: [],
    'VehicleProfile.Name': 'Новый профиль',
    'VehicleProfile.MaxUnitLength': null,
    'VehicleProfile.MaxUnitWidth': null,
    'VehicleProfile.MaxUnitHeight': null,
    'VehicleProfile.CarCode': null,
    'VehicleProfile.CarMark': null,
    'VehicleProfile.AverageSpeed': 60,
    'VehicleProfile.FuelConsumption': null,
    'VehicleProfile.MaxMass': 1500,
    'VehicleProfile.MaxVolume': 15,
    'VehicleProfile.Flags': null,
    'VehicleProfile.MaxPallets': null,
    'VehicleProfile.MaxBoxes': null,
    'VehicleProfile.MaxCost': null,
    'VehicleProfile.MainScheme': null,
    'VehicleProfile.SecondScheme': null,
    'VehicleProfile.VehicleTariffID': null,
    'VehicleProfile.Info': null,
    'VehicleProfile.LoadDuration': null,
    'VehicleProfile.UnloadDuration': null,
    VehicleProfileEnum: {
      '8520': 'Новый профиль'
    },
    RestrictionEnum: [],
    RestrictionID: [],
    key: 49463,
    FriendZoneID: [],
    TripTemplateID: [],
    RoadTrain: [],
    ForbiddenZones: [],
    StartTime: '',
    StartDate: null,
    mass: 0,
    pallets: 0,
    volume: 0,
    TakeForPlanner: null,
    dictionary: 'Vehicle',
    rowParams: {
      isDeleted: null
    }
  }
];
