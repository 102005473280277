import { CellType, FilterCellType, ShowDeleted, TableColumnName, TableConfig } from '@common/types';
import { DRIVER_TABLE_CONFIG } from '@common/models/driver';
import { WEEK_DAYS_OPTIONS } from '@common/constants';
import { BASE_FIELD_NAMES } from '@common/constants/options';

const PLANNER_DATA_DRIVER_COLUMNS = [
  TableColumnName.DriverCode,
  TableColumnName.Name,
  TableColumnName.Phone,
  TableColumnName.PassportNumber,
  TableColumnName.PassportDate,
  TableColumnName.PassportIssuedBy,
  TableColumnName.LicenseNumber,
  TableColumnName.Type
];

export const PLANNER_DATA_DRIVER_TABLE_CONFIG: TableConfig = {
  ...DRIVER_TABLE_CONFIG,
  export: false,
  copying: false,
  multiEditing: false,
  reloading: false,
  showDeleted: ShowDeleted.No,
  columns: DRIVER_TABLE_CONFIG.columns
    .filter((column) => PLANNER_DATA_DRIVER_COLUMNS.includes(column.dataIndex as TableColumnName))
    .concat([
      {
        title: 'Название расписания работы',
        dataIndex: 'DriverTimeline.Name',
        key: 'DriverTimeline.Name',
        active: true,
        filtering: FilterCellType.String,

        editable: true,
        ellipsis: true,
        width: 120,
        type: CellType.Input,

        sorting: 15
      },
      {
        title: 'Начало работы',
        dataIndex: 'DriverTimeline.StartTime',
        key: 'DriverTimeline.StartTime',
        editable: true,
        active: true,
        filtering: FilterCellType.String,
        type: CellType.TimeDouble,
        width: 120,

        sorting: 14
      },
      {
        title: 'Дни недели',
        dataIndex: 'DriverTimeline.WeekDays',
        key: 'DriverTimeline.WeekDays',
        editable: true,
        active: true,
        filtering: FilterCellType.Array,
        width: 150,

        type: CellType.SelectMultiple,
        fieldNames: BASE_FIELD_NAMES,
        needSortByLabelField: false,
        options: WEEK_DAYS_OPTIONS,
        sorting: 13
      },
      {
        title: 'Работа(дн.)',
        dataIndex: 'DriverTimeline.DaysOfWork',
        key: 'DriverTimeline.DaysOfWork',
        editable: true,
        active: true,
        filtering: FilterCellType.Array,
        width: 150,

        type: CellType.Int,

        sorting: 12
      },
      {
        title: 'Выходные(дн.)',
        dataIndex: 'DriverTimeline.DaysOfRest',
        key: 'DriverTimeline.DaysOfRest',
        editable: true,
        active: true,
        filtering: FilterCellType.Array,
        width: 150,

        type: CellType.Int,

        sorting: 11
      },
      {
        title: 'Макс.работа в неделю, ч',
        dataIndex: 'DriverTimeline.WorkPerWeek',
        key: 'DriverTimeline.WorkPerWeek',
        editable: true,
        active: true,
        filtering: FilterCellType.String,
        type: CellType.Int,
        width: 150,

        sorting: 9
      },
      {
        title: 'Макс.работа за 2 недели, ч',
        dataIndex: 'DriverTimeline.WorkPerTwoWeek',
        key: 'DriverTimeline.WorkPerTwoWeek',
        editable: true,
        active: true,
        filtering: FilterCellType.String,
        type: CellType.Int,
        width: 150,

        sorting: 8
      },
      {
        title: 'Специфики',
        dataIndex: TableColumnName.Restriction,
        key: TableColumnName.Restriction,
        active: false,
        filtering: FilterCellType.String,

        editable: true,
        ellipsis: true,
        width: 160,
        type: CellType.Input,

        sorting: 6
      },
      {
        title: 'Склад / Уникальный код точки',
        dataIndex: 'Warehouse.DepotCode',
        key: 'Warehouse.DepotCode',
        active: false,
        filtering: FilterCellType.String,

        ellipsis: true,
        editable: true,
        width: 160,
        type: CellType.Input,

        sorting: 5
      },
      {
        title: 'Шаблоны рейсов',
        dataIndex: TableColumnName.TripTemplate,
        key: TableColumnName.TripTemplate,
        active: false,
        filtering: FilterCellType.String,

        editable: true,
        ellipsis: true,
        width: 160,
        type: CellType.Input,

        sorting: 10
      }
    ])
};
