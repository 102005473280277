import { takeEvery } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { ErrorAPI, Notify, NotifyDurationInSecond } from '@common/types';
import { DEFAULT_NOTIFY_PLANNER_DATA_CONTENT } from '@modules/planning/children/data/constants/content';
import { EMPTY_STRING } from '@common/constants';
import { PLANNER_DATA_ACTION_PREFIX, PlannerDataActionType, uploadRoutinesWithNotifier } from '@modules/planning/children/data/actions';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';

/**Метод получения текста уведомления для разных actions*/
const getDataNotificationMessage = (
  action: PlannerDataActionType,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return payload?.message || DEFAULT_NOTIFY_PLANNER_DATA_CONTENT?.[action]?.[type] || EMPTY_STRING;
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): PlannerDataActionType => {
  return actionName as PlannerDataActionType;
};

const worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, PlannerDataActionType>({
  getActionFromRoutine,
  getKeyPrefix: () => PLANNER_DATA_ACTION_PREFIX,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

const errorWorker = notifierWorkerCreator<ErrorAPI, PlannerDataActionType>({
  getActionFromRoutine,
  getKeyPrefix: () => PLANNER_DATA_ACTION_PREFIX,
  getNotificationMessage: () => EMPTY_STRING,
  getErrorMessage
});

export function* planningDataNotifierWatcher() {
  for (let routine of uploadRoutinesWithNotifier) {
    yield takeEvery(routine.SUCCESS, worker);
    yield takeEvery(routine.REQUEST, worker);
    yield takeEvery(routine.FAILURE, errorWorker);
  }
}
