import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { compareAsString } from '@common/utils';
import { User, UserRole } from '@common/types';
import { EMPTY_OBJECT_DATA } from '@common/constants';
import { currentUserSelector } from '@modules/current-user/selectors/auth';
import { dataSelector } from '@modules/account/selectors/account';
import { AccountStore, UserRequest } from '@modules/account/types';
import { getUserAvatar } from '@modules/account/utils';
import { USER_ROLE_OPTIONS } from '@modules/account/constants';

export const usersSelector = createSelector(
  (state: AccountStore) => state.Account.users,
  (users) => users
);

export const userAvatarSelector = createSelector(usersSelector, currentUserSelector, (users, currentUser) => {
  if (!currentUser.Avatar) {
    const Attachments = users?.find((user) => compareAsString(currentUser.ID, user.ID))?.Attachments;
    const userAvatar = getUserAvatar(Attachments);
    return { userAvatar };
  } else {
    return { userAvatar: currentUser.Avatar };
  }
});

// TODO abac
export const adminAccessSelector = createSelector(currentUserSelector, (user) => {
  return Boolean(user?.UserRole?.includes(UserRole.administrator));
});

// disabled удаление и добавление роли у единственного администратора (он всегда должен оставаться администратором)
export const adminOptionsForRolesSelector = createSelector(currentUserSelector, usersSelector, (user, users) => {
  if (users.length === 1 || users.filter((user) => user.UserRole.includes(UserRole.administrator)).length === 1) {
    return USER_ROLE_OPTIONS.map((role) => {
      return compareAsString(role.value, UserRole.administrator) ? { ...role, disabled: true } : role;
    });
  }
  return undefined;
});

export const currentUserIDSelector = createSelector(currentUserSelector, (currentUser) => currentUser.ID);

export const userSelector = createSelector(
  currentUserIDSelector,
  usersSelector,
  (id, users) =>
    // currentUser не может быть пустым,
    // так как в любом случае мы находимся в каком-либо аккаунте,
    // если текущего user (активная сессия) нет - это баг
    users.find(({ ID }) => compareAsString(id, ID)) ?? (EMPTY_OBJECT_DATA as User)
);

//https://jira.intelogis.ru/browse/TMS-6017
// Убрать водителей и экспедиторов из раздела Аккаунт (их нельзя видеть)
export const usersWithoutDriversSelector = createSelector(usersSelector, (users) =>
  users.filter((user) => !user.UserRole.includes(UserRole.driver))
);
//https://jira.intelogis.ru/browse/TMS-6015
// Убрать возможность удаления единственного и последнего администратора в компании
export const cantChangeRoleOrDeleteUserSelector = (values?: UserRequest['values'], id?: User['ID']) =>
  createSelector(usersWithoutDriversSelector, dataSelector, (users) => {
    const admins = users.filter((user) => !user.UserRole.includes(UserRole.administrator));
    const isAdminExclusive = admins.length === 1;
    const userUpdatingID = admins.find(({ ID }) => compareAsString(id, ID));
    const isAdminExclusiveUpdatingRole = Boolean(values?.UserRole) && !values?.UserRole?.includes(UserRole.administrator) && userUpdatingID;
    return isAdminExclusive && isAdminExclusiveUpdatingRole;
  });

export const userInitialValuesSelector = createSelector(userSelector, (currentUser) => {
  const { Name, UserRole, Login, Phone } = currentUser;
  // currentUser не может быть пустым,
  // так как в любом случае мы находимся в каком-либо аккаунте,
  // если текущего user (активная сессия) нет - это баг
  return { initialValues: { Name, UserRole, Login, Phone }, isLoading: isEmpty(currentUser) };
});
