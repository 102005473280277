import { ILSButton } from '@components/general/buttons';
import { ILSDropdown } from '@components/navigation/dropdown';
import { ILSMenu } from '@components/navigation/menu';
import { MenuActionType } from '@common/types/components/table/menu-action';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';

export const ILSCellMenuButton: FC = ({ children }) => {
  const items: MenuActionType[] = Array.isArray(children) ? children[1] : [];

  const transformed = items?.map((item, idx) => ({ ...item, key: idx })) || [];
  const menuItems =
    transformed.map((x) => {
      return { key: x.key, label: x.label };
    }) || [];

  const onClick = (info: MenuInfo) => {
    if (transformed) {
      const selectedItem = transformed?.find((elm) => elm.key.toString() === info.key);
      selectedItem?.actionCallback && selectedItem?.actionCallback();
    }
  };

  const menu = <ILSMenu items={menuItems} onClick={onClick} />;
  return (
    <ILSDropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <ILSButton size={'small'} type={'text'} icon={<ReactSVG src={'/assets/icons/options.svg'} />} />
    </ILSDropdown>
  );
};
