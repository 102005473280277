import { FC } from 'react';
import { AccountAvatarComponent } from '@modules/account/components/form/avatar';
import { REQUIRED_USER_FIELDS, USER_FORM_ITEMS, UserFormItem } from '@modules/account/constants';
import { FormItemMessage, Label } from '@common/constants/general/form';
import { ILSButton, ILSForm, ILSFormItem } from '@common/components';
import { Avatar, ProfileTab } from '@modules/account/types';
import { renderInput } from '@modules/account/utils/helpers/render-input';
import { ButtonAction } from '@components/general/buttons';
import { IILSSelectProps } from '@components/data-entry/select/types';

interface IProps {
  avatar: Avatar;
  initialValues: any;
  disabled?: boolean;
  name: ProfileTab.user | ProfileTab.users;

  options?: IILSSelectProps['options'];

  onAvatarChange(e: any): void;

  handleCancel?(): void;

  onFinish(values: any): void;
}

export const AccountUserFormComponent: FC<IProps> = ({
  name,
  avatar,
  onAvatarChange,
  initialValues,
  onFinish,
  handleCancel,
  disabled = false,
  options
}) => {
  return (
    <ILSForm name={name} layout={'vertical'} onFinish={onFinish} className="account-form user" initialValues={initialValues}>
      <AccountAvatarComponent avatar={avatar} onAvatarChange={onAvatarChange} />
      {USER_FORM_ITEMS.map((field) => {
        const required = REQUIRED_USER_FIELDS.includes(field as UserFormItem);
        const rules = [{ required, message: FormItemMessage[field] }];
        return (
          <ILSFormItem key={field} className={'account-form-item'} label={Label[field]} name={field} rules={rules}>
            {renderInput({ field, disabled, options })}
          </ILSFormItem>
          // {/* hide before https://jira.intelogis.ru/browse/TMS-5197 */}
          // {/*<ILSAccountTimeZoneComponent value={user?.TimeZone ? user?.TimeZone : ''} />*/}
        );
      })}
      <div className="form-buttons">
        {handleCancel && <ILSButton action={ButtonAction.Cancel} onClick={handleCancel} />}
        <ILSButton form={name} action={ButtonAction.Save} type="primary" htmlType="submit" />
      </div>
    </ILSForm>
  );
};
