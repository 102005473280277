export default function arrayMove<T>(arr: T[], oldIndex: number, newIndex: number) {
  let res: (T | undefined)[] = [...arr];
  if (newIndex >= res.length) {
    let k = newIndex - res.length + 1;

    while (k--) {
      res.push(undefined);
    }
  }
  res.splice(newIndex, 0, res.splice(oldIndex, 1)[0]);
  return res;
}
