import { ILSTable } from '@common/components';
import { DEFAULT_RECORDS_FROM_PAGE } from '@common/components/data-display/table/constants';
import { FC } from 'react';
import { TableChildProps } from '../../types/tables';

export const AnalyticsWidgetTableComponent: FC<TableChildProps> = ({ tableConfig }) => {
  const { dataSource, config, showSummary } = tableConfig;

  return (
    <ILSTable
      tableName={config.className}
      config={{ ...config, tableName: config.className }}
      dataSource={dataSource}
      customSummary={showSummary}
      scroll={{ y: 'max-content' }}
      cellClassName={() => 'analytics-cell'}
      {...(dataSource.length <= DEFAULT_RECORDS_FROM_PAGE && { pagination: false })}
    />
  );
};
