import { TableColumnName } from '@common/types';
import { VEHICLE_NOT_EDITABLE_COLUMN } from '@common/models/vehicle/constants';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators';

export const canEditVehicleInValidation = (
  vehicle: ReturnType<typeof plannerPlanningVehiclesToTable>[number],
  dataIndex: TableColumnName
) => {
  if (dataIndex === TableColumnName.TakeForPlanner) {
    return !Boolean(vehicle?.UseMode);
  } else return !VEHICLE_NOT_EDITABLE_COLUMN.includes(dataIndex);
};
