import { isEmpty } from 'lodash';
import { DashboardData } from '../types';
import { checkDaysDiff } from './check-days-diff';
import { DASHBOARD_UPDATES_INTERVAL } from '../constants';

export const needUpdateDashboard = (
  current: {
    endDate: string | undefined | null;
    startDate: string | undefined | null;
  },
  lastLoad: Pick<DashboardData<any>, 'till' | 'from' | 'lastUpdate'>
) => {
  if (isEmpty(current)) return false;
  const { startDate, endDate } = current;
  if (startDate && endDate && checkDaysDiff(startDate, endDate)) {
    const { from, lastUpdate, till } = lastLoad;

    if (isEmpty(lastLoad) || !from || !lastUpdate || !till) {
      return true;
    }
    if (startDate !== from || endDate !== till || lastUpdate + DASHBOARD_UPDATES_INTERVAL < new Date().getTime()) {
      return true;
    }
  }

  return false;
};
