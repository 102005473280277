import './styles.less';
import { ILSBadgeRibbon } from '@common/components/data-display/badge-ribbon';

export const LogisticServicesLoginBadge = () => {
  return (
    <div className="logistic-services__card-badge logistic-services__card-badge_login">
      <ILSBadgeRibbon text="LOGIN" color="#389e0d" />
    </div>
  );
};

