import reduceReducers from 'reduce-reducers';
import { Module } from '@common/types';
import { initialState } from '@modules/planning/children/by-date/store';
import { createLoadReducer } from '@core/reducers/load-module';
import { getPlanReducer } from './plan/get-plan';
import { planningValidateReducer } from './validate';
import { dictionaryPlannerReducer, handleSwitchersReducer } from './table-data';
import { startPlanningReducer } from './planning/start-planning';
import { checkPlanningReducer } from './planning/check-planning';
import { cancelPlanningReducer } from './planning/cancel-planning';
import { changePlanningStatusReducer } from './planning/change-planning-status';
import { planningDeleteReducer } from './delete';
import { savePlanReducer } from './plan/save';
import { setPlanningConfigReducer } from './planning/set-planning-config';
import { unionTripsReducer } from './route/union-trips';
import { changeVehicleReducer } from './route/change-vehicle';
import { exchangeVehiclesReducer } from './route/exchange-vehicles';
import { addOrderForVehicleReducer } from './route/add-order-for-vehicle';
import { getPlanningConfigsReducer } from './planning/get-planning-configs';
import { getTripLinkReducer } from './vehile-monitoring/get-trip-link';
import planningUseRedisReducer from './map/use-redis';
import { getTrackReducer } from './map/get-track';
import { addTrackToQueryReducer } from './add-track-to-query';
import { donePlanningReducer } from '@modules/planning/children/by-date/reducers/planning/done-planning';
import { moveWaypointInTripReducer } from './trip/move-waypoint-in-trip';
import {
  cleanupDistanceMatrixReducer,
  deleteDistanceMatrixReducer,
  startDistanceMatrixReducer,
  statusDistanceMatrixReducer
} from './distance-matrix';
import { changeDriverInTripReducer } from './trip/change-driver-in-trip';
import { changeDriverInRouteReducer } from './route/change-driver-in-route';
import { mapWaitingModeReducer } from '@modules/planning/children/by-date/reducers/map/map-waiting-mode';
import { changeExpeditorInTripReducer } from '@modules/planning/children/by-date/reducers/trip/change-expeditor-in-trip';
import { getProjectCurrentReducer } from '@modules/planning/children/by-date/reducers/project/get-project-current';
import { tripSaveReducer } from './trip/save';
import { routeSaveReducer } from './route/save';
import { rememberSelectedRowsReducer } from '@modules/planning/children/by-date/reducers/remember-selected-rows';
import { changeExpeditorInRouteReducer } from '@modules/planning/children/by-date/reducers/route/change-expeditor-in-route';
import { changeValidationStatusReducer } from '@modules/planning/children/by-date/reducers/change-validation-status';
import { planningDeleteTenderForRouteRoutineReducer } from '@modules/planning/children/by-date/reducers/route/delete-tender-for-route';
import { moveWaypointToTripReducer } from '@modules/planning/children/by-date/reducers/trip/move-waypoint-to-trip';
import { createTripReducer } from '@modules/planning/children/by-date/reducers/trip/create-trip';
import { addCreatedPlanReducer } from '@modules/planning/children/by-date/reducers/plan/add-created-plan';
import { planningCopyReducer } from '@modules/planning/children/by-date/reducers/plan/copy';
import { planClearReducer } from '@modules/planning/children/by-date/reducers/plan/clear';
import { getPlannerParamsReducer } from '@modules/planning/children/by-date/reducers/planning/get-planner-params';
import { addEndDepotToTripReducer } from '@modules/planning/children/by-date/reducers/trip/add-end-depot-to-trip';
import { rememberFocusNExpandRowsReducer } from '@modules/planning/children/by-date/reducers/remember-focus-and-expand-rows';
import { planningRestrictionFilterReducer } from '@modules/planning/children/by-date/reducers/filters/restrictions';
import { planningGetPlanEfficiencyReducer } from '@modules/planning/children/by-date/reducers/plan/planning-get-plan-efficiency';
import { planningUpdatePlansAndRoutesReducer } from '@modules/planning/children/by-date/reducers/table-data/planning-update-plans-and-routes';
import { planningSetExecutionReducer } from '@modules/planning/children/by-date/reducers/plan/set-execution';
import { planningUnsetExecutionReducer } from '@modules/planning/children/by-date/reducers/plan/unset-execution';
import { planningReloadReducer } from '@modules/planning/children/by-date/reducers/plan/reload';

const planningByDateReducer = reduceReducers(
  initialState,
  dictionaryPlannerReducer,
  changeExpeditorInTripReducer,
  getPlanReducer,
  tripSaveReducer,
  routeSaveReducer,
  planningValidateReducer,
  startPlanningReducer,
  checkPlanningReducer,
  cancelPlanningReducer,
  changePlanningStatusReducer,
  planningDeleteReducer,
  savePlanReducer,
  setPlanningConfigReducer,
  moveWaypointToTripReducer,
  moveWaypointInTripReducer,
  unionTripsReducer,
  changeVehicleReducer,
  exchangeVehiclesReducer,
  addOrderForVehicleReducer,
  getPlanningConfigsReducer,
  getTripLinkReducer,
  planningUseRedisReducer,
  getTrackReducer,
  addTrackToQueryReducer,
  donePlanningReducer,
  cleanupDistanceMatrixReducer,
  startDistanceMatrixReducer,
  deleteDistanceMatrixReducer,
  statusDistanceMatrixReducer,
  changeDriverInTripReducer,
  changeDriverInRouteReducer,
  mapWaitingModeReducer,
  handleSwitchersReducer,
  getProjectCurrentReducer,
  changeExpeditorInRouteReducer,
  rememberSelectedRowsReducer,
  changeValidationStatusReducer,
  planningDeleteTenderForRouteRoutineReducer,
  addCreatedPlanReducer,
  createTripReducer,
  planningCopyReducer,
  planClearReducer,
  getPlannerParamsReducer,
  addEndDepotToTripReducer,
  rememberFocusNExpandRowsReducer,
  planningRestrictionFilterReducer,
  planningGetPlanEfficiencyReducer,
  planningUpdatePlansAndRoutesReducer,
  planningSetExecutionReducer,
  planningUnsetExecutionReducer,
  planningReloadReducer,
  createLoadReducer(Module.PlanningByDate)
);

export default planningByDateReducer;
