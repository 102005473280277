import { PointType } from '@common/utils/get-track/types';
import { isEmpty } from 'lodash';

export const addTrackToQueryState = (planDataQuery: { [K: string]: PointType }, points: { [K: string]: PointType }) => {
  if (planDataQuery && Object.keys(planDataQuery).length && !isEmpty(points)) {
    planDataQuery = { ...planDataQuery };
    Object.keys(points).forEach((pointKey) => {
      if (!planDataQuery?.[pointKey]) {
        planDataQuery[pointKey] = points[pointKey];
      }
    });
  } else {
    planDataQuery = points;
  }
  return planDataQuery;
};
