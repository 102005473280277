import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringBDDEventsStatusType, MonitoringSensorEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { DashboardBDDMetrics } from '@modules/monitoring/children/dashboard/types/bdd-metrics';

// Декоратор данных для метрики нарушений безопасности дорожного движения
export const bddMetricsDecorator = (vehicles: IndexedArray<MonitoringBDDAndFuelDrainModel>): DashboardBDDMetrics => {
  const driverIDs: number[] = [];
  const metrics = {
    driverCount: {
      total: 0,
      unviewed: 0
    },
    accelerationsCount: {
      total: 0,
      unviewed: 0
    },
    sharpTurnCount: {
      total: 0,
      unviewed: 0
    },
    sharpBrakingCount: {
      total: 0,
      unviewed: 0
    }
  };
  for (const vehicleID in vehicles) {
    const vehicle = vehicles[vehicleID];

    if (!vehicle.VehicleTracker || !Object.values(vehicle.VehicleTracker)) {
      continue;
    }

    for (const trackerID in vehicle.VehicleTracker) {
      const tracker = vehicle.VehicleTracker[trackerID];
      if (!tracker.SensorMapping || !Object.values(tracker.SensorMapping)) {
        continue;
      }
      for (const sensorID in tracker.SensorMapping) {
        const sensor = tracker.SensorMapping[sensorID];
        if (!sensor.SensorValue || !Object.values(sensor.SensorValue)) {
          continue;
        }
        if (MonitoringBDDEventsStatusType.includes(Number(sensorID))) {
          if (vehicle.DriverID && !driverIDs.includes(vehicle.DriverID)) {
            driverIDs.push(vehicle.DriverID);
          }
        }
        switch (sensorID) {
          case String(MonitoringSensorEventStatusType.SharpAcceleration):
            metrics.accelerationsCount.total += Object.values(sensor.SensorValue).length;
            break;
          case String(MonitoringSensorEventStatusType.SharpBraking):
            metrics.sharpBrakingCount.total += Object.values(sensor.SensorValue).length;
            break;
          case String(MonitoringSensorEventStatusType.SharpTurn):
            metrics.sharpTurnCount.total += Object.values(sensor.SensorValue).length;
            break;
          default:
            break;
        }
      }
    }
  }
  metrics.driverCount.total = driverIDs.length;

  return metrics;
};
