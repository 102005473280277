import { ModulePath } from '@common/types';

export const ASSETS_DIRECTORY_PATHS = {
  default: ModulePath.Dashboard,
  catalog: '/catalog/',
  planning: '/planning/'
};

export const ORIGIN_PATH = window.location.origin;
export const VEHICLE_MONITORING_PATH = `${ORIGIN_PATH}${ModulePath.CustomWithoutAuthPage}/vehicleMonitoring`;
export const ASSETS_BASE_PATH = '/assets/icons';
export const ICON_PATH = `${ORIGIN_PATH}${ASSETS_BASE_PATH}`;

export enum PermittedUploadFileType {
  png = 'image/png',
  jpeg = 'image/jpeg',
  pdf = 'application/pdf',
  msword = 'application/msword',
  zip = 'application/zip',
  rar = '.rar',
  documentWordML = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  sheetML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  excel = 'application/vnd.ms-excel',
  zipCompressed = 'application/x-zip-compressed',
  textPlain = 'text/plain',
  textCSV = 'text/csv',
  ini = '.ini'
}

export enum PermittedUploadFileExtensions {
  pdf = '.pdf',
  docx = '.docx',
  xls = '.xls',
  xlsx = 'xlsx',
  rar = '.rar',
  zip = '.zip',
  txt = '.txt',
  png = '.png',
  jpg = '.jpg',
  ini = '.ini',
  jpeg = '.jpeg',
  gif = '.gif',
  bmp = '.bmp',
  doc = '.doc',
  csv = '.csv'
}

export const DOCUMENTS_EXTENSION = {
  xls: 'xls',
  xlsx: 'xlsx',
  txt: 'txt',
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
  pdf: 'pdf',
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  zip: 'zip',
  rar: 'rar'
};

export const DEFAULT_FILE_ICON = 'default';
