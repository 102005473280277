import { TODAY } from '@common/constants/general';
import { useModal } from '@common/hooks/components/use-modal';
import { Project, Status } from '@common/types';
import { parseDateFromServer } from '@common/utils/helpers/date-time/parsers';
import { fetchSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { projectStartDateSelector } from '@modules/planning/children/by-date/selectors/project';
import { PlannerPlanningMenuComponent } from '@modules/planning/components/menu';
import { projectsOnDateSelector } from '@modules/planning/selectors';
import { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDatepickProjectHandler } from '../utils/hooks/use-datepick-project';

export type IDateProps = {
  hasContentForProject: boolean;
};

export const PlannerPlanningMenuContainer: FC<IDateProps> = ({ hasContentForProject }) => {
  const { visible, handleShow } = useModal();
  const projects = useSelector(projectsOnDateSelector);
  const projectStart = useSelector(projectStartDateSelector);
  const isFetching = useSelector(fetchSelector);
  const [currentPickedDate, setCurrentPickedDate] = useState<Moment>(() => parseDateFromServer(projectStart ?? TODAY));
  const [projectListToChoose, setProjectListToChoose] = useState<Array<Project> | null>(null);
  const [status, setStatus] = useState<Status.Error | Status.Warning | undefined>(undefined);
  const setPickedDateValue = (selectDate: number | string) => {
    const date = parseDateFromServer(selectDate);
    setCurrentPickedDate((oldDate) => {
      return date.isValid() ? date : oldDate;
    });
  };
  useEffect(() => {
    if (projectStart) {
      setPickedDateValue(projectStart);
    }
  }, [projectStart]);

  useEffect(() => {
    const dateProjects = projects.filter(({ Start }) => parseDateFromServer(Start).isSame(currentPickedDate, 'date'));
    setProjectListToChoose(dateProjects);
  }, [projects]);

  const { onDatePick, getProject, onMonthChange, isDateLoading } = useDatepickProjectHandler({
    projectStart,
    currentPickedDate,
    handleShow,
    projects,
    setCurrentPickedDate,
    setProjectListToChoose,
    setStatus
  });

  return (
    <PlannerPlanningMenuComponent
      hasContentForProject={hasContentForProject}
      getProject={getProject}
      visible={visible}
      handleShow={handleShow}
      isFetching={isFetching}
      onDatePick={onDatePick}
      onCloseModal={onDatePick}
      currentPickedDate={currentPickedDate}
      isDateLoading={isDateLoading}
      projectList={projectListToChoose}
      onMonthChange={onMonthChange}
      status={status}
    />
  );
};
