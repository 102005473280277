import { EMPTY_STRING } from '@common/constants/general';
import { Adding, CellType, TableColumnName } from '@common/types';
import { ActiveTableParameters, ResizeDataType } from '@common/types/components/table/table';
import { ColumnType } from 'antd/es/table/interface';
import { TableLocale } from 'antd/lib/table/interface';

export { ROW_CLASS_DRAG } from './row';

export {
  API_ICON_PARAM,
  CASCADER_CELL_TYPES,
  CELL_TYPE_MIN_WIDTH,
  DATE_PICKER_CELL_TYPES,
  SELECT_CELL_TYPES,
  TIME_PICKER_CELL_TYPES
} from '@components/data-display/table/constants/cell';

export const TABLE_LOCALE: TableLocale = {
  triggerDesc: 'Нажмите для сортировки по убыванию',
  triggerAsc: 'Нажмите для сортировки по возрастанию',
  cancelSort: 'Нажмите, чтобы отменить сортировку'
};

export const INITIAL_COLUMN_RESIZE_DATA: ResizeDataType<any> = {
  x: null,
  column: null,
  startX: null,
  dictionary: null
};
export const NEW_ROW_KEY = 'new-row';
export const NEW_ROW_KEY_TO_ADD = 'addRow';
export const NEW_ROW = { key: NEW_ROW_KEY, update: false };
export const NEW_MODEL: Adding = NEW_ROW;
export const MULTIPLE_EDIT_ROW_CLASSNAME = 'multiple-edit-row';
export const ILS_TABLE_CLASSNAME = 'ils-table';
export const NOT_SORTER_CELL_TYPES = [
  CellType.IconAction,
  CellType.BadgeText,
  CellType.Button,
  CellType.ButtonClickableDropdown,
  CellType.CascaderConfigAPI,
  CellType.Countdown,
  CellType.Duration,
  CellType.LineSeries,
  CellType.MenuButton,
  CellType.SelectIconAPI,
  CellType.TextAction,
  CellType.Status
];
export const TABLE_COLUMN_MIN_WIDTH_BY_TYPE: { [key in CellType]: number } = {
  alert: 50,
  select: 50,
  'select-icon': 30,
  'select-api': 70,
  'select-multiple': 70,
  'select-multiple-api': 70,
  string: 40,
  int: 40,
  float: 40,
  color: 30,
  date: 50,
  input: 50,
  datetime: 50,
  time: 50,
  rangeTime: 90,
  bool: 30,
  phone: 70,
  'duration-view': 50,
  duration: 90,
  regNumber: 70,
  geo: 30,
  text: 50,
  icon: 30,
  percent: 50,
  email: 70,
  uint: 40,
  ufloat: 40,
  progress: 50,
  'icon-action': 30,
  'time-string-utc': 50,
  lineSeries: 70,
  'badge-text': 25,
  'menu-button': 25,
  button: 40,
  buttonClickableDropdown: 40,
  countdown: 40,
  status: 80,
  // cascader: 80,
  cascaderAPI: 80,
  [CellType.CascaderConfigAPI]: 80,
  'string-multiple': 80,
  'date-time-string': 50,
  'text-action': 70,
  'address-autocomplete': 120,
  [CellType.SelectIconAPI]: 30,
  [CellType.TimeDouble]: 50
};

export const TABLE_COLUMN_FIXED_POSITION: Record<string, ColumnType<any>['fixed']> = {
  left: 'left',
  right: 'right'
};

export const TITLED_CELLS: Array<CellType> = [
  CellType.Input,
  CellType.Percent,
  CellType.Int,
  CellType.Float,
  CellType.RangeTime,
  CellType.Date,
  CellType.DateTime,
  CellType.Time
];

export const isTitledCell = (cell: CellType): boolean => {
  return TITLED_CELLS.includes(cell);
};

export const YANDEX_MAPS_URL = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_MAPS_API_KEY}&lang=ru_RU`;

export const API_COLUMN_VARIANT = { value: EMPTY_STRING, label: EMPTY_STRING };
export const API_COLUMN_VARIANTS = [API_COLUMN_VARIANT];

export const ENUM_COLUMN_VARIANTS = [[undefined, undefined]];

export const LABEL_ALIAS = ['Name', 'ID'];

export const EMPTY_FILTER_CAPTION = '[пусто]';
export const DEFAULT_RECORDS_FROM_PAGE = 10;
export const MAX_RECORDS_FROM_PAGE = 100;
export const FIRST_PAGE = 1;
export const LETTER_WIDTH = 10;
export const EXTRA_SPACE_WIDTH_IN_COLUMN_HEADER = 23;
export const VISIBLE_VIRTUAL_ROWS_DEFAULT = 5;
export const SCROLL_Y_DEFAULT = 500;

export const TILDA_SEPARATOR = ' ~ ';

export const NOT_ELLIPSIS_COLUMN = [
  CellType.CascaderAPI,
  CellType.CascaderConfigAPI,
  CellType.Select,
  CellType.SelectAPI,
  CellType.SelectIcon,
  CellType.SelectMultiple,
  CellType.SelectMultipleApi
];

export const TIME_COLUMNS_TO_DOBLE = [
  TableColumnName.Delay,
  TableColumnName.UnloadStartOverload,
  TableColumnName.UnloadEndOverload,
  TableColumnName.LoadStartOverload,
  TableColumnName.LoadEndOverload,
  TableColumnName.MaxDuration,
  TableColumnName.DurationOfWork,
  TableColumnName.DurationOfRest,
  TableColumnName.DurationOfFirstWork,
  TableColumnName.DurationOfFirstRest,
  TableColumnName.WorkPerDay,
  TableColumnName.MaxDurationOverload,
  TableColumnName.MaxDurationRouteOverload,
  TableColumnName.MinStartTimeOverload,
  TableColumnName.StartTime,
  TableColumnName.EndTime,
  TableColumnName.LoadDuration,
  TableColumnName.UnloadDuration,
  TableColumnName.LoadStart,
  TableColumnName.LoadEnd,
  TableColumnName.UnloadStart,
  TableColumnName.UnloadEnd,
  TableColumnName.LunchStart,
  TableColumnName.LunchEnd,
  TableColumnName.MaxDurationRoute,
  TableColumnName.MinStartTime
];

export const cssBlocks = {
  fullWidth: {
    display: 'block',
    width: '100%'
  }
};

export const DEFAULT_ACTIVE_TABLE_PARAMETERS: ActiveTableParameters = {
  key: EMPTY_STRING,
  page: FIRST_PAGE,
  update: false
};

export const DEFAULT_FILTER_SEARCH_STATE = {
  searchText: EMPTY_STRING,
  searchedColumn: EMPTY_STRING,
  strictSearch: false
};
