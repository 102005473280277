import { FC } from 'react';
import { TenderPlatformCargoFieldsComponent } from '../../../../components/tender-card/lots/form/fields/cargo';
import { useEditableTab } from '@common/hooks/components/use-editable-tabs';
import { ILSTabs } from '@components/index';
import { LotTabName, LotTabTitle } from '@modules/tender-platform/types/lot';
import { useSelector } from 'react-redux';
import { currentTenderLotSelector } from '@modules/tender-platform/selectors';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { CardTab, CardTabTitle } from '@modules/tender-platform/constants';
import { TenderPlatformDividerTitle } from '@modules/tender-platform/components/tender-card/lots/form/divider-title';

interface IProps {
  disabled: boolean;
  fromPlanner: boolean;
}

export const TenderPlatformCargoContainer: FC<IProps> = ({ disabled, fromPlanner }) => {
  const tenderLot = useSelector(currentTenderLotSelector);
  const countCargo = createArrayFromIndexedArray(tenderLot?.TenderLotPosition)?.length;
  const route = {
    Component: TenderPlatformCargoFieldsComponent,
    props: { disabled, fromPlanner }
  };

  const { onEdit, onChange, tabs, activeKey } = useEditableTab({
    content: route,
    tabName: LotTabTitle.Cargo,
    keyName: LotTabName.Cargo,
    countChildren: countCargo,
    deps: [disabled]
  });

  return (
    <fieldset className={'tender-form-fieldset'}>
      <TenderPlatformDividerTitle id={CardTab.Cargo} title={CardTabTitle.Cargo} />
      {fromPlanner ? (
        <TenderPlatformCargoFieldsComponent disabled={disabled} fromPlanner={fromPlanner} number={0} />
      ) : (
        <ILSTabs type={!disabled && !fromPlanner ? 'editable-card' : 'card'} onChange={onChange} activeKey={activeKey} onEdit={onEdit}>
          {tabs.map(({ children }) => children)}
        </ILSTabs>
      )}
    </fieldset>
  );
};
