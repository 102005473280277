import React, { FC } from 'react';
import { ILSModal, ILSSkeleton } from '@common/components';
import './styles.less';
import { ReactSVG } from 'react-svg';
import ILSDocumentTemplateViewer from '@modules/documents/components/document-template-viewer';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { documentTemplatesSelector, getCurrentTemplate, getCurrentTemplateId } from '@modules/documents/children/templates/selectors';
import { resetCurrentTemplate, updateTemplateRoutine } from '@modules/documents/children/templates/actions';

const ILSViewDocumentTemplateModal: FC<{ open: boolean; handleClose: () => void }> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const { isFetching } = useAppSelector(documentTemplatesSelector);
  const template = useAppSelector(getCurrentTemplate);
  const templateId = useAppSelector(getCurrentTemplateId);

  const onReportUpdate = (templateBody: string) => {
    dispatch(updateTemplateRoutine({ templateId, template: { Data: templateBody } }));
  };

  const onCancel = () => {
    dispatch(resetCurrentTemplate());
    handleClose();
  };

  const buttons = [
    {
      key: 1,
      icon: '/assets/icons/close.svg',
      handleClick: onCancel
    }
  ];

  return (
    <ILSModal
      visible={open}
      onCancel={onCancel}
      footer={false}
      keyboard={false}
      closable={false}
      destroyOnClose={true}
      width={1300}
      className={'modal-body'}
    >
      <ILSSkeleton loading={isFetching} active>
        <div className="buttons-container">
          {buttons.map(({ icon, handleClick, key }) => (
            <ReactSVG key={key} onClick={handleClick} className={'button '} src={icon} />
          ))}
        </div>
        <ILSDocumentTemplateViewer templateData={template} onReportUpdate={onReportUpdate} />
      </ILSSkeleton>
    </ILSModal>
  );
};

export default ILSViewDocumentTemplateModal;
