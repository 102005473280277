import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/data/store';
import { getUploadedDataRoutine } from '@modules/planning/children/data/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const getUploadedDataReducer = createReducer(initialState, {
  [getUploadedDataRoutine.REQUEST]: onRequest,
  [getUploadedDataRoutine.SUCCESS]: (state, action) => {
    const { scheme, data, files } = action.payload;
    state.scheme = scheme;
    state.data = data;
    state.files = files;
    return state;
  },
  [getUploadedDataRoutine.FAILURE]: onFailure,
  [getUploadedDataRoutine.FULFILL]: onFulfill
});
