import { MonitoringSensorEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { eventMarker } from '@core/components/map/icons';
import { ILSMarker } from '@core/containers/map';
import { LayerHandler } from '@core/types/map';
import L from 'leaflet';
import { isEmpty } from 'lodash';

// Утилита для создания маркер события
export const createEventMarker = (
  marker: {
    ID: string;
    coords: [number, number];
    EventStatusType: MonitoringSensorEventStatusType;
  },
  handlers?: {
    [K: string]: LayerHandler<L.Marker>;
  }
) => {
  const { EventStatusType } = marker;
  const icon = eventMarker(EventStatusType);

  return !isEmpty(marker.coords)
    ? new ILSMarker({
        coords: marker.coords,
        options: {
          icon
        },
        handlers
      })
    : undefined;
};
