import { call, put, takeEvery } from 'redux-saga/effects';
import { getDocumentTemplatesRoutine } from '../actions';
import DocumentTemplatesAPI from '../api';

function* getDocumentTemplatesWorker() {
  const { request, success, failure, fulfill } = getDocumentTemplatesRoutine;

  try {
    yield put(request());

    const { data } = yield call(DocumentTemplatesAPI.indexDocumentTemplates);

    yield put(success({ data: Object.values(data.data) }));
  } catch (e) {
  } finally {
    yield put(fulfill());
  }
}

export function* getDocumentTemplatesWatcher() {
  yield takeEvery(getDocumentTemplatesRoutine.trigger, getDocumentTemplatesWorker);
}
