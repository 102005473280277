import { ILSButton, ILSTooltip } from '@common/components';
import { ReactSVG } from 'react-svg';
import React, { FC, useContext } from 'react';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';

interface Props {}

const ILSRoadGraphTestCleanButton: FC<Props> = (props) => {
  const { handleClean } = useContext(RoadGraphContext) ?? {};

  return (
    <ILSTooltip placement="right" title={'Очистить параметры маршрута'}>
      <ILSButton className={'map-control-btn'} onClick={handleClean} icon={<ReactSVG src={'/assets/icons/road-graph/delete.svg'} />} />
    </ILSTooltip>
  );
};
export default ILSRoadGraphTestCleanButton;
