import { FC } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { CurrencySign } from '@common/constants';
import { ILSDropdownButton, ILSSpin } from '@common/components';
import { PaymentMenuOptions } from '@common/feature/payment/constants/general';
import { ILSTypographyTitle } from '@common/components/general/typography';

interface IProps {
  balance?: string;
  balanceIsFetching: boolean;
  handleClickMenuItem: (menuInfo: MenuInfo) => void;
}

export const CurrentBalance: FC<IProps> = ({ balance, balanceIsFetching, handleClickMenuItem }) => {
  return (
    <ILSSpin spinning={balanceIsFetching}>
      <div className="current-balance">
        <ILSTypographyTitle level={1} className="current-balance-title">
          Текущий баланс
        </ILSTypographyTitle>
        <ILSDropdownButton
          text={`${balance} ${CurrencySign.RUB}`}
          items={PaymentMenuOptions}
          onMenuClick={handleClickMenuItem}
          button={{ type: 'default' }}
        />
      </div>
    </ILSSpin>
  );
};

