import { IILSTableHeaderProps } from '@common/components/data-display/table/components/ils-table-header';
import { ILSUserNotify, ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { inUseDecorator } from '@common/decorators/in-use';
import { ITablePropsFunction, Keys, Notify, NotifyDurationInSecond, SelectedRowKeys, TableRecord } from '@common/types';
import { getIdsWithoutGroupIds } from '@modules/catalog/helpers';
import { isString } from 'lodash';

const notifyConfirmation = ({
  message,
  decoratedText = undefined,
  extraData,
  onOk,
  onCancel
}: {
  message: string;
  decoratedText?: string[] | undefined;
  extraData?: {};
  onOk: any;
  onCancel: any;
}) => {
  ILSUserNotifyConfirm(
    message,
    decoratedText,
    () => {
      onOk(extraData);
    },
    onCancel
  );
};

export const onClickDeleteAndNotify = (
  confirmText: string,
  keys: Keys,
  records: TableRecord[],
  rowIndex?: number,
  handleDelete?: ITablePropsFunction<TableRecord>['handleDelete'],
  handleCheckDelete?: ITablePropsFunction<TableRecord>['handleCheckDelete'],
  setSelectedRowKeys?: IILSTableHeaderProps<TableRecord>['setSelectedRowKeys'],
  handleDisable?: (disabled: boolean) => void,
  selectedRowKeys?: SelectedRowKeys
) => {
  const handleConfirmDelete = (keys: Keys, extraData?: {}) => {
    handleDelete?.(keys, records, rowIndex, extraData);

    setSelectedRowKeys?.((prev: Keys) => {
      return prev.filter((row) => {
        return keys?.every((key) => {
          const isGroup = isString(row);
          const isElement = key === row;
          return !isElement && !isGroup;
        });
      });
    });
    handleDisable?.(false);
  };

  /** Дополнительная проверка, если удаляются группы элементов */
  const checkGroupingBeforeDelete = (extraData?: {}) => {
    const hasGrouping = selectedRowKeys?.some(isString) || (selectedRowKeys?.length === 1 && isString(keys[0]));
    if (hasGrouping) {
      let idsWithoutGroupIDs = getIdsWithoutGroupIds(selectedRowKeys, records);
      ILSUserNotifyConfirm(
        `Вы уверены что хотите удалить все вложенные элементы (${idsWithoutGroupIDs?.length})`,
        'подтвердите действие или выберите нужный элемент',
        () => {
          handleConfirmDelete(idsWithoutGroupIDs as Keys, extraData);
        }
      );
    } else {
      handleConfirmDelete(keys, extraData);
    }
  };

  // если удаление в строке таблицы, то делаем строку неактивной
  handleDisable?.(true);

  if (handleCheckDelete) {
    const response = handleCheckDelete(keys);
    ILSUserNotify(Notify.Loading, 'Проверка...', NotifyDurationInSecond.Three, 'check-delete');
    response
      .then((res) => {
        if (res && !!Object.keys(res.data.data).length) {
          ILSUserNotify(Notify.Loading, 'Проверка...', 1, 'check-delete');
          const decoratedText = inUseDecorator(res.data.data);
          notifyConfirmation({
            message: 'Внимание',
            decoratedText: decoratedText,
            extraData: res.data.data,
            onOk: checkGroupingBeforeDelete,
            onCancel: () => handleDisable?.(false)
          });
        } else {
          ILSUserNotify(Notify.Loading, 'Проверка...', 0.1, 'check-delete');
          notifyConfirmation({
            message: confirmText,
            decoratedText: undefined,
            extraData: undefined,
            onOk: checkGroupingBeforeDelete,
            onCancel: () => handleDisable?.(false)
          });
        }
      })
      .catch((error) => {
        ILSUserNotify(Notify.Error, error, NotifyDurationInSecond.Three, 'check-delete');
      });
  } else {
    notifyConfirmation({
      message: confirmText,
      decoratedText: undefined,
      extraData: undefined,
      onOk: checkGroupingBeforeDelete,
      onCancel: () => handleDisable?.(false)
    });
  }
};
