import { NotificationTip } from '@modules/planning/children/by-date/components/result/tips/notification';
import { PenaltyTip } from '@modules/planning/children/by-date/components/result/tips/penalty';
import { EfficiencyTip } from '@modules/planning/children/by-date/components/result/tips/efficiency';
import ReactDOMServer from 'react-dom/server';

const PenaltyTipHTML = (props) => {
  return ReactDOMServer.renderToString(<PenaltyTip {...props} />);
};
const NotificationTipHTML = (props) => {
  return ReactDOMServer.renderToString(<NotificationTip {...props} />);
};
const EfficiencyTipHTML = (props) => {
  return ReactDOMServer.renderToString(<EfficiencyTip {...props} />);
};

export { NotificationTip, NotificationTipHTML, PenaltyTip, PenaltyTipHTML, EfficiencyTip, EfficiencyTipHTML };
