import { ReactDispatch, TrackActiveData } from '@common/types';
import { ILSMap } from '@core/containers/map';
import L from 'leaflet';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect } from 'react';
import {
  clientClusterCustomIcon,
  customClusterCustomIcon,
  orderClusterCustomIcon,
  storageClusterCustomIcon
} from '../../decorators/map/cluster-to-ils-map';
import { MapLayers, SelectedOrdersLasso } from '../../types/map-container';

export const useMapInit = (
  Map: MutableRefObject<any>,
  mapZoomCluster: string | number | undefined,
  disableClustering: string | number | boolean | undefined,
  setLassoLayers: Dispatch<SetStateAction<any[] | null>>,
  setPolylineDataIDs: ReactDispatch<TrackActiveData>,
  selectedOrdersLasso: SelectedOrdersLasso,
  setMapLayersGroup: Dispatch<SetStateAction<MapLayers>>
) => {
  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    curMap.addRuler();
    curMap.addLasso(setLassoLayers);

    // Функция для отключения кластеризации,
    // для отключения при нужном масштабе
    const onZoomCallback = () => {
      let zoom = curMap.Map?.getZoom();
      if (zoom !== undefined) {
        if (zoom >= Number(mapZoomCluster)) {
          return 0;
        } else {
          return 80;
        }
      }
      return 80;
    };

    const clusterOptions = (mapZoomCluster || disableClustering) && {
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: true,
      spiderLegPolylineOptions: {
        weight: 0,
        opacity: 0
      },
      maxClusterRadius: disableClustering ? 0 : onZoomCallback
      //maxClusterRadius = 0 позволяет группировать только точки
      //по тем же координатам, как и задумывалось
    };
    const clusters: {
      orderCluster: L.MarkerClusterGroup | undefined;
      clientCluster: L.MarkerClusterGroup | undefined;
      cluster: L.MarkerClusterGroup | undefined;
      storageCluster: L.MarkerClusterGroup | undefined;
    } = {
      orderCluster: undefined,
      clientCluster: undefined,
      cluster: undefined,
      storageCluster: undefined
    };

    clusters.cluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: customClusterCustomIcon
      }
    ]);
    clusters.clientCluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: clientClusterCustomIcon
      }
    ]);
    clusters.orderCluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: (cluster: L.MarkerCluster) => orderClusterCustomIcon(cluster, selectedOrdersLasso)
      }
    ]);

    clusters.storageCluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: storageClusterCustomIcon
      }
    ]);
    const polygonGroup = curMap.addLayerGroup([]);
    const convexGroup = curMap.addLayerGroup([]);
    const polylineGroup = curMap.addLayerGroup([]);

    setMapLayersGroup({
      ...clusters,
      polylineGroup: polylineGroup,
      polygonGroup: polygonGroup,
      convexGroup: convexGroup,
      isInit: true
    });

    return () => Map.current.deinit();
  }, []);

  const onMouseLeave = useCallback(
    (target: HTMLElement) =>
      setTimeout(() => {
        //Если мы уходим за пределы карты
        if (!Map.current?.Map?.getContainer()?.contains(target as HTMLElement) || Map.current?.Map?.getContainer() === target) {
          setPolylineDataIDs(null);
        }
      }, 100),
    []
  );
  return { onMouseLeave };
};

export default { useMapInit };

