import { IndexedArray } from '@common/types';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { WidgetSize } from '../types/widget-position';
import { cloneDeep } from '../utils/clone-deep';

enum WidgetCorner {
  TopLeft = 'top-left',
  BottomLeft = 'bottom-left',
  TopRight = 'top-right',
  BottomRight = 'bottom-right'
}

type WidgetSizes = IndexedArray<{ ID: number | string; size: WidgetSize }>;

const nullToZero = (n: number | null | undefined): number => {
  if (!n) return 0;
  return n;
};

/* Хук возвращает функцию для получения изменения положение виджетов на доске. Функция принимает список виджетов доски и их актуальные размера контента и,
 * в случае пересечения - формирует список виджетов к изменению, после применяет к ним callback
 * @param widgets - виджеты приходящие с backend
 * @param actualSizes  - фактические размеры после отрисовки
 */
export const useCheckWidgetIntersect = () => {
  const getActualWidth = (widget: AnalyticsWidget, actualSizes: WidgetSizes): number => {
    if (!widget.ID) return 0;
    return nullToZero(widget.Position?.Left) + (actualSizes[widget.ID]?.size.x || nullToZero(widget.Size?.Width));
  };
  const getActualHeight = (widget: AnalyticsWidget, actualSizes: WidgetSizes): number => {
    if (!widget.ID) return 0;
    return nullToZero(widget.Position?.Top) + (actualSizes[widget.ID]?.size.y || nullToZero(widget.Size?.Height));
  };

  /*получения актуальных координат углов виджета, с учетом возможных изменений по ходу работы хука*/
  const getCorner = (
    widget: AnalyticsWidget,
    corner: WidgetCorner,
    actualSizes: WidgetSizes,
    changedWidgets: IndexedArray<AnalyticsWidget>
  ): { x: number; y: number } => {
    const w = changedWidgets[widget.ID] || widget;
    if (!w.Position) return { x: 0, y: 0 };
    switch (corner) {
      case WidgetCorner.TopLeft:
        return { y: w.Position?.Top, x: w.Position?.Left };
      case WidgetCorner.TopRight:
        return { x: getActualWidth(w, actualSizes), y: w.Position.Top };
      case WidgetCorner.BottomLeft:
        return { x: w.Position?.Left, y: getActualHeight(w, actualSizes) };
      case WidgetCorner.BottomRight:
        return { x: getActualWidth(w, actualSizes), y: getActualHeight(w, actualSizes) };
      default:
        return { x: 0, y: 0 };
    }
  };

  /**
   *  Возвращает виджеты с изменениями в положении. Если были пересечения, то виджет сдвигается вниз
   * @param actualSizes  актуальные размеры, если не указаны то считается из размеров в самих виджетах
   * @param widgets
   * @param returnIntersected -если true только массив пересекающихся виджетов. В случае false -возвращает массив всех виджетов с новым положением с  учетом смещения
   * @returns
   */
  const rerender = (actualSizes: WidgetSizes, widgets: IndexedArray<AnalyticsWidget>, returnIntersected?: boolean) => {
    const changedWidgets: IndexedArray<AnalyticsWidget> = {};
    const widgetsArray = Object.values(widgets);
    const intersectedWidgets: IndexedArray<AnalyticsWidget> = {};

    if (!widgets) {
      return;
    }

    const sortedWidgets = [...widgetsArray].sort((a, b) => (a.Position?.Top || 0) - (b.Position?.Top || 0));
    sortedWidgets.forEach((aWidget, indx) => {
      const aTopLeft = getCorner(aWidget, WidgetCorner.TopLeft, actualSizes, changedWidgets);
      const aTopRight = getCorner(aWidget, WidgetCorner.TopLeft, actualSizes, changedWidgets);
      const aBottomLeft = getCorner(aWidget, WidgetCorner.BottomLeft, actualSizes, changedWidgets);
      const aBottomRight = getCorner(aWidget, WidgetCorner.BottomRight, actualSizes, changedWidgets);

      for (let i = indx + 1; i < sortedWidgets.length; i++) {
        let bWidget = sortedWidgets[i];

        const bTopLeft = getCorner(bWidget, WidgetCorner.TopLeft, actualSizes, changedWidgets);
        const bTopRight = getCorner(bWidget, WidgetCorner.TopRight, actualSizes, changedWidgets);
        const bBottomLeft = getCorner(bWidget, WidgetCorner.BottomLeft, actualSizes, changedWidgets);
        const bBottomRight = getCorner(bWidget, WidgetCorner.BottomRight, actualSizes, changedWidgets);
        //проверяем по y
        let yMove = 0;

        if (bTopLeft.y < aBottomLeft.y) {
          if (
            (bTopLeft.x >= aBottomLeft.x && bTopLeft.x <= aBottomRight.x) /*если верхние углы b внутри a*/ ||
            (bTopRight.x >= aBottomLeft.x && bTopRight.x <= aBottomRight.x) /*если верхние углы b внутри a*/ ||
            (aTopLeft.x >= bBottomLeft.x && aTopLeft.x <= bBottomRight.x) /*если нижние углы a внутри b*/ ||
            (aTopRight.x >= bBottomLeft.x && aTopRight.x <= bBottomRight.x) /*если нижние углы a внутри b*/
          ) {
            yMove = aBottomLeft.y - bTopLeft.y;
          }
        }
        if (yMove > 0) {
          const changedWidget = changedWidgets[bWidget.ID] || cloneDeep(bWidget);
          changedWidget.Position = {
            Top: aBottomLeft.y + 5,
            Left: nullToZero(changedWidget.Position?.Left)
          };
          intersectedWidgets[aWidget.ID] = aWidget;
          intersectedWidgets[bWidget.ID] = bWidget;
          if (changedWidget.ID) changedWidgets[changedWidget.ID] = changedWidget;
        }

        //todo: проверку по x
      }
    });
    if (returnIntersected) return intersectedWidgets;
    return changedWidgets;
    // if (Object.keys(changedWidgets).length > 0) resizeCallback(changedWidgets);
  };

  return { rerender };
};
