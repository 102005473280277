import { ILSButton, ILSCheckbox, ILSForm, ILSFormItem, ILSInput, ILSRow } from '@common/components';
import {
  CellType,
  ColumnTable,
  Dictionary,
  ITablePropsFunction,
  Option,
  ReferenceTables,
  ReferenceTablesData,
  TableColumnName,
  TableConfig,
  TableRecord
} from '@common/types';
import { Reference, SetShowReference } from '@common/types/components/table/common';
import { ILSReferenceCellButton } from '@components/data-display/table/components/ils-cell-reference-button';
import { NEW_ROW_KEY_TO_ADD } from '@components/data-display/table/constants';
import { getReferenceData } from '@components/data-display/table/utils/helpers/get-reference-data';
import { ILSCascaderApi } from '@components/data-entry/cascader/cascader-api';
import { ILSSelectApi } from '@components/data-entry/select/select-api';
import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { useForm } from 'antd/es/form/Form';
import { filterOption } from '@components/data-entry/select/helpers/filter-option';
import { setInitial } from '@components/data-display/table/utils/helpers/get-initial-notification-settings';

const renderFormItem = ({
  column,
  refData,
  dictionaryLoad
}: {
  column: ColumnTable<TableRecord>;
  refData?: ReferenceTablesData;
  dictionaryLoad(dictionary: Dictionary): void;
}) => {
  switch (column.type) {
    case CellType.SelectAPI:
      const {
        referenceData: { dictionary },
        columnOptions: options,
        fieldNames: simpleFieldNames
      } = getReferenceData({ column, refData });
      return (
        <ILSSelectApi
          filterOption={filterOption(simpleFieldNames)}
          dictionaryLoad={dictionaryLoad}
          fieldNames={simpleFieldNames}
          dictionary={dictionary}
          options={options}
        />
      );
    case CellType.CascaderAPI:
      const { referenceData, columnOptions, fieldNames } = getReferenceData({ column, refData });
      return (
        <ILSCascaderApi
          dictionaryLoad={dictionaryLoad}
          fieldNames={fieldNames}
          dictionary={referenceData.dictionary}
          options={columnOptions}
        />
      );
    case CellType.Bool:
      return <ILSCheckbox />;
    default:
      return <ILSInput />;
  }
};
const renderRefButtonItem = <R extends TableRecord>({
  reference,
  dataIndex,
  type,
  setShowReference
}: {
  reference: Reference<R>;
  dataIndex: string;
  type: CellType;
  setShowReference: SetShowReference<R>;
}) => {
  if (!reference) return null;
  return <ILSReferenceCellButton reference={reference} recordDataIndex={dataIndex} setShowReference={setShowReference} cellType={type} />;
};

interface IProps<R extends TableRecord> {
  columns: TableConfig['columns'];

  handleCreate: ITablePropsFunction<R>['handleCreate'];
  dictionaryLoad?: ITablePropsFunction<R>['dictionaryLoad'];

  refData?: ReferenceTablesData;
  handleSave?: ITablePropsFunction<R>['handleSave'];
  refTable?: ReferenceTables;
  setShowReference: SetShowReference<R>;

  handleClose(): void;

  dictionary?: Dictionary;
}

export const ILSTableAddingForm = <R extends TableRecord>({
  columns,
  handleCreate,
  handleClose,
  dictionaryLoad,
  refData,
  refTable,
  setShowReference,
  dictionary
}: IProps<R>): JSX.Element => {
  const { catalogLoad } = useCatalog();
  const [form] = useForm();

  const formKey = NEW_ROW_KEY_TO_ADD + dictionary;

  const requiredColumns = columns.slice().filter(({ required }) => required);
  //TODO universal function
  const createRow = (values: Record<TableColumnName, unknown>) => {
    const payload = Object.entries(values).reduce((payload, [dataIndex, value]) => {
      switch (dataIndex) {
        case TableColumnName.StartDepotID:
        case TableColumnName.EndDepotID:
        case TableColumnName.TargetID:
        case TableColumnName.SourceID:
        case TableColumnName.DepotID:
          payload[dataIndex] = (value as [number, number])[1];
          break;
        case TableColumnName.CargoID:
        case TableColumnName.RampTypeID:
          payload[dataIndex] = (value as Option).value;
          break;
        case TableColumnName.IsWeight:
          payload[dataIndex] = Number(value || 0);
          break;
        default:
          payload[dataIndex] = value;
          break;
      }
      return payload;
    }, {});
    setInitial(form);
    handleCreate?.(payload);
    handleClose();
  };

  return (
    <ILSForm name={formKey} onFinish={createRow} title={'Добавление новой строки'} form={form}>
      {requiredColumns.map((column) => {
        return (
          <div key={column.key} className="adding-form-item-row">
            <ILSFormItem
              required={column.required}
              key={column.dataIndex}
              name={column.dataIndex}
              label={column.title || column.titleText}
              valuePropName={column.type === CellType.Bool ? 'checked' : 'value'}
            >
              {renderFormItem({ column, dictionaryLoad: dictionaryLoad ?? catalogLoad, refData })}
            </ILSFormItem>
            {renderRefButtonItem({
              dataIndex: column.dataIndex,
              reference: refTable?.[column.dataIndex],
              setShowReference,
              type: column.type
            })}
          </div>
        );
      })}
      <ILSRow justify={'space-around'}>
        <ILSButton type={'primary'} htmlType={'submit'} form={formKey}>
          OK
        </ILSButton>
        <ILSButton htmlType={'reset'} onClick={handleClose}>
          Отмена
        </ILSButton>
      </ILSRow>
    </ILSForm>
  );
};
