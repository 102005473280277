import { FC } from 'react';
import { hideEmptyColumns } from '@common/utils/table';
import { CellType, DateFormat, ReferenceTables, RoadTrainUploadFileType, VehicleTableColumn, VehicleUploadFileType } from '@common/types';
import { ILSPlanningDataViewTable } from '@modules/data-validate/containers/table';
import { IDataTableProps } from '@modules/planning/children/data/types/components';
import { ILSPlanningDataRoadTrainTable } from '@modules/data-validate/components/table/tables/road-train';
import { useStatic } from '@common/hooks/use-static';
import { plannerDataVehicleToTable } from '@common/models/vehicle/decorators';

export const ILSDataViewVehicleTable: FC<IDataTableProps> = ({
  dataSource,
  referenceDataSource,
  config,
  dictionary,
  hideEmpty,
  ...props
}) => {
  const roadTrainFileDataRef = useStatic(referenceDataSource);
  const refTable = {
    [VehicleTableColumn.RoadTrain]: {
      title: 'Редактирование автосцепки',
      component: ILSPlanningDataRoadTrainTable
    }
  };

  const isEdit = ({ VehicleCode }: VehicleUploadFileType, dataIndex: string | number) => {
    let error = null;
    switch (dataIndex) {
      case VehicleTableColumn.FrontCouplingTypeCouplingCode:
        error = true;
        break;
      case VehicleTableColumn.FrontCouplingTypeName:
        error = true;
        break;
      case VehicleTableColumn.RearCouplingTypeCouplingCode:
        error = true;
        break;
      case VehicleTableColumn.RearCouplingTypeName:
        error = true;
        break;
      case VehicleTableColumn.IsTrailer:
        error = true;
        break;
    }

    if (
      error &&
      !!roadTrainFileDataRef.current.filter(
        (roadTrain: RoadTrainUploadFileType) =>
          VehicleCode === roadTrain['Vehicle.VehicleCode'] ||
          VehicleCode === roadTrain['Trailer1.VehicleCode'] ||
          VehicleCode === roadTrain['Trailer2.VehicleCode']
      ).length
    ) {
      return 'Запись не может быть отредактирована автомобиль(прицеп) используется в автосцепке';
    }
    return false;
  };

  const tableValue2Data = (dataIndex: VehicleTableColumn, value: string) => {
    switch (dataIndex) {
      case VehicleTableColumn.IsTrailer:
        return Number(value) ? String(0) : String(1);
      default:
        return String(value);
    }
  };

  const tableConfig = hideEmptyColumns(dataSource, config, hideEmpty);

  return (
    <ILSPlanningDataViewTable
      hideEmpty={hideEmpty}
      dictionary={dictionary}
      config={{
        ...tableConfig,
        columns: tableConfig.columns.map((c) => ({
          ...c,
          dateParseFormat: c.type === CellType.Date ? DateFormat.DDMMYYYYPeriod : undefined
        }))
      }}
      dataSource={plannerDataVehicleToTable(dataSource as VehicleUploadFileType[], dictionary)}
      refTable={refTable as ReferenceTables}
      isEdit={isEdit}
      tableValue2Data={tableValue2Data}
      {...props}
    />
  );
};
