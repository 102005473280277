import { TableColumnName } from '@common/types';
import { getDefaultLabelByDictionary, getEnumField, getEnumOption } from '@common/utils';
import { API_COLUMN_VARIANT } from '@components/data-display/table/constants';
import { getDictionaryFromField } from '@common/utils/helpers/object/get-enum-field';

export const getDefaultOptionForSelect = <T extends Partial<Record<TableColumnName, any>>>({
  record,
  dataIndex
}: {
  record: T;
  dataIndex: TableColumnName | string;
}) => {
  if (record[getEnumField(dataIndex)]) {
    const enumValue = getEnumOption(record, dataIndex);
    if (enumValue.value) {
      const value = Number(enumValue.value);
      const label = enumValue.label ?? getDefaultLabelByDictionary(value);
      return { label, value };
    } else {
      return API_COLUMN_VARIANT;
    }
  } else if (record[dataIndex]) {
    const value = Number(record[dataIndex]);
    const label = getDefaultLabelByDictionary(value, getDictionaryFromField(dataIndex));
    return { label, value };
  } else {
    return API_COLUMN_VARIANT;
  }
};
