import { CellType, ShowDeleted, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const NEW_DEPOT_OVERLOAD = {
  Name: 'Новый набор допусков'
};

export const tableConfig: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_DEPOT_OVERLOAD,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      ellipsis: true,

      sorting: true,
      filtering: 'string'
    },
    {
      title: 'Превыш.нач.разгрузки',
      dataIndex: 'UnloadStartOverload',
      key: 'UnloadStartOverload',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 180,
      align: 'center',
      isFixedColumn: true
    },
    {
      title: 'Превыш.конц.разгрузки',
      dataIndex: 'UnloadEndOverload',
      key: 'UnloadEndOverload',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 180,
      align: 'center',
      isFixedColumn: true
    },
    {
      title: 'Превыш.нач.погрузки',
      dataIndex: 'LoadStartOverload',
      key: 'LoadStartOverload',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 180,
      align: 'center',
      isFixedColumn: true
    },
    {
      title: 'Превыш.конц.погрузки',
      dataIndex: 'LoadEndOverload',
      key: 'LoadEndOverload',
      editable: true,
      active: true,
      type: CellType.TimeDouble,
      width: 180,
      align: 'center',
      isFixedColumn: true
    }
  ]
};
