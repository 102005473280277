import { flatModules } from '@core/modules';
import { IModuleModel } from '@core/types/models';
import ILSSidebarMenuComponent from '@modules/sidebar/components/sidebar-menu';
import { checkVisibility } from '@modules/sidebar/helpers/check-visiblity';
import { Module, Props } from '@modules/sidebar/types/component';
import { IComposedModule } from '@modules/sidebar/types/sidebar-menu';
import { matchRoutes } from 'react-router-config';
import { generate } from 'shortid';
import { ModulePath } from '@common/types';

const isModuleAvailableForRole = (
  permission: Array<string> | string | null,
  roles: Array<any>,
  rules: { [x: string]: { [x: string]: any } },
  isAvailableItem: boolean = false
) => {
  if (permission) {
    roles?.forEach((role) => {
      if (Array.isArray(permission)) {
        permission.forEach((perm: string) => {
          if (rules && rules?.[role]?.[perm]) {
            isAvailableItem = true;
          }
        });
      } else {
        if (rules && rules?.[role]?.[permission]) {
          isAvailableItem = true;
        }
      }
    });
  }
  return isAvailableItem;
};

const composeMenuItem: (
  { icon, title, path, component, exact, children }: IModuleModel,
  parentPath: string,
  type?: 'group',
  submenu?: Array<IComposedModule>
) => IComposedModule = ({ icon, title, path, component, exact, children }, parentPath, type?, submenu?) => {
  const p = path ?? generate();
  const pathname = parentPath === ModulePath.Dashboard ? p : parentPath + p;

  return {
    key: pathname,
    icon,
    children,
    title,
    type,
    pathname,
    exact,
    component,
    submenu
  };
};

export const siderMenuRenderer = (
  modules: Module[],
  { rules, roles, route: { path, routes }, location: { pathname }, collapsed, toggleCollapsed }: Props,
  isValidateOnImport: boolean
) => {
  const location = routes && matchRoutes(routes, pathname);
  const activeKey = location?.length ? location[location.length - 1].match.path : null;
  const navMenu = modules
    .filter((x) => checkVisibility(x))
    .map((module) => {
      const { children, permission } = module;
      let navItem: IComposedModule = composeMenuItem(module, path);
      if (Object.keys(children).length) {
        const subParentPath = module.path ? (path === ModulePath.Dashboard ? module.path : path + module.path) : path;
        navItem = {
          ...navItem,
          submenu: flatModules(children)
            .filter(
              ({ path, permission }) =>
                !(path === '/validate' && !isValidateOnImport) && (!permission || isModuleAvailableForRole(permission, roles, rules))
            )
            .map((module) => composeMenuItem(module, subParentPath))
        };
      }
      //проверяем, доступен ли модуль
      return !permission || isModuleAvailableForRole(permission, roles, rules) ? navItem : undefined;
    })
    .filter(Boolean);

  return <ILSSidebarMenuComponent collapsed={collapsed} toggleCollapsed={toggleCollapsed} navMenu={navMenu} activeKey={activeKey} />;
};
