import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { checkTokenRoutine } from '@modules/current-user/actions';
import { UserAuth } from '@common/types';
import { deepEqual } from 'fast-equals';

export const checkTokenReducer = createReducer(authInitialState, {
  [checkTokenRoutine.REQUEST]: onRequest,
  [checkTokenRoutine.FAILURE]: onFailure,
  [checkTokenRoutine.FULFILL]: onFulfill,
  [checkTokenRoutine.SUCCESS]: (state, action: PayloadAction<{ token: string; user: UserAuth }>) => {
    const { token, user } = action.payload;
    //записывать токен?
    state.checkToken = token;
    if (!deepEqual(state.user?.UserRole, user?.UserRole)) state.user = user;
    return state;
  }
});
