import { ILSButton, ILSModal } from '@common/components';
import { TenderButtonText } from '@modules/tender-platform/constants/content';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentTenderLotSelector } from '@modules/tender-platform/selectors';
import { useModal } from '@common/hooks/components/use-modal';
import { tenderOfferDeleteRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';

interface IProps {
  setStatus(): void;
}

export const ILSTenderOfferCancelButton: FC<IProps> = ({ setStatus }) => {
  const dispatch = useDispatch();
  const { handleShow, visible } = useModal();
  const tenderLot = useSelector(currentTenderLotSelector).ID;
  const handleClick = () => handleShow?.();
  const modalText = 'Отмена участия понизит Ваш рейтинг на платформе. Вы уверены, что хотите отменить участие в данном тендере?';

  const handleSubmit = () => {
    dispatch(tenderOfferDeleteRoutine({ tenderLotID: tenderLot, changeRating: 1 }));
    setStatus();
    handleShow();
  };
  //TODO использовать TenderPlatformActionsModalContainer
  return (
    <>
      <TenderPlatformMenuButton htmlType={'submit'} onClick={handleClick} children={TenderButtonText.CancelOffer} />
      <ILSModal
        onCancel={handleShow}
        className={'tender-offer-modal'}
        footer={
          <>
            <ILSButton onClick={handleSubmit}>Да</ILSButton>
            <ILSButton onClick={handleShow}>Нет</ILSButton>
          </>
        }
        title={'Отмена участия в тендере'}
        visible={visible}
      >
        <p>{modalText}</p>
      </ILSModal>
    </>
  );
};
