import { call, put, takeEvery } from 'redux-saga/effects';
import { trackGetItemRoutine } from '../actions';
import { Track } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, PlanID, Trip, Vehicle, Waypoint } from '@common/types';
import history from '@common/utils/history';

function* trackGetPlanWorker(action: PayloadAction<{ dictionary: Dictionary }>) {
  const { request, success, failure, fulfill } = trackGetItemRoutine;
  const { dictionary } = action.payload;

  try {
    const queryParams = new URLSearchParams(history.location.search);
    const planID = Number(queryParams.get('plan'));
    const tripID = Number(queryParams.get('trip'));
    const currentWaypointID = Number(queryParams.get('waypoint'));
    const prevWaypointID = Number(queryParams.get('prevWaypoint'));
    const vehicleID = Number(queryParams.get('vehicle'));
    const waypointID = [currentWaypointID, prevWaypointID].filter(Boolean);
    yield put(request({ dictionary }));

    if (planID) {
      const params: {
        dictionary: Dictionary;
        waypointID?: Waypoint['ID'] | Array<Waypoint['ID']>;
        vehicleID?: Vehicle['ID'] | Array<Vehicle['ID']>;
        planID?: PlanID;
        tripID?: Trip['ID'] | Array<Trip['ID']>;
      } = { dictionary, tripID, planID, waypointID, vehicleID };

      const {
        data: { data }
      } = yield call(Track.getTotal, params);

      yield put(success({ dictionary, data }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* trackGetPlanWatcher() {
  yield takeEvery(trackGetItemRoutine.trigger, trackGetPlanWorker);
}
