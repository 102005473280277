import { TimeStamp } from '../general';
import { Account, AccountRole } from './account';
import { Driver } from './driver';
import { IEnum, IndexedArray } from './enum';
import { DocFilesTypes } from '@modules/documents/constants/document-file-type';
import { IOption } from '@common/types/components/table/common';
import { ITimezone } from 'react-timezone-select';

export enum UserRole {
  manager = 'manager',
  driver = 'driver',
  administrator = 'administrator',
  guest = 'guest'
}

export enum UserRoleLabel {
  manager = 'Логист',
  driver = 'Водитель',
  administrator = 'Администратор',
  guest = 'Посетитель'
}

export type Attachment = {
  ID: number;
  Subject: string;
  UrlOriginal: string | null;
  UrlThumbnail: string | null;
  OriginalName: string;
  Extension: DocFilesTypes;
  DependentID?: number;
  UrlPrivate?: string | null;
};

export type UserAuth = Omit<User, 'Attachments' | 'AccountEnum'> & {
  Avatar: string | null;
};

export type User = {
  ID: number;
  AccountID: Account['ID'];
  Account?: {
    AccountRole: Array<AccountRole> | null;
  };
  Name: string;
  Login: string;
  Token: string;
  DriverID: Driver['ID'];
  Phone: string;
  Created: TimeStamp | null;
  Blocked: TimeStamp | null;
  UserRole: Array<UserRole>;
  AccountEnum?: IEnum<Account['Name']>;
  Attachments?: Array<Attachment> | IndexedArray<Attachment>;
  TimeZone?: any;
  Info: {
    timeZone: ITimezone;
  } | null;
  UserRoleID?: Array<string>;
};

export type UserLoggedIn = {
  ID: number;
  AccountID: Account['ID'];
  Account: Omit<Account, 'Attachments' | 'ProjectCount'>;
  Name: string;
  Login: string;
  Token: string;
  DriverID: Driver['ID'];
  Phone: string;
  Created: TimeStamp | null;
  Blocked: TimeStamp | null;
  UserRole: Array<UserRole>;
  Info: {
    timeZone: string;
  };
  Avatar?: string | null;
};

export type UserSimple = Pick<User, 'ID' | 'AccountID' | 'Name' | 'Login' | 'Phone'>;
export type Users = Array<User>;
export type UserRoles = Array<UserRole>;
export type UserRoleOption = IOption<UserRole, UserRoleLabel>;
export type UserRoleOptions = Array<UserRoleOption>;
