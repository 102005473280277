import { takeLatest } from 'redux-saga/effects';
import { CatalogActions, catalogRoutinesWithNotifier } from '../actions';
import { getNotificationMessage } from '../helpers/get-notification-message';
import { Dictionary } from '@common/types';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { getErrorMessage } from '@common/utils/helpers/notifier/get-error-message';

/**метод получения префикса индентификатора для уведомления*/
const getKeyPrefix = (payload: { dictionary: Dictionary }) => {
  return payload.dictionary.toString();
};

/**метод преобразования типа экшена к типу операции из перечисления Action
 * @param actionName - например CREATE, UPDATE, DELETE, CLONE....
 */
const getActionFromRoutine = (actionName: string): CatalogActions => {
  return actionName as CatalogActions;
};

let worker = notifierWorkerCreator<{ dictionary: Dictionary; notificationMessage?: string }, CatalogActions>({
  getActionFromRoutine,
  getKeyPrefix,
  getNotificationMessage
});

let errorWorker = notifierWorkerCreator<{ error: string | string[]; dictionary: Dictionary; notificationMessage?: string }, CatalogActions>(
  {
    getActionFromRoutine,
    getKeyPrefix,
    getNotificationMessage,
    getErrorMessage
  }
);

export function* catalogNotifierWatcher() {
  for (let routine of catalogRoutinesWithNotifier) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
