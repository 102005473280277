enum DocTableColumn {
  'Type' = 'TypeName',
  'Created' = 'Created',
  'TenderLot' = 'depends[TenderLot]',
  'CitySender' = 'meta[CitySender]',
  'CityRecipient' = 'meta[CityRecipient]',
  'Route' = 'meta[Route]',
  'Contractor' = 'depends[Contractor]',
  'ContractCost' = 'meta[ContractCost]',
  'Sign' = 'meta[Sign]',
  'Payment' = 'meta[Payment]',
  'Execution' = 'meta[Execution]',
  'Contract' = 'depends[Contract]',
  'CargoSender' = 'depends[CargoSender]',
  'CargoReceiver' = 'depends[CargoReceiver]',
  'Vehicle' = 'depends[Vehicle]',
  'DepotStart' = 'depends[DepotStart]',
  'DepotEnd' = 'depends[DepotEnd]',
  'FileName' = 'meta[FileName]'
}

export const columnsWithFixedWidth = {
  [DocTableColumn.Created]: {
    maxWidth: 110,
    width: 110
  },
  [DocTableColumn.TenderLot]: {
    maxWidth: 100,
    width: 100
  },
  [DocTableColumn.ContractCost]: {
    maxWidth: 100,
    width: 100
  },
  [DocTableColumn.Sign]: {
    maxWidth: 137,
    width: 137
  },
  [DocTableColumn.Payment]: {
    maxWidth: 120,
    width: 120
  },
  [DocTableColumn.Execution]: {
    maxWidth: 137,
    width: 137
  }
};

