import moment, { Moment, MomentInput } from 'moment';
import { ILS_DATE_PICKER_MULTIPLE_DISABLE_STYLE } from '@components/data-entry/datepicker-multiple';
import { ILS_CALENDAR_PICKER_BASE, ILS_CALENDAR_PICKER_DISABLED_PICKED } from '@components/data-entry/calendar';
import { HandleMapDaysArgument } from '@components/data-entry/calendar/types';

export const mapCalendarDayOfShipment = (
  { date }: HandleMapDaysArgument,
  {
    disabledDate,
    disabled,
    numberOfShipments
  }: {
    disabled: boolean;
    numberOfShipments: Array<MomentInput> | undefined;
    disabledDate(current: Moment): boolean;
  }
) => {
  const current = moment.unix(date.unix);
  if (disabledDate(current)) {
    return ILS_DATE_PICKER_MULTIPLE_DISABLE_STYLE;
  }
  if (disabled) {
    if (numberOfShipments?.find((shipment) => moment(shipment).isSame(current))) {
      return ILS_CALENDAR_PICKER_DISABLED_PICKED;
    } else {
      return ILS_DATE_PICKER_MULTIPLE_DISABLE_STYLE;
    }
  } else {
    return ILS_CALENDAR_PICKER_BASE;
  }
};
