import { ILSUserNotify } from '@common/components';
import { EMPTY } from '@common/constants';
import { ITablePropsFunction, Notify, TableColumnName } from '@common/types';
import { validateRow } from '@common/utils/table';
import { catalogCloneRoutine, catalogCreateLocalRoutine, catalogCreateRoutine, catalogReadRoutine } from '@modules/catalog/actions';
import { IRecord } from '@modules/catalog/types/catalog';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { MutableRefObject } from 'react';
import { useCatalogTableDataSourceSelector } from '../use-catalog-table-data-source-selector';
import { handleUpdateRow } from '@modules/catalog/helpers/table/handlers/handle-update-row';
import { isNeedLocalSaveRecord } from '@modules/catalog/helpers/is-need-local';
import { handleDeleteRow } from '@modules/catalog/helpers/table/handlers/handle-delete-row';
import { handleCheckDeleteRows } from '@modules/catalog/helpers/table/handlers/handle-check-delete-rows';
import { restoreTableRows } from '@modules/catalog/helpers/table/restore-table-rows';
import { useDispatch } from 'react-redux';

type Args = {
  dataSource: Exclude<IPropsILSCatalogTable['dataSource'], undefined>;
  lastDeletedRowRef: MutableRefObject<{ index: null; id: null } | null>;
  dataSourceForTable: ReturnType<typeof useCatalogTableDataSourceSelector>['dataSourceForTable'];
} & Pick<
  IPropsILSCatalogTable,
  'onCheckSaveError' | 'handleDecorator' | 'dictionary' | 'config' | 'saveDictionary' | 'filterID' | 'setRemoveAddRow' | 'showDeleted'
>;
export const useTableHandlers = ({
  onCheckSaveError,
  handleDecorator,
  dictionary,
  config,
  saveDictionary,
  filterID,
  setRemoveAddRow,
  dataSource,
  lastDeletedRowRef,
  showDeleted,
  dataSourceForTable
}: Args) => {
  const dispatch = useDispatch();

  const handleSave = handleUpdateRow({ dispatch });

  const handleTableRestore = restoreTableRows({ dictionary, saveDictionary, dataSourceForTable, dispatch });

  const handleCopy: ITablePropsFunction<any>['onCopy'] = ({ rows }) => dispatch(catalogCloneRoutine({ dictionary, ids: rows }));

  /**
   * TODO const firstID = ids ? parseInt('' + ids[0]) : null такой парсинг может приводить к ошибкам, необходимо заменить на другой хелпер
   * Функция изменения значения в строке
   * Создает новую строку с перезаписанным значением
   * происходит валидация, и сохранение ошибки
   * проверка на ошибку, если она есть то выводит уведомление и выходит из функции
   * если есть record?.ID или record?.AccountID и успешна пройдена волидация
   * то сохраняется сохраняем изменения
   *
   * иначе создаем новую запись
   */
  const handleTableSave: ITablePropsFunction<any>['handleSave'] = (record, dataIndex: TableColumnName, value) => {
    let newRow = {
      ...record,
      [dataIndex]: value
    };
    const error = onCheckSaveError?.(record, dataIndex);

    if (error) {
      if (typeof error === 'string') ILSUserNotify(Notify.Error, error);
      return;
    }
    //если запись существует, сохраняем изменения
    if (record?.ID || record?.AccountID) {
      value = handleDecorator?.(record, dataIndex, value) ?? value;

      const values = { [dataIndex]: value };

      handleSave?.(dictionary, record, values, saveDictionary);
    }
    //иначе пытаемся создать запись
    else {
      handleTableCreate(newRow);
    }
  };

  /**
   * TODO const firstID = ids ? parseInt('' + ids[0]) : null такой парсинг может приводить к ошибкам, необходимо заменить на другой хелпер
   * Функция для создания строки в таблице
   * Добавляет в конец данных строку с данными из конфигурации либо пустым объектом
   * Использует валидацию по конфигурации, в случае если строка будет валидна
   * то вызовет колбэк handleCreate и сохранит запись локально
   * иначе только сохранит запись в локальном стейте
   * Если передать filterID и в config.adding будет поле id,
   * то в новой строке будет поле id равное filterID
   * Если ID === EMPTY сохраняем запись локально, без отправки на сервер
   * */

  const handleTableCreate = (newRow: IRecord) => {
    if (filterID) {
      Object.keys(config?.adding || {}).forEach((key) => {
        if (key.toLowerCase().includes('id')) {
          newRow = {
            ...newRow,
            [key]: filterID
          };
        }
      });
    }
    const valid = validateRow(newRow, config?.columns);
    if (valid) {
      let createRow = handleDecorator
        ? Object.entries(newRow).reduce((result, [dataIndex, value]) => {
            result[dataIndex] = handleDecorator(newRow, dataIndex, value);
            return result;
          }, newRow)
        : newRow;
      const payload = {
        dictionary,
        values: [createRow],
        saveDictionary
      };
      const isLocal = isNeedLocalSaveRecord({ dictionary, data: createRow });
      if (createRow.ID === EMPTY || isLocal) {
        dispatch(catalogCreateLocalRoutine(payload));
      } else {
        setRemoveAddRow?.(true);
        dispatch(catalogCreateRoutine(payload));
      }
      setRemoveAddRow?.(false);
    }
  };

  const saveLastDeletedRowRef = (ids: (number | string)[] | null) => {
    if (ids) {
      lastDeletedRowRef.current = dataSource?.reduce(
        (prev: any, row: any, index: any) => {
          if (row.ID === ids?.[0]) {
            return {
              index,
              id: ids?.[0]
            };
          }
          return prev;
        },
        { index: null, id: null }
      );
    }
  };

  /**
   * Функция перезагрузки данных
   * загружает новые данные
   */
  const handleTableReload = (_: Array<string | number>, showDeleted?: number) => {
    const payload = {
      dictionaryFetching: true,
      withoutNotification: false,
      dictionary,
      showDeleted,
      rowIDToFocus: lastDeletedRowRef?.current?.id
    };
    dispatch(catalogReadRoutine(payload));
    lastDeletedRowRef.current = null;
  };

  /**
   * Функция удаления строки из таблицы.
   * Запоминает последнюю удаленную строку в lastDeletedRowRef (ее index и id), для смены фокуса
   * если передан числовой ид, то удаляем запись из бд
   * также отправляем handleDelete если в ids есть EMPTY для виртуального удаления
   * */
  const handleTableDelete: ITablePropsFunction<any>['handleDelete'] = (ids) => {
    if (!ids) return;
    //NOTE:
    saveLastDeletedRowRef(ids);
    handleDeleteRow({
      dictionary,
      ids,
      values: [],
      showDeleted,
      saveDictionary,
      dispatch
    });
  };

  const handleTableCheckDelete = handleCheckDeleteRows({
    dictionary,
    dataSourceForTable
  });

  return {
    handleTableCheckDelete,
    handleTableDelete,
    handleTableRestore,
    handleTableReload,
    handleTableCreate,
    handleTableSave,
    handleCopy
  };
};
