import { ILSTitleCell } from '@components/data-display/table/components/cell-types/title-types/ils-title-cell';
import { ColumnTable, TableRecord } from '@common/types';
import { RefObject } from 'react';
import { IILSTableComponentProps } from '@common/types/components/table';

export const getColumnTitle =
  <R extends TableRecord>({
    column,
    config,
    dictionary,
    onResizeStart,
    sorted,
    dataSource,
    tableRef
  }: Pick<IILSTableComponentProps<R>, 'config' | 'dataSource' | 'dictionary' | 'onResizeStart'> & {
    column: ColumnTable<R>;
    sorted: any;
    tableRef: RefObject<HTMLDivElement>;
  }) =>
  () =>
    (
      <ILSTitleCell
        column={column}
        sorted={sorted}
        config={config}
        dictionary={dictionary}
        onResizeStart={onResizeStart}
        dataSource={dataSource}
        tableRef={tableRef}
      />
    );
