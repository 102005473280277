export {
  SECONDS_TO_MILLISECONDS_MULTIPLIER,
  MILLISECONDS_TO_DAYS_MULTIPLIER,
  UPDATE_INTERVAL,
  TODAY,
  MINUTES_IN_HOURS,
  HOURS_IN_DAY,
  SECONDS_IN_MINUTES,
  HOURS_TO_MILLISECONDS_MULTIPLIER,
  MINUTES_TO_MILLISECONDS_MULTIPLIER,
  SECONDS_TO_DAYS_MULTIPLIER,
  DELAY_FOR_SUGGESTIONS,
  TIMEZONE,
  TIMEZONE_OFFSET,
  ONE_DAY_INTERVAL
} from './date-time';
export { ButtonContent, MessageContent, NOT_ENTERED, NO_DESCRIPTION } from './content';
export { Placeholder, getPlaceholder, Field, Label, RuleMessage } from './form';
export { RATING } from '../custom/rating';
export { ErrorMessage, EROROR_MESSAGE_DURATION } from './errors';
export { KeyboardCode } from './key-codes';
export { BUTTON_TEXT } from './button';

export const EMPTY_STRING = '';
export const DASH = '–';
export const EMPTY = 'EMPTY';
export const DOT = '.';
export const EMPTY_CELL = EMPTY_STRING;
export const EMPTY_ARRAY_DATA = [];
export const EMPTY_OBJECT_DATA = {};
export const EMPTY_FUNCTION = () => void 0;
export const NO_OPTIONS = undefined;
export const ADDING_WITH_NO_KEYS = EMPTY_OBJECT_DATA;
export const NOT_EXIST_IN_STRING = -1;
export const DEFAULT_INITIALIZATION_FUCTION = () => {};
export const NO_WRAP_STRING = `\u00A0`;
export const MAX_COMMENT_CHARS = 1000;
export const SIZE_IN_PIXELS = 'px';
export const PLUS_CHAR = '+';
export const PHONE_PREFIX = PLUS_CHAR;
export const NEGATIVE_NUM_PREFIX = '-';
export const SPACE = ' ';

export const CHARS = {
  DASH: '–',
  DOT: '.',
  COLON: ',',
  e: 'e',
  PLUS: '+'
};

export const DEPRECATED_CHARS_FOR_INTEGER_INPUT_NUMBER = [CHARS.DASH, CHARS.DOT, CHARS.COLON, CHARS.e];

export enum JoinChar {
  Semicolon = '; ',
  Dash = ' – ',
  Comma = ', ',
  Space = ' ',
  UnderCore = '_'
}

export enum SplitChar {
  Semicolon = ';',
  Dash = '–',
  Comma = ',',
  Space = ' ',
  Colon = ':',
  Dot = '.',
  DoubleColon = '::'
}

