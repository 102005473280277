import { createSelector } from 'reselect';
import { PlanningByDateState } from '../types/store';
import { CheckPlanningResponseActive } from '@modules/planning/types';
import { CheckPlanningByPlans, PlansCheckPlanningProcess } from '@modules/planning/types/api';
import { isNil } from 'lodash';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { compareAsString } from '@common/utils';
import { PlanningProcessStatus, PlanningProgressStatus } from '@modules/planning/children/by-date/constants';
import { PlanID, TableName } from '@common/types';
import { projectPlansSelector } from '@modules/planning/children/by-date/selectors/project';

export const planningCheckDataSelector = createSelector(
  (state: PlanningByDateState) => state?.PlanningByDate?.planningCheckData,
  (checkData) => checkData
);

export const isPlanningPlansWithProgressSelector = createSelector(planningCheckDataSelector, (checkData) => {
  return checkData
    ? Object.entries(checkData).reduce((planningDataByPlans: CheckPlanningByPlans, [planID, checkResponse]) => {
        const isPlanning = compareAsString(PlanningProcessStatus.IsPlanning, (checkResponse as CheckPlanningResponseActive)?.Status);

        const Progress = isPlanning
          ? Number(Number(checkResponse?.Progress ?? PlanningProgressStatus.StartPlanning).toFixed(3))
          : undefined;
        const PlanningStart = (checkResponse as CheckPlanningResponseActive)?.PlanningStart;

        planningDataByPlans[planID] = { isPlanning, Progress, PlanningStart };
        return planningDataByPlans;
      }, {})
    : {};
});

export const planningCheckDataProcessSelector = createSelector(planningCheckDataSelector, (checkData) => {
  return (Object.entries(checkData ?? {}) as Array<[string, CheckPlanningResponseActive]>).reduce(
    (prev: PlansCheckPlanningProcess, [id, { Status, Progress }]) => {
      if (!isNil(Status)) {
        prev[id] = { Status, Progress };
      }
      return prev;
    },
    {}
  );
});

export const isActivePlanPlanningSelector = createSelector(
  planningCheckDataProcessSelector,
  activePlanIDSelector,
  (checkData, activePlanID) => {
    return compareAsString(checkData?.[activePlanID]?.Status, PlanningProcessStatus.IsPlanning);
  }
);

export const planPlanningStartTimeSelector = createSelector(
  projectPlansSelector,
  isPlanningPlansWithProgressSelector,
  (_state: PlanningByDateState, planID: PlanID) => planID,
  (plans, plansWithInfoAboutPlanning, planID) => plansWithInfoAboutPlanning?.[planID]?.PlanningStart ?? plans?.[planID]?.PlanningStart
);

export const planningFocusNExpandRowIdsRoutesTableSelector = (tableName: TableName, firstRouteID?: number) =>
  createSelector(
    (state: PlanningByDateState) => state?.PlanningByDate?.userSettings.table,
    (data) => {
      const savedRow = data[tableName]?.focusRow;
      const savedRowKey = savedRow?.key;
      return {
        focusRow: savedRowKey ? savedRow : { key: firstRouteID ?? null },
        expandedRowKeys: data[tableName]?.expandedRows
      };
    }
  );

export const planningFocusNExpandRowIdsSelector = createSelector(
  (state: PlanningByDateState, tableName: TableName) => {
    return state?.PlanningByDate?.userSettings.table[tableName]?.focusRow;
  },
  (focusRow) => {
    return {
      focusRow
    };
  }
);
