import { ILSDragger } from '@common/components';
import { PaperClipOutlined, CheckOutlined } from '@ant-design/icons/lib/icons';
import cn from 'classnames';
import {
  ORDERS_TRIPS_PERMITTED_EXTENSIONS_TITLE,
  ORDERS_TRIPS_READY_UPLOAD_TITLE,
  ORDERS_TRIPS_UPLOAD_TITLE
} from '@modules/orders-and-trips/constants/documents';
import { JoinChar, SPACE } from '@common/constants';
import { useImportExportContainer } from '@modules/orders-and-trips/hooks/use-import-export-container';
import { PERMITTED_UPLOAD_FILE_EXTENSIONS } from '@modules/orders-and-trips/constants/file';
import { ImportNExportActions } from './action-buttons';
import { EXPORT_ICON_PREPARATION_STYLE, EXPORT_ICON_READY_STYLE } from '@modules/orders-and-trips/constants/general';

export const ImportNExportContainer = () => {
  const { getTemplate, uploadIsDisabled, handleCheckBeforeUpload, file, submitFile } = useImportExportContainer();

  return (
    <div className="documents-upload">
      <ILSDragger maxCount={1} name="file" showUploadList={false} disabled={uploadIsDisabled} beforeUpload={handleCheckBeforeUpload}>
        <div className={cn('documents-upload-dropzone', { 'documents-upload-dropzone_disabled': uploadIsDisabled })}>
          <div className="documents-upload-dropzone__heading">
            {Boolean(!file) ? (
              <PaperClipOutlined style={EXPORT_ICON_PREPARATION_STYLE} />
            ) : (
              <CheckOutlined style={EXPORT_ICON_READY_STYLE} />
            )}
            <strong>{Boolean(!file) ? ORDERS_TRIPS_UPLOAD_TITLE : file?.name + SPACE + ORDERS_TRIPS_READY_UPLOAD_TITLE}</strong>
          </div>
          <span>{ORDERS_TRIPS_PERMITTED_EXTENSIONS_TITLE}</span>
          <span>{PERMITTED_UPLOAD_FILE_EXTENSIONS.join(JoinChar.Comma)}</span>
        </div>
      </ILSDragger>
      <br />
      <ImportNExportActions getTemplate={getTemplate} file={file} submitFile={submitFile} />
    </div>
  );
};

