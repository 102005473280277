import { FC } from 'react';
import { ILSCheckbox, ILSFormItem, ILSInput, ILSRadioGroup } from '@components/index';
import { TenderPlatformScheduleComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/schedule/schedule';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { RuleMessage } from '@common/constants';
import { TenderPlatformDividerTitle } from '@modules/tender-platform/components/tender-card/lots/form/divider-title';
import cn from 'classnames';
import { TENDER_VAT_OPTIONS } from '@modules/tender-platform/constants/options';
import { useWatch } from 'antd/lib/form/Form';

interface IProps {
  disabled: boolean;
  fromPlanner: boolean;
}

export const TenderPlatformConditionComponent: FC<IProps> = ({ disabled, fromPlanner }) => {
  const isTemplate = useWatch(TenderFormField.IsTemplate);
  const className = cn('no-margin tender-form-fieldset-item');

  return (
    <fieldset className="tender-form-fieldset">
      <TenderPlatformDividerTitle title={'Условия заказа'} />
      <TenderPlatformScheduleComponent fromPlanner={fromPlanner} isLotEditable={!disabled} />
      <ILSFormItem valuePropName="checked" className={className} name={TenderFormField.SeparateVehicle}>
        <ILSCheckbox disabled={disabled} label={LotFormFieldLabel.SeparateVehicle} />
      </ILSFormItem>
      <ILSFormItem
        className={className}
        rules={[{ required: !disabled, message: 'Пожалуйста, укажите налоговые условия' }]}
        name={TenderFormField.VAT}
      >
        <ILSRadioGroup disabled={disabled} className="lot-radio" options={TENDER_VAT_OPTIONS} optionType="button" buttonStyle="solid" />
      </ILSFormItem>
      {!disabled && (
        <ILSFormItem name={TenderFormField.IsTemplate} valuePropName="checked">
          <ILSCheckbox label={'Сохранить как шаблон'} />
        </ILSFormItem>
      )}
      {Boolean(isTemplate) && (
        <ILSFormItem
          label={LotFormFieldLabel.TemplateName}
          name={TenderFormField.TemplateName}
          rules={[{ required: !disabled, message: RuleMessage.Name, whitespace: true }]}
        >
          <ILSInput disabled={disabled} />
        </ILSFormItem>
      )}
    </fieldset>
  );
};
