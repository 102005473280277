import { FC, memo } from 'react';
import { TenderPlatformTenderFormComponent } from '../../../components/tender-card/tender/form';
import { ITenderFunctions } from '@modules/tender-platform/types';
import { useDispatch } from 'react-redux';
import { TenderPlatformAuctionContainer } from '@modules/tender-platform/children/tender-search/containers/auction';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { ILSForm } from '@common/components';
import { TenderForm } from '@modules/tender-platform/types/platform';
import { TenderPlatformLotFormComponent } from '@modules/tender-platform/components/tender-card/lots/form/form';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { TenderPlatformDocuments } from './documents';
import { tenderCreateRoutine, tenderLotCreateRoutine, tenderUpdateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { isEmpty } from 'lodash';
import { useTenderCreating } from '@modules/tender-platform/utils/hooks';
import { ILSTenderOfferContainer } from '@modules/tender-platform/children/tender-search/containers/offer';
import { CardTab } from '@modules/tender-platform/constants';
import { useTenderForm } from '@modules/tender-platform/utils/helpers/use-tender-form';

interface IProps extends ITenderFunctions {
  isTenderEditable: boolean;
  isAuction: boolean;
}

export const TenderPlatformTenderFormContainer: FC<IProps> = memo(({ isTenderEditable, isAuction }) => {
  const dispatch = useDispatch();
  const { url } = useAppHistory(true);
  const { isTenderCreating } = useTenderCreating();
  const { currentTender, fromPlanner, initialValues, form } = useTenderForm();

  const onFinish = (values: Record<TenderFormField, any>) => {
    if (currentTender?.ID) {
      if (isEmpty(currentTender?.TenderLot)) {
        dispatch(tenderLotCreateRoutine({ values, tenderID: currentTender?.ID, url, updateTenderStatus: Boolean(currentTender?.ID) }));
      } else {
        dispatch(
          tenderUpdateRoutine({
            id: currentTender.ID,
            values,
            fromPlanner
          })
        );
      }
    } else {
      dispatch(tenderCreateRoutine({ values, url }));
    }
  };

  return (
    <>
      <ILSForm
        form={form}
        initialValues={initialValues}
        scrollToFirstError
        className={'tender-platform-card-form'}
        id={TenderForm.Tender}
        name={TenderForm.Tender}
        onFinish={onFinish}
        data-testid={'tender-platform-card-form'}
      >
        <TenderPlatformTenderFormComponent values={initialValues} isTenderEditable={isTenderEditable} />
        <TenderPlatformLotFormComponent fromPlanner={fromPlanner} disabled={!isTenderEditable} />
        <TenderPlatformDocuments isTenderCreating={isTenderCreating} />
      </ILSForm>
      {isAuction ? <TenderPlatformAuctionContainer /> : <ILSTenderOfferContainer key={CardTab.Offers + currentTender?.ID} />}
    </>
  );
});
