import { call, cancel, delay, put, take, fork } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { demandGetCountsRoutine, demandGetCountsRunAction, demandGetCountsStopAction } from '../actions';
import { DemandAPI } from '../api/index';
import { GetCountResponse } from '../types/api';
import { Task } from 'redux-saga';
import { UPDATE_GET_COUNTS_INTERVAL } from '../constants';

// Получает количество проектов и тендерных лотов
function* getDemandCountsWorker(action: PayloadAction<{}>) {

  const { request, success, failure, fulfill } = demandGetCountsRoutine;

  try {
    yield put(request());

    const { data }: { data: { data: GetCountResponse } } = yield call(DemandAPI.getCounts, {});
    const counts = data.data;

    yield put(success({ counts }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

//запускает runLoadTrack и повторяет его через 3 секунды с момента полного завершения загрузки
export function* getDemandRun(): any {
  yield delay(500);
  while (true) {
    yield call(getDemandCountsWorker, demandGetCountsRoutine.trigger({}));
    yield delay(UPDATE_GET_COUNTS_INTERVAL);
  }
}

//запускает и останавливает загрузку треков при диспатчах Start и Stop
export function* getDemandCountsRunWatcherAction() {
  let getDemandTask: Task | undefined;
  try {
    while (true) {
      yield take(demandGetCountsRunAction);
      //@ts-ignore
      const getDemandTask = yield fork(getDemandRun);
      yield take(demandGetCountsStopAction);
      yield cancel(getDemandTask);
    }
  } finally {
    if (getDemandTask) {
      yield cancel(getDemandTask);
    }
  }
}
export function* getDemandCountsRunWatcher() {
  yield fork(getDemandCountsRunWatcherAction);
}

