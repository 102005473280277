import { EMPTY_ARRAY_DATA, EMPTY_STRING } from '@common/constants';
import { User, UserRoleLabel } from '@common/types';
import { isNil } from 'lodash';

export const getUserRolesString = (userRole: User['UserRole'] = EMPTY_ARRAY_DATA) => {
  if (isNil(userRole)) return EMPTY_STRING;
  
  return userRole
    .reduce((roles, role) => {
      if (!UserRoleLabel[role]) return roles;
      return roles.concat(`${UserRoleLabel[role]} `);
    }, ``)
    .trim();
}