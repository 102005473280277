import { call, put, takeEvery } from 'redux-saga/effects';
import { getClearTrashModeRoutine } from '../actions';
import { Documents } from '../api';

function* getClearTrashModeWorker() {
  const { request, success, failure, fulfill } = getClearTrashModeRoutine;
  try {
    yield put(request());
    const { data } = yield call(Documents.getUserOptions);
    yield put(success({ clearTrashMode: data.data.clearTrashMode }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getClearTrashModeWatcher() {
  yield takeEvery(getClearTrashModeRoutine.trigger, getClearTrashModeWorker);
}
