import { useDispatch, useSelector } from 'react-redux';
import {
  currentTenderLotSelector,
  currentTenderMyOfferSelector,
  currentTenderStatusConfigSelector
} from '@modules/tender-platform/selectors';
import { currentUserSelector } from '@modules/current-user/selectors/auth';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import { ILSTenderOfferModal } from '@modules/tender-platform/children/tender-search/components/offer/offer-modal';
import { ILSTenderOfferSubmitButton } from '@modules/tender-platform/children/tender-search/components/offer/submit-button';
import { NOT_TENDER_CHANGE_STATUSES } from '@modules/tender-platform/children/tender-search/constants';
import { StatusTenderOffer, TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { currentTenderAuctionPriceSelector } from '@modules/tender-platform/selectors/auctions';
import { TenderStatus } from '@modules/tender-platform/types';
import { FC } from 'react';
import { useAbac } from 'react-abac';
import { tenderOfferSetStatusRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';
import { ILSTenderOfferCancelButton } from './offer/cancel-button';

interface IProps {
  isAuction: boolean;
  isFetching: boolean;
}

export const ILSOfferButtons: FC<IProps> = ({ isFetching, isAuction }) => {
  const dispatch = useDispatch();
  const { userHasPermissions } = useAbac();

  const { ID: tenderOfferID, StatusConfig, UserID } = useSelector(currentTenderMyOfferSelector);
  const tenderStatusConfig = useSelector(currentTenderStatusConfigSelector);
  const current = useSelector(currentTenderAuctionPriceSelector);
  const { MinPrice } = useSelector(currentTenderLotSelector);

  const currentUser = useSelector(currentUserSelector);
  const isOwner = UserID === currentUser.ID;
  const isEditableTender = !NOT_TENDER_CHANGE_STATUSES.includes(tenderStatusConfig?.Alias as TenderStatus);
  const { Alias, TransitionScheme } = StatusConfig ?? {};
  const canChangeOffer =
    userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE, Alias) && current !== MinPrice && isEditableTender;
  const canCancel = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_CANCEL, tenderStatusConfig?.Alias);
  const canApprove = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_APPROVAL, Alias);
  const handleTenderOfferSetStatus = (tenderOfferID: TenderOffer['ID'], statusID: TenderOffer['StatusID']) => {
    dispatch(tenderOfferSetStatusRoutine({ tenderOfferID, statusID }));
  };

  return (
    <>
      {Boolean(canApprove || canChangeOffer) &&
        TransitionScheme?.map(({ TriggerText, ID, Alias }) => {
          const shouldModalTrigger = Boolean(Alias === StatusTenderOffer.Return && canCancel && !isAuction && isOwner && tenderOfferID);
          const handleClick = () => tenderOfferID && handleTenderOfferSetStatus(tenderOfferID, ID);
          return TriggerText ? (
            shouldModalTrigger ? (
              <ILSTenderOfferCancelButton setStatus={handleClick} />
            ) : (
              Alias !== StatusTenderOffer.Received && <TenderPlatformMenuButton children={TriggerText} key={ID} onClick={handleClick} />
            )
          ) : null;
        })}
      {Boolean(canChangeOffer && Alias !== StatusTenderOffer.Sent) &&
        (Boolean(tenderOfferID) ? <ILSTenderOfferSubmitButton /> : <ILSTenderOfferModal isFetching={isFetching} />)}
    </>
  );
};
