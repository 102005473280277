import { FC, memo } from 'react';
import { ILSModal } from '@common/components';
import { ButtonTypes } from '@common/types/components';
import { EXTERNAL_TRANSPORT_COMPANY_SET_EXECUTE_CONFIRM_TEXT } from '@modules/orders-and-trips/constants/general';
import { ExternalTransportContractContainer } from '@modules/orders-and-trips/containers/right/switch-transport/external-transport-contract-container';

export interface IExternalTransportConfirmModalProps {
  confirmModalVisible: boolean;
  handleShowConfirmModal(): void;
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
}

export const ExternalTransportConfirmModal: FC<IExternalTransportConfirmModalProps> = memo(
  ({ confirmModalVisible, handleShowConfirmModal, submitButtonProps }) => {
    return (
      <ILSModal
        title={EXTERNAL_TRANSPORT_COMPANY_SET_EXECUTE_CONFIRM_TEXT}
        footer={null}
        visible={confirmModalVisible}
        onCancel={handleShowConfirmModal}
      >
        <ExternalTransportContractContainer submitButtonProps={submitButtonProps} />
      </ILSModal>
    );
  }
);
