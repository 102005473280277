import { TripToTenderFormComponent } from '@modules/orders-and-trips/components/forms/trip-to-tender-form';
import { useTenderTripForm } from '@modules/orders-and-trips/hooks/use-tender-trip-form';

//TODO убрать mockTrip, когда будет доступен бэк
const mockTrip = 558963;
export const TipToTenderFromContainer = () => {
  const { onFinish, form } = useTenderTripForm();
  //TODO Получаем из стора активный trip на основании которого строим форму
  //const activeTripID = useSelector(activeTripSelector);
  //if(!activeTripID) return "Рейс не выбран"
  return <TripToTenderFormComponent activeTripID={mockTrip} onFinish={onFinish} form={form} />;
};
