import { TableRecord } from '@common/types';
import { isRecordDroppable } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/is-record-droppable';
import { OnChangeHoveredRecord } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-change-hovered-record';
import { MutableRefObject } from 'react';

export type RoutesTableDragHandler = {
  onChangeHoveredRecord: OnChangeHoveredRecord;
  selectRecord: MutableRefObject<TableRecord | undefined>;
  targetRecord: MutableRefObject<TableRecord | undefined>;
};

export const handleRecordDragEnd =
  ({ onChangeHoveredRecord, targetRecord, selectRecord }: RoutesTableDragHandler) =>
  (record: TableRecord | undefined) =>
    isRecordDroppable(record?.dictionary)
      ? ()  => {
          selectRecord.current = undefined;
          targetRecord.current = undefined;
          onChangeHoveredRecord();
        }
      : undefined;
