import { ILSMap } from '@core/containers/map';
import L from 'leaflet';
import { MutableRefObject, useEffect } from 'react';
import { MIN_PIXEL_TO_CLUSTERFY } from '../constants';
import { MapLayerGroupState } from '../types/map';
import { clusterIcon, clusterWaypointIcon } from './helpers/cluster-icon';

/**
 * Инициализация карты
 * @param Map карта
 * @param mapZoomCluster
 * @param disableClustering
 * @param setMapLayersGroup
 */
export const useMapInit = (
  Map: MutableRefObject<ILSMap | null>,
  mapZoomCluster: string | number | undefined,
  disableClustering: string | number | boolean | undefined,
  setMapLayersGroup: React.Dispatch<React.SetStateAction<MapLayerGroupState>>
) => {
  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    const onZoomCallback = () => {
      const zoom = curMap.Map?.getZoom();
      if (zoom !== undefined) {
        if (zoom >= Number(mapZoomCluster)) {
          return 0;
        } else {
          return MIN_PIXEL_TO_CLUSTERFY;
        }
      }
      return MIN_PIXEL_TO_CLUSTERFY;
    };
    //TODO не используется
    const onGetZoom = () => {
      return curMap.Map?.getZoom();
    };

    const clusterOptions = (mapZoomCluster || disableClustering) && {
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: true,
      zoomToBoundsOnClick: true,
      spiderLegPolylineOptions: {
        weight: 0,
        opacity: 0
      },
      maxClusterRadius: disableClustering ? MIN_PIXEL_TO_CLUSTERFY : onZoomCallback
    };
    const clusters: {
      vehicleCluster: L.MarkerClusterGroup | undefined;
      markerCluster: L.MarkerClusterGroup | undefined;
    } = {
      vehicleCluster: undefined,
      markerCluster: undefined
    };

    clusters.vehicleCluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: clusterIcon
      }
    ]);

    clusters.markerCluster = curMap.addClusterGroup([
      {
        ...clusterOptions,
        iconCreateFunction: clusterWaypointIcon
      }
    ]);

    const eventMarkerGroup = curMap.addLayerGroup([]);
    const polylineGroup = curMap.addLayerGroup([]);
    const layerGroup = curMap.addLayerGroup([]);
    const markerGroup = curMap.addLayerGroup([]);
    const waypointGroup = curMap.addLayerGroup([]);

    setMapLayersGroup({
      ...clusters,
      polylineGroup,
      layerGroup,
      markerGroup,
      eventMarkerGroup,
      waypointGroup,
      isInit: true
    });

    return () => Map?.current?.deinit();
  }, []);
};

