import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import initialState from '@modules/catalog/store';
import { resetCatalogInfoRoutine } from '@modules/catalog/actions';
import { CATALOG_RELOAD_DICTIONARY } from '@modules/catalog/constants/catalogs';
import { CATALOG_RELOAD } from '@core/constants/core';

const resetCatalogInfoReducer = createReducer(initialState, {
  [resetCatalogInfoRoutine.SUCCESS]: (state: CatalogStateType) => {
    CATALOG_RELOAD_DICTIONARY.forEach((dictionary) => {
      state.info[dictionary] = {
        isFetching: false,
        lastUpdate: null
      };
    });
    localStorage.removeItem(CATALOG_RELOAD);
    return state;
  },
  [resetCatalogInfoRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
    }>
  ) => {
    const { error } = action.payload;
    state[Dictionary.Data].error = error;
    return state;
  },
  [resetCatalogInfoRoutine.FULFILL]: (state: CatalogStateType) => {
    return state;
  }
});

export default resetCatalogInfoReducer;
