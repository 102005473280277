import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { TenderState } from '@modules/tender-platform/types';
import { Vehicle } from '@common/types';
import { tenderOfferGetVehicleRoutine } from '@modules/tender-platform/actions';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderOfferVehicleReducer = createReducer(initialState, {
  [tenderOfferGetVehicleRoutine.REQUEST]: onRequest,
  [tenderOfferGetVehicleRoutine.FAILURE]: onFailure,
  [tenderOfferGetVehicleRoutine.FULFILL]: onFulfill,
  [tenderOfferGetVehicleRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      vehicles: Array<Vehicle>;
    }>
  ) => {
    const { vehicles } = action.payload;
    state.currentTenderVehicles = vehicles;
    return state;
  }
});
