import { Tender } from '@modules/tender-platform/types';
import { TenderFilter, TenderFilterMethod } from '@modules/tender-platform/children/tender-search/types/filter';
import { isEmpty } from 'lodash';
import moment from 'moment';

export const checkForExtraParams = (item: Tender, filter: TenderFilter) => {
  const param1 = filter.param;
  const param2 = filter?.secondParamLevel;

  const switchParam = (param: any, method: TenderFilterMethod) => {
    switch (method) {
      case 'includes':
        return param?.toString().toLowerCase().includes(filter.value.toString().toLowerCase());
      case 'rangeMin':
        return +param >= +filter.value;
      case 'rangeMax':
        return +param <= +filter.value;
      case 'dateMin':
        return moment.unix(param).isSameOrAfter(moment.unix(Number(filter.value)), 'date');
      case 'dateMax':
        return moment.unix(param).isSameOrBefore(moment.unix(Number(filter.value)), 'date');
      case 'filterMultiple':
        let includes = false;
        if (Array.isArray(filter.value) && param) {
          filter.value.forEach((value) => {
            if (typeof param === 'number') {
              if (param === +value) {
                includes = true;
                return;
              }
            } else {
              for (const [key] of Object.entries(param)) {
                if (key === value) {
                  includes = true;
                  return;
                }
              }
            }
          });
        }
        return includes;
    }
  };

  if (param2 && item[param1] && item[param1]![param2]) {
    return switchParam(item[param1]![param2], filter.method);
  } else {
    return switchParam(item[param1], filter.method);
  }
};

export const filterArrayByValues = (array: Array<Tender>, filters: Array<TenderFilter>) => {
  if (isEmpty(filters)) return array;
  let filteredArray = array;
  filters.forEach((filter) => {
    filteredArray = filteredArray.filter((item) => checkForExtraParams(item, filter));
  });
  return filteredArray;
};
