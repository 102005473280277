import { useDispatch } from 'react-redux';
import { createArrayFromIndexedArray } from '@common/utils';
import { RouteRecord } from '@modules/planning/children/by-date/types/record';
import { stringifyUrl } from 'query-string';
import { Dictionary, ModulePath } from '@common/types';
import { QUERY_PARAMS } from '@common/constants/url';
import { useHistory } from 'react-router';
import { changeVehicleRoutine } from '@modules/planning/children/by-date/actions';
import { useVehicleData } from '@modules/planning/children/by-date/hooks/tables/vehicle/use-vehicle-data';
import { isEmpty } from 'lodash';

export const useVehicleActions = (record: RouteRecord) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vehicles } = useVehicleData();

  const planTransportCompanies = {};
  createArrayFromIndexedArray(vehicles).forEach(({ ID, Name, TransportCompanyID, RegNumber, TransportCompanyEnum, ...vehicle }) => {
    const vehicleName = `${Name || 'Автомобиль'} ${RegNumber || ID} ${(vehicle['VehicleProfile.MaxMass'] || 0) / 1000}Т`;

    if (isEmpty(TransportCompanyEnum)) {
      planTransportCompanies[0] = {
        text: 'Без транспортной компании',
        childMenu: (planTransportCompanies[0]?.childMenu ?? []).concat({
          ID: ID,
          name: vehicleName
        })
      };
    } else {
      Object.entries(TransportCompanyEnum).forEach(([id, name]) => {
        planTransportCompanies[id] = {
          text: name,
          childMenu: (planTransportCompanies[id]?.childMenu ?? []).concat({
            ID: ID,
            name: vehicleName
          })
        };
      });
    }
  });

  //заменить автомобиль
  const handleChangeVehicle = (item: { ID: number }) => {
    const payload = {
      oldVehicleID: record.VehicleID,
      newVehicleID: item.ID,
      planID: record.PlanID
    };
    dispatch(changeVehicleRoutine(payload));
  };

  const onEditVehicle = () => {
    const link = stringifyUrl({
      url: ModulePath.Catalog,
      query: {
        [QUERY_PARAMS.DICTIONARY]: Dictionary.Vehicle,
        [QUERY_PARAMS.ID]: record.VehicleID
      }
    });
    history.push(link);
  };

  return { handleChangeVehicle, vehiclesSubMenu: Object.values(planTransportCompanies), onEditVehicle };
};
