import { apiInstance } from '@core/api/instance';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { IDataSchemeAPI } from '@modules/planning/children/scheme-editor/types';

export const DataSchemeAPI: IDataSchemeAPI = {
  uploadScheme: ({ scheme }) =>
    apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.uploadScheme), { scheme: JSON.stringify(scheme) }),
  getCurrentScheme: () => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.curScheme)),
  getMaxScheme: () => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.maxScheme)),
  uploadSchemeIni: ({ file }) => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.uploadSchemeIni), { file }),
  getCurrentSchemeIni: () => apiInstance().post(getAPIURL(Dictionary.Data, DictionariesEndpoint.getCurrentSchemeIni))
};
