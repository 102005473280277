import { State } from '../types/state';
import { UserAuth, UserRole } from '@common/types';

export const authInitialState: State['Auth'] = {
  isFetching: false,
  token: '',
  account: null,
  user: {
    ID: -1,
    Name: 'Anonymous',
    AuthToken: '',
    Token: '',
    UserRole: [UserRole.guest],
    Account: { AccountRole: null }
  } as unknown as UserAuth,
  error: [],
  notification: false,
  requestAction: null,
  accountRoles: [],
  checkToken: null
};
