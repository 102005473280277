// Типы экшенов
export enum EventDetailsAction {
  SetActiveTab = 'SET_ACTIVE_TAB', // Установка активного таба
  GetDetails = 'GET_EVENT_DETAILS', // Получение детальной информации
  SetActiveVehicle = 'SET_ACTIVE_VEHICLE', // Установка выделенного автомобиля
  GetTrack = 'GET_TRACK', // Получение информации о треке
  SelectOneVehicle = 'SELECT_ONE_VEHICLE', // Выделение одного автомобиля
  UpdateLastInfo = 'UPDATE_TRACKER_LAST_INFO', // Обновление последней информации с трекера
  GetSensorEvents = 'GET_VEHICLE_SENSOR_EVENTS', // Получение данных датчиков
  GetPointsStatusesList = 'GET_POINTS_STATUSES_LIST', // Получение списка со статусами точек
  SetTableMapSizes = 'SET_TABLE_MAP_SIZES'
}

// Запрос информации Дашборда
export type DashboardInfoRequest = {
  from: string; // Начальная дата
  till: string; // Конечная дата
};

// Обновление последней информации Дашборда
export type UpdateLastInfoDashboardRequest = {
  from: string; // Начальная дата
  till: string; // Конечная дата
  vehicleID: Array<string>; // ID автомобиля
  updates?: boolean; // Есть ли обновления
};

// Получение метрик для одного автомобиля
export type SelectOneVehicleMetricsRequest = {
  from: string; // Начальная дата
  till: string; // Конечная дата
  vehicleID: number | undefined; // ID автомобиля
};

// Запрос на получение данных датчиков
export type SensorEventsDashboardRequest = {
  from: string; // Начальная дата
  till: string; // Конечная дата
  vehicleIDs: Array<string | number>; // Массив ID автомобилей
  updates?: boolean; // Есть ли обновления
};
