import { createSelector } from 'reselect';
import { NO_OPTIONS } from '@common/constants';
import { SuggestionAutoCompleteState } from '@common/feature/suggestion-autocomplete/types';

export const addressSelector = createSelector(
  (state: SuggestionAutoCompleteState) => state.SuggestionAutoComplete.address.addresses,
  (addressList) => addressList
);

export const addressLoadingSelector = createSelector(
  (state: SuggestionAutoCompleteState) => state.SuggestionAutoComplete.address.loading,
  (loading) => loading
);

export const addressOptionsSelector = createSelector(
  addressSelector,
  (addressList) => addressList?.map((address) => ({ label: address, value: address })) ?? NO_OPTIONS
);

export const addressSuggestionAutocompleteSelector = createSelector(
  addressLoadingSelector,
  addressOptionsSelector,
  (isLoading, addressOptions) => ({ isLoading, addressOptions })
);
