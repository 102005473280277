import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';
import { ILSPlanningFormNewProjectContainer } from '@modules/planning/containers/create-project';
import { FolderAddOutlined } from '@ant-design/icons/lib/icons';
import { IButtonTooltipProps } from '@components/general/buttons/types';
import { useModal } from '@common/hooks/components/use-modal';

export const ILSPlanningCreateProjectButton: FC<IButtonTooltipProps> = () => {
  const { visible, handleShow } = useModal();

  return (
    <>
      <ILSButtonTooltip icon={<FolderAddOutlined className={'ils-svg primary'} />} onClick={handleShow} title={'Создать новый проект'} />
      {visible && <ILSPlanningFormNewProjectContainer showForm={visible} handleFormModalVisible={handleShow} />}
    </>
  );
};
