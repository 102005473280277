import { FC } from 'react';
import { ILSButton, ILSForm, ILSFormItem, ILSInput, ILSSelect } from '@common/components';
import { ButtonAction } from '@components/general/buttons';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { AddReportRequest } from '@modules/documents/types/documents-api';
import {
  ADD_REPORT_FORM,
  ADD_REPORT_FORM_RULES,
  ADD_REPORT_FORM_SELECT_FIELD_NAMES,
  AddReportFormItem
} from '@modules/documents/constants/reports';
import { DocumentsReportsOptions } from '@modules/documents/types/document-type';

interface IProps {
  onFinish(values: AddReportRequest): void;

  options: DocumentsReportsOptions;
}

export const AddReportFormComponent: FC<IProps> = ({ onFinish, options }) => {
  return (
    <ILSForm name={ADD_REPORT_FORM} onFinish={onFinish}>
      <ILSFormItem rules={ADD_REPORT_FORM_RULES[AddReportFormItem.Name]} name={AddReportFormItem.Name} label={'Название шаблона'}>
        <ILSInput />
      </ILSFormItem>
      <ILSFormItem rules={ADD_REPORT_FORM_RULES[AddReportFormItem.Alias]} name={AddReportFormItem.Alias} label={'Тип шаблона'}>
        <ILSSelect
          dropdownMatchSelectWidth={false}
          fieldNames={ADD_REPORT_FORM_SELECT_FIELD_NAMES}
          options={options as unknown as DefaultOptionType[]}
        />
      </ILSFormItem>
      <ILSButton action={ButtonAction.Save} htmlType={'submit'} form={ADD_REPORT_FORM} />
    </ILSForm>
  );
};
