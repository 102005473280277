import { Dictionary } from '@common/types';
import { EMPTY_STRING } from '@common/constants';

export const getDefaultLabelByDictionary = (value: any, dictionary?: Dictionary): string => {
  if (!value) return EMPTY_STRING;
  switch (dictionary) {
    case Dictionary.Worktime:
      return `Режим работы №${value}`;
    case Dictionary.Depot:
      return `Точка №${value}`;
    case Dictionary.VehicleProfile:
      return `Профиль авто №${value}`;
    case Dictionary.VehicleTimeline:
      return `Расписание авто №${value}`;
    case Dictionary.DriverTimeline:
      return `Расписание №${value}`;
    case Dictionary.Cargo:
    case Dictionary.OrderCargo:
      return `Груз №${value}`;
    case Dictionary.Vehicle:
      return `Автомобиль №${value}`;
    case Dictionary.TargetZoneID:
      return `Зона №${value}`;
    default:
      return `Без имени №${value}`;
  }
};
