import { CellType, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';
import { ZONE_NAME_VALIDATION_PARAMETERS } from '@common/models/zone/config';
import { COLOR_BLACK } from '@common/constants/colors';

export const NEW_FORBIDDEN_ZONE = {
  ID: 'EMPTY',
  Name: 'NewZone',
  Color: COLOR_BLACK,
  Points: ''
};

export const FORBIDDEN_ZONE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: false,
  adding: NEW_FORBIDDEN_ZONE,
  reloading: true,
  export: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      validationParameters: ZONE_NAME_VALIDATION_PARAMETERS,
      active: true,

      editable: true,
      ellipsis: true,
      width: 150,
      type: CellType.Input,
      sorting: 2,
      filtering: FilterCellType.String,
      grouping: true
    },
    {
      title: 'Цвет',
      dataIndex: 'Color',
      key: 'Color',
      active: true,

      editable: true,
      width: 70,
      sorting: 3,
      align: 'center',
      isFixedColumn: true,
      type: CellType.Color
    },
    {
      title: 'Зона',
      dataIndex: 'Zone',
      key: 'Zone',
      invisible: true,
      active: false,

      width: 70,
      required: false,
      editable: false,
      sorting: 4,
      align: 'center',
      isFixedColumn: true,
      filtering: FilterCellType.String,
      type: CellType.Input
    },
    {
      title: 'GEO',
      dataIndex: TableColumnName.Points,
      key: TableColumnName.Points,
      type: CellType.Input,
      editable: true,
      active: false,
      required: false,
      ellipsis: true,
      grouping: true
    }
  ]
};
