import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ILSMonitoringDashboard } from './containers';
import monitoringDashboardRootReducer from './reducers';
import * as monitoringDashboardRootSagas from './sagas';

/*
 * Дашбоард - это подмодуль нашего продукта, который отвечает за вкладку "Детализация событий" модуля Мониторинг.
 * Содержит в себе табы с метриками, компонент прогресса и различные таблицы
 * Основной файл - "входная точка" в подмодуль находится здесь - src\modules\monitoring\children\dashboard\index.ts
 */

export default defineModule({
  title: ModuleTitle.MonitoringDashboard,
  path: ModulePath.MonitoringDashboard,
  component: ILSMonitoringDashboard,
  sagas: getAllModuleSagas(monitoringDashboardRootSagas),
  reducer: monitoringDashboardRootReducer,
  persist: false
  // api: { Component: ILSPlanningByDateContainer }
});

