import { ILSButtonTooltip, ILSTable } from '@components/index';
import { ILSWindowTable } from '@common/components/data-display/table/components/ils-window';
import { ILSDataInfoComponentProps } from '@modules/data-validate/components/info';
import { ColumnTable, Dictionary, TableRecord } from '@common/types';
import { TErrorValidationTable } from '@modules/data-validate/types/types';
import { SetShowReference, ShowReference } from '@common/types/components/table/common';

interface ILSDataTablesComponentProps<R extends TableRecord> extends ILSDataInfoComponentProps {
  type: TErrorValidationTable;
  Catalog: any;
  showReference: ShowReference<R>;
  dictionary: Dictionary;
  text: string;
  codeField: string;
  codes: string[];
  failKeys?: string[];
  failFieldName?: string;
  columns?: ColumnTable<TableRecord>[];
  setShowReference: SetShowReference<R>;
}

export const ILSValidatePlanningTableComponent = <R extends TableRecord>({
  dictionary,
  text,
  errorList,
  showReference,
  setShowReference,
  Catalog,
  codeField,
  codes,
  failKeys,
  failFieldName,
  columns,
  ...props
}: ILSDataTablesComponentProps<R>) => {
  return (
    <div className="data-table position-relative">
      <div className={'bg-white px-3 pb-5'}>
        <ILSTable
          {...props}
          //@ts-ignore TODO why errors has so many types?
          errors={props.errors}
          dictionary={dictionary}
          dataSource={errorList}
          columns={columns}
          rowKey={({ id, Kind_ }: any, i) => id ?? Kind_ + i}
        />
        {Catalog && (
          <ILSButtonTooltip
            title={'Открыть справочник ' + text}
            type="text"
            className="ml-1 open-reference"
            style={{ width: '30px' }}
            icon={'open'}
            onClick={() => setShowReference(true)}
          />
        )}
      </div>
      {Catalog && showReference && (
        <ILSWindowTable
          title={text}
          component={Catalog}
          catalogDictionary={dictionary}
          setShow={setShowReference}
          errors={errorList}
          filterRows={codes}
          codeField={codeField}
          failFieldName={failFieldName}
          failKeys={failKeys}
          tableClassName="validate-errors-catalog"
          isModal
        />
      )}
    </div>
  );
};
