import { CreateVehicleTrackerRequest } from '@common/types/dictionaries/monitoring';
import { cleanEmptyFields } from '@common/utils/helpers/object/clean-empty-fields';
import { MonitoringAPI } from '@modules/monitoring/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringCreateVehicleTrackerRoutine, monitoringGetVehicleTrackersRoutine } from '../actions';
import { monitoringDashboardAllUpdate } from '../children/dashboard/sagas/get-dashboard-all';

// Участвует в создании новой привязки трекера к автомобилю, передаёт на бэк (values)
function* monitoringCreateVehicleTrackerWorker(action: PayloadAction<CreateVehicleTrackerRequest>) {
  const values = action.payload;
  const { request, success, failure, fulfill } = monitoringCreateVehicleTrackerRoutine;

  try {
    yield put(request({ values }));

    cleanEmptyFields(values);

    const { data } = yield call(MonitoringAPI.createVehicleTracker, { ...values });

    yield put(success({ data: data.data }));
    yield call(monitoringDashboardAllUpdate);

    yield put(monitoringGetVehicleTrackersRoutine.trigger({}));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringCreateVehicleTrackerWatcher() {
  yield takeLatest(monitoringCreateVehicleTrackerRoutine.trigger, monitoringCreateVehicleTrackerWorker);
}
