import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, ModulePath, Notify, NotifyDurationInSecond } from '@common/types';
import { AnalyticsTenderActionsType, AnalyticsTenderRoutinesToNotify, AnalyticsTenderRoutinesToNotifyOnlyErrors } from '../actions';
import { analyticsDefaultStatuses } from '@modules/analytics/common/constants/default-messages';
import { getErrorMessage } from '@common/utils';

/**Метод получения текста уведомления для разных экшенов*/
const getDataNotificationMessage = (
  action: AnalyticsTenderActionsType,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || analyticsDefaultStatuses?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): AnalyticsTenderActionsType => {
  return actionName as AnalyticsTenderActionsType;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, AnalyticsTenderActionsType>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Analytics}${ModulePath.AnalyticsTenderMain}`,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, AnalyticsTenderActionsType>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Analytics}${ModulePath.AnalyticsTenderMain}`,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* analyticsTenderNotifierWatcher() {
  for (let routine of AnalyticsTenderRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }
  for (let routine of AnalyticsTenderRoutinesToNotifyOnlyErrors) {
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
