import { IMapTrack, TrackActiveData } from '@common/types';
import { MapClickData } from '@common/types/components/map/map';
import { ILSMap, ILSMarker, ILSPolyline } from '@core/containers/map';
import { useSideAction } from '@core/utils/map/side-action';
import { MapLayers, PolylineItem } from '@modules/planning/children/by-date/types';
import { deepEqual } from 'fast-equals';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import { MutableRefObject, useEffect, useState } from 'react';
import { polyTrack2ILSPoly } from '../../decorators/map/polyline-to-ils-map-polyline';
import { createTrackKey } from '../../utils/create-layer-key';

type UseMapPolygonsArg = {
  Map: MutableRefObject<ILSMap | null>;
  mapLayersGroup: MapLayers;
  trackPolylines: IMapTrack[];
  mapPolylines: Record<string, PolylineItem>;
  activePlanID: number | null;
  setLassoLayers: (layersData: any[]) => void;
  setMapPolylines: (newMapPolylines: Record<string, PolylineItem>) => void;
  handleItemClick: (record?: MapClickData) => void | undefined;
  setPolylineDataIDs: (D: TrackActiveData) => void;
};

export const useMapPolygons = ({
  Map,
  activePlanID,
  mapLayersGroup,
  mapPolylines,
  setLassoLayers,
  handleItemClick,
  setMapPolylines,
  setPolylineDataIDs,
  trackPolylines
}: UseMapPolygonsArg) => {
  const [polylineRenderPlanID, setPolylineRenderPlanID] = useState(activePlanID);

  useEffect(() => {
    if (Map.current && mapLayersGroup.isInit) {
      const mapPolyline_n: Record<string, PolylineItem> = {};

      const { addToSide, runSideAction } = useSideAction(Map);
      const { addToSide: addToSideDelete, runSideAction: runSideDelete } = useSideAction(Map, true);

      let updateP = false;

      const addPolyMarkers = (mark: Parameters<ILSMap['addMarker']>[0][0]) => {
        if (!isEmpty(mark)) {
          const lMark = new ILSMarker(mark).L;
          addToSide(lMark, mapLayersGroup.polylineGroup);
          return lMark;
        }
      };
      const addPoly = (polyTrack: IMapTrack, decTrack: ReturnType<typeof polyTrack2ILSPoly>) => {
        let lMarker: L.Marker | undefined;
        const polyTrackKey = createTrackKey(polyTrack);
        if (mapLayersGroup.polylineGroup) {
          let lPoly;
          if (decTrack.poly) {
            lPoly = new ILSPolyline(decTrack.poly).L;
            mapLayersGroup.polylineGroup.addLayer(lPoly);
          }
          if (decTrack?.marker) {
            lMarker = addPolyMarkers(decTrack.marker);
          }
          if (!updateP) updateP = true;
          mapPolyline_n[polyTrackKey] = {
            lPoly,
            lMarker,
            layer: mapLayersGroup.polylineGroup,
            poly: polyTrack
          };
        } else {
          let lPoly;
          if (decTrack.poly) {
            lPoly = Map.current!.addPolyline([decTrack.poly]);
          }
          if (!isEmpty(decTrack.marker)) {
            lMarker = addPolyMarkers(decTrack.marker);
          }
          if (!updateP) updateP = true;
          mapPolyline_n[polyTrackKey] = {
            lPoly,
            lMarker,
            layer: undefined,
            poly: polyTrack
          };
        }
      };
      const removePoly = (mItem: PolylineItem) => {
        if (mItem?.layer) {
          if (mItem.lPoly) {
            mItem.layer.removeLayer(mItem.lPoly);
          }
        } else {
          if (mItem?.lPoly) {
            Map.current!.deleteAnyLayer(mItem.lPoly);
          }
        }
        if (!isEmpty(mItem?.lMarker)) {
          addToSideDelete(mItem.lMarker, mItem.layer);
        }
      };

      if (activePlanID && activePlanID !== polylineRenderPlanID) {
        if (!updateP) updateP = true;

        Object.values(mapPolylines).forEach((mItem) => {
          removePoly(mItem);
        });

        trackPolylines.forEach((polyTrack) => {
          if (polyTrack) {
            const decTrack = polyTrack2ILSPoly({
              polyTrack,
              setPolylineDataIDs,
              handleItemClick,
              setLassoLayers
            });
            addPoly(polyTrack, decTrack);
          }
        });
        setPolylineRenderPlanID(activePlanID);
      } else if (Object.keys(mapPolylines).length && !trackPolylines.length) {
        if (!updateP) updateP = true;

        Object.keys(mapPolylines).forEach((mapTrackKey) => {
          const mItem = mapPolylines[mapTrackKey];
          removePoly(mItem);
        });
      } else if (Object.values(mapPolylines).length === 0) {
        trackPolylines.forEach((polyTrack) => {
          if (polyTrack) {
            const decTrack = polyTrack2ILSPoly({
              polyTrack,
              setPolylineDataIDs,
              handleItemClick,
              setLassoLayers
            });
            addPoly(polyTrack, decTrack);
          }
        });
      } else {
        //Индексы polylines, которые останутся на карте
        const mapPolyCheckedKeys: Set<string> = new Set();
        trackPolylines.forEach((polyTrack) => {
          const trackKey = createTrackKey(polyTrack);
          const mapPoly = mapPolylines[trackKey];
          if (mapPoly?.poly && polyTrack && isEqualTrack(mapPoly.poly, polyTrack)) {
            mapPolyCheckedKeys.add(trackKey);
          } else if (polyTrack) {
            const decTrack = polyTrack2ILSPoly({
              polyTrack,
              setPolylineDataIDs,
              handleItemClick,
              setLassoLayers
            });
            addPoly(polyTrack, decTrack);
          }
        });

        Object.keys(mapPolylines).forEach((trackKey) => {
          const mItem = mapPolylines[trackKey];
          if (!mapPolyCheckedKeys.has(trackKey)) {
            if (!updateP) updateP = true;

            removePoly(mItem);
          } else {
            mapPolyline_n[trackKey] = mItem;
          }
        });
      }
      if (updateP) {
        runSideDelete();
        runSideAction();
        setMapPolylines(mapPolyline_n);
      }
    }
  }, [trackPolylines, mapLayersGroup.isInit]);
};

const isEqualTrack = (tr1?: IMapTrack, tr2?: IMapTrack) => {
  if (tr1 === tr2) {
    return true;
  } else {
    if (tr1 && tr2 && Object.keys(tr1).length === Object.keys(tr2).length) {
      for (const key in tr1) {
        if ((!tr1[key] || !tr2[key]) && tr1[key] !== tr2[key]) return false;

        switch (key) {
          case 'coords':
            if (!deepEqual(tr1[key], tr2[key])) return false;
            break;
          case 'data':
            break;
          case 'nativeCoords':
            if (!deepEqual(tr1[key], tr2[key])) return false;
            break;
          case 'clickData':
            if (!deepEqual(tr1[key], tr2[key])) return false;
            break;
          case 'point':
            if (!deepEqual(tr1.point, tr2.point)) {
              return false;
            }
            break;
          case 'waypoint':
            break;
          case 'vehicle':
            break;

          default:
            if (
              (typeof tr1[key] === 'boolean' || (typeof tr1[key] === 'number' && !isNaN(tr1[key])) || typeof tr1[key] === 'string') &&
              tr1[key] !== tr2[key]
            ) {
              return false;
            }
            break;
        }
      }
    } else {
      return false;
    }
  }
  return true;
};

export default { useMapPolygons };

