import { useDispatch, useSelector } from 'react-redux';
import { planningOrderCargoTableSelector } from '@modules/planning/children/by-date/selectors/components/split-sizes';
import { useOrderCargoDataSource } from '@modules/planning/children/by-date/hooks/tables/order-cargo/use-order-cargo-data-source';

export const useOrderCargoTable = (record: any) => {
  const dispatch = useDispatch();
  const { refData, orders } = useSelector(planningOrderCargoTableSelector);

  const { dataSource } = useOrderCargoDataSource({ record, orders });

  return { dispatch, refData, dataSource };
};
