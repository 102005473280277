import { ILSTimePickerStatic } from '@components/custom/data-display/time-picker';
import { doubleToTime } from '@common/utils';
import { IILSCellTimeRangeStringProps } from '@common/types/components/table/cell';
import { JoinChar } from '@common/constants';
import { isArray } from 'lodash';
import { TableRecord } from '@common/types';

export const ILSCellTimeRangeString = <R extends TableRecord>({
  children,
  toggleEdit
}: IILSCellTimeRangeStringProps<R>): JSX.Element | null => {
  const [node, period] = children;

  if (!isArray(period)) {
    return <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, period]} />;
  }

  const [startTime, endTime] = period;
  const defaultValue = [doubleToTime(startTime), doubleToTime(endTime)];

  return (
    <ILSTimePickerStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, defaultValue.join(JoinChar.Dash)]} />
  );
};
