import { createSelector } from 'reselect';
import { dictionaryExtraSchemeDataSelector } from '@modules/planning/children/scheme-editor/selectors/extra-scheme';
import { displayedTabPanesSelector, schemeInitialValuesSelector } from '@modules/planning/children/scheme-editor/selectors/form';
import {
  currentSchemeKeysSelector,
  dictionaryCurrentSchemeDataSelector
} from '@modules/planning/children/scheme-editor/selectors/current-scheme';
import { isFetchingSelector } from '@modules/planning/children/scheme-editor/selectors/common';

import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { countObjectKeys } from '@common/utils/helpers/object/count-object-keys';

export const schemeEditorRootContainerSelector = createSelector(
  displayedTabPanesSelector,
  schemeInitialValuesSelector,
  isFetchingSelector,
  (displayedTabPanesSchemes, initialValues, isFetching) => {
    const isLoading = countObjectKeys(initialValues) === 0 && countObjectKeys(displayedTabPanesSchemes) === 1;
    return {
      displayedTabPanesSchemes,
      initialValues,
      isFetching,
      isLoading
    };
  }
);

export const schemeEditorTransferContainerSelector = (scheme: SchemeSwitchDictionary) =>
  createSelector(
    dictionaryExtraSchemeDataSelector(scheme),
    dictionaryCurrentSchemeDataSelector(scheme),
    currentSchemeKeysSelector,
    (extraData, currentData, currentAddedKeys) => {
      return {
        extraData,
        currentData,
        currentAddedKeys
      };
    }
  );
