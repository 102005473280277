import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';
import { MAP_SIZES } from '@core/constants/map';
import classnames from 'classnames';
import { useMapInit } from '@modules/vehicle-monitoring/hooks/map/init';
import { useShowPolyline } from '@modules/vehicle-monitoring/hooks/map/show-polyline';
import { IVehicleMonitoringData } from '@modules/vehicle-monitoring/types/models';
import { useRuler } from '@core/components/map/hooks/use-ruler';
import { useFocusOnCar } from '@modules/vehicle-monitoring/hooks/map/use-focus-on-car';

interface IProps {
  data: IVehicleMonitoringData | null;
}

export const VehicleMonitoringMapContainer: FC<IProps> = ({ data }) => {
  //инициализация карты
  const { mapLayersGroup, Map, mapRef } = useMapInit();
  //отображение треков и авто
  useShowPolyline({ Map, mapLayersGroup, data });
  //линейка
  const { triggerRuler, rulerIsActive } = useRuler({ Map });

  useFocusOnCar({ Map, vehicleTracker: data?.VehicleTracker });

  return (
    <div className={classnames('map-container')} ref={mapRef} style={{ position: 'relative' }}>
      <div id="ils-map" style={MAP_SIZES} />
      <div className={'map-buttons'}>
        <ILSButtonTooltip
          className={classnames({ active: rulerIsActive })}
          icon={'/map/ruler'}
          onClick={triggerRuler}
          title={'Включить линейку'}
          placement={'right'}
        />
      </div>
    </div>
  );
};
