import { ErrorLevel, Notify } from '@common/types';

export const getHighLevelError = (lastError: string | undefined, currentError: string | undefined) => {
  const errorsByLevel = {
    [Notify.Success]: ErrorLevel.Success,
    [Notify.Info]: ErrorLevel.Info,
    [Notify.Warning]: ErrorLevel.Warning,
    [Notify.Error]: ErrorLevel.Error,
    [Notify.Bad]: ErrorLevel.Bad
  };
  if (lastError && errorsByLevel[lastError] && currentError && errorsByLevel[currentError]) {
    return Number(errorsByLevel[lastError]) > Number(errorsByLevel[currentError]) ? lastError : currentError;
  }
  return Notify.Warning;
};
