import { ILSMap } from '@core/containers/map';
import { MutableRefObject } from 'react';
import { addLayersToMap, removeLayersToMap } from '../map-add-layers';
import L from 'leaflet';

// Для массового удаления/добавления слоев на карту или группу слоев
export const useSideAction = (map: MutableRefObject<ILSMap | null>, remove: boolean = false) => {
  const toActionLayers: {
    layers: L.Layer[];
    group: L.LayerGroup | L.MarkerClusterGroup | undefined;
  }[] = [];

  /**
   * Добавляет слой в массив для последующего действия
   */
  const addToSide = (layer: L.Layer, group?: L.LayerGroup | L.MarkerClusterGroup | undefined) => {
    const currentGroup = toActionLayers.find((items) => items.group === group);
    if (currentGroup) {
      currentGroup.layers.push(layer);
    } else {
      toActionLayers.push({ layers: [layer], group });
    }
  };
  /**
   * Запускает процесс добавление маркеров на карту
   */
  const runSideAction = () => {
    const action = remove ? removeLayersToMap : addLayersToMap;
    toActionLayers.forEach((item) => action(map, item.layers, item.group));
    toActionLayers.length = 0;
  };
  return { addToSide, runSideAction, toActionLayers };
};

