import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { onFailure } from '@common/utils/helpers/reducers';
import { addSchemeKeysRoutine } from '@modules/planning/children/scheme-editor/actions';
import { SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { EMPTY_KEY_TEXT, INFO_KEY_TEXT, SCHEMA_KEY_SEPARATOR, SchemeKeyType } from '@modules/planning/children/scheme-editor/constants';

const getNewKey = (keys: Array<string>, type: string): string => {
  const text = type === SchemeKeyType.Info ? INFO_KEY_TEXT : '';

  const typedKeys = keys.filter((x) => x.toLowerCase().indexOf(type.toLowerCase()) > -1);

  const maxIndex = typedKeys
    .map((x) => Number(x.split(`@${text}`)[1]))
    .filter((x) => Number.isInteger(x))
    .sort((a, b) => b - a)[0];

  return `${type}${SCHEMA_KEY_SEPARATOR}${text}${(maxIndex || 0) + 1}`;
};
export const addSchemeKeysReducer = createReducer(INITIAL_STATE, {
  [addSchemeKeysRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ key: string; title?: string; description: string; dictionary: SchemeSwitchDictionary }>
  ) => {
    const { title, description, key, dictionary } = action.payload;
    if (state.currentScheme?.[dictionary]) {
      if (key === SchemeKeyType.Info || key === SchemeKeyType.Empty) {
        const newKey = getNewKey(Object.keys(state.currentScheme?.[dictionary] || {}), key);
        //@ts-ignore
        state.currentScheme[dictionary] = {
          ...state.currentScheme[dictionary],
          [newKey]: {
            type: key,
            title: title || (key === SchemeKeyType.Empty ? EMPTY_KEY_TEXT : ''),
            description: description || '',
            availableTypes: [SchemeKeyType.STR]
          }
        };
        state.currentSchemeKeysInDictionaries[dictionary].push(newKey);
      }
      return state;
    }
  },
  [addSchemeKeysRoutine.FAILURE]: onFailure
});
