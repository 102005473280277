import { ILSTabPane } from '@components/index';
import { FC, SyntheticEvent, useMemo, useState } from 'react';

enum TabAction {
  Add = 'add',
  Remove = 'remove'
}

type TabType = {
  children: JSX.Element;
  key: string;
};

/**
 * Hook return props for ILSTabs with type="editable-card"
 * and make component with adding and removing tabs
 * @return {onEdit func, Array of ILSTabPane, activeTabKey and onChange callback}
 * @param content Any FC is children of ILSTabPane, add key and uniq number-index for each tab-content-component
 * @param tabName title for ILSTabPane
 * @param keyName key for ILSTabPane
 * @param deps: The list of all reactive values referenced inside the calculateValue code.
 * The list of dependencies must have a constant number of items and be written inline like [dep1, dep2, dep3].
 * Default deps value is []
 * @param countChildren
 */
export const useEditableTab = ({
  content,
  countChildren = 1,
  deps = [],
  tabName,
  keyName
}: {
  content: { Component: FC<any>; props: any };
  tabName: string;
  keyName: string;
  countChildren?: number;
  deps?: Array<any>;
}) => {
  //NOTE: Если кол-во tabs которое нужно отобразить равно 0, выставляем это значение в 1
  countChildren = countChildren > 0 ? countChildren : 1;

  //NOTE: Активный ключ tab
  const [activeKey, setActiveKey] = useState(() => `${keyName}${0}`);
  //NOTE: Массив tabs
  const [tabs, setTabs] = useState<TabType[]>([]);

  //NOTE: Возвращает уникальные пропсы для tab'а
  const getTabProps = (number = 0) => {
    return {
      tab: `${tabName} №${number + 1}`,
      key: `${keyName}${number}`,
      number,
      closable: number > 0
    };
  };

  //NOTE: Возвращает новый Tab
  const getTabChildren = (tab: string, key: string, closable: boolean, number: number) => {
    const { Component, props } = content;
    return (
      <ILSTabPane forceRender tab={tab} key={key} closable={closable}>
        {<Component {...props} key={number} number={number} />}
      </ILSTabPane>
    );
  };

  //NOTE: Создаем массив tabs для отображения, присваивая уникальные ключи
  useMemo(() => {
    let TABS: TabType[] = [];
    while (countChildren > 0) {
      const { key, tab, number, closable } = getTabProps(TABS.length);
      const child = {
        children: getTabChildren(tab, key, closable, number),
        key: `${key}`
      };
      TABS = [...TABS, child];
      countChildren--;
    }
    setTabs(() => {
      return [...TABS];
    });
  }, [countChildren, ...deps]);

  const onChange = (newActiveKey: string) => setActiveKey(newActiveKey);

  //NOTE: Функция для добавления tab
  const add = () => {
    setTabs((tabs) => {
      const { key, tab, number, closable } = getTabProps(tabs.length);
      const newTab = {
        children: getTabChildren(tab, key, closable, number),
        key
      };
      setActiveKey(key);
      return [...tabs, newTab];
    });
  };

  //NOTE: Функция для удаления tab
  const remove = (tabKey: string) => {
    setTabs((tab) => {
      const filterTabs = tab.filter(({ key }) => key !== tabKey);
      if (filterTabs?.length > 0) {
        setActiveKey(() => filterTabs?.[filterTabs?.length - 1].key);
      } else {
        setActiveKey(() => filterTabs?.[0].key);
      }

      return filterTabs;
    });
  };

  const onEdit = (tabKey: SyntheticEvent | string, action: 'add' | 'remove') => {
    if (action === TabAction.Add) {
      add();
    }
    if (typeof tabKey === 'string') {
      remove(tabKey);
    }
    return;
  };

  return { onChange, activeKey, onEdit, tabs };
};
