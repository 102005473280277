// Названия метрик
export enum DashboardTablesNumberMetrics {
  Total = 'Total', // Всего
  Served = 'Served' // Обслужено
}

// Названия таблиц
export enum DashboardTablesObjMetrics {
  OnLate = 'Late', // Доставлено с опозданием
  OnTime = 'InTime', // Доставлено вовремя
  Undelivered = 'Undelivered', // Недоставлено
  BrokenSequence = 'Desequence', // Доставлено с нарушением последовательности
  FuelDrain = 'FuelDrain', // Подозрения на слив топлива
  Violations = 'BDD' // Нарушения безопасности дорожного движения
}

// Варианты нарушения безопасности дорожного движения
export enum DashboardBDDMMetrics {
  DriverCount = 'driverCount', // Количество водителей
  AccelerationsCount = 'accelerationsCount', // Число резких ускорений
  SharpTurnCount = 'sharpTurnCount', // Число резких поворотов
  SharpBrakingCount = 'sharpBrakingCount' // Число резких торможений
}

// Название сенсоров
export enum DashboardSensorNameViewed {
  SensorValue = 'SensorValue', // Здесь значения датчиков
  FuelKeyPoint = 'FuelKeyPoint' // Здесь подозрения на слив топлива
}

// название методов для апи
export const apiMethodsNames = {
  [DashboardTablesObjMetrics.OnLate]: 'late', // Опоздания
  [DashboardTablesObjMetrics.OnTime]: 'inTime', // Вовремя
  [DashboardTablesObjMetrics.Undelivered]: 'undelivered', // Недоставлено
  [DashboardTablesObjMetrics.BrokenSequence]: 'desequence', // Нарушения последовательности
  [DashboardTablesObjMetrics.FuelDrain]: 'fuelDrain', // Подозрения на слив топлива
  [DashboardTablesObjMetrics.Violations]: 'bDD', // Безопасность дорожного движения
  [DashboardTablesNumberMetrics.Total]: 'all', // Всего точек
  [DashboardTablesNumberMetrics.Served]: 'all', // Обслужено точек
  [DashboardSensorNameViewed.SensorValue]: 'SensorValue', // Значения датчиков
  [DashboardSensorNameViewed.FuelKeyPoint]: 'FuelKeyPoint' // Значения для подозрений на слив топлива
};
