import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorGetPlanRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { EmulatorPlanVehiclesModel } from '../types/api';

// Возвращает индексный массив автомобилей (planData.vehicles) и загруженный план ID (planData.loadedPlanID)
const emulatorGetPlanReducer = createReducer(initialState, {
  [emulatorGetPlanRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.planData.isFetching = true;
    return state;
  },
  [emulatorGetPlanRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      vehicles: EmulatorPlanVehiclesModel;
      planID: number | string;
    }>
  ) => {
    const { vehicles, planID } = action.payload;

    state.planData.isFetching = false;
    state.planData.vehicles = vehicles;
    state.planData.loadedPlanID = planID;

    return state;
  },
  [emulatorGetPlanRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  },
  [emulatorGetPlanRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.planData.isFetching = false;
    return state;
  }
});

export default emulatorGetPlanReducer;
