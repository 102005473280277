import { call, put, takeLatest } from 'redux-saga/effects';
import { getTripsCountRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { TripStatus } from '../types/trip';

function* getTripsCountWorker(
  action: PayloadAction<{
    updateTripStatus?: TripStatus;
  }>
) {
  const { request, success, failure, fulfill } = getTripsCountRoutine;
  const { updateTripStatus } = action.payload ?? {};
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripsCount);
    yield put(success({ data, updateTripStatus }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripsCountWatcher() {
  yield takeLatest(getTripsCountRoutine.trigger, getTripsCountWorker);
}

