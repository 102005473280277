import { createSelector } from 'reselect';
import { Keys, ModulePath } from '@common/types';
import { PlannerTenderPlatformButton } from '@modules/planning/children/by-date/components/result/table-head/buttons/tender-platform-button';
import { BUUTONS_TO_TENDER_PLATFORM_TEXT } from '@modules/planning/children/by-date/constants/to-tender';
import { planningDeleteTenderForRouteRoutine } from '@modules/planning/children/by-date/actions';
import { stringifyUrl } from 'query-string';
import { TenderPlatformPaths } from '@modules/tender-platform/types';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { hasPermissionsForTenderPlatformSelector } from '@modules/current-user/selectors/auth';
import { Dispatch } from 'redux';
import { isEmpty } from 'lodash';
import { routesIDsSelector, routesSelectedSelector, tendersSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { selectedRouteTableRowsSelector } from './tables/selected-table-rows';
import { checkBeforeModalRouteAdd, checkRouteAdd } from '@modules/planning/children/by-date/helpers/check-route-add-modal';
import { PlanID } from '@common/types/dictionaries/plan';

export const planningPlanTableContainerTenderButtonsSelector = ({ dispatch, push }: { push: any; dispatch: Dispatch }) =>
  createSelector(
    hasPermissionsForTenderPlatformSelector,
    routesIDsSelector,
    tendersSelector,
    activePlanIDSelector,
    selectedRouteTableRowsSelector,
    routesSelectedSelector,
    (hasPermissionsForTenderPlatform, routesIDs, tenders, planID, selectedRouteTableRows, routes) => {
      const { selectedRoutesIDs } = selectedRouteTableRows;
      const hasSelectedRoutes = !isEmpty(selectedRoutesIDs);
      const rowsAmount = selectedRoutesIDs?.length || 0;

      const addDisabledTender = !hasSelectedRoutes || selectedRoutesIDs?.some((VehicleID) => tenders?.[VehicleID]);
      const deleteDisabledTender = !hasSelectedRoutes || selectedRoutesIDs?.some((VehicleID) => !tenders?.[VehicleID]);
      const toTenderDisabled = !hasSelectedRoutes || selectedRoutesIDs.length > 1 || deleteDisabledTender;

      const handleClick = () => {
        const [routeID] = selectedRoutesIDs;
        const tenderID = tenders?.[routeID];
        const url = stringifyUrl({
          url: ModulePath.TenderPlatform + ModulePath.TenderOwn,
          query: { tender: tenderID }
        });
        push(url);
      };

      const handleAdd = (ids?: Keys, planID?: PlanID) => {
        const addUrl = stringifyUrl({
          url: ModulePath.TenderPlatform + ModulePath.TenderOwn + TenderPlatformPaths.CreateFromPlanner
        });
        push(addUrl, { ids, planID });
      };
      const onClickAdd = checkBeforeModalRouteAdd({ rowsAmount });
      const tenderButtons = [
        {
          Component: PlannerTenderPlatformButton,
          props: {
            text: BUUTONS_TO_TENDER_PLATFORM_TEXT.add,
            inactive: addDisabledTender,
            onClick: onClickAdd,
            handler: () => {
              handleAdd(selectedRoutesIDs, planID);
            },
            validateInfo: checkRouteAdd({ routes }),
            rowsAmount
          }
        },
        {
          Component: PlannerTenderPlatformButton,
          props: {
            text: BUUTONS_TO_TENDER_PLATFORM_TEXT.delete,
            inactive: deleteDisabledTender,
            handler: () => {
              dispatch(planningDeleteTenderForRouteRoutine({ ids: selectedRoutesIDs, planID }));
            },
            rowsAmount
          }
        },
        {
          Component: PlannerTenderPlatformButton,
          props: {
            text: BUUTONS_TO_TENDER_PLATFORM_TEXT.toTender,
            inactive: toTenderDisabled,
            handler: handleClick
          }
        }
      ];

      return { recordTableButtons: hasPermissionsForTenderPlatform ? tenderButtons : EMPTY_ARRAY_DATA };
    }
  );
