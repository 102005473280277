import { vehicleMonitoringGetLinkRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { LinkRequest, TripRequest } from '@modules/planning/types/api';

function* vehicleMonitoringGetLinkWorker(action: PayloadAction<TripRequest & LinkRequest>) {
  const { request, success, failure, fulfill } = vehicleMonitoringGetLinkRoutine;
  const { tripID, link } = action.payload;

  try {
    yield put(request());

    const { data } = yield call(PlanningAPI.getSharedLink, { tripID, link });

    yield put(success({ link: data.data.link }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* vehicleMonitoringGetLinkWatcher() {
  yield takeLatest(vehicleMonitoringGetLinkRoutine.trigger, vehicleMonitoringGetLinkWorker);
}
