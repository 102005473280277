import { ILSPolyline } from '@core/containers/map';
import { useSideAction } from '@core/utils/map/side-action';
import { DRAW_PAIR_ALWAYS, DRAW_PAIR_FOCUS, PAIR_PARAMS } from '@modules/planning/children/by-date/constants/map';
import { UseMapPairsUnresolvedType } from '@modules/planning/children/by-date/types/map-container';
import L, { LatLngExpression } from 'leaflet';
import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';

export const useMapPairsUnresolved: UseMapPairsUnresolvedType = ({
  Map,
  lines,
  markers,
  planningMapSettings :{ drawPairForUnresolved },
  selectedOrders
}) => {
  const prevLines = useRef<L.Polyline<any>[]>([]);

  useEffect(() => {
    if (Map && lines && drawPairForUnresolved) {
      const { addToSide, runSideAction } = useSideAction(Map);
      const { addToSide: addToSideDelete, runSideAction: runSideDelete } = useSideAction(Map, true);

      if (prevLines.current?.length) {
        prevLines.current?.forEach((line) => {
          addToSideDelete(line);
        });
        runSideDelete();
      }
      let existLines: typeof lines = [];
      switch (drawPairForUnresolved) {
        case DRAW_PAIR_ALWAYS:
          existLines = lines.filter(({ ID }) => markers?.find((unresolvedOrder) => unresolvedOrder?.data?.ID === ID));
          break;
        case DRAW_PAIR_FOCUS:
          existLines = lines.filter(
            ({ ID }) =>
              selectedOrders?.find((orderID) => orderID === ID) 
          );
          break;
      }
      if (existLines.length) {
        const orderLines = existLines?.reduce((result, line) => {
          if (!isEmpty(line.coords)) {
            const newLine = {
              coords: line.coords as LatLngExpression[],
              options: {
                color: line?.color ?? PAIR_PARAMS.color,
                weight: line?.weight ?? PAIR_PARAMS.weight
              }
            };

            const orderLine = new ILSPolyline(newLine);
            addToSide(orderLine.Polyline);

            result.push(orderLine.Polyline);
          }
          return result;
        }, [] as L.Polyline<any>[]);
        runSideAction();
        prevLines.current = orderLines;
      }
    }
  }, [markers, selectedOrders]);
};

export default { useMapPairsUnresolved };
