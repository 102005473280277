import { CellType, TableConfig } from '@common/types';
import { AttachmentColumn, DriverComment, LastStatus, LastStatusDT } from '../../../monitoring/common/column';
import { ID_COLUMN } from '@common/models/all/columns';

export const tableConfig: TableConfig = {
  columns: [
    ID_COLUMN,
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      width: 80,

      sorting: 3
    },
    {
      title: 'Адрес доставки',
      dataIndex: 'Address',
      key: 'Address',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Время прибытия по плану',
      dataIndex: 'WaypointPlanArrive',
      key: 'WaypointPlanArrive',
      active: true,
      type: CellType.TimeStringUtc,
      ellipsis: true,

      sorting: 3
    },
    {
      title: 'Комментарий',
      dataIndex: 'Comment',
      key: 'Comment',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'string',
      width: 100,

      sorting: 3
    },
    {
      title: 'Фото',
      dataIndex: 'Photo',
      key: 'Photo',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      width: 80,

      sorting: 3
    },
    AttachmentColumn,
    DriverComment,
    LastStatus,
    LastStatusDT,
    {
      title: 'Просмотрено',
      titleText: 'Просмотрено',
      titleTooltip: 'Просмотрено',
      dataIndex: 'Viewed',
      key: 'Viewed',
      active: true,
      type: CellType.IconAction, //to new => checkboxIcon
      ellipsis: true,
      width: 40,

      sorting: 3
    }
    //убрали до реализации функционала
    // {
    //   title: 'Избранное',
    //   dataIndex: 'Favourite',
    //   key: 'Favourite',
    //   active: true,
    //   type: CellType.Select, // to new => checkboxIcon
    //   ellipsis: true,
    //   options: [
    //     { label: '', value: 0 },
    //     { label: 'Избранное', value: 1 }
    //   ],
    //   width: 70,
    //   editable: true,
    //
    //   sorting: 3,
    //
    //   filtering: 'array'
    // }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: false,
  copying: false
};
