import { DownloadLocalDocumentCard } from '@common/components/common/document-download-card/document-download-card';
import { EXTERNAL_TRANSPORT_COMPANIES } from '@common/constants/external-transport-companies/external-transport-companies';

export const QuestionsRulesList = () => {
  return (
    <div className="questions-rules-list">
      {EXTERNAL_TRANSPORT_COMPANIES.map((company) => (
        <div className="questions-rules-list-row" key={company.id}>
          <div className="company-img">
            <img src={company.img} alt={company.name} />
          </div>
          <DownloadLocalDocumentCard document={company} disabled={!company.filePath} />
        </div>
      ))}
    </div>
  );
};

