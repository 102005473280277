import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringSetWaypointStatusRoutine } from '@modules/monitoring/actions';
import { SetWaypointStatusRequest } from '@common/types/dictionaries/monitoring';

// Получает данные прогресса доставок
function* worker(action: PayloadAction<SetWaypointStatusRequest>) {
  const { request, success, failure, fulfill } = monitoringSetWaypointStatusRoutine;
  const { waypointID, statusID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(MonitoringAPI.setWaypointStatus, { waypointID, statusID });
    yield put(success(data));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringSetWaypointStatusWatcher() {
  yield takeLatest(monitoringSetWaypointStatusRoutine.trigger, worker);
}
