import { createReducer } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import initialState from '@modules/catalog/store';
import { catalogGetCoordsRoutine } from '@modules/catalog/actions';

const getCoordinatesReducer = createReducer(initialState, {
  [catalogGetCoordsRoutine.SUCCESS]: (state: CatalogStateType) => {
    return state;
  }
});

export default getCoordinatesReducer;
