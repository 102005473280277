import reduceReducers from 'reduce-reducers';
import { initialState } from '../store';
import monitoringDashboardReducer from './dashboard';
import monitoringDashboardInfoReducer from './get-dashboard';
import monitoringDashboardAllInfoReducer from './get-dashboard-all';
import { monitoringUpdateViewReducer } from './update-view';
import monitoringGetProgressReducer from './get-progress';

// Корневой редьюсер, объединяет все остальные редьюсеры
const monitoringDashboardRootReducer = reduceReducers(
  initialState,
  monitoringDashboardReducer,
  monitoringDashboardInfoReducer,
  monitoringDashboardAllInfoReducer,
  monitoringUpdateViewReducer,
  monitoringGetProgressReducer
);

export default monitoringDashboardRootReducer;
