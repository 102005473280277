import { tenderOfferIndexRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';

function* tenderOfferIndexWorker() {
  const { request, success, failure, fulfill } = tenderOfferIndexRoutine;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderSearch.index);
    yield put(success({ offers: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferIndexWatcher() {
  yield takeLatest(tenderOfferIndexRoutine.trigger, tenderOfferIndexWorker);
}
