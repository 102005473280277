import { InputNumber, InputNumberProps } from 'antd';
import { FC, KeyboardEvent } from 'react';
import { DEPRECATED_CHARS_FOR_INTEGER_INPUT_NUMBER } from '@common/constants/general';

export interface IILSInputNumberProps extends InputNumberProps {}

export const ILSInputNumber: FC<IILSInputNumberProps> = ({ ...props }) => {
  const handleOnKeyDown = ({ key, preventDefault }: KeyboardEvent<HTMLInputElement>) => {
    if (DEPRECATED_CHARS_FOR_INTEGER_INPUT_NUMBER.includes(key)) {
      preventDefault();
    }
  };
  return <InputNumber onKeyDown={handleOnKeyDown} type={'number'} className={'width-full'} {...props} />;
};
