import { ILSTable } from '@common/components';
import { tableEventDetailsPlanFactConfig } from '@common/table-configs/monitoring-event-details/plan-fact-table';
import { data2table } from '@common/table-configs/monitoring-event-details/plan-fact-table/decorators';
import { Dictionary } from '@common/types';
import { EventDetailsTab } from '@modules/monitoring/children/event-details/types';
import { FC } from 'react';
import { tablePropConfig } from '../../configs/table';
import { MonitoringEventDetailsStore } from '../../types/store';

interface Props {
  waypointId?: number;
  tab: EventDetailsTab;
  vehicleEvents: MonitoringEventDetailsStore['VehicleEvents'];
  tracks: MonitoringEventDetailsStore['VehicleTracks'];
  vehicleSensor: MonitoringEventDetailsStore['VehicleSensor'];
  focusRow: { key: string } | undefined;
  focusOnMapByWaypoint: (val: any) => void;
  decoratedData: ReturnType<typeof data2table>;
  modalContent: JSX.Element | null
}

/**
 * Таблица детализации - План/Факт событий
 */

export const DetailsPlanFactTableComponent: FC<Props> = ({
  tab,
  vehicleSensor,
  tracks,
  vehicleEvents,
  focusRow,
  focusOnMapByWaypoint,
  decoratedData,
  modalContent,
  ...props
}) => {
  return (
    <div className={'position-relative h-100 catalog-item'}>
      <div className="ils-monitoring-details-detail-table position-relative">
        <ILSTable
          {...props}
          {...tablePropConfig}
          focusRow={focusRow}
          setFocusRow={focusOnMapByWaypoint}
          config={tableEventDetailsPlanFactConfig}
          dataSource={decoratedData}
          size={'small'}
          className="editable-table"
          bordered
          dictionary={Dictionary.EventDetailsPlanFact}
          tableName={Dictionary.EventDetailsPlanFact}
        />
        {modalContent}
      </div>
    </div>
  );
};
