import { Account, IndexedArray, Key, Plan, VehicleType } from '@common/types';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';
import { Tender, TenderLot } from '@modules/tender-platform/types';
import { TenderRoute } from '@modules/tender-platform/types/tender';
import { PayloadAction } from '@reduxjs/toolkit';

export type RoutesID = Key; //extends Vehicle['ID'];
export type RoutesIDs = Array<RoutesID>;

export interface ITenderRotesFunctions {
  handleSave({ id, field, value }: { id: RoutesID; field: TenderFormTableField; value: any }): void;

  handleEditing({ id, field, editing }: { id: RoutesID; field: TenderFormTableField; editing: boolean }): void;
}
export type RouteList = Array<TenderRoute>;
export type RoutePayload = { ids: RoutesIDs; planID: number | string };
export type TenderCreation = {
  Account: Account;
  AccountID: Account['ID'];
  OfferEndDT: Tender['OfferEndDT'];
  OfferStartDT: Tender['OfferStartDT'];
  ExecutionEndDT: Tender['ExecutionEndDT'];
  ExecutionStartDT: Tender['ExecutionStartDT'];
  Distance: TenderLot['Distance'];
  RegionalCityRecipient: TenderLot['RegionalCityRecipient'];
  RegionalCitySender: TenderLot['RegionalCitySender'];
  Mass: number;
  MinCarrierRating: Tender['MinCarrierRating'];
  MinDriverRating: Tender['MinDriverRating'];
  MinDriverRoutes: Tender['MinDriverRoutes'];
  Name: string;
  Private: Tender['Private'];
  Type: Tender['Type'];
  VehicleType: VehicleType | null;
  TenderLot: Partial<TenderLot>;
  RouteList: RouteList;
};
export type DataForTenderCreation = IndexedArray<TenderCreation>;
export type CreateTenderFromRouteAction = PayloadAction<{ data: DataForTenderCreation; planID: Plan['ID'] }>;

export const TenderDateTimeType = [
  TenderFormTableField.OfferStartDT,
  TenderFormTableField.OfferEndDT,
  TenderFormTableField.ExecutionStartDT,
  TenderFormTableField.ExecutionEndDT
];
