import { getScopeModules } from '@core/index';
import { FC, Fragment } from 'react';
import { IModuleModel } from '@core/types/models';
import { ModuleScope } from '@core/types';
import shortid from 'shortid';

interface ScopeProps {
  name: ModuleScope;
  extraProps?: any;
  renderer?: (modules: IModuleModel[] | any, extraProps: any) => JSX.Element | JSX.Element[];
}

export const Scope: FC<ScopeProps> = ({ name, extraProps, renderer }) => {
  const modules = getScopeModules(name);
  let output: JSX.Element | JSX.Element[] = [];

  if (modules.length) {
    if (renderer && 'function' === typeof renderer) {
      output = renderer(modules, extraProps);
    } else {
      output = modules.map((module) =>
        module.component && 'function' === typeof module.component ? (
          <Fragment key={shortid.generate()}>{module.component(extraProps ?? {})}</Fragment>
        ) : (
          <></>
        )
      );
    }
  }

  return <>{output}</>;
};
