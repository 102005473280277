export { DemandNotifyMessage } from './notify-message';

export const UPDATE_DEMAND_LIST_INTERVAL = 60000; // Период между апдейтами листа в мс

// Дефолтные параметры запроса
export const DEFAULT_REQUEST_PARAMS = {
  perPage: 20, // Количество строк на страницу
  page: 1 // Номер начальной страницы
};

// Максимальное количество символов в названии точки отправления или точки назначения, после которого идёт сокращение названия
export const DEFAULT_SOURCE_TARGET_LENGTH = 25;

export const UPDATE_GET_COUNTS_INTERVAL = 60000; // Период между апдейтами листа в мс
