import { ReactElement, ReactNode, useEffect } from 'react';
import { Redirect, Switch } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { MobileApplicationDictionaryRequest } from '@common/types/dictionaries/mobile-application';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { useDispatch } from 'react-redux';
import { monitoringForwarderPointStatusesRoutine } from '@modules/monitoring/actions';
import { useStimulSoft } from '@common/hooks/stimulsoft/use-stimulsoft';

interface IProps {
  route: {
    component: ReactNode | JSX.Element | ReactElement;
    routes: RouteConfig[];
  };
}

/*
 *На данный момент в модуле есть только один файл контейнеров - src\modules\monitoring\containers\index.tsx
 * Он содержит в себе маршрутизацию для модуля.
 * На самом деле нужно делать структурный рефакторинг модуля Мониторинг
 * - выносить логику из компонентов в контейнеры.
 */
const Monitoring = (props: IProps) => {
  useStimulSoft()
  const dispatch = useDispatch();

  useEffect(() => {
    const payload: MobileApplicationDictionaryRequest = {
      mobileApplicationDictionary: Dictionary.ForwarderPointStatuses,
      operation: DictionariesEndpoint.Index
    };
    dispatch(monitoringForwarderPointStatusesRoutine(payload));
  }, []);

  return (
    <>
      <Switch>
        <Redirect from={'/monitoring'} exact={true} to={'/monitoring/dashboard'} />
      </Switch>
      {/* child routes won't render without this */}
      {renderRoutes(props.route.routes)}
    </>
  );
};

export default Monitoring;
