import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { deleteBoardWidget } from '../actions';
import initialState from '../store';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

export const deleteBoardWidgetReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [deleteBoardWidget.SUCCESS]: (state, action: PayloadAction<AnalyticsWidget>) => {
    const { ID } = action.payload;

    if (ID && state.Boards?.data) {
      const newWidgets = Object.values(state.Widgets).filter((item) => item.ID !== ID);

      state.Widgets = newWidgets.reduce((prev, cur) => {
        prev[cur.ID] = cur;

        return prev;
      }, {});
    }

    return state;
  }
});
