import { useAvatar } from '@modules/account/utils/hooks/use-avatar';
import { SaveUserRequest } from '@modules/account/types';
import { checkImgResolution } from '@common/utils/validators/check-img-resolution';
import { userRoutine } from '@modules/account/actions';
import { useSelector } from 'react-redux';
import { userFormSelector } from '@modules/account/selectors/components/panes';
import { Dispatch } from 'redux';
import { DictionariesEndpoint } from '@common/types';
import { checkHasChangesInProfileForm } from '@modules/account/utils/helpers/check-has-changes-in-profile-form';
import { notifyUserAboutNoChanges } from '@components/feedback/ils-user-notify';

export const useSaveUserChanges = ({ dispatch }: { dispatch: Dispatch }) => {
  const { initialValues, userAvatar, currentUserID } = useSelector(userFormSelector);
  const { newAvatar, onAvatarChange } = useAvatar(userAvatar);

  const onFinish = (values: SaveUserRequest) => {
    if (checkHasChangesInProfileForm({ values, initialValues, avatar: userAvatar, newAvatar }) || !currentUserID) {
      notifyUserAboutNoChanges();
      return;
    }
    const hasAvatar = typeof newAvatar === 'string';
    if (!hasAvatar && newAvatar?.originFileObj) {
      checkImgResolution(newAvatar?.originFileObj);
    }
    const UserImage = (!hasAvatar && newAvatar?.originFileObj) || userAvatar;
    const payload = {
      values: {
        ...initialValues,
        ...values,
        UserImage
      },
      id: currentUserID,
      operation: DictionariesEndpoint.Update
    };
    dispatch(userRoutine(payload));
    onAvatarChange(null);
  };

  return { onFinish, userAvatar, onAvatarChange };
};
