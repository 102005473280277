import { UpdateVehicleTrackerRequest } from '@common/types/dictionaries/monitoring';
import { MonitoringAPI } from '@modules/monitoring/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringUpdateVehicleTrackersRoutine } from '../actions';
import { monitoringDashboardAllUpdate } from '../children/dashboard/sagas/get-dashboard-all';

function* monitoringUpdateVehicleTrackerWorker(action: PayloadAction<UpdateVehicleTrackerRequest>) {
  const { id } = action.payload;
  const { request, success, failure, fulfill } = monitoringUpdateVehicleTrackersRoutine;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(MonitoringAPI.updateVehicleTrackers, action.payload);

    const payload = data?.[id] || data;

    yield put(success(payload));
    yield call(monitoringDashboardAllUpdate);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringUpdateVehicleTrackerWatcher() {
  yield takeLatest(monitoringUpdateVehicleTrackersRoutine.trigger, monitoringUpdateVehicleTrackerWorker);
}
