import { FC } from 'react';
import { ILSButtonTooltip, ILSUserConfirm } from '@components/index';
import { DriverTableButtons } from '@modules/catalog/constants/tables/mobile-application-buttons';
import { EMPTY_STRING } from '@common/constants';
import { HandleMobileApplicationAction } from '@modules/catalog/types/functions';
import { Driver } from '@common/types';

interface IProps {
  disabled: boolean;
  selectedDrivers: Array<Driver['ID']>;
  handleMobileApplicationAction: HandleMobileApplicationAction;
}

export const CatalogInviteDriverToMobileApplicationButtonComponent: FC<IProps> = ({
  disabled,
  handleMobileApplicationAction,
  selectedDrivers
}) => {
  const handleClick = () => {
    ILSUserConfirm({
      text: 'Вы действительно хотите произвести регистрацию выбранных водителей в мобильном приложении и отправить им смс с паролем?',
      onConfirm: handleMobileApplicationAction,
      confirmParams: { operation: DriverTableButtons.Invite, driverIDs: selectedDrivers },
      onConfirmText: `Отправить ${selectedDrivers.length ?? EMPTY_STRING}`,
      onCloseText: 'Нет'
    });
  };

  return (
    <ILSButtonTooltip
      disabled={disabled}
      title={'Система отправит данному водителю на указанный номер телефона смс с паролем для доступа в мобильное приложение'}
      children={'Регистрация в МП'}
      onClick={handleClick}
    />
  );
};
