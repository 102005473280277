import { createAction, createReducer, Reducer } from '@reduxjs/toolkit';

const prefixName = `UPDATE_MODULE_STATE/`;

export const createActionUpdateModule = (moduleName: string) => createAction<any>(prefixName + moduleName);

export const getActionUpdateModule = (moduleName: string) => prefixName + moduleName;

export const createLoadReducer: <T>(moduleName: string) => Reducer<T> = (moduleName) =>
  createReducer({} as any, (builder) => {
    builder.addCase(createActionUpdateModule(moduleName), (state, action) => {
      const newModuleData = action.payload;
      state = { ...state, ...newModuleData };
      return state;
    });
  });
