import { call, put, takeLatest } from 'redux-saga/effects';
import { accountAttachmentDeleteRoutine, accountAttachmentIndexRoutine } from '@modules/account/actions';
import { AccountAttachmentAPI } from '@modules/account/api/account-attachment';
import { PayloadAction } from '@reduxjs/toolkit';
import { AccountAttachmentCategory, AccountAttachmentsDependent } from '../types/attachments';

export function* accountAttachmentDeleteWorker(
  action: PayloadAction<{
    id: number;
    dependentID: number;
    dependent: AccountAttachmentsDependent.Account;
    accountAttachmentCategory: AccountAttachmentCategory;
  }>
) {
  const { failure, fulfill, success } = accountAttachmentDeleteRoutine;
  const { id, accountAttachmentCategory, dependent, dependentID } = action.payload;

  try {
    const {
      data: { data }
    } = yield call(AccountAttachmentAPI.delete, { id });
    if (data) {
      yield put(accountAttachmentIndexRoutine.trigger({ accountAttachmentCategory, dependent, dependentID }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* accountAttachmentDeleteWatcher() {
  yield takeLatest(accountAttachmentDeleteRoutine.trigger, accountAttachmentDeleteWorker);
}
