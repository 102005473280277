import { DepotModel, IndexedArray, VehicleModel, VehicleTracker } from '@common/types';
import { DriverModel } from './driver';
import { UnviewedData } from './monitoring';
import {
  MonitoringTrip,
  MonitoringTripBrokenSequenceDataModel,
  MonitoringTripModel,
  MonitoringTripOnLateDataModel,
  MonitoringTripOnTimeDataModel,
  MonitoringTripOnViolationsDataModel,
  MonitoringTripUndeliveredModel,
  MonitoringTripWpInfoModel
} from './monitoring-trip';
import { MonitoringWaypointModel } from './monitoring-waypoint';
import { Tracker } from '@common/types/dictionaries/tracker';

export enum ZoneRecordType {
  Delivery = 0,
  Monitoring = 1
}

export type ZoneRecord = {
  ID: string | number;
  Priority?: null | number;
  Name: string;
  Color: number | string;
  BorderHash: string;
  Type: ZoneRecordType;
  AccountID: number | string;
  Deleted?: string | Date;
  Tariff?: number | null;
  ZoneCode: string;
  Description?: string | null;
  Queue?: number | null;
  PlanConfigID?: number | string;
  WorkSchedule?: number | null;
  FriendZoneID: Array<string | number>;
};

export type MonitoringVehicle = VehicleModel & {
  Driver: DriverModel;
  ForMarker?: null;
  ForbiddenZones?: null;
  VehicleTracker: MonitoringVehicleTrackerSensorModel | [];
};

export type MonitoringVehicleTrackerModel = {
  ID: number;
  VehicleID: number;
  TrackerIMEI: number | string;
  AccountID: number;
  From: number;
  Till: number;
  Priority: number;
  UserID: number;
  Created: number;
  Changed: number;
};

export type TrackGeoModel = {
  IMEI: number;
  DT: number;
  GeoAddressGUID: string | null;
  GeoRoadOsmID: number | null;
  GeoRoadBegin: number | null;
  GeoRoadEnd: number | null;
  GeoRoad?: GeoRoadModel;
  GeoAddress?: GeoAddressModel;
};

export type GeoRoadModel = {
  OsmName: string | null;
} & Partial<GeoRoadModelLegacy>;

/**
 * @deprecated delete in new version C
 */
export type GeoRoadModelLegacy = {
  OsmID: number | null;
  Begin: number | null;
  End: number | null;
  OsmClass: number | null;
  Speed: number | null;
  Edited: number | null;
  OriginalSpeedLimit: number | null;
  UserName: string | null;
  Created: number;
  Changed: number;
};

export type GeoAddressModel = {
  FullName: string;
} & Partial<GeoAddressModelLegacy>;

/**
 * @deprecated delete in new version C
 */
export type GeoAddressModelLegacy = {
  GUID: string;
  ParentGUID: string;
  Level: number;
  OffName: string;
  ShortName: string;
  Code: string;
  OKATO: string;
  OKTMO: string;
  PostCode: string;
  Insul: string;
  Latitude: number;
  Longitude: number;
  ChangeTime: null;
  ChangeStatus: number;
  OSMorYA: string;
  AreaID: number;
  KindFromSite: string;
  CadNum: null;
};

/***
 * Point events data. События на точках трека
 */
export type MonitoringTrackModel = {
  IMEI: number;
  DT: number;
  Recieved: number | null;
  Valid: number | null;
  SatCount: number | null;
  Engine: number | null;
  Latitude: number | null;
  Longitude: number | null;
  Altitude: number | null;
  Type: number | null;
  Speed: number | null;
  DeviceSpeed: number | null;
  Azimuth: number | null;
  DeviceAzimuth: number | null;
  Distance: number | null;
  Duration: number | null;
  AngularSpeed: number | null;
  RadialAcc: number | null;
  TangentialAcc: number | null;
  Offset: number | null;
  Odometer: number | null;
};

type SensorMapping = IndexedArray<{
  ID: number;
  TrackerIMEI: Tracker['IMEI'];
  SensorID: number;
  From: VehicleTracker['From'];
  Till: VehicleTracker['Till'];
  Path: string;
  Multiplier: number | null;
  Sensor: {
    ID: number;
    Name: string;
    Description: string;
    DataTypeID: number;
    Unit: string;
    GridSettings: string | null;
    Visible: number;
    SensorTypeID: number;
    Icon: string | null;
  };
  SensorValue: IndexedArray<
    {
      Imei: number;
      DT: number;
      SensorID: number;
      Raw: number;
      Value: number;
      Filtered: number | null;
    } & UnviewedData
  >;
}>;
type FuelKeyPoints = IndexedArray<
  {
    IMEI: number;
    DT: number;
    fuelbefore: number;
    fuelafter: number;
    pointtype: number;
  } & UnviewedData
>;

type MonitoringVehicleTrackerSensor = VehicleTracker & {
  SensorMapping?: SensorMapping;
  FuelKeyPoints?: FuelKeyPoints;
};

export type MonitoringVehicleTrackerSensorModel = IndexedArray<MonitoringVehicleTrackerSensor> | Array<MonitoringVehicleTrackerSensor>;

export type GroupVehicleModel = {
  ID: number;
  GroupID: number;
  VehicleID: number;
  Group: {
    ID: number;
    Name: string;
    AccountID: number;
  };
};

export type TrackerModel = {
  IMEI: number;
  TrackerModelID: number;
  TrackerModelProfileID: number;
  Type: number;
  OldDeviceID: number;
  SerialNo: string | null;
  SimNumber: string | null;
  SimTariff: string | null;
  StatusID: number;
  Info?: {
    SendToCRM: boolean;
    TrackerType: string;
    CRMSendNeeded: boolean;
  };
  Created: number;
  Changed: number;
  Comment: string | null;
};
export type TrackerLastInfoModel = {
  IMEI: number;
  DT: string;
  Recieved: number | null;
  Valid: null | 0 | 1;
  SatCount: string | number | null;
  Latitude: string | number;
  Longitude: string | number;
  Speed: string | number | null;
  Azimuth: string | number | null;
  Odometer: string | number | null;
  Type: number;
  TypeChanged: string | number | null;
  Created: number;
  Changed: number;
};

export type EventGeoZoneModel = {
  IMEI: number;
  DT: number;
  EventTypeID: number;
  ZoneID: number | string;
  Duration: number;
  Zone: ZoneRecord;
};

export type EventGeoDepotModel = {
  IMEI: number;
  DT: number;
  EventTypeID: number;
  DepotID: number;
  TripID: number;
  Duration: number;
  Depot: DepotModel | null;
};
export type EventVehicleTracker = {
  Tracker: {
    ID: number;
    TrackerLastInfo?: TrackerLastInfoModel;
    Event_GeoZone?: EventGeoZoneModel;
    Event_GeoDepot?: EventGeoDepotModel;
  } & TrackerModel;
} & Partial<VehicleTracker>;

export type MonitoringVehicleEventsModel = VehicleModel & {
  Driver: DriverModel;
  GroupVehicle?: IndexedArray<GroupVehicleModel>;
  VehicleTracker?: IndexedArray<EventVehicleTracker>;
  Trip: IndexedArray<
    MonitoringTripModel & {
      Waypoint: IndexedArray<MonitoringWaypointModel>;
    }
  >;
};

export type MonitoringBrokenSequenceDataModel = MonitoringVehicle & MonitoringTripBrokenSequenceDataModel;

export type MonitoringOnTimeDataModel = MonitoringVehicle & MonitoringTripOnTimeDataModel;

export type MonitoringUndeliveredModel = MonitoringVehicle & {
  Trip: Array<MonitoringTripUndeliveredModel> | IndexedArray<MonitoringTripUndeliveredModel>;
};

export type MonitoringOnLateDataModel = MonitoringVehicle & {
  Trip: Array<MonitoringTripOnLateDataModel> | IndexedArray<MonitoringTripOnLateDataModel>;
};

export type MonitoringOnViolationsDataModel = MonitoringVehicle & {
  Trip: Array<MonitoringTripOnViolationsDataModel> | IndexedArray<MonitoringTripOnViolationsDataModel>;
};

export type MonitoringBDDAndFuelDrainModel = MonitoringVehicle & {
  VehicleTracker?: MonitoringVehicle['VehicleTracker'];
};

export type MonitoringServedDataModel = MonitoringVehicle & {
  Trip?: Array<MonitoringTrip> | IndexedArray<MonitoringTrip>;
};

export type MonitoringTripWpInfoDataModel = MonitoringVehicle & MonitoringTripWpInfoModel;

export type MonitoringFuelDrainDecoratorReturnedModel = {
  Name: string;
  Number: string;
  ID: number | string;
  DriverName: string;
  Phone: string;
  Speed: string;
  rowKey: number | string;
  key: number | string;
  TimeInterval: (string | number)[];
  FuelDrain: string | undefined;
  VehicleID: string | number;
  Driver?: DriverModel;
};

/**
 *  Объект, содержащий информация о треке, возвращается запросом /Monitoring/getTrack
 */
export type MonitoringGetTrackDataModel = VehicleTracker & {
  DT: number;
  IMEI: number;
  Speed: number | null;
  Tracker: {
    IMEI: number;
    TrackerModelID: number;
    TrackerModelProfileID: number;
    Type: number;
    OldDeviceID: number;
    SerialNo: string;
    SimNumber: string;
    SimTariff: string;
    StatusID: number;
    Info: any;
    Created: number;
    Changed: number;
    Comment: string;
    TrackerLastInfo?: TrackerLastInfoModel;
    TrackGeo: IndexedArray<IndexedArray<TrackGeoModel>>;
    Track: IndexedArray<MonitoringTrackModel>;
  };
  Type: number | null;
};

export type MonitoringBDDDecoratorReturnedModel = Omit<
  MonitoringFuelDrainDecoratorReturnedModel,
  'Speed' | 'TimeInterval' | 'FuelDrain'
> & {
  ViolationType: string;
  Comment: string | null;
};

export enum MonitoringVehicleMovementSpeedIcon {
  NoData = 'speed_no_data',
  Stop = 'speed_0_5',
  VerySlow = 'speed_5_10',
  Slow = 'speed_10_30',
  Medium = 'speed_30_70',
  Hight = 'speed_over_70',
  NoVehicleTracker = 'no_vehicle_tracker'
}

export enum MonitoringVehicleColorDependSpeed {
  NoData = '#232323',
  Stop = '#2358c6',
  VerySlow = '#ae81ff',
  Slow = '#43cc4c',
  Medium = '#ffa605',
  Hight = '#d24747',
  NoVehicleTracker = '#232323'
}

export enum MonitoringVehicleMovementSpeedTitle {
  NoData = 'Нет данных',
  Stop = 'Движется, 0-5 км/ч',
  VerySlow = 'Движется, 5-10 км/ч',
  Slow = 'Движется, 10-30 км/ч',
  Medium = 'Движется, 30-70 км/ч',
  Hight = 'Движется, от 70 км/ч',
  NoVehicleTracker = 'Нет трекера'
}

export enum MonitoringVehicleStatus {
  NoPlan = 'NoPlan',
  During = 'During',
  DuringWithLates = 'DuringWithLates',
  ReturnBase = 'ReturnBase',
  ReturnBaseWithLates = 'ReturnBaseWithLates',
  CompleteWithLates = 'CompleteWithLates',
  Complete = 'Complete'
}
