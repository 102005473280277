import { FC } from 'react';
import { TenderPlatformWaypointsContainer } from '@modules/tender-platform/containers/tender-card/lots/form/waypoints';
import { TenderPlatformRegionalCitiesComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/route/regional-cities';
import { CardTab, CardTabTitle } from '@modules/tender-platform/constants';
import { TenderPlatformDistanceComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/route/distance';
import { TenderPlatformDividerTitle } from '@modules/tender-platform/components/tender-card/lots/form/divider-title';
import { TenderPlatformRouteListContainer } from '@modules/tender-platform/containers/tender-card/lots/form/route-list';

interface IProps {
  disabled: boolean;
  fromPlanner: boolean;
}

export const TenderPlatformRouteContainer: FC<IProps> = ({ disabled, fromPlanner }) => {
  return (
    <fieldset className={'tender-form-fieldset'}>
      <TenderPlatformDividerTitle id={CardTab.Route} title={CardTabTitle.Route} />
      <TenderPlatformDistanceComponent />
      <TenderPlatformRegionalCitiesComponent disabled={disabled || fromPlanner} />
      {fromPlanner ? <TenderPlatformRouteListContainer /> : <TenderPlatformWaypointsContainer disabled={disabled} />}
    </fieldset>
  );
};
