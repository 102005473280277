import { CardTab, CardTabTitle } from '@modules/tender-platform/constants';
import { ReactSVG } from 'react-svg';
import { getSRCIconSVG } from '@common/decorators/path';

export const mapTenderMenu = (cardTabs: CardTab[]) => {
  return cardTabs.map((tab) => {
    return {
      label: (
        <div className={'tender-platform-menu-navigation-item'}>
          {CardTabTitle[tab]}
          <ReactSVG className={'svg-container'} src={getSRCIconSVG(tab === CardTab.Template ? 'template' : 'right-arrow')} />
        </div>
      ),
      key: tab
    };
  });
};
