import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { createBoardSetRoutine } from '../actions';
import initialState from '../store';
import { AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';

export const createBoardSetsReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [createBoardSetRoutine.REQUEST]: (state) => {
    state.BoardSets.adding = true;
    return state;
  },
  [createBoardSetRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsBoardSetModel>;
    }>
  ) => {
    const { data } = action.payload;

    if (data && state.BoardSets?.data) {
      state.BoardSets.data = { ...state.BoardSets?.data, ...data };
    }

    return state;
  },
  [createBoardSetRoutine.FAILURE]: (state) => {
    return state;
  },
  [createBoardSetRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    state.BoardSets.adding = false;
    return state;
  }
});
