import ILSRoadGraphTestCleanButton from '@modules/road-graph/route-test/components/buttons/clean';
import ILSRoadGraphTestReverseButton from '@modules/road-graph/route-test/components/buttons/reverse';
import ILSRoadGraphTestSetMarkersButtons from '@modules/road-graph/route-test/components/buttons/set-makers';
import { trackDataSelector } from '@modules/road-graph/route-test/selectors';
import { useAppSelector } from '@core/hooks';
import ILSRoadGraphTestPermitZonesContainer from '@modules/road-graph/route-test/containers/forbidden-zones';

const ILSRoadGraphTestButtons = () => {
  const trackData = useAppSelector(trackDataSelector);

  return (
    <>
      <div className="map-left-buttons">
        <ILSRoadGraphTestSetMarkersButtons />
        {trackData?.routes && trackData?.waypoints && (
          <>
            <ILSRoadGraphTestReverseButton />
            <ILSRoadGraphTestCleanButton />
          </>
        )}
      </div>
      <div className="map-right-buttons">
        <ILSRoadGraphTestPermitZonesContainer />
      </div>
    </>
  );
};
export default ILSRoadGraphTestButtons;
