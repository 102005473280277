import { Account, ID } from '@common/types';

/**
 * Return array of objects, which ID (depends on param include): exist or not exist (default)
 * in array filtered by ids array (ids.include)
 * @param array
 * @param ids
 * @param include
 */
export const filterByID = <T extends { ID: ID }>(array?: Array<T>, ids?: Array<ID | string>, include: boolean = false) =>
  array?.filter(({ ID }) => (ID && include ? ids?.includes(ID) : !ids?.includes(ID))) ?? [];

/**
 * Return array of objects, which AccountID (depends on param include): exist or not exist (default)
 * in array filtered by Account ids array (ids.include)
 * @param array
 * @param ids
 * @param include
 */
export const filterByAccountID = <T extends { AccountID: Account['ID'] }>(
  array?: Array<T>,
  ids?: Array<Account['ID'] | string>,
  include: boolean = false
) => array?.filter(({ AccountID }) => (AccountID && include ? ids?.includes(AccountID) : !ids?.includes(AccountID))) ?? [];
