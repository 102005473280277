import { useMemo } from 'react';
import { ILSRow } from '@components/data-display/table/components/ils-row';
import { ILSCell } from '@components/data-display/table/components/ils-cell';
import { useVT } from 'virtualizedtableforantd4';
import { SCROLL_Y_DEFAULT, VISIBLE_VIRTUAL_ROWS_DEFAULT } from '@components/data-display/table/constants';
import { useTableRow } from '@components/data-display/table/hooks/row/use-table-row';
import { UseTableComponents } from '@common/types/components/table/hooks';
import { TableRecord } from '@common/types';

export const useTableComponents = <R extends TableRecord>({
  virtual,
  overscanRowCount,
  scroll,
  dragHandler,
  active,
  onTableRowDoubleClick,
  onTableRowClick,
  rowClass,
  onFocusRow,
  onContextOpen,
  dictionary,
  focusRowCustomHighlight,
  notHighlightFocusRow,
  selectedRowKeys,
  setFocusRow,
  tableName
}: UseTableComponents<R>) => {
  /**
   * NOTE: инициализируем виртуализацию таблиц
   * overscanRowCount - +/- кол-во видимых виртуальных строк в DOM дереве
   **/
  const y = scroll?.y ?? SCROLL_Y_DEFAULT;

  const [virtualTableComponents, setVirtualTable, virtualTableRef] = useVT(
    () => ({ scroll: { y }, overscanRowCount: overscanRowCount || VISIBLE_VIRTUAL_ROWS_DEFAULT }),
    [overscanRowCount, y]
  );
  //NOTE: setVirtualTable components
  useMemo(
    () =>
      setVirtualTable({
        body: {
          row: ILSRow,
          cell: ILSCell
        }
      }),
    [setVirtualTable]
  );
  const { onRow, onRowClick } = useTableRow({
    onTableRowClick,
    dragHandler,
    focusRowCustomHighlight,
    notHighlightFocusRow,
    active,
    onFocusRow,
    onTableRowDoubleClick,
    virtualTableRef,
    virtual,
    rowClass,
    onContextOpen,
    dictionary,
    selectedRowKeys,
    setFocusRow,
    tableName
  });

  const noVirtualizationComponents = {
    body: {
      row: ILSRow,
      cell: ILSCell
    }
  };
  /** Добавляем onClick к методам виртуальной строки */
  // @ts-ignore библиотека виртуализирующая таблицы мутировала все стандартные хендлеры событий - их необходимо присваивать через ref
  virtualTableRef.current = { ...virtualTableRef.current, onClick: onRowClick };
  const tableComponents = virtual ? virtualTableComponents : noVirtualizationComponents;

  return { tableComponents, virtualTableRef, scrollY: { y }, onRow, onRowClick };
};

