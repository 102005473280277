import { useAppSelector } from '@core/hooks';
import { splitSizesSelector } from '@core/selectors/split-sizes';
import { getBlocksWidths, getSplitSizeBlocksParams, PlannerBlocksFullScreen } from '@core/decorators/split-sizes';
import {
  PLAN_RESULT_BLOCK,
  PLAN_RESULT_BLOCK_DEFAULT_PERCENT,
  PLAN_RESULT_BLOCK_DEFAULT_VISIBLE,
  PLAN_RESULT_BLOCK_MIN_PERCENT
} from '@modules/planning/children/by-date/constants';
import { setSplitSizes } from '@core/actions/split-sizes';
import { useDispatch, useSelector } from 'react-redux';
import { plannerSettingsSelector } from '@modules/planning/selectors';
import { plannerSettingsRoutine } from '@modules/planning/actions';
import { PlannerSettingsParameter, PlannerSettingsParameterFullScreen } from '@modules/planning/types/planning';

export const useResultInterface = () => {
  const dispatch = useDispatch();

  const params = useAppSelector(splitSizesSelector);
  const { fullScreen } = useSelector(plannerSettingsSelector);

  const onFullChange = (arg: PlannerBlocksFullScreen | PlannerSettingsParameterFullScreen) => {
    if (typeof arg === 'string') {
      dispatch(plannerSettingsRoutine({ parameter: PlannerSettingsParameter.FullScreen, data: { [arg]: !fullScreen[arg] } }));
    } else {
      dispatch(plannerSettingsRoutine({ parameter: PlannerSettingsParameter.FullScreen, data: arg }));
    }
  };

  const visible = Array.isArray(params?.[PLAN_RESULT_BLOCK]?.visible)
    ? params?.[PLAN_RESULT_BLOCK]?.visible
    : PLAN_RESULT_BLOCK_DEFAULT_VISIBLE;
  const percents =
    params?.[PLAN_RESULT_BLOCK]?.percents && Number(params[PLAN_RESULT_BLOCK].percents[0])
      ? params?.[PLAN_RESULT_BLOCK]?.percents
      : PLAN_RESULT_BLOCK_DEFAULT_PERCENT;
  const { blocks } = getSplitSizeBlocksParams({
    fullScreen,
    percents,
    visibleBlocks: visible,
    minPercent: PLAN_RESULT_BLOCK_MIN_PERCENT
  });

  const handleResize = (_: number, allSizes: number[]) => {
    const percents = getBlocksWidths(allSizes, PLAN_RESULT_BLOCK_MIN_PERCENT);
    dispatch(setSplitSizes({ blockName: PLAN_RESULT_BLOCK, visible, percents }));
  };

  return { blocks, handleResize, onFullChange, fullScreen };
};
