import { Action } from 'redux';
import { Task } from 'redux-saga';
import { UnifiedRoutine } from 'redux-saga-routines';
import { cancel, fork, take } from 'redux-saga/effects';

/**
 * takeLatestWithSaveTask - кастомный эффект takeLatest.
 *
 * Внутри логика эффекта takeLatest (из оф. документации),
 * с добавлением логики сохранения тасок воркеров в глобальную переменую,
 * для последующей отмены в stopSagas.
 * @param data {
 *  saga -     воркер для отмены (Generator)
 *  routine -  рутина которая тригерит вызов воркера
 *  saveTask - метод который сохраняет таску в глобальную переменную
 *  taskName - имя таски под которым будет производится сохранение таски воркера в глобальной переменной
 * }
 */
export const takeLatestWithSaveTask = <T>(data: {
  saga: T;
  routine: UnifiedRoutine<(payload?: any) => Action>;
  saveTask: (args: { taskName: string; task: Task }) => void;
  taskName: string;
}) => {
  const { saga, routine, saveTask, taskName } = data ?? {};

  return fork(function* () {
    let lastTask: Task | null = null;

    while (true) {
      const action: any = yield take(routine.trigger);
      if (lastTask) {
        yield cancel(lastTask); // cancel is no-op if the task has already terminated
      }
      lastTask = yield fork(saga as any, action);
      //NOTE: Логика сохранения таски воркера в глобальную переменную
      lastTask && saveTask?.({ taskName: taskName, task: lastTask });
    }
  });
};
