import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { moveWaypointInTripRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { IndexedArray, Trip, Waypoint } from '@common/types';
import { RouteTotal } from '@common/types/dictionaries/route';

export type MoveWaypointInTripPayloadSucces = {
  routeTotal: IndexedArray<RouteTotal>;
  tripID: Trip['ID'];
  waypointIds: Waypoint['ID'][];
}
export const moveWaypointInTripReducer = createReducer(initialState, {
  [moveWaypointInTripRoutine.SUCCESS]: (
    state,
    action: PayloadAction<MoveWaypointInTripPayloadSucces>
  ) => {
    const { routeTotal, tripID, waypointIds } = action.payload;

    for (const vehicleID in routeTotal) {
      state.currentPlan.RouteTotal[vehicleID] = { ...routeTotal[vehicleID], changed: true };
      state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].changed = true;
      waypointIds.forEach((id) => {
        if (state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID]?.WaypointTotal?.[id]) {
          state.currentPlan.RouteTotal[vehicleID].TripTotal[tripID].WaypointTotal[id].changed = true;
        }
      });
    }

    return state;
  }
});
