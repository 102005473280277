import { Notify } from "@common/types";
import { EMPTY_STRING } from "@common/constants";
import { CatalogActions } from "@modules/catalog/actions";

export const CatalogNotificationStatuses: Partial<
  Record<
    CatalogActions,
    {
      loading: string;
      success: string;
      successPlural?: string;
      successSingle?: string;
      error?: string;
    }
  >
> = {
  [CatalogActions.READ]: { loading: 'Загрузка...', success: 'Загружено' },
  [CatalogActions.UPDATE]: { loading: 'Сохранение...', success: 'Строки изменены' },
  [CatalogActions.DELETE]: { loading: 'Удаление...', success: 'Строки удалены', successSingle: 'Строка удалена' },
  [CatalogActions.RESTORE]: { loading: 'Восстановление...', success: 'Строки восстановлены' },
  [CatalogActions.CREATE]: { loading: 'Добавление...', success: 'Строка добавлена' },
  [CatalogActions.CREATE_LOCAL]: {
    loading: 'Добавление...',
    success: 'Строка добавлена, заполните обязательные поля для сохранения'
  },
  [CatalogActions.GET_COORDS]: { loading: 'Получение координат...', success: 'Координаты обновлены' },
  [CatalogActions.BIND_TIME_ZONE]: {
    loading: 'Загрузка временных зон...',
    success: 'Временные зоны загружены...'
  },
  [CatalogActions.CLONE]: {
    loading: 'Копирование...',
    success: 'Скопировано'
  },
  [CatalogActions.HANDLE_MOBILE_APPLICATION]: {
    loading: 'Связь с мобильным приложением...',
    success: 'Отправлено'
  },
  [CatalogActions.GET_TARIFF]: {
    loading: 'Загрузка тарифов...',
    success: 'Список тарифов загружен'
  }
};

/**Метод получения текста уведомления для разных action*/
export const getNotificationMessage = (
  action: string,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { notificationMessage?: string }
): string => {
  if (payload?.notificationMessage) return payload.notificationMessage;
  return CatalogNotificationStatuses?.[CatalogActions[action]]?.[type] || EMPTY_STRING;
};

