import { ILSButtonTooltip } from '@components/index';
import { FC } from 'react';
import { useModal } from '@common/hooks/components/use-modal';
import { PlannerTenderPlatformConfirmModal } from './tender-platform-confirm-modal';
import { ButtonText, ValidateInfoType } from '@modules/planning/children/by-date/types/tender-from-planner';

interface IProps {
  text: ButtonText;
  inactive?: boolean;
  handler: () => void;
  onClick: () => void;  //NOTE будет прерывать открытие модальных окон
  rowsAmount: number;
  validateInfo?: ValidateInfoType;
}

export const PlannerTenderPlatformButton: FC<IProps> = ({ inactive, handler, text, onClick, rowsAmount, validateInfo }) => {
  const { visible, handleShow } = useModal();

  const handleOk = () => {
    handleShow();
    handler();
  };
  return (
    <>
      <ILSButtonTooltip
        placement="bottom"
        title={text.buttonTitle}
        onClick={onClick ?? handleShow}
        disabled={inactive}
        icon={text.iconComponent}
        className="trip-tender-platform-button"
      >
        ТП
      </ILSButtonTooltip>
      <PlannerTenderPlatformConfirmModal
        handler={handleOk}
        validateInfo={validateInfo}
        text={text}
        handleShow={handleShow}
        visible={visible}
        rowsAmount={rowsAmount}
      />
    </>
  );
};
