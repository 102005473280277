import { FC } from 'react';
import classnames from 'classnames';

interface IProps {
  className?: string;
  text: string;
}

export const ILSNoDataComponent: FC<IProps> = ({ text, className, ...props }) => (
  <p {...props} className={classnames(className)}>
    {text}
  </p>
);
