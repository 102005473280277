import { TENDER_PLATFORM_PERMISSIONS } from '../permissions';
import { AbacRules } from '@abac/types';
import {
  checkAccessToApproveOffer,
  checkAccessToCancelOffer,
  checkAccessToCarrierRatingModal,
  checkAccessToChangeOffer,
  checkAccessToChangeResponsibleInOffer,
  checkAccessToRateCarrier,
  checkAccessToTenderEditing,
  checkAccessToTenderSummary,
  checkRemoveTenderDocument
} from '@modules/tender-platform/abac/helpers';
import { AccountRole, UserRole } from '@common/types';

//TODO accountRoles достаточно, userRoles должны проверяться в helpers,
// иначе происходит двойная проверка, либо нужно дублировать правила убирая проверку на isAdmin, но корректно типизировав
// tenderRules (не через абстрактный и не информативный AbacRules, который в ядре описан как "всё что угодно")
export const tenderRules: AbacRules = {
  [UserRole.administrator]: {
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_EDIT]: checkAccessToTenderEditing,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CHANGE_RESPONSIBLE]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE_RESPONSIBLE]: checkAccessToChangeResponsibleInOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_APPROVAL]: checkAccessToApproveOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_CANCEL]: checkAccessToCancelOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE]: checkAccessToChangeOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_SUMMARY]: checkAccessToTenderSummary,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_REMOVE_DOCUMENT]: checkRemoveTenderDocument,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_VIEW_RATE_MODAL]: checkAccessToCarrierRatingModal,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_RATE_CARRIER]: checkAccessToRateCarrier
  },
  [UserRole.manager]: {
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_EDIT]: checkAccessToTenderEditing,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CHANGE_RESPONSIBLE]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE_RESPONSIBLE]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_APPROVAL]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_CANCEL]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE]: checkAccessToChangeOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_SUMMARY]: checkAccessToTenderSummary,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_VIEW_RATE_MODAL]: checkAccessToCarrierRatingModal,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_RATE_CARRIER]: checkAccessToRateCarrier
  },
  [AccountRole.cargoOwner]: {
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OWN]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_SEARCH]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_RATE_CARRIER]: checkAccessToRateCarrier,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_VIEW_RATE_MODAL]: checkAccessToRateCarrier,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_CANCEL]: false,
    [TENDER_PLATFORM_PERMISSIONS.AUCTION_OFFERS]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_ADD_DOCUMENTS]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_REMOVE_DOCUMENT]: checkRemoveTenderDocument
  },
  [AccountRole.carrier]: {
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OWN]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_SEARCH]: true,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_RATE_CARRIER]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_VIEW_RATE_MODAL]: checkAccessToCarrierRatingModal,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_ON_CANCEL]: checkAccessToCancelOffer,
    [TENDER_PLATFORM_PERMISSIONS.AUCTION_OFFERS]: false,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE]: checkAccessToChangeOffer,
    [TENDER_PLATFORM_PERMISSIONS.TENDER_ADD_DOCUMENTS]: false
  }
};
