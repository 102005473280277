import { ColumnTable, ReferenceTableData, ReferenceTablesData, TableRecord } from '@common/types';
import { isCellUniqOptions } from '@components/data-display/table/utils/helpers/is-cell-uniq-options';
import { getColumnOptions } from '@components/data-display/table/utils/helpers/column/get-column-options';

export const getReferenceData = ({ refData, column }: { refData?: ReferenceTablesData; column: ColumnTable<TableRecord> }) => {
  const referenceData = refData?.[column.dataIndex] as ReferenceTableData;
  const fieldNames = referenceData?.fieldNames ?? column.fieldNames;
  const columnOptions = isCellUniqOptions(referenceData?.options)
    ? undefined
    : getColumnOptions({
        variants: referenceData?.options ?? column.options,
        fieldNames,
        needSortByLabelField: column.needSortByLabelField
      });
  return { referenceData, fieldNames, columnOptions };
};
