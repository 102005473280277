import { IMonitoringStore } from '@modules/monitoring/types';
import moment from 'moment';
import { ICharData } from '../types/store';
import { dateTimeToChartDateFormat } from '@common/utils/general/interval-charts';

const current: Date = new Date();
const tomorrow = moment().add(1, 'day').toDate();

//с 00:00 до 00:00 след дня
const period: string[] = [
  dateTimeToChartDateFormat(current).substr(0, 11) + '00:00:00',
  dateTimeToChartDateFormat(tomorrow).substr(0, 11) + '00:00:00'
];
const day1: string = period[0].substr(0, 11);
const day2: string = period[1].substr(0, 11);

export const chartData: ICharData[] = [
  {
    name: 'КамАЗ 1',
    data: [
      {
        x: 'КамАЗ 1',
        y: [day1 + '00:00:00', day1 + '03:00:00']
      },
      {
        x: 'КамАЗ 1',
        y: [day1 + '06:00:00', day1 + '10:00:00']
      },
      {
        x: 'КамАЗ 1',
        y: [day1 + '10:45:00', day1 + '15:16:00']
      },
      {
        x: 'КамАЗ 1',
        y: [day1 + '19:03:00', day1 + '22:34:00']
      }
    ]
  },
  {
    name: 'КамАЗ 2',
    data: [
      {
        x: 'КамАЗ 2',
        y: [day1 + '01:10:00', day1 + '03:20:00']
      },
      {
        x: 'КамАЗ 2',
        y: [day1 + '04:13:00', day1 + '08:50:00']
      },
      {
        x: 'КамАЗ 2',
        y: [day1 + '14:16:00', day1 + '18:16:00']
      },
      {
        x: 'КамАЗ 2',
        y: [day1 + '22:48:00', day2 + '00:00:00']
      }
    ]
  },
  {
    name: 'КамАЗ 3',
    data: [
      {
        x: 'КамАЗ 3',
        y: [day1 + '00:40:00', day1 + '02:40:00']
      },
      {
        x: 'КамАЗ 3',
        y: [day1 + '08:45:00', day1 + '12:16:00']
      },
      {
        x: 'КамАЗ 3',
        y: [day1 + '18:03:00', day1 + '22:34:00']
      },
      {
        x: 'КамАЗ 3',
        y: [day2 + '00:00:00', day2 + '00:00:00']
      }
    ]
  },
  {
    name: 'КамАЗ 4',
    data: [
      {
        x: 'КамАЗ 4',
        y: [day1 + '01:13:00', day1 + '08:00:00']
      },
      {
        x: 'КамАЗ 4',
        y: [day1 + '10:13:00', day1 + '13:00:00']
      },
      {
        x: 'КамАЗ 4',
        y: [day1 + '15:16:00', day1 + '19:16:00']
      },
      {
        x: 'КамАЗ 4',
        y: [day1 + '22:00:00', day2 + '00:00:00']
      }
    ]
  },
  {
    name: 'КамАЗ 5',
    data: [
      {
        x: 'КамАЗ 5',
        y: [day1 + '00:00:00', day1 + '03:00:00']
      },
      {
        x: 'КамАЗ 5',
        y: [day1 + '06:00:00', day1 + '10:00:00']
      },
      {
        x: 'КамАЗ 5',
        y: [day1 + '10:45:00', day1 + '15:16:00']
      },
      {
        x: 'КамАЗ 5',
        y: [day1 + '19:03:00', day1 + '22:34:00']
      }
    ]
  }
];

let initialState: IMonitoringStore['MonitoringDeliveryControl'] = {
  chartData: [],
  intervals: {},
  colors: [],
  isFetching: true
};

export default initialState;
