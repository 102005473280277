import { Dictionary, TableColumnName } from '@common/types';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { handleSwitchersRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';

export const handleSwitchersReducer = createReducer(initialState, {
  [handleSwitchersRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      dictionary: Dictionary;
      dataIndex: TableColumnName;
      isOn: boolean;
    }>
  ) => {
    const { dataIndex, isOn, dictionary } = action.payload;
    state.groupSwitchers[dictionary] = { [dataIndex]: isOn };
    return state;
  }
});
