export enum TenderAction {
  SEARCH = 'SEARCH',
  READ = 'READ'
}

export enum TenderOfferAction {
  SEARCH = 'SEARCH',
  WITH_MY_PARTICIPATION = 'WITH_MY_PARTICIPATION',
  NOTIFY_SETTINGS = 'NOTIFY_SETTINGS',
  GET_TENDER_NOTIFY_SETTINGS = 'GET_TENDER_NOTIFY_SETTINGS',
  INDEX = 'INDEX',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SET_STATUS = 'SET_STATUS',
  APPOINT_RESPONSIBLE = 'APPOINT_RESPONSIBLE'
}

export const defaultTenderMessages: {
  [key in TenderAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  SEARCH: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка загрузки' },
  READ: { loading: 'Загрузка тендера..', success: 'Тендер загружен', error: 'Ошибка загрузки тендера' }
};

export const defaultTenderOfferMessages: {
  [key in TenderOfferAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  INDEX: { loading: 'Загрузка предложений...', success: 'Предложения загружены', error: 'Ошибка загрузки' },
  CREATE: { loading: 'Создание предложения...', success: 'Предложение создано', error: 'Ошибка создания' },
  UPDATE: { loading: 'Сохранение изменений...', success: 'Изменения сохранены', error: 'Ошибка сохранения' },
  SET_STATUS: { loading: 'Смена статуса...', success: 'Статус изменён', error: 'Ошибка изменения статуса' },
  APPOINT_RESPONSIBLE: {
    loading: 'Назначение ответственного...',
    success: 'Ответственный назначен',
    error: 'Ошибка назначения'
  },
  SEARCH: { loading: 'Поиск тендеров...', success: 'Тендеры загружены', error: 'Ошибка!' },
  WITH_MY_PARTICIPATION: { loading: 'Загрузка предложений компании...', success: 'Загружено', error: 'Ошибка!' },
  NOTIFY_SETTINGS: { loading: 'Загрузка...', success: 'Загружено', error: 'Ошибка!' },
  GET_TENDER_NOTIFY_SETTINGS: { loading: 'Загрузка...', success: 'Загружено', error: 'Ошибка!' },
  DELETE: { loading: 'Удаление...', success: 'Вы удалены из участников', error: 'Ошибка!' }
};
