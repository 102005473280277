import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import moment from 'moment';
import { FC } from 'react';
import { monitoringGraphDecorator } from '@modules/monitoring/children/dashboard/components/dashboard/utils/table-graph-decorator';
import { useAppSelector } from '@core/hooks';
import { tracksSelector } from '@modules/monitoring/children/event-details/selectors';
import { MONITORING_DATE_FORMAT } from '@modules/monitoring/config';
import { ILSMonitoringDashboardGraphComponent } from '../../components/dashboard/dashboard-table-graph';
import { graphChartScale } from '../../components/dashboard/configs/graph';
import { dataTypeVariants } from '@modules/monitoring/types/graph';
import { ILSSkeleton } from '@common/components';
import { compareAsString } from '@common/utils';
import { isEmpty } from 'lodash';

interface IProps {
  dataSource: IndexedArray<MonitoringBDDAndFuelDrainModel>;
  title: string;
  tillDate: string;
  selectedVehicleID: number | undefined;
  listOfGraph: dataTypeVariants[];
}

// Контейнер отвечающий за данные для графиков датчиков

//@todo сама диаграмма будет общим компонентом,
// а здесь будут передаваться данные для ее отображения и настройки
export const ILSMonitoringDashboardGraphContainer: FC<IProps> = ({ title, dataSource, tillDate, selectedVehicleID, listOfGraph }) => {
  const vehicleTracks = useAppSelector(tracksSelector);
  const graphData = monitoringGraphDecorator(dataSource, tillDate, selectedVehicleID, vehicleTracks, listOfGraph);
  const currentDate = moment().utc(false).format(MONITORING_DATE_FORMAT);

  return (
    <ILSSkeleton loading={!isEmpty(selectedVehicleID?.toString()) && !compareAsString(selectedVehicleID, vehicleTracks.loadedVehicleId)}>
      <ILSMonitoringDashboardGraphComponent
        title={title}
        graphData={selectedVehicleID ? graphData : []}
        useCurrentTime={currentDate === tillDate}
        chartScale={graphChartScale}
      />
    </ILSSkeleton>
  );
};
