import { call, put, takeLatest } from 'redux-saga/effects';
import { roadGraphGetSegmentsRoutine } from '../actions';
import { RoadGraphEditor } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';

function* roadGraphGetSegmentsWorker(
  action: PayloadAction<{
    points: string;
    roads: string;
  }>
) {
  const { request, success, failure, fulfill } = roadGraphGetSegmentsRoutine;
  const { points, roads } = action.payload;
  try {
    yield put(request({ points, roads }));
    const {
      data: {
        data: { params }
      }
    } = yield call(RoadGraphEditor.getSegments, { points, roads });
    yield put(success({ data: params }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* roadGraphGetSegmentsWatcher() {
  yield takeLatest(roadGraphGetSegmentsRoutine.trigger, roadGraphGetSegmentsWorker);
}
