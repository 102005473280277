import { DocumentsFormDataType, DocumentsFormData } from '../types/document-form';
import { isEmpty, isObject } from 'lodash';
import { periodToStringBySeparator } from '@common/utils/helpers/date-time/date-time';

type ConvertFormData = { [k in DocumentsFormDataType]?: string | number };

export const convertDocumentsFormData = (formData: DocumentsFormData) => {
  if (isEmpty(formData) || !isObject(formData)) return {};
  return Object.keys(formData).reduce((result: ConvertFormData, formFiled) => {
    switch (true) {
      case DocumentsFormDataType.Period === formFiled:
        result[formFiled] = periodToStringBySeparator({ date: formData[formFiled] });
        break;
      default:
        result[formFiled] = formData[formFiled];
    }
    return result;
  }, {});
};
