import { emulatorStopRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { StopRequest } from '../types/api';

// Останавливает эмуляцию выбранного плана (planID)
function* emulatorStopWorker(action: PayloadAction<StopRequest>) {
  const { request, success, failure, fulfill } = emulatorStopRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    yield call(EmulatorAPI.stop, { planID });

    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorStopWatcher() {
  yield takeLatest(emulatorStopRoutine.TRIGGER, emulatorStopWorker);
}
