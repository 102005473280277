import { FC } from 'react';
import { SettingsForwarderPointStatusesGroupTableContainer } from '@modules/settings/containers/mobile-application/settings-forwarder-point-statuses-group-table';
import { ILSCollapse, ILSCollapsePanel } from '@common/components';
import { Dictionary } from '@common/types/dictionaries/dictionaries';
import { useDispatch } from 'react-redux';
import { mobileApplicationDictionaryOperationRoutine } from '@modules/settings/actions';
import { DictionariesEndpoint } from '@common/types';
import { SettingsForwarderPointStatusesTableContainer } from '@modules/settings/containers/mobile-application/settings-forwarder-point-statuses-table';

export const MobileApplication: FC = () => {
  const dispatch = useDispatch();

  const readMobileApplicationCatalogDictionary = (mobileApplicationDictionary: Array<string> | string) => {
    if (mobileApplicationDictionary) {
      dispatch(
        mobileApplicationDictionaryOperationRoutine({
          mobileApplicationDictionary,
          operation: DictionariesEndpoint.Index
        })
      );
    }
  };

  return (
    <ILSCollapse accordion bordered={false} onChange={readMobileApplicationCatalogDictionary} className={'mobile-application'}>
      <ILSCollapsePanel key={Dictionary.ForwarderPointStatuses} header={'Статусы точек экспедитора'}>
        <SettingsForwarderPointStatusesTableContainer />
      </ILSCollapsePanel>
      <ILSCollapsePanel key={Dictionary.ForwarderPointStatusesGroup} header={'Группы статусов точек экспедитора'}>
        <SettingsForwarderPointStatusesGroupTableContainer />
      </ILSCollapsePanel>
    </ILSCollapse>
  );
};
