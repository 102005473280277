import { moveWaypointToTripRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { createNotifyForMoveWPToTrip } from '../../helpers/collect-notify-message';
import { ErrorsAPI, ResponseAPI } from '@common/types/general/api/api';
import { Dictionary, IndexedArray, Trip, Vehicle, Waypoint } from '@common/types';
import { isEmpty } from 'lodash';
import { RouteTotal } from '@common/types/dictionaries/route';
import { planRoutesSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { getRoutesIdsByTripCondition } from '@modules/planning/children/by-date/helpers/sagas/get-routes-ids-by-trip-condition';

export type RouteTotalResponse = { data: ResponseAPI<IndexedArray<RouteTotal>> };

export type MoveWaypointToTripResponse = {
  data: ResponseAPI<{
    sourceTrip: Trip[];
    targetTrip: Trip[];
    waypointID: Waypoint['ID'][];
    deletedSourceTripIDs: Trip['ID'][];
    errors: { errors: string[] };
  }>;
};

function* moveWaypointToTripWorker(
  action: PayloadAction<{
    tripID: number;
    waypointID: Waypoint['ID'];
    planID: number;
  }>
) {
  const { request, success, failure, fulfill } = moveWaypointToTripRoutine;
  const { tripID, waypointID, planID } = action.payload;

  try {
    yield put(request({ waypointID }));

    const {
      data: {
        data: { sourceTrip, targetTrip, errors, waypointID: waypointIDs, deletedSourceTripIDs }
      }
    }: MoveWaypointToTripResponse = yield call(PlanningAPI.moveWaypointToTrip, { tripID, waypointID });

    if (!isEmpty(errors?.errors)) {
      const error = Object.keys(errors.errors).map((id: string) => `${id}: ${errors.errors[id]}`);
      if (error.length) {
        yield put(failure({ error }));
      }
    }

    const routes: IndexedArray<RouteTotal> = yield select(planRoutesSelector);

    const { deletedSourceTripRoutesIDs, sourceTripRoutesIDs } = getRoutesIdsByTripCondition({
      routes,
      deletedSourceTripIDs,
      sourceTrip
    });

    const {
      data: { data: routesTotal }
    }: RouteTotalResponse = yield call(PlanningAPI.getTotal, {
      planID,
      vehicleID: sourceTripRoutesIDs.concat(targetTrip[0].VehicleID),
      dictionary: Dictionary.Route
    });

    const payload: {
      routesTotal: IndexedArray<RouteTotal>;
      waypointIDs: Array<Waypoint['ID']>;
      deletedSourceTripRoutesIDs: Array<Vehicle['ID']>;
    } = {
      routesTotal,
      waypointIDs,
      deletedSourceTripRoutesIDs
    };

    yield put(success(payload));
  } catch (error) {
    if ((error as ErrorsAPI)?.errors && typeof (error as ErrorsAPI).errors === 'object') {
      const errorNote = createNotifyForMoveWPToTrip(error as ErrorsAPI);
      if (errorNote?.length) {
        yield put(failure({ error: errorNote }));
      } else {
        yield put(failure({ error }));
      }
    } else {
      yield put(failure({ error }));
    }
  } finally {
    yield put(fulfill());
  }
}

export function* moveWaypointToTripWatcher() {
  yield takeLatest(moveWaypointToTripRoutine.trigger, moveWaypointToTripWorker);
}
