export enum DocFilesTypes {
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Bmp = 'bmp',
  Pdf = 'pdf'
}

export type FileAttachment = {
  OriginalName: string | undefined;
  Extension: DocFilesTypes;
  Subject: string;
  UrlOriginal: string;
  UrlThumbnail?: string;
};
