import { Dispatch, SetStateAction, useMemo } from 'react';
import { ILSFilteredVehicle } from '@modules/planning/children/by-date/components/result/table-head/action-buttons/filtered-vehicle';
import { ILSApprovedOrders } from '@modules/planning/children/by-date/components/result/table-head/action-buttons/approved-orders';
import { IPlannerPlanningVehicleTableContainerProps } from '@modules/planning/children/by-date/containers/result/tables/vehicle-table';
import { VehicleTableFilterFields } from '@modules/planning/children/by-date/types';

export const useVehicleButtons = ({
  setActiveFiltersData,
  orderSummary,
  addOrderForVehicle
}: Pick<IPlannerPlanningVehicleTableContainerProps, 'orderSummary' | 'addOrderForVehicle'> & {
  setActiveFiltersData: Dispatch<SetStateAction<VehicleTableFilterFields>>;
}) => {
  const actionTableButtons = useMemo(
    () => [
      {
        Component: ILSFilteredVehicle,
        props: {
          setActiveFiltersData,
          orderSummary
        }
      },
      { Component: ILSApprovedOrders, props: { addOrderForVehicle } }
    ],
    [orderSummary]
  );

  return { actionTableButtons };
};
