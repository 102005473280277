import { ILSMap } from '@core/containers/map';
import { LayerGroup } from 'leaflet';
import { MutableRefObject, useEffect, useState } from 'react';

export type TrackMapLayersGroup =
  | {
      markerLayer: undefined;
      trackLayer: undefined;
      isInit: false;
    }
  | {
      markerLayer: LayerGroup;
      trackLayer: LayerGroup;
      isInit: true;
    };
type useTrackMapInitEffect = (arg0: { Map: MutableRefObject<any> }) => { mapLayersGroup: TrackMapLayersGroup };

export const useTrackMapInit: useTrackMapInitEffect = ({ Map }) => {
  const [mapLayersGroup, setMapLayerGroup] = useState<TrackMapLayersGroup>({
    trackLayer: undefined,
    markerLayer: undefined,
    isInit: false
  });
  useEffect(() => {
    Map.current = new ILSMap() as ILSMap;
    const curMap: ILSMap = Map.current;
    curMap.addRuler();

    const trackLayer = curMap.addLayerGroup([]);
    const markerLayer = curMap.addLayerGroup([]);
    setMapLayerGroup({
      trackLayer,
      markerLayer,
      isInit: true
    });
    return () => Map.current.deinit();
  }, []);
  return { mapLayersGroup };
};

