import { IDocumentsPayload } from '@modules/documents/types/document-type';
import { documentCreateRoutine, documentsIndexRoutine, resetDependentList, setDocumentType } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { ModulePath, Notify } from '@common/types';
import history from '@common/utils/history';
import { resetCurrentTemplate } from '@modules/documents/children/templates/actions';
import { confirmPromise } from '@modules/documents/helpers/document-create-confirm-modal';

export function* documentCreateWorker({ payload }: { payload: { form: IDocumentsPayload; withModal?: boolean } }) {
  const { request, success, failure, fulfill } = documentCreateRoutine;

  try {
    yield put(request());

    const { data } = yield call(Documents.create, payload.form);

    yield put(success(Object.values(data.data)[0]));

    ILSUserNotify(Notify.Success, 'Документ успешно создан');

    if (payload.withModal) {
      const withRedirect = (yield confirmPromise()) as boolean;
      if (withRedirect) {
        yield put(setDocumentType((Object.values(data.data)[0] as any).Type));
        yield put(resetCurrentTemplate());
        yield put(resetDependentList());
        yield call(history.push, `${ModulePath.Documents}${ModulePath.DocumentsAll}?type=${(Object.values(data.data)[0] as any).Type}`);
      }
    }
  } catch (error) {
    yield put(failure({ error }));
    ILSUserNotify(Notify.Error, error as any, 3);
  } finally {
    yield put(documentsIndexRoutine());
    yield put(fulfill());
  }
}

export function* documentCreateWatcher() {
  yield takeEvery(documentCreateRoutine.trigger, documentCreateWorker);
}
