import { ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import { ILSModuleTitle } from './title';

export default defineModule({
  title: ModuleTitle.Title,
  component: ILSModuleTitle,
  inScope: [ModuleScope.Title, ModuleScope.HeaderLeft]
});
