import { FC } from 'react';
import { TenderFormTableField, TenderFormTableLabel } from '@modules/tender-platform/constants/content';
import { TENDER_CREATE_FROM_PLANNER_MENU } from '@modules/tender-platform/constants';

interface IProps {
  field: TenderFormTableField;
}

export const TenderOwnFormTableFieldTitle: FC<IProps> = ({ field }) => {
  return (
    <div id={TENDER_CREATE_FROM_PLANNER_MENU[field]} className={'tender-from-planner-form-table-fieldset-row-title'}>
      {TenderFormTableLabel[field]}
    </div>
  );
};
