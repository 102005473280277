import { defineModule } from '@core/index';
import {
  copyParamsConfigWatcher,
  planningControlConfigWatcher,
  planningCreateConfigWatcher,
  planningDeleteConfigWatcher,
  planningGetConfigWatcher,
  planningGetTemplateWatcher,
  planningImportConfigWatcher,
  planningUpdateConfigWatcher,
  updateExternalConfigWatcher
} from './sagas';
import PlanningParams from './containers';
import planningParams from './reducer';
import { planningParamsNotifierWatcher } from './sagas/params-notifier';
import { ModulePath, ModuleTitle } from '@common/types';
import { persistTransform } from './persist';
import { planningGetParamsWatcher } from './sagas/get-planning-params';
import { planningSetParamsWatcher } from './sagas/set-planning-params';

export default defineModule({
  title: ModuleTitle.Params,
  path: ModulePath.Params,
  component: PlanningParams,
  reducer: planningParams,
  sagas: [
    copyParamsConfigWatcher(),
    updateExternalConfigWatcher(),
    planningGetConfigWatcher(),
    planningControlConfigWatcher(),
    planningGetTemplateWatcher(),
    planningCreateConfigWatcher(),
    planningUpdateConfigWatcher(),
    planningDeleteConfigWatcher(),
    planningImportConfigWatcher(),
    planningParamsNotifierWatcher(),
    updateExternalConfigWatcher(),
    planningGetParamsWatcher(),
    planningSetParamsWatcher()
  ],
  persist: persistTransform
});

