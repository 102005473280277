import { PayloadAction } from '@reduxjs/toolkit';
import { deleteCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { Account } from '@common/types';

function* deleteCarriersWorker(
  action: PayloadAction<{
    ids: Array<Account['ID']>;
  }>
) {
  const { request, success, failure, fulfill } = deleteCarriersRoutine;
  const { ids } = action.payload;
  try {
    yield put(request());

    yield call(TenderOwn.deleteCarriers, { ids });

    yield put(success({ ids }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteCarriersWatcher() {
  yield takeEvery(deleteCarriersRoutine.trigger, deleteCarriersWorker);
}
