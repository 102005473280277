import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { demandChangeActiveTabRoutine } from '../actions';
import { initialState } from '../store';
import { IDemandStore } from '../types/store';
import { DemandTabType } from '../types/tabs';

// Переключает активный таб на странице (все проекты и тендерные лоты, либо только проекты, либо только лоты)
const changeActiveTabReducer = createReducer(initialState, {
  [demandChangeActiveTabRoutine.REQUEST]: (state: IDemandStore) => {
    return state;
  },
  [demandChangeActiveTabRoutine.SUCCESS]: (state: IDemandStore, action: PayloadAction<{ activeTab: DemandTabType }>) => {
    const { activeTab } = action.payload;
    state.activeTab = activeTab;
    return state;
  },
  [demandChangeActiveTabRoutine.FAILURE]: (state: IDemandStore) => {
    return state;
  },
  [demandChangeActiveTabRoutine.FULFILL]: (state: IDemandStore) => {
    return state;
  }
});

export default changeActiveTabReducer;
