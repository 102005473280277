import { timeToDouble } from '@common/utils';
import { MarkerFigure } from '@common/types/components/map/map';

export const getMarkerFigure = ({
  isDefinedWorktime,
  UnloadEnd,
  worktimeWindow,
  UnloadStart
}: {
  isDefinedWorktime: boolean;
  UnloadStart: number;
  UnloadEnd: number;
  worktimeWindow: number | false;
}): MarkerFigure => {
  if (!isDefinedWorktime) return MarkerFigure.Triangle;

  if (worktimeWindow && worktimeWindow >= timeToDouble('04:00')) return MarkerFigure.Square;

  return UnloadStart >= timeToDouble('21:00') && UnloadEnd <= timeToDouble('06:00') ? MarkerFigure.Circle : MarkerFigure.Triangle;
};
