import { monitoringDashboardAllInfoRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { initialState } from '../store';
import { DashboardTables } from '../types';
import { MonitoringDashboardState } from '../types/store';
import { updateMonitoringData } from '@modules/monitoring/utils/update-data';

// Записывает информацию нужную для всех таблиц сразу
const monitoringDashboardInfoAllReducer = createReducer<MonitoringDashboardState>(initialState as MonitoringDashboardState, {
  [monitoringDashboardAllInfoRoutine.REQUEST]: (state, action: PayloadAction<{ from: string; till: string }>) => {
    const { from, till } = action.payload;

    state.dashboardTableData.all.from = from;
    state.dashboardTableData.all.till = till;
    state.dashboardTableData.all.isFetching = true;

    return state;
  },
  [monitoringDashboardAllInfoRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ data: Array<any> | IndexedArray<any>; updates?: boolean }>
  ) => {
    const { data, updates } = action.payload;
    if (updates) {
      state.dashboardTableData.all.data = updateMonitoringData(state.dashboardTableData.all.data, data);
    } else {
      state.dashboardTableData.all.data = data;
    }
    state.dashboardTableData.all.isFetching = false;
    state.dashboardTableData.all.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringDashboardAllInfoRoutine.FAILURE]: (state) => {
    state.dashboardTableData.all.data = {};
    state.dashboardTableData.all.isFetching = false;
    state.dashboardTableData.all.lastUpdate = undefined;

    return state;
  },
  [monitoringDashboardAllInfoRoutine.FULFILL]: (state, action: PayloadAction<{ activeTab: DashboardTables | undefined }>) => {
    state.dashboardTableData.all.isFetching = false;
    return state;
  }
});

export default monitoringDashboardInfoAllReducer;
