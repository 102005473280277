import { EMPTY_OBJECT_DATA, EMPTY_STRING } from '@common/constants';
import { IndexedArray } from '@common/types';
import {
  EventVehicleTracker,
  MonitoringBrokenSequenceDataModel,
  MonitoringVehicleEventsModel,
  MonitoringVehicleMovementSpeedTitle,
  TrackerLastInfoModel
} from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { getVehicleStatus } from '@common/utils/general/get-vehicle-status';
import { transformTripToWaypointsInfo } from '@common/utils/general/transform-trips-to-waypoint-info';
import { getVehicleMovementStatus } from '@modules/monitoring/children/event-details/utils/get-vehicle-movement-status';
import { isEmpty, isNil } from 'lodash';
import { countTripsTime } from '@common/table-configs/monitoring-event-details/main-table/helpers/count-trips-time';
import { getVehicleRouteColor } from '@common/models/vehicle/decorators/get-vehicle-route-color';

type DataToTableFunction<R> = (data: IndexedArray<MonitoringVehicleEventsModel>) => Array<
  R & {
    key: string;
    Name: string;
    Number: string;
    DriverName: string;
  }
>;

export const data2table: DataToTableFunction<
  {
    Model: string;
    ID: number;
    Name: string;
    Number: string;
    DriverName: string;
    Phone: string;
    GroupName: string | undefined;
    Comment: string | undefined | null;
    key: string;
    TotalFactStopsTime: string;
    TotalFactDriveTime: string | undefined;
  } & Partial<TrackerLastInfoModel>
> = (vehicleData) => {
  const res: ReturnType<typeof data2table> = [];

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];
    let trackerLastInfo: Partial<TrackerLastInfoModel> = {};

    let vehicleTracker: EventVehicleTracker | undefined;
    if (!isEmpty(vehicle?.VehicleTracker)) {
      if (Object.values(vehicle?.VehicleTracker).length > 1) {
        for (const trackerIndex in vehicle.VehicleTracker) {
          const curVehicleTracker = vehicle.VehicleTracker[trackerIndex];
          //Если не было трекера
          if (isEmpty(curVehicleTracker)) continue;
          if (!vehicleTracker) {
            vehicleTracker = curVehicleTracker;
            //Если по трекеру нет данных
          } else if (!isEmpty(curVehicleTracker?.Tracker) && isEmpty(vehicleTracker?.Tracker)) {
            vehicleTracker = curVehicleTracker;
            //Если связанный трекер действует дольше
          } else if (vehicleTracker?.Till && (isNil(curVehicleTracker.Till) || vehicleTracker.Till < curVehicleTracker.Till)) {
            vehicleTracker = curVehicleTracker;
            //Если получены данные позже
          } else if (
            (vehicleTracker?.Till === curVehicleTracker?.Till &&
              curVehicleTracker?.Tracker?.TrackerLastInfo?.DT &&
              isNil(vehicleTracker?.Tracker?.TrackerLastInfo?.DT)) ||
            (!isNil(curVehicleTracker?.Tracker?.TrackerLastInfo?.DT) &&
              !isNil(vehicleTracker?.Tracker?.TrackerLastInfo?.DT) &&
              vehicleTracker?.Tracker?.TrackerLastInfo?.DT &&
              curVehicleTracker.Tracker.TrackerLastInfo!.DT > vehicleTracker.Tracker.TrackerLastInfo.DT)
          ) {
          }
        }
      } else {
        vehicleTracker = Object.values(vehicle.VehicleTracker)[0];
      }
    }

    if (vehicleTracker?.Tracker?.TrackerLastInfo && Object.keys(vehicleTracker.Tracker.TrackerLastInfo)?.length) {
      trackerLastInfo = vehicleTracker.Tracker.TrackerLastInfo;
    }

    const speedData = getVehicleMovementStatus(trackerLastInfo);
    const trips = Object.values(vehicle?.Trip || EMPTY_OBJECT_DATA);
    const hasTrips = !isEmpty(trips);

    const {
      TotalFactDriveTime,
      TotalFactStopsTime,
      TotalPlanDriveTime,
      TripEndFact,
      TripEndPlan,
      TripBeginFact,
      TripBeginPlan,
      TotalPlanTime,
      TotalPlanStopsTime,
      TotalFactTime
    } = countTripsTime(trips);

    const deliveredStatuses = [MonitoringWaypointEventStatusType.InTime, MonitoringWaypointEventStatusType.OnLate];
    const waypointsInfo = transformTripToWaypointsInfo(vehicle as unknown as MonitoringBrokenSequenceDataModel, deliveredStatuses);
    const complete = waypointsInfo.all === waypointsInfo.fact;
    const hasLates = trips?.some((item) => {
      const waypoints = Object.values(item.Waypoint);
      return waypoints.some(({ EventStatusType }) => EventStatusType === MonitoringWaypointEventStatusType.OnLate);
    });
    const { text: VehicleStatus, icon: VehicleStatus_icon } = getVehicleStatus(complete, hasLates, hasTrips);

    const [trip] = Object.values(vehicle.Trip || {});
    const factDistance = trip?.FactDistance ? trip.FactDistance.toFixed(2) : '-';

    const rowObj = {
      ...trackerLastInfo,
      Speed: trackerLastInfo?.Speed ? Number(trackerLastInfo.Speed).toFixed(1) : MonitoringVehicleMovementSpeedTitle.NoData,
      Model: vehicle.Model || '',
      SpeedIcon: speedData.icon,
      SpeedIcon_htmltip: speedData.status,
      alt: speedData.status,
      ID: vehicle.ID,
      Name: vehicle.Name,
      Number: vehicle.RegNumber || '',
      DriverName: vehicle?.Driver?.Name,
      Phone: vehicle?.Driver?.Phone || EMPTY_STRING,
      ZoneID: vehicleTracker?.Tracker?.Event_GeoDepot?.Depot?.ZoneID || vehicleTracker?.Tracker?.Event_GeoZone?.ZoneID || undefined,
      GroupName: vehicle.GroupVehicle
        ? Object.values(vehicle.GroupVehicle)?.find((group) => group?.VehicleID === vehicle.ID)?.Group?.Name
        : undefined,
      Comment: vehicle.Comment,
      key: vehicle.ID.toString(),
      RouteColor: getVehicleRouteColor(vehicle),
      PlanFactDepot: `${waypointsInfo.all}/${waypointsInfo.fact}`,
      Distance: `${waypointsInfo.running.toFixed(2) || 0}/${factDistance}`,
      TotalFactDriveTime,
      TotalFactStopsTime,
      TotalPlanDriveTime,
      TripEndFact,
      TripEndPlan,
      TripBeginFact,
      TripBeginPlan,
      TotalPlanTime,
      TotalPlanStopsTime,
      TotalFactTime,
      VehicleStatus,
      VehicleStatus_icon
    };
    res.push(rowObj);
  }
  return res;
};
