import { createSelector } from 'reselect';
import { DocumentType } from '@modules/documents/types/document-type';
import { FormField } from '@modules/documents/children/upload/types/form-field';

export const documentTypeSelector = createSelector(
  (state) => state.DocumentsUpload.documentType,
  (documentType?: DocumentType) => documentType
);

export const formModelSelector = createSelector(
  (state) => state.DocumentsUpload.formModel,
  (formModel?: FormField[]) => formModel
);

export const fileNameSelector = createSelector(
  (state) => state.DocumentsUpload.fileName,
  (fileName?: string) => fileName
);

export const isFetchingSelector = createSelector(
  (state) => state.DocumentsUpload.isFetching,
  (isFetching?: boolean) => isFetching
);
