import { apiInstance } from '@core/api/instance';
import { IDocumentsApi } from '@modules/documents/types/documents-api';

export const Documents: IDocumentsApi = {
  indexTemplates: ({ projectId, type }) => apiInstance().post('/newTms/DocumentTemplate/index', { projectId, type }),
  indexDocuments: (payload) => apiInstance().post('/newTms/Document/index', payload),
  getDependentList: ({ type, projectId, setId }) =>
    apiInstance().post(
      '/newTms/Document/getDependentList',
      projectId
        ? {
            type,
            projectId
          }
        : setId
        ? { setId }
        : { type }
    ),
  create: (data) => apiInstance().post('newTms/Document/create', data),
  createDeal: (data) => apiInstance().post('newTms/Document/createDeal', data),
  update: (data) => apiInstance().post('newTms/Document/update', data),
  getDocumentData: (data) => apiInstance().post('/newTms/Document/getDocumentData', data),
  deleteFromTrash: (data) => apiInstance().post('/newTms/Document/deleteFromTrash', data),
  saveAsTemplate: ({ id }) => apiInstance().post('/newTms/Document/saveAsTemplate', { id }),
  getUserOptions: () => apiInstance().post('/newTms/Document/getUserOptions', {}),
  setUserOptions: (data) => apiInstance().post('/newTms/Document/setUserOptions', data),
  suggestContractor: (data) => apiInstance().post('/newTms/Document/suggestions', data),
  sendContractor: (data) => apiInstance().post('/newTms/Document/send', data),
  getDocumentMetrics: (data) => apiInstance().post('/newTms/Document/getDocumentMetrics', data),
  getReceivedDocuments: () => apiInstance().post('/newTms/Document/getReceivedDocuments'),
  getSentDocuments: () => apiInstance().post('/newTms/Document/getSendedDocuments'),
  setDocumentStatus: (data) => apiInstance().post('/newTms/Document/setDocumentStatus', data),
  getReportsList: ({ module }) => apiInstance().post('/newTms/DocumentTemplate/getReportsList', { module }),
  saveReport: (params) => apiInstance().post('/newTms/DocumentTemplate/saveReport', params),
  deleteReport: (params) => apiInstance().post('/newTms/DocumentTemplate/deleteReport', params)
};
