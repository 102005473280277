import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Form, FormInstance } from 'antd';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { ILSButton, ILSForm, ILSFormItem, ILSRow, ILSSelect, ILSSkeleton } from '@components/index';
import { documentTypeOptions } from '@modules/documents/children/upload/constants/document-type-options';
import { FieldFilteringType, FormField } from '@modules/documents/children/upload/types/form-field';
import { prepareDocumentsPayload } from '@modules/documents/helpers/prepare-documents-payload';

import FilterInput from '@modules/documents/components/filter-input';
import { getDocumentType, getFilterFields, getMetricsIsFetchingSelector } from '@modules/documents/selectors';
import { documentsIndexRoutine } from '@modules/documents/actions';
import { DocumentType } from '@modules/documents/types/document-type';
import { isObject } from 'lodash';
import { DocumentsFilterFields } from '@modules/documents/types/document';

const ILSDocumentsFilter: FC = () => {
  const [form] = Form.useForm();
  const isFetching = useAppSelector(getMetricsIsFetchingSelector);
  const filterFields = useAppSelector(getFilterFields);
  const type = useAppSelector(getDocumentType);
  const dispatch = useAppDispatch();
  const [isChanged, setIsChanged] = useState(false);

  const onFilterChange = (filters: DocumentsFilterFields) => {
    if (!isChanged) return;
    dispatch(documentsIndexRoutine(prepareDocumentsPayload({ ...filters })));
    setIsChanged(true);
  };

  useEffect(() => {
    form.resetFields();
    setIsChanged(false);
  }, [type]);

  const onReset = () => {
    if (isChanged) {
      form.resetFields();
      dispatch(documentsIndexRoutine({ documentType: type }));
      setIsChanged(false);
    }
  };

  const onValuesChange = () => {
    setIsChanged(true);
    return form.validateFields();
  };

  const renderField = (field: FormField) => {
    if (field.filtering === FieldFilteringType.Array) {
      return (
        <ILSRow className="flex-between-center flex-wrap-nowrap form-row">
          <ILSFormItem
            name={`${field.alias}[from]`}
            noStyle
            rules={[
              (formInstance: FormInstance) => ({
                message: 'Минимальное значение должно быть меньше или равно максимальному',
                validator(_: any, value: any) {
                  const upperValue = formInstance.getFieldValue(`${field.alias}[till]`);
                  if (value == null || upperValue == null) {
                    return Promise.resolve();
                  }
                  const hasError = isObject(value) ? moment(value).isAfter(upperValue, 'day') : value > upperValue;
                  if (hasError) {
                    return Promise.reject(new Error());
                  }
                  return Promise.resolve();
                }
              })
            ]}
            dependencies={[`${field.alias}[till]`]}
          >
            <FilterInput field={field} />
          </ILSFormItem>
          <ILSFormItem name={`${field.alias}[till]`} noStyle>
            <FilterInput field={field} />
          </ILSFormItem>
        </ILSRow>
      );
    }

    return (
      <ILSFormItem name={field.alias} noStyle>
        <FilterInput field={field} />
      </ILSFormItem>
    );
  };

  return (
    <ILSForm layout="vertical" form={form} onFinish={onFilterChange} onValuesChange={onValuesChange}>
      <ILSRow className="flex-between-center">
        <h2>Фильтры</h2>
        <ILSButton className="filter-cancel" type="text" htmlType="button" onClick={onReset}>
          Очистить
        </ILSButton>
      </ILSRow>
      <ILSSkeleton loading={isFetching} active>
        {type !== DocumentType.Deal && (
          <ILSFormItem label="Тип документа">
            <ILSSelect options={documentTypeOptions} value={type} disabled />
          </ILSFormItem>
        )}
        {filterFields?.map(({ multiselect, ...field }) =>
          field.filtering !== FieldFilteringType.None ? (
            <ILSFormItem label={field.name} key={field.alias}>
              {renderField(field)}
            </ILSFormItem>
          ) : null
        )}
        <ILSButton disabled={!isChanged} type="primary" htmlType="submit">
          Применить
        </ILSButton>
      </ILSSkeleton>
    </ILSForm>
  );
};

export default ILSDocumentsFilter;
