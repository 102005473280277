import { FC } from 'react';
import cn from 'classnames';
import { PlannerPlanningMapLayerMenu } from '@modules/planning/children/by-date/components/result/map/btn-menu/layers-buttons';
import { PlannerPlanningRestrictionMenu } from '@modules/planning/children/by-date/containers/result/context-menu/restriction';
import { ILSButtonTooltip } from '@common/components';
import { IndexedArray, Keys, Layer, RecordTableButton } from '@common/types';
import { OnLayerChange } from '@modules/planning/children/by-date/types/map-container';
import { ReactDispatchBoolean } from '@common/types/vendor/react';
import { ILSMap } from '@core/containers/map';
import { VoidFunction } from '@common/types/general/function';

interface IProps {
  recordMapButtons: RecordTableButton[];
  mapLayersData: IndexedArray<Layer>;
  activeLayers: Keys;
  map: ILSMap | null;
  lassoIsActive: boolean;
  showConvexPolygon: boolean;
  rulerIsActive: boolean;
  onChangeLayer: OnLayerChange;
  resetSelectedData: VoidFunction;
  setShowConvexPolygon: ReactDispatchBoolean;
  setLassoIsActive: ReactDispatchBoolean;
  setRulerIsActive: ReactDispatchBoolean;
}

export const MapButtonsToolbar: FC<IProps> = ({
  recordMapButtons,
  mapLayersData,
  activeLayers,
  lassoIsActive,
  rulerIsActive,
  onChangeLayer,
  showConvexPolygon,
  setShowConvexPolygon,
  map,
  setLassoIsActive,
  setRulerIsActive,
  resetSelectedData
}) => {
  //TODO add unit
  const triggerLasso = () => {
    if (map) {
      map.toggleLasso();
      setLassoIsActive(!lassoIsActive);
    }
    resetSelectedData();
  };
  const triggerRuler = () => {
    if (map) {
      map.toggleRuler();
      setRulerIsActive(map.rulerIsActive());
    }
  };

  return (
    <div className={'map-buttons'}>
      {
        //TODO add unit
        recordMapButtons?.map(({ Component, props }, index) => Component && <Component key={`button-${index}`} {...props} />)
      }
      <PlannerPlanningMapLayerMenu layers={mapLayersData} activeLayers={activeLayers} onChangeLayer={onChangeLayer} />
      <PlannerPlanningRestrictionMenu />
      <ILSButtonTooltip
        className={cn({ active: showConvexPolygon })}
        icon={'/planning/convex-polygon'}
        onClick={() => setShowConvexPolygon(!showConvexPolygon)}
        title={'Вкл / выкл границы рейсов'}
        placement={'right'}
      />
      <ILSButtonTooltip
        className={cn({ active: lassoIsActive })}
        icon={'/map/lasso'}
        onClick={triggerLasso}
        title={'Включить выделение с помощью лассо'}
        placement={'right'}
      />
      <ILSButtonTooltip
        className={cn({ active: rulerIsActive })}
        icon={'/map/ruler'}
        onClick={triggerRuler}
        title={'Включить линейку'}
        placement={'right'}
      />
    </div>
  );
};
