import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsStore } from '../types/store';
import initialState from '../store';
import { AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const selectBoardSetCommonReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonSelectBoardSetRoutine.REQUEST]: (state) => {
      return state;
    },
    [actions.analyticsCommonSelectBoardSetRoutine.SUCCESS]: (state, action: PayloadAction<AnalyticsBoardSetModel>) => {
      state.BoardSets.selectedBoardSet = action.payload?.ID;
      if (!action.payload?.ID) {
        state.Boards.data = {};
      }
      return state;
    },
    [actions.analyticsCommonSelectBoardSetRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsCommonSelectBoardSetRoutine.FULFILL]: (state) => {
      return state;
    }
  });
