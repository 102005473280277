import { ILSSpin } from '@common/components';
import { TripTimeline } from '@modules/orders-and-trips/components/trip/trip-timeline';
import { useTripTimelineContainer } from '@modules/orders-and-trips/hooks/use-trip-timeline-container';

export const TripTimelineContainer = () => {
  const { tripTimeline, isFetching } = useTripTimelineContainer();
  return (
    <ILSSpin tip='Выполняется загрузка данных...' spinning={isFetching}>
      <TripTimeline timelineEvents={tripTimeline} />
    </ILSSpin>
  );
};

