import { FC } from 'react';
import { ILSBadge, ILSTypographyText } from '@common/components';
import { BadgeProps } from 'antd/lib/badge';

interface IProps extends BadgeProps {}

export const ILSTenderTabHeader: FC<IProps> = ({ children, ...props }) => {
  return (
    <ILSBadge offset={[25, 11]} showZero className={'tender-platform-list-content-tab-header'} {...props}>
      <ILSTypographyText className={'tender-platform-list-content-tab-header-content'}>{children}</ILSTypographyText>
    </ILSBadge>
  );
};
