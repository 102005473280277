import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { IAnalyticsCommonAPI } from '../types/api';
import { AnalyticsActions } from '../types/actions';

export const analyticsCancelWidgetUpdatesRoutineFactory = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker() {
    const { success, failure, fulfill } = actions.analyticsCommonCancelWidgetUpdatesRoutine;
    try {
      yield put(success());
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonCancelWidgetUpdatesRoutine.trigger, worker);
  }

  return watcher;
};
