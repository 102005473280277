import { CellType, TableColumnName, TableConfig } from '@common/types';
import { NEW_MODEL } from '@components/data-display/table/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';

export const RAMP_TYPE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  unchangeable: true,
  adding: NEW_MODEL,
  columns: [
    ID_COLUMN,
    {
      title: 'Тип рампы',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Input,
      required: true
    },
    {
      title: 'Норма загрузки (масса)',
      dataIndex: 'LoadingNormMass',
      key: 'LoadingNormMass',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Float
    },
    {
      title: 'Норма загрузки (объем)',
      dataIndex: 'LoadingNormVolume',
      key: 'LoadingNormVolume',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Float
    },
    {
      title: 'Норма загрузки (паллет)',
      dataIndex: 'LoadingNormPallets',
      key: 'LoadingNormPallets',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Норма загрузки (коробов)',
      dataIndex: 'LoadingNormBoxes',
      key: 'LoadingNormBoxes',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Норма разгрузки (масса)',
      dataIndex: 'UnloadingNormMass',
      key: 'UnloadingNormMass',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Float
    },
    {
      title: 'Норма разгрузки (объем)',
      dataIndex: 'UnloadingNormVolume',
      key: 'UnloadingNormVolume',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Float
    },
    {
      title: 'Норма разгрузки (паллет)',
      dataIndex: 'UnloadingNormPallets',
      key: 'UnloadingNormPallets',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Int
    },
    {
      title: 'Норма разгрузки (коробов)',
      dataIndex: 'UnloadingNormBoxes',
      key: 'UnloadingNormBoxes',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    },
    {
      title: 'Начало работы',
      dataIndex: 'StartTime',
      key: 'StartTime',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble
    },
    {
      title: 'Окончание работы',
      dataIndex: 'EndTime',
      key: 'EndTime',
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble
    },
    {
      title: 'Ограничения',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: true,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi
    }
  ]
};
