export enum EmulatorAction {
  GetPlan = 'EMULATOR/GET_PLAN',
  Start = 'EMULATOR/START',
  Stop = 'EMULATOR/STOP',
  Pause = 'EMULATOR/PAUSE',
  ChangeEmulationSpeed = 'EMULATOR/CHANGE_EMULATION_SPEED',
  CheckStatus = 'EMULATOR/CHECK_STATUS',
  CreateConfig = 'EMULATOR/CREATE_CONFIG',
  GetConfig = 'EMULATOR/GET_CONFIG',
  UpdateConfig = 'EMULATOR/UPDATE_CONFIG',
  SaveVehicleProps = 'EMULATOR/SAVE_VEHICLE_PROPS'
}
