import { VehicleTableFilterFields } from '../../types/vehicle-table';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators';
import { TableColumnName } from '@common/types';

const getTableKey = (filterName: string): string => {
  switch (filterName) {
    case 'volume':
      return TableColumnName.VehicleProfileMaxVolume;
    case 'pallets':
      return TableColumnName.VehicleProfileMaxPallets;
    case 'mass':
      return TableColumnName.VehicleProfileMaxMass;
    default:
      return filterName;
  }
};

export function filterVehicleForOrdersDataSource(
  vehicleRecords: ReturnType<typeof plannerPlanningVehiclesToTable>,
  filters: VehicleTableFilterFields
) {
  if (Object.values(filters).every((key) => !Boolean(key))) return vehicleRecords;

  const arrOfFilters = Object.entries({
    mass: filters.mass,
    volume: filters.volume,
    pallets: filters.pallets
  }).filter(([, value]) => value);

  return vehicleRecords?.filter((item) => {
    const matchingVehicles = arrOfFilters.every(([key, value]) => {
      return item[getTableKey(key)] >= Number(value);
    });
    const totalMatch = filters.TransportCompanyID
      ? item.TransportCompanyID === filters.TransportCompanyID && matchingVehicles
      : matchingVehicles;
    return Boolean(filters.ProjectVehicle) ? totalMatch && item.TakeForPlanner : totalMatch;
  });
}
