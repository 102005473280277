import { FC } from 'react';
import { Route, Switch } from 'react-router';
import { FilterRoutes } from '@modules/documents/constants';
import { ILSCol, ILSDivider } from '@common/components';
import { useAppSelector } from '@core/hooks';
import { getDocumentState } from '@modules/documents/selectors';
import { DocumentState } from '@modules/documents/types/document-state';
import ILSDocumentsClearTrashMode from '../clearTrashMode';

const ILSDocumentsSidePanel: FC = () => {
  const location = useAppSelector(getDocumentState);
  return (
    <Switch>
      {FilterRoutes.map((SingleRoute, index) => {
        return (
          <Route exact key={SingleRoute.Path + index} path={SingleRoute.Path}>
            <ILSCol span={4}>
              <ILSDivider />
              <div className={'documents-side-panel'}>
                <SingleRoute.Filter />
                {location === DocumentState.Deleted && (
                  <>
                    <ILSDivider />
                    <ILSDocumentsClearTrashMode />
                  </>
                )}
              </div>
            </ILSCol>
          </Route>
        );
      })}
    </Switch>
  );
};

export default ILSDocumentsSidePanel;

