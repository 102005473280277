// Декоратор направления
export const directionDecorator = (regionalCity: string | null, address: string, stringLength: number) => {
  return {
    name: regionalCity
      ? regionalCity.length > stringLength
        ? `${regionalCity.slice(0, stringLength)}...`
        : regionalCity
      : address.length > stringLength
      ? `${address.slice(0, stringLength)}...`
      : address,
    title: regionalCity ? (regionalCity.length > stringLength ? regionalCity : '') : address.length > stringLength ? address : ''
  };
};
