import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/planning/children/by-date/store';
import { mapWaitingModeRoutine } from '@modules/planning/children/by-date/actions';

export const mapWaitingModeReducer = createReducer(initialState, {
  [mapWaitingModeRoutine.TRIGGER]: (state) => {
    state.isMapWaitingMode = true;
    return state;
  },
  [mapWaitingModeRoutine.FULFILL]: (state) => {
    state.isMapWaitingMode = false;
    return state;
  }
});
