import { createSelector } from 'reselect';
import { SettingsStore } from '@modules/settings/types/store';

export const monitoringSettingsSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings.values.monitoring,
  (list) => list
);

export const monitoringSettingsParametersSelector = createSelector(
  monitoringSettingsSelector,
  (monitoringSettings) => monitoringSettings.parameters
);

export const monitoringSettingsParameterShowAllVehiclesSelector = createSelector(monitoringSettingsParametersSelector, (parameters) =>
  Number(parameters.showAllVehicles || 0)
);

