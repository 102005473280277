import { createSelector } from 'reselect';
import { Data, PlanningDataStore } from '@modules/planning/children/data/types';

export const uploadedDataSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.data,
  (data: Data | []) => data
);

export const uploadedDataCountSelector = createSelector(uploadedDataSelector, (data: Data | []) => {
  return Object.entries(data).reduce((prev, [dictionary, dataSource]) => {
    prev[dictionary] = dataSource?.length ?? 0;
    return prev;
  }, {});
});

export const fetchSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.isFetching,
  (isFetching) => isFetching
);

export const validateDataSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.validateData,
  (validateData) => validateData
);

export const filesUploadSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.files,
  (files) => files
);

export const uploadConflictSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.conflict,
  (conflict) => conflict
);

export const configsSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.configs,
  (configs) => configs
);

export const dataProjectIDSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.projectID,
  (projectID) => projectID
);
