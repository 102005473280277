import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { ILSButtonTooltip } from '@components/general/buttons/components/tooltip-button';

interface IProps {
  show: boolean;
  setShow: (a: boolean) => void;
  icon: string | ReactNode;
  tooltipText?: string | null;
  table?: boolean | null;
  active?: (string | number)[] | [] | null;
  content?: string | null;
}

export const PlannerPlanningMenuButton: FC<IProps> = ({ show, setShow, content, icon, tooltipText, table, active }) => {
  return (
    <ILSButtonTooltip
      className={classnames('map-buttons-with-number', {
        active: show || active?.length
      })}
      icon={typeof icon === 'string' ? `/planning/${icon}` : icon}
      onClick={() => setShow(!show)}
      title={tooltipText}
      placement={table ? 'bottom' : 'right'}
    >
      {content}
    </ILSButtonTooltip>
  );
};
