import { QuestionsHeader } from '@common/feature/questions/components/questions-header';
import { QuestionsVideo } from '@common/feature/questions/components/questions-video';
import { useShowQuestions } from '@common/feature/questions/hooks/use-show-questions';
import { QUESTIONS_SECTIONS_TITLE } from '@common/feature/questions/constants/index';

export const QuestionsVideoContainer = () => {
  const { handleCloseQuestionsContainer } = useShowQuestions();

  return (
    <section className="questions-section">
      <QuestionsHeader title={QUESTIONS_SECTIONS_TITLE.VIDEO} showCloseButton onCloseIconClick={handleCloseQuestionsContainer} />
      <div className="questions-section-content">
        <QuestionsVideo />
      </div>
    </section>
  );
};

