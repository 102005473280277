import './styles.less';
import { FC } from 'react';
import { DownOutlined } from '@ant-design/icons/lib/icons';
import { ILSTooltip, ILSTypographyText } from '@common/components';
import cn from 'classnames';

interface IProps {
  showArrow?: boolean;
  className?: string;
  title?: string;
  onClick?(): void;
}

export const ILSSelectStatic: FC<IProps> = ({ className, onClick, children, showArrow = true, title }) => {
  return (
    <ILSTooltip title={Boolean(children) ? title ?? children : 'Не выбрано'} placement="right">
      <span className={cn(className, 'icon-with-typography')} onClick={onClick}>
        {showArrow && <DownOutlined className={'select-arrow'} />}
        <ILSTypographyText ellipsis children={children} />
      </span>
    </ILSTooltip>
  );
};
