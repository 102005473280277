import { FC } from 'react';
import {
  CARGO_INPUT_STEP,
  DEFAULT_INPUT_STEP,
  FORM_CARGO_SIZE_SHORT_FIELDS,
  MIN_NUMBER_INPUT_VALUE,
  ORDER_AND_TRIP_FORM_RULES
} from '@modules/orders-and-trips/constants/form';
import { ILSButton, ILSFormItem, ILSInputNumber } from '@common/components';
import { FormFieldNames } from '@modules/orders-and-trips/types/form';
import { Field } from '@components/common/forms/constants';
import { ILSTypographyParagraph } from '@components/general/typography';
import { CargoAmountAction, useCargosButtons } from '@modules/orders-and-trips/hooks/use-cargos-buttons';

export const CargoFormFields: FC = () => {
  const { handleCargosAmount, cargoFields } = useCargosButtons();

  return (
    <>
      <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
        <ILSTypographyParagraph>{`Итоговый объем рассчитан как единица груза умноженная на длину/ширину/высоту`}</ILSTypographyParagraph>
        <ILSButton children={'Добавить груз'} onClick={handleCargosAmount(CargoAmountAction.add)} />
      </fieldset>
      {cargoFields
        .map((_, cargoIndex) => {
          const cargoNumber = cargoIndex + 1;
          return (
            <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'} key={Field.Cargo + cargoIndex}>
              <legend className={'fake-pre-cost__form-fieldset-legend'}>
                {`Груз №${cargoNumber}`}
                {Boolean(cargoIndex) && <ILSButton icon={'delete'} onClick={handleCargosAmount(CargoAmountAction.remove)} />}
              </legend>
              {FORM_CARGO_SIZE_SHORT_FIELDS.map((field) => {
                const name = [Field.Cargo, cargoIndex, field.name];

                return (
                  <ILSFormItem
                    key={name.join('')}
                    name={name}
                    label={field.label}
                    rules={ORDER_AND_TRIP_FORM_RULES[field.name]}
                    className={'fake-pre-cost__form-item'}
                  >
                    <ILSInputNumber
                      step={FormFieldNames.Amount === field.name ? DEFAULT_INPUT_STEP : CARGO_INPUT_STEP}
                      placeholder={field.placeholder}
                      min={MIN_NUMBER_INPUT_VALUE}
                    />
                  </ILSFormItem>
                );
              })}
            </fieldset>
          );
        })
        .reverse()}
    </>
  );
};
