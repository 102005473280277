import { createRoutine } from 'redux-saga-routines';

export const getProjectsRoutine = createRoutine('PLANNING/GET_PROJECTS');
export const setActiveProjectIdRoutine = createRoutine('PLANNING/SET_ACTIVE_PROJECT_ID');
export const planningCreateProjectRoutine = createRoutine('PLANNING/CREATE_PROJECT');
export const planningUpdateProjectRoutine = createRoutine('PLANNING/UPDATE_PROJECT');
export const createPlanRoutine = createRoutine('PLANNING/CREATE');

export const plannerSettingsRoutine = createRoutine('PLANNER/SETTINGS');
export const planningSetParamsRoutine = createRoutine('PLANNING/PARAMS_SET');
export const planningGetParamsRoutine = createRoutine('PLANNING/PARAMS_GET');

export const plannerRoutinesWithNotifier = [
  getProjectsRoutine,
  setActiveProjectIdRoutine,
  planningCreateProjectRoutine,
  createPlanRoutine,
  planningSetParamsRoutine,
  planningGetParamsRoutine
];

