import { PayloadAction } from '@reduxjs/toolkit';
import { sendProjectLogisticsFormRoutine } from '@modules/orders-and-trips/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { ProjectLogisticsRequest } from '@modules/orders-and-trips/types/project-logistics';

function* sendProjectLogisticsFormWorker(action: PayloadAction<ProjectLogisticsRequest>) {
  const { request, success, failure, fulfill } = sendProjectLogisticsFormRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.sendProjectLogisticsForm, action.payload);
    yield put(success({ message: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* sendProjectLogisticsFormWatcher() {
  yield takeLatest(sendProjectLogisticsFormRoutine.trigger, sendProjectLogisticsFormWorker);
}
