import { IILSSelectProps } from '@components/data-entry/select/types';
import { isEmpty } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const filterOption = (fieldNames: IILSSelectProps['fieldNames']) => (inputValue: string, option?: any) => {
  if (isEmpty(fieldNames) || !fieldNames.label || !fieldNames.value) {
    showUserAndDevError({
      error: 'fieldNames is empty - для корректной работы необходимо передать параметр fieldNames',
      userError: 'Поиск по вариантам недоступен'
    });
    return true;
  }
  return (
    option?.[fieldNames.label]?.toLowerCase().includes(inputValue.toLowerCase()) ||
    option?.[fieldNames.value]?.toString().toLowerCase().includes(inputValue.toLowerCase())
  );
};
