import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { ActiveType, ConvexPolygon } from '@common/types';
import '@core/components/map/controls/ruler/leafler-ruler';
import {
  carMarker,
  colorMarker,
  customMarker,
  defaultMarker,
  destinationMarker,
  orderMarker,
  pointMarker
} from '@core/components/map/icons';
import homeMarker from '@core/components/map/icons/storage.svg';
import loadMarker from '@core/components/map/icons/take.svg';
import clientMarker from '@core/components/map/icons/user.svg';
import { DRAW_LOCAL, MAP_INIT_ERROR_MESSAGE } from '@core/constants';
import { drawPlugin, DrawPluginOptions, LControlDrawExt } from '@core/containers/map/draw-plugin';
import { LayerHandler, Marker, Polygon, Polyline } from '@core/types/map';
import L, { FitBoundsOptions, LatLngBoundsExpression, LatLngExpression, LatLngTuple, MarkerCluster } from 'leaflet';
import 'leaflet-draw';
import 'leaflet-lasso';
import { LassoControl } from 'leaflet-lasso';
import 'leaflet.markercluster';
import { extend, isEmpty, isString } from 'lodash';
import { depotTip, orderTip, pointTip } from '../components/map/icon-tips';
import '../types/leaflet-extend.ts';
import { MAP_RESIZE_DELAY } from './map/map-config';
import { googleHybridTileLayer, googleTerrainTileLayer, osmTileLayer } from '@core/components/map/constants/tiles';

import '@core/components/map/styles.less';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

extend(L.drawLocal, DRAW_LOCAL);
export const DEFAULT_MAP_CENTER: LatLngTuple = [55.753, 37.63];

const attribution = `
	<div class="tile-layers-list">Переключить:
	<span class="tile-layer-item">OpenStreetMap</span> |
    	<span class="tile-layer-item">Google-map</span> |
    	<span class="tile-layer-item">Google-спутник</span>
	</div>
`;

export enum TileLayers {
  osmTileLayer = 'osmTileLayer',
  googleTerrainTileLayer = 'googleTerrainTileLayer',
  googleHybridTileLayer = 'googleHybridTileLayer'
}

export class ILSMap {
  private readonly map: L.Map | undefined;
  private markers: Marker[] = [];
  private selectedMarkers: any[] = [];
  private markerLayer: L.LayerGroup = L.layerGroup();
  private polygonLayer: L.LayerGroup = L.layerGroup();
  private polylineLayer: L.LayerGroup = L.layerGroup();
  private newShapePolygonLayer: L.FeatureGroup = L.featureGroup();
  private drawControl: LControlDrawExt | undefined;
  private setPolylineDataIDs?: ((_: Record<string, number> | null) => void) | null = null;
  private convexLayer: L.LayerGroup = L.layerGroup();
  private clusters: L.MarkerClusterGroup[] = [];
  private readonly clusterOptions: Record<string, any> = {};
  private onItemClick: ((record: any, expand?: boolean, expandChild?: boolean, ctrl?: boolean) => void) | null = null;
  private onOrderClick: ((record: any, expand?: boolean, ctrl?: boolean) => void) | null = null;
  private setDraggableMarkerIDs?: ((_: any) => void) | null = null;
  /**
   * Наблюдатель за изменением размера контейнера карты
   */
  private readonly _resizeObserver: ResizeObserver;
  private _resizeTimer: NodeJS.Timeout | undefined;
  private readonly _runResize: (callback?: () => void) => void;
  private mapHandlers: Record<string, (...args: any) => void> = {};
  private lassoControl: LassoControl | undefined;
  private rulerControl: any | undefined;

  private tiles = {
    osmTileLayer,
    googleTerrainTileLayer,
    googleHybridTileLayer
  };
  private activeTileLayer = this.tiles.googleHybridTileLayer;

  public constructor() {
    this.map = new L.Map('ils-map', {
      // @ts-ignore
      //drawControl: true,
      boxZoom: false,
      zoomSnap: 0.5,
      zoomDelta: 0.5,
      wheelPxPerZoomLevel: 120,
      center: DEFAULT_MAP_CENTER,
      zoom: 10,
      maxZoom: 18
    });

    this.clusterOptions = {
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: true,
      spiderLegPolylineOptions: {
        weight: 0,
        opacity: 0
      }
    };

    this.map.doubleClickZoom.disable();

    this.map.addLayer(this.activeTileLayer);
    this.map.attributionControl.addAttribution(attribution);
    this.map.attributionControl.setPrefix('');

    const tileLayers = Array.from(document.querySelectorAll('.tile-layer-item'));
    tileLayers[0].addEventListener('click', () => {
      this.layerChange(TileLayers.osmTileLayer);
    });
    tileLayers[1].addEventListener('click', () => {
      this.layerChange(TileLayers.googleHybridTileLayer);
    });
    tileLayers[2].addEventListener('click', () => {
      this.layerChange(TileLayers.googleTerrainTileLayer);
    });

    //Стартовый resize карты после инициализации
    this._resizeTimer = setTimeout(() => {
      if (this.map) {
        this.map.invalidateSize();
        this._resizeTimer = undefined;
      }
    }, MAP_RESIZE_DELAY);

    //Запускает процесс resize карты, если в течении T мс не было повторных вызовов,
    //каждый последующий вызов, также откладывает на T мс
    //Если передать callback то он выполнится после invalidateSize, если его не отложат
    this._runResize = (callback) => {
      if (this._resizeTimer) {
        clearTimeout(this._resizeTimer);
      }
      this._resizeTimer = setTimeout(() => {
        if (this.map) {
          this.map.invalidateSize(false);
          callback?.();
          this._resizeTimer = undefined;
        }
      }, MAP_RESIZE_DELAY);
    };

    this._resizeObserver = new ResizeObserver((_entry) => {
      this._runResize();
    });
    this._resizeObserver.observe(this.map.getContainer());
  }

  public get Map() {
    return this.map;
  }

  public setCenter = (marker?: LatLngTuple | undefined) => {
    if (this.map) {
      if (marker) {
        const [latitude, longitude] = marker;
        this.map.panTo(new L.LatLng(latitude, longitude));
      } else {
        const center = this.map?.getCenter();
        const [latitude, longitude] = [center?.lat, center?.lng];
        this.map.panTo(new L.LatLng(latitude, longitude));
      }
    }
  };
  //Включение в карту плагина для рисования и редактирования слоев
  public enableDrawing = (options: DrawPluginOptions) => {
    if (this.map) {
      if (this.drawControl === undefined) {
        this.drawControl = drawPlugin(this.map, this.newShapePolygonLayer, options);
      } else {
        this.disableDrawing();
        this.drawControl = drawPlugin(this.map, this.newShapePolygonLayer, options);
      }
      return this.drawControl;
    }
  };

  public disableDrawing = () => {
    if (this.drawControl) {
      this.drawControl.clearListener();
      this.drawControl.remove();
      this.drawControl = undefined;
    }
    if (this.newShapePolygonLayer) {
      this.newShapePolygonLayer.clearLayers();
    }
  };

  public toggleLasso() {
    if (this.map && this.lassoControl) {
      this.lassoControl.toggle();
    }
  }

  public toggleRuler() {
    if (this.map && this.rulerControl) {
      this.rulerControl._container.click();
    }
  }

  public rulerIsActive(): boolean {
    return !!this.map && this.rulerControl?._choice;
  }

  /**
   * Отключает последний обработчик на событие mousemove
   * Если active == true то на карту добавится новый обработчик события
   * @param onMouseMove
   * @param active
   */
  public mapMouseMove(onMouseMove: (e: any) => void, active: boolean) {
    if (this.map) {
      if (this.mapHandlers.mousemove) {
        this.map.off('mousemove', this.mapHandlers.mousemove);
      }
      if (active) {
        this.mapHandlers.mousemove = onMouseMove;
        this.map.on('mousemove', this.mapHandlers.mousemove);
      }
    }
  }

  public clickOnMap(setCoords: (coords: { lat: number; lon: number }) => void) {
    if (this.map) {
      if (this.mapHandlers.click) {
        this.map.on('click', this.mapHandlers.click);
      } else {
        this.mapHandlers.click = (e: any) => {
          if (setCoords) {
            setCoords({
              lat: e.latlng.lat,
              lon: e.latlng.lng
            });
          }
        };
        this.map.on('click', this.mapHandlers.click);
      }
    }
  }

  public moveEndMap(setMapChange: (e: any) => void) {
    if (this.map) {
      if (this.mapHandlers.moveend) {
        this.map.on('moveend', this.mapHandlers.moveend);
      } else {
        this.mapHandlers.moveend = (e: any) => {
          if (setMapChange) {
            setMapChange(e);
          }
        };
        this.map.on('moveend', this.mapHandlers.moveend);
      }
    }
  }

  public invalidateSize() {
    if (this.map) {
      this._runResize();
    }
  }

  public fitBounds(bounds: LatLngBoundsExpression, options?: FitBoundsOptions) {
    if (this.map) {
      if (this._resizeTimer) {
        // если в очереди находится resize откладываем fitBounds
        this._runResize(() => this.map?.fitBounds(bounds, options));
      } else {
        this.map.fitBounds(bounds, options);
      }
    }
  }

  public getBounds() {
    if (this.map) {
      return this.map.getBounds();
    }
  }

  public getZoom() {
    if (this.map) {
      return this.map.getZoom();
    }
  }

  public addRuler() {
    if (this.map) {
      // @ts-ignore
      this.rulerControl = L.control.ruler();
      this.rulerControl.addTo(this.map);
    }
  }

  public addLasso(setLassoLayers: (_: any[]) => void) {
    if (this.map) {
      this.lassoControl = L.control.lasso().addTo(this.map);
      this.map.on('lasso.finished', (event: any) => {
        setLassoLayers(event.layers);
      });
    }
  }

  public addMarkers(markers: Marker[]) {
    this.markers = markers;
  }

  /**
   * @deprecated
   */
  public drawMarkers(
    markers: Marker[],
    polylineMarkers?: boolean,
    onItemClick?: (record: any, expand?: boolean, expandChild?: boolean, ctrl?: boolean) => void,
    onOrderClick?: (record: any, expand?: boolean, ctrl?: boolean) => void,
    selectedMarkers?: any[],
    setDraggableMarkerIDs?: (_: any) => void
    //focusOrders?: any[],
  ) {
    if (this.map) {
      this.markerLayer.clearLayers();
      this.markerLayer.addTo(this.map);

      if (!this.onItemClick && onItemClick) {
        this.onItemClick = onItemClick;
      }

      if (!this.onOrderClick && onOrderClick) {
        this.onOrderClick = onOrderClick;
      }

      if (!this.setDraggableMarkerIDs && setDraggableMarkerIDs) {
        this.setDraggableMarkerIDs = setDraggableMarkerIDs;
      }

      this.selectedMarkers = selectedMarkers ?? [];

      const customClusterCustomIcon = (cluster: MarkerCluster) => {
        const count = cluster.getChildCount();

        const options = { cluster: 'marker-cluster' };

        return L.divIcon({
          html: `<div><span>${count}</span></div>`,
          className: `${options.cluster}`,
          iconSize: new L.Point(40, 40)
        });
      };

      const clientClusterCustomIcon = (cluster: MarkerCluster) => {
        const count = cluster.getChildCount();

        const options = { cluster: 'marker-cluster ils-marker-clients' };

        return L.divIcon({
          html: `<div><span>${count}</span></div>`,
          className: `${options.cluster}`,
          iconSize: new L.Point(40, 40)
        });
      };

      const orderClusterCustomIcon = (cluster: MarkerCluster) => {
        const count = cluster.getChildCount();
        const clusterChildMarkers = cluster.getAllChildMarkers();

        let childData: { ID: number }[] = [],
          ids: number[] = [],
          ifSelected: boolean = false;

        clusterChildMarkers.length &&
          clusterChildMarkers.forEach((c) => {
            if (!isEmpty(c.options['data'])) {
              childData = [...childData, c.options['data']];
            }
          });

        childData.forEach((d: { ID: number }) => {
          if (d.ID) {
            ids.push(d.ID);
          }
          if (this.selectedMarkers && this.selectedMarkers.length) {
            this.selectedMarkers.forEach((s) => {
              if (s.ID && s.ID === d.ID) ifSelected = true;
            });
          }
        });

        ids.forEach((d) => {
          if (this.selectedMarkers && this.selectedMarkers.length) {
            this.selectedMarkers.forEach((s) => {
              if (s.ID && s.ID === d) ifSelected = true;
            });
          }
        });

        const options = {
          cluster: 'marker-cluster ils-marker-orders ' + (ifSelected ? ActiveType.Selected : '')
        };

        return L.divIcon({
          html:
            '<div id="marker_' +
            ids +
            '" class="marker-order-cluster ' +
            (ifSelected ? ActiveType.Selected : '') +
            `">
					 <span>${ifSelected ? `${this.selectedMarkers.length > count ? ids.length : this.selectedMarkers.length} / ${count}` : count}</span>
					</div>`,
          // @ts-ignore
          data: JSON.stringify(childData),
          className: `${options.cluster}`,
          iconSize: ifSelected ? new L.Point(50, 50) : new L.Point(40, 40)
        });
      };

      const storageClusterCustomIcon = (cluster: MarkerCluster) => {
        const count = cluster.getChildCount();

        const options = { cluster: 'marker-cluster ils-marker-storages' };

        return L.divIcon({
          html: `<div><span>${count}</span></div>`,
          className: `${options.cluster}`,
          iconSize: new L.Point(40, 40)
        });
      };

      if (this.clusters.length) {
        this.clusters.forEach((cluster) => {
          this.map?.removeLayer(cluster);
        });
      }

      const cluster = L.markerClusterGroup({
        ...this.clusterOptions,
        iconCreateFunction: customClusterCustomIcon
      }).addTo(this.map);
      const clientCluster = L.markerClusterGroup({
        ...this.clusterOptions,
        iconCreateFunction: clientClusterCustomIcon
      }).addTo(this.map);
      const orderCluster = L.markerClusterGroup({
        ...this.clusterOptions,
        iconCreateFunction: orderClusterCustomIcon
      }).addTo(this.map);
      const storageCluster = L.markerClusterGroup({
        ...this.clusterOptions,
        iconCreateFunction: storageClusterCustomIcon
      }).addTo(this.map);

      this.clusters = [cluster, clientCluster, orderCluster, storageCluster];

      if (markers && markers.length) {
        if (!polylineMarkers) {
          this.markers = markers;
        }

        this.markers.forEach((marker) => {
          const ID = marker?.data?.ID;
          const { color = '#4C91FF' } = marker;
          const selected =
            (ID &&
              selectedMarkers &&
              selectedMarkers.length &&
              selectedMarkers?.find((m) =>
                typeof m === 'object' ? String(m.ID) === String(ID) && m.type === marker.type : String(m) === String(ID)
              )) ||
            ((!selectedMarkers || selectedMarkers.length === 0) && marker?.selected);

          let icon;
          switch (marker.type) {
            case 'color':
              icon = colorMarker(color);
              break;
            case 'order':
              icon = orderMarker(color, selected, ID, marker.data);
              break;
            case 'point':
              icon = pointMarker(marker.data, color, marker.number ?? 0, marker.figure, selected, ID);
              break;
            case 'storage':
              icon = customMarker(homeMarker, '#4C91FF');
              break;
            case 'client':
              icon = customMarker(clientMarker, '#2358C6');
              break;
            case 'load':
              icon = customMarker(loadMarker, '#50B993');
              break;
            case 'car':
              icon = carMarker();
              break;
            case 'destination':
              icon = destinationMarker(marker.text ?? '', '#4C91FF');
              break;
            default:
              icon = defaultMarker;
          }

          if (!isEmpty(marker.coords)) {
            const coords = Object.values(marker.coords) as LatLngExpression;
            const draggable = (marker.type && marker.type === 'point') as boolean;
            const newMarker = new ILSMarker({
              coords,
              options: {
                icon: icon,
                draggable: draggable,
                data: marker.data
              }
            }).L;

            if (marker.pointData && marker.type) {
              if (marker.type === 'point') {
                newMarker.bindPopup(pointTip(marker.pointData));
              }

              if (marker.type === 'order') {
                newMarker.bindPopup(orderTip(marker.pointData));
              }

              if (marker.type === 'storage' || marker.type === 'client' || marker.type === 'load') {
                newMarker.bindPopup(depotTip(marker.pointData));
              }
            }

            newMarker.on('click', () => {
              if (marker.type && marker.type === 'point' && marker.clickData && this.onItemClick) {
                this.onItemClick(marker.clickData, true);
              }

              if (marker.type && marker.type === 'order' && marker.pointData && this.onOrderClick) {
                if (marker?.data) {
                  this.onOrderClick(
                    {
                      ...marker.pointData,
                      ...marker.data
                    },
                    true
                  );
                } else {
                  this.onOrderClick({ ...marker.pointData }, true);
                }
              }
            });

            newMarker.on('dragend', () => {
              if (draggable) {
                newMarker.setLatLng(coords as LatLngExpression);

                if (this.setDraggableMarkerIDs) {
                  this.setDraggableMarkerIDs({ data: marker.clickData });

                  setTimeout(() => {
                    this.setDraggableMarkerIDs!(null);
                  }, 100);
                }
              }
            });

            if (this.clusterOptions) {
              switch (marker.type) {
                case 'client':
                  clientCluster.addLayer(newMarker);
                  break;
                case 'order':
                  orderCluster.addLayer(newMarker);
                  break;
                case 'storage':
                  storageCluster.addLayer(newMarker);
                  break;
                default:
                  if (polylineMarkers || marker.noCluster) {
                    newMarker.addTo(this.markerLayer);
                  } else {
                    cluster.addLayer(newMarker);
                  }
              }
            } else {
              newMarker.addTo(this.markerLayer);
            }
          }
        });
      }
    }
  }

  /**
   * @deprecated
   */
  public drawConvexPolygon(
    convex: ConvexPolygon[],
    onItemClick?: (record: any, expand?: boolean, expandChild?: boolean, ctrl?: boolean) => void
  ) {
    if (this.map) {
      this.convexLayer.clearLayers();
      this.convexLayer.addTo(this.map);

      if (!this.onItemClick && onItemClick) {
        this.onItemClick = onItemClick;
      }

      if (convex && convex.length) {
        convex.forEach((c) => {
          const coords = isString(c.coords) ? GeohashDecodePoints(c.coords) : c.coords;
          if (this.map && !isEmpty(coords)) {
            const newConvex = L.polygon(coords as LatLngExpression[], {
              color: c.color,
              weight: 0,
              fillColor: c.color,
              fillOpacity: 0.3
            });

            newConvex.on('click', (e) => {
              if (c.clickData && this.onItemClick) {
                this.onItemClick(
                  c.clickData,
                  true,
                  false,
                  // @ts-ignore
                  e.originalEvent && e.originalEvent.ctrlKey
                );
                // this.map?.fitBounds(newConvex.getBounds()) Отключена по задаче
              }
            });

            newConvex.addTo(this.convexLayer);
          }
        });
      }
    }
  }

  /**
   * @deprecated
   */
  public drawPolylines(
    polylines: Polyline[],
    onItemClick?: (record: any, expand?: boolean, expandChild?: boolean, ctrl?: boolean) => void,
    setPolylineDataIDs?: undefined | ((_: Record<string, number> | null) => void),
    onHoverParams?: {
      color?: string;
      weight?: number;
    }
  ) {
    if (this.map) {
      this.polylineLayer.clearLayers();
      this.polylineLayer.addTo(this.map);

      if (!this.onItemClick && onItemClick) {
        this.onItemClick = onItemClick;
      }

      if (!this.setPolylineDataIDs && setPolylineDataIDs) {
        this.setPolylineDataIDs = setPolylineDataIDs;
      }

      let markers: Marker[] = [];

      if (polylines && polylines.length) {
        polylines.forEach((polyline) => {
          const marker = polyline.active && polyline.point && polyline.point.marker;
          const dashedArr = [polyline.weight < 3 ? polyline.weight * 3 : polyline.weight * 2, polyline.weight * 5];
          const coords = isString(polyline.coords) ? GeohashDecodePoints(polyline.coords) : polyline.coords;
          if (this.map && !isEmpty(coords)) {
            if (marker) {
              markers = markers.concat(marker);
            }

            const polyLine = L.polyline(coords as LatLngExpression[], {
              color: `${polyline.color}B3` ?? '#4C91FFB3',
              weight: polyline.weight ?? 3,
              dashArray: polyline.dashed ? dashedArr.join(', ') : undefined
            });

            polyLine.on('mouseover', (e) => {
              const layer = e.target;

              layer.setStyle({
                color: onHoverParams?.color ?? `${polyline.color}B3` ?? '#4C91FFB3',
                weight: onHoverParams?.weight ?? 5,
                dashArray: polyline.dashed ? dashedArr.join(', ') : undefined
              });

              if (polyline.clickData && this.setPolylineDataIDs) {
                this.setPolylineDataIDs({ data: polyline.clickData });
              }
            });

            polyLine.on('mouseout', (e) => {
              const layer = e.target;

              layer.setStyle({
                color: polyline.color ?? '#4C91FF',
                weight: polyline.weight ?? 3,
                dashArray: polyline.dashed ? dashedArr.join(', ') : undefined
              });

              if (this.setPolylineDataIDs) {
                this.setPolylineDataIDs(null);
              }
            });

            polyLine.on('click', () => {
              if (polyline.clickData && this.onItemClick) {
                this.onItemClick(polyline.clickData, true);
              }
            });

            polyLine.addTo(this.polylineLayer);
          }
        });
      }

      if (markers && markers.length) {
        this.drawMarkers(markers, true);
      }
    }
  }

  public addLayerGroup(layerProps: Parameters<typeof L.layerGroup>, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const layerG = L.layerGroup(...layerProps);
      layerG.addTo(to ? to : this.map);
      return layerG;
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public deleteLayerGroup(layerGroup: L.LayerGroup) {
    layerGroup.remove();
  }

  public addClusterGroup(layerProps: Parameters<typeof L.markerClusterGroup>, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const layerG = L.markerClusterGroup(...layerProps).addTo(this.map);
      layerG.addTo(to ? to : this.map);
      return layerG;
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public deleteClusterGroup(layerGroup: L.MarkerClusterGroup) {
    layerGroup.remove();
  }

  public addAnyLayer(layer: L.Layer, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      layer.addTo(to ?? this.map);
      return layer;
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public deleteAnyLayer(layer: L.Layer) {
    if (this.map) {
      layer.remove();
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public addMarker(markerProps: ConstructorParameters<typeof ILSMarker>, to?: L.Map | L.LayerGroup | L.MarkerClusterGroup) {
    if (this.map) {
      const marker = new ILSMarker(...markerProps).L;
      return marker.addTo(to ? to : this.map);
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public addPolygons(polygons: Polygon[], to?: L.Map | L.LayerGroup) {
    this.polygonLayer.clearLayers();
    if (polygons && polygons.length) {
      polygons.forEach((polygon) => {
        if (polygon.coords) {
          const { coords, color } = polygon;
          const latLng = coords && typeof coords === 'string' ? GeohashDecodePoints(coords) : coords;
          if (latLng) {
            if (this.map) {
              this.polygonLayer.addTo(this.map);
              const p = new ILSPolygon(latLng as LatLngExpression[] | LatLngExpression[][], { color }).L;
              return p.addTo(to ?? this.polygonLayer);
            } else {
              throw new Error(MAP_INIT_ERROR_MESSAGE);
            }
          }
        }
      });
    }
  }

  public startEdit() {}

  public saveEdit() {}

  public cancelEdit() {}

  public addPolyline(polyProps: ConstructorParameters<typeof ILSPolyline>, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const poly = new ILSPolyline(...polyProps).Polyline;
      return poly.addTo(to ? to : this.map);
    } else {
      throw new Error(MAP_INIT_ERROR_MESSAGE);
    }
  }

  public addPolygon(polyProps: ConstructorParameters<typeof ILSPolygon>, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const poly = new ILSPolygon(...polyProps).L;
      return poly.addTo(to ? to : this.map);
    } else {
      throw new Error('Map is not init');
    }
  }

  public deinit() {
    if (this._resizeTimer) {
      clearInterval(this._resizeTimer);
    }
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
    }
    if (this.map) {
      this.map.remove();
    }
  }

  private layerChange(layer: TileLayers) {
    try {
      if (this.map) {
        if (this.tiles[layer] !== this.activeTileLayer) {
          this.map.removeLayer(this.activeTileLayer);
          this.activeTileLayer = this.tiles[layer];
          this.map.addLayer(this.activeTileLayer);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        showUserAndDevError({ userError: error.toString(), error });
      }
    }
  }
}

export class ILSMarker {
  public LMarker: L.Marker;

  public constructor(marker: {
    coords: LatLngExpression;
    options: L.MarkerOptions & {
      icon: L.Icon<L.IconOptions> | L.DivIcon | undefined;
      draggable?: boolean;
      data?: any;
    };
    popup?: Parameters<L.Marker['bindPopup']>;
    handlers?: Record<string, LayerHandler<L.Marker>>;
    tooltip?: Parameters<L.Layer['bindTooltip']>;
  }) {
    const { coords, options, popup, handlers, tooltip } = marker;
    if (isEmpty(coords)) {
      throw new Error('Нельзя создавать маркеры без координат');
    }
    this.LMarker = L.marker(coords, options);
    if (popup) {
      this.LMarker.bindPopup(...popup);
    }
    if (tooltip) {
      this.LMarker.bindTooltip(...tooltip);
    }
    if (handlers) {
      Object.keys(handlers).forEach((eventType) => {
        if (handlers[eventType]) {
          this.LMarker.on(eventType, (event) => {
            handlers[eventType]({
              data: options.data,
              event: event,
              layer: this.LMarker
            });
          });
        }
      });
    }
    return this;
  }

  public get Marker() {
    return this.LMarker;
  }

  public get L() {
    return this.LMarker;
  }
}

export class ILSPolyline {
  public LPolyline: L.Polyline<any>;

  public constructor(poly: {
    coords: LatLngExpression[] | LatLngExpression[][];
    options: L.PolylineOptions & {
      data?: any;
    };
    popup?: Parameters<L.Polyline['bindPopup']>;
    handlers?: Record<string, LayerHandler<L.Polyline>>;
    tooltip?: Parameters<L.Layer['bindTooltip']>;
  }) {
    const { coords, options, popup, handlers, tooltip } = poly;

    this.LPolyline = L.polyline(coords, options);
    if (popup) {
      this.LPolyline.bindPopup(...popup);
    }
    if (tooltip) {
      this.LPolyline.bindTooltip(...tooltip);
    }

    if (handlers) {
      Object.keys(handlers).forEach((eventType) => {
        if (handlers[eventType]) {
          this.LPolyline.on(eventType, (event) => {
            handlers[eventType]({
              data: options.data,
              event: event,
              layer: this.LPolyline
            });
          });
        }
      });
    }
    return this;
  }

  public get Polyline() {
    return this.LPolyline;
  }

  public get L() {
    return this.LPolyline;
  }
}

export class ILSPolygon {
  public LPolygon: L.Polygon;

  public constructor(
    coords: LatLngExpression[] | LatLngExpression[][],
    options: L.PolylineOptions & {
      data?: any;
    },
    popup?: Parameters<L.Polyline['bindPopup']>,
    handlers?: Record<string, LayerHandler<L.Polygon>>,
    tooltip?: Parameters<L.Layer['bindTooltip']>,
  ) {
    this.LPolygon = L.polygon(coords, options);
    if (popup) {
      this.LPolygon.bindPopup(...popup);
    }
    if (tooltip) {
      this.LPolygon.bindTooltip(...tooltip);
    }
    if (handlers) {
      Object.keys(handlers).forEach((eventType) => {
        if (handlers[eventType]) {
          this.LPolygon.on(eventType, (event) => {
            handlers[eventType]({
              data: options.data,
              event: event,
              layer: this.LPolygon
            });
          });
        }
      });
    }
    return this;
  }

  public get Polygon() {
    return this.LPolygon;
  }

  public get L() {
    return this.LPolygon;
  }
}
