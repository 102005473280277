import { isNil } from 'lodash';
import { EMPTY_CELL, EMPTY_STRING, SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';
import moment from 'moment';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

/* Функция считает длительность в рамках месяца, при необходимости необходимо дополнить логику */

export const countDuration = (start: number | null | undefined, end: number | null | undefined): string => {
  if (isNil(start)) return EMPTY_CELL;
  if (isNil(end)) return EMPTY_CELL;

  const startUnix = start * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  const endUnix = end * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  const duration = moment.duration(endUnix - startUnix);
  const months = duration.months().toString();
  const hh = duration.hours().toString();
  const mm = duration.minutes().toString();

  if (Number(months)) {
    showUserAndDevError({ error: 'Длительность больше месяца' });
    return EMPTY_STRING;
  }

  return `${duration.days()} д. ${hh.padStart(2, '0')}:${mm.padStart(2, '0')}`;
};
