import { DictionariesEndpoint, User } from '@common/types';
import { userRoutine } from '@modules/account/actions';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { Dispatch } from 'redux';

export const useUserActions = ({
  dispatch,
  userID,
  toggleForm,
  user
}: {
  user: User;
  dispatch: Dispatch;
  userID: User['ID'];
  toggleForm(show: boolean, user: User | null): void;
}) => {
  const handleDelete = (userID: User['ID']) => dispatch(userRoutine({ id: userID, operation: DictionariesEndpoint.Delete }));

  const handleDeleteUser = () => {
    const confirmDeleteUser = () => handleDelete(userID);
    ILSUserNotifyConfirm('Удалить пользователя?', undefined, confirmDeleteUser);
  };

  const handleChangeUser = () => toggleForm(true, user);
  return { handleDeleteUser, handleChangeUser };
};
