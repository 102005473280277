export const APP_NAME = 'ILS_TMS_APP';
export const USE_CORE_STATE = true;
export const PRODUCT_VERSION = 'PRODUCT_VERSION';

export enum StateKeyName {
  Settings = 'Settings',
  Fixed = 'fixed',
  Auth = 'Auth',
  Common = 'Common',
  StopSagas = 'StopSagas',
  Notification = 'Notification',
  SuggestionAutoComplete = 'SuggestionAutoComplete'
}

export const CATALOG_RELOAD = 'CATALOG_RELOAD';

export enum ButtonText {
  Accept = 'Принять',
  Cancel = 'Отменить',
  ResetSorting = 'Сбросить группировку'
}
