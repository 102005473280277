import moment, { Moment } from 'moment';
import {
  DateFormat,
  DateFunction,
  DateRangeFromTill,
  DateSeparator,
  DateTimeFormat,
  ILS_MAIN_API_DATE_FORMAT,
  TimeFormat,
  TimeStamp
} from '@common/types';
import {
  EMPTY_CELL,
  HOURS_IN_DAY,
  JoinChar,
  MILLISECONDS_TO_DAYS_MULTIPLIER,
  MINUTES_IN_HOURS,
  NOT_EXIST_IN_STRING,
  SECONDS_TO_DAYS_MULTIPLIER,
  SECONDS_TO_MILLISECONDS_MULTIPLIER
} from '@common/constants';
import { isBoolean, isDate, isEmpty, isNil, isObject } from 'lodash';
import { EMPTY_STRING, NEGATIVE_NUM_PREFIX, SplitChar } from '@common/constants/general';
import { TILDA_SEPARATOR } from '@common/components/data-display/table/constants';
import {
  FormatType,
  ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
  ILS_MAIN_INTERFFACE_TIME_FORMAT
} from '@common/types/general/date-time';
import { compareAsString } from '@common/utils';
import { UTC_OFFSET_DEFAULT } from '@modules/monitoring/children/dashboard/components/dashboard/configs/chart';

// TODO: Есть функция в этом файле с похожим названием timestampToDatetimeString
export const timestampToDateTimeString = (ts: number, type: FormatType = FormatType.Datetime): string => {
  if (!ts) return EMPTY_CELL;
  const momentDate = moment.unix(ts);
  if (!momentDate.isValid()) return '';
  switch (type) {
    case FormatType.Datetime:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT);
    case FormatType.Time:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_TIME_FORMAT);
    default:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_DATE_FORMAT);
  }
};

export const timestampToDateTimeStringWithUTC = (ts: TimeStamp, offsetTimeZone: number = UTC_OFFSET_DEFAULT) => {
  if (!ts) return EMPTY_CELL;
  const momentDate = moment.unix(ts);
  if (!momentDate.isValid()) return EMPTY_CELL;
  return momentDate.utcOffset(offsetTimeZone).format(DateTimeFormat.DDMMYYYYPeriodHHMMColonSpaceZ) + 'UTC';
};

export const stringSecondsFromMoment = (time: Moment) => {
  return `:${time.toDate().toString().split(JoinChar.Space)[4].split(SplitChar.Colon).pop()}`;
};

export const timestampToDateTimeStringWithSeconds = (ts: TimeStamp, offsetTimeZone: number) => {
  const time = moment.unix(ts).utc().add(offsetTimeZone, 'minutes');
  const seconds = stringSecondsFromMoment(time);
  const stringTime = time.format(DateTimeFormat.YYYYMMDDDashHHMMSS);
  return moment(stringTime).isValid() ? stringTime : time.format(DateTimeFormat.YYYYMMDDDashHHMM) + seconds;
};

/**
 * @param ts время в секундах (TimeStamp)
 * @param offsetTimeZone смещение временной зоны в минутах (utc default) или часы, если находятся в промежутке между -16 и 16
 * @returns Возвращает строку времени и даты с указанным временем временной зоны и временем по UTC или пустую строку
 */
export const timestampToDateTimeStringWithTZ = (ts: TimeStamp | null | undefined, offsetTimeZone: number | null = 3) => {
  const time = ts ? moment.unix(ts).utc(false) : null;
  return ts && time
    ? `${time
        .utcOffset(offsetTimeZone ?? 3)
        .format(DateTimeFormat.DDMMYYYYPeriodHHMMColonSpaceZ)} Универсальное время: ${timestampToDateTimeStringWithUTC(ts, 0)}`
    : EMPTY_CELL;
};

export const timestampToTimeStringWithUTC = (ts: TimeStamp | undefined, offsetTimeZone: number = UTC_OFFSET_DEFAULT) => {
  if (!ts) return EMPTY_CELL;
  const momentDate = moment.unix(ts);
  if (!momentDate.isValid()) return EMPTY_CELL;

  return moment.unix(ts).utcOffset(offsetTimeZone).format(TimeFormat.HHMMColonSpaceZ) + 'UTC';
};

export const timestampToDateStringWithUTC = (ts: TimeStamp | null | undefined, offsetTimeZone: number | null = 3) => {
  if (!ts) return EMPTY_CELL;
  const momentDate = moment.unix(ts);
  if (!momentDate.isValid()) return EMPTY_CELL;

  return momentDate.utcOffset(offsetTimeZone ?? 3).format(DateFormat.DDMMYYYYPeriod);
};

//! В библиотеке moment проблемы с отображением секунд в формате 'ss'
// TODO: Есть функция в этом файле с похожим названием timestampToDateTimeString
export const timestampToDatetimeString = (timeStamp: TimeStamp | undefined, utc: boolean, format: string) => {
  if (isNil(timeStamp)) return undefined;
  const ts = Number(timeStamp);

  if (isNaN(ts)) return undefined;

  return format
    ?.split('ss')
    .map((fPart) => (fPart ? moment.unix(ts).utc(utc).format(fPart) : ''))
    .join(
      moment.unix(ts).utc(utc).seconds() > 10 ? moment.unix(ts).utc(utc).seconds().toString() : '0' + moment.unix(ts).utc(true).seconds()
    );
};

// todo заменить везде на moment.unix(moment(date, dateFormat).unix())
export const dateStringToTimestamp = (date: string, inputFormat = DateFormat.YYYYMMDDDash) => {
  if (!date) return undefined;
  const dateObj = stringToDate(date, inputFormat ?? DateFormat.YYYYMMDDDash);
  if (!dateObj) return undefined;
  return dateObj.getTime() / SECONDS_TO_MILLISECONDS_MULTIPLIER;
};

export const formatDate = (d: Date, outputFormat = DateFormat.YYYYMMDDSlash): string => {
  if (!d || !isDate(d)) return EMPTY_CELL;
  const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
  const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
  const year = d.getFullYear();
  if (Number.isNaN(Number(day)) || Number.isNaN(Number(month)) || Number.isNaN(Number(year))) {
    return EMPTY_CELL;
  }
  switch (outputFormat) {
    case DateFormat.DDMMYYYYPeriod:
      return day + '.' + month + '.' + year;
    case DateFormat.YYYYMMDDSlash:
    default:
      return year + '-' + month + '-' + day;
  }
};

//TODO moment
export const stringToDate = (date: string, inputFormat: DateFormat = DateFormat.YYYYMMDDDash) => {
  if (!date) return undefined;

  if (date[0] === NEGATIVE_NUM_PREFIX) {
    date = date.slice(1);
  }
  const formatLowerCase = inputFormat?.toLowerCase() ?? DateFormat.YYYYMMDDDash;
  const yearPos = formatLowerCase.indexOf('yyyy');
  if (yearPos === NOT_EXIST_IN_STRING) return undefined;
  const monthPos = formatLowerCase.indexOf('mm');
  const dayPos = formatLowerCase.indexOf('dd');
  const year = date.substring(yearPos, yearPos + 4);
  const month = monthPos === NOT_EXIST_IN_STRING ? '01' : date.substring(monthPos, monthPos + 2);
  const day = dayPos === NOT_EXIST_IN_STRING ? '01' : date.substring(dayPos, dayPos + 2);
  return Number.isNaN(Number(year)) || Number.isNaN(Number(month)) || Number.isNaN(Number(day))
    ? undefined
    : new Date(Number(year), Number(month) - 1, Number(day));
};

//TODO moment
export const formatTimeString = (
  time: string | number = EMPTY_CELL,
  outputFormat: string = TimeFormat.HHMMColon,
  inputFormat: string = TimeFormat.HHMMColon
): string => {
  if (!time) return EMPTY_CELL;
  if (typeof time === 'number') {
    time = time.toString().trim();
  } else {
    time = time.trim();
  }
  if (time.indexOf(SplitChar.Colon) === 1) {
    time = '0' + time;
  }
  const input = inputFormat.toLowerCase();
  const output = outputFormat.toLowerCase();

  const hourPos = input.indexOf('hh');
  const minutePos = input.indexOf('mm');
  const secPos = input.indexOf('ss');
  if (hourPos === NOT_EXIST_IN_STRING || minutePos === NOT_EXIST_IN_STRING) return EMPTY_CELL;

  if (Number.isNaN(Number(time.substring(hourPos, hourPos + 2)))) return EMPTY_CELL;
  if (Number.isNaN(Number(time.substring(minutePos, minutePos + 2)))) return EMPTY_CELL;
  const hour =
    hourPos === NOT_EXIST_IN_STRING ? '00' : time.substring(hourPos, hourPos + 2).length ? time.substring(hourPos, hourPos + 2) : '00';
  const minute =
    minutePos === NOT_EXIST_IN_STRING
      ? '00'
      : time.substring(minutePos, minutePos + 2).length
      ? time.substring(minutePos, minutePos + 2)
      : '00';
  const sec = secPos === NOT_EXIST_IN_STRING ? '00' : time.substring(secPos, secPos + 2);

  return output.replace('hh', hour).replace('mm', minute).replace('ss', sec).trim();
};

//TODO getStartEndMonthDays
export const getFirstNLastDayOfMonth = (d: Date, endBeginNewMonth = false): DateRangeFromTill | undefined => {
  if (!d || !isDate(d)) return undefined;
  const year = d.getFullYear();
  const month = d.getMonth() + 1;

  if (!year || !month) return undefined;

  if (!endBeginNewMonth) {
    const first = new Date(year, month, 1).getDate().toString().padStart(2, '0');
    const last = new Date(year, month, 0).getDate().toString().padStart(2, '0');

    return [[year, month.toString().padStart(2, '0'), first].join('-'), [year, month.toString().padStart(2, '0'), last].join('-')];
  } else {
    //Если нужно чтобы дата оканчивалась следующим днем месяца
    const firstDay = '01';
    const lastDate = new Date(year, month + 1, 1);
    const lastYear = lastDate.getFullYear();
    const lastMonth = lastDate.getMonth();

    return [
      [year, month.toString().padStart(2, '0'), firstDay].join('-'),
      [lastYear, lastMonth.toString().padStart(2, '0'), firstDay].join('-')
    ];
  }
};

export const getFromTillMonth = (e: Moment, monthsShift = 0): DateRangeFromTill => {
  const date = moment(e);
  const from = date.format(DateFormat.FIRST_MMYYYYDash);
  const till = date.add(monthsShift, 'M').format(DateFormat.FIRST_MMYYYYDash);
  return [from, till];
};

export const getStartEndMonthDays = (momentInput: Moment, format = ILS_MAIN_API_DATE_FORMAT): DateRangeFromTill => {
  // передаем moment(momentInput) что бы не мутировать приходящую date
  const from = moment(momentInput).startOf('month').format(format);
  const till = moment(momentInput).endOf('month').format(format);
  return [from, till];
};
//TODO Date | object shouldn't be types of args
export const doubleToTime = (time: number | null | undefined | string | Date | object): string => {
  if (isNil(time) || isObject(time) || compareAsString(time, EMPTY_CELL) || isBoolean(time)) return EMPTY_CELL;

  const minutes = doubleToMinutes(time);

  let hh = Math.floor(minutes / MINUTES_IN_HOURS).toString(); //для часов, где необходимы минуты, нужно округлять вниз
  let mm = Math.round(minutes % MINUTES_IN_HOURS).toString();
  const output = `${hh.padStart(2, '0')}${SplitChar.Colon}${mm.padStart(2, '0')}`;
  return compareAsString('NaN:NaN', output) ? EMPTY_CELL : output;
};

export const doubleToMinutes = (time: number | null | undefined | string): number => {
  return !time && time !== 0 ? 0 : Math.round(Number(time) * HOURS_IN_DAY * MINUTES_IN_HOURS);
};

export const doubleToTimestamp = (time: number | null | undefined = 0) => {
  return Math.round((time ?? 0) * MILLISECONDS_TO_DAYS_MULTIPLIER);
};

export const doubleToUnixTimestamp = (time: number | null | undefined = 0) => {
  return (time ?? 0) * SECONDS_TO_DAYS_MULTIPLIER;
};

export const timeToDouble = (time?: string | null): number => {
  if (time === EMPTY_CELL || isNil(time) || !time.includes(SplitChar.Colon)) return 0;
  const [hh, mm] = time.split(SplitChar.Colon);

  return (parseInt(hh) * MINUTES_IN_HOURS + parseInt(mm)) / (HOURS_IN_DAY * MINUTES_IN_HOURS);
};

export function msToTime(ms: number): string {
  if (ms === undefined || ms === null || isNaN(ms)) return EMPTY_CELL;
  let seconds: string | number = Math.floor((ms / SECONDS_TO_MILLISECONDS_MULTIPLIER) % MINUTES_IN_HOURS),
    minutes: string | number = Math.floor((ms / (SECONDS_TO_MILLISECONDS_MULTIPLIER * MINUTES_IN_HOURS)) % MINUTES_IN_HOURS),
    hours: string | number = Math.floor((ms / 3600000) % HOURS_IN_DAY);
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

//TODO moment.js isBetween || isSame
export const disabledDate = (current: any, availableDates: Array<string>, strict: boolean = true) => {
  let date = moment(current).format(DateFormat.DDMMYYYYPeriod);
  if (strict && (!availableDates || !Array.isArray(availableDates) || availableDates.indexOf(date) === NOT_EXIST_IN_STRING)) {
    return current && moment(current);
  }
};

export const isTimeFormatHHmm = (time: any) => {
  return /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g.test(time);
};

export const changeDateFormat = (date: string) => {
  return moment(date, DateFormat.DDMMYYYYPeriod).format(DateFormat.YYYYMMDDDash);
};

export const periodToStringBySeparator = (data: { date: [Moment, Moment]; format?: DateFormat; separator?: string }) => {
  const { date, format, separator } = data;
  if (isEmpty(date)) return EMPTY_STRING;
  return date
    .reduce((result: string[], data: Moment) => {
      if (moment.isMoment(data)) {
        result.push(moment(data).format(format ?? DateFormat.YYYYMMDDDash));
      }
      return result;
    }, [])
    .join(separator ?? TILDA_SEPARATOR)
    .replaceAll(' ', EMPTY_STRING);
};
