import { FC } from 'react';
import CustomScroll from 'react-customscroll';
import { ILSButton, ILSCheckbox } from '@components/index';
import { Key } from '@common/types';

interface IProps {
  setShow: (a: boolean) => void;
  menu: Array<{ ID: Key; Name: string | null; disabled?: boolean }> | null;
  onChange: (value: boolean | null, item?: { ID: Key } | null, clear?: boolean | null) => void;
  active?: (string | number)[] | null;
  emptyText?: string;
}
//TODO почему мы не используем один и тот же список что и в таблице
export const PlannerPlanningMapMenu: FC<IProps> = ({ setShow, menu, onChange, active, emptyText }) => {
  return (
    <ul className={'map-btn-menu bordered'}>
      <CustomScroll>
        {Boolean(menu?.length) ? (
          menu?.map((item) => (
            <ILSCheckbox
              key={item.ID}
              checked={active?.includes(item.ID)}
              disabled={item.disabled}
              onChange={(e) => onChange(e.target.checked, item)}
            >
              {item.Name}
            </ILSCheckbox>
          ))
        ) : emptyText ? (
          <span>{emptyText}</span>
        ) : null}
      </CustomScroll>
      <ul className="menu-buttons-restriction">
        <ILSButton onClick={() => onChange(null, null, true)}>Сброс</ILSButton>
        <ILSButton type="primary" onClick={() => setShow(false)}>
          OK
        </ILSButton>
      </ul>
    </ul>
  );
};
