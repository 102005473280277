import { apiInstance } from '@core/api/instance';
import { ApiType } from '../types/api';

export const PlanAnalytic: ApiType = {
  get: (params) => {
    const { planID, strPlanningDateTime } = params ?? {};
    const api = apiInstance();

    return api.post('/NewTms/Planning/getPlanAnalitics', { planID, strPlanningDateTime });
  }
};
