import { FC, MutableRefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { RoutePayload, RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderOwnFormTableContainer } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/table';
import { UploadFile } from 'antd/lib/upload/interface';
import { addTenderForRouteRoutine } from '@modules/tender-platform/actions';

interface IProps {
  onSetDataFiles: (routeId: RoutesID, file: UploadFile[]) => void;
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
}

export const TenderOwnTendersFromPlannerContainer: FC<IProps> = ({ onSetDataFiles, dataFiles }) => {
  const dispatch = useDispatch();
  const {
    state: { ids, planID }
  } = useLocation<RoutePayload>() ?? {};

  useEffect(() => {
    if (ids && planID) {
      dispatch(addTenderForRouteRoutine({ ids, planID }));
    }
  }, []);

  return <TenderOwnFormTableContainer dataFiles={dataFiles} onSetDataFiles={onSetDataFiles} />;
};
