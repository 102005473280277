import { ILSButton, ILSSpin } from '@common/components';
import React, { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { catalogTabs } from '../../config/widget-catalog';
import { CatalogWidgetType } from '../../types/widget-catalog';
import ILSWidgetCatalogList from './widget-catalog-list';
import css from 'classnames';
import { IndexedArray } from '@common/types';
import { AnalyticsWidgetCatalogModel } from '@common/types/dictionaries/analytics';

type Props = {
  loading?: boolean;
  data?: IndexedArray<AnalyticsWidgetCatalogModel>;
};

const ILSWidgetCatalogComponent: FC<Props> = ({ loading, data }) => {
  const [activeTab, setActiveTab] = useState<CatalogWidgetType>('Chart');

  return (
    <div className="analytics-widget-catalog">
      <h3 className="analytics-widget-catalog-title">Каталог виджетов</h3>
      <div className="analytics-widget-catalog-tabs">
        {Object.values(catalogTabs).map((item) => (
          <ILSButton
            key={item.type}
            onClick={() => setActiveTab(item.type as CatalogWidgetType)}
            className={css('analytics-widget-catalog-tabs-item', { active: activeTab === item.type })}
            type="link"
            icon={<ReactSVG className="svg-container" src={`/assets/icons/${item.icon}`} />}
          />
        ))}
      </div>

      <h3 className="analytics-widget-catalog-tabs-title">{catalogTabs[activeTab].label}</h3>

      {loading ? (
        <ILSSpin spinning={loading} className="analytics-widget-catalog-spinner" />
      ) : (
        <ILSWidgetCatalogList data={data} activeFilter={activeTab} />
      )}
    </div>
  );
};

export default ILSWidgetCatalogComponent;
