import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { Tender } from '@modules/tender-platform/types';
import { tenderWithMyParticipationRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearchStore } from '@modules/tender-platform/children/tender-search/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderWithMyParticipation = createReducer(initialState, {
  [tenderWithMyParticipationRoutine.REQUEST]: onRequest,
  [tenderWithMyParticipationRoutine.SUCCESS]: (
    state: TenderSearchStore,
    action: PayloadAction<{
      tenders: Array<Tender>;
    }>
  ) => {
    const { tenders } = action.payload;
    state.myTenders = tenders;
    return state;
  },
  [tenderWithMyParticipationRoutine.FAILURE]: onFailure,
  [tenderWithMyParticipationRoutine.FULFILL]: onFulfill
});
