import { FC, useMemo, useRef } from 'react';
import css from 'classnames';
import { ILSButton, ILSTitle } from '@common/components';
import { BOARD_NAME_PLACEHOLDER } from '../../constants';
import ILSWidgetBoard from '@modules/analytics/common/components/board/widget-board';
import { AnalyticsBoardModel, AnalyticsWidget, BoardDefaults } from '@common/types/dictionaries/analytics';
import { IndexedArray } from '@common/types';
import ILSAnalyticsBoardDateSelect from '@modules/analytics/common/components/range-select';
import { MAKE_REPORT_CAPTION } from '@modules/analytics/common/constants';
import { boardToPdf } from '@modules/analytics/common/utils/board-to-pdf';

interface Props {
  board: AnalyticsBoardModel | null;
  widgets?: IndexedArray<AnalyticsWidget> | null;
  widgetParams?: IndexedArray<{ name: string; value: string | number | undefined }>;
  handleSetParams?: (params: IndexedArray<{ name: string; value: string | number | undefined }>) => void;
  handleGetWidgetData: (params: { id: string | number; params: any }) => void;
  selectDateRange: (date: [string, string]) => void;
  selectPeriod: (date: [string, string]) => void;
  handleSelectBoard: (item: AnalyticsBoardModel, defaults: BoardDefaults) => void;
  widgetsError?: boolean;
}

const ILSBoardTenderViewComponent: FC<Props> = ({
  board,
  widgets,
  widgetParams,
  handleSetParams,
  handleGetWidgetData,
  selectDateRange,
  selectPeriod,
  handleSelectBoard,
  widgetsError
}) => {
  const transformedWidgets = useMemo(() => {
    if (!widgets) return {};
    return Object.values(widgets)
      .map((widget) => {
        return { ...widget };
      })
      .reduce((prev, cur) => {
        prev[cur.ID as string | number] = cur;

        return prev;
      }, {});
  }, [widgets]);

  const boardWithHeaderRef = useRef<HTMLDivElement>(null);
  const boardRef = useRef<HTMLDivElement>(null);

  const generatePDF = () => {
    if (!boardWithHeaderRef.current || !boardRef.current) return;
    const boardWithHeaderElement = boardWithHeaderRef.current;
    const boardElement = boardRef.current;
    boardElement.style.overflow = 'visible'; //чтобы попала вся доска и то, что внизу тоже
    boardToPdf({
      board: boardWithHeaderElement,
      canvasHeight: boardElement.scrollHeight,
      canvasWidth: boardElement.clientWidth + 20,
      fileName: board?.Name
    }).then(() => {
      boardElement.style.overflowY = 'scroll'; //вернем обратно scroll
      boardElement.style.overflowX = 'hidden';
    });
  };

  return (
    <>
      <div className="analytics-tender-board-header">
        <ILSTitle title={board?.Name || BOARD_NAME_PLACEHOLDER} />
        <ILSButton onClick={generatePDF} className="export-button mt-2">
          {MAKE_REPORT_CAPTION}
        </ILSButton>
      </div>
      <div ref={boardWithHeaderRef} className="bg-white">
        <div className="ml-3 mr-2">
          <div className="analytics-tender-board-view pt-0">
            <div className={css('flex-between-center', 'analytics-board-header', 'bg-white')}>
              <div className="analytics-tender-board-subtitle">{board?.SubTitle}</div>

              {board?.Period?.length ? (
                <div className="analytics-tender-board-date mr-1">
                  <ILSAnalyticsBoardDateSelect selectDate={selectDateRange} selectPeriod={selectPeriod} />
                </div>
              ) : null}
            </div>
            {/* <ILSWidgetBoard widgets={transformedWidgets} /> */}

            <ILSWidgetBoard
              widgets={transformedWidgets}
              widgetParams={widgetParams}
              stretchable={true}
              handleGetWidgetData={handleGetWidgetData}
              handleSetParams={handleSetParams}
              handleSelectBoard={handleSelectBoard}
              ref={boardRef}
              widgetsError={widgetsError}
              hideWidgetsOnError={true}
            />
            {/* <ILSBoardViewFooter /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ILSBoardTenderViewComponent;

