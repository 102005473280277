import { DashboardTablesObjMetrics } from '../constants';
import { DashboardData, MonitoringDashboardState } from '../types/store';

export const initialDashboardData: DashboardData<any> = {
  isFetching: false, // Параметр показывающий идёт ли загрузка данных
  isSaving: false, // Параметр показывающий идёт ли сохранение данных
  data: [], // Основной массив содержащий информацию
  error: undefined, // Есть ли ошибка и какая
  execStatus: undefined, // Статус выполнения
  lastUpdate: undefined, // Когда и было ли последнее обновление
  from: undefined, // Дата начала периода мониторинга
  till: undefined // Дата окончания периода мониторинга
};

export const initialState: MonitoringDashboardState = {
  selectedDashboard: undefined, // Выбранная вкладка и таблица
  // Данные по метрикам:
  dashboardTableData: {
    all: { ...initialDashboardData }, // Данные для всех таблиц
    [DashboardTablesObjMetrics.OnTime]: { ...initialDashboardData }, // Данные для таблицы "Доставлено вовремя"
    [DashboardTablesObjMetrics.OnLate]: { ...initialDashboardData }, // Данные для таблицы "Доставлено с опозданием"
    [DashboardTablesObjMetrics.Undelivered]: { ...initialDashboardData }, // Данные для таблицы "Не доставлено"
    [DashboardTablesObjMetrics.BrokenSequence]: { ...initialDashboardData }, // Данные для таблицы "Нарушение последовательности"
    [DashboardTablesObjMetrics.Violations]: { ...initialDashboardData }, // Данные для таблицы "Нарушения БДД"
    [DashboardTablesObjMetrics.FuelDrain]: { ...initialDashboardData } // Данные для таблицы "Слив топлива"
  },
  // Метрики прогресса:
  progress: {
    isFetching: false, // Параметр показывающий идёт ли загрузка данных
    data: {
      MassDelivered: 0, // Вес доставленных товаров
      MassTotal: 0, // Общий вес всех товаров
      ClientDelivered: 0, // Количество обслуженных клиентов
      ClientTotal: 0 // Всего клиентов
    }
  }
};
