import { ILSSVG } from '@common/components/custom/icons/svg';
import { JoinChar } from '@common/constants';
import { SVG, SVG_CONFIG, TRIP_ACTIONS } from '@modules/orders-and-trips/constants/general';
import { DecoratedTrips, TripAction } from '@modules/orders-and-trips/types/trip';
import { FC, memo } from 'react';

//TODO убрать моки и сделать типы как только будет готов бэк
interface IProps {
  action: TripAction;
  trip: DecoratedTrips[number];
  index: number;
}

export const TripSingleAction: FC<IProps> = memo(({ action, trip, index }) => {
  return (
    <div className="flex-horizontal">
      {!Boolean(action?.actionType) && (
        <div className="action-direction">
          <ILSSVG config={SVG_CONFIG} icon={SVG.RIGHT_BOX} />
        </div>
      )}
      <div className="action">
        <div className="flex-horizontal">
          <div className="action-type">
            {TRIP_ACTIONS[action?.actionType]} {action.cargoMass} кг &nbsp;
          </div>
          <div className="action-cargo-type">
            {Object.values(action.cargoRestriction ?? {})?.map((restriction) => {
              return restriction + JoinChar.Space;
            })}
          </div>
        </div>
        <div className="action-city">{action.city}</div>
        <div className="action-address">{action.address}</div>
      </div>
    </div>
  );
});

