import permissions from '@abac/permissions';
import { ILSContent, ILSLayout } from '@components/index';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import { requestActionSelector } from '@modules/current-user/selectors/auth';
import { FC } from 'react';
import { AllowedTo } from 'react-abac';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppProps } from 'types';
import { ModulePath } from '@common/types';
import { AuthContent } from '@pages/auth/content';
import ILSDashboard from '@modules/dashboard/containers';

export const AuthScope: Record<
  ModulePath.LogisticServices | ModulePath.Login | ModulePath.Register | ModulePath.Recovery,
  ModuleScope.LogisticServices | ModuleScope.LoginForm | ModuleScope.RegisterForm | ModuleScope.RecoveryForm
> = {
  [ModulePath.LogisticServices]: ModuleScope.LogisticServices,
  [ModulePath.Login]: ModuleScope.LoginForm,
  [ModulePath.Register]: ModuleScope.RegisterForm,
  [ModulePath.Recovery]: ModuleScope.RecoveryForm
};

const AuthPage: FC<AppProps> = ({ location, ...props }) => {
  const requestAction = useSelector(requestActionSelector);
  const allowed = [ModulePath.Login, ModulePath.Recovery, ModulePath.Register, ModulePath.LogisticServices];

  let path = location?.pathname && allowed.includes(location?.pathname as ModulePath) ? location.pathname : ModulePath.LogisticServices;
  let scope = AuthScope[path];
  const queryParams = new URLSearchParams(useHistory().location.search);
  const token = queryParams.get('token');

  if (token && requestAction) {
    //при наличии токена смотрим на request последнего действия
    path = requestAction === 'register' ? ModulePath.Register : ModulePath.Recovery;
    scope = AuthScope[path];
  }

  const isLogisticsServicesPage = path === ModulePath.LogisticServices;

  return (
    <AllowedTo perform={permissions.LOGIN} no={() => <ILSDashboard {...{ location, ...props }} />}>
      <ILSLayout className="ils-wrapper" style={{ background: 'url(/assets/images/bg.svg) no-repeat center' }}>
        <ILSContent className="full-content auth-content">
          <div className="auth-content__header">
            <Scope name={ModuleScope.Logo} extraProps={{ unAuthorizedPages: true }} />
            <AllowedTo perform={permissions.LOGIN} no={() => null}>
              <Scope name={ModuleScope.AuthNav} />
            </AllowedTo>
          </div>
          <AuthContent isForm={!isLogisticsServicesPage} scope={scope} />
        </ILSContent>
      </ILSLayout>
    </AllowedTo>
  );
};

export default AuthPage;
