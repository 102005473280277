import { FC } from 'react';

import ILSBoardSetsTenderListContainer from '../container/board-sets-list';
import { BoardViewContainer } from '../container/board-view';
import ILSBoardsTenderListContainer from '../container/boards-list';

const ILSAnalyticsTenderComponent: FC<{ title?: string | null; showBoardSets: boolean }> = ({ title, showBoardSets }) => {
  return (
    <div className="analytics-tender-component">
      <div className="analytics-tender-component-board">
        <BoardViewContainer />
      </div>
      <div className="analytics-tender-component-list">
        {showBoardSets && <ILSBoardSetsTenderListContainer />}
        <ILSBoardsTenderListContainer />
      </div>
    </div>
  );
};

export default ILSAnalyticsTenderComponent;
