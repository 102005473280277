import { ILSTable } from '@components/index';
import { commonErrorColumns } from '@common/table-configs/error/config';
import { FC } from 'react';
import { FilterCellType, TableColumnName, ValidationData } from '@common/types';
import { AlertNotify } from '@common/types/general/conditions';
import { ValidationKind } from '@common/types/dictionaries/validation';

interface ILSDataCommonTableProps {
  dataSource: ValidationData[];
  kind: AlertNotify | string;
}

export const CommonError: FC<ILSDataCommonTableProps> = ({ dataSource, kind, ...props }) => {
  const columns = commonErrorColumns.map((column) => {
    if (column.dataIndex === TableColumnName.Found && kind) {
      const validationDataIndexFormat = `${ValidationKind[kind]}_`;
      return {
        ...column,
        dataIndex: validationDataIndexFormat,
        key: validationDataIndexFormat
      };
    }

    return { ...column, sorting: true, filtering: FilterCellType.String };
  });

  return (
    <div className="data-table">
      {/*@ts-ignore*/}
      <ILSTable {...props} columns={columns} dataSource={dataSource} className="text-table" rowKey="id" />
    </div>
  );
};
