import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';
import { initialState } from '@modules/planning/children/by-date/store';
import { planningRememberSelectedRowsRoutine } from '@modules/planning/children/by-date/actions';
import { ShortTableRecord } from '@modules/planning/children/by-date/types/record';

export const rememberSelectedRowsReducer = createReducer(initialState, {
  [planningRememberSelectedRowsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      shortRecords?: ShortTableRecord[];
      recordIDs?: (string | number)[];
      tableName: string;
    }>
  ) => {
    const { shortRecords, recordIDs, tableName } = action.payload ?? {};
    if (tableName) {
      state.selectedTableRows[tableName] = {
        shortRecords: shortRecords ?? [],
        recordIDs: recordIDs ?? []
      };
    }
    return state;
  },
  [planningRememberSelectedRowsRoutine.FAILURE]: onFailure,
  [planningRememberSelectedRowsRoutine.FULFILL]: onFulfill
});
