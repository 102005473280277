import { call, put, takeLatest } from 'redux-saga/effects';
import { tenderDeleteRoutine, tenderIndexRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* deleteTenderWorker(action: PayloadAction<{ ids: string | string[] }>) {
  const { request, success, failure, fulfill } = tenderDeleteRoutine;
  const { ids } = action.payload;
  try {
    yield put(request());
    yield call(TenderOwn.delete, { ids });
    yield put(success());
    yield put(tenderIndexRoutine.trigger());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteTenderWatcher() {
  yield takeLatest(tenderDeleteRoutine.trigger, deleteTenderWorker);
}
