import { IILSTableProps, TableRecord } from '@common/types';
import { SelectedRowKeys } from '@common/types/components/table';
import { EMPTY_STRING } from '@common/constants';

const checkIsSelectedRow =
  <R extends TableRecord>(selectedRowKeys: SelectedRowKeys) =>
  (record: R) =>
    selectedRowKeys.map(String)?.includes(record.key?.toString()) ? 'ils-table-row-active-select' : EMPTY_STRING;

export const getRowClass = <R extends TableRecord>({
  rowClass,
  selectedRowKeys
}: Pick<IILSTableProps<R>, 'rowClass'> & {
  selectedRowKeys: SelectedRowKeys;
}) => {
  return rowClass || checkIsSelectedRow(selectedRowKeys);
};
