import { useLocation } from 'react-router';
import { ModulePath, ModuleTitle } from '@common/types';
import { AppProps } from '../../types';

export const getAppTitle = (props: AppProps) => {
  const { pathname } = useLocation();
  const app = props?.route?.routes?.find(({ path }) => path === ModulePath.Dashboard);
  const parentModule = app?.routes
    ?.filter(({ path }) => path !== ModulePath.Dashboard)
    .find(({ path }) => pathname.includes(path as string));
  const childrenModule = parentModule?.routes?.find(({ path }) => pathname === path);

  return `${childrenModule?.title ?? parentModule?.title ?? app?.title ?? ModuleTitle.Main}`;
};
