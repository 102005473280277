import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { GeoCoordType } from '@common/types/general/geohash';
import { useMemo } from 'react';

/***
 * Хук для кэширования декодированных координат
 */
export const useGeoHash = () => {
  // Кэширование для декодированных координат
  return useMemo(() => {
    const cache: {
      [hashCoordinatesString: string]: Array<GeoCoordType> | null;
    } = {};
    return function (hashCoords: string) {
      if (!hashCoords) return [];
      if (cache[hashCoords] === null || cache[hashCoords] === undefined) {
        cache[hashCoords] = GeohashDecodePoints(hashCoords);
      }
      return cache[hashCoords];
    };
  }, []);
};
