import { CellType, ColumnTable, TableConfig, TableRecord } from '@common/types';
import { optionsColumn } from '@modules/documents/constants/options-column';
import { setChildWithStyles } from '../helpers';

const defaultTemplatesConfig = {
  hideClearButtons: true
};

const idColumn: ColumnTable<TableRecord> = {
  title: 'ID документа',
  dataIndex: 'ID',
  key: 'ID',
  type: CellType.Input,
  width: 20
};

const nameColumn = {
  title: 'Имя файла',
  dataIndex: 'Name',
  key: 'Name',
  editable: true,
  type: CellType.Input,
  fullWidth: true,
  width: 280
};

const createdColumn = {
  title: 'Дата последнего редактирования',
  dataIndex: 'Created',
  key: 'Created',
  type: CellType.Input,
  width: 50
};

export const templateTableConfig: TableConfig = {
  ...defaultTemplatesConfig,
  columns: [optionsColumn, idColumn, nameColumn, createdColumn]
};

export const setTableConfig: TableConfig = {
  ...defaultTemplatesConfig,
  columns: [
    optionsColumn,
    { ...idColumn },
    {
      ...nameColumn,
      render: setChildWithStyles
    },
    createdColumn
  ]
};
