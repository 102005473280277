import { monitoringGetTrackersRoutine } from '../actions/index';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { MonitoringState } from '../types/store';

// Получает трекеры
const monitoringGetTrackersReducer = createReducer<MonitoringState>(initialState as MonitoringState, {
  [monitoringGetTrackersRoutine.REQUEST]: (state) => {
    state.trackers.isFetching = true;
    return state;
  },
  [monitoringGetTrackersRoutine.SUCCESS]: (state, action: PayloadAction<any>) => {
    const data = action.payload;

    state.trackers.data = data;

    state.trackers.lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringGetTrackersRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringGetTrackersRoutine.FULFILL]: (state) => {
    state.trackers.isFetching = false;

    return state;
  }
});

export default monitoringGetTrackersReducer;
