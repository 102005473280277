import { put, select, takeLatest } from 'redux-saga/effects';
import { monitoringDeleteVehicleTrackerLocalRoutine } from '../actions';
import { monitoringVehicleTrackersSelector } from '../selectors/trackers';
import last from 'lodash/last';
import { VehicleTracker } from '@common/types';
import { isEmptyString } from '@common/utils/helpers/object/is-nil-or-empty-string';

// Участвует в удалении привязки трекера к автомобилю
function* monitoringDeleteVehicleTrackerLocalWorker() {
  const { success, failure } = monitoringDeleteVehicleTrackerLocalRoutine;
  const vehicleTrackers: VehicleTracker[] = yield select(monitoringVehicleTrackersSelector);
  if (isEmptyString(last(vehicleTrackers)?.TrackerIMEI)) {
    try {
      yield put(success());
    } catch (error) {
      yield put(failure({ error }));
    }
  }
}

export function* monitoringDeleteVehicleTrackeLocalWatcher() {
  yield takeLatest(monitoringDeleteVehicleTrackerLocalRoutine.trigger, monitoringDeleteVehicleTrackerLocalWorker);
}
