import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import Track from './containers';
import trackReducer from './reducers';
import * as trackSagas from './sagas';

export default defineModule({
  title: 'Трек',
  path: '/track',
  component: Track,
  reducer: trackReducer,
  inScope: [ModuleScope.CustomForAuthPage],
  sagas: 'object' === typeof trackSagas ? Object.values(trackSagas).map((s) => s()) : []
});
