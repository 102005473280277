import { tenderDocumentDeleteRoutine } from '@modules/tender-platform/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* tenderDocumentDeleteWorker(action: PayloadAction<{ ids: Array<number> }>) {
  const { request, success, failure, fulfill } = tenderDocumentDeleteRoutine;
  const { ids } = action.payload;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderAPI.delete, { ids });

    yield put(success({ ids, message: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderDocumentDeleteWatcher() {
  yield takeEvery(tenderDocumentDeleteRoutine.trigger, tenderDocumentDeleteWorker);
}
