import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils';
import { initialState } from '@modules/settings/store';
import { mobileApplicationChangeFocusRowRoutine } from '@modules/settings/actions';

export const mobileApplicationFocusRowReducer = createReducer(initialState, {
  [mobileApplicationChangeFocusRowRoutine.REQUEST]: onRequest,
  [mobileApplicationChangeFocusRowRoutine.SUCCESS]: (state, action: PayloadAction<{ dictionary: Dictionary; focusRowID?: number }>) => {
    const { dictionary, focusRowID } = action.payload;

    switch (dictionary) {
      case Dictionary.ForwarderPointStatusesGroup:
        state.mobileApplication.ForwarderPointStatusesGroupFocusRowID = Number(focusRowID);
        break;
      case Dictionary.ForwarderPointStatuses:
        state.mobileApplication.ForwarderPointStatusesFocusRowID = Number(focusRowID);
        break;
      default:
        state.mobileApplication.ForwarderPointStatusesGroupFocusRowID = undefined;
        state.mobileApplication.ForwarderPointStatusesFocusRowID = undefined;
        break;
    }
    return state;
  },
  [mobileApplicationChangeFocusRowRoutine.FAILURE]: onFailure,
  [mobileApplicationChangeFocusRowRoutine.FULFILL]: onFulfill
});

