import { Dictionary, IndexedArray, Plan, PlanStatus, Provider, TableColumnName } from '@common/types';
import { PlansTableRecord } from '@modules/planning/children/by-date/types';
import { CheckPlanningByPlans } from '@modules/planning/types/api';
import { createArrayFromIndexedArray, timestampToDateTimeStringWithTZ } from '@common/utils';
import { DASH, EMPTY_STRING } from '@common/constants';
import { PlanningCell } from '@modules/planning/children/by-date/types/planning-config';

export const plansToTable = ({
  plans,
  Planning,
  checkData
}: {
  plans: IndexedArray<Plan>;
  Planning: PlanningCell;
  checkData: CheckPlanningByPlans | undefined;
}) => {
  return createArrayFromIndexedArray(plans).reduce((plans: Array<PlansTableRecord>, plan) => {
    if (!plan?.PlanTotal) return plans;
    const {
      Pallets,
      ProjectVehicleCount,
      RoutesCount,
      ResolvedOrder,
      Order,
      PenaltyTotal,
      LoadDuration,
      Duration,
      WaitingDuration,
      LatenessDuration,
      UnloadDuration,
      MoveDuration,
      RestDuration,
      RoadDuration,
      FullName,
      Status,
      Mass,
      Distance,
      Boxes,
      StartTime,
      EndTime,
      Volume,
      Dimension,
      TypePlanning,
      UnresolvedOrder,
      AverageSpeed,
      StartTimeOffset,
      EndTimeOffset,
      PlanConfig,
      Efficiency,
      Cost,
      ClientCount
    } = plan.PlanTotal ?? {};

    const isPlanning = checkData?.[plan.ID] && 'isPlanning' in checkData[plan.ID] && checkData[plan.ID].isPlanning;
    const Progress = checkData?.[plan.ID] && 'Progress' in checkData[plan.ID] && checkData[plan.ID].Progress;
    const fullName = `${FullName} ${isPlanning ? '(-/-/-)' : EMPTY_STRING}`;

    const planTotal = {
      key: plan.ID,
      PlanID: plan.ID,
      dictionary: Dictionary.Plan,
      [TableColumnName.Number]: plan.PlanTotal?.Number,
      [TableColumnName.ID]: plan.ID,
      [TableColumnName.Status]: isPlanning ? PlanStatus.Closed : Status,
      [TableColumnName.FullName]: fullName,
      FullName_htmltip: `${FullName} ${plan.NameFull || 'Планирование не запускалось'}`,
      [TableColumnName.PlanConfig]: Object.values(PlanConfig) as [Provider, string],
      [TableColumnName.TypePlanning]: TypePlanning,
      [TableColumnName.Planning]: { ...Planning, icon: isPlanning ? 'stop-planning' : Planning?.icon },
      [TableColumnName.Progress]: Progress,
      [TableColumnName.CalculateDuration]: plan.CalculateDuration,
      [TableColumnName.Vehicles]: isPlanning ? DASH : ProjectVehicleCount,
      [TableColumnName.Trip]: isPlanning ? DASH : RoutesCount,
      [TableColumnName.ResolvedOrder]: isPlanning ? DASH : `${ClientCount ?? DASH}/${ResolvedOrder ?? DASH}/${Order ?? DASH}`,
      [TableColumnName.UnresolvedOrder]: isPlanning ? DASH : `${UnresolvedOrder}/${Order ?? DASH}`,
      [TableColumnName.Penalty]: PenaltyTotal?.toFixed(2),
      [TableColumnName.Cost]: isPlanning ? DASH : Cost,
      [TableColumnName.Duration]: isPlanning ? DASH : Duration,
      [TableColumnName.RoadDuration]: isPlanning ? DASH : RoadDuration,
      [TableColumnName.MoveDuration]: isPlanning ? DASH : MoveDuration,
      [TableColumnName.RestDuration]: isPlanning ? DASH : RestDuration,
      [TableColumnName.Distance]: isPlanning ? DASH : Distance.toFixed(2),
      [TableColumnName.AverageSpeed]: isPlanning ? DASH : AverageSpeed,
      [TableColumnName.LoadDuration]: isPlanning ? DASH : LoadDuration,
      [TableColumnName.UnloadDuration]: isPlanning ? DASH : UnloadDuration,
      [TableColumnName.StartTime]: isPlanning ? DASH : timestampToDateTimeStringWithTZ(StartTime, StartTimeOffset),
      [TableColumnName.EndTime]: isPlanning ? DASH : timestampToDateTimeStringWithTZ(EndTime, EndTimeOffset),
      [TableColumnName.Mass]: isPlanning ? DASH : Mass.toFixed(2),
      [TableColumnName.Pallets]: isPlanning ? DASH : Math.ceil(Pallets ?? 0),
      [TableColumnName.Boxes]: isPlanning ? DASH : Math.ceil(Boxes ?? 0),
      [TableColumnName.Volume]: isPlanning ? DASH : Volume.toFixed(2),
      [TableColumnName.Dimensions]: isPlanning
        ? DASH
        : `${Dimension?.length.toFixed(2) || 0}x${Dimension?.width.toFixed(2) || 0}x${Dimension?.height.toFixed(2) || 0}`,
      [TableColumnName.WaitingDuration]: isPlanning ? DASH : WaitingDuration,
      [TableColumnName.LatenessDuration]: isPlanning ? DASH : LatenessDuration,
      [TableColumnName.Efficiency]: isPlanning ? 0 : Efficiency || 0,
      Efficiency_htmltip: 'Эффективность плана рассчитана как средний процент эффективности по рейсам'
    };

    plans.push(planTotal);

    return plans;
  }, []);
};
