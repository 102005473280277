import { addTenderForRouteRoutine, tenderGetVehicleTypesRoutine, tenderReadRoutine } from '../../../../actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderAPI } from '@modules/tender-platform/api';
import { RoutePayload } from '@modules/tender-platform/children/tender-own/types/route-tenders';

function* addTenderForRouteWorker(action: PayloadAction<RoutePayload>) {
  const { request, success, failure } = addTenderForRouteRoutine;
  const { ids, planID } = action.payload;

  try {
    yield all([put(tenderReadRoutine({ id: null })), put(request())]);

    const {
      data: { data }
    } = yield call(TenderAPI.createTenderFromPlanner, { ids, planID });

    yield all([put(success({ data, planID })), put(tenderGetVehicleTypesRoutine())]);
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* addTenderForRouteWatcher() {
  yield takeEvery(addTenderForRouteRoutine.trigger, addTenderForRouteWorker);
}
