import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-own/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { updateTenderForRouteRoutine } from '@modules/tender-platform/actions';
import { RoutesIDs } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';

export const updateTenderForRouteReducer = createReducer(initialState, {
  [updateTenderForRouteRoutine.REQUEST]: onRequest,
  [updateTenderForRouteRoutine.FAILURE]: onFailure,
  [updateTenderForRouteRoutine.FULFILL]: onFulfill,
  [updateTenderForRouteRoutine.SUCCESS]: (state, action: PayloadAction<{ ids: RoutesIDs; field: TenderFormTableField; value: any }>) => {
    const { ids, field, value } = action.payload;
    ids.forEach((id) => {
      state.initialDataForGroupTenderCreation[id][field] = value;
    });
    return state;
  }
});
