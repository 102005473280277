import { ILSSelectStatic } from '@components/custom/data-display/select';
import { IILSCellCascaderStringProps } from '@common/types/components/table/cell';
import { ENUM_STRING, ID_STRING } from '@common/utils/helpers/object/get-enum-field';
import { isArray, isEmpty, isNil } from 'lodash';
import { compareAsString } from '@common/utils';
import { EMPTY_STRING, JoinChar } from '@common/constants';
import { TableRecord } from '@common/types/components/table/common';
import { getLabelsForCascaderStringChildren } from '@components/data-display/table/utils/helpers/cells/get-labels-for-cascader-string-children';

export const ILSCellCascaderString = <R extends TableRecord>({
  showArrow = true,
  toggleEdit,
  options,
  children,
  record,
  dataIndex,
  fieldNames,
  showParent = true
}: IILSCellCascaderStringProps<R>): JSX.Element => {
  const [node, array] = children;

  if (!array || !isArray(array)) {
    return <ILSSelectStatic className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, undefined]} />;
  }

  const [mainValue, childrenValue] = array;
  const enumV = dataIndex?.includes(ID_STRING) ? record?.[dataIndex.replace(ID_STRING, ENUM_STRING)] : null;
  const cascader = options?.reduce((content, option) => {
    if (isNil(mainValue || childrenValue) || !fieldNames?.value || !fieldNames?.label) return content;
    const { value, label } = fieldNames;
    if (compareAsString(option[value], mainValue)) {
      const selected = isEmpty(enumV)
        ? getLabelsForCascaderStringChildren({ children: option.children, childrenValue, key: value, label })
        : Object.values(enumV).join(JoinChar.Space).trim();
      content = `${showParent ? `${option[label]} /` : EMPTY_STRING} ${selected}`.trim();
    }
    return content;
  }, ``);

  return <ILSSelectStatic showArrow={showArrow} className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, cascader]} />;
};
