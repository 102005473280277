import { IndexedArray } from '@common/types';
import { AnalyticsWidget, BoardWidgetParams } from '@common/types/dictionaries/analytics';
import { FC } from 'react';
import { ILSAnalyticsWidgetParamContainer } from '../../container/widget-param';

type Props = {
  widget: AnalyticsWidget;
  widgetParams: Array<BoardWidgetParams>;
  handleSetParams?: (params: IndexedArray<{ name: string; value: string | number | undefined }>) => void;
  savedParams: IndexedArray<{ name: string; value: string | number | undefined }>;
};
//блок параметров для виджета
export const ILSAnalyticsWidgetParams: FC<Props> = ({ widgetParams, widget, handleSetParams, savedParams }) => {
  return (
    <>
      {widgetParams.length > 0 &&
        widgetParams.map((param) => (
          <ILSAnalyticsWidgetParamContainer
            key={param.Name}
            {...param}
            widget={widget}
            widgetParam={param}
            handleSetParams={handleSetParams}
            savedParams={savedParams}
          />
        ))}
    </>
  );
};
