import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { getMaxSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';
import { SchemePayload } from '@modules/planning/children/scheme-editor/types/scheme';

export const getMaxSchemeReducer = createReducer(INITIAL_STATE, {
  [getMaxSchemeRoutine.REQUEST]: onRequest,
  [getMaxSchemeRoutine.SUCCESS]: (state, action: PayloadAction<SchemePayload>) => {
    const { scheme } = action.payload;
    state.maxScheme = scheme;
    return state;
  },
  [getMaxSchemeRoutine.FAILURE]: onFailure,
  [getMaxSchemeRoutine.FULFILL]: onFulfill
});
