import { Moment, MomentInput } from 'moment';
import { EMPTY_ARRAY_DATA, EMPTY_STRING, VATLabel, VATValue } from '@common/constants';
import {
  SeparateVehicle,
  SeparateVehicleLabel,
  TenderIconTitle,
  TenderType,
  TenderTypeLabel,
  TenderVisibilityStatus
} from '@modules/tender-platform/types';

// ------- TABS -------
export enum TenderContent {
  TenderList = 'Список тендеров',
  AddCarrier = 'Добавить перевозчика',
  CreateTender = 'Создать новый тендер',
  CarriersNotFound = 'Нет компании, отвечающей запросу',
  NoParticipants = 'Список участников пуст'
}

export enum TenderOwnTab {
  Active = 'Active',
  Over = 'Over',
  CarrierList = 'CarrierList'
}

export enum TenderOwnTabTitle {
  Active = 'Активные',
  Over = 'Завершенные',
  CarrierList = 'Список перевозчиков'
}

// ------- BUTTONS -------
export enum TenderButtonText {
  ConfirmSetStatus = 'Сменить статус',
  RateCarrier = 'Оставить отзыв',
  SendCarrierRating = 'Отзыв',
  DeleteLot = 'Удалить лот',
  ChangeLot = 'Изменить лот',
  AddLot = ' Добавить лот',
  ChangeOffer = 'Изменить предложение',
  CancelOffer = 'Отменить участие',
  AddOffer = 'Отправить предложение',
  Accept = 'Принять',
  Save = 'Сохранить',
  Cancel = 'Отменить',
  Back = 'Назад',
  Confirm = 'Подтвердить'
}

// ------- FORM -------
// Tender
export enum TenderFormContent {
  AddCarrier = 'Добавление в список аккредитованных перевозчиков',
  AddParticipants = 'Добавление участников в Тендер',
  SearchCarrier = 'Введите ИНН или наименованию организации для поиска...'
}

export enum TenderFormRuleMessage {
  MinCarrierRating = 'Пожалуйста, укажите Минимальный рейтинг компании Перевозчика для участия',
  MinDriverRating = 'Пожалуйста, укажите Минимальный рейтинг Водителя для участия'
}

export enum TenderFormField {
  // -- tender: backend --
  Name = 'Name',
  Type = 'Type',
  Private = 'Private',
  Description = 'Description',
  OfferStartDT = 'OfferStartDT',
  OfferEndDT = 'OfferEndDT',
  ExecutionStartDT = 'ExecutionStartDT',
  ExecutionEndDT = 'ExecutionEndDT',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRating = 'MinDriverRating',
  MinDriverRoutes = 'MinDriverRoutes',
  // -- lot: backend --
  ID = 'ID',
  IsTemplate = 'IsTemplate',
  TemplateName = 'TemplateName',
  MinPrice = 'MinPrice',
  StartPrice = 'StartPrice',
  SeparateVehicle = 'SeparateVehicle',
  VAT = 'VAT',
  VehicleLoadType = 'VehicleLoadType',
  Currency = 'Currency',
  Schedule = 'Schedule',
  SourceAddress = 'SourceAddress',
  SourceID = 'SourceID',
  Source = 'Source',
  SourceContact = 'SourceContact',
  TargetAddress = 'TargetAddress',
  TargetID = 'TargetID',
  Target = 'Target',
  TargetContact = 'TargetContact',
  TenderLotPosition = 'TenderLotPosition',
  TenderLotUser = 'TenderLotUser',
  VehicleType = 'VehicleType',
  RegionalCityRecipient = 'RegionalCityRecipient',
  RegionalCitySender = 'RegionalCitySender',
  Distance = 'Distance',
  // -- front-custom --
  Phase = 'Phase',
  Interval = 'interval',
  Dates = 'dates',
  Weekdays = 'weekdays',
  Documents = 'Documents'
}

export enum TenderFormLabel {
  Name = 'Имя тендера на Платформе',
  Type = 'Тип тендера',
  Private = 'Видимость тендера',
  Description = 'Краткое описание',
  OfferStartDT = 'Дата начала сбора предложений',
  OfferEndDT = 'Дата окончания сбора предложений',
  ExecutionStartDT = 'Дата начала исполнения договора',
  ExecutionEndDT = 'Дата окончания исполнения договора',
  MinCarrierRating = 'Минимальный рейтинг компании-перевозчика',
  MinDriverRating = 'Минимальный рейтинг водителя',
  MinDriverRoutes = 'Минимальное кол-во выполненных водителем рейсов'
}

export enum TenderFormTableField {
  Name = 'Name',
  Type = 'Type',
  Private = 'Private',
  Description = 'Description',
  OfferStartDT = 'OfferStartDT',
  OfferEndDT = 'OfferEndDT',
  ExecutionStartDT = 'ExecutionStartDT',
  ExecutionEndDT = 'ExecutionEndDT',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRating = 'MinDriverRating',
  MinDriverRoutes = 'MinDriverRoutes',
  Mass = 'Mass',
  Distance = 'Distance',
  RegionalCityRecipient = 'RegionalCityRecipient',
  RegionalCitySender = 'RegionalCitySender',
  RouteList = 'RouteList',
  StartPrice = 'StartPrice',
  MinPrice = 'MinPrice',
  AuctionStep = 'AuctionStep',
  Vat = 'Vat',
  VehicleLoadType = 'VehicleLoadType',
  VehicleType = 'VehicleType',
  SeparateVehicle = 'SeparateVehicle',
  Documents = 'Documents',
  Schedule = 'Schedule',
  CountShipments = 'CountShipments'
}

export enum TenderFormTableLabel {
  Name = 'Имя тендера на Платформе',
  Type = 'Тип тендера',
  Private = 'Видимость тендера',
  Description = 'Краткое описание',
  OfferStartDT = 'Дата начала сбора предложений',
  OfferEndDT = 'Дата окончания сбора предложений',
  ExecutionStartDT = 'Дата начала исполнения договора',
  ExecutionEndDT = 'Дата окончания исполнения договора',
  MinCarrierRating = 'Минимальный рейтинг компании-перевозчика',
  MinDriverRating = 'Минимальный рейтинг водителя',
  MinDriverRoutes = 'Минимальное кол-во выполненных водителем рейсов',
  Mass = `Максимальный вес на борту (кг.)`,
  Distance = 'Дистанция',
  RegionalCitySender = 'Откуда (направление)',
  RegionalCityRecipient = 'Куда (направление)',
  RouteList = 'Маршрут',
  StartPrice = 'Начальная цена руб',
  MinPrice = 'Минимальная цена руб',
  AuctionStep = 'Минимальный шаг торгов',
  Vat = 'С НДС/Без НДС',
  VehicleLoadType = 'Тип погрузки',
  VehicleType = 'Требуемый транспорт',
  SeparateVehicle = 'Требуется выделенный транспорт',
  CountShipments = 'Количество перевозок',
  Schedule = 'Даты',
  Documents = 'Документы'
}

export const TENDER_FORM_TABLE_FIELDS = Object.keys(TenderFormTableLabel) as Array<TenderFormTableField>;

export const TENDER_FORM_TABLE_REQUIRED_FIELDS = [
  TenderFormTableField.Name,
  TenderFormTableField.Type,
  TenderFormTableField.OfferStartDT,
  TenderFormTableField.OfferEndDT,
  TenderFormTableField.ExecutionStartDT,
  TenderFormTableField.ExecutionEndDT,
  TenderFormTableField.RegionalCitySender,
  TenderFormTableField.RegionalCityRecipient,
  TenderFormTableField.StartPrice,
  TenderFormTableField.MinPrice,
  TenderFormTableField.VehicleType
];

export const TENDER_FORM_TABLE_FIELDS_PROPERTIES: Record<
  TenderFormTableField,
  {
    editable: boolean;
    required: boolean;
    defaultValue: string | Moment | number | never[] | MomentInput;
    value: string | Moment | number | never[] | undefined;
    isZeroInvalid?: boolean;
  }
> = {
  [TenderFormTableField.Name]: { editable: true, defaultValue: EMPTY_STRING, required: true, value: EMPTY_STRING },
  [TenderFormTableField.Type]: { editable: true, defaultValue: TenderTypeLabel.Auction, required: true, value: TenderType.Auction },
  [TenderFormTableField.Private]: {
    editable: true,
    defaultValue: TenderIconTitle.Close,
    required: true,
    value: TenderVisibilityStatus.Close
  },
  [TenderFormTableField.Description]: { editable: true, defaultValue: EMPTY_STRING, required: false, value: EMPTY_STRING },
  [TenderFormTableField.OfferStartDT]: { editable: true, defaultValue: undefined, required: true, value: undefined },
  [TenderFormTableField.OfferEndDT]: { editable: true, defaultValue: undefined, required: true, value: undefined },
  [TenderFormTableField.ExecutionStartDT]: { editable: false, defaultValue: EMPTY_STRING, required: true, value: undefined },
  [TenderFormTableField.ExecutionEndDT]: { editable: false, defaultValue: EMPTY_STRING, required: true, value: undefined },
  [TenderFormTableField.MinCarrierRating]: { editable: true, defaultValue: 0, required: false, value: 0 },
  [TenderFormTableField.MinDriverRating]: { editable: true, defaultValue: 0, required: false, value: 0 },
  [TenderFormTableField.MinDriverRoutes]: { editable: true, defaultValue: 0, required: false, value: 0 },
  [TenderFormTableField.Mass]: { editable: false, defaultValue: EMPTY_STRING, required: false, value: 0 },
  [TenderFormTableField.Distance]: { editable: false, defaultValue: EMPTY_STRING, required: false, value: 0 },
  [TenderFormTableField.RegionalCitySender]: { editable: true, defaultValue: EMPTY_STRING, required: true, value: EMPTY_STRING }, //?? считаю editable: false
  [TenderFormTableField.RegionalCityRecipient]: { editable: true, defaultValue: EMPTY_STRING, required: true, value: EMPTY_STRING }, //?? считаю editable: false
  [TenderFormTableField.RouteList]: { editable: false, defaultValue: EMPTY_ARRAY_DATA, required: false, value: EMPTY_ARRAY_DATA },
  [TenderFormTableField.StartPrice]: { editable: true, defaultValue: 0, required: true, value: 0, isZeroInvalid: true },
  [TenderFormTableField.MinPrice]: { editable: true, defaultValue: 0, required: false, value: 0 },
  [TenderFormTableField.AuctionStep]: { editable: false, defaultValue: 0, required: false, value: 0 },
  [TenderFormTableField.Vat]: { editable: true, defaultValue: VATLabel.Yes, required: true, value: VATValue.Yes },
  [TenderFormTableField.VehicleLoadType]: {
    editable: true,
    defaultValue: EMPTY_STRING,
    required: false,
    value: EMPTY_STRING
  },
  [TenderFormTableField.VehicleType]: { editable: true, defaultValue: EMPTY_STRING, required: true, value: EMPTY_ARRAY_DATA },
  [TenderFormTableField.SeparateVehicle]: {
    editable: true,
    defaultValue: SeparateVehicleLabel.Yes,
    required: true,
    value: SeparateVehicle.Yes
  },
  [TenderFormTableField.Documents]: { editable: true, defaultValue: EMPTY_STRING, required: false, value: EMPTY_STRING },
  [TenderFormTableField.Schedule]: { editable: false, defaultValue: EMPTY_STRING, required: true, value: undefined },
  [TenderFormTableField.CountShipments]: { editable: false, defaultValue: 1, required: true, value: undefined }
};

export const DATE_HANDLERS = {
  OfferStartDT: (OfferStartDT: Moment | null) => ({ OfferStartDT }),
  OfferEndDT: (OfferEndDT: Moment | null) => ({ OfferEndDT }),
  ExecutionStartDT: (ExecutionStartDT: Moment | null) => ({ ExecutionStartDT }),
  ExecutionEndDT: (ExecutionEndDT: Moment | null) => ({ ExecutionEndDT })
};

export const TENDER_RATING_INPUTS = [TenderFormField.MinCarrierRating, TenderFormField.MinDriverRating, TenderFormField.MinDriverRoutes];
export const TENDER_DATE_PICKERS = [
  TenderFormField.OfferStartDT,
  TenderFormField.OfferEndDT,
  TenderFormField.ExecutionStartDT,
  TenderFormField.ExecutionEndDT
];

// ------- NOTIFY -------
// Tender
export enum TenderNotifyContent {
  SEARCH_CARRIERS_LEN = 'Введите более двух знаков или оставьте поле пустым'
}

export enum TenderNotifierAction {
  READ = 'READ',
  GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS',
  GET_DISTANCE = 'GET_DISTANCE',
  CHANGE_TEMPLATE = 'CHANGE_TEMPLATE',
  UPLOAD = 'UPLOAD',
  INDEX = 'INDEX',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  ACTION = 'ACTION',
  CHANGE = 'CHANGE',
  VEHICLE_TYPES = 'VEHICLE_TYPES',
  GET_VEHICLES_AND_DRIVERS_FOR_TENDER = 'GET_VEHICLES_AND_DRIVERS_FOR_TENDER',
  GET_VEHICLES_FOR_TENDER = 'GET_VEHICLES_FOR_TENDER',
  GET_DRIVERS_FOR_TENDER = 'GET_DRIVERS_FOR_TENDER',
  ADD_TENDER_FOR_ROUTE = 'ADD_TENDER_FOR_ROUTE',
  UPDATE_TENDER_FOR_ROUTE = 'UPDATE_TENDER_FOR_ROUTE',
  CREATE_TENDER_FOR_ROUTE = 'CREATE_TENDER_FOR_ROUTE',
  SET_RATING_ROUTINE = 'SET_RATING_ROUTINE'
}

export const DEFAULT_TENDER_MESSAGE = {
  loading: 'Загрузка данных...',
  success: 'Данные загружены',
  error: 'Ошибка загрузки'
};

export const DEFAULT_TENDER_MESSAGES: Record<
  TenderNotifierAction,
  {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  }
> = {
  [TenderNotifierAction.READ]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.GET_ACCOUNT_USERS]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.GET_DISTANCE]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.CHANGE_TEMPLATE]: {
    loading: 'Загрузка данных шаблона...',
    success: 'Шаблон загружен',
    error: 'Ошибка загрузки'
  },
  [TenderNotifierAction.UPLOAD]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.INDEX]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.DELETE]: {
    loading: 'Удаление документа...',
    success: 'Документ успешно удалён',
    error: 'Ошибка удаления'
  },
  [TenderNotifierAction.DOWNLOAD]: {
    loading: 'Загрузка документа в систему...',
    success: 'Документ загружен',
    error: 'Ошибка загрузки'
  },
  [TenderNotifierAction.ACTION]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.CHANGE]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.VEHICLE_TYPES]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.GET_VEHICLES_AND_DRIVERS_FOR_TENDER]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.GET_VEHICLES_FOR_TENDER]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.GET_DRIVERS_FOR_TENDER]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.ADD_TENDER_FOR_ROUTE]: {
    loading: 'Перенос маршрутов в форму создания...',
    success: 'Маршруты перенесены, заполните обязательные поля',
    error: 'Маршруты не перенесены'
  },
  [TenderNotifierAction.UPDATE_TENDER_FOR_ROUTE]: DEFAULT_TENDER_MESSAGE,
  [TenderNotifierAction.CREATE_TENDER_FOR_ROUTE]: {
    loading: 'Создание тендеров...',
    success: 'Тендеры успешно созданы',
    error: 'Ошибка создания тендеров'
  },
  [TenderNotifierAction.SET_RATING_ROUTINE]: {
    loading: 'Загрузка данных...',
    success: 'Данные отправлены! Спасибо за Ваш отзыв!',
    error: 'Ошибка загрузки'
  }
};

export enum TenderFromPlannerOperationButton {
  Save = 'SaveFromPlanner',
  SaveAndGoTo = 'SaveFromPlannerAndGoTo',
  SaveAndSetApprove = 'SaveFromPlannerAndSetApprove',
  SaveSetApproveAndGoTo = 'SaveFromPlannerSetApproveAndGoTo'
}

export enum TenderOperationButton {
  Rate = 'Rate',
  ConfirmSetExecutionStatus = 'ConfirmSetExecutionStatus',
  ConfirmSetCancellationStatus = 'ConfirmSetCancellationStatus',
  Cancel = 'Cancel',
  Back = 'Back'
}

export type TenderOperations = TenderOperationButton | TenderFromPlannerOperationButton;
export type TenderOperationsArgument = { operation: TenderOperations };
