import { createRoutine } from 'redux-saga-routines';

export enum CatalogActions {
  CREATE = 'CATALOG/CREATE',
  CREATE_LOCAL = 'CATALOG/CREATE_LOCAL',
  READ = 'CATALOG/READ',
  UPDATE = 'CATALOG/UPDATE',
  MULTIPLE_UPDATE = 'CATALOG/MULTIPLE_UPDATE',
  DELETE = 'CATALOG/DELETE',
  DELETE_LOCAL = 'CATALOG/DELETE_LOCAL',
  RESTORE = 'CATALOG/RESTORE',
  MAP = 'CATALOG/MAP',
  GET_COORDS = 'CATALOG/GET_COORDS',
  CLONE = 'CATALOG/CLONE',
  BIND_TIME_ZONE = 'CATALOG/BIND_TIME_ZONE',
  RESET_CATALOG_INFO = 'CATALOG/RESET_CATALOG_INFO',
  HANDLE_MOBILE_APPLICATION = 'CATALOG/HANDLE_MOBILE_APPLICATION',
  GET_TARIFF = 'CATALOG/GET_TARIFF',
  SET_FOCUS_ROW = 'CATALOG/SET_FOCUS_ROW'
}

export const catalogCreateRoutine = createRoutine(CatalogActions.CREATE);
export const catalogCreateLocalRoutine = createRoutine(CatalogActions.CREATE_LOCAL);
export const catalogReadRoutine = createRoutine(CatalogActions.READ);
export const catalogUpdateRoutine = createRoutine(CatalogActions.UPDATE);
export const catalogMultipleUpdateRoutine = createRoutine(CatalogActions.MULTIPLE_UPDATE);
export const catalogDeleteRoutine = createRoutine(CatalogActions.DELETE);
export const catalogDeleteLocalRoutine = createRoutine(CatalogActions.DELETE_LOCAL);
export const catalogRestoreRoutine = createRoutine(CatalogActions.RESTORE);
export const catalogMapRoutine = createRoutine(CatalogActions.MAP);
export const catalogGetCoordsRoutine = createRoutine(CatalogActions.GET_COORDS);
export const catalogCloneRoutine = createRoutine(CatalogActions.CLONE);
export const catalogBindTimeZoneRoutine = createRoutine(CatalogActions.BIND_TIME_ZONE);
export const resetCatalogInfoRoutine = createRoutine(CatalogActions.RESET_CATALOG_INFO);
export const catalogSetFocusRowRoutine = createRoutine(CatalogActions.SET_FOCUS_ROW);

export const getTariffRoutine = createRoutine(CatalogActions.GET_TARIFF);

/** Driver table buttons for Mobile Application */
export const catalogHandleMobileApplicationActionRoutine = createRoutine(CatalogActions.HANDLE_MOBILE_APPLICATION);

/**Рутины для которых нужно отображать уведомления */
export const catalogRoutinesWithNotifier = [
  catalogReadRoutine,
  catalogCreateLocalRoutine,
  catalogUpdateRoutine,
  catalogDeleteRoutine,
  catalogDeleteLocalRoutine,
  catalogRestoreRoutine,
  catalogGetCoordsRoutine,
  catalogCreateRoutine,
  catalogCloneRoutine,
  catalogBindTimeZoneRoutine,
  catalogHandleMobileApplicationActionRoutine,
  getTariffRoutine
];
