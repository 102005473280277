import { FC, useEffect, useState } from 'react';
import { ILSDropdown } from '@components/navigation/dropdown';
import { ILSMenu } from '@components/navigation/menu';
import { ILSButton } from '@components/index';
import { ILSDropdownCellButtonProps } from '@components/general/buttons/types';

export const ILSDropdownClickableCellButton: FC<ILSDropdownCellButtonProps> = ({ record, dataIndex }) => {
  if (!dataIndex || !record[dataIndex]) return null;

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (!opened) {
      setOpenedKeys([]);
    }
  }, [opened]);

  const { items, dropdown, button, icon } = record[dataIndex];
  const openKeys = record[dataIndex]?.openKeys || [];

  const [openedKeys, setOpenedKeys] = useState<string[]>([]);

  const onOpenChange = (openedKeys: string[]) => {
    if (opened) {
      setOpenedKeys([...openKeys, ...openedKeys]);
    }
  };

  const clear = () => {
    setOpened(false);
    setOpenedKeys([]);
  };

  const menu = (
    <ILSMenu
      openKeys={openedKeys}
      onClick={(e) => {
        //@ts-ignore
        if (e.domEvent.target.localName === 'span' && e.domEvent.target.innerText.trim() === 'Отправить') {
          clear();
        }

        if (openKeys?.includes(e.key)) {
          setOpenedKeys((prev) => [...prev, ...openKeys]);
        } else {
          clear();
        }
      }}
      onOpenChange={onOpenChange}
      items={items}
    />
  );

  const handleVisibleChange = (flag: boolean) => {
    setOpened(flag);
  };

  return (
    <ILSDropdown
      visible={opened}
      onVisibleChange={handleVisibleChange}
      overlay={menu}
      placement="bottomLeft"
      trigger={['click']}
      openClassName={'keep-open'}
      {...dropdown}
    >
      <ILSButton
        size={'small'}
        type={'text'}
        icon={icon && <img alt="icon" className="svg-container" src={icon as string} />}
        {...button}
      />
    </ILSDropdown>
  );
};
