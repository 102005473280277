import { ID, IndexedArray } from '@common/types';
import { isArray, isNil, isObject, isString } from 'lodash';

export const createIndexedArrayFromArray = <T extends { ID: ID }>(
  data?: string | IndexedArray<T> | Array<T> | null
): IndexedArray<T> | {} => {
  if (isNil(data) || isString(data)) return {};

  return isObject(data) && !isArray(data)
    ? data
    : data.reduce((prev, item) => {
        if ('ID' in item) {
          prev[item.ID] = item;
        }
        return prev;
      }, {} as IndexedArray<T>);
};
