import { useEffect } from 'react';
import { IMapTrack, PlanStatus, ReactDispatch, TrackActiveData } from '@common/types';
import { PolylineItem } from '../../types/map-container';

const EXTRA_WIDTH_OF_ACTIVE_TRACK = 2;
const DEFAULT_TRACK_WIDTH = 3;
const DEFAULT_TRACK_COLOR = '#4C91FF';
const MULT_TRACK_FOR_WIDE = 2;
const MULT_TRACK_FOR_THIN = 3;
const MULT_TRACK_SECOND = 5;

export const useMapDragPolyStyle = (
  mapPolylines: Record<string, PolylineItem>,
  setMapPolylines: ReactDispatch<Record<string, PolylineItem>>,
  polylineDataIDs: TrackActiveData,
  markerDragging: boolean | undefined
) => {
  useEffect(() => {
    if (markerDragging && polylineDataIDs?.data) {
      const newPolylines: Record<string, PolylineItem> = {};
      let update = false;
      Object.keys(mapPolylines).forEach((trackKey) => {
        const mItem = mapPolylines[trackKey];
        if (
          mItem?.poly?.tripStatus &&
          isEqualTrackClick(mItem.poly.clickData, polylineDataIDs.data) &&
          (mItem.poly.tripStatus === PlanStatus.Open || mItem.poly.tripStatus === PlanStatus.AdditionsOnly)
        ) {
          if (!update) update = true;
          newPolylines[trackKey] = {
            ...mItem,
            isDragOver: true
          };
          const dashedArr = mItem.poly.weight
            ? [
                mItem.poly.weight < DEFAULT_TRACK_WIDTH ? mItem.poly.weight * MULT_TRACK_FOR_THIN : mItem.poly.weight * MULT_TRACK_FOR_WIDE,
                mItem.poly.weight * MULT_TRACK_SECOND
              ]
            : [];

          mItem.lPoly?.setStyle({
            color: mItem.poly.color ?? DEFAULT_TRACK_COLOR,
            weight: (mItem.poly.weight ?? DEFAULT_TRACK_WIDTH) + EXTRA_WIDTH_OF_ACTIVE_TRACK,
            dashArray: mItem.poly.dashed ? dashedArr.join(', ') : undefined
          });
        } else {
          if (mItem?.isDragOver) {
            if (!update) update = true;
            newPolylines[trackKey] = {
              ...mItem,
              isDragOver: false
            };
            const dashedArr = mItem.poly.weight
              ? [
                  mItem.poly.weight < DEFAULT_TRACK_WIDTH
                    ? mItem.poly.weight * MULT_TRACK_FOR_THIN
                    : mItem.poly.weight * MULT_TRACK_FOR_WIDE,
                  mItem.poly.weight * MULT_TRACK_SECOND
                ]
              : [];

            mItem.lPoly?.setStyle({
              color: (mItem.poly.color ?? DEFAULT_TRACK_COLOR) + 'B3',
              weight: mItem.poly.weight ?? DEFAULT_TRACK_WIDTH,
              dashArray: mItem.poly.dashed ? dashedArr.join(', ') : undefined
            });
          } else {
            newPolylines[trackKey] = mItem;
          }
        }
      });
      if (update) setMapPolylines(newPolylines);
    } else {
      const newPolylines: Record<string, PolylineItem> = {};
      let update = false;

      Object.keys(mapPolylines).forEach((trackKey) => {
        const mItem = mapPolylines[trackKey];
        if (mItem?.isDragOver) {
          if (!update) update = true;
          newPolylines[trackKey] = {
            ...mItem,
            isDragOver: false
          };
          const dashedArr = mItem.poly.weight
            ? [
                mItem.poly.weight < DEFAULT_TRACK_WIDTH ? mItem.poly.weight * MULT_TRACK_FOR_THIN : mItem.poly.weight * MULT_TRACK_FOR_WIDE,
                mItem.poly.weight * MULT_TRACK_SECOND
              ]
            : [];

          mItem.lPoly?.setStyle({
            color: (mItem.poly.color ?? DEFAULT_TRACK_COLOR) + 'B3',
            weight: mItem.poly.weight ?? DEFAULT_TRACK_WIDTH,
            dashArray: mItem.poly.dashed ? dashedArr.join(', ') : undefined
          });
        } else {
          newPolylines[trackKey] = mItem;
        }
      });
      if (update) setMapPolylines(newPolylines);
    }
  }, [polylineDataIDs, markerDragging]);
};
export const isEqualTrackClick = (a: IMapTrack['clickData'], b: IMapTrack['clickData']) => {
  if (a && b) {
    if (a === b) return true;
    if (a.PlanID === b.PlanID && a.VehicleID === b.VehicleID && a.TripID === b.TripID) return true;
  }
  return false;
};
