import { FC } from 'react';
import cn from 'classnames';
import { ILSSVG } from '@common/components/custom/icons/svg';
import { ILSButton } from '@common/components';
import { DetailsRadioButtonType, DETAILS_CONTENT } from '@modules/orders-and-trips/types/details';

interface IProps {
  button: DetailsRadioButtonType;
  detailsContent: DETAILS_CONTENT;
  handleChangeDetailsComponent: (value: DETAILS_CONTENT) => void;
}

export const DetailsContentButton: FC<IProps> = ({ button, detailsContent, handleChangeDetailsComponent }) => {
  const onClickChangeDetailsContent = () => handleChangeDetailsComponent(button.value);
  return (
    <ILSButton
      className={cn('details-content-button', { active: button.value === detailsContent })}
      icon={<ILSSVG config={button?.iconConfig} icon={button.iconName} />}
      onClick={onClickChangeDetailsContent}
      key={button.value}
    >
      {button.label}
    </ILSButton>
  );
};

