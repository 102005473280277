import { DetailsBddContainer } from '../containers/details/details-bdd';
import { DetailsPlanFactContainer } from '../containers/details/details-plan-fact';
import { DetailsSensorContainer } from '../containers/details/details-sensor';
import { DetailsTrackContainer } from '../containers/details/details-track';
import { EventDetailsTab } from '../types';
import { TabType } from '../types/event-details-tabs';

/**Табы которые оторбражаются в детализации модуля monitoring/event-details */
export const detailsTabs: Array<EventDetailsTab> = [
  {
    key: TabType.Track, // Ключ
    label: 'Маршрут', // Заголовок
    initial: true, // Тот который отображается первым
    component: DetailsTrackContainer // Соответствующий компонент
  },
  {
    key: TabType.PlanFact,
    label: 'План/факт',
    component: DetailsPlanFactContainer
  },
  {
    key: TabType.Sensor,
    label: 'Датчики',
    component: DetailsSensorContainer
  },
  {
    key: TabType.Bdd,
    label: 'БДД',
    component: DetailsBddContainer
  }
];
