import { IndexedArray } from '@common/types';

export enum TenderOutServices {
  Ati_su = 'atu.su'
}

export enum TenderOutFastDeliveryServices {
  Gruzovichkof = 'gruzovichkof',
  Sdek = 'sdek',
  Pek = 'pek',
  Dl = 'dl',
  Cse = 'cse',
  /** Не поддерживаем */
  // Yandex = 'yandexNDD',
  YandexExpress = 'yandexExpress',
  Dostavista = 'dostavista',
  BaikalService = 'baikal'
}

export enum IntegrationTypes {
  tenderOut = 'tenderOut',
  tenderOutFastDelivery = 'tenderOutFastDelivery'
}

export type IntegrationTypeService = {
  [IntegrationTypes.tenderOut]: TenderOutServices;
  [IntegrationTypes.tenderOutFastDelivery]: TenderOutFastDeliveryServices;
};

export type IntegrationAllServiceNames = TenderOutFastDeliveryServices; //| TenderOutServices;

export type Integrations = {
  [K in IntegrationTypes]: {
    name: string;
    services: Record<
      IntegrationTypeService[K],
      {
        class: string;
        name: string;
        version: string;
      }
    >;
  };
};

export type DecoratedIntegrationServices = {
  [K in IntegrationTypes]: Array<{ id: IntegrationAllServiceNames; name: string; img: string; active?: number }>;
};

export type ServicesResponseConvertingError = {
  message: string;
  data:
    | string
    | null
    | {
        Key: string;
        Value: Array<string>;
      };
};
export type ServicesResponseConvertingErrors = Array<ServicesResponseConvertingError> | null;

export type ServicesResponsePrice = {
  id: number;
  price: number | string;
  currency: string;
  description?: string;
  info: string;
  minDelivery: number;
  maxDelivery: number;
  unitDelivery: string;
};

export type ServicesResponsePrices = Array<ServicesResponsePrice> | IndexedArray<ServicesResponsePrice> | null;

export type PreCostServiceResponse = {
  name: IntegrationAllServiceNames;
  prices: ServicesResponsePrices;
  convertingErrors: ServicesResponseConvertingErrors;
  requestError: PreCostServiceResponse | null;
};
export type PreCostServiceType = Array<PreCostServiceResponse>;

export type PreCost = {
  [K in number]: {
    tenderId: number;
    services: PreCostServiceType;
  };
};

export type PreCostToDisplay = Record<
  IntegrationAllServiceNames,
  {
    data: Array<{
      priceID: number | string;
      description: string;
      cost: string;
    }>;
    errors: boolean;
  }
>;
