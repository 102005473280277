import { Dictionary, Dragging, Keys, MarkerType, PlanStatus } from '@common/types';
import { ILSUserNotify } from '@components/index';
import { useAppDispatch } from '@core/hooks';
import { IDragDropContainer } from 'react-drag-drop-container';
import { Dispatch, SetStateAction } from 'react';
import { addOrderForVehicleRoutine, moveWaypointInTripRoutine, moveWaypointToTripRoutine } from '../actions';
import { PLANNING_RESULT_ERRORS } from '../constants';
import { compareAsString } from '@common/utils';
import { first } from 'lodash';
import { MoveWaypointInTripPayload } from '../sagas/trip/move-waypoint-in-trip';

//TODO record должен иметь такой же тип, как у record в DragHandler
export const useItemDrop = (
  activePlanID: number | null,
  setIsAfterDragging: Dispatch<SetStateAction<boolean>>,
  handleShowOrderTable: (val: boolean, activeRowID?: null) => void
) => {
  const dispatch = useAppDispatch();
  // TODO focus on enableAutoFocusOnChangeTrip
  //  const {
  //   parameters: { enableAutoFocusOnChangeTrip }
  //   } = useAppSelector(planningSettingsSelector);

  const onItemDrop = (e: IDragDropContainer<Dragging>, record: any) => {
    if (!record || (!e.dragData?.orderIDs?.length && !e.dragData?.waypointIDs?.length) || !e.dragData?.type) return false;
    const { dictionary, PlanID, VehicleID, TripID, WaypointID, Status } = record;
    const { type, orderIDs } = e.dragData;
    const planID = PlanID ?? activePlanID ?? null;
    const tripTargetStatus = (Status?.toString() as PlanStatus) ?? PlanStatus.Open;

    if (type === MarkerType.Order || type === MarkerType.OrderCluster) {
      const payload: {
        orderID: Keys;
        planID: number | null;
        vehicleID?: number | string | null;
        tripID?: number | string | null;
      } = {
        orderID: orderIDs || [],
        planID: planID ? Number(planID) : null
      };
      if (dictionary === Dictionary.Trip || dictionary === Dictionary.Waypoint) {
        payload.vehicleID = VehicleID;
        payload.tripID = TripID;
      } else {
        payload.vehicleID = VehicleID;
      }
      if (payload.planID && payload.orderID) {
        if (tripTargetStatus && tripTargetStatus !== PlanStatus.Open && tripTargetStatus !== PlanStatus.AdditionsOnly) {
          const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.TRIP_IS_LOCK;
          ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
        } else {
          dispatch(addOrderForVehicleRoutine(payload));
          setIsAfterDragging(true);
          // TODO focus enableAutoFocusOnChangeTrip &
          handleShowOrderTable(false);
        }
      }
    } else if (type === MarkerType.Point) {
      if (e.dragData?.waypointIDs?.length === 0 || compareAsString(e.dragData.originalID, WaypointID)) {
        const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.WAYPOINT_IN_TRIP;
        ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
      } else {
        const pointDragStatus = e.dragData?.tripStatus;
        const targetTripsIDs = e.dragData?.tripIds;

        //Для действий внутри рейса WaypointInTrip
        if (
          targetTripsIDs &&
          targetTripsIDs.length === 1 &&
          record.WaypointID &&
          targetTripsIDs[0] === Number(record.TripID) &&
          e.dragData.waypointIDs?.length === 1
        ) {
          if (tripTargetStatus && (PlanStatus.Open === tripTargetStatus || tripTargetStatus === PlanStatus.ReorderingOnly)) {
            const payload: MoveWaypointInTripPayload = {
              tripID: Number(record.TripID),
              targetWaypointID: record.WaypointID,
              sourceWaypointIDs: e.dragData.waypointIDs
            };
            dispatch(moveWaypointInTripRoutine(payload));
          } else {
            const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.TRIP_IS_LOCK;
            ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
          }
        } else if (
          tripTargetStatus &&
          tripTargetStatus !== PlanStatus.Open &&
          tripTargetStatus !== PlanStatus.AdditionsOnly &&
          tripTargetStatus !== PlanStatus.ReorderingOnly
        ) {
          const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.TRIP_IS_LOCK;
          ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
        } else if (pointDragStatus && pointDragStatus !== PlanStatus.Open) {
          const { TYPE, MESSAGE, DURATION, KEY } = PLANNING_RESULT_ERRORS.WAYPOINT_IS_LOCK;
          ILSUserNotify(TYPE, MESSAGE, DURATION, KEY);
        } else {
          //Для перемещения сторонней точки в другой рейс WaypointToTrip
          dispatch(
            moveWaypointToTripRoutine({
              tripID: TripID,
              waypointID: e.dragData.waypointIDs,
              planID
            })
          );
          // TODO focus enableAutoFocusOnChangeTrip &
        }
      }
    }
  };
  return { onItemDrop };
};

