import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { logoutActions } from '@modules/current-user/actions';

export const logoutReducer = createReducer(authInitialState, {
  [logoutActions.REQUEST]: onRequest,
  [logoutActions.FAILURE]: onFailure,
  [logoutActions.FULFILL]: onFulfill,
  [logoutActions.SUCCESS]: (state) => {
    state = authInitialState;
    return state;
  }
});
