export enum VehicleTableFilterStatus {
  mass = 'mass',
  volume = 'volume',
  pallets = 'pallets',
  TransportCompanyID = 'TransportCompanyID',
  ProjectVehicle = 'ProjectVehicle'
}

export enum VehicleTableFilterDesc {
  mass = 'Фильтровать по массе',
  volume = 'Фильтровать по объему',
  pallets = 'Фильтровать по паллетам',
  ProjectVehicle = 'Показать только автомобили проекта'
}

export type VehicleTableFilterFields = Record<VehicleTableFilterStatus, null | number>;
