import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { MarkerPlanning, PolygonPlanning } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { useSideAction } from '@core/utils/map/side-action';
import { deepEqual } from 'fast-equals';
import L, { LatLngExpression } from 'leaflet';
import { MutableRefObject, useEffect, useState } from 'react';
import { MapLayers, MarkerItem } from '../../types/map-container';
import { createLayerKey } from '@modules/planning/children/by-date/utils/create-layer-key';
import { addMarkerH } from '@modules/planning/children/by-date/utils/add-marker-helper';
import { isEmpty, isString } from 'lodash';

export const useMapLayersItems = (
  Map: MutableRefObject<any>,
  mapLayersGroup: MapLayers,
  layerData: {
    marker: Record<string, MarkerPlanning[]>;
    polygon: Record<string, PolygonPlanning[]>;
  }
) => {
  //Для контекстного меню слоев карты
  const [mapLayerMarkers, setMapLayerMarkers] = useState<Record<string, MarkerItem>>({});

  const [mapLayerPolygons, setMapLayerPolygons] = useState<
    Record<
      string,
      {
        poly: PolygonPlanning;
        lPoly: L.Polygon;
        layer: L.LayerGroup | undefined;
      }
    >
  >({});

  useEffect(() => {
    if (Map) {
      const curMap: ILSMap = Map.current;
      //функция добавления маркера в нужный слой
      // новый массив с текущими маркерами, которые на карте
      const mapMarkers_n: Record<
        string,
        {
          lMark: L.Marker;
          mark: MarkerPlanning;
          layer: L.MarkerClusterGroup | undefined;
        }
      > = {};
      let updateM = false;
      const empty = undefined;
      const { addToSide, runSideAction } = useSideAction(Map);
      const { addToSide: addToSideDelete, runSideAction: runSideDelete } = useSideAction(Map, true);

      const addMarker = (
        marker: MarkerPlanning & {
          selected?: boolean | undefined;
        }
      ) => addMarkerH(marker, mapLayersGroup, empty, curMap, addToSide);

      const addNewMarker = (marker: MarkerPlanning, layerID: string | number) => {
        if (!isEmpty(marker.coords)) {
          const layerKey = createLayerKey({ layerID, itemID: marker.ID });
          const addRes = addMarker(marker);
          if (!updateM) updateM = true;

          mapMarkers_n[layerKey] = {
            lMark: addRes.lMark,
            mark: marker,
            layer: addRes.layer
          };
        }
      };

      if (Object.keys(mapLayerMarkers).length === 0) {
        for (let layerID in layerData.marker) {
          layerData.marker[layerID].forEach((marker) => {
            addNewMarker(marker, layerID);
          });
        }
      } else {
        //Индексы маркеров, которые останутся на карте
        const mapMarkerCheckedKeys: string[] = [];

        for (let layerID in layerData.marker) {
          layerData.marker[layerID].forEach((marker) => {
            const layerKey = createLayerKey({ layerID, itemID: marker.ID });

            let mapMark = mapLayerMarkers[layerKey];
            if ((!mapMark || !deepEqual(mapMark.mark, marker)) && marker) {
              addNewMarker(marker, layerID);
            } else if (mapMark) {
              mapMarkerCheckedKeys.push(layerKey);
            }
          });
        }

        Object.keys(mapLayerMarkers).forEach((layerKey) => {
          const mItem = mapLayerMarkers[layerKey];
          if (!mapMarkerCheckedKeys.includes(layerKey)) {
            if (!updateM) updateM = true;
            addToSideDelete(mItem.lMark, mItem.layer);
          } else {
            mapMarkers_n[layerKey] = mItem;
          }
        });
      }
      if (updateM) {
        runSideAction();
        runSideDelete();
        setMapLayerMarkers(mapMarkers_n);
      }
    }
  }, [layerData.marker, mapLayersGroup.isInit]);

  useEffect(() => {
    if (Map) {
      const curMap: ILSMap = Map.current;

      const mapPolygon_n: Record<
        string,
        {
          poly: PolygonPlanning;
          lPoly: L.Polygon;
          layer: L.LayerGroup | undefined;
        }
      > = {};
      let updatePG = false;

      const addPoly = (polygon: PolygonPlanning, layerID: string | number) => {
        const layerKey = createLayerKey({ layerID, itemID: polygon.ID });
        const coords = isString(polygon.coords) ? GeohashDecodePoints(polygon.coords) : polygon.coords;
        if (curMap && !isEmpty(coords)) {
          if (!updatePG) updatePG = true;

          if (mapLayersGroup.polygonGroup && !isEmpty(coords)) {
            const lPoly = curMap.addPolygon(
              [
                coords as LatLngExpression[],
                { color: polygon.color },
                undefined,
                undefined,
                [`<div style="color:${polygon.color || 'black'};" >${polygon?.Name}</div>`, { permanent: true, direction: 'top' }]
              ],
              mapLayersGroup.polygonGroup ? mapLayersGroup.polygonGroup : undefined
            );
            mapPolygon_n[layerKey] = {
              poly: polygon,
              lPoly: lPoly,
              layer: mapLayersGroup.polygonGroup
            };
          }
        }
      };

      if (Object.keys(mapLayerPolygons).length === 0) {
        if (curMap) {
          if (layerData.polygon && Object.keys(layerData.polygon).length) {
            for (let layerID in layerData.polygon) {
              layerData.polygon[layerID].forEach((polygon) => {
                addPoly(polygon, layerID);
              });
            }
          }
        }
      } else {
        const mapPolygonCheckedKeys: string[] = [];

        for (let layerID in layerData.polygon) {
          layerData.polygon[layerID].forEach((polygon) => {
            const layerKey = createLayerKey({ layerID, itemID: polygon.ID });
            let mapPoly = mapLayerPolygons[layerKey];

            if ((!mapPoly || deepEqual(mapPoly.poly, polygon)) && polygon) {
              addPoly(polygon, layerID);
            } else if (mapPoly) {
              mapPolygonCheckedKeys.push(layerKey);
            }
          });
        }

        for (let layerKey in mapLayerPolygons) {
          const mItem = mapLayerPolygons[layerKey];

          if (!mapPolygonCheckedKeys.includes(layerKey)) {
            if (!updatePG) updatePG = true;
            if (mItem.layer) {
              mItem.layer.removeLayer(mItem.lPoly);
            } else {
              curMap.deleteAnyLayer(mItem.lPoly);
            }
          } else {
            mapPolygon_n[layerKey] = mItem;
          }
        }
      }
      if (updatePG) {
        setMapLayerPolygons(mapPolygon_n);
      }
    }
  }, [layerData.polygon, mapLayersGroup.isInit]);
};

export default { useMapLayersItems };

