import { ISchemeEditorState, SchemeFormValues, SchemeValues } from '@modules/planning/children/scheme-editor/types';
import { SchemeFormInitialValues } from '@modules/planning/children/scheme-editor/types/scheme';
import { Dictionary } from '@common/types';

export type GetActiveSwitchedValues = {
  currentSchemeKeysInDictionaries: ISchemeEditorState['currentSchemeKeysInDictionaries'];
  schemeInitialValues: SchemeFormInitialValues;
} & SchemeValues;

export const getActiveSwitchedValues = ({ values, currentSchemeKeysInDictionaries, schemeInitialValues }: GetActiveSwitchedValues) => {
  const scheme = {} as SchemeFormValues;
  for (let dictionary in values) {
    if (dictionary !== Dictionary.Options) {
      if (values[dictionary].active) {
        scheme[dictionary] = {};
        currentSchemeKeysInDictionaries?.[dictionary]?.forEach((key: string) => {
          if (Object.keys(values?.[dictionary]).length === 1) {
            scheme[dictionary][key] = { type: schemeInitialValues[dictionary][key].type };
          } else {
            scheme[dictionary][key] = values[dictionary][key];
          }
        });
      }
    } else {
      scheme[dictionary] = Object.keys(values[dictionary]).length > 1 ? values[dictionary] : schemeInitialValues[dictionary];
    }
    delete scheme?.[dictionary]?.['active'];
  }
  return { scheme };
};
