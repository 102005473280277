import { createRoutine } from 'redux-saga-routines';

export const PLANNER_DATA_ACTION_PREFIX = 'DATA';

export enum PlannerDataActionType {
  GET_PROJECTS = 'GET_PROJECTS',
  UPLOAD = 'UPLOAD',
  CLEAR = 'CLEAR',
  GET_UPLOAD_TEMPLATE = 'GET_UPLOAD_TEMPLATE',
  UPLOAD_CONFLICT = 'UPLOAD_CONFLICT',
  TABLE_CHANGE = 'TABLE_CHANGE',
  GET_UPLOADED_DATA = 'GET_UPLOADED_DATA',
  GET_PLANNING_CONFIGS = 'GET_PLANNING_CONFIGS',
  IMPORT = 'IMPORT',
  VALIDATE = 'VALIDATE',
  UPLOAD_TXT = 'UPLOAD_TXT'
}

const getActionType = (action: PlannerDataActionType) => {
  return `${PLANNER_DATA_ACTION_PREFIX}/${action}`;
};

export const getProjectsRoutine = createRoutine(getActionType(PlannerDataActionType.GET_PROJECTS));

export const uploadRoutine = createRoutine(getActionType(PlannerDataActionType.UPLOAD));

export const dataClearRoutine = createRoutine(getActionType(PlannerDataActionType.CLEAR));

export const getUploadTemplateRoutine = createRoutine(getActionType(PlannerDataActionType.GET_UPLOAD_TEMPLATE));

export const checkConflictRoutine = createRoutine(getActionType(PlannerDataActionType.UPLOAD_CONFLICT));

export const tableDataChangeRoutine = createRoutine(getActionType(PlannerDataActionType.TABLE_CHANGE));

export const getUploadedDataRoutine = createRoutine(getActionType(PlannerDataActionType.GET_UPLOADED_DATA));

export const dataImportRoutine = createRoutine(getActionType(PlannerDataActionType.IMPORT));

export const dataValidateRoutine = createRoutine(getActionType(PlannerDataActionType.VALIDATE));

/** Рутины для которых нужно отображать уведомления */
export const uploadRoutinesWithNotifier = [
  getProjectsRoutine,
  uploadRoutine,
  getUploadTemplateRoutine,
  checkConflictRoutine,
  tableDataChangeRoutine,
  getUploadedDataRoutine,
  dataImportRoutine,
  dataValidateRoutine
];
