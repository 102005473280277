import { dateToString } from '@modules/documents/helpers/date-to-string';
import { DateFormat } from '@common/types';
import { DocumentStatus, InboxSendDocument } from '@modules/documents/types/document-state';

export const decorateSendDate = (data: InboxSendDocument[]) => {
  return data?.map((doc, key) => ({
    ...doc,
    key,
    Created: dateToString(doc.Created, DateFormat.HHmmDDMMYYYYPeriod),
    Status: DocumentStatus[doc?.Status]
  }));
};
