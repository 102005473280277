import { DASH, SPACE } from '@common/constants';
import { Driver, Vehicle } from '@common/types';
import { TripContactPerson } from '@modules/orders-and-trips/types/trip';
import { FormLabels } from '@common/constants/general/content/form';

export const DetailsContacts = ({
  contactPerson,
  tripDriver,
  tripVehicle
}: {
  contactPerson: TripContactPerson;
  tripDriver: Driver | null;
  tripVehicle: Vehicle | null;
}) => {
  return (
    <div className="details-header__column">
      <div>
        <span className="details-header__title">{FormLabels.Contact}: </span>
        <span className="details-header__value">{contactPerson.name}</span>
      </div>
      <div>
        <span className="details-header__title">{FormLabels.Phone}: </span>
        <span className="details-header__value">{contactPerson.name}</span>
      </div>
      <hr />
      <div>
        <span className="details-header__title">Водитель: </span>
        <span className="details-header__value">{tripDriver?.Name ?? DASH}</span>
      </div>
      <div>
        <span className="details-header__title">Телефон водителя: </span>
        <span className="details-header__value">{tripDriver?.Phone ?? DASH}</span>
      </div>
      <div>
        <span className="details-header__title">Автомобиль: </span>
        <span className="details-header__value">{(tripVehicle?.Name ?? DASH) + SPACE + (tripVehicle?.RegNumber ?? DASH)}</span>
      </div>
      <div>
        <span className="details-header__title">Дополнительная информация об автомобиле: </span>
        <span className="details-header__value">{tripVehicle?.Comment ?? DASH}</span>
      </div>
    </div>
  );
};
