import classnames from 'classnames';
import { DataSource, Dictionary, MapItems, MapSettings, TableRecord } from '@common/types';
import { ILSButtonTooltip } from '@common/components';
import { MAP_SIZES } from '@core/constants/map';
import { ILSMap } from '@core/containers/map';
import { LControlDrawExt } from '@core/containers/map/draw-plugin';
import ILSCatalogLayerMenu from '@modules/catalog/components/map/layers';
import { MapLayer, MapProps } from '@modules/catalog/types/components';
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { LAYER_TYPES, useActiveLayers, useTableMap } from '../hooks/map';
import { useMapFocus } from '../hooks/use-map-focus';
import { useAppSelector } from '@core/hooks';
import { Marker, Polygon } from '@core/types/map';

const ALLOWED_DICTS_FOR_LAYERS: Dictionary[] = [
  Dictionary.Zone,
  Dictionary.CrossDock,
  Dictionary.Depot,
  Dictionary.ForbiddenZone,
  Dictionary.DeliveryZone,
  Dictionary.SurveillanceZone
];

export interface ITableMapProps {
  activeKeys?: (number | string)[] | null;
  catalogs?: {
    component: () => Element | ReactNode;
    dictionary: Dictionary;
    dontShow: boolean;
    id: number;
    name: string;
  }[];
  ruler?: boolean;
  dictionary?: Dictionary;
  markers?: Array<Marker>;
  polygons?: Array<Polygon>;
  dataSource?: DataSource;
  mapProps: MapProps;
  removeAddRow?: boolean;
  withPercent?: number;
  record?: TableRecord | any;

  handleSave(record: TableRecord, newProp: any): void;

  handleCreate?(dictionary: Dictionary, values: any, saveDictionary?: any): void;

  setRemoveAddRow?: Dispatch<SetStateAction<any>>;
  activeLayers?: Array<number>;
  setActiveLayers?: (_: Array<number>) => void;
  handleDictionaryLoad?: (dictionary: Dictionary) => void;
  catalogState?: any;
  catalogDepotData?: Array<any>;
}

export const ILSCatalogMapComponent: FC<ITableMapProps> = ({
  mapProps: { field, type, dontEdit, colorField },
  dictionary,
  record,
  dataSource,
  activeKeys,
  handleSave,
  handleDictionaryLoad,
  ruler
}) => {
  const catalogState = useAppSelector((state) => state.Catalog);
  const catalogDepotData = catalogState.Depot.data;
  const Map = useRef<ILSMap | null>(null);

  const [activeLayers, setActiveLayers] = useState<number[]>([]);
  const [mapLayersItems, setMapLayersItems] = useState<MapItems>({
    markers: [],
    polygons: []
  });
  const [mapTableItems, setMapTableItems] = useState<MapItems>({
    markers: [],
    polygons: []
  });
  const [layerData, setLayerData] = useState<MapLayer[]>([]);
  const [isMapEditActive, setIsMapEditActive] = useState<boolean>(false);
  const [mapDrawControl, setMapDrawControl] = useState<LControlDrawExt | undefined>(undefined);
  const [mapSettings, setMapSettings] = useState<MapSettings | undefined>(undefined);

  useEffect(() => {
    Map.current?.disableDrawing();
  }, [activeKeys]);

  //Отвечает за логику отображения и возможность редактирования
  useTableMap(
    record,
    dataSource,
    type,
    activeKeys,
    field,
    colorField,
    dontEdit,
    mapSettings,
    Map.current,
    mapDrawControl,
    mapTableItems,
    (settings) => setMapSettings(settings),
    (mapTableItems) => setMapTableItems(mapTableItems),
    (drawControl) => setMapDrawControl(drawControl),
    (isActiveEdit) => setIsMapEditActive(isActiveEdit),
    handleSave
  );

  //Для работы возможности отображать некоторые слои карты
  useActiveLayers(
    activeLayers,
    catalogState,
    catalogDepotData,
    mapLayersItems,
    mapTableItems,
    dictionary,
    (v) => setMapLayersItems(v),
    layerData,
    (d) => setLayerData(d),
    Map.current
  );
  useEffect(() => {
    if (!dictionary || !ALLOWED_DICTS_FOR_LAYERS.includes(dictionary)) {
      if (activeLayers.length) {
        setActiveLayers([]);
      }
    }
  }, [dictionary]);

  useMapFocus(record, dataSource, type, field, mapTableItems, Map.current);

  useEffect(() => {
    Map.current = new ILSMap();
    if (ruler) Map.current.addRuler();

    return () => Map.current?.deinit();
  }, []);

  const drawPolygon = (drawControl: LControlDrawExt) => {
    if (drawControl) {
      if (drawControl.drawPolygon()) setIsMapEditActive(true);
    }
  };

  const drawMarker = (drawControl: LControlDrawExt) => {
    if (drawControl) {
      if (drawControl.drawMarker()) setIsMapEditActive(true);
    }
  };

  const activeEdit = () => {
    if (mapDrawControl) {
      if (mapSettings?.draw) {
        if (mapSettings.type === LAYER_TYPES.POLYGON) {
          drawPolygon(mapDrawControl);
        } else if (mapSettings.type === LAYER_TYPES.MARKER) {
          drawMarker(mapDrawControl);
        }
      } else if (mapSettings?.edit) {
        if (mapDrawControl.activeEdit()) setIsMapEditActive(true);
      }
    }
  };

  return (
    <div className="map position-relative">
      <div className="map-container custom-draw-toolbar">
        <div id="ils-map" style={{ ...MAP_SIZES }} />
        <div className="ils-map-toolbar">
          {dictionary && ALLOWED_DICTS_FOR_LAYERS.includes(dictionary) && (
            <ILSCatalogLayerMenu
              layers={layerData}
              data={{
                marker: mapLayersItems.markers,
                polygon: mapLayersItems.polygons
              }}
              activeLayers={activeLayers}
              setActiveLayers={setActiveLayers}
              dictionary={dictionary}
              handleDictionaryLoad={handleDictionaryLoad}
            />
          )}
          {mapDrawControl && (
            <ILSButtonTooltip
              icon={
                <div className="leaflet-bar leaflet-draw-toolbar">
                  <a className="ils-toolbar-draw-icon leaflet-draw-edit-edit" />
                </div>
              }
              onClick={activeEdit}
              buttonClassName={classnames('map-control-btn', 'map-draw-btn', {
                'map-btn-top-3': dictionary && ALLOWED_DICTS_FOR_LAYERS.includes(dictionary),
                active: isMapEditActive
              })}
              placement="right"
              title="Включить режим редактирования"
            />
          )}
        </div>
      </div>
    </div>
  );
};
