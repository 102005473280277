import { FC, memo } from 'react';
import { TripActions } from '@modules/orders-and-trips/components/trip/trip-actions';
import { TripDetails } from '@modules/orders-and-trips/components/trip/trip-details';
import { TripOrdersContaner } from '@modules/orders-and-trips/containers/left/trips/trip-orders-container';
import classNames from 'classnames';
import { DecoratedTrips, TripStatus } from '@modules/orders-and-trips/types/trip';
import { ActiveRecord } from '@modules/orders-and-trips/types/general';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { useTripCard } from '@modules/orders-and-trips/hooks/use-trip-card';

interface IProps {
  trip: DecoratedTrips[number];
  activeTrip: ActiveRecord;
  onSetActiveTrip(ID: number): void;
  activeTripCssClass: string | null;
  vehicleTypes: VehicleType | null;
  tripStatus: TripStatus;
}

export const TripCardContainer: FC<IProps> = memo(({ vehicleTypes, tripStatus, activeTripCssClass, trip, activeTrip, onSetActiveTrip }) => {
  const { isActive } = useTripCard({ trip, activeTrip, tripStatus });
  return (
    <div
      className={classNames('trip-card', activeTripCssClass, {
        active: isActive
      })}
      onClick={() => onSetActiveTrip(trip.tripID)}
    >
      <TripActions trip={trip} />
      <TripDetails trip={trip} vehicleTypes={vehicleTypes} />
      <TripOrdersContaner trip={trip} />
    </div>
  );
});

