import { createRoutine } from 'redux-saga-routines';
import { ActiveRecordRoutine, SwitchTripStatusRoutine } from '@modules/orders-and-trips/types/action';

export const OrdersNTripsActionType = {
  init: 'ORDERS_AND_TRIPS/INIT',
  showForm: 'ORDERS_AND_TRIPS/SHOW_FORM',
  getUnresolvedOrders: 'DEMAND/ORDERS',
  getExtraData: 'DEMAND/EXTRA_DATA',
  getDepot: 'DEPOT/DEPOT_SHORT',
  getRestriction: 'RESTRICTION/RESTRICTION_ENUM',
  createOrder: 'DEMAND/ADD_UNALLOCATED_ORDER',
  updateOrder: 'DEMAND/UPDATE_ORDER',
  createTrip: 'DEMAND/CREATE_TRIP',
  activeRecord: 'ORDERS_AND_TRIPS/LAST_MODIFIED_RECORD',
  switchTripStatus: 'DEMAND/SWITCH_TRIPS_STATUS',
  getTripNOrderData: 'DEMAND/GET_TRIP_AND_ORDER_DATA',
  getTrips: 'DEMAND/GET_TRIPS_DATA',
  getVehicleTypes: 'VEHICLE_TYPES/VEHICLE_TYPES_INDEX',
  getTripsCount: 'DEMAND/GET_TRIPS_COUNT',
  getAvailableIntegrations: 'DEMAND/GET_AVAILABLE_INTEGRATIONS',
  getPreCost: 'DEMAND/GET_PRE_COST',
  addOrderToService: 'DEMAND/ADD_ORDER_TO_SERVICE',
  addOrderToServiceFromFake: 'DEMAND/ADD_ORDER_TO_SERVICE_FROM_FAKE',
  getTripDocuments: 'DEMAND/GET_TRIP_DOCUMENTS',
  uploadTripDocuments: 'DEMAND/UPLOAD_TRIP_DOCUMENTS',
  deleteTripDocument: 'DEMAND/DELETE_TRIP_DOCUMENT',
  getTripTimeline: 'DEMAND/GET_TRIP_TIMELINE',
  deleteOrder: 'DEMAND/DELETE_ORDER',
  cancelTrip: 'DEMAND/REMOVE_ORDER_FROM_SERVICE',
  deleteTrip: 'DEMAND/DELETE_TRIP',
  getRouteByCoordsRoutine: 'MAP_EDITOR/GET_SHORTEST',
  switchActiveMainTabRoutine: 'ORDERS_AND_TRIPS/SET_ACTIVE_MAIN_TAB',
  getTripContract: 'ORDERS_AND_TRIPS/GET_TRIP_CONTRACT',
  getFakeTripContract: 'ORDERS_AND_TRIPS/GET_FAKE_TRIP_CONTRACT',
  updateTrip: 'DEMAND/GET_TRIPS_DATA/ID',
  setActiveModalWindow: 'ORDERS_AND_TRIPS/SET_ACTIVE_MODAL_WINDOW',
  getTotalCount: 'DEMAND/GET_TOTAL_COUNT',
  deletePreCost: 'ORDERS_AND_TRIPS/DELETE_PRE_COST',
  importOrders: 'DEMAND/IMPORT_ORDERS',
  sendProjectLogisticsForm: 'DEMAND/SEND_PROJECT_LOGISTICS_FORM',
  getFakePreCost: 'ORDERS_AND_TRIPS/GET_FAKE_PRE_COST',
  clearState: 'ORDERS_AND_TRIPS/CLEARSTATE'
};

export const initRoutine = createRoutine(OrdersNTripsActionType.init);
export const clearStateRoutine = createRoutine(OrdersNTripsActionType.clearState);
/**Рутина для отображения форм*/
export const showFormRoutine = createRoutine(OrdersNTripsActionType.showForm);
export const getUnresolvedOrdersRoutine = createRoutine(OrdersNTripsActionType.getUnresolvedOrders);
export const getExtraDataRoutine = createRoutine(OrdersNTripsActionType.getExtraData);
export const getDepotRoutine = createRoutine(OrdersNTripsActionType.getDepot);
export const getRestrictionRoutine = createRoutine(OrdersNTripsActionType.getRestriction);
export const createOrderRoutine = createRoutine(OrdersNTripsActionType.createOrder);
export const updateOrderRoutine = createRoutine(OrdersNTripsActionType.updateOrder);
export const createTripRoutine = createRoutine(OrdersNTripsActionType.createTrip);
export const activeRecordRoutine = createRoutine<ActiveRecordRoutine>(OrdersNTripsActionType.activeRecord);
export const switchTripStatusRoutine = createRoutine<SwitchTripStatusRoutine>(OrdersNTripsActionType.switchTripStatus);
export const getTripNOrderDataRoutine = createRoutine(OrdersNTripsActionType.getTripNOrderData);
export const getTripsRoutine = createRoutine(OrdersNTripsActionType.getTrips);
export const getVehicleTypesRoutine = createRoutine(OrdersNTripsActionType.getVehicleTypes);
export const getTripsCountRoutine = createRoutine(OrdersNTripsActionType.getTripsCount);
export const getAvailableIntegrationsRoutine = createRoutine(OrdersNTripsActionType.getAvailableIntegrations);
export const getPreCostRoutine = createRoutine(OrdersNTripsActionType.getPreCost);
export const getFakePreCostRoutine = createRoutine(OrdersNTripsActionType.getFakePreCost);
export const addOrderToServiceRoutine = createRoutine(OrdersNTripsActionType.addOrderToService);
export const addOrderToServiceFromFakeRoutine = createRoutine(OrdersNTripsActionType.addOrderToServiceFromFake);
export const getTripDocumentsRoutine = createRoutine(OrdersNTripsActionType.getTripDocuments);
export const uploadTripDocumentsRoutine = createRoutine(OrdersNTripsActionType.uploadTripDocuments);
export const deleteTripDocumentRoutine = createRoutine(OrdersNTripsActionType.deleteTripDocument);
export const getTripTimelineRoutine = createRoutine(OrdersNTripsActionType.getTripTimeline);
export const cancelTripRoutine = createRoutine(OrdersNTripsActionType.cancelTrip);
export const deleteOrderRoutine = createRoutine(OrdersNTripsActionType.deleteOrder);
export const deleteTripRoutine = createRoutine(OrdersNTripsActionType.deleteTrip);
export const getRouteByCoordsRoutine = createRoutine(OrdersNTripsActionType.getRouteByCoordsRoutine);
export const switchActiveMainTabRoutine = createRoutine(OrdersNTripsActionType.switchActiveMainTabRoutine);
export const getTripContractRoutine = createRoutine(OrdersNTripsActionType.getTripContract);
export const getFakeTripContractRoutine = createRoutine(OrdersNTripsActionType.getFakeTripContract);
export const updateTripRoutine = createRoutine(OrdersNTripsActionType.updateTrip);
export const setActiveModalWindowRoutine = createRoutine(OrdersNTripsActionType.setActiveModalWindow);
export const getTotalCountRoutine = createRoutine(OrdersNTripsActionType.getTotalCount);
export const deletePreCostRoutine = createRoutine(OrdersNTripsActionType.deletePreCost);
export const importOrdersRoutine = createRoutine(OrdersNTripsActionType.importOrders);
export const sendProjectLogisticsFormRoutine = createRoutine(OrdersNTripsActionType.sendProjectLogisticsForm);

export const OrdersNTripsRoutinesWithNotifier = [
  initRoutine,
  getDepotRoutine,
  getRestrictionRoutine,
  getUnresolvedOrdersRoutine,
  createOrderRoutine,
  createTripRoutine,
  getTripNOrderDataRoutine,
  getTripsRoutine,
  getVehicleTypesRoutine,
  getAvailableIntegrationsRoutine,
  getPreCostRoutine,
  addOrderToServiceRoutine,
  cancelTripRoutine,
  deleteTripRoutine,
  deleteOrderRoutine,
  updateOrderRoutine,
  deletePreCostRoutine,
  importOrdersRoutine,
  getFakePreCostRoutine,
  getFakeTripContractRoutine,
  addOrderToServiceFromFakeRoutine,
  sendProjectLogisticsFormRoutine
];
