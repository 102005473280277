import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { setBoardWidget } from '../actions';
import initialState from '../store';
import { handleEmptyReducer } from '@common/utils/helpers/reducers';

const handlers = handleEmptyReducer<AnalyticsPlanningStore>(setBoardWidget);

export const setBoardWidgetReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [setBoardWidget.SUCCESS]: (state, action: PayloadAction<{ data: IndexedArray<AnalyticsWidget> }>) => {
    const { data } = action.payload;

    state.Widgets = Object.values(state.Widgets)
      .filter((w) => !w.isNew)
      .reduce(
        (prev, cur) => {
          prev[cur.ID] = cur;

          return prev;
        },
        { ...data }
      );

    return state;
  }
});
