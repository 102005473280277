import { ILSSVG } from '@common/components/custom/icons/svg';
import { SVG, SVG_CONFIG } from '@modules/orders-and-trips/constants/general';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { FC } from 'react';

interface IProps {
  order: DecoratedOrders[number];
}

export const OrderCardDepots: FC<IProps> = ({ order }) => {
  return (
    <div className="order-card-cargo-depots depot">
      <span className="depot-source">{order.Source?.AddressDescription}</span>
      <span className="depot-direction">
        <ILSSVG config={SVG_CONFIG} icon={SVG.RIGHT_BOX} />
      </span>
      <span className="depot-target">{order.Target?.AddressDescription}</span>
    </div>
  );
};

