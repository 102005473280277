import { Dictionary } from '@common/types';
import {
  projectCommonDictionarySelector,
  projectDepotSelector,
  projectRampSelector
} from '@modules/planning/children/by-date/selectors/project';
import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';

export const planningRampTableComponentSelector = createSelector(
  projectDepotSelector,
  projectRampSelector,
  (state: PlanningByDateState) => projectCommonDictionarySelector(state, [Dictionary.Restriction, Dictionary.RampType]),
  (depotData, rampData, { Restriction, RampType }) => {
    const DepotID = createDepotCascaderOptions(depotData);

    const refData = { Restriction, RampTypeID: RampType, DepotID };
    return { refData };
  }
);
