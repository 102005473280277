import { PaymentBalanceHistoryCellType } from '@common/feature/payment/types/general';
import { PaymentBalanceHistoryCell } from '@common/feature/payment/components/payment-balance-history/cell/payment-balance-history-cell';

export const PaymentBalanceHistoryRow = ({ transaction }: { transaction: PaymentBalanceHistoryCellType[] }) => {
  return (
    <div className="payment-balance-history-row">
      {transaction.map((transactionCellData) => (
        <PaymentBalanceHistoryCell
          title={transactionCellData.content}
          className={transactionCellData.className}
          key={transactionCellData?.content}
        />
      ))}
    </div>
  );
};

