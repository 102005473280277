import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { DocFilesTypes } from '@modules/monitoring/types/file';
import { FC } from 'react';

export const ViewDoc: FC<{
  doc: {
    uri: string;
    fileType: string;
    fileName: string;
  };
}> = ({ doc }) => {
  let Viewer;
  switch (doc.fileType) {
    case DocFilesTypes.Doc:
    case DocFilesTypes.Docx:
    case DocFilesTypes.Xls:
    case DocFilesTypes.Xlsx:
      Viewer = <DocViewer style={{ height: '79vh' }} pluginRenderers={DocViewerRenderers} documents={[doc]} />;
      break;
    case DocFilesTypes.Png:
    case DocFilesTypes.Jpg:
    case DocFilesTypes.Jpeg:
    case DocFilesTypes.Bmp:
      Viewer = <img className={'img-viewer'} alt={doc.fileName} src={doc.uri} />;
      break;
    case DocFilesTypes.Pdf:
      Viewer = <iframe className={'pdf-viewer'} src={doc.uri} />;
      break;
    default:
      Viewer = null;
      break;
  }

  return Viewer;
};
