import { useAppDispatch, useAppSelector } from '@core/hooks';
import { referenceSelector } from '../selectors';
import ILSReferenceCollapseComponent from '../components/reference-collapse';
import '../styles.less';
import { referenceRoutine } from '../actions';
import { FC } from 'react';

const ILSReference: FC = () => {
  const dispatch = useAppDispatch();
  const { level1, level2, level3 } = useAppSelector(referenceSelector);

  const getArticles = (params: { cat1: number; cat2?: number; cat3?: number }) => {
    dispatch(referenceRoutine(params));
  };

  return (
    <div className="reference">
      <ILSReferenceCollapseComponent level1={level1} level2={level2} level3={level3} getArticles={getArticles} />
    </div>
  );
};

export default ILSReference;
