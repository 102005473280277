import { getSRCIconSVG } from '@common/decorators/path';
import { Module, ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import { tenderRules } from '@modules/tender-platform/abac/rules';
import TenderOwn from '@modules/tender-platform/children/tender-own';
import TenderSearch from '@modules/tender-platform/children/tender-search';
import Tender from './containers';
import { tenderReducer } from './reducers';
import * as sagas from './sagas';

export default defineModule({
  title: ModuleTitle.TenderPlatform,
  path: ModulePath.TenderPlatform,
  component: Tender,
  children: {
    TenderOwn,
    TenderSearch
  } as any,
  reducer: tenderReducer,
  sagas: getAllModuleSagas(sagas),
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG('/tender', { iconModule: '/sidebar' }),
  access: {
    rules: tenderRules,
    permissions: TENDER_PLATFORM_PERMISSIONS
  },
  permission: [TENDER_PLATFORM_PERMISSIONS.TENDER_OWN, TENDER_PLATFORM_PERMISSIONS.TENDER_SEARCH],
  deps: [Module.Common],
  persist: false
});
