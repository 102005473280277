import { TableColumnName, TableConfig, Vehicle } from '@common/types';

export const canEditVehicle = ({ IsAutomotive }: Vehicle, dataIndex: TableColumnName, vehicleTableConfig: TableConfig) => {
  switch (dataIndex) {
    case TableColumnName.FrontCouplingTypeID:
      return Boolean(!IsAutomotive);
    case TableColumnName.RoadTrain:
      return Boolean(IsAutomotive);
    default:
      return Boolean(vehicleTableConfig.columns.find((column) => column.dataIndex === dataIndex)?.editable);
  }
};
