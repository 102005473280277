import { VehicleTracker } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import moment from 'moment';

export const findCurrentActiveTracker = (trackers: VehicleTracker[], vehicleID: number | string | undefined) => {
  if (trackers.length && vehicleID) {
    const currentDT = moment().utc(false).unix();
    return trackers?.find((tracker) => {
      return (
        compareAsString(vehicleID, tracker.VehicleID) &&
        (!tracker.From || tracker.From <= currentDT) &&
        (!tracker.Till || tracker.Till > currentDT)
      );
    });
  }
  return undefined;
};
