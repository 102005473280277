import { ILSTooltip } from '@components/index';
import { IconOptions, Options, TableRecord } from '@common/types';
import { useAppSelector } from '@core/hooks';
import { planningSettingsSelector } from '@modules/settings/selectors';
import { getSRCIconSVG } from '@common/decorators/path';
import { ILSSelectCloseOnScroll } from '@components/data-display/table/components/ils-select-scroll-close';
import { IILSCellSelectProps } from '@common/types/components/table/cell';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { PlanningParameters } from '@modules/settings/types/store';

export const filterHideOptions = ({
  options,
  activeLockTooltip
}: {
  options?: IconOptions;
  activeLockTooltip?: PlanningParameters['activeLockTooltip'];
}): IILSSelectProps['options'] => {
  return (
    options?.reduce((prev: Options, { hide, value, icon, tooltip, disabled }) => {
      if (!hide) {
        if (icon) {
          prev.push({
            disabled,
            value: String(value),
            label: activeLockTooltip ? (
              <ILSTooltip placement="bottom" title={tooltip}>
                <img aria-disabled={disabled} className="svg-container" alt="icon" src={getSRCIconSVG(icon)} />
              </ILSTooltip>
            ) : (
              <img aria-disabled={disabled} className="svg-container" alt="icon" src={getSRCIconSVG(icon)} />
            )
          });
        }
      }
      return prev;
    }, []) ?? []
  );
};

export const ILSCellSelectIcon = <R extends TableRecord>({
  children,
  save,
  tableRef,
  toggleEdit,
  options
}: IILSCellSelectProps<R>): JSX.Element => {
  const [, defaultIcon] = children;
  //TODO ???? ILSCellSelectIcon не относится к списку только для планировщика
  const { parameters } = useAppSelector(planningSettingsSelector);

  const variants = filterHideOptions({ options, activeLockTooltip: parameters?.activeLockTooltip });

  const onChange = (val: string) => save(Number(val));

  return (
    <ILSSelectCloseOnScroll
      autoFocus
      open
      showSearch={false}
      dropdownMatchSelectWidth={false}
      showArrow={false}
      className={'icon-only'}
      tableRef={tableRef}
      onBlur={toggleEdit}
      onChange={onChange}
      value={String(defaultIcon)}
      options={variants}
    />
  );
};
