import { TenderFilter, TenderFilterMethod } from '@modules/tender-platform/children/tender-search/types/filter';
import { SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';

export const filterDatesEnum = {
  offersStart: 8,
  offersEnd: 9,
  executionStart: 10,
  executionEnd: 11
};

export const pickFiltersDate = (key: number, tendersFilters: Array<TenderFilter>) => {
  const filter = tendersFilters.find((filter) => filter.key === key);
  if (filter?.value) {
    return Number(filter.value) * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  } else {
    return 0;
  }
};

export const disableDateRange = ({
  current,
  tendersFilters,
  filterMethod,
  execution = false
}: {
  current: number;
  tendersFilters: Array<TenderFilter>;
  filterMethod: TenderFilterMethod;
  execution?: boolean;
}) => {
  const offersStartDate = pickFiltersDate(filterDatesEnum.offersStart, tendersFilters);
  const offersEndDate = pickFiltersDate(filterDatesEnum.offersEnd, tendersFilters);
  const executionStartDate = pickFiltersDate(filterDatesEnum.executionStart, tendersFilters);
  const executionEndDate = pickFiltersDate(filterDatesEnum.executionEnd, tendersFilters);

  const maxFilter = execution ? executionEndDate : offersEndDate;
  const minFilter = execution ? executionStartDate : offersStartDate;

  if (filterMethod === 'dateMin') {
    if (Boolean(maxFilter)) {
      return current > maxFilter;
    } else {
      return false;
    }
  } else if (filterMethod === 'dateMax') {
    if (Boolean(minFilter)) {
      return current < minFilter;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
