import { call, put, takeEvery } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { Dictionary } from '@common/types';
import { monitoringCatalogReadRoutine } from '@modules/monitoring/actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* worker(
  action: PayloadAction<{
    dictionary: Dictionary;
  }>
) {
  const { request, success, failure, fulfill } = monitoringCatalogReadRoutine;
  const { dictionary } = action.payload;

  try {
    yield put(request({ dictionary }));
    const {
      data: { data }
    } = yield call(DictionaryAPI.read, {
      dictionary
    });
    yield put(success({ data, dictionary }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* monitoringCatalogReadWatcher() {
  yield takeEvery(monitoringCatalogReadRoutine.trigger, worker);
}

