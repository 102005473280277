import { Module, ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { getPlanningSettings } from '@modules/settings/api';
import { ILSSettings } from '@modules/settings/containers/settings';
import { settingsReducer } from '@modules/settings/reducers';
import * as sagas from '@modules/settings/sagas';

export default defineModule([
  {
    title: ModuleTitle.Settings,
    path: ModulePath.Settings,
    component: ILSSettings,
    reducer: settingsReducer,
    inScope: [ModuleScope.SiderNav],
    icon: '/assets/icons/sidebar/settings.svg',
    sagas: getAllModuleSagas(sagas)
  },
  {
    title: ModuleTitle.PlanningSettings,
    name: Module.PlanningSettings,
    api: { getPlanningSettings }
  }
]);

