import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { ILSButtonTooltip } from '@common/components';
import { showQuestionsRoutine } from '@common/feature/questions/actions';
import { showQuestionsSelector } from '@common/feature/questions/selectors';

export const ShowQuestionsButton = () => {
  const showQuestions = useSelector(showQuestionsSelector);
  const dispatch = useDispatch();

  const changeOrdersAndTripsContainer = () => {
    dispatch(showQuestionsRoutine.success({ value: !showQuestions }));
  };

  return (
    <ILSButtonTooltip
      placement="bottomLeft"
      size="small"
      className="show-questions-button"
      icon={<QuestionCircleTwoTone size={25} />}
      title={showQuestions ? 'Заявки и рейсы' : 'Вопросы и ответы'}
      onClick={changeOrdersAndTripsContainer}
    />
  );
};

