import { createReducer } from '@reduxjs/toolkit';
import { cleanupDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';

export const cleanupDistanceMatrixReducer = createReducer(initialState, {
  [cleanupDistanceMatrixRoutine.REQUEST]: (state) => {
    state.matrix.loading = true;
    return state;
  },
  [cleanupDistanceMatrixRoutine.FULFILL]: (state) => {
    state.matrix.loading = false;
    return state;
  }
});
