import { TModulesObj } from '@core/types/models';
import Auth from './current-user';
import Logo from './logo';
import Header from './header';
import Title from './title';
import SideBar from './sidebar';
import Account from './account';
import Monitoring from './monitoring';
import Catalog from './catalog';
import Reference from './reference';
import Planning from './planning';
import Settings from './settings';
import Validation from './data-validate';
import Track from './track';
import PlanAnalytic from './plan-analytic';
import VehicleMonitoring from './vehicle-monitoring';
import Tender from './tender-platform';
import RoadGraph from './road-graph';
import AnalyticsPlanning from './analytics/planning';
import AnalyticsTender from './analytics/tender';
import Emulator from './emulator';
import Documents from './documents';
import Demand from './demand';
import Dashboard from './dashboard';
import OrdersAndTrips from './orders-and-trips';
import { Common } from '@common/feature';
import Payment from '@common/feature/payment';
import Questions from '@common/feature/questions';
import DemoOrdersAndTrips from './demo/children/orders-and-trips';

const Modules: TModulesObj = {
  Auth,
  Logo,
  Header,
  Title,
  SideBar,
  Validation,
  Common,
  Payment,
  Questions,
  /* Custom pages */
  DemoOrdersAndTrips,
  Track,
  VehicleMonitoring,
  PlanAnalytic,
  /* Custom pages END*/
  /* SidebarMenu */
  Dashboard,
  Demand,
  OrdersAndTrips,
  Catalog,
  Planning,
  Monitoring,
  Emulator,
  Tender,
  Documents,
  RoadGraph,
  AnalyticsPlanning,
  AnalyticsTender,
  Account,
  Reference,
  Settings
  /* SidebarMenu END */
};

export default Modules;
