import { int2color } from '@common/decorators/color';
import { ForbiddenZone } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const forbiddenZones2table = (forbiddenZones: Array<ForbiddenZone>) => {
  return createArrayFromIndexedArray(forbiddenZones).map((forbiddenZone, i) => {
    return {
      ...forbiddenZone,
      key: forbiddenZone?.ID ?? 'row' + i,
      Color: int2color(String(forbiddenZone?.Color))
    };
  });
};
