import { trackGetDepotsRoutine } from '@modules/track/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DictionariesEndpoint, Dictionary, ShowDeleted } from '@common/types';
import { DictionaryAPI } from '@common/api/common';
import history from '@common/utils/history';
import { DictionaryOperationRequest } from '@common/types/general/api/dictionary';

function* trackGetDepotsWorker() {
  const { request, success, failure, fulfill } = trackGetDepotsRoutine;

  try {
    const queryParams = new URLSearchParams(history.location.search);
    const projectID = Number(queryParams.get('project')) || false;

    yield put(request());

    const params: DictionaryOperationRequest = {
      getFullData: false,
      dictionary: Dictionary.Depot,
      projectID,
      ids: [],
      values: undefined as never,
      showDeleted: ShowDeleted.Yes,
      operation: DictionariesEndpoint.Index
    };

    const {
      data: { data: depots }
    } = yield call(DictionaryAPI.operation, params);

    yield put(success({ depots }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* trackGetDepotsWatcher() {
  yield takeEvery(trackGetDepotsRoutine.trigger, trackGetDepotsWorker);
}
