import { AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import { useAppSelector } from '@core/hooks';
import ILSAnalyticsListComponent from '@modules/analytics/common/components/list';
import { BOARD_SETS_TITLE, DEFAULT_BOARD_SETS_TITLE } from '@modules/analytics/common/constants';
import { useContext } from 'react';

import { AnalyticsTenderContext } from '../context';
import { boardSetsSelector } from '../selectors/board';

const ILSBoardSetsTenderListContainer = () => {
  const boardSets = useAppSelector(boardSetsSelector);

  const { handleSelectBoardSet } = useContext(AnalyticsTenderContext) ?? {};

  return (
    <ILSAnalyticsListComponent
      classNames={['boards-list', 'mt-2']}
      isFetching={boardSets.isFetching}
      data={boardSets.data}
      handleClick={(data) => handleSelectBoardSet(data as AnalyticsBoardSetModel)}
      selectedId={boardSets.selectedBoardSet}
      listTitle={BOARD_SETS_TITLE}
      defaultListItemTitle={DEFAULT_BOARD_SETS_TITLE}
    />
  );
};

export default ILSBoardSetsTenderListContainer;
