import moment, { Moment } from 'moment';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { FormFieldData, FormFieldNames, FormInputTypes, FormLabels } from '@modules/orders-and-trips/types/form';
import { VehicleBodyNameEnum, VehicleLoadType, VehicleLoadTypeLabel } from '@modules/orders-and-trips/types/vehicle';
import { FormType } from '@modules/orders-and-trips/types/general';
import { disableDateBeforeToday } from '@common/utils/helpers/date-time/disable-date-before-today';
import { disableTimeBeforeNow } from '@common/utils/helpers/date-time/disable-time-before-now';
import { ILSDatePicker } from '@common/components/data-entry/datepicker';
import { ILSTimePicker } from '@common/components/data-entry/timepicker';

export const FORM_CARGO_SIZE_SHORT_FIELDS = [
  {
    name: FormFieldNames.Height,
    label: LotFormFieldLabel.Height,
    placeholder: 'Введите высоту',
    required: true
  },
  {
    name: FormFieldNames.Length,
    label: LotFormFieldLabel.Length,
    placeholder: 'Введите длину',
    required: true
  },
  {
    name: FormFieldNames.Width,
    label: LotFormFieldLabel.Width,
    placeholder: 'Введите ширину',
    required: true
  },
  {
    name: FormFieldNames.Mass,
    label: LotFormFieldLabel.Mass,
    placeholder: 'Введите массу',
    required: true
  },
  {
    name: FormFieldNames.Amount,
    label: LotFormFieldLabel.Amount,
    placeholder: 'Введите количество',
    required: true
  }
];

export const FORM_CARGO_SIZE_FIELDS = [
  ...FORM_CARGO_SIZE_SHORT_FIELDS,
  {
    name: FormFieldNames.Volume,
    label: LotFormFieldLabel.Volume,
    placeholder: 'Введите объем',
    required: false
  }
];

export const DEFAULT_SLIDER_RANGE_PARAMS = {
  DEFAULT_VALUE: 1,
  MIN: 1,
  MAX: 1000,
  STEP: 1
};

export const FORM_FIELD_DEFAULT_PROPS = {
  [FormInputTypes.Select]: {},
  [FormInputTypes.DateTime]: { picker: 'date', showTime: true },
  [FormInputTypes.InputNumber]: { type: 'number' },
  [FormInputTypes.InputText]: {},
  [FormInputTypes.TextArea]: {},
  [FormInputTypes.Slider]: {
    defaultValue: DEFAULT_SLIDER_RANGE_PARAMS.DEFAULT_VALUE,
    min: DEFAULT_SLIDER_RANGE_PARAMS.MIN,
    max: DEFAULT_SLIDER_RANGE_PARAMS.MAX,
    step: DEFAULT_SLIDER_RANGE_PARAMS.STEP
  }
};

export const DATE_TIME_FORM_FIELDS = [
  FormFieldNames.StartCollectOffer,
  FormFieldNames.EndCollectOffer,
  FormFieldNames.StartContract,
  FormFieldNames.EndContract
];
export const SLIDER_FORM_FIELDS = [FormFieldNames.MinCarrierRating, FormFieldNames.MinDriverRating, FormFieldNames.MinDriverRoutes];
export const TEXTAREA_FORM_FIELDS = [FormFieldNames.TenderDescription];
export const SELECT_FORM_FIELDS = [FormFieldNames.TenderType, FormFieldNames.TenderVisibility];
export const INPUT_TYPE_NUMBER_FORM_FIELDS = [FormFieldNames.MaxWeight, FormFieldNames.Distance];

export const TRIP_FOR_TENDER_FORM_FIELD_NAMES = [
  FormFieldNames.TenderName,
  ...SELECT_FORM_FIELDS,
  ...TEXTAREA_FORM_FIELDS,
  ...DATE_TIME_FORM_FIELDS,
  ...SLIDER_FORM_FIELDS,
  ...INPUT_TYPE_NUMBER_FORM_FIELDS,
  FormFieldNames.SourcePoint,
  FormFieldNames.TargetPoint
];

export const TRIP_FOR_TENDER_FORM_RULES = {
  [FormFieldNames.StartCollectOffer]: [{ required: true, message: 'Пожалуйста, выберите дату' }],
  [FormFieldNames.EndCollectOffer]: [{ required: true, message: 'Пожалуйста, выберите дату' }],
  [FormFieldNames.StartContract]: [{ required: true, message: 'Пожалуйста, выберите дату' }],
  [FormFieldNames.EndContract]: [{ required: true, message: 'Пожалуйста, выберите дату' }],
  [FormFieldNames.MinCarrierRating]: [],
  [FormFieldNames.MinDriverRating]: [],
  [FormFieldNames.MinDriverRoutes]: [],
  [FormFieldNames.TenderDescription]: [],
  [FormFieldNames.TenderType]: [{ required: true, message: 'Пожалуйста, выберите тип тендера' }],
  [FormFieldNames.TenderVisibility]: [{ required: true, message: 'Пожалуйста, выберите видимость тендера' }],
  [FormFieldNames.MaxWeight]: [],
  [FormFieldNames.Distance]: [],
  [FormFieldNames.TenderName]: [{ required: true, message: 'Пожалуйста, введите название тендера' }],
  [FormFieldNames.SourcePoint]: [{ required: true, message: 'Не выбран город отправителя' }],
  [FormFieldNames.TargetPoint]: [{ required: true, message: 'Не выбран город получателя ' }]
};

export const RULE_FOR_DATEPICKER_CURRENT_MOMENT = {
  validator: (_: unknown, value: Moment) =>
    !value?.isBefore(moment()) ? Promise.resolve() : Promise.reject('Пожалуйста, выберите дату и время позже текущего момента')
};

export const ORDER_AND_TRIP_FORM_RULES = {
  [FormFieldNames.SourceID]: [{ required: true, message: 'Выберите точку погрузки' }],
  [FormFieldNames.TargetID]: [{ required: true, message: 'Выберите точку разгрузки' }],
  [FormFieldNames.RestrictionID]: [],
  [FormFieldNames.Mass]: [{ required: true, message: 'Укажите массу груза' }],
  [FormFieldNames.Volume]: [],
  [FormFieldNames.Amount]: [{ required: true, message: 'Укажите количество' }],
  [FormFieldNames.Length]: [{ required: true, message: 'Укажите длину' }],
  [FormFieldNames.Width]: [{ required: true, message: 'Укажите ширину' }],
  [FormFieldNames.Height]: [{ required: true, message: 'Укажите высоту' }],
  [FormFieldNames.Comment]: [],
  [FormFieldNames.LoadDate]: [{ required: true, message: 'Пожалуйста, выберите дату' }, RULE_FOR_DATEPICKER_CURRENT_MOMENT],
  [FormFieldNames.LoadStart]: [],
  [FormFieldNames.LoadEnd]: [],
  [FormFieldNames.UnloadDate]: [{ required: false, message: 'Пожалуйста, выберите дату' }],
  [FormFieldNames.UnloadStart]: [],
  [FormFieldNames.UnloadEnd]: [],
  [FormFieldNames.VehicleType]: [{ required: true, message: 'Укажите тип транспортного средства' }],
  [FormFieldNames.VehicleUnloadType]: [{ required: true, message: 'Укажите тип разгрузки' }],
  [FormFieldNames.VehicleLoadType]: [{ required: true, message: 'Укажите тип погрузки' }],
  [FormFieldNames.Loading]: [],
  [FormFieldNames.GPSMonitoring]: [],
  [FormFieldNames.AmountVehicle]: [],
  [FormFieldNames.LoadCapacity]: [],
  [FormFieldNames.Belts]: [],
  [FormFieldNames.Contact]: [{ required: true, message: `Укажите ${FormLabels.Contact.toLowerCase()}` }],
  [FormFieldNames.Phone]: [{ required: true, message: 'Введите номер телефона' }],
  [FormFieldNames.Name]: [{ required: true, message: 'Пожалуйста, укажите наименование компании' }],
  [FormFieldNames.INN]: [{ required: true, message: 'Пожалуйста, укажите ИНН' }],
  [FormFieldNames.KPP]: [{ required: true, message: 'Пожалуйста, укажите КПП' }],
  [FormFieldNames.Contact]: [{ required: true, message: 'Пожалуйста, укажите контактное лицо' }],
  [FormFieldNames.Phone]: [{ required: true, message: 'Пожалуйста, укажите номер телефона' }]
};

export const TIME_FORM_FIELDS = [FormFieldNames.LoadEnd, FormFieldNames.LoadStart, FormFieldNames.UnloadEnd, FormFieldNames.UnloadStart];

export const CARGO_FIELDS = [
  FormFieldNames.Amount,
  FormFieldNames.Comment,
  FormFieldNames.Height,
  FormFieldNames.Length,
  FormFieldNames.Mass,
  FormFieldNames.Volume,
  FormFieldNames.Width,
  FormFieldNames.RestrictionID
];

export const DATE_TIME_SOURCE_BLOCK: FormFieldData[] = [
  {
    Component: ILSDatePicker,
    name: FormFieldNames.LoadDate,
    label: FormLabels.LoadDate,
    defaultProps: {
      showTime: true,
      disabledDate: disableDateBeforeToday,
      disabledTime: disableTimeBeforeNow
    },
    noDisplay: []
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.LoadStart,
    label: FormLabels.LoadStart,
    defaultProps: {
      allowClear: false
    },
    noDisplay: [FormType.UpdateOrder, FormType.CreateOrder, FormType.Trip]
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.LoadEnd,
    label: FormLabels.LoadEnd,
    defaultProps: {
      allowClear: false
    },
    noDisplay: [FormType.UpdateOrder, FormType.CreateOrder, FormType.Trip]
  }
];

export const DATE_TIME_TARGET_BLOCK: FormFieldData[] = [
  {
    Component: ILSDatePicker,
    name: FormFieldNames.UnloadDate,
    label: FormLabels.UnloadDate,
    noDisplay: [FormType.UpdateOrder, FormType.CreateOrder]
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.UnloadStart,
    label: FormLabels.UnloadStart,
    defaultProps: {
      allowClear: false
    },
    noDisplay: [FormType.UpdateOrder, FormType.CreateOrder]
  },
  {
    Component: ILSTimePicker,
    name: FormFieldNames.UnloadEnd,
    label: FormLabels.UnloadEnd,
    defaultProps: {
      allowClear: false
    },
    noDisplay: [FormType.UpdateOrder, FormType.CreateOrder]
  }
];

//TODO Вынести в общее + тендерная
export const LOAD_TYPE_OPTIONS = [
  { label: VehicleLoadTypeLabel.Up, value: VehicleLoadType.Up.toString() },
  { label: VehicleLoadTypeLabel.Side, value: VehicleLoadType.Side.toString() },
  { label: VehicleLoadTypeLabel.Back, value: VehicleLoadType.Back.toString() }
];

export const VEHICLE_LOAD_BY_TYPE = {
  [VehicleLoadType.Up]: VehicleLoadTypeLabel.Up,
  [VehicleLoadType.Back]: VehicleLoadTypeLabel.Back,
  [VehicleLoadType.Side]: VehicleLoadTypeLabel.Side
};

export const DEFAULT_VEHILCE_BODY_TYPE = '1';

export const DEFAULT_FORM_VALUES = {
  [FormFieldNames.VehicleUnloadType]: { label: VehicleLoadTypeLabel.Back, value: VehicleLoadType.Back.toString() },
  [FormFieldNames.VehicleLoadType]: { label: VehicleLoadTypeLabel.Back, value: VehicleLoadType.Back.toString() },
  [FormFieldNames.VehicleType]: { label: VehicleBodyNameEnum.Tent, value: DEFAULT_VEHILCE_BODY_TYPE }
};

export const MIN_NUMBER_INPUT_VALUE = 0;
export const DEFAULT_INPUT_STEP = 1;
export const CARGO_INPUT_STEP = 0.01;
