import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { startDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { MatrixStatus } from '../../types';

export const startDistanceMatrixReducer = createReducer(initialState, {
  [startDistanceMatrixRoutine.REQUEST]: (state) => {
    state.matrix.loading = true;
    return state;
  },
  [startDistanceMatrixRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: MatrixStatus | null;
    }>
  ) => {
    const { data } = action.payload;
    state.matrix.status = data;
    return state;
  },
  [startDistanceMatrixRoutine.FULFILL]: (state) => {
    state.matrix.loading = false;
    return state;
  }
});

