import { ILSMap } from '@core/containers/map';
import { IndexedArray } from '@common/types';
import { MonitoringVehicleEventsModel } from '@common/types/dictionaries/monitoring-vehicle';
import L, { LatLngBoundsExpression } from 'leaflet';
import { MutableRefObject, useContext, useEffect } from 'react';
import { MonitoringDetailsContext } from '@modules/monitoring/children/event-details/containers/context';
import {
  getTripsCoords,
  getWayPointsBounds,
  getWayPointsBoundsByWaypointId
} from '@modules/monitoring/children/event-details/hooks/helpers/get-fitbound-markers';
import { useGeoHash } from '@common/hooks/use-geoHash';

/**
 * Хук для фокусировки карты на маркерах/машине
 * @Map - карта
 * @cluster  -  кластер, если нужен
 * @vehicleEvents - данные которые приходят с сервера
 * @currentMarkers - текущие отображаемые маркеры
 * @setCurrentMarkers - метод обновления текущих маркеров для хранения в state
 */
export const useFitBoundsMarker = (args: {
  Map: MutableRefObject<ILSMap | null>;
  cluster?: L.LayerGroup;
  vehicleEvents: IndexedArray<MonitoringVehicleEventsModel>;
  trips?: MonitoringVehicleEventsModel['Trip'] & IndexedArray<{ ForbiddenZones?: number[] | null }>;
  selectedVehicleIDs?: (string | number)[];
  activeVehicleID?: string | number;
  selectedVehicleID?: string | number;
  vehicleFocusHandler?: (activeVehicleID: string | number) => void;
}) => {
  const { Map, cluster, vehicleEvents, selectedVehicleIDs, activeVehicleID, selectedVehicleID, vehicleFocusHandler, trips } = args;
  const { focusOnMap, waypointId, clearQueryParams } = useContext(MonitoringDetailsContext) || {};
  const curMap = Map.current;
  const cache = useGeoHash();

  const fitBounds = (map: ILSMap, bounds: (string | number | undefined)[][]) => {
    if (bounds.length != 0) {
      setTimeout(() => {
        map.fitBounds(bounds as LatLngBoundsExpression);
        clearQueryParams?.();
      }, 100);
    }
  };

  useEffect(() => {
    if (curMap && cluster && focusOnMap) {
      if (waypointId) {
        const waypointBounds = getWayPointsBoundsByWaypointId(waypointId, vehicleEvents);
        fitBounds(curMap, waypointBounds);
      } else {
        const tripsCoords = getTripsCoords(trips, cache);
        const wayPointsBounds = getWayPointsBounds(selectedVehicleIDs, activeVehicleID, selectedVehicleID, vehicleEvents);
        fitBounds(curMap, [...wayPointsBounds, ...tripsCoords]);
      }
    }
  }, [Map.current, cluster, vehicleEvents, selectedVehicleIDs, activeVehicleID, vehicleFocusHandler]);
};

