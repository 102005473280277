import { Dictionary, IndexedArray } from '@common/types';
import { assign, isEmpty } from 'lodash';
import { createIndexedArrayFromArray } from '@common/utils/helpers/array/create-indexed-array-from-array';
import { ProjectState } from '@common/types/dictionaries/project';

/**
 * Обновляет store справочников в проекте.
 * Мутирует projectState
 * @param projectState данные справочников, которые нужно обновить
 * @param data
 * @param dictionary
 * @param getFullData
 * @param dictionary
 * @param getFullData
 * @returns возвращает обновленные данные
 */
export const updateProjectData = ({
  projectState,
  data,
  dictionary,
  getFullData
}: {
  projectState: ProjectState;
  data: Array<any> | IndexedArray<any>;
  dictionary?: Dictionary;
  getFullData?: boolean;
}) => {
  if (!isEmpty(data)) {
    if (getFullData) {
      for (const dataDictionary in data) {
        projectState[dataDictionary] = assign({}, projectState[dataDictionary], createIndexedArrayFromArray(data[dataDictionary]));
      }
    } else if (dictionary) {
      projectState[dictionary] = assign({}, projectState[dictionary], createIndexedArrayFromArray(data));
    }
  }
  return projectState;
};
