import { changeTenderReducer } from '@modules/tender-platform/reducers/change-current-tender';
import { tenderGetAccountUsersReducer } from '@modules/tender-platform/reducers/get-account-users';
import { readTenderReducer } from '@modules/tender-platform/reducers/read-tender';
import { tenderActionReducer } from '@modules/tender-platform/reducers/tender-action';
import initialState from '@modules/tender-platform/store';
import reduceReducers from 'reduce-reducers';
import { changeTenderTemplateReducer } from './change-tender-template';
import { tenderDocumentDeleteReducer } from './document-delete';
import { getTenderDocumentsReducer } from './document-get';
import { tenderDocumentUploadReducer } from './document-upload';
import { tenderGetVehicleTypesReducer } from './get-vehicle-types';
import { tenderOfferDriverReducer } from './offer-driver';
import { tenderOfferVehicleReducer } from './offer-vehicle';
import { tenderGetDistanceReducer } from './get-distance';

export const tenderReducer = reduceReducers(
  initialState,
  tenderDocumentDeleteReducer,
  tenderDocumentUploadReducer,
  getTenderDocumentsReducer,
  tenderGetDistanceReducer,
  readTenderReducer,
  changeTenderTemplateReducer,
  tenderGetAccountUsersReducer,
  tenderActionReducer,
  changeTenderReducer,
  tenderGetVehicleTypesReducer,
  tenderOfferDriverReducer,
  tenderOfferVehicleReducer,
  tenderGetDistanceReducer
);

