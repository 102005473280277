import { Account, AccountSimple, Driver, TimeStamp, User, UserSimple, Vehicle } from '@common/types';
import { StatusConfig, Tender, TenderLot, TenderLotPosition } from '@modules/tender-platform/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderSummaryTableColumn } from '@modules/tender-platform/children/tender-own/utils/table-configs/summary';
import { TenderOfferFormItem } from '@modules/tender-platform/children/tender-search/constants/offer';
import { CarrierRating } from '../../tender-own/types/carriers';
import { DriverRating } from '@common/types/dictionaries/driver';

export type TenderOfferModel = {
  ID: number;
  DT: string;
  AccountID: Account['ID'];
  TenderID: Tender['ID'];
  TenderLotID: TenderLot['ID'];
  UserID: User['ID'];
  StatusID?: number | null;
  Price: number;
  Volume?: number | null;
  Accepted: AcceptedOffer | null;
  AllocatedVolume?: number | null;
  DriverID: Driver['ID'] | null;
  VehicleID: Vehicle['ID'] | null | number[];
  Vehicle?: Vehicle;
  CarrierRating: CarrierRating | null;
  CarrierRatingID: CarrierRating['ID'] | null;
  DriverRating: DriverRating | null;
  DriverRatingID: number | null;
};
//todo change type in Tender type on Array<TenderOffer> | TenderOffer and refactor code https://jira.intelogis.ru/browse/TMS-5882
export type TenderOffer = TenderOfferModel & {
  StatusConfig?: Partial<StatusConfig> | null;
  User?: User;
  Account?: AccountSimple;
  Driver: Partial<Driver>;
};

export type Summary = Record<TenderSummaryTableColumn, any> & { TenderLotID: TenderLot['ID'] };

export type TenderLotWithOffer = {
  Alias: StatusConfig['Alias'];
  Cargo: string;
  Contact: TenderLot['TargetContact'];
  Currency: TenderLot['Currency'];
  ID: TenderLot['ID'];
  IsTemplate: TenderLot['IsTemplate'];
  MinPrice: TenderLot['MinPrice'];
  Price: TenderOffer['Price'];
  Route: string;
  Schedule: TenderLot['Schedule'];
  SeparateVehicle: TenderLot['SeparateVehicle'];
  Source: TenderLot['Source'];
  SourceID: TenderLot['SourceID'];
  StartPrice: TenderLot['StartPrice'];
  StatusConfig: StatusConfig;
  TargetID: TenderLot['TargetID'];
  TemplateName: TenderLot['TemplateName'];
  TenderID: TenderOffer['TenderID'];
  TenderLot: TenderLot;
  TenderLotPosition: TenderLotPosition;
  TenderLotUser: TenderLot['TenderLotUser'];
  TenderOffer: TenderOffer;
  UserID: TenderOffer['UserID'];
  VAT: TenderLot['VAT'];
  VehicleLoadType: TenderLot['VehicleLoadType'];
  Volume: TenderOffer['Volume'];
};

export type TenderOfferFormValues = Record<TenderOfferFormItem, any>;

export enum AcceptedOffer {
  No,
  Yes
}

export enum AcceptedOfferLabel {
  No = 'Не выбран',
  Yes = 'Победитель'
}

export enum TextStatusTenderOffer {
  //New = 'Новое: создано предложение (Перевозчик)',
  Edited = 'Редактируется: создано предложение, вносятся изменения (Перевозчик)',
  UnderRevision = 'На доработке: требует изменений менеджером Перевозчика (Перевозчик)',
  OnApproval = 'На утверждении: утверждается администратором Перевозчика (Перевозчик)',
  Sent = 'Отправлено: доступно Грузовладельцу для учёта предложения в тендере (Перевозчик, Грузовладелец)',
  Return = 'Отозвано: предложение больше не учитывается в тендере (Перевозчик)',
  Received = 'Получено: Грузовладелец получил предложение по тендеру, предложение нельзя отозвать и менять (Перевозчик, Грузовладелец)'
}

export enum StatusTenderOffer {
  //New = 'New', //Новое 23
  Edited = 'Edited', //Редактируется 24
  UnderRevision = 'UnderRevision', //На доработке 25
  OnApproval = 'OnApproval', //На утверждении 26
  Sent = 'Sent', //Отправлено 27
  Return = 'Return', //Отозвано 28
  Received = 'Received' //Получено 29
}

export type TenderOfferRequest = {
  tenderID: Tender['ID'];
  tenderLotID: TenderLot['ID'];
  price: TenderOffer['Price'];
};

export type TenderOfferAction = PayloadAction<TenderOfferRequest>;

export type OfferAccepted = {
  Accepted: number;
  Account: AccountSimple;
  AccountID: number;
  AllocatedVolume: number;
  CarrierRatingID: number;
  DT: TimeStamp;
  DriverID: number | null;
  DriverRatingID: number | null;
  ID: number;
  Price: number;
  StatusID: number;
  TenderID: number;
  TenderLotID: number;
  User: UserSimple;
  UserID: number;
  VehicleID: number | null;
  Volume: number;
};
