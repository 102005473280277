import { HandleCheckCoordsArgType } from '@common/utils/table/handle-check-coords';
import { planningGetCoordsRoutine } from '@modules/planning/children/by-date/actions';
import { Dictionary, Keys } from '@common/types';
import { checkCoordinates } from '@common/utils/table/check-coordinates';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { GET_COORDS_ERROR } from '@common/constants/get-coords/get-coords-error';
import { Dispatch } from 'redux';

export const handleCoordinates =
  ({ dispatch, dataSource, dictionary }: { dispatch: Dispatch; dataSource: Array<any>; dictionary: Dictionary }) =>
  (recordID: Keys) => {
    const getCoordinates = (arg: HandleCheckCoordsArgType) => {
      dispatch(planningGetCoordsRoutine(arg));
    };

    try {
      checkCoordinates(recordID, dataSource, dictionary, getCoordinates);
    } catch (error) {
      showUserAndDevError({ error, userError: GET_COORDS_ERROR.Common });
    }
  };
