import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { getCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { Carriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { onFailure } from '@common/utils/helpers/reducers';

export const getCarriersReducer = createReducer(initialState, {
  [getCarriersRoutine.REQUEST]: (state) => {
    state.carriers.loading = true;
    return state;
  },
  [getCarriersRoutine.FAILURE]: onFailure,
  [getCarriersRoutine.FULFILL]: (state) => {
    state.carriers.loading = false;
    return state;
  },
  [getCarriersRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      carriers: Carriers;
    }>
  ) => {
    const { carriers } = action.payload;
    state.carriers.myCarriers = carriers;
    return state;
  }
});
