import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import AnalyticsTenderReports from './children/reports';
import AnalyticsTender from './children/tender-analytics';
import ILSAnalyticsTenderContainer from './container';

export default defineModule({
  title: ModuleTitle.AnalyticsTender,
  path: ModulePath.AnalyticsTender,
  component: ILSAnalyticsTenderContainer,
  children: { AnalyticsTender, AnalyticsTenderReports } as any,
  icon: '/assets/icons/sidebar/analytic.svg', //todo: extract,
  permission: [TENDER_PLATFORM_PERMISSIONS.TENDER_OWN, TENDER_PLATFORM_PERMISSIONS.TENDER_SEARCH],
  inScope: [ModuleScope.SiderNav]
});
