import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { demandGetCountsRoutine } from '../actions';
import { initialState } from '../store';
import { IDemandStore } from '../types/store';
import { GetCountResponse } from '../types/api';

// Записывает в стор количество проектов и тендерных лотов
const getDemandCountsReducer = createReducer(initialState, {
  [demandGetCountsRoutine.REQUEST]: (state: IDemandStore) => {
    state.isFetching = true;
    return state;
  },
  [demandGetCountsRoutine.SUCCESS]: (state: IDemandStore, action: PayloadAction<{ counts: GetCountResponse; params: {} }>) => {
    const { counts } = action.payload;

    state.demandMetrics = { ...state.demandMetrics, ...counts };
    state.lastUpdate = new Date().getTime();
    state.isFetching = false;
    return state;
  },
  [demandGetCountsRoutine.FAILURE]: (state: IDemandStore) => {
    state.isFetching = false;
    return state;
  },
  [demandGetCountsRoutine.FULFILL]: (state: IDemandStore) => {
    return state;
  }
});

export default getDemandCountsReducer;
