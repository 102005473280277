import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { questionsInitialState } from '@common/feature/questions/store';
import { showQuestionsRoutine } from '@common/feature/questions/actions';

export const showQuestionsReducer = createReducer(questionsInitialState, {
  [showQuestionsRoutine.REQUEST]: (state) => {
    return state;
  },
  [showQuestionsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      value: boolean;
    }>
  ) => {
    const { value } = action.payload;
    state.showQuestions = value;
    return state;
  },
  [showQuestionsRoutine.FAILURE]: (state) => {
    return state;
  },
  [showQuestionsRoutine.FULFILL]: (state) => {
    return state;
  }
});

