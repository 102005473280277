import { Dictionary } from '@common/types';
import { put } from 'redux-saga/effects';
import { catalogReadRoutine } from '@modules/catalog/actions';
import { refreshWithCurrentFilters } from './refresh-with-current-filters';

export function* refreshDictionary(dictionary: Dictionary) {
  switch (dictionary) {
    case Dictionary.Contact:
    case Dictionary.Worktime:
    case Dictionary.DepotOverload:
      yield refreshWithCurrentFilters(Dictionary.Depot);
      break;
    case Dictionary.Restriction:
      yield refreshWithCurrentFilters(Dictionary.Vehicle);
      yield refreshWithCurrentFilters(Dictionary.Depot);
      break;
    case Dictionary.VehicleTimeline:
      yield refreshWithCurrentFilters(Dictionary.Driver);
      yield refreshWithCurrentFilters(Dictionary.Vehicle);
      break;
    case Dictionary.VehicleOverload:
    case Dictionary.VehicleProfile:
      yield refreshWithCurrentFilters(Dictionary.Vehicle);
      break;
    case Dictionary.DriverTimeline:
      yield refreshWithCurrentFilters(Dictionary.Driver);
      break;
    case Dictionary.RestrictionGroup:
      yield put(catalogReadRoutine.trigger({ dictionary: Dictionary.Restriction, withoutNotification: true }));
      break;
    default:
      break;
  }
}
