import { createSelector } from 'reselect';
import {
  currentTenderSelector,
  currentTenderStatusConfigSelector,
  currentTenderTypeSelector
} from '@modules/tender-platform/selectors/current-tender';
import { FORMED_TENDERS } from '@modules/tender-platform/constants';
import { TenderStatus, TenderType } from '@modules/tender-platform/types';
import { tenderOffers2table } from '@modules/tender-platform/utils/decorators/offer-lots-to-table';
import { PARTICIPANT_STATUS_COLUMN } from '@modules/tender-platform/utils/table-config/tender-offer';
import { TableColumnName, TableConfig } from '@common/types';

export const offerTableCargoOwnerSelector = (config: TableConfig) =>
  createSelector(
    currentTenderSelector,
    currentTenderStatusConfigSelector,
    currentTenderTypeSelector,
    (tender, tenderStatusConfig, tenderType) => {
      const hasWinnerColumn = FORMED_TENDERS.includes(tender?.StatusConfig?.Alias as TenderStatus);
      const dataSource = tenderOffers2table(tender);

      const removeParticipantsColumns =
        tenderType === TenderType.Auction &&
        (tenderStatusConfig?.Alias === TenderStatus.SummingResults || tenderStatusConfig?.Alias === TenderStatus.ApprovalResults);
      let columns = config?.columns;
      if (hasWinnerColumn) {
        columns = config?.columns?.concat(PARTICIPANT_STATUS_COLUMN);
      }

      if (removeParticipantsColumns) {
        columns = columns?.filter((column) => column.dataIndex !== TableColumnName.Accepted);
      }
      return { dataSource, columns };
    }
  );
