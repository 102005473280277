import { Account, Options } from '@common/types';
import { EMPTY_STRING } from '@common/constants';

export const getCarriersOptions = ({ carriers, ids }: { carriers: [] | Account[]; ids: Set<number> }) =>
  (carriers as any).reduce((carriersOptions: Options, { ID, Name, Rating = 0, Info }: Account) => {
    if (ids.has(ID)) {
      return carriersOptions;
    }
    carriersOptions.push({
      value: ID,
      label: `${Name} ${Info?.INN ?? EMPTY_STRING} (${Rating})`
    });
    return carriersOptions;
  }, [] as Options);
