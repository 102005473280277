import { apiInstance } from '@core/api/instance';
import { ITenderSearchAPI } from '@modules/tender-platform/children/tender-search/types';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const TenderSearch: ITenderSearchAPI = {
  index: () => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.Index)),
  search: () => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Search)),
  tenderWithMyParticipation: () => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.withMyParticipation)),
  appointResponsible: ({ tenderOfferID, userID }) =>
    apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.AppointResponsible), {
      tenderOfferID,
      userID
    }),
  create: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.Create), { ...params }),
  update: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.Update), { ...params }),
  setStatus: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.SetStatus), { data: params }),
  delete: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.Delete), { ...params }),
  setNotifySettings: (params) => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.setNotificationParams), { ...params }),
  getNotifySettings: () => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.getNotificationParams))
};
