import ILSDocumentUploadForm from '@modules/documents/components/upload-form';
import ILSDocumentsFilter from '@modules/documents/components/filter';
import { DocumentPath } from '@modules/documents/types/documents-navigation-item';

export const showSidePanelRoutes = [DocumentPath.Upload, DocumentPath.All, DocumentPath.Archive, DocumentPath.Trash];

export const FilterRoutes = [
  {
    Path: DocumentPath.All,
    Filter: ILSDocumentsFilter
  },
  {
    Path: DocumentPath.Trash,
    Filter: ILSDocumentsFilter
  },
  {
    Path: DocumentPath.Archive,
    Filter: ILSDocumentsFilter
  },
  {
    Path: DocumentPath.Upload,
    Filter: ILSDocumentUploadForm
  }
];
