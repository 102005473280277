import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsStore } from '../types/store';

import initialState from '../store';
import { AnalyticsActions } from '../types/actions';
import { getWidgetParamName } from '@modules/analytics/common/utils/get-widget-param-name';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

export const setParamsCommonReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonSetWidgetParamsRoutine.REQUEST]: (state) => {
      return state;
    },
    [actions.analyticsCommonSetWidgetParamsRoutine.SUCCESS]: (
      state,
      action: PayloadAction<IndexedArray<{ name: string; value: string | number | undefined }>>
    ) => {
      const prevWidgets = { ...state.Widgets };
      const nextWidgets = Object.keys(prevWidgets).reduce((prev, key) => {
        //проверим обновились ли для этого виджета параметры
        const widgetParamsIntersect = Object.values(prevWidgets[key]?.Params || {}).some((param) =>
          Object.keys(action.payload).some((y) => y === (param.IsWidgetParam ? getWidgetParamName(param.Name, { ID: key }) : param.Name))
        );
        if (widgetParamsIntersect) {
          const nextWidget = {
            ...prevWidgets[key],
            loaded: false,
            error: false,
            Data: undefined
          };
          prev[key] = nextWidget;
        } else {
          prev[key] = prevWidgets[key];
        }

        return prev;
      }, {} as IndexedArray<AnalyticsWidget>);
      state.Widgets = nextWidgets;
      state.WidgetParams = { ...state.WidgetParams, ...action.payload };
      return state;
    },
    [actions.analyticsCommonSetWidgetParamsRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsCommonSetWidgetParamsRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
      return state;
    }
  });
