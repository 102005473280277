import { put, select, takeLatest } from 'redux-saga/effects';
import { getAccountRoutine } from '@modules/current-user/actions';
import { dataImportRoutine } from '@modules/planning/children/data/actions';
import { planningCreateProjectRoutine } from '@modules/planning/actions';

function* worker() {
  const projectCount: number | undefined = yield select((state) => state.Auth?.account?.ProjectCount);
  if (!projectCount) {
    yield put(getAccountRoutine.trigger());
  }
}

export function* afterCreateProjectWatcher() {
  yield takeLatest(dataImportRoutine.success, worker);
  yield takeLatest(planningCreateProjectRoutine.success, worker);
}
