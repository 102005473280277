import { call, put, takeLatest } from 'redux-saga/effects';
import { Auth } from '@modules/current-user/api/auth';
import { getAccountRoutine } from '@modules/current-user/actions';

function* getUserProjectsWorker() {
  const { request, success, failure, fulfill } = getAccountRoutine;
  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(Auth.index);
    yield put(success({ account: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getUserProjectsWatcher() {
  yield takeLatest(getAccountRoutine.trigger, getUserProjectsWorker);
}
