import { CellType, TableConfig } from '@common/types';

export const dealsTableConfig: TableConfig = {
  columns: [
    {
      title: 'Прикреп. файлы',
      dataIndex: 'Attachments',
      type: CellType.Icon
    },
    {
      title: 'Номер договора',
      dataIndex: 'Number',
      type: CellType.Input
    },
    {
      title: 'Грузоотправитель',
      dataIndex: 'Sender',
      type: CellType.Input
    },
    {
      title: 'Грузополучатель',
      dataIndex: 'Receiver',
      type: CellType.Input
    },
    {
      title: 'Стоимость договора',
      dataIndex: 'Cost',
      type: CellType.Input
    },
    {
      title: 'Дата создания',
      dataIndex: 'Created',
      type: CellType.Date
    },
    {
      title: 'Сопроводительные документы',
      dataIndex: 'Documents',
      type: CellType.Input
    },
    {
      title: 'Статус подписания',
      dataIndex: 'Cost',
      type: CellType.Input
    }
  ]
};
