import { settingsGetRoutine } from '@modules/settings/actions';
import { call, cancel, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { SettingsAPI } from '@modules/settings/api';
import { isEmpty } from 'lodash';
import { DEFAULT_PLANNER_PARAMS } from '@modules/settings/config/planning/default-planner-params';
import { DEFAULT_MONITORING_PARAMS } from '@modules/settings/config/monitoring/default-monitoring-params';
import { PayloadAction } from '@reduxjs/toolkit';
import { DefaultUserParams, UserParamsAll } from '../types/store';
import { DeepPartial, DeepRequired } from '@common/types/general';
import { deepEqual } from 'fast-equals';
import { getDiffSettings } from '@modules/settings/utils/get-diff-settings';
import { Task } from 'redux-saga';
import { settingsDataSelector } from '../selectors/account';
import { RequestParams } from '@common/constants/settings/api';

type SettingsPayloadAction = PayloadAction<{
  value?: DeepPartial<DefaultUserParams>;
  binding?: RequestParams;
  paramName?: RequestParams;
  forceUpdate?: boolean;
}>;

function* settingsGetWorker(action: SettingsPayloadAction) {
  const { request, success, failure, fulfill } = settingsGetRoutine;
  const { value, paramName, binding } = action.payload ?? {};

  try {
    yield put(request({ isFetching: true }));

    let userSettings: UserParamsAll;
    let payload = {
      binding: binding ?? RequestParams.BindingAccount,
      paramName: paramName ?? RequestParams.Settings,
      value: value ?? null
    };

    const {
      data: { data }
    } = yield call(SettingsAPI.getUserParams, { ...payload });

    userSettings = data;

    //NOTE: Настройки по умолчанию
    const defaultSettings = { ...DEFAULT_PLANNER_PARAMS, ...DEFAULT_MONITORING_PARAMS };

    //NOTE: Разница между дефолтными и текущими настройками
    let diffSettings = null;

    //Note: Если настройки аккаунта (пользователя) отсутствуют добавляем дефолтные
    if (isEmpty(userSettings.value)) {
      const {
        data: { data }
      } = yield call(SettingsAPI.createUserParams, {
        ...payload,
        value: defaultSettings
      });
      userSettings = data;
      //Note: Если настройки существуют, смотрим разницу между существующими настройками и дефолтными настройками
    } else if (!deepEqual(userSettings.value, defaultSettings)) {
      diffSettings = getDiffSettings(userSettings.value, defaultSettings);
    }
    yield put(success({ value: userSettings.value, diffSettings }));
  } catch (error) {
    yield put(failure({ error: error }));
  } finally {
    yield put(fulfill());
  }
}

/**
 *  settingsMiddleWareWorker - промежуточная saga для загрузки настроек (убирает побочные эффекты редиректов и при переключении модулей,
 *  когда загрузка настроек, может не отработать вовремя)
 *
 *  yield select(settingsDataSelector) - смотрит есть ли сохраненные настройки
 *  if (!isEmpty(currentSettings)) - если настройки существуют, выходим из цикла и завершаем таску
 * @param action
 * */
export function* settingsMiddleWareWorker(action: SettingsPayloadAction) {
  let lastTask: Task | null = null;
  const { forceUpdate } = action.payload ?? {};
  try {
    while (true) {
      if (forceUpdate) {
        yield fork(settingsGetWorker, action);
        break;
      }
      if (lastTask) {
        yield cancel(lastTask);
      }
      const currentSettings: DeepRequired<DefaultUserParams> = yield select(settingsDataSelector);
      if (!isEmpty(currentSettings)) {
        break;
      }
      lastTask = yield fork(settingsGetWorker, action);
      yield delay(3000);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* settingsGetWatcher() {
  yield takeLatest(settingsGetRoutine.trigger, settingsMiddleWareWorker);
}

