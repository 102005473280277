import { EMPTY_STRING } from '@common/constants';
import { logisticServicesGroupList, logisticServicesTitles } from '@modules/current-user/constants/logistic-servises';
import { LogisticServicesColumn } from '@modules/current-user/components/logistic-services/list/logistic-services-column';

export const LogisticServicesList = () => {
  return (
    <div className="logistic-services__list">
      {logisticServicesGroupList.map((serviceGroup) => (
        <div className="logistic-services__group">
          <div className="logistic-services__group-title" key={serviceGroup}>
            <p>{logisticServicesTitles[serviceGroup].title}</p>
            <p className="subtitle">{logisticServicesTitles[serviceGroup]?.subtitle || EMPTY_STRING}</p>
          </div>

          <LogisticServicesColumn serviceGroup={serviceGroup} key={serviceGroup} />
        </div>
      ))}
    </div>
  );
};

