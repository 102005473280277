import { FC } from 'react';
import { ILSAuctionCountdown } from '@modules/tender-platform/components/countdown';
import { ILSTypographyTitle } from '@components/general/typography';
import { StatisticProps } from 'antd/lib/statistic/Statistic';

interface IProps {
  endTime: StatisticProps['value'];

  currentPrice: number;
  hasCountdown: boolean;
}

export const TenderPlatformAuctionHeader: FC<IProps> = ({ endTime, currentPrice, hasCountdown }) => {
  if (hasCountdown) {
    return <ILSAuctionCountdown deadline={endTime} />;
  }
  return <ILSTypographyTitle className={'countdown'} children={`Аукцион ${Boolean(currentPrice) ? 'завершён' : 'не состоялся'}`} />;
};
