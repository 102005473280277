import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';
import { initialState } from '@modules/planning/children/by-date/store';
import { planningChangeValidationStatusRoutine } from '@modules/planning/children/by-date/actions';
import { ValidationStatus } from '../types/validate';

export const changeValidationStatusReducer = createReducer(initialState, {
  [planningChangeValidationStatusRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      status: ValidationStatus;
    }>
  ) => {
    const { status } = action.payload;
    state.validationStatus = status;
    return state;
  },
  [planningChangeValidationStatusRoutine.FAILURE]: onFailure,
  [planningChangeValidationStatusRoutine.FULFILL]: onFulfill
});
