import { createSelector } from 'reselect';
import { FormType, InitialState, MainTabsContainer } from '@modules/orders-and-trips/types/general';
import { getDecoratedOrders } from '@common/models/order/decorators';
import { tripsPaginationSelector } from './trip';
import { DASH, EMPTY_STRING } from '@common/constants';
import { Dictionary } from '@common/types';

export const showFormSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.showForm,
  (showForm) => showForm
);

export const orderPaginationSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.orders.pagination,
  (pagination) => pagination
);

export const decoratedOrdersNPaginationSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.orders.data,
  orderPaginationSelector,
  (data, pagination) => {
    return {
      orders: {
        [Dictionary.Order]: Object.values(getDecoratedOrders({ orders: data }) ?? {}).reverse()
      },
      pagination
    };
  }
);
export const unresolvedOrdersFetchSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.orders.isFetching,
  (isFetching) => isFetching
);

export const activeOrderRecordSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.orders.activeRecord,
  (activeRecord) => {
    return activeRecord;
  }
);

export const ordersSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.orders.data,
  (orders) => orders
);

export const mainActiveTabSelector = (container: MainTabsContainer) =>
  createSelector(
    (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.activeTab[container],
    (activeTab) => activeTab
  );

export const countOrdersNTripsSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips,
  (data) => ({
    amountOrders: data.orders.total,
    amountTrips: data.trips.total
  })
);

export const activeRecordIDSelector = (showForm: FormType | null) =>
  createSelector(
    (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips,
    (ordersNTrips) => {
      switch (showForm) {
        case FormType.Trip:
          return ordersNTrips.trips.activeRecord?.ID;
        case FormType.UpdateOrder:
          return ordersNTrips.orders.activeRecord?.ID;
        default:
          return EMPTY_STRING;
      }
    }
  );

export const activeModalWindowSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.activeModalWindow,
  (activeModalWindow) => activeModalWindow
);

