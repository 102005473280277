import '../styles.less';
import { FC } from 'react';
import { CellType, IILSTableProps, Reference, TableRecord } from '@common/types';
import { ILSModal } from '@common/components/feedback/modal';
import { ChangeDataPayload, DataScheme } from '@modules/planning/children/data/types';
import { ILSSwitch } from '@common/components/data-entry/switch';
import { useSwitch } from '@common/hooks/components/use-switch';
import { getFocusRefRow } from '../utils/helpers/get-focus-ref-row';
import { SetShowReference } from '@common/types/components/table/common';
import { ILSButton } from '@common/components';
import { useModal } from '@common/hooks/components/use-modal';

export interface IILSWindowTableProps<R extends TableRecord>
  extends Partial<
      Pick<
        IILSTableProps<R>,
        | 'handleReload'
        | 'handleCreate'
        | 'handleDelete'
        | 'handleUpdate'
        | 'handleRestore'
        | 'handleCheckDelete'
        | 'dictionaryInfo'
        | 'dictionaryLoad'
        | 'config'
        | 'dataSource'
        | 'dictionary'
        | 'errors'
      >
    >,
    Reference<R> {
  setShow: SetShowReference<R>;
  mapProps?: any;
  catalogDictionary?: any;
  refProps?: any;
  setRemoveAddRow?: any;
  filterRows?: any;
  codeField?: string;
  failKeys?: string[];
  failFieldName?: string;
  recordDataIndex?: string;
  isModal?: boolean;
  tableClassName?: string;
  scheme?: {} | DataScheme;
  hideEmpty?: boolean;
  cellType?: CellType;

  handleChange?(payload: ChangeDataPayload): void;
}

export const ILSWindowTable: FC<IILSWindowTableProps<any>> = ({
  title,
  component,
  setShow,
  mapProps,
  dictionary,
  refProps,
  record,
  handleSave,
  recordDataIndex,
  tableClassName,
  hideEmpty,
  cellType,
  ...props
}) => {
  const Component = component;
  const { setVisible, visible } = useModal(true);
  const { onSwitchChange, isOn } = useSwitch();

  const handleCancel = () => {
    setVisible(false);
    setShow(false);
  };

  const focusRefTableRowID = getFocusRefRow({ record, recordDataIndex, cellType });

  return (
    <ILSModal
      onCancel={handleCancel}
      footer={<ILSButton onClick={handleCancel} type={'primary'} children={'Закрыть'} />}
      className="table-modal"
      title={title}
      visible={visible}
    >
      <div className={tableClassName ?? 'catalog position-relative'}>
        {hideEmpty && (
          <ILSSwitch
            checked={isOn}
            onChange={onSwitchChange}
            checkedChildren="Показать пустые столбцы"
            unCheckedChildren="Скрыть пустые столбцы"
          />
        )}
        {Component && (
          <Component
            {...props}
            hideEmpty={isOn}
            component={component}
            setShow={setShow}
            dictionary={dictionary}
            record={record}
            handleSave={handleSave}
            focusRow={{ key: focusRefTableRowID ?? props.dataSource?.[0]?.key }}
            {...refProps}
          />
        )}
      </div>
    </ILSModal>
  );
};
