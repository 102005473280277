import moment, { Moment, MomentInput } from 'moment';
import { useMemo } from 'react';
import { isNil, isString } from 'lodash';
import { ILSDatePicker } from '@components/index';
import { EMPTY_STRING } from '@common/constants';
import { ILS_MAIN_API_DATE_FORMAT, ILS_MAIN_API_DATE_TIME_FORMAT, TableRecord } from '@common/types';
import { parseDateFromServer } from '@common/utils/helpers/date-time/parsers';
import { getValidMoment } from '@common/utils/helpers/date-time';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general/date-time';
import { IEditableCell, IILSCellDateProps } from '@common/types/components/table/cell';
import {
  handleDatePickerChange,
  handleDatePickerSelect
} from '@components/data-display/table/utils/helpers/cells/handlers/handle-date-picker-change';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isMomentInput } from '@components/data-display/table/utils/helpers/cells/is-moment-input';
import { isEmptyStringDate } from '@common/components/data-display/table/utils/helpers/cells/is-empty-string-date';

export const ILSCellDate = <R extends TableRecord>({
  showTime,
  children,
  toggleEdit,
  save,
  format,
  minField,
  maxField,
  record,
  dateParseFormat,
  allowClear
}: IILSCellDateProps<R>): JSX.Element => {
  const formatForPicker: IEditableCell<TableRecord>['format'] =
    format ?? (showTime ? ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT : ILS_MAIN_INTERFFACE_DATE_FORMAT);
  const formatForParser: IEditableCell<TableRecord>['dateParseFormat'] =
    dateParseFormat ?? (showTime ? ILS_MAIN_API_DATE_TIME_FORMAT : ILS_MAIN_API_DATE_FORMAT);

  const minDate = (minField && record?.[minField]) ?? new Date(0);
  const maxDate = (maxField && record?.[maxField]) ?? new Date(8640000000000000);
  const [node, date] = children;

  const handleDisableDate = (current: Moment) => {
    return current.isBefore(moment(minDate)) || current.isAfter(moment(maxDate));
  };

  if (!isMomentInput(date, dateParseFormat) && !isNil(date) && !isEmptyStringDate(date)) {
    showUserAndDevError({ error: 'Дата должна быть в формате MomentInput' });
    return (
      <>
        {node}
        <ILSDatePicker
          className={'cell-wrapper-editable-full'}
          open
          autoFocus
          allowClear={allowClear}
          showTime={showTime}
          format={formatForPicker}
          value={undefined}
          onClick={toggleEdit}
          onBlur={toggleEdit}
          disabledDate={handleDisableDate}
          onChange={handleDatePickerChange({ save, dateParseFormat: formatForParser, showTime })}
          onSelect={handleDatePickerSelect({ save, dateParseFormat: formatForParser, showTime })}
        />
      </>
    );
  }

  const interfaceDate = useMemo(() => getValidMoment(parseDateFromServer(date as MomentInput, showTime, formatForParser)), [date]);

  return (
    <>
      {node}
      <ILSDatePicker
        className={'cell-wrapper-editable-full'}
        open
        autoFocus
        allowClear={allowClear}
        showTime={showTime}
        format={formatForPicker}
        value={interfaceDate}
        onClick={toggleEdit}
        onBlur={toggleEdit}
        disabledDate={handleDisableDate}
        onChange={handleDatePickerChange({ save, dateParseFormat: formatForParser, showTime })}
        onSelect={handleDatePickerSelect({ save, dateParseFormat: formatForParser, showTime })}
      />
    </>
  );
};

