import { CSSProperties, DragEvent, DragEventHandler, FC, forwardRef, MutableRefObject, ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { EMPTY_CELL } from '@common/constants/general';
import { Dictionary, TableRecord } from '@common/types';
import { ActiveTableParameters } from '@common/types/components/table';

export interface IProps {
  children: Array<ReactNode>;
  record: TableRecord;
  active: ActiveTableParameters;
  draggable?: boolean;
  highlighted?: boolean;
  notHighlightFocusRow?: boolean;
  focusRowCustomHighlight?: string;
  style?: CSSProperties;
  VTref: MutableRefObject<any>;
  dictionary: Dictionary;
  virtual: boolean;

  onContextOpen(record: TableRecord, e: MouseEvent): void;

  onExpand(expanded: any, record: TableRecord): void;

  onRowClick(record: TableRecord, e: MouseEvent, rowRef: any): void;

  onTableRowDoubleClick?(record: TableRecord): void;

  onDragStart?(e: DragEvent): void;

  onDrop?(e: DragEvent): void;

  onDragEnd?(e: DragEvent): void;

  onHover?(e: DragEventHandler, record: TableRecord): void;

  rowClass?(record: TableRecord): string;
}

export const ILSRow: FC<IProps> = forwardRef<HTMLTableRowElement, IProps>(
  (
    {
      children,
      record,
      active,
      onContextOpen,
      onExpand,
      onRowClick,
      onTableRowDoubleClick,
      onDragStart,
      onDrop,
      onDragEnd,
      draggable,
      onHover,
      highlighted,
      rowClass,
      style,
      notHighlightFocusRow,
      focusRowCustomHighlight,
      virtual
    },
    virtualTableRef
  ) => {
    const rowRef = virtual ? virtualTableRef : useRef<any>(null);

    const onRowDoubleClick = (e: any) => {
      onExpand?.(e, record); //двойной клик для открытия подтаблицы
      onTableRowDoubleClick?.(record);
    };

    const onContextMenu = (e: any) => {
      e.preventDefault();
      //onRowClick?.(record, e, rowRef); - вызывает повторный рендеринг таблиц при вызове контекстного меню
      onContextOpen?.(record, e);
    };

    const onMouseHover = (e: any, record: any) => {
      onHover?.(e, record);
    };

    const onDragOver = onDragStart
      ? (e: any) => {
          e.preventDefault();
          onMouseHover(e, record);
        }
      : undefined;

    const onDragEnter = (e: DragEvent) => onMouseHover(e, record);

    const onClick = (e: any) => onRowClick?.(record, e, rowRef);

    const isRowActive = active?.key && (record.key || record.ID || record.rowKey) === active.key;
    const id = record?.ID ? `row_${record.ID.toString()}` : 'row_expand';

    const rowClassNames = rowClass?.(record) ?? EMPTY_CELL;

    return (
      <tr
        ref={rowRef}
        onDoubleClick={onRowDoubleClick}
        onClick={onClick}
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragEnd={onDragEnd}
        id={id}
        draggable={draggable}
        onContextMenu={onContextMenu}
        className={classnames('row', record?.dictionary, rowClassNames, {
          'active-row': notHighlightFocusRow || focusRowCustomHighlight ? false : isRowActive,
          [focusRowCustomHighlight ?? '']: isRowActive && focusRowCustomHighlight,
          'draggable-row': draggable,
          'highlighted-row': highlighted,
          'deleted-row': record?.Deleted || record?.rowParams?.isDeleted
        })}
        style={style}
      >
        {children}
      </tr>
    );
  }
);
