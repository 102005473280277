import { ICellInputValidationParameters, Notify, TableRecord } from '@common/types';
import { EventBlurNFocus, IILSCellInputProps } from '@common/types/components/table/cell';
import { ILSUserNotify } from '@common/components';
import { compareAsString } from '@common/utils';
import { getCellInputSaveValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-save-value';

export const handleCellInputSave =
  <R extends TableRecord>({
    forbiddenKeys,
    value,
    save,
    toggleEdit,
    replacer
  }: {
    forbiddenKeys?: ICellInputValidationParameters['forbiddenKeys'];
    replacer?: ICellInputValidationParameters['replacer'];
    save: IILSCellInputProps<R>['save'];
    value: string | number | boolean | object | null;
    toggleEdit: IILSCellInputProps<R>['toggleEdit'];
  }) =>
  (e: EventBlurNFocus) => {
    const newValue = getCellInputSaveValue({ value: e.target.value, replacer });
    /** Вынесено из основного if/else для валидации уже существующих полей */
    if (forbiddenKeys && newValue.match(forbiddenKeys)) {
      ILSUserNotify(Notify.Error, `Для данного поля запрещены символы ${forbiddenKeys}`);
    } else {
      if (!compareAsString(newValue, value)) {
        save(newValue);
      } else {
        toggleEdit?.();
      }
    }
  };
