import { checkCoordsDaData, checkCoordsYandex } from '@common/sagas';
import { reduceUpdateCoords } from '@common/sagas/utils/get-coords/reduced-get-coords';
import { Dictionary, NotifyDurationInSecond } from '@common/types';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';
import { GET_COORD_NOTIFY } from '@modules/catalog/constants';
import { dictionaryPlannerRoutine, multipleUpdateRoutine, planningGetCoordsRoutine } from '@modules/planning/children/by-date/actions';
import { MESSAGE_KEY } from '@modules/planning/children/by-date/constants';
import { planningGeocodeSettings } from '@modules/settings/selectors/planning';
import { GeocodeService } from '@modules/settings/types/planning';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

function* planningGetCoordsWorker(
  action: PayloadAction<{
    records: { ID: number; coords?: number[]; address?: string; dictionary: Dictionary }[];
    showModal?: boolean; //Использовать ли модальное окно для выбора сохранения результатов
    updatePriority?: CoordinatesUpdatePriority; //Если не используется модальное окно, по каким данным делать сохранение
  }>
) {
  const { records, updatePriority, showModal } = action.payload;
  const { request, success, failure, fulfill } = planningGetCoordsRoutine;
  const { getAddressError, getCoordinatesError } = GET_COORD_NOTIFY;
  let successMessage: undefined | string = undefined;
  let notificationCloseDelay: undefined | NotifyDurationInSecond = undefined;

  try {
    yield put(request({ dictionary: MESSAGE_KEY }));
    const { geocodeService } = yield select(planningGeocodeSettings);
    if (!showModal) {
      const { updatedCounts } = yield call(reduceUpdateCoords, {
        records,
        geocodeService,
        updatePriority,
        multipleUpdateRoutine
      });
      notificationCloseDelay = 3;
      successMessage = `Обновлено местоположений ${updatedCounts} из ${records.length}`;
    } else if (geocodeService === GeocodeService.Yandex) {
      for (const record of records) {
        const { address, coords, dictionary, ID } = record;
        yield call(
          checkCoordsYandex,
          { address, coords, dictionary, ID },
          { showModal, updatePriority },
          { getAddressError, getCoordinatesError },
          dictionaryPlannerRoutine
        );
      }
    } else {
      for (const record of records) {
        const { address, coords, dictionary, ID } = record;
        yield call(
          checkCoordsDaData,
          { address, coords, dictionary, ID },
          { showModal, updatePriority },
          { getAddressError, getCoordinatesError },
          dictionaryPlannerRoutine
        );
      }
    }

    yield put(success({ dictionary: MESSAGE_KEY, successMessage, notificationCloseDelay }));
  } catch (error) {
    yield put(failure({ error, dictionary: MESSAGE_KEY }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningGetCoordsWatcher() {
  yield takeEvery(planningGetCoordsRoutine.trigger, planningGetCoordsWorker);
}
