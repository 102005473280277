import { SURVEILLANCE_ZONE_CONFIG } from '@common/models/zone/config';
import { CatalogDictionary, Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC, useEffect } from 'react';
import { ILSCatalogTable } from '../../containers/table-container';
import { zone2table } from '@common/models/zone/decorators';

export const ILSCatalogSurveillanceZone: FC<IPropsILSCatalogTable> = tableCustom(
  ({ setMapProps, ...props }) => {
    useEffect(() => {
      setMapProps?.({
        field: 'BorderHash',
        type: 'polygon',
        colorField: 'Color'
      });
    }, []);

    return <ILSCatalogTable {...props} setMapProps={setMapProps} dataDecorator={zone2table} dictionary={Dictionary.SurveillanceZone} />;
  },
  SURVEILLANCE_ZONE_CONFIG,
  CatalogDictionary.SurveillanceZone,
  true
);
