import { SettingsFieldType } from '@modules/settings/types/container';

export const DEFAULT_MONITORING_PARAMS = {
  monitoring: {
    name: 'Мониторинг',
    sections: {
      parameters: {
        name: 'Параметры',
        data: {
          monitoringMaxDateInvterval: {
            name: 'Максимальный размер выгрузки данных',
            value: 1,
            defaultValue: 1,
            type: SettingsFieldType.Select,
            active: 1,
            extraParams: {},
            options: [
              {
                value: 1,
                label: '1'
              },
              {
                value: 2,
                label: '2'
              },
              {
                value: 3,
                label: '3'
              }
            ]
          },
          showAllVehicles: {
            name: 'Отображать все автомобили в Детализации событий',
            value: 0,
            defaultValue: 0,
            type: SettingsFieldType.Checkbox,
            active: 1,
            extraParams: {},
            options: []
          }
        }
      }
    }
  }
};

