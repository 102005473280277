import { defaultGeohashEncode } from '@common/decorators/latlon-geohash';
import { UPDATE_COORDS_REQUEST_AT_TIME_COUNT } from '@modules/catalog/constants/get-coords';
import { GeocodeService } from '@modules/settings/types/planning';
import { isEmpty } from 'lodash';
import { Task } from 'redux-saga';
import { call, fork, put } from 'redux-saga/effects';
import { collectDataByDictionary, getGeocodeFunction } from './update-coords';
import { Dictionary } from '@common/types';
import { CoordinatesUpdatePriority } from '@common/types/general/coords-proiority';
import { UnifiedRoutine } from 'redux-saga-routines';
import { Action } from 'redux';

//TODO UNIT

/**
 * Разделяет обновление координат у записей на группы с заданным числом
 * Обновление групп будет идти последовательно и блокировать следующее обновление группы,
 * пока полностью не выполнится обновление предыдущей
 */
export function* reduceUpdateCoords({
  records,
  updatePriority,
  geocodeService,
  multipleUpdateRoutine
}: {
  records: { ID: number; coords?: number[]; address?: string; dictionary: Dictionary }[];
  geocodeService?: GeocodeService;
  updatePriority?: CoordinatesUpdatePriority; //Если не используется модальное окно, по каким данным делать сохранение,
  multipleUpdateRoutine: UnifiedRoutine<(payload?: any) => Action<any>>;
}) {
  /**
   * Кол-во обновленных записей
   */
  let updatedCounts: number = 0;
  if (!isEmpty(records)) {
    for (const it of Array.from({ length: Math.ceil(records.length / UPDATE_COORDS_REQUEST_AT_TIME_COUNT) }, (_, i) => i)) {
      const { counts } = yield call(quietFastUpdateCoordsWorker, {
        records: records.slice(it * UPDATE_COORDS_REQUEST_AT_TIME_COUNT, (it + 1) * UPDATE_COORDS_REQUEST_AT_TIME_COUNT),
        updatePriority,
        geocodeService,
        multipleUpdateRoutine
      });
      updatedCounts += counts;
    }
  }
  return { updatedCounts };
}

type updateDataItem = {
  ID: number;
  dictionary: Dictionary;
  LatLon?: string;
  AddressDescription?: string;
  LatLonVerified?: number;
};

/**
 * Выполняет обновление координат сразу для всех записей
 * Выполняется только по одному известному признаку (координатам или адрессу)
 * Не запрашивает одновременно по двум признакам
 */
function* quietFastUpdateCoordsWorker({
  records,
  updatePriority,
  geocodeService,
  multipleUpdateRoutine
}: {
  records: { ID: number; coords?: number[]; address?: string; dictionary: Dictionary }[];
  geocodeService?: GeocodeService;
  updatePriority?: CoordinatesUpdatePriority; //Если не используется модальное окно, по каким данным делать сохранение
  multipleUpdateRoutine: UnifiedRoutine<(payload?: any) => Action<any>>;

}) {
  const { getAddressByCoords, getCoordsByAddress } = getGeocodeFunction({ geocodeService });
  /**
   * Задачи на получение данных с контекстом
   */
  const tasks: {
    getCoordsTask?: Task;
    getAddressTask?: Task;
    ID: number;
    coords?: number[];
    address?: string;
    dictionary: Dictionary;
  }[] = [];

  for (const record of records) {
    if (updatePriority && record.coords?.[0] && record.coords?.[1] && record.address) {
      if (updatePriority === CoordinatesUpdatePriority.byCoords) {
        tasks.push({ ...record, getAddressTask: yield fork(getAddressByCoords, record.coords[0], record.coords[1]) });
      } else if (updatePriority === CoordinatesUpdatePriority.byAddress) {
        tasks.push({ ...record, getCoordsTask: yield fork(getCoordsByAddress, record.address) });
      }
    } else if (record.coords?.[0] && record.coords?.[1] && !record.address) {
      tasks.push({ ...record, getAddressTask: yield fork(getAddressByCoords, record.coords[0], record.coords[1]) });
    } else if (isEmpty(record.coords) && record.address) {
      tasks.push({ ...record, getCoordsTask: yield fork(getCoordsByAddress, record.address) });
    }
  }

  /**
   * Ожидание выполнения всех поставленных задач
   */
  yield Promise.allSettled(tasks.map((it) => it?.getCoordsTask?.toPromise() ?? it?.getAddressTask?.toPromise()));
  /**
   * Данные которые нужно будет обновить в справочниках
   */
  const summaryUpdateData: updateDataItem[] = [];

  for (const task of tasks) {
    const updateItem: updateDataItem = {
      ID: task.ID,
      dictionary: task.dictionary
    };
    if (task.getAddressTask) {
      updateItem.AddressDescription = task.getAddressTask?.result();
    }
    if (task.getCoordsTask) {
      const coords: number[] | undefined = task.getCoordsTask?.result();
      if (!isEmpty(coords) && coords?.[0] && coords?.[1]) {
        updateItem.LatLon = defaultGeohashEncode([coords[0], coords[1]]);
      }
    }
    if (updateItem.AddressDescription || updateItem.LatLon) {
      /**
       * LatLonVerified - 1 ставится для проверенных координат с адресом
       */
      updateItem.LatLonVerified = 1;
      summaryUpdateData.push(updateItem);
    }
  }

  /**
   * разделенные по справочникам данные по обновлению
   * NOTE сейчас получение координат предусмотрен только для Depot
   */
  const updatedData = collectDataByDictionary(summaryUpdateData);
  if (!isEmpty(updatedData)) {
    for (const dictionaryUpdatedData of updatedData) {
      yield put(multipleUpdateRoutine.trigger(dictionaryUpdatedData));
    }
  }

  return {
    /**
     * Количество обновленных записей
     */
    counts: summaryUpdateData.length
  };
}

