import { PlanningAPI } from '@modules/planning/api';
import {
  planningGetTrackRoutine,
  planningGetTrackStartAction,
  planningGetTrackStopAction
} from '@modules/planning/children/by-date/actions';
import { call, cancel, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { filterPointsToGetTrack } from '@common/utils/get-track/planPoint2getTrack';
import { PointTypeApi, PointWithData } from '@common/utils/get-track/types';
import { GetTrackRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { Task } from 'redux-saga';
import { planningGetQueryPoints } from '../../selectors/plan-points';

function* planningGetTrackWorker(action: PayloadAction<GetTrackRequest>) {
  const { request, success, failure, fulfill } = planningGetTrackRoutine;
  const { points } = action.payload;

  try {
    yield put(request({ points, notifyKey: 'track-load' }));
    const searchPoint = points && filterPointsToGetTrack(points);
    const { data } = yield call(PlanningAPI.getTrack, { points: searchPoint });

    yield put(success({ data: data.data, notifyKey: 'track-load', withoutNotification: true, notificationCloseDelay: 1 }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningGetTrackWatcher() {
  yield takeEvery(planningGetTrackRoutine.trigger, planningGetTrackWorker);
}

//получает точки для загрузки и добавляет событие загрузки в очередь
function* runLoadTrack() {
  const points: Record<string, PointTypeApi> = yield select(planningGetQueryPoints);

  let hasNotLoadTrack =
    Object.values(points)
      ?.reverse()
      ?.find((item) => isNil((item as PointWithData).track)) || false;
  if (hasNotLoadTrack) {
    yield call(
      planningGetTrackWorker,
      planningGetTrackRoutine.trigger({
        points
      })
    );
  }
}

//запускает runLoadTrack и повторяет его через 3 секунды с момента полного завершения загрузки
export function* planningGetTrackStarter(): any {
  yield delay(3000);
  while (true) {
    yield call(runLoadTrack);
    yield delay(3000);
  }
}

//запускает и останавливает загрузку треков при dispatch Start и Stop
function* planningGetTrack() {
  let forkGetTrackTask: Task[] | undefined;
  try {
    while (true) {
      yield take(planningGetTrackStartAction);
      forkGetTrackTask = yield fork(planningGetTrackStarter);
      yield take(planningGetTrackStopAction);
      if (forkGetTrackTask) {
        yield cancel(forkGetTrackTask);
      }
    }
  } finally {
    if (forkGetTrackTask) {
      yield cancel(forkGetTrackTask);
    }
  }
}
export function* planningGetTrackWatcherInit() {
  yield fork(planningGetTrack);
}
