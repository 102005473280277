import reduceReducers from 'reduce-reducers';
import { INITIAL_SUGGESTION_AUTO_COMPLETE_STATE } from '@common/feature/suggestion-autocomplete/address/store/store';
import { suggestionAddress } from '@common/feature/suggestion-autocomplete/address/reducers/suggestion';
import { suggestionClearReducer } from '@common/feature/suggestion-autocomplete/address/reducers/clear';

export const suggestionAutoCompleteReducer = reduceReducers(
  INITIAL_SUGGESTION_AUTO_COMPLETE_STATE,
  suggestionAddress,
  suggestionClearReducer
);
