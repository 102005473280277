import CustomScroll from 'react-customscroll';
import { ILSButtonTooltip, ILSCheckbox, ILSForm, ILSFormItem, ILSInput, ILSRadioGroup, ILSTooltip } from '@components/index';
import { DragDropContainer } from 'react-drag-drop-container';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm, useWatch } from 'antd/es/form/Form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ActiveTabType, ButtonType, Tariff } from '@modules/catalog/types/tariff';
import { useTariffFormulaButtons } from '@modules/catalog/hooks/tariff/use-tariff-formula-buttons';
import { EMPTY_STRING } from '@common/constants';
import { createTariffButtonHTML } from './components/create-button';
import { isEmpty } from 'lodash';
import { REGEXP_FOR_SPLITTER } from '@modules/catalog/constants/tariff';

const group = [
  { value: 'const', label: 'Const' },
  { value: 'func', label: 'f(x,y)', disabled: true },
  { value: 'param', label: 'a, b, c', disabled: true }
];

const title =
  'В данной вкладке сохраняются созданные вами параметры. Для добавления нового параметра, при создании тарифа выберите опцию "Сохранить как пользовательский параметр".';
export interface IILSTariffTabsComponent {
  showCheck: boolean;
  edit: boolean;
  setParser: Dispatch<SetStateAction<ButtonType[]>>;
  defaultName: string;
  tariff: Tariff | undefined;
  insertEditor(inner: string): void;
  onFinish(v: any): void;
  setFormulaDesc(desc: string): void;
}

export const TariffFormComponent: FC<IILSTariffTabsComponent> = ({
  insertEditor,
  setFormulaDesc,
  setParser,
  onFinish,
  defaultName,
  showCheck,
  tariff,
  edit
}) => {
  const [form] = useForm();
  const activeTab = useWatch('activeTab', form);
  const { visibleButtons, onSearchChange } = useTariffFormulaButtons(activeTab);

  const btnHandler = (btn: ButtonType) => {
    insertEditor(createTariffButtonHTML(activeTab, btn));
    setFormulaDesc(btn.desc);
    setParser((prev) => prev.concat(btn));
  };
  const onDragEnd = (btn: ButtonType, contentFrame: any, _x: any, _y: any) => {
    if (contentFrame.className === 'tox-edit-area__iframe') btnHandler(btn);
    return;
  };

  useEffect(() => {
    if (tariff) {
      setParser((prevParser) => {
        //Устанавливаем нужные parser при переводе формулы в режим редактирования
        if (isEmpty(prevParser)) {
          const newParser: ButtonType[] = [];
          tariff?.Formula.slice()
            .split(REGEXP_FOR_SPLITTER)
            .forEach((operand: string) => {
              const button = visibleButtons?.find((button) => {
                return button.variable === operand;
              });
              if (button) {
                newParser.push(button);
              }
            });
          return newParser;
        }
        return prevParser;
      });
    }
  }, []);

  //TODO DragDropContainer с antd tooltip плохо взаимодействует
  return (
    <ILSForm
      initialValues={{
        name: defaultName,
        template: false,
        activeTab: ActiveTabType.const
      }}
      name={'tariff'}
      onFinish={(val) => {
        onFinish(val);
        form.resetFields();
      }}
      form={form}
      className="tariff-form"
    >
      <ILSFormItem className={'tariff-form-item'} name={'name'}>
        <ILSInput addonBefore={'Тариф'} placeholder={'Название тарифа'} />
      </ILSFormItem>
      <ILSInput
        defaultValue={EMPTY_STRING}
        placeholder="Поиск по формулам"
        onChange={onSearchChange}
        addonAfter={activeTab === 'param' && <ILSTooltip children={<QuestionCircleOutlined className={'ils-svg green'} />} title={title} />}
      />
      <ILSFormItem className={'tariff-form-item'} name={'activeTab'}>
        <ILSRadioGroup size={'small'} optionType="button" buttonStyle="solid" options={group} />
      </ILSFormItem>
      {showCheck && (
        <>
          {!edit && (
            <ILSFormItem className={'tariff-form-item'} valuePropName={'checked'} name={'template'}>
              <ILSCheckbox children={'Сохранить как пользовательский параметр'} />
            </ILSFormItem>
          )}
        </>
      )}
      <div className="tariff-form-formula-btns">
        <CustomScroll>
          {visibleButtons?.map((b) => (
            <DragDropContainer
              key={b.variable}
              targetKey="drop-editor"
              className="w-100"
              dragData={b.name}
              dragClone
              onDragEnd={(_name: any, dropTargetElement: any, x: any, y: any) => onDragEnd(b, dropTargetElement, x, y)}
            >
              <ILSButtonTooltip title={b.desc} placement="topLeft" onClick={() => btnHandler(b)} children={b.name} />
            </DragDropContainer>
          ))}
        </CustomScroll>
      </div>
    </ILSForm>
  );
};

