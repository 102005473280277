import { createArrayFromIndexedArray } from '@common/utils';
import { Restrictions } from '@common/types/dictionaries/restriction';
import { TenderLot } from '@modules/tender-platform/types';

export const createRestrictionArray = (lot: TenderLot) => {
  return (
    createArrayFromIndexedArray(lot?.TenderLotPosition)?.reduce((prev: Restrictions, { RestrictionEnum }) => {
      if (RestrictionEnum) {
        const RestrictionID = Object.keys(RestrictionEnum ?? {})[0];
        prev.push({
          ID: Number(RestrictionID),
          Name: RestrictionEnum?.[RestrictionID],
          Deleted: null,
          RestrictionGroupID: null,
          RestrictionGroupEnum: []
        });
      }
      return prev;
    }, []) ?? []
  );
};
