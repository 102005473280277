import { IndexedArray } from '@common/types';
import { SortDirection } from '@common/types/general';
import { sortedObjectKeys } from '@common/utils/general/object-functions';
import { PointEventAbstract } from '../../types/map';

// Утилита для обновления данных трека
export const rebuildDataTrackData = (
  currentData: IndexedArray<PointEventAbstract>,
  newData: IndexedArray<PointEventAbstract>
): IndexedArray<PointEventAbstract> => {
  const sortedExistingEvents = sortedObjectKeys(currentData, SortDirection.Asc);
  let incomingEventsKeys = sortedObjectKeys(newData, SortDirection.Asc);
  let rebuiltData: IndexedArray<PointEventAbstract> = {};
  //if min incoming DT is more than max exisiting DT
  const keysArray = incomingEventsKeys;
  let minIncomingDT = keysArray.shift();
  let maxExistingDT = sortedExistingEvents[0];
  if (minIncomingDT && minIncomingDT <= maxExistingDT) {
    sortedExistingEvents.forEach((key) => {
      if (!minIncomingDT) {
        return { ...currentData, ...rebuiltData };
      }
      if (minIncomingDT && key < minIncomingDT) {
        rebuiltData[key] = currentData[key];
      } else if (key === minIncomingDT) {
        rebuiltData[key] = newData[key];
      } else {
        rebuiltData[minIncomingDT] = newData[minIncomingDT];
        minIncomingDT = keysArray.shift();
      }
    });
    return rebuiltData;
  } else {
    return { ...currentData, ...newData };
  }
};
