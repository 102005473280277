import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { PlanStatus, RouteTotal, TableColumnName, TripTotal } from '@common/types';
import { tripSaveRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { compareAsString } from '@common/utils';
import { TripSaveSuccessPayload } from '@modules/planning/children/by-date/sagas/trip/save';

export type TripSaveSuccessPayloadAction = PayloadAction<TripSaveSuccessPayload>;

export const tripSaveReducer = createReducer(initialState, {
  [tripSaveRoutine.SUCCESS]: (state, action: TripSaveSuccessPayloadAction) => {
    const { key, value, vehicleID, tripID, planID } = action.payload;

    switch (key) {
      case TableColumnName.Status:
        const route = state.currentPlan.RouteTotal[vehicleID];
        const trip = route.TripTotal[tripID];
        route.changed = true;
        trip[key] = value;
        trip.changed = true;

        const waypoints = trip.WaypointTotal;
        for (const waypointID in waypoints) {
          const waypoint = waypoints[waypointID];
          waypoint[key] = value;
          // TODO backed should return Status, not PlanStatus
          waypoint.PlanStatus = value;
          const orderAction = waypoints[waypointID].OrderActionTotal;
          for (const orderActionID in orderAction) {
            orderAction[orderActionID][key] = value;
          }
        }

        const arrayTrips: Array<TripTotal> = Object.values(route.TripTotal);

        if (arrayTrips.length > 1) {
          const isDifferentStatuses = arrayTrips.some(({ Status }) => {
            return !compareAsString(Status, value as PlanStatus);
          });
          if (isDifferentStatuses) {
            route[key] = PlanStatus.TripWithDifferentStatuses;
          } else {
            route[key] = value;
          }
        } else {
          route[key] = value;
        }

        const routes: Array<RouteTotal> = Object.values(state.currentPlan.RouteTotal);
        if (routes.length > 1) {
          const isDifferentStatuses = routes.some(({ Status }) => {
            return !compareAsString(Status, value as PlanStatus);
          });
          if (state.project?.Plan) {
            const currentPlan = state.project.Plan[planID];
            if (isDifferentStatuses) {
              currentPlan[key] = PlanStatus.TripWithDifferentStatuses;
              currentPlan.PlanTotal[key] = PlanStatus.TripWithDifferentStatuses;
            } else {
              currentPlan[key] = value as PlanStatus;
              currentPlan.PlanTotal[key] = value as PlanStatus;
            }
          }
        }
        break;
      default:
        break;
    }

    return state;
  }
});
