import { ReactSVG } from 'react-svg';
import { ILSButton, ILSTooltip } from '@components/index';
import { FC } from 'react';
import { IPropsILSMapBtn } from '@modules/catalog/types/components';

const ILSMapMenuBtn: FC<IPropsILSMapBtn> = ({ show, setShow, menu, icon, tooltipText, table, active }) => {
  return (
    <ILSTooltip placement={table ? 'bottom' : 'right'} title={tooltipText}>
      <ILSButton
        className={`${table ? 'table-control-btn mr-2 table-' : 'map-control-btn map-'}${icon}${
          show || (active && active.length) ? ' active' : ''
        }`.trim()}
        onClick={() => setShow(!show)}
        icon={active && active.length ? false : <ReactSVG className="svg-container" src={`/assets/icons/planning/${icon}.svg`} />}
      >
        {Boolean(active && active.length && menu && menu.length) && <span>{`${active?.length}/${menu?.length}`}</span>}
      </ILSButton>
    </ILSTooltip>
  );
};

export default ILSMapMenuBtn;
