import { call, put, select, takeEvery } from 'redux-saga/effects';
import { tenderLotUpdateRoutine, tenderUpdateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender, TenderLot } from '@modules/tender-platform/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { formToSaveData } from '@modules/tender-platform/utils/decorators/form-to-save-data';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderChangeRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint, IndexedArray, User } from '@common/types';
import { accountUsersSelector, currentTenderLotSelector } from '@modules/tender-platform/selectors';
import { getLotFormData } from '@modules/tender-platform/children/tender-own/utils/helprers/get-lot-form-data';

function* tenderUpdateWorker(
  action: PayloadAction<{
    id: Tender['ID'];
    values: Record<TenderFormField, any>;
    fromPlanner?: boolean;
  }>
) {
  const { request, failure, fulfill } = tenderUpdateRoutine;
  const { id, values } = action.payload;

  //NOTE: Сохраняем заполненные поля лота во временную переменную
  const accountUsers: IndexedArray<User> = yield select(accountUsersSelector);
  const tmpLotData = getLotFormData(values, accountUsers);
  try {
    yield put(request());
    const { lot, tender } = formToSaveData(values);
    const TenderLot: TenderLot = yield select(currentTenderLotSelector);

    const {
      data: { data }
    } = yield call(TenderOwn.update, { id, values: tender });

    const [Tender] = createArrayFromIndexedArray<Tender>(data);
    yield put(tenderChangeRoutine.trigger({ Tender, operation: DictionariesEndpoint.Update }));

    const lotID = TenderLot?.ID;
    if (lotID) {
      yield put(tenderLotUpdateRoutine.trigger({ id: lotID, values: { ...Tender.TenderLot, ...lot }, tmpLotData }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(tenderReadRoutine.trigger({ id }));
    yield put(fulfill());
  }
}

export function* tenderUpdateWatcher() {
  yield takeEvery(tenderUpdateRoutine.trigger, tenderUpdateWorker);
}
