import { ColumnTable, TableColumnName } from '@common/types';
import { plannerPlanningVehiclesToTable } from '@common/models/vehicle/decorators';

export const canEditVehicle = (
  vehicle: ReturnType<typeof plannerPlanningVehiclesToTable>[number],
  dataIndex: ColumnTable<ReturnType<typeof plannerPlanningVehiclesToTable>[number]>['dataIndex']
): boolean => {
  switch (dataIndex) {
    case TableColumnName.TakeForPlanner:
      return !Boolean(vehicle?.UseMode);
    case TableColumnName.StartDate:
    case TableColumnName.StartTime:
      return true;
    default:
      return false;
  }
};
