import { DashboardBDDMMetrics } from '@modules/monitoring/children/dashboard/constants';

// Названия метрик безопасности дорожного движения
export const dashboardBDDTabs: {
  [key in DashboardBDDMMetrics]: { subTitle: string; sufix: string };
} = {
  [DashboardBDDMMetrics.DriverCount]: {
    subTitle: 'Кол-во водителей-нарушителей',
    sufix: 'чел.'
  },
  [DashboardBDDMMetrics.AccelerationsCount]: {
    subTitle: 'Кол-во резких ускорений',
    sufix: ''
  },
  [DashboardBDDMMetrics.SharpTurnCount]: {
    subTitle: 'Кол-во опасных маневров',
    sufix: ''
  },
  [DashboardBDDMMetrics.SharpBrakingCount]: {
    subTitle: 'Кол-во резких торможений',
    sufix: ''
  }
};
