import { createSelector } from 'reselect';
import { SettingsStore } from '@modules/settings/types/store';
import { Dictionary } from '@common/types/dictionaries/dictionaries';
import { ReferenceTablesData, TableColumnName } from '@common/types';
import { EMPTY_OBJECT_DATA } from '@common/constants';
import { BASE_FIELD_NAMES, DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { generateBaseFieldsNamesOptionsFromEnums } from '@components/decorators';

export const forwarderPointStatusesSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings?.mobileApplication?.ForwarderPointStatuses ?? EMPTY_OBJECT_DATA,
  (forwarderPointStatuses) => forwarderPointStatuses
);

export const forwarderPointStatusesGroupSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings?.mobileApplication?.ForwarderPointStatusesGroup ?? EMPTY_OBJECT_DATA,
  (forwarderPointStatusesGroup) => forwarderPointStatusesGroup
);

export const forwarderPointStatusesFocusRowSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings?.mobileApplication?.ForwarderPointStatusesFocusRowID ?? null,
  (ForwarderPointStatusesFocusRowID) => ForwarderPointStatusesFocusRowID
);

export const forwarderPointStatusesGroupFocusRowSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings?.mobileApplication?.ForwarderPointStatusesGroupFocusRowID ?? null,
  (forwarderPointStatusesGroupFocusRowID) => forwarderPointStatusesGroupFocusRowID
);


export const forwarderPointStatusesIconsURLSelector = createSelector(
  (state: SettingsStore) => state.fixed.Settings?.mobileApplication?.iconsURL ?? EMPTY_OBJECT_DATA,
  (iconsURL) => iconsURL
);

export const forwarderPointStatusesIconsURLOptionsSelector = createSelector(forwarderPointStatusesIconsURLSelector, (iconsURL) => {
  // В поле "Доступные статусы" допускается выбирать из списка статусов (TMS-5755)
  return {
    [TableColumnName.Icon]: {
      options: generateBaseFieldsNamesOptionsFromEnums(iconsURL),
      dictionary: Dictionary.IconUrl,
      fieldNames: BASE_FIELD_NAMES
    }
  };
});

export const forwarderPointStatusesOptionsSelector = createSelector(forwarderPointStatusesSelector, (forwarderPointStatuses) => {
  const refData: ReferenceTablesData = {
    // В поле "Доступные статусы"
    [TableColumnName.AvailableStatusesID]: {
      //допускается выбирать из списка статусов (TMS-5755)
      options: forwarderPointStatuses,
      dictionary: Dictionary.AvailableStatuses,
      fieldNames: DEFAULT_FIELD_NAMES
    }
  };
  return refData;
});
