import { Dictionary, ReferenceTablesData, TableColumnName } from '@common/types';
import { createSelector } from 'reselect';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { filterCatalogData } from '@modules/catalog/helpers/catalog-dictionary-filter';
import { getOnlyStorages } from '@common/models/depot/decorator';
import { filterCatalogOptions } from '@common/components/data-display/table/utils';

export const driverTableOptionsSelector = createSelector(
  catalogDictionaryDataSelector(Dictionary.DriverTimeline),
  catalogDictionaryDataSelector(Dictionary.Restriction),
  catalogDictionaryDataSelector(Dictionary.Zone),
  catalogDictionaryDataSelector(Dictionary.Vehicle),
  catalogDictionaryDataSelector(Dictionary.VehicleTimeline),
  catalogDictionaryDataSelector(Dictionary.Depot),
  catalogDictionaryDataSelector(Dictionary.TripTemplate),
  catalogDictionaryDataSelector(Dictionary.TransportCompany),
  (DriverTimeline, Restriction, Zone, Vehicle, VehicleTimeline, Depot, TripTemplate, TransportCompany) => {
    const refData: ReferenceTablesData = {
      DriverTimelineID: {
        options: filterCatalogOptions(DriverTimeline),
        dictionary: Dictionary.DriverTimeline,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RestrictionID: {
        options: filterCatalogOptions(Restriction),
        dictionary: Dictionary.Restriction,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      ZoneID: {
        options: filterCatalogOptions(filterCatalogData(Zone, Dictionary.DeliveryZone)),
        dictionary: Dictionary.Zone,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      VehicleID: {
        options: filterCatalogOptions(Vehicle),
        dictionary: Dictionary.Vehicle,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      VehicleTimelineID: {
        options: filterCatalogOptions(VehicleTimeline),
        dictionary: Dictionary.VehicleTimeline,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      WarehouseID: {
        options: filterCatalogOptions(getOnlyStorages(Depot)),
        dictionary: Dictionary.Depot,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      TripTemplateID: {
        options: filterCatalogOptions(TripTemplate),
        dictionary: Dictionary.TripTemplate,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      [TableColumnName.TransportCompanyID]: {
        options: TransportCompany,
        dictionary: Dictionary.TransportCompany,
        fieldNames: DEFAULT_FIELD_NAMES
      }
    };
    return { refData };
  }
);
