import { FC } from 'react';
import { ILSCollapse, ILSCollapsePanel, ILSInputNumber } from '@common/components';
import { ExpandIconPosition } from '@common/components/data-display/collapse/types';
import { CollapsePanels } from '../types/general';
import { CaretRightOutlined } from '@ant-design/icons/lib/icons';

type IProps = {
  filterID?: string | number;
  handleChangeFilterID(value: string | number): void;
};

export const Filters: FC<IProps> = ({ filterID, handleChangeFilterID }) => {
  return (
    <ILSCollapse
      expandIconPosition={ExpandIconPosition.Left}
      bordered={false}
      className="orders-and-trips-collapse orders-and-trips-filters"
      accordion
      destroyInactivePanel
      defaultActiveKey={CollapsePanels.Filters}
      expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: '16px' }} rotate={isActive ? 90 : 0} />}
    >
      <ILSCollapsePanel key={CollapsePanels.Filters} header={'Фильтр поиск и сортировка'}>
        <ILSInputNumber placeholder={'введите строку поиска'} value={filterID} onChange={handleChangeFilterID} />
      </ILSCollapsePanel>
    </ILSCollapse>
  );
};

