import { FC } from 'react';
import { TenderPlatformTakeWithMinPriceButton } from '@modules/tender-platform/children/tender-search/components/auction/take-with-min-price-button';
import { ILSTenderOfferModal } from '@modules/tender-platform/children/tender-search/components/offer/offer-modal';
import { useSelector } from 'react-redux';
import { fetchSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { AuctionFormValues } from '@modules/tender-platform/children/tender-search/constants/offer';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';

interface IProps {
  tenderOfferID: TenderOffer['ID'];

  onFinish(values: AuctionFormValues): void;
}

export const MinPriceButtonText = 'Забрать по минимальной стоимости';

export const MinPriceButton: FC<IProps> = ({ tenderOfferID, onFinish }) => {
  const isFetching = useSelector(fetchSelector);

  return tenderOfferID ? (
    <TenderPlatformTakeWithMinPriceButton children={'Забрать по минимальной стоимости'} onFinish={onFinish} />
  ) : (
    <ILSTenderOfferModal onFinish={onFinish} isFetching={isFetching} children={MinPriceButtonText} takeWithMin />
  );
};
