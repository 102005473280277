import { call, put, takeLatest } from 'redux-saga/effects';
import { dataClearRoutine, dataValidateRoutine, uploadRoutine } from '@modules/planning/children/data/actions';
import { Data } from '@modules/planning/children/data/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { UploadPayload } from '@modules/planning/children/data/types/api';

function* uploadWorker(action: PayloadAction<UploadPayload>) {
  const { request, success, failure, fulfill } = uploadRoutine;
  const { file, join, type } = action.payload;

  try {
    if (!join) {
      yield put(dataClearRoutine.trigger());
    }

    yield put(request());

    const {
      data: {
        data: { data, files, scheme }
      }
    } = yield call(type ? Data.uploadTxt : Data.upload, { file, join: Boolean(join), type });

    yield put(success({ data, files, scheme }));
    yield put(dataValidateRoutine());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* dataUploadWatcher() {
  yield takeLatest(uploadRoutine.trigger, uploadWorker);
}
