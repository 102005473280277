import { apiInstance } from '@core/api/instance';
import { ITenderAPI, TenderStatus } from '@modules/tender-platform/types';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const TenderAPI: ITenderAPI = {
  read: ({ id }) => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.Read), { id }),
  upload: ({ tenderID, attachment }) =>
    apiInstance().post(getAPIURL(Dictionary.TenderDoc, DictionariesEndpoint.Upload), { tenderID, attachment }),
  index: ({ tenderID }) => apiInstance().post(getAPIURL(Dictionary.TenderDoc, DictionariesEndpoint.Index), { tenderID }),
  delete: ({ ids }) => apiInstance().post(getAPIURL(Dictionary.TenderDoc, DictionariesEndpoint.Delete), { data: { ids } }),
  downloadAllChecked: ({ requestParams }) =>
    apiInstance().get(getAPIURL(Dictionary.TenderDoc, DictionariesEndpoint.GetAllDocuments) + requestParams, { responseType: 'blob' }),
  getAccountUsers: () => apiInstance().get(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.GetAccountUsers)),
  getVehicleTypes: () => apiInstance().get(getAPIURL(Dictionary.VehicleType, DictionariesEndpoint.Index)),
  getVehiclesForTender: (params) =>
    apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.getVehiclesForTender), { ...params }),
  getDriversForTender: (params) =>
    apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.getDriversForTender), { ...params }),
  setDriverRating: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.setDriverRating), { ...params }),
  setCarrierRating: (params) => apiInstance().post(getAPIURL(Dictionary.TenderOffer, DictionariesEndpoint.setCarrierRating), { ...params }),
  getDistance: (params) => apiInstance().post(getAPIURL(Dictionary.TenderLot, DictionariesEndpoint.GetLotDistance), { ...params }),
  createTenderFromPlanner: (params) => apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.createFromRoute), params),
  updateTenderFromPlanner: ({ payload, planID, operation }) => {
    return apiInstance().post(getAPIURL(Dictionary.Tender, DictionariesEndpoint.updateFromRoute), {
      data: JSON.stringify(payload),
      planID,
      Alias: operation.includes('Approve') ? TenderStatus.OnApproval : TenderStatus.New
    });
  }
};
