import { apiInstance } from '@core/api/instance';

export const RoadGraphEditor = {
  getSegments: (params: { points: string; roads: string }) => {
    const api = apiInstance();
    const { points, roads } = params;

    return api.post('/NewTms/MapEditor/getSegments', {
      points,
      roads
    });
  },
  setSegment: (params: {}) => {
    const api = apiInstance();

    return api.post('/NewTms/MapEditor/setSegment', {
      ...params
    });
  }
};
