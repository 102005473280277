import { PlanStatus, TableColumnName } from '@common/types';
import { PlansTableRecord } from '@modules/planning/children/by-date/types';
import { isNil } from 'lodash';
import { EDITABLE_STATUSES } from '@modules/planning/children/by-date/constants';

export const canEditPlan = (record: PlansTableRecord, dataIndex: TableColumnName): boolean => {
  switch (dataIndex) {
    case TableColumnName.Status:
    case TableColumnName.TypePlanning:
    case TableColumnName.PlanConfig:
      if (!isNil(record.Status)) {
        return EDITABLE_STATUSES.includes(record.Status.toString() as PlanStatus);
      }
      return false;
    default:
      return false;
  }
};
