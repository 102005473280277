import { ILSButton } from '@common/components';
import { useAppSelector } from '@core/hooks';
import moment from 'moment';
import { memo, useEffect } from 'react';
import { monitoringDateSelector } from '../selectors';
import { DateFormat, Module } from '@common/types';
import { ILSMonitoringRangePickerComponent } from './range-picker/range-picker';
import { ILSMonitoringDatePickerComponent } from './date-picker/date-picker';
import { DocumentsModalButton } from '@common/feature/documents-modal-button/documents-modal-button';

interface Props {
  handleDate: (date: string[]) => void;
  maxInterval: string | number | boolean | undefined;
}

/*
 * Компонент отвечающий за выбор даты или диапазона дат.
 * Отображает либо date picker (если в настройках стоит отображение 1 дня)
 * либо range picker (если в настройках стоит 2 или 3 дня)
 */

const ILSMonitoringDateSelectComponent = (props: Props) => {
  const { handleDate, maxInterval } = props;
  let { startDate, endDate } = useAppSelector(monitoringDateSelector);

  const handleClickToday = () => {
    const today = moment(Date.now()).format(DateFormat.YYYYMMDDDash);

    handleDate([today, today]);
  };

  useEffect(() => {
    const today = moment(Date.now()).format(DateFormat.YYYYMMDDDash);
    if (startDate && endDate && (startDate !== today || endDate !== today)) {
      handleDate([String(startDate), String(endDate)]);
    } else {
      handleDate([today, today]);
    }
  }, []);

  const handleClickYesterday = () => {
    const yesterday = moment().subtract(1, 'days').format(DateFormat.YYYYMMDDDash);

    handleDate([yesterday, yesterday]);
  };

  const useRangePicker = maxInterval && Number(maxInterval) > 1;

  return (
    <div className="ils-monitoring-date-select">
      <div className="title-box">
        <div className="title">Текущая сводка</div>
      </div>
      <div className="date-box">
        <DocumentsModalButton module={Module.Monitoring} />
        <div className="date-select">
          {useRangePicker ? (
            <ILSMonitoringRangePickerComponent handleDate={handleDate} maxInterval={maxInterval} />
          ) : (
            <ILSMonitoringDatePickerComponent handleDate={handleDate} />
          )}
          <ILSButton type="primary" onClick={handleClickYesterday}>
            Вчера
          </ILSButton>
          <ILSButton type="primary" onClick={handleClickToday}>
            Сегодня
          </ILSButton>
        </div>
      </div>
    </div>
  );
};

export default memo(ILSMonitoringDateSelectComponent);
