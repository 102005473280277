import { DragHandler, TableRecord } from '@common/types';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { handleChangeHoveredRecord } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-change-hovered-record';
import { handleRecordDragEnd } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drag-end';
import { handleRecordHover } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-hover';
import { handleRecordDragStart } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drag-start';
import { handleRecordDrop } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drop';
import { handleRecordDragEndDrop } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drag-end-drop';
import { isRecordDraggable } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/is-record-draggable';

export const useRoutesDragNDrop = ({ tableRef }: { tableRef: MutableRefObject<HTMLDivElement | null> }) => {
  const isRun = useRef<boolean>(true);
  const hoveredRecord = useRef<TableRecord | null>(null);
  const selectRecord = useRef<TableRecord  | undefined>();
  const targetRecord = useRef<TableRecord | undefined>();
  const onChangeHoveredRecord = handleChangeHoveredRecord({ isRun, hoveredRecord, tableRef });
  const dispatch = useDispatch();

  const dragHandler: DragHandler<TableRecord  | undefined> = useMemo(() => {
    const handleRecordDragEndDropF = handleRecordDragEndDrop({  onChangeHoveredRecord, dispatch, targetRecord, selectRecord })
    return {
      onDragStart: handleRecordDragStart({ selectRecord }),
      onDrop: handleRecordDrop({ selectRecord, targetRecord, onDragEndDropCallback: handleRecordDragEndDropF  }),
      onDragEndDropCallback: handleRecordDragEndDropF,
      onHoverRecord: handleRecordHover({
        onChangeHoveredRecord,
        selectRecord,
        targetRecord
      }),
      onDragEnd: handleRecordDragEnd({
        onChangeHoveredRecord,
        selectRecord,
        targetRecord
      }),
      draggable: isRecordDraggable
    };
  }, []);
  return { dragHandler };
};
