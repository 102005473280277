import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { EMPTY_STRING } from '@common/constants';

export const CONTACTS_INITIAL_STATE = { contactOptions: null, loading: false, error: EMPTY_STRING };

const initialState: TenderOwnStore = {
  carriers: { myCarriers: {}, search: {}, loading: false },
  tenderData: null,
  tenders: null,
  tenderAction: TenderAction.Start,
  isFetching: false,
  error: [],
  users: null,
  accountUsers: null,
  contacts: CONTACTS_INITIAL_STATE,
  templateList: undefined,
  restrictions: null,
  dataForGroupTenderCreation: {},
  initialDataForGroupTenderCreation: {},
  planID: null,
  replicateDocument: false
};

export default initialState;
