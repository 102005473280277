import { ChangeEvent, FC, useState } from 'react';
import {
  ILSButton,
  ILSForm,
  ILSFormItem,
  ILSInput,
  ILSInputNumber,
  ILSPhoneInput,
  ILSRadioGroup,
  ILSSelect,
  ILSTitle
} from '@components/index';
import { ReactSVG } from 'react-svg';
import { RegisterForm } from '../types/components';
import { useAppSelector } from '@core/hooks';
import { accountRolesSelector } from '@modules/current-user/selectors/auth';
import { COMPANY_OPTIONS, CompanyValue } from '@common/constants/options';
import { validatorInn } from '@common/utils/validators';
import { generateRolesSelect } from '@modules/account/utils';
import { Label } from '@common/constants';

const ILSRegisterFormComponent: FC<RegisterForm> = ({ onFinish, isFetching }) => {
  const accountRoles = useAppSelector(accountRolesSelector);
  const [company, setCompany] = useState<CompanyValue>(CompanyValue.New);
  const isNew = company === CompanyValue.New;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setCompany(e.target.value as CompanyValue);

  // const { handleTimezone, timezone } = useTimezonePicker();

  const roles = generateRolesSelect(accountRoles);

  const link = window.location.href;

  return (
    <div className="register__form-block">
      <ILSTitle className="register__title mb-2" title="Регистрация" />
      <ILSForm className="" layout={'vertical'} onFinish={onFinish} initialValues={{ accountType: CompanyValue.New, link }}>
        <div className="register__checkboxes">
          <ILSFormItem name="accountType">
            <ILSRadioGroup options={COMPANY_OPTIONS} onChange={onChange} defaultValue={company} />
          </ILSFormItem>
        </div>
        {isNew ? (
          <>
            <ILSFormItem
              className="register__textfield"
              name="accountName"
              label="Название компании"
              rules={[{ required: true, message: 'Пожалуйста, введите название компании', whitespace: true }]}
            >
              <ILSInput />
            </ILSFormItem>
            <ILSFormItem
              className="register__textfield"
              name="INN"
              label="ИНН компании"
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Пожалуйста, введите ИНН компании'
                },
                validatorInn
              ]}
            >
              <ILSInputNumber style={{ width: '100%' }} />
            </ILSFormItem>
          </>
        ) : (
          <ILSFormItem
            name="accountCode"
            label="Код организации"
            rules={[{ required: true, message: 'Пожалуйста, введите код организации', whitespace: true }]}
          >
            <ILSInput />
          </ILSFormItem>
        )}

        <ILSFormItem
          className="register__textfield"
          name="name"
          label="Фамилия Имя Отчество"
          rules={[{ required: true, message: 'Пожалуйста, введите ФИО', whitespace: true }]}
        >
          <ILSInput />
        </ILSFormItem>

        <ILSFormItem
          className="register__textfield"
          label="Телефон"
          name="phone"
          rules={[{ required: true, message: 'Пожалуйста, введите Ваш номер' }]}
        >
          <ILSPhoneInput />
        </ILSFormItem>

        <ILSFormItem
          className="register__textfield"
          name="email"
          label="E-mail"
          rules={[
            { type: 'email', message: 'Некорректное значение E-mail' },
            { required: true, message: 'Пожалуйста, введите Ваш E-mail!' }
          ]}
        >
          <ILSInput />
        </ILSFormItem>

        {isNew && (
          <ILSFormItem className="register__textfield" label={Label.AccountRole} name="accountRole">
            <ILSSelect mode="multiple" allowClear style={{ width: '100%' }} options={roles} />
          </ILSFormItem>
        )}

        <ILSFormItem className="m-0 hidden-input" name="link">
          <ILSInput type="hidden" />
        </ILSFormItem>

        <ILSFormItem className="mb-0">
          <ILSButton type="primary" className="mt-4" htmlType="submit">
            {isFetching && <ReactSVG className="svg-container" src={'/assets/icons/loading.svg'} />}
            Регистрация
          </ILSButton>
        </ILSFormItem>
      </ILSForm>
    </div>
  );
};

export default ILSRegisterFormComponent;
