import { DictionaryAPI } from '@common/api/common';
import { DictionariesEndpoint, Dictionary, Order, Project, ShowDeleted } from '@common/types';
import { DictionaryOperationRequest } from '@common/types/general/api/dictionary';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { dictionaryPlannerRoutine, planningUpdatePlansAndRoutesAction } from '../../actions';
import { createIndexedArrayFromArray } from '@common/utils/helpers/array/create-indexed-array-from-array';
import { generateUUID } from '@common/utils/general/uuid';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { TABLES_WITH_ORDER_DEPENDENCIES_UPDATE } from '../../constants';
import { planningUpdateProjectRoutine } from '@modules/planning/actions';
import { getMessageForOperation } from '@modules/planning/children/by-date/helpers/sagas/table-data/get-message-for-operation';
import { planResolvedOrdersIDsSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { isArray } from 'lodash';
import { TABLES_WITH_DEPOT_DEPENDENCIES_UPDATE } from '@modules/planning/children/by-date/constants/result';

export function* dictionaryPlannerWorker(
  action: PayloadAction<
    DictionaryOperationRequest & {
      dictionaryFetching?: boolean;
      projectListUpdate?: boolean;
      messages?: {
        request: string;
        success: string;
        failure: string;
        fulfill: string;
      };
    }
  >
) {
  const { request, success, failure, fulfill } = dictionaryPlannerRoutine;
  const {
    dictionary,
    ids,
    dictionaryFetching,
    showDeleted = ShowDeleted.No,
    projectID,
    projectListUpdate = false,
    operation = DictionariesEndpoint.Index,
    values,
    getFullData,
    messages
  } = action.payload;

  const message = getMessageForOperation(operation, messages);
  const notifyKey = generateUUID();

  try {
    yield put(
      request({
        dictionaryFetching,
        dictionary,
        notifyKey,
        message: message.request
      })
    );

    const activeProjectID: Project['ID'] | null = yield select(activeProjectIdSelector);

    const {
      data: { data }
    } = yield call(DictionaryAPI.operation, {
      activeProjectID,
      projectID,
      dictionary,
      ids,
      showDeleted,
      getFullData,
      operation,
      values
    });

    yield put(
      success({
        data: createIndexedArrayFromArray(data),
        dictionary,
        operation,
        ids,
        getFullData,
        message: operation === DictionariesEndpoint.Delete ? data : message.success
      })
    );

    switch (dictionary) {
      case Dictionary.Project:
        const projectActiveID: Project['ID'] = yield select(activeProjectIdSelector);
        if (projectListUpdate && projectActiveID in data) {
          for (let [ID, project] of Object.entries(data)) {
            yield put(planningUpdateProjectRoutine.trigger({ ID, project }));
            yield delay(2000);
          }
        }
        break;
      case Dictionary.Order:
        const resolvedOrdersIDs: Set<Order['ID']> = yield select(planResolvedOrdersIDsSelector);
        const checkIDs = isArray(ids) ? ids : [ids];
        const updateRoutes = checkIDs.some((id) => resolvedOrdersIDs.has(Number(id)));
        yield put(planningUpdatePlansAndRoutesAction({ updateRoutes }));
        break;
      case Dictionary.Worktime:
      case Dictionary.Depot:
        if (operation !== DictionariesEndpoint.Index) {
          yield put(planningUpdatePlansAndRoutesAction({ updateRoutes: true }));
        }
        break;
      default:
        break;
    }

    if (TABLES_WITH_ORDER_DEPENDENCIES_UPDATE.includes(dictionary) && operation !== DictionariesEndpoint.Index) {
      const projectID: Project['ID'] = yield select(activeProjectIdSelector);
      yield put(dictionaryPlannerRoutine({ dictionary: Dictionary.Order, projectID, getFullData: true }));
    }
    if (TABLES_WITH_DEPOT_DEPENDENCIES_UPDATE.includes(dictionary) && operation !== DictionariesEndpoint.Index) {
      yield put(dictionaryPlannerRoutine({ dictionary: Dictionary.Depot }));
    }
  } catch (error) {
    yield put(failure({ error, notifyKey, message: message.failure }));
  } finally {
    yield put(fulfill({ dictionary, notifyKey, message: message.fulfill }));
  }
}

export function* dictionaryPlannerWatcher() {
  yield takeEvery(dictionaryPlannerRoutine.trigger, dictionaryPlannerWorker);
}
