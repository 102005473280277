import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';

export const PLANNER_ACHEME_EDITOR_UPLOAD_INI_FORMAT: Array<PermittedUploadFileType | PermittedUploadFileExtensions> = [
  PermittedUploadFileType.ini
];

export const PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_TYPES: Array<PermittedUploadFileType> = [PermittedUploadFileType.ini];

export const PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_EXTENSIONS: Array<PermittedUploadFileExtensions> = [
  PermittedUploadFileExtensions.ini
];
