import { cleanNil } from '@common/utils/helpers/object';
import { isEmpty, isObject, merge } from 'lodash';

/**
 * Функция обновления данных с Api при указании параметра update
 * @param data текущие данные, которые необходимо обновить
 * @param updateData обновленные данные
 * @returns Возвращает объект с обновленными свойствами
 */
export const updateMonitoringData = <T extends object>(data: T, updateData: Partial<T>): T => {
  if (isEmpty(updateData) || !isObject(updateData) || !isObject(data)) return data;
  return cleanNil(merge(data, updateData));
};
