import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tenderGetLotTemplateRoutine, tenderTemplateFullRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { lotTemplateListSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { ILSTypographyTitle } from '@components/general/typography';
import { CardTab, CardTabTitle } from '@modules/tender-platform/constants';
import CustomScroll from 'react-customscroll';
import { ILSRadioGroup } from '@common/components';
import { TENDER_LOT_TEMPLATE_EMPTY_LABEL } from '@modules/tender-platform/constants/lot-card';

export const TenderPlatformLotTemplateFieldset: FC = () => {
  const dispatch = useDispatch();
  const lotTemplateList = useSelector(lotTemplateListSelector);

  const options =
    lotTemplateList &&
    Object.keys(lotTemplateList).map((lotID) => ({
      value: lotID,
      label: lotTemplateList[lotID] ? lotTemplateList[lotID] : TENDER_LOT_TEMPLATE_EMPTY_LABEL
    }));

  useEffect(() => {
    dispatch(tenderGetLotTemplateRoutine());
  }, []);

  const onTemplateClick = (lotID: string) => dispatch(tenderTemplateFullRoutine({ lotID }));

  const handleChange = (e: any) => onTemplateClick(e.target.value);

  return (
    <fieldset className="template-list">
      <ILSTypographyTitle level={3} id={CardTab.Template} children={CardTabTitle.Template} />
      <CustomScroll>
        <ILSRadioGroup
          onChange={handleChange}
          className={'lot-template-list'}
          optionType={'button'}
          buttonStyle="solid"
          options={options}
        />
      </CustomScroll>
    </fieldset>
  );
};
