import { useHistory } from 'react-router';

/**
 * Return:
 * url of useHistory().pathname or useHistory().pathname + useHistory().search (depends of param hasSearchParameter)
 * push of useHistory().push
 * search of useHistory().location
 * @param {boolean} hasSearchParameter - If needed history.location.search part
 */
export const useAppHistory = (hasSearchParameter: boolean = false) => {
  const {
    push,
    goBack,
    location: { pathname, search }
  } = useHistory();
  return { url: hasSearchParameter ? pathname + search : pathname, push, search, goBack };
};
