import { Dispatch, FC, MouseEvent, SetStateAction, useState } from 'react';
import CustomScroll from 'react-customscroll';
import classNames from 'classnames';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';
import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { DeleteTemplateRequest } from '@modules/documents/types/documents-api';
import { ILSTypographyText } from '@common/components';
import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';

interface IProps {
  documentTemplates: DocumentTemplate[];
  template: DocumentTemplate | undefined;
  setTemplate: Dispatch<SetStateAction<DocumentTemplate | undefined>>;

  handleDeleteReport({ id }: DeleteTemplateRequest): void;
}

export const DocumentsTemplateListComponent: FC<IProps> = ({ template, setTemplate, documentTemplates, handleDeleteReport }) => {
  const [isShown, setIsShown] = useState<DocumentTemplate['ID'] | null>(null);

  return (
    <div className="templates-container">
      <CustomScroll>
        {documentTemplates.map((t) => {
          const handleMouseLeave = () => setIsShown(null);

          const handleMouseEnter = () => setIsShown(t.ID);

          const deleteTemplate = () => handleDeleteReport({ id: t.ID });

          const confirmDelete = (e: MouseEvent<HTMLSpanElement>) => {
            e.stopPropagation();
            ILSUserNotifyConfirm('Удалить отчёт?', '', deleteTemplate);
          };

          const pickTemplate = () => setTemplate(t);

          const hover = isShown === t.ID;

          return (
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={classNames('template-block', {
                active: template === t,
                hover
              })}
              key={t.ID}
              onClick={pickTemplate}
              title={t.Name}
            >
              <ILSTypographyText ellipsis>{t.Name}</ILSTypographyText>
              {Boolean(hover && t.isReport) && <DeleteOutlined onClick={confirmDelete} className={'ils-svg error pointer'} />}
            </div>
          );
        })}
      </CustomScroll>
    </div>
  );
};
