import { DefaultUserParams, MonitoringParameters, PlanningParameters, SettingsState } from '@modules/settings/types/store';
import { getInitialState } from '@common/utils/helpers/store/get-initial-state';
import { Module } from '@common/types';
import { EMPTY_OBJECT_DATA } from '@common/constants';
import { DeepRequired } from '@common/types/general/general';

const INITIAL_SETTINGS_STATE: SettingsState = {
  data: {} as DeepRequired<DefaultUserParams>,
  values: {
    planning: {
      parameters: {} as PlanningParameters
    },
    monitoring: {
      parameters: {} as MonitoringParameters
    }
  },
  diffSettings: null,
  success: null,
  mobileApplication: {
    ForwarderPointStatusesGroup: EMPTY_OBJECT_DATA,
    ForwarderPointStatuses: EMPTY_OBJECT_DATA,
    ForwarderPointStatusesFocusRowID: undefined,
    ForwarderPointStatusesGroupFocusRowID: undefined,
    iconsURL: EMPTY_OBJECT_DATA
  }
};

export const initialState = getInitialState(INITIAL_SETTINGS_STATE, Module.Settings);

