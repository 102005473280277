import { defineModule } from '@core/modules';
import { FeatureModule } from '@common/types/modules/module';
import { QuestionsContainer } from '@common/feature/questions/containers/questions-container';
import questionsReducer from '@common/feature/questions/reducers';

export default defineModule({
  title: FeatureModule.Questions,
  component: QuestionsContainer,
  reducer: questionsReducer
});
