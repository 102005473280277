import { ServicesResponsePrice } from '@modules/orders-and-trips/types/integrations';

export const getProposalTerm = ({
  maxDelivery,
  minDelivery,
  unitDelivery
}: {
  maxDelivery: ServicesResponsePrice['maxDelivery'];
  minDelivery: ServicesResponsePrice['minDelivery'];
  unitDelivery: ServicesResponsePrice['unitDelivery'];
}) => {
  if (!(maxDelivery && minDelivery)) return 'Сроки доставки уточняйте у оператора.';
  if (!maxDelivery) return `${minDelivery} ${unitDelivery}`;
  if (!minDelivery) return `${maxDelivery} ${unitDelivery}`;
  if (maxDelivery === minDelivery) return `${minDelivery} ${unitDelivery}`;
  return `${minDelivery} – ${maxDelivery} ${unitDelivery}`;
};
