import { createSelector } from 'reselect';
import { TenderOwnState } from '@modules/tender-platform/children/tender-own/types';
import { currentTenderLotSelector } from '@modules/tender-platform/selectors';
import { TenderModule } from '@modules/tender-platform/types';
import { Module } from '@common/types';
import { createRestrictionArray } from '@modules/tender-platform/children/tender-own/utils/helprers/create-restriction-array';

export const restrictionsSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.restrictions,
  (restrictions) => restrictions
);

export const restrictionsFromLotSelector = createSelector(currentTenderLotSelector, (lot) => {
  return createRestrictionArray(lot);
});

export const restrictionsOptionsSelector = (module: TenderModule) =>
  createSelector(restrictionsSelector, restrictionsFromLotSelector, (restrictions = [], restrictionsFromLot = []) => {
    return module === Module.TenderOwn ? restrictions ?? [] : (restrictions ?? []).concat(restrictionsFromLot);
  });
