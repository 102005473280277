import { useDispatch, useSelector } from 'react-redux';
import { FormType, ModalWindowContainerType, RecordType } from '@modules/orders-and-trips/types/general';
import { Key, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  activeRecordRoutine,
  getTripNOrderDataRoutine,
  getUnresolvedOrdersRoutine,
  setActiveModalWindowRoutine,
  showFormRoutine
} from '@modules/orders-and-trips/actions';
import { Dictionary, Keys } from '@common/types';
import {
  activeOrderRecordSelector,
  decoratedOrdersNPaginationSelector,
  unresolvedOrdersFetchSelector
} from '@modules/orders-and-trips/selectors';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';
import { UsergroupAddOutlined, BranchesOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { useOrdersAndTripsFilter } from '@modules/orders-and-trips/hooks/use-orders-and-trips-filter';

/**
 *
 * @returns {
 * recordTableButtons,
 * onSelectRows - колбэк для получение выделенных строк таблице,
 * unresolvedOrders - массив нераспределенных заявок,
 * isFetching - статус загружено/загружается,
 * activeOrderRecord - активная сфокусированная заявка в таблице
 * handleFocusRow - колбэк, который вызывается при смене фокусировки таблицы
 * }
 */

export const useUnresolvedOrders = () => {
  const [selectedOrders, setSelectedOrders] = useState<Keys>([]);
  const { orders, pagination } = useSelector(decoratedOrdersNPaginationSelector);
  const activeOrderRecord = useSelector(activeOrderRecordSelector);
  const isFetching = useSelector(unresolvedOrdersFetchSelector);
  const dispatch = useDispatch();

  const { filteredDataSource, filterID, handleChangeFilterID } = useOrdersAndTripsFilter({
    dataSource: orders,
    dictionary: Dictionary.Order
  });

  const handleShowForm = (formType: FormType | null) => {
    dispatch(showFormRoutine({ formType }));
  };

  const setActiveOrderRecord = (key?: Key) => {
    dispatch(activeRecordRoutine.success({ recordType: RecordType.Order, value: { ID: key } }));
  };

  const handleCreateOrder = () => {
    handleShowForm(FormType.CreateOrder);
    setActiveOrderRecord();
  };

  const getTripNOrderData = (ids: Keys, formType: FormType) => {
    dispatch(getTripNOrderDataRoutine({ ids, formType }));
  };

  const handleActiveOrder = (key: number) => {
    setActiveOrderRecord(key);
    getTripNOrderData([key], FormType.UpdateOrder);
  };

  const handleCreateTrip = () => {
    getTripNOrderData(selectedOrders, FormType.Trip);
  };

  const onImport = () => {
    dispatch(setActiveModalWindowRoutine.success({ activeModal: ModalWindowContainerType.ImportExport }));
  };

  const loadOrders = () => {
    dispatch(
      getUnresolvedOrdersRoutine({
        params: {
          page: Number(pagination.page) + 1,
          perPage: pagination.perPage
        }
      })
    );
  };

  const orderActionButtons = [
    {
      Component: ActionButton,
      args: {
        text: 'Создать заявку',
        click: handleCreateOrder,
        icon: <UsergroupAddOutlined style={{ fontSize: '18px' }} />
      }
    },
    // Внедрение импорта заявок врменно приостановлено
    {
      Component: ActionButton,
      args: {
        text: 'Импорт заявок',
        click: onImport,
        type: 'dashed',
        icon: <CloudUploadOutlined style={{ fontSize: '18px' }} />
      }
    },
    {
      Component: ActionButton,
      args: {
        text: ' Создать рейс',
        click: handleCreateTrip,
        disabled: isEmpty(selectedOrders),
        tooltip: true,
        title: !isEmpty(selectedOrders) ? 'Нажмите, чтобы создать рейс' : 'Выберите одну заявку из таблицы ниже',
        icon: <BranchesOutlined style={{ fontSize: '18px' }} />
      }
    }
  ];

  const onSelectOrders = (data: { ID: number; checked: boolean }) => {
    setSelectedOrders((prev) => {
      const { ID, checked } = data ?? {};
      let newSelectedOrders = [];
      if (ID) {
        if (checked) {
          newSelectedOrders = Array.from(new Set([...prev, ID]));
        } else {
          newSelectedOrders = prev.filter((prevID) => prevID !== ID);
        }
        if (isEmpty(newSelectedOrders)) {
          dispatch(showFormRoutine({ formType: null }));
        }
        return newSelectedOrders;
      }
      return prev;
    });
  };

  /** Получаем заявки */
  useEffect(() => {
    dispatch(getUnresolvedOrdersRoutine({ resetActiveRecords: true }));
  }, []);

  useEffect(() => {
    if (pagination.page === 1) {
      setSelectedOrders([]);
    }
  }, [orders]);

  return {
    orderActionButtons,
    onSelectOrders,
    orders: filteredDataSource,
    pagination,
    isFetching,
    activeOrderRecord,
    handleActiveOrder,
    amountOrders: Object.keys(orders ?? {}).length,
    loadOrders,
    selectedOrders,
    filterID,
    handleChangeFilterID
  };
};

