import { FC, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { ILSCollapse, ILSCollapsePanel, ILSTable } from '@common/components';
import { tableCustom } from '@core/containers/table-custom';
import { PLANS_TABLE_CONFIG } from '@common/models/project/config';
import { Dictionary, IILSTableProps, TableName } from '@common/types';
import {
  PLAN_TABLE_COLLAPSE_HEADER_HEIGHT,
  PLAN_TABLE_HEADER_HEIGHT,
  PLAN_TABLE_ROW_HEIGHT,
  PLAN_TABLE_SCROLL_WIDTH,
  PLAN_VALIDATION_BLOCK_HEIGHT
} from '@modules/planning/children/by-date/constants';
import { useContextMenu } from '@modules/planning/children/by-date/hooks';
import { PlannerPlansTableContextMenuContainer } from '@modules/planning/children/by-date/containers/result/context-menu/plans';
import { IPlanningResultTablesContainerProps, PlanningByDateState } from '@modules/planning/children/by-date/types';
import { usePlansTableRecordButtons } from '@modules/planning/children/by-date/hooks/tables/use-plans-table-record-buttons';
import {
  dataSourceForPlansTableSelector,
  refDataForPlansTableSelector
} from '@modules/planning/children/by-date/selectors/components/tables/plans';
import { ILSTypographyTitle } from '@components/general/typography';
import { usePlansCollapse } from '@modules/planning/children/by-date/hooks/tables/plans/use-plans-collapse';
import { usePlanning } from '@modules/planning/children/by-date/hooks/tables/plans/use-planning';
import { useHandleSavePlan } from '@modules/planning/children/by-date/hooks/tables/plans/use-handle-save-plan';
import { PlansTableRecord } from '@modules/planning/children/by-date/types/record';
import { canEditPlan } from '@modules/planning/children/by-date/helpers';
import { projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export interface IPlannerPlanningPlansTableContainerProps
  extends Pick<IPlanningResultTablesContainerProps, 'handleShowOrderTable' | 'setTriggerMapFocus' | 'settingsTableButtons'>,
    Pick<IILSTableProps<PlansTableRecord>, 'virtual'> {
  tableRef: MutableRefObject<HTMLDivElement | null>;
  onTableRowClick: IPlanningResultTablesContainerProps['onTableRowClick'];
}

export const PlannerPlanningPlansTableContainer: FC<IPlannerPlanningPlansTableContainerProps> = ({
  tableRef,
  handleShowOrderTable,
  setTriggerMapFocus,
  ...props
}) => {
  const { Planning } = usePlanning();
  const { dataSource } = useSelector((state: PlanningByDateState) => dataSourceForPlansTableSelector(state, Planning));
  const { refData } = useSelector(refDataForPlansTableSelector);
  const activePlanID = useSelector(activePlanIDSelector);
  const projectIsFetching = useSelector(projectIsFetchingSelector);

  const { defaultActiveKey, onChangeCollapsePanel } = usePlansCollapse();
  const { tableButtons } = usePlansTableRecordButtons({
    handleShowOrderTable
  });
  const { onContextOpen, onContextClose, contextMenu, hasContextMenu } = useContextMenu({ tableRef });
  const { handleSave } = useHandleSavePlan();

  return (
    <>
      <ILSCollapse
        destroyInactivePanel={false}
        className={'main-collapse'}
        bordered={false}
        defaultActiveKey={defaultActiveKey}
        onChange={onChangeCollapsePanel}
      >
        <ILSCollapsePanel key={Dictionary.Project} header={<ILSTypographyTitle children={'Планы'} level={4} />}>
          <PlansTableComponent
            {...props}
            focusRow={{ key: activePlanID }}
            loading={projectIsFetching}
            onCanEdit={canEditPlan}
            handleSave={handleSave}
            refData={refData}
            recordTableButtons={tableButtons}
            onContextOpen={onContextOpen}
            tableRef={tableRef}
            dataSource={dataSource}
          />
        </ILSCollapsePanel>
      </ILSCollapse>
      {hasContextMenu && (
        <PlannerPlansTableContextMenuContainer
          setTriggerMapFocus={setTriggerMapFocus}
          handleShowOrderTable={handleShowOrderTable}
          onContextClose={onContextClose}
          visible={contextMenu?.visible}
          record={contextMenu?.record}
          coords={contextMenu?.coords}
          maxHeight={contextMenu?.maxHeight}
        />
      )}
    </>
  );
};

const PlansTable: FC<IILSTableProps<PlansTableRecord>> = (props) => {
  const planTableOpenHeight =
    (props.dataSource?.length ?? 0) * PLAN_TABLE_ROW_HEIGHT +
    PLAN_VALIDATION_BLOCK_HEIGHT +
    PLAN_TABLE_HEADER_HEIGHT +
    PLAN_TABLE_HEADER_HEIGHT +
    PLAN_TABLE_COLLAPSE_HEADER_HEIGHT;

  return (
    <ILSTable
      {...props}
      pagination={false}
      scroll={{
        x: PLAN_TABLE_SCROLL_WIDTH,
        y: `calc(100% - ${planTableOpenHeight}px)`
      }}
    />
  );
};

const PlansTableComponent = tableCustom(PlansTable, PLANS_TABLE_CONFIG, TableName.PlansTable);
