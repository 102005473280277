import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { getTripContractRoutine } from '@modules/orders-and-trips/actions';
import { getContractDataToTemplate } from '@modules/orders-and-trips/helpers/get-contract-data-to-template';
import { onFailure } from '@common/utils/helpers/reducers';
import { ContractTemplateResponseType } from '@modules/orders-and-trips/types/contract-template';

export const getTripContractReducer = createReducer(initialState, {
  [getTripContractRoutine.REQUEST]: (state: InitialState) => {
    state.tripContract.isFetching = true;
    return state;
  },
  [getTripContractRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: ContractTemplateResponseType;
    }>
  ) => {
    const { data } = action.payload;
    const modifiedContractData = getContractDataToTemplate(data.data);
    state.tripContract.contractTemplate = { template: data.template, data: modifiedContractData };
    return state;
  },
  [getTripContractRoutine.FAILURE]: onFailure,
  [getTripContractRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripContract.isFetching = false;
    return state;
  }
});
