import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createTripRoutine,
  getUnresolvedOrdersRoutine,
  switchActiveMainTabRoutine,
  showFormRoutine,
  switchTripStatusRoutine,
  getTotalCountRoutine
} from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITripDataRequestValues } from '@modules/orders-and-trips/types/form';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { MainTabs, MainTabsContainer } from '@modules/orders-and-trips/types/general';

function* createTripWorker(
  action: PayloadAction<{
    tripData: ITripDataRequestValues;
  }>
) {
  const { request, failure, fulfill, success } = createTripRoutine;
  const { tripData } = action.payload ?? {};
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.createTrip, tripData);

    if (data) {
      yield put(switchActiveMainTabRoutine.success({ tab: MainTabs.Trip, container: MainTabsContainer.leftBlock }));
      yield put(getUnresolvedOrdersRoutine.trigger({ resetActiveRecord: true }));
      yield put(switchTripStatusRoutine.trigger({ status: TripStatus.New, activeTrip: { ID: data.tripID } }));
      yield put(showFormRoutine.trigger({ formType: null }));
      yield put(getTotalCountRoutine.trigger());
    }
    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* createTripWatcher() {
  yield takeLatest(createTripRoutine.trigger, createTripWorker);
}

