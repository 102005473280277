import { PlayCircleOutlined } from '@ant-design/icons';
import { useShowQuestionsVideo } from '@common/feature/questions/hooks/use-show-questions-video';
import { QUESTIONS_PREVIEW_PICTURE, QUESTIONS_VIDEO } from '@common/feature/questions/constants';
import CustomScroll from 'react-customscroll';

export const QuestionsVideo = () => {
  const { showVideo, hidePreviewPicture } = useShowQuestionsVideo();

  return (
    <div className="questions-video-component">
      <div className="questions-video">
        <CustomScroll>
          {!showVideo ? (
            <div onClick={hidePreviewPicture} className="questions-preview">
              <div className="questions-preview-overlay">
                <PlayCircleOutlined size={25} className="questions-preview-icon" />
              </div>
              <img src={QUESTIONS_PREVIEW_PICTURE} alt={'Превью видео с вопросами и ответами'} />
            </div>
          ) : (
            <iframe
              width="100%"
              height="100%"
              src={QUESTIONS_VIDEO}
              allow="clipboard-write; picture-in-picture;"
              allowFullScreen
              loading="lazy"
            />
          )}
        </CustomScroll>
      </div>
    </div>
  );
};

