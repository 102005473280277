import { CellType, FilterCellType, ShowDeleted, TableConfig } from '@common/types';
import { NEW_RESTRICTION } from '@common/models/restriction/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { PlacementType } from '@common/types/components/table/cell';

const TitleTooltip = {
  RestrictionGroup: 'Группа специфик определяет их НЕСОВМЕСТИМОСТЬ, заявки с одной группой не могут быть размещены в одной машине'
};

export const RESTRICTION_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: true,
  export: true,
  adding: NEW_RESTRICTION,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,

      sorting: 2,
      filtering: FilterCellType.String,
      fullWidth: true,
      grouping: true
    },
    {
      title: 'Группа несовместимости',
      titleTooltip: TitleTooltip.RestrictionGroup,
      dataIndex: 'RestrictionGroupID',
      key: 'RestrictionGroupID',
      active: true,

      editable: true,
      ellipsis: true,
      width: 150,
      align: 'center',
      isFixedColumn: true,
      type: CellType.SelectAPI,
      titleTooltipPlaceMent: PlacementType.bottom,
      sorting: 1,
      filtering: FilterCellType.Array,
      grouping: true
    }
  ]
};
