import { isObject } from 'lodash';

/**
 * Return the boolean result of comparison of 2 values
 * with Boolean and object check
 * @param value
 * @param comparableValue
 */
export const compareAsString = (
  value?: boolean | string | number | null | object,
  comparableValue?: boolean | string | number | null | object
) => Boolean(String(value) === String(comparableValue)) && !isObject(value) && !isObject(comparableValue);
