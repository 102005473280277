import { FC } from 'react';
import { ILSFormItem, ILSInput, ILSTypographyText } from '@common/components';
import { OptionsSchemaColumnKeys } from '@modules/planning/children/scheme-editor/types/scheme';
import { Dictionary } from '@common/types';

interface IProps {
  options: Array<OptionsSchemaColumnKeys>;
}

export const SchemaEditorOptionsComponent: FC<IProps> = ({ options }) => {
  return (
    <section className={'scheme-editor-options'}>
      {options.map((key) => (
        <fieldset className={'scheme-editor-scheme-input option'}>
          <ILSTypographyText children={key} />
          <ILSFormItem name={[Dictionary.Options, key]} key={key} className={'scheme-editor-scheme-input-title'}>
            <ILSInput className={'scheme-editor-intro-list-item-option'} disabled={key !== 'HeadLines'} />
          </ILSFormItem>
        </fieldset>
      ))}
    </section>
  );
};
