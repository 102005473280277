import { FC } from 'react';
import { useVehicleTypes } from '@modules/orders-and-trips/hooks/use-vehicle-types';
import { VehicleTypeFormField } from '@components/common/forms/vehicle/components/form-items/vehicle-type-form-field';
import { ILSCheckbox, ILSFormItem } from '@common/components';
import { VehicleProfileTypeFormField } from '@components/common/forms/vehicle/components/form-items/vehicle-profile-type-form-field';
import { Field, Label } from '@components/common/forms/constants';
import { DATE_TIME_BLOCK_LOAD, DATE_TIME_BLOCK_UNLOAD } from '@modules/orders-and-trips/constants/fake-pre-cost';

export const VehicleFormFields: FC = () => {
  const { vehicleTypes } = useVehicleTypes();

  return (
    <>
      <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
        <VehicleProfileTypeFormField required className={'fake-pre-cost__form-item'} />
        <VehicleTypeFormField className={'fake-pre-cost__form-item'} vehicleTypes={vehicleTypes} />
        <ILSFormItem className={'fake-pre-cost__form-item checkbox'} valuePropName="checked" name={Field.HydroBoard}>
          <ILSCheckbox label={Label.HydroBoard} />
        </ILSFormItem>
        <ILSFormItem className={'fake-pre-cost__form-item checkbox'} valuePropName="checked" name={Field.LoadersCount}>
          <ILSCheckbox label={Label.LoadersCount} />
        </ILSFormItem>
      </fieldset>
      <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
          <legend>Погрузка</legend>
          {DATE_TIME_BLOCK_LOAD.map(({ Component, name, label, defaultProps }) => {
            return (
              <ILSFormItem required className={'fake-pre-cost__form-item'} key={name} name={name} label={label}>
                <Component {...(defaultProps ?? {})} />
              </ILSFormItem>
            );
          })}
        </fieldset>
        <fieldset className={'fake-pre-cost__form-fieldset fake-pre-cost__form-fieldset_horizontal'}>
          <legend>Разгрузка</legend>
          {DATE_TIME_BLOCK_UNLOAD.map(({ Component, name, label, defaultProps }) => {
            return (
              <ILSFormItem required className={'fake-pre-cost__form-item'} key={name} name={name} label={label}>
                <Component {...(defaultProps ?? {})} />
              </ILSFormItem>
            );
          })}
        </fieldset>
      </fieldset>
    </>
  );
};
