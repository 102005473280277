import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { apiInstance } from '@core/api/instance';
import { IOrdersNTripsApi } from '@modules/orders-and-trips/types/api';
import { EMPTY_STRING } from '@common/constants';

const OrdersNTripsApiPost = <P>({ endpoint, payload }: { endpoint: DictionariesEndpoint; payload?: P }) => {
  return apiInstance().post(getAPIURL(Dictionary.Demand, endpoint), payload);
};
const OrdersNTripsApiGet = <P>({ endpoint, query = EMPTY_STRING }: { endpoint: DictionariesEndpoint; query?: string }) => {
  return apiInstance().get(getAPIURL(Dictionary.Demand, endpoint) + query);
};

export const OrdersNTripsApi: IOrdersNTripsApi = {
  addOrderToServiceFromFake: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.addOrderToServiceFromFakeTender,
      payload
      // : {
      //         data: payload
      //       }
    }),
  sendProjectLogisticsForm: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.sendProjectLogisticData,
      payload: {
        data: payload
      }
    }),
  getOrders: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.Orders, payload }),
  updateUnallocatedOrder: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.UpdateUnallocatedOrder,
      payload
    }),
  getVehicleTypes: () => apiInstance().get(getAPIURL(Dictionary.VehicleType, DictionariesEndpoint.Index)),
  getTripsCount: () => OrdersNTripsApiGet({ endpoint: DictionariesEndpoint.GetTripsCount }),
  getDepot: () => apiInstance().get(getAPIURL(Dictionary.Depot, DictionariesEndpoint.Short)),
  getRestriction: () => apiInstance().get(getAPIURL(Dictionary.Restriction, DictionariesEndpoint.Enum)),
  addUnallocatedOrder: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.AddUnallocatedOrder,
      payload
    }),
  getTripNOrderData: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.GetTripDataForOrders,
      payload: { data: payload }
    }),
  getTripsData: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.GetTripsData, payload }),
  createTrip: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.createTrip, payload }),
  getAvailableIntegrations: () => OrdersNTripsApiGet({ endpoint: DictionariesEndpoint.GetAvailableIntegrations }),
  getPreCost: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.GetPreCost, payload }),
  getFakePreCost: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.FakePreCost, payload }),
  addOrderToService: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.AddOrderToService, payload }),
  getTripDocuments: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Index), payload),
  uploadTripDocuments: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Create), payload),
  deleteTripDocument: (payload) => apiInstance().post(getAPIURL(Dictionary.Attachments, DictionariesEndpoint.Delete), payload),
  getTripTimeline: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.GetTripTimeline, payload }),
  removeOrderFromService: (payload) =>
    OrdersNTripsApiPost({
      endpoint: DictionariesEndpoint.RemoveOrderFromService,
      payload
    }),
  deleteOrder: (payload) => apiInstance().post(getAPIURL(Dictionary.Order, DictionariesEndpoint.Delete), { data: payload }),
  deleteTrip: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.DeleteTrip, payload }),
  getTrack: (payload) => apiInstance().post(getAPIURL(Dictionary.Planning, DictionariesEndpoint.GetTrack), payload),
  getTripContract: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.GetContractData, payload }),
  getFakeTripContract: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.GetFakeTripContract, payload }),
  getTotalCount: () => OrdersNTripsApiGet({ endpoint: DictionariesEndpoint.GetTotalCount }),
  getExportTemplate: (payload) =>
    OrdersNTripsApiGet({
      endpoint: DictionariesEndpoint.GetExportTemplate,
      query: `?addExample=${payload.addExample}`
    }),
  importOrders: (payload) => OrdersNTripsApiPost({ endpoint: DictionariesEndpoint.ImportOrders, payload })
};
