import { FC } from 'react';
import moment from 'moment';
import { ILSButton, ILSCheckbox, ILSDatePicker, ILSForm, ILSFormItem, ILSModal, ILSSelect } from '@common/components';
import ILSInputProjectName from '@components/common/forms/project/inputs/project-name';
import { CreateProject } from '@modules/planning/types/functions';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { useForm, useWatch } from 'antd/es/form/Form';
import { ButtonAction } from '@components/general/buttons';

interface IProps {
  showForm: boolean;
  options?: IILSSelectProps['options'];
  createProject: CreateProject;

  handleFormModalVisible(): void;
}

export enum CreateProjectFormItem {
  Name = 'Name',
  Start = 'Start',
  hasOrders = 'hasOrders',
  PlanID = 'planID'
}

const CREATE_PROJECT_FORM_NAME = 'createProject';

const CREATE_PROJECT_FORM_INITIAL_VALUES = {
  [CreateProjectFormItem.Name]: 'Новый проект',
  [CreateProjectFormItem.Start]: moment(Date.now()),
  [CreateProjectFormItem.hasOrders]: false
};

export const ILSPlanningFormNewProjectComponent: FC<IProps> = ({ showForm, createProject, handleFormModalVisible, options }) => {
  const [form] = useForm();
  const hasOrders = useWatch(CreateProjectFormItem.hasOrders, form);

  const onFinish = (values: Record<CreateProjectFormItem, unknown>) => {
    createProject(values);
    handleFormModalVisible();
  };

  return (
    <ILSModal
      title={'Новый проект'}
      visible={showForm}
      onCancel={handleFormModalVisible}
      footer={
        <>
          <ILSButton action={ButtonAction.Cancel} onClick={handleFormModalVisible} />
          <ILSButton children={'Добавить'} form={CREATE_PROJECT_FORM_NAME} htmlType={'submit'} />
        </>
      }
    >
      <ILSForm
        form={form}
        initialValues={CREATE_PROJECT_FORM_INITIAL_VALUES}
        name={CREATE_PROJECT_FORM_NAME}
        layout={'vertical'}
        onFinish={onFinish}
      >
        <ILSInputProjectName labelAlign={'left'} />
        <ILSFormItem label="Дата проекта" name="Start" className="mb-3">
          <ILSDatePicker picker="date" />
        </ILSFormItem>
        <ILSFormItem valuePropName={'checked'} name="hasOrders" className="mb-3">
          <ILSCheckbox children={'Скопировать нераспределенные заявки'} />
        </ILSFormItem>
        {hasOrders && (
          <ILSFormItem label="План для копирования нераспределенных заявок:" name={CreateProjectFormItem.PlanID} className="mb-3">
            <ILSSelect fieldNames={DEFAULT_FIELD_NAMES} className="item" placeholder="Выберите план" options={options} />
          </ILSFormItem>
        )}
      </ILSForm>
    </ILSModal>
  );
};
