import { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { ModulePath } from '@common/types';

interface TenderPlatformProps<Params extends { [K in keyof Params]?: string } = {}> extends RouteComponentProps<Params> {
  route?: RouteConfig | undefined;
}

export const ILSRoadGraph: FC<TenderPlatformProps> = ({ route }) => {
  const path = ModulePath.RoadGraph + ModulePath.RouteTest;
  return (
    <>
      <Switch>
        <Redirect from={ModulePath.RoadGraph} exact to={path} />
      </Switch>
      {renderRoutes(route?.routes)}
    </>
  );
};
