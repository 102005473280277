import { ILSButton, ILSSpinCenter, ILSTypographyText, ILSUserNotify } from '@components/index';
import { FC } from 'react';
import { string2ArrayBuffer } from '@common/utils/general/string2ArrayBuffer';
import { useSelector } from 'react-redux';
import { EXCEL_MEDIA_TYPE } from '@common/constants/url';
import { Notify } from '@common/types';
import { FileName } from '@modules/planning/children/data/constants';
import { plannerDataDocumentsSelector } from '@modules/planning/children/data/selectors/component';

interface IProps {
  isFetching: boolean;
}

export const PlannerDataFileDownload: FC<IProps> = ({ isFetching }) => {
  const { example, template } = useSelector(plannerDataDocumentsSelector);

  const downloadFile = (addExample: boolean = false) => {
    onDownload(addExample ? FileName.Template : FileName.Example);
  };

  //todo refactoring on Link with href?
  const onDownload = (name: FileName) => {
    const file = name === FileName.Example ? example : template;
    if (!file) {
      ILSUserNotify(Notify.Error, 'Ссылка временно не доступна');
      return;
    }
    const bin = atob(file);
    const data = string2ArrayBuffer(bin);
    const blob = new Blob([data], { type: EXCEL_MEDIA_TYPE });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name + '.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!template) {
    return <ILSSpinCenter />;
  }

  return (
    <>
      <ILSButton
        className={'button-icon-text'}
        loading={isFetching}
        disabled={isFetching}
        type="link"
        onClick={() => downloadFile()}
        icon={'excel'}
      >
        <ILSTypographyText children={'Скачать файл пример данных'} underline strong />
      </ILSButton>
      <ILSButton
        className={'button-icon-text'}
        loading={isFetching}
        disabled={isFetching}
        type="link"
        onClick={() => downloadFile(true)}
        icon={'excel'}
      >
        <ILSTypographyText children={'Скачать шаблон для загрузки данных'} underline strong />
      </ILSButton>
    </>
  );
};
