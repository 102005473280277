import { ILSButton, ILSInput } from '@common/components';
import { AnalyticsWidget, WidgetType } from '@common/types/dictionaries/analytics';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { DEFAULT_WIDGET_TEXT } from '../../constants';

type WidgetLayoutProps = {
  name: string;
  isIntersect: boolean;
  widget?: AnalyticsWidget;
  onChangeText: (text: string) => void;
  setIsFixed: (isFixed: boolean) => void;
};

/***Отображение виджета в режиме редактирования доски
 * @param name - имя виджета
 * @param isIntersect признак, что виджет имеет пересечения с другигми виджетами на доске
 * @param widget - виджет AnalyticsWidget
 * @param onChangeText - событие при редактировании текста виджета
 * @param setIsFixed  - виджет недосутпен для перемещеиня
 */
export const WidgetLayout: FC<WidgetLayoutProps> = ({ name, isIntersect, widget, onChangeText, setIsFixed }) => {
  const [text, setText] = useState(widget?.Text || DEFAULT_WIDGET_TEXT);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setIsFixed(editMode);
  }, [editMode]);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const onChangeTextInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
  };
  const saveText = () => {
    if (!widget) return;
    onChangeText(text);
    toggleEditMode();
  };
  const onBlur = () => {
    if (editMode) setText(widget?.Text || DEFAULT_WIDGET_TEXT);
    toggleEditMode();
  };

  if (widget?.Type == WidgetType.text) {
    return (
      <div className={classNames('analytics-widget-layout-text', { 'analytics-widget-intersect': isIntersect })}>
        {editMode ? (
          <>
            <ILSInput
              onChange={onChangeTextInput}
              onBlur={onBlur}
              maxLength={50}
              className="edit-name-input"
              autoFocus
              type="string"
              onPressEnter={saveText}
              defaultValue={text}
            />

            <ILSButton
              className={'btn-action edit-name'}
              type="link"
              onMouseDown={(e) => e.preventDefault()}
              onClick={saveText}
              icon={<ReactSVG className="svg-container" src={'/assets/icons/confirm.svg'} />}
            />
          </>
        ) : (
          <>
            {text}
            <ILSButton
              className={'btn-action edit-name'}
              type="link"
              onClick={toggleEditMode}
              icon={<ReactSVG className="svg-container" src={'/assets/icons/pen.svg'} />}
            />
          </>
        )}
      </div>
    );
  }
  return (
    <div className={classNames('analytics-widget-layout-container')}>
      <div className={classNames('analytics-widget-layout-body', { 'analytics-widget-intersect': isIntersect })} />

      <div className={classNames('analytics-widget-layout-footer')}>{name}</div>
    </div>
  );
};
