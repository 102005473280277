import { FC } from 'react';
import ILSMapMenuBtn from '@modules/catalog/components/map/btn-menu/btn';
import ILSMapMenuComponent from '@modules/catalog/components/map/btn-menu/menu';
import { IPropsILSMapMenuProps } from '@modules/catalog/types/components';

const ILSMapMenu: FC<IPropsILSMapMenuProps> = (props) => {
  const { show } = props;

  return show ? <ILSMapMenuComponent {...props} /> : <ILSMapMenuBtn {...props} />;
};

export default ILSMapMenu;
