import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { apiInstance } from '@core/api/instance';
import { IPaymentApi } from '@common/feature/payment/types/api';

export const PaymentApi: IPaymentApi = {
  getBalanceInvoiceTemplate: () => apiInstance().get(getAPIURL(Dictionary.AccountBalance, DictionariesEndpoint.BalanceInvoiceTemplate)),
  getBalanceInvoiceData: (payload) => apiInstance().post(getAPIURL(Dictionary.AccountBalance, DictionariesEndpoint.BalanceInvoiceData), payload),
  getCurrentBalance: () => apiInstance().get(getAPIURL(Dictionary.AccountBalance, DictionariesEndpoint.GetBalance)),
  getBalanceHistory: () => apiInstance().get(getAPIURL(Dictionary.AccountBalance, DictionariesEndpoint.getBalanceHistory)),
 
};

