import { MarkerType } from '@common/types';
import { getLatLngExpression } from '@common/utils';
import {
  carMarker,
  colorMarker,
  customMarker,
  defaultMarker,
  destinationMarker,
  orderMarker,
  pointMarker
} from '@core/components/map/icons';
import homeMarker from '@core/components/map/icons/storage.svg';
import loadMarker from '@core/components/map/icons/take.svg';
import clientMarker from '@core/components/map/icons/user.svg';
import { ILSMap, ILSMarker } from '@core/containers/map';
import { Marker } from '@core/types/map';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import { MutableRefObject } from 'react';

export const drawMarkers = (Map: MutableRefObject<ILSMap | null>, markers: Marker[], markerLayer: L.LayerGroup) => {
  if (Map.current) {
    if (!isEmpty(markers)) {
      markers.forEach((marker) => {
        const ID = marker.data?.ID;
        const { color = '#4C91FF' } = marker;

        let icon;
        switch (marker.type) {
          case MarkerType.Color:
            icon = colorMarker(color);
            break;
          case MarkerType.Order:
            icon = orderMarker(color, false, ID, marker.data);
            break;
          case MarkerType.Waypoint:
            icon = pointMarker(marker.data, color, marker.number ?? 0, marker.figure, false, ID);
            break;
          case MarkerType.ForTrack:
            icon = customMarker(clientMarker, '#2358C6');
            break;
          case MarkerType.Storage:
            icon = customMarker(homeMarker, '#4C91FF');
            break;
          case MarkerType.Client:
            icon = customMarker(clientMarker, '#2358C6');
            break;
          case MarkerType.Load:
            icon = customMarker(loadMarker, '#50B993');
            break;
          case MarkerType.Car:
            icon = carMarker();
            break;
          case MarkerType.Destination:
            icon = destinationMarker(marker.text ?? '', '#4C91FF');
            break;
          default:
            icon = defaultMarker;
        }

        if (!isEmpty(marker.coords)) {
          const coords = getLatLngExpression(marker.coords) as L.LatLngExpression;
          const draggable = (marker.type && marker.type === MarkerType.Waypoint) as boolean;
          const newMarker = new ILSMarker({
            coords,
            options: {
              icon,
              draggable,
              data: marker.data
            }
          });

          newMarker.L.addTo(markerLayer);
        }
      });
    }
  }
};

