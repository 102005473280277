import { LatLngExpression } from 'leaflet';
import { Coordinates, isLatLngTuple, LatLngObject } from '@common/types/general/geohash';
import { isObject, isString } from 'lodash';
import { GeohashDecode } from '@common/decorators/latlon-geohash';
import { Depot } from '@common/types';

export const isDepotLatLon = (latLon: object | null): latLon is Depot['LatLon'] => {
  return latLon === null || (latLon.hasOwnProperty('lat') && latLon.hasOwnProperty('lon'));
};

export const isLatLngOrLatLngLiteral = (latLon: object): latLon is LatLngObject => {
  return latLon.hasOwnProperty('lat') && latLon.hasOwnProperty('lng');
};

export const getLatLngExpression = (latLon: Coordinates): LatLngExpression | LatLngExpression[] => {
  if (isString(latLon)) {
    return GeohashDecode(latLon);
  } else if (isObject(latLon)) {
    if (isLatLngTuple(latLon)) {
      return latLon;
    } else {
      if (isLatLngOrLatLngLiteral(latLon)) {
        return latLon;
      } else if (isDepotLatLon(latLon)) {
        return { lat: latLon.lat, lng: latLon.lon };
      }
    }
  }
  return latLon;
};
