import { useMemo } from 'react';

export const useRaf = (callback: (args: any) => any) => {
  const rafAction = useMemo(() => {
    let requestId: number | null = null;

    let lastArgs: any;

    const worker = () => () => {
      requestId = null;
      callback(lastArgs);
    };

    const raf = function (args: any) {
      lastArgs = args;
      if (requestId === null) {
        requestId = requestAnimationFrame(worker());
      }
    };

    raf.cancel = () => {
      requestId && cancelAnimationFrame(requestId);
      requestId = null;
    };

    return raf;
  }, [callback]);

  return rafAction;
};
