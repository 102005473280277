import { Moment } from 'moment';
import { FC } from 'react';
import { FormType } from './general';
import { FormLabels } from '@common/constants/general/content/form';
import { getFormTrip } from '@modules/orders-and-trips/helpers/get-form-trip-and-order';

export { FormLabels, TimeLineLabels, FormPlaceholders } from '@common/constants/general/content/form';

export enum FormSections {
  Cargo = 'Cargo',
  TransportRequirement = 'transportRequirement',
  Points = 'points'
}

export enum FormFieldNames {
  Name = 'Name',
  INN = 'INN',
  KPP = 'KPP',
  SourceID = 'SourceID',
  TargetID = 'TargetID',
  RestrictionID = 'RestrictionID',
  Mass = 'Mass',
  Volume = 'Volume',
  Amount = 'Amount',
  Length = 'Length',
  Width = 'Width',
  Height = 'Height',
  Comment = 'Comment',
  LoadDate = 'LoadDate',
  LoadStart = 'LoadStart',
  LoadEnd = 'LoadEnd',
  UnloadDate = 'UnloadDate',
  UnloadStart = 'UnloadStart',
  UnloadEnd = 'UnloadEnd',
  VehicleType = 'VehicleType',
  VehicleUnloadType = 'VehicleUnloadType',
  VehicleLoadType = 'VehicleLoadType',
  Loading = 'Loading',
  GPSMonitoring = 'GPSMonitoring',
  AmountVehicle = 'AmountVehicle',
  LoadCapacity = 'LoadCapacity',
  Belts = 'Belts',
  TenderName = 'TenderName',
  TenderType = 'TenderType',
  TenderVisibility = 'TenderVisibility',
  TenderDescription = 'TenderDescription',
  StartCollectOffer = 'StartCollectOffer',
  EndCollectOffer = 'EndCollectOffer',
  StartContract = 'StartContract',
  EndContract = 'EndContract',
  MinDriverRating = 'MinDriverRating',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRoutes = 'MinDriverRoutes',
  MaxWeight = 'MaxWeight',
  Distance = 'Distance',
  SourcePoint = 'SourcePoint',
  TargetPoint = 'TargetPoint',
  Cargo = 'Cargo',
  ActionType = 'ActionType',
  DepotID = 'DepotID',
  Contact = 'Contact',
  Phone = 'Phone',
  LoadDateLocal = 'LoadDateLocal',
  LoadersCount = 'loadersCount',
  HydroBoard = 'hydroBoard'
}

export enum TimeLinePoints {
  Load = 'Load',
  Unload = 'Unload'
}

export enum FormInputTypes {
  Select = 'SELECT',
  DateTime = 'DATE_TIME',
  InputText = 'INPUT_TEXT',
  TextArea = 'TEXT_AREA',
  InputNumber = 'INPUT_NUMBER',
  Slider = 'SLIDER'
}

export enum FormName {
  TripToTender = 'tender-trip-form'
}

export type CargoFormFields = {
  [FormFieldNames.Amount]: Array<number>;
  [FormFieldNames.Comment]?: Array<string>;
  [FormFieldNames.Height]: Array<number>;
  [FormFieldNames.Length]: Array<number>;
  [FormFieldNames.Mass]: Array<number>;
  [FormFieldNames.Volume]?: Array<number>;
  [FormFieldNames.Width]: Array<number>;
  [FormFieldNames.RestrictionID]: Array<number | string>;
};

export type OrderFormValues = {
  [FormFieldNames.LoadDate]: Moment;
  [FormFieldNames.LoadEnd]?: Moment;
  [FormFieldNames.LoadStart]?: Moment;
  [FormFieldNames.SourceID]: [number, number];
  [FormFieldNames.TargetID]: [number, number];
  [FormFieldNames.UnloadDate]: Moment;
  [FormFieldNames.UnloadEnd]?: Moment;
  [FormFieldNames.UnloadStart]?: Moment;
  [FormFieldNames.Cargo]: CargoFormFields;
  [FormFieldNames.Contact]: string;
  [FormFieldNames.Phone]: string;
};

export type RequestOrderData = {
  [FormFieldNames.LoadDate]: string;
  [FormFieldNames.LoadEnd]?: string;
  [FormFieldNames.LoadStart]?: string;
  [FormFieldNames.SourceID]: number;
  [FormFieldNames.TargetID]: number;
  [FormFieldNames.UnloadDate]: string;
  [FormFieldNames.UnloadEnd]?: string;
  [FormFieldNames.UnloadStart]?: string;
  [FormFieldNames.LoadDateLocal]?: string;
  [FormFieldNames.Contact]: string;
  [FormFieldNames.Phone]: string;
  [FormFieldNames.Cargo]: {
    [FormFieldNames.Amount]: number;
    [FormFieldNames.Comment]?: number;
    [FormFieldNames.Height]: number;
    [FormFieldNames.Length]: number;
    [FormFieldNames.Mass]: number;
    [FormFieldNames.Volume]?: number;
    [FormFieldNames.Width]: number;
    [FormFieldNames.RestrictionID]: string;
  }[];
};

export type IOrdeFormValues = Record<number, OrderFormValues>;

export interface ITripFormValues extends IOrdeFormValues {
  transportRequirement: {
    AmountVehicle?: string;
    Belts?: string;
    GPSMonitoring?: boolean;
    LoadCapacity?: number;
    Loading?: string;
    VehicleLoadType: string;
    VehicleType: string;
    VehicleUnloadType: string;
  };
}

export interface ITripDataRequestValues {
  points: {
    ActionType: number;
    DepotID: number | string;
    Cargo: {
      Amount: number;
      CargoID: number;
      OrderID: number;
    }[];
  }[];
  transportRequirement: {
    AmountVehicle?: number;
    Belts?: number;
    GPSMonitoring?: number;
    LoadCapacity?: number;
    Loading?: number;
    VehicleLoadType: number;
    VehicleType: number;
    VehicleUnloadType: number;
  };
}

export type FormTrip = ReturnType<typeof getFormTrip>;

export type FormFieldData = {
  Component: FC<any>;
  name: FormFieldNames;
  label: FormLabels;
  defaultProps?: Record<string, any>;
  noDisplay: FormType[];
};

export enum DeleteTripKeys {
  DeleteWithOrders = 'deleteWithOrders'
}
export type DeleteTripFields = {
  [DeleteTripKeys.DeleteWithOrders]?: boolean;
};

export type FieldChangeData = {
  errors?: Array<string>;
  name: string | number | (string | number)[];
  touched?: boolean;
  validating?: boolean;
  value?: string;
  warnings?: Array<string | number>;
};
