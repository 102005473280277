export enum ModuleScope {
  Breadcrumbs = 'breadcrumbs',
  CustomForAuthPage = 'customForAuthPage',
  CustomWithoutAuthPage = 'customWithoutAuthPage',
  DocumentBuilder = 'documentBuilder',
  Header = 'header',
  HeaderRight = 'headerRight',
  HeaderLeft = 'headerLeft',
  LoginForm = 'loginForm',
  Logo = 'logo',
  RegisterForm = 'registerForm',
  RecoveryForm = 'recoveryForm',
  Sider = 'sider',
  SiderNav = 'siderNav',
  Title = 'title',
  LogisticServices = 'logisticServices',
  AuthNav = 'authNav'
}
