import { VALIDATION_TABLES, validationErrorTypes } from '../constants';
import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import { Dispatch, FC, useEffect } from 'react';
import { TActiveValidationTable, TChangeActiveValidationTable, TErrorValidationTable } from '@modules/data-validate/types/types';
import { ILSNoDataComponent } from '@components/common/no-data';
import { IDecorateValidationData, Notify } from '@common/types';
import { getErrorsByType } from '../utils';
import { ILSDataInfoComponent } from './info';
import { ValidationErrors } from '@common/types/dictionaries/validation';

interface IProps {
  errorsByDictionary: IDecorateValidationData;
  activeCategory: TErrorValidationTable;
  activeTable: TActiveValidationTable;
  activePanel: string | number | (string | number)[] | undefined;
  changeActiveTable: TChangeActiveValidationTable;
  showAll: boolean;
  isPlanning: boolean | undefined;
  setActiveCategory: Dispatch<TErrorValidationTable>;
  setActivePanel: Dispatch<string | number | (string | number)[] | undefined>;
  dataSource: any;
}

export const DataValidateErrorsComponent: FC<IProps> = (props) => {
  const { errorsByDictionary, activeCategory, changeActiveTable, activeTable, showAll, isPlanning, setActivePanel, activePanel } = props;
  const errorList = errorsByDictionary?.[activeCategory] || [];
  const errorsByType = getErrorsByType(errorsByDictionary, activeCategory);
  const onChange = (val: any) => {
    setActivePanel(val);
    changeActiveTable(activeCategory, val, null);
  };

  useEffect(() => {
    return () => setActivePanel(undefined);
  }, []);

  return (
    <div className={'error-list'}>
      {errorList?.length ? (
        <>
          <ILSCollapse className="main-collapse" onChange={onChange} accordion activeKey={activePanel}>
            {Object.entries(errorsByType).map(([errorType, values]) => {
              if (!showAll && ValidationErrors[errorType] !== Notify.Error) {
                return <></>;
              }
              <h3>{VALIDATION_TABLES?.[activeCategory].text}:</h3>;
              return (
                Boolean(values.length) && (
                  <ILSCollapsePanel
                    className={'ils-error-collapse ils-error-collapse-' + errorType}
                    key={errorType}
                    header={validationErrorTypes[errorType].text}
                  >
                    {Boolean(activeTable?.table && (showAll || activeTable.kind)) && (
                      <ILSDataInfoComponent
                        {...props}
                        isPlanning={isPlanning}
                        validateData={errorsByDictionary}
                        showAll
                        activeTable={activeTable}
                        validate={!!errorsByDictionary}
                        errorList={values}
                      />
                    )}
                  </ILSCollapsePanel>
                )
              );
            })}
          </ILSCollapse>
        </>
      ) : (
        showAll && <ILSNoDataComponent className={'bg-white position-relative p-3 pr-5 flex-direction-column'} text={'Ошибок не найдено'} />
      )}
    </div>
  );
};
