import { call, put, select, takeLatest } from 'redux-saga/effects';
import { tenderLotCreateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderChangeRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint, IndexedArray, User } from '@common/types';
import { TenderLot, TenderLotCreateAction } from '@modules/tender-platform/types';
import { formToSaveData } from '@modules/tender-platform/utils/decorators/form-to-save-data';
import { getLotFormData } from '@modules/tender-platform/children/tender-own/utils/helprers/get-lot-form-data';
import { accountUsersSelector } from '@modules/tender-platform/selectors';
import history from '@common/utils/history';
import { TenderAction } from '../../types';

function* tenderLotCreateWorker(action: TenderLotCreateAction) {
  const { request, failure, fulfill } = tenderLotCreateRoutine;
  const { tenderID, values, updateTenderStatus, url } = action.payload;

  //NOTE: Сохраняем заполненные поля лота во временную переменную
  const accountUsers: IndexedArray<User> = yield select(accountUsersSelector);
  const tmpLotData = getLotFormData(values, accountUsers);

  try {
    yield put(request());
    const { lot } = Array.isArray(values) ? { lot: values } : formToSaveData(values);
    const {
      data: {
        data: { tenderLot }
      }
    } = yield call(TenderOwn.createLot, { tenderID, values: Array.isArray(lot) ? lot : [lot] });

    if (tenderLot) {
      const [TenderLot] = Object.values(tenderLot) as Array<TenderLot>;
      yield put(tenderChangeRoutine.trigger({ TenderLot, operation: DictionariesEndpoint.Create }));
      yield put(tenderChangeRoutine.trigger({ operation: DictionariesEndpoint.Reset }));
    }
  } catch (error) {
    if (tmpLotData) {
      yield put(tenderChangeRoutine.trigger({ TenderLot: [tmpLotData], operation: DictionariesEndpoint.tenderTemplateData }));
    }
    yield put(failure({ error }));
  } finally {
    if (updateTenderStatus) {
      yield put(tenderReadRoutine.trigger({ id: tenderID }));
    }
    if (tenderID && url && !updateTenderStatus) {
      yield call(history.push, url.replace(TenderAction.Create, String(tenderID)));
    }
    yield put(fulfill());
  }
}

export function* tenderLotCreateWatcher() {
  yield takeLatest(tenderLotCreateRoutine.trigger, tenderLotCreateWorker);
}

