import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { getRouteByCoordsRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { MapRouteByCoords } from '@modules/orders-and-trips/types/trip';

export const getRouteByCoordsReducer = createReducer(initialState, {
  [getRouteByCoordsRoutine.REQUEST]: (state: InitialState) => {
    //state.tripDocuments.isFetching = true;
    return state;
  },
  [getRouteByCoordsRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: string[];
    }>
  ) => {
    const { data } = action.payload;
    const activeTrip = state.trips.activeRecord;
    if (activeTrip?.ID) {
      state.trips.routes[activeTrip.ID] = data;
    }
    return state;
  },
  [getRouteByCoordsRoutine.FAILURE]: onFailure,
  [getRouteByCoordsRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    // state.tripDocuments.isFetching = false;
    return state;
  }
});
