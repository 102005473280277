import { call, put, takeLatest } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { tenderGetAccountUsersRoutine } from '@modules/tender-platform/actions';

function* tenderGetAccountUsersWorker() {
  const { request, success, failure, fulfill } = tenderGetAccountUsersRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(TenderAPI.getAccountUsers);
    yield put(success({ data: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderGetAccountUsersWatcher() {
  yield takeLatest(tenderGetAccountUsersRoutine.trigger, tenderGetAccountUsersWorker);
}
