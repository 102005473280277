import { Plan } from '@common/types';
import { PlansTableRecord } from '@modules/planning/children/by-date/types/record';

export type PlanningCell = { icon: string; onClick: (record: PlansTableRecord) => void };

export type PlanningConfig = {
  config: {
    planConfigID?: number | null | undefined;
    operation?: string | null | undefined;
    preplan?: number | null | undefined;
    task?: string | null | undefined;
    countIteration?: number | null | undefined;
  };
  planID: Plan['ID'];
};

export enum PlanningConfigEnum {
  PlanConfig = 'planConfigID',
  Provider = 'providerName',
  TypePlanning = 'operation',
  CountIteration = 'countIteration',
  ExternalPlanConfig = 'externalPlanConfigID'
}

export enum PlanningConfigType {
  Validation = 'validation',
  Planning = 'planning'
}
