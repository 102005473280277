import { compareAsString } from '@common/utils';
import { IMonitoringStore } from '@modules/monitoring/types';
import { createSelector } from 'reselect';
import { initialState } from '../store';

// Возвращает данные одной из таблиц в зависимости от ключа
//TODO Если нужна эта реализация, то сделать ее через createSelector
export const EventDetailsSelector = (state: IMonitoringStore, key: keyof IMonitoringStore) => state.MonitoringEventDetails?.[key];

// Возвращает значение активно таба
export const activeTabSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.activeTab,
  (activeTab) => activeTab
);

// Возвращает события по автомобилю
export const vehicleEventsSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.VehicleEvents,
  (VehicleEvents) => VehicleEvents
);

// Возвращает данные по плану для автомобиля
export const vehiclePlansSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.VehicleEvents.Plan,
  (VehicleEvents) => VehicleEvents
);

// Возвращает данные по датчикам для автомобиля
export const vehicleSensorSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.VehicleSensor,
  (VehicleSensor) => VehicleSensor
);

// Возвращает маршруты для автомобиля
export const tracksSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.VehicleTracks,
  (VehicleTracks) => VehicleTracks
);

// Возвращает ID для выбранного автомобиля
export const activeVehicleIDSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.activeVehicleID,
  (activeVehicleID) => activeVehicleID
);
// Возвращает ID для выбранного двойным кликом автомобиля
export const fixedActiveVehicleIDSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.VehicleTracks.selectedVehicleId,
  (selectedVehicleId) => selectedVehicleId
);

// Возвращает список со статусами точек
export const PointsStatusesListSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.PointsStatusesList,
  (PointsStatusesList) => PointsStatusesList
);

// Возвращает последние данные
export const lastInfoRequestSelector = createSelector(
  (state: IMonitoringStore) => state.MonitoringEventDetails.lastInfoRequest,
  (lastInfoRequest) => lastInfoRequest
);

// Возвращает данные по датчикам для выбранного автомобиля
export const currentVehicleSensorSelector = createSelector(
  vehicleSensorSelector,
  fixedActiveVehicleIDSelector,
  (VehicleSensor, selectedVehicleId) =>
    compareAsString(VehicleSensor.loadedVehicleId, selectedVehicleId) ? VehicleSensor : initialState.VehicleSensor
);
