import { ILSInputNumber } from '@components/index';
import { TableRecord } from '@common/types';
import { IILSCellNumberProps } from '@common/types/components/table/cell';
import { getCellInputValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-value';
import { EMPTY_STRING } from '@common/constants';
import { handleCellIntegerSave } from '@components/data-display/table/utils/helpers/cells/handlers/handle-cell-integer-save';

export const ILSCellInteger = <R extends TableRecord>({
  children,
  toggleEdit,
  save,
  min,
  max,
  step = 1
}: IILSCellNumberProps<R>): JSX.Element => {
  const [node, value = EMPTY_STRING] = children;

  return (
    <>
      {node}
      <ILSInputNumber
        className={'cell-wrapper-editable-full'}
        bordered
        autoFocus
        type="number"
        defaultValue={getCellInputValue(value)}
        min={min}
        max={max}
        step={step}
        onPressEnter={handleCellIntegerSave({ min, max, save, toggleEdit })}
        onBlur={handleCellIntegerSave({ min, max, save, toggleEdit })}
      />
    </>
  );
};
