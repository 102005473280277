import { DictionariesEndpoint, Dictionary, Order, Plan, ResponseAPI, TableName, Trip, Vehicle } from '@common/types';
import { throwError } from '@common/utils/validators/check-data-for-error';
import { PlanningAPI } from '@modules/planning/api';
import {
  addOrderForVehicleRoutine,
  planningRememberFocusNExpandRowsRoutine,
  planningRememberSelectedRowsRoutine
} from '@modules/planning/children/by-date/actions';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';
import { vehicleIDByTripIDSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import { AddOrderForVehicleResponse } from '@modules/planning/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectedOrderTableRowsSelector } from '../../selectors/components/tables/selected-table-rows';
import { RememberFocusNExpandRows } from '../../types/record';
import { FocusNExpandRowsChangingType } from '../../types/tables-container';

export type AddOrderForVehicle = {
  vehicleID?: Vehicle['ID'] | null;
  orderID: Array<Order['ID']>;
  tripID?: Trip['ID'];
};

function* addOrderForVehicleWorker(action: PayloadAction<AddOrderForVehicle>) {
  const { failure, success, fulfill, request } = addOrderForVehicleRoutine;
  const { vehicleID, tripID, orderID } = action.payload;

  try {
    yield put(request());
    const planID: Plan['ID'] = yield select(activePlanIDSelector);
    const routeID: Vehicle['ID'] | undefined = yield select((state) => vehicleIDByTripIDSelector(state, tripID));
    const { selectedOrders }: { selectedOrders: Array<Order['ID']> } = yield select(selectedOrderTableRowsSelector);
    const noVehicleForAdd = !(vehicleID || routeID);
    const noExistTripForAdd = !tripID && vehicleID;

    if (noVehicleForAdd) {
      throwError({ message: 'Автомобиль не найден или удалён' });
    } else {
      const rememberFocusNExpandRowsPayload: RememberFocusNExpandRows = {
        changingType: FocusNExpandRowsChangingType.CheckAndUpdate,
        tableName: TableName.RoutesTable,
        lastModifiedData: {
          vehicleID: (vehicleID || routeID)!,
          dictionary: Dictionary.Route,
          operation: DictionariesEndpoint.Create
        }
      };
      const {
        data: {
          data: { oldTrips = [], waypointID }
        }
      }: { data: ResponseAPI<AddOrderForVehicleResponse> } = yield call(PlanningAPI.addOrderForVehicle, {
        planID,
        vehicleID: (vehicleID || routeID)!,
        tripID,
        orderID
      });

      const changedTripsRoutesIDs = [(vehicleID || routeID)!].concat(oldTrips?.map(({ VehicleID }) => VehicleID) || []);
      const {
        data: { data: routeTotal }
      } = yield call(PlanningAPI.getTotal, { planID, vehicleID: changedTripsRoutesIDs, dictionary: Dictionary.Route });
      const {
        data: { data: planTotal }
      } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });

      if (noExistTripForAdd) {
        /** Данные для изменения фокуса активной + expand rows - для нового маршрута */
        rememberFocusNExpandRowsPayload.lastModifiedData!.routeTotal = routeTotal;
      } else {
        /** Данные для изменения фокуса активной + expand rows - для новых добавленных точек */
        rememberFocusNExpandRowsPayload.lastModifiedData!.tripIDs = isArray(tripID) ? tripID : [tripID!];
        rememberFocusNExpandRowsPayload.lastModifiedData!.waypointIDs = isArray(orderID) ? orderID : [orderID];
        rememberFocusNExpandRowsPayload.lastModifiedData!.dictionary = Dictionary.Waypoint;
      }

      yield put(success({ routeTotal, planTotal, waypointID }));
      /**
       * Сброс выделения, если перемещена выделенная заявка
       */
      if (selectedOrders.find((ID) => orderID.includes(ID))) {
        yield put(planningRememberSelectedRowsRoutine({ tableName: TableName.PlanningNotDeliveredOrder }));
      }
      /** Меняем фокус активной строки и раскрываем до вложенных строк */
      yield put(planningRememberFocusNExpandRowsRoutine(rememberFocusNExpandRowsPayload));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* addOrderForVehicleWatcher() {
  yield takeLatest(addOrderForVehicleRoutine.trigger, addOrderForVehicleWorker);
}
