import { PlanningStore } from '@modules/planning/types/store';
import { getInitialState } from '@common/utils/helpers/store/get-initial-state';
import { Module } from '@common/types';

export const INITIAL_PLANNER_STATE: PlanningStore = {
  isDateLoading: false,
  isSaving: false,
  availableDates: [],
  activeProjectID: null,
  projects: {},
  status: null,
  settings: {
    table: {
      showPlans: true
    },
    fullScreen: {
      map: false,
      table: false
    }
  }
};

export const initialState = getInitialState(INITIAL_PLANNER_STATE, Module.Planner);

