import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { FC, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { infoValueToString } from '@modules/analytics/common/utils/info-value-to-string';

type Props = {
  widget: AnalyticsWidget;
  hideWidget: boolean;
};

const ILSInfoArrayWidget: FC<Props> = ({ widget, hideWidget }) => {
  const infoWidgets = useMemo(() => {
    const infoData = widget?.Data?.info;
    if (infoData) {
      const infoValues = Object.values(infoData);
      return typeof infoValues?.[0] === 'object' ? infoValues : [infoData];
    }

    return [];
  }, [widget.Data]);

  const { location } = useHistory();

  return (
    <>
      {!hideWidget && (
        <div className="analytics-info-array-widget analytics-scroll">
          {infoWidgets.map((widget) => {
            return (
              widget && (
                <Link
                  key={widget.title}
                  to={widget.link ? widget.link : location?.pathname}
                  className={classNames('analytics-info-widget', 'analytics-info-array-widget-item', {
                    'analytics-info-widget-inactive': !widget.link.length
                  })}
                >
                  <span className="value">
                    {infoValueToString(widget.value)} {widget.unit}
                  </span>
                  <div className="title">{widget.title} </div>
                </Link>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default ILSInfoArrayWidget;
