// Константы для запросов
export enum MonitoringQueryParams {
  TAB_NAME = 'tabName', // Наименование таба для детализации
  ACTIVE_TAB = 'activeTab', // Текущий активный таб
  WAYPOINT_ID = 'waypointID', // ID точки
  FOCUS_ON_MAP = 'focusOnMap' // Есть ли фокусировка на карте
}

export const UPDATE_METRICS_INTERVAL = 15000; // Временной интервал для апдейта метрик, сейчас 15 секунд

export const MAX_DAYS_INTERVAL = 3; // Максимальный период, который отображает мониторинг, сейчас трое суток
