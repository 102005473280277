import { FC } from 'react';
import { useInvoiceForm } from '@common/feature/payment/hooks/use-invoice-form';
import { InvoiceForm } from '@common/feature/payment/components/forms/invoice-form';

interface IProps {
  handleShowModal(): void;
  openInvoiceViewer(): void;
}

export const InvoiceFormContainer: FC<IProps> = ({ handleShowModal, openInvoiceViewer }) => {
  const { form, submitButtonProps, onFinish } = useInvoiceForm({ handleShowModal, openInvoiceViewer });

  return <InvoiceForm form={form} onFinish={onFinish} submitButtonProps={submitButtonProps} />;
};

