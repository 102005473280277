export enum Action {
  Add = 'add',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Create = 'create',
  Restore = 'restore',
  GetCoordinates = 'getCoordinates',
  BindTimeZone = 'bindTimeZone',
  CopyRow = 'copyRow'
}

export enum Status {
  Error = 'error',
  Loading = 'loading',
  Success = 'success',
  Warning = 'warning'
}

export enum Notify {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Loading = 'loading',
  Warning = 'warning',
  Confirm = 'confirm',
  Bad = 'bad'
}

export enum ErrorLevel {
  Success = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
  Bad = 5
}

export type AlertNotify = Notify.Info | Notify.Success | Notify.Error | Notify.Warning | Notify.Bad;
