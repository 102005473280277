import { FC } from 'react';
import { ILSButton } from '@common/components';
import { PlanningDataUploadForm } from '@modules/planning/children/data/constants';
import { useSelector } from 'react-redux';
import { useLink } from '@common/hooks/history/use-link';
import { ButtonAction } from '@components/general/buttons';
import { dataActionsSelector } from '@modules/planning/children/data/selectors/component';

export const PlannerDataActions: FC = () => {
  const { projectID, url, disabledSubmit, isLoading } = useSelector(dataActionsSelector);
  const { handleClick } = useLink(url);

  return (
    <div className={'planning-data-upload-actions'}>
      {projectID && (
        <ILSButton
          loading={isLoading}
          className={'planning-data-upload-actions-start'}
          type={'primary'}
          children={'Перейти в раздел планирования'}
          onClick={handleClick}
        />
      )}
      <ILSButton
        action={ButtonAction.Save}
        loading={isLoading}
        disabled={disabledSubmit}
        className={'planning-data-upload-actions-submit'}
        form={PlanningDataUploadForm.DataUploadForm}
        type={'primary'}
        htmlType={'submit'}
      />
    </div>
  );
};
