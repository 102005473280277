import { useEffect, useState } from 'react';
import { NEW_ROW_KEY_TO_ADD } from '@components/data-display/table/constants';
import { compareAsString } from '@common/utils';
import { ActiveType, Keys, TableRecord } from '@common/types';
import { SelectedRowKeys } from '@common/types/components/table/table';
import { TableProps } from 'antd/lib/table/Table';
import { UseSelectRow } from '@common/types/components/table/hooks';
import { RowSelectionType } from '@common/types/components/table/functions';

export const useSelectRow = <R extends TableRecord>({
  handleSelectedElements,
  dataSource,
  active,
  selectedRows = [],
  onSelectRows,
  rowSelection,
  selecting,
  onSelectRowsWithRecord,
  tableName
}: UseSelectRow<R>) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeys>(selectedRows); //выбор строк

  //TODO Убрать callBack handleSelectedElements + useEffect, вместо этого использовать onSelectWithRecord/onSelectRows
  //Callback для выделенной цветом или чек боксом строки
  //почему это всё не в onSelect rowSelection?  От этого useEffect необходимо избавляться
  //!config?.externalFocusControl - костыль для этого useEffect, нужно избавиться от handleSelectedElementsDefault, т.к. ломает фокус в других таблицах + лишние рендеры
  useEffect(() => {
    if (handleSelectedElements) {
      if (dataSource) {
        if (selectedRowKeys.length > 0) {
          if (active && active.key === NEW_ROW_KEY_TO_ADD) {
            const records = dataSource.reduce((prev, record) => {
              if (compareAsString(active.key, record.key)) {
                prev.push({ ...record, selectType: ActiveType.Focus });
              }
              return prev;
            }, [] as Array<R>);
            handleSelectedElements(records, [active.key]);
          } else {
            const records = dataSource.reduce((prev, record) => {
              //@ts-ignore
              if (selectedRowKeys.includes(record.key)) {
                prev.push({ ...record, selectType: ActiveType.Selected });
              }
              return prev;
            }, [] as Array<R>);
            handleSelectedElements(records, [...selectedRowKeys]);
          }
        } else if (active?.key) {
          const records = dataSource.reduce((prev, record) => {
            if (compareAsString(active.key, record.key)) {
              prev.push({ ...record, selectType: ActiveType.Focus });
            }
            return prev;
          }, [] as Array<R>);
          handleSelectedElements?.(records, [active.key]);
        } else {
          handleSelectedElements([], []);
        }
      } else {
        handleSelectedElements([], []);
      }
    }
  }, [active, selectedRowKeys]);

  const clearSelectedRowKeys = () => {
    setSelectedRowKeys([]);
    onSelectRows?.([]);
  };

  const onChangeRowSelection = (selectedRowKeys: Keys, selectedRows: TableRecord[]) => {
    setSelectedRowKeys(selectedRowKeys);
    onSelectRows?.(selectedRowKeys, selectedRows, tableName);
  };

  const onSelectWithRecordByTable = (record: TableRecord, selected: boolean, selectedRows: TableRecord[], nativeEvent: Event) => {
    onSelectRowsWithRecord?.({
      record,
      selected,
      selectedRows,
      nativeEvent,
      tableName,
      changeRows: null,
      rowSelectionType: RowSelectionType.Single
    });
  };

  const onSelectAllRecordsByTable = (selected: boolean, selectedRows: TableRecord[], changeRows: TableRecord[]) => {
    onSelectRowsWithRecord?.({
      record: null,
      selected,
      selectedRows,
      nativeEvent: null,
      tableName,
      changeRows,
      rowSelectionType: RowSelectionType.All
    });
  };

  const rowSelectionInner: TableProps<R>['rowSelection'] = selecting
    ? {
        ...rowSelection,
        type: rowSelection?.type ?? 'checkbox',
        onChange: onChangeRowSelection,
        selectedRowKeys,
        onSelect: onSelectWithRecordByTable,
        onSelectAll: onSelectAllRecordsByTable
      }
    : undefined;

  return { clearSelectedRowKeys, selectedRowKeys, setSelectedRowKeys, rowSelectionInner };
};

