import { Dictionary, TableColumnName, TableRecord } from '@common/types';
import { NEW_ROW_KEY } from '../../constants';
import { isNil } from 'lodash';
import { UseRowValidation } from '@common/types/components';

export function saveTableRow<R extends TableRecord>({
  record,
  dataIndex,
  value,
  rowIndex,
  dictionary,
  handleSave,
  handleCreate
}: UseRowValidation<R> & {
  record: R;
  dataIndex: TableColumnName;
  value: string;
  rowIndex: number;
  dictionary?: Dictionary;
}) {
  if (record.key === NEW_ROW_KEY) {
    const updatedRow = Object({ [dataIndex]: value });
    if (!isNil(dictionary)) {
      handleCreate?.(updatedRow);
    }
  } else {
    handleSave?.(record, dataIndex, value, rowIndex, dictionary);
  }
}
