import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { setClearTrashModeRoutine } from '../actions';
import { TClearTrashMode } from '@modules/documents/types/document-state';

export const setClearTrashMode = createReducer(initialState, {
  [setClearTrashModeRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [setClearTrashModeRoutine.SUCCESS]: (
    state,
    action: {
      payload: {
        clearTrashMode: TClearTrashMode;
      };
    }
  ) => {
    state.clearTrashMode = action.payload.clearTrashMode;
    return state;
  },
  [setClearTrashModeRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
