import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderDocumentUploadRoutine, tenderReadRoutine } from '@modules/tender-platform/actions';
import { TenderAPI } from '@modules/tender-platform/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender } from '@modules/tender-platform/types';

function* tenderDocumentUploadWorker(action: PayloadAction<{ tenderID: Tender['ID']; attachment: string; updateTenderStatus: boolean }>) {
  const { success, failure } = tenderDocumentUploadRoutine;
  const { tenderID, attachment, updateTenderStatus } = action.payload;

  try {
    const {
      data: { data }
    } = yield call(TenderAPI.upload, { tenderID, attachment });
    yield put(success({ documents: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    if (updateTenderStatus) {
      yield put(tenderReadRoutine({ id: tenderID }));
    }
  }
}

export function* tenderDocumentUploadWatcher() {
  yield takeEvery(tenderDocumentUploadRoutine.trigger, tenderDocumentUploadWorker);
}
