import { FC } from 'react';
import { Moment } from 'moment';
import { Project } from '@common/types';
import { PlanningAvailableDates } from '@modules/planning/types';
import { ProjectSelectValues } from '@modules/planning/children/data/types';
import { ProjectOptionStatus } from '@modules/planning/children/data/constants/upload';
import {
  PlannerDataChooseProject,
  PlannerDataCurrentProjectTitles,
  PlannerDataDatePickerForm
} from '@modules/planning/children/data/components/upload';
import ILSInputProjectName from '@common/components/common/forms/project/inputs/project-name';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

interface IProps {
  projectOptionStatus: ProjectOptionStatus;
  values: ProjectSelectValues;
  availableDates: PlanningAvailableDates;
  projectID?: Project['ID'];
  dayProjects: Array<Project>;
  activeProjectID: number | null;
  activeProject: Project | null;
  isLoading: boolean;
  visible: boolean;
  onPanelChange: (e: Moment) => void;
  onSelect: (date: Moment | null) => void;
  onCancel: () => void;
  chooseProject: (ID: Project['ID']) => void;
  setProject: () => void;
}

export const renderProjectOptionStatus: FC<IProps> = ({
  projectOptionStatus,
  values,
  availableDates,
  projectID,
  dayProjects,
  activeProjectID,
  activeProject,
  isLoading,
  visible,
  onPanelChange,
  onSelect,
  onCancel,
  chooseProject,
  setProject
}) => {
  switch (projectOptionStatus) {
    case ProjectOptionStatus.CreateNewProject:
      return (
        <>
          <PlannerDataDatePickerForm
            values={values}
            availableDates={availableDates}
            isLoading={isLoading}
            onPanelChange={onPanelChange}
            onSelect={onSelect}
          />
          <ILSInputProjectName disabled={isLoading} />
        </>
      );

    case ProjectOptionStatus.ChooseProject:
      return (
        <>
          <PlannerDataDatePickerForm
            values={values}
            availableDates={availableDates}
            isLoading={isLoading}
            onPanelChange={onPanelChange}
            onSelect={onSelect}
          />
          <PlannerDataChooseProject
            dayProjects={dayProjects}
            projectID={projectID}
            visible={visible}
            chooseProject={chooseProject}
            setProject={setProject}
            onCancel={onCancel}
          />
        </>
      );

    case ProjectOptionStatus.CurrentProject:
      return <PlannerDataCurrentProjectTitles activeProjectID={activeProjectID} activeProject={activeProject} />;

    default:
      showUserAndDevError({ error: 'Not implemented' });
      return <></>;
  }
};
