import { useVirtualBox } from '@modules/orders-and-trips/hooks/use-virtual-box';
import { List } from 'antd';
import VirtualList from 'rc-virtual-list';
import { FC } from 'react';
import { DecoratedTrips } from '@modules/orders-and-trips/types/trip';
import { DecoratedOrders } from '@modules/orders-and-trips/types/orders';
import { IVirtualBoxContainerProps } from '@modules/orders-and-trips/types/virtual-list';
import { renderVirtualListItemComponent } from '@modules/orders-and-trips/helpers/render-virtual-list-item-component';

export const VirtualBoxContainer: FC<IVirtualBoxContainerProps> = ({ dataSource, onLoadData, dictionary, listItemComponentProps }) => {
  const { boxRef, containerHeight, onScroll } = useVirtualBox({ onLoadData });

  return (
    <div key={dictionary} className="orders-and-trips-virtual-box" ref={boxRef}>
      <List>
        <VirtualList
          key={dictionary}
          data={dataSource[dictionary]}
          height={containerHeight}
          itemKey={(item) => [item.ID].join('_')}
          onScroll={onScroll}
        >
          {(listData: DecoratedOrders[number] | DecoratedTrips[number]) => {
            return renderVirtualListItemComponent({
              listData: { [dictionary]: listData },
              listItemComponentProps: { [dictionary]: listItemComponentProps },
              dictionary
            });
          }}
        </VirtualList>
      </List>
    </div>
  );
};
