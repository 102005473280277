import { FC } from 'react';
import { ILSButton, ILSTypographyText } from '@common/components';

interface IProps {
  getTemplate: (data: { addExample?: boolean | undefined }) => Promise<void>;
  file: File | null;
  submitFile(): void;
}

export const ImportNExportActions: FC<IProps> = ({ getTemplate, submitFile, file }) => {
  return (
    <>
      <ILSButton className="orders-and-trips-export-action" type="link" onClick={() => getTemplate({})} icon={'excel'}>
        <ILSTypographyText children={'Скачать файл пример данных'} underline strong />
      </ILSButton>
      <ILSButton className="orders-and-trips-export-action" type="link" onClick={() => getTemplate({ addExample: true })} icon={'excel'}>
        <ILSTypographyText children={'Скачать шаблон для загрузки данных'} underline strong />
      </ILSButton>
      <ILSButton type="primary" disabled={Boolean(!file)} onClick={submitFile}>
        Сохранить данные
      </ILSButton>
    </>
  );
};
