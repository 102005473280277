import { FC } from 'react';
import { tableCustom } from '@core/containers/table-custom';
import { ILSTable } from '@common/components';
import { EmulatorDictionary, VehicleTableRecord } from '../types/vehicle';
import { vehicleTableConfig } from './config/table-config';
import { EmulatorTableTopLayout } from '@modules/emulator/common/components/emulator-table-top-layout';

interface IProps {
  dataSource: Array<VehicleTableRecord>;
}

/*
 * Основной компонент таблицы эмулятора
 * Конфигурация этой таблицы, с названиями всех колонок и их свойствами находится
 * в конфиге вот в этом файле - src\modules\emulator\common\table\config\table-config.ts
 * А декоратор данных, который преобразовывает входные данные с бэкэнда в данные
 * нужного формата для таблицы - находится вот в этом файле - src\modules\emulator\common\table\config\table-decorator.ts
 */
export const EmulatorVehiclePropsTable: FC<IProps> = ({ dataSource, ...props }) => {
  return (
    <EmulatorTableTopLayout>
      <ILSTable
        {...props}
        config={vehicleTableConfig}
        dataSource={dataSource}
        size={'small'}
        scroll={{ y: 290 }}
        className="editable-table mr-2 ml-2"
        bordered
      />
    </EmulatorTableTopLayout>
  );
};

export const EmulatorVehicleTable = tableCustom(EmulatorVehiclePropsTable, vehicleTableConfig, EmulatorDictionary.VehicleProps);
