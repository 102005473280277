import { IndexedArray, VehicleTracker } from '@common/types';
import { MonitoringBrokenSequenceDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { transformTripToWaypointsInfo } from '@common/utils/general/transform-trips-to-waypoint-info';
import { numberToFixedOrEmpty } from '@modules/monitoring/children/dashboard/utils/numberToFixedOrEmpty';
import { TabType } from '@modules/monitoring/children/event-details/types/event-details-tabs';
import { openVehicleDetByPage } from '@modules/monitoring/helpers/open-vehicle-details';
import { History } from 'history';
import { isObject } from 'lodash';
import { Dispatch } from 'redux';
import { viewedContent } from '../../common/cell-viewed-content';
import { isShouldWaypointBeTaken } from '../../utils/waypoints';

type DataToTableFunction = (
  data: Array<MonitoringBrokenSequenceDataModel> | IndexedArray<MonitoringBrokenSequenceDataModel>,
  dispatch: Dispatch,
  history: History,
  dataSourceAll: IndexedArray<MonitoringBrokenSequenceDataModel & VehicleTracker>,
  handleView?: (id: string | number | string[] | number[], value: 0 | 1) => void
) => Array<{
  key: string;
  ID: string;
  waypointIDs: string[];
  VehicleID: number;
  Name: string;
  DriverName: string;
  Phone: string | null;
  MileagePlan: string | undefined;
  MileageFact: string | undefined;
  Overrun: string | undefined;
  Comment: string | undefined;

  Search: { value: string; onClick: (record: any, dataIndex: any) => void };
  viewed: 0 | 1;
}>;

export const data2table: DataToTableFunction = (data, dispatch, history, dataSourceAll, handleView) => {
  const res: ReturnType<typeof data2table> = [];
  const vehicleData = isObject(data) ? Object.values(data) : data;
  const viewedCell = viewedContent(1, handleView, 'waypointIDs');
  const notViewedCell = viewedContent(0, handleView, 'waypointIDs');

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];
    const waypointsInfo = transformTripToWaypointsInfo(dataSourceAll[vehicle?.ID], []);

    if (!vehicle.Trip || !Object.keys(vehicle.Trip)?.length) {
      continue;
    }

    for (const tripIndex in vehicle.Trip) {
      const trip = vehicle.Trip[tripIndex];

      if (!trip.Waypoint || !Object.keys(trip.Waypoint)?.length) {
        continue;
      }

      const tripDeviation = (dataSourceAll[vehicle?.ID] as any)?.TripDeviationDistance;
      const viewed: 0 | 1 = trip?.Viewed || 0;
      const waypointIDs = Object.values(trip.Waypoint).reduce((acc: string[], WP) => {
        if (WP?.ID && isShouldWaypointBeTaken(WP, vehicle)) {
          acc.push(WP.ID.toString());
        }
        return acc;
      }, []);

      if (!waypointIDs.length) continue;
      const Driver = trip?.Driver || vehicle?.Driver;

      const rowObj = {
        ID: tripIndex,
        waypointIDs,
        VehicleID: vehicle.ID,
        tripStatus: trip.Status, //NOTE для работы с колбеками
        TripID: trip.ID, //NOTE для работы с колбеками

        Name: vehicle.Name,
        DriverName: Driver?.Name || '',
        Phone: Driver?.Phone || '',
        Count: Object.keys(waypointIDs)?.length,
        MileagePlan: `${waypointsInfo.running.toFixed(2) || 0}`,
        MileageFact: numberToFixedOrEmpty(trip.FactDistance),
        Overrun: numberToFixedOrEmpty(tripDeviation),
        Comment: trip.Comment,

        Search: {
          value: 'Посмотреть',
          title: 'Нажмите для перехода в детализацию',
          onClick: (_record: ReturnType<typeof data2table>[number]) => {
            openVehicleDetByPage(TabType.PlanFact, _record.VehicleID, dispatch, history);
          }
        },
        key: `row#${vehicleIndex}#${tripIndex}}`,
        Viewed: viewed ? viewedCell : notViewedCell,
        viewed
      };
      res.push(rowObj);
    }
  }
  return res;
};

