import { createSelector } from 'reselect';
import { TABLE_CONFIG_ADDING, TABLE_DATA_TO_TABLE_DECORATORS } from '@common/models/all';
import {
  dataProjectIDSelector,
  fetchSelector,
  filesUploadSelector,
  uploadedDataSelector
} from '@modules/planning/children/data/selectors/data';
import { ColumnTable, Dictionary, ModulePath, Project, TableConfig, TableRecord } from '@common/types';
import { PlanningDataStore } from '@modules/planning/children/data/types';
import { schemeDictionarySelector, schemeKeysSelector } from '@modules/planning/children/data/selectors/scheme';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { stringifyUrl } from 'query-string';
import { isEmpty, isNil } from 'lodash';
import { flatDataValues } from '@modules/planning/children/data/utils/helpers/tables/flat-data-values';
import { activeProjectIdSelector, availableDatesSelector, projectsSelector } from '@modules/planning/selectors';
import { filterByDateProjects } from '@modules/planning/utils/helpers/filter-by-date-projects';
import moment from 'moment';
import { getTableColumnsForDataTableFromScheme } from '../utils/helpers/tables/get-table-columns-for-data-table-from-scheme';

export const dataSourceSelector = (dictionary: Dictionary) =>
  createSelector(
    uploadedDataSelector,
    (data) => TABLE_DATA_TO_TABLE_DECORATORS?.[dictionary]?.(createArrayFromIndexedArray(data?.[dictionary])) ?? []
  );

export const collapsePanelsSelector = createSelector(
  schemeKeysSelector,
  (schemeKeys) => schemeKeys.filter((scheme) => scheme !== Dictionary.Options) as Array<Dictionary>
);

export const plannerDataViewTableSelector = (dictionary: Dictionary) =>
  createSelector(schemeDictionarySelector(dictionary), dataSourceSelector(dictionary), (scheme, dataSource) => {
    const columns: ColumnTable<TableRecord>[] = getTableColumnsForDataTableFromScheme(scheme);
    const config: TableConfig = {
      adding: TABLE_CONFIG_ADDING?.[dictionary],
      deleting: true,
      selecting: true,
      columns
    };

    return { config, dataSource };
  });

export const templateFileSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.templateFile,
  (templateFile) => templateFile
);

export const exampleFileSelector = createSelector(
  (state: PlanningDataStore) => state.PlanningData.exampleFile,
  (exampleFile) => exampleFile
);

export const plannerDataProjectFormSelector = createSelector(
  filesUploadSelector,
  projectsSelector,
  availableDatesSelector,
  uploadedDataSelector,
  fetchSelector,
  activeProjectIdSelector,
  (files, projects, availableDates, data, isLoading, activeProjectID) => {
    const activeProject: Project | null = !isNil(activeProjectID) ? projects[activeProjectID] : null;
    const dayProjectsInitial = filterByDateProjects(projects, moment());
    const disabledSubmit = isEmpty(flatDataValues(data)) || isLoading || isEmpty(files);

    return {
      files,
      projects,
      availableDates,
      dayProjectsInitial,
      disabledSubmit,
      isLoading,
      activeProject,
      activeProjectID
    };
  }
);

export const plannerDataDocumentsSelector = createSelector(templateFileSelector, exampleFileSelector, (template, example) => ({
  template,
  example
}));

export const dataActionsSelector = createSelector(
  dataProjectIDSelector,
  uploadedDataSelector,
  fetchSelector,
  (projectID, data, isFetching) => {
    const url = stringifyUrl({
      url: ModulePath.Planner + ModulePath.Planning,
      query: { projectID }
    });
    const isLoading = isFetching;
    const disabledSubmit = isEmpty(flatDataValues(data)) || isLoading;
    return { url, projectID, disabledSubmit, isLoading };
  }
);
