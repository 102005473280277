import { FC } from 'react';
import classNames from 'classnames';
import CustomScroll from 'react-customscroll';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';

interface IProps {
  isForm: boolean;
  scope: ModuleScope.LogisticServices | ModuleScope.LoginForm | ModuleScope.RegisterForm | ModuleScope.RecoveryForm;
}

export const AuthContent: FC<IProps> = ({ scope, isForm }) => {
  return (
    <div className="ils-content flex-center-center width-full">
      <div className={classNames('bg-white rounded p-3 width-full', { 'auth-form': isForm })}>
        <CustomScroll>
          <Scope name={scope} />
        </CustomScroll>
      </div>
    </div>
  );
};
