import { planningSetConfigRoutine } from '@modules/planning/children/by-date/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanningConfig } from '@modules/planning/children/by-date/types';
import { PLANNIG_CONFIG_KEYS } from '@modules/planning/children/by-date/constants';

function* planningSetConfigWorker(action: PayloadAction<PlanningConfig>) {
  const { success, failure, fulfill } = planningSetConfigRoutine;

  try {
    const { planID, config } = action.payload;
    if (config) {
      const payload = { planID };
      PLANNIG_CONFIG_KEYS.forEach((key) => {
        if (key in config) {
          payload[key] = config[key];
        } else {
          payload[key] = undefined;
        }
      });
      yield put(success(payload));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningSetConfigWatcher() {
  yield takeLatest(planningSetConfigRoutine.trigger, planningSetConfigWorker);
}
