import { DateFormat, DateTimeFormat, TimeStamp } from '@common/types';
import { parseDateFromServer } from '@common/utils';

export const parseDateFromServerToPicker = (
  date?: string | TimeStamp | null | undefined,
  withTime: boolean = false,
  dateFormat?: DateFormat | DateTimeFormat
) => {
  if (!date) return undefined;
  return parseDateFromServer(date, withTime, dateFormat);
};
