import { useEffect } from 'react';
import { UseVirtualTableScroll } from '@common/types/components/table/hooks';

export const useTableScroll = ({ virtual, virtualTableRef, focusRowArrayIndex, isScrollToActiveRow }: UseVirtualTableScroll) => {
  useEffect(() => {
    if (virtual) {
      if (focusRowArrayIndex <= 1) {
        virtualTableRef?.current?.scrollTo(1);
      }
      if (isScrollToActiveRow) {
        virtualTableRef?.current?.scrollToIndex(focusRowArrayIndex);
      }
    }
  }, [focusRowArrayIndex]);
};
