import { CellType, ColumnTable, DateFormat, DateTimeFormat, Notify, Status, TableConfig, TableRecord, TimeFormat } from '@common/types';
import { ExecStatusType, IRecord } from '@modules/catalog/types/catalog';
import { ILSUserNotify } from '@common/components';
import { SetStateAction } from 'react';
import { DataSourceType } from '@modules/planning/children/data/types/components';
import { deepEqual } from 'fast-equals';
import { DataScheme } from '@modules/planning/children/data/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export type IValidFields = Record<string | number, any>;

export type ICreateFields = Record<string | number, any>;

export interface IStatusListener {
  execStatus: Record<string, any>;
  statusState: Record<string, any>;
  setStatusState: {
    (value: any): void;
    (value: SetStateAction<{}>): void;
    (arg0: any): void;
  };
}

export const scheme2config = (scheme: DataScheme, data: any, hideEmpty: boolean) => {
  let conf = { columns: [] as Array<ColumnTable<TableRecord>> };

  if ('object' !== typeof scheme) return conf;

  const notEmptyCols = getNotEmptyColumns(data);

  for (let col in scheme) {
    if ('object' !== typeof scheme[col] || !scheme[col].length || scheme[col].length < 3) {
      continue;
    }

    const converted = typeConverter(scheme[col][1]);
    const { type, format } = converted;

    if (!notEmptyCols || notEmptyCols.includes(scheme[col][0]) || !hideEmpty) {
      conf.columns.push({
        title: scheme[col][2],
        dataIndex: scheme[col][0],
        key: scheme[col][0],
        editable: true,
        active: true,
        type,
        format,
        width: 150,
        ellipsis: true
      });
    }
  }

  return conf;
};

const typeConverter = (
  type: string
): {
  type: CellType;
  format: TimeFormat | DateFormat | DateTimeFormat | undefined;
} => {
  let cellType, format;

  switch (type) {
    case 'DD.MM.YYYY':
      format = DateFormat.DDMMYYYYPeriod;
      cellType = CellType.Date;
      break;
    case 'YYYY-MM-DD':
      format = DateFormat.YYYYMMDDDash;
      cellType = CellType.Date;
      break;
    case 'HH:MM':
      format = TimeFormat.HHMMColon;
      cellType = CellType.TimeDouble;
      break;
    case 'INT':
      cellType = CellType.Int;
      break;
    case 'DOUBLE':
      cellType = CellType.Float;
      break;
    case 'STR':
    default:
      cellType = CellType.Input;
  }

  return {
    type: cellType,
    format
  };
};

const getNotEmptyColumns = (data: Array<any> | undefined) => {
  return data?.reduce((prev, item) => {
    for (let key in item) {
      const columnValue = item[key];
      const notEmpty = Boolean(columnValue) || Number.isInteger(columnValue);
      if (notEmpty) {
        prev[key] = true;
      }
    }
    return prev;
  }, {});
};

export const hideEmptyColumns = (data: Array<DataSourceType> | undefined, config: TableConfig, hide: boolean): TableConfig => {
  if (!config) {
    showUserAndDevError({
      userError: `Работа таблицы не настроена конфигурацией, обратитесь к администратору!`,
      error: `Необходимо передать prop config`
    });
  }
  const notEmptyColumns = getNotEmptyColumns(data);
  const { columns } = config;
  return {
    ...config,
    columns: hide ? columns?.filter(({ dataIndex }) => notEmptyColumns[dataIndex]) : columns
  };
};

export type RowData = {
  ID?: string | number;
  BorderHash: string;
  Color: string;
  Name: string;
  ZoneCode: string;
  key?: string | number;
  rowKey?: string | number;
};

export const isEqualIDRow = (r1: RowData, key: string | number | Array<string | number | undefined>): boolean => {
  if (r1 && key) {
    if (Array.isArray(key)) {
      for (let i in key) {
        if (!key[i]) continue;
        let keyI = key[i];
        if (r1.ID === keyI || r1.key === keyI || r1.rowKey === keyI) {
          return true;
        }
      }
    } else {
      if (r1.ID === key || r1.key === key || r1.rowKey === key) {
        return true;
      }
    }
  }
  return false;
};

export const isEqualRow = (r1: RowData, r2: RowData, extraField?: string): boolean => {
  if (r1 === r2) return true;
  if (!r1 || !r2) return false;
  return !(
    r1.ID !== r2.ID ||
    r1.key !== r2.key ||
    r1.BorderHash !== r2.BorderHash ||
    r1.Color !== r2.Color ||
    r1.Name !== r2.Name ||
    r1.ZoneCode !== r2.ZoneCode ||
    (extraField && !deepEqual(r1?.[extraField], r2?.[extraField]))
  );
};

export const validateRow = <R>(newRow: R, columns: ColumnTable<R>[] | undefined) => {
  const invalid: string[] = [];
  const validatedFields: IValidFields = {};
  columns?.map((column) => {
    if (column.required) validatedFields[column.dataIndex] = column.title;
  });
  Object.keys(validatedFields).map((v) => (!Object.keys(newRow).includes(v) || newRow[v as keyof IRecord] === '') && invalid.push(v));
  if (invalid.length) {
    const fieldsTitles: IValidFields[] = invalid.map((i) => validatedFields[i]);
    ILSUserNotify(Notify.Error, 'Не заполнены поля: ' + fieldsTitles.join(', '), 3, Status.Error);
    return false;
  } else {
    //проверяем, заполнены ли все required поля
    const createFields: ICreateFields = {};
    columns?.forEach((column) => (createFields[column.dataIndex] = column.title));
    //убираем ненужную информацию
    Object.keys(newRow).forEach((key) => {
      Object.keys(createFields).includes(key) || delete newRow[key as keyof IRecord];
    });
    return true;
  }
};

export const statusListener = (
  execStatus: IStatusListener['execStatus'],
  statusState: IStatusListener['statusState'],
  setStatusState: IStatusListener['setStatusState']
) => {
  let diff = Object.keys(execStatus).reduce((diff, key) => {
    if (statusState[key] === execStatus[key]) return diff;
    return {
      ...diff,
      [key]: execStatus[key]
    };
  }, {});
  const key = Object.keys(diff)[0];
  const value: { status: ExecStatusType } | any = Object.values(diff)[0];
  key && value && ILSUserNotify(value.status, value.text, value.status === Status.Loading ? 120 : 3, key);
  setStatusState(execStatus);
  return !!(key && value && value.status === Status.Success);
};
