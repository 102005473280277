import { useState } from 'react';
import { Dictionary, Driver } from '@common/types';
import { isEmpty } from 'lodash';
import { getModelsId } from '@common/utils/general/get-models-id';
import { CatalogMobileApplicationButtons } from '@modules/catalog/containers/mobile-application-buttons';
import { CatalogFilterProject } from '@modules/catalog/containers/catalog-filter-project';

export const useDriverInviteWithVerification = () => {
  const [selectedDrivers, setSelectedDrivers] = useState<Array<Driver['ID']>>([]);
  /**
   * Кнопки заблокированы если не выбран ни один водитель (на render нет checkbox)
   */
  const [cantInvite, setCantInvite] = useState<boolean>(true);
  const [cantDelete, setCantDelete] = useState<boolean>(true);

  const handleSelectedDrivers = (selectedDrivers: Array<Driver>) => {
    const selectedDriversIDs = getModelsId(selectedDrivers) as Array<Driver['ID']>;
    // Кнопки заблокированы если не выбран ни один водитель
    const selectedDriversIsEmpty = isEmpty(selectedDriversIDs);
    setSelectedDrivers(selectedDriversIDs);
    /**
     * Кнопка удаления заблокирована, если хотя бы один выбранный водитель не в мобильном приложении
     */
    const someIsInvited = selectedDrivers.some(({ MobileApplication }) => Boolean(MobileApplication));
    setCantDelete(selectedDriversIsEmpty || someIsInvited);
    /**
     *     Кнопки регистрации и повторной отправки пароля заблокированы, если хотя бы один выбранный водитель в мобильном приложении
     */
    const someIsDeleted = selectedDrivers.some(({ MobileApplication }) => !Boolean(MobileApplication));
    /**
     * Если выбраны водители одного типа
     * Активна кнопка удаления, если выбранные водители уже в мобильном приложении и не активны кнопки регистрации и повторной отправки пароля
     * Активны кнопки регистрации и повторной отправки пароля, если выбранные водители ещё не в мобильном приложении и не активна кнопка Удаления
     */
    setCantInvite(selectedDriversIsEmpty || someIsDeleted);
  };

  const actionTableButtons = [
    { Component: CatalogMobileApplicationButtons, props: { cantInvite, cantDelete, selectedDrivers } },
    {
      Component: CatalogFilterProject,
      props: {
        dictionary: Dictionary.Driver
      }
    }
  ];

  return { actionTableButtons, handleSelectedDrivers };
};
