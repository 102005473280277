import React, { FC } from 'react';
import { TipHeadCell } from './tip-th';

interface IProps {
  dataHead: any[];
  emptyData: string;
}

export const TableTipContainer: FC<IProps> = ({ dataHead, emptyData, children }) => {
  if (!React.Children.count(children)) return <div>{emptyData}</div>;
  return (
    <div className="table-tip">
      <div className="table-tip__container tip-container">
        <div className="tip-container__head">
          {dataHead.map((head) => {
            return <TipHeadCell>{head}</TipHeadCell>;
          })}
        </div>
        <div className="tip-container__body">{children}</div>
      </div>
    </div>
  );
};
