import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { updateOrderRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { IndexedArray, Order } from '@common/types';

export const updateOrderReducer = createReducer(initialState, {
  [updateOrderRoutine.REQUEST]: (state: InitialState) => {
    state.orders.isFetching = true;
    return state;
  },
  [updateOrderRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      order: IndexedArray<Order>;
    }>
  ) => {
    const { order } = action.payload;
    state.orders.data = { ...state.orders.data, ...order };
    const orderID = Object.values(order ?? {})?.[0]?.ID;
    state.orders.activeRecord = { key: orderID, ID: orderID };
    return state;
  },
  [updateOrderRoutine.FAILURE]: onFailure,
  [updateOrderRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.orders.isFetching = false;
    return state;
  }
});

