import './styles.less';
import { Button, ButtonProps } from 'antd';
import { FC, MouseEvent, ReactNode } from 'react';
import { getSRCIconSVG } from '@common/decorators/path';
import { ReactSVG } from 'react-svg';
import { IconName } from '@common/types';
import { ButtonContent } from '@common/constants/general/content';
import classNames from 'classnames';

export enum ButtonAction {
  Save = 'Save',
  Add = 'Add',
  Delete = 'Delete',
  Back = 'Back',
  Cancel = 'Cancel',
  Reset = 'reset'
}

export interface IButtonProps extends Omit<ButtonProps, 'icon'> {
  action?: ButtonAction;
  children?: ButtonContent | string | ReactNode;
  icon?: IconName | ButtonProps['icon'];
}

export const ILSButton: FC<IButtonProps> = ({
  loading = false,
  action = null,
  children,
  icon,
  disabled = false,
  onClick,
  className,
  ...props
}) => {
  let buttonIcon;
  switch (typeof icon) {
    case 'string':
      buttonIcon = <ReactSVG className="ils-svg" src={getSRCIconSVG(icon)} />;
      break;
    case 'object':
      buttonIcon = icon === null ? undefined : icon;
      break;
    case 'undefined':
      break;
  }

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    // TODO данный хак не работает с submit
    if (!disabled) {
      onClick?.(e);
    }
    return;
  };

  return (
    //отключение кнопки через стили - это хак, чтобы tooltip работал на disable кнопках
    <Button
      {...props}
      loading={loading}
      icon={buttonIcon}
      className={classNames(className, 'ils-button', { 'disabled-btn': disabled || loading })}
      onClick={handleClick}
    >
      {action ? ButtonContent[action] : children}
    </Button>
  );
};
