import { DragEvent, MutableRefObject } from 'react';
import { TableRecord } from '@common/types';
import { isRecordDroppable } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/is-record-droppable';

export const handleRecordDrop =
  ({
    targetRecord,
    selectRecord,
    onDragEndDropCallback
  }: {
    selectRecord: MutableRefObject<TableRecord | undefined>;
    targetRecord: MutableRefObject<TableRecord | undefined>;
    onDragEndDropCallback: (select: TableRecord | undefined, target: TableRecord | undefined) => void;
  }) =>
  (record: TableRecord | undefined) =>
    isRecordDroppable(record?.dictionary)
      ? (_e: DragEvent | undefined, record: TableRecord | undefined) => {
          targetRecord.current = record || undefined;
          onDragEndDropCallback(selectRecord.current, targetRecord.current);
        }
      : undefined;
