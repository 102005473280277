const layerLanguage: 'ru' | 'en' = 'ru';

//todo для osm нужно тоже найти как менять локализацию
export const osmTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  maxZoom: 18,
  id: 'mapbox/streets-v11',
  // @ts-ignore
  reuseTiles: true,
  updateWhenIdle: true,
  tileSize: 512,
  zoomOffset: -1
});

export const googleTerrainTileLayer = L.tileLayer(`http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=${layerLanguage}`, {
  maxZoom: 18,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  // @ts-ignore
  reuseTiles: true,
  updateWhenIdle: true
});

export const googleHybridTileLayer = L.tileLayer(`http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&hl=${layerLanguage}`, {
  maxZoom: 18,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  // @ts-ignore
  reuseTiles: true,
  updateWhenIdle: true
});
