import { ILSButton, ILSCheckbox } from '@common/components';
import { ReactSVG } from 'react-svg';
import { FC, useContext } from 'react';
import { RoadGraphContext } from '@modules/road-graph/route-test/context';
import CustomScroll from 'react-customscroll';
import { IForbiddenZone } from '@modules/road-graph/route-test/types/map';

interface Props {
  closeMenu: () => void;
  onSelect: (checked: boolean, id: number) => void;
  data:
    | undefined
    | {
        [key: number]: IForbiddenZone;
      };
}

const ILSRoadGraphTestPermitZonesMenu: FC<Props> = (props) => {
  const { showZones } = useContext(RoadGraphContext) ?? {};
  const { closeMenu, onSelect, data } = props;

  return (
    <div className="px-3 py-2 zones-menu bordered rounded">
      <h3>Зоны ограничения</h3>
      <div className="mt-3 menu">
        <CustomScroll>
          {data && Object.values(data).length
            ? Object.values(data).map((fz, index) => (
                <div>
                  <ILSCheckbox
                    key={'ILSRoadGraphTestPermitZonesMenu' + index}
                    checked={showZones?.selectedZones.includes(fz.Zone)}
                    onChange={(e) => onSelect(e.target.checked, fz.Zone)}
                  >
                    {fz.Name}
                  </ILSCheckbox>
                </div>
              ))
            : 'Зоны ограничения не найдены'}
        </CustomScroll>
      </div>
      <ILSButton className="close" onClick={closeMenu} icon={<ReactSVG src={'/assets/icons/close.svg'} />} />
    </div>
  );
};
export default ILSRoadGraphTestPermitZonesMenu;
