import { CatalogTitle, Dictionary, Order, ReferenceTables } from '@common/types';
import { RestrictionTable } from '@modules/planning/children/by-date/components/tables/restriction';
import { ContactTable } from '@modules/planning/children/by-date/components/tables/contact';
import { ILSPlanningDepot } from '@modules/planning/children/by-date/components/tables/depot';
import { WorktimeTable } from '@modules/planning/children/by-date/components/tables/worktime';
import { PlannerPlanningOrderCargoTableComponent } from '@modules/planning/children/by-date/components/tables/order-cargo';

export const MAX_TOOLTIP_ITEM = 5;

export const REFERENCE_TABLES = {
  [Dictionary.Restriction]: {
    title: CatalogTitle.Restriction,
    component: RestrictionTable,
    catalogDictionary: Dictionary.Restriction
  },
  [Dictionary.Contact]: {
    title: 'Контакты',
    component: ContactTable,
    dictionary: Dictionary.Contact
  },
  [Dictionary.Worktime]: {
    title: 'Режим работы',
    component: WorktimeTable,
    dictionary: Dictionary.Worktime
  },
  [Dictionary.Depot]: {
    title: 'Точки',
    component: ILSPlanningDepot,
    dictionary: Dictionary.Depot
  },
  OrderCargo: (record: Order) => {
    return {
      title: `Грузы в заявке №${record.ID}`,
      component: PlannerPlanningOrderCargoTableComponent,
      record
    };
  }
};

const depotTable = REFERENCE_TABLES[Dictionary.Depot];
const restrictionTable = REFERENCE_TABLES[Dictionary.Restriction];

export const ORDER_REFERENCE_TABLES = {
  SourceID: depotTable,
  TargetID: depotTable,
  RestrictionID: restrictionTable,
  SourceContactID: REFERENCE_TABLES.Contact,
  TargetContactID: REFERENCE_TABLES.Contact,
  WorktimeID: REFERENCE_TABLES.Worktime,
  CargoID: REFERENCE_TABLES.OrderCargo
} as unknown as ReferenceTables;
