import { useCallback } from 'react';
import { Dictionary, TableColumnName, TableRecord } from '@common/types';
import { saveTableRow } from '@components/data-display/table/utils/helpers/save-table-row';
import { UseRowValidation } from '@common/types/components/table/hooks';

export const useCreateOrUpdateTableRow = <R extends TableRecord>({ handleCreate, handleSave }: UseRowValidation<R>) => {
  const handleCreateOrUpdateTableRow = useCallback(
    (record: R, dataIndex: TableColumnName, value: any, rowIndex: number, dictionary?: Dictionary) => {
      saveTableRow({
        record,
        dataIndex,
        value,
        rowIndex,
        handleCreate,
        dictionary,
        handleSave
      });
    },
    [handleSave]
  );

  return { handleCreateOrUpdateTableRow };
};
