import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { checkTokenRoutine } from '@modules/current-user/actions';
import { Auth } from '@modules/current-user/api/auth';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

function* checkTokenWorker() {
  const { request, success, failure, fulfill } = checkTokenRoutine;

  //Delay для предотвращения слишком частых вызов
  yield delay(1000);
  try {
    yield put(request());
    const {
      data: { data: userData }
    } = yield call(Auth.checkToken);

    yield put(success({ token: userData.Token, user: userData }));
  } catch (error) {
    yield put(failure({ error: createArrayFromIndexedArray(error as Record<string, string>) }));
  } finally {
    yield put(fulfill());
  }
}

export function* checkTokenWatcher() {
  yield takeLatest(checkTokenRoutine.trigger, checkTokenWorker);
}
