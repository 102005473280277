import { Dispatch, FC, SetStateAction } from 'react';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';
import { DocumentsTemplateListComponent } from '@modules/documents/components/form-document-modal/template-list';
import { useTemplateList } from '@modules/documents/hooks/use-template-list';
import { Project } from '@common/types';
import { ROOT_PROJECT_ID_MONITORING } from '@modules/documents/constants/reports';

interface IProps {
  projectId?: Project['ID'] | null;
  template: DocumentTemplate | undefined;
  setTemplate: Dispatch<SetStateAction<DocumentTemplate | undefined>>;
}

export const DocumentsTemplateList: FC<IProps> = ({ template, setTemplate, projectId }) => {
  const { documentTemplates, handleDeleteReport } = useTemplateList({ projectId: projectId ?? ROOT_PROJECT_ID_MONITORING });

  return (
    <DocumentsTemplateListComponent
      documentTemplates={documentTemplates}
      setTemplate={setTemplate}
      template={template}
      handleDeleteReport={handleDeleteReport}
    />
  );
};
