import '../styles.less';
import { FC, useContext, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, Switch, useLocation } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { AppContext } from '../../../App';
import { AccountRole, ModulePath } from '@common/types';
import { ILSTenderPlatformAccountInfoContainer } from '@modules/tender-platform/containers/account-info';

interface TenderPlatformProps<Params extends { [K in keyof Params]?: string } = {}> extends RouteComponentProps<Params> {
  route?: RouteConfig | undefined;
}

const ILSTenderPlatform: FC<TenderPlatformProps> = ({ route }) => {
  const { roles } = useContext(AppContext) ?? {};
  const [fromUrl, setFromUrl] = useState<string>(ModulePath.TenderPlatform);
  const location = useLocation();
  const toPath = `${ModulePath.TenderPlatform}${
    // определяем страницу на которую будет выполнен 301 redirect в зависимости от роли пользователя
    roles?.includes(AccountRole.cargoOwner) ? ModulePath.TenderOwn : ModulePath.TenderSearch
  }`;

  // определяем страницу с которой будет выполнен 301 redirect, для пользователя с двойной ролью (перевозчик и грузовладелец) операцию НЕ выполняем
  useEffect(() => {
    if (roles && roles.includes(AccountRole.cargoOwner) && roles.includes(AccountRole.carrier)) return undefined;
    if (location.pathname !== toPath) {
      setFromUrl(location.pathname);
    } else {
      setFromUrl(ModulePath.TenderPlatform);
    }
  }, []);

  return (
    <section className={'tender-platform'}>
      <ILSTenderPlatformAccountInfoContainer />
      <Switch>
        <Redirect from={fromUrl} exact to={toPath} />
      </Switch>
      {renderRoutes(route?.routes)}
    </section>
  );
};

export default ILSTenderPlatform;
