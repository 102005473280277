import { RcFile } from 'antd/lib/upload/interface';
import { AccountAttachmentSubject, Notify } from '@common/types';
import { EMPTY_STRING, JoinChar, SplitChar } from '@common/constants';
import { ILSUserNotify } from '@common/components';
import { PermittedUploadFileExtensions } from '@common/constants/general/path';
import { DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE } from '@common/constants/general/form';

type checkFileBeforeUpload = {
  file: RcFile;
  permittedFileExtensions: PermittedUploadFileExtensions[];
  handleUploadDocuments: (documents: RcFile, subject: AccountAttachmentSubject) => void;
  subject: AccountAttachmentSubject;
};

export const checkFileBeforeUpload = ({ file, permittedFileExtensions, subject, handleUploadDocuments }: checkFileBeforeUpload) => {
  let error = EMPTY_STRING;
  if (!file?.size) {
    error = 'Нельзя загружать пустые файлы' + JoinChar.Semicolon;
  }

  if (file.size > DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE) {
    error = error + 'Максимальный размер файла 20МБ' + JoinChar.Semicolon;
  }

  if (!permittedFileExtensions.includes(`${SplitChar.Dot + file.name.split(SplitChar.Dot).pop()}` as PermittedUploadFileExtensions)) {
    error = error + JoinChar.Semicolon + `Допустимые форматы файлов: ${permittedFileExtensions.join(JoinChar.Comma)}`;
  }

  if (error) {
    ILSUserNotify(Notify.Error, error, 3);
    return false;
  } else {
    handleUploadDocuments(file, subject);
  }
};
