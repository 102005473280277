import { Dictionary } from '@common/types';
import { isEmpty } from 'lodash';

//NOTE Проверяет нужно ли сохранять только в локальном сторе запись
export const isNeedLocalSaveRecord: (arg0: {
  dictionary: Dictionary | string;
  data: {
    [k: string]: any;
  };
}) => boolean = ({ data, dictionary }) => {
  return dictionary === Dictionary.ForbiddenZone && isEmpty(data.Points);
};
