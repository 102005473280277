import moment, { Moment } from 'moment';
import { DateGeneral } from '@common/types';

export const checkIsDateDisabled = (current: Moment, minDate?: DateGeneral, maxDate?: DateGeneral): boolean => {
  if (current > moment()) {
    return moment().isAfter(current);
  }
  if (!minDate && !maxDate) {
    return false;
  } else if (minDate && maxDate) {
    return !(current > moment(minDate) && current < moment(maxDate))
  } else if (minDate && !maxDate) {
    return !current.isAfter(minDate)
  } else if (!minDate && maxDate) {
    return !current.isBefore(maxDate)
  }
  return true;
};
