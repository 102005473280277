import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { monitoringDeleteVehicleTrackersRoutine } from '../actions';

export const monitoringDeleteVehicleTrackerReducer = createReducer(initialState, {
  [monitoringDeleteVehicleTrackersRoutine.REQUEST]: (state) => {
    state.vehicleTrackers.isFetching = true;

    return state;
  },
  [monitoringDeleteVehicleTrackersRoutine.SUCCESS]: (state, action: PayloadAction<{ IDs: number }>) => {
    const { IDs } = action.payload;
    state.vehicleTrackers.data = state.vehicleTrackers.data.filter((tracker) => tracker.ID !== IDs);
    state.vehicleTrackers.lastUpdate = new Date().getTime();
    return state;
  },
  [monitoringDeleteVehicleTrackersRoutine.FAILURE]: (state) => {
    state.vehicleTrackers.lastUpdate = undefined;

    return state;
  },
  [monitoringDeleteVehicleTrackersRoutine.FULFILL]: (state) => {
    state.vehicleTrackers.isFetching = false;
    return state;
  }
});
