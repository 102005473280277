import { Dictionary, Marker, Polygon } from '@common/types';

export type LayerItemMarker = Pick<Marker, 'coords' | 'type'> & { itemKey: string; name: string };

export type LayerItemPolygon = Pick<Polygon, 'coords' | 'color'> & { itemKey: string; name: string };

type MapItems = {
  markers?: LayerItemMarker[];
  polygons?: LayerItemPolygon[];
};

export enum LayerEntytiType {
  Polygon = 'polygons',
  Marker = 'markers'
}
export type LayerData = {
  ID: number;
  name: string;
  active: boolean;
  data?: MapItems;
  field: string;
  markerType?: string;
  type: LayerEntytiType;
  dictionary?: Dictionary;
  entity?: Dictionary;
  filter?: (item: any) => boolean;
};

export type LayerMenu = {
  ID: number;
  name: string;
  active: boolean;
  isEmpty: boolean;
};

