import { useMemo } from 'react';
import { orderCargos2Table } from '@common/models/order-cargo/decorators';
import { ProjectState } from '@common/types';

export const useOrderCargoDataSource = ({ record, orders }: { record: any; orders: ProjectState['Order'] | undefined }) => {
  const dataSource = useMemo(() => {
    const order = orders?.[record.ID];
    return orderCargos2Table(order?.OrderCargo ?? []);
  }, [orders]);

  return { dataSource };
};
