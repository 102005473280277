import { FC } from 'react';
import { ILSCascader } from '@common/components';
import { CascaderProps } from 'antd/es';
import { DictionariesInfo, Dictionary } from '@common/types';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';

interface IProps {
  showChild?: boolean;
  dictionary: Dictionary;
  dictionaryLoad(dictionary: Dictionary): void;
  dictionaryInfo?: DictionariesInfo;
}

export const ILSCascaderApi: FC<CascaderProps<any> & IProps> = ({
  showChild = true,
  dictionaryLoad,
  dictionary,
  dictionaryInfo,
  fieldNames,
  options,
  ...props
}) => {
  const loading = dictionaryInfo?.[dictionary]?.isFetching;

  useApiOptions({ dictionary, dictionaryLoad });

  return (
    <ILSCascader
      {...props}
      className={'cell-wrapper-editable-full'}
      showChild={showChild}
      loading={Boolean(loading)}
      expandTrigger={'hover'}
      allowClear
      options={options}
      fieldNames={fieldNames}
    />
  );
};
