import { useDispatch } from 'react-redux';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { FileName } from '@modules/planning/children/data/constants';
import { downloadFileAsBlobLink } from '@common/utils/file/download-file-as-blob-link';
import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';
import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { useState } from 'react';
import { DOT } from '@common/constants';
import { PERMITTED_UPLOAD_FILE_EXTENSIONS } from '@modules/orders-and-trips/constants/file';
import { importOrdersRoutine } from '@modules/orders-and-trips/actions';

export const useImportExportContainer = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);

  const handleCheckBeforeUpload = (file: File) => {
    const fileExtension = file?.name?.split(DOT)?.[1];
    if (!PERMITTED_UPLOAD_FILE_EXTENSIONS.includes(fileExtension)) {
      ILSUserNotify(Notify.Warning, `Нельзя загружать файлы формата ${fileExtension}`, NotifyDurationInSecond.Three);
      return;
    }
    setFile(file);
  };

  const submitFile = () => {
    dispatch(importOrdersRoutine({ file }));
    setFile(null);
  };

  const getTemplate = async ({ addExample = false }: { addExample?: boolean }) => {
    try {
      const responseData = await OrdersNTripsApi.getExportTemplate({ addExample });
      downloadFileAsBlobLink({
        data: responseData.data.data as string,
        fileName: FileName.Example,
        mimeType: PermittedUploadFileType.excel,
        fileExtension: PermittedUploadFileExtensions.xlsx
      });
    } catch (e) {
      ILSUserNotify(Notify.Warning, 'Не удалось скачать файл', NotifyDurationInSecond.Three);
    }
  };

  const uploadIsDisabled = false;

  return { getTemplate, uploadIsDisabled, handleCheckBeforeUpload, file, submitFile };
};

