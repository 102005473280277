import { FC } from 'react';
import { ILSFormItem, ILSInput, ILSSelect } from '@components/index';
import { CardTab, LOAD_TYPE_OPTIONS } from '@modules/tender-platform/constants';
import { useSelector } from 'react-redux';
import { getPlaceholder, Placeholder } from '@common/constants/general';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { ILSSelectCurrency } from '@components/data-entry/select';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { TenderPlatformDividerTitle } from '@modules/tender-platform/components/tender-card/lots/form/divider-title';
import cn from 'classnames';
import { RULE_FOR_TENDER_LOT_MIN_PRICE } from '@modules/tender-platform/constants/form';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { accountUsersOptionsWithPhoneSelector, tenderVehicleTypeOptionsSelector } from '@modules/tender-platform/selectors/current-tender';

interface IProps {
  disabled: boolean;
}

export const TenderPlatformBiddingComponent: FC<IProps> = ({ disabled }) => {
  const contacts = useSelector(accountUsersOptionsWithPhoneSelector);
  const vehicleType = useSelector(tenderVehicleTypeOptionsSelector);
  const className = cn('no-margin tender-form-fieldset-item');
  const placeholder = getPlaceholder(Placeholder.ChooseValue, disabled);
  const rules = [{ required: !disabled, message: 'Пожалуйста, задайте начальную цену' }];

  return (
    <fieldset className="tender-form-fieldset">
      <TenderPlatformDividerTitle id={CardTab.Condition} title={'Условия контракта'} />
      <ILSFormItem className={className} label={LotFormFieldLabel.TenderLotUser} name={TenderFormField.TenderLotUser}>
        <ILSSelect showArrow={!disabled} mode="multiple" options={contacts} disabled={disabled} placeholder={placeholder} />
      </ILSFormItem>
      <ILSFormItem className={className} label={LotFormFieldLabel.StartPrice} name={TenderFormField.StartPrice} rules={rules}>
        <ILSInput
          step={1}
          type="number"
          disabled={disabled}
          placeholder={getPlaceholder(Placeholder.Float, disabled)}
          addonAfter={<ILSSelectCurrency disabled={disabled} />}
        />
      </ILSFormItem>
      <ILSFormItem
        rules={RULE_FOR_TENDER_LOT_MIN_PRICE}
        className={className}
        label={LotFormFieldLabel.MinPrice}
        name={TenderFormField.MinPrice}
      >
        <ILSInput step={1} type="number" disabled={disabled} placeholder={getPlaceholder(Placeholder.Float, disabled)} />
      </ILSFormItem>
      <ILSFormItem className={className} label={LotFormFieldLabel.VehicleLoadType} name={TenderFormField.VehicleLoadType}>
        <ILSSelect showArrow={!disabled} options={LOAD_TYPE_OPTIONS} disabled={disabled} placeholder={placeholder} />
      </ILSFormItem>
      <ILSFormItem
        className={className}
        rules={[{ required: !disabled, message: 'Пожалуйста, выберите требуемый вид транспорта' }]}
        label={LotFormFieldLabel.VehicleType}
        name={TenderFormField.VehicleType}
      >
        <ILSSelect
          maxTagCount={'responsive'}
          showArrow={!disabled}
          mode="multiple"
          disabled={disabled}
          placeholder={getPlaceholder(Placeholder.ChooseValue, disabled)}
          fieldNames={DEFAULT_FIELD_NAMES}
          options={vehicleType}
        />
      </ILSFormItem>
    </fieldset>
  );
};
