import { useDispatch, useSelector } from 'react-redux';
import {
  createOrderRoutine,
  createTripRoutine,
  deleteOrderRoutine,
  showFormRoutine,
  updateOrderRoutine
} from '@modules/orders-and-trips/actions';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { extraDataSelector } from '@modules/orders-and-trips/selectors/extra-data';
import { restrictionToForm } from '@modules/orders-and-trips/decorators/restriction-to-form';
import { ITripFormValues } from '@modules/orders-and-trips/types/form';
import { orderFormValuesToData } from '@modules/orders-and-trips/decorators/order-form-to-data';
import { draftTripNOrderSelector, fetchDraftTripNOrderSelector } from '@modules/orders-and-trips/selectors/trip';
import { FormType } from '@modules/orders-and-trips/types/general';
import { ButtonTypes } from '@common/types/components';
import { getFormTrip } from '@modules/orders-and-trips/helpers/get-form-trip-and-order';
import { tripNOrderDataToForm } from '@modules/orders-and-trips/decorators/trip-and-order-data-to-form';
import { vehicleTypesToForm } from '@components/common/forms/vehicle/helpers/vehicle-types-to-form';
import { tripFormValuesToData } from '@modules/orders-and-trips/decorators/trip-form-to-data';
import { getOrderAndTripFormTitle } from '@modules/orders-and-trips/helpers/get-order-and-trip-form-title';
import { activeOrderRecordSelector, activeRecordIDSelector } from '@modules/orders-and-trips/selectors';
import { ILSUserNotifyConfirm } from '@common/components/feedback/ils-user-notify';
import { DELETE_ORDER_NOTIFY } from '@modules/orders-and-trips/constants/general';

type UseOrderNTripForm = { showForm: FormType | null };

export const useOrderNTripForm = ({ showForm }: UseOrderNTripForm) => {
  const isFetching = useSelector(fetchDraftTripNOrderSelector);
  const draftTripNOrder = useSelector(draftTripNOrderSelector);
  const activeRecordID = useSelector(activeRecordIDSelector(showForm));
  const tripNOrderFormData = showForm ? draftTripNOrder[showForm] : undefined;
  const { restriction, vehicleTypes } = useSelector(extraDataSelector);
  const activeOrderRecord = useSelector(activeOrderRecordSelector);
  const [isLocked, setLocked] = useState(true);

  const dispatch = useDispatch();

  const [form] = useForm();
  const formTitle = getOrderAndTripFormTitle(showForm);

  const onFinish = (formValues: ITripFormValues) => {
    switch (showForm) {
      case FormType.CreateOrder:
        dispatch(createOrderRoutine({ orderData: orderFormValuesToData(formValues) }));
        break;
      case FormType.Trip:
        tripNOrderFormData &&
          dispatch(createTripRoutine({ tripData: tripFormValuesToData({ formValues, draftTripNOrder: tripNOrderFormData }) }));
        break;
      case FormType.UpdateOrder:
        dispatch(updateOrderRoutine({ id: activeOrderRecord?.ID, orderData: orderFormValuesToData(formValues) }));
        break;
      default:
        console.error('Не выбрана форма для отображения');
    }
  };

  const handleCancel = () => {
    dispatch(showFormRoutine({ formType: null }));
  };

  const handleDeleteOrder = () => {
    ILSUserNotifyConfirm('Внимание', DELETE_ORDER_NOTIFY, () => {
      dispatch(deleteOrderRoutine({ id: activeOrderRecord?.ID }));
    });
  };

  const onFieldsChange = () => {
    setLocked((locked) => {
      if (locked && [FormType.UpdateOrder].includes(showForm as FormType)) {
        return !locked;
      }
      return locked;
    });
  };

  const submitButtonProps = {
    [ButtonTypes.Cancel]: handleCancel,
    [ButtonTypes.Delete]: handleDeleteOrder
  };

  useEffect(() => {
    form.resetFields();
    if (tripNOrderFormData && !isEmpty(tripNOrderFormData) && restriction && !isFetching) {
      form.setFieldsValue(tripNOrderDataToForm({ draftTripNOrder: tripNOrderFormData, restriction }));
    }
  }, [draftTripNOrder, restriction, isFetching]);

  useEffect(() => {
    setLocked(true);
  }, [activeRecordID, activeOrderRecord]);

  const lockedButtons = {
    [ButtonTypes.Save]: [FormType.UpdateOrder].includes(showForm as FormType) && isLocked
  };

  return {
    form,
    formTitle,
    submitButtonProps,
    onFinish,
    isFetching,
    restriction: restrictionToForm(restriction),
    formTrip: getFormTrip({ draftTripNOrder: tripNOrderFormData, showForm, isFetching }),
    disabled: showForm === FormType.Trip,
    lockedButtons,
    vehicleTypes: vehicleTypesToForm(vehicleTypes),
    activeRecordID,
    onFieldsChange
  };
};
