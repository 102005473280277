import { createSelector } from 'reselect';
import { MonitoringState } from '../types/store';
import { Dictionary } from '@common/types';
import { LayerData, LayerEntytiType } from '../types/layer';
import { CATALOG_DEFAULT_LAYERS } from '../constants/catalog';
import { isEmpty } from 'lodash';
import { COLOR_BLACK } from '@common/constants/colors';
import { int2color } from '@common/decorators/color';

export const monitoringCatalogSelector = createSelector(
  (state: { Monitoring: MonitoringState }) => state.Monitoring.catalog,
  (catalog) => catalog
);

export const monitoringCatalogDictionarySelector = createSelector(
  (state: { Monitoring: MonitoringState }, dictionary: Dictionary) => state.Monitoring.catalog?.[dictionary],
  (dictionaryData) => dictionaryData
);

export const monitoringLayerSelector = createSelector(monitoringCatalogSelector, (catalog) => {
  const layerData: LayerData[] = CATALOG_DEFAULT_LAYERS.map((item) => {
    return {
      ...item,
      data: {
        [item.type]:
          item.dictionary && !isEmpty(catalog?.[item.dictionary]?.data)
            ? Object.values(catalog[item.dictionary].data)
                ?.filter?.(item?.filter ?? Boolean)
                .map((entity: any) => {
                  if (item.type === LayerEntytiType.Polygon) {
                    return {
                      coords: entity[item.field],
                      color: entity.Color ? int2color(entity.Color) : COLOR_BLACK,
                      itemKey: '' + item.dictionary + '#' + entity.ID,
                      name: entity?.Name ?? `№${entity.ID}`
                    };
                  }
                  // Note для LayerEntytiType.Marker не реализовано
                  return undefined;
                })
            : []
      }
    };
  });
  return { layerData };
});
