import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningGetTrackRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PointTypeApi } from '@common/utils/get-track/types';
import { getTrackState } from '@common/utils/get-track/get-track-state';

export const getTrackReducer = createReducer(initialState, {
  [planningGetTrackRoutine.REQUEST]: (state, action: PayloadAction<{ points: Record<string, PointTypeApi> }>) => {
    const { points } = action.payload;
    state.planPoints.isFetching = true;
    state.planPoints.trackData = points;
    return state;
  },
  [planningGetTrackRoutine.SUCCESS]: (state, action: PayloadAction<{ data: any }>) => {
    const { data } = action.payload;
    state.planPoints.trackData = getTrackState(data, state.planPoints.trackData);
    return state;
  },
  [planningGetTrackRoutine.FULFILL]: (state) => {
    state.planPoints.isFetching = false;
    return state;
  }
});
