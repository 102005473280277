import './styles.less';
import { LockFilled } from '@ant-design/icons/lib/icons';

export const LogisticServicesLockBadge = () => {
  return (
    <div className="logistic-services__card-badge logistic-services__card-badge_lock">
      <LockFilled />
    </div>
  );
};

