import {
  DateFormat,
  DateTimeFormat,
  ILS_MAIN_API_TIME_FORMAT,
  ILS_MAIN_INTERFFACE_TIME_FORMAT,
  TimeFormat,
  TimeStamp
} from '@common/types/general/date-time';
import { MomentInput } from 'moment';
import { doubleToTime } from '@common/utils';
import { EMPTY_STRING } from '@common/constants';
import { parseTimeFromServer } from '@common/utils/helpers/date-time/parsers';

export const parseTimeFromServerToTimeString = ({
  time,
  double = false,
  parseFormat = ILS_MAIN_API_TIME_FORMAT,
  interfaceFormat = ILS_MAIN_INTERFFACE_TIME_FORMAT
}: {
  time?: MomentInput | TimeStamp | null;
  double?: boolean;
  parseFormat?: DateFormat | DateTimeFormat | TimeFormat | null;
  interfaceFormat?: DateFormat | DateTimeFormat | TimeFormat;
}): string => {
  if (double) {
    return doubleToTime(time);
  }
  return parseTimeFromServer({ time, parseFormat: parseFormat ?? ILS_MAIN_API_TIME_FORMAT })?.format(interfaceFormat) ?? EMPTY_STRING;
};
