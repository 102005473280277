import { createRoutine } from 'redux-saga-routines';

export const trackGetProjectRoutine = createRoutine('TRACK/GET_PROJECT');

export const trackGetDepotsRoutine = createRoutine('TRACK/GET_DEPOTS');

export const trackGetItemRoutine = createRoutine('TRACK/GET_ITEM');

export const trackAddTrackToQueryRoutine = createRoutine('TRACK/ADD_TRACK_TO_QUERY');

export const trackGetTrackRoutine = createRoutine('TRACK/GET_TRACK');
