import { tableCustom } from '@core/containers/table-custom';
import { ILSCatalogTable } from '../../containers/table-container';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { Dictionary } from '@common/types';
import { TRANSPORT_COMPANY_TABLE_CONFIG } from '@common/models/transport-company';
import { transportCompanies2table } from '@common/models/transport-company/decorators';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';

export const ILSCatalogTransportCompany: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    return (
      <ILSCatalogTable
        {...props}
        dataDecorator={transportCompanies2table}
        handleDecorator={table2data}
        dictionary={Dictionary.TransportCompany}
      />
    );
  },
  TRANSPORT_COMPANY_TABLE_CONFIG,
  Dictionary.TransportCompany,
  true
);
