import { apiInstance } from '@core/api/instance';
import { IAddressAPI } from '@common/types/general/api/address';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export enum AddressEndpoints {
  Suggestions = 'suggestions',
  getCoordinates = 'getCoordinates',
  getAddress = 'getAddress'
}

const getAddressAPIURL = (endpoint: AddressEndpoints) => {
  return getAPIURL(Dictionary.Address, endpoint as unknown as DictionariesEndpoint);
};

export const AddressAPI: IAddressAPI = {
  suggestions: ({ q }) => apiInstance().post(getAddressAPIURL(AddressEndpoints.Suggestions), { q }),
  getCoordinates: ({ address }) => apiInstance().post(getAddressAPIURL(AddressEndpoints.getCoordinates), { address }),
  getAddress: ({ lat, lon }) => apiInstance().post(getAddressAPIURL(AddressEndpoints.getAddress), { lat, lon })
};
