import { FC, useRef } from 'react';
import { DemandRow } from '../types/store';
import { DemandListItemComponent } from './demand-list-item';
import '../styles.less';
import { List } from 'antd';
import VirtualList from 'rc-virtual-list';

interface Props {
  list: Array<DemandRow>;
  loadNextDemands: () => void;
}

// Основной компонент для отображения списка заявок и проектов в разделе "Заявки и рейсы"
export const DemandListComponent: FC<Props> = ({ list, loadNextDemands }) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const ContainerHeight = listRef.current?.getBoundingClientRect().height ?? 900;
  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      loadNextDemands();
    }
  };

  return (
    <div ref={listRef} className="ils-demand ils-demand-list-wrapper">
      <List>
        <VirtualList
          data={list}
          height={ContainerHeight}
          itemKey={(item) => [item.Data.ID.toString(), item.DateTime, item.Type].join('_')}
          onScroll={onScroll}
        >
          {(item: DemandRow) => (
            <DemandListItemComponent key={[item.Data.ID.toString(), item.DateTime, item.Type].join('_')} listItem={item} />
          )}
        </VirtualList>
      </List>
    </div>
  );
};
