import { RcFile } from 'antd/lib/upload/interface';
import { Notify } from '@common/types';
import { JoinChar } from '@common/constants';
import { ILSUserNotify } from '@components/index';
import { DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE } from '@common/constants/general/form';

export const beforeUpload = (file: RcFile, permittedExtensions: Array<string>, handleUploadDocuments: (documents?: RcFile) => void) => {
  if (!file.size) {
    ILSUserNotify(Notify.Error, 'Нельзя загружать пустые файлы', 3);
    return false;
  }

  if (file.size > DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE) {
    ILSUserNotify(Notify.Error, 'Максимальный размер файла 20МБ', 3);
    return false;
  }

  const fileExtension = file.name.split('.').pop();

  if (!permittedExtensions.includes(`${fileExtension}`)) {
    const permittedExtensionsNotify = permittedExtensions.join(JoinChar.Comma);
    ILSUserNotify(Notify.Error, `Допустимые форматы файлов: ${permittedExtensionsNotify}`, 3);
    return false;
  }
  handleUploadDocuments(file);
};
