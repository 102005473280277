import { IStore } from '@modules/planning/children/params/types/store';

const initialState: IStore = {
  isFetching: false,
  isSaving: false,
  error: [],
  data: null,
  template: null,
  conf: null,
  activeConfig: null,
  localConf: {
    ILS: {},
    Veeroute: {},
    Yandex: {}
  }
};

export default initialState;
