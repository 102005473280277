export const BENCHMARK = 'benchmark';
export const EXCEL_MEDIA_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';

export const SAVED_PARAMS = [BENCHMARK];

export const PROTOCOL_LINK_REGEX = new RegExp('^(http|https)://', 'i');

export const QUERY_PARAMS = {
  DICTIONARY: 'dictionary',
  ID: 'id'
};
