const color1 = '#7d5b2b',
  color2 = '#ed9121',
  color3 = '#ffd700',
  color4 = 'red';

export const ROAD_TYPES = {
  unknown: {
    label: 'Неизвестные',
    color: color1,
    min: 14,
    max: 18,
    weight: 2
  },
  motorway: {
    label: 'Автомагистрали',
    color: color1,
    min: 1,
    max: 12,
    weight: 2
  },
  'motorway-link': {
    label: 'Съезды-Автомагистрали',
    color: color1,
    min: 14,
    max: 18,
    weight: 2
  },
  trunk: {
    label: 'Трассы',
    color: color1,
    min: 6,
    max: 13,
    weight: 2
  },
  'trunk-link': {
    label: 'Съезды-Трассы',
    color: color1,
    min: 15,
    max: 18,
    weight: 2
  },
  primary: {
    label: 'Региональные',
    color: color1,
    min: 10,
    max: 15,
    weight: 2
  },
  'primary-link': {
    label: 'Съезды-Региональные',
    color: color1,
    min: 15,
    max: 18,
    weight: 2
  },
  secondary: {
    label: 'Областные',
    color: color1,
    min: 11,
    max: 16,
    weight: 2
  },
  'secondary-link': {
    label: 'Съезды-Областные',
    color: color1,
    min: 15,
    max: 18,
    weight: 2
  },
  tertiary: {
    label: 'Важные',
    color: color2,
    min: 14,
    max: 18,
    weight: 2
  },
  'tertiary-link': {
    label: 'Съезды-Важные',
    color: color2,
    min: 16,
    max: 18,
    weight: 2
  },
  residential: {
    label: 'Местные',
    color: color2,
    min: 15,
    max: 18,
    weight: 2
  },
  road: {
    label: 'Обычные',
    color: color3,
    min: 13,
    max: 18,
    weight: 2
  },
  unclassified: {
    label: 'Неклассифицированные',
    color: color3,
    min: 14,
    max: 17,
    weight: 2
  },
  service: {
    label: 'Служебные',
    color: color3,
    min: 17,
    max: 18,
    weight: 2
  },
  'living-street': {
    label: 'Дворы',
    color: color4,
    min: 16,
    max: 18,
    weight: 3
  },
  pedestrian: {
    label: 'Пешеходные',
    color: color4,
    min: 15,
    max: 18,
    weight: 3
  }
};
