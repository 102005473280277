import { hasTableButtonsInHeader } from '@components/data-display/table/utils/helpers/has-table-buttons-in-header';
import { IILSTableProps, TableRecord } from '@common/types';

export const useTableTitle = <R extends TableRecord>({
  tableTitle,
  config,
  recordTableButtons,
  tableWidgets,
  actionTableButtons,
  settingsTableButtons
}: Pick<
  IILSTableProps<R>,
  'tableTitle' | 'config' | 'recordTableButtons' | 'tableWidgets' | 'actionTableButtons' | 'settingsTableButtons'
>) => {
  const hasHeaderButtons = hasTableButtonsInHeader([recordTableButtons, tableWidgets, actionTableButtons, settingsTableButtons]);

  const hasHeader =
    tableTitle ||
    hasHeaderButtons ||
    config?.header ||
    config?.adding ||
    config?.reloading ||
    config?.deleting ||
    config?.showDeleted ||
    !config?.hideClearButtons;

  return { hasHeader };
};
