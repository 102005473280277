import { call, put, takeLatest } from 'redux-saga/effects';
import { Data } from '@modules/planning/children/data/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { dataValidateRoutine, tableDataChangeRoutine } from '@modules/planning/children/data/actions';
import { ChangeDataPayload } from '@modules/planning/children/data/types/components';

function* tableDataChangeWorker(action: PayloadAction<ChangeDataPayload>) {
  const { request, success, failure, fulfill } = tableDataChangeRoutine;
  const { file, row, cell, value, rows, operation, values } = action.payload;
  try {
    yield put(request());
    const { data } = yield call(Data.change, { file, row, cell, value, rows, operation, values });
    yield put(
      success({
        data: data.data.data,
        files: data.data.files,
        file,
        row,
        cell,
        value,
        rows,
        operation,
        values
      })
    );
    yield put(dataValidateRoutine());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tableDataChangeWatcher() {
  yield takeLatest(tableDataChangeRoutine.trigger, tableDataChangeWorker);
}
