import { ILSForm, ILSFormItem, ILSInput } from '@common/components';
import { User } from '@common/types';
import { ILSSelectCurrency } from '@components/data-entry/select';
import { TenderSearchOfferTitleComponent } from '@modules/tender-platform/children/tender-search/components/offer/offer-title';
import { ILSVehicleNDriverField } from '@modules/tender-platform/children/tender-search/components/offer/vehicle-n-driver';
import { TenderOfferFormItem, TenderOfferFormItemLabel } from '@modules/tender-platform/children/tender-search/constants/offer';
import { tenderOfferFormSelector } from '@modules/tender-platform/children/tender-search/selectors/components/tender-offer-form';
import { TenderForm } from '@modules/tender-platform/types/platform';
import { Form } from 'antd';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TenderOfferFormValues } from '../../types/offer';
import { TenderPlatformSearchUserField } from '@modules/tender-platform/children/tender-search/components/offer/form/fields/user';

interface IProps {
  initialValues?: Partial<TenderOfferFormValues> | undefined;
  canChangeOffer: boolean;
  canSelectResponsible: boolean;

  onFinish(values: {
    Price?: number;
    TenderOffer: {
      UserID: User['ID'];
    };
  }): void;
}

export const ILSTenderOfferForm: FC<IProps> = ({ onFinish, canSelectResponsible, canChangeOffer }) => {
  const [form] = Form.useForm();
  const { initialValues, tenderOfferID } = useSelector(tenderOfferFormSelector);

  useEffect(() => {
    if (initialValues) {
      form.resetFields(Object.keys(initialValues).filter((key) => initialValues[key]));
      form.setFieldsValue({ ...initialValues });
    }
  }, [initialValues]);

  return (
    <ILSForm
      form={form}
      id={TenderForm.Offer}
      name={TenderForm.Offer}
      onFinish={onFinish}
      className={'tender-platform-card-form'}
      initialValues={initialValues}
    >
      <TenderSearchOfferTitleComponent tenderOfferID={tenderOfferID} />
      <fieldset className={'tender-platform-card-form-offer'}>
        <ILSFormItem label={TenderOfferFormItemLabel.Price} name={[TenderOfferFormItem.TenderOffer, TenderOfferFormItem.Price]}>
          <ILSInput type={'number'} disabled={!canChangeOffer} addonAfter={<ILSSelectCurrency disabled />} />
        </ILSFormItem>
        <ILSVehicleNDriverField disabled={!canChangeOffer} />
        <TenderPlatformSearchUserField disabled={!canSelectResponsible} />
        <ILSFormItem label={TenderOfferFormItemLabel.Alias} name={TenderOfferFormItem.Alias}>
          <ILSInput disabled />
        </ILSFormItem>
      </fieldset>
    </ILSForm>
  );
};
