import { ISettingsAPI } from '@modules/settings/types/api';
import { apiInstance } from '@core/api/instance';
import { ContentType } from '@common/types/general/general';

export const SettingsAPI: ISettingsAPI = {
  changePassword: ({ newPassword, currentPassword }) =>
    apiInstance().post('/NewTms/Auth/changePassword', {
      newPassword,
      currentPassword
    }),
  getUserParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/index', JSON.stringify(values)),
  createUserParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/create', JSON.stringify(values)),
  mergeUserParams: (values) =>
    apiInstance({
      contentType: ContentType.ApplicationJson
    }).post('/NewTms/UserParams/merge', JSON.stringify(values))
};

