import { int2color } from '@common/decorators/color';
import { IndexedArray } from '@common/types';
import { AnalyticsWidgetDataActions, AnalyticsWidgetMetricsModel, AnalyticsWidgetValue } from '@common/types/dictionaries/analytics';
import { generateUUID } from '@common/utils/general/uuid';
import { string2Number } from '@common/utils/helpers/string/string-to-number';
import { WidgetProps } from '../types/container';
import { AnalyticsWidgetDataType, AnalyticsWidgetFieldType } from '../types/tables';
import { number2FormattedString } from '@common/utils/helpers/numbers/number-to-formated-string';

/**
 * Преобразование данных к виджета к формату воспринимаемому таблицей
 * @param widgetDataEntry
 * @param config
 */
const convertAnalyticsWidgetTableDataEntry = (widgetDataEntry: AnalyticsWidgetValue, config: AnalyticsWidgetMetricsModel): any => {
  switch (config?.cellType) {
    case AnalyticsWidgetFieldType.ProgressCell:
      const total = (100 * (widgetDataEntry.value as number)) / (widgetDataEntry?.percent as number);
      return [string2Number(widgetDataEntry.value), total, config?.format ? config?.format[1] : null];
    case AnalyticsWidgetFieldType.TrafficLightCell:
      return [widgetDataEntry.value, widgetDataEntry?.color ? int2color(widgetDataEntry?.color) : null];
    case AnalyticsWidgetFieldType.FavoritesCell:
      return widgetDataEntry.value && widgetDataEntry.value === 'true' ? 'favorite' : 'not-favorite';
    default:
      if (config?.type && config.type === AnalyticsWidgetDataType.Number && config.format) {
        return number2FormattedString(parseInt(widgetDataEntry.value + ''), config.format[1]);
      } else {
        return widgetDataEntry.value;
      }
  }
};

export const convertAnalyticsWidgetTableData = (
  params: {
    widgetData: Array<IndexedArray<AnalyticsWidgetValue>>;
    metrics: IndexedArray<AnalyticsWidgetMetricsModel>;
    actions?: AnalyticsWidgetDataActions[];
  } & Pick<WidgetProps, 'handleSelectBoard'>
): any[] => {
  const { widgetData, metrics, actions = [], handleSelectBoard } = params;
  if (!widgetData) return [];
  return Object.values(widgetData).map((dataRow) => {
    const row = {};

    if (actions?.length) {
      const menuActions = actions.map((action) => {
        const params: IndexedArray<{ name: string; value: string }> = Object.keys(action.params).reduce((acc, curr) => {
          acc[curr] = { value: dataRow[curr].value, name: curr };
          return acc;
        }, {});
        const actionCallback = () =>
          handleSelectBoard &&
          handleSelectBoard(
            {
              ID: action.boardID,
              Name: null,
              From: null,
              Till: null,
              Serial: 0,
              Active: 0
            },
            { defaults: { params, boardId: action.boardID } }
          );
        return { ...action, actionCallback };
      });
      row['menu-button'] = menuActions;
    }

    Object.values(dataRow).map((column) => {
      if (column.data && !column.hidden) {
        row[column.data] = convertAnalyticsWidgetTableDataEntry(column, metrics[column.data]);
      }
    });
    row['key'] = row['ID'] || generateUUID();
    return row;
  });
};
