import { ColumnTable, TableColumnName, TableConfig, TableRecord } from '@common/types';

/**
 * Функция проверки редактируемости ячейки.
 * Если у строки есть поле grouping, и оно true, то изменять строку нельзя.
 * Если существует функция onCanEdit, напр. в таблице Vehicles - canEditVehicle,
 * то вызываем ее, если grouping false;
 * В остальных случаях смотрим в config, какое значение editable колонки.
 * @param record - данные строки;
 * @param dataIndex - название текущей колонки;
 * @param catalogConfig - конфигурация таблицы;
 * @param onCanEdit - функция проверки на редактируемость ячейки;
 * @returns - boolean значение, возможно ли редактировать ячейку или нет;
 */
export const canEditCatalogElement = (
  record: Partial<Record<ColumnTable<TableRecord>['dataIndex'] | string, any>>,
  dataIndex: TableColumnName,
  catalogConfig: TableConfig,
  onCanEdit?: (record: any, dataIndex: string) => boolean
) => {
  if (record?.groupingKey) {
    return false;
  } else if (onCanEdit) {
    return onCanEdit?.(record, dataIndex);
  }
  return Boolean(catalogConfig.columns.find((column) => column.dataIndex === dataIndex)?.editable);
};
