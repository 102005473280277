import { createReducer } from '@reduxjs/toolkit';
import { getCurrentBalanceRoutine } from '@common/feature/payment/actions';
import { PaymentCurrentBalanceResponse, PaymentInitialState } from '@common/feature/payment/types/general';
import { paymentInitialState } from '@common/feature/payment/store';
import { onFailure } from '@common/utils/helpers/reducers';

export const getCurrentBalanceReducer = createReducer(paymentInitialState, {
  [getCurrentBalanceRoutine.REQUEST]: (state: PaymentInitialState) => {
    state.currentBalance.isFetching = true;
    return state;
  },
  [getCurrentBalanceRoutine.SUCCESS]: (state: PaymentInitialState, action: { payload: { data: PaymentCurrentBalanceResponse } }) => {
    const { data } = action.payload;
    state.currentBalance = {
      ...state.currentBalance,
      sum: data.CurrentBalance ?? 0,
      lastUpdate: data.LastUpdate ?? null
    };
    return state;
  },
  [getCurrentBalanceRoutine.FAILURE]: onFailure,
  [getCurrentBalanceRoutine.FULFILL]: (state: PaymentInitialState) => {
    state.error = [];
    state.currentBalance.isFetching = false;
    return state;
  }
});

