import { ActiveTabType, ButtonType } from '@modules/catalog/types/tariff';
import {
  TARIFF_CONST_BUTTON_CLASSNAME,
  TARIFF_CONST_BUTTON_DATA_ATTR,
  TARIFF_CONST_BUTTON_DELETE_LINK_CLASSNAME
} from '@modules/catalog/components/tariff/constants/general';

export const createTariffButtonHTML = (activeTab: ActiveTabType, btn: ButtonType) => {
  const deleteBtn = `<span style="cursor: pointer; font-size: 18px;" class="${TARIFF_CONST_BUTTON_DELETE_LINK_CLASSNAME}">&times;</span>`;

  switch (activeTab) {
    case ActiveTabType.func:
      return `<label class="${TARIFF_CONST_BUTTON_CLASSNAME} formulaBtn--func mceNonEditable" ${TARIFF_CONST_BUTTON_DATA_ATTR}=${
        btn.variable + Date.now()
      }><em>${btn.variable}</em>(<span class="mceEditable">${btn.example}</span>) ${deleteBtn}</label>`;
    case ActiveTabType.param:
      return `<a class="${TARIFF_CONST_BUTTON_CLASSNAME} formulaBtn--params mceNonEditable" ${TARIFF_CONST_BUTTON_DATA_ATTR}=${
        btn.variable + Date.now()
      }>${btn.desc.replace('Формула: ', '')} ${deleteBtn}</a>`;
    case ActiveTabType.const:
      return `<a class="${TARIFF_CONST_BUTTON_CLASSNAME} mceNonEditable" ${TARIFF_CONST_BUTTON_DATA_ATTR}=${btn.variable + Date.now()}>${
        btn.name
      } ${deleteBtn}</a>`;
    default:
      return '';
  }
};

