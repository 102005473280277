import { Dictionary } from '@common/types';
import { isArray, isEmpty } from 'lodash';
import { ProjectState } from '@common/types/dictionaries/project';

/**
 * Восстанавливает удаленные записи в сторе справочников в проекте.
 * Мутирует projectState
 * @param projectState данные справочников, которые нужно обновить
 * @param ids Массив восстановленных записей
 * @param dictionary
 * @returns возвращает обновленные данные
 */
export const restoreProjectData = ({
  projectState,
  ids,
  dictionary
}: {
  projectState: ProjectState;
  ids?: number[];
  dictionary: Dictionary;
}) => {
  if (isArray(ids) && !isEmpty(ids)) {
    if (dictionary) {
      for (const id of ids) {
        if (projectState[dictionary]?.[id]) {
          projectState[dictionary][id].Deleted = null;
        }
      }
    }
  }
  return projectState;
};
