import { call, put, select, takeLatest } from 'redux-saga/effects';
import { deleteOrderRoutine, getTotalCountRoutine, getTripNOrderDataRoutine, showFormRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ordersSelector } from '@modules/orders-and-trips/selectors';
import { FormType } from '@modules/orders-and-trips/types/general';
import { IndexedArray, Order } from '@common/types';

function* deleteOrderWorker(
  action: PayloadAction<{
    id: number;
  }>
) {
  const { request, success, failure, fulfill } = deleteOrderRoutine;
  const { id } = action.payload ?? {};
  try {
    yield put(request());

    const orders: IndexedArray<Order> = yield select(ordersSelector);
    const oldOrders = Object.keys(orders);
    const deletedOrderIndex = oldOrders?.indexOf(id.toString());
    const prevOrderID = oldOrders?.[deletedOrderIndex - 1];

    yield call(OrdersNTripsApi.deleteOrder, { ids: [id] });
    yield put(success({ id, prevOrderID }));

    /** Запрашиваем данные предыдущей заявки для формы,
     * либо если не нашли ID предыдущей заявки, то сбрасываем данные формы и закрываем форму */
    if (prevOrderID) {
      yield put(getTripNOrderDataRoutine({ ids: [prevOrderID], formType: FormType?.UpdateOrder }));
    } else {
      yield put(getTripNOrderDataRoutine.success({ data: null, formType: FormType?.UpdateOrder }));
      yield put(showFormRoutine.success({ formType: null }));
    }
    yield put(getTotalCountRoutine.trigger());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteOrderWatcher() {
  yield takeLatest(deleteOrderRoutine.trigger, deleteOrderWorker);
}

