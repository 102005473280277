import { catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, Key } from '@common/types';

function* catalogSetFocusRowWorker(action: PayloadAction<{ key: Key; dictionary: Dictionary }>) {
  const { key, dictionary } = action.payload;
  const { success, failure, fulfill } = catalogSetFocusRowRoutine;
  try {
    yield put(success({ key, dictionary }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogSetFocusRowWatcher() {
  yield takeEvery(catalogSetFocusRowRoutine.trigger, catalogSetFocusRowWorker);
}
