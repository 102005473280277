import { AxiosResponse } from 'axios';
import { ErrorAPIData, ResponseAPI } from '@common/types';
import { DemandTabType } from './tabs';

export type RequestParamsFilter = {
  type?: DemandTabType;
  from?: string;
  till?: string;
  nameOwner?: string;
  nameCarrier?: string;
  driver?: string;
  vehicle?: string;
};
export type GetListRequest = {
  page?: number;
  perPage?: number;
  filter?: RequestParamsFilter;
};

export enum DemandItemType {
  Project = 'Project',
  TenderLot = 'TenderLot'
}

export type DemandList = {
  data: Array<any>;
};

export type GetListResponse = {
  data: Array<DemandList>;
};

export type GetCountResponse = {
  data: DemandTabType;
};

export interface IDemandAPI {
  getList(params: GetListRequest): Promise<AxiosResponse<ResponseAPI<GetListResponse> | ErrorAPIData>>;

  getCounts({}): Promise<AxiosResponse<ResponseAPI<GetCountResponse> | ErrorAPIData>>;
}
