import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningGetProjectCurrentRoutine } from '../../actions';
import { PlanningStatus } from '@modules/planning/types';
import { initialState } from '@modules/planning/children/by-date/store';
import { onFailure } from '@common/utils';
import { GetProjectResponse } from '@common/types/dictionaries/project';
import { Plan } from '@common/types';
import { DictionaryCatalogInfo } from '@modules/planning/children/by-date/types/store';
import { INITIAL_PROJECT_CATALOG } from '@modules/planning/children/by-date/constants/store';

export const getProjectCurrentReducer = createReducer(initialState, {
  [planningGetProjectCurrentRoutine.REQUEST]: (state) => {
    state.info.project.isFetching = true;
    return state;
  },
  [planningGetProjectCurrentRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      project: GetProjectResponse;
    }>
  ) => {
    const { project } = action.payload;
    const plans = project.Plan ?? {};
    state.project = { ...INITIAL_PROJECT_CATALOG, ...project };
    //TODO helper
    state.info.plans = Object.keys(plans).reduce((info: Record<Plan['ID'], DictionaryCatalogInfo>, planID) => {
      info[planID] = {
        isFetching: false,
        lastUpdate: Date.now()
      };
      return info;
    }, {});
    return state;
  },
  [planningGetProjectCurrentRoutine.FAILURE]: onFailure,
  [planningGetProjectCurrentRoutine.FULFILL]: (state) => {
    state.status = PlanningStatus.Result;
    state.error = [];
    state.info.project.isFetching = false;
    return state;
  }
});
