import { handleMapFocus } from '@modules/planning/children/by-date/helpers/map/handle-map-focus';
import { FocusOnMapItem } from '@modules/planning/children/by-date/types';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';

export const getFocusOnMapItem = (setTriggerMapFocus: SetTriggerMapFocus) => {
  return {
    icon: 'right-arrow',
    title: 'Перейти к объекту на карте',
    onClick: handleMapFocus(setTriggerMapFocus)
  } as FocusOnMapItem;
};
