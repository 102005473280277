import { getDocumentMetricsRoutine } from './../actions/index';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Notify } from '@common/types';
import { ILSUserNotify } from '@common/components';
import { Documents } from '../api';

function* getDocumentMetricsWorker(action: PayloadAction<{ type: string }>) {
  const { failure, success, fulfill, request } = getDocumentMetricsRoutine;

  try {
    yield put(request());

    const { data } = yield call(Documents.getDocumentMetrics, action.payload);

    yield put(
      success({
        fields: data.data
      })
    );
  } catch (error) {
    yield put(failure({ error }));
    ILSUserNotify(Notify.Error, error as any, 3);
  } finally {
    yield put(fulfill());
  }
}

export function* getDocumentMetricsWatcher() {
  yield takeEvery(getDocumentMetricsRoutine.trigger, getDocumentMetricsWorker);
}

