import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { createDepotCascaderOptions } from '@common/models/depot/decorators/create-depot-cascader-options';
import { OrderStatus } from '@common/models/order';
import { order2PlannerTable } from '@common/models/order/decorators';
import { Depot, Dictionary, IndexedArray, Order, ReferenceTablesData, TableColumnName } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils';
import { filterObjectByKeys } from '@common/utils/helpers/object/filter-by-keys';
import { pickByRestrictionId } from '@modules/planning/children/by-date/decorators/filters/pick-by-restriction-id';
import { groupSwitcherSelector } from '@modules/planning/children/by-date/selectors/components/by-date';
import { selectedOrderTableRowsSelector } from '@modules/planning/children/by-date/selectors/components/tables/selected-table-rows';
import { planResolvedOrdersSelector, planUnresolvedOrdersSelector } from '@modules/planning/children/by-date/selectors/current-plan';
import {
  planningByDateInfoSelector,
  projectCargoSelector,
  projectCommonDictionarySelector,
  projectDepotOverloadSelector,
  projectDepotSelector,
  projectIDSelector,
  projectOrderSelector,
  projectWorktimeSelector
} from '@modules/planning/children/by-date/selectors/project';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { isEmpty, pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { SelectedOrdersLasso, SelectedOrdersLassoType } from '../../../types/map-container';
import { activeRestrictionIDsFiltersSelector } from '@modules/planning/children/by-date/selectors/restriction/active-restriction-ids-filters';

const DICTIONARY_FOR_PLANNING_ORDER = [Dictionary.Worktime, Dictionary.Restriction, Dictionary.Contact];

export const orderTableSwitchersSelector = createSelector(groupSwitcherSelector, (groupSwitcher) => groupSwitcher?.[Dictionary.Order]);
export const orderTableTargetSwitchersSelector = createSelector(
  orderTableSwitchersSelector,
  (groupOrderSwitcher) => groupOrderSwitcher?.TargetID
);

export const orderTableSelector = createSelector(
  projectDepotSelector,
  projectCargoSelector,
  (state: PlanningByDateState) => projectCommonDictionarySelector(state, DICTIONARY_FOR_PLANNING_ORDER),
  projectIDSelector,
  planningByDateInfoSelector,
  projectOrderSelector,
  (depotData, cargoData, { Restriction, Contact, Worktime }, projectID, catalogInfo, allOrdersIDs) => {
    const depotReference = createDepotCascaderOptions(depotData);
    const refData: ReferenceTablesData = {
      [TableColumnName.SourceID]: depotReference,
      [TableColumnName.TargetID]: depotReference,
      [TableColumnName.SourceContactID]: Contact,
      [TableColumnName.TargetContactID]: Contact,
      [TableColumnName.WorktimeID]: Worktime,
      [TableColumnName.RestrictionID]: Restriction,
      [TableColumnName.CargoID]: {
        options: cargoData,
        dictionary: Dictionary.Cargo,
        fieldNames: DEFAULT_FIELD_NAMES
      }
    };
    return { refData, projectID, catalogInfo, depotData, allOrdersIDs: Object.keys(allOrdersIDs ?? {}).map(Number) };
  }
);

export const projectDataForPlannerMapTablesSelector = createSelector(
  projectWorktimeSelector,
  projectDepotOverloadSelector,
  (Worktime, DepotOverload) => {
    return {
      Worktime,
      DepotOverload
    };
  }
);

export const plannerMapTablesContainerSelector = ({ selectedOrdersLasso }: { selectedOrdersLasso?: SelectedOrdersLasso }) =>
  createSelector(
    projectDataForPlannerMapTablesSelector,
    orderTableTargetSwitchersSelector,
    projectDepotSelector,
    planResolvedOrdersSelector,
    planUnresolvedOrdersSelector,
    selectedOrderTableRowsSelector,
    activeRestrictionIDsFiltersSelector,
    ({ Worktime, DepotOverload }, byTargetID, depots, resolvedOrders, unResolvedOrders, { selectedOrders }, activeRestrictionIDs) => {
      const orderStatus =
        selectedOrdersLasso?.type === SelectedOrdersLassoType.ResolvedOrder ||
        selectedOrders?.some((id) => {
          return String(id) in resolvedOrders;
        })
          ? OrderStatus.Resolved
          : OrderStatus.Unresolved;
      const allCurrentOrders = orderStatus === OrderStatus.Unresolved ? unResolvedOrders : resolvedOrders;
      const orders = filterObjectByKeys(allCurrentOrders, selectedOrdersLasso?.orderIDs);

      const dataSource = order2PlannerTable({
        orders: pickByRestrictionId(orders, activeRestrictionIDs),
        DepotOverload,
        Worktime,
        depots,
        byTargetID
      });

      const checkedOrders = isEmpty(selectedOrders)
        ? []
        : (pickBy(orders, (order, orderID) => {
            return selectedOrders.map(Number).includes(Number(orderID));
          }) as IndexedArray<Order> | Array<Order>);

      const checkedOrdersArray = createArrayFromIndexedArray(checkedOrders);
      const targets = new Set(
        checkedOrdersArray?.reduce((clients: Array<Depot['ID']>, order) => {
          if (order.TargetID) {
            clients.push(order.TargetID);
          }
          return clients;
        }, [])
      );

      const orderSummary = checkedOrdersArray.reduce(
        (orderSummary, order) => {
          orderSummary.mass += order.Mass;
          orderSummary.volume += order.Volume;
          orderSummary.pallets += order.Pallets;
          return orderSummary;
        },
        {
          clients: targets.size,
          mass: 0,
          volume: 0,
          pallets: 0
        }
      );

      return {
        selectedOrders,
        orderSummary,
        dataSource,
        orderStatus
      };
    }
  );
