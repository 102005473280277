import { takeLatest } from 'redux-saga/effects';
import { notifierWorkerCreator } from '@common/utils/notifier/notifier-worker-creator';
import { Module, ModulePath, Notify, NotifyDurationInSecond } from '@common/types';
import { analyticsDefaultStatuses } from '@modules/analytics/common/constants/default-messages';
import { AnalyticsPlanningActionsType, AnalyticsPlanningRoutinesToNotify, AnalyticsPlanningRoutinesToNotifyErrors } from '../actions';
import { getErrorMessage } from '@common/utils';

/**Метод получения текста уведомления для разных экшенов*/
const getDataNotificationMessage = (
  action: AnalyticsPlanningActionsType,
  type: Notify.Loading | Notify.Error | Notify.Success,
  payload?: { message: string; hideSuccessMessage?: boolean }
): string => {
  if (payload?.hideSuccessMessage) return '';
  return payload?.message || analyticsDefaultStatuses?.[action]?.[type] || '';
};

export default getDataNotificationMessage;

const getActionFromRoutine = (actionName: string): AnalyticsPlanningActionsType => {
  return actionName as AnalyticsPlanningActionsType;
};

let worker = notifierWorkerCreator<{ message: string; hideSuccessMessage?: boolean }, AnalyticsPlanningActionsType>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Analytics}${ModulePath.AnalyticsPlanningBoards}`,
  getNotificationMessage: getDataNotificationMessage,
  successDuration: NotifyDurationInSecond.Two
});

let errorWorker = notifierWorkerCreator<{ error: string | string[] }, AnalyticsPlanningActionsType>({
  getActionFromRoutine,
  getKeyPrefix: () => `${Module.Analytics}${ModulePath.AnalyticsPlanningBoards}`,
  getNotificationMessage: () => '',
  getErrorMessage
});

export function* analyticsPlanningNotifierWatcher() {
  for (let routine of AnalyticsPlanningRoutinesToNotify) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.FAILURE, errorWorker);
  }

  for (let routine of AnalyticsPlanningRoutinesToNotifyErrors) {
    yield takeLatest(routine.FAILURE, errorWorker);
  }
}
