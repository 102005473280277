import { DictionariesEndpoint, Dictionary, Keys } from '@common/types';
import { DictionaryOperation, DictionaryRestoreRequest } from '@common/types/general/api/dictionary';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';

export const handleRestoreRows =
  ({ dispatch, dictionary }: { dispatch: Dispatch; dictionary: Dictionary }) =>
  (ids: Keys | null) => {
    if (!ids) return;
    const payload: DictionaryOperation<DictionaryRestoreRequest> & {
      dictionaryFetching?: boolean;
    } = {
      dictionary,
      ids,
      operation: DictionariesEndpoint.Restore
    };
    dispatch(dictionaryPlannerRoutine(payload));
  };
