import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { loginActions } from '@modules/current-user/actions';
import { UserAuth } from '@common/types';

export const loginReducer = createReducer(authInitialState, {
  [loginActions.REQUEST]: onRequest,
  [loginActions.FAILURE]: onFailure,
  [loginActions.FULFILL]: onFulfill,
  [loginActions.SUCCESS]: (state, action: PayloadAction<{ token: string; user: UserAuth }>) => {
    const { token, user } = action.payload;
    state.token = token;
    state.user = user;
    state.requestAction = null;
    return state;
  }
});
