import { createSelector } from 'reselect';
import { projectDriverSelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary, ReferenceTableData, ReferenceTablesData, TableColumnName } from '@common/types';
import { getDriversOptions } from '@modules/planning/children/by-date/helpers/tables/ref-options/plan/get-drivers-options';

export const refDataForRoutesTableSelector = createSelector(projectDriverSelector, (drivers) => {
  const driverOptions: ReferenceTableData = {
    options: getDriversOptions({ drivers }),
    fieldNames: { value: 'driverID', label: 'name' },
    dictionary: Dictionary.Driver
  };
  const refData: ReferenceTablesData = {
    [TableColumnName.ExpeditorID]: driverOptions,
    [TableColumnName.DriverID]: driverOptions
  };
  return { refData };
});
