import { FC } from 'react';
import { ILSFormItem } from '@common/components';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { ILSFormItemProps } from '@components/data-entry/form';
import { Field, FORM_RULES, Label } from '@components/common/forms/constants';

interface IProps extends ILSFormItemProps {
  pointIndex?: number;
  disabled?: boolean;
  hasRules?: boolean;
  label?: Label;
  pointType?: Field.Source | Field.Target;
}

export const PointAddressFormItem: FC<IProps> = ({
  pointType = Field.Source,
  pointIndex = 0,
  disabled = false,
  label = Label.Source,
  ...props
}) => {
  return (
    <ILSFormItem name={[pointType, Field.Address]} label={label} rules={FORM_RULES[pointType]} {...props}>
      <ILSSuggestionAutoComplete disabled={disabled} />
    </ILSFormItem>
  );
};
