import { createSelector } from 'reselect';
import { ActiveRecord, InitialState } from '@modules/orders-and-trips/types/general';
import { extraDataFetchSelector, extraVehicleTypesSelector } from '@modules/orders-and-trips/selectors/extra-data';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { Dictionary } from '@common/types';

export const draftTripNOrderSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.serviceData.form,
  (form) => form
);

export const fetchDraftTripNOrderSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.serviceData.isFetching,
  (isFetching) => isFetching
);

export const fetchTripsSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.isFetching,
  (isFetching) => isFetching
);

export const tripsDataSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.data,
  (tripsData) => tripsData
);

export const tripsDataSelectorByDictionary = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.data,
  (tripsData) => ({
    [Dictionary.Trip]: Object.values(tripsData ?? {}).reverse()
  })
);

export const fetchDraftTripSelector = createSelector(extraDataFetchSelector, fetchTripsSelector, (extraDataFecth, fetchDraftTrip) =>
  Boolean(extraDataFecth || fetchDraftTrip)
);

export const tripStatusSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.tripStatus,
  (tripStatus: TripStatus) => tripStatus
);

export const activeTripSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.activeRecord,
  (activeTrip: ActiveRecord) => activeTrip
);

export const tripsCountSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.tripsCount,
  (tripsCount) => tripsCount
);

export const tripsPaginationSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.pagination,
  (tripsCount) => tripsCount
);

export const updateTripStatusSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.trips.updateTrips,
  (updateTripStatus) => updateTripStatus
);

export const tripsAllDataSelector = createSelector(
  tripsDataSelectorByDictionary,
  tripStatusSelector,
  activeTripSelector,
  tripsCountSelector,
  tripsPaginationSelector,
  extraVehicleTypesSelector,
  updateTripStatusSelector,
  (tripsData, tripStatus, activeTrip, tripsCount, tripsPagination, vehicleTypes, updateTripStatus) => ({
    tripsData,
    tripStatus,
    activeTrip,
    tripsCount,
    tripsPagination,
    vehicleTypes,
    updateTripStatus
  })
);

export const tripsContractTemplateSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.tripContract?.contractTemplate,
  (contractTemplate) => contractTemplate
);

export const tripsContractFetchSelector = createSelector(
  (state: { OrdersAndTrips: InitialState }) => state.OrdersAndTrips.tripContract?.isFetching,
  (isFetching) => isFetching
);

