import { Dictionary } from '@common/types';
import { catalogReadRoutine } from '@modules/catalog/actions';
import { Dispatch } from 'redux';
import { CatalogRead } from '@modules/catalog/types/actions';

/** загрузка справочников */
export const handleDictionaryLoad =
  ({
    dispatch,
    payload = {
      dictionary: Dictionary.Empty,
      /** для связанных справочников/выпадающих списков, без уведомлений и сортировки по проекту */
      uncheckProjectButton: true,
      dictionaryFetching: true,
      withoutNotification: true
    }
  }: {
    dispatch: Dispatch;
    payload?: CatalogRead;
  }) =>
  (dictionary: Dictionary) => {
    dispatch(
      catalogReadRoutine({
        ...payload,
        dictionary
      })
    );
  };
