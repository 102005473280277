import { Dictionary } from '@common/types';
import { createSelector } from 'reselect';
import { CatalogState, Name } from '../types/store';
import { filterCatalogData } from '../helpers/catalog-dictionary-filter';
import { swapToOriginalDictionary } from '../helpers/swap-catalog-dictionary';
import { EMPTY_ARRAY_DATA } from '@common/constants';

/** Не нужны useMemo/createSelector, так как это наоборот ломает логику селектора, memo уже прописано внутри catalogSelector*/
export const catalogSelector = () => catalogSimpleSelector;

export const catalogValueStateByKeySelector = createSelector(
  [
    (state: CatalogState, dictionary: Dictionary, name: Name) => {
      return catalogStoreSelector(state)?.[swapToOriginalDictionary(dictionary)]?.[name] ?? null;
    }
  ],
  (data) => data
);
export const catalogDictionarySecondSelector = createSelector(
  [(state: CatalogState, dictionary: Dictionary) => dictionary],
  (dictionary) => dictionary
);
export const catalogNameThirdSelector = createSelector([(state: CatalogState, dictionary: Dictionary, name: Name) => name], (name) => name);

export const catalogSimpleSelector = createSelector(
  [catalogValueStateByKeySelector, catalogDictionarySecondSelector, catalogNameThirdSelector],
  (data, dictionary: Dictionary, name: Name) => {
    switch (name) {
      case 'data':
        return filterCatalogData(data, dictionary);
      case 'error':
      case 'execStatus':
      case 'isFetching':
      case 'refresh':
      case 'showDeleted':
      case 'focusRow':
      case 'map':
        return data;
      default:
        return data ?? EMPTY_ARRAY_DATA;
    }
  }
);

export const catalogStoreSelector = createSelector([(state: CatalogState) => state.Catalog], (catalog) => catalog);

export const catalogDictionarySelector = (dictionary: Dictionary) => createSelector(catalogStoreSelector, (catalog) => catalog[dictionary]);

export const catalogDictionaryNameSelector = (dictionary: Dictionary, name: Name) =>
  createSelector(catalogDictionarySelector(dictionary), (catalog) => catalog[name]);

export const catalogFullStateSelector = () =>
  createSelector(
    [(state: CatalogState, dictionary: Dictionary) => catalogStoreSelector(state)[dictionary], (_, dictionary: Dictionary) => dictionary],
    (data, dictionary: Dictionary) => (dictionary ? data : undefined)
  );

/**
 * Не использовать если нет необходимости получать инфо для всех справочников
 * вместо этого использовать dictionaryInfoSelector
 */
export const infoSelector = createSelector([(state: CatalogState) => catalogStoreSelector(state).info], (info) => info);

export const dictionaryInfoSelector = createSelector(
  [(state: CatalogState, dictionary: Dictionary) => infoSelector(state)?.[dictionary]],
  (info) => info
);

export const dictionaryLastUpdateSelector = createSelector(
  [(state: CatalogState, dictionary: Dictionary) => dictionaryInfoSelector(state, dictionary).lastUpdate],
  (lastUpdate) => lastUpdate
);

export const catalogDictionaryFiltersSelector = createSelector(
  [
    (state: CatalogState, dictionary: Dictionary) => catalogSimpleSelector(state, dictionary, 'showDeleted'),
    (state: CatalogState, dictionary: Dictionary) => catalogSimpleSelector(state, dictionary, 'projectID')
  ],
  (showDeleted, projectID) => {
    return { showDeleted, projectID };
  }
);
