import { VehicleTableFilterDesc, VehicleTableFilterFields, VehicleTableFilterStatus } from '@modules/planning/children/by-date/types';

export const VEHICLE_TABLE_INITIAL_FILTERS: VehicleTableFilterFields = {
  mass: null,
  volume: null,
  pallets: null,
  TransportCompanyID: null,
  [VehicleTableFilterStatus.ProjectVehicle]: 1
};

export const VEHICLE_TABLE_FILTERS = [
  {
    id: 0,
    icon: 'more-vehicles',
    filterValue: VehicleTableFilterStatus.ProjectVehicle,
    title: VehicleTableFilterDesc.ProjectVehicle
  },
  {
    id: 1,
    icon: 'order-mass',
    filterValue: VehicleTableFilterStatus.mass,
    title: VehicleTableFilterDesc.mass
  },
  {
    id: 2,
    icon: 'order-volume',
    filterValue: VehicleTableFilterStatus.volume,
    title: VehicleTableFilterDesc.volume
  },
  {
    id: 3,
    icon: 'order-pallets',
    filterValue: VehicleTableFilterStatus.pallets,
    title: VehicleTableFilterDesc.pallets
  }
];
