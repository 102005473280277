import { IsTemplate, ScheduleType, SeparateVehicle, TenderLot } from '@modules/tender-platform/types';
import { Currency, VATValue } from '@common/constants/options';
import { EMPTY_STRING } from '@common/constants/general';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { parseDateFromServerForRangePicker, parseDateMultiply } from '@common/utils/helpers/date-time/parsers';
import { combinePhoneAndContactName } from '@modules/tender-platform/utils/helpers/combine-phone-and-contact-name';

export const lotToForm = (lotData?: TenderLot | null) => {
  const tenderLotPositions = createArrayFromIndexedArray(lotData?.TenderLotPosition)?.map((tenderLotPosition) => {
    return {
      ...tenderLotPosition,
      Mass: tenderLotPosition.Mass ? tenderLotPosition.Mass : null,
      Volume: tenderLotPosition.Volume ? tenderLotPosition.Volume : null,
      Width: tenderLotPosition.Width ? tenderLotPosition.Width : null,
      Height: tenderLotPosition.Height ? tenderLotPosition.Height : null,
      Length: tenderLotPosition.Length ? tenderLotPosition.Length : null
    };
  });
  const vehicleTypes = createArrayFromIndexedArray(lotData?.VehicleType);
  const values = {
    [TenderFormField.IsTemplate]: Boolean(IsTemplate.No),
    [TenderFormField.MinPrice]: lotData?.MinPrice ?? undefined,
    [TenderFormField.StartPrice]: lotData?.StartPrice ?? undefined,
    [TenderFormField.SeparateVehicle]: Boolean(lotData?.SeparateVehicle ?? SeparateVehicle.Yes),
    [TenderFormField.VAT]: lotData?.VAT ?? VATValue.Yes,
    [TenderFormField.VehicleLoadType]: lotData?.VehicleLoadType ?? undefined,
    [TenderFormField.Currency]: lotData?.Currency ?? Currency.RUB,
    [TenderFormField.SourceAddress]: lotData?.Source?.AddressDescription ?? lotData?.SourceAddress ?? EMPTY_STRING,
    [TenderFormField.SourceContact]: createArrayFromIndexedArray(lotData?.SourceContact),
    [TenderFormField.TargetAddress]: lotData?.Target?.AddressDescription ?? lotData?.TargetAddress ?? EMPTY_STRING,
    [TenderFormField.TargetContact]: createArrayFromIndexedArray(lotData?.TargetContact),
    [TenderFormField.TenderLotPosition]: tenderLotPositions,
    [TenderFormField.VehicleType]: vehicleTypes?.map(({ ID, Name, label, value }) => ({ value: value ?? ID, label: label ?? Name })),
    [TenderFormField.TenderLotUser]: lotData?.TenderLotUser ? combinePhoneAndContactName(lotData.TenderLotUser) : undefined,
    [TenderFormField.RegionalCityRecipient]: lotData?.RegionalCityRecipient ?? EMPTY_STRING,
    [TenderFormField.RegionalCitySender]: lotData?.RegionalCitySender ?? EMPTY_STRING,
    [TenderFormField.Schedule]: ScheduleType.Dates,
    [TenderFormField.Distance]: lotData?.Distance ?? EMPTY_STRING
  };

  if (lotData?.Schedule) {
    if (lotData.Schedule.weekdays) {
      values[ScheduleType.Weekdays] = lotData.Schedule.weekdays;
      values[TenderFormField.Schedule] = ScheduleType.Weekdays;
      values[TenderFormField.Phase] = parseDateFromServerForRangePicker(lotData?.Schedule?.from, lotData?.Schedule?.till);
    }
    if (lotData.Schedule.dates && Array.isArray(lotData.Schedule.dates)) {
      values[ScheduleType.Dates] = lotData?.Schedule?.dates?.map((date) => parseDateMultiply(date));
      values[TenderFormField.Schedule] = ScheduleType.Dates;
    }
    if (lotData.Schedule.interval) {
      values[ScheduleType.Interval] = lotData.Schedule.interval;
      values[TenderFormField.Schedule] = ScheduleType.Interval;
      values[TenderFormField.Phase] = parseDateFromServerForRangePicker(lotData?.Schedule?.from, lotData?.Schedule?.till);
    }
  }
  values[TenderFormField.TenderLotPosition] = tenderLotPositions;
  return values;
};
