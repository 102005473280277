import { DriverTableColumn, DriverUploadFileType } from '@common/types';

export const driver2Table = (drivers: DriverUploadFileType[], type: string) => {
  const { DriverTimelineStartTime, DriverTimelineWorkPerWeek, DriverTimelineWorkPerTwoWeek } = DriverTableColumn;
  return (
    drivers?.map((driver, index) => {
      return {
        ...driver,
        [DriverTimelineStartTime]: driver[DriverTimelineStartTime],
        [DriverTimelineWorkPerWeek]: driver[DriverTimelineWorkPerWeek] && Number(driver[DriverTimelineWorkPerWeek]),
        [DriverTimelineWorkPerTwoWeek]: driver[DriverTimelineWorkPerTwoWeek] && Number(driver[DriverTimelineWorkPerTwoWeek]),
        key: driver.DriverCode ?? type + index,
        Type: Number(driver.Type ?? 0),
        fileIndex: index
      };
    }) ?? []
  );
};
