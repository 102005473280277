import { FC, useEffect, useMemo, useState } from 'react';
import { formatDate, getFirstNLastDayOfMonth, timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { deepEqual } from 'fast-equals';
import { ILSButton, ILSTitle, ILSUserNotify } from '@components/index';
import { SECONDS_TO_MILLISECONDS_MULTIPLIER, TODAY } from '@common/constants/general';
import { DateFormat, Notify, NotifyDurationInSecond, Project } from '@common/types';
import { ILSChooseProjectContainer } from '@modules/planning/containers/choose-project';
import ILSEmulatorDateComponent from '../components/date';
import { emulatorGetProjectsRoutine } from '../actions';
import { activeProjectIdSelector, availableDatesSelector, isFetchingSelector, projectSelector, projectsSelector } from '../selectors';
import { useModal } from '@common/hooks/components/use-modal';
import { FormatType } from '@common/types/general/date-time';

export type IDateProps = {
  manager?: boolean; //legacy менеджер проектов не используется
  getPlans: (ID: number, setStatus?: boolean) => void;
  getPlanManager?: (ID: number, project?: any) => void;
};

// Контейнер для выбора даты проекта. Также отвечает за загрузку данных по активному плану.
const ILSEmulatorDate: FC<IDateProps> = ({ getPlans, getPlanManager, manager = false }) => {
  const dispatch = useAppDispatch();
  const { visible, handleShow } = useModal();
  const projects = useAppSelector(projectsSelector);
  const availableDates = useAppSelector(availableDatesSelector) ?? [];
  const activeProjectID = useAppSelector(activeProjectIdSelector);
  const isDateLoading = useAppSelector(isFetchingSelector);
  const byDateProject = useAppSelector(projectSelector);
  /**
   * Изначальная дата в календаре
   */
  let defaultDate = TODAY;

  /**
   * Если есть загруженный проект, то по умолчанию используется его дата
   */
  if (activeProjectID) {
    if (projects[activeProjectID]?.Start) {
      defaultDate = projects[activeProjectID].Start * SECONDS_TO_MILLISECONDS_MULTIPLIER;
    } else if (byDateProject?.ID === activeProjectID && byDateProject.Start) {
      defaultDate = byDateProject.Start * SECONDS_TO_MILLISECONDS_MULTIPLIER;
    }
  }

  // Оставлено для следующих задач
  // if (projectID) {
  //   if (projects[projectID]?.Start) {
  //     defaultDate = projects[projectID].Start * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  //   } else if (projectID && projectStart) {
  //     defaultDate = projectStart;
  //   }
  // }

  /**
   * дата проекта в календаре
   */
  const [defDate, setDefDate] = useState<number | Date | undefined>(defaultDate);
  /**
   * Предыдущая дата для эффектов загрузки проекта
   */
  const [prevDate, setPrevDate] = useState<number | Date | undefined>(undefined);
  const [currentDate, setCurrentDate] = useState<number | Date | undefined>(defaultDate);

  /**
   * Список проектов на выбранную дату
   */
  const [projectListToChoose, setProjectListToChoose] = useState<any>(null);

  /**
   * Флаг того что был отправлен запрос на загрузку данных по проекту и данные по планам не были еще получены
   */
  const [getFirstData, setGetFirstData] = useState<boolean>(true);

  /**
   * Границы дат по которым идет загрузка проектов
   * используется конец и начало месяца
   */
  const [curBoundDate, setCurBoundDate] = useState<[string, string] | undefined>(undefined);

  /**
   * Флаг того что был отправлен запрос на загрузку данных по проекту и список проектов установлен changeProjectList
   */
  const [awaitLoad, setAwaitLoad] = useState(false);

  /**
   * Массив проектов за выбранную дату
   */
  const [changeProjectList, setChangeProjectList] = useState<Array<Project>>([]);
  /**
   * флаг загружены ли при инициализации проекты на сегодняшнюю дату
   */
  const [gotToday, setGotToday] = useState<boolean>(false);

  /**
   * Callback на загрузку данных при смене месяца в календаре.
   * Сбрасывает существующий список.
   * Отправляет запрос на получение других проектов.
   * Обновляет дату и границы
   */
  const onMonthChange = (d: Date) => {
    const date = getFirstNLastDayOfMonth(d, true);
    setChangeProjectList([]);
    if (date) {
      const [from, till] = date;
      dispatch(emulatorGetProjectsRoutine({ from, till }));
      setCurrentDate(d);
      setCurBoundDate(date);
    }
  };

  /**
   * Callback на изменение даты в календаре
   * Сбрасывает существующий список
   * Обновляет дату и границы
   * Отправляет запрос на получение  проектов
   * После загрузки проекта загружает планы
   */
  const onDateSelect = (d: Date) => {
    const date = getFirstNLastDayOfMonth(d, true);
    setChangeProjectList([]);
    if (date && (!curBoundDate || !deepEqual(date, curBoundDate))) {
      const [from, till] = date;
      dispatch(emulatorGetProjectsRoutine({ from, till }));
      setCurBoundDate(date);
      setAwaitLoad(true);
    }
    if ('object' === typeof projects && Object.values(projects).length) {
      const dateProjects = Object.values(projects).filter((p) => {
        return timestampToDateTimeString(p.Start, FormatType.Date) === formatDate(d, DateFormat.DDMMYYYYPeriod) && p;
      });
      if (dateProjects?.length) {
        if (dateProjects.length > 1) {
          setProjectListToChoose(dateProjects);
        } else {
          if (manager && getPlanManager) {
            getPlanManager(dateProjects[0].ID);
          } else {
            if (dateProjects[0].PlanEnum && Object.keys(dateProjects[0].PlanEnum).length) {
              getPlans(Number(Object.keys(dateProjects[0].PlanEnum)[0]));
            }
          }
        }

        setGetFirstData(true);
        if (dateProjects.length >= 1) {
          setChangeProjectList(dateProjects as Project[]);
        } else {
          setChangeProjectList([]);
        }
        setAwaitLoad(false);
      } else {
        ILSUserNotify(Notify.Warning, 'В выбранной дате отсутствуют проекты', NotifyDurationInSecond.Two, 'planning');
        // dispatch(clearPlanManagerRoutine());
      }
      setCurrentDate(d);
    }
  };

  /**
   * обвновляет дату в календаре, после загрузки проекта и конфигурации плана
   */
  useEffect(() => {
    if (defDate !== defaultDate && defaultDate && currentDate === defDate) {
      setDefDate(defaultDate);
      setCurrentDate(defaultDate);
    }
  }, [activeProjectID, projects, byDateProject]);

  /**
   * При иниициализации загружается список проектов текущего месяца или месяца загруженного проекта
   */
  useEffect(() => {
    if (!curBoundDate && currentDate) {
      const date = getFirstNLastDayOfMonth(typeof currentDate === 'number' ? new Date(currentDate) : currentDate, true);
      if (date) {
        const [from, till] = date;
        if (!isDateLoading) {
          dispatch(emulatorGetProjectsRoutine({ from, till }));
        }
        setCurBoundDate(date);
      }
    }
  }, []);

  /**
   * эффект
   * Если будут загружены проекты, то создается список проектов на выбранную дату
   * Если проект будет только один на текущую дату - автоматически запустится загрузка его планов
   * Если проектов несколько на текущую дату - то список запишется в стейт для использования модального окна
   */
  useEffect(() => {
    if (currentDate) {
      if ('object' === typeof projects && Object.values(projects).length) {
        const dateProjects = Object.values(projects).filter((p) => {
          return timestampToDateTimeString(p.Start, FormatType.Date) === formatDate(new Date(currentDate), DateFormat.DDMMYYYYPeriod) && p;
        });
        const isDefDateIsToday =
          new Date(currentDate).getFullYear() === new Date().getFullYear() &&
          new Date(currentDate).getMonth() === new Date().getMonth() &&
          new Date(currentDate).getDate() === new Date().getDate();

        if (
          'object' === typeof projects &&
          Object.values(projects).length &&
          (awaitLoad ||
            //загружаем сегодняшние проекты, если они есть
            (!gotToday && isDefDateIsToday && dateProjects.length))
        ) {
          if (dateProjects?.length) {
            if (dateProjects.length > 1) {
              if (!activeProjectID) {
                setProjectListToChoose(dateProjects);
              }
            } else {
              if (manager && getPlanManager) {
                getPlanManager(dateProjects[0].ID);
              } else {
                if (dateProjects[0].PlanEnum && Object.keys(dateProjects[0].PlanEnum).length) {
                  getPlans(Number(Object.keys(dateProjects[0].PlanEnum)[0]));
                }
              }
            }
            setGotToday(true);
            setAwaitLoad(false);
            setGetFirstData(true);
          } else {
            ILSUserNotify(Notify.Warning, 'В выбранной дате отсутствуют проекты', NotifyDurationInSecond.Two, 'planning');
            // dispatch(clearPlanManagerRoutine());
          }
        }
        if (dateProjects.length) {
          setChangeProjectList(dateProjects as Project[]);
        } else {
          setChangeProjectList([]);
        }
      }
    } else {
      const dateProjects = Object.values(projects).filter((p) => {
        return timestampToDateTimeString(p.Start, FormatType.Date) === formatDate(new Date(), DateFormat.DDMMYYYYPeriod) && p;
      });
      setChangeProjectList(dateProjects as Project[]);
    }
  }, [projects, currentDate]);

  /**
   * Если prevDate и currentDate отличается, то повторно отправляется запрос на загрузку проектов
   * TODO вообще нужно все это облегчить
   */
  useEffect(() => {
    if (getFirstData || prevDate !== currentDate) {
      const month = getFirstNLastDayOfMonth(new Date(currentDate || defDate || new Date()), true);

      if (month) {
        const [from, till] = month;
        if (!isDateLoading) {
          dispatch(emulatorGetProjectsRoutine({ from, till }));
        }
        setPrevDate(currentDate);
      }
      setGetFirstData(false);
    }
  }, [currentDate]);

  /**
   * Используется useMemo для остановки ререндера
   * NOTE того же можно было добиться, если использовать useCallback на функциях
   */
  const DatePicker = useMemo(
    () => (
      <div className="plan-date" key={defDate?.toString()}>
        <ILSTitle title="Выбор проекта:" />
        <ILSEmulatorDateComponent
          defaultDate={defDate}
          isDateLoading={isDateLoading}
          availableDates={availableDates}
          onDateSelect={onDateSelect}
          onMonthChange={onMonthChange}
        />
        {changeProjectList.length > 1 && (
          <ILSButton type="primary" onClick={() => setProjectListToChoose(changeProjectList)}>
            Выбрать проект
          </ILSButton>
        )}
      </div>
    ),
    [defDate, isDateLoading, availableDates, changeProjectList]
  );
  /**
   * Загружает первый план (принятый к исполнению) из указанного проекта
   */
  const getPlanFromProject = (ProjectID: number) => {
    if (projects?.[ProjectID]?.PlanEnum && Object.keys(projects?.[ProjectID]?.PlanEnum ?? {})[0]) {
      getPlans(Number(Object.keys(projects?.[ProjectID]?.PlanEnum ?? {})[0]));
    }
  };

  return (
    <>
      {DatePicker}
      {projectListToChoose?.length && (
        <ILSChooseProjectContainer
          projectList={projectListToChoose}
          getProject={getPlanFromProject}
          handleShow={handleShow}
          visible={visible}
        />
      )}
    </>
  );
};

export default ILSEmulatorDate;
