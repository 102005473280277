import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';
import { useAppSelector } from '@core/hooks';
import ILSAnalyticsListComponent from '@modules/analytics/common/components/list';
import { BOARDS_TITLE, DEFAULT_BOARD_SETS_TITLE } from '@modules/analytics/common/constants';
import { useContext } from 'react';

import { AnalyticsTenderContext } from '../context';
import { boardsSelector } from '../selectors/board';

const ILSBoardsTenderListContainer = () => {
  const boards = useAppSelector(boardsSelector);

  const { handleSelectBoard } = useContext(AnalyticsTenderContext) ?? {};

  return (
    <ILSAnalyticsListComponent
      classNames={['boards-list']}
      isFetching={boards.isFetching}
      data={boards.data}
      handleClick={(data) => handleSelectBoard(data as AnalyticsBoardModel)}
      selectedId={boards.selectedBoard}
      listTitle={BOARDS_TITLE}
      defaultListItemTitle={DEFAULT_BOARD_SETS_TITLE}
    />
  );
};

export default ILSBoardsTenderListContainer;
