import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { deletePreCostRoutine } from '@modules/orders-and-trips/actions';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';

export const deletePreCostReducer = createReducer(initialState, {
  [deletePreCostRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      tripID: number;
      alias: TenderOutFastDeliveryServices;
    }>
  ) => {
    const { tripID, alias } = action.payload;
    if (state.availableIntegrations.preCost?.[tripID]?.services) {
      state.availableIntegrations.preCost[tripID].services = state.availableIntegrations.preCost[tripID].services.filter(
        ({ name }) => name !== alias
      );
    }
    return state;
  }
});
