import { isEmpty } from 'lodash';
import { DecoratedIntegrationServices, Integrations } from '@modules/orders-and-trips/types/integrations';
import { TRANSPORT_COMPANY_IMG } from '@modules/orders-and-trips/constants/img';

export const integrationServicesToArray = (data: Integrations) => {
  if (isEmpty(data) || !data) return null;

  return Object.keys(data).reduce((result, integrationTypeKey) => {
    result[integrationTypeKey] = [];
    const integrationTypeServices = data[integrationTypeKey].services;
    if (!isEmpty(integrationTypeServices)) {
      Object.keys(integrationTypeServices).forEach((serviceKey) => {
        const service = {
          id: serviceKey,
          name: integrationTypeServices[serviceKey].name,
          img: TRANSPORT_COMPANY_IMG?.[serviceKey] ?? null
        };
        result[integrationTypeKey].push(service);
      });
    }

    return result;
  }, {} as DecoratedIntegrationServices);
};
