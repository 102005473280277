import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { monitoringPlanFactRoutine } from '../actions';

type PlanFactRequest = {
  vehicleID: string;
  from: string;
  till: string;
};

// Загружает план/факт данные
function* monitoringPlanFactWorker(action: PayloadAction<PlanFactRequest>) {
  const { request, success, failure, fulfill } = monitoringPlanFactRoutine;
  const { vehicleID, from, till } = action.payload;

  try {
    yield put(request({ vehicleID, from, till }));

    const { data } = yield call(MonitoringAPI.getPlanFact, { vehicleID, from, till });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringPlanFactWatcher() {
  yield takeLatest(monitoringPlanFactRoutine.trigger, monitoringPlanFactWorker);
}
