import { ILSRangePicker } from '@common/components';
import { DateFormat } from '@common/types';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';

interface IProps {
  from?: string;
  till?: string;
  onChangeRange: (from?: string, till?: string) => void;
}

// Компонент для фильтра по диапазону дат
export const ILSDemandFilterRangeComponent: FC<IProps> = ({ from, till, onChangeRange }) => {
  const [date, setDate] = useState<[Moment | null, Moment | null]>([
    from ? moment(from, DateFormat.YYYYMMDDSlash) : null,
    till ? moment(till, DateFormat.YYYYMMDDSlash) : null
  ]);
  const onChange = (dates: [Moment | null, Moment | null] | null, _dateStrings: [string, string]) => {
    if (dates) {
      setDate(dates);
    } else {
      setDate([null, null]);
    }
  };
  useEffect(() => {
    if (date?.[0] && date?.[1]) {
      onChangeRange(date[0].format(DateFormat.YYYYMMDDDash), date[1].format(DateFormat.YYYYMMDDDash));
    } else {
      onChangeRange(undefined, undefined);
    }
  }, [date]);

  return <ILSRangePicker onChange={onChange} value={date} />;
};
