import { Dictionary, PlanStatus } from '@common/types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { RouteRecord, RoutesTableRecord } from '@modules/planning/children/by-date/types/record';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { stringifyUrl } from 'query-string';
import { useRouteActions } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/route/use-route-actions';
import { useVehicleActions } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/route/use-vehicle-actions';
import { useDriverActions } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/route/use-driver-actions';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';
import { useCreateTrip } from '@modules/planning/children/by-date/hooks/tables/routes/context-menu/route/use-create-trip';

export const getContextMenuForRoute = ({
  record,
  setTriggerMapFocus,
  handleDelete
}: {
  record: RouteRecord;
  tableEditRow: (table: Dictionary, id: number) => void;
  setTriggerMapFocus: SetTriggerMapFocus;
  handleDelete(record: RoutesTableRecord): void;
}) => {
  const projectID = useSelector(projectIDSelector);

  const link = stringifyUrl({
    url: '/pages/track',
    query: {
      dictionary: record.dictionary,
      project: projectID,
      plan: record?.PlanID,
      vehicle: record.VehicleID
    }
  });

  const { routesSubMenu, handleExchangeVehicle, handleUnionTrips } = useRouteActions(record);
  const { handleChangeVehicle, vehiclesSubMenu, onEditVehicle } = useVehicleActions(record);
  const { onChangeDriver, driversSubMenu } = useDriverActions(record);
  const { handleCreateTrip } = useCreateTrip(record);

  const isBeforeExecution = record.Status < PlanStatus.OnExecution;
  const hasRoutes = !isEmpty(routesSubMenu);
  const hasVehicles = !isEmpty(vehiclesSubMenu);
  const hasDriversForChange = !isEmpty(driversSubMenu);
  return [
    [
      {
        text: 'Перейти к объекту на карте',
        handler: (record: RoutesTableRecord) => setTriggerMapFocus([record])
      },
      {
        text: 'Открыть на отдельной странице',
        link
      }
    ],
    isBeforeExecution && [
      {
        text: 'Редактировать автомобиль',
        handler: onEditVehicle
      },
      hasDriversForChange && {
        text: 'Заменить водителя',
        handler: onChangeDriver,
        submenu: driversSubMenu
      },
      hasVehicles && {
        text: 'Заменить автомобиль на...',
        handler: handleChangeVehicle,
        submenu: vehiclesSubMenu
      },
      hasRoutes && {
        text: 'Объединить рейсы с...',
        handler: handleUnionTrips,
        submenu: routesSubMenu
      },
      hasRoutes && {
        text: 'Обменять автомобили с...',
        handler: handleExchangeVehicle,
        submenu: routesSubMenu
      }
    ],
    [
      {
        text: 'Добавить рейс',
        handler: handleCreateTrip
      },
      {
        text: 'Добавить рейс со стартом с конца предыдущего',
        handler: handleCreateTrip,
        param: true
      }
    ],
    [
      {
        text: 'Удалить маршрут',
        handler: handleDelete
      }
    ]
  ];
};
