import { FC } from 'react';
import { PlannerDragNDropDocumentUploadComponent } from '@modules/planning/children/data/components';
import { JoinChar } from '@common/constants';
import { FileType, UploadButtons } from '@modules/planning/children/data/constants/data';
import {
  PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_EXTENSIONS,
  PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_TYPES,
  PLANNER_ACHEME_EDITOR_UPLOAD_INI_FORMAT
} from '@modules/planning/children/scheme-editor/constants';
import { ILSSVG } from '@components/custom/icons/svg';
import { PlannerFunctions } from '@modules/planning/types/components';
import { useDispatch } from 'react-redux';
import { uploadSchemeIniRoutine } from '@modules/planning/children/scheme-editor/actions';

interface IProps {
  isFetching: boolean;
}

export const PlannerDragNDropSchemeUploadContainer: FC<IProps> = ({ isFetching }) => {
  const dispatch = useDispatch();

  const handleUpload: PlannerFunctions['handleUpload'] = ({ file }) => {
    dispatch(uploadSchemeIniRoutine({ file }));
    return false;
  };

  return (
    <PlannerDragNDropDocumentUploadComponent
      title={<ILSSVG className={'scheme-editor-intro-icon'} icon={FileType.Ini} />}
      handleUpload={handleUpload}
      defaultPermittedTypes={PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_TYPES}
      defaultFormats={PERMITTED_UPLOAD_PLANNER_SCHEME_EDITOR_FILE_EXTENSIONS}
      button={[UploadButtons.ini]}
      isFetching={isFetching}
      accept={PLANNER_ACHEME_EDITOR_UPLOAD_INI_FORMAT.join(JoinChar.Comma)}
    />
  );
};
