import { useEffect, useState } from 'react';
import { Avatar } from '@modules/account/types/user';

export const useAvatar = (avatar: Avatar = null) => {
  const [newAvatar, setNewAvatar] = useState<Avatar>(avatar);
  const onAvatarChange = (e: Avatar) => setNewAvatar(e);

  useEffect(() => {
    if (avatar) setNewAvatar(avatar);
  }, [avatar]);

  return { newAvatar, onAvatarChange };
};
