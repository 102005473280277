import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI, Keys, ShowDeleted } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogDeleteRoutine } from '@modules/catalog/actions';
import { ForceDeleteType, ICatalogDataItem, SaveDictionaryType, ValuesType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';
import { compareAsString } from '@common/utils';
import { EMPTY_STRING } from '@common/constants';

const deleteDictionaryReducer = createReducer(initialState, {
  [catalogDeleteRoutine.REQUEST]: onRequest,
  [catalogDeleteRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      ids: Keys;
      values: ValuesType;
      forceDelete: ForceDeleteType;
      saveDictionary: SaveDictionaryType;
      showDeleted: ShowDeleted;
    }>
  ) => {
    let { dictionary, ids, saveDictionary, forceDelete, showDeleted } = action.payload;

    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;

    let oldData: ICatalogDataItem[] = state[dictionary].data;
    const [firstDeletedID] = ids;
    const firstDeletedIDIndex = oldData.findIndex((item) => compareAsString(item.ID, firstDeletedID));

    if (ids.length > 0) {
      if (saveDictionary) {
        //для удаления записи из родительского справочника
        oldData = oldData.map((item: ICatalogDataItem) => {
          if (item.ID === saveDictionary.recordID) {
            if (showDeleted) {
              item[saveDictionary.childDict] = item[saveDictionary.childDict].map((childItem) => {
                if (ids.includes(childItem.ID)) {
                  childItem['Deleted'] = Math.floor(Date.now() / 1000);
                }
                return childItem;
              });
            } else {
              item[saveDictionary.childDict] = item[saveDictionary.childDict].filter((childItem) => !ids.includes(childItem.ID));
            }
          }
          return item;
        });
      } else {
        if (showDeleted) {
          oldData = oldData.map((item: ICatalogDataItem) => {
            if (ids.includes(item.ID)) {
              item['Deleted'] = Math.floor(Date.now() / 1000);
            }
            return item;
          });
          state[dictionary].focusRow = {
            key: firstDeletedID
          };
        } else {
          oldData = oldData.filter((item: ICatalogDataItem) => !ids.includes(item.ID) && item);
          const prevElement = oldData[firstDeletedIDIndex - 1] ?? oldData[0] ?? EMPTY_STRING;
          state[dictionary].focusRow = { key: prevElement.ID };
        }
      }
    }
    state[dictionary].data = oldData;

    if (forceDelete) state[dictionary].refresh = true;

    return state;
  },
  [catalogDeleteRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogDeleteRoutine.FULFILL]: onFulfill
});

export default deleteDictionaryReducer;
