import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-own/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { approveReplicateDocumentRoutine } from '@modules/tender-platform/actions';

export const approveReplicateDocumentReducer = createReducer(initialState, {
  [approveReplicateDocumentRoutine.REQUEST]: onRequest,
  [approveReplicateDocumentRoutine.FAILURE]: onFailure,
  [approveReplicateDocumentRoutine.FULFILL]: onFulfill,
  [approveReplicateDocumentRoutine.SUCCESS]: (state, action: PayloadAction<{ replicateDocument: boolean }>) => {
    const { replicateDocument } = action.payload;
    state.replicateDocument = replicateDocument;
    return state;
  }
});
