import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TableRecord } from '@common/types';
import { ILSTooltip } from '@common/components/data-display/tooltip';
import { ILSDropdown, ILSMenu } from '@common/components';
import { preventDefaultStopPropagationForHandler } from '@common/utils/helpers/prevent-default-stop-propagation-for-handler';
import { getGapWidthInPixels } from '@common/utils/helpers/styles/styles';
import { useAppSelector } from '@core/hooks';
import { forwarderPointStatusesSubMenuSelector } from '@modules/monitoring/children/dashboard/selectors/dashboard';
import { ILSMonitoringWaypointTooltip } from '@modules/monitoring/children/dashboard/utils/tooltips';
import { getCellLineSeriesDeliveryBackground } from '@common/components/data-display/table/utils/helpers/cells/get-cell-line-series-delivery-background';
import { MonitoringStatusType } from '@modules/settings/types/forwarder-point-statuses';
import { IILSCellLineSeriesProps } from '@common/types/components/table/cell';
import { isArray, isNil } from 'lodash';

export const DEFAULT_LINESERIES_PROPS = {
  size: 30,
  gap: 8
};
export type ValueItemType = {
  waypointID: number;
  clientName: string;
  clientAddress: string;
  status: string;
  statusType?: MonitoringStatusType | null;
  lastStatus: string;
  lastStatusDT: string;
  startTime: string;
  factTime: string;
  onClick?: ((rec: TableRecord) => void) | undefined;
  Number: string;
};
export type ValueItemRecordType = ValueItemType & {
  ID: number;
};

export const ILSCellLineSeries = <R extends TableRecord>({
  children,
  columnKey,
  record,
  lineSeriesProps
}: IILSCellLineSeriesProps<R>): JSX.Element => {
  const dispatch = useDispatch();
  const { menu, onMenuItemClick } = useAppSelector(forwarderPointStatusesSubMenuSelector({ dispatch }));

  const value = isNil(children[1]) || !isArray(children[1]) ? [] : children[1];

  return (
    <div
      className={classNames('ils-delivery-status-info', columnKey)}
      style={getGapWidthInPixels(lineSeriesProps?.gap || DEFAULT_LINESERIES_PROPS.gap)}
    >
      {createStatusesArray(value).map((item, index) => (
        <ILSTooltip
          placement="top"
          overlayClassName="ils-delivery-status-tooltip"
          key={index}
          destroyTooltipOnHide
          title={
            <ILSMonitoringWaypointTooltip
              clientName={item.clientName}
              clientAddress={item.clientAddress}
              startTime={item.startTime}
              factTime={item.factTime}
              lastStatus={item.lastStatus}
              lastStatusDT={item.lastStatusDT}
            />
          }
        >
          <ILSDropdown
            trigger={['contextMenu']}
            overlay={
              <ILSMenu
                onClick={onMenuItemClick}
                items={menu.map((p) => {
                  return {
                    ...p,
                    key: item.waypointID
                  };
                })}
              />
            }
          >
            <span
              onContextMenu={preventDefaultStopPropagationForHandler}
              onClick={() => item?.onClick?.({ ...item, ...record })}
              key={index}
              className="ils-delivery-status-square"
              style={{
                background: getCellLineSeriesDeliveryBackground({ status: item.status, statusType: item.statusType }),
                width: (lineSeriesProps?.size || DEFAULT_LINESERIES_PROPS.size) + 'px',
                height: (lineSeriesProps?.size || DEFAULT_LINESERIES_PROPS.size) + 'px'
              }}
            >
              {item.Number}
            </span>
          </ILSDropdown>
        </ILSTooltip>
      ))}
    </div>
  );
};

const createStatusesArray = (items: Array<ValueItemRecordType>) =>
  items?.map((item: ValueItemRecordType) => {
    return {
      onClick: item?.onClick,
      Number: item.Number,
      waypointID: item.ID,
      status: item.status,
      statusType: item?.statusType || null,
      clientName: item?.clientName || '',
      clientAddress: item?.clientAddress || '',
      startTime: item?.startTime || '',
      factTime: item?.factTime || '',
      lastStatusDT: item?.lastStatusDT || '',
      lastStatus: item?.lastStatus || ''
    };
  }) || ([] as Array<ValueItemType>);
