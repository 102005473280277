import { DateFormat, IndexedArray } from '@common/types';
import { MonitoringOnTimeDataModel, MonitoringTripWpInfoDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { ILSChartTable } from '@modules/monitoring/components/chart/monitoring-chart-table';
import moment from 'moment';
import { FC } from 'react';

import { ContextMenuItem } from '../../types/context-menu';
import { UTC_OFFSET_DEFAULT } from './configs/chart';
import { monitoringChartDecorator } from './utils/table-chart-decorator';

interface IProps {
  dataSource: IndexedArray<MonitoringOnTimeDataModel>;
  dataSourceAll: IndexedArray<MonitoringTripWpInfoDataModel>;
  title: string;
  tillDate: string;
  contextMenuList: ContextMenuItem[];
}

// Компонент диаграммы доставки

//@todo график будет общим компонентом,
// а здесь будут передаваться данные для его отображения и настройки
export const ILSMonitoringDashboardChart: FC<IProps> = ({ title, dataSource, dataSourceAll, tillDate, contextMenuList }) => {
  const chartData = monitoringChartDecorator(dataSource, dataSourceAll, tillDate);
  const currentDate = moment().utc(false).utcOffset(UTC_OFFSET_DEFAULT).format(DateFormat.YYYYMMDDDash);

  return <ILSChartTable chartData={chartData} useCurrentTime={currentDate === tillDate} contextMenuList={contextMenuList} />;
};
