import { createReducer } from '@reduxjs/toolkit';
import {
  createConfigRoutine,
  deleteConfigRoutine,
  getConfigsRoutine,
  getConfigTemplateRoutine,
  importConfigRoutine,
  updateConfigRoutine
} from '../actions';
import initialState from '../store';
import { IAction } from '@modules/planning/children/params/types/reducer';
import { IData } from '@modules/planning/children/params/types/store';
import { createLoadReducer } from '@core/reducers/load-module';
import reduceReducers from 'reduce-reducers';
import { PLANNING_PARAMS_MODULE_NAME } from '../constants';
import { updateExternalConfigReducer } from './update-external-params';
import { copyParamsConfigReducer } from '@modules/planning/children/params/reducer/copy-config';
import { getPlanningParamsReducer } from './get-params';
import { setPlanningParamsReducer } from './set-parms';

const planningParams = reduceReducers(
  initialState,
  createReducer(initialState, {
    //GET CONFIGS
    [getConfigsRoutine.REQUEST]: (state) => {
      return { ...state, isFetching: true };
    },
    [getConfigsRoutine.SUCCESS]: (state, action: IAction) => {
      const { data, activeConfig } = action.payload;
      state.data = data;
      state.activeConfig = activeConfig;
      state.isFetching = false;
      return state;
    },
    [getConfigsRoutine.FAILURE]: (state) => {
      state.isFetching = false;
      return state;
    },
    [getConfigsRoutine.FULFILL]: (state) => {
      return state;
    },
    //GET TEMPLATE
    [getConfigTemplateRoutine.REQUEST]: (state) => {
      state.template = null;
      state.conf = null;
      return state;
    },
    [getConfigTemplateRoutine.SUCCESS]: (state, action: IAction) => {
      const { template, conf } = action.payload;
      state.template = template;
      state.conf = conf;
      return state;
    },
    [getConfigTemplateRoutine.FAILURE]: (state) => {
      return state;
    },
    [getConfigTemplateRoutine.FULFILL]: (state) => {
      return { ...state, error: [] };
    },
    //CREATE
    [createConfigRoutine.REQUEST]: (state) => {
      return { ...state, isSaving: true };
    },
    [createConfigRoutine.SUCCESS]: (state, action: IAction) => {
      const { data, activeConfig } = action.payload;
      state.data = data;
      state.activeConfig = activeConfig;
      state.isSaving = false;
      return state;
    },
    [createConfigRoutine.FAILURE]: (state) => {
      state.isSaving = false;
      return state;
    },
    [createConfigRoutine.FULFILL]: (state) => {
      return state;
    },
    //UPDATE
    [updateConfigRoutine.REQUEST]: (state) => {
      return { ...state, isSaving: true };
    },
    [updateConfigRoutine.SUCCESS]: (state, action: IAction) => {
      const { values } = action.payload;
      let oldData = state.data!;
      const id = Object.keys(values).length && Object.keys(values)[0];
      const row = Object.values(values).length ? Object.values(values)[0] : null;
      if (row!.Def === 'ConfigRow') {
        oldData = oldData.map((o) => {
          if (o.id === id) o = { ...o, Name_: row!.Name, DefaultValue_: row!.Context };
          return o;
        });
      }
      state.data = oldData;
      state.isSaving = false;
      return state;
    },
    [updateConfigRoutine.FAILURE]: (state) => {
      state.isSaving = false;
      return state;
    },
    [updateConfigRoutine.FULFILL]: (state) => {
      return state;
    },
    //DELETE
    [deleteConfigRoutine.REQUEST]: (state) => {
      return { ...state, isSaving: true };
    },
    [deleteConfigRoutine.SUCCESS]: (state, action: IAction) => {
      const { ConfigID } = action.payload;
      state.data = state.data!.filter((d) => !ConfigID.includes(d.id.toString()) && d);
      state.isSaving = false;
      state.activeConfig = null;
      return state;
    },
    [deleteConfigRoutine.FAILURE]: (state) => {
      state.isSaving = false;
      return state;
    },
    [deleteConfigRoutine.FULFILL]: (state) => {
      return state;
    },
    //IMPORT
    [importConfigRoutine.REQUEST]: (state) => {
      return { ...state, isSaving: true };
    },
    [importConfigRoutine.SUCCESS]: (state, action: { payload: { data: IData[] } }) => {
      const { data } = action.payload;
      state.data = state.data ? [...state.data, ...data] : data;
      state.isSaving = false;
      return state;
    },
    [importConfigRoutine.FAILURE]: (state) => {
      state.isSaving = false;
      return state;
    },
    [importConfigRoutine.FULFILL]: (state) => {
      return state;
    }
  }),
  copyParamsConfigReducer,
  updateExternalConfigReducer,
  createLoadReducer(PLANNING_PARAMS_MODULE_NAME),
  getPlanningParamsReducer,
  setPlanningParamsReducer
);

export default planningParams;
