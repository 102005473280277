import { MapMarkerSelectType } from '@common/types';
import L from 'leaflet';
import { regexSelect } from './marker-selected-regexp';

export const checkIfMarkerIsSelected = (layer: L.Marker) => {
  const curIcon = (layer?.getIcon() as L.DivIcon).options.html;
  if (typeof curIcon === 'string') {
    const match = curIcon.match(regexSelect);
    if (match?.length && match[0].length + 1 > MapMarkerSelectType.Selected.length) {
      return true;
    }
  } else if (typeof curIcon === 'object') {
    return curIcon.classList.contains(MapMarkerSelectType.Selected);
  }
  return false;
};
