import { Chart, Coord, Interval, Tooltip } from 'bizcharts';
import { tooltipNameTranslator } from '../../utils/chart-tooltip';
import React, { FC, useMemo } from 'react';
import { BarChartProps } from '../../types/components/charts';
import { ILSSkeleton } from '@common/components';
import { AnalyticsChartNoData } from '@modules/analytics/common/charts/components/chart-no-data';
import { useForceUpdate } from '@modules/analytics/common/hooks/use-force-update';

type BarChartDataType = { label: string; data: string | number } & Record<string, any>;
const ILSAnalyticsBarChartComponent: FC<BarChartProps> = ({
  loading,
  axisConfig,
  data,
  height = 200,
  config,
  vertical,
  metrics = ['value'],
  name,
  children
}) => {
  //по другому почему-то график иногда отрисовывается неправильно, если принудительно не проводить ререндер
  useForceUpdate(loading);

  //проверка на уникальность label
  const uniqueLabelData = useMemo(() => {
    return data.reduce((acc, curr) => {
      const existing = acc.find((x: BarChartDataType) => x.label === curr.label);
      if (!existing) {
        acc.push(curr);
      } else {
        acc.push({ ...curr, label: `${curr.label || ''} код:${curr.data}` });
      }
      return acc;
    }, [] as Array<BarChartDataType>);
  }, [data]);

  const dataValues = useMemo(() => {
    return data.map((x) => x[metrics[0]]);
  }, [data, metrics]);

  const maxValue = useMemo(() => {
    return Math.max(...dataValues);
  }, [dataValues]);

  const minValue = useMemo(() => {
    return Math.min(...dataValues);
  }, [dataValues]);

  return (
    <div className="analytics-chart lines">
      {children}
      {!data?.length && !loading ? (
        <AnalyticsChartNoData name={name} hideBorder={!!children} />
      ) : (
        <ILSSkeleton loading={loading}>
          <div className="mb-2">{config?.title}</div>
          <div>
            <Chart
              height={height - 80}
              data={uniqueLabelData}
              padding="auto"
              autoFit
              scale={{
                [metrics[0]]: {
                  max: maxValue * 1.15 || undefined,
                  min: minValue && minValue < 0 ? minValue * 1.15 : 0,
                  nice: true,
                  formatter: (v: any) => {
                    return `${v}${axisConfig?.unit}`;
                  }
                }
              }}
            >
              <Tooltip shared customItems={tooltipNameTranslator} data-html2canvas-ignore />
              <Coord transpose={!vertical} />
              <Interval
                position={`label*${metrics[0]}`}
                size={height / 20}
                label={[
                  metrics[0],
                  (v) => {
                    return {
                      content: v && v > 0 ? `${v}${axisConfig?.unit}` : '',
                      style: {
                        fontSize: 12
                      }
                    };
                  }
                ]}
              />
            </Chart>
          </div>
        </ILSSkeleton>
      )}
    </div>
  );
};

export default ILSAnalyticsBarChartComponent;
