import { CellType, ColumnTable, FilterCellType, ShowDeleted, TableColumnName, TableConfig, TableRecord } from '@common/types';
import { BASE_FIELD_NAMES, WEEK_DAYS_OPTIONS } from '@common/constants/options';
import { NEW_SURVEILLANCE_ZONE, NEW_ZONE, ZONE_TYPE_OPTIONS } from '@common/models/zone/constants';
import { ID_COLUMN } from '@common/models/all/columns';
import { ALL_SPACE } from '@common/constants/regex';
import { JoinChar } from '@common/constants';

export const REGEXP_FOR_ZONE_NAMES_FORBIDDEN_KEYS = new RegExp(/[\*\+\-\/\(\)\s]/, 'giu');
export const REPLACER_FROM_SPACES_TO_UNDER_CORE = { from: ALL_SPACE, to: JoinChar.UnderCore };
export const ZONE_NAME_VALIDATION_PARAMETERS = {
  forbiddenKeys: REGEXP_FOR_ZONE_NAMES_FORBIDDEN_KEYS,
  replacer: REPLACER_FROM_SPACES_TO_UNDER_CORE
};
const columns: ColumnTable<TableRecord>[] = [
  ID_COLUMN,
  {
    title: 'Код',
    dataIndex: 'ZoneCode',
    key: 'ZoneCode',
    active: false,

    editable: true,
    sorting: 5,
    ellipsis: true,
    type: CellType.Input,
    filtering: FilterCellType.String,
    grouping: true
  },
  {
    title: 'Название',
    dataIndex: 'Name',
    key: 'Name',
    active: true,

    editable: true,
    ellipsis: true,
    validationParameters: ZONE_NAME_VALIDATION_PARAMETERS,
    type: CellType.Input,
    filtering: FilterCellType.String,
    sorting: 2,
    grouping: true
  },
  {
    title: 'Цвет',
    dataIndex: 'Color',
    key: 'Color',
    active: true,

    editable: true,
    ellipsis: true,
    sorting: 9,
    align: 'center',
    isFixedColumn: true,
    type: CellType.Color
  },
  {
    title: 'Описание',
    dataIndex: 'Description',
    key: 'Description',
    active: false,

    editable: true,
    ellipsis: true,
    sorting: 10,
    type: CellType.Input,
    filtering: FilterCellType.String,
    grouping: true
  },
  {
    title: 'Тариф',
    dataIndex: 'Tariff',
    key: 'Tariff',
    active: true,

    type: CellType.Float,
    filtering: FilterCellType.String,
    editable: true,
    ellipsis: true,
    sorting: 12,
    grouping: true
  },
  {
    title: 'Очередь погр/разг',
    dataIndex: 'Queue',
    key: 'Queue',
    active: false,

    editable: true,
    ellipsis: true,
    sorting: 14,
    type: CellType.Int,
    filtering: FilterCellType.String,
    validationParameters: {
      step: 1,
      min: 0
    },
    grouping: true
  },
  {
    title: 'График',
    dataIndex: 'WorkSchedule',
    key: 'WorkSchedule',
    active: false,

    editable: true,
    sorting: 15,
    type: CellType.SelectMultiple,
    fieldNames: BASE_FIELD_NAMES,
    needSortByLabelField: false,
    options: WEEK_DAYS_OPTIONS,
    grouping: true
  },
  {
    title: 'Границы зоны',
    dataIndex: TableColumnName.BorderHash,
    key: TableColumnName.BorderHash,
    type: CellType.Input,
    editable: true,
    active: false,
    ellipsis: true,
    grouping: true
  }
];

const FriendZoneColumn: ColumnTable<TableRecord> = {
  title: 'Дружественные зоны',
  dataIndex: 'FriendZoneID',
  key: 'FriendZoneID',
  active: true,
  editable: true,
  align: 'center',
  isFixedColumn: true,
  sorting: 13,
  ellipsis: true,
  type: CellType.SelectMultipleApi,
  grouping: true
};

const TypeZoneColumn: ColumnTable<TableRecord> = {
  title: 'Тип',
  dataIndex: 'Type',
  key: 'Type',
  active: true,
  required: false,

  align: 'center',
  isFixedColumn: true,
  editable: true,
  type: CellType.Select,
  options: ZONE_TYPE_OPTIONS,
  fieldNames: BASE_FIELD_NAMES,

  sorting: 1,
  grouping: true
};
const ClosedTypeZoneColumn = { ...TypeZoneColumn, active: false }; //нужно чтобы не работало авто очищение при создании записи

export const ZONE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: true,
  export: true,
  adding: NEW_ZONE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: columns.concat([FriendZoneColumn, TypeZoneColumn])
};
export const DELIVERY_ZONE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: true,
  export: true,
  adding: NEW_ZONE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: columns.concat([FriendZoneColumn, ClosedTypeZoneColumn])
};
export const SURVEILLANCE_ZONE_CONFIG: TableConfig = {
  deleting: true,
  selecting: true,
  copying: true,
  export: true,
  adding: NEW_SURVEILLANCE_ZONE,
  reloading: true,
  showDeleted: ShowDeleted.Yes,
  columns: columns.concat([ClosedTypeZoneColumn])
};
