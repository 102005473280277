import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusTenderOffer } from '@modules/tender-platform/children/tender-search/types';
import {
  tenderOfferAppointResponsibleRoutine,
  tenderOfferCreateRoutine,
  tenderOfferUpdateRoutine
} from '@modules/tender-platform/children/tender-search/actions';
import { Module } from '@common/types';
import { TenderPlatformOfferTable } from '@modules/tender-platform/children/tender-own/components/offer-table';
import { currentTenderLotSelector, currentTenderSelector } from '@modules/tender-platform/selectors';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import { useAbac } from 'react-abac';
import { TenderOfferFormValues } from '@modules/tender-platform/children/tender-search/types/offer';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { TENDER_OFFER_TABLE_CONFIG } from '@modules/tender-platform/utils/table-config/tender-offer';
import { ILSAuctionSummary } from '@modules/tender-platform/children/tender-own/components/auction-summary';
import { checkBeforeTenderOfferCompetition } from '@modules/tender-platform/utils/helpers/check-before-tender-offer-competition';
import { EDITABLE_OFFER_STATUSES, NOT_TENDER_CHANGE_STATUSES } from '@modules/tender-platform/children/tender-search/constants';
import { auctionSummarySelector } from '@modules/tender-platform/selectors/auctions';
import { TenderStatus } from '@modules/tender-platform/types';
import { currentTenderMyOfferSelector } from '@modules/tender-platform/selectors/current-tender';
import { ILSTenderOfferForm } from '../components/offer/form';
import { isEmpty } from 'lodash';
import { CardTab, CardTabTitle, NOT_EDITABLE_TENDERS } from '@modules/tender-platform/constants';
import { ILSTypographyTitle } from '@components/general/typography';
import { ILSDivider } from '@common/components';

export const ILSTenderOfferContainer: FC = () => {
  const tenderPlatformChildrenModule = useContext(ILSTenderModuleContext);
  const dispatch = useDispatch();
  const { userHasPermissions } = useAbac();
  const currentTender = useSelector(currentTenderSelector);
  const tenderLot = useSelector(currentTenderLotSelector);
  const tenderOffer = useSelector(currentTenderMyOfferSelector);
  const { hasAuctionSummary } = useSelector(auctionSummarySelector);
  const { Alias } = tenderOffer?.StatusConfig ?? {};
  const isEditableTender = !NOT_TENDER_CHANGE_STATUSES.includes(currentTender?.StatusConfig?.Alias as TenderStatus);
  const canChangeOffer = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE, Alias) && isEditableTender;
  const canSelectResponsible = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_OFFER_CHANGE_RESPONSIBLE, Alias);
  const isForm = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDET_SEARCH) || tenderPlatformChildrenModule === Module.TenderSearch;

  const onFinish = ({ TenderOffer: { price, UserID, VehicleID, DriverID } }: TenderOfferFormValues) => {
    if (tenderOffer?.ID && UserID !== tenderOffer?.UserID) {
      dispatch(
        tenderOfferAppointResponsibleRoutine({
          tenderOfferID: tenderOffer.ID,
          userID: UserID
        })
      );
      return;
    }
    const isSuccess = checkBeforeTenderOfferCompetition(
      { price, UserID, VehicleID, DriverID },
      tenderOffer,
      tenderLot?.MinPrice,
      tenderLot?.StartPrice
    );
    if (isSuccess) {
      const { ID } = currentTender;
      const payload = {
        tenderID: ID,
        tenderLotID: tenderLot?.ID,
        vehicleID: Array.isArray(VehicleID) ? VehicleID[1] : VehicleID,
        driverID: DriverID,
        price
      };

      if (!tenderOffer?.ID) dispatch(tenderOfferCreateRoutine(payload));

      if (tenderOffer?.ID && EDITABLE_OFFER_STATUSES.includes(Alias as StatusTenderOffer)) {
        dispatch(tenderOfferUpdateRoutine(payload));
      }
    }
  };

  if (isForm) {
    return <ILSTenderOfferForm canChangeOffer={canChangeOffer} canSelectResponsible={canSelectResponsible} onFinish={onFinish} />;
  }

  return (
    <>
      {(!isEmpty(tenderOffer) || NOT_EDITABLE_TENDERS.includes(Alias as TenderStatus)) && (
        <>
          <ILSDivider
            key={`${CardTab.Offers}divider`}
            orientation={'left'}
            children={<ILSTypographyTitle level={2} id={CardTab.Offers} children={CardTabTitle.Offers} />}
          />
          <TenderPlatformOfferTable config={TENDER_OFFER_TABLE_CONFIG} />
          {hasAuctionSummary && <ILSAuctionSummary />}
        </>
      )}
    </>
  );
};

