import { EMPTY_STRING } from '@common/constants';
import { TableColumnName } from '@common/types';

/**
 * Функция возвращает нужный enum вместо dataIndex, который заканчивается на ID;
 * EndDepotID => EndDepotEnum
 * @param dataIndex - название колонки;
 * @returns - enum, либо null;
 */

export const replaceIDToEnumInDataIndex = (dataIndex?: TableColumnName | string) => {
  if (dataIndex !== EMPTY_STRING && dataIndex?.endsWith('ID')) {
    return dataIndex.replace(/ID$/, 'Enum');
  }
  return null;
};

