import { ILSButtonTooltip } from '@components/index';
import { ILSCChosenVehicle } from '@modules/planning/children/by-date/components/result/table-head/action-buttons/choosed-vehicle';
import { ILSOrderDataSummary } from '@modules/planning/children/by-date/components/result/info-blocks/order-data-summary';
import { OrderData } from '@modules/planning/children/by-date/types';
import { useEffect, useState } from 'react';
import { orderConfig } from '@modules/planning/children/by-date/config/order';
import { GroupByTargetButton } from '@modules/planning/children/by-date/components/result/table-head/action-buttons/group-by-target-button';

type Props = {
  addOrderForVehicle: (existTrip: boolean) => void;
  handleShowVehicleTable: (val: boolean) => void;
  orderSummary: OrderData | null;
  showVehicleTable: boolean | undefined;
  props: any;
};

export const useOrderTableWidgetsNButtons = ({
  addOrderForVehicle,
  handleShowVehicleTable,
  orderSummary,
  showVehicleTable,
  props
}: Props) => {
  const [fullScreenTable, setFullScreen] = useState(false);

  useEffect(() => {
    if (fullScreenTable && !showVehicleTable) {
      handleShowVehicleTable(!fullScreenTable);
    }
    if (showVehicleTable) {
      setFullScreen(!showVehicleTable);
    }
  }, [fullScreenTable, showVehicleTable]);

  const tableWidgets = [
    { Component: GroupByTargetButton },
    { Component: ILSOrderDataSummary, props: { orderSummary } },
    {
      Component: ILSCChosenVehicle,
      props: { addOrderForVehicle, handleShowVehicleTable }
    }
  ];

  const settingsTableButtons = [
    {
      Component: ILSButtonTooltip,
      props: {
        icon: fullScreenTable ? 'small-screen' : 'full-screen',
        title: fullScreenTable ? 'Свернуть таблицу' : 'Развернуть таблицу',
        onClick: () => setFullScreen((prev) => !prev),
        className: 'ils-full-screen-button'
      }
    }
  ];

  const modifiedProps = fullScreenTable
    ? {
        ...props,
        overscanRowCount: 20,
        scroll: { x: 500, y: 'calc(100% - 160px)' },
        pagination: false,
        tableConfig: {
          ...orderConfig,
          configClassName: 'table-config-planner-order-100h'
        }
      }
    : props;

  return { tableWidgets, settingsTableButtons, fullScreenTable, modifiedProps };
};
