import { TableColumnName } from '@common/types';

export enum Weekday {
  Sunday = 'Воскресенье',
  Monday = 'Понедельник',
  Tuesday = 'Вторник',
  Wednesday = 'Среда',
  Thursday = 'Четверг',
  Friday = 'Пятница',
  Saturday = 'Суббота'
}

export enum Month {
  january = 'Январь',
  february = 'Февраль',
  march = 'Март',
  april = 'Апрель',
  may = 'Май',
  june = 'Июнь',
  july = 'Июль',
  august = 'Август',
  september = 'Сентябрь',
  october = 'Октябрь',
  november = 'Ноябрь',
  december = 'Декабрь'
}

export type CalendarEntity = typeof Weekday | typeof Month;

const getArrayOfLocale = (calendarEntity: CalendarEntity) => {
  return Object.values(calendarEntity).map((entity) => [entity, entity.slice(0, 3)]);
};

export const WEEKDAYS = getArrayOfLocale(Weekday);
export const MONTHS = getArrayOfLocale(Month);

export const WEEK_DAYS_OPTIONS = [
  { label: Weekday.Monday, value: '0' },
  { label: Weekday.Tuesday, value: '1' },
  { label: Weekday.Wednesday, value: '2' },
  { label: Weekday.Thursday, value: '3' },
  { label: Weekday.Friday, value: '4' },
  { label: Weekday.Saturday, value: '5' },
  { label: Weekday.Sunday, value: '6' }
];

export enum VATLabel {
  No = 'Без НДС',
  Yes = 'С НДС',
  Partial = 'с и без НДС'
}

export enum VATValue {
  No = 0,
  Yes = 1,
  Partial = 2
}
// export const VAT_OPTIONS = Object.keys(VATValue).reduce((acc: any[], value, index, arr) => {
//   if (!isNaN(value)) acc.push({ value, label: VATLabel[VATValue[value]] });
//   return acc;
// }, []);
export const VAT_OPTIONS = [
  { label: VATLabel.No, value: VATValue.No },
  { label: VATLabel.Yes, value: VATValue.Yes },
  { label: VATLabel.Partial, value: VATValue.Partial }
];

export enum CompanyValue {
  New = 'new',
  Old = 'old'
}

export enum CompanyLabel {
  New = 'Новая компания',
  Old = 'Зарегистрированная компания'
}

export const COMPANY_OPTIONS = [
  { label: CompanyLabel.New, value: CompanyValue.New },
  { label: CompanyLabel.Old, value: CompanyValue.Old }
];

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR'
}

export enum CurrencySign {
  RUB = '₽',
  USD = '$',
  EUR = '€'
}

export const CURRENCY_OPTIONS = [
  { label: Currency.RUB, value: Currency.RUB },
  { label: Currency.USD, value: Currency.USD },
  { label: Currency.EUR, value: Currency.EUR }
];

export const CURRENCY_SIGN_OPTIONS = [
  { value: Currency.RUB, label: CurrencySign.RUB },
  { value: Currency.USD, label: CurrencySign.USD },
  { value: Currency.EUR, label: CurrencySign.EUR }
];

export const DEFAULT_FIELD_NAMES = { value: TableColumnName.ID as string, label: TableColumnName.Name as string };
export const BASE_FIELD_NAMES = { value: 'value', label: 'label' };
export const BASE_CASCADER_FIELD_NAMES = { ...BASE_FIELD_NAMES, children: 'children' };
