import { FC } from 'react';
import { ILSFormItem, ILSSelect } from '@common/components';
import { TenderOfferFormItem, TenderOfferFormItemLabel } from '@modules/tender-platform/children/tender-search/constants/offer';
import { useSelector } from 'react-redux';
import { tenderOfferFormSelector } from '@modules/tender-platform/children/tender-search/selectors';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';

interface IProps {
  disabled: boolean;
}

export const TenderPlatformSearchUserField: FC<IProps> = ({ disabled }) => {
  const { userOptions } = useSelector(tenderOfferFormSelector);

  return (
    <ILSFormItem label={TenderOfferFormItemLabel.UserID} name={[TenderOfferFormItem.TenderOffer, TenderOfferFormItem.UserID]}>
      <ILSSelect fieldNames={DEFAULT_FIELD_NAMES} showArrow={!disabled} disabled={disabled} options={userOptions} />
    </ILSFormItem>
  );
};
