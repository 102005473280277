import { WritableDraft } from 'immer/dist/internal';
import { PointWithData } from './types';
import { isEmpty } from 'lodash';

export const getTrackState = (data: { [key: string]: PointWithData }, trackData: { [key: string]: PointWithData }) => {
  if (!isEmpty(data)) {
    Object.keys(data).forEach((dataKey) => {
      const dataItem = data[dataKey];
      const trackKey = Object.keys(trackData)[dataKey];
      if (trackKey) {
        if (typeof dataItem === 'string') {
          if (trackData[trackKey]) {
            trackData[trackKey].track = dataItem;
          }
        }
        if (typeof dataItem === 'object' && dataItem.Key) {
          if (trackData[trackKey]) {
            trackData[trackKey] = {  ...trackData[trackKey], Key: dataItem.Key };
          }
        }
      }
    });
  }
  return trackData;
};

export const getTrackStateWithSaveState = (
  data: { [key: string]: any },
  trackData: { [key: string]: any },
  stateTrackData: WritableDraft<{
    [K: string]: PointWithData;
  }>
) => {
  if (!isEmpty(data)) {
    Object.keys(data).forEach((dataKey) => {
      const dataItem = data[dataKey];
      const trackKey = Object.keys(trackData)[dataKey];
      if (typeof dataItem === 'string') {
        if (trackData[trackKey]) {
          trackData[trackKey].track = dataItem;
          stateTrackData[trackKey] = trackData[trackKey];
        }
      }
      if (typeof dataItem === 'object') {
        if (trackData[trackKey]) {
          trackData[trackKey] = { ...trackData[trackKey], ...dataItem };
          stateTrackData[trackKey] = trackData[trackKey];
        } else {
          trackData[trackKey] = { ...dataItem };
          stateTrackData[trackKey] = trackData[trackKey];
        }
      }
    });
  }
  return stateTrackData;
};
