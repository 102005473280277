import { createSelector } from 'reselect';
import { VehicleTariffPart } from '@common/types';
import { isEmpty } from 'lodash';
import { VehicleTariffs } from '@common/types/dictionaries/vehicle';
import { ButtonType, ParserTariffParameters } from '@modules/catalog/types/tariff';
import { EMPTY_STRING } from '@common/constants';
import { tariffFunctionsList } from '@modules/catalog/constants/tariff';
import { CatalogState } from '@modules/catalog/types/store';
import { catalogStoreSelector } from '@modules/catalog/selectors/index';

export const VehicleTariffSelector = createSelector(
  (state: CatalogState) => catalogStoreSelector(state).VehicleTariff,
  (VehicleTariff) => VehicleTariff
);

export const tariffSelector = createSelector(VehicleTariffSelector, (VehicleTariff) => VehicleTariff.data as unknown as VehicleTariffs);

export const defaultTariffsSelector = createSelector(tariffSelector, (tariffs) =>
  isEmpty(tariffs)
    ? null
    : {
        ...tariffs[0],
        Formula: tariffs[0].TripPriceFormulaDraft ?? tariffs[0].TripPriceFormula,
        Calc: tariffs[0].TripPriceFormula
      }
);

export const tariffsParserSelector = createSelector(
  VehicleTariffSelector,
  (VehicleTariff) => VehicleTariff.parameters as ParserTariffParameters
);
export const tariffFetchSelector = createSelector(VehicleTariffSelector, (VehicleTariff) => VehicleTariff.isFetching);
export const tariffExecStatusSelector = createSelector(VehicleTariffSelector, (VehicleTariff) => VehicleTariff.execStatus);
export const tariffRefreshSelector = createSelector(VehicleTariffSelector, (VehicleTariff) => VehicleTariff.refresh);

export const VehicleTariffPartSelector = createSelector(
  (state: CatalogState) => state.Catalog.VehicleTariffPart,
  (VehicleTariffPart) => VehicleTariffPart
);

export const paramSelector = createSelector(
  VehicleTariffPartSelector,
  (VehicleTariffPart) => VehicleTariffPart.data as unknown as Array<VehicleTariffPart>
);
export const paramTabsSelector = createSelector(paramSelector, tariffsParserSelector, (params, tariffConstantsList) => {
  let buttons: Record<string, Array<ButtonType>> = {
    ['const']: [],
    ['func']: [],
    ['param']: []
  };
  for (let variable in tariffConstantsList) {
    buttons.const.push({
      variable,
      name: tariffConstantsList[variable].name,
      desc: tariffConstantsList[variable].desc,
      example: EMPTY_STRING
    });
  }
  for (let variable in tariffFunctionsList) {
    buttons.func.push({
      variable,
      name: tariffFunctionsList[variable].name,
      desc: tariffFunctionsList[variable].desc,
      example: tariffFunctionsList[variable].example ?? EMPTY_STRING
    });
  }
  for (let variable in params) {
    buttons.param.push({
      example: EMPTY_STRING,
      variable,
      name: params[variable].Name,
      desc: `Формула: ${params[variable].Formula}`,
      id: params[variable].ID
    });
  }
  return { buttons };
});
export const paramFetchSelector = createSelector(VehicleTariffPartSelector, (VehicleTariffPart) => VehicleTariffPart.isFetching);
export const paramExecStatusSelector = createSelector(VehicleTariffPartSelector, (VehicleTariffPart) => VehicleTariffPart.execStatus);
export const paramRefreshSelector = createSelector(VehicleTariffPartSelector, (VehicleTariffPart) => VehicleTariffPart.refresh);
