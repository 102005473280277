import { Key } from '@common/types';

export interface Tariff {
  ID: Key;
  Formula: any;
  Name: string;
  showFormula: any;
  modal: boolean;
  template: boolean;
}

export type ParserTariffParameters = Record<string, { name: string; desc: string; priority: number; unit: string }>;

export interface CheckFormulaParameters {
  name: string;
  formula: string;
  showFormula: string;
  modal: boolean;
}

export type ButtonType = {
  id?: Key;
  name: string;
  variable: string;
  example: string;
  desc: string;
};
export enum ActiveTabType {
  'func' = 'func',
  'param' = 'param',
  'const' = 'const'
}

export interface ITariffState {
  name: string;
  formula: any;
  parsedFormula: string;
}

export interface tariffFunction {
  name: string;
  desc: string;
  example: string;
  priority?: number;
}

export type tariffFunctions = Record<string, tariffFunction>;

export interface tariffMathFunction {
  key: string;
  func: string;
}

export interface tariffOperation {
  name: string;
  sign: string;
}
