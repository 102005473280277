import { SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { createSelector } from 'reselect';
import { EmulatorState, IEmulatorStore } from '../types/store';

export { emulationSpeedSelector, emulationConfigsSelector } from './config';

/** Возвращает ID активного проекта либо null */
export const activeProjectIdSelector = createSelector(
  (state: EmulatorState) => state.Emulator.activeProjectID,
  (activeProjectID: IEmulatorStore['activeProjectID']): number | null => (activeProjectID ? Number(activeProjectID) : null)
);

/** Возвращает массив дат с существующими проектами, в которых есть план принятый к исполнению */
export const availableDatesSelector = createSelector(
  (state: EmulatorState) => state.Emulator.availableDates,
  (availableDates: IEmulatorStore['availableDates']) => availableDates
);

/** Возвращает булево значение - производится ли сейчас загрузка данных */
export const isFetchingSelector = createSelector(
  (state: EmulatorState) => state.Emulator.isFetching,
  (isFetching: IEmulatorStore['isFetching']) => isFetching
);

/**  Возвращает массив проектов */
export const projectsSelector = createSelector(
  (state: EmulatorState) => state.Emulator.projects,
  (projects: IEmulatorStore['projects']) => projects
);

/**  Возвращает либо активный проект либо undefined */
export const projectSelector = createSelector(
  (state: EmulatorState) => (state.Emulator?.activeProjectID ? state.Emulator.projects?.[state.Emulator.activeProjectID] : undefined),
  (project) => project
);

/**  Возвращает данные по активному плану */
export const planSelector = createSelector(
  (state: EmulatorState) => state.Emulator.planData,
  (planData) => planData
);

/**  Возвращает данные по активному плану */
export const startEmulationTimeSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.startEmulationTime,
  (startEmulationTime) => startEmulationTime
);

/**  Возвращает таймстэмп окончания эмуляции */
export const endEmulationTimeSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.endEmulationTime,
  (endEmulationTime) => endEmulationTime
);

/**  Возвращает таймстэмп текущего момента эмуляции */
export const currentEmulationTimeSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.currentEmulationTime,
  (currentEmulationTime) => currentEmulationTime
);

/**  Возвращает дату и время начала эмуляции */
export const startEmulationDateTimeStringSelector = createSelector(
  (state: EmulatorState) =>
    state.Emulator.emulatorStatus.startEmulationTime
      ? timestampToDateTimeString(state.Emulator.emulatorStatus.startEmulationTime / SECONDS_TO_MILLISECONDS_MULTIPLIER)
      : undefined,
  (startEmulationDateTimeString) => startEmulationDateTimeString
);

/**  Возвращает дату и время окончания эмуляции */
export const endEmulationDateTimeStringSelector = createSelector(
  (state: EmulatorState) =>
    state.Emulator.emulatorStatus.endEmulationTime
      ? timestampToDateTimeString(state.Emulator.emulatorStatus.endEmulationTime / SECONDS_TO_MILLISECONDS_MULTIPLIER)
      : undefined,
  (endEmulationDateTimeString) => endEmulationDateTimeString
);

/**  Возвращает дату и время текущего момента эмуляции */
export const currentEmulationDateTimeStringSelector = createSelector(
  (state: EmulatorState) =>
    state.Emulator.emulatorStatus.currentEmulationTime
      ? timestampToDateTimeString(state.Emulator.emulatorStatus.currentEmulationTime / SECONDS_TO_MILLISECONDS_MULTIPLIER)
      : undefined,
  (currentEmulationDateTimeString) => currentEmulationDateTimeString
);

/**  Возвращает значение прогресса */
export const progressSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.progress,
  (progress) => progress
);

/**  Возвращает значение статуса */
export const statusSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.status,
  (status) => status
);

/**  Возвращает значение текущей команды эмулятора */
export const commandSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.command,
  (command) => command
);

/**  Возвращает текст ошибки */
export const errorTextSelector = createSelector(
  (state: EmulatorState) => state.Emulator.emulatorStatus.errorText,
  (errorText) => errorText
);

/**  Возвращает ID'шник используемого плана */
export const loadedPlanIDSelector = createSelector(
  (state: EmulatorState) => state.Emulator.planData.loadedPlanID,
  (loadedPlanID) => loadedPlanID
);

