import { Module, ModulePath } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { ILSPlanAnalytic } from './containers';
import planAnalyticReducer from './reducers';
import * as sagas from './sagas';

export default defineModule({
  title: Module.PlanAnalytic,
  path: ModulePath.PlanAnalytic,
  component: ILSPlanAnalytic,
  reducer: planAnalyticReducer,
  inScope: [ModuleScope.CustomForAuthPage],
  sagas: getAllModuleSagas(sagas)
});
