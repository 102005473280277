import { PlanningAPI } from '@modules/planning/api';
import { planningGetPlanRoutine } from '@modules/planning/children/by-date/actions';
import { call, cancel, fork, put, take, TakeEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { pull } from 'lodash';
import { Task } from 'redux-saga';
import { PlanResponse } from '@common/types/dictionaries/plan';
import { translateNotification } from '@modules/planning/children/by-date/decorators/notice/translate-notification';
import { translatePenalties } from '@modules/planning/children/by-date/decorators/penalties/translate-penalties';

function* planningGetPlanWorker(
  action: PayloadAction<{
    ID: number;
    hideSuccessMessage: boolean;
  }>,
  task: Task
) {
  const { request, success, failure, fulfill } = planningGetPlanRoutine;
  const { ID, hideSuccessMessage } = action.payload;

  try {
    yield put(request({ planID: ID, hideSuccessMessage }));
    const {
      data: { data }
    }: { data: { data: PlanResponse } } = yield call(PlanningAPI.getPlan, { ID });

    data.PlanNotice = data.PlanNotice.map((PlanNotice) => translateNotification(PlanNotice));
    data.PlanPenalty = data.PlanPenalty.map((PlanPenalty) => translatePenalties(PlanPenalty));

    const payload: {
      data: PlanResponse;
    } = {
      data
    };
    yield put(success(payload));
  } catch (error) {
    yield put(
      failure({
        error,
        ID
      })
    );
  } finally {
    yield put(fulfill({ planID: ID }));
    pull(planningGetPlanSagaTasks, task);
  }
}

/**
 * Массив задач по загрузке планов
 */
const planningGetPlanSagaTasks: Task[] = [];

export function* planningGetPlanWatcherAction() {
  // Это то же самое что и takeEvery только все задачи после триггера будут попадать в массив planningGetPlanSagaTasks
  while (true) {
    const action: TakeEffect = yield take(planningGetPlanRoutine.trigger);
    if (action) {
      const task: Task =
        //@ts-ignore TODO
        yield fork(planningGetPlanWorker, action, task);
      planningGetPlanSagaTasks.push(task);
    }
  }
}

/**
 NOTE саги типа Watcher, которые перечислены в индексе
 и используется в подключении модуля должны быть обернуты в fork (кроме takeLeading, takeLatest, takeEvery)
 для корректного отключения при выходе из модуля
 */
export function* planningGetPlanWatcher() {
  yield fork(planningGetPlanWatcherAction);
}

/**
 * Сага для очистки буффера задач и отмены выполнения саг по загрузке плана
 * можно вызывать при помощи yield call[|fork|spawn]
 */
export function* planningGetPlanCancelTasks() {
  if (planningGetPlanSagaTasks.length) {
    for (const task of planningGetPlanSagaTasks) {
      yield cancel(task);
    }
    planningGetPlanSagaTasks.length = 0;
  }
}
