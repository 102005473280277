import { RecordType } from '@modules/planning/children/scheme-editor/types';
import { INFO_KEY_TEXT, SCHEMA_KEY_SEPARATOR, SchemeKeyType } from '@modules/planning/children/scheme-editor/constants';

/**Функция проверяет является ли запись  инфо полем*/
export const isInfo = (item: RecordType): boolean => {
  return item.type === SchemeKeyType.Info || item.key?.toLowerCase().indexOf(`${SchemeKeyType.Info.toLowerCase()}@`) === 0;
};

/**Функция проверяет является ли запись  пустым полем*/
export const isEmpty = (item: RecordType): boolean => {
  return item.type === SchemeKeyType.Empty || item.key?.toLowerCase().indexOf(SchemeKeyType.Empty.toLowerCase()) === 0;
};

/**Функция выделяет из ключа инфо поля редактируемую часть после спец символа @*/
export const getInfoName = (key: string) => {
  return key.split(`@`)[1] || INFO_KEY_TEXT;
};

/**Функция возвращает новый ключ схемы, принимает текущий ключ поля  и редактируемую часть ключа.
 *@param currentKey текущий ключ
 *@param partKey измененная часть ключа
 * */
export const getNewSchemaKey = (currentKey: string, partKey: string) => {
  const splitOldKey = currentKey.split(SCHEMA_KEY_SEPARATOR);
  return splitOldKey.length > 1 ? `${splitOldKey[0]}${SCHEMA_KEY_SEPARATOR}${partKey}` : partKey;
};
