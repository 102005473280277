import { CatalogDescription, CatalogTitle, Dictionary, Zone, ZoneType } from '@common/types';
import { ICatalogItem } from '@modules/catalog/types/catalog';
import { ILSCatalogTariff } from '@modules/catalog/containers/tariff';
import { ILSCatalogVehicle } from '@modules/catalog/components/tables/vehicle';
import { ILSCatalogDepot } from '@modules/catalog/components/tables/depot';
import { CatalogDriverContainer } from '@modules/catalog/components/tables/driver';
import { ILSCatalogSurveillanceZone } from '@modules/catalog/components/tables/surveillance-zone';
import { ILSCatalogDeliveryZone } from '@modules/catalog/components/tables/delivery-zone';
import { ILSCatalogForbiddenZone } from '@modules/catalog/components/tables/forbidden-zone';
import { ILSCatalogSpeedProfile } from '@modules/catalog/components/tables/speed-profile';
import { ILSCatalogRestriction } from '@modules/catalog/components/tables/restriction';
import { ILSCatalogTripTemplate } from '@modules/catalog/components/tables/trip-template';
import { ILSCatalogCrossDock } from '@modules/catalog/components/tables/cross-dock';
import { ILSCatalogTransportCompany } from '@modules/catalog/components/tables/transport-company';
import { ILSCatalogWorktime } from '@modules/catalog/components/tables/worktime';
import { ILSCatalogZone } from '@modules/catalog/components/tables/zone';

export const DICTIONARIES_WITH_MAP = [Dictionary.Depot, Dictionary.Zone, Dictionary.ForbiddenZone, Dictionary.CrossDock];
export const DICTIONARIES_LOAD_BY_PROJECT = [Dictionary.Vehicle, Dictionary.Driver, Dictionary.Depot];
export const CATALOG_RELOAD_DICTIONARY = [Dictionary.Vehicle, Dictionary.Driver, Dictionary.Depot, Dictionary.Order];
export const CATALOG_SHOW_MAP_DICTIONARY = [Dictionary.Depot, Dictionary.CrossDock, Dictionary.Zone, Dictionary.ForbiddenZone];
export const CATALOG_SWAPED_DICTIONARY = [Dictionary.DeliveryZone, Dictionary.SurveillanceZone];

export const CATALOGS: Array<ICatalogItem> = [
  {
    id: 1,
    name: CatalogTitle.Order,
    component: ILSCatalogDepot,
    dictionary: Dictionary.Order,
    dontShow: true,
    desc: CatalogDescription.Order
  },
  {
    id: 2,
    name: CatalogTitle.Depot,
    component: ILSCatalogDepot,
    dictionary: Dictionary.Depot,
    map: true,
    desc: CatalogDescription.Depot
  },
  {
    id: 3,
    name: CatalogTitle.Vehicle,
    component: ILSCatalogVehicle,
    dictionary: Dictionary.Vehicle,
    desc: CatalogDescription.Vehicle
  },
  {
    id: 4,
    name: CatalogTitle.Driver,
    component: CatalogDriverContainer,
    dictionary: Dictionary.Driver,
    desc: CatalogDescription.Driver
  },
  {
    id: 5,
    name: CatalogTitle.Tariff,
    component: ILSCatalogTariff,
    desc: CatalogDescription.Tariff
  },
  {
    id: 6,
    name: CatalogTitle.SurveillanceZone,
    component: ILSCatalogSurveillanceZone,
    dictionary: Dictionary.Zone,
    map: true,
    desc: CatalogDescription.SurveillanceZone
  },
  {
    id: 7,
    name: CatalogTitle.DeliveryZone,
    component: ILSCatalogDeliveryZone,
    dictionary: Dictionary.Zone,
    map: true,
    desc: CatalogDescription.DeliveryZone
  },
  {
    id: 8,
    name: CatalogTitle.ForbiddenZone,
    component: ILSCatalogForbiddenZone,
    dictionary: Dictionary.ForbiddenZone,
    map: true,
    desc: CatalogDescription.ForbiddenZone
  },
  {
    id: 9,
    name: CatalogTitle.SpeedProfile,
    component: ILSCatalogSpeedProfile,
    dictionary: Dictionary.SpeedProfile,
    desc: CatalogDescription.SpeedProfile
  },
  {
    id: 10,
    name: CatalogTitle.Restriction,
    component: ILSCatalogRestriction,
    dictionary: Dictionary.Restriction,
    desc: CatalogDescription.Restrictions
  },
  {
    id: 11,
    name: CatalogTitle.TripTemplate,
    component: ILSCatalogTripTemplate,
    dictionary: Dictionary.TripTemplate,
    desc: CatalogDescription.TripTemplate
  },
  {
    id: 13,
    name: CatalogTitle.CrossDock,
    component: ILSCatalogCrossDock,
    dictionary: Dictionary.CrossDock,
    map: true,
    desc: CatalogDescription.CrossDock
  },
  {
    id: 14,
    name: CatalogTitle.TransportCompany,
    component: ILSCatalogTransportCompany,
    dictionary: Dictionary.TransportCompany,
    desc: CatalogDescription.TransportCompany
  }
];

export const SUB_CATALOGS: ICatalogItem[] = [
  {
    id: 12,
    name: CatalogTitle.Worktime,
    component: ILSCatalogWorktime,
    dictionary: Dictionary.Worktime
  },
  {
    id: 15,
    name: CatalogTitle.Zone,
    component: ILSCatalogZone,
    dictionary: Dictionary.Zone
  }
];

export const CATALOG_TABLE_NAMES: { [key: string]: { text: string } } = {
  Vehicle: { text: 'Автомобили' },
  Driver: { text: 'Водители' },
  Depot: { text: 'Склады' },
  Order: { text: 'Заявки' },
  Planning: { text: 'Планирование' },
  Cargo: { text: 'Грузы' },
  Zone: { text: 'Зоны' }
};
export enum CollectedDictionaryLayers {
  CrossDock = 3,
  ForbiddenZone = 4,
  Delivery = 6,
  Surveillance = 7
}
export type CollectLayerFromDepot = 1 | 2 | 5;

export type LayerData = {
  ID: CollectedDictionaryLayers | CollectLayerFromDepot;
  Name: string;
  active: boolean;
  data?: Array<any>;
  field: string;
  markerType?: string;
  type: string;
  dictionary?: Dictionary;
  entity?: Dictionary;
  filter?: (item: any) => boolean;
};
export const CATALOG_DEFAULT_LAYERS: Array<LayerData> = [
  {
    ID: 1,
    Name: 'Клиенты',
    active: false,
    data: [],
    field: 'LatLon',
    markerType: 'client',
    type: 'marker',
    dictionary: Dictionary.Depot
  },
  {
    ID: 2,
    Name: 'Склады',
    active: false,
    data: [],
    field: 'LatLon',
    markerType: 'storage',
    type: 'marker',
    dictionary: Dictionary.Depot
  },
  {
    ID: 3,
    Name: 'Зоны кросс-докинга',
    active: false,
    entity: Dictionary.CrossDock,
    field: 'BorderHash',
    type: 'polygon',
    dictionary: Dictionary.CrossDock
  },
  {
    ID: 4,
    Name: 'Зоны ограничения',
    active: false,
    entity: Dictionary.ForbiddenZone,
    field: 'Points',
    type: 'polygon',
    dictionary: Dictionary.ForbiddenZone
  },
  {
    ID: 5,
    Name: 'Точки сбора',
    active: false,
    data: [],
    field: 'LatLon',
    markerType: 'load',
    type: 'marker',
    dictionary: Dictionary.Depot
  },
  {
    ID: 6,
    Name: 'Зоны доставки',
    active: false,
    entity: Dictionary.DeliveryZone,
    field: 'BorderHash',
    type: 'polygon',
    dictionary: Dictionary.Zone,
    filter: (item: Zone) => Boolean(item.Type === ZoneType.Delivery)
  },
  {
    ID: 7,
    Name: 'Зоны наблюдения',
    active: false,
    entity: Dictionary.SurveillanceZone,
    field: 'BorderHash',
    type: 'polygon',
    dictionary: Dictionary.Zone,
    filter: (item: Zone) => Boolean(item.Type === ZoneType.Surveillance)
  }
];

export enum CATALOG_SWAPED_ORIGINAL_DICTIONARY {
  DeliveryZone = Dictionary.Zone,
  SurveillanceZone = Dictionary.Zone,
  PermitZone = Dictionary.ForbiddenZone
}

export enum BIND_TIMEZONE_ERROR {
  EmptyParams = 'Выбраны точки без координат'
}
