import { ILSInputRegNumber } from '@components/data-entry/input/input-reg-number';
import { IILSCellRegNumberProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isNull, isString } from 'lodash';
import { EMPTY_STRING } from '@common/constants';

export const ILSCellRegNumber = <R extends TableRecord>({ children, toggleEdit, save }: IILSCellRegNumberProps<R>): JSX.Element => {
  const [node, regNumber = EMPTY_STRING] = children;

  if (!isString(regNumber) && !isNull(regNumber)) {
    showUserAndDevError({ error: 'Номер автомобиля должен быть строкой' });
    return <></>;
  }

  return (
    <>
      {node}
      <ILSInputRegNumber
        className={'cell-wrapper-editable-full'}
        regNumber={regNumber ?? EMPTY_STRING}
        handleSave={save}
        toggleEdit={toggleEdit}
      />
    </>
  );
};
