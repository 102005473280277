import { FC } from 'react';
import { ILSButtonLink } from '@common/components';

interface IProps {
  tooltip?: boolean
  link: string
  text?: string
  icon?: string
  onClick?: () => void
}

const BackButton: FC<IProps> = ({
  tooltip = false,
  text='Назад',
  icon = '',
  onClick = () => null,
  ...props
}) => <ILSButtonLink tooltip={tooltip} text={text} icon={icon} onClick={onClick} {...props} />;

export default BackButton;
