import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import { ILSPageHeader } from './containers';

export default defineModule({
  title: 'Шапка',
  component: ILSPageHeader,
  useScope: [ModuleScope.HeaderLeft, ModuleScope.HeaderRight],
  inScope: [ModuleScope.Header]
});
