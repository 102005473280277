import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import ErrorPage from './error-page-container';

export default defineModule({
  title: 'Ошибка',
  path: '*',
  component: ErrorPage,
  useScope: [ModuleScope.Logo, ModuleScope.HeaderRight]
});
