import { Dictionary } from '@common/types';
import { compareAsString } from '@common/utils';
import { getAddressByCoordsYandex, getCoordsByAddressYandex } from '@core/utils/get-geocode';
import { GeocodeService } from '@modules/settings/types/planning';
import { isEmpty, omit } from 'lodash';
import { getAddressByCoordsDaData, getCoordsByAddressDaData } from './get-coords-dadata';

type UpdateDataItem = {
  ID: number;
  dictionary: Dictionary;
  LatLon?: string;
  AddressDescription?: string;
};

 
//TODO UNIT
/**
 * Проверить что пересборка идет корректно
 */
/**
 * Пересобирает данные по справочникам для запроса обновления
 */
export const collectDataByDictionary = (data: UpdateDataItem[]) => {
  if (isEmpty(data)) return [];
  return data.reduce(
    (colectedData: { data: Array<Omit<UpdateDataItem, 'dictionary'>>; dictionary: Dictionary }[], currentRecord: UpdateDataItem) => {
      const dictionaryData = colectedData.find((item) => compareAsString(item.dictionary, currentRecord.dictionary));
      if (dictionaryData) {
        dictionaryData.data.push(omit(currentRecord, 'dictionary'));
      } else {
        colectedData.push({ dictionary: currentRecord.dictionary, data: [omit(currentRecord, 'dictionary')] });
      }
      return colectedData;
    },
    []
  );
};

/**
 * Получает список функций которые нужно использовать для декодирования в зависимости от выбранного сервиса
 * (по умолчанию яндекс)
 */
export const getGeocodeFunction = ({ geocodeService }: { geocodeService?: GeocodeService }) => {
  if (geocodeService === GeocodeService.DaData) {
    return { getCoordsByAddress: getCoordsByAddressDaData, getAddressByCoords: getAddressByCoordsDaData };
  } else {
    return { getCoordsByAddress: getCoordsByAddressYandex, getAddressByCoords: getAddressByCoordsYandex };
  }
};

