import { TabType } from '../types/event-details-tabs';
import { MonitoringEventDetailsStore } from '../types/store';
import { MONITORING_EVENT_DETAILS_RESIZER } from '@modules/monitoring/children/event-details/constants/store';

//TODO рефакторинг
export const initialState: MonitoringEventDetailsStore = {
  resizer: MONITORING_EVENT_DETAILS_RESIZER,
  activeTab: TabType.Track, // Текущий активный таб
  VehicleEvents: {
    // События автомобиля
    data: {}, // Объект данных
    Plan: {}, // Данные по плану
    isFetching: false // Идёт ли загрузка данных
  },
  activeVehicleID: undefined, // ID выбранного автомобиля
  lastInfoRequest: {
    // Загрузка последней информации
    isFetching: false // Идёт ли загрузка данных
  },
  VehicleTracks: {
    // Маршруты автомобиля
    data: {}, // Объект с данными
    trackGeo: {}, // Гео информация трека
    isFetching: false // Идёт ли загрузка данных
  },
  VehicleSensor: {
    // Данные датчиков
    data: {}, // Объект с данными
    isFetching: false // Идёт ли загрузка данных
  },
  PointsStatusesList: {
    // Список статусов точек
    data: {}, // Объект с данными
    isFetching: false // Идёт ли загрузка данных
  }
};
