import { IModuleModel, IRouteModel } from '@core/types/models';
import React, { FC } from 'react';
import { Redirect } from 'react-router';
import { REACT_APP_REDIRECTS } from '@core/constants/env-variables';
import Modules from '@modules/index';

const ROOT_PATH = '/';

/**Функция принимает модуль и адрес переадресации в вовзрващает массив переадресации вида [[from, to]....]
 * Переадресует все дочерние модули на указанный путь*/
const getRedirectsForModule = (fromModule: IModuleModel, to?: string | null, parentPath: string = ''): Array<Array<string>> => {
  if (!fromModule || !to) return [];
  let results: Array<Array<string>> = [];
  const from = `${parentPath}${fromModule.path}`;
  if (from && to) {
    results.push([from, to]);
    if (fromModule.children && Object.keys(fromModule.children)?.length) {
      const childResults = Object.values(fromModule.children).reduce((acc: Array<Array<string>>, x) => {
        const childRes = getRedirectsForModule(x, to, from);
        acc = [...acc, ...childRes];
        return acc;
      }, []);
      results = [...results, ...childResults];
    }
  }

  return results;
};
/**Функиця возвращает массив переадресации вида [[from, to]....] на основе переменной REACT_APP_REDIRECTS*/
const getRedirects = (): Array<Array<string>> => {
  const redirectsEnv = process.env[REACT_APP_REDIRECTS];

  if (!redirectsEnv) return [];

  const redirectsArray = redirectsEnv.trim().split(',');

  if (!redirectsArray) return [];

  return redirectsArray.reduce((acc: Array<Array<string>>, x) => {
    const redirect = x.trim().split('_') || [];
    if (redirect.length !== 2) return acc;
    const fromModule = Modules[redirect[0].trim()] as IModuleModel;
    const to = (Modules[redirect[1].trim()] as IModuleModel).path;
    acc.push(...getRedirectsForModule(fromModule, to));
    return acc;
  }, []);
};

/** Функция возвращает компонент для переадресации на нужный адрес*/
const getRedirectComponent = (redirectTo?: string) => {
  return () => redirectTo && <Redirect to={redirectTo} />;
};

/**Функция проверяет есть ли корневой-дочерний маршрут. Если нет, но есть переадресации в настройках - то возвращает маршрут для переадресации*/
export const checkDefaultRootRedirect = (route: IRouteModel): IRouteModel | null => {
  const hasRootPath = route.routes?.some((x) => x.path === ROOT_PATH);
  const rootRedirect = getRedirects().find((redirect) => redirect[0] === ROOT_PATH);

  if (hasRootPath || !rootRedirect) return null;

  return {
    path: '/',
    exact: true,
    component: getRedirectComponent(rootRedirect[1]),
    permission: null
  };
};

/**Функция возвращает компонент для маршрута. Если нет переадресация - вернет просто компонент из модуля.
 * Если переадресации есть - вернет компонент переадресации*/
export const getRouteComponent = (module: IModuleModel, parentPath?: string | null): FC<any> | React.ReactNode => {
  const { component, path } = module;
  const fullPath = parentPath && parentPath !== '/' ? parentPath + path : path;
  let redirect: string | undefined = undefined;
  if (fullPath) {
    const redirectPath = getRedirects().find((redirect) => redirect[0] === fullPath);
    if (redirectPath && parentPath) {
      redirect = redirectPath[1];
    }
  }
  if (redirect) return getRedirectComponent(redirect);
  return component;
};
