import { EMPTY_STRING } from '@common/constants';
import { RULE_FOR_DATEPICKER_CURRENT_MOMENT } from '@modules/orders-and-trips/constants/form';
import { Rule } from 'rc-field-form/lib/interface';

export enum Placeholder {
  Email = 'mail@mail.ru',
  CompanyName = 'ООО Ромашка',
  Name = 'ФИО',
  Float = '0.000',
  Integer = '0',
  Phone = '+7 (000) 000 - 00 - 00',
  AddComment = 'Добавить комментарий',
  ChooseValue = 'Выберите значение',
  InputValue = 'Введите значение',
  Number = '1',
  ChooseWeekDays = 'Выберите дни недели',
  Address = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  FactAddress = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  YurAddress = 'Введите адрес (Город, улица, дом, почтовый индекс)',
  City = 'Введите город',
  KPP = 'Введите КПП',
  OrganizationTypeID = 'Выберите тип организации',
  Login = 'mail@mail.ru',
  UserRole = 'Роль пользователя',
  INN = 'ИНН компании',
  AccountRole = 'Роль компании для доступа к Тендерной площадке',
  Bank = 'Банк',
  BIK = 'БИК',
  BankAccount = 'Расчетный счет',
  CorrespondentAccount = 'Кор/с',
  VehicleProfileMass = 'Грузоподъемность',
  Source = 'Точка погрузки',
  Target = 'Точка разгрузки',
  LoadDate = 'Дата погрузки',
  LoadStart = 'Начало погрузки',
  LoadEnd = 'Окончание погрузки',
  UnloadDate = 'Дата разгрузки',
  UnloadStart = 'Начало разгрузки',
  UnloadEnd = 'Окончание разгрузки',
  Loading = 'Загрузка',
  GPSMonitoring = 'GPS - Мониторинг',
  GPSMonitoringDescription = 'Запросить у перевозчика GPS мониторинг груза',
  AmountVehicle = 'Количество машин',
  LoadCapacity = 'Грузоподъемность',
  Belts = 'Страховочные ремни',
  StartCollectOffer = 'Начало сбора предложений',
  EndCollectOffer = 'Окончание сбора предложений',
  StartContract = 'Начало исполнения договора',
  EndContract = 'Окончание исполнения договора',
  MinDriverRating = 'Минимальный рейтинг водителя',
  MinCarrierRating = 'Минимальный рейтинг перевозчика',
  MinDriverRoutes = 'Минимальное кол-во выполненных водителем рейсов',
  MaxWeight = 'Максимальный вес',
  Distance = 'Дистанция',
  SourcePoint = 'Откуда (направление)',
  TargetPoint = 'Куда (направление)',
  LoadersCount = 'Грузчики',
  HydroBoard = 'Гидроборт',
  Currency = 'Валюта',
  ID = 'ID',
  Type = 'Тип',
  Description = 'Описание',
  Date = 'Дата',
  SourceID = 'Точка погрузки',
  TargetID = 'Точка разгрузки',
  RestrictionID = 'Специфика',
  Mass = 'Масса',
  Volume = 'Объем',
  Amount = 'Количество',
  Length = 'Длина',
  Width = 'Ширина',
  Height = 'Высота',
  Comment = 'Комментарий',
  Cargo = 'Груз',
  ActionType = 'Действие с грузом',
  DepotID = 'Точка',
  LoadDateLocal = 'Время погрузки (локальное)',
  VehicleType = 'Выберите тип кузова автомобиля',
  VehicleUnloadType = 'Выберите тип выгрузки',
  VehicleLoadType = 'Выберите тип погрузки',
  TenderName = 'Введите название тендера',
  TenderType = 'Выберите тип тендера',
  TenderVisibility = 'Видимость тендера',
  TenderDescription = 'Введите краткое описание',
  Contact = 'Контактное лицо'
}

export enum FormItemMessage {
  Name = 'Пожалуйста, введите ФИО!',
  Phone = 'Пожалуйста, введите номер!',
  Login = 'Пожалуйста, введите email!',
  UserRole = 'Пожалуйста, выберете пользователя',
  City = 'Пожалуйста, введите город!'
}

export enum Field {
  User = 'User',
  VehicleProfile = 'VehicleProfile',
  Currency = 'Currency',
  ID = 'ID',
  Email = 'Email',
  Name = 'Name',
  Address = 'Address',
  City = 'City',
  Source = 'Source',
  Target = 'Target',
  Type = 'Type',
  Description = 'Description',
  Date = 'Date',
  SourceID = 'SourceID',
  TargetID = 'TargetID',
  RestrictionID = 'RestrictionID',
  Mass = 'Mass',
  Volume = 'Volume',
  Amount = 'Amount',
  Length = 'Length',
  Width = 'Width',
  Height = 'Height',
  Comment = 'Comment',
  LoadDate = 'LoadDate',
  LoadStart = 'LoadStart',
  LoadEnd = 'LoadEnd',
  UnloadDate = 'UnloadDate',
  UnloadStart = 'UnloadStart',
  UnloadEnd = 'UnloadEnd',
  VehicleType = 'VehicleType',
  VehicleUnloadType = 'VehicleUnloadType',
  VehicleLoadType = 'VehicleLoadType',
  Loading = 'Loading',
  GPSMonitoring = 'GPSMonitoring',
  AmountVehicle = 'AmountVehicle',
  LoadCapacity = 'LoadCapacity',
  Belts = 'Belts',
  TenderName = 'TenderName',
  TenderType = 'TenderType',
  TenderVisibility = 'TenderVisibility',
  TenderDescription = 'TenderDescription',
  StartCollectOffer = 'StartCollectOffer',
  EndCollectOffer = 'EndCollectOffer',
  StartContract = 'StartContract',
  EndContract = 'EndContract',
  MinDriverRating = 'MinDriverRating',
  MinCarrierRating = 'MinCarrierRating',
  MinDriverRoutes = 'MinDriverRoutes',
  MaxWeight = 'MaxWeight',
  Distance = 'Distance',
  SourcePoint = 'SourcePoint',
  TargetPoint = 'TargetPoint',
  Cargo = 'Cargo',
  ActionType = 'ActionType',
  DepotID = 'DepotID',
  Contact = 'Contact',
  Phone = 'Phone',
  LoadDateLocal = 'LoadDateLocal',
  LoadersCount = 'loadersCount',
  HydroBoard = 'hydroBoard',
  Login = 'Email',
  UserRole = 'UserRole',
  INN = 'INN',
  KPP = 'KPP',
  FactAddress = 'FactAddress',
  YurAddress = 'YurAddress',
  AccountRole = 'AccountRole',
  Bank = 'Bank',
  BIK = 'BIK',
  BankAccount = 'BankAccount',
  CorrespondentAccount = 'CorrespondentAccount',
  OrganizationTypeID = 'OrganizationTypeID',
  GPSMonitoringDescription = 'GPSMonitoringDescription'
}

export enum Label {
  Email = 'Почта',
  Name = 'Наименование организации',
  Phone = 'Телефон',
  Login = 'Логин',
  UserRole = 'Роль пользователя',
  INN = 'ИНН компании',
  KPP = 'КПП',
  City = 'Город',
  FactAddress = 'Почтовый адрес',
  YurAddress = 'Юридический адрес',
  AccountRole = 'Роль компании для доступа к Тендерной площадке',
  Bank = 'Банк',
  BIK = 'БИК',
  BankAccount = 'Расчетный счет',
  CorrespondentAccount = 'Кор/с',
  OrganizationTypeID = 'Организационно-правовая форма',
  HydroBoard = 'Гидроборт',
  LoadersCount = 'Грузчики',
  Currency = 'Валюта',
  ID = 'ID',
  Address = 'Адрес',
  Type = 'Тип',
  Description = 'Описание',
  Date = 'Дата',
  SourceID = 'Точка погрузки',
  TargetID = 'Точка разгрузки',
  RestrictionID = 'Специфика',
  Mass = 'Масса',
  Volume = 'Объем',
  Amount = 'Количество',
  Length = 'Длина',
  Width = 'Ширина',
  Height = 'Высота',
  Comment = 'Комментарий',
  Cargo = 'Груз',
  ActionType = 'Действие с грузом',
  DepotID = 'Точка',
  LoadDateLocal = 'Время погрузки (локальное)',
  VehicleProfileMass = 'Грузоподъемность',
  Source = 'Точка погрузки',
  Target = 'Точка разгрузки',
  LoadDate = 'Дата погрузки',
  LoadStart = 'Начало погрузки',
  LoadEnd = 'Окончание погрузки',
  UnloadDate = 'Дата разгрузки',
  UnloadStart = 'Начало разгрузки',
  UnloadEnd = 'Окончание разгрузки',
  VehicleType = 'Тип ТС',
  VehicleUnloadType = 'Выгрузка',
  VehicleLoadType = 'Погрузка',
  Loading = 'Загрузка',
  GPSMonitoring = 'GPS - Мониторинг',
  GPSMonitoringDescription = 'Запросить у перевозчика GPS мониторинг груза',
  AmountVehicle = 'Количество машин',
  LoadCapacity = 'Грузоподъемность',
  Belts = 'Страховочные ремни',
  TenderName = 'Имя тендера на платформе',
  TenderType = 'Тип тендера',
  TenderVisibility = 'Видимость тендера',
  TenderDescription = 'Краткое описание',
  StartCollectOffer = 'Начало сбора предложений',
  EndCollectOffer = 'Окончание сбора предложений',
  StartContract = 'Начало исполнения договора',
  EndContract = 'Окончание исполнения договора',
  MinDriverRating = 'Минимальный рейтинг водителя',
  MinCarrierRating = 'Минимальный рейтинг перевозчика',
  MinDriverRoutes = 'Минимальное кол-во выполненных водителем рейсов',
  MaxWeight = 'Максимальный вес',
  Distance = 'Дистанция',
  SourcePoint = 'Откуда (направление)',
  TargetPoint = 'Куда (направление)',
  Contact = 'Контактное лицо'
}

export enum RuleMessage {
  ChooseValue = 'Пожалуйста, выберите значение',
  InputValue = 'Пожалуйста, введите значение',
  Name = 'Пожалуйста, введите Имя',
  Phone = 'Пожалуйста, введите номер телефона',
  Email = 'Пожалуйста, введите электронную почту',
  Login = 'Пожалуйста, введите электронную почту',
  UserRole = 'Пожалуйста, выберите, Роль пользователя',
  AccountRole = 'Пожалуйста, выберите Роль компании для доступа к Тендерной площадке',
  INN = 'Пожалуйста, введите ИНН компании',
  KPP = 'Пожалуйста, введите КПП',
  City = 'Пожалуйста, введите Город',
  FactAddress = 'Пожалуйста, введите Почтовый адрес',
  YurAddress = 'Пожалуйста, введите Юридический адрес',
  Bank = 'Пожалуйста, введите Банк',
  BIK = 'Пожалуйста, введите БИК',
  BankAccount = 'Пожалуйста, введите Расчетный счет',
  CorrespondentAccount = 'Пожалуйста, введите Кор/с',
  OrganizationTypeID = 'Пожалуйста, введите Организационно-правовая форма',
  VehicleProfileMass = 'Пожалуйста, введите Грузоподъемность',
  Source = 'Пожалуйста, введите Точка погрузки',
  Target = 'Пожалуйста, введите Точка разгрузки',
  LoadDate = 'Пожалуйста, введите Дата погрузки',
  LoadStart = 'Пожалуйста, введите Начало погрузки',
  LoadEnd = 'Пожалуйста, введите Окончание погрузки',
  UnloadDate = 'Пожалуйста, введите Дата разгрузки',
  UnloadStart = 'Пожалуйста, введите Начало разгрузки',
  UnloadEnd = 'Пожалуйста, введите Окончание разгрузки',
  VehicleType = 'Пожалуйста, введите Тип ТС',
  VehicleUnloadType = 'Пожалуйста, введите Выгрузка',
  VehicleLoadType = 'Пожалуйста, введите Погрузка',
  Loading = 'Пожалуйста, введите Загрузка',
  GPSMonitoring = 'Пожалуйста, введите GPS - Мониторинг',
  GPSMonitoringDescription = 'Пожалуйста, введите Запросить у перевозчика GPS мониторинг груза',
  AmountVehicle = 'Пожалуйста, введите Количество машин',
  LoadCapacity = 'Пожалуйста, введите Грузоподъемность',
  Belts = 'Пожалуйста, введите Страховочные ремни',
  TenderName = 'Пожалуйста, введите Имя тендера на платформе',
  TenderType = 'Пожалуйста, введите Тип тендера',
  TenderVisibility = 'Пожалуйста, введите Видимость тендера',
  TenderDescription = 'Пожалуйста, введите Краткое описание',
  StartCollectOffer = 'Пожалуйста, введите Начало сбора предложений',
  EndCollectOffer = 'Пожалуйста, введите Окончание сбора предложений',
  StartContract = 'Пожалуйста, введите Начало исполнения договора',
  EndContract = 'Пожалуйста, введите Окончание исполнения договора',
  MinDriverRating = 'Пожалуйста, введите Минимальный рейтинг водителя',
  MinCarrierRating = 'Пожалуйста, введите Минимальный рейтинг перевозчика',
  MinDriverRoutes = 'Пожалуйста, введите Минимальное кол-во выполненных водителем рейсов',
  MaxWeight = 'Пожалуйста, введите Максимальный вес',
  Distance = 'Пожалуйста, введите Дистанция',
  SourcePoint = 'Пожалуйста, введите Откуда (направление)',
  TargetPoint = 'Пожалуйста, введите Куда (направление)',
  Contact = 'Пожалуйста, введите Контактное лицо',
  LoadersCount = 'Пожалуйста, введите Грузчики',
  HydroBoard = 'Пожалуйста, введите Гидроборт',
  Currency = 'Пожалуйста, введите Валюта',
  ID = 'Пожалуйста, введите ID',
  Address = 'Пожалуйста, введите Адрес',
  Type = 'Пожалуйста, введите Тип',
  Description = 'Пожалуйста, введите Описание',
  Date = 'Пожалуйста, введите Дата',
  SourceID = 'Пожалуйста, введите Точка погрузки',
  TargetID = 'Пожалуйста, введите Точка разгрузки',
  RestrictionID = 'Пожалуйста, введите Специфика',
  Mass = 'Пожалуйста, введите Масса',
  Volume = 'Пожалуйста, введите Объем',
  Amount = 'Пожалуйста, введите Количество',
  Length = 'Пожалуйста, введите Длина',
  Width = 'Пожалуйста, введите Ширина',
  Height = 'Пожалуйста, введите Высота',
  Comment = 'Пожалуйста, введите Комментарий',
  Cargo = 'Пожалуйста, введите Груз',
  ActionType = 'Пожалуйста, введите Действие с грузом',
  DepotID = 'Пожалуйста, введите Точка',
  LoadDateLocal = 'Пожалуйста, введите Время погрузки (локальное)'
}

export const FORM_RULES: Partial<Record<Field, Rule[]>> = {
  [Field.SourceID]: [{ required: true, message: 'Выберите точку погрузки' }],
  [Field.TargetID]: [{ required: true, message: 'Выберите точку разгрузки' }],
  [Field.RestrictionID]: [],
  [Field.Mass]: [{ required: true, message: 'Укажите массу груза' }],
  [Field.Volume]: [],
  [Field.Amount]: [{ required: true, message: 'Укажите количество' }],
  [Field.Length]: [{ required: true, message: 'Укажите длину' }],
  [Field.Width]: [{ required: true, message: 'Укажите ширину' }],
  [Field.Height]: [{ required: true, message: 'Укажите высоту' }],
  [Field.Comment]: [],
  [Field.LoadDate]: [{ required: true, message: 'Пожалуйста, выберите дату' }, RULE_FOR_DATEPICKER_CURRENT_MOMENT],
  [Field.LoadStart]: [],
  [Field.LoadEnd]: [],
  [Field.UnloadDate]: [{ required: false, message: 'Пожалуйста, выберите дату' }],
  [Field.UnloadStart]: [],
  [Field.UnloadEnd]: [],
  [Field.VehicleType]: [{ required: true, message: 'Укажите тип транспортного средства' }],
  [Field.VehicleUnloadType]: [{ required: true, message: 'Укажите тип разгрузки' }],
  [Field.VehicleLoadType]: [{ required: true, message: 'Укажите тип погрузки' }],
  [Field.Loading]: [],
  [Field.GPSMonitoring]: [],
  [Field.AmountVehicle]: [],
  [Field.LoadCapacity]: [],
  [Field.Belts]: [],
  [Field.Contact]: [{ required: true, message: 'Укажите контактное лицо' }]
};

// todo Placeholder[field] ?
export const getPlaceholder = (placeholder: Placeholder, isEmpty: boolean) => (isEmpty ? EMPTY_STRING : placeholder);
export const DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE = 20971520;
