import { isEmpty, isObject } from 'lodash';
import { PlanningParameters } from '@modules/settings/types/store';
import { PlanningSettingsKeys } from '@modules/settings/types';
import { IMapTrack } from '@common/types';

export const isNeedBounds = ({
  settings,
  coords,
  polyline
}: {
  settings?:
    | Pick<
        PlanningParameters,
        | PlanningSettingsKeys.UseRedis
        | PlanningSettingsKeys.HideLinesOnTrackAbsent
        | PlanningSettingsKeys.ActiveWidth
        | PlanningSettingsKeys.InactiveWidth
      >
    | undefined;
  coords: IMapTrack['coords'];
  polyline: IMapTrack;
}) => {
  return (
    settings &&
    (settings.useRedis || !settings.hideLinesOnTrackAbsent) &&
    isEmpty(coords) &&
    isObject(polyline?.nativeCoords) &&
    polyline.orderID
  );
};
