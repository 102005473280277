import CustomScroll from 'react-customscroll';
import { questionsAndAnswersData } from '@common/feature/questions/constants/questions-and-answers.json';

export const QuestionsAndAnswersList = () => {
  return (
    <div className="questions-scroll">
      <CustomScroll>
        <div className="questions-list">
          {questionsAndAnswersData.map((questionAndAnswer) => (
            <div className="question-item" key={questionAndAnswer.id}>
              <div className="question-item-question">
                <p>
                  <span>{`№${questionAndAnswer.id}. `}</span>
                  {questionAndAnswer.question}
                </p>
              </div>
              <div className="question-item-answer">
                <p>{questionAndAnswer.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </CustomScroll>
    </div>
  );
};

