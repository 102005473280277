import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { getCurrentSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';
import { ISchemeEditorFullState, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { SchemePayload } from '@modules/planning/children/scheme-editor/types/scheme';

export const getCurrentSchemeReducer = createReducer(INITIAL_STATE, {
  [getCurrentSchemeRoutine.REQUEST]: onRequest,
  [getCurrentSchemeRoutine.SUCCESS]: (state: ISchemeEditorFullState, action: PayloadAction<SchemePayload>) => {
    const { scheme } = action.payload;
    state.currentScheme = scheme;
    state.currentSchemeDictionaries = Object.keys(scheme) as Array<SchemeSwitchDictionary>;
    state.currentSchemeKeysInDictionaries = Object.entries(scheme).reduce((prev, cur) => {
      prev[cur[0]] = Object.keys(cur[1]);
      return prev;
    }, {});
    return state;
  },
  [getCurrentSchemeRoutine.FAILURE]: onFailure,
  [getCurrentSchemeRoutine.FULFILL]: onFulfill
});
