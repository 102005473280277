import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { tenderOfferGetDriverRoutine } from '@modules/tender-platform/actions';
import { TenderAPI } from '@modules/tender-platform/api';

function* tenderOfferGetDriverWorker(
  action: PayloadAction<{
    id: number | string;
  }>
) {
  const { request, success, failure, fulfill } = tenderOfferGetDriverRoutine;
  const { id } = action.payload;

  try {
    yield put(request());

    const {
      data: { data }
    } = yield call(TenderAPI.getDriversForTender, { tenderID: id });
    yield put(success({ drivers: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferGetDriverWatcher() {
  yield takeLatest(tenderOfferGetDriverRoutine.trigger, tenderOfferGetDriverWorker);
}
