import { Dispatch, FC, SetStateAction } from 'react';
import { ILSTabPane, ILSTabs } from '@common/components';
import { useDispatch, useSelector } from 'react-redux';
import { checkConflictRoutine } from '@modules/planning/children/data/actions';
import { UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { PlannerDragNDropDocumentUploadComponent } from '@modules/planning/components/drag-n-drop';
import {
  FileName,
  FileType,
  PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_EXTENSIONS,
  PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_TYPES,
  PERMITTED_UPLOAD_PLANNER_DATA_TEXT_EXTENSIONS,
  PERMITTED_UPLOAD_PLANNER_DATA_TEXT_TYPES,
  PLANNER_DATA_UPLOAD_EXCEL_FORMATS,
  PLANNER_DATA_UPLOAD_TEXT_FORMATS,
  UploadButtons
} from '@modules/planning/children/data/constants';
import { JoinChar } from '@common/constants';
import { PlannerDataFileDownload } from '@modules/planning/children/data/components';
import { schemeKeysSelector } from '@modules/planning/children/data/selectors';
import { Dictionary } from '@common/types';
import CustomScroll from 'react-customscroll';
import { ILSSVG } from '@components/custom/icons/svg';
import { PlannerFunctions } from '@modules/planning/types/components';

interface IProps {
  setFile: Dispatch<SetStateAction<File | null>>;
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
  isFetching: boolean;
}

export const PlannerDataTabsContainer: FC<IProps> = ({ setFile, setFileList, fileList, isFetching }) => {
  const dispatch = useDispatch();
  const inputs = useSelector(schemeKeysSelector);

  const handleUpload: PlannerFunctions['handleUpload'] = ({ file, type }) => {
    setFile(file);
    dispatch(checkConflictRoutine({ file, type }));
    return false;
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => setFileList(fileList);

  return (
    <ILSTabs className={'planning-data-upload-tabs'}>
      <ILSTabPane
        className={'planning-data-upload-tab'}
        tab={<ILSSVG className={'planner-upload-drag-n-drop-icon'} icon={FileName.Excel} />}
        key={FileName.Excel}
      >
        <PlannerDragNDropDocumentUploadComponent
          handleUpload={handleUpload}
          defaultPermittedTypes={PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_TYPES}
          defaultFormats={PERMITTED_UPLOAD_PLANNER_DATA_EXCEL_EXTENSIONS}
          button={[UploadButtons.xcl]}
          isFetching={isFetching}
          fileList={fileList}
          handleChange={handleChange}
          accept={PLANNER_DATA_UPLOAD_EXCEL_FORMATS.join(JoinChar.Comma)}
        />
        <PlannerDataFileDownload isFetching={isFetching} />
      </ILSTabPane>
      <ILSTabPane
        className={'planning-data-upload-tab'}
        tab={
          <div className={'planner-upload-drag-n-drop-icons'}>
            <ILSSVG className={'planner-upload-drag-n-drop-icon'} icon={FileName.Txt} />
            <ILSSVG className={'planner-upload-drag-n-drop-icon'} icon={FileName.Csv} />
          </div>
        }
        key={FileType.Txt}
      >
        <CustomScroll>
          {inputs.map((input) => (
            <PlannerDragNDropDocumentUploadComponent
              key={input}
              handleUpload={handleUpload}
              title={input as Dictionary}
              defaultPermittedTypes={PERMITTED_UPLOAD_PLANNER_DATA_TEXT_TYPES}
              defaultFormats={PERMITTED_UPLOAD_PLANNER_DATA_TEXT_EXTENSIONS}
              button={[UploadButtons.csv, UploadButtons.txt]}
              handleChange={handleChange}
              accept={PLANNER_DATA_UPLOAD_TEXT_FORMATS.join(JoinChar.Comma)}
              isFetching={isFetching}
            />
          ))}
        </CustomScroll>
      </ILSTabPane>
    </ILSTabs>
  );
};
