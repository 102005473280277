import { IndexedArray, OrderCargo } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const orderCargos2Table = (orderCargos: Array<OrderCargo> | IndexedArray<OrderCargo>) => {
  return createArrayFromIndexedArray(orderCargos).map((orderCargo) => {
    return {
      ...orderCargo,
      key: orderCargo.ID
    };
  });
};
