import { GetBoardSetRequest, GetBoardsRequest, GetBoardWidgetRequest, GetWidgetDataRequest } from '@common/types/dictionaries/analytics';
import { apiInstance } from '@core/api/instance';
import { IAnalyticsTenderAPI } from '../types/api';

const baseUrl = '/NewTms/AnalyticsTender/';

export const AnalyticsTenderAPI: IAnalyticsTenderAPI = {
  boardSetIndex: (params: GetBoardSetRequest) => apiInstance().post(baseUrl + 'boardSetIndex', params),
  boardIndex: (params: GetBoardsRequest) => apiInstance().post(baseUrl + 'boardIndex', params),
  getBoardWidget: (params: GetBoardWidgetRequest) => apiInstance().post(baseUrl + 'getBoardWidget', params),
  getWidgetData: (params: GetWidgetDataRequest) => apiInstance().post(baseUrl + 'getWidgetData', params)
};
