import { getSRCIconSVG } from '@common/decorators/path';
import { PlanNotice } from '@common/types';
import { doubleToTime } from '@common/utils';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';
import { countNoticesInPlanNotice } from '@modules/planning/children/by-date/helpers/plan/count-notices-in-plan-notice';
import { getCurrentNotifications } from '@modules/planning/children/by-date/decorators/notice/get-current-notifications';
import { PLAN_NOTICE_TYPE } from '@modules/planning/children/by-date/constants/params';
import { translateNotification } from '@modules/planning/children/by-date/decorators/notice/translate-notification';

const noticesToConvertToDate = [
  'DurationOverload',
  'DurationOvermax',
  'DurationRouteOverload',
  'Lateness',
  'LateOver',
  'Waiting',
  'WaitOver',
  'RampDurationOverWorktime',
  'DurationRouteOvermax'
];

export const getNotificationTips = ({
  ownNotifications = [],
  childNotifications = []
}: {
  ownNotifications?: PlanNotice[];
  childNotifications?: PlanNotice[];
}) => {
  const existChildNotifications = !isEmpty(childNotifications);
  const currentNotification = getCurrentNotifications({
    ownNotifications,
    childNotifications
  });
  const translatedCurrentNotification = currentNotification.map((notice) => translateNotification(notice));

  const { error: childErrors, warning: childWarnings } = countNoticesInPlanNotice(childNotifications);
  const resultNotifications = translatedCurrentNotification?.reduce((allNotifications, singleNotification) => {
    const jsx = (
      <div className="flex-start-center" key={singleNotification.ID}>
        <img
          src={getSRCIconSVG(PLAN_NOTICE_TYPE[singleNotification.NoticeType])}
          alt={singleNotification.NoticeType > 2 ? 'Иконка ошибки' : 'Иконка предупреждения'}
        />
        <span className="ml-2">
          <span dangerouslySetInnerHTML={{ __html: singleNotification.Name ?? singleNotification.StringKey }} />
          {!singleNotification.IsParsed && singleNotification.Value && (
            <span className="ml-2">
              {noticesToConvertToDate.some((x) => x === singleNotification.StringKey)
                ? doubleToTime(singleNotification.Value)
                : singleNotification.Value}
            </span>
          )}
        </span>
      </div>
    );

    if (!allNotifications[singleNotification.StringKey]) {
      allNotifications[singleNotification.StringKey] = [];
    }
    allNotifications[singleNotification.StringKey].push(jsx);

    return allNotifications;
  }, {} as Record<string, ReactElement[]>);

  return {
    notification: resultNotifications,
    existChildNotifications,
    childErrors,
    childWarnings
  };
};
