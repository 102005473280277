import { defineModule } from '@core/index';
import { DemandContainer } from './containers';

import { ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { persistTransform } from '@modules/demand/persist';
import demandRootReducer from './reducers';
import * as rootSagas from './sagas/';

/*
 * Модуль "Заявки и рейсы" - это "входная страница" всего проекта.
 * Содержит проекты и тендерные лоты.Есть табы для переключения отображений -
 * проекты и тендерные лоты вместе, только проекты, только тендерные лоты.
 * С этой страницы также осуществляется переход на создание проектов и тендерных лотов.
 * Есть фильтрация по Заказчикам, по временному интервалу, по транспортной компании,
 * транспортоному средству и водительским данным.
 * Основной файл - "входная точка" в модуль находится здесь - src\modules\demand\index.ts
 */

export default defineModule({
  title: ModuleTitle.Demand,
  path: ModulePath.Demand,
  component: DemandContainer,
  reducer: demandRootReducer,
  sagas: getAllModuleSagas(rootSagas),
  inScope: [ModuleScope.SiderNav],
  icon: '/assets/icons/sidebar/demand.svg',
  persist: persistTransform
});
