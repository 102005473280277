import { DragEvent, MouseEvent, useState } from 'react';
import { compareAsString } from '@common/utils';
import { HighlightedRow } from '@common/types/components/table/table';
import { getRowClass } from '@components/data-display/table/utils/helpers/row/get-row-class';
import { Key, TableRecord } from '@common/types';
import { UseTableRow } from '@common/types/components/table/hooks';
import { handleRowClick } from '@components/data-display/table/utils/helpers/row/handle-row-click';

export const useTableRow = <R extends TableRecord>({
  onTableRowClick,
  dragHandler,
  focusRowCustomHighlight,
  notHighlightFocusRow,
  active,
  onFocusRow,
  onTableRowDoubleClick,
  virtualTableRef,
  virtual,
  onContextOpen,
  dictionary,
  rowClass,
  selectedRowKeys,
  setFocusRow,
  tableName
}: UseTableRow<R>) => {
  const [highlightedRow, setHighlightedRow] = useState<HighlightedRow>({
    active: false,
    ID: undefined
  });

  const onRowClick = handleRowClick({ onTableRowClick, setFocusRow, onFocusRow, tableName });

  const onRow = (record: R) => {
    let onDragStart: ((e: DragEvent, record: R, key: string | number) => void) | undefined,
      onDrop: ((e: DragEvent, record: R, key: string | number) => void) | undefined,
      onDragEnd: ((e: DragEvent) => void) | undefined,
      draggable: boolean | undefined,
      onHover: ((e: MouseEvent, record: R, key: Key) => { ID: number | undefined }) | undefined;

    if (dragHandler !== undefined) {
      onDragStart = dragHandler.onDragStart(record);
      onDrop = dragHandler.onDrop(record);
      onDragEnd = dragHandler.onDragEnd(record);
      draggable = dragHandler.draggable(record);
      onHover = dragHandler.onHoverRecord(record);
    }

    return {
      virtualTableRef,
      virtual,
      record,
      onContextOpen,
      onRowClick,
      dictionary,
      active,
      onTableRowDoubleClick,
      onDragStart: onDragStart
        ? (e: DragEvent) => {
            if (onDragStart) {
              onDragStart(e, record, record.key);
              setHighlightedRow({
                ...highlightedRow,
                active: true
              });
            }
          }
        : undefined,
      onDrop: onDrop
        ? (e: DragEvent) => {
            if (onDrop) onDrop(e, record, record.key);
          }
        : undefined,
      onDragEnd: onDragEnd
        ? (e: DragEvent) => {
            if (onDragEnd) {
              onDragEnd(e);
              setHighlightedRow({
                active: false,
                ID: undefined
              });
            }
          }
        : undefined,
      onHover: onHover
        ? (e: MouseEvent, recordT: any) => {
            if (onHover?.(e, recordT, recordT.key).ID !== highlightedRow.ID) {
              setHighlightedRow({
                ...highlightedRow,
                ...onHover?.(e, recordT, recordT?.key)
              });
            }
          }
        : undefined,
      draggable,
      notHighlightFocusRow,
      focusRowCustomHighlight,
      highlighted: highlightedRow.active && compareAsString(record.ID, highlightedRow.ID),
      rowClass: getRowClass({ rowClass, selectedRowKeys })
    };
  };

  return { onRowClick, onRow };
};
