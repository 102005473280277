import { ILS_MAIN_API_DATE_FORMAT, TableRecord } from '@common/types';
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from '@common/types/general/date-time';
import { IILSCellDateStringProps } from '@common/types/components/table/cell';
import { ILSDatePickerStatic } from '@components/custom/data-display/date-picker';
import { EMPTY_STRING } from '@common/constants';
import { getDateStringCellValue } from '@components/data-display/table/utils/helpers/cells/get-date-string-cell-value';

export const ILSCellDateString = <R extends TableRecord>({
  showTime,
  children,
  format = ILS_MAIN_INTERFFACE_DATE_FORMAT,
  dateParseFormat = ILS_MAIN_API_DATE_FORMAT,
  toggleEdit
}: IILSCellDateStringProps<R>): JSX.Element => {
  const [node, date = EMPTY_STRING] = children;

  return (
    <ILSDatePickerStatic
      className={'cell-wrapper-editable'}
      onClick={toggleEdit}
      children={[node, getDateStringCellValue({ date, dateParseFormat, showTime, interfaceFormat: format })]}
    />
  );
};
