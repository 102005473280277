import { getDecoratedOrders } from '@common/models/order/decorators/get-decorated-orders';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { getOrdersDataSourceByTargetId } from '@common/models/order/decorators/get-orders-data-source-by-target-id';
import { ProjectState } from '@common/types/dictionaries/project';
import { IndexedArray, Order } from '@common/types';

export const order2PlannerTable = ({
  orders,
  DepotOverload,
  Worktime,
  depots,
  byTargetID
}: {
  orders?: Array<Order> | null | IndexedArray<Order>;
  Worktime?: ProjectState['Worktime'];
  depots?: ProjectState['Depot'];
  DepotOverload?: ProjectState['DepotOverload'];
  byTargetID?: boolean;
}) => {
  const dataSourceOrders = createArrayFromIndexedArray(orders);
  const resultOrders = getDecoratedOrders({ orders: dataSourceOrders, DepotOverload, Worktime, depots });

  if (byTargetID) {
    return getOrdersDataSourceByTargetId({ orders: dataSourceOrders, resultOrders, depots });
  }
  return resultOrders;
};
