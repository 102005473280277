import { defineModule } from '@core/index';
import TenderOwn from './containers';
import { tenderOwnRootReducer } from '@modules/tender-platform/children/tender-own/reducers';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import * as tenderOwnRootSagas from '@modules/tender-platform/children/tender-own/sagas';
import { ModulePath, ModuleTitle } from '@common/types';
import { getAllModuleSagas } from '@core/sagas';

export default defineModule({
  title: ModuleTitle.TenderOwn,
  path: ModulePath.TenderOwn,
  component: TenderOwn,
  reducer: tenderOwnRootReducer,
  sagas: getAllModuleSagas(tenderOwnRootSagas),
  permission: TENDER_PLATFORM_PERMISSIONS.TENDER_OWN,
  persist: false
});
