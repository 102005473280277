import { createReducer } from '@reduxjs/toolkit';
import { setSplitSizes } from '../actions/split-sizes';
import { ISplitSizesState } from '@core/types/store';

const initialState: ISplitSizesState = {};

const SplitSizes = createReducer(initialState, (builder) => {
  builder.addCase(setSplitSizes, (state, action) => {
    const { blockName, percents, visible } = action.payload;

    state[blockName] = {
      ...state[blockName],
      percents: percents
    };

    if (visible) {
      state[blockName]['visible'] = visible;
    }
  });
});

export default SplitSizes;
