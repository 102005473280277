import { createReducer } from '@reduxjs/toolkit';
import { emulatorStopRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { EmulatorStatus } from '../constants';

// Присваивает статусу эмуляции (emulatorStatus.status) состояние остановки эмуляции (EmulatorStatus.Stop)
const emulatorStopReducer = createReducer(initialState, {
  [emulatorStopRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = true;
    return state;
  },
  [emulatorStopRoutine.SUCCESS]: (state: IEmulatorStore) => {
    state.emulatorStatus.status = EmulatorStatus.Stop;
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorStopRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorStopRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  }
});

export default emulatorStopReducer;
