import { useState } from 'react';
import { FilterSort, TableRecord } from '@common/types';
import { UseFilterSortInfo } from '@common/types/components/table/hooks';
import { TableProps } from 'antd/lib/table/Table';

/** сортировка и фильтры */
export const useFilterSortInfo = <R extends TableRecord>({ current, pageSize, customClearFilters }: UseFilterSortInfo<R>) => {
  const [filterSortInfo, setFilterSortInfo] = useState<FilterSort>({
    filtered: null,
    sorted: null,
    pagination: { current, pageSize }
  });

  const handleChange: TableProps<R>['onChange'] = (pagination, filtered, sorted) => {
    setFilterSortInfo({ filtered, sorted, pagination });
  };

  const clearSorting = () => setFilterSortInfo((filterSortInfo) => ({ ...filterSortInfo, sorted: null }));
  const clearFilters = () => {
    customClearFilters?.clearFilters?.();
    setFilterSortInfo((filterSortInfo) => ({ ...filterSortInfo, filtered: null }));
  };

  return { clearSorting, clearFilters, filterSortInfo, handleChange };
};
