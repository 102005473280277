import { FormField, FormFieldType } from '@modules/documents/children/upload/types/form-field';
import { ILSDatePicker, ILSInput, ILSInputNumber, ILSSelect } from '@common/components';
import { DateFormat } from '@common/types';
import { FC } from 'react';

const FilterInput: FC<{ field: FormField; disabled?: boolean }> = ({ field, ...rest }) => {
  switch (field.type) {
    case FormFieldType.Date:
      return <ILSDatePicker className="w-100" format={DateFormat.DDMMYYYYPeriod} {...rest} />;
    case FormFieldType.Int:
      return <ILSInputNumber className="w-100" {...rest} />;
    case FormFieldType.Float:
      return <ILSInputNumber className="w-100" step="1.0" precision={field.format?.precision} {...rest} />;
    case FormFieldType.Select:
      return field.multiselect === 1 ? (
        <ILSSelect mode={'tags'} maxTagCount={'responsive'} showSearch options={field.variants} {...rest} />
      ) : (
        <ILSSelect showSearch options={field.variants} {...rest} />
      );
    case FormFieldType.String:
      return <ILSInput className="w-100" {...rest} />;
  }
};

export default FilterInput;
