import { DashboardTables } from '../types';
import { DashboardTablesObjMetrics } from './tables';

export { DashboardTablesNumberMetrics, DashboardTablesObjMetrics, DashboardBDDMMetrics, apiMethodsNames } from './tables';

export const MAX_HEIGHT_DEFAULT = 300; // Максимальная высота для таблиц по дефолту
export const FROM_TOP = 10; // Марджин сверху
export const DASHBOARD_LOAD_INTERVAL = 600000; // Время после которого будет полная загрузка всей таблицы
export const DASHBOARD_UPDATES_INTERVAL = 15000; // Время после которого будет загрузка таблицы с параметром updates

// Дефолтные метрики для безопасности дорожного движения
export const DEFAULT_METRICS = {
  // Всего водителей и не просмотренных событий
  driverCount: {
    total: 0,
    unviewed: 0
  },
  // Всего резких ускорений и не просмотренных ускорений
  accelerationsCount: {
    total: 0,
    unviewed: 0
  },
  // Всего резких поворотов и не просмотренных поворотов
  sharpTurnCount: {
    total: 0,
    unviewed: 0
  },
  // Всего резких торможений и не просмотренных торможений
  sharpBrakingCount: {
    total: 0,
    unviewed: 0
  }
};

// Таблицы для которых будут загружены все данные
export const TABLES_WHERE_LOAD_ALL: Array<DashboardTables> = [
  DashboardTablesObjMetrics.OnLate, // Опоздания к клиенту
  DashboardTablesObjMetrics.OnTime, // Доставлено вовремя
  DashboardTablesObjMetrics.Undelivered, // Недоставлено
  DashboardTablesObjMetrics.BrokenSequence // Нарушения последовательности
];

/**
 * Таблицы в которых ключевая сущность для проверки просмотра Waypoint
 */
export const TABLES_WAYPOINT_VIEW: Array<DashboardTables> = [
  DashboardTablesObjMetrics.OnLate,
  DashboardTablesObjMetrics.OnTime,
  DashboardTablesObjMetrics.Undelivered
];
/**
 * Таблицы в которых ключевая сущность для проверки просмотра Trip
 */
export const TABLES_TRIP_VIEW: Array<DashboardTables> = [DashboardTablesObjMetrics.BrokenSequence];

export const MONITORING_IMEI_DT_SEPARATOR = '_'; // Разделитель в дате и времени для IMEI

export const PROGRESS_COLOR = '#4C91FF'; // Цвет прогресса в прогресс-баре
export const PROGRESS_TRAIL_COLOR = '#CECECE'; // Цвет самого прогресс-бара

// Параметры, для которых считается прогресс
export const DashboardProgressLabels = {
  mass: 'Доставлено, кг',
  clients: 'Обслужено точек'
};
