import { DocumentType } from '@modules/documents/types/document-type';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DocumentSetChild } from '../children/templates/types/api';
import { Moment } from 'moment';

export interface Document {
  ID: number;
  Type: DocumentType;
  Created: number;
  TypeName: string;
  DocumentAttachment: DocumentAttachment[];
  children?: Document[];
  DocumentTemplateID?: number;
  Archive?: number;
  Edited?: number;
  Deleted?: number;
}

export interface DocumentAttachment {
  ID: number;
  Subject: string;
  UrlOriginal: string;
  UrlThumbnail: string;
  OriginalName: string;
  Extension: string;
}

export interface DocumentWithButtons {
  ID: number;
  Type?: string;
  Created: string;
  TypeName?: string;
  LeadID?: number;
  DocumentAttachment?: DocumentAttachment[];
  children?: DocumentSetChild[] | Document[] | undefined;
  DocumentTemplateID?: number;
  Archive?: string;
  Edited?: string;
  Deleted: string;
  AccountID?: number;
  Button: { icon: string; items: ItemType[] };
  Favorite?: string | null;
}

export enum DocumentsFilterFieldsEnum {
  CreatedFrom = 'Created[from]',
  CreatedTill = 'Created[till]',
  DependsContract = 'depends[Contract]',
  DependsTenderLot = 'depends[TenderLot]',
  MetaContractCostFrom = 'meta[ContractCost][from]',
  MetaContractCostTill = 'meta[ContractCost][till]'
}

export type DocumentsFilterFields = {
  [DocumentsFilterFieldsEnum.CreatedFrom]?: Moment;
  [DocumentsFilterFieldsEnum.CreatedTill]?: Moment;
  [DocumentsFilterFieldsEnum.DependsContract]?: number;
  [DocumentsFilterFieldsEnum.DependsTenderLot]?: number;
  [DocumentsFilterFieldsEnum.MetaContractCostFrom]?: number;
  [DocumentsFilterFieldsEnum.MetaContractCostTill]?: number;
};

