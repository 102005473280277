import { analyticsGetBoardsRoutineFactory } from '@modules/analytics/common/sagas/get-boards';
import { analyticsGetBoardSetsRoutineFactory } from '@modules/analytics/common/sagas/get-board-sets';

import { AnalyticsTenderAPI } from '../api';
import { analyticsGetWidgetDataRoutineFactory } from '@modules/analytics/common/sagas/get-widget-data';
import { analyticsTenderActions } from '../actions';
import { getBoardsSuccessCommonWatcher } from '@modules/analytics/common/sagas/get-boards-success';
import { getBoardSetsSuccessCommonWatcher } from '@modules/analytics/common/sagas/get-board-sets-success';
import { selectBoardSetCommonWatcher } from '@modules/analytics/common/sagas/select-board-set';
import { selectBoardSetSuccessCommonWatcher } from '@modules/analytics/common/sagas/select-board-set-success';

import { selectBoardCommonWatcher } from '@modules/analytics/common/sagas/select-board';
import { selectBoardSuccessCommonWatcher } from '@modules/analytics/common/sagas/select-board-success';
import { analyticsGetBoardWidgetRoutineFactory } from '@modules/analytics/common/sagas/get-board-widget';
import { setWidgetParamsWatcher } from '@modules/analytics/common/sagas/set-params';
import { setBoardDateRangeCommonWatcher } from '@modules/analytics/common/sagas/board-date-range';

//получение данных виджета
export const analyticsGetWidgetDataWatcher = analyticsGetWidgetDataRoutineFactory(AnalyticsTenderAPI, analyticsTenderActions);
//получение досок
export const analyticsGetBoardsWatcher = analyticsGetBoardsRoutineFactory(AnalyticsTenderAPI, analyticsTenderActions);
export const analyticsGetBoardSets = analyticsGetBoardSetsRoutineFactory(AnalyticsTenderAPI, analyticsTenderActions);
//после получения досок
export const getBoardsSuccessCommon = getBoardsSuccessCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);
//после получения набора досок
export const getBoardSetsSuccessCommon = getBoardSetsSuccessCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);
//после выборан набора досок
export const selectBoardSetSuccessCommon = selectBoardSetSuccessCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);
export const selectBoardCommon = selectBoardCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);
//выбор набора досок
export const selectBoardSetCommon = selectBoardSetCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);
//после выбора доски
export const selectBoardSuccessCommon = selectBoardSuccessCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);

//виджеты для доски
export const getBoardWidgets = analyticsGetBoardWidgetRoutineFactory(AnalyticsTenderAPI, analyticsTenderActions);

// установка параметров
export const tenderSetParams = setWidgetParamsWatcher(AnalyticsTenderAPI, analyticsTenderActions);

export const tenderBoardRangeSetParams = setBoardDateRangeCommonWatcher(AnalyticsTenderAPI, analyticsTenderActions);

//оповещения
export { analyticsTenderNotifierWatcher } from './notifier';
