import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { emulatorChangeEmulationSpeedRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';

// Устанавливает в настройках конфигурации (emulatorStatus.configs.EmulationSpeed) выбранную скорость эмуляции
const emulatorChangeEmulationSpeedReducer = createReducer(initialState, {
  [emulatorChangeEmulationSpeedRoutine.SUCCESS]: (
    state: IEmulatorStore,
    action: PayloadAction<{
      emulationSpeed: number;
    }>
  ) => {
    const { emulationSpeed } = action.payload;

    state.emulatorStatus.configs.EmulationSpeed = emulationSpeed;
    return state;
  }
});

export default emulatorChangeEmulationSpeedReducer;
