import { put, takeLatest } from 'redux-saga/effects';
import {
  getAvailableIntegrationsRoutine,
  getTripsRoutine,
  getVehicleTypesRoutine,
  initRoutine,
  showFormRoutine,
  getTotalCountRoutine
} from '@modules/orders-and-trips/actions';
import { TripStatus } from '@modules/orders-and-trips/types/trip';

function* initWorker() {
  try {
    yield put(showFormRoutine.trigger({ formType: null }));
    yield put(getVehicleTypesRoutine.trigger());
    yield put(getAvailableIntegrationsRoutine.trigger());
    yield put(getTripsRoutine.trigger({ source: TripStatus.New }));
    yield put(getTotalCountRoutine.trigger());
  } catch (error) {
    console.error(error);
  }
}

export function* initWatcher() {
  yield takeLatest(initRoutine.trigger, initWorker);
}

