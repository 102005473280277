import { FC, useEffect, useState } from 'react';
import '../styles.less';
import { useHistory } from 'react-router';
import { ILSButton, ILSTabPane, ILSTabs } from '@components/index';
import { useAbac } from 'react-abac';
import { TENDER_PLATFORM_PERMISSIONS } from '@modules/tender-platform/abac/permissions';
import { DictionariesEndpoint, ITablePropsFunction } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { Tender, TenderPlatformPaths } from '@modules/tender-platform/types';
import { TenderContent } from '@modules/tender-platform/constants';
import { TenderOwnTab } from '@modules/tender-platform/constants/content';
import { ILSTenderTabHeader } from '@modules/tender-platform/components/tab-header';
import { ListTab, ListTabTitle, MAX_DATA_COUNT } from '@modules/tender-platform/constants/tender-card';
import { tender2Table } from '@modules/tender-platform/utils/decorators/tender-to-table';
import { ILSTenderOwnTableContainer } from '@modules/tender-platform/children/tender-own/containers/own-table';
import { ILSMyCarriersButton } from '@modules/tender-platform/children/tender-own/components/my-carriers-button';
import { ownListSelector } from '@modules/tender-platform/children/tender-own/selectors/components';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { useTenderOpen } from '@modules/tender-platform/utils/hooks';

export const TenderPlatformOwnList: FC<{
  handleOpen: ITablePropsFunction<Tender>['handleOpen'];
  handleDelete: (tenderID: number[]) => void;
}> = ({ handleOpen, handleDelete }) => {
  const { userHasPermissions } = useAbac();
  const hasButtons = userHasPermissions(TENDER_PLATFORM_PERMISSIONS.TENDER_CREATE);
  const dispatch = useDispatch();
  const { redirectToTenderCreation } = useTenderOpen();

  const { tenserTabs, tenderTabsByStatus } = useSelector(ownListSelector);

  const {
    push,
    location: { pathname, search }
  } = useHistory();
  const showForm = search === TenderPlatformPaths.Add;
  const [activeKey, setActiveKey] = useState<string>(ListTab.Tenders);

  useEffect(() => {
    (showForm || search === TenderPlatformPaths.CarriersList) && setActiveKey(TenderOwnTab.CarrierList);
  }, [search]);

  const handleCarriersClick = () => push(`${pathname}${TenderPlatformPaths.CarriersList}`);

  const createNewTender = () => {
    dispatch(tenderChangeRoutine({ operation: DictionariesEndpoint.Reset }));
    redirectToTenderCreation();
  };

  return (
    <>
      {hasButtons && (
        <div className={'menu-buttons'}>
          <ILSButton children={TenderContent.CreateTender} type="primary" onClick={createNewTender} />
          <ILSMyCarriersButton onClick={handleCarriersClick} />
        </div>
      )}
      <ILSTabs onTabClick={setActiveKey} activeKey={activeKey} defaultActiveKey={activeKey} className={'tender-platform-list-content'}>
        {tenserTabs.map((tab) => {
          const dataSource = tender2Table(tenderTabsByStatus[tab]);
          return (
            <ILSTabPane
              key={ListTab[tab]}
              tab={<ILSTenderTabHeader overflowCount={MAX_DATA_COUNT} count={dataSource.length} children={ListTabTitle[tab]} />}
            >
              <ILSTenderOwnTableContainer currentTab={tab} handleDelete={handleDelete} handleOpen={handleOpen} dataSource={dataSource} />
            </ILSTabPane>
          );
        })}
      </ILSTabs>
    </>
  );
};
