import { DictionariesEndpoint, Dictionary, IndexedArray, Trip } from '@common/types';
import { dictionaryPlannerRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { restoreProjectData } from '@modules/planning/children/by-date/helpers/reducers/restore-project';
import { updateProjectData } from '@modules/planning/children/by-date/helpers/reducers/update-project';
import { createIndexedArrayFromArray } from '@common/utils/helpers/array/create-indexed-array-from-array';
import { isArray, isEmpty, isObject } from 'lodash';

export const dictionaryPlannerReducer = createReducer(initialState, {
  [dictionaryPlannerRoutine.REQUEST]: (state, action: PayloadAction<{ dictionary: Dictionary }>) => {
    const { dictionary } = action.payload;
    if (state.project?.Info?.[dictionary]) {
      state.project!.Info![dictionary]!.isFetching = true;
    }
    return state;
  },
  [dictionaryPlannerRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<any>;
      dictionary: Dictionary;
      operation: DictionariesEndpoint;
      getFullData: boolean;
      ids?: Array<number>;
    }>
  ) => {
    const { dictionary, data, operation, getFullData, ids } = action.payload;
    if (state.project) {
      switch (operation) {
        case DictionariesEndpoint.Read:
        case DictionariesEndpoint.Index:
          if (dictionary === Dictionary.Depot) {
            if (isEmpty(state.project.Depot)) {
              state.initial = true;
              state.isMapWaitingMode = true;
            }
          }
          //NOTE для сохранения данных, которые доступны только для проекта
          updateProjectData({ data, dictionary, projectState: state.project, getFullData });
          break;
        case DictionariesEndpoint.Clone:
          const items = Object.entries(data);
          items.forEach(([id, item]) => {
            // ! так как вверху есть проверка if (state.project)
            state.project![dictionary][id] = item;
          });
          break;
        case DictionariesEndpoint.Restore:
          restoreProjectData({ dictionary, projectState: state.project, ids });
          break;
        case DictionariesEndpoint.Update:
        case DictionariesEndpoint.Create:
          switch (dictionary) {
            case Dictionary.Project:
              const currentData = isObject(data?.[state.project?.ID]) ? data[state.project.ID] : data;
              state.project = { ...state.project, ...currentData };
              break;
            case Dictionary.Trip:
              const trips: Array<Trip> = Object.values(data);
              const [trip] = trips;
              state.currentPlan.RouteTotal[trip.VehicleID].TripTotal[trip.ID].Comment = trip.Comment;
              break;
            default:
              // ! так как вверху есть проверка if (state.project)
              state.project![dictionary] = { ...state.project[dictionary], ...data };
              break;
          }
          break;
        case DictionariesEndpoint.Delete:
          if (isArray(ids)) {
            if (isArray(state.project?.[dictionary])) {
              state.project![dictionary] = state.project![dictionary].filter((item: { ID: number }) => ids.map(Number).includes(item.ID));
            } else {
              ids.forEach((id) => {
                delete state.project![dictionary][id];
              });
            }
          }
          break;
        case DictionariesEndpoint.MultipleUpdate:
          state.project![dictionary] = { ...state.project[dictionary], ...createIndexedArrayFromArray(data) };
          break;
        default:
          console.error('Нет обработчика в reducer');
          break;
      }
    }
    if (state.project?.Info?.[dictionary]) {
      state.project!.Info![dictionary]!.lastUpdate = Date.now();
    }
    return state;
  },
  [dictionaryPlannerRoutine.FAILURE]: (state) => state,
  [dictionaryPlannerRoutine.FULFILL]: (state, action: PayloadAction<{ dictionary: Dictionary }>) => {
    const { dictionary } = action.payload;
    if (state.project?.Info?.[dictionary]) {
      state.project!.Info![dictionary]!.isFetching = false;
    }
    if (dictionary === Dictionary.Depot) {
      state.initial = false;
      state.isMapWaitingMode = false;
    }
    return state;
  }
});
