export enum SchemeAction {
  UPLOAD_SCHEME = 'UPLOAD_SCHEME',
  CUR_SCHEME = 'CUR_SCHEME',
  MAX_SCHEME = 'MAX_SCHEME'
}

export enum DataSchemeAction {
  UploadScheme = 'SCHEME_EDITOR/UPLOAD_SCHEME',
  CurScheme = 'SCHEME_EDITOR/CUR_SCHEME',
  MaxScheme = 'SCHEME_EDITOR/MAX_SCHEME',
  GetInitialData = 'SCHEME_EDITOR/GET_INITIAL_DATA',
  MoveSchemeKeys = 'SCHEME_EDITOR/MOVE_SCHEME_KEYS',
  AddNewKey = 'SCHEME_EDITOR/ADD_SCHEME_KEYS',
  UploadSchemeIni = 'SCHEME_EDITOR/UPLOAD_SCHEME_INI',
  GetCurrentSchemeIni = 'SCHEME_EDITOR/GET_CURRENT_SCHENE_INI'
}
