import { getAPIURL } from '@common/api/helpers';
import { EMPTY_STRING } from '@common/constants';

export const downloadFileByUrl = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.download = fileName || EMPTY_STRING;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertToMB = (fileSize: number): number => Math.floor(fileSize / 1024 / 1024);

export const downloadFile = ({ apiURL, fileName = EMPTY_STRING }: { apiURL: Parameters<typeof getAPIURL>; fileName?: string }) => {
  const [dictionary, endpoint] = apiURL;
  const attachmentsUrl = process.env.REACT_APP_API_HOST + getAPIURL(dictionary, endpoint);
  downloadFileByUrl(attachmentsUrl, fileName);
};
