import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { SpeedProfile } from '@common/types';

export const speedProfiles2table = (speedProfiles: Array<SpeedProfile>) => {
  return createArrayFromIndexedArray(speedProfiles).map((speedProfile, i) => {
    return {
      ...speedProfile,
      key: speedProfile.ID ?? 'row' + i
    };
  });
};
