import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import { ILSResultRampScheduleContainer } from '@modules/planning/children/by-date/containers/result/tables/ramp-table-container';
import { FieldTimeOutlined } from '@ant-design/icons/lib/icons';
import { useModal } from '@common/hooks/components/use-modal';

export const PlannerPlanningRampScheduleButton: FC = () => {
  const { visible, handleShow, setVisible } = useModal();

  return (
    <>
      <ILSButtonTooltip
        onClick={handleShow}
        icon={<FieldTimeOutlined className={'ils-svg primary'} />}
        placement="bottom"
        title={'Открыть расписание работы рамп для выделенного плана (YMS)'}
      />
      {/*@ts-ignore*/}
      {visible && <ILSResultRampScheduleContainer setShowRampSchedule={setVisible} />}
    </>
  );
};
