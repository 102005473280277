import { CellType, FilterCellType, TableColumnName, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const TENDER_OFFER_TABLE_CONFIG: TableConfig = {
  deleting: false,
  selecting: false,
  copying: false,
  adding: false,
  columns: [
    ID_COLUMN,
    {
      title: 'ИНН',
      dataIndex: TableColumnName.INN,
      key: TableColumnName.INN,
      active: true,

      width: 120,
      editable: false,
      ellipsis: true,
      type: CellType.Input,
      sorting: 4,
      filtering: FilterCellType.String
    },
    {
      title: 'Наименование',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      active: true,

      width: 150,
      editable: false,
      ellipsis: true,
      type: CellType.Input,
      sorting: 3,
      filtering: FilterCellType.String
    },
    {
      title: 'Рейтинг организации',
      dataIndex: TableColumnName.MinCarrierRating,
      key: TableColumnName.MinCarrierRating,
      active: true,

      editable: false,
      ellipsis: true,
      type: CellType.Float
    },
    {
      title: 'ТС',
      dataIndex: TableColumnName.Vehicles,
      key: TableColumnName.Vehicles,
      active: true,

      width: 120,
      editable: false,
      ellipsis: true,
      type: CellType.Input
    },
    {
      title: 'Водитель',
      dataIndex: TableColumnName.DriverName,
      key: TableColumnName.DriverName,
      active: true,

      width: 150,
      editable: false,
      ellipsis: true,
      type: CellType.Input,
      sorting: 1
    },
    {
      title: 'Рейтинг водителя',
      dataIndex: TableColumnName.MinDriverRating,
      key: TableColumnName.MinDriverRating,
      active: true,

      editable: false,
      ellipsis: true,
      type: CellType.Float,
      sorting: 1
    },
    {
      title: 'Рейсов',
      dataIndex: TableColumnName.Route,
      key: TableColumnName.Route,
      width: 100,
      active: true,

      editable: false,
      sorting: 8,
      filtering: FilterCellType.String,
      ellipsis: true,
      type: CellType.Int
    },
    {
      title: 'Предложение',
      dataIndex: TableColumnName.Price,
      key: TableColumnName.Price,
      width: 150,
      active: true,

      editable: false,
      sorting: 8,
      filtering: FilterCellType.String,
      ellipsis: true,
      type: CellType.Int
    }
  ]
};

export const PARTICIPANT_STATUS_COLUMN = {
  title: 'Статус участника',
  dataIndex: TableColumnName.Accepted,
  key: TableColumnName.Accepted,
  width: 200,
  active: true,

  editable: false,
  sorting: 8,
  filtering: FilterCellType.String,
  ellipsis: true,
  type: CellType.Status
};
