import { FC } from 'react';
import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import { ILSCatalogTariffItem } from './item';
import { useSelector } from 'react-redux';
import { paramFetchSelector, tariffFetchSelector } from '@modules/catalog/selectors/tariffs';
import { Dictionary } from '@common/types';
import { Tariff } from '@modules/catalog/types/tariff';
import { VehicleTariffs } from '@common/types/dictionaries/vehicle';
import { ILSTariffConstructor } from '@modules/catalog/components/tariff/constructor';

export enum TariffPanel {
  tariffConstructor = 'tariffConstructor',
  VehicleTariff = 'VehicleTariff',
  VehicleTariffPart = 'VehicleTariffPart'
}

export interface IILSCatalogTariffContainer {
  tariffs: VehicleTariffs;
  activePanel: TariffPanel;
  params?: any;
  activeItem: any;
  changeActiveItem: (dictionary: Dictionary, item: any) => void;
  handleSave: any;
  handleDelete: any;
  tariff?: Tariff;
  edit?: boolean;
  checking?: boolean | 1 | 0;

  setActivePanel(val: TariffPanel): void;

  setEdit?(p: boolean): void;
}

export const ILSCatalogTariffContainer: FC<IILSCatalogTariffContainer> = ({
  activePanel,
  setActivePanel,
  params,
  tariffs,
  tariff,
  activeItem,
  changeActiveItem,
  checking,
  edit,
  setEdit,
  handleDelete,
  handleSave
}) => {
  const tariffIsFetching = useSelector(tariffFetchSelector);
  const paramIsFetching = useSelector(paramFetchSelector);

  const onChange = (val: any) => {
    setActivePanel(val);
    if (val !== TariffPanel.tariffConstructor) {
      changeActiveItem(val, val === TariffPanel.VehicleTariff ? tariffs?.[0] : params[0]);
    }
  };

  return (
    <ILSCollapse
      className="position-relative"
      bordered={false}
      accordion
      defaultActiveKey={[activePanel]}
      onChange={onChange}
      activeKey={activePanel}
      destroyInactivePanel
    >
      <ILSCollapsePanel key={TariffPanel.tariffConstructor} header={'Конструктор тарифов'}>
        <ILSTariffConstructor
          tariff={tariff}
          dictionary={Dictionary.Empty}
          edit={edit}
          checking={checking}
          setEdit={setEdit}
          handleSave={handleSave}
        />
      </ILSCollapsePanel>
      <ILSCollapsePanel key={TariffPanel.VehicleTariff} header={'Перечень тарифов'}>
        <ILSCatalogTariffItem
          activePanel={activePanel}
          activeItem={activeItem}
          changeActiveItem={changeActiveItem}
          isFetching={tariffIsFetching}
          dictionary={Dictionary.VehicleTariff}
          panelName={TariffPanel.VehicleTariff}
          items={tariffs}
          handleDelete={handleDelete}
          handleSave={handleSave}
        />
      </ILSCollapsePanel>
      <ILSCollapsePanel key={TariffPanel.VehicleTariffPart} header={'Перечень пользовательских параметров'}>
        <ILSCatalogTariffItem
          activePanel={activePanel}
          activeItem={activeItem}
          changeActiveItem={changeActiveItem}
          handleDelete={handleDelete}
          handleSave={handleSave}
          isFetching={paramIsFetching}
          dictionary={Dictionary.VehicleTariffPart}
          panelName={TariffPanel.VehicleTariffPart}
          items={params}
        />
      </ILSCollapsePanel>
    </ILSCollapse>
  );
};
