import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';

export const useTenderTripForm = () => {
  const [form] = useForm();
  //TODO Типизировать values
  const onFinish = (values: any) => {
    //TODO Реализовать логику отправки данных на сервер
  };
  useEffect(() => {
    //TODO Реализовать form.reset() / form.setValues
  }, []);
  return { onFinish, form };
};
