import LangSwitch from '@core/reducers/lang-switch';
import SplitSizes from '@core/reducers/split-sizes';
import TablesConfig from '@core/reducers/table-custom';
import { TModulesObj, TReducersObj } from '@core/types/models';
import { AnyAction, createReducer, Reducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { flatModules } from './modules';
import { ICoreState } from './types/store';
import { StateKeyName } from '@core/constants/core';
import { clearStoreInterceptor } from '@core/helpers/clear-store-interceptor';

const getModuleReducers = (modules: TModulesObj, reducers: TReducersObj = {}): TReducersObj => {
  const flat = flatModules(modules);

  for (let i = 0; i < flat.length; i++) {
    if (flat.hasOwnProperty(i)) {
      const reducer = flat[i].reducer;

      if (typeof reducer === 'function') {
        reducers[flat[i]['MODULE']!] = reducer;
      }

      if (typeof flat[i].children === 'object' && Object.keys(flat[i].children!).length > 0) {
        getModuleReducers(flat[i].children!, reducers);
      }
    }
  }

  if (Object.keys(reducers).length === 0) {
    reducers['default'] = createReducer(null, {});
  }

  return reducers;
};

export function createReducerManager(initialReducers: TReducersObj) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };
  const initialKeys = Object.keys(initialReducers);

  // Create the initial combinedReducer
  let combinedReducer: Reducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove: string[] = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state: ICoreState, action: AnyAction) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (let key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      // Clear All Store
      const clearState = clearStoreInterceptor(state, action);

      // Delegate to the combined reducer
      return combinedReducer(clearState ?? state, action as never);
    },

    // Adds a new reducer with the specified key
    add: (key: string, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key: string) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    replace: (keys: string[]) => {
      if (!keys) return;

      const newKeys = [...initialKeys, ...keys];
      keysToRemove = Object.keys(reducers).filter((key) => !newKeys.includes(key));

      keysToRemove.forEach((key: string) => {
        delete reducers[key];
      });

      combinedReducer = combineReducers(reducers);
    }
  };
}

export const createRootReducer = (args: { modules: TModulesObj; rootReducers: boolean }) => {
  const { modules, rootReducers } = args;
  const moduleReducers = getModuleReducers(modules);
  const coreReducers = { TablesConfig, SplitSizes, LangSwitch };
  const reducers: TReducersObj = {};

  if (rootReducers) {
    reducers[StateKeyName.Auth] = moduleReducers.Auth;
  } else {
    for (const key in moduleReducers) {
      if (key !== StateKeyName.Settings) {
        reducers[key] = moduleReducers[key];
      }
    }
  }

  const fixed = { ...coreReducers, Settings: moduleReducers.Settings };
  reducers[StateKeyName.Fixed] = combineReducers(fixed); // NOTE: фиксированное поле в store
  return createReducerManager(reducers);
};

