import { FC } from 'react';
import { PlannerProjectTitle } from '@modules/planning/components/project-title';
import { PlannerPlanningMenuContainer } from '@modules/planning/containers/menu';
import { IPlanningResultTablesContainerProps } from '../../types/tables-container';
import { ILSPlanningValidateContainer } from '@modules/planning/children/by-date/containers/validate';
import cn from 'classnames';
import { PlannerPlanningPlansAndRoutes } from '@modules/planning/children/by-date/containers/result/tables/plans-and-routes';
import { usePlanningConfig } from '@modules/planning/children/by-date/hooks';

export const ILSPlanningResultTablesContainer: FC<Omit<IPlanningResultTablesContainerProps, 'onTableRowClick' | 'setActiveRowID'>> = ({
  markerDragging,
  setTriggerMapFocus,
  onItemDrop,
  handleShowOrderTable,
  fullMap,
  settingsTableButtons
}) => {
  usePlanningConfig();

  return (
    <div className={cn('h-100 position-relative ils-planning-result', { fullMap })}>
      <section className="ils-planning-result-settings">
        <PlannerPlanningMenuContainer hasContentForProject />
        <PlannerProjectTitle hasTooltip />
      </section>
      <section className="ils-planning-result-info">
        <ILSPlanningValidateContainer />
        <PlannerPlanningPlansAndRoutes
          settingsTableButtons={settingsTableButtons}
          setTriggerMapFocus={setTriggerMapFocus}
          handleShowOrderTable={handleShowOrderTable}
          markerDragging={markerDragging}
          onItemDrop={onItemDrop}
        />
      </section>
    </div>
  );
};
