import { createReducer } from '@reduxjs/toolkit';
import { documentUpdateRoutine } from '@modules/documents/actions';
import { initialState } from '@modules/documents/store';

export const documentUpdate = createReducer(initialState, {
  [documentUpdateRoutine.REQUEST]: (state) => {
    state.isFetching = true;
    return state;
  },
  [documentUpdateRoutine.SUCCESS]: (state, action) => {
    state.data = state.data?.map((document) => (document.ID === action.payload.ID ? action.payload : document));
    return state;
  },
  [documentUpdateRoutine.FULFILL]: (state) => {
    state.isFetching = false;
    return state;
  }
});
