import reduceReducers from 'reduce-reducers';
import { INITIAL_STATE } from '@modules/planning/children/scheme-editor/store';
import { getCurrentSchemeReducer } from '@modules/planning/children/scheme-editor/reducers/get-current-scheme';
import { getMaxSchemeReducer } from '@modules/planning/children/scheme-editor/reducers/get-max-scheme';
import { moveSchemeKeysReducer } from '@modules/planning/children/scheme-editor/reducers/move-scheme-keys';
import { addSchemeKeysReducer } from '@modules/planning/children/scheme-editor/reducers/add-scheme-keys';
import { uploadSchemeIniReducer } from '@modules/planning/children/scheme-editor/reducers/upload-scheme-ini';

export const schemeEditorReducer = reduceReducers(
  INITIAL_STATE,
  getCurrentSchemeReducer,
  getMaxSchemeReducer,
  getCurrentSchemeReducer,
  moveSchemeKeysReducer,
  addSchemeKeysReducer,
  uploadSchemeIniReducer
);
