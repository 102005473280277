import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { updateExternalConfigRoutine } from '../actions';
import { Provider } from '@common/types';

function* updateExternalConfigWorker(
  action: PayloadAction<{
    provider: Provider;
    key: string;
    value: string;
  }>
) {
  const { success, failure } = updateExternalConfigRoutine;
  const { provider, key, value } = action.payload;

  try {
    yield put(success({ provider, key, value }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* updateExternalConfigWatcher() {
  yield takeLatest(updateExternalConfigRoutine.trigger, updateExternalConfigWorker);
}
