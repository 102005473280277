import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';
import { initialState } from '@modules/planning/children/by-date/store';
import { planningRestrictionFilterRoutine } from '@modules/planning/children/by-date/actions';
import { RestrictionFilterPayloadAction } from '@modules/planning/children/by-date/sagas/filters/restrictions';

export const planningRestrictionFilterReducer = createReducer(initialState, {
  [planningRestrictionFilterRoutine.SUCCESS]: (state, action: RestrictionFilterPayloadAction) => {
    const { ids } = action.payload ?? {};
    state.filters.restriction.IDs = ids;
    return state;
  },
  [planningRestrictionFilterRoutine.FAILURE]: onFailure,
  [planningRestrictionFilterRoutine.FULFILL]: onFulfill
});
