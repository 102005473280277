import { TableConfig } from '@common/types';
import { ORDER_TABLE_CONFIG } from '@common/models/order/config';
import { CARGO_TABLE_COLUMNS } from '@common/models/cargo/config';

export const orderConfig: TableConfig = {
  ...ORDER_TABLE_CONFIG,
  headerButtonsParams: {
    add: {
      icon: 'add-order',
      iconText: 'Создать новую заявку',
      className: 'add-order-btn'
    }
  },
  configClassName: 'table-config-planner-order',
  deleting: true,
  selecting: true,
  reloading: false,
  adding: {},
  widget: true,
  closeButton: true,
  columns: [...ORDER_TABLE_CONFIG.columns, ...CARGO_TABLE_COLUMNS] //доп колонки для заявок
};

export const orderConfigForEditable: TableConfig = {
  ...ORDER_TABLE_CONFIG,
  deleting: false,
  selecting: false,
  reloading: false,
  adding: false,
  widget: false,
  closeButton: false,
  copying: false,
  columns: [...ORDER_TABLE_CONFIG.columns, ...CARGO_TABLE_COLUMNS] //доп колонки для заявок
};
