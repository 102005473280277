import { ILSSkeleton } from '@common/components';
import { Area, Chart, Tooltip } from 'bizcharts';
import React, { FC, useMemo } from 'react';
import { AreaChartProps } from '../../types/components/charts';
import { AnalyticsChartNoData } from '@modules/analytics/common/charts/components/chart-no-data';
import { useForceUpdate } from '@modules/analytics/common/hooks/use-force-update';

const ILSAnalyticsAreaChartComponent: FC<AreaChartProps> = ({
  hideWidget,
  axisConfig,
  axis = {},
  data,
  height = 200,
  config,
  loading,
  name,
  children
}) => {
  const dataValueEmpty = useMemo(() => data && Object.values(data)?.every((item) => item?.x === 0 || item?.y === 0), [data]);
  useForceUpdate(loading);

  if (hideWidget) {
    return <></>;
  }
  return (
    <div className="analytics-chart limit">
      {children}
      {!data?.length && !loading ? (
        <AnalyticsChartNoData name={name} hideBorder={!!children} />
      ) : (
        <ILSSkeleton loading={loading}>
          <div className="mb-2">{config?.title}</div>
          <div>
            {dataValueEmpty ? (
              <AnalyticsChartNoData hideBorder={true} />
            ) : (
              <Chart
                height={height - 80}
                data={data}
                padding="auto"
                autoFit
                scale={{
                  y: {
                    nice: true,
                    formatter: (v: any) => {
                      return `${v}${axisConfig?.y.unit}`;
                    }
                  }
                }}
              >
                <Tooltip data-html2canvas-ignore>
                  {(title, items) => {
                    return (
                      <div className="mt-1 mb-1">
                        <h4 className="mb-1">{title}</h4>
                        {items ? (
                          items.map((item) => {
                            return (
                              <div>
                                {axis[item.name]}: &nbsp;&nbsp;{item.value}
                              </div>
                            );
                          })
                        ) : (
                          <div>Нет данных</div>
                        )}
                      </div>
                    );
                  }}
                </Tooltip>
                <Area position="x*y" />
              </Chart>
            )}
          </div>
        </ILSSkeleton>
      )}
    </div>
  );
};

export default ILSAnalyticsAreaChartComponent;
