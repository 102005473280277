import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTripContractRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';

function* getTripContractWorker(
  action: PayloadAction<{
    tripID: number;
    alias: TenderOutFastDeliveryServices;
    integrationID: string;
  }>
) {
  const { request, success, failure, fulfill } = getTripContractRoutine;
  const { tripID, alias, integrationID } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripContract, { tripID, alias, integrationID });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripContractWatcher() {
  yield takeLatest(getTripContractRoutine.trigger, getTripContractWorker);
}

