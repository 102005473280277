import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogCreateLocalRoutine } from '@modules/catalog/actions';
import { DataType, SaveDictionaryType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';

const createLocalReducer = createReducer(initialState, {
  [catalogCreateLocalRoutine.REQUEST]: onRequest,
  [catalogCreateLocalRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: DataType;
      dictionary: Dictionary;
      saveDictionary: SaveDictionaryType;
    }>
  ) => {
    let { data, dictionary, saveDictionary } = action.payload;
    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;
    const values = Object.values(data);
    const newValue = values.pop();
    const newRow = { ...newValue, key: newValue?.ID };
    if (newValue) {
      // @ts-ignore
      state[dictionary].data = values.concat(newRow);
      state[dictionary].focusRow = newRow;
    }
    return state;
  },
  [catalogCreateLocalRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogCreateLocalRoutine.FULFILL]: onFulfill
});

export default createLocalReducer;
