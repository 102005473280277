import { Cargo } from './cargo';
import { Depot } from './depot';

export enum Dictionary {
  ProjectVehicle = 'ProjectVehicle',
  Account = 'Account',
  User = 'User',
  Address = 'Address',
  BrokenSequence = 'Desequence',
  Cargo = 'Cargo',
  Contact = 'Contact',
  CouplingType = 'CouplingType',
  CrossDock = 'CrossDock',
  CountIteration = 'CountIteration',
  Client = 'Depot',
  Data = 'Data',
  DeliveredOrder = 'DeliveredOrder',
  Depot = 'Depot',
  DepotOverload = 'DepotOverload',
  Driver = 'Driver',
  DriverTimeline = 'DriverTimeline',
  EventDetailsPlanFact = 'EventDetailsPlanFact',
  Empty = '',
  EventDetails = 'EventDetails',
  EventDetailsBDD = 'EventDetailsBDD',
  ForbiddenZone = 'ForbiddenZone',
  FriendZone = 'FriendZone',
  DeliveryZone = 'DeliveryZone',
  SurveillanceZone = 'SurveillanceZone',
  FuelDrain = 'FuelDrain',
  Garage = 'Garage',
  NotDeliveredOrder = 'NotDeliveredOrder',
  OnLate = 'Late',
  OnTime = 'InTime',
  Order = 'Order',
  OrderAction = 'OrderAction',
  OrderCargo = 'OrderCargo',
  PermitZone = 'PermitZone',
  PermitZoneType = 'PermitZoneType',
  Plan = 'Plan',
  Planning = 'Planning',
  Project = 'Project',
  PlanConfig = 'PlanConfig',
  Ramp = 'Ramp',
  RampType = 'RampType',
  Restriction = 'Restriction',
  RestrictionGroup = 'RestrictionGroup',
  RoadTrain = 'RoadTrain',
  Route = 'Route',
  Served = 'Served',
  Tracker = 'Tracker',
  VehicleTracker = 'VehicleTracker',
  SpeedProfile = 'SpeedProfile',
  TargetZoneID = 'TargetZoneID',
  TargetZoneEnum = 'TargetZoneEnum',
  Tender = 'Tender',
  TenderCarrier = 'TenderCarrier',
  TenderDoc = 'TenderDoc',
  TenderDocuments = 'TenderDocuments',
  TenderLot = 'TenderLot',
  TenderOffer = 'TenderOffer',
  TenderOwn = 'TenderOwn',
  TenderParticipants = 'TenderParticipants',
  TenderSearch = 'TenderSearch',
  TenderSummary = 'TenderSummary',
  TrailerAction = 'TrailerAction',
  TransportCompany = 'TransportCompany',
  TypePlanning = 'TypePlanning',
  Trip = 'Trip',
  TripTemplate = 'TripTemplate',
  Undelivered = 'Undelivered',
  UserID = 'UserID',
  Vehicle = 'Vehicle',
  VehicleOverload = 'VehicleOverload',
  VehicleProfile = 'VehicleProfile',
  VehicleTariff = 'VehicleTariff',
  VehicleTariffPart = 'VehicleTariffPart',
  VehicleTimeline = 'VehicleTimeline',
  VehicleType = 'VehicleType',
  Violations = 'BDD',
  Waypoint = 'Waypoint',
  Worktime = 'Worktime',
  Zone = 'Zone',
  EventDetailsTrack = 'EventDetailsTrack',
  VehicleMonitoring = 'VehicleMonitoring',
  Trips = 'Trips',
  Waypoints = 'Waypoints',
  DistanceMatrix = 'DistanceMatrix',
  PlannerParams = 'PlannerParams',
  Options = 'Options',
  VehicleBox = 'VehicleBox',
  DepotGatheringSchedule = 'DepotGatheringSchedule',
  Productor = 'Productor',
  ProductorVehicle = 'ProductorVehicle',
  SimplePlan = 'SimplePlan',
  Intro = 'Intro',
  Color = 'Color',
  AvailableStatuses = 'AvailableStatuses',
  MobileApplication = 'MobileApplication',
  ForwarderPointStatuses = 'ForwarderPointStatuses',
  ForwarderPointStatusesGroup = 'ForwarderPointStatusesGroup',
  IconUrl = 'IconUrl',
  LoadDate = 'LoadDate',
  UnLoadDate = 'UnloadDate',
  Monitoring = 'Monitoring',
  Demand = 'Demand',
  Attachments = 'Attachments',
  AccountBalance = 'AccountBalance'
}

export enum ValidationDictionary {
  Client = 'Depot',
  Depot = 'Depot',
  Garage = 'Depot',
  Order = 'Order',
  Driver = 'Driver',
  Vehicle = 'Vehicle'
}

export enum DictionaryTitle {
  Account = 'Аккаунт',
  Address = 'Адрес',
  BrokenSequence = 'Desequence',
  Cargo = 'Грузы',
  Contact = 'Контакты',
  CouplingType = 'Автосцепки',
  CrossDock = 'Зоны кросс-докинга',
  CountIteration = 'CountIteration',
  Client = 'Клиенты',
  Data = 'Data',
  DeliveredOrder = 'DeliveredOrder',
  Depot = 'Точки',
  DepotOverload = 'Превышения',
  Driver = 'Водители',
  DriverTimeline = 'Режимы работы водителей',
  EventDetailsPlanFact = 'EventDetailsPlanFact',
  Empty = '',
  EventDetails = 'EventDetails',
  EventDetailsBDD = 'EventDetailsBDD',
  ForbiddenZone = 'Зоны ограничений',
  FriendZone = 'Зоны доставки',
  FuelDrain = 'FuelDrain',
  Garage = 'Склады/Гаражи',
  NotDeliveredOrder = 'NotDeliveredOrder',
  OnLate = 'Late',
  OnTime = 'InTime',
  Order = 'Заявки',
  OrderAction = 'OrderAction',
  OrderCargo = 'OrderCargo',
  PermitZone = 'Зона доставки',
  PermitZoneType = 'Типы зон доставки',
  Plan = 'Планы',
  Planning = 'Planning',
  Project = 'Проекты',
  PlanConfig = 'PlanConfig',
  Ramp = 'Рампы',
  RampType = 'Типы рамп',
  Restriction = 'Специфики',
  RestrictionGroup = 'Группы специфик',
  RoadTrain = 'Сцепки',
  Route = 'Route',
  Served = 'Served',
  SpeedProfile = 'Профили скоростей',
  Tender = 'Tender',
  TenderCarrier = 'TenderCarrier',
  TenderDoc = 'TenderDoc',
  TenderDocuments = 'TenderDocuments',
  TenderLot = 'TenderLot',
  TenderOffer = 'TenderOffer',
  TenderOwn = 'TenderOwn',
  TenderParticipants = 'TenderParticipants',
  TenderSearch = 'TenderSearch',
  TenderSummary = 'TenderSummary',
  TrailerAction = 'TrailerAction',
  TransportCompany = 'Транспортные компании',
  TypePlanning = 'TypePlanning',
  Trip = 'Trip',
  TripTemplate = 'Шаблоны рейсов',
  Undelivered = 'Undelivered',
  UserID = 'UserID',
  Vehicle = 'Автомобиль',
  VehicleOverload = 'Превышения автомобилей',
  VehicleProfile = 'Профили автомобилей',
  VehicleTariff = 'Тарифы',
  VehicleTariffPart = 'VehicleTariffPart',
  VehicleTimeline = 'Расписания работы автомобиля',
  VehicleType = 'Типы транспортного средства',
  Violations = 'BDD',
  Waypoint = 'Waypoint',
  Worktime = 'Расписания работы',
  Zone = 'Зона',
  EventDetailsTrack = 'EventDetailsTrack',
  VehicleMonitoring = 'VehicleMonitoring',
  Trips = 'Trips',
  Waypoints = 'Waypoints',
  DistanceMatrix = 'DistanceMatrix',
  Tracker = 'Tracker',
  VehicleTracker = 'VehicleTracker',
  PlannerParams = 'PlannerParams',
  Options = 'Options',
  VehicleBox = 'VehicleBox',
  DepotGatheringSchedule = 'DepotGatheringSchedule',
  Productor = 'Productor',
  ProductorVehicle = 'ProductorVehicle',
  SimplePlan = 'SimplePlan',
  Intro = 'Intro',
  MobileApplication = 'MobileApplication'
}

export type DictionariesStrings = keyof typeof Dictionary;

export enum CatalogDictionary {
  Cargo = 'CatalogCargo',
  Contact = 'CatalogContact',
  CouplingType = 'CatalogCouplingType',
  CrossDock = 'CatalogCrossDock',
  Depot = 'CatalogDepot',
  DepotOverload = 'CatalogDepotOverload',
  Driver = 'CatalogDriver',
  DriverTimeline = 'CatalogDriverTimeline',
  Order = 'CatalogOrder',
  OrderCargo = 'CatalogOrderCargo',
  ForbiddenZone = 'CatalogForbiddenZone',
  Restriction = 'CatalogRestriction',
  RestrictionGroup = 'CatalogRestrictionGroup',
  RoadTrain = 'CatalogRoadTrain',
  SpeedProfile = 'CatalogSpeedProfile',
  TripTemplate = 'CatalogTripTemplate',
  Vehicle = 'CatalogVehicle',
  VehicleOverload = 'CatalogVehicleOverload',
  VehicleProfile = 'CatalogVehicleProfile',
  VehicleTimeline = 'CatalogVehicleTimeline',
  Worktime = 'CatalogWorktime',
  Zone = 'CatalogZone',
  DeliveryZone = 'CatalogDeliveryZone',
  SurveillanceZone = 'CatalogSurveillanceZone',
  Ramp = 'CatalogRamp',
  RampType = 'CatalogRampType'
}

export enum CatalogTitle {
  Order = 'Заявки',
  Driver = 'Водители',
  Vehicle = 'Парк машин',
  Depot = 'Точки',
  Zone = 'Зоны',
  DeliveryZone = 'Зоны доставки',
  SurveillanceZone = 'Зоны наблюдения',
  ForbiddenZone = 'Зоны ограничения',
  SpeedProfile = 'Профили скоростей',
  Restriction = 'Специфики',
  TripTemplate = 'Шаблоны рейсов',
  CrossDock = 'Кросс докинг',
  Tariff = 'Тарифы',
  Worktime = 'Расписание работы',
  TransportCompany = 'Транспортные компании'
}

export enum CatalogDescription {
  CrossDock = 'Описание справочника кросс докинг',
  Depot = 'Описание справочника точек',
  Driver = 'Описание справочника водителей',
  Order = 'Описание заявок',
  ForbiddenZone = 'Описание справочника зоны ограничения',
  Restrictions = 'Описание справочника специфик',
  SpeedProfile = 'Описание справочника профиля скоростей',
  TripTemplate = 'Описание справочника шаблоны рейсов',
  Tariff = 'Описание справочника с тарифами',
  Vehicle = 'Описание справочника парка машин',
  DeliveryZone = 'Описание справочника зон доставки',
  SurveillanceZone = 'Описание справочника зон наблюдения',
  TransportCompany = 'Описание справочника транспортных компаний'
}

export enum CATALOG_NAMES {
  CatalogDepot = 'CatalogDepot',
  CatalogDepotOverload = 'CatalogDepotOverload',
  CatalogDriverTimeline = 'CatalogDriverTimeline',
  CatalogRestrictionGroup = 'CatalogRestrictionGroup',
  CatalogRoadTrain = 'CatalogRoadTrain',
  CatalogVehicle = 'CatalogVehicle',
  CatalogVehicleOverload = 'CatalogVehicleOverload',
  CatalogVehicleProfile = 'CatalogVehicleProfile',
  CatalogVehicleTimeline = 'CatalogVehicleTimeline'
}

export type MobileApplicationCatalogDictionary = Dictionary.ForwarderPointStatuses | Dictionary.ForwarderPointStatusesGroup;

export type Catalog = Cargo | Depot; // все

