import { EMPTY, EMPTY_STRING } from '@common/constants';
import { FormFieldNames, FormSections, ITripFormValues, ITripDataRequestValues } from '@modules/orders-and-trips/types/form';
import { DraftTripNOrder } from '@modules/orders-and-trips/types/trip';

export const tripFormValuesToData = (data: { formValues: ITripFormValues; draftTripNOrder: DraftTripNOrder }): ITripDataRequestValues => {
  const { formValues, draftTripNOrder } = data ?? {};
  return {
    [FormSections.Points]: draftTripNOrder?.map((point, index) => {
      return {
        [FormFieldNames.ActionType]: point.ActionType,
        [FormFieldNames.DepotID]: point.DepotID,
        [FormFieldNames.Contact]: formValues[index]?.Contact ?? EMPTY_STRING,
        [FormFieldNames.Phone]: formValues[index]?.Phone ?? EMPTY_STRING,
        [FormFieldNames.Cargo]: point?.Cargo.map((cargo) => {
          return {
            Amount: cargo.Amount,
            CargoID: cargo.ID,
            OrderID: cargo.OrderID
          };
        })
      };
    }),
    [FormSections.TransportRequirement]: Object.entries(formValues?.transportRequirement ?? {}).reduce((requirements, [key, value]) => {
      switch (key) {
        default:
          requirements[key] = !value ? 0 : Number(value);
          break;
      }
      return requirements;
    }, {} as ITripDataRequestValues['transportRequirement'])
  };
};

