import { tableCustom } from '@core/containers/table-custom';
import { worktime2table } from '@common/models/worktime/decorators';
import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';
import { FC } from 'react';
import { Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { WORKTIME_TABLE_CONFIG } from '@common/models/worktime/config/table';

const PlanningWorktime: FC<IILSTablePlanningComponentProps> = (props) => {
  return (
    <PlannerPlanningTablesContainer
      {...props}
      handleDecorator={table2data}
      dataDecorator={worktime2table}
      dictionary={Dictionary.Worktime}
    />
  );
};

export const WorktimeTable = tableCustom(PlanningWorktime, WORKTIME_TABLE_CONFIG, Dictionary.Worktime);
