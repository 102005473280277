import { CellType, TableConfig } from '@common/types';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: 'Время',
      dataIndex: 'time',
      key: 'time',
      active: true,
      type: CellType.Input,
      width: 100,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      active: true,
      type: CellType.Input,
      width: 100,

      sorting: 3,

      ellipsis: true
    },
    {
      title: 'Название события',
      dataIndex: 'eventName',
      key: 'eventName',
      active: true,
      type: CellType.Input,
      width: 80,

      sorting: 3,

      ellipsis: true
    }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  copying: false
};
