import { Dragging, IMapTrack, IndexedArray, MarkerType, TrackActiveData } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { SelectedOrders } from '@modules/planning/types/active-items';
import { UnresolvedMarkerSourceValue } from '@modules/settings/types/planning';
import L from 'leaflet';
import { Dispatch, FC, MouseEvent, MutableRefObject, SetStateAction, memo, useCallback, useEffect, useState } from 'react';
import { DragDropContainer } from 'react-drag-drop-container';
import { DragDataType, MarkerItem, PolylineItem, SelectedOrdersLasso } from '../../../types/map-container';
import { useMarkerEventHandler, useMarkerTripHandlers, useSummaryData } from './hooks/drag-marker';

//TODO сделать общий тип
interface Props {
  dragging: Dragging;
  polylineDataIDs: MutableRefObject<TrackActiveData>;
  handleMarkerDragEnd: (dragData: DragDataType, trackData?: IMapTrack['clickData'] & Partial<IMapTrack['point']>) => void;
  selectedOrdersLasso: SelectedOrdersLasso;
  selectedOrders: SelectedOrders;
  mapPolylines: MutableRefObject<Record<string, PolylineItem>>;
  mapMarkers: MutableRefObject<Record<string, MarkerItem>>;
  orderCluster: (L.MarkerClusterGroup & { _featureGroup?: { _layers?: IndexedArray<L.MarkerCluster & { _icon?: any }> } }) | undefined;
  map: MutableRefObject<ILSMap | null>;
  onMouseLeave: (target: HTMLElement) => void;
  markerDragging: boolean;
  setMarkerDragging: Dispatch<SetStateAction<boolean>>;
  unresolvedMarkerSource?: UnresolvedMarkerSourceValue;
}

const ILSPlanningDragMarker: FC<Props> = memo(
  ({
    dragging,
    polylineDataIDs,
    handleMarkerDragEnd,
    selectedOrdersLasso,
    selectedOrders,
    mapPolylines,
    onMouseLeave,
    mapMarkers,
    orderCluster,
    map,
    setMarkerDragging,
    markerDragging,
    unresolvedMarkerSource = UnresolvedMarkerSourceValue.Target
  }) => {
    const toggleMarkerDragging = useCallback((val?: boolean) => {
      setMarkerDragging(val ?? !markerDragging);
    }, []);
    const [eventType, setEventType] = useState<MarkerType | string | undefined>(undefined);
    //eventType обязательно должен проверяться в самом начале
    if (dragging.type !== eventType) {
      setEventType(dragging.type);
    }
    //Хранит информацию о максимальном статусе маркера
    const { changeDragMaxMarkerStatus, dragMarkerTripIDs, dragMaxMarkerStatus } = useMarkerTripHandlers({
      dragging,
      mapPolylines
    });

    const { onMarkerClick, onMouseOut, onMouseOver } = useMarkerEventHandler({
      dragging,
      map,
      mapPolylines,
      mapMarkers,
      eventType,
      orderCluster
    });

    useSummaryData({ selectedOrdersLasso, selectedOrders, dragging, unresolvedMarkerSource });

    const onDragEnd = (
      dragData: Dragging,
      polyline:
        | {
            data: IMapTrack['clickData'] & Partial<IMapTrack['point']>;
          }
        | undefined
        | null
    ) => {
      toggleMarkerDragging?.(false);

      if (polyline && polyline.data) {
        handleMarkerDragEnd(dragData, polyline.data);
        changeDragMaxMarkerStatus(undefined);
      }
    };

    const onDragNDropClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        toggleMarkerDragging?.(false);
        onMarkerClick(e);
      },
      [onMarkerClick]
    );

    return (
      <div
        className={'drag-container'}
        onClick={onDragNDropClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        style={{
          width: `${dragging.width}px`,
          height: `${dragging.height}px`,
          right: `${dragging.x}px`,
          top: `${dragging.y}px`
        }}
      >
        <DragDropContainer
          targetKey="trip-target"
          onDragStart={() => {
            toggleMarkerDragging?.(true);
          }}
          //TODO проработать дальше polylineDataIDs должен хранить ссылку на сам элемент
          onDragEnd={(dragData: Dragging, target: HTMLElement) => {
            if (map.current?.Map?.getContainer()?.contains?.(target) && target !== map.current?.Map?.getContainer()) {
              onDragEnd(dragData as Dragging, polylineDataIDs.current);
            } else {
              onMouseLeave(target);
              onDragEnd(dragData as Dragging, undefined);
            }
          }}
          dragData={{
            ...dragging,
            tripStatus: dragMaxMarkerStatus,
            tripIds: dragMarkerTripIDs
          }}
        >
          <div className={'position-relative container ' + (dragging.selected ? 'selected ' : '') + dragging.className}>
            <div className="drag" />
          </div>
        </DragDropContainer>
      </div>
    );
  }
);

export default ILSPlanningDragMarker;

