import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/documents/store';
import { currentDocumentRoutine } from '@modules/documents/actions';
import { Document } from '@modules/documents/types/document';
import { ICurrentDocument } from '@modules/documents/types/document-state';

export const currentDocument = createReducer(initialState, {
  [currentDocumentRoutine.TRIGGER]: (
    state,
    action: {
      payload: ICurrentDocument;
    }
  ) => {
    state.isDocumentFetching = !!action.payload.documentId;
    state.currentDocument = null;
    state.isViewDocumentModelOpen = action.payload.isOpenModal;
    return state;
  },
  [currentDocumentRoutine.SUCCESS]: (state, action: { payload: Document }) => {
    state.currentDocument = action.payload;
    return state;
  },
  [currentDocumentRoutine.FULFILL]: (state) => {
    state.isDocumentFetching = false;
    return state;
  }
});
