import { FC, MutableRefObject, useEffect, useState } from 'react';
import { TenderFormTableField } from '@modules/tender-platform/constants/content';
import { ITenderRotesFunctions, RoutesID } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { renderFormInputForRouteTenders } from '@modules/tender-platform/children/tender-own/utils/helprers/render-form-input-for-route-tenders';
import { TenderOwnFormTableRouteList } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/route-list';
import { renderInViewMode } from '@modules/tender-platform/children/tender-own/utils/helprers/render-in-view-mode';
import { UploadFile } from 'antd/lib/upload/interface';
import { renderNotEditable } from '@modules/tender-platform/children/tender-own/utils/helprers/render-not-editable';
import { useDispatch, useSelector } from 'react-redux';
import { replicateDocumentSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { approveReplicateDocumentRoutine } from '@modules/tender-platform/actions';

interface IProps {
  id: RoutesID;
  field: TenderFormTableField;
  editing: boolean;
  editable: boolean;
  value: string;
  handleSave: ITenderRotesFunctions['handleSave'];
  handleEditing: ITenderRotesFunctions['handleEditing'];
  onSetDataFiles: (routeId: RoutesID, file: UploadFile[]) => void;
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
}

export const TenderOwnFormTableVirtualInput: FC<IProps> = ({
  handleEditing,
  id,
  field,
  onSetDataFiles,
  handleSave,
  value,
  editing,
  editable,
  dataFiles
}) => {
  const dispatch = useDispatch();
  const isReplicateDocument = useSelector(replicateDocumentSelector);
  //NOTE fileList - для отображения файлов в режиме просмотра
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (isReplicateDocument && field === TenderFormTableField.Documents) {
      const firstRouteDocumentsKey = Object.keys(dataFiles.current)?.[0];
      if (firstRouteDocumentsKey) {
        const firstRouteDocuments = dataFiles.current[firstRouteDocumentsKey];
        onSetDataFiles(id, firstRouteDocuments);
        setFileList(() => firstRouteDocuments);
      }
    }
  }, [isReplicateDocument]);

  if (field === TenderFormTableField.RouteList) {
    return <TenderOwnFormTableRouteList id={id} />;
  }

  if (!editable) {
    return renderNotEditable({ field, value });
  }

  const toggleEdit = () => handleEditing({ id, field, editing: true });

  const onChangeFileList = (routeId: RoutesID, file: UploadFile[]) => {
    onSetDataFiles(routeId, file);
    setFileList(file);
    if (isReplicateDocument) {
      dispatch(approveReplicateDocumentRoutine({ replicateDocument: false }));
    }
  };

  return (
    <>
      {editing ? (
        <>{renderFormInputForRouteTenders({ field, handleSave, handleEditing, value, id, fileList, onChangeFileList })}</>
      ) : (
        <>{renderInViewMode({ field, value, toggleEdit, fileList })}</>
      )}
    </>
  );
};
