import { Moment } from 'moment';

export type DateTime = {
  date: string;
  timezone_type: number;
  timezone: string;
};

// Числа, но в данных бекенда выглядят по разному => нужны разные декораторы
export type Double = number; //(часть от 24-х часов)
export type DayFraction = number; //(дробная часть от 24-ех часов)
export type DayPart = number; //(часть суток)
export type TimeStamp = number;
export type DateString = string;
export type DateRangeFromTill = [string, string];

export type DateGeneral = Moment | TimeStamp | DateString;

export type DatePatch = Record<string, string>;

export interface DateFunction {
  datePatch(formatted: string): DatePatch;
}

export enum WeekDay {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export enum FormatType {
  Datetime = 'datetime',
  Time = 'time',
  Date = 'date'
}

export enum DateFormat {
  DDMMYYYYPeriod = 'DD.MM.YYYY',
  YYYYMMDDPeriod = 'YYYY.MM.DD',
  YYYYMMDDDash = 'YYYY-MM-DD',
  DDMMYYYYDash = 'DD-MM-YYYY',
  DDMMYYYYSlash = 'DD/MM/YYYY',
  YYYYMMDDSlash = 'YYYY/MM/DD',
  DaysCountdown = 'D д. H:mm:s',
  Duration = 'D д. HH:mm',
  Countdown = 'H:m:s',
  FIRST_MMYYYYDash = '01-MM-YYYY',
  MMYYYYPeriod = 'MM.YYYY',
  MMYYYYSlash = 'MM/YYYY',
  HHmmDDMMYYYYPeriod = 'HH:mm DD.MM.YYYY',
  YYYYMMDD = 'YYYYMMDD'
}

export enum DateSeparator {
  Period = '.',
  Slash = '/',
  Dash = '-',
  Space = ' '
}

export enum TimeFormat {
  HHMMSSColon = 'HH:mm',
  HHMMColon = 'HH:mm',
  HHmmssColon = 'HH:mm:ss',
  HHmmColon = 'HH:mm',
  HHMMDash = 'HH-mm',
  HHMMPeriod = 'HH.mm',
  HHMMSpace = 'HH mm',
  HHMMSlash = 'HH-mm',
  HHMMColonSpaceZ = 'HH:mm Z',
  H = 'H',
  HH = 'HH',
  S = 'S',
  SS = 'SS',
  mm = 'mm',
  HHMM = 'HHmm'
}

export enum DateTimeFormat {
  DDMMYYYYPeriodHHMMColonSpaceZ = 'DD.MM.YYYY HH:mm Z',
  DDMMYYYYPeriodHHMM = 'DD.MM.YYYY HH:mm',
  YYYYMMDDDashHHMM = 'YYYY-MM-DD HH:mm',
  YYYYMMDDDashHHMMSS = 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDDDashHHMMS = 'YYYY-MM-DD HH:mm:s',
  HHmmssDMMYY = 'HH:mm:ss D.MM.YY',
  DMYPeriodHISColon = 'd.m.Y H:i:s',
  HHMMColonDDMDot = 'HH:MM DD.M',
  ISO8601 = '',
  YYYYMMDDDashHHMMColon = 'YYYYMMDDDashHHMMColon',
  YYYYMMDDDashHHMMSSColon = 'YYYYMMDDDashHHMMSSColon',
  YYYYMMDDHHMM = 'YYYYMMDDHHMM',
  YYYYMMDDHHMMSS = 'YYYYMMDDHHMMSS',
  UNIX = 'X',
  UNIXMillisecond = 'x'
}

export enum NotifyDurationInSecond {
  Infinity,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten
}

export const ILS_MAIN_API_DATE_TIME_FORMAT = DateTimeFormat.YYYYMMDDDashHHMM;
export const ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT = DateTimeFormat.DDMMYYYYPeriodHHMM;

export const ILS_MAIN_API_DATE_FORMAT = DateFormat.YYYYMMDDSlash;
export const ILS_MAIN_INTERFFACE_DATE_FORMAT = DateFormat.DDMMYYYYPeriod;

export const ILS_MAIN_API_TIME_FORMAT = TimeFormat.HHMMColon;
export const ILS_MAIN_INTERFFACE_TIME_FORMAT = TimeFormat.HHMMColon;

export const ILS_LOT_API_DATE_FORMAT = DateFormat.YYYYMMDDDash;

export const UNIX_TIME_STAMP_CHARS = 11;
