import { ILSAppRegistry } from '../index';
import { IModuleModel, TModulesObj, TTranslateModel } from '@core/types/models';
import { FC } from 'react';
import { Store } from 'redux';
import { ICoreState } from '@core/types/store';
import permissions from '../abac/permissions';
import rules from '../abac/rules';
import { UserAuth } from '@common/types';
import { plannerPermissions } from '@modules/planning/abac/permissions';
import { REACT_APP_MODULES_EXCLUDE_KEY } from '@core/constants/env-variables';
import { AppRoles } from '@common/types/general/general';

const composeModuleDefinition = (module: IModuleModel): IModuleModel => ({
  title: module.title,
  path: module.path ?? null,
  exact: module.exact ?? false,
  component: module.component ?? null,
  sagas: module.sagas ?? [],
  reducer: module.reducer ?? null,
  children: module.children ?? {},
  inScope: module.inScope ?? [],
  useScope: module.useScope ?? [],
  icon: module.icon ?? null,
  api: module.api ?? {},
  lang: module.lang ?? null,
  deps: module.deps ?? null,
  permission: module.permission ?? null,
  access: module.access ?? null,
  persist: module.persist ?? null
});

const localizeModule = (module: IModuleModel, store: Store<ICoreState>) => {
  const { fixed } = store.getState();
  const currentLanguage = fixed!.LangSwitch!.currentLanguage;

  if (module.lang) {
    module.title = module.lang[currentLanguage as keyof TTranslateModel].moduleTitle;
    return module;
  }

  return module;
};

export const defineModule = (module: IModuleModel | IModuleModel[]): IModuleModel | IModuleModel[] => {
  return Array.isArray(module) ? module.map((item) => composeModuleDefinition(item)) : composeModuleDefinition(module);
};

export const flatModules = (modules: TModulesObj): IModuleModel[] => {
  return Object.entries(modules)
    .map(([key, value]) => {
      const res = Array.isArray(value) ? value : [value];
      res.forEach((module) => (module['MODULE'] = key));
      return res;
    })
    .reduce((acc, moduleArr) => acc.concat(moduleArr), []);
};
export const allModules = (modules: TModulesObj, store: Store<ICoreState>): IModuleModel[] => {
  return Object.keys(modules)
    .map((key) => {
      const res = (Array.isArray(modules[key]) ? modules[key] : [modules[key]]) as IModuleModel[];

      res.forEach((module) => {
        localizeModule(module, store);
        const children = module.children;

        if (children && Object.keys(children).length) {
          const parentPath = module.path!;
          const parentTitle = module.title;

          for (const child in children) {
            localizeModule(children[child], store);
            const fullPath = parentPath + children[child].path;
            const fullTitle = parentTitle !== children[child].title ? parentTitle + ': ' + children[child].title : children[child].title;
            res.push({
              ...children[child],
              fullPath: fullPath,
              fullTitle: fullTitle,
              MODULE: child
            });
          }
        } else {
          res.forEach((module) => {
            module['MODULE'] = key;
          });
        }
      });

      return res;
    })
    .reduce((acc, moduleArr) => acc.concat(moduleArr), []);
};

const isModuleActive = (moduleName: string, modulesArray?: Array<string>) => {
  if (!modulesArray) return true;
  if (!modulesArray.length) return true;
  return !modulesArray.some((x) => x.trim() === moduleName);
};

/**
 * Функция возвращает все модули за исключением,  тех что переданы в исключения*/
export const getActiveModules = (modules: TModulesObj, exceptions?: string | null): TModulesObj => {
  const modulesArray = exceptions?.split(',');
  return Object.keys(modules).reduce((activeModules, moduleName) => {
    if (isModuleActive(moduleName, modulesArray)) {
      activeModules[moduleName] = modules[moduleName];
    }
    return activeModules;
  }, {});
};
export const getModules = (modules: TModulesObj): TModulesObj => {
  const modulesExceptions = process.env[REACT_APP_MODULES_EXCLUDE_KEY];
  const activeModules: TModulesObj = getActiveModules(modules, modulesExceptions);
  return Object.entries(activeModules)
    .filter(([_, value]) => {
      return 'undefined' !== typeof value;
    })
    .reduce((acc: TModulesObj, [key, _]) => {
      acc[key] = modules[key];
      return acc;
    }, {});
};

export const isModuleAvailable = (moduleName: string, apiName: string): FC<any> | false => {
  const { modules }: { modules: IModuleModel[] } = ILSAppRegistry;
  const module = modules.filter((module) => module.MODULE === moduleName && module);
  const moduleApi = module.filter((item) => item.api!.hasOwnProperty(apiName));
  const api = moduleApi && moduleApi[0] && moduleApi[0].api;

  if (!module.length || !api || !api[apiName]) return false;
  return api[apiName];
};

export const getModulesAccess = (
  user: UserAuth,
  projects: boolean
): {
  rules: {};
  roles: AppRoles;
  permissions: {};
} => {
  let allRules = {
    ...rules
  } as object;
  let allPermissions = { ...permissions };
  let roles: any[] = [];
  if (user.UserRole) {
    roles = [...roles, ...user.UserRole];
  }
  if (user?.Account?.AccountRole) {
    roles = [...roles, ...user.Account.AccountRole];
  }

  const { modules }: { modules: IModuleModel[] } = ILSAppRegistry;

  modules.forEach((module) => {
    if (module.access) {
      allPermissions = { ...allPermissions, ...module.access.permissions };
      if (module.access.rules) {
        for (let rule in module.access.rules) {
          allRules[rule] = {
            ...allRules[rule],
            ...module.access.rules[rule],
            [plannerPermissions.PLANNER_SUBMODULES]: projects,
            [plannerPermissions.PLANNER_NEW]: !projects
          };
        }
      }
    }
  });

  return {
    rules: allRules,
    roles: roles,
    permissions: allPermissions
  };
};
