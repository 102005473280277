import { analyticsGetBoardSetsRoutineFactory } from '@modules/analytics/common/sagas/get-board-sets';
import { analyticsGetBoardWidgetRoutineFactory } from '@modules/analytics/common/sagas/get-board-widget';
import { analyticsGetBoardsRoutineFactory } from '@modules/analytics/common/sagas/get-boards';
import { analyticsGetWidgetDataRoutineFactory } from '@modules/analytics/common/sagas/get-widget-data';
import { analyticsUpdateWidgetPositionRoutineFactory } from '@modules/analytics/common/sagas/update-widget';
import { analyticsPlanningActions } from '../actions';
import { AnalyticsPlanningAPI } from '../api';
import { getBoardsSuccessCommonWatcher } from '@modules/analytics/common/sagas/get-boards-success';
import { getBoardSetsSuccessCommonWatcher } from '@modules/analytics/common/sagas/get-board-sets-success';
import { selectBoardSetCommonWatcher } from '@modules/analytics/common/sagas/select-board-set';
import { selectBoardSetSuccessCommonWatcher } from '@modules/analytics/common/sagas/select-board-set-success';

import { selectBoardCommonWatcher } from '@modules/analytics/common/sagas/select-board';
import { selectBoardSuccessCommonWatcher } from '@modules/analytics/common/sagas/select-board-success';
import { setWidgetParamsWatcher } from '@modules/analytics/common/sagas/set-params';
import { analyticsCancelWidgetUpdatesRoutineFactory } from '@modules/analytics/common/sagas/cancel-widgets-update';
import { commonWatcher } from './common-saga';

export { analyticsWidgetCatalogWatcher } from './widget-catalog';
export { analyticsDeleteBoardSetWatcher } from './delete-board-set';
export { analyticsDeleteBoardWatcher } from './delete-board';
export { analyticsSetBoardWidgetWatcher } from './set-board-widget';
export { analyticsSetBoardWidgetBatchWatcher } from './set-board-widget-batch';
export { analyticsMarkBoardWidgetWatcher } from './mark-board-widget';
export { analyticsDropBoardWidgetWatcher } from './drop-board-widget';
export { analyticsDeleteBoardWidgetWatcher } from './delete-board-widget';

export const updateWidgetPosition = analyticsUpdateWidgetPositionRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);

//получение данных виджета
export const analyticsGetWidgetDataWatcher = analyticsGetWidgetDataRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);
//получение досок
export const analyticsGetBoardsWatcher = analyticsGetBoardsRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);
export const analyticsGetBoardSets = analyticsGetBoardSetsRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);
//после получения досок
export const getBoardsSuccessCommon = getBoardsSuccessCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
//после получения набора досок
export const getBoardSetsSuccessCommon = getBoardSetsSuccessCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
//после выборан набора досок
export const selectBoardSetSuccessCommon = selectBoardSetSuccessCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
export const selectBoardCommon = selectBoardCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
//выбор набора досок
export const selectBoardSetCommon = selectBoardSetCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
//после выбора доски
export const selectBoardSuccessCommon = selectBoardSuccessCommonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);
//виджеты для доски
export const getBoardWidgets = analyticsGetBoardWidgetRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);

// установка параметров
export const tenderSetParams = setWidgetParamsWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);

export const common = commonWatcher(AnalyticsPlanningAPI, analyticsPlanningActions);

//отмена сохранения доски
export const cancelWidgetsUpdates = analyticsCancelWidgetUpdatesRoutineFactory(AnalyticsPlanningAPI, analyticsPlanningActions);
//notifier
export { analyticsPlanningNotifierWatcher } from './notifier';
