import React, { FC, MouseEvent, useContext, useState } from 'react';
import '../styles.less';
import ILSRoadGraphTestMapContainer from '@modules/road-graph/route-test/containers/map-container';
import { RoadGraphContext } from '../context';
import ILSRoadGraphTestButtons from '@modules/road-graph/route-test/components/buttons';
import ILSRoadGraphTestSummaryInfo from '@modules/road-graph/route-test/components/summary';
import { ReactSVG } from 'react-svg';

const ILSRoadGraphTestButtonsContainer: FC = () => {
  const { trackMarker } = useContext(RoadGraphContext) ?? {};

  const [helperPosition, setHelperPosition] = useState<{ left: number; top: number } | undefined>(undefined);

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setHelperPosition({
      left: e.pageX - 174,
      top: e.pageY - 140
    });
  };

  return (
    <div className="road-graph-content" onMouseMove={handleMouseMove}>
      <ILSRoadGraphTestMapContainer />
      <ILSRoadGraphTestButtons />
      <ILSRoadGraphTestSummaryInfo />
      {(trackMarker?.A.moving || trackMarker?.B.moving) && (
        <div
          className="marker-helper"
          style={{
            left: helperPosition?.left,
            top: helperPosition?.top
          }}
        >
          <div className="custom-marker text">
            <ReactSVG src={'/assets/icons/road-graph/marker-helper.svg'} />
            <span>{trackMarker?.A.moving ? 'A' : 'B'}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ILSRoadGraphTestButtonsContainer;
