import { call, put, takeEvery } from 'redux-saga/effects';
import { documentsGetTemplatesRoutine } from '@modules/documents/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { Documents } from '@modules/documents/api';

function* documentTemplatesGetWorker(action: PayloadAction<{ type?: string; projectId?: number }>) {
  const { request, success, failure, fulfill } = documentsGetTemplatesRoutine;
  const { type, projectId } = action.payload;
  try {
    yield put(request());

    const { data } = yield call(Documents.indexTemplates, { type, projectId });

    yield put(success({ data: Object.values(data.data) }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* documentsTemplateGetWatcher() {
  yield takeEvery(documentsGetTemplatesRoutine.trigger, documentTemplatesGetWorker);
}
