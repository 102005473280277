import { getInboxSendDocumentsRoutine } from '@modules/documents/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { DocumentsType } from '@modules/documents/types/document-state';

export function* getInboxSendDocumentsWorker({ payload }: { payload: { type: DocumentsType } }) {
  const { request, success, failure, fulfill } = getInboxSendDocumentsRoutine;

  try {
    yield put(request());

    let response = '';

    switch (payload.type) {
      case DocumentsType.Inbox:
        response = 'getReceivedDocuments';
        break;
      case DocumentsType.Send:
        response = 'getSentDocuments';
        break;
    }

    const { data } = yield call(Documents[response]);

    yield put(success(data));
  } catch (e) {
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export function* getInboxSendDocumentsWatcher() {
  yield takeLatest(getInboxSendDocumentsRoutine.trigger, getInboxSendDocumentsWorker);
}

