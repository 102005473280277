import { IDecorateValidationData, Notify, TableColumnName, TableRecord, ValidationData } from '@common/types';
import { ValidationKind } from '@common/types/dictionaries/validation';
import ReactDOMServer from 'react-dom/server';
import { ERROR_TABLE_DICTIONARY, LAST_TABLE_ROW } from '../../constants/validation';
import { CommonErrorTip } from '../../components/result/tips/common-error-tip';

export const getErrorByTypes = (errors: ValidationData[] | null) => {
  const resultErrors: Record<string, ValidationData[]> = {
    error: [],
    warning: [],
    bad: [],
    info: []
  };

  errors?.forEach((error) => {
    const { Kind_ } = error;
    switch (Kind_) {
      case ValidationKind.bad:
        resultErrors.bad.push(error);
        break;
      case ValidationKind.error:
        resultErrors.error.push(error);
        break;
      case ValidationKind.warning:
        resultErrors.warning.push(error);
        break;
      case ValidationKind.info:
        resultErrors.info.push(error);
        break;
    }
  });

  return resultErrors;
};

export const countErrorsByType = (errors: ValidationData[] | null) => {
  const resultErrors: Record<string, number> = {
    error: 0,
    warning: 0,
    bad: 0,
    info: 0
  };

  errors?.forEach((error) => {
    const { Kind_ } = error;
    switch (Kind_) {
      case ValidationKind.bad:
        resultErrors.bad += Number(error[Kind_ + '_']);
        break;
      case ValidationKind.error:
        resultErrors.error += Number(error[Kind_ + '_']);
        break;
      case ValidationKind.warning:
        resultErrors.warning += Number(error[Kind_ + '_']);
        break;
      case ValidationKind.info:
        resultErrors.info += Number(error[Kind_ + '_']);
        break;
    }
  });
  return resultErrors;
};

export const getFail = (errors: ValidationData[]) => {
  let resultFail: { failKeys: any[]; failFieldName: TableColumnName | null } = {
    failKeys: [],
    failFieldName: null
  };

  errors?.forEach((error) => {
    if (error.FailKeys) {
      for (let code of error.FailKeys) {
        if (!resultFail.failKeys.includes(code)) {
          resultFail.failKeys.push(code);
        }
      }
    }
    if (error.FailFieldName) {
      resultFail.failFieldName = error.FailFieldName;
    }
    if (!error.FailFieldName && error.FailKeys) {
      resultFail.failFieldName = TableColumnName.ID;
    }
  });
  return resultFail;
};

export const errorToTable = (
  data: IDecorateValidationData | null,
  handleClick: (record: TableRecord, dataIndex: TableColumnName) => void
): any[] => {
  const resultData: any = [];
  let lastElem: any = null;
  data &&
    Object.keys(data).forEach((key) => {
      if (ERROR_TABLE_DICTIONARY[key]) {
        const { error, warning, bad, info } = countErrorsByType(data[key]);
        const { error: Errors, warning: Warnings, bad: Bad, info: Info } = getErrorByTypes(data[key]);

        const newData = {
          Name: ERROR_TABLE_DICTIONARY[key],
          dictionary: key,
          error,
          warning,
          bad,
          info,
          [Notify.Error + '_htmltip']: ReactDOMServer.renderToString(<CommonErrorTip dataSource={Errors} />),
          [Notify.Bad + '_htmltip']: ReactDOMServer.renderToString(<CommonErrorTip dataSource={Bad} />),
          [Notify.Info + '_htmltip']: ReactDOMServer.renderToString(<CommonErrorTip dataSource={Info} />),
          [Notify.Warning + '_htmltip']: ReactDOMServer.renderToString(<CommonErrorTip dataSource={Warnings} />),
          handleClick
        };
        resultData.push(newData);
      }
      if (LAST_TABLE_ROW[key]) {
        lastElem = {
          Name: LAST_TABLE_ROW[key],
          dictionary: key,
          ...data[key]
        };
      }
    });

  if (lastElem) {
    resultData.push(lastElem);
  }

  return resultData;
};
