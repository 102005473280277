import { call, put, takeLatest } from 'redux-saga/effects';
import { createOrderRoutine, getTotalCountRoutine, getTripNOrderDataRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { RequestOrderData } from '@modules/orders-and-trips/types/form';
import { FormType } from '@modules/orders-and-trips/types/general';

function* createOrderWorker(
  action: PayloadAction<{
    orderData: RequestOrderData;
  }>
) {
  const { request, success, failure, fulfill } = createOrderRoutine;
  const { orderData } = action.payload ?? {};
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.addUnallocatedOrder, orderData);
    yield put(success({ order: data }));
    const newOrderID = Object.keys(data ?? {})?.[0];
    if (newOrderID) {
      yield put(getTripNOrderDataRoutine.trigger({ ids: [newOrderID], formType: FormType.UpdateOrder }));
      yield put(getTotalCountRoutine.trigger());
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* createOrderWatcher() {
  yield takeLatest(createOrderRoutine.trigger, createOrderWorker);
}

