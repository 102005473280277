import { MATH } from '@modules/catalog/constants/tariff';

export function calcFormula(formula: string): { check: boolean; message: string } {
  try {
    return {
      check: true,
      message: MATH.evaluate(formula)?.toString()
    };
  } catch {
    return {
      check: false,
      message: 'Невозможно рассчитать формулу. Проверьте правильность написания формулы'
    };
  }
}

