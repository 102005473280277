import { createSelector } from 'reselect';
import { filterTendersByStatus } from '@modules/tender-platform/utils/decorators/filter-tenders-by-status';
import { TenderStatus } from '@modules/tender-platform/types';
import { ListTab } from '@modules/tender-platform/constants/tender-card';
import { tendersSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';

export const tenderByStatusSelector = createSelector(tendersSelector, (tenders) => {
  const newTenders = filterTendersByStatus([TenderStatus.New], tenders);
  const onApprovalTenders = filterTendersByStatus([TenderStatus.OnApproval], tenders);
  const approvedTenders = filterTendersByStatus([TenderStatus.Approved], tenders);
  const underRevisionTenders = filterTendersByStatus([TenderStatus.UnderRevision], tenders);
  const collectingOffersTenders = filterTendersByStatus([TenderStatus.CollectingOffers], tenders);
  const summingResultsTenders = filterTendersByStatus([TenderStatus.SummingResults], tenders);
  const approvalResultsTenders = filterTendersByStatus([TenderStatus.ApprovalResults], tenders);
  const executedTenders = filterTendersByStatus([TenderStatus.Executed], tenders);
  const cancelledTenders = filterTendersByStatus([TenderStatus.Cancelled], tenders);
  const completedTenders = filterTendersByStatus([TenderStatus.Completed], tenders);
  const notTakeTenders = filterTendersByStatus([TenderStatus.NotTake], tenders);
  return {
    [ListTab.Tenders]: tenders,
    [ListTab.New]: newTenders,
    [ListTab.OnApproval]: onApprovalTenders,
    [ListTab.UnderRevision]: underRevisionTenders,
    [ListTab.Approved]: approvedTenders,
    [ListTab.CollectingOffers]: collectingOffersTenders,
    [ListTab.SummingResults]: summingResultsTenders,
    [ListTab.ApprovalResults]: approvalResultsTenders,
    [ListTab.Executed]: executedTenders,
    [ListTab.Completed]: completedTenders,
    [ListTab.Cancelled]: cancelledTenders,
    [ListTab.NotTake]: notTakeTenders
  };
});
