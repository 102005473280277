import { useEffect, useState } from 'react';
import { Dictionary, Driver } from '@common/types';

export const useSelectedRows = ({
  data,
  dictionary,
  handleCustomCheckboxOnChange
}: {
  data: any;
  dictionary: Dictionary;
  handleCustomCheckboxOnChange?(selected: Array<object>): void;
}) => {
  const [selectedRows, setSelectedRows] = useState<Array<number>>([]);
  const onSelectRows = (newSelectedRows: number[]) => {
    if (dictionary === Dictionary.Driver) {
      const selected = data.filter(({ ID }: Driver) => newSelectedRows.includes(ID));
      handleCustomCheckboxOnChange?.(selected);
    }
    setSelectedRows(newSelectedRows);
    return newSelectedRows;
  };

  useEffect(() => {
    if (dictionary === Dictionary.Driver) {
      const selected = data.filter(({ ID }: Driver) => selectedRows.includes(ID));
      handleCustomCheckboxOnChange?.(selected);
    }
  }, [data]);

  return { selectedRows, onSelectRows };
};

