import './styles.less';
import { Link, useLocation } from 'react-router-dom';
import { FC } from 'react';
import { ModulePath } from '@common/types';
import { ILSDivider, ILSTypographyText } from '@common/components';
import { ReactSVG } from 'react-svg';
import { getSRCIconSVG } from '@common/decorators/path';

export const ILSLogoFull: FC<{ unAuthorizedPages?: boolean }> = ({ unAuthorizedPages }) => {
  const { pathname } = useLocation();
  const to = !unAuthorizedPages ? ModulePath.OrdersNTrips : ModulePath.LogisticServices;

  return (
    <Link className="logo" to={to}>
      <ReactSVG className={'logo-image'} src={getSRCIconSVG('logo')} />
      <ILSDivider type={'vertical'} className="logo-divider" />
      {pathname === ModulePath.OrdersNTrips && !unAuthorizedPages && (
        <>
          <ILSTypographyText className={'logo-text'} children={'Поддержка +7 (968)322 70 73'} />
          <ILSDivider type={'vertical'} className="logo-divider" />
        </>
      )}
    </Link>
  );
};

