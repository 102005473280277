import { ChangeEvent, CSSProperties, Dispatch, FocusEvent, KeyboardEvent, ReactElement, RefObject, SetStateAction } from 'react';
import {
  ColumnTable,
  DateFormat,
  DateTimeFormat,
  DictionariesInfo,
  Dictionary,
  DragInfo,
  FilterSort,
  IILSTableComponentProps,
  ITablePropsFunction,
  Options,
  Reference,
  ReferenceTableData,
  TableColumnName,
  TimeFormat
} from '@common/types';
import { CellRecord, SetShowReference, TableRecord } from '@common/types/components/table/common';
import { DatePickerProps, InputProps, ModalProps, TooltipProps } from 'antd';
import { CascaderProps, SelectProps } from 'antd/es';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { IILSInputNumberProps } from '@components/data-entry/input/input-number';
import { IDatePickerProps } from '@components/data-entry/datepicker';
import { IILSAlertProps } from '@components/feedback/alert';
import { TextProps } from 'antd/lib/typography/Text';

export interface ICellProps<R extends TableRecord> extends Omit<IEditableCell<R>, 'children'>, Omit<INotEditableCell<R>, 'children'> {
  children?: CellChildren;
  fixed?: 'left' | 'right' | boolean;
  title: string; // todo избавиться от string
  record: R;
  reference?: Reference<R> | ((record: R) => Reference<R>);
  error?: { Name: string; Type: any }[];
  rowIndex: number | undefined;
  tooltip: TooltipProps;
  dragInfo: DragInfo | null;
  setShowReference: SetShowReference<R>;
  cellClassName: ITablePropsFunction<R>['cellClassName'];
  className?: string;
  showParent?: boolean;
}

export type CellChildren = [
  ReactElement | undefined,
  string | number | null | ReactElement | boolean | Array<any> | object | undefined | Date
];

export interface ICell<R> {
  children?: CellChildren;
  fieldNames?: SelectProps['fieldNames'] | CascaderProps<any>['fieldNames'];
  options?: IILSSelectProps['options'];
  rowIndex: number | undefined;
  editable?: boolean;
  columnKey?: string;
  alt?: string;
  record: R;
  style?: CSSProperties;
  ellipsis?: boolean;
  dataIndex: ColumnTable<R>['dataIndex'];
  type: CellType;
  /** Формат, в котором нужно отобразить данные в интерфейсе*/
  format?: DateFormat | TimeFormat | DateTimeFormat;
  /** Формат, с которого нужно преобразовать данные с бека */
  dateParseFormat?: DateFormat | TimeFormat | DateTimeFormat;
  handleSave: ITablePropsFunction<R>['handleSave'];
  showParent?: boolean;
}

export interface ICellInputValidationParameters {
  replacer: { from: RegExp; to: string };
  forbiddenKeys: RegExp;
  maxLength: number;
  max: number;
  min: number;
  step: number;
}

export interface IEditableCell<R> extends Omit<ICell<R>, 'children'> {
  children: CellChildren;
  dictionaryLoad?: ITablePropsFunction<R>['dictionaryLoad'];
  dictionaryInfo?: DictionariesInfo;
  validationParameters?: Partial<ICellInputValidationParameters>;
  selectProps?: Partial<ICellSelectProps<R>>;
  minField?: TableColumnName;
  maxField?: TableColumnName;
  activeCell?: string | null;
  allowClear?: boolean;
  dictionary: Dictionary;
  placeholder?: string;
  tableRef?: RefObject<HTMLDivElement> | null | undefined;
}

export interface INotEditableCell<R> extends ICell<R> {
  children: CellChildren;
  lineSeriesProps?: { size: number; gap: number };
  countdown?: { text: string };
  activeCell?: string | null;
  column?: ColumnTable<R>;
}

export enum FilterCellType {
  String = 'string',
  Array = 'array',
  Cascader = 'cascader'
}

export enum CellType {
  AddressAutocomplete = 'address-autocomplete',
  Alert = 'alert',
  Select = 'select',
  SelectIcon = 'select-icon',
  SelectAPI = 'select-api',
  SelectMultiple = 'select-multiple',
  SelectMultipleApi = 'select-multiple-api',
  String = 'string',
  Input = 'input',
  Int = 'int',
  Float = 'float',
  Color = 'color',
  Date = 'date',
  DateTime = 'datetime',
  Duration = 'duration',
  Time = 'time',
  RangeTime = 'rangeTime',
  /** Принимает любое значение, но ячейка приведёт любое значение к boolean.
   * Корректными значениями считаются null | undefined | 0 | 1 | true | false */
  Bool = 'bool',
  Phone = 'phone',
  /** Ячейка для отображения времени. Принимает в качестве значения строку вида "hh:mm" или "hh:mm:ss"*/
  DoubleRangeTimeString = 'duration-view',
  RegNumber = 'regNumber',
  Geo = 'geo',
  Text = 'text',
  Icon = 'icon',
  IconAction = 'icon-action',
  TextAction = 'text-action',
  Percent = 'percent',
  Email = 'email',
  Uint = 'uint',
  Ufloat = 'ufloat',
  TimeStringUtc = 'time-string-utc',
  Progress = 'progress',
  LineSeries = 'lineSeries',
  BadgeText = 'badge-text',
  Button = 'button',
  MenuButton = 'menu-button',
  Status = 'status',
  CascaderAPI = 'cascaderAPI',
  CascaderConfigAPI = 'cascaderConfigAPI',
  // Cascader = 'cascader',
  StringMultiple = 'string-multiple',
  DateTimeString = 'date-time-string',
  Countdown = 'countdown',
  ButtonClickableDropdown = 'buttonClickableDropdown',
  SelectIconAPI = 'select-icon-API',
  TimeDouble = 'time-double'
}

//TODO General types, not cell
export enum PlacementType {
  topLeft = 'topLeft',
  top = 'top',
  topRight = 'topRight',
  leftTop = 'leftTop',
  left = 'left',
  leftBottom = 'leftBottom',
  rightTop = 'rightTop',
  right = 'right',
  rightBottom = 'rightBottom',
  bottomLeft = 'bottomLeft',
  bottom = 'bottom',
  bottomRight = 'bottomRight'
}

export type EventBlurNFocus = KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement> & FocusEvent<HTMLInputElement>;

export type PropWithoutChildren<P> = Omit<P, 'children'>;
//TODO end

/** Cell editable props */
export interface IEditableCellProps<R> extends Pick<IEditableCell<R>, 'children'> {
  toggleEdit?(): void;

  save<T>(value: T, cellData?: CellRecord | CellRecord[]): void;
}

export type ICellWithDropdown<R> = Pick<IEditableCell<R>, 'tableRef'> & IEditableCellProps<R>;
export type ICellWithDropdownAPI<R> = ICellWithDropdown<R> & Pick<IEditableCell<R>, 'dictionaryInfo' | 'dictionary' | 'dictionaryLoad'>;

export interface ICellSelectProps<R> {
  options?: Options;
  referenceData: ReferenceTableData;
  dictionaryLoad: ITablePropsFunction<R>['dictionaryLoad'];
  dictionaryInfo: DictionariesInfo;
  dropdownMenuColumnStyle: CSSProperties & { maxColumnWidth?: string | number };
  showSearch: boolean;
}

export interface IILSCellTimeRangeProps<R extends TableRecord> extends IEditableCellProps<R>, Pick<IEditableCell<R>, 'format'> {}
export interface IILSCellRegNumberProps<R extends TableRecord> extends IEditableCellProps<R>, Pick<IEditableCell<R>, 'format'> {}

export interface IILSCellPhoneProps<R extends TableRecord> extends IEditableCellProps<R> {}
export interface ILSCellAddressAutocompleteIProps<R extends TableRecord> extends IEditableCellProps<R> {}
export interface IILSCellColorProps<R extends TableRecord> extends IEditableCellProps<R> {}
export interface IILSCellEmailProps<R extends TableRecord> extends IEditableCellProps<R> {}

export interface IILSCellBoolProps<R extends TableRecord> extends IEditableCellProps<R>, Pick<IEditableCell<R>, 'editable'> {}
export interface IILSCellSelectIconAPIProps<R extends TableRecord> extends IILSCellSelectApiProps<R> {
  editable: boolean;
}
export interface IILSCellSelectProps<R extends TableRecord> extends PropWithoutChildren<IILSSelectProps>, ICellWithDropdown<R> {}
export interface IILSCellSelectApiProps<R extends TableRecord> extends PropWithoutChildren<IILSSelectProps>, ICellWithDropdownAPI<R> {}

export interface IILSCellSelectMultipleProps<R extends TableRecord> extends PropWithoutChildren<IILSSelectProps>, ICellWithDropdown<R> {}
export interface IILSCellSelectMultipleApiProps<R extends TableRecord>
  extends PropWithoutChildren<IILSSelectProps>,
    ICellWithDropdownAPI<R> {}

export interface ILSCellCascaderApiIProps<R extends TableRecord> extends PropWithoutChildren<CascaderProps<any>>, ICellWithDropdownAPI<R> {}
export interface IILSCellConfigurableCascaderApiProps<R extends TableRecord>
  extends PropWithoutChildren<CascaderProps<any>>,
    ICellWithDropdownAPI<R> {}

export interface IILSCellDateProps<R extends TableRecord>
  extends PropWithoutChildren<IDatePickerProps & DatePickerProps>,
    IEditableCellProps<R>,
    Pick<IEditableCell<R>, 'minField' | 'maxField' | 'record' | 'dateParseFormat'> {
  showTime?: boolean;
}
export interface IILSCellTimeProps<R extends TableRecord>
  extends IEditableCellProps<R>,
    Pick<IEditableCell<R>, 'format' | 'dateParseFormat'> {
  double?: boolean;
}
export interface IILSCellNumberProps<R extends TableRecord> extends PropWithoutChildren<IILSInputNumberProps>, IEditableCellProps<R> {}

export interface IILSCellGeoProps<R extends TableRecord> extends IEditableCellProps<R>, Pick<IEditableCell<R>, 'record' | 'dataIndex'> {}
export interface IILSCellInputProps<R extends TableRecord>
  extends PropWithoutChildren<InputProps>,
    IEditableCellProps<R>,
    Pick<IEditableCell<R>, 'validationParameters'> {}

export interface IILSCellUintProps<R extends TableRecord>
  extends PropWithoutChildren<IILSInputNumberProps>,
    IEditableCellProps<R>,
    Pick<IEditableCell<R>, 'style'> {}

/** Cell static props */
export interface IILSCellNotEditableCellProps<R extends TableRecord> extends Pick<INotEditableCell<R>, 'children'> {}

export interface IILSCellAlertProps<R extends TableRecord>
  extends IILSCellNotEditableCellProps<R>,
    Pick<INotEditableCell<R>, 'record' | 'dataIndex' | 'activeCell'> {}

export interface IILSCellBoolStaticProps<R extends TableRecord> extends IILSCellNotEditableCellProps<R> {}

export interface IILSCellCountdownProps<R extends TableRecord> extends IILSCellNotEditableCellProps<R> {
  text?: string;
}

export interface IILSCellDateTimeStringProps<R extends TableRecord>
  extends IILSCellNotEditableCellProps<R>,
    Pick<IEditableCell<R>, 'format'> {
  showTime?: boolean;
}

export interface IILSCellIconProps<R extends TableRecord> extends IILSCellNotEditableCellProps<R>, Pick<INotEditableCell<R>, 'alt'> {}

export interface IILSCellIconActionProps<R extends TableRecord> extends Pick<INotEditableCell<R>, 'record' | 'dataIndex'> {}

export interface IILSCellLineSeriesProps<R extends TableRecord>
  extends IILSCellNotEditableCellProps<R>,
    Pick<INotEditableCell<R>, 'record' | 'columnKey' | 'lineSeriesProps'> {}

export interface IILSCellModalProps {
  title: ICellProps<any>['title'];
  content: ModalProps['children'];
  setShow: Dispatch<SetStateAction<boolean>>;
}

export interface IILSCellStatusProps<R extends TableRecord>
  extends IILSCellNotEditableCellProps<R>,
    Pick<INotEditableCell<R>, 'handleSave' | 'record' | 'dataIndex'> {}

export interface IILSCellTimeStringWithUTCProps<R extends TableRecord>
  extends IILSCellNotEditableCellProps<R>,
    Pick<INotEditableCell<R>, 'record' | 'ellipsis' | 'style'> {}

export interface IILSCellValidateAlertProps<R extends TableRecord> extends Pick<ICellProps<R>, 'error'>, IILSAlertProps {
  showCellValidateTip: boolean;

  setShowCellValidateTip(show: boolean): void;
}

/** Cell toggle props */
export interface IILSCellToggleProps<R> extends Pick<IEditableCellProps<R>, 'toggleEdit' | 'children'> {}

export interface IILSCellBoolStringProps<R extends TableRecord> extends IILSCellToggleProps<R> {}

export interface IILSCellCascaderStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<IEditableCell<R>, 'record' | 'dataIndex' | 'fieldNames' | 'options'> {
  //если record содержит в себе полный объект другого справочника
  showArrow?: boolean;
  showParent?: boolean;
}

export interface IILSCellColorStaticProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<IEditableCell<R>, 'record' | 'dataIndex'> {}

export interface IILSCellDateStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<IEditableCell<R>, 'format' | 'dateParseFormat'> {
  showTime?: boolean;
}

export interface IILSCellDurationStringProps<R extends TableRecord> extends IILSCellToggleProps<R> {}

export interface IILSCellGeoStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<INotEditableCell<R>, 'record' | 'dataIndex'> {}

export interface IILSCellSelectAPIStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<IEditableCell<R>, 'record' | 'dataIndex'> {
  showArrow?: boolean;
}

export interface IILSCellSelectStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<IEditableCell<R>, 'options' | 'fieldNames'> {
  icon?: boolean;
  showArrow?: boolean;
}

export interface IILSCellStringProps<R extends TableRecord> extends IILSCellToggleProps<R>, PropWithoutChildren<TextProps> {}

export interface IILSCellTimeRangeStringProps<R extends TableRecord> extends IILSCellStringProps<R> {}

export interface IILSCellTimeStringProps<R extends TableRecord>
  extends IILSCellToggleProps<R>,
    Pick<INotEditableCell<R>, 'dateParseFormat'> {
  double?: boolean;
}

/** Cell title props */
export interface IILSTitleCellProps<R extends TableRecord>
  extends Pick<IILSTableComponentProps<R>, 'onResizeStart' | 'dictionary' | 'config' | 'dataSource' | 'tableRef'> {
  column: ColumnTable<R>;
  sorted?: FilterSort['sorted'];
}

export interface IILSTitleLineSeriesComponentProps {
  rangeLeft: number;
  rangeRight: number;
  isDisabled: boolean;

  onChange?(e: any): void;
}
export interface IILSTitleLineSeriesContainerProps<R extends TableRecord>
  extends Pick<IILSTableComponentProps<R>, 'onResizeStart' | 'dictionary' | 'config' | 'dataSource' | 'tableRef'> {
  dataIndex: ColumnTable<R>['dataIndex'];
  sorted?: FilterSort['sorted'];
  lineSeriesProps: ColumnTable<R>['lineSeriesProps'];
  columnKey: ColumnTable<R>['key'];
  width: number;
}
