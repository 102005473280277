import { FC, MouseEvent } from 'react';
import { ILSButton } from '@common/components';
import { ILSTypographyTitle } from '@components/general/typography';
import { useSelector } from 'react-redux';
import { fetchSelector, projectIsFetchingSelector } from '@modules/planning/children/by-date/selectors/fetching';
import { PlannerPlanningValidationSummaryComponent } from '@modules/planning/children/by-date/components/validate/validation-summary';
import { preventDefaultStopPropagationForHandler } from '@common/utils/helpers/prevent-default-stop-propagation-for-handler';

interface IProps {
  setShowValidateContainer(val: boolean): void;
  onValidatePlan(): void;
  activePlanErrors:
    | 0
    | {
        error: number;
        warning: number;
        info: number;
        bad: number;
      }
    | null
    | undefined;
}

export const ILSPlanningValidateSummary: FC<IProps> = ({ setShowValidateContainer, activePlanErrors, onValidatePlan }) => {
  const isFetching = useSelector(fetchSelector);
  const projectIsFetching = useSelector(projectIsFetchingSelector);

  const startValidate = (e: MouseEvent) => {
    preventDefaultStopPropagationForHandler(e);
    onValidatePlan();
  };

  const showValidation = () => setShowValidateContainer(true);

  return (
    <ul className="validation-summary" onClick={showValidation}>
      <ILSTypographyTitle children={'Валидация'} level={5} />
      <PlannerPlanningValidationSummaryComponent validationData={activePlanErrors} isFetching={isFetching || projectIsFetching} />
      <ILSButton
        loading={isFetching || projectIsFetching}
        disabled={isFetching || projectIsFetching}
        children={'Обновить'}
        onClick={startValidate}
      />
    </ul>
  );
};
