import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import '../styles.less';
import { MAP_SIZES } from '@core/constants/map';
import { useMapInit } from '../hooks/map-init';
import { roadGraphGetSegmentsRoutine } from '@modules/road-graph/editor/actions';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { GeohashEncodePoints } from '@common/decorators/latlon-geohash';
import { geoHashSelector, roadDataSelector } from '@modules/road-graph/editor/selectors';
import { Marker } from '@modules/road-graph/route-test/types';
import { RoadGraphEditorContext } from '@modules/road-graph/editor/context';
import { depotsSelector } from '@modules/road-graph/common/selectors';
import { Depot } from '@common/types';
import { getRoadTypesForZoom, roadData2Track } from '@modules/road-graph/editor/decorators';
import { ActiveRoad } from '@modules/road-graph/editor/types';

const ILSRoadGraphEditorMapContainer: FC = () => {
  const { showDepots, setEdit, edit } = useContext(RoadGraphEditorContext) ?? {};
  const Map = useRef<any>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const geoHash = useAppSelector(geoHashSelector);
  const depotData = useAppSelector(depotsSelector);
  const roadData = useAppSelector(roadDataSelector);
  const dispatch = useAppDispatch();

  //инициализация карты
  useMapInit(Map);

  const changeBounds = () => {
    if (Map?.current) {
      const { _northEast, _southWest } = Map.current.getBounds();
      const zoom = Map.current.getZoom();
      if (!_northEast || !_southWest || !zoom) return;

      const roads = getRoadTypesForZoom(zoom);
      const points = GeohashEncodePoints([Object.values(_northEast), Object.values(_southWest)], 'GW');
      if (points !== geoHash) {
        if (!roads) return;
        dispatch(
          roadGraphGetSegmentsRoutine({
            points: points,
            roads: roads
          })
        );
      }
    }
  };

  const [mapChange, setMapChange] = useState(null);

  useEffect(() => {
    if (Map?.current) {
      Map.current.moveEndMap(setMapChange);
    }
  }, [Map]);

  useEffect(() => {
    changeBounds();
  }, [mapChange]);

  useEffect(() => {
    if (Map) {
      const depotMarkers: Marker[] = [];
      if (depotData?.length && showDepots) {
        depotData.forEach((depot: Depot) => {
          if (depot.LatLon) {
            depotMarkers.push({
              coords: depot.LatLon
              // pointData: depot['pointData'],
              // type: 'storage',
            });
          }
        });
      }
      Map.current.drawMarkers(depotMarkers);
    }
  }, [showDepots, depotData]);

  const onRouteClick = (record: ActiveRoad) => {
    setEdit && setEdit(record);
  };
  useEffect(() => {
    if (Map) {
      const tracks = roadData2Track(roadData, edit);
      Map.current.drawPolylines(tracks, onRouteClick, undefined, {
        color: '#D24747',
        weight: 5
      });
    }
  }, [roadData, edit]);

  return (
    <div className="map-container" ref={mapRef}>
      <div id="ils-map" style={{ ...MAP_SIZES }} />
    </div>
  );
};

export default ILSRoadGraphEditorMapContainer;
