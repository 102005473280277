import { IndexedArray } from '@common/types';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToTimeStringWithUTC } from '@common/utils';
import { TIMEZONE_OFFSET } from '@common/constants';
import { convertSecondsToTimeHhMm } from '@common/utils/helpers/date-time/convert-seconds-to-time-hh-mm';
import { MonitoringTripModel } from '@common/types/dictionaries/monitoring-trip';

export const countTripsTime = (trips: (MonitoringTripModel & { Waypoint: IndexedArray<MonitoringWaypointModel> })[]) => {
  let totalFactDriveTime: number = 0,
    totalFactStopsTime: number = 0,
    totalPlanDriveTime: number = 0,
    tripEndFact: number = 0,
    tripEndPlan: number = 0,
    tripBeginFact: number = 0,
    tripBeginPlan: number = 0,
    totalPlanTime: number = 0,
    totalPlanStopsTime: number = 0,
    totalFactTime: number = 0;

  for (const trip of trips) {
    if (trip?.TripBegin && (!tripBeginPlan || trip?.TripBegin < tripBeginPlan)) {
      tripBeginPlan = trip.TripBegin;
    }
    if (trip?.FactTimeBegin && (!tripBeginFact || trip?.FactTimeBegin < tripBeginFact)) {
      tripBeginFact = trip.FactTimeBegin;
    }

    if (trip?.TripEnd && (!tripEndPlan || trip?.TripEnd > tripEndPlan)) {
      tripEndPlan = trip.TripEnd;
    }
    if (trip?.FactTimeEnd && (!tripEndFact || trip?.FactTimeEnd > tripEndFact)) {
      tripEndFact = trip.FactTimeEnd;
    }

    if (trip?.TotalPlanStopsTime) {
      totalPlanStopsTime += trip.TotalFactStopsTime;
    }
    if (trip?.TotalFactStopsTime) {
      totalFactStopsTime += trip?.TotalFactStopsTime;
    }

    if (trip?.TotalPlanDriveTime) {
      totalPlanDriveTime += trip?.TotalPlanDriveTime;
    }
    if (trip?.TotalFactDriveTime) {
      totalFactDriveTime += trip?.TotalPlanDriveTime;
    }

    if (trip?.PlanDurationTotal) {
      totalPlanTime = trip?.PlanDurationTotal;
    }
    if (trip?.FactDurationTotal) {
      totalFactTime = trip?.FactDurationTotal;
    }
  }

  const TripBeginPlan = timestampToTimeStringWithUTC(tripBeginPlan, -TIMEZONE_OFFSET);
  const TripEndPlan = timestampToTimeStringWithUTC(tripEndPlan, -TIMEZONE_OFFSET);

  const TripBeginFact = timestampToTimeStringWithUTC(tripBeginFact, -TIMEZONE_OFFSET);
  const TripEndFact = timestampToTimeStringWithUTC(tripEndFact, -TIMEZONE_OFFSET);

  const TotalFactStopsTime = convertSecondsToTimeHhMm(totalFactStopsTime);
  const TotalPlanStopsTime = convertSecondsToTimeHhMm(totalFactStopsTime);

  const TotalPlanDriveTime = convertSecondsToTimeHhMm(totalPlanDriveTime);
  const TotalFactDriveTime = convertSecondsToTimeHhMm(totalFactDriveTime);

  const TotalPlanTime = convertSecondsToTimeHhMm(totalPlanTime);
  const TotalFactTime = convertSecondsToTimeHhMm(totalFactTime);

  return {
    TripBeginPlan,
    TripEndPlan,
    TripBeginFact,
    TripEndFact,
    TotalFactStopsTime,
    TotalPlanStopsTime,
    TotalPlanDriveTime,
    TotalFactDriveTime,
    TotalPlanTime,
    TotalFactTime
  };
};
