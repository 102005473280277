import { ILSButton } from '@common/components';
import { timestampToDateTimeString } from '@common/utils/helpers/date-time/date-time';
import { FC } from 'react';
import { TripItem } from '../../types/store';
import { DemandProjectTripComponent } from './demand-project-trip';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { FormatType } from '@common/types/general/date-time';

interface Props {
  projectID: number;
  projectDT: number;
  projectName: string;
  tripsLength: number;
  itemsToShow: TripItem[];
  handleClickShowMore: () => void;
  isVisibleButton: boolean;
}

// Компонент для отображения одного проекта в разделе "Заявки и рейсы"
export const DemandListItemProjectComponent: FC<Props> = ({
  projectID,
  projectDT,
  projectName,
  tripsLength,
  itemsToShow,
  handleClickShowMore,
  isVisibleButton
}) => {
  return (
    <div className="ils-demand ils-project-wrapper">
      <div className="ils-demand ils-demand-list-item" key={projectID}>
        <div className="ils-demand-list-item-wrapper">
          <div className="ils-demand-list-item-project-wrapper">
            <span className="ils-demand-project-background">Проект</span>
            <span className="ils-demand-project-created">{timestampToDateTimeString(projectDT, FormatType.Date)}</span>
            <span className="ils-demand-project-id">ID: "{projectID}"</span>
            <span className="ils-demand-project-name">"{projectName}"</span>
          </div>
          <span className="ils-demand-plan-name">Принятый план к исполнению:</span>
        </div>
      </div>

      {itemsToShow?.map((trip, index) => (
        <DemandProjectTripComponent tripItem={trip} key={trip.ID.toString()} index={index + 1} last={itemsToShow.length === index + 1} />
      ))}

      <div
        className={classNames(
          'ils-demand-trip-show-more-button-wrapper',
          isVisibleButton ? '' : 'ils-demand-trip-show-more-button-not-visible'
        )}
      >
        <ILSButton className="ils-demand-trip-show-more-button" type="ghost" onClick={handleClickShowMore}>
          <ReactSVG className="ils-demand svg-container" src={'/assets/icons/arrow-down.svg'} />
          Показать все {tripsLength}
          <ReactSVG className="ils-demand svg-container" src={'/assets/icons/arrow-down.svg'} />
        </ILSButton>
      </div>
    </div>
  );
};
