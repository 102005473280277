import { QuestionsHeader } from '@common/feature/questions/components/questions-header';
import { QUESTIONS_SECTIONS_TITLE } from '@common/feature/questions/constants/index';
import { QuestionsAndAnswersList } from '@common/feature/questions/components/questions-and-answers-list';

export const QuestionsAndAnswersContainer = () => {
  return (
    <section className="questions-section">
      <QuestionsHeader title={QUESTIONS_SECTIONS_TITLE.QUESTIONS_ANSWERS} />
      <div className="questions-section-content">
        <QuestionsAndAnswersList />
      </div>
    </section>
  );
};

