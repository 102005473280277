import { useAppSelector } from '@core/hooks';
import ILSMonitoringDateSelectComponent from '@modules/monitoring/components/date-select';
import { monitoringSettingsSelector } from '@modules/settings/selectors';
import { FC } from 'react';
import { ILSDashboardMetricsContainer } from '@modules/monitoring/children/dashboard/containers/dashboard-metrics';
import MonitoringSliderContainer from '../slider';

interface Props {
  handleDateSelect: (date: string[]) => void;
  handleRedirect: (name: string) => void;
}

const ILSMonitoringDeliveryComponent: FC<Props> = ({ handleDateSelect, handleRedirect }) => {
  const maxInterval = useAppSelector(monitoringSettingsSelector).parameters.monitoringMaxDateInvterval;

  return (
    <>
      <div className="">
        <ILSMonitoringDateSelectComponent handleDate={handleDateSelect} maxInterval={maxInterval} />
        <ILSDashboardMetricsContainer handleRedirect={handleRedirect} />
      </div>
      <div className="mt-5 mb-4">
        <h2>Текущая ситуация</h2>
        <div className="mt-2">
          <MonitoringSliderContainer />
        </div>
      </div>
    </>
  );
};

export default ILSMonitoringDeliveryComponent;

