import '../styles.less';
import { FC, useState } from 'react';
import { ModalProps } from 'antd';
import classnames from 'classnames';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { ILSNotEditableTypeCell } from './ils-cell-not-editable-types';
import { ILSCellTooltip } from './ils-cell-tooltip';
import { ILSReferenceCellButton } from './ils-cell-reference-button';
import { ILSCellModal } from './cell-types/static/ils-cell-modal';
import { DropTarget } from 'react-drag-drop-container';
import { useReference } from '../hooks/cell/use-reference';
import { ICellProps } from '@common/types/components/table/cell';
import { ILSTypeEditableCell } from '@components/data-display/table/components/ils-cell-editable-types';
import { ILSSVG } from '@components/custom/icons/svg';
import { ILSCellValidateAlert } from '@components/data-display/table/components/cell-types/static/ils-cell-validate-alert';
import { TableRecord } from '@common/types';
import { CELL_DEFAULT_ERROR, showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCell: FC<ICellProps<TableRecord> & {}> = ({
  fixed,
  title,
  setShowReference,
  error,
  tooltip,
  dragInfo,
  style = {},
  cellClassName,
  type,
  format,
  placeholder,
  dateParseFormat,
  children,
  record,
  dataIndex,
  activeCell,
  tableRef,
  alt,
  rowIndex,
  ellipsis,
  editable,
  dictionary,
  allowClear,
  handleSave,
  maxField,
  minField,
  countdown,
  columnKey,
  column,
  lineSeriesProps,
  validationParameters,
  selectProps,
  reference: ref,
  fieldNames,
  dictionaryInfo,
  dictionaryLoad,
  options,
  className,
  showParent = true
}) => {
  //Содержимое модального окна
  const [showCellModal, setShowCellModal] = useState<ModalProps['children']>(false);
  //Тултип ячейки
  const [showCellTip, setShowCellTip] = useState<boolean>(false);
  //Тултип валидации ячейки
  const [showCellValidateTip, setShowCellValidateTip] = useState<boolean>(false);
  const reference = useReference(ref, record);

  if (!children) {
    showUserAndDevError({ error: CELL_DEFAULT_ERROR });
    return <></>;
  }

  let childNode = editable ? (
    <ILSTypeEditableCell
      showParent={showParent}
      fieldNames={fieldNames}
      dictionaryInfo={dictionaryInfo}
      dictionaryLoad={dictionaryLoad}
      options={options}
      validationParameters={validationParameters}
      columnKey={columnKey}
      type={type}
      format={format}
      placeholder={placeholder}
      selectProps={selectProps}
      dateParseFormat={dateParseFormat}
      children={children}
      record={record}
      dataIndex={dataIndex}
      activeCell={activeCell}
      tableRef={tableRef}
      alt={alt}
      rowIndex={rowIndex}
      ellipsis={ellipsis}
      editable={editable}
      dictionary={dictionary}
      allowClear={allowClear}
      handleSave={handleSave}
      maxField={maxField}
      minField={minField}
    />
  ) : (
    <ILSNotEditableTypeCell
      showParent={showParent}
      fieldNames={fieldNames}
      options={options}
      rowIndex={rowIndex}
      lineSeriesProps={lineSeriesProps}
      columnKey={columnKey}
      type={type}
      format={format}
      children={children}
      record={record}
      dataIndex={dataIndex}
      activeCell={activeCell}
      countdown={countdown}
      alt={alt}
      ellipsis={ellipsis}
      handleSave={handleSave}
      style={style}
    />
  );

  //TODO refactor
  if (
    !isEmpty(error) ||
    (dataIndex && record?.[dataIndex + '_modal']) ||
    record?.[dataIndex + '_htmlmodal'] ||
    tooltip ||
    record?.[dataIndex + '_htmltip'] ||
    record?.[dataIndex + '_tip'] ||
    record?.[column?.tooltip?.field]
  ) {
    const onClick = () => {
      if (record?.[dataIndex + '_modal'] || record?.[dataIndex + '_htmlmodal']) {
        //модальное окно
        const content = record?.[dataIndex + '_htmlmodal'] ? (
          <div dangerouslySetInnerHTML={{ __html: record[dataIndex + '_htmlmodal'] }} />
        ) : (
          record?.[dataIndex + '_modal']
        );
        setShowCellModal(() => content);
      }
      if (tooltip || record?.[dataIndex + '_htmltip'] || record?.[dataIndex + '_tip'] || record?.[column?.tooltip?.field]) {
        setShowCellTip(false);
      }
      setShowCellValidateTip((prev) => (prev ? false : prev));
    };

    childNode = (
      <div style={{ display: 'contents' }} onClick={onClick}>
        {childNode}
      </div>
    );
  }

  //TODO если используем onCell проблемы с fixed, нужен рефакторинг
  const fixedRight = fixed === 'right';
  return (
    <td
      style={fixedRight ? { ...style, [fixed]: 0 } : style}
      data-target={dragInfo?.active && 1}
      className={classnames(className, cellClassName?.(record, Number(rowIndex), dataIndex), {
        'ant-table-cell-fix-right': fixedRight,
        'ant-table-cell-fix-right-first': fixedRight,
        'antd-td': !type
      })}
    >
      <ILSCellValidateAlert
        error={error}
        showCellValidateTip={Boolean(!showCellModal && showCellValidateTip)}
        setShowCellValidateTip={setShowCellValidateTip}
        message={
          <ILSCellTooltip
            dataIndex={dataIndex}
            record={record}
            showCellTip={Boolean(!showCellModal && showCellTip)}
            setShowCellTip={setShowCellTip}
            tooltip={tooltip}
            column={column}
          >
            <span className={cn('cell-wrapper', { reference })}>
              {dataIndex && record?.[dataIndex + '_icon'] && (
                <ILSSVG key={`${dataIndex}_icon`} className="cell-icon" icon={record[dataIndex + '_icon']} />
              )}
              {dataIndex && record?.[dataIndex + '_iconLabel'] && (
                <span key={`${dataIndex}_iconLabel`} className="cell-label" children={record[dataIndex + '_iconLabel']} />
              )}
              {childNode ?? children}
              {reference && (
                <ILSReferenceCellButton
                  disabled={!(column?.showRefButton || editable)}
                  reference={reference}
                  dataIndex={dataIndex}
                  //@ts-ignore TODO
                  errors={error}
                  handleSave={handleSave}
                  setShowReference={setShowReference}
                  record={record}
                  cellType={type}
                  recordDataIndex={dataIndex}
                />
              )}
              {showCellModal && <ILSCellModal title={title} content={showCellModal} setShow={setShowCellModal} />}
              {dragInfo?.active && (
                <DropTarget targetKey={String(dragInfo?.key)} onHit={(e) => dragInfo?.handler?.(e, record)}>
                  <div className="target" />
                </DropTarget>
              )}
            </span>
          </ILSCellTooltip>
        }
      />
    </td>
  );
};
