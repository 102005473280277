import { FC } from 'react';
import { ILSForm, ILSFormItem, ILSRate, ILSTextArea } from '@common/components';
import { TenderForm } from '@modules/tender-platform/types/platform';
import {
  RATE_CARRIER_FORM_ITEMS,
  RateCarrierFormItem,
  RateCarrierFormItemLabel
} from '@modules/tender-platform/children/tender-own/constants/content';
import { useDispatch } from 'react-redux';
import { EMPTY_STRING } from '@common/constants';
import { tenderOfferSetRatingRoutine } from '@modules/tender-platform/actions';
import { useAppSelector } from '@core/hooks';
import { useTenderPlatformPermissions } from '@modules/tender-platform/abac/hooks/permissions';
import { currentTenderAcceptedOfferSelector } from '@modules/tender-platform/selectors/current-tender';
import { deepEqual } from 'fast-equals';

export const ILSCarrierRateForm: FC = () => {
  const dispatch = useDispatch();
  const TenderOffer = useAppSelector(currentTenderAcceptedOfferSelector);
  const TenderOfferID = TenderOffer?.ID;
  const { canRate } = useTenderPlatformPermissions();

  const handleRate = (val: {}) => {
    if (!deepEqual(val, initialValues)) {
      dispatch(tenderOfferSetRatingRoutine({ ...val, TenderOfferID }));
    }
  };

  const initialValues = {
    [RateCarrierFormItem.CarrierRating]: TenderOffer?.CarrierRating?.RatingValue ?? 0,
    [RateCarrierFormItem.CarrierComment]: TenderOffer?.CarrierRating?.Comment ?? EMPTY_STRING,
    [RateCarrierFormItem.DriverRating]: TenderOffer?.DriverRating?.RatingValue ?? 0,
    [RateCarrierFormItem.DriverComment]: TenderOffer?.DriverRating?.Comment ?? EMPTY_STRING
  };

  return (
    <ILSForm
      initialValues={initialValues}
      className={'tender-platform-card-form tender-platform-card-form-rating'}
      onFinish={handleRate}
      name={TenderForm.Rate}
    >
      {RATE_CARRIER_FORM_ITEMS.map(({ rating, comment }) => (
        <fieldset>
          <ILSFormItem label={RateCarrierFormItemLabel[rating]} name={rating}>
            <ILSRate disabled={!canRate} allowClear />
          </ILSFormItem>
          <ILSFormItem
            label={RateCarrierFormItemLabel[comment]}
            name={comment}
            rules={[
              {
                required: true,
                message: `Пожалуйста, оцените ${
                  rating === RateCarrierFormItem.CarrierRating ? 'перевозчика' : 'водителя'
                } (оставьте свой отзыв)!`
              }
            ]}
          >
            <ILSTextArea disabled={!canRate} showCount />
          </ILSFormItem>
        </fieldset>
      ))}
    </ILSForm>
  );
};
