import { TimeStamp, User } from '@common/types';
import { DocFilesTypes } from '@modules/documents/constants/document-file-type';
import { IOption } from '@common/types/components/table/common';

export enum AccountRole {
  carrier = 'carrier',
  cargoOwner = 'cargoOwner',
  carrier_simple = 'carrier_simple',
  intermediary = 'intermediary'
}

export enum AccountRoleLabel {
  carrier = 'Перевозчик',
  cargoOwner = 'Грузовладелец',
  carrier_simple = 'Перевозчик-водитель (единоличный)',
  intermediary = 'Посредник'
}

export enum AccountDisabled {
  No,
  Yes
}

export enum AccountAgreement {
  CargoOwnerWithApprovedContract,
  CarrierWithApprovedContract,
  BothWithApprovedContract
}

export type AccountRoles = Array<AccountRole>;

export type AccountInfo = Partial<{
  City: string;
  AccountVAT: string;
  YurAddress: string;
  FactAddress: string;
  SourceCreator: string;
  INN: string;
  Phone?: string;
  BIK: string;
  Bank: string;
  BankAccount: string;
  KPP: string;
  CorrespondentAccount: string | null;
  OrganizationTypeID: number | null;
}>;

export enum AccountAttachmentSubject {
  Avatar = 'avatar',
  Stamp = 'stamp',
  Sign = 'sign'
}

export type AccountAttachment = {
  ID: number;
  Subject: AccountAttachmentSubject;
  UrlOriginal: string;
  UrlThumbnail: string;
  OriginalName: string;
  Extension: DocFilesTypes;
};

export type AccountModel = {
  ID: number;
  ParentID: number;
  Name: string;
  Description: string | null;
  Created: TimeStamp | null;
  LastUpdate: TimeStamp;
  Deleted: string | null;
  Disabled: AccountDisabled;
  DeletedBy: User['ID'] | null;
  AccountCode: string;
  Info?: AccountInfo | null;
  Attachments: Array<AccountAttachment> | [];
  AccountRole: AccountRoles;
  ProjectCount: number;
  Avatar?: string | null;
};

export type Account = AccountModel &
  Partial<{
    AgreementFlag: AccountAgreement | null;
    DeskID: number;
    PaymentTariffID: number;
    ManagerID: number | null;
    OrderStatusConfigID: number;
    TripStatusConfigID: number;
    Rating: number;
    User: Array<User>;
  }>;

export type AccountSimple = Pick<Account, 'ID' | 'Name' | 'Description' | 'Rating' | 'Info'>;

export type AccountRoleOption = IOption<AccountRole, AccountRoleLabel>;
export type AccountRoleOptions = Array<AccountRoleOption>;

