import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { monitoringActiveDashboardTabRoutine } from '../actions';
import { DashboardTables } from '../types';

type DashboardRequest = {
  activeTab: DashboardTables | undefined;
};

// Получает информацию, какой таб сейчас активный
function* monitoringActiveDashboardWorker(action: PayloadAction<DashboardRequest>) {
  const { success, failure, fulfill } = monitoringActiveDashboardTabRoutine;
  const { activeTab } = action.payload;

  try {
    yield put(success({ activeTab }));
  } catch (error) {
    yield put(failure({ error, activeTab }));
  } finally {
    yield put(fulfill({ activeTab }));
  }
}

export function* monitoringActiveDashboardWatcher() {
  yield takeLatest(monitoringActiveDashboardTabRoutine.trigger, monitoringActiveDashboardWorker);
}
