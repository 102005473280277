import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderOfferDeleteRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderSearch } from '@modules/tender-platform/children/tender-search/api';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { DictionariesEndpoint, Dictionary } from '@common/types';
import { TenderOfferAction } from '@modules/tender-platform/children/tender-search/types/offer';

function* tenderOfferDeleteWorker(action: TenderOfferAction) {
  const { request, failure, fulfill, success } = tenderOfferDeleteRoutine;

  try {
    yield put(request());
    yield call(TenderSearch.delete, action.payload);
    yield put(
      tenderChangeRoutine.trigger({
        operation: DictionariesEndpoint.Delete,
        dictionary: Dictionary.TenderOffer
      })
    );
    yield put(success({ message: `Вы удалены из участников данного тендера` }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderOfferDeleteWatcher() {
  yield takeEvery(tenderOfferDeleteRoutine.trigger, tenderOfferDeleteWorker);
}
