import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticsCommonAPI } from '../types/api';
import { GetBoardWidgetRequest } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';

export const analyticsGetBoardWidgetRoutineFactory = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<GetBoardWidgetRequest>) {
    const { payload } = action;
    const { request, success, failure, fulfill } = actions.analyticsGetBoardWidgetRoutine;
    try {
      yield put(request(payload));
      const { data } = yield call(api.getBoardWidget, payload);
      yield put(success({ data: data.data }));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsGetBoardWidgetRoutine.trigger, worker);
  }

  return watcher;
};
