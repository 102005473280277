import { ILSButton } from '@common/components/general/buttons';
import { FC } from 'react';
import { ILSButtonTooltip } from '@common/components';

type ClearFilterButtonProps = {
  isFetching: boolean;
  clearSorting: any;
  clearSortDisabled: boolean;
  withText?: boolean | undefined;
};

export const ILSClearSortButton: FC<ClearFilterButtonProps> = ({ withText, isFetching, clearSorting, clearSortDisabled }) => {
  if (withText) {
    return (
      <ILSButton loading={isFetching} className="clearSorting" onClick={clearSorting} disabled={clearSortDisabled}>
        Сбросить сортировку
      </ILSButton>
    );
  }
  return (
    <ILSButtonTooltip
      placement="bottom"
      title="Сбросить сортировку"
      disabled={clearSortDisabled}
      onClick={clearSorting}
      buttonClassName="ils-clear-sort"
      icon={'clear-sort'}
    />
  );
};
