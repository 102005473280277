import { EMPTY_ARRAY_DATA } from '@common/constants';
import { IndexedArray } from '@common/types';
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from '@common/types/general/date-time';
import { timestampToDatetimeString } from '@common/utils';
import { TenderAction, TenderOwnState } from '@modules/tender-platform/children/tender-own/types';
import { RoutesID, TenderDateTimeType } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { TENDER_FORM_TABLE_REQUIRED_FIELDS, TenderFormTableField } from '@modules/tender-platform/constants/content';
import { LotTemplate, Tender } from '@modules/tender-platform/types';
import { TenderRoute } from '@modules/tender-platform/types/tender';
import { validateCustomFieldValue } from '@modules/tender-platform/utils/helpers/form/validate-custom-field-value';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const tendersSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.tenders,
  (tenders: IndexedArray<Tender> | null) => tenders
);
export const lotTemplateListSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.templateList,
  (templateList: LotTemplate | undefined) => templateList
);

export const tenderActionSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.tenderAction,
  (tenderAction: TenderAction) => tenderAction
);

export const dataForGroupTenderCreationSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.dataForGroupTenderCreation,
  (dataForGroupTenderCreation) => dataForGroupTenderCreation
);

export const initialDataForGroupTenderCreationSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.initialDataForGroupTenderCreation,
  (initialDataForGroupTenderCreation) => initialDataForGroupTenderCreation
);
export const createDataForCreateTenderForRouteSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.initialDataForGroupTenderCreation,
  (initialDataForGroupTenderCreation) => {
    if (isEmpty(initialDataForGroupTenderCreation)) return initialDataForGroupTenderCreation;

    const dataForGroupTenderCreation = { ...initialDataForGroupTenderCreation };
    for (const routekey in dataForGroupTenderCreation) {
      const route = { ...dataForGroupTenderCreation[routekey] };
      for (const key of TenderDateTimeType) {
        if (route[key]) {
          route[key] = timestampToDatetimeString(route[key], false, ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT);
        }
      }
      dataForGroupTenderCreation[routekey] = route;
    }
    return dataForGroupTenderCreation;
  }
);

export const planIDSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.planID,
  (planID) => planID
);

export const replicateDocumentSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.replicateDocument,
  (replicateDocument) => replicateDocument
);

export const routesSelector = createSelector(dataForGroupTenderCreationSelector, (dataForGroupTenderCreation) => ({
  routes: !isEmpty(dataForGroupTenderCreation) ? Object.keys(dataForGroupTenderCreation) : EMPTY_ARRAY_DATA
}));

export const firstRouteValueByFieldSelector = (field: TenderFormTableField, id: RoutesID) =>
  createSelector(initialDataForGroupTenderCreationSelector, (initialDataForGroupTenderCreation) => {
    return initialDataForGroupTenderCreation?.[id]?.[field];
  });

export const dataRoutesEditingSelector = createSelector(routesSelector, ({ routes }) => {
  return routes.reduce((prev, id) => {
    prev[id] = {};
    for (const field in TenderFormTableField) {
      prev[id][field] = false;
    }
    return prev;
  }, {});
});

const validateIsRouteDone = (route: TenderRoute) => {
  const notValidFields = TENDER_FORM_TABLE_REQUIRED_FIELDS.filter((field) => {
    const value = route[field];
    return validateCustomFieldValue({ value, field });
  });
  return isEmpty(notValidFields);
};

export const validateIsRouteDoneSelector = (id: RoutesID) =>
  createSelector(initialDataForGroupTenderCreationSelector, (initialDataForGroupTenderCreation) => {
    const route = initialDataForGroupTenderCreation[id];
    const isRouteDone = validateIsRouteDone(route);
    return { isRouteDone };
  });

export const validateIsAllRoutesDoneSelector = createSelector(
  initialDataForGroupTenderCreationSelector,
  (initialDataForGroupTenderCreation) => {
    const routes = (Object.entries(initialDataForGroupTenderCreation) as Array<[RoutesID, TenderRoute]>).reduce((prev, [id, route]) => {
      prev[id] = validateIsRouteDone(route);
      return prev;
    }, {});
    return !Object.values(routes).every(Boolean);
  }
);
