import { Dragging, IndexedArray } from '@common/types';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { ILSMap } from '@core/containers/map';
import { MarkerItem, PolylineItem } from '@modules/planning/children/by-date/types';
import L, { DivIcon, MarkerOptions } from 'leaflet';
import { isEmpty } from 'lodash';
import { MouseEvent, MutableRefObject } from 'react';

export const getUnderMarker = (e: MouseEvent<HTMLElement>) => {
  const { x, y } = (e.target as HTMLElement)?.getBoundingClientRect();
  const elements = document.elementsFromPoint(e.pageX || x, e.pageY || y);

  let element: any;
  if (elements && elements.length) {
    elements.forEach((el) => {
      if (
        el.className.indexOf &&
        (el.className.indexOf('marker-order') > -1 ||
          el.className.indexOf('marker-point') > -1 ||
          el.className.indexOf('ils-marker-orders') > -1)
      ) {
        element = el;
      }
    });
  }
  return element;
};

export const dispatchMarkerEvent = (
  mapMarkers: MutableRefObject<Record<string, MarkerItem>>,
  dragging: Dragging,
  fireEventType: string,
  map: MutableRefObject<ILSMap | null>
) => {
  if (isEmpty(mapMarkers.current)) return;
  Object.keys(mapMarkers.current).forEach((markerKey) => {
    const mItem = mapMarkers.current[markerKey];
    if (!isEmpty(mItem.mark.data) && compareAsString(mItem.mark.data.ID, dragging.originalID)) {
      if (mItem.lMark?.getElement?.()) {
        mItem.lMark.fireEvent(fireEventType);
      } else if (fireEventType === 'click') {
        const parent = mItem.layer?.getVisibleParent(mItem.lMark) as L.MarkerCluster;
        dispatchClusterClick(parent, map);
      }
    }
  });
};
export const dispatchPolylineEvent = (
  mapPolylines: MutableRefObject<Record<string, PolylineItem>>,
  dragging: Dragging,
  fireEventType: string
) => {
  if (isEmpty(mapPolylines.current)) return;

  Object.keys(mapPolylines.current).forEach((trackKey) => {
    const mPItem = mapPolylines.current[trackKey];
    if (mPItem?.lMarker?.options) {
      let options = mPItem.lMarker.options as MarkerOptions & { data: any };

      const data = options.data;
      if (compareAsString(data.ID, dragging.originalID)) {
        mPItem.lMarker.fireEvent(fireEventType);
      }
    }
  });
};
export const findClusterLayerByChildIcon = (
  orderCluster: (L.MarkerClusterGroup & { _featureGroup?: { _layers?: IndexedArray<L.MarkerCluster & { _icon?: any }> } }) | undefined,
  icon: any | DivIcon
) => orderCluster?._featureGroup?._layers && Object.values(orderCluster._featureGroup?._layers).find((item) => item?._icon === icon);

export const dispatchClusterClick = (
  cluster:
    | (L.MarkerCluster & {
        _icon?: any;
      })
    | undefined,
  map: MutableRefObject<ILSMap | null>
) => {
  if (cluster) {
    const zoom = map.current?.getZoom();
    if (zoom && zoom >= 17) {
      cluster.spiderfy();
    } else {
      cluster.zoomToBounds();
    }
  }
};

