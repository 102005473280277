import { ICountry } from './types';

export const rusRegNumbers =
  /[\wАВЕКМНОРСТУХавекмнорстух{1}][\wАВЕКМНОРСТУХавекмнорстух{1}][\d{1}][\d{1}][\d{1}|\wАВЕКМНОРСТУХавекмнорстух{1}][\d{1}|\wАВЕКМНОРСТУХавекмнорстух{1}][\d{1}][\d{1}]?[\d]/gm;

export const countries: ICountry[] = [
  {
    country: 'Россия',
    code: 'ru',
    regex: rusRegNumbers,
    mask: [
      /[АВЕКМНОРСТУХавекмнорстух]/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /[АВЕКМНОРСТУХ-авекмнорстух]/,
      /[АВЕКМНОРСТУХавекмнорстух]/,
      ' ',
      /\d/,
      /\d/,
      /\d/
    ],
    placeholder: 'A 999 AA 999'
  },
  {
    country: 'Азербайджан',
    code: 'az',
    regex: /^[\d]{2}[\w]{2}[\d]{3}$/,
    mask: [/[0-9]/, /[0-9]/, /[A-Z-a-z]/, /[A-Z-a-z]/, /[0-9]/, /[0-9]/, /[0-9]/],
    placeholder: '99AA999'
  },
  {
    country: 'Армения',
    code: 'am',
    regex: /^[\d]{3}[\w]{2}[\d]{3}$/,
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[A-Z-a-z]/, /[A-Z-a-z]/, /[0-9]/, /[0-9]/, /[0-9]/],
    placeholder: '999AA999'
  },
  {
    country: 'Беларусь',
    code: 'by',
    regex: /^[\d|\w]{6}[\d]$/,
    mask: [
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[ABEIKMHOPCTXabeikmhopctx0-9]/,
      /[0-9]/
    ],
    placeholder: '0000000'
  },
  {
    country: 'Казахстан',
    code: 'kz',
    regex: /^[\w][\d]{3}[\w]{3}$/,
    mask: [/[A-Za-z]/, /[0-9]/, /[0-9]/, /[0-9]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/],
    placeholder: 'A999AAA'
  },
  {
    country: 'Кыргызстан',
    code: 'kgz',
    regex: /^[\d|\w]{6}$/,
    mask: [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/],
    placeholder: '000000'
  },
  {
    country: 'Молдова',
    code: 'md',
    regex: /^[\d|\w]{6}$/,
    mask: [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/],
    placeholder: '000000'
  },
  {
    country: 'Таджикистан',
    code: 'tj',
    regex: /[\d|\w]{7}/,
    mask: [
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxсdjzy0-9]/,
      /[ABEKMHOPCTXDJZYabekmhopctxdсjzy0-9]/
    ],
    placeholder: '00000000'
  },
  {
    country: 'Туркменистан',
    code: 'tm',
    regex: /[\d|\w]{7}/,
    mask: [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/],
    placeholder: '0000000'
  },
  {
    country: 'Узбекистан',
    code: 'uz',
    regex: /[\d|\w]{6}/,
    mask: [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/],
    placeholder: '000000'
  }
];
