import { IconOptions, TableRecord } from '@common/types';
import { IILSCellSelectStringProps } from '@common/types/components/table/cell';
import { compareAsString } from '@common/utils';
import { ILSSelectStatic } from '@components/custom/data-display/select';
import { ILSSVG } from '@components/custom/icons/svg';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { API_ICON_PARAM } from '@components/data-display/table/constants';
import { getStringFromVariants } from '@components/data-display/table/utils/helpers/cells/get-string-from-variants';

export const ILSCellSelectString = <R extends TableRecord>({
  fieldNames,
  toggleEdit,
  children,
  showArrow = true,
  icon,
  options
}: IILSCellSelectStringProps<R>): JSX.Element => {
  const [node, variant] = children;
  const text = getStringFromVariants({ options, variant, fieldNames });
  if (icon) {
    const current = (options as IconOptions | undefined)?.find(({ value }) => compareAsString(variant, value));
    const iconLabel = current?.icon ?? current?.label ?? current?.[fieldNames?.label ?? BASE_FIELD_NAMES.label] ?? 'bad';

    return (
      <span className={'cell-wrapper-editable'} onClick={toggleEdit}>
        {iconLabel.includes(API_ICON_PARAM) ? (
          <img width={20} height={20} src={iconLabel} alt={'Иконка статуса'} />
        ) : (
          <ILSSVG icon={iconLabel} />
        )}
      </span>
    );
  }

  return <ILSSelectStatic showArrow={showArrow} className={'cell-wrapper-editable'} onClick={toggleEdit} children={[node, text]} />;
};
