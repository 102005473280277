import { dataClearRoutine, dataImportRoutine } from '@modules/planning/children/data/actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Data } from '@modules/planning/children/data/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ImportRequest } from '@modules/planning/children/data/types/api';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { PLANNER_DATA_CONTENT } from '@modules/planning/children/data/constants/content';

export function* dataImportWorker(action: PayloadAction<ImportRequest>) {
  const { request, success, failure, fulfill } = dataImportRoutine;
  const { files, projectID } = action.payload;

  try {
    yield put(request());
    yield call(Data.import, { files, projectID });
    yield all([put(dataClearRoutine.trigger()), put(success({ projectID }))]);
  } catch (error) {
    yield put(failure({ error: createArrayFromIndexedArray(error as Record<string, string>) }));
  } finally {
    yield put(fulfill());
    if (files.Order) {
      ILSUserNotify(Notify.Info, PLANNER_DATA_CONTENT.UPLOAD_ORDER_INFO_NOTIFY, NotifyDurationInSecond.Five);
    }
  }
}

export function* dataImportWatcher() {
  yield takeLatest(dataImportRoutine.trigger, dataImportWorker);
}
