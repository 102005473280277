import { FC, useEffect, useRef, useState } from 'react';
import { stopPropagationEvent } from '@common/utils/helpers/prevent-default-stop-propagation-for-handler';
import { ILSPlanContextMenu } from '@modules/planning/children/by-date/components/result/context-menu/menu/menu';
import { ILSPlanContextForm } from '@modules/planning/children/by-date/components/result/context-menu/form';
import { PlansTableRecord, RoutesTableRecord } from '@modules/planning/children/by-date/types';

interface IILSPCMC {
  coords: {
    x: number;
    y: number;
  };
  record: PlansTableRecord | RoutesTableRecord;
  maxHeight: number;
  menu: Array<any>;

  onContextClose(): void;
}

const BOTTOM_TOP_MARGIN = 65;

export const PlannerPlanningPlanTableContextMenuPopover: FC<IILSPCMC> = ({ coords, onContextClose, record, maxHeight, menu }) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const onClick = (item: any, forceAdd = false, submenu = false) => {
    if (item.confirm && !forceAdd) {
      setTimeout(() => {
        setConfirm(item);
        item.field && setInputValue(record[item.field]);
      }, 100);
    } else {
      if (!submenu) {
        if (item.handler && typeof item.handler === 'function') {
          if (item.field) {
            item.handler(record, item.field, item.inputValue);
          } else {
            item.handler(record, item.param);
          }
        }
      } else {
        if (item.handler && typeof item.handler === 'function') {
          item.handler(item);
        }
      }
      if (!item.popover) onContextClose();
    }
  };
  const menuRef = useRef<HTMLDivElement | null>(null);
  let top = coords?.y;

  //NOTE useEffect нужен для обработки высоты после рендеров
  useEffect(() => {
    /**
     * Текущий размер меню
     */
    const menuHeight = menuRef?.current?.clientHeight ?? 300;

    /**
     * Смещение текущей таблицы относительно всего экрана
     */
    const parentOffset = menuRef?.current?.parentElement?.getBoundingClientRect()?.y ?? 0;
    //NOTE top задается относительно родителя
    // в coords.y задается относительно всего экрана
    let top = coords?.y - parentOffset;

    if (coords?.y + menuHeight > maxHeight) {
      top -= menuHeight;
    }
    //Отступ от верхней границы экрана
    if (top < -parentOffset + BOTTOM_TOP_MARGIN) {
      top = -parentOffset + BOTTOM_TOP_MARGIN;
    }
    if (menuRef.current) {
      const currentTop = menuRef.current.style.getPropertyValue('top');
      const newTop = top + 'px';
      if (currentTop !== newTop) {
        menuRef.current.style.setProperty('top', newTop);
      }
    }
  });
  useEffect(() => {
    const closeMenu = () => onContextClose();
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  return (
    <div onClick={stopPropagationEvent} ref={menuRef} className="plan-context-menu" style={{ left: coords?.x + 'px', top: top + 'px' }}>
      {confirm ? (
        <ILSPlanContextForm inputValue={inputValue} setConfirm={setConfirm} confirm={confirm} onClick={onClick} />
      ) : (
        <ILSPlanContextMenu record={record} menu={menu} onClick={onClick} onContextClose={onContextClose} />
      )}
    </div>
  );
};
