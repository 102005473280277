import { FC, useEffect, useState } from 'react';
import { TenderPlatformWaypointComponent } from '@modules/tender-platform/components/tender-card/lots/form/fields/route/waypoint';
import { Field } from '@common/constants';
import { useDispatch } from 'react-redux';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import { tenderGetDistanceRoutine } from '@modules/tender-platform/actions';
import { ROUTE_FORM_ITEMS } from '@modules/tender-platform/constants/form';

interface IProps {
  disabled: boolean;
}

export const TenderPlatformWaypointsContainer: FC<IProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const [isSelectedAddress, setSelectedAddress] = useState(false);

  const form = useFormInstance();
  const source = useWatch(`${Field.Source}${Field.Address}`, form);
  const target = useWatch(`${Field.Target}${Field.Address}`, form);

  const onSetSelectedAddress = () => {
    setSelectedAddress((prev: boolean) => !prev);
  };

  //NOTE: Отправляем запрос на получение расстояния, только после автокомплита
  useEffect(() => {
    if (source && target && !disabled) {
      dispatch(tenderGetDistanceRoutine({ source, target }));
    }
  }, [isSelectedAddress]);

  return (
    <>
      {ROUTE_FORM_ITEMS.map((fieldset) => (
        <TenderPlatformWaypointComponent key={fieldset} field={fieldset} disabled={disabled} onSetSelectedAddress={onSetSelectedAddress} />
      ))}
    </>
  );
};
