import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import ILSAnalyticsPlanningContainer from './containers';
import AnalyticsPlanningReducer from './reducers';
import * as AnalyticsPlanningSagas from './sagas';
import './styles.less';

export default defineModule({
  title: ModuleTitle.AnalyticsPlanningBoards,
  path: ModulePath.AnalyticsPlanningBoards,
  component: ILSAnalyticsPlanningContainer,
  reducer: AnalyticsPlanningReducer,
  sagas: getAllModuleSagas(AnalyticsPlanningSagas),
  persist: false
});
