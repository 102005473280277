import { IndexedArray } from '@common/types';
import { MAP_SIZES } from '@core/constants/map';
import { ILSMap } from '@core/containers/map';
import { useAppSelector } from '@core/hooks';
import { useFitBoundsMarker } from '@modules/monitoring/children/event-details/hooks/use-fitbound-markers';
import {
  activeTabSelector,
  activeVehicleIDSelector,
  tracksSelector,
  vehicleEventsSelector,
  vehiclePlansSelector
} from '@modules/monitoring/children/event-details/selectors';
import { ILSMonitoringLayerMenu } from '@modules/monitoring/components/map/layer/menu';
import { monitoringDateSelector, monitoringPlanTrackSelector } from '@modules/monitoring/selectors';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { MAP_INIT_SETTINGS } from '../configs/map';
import { useEventMarker } from '../hooks/use-event-marker';
import { useMapInit } from '../hooks/use-map-init';
import { useTracks } from '../hooks/use-tracks';
import { VehicleMarkerMapped, useVehicleMarker } from '../hooks/use-vehicle-marker';
import { useWaypointMarker } from '../hooks/use-waypoint-marker';
import { MapLayerGroupState } from '../types/map';
import { collectVehicleTrip } from '../utils/event-details';
import { MonitoringDetailsContext } from './context';
import { useMapLayer } from '@modules/monitoring/hooks/use-map-layers';

interface Props {}

/**
 * Контейнер для работы с картой
 * Здесь находится:
 * Вся логика по работе с картой
 * Первичное отображение карты
 * Обновление элементов карты
 * Обработка пользовательских действий с элементами карты (кнопки, клики по маркеру, маршруту и т.д)
 */
//TODO добавить комментарии к хукам
export const MainMapContainer: FC<Props> = ({}) => {
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);

  const VehicleEvents = useAppSelector(vehicleEventsSelector);
  const vehiclePlans = useAppSelector(vehiclePlansSelector);
  const planTrack = useAppSelector(monitoringPlanTrackSelector);
  const activeVehicleID = useAppSelector(activeVehicleIDSelector);

  const { selectedVehicleIDs, vehicleFocusHandler } = useContext(MonitoringDetailsContext) ?? {};
  const { disableClustering, mapZoomCluster } = MAP_INIT_SETTINGS;
  const VehicleTracks = useAppSelector(tracksSelector);
  const activeTab = useAppSelector(activeTabSelector);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<ILSMap | null>(null);
  const [currentMarkers, setCurrentMarkers] = useState<IndexedArray<VehicleMarkerMapped> | undefined>();
  const trips = useMemo(
    () => collectVehicleTrip(VehicleEvents.data, VehicleTracks.selectedVehicleId),
    [VehicleEvents.data, VehicleTracks.selectedVehicleId]
  );

  const [mapLayersGroup, setMapLayersGroup] = useState<MapLayerGroupState>({
    vehicleCluster: undefined,
    polylineGroup: undefined,
    markerGroup: undefined,
    eventMarkerGroup: undefined,
    waypointGroup: undefined,
    markerCluster: undefined,
    layerGroup: undefined,
    isInit: false
  });

  useMapInit(mapRef, mapZoomCluster ? Number(mapZoomCluster) : undefined, disableClustering, setMapLayersGroup);

  useVehicleMarker({
    Map: mapRef,
    vehicleEvents: VehicleEvents.data,
    currentMarkers,
    setCurrentMarkers,
    selectedVehicleIDs,
    activeVehicleID,
    vehicleFocusHandler
  });

  useTracks({
    Map: mapRef,
    trackData: VehicleTracks.data,
    trips: trips,
    vehiclePlans,
    show:
      !!VehicleTracks.selectedVehicleId &&
      VehicleTracks.selectedVehicleId === VehicleTracks.loadedVehicleId &&
      VehicleTracks.lastUpdateInterval?.from === startDate &&
      VehicleTracks.lastUpdateInterval?.till === endDate,
    mapLayersGroup,
    activeTab,
    activeVehicleID,
    VehicleEvents: VehicleEvents.data,
    loading: VehicleEvents.isFetching,
    planTrackData: planTrack
  });

  useWaypointMarker({
    Map: mapRef,
    cluster: mapLayersGroup.markerCluster,
    vehicleEvents: VehicleEvents.data,
    selectedVehicleIDs,
    activeVehicleID,
    selectedVehicleID: VehicleTracks.selectedVehicleId,
    vehicleFocusHandler
  });

  useFitBoundsMarker({
    Map: mapRef,
    cluster: mapLayersGroup.waypointGroup,
    vehicleEvents: VehicleEvents.data,
    trips: trips,
    selectedVehicleIDs,
    activeVehicleID,
    selectedVehicleID: VehicleTracks.selectedVehicleId,
    vehicleFocusHandler
  });

  useEventMarker({
    Map: mapRef,
    cluster: mapLayersGroup.eventMarkerGroup
  });

  const { activeLayers, layerMenu, setActiveLayers, handleDictionaryLoad } = useMapLayer({
    Map: mapRef,
    mapLayersGroup: mapLayersGroup.layerGroup
  });
  return (
    <div className="map-container" ref={mapContainerRef}>
      <div id="ils-map" style={{ ...MAP_SIZES }} />
      <div className="ils-map-toolbar">
        <ILSMonitoringLayerMenu
          activeLayers={activeLayers}
          layerMenu={layerMenu}
          setActiveLayers={setActiveLayers}
          handleDictionaryLoad={handleDictionaryLoad}
        />
      </div>
    </div>
  );
};

