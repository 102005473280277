import { put, takeLatest } from 'redux-saga/effects';
import { roadGraphCleanRouteRoutine } from '@modules/road-graph/route-test/actions';

function* roadGraphCleanRouteWorker() {
  const { request, success, failure } = roadGraphCleanRouteRoutine;
  try {
    yield put(request());

    yield put(success());
  } catch (error) {
    yield put(failure({ error: error }));
  }
}

export function* roadGraphCleanRouteWatcher() {
  yield takeLatest(roadGraphCleanRouteRoutine.trigger, roadGraphCleanRouteWorker);
}
