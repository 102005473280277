import { Dispatch, FC, Key, MutableRefObject, ReactNode, SetStateAction } from 'react';
import { TableProps } from 'antd/lib/table/Table';
import {
  Adding,
  DictionariesInfo,
  DragInfo,
  Reference,
  ReferenceTables,
  ReferenceTablesData,
  SetShowReference,
  ShowDeleted,
  TableRecord
} from './common';
import { MapProps } from '@modules/catalog/types/components';
import { Dictionary, Keys, ReactDispatch, ValidationData } from '@common/types';
import { ColumnTable } from './column';
import { DragHandler, ITablePropsFunction } from './functions';
import { UploadChangeParam } from 'antd/es/upload';
import { PlacementType } from '@common/types/components';
import { ExpandableConfig } from 'antd/lib/table/interface';

export interface IILSTableComponentProps<R extends TableRecord>
  extends Omit<TableProps<R>, 'columns' | 'dataSource'>,
    ITablePropsFunction<R>,
    Partial<{
      pageSizeOptions: string[];
      recordsPerPage: MutableRefObject<null | number>;
      codeField: any;
      pageSize: number;
      virtual: boolean;
      dontEdit: boolean;
      deleting: boolean;
      closeTableButton?: JSX.Element;
      columns: ColumnTable<R>[];
      tableName: TableName | string;
      tableTitle: TableTitle | string;
      overscanRowCount: number;
      dataSource: Array<R>;
      focusRow: any | any[];
      notHighlightFocusRow: boolean;
      focusRowCustomHighlight: string;
      showDeleted: ShowDeleted;
      setShowDeleted: Dispatch<SetStateAction<ShowDeleted>>;
      errors: Array<ValidationData>;
      recordTableButtons: Array<RecordTableButton>;
      actionTableButtons: Array<ActionTableButton>;
      settingsTableButtons: Array<SettingsTableButton>;
      tableWidgets: Array<TableWidget>;
      refData: ReferenceTablesData;
      refTable: ReferenceTables;
      dictionary: Dictionary;
      dictionaryInfo: DictionariesInfo;
      dragInfo: DragInfo;
      dragHandler: DragHandler<R>;
      accordionDictionary: Dictionary;
      customSummary: ReactNode;
      selectedRows: (number | string)[];
      isModal: boolean;
      expandIconColumnIndex: number;
      activeCell: string | null;
      hasFilters: boolean;
      reference?: Reference<R>;
      uploadConfiguration: Partial<{
        uploading: boolean;
        fileMaxSize: number;
        defaultPermittedTypes: Array<string>;
        defaultFormats: Array<string>;
        buttonText: string;
        handleUpload(params: UploadChangeParam): void;
      }>;
      notificationEnabled: boolean;
      customClearFilters: {
        filtered: boolean;
        clearFilters: () => void;
      };
      externalExpandableProps: ExpandableConfig<R>;
      isScrollToActiveRow: boolean;
      defaultPageSize: number;
    }> {
  setShowReference: SetShowReference<R>;
  tableRef: MutableRefObject<HTMLDivElement | null>;
  config: TableConfig;
}

export enum TableRowButton {
  delete = 'delete',
  details = 'details'
}
export type TableRowButtons = Record<
  TableRowButton,
  {
    isUse: boolean;
  }
>;

export interface IILSTableProps<R extends TableRecord>
  extends Omit<
    IILSTableComponentProps<R>,
    'setShowReference' | 'tableRef' | 'onResizeStart' | 'handleOpenExportModal' | 'pageSize' | 'setAddedRow'
  > {
  isFetching?: boolean;
  mapProps?: MapProps;
  currentTab?: string;
}

export type SelectedRowKeys = Keys | [];
export type SetSelectedRowKeys = ReactDispatch<SelectedRowKeys>;

export type ActiveTableParameters = { key: Key; page: number; update: boolean };
export type SetActiveTableParameters = ReactDispatch<ActiveTableParameters>;

export type HighlightedRow = { active: boolean; ID: number | undefined | null };
export type SetHighlightedRow = ReactDispatch<HighlightedRow>;

export type ResizeDataType<R> = {
  x?: null | number;
  column: null | ColumnTable<R>;
  startX: null | number;
  dictionary: null | Dictionary;
};

export type RecordTableButton = {
  Component?: FC<any>;
  props?: any;
  dictionary?: Dictionary;
  icon?: string;
};

export type ActionTableButton = RecordTableButton;
export type SettingsTableButton = RecordTableButton;

export type TableWidget = {
  Component: FC<any>;
  props?: {
    className: string;
    icon: string;
  };
};

export enum TableTitle {
  Contact = 'Контакты',
  DepotOverload = 'Превышения клиента',
  VehicleOverload = 'Превышения',
  Worktime = 'Расписание работы',
  VehicleTimeline = 'Режим работы',
  Driver = 'Водители',
  ForbiddenZone = 'Зоны ограничений',
  VehicleProfile = 'Профиль',
  CouplingType = 'Типы связок',
  RoadTrain = 'Редактирование автосцепки',
  Speeds = 'Скорости',
  FriendZone = 'Зоны',
  RampType = 'Типы рамп',
  Order = 'Заявки',
  Vehicle = 'Автомобили',
  VehiclePark = 'Парк машин',
  Depot = 'Точки',
  Zone = 'Зоны доставки',
  SpeedProfile = 'Профили скоростей',
  Restriction = 'Специфики',
  TripTemplate = 'Шаблоны рейсов',
  CrossDock = 'Кросс докинг',
  Tariff = 'Тарифы'
}

export enum ILSTableHeaderButton {
  Add = 'add',
  Reload = 'reload',
  Restore = 'restore',
  DeleteAll = 'deleteAll'
}

export type ButtonParams = Partial<{
  icon: string;
  iconText: string;
  className: string;
}>;

export type TableConfig = {
  configClassName?: string;
  headerButtonsParams?: Partial<Record<ILSTableHeaderButton, ButtonParams | null>>;
  //TODO not tableWidth, but tableStyle: React.CSSProperties | undefined
  tableWidth?: string;
  header?: boolean;
  //TODO generic
  columns: ColumnTable<any>[];
  grouping?: Array<ColumnTable<any>['dataIndex']>;
  adding?: Adding | boolean;
  deleting?: boolean;
  uploading?: boolean;
  reloading?: boolean;
  selecting?: boolean;
  copying?: boolean;
  copy?: boolean;
  hideClearButtons?: boolean;
  unchangeable?: boolean;
  showDeleted?: ShowDeleted;
  getCoordinates?: boolean;
  name?: string;
  childConfigs?: Record<string, TableConfig>;
  multiEditing?: boolean;
  export?: boolean;
  widget?: boolean;
  closeButton?: boolean;
  approvedOrder?: boolean;
  filtered?: boolean;
  withIcon?: true;
  pageSize?: number;
  /*Столбец или массив, которые надо проверять для ререндера ячейки*/
  updateWatch?: string | Array<string>;
  /*Проверять всю запись для ререндера ячейки */
  checkRecordOnUpdate?: boolean;
  mouseLeaveDelay?: number;
  popoverPosition?: PlacementType;
  externalFocusControl?: boolean;
};

export enum TableName {
  TenderOffers = 'TenderOffers',
  TenderLots = 'TenderLots',
  TenderListCarrier = 'TenderListCarrier',
  PlanningVehicle = 'PlanningVehicle',
  PlanningNotDeliveredOrder = 'PlanningNotDeliveredOrder',
  PlanningOrder = 'PlanningOrder',
  PlansTable = 'PlansTable',
  RoutesTable = 'RoutesTable'
}

