import { FC } from 'react';
import { ILSTypographyTitle } from '@components/general/typography';
import { EMPTY_STRING } from '@common/constants';
import { ILSFormItem, ILSInput } from '@common/components';
import { LotFormFieldLabel } from '@modules/tender-platform/types';
import { ILSSelectCurrency } from '@components/data-entry/select';
import { TenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { TenderFormField } from '@modules/tender-platform/constants/content';

interface IProps {
  offerID?: TenderOffer['ID'];
}

export const TenderPlatformAuctionInfo: FC<IProps> = ({ offerID }) => {
  return (
    <>
      <ILSTypographyTitle level={2} children={`Заявка ${offerID ?? EMPTY_STRING}`} />
      <ILSFormItem label={LotFormFieldLabel.StartPrice} name={TenderFormField.StartPrice}>
        <ILSInput addonAfter={<ILSSelectCurrency disabled />} disabled />
      </ILSFormItem>
      <ILSFormItem label={LotFormFieldLabel.MinPrice} name={TenderFormField.MinPrice}>
        <ILSInput addonAfter={<ILSSelectCurrency disabled />} disabled />
      </ILSFormItem>
    </>
  );
};
