import React, { FC } from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { ILSTitle } from '../page-title';

const ILSNotFoundPage: FC = () => {
  return (
    <div>
      <Result
        status="404"
        title={<ILSTitle title="Страница не найдена" />}
        extra={
          <Button type="primary">
            <Link to="/">На главную</Link>
          </Button>
        }
      />
    </div>
  );
};
export default ILSNotFoundPage;
