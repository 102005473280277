import { MouseEvent } from 'react';
import { CellType, TableRecord } from '@common/types';
import { ILSTitleLineSeriesContainer } from './ils-title-line-series-container';
import { ILSTooltip, ILSTypographyText } from '@components/index';
import { ILSSVG } from '@components/custom/icons/svg';
import { IILSTitleCellProps, PlacementType } from '@common/types/components/table/cell';
import { TOOLTIP_MOUSE_LEAVEDELAY } from '@common/components/data-display/tooltip/constants';

export const ILSTitleCell = <R extends TableRecord>({
  column,
  sorted,
  config,
  dictionary,
  onResizeStart,
  dataSource,
  tableRef
}: IILSTitleCellProps<R>): JSX.Element => {
  const {
    style,
    type,
    dataIndex,
    lineSeriesProps,
    key,
    titleTooltip,
    title,
    subTitle,
    titleText,
    isFixedColumn,
    titleTip,
    titleIcon,
    classOverlayTitleTooltip,
    ellipsis
  } = column;

  if (type === CellType.LineSeries && key) {
    const width = Number((style?.width || style?.minWidth) ?? 0);
    let maxLineLength = 0;

    dataSource?.forEach((item) => {
      if (item[dataIndex] && Array.isArray(item[dataIndex]) && item[dataIndex].length > maxLineLength) {
        maxLineLength = item[dataIndex].length;
      }
    });

    return (
      <ILSTitleLineSeriesContainer
        dataIndex={dataIndex}
        width={width}
        sorted={sorted}
        config={config}
        dictionary={dictionary}
        onResizeStart={onResizeStart}
        dataSource={dataSource}
        tableRef={tableRef}
        columnKey={key}
        lineSeriesProps={lineSeriesProps}
      />
    );
  }

  const onMouseDown = (e: MouseEvent) => onResizeStart?.(e, column, dictionary);

  return (
    <>
      <ILSTooltip
        placement={column?.titleTooltipPlaceMent ?? PlacementType.left}
        title={titleTooltip}
        mouseLeaveDelay={TOOLTIP_MOUSE_LEAVEDELAY}
        overlayClassName={classOverlayTitleTooltip}
      >
        {titleIcon && <ILSSVG alt={titleTip ?? title} icon={titleIcon} />}
        <div>{title ?? titleText}</div>
        {subTitle && <ILSTypographyText style={{ opacity: 0.5 }} children={subTitle} ellipsis={Boolean(ellipsis)} title={subTitle} />}
      </ILSTooltip>
      {/*TODO resizer pos*/}
      {!isFixedColumn && <div className="resizer" onMouseDown={onMouseDown} />}
    </>
  );
};
