import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { demandGetListRoutine } from '../actions';
import { initialState } from '../store';
import { DemandRow, IDemandStore } from '../types/store';
import { GetListRequest } from '../types/api';
import { updateDemandData } from '../utils/update-demand-data';
import { deepEqual } from 'fast-equals';

// Основной редьюсер, который записывает в стор список проектов и тендерных лотов
const getDemandListReducer = createReducer(initialState, {
  [demandGetListRoutine.REQUEST]: (state: IDemandStore) => {
    state.isFetching = true;
    return state;
  },
  [demandGetListRoutine.SUCCESS]: (
    state: IDemandStore,
    action: PayloadAction<{ list: Array<DemandRow>; params: GetListRequest; forceUpdate?: boolean }>
  ) => {
    const { list, params, forceUpdate } = action.payload;

    if (list.length) {
      if (!deepEqual(params, state.lastParams)) {
        if (
          typeof state.lastParams.page === 'number' &&
          typeof params.page === 'number' &&
          state.lastParams.page >= params.page &&
          !forceUpdate
        ) {
          state.hasDesync = true;
        } else {
          const { newList, hasDesync } = updateDemandData(state.list, list, state.hasDesync, forceUpdate);
          state.list = newList;
          state.hasDesync = hasDesync;
          state.isAllUploaded = false;
        }
      } else {
        if (forceUpdate) {
          state.list = list;
          state.hasDesync = false;
          state.isAllUploaded = false;
        }
      }
    } else {
      if (forceUpdate) {
        state.list = list;
        state.hasDesync = false;
      }
      state.isAllUploaded = true;
    }
    state.isFetching = false;
    state.lastParams = params;
    // if (!state.lastParams.filter?.type) {
    //   state.lastParams.filter = { type: DemandTabType.All };
    // }
    return state;
  },
  [demandGetListRoutine.FAILURE]: (state: IDemandStore) => {
    state.isFetching = false;
    return state;
  },
  [demandGetListRoutine.FULFILL]: (state: IDemandStore) => {
    return state;
  }
});

export default getDemandListReducer;
