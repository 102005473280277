import { TableTipContainer } from '@common/components/data-display/tips-table/tip-container';
import { TipRow } from '@common/components/data-display/tips-table/tip-row';
import { TipCell } from '@common/components/data-display/tips-table/tip-td';
import { ValidationData } from '@common/types';
import { FC } from 'react';

interface IProps {
  dataSource: ValidationData[];
}

export const CommonErrorTip: FC<IProps> = ({ dataSource }) => {
  const errorHeads = ['Описание', 'Проверено', 'Найдено'];
  const data = dataSource.map((data) => {
    return (
      <TipRow>
        <TipCell>{data.Name_}</TipCell>
        <TipCell>{data.Chk_}</TipCell>
        <TipCell>{data[data.Kind_ + '_']}</TipCell>
      </TipRow>
    );
  });
  return (
    <TableTipContainer dataHead={errorHeads} emptyData="Ошибки не найдены">
      {data}
    </TableTipContainer>
  );
};
