import '../styles.less';
import { FC } from 'react';
import { ILSButton, ILSTooltip } from '@components/index';
import cn from 'classnames';
import { IButtonTooltipProps } from '@components/general/buttons/types';

export const ILSButtonTooltip: FC<IButtonTooltipProps> = ({
  isLoading = false,
  children,
  title,
  placement = 'bottom',
  tooltipClassName,
  buttonClassName,
  icon,
  onClick,
  isFetching = false,
  ...props
}) => {
  return (
    <ILSTooltip key={title as string} mouseLeaveDelay={0} title={title} className={tooltipClassName} placement={placement}>
      <ILSButton
        title={(title as string) ?? 'Кнопка'}
        loading={isLoading || isFetching}
        className={cn(buttonClassName, 'button-icon-tooltip')}
        icon={icon}
        {...props}
        children={children}
        onClick={onClick}
      />
    </ILSTooltip>
  );
};
