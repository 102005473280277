import { useEffect, useState } from 'react';
import { ExpandableConfig } from 'rc-table/lib/interface';
import { UseExpandRows } from '@common/types/components/table/hooks';
import { TableRecord } from '@common/types';

export const useExpandRows = <R extends TableRecord>({ dataSource, dataSourceRef, tableName, expandable }: UseExpandRows<R>) => {
  /** Раскрытые строки */
  const [expandKeys, setExpandKeys] = useState<ExpandableConfig<R>['expandedRowKeys']>([]); //
  //NOTE: dataSourceRef.current - хранит предыдущее кол-во записей после удаления/ добавления строки. Новое значение присваивается после установки фокуса
  useEffect(() => {
    if (!dataSourceRef.current && dataSource?.length) {
      dataSourceRef.current = dataSource;
    }
  }, [tableName, dataSource]);

  const expandedRowKeys = expandable?.defaultExpandAllRows || expandable?.defaultExpandedRowKeys ? undefined : expandKeys ?? [];
  const expandableProp: ExpandableConfig<R> = {
    ...expandable,
    expandedRowKeys,
    onExpandedRowsChange: setExpandKeys
  };

  return { expandableProp };
};
