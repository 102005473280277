import { PlanningMarker } from '@modules/planning/children/by-date/hooks/map/map-hooks-item-markers';
import { LatLngExpression } from 'leaflet';
import { getLatLngExpression } from '@common/utils';

export const getBoundsForMarkers = (markers: Readonly<PlanningMarker>): LatLngExpression[] => {
  return markers.slice().reduce((bounds: LatLngExpression[], marker) => {
    const { coords } = marker ?? {};
    if (coords) {
      bounds.push(getLatLngExpression(coords) as LatLngExpression);
    }
    return bounds;
  }, []);
};
