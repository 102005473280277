import { default as localForage } from 'localforage';
import { APP_NAME } from '@core/constants';

class FilesLocalForageImpl {
  instance: LocalForage;

  constructor() {
    this.instance = localForage.createInstance({
      driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
      name: APP_NAME,
      storeName: 'files-storage',
      version: 1.0
    });
  }

  getItem(key: string) {
    return this.instance.getItem(key);
  }

  setItem(key: string, value: any) {
    return this.instance.setItem(key, value);
  }
}

const FilesLocalForage = new FilesLocalForageImpl();

export { FilesLocalForage };
