import { IndexedArray } from '@common/types';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import { apiInstance } from '@core/api/instance';

const getBoardsForPDF = async (id: string | number) => {
  const { data } = await apiInstance().post<{ data: IndexedArray<AnalyticsWidget> }>('/NewTms/AnalyticsPlanning/getBoardWidget', {
    BoardID: id
  });

  return data.data;
};

export default getBoardsForPDF;
