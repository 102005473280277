import { Dictionary } from '@common/types';
import { createIndexedArrayFromArray } from '@common/utils/helpers/array/create-indexed-array-from-array';
import { monitoringCatalogReadRoutine } from '@modules/monitoring/actions';
import { initialState } from '@modules/monitoring/store';
import { MonitoringState } from '@modules/monitoring/types/store';

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

type DataType = any;

export const monitoringReadDictionaryReducer = createReducer(initialState, {
  [monitoringCatalogReadRoutine.REQUEST]: (
    state: MonitoringState,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;
    if (isEmpty(state.catalog?.[dictionary])) {
      state.catalog[dictionary] = { isFetching: true, data: {}, lastUpdate: null };
    }
    state.catalog[dictionary].isFetching = true;

    return state;
  },
  [monitoringCatalogReadRoutine.SUCCESS]: (
    state: MonitoringState,
    action: PayloadAction<{
      data: DataType;
      dictionary: Dictionary;
    }>
  ) => {
    const { data, dictionary } = action.payload;
    state.catalog[dictionary].data =  createIndexedArrayFromArray(data)  || {};

    state.catalog[dictionary].lastUpdate = Date.now();
    return state;
  },
  [monitoringCatalogReadRoutine.FAILURE]: (
    state: MonitoringState,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;
    state.catalog[dictionary].isFetching = false;
    state.catalog[dictionary].lastUpdate = undefined;
    return state;
  },
  [monitoringCatalogReadRoutine.FULFILL]: (
    state: MonitoringState,
    action: PayloadAction<{
      dictionary: Dictionary;
    }>
  ) => {
    const { dictionary } = action.payload;
    state.catalog[dictionary].isFetching = false;

    return state;
  }
});


