import { Depot, Dictionary, IndexedArray } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { DEPOT_TYPE_OPTIONS } from '@common/models/depot/constants';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';

export const createDepotCascaderOptions = (depots: Array<Depot> | IndexedArray<Depot> | undefined | null) => {
  const children = createArrayFromIndexedArray(depots);
  const options = DEPOT_TYPE_OPTIONS.map(({ ID, Name }) => {
    return {
      ID,
      Name,
      children: children.reduce((acc: (Depot & { disabled: boolean })[], depot) => {
        if (compareAsString(depot.DepotTypeID, ID)) {
          acc.push({ ...depot, disabled: Boolean(depot?.Deleted) });
        }
        return acc;
      }, [])
    };
  });
  return {
    options,
    dictionary: Dictionary.Depot,
    fieldNames: {
      ...DEFAULT_FIELD_NAMES,
      children: 'children'
    }
  };
};

