import { GeohashDecodePoints } from '@common/decorators/latlon-geohash';
import { ConvexPolygon } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { LayerHandler, Polygon } from '@core/types/map';
import { deepEqual } from 'fast-equals';
import L, { LatLngExpression } from 'leaflet';
import { isEmpty, isString } from 'lodash';
import { MutableRefObject, useEffect } from 'react';
import { ConvexItem, MapLayers } from '../../types/map-container';

export const useMapConvexItems = (
  Map: MutableRefObject<ILSMap | null>,
  mapLayersGroup: MapLayers,
  convex: ConvexPolygon[],
  mapConvex: ConvexItem[],
  setMapConvex: (newConvex: ConvexItem[]) => void
) => {
  useEffect(() => {
    if (Map.current) {
      const mapConvex_n: ConvexItem[] = [];
      let updateP = false;

      const addPoly = (c: ConvexPolygon): ConvexItem | undefined => {
        const coords = isString(c.coords) ? GeohashDecodePoints(c.coords) : c.coords;

        let click: LayerHandler<L.Polygon> | undefined;
        if (c.clickData) {
          click = () => {};
        }

        const newLConvex =
          !isEmpty(coords) &&
          Map.current?.addPolygon(
            [
              coords as LatLngExpression[] | LatLngExpression[][],
              {
                color: c.color,
                weight: 0,
                fillColor: c.color,
                fillOpacity: 0.3
              },
              undefined,
              click ? { click } : undefined
            ],
            mapLayersGroup.convexGroup
          );
        if (newLConvex) {
          return {
            lConvex: newLConvex,
            convex: c as Polygon,
            layer: mapLayersGroup.convexGroup
          };
        }
        return undefined;
      };

      if (mapConvex.length === 0) {
        convex.forEach((poly) => {
          if (!isEmpty(poly.coords)) {
            const pItem = addPoly(poly);

            if (pItem) {
              mapConvex_n.push(pItem);
              if (!updateP) updateP = true;
            }
          }
        });
      } else {
        const mapPolyCheckedKeys: number[] = [];
        const poly2AddToMap: ConvexPolygon[] = [];

        convex.forEach((poly) => {
          let mapPoly = mapConvex.find((mItem, index) => {
            if (mItem.convex && poly && deepEqual(mItem.convex, poly)) {
              mapPolyCheckedKeys.push(index);
              return true;
            }
          });
          if (!mapPoly && poly) {
            poly2AddToMap.push(poly);
          }
        });

        mapConvex.forEach((mItem, index) => {
          if (!mapPolyCheckedKeys.includes(index)) {
            if (!updateP) updateP = true;

            if (mItem.layer) {
              if (mItem.lConvex) {
                mItem.layer.removeLayer(mItem.lConvex);
              }
            } else {
              if (mItem.lConvex) {
                Map.current?.deleteAnyLayer(mItem.lConvex);
              }
            }
          } else {
            mapConvex_n.push(mItem);
          }
        });
        poly2AddToMap.forEach((poly) => {
          if (!isEmpty(poly.coords)) {
            const pItem = addPoly(poly);
            if (pItem) {
              mapConvex_n.push(pItem);
              if (!updateP) updateP = true;
            }
          }
        });
      }
      if (updateP) {
        setMapConvex(mapConvex_n);
      }
    }
  }, [convex]);
};

export default { useMapConvexItems };

