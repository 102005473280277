import { MutableRefObject, useEffect } from 'react';
import { ILSMap } from '@core/containers/map';
import { getVehicleCoordinates } from '@modules/vehicle-monitoring/decorators';
import { IVehicleMonitoringData } from '@modules/vehicle-monitoring/types/models';

export const useFocusOnCar = ({
  Map,
  vehicleTracker
}: {
  Map: MutableRefObject<ILSMap | null>;
  vehicleTracker?: IVehicleMonitoringData['VehicleTracker'];
}) => {
  useEffect(() => {
    // Если isInit то markerLayer и trackLayer - L.LayerGroup
    if (Map.current) {
      Map.current?.setCenter(getVehicleCoordinates(vehicleTracker?.[0]));
    }
  }, [vehicleTracker]);
};

