import { ILSCascader } from '@components/index';
import { IILSCellConfigurableCascaderApiProps } from '@common/types/components/table/cell';
import { useApiOptions } from '@components/data-display/table/hooks/cell/use-api-options';
import { TableRecord } from '@common/types';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { isCascaderValueType } from '@components/data-display/table/components/cell-types/editable/ils-cell-cascader-api';

export const ILSCellConfigurableCascaderApi = <R extends TableRecord>({
  save,
  tableRef,
  toggleEdit,
  children,
  dictionaryInfo,
  dictionaryLoad,
  dictionary,
  options,
  fieldNames,
  dropdownMenuColumnStyle
}: IILSCellConfigurableCascaderApiProps<R>): JSX.Element => {
  const [node, defaultValue] = children;
  const loading = dictionaryInfo?.[dictionary]?.isFetching;
  useApiOptions({ dictionary, dictionaryLoad });

  if (!isCascaderValueType(defaultValue)) {
    showUserAndDevError({ error: 'Каскадное значение - массив из двух value[] значений value и children[value]' });
    return <></>;
  }
  return (
    <>
      {node}
      <ILSCascader
        className={'cell-wrapper-editable-full'}
        autoFocus
        open
        showChild
        fieldNames={fieldNames}
        loading={Boolean(loading)}
        allowClear={false}
        value={defaultValue}
        options={options}
        onChange={save}
        dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        expandTrigger={'hover'}
        defaultValue={defaultValue}
        onBlur={toggleEdit}
        tableRef={tableRef}
      />
    </>
  );
};
