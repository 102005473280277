import { CellType, TableConfig } from '@common/types';
import { generateUUID } from '@common/utils/general/uuid';
import { ID_COLUMN } from '@common/models/all/columns';

export const NEW_COUPLING_TYPE = {
  Name: 'Новый тип связки',
  CouplingCode: generateUUID()
};

export const COUPLING_TYPE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_COUPLING_TYPE,
  reloading: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 150,
      ellipsis: true,

      sorting: 2,
      filtering: 'string'
    },
    {
      title: 'Код',
      dataIndex: 'CouplingCode',
      key: 'CouplingCode',
      invisible: true,
      type: CellType.Input,
      width: 50
    }
  ]
};
