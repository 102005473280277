import { createTripRoutine } from '@modules/planning/children/by-date/actions';
import { RouteRecord } from '@modules/planning/children/by-date/types';
import { Dispatch } from 'redux';
import { CreateTripPayload } from '@modules/planning/children/by-date/sagas/trip/create-trip';

export const createTrip =
  ({ record, dispatch }: { record: RouteRecord; dispatch: Dispatch }) =>
  (_?: unknown, startDepot = false) => {
    const payload: CreateTripPayload = {
      vehicleID: record.ID,
      planID: record.PlanID,
      startDepot
    };
    dispatch(createTripRoutine(payload));
  };
