import { FC } from 'react';
import { EMPTY_STRING } from '@common/constants';
import { DETAILS_CONTENT } from '@modules/orders-and-trips/types/details';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { VehicleType } from '@modules/orders-and-trips/types/vehicle';
import { DetailsContentGeneral } from '@modules/orders-and-trips/components/details/details-content-general';
import { DetailsMap } from '@modules/orders-and-trips/components/details/details-map';

interface IProps {
  detailsContent: DETAILS_CONTENT;
  tripDetails: {
    distance: number;
    mass: number;
    volume: number;
    vehicleType: number;
    loadingType: string;
  };
  vehicleTypes: VehicleType | null;
}

export const renderDetailsContent: FC<IProps> = ({ detailsContent, tripDetails, vehicleTypes }) => {
  switch (detailsContent) {
    case DETAILS_CONTENT.GENERAL:
      return <DetailsContentGeneral key={'details_content'} tripDetails={tripDetails} vehicleTypes={vehicleTypes} />;
    case DETAILS_CONTENT.MAP:
      return <DetailsMap key={'details_map'} detailsContent={detailsContent} />;
    default:
      showUserAndDevError({ error: 'Ошибка отображения деталей рейса', userError: EMPTY_STRING });
      return <></>;
  }
};

