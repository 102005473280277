import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Account } from '@common/types';
import { ILSCarriersTable } from '@modules/tender-platform/children/tender-own/components/carriers/table';
import { TenderPlatformCarriersFormContainer } from '@modules/tender-platform/children/tender-own/components/carriers/form';
import { TenderPlatformPaths } from '@modules/tender-platform/types';
import { addCarriersRoutine, deleteCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { carriersFetchingSelector, myCarriersSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { carriers2Table } from '@modules/tender-platform/children/tender-own/utils/decorators/carriers-to-table';
import BackButton from '../components/carriers/back-button';
import { ModulePath } from '@common/types/modules';
import { useCarriers } from '@modules/tender-platform/children/tender-own/utils/hooks/use-carriers';
import { TENDER_PLATFORM_MY_CARRIERS } from '@modules/tender-platform/children/tender-own/constants/content';
import { TenderPlatformMainTitleComponent } from '@modules/tender-platform/components/tender-card/cards-title';
import { tenderCarriersTableConfig } from '@modules/tender-platform/children/tender-own/utils/table-configs/carriers';

interface IProps {
  showForm: boolean;

  handleClick(path: TenderPlatformPaths): void;
}

export const TenderOwnMyCarriers: FC<IProps> = ({ handleClick, showForm }) => {
  const dispatch = useDispatch();
  useCarriers(dispatch);
  const addCarriers = (ids: Array<Account['ID']>) => dispatch(addCarriersRoutine({ ids }));
  const carriers = useSelector(myCarriersSelector);
  const link = `${ModulePath.TenderPlatform}${ModulePath.TenderOwn}`;
  const dataSource = carriers2Table(carriers);
  const IsCarriersFetching = useSelector(carriersFetchingSelector);
  const handleDelete = (ids: Array<Account['ID']>) => {
    dispatch(deleteCarriersRoutine({ ids }));
  };

  const actionTableButtons = [
    {
      Component: BackButton,
      props: { link }
    }
  ];

  return (
    <>
      <TenderPlatformMainTitleComponent cardsPageTitle={TENDER_PLATFORM_MY_CARRIERS} />
      <ILSCarriersTable
        config={tenderCarriersTableConfig}
        loading={IsCarriersFetching}
        dataSource={dataSource ?? []}
        handleCreate={() => handleClick(TenderPlatformPaths.Add)}
        handleDelete={handleDelete}
        actionTableButtons={actionTableButtons}
      />
      <TenderPlatformCarriersFormContainer
        showForm={showForm}
        onOk={addCarriers}
        onCancel={() => handleClick(TenderPlatformPaths.CarriersList)}
      />
    </>
  );
};
