import { useCatalog } from '@modules/planning/children/by-date/hooks/use-catalog';
import { useCallback, useEffect, useState } from 'react';
import { Dictionary, ShowDeleted } from '@common/types';

export const useCatalogInTable = (dictionary: Dictionary) => {
  const [showDeleted, setShowDeleted] = useState<ShowDeleted>(ShowDeleted.Yes);

  const { catalogLoad } = useCatalog();

  const handleReload = useCallback(
    (keys, showDeleted) => {
      catalogLoad(dictionary, showDeleted);
    },
    [dictionary, showDeleted]
  );

  useEffect(() => {
    catalogLoad(dictionary, showDeleted);
  }, [dictionary]);

  return { handleReload, setShowDeleted, showDeleted, catalogLoad };
};
