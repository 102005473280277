import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentApi } from '@common/feature/payment/api';
import { getInvoiceTemplateRoutine } from '@common/feature/payment/actions';

function* getInvoiceTemplateWorker(
  action: PayloadAction<{
    invoiceSum: string;
  }>
) {
  const { request, success, failure, fulfill } = getInvoiceTemplateRoutine;
  const { invoiceSum } = action.payload;
  try {
    yield put(request());
    const {
      data: { data: template }
    } = yield call(PaymentApi.getBalanceInvoiceTemplate);
    const {
      data: { data }
    } = yield call(PaymentApi.getBalanceInvoiceData, { invoiceSum });
    yield put(success({ template: template.template, data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getInvoiceTemplateWatcher() {
  yield takeLatest(getInvoiceTemplateRoutine.trigger, getInvoiceTemplateWorker);
}

