import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsPlanningStore } from '../types/store';
import { markBoardWidget } from '../actions';
import initialState from '../store';
import { AnalyticsWidget } from '@common/types/dictionaries/analytics';

export const markBoardWidgetReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [markBoardWidget.REQUEST]: (state, action: PayloadAction<AnalyticsWidget>) => {
    const widget = action.payload;
    if (widget) {
      const newWidgets = Object.values(state.Widgets).reduce((prev, cur) => {
        if (cur.ID === widget.ID) {
          prev[cur.ID] = { ...cur, isDeleted: true };
        } else {
          prev[cur.ID] = { ...cur };
        }
        return prev;
      }, {});
      state.Widgets = { ...newWidgets };
    }

    return state;
  }
});
