import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import CustomForAuthPage from './custom-page-container';

export default defineModule({
  title: 'Главная',
  path: '/pages',
  component: CustomForAuthPage,
  useScope: [ModuleScope.CustomForAuthPage]
});
