import { ILSButton, ILSForm, ILSFormItem, ILSInput, ILSRadio, ILSRadioGroup, ILSSpin, ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { clearContractorList, sendContractorRoutine, suggestContractorRoutine } from '@modules/documents/actions';
import { getSuggestContractor } from '@modules/documents/selectors';
import { Document } from '@modules/documents/types/document';
import { Form, RadioChangeEvent } from 'antd';
import { FC, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import './styles.less';

interface Props {
  data: Document;
}

const SendMenuItem: FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const contractors = useAppSelector(getSuggestContractor);
  const [value, setValue] = useState<number | null>(null);
  const [currentValue, setCurrentValue] = useState<number | null>(1);
  const [form] = Form.useForm();

  const docContractor = data['depends[Contractor]'];
  const checked = useMemo(() => !!currentValue, [value, currentValue]);

  const handleSendToContractor = (doc: Document) => {
    let payload: { [key: string]: number } = {
      docID: doc.ID
    };

    if (!currentValue && value) {
      payload = {
        ...payload,
        contractorID: value
      };
    }

    dispatch(sendContractorRoutine(payload));
    dispatch(clearContractorList());
    form.resetFields();
    setValue(null);
  };

  const handleSearchContractor = (values: { name: string }) => {
    if (!values.name) {
      ILSUserNotify(Notify.Warning, 'Заполните поле...' as any, 3);
      return;
    }

    dispatch(suggestContractorRoutine(values));
  };

  const onChange = (e: RadioChangeEvent, current?: boolean) => {
    if (current) {
      setCurrentValue(e.target.value);
      setValue(null);
      return;
    }

    setCurrentValue(null);
    setValue(e.target.value);
  };

  return (
    <div className={'container'}>
      <div className={'select-item'}>
        <ILSForm form={form} name="contractor" onFinish={handleSearchContractor} className="search-contractor">
          <ILSFormItem name={'name'} className="search-contractor__input">
            <ILSInput maxLength={30} placeholder="Введите контрагента" />
          </ILSFormItem>

          <ILSButton htmlType="submit" className="contractor-search" icon={<ReactSVG src={'/assets/icons/search.svg'} />} />
        </ILSForm>
      </div>

      {docContractor ? (
        <div className="current-contractor">
          <p>Контрагент по данному документу: </p>
          <ILSRadio onChange={(e) => onChange(e, true)} defaultChecked={checked} checked={checked} value={1}>
            {docContractor}
          </ILSRadio>
        </div>
      ) : null}

      <ILSSpin spinning={contractors.isFetching}>
        {contractors?.data?.length ? (
          <div className="contracts-list">
            <ILSRadioGroup className="d-flex flex-direction-column contractors-group" onChange={onChange} value={value}>
              {contractors?.data?.map((item: any) => (
                <ILSRadio key={item.ID + '' + item.Name} value={item.ID}>
                  {item.Name}
                </ILSRadio>
              ))}
            </ILSRadioGroup>
          </div>
        ) : (
          <p className="no-data">Нет данных</p>
        )}
      </ILSSpin>

      {value || docContractor ? (
        <ILSButton onClick={() => handleSendToContractor(data)} type="primary">
          Отправить
        </ILSButton>
      ) : null}
    </div>
  );
};

export default SendMenuItem;
