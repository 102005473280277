import { isEmpty, isNil, isObject, omitBy } from 'lodash';

type Settings = {
  needClearEmptyString?: boolean;
  needClearNullable?: boolean;
  checkIsEmpty?: boolean;
};

/**
 * Clean keys in Objects if value is 'undefined'
 * Can cleaning falsy values (default keep null and empty string)
 * @param object
 * @param settings
 */
export const cleanEmptyFields = (
  object: Object,
  settings: Settings = { needClearEmptyString: false, needClearNullable: false, checkIsEmpty: true }
) => {
  const { needClearEmptyString, needClearNullable, checkIsEmpty } = settings;
  if (checkIsEmpty && isEmpty(object)) {
    throw new Error();
  }
  for (let field in object) {
    if (typeof object[field] === 'undefined') {
      delete object[field];
    }
    if (needClearEmptyString && typeof object[field] === 'string' && Boolean(!object[field].length)) {
      delete object[field];
    }
    if (needClearNullable && object[field] === null) {
      delete object[field];
    }
    if (Array.isArray(object[field])) {
      object[field] = object[field].filter((item: any) => {
        if (isObject(item)) {
          cleanEmptyFields(item, { checkIsEmpty: false });
          return !isEmpty(item);
        }
        return typeof item === 'number' || Boolean(item);
      });
      if (!object[field].length) {
        delete object[field];
      }
    }
  }
};

export const cleanIsNilObjectFields = (object: Object) => omitBy(object, isNil);

export const cleanEmptyFieldsInArrayObject = (array: Array<object>) => {
  array?.forEach((item) => cleanEmptyFields(item));
};
