import { ILSButton, ILSCheckbox } from '@components/index';
import CustomScroll from 'react-customscroll';
import { FC, useEffect } from 'react';
import { IPropsILSMapMenuComponent } from '@modules/catalog/types/components';
import { Dictionary } from '@common/types';

const ILSMapMenuComponent: FC<IPropsILSMapMenuComponent> = ({
  setShow,
  menu,
  onChange,
  table,
  active,
  dictionary,
  handleDictionaryLoad
}) => {
  const loadMapData = (dictionary?: Dictionary) => {
    dictionary && handleDictionaryLoad?.(dictionary);
  };

  useEffect(() => {
    if (menu && menu.length) {
      menu
        .filter((menuItem) => menuItem.dictionary !== dictionary)
        .forEach((item) => {
          loadMapData(item.dictionary);
        });
    }
  }, []);
  return (
    <div className={`p-1 bordered ${table ? 'table-btn-menu' : 'map-btn-menu'}`}>
      <div className="my-2 menu-content px-2">
        {/* @ts-ignore */}
        <CustomScroll>
          {/* @ts-ignore */}
          {Boolean(menu && menu?.length) ? (
            menu?.map((item) => (
              <div className="mb-2" key={item.ID}>
                <ILSCheckbox
                  checked={Boolean(active?.includes(item.ID))}
                  onChange={(e) => {
                    onChange(e.target.checked, item);
                  }}
                >
                  {item.Name}
                </ILSCheckbox>
              </div>
            ))
          ) : (
            <span>В данном каталоге специфики не найдены</span>
          )}
        </CustomScroll>
      </div>
      <div className="flex-between-center m-1">
        <ILSButton onClick={() => onChange(null, null, true)}>Сброс</ILSButton>
        <ILSButton type="primary" onClick={() => setShow(false)}>
          OK
        </ILSButton>
      </div>
    </div>
  );
};

export default ILSMapMenuComponent;
