import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { addTrackToQueryState } from '@common/utils/get-track/add-track-to-query-state';
import { trackAddTrackToQueryRoutine } from '@modules/track/actions';
import { PointType } from '@common/utils/get-track/types';
import initialState from '@modules/track/store';

const AddTrackToQueryReducer = createReducer(initialState, {
  [trackAddTrackToQueryRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      points: { [pointKey: string]: PointType };
    }>
  ) => {
    const { points } = action.payload;
    state.planPoints.planDataQuery = addTrackToQueryState(state.planPoints.planDataQuery, points);
    return state;
  }
});

export default AddTrackToQueryReducer;
