import { CellType, DateFormat, TableColumnName, TableConfig } from '@common/types';
import { NEW_MODEL } from '@components/data-display/table/constants';
import { ID_COLUMN } from '@common/models/all/columns';

export const RAMP_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  unchangeable: true,
  adding: NEW_MODEL,
  columns: [
    ID_COLUMN,
    {
      title: 'Группа',
      dataIndex: 'Ind',
      key: 'Ind',
      active: true,

      editable: true,
      ellipsis: true,
      type: CellType.Int,
      sorting: 2,
      filtering: CellType.Input,

      required: true
    },
    {
      title: 'Местоположение',
      dataIndex: 'DepotID',
      allowClear: false,
      key: 'DepotID',
      active: true,

      editable: true,
      sorting: 3,
      filtering: 'array',
      ellipsis: false,
      type: CellType.CascaderAPI,

      required: true
    },
    {
      title: 'Рампа',
      dataIndex: 'Name',
      key: 'Name',
      sorting: 4,
      filtering: CellType.Input,
      active: true,

      editable: true,
      ellipsis: true,
      type: CellType.Input,
      required: true
    },
    {
      title: 'Тип рампы',
      dataIndex: 'RampTypeID',
      key: 'RampTypeID',
      active: true,

      editable: true,
      sorting: 5,
      filtering: CellType.Input,
      ellipsis: true,
      type: CellType.SelectAPI,

      required: true
    },
    {
      title: 'ТС',
      dataIndex: 'VehicleName',
      key: 'VehicleName',
      active: false,

      sorting: 7,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.Input
    },
    {
      title: 'Гос. номер',
      dataIndex: 'VehicleRegNumber',
      key: 'VehicleRegNumber',
      active: false,

      sorting: 8,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.RegNumber
    },
    {
      title: 'Начало',
      dataIndex: 'StartDate',
      key: 'StartDate',
      active: false,

      editable: true,
      ellipsis: false,
      type: CellType.Date,
      format: DateFormat.YYYYMMDDSlash,
      sorting: 11,
      filtering: CellType.Input,
      width: 30
    },
    {
      title: 'Окончание',
      dataIndex: 'EndDate',
      key: 'EndDate',
      active: false,

      editable: true,
      ellipsis: false,
      type: CellType.Date,
      format: DateFormat.YYYYMMDDSlash,
      sorting: 12,
      filtering: CellType.Input,
      width: 30
    },
    {
      title: 'Ограничения',
      dataIndex: TableColumnName.RestrictionID,
      key: TableColumnName.RestrictionID,
      active: true,

      sorting: 13,
      filtering: 'array',

      editable: true,
      ellipsis: true,
      type: CellType.SelectMultipleApi
    },
    {
      title: 'Прибытие',
      dataIndex: 'ArrivalTime',
      key: 'ArrivalTime',
      active: false,

      sorting: 14,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble,
      width: 30
    },
    {
      title: 'Убытие',
      dataIndex: 'DepartureTime',
      key: 'DepartureTime',
      active: false,

      sorting: 15,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.TimeDouble,
      width: 50
    },
    {
      title: 'Окно работы точки',
      dataIndex: 'DepotWorktime',
      key: 'DepotWorktime',
      active: false,

      sorting: 16,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.RangeTime,
      width: 50
    },
    {
      title: 'Окно работы рампы',
      dataIndex: 'RampWorktime',
      key: 'RampWorktime',
      active: false,

      sorting: 17,
      filtering: CellType.Input,
      editable: true,
      ellipsis: true,
      type: CellType.RangeTime,
      width: 50
    }
  ]
};
