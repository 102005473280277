/// <reference path="../../../node_modules/@types/leaflet/index.d.ts" />

//NOTE Расширение описанных типов в leaflet
namespace L {
  export interface MarkerOptions {
    draggable?: boolean;
    data?: any;
    color?: string;
  }
}
