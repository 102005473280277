import { TimeStamp } from '../general';
import { Account } from './account';
import { IndexedArray } from '@common/types';

export enum ZoneType {
  Delivery,
  Surveillance
}

export type Zone = {
  ID: number;
  Priority: number | null;
  Name: string;
  Color: number | null;
  BorderHash: string | null;
  Type: ZoneType;
  AccountID: Account['ID'];
  Deleted: TimeStamp | null;
  Tariff: number | null;
  ZoneCode: string;
  Description: string | null;
  Queue: number | null;
  PlanConfigID: PlanConfigID | null;
  WorkSchedule: string | null; // WeekDay "0;1;2;3;4;5;6"
  FriendZoneID?: Array<string>;
};

export type Zones = Array<Zone> | IndexedArray<Zone>;

export enum PlanConfigID {
  Default = 1,
  BestConf = 3
}

export enum ZoneIsActive {
  No,
  Yes
}
