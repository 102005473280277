import '../styles.less';
import { FC, useEffect } from 'react';
import { ILSForm, ILSSkeleton, ILSSpin } from '@common/components';
import { Module } from '@common/types';
import { getInitialRoutine, uploadSchemeRoutine } from '@modules/planning/children/scheme-editor/actions';
import { useDispatch, useSelector } from 'react-redux';
import { schemeEditorRootContainerSelector } from '@modules/planning/children/scheme-editor/selectors';
import { SchemeEditorTabs } from '@modules/planning/children/scheme-editor/containers/tabs';
import { SchemeFormValues } from '@modules/planning/children/scheme-editor/types';

//TODO: написать unit TMS-4522
export const PlannerSchemeEditor: FC = () => {
  const dispatch = useDispatch();
  const { displayedTabPanesSchemes, initialValues, isFetching, isLoading } = useSelector(schemeEditorRootContainerSelector);
  const onFinish = (values: SchemeFormValues) => dispatch(uploadSchemeRoutine({ values }));

  useEffect(() => {
    dispatch(getInitialRoutine());
  }, []);

  if (isLoading) {
    return (
      <section className={'scheme-editor loading-spinner'}>
        <ILSSpin />
      </section>
    );
  }

  return (
    <ILSSkeleton loading={isFetching}>
      <section className={'scheme-editor'}>
        <ILSForm onFinish={onFinish} name={Module.SchemeEditor} initialValues={initialValues}>
          <SchemeEditorTabs displayedTabPanesSchemes={displayedTabPanesSchemes} />
        </ILSForm>
      </section>
    </ILSSkeleton>
  );
};
