import { CONTACT_TABLE_CONFIG } from '@common/models/contact/config/table';
import { contact2table } from '@common/models/contact/decorators';
import { Dictionary } from '@common/types';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { tableCustom } from '@core/containers/table-custom';
import { FC } from 'react';
import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';

const PlanningContact: FC<IILSTablePlanningComponentProps> = (props) => {
  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={props.config}
      handleDecorator={table2data}
      dataDecorator={contact2table}
      dictionary={Dictionary.Contact}
    />
  );
};

export const ContactTable = tableCustom(PlanningContact, CONTACT_TABLE_CONFIG, Dictionary.Contact);
