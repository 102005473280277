import { Notify } from '@common/types';

export const ParamsKeys = {
  PlanNotices: 'PlanNotices'
};

export const PLAN_NOTICE_TYPE = {
  0: Notify.Success,
  1: Notify.Info,
  2: Notify.Warning,
  3: Notify.Error
};

export const PARAMS_KEY = {
  PROJECT_ID: 'projectID',
  PLAN_ID: 'planID'
};
