import { FC } from 'react';
import { forwarderPointStatusesTableConfig } from '@modules/settings/config/mobile-application/forwarder-point-statuses-table-config';
import { useDispatch, useSelector } from 'react-redux';
import { settingsForwarderPointStatusesTableSelector } from '@modules/settings/selectors/components/settings-forwarder-point-statuses-table';
import { SettingsForwarderPointStatusesTableComponent } from '@modules/settings/components/mobile-application/settings-forwarder-point-statuses-table';

export const SettingsForwarderPointStatusesTableContainer: FC = () => {
  const dispatch = useDispatch();
  const { handleSave, refData, dataSource, handleDelete, handleCreate, onCanEdit, focusRow } = useSelector(
    settingsForwarderPointStatusesTableSelector(dispatch)
  );

  return (
    <SettingsForwarderPointStatusesTableComponent
      handleDelete={handleDelete}
      handleCreate={handleCreate}
      onCanEdit={onCanEdit}
      refData={refData}
      handleSave={handleSave}
      dataSource={dataSource}
      config={forwarderPointStatusesTableConfig}
      focusRow={focusRow}
    />
  );
};
