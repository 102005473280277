import { MutableRefObject, useEffect } from 'react';
import { PlanningMarker } from '@modules/planning/children/by-date/hooks/map/map-hooks-item-markers';
import { isEmpty, isObject } from 'lodash';
import { Dictionary, IMapTrack } from '@common/types';
import { latLngBounds, LatLngBoundsExpression, LatLngExpression, LatLngTuple } from 'leaflet';
import { IPlanningMapProps } from '@modules/planning/children/by-date/containers/result/map-container';
import { MapLayers } from '@modules/planning/children/by-date/types';
import { useAppSelector } from '@core/hooks';
import { isInitialSelector } from '@modules/planning/children/by-date/selectors/project';
import { isLatLngTuple } from '@common/types/general';
import { getBoundsForMarkers, getBoundsForPolylines, getLatLngExpression } from '@common/utils';

export const MAP_PLANNING_FIT_BOUNDS_OPTIONS = { padding: [20, 50] };

export const setOrderTrackFitBound = ({
  Map,
  markers = [],
  polyline = []
}: {
  Map: MutableRefObject<any>;
  markers?: PlanningMarker;
  polyline?: IMapTrack[];
}) => {
  if (Map.current) {
    const currentMap = Map.current;
    const markerCoordinates = getBoundsForMarkers(markers);
    const polylineCoordinates = getBoundsForPolylines(polyline);
    const bounds = [...markerCoordinates, ...polylineCoordinates];
    if (!isEmpty(bounds)) {
      setTimeout(() => {
        const boundEntity = latLngBounds(bounds);
        if (boundEntity.isValid()) {
          currentMap.fitBounds(boundEntity, MAP_PLANNING_FIT_BOUNDS_OPTIONS);
        }
      });
    }
  }
};

export const useOrderTrackFitBound = ({
  Map,
  markers = [],
  polyline = [],
  triggerMapFocus,
  isInit,
  activePlanID
}: {
  activePlanID: number | null;
  Map: MutableRefObject<any>;
  markers: PlanningMarker;
  polyline: IMapTrack[];
  triggerMapFocus: IPlanningMapProps['triggerMapFocus'];
  isInit: MapLayers['isInit'];
}) => {
  const initial = useAppSelector(isInitialSelector);

  useEffect(() => {
    if (triggerMapFocus?.length && Map.current) {
      const bounds: LatLngExpression | LatLngBoundsExpression = [];
      const boundsMap: ReturnType<typeof latLngBounds> = latLngBounds([]);

      triggerMapFocus.forEach((item) => {
        if (item?.dictionary && polyline?.length) {
          if (item.dictionary === Dictionary.Plan) {
            markers?.forEach((m) => {
              if (!isEmpty(m.coords)) {
                bounds.push(getLatLngExpression(m?.coords) as LatLngTuple);
              }
            });
          }
          polyline.forEach((poly) => {
            const inPlan =
              item.dictionary === Dictionary.Plan && item.PlanID && poly && poly?.clickData && poly.clickData.PlanID === item.PlanID;
            const inRoute =
              item.dictionary === 'Route' &&
              item.PlanID &&
              poly?.clickData &&
              poly.clickData.PlanID === item.PlanID &&
              poly.clickData.VehicleID === item.VehicleID;
            const inTrip =
              item.dictionary === 'Trip' &&
              item.PlanID &&
              poly?.clickData &&
              poly.clickData.PlanID === item.PlanID &&
              poly.clickData.TripID === item.TripID;
            const inWaypoint =
              item.dictionary === Dictionary.Waypoint &&
              item.PlanID &&
              poly?.clickData &&
              poly.clickData.PlanID === item.PlanID &&
              poly.clickData.WaypointID === item.WaypointID;
            if (inPlan || inRoute || inTrip || inWaypoint) {
              if (!isEmpty(poly?.coords) && Array.isArray(poly.coords) && isLatLngTuple(poly.coords)) {
                bounds.push(poly.coords);
              } else if (poly.nativeCoords) {
                const native = [poly.nativeCoords?.from, poly.nativeCoords?.to].reduce((acc: LatLngTuple[], coordinatesItem) => {
                  if (isObject(coordinatesItem) && !isEmpty(coordinatesItem)) {
                    acc.push(Object.values(coordinatesItem) as LatLngTuple);
                  }
                  return acc;
                }, []);
                bounds.push(...native);
              }
            }
          });
        }
      });

      if (bounds?.length) {
        boundsMap.extend(bounds);
        if (boundsMap.isValid()) {
          Map.current?.fitBounds(boundsMap, MAP_PLANNING_FIT_BOUNDS_OPTIONS);
        }
      }
    }
  }, [triggerMapFocus]);

  useEffect(() => {
    if (isInit && initial) {
      setOrderTrackFitBound({ markers, Map, polyline });
    }
  }, [isInit, initial]);

  useEffect(() => {
    if (isInit) {
      setOrderTrackFitBound({ markers, Map, polyline });
    }
  }, [activePlanID, isInit, initial]);
};
