import { settingsSaveRoutine } from '@modules/settings/actions';
import { DEFAULT_MONITORING_PARAMS } from '@modules/settings/config/monitoring/default-monitoring-params';
import { DEFAULT_PLANNER_PARAMS } from '@modules/settings/config/planning/default-planner-params';
import { SettingsChangePasswordButtonAction } from '@modules/settings/constants';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const useSettingsActions = () => {
  const [confirm, setConfirm] = useState<boolean | string>(false);
  const dispatch = useDispatch();

  const resetSettings = () => dispatch(settingsSaveRoutine({ value: { ...DEFAULT_PLANNER_PARAMS, ...DEFAULT_MONITORING_PARAMS } }));
  const setDefaultSettings = () => setConfirm(SettingsChangePasswordButtonAction.SetDefault);

  return { confirm, resetSettings, setDefaultSettings, setConfirm };
};
