import { useState } from 'react';

export enum CargoAmountAction {
  add = 'add',
  remove = 'remove'
}

export const useCargosButtons = () => {
  const [cargos, setCargos] = useState(1);
  const cargoFields = Array.from({ length: cargos });

  const handleCargosAmount = (action: CargoAmountAction) => () => {
    switch (action) {
      case 'add':
        setCargos((prev) => ++prev);
        break;
      case 'remove':
        setCargos((prev) => --prev);
        break;
      default:
        break;
    }
  };

  return { handleCargosAmount, cargoFields };
};
