import { FC } from 'react';
import { ILSButton, ILSPopover } from '@common/components';
import { useModal } from '@common/hooks/components/use-modal';
import { AddReportForm } from '@modules/documents/containers/reports/add-report-form';
import { ReportsListRequest } from '@modules/documents/types/documents-api';
import { Project } from '@common/types';
import { ROOT_PROJECT_ID_MONITORING } from '@modules/documents/constants/reports';

interface IProps {
  projectId?: Project['ID'] | null;
  module: ReportsListRequest['module'];
}

export const DocumentsAddReportButtonComponent: FC<IProps> = ({ module, projectId }) => {
  const { visible, handleShow } = useModal();
  return (
    <ILSPopover
      visible={visible}
      arrowContent={false}
      placement="bottomRight"
      trigger="click"
      title={'Добавление шаблона'}
      content={<AddReportForm projectId={projectId ?? ROOT_PROJECT_ID_MONITORING} module={module} handleShow={handleShow} />}
    >
      <ILSButton onClick={handleShow} children={visible ? 'Отменить' : 'Добавить отчёт'} danger={visible} />
    </ILSPopover>
  );
};
