import { PlanningAPI } from '@modules/planning/api';
import { planningCancelRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlanRequest } from '@modules/planning/types/api';

function* planningCancelWorker(action: PayloadAction<PlanRequest>) {
  const { request, success, failure, fulfill } = planningCancelRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(PlanningAPI.cancelPlanning, { planID });
    yield put(success({ planID, message: data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* planningCancelWatcher() {
  yield takeLatest(planningCancelRoutine.trigger, planningCancelWorker);
}
