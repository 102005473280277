import { createSelector } from 'reselect';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { activePlanIDSelector } from './active-plan';
import { IDecorateValidationData } from '@common/types';

export const matrixStatusSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.matrix?.status,
  (matrixStatus) => matrixStatus
);

export const matrixIsLoadingSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.matrix?.loading,
  (isLoading) => isLoading
);

export const validationDataSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.validationData,
  (validationData) => validationData
);

export const validationStatusSelector = createSelector(
  (state: PlanningByDateState) => state.PlanningByDate.validationStatus,
  (validationStatus) => validationStatus
);

export const activePlanErrorsSelector = createSelector(activePlanIDSelector, validationDataSelector, (activePlanID, validationData) => {
  return activePlanID ? validationData?.[activePlanID]?.CountAll : ({} as IDecorateValidationData['CountAll']);
});
