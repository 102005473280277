import { IndexedArray, TableRecord } from '@common/types';
import { Tracker } from '@common/types/dictionaries/tracker';

export const data2Table = (
  data: IndexedArray<Tracker> | Array<Tracker> | undefined
): Array<
  TableRecord & {
    IMEI: number | string;
  }
> => {
  const result: Array<
    TableRecord & {
      IMEI: number | string;
    }
  > = [];

  if (!data || !Object.values(data).length) {
    return result;
  }

  return Object.entries(data).map(([id, value]) => ({
    key: id,
    IMEI: value?.IMEI,
    SerialNo: value?.SerialNo,
    SimNumber: value?.SimNumber,
    SimTariff: value?.SimTariff
  }));
};

