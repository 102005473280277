import { FakePreCostProposals, FakeTenderId, IntegrationServicesResponse } from '@modules/orders-and-trips/types/fake-pre-cost';
import { Currency, EMPTY_STRING } from '@common/constants';
import { getProposalTerm } from '@modules/orders-and-trips/helpers/fake-pre-cost/get-proposal-term';
import { getProposalDescription } from '@modules/orders-and-trips/helpers/fake-pre-cost/get-proposal-description';
import { isEmpty, isNil } from 'lodash';
import { createArrayFromIndexedArray } from '@common/utils';

export const getProposalsFromServices = (
  { name, prices, convertingErrors, requestError }: IntegrationServicesResponse,
  tenderId: FakeTenderId
): FakePreCostProposals | [] => {
  if (!name) return [];
  const serviceInfo = { tenderId, convertingErrors, requestError, name };

  if (isEmpty(prices) || isNil(prices)) {
    return [
      {
        id: 0,
        ...serviceInfo,
        price: 0,
        info: EMPTY_STRING,
        description: getProposalDescription({ convertingErrors }),
        term: EMPTY_STRING,
        currency: Currency.RUB,
        maxDelivery: Number.MAX_SAFE_INTEGER,
        minDelivery: Number.MAX_SAFE_INTEGER
      }
    ];
  }

  return createArrayFromIndexedArray(prices).map(({ info, id, price, currency, maxDelivery, minDelivery, unitDelivery, ...rest }) => {
    const term = getProposalTerm({ maxDelivery, minDelivery, unitDelivery });
    const description = getProposalDescription({ description: rest.description, convertingErrors });

    return {
      id,
      ...serviceInfo,
      price: Number(price),
      description,
      term,
      currency,
      maxDelivery,
      minDelivery,
      info
    };
  });
};
