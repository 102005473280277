import { useAppDispatch, useAppSelector } from '@core/hooks';
import { FC, useEffect, useState } from 'react';
import { emulatorPauseRoutine, emulatorStartRoutine, emulatorStopRoutine } from '../../actions';
import {
  commandSelector,
  emulationConfigsSelector,
  emulationSpeedSelector,
  errorTextSelector,
  loadedPlanIDSelector,
  statusSelector
} from '../../selectors';
import { EmulatorStatus, EmulatorStatusText, actionButtonsCaption, emulatorCommands } from '../../constants';
import ILSActionButtonsComponent from '../../components/action-buttons/action-buttons';

// Контейнер для отображения кнопок старта / остановки и паузы / возобновления работы
export const ILSActionButtonsContainer: FC = () => {
  const dispatch = useAppDispatch();

  const loadedPlanID = useAppSelector(loadedPlanIDSelector);
  const emulationSpeed = useAppSelector(emulationSpeedSelector);

  const { Status: configStatus } = useAppSelector(emulationConfigsSelector);
  const status = useAppSelector(statusSelector);
  const command = useAppSelector(commandSelector);
  const errorText = useAppSelector(errorTextSelector);

  /**
   * Текущий тип первой кнопки в меню управления запуска эмуляции
   */
  const [startStopCaption, setStartStopCaption] = useState<string>(actionButtonsCaption.Start);
  /**
   * Текущий тип второй кнопки в меню управления запуска эмуляции
   */
  const [pauseResumeCaption, setPauseResumeCaption] = useState<string>(actionButtonsCaption.Pause);
  /**
   * Отключена ли первая кнопка
   */
  const [startStopIsDisabled, setStartStopIsDisabled] = useState<boolean>(true);
  /**
   * Отключена ли вторая кнопка
   */
  const [pauseResumeIsDisabled, setPauseResumeIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    /**
     * Текущий статус эмуляции
     */
    const actualStatus = status || configStatus;

    if (actualStatus === EmulatorStatus.Error) {
      setStartStopCaption(actionButtonsCaption.Start);
      setPauseResumeCaption(actionButtonsCaption.Continue);
      setStartStopIsDisabled(false);
      setPauseResumeIsDisabled(true);
      return;
    }

    if (
      (command === emulatorCommands.Stop && actualStatus !== EmulatorStatus.Stop) ||
      (command === emulatorCommands.Pause && actualStatus !== EmulatorStatus.Pause)
    ) {
      setStartStopIsDisabled(true);
      setPauseResumeIsDisabled(true);
    } else {
      switch (actualStatus) {
        case EmulatorStatus.Init:
          setStartStopCaption(actionButtonsCaption.Stop);
          setPauseResumeCaption(actionButtonsCaption.Pause);
          setStartStopIsDisabled(false);
          setPauseResumeIsDisabled(false);
          break;
        case EmulatorStatus.Emulation:
          setStartStopCaption(actionButtonsCaption.Stop);
          setPauseResumeCaption(actionButtonsCaption.Pause);
          setStartStopIsDisabled(false);
          setPauseResumeIsDisabled(false);
          break;
        case EmulatorStatus.Pause:
          setStartStopCaption(actionButtonsCaption.Stop);
          setPauseResumeCaption(actionButtonsCaption.Continue);
          setStartStopIsDisabled(false);
          setPauseResumeIsDisabled(false);
          break;
        case EmulatorStatus.Stop:
          setStartStopCaption(actionButtonsCaption.Start);
          setPauseResumeCaption(actionButtonsCaption.Continue);
          setStartStopIsDisabled(true);
          setPauseResumeIsDisabled(false);
          break;
        case EmulatorStatus.Finished:
          setStartStopCaption(actionButtonsCaption.Stop);
          setPauseResumeCaption(actionButtonsCaption.Continue);
          setStartStopIsDisabled(true);
          setPauseResumeIsDisabled(true);
          break;
        default:
          setStartStopCaption(actionButtonsCaption.Start);
          setPauseResumeCaption(actionButtonsCaption.Continue);
          setStartStopIsDisabled(false);
          setPauseResumeIsDisabled(true);
      }
    }
  }, [status, configStatus, command]);

  const handleClickStart = () => {
    if (loadedPlanID) {
      if (startStopCaption === actionButtonsCaption.Start) {
        dispatch(emulatorStartRoutine({ planID: loadedPlanID, emulationSpeed: emulationSpeed }));
      } else {
        dispatch(emulatorStopRoutine({ planID: loadedPlanID }));
      }
    }
  };

  const handleClickPause = () => {
    if (loadedPlanID) {
      if (pauseResumeCaption === actionButtonsCaption.Pause) {
        dispatch(emulatorPauseRoutine({ planID: loadedPlanID }));
      } else {
        dispatch(emulatorStartRoutine({ planID: loadedPlanID, emulationSpeed: emulationSpeed }));
      }
    }
  };

  return (
    <>
      <div className="ils-emulator-status">
        Cтатус эмуляции:{' '}
        {status ? EmulatorStatusText[status] : configStatus ? EmulatorStatusText[configStatus] : EmulatorStatusText.default}
        {errorText && ` (${errorText})`}
      </div>
      <ILSActionButtonsComponent
        startStopCaption={startStopCaption}
        pauseResumeCaption={pauseResumeCaption}
        startStopIsDisabled={startStopIsDisabled}
        pauseResumeIsDisabled={pauseResumeIsDisabled}
        handleClickStart={handleClickStart}
        handleClickPause={handleClickPause}
      />
    </>
  );
};

