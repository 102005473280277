import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { PlanningAPI } from '@modules/planning/api';
import {
  planningCheckRoutine,
  planningDoneRoutine,
  planningGetPlanRoutine,
  planningRunCheckAction
} from '@modules/planning/children/by-date/actions';
import { PlanningProcessStatus, PlanningProgressStatus } from '@modules/planning/children/by-date/constants';
import { planningCheckDataProcessSelector } from '@modules/planning/children/by-date/selectors/planning';
import { plansIDsSelector, projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { CheckPlanningProcess, PlanIDsRequest, ProjectRequest } from '@modules/planning/types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'lodash';
import { Task } from 'redux-saga';
import { call, cancel, delay, fork, put, race, select, take } from 'redux-saga/effects';
import { PlanIDs } from '@common/types/dictionaries/plan';
import { activeProjectIdSelector } from '@modules/planning/selectors';
import { Project } from '@common/types';

function* planningCheckWorker(action: PayloadAction<PlanIDsRequest>) {
  const { success, failure } = planningCheckRoutine;
  const { plans } = action.payload;
  const notifyKey = 'planning-by-date-check-error';

  try {
    const projectID: Project['ID'] = yield select(activeProjectIdSelector);

    const {
      data: { data }
    } = yield call(PlanningAPI.checkPlanning, { plans, projectID });

    const previousProcess: CheckPlanningProcess | {} = yield select(planningCheckDataProcessSelector);
    if (Object.keys(previousProcess).length) {
      for (const ID in data) {
        const isDone = compareAsString(previousProcess?.[ID]?.Status, PlanningProcessStatus.IsDone);
        const planningProcessWasDoneSomewhere = !compareAsString(previousProcess?.[ID]?.Status, data[ID].Status) && isDone;
        if (
          compareAsString(data[ID].Progress, previousProcess?.[ID]?.Progress) &&
          compareAsString(previousProcess?.[ID]?.Progress, PlanningProgressStatus.CompletePlanning) &&
          !isDone
        ) {
          yield put(planningDoneRoutine({ planID: ID }));
        }
        if (planningProcessWasDoneSomewhere) {
          yield put(planningGetPlanRoutine.trigger({ ID }));
        }
      }
    }
    yield put(success({ checkData: data, hideSuccessMessage: true }));
  } catch (error) {
    yield put(failure({ error, notifyKey }));
  }
}

function* planningCheck(action: PayloadAction<PlanIDsRequest & ProjectRequest>) {
  const planIDs: PlanIDs = yield select(plansIDsSelector);
  const projectID: number | undefined = yield select(projectIDSelector);
  if (!isNil(projectID) && !isEmpty(planIDs)) {
    yield call(planningCheckWorker, planningCheckRoutine.trigger({ ...action.payload, plans: planIDs, projectID }));
  }
}

/** какие планы необходимо периодические проверять */
export function* planningCheckRun() {
  //Параметры последнего запроса по checkPlanning
  while (true) {
    //Проверка нет ли изменений по проверяемым планам
    const { checkAction } = yield race({
      checkAction: take(planningCheckRoutine.trigger),
      skip: delay(3000)
    });

    //Если был новый экшен, то запрашиваем данные по нему
    if (checkAction) {
      yield fork(planningCheck, checkAction);
      yield delay(3000);
      //Если не появилось новых событий, то проверяются предыдущие планы
    } else {
      yield fork(planningCheck, planningCheckRoutine.trigger({}));
      yield delay(3000);
    }
  }
}

//разрешает и запрещает обработку planningCheckRoutine.trigger
export function* planningCheckRunWatcherAction() {
  let getCheckTask: Task | undefined;

  while (true) {
    //NOTE нужно для пропуска событий END которые генерируются при переключении модулей
    while (true) {
      const actionRun: PayloadAction<{ run: boolean }> | undefined = yield take(planningRunCheckAction);
      if (actionRun?.payload?.run) {
        break;
      }
    }

    getCheckTask = yield fork(planningCheckRun);

    //NOTE также пропускаем END события
    while (true) {
      const actionRun: PayloadAction<{ run: boolean }> | undefined = yield take(planningRunCheckAction);
      if (actionRun?.payload?.run === false) {
        break;
      }
    }
    if (getCheckTask) {
      yield cancel(getCheckTask);
    }
  }
}
export function* planningCheckRunWatcher() {
  yield fork(planningCheckRunWatcherAction);
}
