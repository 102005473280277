export enum ButtonTypes {
  Save = 'Save',
  Cancel = 'Cancel',
  Edit = 'Edit',
  Delete = 'Delete',
  Form = 'Form',
  Confirm = 'Confirm',
  Update = 'Update'
}

