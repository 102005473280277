import { ISchemeEditorState, SchemeFormValues } from '@modules/planning/children/scheme-editor/types';
import { IndexedArray } from '@common/types';
import { getNewSchemaKey } from '@modules/planning/children/scheme-editor/utils/helpers/item-type-helpers';

/**Функция проверяет есть ли в переданной схеме, элементы, у которых надо изменить ключ и возвращает измененную схему с новыми ключами
 * Для определения изменился ли ключ используется поле partKey
 * */
export const renameChangedKeysInValues = (values: SchemeFormValues): SchemeFormValues => {
  const dictionaries = Object.keys(values);
  return dictionaries.reduce(
    (acc, curr) => {
      const dic = values[curr];
      const dicScheme = Object.keys(dic).reduce((newDicScheme, currentKey) => {
        const currentValue = dic[currentKey];
        if (typeof currentValue !== 'object') {
          newDicScheme[currentKey] = currentValue;
          return newDicScheme;
        }
        //в результирующей схеме partKey не нужен
        const { partKey, ...newValue } = currentValue;

        if (!dic[currentKey]?.partKey) {
          if (newValue.hasOwnProperty('description') && typeof newValue.description === 'undefined') {
            newDicScheme[currentKey] = { ...newValue, description: newValue.description ?? '' };
          } else {
            newDicScheme[currentKey] = newValue;
          }
        } else {
          const newElementKey = getNewSchemaKey(currentKey, dic[currentKey].partKey);
          newDicScheme[newElementKey] = newValue;
        }
        return newDicScheme;
      }, {});
      acc[curr] = { ...dicScheme };
      return acc;
    },
    { ...values }
  );
};

/**Функция принимает схему и возвращает только измененные ключи*/
export const getRenamedKeys = (values: SchemeFormValues): IndexedArray<Array<{ old: string; new: string }>> => {
  const dictionaries = Object.keys(values);
  return dictionaries.reduce((acc, dicName) => {
    const dic = values[dicName];

    const dictionaryRenamed = Object.keys(dic).reduce((renamed: Array<{ old: string; new: string }>, currentKey) => {
      if (dic[currentKey]?.partKey) {
        const newElementKey = getNewSchemaKey(currentKey, dic[currentKey].partKey);
        if (newElementKey !== currentKey) renamed.push({ old: currentKey, new: newElementKey });
      }

      return renamed;
    }, []);

    if (dictionaryRenamed?.length > 0) acc[dicName] = dictionaryRenamed;
    return acc;
  }, {});
};

/**Функция переименовывает измененные ключи в объекте currentSchemeKeysInDictionaries
 * @param values -схема
 * @param currentSchemeKeysInDictionaries -текущий объект currentSchemeKeysInDictionaries
 * */
export const getKeysFromValues = (
  values: SchemeFormValues,
  currentSchemeKeysInDictionaries: ISchemeEditorState['currentSchemeKeysInDictionaries']
): ISchemeEditorState['currentSchemeKeysInDictionaries'] => {
  const renamed = getRenamedKeys(values);
  return Object.keys(currentSchemeKeysInDictionaries).reduce(
    (acc, dicName) => {
      if (currentSchemeKeysInDictionaries[dicName]) {
        acc[dicName] = currentSchemeKeysInDictionaries[dicName]?.map((x: string) => {
          const hasChanged = renamed[dicName] && renamed[dicName].find((r) => r.old === x);
          if (hasChanged) {
            return hasChanged.new;
          }
          return x;
        });
      }
      return acc;
    },
    { ...currentSchemeKeysInDictionaries }
  );
};
