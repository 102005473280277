import { DICTIONARIES_WITH_PROJECT_PARAMETER } from '@common/api/constants';
import { Dictionary, Project } from '@common/types';

export const patchUpdateValues = ({
  dictionary,
  activeProjectID,
  values
}: {
  dictionary: Dictionary;
  activeProjectID?: Project['ID'] | null;
  values: any;
}) => {
  return DICTIONARIES_WITH_PROJECT_PARAMETER.includes(dictionary) && activeProjectID ? { ...values, ProjectID: activeProjectID } : values;
};
