import reduceReducers from 'reduce-reducers';
import { initialState } from '../store';
import { createLoadReducer } from '@core/reducers/load-module';
import { Module } from '@common/types';
import { getProjectsReducer } from '@modules/planning/reducers/get-projects';
import { setActiveProjectIdReducer } from '@modules/planning/reducers/set-active-project';
import { createPlanReducer } from '@modules/planning/reducers/create-plan';
import { createProjectReducer } from '@modules/planning/reducers/create-project';
import { updateProjectsReducer } from '@modules/planning/reducers/update-projects';
import { setPlannerSettingsReducer } from './set-planner-settings';

const planningRootReducer = reduceReducers(
  initialState,
  getProjectsReducer,
  setActiveProjectIdReducer,
  createPlanReducer,
  createProjectReducer,
  createLoadReducer(Module.Planner),
  updateProjectsReducer,
  setPlannerSettingsReducer
);

export default planningRootReducer;

