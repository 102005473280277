import { DemandRow } from './store';

export interface DemandTabItemProps {
  list: Array<DemandRow>;
}

// Типы табов
export enum DemandTabType {
  Project = 'Project', // Проекты
  TenderLot = 'TenderLot', // Лоты
  All = 'All' // Все сразу (и проекты и лоты)
}

export type DemandTab = {
  key: DemandTabType;
  label: string;
};
