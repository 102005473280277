import { ChartSchemaDataType } from '@modules/monitoring/types/chart';
import { Axis, Chart, Interaction, Schema, Tooltip } from 'bizcharts';
import React, { FC } from 'react';
import { CHART_SIZE } from './constants/chart-table';
import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { openVehicleDetails } from '@modules/monitoring/helpers/open-vehicle-details';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { BizChartsGraphColor } from '@modules/monitoring/children/dashboard/components/dashboard/configs/graph';
import ChartCustomTooltip from '@modules/monitoring/components/chart/chart-custom-tooltip';
import moment from 'moment';
import { UTC_OFFSET_DEFAULT } from '@modules/monitoring/children/dashboard/components/dashboard/configs/chart';

interface IProps {
  data: ChartSchemaDataType[];
  xMin: number;
  xMax: number;
  height?: number; // высота canvas;
}

//TODO рефакторинг, распределить по файлам
export const styleCb = (
  plan?: boolean,
  onWay?: boolean,
  onTimePart: MonitoringWaypointEventStatusType = MonitoringWaypointEventStatusType.Wait,
  start?: number
) => {
  // если есть опоздания, то раскрашиваем прямоугольник в желтый цвет с момента опоздания;
  let color = 'transparent';
  if (onTimePart === MonitoringWaypointEventStatusType.OnLate) {
    color = `l (0) 0:${BizChartsGraphColor.warn} 1:${BizChartsGraphColor.warn}`;
  } else if (onTimePart === MonitoringWaypointEventStatusType.InTime) {
    color = `l (0) 0:${BizChartsGraphColor.success} 1:${BizChartsGraphColor.success}`;
  }
  // если не доставлено, то раскрашиваем прямоугольник (план) в красный цвет;
  let stroke = BizChartsGraphColor.planColor;
  if (onTimePart === MonitoringWaypointEventStatusType.Undelivered) {
    stroke = BizChartsGraphColor.undelivered;
  } else if (onTimePart === MonitoringWaypointEventStatusType.OnLate) {
    stroke = BizChartsGraphColor.warn;
  }

  let fill = color;
  if (plan) {
    const dateNow = moment.now();
    if (start) {
      const datePlan = moment.unix(start).utcOffset(UTC_OFFSET_DEFAULT).valueOf();
      if (datePlan < dateNow) {
        fill = onWay ? BizChartsGraphColor.planColor : 'transparent';
      } else {
        fill = onWay ? BizChartsGraphColor.planColorFeature : 'transparent';
      }
    } else {
      fill = onWay ? BizChartsGraphColor.planColor : 'transparent';
    }
  }
  return {
    lineWidth: !onWay && plan ? 1 : 0,
    stroke,
    fill,
    fillOpacity: plan ? 1 : 0.6,
    cursor: plan ? 'pointer' : 'default'
  };
};

// отвечает за высоту линий и прямоугольников;
export const sizeCb = (plan?: boolean, onWay?: boolean) => {
  const planOnWayWidth = 160;
  const factOnWayWidth = planOnWayWidth * 1.618;
  const factOnPointWidth = factOnWayWidth * 1.618;
  const planOnPointWidth = factOnPointWidth * 1.618;
  if (plan) {
    return onWay ? planOnWayWidth : planOnPointWidth;
  }
  return onWay ? factOnWayWidth : factOnPointWidth;
};

// Компонент с расчётами схемы для отображения прямоугольников диаграммы
export const ChartSchema: FC<IProps> = ({ data, xMax, xMin, height = CHART_SIZE.Max }) => {
  const dv = data.map((item) => {
    const range = [undefined, item.start, undefined, item.end, undefined];
    // переводим координаты start и end в параметры Schema;
    return { ...item, range };
  });
  //TODO dispatch и history не должно быть в создании данных схемы
  const dispatch = useDispatch();
  const history = useHistory();

  const cols = {
    range: { min: xMin, max: xMax, nice: true } // растягиваем контекст canvas на всю ширину canvas;
  };

  //TODO Это должно приходить из вне (создавать в отдельном хуке и получатся с ILSMonitoringDashboardChart)
  const onDetailsClick = (event: any) => {
    const waypointID = event.data.data.dataset.waypointID;
    const vehicleID = event.data.data.dataset.vehicleID;
    openVehicleDetails(waypointID, vehicleID, dispatch, history);
  };
  return (
    <div
      style={{
        height: `${height + 15}px`,
        overflow: 'hidden'
      }} // костыль, чтоб скрыть панель size, не нашёл, как убрать через api bizchart
    >
      <Chart
        onClick={onDetailsClick}
        scale={cols}
        autoFit
        data={dv}
        height={height * 1.8} // продолжение костыля;
      >
        <Tooltip>
          {(title, items) => {
            const ds = items ? items[0]?.data.dataset : undefined;
            return <ChartCustomTooltip ds={ds} />;
          }}
        </Tooltip>
        <Axis name="range" visible={false} /> {/*убирает отображение оси Х */}
        <Schema
          position="range*1" // разворот schema в горизонт;
          shape="box"
          style={['plan*onWay*onTimePart*start', styleCb]} // передаёт plan, onWay, onTime из текущего элемента массива data в styleCb;
          size={['plan*onWay', sizeCb]} // передаёт plan, onWay из текущего элемента массива data в sizeCb;
        />
        <Interaction type="element-highlight" />
      </Chart>
    </div>
  );
};
