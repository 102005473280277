import { getSRCIconSVG } from '@common/decorators/path';
import { Module, ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { ILSCatalog } from './containers/catalog';
import catalogReducer from './reducers';
import * as catalogRootSagas from './sagas';

export default defineModule({
  title: ModuleTitle.Catalog,
  path: ModulePath.Catalog,
  component: ILSCatalog,
  reducer: catalogReducer,
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG(ModulePath.Catalog, { iconModule: '/sidebar' }),
  sagas: getAllModuleSagas(catalogRootSagas),
  api: { Component: ILSCatalog },
  persist: false,
  deps: [Module.Common, 'Planning']
});
