import { IndexedArray, PayloadActionError } from '@common/types';
import { updateMonitoringData } from '@modules/monitoring/utils/update-data';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { monitoringDashboardInfoRoutine } from '../actions/index';
import { DashboardTablesNumberMetrics } from '../constants';
import { initialState } from '../store';
import { DashboardTables } from '../types';
import { MonitoringDashboardState } from '../types/store';
import { isEmpty } from 'lodash';

const getTabDataKey = (activeTab: DashboardTables | undefined): keyof MonitoringDashboardState['dashboardTableData'] =>
  activeTab === DashboardTablesNumberMetrics.Served || activeTab === DashboardTablesNumberMetrics.Total ? 'all' : activeTab || 'all';

// Получает информацию для выбранной таблицы и ставит её таб активным
const monitoringDashboardInfoReducer = createReducer<MonitoringDashboardState>(initialState as MonitoringDashboardState, {
  [monitoringDashboardInfoRoutine.REQUEST]: (
    state,
    action: PayloadAction<{ activeTab: DashboardTables | undefined; from: string; till: string }>
  ) => {
    const { activeTab, from, till } = action.payload;

    const tabData = getTabDataKey(activeTab);

    //TODO перенести в success все записи для from и till
    if (state.dashboardTableData[tabData].from !== from) {
      state.dashboardTableData[tabData].from = from;
    }
    if (state.dashboardTableData[tabData].till !== till) {
      state.dashboardTableData[tabData].till = till;
    }
    if (state.dashboardTableData[tabData].isFetching !== true) {
      state.dashboardTableData[tabData].isFetching = true;
    }

    if (state.selectedDashboard !== (activeTab || DashboardTablesNumberMetrics.Served)) {
      state.selectedDashboard = activeTab || DashboardTablesNumberMetrics.Served;
    }

    return state;
  },
  [monitoringDashboardInfoRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ data: Array<any> | IndexedArray<any>; activeTab: DashboardTables | undefined; updates?: boolean }>
  ) => {
    const { data, activeTab, updates } = action.payload;
    //NOTE для облужено точек везде устанавливаем "Served"
    if (state.selectedDashboard !== (activeTab || DashboardTablesNumberMetrics.Served)) {
      state.selectedDashboard = activeTab || DashboardTablesNumberMetrics.Served;
    }

    const tabData = getTabDataKey(activeTab);

    if (updates) {
      if (!isEmpty(data)) {
        state.dashboardTableData[tabData].data = updateMonitoringData(state.dashboardTableData?.[tabData].data, data);
      }
    } else {
      state.dashboardTableData[tabData].data = data;
    }
    state.dashboardTableData[tabData].isFetching = false;
    state.dashboardTableData[tabData].lastUpdate = new Date().getTime();

    return state;
  },
  [monitoringDashboardInfoRoutine.FAILURE]: (
    state,
    action: PayloadActionError & PayloadAction<{ activeTab: DashboardTables | undefined }>
  ) => {
    const { activeTab } = action.payload;
    if (state.selectedDashboard !== (activeTab || DashboardTablesNumberMetrics.Served)) {
      state.selectedDashboard = activeTab || DashboardTablesNumberMetrics.Served;
    }

    const tabData = getTabDataKey(activeTab);

    state.dashboardTableData[tabData].data = [];
    state.dashboardTableData[tabData].isFetching = false;
    state.dashboardTableData[tabData].lastUpdate = undefined;

    return state;
  },
  [monitoringDashboardInfoRoutine.FULFILL]: (state, action: PayloadAction<{ activeTab: DashboardTables | undefined }>) => {
    const { activeTab } = action.payload;
    const tabData = getTabDataKey(activeTab);

    if (state.dashboardTableData[tabData].isFetching !== false) {
      state.dashboardTableData[tabData].isFetching = false;
    }

    return state;
  }
});

export default monitoringDashboardInfoReducer;

