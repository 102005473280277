import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '../selectors/auth';
import { CurrentUserComponent } from '../components/current-user';
import { logoutActions } from '@modules/current-user/actions';

const CurrentUser: FC = () => {
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const logOut = () => dispatch(logoutActions());

  return <CurrentUserComponent user={user} logOut={logOut} />;
};

export default CurrentUser;
