import { Dictionary, TableColumnName } from '@common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { handleSwitchersRoutine } from '../../actions';

function* worker(
  action: PayloadAction<{
    dictionary: Dictionary;
    dataIndex: TableColumnName;
    isOn: boolean;
  }>
) {
  const { success, failure } = handleSwitchersRoutine;
  const { dictionary, dataIndex, isOn } = action.payload;
  try {
    yield put(success({ dictionary, dataIndex, isOn }));
  } catch (error) {
    yield put(failure({ error, dictionary }));
  }
}

export function* handleSwitchersWatcher() {
  yield takeLatest(handleSwitchersRoutine.trigger, worker);
}
