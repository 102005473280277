import './styles.less';
import { FC } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons/lib/icons';
import { ILSTypographyText } from '@common/components';
import cn from 'classnames';

interface IProps {
  className?: string;

  onClick?(): void;
}

export const ILSTimePickerStatic: FC<IProps> = ({ className, children, onClick }) => {
  return (
    <span className={cn(className, 'icon-with-typography')} onClick={onClick}>
      <ClockCircleOutlined className={'ils-svg primary'} />
      <ILSTypographyText ellipsis children={children} />
    </span>
  );
};
