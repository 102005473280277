import { Button, notification } from 'antd';
import { Notify } from '@common/types';
import { DEFAULT_INITIALIZATION_FUCTION } from '@common/constants';
import { PlacementType } from '@common/types/components';

export const ILSUserConfirm = ({
  text,
  confirmParams = {},
  onConfirm,
  onClose = DEFAULT_INITIALIZATION_FUCTION,
  title,
  onConfirmText = 'Подтвердить',
  onCloseText = 'Отмена'
}: {
  text: string;
  onConfirm?(params: {}): void;
  confirmParams?: {};
  title?: string;
  onClose?(): void;
  onConfirmText?: string;
  onCloseText?: string;
}) => {
  const key = `open${Date.now()}`;

  const onClick = () => {
    onCancel();
    onConfirm?.(confirmParams);
  };

  const onCancel = () => {
    notification.close(key);
    onClose();
  };

  const btn = (
    <div className="w-100 flex-between-center">
      {onConfirm && <Button type="primary" size="small" onClick={onClick} children={onConfirmText} />}
      <Button size="small" onClick={onCancel} children={onCloseText} />
    </div>
  );

  notification.warn({
    message: title ?? 'Внимание!',
    description: text,
    duration: 0,
    onClose: onClose,
    className: Notify.Confirm,
    placement: PlacementType.topLeft,
    btn,
    key
  });
};
