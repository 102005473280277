import { getColumnWidthByTypeOrName } from '@components/data-display/table/utils/helpers';
import { CellType } from '@common/types/components/table/cell';
import { CELL_TYPE_MIN_WIDTH, EXTRA_SPACE_WIDTH_IN_COLUMN_HEADER } from '@components/data-display/table/constants';
import { ColumnTable, TableRecord } from '@common/types';
import { CSSProperties } from 'react';

export const getColumnWidth = <R extends TableRecord>({
  /** Параметр переданный в конфигурации таблицы */
  width,
  /** тип ячейки для определения из настроек кода минимальной ширины */
  type,
  /** заголовок колонки для расчёта длины */
  title,
  /** inline стили ячейки */
  style
}: {
  width: ColumnTable<R>['width'];
  style?: CSSProperties;
  type: CellType;
  title?: ColumnTable<R>['title'];
}) => {
  return (
    /** Параметру из конфигурации таблицы отдается приоритет, затем проверяются inline стили ячейки
     * в последнюю очередь подставляется расчетная ширина – размер текста названия и сравнивается с минимальной установленной приложением */
    Number(width ?? style?.width ?? style?.minWidth ?? getColumnWidthByTypeOrName(CELL_TYPE_MIN_WIDTH[type], title)) +
    /** добавляем ширину поиска/фильтров/сортировки, чтобы antd не применил к ним overflow */
    EXTRA_SPACE_WIDTH_IN_COLUMN_HEADER
  );
};
