import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import { authReducer } from '@modules/current-user/reducers';
import { getUserProjectsWatcher } from '@modules/current-user/sagas/account';
import { checkTokenWatcher } from '@modules/current-user/sagas/check-token';
import { accountGetRoleWatcher } from '@modules/current-user/sagas/get-role';
import CurrentUser from './containers/current-user';
import ILSLoginForm from './containers/login-form';
import ILSRecoveryForm from './containers/recovery-form';
import ILSRegisterForm from './containers/register-form';
import ILSLogisticServices from '@modules/current-user/containers/logistic-services';
import { loginWatcher, logoutWatcher } from './sagas/login';
import { recoveryRequestWatcher, recoveryWatcher } from './sagas/recovery';
import { registerRequestWatcher, registerWatcher } from './sagas/register';
import { currentUserNotifierWatcher } from '@modules/current-user/sagas/notifier';
import { changeUserInfoWatcher } from './sagas/change-user-info';
import { ILSAuthNav } from '@modules/auth-nav/containers/auth-nav';

export default defineModule([
  {
    title: 'Авторизованный пользователь',
    component: CurrentUser,
    reducer: authReducer,
    sagas: [logoutWatcher(), changeUserInfoWatcher()],
    inScope: [ModuleScope.HeaderRight]
  },
  {
    title: 'Форма аутентификации',
    component: ILSLoginForm,
    reducer: authReducer,
    sagas: [loginWatcher(), checkTokenWatcher(), getUserProjectsWatcher()],
    inScope: [ModuleScope.LoginForm]
  },
  {
    title: 'Форма регистрации',
    component: ILSRegisterForm,
    reducer: authReducer,
    sagas: [registerWatcher(), registerRequestWatcher(), accountGetRoleWatcher(), currentUserNotifierWatcher()],
    inScope: [ModuleScope.RegisterForm]
  },
  {
    title: 'Форма восстановления пароля',
    component: ILSRecoveryForm,
    reducer: authReducer,
    sagas: [recoveryWatcher(), recoveryRequestWatcher()],
    inScope: [ModuleScope.RecoveryForm]
  },
  {
    title: 'Логистические сервисы',
    component: ILSLogisticServices,
    reducer: authReducer,
    sagas: [],
    inScope: [ModuleScope.LogisticServices]
  },
  {
    title: 'Навигация на страницах для неавторизованных пользователей',
    component: ILSAuthNav,
    reducer: authReducer,
    sagas: [],
    inScope: [ModuleScope.AuthNav]
  }
]);
