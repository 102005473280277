import { createSelector } from 'reselect';
import { currentTenderMyOfferSelector } from '@modules/tender-platform/selectors';
import { generateSelect } from '@components/decorators';
import { sortByMatch } from '@common/utils/helpers/object/divided-array-objects';
import { ILSAllowedField, ILSVehilceAllowedForLot } from '@modules/tender-platform/children/tender-search/constants/offer';
import { Driver, Vehicle } from '@common/types';
import { getVehicleTenderSelect } from '@modules/tender-platform/utils/helpers/get-vehicles-for-tender';
import { TenderStore } from '@modules/tender-platform/types';
import { isEmpty } from 'lodash';

export const tenderVehicleSelector = createSelector(
  (state: TenderStore) => state.Tender.currentTenderVehicles,
  (Vehicles: Array<Vehicle>) => Vehicles
);

export const tenderDriverSelector = createSelector(
  (state: TenderStore) => state.Tender.currentTenderDrivers,
  (Drivers: Array<Driver>) => Drivers
);
//TODO: refactor this selector ant add Types
export const tenderOfferVehiclesSelector = createSelector(currentTenderMyOfferSelector, tenderVehicleSelector, (offer, tenderVehicles) => {
  let offerVehicleData: any[] = [];

  if (offer?.VehicleID) {
    const vehicleData: Record<number, Vehicle[]> = sortByMatch(tenderVehicles, ILSAllowedField.AllowedForLot);
    const vehicleOptionsMatch = getVehicleTenderSelect(vehicleData[1]);
    const vehicleOptionsNotMatch = getVehicleTenderSelect(vehicleData[0]);

    if (vehicleOptionsMatch?.find(({ value }) => value === offer?.VehicleID)) {
      offerVehicleData = [ILSVehilceAllowedForLot.YES, offer?.VehicleID];
    }
    if (vehicleOptionsNotMatch?.find(({ value }) => value === offer?.VehicleID)) {
      offerVehicleData = [ILSVehilceAllowedForLot.NO, offer?.VehicleID];
    }
  }
  const VehicleID = offerVehicleData?.length ? offerVehicleData : [ILSVehilceAllowedForLot.NO, offer?.VehicleID];

  return { VehicleID };
});

export const vehiclesAndDriversOptionsSelector = createSelector(
  tenderDriverSelector,
  tenderVehicleSelector,
  currentTenderMyOfferSelector,
  (drivers, vehicles, TenderOffer) => {
    const driverOptions = generateSelect({ data: drivers.length ? drivers : TenderOffer?.Driver });
    const vehicleData = isEmpty(vehicles) ? [{ ...TenderOffer.Vehicle, AllowedForLot: ILSVehilceAllowedForLot.NO }] : vehicles;
    const vehicleDataSort: Record<number, Vehicle[]> = sortByMatch(vehicleData, ILSAllowedField.AllowedForLot);
    const vehicleOptionsMatch = getVehicleTenderSelect(vehicleDataSort[1]);
    const vehicleOptionsNotMatch = getVehicleTenderSelect(vehicleDataSort[0]);

    const vehicleOptions = [
      {
        value: ILSVehilceAllowedForLot.YES,
        label: 'Соответствует',
        children: vehicleOptionsMatch
      },
      {
        value: ILSVehilceAllowedForLot.NO,
        label: 'Не соответствует',
        children: vehicleOptionsNotMatch
      }
    ];
    return {
      driverOptions,
      vehicleOptions
    };
  }
);
