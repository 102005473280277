import { ActiveRecord } from '@modules/orders-and-trips/types/general';
import { DecoratedTrips, TripStatus } from '@modules/orders-and-trips/types/trip';
import { useDispatch } from 'react-redux';
import { updateTripRoutine } from '@modules/orders-and-trips/actions';
import { useInterval } from '@common/hooks/use-interval';
import { COUNT_TRIPS_UPDATE_INTERVAL } from '@modules/orders-and-trips/constants/general';

type UseTripCard = {
  trip: DecoratedTrips[number];
  activeTrip: ActiveRecord;
  tripStatus: TripStatus;
};
export const useTripCard = ({ trip, activeTrip, tripStatus }: UseTripCard) => {
  const dispatch = useDispatch();
  const isActive = trip.tripID === activeTrip?.ID;
  const isNotExistTripDetails = !trip.details.price || !trip.details.driver || !trip.details.vehicle;

  const updateTripData = () => {
    if (isActive && trip.status === TripStatus.onRegistration && isNotExistTripDetails) {
      dispatch(updateTripRoutine({ tripID: activeTrip?.ID }));
    }
  };

  useInterval(updateTripData, COUNT_TRIPS_UPDATE_INTERVAL, [activeTrip, isActive, tripStatus]);

  return { isActive };
};
