import { Notify, TableRecord } from '@common/types';
import { EventBlurNFocus, IILSCellNumberProps } from '@common/types/components/table/cell';
import { isNil } from 'lodash';
import { ILSUserNotify } from '@components/index';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { MessageContent } from '@common/constants';

export const handleCellIntegerSave =
  <R extends TableRecord>({ min, max, save, toggleEdit }: Pick<IILSCellNumberProps<R>, 'max' | 'min' | 'save' | 'toggleEdit'>) =>
  ({ target: { value } }: EventBlurNFocus) => {
    try {
      if (!isNil(min) && value < min) {
        ILSUserNotify(Notify.Error, `Нельзя установить значение < ${min}`);
      } else if (!isNil(max) && value > max) {
        ILSUserNotify(Notify.Error, `Нельзя установить значение > ${max}`);
      } else if (!Number.isInteger(Number(value))) {
        ILSUserNotify(Notify.Error, `Нельзя установить дробное значение`);
      } else {
        save(value);
      }
    } catch (error) {
      showUserAndDevError({ error, userError: MessageContent.SomethingWentWrong });
    } finally {
      toggleEdit?.();
    }
  };
