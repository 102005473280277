import { KeyboardEvent } from 'react';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { EMPTY_STRING, Field, KeyboardCode } from '@common/constants';
import { ILSCellAddressAutocompleteIProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellAddressAutocomplete = <R extends TableRecord>({
  children,
  toggleEdit,
  save
}: ILSCellAddressAutocompleteIProps<R>): JSX.Element => {
  const [node, address = EMPTY_STRING] = children;

  const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e?.code === KeyboardCode.Enter) {
      save((e.target as HTMLInputElement).value);
    }
    return;
  };

  return (
    <>
      {node}
      <ILSSuggestionAutoComplete
        className={'cell-wrapper-editable-full'}
        autoFocus
        onBlur={toggleEdit}
        defaultValue={address}
        onKeyDown={onEnter}
        onSelect={save}
        field={Field.Address}
      />
    </>
  );
};
