import { createSelector } from 'reselect';
import { IVehicleMonitoringState } from '@modules/vehicle-monitoring/types/store';

export type TVehicleMonitoringSelector = {
  VehicleMonitoring: IVehicleMonitoringState;
};

export const fetchSelector = createSelector(
  (state: TVehicleMonitoringSelector) => state.VehicleMonitoring.isFetching,
  (isFetching) => isFetching
);

export const dataSelector = createSelector(
  (state: TVehicleMonitoringSelector) => state.VehicleMonitoring.data,
  (data) => data
);

export const lastUpdateSelector = createSelector(
  (state: TVehicleMonitoringSelector) => state.VehicleMonitoring.lastUpdate,
  (lastUpdate) => lastUpdate
);
