import { TooltipItem } from 'bizcharts/lib/interface';

export function tooltipNameTranslator(item: TooltipItem[]) {
  const names = {
    late: 'С опозданием',
    inTime: 'С опережением',
    visits: 'Визиты',
    intencity: 'Интенсивность',
    onTime: 'Визиты',
    fines: 'Штрафы',
    volume: 'Объем',
    sequence: 'Последовательность',
    deviation: 'Отклонение'
  };

  return item.map((item) => {
    return {
      ...item,
      name: names[item.name]
    };
  });
}

