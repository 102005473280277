import { useSelector } from 'react-redux';
import { projectIDSelector } from '@modules/planning/children/by-date/selectors/project';
import { Dictionary, ModulePath, PlanStatus } from '@common/types';
import { stringifyUrl } from 'query-string';
import { compareAsString } from '@common/utils';
import { useHistory } from 'react-router';
import { QUERY_PARAMS } from '@common/constants/url';
import { RoutesTableRecord, WaypointRecord } from '@modules/planning/children/by-date/types/record';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';

export const getContextMenuForWaypoint = ({
  record: { PrevWaypointID, ID, VehicleID, Status, DepotID, PlanID, dictionary, TripID },
  setTriggerMapFocus,
  handleDelete
}: {
  record: WaypointRecord;
  setTriggerMapFocus: SetTriggerMapFocus;

  handleDelete(record: RoutesTableRecord): void;
}) => {
  const projectID = useSelector(projectIDSelector);
  const history = useHistory();

  const link = stringifyUrl(
    {
      url: '/pages/track',
      query: {
        dictionary,
        project: projectID,
        plan: PlanID,
        prevWaypoint: PrevWaypointID,
        waypoint: ID,
        trip: TripID,
        vehicle: VehicleID
      }
    },
    {
      skipEmptyString: true,
      skipNull: true
    }
  );

  const onEdit = () => {
    if (DepotID) {
      const depotCatalog = stringifyUrl({
        url: ModulePath.Catalog,
        query: {
          [QUERY_PARAMS.DICTIONARY]: Dictionary.Depot,
          [QUERY_PARAMS.ID]: DepotID
        }
      });
      history.push(depotCatalog);
    }
  };

  return [
    [
      {
        text: 'Перейти к объекту на карте',
        handler: (record: WaypointRecord) => setTriggerMapFocus([record])
      },
      {
        text: 'Редактировать точку',
        handler: onEdit
      },
      {
        text: 'Открыть на отдельной странице',
        link
      }
    ],
    [!compareAsString(Status, PlanStatus.OnExecution) && { text: 'Удалить точку', handler: handleDelete }]
  ];
};
