import { _ROAD_TRAIN_TABLE_CONFIG } from '@common/models/roadtrain/config/table';
import { roadTrain2table } from '@common/models/roadtrain/decorators';
import { CatalogDictionary, Dictionary, RoadTrain, TableColumnName } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { ILSCatalogTable } from '../../containers/table-container';
import { useSelector } from 'react-redux';
import { catalogRoadTrainOptionsSelector } from '@modules/catalog/selectors/options';

export const ILSCatalogRoadTrain: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    const { refData } = useSelector(catalogRoadTrainOptionsSelector(props.reference?.record));
    const onCanEdit = (record: RoadTrain, dataIndex: TableColumnName) => {
      const editable = props.config?.columns?.find((column: any) => column.dataIndex === dataIndex)?.editable;
      if (dataIndex === 'Trailer2ID') return !!record.Trailer1ID;
      return Boolean(editable);
    };

    return (
      <ILSCatalogTable
        {...props}
        dictionary={Dictionary.RoadTrain}
        dataDecorator={roadTrain2table}
        filterID={props.reference?.record?.ID}
        refData={refData}
        onCanEdit={onCanEdit}
      />
    );
  },
  _ROAD_TRAIN_TABLE_CONFIG,
  CatalogDictionary.RoadTrain,
  true
);
