import { FC } from 'react';
import { CellType, TableRecord } from '@common/types';
import { ILSCellIcon } from './cell-types/static/ils-cell-icon';
import { ILSCellPercent } from './cell-types/static/ils-cell-percent';
import { ILSCellProgress } from './cell-types/static/ils-cell-progress';
import { ILSCellTimeStringWithUTC } from './cell-types/static/ils-cell-time-string-hint';
import { ILSCellIconAction } from './cell-types/static/ils-cell-icon-action';
import { ILSCellAntd } from '@components/data-display/table/components/cell-types/static/ils-cell-antd';
import { ILSCellBadgeText } from './cell-types/static/ils-cell-badge-text';
import { ILSCellMenuButton } from './cell-types/static/ils-cell-menu-button';
import { ILSCellStatus } from '@components/data-display/table/components/cell-types/static/ils-cell-status';
import { ILSCellStringMultiple } from '@components/data-display/table/components/cell-types/static/ils-cell-string-multiple';
import { ILSCellAlert } from '@components/data-display/table/components/cell-types/static/ils-cell-alert';
import { ILSCellDateTimeString } from './cell-types/static/ils-cell-datetime-string';
import { ILSCellCountdown } from '@components/data-display/table/components/cell-types/static/ils-cell-countdown';
import { ILSCellTextAction } from '@components/data-display/table/components/cell-types/static/ils-cell-text-action';
import { INotEditableCell } from '@common/types/components/table/cell';
import { ILSCellString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-string';
import { ILSCellGeoString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-geo-string';
import { ILSCellColorStatic } from '@components/data-display/table/components/cell-types/toggle/ils-cell-color-static';
import { ILSCellTimeString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-time-string';
import { ILSCellDateString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-date-string';
import { ILSCellBoolString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-bool-string';
import { ILSCellLineSeries } from '@components/data-display/table/components/cell-types/static/ils-cell-line-series';
import { ILSCellTimeRangeString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-time-range-string';
import { ILSCellDurationString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-duration-string';
import { ILSCellCascaderString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-cascader-string';
import { ILSCellSelectString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-select-string';
import { ILSCellSelectAPIString } from '@components/data-display/table/components/cell-types/toggle/ils-cell-select-api-string';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { ILSDropdownCellButton, ILSDropdownClickableCellButton } from '@common/components';

export const ILSNotEditableTypeCell: FC<INotEditableCell<TableRecord>> = ({
  type,
  columnKey,
  format,
  children,
  record,
  dataIndex,
  activeCell,
  countdown,
  alt,
  ellipsis = true,
  handleSave,
  style,
  lineSeriesProps,
  options,
  fieldNames = BASE_FIELD_NAMES,
  showParent
}) => {
  switch (type) {
    case CellType.DateTimeString:
      return <ILSCellDateTimeString children={children} format={format} />;
    case CellType.String:
    // В случае если данным типам присвоят значение Editable false могут возникать ошибки, происходит вывод обычной строки
    case CellType.Text:
    case CellType.AddressAutocomplete:
    case CellType.Email:
    case CellType.Float:
    case CellType.Input:
    case CellType.Int:
    case CellType.Phone:
    case CellType.RegNumber:
    case CellType.Ufloat:
    case CellType.Uint:
      return <ILSCellString children={children} />;
    case CellType.DoubleRangeTimeString:
      return <ILSCellTimeString double children={children} />;
    case CellType.Icon:
      return <ILSCellIcon alt={alt} children={children} />;
    case CellType.Percent:
      return <ILSCellPercent children={children} />;
    case CellType.TimeStringUtc:
      return <ILSCellTimeStringWithUTC record={record} ellipsis={ellipsis} children={children} style={style} />;
    case CellType.Progress:
      return <ILSCellProgress children={children} />;
    case CellType.IconAction:
      return <ILSCellIconAction record={record} dataIndex={dataIndex} />;
    case CellType.TextAction:
      return <ILSCellTextAction record={record} dataIndex={dataIndex} />;
    case CellType.Button:
      return <ILSDropdownCellButton record={record} dataIndex={dataIndex} />;
    case CellType.MenuButton:
      return <ILSCellMenuButton children={children} />;
    case CellType.ButtonClickableDropdown:
      return <ILSDropdownClickableCellButton record={record} dataIndex={dataIndex} />;
    case CellType.BadgeText:
      return <ILSCellBadgeText children={children} />;
    case CellType.Status:
      return <ILSCellStatus children={children} handleSave={handleSave} record={record} dataIndex={dataIndex} />;
    case CellType.StringMultiple:
      return <ILSCellStringMultiple children={children} />;
    case CellType.Alert:
      return <ILSCellAlert children={children} dataIndex={dataIndex} activeCell={activeCell} record={record} />;
    case CellType.Countdown:
      return <ILSCellCountdown children={children} text={countdown?.text} />;
    case CellType.Geo:
      return <ILSCellGeoString children={children} dataIndex={dataIndex} record={record} />;
    case CellType.Color:
      return <ILSCellColorStatic children={children} dataIndex={dataIndex} record={record} />;
    // Время
    case CellType.Time:
      return <ILSCellTimeString children={children} />;
    case CellType.TimeDouble:
      return <ILSCellTimeString double children={children} />;
    case CellType.RangeTime:
      return <ILSCellTimeRangeString children={children} />;
    case CellType.Duration:
      return <ILSCellDurationString children={children} />;
    // Календари
    case CellType.Date:
      return <ILSCellDateString format={format} dateParseFormat={format} children={children} />;
    case CellType.DateTime:
      return <ILSCellDateString format={format} dateParseFormat={format} showTime children={children} />;
    case CellType.Bool:
      return <ILSCellBoolString children={children} />;
    case CellType.LineSeries:
      return <ILSCellLineSeries lineSeriesProps={lineSeriesProps} children={children} record={record} columnKey={columnKey} />;
    // Каскадеры
    case CellType.CascaderConfigAPI:
    case CellType.CascaderAPI:
      // case CellType.Cascader:
      return (
        <ILSCellCascaderString
          showParent={showParent}
          fieldNames={fieldNames}
          options={options}
          record={record}
          dataIndex={dataIndex}
          showArrow={false}
          children={children}
        />
      );
    // Селекты
    case CellType.Select:
    case CellType.SelectMultiple:
      return <ILSCellSelectString fieldNames={fieldNames} options={options} showArrow={false} children={children} />;
    case CellType.SelectIconAPI:
    case CellType.SelectIcon:
      return <ILSCellSelectString fieldNames={fieldNames} options={options} showArrow={false} icon children={children} />;
    case CellType.SelectMultipleApi:
    case CellType.SelectAPI:
      return <ILSCellSelectAPIString showArrow={false} children={children} record={record} dataIndex={dataIndex} />;
    default:
      return <ILSCellAntd children={children} />;
  }
};
