import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringCreateTrackerRoutine, monitoringGetTrackersRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';
import { CreateTrackerRequest } from '@common/types/dictionaries/monitoring';
import { cleanEmptyFields } from '@common/utils/helpers/object/clean-empty-fields';

// Записывает новый трекер на основе данных values
function* monitoringCreateTrackerWorker(action: PayloadAction<CreateTrackerRequest>) {
  const values = action.payload;
  const { request, success, failure, fulfill } = monitoringCreateTrackerRoutine;
  cleanEmptyFields(values);

  try {
    yield put(request({ values }));

    const { data } = yield call(MonitoringAPI.createTracker, { ...values });

    yield put(success({ data: data.data }));

    yield put(monitoringGetTrackersRoutine.trigger({}));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringCreateTrackerWatcher() {
  yield takeLatest(monitoringCreateTrackerRoutine.trigger, monitoringCreateTrackerWorker);
}
