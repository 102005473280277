import { ILSUserNotify } from '@common/components/feedback/ils-user-notify';
import { ILSButton } from '@components/general/buttons';
import { FC } from 'react';
import { CopyOutlined } from '@ant-design/icons/lib/icons';
import { Dictionary, IILSTableComponentProps, Key, Keys, Notify, NotifyDurationInSecond, TableRecord } from '@common/types';
import { isEmpty } from 'lodash';
import { ILSButtonTooltip } from '@common/components';

interface IProps extends Pick<IILSTableComponentProps<TableRecord>, 'dictionary' | 'onCopy'> {
  selectedRows: Keys | [];
  isIcon: boolean;
  dictionary?: Dictionary;
  focusedRow?: Key;
}

const ILSCopyRowActionButton: FC<IProps> = ({ selectedRows, isIcon, dictionary, onCopy, focusedRow }) => {
  const copyRows = () => {
    if (!dictionary) {
      console.error(`Отсутствует необходимый props: dictionary ${dictionary}`);
      return;
    }

    if (isIcon) {
      if (selectedRows.length) {
        onCopy?.({ dictionary, rows: selectedRows });
      } else {
        ILSUserNotify(Notify.Warning, 'Выберите строку, чтобы скопировать', NotifyDurationInSecond.Three);
      }
    } else {
      if (focusedRow) {
        onCopy?.({ dictionary, rows: [focusedRow] });
      }
    }
  };

  return (
    <>
      {isIcon ? (
        <ILSButtonTooltip
          title="Добавить копию строки"
          disabled={isEmpty(selectedRows) || !onCopy}
          icon={<CopyOutlined className={'ils-svg primary'} />}
          onClick={copyRows}
        />
      ) : (
        <ILSButton disabled={!focusedRow || !onCopy} onClick={copyRows} children={'Копировать'} />
      )}
    </>
  );
};

export default ILSCopyRowActionButton;
