import { createReducer } from '@reduxjs/toolkit';
import initialState from '../store';
import { vehicleMonitoringIndexRoutine } from '../actions';
import { onFailure, onFulfill, onRequest } from '@common/utils';

const vehicleMonitoring = createReducer(initialState, {
  [vehicleMonitoringIndexRoutine.REQUEST]: onRequest,
  [vehicleMonitoringIndexRoutine.SUCCESS]: (state, action: ReturnType<typeof vehicleMonitoringIndexRoutine.success>) => {
    const { data, lastUpdate } = action.payload;
    state.data = data;
    state.lastUpdate = lastUpdate;
    state.isFetching = false;
    return state;
  },
  [vehicleMonitoringIndexRoutine.FAILURE]: onFailure,
  [vehicleMonitoringIndexRoutine.FULFILL]: onFulfill
});

export default vehicleMonitoring;
