// Утилита для формирования тултипа
export function generateInfoTemplate(data: {
  Name: string;
  DriverName: string;
  Time: string;
  Lat: number | null;
  Lon: number | null;
  Speed: string | null;
}) {
  return `
  <ul class="route-info">
    <li>
      <strong>ТС:</strong>
      <span>${data.Name}</span>
    </li>
    <li>
      <strong>Водитель:</strong>
      <span>${data.DriverName}</span>
    </li>
    <li>
      <strong>Время:</strong>
      <span>${data.Time}</span>
    </li>
    <li>
      <strong>Широта:</strong>
      <span>${data.Lat}</span>
    </li>
    <li>
      <strong>Долгота:</strong>
      <span>${data.Lon}</span>
    </li>
    <li>
      <strong>Скорость:</strong>
      <span>${data.Speed} км</span>
    </li>
    <li>
      <strong>Температура:</strong>
      <span></span>
    </li>
    <li>
      <strong>Уровень топлива:</strong>
      <span></span>
    </li>
  </ul>`;
}
