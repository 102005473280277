import { ILSTable } from '@components/index';
import { Dictionary, IILSTableProps, IndexedArray, TableRecord } from '@common/types';
import { FC } from 'react';
import { usePlanningTable } from '@modules/planning/children/by-date/hooks/use-planning-table';

export interface IILSTablePlanningComponentProps extends IILSTableProps<TableRecord> {
  //TODO нужно либо как-то сделать ограничение с помощью типа, либо разделить на 2 функции
  // NOTE функция должна возвращать объект строки, если передается только row, или измененное значение если передаются 3 аргумента
  handleDecorator?(row: any, dataIndex?: string | number, value?: any): any;

  dataDecorator(data: Array<unknown> | IndexedArray<unknown>): Array<unknown & TableRecord>;

  dictionaryLoad?(dictionary: Dictionary): void;
}

export const PlannerPlanningTablesContainer: FC<IILSTablePlanningComponentProps> = ({
  dictionary = Dictionary.Empty,
  dataDecorator,
  handleDecorator,
  ...props
}) => {
  const {
    handleSave,
    handleCreate,
    onCopy,
    handleDelete,
    handleRestore,
    handleReload,
    info,
    isFetching,
    catalogLoad,
    showDeleted,
    setShowDeleted,
    dataSource,
    handleCheckDelete
  } = usePlanningTable({ dictionary, dataDecorator, handleDecorator });

  return (
    <ILSTable
      {...props}
      showDeleted={showDeleted}
      setShowDeleted={setShowDeleted}
      dictionary={dictionary}
      loading={isFetching}
      tableName={Dictionary.Planning + dictionary}
      dataSource={dataSource}
      className="editable-table"
      handleCheckDelete={handleCheckDelete}
      onCopy={onCopy}
      handleSave={handleSave}
      handleReload={handleReload}
      handleCreate={handleCreate}
      handleDelete={handleDelete}
      handleRestore={handleRestore}
      dictionaryLoad={catalogLoad}
      dictionaryInfo={info}
    />
  );
};
