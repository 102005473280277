import { FC, useState } from 'react';
import { ILSButton, ILSCheckboxGroup, ILSForm, ILSFormItem, ILSInputSearch, ILSModal, ILSSpin, ILSUserNotify } from '@components/index';
import { Carriers } from '@modules/tender-platform/children/tender-own/types/carriers';
import { Account, Notify, NotifyDurationInSecond } from '@common/types';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { EMPTY_STRING, KeyboardCode } from '@common/constants/general';
import { TenderContent, TenderFormContent } from '@modules/tender-platform/constants';
import { ButtonContent } from '@common/constants/general/content';
import { useDispatch, useSelector } from 'react-redux';
import { searchCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderNotifyContent } from '@modules/tender-platform/constants/content';
import { ILSNoDataComponent } from '@components/common/no-data';
import { ButtonAction } from '@components/general/buttons';
import { tenderPlatformCarriersFormSelector } from '@modules/tender-platform/children/tender-own/selectors/components/carriers';
import { MIN_CHAR_FOR_QUERY } from '@common/components/data-entry/autocomplete/search';

interface IProps {
  title?: TenderFormContent;
  showForm: boolean;

  onCancel(): void;

  onOk(ids: Array<Account['ID']>): void;
}

export const TenderPlatformCarriersFormContainer: FC<IProps> = ({ title = TenderFormContent.AddCarrier, showForm, onCancel, onOk }) => {
  const dispatch = useDispatch();
  const { isLoading, options } = useSelector(tenderPlatformCarriersFormSelector);

  const [ids, setIds] = useState<Array<CheckboxValueType>>([]);
  const addingButton = `${ButtonContent.Add} ${ids.length > 1 ? `(${ids.length})` : EMPTY_STRING}`;
  const disabled = !Boolean(ids.length);

  const onFinish = () => {
    if (!disabled) {
      onOk(ids as Array<Account['ID']>);
      onCancel();
    } else {
      ILSUserNotify(Notify.Warning, 'Необходимо выбрать хотя бы одного перевозчика для сохранения', NotifyDurationInSecond.Ten);
    }
  };

  const handleChange = (ids: Array<CheckboxValueType>) => setIds(ids);

  const onSearch = (query: string) => {
    //Сбрасывает список перевозчиков до дефолтного
    if (!query) {
      dispatch(searchCarriersRoutine({ query }));
      return;
    }
    query.trim().length <= MIN_CHAR_FOR_QUERY
      ? ILSUserNotify(Notify.Warning, TenderNotifyContent.SEARCH_CARRIERS_LEN, NotifyDurationInSecond.Six)
      : dispatch(searchCarriersRoutine({ query }));
  };

  return (
    <ILSModal className={'carrier-add-modal'} title={title} visible={showForm} footer={null} onCancel={onCancel}>
      <ILSForm
        className={'carrier-form'}
        name="Carriers"
        onFinish={onFinish}
        onKeyDown={(e) => (e.key == KeyboardCode.Enter ? e.preventDefault() : e)}
      >
        <ILSFormItem name="Search">
          <ILSInputSearch loading={isLoading} placeholder={TenderFormContent.SearchCarrier} allowClear onSearch={onSearch} />
        </ILSFormItem>
        {isLoading ? (
          <ILSSpin />
        ) : (
          <>
            {options.length ? (
              <ILSCheckboxGroup onChange={handleChange} options={options} className={'carrier-checkboxes'} />
            ) : (
              <ILSNoDataComponent
                className={'bg-white position-relative p-3 pr-5 flex-direction-column'}
                text={TenderContent.CarriersNotFound}
              />
            )}
          </>
        )}
        <ILSButton children={addingButton} type={'primary'} form={'Carriers'} disabled={disabled} htmlType={'submit'} />
        <ILSButton action={ButtonAction.Cancel} onClick={onCancel} />
      </ILSForm>
    </ILSModal>
  );
};
