import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardSetModel, BoardDefaults } from '@common/types/dictionaries/analytics';
import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';

export const selectBoardSetSuccessCommonWatcher = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<AnalyticsBoardSetModel & BoardDefaults>) {
    const { trigger, failure } = actions.analyticsCommonGetBoardsRoutine;
    try {
      if (action.payload?.ID) {
        yield put(trigger({ BoardSetID: action.payload?.ID, defaults: { ...action.payload.defaults } }));
      }
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonSelectBoardSetRoutine.SUCCESS, worker);
  }

  return watcher;
};
