import { deliveryStatuses } from '../data/dashboard';

// Компонент несущий просто информацию, какой квадратик, что обозначает
const ILSDeliveryStatusInfoComponent = () => {
  return (
    <div className="ils-monitoring-delivery-status">
      {deliveryStatuses.map(({ text, color }, index) => (
        <div className="ils-monitoring-delivery-status-info" key={index}>
          <span
            className="ils-monitoring-delivery-status-square"
            style={{
              background: color
            }}
          />
          <span>{text}</span>
        </div>
      ))}
    </div>
  );
};

export default ILSDeliveryStatusInfoComponent;
