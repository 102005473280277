import { defineModule } from '@core/modules';
import { ModuleScope } from '@core/types';
import permissions from '@abac/permissions';
import { Module, ModulePath } from '@common/types';
import { DemoOrdersAndTrips } from '@modules/demo/children/orders-and-trips/containers';

export default defineModule({
  title: '',
  path: ModulePath.DemoOrdersNTrips,
  component: DemoOrdersAndTrips,
  inScope: [ModuleScope.CustomWithoutAuthPage],
  deps: [Module.Common, Module.OrdersNTrips],
  permission: permissions.NON_AUTH_PAGES,
  persist: false
});
