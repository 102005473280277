import { IMobileApplicationAPI } from '@common/types/general/api/mobile-application';
import { apiInstance } from '@core/api/instance';
import { getAPIURL } from '@common/api/helpers';
import { DictionariesEndpoint, Dictionary } from '@common/types';

export const MobileApplicationAPI: IMobileApplicationAPI = {
  handleMobileApplicationActionWithDriver: ({ operation, driverIDs }) => {
    //$_POST[ids] (int|array)
    return apiInstance().post(getAPIURL(Dictionary.Driver, DictionariesEndpoint[operation]), { ids: driverIDs });
  },
  mobileApplicationDictionaryOperation: ({
    mobileApplicationDictionary = Dictionary.ForwarderPointStatuses,
    operation = DictionariesEndpoint.Index,
    ids = [],
    values
  }) => {
    const url = getAPIURL(Dictionary[mobileApplicationDictionary], operation);
    switch (operation) {
      case DictionariesEndpoint.Create:
        return apiInstance().post(url, { data: { values } });
      case DictionariesEndpoint.Delete:
        return apiInstance().post(url, { data: { ids } });
      case DictionariesEndpoint.Update:
        return apiInstance().post(url, { data: { ids, values } });
      default:
        return apiInstance().post(url);
    }
  }
};
