import { CellType, ColumnTable, TableRecord } from '@common/types';

export const commonErrorColumns: ColumnTable<TableRecord>[] = [
  {
    title: 'Тип',
    dataIndex: 'Type',
    key: 'Type',
    type: CellType.Icon,
    width: 50
  },
  {
    title: 'Название',
    dataIndex: 'Name_',
    key: 'Name_',
    type: CellType.Text,
    ellipsis: true,
    width: 350,
    tooltip: { field: 'Tip' }
  },
  {
    title: 'Проверено',
    dataIndex: 'Chk_',
    key: 'Chk_',
    width: 90,
    type: CellType.Input
  },
  {
    title: 'Найдено',
    dataIndex: 'Found',
    key: 'Found',
    width: 80,
    type: CellType.Input
  }
];
