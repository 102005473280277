import { createSelector } from 'reselect';
import { TenderOwnState } from '@modules/tender-platform/children/tender-own/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { currentTenderParticipantsSelector } from '@modules/tender-platform/selectors/current-tender';

export const carriersFullSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.carriers,
  (carriers) => carriers
);
export const carriersListSelector = createSelector(carriersFullSelector, (carriers) => carriers.search);

//TODO whi is 2 state for one field? https://jira.intelogis.ru/browse/TMS-5767
export const carriersListLoadingSelector = createSelector(carriersFullSelector, (carriers) => carriers.loading);
export const carriersFetchingSelector = createSelector(carriersFullSelector, (carriers) => carriers.loading);
export const carriersListArraySelector = createSelector(carriersListSelector, (carriers) => createArrayFromIndexedArray(carriers));

export const myCarriersSelector = createSelector(carriersFullSelector, (carriers) => carriers.myCarriers);
export const myCarriersArraySelector = createSelector(myCarriersSelector, (carriers) => createArrayFromIndexedArray(carriers));
export const myCarriersIDsSelector = createSelector(myCarriersArraySelector, (myCarriers) =>
  myCarriers.map(({ CarrierID }) => Number(CarrierID))
);

export const tenderParticipantsIDSelector = createSelector(currentTenderParticipantsSelector, (TenderParticipants) =>
  createArrayFromIndexedArray(TenderParticipants).map(({ ID }) => Number(ID))
);

export const addedParticipantsSelector = createSelector(
  myCarriersIDsSelector,
  tenderParticipantsIDSelector,
  (myCarriersIDs, tenderParticipantsID) => new Set([...myCarriersIDs, ...tenderParticipantsID])
);
