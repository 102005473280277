import { EMPTY_STRING, JoinChar } from '@common/constants';
import { Dictionary, TableRecord } from '@common/types';
import { isReactElement } from '@components/data-display/table/utils/helpers/cells/transform-react-highlight-node-into-text';
import { CellChildren } from '@common/types/components/table/cell';

export const dataToCellTypeStringWithUtcHint = <R extends TableRecord>(time: CellChildren[1], record: R) => {
  const hint = isReactElement(time) ? time?.props?.children?.toString() : (time ?? EMPTY_STRING)?.toString();
  //TODO почему ячейка знает о Waypoint? для планировщика проще сделать отдельный тип ячейки, где можно будет передавать
  // hintFormat и format как функции принимающие record
  const content = Boolean(hint?.length)
    ? hint.split(record?.dictionary === Dictionary.Waypoint ? JoinChar.Space : '+')[record?.dictionary === Dictionary.Waypoint ? 1 : 0]
    : EMPTY_STRING;
  return { hint, content };
};
