import { compare } from '@common/utils/helpers/comparators/comparator';
import { EMPTY_STRING, JoinChar } from '@common/constants';

// сравнивает строки в "естественном" порядке
export const compareStrings = (str1: string, str2: string) => {
  const xor = (a: boolean, b: boolean) => (a ? !b : b); // логическое исключающее "или"

  // проверяет, является ли символ цифрой
  const isDigit = function (chr: string) {
    const charCode = (ch: string) => ch.charCodeAt(0);
    const code = charCode(chr);
    return code >= charCode('0') && code <= charCode('9');
  };

  // возвращает итератор для строки
  const splitString = function (str: string) {
    let from = 0; // начальный индекс для slice()
    let index = 0; // индекс для прохода по строке
    let count = 0; // количество уже найденных частей
    // будущий итератор
    return {
      // аналог свойства только для чтения
      count: () => count,
      // возвращает следующую часть строки
      next: () => {
        if (index === str.length) return null; // если строка закончилось - вернём null
        // перебираем символы до границы между нецифровыми символами и цифрами
        while (++index) {
          const currentIsDigit = isDigit(str.charAt(index - 1));
          const nextChar = str.charAt(index);
          const currentIsLast = index === str.length;
          // граница - если символ последний,
          // или если текущий и следующий символы разнотипные (цифра / не цифра)
          const isBorder = currentIsLast || xor(currentIsDigit, isDigit(nextChar));
          if (isBorder) {
            const part = str.slice(from, index).replaceAll(JoinChar.Space, EMPTY_STRING);
            from = index;
            count++;
            return {
              IsNumber: currentIsDigit,
              Value: currentIsDigit ? Number(part) : part
            };
          } // end if
        } // end while
      }
    };
  };

  // получаем итераторы для строк
  const splitter1 = splitString(str1);
  const splitter2 = splitString(str2);
  // перебираем части
  while (true) {
    const first = splitter1.next();
    const second = splitter2.next();
    // если обе части существуют ...
    if (first && second) {
      // части разных типов (цифры либо нецифровые символы)
      if (xor(first.IsNumber, second.IsNumber)) {
        // цифры всегда "меньше"
        return first.IsNumber ? -1 : 1; // части одного типа можно просто сравнить
      } else {
        const comp = compare(first.Value, second.Value);
        if (comp !== 0) return comp;
      } // end if
      // ... если же одна из строк закончилась - то она "меньше"
    } else {
      return compare(splitter1.count(), splitter2.count());
    }
  } // end while
};
