import {
  CellType,
  ColumnTable,
  DateFormat,
  DateTimeFormat,
  FilterSort,
  ILS_MAIN_API_DATE_FORMAT,
  TableRecord,
  TimeFormat
} from '@common/types';
import { FilterState } from '@common/types/components/table/common';
import { InputRef } from 'antd';
import { omit } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { EMPTY_STRING } from '@common/constants';
import { getDefaultColumnSearchProps } from './common-search-props';
import { doubleToTime, getValidMoment, parseDateFromServer } from '@common/utils';
import { dataToCellTypeStringWithUtcHint } from '../../decorators';
import { countDuration } from '@common/utils/helpers/date-time';

type Arg<R extends TableRecord> = {
  filtered: FilterSort['filtered'];
  column: Pick<ColumnTable<R>, 'dataIndex' | 'filtering' | 'options' | 'title' | 'type' | 'format'>;
  filterState: FilterState;
  setFilterState: Dispatch<SetStateAction<FilterState>>;
  searchInput: InputRef | null;
  dataIndex: string;
};

const filterSearchValue = (value: string | number | boolean, record?: string) => {
  return record?.toString().toLowerCase().includes(value?.toString().toLowerCase()) ?? false;
};

//TODO refactoring нужно собрать все функции и их декораторы в каком то одно месте и переиспользовать их
/**
 * @returns Возвращает функцию декоратор, который преобразует исходные данные в строку
 */
const convertDataToSearchValue = <R extends TableRecord>({
  type,
  format,
  dateParseFormat
}: Pick<ColumnTable<R>, 'type' | 'format' | 'dateParseFormat'>): ((value: any, record: R) => string) => {
  switch (type) {
    case CellType.DateTimeString:
      return (value) =>
        moment(getValidMoment(parseDateFromServer(value, false, format as DateFormat | DateTimeFormat | TimeFormat))).format(format);
    case CellType.TimeDouble:
      return doubleToTime;
    case CellType.TimeStringUtc:
      return (value: any, record: R) => dataToCellTypeStringWithUtcHint(value, record).content.toString();
    case CellType.Date:
      return (value: any) =>
        value
          ? parseDateFromServer(
              value,
              false,
              (dateParseFormat ?? ILS_MAIN_API_DATE_FORMAT) as DateFormat | DateTimeFormat | TimeFormat
            ).format(format)
          : EMPTY_STRING;
    case CellType.DateTime:
      return (value: any) =>
        value
          ? parseDateFromServer(
              value,
              true,
              (dateParseFormat ?? ILS_MAIN_API_DATE_FORMAT) as DateFormat | DateTimeFormat | TimeFormat
            ).format(format)
          : EMPTY_STRING;
    case CellType.Duration:
      return (value: any) => countDuration(value?.[0], value?.[1]);
    default:
      return (value) => value?.toString();
  }
};

export const datetimeFilterProps = <R extends TableRecord>({
  column,
  filterState,
  filtered,
  searchInput,
  setFilterState,
  dataIndex
}: Arg<R>) => {
  const { title, type, format } = column;
  const filteredValue = filtered && filtered?.[dataIndex];
  const convertFunction = convertDataToSearchValue({ type, format });
  const onFilter: ColumnTable<R>['onFilter'] = (value, record) => filterSearchValue(value, convertFunction(record?.[dataIndex], record));
  return omit(
    {
      ...getDefaultColumnSearchProps({ dataIndex, filterState, setFilterState, searchInput, filteredValue, title }),
      filteredValue,
      onFilter
    },
    ['render']
  );
};
