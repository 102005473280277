import { CellType, TableColumnName, TableConfig } from '@common/types';
import { CELL_INTEGER_VALIDATION_PARAMETERS } from '@components/data-display/table/constants/cell';
import { FormLabels } from '@common/constants/general/content/form';

export const tenderCarriersTableConfig: TableConfig = {
  deleting: true,
  selecting: true,
  adding: true,
  configClassName: 'tender-platform-my-carriers',
  columns: [
    {
      title: '№',
      dataIndex: TableColumnName.ID,
      key: TableColumnName.ID,
      editable: false,
      active: true,
      type: CellType.Input
    },
    {
      title: 'Наименование',
      dataIndex: TableColumnName.Name,
      key: TableColumnName.Name,
      active: true,

      width: 120,
      editable: false,
      ellipsis: false,
      type: CellType.Input,
      sorting: 1,
      filtering: 'string'
    },
    {
      title: 'ИНН',
      dataIndex: TableColumnName.INN,
      key: TableColumnName.INN,
      active: true,

      width: 120,
      editable: false,
      ellipsis: false,
      type: CellType.Input,
      sorting: 2
    },
    {
      title: FormLabels.Contact,
      dataIndex: TableColumnName.ClientContact,
      key: TableColumnName.ClientContact,
      active: true,

      width: 120,
      editable: false,
      ellipsis: false,
      type: CellType.Input,
      sorting: 3
    },
    {
      title: 'Телефон',
      dataIndex: TableColumnName.Phone,
      key: TableColumnName.Phone,
      active: true,

      width: 120,
      editable: false,
      ellipsis: false,
      type: CellType.Input,
      sorting: 4
    },
    {
      title: 'Рейтинг компании',
      dataIndex: TableColumnName.MinCarrierRating,
      key: TableColumnName.MinCarrierRating,
      active: true,

      editable: false,
      ellipsis: false,
      type: CellType.Int,
      validationParameters: CELL_INTEGER_VALIDATION_PARAMETERS
    }
  ]
};
