import { DeepRequired } from '@common/types/general/general';
import { DefaultUserParams } from '@modules/settings/types/store';
import { isEmpty } from 'lodash';

export const getDiffSettings = (currentSettings: DefaultUserParams, defaultSettings: DeepRequired<DefaultUserParams>) => {
  const result = Object.keys(defaultSettings).reduce((result, moduleKey) => {
    const allSections = defaultSettings[moduleKey].sections;

    Object.keys(allSections).forEach((sectionKey) => {
      const singleSectionData = allSections[sectionKey].data;

      Object.entries(singleSectionData).forEach(([paramkey, value]) => {
        if (!currentSettings[moduleKey]?.sections?.[sectionKey]?.data?.[paramkey]) {
          if (!result[moduleKey]) result[moduleKey] = { sections: {} };
          if (!result[moduleKey].sections?.[sectionKey]) result[moduleKey].sections = { [sectionKey]: { data: {} } };
          result[moduleKey].sections[sectionKey].data[paramkey] = value;
        }
      });
    });
    return result;
  }, {} as DefaultUserParams);

  if (isEmpty(result)) return null;

  return result;
};
