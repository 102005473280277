import { ForwarderPointStatuses } from '@modules/settings/types';
import { Depot, DriverModel, FileAttachment, IndexedArray } from '.';
import { Driver } from './driver';
import { UnviewedData } from './monitoring';
import { WaypointModel } from './waypoint';

export type MonitoringWaypointModel = WaypointModel & {
  WaypointFact?: MonitoringWaypointFactModel | null;
  Driver?: DriverModel;
  FactInd?: string;
  LastStatusDT?: number;
  LastStatus?: ForwarderPointStatuses;
  LastComment?: string;
  Attachments?: Array<FileAttachment>;
  EventStatusType: MonitoringWaypointEventStatusType;
  WaypointFactManual?: IndexedArray<MonitoringWaypointFactManualModel> | [];
  AttachmentsCount: number;
  EventStatusTime?: number;
  Depot?: Depot | null;
};

export type MonitoringWaypointUnviewedData = MonitoringWaypointModel & UnviewedData;

export type MonitoringWaypointEventUnviewed = MonitoringWaypointUnviewedData & WaypointEventTime;

export enum MonitoringWaypointEventStatusType {
  InTime = 'inTime',
  OnLate = 'late',
  Undelivered = 'undelivered',
  Wait = 'wait'
}
//TODO в типе должны быть только числа, при сравнении где нужно преобразовывать
export enum MonitoringSensorEventStatusType {
  SharpAcceleration = 199, //ускорение
  SharpBraking = 200, //торможение
  SharpTurn = 201, //поворот
  SensorTemp1 = '3', //датчик температуры 1
  SensorTemp2 = '4', //датчик температуры 2
  SensorOpenDoor = '5' //датчик открытия двери
}

export const MonitoringBDDEventsStatusType = [
  MonitoringSensorEventStatusType.SharpAcceleration,
  MonitoringSensorEventStatusType.SharpBraking,
  MonitoringSensorEventStatusType.SharpTurn
];

export type MonitoringWaypointEventModel = {
  GUID: string;
  EventDepotGUID: string;
  VehicleID: number;
  WaypointID: number;
  DT: number;
  PlanHit: number;
  WorktimeHit: number;
  FeasibleHit: number;
};
export type WaypointEventTime = {
  EventStatusTime: number;
  FactDepart: number;
  FactArrive: number;
};

export type MonitoringWaypointFactManualModel = {
  ID: string;
  WaypointID: number;
  Application: string;
  Status: number;
  DateTimeStart: number;
  DateTimeEnd: number;
  Comment: string;
  UserID: number;
  UTCOffset: number;
  DT: number;
  Latitude: number;
  Longitude: number;
};

export type MonitoringWaypointFactModel = {
  WaypointID: number;
  WaypointStatus: number;
  WaypointEventGUID: string;
  WaypointEvent: MonitoringWaypointEventModel;
};

export type MonitoringWaypointFactNullableUnviewed =
  | (MonitoringWaypointEventUnviewed & { WaypointFact?: MonitoringWaypointFactModel })
  | undefined
  | null;

export type MonitoringTripWaypointBrokenSequenceModel = {
  Waypoint: Array<MonitoringWaypointFactNullableUnviewed>;
  Driver: Driver;
  Comment: string | undefined;
  FactDistance: number;
} & UnviewedData;

export type MonitoringTripWaypointOnTimeModel = {
  ID: number;
  ProjectStart: number;
  Waypoint: IndexedArray<MonitoringWaypointFactNullableUnviewed>;
  Driver: Driver;
};

export type MonitoringWaypointUndeliveredModel = MonitoringWaypointEventUnviewed;

export type MonitoringWaypointOnLateModel =
  | (MonitoringWaypointUnviewedData &
      WaypointEventTime & {
        WaypointFact?: MonitoringWaypointFactModel;
      })
  | null
  | undefined;

export type MonitoringTripWaypointOnLateModel = {
  Waypoint: Array<MonitoringWaypointOnLateModel>;
  Driver: Driver;
};

export type MonitoringWaypointOnViolationsModel = MonitoringWaypointModel &
  WaypointEventTime & {
    WaypointFact: MonitoringWaypointFactModel;
  };

export type MonitoringTripWaypointOnViolationsModel = {
  Waypoint: Array<MonitoringWaypointOnViolationsModel>;
  Driver: Driver;
};

export type MonitoringTripWaypointServedModel = MonitoringWaypointModel & {
  Waypoint: Array<MonitoringWaypointFactNullableUnviewed>;
  Driver: Driver;
};
