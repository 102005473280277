import React, { FC, useContext, useEffect } from 'react';
import '../styles.less';
import { ILSButton, ILSCheckbox, ILSForm, ILSFormItem, ILSInput, ILSSelect } from '@components/index';
import { ROAD_TYPES } from '@modules/road-graph/editor/constants';
import { RoadGraphEditorContext } from '@modules/road-graph/editor/context';
import { Form } from 'antd';

interface Props {
  handleSave: (values: {}) => void;
}

const ILSRoadGraphEditorSegmentEditForm: FC<Props> = (props) => {
  const { edit, setEdit } = useContext(RoadGraphEditorContext) ?? {};
  const { handleSave } = props;

  const initialValues =
    edit && typeof edit === 'object'
      ? {
          ...edit,
          available: true
        }
      : {};

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const roadOptions = Object.keys(ROAD_TYPES).map((key) => {
    return {
      value: key,
      label: ROAD_TYPES[key].label
    };
  });
  return (
    <ILSForm
      name="account"
      layout={'vertical'}
      onFinish={handleSave}
      className="account-form px-3"
      initialValues={initialValues}
      form={form}
    >
      <ILSFormItem
        label="Тип дороги"
        name="road"
        rules={[
          {
            required: true,
            message: 'Пожалуйста, выберите значение'
          }
        ]}
      >
        <ILSSelect placeholder="Выберите значение" options={roadOptions} />
      </ILSFormItem>

      <ILSFormItem
        label="Скорость"
        name="speed"
        rules={[
          {
            required: true,
            message: 'Пожалуйста, введите значение'
          }
        ]}
      >
        <ILSInput placeholder="60" type={'number'} />
      </ILSFormItem>

      <ILSFormItem label="Возможность проезда" name="available" className="checkbox-item" valuePropName="checked">
        <ILSCheckbox />
      </ILSFormItem>

      <div className="mt-3 flex-between-center">
        <ILSButton type="default" onClick={() => setEdit && setEdit(null)}>
          Отмена
        </ILSButton>
        <ILSButton type="primary" htmlType="submit">
          Сохранить
        </ILSButton>
      </div>
    </ILSForm>
  );
};

export default ILSRoadGraphEditorSegmentEditForm;
