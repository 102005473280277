import { emulatorStartRoutine } from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EmulatorAPI } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { StartRequest } from '../types/api';

// Запускает эмуляцию выбранного плана (planID)
function* emulatorStartWorker(action: PayloadAction<StartRequest>) {
  const { request, success, failure, fulfill } = emulatorStartRoutine;
  const { planID } = action.payload;

  try {
    yield put(request());

    yield call(EmulatorAPI.start, { planID });

    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* emulatorStartWatcher() {
  yield takeLatest(emulatorStartRoutine.TRIGGER, emulatorStartWorker);
}
