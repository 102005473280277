import { useDispatch, useSelector } from 'react-redux';
import { planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';
import { Keys, TableName } from '@common/types';
import { FocusNExpandRowsChangingType } from '@modules/planning/children/by-date/types/tables-container';
import { planningFocusNExpandRowIdsRoutesTableSelector } from '@modules/planning/children/by-date/selectors/planning';

export const useExpandableRow = () => {
  const dispatch = useDispatch();
  const { expandedRowKeys } = useSelector(planningFocusNExpandRowIdsRoutesTableSelector(TableName.RoutesTable));
  const onExpandedRowsChange = (expandedRows: Keys) => {
    dispatch(
      planningRememberFocusNExpandRowsRoutine({
        tableName: TableName.RoutesTable,
        expandedRows,
        changingType: FocusNExpandRowsChangingType.Expand
      })
    );
  };

  return {
    externalExpandableProps: {
      expandedRowKeys,
      onExpandedRowsChange
    }
  };
};
