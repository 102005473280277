import { FC } from 'react';
import { ILSButton, ILSInput, ILSTooltip } from '@common/components';

const ILSDocumentsSearch: FC = () => {
  return (
    <div className="d-flex">
      <ILSInput placeholder="Поиск документов" maxLength={50} />
      <ILSTooltip placement={'bottom'} title={'В разработке'}>
        <ILSButton disabled type="primary">
          Найти
        </ILSButton>
      </ILSTooltip>
    </div>
  );
};

export default ILSDocumentsSearch;
