import { createSelector } from 'reselect';
import { AnalyticsPlanningStore } from '../types/store';

export const boardSetsSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.BoardSets
);
export const boardsSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.Boards
);
export const selectedBoard = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.Boards.selectedBoard
);

export const widgetsSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.Widgets
);

export const widgetDataSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.WidgetData
);

export const savingBoardWidgetsSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.Boards.savingWidgets
);

export const widgetCatalogSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.widgetCatalog
);

export const widgetParamsSelector = createSelector(
  (state: { AnalyticsPlanningBoards: AnalyticsPlanningStore }) => state,
  (state) => state.AnalyticsPlanningBoards.WidgetParams
);

