import { put, select, takeLatest } from 'redux-saga/effects';
import { moveSchemeKeysRoutine } from '@modules/planning/children/scheme-editor/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { RecordType, SchemeDragInfo, SchemeSwitchDictionary, TypeContainer } from '@modules/planning/children/scheme-editor/types';
import { ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond } from '@common/types';
import { getSelector } from '@modules/planning/children/scheme-editor/selectors/current-scheme';
import { substituteItems } from '@modules/planning/children/scheme-editor/utils/helpers/substitute-items';

function* moveSchemeKeysWorker(
  action: PayloadAction<
    SchemeDragInfo & {
      dictionary: SchemeSwitchDictionary;
    }
  >
) {
  const { success, failure } = moveSchemeKeysRoutine;
  const { target, typeContainer, source, dictionary } = action.payload;
  try {
    const currentKeys: Array<RecordType['title']> = yield select(getSelector(dictionary));
    //TODO разбит логику на функции
    if (target === typeContainer) {
      switch (typeContainer) {
        case TypeContainer.Current:
          const newCurrentKeys = currentKeys.concat(source.title);
          yield put(success({ newCurrentKeys, dictionary, newKey: source.title }));
          break;
        case TypeContainer.Max:
          yield put(success({ newCurrentKeys: currentKeys.filter((key) => key !== source.title), dictionary }));
      }
    } else {
      if (typeContainer === source.typeContainer) {
        if (typeContainer === TypeContainer.Max) {
          ILSUserNotify(Notify.Warning, 'Передвижения в рамках максимальной схемы не меняют порядок', NotifyDurationInSecond.Eight);
          return;
        }
        const newCurrentKeys = substituteItems(currentKeys, source, target);
        yield put(success({ newCurrentKeys, dictionary }));
      } else {
        switch (typeContainer) {
          case TypeContainer.Max:
            yield put(success({ newCurrentKeys: currentKeys.filter((key) => key !== source.title), dictionary }));
            break;
          case TypeContainer.Current:
            const newCurrentKeys = substituteItems(currentKeys, source, target, true);
            yield put(success({ newCurrentKeys, dictionary, newKey: source.title }));
            break;
        }
      }
    }
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* moveSchemeKeysWatcher() {
  yield takeLatest(moveSchemeKeysRoutine.trigger, moveSchemeKeysWorker);
}
