import { catalogCreateRoutine, catalogReadRoutine, catalogSetFocusRowRoutine } from '@modules/catalog/actions';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { DictionaryAPI } from '@common/api/common';
import { checkAndCreateArrayFromObject } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { CatalogCreatePayload } from '@modules/catalog/types/actions';

export function* catalogCreateWorker(action: CatalogCreatePayload) {
  const { dictionary, values, saveDictionary } = action.payload;
  const { request, success, failure, fulfill } = catalogCreateRoutine;

  try {
    const notifyKey = `create-dictionary-key-${dictionary}`;

    yield put(request({ dictionary, notifyKey }));

    const {
      data: { data }
    } = yield call(DictionaryAPI.create, { dictionary, values });

    /** Передаем id новой строки для фокусировки в catalog.read сагу */
    const newRow = checkAndCreateArrayFromObject(data);
    const rowIDToFocus = newRow[0].ID;

    yield put(
      success({
        data: newRow,
        dictionary,
        saveDictionary,
        notifyKey
      })
    );
    yield put(
      catalogReadRoutine.trigger({
        dictionary,
        dictFetching: false,
        notifyKey,
        projectID: null,
        withoutNotification: true,
        uncheckProjectButton: true,
        rowIDToFocus: rowIDToFocus
      })
    );
  } catch (error) {
    yield put(failure({ error, dictionary }));
  } finally {
    yield delay(2000);
    yield put(fulfill({ dictionary }));
  }
}

export function* catalogCreateWatcher() {
  yield takeLatest(catalogCreateRoutine.trigger, catalogCreateWorker);
}
