import { KeyboardEvent } from 'react';
import { ILSInput } from '@components/index';
import { IILSCellInputProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { getCellInputValue } from '@components/data-display/table/utils/helpers/cells/get-cell-input-value';
import { EMPTY_STRING } from '@common/constants';
import { handleCellInputSave } from '@components/data-display/table/utils/helpers/cells/handlers/handle-cell-input-save';

export const ILSCellInput = <R extends TableRecord>({
  save,
  toggleEdit,
  validationParameters = {},
  maxLength = 500,
  children,
  placeholder
}: IILSCellInputProps<R>): JSX.Element => {
  const [node, value = EMPTY_STRING] = children;
  const { forbiddenKeys, replacer } = validationParameters;

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (forbiddenKeys && e.key.match(forbiddenKeys)) {
      e.preventDefault();
    }
  };

  const handleSave = handleCellInputSave({ forbiddenKeys, save, toggleEdit, value, replacer });

  return (
    <>
      {node}
      <ILSInput
        placeholder={placeholder}
        onKeyDown={handleOnKeyDown}
        className={'cell-wrapper-editable-full'}
        autoFocus
        bordered
        defaultValue={getCellInputValue(value)}
        allowClear={false}
        onPressEnter={handleSave}
        onBlur={handleSave}
        maxLength={maxLength}
      />
    </>
  );
};
