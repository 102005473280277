import { useAppHistory } from '@common/hooks/history/use-app-history';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { TenderPlatformPaths } from '@modules/tender-platform/types';

export const useTenderCreating = () => {
  const { search } = useAppHistory();
  const isTenderCreating = search.includes(TenderAction.Create);
  const isTendersCreatingFromPlanner = isTenderCreating && search === TenderPlatformPaths.CreateFromPlanner;
  return { isTenderCreating, isTendersCreatingFromPlanner };
};
