import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { onFailure, onFulfill } from '@common/utils/helpers/reducers';
import { initialState } from '@modules/planning/children/by-date/store';
import { planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';
import { DictionariesEndpoint, Dictionary, TableName } from '@common/types';
import { FocusNExpandRowsChangingType } from '@modules/planning/children/by-date/types/tables-container';
import { isEmpty, isNil } from 'lodash';
import { RememberFocusNExpandRows } from '@modules/planning/children/by-date/types/record';

export const rememberFocusNExpandRowsReducer = createReducer(initialState, {
  [planningRememberFocusNExpandRowsRoutine.SUCCESS]: (state, action: PayloadAction<RememberFocusNExpandRows>) => {
    const { expandedRows, ID, changingType, tableName, lastModifiedData, routeIDs } = action.payload ?? {};
    if (!tableName) return state;

    if (tableName === TableName.RoutesTable) {
      if (!state.userSettings.table[tableName]) {
        state.userSettings.table[tableName] = {
          focusRow: { key: !isNil(routeIDs) && !isEmpty(routeIDs) ? routeIDs[0] : null },
          expandedRows: []
        };
      }
      const currentExpandableRowKeys = state.userSettings.table[tableName]?.expandedRows ?? [];
      const currentFocus = state.userSettings.table[tableName]?.focusRow.key ?? null;

      switch (changingType) {
        case FocusNExpandRowsChangingType.Focus:
          state.userSettings.table[tableName] = {
            ...state.userSettings.table[tableName]!,
            focusRow: { key: ID ?? null }
          };
          break;
        case FocusNExpandRowsChangingType.UnsetFocus:
          state.userSettings.table[tableName] = {
            ...state.userSettings.table[tableName]!,
            focusRow: { key: null },
            expandedRows: []
          };
          break;
        case FocusNExpandRowsChangingType.Expand:
          state.userSettings.table[tableName] = {
            ...state.userSettings.table[tableName]!,
            expandedRows: expandedRows ?? []
          };
          break;
        case FocusNExpandRowsChangingType.CheckAndUpdate:
          if (tableName && lastModifiedData) {
            const { routeTotal, tripIDs, dictionary, waypointIDs, vehicleID, orderActionID, operation, currentPlan } = lastModifiedData;
            const tripID = tripIDs?.[0];
            const waypointID = waypointIDs?.[0];

            const newFocusRow = {
              key: currentFocus
            };

            /** Если рейс больше не существует */
            if (!routeTotal && [vehicleID, tripID, waypointID].includes(currentFocus as number)) {
              const routeKeys = Object.keys(currentPlan?.RouteTotal ?? {});
              if (!isEmpty(routeKeys)) {
                const lastRouteKey = routeKeys[routeKeys.length - 1];
                newFocusRow.key = Number(lastRouteKey);
              }
            }

            switch (dictionary) {
              case Dictionary.Route:
                switch (operation) {
                  case DictionariesEndpoint.Create:
                    if (vehicleID) {
                      newFocusRow.key = vehicleID;
                    }
                    break;
                }
                break;
              case Dictionary.Trip:
                switch (operation) {
                  case DictionariesEndpoint.Delete:
                    if (routeTotal && vehicleID && tripID) {
                      const tripKeys = Object.keys(routeTotal[vehicleID]?.TripTotal);
                      if (!isEmpty(tripKeys)) {
                        const lastTripKey = tripKeys[tripKeys.length - 1];
                        newFocusRow.key = Number(lastTripKey);
                      }
                    }
                    break;
                  case DictionariesEndpoint.Create:
                    if (tripID) {
                      newFocusRow.key = tripID;
                    }
                    if (vehicleID) {
                      currentExpandableRowKeys?.push(vehicleID);
                    }
                    break;
                }
                break;
              case Dictionary.Waypoint:
                switch (operation) {
                  case DictionariesEndpoint.Delete:
                    if (routeTotal && vehicleID && tripID && waypointID) {
                      const waypointKeys = Object.keys(routeTotal[vehicleID]?.TripTotal?.[tripID]?.WaypointTotal);
                      if (!isEmpty(waypointKeys)) {
                        const lastWaypointKey = waypointKeys[waypointKeys.length - 1];
                        newFocusRow.key = Number(lastWaypointKey);
                      }
                    }
                    break;
                  case DictionariesEndpoint.Create:
                    if (tripID && waypointID) {
                      newFocusRow.key = Number(tripID);
                      currentExpandableRowKeys?.push(tripID);
                    }
                }
                break;
              case Dictionary.OrderAction:
                switch (operation) {
                  case DictionariesEndpoint.Delete:
                    if (routeTotal && vehicleID && tripID && waypointID && waypointID && orderActionID) {
                      const orderActionKeys = Object.keys(
                        routeTotal[vehicleID]?.TripTotal?.[tripID]?.WaypointTotal?.[waypointID]?.OrderActionTotal ?? {}
                      );
                      if (!isEmpty(orderActionKeys)) {
                        const lastOrderActionKey = orderActionKeys[orderActionKeys.length - 1];
                        newFocusRow.key = Number(lastOrderActionKey);
                      } else {
                        const waypointKeys = Object.keys(routeTotal[vehicleID]?.TripTotal?.[tripID]?.WaypointTotal ?? {});
                        if (!isEmpty(waypointKeys)) {
                          const lastWaypointKey = waypointKeys[waypointKeys.length - 1];
                          newFocusRow.key = Number(lastWaypointKey);
                        }
                      }
                    }
                    break;
                }
            }

            state.userSettings.table[tableName] = {
              focusRow: newFocusRow,
              expandedRows: Array.from(new Set(currentExpandableRowKeys))
            };
          }
          break;
      }
    } else {
      state.userSettings.table[tableName] = {
        focusRow: { key: ID || null },
        expandedRows: []
      };
    }

    return state;
  },
  [planningRememberFocusNExpandRowsRoutine.FAILURE]: onFailure,
  [planningRememberFocusNExpandRowsRoutine.FULFILL]: onFulfill
});
