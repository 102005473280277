import '../styles.less';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tenderIndexRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderPlatformOwnList } from '@modules/tender-platform/children/tender-own/containers/list';
import { Module } from '@common/types';
import { TenderPlatformPaths } from '@modules/tender-platform/types';
import { ILSTenderCardsContainer } from '@modules/tender-platform/containers/tender-card';
import { ILSTenderModuleContext } from '@modules/tender-platform/context';
import { useTenderOpen } from '@modules/tender-platform/utils/hooks';
import { useAppHistory } from '@common/hooks/history/use-app-history';
import { ILSTenderCarriersCard } from './carriers-card';
import { useTenderDelete } from '@modules/tender-platform/utils/hooks/use-tender-delete';

const ILSTenderOwn: FC = () => {
  const dispatch = useDispatch();
  const { search } = useAppHistory();
  const { handleOpen } = useTenderOpen();
  const { handleDelete } = useTenderDelete();
  const isCards = Boolean(search) && !search.includes(TenderPlatformPaths.CarriersList);
  const isCarriers = Boolean(search) && search.includes(TenderPlatformPaths.CarriersList);

  useEffect(() => {
    dispatch(tenderIndexRoutine());
  }, []);

  const componentToRender = () => {
    switch (true) {
      case isCards:
        return <ILSTenderCardsContainer />;
      case isCarriers:
        return <ILSTenderCarriersCard />;
      default:
        return <TenderPlatformOwnList handleDelete={handleDelete} handleOpen={handleOpen} />;
    }
  };

  return <ILSTenderModuleContext.Provider value={Module.TenderOwn}>{componentToRender()}</ILSTenderModuleContext.Provider>;
};

export default ILSTenderOwn;
