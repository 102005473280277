import { DEFAULT_ORDER_COLOR } from '@common/constants/colors/order';
import { MarkerPlanning, MarkerType } from '@common/types';
import { isLatLngTuple } from '@common/types/general';
import { getLatLngExpression } from '@common/utils';
import { depotTip, orderTip, pointTip } from '@core/components/map/icon-tips';
import { carMarker, colorMarker, customMarker, defaultMarker, orderMarker, pointMarker } from '@core/components/map/icons';
import homeMarker from '@core/components/map/icons/storage.svg';
import loadMarker from '@core/components/map/icons/take.svg';
import clientMarker from '@core/components/map/icons/user.svg';
import { ILSMap } from '@core/containers/map';
import { LayerHandler } from '@core/types/map';
import { anyDataMapItemType } from '@modules/planning/children/by-date/types';
import { OnItemClick } from '@modules/planning/children/by-date/types/map-container';
import L, { LatLngTuple } from 'leaflet';
import { isFunction, isNil } from 'lodash';
import { POPUP_MAX_WIDTH } from '../../constants/map';

export const marker2ILSMarker = (
  mProps: MarkerPlanning & {
    selected?: boolean | undefined;
  },
  onMarkerClick?: OnItemClick,
  setDraggableMarkerIDs?: (newMarkerData: anyDataMapItemType) => void,
  handlersArg?: Record<string, LayerHandler<L.Marker>>
) => {
  const ID = mProps?.data?.ID;

  const selected = mProps.selected;
  const draggable = mProps.type && mProps.type === MarkerType.Waypoint;
  let icon;
  let popup;
  const coords = mProps.coords;

  if (mProps.pointData && mProps.type) {
    if (mProps.type === MarkerType.Waypoint) {
      popup = pointTip(mProps.pointData);
    }

    if (mProps.type === MarkerType.Order) {
      popup = orderTip(mProps.pointData);
    }

    if (mProps.type === 'storage' || mProps.type === 'client' || mProps.type === 'load') {
      popup = depotTip(mProps.pointData);
    }
  }
  switch (mProps.type) {
    case 'color':
      icon = colorMarker(mProps.color as string);
      break;
    case 'order':
      icon = orderMarker(mProps.color ?? DEFAULT_ORDER_COLOR, !!selected, String(ID), mProps.data);
      break;
    case 'point':
      icon = pointMarker(
        mProps.data,
        mProps.color as string,
        mProps.number as number | string,
        mProps.figure,
        selected,
        String(ID),
        mProps.isHome,
        mProps.homeNumber
      );
      break;
    case 'storage':
      icon = customMarker(homeMarker, '#4C91FF');
      break;
    case 'client':
      icon = customMarker(clientMarker, '#2358C6');
      break;
    case 'load':
      icon = customMarker(loadMarker, '#50B993');
      break;
    case 'car':
      icon = carMarker();
      break;
    default:
      icon = defaultMarker;
  }

  const click: LayerHandler<L.Marker> = (eventData) => {
    if ((mProps.type === MarkerType.Point || mProps.type === MarkerType.Order) && mProps.clickData && isFunction(onMarkerClick)) {
      onMarkerClick(mProps?.clickData, eventData);
    }
  };

  const dragend: LayerHandler<L.Marker> = (props) => {
    if (draggable) {
      if (isNil(coords)) console.error('coords не определены');
      const latLon = getLatLngExpression(coords);
      isLatLngTuple(latLon) && props?.layer?.setLatLng(latLon);
      if (setDraggableMarkerIDs && mProps.clickData) {
        setDraggableMarkerIDs({ data: mProps.clickData });

        setTimeout(() => {
          setDraggableMarkerIDs!(null);
        }, 100);
      }
    }
  };
  const maxWidthPopup = mProps.type ? POPUP_MAX_WIDTH?.[mProps.type] ?? POPUP_MAX_WIDTH.default : POPUP_MAX_WIDTH.default;

  const markerInit: Parameters<ILSMap['addMarker']>[0][0] = {
    coords: mProps['coords'] as LatLngTuple,
    options: {
      data: mProps['data'],
      icon: icon,
      color: mProps.color,
      draggable: false
    },
    handlers: {
      ...handlersArg,
      click: click,
      dragend: dragend
    },
    popup: popup ? [popup, { maxWidth: maxWidthPopup, autoPanPaddingBottomRight: [50, 1000] }] : undefined
  };

  return markerInit;
};

export default {
  marker2ILSMarker
};

