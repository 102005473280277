import { AnalyticsWidget } from '@common/types/dictionaries/analytics';
import React, { ForwardedRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ILSAnalyticsWidgetContainer from '../../container/widget';
import '@modules/analytics/common/styles.less';
import { IndexedArray } from '@common/types';
import { WidgetSize } from '../../types/widget-position';
import { WidgetProps } from '../../types/container';
import { useCheckWidgetIntersect } from '../../hooks/use-check-widget-intersect';
import classNames from 'classnames';
import useDragWidget from '../../hooks/use-drag-widget';
import { useCombineRefs } from '@common/hooks/use-combine-ref';
import { useListenWindowResize } from '@modules/analytics/common/hooks/use-listen-window-resize';
import { useRefReady } from '@modules/analytics/common/hooks/use-ref-ready';
import { AnalyticsWidgetBoardContextType } from '@modules/analytics/common/types/context/widget-board-context';
import { AnalyticsWidgetBoardContext } from '@modules/analytics/common/context/widget-board-context';
import AnalyticsModalWidgetContainer from '../../container/modal-widget-container';

type WidgetBoardProps = {
  widgets?: IndexedArray<AnalyticsWidget> | null;
  changedWidgets?: IndexedArray<AnalyticsWidget> | null;
  hideWidgetsOnError?: boolean;
  widgetsError?: boolean;
  forPDF?: boolean;
  setBoardWidgets?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  from?: string | number;
  till?: string | number;
} & Pick<
  WidgetProps,
  | 'handleSelectBoard'
  | 'stretchable'
  | 'widgetParams'
  | 'handleSetParams'
  | 'handleChange'
  | 'handleGetWidgetData'
  | 'dropWidgetOnBoard'
  | 'deleteBoardWidget'
>;

const ILSWidgetBoard = React.forwardRef((props: WidgetBoardProps & { editMode?: boolean }, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    widgets,
    changedWidgets = {},
    editMode,
    handleChange = () => {},
    stretchable = false,
    widgetParams = {},
    handleSetParams,
    handleGetWidgetData = () => {},
    deleteBoardWidget = () => {},
    dropWidgetOnBoard = () => {},
    handleSelectBoard = () => {},
    hideWidgetsOnError = false,
    widgetsError = false,
    forPDF,
    from,
    till,
    setBoardWidgets
  } = props;

  //перемещенные виджеты
  const [widgetsLocation, setWidgetsLocation] = useState<IndexedArray<{ ID: number | string; size: WidgetSize }>>({});

  //измененные виджеты
  const [resizedWidgets, setResizedWidgets] = useState<IndexedArray<AnalyticsWidget>>({});

  //проверка пересечений
  const { rerender } = useCheckWidgetIntersect();

  //виджеты для доски с измененными размерами
  const boardWidgets = useMemo(() => {
    const serverWidgets = widgets ? Object.values(widgets) : [];
    if (!stretchable || editMode) return serverWidgets;
    return serverWidgets.map((w) => {
      if (resizedWidgets[w.ID]) {
        return {
          ...w,
          Position: { Left: resizedWidgets[w.ID].Position?.Left || 0, Top: resizedWidgets[w.ID].Position?.Top || 0 }
        };
      }
      return w;
    });
  }, [widgets, editMode, resizedWidgets, stretchable]);

  /** метод срабатывает для виджета, изменившего свой размер в случае "растягиваемой доски", для такого сохраняется на доске его новые размеры
   **
   */
  const onRenderWidget = (widget: AnalyticsWidget, size: WidgetSize) => {
    if (stretchable && !editMode && widget.ID) {
      const updatedWidget = {};
      updatedWidget[widget.ID] = { ID: widget.ID, size: { ...size } };
      setWidgetsLocation((prev) => {
        return { ...prev, ...updatedWidget };
      });
    }
  };

  /**
   * Для "растягиваемой" доски в случае изменеия размера видежта перестраиваем новые виджеты, чтобы они не налезали друг на друга. Налезающие виджеты смещаются вниз
   * */
  useEffect(() => {
    if (stretchable && !editMode) {
      if (Object.keys(widgetsLocation).length >= boardWidgets.length && widgets && Object.keys(widgetsLocation).length > 0) {
        const widgetsToResize = rerender(widgetsLocation, widgets);
        setResizedWidgets(widgetsToResize || {});
      }
    }
  }, [widgetsLocation]);

  //сброс изменений размеров видежтов
  const resetLocations = () => {
    if (stretchable && !editMode) setWidgetsLocation({});
  };

  //при изменении окна обнуляем все смещения и ждем ререндера виджетов
  useListenWindowResize({ callback: resetLocations, condition: !editMode && stretchable, timeout: 50 });

  const boardRef = useRef<HTMLDivElement>(null);

  const combineRefs = useCombineRefs();

  // if  true - ref  is now available
  const refReady = useRefReady();

  /**Методе проверки виджета на перечечение с другими виджетам на доске,в том числе с уже измененными
   * Принимает на вход виджет
   * Возвращает false -если виджет пересекается с другими,
   *  true -если пересечений нет
   * */
  const checkWidgets = useCallback(
    (widget: AnalyticsWidget) => {
      if (widgets) {
        const widgetsToCheck = { ...widgets, ...changedWidgets };

        widgetsToCheck[widget.ID] = widget;
        const intersectedWidgets = rerender(widgetsLocation, widgetsToCheck, true);
        if (intersectedWidgets && widget.ID && Object.keys(intersectedWidgets).includes(widget.ID.toString())) {
          return false;
        }
      }
      return true;
    },
    [changedWidgets, rerender, widgetsLocation, widgets]
  );

  /** Метод удаления виджета*/
  const onDeleteBoardWidget = (widget: AnalyticsWidget) => {
    delete resizedWidgets[widget.ID];
    delete widgetsLocation[widget.ID];
    deleteBoardWidget(widget);
  };

  // Хук для переноса виджета на доску из каталога
  const { boardDroppable, newWidget, dragOver, dragLeave, onDrop, onDragEnd } = useDragWidget({
    boardRef,
    dropWidgetOnBoard,
    checkWidgets
  });

  /**При появлении нового виджета сохряем его изменения*/
  useEffect(() => {
    if (newWidget) {
      handleChange(newWidget as AnalyticsWidget);
    }
  }, [newWidget]);

  const context: AnalyticsWidgetBoardContextType = {
    widgetParams,
    editMode: editMode || false,
    stretchable,
    handleSetParams,
    handleGetWidgetData,
    deleteBoardWidget: onDeleteBoardWidget,
    handleSelectBoard,
    onRenderWidget,
    checkWidgets,
    board: boardRef.current,
    hideWidgetsOnError,
    widgetsError,
    handleChange
  };

  return (
    <AnalyticsWidgetBoardContext.Provider value={context}>
      <div
        className={classNames(
          'analytics-widget-board',
          'analytics-scroll',
          { 'analytics-widget-board-stretchable': stretchable },
          { droppable: boardDroppable }
        )}
        onDragOver={dragOver}
        onDrop={onDrop}
        onDragEnd={onDragEnd}
        onDragLeave={dragLeave}
        ref={combineRefs(boardRef, ref)}
      >
        {refReady &&
          boardWidgets.map((widget) => {
            return forPDF ? (
              <AnalyticsModalWidgetContainer
                setBoardWidgets={setBoardWidgets}
                board={boardRef.current}
                from={from}
                till={till}
                widget={widget}
                key={widget.ID}
              />
            ) : (
              <ILSAnalyticsWidgetContainer widget={widget} key={widget.ID} />
            );
          })}
      </div>
    </AnalyticsWidgetBoardContext.Provider>
  );
});
export default ILSWidgetBoard;

