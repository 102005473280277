import { SECONDS_TO_DAYS_MULTIPLIER } from '@common/constants/general/date-time';
import { DateFormat } from '@common/types';
import { MonitoringWaypointEventStatusType } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToDateTimeStringWithUTC } from '@common/utils/helpers/date-time/date-time';
import { UTC_OFFSET_DEFAULT } from '@modules/monitoring/children/dashboard/components/dashboard/configs/chart';
import { ChartDecorator, WaypointType } from '@modules/monitoring/children/dashboard/types/chart-decorator';
import { ChartSchemaDataSetType, MonitoringChartVehicle } from '@modules/monitoring/types/chart';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

//TODO декомпозировать и разделить на файлы и перенести их в папку ./chart
// преобразует waypoint к координатам biz-chart Schema;
export function waypointsToChartSchemaData(
  waypoints: (WaypointType | null | undefined)[],
  projectStartTime: number,
  id: number,
  showDate: string
): MonitoringChartVehicle['data'] {
  let prevPointEndPlan: number | undefined, prevPointEndFact: number | undefined;
  const currentDayDT = moment(showDate, DateFormat.YYYYMMDDDash).utc(false).unix();
  const nextDayDT = moment(showDate, DateFormat.YYYYMMDDDash).add(1, 'day').utc(false).unix();

  const isActiveDay = (departedDT?: number, arrivalDT?: number) =>
    Boolean(
      (arrivalDT && nextDayDT >= arrivalDT && currentDayDT <= arrivalDT) ||
        (departedDT && nextDayDT >= departedDT && currentDayDT <= departedDT)
    );

  return waypoints.reduce<MonitoringChartVehicle['data']>((schema, waypoint) => {
    if (isEmpty(waypoint)) return schema;
    const { ArrivalTime, DepartureTime, EventStatusType, FactArrive, FactDepart, Depot } = waypoint;
    const planWayEnd = Math.round(ArrivalTime * SECONDS_TO_DAYS_MULTIPLIER) + projectStartTime;
    const planWayStart = prevPointEndPlan || 0;
    const planPointStart = planWayEnd;
    const planPointEnd = Math.round(DepartureTime * SECONDS_TO_DAYS_MULTIPLIER) + projectStartTime;
    const hasLates = EventStatusType === MonitoringWaypointEventStatusType.OnLate;
    prevPointEndPlan = planPointEnd;
    const isActivePlan = isActiveDay(planWayStart);
    const isActiveFact = isActiveDay(FactDepart, FactArrive);
    if (!isActivePlan && !isActiveFact) return schema;

    const onWayPlanImageData: MonitoringChartVehicle['data'][0] = {
      onWay: true,
      plan: true,
      start: planWayStart,
      end: planWayEnd,
      onTimePart: EventStatusType,
      dataset: createDataSet({
        id,
        waypointID: waypoint.ID,
        clientName: Depot?.Name ?? '',
        clientAddress: Depot?.ClientContact ?? '',
        planPointStart,
        FactArrive,
        wpOffset: Depot?.UTCOffset
      })
    };
    const onPointPlanImageData: MonitoringChartVehicle['data'][0] = {
      plan: true,
      start: planPointStart,
      end: planPointEnd,
      hasLates,
      onTimePart: EventStatusType,
      dataset: createDataSet({
        id,
        waypointID: waypoint.ID,
        clientName: Depot?.Name ?? '',
        clientAddress: Depot?.ClientContact ?? '',
        planPointStart,
        FactArrive,
        wpOffset: Depot?.UTCOffset
      })
    };

    if (FactArrive && FactDepart) {
      const wayEnd = FactArrive;
      const wayStart = prevPointEndFact || wayEnd;
      const pointStart = wayEnd;
      const pointEnd = FactDepart;
      prevPointEndFact = pointEnd;

      const onWayFactImageData: MonitoringChartVehicle['data'][0] = {
        onWay: true,
        start: wayStart,
        end: wayEnd,
        onTimePart: EventStatusType,
        dataset: createDataSet({
          id,
          waypointID: waypoint.ID,
          clientName: Depot?.Name ?? '',
          clientAddress: Depot?.ClientContact ?? '',
          planPointStart,
          FactArrive,
          wpOffset: Depot?.UTCOffset
        })
      };
      const onPointFactImageData: MonitoringChartVehicle['data'][0] = {
        start: pointStart,
        end: pointEnd,
        onTimePart: EventStatusType,
        dataset: createDataSet({
          id,
          waypointID: waypoint.ID,
          clientName: Depot?.Name ?? '',
          clientAddress: Depot?.ClientContact ?? '',
          planPointStart,
          FactArrive,
          wpOffset: Depot?.UTCOffset
        })
      };
      if (isActiveFact) {
        schema.push(onWayFactImageData);
        schema.push(onPointFactImageData);
      }
    }
    if (isActivePlan) {
      schema.push(onWayPlanImageData);
      schema.push(onPointPlanImageData);
    }

    return schema;
  }, []);
}
type CreateSetArg = {
  id: number;
  waypointID: number;
  clientName: string;
  clientAddress: string | null;
  planPointStart: number;
  FactArrive: number;
  wpOffset?: number | undefined | null;
};
const createDataSet = ({
  clientAddress,
  clientName,
  FactArrive: factTime,
  planPointStart: startTime,
  id: vehicleID,
  waypointID,
  wpOffset
}: CreateSetArg): ChartSchemaDataSetType => {
  return {
    vehicleID,
    waypointID,
    clientName,
    clientAddress,
    startTime: startTime ? timestampToDateTimeStringWithUTC(startTime, !isNil(wpOffset) ? wpOffset * 60 : UTC_OFFSET_DEFAULT) : '',
    factTime: factTime ? timestampToDateTimeStringWithUTC(factTime, !isNil(wpOffset) ? wpOffset * 60 : UTC_OFFSET_DEFAULT) : ''
  };
};

// Декоратор для превращения данных рейсов в данные для biz-charts
export const monitoringChartDecorator: ChartDecorator = (dataSource, dataSourceAll, showDate) => {
  if (!dataSource || !dataSourceAll) {
    return [];
  }
  const vehicleIds = Object.keys(dataSource);
  // создаётся массив графиков для ТС с планом;
  return vehicleIds.reduce<MonitoringChartVehicle[]>((charts, vehicleID) => {
    const vehicle = dataSourceAll[vehicleID];

    if (!vehicle || !vehicle.Trip) {
      return charts;
    }
    const { ID: id, Name: name, Trip } = vehicle;
    const trips = Object.values(Trip);

    const data = trips.reduce<MonitoringChartVehicle['data']>((schemaData, trip) => {
      // ТС без плана игнорируются;
      if (!trip.Waypoint || !trip.ProjectStart) {
        return schemaData;
      }
      const waypoints = Object.values(trip.Waypoint);
      // ТС с пустым планом игнорируются;
      if (!waypoints.length) {
        return schemaData;
      }
      const data = waypointsToChartSchemaData(waypoints, trip.ProjectStart, id, showDate);
      schemaData.push(...data);
      return schemaData;
    }, []);
    charts.push({ id, name, data });
    return charts;
  }, []);
};
