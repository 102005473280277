import { useEffect, useState } from 'react';
import { CardTab, TENDER_CREATING_FROM_PLANNER_MENU, TENDER_STARTED_MENU, TENDER_UPDATING_MENU } from '@modules/tender-platform/constants';
import { CurrentTender, TenderStatus } from '@modules/tender-platform/types';
import { useSearchParams } from '@common/hooks/history/use-search-params';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';
import { useTenderCreating } from '@modules/tender-platform/utils/hooks/use-tender-creating';
import { TENDER_CREATING_MENU } from '@modules/tender-platform/constants/tender-card';

export const useTenderCardMenu = ({ currentTender, isTenderEditable }: { currentTender: CurrentTender; isTenderEditable: boolean }) => {
  const { isTendersCreatingFromPlanner, isTenderCreating } = useTenderCreating();
  const tenderID = useSearchParams(TenderAction.Tender);
  const Alias = currentTender?.StatusConfig?.Alias;
  const [menu, setMenu] = useState<Array<CardTab>>(() => {
    return isTenderEditable
      ? isTendersCreatingFromPlanner
        ? TENDER_CREATING_FROM_PLANNER_MENU
        : isTenderCreating
        ? TENDER_CREATING_MENU
        : TENDER_UPDATING_MENU
      : TENDER_STARTED_MENU;
  });

  useEffect(() => {
    //TODO лучше обновлять меню ориентируясь на alias
    if (currentTender?.ID && currentTender?.TenderLot && !isTenderEditable) {
      setMenu(TENDER_STARTED_MENU);
    }
    //NOTE: Удаляет побочный эффект (появляется таблица с предложениями в новом тендере) при переключении страниц (назад / вперед) - переход из действующего тендера в tender create
    if (!currentTender?.ID || (currentTender?.ID && Alias === TenderStatus.New)) {
      setMenu(isTendersCreatingFromPlanner || currentTender?.FromPlanner ? TENDER_CREATING_FROM_PLANNER_MENU : TENDER_CREATING_MENU);
    }
  }, [Alias, tenderID]);

  return { menu, setMenu };
};
