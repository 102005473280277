import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteTripDocumentRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';

function* deleteTripDocumentWorker(
  action: PayloadAction<{
    tripID: number;
    documentID: number;
  }>
) {
  const { request, success, failure, fulfill } = deleteTripDocumentRoutine;
  const { tripID, documentID } = action.payload;
  try {
    yield put(request());
    yield call(OrdersNTripsApi.deleteTripDocument, { id: documentID });
    yield put(success({ tripID: tripID.toString(), documentID }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteTripDocumentWatcher() {
  yield takeLatest(deleteTripDocumentRoutine.trigger, deleteTripDocumentWorker);
}

