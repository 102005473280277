import { PermittedUploadFileExtensions, PermittedUploadFileType } from '@common/constants/general/path';
import { string2ArrayBuffer } from '../general';

type Params = {
  data: Blob | string;
  mimeType?: PermittedUploadFileType;
  fileName: string;
  fileExtension?: PermittedUploadFileExtensions;
};
export const downloadFileAsBlobLink = ({ data, mimeType, fileName, fileExtension }: Params) => {
  let currentData: string | Blob = data;
  if (mimeType && typeof currentData === 'string') {
    currentData = new Blob([string2ArrayBuffer(atob(currentData))], { type: mimeType });
  }
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(currentData as Blob);
  const downloadName = `${fileName || 'Файл'}`;
  link.download = fileExtension ? downloadName + fileExtension : downloadName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
