import { MarkerPlanning } from '@common/types';
import { doubleToTime } from '@common/utils';
import { ProjectDataType } from '@modules/planning/children/by-date/types';

//TODO useless
export const mapMarkerDecorator = (
  markers: MarkerPlanning[] | undefined,
  projectData: Pick<ProjectDataType, 'Worktime' | 'Order' | 'Depot'>
): MarkerPlanning[] => {
  if (!markers || !projectData) return [];
  const currentMarkers = markers.slice();
  const Worktime = { ...projectData }?.Worktime;
  const Orders = { ...projectData }?.Order;
  const Depots = { ...projectData }?.Depot;
  if (!Worktime || !Orders) return currentMarkers;

  return currentMarkers?.map((marker) => {
    let currentMarker = { ...marker };
    const orderID = currentMarker.data?.ID;
    const Target = orderID && Orders[orderID].TargetID ? Depots?.[Orders[orderID].TargetID!] : null;
    const orderWorktimeID = orderID && Orders?.[orderID]?.WorktimeID;
    const targetWorktimeID = Target?.WorktimeID;
    if (currentMarker.pointData) {
      if (orderWorktimeID && Worktime[orderWorktimeID]) {
        currentMarker.pointData = {
          ...currentMarker.pointData,
          UnloadStart: doubleToTime(Worktime[orderWorktimeID]?.UnloadStart),
          UnloadEnd: doubleToTime(Worktime[orderWorktimeID]?.UnloadEnd),
          LoadEnd: doubleToTime(Worktime[orderWorktimeID]?.LoadEnd),
          LoadStart: doubleToTime(Worktime[orderWorktimeID]?.LoadStart)
        };
      }
      if (targetWorktimeID && Worktime[targetWorktimeID]) {
        currentMarker.pointData = {
          ...currentMarker.pointData,
          TargetUnloadStart: doubleToTime(Worktime[targetWorktimeID]?.UnloadStart),
          TargetUnloadEnd: doubleToTime(Worktime[targetWorktimeID]?.UnloadEnd),
          TargetLoadEnd: doubleToTime(Worktime[targetWorktimeID]?.LoadEnd),
          TargetLoadStart: doubleToTime(Worktime[targetWorktimeID]?.LoadStart)
        };
      }
    }

    return currentMarker;
  });
};

