import { ILSRangePicker, ILSSelect, ILSSelectOption } from '@common/components';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { DataRangeOptionType, dateRangeOptions } from '../../config/date-select';
import convertPeriod from '../../utils/convertPeriod';
import { DEFAULT_PERIOD } from '@modules/analytics/tender/children/tender-analytics/constants';

type Props = {
  selectDate?: (date: [string, string]) => void;
  selectPeriod?: (date: [string, string]) => void;
};

const ILSAnalyticsBoardDateSelect: FC<Props> = ({ selectDate, selectPeriod }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<DataRangeOptionType>(DEFAULT_PERIOD);
  const [range, setRange] = useState<any>(['', '']);

  useEffect(() => {
    selectPeriod?.(convertPeriod(DEFAULT_PERIOD));
  }, []);

  useEffect(() => {
    if (selectPeriod) {
      const period = convertPeriod(selectedPeriod);

      if (period[0].length && period[1].length) {
        setRange([moment(period[0]), moment(period[1])]);
      }
    }
  }, [selectPeriod]);

  const changeHandler = (e: DataRangeOptionType) => {
    setSelectedPeriod(e);

    if (e === DataRangeOptionType.Custom) {
      return;
    }

    const period = convertPeriod(e);

    selectPeriod?.(period);
  };

  const handleDate = (date: any) => {
    if (!date) {
      setRange(['', '']);
      return;
    }

    const [from, till] = date;

    const res: [string, string] = [moment(from).format('YYYY-MM-DD'), moment(till).format('YYYY-MM-DD')];

    setRange([moment(res[0]), moment(res[1])]);
    selectDate?.(res);
  };

  return (
    <div className="analytics-board-date-select">
      <ILSSelect onChange={changeHandler} defaultValue={selectedPeriod} showSearch={false}>
        {dateRangeOptions.map((item) => (
          <ILSSelectOption key={item.type} value={item.value}>
            {item.value}
          </ILSSelectOption>
        ))}
      </ILSSelect>

      <ILSRangePicker
        className="date-select"
        onChange={handleDate}
        value={range}
        disabled={selectedPeriod !== DataRangeOptionType.Custom}
      />
    </div>
  );
};

export default ILSAnalyticsBoardDateSelect;
