import { CellType, FilterCellType, TableConfig } from '@common/types';
import { ID_COLUMN } from '@common/models/all/columns';

export const NEW_RESTRICTION_TYPE = {
  Name: 'Новая группа специфик'
};

export const RESTRICTION_TYPE_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_RESTRICTION_TYPE,
  reloading: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
      editable: true,
      active: true,
      type: CellType.Input,
      width: 250,
      ellipsis: true,

      sorting: 1,
      filtering: FilterCellType.String
    }
  ]
};
