import { CellChildren } from '@common/types/components/table/cell';
import { DateFormat, DateTimeFormat, TimeFormat } from '@common/types';
import { MomentInput } from 'moment';
import { isBoolean, isUndefined } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';
import { EMPTY_STRING } from '@common/constants';
import { isMomentInput } from '@components/data-display/table/utils/helpers/cells/is-moment-input';

export const isCellTimeStringChildrenValid = (
  timeValue: CellChildren[1],
  dateParseFormat: DateFormat | TimeFormat | DateTimeFormat,
  double: boolean
): timeValue is MomentInput => {
  if (isBoolean(timeValue)) {
    showUserAndDevError({ error: 'Дата должна быть в формате MomentInput | TimeStamp' });
    return false;
  } else if (double) {
    return true;
  } else if (timeValue !== EMPTY_STRING && !isUndefined(timeValue) && !isMomentInput(timeValue, dateParseFormat)) {
    showUserAndDevError({ error: 'Дата должна быть в формате MomentInput | TimeStamp' });
    return false;
  }
  return true;
};
