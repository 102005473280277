import { useDispatch, useSelector } from 'react-redux';
import { tripLinkSelector } from '@modules/planning/children/by-date/selectors/plan-points';
import { useEffect } from 'react';
import { vehicleMonitoringGetLinkRoutine } from '@modules/planning/children/by-date/actions';
import { ILSButton, ILSUserNotify } from '@common/components';
import { Notify, NotifyDurationInSecond, PlanStatus } from '@common/types';
import copy from 'copy-to-clipboard';
import { TripRecord } from '@modules/planning/children/by-date/types/record';

export const useVehicleMonitoringLink = (record: TripRecord) => {
  const dispatch = useDispatch();

  const tripLink = useSelector(tripLinkSelector);
  const onExecution = record.Status >= PlanStatus.OnExecution;

  useEffect(() => {
    //ссылка на мониторинг рейса
    if (onExecution) {
      dispatch(
        vehicleMonitoringGetLinkRoutine({
          tripID: record.ID,
          link: window.location.origin + '/all-user-pages/vehicleMonitoring'
        })
      );
    }
  }, []);
  const copyLinkForTripMonitoring = () => {
    if (!tripLink) {
      ILSUserNotify(Notify.Error, 'Ссылка не доступна на сервере, попробуйте снова или обратитесь в техническую поддержку');
      return;
    }
    copy(tripLink);
    ILSUserNotify(Notify.Success, 'Ссылка скопирована в буфер обмена', NotifyDurationInSecond.Three, Notify.Success);
  };
  const ButtonForCopyLink = (
    <ILSButton className={'plan-context-menu-button-link'} onClick={copyLinkForTripMonitoring} type={'link'}>
      Получить ссылку для мониторинга рейса
    </ILSButton>
  );

  return { ButtonForCopyLink };
};
