import { TableConfigSaveTrigger } from '@modules/settings/types/table-config';

export const TableConfigSettings = {
  Title: 'Управление настройками таблиц',
  Actions: [
    {
      Info: 'Сохранить текущие настройки таблиц?',
      Title: 'Сохранить текущие',
      Trigger: TableConfigSaveTrigger.Save
    },
    {
      Info: 'Восстановить настройки таблиц для текущего аккаунта?',
      Title: 'Восстановить сохраненные',
      Trigger: TableConfigSaveTrigger.Recovery
    },
    {
      Info: 'Сбросить существующие настройки таблиц?',
      Title: 'Сбросить',
      Trigger: TableConfigSaveTrigger.Reset
    }
  ]
};
