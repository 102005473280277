import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../store';
import { emulatorSaveVehiclePropsRoutine } from '../actions';

// Устанавливает свойства эмуляции для автомобилей (emulatorStatus.configs.EmulatorConfigVehicle)
export const emulatorSaveVehiclePropsReducer = createReducer(initialState, {
  [emulatorSaveVehiclePropsRoutine.REQUEST]: (state) => {
    return state;
  },
  [emulatorSaveVehiclePropsRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: any;
    }>
  ) => {
    let { data } = action.payload;

    state.emulatorStatus.configs.EmulatorConfigVehicle = data;
    return state;
  },
  [emulatorSaveVehiclePropsRoutine.FAILURE]: (state) => {
    return state;
  },
  [emulatorSaveVehiclePropsRoutine.FULFILL]: (state) => {
    return state;
  }
});
