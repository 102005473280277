import { InputType } from '@common/components/data-entry/input';
import { Field } from '@common/constants/general';
import { ILSDivider, ILSFormItem, ILSInput, ILSTypographyText } from '@common/components';
import { NotificationSettingsFormField } from '@modules/tender-platform/constants/notification-settings';
import { FC } from 'react';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';

interface IPlaceProps {
  name: string;
}

export const PlaceComponent: FC<IPlaceProps> = ({ name }) => {
  return (
    <div className="restriction-place-component">
      <ILSDivider children={<ILSTypographyText children={name} />} />
      <div className="inputs">
        <ILSFormItem
          className={'inputs-auto-complete'}
          name={
            name === 'Погрузка'
              ? [NotificationSettingsFormField.Source, NotificationSettingsFormField.Name]
              : [NotificationSettingsFormField.Target, NotificationSettingsFormField.Name]
          }
        >
          <ILSSuggestionAutoComplete placeholder="город или регион" field={Field.Address} />
        </ILSFormItem>
        <ILSFormItem
          name={
            name === 'Погрузка'
              ? [NotificationSettingsFormField.Source, NotificationSettingsFormField.Radius]
              : [NotificationSettingsFormField.Target, NotificationSettingsFormField.Radius]
          }
        >
          <ILSInput className="radius" addonAfter="Км" placeholder="радиус" min={0} step="1" type={InputType.Number} />
        </ILSFormItem>
      </div>
    </div>
  );
};
