import { Dictionary, Keys } from '@common/types';
import { DictionaryAPI } from '@common/api/common';
import { AxiosResponse } from 'axios';

export const handleCheckDeleteRows =
  (dictionary: Dictionary) =>
  (ids?: Keys | undefined): Promise<AxiosResponse<{ data: Record<string, Partial<Record<Dictionary, Keys>>> }> | undefined> => {
    const dictionaryIsInUse = [Dictionary.Driver, Dictionary.Depot, Dictionary.Cargo, Dictionary.Order];
    return dictionary && ids && dictionaryIsInUse.includes(dictionary)
      ? (DictionaryAPI.isInUse({
          dictionary,
          ids
        }) as Promise<AxiosResponse>)
      : new Promise((res) => {
          res(undefined);
        });
  };
