import { ILSTypographyText } from '@components/index';
import { JoinChar } from '@common/constants';
import { IILSCellNotEditableCellProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';

export const ILSCellStringMultiple = <R extends TableRecord>({ children }: IILSCellNotEditableCellProps<R>): JSX.Element => {
  const [, text = []] = children as [undefined, Array<string>];

  return <ILSTypographyText children={text?.join(JoinChar.Dash)} title={text?.join(JoinChar.Dash)} />;
};
