import { ColumnTable, Depot, DictionariesEndpoint, Dictionary, Keys, Order, TableColumnName } from '@common/types';
import { getOrderIdsFromAllIds } from '@modules/planning/children/by-date/helpers/tables/get-order-ids-from-all-ids';
import { order2PlannerTable } from '@common/models/order/decorators';
import { dictionaryPlannerRoutine, orderWithCargoUpdatingRoutine } from '@modules/planning/children/by-date/actions';
import { timeToDouble } from '@common/utils';
import { Dispatch } from 'redux';

type OrderSaveType = {
  record: ReturnType<typeof order2PlannerTable>[number];
  dataIndex: ColumnTable<Order>['dataIndex'];
  dispatch: Dispatch;
  value: any;
  allOrdersIDs: Keys;
};

type PayloadType = {
  operation: DictionariesEndpoint;
  dictionary: Dictionary;
  ids: Array<Order['ID']> | Order['ID'] | Depot['ID'];
  values: Record<string, any>;
};

export const saveOrderByDataIndex = ({ record, dataIndex, dispatch, value, allOrdersIDs }: OrderSaveType) => {
  const payload: PayloadType = {
    operation: DictionariesEndpoint.Update,
    dictionary: Dictionary.Order,
    ids: record.ID,
    values: { [dataIndex]: value }
  };
  payload.ids = getOrderIdsFromAllIds({ allOrdersIDs, ids: payload.ids, record });

  switch (dataIndex) {
    case TableColumnName.TargetID:
    case TableColumnName.SourceID:
      const [, depotID] = value ?? [null, null];
      payload.values = { [dataIndex]: depotID };
      dispatch(dictionaryPlannerRoutine(payload));
      break;
    case TableColumnName.LoadStart:
    case TableColumnName.UnloadStart:
    case TableColumnName.LoadEnd:
    case TableColumnName.UnloadEnd:
      if (record.WorktimeID) {
        payload.dictionary = Dictionary.Worktime;
        payload.ids = [record.WorktimeID];
        payload.values = { [dataIndex]: timeToDouble(value) };
        dispatch(dictionaryPlannerRoutine(payload));
      }
      break;
    /** В случае обновления полей, относящихся к грузу – вызываем рутину создания груза,
     *  с дальнейшим обновлением CargoID у заявки */
    case TableColumnName.Mass:
    case TableColumnName.Volume:
    case TableColumnName.Pallets:
    case TableColumnName.Boxes:
      dispatch(orderWithCargoUpdatingRoutine({ ...payload, record }));
      break;
    default:
      dispatch(dictionaryPlannerRoutine(payload));
      break;
  }
};
