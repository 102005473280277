import { ColumnOption } from '@common/types/components/table/common';
import { CascaderProps, SelectProps } from 'antd/es';
import { compareStrings, createArrayFromIndexedArray } from '@common/utils';
import { EMPTY_STRING } from '@common/constants';

export const sortOptionsByFieldNames = ({
  options,
  fieldNames
}: {
  options: ColumnOption;
  fieldNames?: SelectProps['fieldNames'] | CascaderProps<any>['fieldNames'];
}) => {
  return createArrayFromIndexedArray(options)
    .slice()
    .sort((first, second) => {
      if (!fieldNames) return 0;
      const { label } = fieldNames;
      if (!label) return 0;

      if (first[label] === second[label]) return 0;
      if (first[label] === EMPTY_STRING) return 1;
      if (second[label] === EMPTY_STRING) return -1;
      return compareStrings(
        first[label]?.toString().toUpperCase() || EMPTY_STRING,
        second[label]?.toString().toUpperCase() || EMPTY_STRING
      );
    });
};
