import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { ILSButton, ILSForm, ILSFormItem, ILSInput } from '@common/components';
import { BUTTON_TEXT } from '@common/constants';
import { ButtonTypes } from '@common/types/components';
import { PAYMENT_FORM_RULES } from '@common/feature/payment/constants/form';
import {
  PaymentFormFieldNames,
  PaymentFormInputTypes,
  PaymentFormLabels,
  PaymentFormPlaceholders,
  ICreateInvoiceFormValues,
  PaymentFormNames
} from '@common/feature/payment/types/form';
import { formatNumberInput } from '@common/feature/payment/utils/helpers/format-number-input';

interface IProps {
  form: FormInstance<ICreateInvoiceFormValues>;
  onFinish: (values: ICreateInvoiceFormValues) => void;
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
}

export const InvoiceForm: FC<IProps> = ({ form, onFinish, submitButtonProps }) => {
  return (
    <ILSForm form={form} name={PaymentFormNames.Invoice} onFinish={onFinish}>
      <ILSFormItem
        name={PaymentFormFieldNames.InvoiceSum}
        label={PaymentFormLabels.InvoiceSum}
        rules={PAYMENT_FORM_RULES[PaymentFormFieldNames.InvoiceSum]}
        getValueFromEvent={formatNumberInput}
      >
        <ILSInput placeholder={PaymentFormPlaceholders.InvoiceSum} type={PaymentFormInputTypes.Text} />
      </ILSFormItem>
      <div className="invoice-form-action-buttons">
        <ILSButton type="primary" onClick={submitButtonProps[ButtonTypes.Cancel]} ghost>
          {BUTTON_TEXT[ButtonTypes.Cancel]}
        </ILSButton>
        <ILSButton type="primary" htmlType="submit" form={PaymentFormNames.Invoice}>
          {BUTTON_TEXT[ButtonTypes.Form]}
        </ILSButton>
      </div>
    </ILSForm>
  );
};

