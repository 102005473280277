import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types/store';
import { tenderActionRoutine } from '@modules/tender-platform/actions';
import { Tender } from '@modules/tender-platform/types';

function* tenderActionWorker(action: PayloadAction<{ tenderAction: TenderAction; tender: Tender | null }>) {
  const { success, failure, fulfill } = tenderActionRoutine;
  const { tenderAction, tender } = action.payload;
  try {
    yield put(success({ tenderAction, tender }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderActionWatcher() {
  yield takeLatest(tenderActionRoutine.trigger, tenderActionWorker);
}
