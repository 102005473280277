import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { monitoringTrackRoutine } from '../actions';
import { MonitoringAPI } from '@modules/monitoring/api';

type TrackRequest = {
  vehicleID: string;
  from: string;
  till: string;
};

// Получает данные по трэку (data)
function* monitoringTrackWorker(action: PayloadAction<TrackRequest>) {
  const { request, success, failure, fulfill } = monitoringTrackRoutine;
  const { vehicleID, from, till } = action.payload;

  try {
    yield put(request({ vehicleID, from, till }));

    const { data } = yield call(MonitoringAPI.getTrack, { vehicleID, from, till });

    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringTrackWatcher() {
  yield takeLatest(monitoringTrackRoutine.trigger, monitoringTrackWorker);
}
