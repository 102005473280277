import { Double, TimeStamp } from '../general';
import { Account } from './account';
import { Contact } from './contact';
import { IEnum } from './enum';
import { Restriction } from './restriction';
import { Worktime } from './worktime';
import { Project } from './project';
import { Depot, DepotOverload } from './depot';
import { Cargo } from './cargo';
import { Waypoint } from './waypoint';
import { PlanIDs, PlanStatus, Trip, Vehicle } from '@common/types';

export type OrderModel = {
  ID: number;
  AccountID: Account['ID'];
  ProjectID: Project['ID'];
  SourceID: Depot['ID'] | null;
  TargetID: Depot['ID'] | null;
  WorktimeID: Worktime['ID'] | null;
  DocumentStatus: number | null;
  DepotOverloadID: DepotOverload['ID'] | null;
  LoadDate: TimeStamp | string | null;
  UnloadDate: TimeStamp | string | null;
  DeliveryDayLimit: DeliveryDayLimit | null;
  Changed: TimeStamp | null;
  Created: TimeStamp | null;
  OriginalID: number | null;
  OrderCode: string | null;
  Comment: string | null;
  CommentDriver: string | null;
  ExclusiveOrder: ExclusiveOrder | null;
  PlaceInTrip: PlaceInTrip | null;
  DeniedLate: DeniedLate | null;
  Name: string | null;
  OrderSeries: OrderSeries | null;
  City: string | null;
  Country: string | null;
  ForMarket: ForMarket | null;
  Queue: null;
  SourceContact: string | null;
  TargetContact: string | null;
  Organization: string | null;
  RequireTailLift: RequireTailLift | null;
  RequirePalletTruck: RequirePalletTruck | null;
  RequireLoaders: RequireLoaders | null;
  RequireUnloaders: RequireUnloaders | null;
  AdditionalService: string | null;
  AvailableFrom: Double | null;
  Agro: Agro | null;
  Info: JSON | null;
  Value1: number | null;
  Value2: number | null;
  Value3: number | null;
  Mass: number;
  Pallets: number;
  Volume: number;
  Boxes: number;
  Cost: number;
  Amount: number;
  Client?: Depot;
};

export type Order = OrderModel & {
  Cargo: Array<Cargo>;
  CargoEnum?: IEnum<Cargo['Name']>;
  OrderCargoEnum?: IEnum<Cargo['Name']>;
  OrderCargo: Array<OrderCargo>;
  ContactID?: Contact['ID'] | Contact['ID'][];
  SourceEnum?: IEnum<Depot['Name']>;
  SourceTypeID?: Depot['DepotTypeID'];
  SourceTypeEnum?: Depot['DepotTypeEnum'];
  SourceContactEnum: IEnum<Contact['Name']>;
  TargetEnum?: IEnum<Depot['Name']>;
  TargetTypeID?: Depot['DepotTypeID'];
  TargetTypeEnum?: Depot['DepotTypeEnum'];
  TargetContactEnum?: IEnum<Contact['Name']>;
  WorktimeEnum?: IEnum<Worktime['Name']>;
  Restriction: Array<Restriction>;
  RestrictionID?: Array<Restriction['ID']>;
  RestrictionEnum?: IEnum<Restriction['Name']>;
  DepotOverloadEnum?: IEnum<DepotOverload['Name']>;
  Worktime?: Worktime;
  SourceContactID?: number | string;
  TargetContactID?: number | string;
};

export type OrderResponse = Order;
export type Orders = Array<Order>;
export type OrdersIDs = Array<Order['ID']>;

export enum ExclusiveOrder {
  No,
  OrderSeparate,
  ClientSeparate
}

export enum ExclusiveOrderLabel {
  No = 'Нет',
  OrderSeparate = 'Заявка отдельно',
  ClientSeparate = 'Клиент отдельно'
}

export enum ForMarket {
  No,
  Yes
}

export type OrderAction = {
  ID: number;
  OrderID: OrderModel['ID'];
  WaypointID: Waypoint['ID'];
  ActionType: OrderActionType;
};

export type OrderActionTotal = {
  ID: number;
  Number: string;
  FullName: string;
  Cost: number;
  WaypointID: Waypoint['ID'];
  TripID: Trip['ID'];
  ActionType: OrderActionType;
  OrderID: Order['ID'];
  VehicleID: Vehicle['ID'];
  RestrictionEnum: IEnum<Restriction['Name']>;
  Comment: OrderCargo['Comment'];
  RouteColor: Vehicle['RouteColor'];
  Dimensions: string;
  Status: PlanStatus;
  Mass: Cargo['Mass'];
  Pallets: Cargo['Pallets'];
  Boxes: Cargo['Boxes'];
  Volume: Cargo['Volume'];
  Cargo: OrderCargo['Amount'];
  LoadDuration: Cargo['LoadDuration'];
  UnloadDuration: Cargo['UnloadDuration'];
};

export type OrderCargo = {
  ID: number;
  OrderID: Order['ID'];
  CargoID: Cargo['ID'];
  Amount: number | null;
  PaymentStatus: number | null;
  PayedAmount: number | null;
  BatchDate: string | null;
  Comment: string | null;
  CommentDriver: string | null;
  CargoEnum?: Record<string, string | number | null>;
};

export type OrderToProjectRequest = { planIds: PlanIDs; toProject: Project['ID'] };

export type OrderUploadFileType = {
  [k in OrderColumnFromTable]: string | number | '';
};

export enum OrderActionType {
  Unloading,
  Loading
}

export enum OrderActionTypeLabel {
  Unloading = 'Разгрузка',
  Loading = 'Погрузка'
}

export enum PlaceInTrip {
  Casual,
  Last,
  NotLast,
  First,
  NotFirst
}
export enum PlaceInTripLabel {
  Casual = 'Обычный',
  Last = 'Последний',
  NotLast = 'Не последний',
  First = 'Первый',
  NotFirst = 'Не первый'
}

export enum DeniedLate {
  No,
  Yes
}

export enum OrderSeries {
  No,
  Yes
}

export enum RequireTailLift {
  No,
  Yes
}

export enum RequirePalletTruck {
  No,
  Yes
}

export enum RequireLoaders {
  No,
  Yes
}

export enum RequireUnloaders {
  No,
  Yes
}

export enum Agro {
  No,
  Yes
} //(пока не актуально)

export enum DeliveryDayLimit {
  After_Before,
  After_InTime,
  InTime_Before,
  InTime_InTime
}

export enum DeliveryDayLimitLabel {
  After_Before = 'Доставить до',
  After_InTime = 'Доставить в',
  InTime_Before = '',
  InTime_InTime = ''
}

export enum OrderColumnFromTable {
  AvailableFrom = 'AvailableFrom',
  CargoBarcode = 'Cargo.Barcode',
  CargoBoxes = 'Cargo.Boxes',
  CargoCargoCode = 'Cargo.CargoCode',
  CargoCost = 'Cargo.Cost',
  CargoCurrency = 'Cargo.Currency',
  CargoDescription = 'Cargo.Description',
  CargoIsWeight = 'Cargo.IsWeight',
  CargoLoadDuration = 'Cargo.LoadDuration',
  CargoMass = 'Cargo.Mass',
  CargoName = 'Cargo.Name',
  CargoPallets = 'Cargo.Pallets',
  CargoPriority = 'Cargo.Priority',
  CargoUnitHeight = 'Cargo.UnitHeight',
  CargoUnitLength = 'Cargo.UnitLength',
  CargoUnitWidth = 'Cargo.UnitWidth',
  CargoUnloadDuration = 'Cargo.UnloadDuration',
  CargoVolume = 'Cargo.Volume',
  Comment = 'Comment',
  DeliveryDayLimit = 'DeliveryDayLimit',
  DeniedLate = 'DeniedLate',
  ExclusiveOrder = 'ExclusiveOrder',
  LoadDate = 'LoadDate',
  OrderCode = 'OrderCode',
  Organization = 'Organization',
  PlaceInTrip = 'PlaceInTrip',
  RequireLoaders = 'RequireLoaders',
  RequirePalletTruck = 'RequirePalletTruck',
  RequireTailLift = 'RequireTailLift',
  RequireUnloaders = 'RequireUnloaders',
  Restriction = 'Restriction',
  SourceDepotCode = 'Source.DepotCode',
  SourceContact = 'SourceContact',
  TargetDepotCode = 'Target.DepotCode',
  TargetContact = 'TargetContact',
  UnloadDate = 'UnloadDate',
  Value1 = 'Value1',
  Value2 = 'Value2',
  Value3 = 'Value3',
  WorktimeLoadEnd = 'Worktime.LoadEnd',
  WorktimeLoadStart = 'Worktime.LoadStart',
  WorktimeLunchEnd = 'Worktime.LunchEnd',
  WorktimeLunchStart = 'Worktime.LunchStart',
  WorktimeName = 'Worktime.Name',
  WorktimeUnloadEnd = 'Worktime.UnloadEnd',
  WorktimeUnloadStart = 'Worktime.UnloadStart',
  fileIndex = 'fileIndex',
  key = 'key'
}
