import { moveWaypointInTripRoutine, moveWaypointToTripRoutine } from '@modules/planning/children/by-date/actions';
import { Dispatch } from 'redux';
import { RoutesTableDragHandler } from '@modules/planning/children/by-date/helpers/tables/handlers/drag-handlers/handle-record-drag-end';
import { TableRecord } from '@common/types';
import { WaypointTotal } from '@common/types/dictionaries/waypoint';
import { MoveWaypointInTripPayload } from '@modules/planning/children/by-date/sagas/trip/move-waypoint-in-trip';

export const handleRecordDragEndDrop =
  ({
    dispatch,
    onChangeHoveredRecord,
    selectRecord
  }: RoutesTableDragHandler & {
    dispatch: Dispatch;
  }) =>
  (
    selectRecordTable: (TableRecord & Partial<WaypointTotal>) | undefined,
    targetRecordTable: (TableRecord & Partial<WaypointTotal>) | undefined
  ) => {
    if (selectRecordTable && targetRecordTable) {
      const { WaypointID, TripID } = selectRecordTable ?? {};

      const targetKeys = {
        PlanID: targetRecordTable.PlanID,
        VehicleID: targetRecordTable.VehicleID,
        TripID: targetRecordTable.TripID,
        WaypointID: targetRecordTable.WaypointID,
        dictionary: targetRecordTable.dictionary
      };
      const tripIDTo = targetKeys.TripID;
      if (WaypointID && tripIDTo && selectRecordTable?.PlanID && selectRecord.current) {
        if (tripIDTo !== TripID) {
          dispatch(
            moveWaypointToTripRoutine({
              tripID: tripIDTo,
              waypointID: WaypointID,
              planID: selectRecordTable?.PlanID
            })
          );
        } else {
          const payload: MoveWaypointInTripPayload = {
            tripID: Number(tripIDTo),
            targetWaypointID: Number(targetKeys.WaypointID),
            sourceWaypointIDs: [Number(WaypointID)]
          }
          dispatch(
            moveWaypointInTripRoutine(payload)
          );
        }
      }
      onChangeHoveredRecord();
    }
  };

