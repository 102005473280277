import { ILSButton, ILSRangePicker, ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { Moment } from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';

const ILSDateSelect: FC<{
  onSelect: (range: { from: Moment; till: Moment }) => void;
  onSave: (range: { from: Moment; till: Moment }) => void;
  onCancel: () => void;
  dates: { from: Moment; till: Moment } | null;
}> = ({ onSelect, onSave, onCancel, dates }) => {
  const [status, setStatus] = useState<'error' | ''>('');
  const [range, setRange] = useState<{ from: Moment; till: Moment } | null>();
  const [clearedCurrentData, setClearedCurrentData] = useState(false);

  useEffect(() => {
    if (dates?.from != null && dates?.till != null) {
      setRange(dates);
    }
  }, [dates]);

  const hasChanges = useMemo(() => {
    return !dates?.from.isSame(range?.from) || !dates?.till.isSame(range?.till);
  }, [dates, range]);

  const changeDateHandler = (momentDates: any, date: [string, string]) => {
    if (!momentDates) {
      setRange(null);
      setClearedCurrentData(true);
    }

    if (momentDates && momentDates[0] && momentDates[1]) {
      const period = { from: momentDates[0], till: momentDates[1] };

      setRange(period);
      onSelect(period);
      setClearedCurrentData(false);
    }
  };

  const handleSave = () => {
    if (!range || !range.from || !range.till) {
      ILSUserNotify(Notify.Warning, 'Выберите дату!', 1);
      return;
    }
    onSave(range);
  };

  const handleCancel = () => {
    if (!clearedCurrentData) {
      onCancel();
    }
    setRange(dates);
  };

  return (
    <div className="ml-4 mr-4 analytics-date-select">
      <ILSRangePicker
        className="analytics-date-select-picker"
        onChange={changeDateHandler}
        value={range ? [range.from, range.till] : null}
        status={status}
        onBlur={() => setStatus('')}
      />

      <div className="analytics-date-select-buttons">
        <ILSButton type="primary" onClick={handleSave} disabled={!hasChanges}>
          Сохранить
        </ILSButton>
        <ILSButton type="default" onClick={handleCancel} disabled={!hasChanges}>
          Отменить изменения
        </ILSButton>
      </div>
    </div>
  );
};

export default ILSDateSelect;
