import { ILSButton } from '@components/index';
import { Popover } from 'antd';
import { IILSCellColorProps } from '@common/types/components/table/cell';
import { TableRecord } from '@common/types';
import { ILSColorPicker } from '@components/data-entry/color-picker';
import { useCellColor } from '@components/data-display/table/hooks/cell/use-cell-color';
import { color2int } from '@common/decorators/color';
import { isString } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellColor = <R extends TableRecord>({ children, toggleEdit, save }: IILSCellColorProps<R>): JSX.Element => {
  const [node, stringColor] = children;

  const { handleChange, colorBtnRef, color } = useCellColor(children);

  if (!isString(stringColor)) {
    showUserAndDevError({ error: 'Цвет должен быть строкой HEX/rgb()/string' });
    return <></>;
  }

  const handleClose = () => {
    if (color !== stringColor) {
      save(color2int(color));
    }
    toggleEdit?.();
  };

  return (
    <Popover visible content={<ILSColorPicker onChange={handleChange} handleClose={handleClose} color={color} />} trigger="click">
      <div className="color-cell" onClick={toggleEdit} role="button" tabIndex={0} style={{ backgroundColor: color }} ref={colorBtnRef}>
        {node}
        <ILSButton
          className="position-relative change-cell"
          icon={<img alt="icon" className="svg-container" src={'/assets/icons/edit.svg'} />}
        />
      </div>
    </Popover>
  );
};
