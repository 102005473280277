import { NavLink } from 'react-router-dom';
import { ILSPageHeader } from '@components/index';
import ILSRegisterFormComponent from '../components/register-form';
import { fetchSelector } from '../selectors/auth';
import { FC, useEffect } from 'react';
import { registerActions, registerRequestActions, userGetRoleRoutine } from '@modules/current-user/actions';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { RegisterRequestProps } from '../types/auth';

const ILSRegisterForm: FC = () => {
  const isFetching = useAppSelector(fetchSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const token: string | null = queryParams.get('token');

  const onFinish = (values: RegisterRequestProps['values']) => {
    if (!values.accountRole?.length) {
      delete values.accountRole;
    }
    dispatch(registerRequestActions({ values }));
  };

  useEffect(() => {
    if (token) {
      dispatch(registerActions({ token }));
    } else {
      dispatch(userGetRoleRoutine());
    }
  }, [token]);

  return (
    <div className="register-form">
      <NavLink to="/login">
        <ILSPageHeader className="site-page-header back-link" onBack={() => null} subTitle="Вернуться к авторизации" />
      </NavLink>
      <ILSRegisterFormComponent isFetching={isFetching} onFinish={onFinish} />
    </div>
  );
};

export default ILSRegisterForm;
