import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeRecordRoutine,
  getTripsCountRoutine,
  getTripsRoutine,
  showFormRoutine,
  switchTripStatusRoutine
} from '@modules/orders-and-trips/actions';
import { TripStatus } from '@modules/orders-and-trips/types/trip';
import { fetchDraftTripSelector, tripsAllDataSelector } from '@modules/orders-and-trips/selectors/trip';
import { RecordType } from '@modules/orders-and-trips/types/general';
import { showFormSelector } from '@modules/orders-and-trips/selectors';
import { compareAsString } from '@common/utils';
import { Dictionary } from '@common/types';
import { useOrdersAndTripsFilter } from '@modules/orders-and-trips/hooks/use-orders-and-trips-filter';
import { useInterval } from '@common/hooks/use-interval';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { COUNT_TRIPS_UPDATE_INTERVAL } from '@modules/orders-and-trips/constants/general';

/**
 * useTripContainer - хук для контейнера рейсов
 * @returns {
 * onChange - переключатель статусов рейсов,
 * loadTrips - метод позволяющий подгружать партию рейсов по кнопке "Показать еще",
 * changeActiveTab - устанавливает активный TAB (категорию) рейсов,
 * tripStatus - активный TAB (статус/категория) рейсов,
 * onSetActiveIndex - колбэк для выделения активного рейса,
 * activeIndex - текущий активный рейс выделнный рамкой,
 * isFetching - статус загрузки ресов: загружено/не загружено
 * tripsData - рейсы статуса/категории
 * }
 */

export const useTripContainer = () => {
  const dispatch = useDispatch();
  const { tripsData, tripStatus, activeTrip, tripsCount, tripsPagination, vehicleTypes, updateTripStatus } =
    useSelector(tripsAllDataSelector);
  const isFetching = useSelector(fetchDraftTripSelector);
  const showForm = useSelector(showFormSelector);

  const onSetActiveTrip = (ID: number) => {
    if (ID !== activeTrip?.ID) {
      dispatch(activeRecordRoutine.success({ recordType: RecordType.Trip, value: { ID } }));
      if (showForm) {
        dispatch(showFormRoutine({ formType: null }));
      }
    }
  };

  const { filteredDataSource, filterID, handleChangeFilterID } = useOrdersAndTripsFilter({
    dataSource: tripsData,
    dictionary: Dictionary.Trip
  });

  const loadTrips = () => {
    if (!compareAsString(tripsPagination.total, tripsData[Dictionary.Trip]?.length)) {
      dispatch(
        getTripsRoutine({
          source: tripStatus,
          params: {
            page: Number(tripsPagination.page) + 1,
            perPage: tripsPagination.perPage
          }
        })
      );
    }
  };

  const changeActiveTab = (tripStatus: string, isUpdate = true) => {
    dispatch(switchTripStatusRoutine({ status: tripStatus as TripStatus, isUpdate }));
  };

  const getTripsCount = () => {
    dispatch(getTripsCountRoutine({ updateTripStatus: tripStatus }));
  };

  /** Получаем рейсы со статусом  New */
  useEffect(() => {
    dispatch(getTripsRoutine({ source: TripStatus.New }));
  }, []);

  useInterval(getTripsCount, COUNT_TRIPS_UPDATE_INTERVAL, EMPTY_ARRAY_DATA);

  return {
    loadTrips,
    changeActiveTab,
    tripStatus,
    tripsData: filteredDataSource,
    onSetActiveTrip,
    activeTrip,
    isFetching,
    tripsCount,
    tripsPagination,
    vehicleTypes,
    filterID,
    handleChangeFilterID,
    updateTripStatus
  };
};

