import '../styles.less';
import { addTrackForLoading } from '@common/utils/get-track/add-track-for-loading';
import { loadTrack } from '@common/utils/get-track/load-track';
import { ILSSpinCenter } from '@components/index';
import { isModuleAvailable } from '@core/index';
import { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  trackAddTrackToQueryRoutine,
  trackGetDepotsRoutine,
  trackGetItemRoutine,
  trackGetProjectRoutine,
  trackGetTrackRoutine
} from '../actions';
import {
  depotsSelector,
  fetchSelector,
  routeSelector,
  trackGetPoints,
  trackGetTrackData,
  tripSelector,
  waypointSelector
} from '../selectors';
import { Dictionary, IMapTrack } from '@common/types';
import { routeDecorator } from '@modules/track/decorators/route';
import { tripDecorator } from '@modules/track/decorators/trip';
import { waypointDecorator } from '@modules/track/decorators/waypoint';
import { TrackMapContainer } from './map';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getMapTracksPolylines } from '@modules/track/decorators/getMapTracksPolylines';

const ILSTrack: FC = () => {
  const dispatch = useDispatch();

  const isFetching = useSelector(fetchSelector);
  const trackData = useSelector(trackGetTrackData);
  const pointData = useSelector(trackGetPoints);
  const depots = useSelector(depotsSelector);
  const route = useSelector(routeSelector);
  const trip = useSelector(tripSelector);
  const waypoint = useSelector(waypointSelector);

  const getPlanningSettings: any = isModuleAvailable('Settings', 'getPlanningSettings');
  const planningSettings = 'function' === typeof getPlanningSettings ? getPlanningSettings() : {};

  const queryParams = new URLSearchParams(useHistory().location.search);
  const vehicleID = queryParams.get('vehicle');
  const dictionary = queryParams.get('dictionary');

  useEffect(() => {
    const payload = { dictionary };
    switch (dictionary) {
      case Dictionary.Plan:
        dispatch(trackGetProjectRoutine());
        break;
      case Dictionary.Route:
      case Dictionary.Trip:
      case Dictionary.Waypoint:
        dispatch(trackGetItemRoutine(payload));
        break;
      default:
        break;
    }
    dispatch(trackGetDepotsRoutine());
  }, [dictionary]);

  const { tracks } = useMemo(() => {
    if (dictionary) {
      const vehicle = vehicleID && route?.[vehicleID].Vehicle;
      switch (dictionary) {
        case Dictionary.Plan:
          return routeDecorator(route);
        case Dictionary.Route:
          return routeDecorator(route);
        case Dictionary.Trip:
          return vehicle ? tripDecorator(trip, vehicle) : { tracks: [] };
        case Dictionary.Waypoint:
          return vehicle ? waypointDecorator(waypoint, vehicle) : { tracks: [] };
        default:
          return { tracks: [] };
      }
    }
    return { tracks: [] };
  }, [isFetching, dictionary]);

  //Добавление треков на загрузку с редиса
  useEffect(() => {
    if (!isEmpty(tracks)) {
      addTrackForLoading(planningSettings, { track: tracks as IMapTrack[] }, trackData, dispatch, trackAddTrackToQueryRoutine);
    }
  }, [planningSettings, tracks]);

  //Загрузка треков
  useEffect(() => {
    if (!isFetching) {
      loadTrack(pointData, dispatch, trackGetTrackRoutine);
    }
  }, [pointData, tracks, isFetching]);

  const polyline = getMapTracksPolylines(tracks, depots, { ...planningSettings }, trackData);

  return (
    <ILSSpinCenter spinning={isFetching}>
      <div className="track-content">
        <TrackMapContainer polyline={polyline} />
      </div>
    </ILSSpinCenter>
  );
};

export default ILSTrack;
