import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { TenderAPI } from '@modules/tender-platform/api';
import { tenderGetDistanceRoutine } from '@modules/tender-platform/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { DELAY_FOR_GET_DISTANCE_QUERY } from '@modules/tender-platform/constants/tender-card';

function* tenderGetDistanceWorker(action: PayloadAction<{ source: string; target: string }>) {
  const { success, failure } = tenderGetDistanceRoutine;
  const { source, target } = action.payload;

  try {
    yield delay(DELAY_FOR_GET_DISTANCE_QUERY);
    const {
      data: { data }
    } = yield call(TenderAPI.getDistance, { source, target });
    yield put(success({ data: data, source, target }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* tenderGetDistanceWatcher() {
  yield takeLatest(tenderGetDistanceRoutine.trigger, tenderGetDistanceWorker);
}
