import { ILSButton, ILSTooltip } from '@components/index';
import { ReactSVG } from 'react-svg';
import React, { FC } from 'react';

interface Props {
  show: boolean | undefined;
  setShow: ((s: boolean) => void) | undefined;
}

const ILSRoadGraphTestDepotsButton: FC<Props> = ({ show, setShow }) => {
  return (
    <ILSTooltip placement="right" title={(show ? 'Выкл' : 'Вкл') + ' отображение точек'}>
      <ILSButton
        className={`map-control-btn map-depots-btn ${show ? 'active' : ''}`}
        onClick={() => setShow && setShow(!show)}
        icon={<ReactSVG src={'/assets/icons/road-graph/depots.svg'} />}
      />
    </ILSTooltip>
  );
};
export default ILSRoadGraphTestDepotsButton;
