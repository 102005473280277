import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningGetPlanRoutine } from '../../actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { PlanRequest } from '@modules/planning/types/api';
import { onFailure } from '@common/utils';
import { PlanResponse } from '@common/types/dictionaries/plan';

export type GetPlanPayloadAction = PayloadAction<{
  data: PlanResponse;
}>;

export const getPlanReducer = createReducer(initialState, {
  [planningGetPlanRoutine.FAILURE]: onFailure,
  [planningGetPlanRoutine.REQUEST]: (state, { payload: { planID } }: PayloadAction<PlanRequest>) => {
    state.activePlanID = planID;
    state.info.plans[planID] = { isFetching: true, lastUpdate: null };
    state.initial = false;
    return state;
  },
  [planningGetPlanRoutine.FULFILL]: (state, { payload: { planID } }: PayloadAction<PlanRequest>) => {
    state.info.plans[planID].isFetching = false;
    state.initial = false;
    return state;
  },
  [planningGetPlanRoutine.SUCCESS]: (state, action: GetPlanPayloadAction) => {
    const { data } = action.payload;
    if (!state.project) return state;
    state.initial = true;
    state.currentPlan = data;
    state.info.plans[data.ID].lastUpdate = Date.now();
    return state;
  }
});
