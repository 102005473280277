import { createReducer } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-own/store';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { createTenderForRouteRoutine } from '@modules/tender-platform/actions';

export const createTenderForRouteReducer = createReducer(initialState, {
  [createTenderForRouteRoutine.REQUEST]: onRequest,
  [createTenderForRouteRoutine.FAILURE]: onFailure,
  [createTenderForRouteRoutine.FULFILL]: onFulfill,
  [createTenderForRouteRoutine.SUCCESS]: (state) => {
    state.dataForGroupTenderCreation = {};
    state.initialDataForGroupTenderCreation = {};
    return state;
  }
});
