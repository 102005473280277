import { createReducer } from '@reduxjs/toolkit';
import { MonitoringEventDetailsStore } from '@modules/monitoring/children/event-details/types/store';
import { initialState } from '@modules/monitoring/children/event-details/store';
import { setTableMapSizesRoutine } from '@modules/monitoring/children/event-details/actions';
import { TableMapSizesPayload } from '@modules/monitoring/children/event-details/types/resizer';

export const setTableMapSizesReducer = createReducer<MonitoringEventDetailsStore>(initialState as MonitoringEventDetailsStore, {
  [setTableMapSizesRoutine.SUCCESS]: (state, action: TableMapSizesPayload) => {
    const { tablesSplitSizes, tablesMapSplitSizes } = action.payload;
    if (tablesMapSplitSizes) {
      state.resizer.tablesMapSplitSizes = tablesMapSplitSizes;
    }
    if (tablesSplitSizes) {
      state.resizer.tablesSplitSizes = tablesSplitSizes;
    }
    return state;
  }
});
