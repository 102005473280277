import { GetListRequest, RequestParamsFilter } from '@modules/demand/types/api';
import { ChangeEvent, FC, useState } from 'react';
import { ILSDemandInputComponent } from '../components/header/demand-input';

interface IProps {
  filter: GetListRequest['filter'];
  onChangeFilter: (f: Partial<RequestParamsFilter>) => void;
  placeholder: string;
  filterField: string;
}

// Контейнер, который отвечает за инпут элементы
export const ILSDemandInputContainer: FC<IProps> = ({ filter, onChangeFilter, placeholder, filterField }) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onChangeFilter({ ...filter, [filterField]: value });
  };

  return (
    <ILSDemandInputComponent
      className="filter-input"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onPressEnter={handleSubmit}
      onBlur={handleSubmit}
    />
  );
};
