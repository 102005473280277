import { ILSAlert, ILSButton, ILSSpace } from '@common/components';
import { Notify } from '@common/types';
import { settingsSaveRoutine } from '@modules/settings/actions';
import { NEW_SETTINGS_NOTIFICATION } from '@modules/settings/config/notification/config';
import { diffSettingsSelector } from '@modules/settings/selectors/general';
import { useDispatch, useSelector } from 'react-redux';

export const InfoContainer = () => {
  const diffSettings = useSelector(diffSettingsSelector);
  const dispatch = useDispatch();

  const acceptNewSettings = () => {
    dispatch(settingsSaveRoutine({ value: diffSettings, rehydrate: true }));
  };

  if (!diffSettings) return null;
  return (
    <ILSAlert
      message={NEW_SETTINGS_NOTIFICATION.Message}
      description={NEW_SETTINGS_NOTIFICATION.Description}
      type={Notify.Warning}
      showIcon
      action={
        <ILSSpace direction={'vertical'}>
          <ILSButton type="primary" onClick={acceptNewSettings}>
            {NEW_SETTINGS_NOTIFICATION.ButtonTitle}
          </ILSButton>
        </ILSSpace>
      }
    />
  );
};
