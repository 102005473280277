import { getSRCIconSVG } from '@common/decorators/path';
import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import Dashboard from './containers';

export default defineModule({
  title: ModuleTitle.Dashboard,
  path: ModulePath.Dashboard,
  exact: true,
  component: Dashboard,
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG('/dashboard', { iconModule: '/sidebar' })
});
