import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { deleteTripRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';

export const deleteTripReducer = createReducer(initialState, {
  [deleteTripRoutine.REQUEST]: (state: InitialState) => {
    state.trips.isFetching = true;
    return state;
  },
  [deleteTripRoutine.SUCCESS]: (state: InitialState, action: PayloadAction<{}>) => {
    return state;
  },
  [deleteTripRoutine.FAILURE]: onFailure,
  [deleteTripRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.trips.isFetching = false;
    return state;
  }
});
