import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getAvailableIntegrationsRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { Integrations } from '@modules/orders-and-trips/types/integrations';
import { integrationServicesToArray } from '@modules/orders-and-trips/decorators/integration-services-to-array';

export const getAvailableIntegrationsReducer = createReducer(initialState, {
  [getAvailableIntegrationsRoutine.REQUEST]: (state: InitialState) => {
    state.availableIntegrations.isFetching = true;
    return state;
  },
  [getAvailableIntegrationsRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: Integrations;
    }>
  ) => {
    const { data } = action.payload;
    state.availableIntegrations.data = integrationServicesToArray(data);
    return state;
  },
  [getAvailableIntegrationsRoutine.FAILURE]: onFailure,
  [getAvailableIntegrationsRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.availableIntegrations.isFetching = false;
    return state;
  }
});
