import { RightTabsContainer } from '@modules/orders-and-trips/containers/right/right-tabs-container';
import { useSelector } from 'react-redux';
import { showFormSelector } from '@modules/orders-and-trips/selectors';
import { ILSCustomScrollCss } from '@common/components/custom/scroll/custom-scroll';
import { OrderNTripFormContainer } from '@modules/orders-and-trips/containers/right/forms/order-and-trip-form-container';

export const RightBlock = () => {
  const showForm = useSelector(showFormSelector);
  return (
    <ILSCustomScrollCss scrollX={false} className="orders-and-trips-right">
      <RightTabsContainer showForm={showForm} />
      <OrderNTripFormContainer showForm={showForm} />
    </ILSCustomScrollCss>
  );
};

