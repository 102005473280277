import { PaymentBalanceHistoryModified } from '@common/feature/payment/types/general';
import { PaymentBalanceHistoryHeader } from '@common/feature/payment/components/payment-balance-history/header/payment-balance-history-header';
import { PaymentBalanceHistoryRows } from '@common/feature/payment/components/payment-balance-history/rows/payment-balance-history-rows';

export const PaymentBalanceHistory = ({ balanceHistory }: { balanceHistory: PaymentBalanceHistoryModified[] }) => {
  return (
    <div className="payment-balance-history">
      <PaymentBalanceHistoryHeader />
      <PaymentBalanceHistoryRows balanceHistory={balanceHistory} />
    </div>
  );
};

