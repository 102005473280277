import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { getFakePreCostRoutine } from '@modules/orders-and-trips/actions';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { isArray } from 'lodash';
import { PayloadActionError } from '@common/types';
import { SplitChar } from '@common/constants';
import { FakePreCostRequest, FakePreCostResponse } from '@modules/orders-and-trips/types/fake-pre-cost';

export const getFakePreCostReducer = createReducer(initialState, {
  [getFakePreCostRoutine.REQUEST]: (state: InitialState) => {
    state.availableIntegrations.isFetching = true;
    return state;
  },
  [getFakePreCostRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: FakePreCostResponse;
      values: FakePreCostRequest;
    }>
  ) => {
    const { data, values } = action.payload;
    state.availableIntegrations.fakePreCost = data;
    state.availableIntegrations.fakePreCostValues = values;
    return state;
  },
  [getFakePreCostRoutine.FAILURE]: (state: InitialState, action: PayloadActionError & PayloadAction<{ values: FakePreCostRequest }>) => {
    const { error } = action.payload;
    state.error = !isArray(error) ? [error] : [error.join(SplitChar.Semicolon)];
    return state;
  },
  [getFakePreCostRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.availableIntegrations.isFetching = false;
    return state;
  }
});
