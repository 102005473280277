import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsBoardModel, BoardDefaults } from '@common/types/dictionaries/analytics';
import { IndexedArray } from '@common/types';

import { AnalyticsActions } from '../types/actions';
import { IAnalyticsCommonAPI } from '../types/api';
import { sortListBySerial } from '../utils/sort-boards';

export const getBoardsSuccessCommonWatcher = (
  api: IAnalyticsCommonAPI,
  actions: AnalyticsActions
): (() => Generator<ForkEffect<never>, void>) => {
  function* worker(action: PayloadAction<{ data: IndexedArray<AnalyticsBoardModel> } & BoardDefaults>) {
    const { success, failure, fulfill } = actions.analyticsCommonSelectBoardRoutine;
    const { payload } = action;
    try {
      const boardIdToSelect = payload.defaults?.boardId || sortListBySerial(payload.data)[0]?.ID;
      yield put(success({ ID: boardIdToSelect, defaults: { ...action.payload.defaults } }));
    } catch (error) {
      console.error(error);
      yield put(failure(error));
    } finally {
      yield put(fulfill());
    }
  }

  function* watcher() {
    yield takeLatest(actions.analyticsCommonGetBoardsRoutine.SUCCESS, worker);
  }

  return watcher;
};
