import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { emulatorGetConfigRoutine, emulatorSaveVehiclePropsRoutine } from '../actions';
import { EmulatorAPI } from '../api';

// Сохраняет конфигурацию эмуляции и свойства для автомобилей из таблицы настроек
function* emulatorSaveVehiclePropsWorker(
  action: PayloadAction<{
    planID: number;
    vehicleIDs?: number[] | string[];
    values?: { [K: string]: any };
    createConfig?: boolean;
  }>
) {
  const { request, success, failure } = emulatorSaveVehiclePropsRoutine;
  const { vehicleIDs, values, planID, createConfig } = action.payload;

  try {
    yield put(request({}));
    yield delay(100);

    if (createConfig) {
      const emulationSpeed = 0;
      yield call(EmulatorAPI.createConfig, { planID, emulationSpeed });
      yield put(emulatorGetConfigRoutine({ planID }));
    }

    yield delay(100);
    const { data } = yield call(EmulatorAPI.saveConfigVehicle, {
      planID,
      values,
      vehicleIDs
    });

    yield put(success({ data: data.data }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* emulatorSaveVehiclePropsWatcher() {
  yield takeLatest(emulatorSaveVehiclePropsRoutine.trigger, emulatorSaveVehiclePropsWorker);
}
