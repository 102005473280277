import { FC, useCallback } from 'react';
import ILSEmulatorDate from './date';
import '../../styles.less';
import { emulationConfigsSelector, planSelector, statusSelector } from '../selectors';
import { MainTableContainer } from './main-table';
import { ControlsContainer } from './controls';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { emulatorCheckStatusRoutine, emulatorCreateConfigRoutine, emulatorGetConfigRoutine, emulatorGetPlanRoutine } from '../actions';
import { EMULATOR_UPDATE_CONFIG_INTERVAL, EMULATOR_UPDATE_STATUS_INTERVAL, EmulatorStatus } from '../constants';
import { useInterval } from '@common/hooks/use-interval';

// Основной контейнер для модуля, "точка входа"
export const ILSEmulatorContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { loadedPlanID } = useAppSelector(planSelector);
  const { lastUpdate, isFetching: isFetchingConfig, PlanID, Status } = useAppSelector(emulationConfigsSelector);
  const status = useAppSelector(statusSelector);

  //получение данных проекта
  const getPlans = (planID?: string | number) => {
    dispatch(
      emulatorGetPlanRoutine({
        planID
      })
    );
  };
  /**
   * Создать конфигурацию для эмуляции загруженного плана
   * emulationSpeed - скорость эмуляции (работает только - 1)
   */
  const createConfig = useCallback(
    (emulationSpeed?: number) => {
      if (loadedPlanID && emulationSpeed) {
        dispatch(emulatorCreateConfigRoutine({ planID: loadedPlanID, emulationSpeed }));
      }
    },
    [loadedPlanID]
  );

  /**
   * Проверяет статус запущенной эмуляции по плану
   */
  const checkStatus = () => {
    if (loadedPlanID && Status !== EmulatorStatus.Finished && status !== EmulatorStatus.Finished && PlanID) {
      dispatch(emulatorCheckStatusRoutine({ planID: loadedPlanID }));
    }
  };
  useInterval(checkStatus, EMULATOR_UPDATE_STATUS_INTERVAL, [loadedPlanID, PlanID, Status]);

  /**
   * Загружает конфигурацию эмуляции по загруженному плану
   */
  const loadConfig = () => {
    if (
      loadedPlanID &&
      ((!isFetchingConfig && (!lastUpdate || lastUpdate + EMULATOR_UPDATE_CONFIG_INTERVAL > new Date().getTime())) ||
        !PlanID ||
        PlanID !== loadedPlanID)
    ) {
      dispatch(emulatorGetConfigRoutine({ planID: loadedPlanID }));
    }
  };
  useInterval(loadConfig, EMULATOR_UPDATE_CONFIG_INTERVAL, [loadedPlanID]);

  return (
    <div className="ils-emulator-wrapper">
      <ILSEmulatorDate getPlans={getPlans} />
      <div className="ils-emulator-aside">
        <MainTableContainer />
        <ControlsContainer createConfig={createConfig} />
      </div>
    </div>
  );
};
