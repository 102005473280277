import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { uploadTripDocumentsRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { TripDocument } from '@modules/orders-and-trips/types/trip';

export const uploadTripDocumentsReducer = createReducer(initialState, {
  [uploadTripDocumentsRoutine.REQUEST]: (state: InitialState) => {
    state.tripDocuments.isFetching = true;
    return state;
  },
  [uploadTripDocumentsRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: Array<TripDocument>;
      tripID: string;
    }>
  ) => {
    const { data, tripID } = action.payload;
    const currentTripDocuments = state.tripDocuments?.documents?.[tripID];
    state.tripDocuments.documents = { ...state.tripDocuments.documents, [tripID]: [...currentTripDocuments, ...data] };
    return state;
  },
  [uploadTripDocumentsRoutine.FAILURE]: onFailure,
  [uploadTripDocumentsRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripDocuments.isFetching = false;
    return state;
  }
});

