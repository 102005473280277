import { TenderButtonText } from '@modules/tender-platform/constants/content';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { currentTenderSelector } from '@modules/tender-platform/selectors';
import { TenderType } from '@modules/tender-platform/types';
import { TenderForm } from '@modules/tender-platform/types/platform';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';

interface IProps {
  isModal?: boolean;

  handleShow?(): void;
}

export const ILSTenderOfferSubmitButton: FC<IProps> = ({ isModal, handleShow }) => {
  const { Type } = useSelector(currentTenderSelector);
  const handleClick = () => handleShow?.();

  return (
    <TenderPlatformMenuButton
      form={Type === TenderType.Auction ? TenderForm.Auction : TenderForm.Offer}
      htmlType={'submit'}
      onClick={isModal ? handleClick : undefined}
      children={isModal ? TenderButtonText.Accept : TenderButtonText.ChangeOffer}
    />
  );
};
