import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../../store';
import { deleteCarriersRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwnStore } from '@modules/tender-platform/children/tender-own/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const deleteCarrierReducer = createReducer(initialState, {
  [deleteCarriersRoutine.REQUEST]: onRequest,
  [deleteCarriersRoutine.FAILURE]: onFailure,
  [deleteCarriersRoutine.FULFILL]: onFulfill,
  [deleteCarriersRoutine.SUCCESS]: (
    state: TenderOwnStore,
    action: PayloadAction<{
      ids: Array<number>;
    }>
  ) => {
    const { ids } = action.payload;

    const carriers = state.carriers.myCarriers;
    ids.forEach((id) => delete carriers[id]);

    state.carriers.myCarriers = carriers;
    return state;
  }
});
