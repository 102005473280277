import { deepEqual } from 'fast-equals';
import { Dictionary } from '@common/types';
import { IRecord, SaveDictionaryType } from '@modules/catalog/types/catalog';
import { ForbiddenZonesUpdateRequest } from '@modules/catalog/types/api';
import { CatalogUpdate } from '@modules/catalog/types/actions';

export const createCatalogPayload = (dictionary: Dictionary, record: IRecord, values: any, saveDictionary?: SaveDictionaryType) => {
  const recordIncludeValues = Object.keys(values).every((key) => {
    return typeof record[key] === 'object' ? deepEqual(record[key], values[key]) : record[key] === values[key];
  });
  if (recordIncludeValues) return;
  const payload: CatalogUpdate = { dictionary, values, ids: [record.key || record.ID], saveDictionary };
  // Проверка для времени работы в 1 неделю
  if (typeof payload.values.WorkPerWeek === 'number') {
    // увеличение времени работы за две недели в 2 раза от времени работы в 1 неделю
    payload.values.WorkPerTwoWeek = payload.values.WorkPerWeek * 2;
  }
  // Проверка для времени работы в 2 недели
  if (typeof payload.values.WorkPerTwoWeek === 'number') {
    // уменьшение времени работы за 1 неделю в 2 раза от времени работы за 2 недели
    payload.values.WorkPerWeek = payload.values.WorkPerTwoWeek / 2;
  }

  // Замена в payload - ForbiddenZoneID -> ForbiddenZones в таблице "Парк Машин", колонка "Запрет"
  if (dictionary === Dictionary.Vehicle) {
    if (ForbiddenZonesUpdateRequest.Source in payload.values) {
      payload.values[ForbiddenZonesUpdateRequest.Target] = payload.values[ForbiddenZonesUpdateRequest.Source];
      delete payload.values[ForbiddenZonesUpdateRequest.Source];
    }
  }

  return payload;
};
