import { Dictionary } from '@common/types';
import { temporaryLotDistanceSelector, tenderTemplateDataSelector } from '@modules/tender-platform/selectors';
import { tenderFormSelector } from '@modules/tender-platform/selectors/components/tender-form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tenderLotCurrent2Form } from '@modules/tender-platform/utils/decorators/tender-data-to-form';
import { lotToForm } from '@modules/tender-platform/utils/decorators/lot-to-form';
import { TenderLotFields } from '@modules/tender-platform/types';
import { useForm } from 'antd/lib/form/Form';

export const useTenderForm = () => {
  const { initialValues, currentTender, fromPlanner } = useSelector(tenderFormSelector);
  const [form] = useForm();
  const tenderTemplateData = useSelector(tenderTemplateDataSelector);
  const temporaryLotDistance = useSelector(temporaryLotDistanceSelector);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
      form.setFieldsValue({
        ...initialValues
      });
    }
  }, [initialValues]);

  useEffect(() => {
    if (tenderTemplateData) {
      let templateInitialValues;
      if (Dictionary.TenderLot in tenderTemplateData) {
        templateInitialValues = tenderLotCurrent2Form(tenderTemplateData, tenderTemplateData.TenderLot[0]);
      } else {
        templateInitialValues = lotToForm(tenderTemplateData);
      }
      form.resetFields(Object.keys(templateInitialValues));
      form.setFieldsValue({ ...templateInitialValues });
    }
  }, [tenderTemplateData]);

  useEffect(() => {
    if (temporaryLotDistance) {
      form.resetFields(Object.keys(TenderLotFields.Distance));
      form.setFieldsValue({ Distance: temporaryLotDistance });
    }
  }, [temporaryLotDistance]);

  return {
    currentTender,
    fromPlanner,
    form,
    initialValues
  };
};
