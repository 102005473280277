import { call, put, takeEvery } from 'redux-saga/effects';
import { tenderLotUpdateRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { TenderOwn } from '@modules/tender-platform/children/tender-own/api';
import { tenderChangeRoutine } from '@modules/tender-platform/actions';
import { TenderLotUpdateAction } from '@modules/tender-platform/types';
import { DictionariesEndpoint } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

function* tenderLotUpdateWorker(action: TenderLotUpdateAction) {
  const { request, failure, fulfill } = tenderLotUpdateRoutine;
  const { id, values, tmpLotData } = action.payload;

  try {
    yield put(request());
    const {
      data: {
        data: { tenderLot, errors }
      }
    } = yield call(TenderOwn.updateLot, { id, values });
    if (errors) {
      new Error(`${Object.values(errors).join(', ')}`);
    }
    yield put(
      tenderChangeRoutine.trigger({
        TenderLot: createArrayFromIndexedArray(tenderLot),
        operation: DictionariesEndpoint.Update
      })
    );
  } catch (error) {
    if (tmpLotData) {
      yield put(tenderChangeRoutine.trigger({ TenderLot: [tmpLotData], operation: DictionariesEndpoint.tenderTemplateData }));
    }
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* tenderLotUpdateWatcher() {
  yield takeEvery(tenderLotUpdateRoutine.trigger, tenderLotUpdateWorker);
}

