import { IndexedArray } from '@common/types';
import { AnalyticsWidget, GetBoardWidgetRequest, GetBoardWidgetResponse } from '@common/types/dictionaries/analytics';
import { createModuleAction } from '@common/utils/general/create-module-action';
import { createRoutine } from 'redux-saga-routines';
import { AnalyticsActions } from '../types/actions';

export enum AnalyticsActionsTypes {
  SELECT_DATE = 'SELECT_DATE',
  SELECT_BOARD_DATE_RANGE = 'SELECT_BOARD_DATE_RANGE',
  GET_WIDGET_DATA = 'GET_WIDGET_DATA',
  GET_BOARD_WIDGET = 'GET_BOARD_WIDGET',
  GET_BOARDS = 'GET_BOARDS',
  GET_BOARDS_SETS = 'GET_BOARDS_SETS',
  UPDATE_WIDGET = ' UPDATE_WIDGET',
  SELECT_BOARD_SET = 'SELECT_BOARD_SET',
  SELECT_BOARD = 'SELECT_BOARD',
  SET_PARAMS = 'SET_PARAMS',
  CANCEL_WIDGETS_UPDATE = 'CANCEL_WIDGETS_UPDATE',
  BATCH = 'BATCH'
}

export const getCommonAnalyticsActions = (moduleName: string): AnalyticsActions => {
  const createAnalyticsRoutine = (name: AnalyticsActionsTypes) => createRoutine(createModuleAction(moduleName, name));

  return {
    analyticsDateRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.SELECT_DATE),
    analyticsGetWidgetDataRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.GET_WIDGET_DATA),
    analyticsCommonGetBoardsRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.GET_BOARDS),
    analyticsCommonGetBoardSetsRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.GET_BOARDS_SETS),
    analyticsCommonSelectBoardSetRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.SELECT_BOARD_SET),
    analyticsCommonSelectBoardRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.SELECT_BOARD),
    analyticsCommonSetWidgetParamsRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.SET_PARAMS),
    analyticsCommonSetBoardDateRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.SELECT_BOARD_DATE_RANGE),
    analyticsCommonCancelWidgetUpdatesRoutine: createAnalyticsRoutine(AnalyticsActionsTypes.CANCEL_WIDGETS_UPDATE),
    analyticsGetBoardWidgetRoutine: createRoutine(createModuleAction(moduleName, AnalyticsActionsTypes.GET_BOARD_WIDGET), {
      trigger: (payload: GetBoardWidgetRequest) => ({ ...payload }),
      failure: (reason: any) => reason,
      fulfill: () => {},
      success: (payload: GetBoardWidgetResponse) => ({ ...payload })
    }),

    analyticsUpdateWidgetRoutine: createRoutine(createModuleAction(moduleName, AnalyticsActionsTypes.UPDATE_WIDGET), {
      trigger: (payload: IndexedArray<AnalyticsWidget>) => ({ ...payload }),
      failure: (reason: any) => reason,
      fulfill: () => {},
      success: (payload: IndexedArray<AnalyticsWidget>) => ({ ...payload })
    }),
    batchRoutine: createRoutine(createModuleAction(moduleName, AnalyticsActionsTypes.BATCH), {
      trigger: (payload: any) => ({ ...payload }),
      failure: (reason: any) => reason,
      fulfill: () => {},
      success: (payload: any) => ({ ...payload })
    })
  };
};
