import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { switchTripStatusRoutine } from '@modules/orders-and-trips/actions';
import { onFailure } from '@common/utils/helpers/reducers';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { TripStatus } from '@modules/orders-and-trips/types/trip';

export const switchTripStatusReducer = createReducer(initialState, {
  [switchTripStatusRoutine.REQUEST]: (state: InitialState) => {
    return state;
  },
  [switchTripStatusRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      status?: TripStatus;
      isUpdate?: boolean | undefined;
    }>
  ) => {
    const { status, isUpdate } = action.payload;
    if (status) {
      state.trips.tripStatus = status;
    }
    if (isUpdate) {
      state.trips.updateTrips = null;
    }

    return state;
  },
  [switchTripStatusRoutine.FAILURE]: onFailure,
  [switchTripStatusRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    return state;
  }
});

