import { EMPTY_ARRAY_DATA } from '@common/constants';
import { Dictionary, ErrorAPI, Keys, Project, ShowDeleted } from '@common/types';
import { catalogReadRoutine } from '@modules/catalog/actions';
import initialState from '@modules/catalog/store';
import { DataType, ICatalogDataItem, LastUpdate } from '@modules/catalog/types/catalog';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { isEmpty } from 'lodash';

const readDictionaryReducer = createReducer(initialState, {
  [catalogReadRoutine.REQUEST]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      ids: Keys;
      dictionaryFetching: boolean;
      showDeleted: ShowDeleted;
    }>
  ) => {
    const { dictionary, ids, dictionaryFetching, showDeleted } = action.payload;
    state[dictionary].isFetching = !(ids && ids.length);
    state[dictionary].refresh = false;
    if (dictionaryFetching) {
      state.info[dictionary].isFetching = true;
    }
    state[dictionary].showDeleted = showDeleted;
    return state;
  },
  [catalogReadRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: DataType;
      dictionary: Dictionary;
      ids: Keys;
      lastUpdate: LastUpdate;
      projectID: Project['ID'] | null;
    }>
  ) => {
    const { data = EMPTY_ARRAY_DATA, dictionary, ids, lastUpdate, projectID } = action.payload;
    if (isEmpty(ids)) {
      state[dictionary].data = data;
    } else {
      for (let i = 0; i < ids.length; i++) {
        const newRow = data.filter((item: ICatalogDataItem) => item.ID === ids[i] && item);
        state[dictionary].data = state[dictionary].data.map((item: ICatalogDataItem) => {
          if (item.ID === ids[i]) return newRow[0];
          return item;
        });
      }
    }
    state[dictionary].projectID = projectID;
    state.info[dictionary].lastUpdate = lastUpdate;
    return state;
  },
  [catalogReadRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
      lastUpdate: LastUpdate;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    state.info[dictionary].lastUpdate = undefined;
    return state;
  },
  [catalogReadRoutine.FULFILL]: (
    state: CatalogStateType,
    action: PayloadAction<{
      dictionary: Dictionary;
      dictionaryFetching?: boolean;
    }>
  ) => {
    const { dictionary, dictionaryFetching } = action.payload;
    state[dictionary].error = [];
    state[dictionary].isFetching = false;
    if (dictionaryFetching) {
      state.info[dictionary].isFetching = false;
    }
    return state;
  }
});

export default readDictionaryReducer;
