import { ILSCollapse, ILSCollapsePanel } from '@components/index';
import { ChangeSettings } from '@modules/settings/types/container';
import { SingleSettingsParams } from '@modules/settings/types/store';
import { ILSSettingsItemComponent } from './items/index';
import { FC } from 'react';
import { ExpandIconPosition } from '@components/data-display/collapse/types';
import { isEmpty } from 'lodash';

interface IProps {
  category: string;
  onSettingsChange(obj: ChangeSettings): void;
  sections: {
    [k: string]: {
      name: string;
      data: {
        [k: string]: SingleSettingsParams;
      };
    };
  };
}

export const ILSSettingBlockComponent: FC<IProps> = ({ sections, ...props }) => {
  return (
    <div className="position-relative mr-2 pr-5">
      <ILSCollapse
        bordered={false}
        accordion
        expandIconPosition={ExpandIconPosition.Right}
        defaultActiveKey={Object.keys(sections ?? {})?.[0]}
      >
        {Object.entries(sections ?? {}).map(([sectionKey, sectionSettings]) => {
          if (isEmpty(sectionSettings?.data)) return null;
          return (
            sectionSettings && (
              <ILSCollapsePanel key={sectionKey} header={sectionSettings.name}>
                {Object.entries(sectionSettings?.data)?.map(
                  ([parameter, singleSettingsParams]) =>
                    singleSettingsParams && (
                      <ILSSettingsItemComponent
                        {...props}
                        parameter={parameter}
                        section={sectionKey}
                        key={parameter}
                        singleSettingsParams={singleSettingsParams}
                      />
                    )
                )}
              </ILSCollapsePanel>
            )
          );
        })}
      </ILSCollapse>
    </div>
  );
};

