import { FC, MutableRefObject } from 'react';
import { TenderOwnFormTableFieldButtonColumn } from '@modules/tender-platform/children/tender-own/components/create-from-planner/field-button-column';
import { TenderOwnFormTableFieldsetRow } from '@modules/tender-platform/children/tender-own/containers/create-from-planner/fieldset-row';
import { TENDER_FORM_TABLE_FIELDS } from '@modules/tender-platform/constants/content';
import { RoutesID, RoutesIDs } from '@modules/tender-platform/children/tender-own/types/route-tenders';
import { UploadFile } from 'antd/lib/upload/interface';

interface IProps {
  routes: RoutesIDs;
  onSetDataFiles: (routeId: RoutesID, file: UploadFile[]) => void;
  dataFiles: MutableRefObject<{ [k: string | number]: UploadFile[] }>;
}

export const TenderOwnFormTableBody: FC<IProps> = ({ routes, onSetDataFiles, dataFiles }) => {
  return (
    <div className={'tender-from-planner-form-table-body'}>
      {TENDER_FORM_TABLE_FIELDS.map((field, i) => (
        <div key={field + routes[i]} className={`tender-from-planner-form-table-row ${field}`}>
          <TenderOwnFormTableFieldButtonColumn field={field} />
          <TenderOwnFormTableFieldsetRow dataFiles={dataFiles} onSetDataFiles={onSetDataFiles} field={field} routes={routes} />
        </div>
      ))}
    </div>
  );
};
