import { Tender, TenderType, TenderVisibilityIcon, TenderVisibilityStatus } from '@modules/tender-platform/types';
import { IndexedArray } from '@common/types';
import { TenderIconTitle, TenderStatus, TenderTypeLabel } from '../../types/tender';
import { DASH, EMPTY_OBJECT_DATA, SECONDS_TO_MILLISECONDS_MULTIPLIER } from '@common/constants/general';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';

export const tender2Table = (tenders?: IndexedArray<Tender> | Array<Tender> | null) => {
  return createArrayFromIndexedArray(tenders)
    .map((tender) => {
      const open = tender.Private === TenderVisibilityStatus.Open;
      const deadline = tender.OfferEndDT * SECONDS_TO_MILLISECONDS_MULTIPLIER;
      const VehicleType = tender.TenderSummary?.VehicleType ?? EMPTY_OBJECT_DATA;
      const tenderVehicles = (Array.isArray(VehicleType) ? VehicleType.map(({ Name }) => Name) : Object.values(VehicleType)).reduce(
        (prev: string, Name) => {
          return Name ? prev.concat(`${Name}\n`) : prev;
        },
        ''
      );

      return {
        ...tender,
        key: tender.ID,
        BasePrice: tender.BasePrice ?? DASH,
        CurrentPrice: tender.CurrentPrice > 0 ? tender.CurrentPrice : DASH,
        CargoOwner: tender.Account?.Name,
        Type: TenderTypeLabel[TenderType[tender.Type]],
        Private: open ? TenderVisibilityIcon.Open : TenderVisibilityIcon.Close,
        UserID: tender.User?.ID,
        UserEnum: tender.User && {
          [tender.User?.ID]: tender.User?.Name
        },
        Mass: tender.TenderSummary?.Mass ?? DASH,
        VehicleType: Boolean(tenderVehicles) ? tenderVehicles : DASH,
        Countdown: deadline,
        Status: tender.StatusConfig?.Text,
        MinCarrierRating: open ? tender.MinCarrierRating ?? DASH : DASH,
        MinDriverRating: open ? tender.MinDriverRating ?? DASH : DASH,
        alt: open ? TenderIconTitle.Open : TenderIconTitle.Close,
        Distance: tender.TenderSummary?.Distance ?? DASH,
        RegionalCityRecipient: tender.TenderSummary?.RegionalCityRecipient ?? DASH,
        RegionalCitySender: tender.TenderSummary?.RegionalCitySender ?? DASH,
        Carrier: tender.OffersAccepted?.length ? tender.OffersAccepted[0]?.User?.Name : DASH,
        ForbiddenToDelete: ![TenderStatus.New, TenderStatus.Cancelled, TenderStatus.UnderRevision].includes(
          tender.StatusConfig.Alias as TenderStatus
        )
      };
    })
    .reverse();
};
