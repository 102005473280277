import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { deleteDocumentTemplateRoutine, getDocumentTemplatesRoutine } from '@modules/documents/children/templates/actions';

function* deleteDocumentTemplateWorker(action: PayloadAction<number>) {
  try {
    yield call(DocumentTemplatesAPI.deleteDocumentTemplate, action.payload);
    yield put(getDocumentTemplatesRoutine());
    ILSUserNotify(Notify.Success, 'Шаблон документа успешно удален');
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* deleteDocumentTemplateWatcher() {
  yield takeEvery(deleteDocumentTemplateRoutine.trigger, deleteDocumentTemplateWorker);
}
