import { TenderStatus } from '@modules/tender-platform/types';
import { AccountRole, User } from '@common/types';
import { StatusTenderOffer } from '@modules/tender-platform/children/tender-search/types';
import { EDITABLE_OFFER_STATUSES } from '@modules/tender-platform/children/tender-search/constants';
import { NOT_EDITABLE_TENDERS } from '@modules/tender-platform/constants/tender-card';
import { compareAsString } from '@common/utils';
import { UserRole } from '@common/types/dictionaries';

export const checkAccessToTenderSummary = (statusAlias: TenderStatus) =>
  statusAlias === TenderStatus.SummingResults || statusAlias === TenderStatus.ApprovalResults || statusAlias === TenderStatus.Executed;

export const checkAccessToTenderEditing = (
  { tenderUserID, statusAlias }: { tenderUserID: User['ID']; statusAlias: TenderStatus },
  user: User
) => {
  const startedTender = NOT_EDITABLE_TENDERS.includes(statusAlias);
  const isAdmin = user.UserRole.includes(UserRole.administrator);
  const canLogisticalEdit = !isAdmin && TenderStatus.OnApproval !== statusAlias;
  return (tenderUserID ? tenderUserID === user?.ID : !tenderUserID) && (canLogisticalEdit || isAdmin) && !startedTender;
};

export const checkAccessToApproveOffer = (statusAlias: StatusTenderOffer, user: User) => {
  const [role] = user.UserRole;
  return role === UserRole.administrator && statusAlias === StatusTenderOffer.OnApproval;
};

export const checkRemoveTenderDocument = (statusAlias: TenderStatus, user: User) => {
  const allowAdmin: TenderStatus[] = [TenderStatus.New, TenderStatus.OnApproval, TenderStatus.UnderRevision];
  const allowManager: TenderStatus[] = [TenderStatus.New, TenderStatus.UnderRevision];
  if (user.UserRole?.includes(UserRole.administrator)) {
    return allowAdmin.includes(statusAlias);
  } else if (user.UserRole?.includes(UserRole.manager)) {
    return allowManager.includes(statusAlias);
  } else {
    return false;
  }
};

export const checkAccessToCancelOffer = (statusAlias: TenderStatus, user: User) => {
  const [role] = user.UserRole;
  const isAcceptedUser = role === UserRole.administrator || role === UserRole.driver || role === UserRole.manager;
  const isStatusAccepted =
    statusAlias === TenderStatus.SummingResults ||
    statusAlias === TenderStatus.ApprovalResults ||
    statusAlias === TenderStatus.CollectingOffers;
  return isAcceptedUser && isStatusAccepted;
};

export const checkAccessToChangeOffer = (statusAlias: StatusTenderOffer) => EDITABLE_OFFER_STATUSES.includes(statusAlias);
export const checkAccessToChangeResponsibleInOffer = (statusAlias: StatusTenderOffer) =>
  EDITABLE_OFFER_STATUSES.filter(Boolean).includes(statusAlias);

//TODO вынести в utils
export const checkIsSameUser = ({ UserID, ID }: { UserID: User['ID']; ID: User['ID'] }) => compareAsString(UserID, ID);
export const checkIsCompletedTender = ({ statusAlias }: { statusAlias: TenderStatus }) => {
  return statusAlias === TenderStatus.Completed;
};
export const chekIsAdmin = (userRole: User['UserRole']) => {
  return userRole?.includes(UserRole.administrator);
};
//TODO end

export const checkAccessToRateCarrier = (
  { tenderUserID, statusAlias }: { tenderUserID: User['ID']; statusAlias: TenderStatus },
  { ID, UserRole, Account }: User
) => {
  return (
    checkIsCompletedTender({ statusAlias }) &&
    (checkIsSameUser({ UserID: tenderUserID, ID }) || (chekIsAdmin(UserRole) && Account?.AccountRole?.includes(AccountRole.cargoOwner)))
  );
};

export const checkAccessToCarrierRatingModal = ({ statusAlias }: { tenderOfferUserID: User['ID']; statusAlias: TenderStatus }) => {
  return checkIsCompletedTender({ statusAlias });
  // NOTE: проверка не работает, так как у нас путаница с ответственными на беке из-за бизнес-требований
  // В данном случае, чтобы не давать доступ к чужим отзывам мы полагаемся на фильтрацию тендеров,
  // То есть, во вкладке "Исполнен" не могу быть тендеры, исполненный не checkIsSameUser({ UserID: tenderOfferUserID, ID })
  // && checkIsSameUser({ UserID: tenderOfferUserID, ID });
};
