import { ILSFormItem, ILSSelect } from '@common/components';
import { BASE_FIELD_NAMES, Currency, CURRENCY_OPTIONS } from '@common/constants/options';
import { FC } from 'react';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { Field } from '@common/constants/general';

export const ILSSelectCurrency: FC<IILSSelectProps> = ({ disabled, ...props }) => {
  return (
    <ILSFormItem className={'no-margin'} name={Field.Currency}>
      <ILSSelect
        defaultValue={{ value: Currency.RUB, label: Currency.RUB }}
        {...props}
        showArrow={!disabled}
        disabled={disabled}
        fieldNames={BASE_FIELD_NAMES}
        options={CURRENCY_OPTIONS}
        style={{ width: 'fit-content' }}
      />
    </ILSFormItem>
  );
};
