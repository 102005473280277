import { createReducer } from '@reduxjs/toolkit';
import { emulatorPauseRoutine } from '../actions';
import initialState from '../store';
import { IEmulatorStore } from '../types/store';
import { EmulatorStatus } from '../constants';

// Присваивает статусу эмуляции (emulatorStatus.status) состояние паузы эмуляции (EmulatorStatus.Pause)
const emulatorPauseReducer = createReducer(initialState, {
  [emulatorPauseRoutine.REQUEST]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = true;
    return state;
  },
  [emulatorPauseRoutine.SUCCESS]: (state: IEmulatorStore) => {
    state.emulatorStatus.status = EmulatorStatus.Pause;
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorPauseRoutine.FAILURE]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  },
  [emulatorPauseRoutine.FULFILL]: (state: IEmulatorStore) => {
    state.emulatorStatus.isFetching = false;
    return state;
  }
});

export default emulatorPauseReducer;
