import { ColumnTable, TableRecord } from '@common/types';

type SearchFilterParameters = {
  recordName: string | null | undefined;
  searchLower: string;
  recordChildrenNames: string[] | null | undefined;
};

export const filterSearch = ({ recordChildrenNames, searchLower, recordName }: SearchFilterParameters): boolean => {
  return recordName?.includes(searchLower) || recordChildrenNames?.some((descValue) => descValue?.includes(searchLower)) || false;
};

export const filterStrictSearch = ({ recordChildrenNames, searchLower, recordName }: SearchFilterParameters): boolean => {
  return recordName === searchLower || recordChildrenNames?.some((descValue) => descValue === searchLower) || false;
};

export const handleRowFilter =
  (dataIndex: ColumnTable<TableRecord>['dataIndex'], strictSearch: boolean) => (value: string | number | boolean, record: TableRecord) => {
    const getDescendantValues = (record: TableRecord) => {
      const values = [];
      (function recurseSearchInChildren(record) {
        record[dataIndex] && values.push(record[dataIndex].toString().toLowerCase());
        record?.children?.forEach(recurseSearchInChildren);
      })(record);
      return values;
    };

    const recordName = record[dataIndex]?.toString().toLowerCase();
    const searchLower = value?.toString().toLowerCase();
    const recordChildrenNames = getDescendantValues(record);

    if (strictSearch) {
      return filterStrictSearch({ recordName, searchLower, recordChildrenNames });
    }
    return filterSearch({ recordName, searchLower, recordChildrenNames });
  };
