import { ILSCalendarPicker, ILSRadioGroup, ILSTypographyText } from '@common/components';
import { MONTHS, WEEKDAYS } from '@common/constants/options';
import { LOT_SCHEDUAL_OPTIONS, TenderFormTableField } from '@modules/tender-platform/constants';

export const renderNotEditable = ({ field, value }: { field: TenderFormTableField; value: string }) => {
  switch (field) {
    case TenderFormTableField.Schedule:
      return (
        <div className={'tender-from-planner-form-table-fieldset-row tender-from-planner-form-table-fieldset-row-shedule'}>
          <div className={'shedual-options'}>
            <ILSRadioGroup
              options={LOT_SCHEDUAL_OPTIONS}
              defaultValue={LOT_SCHEDUAL_OPTIONS[0].value}
              className="lot-radio"
              disabled
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div>
            <ILSCalendarPicker
              shadow={false}
              weekStartDayIndex={1}
              weekDays={WEEKDAYS}
              months={MONTHS}
              numberOfMonths={1}
              multiple
              value={value}
              maxDate={value}
              minDate={value}
              disabled={false}
            />
          </div>
        </div>
      );
    default:
      return <ILSTypographyText className={'ant-input ant-input-disabled tender-from-planner-form-table-fieldset-row'} children={value} />;
  }
};
