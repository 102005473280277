import { TenderSearchStore } from '@modules/tender-platform/children/tender-search/types';

const initialState: TenderSearchStore = {
  myTenders: [],
  tenders: [],
  offers: [],
  currentTender: null,
  participantInTenders: [],
  accountUsers: [],
  isFetching: false,
  error: [],
  isSaving: false,
  success: false,
  notificationSettings: {
    Enabled: 1
  },
  filters: []
};

export default initialState;
