import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-search/store';
import { tenderOfferIndexRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderOffer, TenderSearchStore } from '@modules/tender-platform/children/tender-search/types';
import { IndexedArray } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderOfferIndexReducer = createReducer(initialState, {
  [tenderOfferIndexRoutine.REQUEST]: onRequest,
  [tenderOfferIndexRoutine.SUCCESS]: (
    state: TenderSearchStore,
    action: PayloadAction<{
      offers: IndexedArray<TenderOffer>;
    }>
  ) => {
    const { offers } = action.payload;
    state.offers = createArrayFromIndexedArray(offers);
    return state;
  },
  [tenderOfferIndexRoutine.FAILURE]: onFailure,
  [tenderOfferIndexRoutine.FULFILL]: onFulfill
});
