import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { setActiveTabRoutine } from '../actions';
import { detailsTabs } from '../configs/details-tabs';
import { TabChangePayload } from '../types/event-details-tabs';

// Устанавливает активную вкладку
function* setActiveTab(action: PayloadAction<TabChangePayload>) {
  const { activeKey } = action.payload;
  const { success, failure } = setActiveTabRoutine;
  try {
    yield put(success({ activeTab: detailsTabs.find((tab) => tab.key === activeKey)?.key }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* detailsTabChangeWatcher() {
  yield takeLatest(setActiveTabRoutine.trigger, setActiveTab);
}
