import { getSRCIconSVG } from '@common/decorators/path';
import { Module, ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import PlanningByDate from './children/by-date';
import PlanningData from './children/data';
import PlannerNew from './children/new';
import PlanningParams from './children/params';
import PlannerSchemeEditor from './children/scheme-editor';
import { ILSPlanner } from './containers';
import planningRootReducer from './reducers';
import * as planningRootSagas from './sagas';

export default defineModule({
  title: ModuleTitle.Planner,
  path: ModulePath.Planner,
  component: ILSPlanner,
  reducer: planningRootReducer,
  sagas: getAllModuleSagas(planningRootSagas),
  children: {
    PlannerNew,
    PlanningData,
    PlanningByDate,
    PlanningParams,
    PlannerSchemeEditor
    //TODO second stage TMS-1799
    // PlanningManager
  } as any,
  inScope: [ModuleScope.SiderNav],
  icon: getSRCIconSVG('/planning', { iconModule: '/sidebar' }),
  deps: [Module.Documents, Module.Common]
});
