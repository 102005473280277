import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { tenderOfferSetRatingRoutine } from '../actions';
import { TenderAPI } from '../api';

function* tenderSetRatingWorker(
  action: PayloadAction<{
    TenderOfferID: number;
    CarrierComment: string;
    CarrierRating: number;
    DriverComment: string;
    DriverRating: number;
  }>
) {
  const { failure } = tenderOfferSetRatingRoutine;
  const { CarrierComment, CarrierRating, DriverComment, DriverRating, TenderOfferID } = action.payload;

  try {
    yield all([
      call(TenderAPI.setCarrierRating, { TenderOfferID, Value: CarrierRating, Comment: CarrierComment }),
      call(TenderAPI.setDriverRating, { TenderOfferID, Value: DriverRating, Comment: DriverComment })
    ]);
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* tenderSetRatingWatcher() {
  yield takeLatest(tenderOfferSetRatingRoutine.TRIGGER, tenderSetRatingWorker);
}
