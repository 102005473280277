import { getInitialState } from '@common/utils/helpers/store/get-initial-state';
import { Module } from '@common/types';
import { IPlanningDataState } from '@modules/planning/children/data/types';

const initialPlanningDataState: IPlanningDataState = {
  data: [],
  projectID: null,
  conflict: { hasConflict: false, type: null },
  files: null,
  scheme: {},
  templateFile: null,
  exampleFile: null,
  configs: [],
  validateData: null
};

export const initialState = getInitialState<IPlanningDataState>(initialPlanningDataState, Module.PlanningData);
