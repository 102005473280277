import { createReducer } from '@reduxjs/toolkit';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';
import { authInitialState } from '@modules/current-user/store/auth';
import { recoveryRequestActions } from '@modules/current-user/actions';

export const recoveryRequestReducer = createReducer(authInitialState, {
  [recoveryRequestActions.REQUEST]: onRequest,
  [recoveryRequestActions.FAILURE]: onFailure,
  [recoveryRequestActions.FULFILL]: onFulfill,
  [recoveryRequestActions.SUCCESS]: (state) => {
    state.requestAction = 'resetPassword';
    return state;
  }
});
