import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { MonitoringAPI } from '@modules/monitoring/api';
import { getSensorEventsRoutine } from '../actions';
import { SensorEventsDashboardRequest } from '../types/api';

// Получает данные датчиков
function* monitoringSensorEventsWorker(action: PayloadAction<Omit<SensorEventsDashboardRequest, 'vehicleIDs'> & { vehicleID: number }>) {
  const { request, success, failure, fulfill } = getSensorEventsRoutine;
  const { from, till, vehicleID, updates } = action.payload;

  try {
    yield put(request({ from, till, vehicleID }));
    yield delay(100);
    const { data } = yield call(MonitoringAPI.getBDD, { from, till, vehicleID: [vehicleID], updates });

    yield put(success({ data: data.data, from, till, vehicleID, updates }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* monitoringSensorEventsWatcher() {
  yield takeLatest(getSensorEventsRoutine.trigger, monitoringSensorEventsWorker);
}

