import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorComponentType } from '@modules/planning/children/by-date/types';
import { getErrorByTypes, getFail } from '@modules/planning/children/by-date/decorators/table/error-to-table';
import { VALIDATION_TABLES_FOR_PLANNING_BY_DICTIONARY } from '@modules/planning/children/by-date/constants/validation';
import { usePlanning } from '@modules/planning/children/by-date/hooks/tables/plans/use-planning';
import { validationDataSelector } from '@modules/planning/children/by-date/selectors/plan';
import { activePlanIDSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const useValidation = () => {
  const validationData = useSelector(validationDataSelector);
  const activePlanID = useSelector(activePlanIDSelector);
  const { startValidation, startPlanningWithErrorsCheck, activePlanErrors } = usePlanning();

  const [showValidateContainer, setShowValidateContainer] = useState<boolean>(false);
  const [errorComponent, setComponent] = useState<ErrorComponentType>({
    table: null,
    errorType: null,
    errors: [],
    failKeys: null,
    failFieldName: null,
    activeTable: null
  });

  const handleCancel = () => {
    setShowValidateContainer(false);
    setComponent(() => {
      return {
        table: null,
        errorType: null,
        errors: [],
        failKeys: null,
        failFieldName: null,
        activeTable: null
      };
    });
  };

  const onUpdateErrorRecordsFromTable = () => {
    startValidation(true);
  };

  const onValidatePlan = () => {
    startValidation();
  };

  const onStartPlanning = () => {
    // вызывается после валидации
    startPlanningWithErrorsCheck(activePlanID);
  };

  useEffect(() => {
    setComponent((prev: ErrorComponentType) => {
      if (activePlanID && prev?.table && prev?.errorType) {
        const { table, errorType } = prev;
        const errorByTypes = getErrorByTypes(validationData[activePlanID]?.[table]);
        const { failKeys, failFieldName } = getFail(errorByTypes[errorType]);
        return {
          ...prev,
          errors: errorByTypes[errorType],
          failFieldName,
          failKeys
        };
      }
      return prev;
    });
  }, [validationData]);

  return {
    onValidatePlan,
    onUpdateErrorRecordsFromTable,
    onStartPlanning,
    handleCancel,
    validationData,
    activePlanID,
    errorComponent,
    showValidateContainer,
    setShowValidateContainer,
    setComponent,
    activePlanErrors,
    TableWithErrorRecords: errorComponent?.table && VALIDATION_TABLES_FOR_PLANNING_BY_DICTIONARY[errorComponent.table]?.component
  };
};
