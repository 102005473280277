import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/orders-and-trips/store';
import { InitialState } from '@modules/orders-and-trips/types/general';
import { getFakeTripContractRoutine } from '@modules/orders-and-trips/actions';
import { getContractDataToTemplate } from '@modules/orders-and-trips/helpers/get-contract-data-to-template';
import { onFailure } from '@common/utils/helpers/reducers';
import { ContractTemplateResponseType } from '@modules/orders-and-trips/types/contract-template';
import { FakeTripContractRequest } from '@modules/orders-and-trips/types/fake-pre-cost';

export const getFakeTripContractReducer = createReducer(initialState, {
  [getFakeTripContractRoutine.REQUEST]: (state: InitialState) => {
    state.tripContract.isFetching = true;
    return state;
  },
  [getFakeTripContractRoutine.SUCCESS]: (
    state: InitialState,
    action: PayloadAction<{
      data: ContractTemplateResponseType;
      values: FakeTripContractRequest;
    }>
  ) => {
    const { data, values } = action.payload;
    const modifiedContractData = getContractDataToTemplate(data.data);
    state.tripContract.contractTemplate = { template: data.template, data: modifiedContractData };
    state.availableIntegrations.fakePreCostValues = values;
    return state;
  },
  [getFakeTripContractRoutine.FAILURE]: onFailure,
  [getFakeTripContractRoutine.FULFILL]: (state: InitialState) => {
    state.error = [];
    state.tripContract.isFetching = false;
    return state;
  }
});
