import { FC } from 'react';
import { ButtonTypes } from '@common/types/components';
import { EMPTY_STRING } from '@common/constants';
import { ConfirmModalButtons } from '@modules/orders-and-trips/constants/display-blocks';
import { ActionButton } from '@modules/orders-and-trips/components/buttons/action-button';

interface IProps {
  submitButtonProps: Partial<Record<ButtonTypes, () => void>>;
}

export const ExternalTransportConfirmModalButtons: FC<IProps> = ({ submitButtonProps }) => {
  return (
    <div className="external-transport-contract-buttons">
      {ConfirmModalButtons.map((defaultProps) => (
        <ActionButton {...defaultProps} click={submitButtonProps[defaultProps.click ?? EMPTY_STRING]} key={defaultProps.text} />
      ))}
    </div>
  );
};
