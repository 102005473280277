import { fetchSelector } from '@modules/tender-platform/children/tender-own/selectors';
import { validateIsAllRoutesDoneSelector } from '@modules/tender-platform/children/tender-own/selectors/tender';
import { TenderPlatformMenuButton } from '@modules/tender-platform/components/menu-button';
import { TenderFromPlannerOperationButton, TenderOperationsArgument } from '@modules/tender-platform/constants/content';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON } from '@modules/tender-platform/constants/modal-content';

interface IProps {
  handleModal({ operation }: TenderOperationsArgument): void;
}
export const TenderFromPlannerOperationButtonContainer: FC<IProps> = ({ handleModal }) => {
  const disabled = useSelector(validateIsAllRoutesDoneSelector);
  const isFetching = useSelector(fetchSelector);

  return (
    <>
      {Object.values(TenderFromPlannerOperationButton).map((operation) => {
        const handleSaveTenders = () => handleModal({ operation });
        return (
          <TenderPlatformMenuButton
            disabled={disabled || isFetching}
            children={TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON[operation]?.text}
            onClick={handleSaveTenders}
          />
        );
      })}
    </>
  );
};
