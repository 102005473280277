import { ILSAppRegistry } from '../../../index';
import { useAbac } from 'react-abac';
import permissions from '@abac/permissions';
import { RouteConfig } from 'react-router-config';
import { FC } from 'react';
import { ModulePath, UserRole } from '@common/types';

type UserType = {
  UserRole: Array<UserRole>;
};
type moduleType = {
  path: string;
  fullPath: string;
  title: string;
  fullTitle: string;
};
type accessAllowedType = boolean;

const getPageTitle = (
  location: string,
  routes: Array<RouteConfig>,
  user: UserType,
  accessAllowed: accessAllowedType,
  currentRoute: RouteConfig
) => {
  const route = routes.filter((r) => r.path === location && r);
  const modules = ILSAppRegistry.modules as Array<moduleType>;

  if (currentRoute) {
    return currentRoute.title;
  } else if (!user.UserRole || user.UserRole[0] === 'guest') {
    switch (location) {
      case ModulePath.Register:
        return 'Регистрация';
      case ModulePath.Recovery:
        return 'Восстановление пароля';
      case ModulePath.Login:
      default:
        return 'Авторизация';
    }
  } else if (!user.UserRole.length || !accessAllowed) {
    return 'Ошибка доступа';
  } else if (route && route[0] && route[0].title) {
    return route[0].title;
  } else {
    const module = modules.filter((r) => r.path === location && r);
    if (module && module[0]) return module[0].title;

    const childModule = modules.filter((r) => r.fullPath === location && r);
    if (childModule && childModule[0]) return childModule[0].fullTitle;
  }
};

const setPageTitle = (
  location: string,
  routes: Array<RouteConfig>,
  user: UserType,
  accessAllowed: accessAllowedType,
  currentRoute: RouteConfig
) => {
  document.title = getPageTitle(location, routes, user, accessAllowed, currentRoute) ?? 'TMS'; //store,
};

interface ITitler {
  currentRoute: RouteConfig;
  location: string;
  user: UserType;
  route: {
    routes: Array<RouteConfig>;
  };
}

export const Titler: FC<ITitler> = ({ currentRoute, location, user, route: { routes } }) => {
  //для проверки, разрешен ли доступ
  const { userHasPermissions } = useAbac();
  const accessAllowed = userHasPermissions(permissions.MAIN_LAYOUT, user);
  //title страницы
  setPageTitle(location, routes, user, accessAllowed, currentRoute);
  return null;
};
