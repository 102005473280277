import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRouteByCoordsRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { RoutePointsRequest } from '@modules/orders-and-trips/types/map';

function* getRouteWorker(
  action: PayloadAction<{
    points: RoutePointsRequest[];
  }>
) {
  const { request, success, failure, fulfill } = getRouteByCoordsRoutine;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTrack, { ...(action.payload ?? {}) });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getRouteWatcher() {
  yield takeLatest(getRouteByCoordsRoutine.trigger, getRouteWorker);
}
