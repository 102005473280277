import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { tenderDocumentsRoutine } from '@modules/tender-platform/actions';
import { TenderDocuments, TenderState } from '@modules/tender-platform/types';
import { IndexedArray } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const getTenderDocumentsReducer = createReducer(initialState, {
  [tenderDocumentsRoutine.REQUEST]: onRequest,
  [tenderDocumentsRoutine.FAILURE]: onFailure,
  [tenderDocumentsRoutine.FULFILL]: onFulfill,
  [tenderDocumentsRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      documents: IndexedArray<TenderDocuments>;
    }>
  ) => {
    const { documents } = action.payload;
    state.documents = documents;
    return state;
  }
});
