import { Module } from '@common/types';
import { createModuleAction } from '@common/utils/general/create-module-action';
import { Action } from 'redux';
import { createRoutine, UnifiedRoutine } from 'redux-saga-routines';
import { DemandAction } from '../types/action';
import { createAction } from '@reduxjs/toolkit';

const MODULE_NAME = Module.Demand.toLocaleUpperCase();

// Рутина для создания модуля "Заявки и рейсы"
export const createDemandRoutine = (name: DemandAction) => createRoutine(createModuleAction(MODULE_NAME, name));

export const demandGetListRoutine = createDemandRoutine(DemandAction.GetList); // Рутина для получения списка проектов и тендерных лотов
export const demandChangeActiveTabRoutine = createDemandRoutine(DemandAction.ChangeActiveTab); // Рутина для изменения активного таба (все, только проекты, только лоты)
export const demandGetCountsRoutine = createDemandRoutine(DemandAction.GetCounts); // Рутина для получения количества проектов и лотов
export const demandGetCountsRunAction = createAction(createModuleAction(MODULE_NAME, DemandAction.GetCountsRun)); // Экшен для начала запросов по подсчету лотов и проектов
export const demandGetCountsStopAction = createAction(createModuleAction(MODULE_NAME, DemandAction.GetCountsStop)); //  Экшен для окончания запросов по подсчету лотов и проектов

export const demandRoutinesToNotify: UnifiedRoutine<(payload?: any) => Action>[] = [demandGetListRoutine]; // Работа с нотификациями
