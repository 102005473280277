import { Notify, NotifyDurationInSecond } from '@common/types';

export const GET_COORD_NOTIFY = {
  GET_COORDINATES_ERROR: {
    MESSAGE: 'Не удалось получить координаты по адресу (введённый адрес не найден в базе адресов, проверьте корректность ввода)',
    TYPE: Notify.Warning,
    DURATION: NotifyDurationInSecond.Five,
    KEY: 'CATALOG/GET_COORDINATES_ERROR'
  },
  GET_ADDRESS_ERROR: {
    MESSAGE: 'Не удалось получить адрес по координатам',
    TYPE: Notify.Warning,
    DURATION: NotifyDurationInSecond.Five,
    KEY: 'CATALOG/GET_ADDRESS_ERROR'
  }
};

/**
 * Кол-во одновременных обвновлений для точек
 */
export const UPDATE_COORDS_REQUEST_AT_TIME_COUNT = 500;
