import { useInterval } from '@common/hooks/use-interval';
import { MonitoringServedDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { monitoringDateRoutine } from '@modules/monitoring/actions';
import { MonitoringQueryParams } from '@modules/monitoring/constants';
import { useLoadMetrics } from '@modules/monitoring/hooks/use-load-metrics';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { monitoringSettingsSelector } from '@modules/settings/selectors';
import { omit } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  monitoringActiveDashboardTabRoutine,
  monitoringDashboardAllInfoRoutine,
  monitoringDashboardInfoRoutine,
  monitoringDashboardUpdateViewRoutine,
  monitoringGetProgressRoutine
} from '../actions';
import ILSMonitoringDashboardComponent from '../components';
import {
  DASHBOARD_LOAD_INTERVAL,
  DASHBOARD_UPDATES_INTERVAL,
  DashboardTablesObjMetrics,
  TABLES_WHERE_LOAD_ALL,
  apiMethodsNames
} from '../constants';
import { DashboardSensorNameViewed, DashboardTablesNumberMetrics } from '../constants/tables';
import { dashboardTableSelector } from '../selectors';
import { dashboardInfoSelector, dashboardTableDataSelector } from '../selectors/dashboard';
import { initialDashboardData } from '../store';
import '../styles.less';
import { DashboardTables } from '../types';
import { DashboardData } from '../types/store';
import { checkDaysDiff } from '../utils/check-days-diff';
import { needUpdateDashboard } from '../utils/check-need-dashboard-update';

// Основной контейнер отвечающий за данные модуля «Текущая сводка»
//TODO перенести в хуки создание функций хелперов и useEffect
export const ILSMonitoringDashboard = () => {
  const currentTable = useAppSelector(dashboardTableSelector);
  const { startDate, endDate } = useAppSelector(monitoringDateSelector);

  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const queryTabName = queryParams.get(MonitoringQueryParams.TAB_NAME);
  const currentTableName = useAppSelector(dashboardTableSelector);
  const dashboardInfo = useAppSelector(dashboardInfoSelector);
  const maxInterval = useAppSelector(monitoringSettingsSelector).parameters.monitoringMaxDateInvterval;
  const currentDashboardTable: DashboardData<any> = dashboardInfo[currentTableName as DashboardTables] || dashboardInfo['all'];
  const dashboardTableData: typeof initialDashboardData = useAppSelector((state) => dashboardTableDataSelector(state, currentTableName));
  const dashboardTableAllData: DashboardData<MonitoringServedDataModel> = useAppSelector((state) =>
    dashboardTableDataSelector(state, undefined)
  );

  const refLoadAllInfo = useRef(omit(dashboardTableAllData, 'data'));
  useEffect(() => {
    refLoadAllInfo.current = omit(dashboardTableAllData, 'data');
  }, [dashboardTableAllData]);
  const refLoadCurrentInfo = useRef(omit(currentDashboardTable, 'data'));
  useEffect(() => {
    refLoadCurrentInfo.current = omit(currentDashboardTable, 'data');
  }, [currentDashboardTable]);

  const useLoadAll = currentTableName && TABLES_WHERE_LOAD_ALL.includes(currentTableName);

  const dispatch = useAppDispatch();

  const updateProgress = useCallback(() => {
    if (endDate && startDate && checkDaysDiff(startDate, endDate)) {
      dispatch(monitoringGetProgressRoutine({ from: startDate, till: endDate }));
    }
  }, [startDate, endDate]);

  useInterval(updateProgress, DASHBOARD_UPDATES_INTERVAL, [startDate, endDate]);
  const handleDashboardDate = (date?: string[] | string) => {
    dispatch(
      monitoringDateRoutine({
        date,
        metricsInterval: maxInterval
      })
    );
  };

  const handleDashboardInfo = () => {
    if (
      (!currentDashboardTable?.isFetching && endDate && startDate && checkDaysDiff(startDate, endDate) && !queryTabName) ||
      needUpdateDashboard({ startDate, endDate }, refLoadCurrentInfo.current)
    ) {
      dispatch(monitoringDashboardInfoRoutine({ activeTab: currentTableName, from: startDate, till: endDate }));
    }
  };
  const setActiveTab = (newActiveTable: DashboardTables | undefined) => {
    dispatch(monitoringActiveDashboardTabRoutine({ activeTab: newActiveTable }));
  };
  const handleActiveDashboard = () => {
    if (queryTabName) {
      queryParams.delete(MonitoringQueryParams.TAB_NAME);
      history.replace({
        search: queryParams.toString()
      });
      setActiveTab(queryTabName as DashboardTables);
    } else {
      handleDashboardInfo();
    }
  };
  const handleDashboardLoadAllInfo = () => {
    const { lastUpdate, till, from, isFetching } = refLoadAllInfo.current;
    const updates = lastUpdate && lastUpdate + DASHBOARD_UPDATES_INTERVAL < new Date().getTime() && till === endDate && from === startDate;
    if (
      useLoadAll &&
      !isFetching &&
      endDate &&
      startDate &&
      checkDaysDiff(startDate, endDate) &&
      ((till !== endDate && from !== startDate) || !lastUpdate || updates)
    ) {
      dispatch(monitoringDashboardAllInfoRoutine({ from: startDate, till: endDate, updates }));
    }
  };

  const handleDashboardUpdateCurrent = () => {
    if (queryTabName) {
      return;
    } else if (!currentTableName || currentTableName === DashboardTablesNumberMetrics.Served) {
      const { lastUpdate, till, from } = refLoadAllInfo.current;

      const updates =
        lastUpdate && lastUpdate + DASHBOARD_UPDATES_INTERVAL > new Date().getTime() && till === endDate && from === startDate;
      dispatch(monitoringDashboardInfoRoutine({ from: startDate, till: endDate, updates }));
    }
    const { lastUpdate, from, till } = refLoadCurrentInfo.current;
    const updates = lastUpdate && lastUpdate + DASHBOARD_UPDATES_INTERVAL > new Date().getTime() && startDate === from && endDate === till;
    if (
      endDate &&
      startDate &&
      checkDaysDiff(startDate, endDate) &&
      (!currentDashboardTable.from || !currentDashboardTable.till || !currentDashboardTable?.lastUpdate || updates)
    ) {
      dispatch(monitoringDashboardInfoRoutine({ from: startDate, till: endDate, updates, activeTab: currentTableName }));
    }
  };
  const handleUpdateView = useCallback(
    (ids: string | number | string[], value: 0 | 1) => {
      const payload = {
        DependentKey: ids,
        Value: value,
        activeTab: currentTableName as string
      };
      switch (currentTableName) {
        case DashboardTablesObjMetrics.Violations:
          payload.activeTab = apiMethodsNames[DashboardSensorNameViewed.SensorValue];
          break;
        case DashboardTablesObjMetrics.FuelDrain:
          payload.activeTab = apiMethodsNames[DashboardSensorNameViewed.FuelKeyPoint];
          break;
        default:
          break;
      }
      dispatch(monitoringDashboardUpdateViewRoutine(payload));
    },
    [monitoringDashboardUpdateViewRoutine, currentTableName]
  );

  // Запрос метрик после обновления даты
  useLoadMetrics();
  //Получение данных дашборд при изменении активного таба
  useInterval(handleActiveDashboard, DASHBOARD_LOAD_INTERVAL, [currentTableName, startDate, endDate, queryTabName]);
  //Обновление общих данных если находится в другой вкладке
  useInterval(handleDashboardLoadAllInfo, DASHBOARD_UPDATES_INTERVAL, [currentTableName, startDate, endDate]);
  //Обновление текущей вкладки
  useInterval(handleDashboardUpdateCurrent, DASHBOARD_UPDATES_INTERVAL, [currentTableName, startDate, endDate]);

  return (
    <ILSMonitoringDashboardComponent
      dataSource={dashboardTableData?.data || []}
      dataSourceAll={dashboardTableAllData?.data}
      maxInterval={maxInterval}
      activeTab={currentTable}
      setActiveTab={setActiveTab}
      handleDashboardDate={handleDashboardDate}
      handleUpdateView={handleUpdateView}
      isFetching={currentDashboardTable?.isFetching}
      from={startDate}
      till={endDate}
    />
  );
};

