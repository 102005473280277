import { YANDEX_MAPS_URL } from '@common/components/data-display/table/constants';
import { GeocodeResult } from '@common/types';
import { getScript } from '@common/utils';

const emtpyPromise = new Promise<undefined>((resolve) => {
  resolve(undefined);
});

const CHECK_INIT_YAPI = 500; //Частота проверки загрузки Api
const TIMEOUT_LOAD_YAPI_STEP = 50; //Шаг после которого вместо геокодирования будет отправлен пустой промис в качестве ошибки

//NOTE утилита инициализации api яндекса и получения результата geocode
export const getGeocode = async (addressOrCoords: string | number[]): Promise<GeocodeResult | undefined> => {
  //NOTE инициализировано ли Api
  let ymapsInit = false;

  const loadCallback = () => {
    ymapsInit = true;
  };
  getScript(YANDEX_MAPS_URL, 'ymaps', loadCallback, {});

  //
  return await new Promise<Promise<GeocodeResult | undefined>>((resolve) => {
    let step = 0;
    const resolveFunction = () => {
      if (ymapsInit) {
        if (window.ymaps?.ready) {
          clearInterval(interval);
          window.ymaps?.ready(() => {
            resolve(window.ymaps?.geocode(addressOrCoords, { results: 1 }) ?? emtpyPromise);
          });
        }
      } else {
        step++;
        if (step > TIMEOUT_LOAD_YAPI_STEP) {
          clearInterval(interval);
          resolve(emtpyPromise);
        }
      }
    };
    let interval = setInterval(resolveFunction, CHECK_INIT_YAPI);
    resolveFunction();
  });
};

export const getAddressByCoordsYandex = async (lat: number, lon: number) => {
  let geocode = await getGeocode([lat, lon]);
  let firstGeoObject = geocode?.geoObjects.get(0);
  if (firstGeoObject && geocode?.metaData?.geocoder?.found) {
    return firstGeoObject.getAddressLine();
  } else return undefined;
};

export const getCoordsByAddressYandex = async (address: string) => {
  let geocode = await getGeocode(address);
  let firstGeoObject = geocode?.geoObjects.get(0);
  if (geocode?.metaData?.geocoder?.found && firstGeoObject) {
    return firstGeoObject.geometry.getCoordinates();
  } else return undefined;
};

