import { Tooltip, TooltipProps } from 'antd';
import { FC } from 'react';

export const ILSTooltip: FC<TooltipProps> = ({ children, mouseLeaveDelay = 0, ...props }) => {
  return (
    <Tooltip mouseLeaveDelay={mouseLeaveDelay} {...props}>
      {children}
    </Tooltip>
  );
};
