import { trackGetProjectRoutine } from '@modules/track/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Track } from '@modules/track/api';
import history from '@common/utils/history';
import { PlanResponse } from '@common/types';

function* trackGetProjectWorker() {
  const { request, success, failure, fulfill } = trackGetProjectRoutine;

  try {
    const queryParams = new URLSearchParams(history.location.search);
    const planID = queryParams.get('plan');

    yield put(request());

    if (planID) {
      const {
        data: { data: plan }
      } = yield call(Track.getPlan, { ID: planID });

      const payload: { plan: PlanResponse } = { plan };

      yield put(success(payload));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* trackGetProjectWatcher() {
  yield takeEvery(trackGetProjectRoutine.trigger, trackGetProjectWorker);
}
