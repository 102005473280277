import { FC } from 'react';
import { ILSMonitoringDashboardGraphContainer } from '@modules/monitoring/children/dashboard/containers/dashboard/dashboard-table-graph';
import { IndexedArray } from '@common/types';
import { MonitoringBDDAndFuelDrainModel } from '@common/types/dictionaries/monitoring-vehicle';
import { dataTypeVariants } from '@modules/monitoring/types/graph';

interface EventsDetailsTableProps {
  title: string;
  selectedVehicleID: number | undefined;
  dataSource: IndexedArray<MonitoringBDDAndFuelDrainModel>;
  startDate: string | number | undefined;
  endDate: string | number | undefined;
}

/**
 * Компонент для отображения одной из таблиц с детализацией (Маршрут, План/Факт, Датчики, БДД)
 */
export const DetailsTableComponent: FC<EventsDetailsTableProps> = ({ title, selectedVehicleID, dataSource, startDate, endDate }) => {
  return (
    <ILSMonitoringDashboardGraphContainer
      dataSource={dataSource}
      title={''}
      tillDate={endDate ? endDate.toString() : ''}
      selectedVehicleID={selectedVehicleID}
      listOfGraph={[
        dataTypeVariants.Fuel,
        dataTypeVariants.Speed,
        dataTypeVariants.Temperature1,
        dataTypeVariants.Temperature2,
        dataTypeVariants.OpenDoor
      ]}
    />
  );
};
