import { ROW_CLASS_DRAG } from '@common/components/data-display/table/constants';
import { TableRecord } from '@common/types';
import { getDraggableRowByID } from '@modules/planning/children/by-date/helpers';
import { deepEqual } from 'fast-equals';
import { MutableRefObject } from 'react';

export type OnChangeHoveredRecord = (record?: TableRecord) => void;

export const handleChangeHoveredRecord =
  ({
    isRun,
    hoveredRecord,
    tableRef
  }: {
    isRun: MutableRefObject<boolean>;
    hoveredRecord: MutableRefObject<TableRecord | null>;
    tableRef: MutableRefObject<HTMLDivElement | null>;
  }) =>
  (record: TableRecord | undefined) => {
    const delay = 50;
    if ((isRun.current && !deepEqual(record, hoveredRecord.current)) || !record) {
      isRun.current = false;

      if (hoveredRecord.current) {
        const row = getDraggableRowByID(hoveredRecord.current.ID, tableRef);
        row?.classList.remove(ROW_CLASS_DRAG);
      }

      if (record) {
        const row = getDraggableRowByID(record.ID, tableRef);
        row?.classList.add(ROW_CLASS_DRAG);
      }

      hoveredRecord.current = record ?? null;
      setTimeout(() => (isRun.current = true), delay);
    }
  };
