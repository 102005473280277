import { ILSUserNotifyConfirm } from '@components/feedback/ils-user-notify';
import { ILSButton } from '@components/index';
import { Dictionary, Key } from '@common/types';
import { FC } from 'react';
import { CatalogTariffFormulaAndResultContainer } from '@modules/catalog/components/tariff/form';
import { isEmpty } from 'lodash';
import { EMPTY_STRING } from '@common/constants';

export interface IILSCatalogFormulaViewComponent {
  activeItem: {
    ID: Key;
    Formula: string;
    Name: string;
    Calc: string;
  };
  checking?: boolean | 1 | 0;
  dictionary: Dictionary;

  handleDelete(dictionary: Dictionary, id: Key): void;

  setEdit(p: boolean): void;
}

export const ILSCatalogFormulaViewComponent: FC<IILSCatalogFormulaViewComponent> = ({
  activeItem,
  handleDelete,
  setEdit,
  dictionary,
  checking
}) => {
  const onEdit = () => setEdit(true);
  const onDelete = (id: Key) => handleDelete(dictionary, id);
  const onClick = () => {
    ILSUserNotifyConfirm('Удалить тариф?', undefined, () => onDelete(activeItem.ID));
  };

  if (isEmpty(activeItem)) return null;

  return (
    <>
      {checking && (
        <CatalogTariffFormulaAndResultContainer name={activeItem?.Name ?? EMPTY_STRING} showFormula={activeItem?.Formula ?? EMPTY_STRING} />
      )}
      <div className="flex-between-center mt-4 mb-3 mr-2">
        <ILSButton children={'Удалить'} onClick={onClick} danger />
        <ILSButton children={'Редактировать'} onClick={onEdit} />
      </div>
    </>
  );
};
