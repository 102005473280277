import { defineModule } from '@core/index';
import { ModuleScope } from '@core/types';
import AuthPage from './auth-page-container';
import { ModulePath } from '@common/types';

export default defineModule([
  {
    title: 'Авторизация',
    path: ModulePath.Login,
    component: AuthPage,
    useScope: [ModuleScope.Logo, ModuleScope.LoginForm, ModuleScope.AuthNav]
  },
  {
    title: 'Регистрация',
    path: ModulePath.Register,
    component: AuthPage,
    useScope: [ModuleScope.Logo, ModuleScope.RegisterForm, ModuleScope.AuthNav]
  },
  {
    title: 'Восстановление пароля',
    path: ModulePath.Recovery,
    component: AuthPage,
    useScope: [ModuleScope.Logo, ModuleScope.RecoveryForm, ModuleScope.AuthNav]
  },
  {
    title: 'Логистические сервисы',
    path: ModulePath.LogisticServices,
    component: AuthPage,
    useScope: [ModuleScope.LogisticServices, ModuleScope.AuthNav]
  },
]);
