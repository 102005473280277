import { PayloadAction } from '@reduxjs/toolkit';
import { sendContractorRoutine } from '@modules/documents/actions';
import { call, takeEvery, put } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* sendContractorWorker(action: PayloadAction<{ contractorID: number; docID: number }>) {
  const { fulfill, failure } = sendContractorRoutine;

  try {
    const { data } = yield call(Documents.sendContractor, action.payload);

    ILSUserNotify(Notify.Success, data.data, 3);
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* sendContractorWatcher() {
  yield takeEvery(sendContractorRoutine.trigger, sendContractorWorker);
}
