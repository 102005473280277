import { IILSCellNotEditableCellProps } from '@common/types/components/table/cell';
import cn from 'classnames';
import { ILSTypographyText } from '@common/components';
import { TableRecord } from '@common/types';

//todo modal
export const ILSCellPercent = <R extends TableRecord>({ children }: IILSCellNotEditableCellProps<R>): JSX.Element => {
  const [, value] = children;
  const numberValue = Number(value) > 0 ? Number(value) : 0;
  const className = numberValue < 50 ? 'low' : numberValue < 80 ? 'good' : numberValue <= 100 ? 'high' : 'over';

  return (
    <div className={cn('cell-percent', className)}>
      <ILSTypographyText className={'cell-percent-text'}>{Math.ceil(numberValue)}%</ILSTypographyText>
    </div>
  );
};
