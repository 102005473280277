import { IILSTableProps, TableName, TableRecord } from '@common/types';
import { IDragDropContainer } from 'react-drag-drop-container';
import { SetTriggerMapFocus } from '@modules/planning/children/by-date/types/map-container';

export interface IPlanningResultTablesContainerProps extends Pick<IILSTableProps<TableRecord>, 'settingsTableButtons'> {
  markerDragging: boolean;
  fullMap: boolean;

  setTriggerMapFocus: SetTriggerMapFocus;

  onItemDrop(e: IDragDropContainer, record: any): void;

  onTableRowClick(record: any, expand?: boolean, ctrl?: boolean, tableName?: TableName): void;

  handleShowOrderTable(val: boolean): void;
}

export type OnExpandRowArgs = { tableName: TableName | string | undefined; isExpand: boolean; record: TableRecord };

export enum FocusNExpandRowsChangingType {
  Focus = 'focus',
  UnsetFocus = 'unsetFocus',
  Expand = 'expand',
  CheckAndUpdate = 'checkAndUpdate'
}
