import { Contact } from '@modules/tender-platform/types/contact';
import { Depot, IEnum, IndexedArray, Restriction, User, VehicleType, WeekDay } from '@common/types';
import { Currency, VATLabel } from '@common/constants/options';
import { PayloadAction } from '@reduxjs/toolkit';
import { Tender } from '@modules/tender-platform/types/tender';
import { Field } from '@common/constants/general';
import { TenderFormField } from '@modules/tender-platform/constants/content';
import { TenderFormPart } from '@modules/tender-platform/utils/decorators/form-to-save-data';

export type TenderLotPosition = {
  ID: number;
  TenderLotID: TenderLot['ID'];
  Mass: number | null;
  Amount?: number | null;
  Comment?: string | null;
  Height?: number | null;
  Length?: number | null;
  RestrictionID?: Restriction['ID'] | null;
  RestrictionEnum?: IEnum<Restriction['Name']>;
  Volume?: number | null;
  Width?: number | null;
};

export enum SeparateVehicle {
  No,
  Yes
}

export enum SeparateVehicleLabel {
  No = 'Не требуется выделенный транспорт',
  Yes = 'Требуется выделенный транспорт'
}

export enum LotTabName {
  Cargo = 'Cargo',
  Route = 'Route',
  Condition = 'Condition',
  Template = 'Template'
}

export enum LotTabTitle {
  Cargo = 'Груз',
  Route = 'Маршрут',
  Condition = 'Условия заказа',
  Template = 'Сохраненные шаблоны'
}

export enum LotPosition {
  Mass = 'Mass',
  Volume = 'Volume',
  Width = 'Width',
  Height = 'Height',
  Length = 'Length',
  Amount = 'Amount',
  RestrictionID = 'RestrictionID',
  Comment = 'Comment'
}

export enum LotPositionSign {
  Mass = 'кг',
  Volume = 'м3',
  Width = 'мм',
  Height = 'мм',
  Length = 'мм',
  Amount = 'кол-во'
}

export enum VehicleLoadType {
  Up,
  Side,
  Back
}

export enum VehicleLoadTypeLabel {
  Up = 'Верхняя',
  Side = 'Боковая',
  Back = 'Задняя'
}

export enum IsTemplate {
  No,
  Yes
}

export enum IsTemplateLabel {
  No = 'Не сохранять как шаблон',
  Yes = 'Сохранить как шаблон'
}

export type Schedule = {
  from?: string;
  till?: string;
  weekdays?: Array<WeekDay>;
  dates?: string[];
  interval?: number;
};

export type TenderLot = {
  ID: number;
  TenderID: Tender['ID'];
  //NOTE: ignore this parameter from back (redundant)
  // BiddingParameter: BiddingParameter;
  IsTemplate: IsTemplate | null | string;
  TemplateName?: string | null;
  MinPrice?: number | null;
  RegionalCityRecipient: string | null;
  RegionalCitySender: string | null;
  StartPrice: number;
  Currency: Currency;
  Contact: null;
  Schedule?: Schedule;
  SourceAddress?: Depot['AddressDescription'];
  SourceID: Depot['ID'];
  Source?: Depot;
  SourceContact?: Contact[] | IndexedArray<Contact>;
  TargetAddress?: Depot['AddressDescription'];
  TargetID: Depot['ID'];
  Target?: Depot;
  TargetContact?: Contact[] | IndexedArray<Contact>;
  TenderLotPosition?: TenderLotPosition[];
  TenderLotUser?: User[] | IndexedArray<User>;
  SeparateVehicle: SeparateVehicle | null;
  VAT: VATLabel;
  VehicleLoadType: VehicleLoadType | null;
  VehicleType?: Array<VehicleType>;
  VehicleTypeEnum?: IEnum<VehicleType>;
  Distance: number | null;
  // TODO delete, add TenderLotSummary
  TotalMass?: number | null;
};

export type TenderLotCreateAction = PayloadAction<{
  tenderID: Tender['ID'];
  values: Record<TenderFormField, any>;
  url: string;
  updateTenderStatus: boolean;
}>;

export type TenderLotUpdateAction = PayloadAction<{
  id: TenderLot['ID'];
  values: Record<TenderFormPart, Partial<Record<TenderFormField, any>>>;
  tmpLotData: Record<TenderFormField, any>;
}>;

export type LotTemplate = Record<TenderLot['ID'], string>;
export type TenderLotTemplate = TenderLot;

export type RouteField = Field.Target | Field.Source;
export type AddressField = TenderFormField.TargetAddress | TenderFormField.SourceAddress;
export type ContactField = TenderFormField.TargetContact | TenderFormField.SourceContact;

export enum ScheduleType {
  Weekdays = 'weekdays',
  Dates = 'dates',
  Interval = 'interval'
}

export enum PositionType {
  Volume,
  WHL
}

export enum LotFormFieldLabel {
  ID = 'Номер лота',
  SeparateVehicle = 'Требуется выделенный транспорт',
  Schedule = 'Schedule',
  SourceAddress = 'Погрузка',
  Source = 'Source',
  SourceContact = 'Контакт погрузки',
  TargetAddress = 'Разгрузка',
  Target = 'Target',
  TargetContact = 'Контакт разгрузки',
  TenderLotPosition = 'Груз',
  TenderLotUser = 'Контактное лицо',
  RestrictionID = 'Специфики (наименования)',
  Mass = 'Масса (кг)',
  Volume = 'Объем (м3)',
  Amount = 'Количество (шт)',
  Length = 'Длина (м)',
  Width = 'Ширина (м)',
  Height = 'Высота (м)',
  MinPrice = 'Минимальная цена',
  StartPrice = 'Начальная цена',
  VehicleLoadType = 'Тип погрузки',
  TemplateName = 'Название шаблона',
  VehicleType = 'Требуемый транспорт',
  Phase = 'Период',
  Distance = 'Дистанция'
}

export enum TenderLotFields {
  ID = 'ID',
  IsTemplate = 'IsTemplate',
  TemplateName = 'TemplateName',
  MinPrice = 'MinPrice',
  RegionalCityRecipient = 'RegionalCityRecipient',
  RegionalCitySender = 'RegionalCitySender',
  StartPrice = 'StartPrice',
  SeparateVehicle = 'SeparateVehicle',
  VAT = 'VAT',
  VehicleLoadType = 'VehicleLoadType',
  Currency = 'Currency',
  Schedule = 'Schedule',
  SourceAddress = 'SourceAddress',
  SourceID = 'SourceID',
  Source = 'Source',
  SourceContact = 'SourceContact',
  TargetAddress = 'TargetAddress',
  TargetID = 'TargetID',
  Target = 'Target',
  TargetContact = 'TargetContact',
  TenderLotPosition = 'TenderLotPosition',
  TenderLotUser = 'TenderLotUser',
  VehicleType = 'VehicleType',
  Distance = 'Distance'
}
