import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { statusDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { initialState } from '@modules/planning/children/by-date/store';
import { MatrixStatus } from '@modules/planning/children/by-date/types';

export const statusDistanceMatrixReducer = createReducer(initialState, {
  [statusDistanceMatrixRoutine.TRIGGER]: (state, action: PayloadAction<{ withoutLoading?: boolean }>) => {
    state.matrix.loading = !action.payload?.withoutLoading;
    return state;
  },
  [statusDistanceMatrixRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: MatrixStatus;
    }>
  ) => {
    const { data } = action.payload;
    state.matrix.status = data;
    return state;
  },
  [statusDistanceMatrixRoutine.FULFILL]: (state) => {
    state.matrix.loading = false;
    return state;
  }
});
