import { PayloadAction } from '@reduxjs/toolkit';
import { suggestContractorRoutine } from '@modules/documents/actions';
import { call, takeEvery, put } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';

function* suggestContractorWorker(action: PayloadAction<{ name: string; resetData?: Array<any> }>) {
  const { success, request, fulfill, failure } = suggestContractorRoutine;

  try {
    yield put(request());

    if (!action.payload?.resetData) {
      const { data } = yield call(Documents.suggestContractor, { q: action.payload.name?.toLowerCase() });
      yield put(success({ data: data.data }));
    } else {
      yield put(success({ data: action.payload?.resetData }));
    }
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* suggestContractorWatcher() {
  yield takeEvery(suggestContractorRoutine.trigger, suggestContractorWorker);
}
