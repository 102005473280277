import { CellType, TableConfig } from '@common/types';

export const inboxConfig: TableConfig = {
  hideClearButtons: true,
  columns: [
    {
      active: true,
      dataIndex: 'Button',
      align: 'left',
      dontHide: true,
      isFixedColumn: true,
      titleIcon: 'options',
      type: CellType.Button,
      width: 40
    },
    {
      title: 'Дата получения',
      dataIndex: 'Created',
      type: CellType.Input,
      width: 50
    },
    {
      title: 'От кого',
      dataIndex: 'ContractorName',
      type: CellType.Input,
      width: 150
    },
    {
      title: 'Тип документа',
      dataIndex: 'Type',
      type: CellType.Input,
      align: 'center',
      width: 120
    },
    {
      title: 'Название документа',
      dataIndex: 'Name',
      type: CellType.Input,
      width: 250
    },
    {
      title: 'ID Документа',
      dataIndex: 'DocumentID',
      type: CellType.Input,
      align: 'center',
      width: 40
    }
  ]
};

export enum InboxAction {
  Open = 'open',
  Accept = 'accept',
  Decline = 'decline',
  Delete = 'delete'
}

