import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../store';
import { trackGetProjectRoutine } from '../actions';
import { TrackStore } from '@modules/track/types';
import { PlanResponse } from '@common/types';

const getProject = createReducer(initialState, {
  [trackGetProjectRoutine.REQUEST]: (state: TrackStore) => {
    state.isFetching = true;
    return state;
  },
  [trackGetProjectRoutine.SUCCESS]: (state: TrackStore, action: PayloadAction<{ plan: PlanResponse }>) => {
    const { plan } = action.payload;
    state.Route = plan.RouteTotal;
    state.lastUpdate = Date.now();
    return state;
  },
  [trackGetProjectRoutine.FAILURE]: (state: TrackStore, action) => {
    const { error } = action.payload;
    state.error = error;
    return state;
  },
  [trackGetProjectRoutine.FULFILL]: (state: TrackStore) => {
    state.isFetching = false;
    return state;
  }
});

export default getProject;
