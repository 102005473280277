import React, { FC } from 'react';
import InboxDocumentTable from '@modules/documents/children/inbox/components/inbox-document-table';
import ViewDocumentModal from '@modules/documents/components/view-document-modal';

const ILSDocumentsInbox: FC = () => {
  return (
    <>
      <InboxDocumentTable />
      <ViewDocumentModal isInbox />
    </>
  );
};

export default ILSDocumentsInbox;
