import { EmulatorConfigVehicle, EmulatorPlanVehiclesModel } from '../../types/api';
import { VehicleTableColumnData } from '../../types/table';
import { VehicleTableRecord } from '../../types/vehicle';

// Декоратор данных для главной таблицы
export const vehicleTableDecorator = (args: { vehicles?: EmulatorPlanVehiclesModel; vehiclesData?: Array<EmulatorConfigVehicle> }) => {
  const { vehicles, vehiclesData } = args;
  const result: VehicleTableRecord[] = [];

  if (!vehicles || !Object.values(vehicles).length) {
    return result;
  }

  for (const vehicleID in vehicles) {
    const vehicleName = vehicles[vehicleID];
    const vehicleProps = vehiclesData
      ? Object.values(vehiclesData)?.find((item) => Number(item.VehicleID) === Number(vehicleID))
      : undefined;

    result.push({
      ID: Number(vehicleID),
      key: vehicleID,
      rowKey: vehicleID,
      Name: vehicleName,
      SpeedDelta: vehicleProps?.SpeedDelta,
      BDD: vehicleProps?.BDD?.join(';') || undefined,
      StartPointDelta: vehicleProps?.StartPointDelta?.toString() || '',
      NextPointsArrivalDelta: vehicleProps?.NextPointsArrivalDelta?.toString(),
      NextPointsDepartureDelta: vehicleProps?.NextPointsDepartureDelta?.toString(),
      Desequence: vehicleProps?.Desequence
    });
  }

  return result;
};

export const tableVehicleToData = (dataIndex: VehicleTableColumnData, value: any) => {
  switch (dataIndex) {
    case VehicleTableColumnData.BDD:
      return value.join(',');
    default:
      return value;
  }
};
