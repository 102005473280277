import { useEffect, useRef, useState } from 'react';
import { COLOR_BLACK } from '@common/constants/colors';
import { CellChildren } from '@common/types/components/table/cell';
import { isString } from 'lodash';

export const useCellColor = (children: CellChildren) => {
  const colorBtnRef = useRef<HTMLDivElement | null>(null);
  const [color, setColor] = useState(COLOR_BLACK);

  useEffect(() => {
    isString(children?.[1]) && setColor(children?.[1] ?? COLOR_BLACK);
  }, []);

  const handleChange = (color: { hex: string }) => {
    setColor(color.hex);
  };

  return { colorBtnRef, handleChange, color };
};
