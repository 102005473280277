import { MouseEvent } from 'react';
import { TableRecord } from '@common/types';
import { UseTableRow } from '@common/types/components/table/hooks';

export const handleRowClick =
  <R extends TableRecord>({
    onTableRowClick,
    setFocusRow,
    onFocusRow,
    tableName
  }: Pick<UseTableRow<R>, 'onTableRowClick' | 'setFocusRow' | 'onFocusRow' | 'tableName'>) =>
  (record: R, e?: MouseEvent, rowRef?: any, expand?: boolean) => {
    onTableRowClick?.(record, expand ?? false, e ? e.ctrlKey : false, tableName);
    onFocusRow({ key: record.key });
    setFocusRow?.(record);
    if (e?.ctrlKey && rowRef?.current) {
      //если зажат ctrl, выделяем строку
      const selectCol = rowRef.current.querySelector('.ant-table-selection-column');
      if (selectCol) {
        const chb = selectCol.querySelector('input[type="checkbox"]');
        if (chb) chb.click();
      }
    }
  };
