import { ILSMenuItem, ILSSubMenu } from '@components/index';
import { RenderItemProps } from '../../types/context-menu';
import classnames from 'classnames';

// Компонент подпункта контекстного меню
//NOTE RenderFunction - не использовать как компонент
export const DashboardContextMenuSubItem = ({ item, handleClick, record, index }: RenderItemProps) => {
  return (
    <ILSSubMenu popupClassName={'ils-monitoring-dashboard-sub-menu'} title={item.text} key={'menu' + 'item' + index}>
      {item?.submenu
        ?.filter((item) => item && (item.isActive?.({ record }) ?? !item?.inactive))
        ?.map((subItem: any, k: number) => {
          const onSunItemClick = () => {
            subItem.handler?.(subItem.ID, record);
            handleClick(record, item);
          };
          return (
            <ILSMenuItem
              key={index + 'subMenu' + k}
              onClick={onSunItemClick}
              className={classnames('', { 'repeated-element': subItem.repeated })}
            >
              {subItem.name}
            </ILSMenuItem>
          );
        })}
    </ILSSubMenu>
  );
};

