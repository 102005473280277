import { Key } from '../general';
import { AlertNotify } from '@common/types/general/conditions';
import { PlanningValidationType } from '@modules/planning/types/planning';
import { Provider, TableColumnName } from '@common/types';

//todo refactoring validation

export interface ValidationData {
  Kind_: AlertNotify | ValidationKind; //NOTE: Тип ошибки (Err,)
  Name_: string; //NOTE: Название ошибки
  Fields_: string[]; //NOTE: Массив полей, в которых есть ошибки (['LatLon', 'Latitude', 'Longitude'])
  Tip: string; //NOTE: Описание ошибки
  Sect_: string; // NOTE: dictionary (Depot,Vehicle...)
  Layout_: string;
  ColForCode_: string;
  id: string;
  CanFocus: number;
  Chk_: number; //NOTE: Количество проверенных строк
  Codes_: string;
  Err_?: number; //NOTE: Количество строк содержащих ошибку
  Wrn_?: number; //NOTE: Количество строк содержащих предупреждение
  Inf_?: number; //NOTE: Количество строк содержащих информацию
  Bad_?: number; //NOTE: Количество критических ошибок
  Codes_Tip: string; //NOTE: Всплывающая подсказка с идентификаторами
  Type?: AlertNotify;
  Count?: number;
  key: Key;
  FailKeys?: string[]; //NOTE: Массив ID, в которых есть ошибки (['607192', '612216'])
  FailFieldName?: TableColumnName;
  FailRows?: number[] | null;
}

export enum ValidationKind {
  error = 'Err',
  bad = 'Bad',
  warning = 'Wrn',
  info = 'Inf'
}

export enum ValidationErrors {
  Err = 'error',
  Bad = 'bad',
  Wrn = 'warning',
  Inf = 'info'
}

export enum ValidationTable {
  Vehicle = 'Vehicle',
  Driver = 'Driver',
  Depot = 'Depot',
  Order = 'Order',
  Common = 'Common',
  ServicesAvailable = 'ServicesAvailable',
  Distances = 'Distances',
  Config = 'Config'
}

export enum ValidationField {
  Vehicle = 'Vehicle',
  Driver = 'Driver',
  Depot = 'Depot',
  Order = 'Order',
  ServicesAvailable = 'ServicesAvailable',
  Distances = 'Distances'
}

export type ValidationFields = Record<ValidationField, Array<ValidationData>>;
export type CommonValidationField = ValidationFields;
export type AllValidationFields = Record<ValidationTable, Array<ValidationData> | CommonValidationField>;
export type TErrorValidationTable = keyof typeof ValidationTable;
export type TActiveValidationTable = {
  table: TErrorValidationTable | null | undefined;
  kind: AlertNotify | null | undefined;
  errorItem: ValidationData | null | undefined;
};

export type TChangeActiveValidationTable = (
  table: TErrorValidationTable | null | undefined,
  kind: AlertNotify | null | undefined,
  errorItem: ValidationData | null | undefined
) => void;

export type TErrorValidationItem = { Kind?: AlertNotify; Type: AlertNotify; Count?: number; Field?: Record<string, number> };

export interface IDecorateValidationData {
  Vehicle?: ValidationData[];
  Depot?: ValidationData[];
  Driver?: ValidationData[];
  Order?: ValidationData[];
  Restriction?: ValidationData[];
  PermitZone?: ValidationData[];
  FriendZone?: ValidationData[];
  Common: ValidationData[];
  CountAll: {
    error: number;
    warning: number;
    info: number;
    bad: number;
  };
  Config?: {
    planConfigID?: string;
    providerName?: Provider;
    validationType?: PlanningValidationType;
  } | null;
}
