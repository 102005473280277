import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CatalogStateType } from '../types/store';
import { Dictionary, ErrorAPI } from '@common/types';
import initialState from '@modules/catalog/store';
import { catalogCreateRoutine } from '@modules/catalog/actions';
import { DataType, ICatalogDataItem, SaveDictionaryType } from '@modules/catalog/types/catalog';
import { onFulfill, onRequest } from '@modules/catalog/reducers/helpers';

const createDictionaryReducer = createReducer(initialState, {
  [catalogCreateRoutine.REQUEST]: onRequest,
  [catalogCreateRoutine.SUCCESS]: (
    state: CatalogStateType,
    action: PayloadAction<{
      data: DataType;
      dictionary: Dictionary;
      saveDictionary: SaveDictionaryType;
    }>
  ) => {
    let { data, dictionary, saveDictionary } = action.payload;
    dictionary = saveDictionary ? saveDictionary.parentDict : dictionary;
    // todo бэк не должен при create возвращать все зоны, это временный костыль
    if (dictionary === Dictionary.ForbiddenZone) {
      const zone = Object.values(data);
      const newZone = zone.pop();
      const newRow = { ...newZone, key: newZone?.ID };
      if (newRow) {
        // @ts-ignore
        state[dictionary].data = zone.concat(newRow);
      }
    } else {
      const [item] = data;
      const newItem = { ...item, key: item.ID };
      let oldData = state[dictionary].data;
      if (saveDictionary) {
        //для добавления записи в родительский справочник
        oldData = oldData.map((item: ICatalogDataItem) => {
          if (item.ID === saveDictionary.recordID) {
            item[saveDictionary.childDict].push(newItem);
          }
          return item;
        });
      } else {
        oldData.push(newItem);
      }
      state[dictionary].data = oldData;
    }
    return state;
  },
  [catalogCreateRoutine.FAILURE]: (
    state: CatalogStateType,
    action: PayloadAction<{
      error: ErrorAPI;
      dictionary: Dictionary;
    }>
  ) => {
    const { error, dictionary } = action.payload;
    state[dictionary].error = error;
    return state;
  },
  [catalogCreateRoutine.FULFILL]: onFulfill
});

export default createDictionaryReducer;
