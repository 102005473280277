import { countDecimal } from '@modules/analytics/common/utils/count-decimal';
import { number2FormattedString } from '@common/utils/helpers/numbers/number-to-formated-string';

export const infoValueToString = (value: string | number | undefined | null): string => {
  if (typeof value === 'string') {
    return value;
  }
  return value?.toString() ?? ''
  // const floatValue = parseFloat(value + '');

  // if (isNaN(floatValue)) {
  //   return value?.toString() || '';
  // } else {
  //   const fixed = countDecimal(parseFloat(value + '') || 0);
  //   return number2FormattedString(parseFloat(value + ''), ' ', fixed);
  // }
};
