import { FC } from 'react';
import { ILSSelect } from '@common/components';
import { USER_ROLE_OPTIONS } from '@modules/account/constants';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { BASE_FIELD_NAMES } from '@common/constants/options';

interface IProps extends IILSSelectProps {}

export const UserRolesSelect: FC<IProps> = ({ options, ...props }) => {
  return (
    <ILSSelect
      mode="multiple"
      allowClear
      placeholder="Выберите роли"
      options={options ?? USER_ROLE_OPTIONS}
      fieldNames={BASE_FIELD_NAMES}
      {...props}
    />
  );
};
