import { IILSTablePlanningComponentProps, PlannerPlanningTablesContainer } from '../../containers/result/tables/table';
import { useAppSelector } from '@core/hooks';
import { projectCommonDictionarySelector, projectDepotSelector } from '@modules/planning/children/by-date/selectors/project';
import { tableCustom } from '@core/containers/table-custom';
import { depot2table } from '@common/models/depot/decorator';
import { WorktimeTable } from './worktime';
import { DepotOverloadTable } from './depot-overload';
import { Dictionary, IILSTableProps, ReferenceTablesData, TableRecord } from '@common/types';
import { FC } from 'react';
import { table2data } from '@components/data-display/table/utils/decorators/table-to-data';
import { depotConfigDec } from '@modules/planning/children/by-date/decorators/table-config/table-config';
import { depotConfigForValidate } from '@common/models/depot/config';
import { bindTimeZoneForDepotsRoutine } from '@modules/planning/children/by-date/actions';
import { useDispatch, useSelector } from 'react-redux';
import { handleCoordinates } from '@modules/planning/children/by-date/helpers/tables/handlers/handle-coordinates';
import { createArrayFromIndexedArray } from '@common/utils';
import { canEditDepot } from '@common/models/depot/helpers/can-edit-depot';

const PlanningDepot: FC<IILSTablePlanningComponentProps> = (props) => {
  const dispatch = useDispatch();
  const depots = useSelector(projectDepotSelector);
  const { Worktime, DepotOverload, Contact, Restriction } = useAppSelector((state) =>
    projectCommonDictionarySelector(state, [Dictionary.Worktime, Dictionary.DepotOverload, Dictionary.Contact, Dictionary.Restriction])
  );

  const refData: ReferenceTablesData = {
    ContactID: Contact,
    WorktimeID: Worktime,
    DepotOverloadID: DepotOverload,
    RestrictionID: Restriction
  };

  const refTable = {
    WorktimeID: {
      title: 'Режим работы',
      component: WorktimeTable
    },
    DepotOverloadID: (record: TableRecord) => {
      return {
        title: 'Превышения',
        component: DepotOverloadTable,
        record
      };
    }
  };

  const handleTableBindTimeZone: IILSTableProps<TableRecord>['handleBindTimeZone'] = (ids) =>
    dispatch(bindTimeZoneForDepotsRoutine({ ids }));

  const handleCheckCoordinates = handleCoordinates({
    dispatch,
    dataSource: createArrayFromIndexedArray(depots),
    dictionary: Dictionary.Depot
  });

  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={props.config}
      refData={refData}
      onCanEdit={canEditDepot}
      // @ts-ignore
      refTable={refTable}
      handleCheckCoords={handleCheckCoordinates}
      handleTableBindTimeZone={handleTableBindTimeZone}
      dataDecorator={depot2table}
      handleDecorator={table2data}
      dictionary={Dictionary.Depot}
    />
  );
};
export const DEPOT_PLANNER_TABLE_CONFIG = depotConfigDec(depotConfigForValidate);

export const ILSPlanningDepot = tableCustom(PlanningDepot, DEPOT_PLANNER_TABLE_CONFIG, Dictionary.Depot);
