import { FC } from 'react';
import { PlanPenalty } from '@common/types';
import { number2FormattedString } from '@common/utils/helpers/numbers/number-to-formated-string';
import { EMPTY_CELL, JoinChar } from '@common/constants';

interface PenaltyTipProps {
  penalty: PlanPenalty[];
  isParted?: boolean;
  total?: number;
}

export const PenaltyTip: FC<PenaltyTipProps> = ({ penalty, isParted, total = 0 }) => {
  let summary = 0;
  return (
    <div className="penalty-window">
      <div className="row">
        <div className="result">Результат</div>
        <div className="coef">Коэф-т</div>
        <div className="value">Значение</div>
        <div className="desc">Описание</div>
      </div>
      {penalty?.map(({ Coefficient, Value, StringKey, ID }) => {
        const result = Coefficient * Value;
        summary += result;
        return Boolean(Coefficient) && Boolean(Value) ? (
          <div className="row" key={ID}>
            <div className="result">+ {result.toFixed(2)}</div>
            <div className="coef">= {Coefficient.toFixed(2)}</div>
            <div className="value">* {Value.toFixed(2)}</div>
            <div className="desc">{StringKey}</div>
          </div>
        ) : (
          EMPTY_CELL
        );
      })}
      {isParted && (
        <>
          <div className={'row'}>...</div>
          <div className={'row'}>...</div>
        </>
      )}
      <div className="row">
        <div className="result">
          <b>= {isParted ? number2FormattedString(total, JoinChar.Space, 2) : summary.toFixed(2)}</b>
        </div>
        <div className="coef" />
        <div className="value" />
        <div className="desc">
          <b>Итоговая стоимость</b>
        </div>
      </div>
    </div>
  );
};
