import { ILSTypographyText } from '@common/components';
import { DEFAULT_FILE_ICON, DOCUMENTS_EXTENSION } from '@common/constants/general/path';
import { getFileExtension } from '@common/utils/general';
import { TENDER_FORM_TABLE_FIELDS_PROPERTIES, TenderFormTableField } from '@modules/tender-platform/constants/content';
import { validateCustomFieldValue } from '@modules/tender-platform/utils/helpers/form/validate-custom-field-value';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

export const renderInViewMode = ({
  value,
  field,
  toggleEdit,
  fileList
}: {
  field: TenderFormTableField;
  value: string;
  toggleEdit: () => void;
  fileList: UploadFile[];
}) => {
  switch (field) {
    case TenderFormTableField.Documents:
      const isFileList = Boolean(fileList?.length);
      return (
        <div onClick={toggleEdit} className={'tender-from-planner-form-table-fieldset-row tender-from-planner-form-table-filelist'}>
          {isFileList ? (
            fileList?.map((file) => {
              const fileExtension = getFileExtension(file?.name);
              const icon = fileExtension && fileExtension in DOCUMENTS_EXTENSION ? fileExtension : DEFAULT_FILE_ICON;
              return (
                <p key={`${file.uid}`} className="ant-upload-hint">
                  <ReactSVG src={`/assets/icons/filetype/${icon}.svg`} />
                  {file.name}
                </p>
              );
            })
          ) : (
            <div className={'tender-from-planner-form-table-filelist-empty'}>Загрузить документы</div>
          )}
        </div>
      );
    default:
      return (
        <ILSTypographyText
          className={classNames('ant-input tender-from-planner-form-table-fieldset-row input', field, {
            required: validateCustomFieldValue({ value, field }) && TENDER_FORM_TABLE_FIELDS_PROPERTIES?.[field]?.required
          })}
          onClick={toggleEdit}
          children={value}
        />
      );
  }
};
