import { ILSFormItem, ILSTooltip, ILSTypographyText } from '@common/components';
import { RecordType, SchemeSwitchDictionary } from '@modules/planning/children/scheme-editor/types';
import { FC } from 'react';
import { SchemeTypeSelect } from '@modules/planning/children/scheme-editor/components/scheme-type-select';

interface IProps {
  item: RecordType;
  scheme: SchemeSwitchDictionary;
}

export const SchemeCard: FC<IProps> = ({ scheme, item: { title, options, description, key } }) => {
  return (
    <>
      <ILSTooltip title={title}>
        <ILSTypographyText className={'scheme-editor-scheme-input-title'} children={key} />
      </ILSTooltip>
      <ILSFormItem name={[scheme, key, 'type']}>
        <SchemeTypeSelect className={'scheme-editor-scheme-input-select'} dropdownMatchSelectWidth={false} options={options} />
      </ILSFormItem>
      <ILSTooltip title={description}>
        <ILSTypographyText ellipsis>{description}</ILSTypographyText>
      </ILSTooltip>
    </>
  );
};
