import { ChangeEvent } from 'react';
import { EMPTY_STRING } from '@common/constants';

export const formatNumberInput = (e: ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;
  if (!value) {
    return EMPTY_STRING;
  }
  const onlyNumerical = value.replace(/[^0-9]/g, EMPTY_STRING);
  const numberValue = Number(onlyNumerical.replace(/\s/g, EMPTY_STRING));

  return numberValue ? numberValue.toLocaleString('ru-RU') : EMPTY_STRING;
};
