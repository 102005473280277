import { documentGetDependentListRoutine } from '@modules/documents/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Documents } from '@modules/documents/api';
import { IDependentListPayload } from '@modules/documents/types/document-type';

function* documentGetDependentListWorker({ payload: { type, projectId, setId } }: { payload: IDependentListPayload }) {
  const { request, success, failure, fulfill } = documentGetDependentListRoutine;

  try {
    yield put(request());

    const { data } = yield call(Documents.getDependentList, { type, projectId, setId });

    yield put(success(data.data));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* documentGetDependentListWatcher() {
  yield takeEvery(documentGetDependentListRoutine.trigger, documentGetDependentListWorker);
}
