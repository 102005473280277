import { createReducer } from '@reduxjs/toolkit';
import { AnalyticsStore } from '../types/store';
import initialState from '@modules/analytics/common/store';
import { AnalyticsActions } from '../types/actions';

export const analyticsCancelWidgetsUpdateReducer = (actions: AnalyticsActions) =>
  createReducer<AnalyticsStore>(initialState as AnalyticsStore, {
    [actions.analyticsCommonCancelWidgetUpdatesRoutine.REQUEST]: (state) => {
      return state;
    },
    [actions.analyticsCommonCancelWidgetUpdatesRoutine.SUCCESS]: (state) => {
      const newWidgets = Object.values(state.Widgets).filter((x) => !x.isNew);
      state.Widgets = newWidgets.reduce((prev, cur) => {
        prev[cur.ID] = { ...cur, isDeleted: false };
        return prev;
      }, {});
      return state;
    },
    [actions.analyticsCommonCancelWidgetUpdatesRoutine.FAILURE]: (state) => {
      return state;
    },
    [actions.analyticsCommonCancelWidgetUpdatesRoutine.FULFILL]: (state) => {
      return state;
    }
  });
