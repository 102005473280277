import { CellType, ColumnTable, TableRecord } from '@common/types';

export const optionsColumn: ColumnTable<TableRecord> = {
  titleIcon: 'options',
  dataIndex: 'Button',
  type: CellType.ButtonClickableDropdown,
  width: 40,
  align: 'left',
  isFixedColumn: true,
  active: true,
  dontHide: true
};

export const defaultTableColumns: ColumnTable<TableRecord>[] = [
  {
    titleIcon: 'options',
    dataIndex: 'Button',
    key: 'Button',
    type: CellType.ButtonClickableDropdown,
    align: 'center',
    isFixedColumn: true,
    active: true,
    dontHide: true,
    width: 40
  },
  {
    title: 'ID документа',
    dataIndex: 'ID',
    key: 'ID',
    type: CellType.Input,
    active: true,
    isFixedColumn: true
  }
];
