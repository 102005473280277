import { ILSModal, ILSRadioGroup } from '@components/index';
import { FC } from 'react';
import { ModalProps } from 'antd';
import { ID, Project } from '@common/types';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

interface IChooseProjectModalProps extends Pick<ModalProps, 'onOk' | 'onCancel' | 'visible'> {
  list: Array<Project>;
  selected?: ID;

  onChange(ID: number): void;
}

export const ILSChooseProjectModal: FC<IChooseProjectModalProps> = ({ visible, onOk, onCancel, onChange, list, selected }) => {
  const options = list.map(({ Name, ID }) => {
    return { value: ID, label: `${Name || `№${ID}`} (${ID})` };
  });

  const [{ value }] = options;
  const handleChange = (e: RadioChangeEvent) => onChange(e.target.value);

  return (
    <ILSModal className={'choose-project-modal'} title="Выберите проект" visible={visible} onOk={onOk} onCancel={onCancel}>
      <ILSRadioGroup value={selected ?? value} className={'choose-project-radio'} options={options} onChange={handleChange} />
    </ILSModal>
  );
};
