import { call, put, takeLatest } from 'redux-saga/effects';
import { organizationTypesRoutine } from '@modules/account/actions';
import { DictionariesEndpoint } from '@common/types';
import { AccountAPI } from '@modules/account/api/account';

export function* organizationTypesWorker() {
  const { failure, fulfill, success } = organizationTypesRoutine;

  try {
    const {
      data: { data }
    } = yield call(AccountAPI.accountOperation, { operation: DictionariesEndpoint.OrganizationTypes });
    yield put(success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* organizationTypesWatcher() {
  yield takeLatest(organizationTypesRoutine.trigger, organizationTypesWorker);
}
