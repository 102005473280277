import { useDispatch, useSelector } from 'react-redux';
import { planningConfigurationSelector } from '@modules/planning/children/by-date/selectors/config';
import { useEffect } from 'react';
import { getActivePlanConfig } from '@core/utils/get-active-plan-config';
import { planningSetConfigRoutine } from '@modules/planning/children/by-date/actions';
import { activePlanIDSelector, activePlanSelector } from '@modules/planning/children/by-date/selectors/active-plan';

export const usePlanningConfig = () => {
  const dispatch = useDispatch();

  const activePlanID = useSelector(activePlanIDSelector);
  const activePlan = useSelector(activePlanSelector);
  const { planningConfigByPlan, defaultPlannerConfigs } = useSelector(planningConfigurationSelector);

  /** При изменении активного плана, формируем новые настройки для валидации и планирования */
  useEffect(() => {
    if (activePlanID && defaultPlannerConfigs.data) {
      const config = getActivePlanConfig(activePlan, activePlanID, planningConfigByPlan, defaultPlannerConfigs);
      dispatch(
        planningSetConfigRoutine({
          planID: activePlanID,
          config
        })
      );
    }
  }, [activePlanID, defaultPlannerConfigs]);
};
