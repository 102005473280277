import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { planningCheckRoutine } from '@modules/planning/children/by-date/actions';
import { IPlanningByDateStore } from '../../types/store';
import { initialState } from '@modules/planning/children/by-date/store';
import { onFailure } from '@common/utils/helpers/reducers';
import { deepEqual } from 'fast-equals';

export const checkPlanningReducer = createReducer(initialState, {
  [planningCheckRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      checkData: IPlanningByDateStore['planningCheckData'];
    }>
  ) => {
    const { checkData } = action.payload;
    if (deepEqual(state.planningCheckData, checkData)) {
      return state;
    }
    state.planningCheckData = checkData;
    return state;
  },
  [planningCheckRoutine.FAILURE]: onFailure
});
