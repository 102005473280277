import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTripDocumentsRoutine } from '@modules/orders-and-trips/actions';
import { OrdersNTripsApi } from '@modules/orders-and-trips/api';
import { DocumentDependentType } from '@modules/orders-and-trips/types/documents';

function* getTripDocumentsWorker(
  action: PayloadAction<{
    tripID: number;
    dependent: DocumentDependentType;
  }>
) {
  const { request, success, failure, fulfill } = getTripDocumentsRoutine;
  const { tripID, dependent } = action.payload;
  try {
    yield put(request());
    const {
      data: { data }
    } = yield call(OrdersNTripsApi.getTripDocuments, { dependentId: tripID, dependent });
    yield put(success({ data, tripID: tripID.toString() }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getTripDocumentsWatcher() {
  yield takeLatest(getTripDocumentsRoutine.trigger, getTripDocumentsWorker);
}

