import { Worktime } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { WORKTIME } from '@common/models/worktime';
import { DEFAULT_WORKTIME_NAME } from '@common/models/worktime/constants';

export const worktime2table = (worktime: Array<Worktime>) =>
  createArrayFromIndexedArray(worktime).map((time, i) => {
    return {
      ...time,
      key: time.ID ?? 'row' + i,
      Name: Boolean(time.Name)
        ? time.Name === DEFAULT_WORKTIME_NAME
          ? `${DEFAULT_WORKTIME_NAME} №${time.ID}`
          : time.Name
        : `${WORKTIME} №${time.ID}`
    };
  });
