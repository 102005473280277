import { createSelector } from 'reselect';
import { State } from '../types/state';
import { AccountRole, UserAuth } from '@common/types';

export const currentUserSelector = createSelector(
  (state: State) => state.Auth.user,
  (user: UserAuth) => user
);

export const currentUserErrorSelector = createSelector(
  (state: State) => state.Auth.error,
  (error) => error
);

export const fetchSelector = createSelector(
  (state: State) => state.Auth.isFetching,
  (isFetching) => isFetching
);

export const notificationSelector = createSelector(
  (state: State) => state.Auth.notification,
  (notification) => notification
);

export const requestActionSelector = createSelector(
  (state: State) => state.Auth.requestAction,
  (requestAction) => requestAction
);

export const accountRolesSelector = createSelector(
  (state: State) => state.Auth.accountRoles,
  (accountRoles) => accountRoles
);
export const accountRoleSelector = createSelector(
  (state: State) => state.Auth.user.Account?.AccountRole ?? [],
  (AccountRole) => AccountRole
);

export const hasPermissionsForTenderPlatformSelector = createSelector(accountRoleSelector, (roles) =>
  roles?.includes(AccountRole.cargoOwner)
);

export const tokenSelector = createSelector(
  (state: State) => state.Auth.checkToken,
  (token) => token
);

export const accountSelector = createSelector(
  (state: State) => state.Auth.account,
  (account) => account
);
