import { createSelector } from 'reselect';
import { Document } from '@modules/documents/types/document';
import { FormField } from '@modules/documents/children/upload/types/form-field';
import { dealsTableConfig } from '@modules/documents/table-config/deals-table-config';
import { generateColumns } from '@modules/documents/helpers/generate-columns';
import { defaultTableColumns } from '@modules/documents/constants/options-column';
import { DocumentType, ReportList } from '@modules/documents/types/document-type';
import {
  DocEnum,
  DocumentsState,
  DocumentState,
  IDependentList,
  InboxSendDocument,
  TClearTrashMode
} from '@modules/documents/types/document-state';
import { addFixedWidthToColumns } from '../utils/add-fixed-width-to-columns';
import { DocumentTemplate } from '@modules/documents/children/templates/types/api';

export const getDocumentsTemplates = createSelector(
  (state: DocumentsState) => state.Documents.templates,
  (templates: DocumentTemplate[]) => templates
);

export const getDocumentsDependentList = createSelector(
  (state: any) => state.Documents.dependentList,
  (dependentList: IDependentList | IDependentList[] | null) => dependentList
);

export const getAllDocumentsSelector = createSelector(
  (state: any) => state.Documents.data,
  (data: Document[]) => data
);

export const getFilterFields = createSelector(
  (state: any) => state.Documents.fields,
  (fields: FormField[]) => fields?.filter((f) => f.filtering)
);

export const getDocumentType = createSelector(
  (state: any) => state.Documents.documentType,
  (documentType: DocumentType) => documentType
);

export const getDocumentState = createSelector(
  (state: DocumentsState) => state.Documents.documentState,
  (documentState: DocumentState) => documentState
);

export const getTableConfigSelector = createSelector(
  (state: DocumentsState) => state.Documents.fields,
  (fields: FormField[]) => {
    const columns = fields?.length ? addFixedWidthToColumns(generateColumns(fields)) : dealsTableConfig.columns;
    return {
      columns: [...defaultTableColumns, ...columns],
      hideClearButtons: true,
      externalFocusControl: true
    };
  }
);

export const getIsFetchingSelector = createSelector(
  (state: DocumentsState) => state.Documents.isFetching,
  (isFetching: boolean) => isFetching
);

export const getMetricsIsFetchingSelector = createSelector(
  (state: DocumentsState) => state.Documents.metrics.isFetching,
  (isFetching: boolean) => isFetching
);

export const getIsDocumentFetching = createSelector(
  (state: DocumentsState) => state.Documents.isDocumentFetching,
  (isDocumentFetching: boolean) => isDocumentFetching
);

export const getCurrentDocumentState = createSelector(
  (state: DocumentsState) => state.Documents.currentDocument,
  (currentDocument: null | Document) => currentDocument
);

export const getIsViewDocumentModelOpen = createSelector(
  (state: DocumentsState) => state.Documents.isViewDocumentModelOpen,
  (isViewDocumentModelOpen: boolean) => isViewDocumentModelOpen
);

export const getDocumentsErrors = createSelector(
  (state: DocumentsState) => state.Documents.error,
  (error: string[]) => error
);

export const getClearTrashMode = createSelector(
  (state: DocumentsState) => state.Documents.clearTrashMode,
  (clearTrashMode: TClearTrashMode | null) => clearTrashMode
);

export const getSuggestContractor = createSelector(
  (state: DocumentsState) => state.Documents.contractors,
  (contractors) => contractors
);

export const getInboxSendDocuments = createSelector(
  (state: DocumentsState) => state.Documents.inboxSendDocuments,
  (inboxSendDocuments: { data: InboxSendDocument[]; isFetching: boolean }) => inboxSendDocuments
);
export const getDocumentsEnum = createSelector(
  (state: any) => state.Documents.inboxSendDocuments.docEnum,
  (docEnum: DocEnum[]) => docEnum
);

export const reportsListSelector = createSelector(
  (state: DocumentsState) => state.Documents.reportList,
  (reportsList: ReportList) => reportsList
);

export const reportListOptionsSelector = createSelector(reportsListSelector, (reportsList) => {
  return Object.entries(reportsList).map(([alias, name]) => {
    return { alias, name };
  });
});
