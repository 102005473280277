import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@modules/monitoring/store';
import { DictionariesEndpoint } from '@common/types';
import { ForwarderPointStatusesResponse } from '@modules/settings/types';
import { monitoringForwarderPointStatusesRoutine } from '@modules/monitoring/actions';
import { MonitoringState } from '@modules/monitoring/types/store';

export const monitoringStatusesReducer = createReducer<MonitoringState>(initialState, {
  [monitoringForwarderPointStatusesRoutine.REQUEST]: (state) => {
    return state;
  },
  [monitoringForwarderPointStatusesRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{ data: ForwarderPointStatusesResponse; operation: DictionariesEndpoint }>
  ) => {
    const { data } = action.payload;
    state.forwarderPointStatuses = data;
    return state;
  },
  [monitoringForwarderPointStatusesRoutine.FAILURE]: (state) => {
    return state;
  },
  [monitoringForwarderPointStatusesRoutine.FULFILL]: (state) => {
    return state;
  }
});
