import { useEffect, useState } from 'react';
import { StimulsoftTemplateData } from '@common/types/general/stimulsoft';
import { generateStimulsoftReportFromJson } from '@common/utils/stimulsoft/generate-stimulsoft-report-from-json';

declare var Stimulsoft: any;

interface IProps {
  template: StimulsoftTemplateData;
  viewerID: string;
  isFetching: boolean;
  height?: string;
}

export const useStimulsoftInit = ({ template, viewerID, isFetching, height = '70vh' }: IProps) => {
  const [report, setReport] = useState<StimulsoftTemplateData>();
  const uniqueViewerID = `${viewerID}-${Date.now()}`;

  const createViewer = () => {
    const options = new Stimulsoft.Viewer.StiViewerOptions();
    options.toolbar.showDesignButton = false;
    options.appearance.scrollbarsMode = true;
    options.height = height;
    options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Div;
    const viewerInst = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    viewerInst.renderHtml(uniqueViewerID);
    viewerInst.report = report;
  };

  useEffect(() => {
    Stimulsoft.Base.StiLicense.loadFromFile('/assets/scripts/stimulsoft/license.key');
    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/scripts/stimulsoft/ru.xml');
  }, []);

  useEffect(() => {
    if (!template) return;
    setReport(generateStimulsoftReportFromJson(template));
  }, [isFetching]);

  useEffect(() => {
    if (!report) return;
    createViewer();
  }, [report, isFetching]);

  return { StimulSoftViewer: () => <div id={uniqueViewerID} key={uniqueViewerID} /> };
};

