export enum ButtonContent {
  Cancel = 'Отмена',
  Add = 'Добавить',
  Save = 'Сохранить',
  Back = 'Назад',
  reset = 'Очистить',
  Send = 'Отправить'
}

export enum MessageContent {
  NotFound = 'Ничего не найдено',
  SomethingWentWrong = 'Что-то пошло не так...'
}

export const NO_DESCRIPTION = 'Нет описания';
export const NOT_ENTERED = 'Информация не внесена';
