import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { selectOneVehicleRoutine } from '../actions';
import { monitoringDateSelector } from '@modules/monitoring/selectors';
import { monitoringMetricsRoutine } from '@modules/monitoring/actions';
import { SelectOneVehicleMetricsRequest } from '../types/api';

// Получает данные для одного автомобиля
function* selectOneVehicle(action: PayloadAction<SelectOneVehicleMetricsRequest>) {
  const { vehicleID } = action.payload;
  const { success, failure } = selectOneVehicleRoutine;
  try {
    const { startDate, endDate } = yield select(monitoringDateSelector);
    yield put(success({ vehicleID }));
    if (startDate && endDate && vehicleID) {
      yield put(monitoringMetricsRoutine({ from: startDate, till: endDate, selectedVehicleId: vehicleID }));
    } else if (startDate && endDate) {
      yield put(monitoringMetricsRoutine({ from: startDate, till: endDate }));
    }
  } catch (error) {
    console.error(error);
    yield put(failure());
  }
}

export function* selectOneVehicleWatcher() {
  yield takeLatest(selectOneVehicleRoutine.trigger, selectOneVehicle);
}
