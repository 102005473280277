import { DEFAULT_ORDER_COLOR } from '@common/constants/colors/order';
import { int2color } from '@common/decorators/color';
import { Depot, DepotTypeId, IndexedArray, TableColumnName } from '@common/types';
import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { compareAsString } from '@common/utils/helpers/comparators/compare-as-string';
import { isNil } from 'lodash';

export const depot2table = (depots: Array<Depot>) => {
  return createArrayFromIndexedArray(depots).map((depot) => {
    return {
      ...depot,
      key: depot.ID,
      //todo helper
      [TableColumnName.ContactID]: depot.ContactEnum && Object.keys(depot.ContactEnum).map(Number),
      [TableColumnName.DepotOverloadUnloadStartOverload]: depot?.DepotOverload?.UnloadStartOverload,
      [TableColumnName.DepotOverloadUnloadEndOverload]: depot?.DepotOverload?.UnloadEndOverload,
      [TableColumnName.DepotOverloadLoadStartOverload]: depot?.DepotOverload?.LoadStartOverload,
      [TableColumnName.DepotOverloadLoadEndOverload]: depot?.DepotOverload?.LoadEndOverload,
      //todo helper
      [TableColumnName.TripTemplateID]: depot.TripTemplate?.map(({ ID }) => ID),
      TripTemplateEnum: depot.TripTemplate?.reduce((prev, { ID, Name }) => {
        prev[ID] = Name;
        return prev;
      }, {}),
      //todo helper
      [TableColumnName.RestrictionID]: depot.Restriction?.map(({ ID }) => ID),
      RestrictionEnum: depot.Restriction?.reduce((prev, { ID, Name }) => {
        prev[ID] = Name;
        return prev;
      }, {}),
      Color: isNil(depot.Color) ? DEFAULT_ORDER_COLOR : int2color(String(depot.Color))
    };
  });
};

export const getOnlyStorages = (depots: Array<Depot> | IndexedArray<Depot> | undefined | null) => {
  return createArrayFromIndexedArray(depots).filter(({ DepotTypeID }) => compareAsString(DepotTypeID, DepotTypeId.Storage));
};

