import { isEmpty, isNil } from 'lodash';
import { EMPTY_ARRAY_DATA } from '@common/constants';
import { Driver } from '@common/types';
import { ColumnOptionFunction, TableRecord } from '@common/types/components/table/common';
import { compareAsString } from '@common/utils';
/**
 * Функция фильтрует водителей для ячейки select. Если у автомобиля выбрана транспортная компания,
 * то функция возвращает водителей только этой транспортной компании.
 * Если не выбрана транспортная компания, то возвращаются только свои водители, у который не назначена транспортная компания.
 * @param driverOptions - водители;
 * @param record - данные строки;
 * @return - Array<Driver> | EMPTY_ARRAY
 */

export const filterDriverOptionsByTransportCompany =
  (driverOptions: Array<Driver> = []): ColumnOptionFunction =>
  (record: TableRecord) => {
    if (isEmpty(driverOptions)) return EMPTY_ARRAY_DATA;

    return (
      driverOptions.filter((driver) =>
        record.TransportCompanyID ? compareAsString(driver.TransportCompanyID, record.TransportCompanyID) : isNil(driver.TransportCompanyID)
      ) ?? EMPTY_ARRAY_DATA
    );
  };

