import { FC } from 'react';
import { ILSButtonTooltip } from '@components/index';
import ILSDocumentsFormDocumentModal from '@modules/documents/components/form-document-modal';
import { LayoutOutlined } from '@ant-design/icons/lib/icons';
import { Project } from '@common/types';
import { useModal } from '@common/hooks/components/use-modal';
import { ReportsListRequest } from '@modules/documents/types/documents-api';

interface IProps {
  module: ReportsListRequest['module'];
  projectID?: Project['ID'];
}

export const DocumentsModalButton: FC<IProps> = ({ projectID, module }) => {
  const { visible, handleShow } = useModal();

  return (
    <>
      <ILSButtonTooltip
        title={'Открыть модальное окно для формирования документов'}
        onClick={handleShow}
        icon={<LayoutOutlined className={'ils-svg primary'} />}
      />
      {visible && <ILSDocumentsFormDocumentModal open={visible} handleClose={handleShow} projectId={projectID} module={module} />}
    </>
  );
};
