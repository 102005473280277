import { Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { ILSRampTypePlanningTable } from '@modules/planning/children/by-date/components/tables/ramp-type';
import {
  IILSTablePlanningComponentProps,
  PlannerPlanningTablesContainer
} from '@modules/planning/children/by-date/containers/result/tables/table';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { planningRampTableComponentSelector } from '@modules/planning/children/by-date/selectors/components/tables/ramp';
import { ramp2table, table2ramp } from '@common/models/ramp/decorators';
import { RAMP_TABLE_CONFIG } from '@common/models/ramp';

export const REFERENCE_TABLES = {
  RampTypeID: {
    title: 'Типы рамп (YMS)',
    dictionary: Dictionary.RampType,
    component: ILSRampTypePlanningTable
  }
};

const PlanningRamp: FC<IILSTablePlanningComponentProps> = memo((props) => {
  const { refData } = useSelector(planningRampTableComponentSelector);

  return (
    <PlannerPlanningTablesContainer
      {...props}
      config={props.config}
      refTable={REFERENCE_TABLES}
      refData={refData}
      dataDecorator={ramp2table}
      handleDecorator={table2ramp}
      dictionary={Dictionary.Ramp}
      className="result-table"
    />
  );
});

export const ILSRampPlanningTable = memo(tableCustom(PlanningRamp, RAMP_TABLE_CONFIG, Dictionary.Ramp));
