import { ILSTable } from '@common/components';
import { tableBrokenSequenceConfig, tableBrokenSequenceDataToTable } from '@common/table-configs/monitoring-dashboard/broken-sequence';
import { ColumnTable, Dictionary, IndexedArray, RecordTableButton, VehicleTracker } from '@common/types';
import { MonitoringBrokenSequenceDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { tableCustom } from '@core/containers/table-custom';
import { MonitoringExelData } from '@modules/monitoring/utils/create-exel-data';
import { FC, useState } from 'react';
import { ILSDashboardViewedButtonComponent } from '../../components/viewed-button';
import { addToTableExelButton } from '../../utils/exel-button';
import { addToTableShowViewedButton, hasUnviewed, tableViewedFilter } from '../../utils/viewed-button';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { MonitoringTableTopLayout } from '@modules/monitoring/children/dashboard/tables/components/monitoring-table-top-layout';
import isEmpty from 'lodash/isEmpty';
import { useFocusRow } from '@components/data-display/table/hooks/row/use-focus-row';

interface IProps {
  dataSource: Array<MonitoringBrokenSequenceDataModel> | IndexedArray<MonitoringBrokenSequenceDataModel>;
  onContextOpen?: (record: MonitoringBrokenSequenceDataModel, e: MouseEvent) => void;
  columns: ColumnTable<MonitoringBrokenSequenceDataModel>[];
  recordTableButtons?: Array<RecordTableButton>;
  loadExel: (exelData: MonitoringExelData | undefined, fileName: string) => void;
  handleUpdateView?: ((id: string | number | string[] | number[], value: 0 | 1) => void) | undefined;
  dataSourceAll: IndexedArray<MonitoringBrokenSequenceDataModel & VehicleTracker>;
}

// Контейнер таблицы нарушения последовательности
export const MonitoringTableDeliveryBrokenSequence: FC<IProps> = ({
  dataSource,
  onContextOpen,
  recordTableButtons,
  loadExel,
  handleUpdateView,
  ...props
}) => {
  const [showAll, setShowAll] = useState(true);
  const { setFocusRow, focusRow } = useFocusRow();
  const history = useHistory();
  const dispatch = useDispatch();

  const decoratedData = isEmpty(props.dataSourceAll as IndexedArray<MonitoringBrokenSequenceDataModel & VehicleTracker>)
    ? []
    : tableViewedFilter(tableBrokenSequenceDataToTable(dataSource, dispatch, history, props.dataSourceAll, handleUpdateView), showAll);
  recordTableButtons = addToTableExelButton(recordTableButtons, loadExel, decoratedData, props.columns, Dictionary.BrokenSequence);
  recordTableButtons = addToTableShowViewedButton(recordTableButtons, showAll, (val: boolean) => showAll !== val && setShowAll(val));

  return (
    <MonitoringTableTopLayout>
      <ILSTable
        {...props}
        focusRow={focusRow}
        setFocusRow={setFocusRow}
        recordTableButtons={recordTableButtons}
        config={tableBrokenSequenceConfig}
        dataSource={decoratedData}
        size={'small'}
        scroll={{ y: 'calc(75vh - 430px)' }}
        pagination={false}
        className="editable-table"
        bordered
        dictionary={Dictionary.BrokenSequence}
        onContextOpen={onContextOpen}
      />
      <ILSDashboardViewedButtonComponent
        key={Dictionary.BrokenSequence + 'button'}
        disabled={!hasUnviewed(decoratedData)}
        onClick={() => {
          handleUpdateView?.(
            tableViewedFilter(decoratedData, false).reduce((acc: string[], cur) => {
              return acc.concat(cur.waypointIDs);
            }, []),
            1
          );
        }}
      />
    </MonitoringTableTopLayout>
  );
};

export const ILSMonitoringBrokenSequence = tableCustom(
  MonitoringTableDeliveryBrokenSequence,
  tableBrokenSequenceConfig,
  Dictionary.BrokenSequence
);
