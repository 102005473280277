import { AnalyticsBoardModel } from '@common/types/dictionaries/analytics';
import moment from 'moment/moment';
import { DateFormat } from '@common/types';
import { Moment } from 'moment';

export const getBoardDateRange = (board: AnalyticsBoardModel): { from: Moment; till: Moment } => {
  if (!board.From || !board.Till || board.From < 0 || board.Till < 0) {
    return { from: moment(), till: moment() };
  }
  const from = board.From > 0 ? moment.unix(board.From || 0) : moment();
  const till = board.Till > 0 ? moment.unix(board.Till || 0) : moment();
  return { from, till };
};

export const getBoardDateRangeFormatted = (board: AnalyticsBoardModel): { from: string; till: string } => {
  const { from, till } = getBoardDateRange(board);
  return { from: from.format(DateFormat.YYYYMMDDDash), till: till.format(DateFormat.YYYYMMDDDash) };
};
