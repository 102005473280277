import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IndexedArray } from '@common/types';
import { AnalyticsPlanningStore } from '../types/store';
import { widgetCatalogRoutine } from '../actions';
import initialState from '@modules/analytics/common/store';
import { AnalyticsWidgetCatalogModel } from '@common/types/dictionaries/analytics';

export const widgetCatalogReducer = createReducer<AnalyticsPlanningStore>(initialState as AnalyticsPlanningStore, {
  [widgetCatalogRoutine.REQUEST]: (state) => {
    if (state.widgetCatalog) {
      state.widgetCatalog.isFetching = true;
      state.widgetCatalog.data = {};
    }
    return state;
  },
  [widgetCatalogRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: IndexedArray<AnalyticsWidgetCatalogModel>;
    }>
  ) => {
    const { data } = action.payload;
    if (state.widgetCatalog) {
      state.widgetCatalog.data = data;
    }
    return state;
  },
  [widgetCatalogRoutine.FAILURE]: (state) => {
    return state;
  },
  [widgetCatalogRoutine.FULFILL]: (state, action: PayloadAction<any>) => {
    if (state.widgetCatalog) {
      state.widgetCatalog.isFetching = false;
    }
    return state;
  }
});
