export const ANALYTICS_MODULE_NAME = 'ANALYTICS';

export const BOARD_SETS_TITLE = 'Наборы';
export const BOARDS_TITLE = 'Доски';
export const DEFAULT_BOARD_TITLE = 'Новая доска';
export const DEFAULT_BOARD_SETS_TITLE = 'Новый набор';
export const DEFAULT_WIDGET_TEXT = 'Текстовый виджет';
export const CREATE_BOARD_CAPTION = 'Создать доску';

export const GET_WIDGET_DATA_ERROR = 'Ошибка получения данных';

export const MAX_WIDGET_SIZE = 100 + '%';
export const UNDER_CONSTRUCT_CAPTION = 'Раздел в разработке';
export const MAKE_REPORT_CAPTION = 'Создать PDF';
export const CONFIRM_DELETE_CAPTION = 'Подтверждение удаления';
//z-index перетаскиваемого виджета
export const RESIZABLE_Z_INDEX = 100;

export const SUMMARY_TABLE_CHART_DEFAULT_SLIDER = 2;
export const SUMMARY_TABLE_CHART_DEFAULT_HEIGHT = 210;

export enum WidgetCommonParam {
  From = 'From',
  Till = 'Till'
}

export const graphVerticalChartLegendInfo = [
  {
    name: 'Во время',
    value: 'visits',
    marker: {
      symbol: 'square',
      style: { fill: '#4C91FF' }
    }
  },
  {
    name: 'С опережением',
    value: 'inTime',
    marker: {
      symbol: 'square',
      style: { fill: '#43CC4C' }
    }
  },
  {
    name: 'С опозданием',
    value: 'late',
    marker: {
      symbol: 'square',
      style: { fill: '#D24747' }
    }
  }
];

export const FormDataFields = {
  column1: {
    id: 'id',
    name: 'name',
    type: 'type',
    visible: 'visible',
    status: 'status'
  },
  column2: {
    startDateProposals: 'startDateProposals',
    endDateProposals: 'endDateProposals',
    contractStartDate: 'contractStartDate',
    contractEndDate: 'contractEndDate',
    contactDetails: 'contactDetails'
  },
  column3: {
    description: 'description'
  }
};
