import { ChangeEvent, FC } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { importConfigRoutine } from '@modules/planning/children/params/actions';
import { dataSelector } from '@modules/planning/children/params/selectors';
import { IData } from '@modules/planning/children/params/types/store';
import { ILSButton } from '@components/index';
import { EMPTY_STRING } from '@common/constants';
import '../styles.less';
import { transformConfig } from '@modules/planning/children/params/utils/transform-config-to-object';

interface Props {
  selectedConfigs: IData[];
}

const ILSPlanningParamsImportExportContainer: FC<Props> = (props) => {
  const { selectedConfigs } = props;
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataSelector);

  const handleDownload = async () => {
    const config = selectedConfigs && data?.find((i) => i.id === selectedConfigs[0].id);

    if (config) {
      const template = transformConfig(config);

      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(template, null, '\t'))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `${config.Name_}.json`;
      link.click();
    }
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files ? event.target.files[0] : null;
    if (!file) return false;
    let reader = new FileReader();
    reader.onload = function (e) {
      const result: string | ArrayBuffer | null | undefined = e?.target?.result;
      if (typeof result === 'string') {
        dispatch(importConfigRoutine({ config: JSON.parse(result) }));
        event.target.value = EMPTY_STRING;
      }
    };
    reader.readAsText(file);
  };
  return (
    <div className="import-export-btns">
      {selectedConfigs && selectedConfigs[0] && (
        <ILSButton type="primary" className="mr-2 grey" onClick={handleDownload}>
          Скачать выбранную конфигурацию
        </ILSButton>
      )}
      <ILSButton type="primary" className="mr-2 success upload-config">
        <input type="file" accept="application/JSON" onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpload(e)} />
        Загрузить конфигурацию
      </ILSButton>
    </div>
  );
};

export default ILSPlanningParamsImportExportContainer;
