import { CellType, ColumnTable, FilterCellType, TableColumnName, TableRecord } from '@common/types';

export const ID_COLUMN: ColumnTable<TableRecord> = {
  title: '№',
  dataIndex: TableColumnName.ID,
  key: TableColumnName.ID,
  active: false,
  editable: false,
  type: CellType.String,
  filtering: FilterCellType.String
};
export const COLUMN_MIN_MAX_VALUES = {
  Boolean: { min: 0, max: 1 },
  Two: { min: 0, max: 2 },
  Three: { min: 0, max: 3 },
  Four: { min: 0, max: 4 }
};

export const COLUMNS_WITN_MIN_MAX_PROPERTY = {
  // Поле «Доставить в/до». Допустимо только 0,1,2,3.
  [TableColumnName.DeliveryDayLimit]: COLUMN_MIN_MAX_VALUES.Three,
  // Поле «Отдельно». Допустимо только 0,1,2.
  [TableColumnName.ExclusiveOrder]: COLUMN_MIN_MAX_VALUES.Two,
  // Поле «Порядок». Допустимо только 0,1,2,3,4.
  [TableColumnName.PlaceInTrip]: COLUMN_MIN_MAX_VALUES.Four,
  // Поле «Гидроборт». Допустимо только 0,1.
  [TableColumnName.RequireTailLift]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Без опоздания». Допустимо только 0,1.
  [TableColumnName.DeniedLate]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Роклы». Допустимо только 0,1.
  [TableColumnName.RequirePalletTruck]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Загрузчики». Допустимо только 0,1.
  [TableColumnName.RequireLoaders]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Погрузчик». Допустимо только 0,1.
  [TableColumnName.RequireUnloaders]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Тип точки». Допустимо только 1,2,3.
  [TableColumnName.DepotType]: COLUMN_MIN_MAX_VALUES.Three,
  // Поле «Оставить прицеп». Допустимо только 0,1.
  [TableColumnName.WarehouseAllowKeepTrailer]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Использовать». Допустимо только 0,1.
  [TableColumnName.TakeForPlanner]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Приоритет». Допустимо только 0,1,2.
  [TableColumnName.Priority]: COLUMN_MIN_MAX_VALUES.Two,
  // Поле «Признак прицепа». Допустимо только 0,1.
  [TableColumnName.AllowKeepTrailer]: COLUMN_MIN_MAX_VALUES.Boolean,
  // Поле «Тип». Допустимо только 0,1,2,3. TODO возможно нужна проверка на Driver dictionary
  [TableColumnName.Type]: COLUMN_MIN_MAX_VALUES.Three
};
