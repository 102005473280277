import { Provider, ValidationData } from '@common/types';
import { PlanningConfigType } from '@modules/planning/children/by-date/types/planning-config';
import { TPlanningConfig } from '@modules/planning/children/by-date/types/store';

export type ErrorComponentType = {
  table: string | null | undefined;
  errorType: string | null;
  errors: ValidationData[];
  failKeys: string[] | null;
  failFieldName: string | null;
  activeTable: string | null;
};

export type FinalPlanningConfigArgsType = {
  planID: string | number;
  projectID: number | null;
  planningConfigByPlan: Record<string, TPlanningConfig> | null;
  planningParamsConfig: {
    ILS: Record<string, any>;
    Veeroute: Record<string, any>;
    Yandex: Record<string, any>;
  } | null;
  configType: PlanningConfigType;
};

export type PlanningAndValidationRequestConfig = {
  planID: string | number;
  planConfigID: string | number | null | undefined;
  validationType?: string | null;
  providerName: Provider;
  operation?: string | null;
  params?: Record<string, any> | undefined;
  projectID: number | null;
};

export enum ValidationStatus {
  Start = 'start',
  Success = 'success',
  Stop = 'stop'
}
