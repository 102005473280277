import { getDecoratedOrders } from '@common/models/order/decorators/get-decorated-orders';
import { TableColumnName } from '@common/types';

enum MinTime {
  LoadStart = 1,
  LoadEnd = 0,
  UnloadStart = 1,
  UnloadEnd = 0
}

export const countOrderLoadUnloadStartEnd = ({
  orders,
  dateOrderAction
}: {
  orders: ReturnType<typeof getDecoratedOrders>;
  dateOrderAction: TableColumnName;
}): number | null => {
  const noInformationInOrder = orders.every((order) => {
    const columnValue = order[dateOrderAction];
    return !columnValue;
  });
  if (noInformationInOrder) return null;

  return orders.reduce((time, order) => {
    const columnValue = order[dateOrderAction];
    if (columnValue && dateOrderAction.includes('End')) {
      return Math.max(time, columnValue);
    }
    if (columnValue && dateOrderAction.includes('Start')) {
      return Math.min(time, columnValue);
    }
    return time;
  }, MinTime[dateOrderAction]);
};
