import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { Restriction } from '@common/types';

export const restriction2table = (restrictions: Array<Restriction>) => {
  return createArrayFromIndexedArray(restrictions).map((restriction, i) => {
    return {
      ...restriction,
      key: restriction.ID ?? 'row' + i
    };
  });
};
