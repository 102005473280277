export const productVersionConfirmData = {
  execute: 'Подтвердить',
  title: 'Внимание!',
  description:
    "Версия продукта изменилась, после того, как вы нажмете 'Подтвердить', страница будет перезагружена и вам необходимо будет заново выполнить вход в приложение!"
};

export const productVersionRequest = {
  file: 'version.json',
  interval: 60000
};

