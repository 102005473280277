import reduceReducers from 'reduce-reducers';
import initialState from '../store';
import { getDocumentSets } from './get-document-sets';
import { getDocumentTemplates } from './get-document-templates';
import { getTemplate } from './get-template';
import { updateTemplate } from './update-template';
import { currentTemplate } from '@modules/documents/children/templates/reducers/current-template';

export const templatesRootReducer = reduceReducers(
  initialState,
  getDocumentTemplates,
  getDocumentSets,
  getTemplate,
  updateTemplate,
  currentTemplate
);
