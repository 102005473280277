import { call, put, select, takeEvery } from 'redux-saga/effects';
import { userRoutine } from '../actions/index';
import { UserAPI } from '../api/user';
import { UserPayload } from '@modules/account/types/api';
import { cantChangeRoleOrDeleteUserSelector } from '@modules/account/selectors';
import { checkIsErrorData } from '@common/utils/validators/check-data-for-error';
import { DictionariesEndpoint } from '@common/types';
import { changeUserInfoRoutine } from '@modules/current-user/actions';

export function* userWorker(action: UserPayload) {
  const { operation, values, id } = action.payload;
  const { request, success, failure, fulfill } = userRoutine;
  try {
    if (operation === DictionariesEndpoint.Delete || (values?.hasOwnProperty('Blocked') && values.Blocked)) {
      const cantChangeRoleOrDeleteUser: boolean = yield select(cantChangeRoleOrDeleteUserSelector());
      checkIsErrorData({ data: cantChangeRoleOrDeleteUser, message: 'Нельзя удалить единственного пользователя-администратора' });
    }
    yield put(request({ operation }));

    const {
      data: { data }
    } = yield call(UserAPI.userOperation, { operation, values, id });
    yield put(success({ data, operation, id }));
    if (operation === DictionariesEndpoint.Update) {
      yield put(changeUserInfoRoutine({ data }));
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation }));
  }
}

export function* userWatcher() {
  yield takeEvery(userRoutine.trigger, userWorker);
}

