import { createSelector } from 'reselect';
import { PaymentInitialState } from '@common/feature/payment/types/general';

export const invoiceTemplateSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.invoice,
  (invoice) => invoice
);

export const invoiceTemplateFetchSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.isFetching,
  (isFetching) => isFetching
);

export const currentBalanceSumSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.currentBalance,
  (currentBalance) => currentBalance.sum
);

export const currentBalanceFetchSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.currentBalance,
  (currentBalance) => currentBalance.isFetching
);

export const currentBalanceLastUpdateSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.currentBalance,
  (currentBalance) => currentBalance.lastUpdate
);

