import { CellType, ColumnTable, IndexedArray, TableConfig, TableRecord } from '@common/types';
import { AnalyticsWidgetDataActions, AnalyticsWidgetMetricsModel } from '@common/types/dictionaries/analytics';
import { AnalyticsWidgetFieldType } from '../types/tables';

export const createAnalyticsTableConfig = (
  data?: IndexedArray<AnalyticsWidgetMetricsModel> | null,
  className?: string,
  actions?: AnalyticsWidgetDataActions[]
): TableConfig => {
  if (!data) return { columns: [] };
  let columns = Object.values(data).reduce<ColumnTable<TableRecord>[]>((acc, curr) => {
    if (curr.name) {
      const col = {
        title: curr.title ?? '',
        subTitle: curr.subTitle || undefined,
        dataIndex: curr.name,
        key: className + '-' + curr.name,
        type: getCellType(curr.type, curr.cellType),
        width: curr.width || 100,
        ellipsis: !(curr?.lineCount || curr.lineCount === 1),
        minWidth: curr?.minWidth || 30,
        textWrap: curr?.textWrap || false
      };
      acc.push(col);
    }
    return acc;
  }, []);

  if (actions?.length) {
    const btnConfig = {
      dataIndex: 'menu-button',
      ellipsis: true,
      key: 'menu-button',

      subTitle: undefined,
      title: '',
      type: CellType.MenuButton,
      width: 50
    };

    columns = [btnConfig, ...columns];
  }

  return {
    deleting: false,
    selecting: false,
    reloading: false,
    copying: false,
    hideClearButtons: true,
    columns
  };
};

const getCellType = (type?: string | null, cellType?: string | null): CellType => {
  switch (cellType) {
    case AnalyticsWidgetFieldType.ProgressCell:
      return CellType.Progress;
    case AnalyticsWidgetFieldType.TrafficLightCell:
      return CellType.BadgeText;
    case AnalyticsWidgetFieldType.FavoritesCell:
      return CellType.Icon;
  }
  switch (type) {
    case 'date':
      return CellType.Date;
    case 'number':
      return CellType.Text;
    case 'float':
      return CellType.Float;
    default:
      return CellType.Text;
  }
};
