import { call, put, takeLatest } from 'redux-saga/effects';
import { statusDistanceMatrixRoutine } from '@modules/planning/children/by-date/actions';
import { DistanceMatrix } from '@modules/planning/children/by-date/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ProjectModel } from '@common/types';

function* statusDistanceMatrixWorker(
  action: PayloadAction<{
    projectID: ProjectModel['ID'];
    withoutNotification?: boolean;
  }>
) {
  const { request, success, failure, fulfill } = statusDistanceMatrixRoutine;
  const { projectID, withoutNotification } = action.payload;

  try {
    yield put(request({ withoutNotification }));
    const { data } = yield call(DistanceMatrix.status, { projectID });
    yield put(success({ data: data.data, withoutNotification }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* statusDistanceMatrixWatcher() {
  yield takeLatest(statusDistanceMatrixRoutine.trigger, statusDistanceMatrixWorker);
}
