import { AbacRules } from '@abac/types';
import { plannerPermissions } from '@modules/planning/abac/permissions';
import { UserRole } from '@common/types';

export const plannerRules: AbacRules = {
  [UserRole.administrator]: {
    [plannerPermissions.PLANNER_SUBMODULES]: false,
    [plannerPermissions.PLANNER_NEW]: true
  },
  [UserRole.manager]: {
    [plannerPermissions.PLANNER_SUBMODULES]: false,
    [plannerPermissions.PLANNER_NEW]: true
  },
  [UserRole.driver]: {
    [plannerPermissions.PLANNER_SUBMODULES]: false,
    [plannerPermissions.PLANNER_NEW]: true
  }
};
