import { ILSUserNotify } from '@components/index';
import { Notify, TableRecord } from '@common/types';
import { isObject } from 'lodash';
import { FocusEvent, KeyboardEvent } from 'react';
import { IMaskInput } from 'react-imask';
import { IILSCellGeoProps } from '@common/types/components/table/cell';
import { DEFAULT_INITIALIZATION_FUCTION, JoinChar } from '@common/constants';
import { LAT_LON_MASK, LAT_LON_REGEX } from '@common/constants/regex';

export const CELL_GEO_INPUT_PLACEHOLDER = 'Через пробел: широта долгота';

export const ILSCellGeo = <R extends TableRecord>({ children, record, dataIndex, toggleEdit, save }: IILSCellGeoProps<R>): JSX.Element => {
  const [node, childrenCoordinates] = children;
  const coordinates = isObject(childrenCoordinates) ? Object.values(childrenCoordinates).join(JoinChar.Space) : childrenCoordinates;
  const [lat, lon] = (dataIndex && record?.[dataIndex] && Object.values(record[dataIndex])) || [null, null];

  const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    const { code } = e;
    if (code === 'Enter') {
      handleBlur(e);
    }
    return;
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
    try {
      if (e.target instanceof Element) {
        const { value } = e.target;
        if (!value) {
          save(null);
        }
        if (LAT_LON_REGEX.test(value)) {
          const coordinates = value.split(JoinChar.Space);
          if (coordinates.length === 2) {
            const [lat, lon] = coordinates;
            const payload = { lat, lon };
            save(payload);
          } else {
            ILSUserNotify(Notify.Warning, 'Между координатами должен быть пробел', 5);
          }
        } else {
          ILSUserNotify(Notify.Warning, 'Введите корректные широту и долготу через пробел', 5);
        }
      }
      return;
    } catch (error) {
      ILSUserNotify(Notify.Error, `Ошибка: ${error}`);
    } finally {
      toggleEdit?.();
    }
  };

  const inputValue = coordinates ?? (lat && lon && `${lat} ${lon}`);

  return (
    <>
      {node}
      <IMaskInput
        placeholder={CELL_GEO_INPUT_PLACEHOLDER}
        autoFocus
        className={'input ant-input cell-wrapper-editable-full'}
        mask={LAT_LON_MASK}
        radix="."
        value={inputValue}
        unmask
        commit={DEFAULT_INITIALIZATION_FUCTION}
        prepare={(str: any) => str}
        validate={(str: any) => str}
        onKeyDown={onEnter}
        // @ts-ignore
        onBlur={handleBlur}
      />
    </>
  );
};
