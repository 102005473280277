import { FC } from 'react';
import { ILSTable } from '@components/index';
import { IILSTableProps } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { tenderLotWinnerChooseRoutine } from '@modules/tender-platform/children/tender-own/actions';
import { offerTableCargoOwnerSelector } from '@modules/tender-platform/children/tender-own/selectors/components';
import { TenderOfferRecord } from '@modules/tender-platform/utils/decorators/offer-lots-to-table';

interface IProps extends IILSTableProps<TenderOfferRecord> {}

export const TenderPlatformOfferTable: FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { dataSource, columns } = useSelector(offerTableCargoOwnerSelector(props.config));

  const handleSave: IILSTableProps<TenderOfferRecord>['handleSave'] = ({ offerID }) => {
    dispatch(tenderLotWinnerChooseRoutine({ offerID }));
  };

  return (
    <ILSTable
      {...props}
      handleSave={handleSave}
      scroll={{ x: 300, y: 500 }}
      columns={columns}
      dataSource={dataSource}
      focusRow={{ key: dataSource[0].key }}
    />
  );
};
