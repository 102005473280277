import { ValueItemType } from '@common/components/data-display/table/components/cell-types/static/ils-cell-line-series';
import { MonitoringStatusType } from '@modules/settings/types/forwarder-point-statuses';
import { DELIVERY_STATUSES_COLORS, deliveryStatuses } from '@modules/monitoring/children/dashboard/data/dashboard';

/**
 * Функция возвращает цвет квадрата доставки в ячейке ILSCellLineSeries;
 * Сначала проверяем поле statusType.
 * Если 0 - то выбираем красный цвет. Если 1 или null, то выбираем по полю status.
 * Если status не соответствует статусу из deliveryStatuses, то возвращаем undefined.
 * @param status - вычисленный статус актуального события;
 * @param statusType - позитивный или негативный статус доставки;
 * @returns - цвет: DELIVERY_STATUSES_COLORS | undefined
 */

interface IProps extends Pick<ValueItemType, 'status' | 'statusType'> {}

export const getCellLineSeriesDeliveryBackground = ({ status, statusType }: IProps): DELIVERY_STATUSES_COLORS | undefined => {
  if (statusType === MonitoringStatusType.Negative) {
    return DELIVERY_STATUSES_COLORS.red;
  }
  return deliveryStatuses.find((deliveryStatus) => deliveryStatus.label === status)?.color;
};

