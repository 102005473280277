import { DateFormat, DateTimeFormat, TimeFormat, TimeStamp } from '@common/types';
import { ILS_MAIN_API_DATE_FORMAT, ILS_MAIN_API_DATE_TIME_FORMAT, UNIX_TIME_STAMP_CHARS } from '@common/types/general/date-time';
import { isString } from 'lodash';
import moment, { MomentInput } from 'moment';
import { EMPTY_STRING } from '@common/constants';

export const parseDateFromServer = (
  date?: MomentInput | TimeStamp | null,
  withTime: boolean = false,
  parseFormat?: DateFormat | DateTimeFormat | TimeFormat | null
): moment.Moment => {
  const format = parseFormat ?? (withTime ? ILS_MAIN_API_DATE_TIME_FORMAT : ILS_MAIN_API_DATE_FORMAT);
  if (isString(date)) {
    return moment(date, format);
  } else {
    if (date && date.toString().length < UNIX_TIME_STAMP_CHARS) {
      return moment.unix(date as TimeStamp);
    } else {
      return moment(date || EMPTY_STRING);
    }
  }
};

export const parseDateMultiply = (
  date?: string | TimeStamp,
  withTime: boolean = false,
  parseFormat: DateFormat | DateTimeFormat | TimeFormat | null | undefined = ILS_MAIN_API_DATE_FORMAT
): Date | undefined => {
  return parseDateFromServer(date, withTime, parseFormat)?.toDate();
};

export const parseDateFromServerToDisplay = (
  date?: string | TimeStamp | null | undefined,
  withTime: boolean = false,
  dateFormat?: DateFormat | DateTimeFormat
) => {
  if (!date) return undefined;
  return parseDateFromServer(date, withTime, dateFormat);
};

