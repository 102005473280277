import { createArrayFromIndexedArray } from '@common/utils';
import { Option } from '@common/types';
import { sortOptionsByFieldNames } from '@components/data-display/table/utils/helpers/sort-options-by-field-names';
import { GetCellOptionsParameters, GetColumnOptionsParameters } from '@common/types/components/table';

export const getColumnOptions = ({
  variants,
  fieldNames,
  needSortByLabelField = true
}: GetColumnOptionsParameters): undefined | (Option | object)[] => {
  if (!variants || !fieldNames) return;
  return needSortByLabelField ? sortOptionsByFieldNames({ options: variants, fieldNames }) : createArrayFromIndexedArray(variants);
};

export const getCellOptions = ({
  getOptions,
  record,
  fieldNames,
  needSortByLabelField
}: GetCellOptionsParameters): undefined | (Option | object)[] => {
  if (!getOptions || !fieldNames) return;

  const options = getOptions(record);
  return getColumnOptions({ variants: options, fieldNames, needSortByLabelField });
};
