import { USE_CORE_STATE } from '@core/constants';
import { IModuleModel } from '@core/types/models';
import { ICoreState } from '@core/types/store';
import { getCurrentModule } from '@core/utils/get-current-module';
import { ComponentType, useMemo, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Store } from 'redux';
import { ILSAppRegistry } from '../../index';
import { StateKeyName } from '@core/constants/core';
import { ModuleScope } from '@core/types';

const staticStoreKeys = [StateKeyName.Fixed, StateKeyName.Auth];

interface InjectAsyncStoreProps extends RouteComponentProps<any> {
  onModuleChange: (currentReducersKeys: string[]) => void;
  Wrapper: ComponentType;
}

export const InjectAsyncStore = withRouter<InjectAsyncStoreProps, React.ComponentType<InjectAsyncStoreProps>>(
  ({ history, children, onModuleChange, Wrapper }) => {
    const { location } = history;
    const { pathname } = location;
    const { store, modules }: { store: Store<ICoreState>; modules: any } = ILSAppRegistry;
    const usedModules = modules.filter(
      (module: IModuleModel) =>
        module.inScope!.includes(ModuleScope.SiderNav) ||
        module.inScope!.includes(ModuleScope.CustomForAuthPage) ||
        module.inScope!.includes(ModuleScope.CustomWithoutAuthPage)
    );
    const prevModuleRef = useRef({});

    useMemo(() => {
      const currentModule = getCurrentModule(usedModules, pathname);
      if (USE_CORE_STATE && currentModule && prevModuleRef.current !== currentModule) {
        prevModuleRef.current = currentModule;
        const currentReducersKeys: string[] = [];
        const currentSagas: string[] = [];

        const getModulesRedux = (module: IModuleModel) => {
          if (module.reducer) {
            currentReducersKeys.push(module.MODULE!);
            store['reducerManager'].add(module.MODULE, module.reducer, store);
          }

          if (module.sagas && module.sagas.length) {
            currentSagas.push(module.MODULE!);
            store['sagaInjector'].injectSaga(module.MODULE, module.sagas);
          }

          if (module.children && Object.keys(module.children).length) {
            Object.values(module.children).forEach((child: any) => {
              getModulesRedux(child);
            });
          }
        };

        if (currentModule && currentModule.MODULE !== StateKeyName.Settings) {
          getModulesRedux(currentModule);

          if (currentModule.deps && currentModule.deps.length) {
            const depsModules = currentModule.deps.map((title: string) => {
              return modules.find((module: IModuleModel) => module.MODULE === title);
            });

            depsModules.forEach((module: IModuleModel) => {
              getModulesRedux(module);
            });
          }
        }

        // Меняем reducer и саги, оставляем только те, которые нужны для текущего модуля
        store['reducerManager'].replace(currentReducersKeys);
        store['sagaInjector'].replaceSagas(currentSagas);

        onModuleChange(currentReducersKeys.concat(staticStoreKeys));
      }
    }, [pathname, children, store, usedModules, modules]);

    return <Wrapper>{children}</Wrapper>;
  }
);
