import { unionTripsRoutine } from '@modules/planning/children/by-date/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PlanningAPI } from '@modules/planning/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from '@common/types';

export function* planningUnionTripsWorker(
  action: PayloadAction<{
    sourceVehicleID: number;
    targetVehicleID: number;
    planID: number;
  }>
) {
  const { request, success, failure, fulfill } = unionTripsRoutine;
  const { sourceVehicleID, targetVehicleID, planID } = action.payload;

  try {
    yield put(request());
    const {
      data: { data: message }
    } = yield call(PlanningAPI.unionTrips, { sourceVehicleID, targetVehicleID, planID });
    const {
      data: { data: route }
    } = yield call(PlanningAPI.getTotal, { planID, vehicleID: targetVehicleID, dictionary: Dictionary.Route });

    const {
      data: { data: planTotal }
    } = yield call(PlanningAPI.getTotal, { planID, dictionary: Dictionary.Plan });

    yield put(success({ message, sourceVehicleID, route, planTotal }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* unionTripsWatcher() {
  yield takeLatest(unionTripsRoutine.trigger, planningUnionTripsWorker);
}

