import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/children/tender-search/store';
import { tenderFilterRoutine } from '@modules/tender-platform/children/tender-search/actions';
import { TenderFilter } from '@modules/tender-platform/children/tender-search/types/filter';
import { EMPTY_STRING } from '@common/constants';

export const tenderFilterReducer = createReducer(initialState, {
  [tenderFilterRoutine.SUCCESS]: (state, action: PayloadAction<{ filter: TenderFilter; filterValue: string }>) => {
    const { filter, filterValue } = action.payload;

    let filterWithValue = { ...filter, value: filterValue };

    if (filter.method === 'rangeMax' && (filterValue === EMPTY_STRING || filterValue === '0')) {
      filterWithValue = { ...filter, value: '100000' };
    } else if (filter.method === 'rangeMin' && filterValue === EMPTY_STRING) {
      filterWithValue = { ...filter, value: '0' };
    }

    const emptyFilterValue = filterValue === EMPTY_STRING || (Array.isArray(filterValue) && !filterValue.length);

    if (!state.filters.length || state.filters.every((filter) => filter.key !== filterWithValue.key)) {
      state.filters.push(filterWithValue);
    } else if (emptyFilterValue) {
      state.filters = state.filters.filter((filter) => filter.key !== filterWithValue.key);
    } else {
      state.filters = state.filters.map((filter) => {
        if (filter.key === filterWithValue.key) {
          return {
            ...filter,
            value: filterWithValue.value
          };
        }
        return filter;
      });
    }
  }
});
