import { tableData2Vehicles } from '@common/models/vehicle/decorators';
import { Dictionary, RoadTrain, TableColumnName, Vehicle } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';
import { canEditVehicle } from '@modules/catalog/helpers/table/can-edit-vehicle';
import { IPropsILSCatalogTable } from '@modules/catalog/types/components';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ILSCatalogTable } from '../../containers/table-container';
import { CatalogFilterProject } from '@modules/catalog/containers/catalog-filter-project';
import { vehicleTableOptionsSelector } from '@modules/catalog/selectors/options';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { VEHICLE_CATALOG_TABLE_CONFIG } from '@common/models/vehicle/constants';
import { ILSCatalogVehicleProfile } from './vehicle-profile';
import { ILSCatalogVehicleTimeline } from '@modules/catalog/components/tables/vehicle-timeline';
import { ILSCatalogVehicleOverload } from '@modules/catalog/components/tables/vehicle-overload';
import { ILSCatalogCouplingType } from '@modules/catalog/components/tables/coupling-type';
import { ILSCatalogRoadTrain } from '@modules/catalog/components/tables/road-train';
import { catalogVehicleToTable } from '@common/models/vehicle/decorators/catalog-vehicle-to-table';

export const VEHICLE_REFERENCE_TABLE = {
  VehicleProfileID: {
    title: 'Характеристики профиля автомобиля',
    component: ILSCatalogVehicleProfile
  },
  VehicleTimelineID: {
    title: 'Расписание работы автомобилей',
    component: ILSCatalogVehicleTimeline
  },
  VehicleOverloadID: {
    title: 'Превышения автомобилей',
    component: ILSCatalogVehicleOverload
  },
  FrontCouplingTypeID: {
    title: 'Редактирование типов связок',
    component: ILSCatalogCouplingType
  },
  RearCouplingTypeID: {
    title: 'Редактирование типов связок',
    component: ILSCatalogCouplingType
  },
  RoadTrain: {
    title: 'Редактирование автосцепки',
    component: ILSCatalogRoadTrain
  }
};

export const ILSCatalogVehicle: FC<IPropsILSCatalogTable> = tableCustom(
  (props) => {
    const { refData } = useSelector(vehicleTableOptionsSelector);
    const roadTrains: Array<RoadTrain> | undefined = useSelector(catalogDictionaryDataSelector(Dictionary.RoadTrain));
    const COLUMN_WITH_VALIDATION = [TableColumnName.FrontCouplingTypeID, TableColumnName.IsAutomotive, TableColumnName.StrictStartTime, TableColumnName.RearCouplingTypeID];

    const onCheckSaveError = (record: Vehicle, dataIndex: TableColumnName) => {
      if (COLUMN_WITH_VALIDATION.includes(dataIndex)) {
        const vehicleTrains = roadTrains?.filter((train: RoadTrain) =>
          record.IsAutomotive
            ? train.VehicleID === record.ID && (train.Trailer1ID || train.Trailer2ID)
            : train.Trailer1ID === record.ID || train.Trailer2ID === record.ID
        );
        if (vehicleTrains?.length) {
          return record.IsAutomotive
            ? `Тягач задействован в автопоезде, нельзя менять это поле`
            : `Прицеп задействован в автопоезде с тягачом №${vehicleTrains?.[0].VehicleID}, нельзя менять это поле`;
        }
      }

      return false;
    };

    const onCanEdit = (vehicle: Vehicle, dataIndex: TableColumnName) => canEditVehicle(vehicle, dataIndex, VEHICLE_CATALOG_TABLE_CONFIG);
    const actionTableButtons = [
      {
        Component: CatalogFilterProject,
        props: {
          dictionary: Dictionary.Vehicle
        }
      }
    ];

    return (
      <ILSCatalogTable
        {...props}
        actionTableButtons={actionTableButtons}
        dictionary={Dictionary.Vehicle}
        childrenDictionary={Dictionary.RoadTrain}
        dataDecorator={catalogVehicleToTable}
        refTable={VEHICLE_REFERENCE_TABLE}
        refData={refData}
        onCheckSaveError={onCheckSaveError}
        onCanEdit={onCanEdit}
        handleDecorator={tableData2Vehicles}
      />
    );
  },
  VEHICLE_CATALOG_TABLE_CONFIG,
  Dictionary.Vehicle,
  true
);
