import { createSelector } from 'reselect';
import { currentTenderLotSelector, currentTenderSelector, tenderActionSelector } from '@modules/tender-platform/selectors';
import { tender2Form, tenderLotCurrent2Form } from '@modules/tender-platform/utils/decorators/tender-data-to-form';
import { CurrentTender, ScheduleType, Tender, TenderLot } from '@modules/tender-platform/types';
import { getModelsId, parseDateMultiply } from '@common/utils';
import { TenderFormField } from '@modules/tender-platform/constants';
import { EMPTY_ARRAY_DATA, EMPTY_OBJECT_DATA, EMPTY_STRING, VATValue } from '@common/constants';
import { useSearchParams } from '@common/hooks/history/use-search-params';
import { TenderAction } from '@modules/tender-platform/children/tender-own/types';

const getUniqDates = (TenderLot?: Tender['TenderLot']) => {
  const dates = TenderLot?.map(({ Schedule }) => Schedule?.dates).flat();
  const uniqSet = Array.from(new Set(dates));
  return uniqSet.map((date) => parseDateMultiply(date));
};

const plannerTenderToForm = ({ tender = EMPTY_OBJECT_DATA as CurrentTender }: { tender: CurrentTender }) => {
  const VehicleType = tender?.TenderSummary?.VehicleType;
  const TenderLotUser = tender?.TenderLot?.[0].TenderLotUser;
  const TenderLotUserIDs = Array.isArray(TenderLotUser) ? TenderLotUser.map(({ ID }) => ID) : Object.keys(TenderLotUser ?? {});

  return {
    ...tender2Form(tender),
    [TenderFormField.Distance]: tender?.TenderSummary?.Distance,
    [TenderFormField.TenderLotPosition]: [
      {
        Comment: EMPTY_STRING,
        // Количество всегда один, поле не редактируемое
        Amount: 1,
        // Масса, специфики и объем считаются по всему Тендеру (маршруту) суммируя все лоты (заявки)
        RestrictionID: tender?.TenderSummary?.RestrictionID ?? EMPTY_ARRAY_DATA,
        Mass: tender?.TenderSummary?.Mass ?? 0,
        Volume: tender?.TenderSummary?.Volume ?? 0
      }
    ],
    [TenderFormField.MinPrice]: tender?.TenderSummary?.MinPrice ?? 0,
    [TenderFormField.StartPrice]: tender?.TenderSummary?.StartPrice ?? 0,
    [TenderFormField.VAT]: tender?.TenderSummary?.VAT ?? VATValue.Yes,
    [TenderFormField.VehicleType]: Array.isArray(VehicleType) ? getModelsId(VehicleType) : Object.keys(VehicleType ?? EMPTY_OBJECT_DATA),
    [TenderFormField.TenderLotUser]: TenderLotUserIDs,
    [TenderFormField.Schedule]: ScheduleType.Dates,
    [TenderFormField.Dates]: getUniqDates(tender?.TenderLot as Array<TenderLot>),
    [TenderFormField.RegionalCityRecipient]: tender?.TenderSummary?.RegionalCityRecipient,
    [TenderFormField.RegionalCitySender]: tender?.TenderSummary?.RegionalCitySender,
    [TenderFormField.IsTemplate]: 0
  };
};

export const tenderFormSelector = createSelector(
  currentTenderSelector,
  currentTenderLotSelector,
  tenderActionSelector,
  (currentTender, tenderLot) => {
    const fromPlanner = Boolean(currentTender?.FromPlanner);
    const tenderID = useSearchParams(TenderAction.Tender);
    const isTenderCreating = tenderID === TenderAction.Create;
    const initialValues = fromPlanner
      ? plannerTenderToForm({ tender: currentTender })
      : tenderLotCurrent2Form(currentTender, tenderLot, isTenderCreating);

    return { initialValues, currentTender, fromPlanner };
  }
);
