import { GeoHashType } from '@common/decorators/types/plan';
import { IndexedArray } from '@common/types';
import { LayerHandler } from '@core/types/map';

import L from 'leaflet';

// Общие свойства для трекера
export type TrackerAbstractInfo = {
  IMEI: number; // IMEI индентификатор
  Latitude: string | number; // Широта
  Longitude: string | number; // Долгота
  Azimuth: null | string | number; // Азимут
};

// Является ли трекер валидным
export enum TrackValid {
  Valid = 1,
  Invalid = 0
}

// Тип трека
export enum TrackType {
  Road = 1, // Дорога
  Stop = 0 // Точка
}

// Общие данные точки события
export type PointEventAbstract = {
  IMEI: number; // IMEI индентификатор
  DT: number; // Дата и время события
  Latitude: null | number; // Широта
  Longitude: null | number; // Долгота
  Speed?: null | number; // Скорость движения
  info?: Array<string>; // Информация о событии
  Type?: TrackType | number | null; // Тип события
  Valid?: TrackValid | number | null; // Является ли валидным событие
};

// Общие данные о рейсе
export type TripAbstract = {
  Waipoints: IndexedArray<PlanWaipointAbstract>; // Точки
};

// Общие данные о плановой точке
export type PlanWaipointAbstract = {
  GeoHash: GeoHashType; // Гео хеш
};

// Общий тип данных о треке
export type TrackAbstract = IndexedArray<PointEventAbstract>;

//TODO Any тип
// Начальные данных для диаграм доставки
export type PolyLineInit = {
  coords: L.LatLngExpression[] | L.LatLngExpression[][]; // Координаты
  handlers?: {
    // Обработчики
    mouseover?: LayerHandler<L.Polyline>; // Указатель мыши сверху
    mouseout?: LayerHandler<L.Polyline>; // Указатель мыши покинул часть диаграммы
    click?: LayerHandler<L.Polyline>; // Был ли клик
  };
  options: {
    // Опции
    color: string; // Цвет линии
    weight?: number; // Толщина линии
    dashArray?: any; // Есть ли пунктир
  };
  popup?: Parameters<L.Polyline['bindPopup']>; // Есть ли всплывающие тултипы
};

//TODO разделить на 2 подтипа, если isInit true то все кластеры уже не могут стать undefined
// Группировка типов по слоям карты
export type MapLayerGroupState = {
  vehicleCluster: L.MarkerClusterGroup | undefined; // Кластер автомобилей
  markerCluster: L.MarkerClusterGroup | undefined; // Кластер маркеров
  polylineGroup: L.LayerGroup | undefined; // Группа линий
  markerGroup: L.LayerGroup | undefined; // Группа маркеров
  waypointGroup: L.LayerGroup | undefined; // Группа точек
  layerGroup: L.LayerGroup | undefined; // Группа слоев карты
  eventMarkerGroup: L.LayerGroup | undefined; // Группа маркеров событий
  isInit: boolean; // Является ли инициализационными установками
};
