import { PayloadAction } from '@reduxjs/toolkit';
import { ILSUserNotify } from '@common/components';
import { Notify } from '@common/types';
import { call, takeLatest } from 'redux-saga/effects';
import DocumentTemplatesAPI from '@modules/documents/children/templates/api';
import { downloadDocuments } from '@modules/documents/children/templates/actions';
import { downloadDocumentsFromTemplates } from '@modules/documents/helpers/download-documents-as-zip';

function* downloadDocumentsAsZipWorker(action: PayloadAction<number>) {
  try {
    const { data } = yield call(DocumentTemplatesAPI.getDocumentTemplates, { docID: action.payload });

    downloadDocumentsFromTemplates(Object.values(data.data));
  } catch (error) {
    ILSUserNotify(Notify.Error, error as any, 3);
  }
}

export function* downloadDocumentAsZipWatcher() {
  yield takeLatest(downloadDocuments, downloadDocumentsAsZipWorker);
}
