import { SECONDS_TO_DAYS_MULTIPLIER } from '@common/constants/general/date-time';
import { viewedContent } from '@common/table-configs/monitoring-dashboard/common/cell-viewed-content';
import { IndexedArray } from '@common/types';
import { MonitoringOnTimeDataModel } from '@common/types/dictionaries/monitoring-vehicle';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { timestampToTimeStringWithUTC } from '@common/utils/helpers/date-time/date-time';
import { FileAttachment } from '@common/types/dictionaries';
import { isEmpty, isObject } from 'lodash';
import { createWaypointComments } from '../../common/collect-waypoints-comments';
import { getTextViewStatus } from '../../common/forward-status-view';
import { isShouldWaypointBeTaken } from '../../utils/waypoints';

//временно до рефакторинга выводим вместо селекта
enum Status {
  'Не посещена',
  'Обслуживается',
  'Посещена',
  'Посещена с допустимым опережением',
  'Посещена с допустимым опозданием',
  'Посещена с опережением',
  'Посещена с опозданием'
}

type DataToTableFunction<R> = (arg: {
  dataSource: Array<MonitoringOnTimeDataModel> | IndexedArray<MonitoringOnTimeDataModel>;
  handleView?: (id: string | number | string[] | number[], value: 0 | 1) => void;
  showAttachment?: (arg: { files: Array<FileAttachment> }) => any;
  getWpTimeZone: (waypointOffset: number | null | undefined) => number;
}) => Array<
  Omit<R, 'LastStatusDT' | 'LastStatus'> & {
    key: string;
    Name: string;
    Number: string;
    DriverName: string;
    LastStatusDT: string | undefined;
    viewed: 0 | 1;
  }
>;

export const data2table: DataToTableFunction<MonitoringWaypointModel> = ({ dataSource, handleView, showAttachment, getWpTimeZone }) => {
  const res: ReturnType<typeof data2table> = [];
  const viewedCell = viewedContent(1, handleView);
  const notViewedCell = viewedContent(0, handleView);
  const vehicleData = isObject(dataSource) ? Object.values(dataSource) : dataSource;

  for (const vehicleIndex in vehicleData) {
    const vehicle = vehicleData[vehicleIndex];

    if (!vehicle.Trip || !Object.keys(vehicle.Trip)?.length) {
      continue;
    }

    for (const tripIndex in vehicle.Trip) {
      const trip = vehicle.Trip[tripIndex];

      if (!trip.Waypoint || !Object.keys(trip.Waypoint)?.length) {
        continue;
      }

      for (const waypointIndex in trip.Waypoint) {
        const waypoint = trip.Waypoint[waypointIndex];
        if (!isShouldWaypointBeTaken(waypoint, vehicle)) continue;
        const Driver = trip?.Driver || vehicle?.Driver;
        const viewed: 0 | 1 = waypoint?.Viewed || 0;

        const Attachments = !isEmpty(waypoint?.Attachments)
          ? showAttachment?.({
              files: Object.values(waypoint.Attachments as Array<FileAttachment> | IndexedArray<FileAttachment>)
            })
          : undefined;

        let { LastComment, LastComment_htmltip } = createWaypointComments(waypoint);

        const rowObj = {
          ...waypoint,
          Address: waypoint?.Depot?.Name || '',
          Attachments,
          Name: vehicle.Name,
          Number: vehicle.RegNumber || '',
          DriverName: Driver?.Name || '',
          WaypointFactArrive: waypoint.FactArrive
            ? timestampToTimeStringWithUTC(waypoint.FactArrive, getWpTimeZone(waypoint?.Depot?.UTCOffset))
            : '',
          WaypointPlanArrive: timestampToTimeStringWithUTC(
            waypoint.ArrivalTime * SECONDS_TO_DAYS_MULTIPLIER + trip.ProjectStart,
            getWpTimeZone(waypoint?.Depot?.UTCOffset)
          ),
          LastStatusDT: waypoint.LastStatusDT
            ? timestampToTimeStringWithUTC(waypoint.LastStatusDT, getWpTimeZone(waypoint?.Depot?.UTCOffset))
            : undefined,
          LastStatus: getTextViewStatus(waypoint.LastStatus),
          LastComment,
          LastComment_htmltip,
          Phone: Driver?.Phone || '',
          key: !waypoint.ID ? `row#${vehicleIndex}#${tripIndex}#${waypointIndex}` : String(waypoint.ID),
          tripStatus: trip.Status, //NOTE для работы с колбеками
          TripID: trip.ID, //NOTE для работы с колбеками
          // Status: waypoint.WaypointFact?.WaypointStatus,
          Status: waypoint.WaypointFact ? Status[waypoint.WaypointFact.WaypointStatus] : '',
          Viewed: viewed ? viewedCell : notViewedCell,
          viewed,
          Favourite: 1,
          WaypointID: waypoint.ID || undefined,
          VehicleID: vehicle.ID
        };

        res.push(rowObj);
      }
    }
  }

  return res;
};
