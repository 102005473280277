import { DemandAction } from '../types/action';

// Начальная и конечная фраза нотификации в зависимости от состояния получения списка проектов и тендерных лотов
export const DemandNotifyMessage: {
  [key in DemandAction]?: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  GET_LIST: { loading: 'Загрузка данных...', success: 'Данные загружены', error: 'Ошибка загрузки данных' }
};
