import { IILSCellSelectAPIStringProps } from '@common/types/components/table/cell';
import { ILSSelectStatic } from '@components/custom/data-display/select';
import { ENUM_STRING, ID_STRING } from '@common/utils/helpers/object/get-enum-field';
import { JoinChar } from '@common/constants';
import { isBoolean, isEmpty, isObject } from 'lodash';
import { TableRecord } from '@common/types';

export const ILSCellSelectAPIString = <R extends TableRecord>({
  showArrow = true,
  toggleEdit,
  dataIndex,
  record,
  children
}: IILSCellSelectAPIStringProps<R>): JSX.Element => {
  const [node, value] = children;
  const multiple = Array.isArray(value);

  if (!value || isBoolean(value) || (multiple && isEmpty(value))) {
    return <ILSSelectStatic showArrow={showArrow} className={'cell-wrapper-editable'} children={[node, undefined]} onClick={toggleEdit} />;
  }

  const enumV = dataIndex?.includes(ID_STRING) ? record?.[dataIndex.replace(ID_STRING, ENUM_STRING)] : null;

  const selected = multiple
    ? value.map((id) => enumV?.[id] ?? `Без имени №${id}`).join(JoinChar.Comma)
    : !isObject(value)
    ? enumV?.[value] ?? `Без имени №${value}`
    : `Без имени №${value}`;

  return <ILSSelectStatic showArrow={showArrow} className={'cell-wrapper-editable'} children={[node, selected]} onClick={toggleEdit} />;
};
