import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { filterVehicleForOrdersDataSource } from '@modules/planning/children/by-date/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { PlanningByDateState } from '@modules/planning/children/by-date/types';
import { planningFocusNExpandRowIdsSelector } from '@modules/planning/children/by-date/selectors/planning';
import { TableName, TableRecord } from '@common/types';
import { planningRememberFocusNExpandRowsRoutine } from '@modules/planning/children/by-date/actions';

export const useVehicleTableFocusRow = ({ dataSource }: { dataSource: ReturnType<typeof filterVehicleForOrdersDataSource> }) => {
  const dispatch = useDispatch();
  const { focusRow } = useSelector((state: PlanningByDateState) => planningFocusNExpandRowIdsSelector(state, TableName.PlanningVehicle));

  const onTableRowClick = (record: TableRecord) => {
    dispatch(planningRememberFocusNExpandRowsRoutine({ ID: record.ID, tableName: TableName.PlanningVehicle }));
  };

  useEffect(() => {
    if (!isEmpty(dataSource)) {
      const [firstVehicle] = dataSource;
      onTableRowClick(firstVehicle);
    }
  }, [dataSource.length]);

  return { focusRow, onTableRowClick };
};
