import { FC } from 'react';
import { useSelector } from 'react-redux';
import { projectDepotSelector, projectOrderSelector, projectWorktimeSelector } from '@modules/planning/children/by-date/selectors/project';
import { orderTableSelector } from '@modules/planning/children/by-date/selectors/components/tables/map-tables';
import { order2PlannerTable, table2data } from '@common/models/order/decorators';
import { ILSTable } from '@common/components';
import { orderConfigForEditable } from '@modules/planning/children/by-date/config/order';
import { ORDER_REFERENCE_TABLES } from '@modules/planning/children/by-date/constants';
import { Dictionary } from '@common/types';
import { tableCustom } from '@core/containers/table-custom';

const ILSPlanningOrderTable: FC<{ failFieldName: any; failKeys: any; showAll: boolean }> = ({
  showAll,
  failFieldName,
  failKeys,
  ...props
}) => {
  const Worktime = useSelector(projectWorktimeSelector);
  const depots = useSelector(projectDepotSelector);
  const orders = useSelector(projectOrderSelector);

  const { refData } = useSelector(orderTableSelector);

  const dataSource = order2PlannerTable({ orders, Worktime, depots });

  return (
    <ILSTable
      config={orderConfigForEditable}
      refTable={ORDER_REFERENCE_TABLES}
      refData={refData}
      handleDecorator={table2data}
      dictionary={Dictionary.Order}
      // @ts-ignore
      dataSource={
        showAll
          ? dataSource
          : dataSource.filter((row) => {
              return failFieldName && row[failFieldName] && failKeys?.includes(row[failFieldName]?.toString());
            })
      }
      {...props}
    />
  );
};

export const ILSPlanningOrder = tableCustom(ILSPlanningOrderTable, orderConfigForEditable, Dictionary.Order);
