import { EMPTY_ARRAY_DATA } from '@common/constants';
import { IndexedArray, TableColumnName } from '@common/types';
import { isObject } from 'lodash';

export const createArrayFromIndexedArray = <T>(object?: IndexedArray<T> | Array<T> | null): Array<T> => {
  return object ? (Array.isArray(object) ? object : Object.values(object)) : (EMPTY_ARRAY_DATA as Array<T>);
};

export const createArrayFromObject = <T>(object?: T | Array<T> | null): Array<T> => {
  return object ? (Array.isArray(object) ? object : [object]) : (EMPTY_ARRAY_DATA as Array<T>);
};

export const checkAndCreateArrayFromObject = <T extends Partial<Record<TableColumnName, any>>>(
  object?: IndexedArray<T> | T | Array<T> | null
): Array<T> | [] => {
  return object
    ? Array.isArray(object)
      ? object
      : Object.keys(object).some((id) => isNaN(Number(id)))
      ? [object as T]
      : Object.values(object)
    : EMPTY_ARRAY_DATA;
};

export const createArrayFromErrorObject = <T extends Partial<Record<TableColumnName, any> | string>>(
  object?: IndexedArray<T> | Array<T> | null | (object & { message: string })
): Array<T> | [] => {
  if (!object) return EMPTY_ARRAY_DATA;
  if (!Array.isArray(object)) {
    const values = Object.values(object);
    if (values.some((value) => isObject(value))) {
      return values.reduce((result, value) => {
        if (isObject(value)) {
          return result as Array<T>;
        }
        return [...result, value];
      }, [] as any);
    }
    return Object.values(object) as Array<T>;
  }
  return Object.entries(object).some(([, value]) => typeof value === 'object' && value)
    ? object.reduce((result, elem) => {
        const error = Object.values(elem);

        if (Array.isArray(error)) {
          return [...result, ...error];
        }

        return result;
      }, [] as any)
    : object;
};
