import { Dictionary, ReferenceTablesData, TableColumnName } from '@common/types';
import { catalogDictionaryDataSelector } from '@modules/catalog/selectors/options/options';
import { createSelector } from 'reselect';
import { DEFAULT_FIELD_NAMES } from '@common/constants/options';
import { getOnlySurveillanceZone } from '@common/models/zone/decorators/get-only-surveillance-zone';
import { filterCatalogOptions } from '@common/components/data-display/table/utils';
import { filterCatalogOptionsWithRecord } from '@common/components/data-display/table/utils/helpers/filter-catalog-options-with-record';

export const depotTableOptionsSelector = createSelector(
  catalogDictionaryDataSelector(Dictionary.Contact),
  catalogDictionaryDataSelector(Dictionary.Worktime),
  catalogDictionaryDataSelector(Dictionary.DepotOverload),
  catalogDictionaryDataSelector(Dictionary.Restriction),
  catalogDictionaryDataSelector(Dictionary.TripTemplate),
  catalogDictionaryDataSelector(Dictionary.Zone),
  (Contact, Worktime, DepotOverload, Restriction, TripTemplate, Zone) => {
    const refData: ReferenceTablesData = {
      ContactID: {
        options: Contact,
        dictionary: Dictionary.Contact,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      WorktimeID: {
        options: filterCatalogOptions(Worktime),
        dictionary: Dictionary.Worktime,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      DepotOverloadID: {
        options: filterCatalogOptionsWithRecord(DepotOverload, TableColumnName.DepotOverload),
        dictionary: Dictionary.DepotOverload,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      RestrictionID: {
        options: filterCatalogOptionsWithRecord(Restriction, TableColumnName.Restriction),
        dictionary: Dictionary.Restriction,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      TripTemplateID: {
        options: filterCatalogOptionsWithRecord(TripTemplate, TableColumnName.TripTemplate),
        dictionary: Dictionary.TripTemplate,
        fieldNames: DEFAULT_FIELD_NAMES
      },
      ZoneID: {
        options: filterCatalogOptions(getOnlySurveillanceZone(Zone) || []),
        dictionary: Dictionary.Zone,
        fieldNames: DEFAULT_FIELD_NAMES
      }
    };
    return { refData };
  }
);
