import { IILSCellBoolStringProps } from '@common/types/components/table/cell';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons/lib/icons';
import { TableRecord } from '@common/types';
import { isBoolean, isNil, isNumber } from 'lodash';
import { showUserAndDevError } from '@common/utils/helpers/show-error/show-user-and-dev-error';

export const ILSCellBoolString = <R extends TableRecord>({ children, toggleEdit }: IILSCellBoolStringProps<R>): JSX.Element => {
  const [node, isChecked = false] = children;

  if (!(isBoolean(isChecked) || isNumber(isChecked) || isNil(isChecked))) {
    showUserAndDevError({ error: 'Значение приводится к типу Boolean для корректного отображения' });
    return <></>;
  }

  return (
    <span onClick={toggleEdit} className={'cell-wrapper-editable'}>
      {node}
      {isChecked ? <CheckOutlined className={'ils-svg success pointer'} /> : <CloseOutlined className={'ils-svg error pointer'} />}
    </span>
  );
};
