import { ILSTooltip } from '@components/index';
import { getSRCIconSVG } from '@common/decorators/path';
import { OrderData } from '@modules/planning/children/by-date/types';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';

const noToFixedKeys = ['clients'];

const ToolTips = {
  clients: 'Клиенты',
  mass: 'Вес (кг)',
  volume: 'Объем (м3)',
  pallets: 'Паллеты (шт.)'
};

export const ILSOrderDataSummary: FC<{
  orderSummary: OrderData;
}> = ({ orderSummary }) => {
  return (
    <div className="ils-order-data-summary">
      {Object.entries(orderSummary ?? {}).map(([key, value], index) => {
        return (
          !Array.isArray(value) && (
            <ILSTooltip key={`order-tooltip-${key + index}`} title={ToolTips[key]}>
              <div className="ils-order-data-item" key={key + index}>
                <ReactSVG src={getSRCIconSVG(`order-${key}`)} />
                <span className="ils-order-data-value">{!noToFixedKeys.includes(key) ? value?.toFixed(2) : value}</span>
              </div>
            </ILSTooltip>
          )
        );
      })}
    </div>
  );
};
