import { useEffect, useState } from 'react';

export const useSearchElement = (handleFilter?: (search: string) => void) => {
  const [search, setSearch] = useState<string | null>(null);

  const onSearch = (e: any) => {
    setSearch(e.target?.value || '');
  };
  useEffect(() => {
    const t = setTimeout(() => handleFilter && handleFilter(search || ''), 800);
    return () => clearTimeout(t);
  }, [search]);

  return onSearch;
};
