import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { trackGetTrackRoutine } from '../actions';
import { PointTypeApi } from '@common/utils/get-track/types';
import initialState from '@modules/track/store';
import { getTrackState } from '@common/utils/get-track/get-track-state';
import { PayloadActionError } from '@common/types';

const getTrack = createReducer(initialState, {
  [trackGetTrackRoutine.REQUEST]: (state, action: PayloadAction<{ points: Record<string, PointTypeApi> }>) => {
    const { points } = action.payload;
    state.planPoints.isFetching = true;
    state.planPoints.trackData = points;
    return state;
  },
  [trackGetTrackRoutine.SUCCESS]: (state, action: PayloadAction<{ data: any }>) => {
    const { data } = action.payload;
    state.planPoints.trackData = getTrackState(data, state.planPoints.trackData);
    state.planPoints.isFetching = false;
    return state;
  },
  [trackGetTrackRoutine.FAILURE]: (state, action: PayloadActionError) => {
    const { error } = action.payload;
    state.planPoints.isFetching = false;
    state.error = error;
    return state;
  },
  [trackGetTrackRoutine.FULFILL]: (state) => {
    state.error = [];
    return state;
  }
});

export default getTrack;
