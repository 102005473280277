import { ILSUserConfirm } from '@common/components';
import { planningSetParamsRoutine } from '@modules/planning/actions';
import { Button } from 'antd';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const ParamsSaveButtons = () => {
  const dispatch = useDispatch();
  const onSavePlanningParams = useCallback(
    (resetParams = false) =>
      () => {
        ILSUserConfirm({
          text: resetParams
            ? 'Вы действительно хотите сбросить настройки планирования для текущего аккаунта?'
            : 'Сохранить настройки планирования для текущего аккаунта?',
          onConfirm: () => dispatch(planningSetParamsRoutine({ resetParams })),
          onConfirmText: 'Да',
          onCloseText: 'Нет'
        });
      },
    []
  );
  return (
    <div className="planning-params-save-buttons">
      <Button type="primary" ghost onClick={onSavePlanningParams()}>
        Сохранить параметры
      </Button>
      <Button type="primary" ghost danger onClick={onSavePlanningParams(true)}>
        Восстановить параметры
      </Button>
    </div>
  );
};

