import { ILSButton } from '@components/index';
import { FC, MouseEvent } from 'react';
import { ReactSVG } from 'react-svg';
import { PlansTableRecord, RoutesTableRecord } from '@modules/planning/children/by-date/types/record';

interface IILSPCMH {
  record: RoutesTableRecord | PlansTableRecord;
  //TODO что такое b1 и b2?
  onContextClose(e: MouseEvent, b1?: boolean, b2?: boolean): void;
}

export const ILSPlanContextMenuHead: FC<IILSPCMH> = ({ record, onContextClose }) => {
  return (
    <div className="py-1 pl-3 pr-1 menu-title flex-between-center">
      <div>
        {record.FullName}
        {'Vehicle' in record && record.Vehicle?.Deleted && ' (удален)'}
      </div>
      <ILSButton
        className="p-2"
        onClick={onContextClose}
        type="link"
        icon={<ReactSVG className="svg-container" src={'/assets/icons/close.svg'} />}
      />
    </div>
  );
};
