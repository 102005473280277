import { ILSCheckbox, ILSFormItem, ILSInput } from '@common/components';
import { MIN_NUMBER_INPUT_VALUE } from '@modules/orders-and-trips/constants/form';
import { FormFieldNames, FormSections } from '@modules/orders-and-trips/types/form';
import { Label } from '@components/common/forms/constants';
import { FC } from 'react';

export const AdditionalOptionsBlock: FC = () => {
  return (
    <div className="additional-options">
      <div className="flex-horizontal group">
        <ILSFormItem name={[FormSections.TransportRequirement, FormFieldNames.HydroBoard]} valuePropName="checked">
          <ILSCheckbox label={Label.HydroBoard} />
        </ILSFormItem>
        <ILSFormItem name={[FormSections.TransportRequirement, FormFieldNames.LoadersCount]}>
          <ILSCheckbox label={Label.LoadersCount} />
        </ILSFormItem>
      </div>
      <ILSFormItem
        name={[FormSections.TransportRequirement, FormFieldNames.GPSMonitoring]}
        valuePropName="checked"
        label={Label.GPSMonitoring}
      >
        <ILSCheckbox label={Label.GPSMonitoringDescription} />
      </ILSFormItem>
      <ILSFormItem name={[FormSections.TransportRequirement, FormFieldNames.AmountVehicle]}>
        <ILSInput type="number" addonBefore={Label.AmountVehicle} min={MIN_NUMBER_INPUT_VALUE} />
      </ILSFormItem>
      <ILSFormItem name={[FormSections.TransportRequirement, FormFieldNames.LoadCapacity]}>
        <ILSInput type="number" addonBefore={Label.LoadCapacity} min={MIN_NUMBER_INPUT_VALUE} />
      </ILSFormItem>
      <ILSFormItem name={[FormSections.TransportRequirement, FormFieldNames.Belts]}>
        <ILSInput type="number" addonBefore={Label.Belts} min={MIN_NUMBER_INPUT_VALUE} />
      </ILSFormItem>
    </div>
  );
};
