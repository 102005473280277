import { createSelector } from 'reselect';
import { TenderOwnState } from '@modules/tender-platform/children/tender-own/types/store';

export const fetchSelector = createSelector(
  (state: TenderOwnState) => state.TenderOwn.isFetching,
  (isFetching: boolean) => isFetching
);

export {
  lotContactFieldsSelector,
  offerTableCargoOwnerSelector,
  ownListSelector,
  tenderPlatformCarriersFormSelector,
  routesDataSelector,
  routeListSelector
} from '@modules/tender-platform/children/tender-own/selectors/components';

export { auctionCargoOwnerSelector } from '@modules/tender-platform/children/tender-own/selectors/auction';
export {
  carriersFetchingSelector,
  carriersListSelector,
  myCarriersSelector,
  carriersFullSelector,
  carriersListLoadingSelector,
  addedParticipantsSelector,
  carriersListArraySelector,
  myCarriersArraySelector,
  myCarriersIDsSelector,
  tenderParticipantsIDSelector
} from '@modules/tender-platform/children/tender-own/selectors/carriers';
export {
  contactsLoadingSelector,
  contactsOptionsSelector,
  contactsSelector,
  contactsErrorSelector
} from '@modules/tender-platform/children/tender-own/selectors/contacts';
export { restrictionsSelector } from '@modules/tender-platform/children/tender-own/selectors/restrictions';
export {
  tenderActionSelector,
  lotTemplateListSelector,
  tendersSelector,
  dataForGroupTenderCreationSelector,
  dataRoutesEditingSelector,
  routesSelector
} from '@modules/tender-platform/children/tender-own/selectors/tender';
export { tenderByStatusSelector } from '@modules/tender-platform/children/tender-own/selectors/tenders-by-status';
