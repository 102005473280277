import { FC } from 'react';
import { ILSFormItem } from '@components/index';
import { ILSSuggestionAutoComplete } from '@common/feature/suggestion-autocomplete/containers/suggestion-autocomplete';
import { Field } from '@common/constants';
import { TenderFormTableLabel } from '@modules/tender-platform/constants/content';
import { REGIONAL_CITIES_FORM_ITEMS } from '@modules/tender-platform/constants/form';
import cn from 'classnames';

interface IProps {
  disabled: boolean;
}

export const TenderPlatformRegionalCitiesComponent: FC<IProps> = ({ disabled }) => {
  return (
    <>
      {REGIONAL_CITIES_FORM_ITEMS.map((direction) => {
        const className = cn(direction, 'no-margin tender-form-fieldset-item');
        return (
          <ILSFormItem
            key={direction}
            name={direction}
            label={TenderFormTableLabel[direction]}
            className={className}
            rules={[
              {
                required: !disabled,
                message: 'Пожалуйста, введите название города'
              }
            ]}
          >
            <ILSSuggestionAutoComplete disabled={disabled} field={Field.City} />
          </ILSFormItem>
        );
      })}
    </>
  );
};
