export enum ParamsAction {
  GET_CONFIGS = 'GET_CONFIGS',
  CREATE_CONFIG = 'CREATE_CONFIG',
  UPDATE_CONFIG = 'UPDATE_CONFIG',
  DELETE_CONFIG = 'DELETE_CONFIG',
  IMPORT_CONFIG = 'IMPORT_CONFIG',
  GET_TEMPLATE = 'GET_TEMPLATE'
}

export const defaultStatuses: {
  [key in ParamsAction]: {
    loading: string;
    success: string;
    successPlural?: string;
    error?: string;
  };
} = {
  GET_CONFIGS: { loading: 'Загрузка...', success: 'Загружено' },
  CREATE_CONFIG: { loading: 'Загрузка...', success: 'Загружено' },
  UPDATE_CONFIG: { loading: 'Обновление...', success: 'Обновлено' },
  DELETE_CONFIG: { loading: 'Удаление...', success: 'Удалено' },
  IMPORT_CONFIG: { loading: 'Импорт...', success: 'Импортировано' },
  GET_TEMPLATE: { loading: 'Загрузка...', success: 'Загружено' }
};
