import { getCurrentProductVersion } from '@core/persist/get-current-product-version';
import { changeProductVersion } from '@common/components/feedback/change-product-version/change-product-version';
import { productVersionRequest } from '@common/types/general/product-version';

const compareProductVersion = (store: any) => {
  const currentProdVersion = getCurrentProductVersion();
  fetch(`${window.location.origin}/${productVersionRequest.file}`).then((response) => {
    response.json().then((data) => {
      if (currentProdVersion && data?.productVersion && currentProdVersion !== data.productVersion) {
        changeProductVersion({ store });
      }
    });
  });
};

export const runCheckProductVersion = (store: any) => {
  compareProductVersion(store);
  return setInterval(() => {
    compareProductVersion(store);
  }, productVersionRequest.interval);
};

