import { ModulePath, ModuleTitle } from '@common/types';
import { defineModule } from '@core/index';
import { getAllModuleSagas } from '@core/sagas';
import { ModuleScope } from '@core/types';
import { plannerPermissions } from '@modules/planning/abac/permissions';
import { plannerRules } from '@modules/planning/abac/rules';
import { PlanningByDateContainer } from '@modules/planning/children/by-date/containers/by-date';
import { persistTransform } from './persist';
import planningByDateReducer from './reducers';
import * as planningByDateSagas from './sagas';
import './styles.less';

export default defineModule({
  title: ModuleTitle.Planning,
  path: ModulePath.Planning,
  component: PlanningByDateContainer,
  reducer: planningByDateReducer,
  sagas: getAllModuleSagas(planningByDateSagas),
  api: { Component: PlanningByDateContainer },
  access: {
    rules: plannerRules,
    permissions: plannerPermissions
  },
  permission: [plannerPermissions.PLANNER_SUBMODULES],
  inScope: [ModuleScope.DocumentBuilder],
  persist: persistTransform
});
