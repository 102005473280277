import { IEnum } from '@common/types';
import { ActionTypeID, DraftTripNOrder } from '@modules/orders-and-trips/types/trip';
import { FormFieldNames, FormSections } from '@modules/orders-and-trips/types/form';
import { isEmpty, isNumber } from 'lodash';
import { doubleToTime } from '@common/utils';
import moment from 'moment';
import { ILS_MAIN_INTERFFACE_TIME_FORMAT } from '@common/types/general/date-time';
import { cargoDataToForm } from '@modules/orders-and-trips/decorators/cargo-data-to-form';
import { VehicleLoadType } from '@modules/tender-platform/types';
import { DEFAULT_VEHILCE_BODY_TYPE } from '@modules/orders-and-trips/constants/form';

export const tripNOrderDataToForm = (data: { draftTripNOrder: DraftTripNOrder; restriction: IEnum<string> }) => {
  const { draftTripNOrder } = data;
  if (isEmpty(draftTripNOrder)) return {};
  return draftTripNOrder?.reduce((tripPoints, point, index) => {
    const isLoad = point.ActionType === ActionTypeID.Load;
    return {
      ...tripPoints,
      [index]: {
        [isLoad ? FormFieldNames.LoadDate : FormFieldNames.UnloadDate]: isNumber(point.Date)
          ? moment.unix(point.Date).utcOffset(point.UTCOffset)
          : undefined,
        [isLoad ? FormFieldNames.LoadEnd : FormFieldNames.UnloadEnd]: point.TimeEnd
          ? moment(doubleToTime(point.TimeEnd), ILS_MAIN_INTERFFACE_TIME_FORMAT)
          : null,
        [isLoad ? FormFieldNames.LoadStart : FormFieldNames.UnloadStart]: point.TimeStart
          ? moment(doubleToTime(point.TimeStart), ILS_MAIN_INTERFFACE_TIME_FORMAT)
          : null,
        [isLoad ? FormFieldNames.SourceID : FormFieldNames.TargetID]: point.DepotName,
        [FormFieldNames.Cargo]: cargoDataToForm(point.Cargo)
      },
      [FormSections.TransportRequirement]: {
        [FormFieldNames.VehicleLoadType]: VehicleLoadType.Back.toString(),
        [FormFieldNames.VehicleUnloadType]: VehicleLoadType.Back.toString(),
        [FormFieldNames.VehicleType]: DEFAULT_VEHILCE_BODY_TYPE
      }
    };
  }, {});
};

