import { FC, useEffect, useState } from 'react';
import { ILSPlanningDataModalConflict } from '@modules/planning/children/data/components';
import { useSelector } from 'react-redux';
import { fetchSelector, uploadConflictSelector } from '@modules/planning/children/data/selectors';
import { useModal } from '@common/hooks/components/use-modal';
import { UploadFile } from 'antd/es/upload/interface';
import { PlannerDataTabsContainer } from '@modules/planning/children/data/containers/upload/tabs';

export const PlannerDocumentUploadContainer: FC = () => {
  const isFetching = useSelector(fetchSelector);
  const [file, setFile] = useState<File | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { hasConflict, type } = useSelector(uploadConflictSelector);

  const { visible, handleShow } = useModal(hasConflict);

  useEffect(() => {
    if (hasConflict) {
      handleShow();
    }
  }, [hasConflict]);

  return (
    <>
      <PlannerDataTabsContainer fileList={fileList} setFile={setFile} setFileList={setFileList} isFetching={isFetching} />
      <ILSPlanningDataModalConflict
        setFileList={setFileList}
        fileList={fileList}
        file={file}
        type={type}
        handleShow={handleShow}
        visible={visible}
        isFetching={isFetching}
      />
    </>
  );
};
