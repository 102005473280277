import { isEmpty, isNil, isString } from 'lodash';
import { Keys, TableRecord } from '@common/types';
import { getSelectedRowsWithoutGroupIDs } from '@common/components/data-display/table/utils';
import { findRowByIDInNestedDataSource } from '@modules/catalog/helpers';

/**
 * Функция получает массив ID выделенных строк и возвращает ID cтрок, у которых ключ Deleted true.
 * Сначала проверяет, есть ли группирующие строки в массиве ID, тогда ищем по вложенным элементам.
 * @param selectedRowKeys массив ID выделенных строк.
 * @param dataSource Array<Record<string, any>> - данные таблицы
 * @returns массив ID элементов на удаление
 */

export const getRowsIDsToRestore = (selectedRowKeys: Keys = [], dataSource?: any) => {
  if (isEmpty(selectedRowKeys) || isNil(dataSource)) return undefined;

  const hasGrouping = selectedRowKeys?.some(isString);
  let rows;

  if (hasGrouping) {
    const idsWithoutGroupIDs = getSelectedRowsWithoutGroupIDs(selectedRowKeys);
    rows = idsWithoutGroupIDs.reduce((result: TableRecord[], id) => {
      const row = findRowByIDInNestedDataSource(dataSource, id);
      if (row?.Deleted) {
        result.push(row.ID);
      }
      return result;
    }, []);
  } else {
    rows = dataSource?.reduce((result: number[], row: TableRecord) => {
      if (row?.ID && row?.Deleted && selectedRowKeys?.includes(Number(row?.ID))) {
        result.push(Number(row?.ID));
      }
      return result;
    }, [] as number[]);
  }

  return rows;
};

