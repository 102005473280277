import { ActiveType, Dragging, Keys, MarkerPlanning, MarkerType } from '@common/types';
import { ILSMap } from '@core/containers/map';
import { first, isArray, isEmpty, isNil, isNumber } from 'lodash';
import { MutableRefObject, useEffect } from 'react';
import { MapWaypointData, SelectedOrdersLasso, SelectedOrdersLassoType } from '../../types/map-container';
import { SelectedOrders } from '@modules/planning/types';
import { Waypoint } from '@modules/track/types';
import { MarkerDataAttribute } from '@common/types/components/map/map';

//TODO refactoring
//дополнительные настройки по сдвигу невидимого d&d маркера
const RIGHT_OFFSET = 25;
const TOP_OFFSET = 60;
const SIZE_WIDTH_MULT_OFFSET = 0.25;

export const useDragDrop = (
  Map: MutableRefObject<ILSMap | null>,
  dragging: Dragging | null,
  selectedOrdersLasso: SelectedOrdersLasso,
  markerDragging: boolean,
  marker: MarkerPlanning[],
  waypointMapData: Record<string, MapWaypointData>,
  setDragging: (d: Dragging | null) => void
) => {
  useEffect(() => {
    if (Map.current) {
      const onMouseMove = (e: any) =>
        onMouseMoveUnderMarker(e, dragging, selectedOrdersLasso, waypointMapData, callbackDragging, setDragging);
      Map.current.mapMouseMove(onMouseMove, !markerDragging);
    }
  }, [marker, selectedOrdersLasso, markerDragging, waypointMapData]);
};

const getOrderIDs = (
  ordersIDs: SelectedOrders | undefined,
  selected: boolean | undefined,
  selectedLassoOrdersIDs: Keys | undefined
): Keys | undefined | null => {
  if (selected && !isNil(selectedLassoOrdersIDs) && !isEmpty(selectedLassoOrdersIDs)) {
    return selectedLassoOrdersIDs?.filter(isNumber);
  }
  return ordersIDs?.filter(isNumber);
};
const getWaypointsIDs = (
  waypointIDs: Array<Waypoint['ID']> | undefined,
  selected: boolean | undefined,
  selectedOrdersLasso: SelectedOrdersLasso
): Array<number> | undefined => {
  if (
    selected &&
    selectedOrdersLasso?.type === SelectedOrdersLassoType.ResolvedOrder &&
    !isNil(selectedOrdersLasso.waypointIDs) &&
    !isEmpty(selectedOrdersLasso.waypointIDs)
  ) {
    return selectedOrdersLasso.waypointIDs?.map(Number);
  }
  return waypointIDs?.map(Number);
};

const getTypeDDMarker = (element: HTMLElement): MarkerType | undefined => {
  const attributeType = element?.getAttribute(MarkerDataAttribute.MarkerType);
  if (!isNil(attributeType) && Object.values(MarkerType).includes(attributeType as MarkerType)) {
    return element.getAttribute(MarkerDataAttribute.MarkerType) as MarkerType;
  }
  const elementType = element.className.substring(element.className.indexOf('marker-') + 7, element.className.indexOf(' '));
  if (Object.values(MarkerType).includes(elementType as MarkerType)) {
    return elementType as MarkerType;
  }
  console.error('Тип маркера не определен'); //TODO удалить
  return undefined;
};

export const callbackDragging = (
  draggingNew: Partial<Dragging>,
  dragging: Dragging | null,
  selectedOrdersLasso: SelectedOrdersLasso,
  setDragging: (d: Dragging | null) => void
) => {
  {
    const { x, y, data, selected, type, className, width, height, originalID, orderIDs, waypointIDs } = draggingNew;
    const orders = getOrderIDs(orderIDs, selected, selectedOrdersLasso?.orderIDs);

    const waypoints = getWaypointsIDs(waypointIDs, selected, selectedOrdersLasso);
    setDragging({
      ...dragging,
      x,
      y,
      orderIDs: orders,
      waypointIDs: waypoints,
      originalID: originalID as number | undefined,
      data,
      selected,
      type,
      className,
      width,
      height
    });
  }
};

const getDataFromElement = (element: HTMLElement, type: MarkerType | undefined, mapWaypointData: Record<string, MapWaypointData>) => {
  const waypointIDs: number[] = [];
  const orderIDs: number[] = [];
  if (type) {
    switch (type) {
      case MarkerType.Point:
        const wpID = Number(element.getAttribute(MarkerDataAttribute.WaypointID));
        const { sourceOrders, targetOrders } = mapWaypointData[wpID];
        waypointIDs.push(wpID);
        if (!isNil(sourceOrders)) {
          orderIDs.push(...sourceOrders);
        }
        if (!isNil(targetOrders)) {
          orderIDs.push(...targetOrders);
        }

        break;
      case MarkerType.OrderCluster:
        const ordersJson = element.getAttribute(MarkerDataAttribute.OrderIDs) || '';
        const clusterOrders = JSON.parse(ordersJson);
        if (isArray(clusterOrders)) {
          orderIDs.push(...clusterOrders.map(Number));
        }
        break;
      case MarkerType.Order:
        const orderID = element.getAttribute(MarkerDataAttribute.OrderID) || '';
        orderIDs.push(Number(orderID));
        break;
      default:
        break;
    }
  }
  //TODO проверить
  return { waypointIDs, orderIDs };
};
export const onMouseMoveUnderMarker = (
  e: any,
  dragging: Dragging | null,
  selectedOrdersLasso: SelectedOrdersLasso,
  waypointMapData: Record<string, MapWaypointData>,
  onDragging: typeof callbackDragging,
  setDragging: (d: Dragging | null) => void
) => {
  let originalTarget = null;
  let element: HTMLElement | null = null;

  if (e.originalEvent.srcElement?.id?.indexOf('marker_') > -1) {
    element = e.originalEvent.srcElement;
  } else if (e.originalEvent?.srcElement.tagName === 'SPAN' && e.originalEvent?.path?.length) {
    element = e.originalEvent?.path[1];
  }

  originalTarget = element;
  if (e.originalEvent.srcElement?.id !== 'ils-map') {
    const closestMarker = e.originalEvent?.srcElement?.closest?.('[id*=marker]');

    if (closestMarker) {
      element = closestMarker;
    }
  }
  if (!element && e.originalEvent?.path?.length && e.originalEvent?.srcElement.tagName === 'SPAN') {
    e.originalEvent?.path.slice(0, 2).forEach((el: any) => {
      if (el.id && el.id.indexOf('marker_') > -1) {
        element = el;
        originalTarget = el;
      }
    });
  }
  if (!element) {
    if (e.originalEvent.srcElement.parentElement && e.originalEvent.srcElement.parentElement.id?.indexOf('marker_') > -1) {
      element = e.originalEvent.srcElement.parentElement;
      originalTarget = e.originalEvent.srcElement.parentElement;
    }
  }
  if (element && element.id?.indexOf('marker_') > -1 && e.containerPoint.x && e.containerPoint.y) {
    const markerID = element.id
      .replace('marker_', '')
      .split(',')
      .reduce((acc: number[], cur: string) => {
        if (cur && !Number.isNaN(Number(cur)) && !acc.includes(Number(cur))) {
          acc.push(Number(cur));
        }
        return acc;
      }, []);
    const selected = element.className.includes(ActiveType.Selected);

    const type = getTypeDDMarker(element);

    const className = element.className.replace('marker-', '');

    const data = element.textContent;
    const target = originalTarget?.getBoundingClientRect() ?? e.originalEvent.target.getBoundingClientRect();
    const { right, width, height, top } = target;
    //абсолютное позиционирование
    const x = document.body.offsetWidth - right - SIZE_WIDTH_MULT_OFFSET * width - RIGHT_OFFSET;
    const y = top - TOP_OFFSET;
    const originalID = first(markerID);

    const { waypointIDs, orderIDs } = getDataFromElement(element, type, waypointMapData);

    onDragging(
      {
        x,
        y,
        orderIDs,
        waypointIDs,
        originalID,
        data,
        selected,
        type,
        className,
        width,
        height
      },
      dragging,
      selectedOrdersLasso,
      setDragging
    );
  }
};

