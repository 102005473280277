import { CellType, TableConfig } from '@common/types';
import { NEW_ROAD_TRAIN } from '@common/models/roadtrain/config/index';
import { ID_COLUMN } from '@common/models/all/columns';

export const _ROAD_TRAIN_TABLE_CONFIG: TableConfig = {
  deleting: true,
  selecting: false,
  copying: false,
  adding: NEW_ROAD_TRAIN,
  reloading: true,
  columns: [
    ID_COLUMN,
    {
      title: 'Автомобиль',
      dataIndex: 'VehicleID',
      key: 'VehicleID',
      invisible: true,
      editable: false,
      width: 80,
      type: CellType.Uint
    },
    {
      title: 'Прицеп 1',
      dataIndex: 'Trailer1ID',
      key: 'Trailer1ID',
      active: true,

      editable: true,
      width: 190,
      ellipsis: true,
      isFixedColumn: true,
      type: CellType.SelectAPI
    },
    {
      title: 'Прицеп 2',
      dataIndex: 'Trailer2ID',
      key: 'Trailer2ID',
      active: true,

      editable: true,
      width: 190,
      ellipsis: true,
      isFixedColumn: true,
      type: CellType.SelectAPI
    },
    {
      title: 'Код',
      dataIndex: 'RoadTrainCode',
      key: 'RoadTrainCode',
      active: true,
      editable: true,

      ellipsis: true,
      type: CellType.Input,

      align: 'center',
      isFixedColumn: true
    },
    {
      title: 'Для проекта',
      dataIndex: 'ProjectID',
      key: 'ProjectID',
      invisible: true,
      editable: false,
      required: false,
      type: CellType.Uint,
      width: 80,
      align: 'center',
      ellipsis: true,
      isFixedColumn: true
    }
  ]
};
