import { call, put, takeLatest } from 'redux-saga/effects';
import { accountAttachmentIndexRoutine } from '@modules/account/actions';
import { AccountAttachmentAPI } from '@modules/account/api/account-attachment';
import { PayloadAction } from '@reduxjs/toolkit';
import { AccountAttachmentCategory, AccountAttachmentsDependent } from '@modules/account/types/attachments';

export function* getAccountAttachmentsWorker(
  action: PayloadAction<{
    dependentID: number;
    dependent: AccountAttachmentsDependent.Account;
    accountAttachmentCategory: AccountAttachmentCategory;
  }>
) {
  const { failure, fulfill, success } = accountAttachmentIndexRoutine;
  const { dependentID, dependent, accountAttachmentCategory } = action.payload;

  try {
    const {
      data: { data }
    } = yield call(AccountAttachmentAPI.index, { dependentID, dependent });
    yield put(success({ data, accountAttachmentCategory }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getAccountAttachmentsWatcher() {
  yield takeLatest(accountAttachmentIndexRoutine.trigger, getAccountAttachmentsWorker);
}
