import { useDispatch } from 'react-redux';
import { ProjectLogisticsFromValues, ProjectLogisticsRequest } from '@modules/orders-and-trips/types/project-logistics';
import { getProjectLogisticPayload } from '@modules/orders-and-trips/helpers/project-logistics/get-project-logistic-payload';
import { sendProjectLogisticsFormRoutine } from '@modules/orders-and-trips/actions';

export const useProjectLogisticsForm = () => {
  const dispatch = useDispatch();
  const sendProjectLogisticForm = (fields: ProjectLogisticsFromValues) => {
    const payload: ProjectLogisticsRequest = getProjectLogisticPayload(fields);
    dispatch(sendProjectLogisticsFormRoutine(payload));
  };

  return { sendProjectLogisticForm };
};
