import { DepotCode, VehicleModel } from '@common/types';
import { MonitoringWaypointModel } from '@common/types/dictionaries/monitoring-waypoint';
import { isEmpty } from 'lodash';

/**
 * Проверяет учитывать ли точку маршрута при подсчетах
 */
export const isShouldWaypointBeTaken = <T extends MonitoringWaypointModel>(
  waypoint: T | null | undefined,
  vehicle: VehicleModel
): waypoint is Exclude<T, undefined | null> => {
  if (isEmpty(waypoint?.Depot)) {
    return false;
  }
  return !(
    waypoint?.Depot?.DepotTypeID === Number(DepotCode.Storage) ||
    waypoint?.Depot?.ID === Number(vehicle.StartDepotID) ||
    waypoint?.Depot?.ID === Number(vehicle.EndDepotID)
  );
};

