import { FC } from 'react';
import { ILSFormItem, ILSSelect } from '@common/components';
import { IILSSelectProps } from '@components/data-entry/select/types';
import { IFormItemProps } from '@components/common/forms/vehicle/components/form-items/vehicle-type-form-field';
import { VEHICLE_PROFILE_TYPE_OPTIONS } from '@components/common/forms/vehicle/constants/options';
import { Field, Label } from '@components/common/forms/constants';

interface IProps extends IFormItemProps {
  selectProps?: IILSSelectProps;
}

export const VehicleProfileTypeFormField: FC<IProps> = ({ selectProps, ...props }) => {
  const name = [Field.VehicleProfile, Field.Mass];
  const label = Label[name.join('')];
  const info = 'Максимальный вес перевозки 20 тонн';
  const options = selectProps?.options ?? VEHICLE_PROFILE_TYPE_OPTIONS;

  return (
    <ILSFormItem tooltip={info} name={name} label={label} {...props}>
      <ILSSelect dropdownMatchSelectWidth={false} options={options} {...selectProps} />
    </ILSFormItem>
  );
};
