import { DictionaryAPI } from '@common/api/common';
import {
  ILSCheckbox,
  ILSInput,
  ILSModal,
  ILSSelect,
  ILSSelectOption,
  ILSTreeSelect,
  ILSTypographyText,
  ILSUserNotify
} from '@common/components';
import { Dictionary, Notify } from '@common/types';
import { Spin, Typography } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { exportDataRows } from './data';
import SelectItems from './selelect-items';
import { ExportData, ExportSettingsModalComponentProps } from './types';
import { getErrorMessage, string2ArrayBuffer } from '@common/utils';

import './style.less';

function transformExportData(data: ExportData[], values: string[]): { name: string }[] {
  let res: { name: string }[] = [];

  data.forEach((item) => {
    if (values.includes(item.value)) {
      res.push({
        name: item.value
      });
    }
    if (item.children?.length) {
      res.push(...transformExportData(item.children, values));
    }
  });

  return res;
}

const ExportSettingsModalComponent: FC<ExportSettingsModalComponentProps> = ({ visible, onCancel, onClose, dictionary }) => {
  const [data, setData] = useState<string[]>();
  const [name, setName] = useState<string>('');
  const [format, setFormat] = useState<string>('');
  const [isHeader, setIsHeader] = useState({
    label: 'header',
    value: false
  });

  const exportData: {
    data: ExportData[];
    dictionary: Dictionary;
  } = useMemo(() => {
    if (dictionary && exportDataRows[dictionary]) {
      return {
        data: exportDataRows[dictionary],
        dictionary
      };
    } else {
      return {
        dictionary: Dictionary.Empty,
        data: []
      };
    }
  }, [dictionary]);

  const [file, setFile] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      let bin = atob(file);
      let data = string2ArrayBuffer(bin);
      let mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
      let blob = new Blob([data], { type: mediaType });

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name || 'Файл'}.${format || 'xlsx'}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setFile(null);
    }
  }, [file]);

  const onOk = () => {
    const filtered = transformExportData(exportDataRows[dictionary], data!);

    const filters = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] == null) {
        delete filters[key];
      }
    });

    if (!filtered.length) {
      ILSUserNotify(Notify.Warning, 'Выберите столбцы', 2);
      return;
    }

    setLoading(true);

    if (filtered.length) {
      DictionaryAPI.export({ headers: filtered, format: 'xlsx', header: isHeader.value ? 1 : 0, dictionary, filters })
        .then(({ data: res }) => {
          setFile(res.data);

          setData([]);
          setFile(null);
          setFormat('');
          setIsHeader({
            label: 'header',
            value: false
          });
          setName('');
          setLoading(false);

          ILSUserNotify(Notify.Success, 'Файл скачан!', 2);
        })
        .catch((err) => {
          ILSUserNotify(Notify.Error, getErrorMessage({ error: err }), 2);
          setLoading(false);
        })
        .finally(onClose);
    }
  };

  const selectedAllValues = exportData.data.map((item) => item.value) ?? [];

  return (
    <ILSModal
      okButtonProps={{
        disabled: !(data?.length && !loading)
      }}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title="Параметры экспорта"
    >
      <Spin spinning={loading}>
        <ILSTypographyText title="Выбрать столбцы" className="title">
          Выбрать столбцы
        </ILSTypographyText>

        <div style={{ marginBottom: '20px' }}>
          <ILSTreeSelect
            treeCheckable={true}
            style={{ width: '100%', margin: '10px 0 5px 0' }}
            dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
            placeholder="Выберите столбцы (обязательное поле)"
            multiple
            value={data}
            onChange={setData}
            allowClear
            maxTagCount={2}
            maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} эл-в ...`}
            treeData={[
              {
                title:
                  data && data?.length > 0 ? (
                    <SelectItems onClick={() => setData([])} text="Снять все" />
                  ) : (
                    <SelectItems onClick={() => setData(selectedAllValues)} text="Выбрать все" />
                  ),
                value: 'xxx',
                disabled: true,
                checkable: false
              },
              ...exportData.data
            ]}
          />

          <Typography.Text type="danger">Обязательное поле</Typography.Text>
        </div>

        <ILSTypographyText title="Выбрать строки" className="title">
          Выбрать строки
        </ILSTypographyText>

        <li className="checkboxland">
          <li>
            <ILSCheckbox
              onChange={(e: any) => {
                setIsHeader({
                  label: 'header',
                  value: e.target.value
                });
              }}
              value={isHeader.label}
              checked={isHeader.value}
            >
              Header
            </ILSCheckbox>
          </li>
        </li>

        <div className="line" />

        <ILSTypographyText title="Параметры экспорта" className="title">
          Параметры экспорта
        </ILSTypographyText>

        <div className="export-settings">
          <div className="export-settings-row">
            <div className="input-form">
              <label>Имя</label>
              <ILSInput placeholder="По умолчанию - Файл " value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="input-form">
              <label>Расширение файла</label>
              <ILSSelect style={{ width: '100px' }} placeholder="xlsx" onChange={(e: string) => setFormat(e)}>
                <ILSSelectOption value={'xlsx'}>xlsx</ILSSelectOption>
                <ILSSelectOption value={'csv'}>cvs</ILSSelectOption>
              </ILSSelect>
            </div>
          </div>
        </div>
      </Spin>
    </ILSModal>
  );
};

export default ExportSettingsModalComponent;
