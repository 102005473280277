import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import initialState from '@modules/tender-platform/store';
import { tenderGetVehicleTypesRoutine } from '@modules/tender-platform/actions';
import { TenderState } from '@modules/tender-platform/types';
import { IndexedArray, VehicleType } from '@common/types';
import { onFailure, onFulfill, onRequest } from '@common/utils/helpers/reducers';

export const tenderGetVehicleTypesReducer = createReducer(initialState, {
  [tenderGetVehicleTypesRoutine.REQUEST]: onRequest,
  [tenderGetVehicleTypesRoutine.FAILURE]: onFailure,
  [tenderGetVehicleTypesRoutine.FULFILL]: onFulfill,
  [tenderGetVehicleTypesRoutine.SUCCESS]: (
    state: TenderState,
    action: PayloadAction<{
      data: IndexedArray<VehicleType>;
    }>
  ) => {
    const { data } = action.payload;
    state.vehicleTypes = data;
    return state;
  }
});
