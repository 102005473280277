import { approveReplicateDocumentRoutine } from '@modules/tender-platform/actions';
import { put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

function* replicateDocumentWorker(action: PayloadAction<{ replicateDocument: boolean }>) {
  const { success, failure } = approveReplicateDocumentRoutine;
  let { replicateDocument } = action.payload;

  replicateDocument = replicateDocument ?? false;

  try {
    yield put(success({ replicateDocument }));
  } catch (error) {
    yield put(failure({ error }));
  }
}

export function* replicateDocumentWatcher() {
  yield takeEvery(approveReplicateDocumentRoutine.trigger, replicateDocumentWorker);
}
