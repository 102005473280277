import { createSelector } from 'reselect';
import { MonitoringState } from '../types/store';
import { Dictionary, ReferenceTablesData } from '@common/types';
import { isEmpty } from 'lodash';
import { compareAsString } from '@common/utils';
import { generateSelect } from '@common/components/decorators';
import { DefaultOptionType } from 'antd/es/select';

// Возвращает привязки трекеров к автомобилям (Monitoring.vehicleTrackers.data)
export const monitoringVehicleTrackersSelector = createSelector(
  (state: { Monitoring: MonitoringState }) => state.Monitoring.vehicleTrackers.data,
  (data) => data
);

//TODO рефакторинг
// Возвращает информацию, когда были обновлены в последний раз привязки трекеров,
// и, обновляется ли сейчас информация(Monitoring.vehicleTrackers)
export const monitoringVehicleTrackersInfoSelector = createSelector(
  (state: { Monitoring: MonitoringState }) => state.Monitoring.vehicleTrackers,
  ({ lastUpdate, isFetching }) => ({ lastUpdate, isFetching })
);

// Возвращает данные по трекерам (Monitoring.trackers.data)
export const monitoringTrackersSelector = createSelector(
  (state: { Monitoring: MonitoringState }) => state.Monitoring.trackers.data,
  (data) => data
);

//Note не применять общие правила trackers и vehicleTrackers не являются взаимозаменяемыми
export const monitoringVehicleTrackerRefDataSelector = createSelector(
  monitoringTrackersSelector,
  monitoringVehicleTrackersSelector,
  (trackers, vehicleTrackers) => {
    const variants: DefaultOptionType[] = !isEmpty(trackers)
      ? (generateSelect({ data: trackers, labelField: 'IMEI', valueField: 'IMEI' }) as DefaultOptionType[])
      : [];

    const vehicleSelect = generateSelect({
      data: vehicleTrackers,
      labelField: 'TrackerIMEI',
      valueField: 'TrackerIMEI'
    }) as DefaultOptionType[];

    vehicleSelect.forEach((vehicleTracker) => {
      if (!variants.find((item) => compareAsString(item.value, vehicleTracker?.value))) {
        variants.push({ ...vehicleTracker, disabled: true });
      }
    });

    const refData: ReferenceTablesData = {
      TrackerIMEI: {
        options: variants,
        dictionary: Dictionary.Tracker,
        fieldNames: { label: 'label', value: 'value' }
      }
    };
    return refData;
  }
);
//TODO рефакторинг
// Возвращает информацию по трекерам - когда были обновлены в последний раз и,
// обновляется ли сейчас информация(Monitoring.trackers)
export const monitoringTrackersInfoSelector = createSelector(
  (state: { Monitoring: MonitoringState }) => state.Monitoring.trackers,
  ({ lastUpdate, isFetching }) => ({ lastUpdate, isFetching })
);

// Возвращает номер IMEI трекера (Monitoring.trackers.data)
export const trackersIMEISelector = createSelector(
  (state: any) => state.Monitoring.trackers.data,
  (data) => data.map((item: any) => item.IMEI)
);

