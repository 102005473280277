import { useAppDispatch, useAppSelector } from '@core/hooks';
import { FC, useEffect, useState } from 'react';
import { emulatorChangeEmulationSpeedRoutine } from '../../actions';
import ILSEmulationSpeedComponent from '../../components/emulation-speed/emulation-speed';
import { EmulatorStatus } from '../../constants';
import { emulationConfigsSelector, planSelector, statusSelector } from '../../selectors';

interface Props {
  createConfig: (emulationSpeed?: number) => void;
}

// Контейнер для селекта для выбора скорости эмуляции

const ILSEmulationSpeedContainer: FC<Props> = ({ createConfig }) => {
  const dispatch = useAppDispatch();
  const { PlanID, EmulationSpeed } = useAppSelector(emulationConfigsSelector);
  const { loadedPlanID } = useAppSelector(planSelector);

  const [emulationSpeed, setEmulationSpeed] = useState<number>(EmulationSpeed || 0);
  const { Status: configStatus } = useAppSelector(emulationConfigsSelector);
  const status = useAppSelector(statusSelector);
  const actualStatus = status ?? configStatus;

  let isDisabled = !!actualStatus && actualStatus !== EmulatorStatus.Error;

  useEffect(() => {
    isDisabled = status === EmulatorStatus.Init || status === EmulatorStatus.Emulation;
  }, [status]);

  const handleChange = (e: number) => {
    setEmulationSpeed(e);
  };

  useEffect(() => {
    if (EmulationSpeed && EmulationSpeed !== emulationSpeed) {
      setEmulationSpeed(EmulationSpeed);
    }
  }, [EmulationSpeed]);

  useEffect(() => {
    if (emulationSpeed !== null && emulationSpeed !== undefined && emulationSpeed !== EmulationSpeed) {
      //Если PlanID в конфигурации, то конфигурация для плана существует
      if (!PlanID) {
        createConfig(emulationSpeed);
      } else if (loadedPlanID) {
        dispatch(emulatorChangeEmulationSpeedRoutine({ emulationSpeed, planID: loadedPlanID }));
      }
    }
  }, [emulationSpeed, PlanID]);

  return <ILSEmulationSpeedComponent value={emulationSpeed} isDisabled={isDisabled} handleChange={handleChange} />;
};

export default ILSEmulationSpeedContainer;
