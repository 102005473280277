import { ILSSelect } from '@components/index';
import { FC } from 'react';
import { BASE_FIELD_NAMES } from '@common/constants/options';
import { Option } from '@common/types';

interface IProps {
  type: string;
  value: string | number;
  attention: string | undefined;
  options: Option[];
  onChange(value: number | boolean | string): void;
}

export const ILSSelectItem: FC<IProps> = ({ onChange, options, value }) => {
  return <ILSSelect value={value} fieldNames={BASE_FIELD_NAMES} options={options} className="w-50" onChange={onChange} />;
};
