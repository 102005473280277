import './styles.less';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Dictionary, IILSTableProps, TableRecord } from '@common/types';
import { ILSTableComponent } from './components/ils-table-component';
import { ILS_TABLE_CLASSNAME } from './constants';
import { useResizeColumn } from './hooks/use-resize-column';
import ExportSettingsModalComponent from '@components/general/export-excel';
import { useTableExportModal } from '@components/data-display/table/hooks/use-table-export-modal';
import { useCreateOrUpdateTableRow } from '@components/data-display/table/hooks/use-create-or-update-table-row';
import { tableConfigSelector } from '@core/selectors/table-custom';
import { useReferenceTable } from '@components/data-display/table/hooks/use-reference-table';
import { ILSReferenceTable } from '@components/data-display/table/components/ils-reference-table';

export const ILSTable = <R extends TableRecord>({
  tableName,
  config,
  dataSource,
  handleDelete,
  handleCreate,
  handleUpdate,
  handleRestore,
  handleCheckDelete,
  handleSave,
  dictionary = Dictionary.Empty,
  defaultPageSize,
  ...props
}: IILSTableProps<R>): JSX.Element => {
  const { newConfig, pageSize } = useSelector(tableConfigSelector(config, tableName));
  const { showReference, setShowReference, handleCloseReferenceTable } = useReferenceTable<R>();
  const { visible, handleClose, handleOpenExportModal } = useTableExportModal(dictionary);

  const { handleCreateOrUpdateTableRow } = useCreateOrUpdateTableRow({
    handleCreate,
    handleSave
  });

  const { onResizeStart, resize, left, tableRef } = useResizeColumn<R>({ newConfig, tableName });

  return (
    <section data-testid={'ils-table'} className={ILS_TABLE_CLASSNAME}>
      <div
        ref={tableRef}
        //TODO not tableWidth, but tableStyle: React.CSSProperties | undefined
        style={{ width: config?.tableWidth ?? '100%' }}
        className={classnames('position-relative table-resize-area', { disabled: resize?.startX })}
      >
        <ILSTableComponent
          {...props}
          handleCreate={handleCreate}
          dictionary={dictionary}
          loading={props.isFetching || props.loading}
          handleCheckDelete={handleCheckDelete}
          handleUpdate={handleUpdate}
          handleRestore={handleRestore}
          tableName={tableName}
          handleDelete={handleDelete}
          handleSave={handleCreateOrUpdateTableRow}
          setShowReference={setShowReference}
          tableRef={tableRef}
          onResizeStart={onResizeStart}
          handleOpenExportModal={handleOpenExportModal}
          pageSize={defaultPageSize ?? pageSize}
          config={newConfig}
          dataSource={dataSource}
        />
        {left && <div className="resizer-line" style={{ left }} />}
      </div>
      {typeof showReference !== 'boolean' && showReference?.reference && (
        <ILSReferenceTable
          visible={Boolean(typeof showReference !== 'boolean' && showReference?.reference)}
          handleCloseReferenceTable={handleCloseReferenceTable}
          reference={showReference.reference}
        />
      )}
      {visible && <ExportSettingsModalComponent visible={visible} onClose={handleClose} dictionary={dictionary} onCancel={handleClose} />}
    </section>
  );
};

