import { Double, Driver, IEnum, ILSAPILatLonLiteral, Order, Vehicle } from '@common/types';
import { tripsTodata } from '@modules/orders-and-trips/decorators/trips-to-data';
import { PaginationType } from '@modules/orders-and-trips/types/general';
import { TenderOutFastDeliveryServices } from '@modules/orders-and-trips/types/integrations';

/** Переключатели статусов */
export enum TripStatus {
  New = 'new',
  Pending = 'pending',
  onRegistration = 'onRegistration',
  Issued = 'issued',
  OnExecution = 'onExecution',
  Finished = 'finished',
  All = 'all',
  Canceled = 'canceled'
}

export enum TripStatusTitle {
  new = 'Новый',
  pending = 'В распределении',
  onRegistration = 'На оформлении',
  issued = 'Оформлен',
  onExecution = 'На исполнении',
  finished = 'Завершен',
  all = 'Все',
  canceled = 'Отменен'
}

/** Тип точки погрузка/разгрузка */
export enum ActionTypeID {
  Load = 1,
  Unload = 0
}

/** Тип погрузки/разгрузки для автомобиля */
export enum LoadingType {
  Rear = 0
}

/** Название ActionType точки погрузка/разгрузка */
export enum TripActionType {
  Load = 'Погрузка',
  Unload = 'Разгрузка'
}

/** Предварительный рейс или заявка для построения формы */
export type DraftTripNOrder = {
  ActionType: ActionTypeID;
  DepotID: number | string;
  DepotName: string;
  Date: string | number;
  TimeStart: Double;
  TimeEnd: Double;
  UTCOffset: number;
  Cargo: {
    ID: number;
    UnitHeight: number;
    UnitLength: number;
    UnitWidth: number;
    Volume: number;
    Mass: number;
    Amount: number;
    Comment: string;
    RestrictionEnum: IEnum<string>;
    OrderID: number;
  }[];
}[];

/** Action точки рейса  */
export type TripAction = {
  ind: number;
  id: number;
  city: string;
  address: string;
  arrivalTime: string;
  arrivalDate: string;
  actionType: number;
  cargoRestriction: string[];
  cargoMass: number;
  latLon?: ILSAPILatLonLiteral | null;
  contactName: string;
  contactPhone: string;
  arrivalTimestamp: number;
  UTCOffset: number;
};

/** Базовый интерфес рейса  */
export interface IBaseTripOfOrdersNTrips {
  status: string | number;
  tripID: number;
  tripSource: number; // откуда получаем рейсы: 0 - планировщик, 1 - тендерная
  actions: TripAction[];
  details: {
    distance: number;
    mass: number;
    volume: number;
    vehicleType: number;
    loadingType: string;
    price: number | null; // стоимость доставки
    service: TenderOutFastDeliveryServices | null; // алиас транспортной компании
    driver: Driver | null;
    vehicle: Vehicle | null;
  };
}

/** Рейс получаемый с сервера  */
export interface TripResponse extends IBaseTripOfOrdersNTrips {
  ID?: string | number;
  orders: Order[];
  created?: number;
}

/** Рейс пропущенный через декоратор для последующего отображения в списке рейсов */
export type DecoratedTrips = ReturnType<typeof tripsTodata>;

export type ITripsData = {
  data: IBaseTripOfOrdersNTrips[];
  pager: Required<PaginationType>;
};

/** Документы рейса. (Предварительно тип документа использую как в ЦЭ) */
export type TripDocumentsType = {
  [K in number]: {
    documents: Array<TripDocument>;
  };
};
/** "События по заказу в целом" - вкладка Документы */
export type TripTimelinesType = {
  [K in number]: {
    timelines: Array<TripTimelineType>;
  };
};

export type TripDocument = {
  id: number;
  name: string;
  type: string;
  extension: string;
  link: string;
  icon: string;
};

export type TripTimelineType = {
  created: string | number;
  title: string;
  creator: {
    id: number;
    name: string;
    surname?: string;
    patronymic?: string;
    phone?: string;
  };
  type: string;
};

export type TripContactPerson = {
  name: string;
  phone: string;
};

export type MapRouteByCoords = {
  algorithm: string;
  code: string;
  waypoints: Array<{
    hint: string;
    distance: number;
    location: number[];
    name: string;
  }>;
  routes: Array<{
    legs: [
      {
        steps: [];
        weight: number;
        distance: number;
        summary: string;
        duration: number;
      }
    ];
    weight_name: string;
    geometry: string;
    weight: number;
    distance: number;
    duration: number;
  }>;
};

