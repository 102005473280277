import { ILSContent, ILSHeader, ILSLayout } from '@components/index';
import { Scope } from '@core/containers/scope';
import { ModuleScope } from '@core/types';
import { FC } from 'react';
import CustomScroll from 'react-customscroll';
import { ILSTitle } from '@common/components';

const error = {
  title: 'Ваша роль в системе не определена',
  text: 'Система не смогла определить роль Вашего аккаунта. Пожалуйста, обратитесь к администратору аккаунта.'
};

const ErrorPage: FC<any> = () => {
  return (
    <ILSLayout className="ils-wrapper">
      <ILSHeader>
        <div className="flex-between-center">
          <Scope name={ModuleScope.Logo} />
          <Scope name={ModuleScope.HeaderRight} />
        </div>
      </ILSHeader>
      <ILSContent className="ils-content">
        <CustomScroll>
          <div className="flex-center-start p-5">
            <div className="bg-white rounded p-5">
              <ILSTitle title={error.title} />
              <div className="py-3">{error.text}</div>
            </div>
          </div>
        </CustomScroll>
      </ILSContent>
    </ILSLayout>
  );
};

export default ErrorPage;
