import { ILSButton, ILSList, ILSSkeleton } from '@common/components';
import { IndexedArray } from '@common/types';
import { AnalyticsBoardModel, AnalyticsBoardSetModel } from '@common/types/dictionaries/analytics';
import React, { FC, MutableRefObject, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { sortListBySerial } from '../../utils/sort-boards';
import ILSListItemComponent from './list-item';
import css from 'classnames';

type Props = {
  data?: IndexedArray<AnalyticsBoardModel | AnalyticsBoardSetModel>;
  listTitle: string;
  isFetching: boolean;
  updatingItem?: string | number;
  handleClick: (data: AnalyticsBoardModel | AnalyticsBoardSetModel) => void;
  selectedId?: number | string;
  listRef?: MutableRefObject<HTMLDivElement | null>;
  onEdit?: (value: boolean) => void;
  defaultListItemTitle: string;
  classNames?: string[];
  editable?: boolean;
};

const ILSAnalyticsListComponent: FC<Props> = ({
  data,
  listTitle,
  isFetching,
  updatingItem,
  selectedId,
  handleClick = () => {},
  onEdit = () => {},
  defaultListItemTitle,
  listRef,
  classNames,
  editable
}) => {
  const boardsData = useMemo(() => {
    return sortListBySerial(data);
  }, [data]);

  const classes = css('ml-4 mr-4 mt-2  bg-white', classNames ? [...classNames] : '');

  return (
    <div className={classes} ref={listRef}>
      <div>
        <div className="analytics-list-header">
          <div className="pt-2 pb-2 pl-2 title">{listTitle}</div>
          {editable ? (
            <ILSButton
              onClick={() => onEdit(true)}
              className="edit"
              disabled={isFetching}
              type="link"
              icon={<ReactSVG className="svg-container" width={30} height={30} src={'/assets/icons/pen.svg'} />}
            />
          ) : null}
        </div>
        <div className={css('analytics-list', 'analytics-scroll', 'item-list')}>
          <ILSSkeleton loading={isFetching && !updatingItem} active>
            <ILSList
              itemLayout="horizontal"
              dataSource={boardsData}
              renderItem={(item) => (
                <ILSListItemComponent
                  defaultListItemTitle={defaultListItemTitle}
                  selectedId={selectedId}
                  handleClick={handleClick}
                  item={item}
                  loading={item.ID === updatingItem && isFetching}
                />
              )}
            />
          </ILSSkeleton>
        </div>
      </div>
    </div>
  );
};

export default ILSAnalyticsListComponent;
