import { CellType, TableConfig } from '@common/types';

export const tableConfig: TableConfig = {
  columns: [
    {
      title: '№',
      dataIndex: 'ID',
      key: 'ID',
      active: true,
      type: CellType.Input,

      ellipsis: true,
      sorting: 3,

      invisible: true
    },
    {
      title: 'ТС',
      dataIndex: 'Name',
      key: 'Name',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Водитель',
      dataIndex: 'DriverName',
      key: 'DriverName',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'array',
      width: 100,

      sorting: 3
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone',
      key: 'Phone',
      active: true,
      type: CellType.Input,
      filtering: 'string',
      ellipsis: true,
      width: 80,

      sorting: 3
    },
    {
      title: 'Количество нарушений',
      dataIndex: 'Count',
      key: 'Count',
      active: true,
      type: CellType.Int,
      filtering: 'string',
      ellipsis: true,
      width: 80,

      sorting: 3
    },
    {
      title: 'Пробег по плану, км',
      dataIndex: 'MileagePlan',
      key: 'MileagePlan',
      active: false,
      type: CellType.Input,
      filtering: 'string',
      ellipsis: true,
      width: 60,

      sorting: 3
    },
    {
      title: 'Пробег по факту, км',
      dataIndex: 'MileageFact',
      key: 'MileageFact',
      active: false,
      type: CellType.Input,
      filtering: 'string',
      ellipsis: true,
      width: 60,

      sorting: 3
    },
    {
      title: 'Перепробег, км',
      dataIndex: 'Overrun',
      key: 'Overrun',
      active: false,
      type: CellType.Input,
      filtering: 'string',
      ellipsis: true,
      width: 60,

      sorting: 3
    },
    {
      title: 'Комментарий',
      dataIndex: 'Comment',
      key: 'Comment',
      active: true,
      type: CellType.Input,
      ellipsis: true,
      filtering: 'string',
      width: 100,

      sorting: 3
    },
    {
      title: 'Просмотреть план/факт',
      dataIndex: 'Search',
      key: 'Search',
      active: true,
      type: CellType.TextAction, // to Link Icon
      ellipsis: true,
      width: 60,

      sorting: false
    },
    {
      title: 'Просмотрено',
      titleText: 'Просмотрено',
      titleTooltip: 'Просмотрено',
      dataIndex: 'Viewed',
      key: 'Viewed',
      active: true,
      type: CellType.IconAction, //to new => checkboxIcon
      ellipsis: true,
      width: 40,

      sorting: 3
    }
    //убрали до реализации функционала
    // {
    //   title: 'Избранное',
    //   dataIndex: 'Favourite',
    //   key: 'Favourite',
    //   active: true,
    //   type: CellType.Select, // to new => checkboxIcon
    //   options: [
    //     { label: '', value: 0 },
    //     { label: 'Избранное', value: 1 }
    //   ],
    //   ellipsis: true,
    //   width: 70,
    //   editable: true,
    //
    //   sorting: 3,
    //
    //   filtering: 'array'
    // }
  ],
  adding: false,
  deleting: false,
  reloading: false,
  selecting: false,
  copying: false
};
