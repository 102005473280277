import { ReactNode } from 'react';
import { TenderForm } from '@modules/tender-platform/types';
import { ButtonProps } from 'antd';
import { EMPTY_STRING } from '@common/constants';
import { ILSCarrierRateForm } from '@modules/tender-platform/children/tender-own/components/carriers/rate';
import { PLANNER_PLANNING_PATH } from '@common/types/modules/module';
import {
  TenderButtonText,
  TenderFromPlannerOperationButton,
  TenderOperationButton,
  TenderOperations
} from '@modules/tender-platform/constants/content';

export const TENDER_PLATFORM_MODALS_TRIGGER_WITH_BUTTON: Record<
  TenderOperations,
  {
    text: TenderButtonText | string;
    modalContent: string | ReactNode;
    modalTitle: string;
    okText?: string;
    form?: TenderForm;
    htmlType?: ButtonProps['htmlType'];
    type?: ButtonProps['type'];
    url?: string;
  }
> = {
  [TenderFromPlannerOperationButton.Save]: {
    text: TenderButtonText.Save,
    modalContent: 'Вы действительно хотите сохранить все маршруты на тендерной площадке?',
    modalTitle: 'Сохранение маршрутов'
  },
  [TenderFromPlannerOperationButton.SaveAndGoTo]: {
    text: 'Сохранить и перейти',
    modalContent: 'Вы действительно хотите сохранить все маршруты и перейти на тендерную площадку?',
    modalTitle: 'Сохранение маршрутов'
  },
  [TenderFromPlannerOperationButton.SaveAndSetApprove]: {
    text: 'Сохранить и отправить на утверждение',
    modalContent: 'Вы действительно хотите сохранить все маршруты и перевести их на утверждение?',
    modalTitle: 'Сохранение маршрутов'
  },
  [TenderFromPlannerOperationButton.SaveSetApproveAndGoTo]: {
    text: 'Сохранить, отправить на утверждение и перейти',
    modalContent: 'Вы действительно хотите сохранить все маршруты, перевести их на утверждение и перейти в тендерную площадку?',
    modalTitle: 'Сохранение маршрутов'
  },
  [TenderOperationButton.Rate]: {
    text: EMPTY_STRING,
    modalContent: <ILSCarrierRateForm />,
    modalTitle: EMPTY_STRING,
    okText: TenderButtonText.RateCarrier,
    form: TenderForm.Rate,
    htmlType: 'submit'
  },
  [TenderOperationButton.ConfirmSetExecutionStatus]: {
    text: EMPTY_STRING,
    modalContent: 'Вы уверены, что хотите сменить статус контракта на "Завершён"?',
    modalTitle: EMPTY_STRING,
    okText: TenderButtonText.ConfirmSetStatus
  },
  [TenderOperationButton.ConfirmSetCancellationStatus]: {
    text: EMPTY_STRING,
    modalContent: 'Вы уверены, что хотите отменить тендер?',
    modalTitle: EMPTY_STRING,
    okText: TenderButtonText.ConfirmSetStatus
  },
  [TenderOperationButton.Cancel]: {
    text: TenderButtonText.Cancel,
    modalContent: 'Вы действительно хотите прервать создание тендеров? Все внесенные изменения не будут сохранены',
    modalTitle: EMPTY_STRING,
    type: 'link',
    okText: TenderButtonText.Confirm,
    url: PLANNER_PLANNING_PATH
  },
  [TenderOperationButton.Back]: {
    text: TenderButtonText.Back,
    modalContent: 'Вы уверены, что хотите перейти назад с текущей страницы?',
    modalTitle: EMPTY_STRING,
    type: 'link',
    okText: TenderButtonText.Confirm
  }
};
