import { DateFormat, DateTimeFormat, ILS_MAIN_API_DATE_TIME_FORMAT, TimeFormat, TimeStamp } from '@common/types';
import moment, { isMoment, MomentInput } from 'moment';
import { isNumber } from 'lodash';

export const isMomentInput = (
  value: unknown,
  dateParseFormat: DateFormat | TimeFormat | DateTimeFormat = ILS_MAIN_API_DATE_TIME_FORMAT
): value is MomentInput | TimeStamp => {
  return isMoment(value) || isNumber(value) || moment(value as never, dateParseFormat).isValid();
};
