import { createArrayFromIndexedArray } from '@common/utils/helpers/array/create-array-from-indexed-array';
import { Cargo } from '@common/types';
import { EMPTY_STRING, JoinChar } from '@common/constants';

export const cargoDescriptionsToString = (cargos: Array<Cargo>) => {
  const cargosAmount = cargos?.length;
  if (!cargosAmount) return EMPTY_STRING;
  return cargos.reduce((descriptions: string, cargo, index) => {
    if (cargo.Description) {
      descriptions = descriptions + cargo.Description;
      if (index !== cargosAmount - 1) {
        descriptions = descriptions + JoinChar.Comma;
      }
    }
    return descriptions;
  }, EMPTY_STRING);
};

export const cargo2table = (cargos: Array<Cargo>) => {
  return createArrayFromIndexedArray(cargos).map((cargo) => {
    return {
      ...cargo,
      key: cargo.ID
    };
  });
};

