import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { RcFile } from 'antd/lib/upload';
import { JoinChar } from '@common/constants';
import { ORDERS_TRIPS_PERMITTED_EXTENSIONS } from '@modules/orders-and-trips/constants/documents';
import { beforeUpload } from '@modules/orders-and-trips/helpers/before-upload';
import { activeTripSelector, tripStatusSelector } from '@modules/orders-and-trips/selectors/trip';
import { activeTripDocumentsSelector, tripDocumentsIsFetchingSelector } from '@modules/orders-and-trips/selectors/trip-documents';
import { deleteTripDocumentRoutine, getTripDocumentsRoutine, uploadTripDocumentsRoutine } from '@modules/orders-and-trips/actions';
import { TripDocument, TripStatus } from '@modules/orders-and-trips/types/trip';
import { DocumentDependentType } from '@modules/orders-and-trips/types/documents';

interface ReturnValues {
  documents: Array<TripDocument>;
  isFetching: boolean;
  uploadIsDisabled: boolean;
  permittedExtensionsTitle: string;
  handleCheckBeforeUpload: (file: RcFile) => void;
  handleDeleteDocument: (documentID: number) => void;
}

export const useDocumentsContainer = (): ReturnValues => {
  const dispatch = useDispatch();
  const tripStatus = useSelector(tripStatusSelector);
  const activeTrip = useSelector(activeTripSelector);
  const documents = useSelector(activeTripDocumentsSelector);
  const isFetching = useSelector(tripDocumentsIsFetchingSelector);
  const permittedExtensionsTitle = ORDERS_TRIPS_PERMITTED_EXTENSIONS.join(JoinChar.Comma);
  const uploadIsDisabled = tripStatus !== TripStatus.onRegistration || isFetching;

  useEffect(() => {
    dispatch(getTripDocumentsRoutine({ tripID: activeTrip?.ID, dependent: DocumentDependentType.Tender }));
  }, [activeTrip?.ID]);

  const handleUploadDocuments = (documents?: RcFile | Array<RcFile>) => {
    if (isEmpty(documents)) return null;
    dispatch(uploadTripDocumentsRoutine({ dependentID: activeTrip?.ID, attachment: documents }));
  };

  const handleCheckBeforeUpload = (file: RcFile) => {
    beforeUpload(file, ORDERS_TRIPS_PERMITTED_EXTENSIONS, handleUploadDocuments);
  };

  const handleDeleteDocument = (documentID: number) => {
    dispatch(deleteTripDocumentRoutine({ tripID: activeTrip?.ID, documentID }));
  };

  return {
    documents,
    isFetching,
    uploadIsDisabled,
    permittedExtensionsTitle,
    handleCheckBeforeUpload,
    handleDeleteDocument
  };
};
