import { createSelector } from 'reselect';
import { PaymentInitialState } from '@common/feature/payment/types/general';

export const paymentBalanceHistorySelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.balanceHistory,
  (balanceHistory) => balanceHistory?.transactions
);

export const paymentBalanceHistoryFetchSelector = createSelector(
  (state: { Payment: PaymentInitialState }) => state.Payment.balanceHistory,
  (balanceHistory) => balanceHistory?.isFetching
);

